<template>
  <section>
    <img src="@/assets/fond.jpg" alt="Nebula Silva Background image">
    <div class="logo">
        <svg id="Layer_1" x="0px" y="0px"
           viewBox="0 0 512 530.7" enable-background="new 0 0 512 530.7" xml:space="preserve">
        <path d="M254.9,4.1c0-0.1,0.1-0.2,0.2-0.3c-0.1,0.8,0,1.7-0.1,2.6c0.1,1.3,0,2.7,0.1,4c-0.1,1.2,0.1,2.5-0.1,3.7
        c0,0.3,0.1,0.5,0.1,0.8c0,0.9,0.1,1.7,0,2.6c0.1,0.5,0.1,1.1,0.1,1.6c0,0.5,0.2,1,0.1,1.5c0,0.1,0,0.2,0.1,0.3
        c-0.1,0.5,0.2,1.1-0.2,1.6c0.1,0.2,0.1,0.3,0.2,0.5c0.2-0.2,0.2-0.5,0.4-0.6c0,0.6-0.3,1.2-0.3,1.8c0,0.8,0,1.7,0,2.5
        c0.1,0,0.2-0.1,0.3-0.1c-0.1,0.2-0.2,0.3-0.3,0.5l0.2,0c-0.1,0.1-0.2,0.4-0.3,0.5c0.1,0,0.3-0.1,0.5-0.2c-0.2,0.2-0.2,0.4-0.2,0.6
        l0.2,0c0,0.4,0,0.9,0,1.3L256,29c0,0.5-0.2,0.9,0,1.4c0,0,0.1,0,0.2-0.1c0,0.2,0,0.5-0.1,0.6c0,0,0.1,0.1,0.2,0.1l-0.1,0.3l0.2-0.1
        c0,0.1,0,0.3,0,0.4l-0.3-0.1c0.2,0.3,0.4,0.5,0.6,0.8c-0.1,0-0.3,0-0.4-0.1l0,0.3l0.2-0.1c0,0.2,0.1,0.5,0.1,0.7
        c-0.1,0.1-0.1,0.2-0.2,0.3l-0.1,0.1c0.2,0.2,0.3,0.5,0.2,0.7c0.1,0,0.2,0,0.3-0.1c0,0.2-0.1,0.4-0.2,0.7c0.1-0.1,0.2-0.2,0.3-0.3
        c0,0.4,0.2,1-0.1,1.2l0,0c0.1,0,0.3-0.1,0.4-0.1c0,0.1-0.1,0.3-0.2,0.3c0.2,0.2,0.1,0.4,0.1,0.7c0.1,0.1,0.2,0.2,0.3,0.4
        c-0.3,0.3,0.1,0.7,0,1c0.1,0.2,0.1,0.4,0.1,0.6c0.1,0.2,0.1,0.4,0.1,0.6c0,0.1,0.1,0.3,0.2,0.3c-0.2-0.1-0.4-0.2-0.6-0.3
        c0,0.3,0.4,0.5,0.3,0.9c-0.2,0.1-0.4,0.1-0.5,0.1c0-0.2,0.1-0.3,0.2-0.5c-0.3-0.1-0.3,0.4-0.6,0.5c0.1,0.1,0.1,0.2,0.2,0.2v-0.2
        c0.3,0,0.8,0.1,0.6,0.6c0.1,0,0.3-0.1,0.3-0.2c0,0.1-0.1,0.3-0.1,0.4l-0.2,0c0.1,0.2,0.2,0.4,0.4,0.6c-0.2,0.5,0,0.9,0,1.4l0.3,0
        c-0.1,0.2-0.3,0.3-0.4,0.5c0.1,0.2,0.2,0.4,0.4,0.6l-0.4-0.2c0.1,0.2,0.2,0.4,0.2,0.5c0.1,0.1,0.2,0.2,0.2,0.3
        c0,0.2-0.1,0.3-0.1,0.5c0.3,0.2,0.2,0.6,0.2,0.9l-0.1-0.1c0,0.2,0,0.5,0,0.8c-0.1,0-0.3-0.3-0.5-0.1c0.2,0.1,0.3,0.3,0.3,0.5
        c0.1-0.2,0.1-0.6,0.5-0.5c0,0.2-0.1,0.5-0.2,0.6c0.2-0.1,0.3-0.1,0.5-0.2c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0,0.3,0,0.4,0
        c-0.1,0.1-0.2,0.3-0.3,0.4c0.2,0.2,0.1,0.3-0.1,0.4c0.2,0.3-0.2,0.5-0.3,0.7c0.2-0.1,0.5-0.2,0.7-0.3c-0.2-0.2-0.3-0.4-0.1-0.6
        c0.2,0.2,0.3,0.4,0.5,0.7c-0.1,0-0.2,0-0.3,0c-0.1,0.2-0.2,0.5-0.2,0.7c0.3,0.2,0.5,0.4,0.6,0.8c-0.4,0.1-0.7-0.1-1-0.3
        c0.1,0.1,0.2,0.2,0.2,0.2c0,0,0,0.1,0,0.2l0,0l0-0.2l0.3,0c0,0.1,0,0.3,0,0.4l0-0.3l0.3,0c-0.2,0.3-0.3,0.7,0.1,0.8
        c-0.1-0.2-0.1-0.3-0.2-0.5c0.3,0,0.2,0.4,0.3,0.6c-0.2,0.1-0.4,0.3-0.5,0.6c0.1,0,0.2-0.1,0.2-0.1c0.1-0.2,0.2-0.3,0.4-0.4
        c0,0.1-0.1,0.4-0.1,0.5c0.1,0,0.2,0.1,0.3,0.2l-0.3,0c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.1-0.1,0.2-0.2,0.3
        c0.2,0.1,0.4,0.3,0.4,0.6c-0.2-0.1-0.3-0.5-0.6-0.5c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.2-0.2,0.3l0.3,0.1l0-0.4c0,0.1,0.4,0.4,0,0.4
        l0,0.1l0.2,0c0,0.3,0.2,0.7-0.3,0.7c0.1,0.1,0.2,0.1,0.3,0.2c0.1-0.1,0.2-0.3,0.3-0.5c-0.1,0.3-0.1,0.7,0.1,0.9
        c0.2,0.4,0.4,1.1-0.1,1.4c-0.1-0.2-0.1-0.3-0.3-0.5c0.1,0.3,0.2,0.5,0.3,0.8c0-0.3,0.2-0.4,0.4-0.6c0.1,0.3,0.3,0.6,0.1,0.9
        c0.1,0,0.3,0,0.4,0l0,0.1l-0.2,0c0,0.5,0.5,0.9,0.5,1.4c0.1,0.2,0.2,0.3,0.4,0.4c0,0,0,0.1,0,0.2c0.3,0.1,0.1,0.4,0.2,0.5
        c0.2,0.6,0.7,1,0.8,1.6c0.2,0.1,0.2,0.3,0.3,0.4c0.3,0.4,0.6,0.8,1.1,1.1c-0.2,0-0.3,0-0.5,0c-0.3-0.3-0.8-0.3-1.1-0.6
        c-0.1-0.1-0.3-0.1-0.4-0.2c0-0.5-0.8-0.5-0.9-1c-0.1,0-0.2,0.1-0.3,0.1c0-0.1,0-0.4,0-0.5c-0.2-0.1-0.3-0.3-0.3-0.4
        c0-0.1-0.1-0.2-0.1-0.3c-0.2,0-0.3-0.1-0.5-0.2c0.2,0.2,0.4,0.3,0.4,0.6l0.1,0l-0.1,0.2c0.3,0.1,0.4,0.4,0.4,0.7l-0.3,0
        c0.2,0.1,0.6,0.1,0.6,0.5c0,0.5,0.4,1,0.4,1.6c0.1,0.1,0.2,0.2,0.3,0.2c-0.2,0.3,0,0.5,0.3,0.7c-0.8-0.1,0.1,0.3-0.1,0.6
        c0.1,0,0.2-0.1,0.2-0.1c0,0.2,0,0.3-0.1,0.5c0.1,0,0.2-0.1,0.3-0.1c0,0.1,0,0.4,0,0.5c0.4,0.3,0.4,0.9,0.8,1.2
        c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.2,0.1,0.4,0.2,0.6c-0.4-0.8-1.1-1.3-1.7-2c-0.3-0.5-0.8-0.9-0.9-1.5c-0.4,0-0.4-0.5-0.6-0.7
        c-0.3-0.4-0.6-0.8-0.9-1.2c0.1-0.5-0.5-0.8-0.7-1.2c-0.2-0.4-0.5-0.7-0.6-1.1c-0.2,0.2-0.3,0.4-0.5,0.6c0.1,0,0.2,0,0.3,0
        c-0.2,0.5-0.3,1-0.2,1.5c0.1,0,0.2,0.1,0.3,0.2c-0.3,0-0.3,0.3-0.5,0.5l0.3,0l-0.1-0.2c0.1,0,0.2,0,0.2,0.1
        c-0.1,0.4,0.2,0.6,0.4,0.9c-0.1,0-0.3,0-0.4,0c0,0.3,0.1,0.4,0.3,0.4c-0.1,0.4,0.2,0.7,0.5,0.9c-0.1,0-0.3,0-0.4,0
        c0.4,0.3,0.5,0.7,0.4,1.2l0.1,0c0,0,0.1-0.1,0.2-0.2c-0.1,0.4,0.2,0.6,0.4,0.9c0,0.1,0,0.3,0,0.4l0.1,0c0.2,0.3,0.4,0.5,0.5,0.9
        l0.1,0c0.2,0.4,0.6,0.7,0.8,1.1c-0.6-0.1-0.9-0.6-1.3-0.9c0.2,0.6,0.7,1,0.9,1.6c-0.1,0-0.3,0.1-0.4,0.1c0.1,0.2,0.3,0.3,0.4,0.4
        c0-0.1,0-0.3,0-0.4c0.3,0,0.3,0.3,0.1,0.4c0,0.1-0.1,0.2-0.1,0.3c0.1,0,0.3,0.1,0.4,0.1c0,0.2,0.4,0.5,0,0.6
        c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1,0.1-0.2,0.2-0.2,0.3l0.3,0c0,0.1-0.1,0.2-0.1,0.3c0.2,0.1,0.3,0.3,0.3,0.5c0.1,0,0.2-0.1,0.3-0.1
        c0.1,0.2,0.2,0.5,0,0.7c0.1,0.1,0.3,0.2,0.3,0.2c0-0.1,0-0.3,0-0.4c0.1,0.1,0.2,0.2,0.3,0.3c-0.2,0.2-0.3,0.6,0,0.6
        c0.3,0,0.2,0.4,0.4,0.6c-0.5-0.2-0.2,0.2-0.2,0.4c0.3,0.2,0.6,0.3,0.8,0.6l-0.3-0.1c0,0.1,0.1,0.4,0.2,0.5c0.1,0.1,0.2,0.2,0.2,0.4
        c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0-0.3,0-0.4,0.1c0.2,0.2,0.5,0.4,0.5,0.6c0.2,0.5,0.5,1,0.7,1.5c-0.1,0-0.3,0-0.4,0c0,0,0,0.1,0,0.1
        c0.1,0,0.4,0,0.6,0c-0.1,0.1-0.2,0.2-0.3,0.3l0.2,0c-0.1,0.6,0.6,0.9,0.8,1.4c-0.4,0-0.7-0.3-1-0.5c-0.4-0.4-0.9-0.8-1.3-1.2
        c0-0.1,0-0.2,0-0.3c-0.1,0-0.2,0-0.3,0c-0.2-0.2-0.4-0.5-0.5-0.7c-0.1,0-0.3-0.1-0.4-0.1c-0.2-0.3-0.5-0.4-0.8-0.6
        c0-0.1-0.1-0.2-0.1-0.4c-0.5-0.2-0.6-0.7-0.9-1.1c-0.2-0.3-0.5-0.6-0.7-0.8c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2,0.1-0.5-0.1-0.7-0.2
        c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0.1,0,0.2,0c0.1,0,0.3,0.1,0.4,0.1c-0.1,0-0.3,0.1-0.4,0.2c0,0,0.1,0.1,0.1,0.2
        c0.1-0.1,0.2-0.2,0.2-0.2c0.1,0.2,0.3,0.5,0.4,0.7c-0.1,0-0.3,0-0.4,0c0.2,0.3,0.5,0.8,0.9,0.4c-0.1,0.2-0.1,0.4-0.2,0.6
        c0.1-0.1,0.3-0.2,0.4-0.3c0,0.3,0,0.6,0,0.9c0.3,0,0.1-0.4,0.1-0.6c0.4,0,0.2,0.4,0.3,0.7l0.4-0.2c-0.1,0.1-0.2,0.3-0.3,0.4
        c0.1,0,0.2,0.1,0.3,0.1c0-0.1-0.1-0.3-0.1-0.4c0.1,0.1,0.3,0.2,0.4,0.3c-0.1,0.2-0.1,0.3-0.2,0.5c0.1,0,0.3,0,0.4-0.1
        c0,0.1,0.1,0.3,0.1,0.4c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0.2,0.4,0.5,0.4,0.8c-0.2,0-0.7-0.7-0.6-0.1c0.3,0.4,1,0.6,1,1.1
        c0.3,0.1,0.2,0.5,0.5,0.5c0.1,0.5,0.8,0.6,0.7,1.1c0.2,0.2,0.4,0.4,0.7,0.5c0.2,0.2,0.3,0.3,0.3,0.6c0.1,0,0.2-0.1,0.4-0.1
        c0,0.1-0.1,0.3-0.1,0.4c0.1,0,0.2,0,0.3,0c0,0.2,0.2,0.3,0.4,0.3l0,0.1c0.1,0,0.4,0,0.5,0c-0.1,0.3-0.5,0.1-0.7,0.1
        c-0.3,0-0.5-0.2-0.8-0.4c0,0-0.1,0.1-0.1,0.1c-0.3-0.2-0.7-0.2-0.9-0.5c-0.1,0-0.2,0.1-0.2,0.1l-0.3-0.2c0,0.1,0,0.4,0.1,0.5
        c0.2,0.1,0.4,0.2,0.5,0.5c0,0.2,0.1,0.3,0.1,0.5c0.1,0,0.2,0,0.2,0c-0.1,0.5,0.5,0.6,0.8,0.7c-0.3,0.3-0.8,0-1.1-0.1
        c0,0.1,0.1,0.3,0.1,0.4c-0.1-0.2-0.3-0.2-0.5-0.3l0.1-0.2c-0.2,0.1-0.4-0.1-0.6-0.2c-0.4-0.3-0.8-0.5-1.2-0.9
        c-0.4-0.2-0.7-0.4-1-0.7c-0.3-0.1-0.5-0.3-0.6-0.6c-0.6-0.4-1.3-0.8-1.6-1.5c-0.5-0.1-0.7-0.5-0.8-0.9c-0.4-0.2-0.5-0.6-0.9-0.9
        c0-0.4-0.5-0.6-0.6-1.1c-0.1-0.1-0.3-0.2-0.4-0.2c0.1-0.3-0.1-0.4-0.3-0.5c0-0.1,0-0.2,0-0.3c0,0.1-0.1,0.2-0.1,0.2c0,0,0,0.1,0,0.2
        c0.1,0,0.2,0,0.2,0c0.1,0.3,0.1,0.6,0.3,0.9l0.2,0c0.1,0.3,0.1,0.6,0.2,0.9c0.1,0.2,0.1,0.3,0.3,0.4c0.2,0.3,0.4,0.6,0.4,1
        c0.1,0,0.2,0.1,0.3,0.1c-0.1,0.1-0.1,0.2-0.2,0.3c0.4,0.1,0.9,0.4,0.6,0.9c0.1,0,0.2,0,0.3-0.1c0.1,0.2,0.3,0.4,0.4,0.5
        c-0.1,0.2-0.1,0.4-0.1,0.6l0.2-0.1c0,0.1-0.1,0.4-0.1,0.6c0.1,0,0.3,0,0.4,0l0,0.2l0.2,0c-0.1,0.2-0.1,0.3-0.2,0.5
        c0.1,0,0.3-0.1,0.4-0.1c0,0.3,0.1,0.4,0.3,0.6c0-0.1,0-0.4,0-0.5c0.1,0.2,0.1,0.4,0.1,0.6c0.1,0.1,0.2,0.1,0.4,0.2
        c-0.1,0-0.3,0-0.3,0c0,0.2,0.2,0.3,0.4,0.3c0.1,0.2,0.3,0.3,0.3,0.5c0.1,0,0.3,0,0.3,0c0,0.1-0.1,0.3-0.1,0.4
        c0.2,0.1,0.3,0.3,0.6,0.3c0.1,0.2,0.2,0.3,0.4,0.3c0,0.1,0,0.2,0,0.3c-0.3-0.2-0.6-0.2-0.9-0.2c-0.2-0.1-0.3-0.1-0.5-0.2
        c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1,0-0.2,0-0.3,0c-0.5-0.3-1.1-0.5-1.4-1.1c-0.3-0.2-0.7-0.4-1.1-0.3c0.1,0.2,0.3,0.4,0.5,0.6
        c-0.1,0.1-0.1,0.2-0.2,0.4c0.4,0.2,0.3,0.5,0.2,0.9l0.2-0.1c0,0.1,0,0.2,0,0.2c0.4,0,0.3,0.4,0.3,0.6l0.2,0c0,0.2,0,0.4,0,0.6
        c0.1,0,0.2,0,0.3,0c0,0.1-0.1,0.3-0.1,0.4l0.2,0l0,0.2c0.1,0,0.2,0,0.3,0c0,0.1,0,0.3,0,0.3c-0.1,0.1-0.3,0.1-0.4,0.2
        c0.2,0.5,0.4,1,0.7,1.5c0.4,0.3,0.7,0.6,0.9,1c-0.7-0.2-1.2-0.8-1.8-1.2c0-0.1,0-0.3,0-0.4c-0.4-0.1-0.7-0.5-0.9-0.8
        c-0.1,0.1-0.2,0.1-0.2,0.2l0-0.2l-0.2,0c0-0.1,0.1-0.2,0.1-0.3c-0.3,0-0.3-0.1,0-0.2l0-0.3l-0.2,0.1l0.1-0.3
        c-0.1,0.1-0.3,0.2-0.4,0.2c0.1,0.3,0.2,0.6,0.4,0.8c0.2,0.1,0,0.3,0,0.5c0.1,0.1,0.3,0.1,0.4,0.2c-0.2,0.5,0.3,0.8,0.4,1.3
        c0.4,0.4,0.6,1,1,1.5c0.5,0.7,1.2,1.3,1.9,1.6c-0.6,0.1-1.1-0.3-1.6-0.5c-0.2,0-0.4,0.1-0.6,0.3c0.3,0.1,0.5,0.1,0.8,0
        c-0.1,0.4-0.9,0-0.7,0.6c0.2-0.1,0.3-0.1,0.5-0.2c0.1,0.5,0.6,0.6,1,0.8c0,0,0.1-0.1,0.2-0.1c0.4,0.2,0.9,0.2,1.3,0.4
        c0.2,0.1,0.5,0.2,0.7,0.3c0,0,0.1,0,0.1,0c0.2,0.2,0.6,0.3,0.9,0.4c0.3,0.1,0.6,0.3,0.9,0.4c0.4-0.7,0.6-1.6,0.9-2.4
        c0.2-0.3,0.1-0.8,0.5-1c-0.1-0.6,0.2-1.1,0.4-1.6c0.2-0.7,0.4-1.5,0.7-2.2c0-0.2,0-0.5,0.2-0.7c0-0.5,0.1-0.9,0.1-1.4
        c0,0,0.1-0.1,0.2-0.2h-0.2c0-0.2,0-0.4,0.1-0.5c-0.1-0.1-0.2-0.2-0.2-0.3l0.2,0c0-1.1,0-2.1,0.1-3.2c0.1-0.5,0-1,0.2-1.4
        c-0.1-1.9,0.1-3.8,0.1-5.7c0.2-0.8,0-1.7,0.2-2.5l0.1,0c0,0.5-0.1,1,0,1.5c-0.1,0.5-0.2,1,0,1.6c-0.1,0.4-0.2,0.8,0,1.1
        c-0.1,0.3-0.1,0.5,0,0.8c-0.2,0.5-0.1,1,0,1.5c-0.1,0.8,0.1,1.7,0,2.6c-0.1,0.7,0.2,1.4,0,2.1c0.2,0.5,0.2,1.1,0.1,1.6
        c0.1,1.2,0.2,2.4,0.3,3.5c0.3,0.5,0,1.3,0.6,1.6l-0.2,0c0.1,0.4,0.4,0.8,0.4,1.3l0.1,0c0.1,0.5,0.4,1,0.3,1.5l0.2-0.1
        c0,0.3,0.1,0.5,0.2,0.8c0.1,0.2,0.2,0.5,0.3,0.7c0.3,0.2-0.1,1,0.4,0.9c0,0.1-0.1,0.4-0.1,0.5c0.1,0,0.2-0.1,0.3-0.1
        c0,0.1,0,0.3,0,0.4c0.3,0,0.1,0.3,0.2,0.5c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.4,0.4,0.6,0.7,0.9c0.1-0.2,0.2-0.4,0.3-0.5
        c0.5-1.1,1.1-2.2,1.6-3.3c0.1-0.4,0.3-0.7,0.3-1.1l0.1,0l0-0.1c0.1-0.1,0.3-0.3,0.3-0.5c-0.1,0-0.3,0-0.4,0c0.1,0,0.2-0.1,0.3-0.1
        c0-0.1-0.1-0.3-0.1-0.4l0.3,0.1c0-0.1-0.1-0.3-0.2-0.4l0.3,0c-0.2-0.2-0.4-0.7,0.1-0.7c0-0.2,0-0.5,0-0.7l0.1,0
        c0-0.1,0.1-0.3,0.2-0.4c0-0.2,0.1-0.5,0.2-0.7c-0.1,0-0.2,0-0.3,0l0-0.1c0.1,0,0.3,0,0.4,0.1c-0.1-0.1-0.2-0.3-0.2-0.4
        c0.3-0.1,0.2-0.3,0-0.4c0.5,0.1,0.3-0.5,0.4-0.8l-0.2,0c0-0.6,0.5-0.9,0.8-1.3c-0.1,0-0.4,0.1-0.6,0.2c0.1-0.2,0.2-0.3,0.4-0.4
        c-0.1-0.2,0-0.4,0.1-0.5c-0.1-0.4,0.1-0.8,0-1.3c0.1,0,0.2,0,0.3,0c-0.1-0.3,0.2-0.5,0.4-0.8c-0.1,0-0.3-0.1-0.4-0.2
        c0.2-0.3,0.4-0.5,0.7-0.8c-0.2,0.1-0.3,0.1-0.5,0.2c0-0.1,0.1-0.3,0.1-0.5c0.1,0,0.3,0,0.3,0c0-0.3-0.1-0.5-0.2-0.7
        c0.1,0,0.4,0,0.5,0c-0.2-0.1-0.4,0-0.6,0c0.1-0.1,0.3-0.3,0.4-0.4c0-0.3,0-0.5,0-0.8c0.1,0,0.4,0,0.5,0c0-0.1,0.1-0.3,0.1-0.4
        c-0.2,0.1-0.4,0.4-0.6,0.1c0.2-0.2,0.4-0.4,0.6-0.6c-0.1,0-0.3-0.1-0.4-0.1c0.1-0.2,0.3-0.4,0.1-0.6c0,0,0.1,0,0.2-0.1
        c0-0.2,0-0.4,0-0.6l0.2,0.1c-0.1-0.3-0.1-0.6-0.1-1c0,0.1,0.1,0.2,0.2,0.3c0.4-0.1,0-0.4-0.2-0.4c0-0.2,0.1-0.3,0.1-0.5
        c0.1,0,0.3,0,0.3,0c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.2,0-0.3,0-0.5c0,0-0.1-0.1-0.2-0.2l0.1-0.1c0,0.1,0.1,0.2,0.2,0.3
        c0.3-0.1,0-0.3-0.1-0.5c0.1-0.2,0.2-0.5,0.1-0.8l0.2,0c-0.1-0.2-0.2-0.3-0.3-0.5l0.3,0.1c0.1-0.3,0.1-0.6-0.1-0.8c0,0,0-0.1,0.1-0.2
        c-0.1-0.1-0.2-0.3-0.2-0.4c0,0,0.1-0.1,0.2-0.1c0.1,0.1,0.3,0.3,0.3,0.4c-0.1-0.2-0.2-0.5-0.3-0.7c0.1-0.1,0.2-0.2,0.4-0.3
        c-0.1-0.2-0.2-0.4-0.3-0.5c0.3-0.2,0.2-0.6,0.2-0.9l-0.2,0l0-0.3l0.2-0.1c0-0.5-0.1-1,0.1-1.4c-0.2-0.5-0.1-1-0.1-1.5
        c0-0.2-0.1-0.3-0.2-0.4c0.1,0,0.2,0.1,0.3,0.1c0-0.4,0-0.8,0-1.3l-0.3,0.1c0,0,0.1-0.1,0.2-0.2c0-0.2,0-0.4,0.2-0.5
        c-0.1-0.1-0.2-0.3-0.2-0.4c0.6,0.1,0-0.4,0-0.6l0.3,0.1c-0.1,0-0.2-0.1-0.3-0.1c0-0.3,0-0.6,0.1-0.9l-0.3,0c0.1,0,0.2-0.1,0.2-0.2
        c0-0.6,0.1-1.2,0.2-1.8l-0.2,0c0-1.5,0-3.1,0-4.6c-0.2-0.8,0-1.7-0.1-2.6c0-0.9-0.1-1.8-0.1-2.7c0.2,0.4,0.1,0.9,0.2,1.3
        c0.2,0.7,0,1.4,0.1,2.1c0,0,0.1,0.1,0.2,0.1c-0.1,0-0.2,0.1-0.2,0.1c0,0.1,0.1,0.3,0.2,0.3c-0.2,0.2-0.1,0.5,0,0.8
        c0.2,0.7,0.1,1.5,0.2,2.3c0.1,0.3,0.1,0.7,0,1c0.1,0.3-0.1,0.7,0.1,1c0.3,0.7,0.1,1.5,0.3,2.2c0.1,0.2,0,0.3-0.1,0.4
        c0.2,0.4,0.2,0.8,0.2,1.3c0,0.1-0.2,0.3-0.1,0.4c0.2,0.2,0,0.5,0.1,0.7c0.2,0.6,0,1.1,0.1,1.7c0.1,1,0.6,2,0.6,3.1
        c0.1,0.7,0.2,1.3,0.2,2c0.2,0.8,0.1,1.6,0.3,2.3c0,0.1-0.1,0.2-0.1,0.2c0.2,0.5,0.4,1.1,0.3,1.7c-0.1,0.5,0.1,1.1,0.2,1.6
        c-0.1,0-0.2,0-0.3-0.1c0.1,0.1,0.2,0.3,0.4,0.4c0,0.2,0,0.3-0.1,0.5l0.3-0.1c-0.1,0.1-0.1,0.3-0.2,0.4l0.2-0.2c0,0.2,0,0.4,0,0.6
        c0.1,0.2,0.1,0.4,0.1,0.6c0.3-0.1,0.3,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4l0.2,0c-0.1,0.2-0.1,0.4-0.1,0.6c0.2,0.1,0.3,0.2,0.4,0.3
        h-0.3c0.1,0.3,0.3,0.7,0.2,1c0.1,0,0.2,0,0.2,0c0,0.2,0.1,0.4,0.2,0.6c0,0.1,0,0.3,0,0.4l0.2,0c0,0.2-0.1,0.5-0.1,0.7
        c0.1,0,0.2,0,0.2,0c0,0.3,0.1,0.7,0.1,1c0.1,0.1,0.2,0.3,0.4,0.4c0,0.2,0,0.3,0,0.5c0,0,0.1,0,0.1,0c0,0.2,0.1,0.3,0.2,0.4
        c-0.1,0-0.3-0.1-0.5-0.1c0.1,0.1,0.3,0.3,0.4,0.4c0,0.1,0,0.3,0,0.4l0.2,0c0,0.1-0.1,0.3-0.1,0.4l0.2,0c0,0.1,0,0.2-0.1,0.3
        c0.2,0.1,0.3,0.2,0.1,0.5c0.5-0.1,0.3,0.4,0.3,0.7l0.2,0c0,0.1-0.1,0.2-0.1,0.3l0.2,0c0,0.4,0.3,0.9,0.5,1.2c0.1,0.3,0.2,0.7,0.4,1
        c0.2,0.3,0.6,0.7,0.7,1c-0.3-0.3-0.6-0.5-0.7-0.9c-0.1,0-0.2,0-0.2,0c-0.2-0.3-0.4-0.7-0.5-1c-0.3-0.3-0.3-0.8-0.6-1
        c-0.3-0.3-0.4-0.8-0.8-1.2c0-0.4-0.3-0.6-0.5-0.9c-0.2-0.1,0-0.4,0-0.5l-0.1,0.1c-0.1-0.2-0.3-0.5-0.3-0.7c-0.1-0.1-0.2-0.2-0.3-0.4
        c-0.3,0.2-0.5-0.1-0.7-0.3c0.3,0,0.3-0.3,0.4-0.5c-0.2,0.1-0.3,0.3-0.4,0.4c-0.4-0.2,0-0.7,0.3-0.7c-0.1-0.1-0.2-0.1-0.4-0.2
        l0.1-0.3c0.1,0.1,0.2,0.2,0.3,0.2l0.1,0c-0.2-0.3-0.4-0.5-0.4-0.9c-0.3-0.2-0.2-0.6-0.4-0.8c0,0.2,0,0.4,0,0.5
        c0.2,0.2,0.3,0.4,0.3,0.6c-0.1-0.1-0.3-0.2-0.4-0.3c0,0.1,0.1,0.3,0.1,0.4c0.1-0.1,0.3-0.1,0.5-0.1c-0.2,0.2-0.2,0.5-0.3,0.7
        c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.1,0.2,0.3,0.2,0.5c0.2,0,0.3,0,0.5,0.1l0,0.2c0.1,0,0.2,0,0.3,0c-0.1,0.2-0.1,0.5-0.2,0.7
        c0.1,0,0.2,0,0.2,0c0.1,0.3,0.3,0.5,0.4,0.7c-0.3,0.1-0.3,0.2,0.1,0.1l-0.1,0.2c0.1,0,0.3,0,0.4,0c0,0.1-0.1,0.3-0.1,0.4
        c0.1,0.1,0.3,0.2,0.4,0.3c-0.1,0-0.3,0-0.5,0c0.1,0.1,0.7,0.1,0.4,0.4c-0.1,0-0.2,0.1-0.3,0.2l0.2,0.1c0.1,0,0.3,0,0.4,0
        c0,0.3-0.1,0.6-0.2,0.9c0.2-0.2,0.3-0.4,0.5-0.6c0,0.2,0.2,0.5-0.1,0.7c0.2,0.2,0.5,0.5,0.4,0.9c0,0.3,0.2,0.5,0.2,0.7
        c0.2,0.1,0.2,0.4,0.3,0.6c0,0.1,0.1,0.2,0.1,0.3c0.1,0.2,0.1,0.4,0.1,0.6c0.2,0.2,0.3,0.3,0.3,0.6c0.2,0.2,0.4,0.4,0.4,0.6l0.1,0
        c0.1,0.2,0.2,0.3,0.2,0.5c0.1,0.1,0.2,0.2,0.3,0.3c-0.6,0-0.6-0.8-1.2-0.8c-0.5-0.6-1-1.1-1.7-1.6c-0.1-0.2-0.2-0.4-0.4-0.5
        c-0.4-0.3-0.7-0.6-1-1c0.1,0.1,0.2,0.3,0.2,0.5c-0.1,0-0.2,0-0.3,0.1c0.1,0,0.4,0,0.5,0c0,0.2,0.1,0.4,0.2,0.6l0.2-0.2
        c0.1,0.2,0.1,0.3,0.2,0.5c-0.1,0-0.2,0.1-0.3,0.1c0.1,0.2,0.3,0.3,0.5,0.3c0,0.4,0.3,0.6,0.5,0.8c-0.2-0.1-0.4-0.2-0.7-0.3
        c0.1,0.2,0.3,0.3,0.4,0.5c0.1,0,0.3-0.1,0.4-0.1c-0.1,0.2-0.3,0.3-0.3,0.5c0.1,0,0.3-0.1,0.4-0.1c0,0.2,0.1,0.4,0.1,0.6
        c0,0.1,0,0.3-0.1,0.4c0.1-0.1,0.2-0.3,0.2-0.4c0,0.1,0,0.3,0,0.4c0.4,0.6,0.6,1.3,1,1.9c0.1,0.1,0.2,0.2,0.3,0.3c0,0.2,0,0.4,0,0.6
        c0.2,0,0.3,0,0.3,0.1c-0.1,0-0.3,0-0.3,0c0.1,0.2,0.3,0.3,0.5,0.3c0,0.1,0,0.2-0.1,0.3l0.1,0c0.1,0.3,0.2,0.5,0.3,0.7l0.1,0
        c0.1,0.2,0.2,0.4,0.3,0.6c-0.6-0.3-1-0.9-1.6-1.3c-0.4-0.4-0.9-0.6-1.2-1.1c-0.3-0.3-0.4-0.7-0.8-0.9c-0.1-0.3-0.4-0.4-0.7-0.5
        c-0.2-0.6-0.8-1-1.2-1.5c-0.2-0.3-0.4-0.6-0.7-0.8c-0.1,0.4,0.3,0.7,0.4,1.1c-0.2,0.3,0.2,0.5,0.2,0.9c0.1,0,0.2,0,0.3-0.1
        c0,0,0,0.2,0,0.2l-0.2,0l0,0.3c0.2,0,0.2,0.1,0.1,0.3c-0.1,0.4,0.2,0.1,0.3-0.1c0,0.2,0.3,0.5-0.1,0.6c0.2,0.2,0.5,0.3,0.3,0.6
        c0.1,0,0.2,0,0.2,0c0,0.2-0.1,0.3-0.1,0.5c0.2,0.1,0.4,0.1,0.5,0.3c0.3,0.3,0.2,0.7,0.4,1.1c0.4,0.1,0.1,0.4,0.1,0.6
        c0.1,0,0.2-0.1,0.3-0.1c-0.1,0.3,0.3,0.6,0.1,0.9c0.1,0,0.3-0.2,0.4,0c0,0.2,0.1,0.8-0.3,0.7c-0.1-0.2-0.3-0.5-0.5-0.7
        c0-0.2-0.1-0.4-0.1-0.6c0,0.2,0,0.4,0,0.6c0.4,0.2,0.5,0.7,0.7,1c0.1-0.1,0.2-0.2,0.4-0.3c0.1-0.1,0.1-0.4,0.2-0.4
        c0.2,0.2-0.2,0.6,0.2,0.6c-0.1,0.2,0,0.3,0.2,0.4c0,0.1,0,0.2,0,0.2c-0.1,0-0.3-0.1-0.4-0.2c0.1,0.2,0.3,0.3,0.4,0.5
        c0.1,0,0.3,0,0.5,0.1c0,0.1-0.1,0.3-0.1,0.4c0.1,0.1,0.2,0.3,0.4,0.4c0.1,0.4,0.4,0.7,0.5,1.1c0.1,0.2,0.2,0.5,0.5,0.5
        c0,0.1,0,0.3,0,0.4c0.4-0.1,0.5,0.3,0.4,0.6c0.2,0.1,0.3,0.1,0.5,0.1c0,0.2,0,0.3,0,0.5c0.1,0,0.3-0.1,0.4-0.2
        c-0.1,0.3,0.1,0.5,0.2,0.8c-0.6-0.1-0.8-0.9-1.4-0.9c-0.3-0.5-1-0.5-1.2-1c-0.3-0.1-0.6-0.3-0.9-0.6c0-0.1,0-0.2,0-0.3
        c-0.4-0.3-1-0.3-1.2-0.9l0,0c0,0.4,0.4,0.7,0.7,1c-0.1,0-0.4-0.1-0.6-0.1c0.3,0.1,0.5,0.3,0.8,0.4c-0.2,0.1-0.3,0.2-0.4,0.3
        c0.1,0.3,0.4,0.2,0.6,0c-0.1,0.4,0,0.7,0,1.1l0.1,0c0-0.2,0.1-0.4,0.2-0.6c0.2,0.3,0,0.6,0,0.9c0.1-0.1,0.2-0.2,0.3-0.3
        c0,0.2,0.1,0.5,0.2,0.7c0.1,0,0.2,0,0.3,0.1c0,0.3,0.1,0.4,0.3,0.6c0.2,0.5,0.5,0.9,0.9,1.4c-0.1,0.1-0.2,0.2-0.3,0.3
        c0,0,0.1,0.1,0.1,0.1c0.1-0.1,0.2-0.1,0.3-0.2c0,0.1,0.1,0.3,0.1,0.5c-0.1,0-0.3-0.1-0.4-0.1c0,0.3,0.3,0.1,0.5,0.1
        c0.2,0.3,0.3,0.7,0.6,1c-0.1,0.1-0.2,0.2-0.3,0.3c0,0,0.1,0,0.2,0l0,0.2c0,0,0.1,0,0.1,0c0-0.1,0.1-0.3,0.3-0.4
        c0.4-0.8,1-1.5,1.3-2.3c0.3-0.6,0.6-1.1,0.9-1.6c0-0.1,0-0.1,0-0.2c-0.1,0.3-0.4,0.6-0.6,0.8c-0.1,0-0.2,0.1-0.3,0.1
        c-0.2,0.3-0.3,0.6-0.6,0.7c0.2-0.5,0.5-1,0.9-1.4c0.4-0.7,1-1.4,1.3-2.2c0.1-0.2,0.3-0.4,0.4-0.7c0.1-0.4,0.2-0.7,0.4-1
        c0.2-0.3,0.2-0.7,0.5-0.9c0.1-0.3,0.1-0.5,0.2-0.8c0.2-0.2,0.5-0.5,0.6-0.8c-0.2,0.1-0.3,0.1-0.5,0.2c-0.1,0.2-0.1,0.4-0.2,0.6
        c-0.4,0-0.1-0.3-0.1-0.5c-0.2,0.2-0.1,0.5-0.1,0.8l-0.3-0.1c0.2,0.3,0,0.5-0.2,0.7c-0.1,0.1-0.3,0.3-0.4,0.4
        c-0.1,0.3-0.2,0.5-0.4,0.8c-0.2,0.1-0.4,0.2-0.4,0.4c-0.1,0.4-0.5,0.6-0.7,1c-0.3,0.3-0.5,0.7-0.8,1c-0.1,0.5-0.6,0.5-0.8,0.9
        c-0.1-0.5,0.5-0.6,0.6-1.1c0-0.1,0.1-0.3,0.1-0.3c0.1,0,0.2-0.1,0.3-0.2c0-0.3,0-0.6,0.3-0.7c0.1-0.2,0.1-0.4,0.3-0.6
        c0-0.2,0-0.4,0.3-0.5c0-0.2,0-0.4,0-0.6c0.1,0.1,0.2,0.2,0.3,0.2c0-0.2,0-0.4,0-0.6c0.3-0.1,0.2-0.4,0.3-0.7c0.2-0.3,0.6-0.6,0.5-1
        c0.2,0,0.3-0.2,0.1-0.3c0.1-0.2,0.2-0.5,0.3-0.7c0.3,0.1,0.2-0.2,0.2-0.3c0.3-0.1,0.5-0.4,0.5-0.8c0.1-0.3,0.3-0.6,0.2-0.9l0.2,0.1
        c0-0.1-0.1-0.3-0.1-0.4c0.1,0,0.2,0,0.3,0c0-0.1-0.1-0.3-0.2-0.4c-0.2,0.3-0.4,0.6-0.7,0.9c-0.4,0.2-0.5,0.8-0.9,0.9
        c-0.2,0.2-0.4,0.5-0.7,0.7c0.2-0.7,1.1-1,1.2-1.8c0,0,0.1,0,0.1,0c0.1-0.3,0.3-0.5,0.4-0.7c0.1-0.2,0.1-0.4,0.3-0.5
        c0.2-0.6,0.7-0.9,0.9-1.5c0.2-0.4,0.3-0.9,0.8-1.2c0-0.3,0.2-0.5,0.3-0.7c0.2-0.4,0.4-0.8,0.7-1.2c-0.1-0.2,0-0.3,0.1-0.4
        c0.2-0.8,0.5-1.5,0.8-2.3c0-0.2,0.1-0.3,0.3-0.3c-0.3-0.4,0.3-0.8,0.3-1.3c0.1,0,0.2-0.1,0.3-0.1c0-0.1-0.1-0.2-0.1-0.3
        c0.5-0.9,0.8-2,1-3.1c0-0.1,0.1-0.2,0.2-0.2c-0.1-0.4,0.3-0.6,0.2-1c0.1-0.1,0.2-0.2,0.3-0.4c-0.3-0.1-0.2-0.3,0-0.4
        c0.1-0.6,0.3-1.2,0.3-1.8c0.3-0.2,0.1-0.9-0.3-0.5c-0.1,0-0.2-0.1-0.2-0.1c-0.1,0.2-0.3,0.3-0.5,0.4c0.1,0.2,0.2,0.4,0.2,0.6
        c-0.4-0.3-0.4,0.2-0.2,0.4c-0.1,0.1-0.2,0.1-0.4,0.2l0.3,0c-0.2,0.2-0.4,0.4-0.4,0.7l-0.1,0c0,0.3-0.1,0.5-0.2,0.7l-0.1,0
        c0,0.4-0.3,0.7-0.4,1.1c-0.5,1-0.7,2.2-1.4,3.1c0.1-0.5,0.4-1,0.5-1.5c0.1-0.4,0.2-0.8,0.3-1.2c0.3-0.9,0.4-1.8,0.6-2.7
        c0.1-0.4,0.3-0.8,0.3-1.2c-0.4,0-0.7,0.2-1.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.4-0.2-0.6,0.4-1,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
        c-1,0.6-2,1.2-2.8,2.1c0.5-1.1,1.8-1.6,2.7-2.4c0.5-0.4,1.2-0.7,1.8-1.1c0.2-0.1,0.3-0.2,0.5-0.2c0.1-0.1,0.3-0.1,0.5-0.2
        c0.2-0.1,0.4-0.1,0.6-0.3c0-0.4,0-0.9,0.3-1.3c0-0.3,0-0.6,0.1-0.9c-0.1-0.7,0.3-1.3,0.3-2c0-0.5,0.2-1.1,0.4-1.6
        c-0.1-0.7,0.1-1.4,0.2-2.1c0.2-1,0.4-2,0.4-3c0-0.6,0.1-1.2,0.2-1.8c0.1,0,0.2,0,0.3,0.1c0.3-1,0.4-2.1,0.7-3.1
        c0.2-0.4,0.2-0.9,0.3-1.4c0-0.3,0.1-0.6-0.1-0.8c0,0,0.1-0.1,0.2-0.1c0-0.1,0-0.3,0-0.4c0-0.3,0-0.5,0-0.8c0-0.1,0.1-0.2,0.1-0.3
        l-0.1-0.2c0-0.1,0.1-0.2,0.1-0.3c-0.1-0.7-0.1-1.4-0.1-2.1c0.2-0.7,0-1.5,0.1-2.2c0.1-0.9,0.1-1.7,0.1-2.6c0-0.1,0-0.2,0.1-0.3
        c0,0.1,0,0.2,0,0.3c0,0.5,0.1,1-0.1,1.4c0.1,0.6,0.1,1.1,0.1,1.7c-0.1,0.6,0.2,1.3-0.1,1.8c0.2,0.6,0.1,1.2,0.2,1.7
        c0.1,1,0,2,0.1,3.1c-0.3,0.9,0.3,2-0.1,2.9c0.4,0.8,0,1.7,0.3,2.6c-0.1,0.3-0.2,0.5-0.2,0.8c0.1,0.1,0.2,0.2,0.3,0.3
        c-0.2,0.6,0,1.2,0,1.9c0,0.5-0.1,1,0.1,1.4c-0.3,0.8,0.1,1.6,0,2.4c0.1,0.4,0.1,0.8,0.1,1.1c0.1,0.7-0.1,1.6,0.6,2.2
        c-0.2-0.1-0.3-0.1-0.5-0.2c0.1,0.2,0.1,0.3,0.2,0.5c0.3-0.1,0.6-0.2,0.9-0.3c-0.1,0.1-0.1,0.3-0.2,0.4c0.4-0.2,0.9-0.6,1.4-0.4
        c0.3,0.1,0.6-0.2,1-0.1c0,0,0.1-0.1,0.2-0.1c0.1,0.1,0.2,0.2,0.3,0.3c0-0.1,0-0.3,0-0.5c0.1,0.1,0.2,0.2,0.4,0.4
        c0.1-0.1,0.2-0.1,0.3-0.2c0.2,0.2,0.4,0.5,0.6,0.1c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.2,0-0.2c0.1,0.1,0.2,0.2,0.3,0.2
        c0.3,0,0.7-0.1,1,0.1c0.3-0.1,0.6-0.2,0.8,0.1c-0.1,0.1-0.2,0.1-0.3,0.3c0.1,0,0.3-0.1,0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.5
        c0.4,0.1,1,0.5,1.3,0.1c0.2,0.1,0.4,0.1,0.6,0.2c0.4-0.2,0.9,0,1.3,0.1c0.2-0.2,0.3-0.3,0.4,0.1c0.1,0.1,0.3,0.1,0.5,0.2
        c-0.1-0.1-0.2-0.1-0.2-0.2c0.1-0.7,0-1.5,0-2.2c0-0.8,0.2-1.7,0.3-2.5c0.2-0.7,0.1-1.4,0.2-2c0-1.3,0-2.6,0.1-3.9
        c-0.1-1.5,0.1-3,0.2-4.6c0,0.4,0.1,0.7,0.1,1.1c-0.1,0.6-0.1,1.1-0.1,1.7c0,0-0.1,0.1-0.1,0.2c0.2,0.7,0.1,1.5,0.1,2.3
        c0,0.3,0,0.6,0,0.9c0.1,0.6,0.1,1.1,0.1,1.7c-0.1,0.2-0.1,0.4-0.1,0.5c0.1,0.6,0,1.2,0,1.7c-0.1,0.5,0.1,1.1-0.1,1.6
        c0.4,0.3,0,0.8,0.2,1.1c0,0.2-0.1,0.4,0,0.6c0.2,0.6-0.1,1.2,0.1,1.8c0.1,0.1,0.3,0.2,0.4,0.3c0-0.1,0-0.2,0-0.3c0.2,0,0.4,0,0.7,0
        c0,0.1-0.1,0.2-0.1,0.3c0.1,0.1,0.3,0.1,0.4,0.2c-0.1-0.2-0.2-0.4-0.3-0.5c0.1,0.1,0.4,0.2,0.5,0.3c0.3,0,0.5,0.1,0.8,0.2
        c0.4-0.4,0.8,0.1,1.3,0.1c0.2,0.1,0.3,0.3,0.5,0.3c-0.1-0.2-0.2-0.3-0.2-0.5c0.2,0,0.4,0.2,0.6,0.3c0-0.4,0.3-0.1,0.6-0.1
        c0.2,0,0.3,0,0.3,0.3l-0.3-0.1l0.1,0.3c0.2-0.2,0.5-0.1,0.8-0.1l-0.1-0.1c0.3,0,0.7,0,1,0.2c-0.1,0.1-0.2,0.1-0.4,0.2
        c0,0.1,0.1,0.3,0.1,0.3c0.2-0.2,0.4-0.3,0.7-0.4c0.3,0,0.6,0.4,0.9,0.1c0,0.1,0,0.2,0,0.3c0.2,0.1,0.3,0.4,0.5,0.1
        c0-0.1-0.1-0.2-0.1-0.4c0.6-0.1,0.3-0.7,0.3-1.1c0.1,0.1,0.3,0.2,0.3,0.2c0.1-0.2,0.1-0.5,0.3-0.6c0.3-1.1,0.7-2.1,1-3.2
        c0.1-0.3,0.1-0.6,0-1c0.1-0.1,0.2-0.2,0.3-0.3c-0.1-0.2-0.2-0.4-0.3-0.5l0.3,0.1c-0.2-0.3-0.1-0.6,0-0.8c0.1,0,0.2,0.1,0.3,0.1
        l0-0.1c-0.1,0-0.2,0-0.2,0c0-0.2,0.1-0.4,0.3-0.6c-0.3,0-0.4,0-0.2-0.2c0-0.1-0.1-0.2-0.1-0.3l0.2,0c0-0.3,0-0.5-0.1-0.8l0.3,0.1
        c0-0.1-0.1-0.4-0.2-0.5c0.2-0.1,0.3-0.3,0.2-0.6l-0.2,0.1c0,0,0-0.1,0-0.2l0.2,0c0-0.1-0.1-0.2-0.1-0.3l0.2,0
        c-0.1-0.9,0.2-1.9,0.3-2.8c0-0.8,0.2-1.5,0.2-2.3c0.1-0.4,0-0.8,0.2-1.2c-0.1-0.7,0.3-1.3,0.2-2c0,0.1,0.1,0.2,0.2,0.4
        c-0.2,0.4-0.1,0.9-0.2,1.3l0.2,0.1l-0.2,0c0,0.1,0,0.2,0,0.2c0,0-0.1,0.1-0.1,0.1c0,0.9,0.1,1.8-0.1,2.6c0.2,1,0.1,2,0.1,2.9
        c0.1,0.6,0.1,1.2,0.1,1.7c0,0.2-0.1,0.4-0.1,0.6c0.3,0.6,0,1.2,0.1,1.7c0.1,0.4-0.2,0.9,0.1,1.3c0,0.7,0.3,1.4,0.1,2.1
        c0.2,0.2,0.2,0.6,0.1,0.9c0.2,0.5,0,1,0.2,1.4c-0.2,0.3,0,0.7-0.1,1.1c-0.1,0.5,0,1,0,1.4c0.3,0.3,0.8,0.3,1.2,0.3
        c0.2,0.2,0.3,0.3,0.1,0.6c0.1,0.1,0.3,0.1,0.4,0.2c0-0.1-0.1-0.3-0.1-0.4c0.3,0.1,0.6,0.2,0.9,0.4l-0.1-0.3c0.2,0,0.6-0.2,0.6,0.2
        c-0.2,0.1-0.2,0.2-0.3,0.4c0.3,0,0.5,0,0.8,0c0.1,0.1,0.2,0.3,0.4,0.5c0-0.1,0.1-0.2,0.1-0.3c0,0-0.1-0.1-0.2-0.1
        c0.1-0.4,0.6,0,0.9-0.2c0,0.1,0,0.2,0,0.2c0.3-0.1,0.4,0.3,0.1,0.3c-0.1-0.2-0.3-0.4-0.6-0.3c0,0.1,0.1,0.4,0.1,0.5l-0.3-0.1
        c0,0,0,0.1,0,0.1c0.1,0,0.3,0,0.4,0.1c0,0,0.1-0.1,0.1-0.2c0.2,0.1,0.4,0.2,0.6,0.2c0-0.1,0.1-0.3,0.1-0.4c0.2,0.1,0.3,0.2,0.5,0.3
        c0-0.1,0-0.3,0-0.5c0.2,0.1,0.3,0.3,0.2,0.6c0.1,0,0.3,0,0.5,0c0-0.1-0.1-0.3-0.1-0.4l0.1,0c0.1,0.2,0.2,0.3,0.4,0.4
        c-0.1,0-0.4,0.1-0.5,0.1c0.1,0.2,0.2,0.3,0.3,0.5l0.1,0c-0.3-0.4,0.3-0.6,0.6-0.5c-0.1,0.2-0.2,0.3-0.3,0.5c0.1-0.1,0.5,0,0.4-0.3
        l0-0.2c0.2,0.1,0.4,0.7,0.7,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0,0.3,0,0.4,0c0,0.1-0.1,0.3-0.1,0.4c0.3-0.1,0.7,0,1-0.2
        c0,0.1-0.1,0.3-0.1,0.4c0.1,0,0.2,0.1,0.3,0.1l-0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.2l0.1,0.3c0.2-0.1,0.4-0.1,0.5,0
        c-0.2,0-0.4,0.2-0.5,0.4c0.2,0,0.4-0.1,0.5-0.3c0.4,0.1,0.8,0.8,1.2,0.3c0,0.1,0.1,0.2,0.2,0.3c0.5-0.1,0.2,0.5,0.6,0.6
        c0.2-0.3,0.4-0.2,0.6,0c0.1-0.1,0.2-0.2,0.2-0.3c0.2,0.1,0.3,0.1,0.5,0.2l0.1,0c0,0.1,0,0.3-0.1,0.4c0.1-0.1,0.3-0.1,0.4-0.2
        c0.1,0.1,0.3,0.2,0.4,0.3c0.2,0,0.3-0.2,0.5-0.1c0.1,0.2-0.2,0.4-0.1,0.6c0.1,0.1,0.3,0.1,0.4,0.2c-0.1-0.1-0.2-0.3-0.2-0.4
        c0.1-0.1,0.3-0.2,0.4-0.2c0,0.1,0,0.3,0,0.5c0.2,0.2,0.4,0.3,0.7,0.4c-0.2-0.3-0.4-0.5-0.5-0.8c0.3,0.3,0.7,0.6,1,0.9
        c-0.1,0.1-0.1,0.3-0.1,0.4c0.1-0.1,0.2-0.2,0.4-0.3c-0.2-0.2-0.2-0.4,0-0.6c0,0.2,0.2,0.4,0.4,0.5c0,0.2,0.1,0.3,0.3,0.3
        c-0.1-0.2-0.1-0.3-0.2-0.5c0.2,0.1,0.3,0.1,0.5,0.2c-0.1,0.1-0.1,0.2-0.2,0.4c0.2,0.1,0.4,0.2,0.6,0.4c0-0.1-0.1-0.2-0.1-0.3
        c0.2,0,0.4-0.1,0.7-0.1c0,0.1,0,0.2,0,0.3c0.1,0,0.3,0.1,0.4,0.2c-0.1-0.2-0.2-0.4-0.3-0.5c0.3,0.1,0.5,0.3,0.8,0.4l0-0.3
        c0.1,0,0.3,0.1,0.4,0.2c-0.2,0-0.3,0.2-0.4,0.4c-0.1,0-0.3-0.1-0.4-0.1c0.1,0.5,0.6,0.2,0.8,0c0.2,0.2,0.4,0.5,0.7,0.6
        c0,0.1-0.1,0.2-0.1,0.3l0.1,0c0.1-0.3,0.6-0.4,0.7,0c0.1,0,0.2-0.1,0.2-0.1c-0.1-0.1-0.1-0.3-0.1-0.4c0.1,0,0.3,0,0.4,0
        c0,0.1-0.1,0.2-0.2,0.3c0,0.1,0,0.4,0,0.5c0.2-0.1,0.3-0.2,0.4-0.4c0.1,0.2,0.2,0.3,0.3,0.5c0.2-0.2,0.6-0.3,0.7,0
        c0.3,0.5,0.9,0.7,1.3,0.4c0.2,0,0.3,0.1,0.5,0.1c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.3,0-0.4,0c0,0.1,0.1,0.2,0.1,0.3
        c0.2-0.1,0.3-0.2,0.5-0.4c0.1,0.1,0.2,0.2,0.2,0.3c0.4,0,0.8-0.2,1.1,0c0,0,0-0.1,0.1-0.2c0.1,0.2,0.2,0.3,0.4,0.2
        c0.1,0.2,0.2,0.4,0.4,0.6c0.1-0.1,0.3-0.1,0.4-0.2c0.1,0.3,0.2,0.4,0.5,0.2c0.1,0.4,1.2-0.1,0.6,0.6c0.3,0.1,0.5,0,0.8-0.1
        c0,0.3,0.4,0.3,0.5,0.4c-0.1-0.2-0.1-0.3-0.1-0.5c0.2,0.1,0.5,0.1,0.6,0.3c0.1,0,0.3,0,0.4,0c-0.2,0.2-0.4,0.2-0.6,0.2
        c-0.1,0.1-0.2,0.2-0.2,0.2c0.3-0.2,0.8,0.1,1-0.1c0.3,0.1,0.7,0.3,1.1,0.1c0,0.1-0.1,0.2-0.2,0.3c0,0,0,0.1,0,0.2
        c0.2-0.1,0.4-0.2,0.6-0.2c0,0.1,0,0.3,0,0.4c0.2-0.1,0.3-0.2,0.5-0.4c0,0.1-0.1,0.3-0.1,0.4c0.1,0,0.4-0.1,0.5-0.2
        c0,0.1,0.1,0.2,0.1,0.3c0.3,0.1,0.5,0.2,0.8,0.3c0.2-0.2,0.1-0.5,0.1-0.8c0.3,0.1,0.1,0.4,0.2,0.7c0.1,0.2,0.2,0.3,0.3,0.5
        c0,0,0.1,0,0.1,0c-0.2-0.2-0.2-0.4-0.2-0.6l0.2,0c0,0,0.1-0.1,0.2-0.1c0.3,0,0,0.3,0.1,0.5c0.1-0.1,0.2-0.2,0.3-0.3
        c0.4,0,0.8,0.2,1,0.6c0.1,0,0.3,0.1,0.3,0.1c0.3-0.2,0.6,0.1,0.7,0.4c0-0.1,0.1-0.2,0.2-0.2c-0.3,0-0.5-0.1-0.3-0.4
        c0.4-0.1,0.4,0.3,0.6,0.5c0-0.1,0.1-0.2,0.1-0.3c0.2,0.1,0.3,0.3,0.5,0.4c0.2,0,0.4,0,0.6-0.1c-0.1,0.5,0.4,0.3,0.7,0.4
        c0.2,0.1,0.4-0.1,0.6-0.1c0.1,0.3,0.3,0.6,0.5,0.2c0.1,0,0.2,0.1,0.2,0.2c0.2-0.1,0.5-0.2,0.5,0.1c0.1,0,0.2-0.1,0.2-0.1
        c0,0.2,0,0.3,0,0.5c0,0,0.1,0,0.2,0c-0.1-0.1-0.1-0.3-0.2-0.4c0.3-0.1,0.5,0.2,0.6,0.4c0.3-0.1,0.5-0.1,0.8-0.2
        c0,0.3,0.2,0.3,0.4,0.4c0-0.1,0-0.3,0-0.4c0.2,0.4,0.6,0.1,1,0c0.3,0.4,0.8,0.6,1.3,0.4c0.1,0.2,0.3,0.4,0.6,0.4
        c0.1-0.1,0.2-0.2,0.3-0.3c0.3,0,0.6,0,0.9,0c0.3,0.1,0.7,0.2,1.1,0.1c0.1,0.2,0.2,0.2,0.3,0.1c0.2,0,0.3,0.1,0.5,0.1
        c0.1-0.2,0.2-0.5,0.5-0.5c0.2,0.1,0.5,0.1,0.8,0.1l-0.1,0.2c0.3-0.1,0.6-0.2,0.9-0.2c0.1,0.1,0.2,0.3,0.4,0.4
        c0.1-0.1,0.3-0.1,0.5-0.2c-0.1,0-0.4,0-0.5,0c0.1-0.2,0.2-0.4,0.4-0.5c0.1,0.2,0.2,0.4,0.4,0.4c-0.1-0.2-0.1-0.3-0.2-0.5
        c0.2,0,0.3,0,0.5,0c0.2,0.3,0.5,0.3,0.8,0.4c0-0.1,0-0.2,0-0.3c-0.2-0.1-0.7,0.1-0.4-0.3c0.2,0.1,0.4,0.2,0.5,0.3c0-0.2,0-0.3,0-0.5
        c0.4,0.1,0.7,0.2,1.1,0.3l-0.2-0.4c0.1,0.1,0.3,0.3,0.4,0.3c0.2-0.2,0.4-0.4,0.6-0.6c0,0.1,0,0.3,0,0.4l0.4-0.1
        c-0.1-0.2-0.2-0.3-0.3-0.5c0.1,0,0.3,0.1,0.5,0.1c0.1,0,0.2-0.1,0.2-0.2c0.1,0.1,0.2,0.2,0.2,0.2c-0.1-0.2-0.1-0.3-0.2-0.5
        c0.1,0,0.3,0.1,0.4,0.1c0,0.1,0,0.2,0,0.3c0.2-0.1,0.3-0.3,0.3-0.5c0.3,0,0.6-0.1,0.8-0.4c0.1,0,0.3,0,0.4,0c0,0.2-0.1,0.4-0.1,0.6
        c0.3-0.4,0.3-1,0.8-1.2c0.2,0.1,0.3,0.1,0.5,0.2l0-0.2c0.2,0,0.4-0.1,0.6-0.1c0-0.3,0.3-0.4,0.6-0.2c0.1-0.3,0.6-0.3,0.6-0.6
        c0.2,0,0.3,0,0.5,0.1c0.1,0.2,0.2,0.4,0.4,0.5c0-0.2-0.1-0.3-0.2-0.5l0.1,0.1c-0.1-0.1-0.2-0.2-0.3-0.3l0.3-0.1
        c-0.1-0.2,0-0.4,0.2-0.4c0.1,0.1,0.2,0.3,0.3,0.3c0-0.1,0-0.4-0.1-0.5c0.1,0,0.2,0.1,0.3,0.1c0-0.4,0.3-0.3,0.6-0.3
        c0.1-0.2,0.1-0.3,0.3-0.3l0-0.1c0.2,0,0.5,0.1,0.5-0.2c0.1,0,0.2-0.1,0.3-0.2c0.3,0.1,0.4-0.1,0.5-0.3l0.1,0l0-0.1
        c0.8-0.2,1.3-0.9,2-1.2c-0.2,0.6-0.7,1-1.2,1.4c-0.9,0.8-1.2,2.1-2.2,2.9c-0.2,0.6-0.6,1-1,1.5c-0.1,0.2-0.4,0.2-0.6,0.3
        c-0.1,0.5-0.4,1-0.9,1.1c-0.3,0.6-0.9,0.8-1.3,1.1c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0.1-0.3,0.1-0.4,0.2c-0.4,0.7-1.4,0.3-1.9,0.9
        c-0.3,0-0.5,0-0.7,0c-0.4,0.4-1.1,0.3-1.4,0.8h-0.1c0-0.1,0-0.2,0.1-0.3c-0.6,0.3-1.3,0.2-2,0.5c-0.6-0.3-1.3,0.2-1.9,0
        c-0.1,0.1-0.3,0.3-0.4,0.4l0-0.2c-0.7,0.2-1.4-0.1-2,0c-0.3,0-0.6,0-0.9,0c-0.5,0.1-1-0.1-1.5-0.1c-0.5-0.1-1.1-0.2-1.6-0.5
        c-0.4-0.2-0.9-0.1-1.3,0.1c-0.1,0.1-0.2,0.2-0.4,0.2c0,0.1,0,0.3,0,0.4l-0.4-0.1c0.2,0.1,0.4,0.3,0.5,0.5c0-0.2-0.2-0.4-0.1-0.6
        c0.2-0.2,0.6-0.1,0.4,0.3c0.2,0.2,0.4-0.2,0.5-0.3c-0.1,0-0.4-0.1-0.6-0.1c0.2-0.3,0.6-0.1,0.9-0.1c-0.1,0.1-0.2,0.2-0.3,0.2
        c0.1,0,0.3-0.1,0.4-0.1c0,0.1,0,0.3-0.1,0.4l0.2,0.1c0-0.1,0-0.2,0-0.3c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2-0.1,0.4-0.1
        c-0.1,0.2-0.5,0.6,0,0.7c0-0.1,0.1-0.3,0.1-0.4c0.5-0.2,0.1,0.4,0.2,0.5c0.3,0,0.3-0.2,0.2-0.4c0.4,0.1,0.8,0,1.2,0.2
        c0,0.1,0,0.3,0,0.4c0.2-0.3,0.5-0.3,0.8-0.3c0.1,0.2,0.3,0.1,0.4,0.1c0.3,0.1,0.7,0.2,1,0.1c0.1,0,0.2,0.1,0.3,0.2
        c0.3-0.2,0.6-0.1,0.8,0.1c0.1-0.1,0.3-0.3,0.5-0.4c0.1,0.2,0.2,0.3,0.3,0.5c0-0.1,0-0.3,0-0.3c0.3,0.1,0.6-0.1,1-0.2
        c0.6,0.1,1.2-0.3,1.8-0.1c0-0.2,0.2-0.4,0.4-0.2c0.3,0.2,0.7,0,0.8-0.3c0.2,0.1,0.4,0.1,0.5-0.2c0.1,0,0.2,0.1,0.2,0.2
        c0-0.1,0.1-0.2,0.2-0.3c0.3,0.1,0.5-0.2,0.8,0c0.4-0.2,0.8-0.4,1.2-0.7c0.1,0,0.2,0.1,0.3,0.1c0.3-0.2,0.7-0.5,1.1-0.4
        c0.6,0,0.7-0.7,1.2-0.8c-0.1,0.2-0.2,0.4-0.3,0.5c0.2,0,0.3-0.3,0.5-0.4c0.3-0.2,0.7-0.4,1-0.6c0.1,0.1,0.2,0.2,0.2,0.2
        c0.1-0.2,0.2-0.4,0.3-0.6c0.3-0.1,0.5-0.3,0.5-0.6c0.1,0.2,0.1,0.3,0.2,0.5c0.1,0,0.2-0.1,0.3-0.1c-0.2-0.2-0.3-0.3-0.5-0.5
        c0.5,0,0.7-0.6,1.1-0.4c0.1-0.2,0.1-0.4,0.2-0.6c0,0.1,0.1,0.3,0.2,0.4c0.2-0.2,0.4-0.4,0.3-0.7c0.1,0,0.2,0,0.3,0
        c0-0.1,0-0.3-0.1-0.3l0.1,0c0,0.1,0,0.3,0,0.4c0.2-0.2,0.4-0.4,0.1-0.5c0.3-0.2,0.6-0.3,0.5-0.7c0.1,0.1,0.2,0.3,0.3,0.4
        c0-0.1,0.1-0.4,0.1-0.5l-0.3,0c0.1-0.2,0.4-0.1,0.6-0.1c0-0.1,0.1-0.3,0.1-0.5c0.1,0,0.2,0.1,0.3,0.1c0-0.2,0.1-0.4,0.2-0.6
        c-0.1,0.4,0.3,0.4,0.3,0.1c0.2-0.1,0.3-0.3,0.5-0.5c0.3-0.1,0.7,0.1,0.8-0.3c-0.1,0-0.3,0-0.4,0c0.1-0.1,0.3-0.3,0.5-0.1
        c0.1,0.1,0.3-0.1,0.4-0.1c0-0.1,0.1-0.2,0.1-0.3c0.1,0,0.3,0,0.4,0c0-0.1,0-0.2-0.1-0.3c0.2,0,0.3-0.1,0.5-0.1c0-0.1,0-0.2,0.1-0.3
        c0.1-0.1,0.2-0.1,0.4-0.2c0.1-0.2,0.1-0.3,0.2-0.5c0-0.1-0.1-0.2-0.1-0.3c0.3,0,0.6,0,0.9,0c0-0.1-0.1-0.3-0.2-0.4l-0.2,0
        c0.1-0.4,0.5,0,0.7-0.3c0.4-0.2,1.1-0.4,1-1c0.1,0.1,0.3,0.3,0.4,0.4c0.1-0.1,0.2-0.2,0.4-0.2c-0.2-0.1-0.3-0.2-0.5-0.3
        c0.1,0,0.3-0.1,0.4-0.2c0.1,0,0.2,0.1,0.2,0.2c0.1-0.2,0.2-0.3,0.3-0.4c0.2,0.1,0.4,0.2,0.6,0.3c0,0,0-0.1,0-0.2
        c-0.3,0.1-0.5-0.5-0.1-0.4c0.1,0,0.2,0,0.3,0c0-0.1-0.1-0.2-0.1-0.2c0.1,0,0.3,0.1,0.3,0.1c0.1-0.1,0.3-0.1,0.3-0.3
        c0.4,0.2,0.6-0.4,1-0.3c0.3,0.1,0.5-0.3,0.8-0.4c0,0.1,0,0.4,0,0.5c0.1-0.1,0.1-0.3,0.2-0.4c0.4,0,0.8-0.1,1.3-0.1
        c-0.2-0.3,0.2-0.1,0.3-0.2c0.8,0.1,1.5-0.5,2.2-0.6c0.1,0,0.2,0.1,0.2,0.1c0.4-0.3,1-0.1,1.5-0.2c0.1,0.1,0.2,0.2,0.2,0.2
        c0.2-0.1,0.4-0.2,0.5-0.2c0.1,0,0.2,0.1,0.3,0.1c0.8,0,1.5,0.5,2.3,0.6c0.1,0.2,0.3,0.4,0.6,0.3c0.3,0.2,0.6,0.4,0.9,0.6
        c0.6,0.1,0.9,0.7,1.5,0.7c0.1,0.3,0.4,0.4,0.7,0.4c0.2,0.4,0.6,0.5,1,0.5c0,0.2,0.1,0.3,0.2,0.5c0.1,0,0.3,0,0.4,0
        c0.4,0.2,0.6,0.7,1,0.9c0.6,0.3,1,0.9,1.6,1.3c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0,0.2,0,0.3,0c0.2,0.6,1,0.3,1.2,0.9
        c0.4,0.2,0.8,0.3,1,0.7c0.6,0.2,1,0.7,1.6,1c0.3,0.3,0.6,0.5,1,0.6c0.3,0.4,0.9,0.6,1.3,1c0.3,0.4,0.9,0.5,1.2,0.9
        c0.3,0.3,0.9,0.3,0.9,0.8c0.4-0.1,0.8,0.2,1,0.6c0.8,0.3,1.4,0.9,2,1.4c0.6,0.4,1.1,0.8,1.6,1.3c1.1,0.5,1.8,1.4,2.7,2.1
        c0.4,0.5,0.9,0.8,1.1,1.4c0.6,0,1,0.6,1.5,0.9c0.3,0.4,0.9,0.6,0.9,1.1c0,0.4,0.5,0.3,0.7,0.6c0.3,0.4,1,0.6,0.9,1.3
        c0.1,0,0.3,0.1,0.4,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0.2,0.1,0.4,0.2,0.6,0.3c0.3,0.2,0.1,0.7,0.5,0.8c0.8,0.3,1.1,1.1,1.5,1.8
        c0.3,0.2,0.6,0.4,0.7,0.7c0.4,0.4,0.8,0.8,0.8,1.4c0.5,0.4,0.9,0.9,1.2,1.4c0.1,0.6,0.7,0.9,1,1.4c0.2,0.3,0.3,0.6,0.3,0.9
        c0.4,0.1,0.5,0.4,0.6,0.7c0.4,0.1,0.4,0.6,0.5,0.9c0.1,0.2,0.1,0.4,0.2,0.7c0.3,0.1,0.6,0.2,0.9,0.4c0,0.2-0.1,0.4-0.1,0.6
        c0.6,0.4,1,1.2,1.1,1.9c0.3,0.4,0.3,1,0.7,1.3c0.3,0.8,0.6,1.5,0.9,2.3c0,0.1,0,0.3,0,0.4c0.6,0.6,0.4,1.5,0.9,2.1
        c-0.1,0.4,0,0.8,0.3,1c0,0.1-0.1,0.2-0.1,0.3c0.2,0.6,0.5,1.1,0.6,1.7c0.1,0.2,0,0.5,0.3,0.6c-0.3,0.3-0.1,0.7,0.1,1
        c0,0.3,0,0.7,0.1,1c-0.1,0.5,0.1,1,0,1.5c0.1,0.2,0.2,0.3,0.3,0.5c-0.1,0.4,0.1,0.7,0.3,1.1c-0.1,0-0.2,0.1-0.3,0.1
        c0,0.4,0.1,0.8,0.3,1.1c-0.2,0.5,0.2,1-0.1,1.5c0,0.2,0.2,0.2,0.3,0.4c-0.1,0-0.2,0-0.3,0c0.1,0.6,0.3,1.2,0.2,1.9
        c0.1,0.4,0.1,0.8-0.3,1.1c-0.3,0.3,0.2,0.6,0.2,1c-0.4,0.3-0.2,0.9-0.2,1.3c0.1,0,0.3,0.1,0.4,0.1c-0.3,0.4-0.6,0.8-0.8,1.2
        c0.1,0,0.2,0,0.3,0c-0.1,0.1-0.1,0.3-0.1,0.5c-0.1,0.2-0.2,0.4-0.3,0.6c0.2,0.5-0.2,0.9-0.3,1.3c-0.1,0.3-0.3,0.5-0.3,0.8
        c-0.1,0.1-0.2,0.1-0.3,0.2c0,0.2,0,0.5-0.1,0.7c-0.1,0.2-0.4,0.5-0.3,0.8c-0.1,0-0.2,0-0.2,0c0.1,0.4-0.2,0.7-0.6,0.8
        c0,0.2,0.2,0.5,0,0.7c-0.4,0.4-0.6,0.9-0.9,1.4c0.1,0,0.4,0,0.5,0c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0.2,0.2,0.2,0.4,0.2
        c0,0.1,0,0.2,0,0.3c0.2-0.1,0.5-0.2,0.7,0c0.1-0.2,0.2-0.3,0.4-0.3c0,0.4,0.5,0.3,0.7,0.5l-0.1-0.3c0.2,0.1,0.4,0.3,0.4,0.6
        c0.2,0,0.4,0,0.6,0c0,0.2,0.2,0.3,0.4,0.3c-0.1,0.3,0.3,0.5,0.5,0.4c-0.1-0.1-0.2-0.3-0.3-0.4c0.2,0,0.5,0,0.7,0
        c0,0.1-0.1,0.2-0.2,0.3c0.3-0.1,0.6,0.2,1,0.1c0.3,0.3,0.7,0.5,1.1,0.4c0.1,0.1,0.1,0.3,0.2,0.4c0.5-0.2,0.9,0.2,1.3,0.4
        c0.3-0.3,0.4,0.1,0.6,0.3c0.3,0.1,0.6,0.2,0.9,0.2l0,0.1l-0.3,0c0.1,0.3,0.5,0.3,0.8,0.4c0.2-0.4,0.7,0.2,0.3,0.3
        c0.1,0.1,0.2,0.2,0.4,0.3c-0.1-0.2-0.1-0.3-0.2-0.4c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0.1,0.3,0.2c0,0.1-0.1,0.2-0.2,0.3
        c0.4-0.1,0.9,0,1.2,0.3c0.1,0.1,0.3,0.2,0.5,0.2c0.7,0.4,1.2,1.1,2,1.5c0.3,0.1,0.4,0.4,0.7,0.5c0.6,0.3,1.1,0.8,1.5,1.2
        c0.1,0,0.2,0.1,0.3,0.1c0.2,0.2,0.4,0.4,0.6,0.6c0.4,0.1,0.5,0.5,0.8,0.7c0.4,0.2,0.5,0.6,0.8,0.9c0.3,0.3,0.7,0.6,0.9,1
        c0.4,0.2,0.7,0.5,0.8,0.9c0.5,0.1,0.5,0.7,0.9,0.9c0.3,0.3,0.2,0.9,0.7,1c0.4,0.1,0.4,0.6,0.7,0.9c0.2,0.1,0.4,0.4,0.4,0.6
        c0,0.3,0.3,0.4,0.5,0.6c0.4,0.4,0.4,1.1,0.8,1.5c0.3,0.4,0.4,1,0.5,1.5c0.2,0.2,0.4,0.3,0.6,0.5c0.1,0.5,0.5,0.9,0.5,1.4
        c0.5,0.3,0.3,0.9,0.6,1.3c0.1,0.5,0.5,0.9,0.4,1.4c0,0.4,0.3,0.7,0.5,1.1c0.2,0.3,0.2,0.8,0.4,1.2c0.2,0.3,0.2,0.6,0.3,0.9
        c0,0.1-0.1,0.2-0.1,0.2c0.2,0.4,0.3,0.8,0.4,1.2c0,0.7,0,1.4,0.3,2c0.1,0.1,0.2,0.1,0.4,0.2c0.2,0.3,0.3,0.7,0.5,1
        c0.1,0,0.3,0,0.4,0c0.1,0.2,0.1,0.6,0.4,0.7c0.1,0.2,0.2,0.3,0.4,0.3c0,0.1,0,0.3,0,0.3c0.2,0,0.4,0,0.3,0.2c0.4,0.1,0.4,0.7,0.7,1
        c0.5,0.5,0.8,1.1,1.2,1.6c0.2,0.1,0.2,0.3,0.1,0.5c0.3,0,0.3,0.3,0.4,0.5c0.1,0.3,0.2,0.5,0.5,0.7c0,0.1-0.1,0.2-0.1,0.3
        c0.2,0.2,0.3,0.4,0.5,0.6c0.2,0.8,0.6,1.5,0.8,2.3c0.3,0.6,0.2,1.2,0.6,1.7c0.1,0.8,0.5,1.6,0.5,2.4c0.1,0.6,0.5,1.1,0.2,1.7
        c-0.1,0.2,0.1,0.4,0.2,0.6c-0.1,0.7,0.3,1.4,0,2.1c0.4,0.4,0.3,0.9,0.2,1.4c0.1,0.4,0.1,0.8,0,1.1c0.2,0.4,0,0.7-0.2,1
        c0.2,0.5,0,1.1,0,1.6c0,0.8-0.3,1.5-0.4,2.3c0,0.2-0.1,0.4-0.2,0.6c0.2,0.5-0.2,0.8-0.3,1.3c-0.2,0.5-0.2,1.1-0.6,1.6
        c0,0.1,0.1,0.3,0,0.4c-0.2,0.1-0.3,0.4-0.4,0.6c0,0,0.1,0.2,0.1,0.2c-0.2,0.2-0.3,0.4-0.3,0.7c-0.2,0.1-0.4,0.2-0.4,0.5
        c0,0.2-0.1,0.5-0.1,0.7c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0,0.3,0,0.4c-0.3,0.4-0.8,0.8-0.9,1.4c-0.2,0.2-0.3,0.5-0.6,0.4
        c0,0.1,0.1,0.4,0.1,0.5c-0.3,0-0.4,0.2-0.6,0.4c-0.3,0.5-0.7,1-0.8,1.7c-0.7-0.1-0.7,1-1.4,0.8c0,0.5-0.2,1.2-0.8,1.1
        c0,0.1,0.1,0.2,0.1,0.2c-0.2,0.1-0.4,0.1-0.5,0.3c0.5,0.2-0.1,0.2-0.1,0.4c-0.1,0.4-0.4,0.4-0.7,0.6c-0.2,0.2-0.4,0.5-0.6,0.7
        c-0.2,0.1-0.4,0.1-0.6,0.2c-0.1,0.4-0.2,0.9-0.7,0.9c-0.2,0.2-0.4,0.3-0.7,0.5c0,0.1,0.1,0.2,0.1,0.2c-0.3,0-0.6,0.2-0.8,0.4
        c-0.1,0-0.2-0.1-0.3-0.1c-0.3,0.1-0.5,0.3-0.7,0.5c0,0,0.1,0.1,0.1,0.2c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0-0.2-0.1-0.2-0.2
        c-0.2,0.1-0.4,0.1-0.5,0.3c-0.3,0.1-0.7,0.3-0.6,0.7c-0.5-0.2-0.8,0.4-1.3,0.4c-0.1,0.1-0.2,0.3-0.2,0.4c-0.6,0.1-1,0.4-1.6,0.5
        c-0.2,0.1-0.3,0.3-0.4,0.4c-0.7,0.1-1.2,0.7-1.9,0.5c-0.4,0.4-1,0.3-1.4,0.7c-0.5,0.3-1.1,0.1-1.6,0.5c-0.1,0-0.2,0-0.3,0
        c-0.2,0.2-0.4,0.5-0.7,0.4c-0.4-0.2-0.8,0-1.1,0.2c-0.1-0.1-0.2-0.2-0.3-0.2c0,0.1-0.1,0.2-0.1,0.3c-0.3,0-0.6,0.1-0.8,0.3
        c0-0.5-0.4,0-0.7,0.1c-0.6-0.4-1,0.5-1.6,0c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2,0-0.4,0-0.5,0.1c-0.2,0-0.4-0.2-0.5,0c-0.2,0-0.4,0-0.6,0
        l0.1,0.3c-0.1-0.1-0.2-0.2-0.2-0.3c-0.5,0.2-1.1,0-1.4,0.3c0,0-0.1-0.1-0.2-0.1c-0.8,0-1.5,0.2-2.3,0c0,0.1-0.2,0.2-0.2,0.3
        c-0.3-0.2-0.8-0.5-1-0.2c0-0.1,0-0.2,0-0.2c-0.4,0.1-0.8,0.2-1.2,0.1c-0.6,0.2-1.3-0.1-1.8,0.3c0.1-0.1,0.2-0.3,0.3-0.4
        c-0.3-0.1-0.5-0.1-0.7-0.1c-0.1,0.1-0.3,0.2-0.4,0.2c0-0.1,0.1-0.3,0.1-0.4c-0.1,0-0.3,0.1-0.3,0.1c-0.1,0-0.2,0.1-0.2,0.2
        c-0.3-0.1-0.6-0.2-0.8-0.2c0,0.1-0.1,0.2-0.1,0.2c-0.4-0.1-0.7-0.6-1.1-0.4c0,0.1,0.1,0.4,0.2,0.5c-0.3-0.1-0.6-0.3-0.9-0.6
        c0,0-0.1,0.1-0.2,0.2l0,0.2c-0.1-0.2-0.2-0.4-0.3-0.6c-0.3,0-0.7,0.1-1,0.1c0-0.1,0.1-0.3,0.1-0.4c-0.2,0.1-0.3,0.2-0.3,0.3
        c-0.3-0.1-0.6-0.1-0.9-0.2c-0.2-0.2-0.4-0.1-0.7-0.2c-0.3-0.2-0.7-0.2-1.1-0.3c0-0.1,0-0.2,0-0.2c-0.2,0.1-0.6,0.2-0.8,0.3l0.4-0.5
        c-0.2,0-0.5,0.3-0.5-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c0-0.2-0.1-0.5-0.1-0.7c-0.4,0.1-0.3,0.4-0.1,0.6c-0.3-0.1-0.3-0.4-0.4-0.6
        c-0.3-0.1-0.5,0.1-0.8,0.3c0-0.1,0.1-0.2,0.2-0.3c-0.3,0-0.6-0.1-0.8-0.2c0,0.1-0.1,0.2-0.1,0.3c0-0.1,0-0.3,0-0.4
        c-0.6-0.2-1.3-0.2-1.6-0.8c-0.2,0-0.3,0-0.5,0c-0.2-0.1-0.3-0.5-0.6-0.4c-0.5,0.3-0.1-0.7-0.6-0.4c-0.2,0.1-0.4,0.2-0.7,0.1
        c0-0.1,0.1-0.3,0.1-0.4c-0.2,0-0.4,0.3-0.6,0.1c-0.4-0.3-1-0.3-1.3-0.7c0.1,0.3,0.2,0.5,0,0.8c0.1,0,0.3-0.1,0.4-0.2
        c0.2,0.2,0.6,0.6,0.2,0.9c0.1,0,0.2,0.1,0.2,0.2c0-0.1,0-0.3,0-0.4c0.3,0.2,0.5,0.4,0.6,0.7c0.1,0.2,0.3,0.3,0.6,0.3
        c0,0.1-0.1,0.2-0.1,0.3c0.2,0,0.3,0.2,0.3,0.3c0.4-0.1,0.2,0.5,0.3,0.7c0.1,0,0.3,0,0.4-0.1c0,0.1,0.1,0.3,0.1,0.4
        c0,0.3,0.4,0.1,0.4,0.4c0,0.1,0,0.2,0,0.3c0.3,0.1,0.5,0.3,0.5,0.6c0,0,0.1,0,0.2,0c0.2,0.3,0.3,0.7,0.6,1c0.2,0.2,0.1,0.4,0,0.6
        c0.5-0.1,0.5,0.5,0.6,0.8c0.1,0.2,0.3,0.4,0.2,0.6c0.2,0.1,0.5,0.1,0.5,0.4c0.2,0.5,0.5,1,0.6,1.5c0.1-0.1,0.2-0.1,0.3-0.2
        c0,0.1-0.1,0.3-0.1,0.4c0.6,0.4,0.7,1.2,1,1.8c0,0.1,0,0.2,0,0.3c0,0,0.1,0,0.1,0c0-0.1,0-0.3,0-0.3c0.3,0.4,0.4,0.9,0.5,1.4
        c0.1,0,0.2-0.1,0.2-0.2c0,0.2,0,0.3,0,0.5c0.3-0.1,0.3,0.2,0.4,0.4c0.2,0.3,0.1,0.8,0.4,1c-0.1,0.1-0.2,0.2-0.2,0.3
        c0.1,0,0.3-0.1,0.3-0.1c0,0.4,0.3,0.7,0.4,1.1c0.4,0.6,0.6,1.4,0.7,2.2c0.1,0,0.2,0,0.3,0c0,0.1-0.1,0.2-0.2,0.3
        c0.5,0.2,0.1,0.9,0.4,1.3c0.3,0.7,0.4,1.3,0.5,2.1c0,0,0.1,0.1,0.1,0.2c0,0.2,0,0.4,0.1,0.6c0.3,0.3,0.2,0.8,0.3,1.2
        c0.1,0.6,0.1,1.2,0.3,1.8c0.1,0.3,0.1,0.7,0.2,1c0,0.3,0,0.6-0.1,0.8c0.1-1.5-0.8-2.8-0.9-4.3c-0.1-0.1-0.2-0.2-0.2-0.2
        c-0.1-0.2-0.2-0.4-0.2-0.6c-0.1-0.2-0.1-0.5-0.1-0.7l-0.2,0c0-0.2-0.1-0.4-0.1-0.6c-0.1,0-0.2-0.1-0.2-0.2c0.1-0.5-0.5-0.8-0.3-1.3
        c-0.2-0.4-0.6-0.8-0.6-1.3c-0.2-0.3-0.3-0.6-0.5-0.8c0-0.4-0.4-0.6-0.5-0.9c-0.4-0.7-1-1.3-1.4-2c-0.3-0.5-0.7-0.8-0.9-1.4
        c-0.6-0.5-1.1-1.1-1.5-1.7c-0.2-0.3-0.5-0.3-0.6-0.6c-0.2-0.5-0.7-0.9-0.9-1.4c-0.5-0.3-0.9-0.7-1.2-1.2c-0.2-0.3-0.6-0.3-0.6-0.6
        c-0.1-0.2-0.1-0.4-0.3-0.6c-0.5-0.3-0.8-0.8-1.2-1.2c-0.7-0.6-0.8-1.6-1.5-2.2c-0.3-0.3-0.8-0.5-0.9-0.9c-0.1-0.4-0.6-0.5-0.8-0.8
        c-0.3-0.5-0.9-0.8-1.1-1.4c-0.6-0.3-0.8-0.9-1.4-1.2c-0.5-0.6-1-1.2-1.6-1.7c-0.4-0.4-0.5-1-1-1.2c-0.5-0.4-0.9-0.8-1.4-1.2
        c0-0.1-0.1-0.2-0.1-0.3c-0.3-0.1-0.6-0.3-0.8-0.5c0-0.1,0-0.3,0-0.4c-0.4,0-0.9-0.2-0.9-0.7c-0.5-0.3-1-0.8-1.5-1.1
        c-0.4-0.5-0.9-0.7-1.3-1.1c-0.4-0.4-0.9-0.5-1.2-1c-0.1-0.2-0.4-0.3-0.5-0.4c-0.3-0.1-0.3-0.7-0.7-0.7c-0.7-0.5-1.5-1-2.1-1.6
        c-0.3-0.2-0.5-0.5-0.8-0.6c-0.2-0.1-0.3-0.2-0.4-0.3c-0.7-0.3-1.3-0.9-1.9-1.4c-0.3-0.4-0.7-0.5-1-0.9c-0.3-0.4-0.9-0.3-1.1-0.7
        c0-0.1,0.1-0.2,0.1-0.3c-0.2,0-0.3,0.1-0.5,0.1c-0.3-0.3-0.7-0.6-1.1-0.8c-0.3-0.1-0.4-0.6-0.8-0.5c-0.2-0.2-0.3-0.4-0.5-0.6
        c-0.5-0.3-0.8-0.7-1.3-1.1c-0.4-0.4-1-0.7-1.4-1.1c-0.5-0.1-0.7-0.6-1.1-0.8c-0.5-0.1-0.6-0.8-1.1-0.7c-0.1-0.3-0.3-0.6-0.7-0.6
        c0.1-0.3-0.3-0.4-0.5-0.5c-0.3-0.1-0.5-0.5-0.8-0.6c-0.7-0.4-1.1-1.3-1.9-1.6c-0.6-0.3-1.1-0.8-1.5-1.2c-0.4-0.5-0.9-0.8-1.3-1.3
        c-0.3-0.4-0.9-0.6-1.1-1.1c-0.6-0.3-0.9-0.9-1.4-1.2c-0.5-0.2-0.8-0.7-1.1-1c-0.2-0.4-0.7-0.5-1-0.9c-0.5-0.2-0.8-0.6-1-1
        c-1-0.7-1.7-1.7-2.6-2.6c-0.7-0.4-1.1-1.1-1.6-1.7c-0.3-0.5-0.8-0.7-0.9-1.2c-0.3-0.3-0.9-0.5-0.9-1c-0.3-0.5-1-0.7-1.2-1.3
        c-0.1-0.5-0.6-0.7-0.7-1.2c-0.4-0.3-0.3-0.8-0.7-1.1c-0.2-0.6-0.5-1.1-1-1.5c-0.6-0.6-0.8-1.4-1.3-2.1c-0.2-0.2-0.6-0.4-0.1-0.5
        c-0.1,0-0.3,0-0.4,0c0-0.1,0-0.3,0.1-0.4c-0.2-0.1-0.3-0.1-0.5-0.1c0.4-0.1,0.1-0.3,0-0.5c0,0-0.1,0.1-0.2,0.1
        c-0.2-0.2-0.9-0.5-0.3-0.8c-0.1-0.1-0.1-0.1-0.2-0.2c0,0.1,0,0.2,0,0.3l-0.3,0.1c0-0.1,0-0.3,0-0.4c-0.1,0-0.2,0.1-0.3,0.1
        c0-0.1,0-0.3,0-0.4c-0.2,0-0.3,0.1-0.4,0.2c-0.2-0.3-0.4-0.6-0.7-0.8c-0.5-0.2-1-0.3-1.5-0.5c-0.4,0-0.8-0.2-1.2-0.3
        c-0.3,0-0.6-0.1-0.8-0.1c-0.9,0-1.9,0.4-2.6,1c-0.5,0.1-1,0.4-1,0.9c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0.2-0.2,0.3-0.4,0.5
        c-0.3,0.5-0.7,0.9-1.1,1.3c-0.5,0.3-0.5,1.1-1.2,1.1c0,0.2,0,0.3,0,0.5c-0.5,0.3-0.9,0.9-1.3,1.4c-0.2,0-0.3,0-0.5,0
        c0,0.2,0.2,0.3,0.1,0.5c-0.2,0.1-0.5,0.2-0.6,0.5c-0.1,0.4-0.5,0.5-0.6,0.9c-0.5,0.5-0.8,1.1-1.2,1.6c-0.4,0.5-0.4,1.1-0.8,1.6
        c-0.3,0.4-0.5,1-0.7,1.5c-0.2,0.1-0.4,0.2-0.5,0.3c0.1,0.1,0.1,0.1,0.2,0.2c0.1-0.2,0.3-0.2,0.5-0.3c0.1,0.1,0.2,0.2,0.3,0.3
        c0,0.1,0,0.3,0,0.3l0.2-0.2c0-0.1,0-0.3,0-0.3c0.1-0.1,0.3-0.4,0.4,0c0.1-0.1,0.2-0.2,0.4-0.3c0.1,0,0.3,0.1,0.4,0.1
        c0.1,0.5,0.5,0,0.3-0.3c0.3,0.4,0.6-0.1,0.9-0.2c-0.1,0.2-0.3,0.5-0.4,0.7c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0,0.2,0.1,0.3,0.1
        c0-0.2,0-0.3,0.1-0.5c0.1,0.1,0.2,0.2,0.3,0.2c0.1-0.1,0.3-0.2,0.5-0.2l-0.2-0.4c0.3,0.1,0.5,0.2,0.7,0.4c0.2-0.1,0.7-0.2,0.2-0.6
        l0.3,0.2c0.1-0.2,0.3-0.2,0.5-0.2c0.1-0.2,0.2-0.3,0.3-0.5l0.1,0c0,0.2,0,0.5,0,0.7c0.3-0.3,0.4-0.7,0.8-0.9c0,0.1,0,0.2,0.1,0.3
        c0.2-0.2,0.4-0.3,0.7-0.5c-0.1,0.2-0.1,0.3-0.2,0.5c0.2-0.1,0.3-0.2,0.5-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c0.2,0,0.5,0.1,0.6-0.2
        c0.1,0,0.2,0,0.2,0c0-0.2,0.1-0.4,0.3-0.4l0.1,0.3c-0.1,0.1-0.2,0.2-0.2,0.2c0.2,0.1,0.4-0.2,0.6-0.2c-0.1-0.2-0.7-0.3-0.2-0.5
        c0,0.5,0.6,0.1,0.9,0.1c-0.2-0.5,0.3-0.2,0.6-0.2c0-0.1,0-0.3-0.1-0.4c0.2,0.2,0.4,0.3,0.7,0.3c-0.1-0.1-0.2-0.3-0.2-0.4
        c0.1-0.1,0.3-0.1,0.4-0.2c0-0.1,0-0.3,0.1-0.4c0.3,0,0.7,0,1,0.1c-0.1-0.1-0.2-0.3-0.3-0.4c0.1-0.3,0.4-0.3,0.3,0.1
        c0.1,0,0.3-0.1,0.3-0.1c0.1-0.1,0.2-0.3,0.3-0.4c-0.3,0-0.6,0-0.6-0.4c0.1,0,0.2,0.1,0.3,0.2c0.1,0,0.3,0,0.4,0c0-0.2,0-0.3,0.1-0.5
        c0.2,0,0.4-0.1,0.6-0.2c0-0.2,0.1-0.3,0.2-0.4c0-0.2,0.1-0.4,0.2-0.6c0.4,0.1,0.4-0.3,0.6-0.5c0.2-0.4,0.3-0.8,0.6-1.1
        c0,0.7,0,1.5,0,2.2c0,0.1,0.1,0.2,0.1,0.2c-0.1,0.2-0.2,0.3,0,0.5c0.2,0.2,0,0.4,0,0.7c0.3,0.5-0.2,1.1,0,1.6
        c-0.2,0.4-0.6,0.7-0.7,1.1c-0.4,0.2-0.2,0.7-0.3,1.1c0.4,0.3,0.3,0.9,0.6,1.3c0.3,0.1,0.4,0.4,0.5,0.7c0.2,0.2,0.4,0.4,0.5,0.6
        c0.3,0.2,0.7,0.3,1,0.6c0.1-0.1,0.2-0.2,0.2-0.2c0,0.1,0.1,0.2,0.2,0.3c0.1-0.1,0.2-0.1,0.3-0.2c0,0.1,0,0.2,0,0.3
        c0.1,0,0.3,0,0.4-0.1c0.2-0.3,0.5-0.6,0.9-0.4c-0.1-0.1-0.2-0.2-0.3-0.3c0.3,0.2,0.3-0.2,0.4-0.4c0.1,0,0.2,0,0.3,0
        c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0.1-0.3,0.2-0.3c-0.4-0.1,0.1-0.6,0-0.9c-0.2-0.3-0.1-0.6-0.3-0.9c-0.2-0.4-0.5-0.8-0.4-1.2
        c0.4,0.8,1.1,1.4,1.5,2.2c0.2,0.1,0.4,0.3,0.5,0.5c0.8,0.8,1.4,1.9,2.3,2.6c0.9,0.8,1.7,1.6,2.6,2.4c0,0.1,0.1,0.3,0.1,0.4
        c0.3-0.2,0.8,0,0.8,0.4c0.7,0.2,1,0.8,1.4,1.3c0.5,0.2,0.7,0.6,1.1,0.9c0.2,0.4,0.5,0.6,0.9,0.8c-0.1,0.6,0.6,0.3,0.8,0.7
        c0.2,0.4,0.8,0.5,1,1c0.8,0.4,1.2,1.4,2.1,1.7c0.2,0.2,0.4,0.3,0.6,0.5c1.1,1,2.3,2,3.4,2.9c0,0.1,0.1,0.2,0.2,0.3
        c0.7,0.1,0.8,0.9,1.4,0.9c0,0.1,0.1,0.3,0.1,0.5c0.3,0.1,0.7,0.1,0.9,0.4c0.4,0.5,1,0.7,1.4,1.1c0.1,0.1,0.2,0.2,0.4,0.3
        c0.3,0.1,0.5,0.3,0.7,0.6c0.5,0.2,0.8,0.6,1.2,0.9c0.2,0.1,0.4,0.3,0.6,0.4c0.1,0,0.2,0.1,0.3,0.1c0.2,0.4,0.6,0.5,0.9,0.6
        c-0.2,0.6,0.6,0.3,0.8,0.7c0.2,0.4,0.7,0.5,0.9,0.9c1.1,0.7,2.1,1.4,3,2.4c0.4,0.2,0.6,0.7,1.1,0.8c0.6,0.4,1.1,0.8,1.7,1.3
        c0.4,0.4,0.7,0.8,1.2,1c0,0,0.1,0.1,0.1,0.2c0.6,0.3,1.1,0.8,1.5,1.3c0.2,0.4,0.7,0.5,0.9,0.9c0.4,0.1,0.6,0.6,1,0.7
        c0.2,0.1,0.3,0.3,0.4,0.5c0.2,0.1,0.5,0.2,0.6,0.5c0.1,0.3,0.5,0.5,0.7,0.8c0.4,0.4,0.8,0.8,1.3,1.2c0.4,0.3,0.5,0.8,0.9,1
        c0.5,0.4,1,0.9,1.5,1.3c0.3,0.3,0.4,0.7,0.7,1c0.2,0.1,0.4,0.4,0.5,0.6c0.3,0.2,0.5,0.4,0.6,0.7c0.4,0.3,0.7,0.7,1.1,1
        c0.4,0.4,0.8,0.9,1,1.4c0.2,0.4,0.7,0.5,0.8,0.9c0.2,0.4,0.6,0.7,0.9,1c0.3,0.2,0.3,0.6,0.4,0.9c0.5,0.4,1,0.8,1.2,1.4
        c0.4,0.6,0.8,1.1,1.2,1.6c0.1,0.5,0.8,0.6,0.7,1.2c0.4,0.3,0.4,0.8,0.7,1.1c0.3,0.4,0.5,0.8,0.8,1.2c-0.2,0.6,0.2,1,0.6,1.4
        c0.3,0.6,0.3,1.3,0.5,2c0.3,0.5,0.4,1.1,0.6,1.7c0,0.1,0,0.2,0,0.3c0.4,0.4,0.3,1,0.5,1.6c-0.1,0.2-0.1,0.5,0.1,0.7
        c0.2,0.3,0,0.7,0.3,1c0.1,0.4,0.1,0.7,0.1,1.1c0.1,0.3,0.2,0.6,0.2,0.9c0,0.1-0.1,0.2-0.1,0.3c0.1,0.1,0.1,0.3,0.2,0.4
        c0.3-0.1,0.5,0,0.7,0.2c0.3,0.1,0.8-0.1,0.9,0.3c0.2-0.1,0.4-0.1,0.6,0.1c0.2-0.4,0.6,0.2,0.9,0.2c0,0.1,0,0.3,0,0.3
        c0.1,0,0.2,0,0.3,0c-0.1-0.2-0.2-0.3-0.2-0.5c0.4,0.1,0.8,0.1,0.8,0.6c0.4-0.3,1.2-0.1,1.4,0.3c0.5-0.2,0.9,0.2,1.3,0.5
        c0.1-0.2,0.4-0.1,0.6-0.1c0,0.1-0.1,0.2-0.1,0.3c0.4-0.2,0.7,0,1.1,0.1c0,0.1,0.1,0.3,0.1,0.4c0.2,0,0.5-0.2,0.6,0.1
        c0.2,0.3,0.6,0.2,0.9,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.2-0.1,0.5,0,0.7,0.1c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2,0,0.3-0.1
        c0.3,0.2,0.6,0.4,0.9,0.6c0.1,0,0.2-0.1,0.2-0.2c0,0.1,0.1,0.3,0.1,0.4c0.2,0.1,0.3,0.2,0.5,0.3c0-0.1,0.1-0.2,0.1-0.3
        c0.2,0,0.3,0.3,0.6,0.4c0.4,0.1,0.7,0.3,1.1,0.4c0,0.1,0,0.2,0,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0-0.1-0.1-0.3-0.1-0.4l0.2,0
        c-0.1,0.3,0.2,0.5,0.4,0.3c0.3-0.2,0.2,0.3,0.4,0.4c0.2,0,0.3-0.1,0.5,0c0-0.4,0.4,0.1,0.6,0c0,0.2-0.1,0.4-0.1,0.5
        c0.1,0,0.3-0.1,0.4-0.1c0-0.1-0.1-0.3-0.2-0.3c0,0,0.1,0,0.1-0.1c0.1,0.1,0.2,0.2,0.3,0.3l0,0.2l0.2,0c0-0.1,0-0.2,0-0.2
        c0.1,0.2,0.2,0.3,0.3,0.5c0.3,0.1,0.9-0.1,0.6,0.4c0.2,0.1,0.3,0.1,0.5,0.2c0-0.1,0-0.3,0.1-0.4c0.2,0.3,0.3,0.6,0.4,0.9
        c0.1-0.7,0.7,0,0.5,0.4c0.4,0.2,0.6,0.7,0.7,1.1c0.2,0,0.5,0,0.7,0c0.2,0.3,0.4,0.5,0.4,0.8c0.4,0.3,0.8,0.7,1.2,1
        c0.2,0,0.4,0,0.5,0c0,0.1-0.1,0.3-0.1,0.4c0.2-0.1,0.2-0.2,0.2-0.4c0.2,0,0.4,0,0.6-0.1c0,0.1,0.1,0.2,0.1,0.3
        c0-0.1,0.1-0.3,0.2-0.5c0.3,0.1,0.6,0,0.9-0.2c0.1,0.3,0.7,0.7,0.8,0.2c-0.1,0-0.3,0-0.4,0c0,0,0-0.1,0-0.2c0.1,0,0.3,0,0.5,0
        c0.2,0.1,0.3,0.2,0.2,0.5c0.2,0.1,0.5,0,0.6,0.3c0,0.1-0.1,0.3-0.1,0.4c0.6,0.1,0.6,0.7,0.8,1.1c0.2,0.3,0.1,0.6,0,0.9
        c0.2,0.2,0.2,0.5,0.3,0.7l0.1-0.1l0,0.2c0.2,0,0.4,0,0.4,0.3c0.3-0.1,0.3,0,0.3,0.3c0.1-0.1,0.4-0.2,0.6-0.3
        c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.2-0.1-0.3-0.2l0-0.1l0.2,0.1c0-0.1-0.1-0.2-0.1-0.3c0.1-0.1,0.2-0.2,0.3-0.2
        c0.1,0,0.2,0.1,0.3,0.1c0-0.1,0.1-0.2,0.2-0.3c0.3,0.3,0.8,0.3,1.1,0.7c0.5,0.5,1.1,0.7,1.7,1c0.4,0.1,0.5,0.7,1,0.8
        c0.1,0,0.3,0.1,0.4,0.1c-0.1-0.2-0.2-0.3-0.3-0.5c0.4,0,0.4,0.6,0.9,0.7c0.3,0,0.2,0.3,0.2,0.4c0.2,0.1,0.4,0.3,0.5,0.6
        c0.2,0,0.4,0,0.6,0.1c0.1,0.2,0.1,0.4,0.4,0.4c0.5,0.1,0.8,0.4,1.3,0.6c0,0.1,0.1,0.2,0.1,0.3c0.4,0.2,1,0.3,1.2,0.8
        c0.3,0.1,0.7,0.1,0.9,0.4c0.1,0.2,0.3,0.3,0.6,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.1c0.2,0.3,0.5,0.6,0.9,0.5
        c0,0.1,0.1,0.2,0.2,0.4c0.4,0.1,0.8,0.4,1.2,0.4c0.1,0.1,0.2,0.3,0.4,0.5c0.2,0,0.5,0.1,0.7,0.1c-0.3,0.5,0.3,0.5,0.6,0.5
        c0.1,0.1,0.2,0.3,0.4,0.2c0.7,0.1,1.3,0.6,2,0.7c-0.1,0.1-0.2,0.2-0.3,0.4c0.5-0.1,1.1-0.3,1.3,0.3c0.2-0.1,0.4-0.1,0.7,0
        c0,0.1,0.1,0.3,0.1,0.4c0.1-0.1,0.2-0.2,0.2-0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.2-0.1,0.4-0.2,0.6-0.3c0,0.1,0,0.2,0,0.3l0.2,0.1
        c0.1,0,0.3-0.1,0.4-0.1c0,0.1-0.1,0.4-0.1,0.5l0.1,0c0-0.1,0-0.3,0.1-0.4c0.2,0,0.4,0.2,0.3,0.4c0.1,0,0.2-0.1,0.3-0.2
        c0.2,0,0.3,0,0.5,0.1c0.1,0.1,0.2,0.4,0.4,0.3c-0.1-0.2-0.1-0.3-0.2-0.5c0.2,0,0.6,0,0.6,0.3c0.2-0.1,0.4-0.3,0.6-0.4
        c0,0.1,0,0.2,0,0.2c0.4,0,0.9-0.3,1.3-0.1c-0.1-0.4,0.5-0.3,0.4,0c0.3-0.2,0.6-0.1,0.9-0.1c-0.1,0-0.2-0.1-0.3-0.1
        c0.1-0.1,0.2-0.2,0.3-0.3c0,0.1,0.1,0.3,0.1,0.4c0.1-0.1,0.3-0.3,0.4-0.4l0,0.2c0.3,0,0.7,0.1,1,0c0-0.1-0.1-0.2-0.2-0.3
        c0.2-0.5,0.7,0.2,0.9-0.2c0.2-0.1,0.4-0.3,0.5-0.5c0.2-0.1,0.5-0.1,0.7-0.1c-0.1-0.1-0.3-0.2-0.4-0.3c0.2,0,0.4,0,0.6-0.1
        c0-0.1-0.1-0.3-0.1-0.4c0.3,0.2,0.6,0.1,1,0.1c-0.2-0.1-0.5-0.1-0.6-0.4c0.1,0,0.3,0,0.4,0.1c0.1-0.1,0.2-0.2,0.3-0.2
        c0-0.1-0.1-0.2-0.1-0.2c0.2-0.1,0.4-0.3,0.5-0.6c0.1,0.1,0.1,0.2,0.2,0.3c0.6-0.4,0.7-1.1,0.9-1.8c0,0,0.1,0.1,0.2,0.2
        c0.1-0.1,0.2-0.3,0.3-0.4c0,0.1,0,0.2,0,0.3l0.2,0.1c0-0.1-0.1-0.3-0.2-0.4c0.4,0,0.4-0.4,0.1-0.6c0.2-0.1,0.4-0.1,0.6-0.2
        c-0.1-0.1-0.2-0.2-0.3-0.3c0.1,0,0.3,0,0.4,0c0.1-0.2,0.2-0.5,0.2-0.7c0.1,0,0.2,0,0.2,0c0-0.2,0-0.5,0-0.7c0.1-0.1,0.3-0.2,0.4-0.2
        c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.2,0.2-0.5,0.4-0.7c0-0.2-0.3-0.5,0-0.5c0.1-0.3,0.2-0.5,0.4-0.8c-0.1-0.1-0.2-0.2-0.3-0.2
        c0.1-0.2,0.3-0.4,0.3-0.7c0.1,0,0.2,0.1,0.2,0.1c-0.1-0.3-0.2-0.7,0-1c0-0.2,0-0.4,0.2-0.5c-0.1-0.1-0.2-0.2-0.2-0.3
        c0.2-0.1,0.2-0.2,0.1-0.4c-0.1-0.1-0.2-0.2-0.3-0.2l0,0c0.1,0,0.3,0,0.5,0c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.4,0-0.7,0.1-1.1
        c0-0.7-0.4-1.4-0.5-2.1c0.1,0.2,0.3,0.4,0.3,0.6c0.3,0.8,0.8,1.5,0.8,2.4c0.5,0.7,0.5,1.7,0.7,2.5c0,0.5,0.2,0.9,0,1.4
        c-0.2,0.5,0.4,1,0,1.4c0,0.4-0.1,0.7-0.2,1.1c0.1,0.4,0.2,0.8,0.2,1.2c-0.2,0.2-0.2,0.5-0.2,0.8c-0.2,0.2-0.5,0.3-0.3,0.6
        c0.8-0.8,0.8-2.1,1.5-3.1c-0.1-0.6,0.3-1,0.3-1.6c0.2-0.8,0.3-1.7,0.5-2.5c0-0.8,0.1-1.5,0.1-2.3c0,0-0.1-0.1-0.2-0.1
        c0.2-0.2,0.3-0.5,0-0.6c0.1-0.1,0.1-0.2,0.2-0.3c-0.5,0-0.1-0.3,0-0.5c-0.1,0-0.3,0.1-0.4,0.1c0.1-0.4,0-0.8-0.1-1.1
        c0-0.3,0-0.6,0-0.9c-0.5,0,0-0.4-0.2-0.6c0-0.1,0.1-0.2,0.1-0.3c-0.1,0-0.2,0.1-0.3,0.1c0-0.1-0.1-0.3-0.1-0.5
        c0.1,0,0.2-0.1,0.3-0.2c0-0.1,0-0.3,0-0.4c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1-0.3-0.1-0.6-0.1-0.9c0.1-0.3-0.3-0.2-0.3-0.4
        c0-0.3-0.2-0.6-0.1-0.9c-0.1-0.1-0.2-0.3-0.3-0.4c0.2-0.1,0.1-0.3,0-0.5c-0.1,0-0.2,0.1-0.2,0.1c0-0.2-0.1-0.3-0.1-0.5
        c0.1-0.2,0-0.4-0.1-0.5c0-0.1,0-0.3,0-0.4l-0.3,0.1c-0.1-0.3,0.1-1-0.4-0.9c0.1,0,0.3-0.1,0.4-0.2c-0.2-0.1-0.3-0.2-0.5-0.3
        c-0.2-0.3-0.3-0.6-0.5-0.8c0-0.1,0.1-0.3,0.1-0.4c-0.1,0-0.2,0-0.2,0c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.1-0.4-0.2-0.4-0.4
        c0-0.3-0.2-0.2-0.4-0.3l0.3,0c-0.1-0.2-0.2-0.3-0.5-0.3c0-0.4,0.4-0.2,0.5-0.1c-0.2-0.2-0.5-0.2-0.5-0.5c0.3,0.1,0.3,0,0.1-0.2
        c-0.2,0.1-0.2,0.5-0.5,0.3c0.2-0.2,0.1-0.5,0.4-0.6c-0.1,0-0.3,0-0.4,0c0,0.1,0,0.3,0,0.4c-0.2-0.1-0.3-0.2-0.3-0.5
        c-0.4-0.1-0.5-0.5-0.8-0.7c0.1,0,0.3,0,0.5,0c-0.1-0.1-0.3-0.3-0.4-0.4l0.3-0.1c0.1-0.4-0.1-0.1-0.2,0c-0.1,0-0.3-0.1-0.4-0.2
        c0-0.1,0.1-0.2,0.1-0.3c-0.1,0-0.2,0-0.3,0.1c0-0.1-0.1-0.3-0.1-0.4c-0.2-0.1-0.4-0.3-0.6-0.4c-0.1-0.1-0.2-0.2-0.2-0.2l-0.1,0
        c0-0.2-0.1-0.3-0.2-0.3c0-0.4-0.4-0.2-0.5-0.2c0-0.1,0.1-0.2,0.2-0.3l-0.2,0c0.1-0.3-0.2-0.3-0.3-0.4c-0.2-0.2-0.4-0.3-0.6-0.4
        c-0.1-0.3-0.3-0.3-0.5-0.2c0-0.1,0.1-0.3,0.1-0.4c-0.1,0-0.3,0.1-0.5,0.1c0-0.1,0-0.3-0.1-0.5c0,0.1-0.1,0.3-0.1,0.4l-0.3-0.2
        c0.1-0.1,0.2-0.2,0.1-0.4c-0.1,0.1-0.3,0.2-0.4,0.3l0-0.2c-0.2,0-0.4-0.1-0.5-0.2c0-0.1,0.1-0.2,0.1-0.2c-0.1,0-0.3,0-0.4,0
        c0-0.3-0.2-0.3-0.4-0.3c-0.1-0.2-0.2-0.4-0.5-0.3c0,0,0-0.1-0.1-0.2c-0.1,0-0.4,0.1-0.6,0.2c0.1-0.4,0.6-0.7,0.9-0.4
        c0.6,0.3,1.2,0.6,1.7,1c0.6,0.2,1.1,0.7,1.7,1c0,0.3,0.3,0.4,0.5,0.3c0.3,0.5,1,0.5,1.3,1.1c0.4,0.5,1.1,0.7,1.5,1.2
        c0.2,0.4,0.7,0.6,0.8,1c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.5c0.1,0,0.2,0.1,0.4,0.2c0.2,0.4,0.5,0.8,0.8,1.1
        c0.1,0.2,0.2,0.4,0.4,0.4c0,0.2,0.1,0.4,0.2,0.6c0.1,0,0.3,0,0.4,0c-0.1,0.1-0.2,0.3-0.3,0.4c0.1,0,0.2,0,0.3,0
        c0.1,0.3,0.4,0.5,0.5,0.8c0.4,0.3,0.3,0.9,0.5,1.4c0.1,0,0.2,0,0.3,0c-0.1,0.4,0,0.8,0.3,1c0,0.1-0.1,0.2-0.1,0.2
        c0.3,0,0.3,0.2,0.2,0.5c0.4,0.1,0.2,0.5,0.1,0.8c0.1-0.1,0.2-0.2,0.3-0.2c0,0.1,0,0.4,0,0.5c0.1,0.2,0.2,0.3,0.3,0.5
        c-0.1,0.5,0.3,0.9,0.4,1.4c0.1,0.3,0,0.6,0.3,0.8c-0.1,0-0.3,0-0.4,0.1c0.1,0,0.3,0,0.4,0c0,0.1,0,0.3,0,0.4l0.2-0.1
        c-0.1,0.2-0.1,0.5-0.1,0.7c0,0,0.2,0,0.2,0.1c-0.1,0.3,0,0.6,0,0.9c0.3,0.1,0,0.5,0.1,0.7c0.2,0.6,0.2,1.2,0.1,1.8
        c-0.1,0-0.2,0.1-0.3,0.2c0.1,0,0.2,0,0.3,0c-0.1,0.4-0.1,0.9-0.1,1.4l0.2,0c-0.1,0.4-0.3,0.9-0.7,1.1c0.1-0.1,0.3-0.1,0.4-0.2
        c0,0.1,0,0.4,0,0.6c-0.3,0.2-0.4,0.5-0.1,0.7c-0.3,0.1-0.4,0.2-0.2,0.5c-0.1,0-0.3,0-0.4,0c0,0.1,0,0.2,0,0.2l0.1-0.2
        c0.1,0.1,0.3,0.2,0.4,0.4c-0.1,0.2-0.3,0.5-0.1,0.7c-0.1,0-0.3-0.1-0.4-0.2c0,0.1,0.1,0.2,0.1,0.3c0,0,0,0.1-0.1,0.2
        c0.3,0.1,0.2,0.4,0.1,0.6c0-0.1-0.1-0.4-0.1-0.5c-0.1,0.2-0.2,0.5,0,0.7c-0.2,0.1-0.3,0.3-0.3,0.5l0.5-0.4l-0.2,0.4
        c-0.1,0-0.2,0.1-0.2,0.1c0.2,0.6-0.3,0.2-0.1-0.2c-0.4,0.3,0,0.8-0.3,1.1c0,0.2-0.1,0.4-0.1,0.7l-0.2-0.1c0,0.3,0,0.5,0,0.8
        c-0.1,0-0.2,0.1-0.3,0.1c0.2,0.1,0.2,0.2,0,0.4c-0.2,0.2-0.4,0.5-0.7,0.6c0,0.2,0,0.4,0,0.5c-0.1,0.1-0.3,0.3-0.4,0.4
        c0.2,0.1,0.2,0.3,0,0.4c0-0.1,0-0.3,0-0.3l-0.3,0c0,0.3,0.1,0.7-0.3,0.8c0,0.1,0.1,0.2,0.1,0.2c-0.1,0-0.2-0.1-0.3-0.1
        c0,0.2,0,0.5,0,0.7c-0.1-0.2-0.1-0.5-0.4-0.3c0.3,0.5-0.3,0.9-0.7,1c0,0,0.1,0.1,0.1,0.2c0,0-0.2,0.1-0.2,0.2
        c0.2,0.3-0.1,0.4-0.3,0.4l0.1,0.2c-0.1,0-0.3,0-0.4,0c0,0.1,0.1,0.2,0.1,0.2c-0.1,0-0.3,0.1-0.4,0.1c0,0.1,0,0.3,0,0.5
        c-0.1,0-0.4,0-0.5,0c0,0.1,0.1,0.3,0.2,0.4l-0.3-0.2c0,0.1,0,0.2,0,0.3c-0.5-0.1-0.7,0.4-0.9,0.7c-0.1,0-0.2,0-0.2,0
        c0.1-0.1,0.2-0.2,0.2-0.4c-0.4,0.1-0.6,0.4-0.8,0.6c0.1,0,0.3,0,0.4,0c-0.2,0.2-0.4,0.3-0.6,0.5c-0.1-0.6-0.4,0-0.3,0.3
        c0,0-0.1-0.1-0.2-0.1c-0.1,0.3-0.5,0.5-0.7,0.7l0-0.2c-0.1,0-0.2,0.1-0.3,0.2c0-0.1,0-0.2-0.1-0.3c-0.1,0-0.3,0.1-0.3,0.2
        c0.1,0.1,0.2,0.3,0.3,0.4l-0.5-0.2c0,0.3-0.2,0.4-0.4,0.6c0.1-0.3,0.1-0.5,0.2-0.8c-0.2,0.2-0.3,0.4-0.4,0.6
        c-0.1,0.3-0.5,0.3-0.8,0.5c-0.1,0-0.2-0.1-0.2-0.1c-0.2,0.3-0.5,0.5-0.7,0.7c-0.2-0.1-0.7-0.4-0.6,0.1c-0.3-0.2-0.6-0.2-0.9-0.3
        c0.1,0.1,0.2,0.3,0.2,0.4c-0.2,0-0.7-0.3-0.6,0.1c0.1,0,0.3,0,0.5,0.1c-0.1,0.1-0.3,0.2-0.4,0.2c-0.4,0-0.7,0.2-1.1,0.4
        c-0.4-0.1-0.8,0.2-1.1,0.1c-0.2,0-0.3,0-0.5-0.1l0.1,0.2c-0.3,0.1-0.7,0.1-0.9,0.3c0-0.1,0-0.3,0-0.4c-0.3,0.2-0.7,0.4-0.9,0.7
        c-0.1,0-0.3-0.1-0.4-0.1c-0.1-0.4-0.3-0.2-0.5,0c-0.2-0.1-0.4-0.2-0.6-0.3c-0.1,0.1-0.2,0.2-0.4,0.3c-0.1,0-0.2,0-0.3,0
        c-0.3,0.1-0.7,0.2-1.1,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0-0.3-0.1-0.4-0.1c0,0-0.1-0.1-0.2-0.2c0,0.2-0.1,0.4-0.2,0.5
        c0-0.1,0-0.3,0-0.4c-0.3,0.1-0.6,0.2-0.8,0.3c-0.2,0-0.3-0.3-0.5-0.4c0,0.2,0,0.3,0.1,0.5c-0.2-0.1-0.5-0.3-0.8-0.2
        c0-0.1,0.1-0.3,0.2-0.3c-0.3,0.1-0.5,0.4-0.9,0.1c0,0.1-0.1,0.2-0.2,0.2c-0.2,0-0.5,0-0.7-0.1c0-0.1,0-0.3,0-0.4
        c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0-0.4,0-0.5,0c0.2-0.2,0.4-0.3,0.5-0.5c-0.4,0-0.7,0.3-1,0.3c-0.2,0.1-0.4,0.1-0.5,0.1
        c0-0.1,0-0.3,0-0.4c-0.1,0.1-0.3,0.3-0.5,0.4c0-0.1,0.1-0.4,0.2-0.6c-0.2,0.1-0.5,0.2-0.5,0.4c-0.2-0.1-0.4-0.3-0.6-0.1l0.2,0.1
        c0.2,0.4-0.3,0-0.3-0.1c-0.3-0.2-0.5-0.3-0.8-0.4c-0.3,0.1,0.1,0.3,0.1,0.5c-0.2,0-0.4,0.1-0.6,0.1c0.1-0.1,0.2-0.2,0.2-0.2
        l-0.1-0.2l-0.3,0.2c0.2-0.2,0.3-0.4,0.4-0.6c-0.2,0.1-0.4,0.2-0.6,0.4c-0.3,0-0.5,0-0.8,0c-0.2-0.1-0.4-0.3-0.5-0.5
        c-0.1,0.1-0.3,0.1-0.4,0.2l0.1,0.2c-0.1,0-0.2,0-0.3,0c-0.1-0.2-0.1-0.3-0.2-0.4c-0.1,0.3-0.5,0.3-0.7,0.4c0.2-0.2,0.3-0.4,0.5-0.6
        c-0.1,0-0.3-0.1-0.4-0.1c0,0-0.1,0.1-0.2,0.1l0.4,0c-0.1,0.1-0.3,0.2-0.4,0.3c-0.4,0.1-0.3-0.4-0.6-0.5c0,0.1,0,0.3,0,0.4
        c-0.4,0-0.9-0.1-1.2-0.4c0,0.1,0,0.2,0,0.3c-0.2-0.1-0.6-0.3-0.3-0.6l-0.3,0.2c0,0.1,0,0.2,0,0.3c-0.1,0-0.2,0-0.2,0
        c0-0.1,0.1-0.2,0.2-0.3c-0.2,0-0.5-0.1-0.7-0.2c-0.1,0-0.2,0.1-0.3,0.1l0-0.3l-0.1,0c0,0.2-0.1,0.3-0.2,0.3c0-0.1,0-0.3,0.1-0.4
        c-0.3,0.1-0.6,0.3-0.8,0l0.1-0.1l0.1-0.1c0.1-0.1-0.1-0.2-0.1-0.4c0,0.1-0.1,0.2-0.1,0.3c-0.2,0-0.3-0.1-0.5-0.2l0,0.2
        c0.2,0.1,0.4,0.1,0.5,0.2c0,0.2,0,0.3,0.1,0.5c-0.3-0.2-0.5-0.5-0.9-0.6c0-0.1,0-0.2,0-0.2c-0.1,0-0.3,0-0.4,0
        c0,0.1,0.1,0.3,0.1,0.4c-0.3-0.2-0.5-0.7-0.9-0.5l0-0.2c-0.2,0.1-0.4,0.2-0.4,0.4c0,0-0.1-0.1-0.1-0.2c-0.2,0.1-0.4,0.1-0.6,0.1
        c0-0.1,0.1-0.2,0.1-0.3c0.1,0,0.3,0.1,0.5,0.1c-0.1-0.1-0.3-0.3-0.4-0.4c-0.1,0.2-0.3,0.4-0.6,0.5c0-0.1,0.1-0.4,0.1-0.5
        c-0.1,0-0.3,0.1-0.4,0.1c0,0-0.1,0.1-0.2,0.2c-0.1-0.1-0.3-0.2-0.5-0.2l0.1-0.1c-0.3,0-0.5-0.3-0.3-0.6c-0.2,0-0.3,0-0.5,0
        c0.2,0.3,0.1,0.5,0.4,0.9c-0.2-0.3-0.7-0.3-0.6-0.7c-0.3-0.2-0.6-0.1-0.8,0.1c-0.1-0.1-0.2-0.2-0.3-0.2c0,0-0.1,0.1-0.2,0.2
        c-0.2-0.1-0.3-0.1-0.5-0.2c0-0.2-0.1-0.5-0.2-0.7c-0.2,0.2-0.2,0.5,0,0.7c-0.1-0.1-0.4-0.3-0.6-0.4c-0.3-0.3-0.6-0.5-0.9-0.6
        c0-0.1,0.1-0.2,0.1-0.3c-0.2,0-0.3,0.1-0.3,0.3c0.2,0.2,0.7,0.2,0.7,0.6c-0.1,0-0.3,0-0.3-0.1c-0.3,0.1-0.4-0.1-0.5-0.3
        c-0.1,0-0.2,0.1-0.3,0.1l-0.1-0.2c0.1,0,0.3,0,0.4,0c0-0.4-0.3-0.2-0.5-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0.2-0.1,0.4-0.1,0.6
        c-0.1-0.1-0.3-0.2-0.4-0.4c-0.2,0-0.4-0.1-0.5-0.1c0-0.1,0.1-0.2,0.1-0.2c-0.1-0.1-0.2-0.2-0.4-0.3c0,0.1,0.1,0.3,0.1,0.4
        c-0.5,0.3-0.7-0.8-1.1-0.3c0.2-0.2,0.2-0.4,0-0.5c0,0.5-0.8,0.5-0.8,0c-0.3,0.1-0.7-0.1-0.2-0.3c-0.1,0-0.2,0-0.4,0
        c-0.1,0.1-0.2,0.1-0.3,0.2c0-0.2-0.1-0.4-0.1-0.6c0,0.1-0.1,0.2-0.2,0.3c0,0-0.1,0-0.2,0c-0.1,0.1-0.2,0.2-0.3,0.2
        c0.1-0.1,0.2-0.3,0.3-0.4c-0.1,0-0.4,0-0.5,0.1l0,0.2l-0.1,0c0-0.1,0.1-0.2,0.1-0.3l-0.3,0c0,0-0.1-0.1-0.1-0.2
        c-0.3,0.2-0.6,0.4-0.8,0c0.1,0,0.3,0,0.3,0c-0.1-0.2-0.3-0.4-0.5-0.5c0,0.1,0.1,0.4,0.2,0.5c-0.1,0-0.4-0.1-0.5-0.2
        c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1,0-0.2,0-0.3,0.1c-0.1-0.1-0.3-0.2-0.4-0.3c0,0.1-0.1,0.2-0.2,0.2c-0.1-0.3-0.3-0.5-0.6-0.7
        c0,0.1,0.1,0.3,0.1,0.5c-0.2-0.2-0.5-0.3-0.8-0.2l0-0.2c-0.1,0-0.2,0.1-0.3,0.1c0-0.1,0.1-0.3,0.1-0.4c-0.2,0-0.4,0-0.5,0.3
        c-0.2-0.1-0.4-0.2-0.5-0.2l0.1-0.2c0.1,0,0.2-0.1,0.3-0.1c-0.1-0.1-0.3-0.3-0.4-0.4c0.1,0.1,0.2,0.3,0.3,0.4c-0.3,0-0.5,0.1-0.8,0.1
        c0.1-0.2,0.2-0.3,0.3-0.5c-0.4,0.2-0.8,0.3-1.3,0.3c0.1-0.1,0.2-0.3,0.4-0.4c-0.3-0.1-0.8,0.6-1,0.1c0.2-0.1,0.6-0.1,0.6-0.5
        c-0.3,0.2-0.6,0.3-0.9,0.4c-0.1,0-0.3,0-0.4,0c0,0,0.1-0.1,0.1-0.2c-0.1,0-0.4,0-0.5,0c0.2-0.2,0.6-0.4,0.9-0.6
        c-0.2,0-0.3,0.1-0.5,0.1c-0.3,0-0.6,0.1-0.8,0.3c0-0.1-0.1-0.2-0.2-0.3c0,0-0.1,0.1-0.2,0.2c-0.1-0.4-0.5-0.4-0.8-0.5
        c-0.1-0.2-0.4,0-0.5,0c0.1-0.1,0.1-0.3,0.2-0.4c-0.2-0.1-0.4,0.1-0.6,0.2c-0.1-0.2-0.3-0.6-0.6-0.3c0.1-0.2,0.2-0.4,0.3-0.5
        c-0.2,0.1-0.6,0.1-0.5,0.5c-0.1-0.1-0.2-0.1-0.3-0.2c0,0.1-0.1,0.2-0.2,0.2l-0.1-0.1c0-0.1,0-0.2,0-0.3c-0.2,0-0.3-0.2-0.4-0.4
        c-0.4,0-0.2,0.4,0.1,0.4l0,0.2c-0.3-0.1-0.5-0.3-0.7-0.3c-0.2,0.1-0.3-0.1-0.5-0.2c-0.1,0-0.3,0-0.4,0c0.2-0.3-0.2-0.7-0.2-0.2
        c-0.3,0-0.5,0-0.8,0c-0.1-0.1-0.3-0.2-0.4-0.3l0.2-0.1c0-0.4-0.4,0-0.6-0.3c-0.2-0.2-0.3-0.3-0.5-0.5c-0.1,0.1-0.2,0.2-0.3,0.3
        c0.3,0,0.6,0.1,0.8,0.4c-0.3,0-0.7,0.1-0.8-0.4c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1-0.2-0.3-0.4-0.3-0.6c-0.2,0.3-0.6,0.3-0.9,0.3
        c0.1-0.2,0.3-0.4,0.4-0.6c-0.1,0-0.4,0.1-0.5,0.2c0.1,0.2,0,0.4-0.2,0.5c0-0.1,0.1-0.4,0.2-0.5c-0.4,0.2-0.7-0.1-1.1,0
        c0-0.2,0.1-0.3,0.1-0.5c-0.1,0-0.3,0-0.4,0c0.1,0.1,0.2,0.3,0.3,0.5c-0.4,0-0.5-0.2-0.4-0.5c-0.2,0-0.3,0.1-0.5,0.1l0-0.1l-0.2,0.1
        l0.1-0.3c-0.1,0-0.2,0-0.3-0.1c0,0.1,0,0.2,0,0.3c-0.1-0.1-0.4-0.2-0.2-0.4c0.1,0,0.3,0,0.4,0c0-0.1-0.1-0.3-0.1-0.4
        c-0.3,0.4-0.8,0.2-1.2,0l0,0.2c0.2-0.1,0.2,0.3,0,0.3c-0.1-0.3-0.3-0.5-0.6-0.2c-0.1-0.2-0.1-0.3-0.1-0.5c-0.2-0.1-0.4-0.3-0.5-0.1
        l0.3,0.1c0.1,0.3-0.4,0.2-0.6,0.2c0-0.1,0.1-0.2,0.1-0.3c-0.2-0.1-0.6,0.1-0.7-0.2c-0.3,0-0.6,0.2-0.9,0.1c0-0.1,0.1-0.3,0.1-0.4
        c-0.3-0.1-0.6-0.1-0.9-0.1c0,0.1,0,0.2,0.1,0.3c0.1,0,0.4-0.1,0.5-0.2c0,0.4-0.5,0.3-0.7,0.3c0-0.3-0.3-0.5-0.5-0.6
        c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0-0.4-0.1-0.5-0.1c0.2-0.1,0.5-0.2,0.6-0.4c-0.4,0.1-0.9,0.2-1-0.3c-0.2,0.2-0.3,0.5-0.6,0.4
        c0.1-0.2,0.1-0.6,0.5-0.7c0,0,0-0.1,0-0.1c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1,0.3-0.3,0.5-0.5,0.6c0-0.1,0.1-0.4,0.2-0.5
        c-0.2,0.1-0.4,0.2-0.6,0.3c-0.1-0.2-0.3-0.2-0.5-0.2c0-0.1,0-0.3,0-0.4c-0.1,0.1-0.3,0.2-0.4,0.3c-0.2-0.1-0.3-0.2-0.5-0.3l0,0.3
        c0,0-0.1,0.1-0.2,0.1c0-0.1,0-0.3,0-0.4l-0.3,0.4c0-0.1,0.1-0.4,0.1-0.5c-0.1,0-0.2,0-0.3,0c-0.1-0.1-0.2-0.1-0.4-0.2
        c-0.1,0.2-0.4,0.2-0.6,0.4l0.3,0.1c0,0.1,0,0.2,0,0.2c-0.1-0.2-0.3-0.3-0.4-0.5l0.4-0.1c-0.2-0.1-0.8-0.2-0.6,0.2
        c-0.3,0-0.5,0-0.8,0.1c0-0.2,0.1-0.4-0.2-0.5c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.3-0.2c0,0-0.1,0.1-0.2,0.2
        c-0.1-0.1-0.2-0.2-0.3-0.3c0,0.2,0,0.3,0.1,0.5c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0-0.2,0-0.4,0c0.1-0.4,0.7-0.3,0.9-0.6
        c-0.3,0.1-0.6,0.1-1,0.2c0,0.1,0,0.2,0,0.2c-0.1,0.1-0.4,0.3-0.4,0c-0.3,0-0.7,0-0.8-0.4c-0.2,0.1-0.4,0.4-0.6,0.2
        c-0.3-0.1-0.7,0.4-0.9,0c0.2,0,0.6,0.1,0.7-0.2c-0.3,0-0.4-0.3-0.6-0.5c0,0.1,0.1,0.2,0.1,0.3c-0.1,0.3-0.6,0.4-0.7,0
        c-0.1,0-0.3,0-0.3,0c0,0,0,0.1,0,0.2c0.1,0,0.3,0,0.4,0c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1-0.2-0.3-0.3-0.5-0.2l0-0.2
        c-0.4,0.1-0.8,0-1.1-0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.4-0.2-0.8,0.2-1.2,0.1c-0.2,0-0.4,0.1-0.6,0.2c0.1-0.1,0.2-0.4,0.3-0.5
        c-0.2,0-0.4,0.2-0.4,0.4c-0.2-0.1-0.4-0.3-0.5-0.4c-0.2,0.1-0.3,0.3-0.4,0.4c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1,0.1-0.2,0.3-0.2,0.4
        c-0.2-0.2-0.5-0.1-0.7-0.1c0-0.1,0.1-0.2,0.1-0.3c-0.2,0.1-0.3,0.3-0.5,0.4c0-0.1,0-0.3,0-0.4c0,0-0.1,0.1-0.2,0.1
        c0,0.1,0.1,0.3,0.1,0.3c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2,0.1-0.6,0.3-0.5-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0.2-0.4,0.3-0.6,0.4
        c-0.1-0.2-0.3-0.4-0.4-0.6c0,0.2,0.1,0.5,0.2,0.7c-0.2-0.1-0.4-0.1-0.6-0.2c-0.1,0.1-0.4,0.2-0.5,0.2c0.1-0.2,0.3-0.4,0.4-0.6
        c-0.3,0.2-0.6,0.4-0.9,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c0-0.1,0-0.2,0-0.3c-0.1,0-0.2,0.1-0.2,0.1l-0.1-0.2c-0.1,0.1-0.2,0.2-0.2,0.2
        c0.2,0.1,0.2,0.2,0.3,0.4c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0.1-0.2,0.2-0.3,0.1c-0.4,0.1-0.8,0.3-1.2,0.6c-0.2,0.1-0.5,0.4-0.7,0.2
        c-0.1,0.2-0.3,0.3-0.5,0.5c0-0.1,0-0.3,0-0.4c-0.2,0-0.3,0.1-0.5,0.2c0,0.5-0.7,0.3-0.9,0.7c-0.3,0.1-0.6,0.3-0.9,0.4
        c0-0.1,0-0.3,0.1-0.4c-0.2,0.2-0.3,0.4-0.4,0.7c-0.2,0-0.4-0.1-0.5-0.2c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0-0.3,0.1-0.3,0.2
        c0-0.1-0.1-0.2-0.1-0.3c0,0.3-0.2,0.4-0.4,0.5c-0.1,0-0.2-0.1-0.2-0.1c0,0.1,0,0.3,0,0.4c-0.1-0.1-0.3-0.2-0.4-0.3
        c-0.1,0.1-0.1,0.3-0.2,0.5c-0.6-0.3-0.8,0.6-1.3,0.7c-0.4,0.1-0.7,0.2-0.7,0.6c-0.2,0-0.4,0.1-0.5,0.1c0,0.1-0.1,0.2-0.2,0.3
        c-0.3,0.2-0.6,0.3-0.9,0.4c-0.5,0.5-1,0.9-1.6,1.3c-0.1,0.1-0.2,0.2-0.3,0.4c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0.6-0.8,0.5-1.2,0.9
        c-0.1,0.5-0.6,0.5-1,0.6c-0.1,0.2-0.2,0.3-0.2,0.5c-0.7,0.1-1,0.9-1.5,1.3c-0.2,0.4-0.6,0.6-0.9,0.9c-0.3,0.6-0.7,1.1-1.2,1.5
        c-0.3,0.4-0.5,0.8-0.9,1c0.3,0,0.5,0,0.8,0.1c0.3-0.7,1.3-0.5,1.8-1c0.6-0.1,1.1-0.4,1.8-0.5c0.5-0.7,1.5-0.4,2.1-1
        c0.2-0.1,0.4-0.1,0.6-0.1c0.2-0.2,0.4-0.6,0.7-0.6c0.7-0.2,1.5-0.2,2.1-0.7c0.1,0,0.3,0,0.4,0c0.1-0.1,0.3-0.4,0.5-0.4
        c0.6,0.1,1.1-0.2,1.6-0.5c0.6-0.1,1.2-0.1,1.6-0.5c0.3,0.1,0.6-0.1,0.8-0.3c1,0.2,1.7-0.8,2.7-0.6c0.9-0.6,2.1-0.6,3.1-1
        c0.1,0,0.2,0.1,0.3,0.1c0.5,0,1-0.5,1.5-0.2c0.5-0.3,1-0.2,1.5-0.4c0.5,0,1-0.1,1.4-0.3c0.3-0.2,0.6,0,1,0c0.4-0.3,0.8-0.1,1.3-0.2
        c0.9-0.4,1.9-0.4,2.9-0.6c0.5-0.1,0.9-0.1,1.4-0.1c0.1,0.1,0.2,0.2,0.3,0.3c0-0.3,0.3-0.4,0.5-0.4c0.2,0.1,0.3,0.2,0.5,0.3
        c0.2-0.1,0.3-0.3,0.5-0.4c0.2,0,0.5,0.3,0.8,0.1c0.5-0.3,1-0.1,1.5-0.1c0.5,0,1-0.3,1.4,0c0.3,0.3,0.5-0.2,0.7-0.2
        c0.4,0.1,0.9-0.1,1.2,0.3l-0.1-0.3c0.2,0,0.4,0,0.5,0.2c0.5-0.2,1-0.1,1.5-0.1l-0.1,0.2l0.2,0c-0.1-0.4,0.3-0.1,0.5-0.1l0-0.1
        c0.4,0.2,0.9,0.1,1.4,0.1c0.3,0,0.6,0.1,0.9,0c0.4,0.3,0.9,0,1.3,0.3c0.3-0.1,0.5,0.1,0.7,0.2c0.2,0,0.4-0.3,0.6-0.1
        c0.1,0.1,0.3,0,0.4,0c0.4-0.1,0.9,0.1,1.3,0.1c0,0,0.1-0.1,0.1-0.2c0.3,0.6,1.1,0.6,1.7,0.3c0.4,0.3,0.9-0.1,1.3,0.2
        c0.1,0.1,0.3,0.2,0.4,0.3c0.3,0,0.6,0,0.9,0c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0,0.2-0.1,0.2-0.1c0.1,0.1,0.3,0.2,0.4,0.2
        c0.3-0.3,0.8-0.1,1.1,0.1c0.4,0.2,0.8,0.1,1.1,0.1c0.2,0.2,0.4,0.3,0.6,0.5c0.1-0.1,0.2-0.2,0.3-0.2c0.4,0.2,0.8,0.3,1.3,0.4
        c0.3,0.1,0.8,0.7,1,0.2c0.2,0.1,0.4,0.1,0.7,0.2l-0.1,0.2c0.4,0,0.8,0.1,1.2,0.1c0,0.1,0.1,0.3,0.1,0.3c0.1,0,0.2-0.1,0.3-0.1
        c0.7,0.3,1.5,0.5,2.2,0.9c0.8,0.2,1.5,0.9,2.3,1c0,0,0.1,0.1,0.2,0.2c0.7,0.2,1.3,0.5,1.9,0.8c-0.1,0.1-0.2,0.2-0.2,0.3
        c0.7,0,1.5,0.1,2,0.7c0.4,0.3,0.9,0.3,1.2,0.7c0.2,0.3,0.6,0.3,1,0.4c0.3,0.3,0.7,0.6,1.2,0.7c0.2,0,0.3,0.2,0.4,0.4
        c0.2,0,0.4,0,0.6,0.1c0.2,0.4,0.7,0.3,0.9,0.7c0,0.3,0.4,0.1,0.6,0.1c0.2,0.3,0.5,0.4,0.7,0.8c0.2,0.2,0.5,0.4,0.8,0.5
        c0.3,0.4,0.9,0.5,1.1,1c0.1,0.3,0.4,0.3,0.6,0.3c0.3,0.2,0.5,0.3,0.8,0.5c0.4,0.2,0.7,0.5,1,0.9c0.1,0.2,0.3,0.1,0.5,0.2
        c0,0.1,0.1,0.2,0.1,0.2c0.5,0.4,1,0.8,1.5,1.1c0.4,0.5,0.8,1,1.3,1.4c0.6,0.2,1,0.7,1.4,1.1c0.6,0.5,1.1,1.1,1.7,1.6
        c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.3,0.2,0.4,0.4c0.2,0.1,0.3,0.3,0.5,0.5c0.1,0.2,0.3,0.3,0.5,0.5c0.3,0.2,0.4,0.5,0.7,0.8
        c0.5,0.4,0.8,0.9,1.3,1.3c0.5,0.8,1.1,1.5,1.6,2.2c0.9,0.9,1.5,2,2.4,3c0,0.1,0,0.2,0,0.2c0.2,0.1,0.5,0.2,0.3,0.5
        c0.1,0,0.2-0.1,0.3-0.1c0.1,0.2,0,0.6,0.3,0.6c0.5,0.7,0.8,1.5,1.2,2.2c0.1,0,0.2,0.1,0.3,0.1c-0.2,0.5,0.6,0.7,0.5,1.2
        c0.1,0.1,0.2,0.2,0.2,0.4c0.2,0.4,0.4,0.7,0.7,1.1c0,0-0.1,0.1-0.1,0.2c0.3,0.2,0.4,0.6,0.7,0.9c0.1,0.3,0.1,0.8,0.5,1
        c0.1,0.4,0.6,0.7,0.4,1.2c0.3,0.1,0.3,0.4,0.4,0.7c0.2,0.6,0.5,1.2,0.8,1.9c-0.1-0.1-0.2-0.3-0.3-0.4c0-0.1,0-0.3,0-0.3
        c-0.1,0-0.2-0.1-0.3-0.2c0-0.3-0.1-0.5-0.3-0.7c0-0.3-0.2-0.7-0.4-0.8c0-0.2,0-0.4,0-0.6c-0.1,0-0.2,0-0.2,0
        c-0.3-0.5-0.5-0.9-0.7-1.4c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.4-0.5-0.5-0.5-0.9c-0.1,0-0.2,0-0.2,0l0.1-0.1c-0.2-0.2-0.5-0.5-0.4-0.8
        l-0.1,0c0-0.3-0.3-0.6-0.3-0.9l-0.1,0.1c0-0.3-0.1-0.6-0.4-0.5c0.1-0.2,0-0.5-0.3-0.4c0.1-0.2,0-0.4-0.1-0.6
        c-0.1-0.1-0.2-0.2-0.3-0.4c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0-0.2,0.1-0.2l-0.1,0c-0.1-0.2-0.2-0.3-0.4-0.3c0-0.1,0-0.2,0.1-0.2
        l-0.2,0.1l0.1-0.3c-0.1,0-0.3,0.1-0.4,0.1c0-0.1,0.1-0.3,0.1-0.4l-0.2,0c0-0.1,0-0.2,0-0.3l-0.1,0c0-0.2-0.1-0.4-0.3-0.5
        c-0.1-0.2-0.2-0.5-0.4-0.7c-0.1,0-0.2,0-0.2,0c0-0.3-0.2-0.6-0.5-0.6c0-0.1,0-0.2-0.1-0.3c-0.1,0-0.3,0-0.4,0.1
        c0.1-0.2,0.2-0.3,0.3-0.4c-0.2,0-0.3,0-0.5,0v-0.4c0.1,0,0.2,0.1,0.3,0.1l0-0.1c-0.2,0-0.3-0.1-0.5-0.1c-0.1-0.2-0.1-0.3,0-0.5
        c0,0-0.1-0.1-0.2-0.1c0,0.1,0,0.3,0,0.4c-0.5-0.1-0.5-0.6-0.6-0.9c-0.1,0-0.2,0-0.3,0c0-0.1,0.1-0.3,0.1-0.4c0,0.1-0.2,0.2-0.2,0.3
        c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1,0-0.2,0-0.2,0.1c-0.2-0.4-0.6-0.6-0.8-0.9c0.1,0.1,0.4,0.3,0.6,0.4c0-0.4-0.5-0.4-0.6-0.8
        c-0.1-0.1-0.1-0.3-0.2-0.4c-0.2,0-0.5-0.1-0.6,0c0.1-0.1,0.3-0.2,0.4-0.3c0,0-0.1-0.1-0.1-0.1l0,0.2c-0.2,0-0.4,0-0.5,0
        c0-0.1,0.1-0.3,0.1-0.4c-0.1,0-0.3,0.1-0.4,0.1c0-0.1,0-0.3,0-0.5c-0.4,0.1-0.9-0.2-1-0.6c-0.1,0-0.3,0-0.4,0.1l0-0.1
        c0.1,0,0.2,0,0.3,0c-0.1-0.4-0.5-0.2-0.7-0.4c0.2,0,0.4,0,0.6,0c-0.1-0.4-0.6-0.2-0.9-0.3c0.1,0,0.3-0.1,0.4-0.1
        c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1,0-0.3,0-0.3,0c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1,0-0.3,0-0.4,0c0-0.1,0-0.3,0.1-0.4
        c-0.2,0-0.3-0.1-0.5-0.1l0.1-0.2c-0.1,0-0.2,0-0.3,0c0-0.2-0.1-0.4-0.1-0.5c-0.1,0.1-0.3,0.1-0.4,0.2c0-0.2,0-0.5-0.1-0.7
        c0,0.1-0.1,0.3-0.1,0.4c0-0.3-0.1-0.4-0.4-0.3c-0.1-0.1-0.2-0.2-0.2-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c-0.2,0-0.5-0.1-0.7-0.1
        c0-0.1,0-0.2,0-0.2c-0.3,0-0.4-0.3-0.6-0.5c-0.8-0.5-1.8-0.7-2.5-1.3c-0.3,0.1-0.5,0-0.7-0.2c-0.8-0.1-1.5-0.6-2.3-0.9
        c-0.9-0.3-1.7-0.7-2.6-0.9c-0.7-0.2-1.3-0.4-1.9-0.7c-0.1-0.1-0.3,0-0.4,0c-0.2-0.1-0.3-0.2-0.5-0.3c-0.4,0.3-0.8,0-1.2-0.1
        c-0.5,0-1-0.3-1.4-0.5c-0.6-0.2-1.2-0.3-1.8-0.6c-0.4,0-0.7,0-1-0.2c-0.4,0.1-0.8,0-1.1-0.2c-0.3,0.2-0.6-0.1-0.9-0.2
        c-0.4,0.1-0.8-0.1-1.1-0.3c-0.4,0-0.8-0.1-1.1-0.3c-0.7,0-1.4-0.2-2.1-0.2c-0.5-0.2-1,0-1.5-0.3c-0.2-0.1-0.4,0-0.5,0.1
        c-0.2-0.1-0.4-0.1-0.7-0.1c-0.6-0.2-1.2-0.1-1.7-0.3c-0.6-0.2-1.3,0.1-1.9-0.2c-0.4-0.1-0.8,0.4-1.1-0.1c-0.2,0.3-0.5,0.3-0.7,0.2
        c0-0.1-0.1-0.2-0.2-0.2c-0.2,0.1-0.4,0.3-0.7,0.2c-0.3-0.2-0.6,0.1-0.9,0c-0.2,0-0.5,0-0.7,0.1c-0.1,0-0.2,0-0.3-0.1
        c-0.1,0.1-0.2,0.2-0.2,0.3c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2,0.1-0.5,0.2-0.7,0.3c-0.4-0.4-0.9,0.1-1.4,0.1c0-0.1,0-0.2-0.1-0.3
        c-0.2,0-0.3,0.1-0.5,0.2c0,0.1-0.1,0.2-0.2,0.3c-0.3-0.1-0.4-0.4-0.7-0.5l0.1,0.2c0.1,0,0.2,0.1,0.2,0.1l0,0.2c-0.1,0-0.4,0-0.6,0
        c0-0.1-0.1-0.2-0.2-0.2c-0.3,0.2-0.6,0.4-0.9,0.4c0-0.1,0-0.2-0.1-0.3c-0.1,0.2-0.3,0.3-0.5,0.2c-0.1,0.4-0.6,0.3-0.9,0.2
        c-0.2,0.3-0.5,0.1-0.8,0.3c-0.3,0.1-0.6,0.3-0.8,0c-0.1,0.1-0.2,0.2-0.3,0.4c-0.1,0-0.2-0.1-0.4-0.1c-0.2,0.2-0.4,0.3-0.7,0.4
        c0-0.2-0.1-0.3-0.3-0.4c0,0.1,0,0.3,0,0.4c-0.4,0.1-0.9,0-1.2,0.3c-0.3,0-0.5,0-0.8,0c-0.2,0.1-0.5,0.2-0.6,0.5
        c-0.1,0-0.2,0-0.3-0.1c0,0.1-0.1,0.2-0.1,0.3c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2,0.3-0.7,0.6-1,0.3c0.2,0.2-0.1,0.4-0.3,0.3
        c-0.2-0.1-0.4-0.2-0.6-0.2c0.1,0.1,0.3,0.2,0.3,0.4c-0.2,0-0.4-0.2-0.6-0.1c0.1,0.1,0.2,0.2,0.3,0.3c-0.5-0.1-0.9,0-1.3,0.2
        c0,0.2-0.2,0.2-0.4,0.3c0.1-0.2,0-0.4-0.1-0.5c0.1,0.4-0.3,0.5-0.6,0.5c0,0.1,0.1,0.3,0.2,0.4c-0.2-0.1-0.4-0.3-0.6-0.4
        c0,0.1,0.1,0.3,0.1,0.4c-0.1-0.1-0.3-0.2-0.4-0.3c0,0.1,0,0.3,0,0.4c-0.2-0.1-0.3-0.1-0.5-0.2c0,0.1,0.1,0.2,0.1,0.3
        c-0.6-0.2-1.1,0.3-1.6,0.5c0-0.3-0.7-0.2-0.3,0.1c-0.2-0.2-0.4-0.3-0.6,0.1c-0.2,0.1-0.5,0.2-0.6,0.4c-0.3-0.2-0.6,0.4-0.6-0.1
        c-0.1,0.1-0.2,0.2-0.3,0.3l0.2,0l0,0.2c-0.1-0.1-0.2-0.2-0.2-0.2c-0.1,0-0.2-0.1-0.3-0.1c0.1,0.1,0.1,0.3,0.1,0.5
        c-0.1-0.1-0.3-0.3-0.4-0.4c0,0.1,0,0.3,0,0.4c-0.3-0.1-0.6,0-0.7,0.3c-0.2,0-0.4,0.1-0.5,0.2c-0.1-0.1-0.2-0.2-0.3-0.2
        c-0.1,0.4-0.6,0.2-0.7,0.5c-0.2,0.3-0.5,0.3-0.7,0.4c-0.2-0.1-0.3-0.1-0.4,0.1c-0.4,0.4-1,0.7-1.6,0.5c-0.1,0.2-0.2,0.5-0.3,0.7
        c0-0.1-0.1-0.2-0.2-0.3c0,0.3-0.1,0.5-0.3,0.6c0-0.1,0.1-0.4,0.1-0.5c-0.2,0.2-0.7,0.2-0.7,0.6c-0.3,0-0.6,0-0.9,0
        c0.1,0.5,0.7-0.1,1,0.1c-0.3,0.3-0.7,0.4-1.1,0.3c0,0.1,0.1,0.3,0.1,0.4c-0.1,0-0.3,0-0.4,0c-0.1,0-0.4,0-0.5,0
        c-0.2,0.2-0.3,0.5-0.6,0.7c0-0.2,0-0.4,0.2-0.6c-0.3,0.1-0.2,0.4-0.5,0.5c-0.3,0.1-0.6,0.1-0.8,0.2c-0.2,0.1-0.5,0-0.6,0.3
        c-0.3-0.1-0.6,0.2-0.9,0l0,0.2l0.3,0c-0.2,0.2-0.5,0.4-0.7,0.6c-0.1,0-0.2,0-0.3,0c-0.2,0.2-0.5,0.5-0.4,0c-0.3,0-0.3,0.2-0.2,0.4
        c-0.2,0-0.3,0.1-0.5,0.1c-0.2,0.4-0.7,0.4-0.9,0.8c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0.2-0.3,0.3-0.4,0.4c-0.3,0.2-0.7,0.2-0.8,0.6
        c0.2,0,0.5,0,0.6,0c-0.1,0.2-0.3,0.2-0.5,0.2c-0.2,0-0.4,0-0.5,0c0,0.1,0,0.2,0,0.3c-0.3-0.1-0.6-0.2-0.8-0.1
        c0.2,0.1,0.4,0.2,0.6,0.3c-0.2,0.1-0.4,0-0.6,0c0,0.1-0.1,0.2-0.1,0.3c-0.3,0.1-0.8-0.4-0.9,0.1c0.2,0,0.4,0.1,0.7,0.1
        c-0.1,0.3-0.3,0.1-0.5,0.1c0,0.1,0.1,0.2,0.1,0.3c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0.2-0.2,0.4-0.2,0.6c-0.1-0.1-0.1-0.3-0.1-0.4
        c-0.2,0.2-0.4,0.4-0.6,0.6c-0.2,0-0.4,0-0.6,0c0,0.1,0.1,0.2,0.1,0.3l-0.3,0.2c0-0.2,0.1-0.5,0.1-0.6c-0.2,0.2-0.6,0.4-0.1,0.8
        l-0.3-0.2c-0.2,0-0.3,0-0.5,0.1c0,0.1,0,0.2,0.1,0.2c-0.1,0.1-0.3,0.3-0.5,0.4c0.1,0,0.2,0.1,0.3,0.1l0,0.1c-0.2,0-0.4,0.1-0.5,0.1
        c-0.1,0.1-0.2,0.3-0.3,0.3c-0.2-0.4-0.6,0.1-0.8,0.2c0,0.5,0.3-0.1,0.5,0c0.1,0,0.4,0.2,0.2,0.2c-0.1,0-0.3,0-0.3-0.1
        c-0.1,0.1-0.1,0.2-0.2,0.4c0-0.1,0-0.3,0-0.3c-0.3,0.1-0.6,0.2-0.9,0.3c-0.1,0.2-0.3,0.6-0.6,0.6c0,0.3-0.5,0.6-0.7,0.3
        c-0.1,0.3-0.3,0.4-0.6,0.5l0,0.1c-0.2,0.1-0.3,0.3-0.6,0.3c0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0-0.3,0-0.4,0c0.1,0.3,0,0.6-0.2,0.8
        c-0.5-0.1-1,0.3-1.5,0.3c0.1,0.2,0.3,0.3,0.5,0.2c-0.1,0.5-0.8,0.1-1.1,0.5c-0.1,0.2,0.1,0.6-0.2,0.7c0-0.1,0-0.3,0.1-0.4
        c-0.2,0.1-0.3,0.2-0.3,0.4c-0.2-0.1-0.4,0-0.5,0.1c-0.2-0.1-0.4-0.2-0.6-0.2c0.3,0.3,0.6,0.5,0.8,0.8c0,0.1-0.1,0.2-0.1,0.3
        c0.3,0.1,0.6,0.3,0.7,0.6c0-0.3,0.1-0.4,0.3-0.5c0.1,0.2,0,0.4,0,0.6c0.5,0.1,0.2-0.4,0.2-0.7c0.2-0.1,0.5-0.1,0.8-0.1
        c0-0.1-0.1-0.3-0.1-0.4c0.3-0.2,0.6-0.5,1.1-0.4c0-0.2-0.1-0.3-0.3-0.3c0.2-0.1,0.5-0.3,0.7-0.2c0.1-0.2,0.3-0.3,0.4-0.4
        c0.2,0.1,0.3,0.2,0.5,0.3c0-0.3-0.3-0.3-0.5-0.4c0.1-0.1,0.3-0.3,0.4-0.4c0.1,0.1,0.2,0.1,0.3,0.2c-0.1-0.3,0.1-0.4,0.4-0.4
        c0.4,0,0.7-0.1,0.9-0.4c-0.1,0-0.3,0.1-0.4,0.1c0-0.2,0-0.3,0.1-0.5c0.1,0.1,0.3,0.2,0.4,0.3c0.2-0.1,0.3-0.3,0.4-0.5
        c0.3,0.1,0.5,0.2,0.8,0.3c0-0.1,0-0.2,0-0.3c-0.2,0.2-0.7-0.1-0.4-0.3c0.4-0.1,0.8-0.6,1.2-0.3c0.1-0.2,0.2-0.5,0.3-0.7
        c-0.2,0.2-0.4,0.3-0.7,0.4c0.2-0.6,0.9-0.4,1.3-0.6l-0.1-0.2c0.2,0,0.4,0,0.6,0.1c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.3-0.1,0.4-0.1
        c-0.1-0.2-0.1-0.3-0.2-0.4c0.3,0.1,0.7,0.4,0.7-0.1c0.3,0.3,0.5-0.2,0.7-0.3c0.1-0.1,0.3-0.2,0.3-0.4c0.1,0,0.3,0.1,0.4,0.1
        c0-0.1-0.1-0.3-0.1-0.4l0.1-0.1c0,0.4,0.3,0.1,0.3-0.1c0.2-0.1,0.3-0.2,0.5-0.4c0.3,0.3,0.7-0.1,1.1-0.2c-0.2-0.1-0.3-0.2-0.5-0.3
        l0-0.2c0.2,0.1,0.3,0.1,0.5,0.2c0.1-0.3,0.4-0.5,0.7-0.4c0.1-0.2,0.2-0.6,0.5-0.7c0,0.2,0.1,0.4,0.3,0.4c0-0.2,0-0.4,0.3-0.4
        c0.2,0,0.6,0.4,0.7,0.1c-0.3-0.1-0.6-0.2-0.9-0.3c0.1-0.3,0.4,0,0.6-0.1l-0.1,0.2c0.2-0.1,0.4-0.1,0.6-0.1c0.1-0.3,0.4-0.4,0.5-0.6
        c0.1,0,0.2,0.1,0.3,0.1c0.2-0.2,0.3-0.2,0.5,0c0.2-0.3,0.4-0.6,0.8-0.5c0-0.1,0-0.3,0-0.4c0.3,0,0.6-0.2,0.8-0.4
        c0.2,0,0.5,0.1,0.8,0.1c0.1-0.2,0.1-0.3,0.2-0.5l0.1,0.2c0.2-0.2,0.4-0.4,0.5-0.8c0,0.2,0.1,0.3,0.2,0.5c0.1-0.1,0.2-0.2,0.3-0.3
        c0,0,0.1,0.1,0.2,0.1c0.1-0.2,0.2-0.3,0.3-0.5c0.2,0.1,0.3,0,0.4-0.1c0.1,0,0.2,0.1,0.3,0.1c0-0.1-0.1-0.3-0.1-0.4
        c0.3,0.2,0.5-0.3,0.8-0.3c0.3-0.1,0.5-0.1,0.7-0.2c0.2,0,0.4,0,0.6,0c0-0.1,0-0.3,0-0.3c0.1-0.1,0.2-0.2,0.4-0.3
        c0,0.1,0.1,0.3,0.1,0.3c0.1-0.2,0.1-0.5,0.3-0.6c-0.1,0.2-0.1,0.4,0.1,0.6c0.1-0.3,0.3-0.5,0.5-0.6c0.6,0,1-0.8,1.7-0.5l-0.1-0.3
        c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0.3,0,0.5,0,0.8,0c0-0.1,0-0.3,0-0.4c0.1,0.2,0.1,0.3,0.1,0.5l0.3,0.1c-0.1-0.6,0.6-0.4,1-0.6
        c0.2,0,0.5,0,0.7,0c-0.1-0.1-0.2-0.2-0.3-0.3c0.4,0,0.8,0.2,1-0.3c0,0.1,0.1,0.2,0.2,0.3c0.5,0-0.3-0.6-0.2-0.7
        c0.2,0.2,0.5,0.4,0.8,0.5c0.3-0.4,0.8-0.5,1.2-0.9c0.1,0.5,0.5,0.1,0.8,0c0-0.1-0.1-0.3-0.1-0.4c0.3,0.1,0.6,0.2,0.9,0.3
        c-0.2-0.2-0.3-0.4-0.5-0.5c0.3,0,0.5,0.5,0.8,0.1c0.2,0.3,0.4,0.2,0.6,0c0.3,0.1,0.6-0.1,1-0.1c0-0.1,0.1-0.2,0.1-0.3
        c0.3-0.1,0.6-0.2,0.8,0.1c0-0.1,0.1-0.3,0.2-0.4c0.2,0,0.3,0.1,0.5,0.2c0-0.2-0.1-0.3-0.1-0.4c0.2,0.1,0.4,0.1,0.6,0.2
        c0-0.3-0.4-0.3-0.4-0.6c0.1,0.1,0.3,0.2,0.4,0.3c0.1-0.1,0.3-0.2,0.4-0.3c-0.1,0.2-0.1,0.4-0.2,0.6c0.1,0,0.3-0.1,0.4-0.1
        c0-0.1,0-0.2-0.1-0.3l0.2,0.1c0-0.1,0-0.2,0-0.3c0.1,0,0.2,0.1,0.3,0.2c0,0,0.1-0.1,0.2-0.2c0.1,0.2,0.3,0.2,0.5,0.2
        c0.1-0.2,0.1-0.8,0.4-0.4c0.2,0,0.4,0.1,0.6,0.1c0.4-0.2,1,0.1,1.4-0.3c-0.3,0.1-0.4,0-0.5-0.3l0.3,0c0,0,0-0.1,0-0.2
        c0.2,0,0.4,0,0.6,0c0.1,0.1,0.2,0.3,0.2,0.4l0.2-0.1c-0.1-0.1-0.1-0.2-0.2-0.4c0.1,0,0.3,0,0.4,0l-0.1,0.2c0.3-0.1,0.7,0.1,0.9-0.2
        c-0.2-0.1-0.3-0.2-0.5-0.3c0.2,0,0.4,0,0.5,0c0,0.2,0,0.3,0.1,0.5c0.2,0,0.5-0.1,0.7-0.1c0-0.1,0-0.3,0-0.3c-0.2,0-0.4-0.1-0.5-0.2
        c0.1,0,0.2-0.1,0.4-0.1c0.1,0.1,0.2,0.3,0.2,0.4c0.5,0,0.9-0.2,1.3-0.1l0-0.2c0.4,0,0.6-0.4,1-0.3l0-0.2c0.1,0,0.2,0,0.3,0
        c0,0.1,0,0.2,0,0.2l-0.1,0c-0.1,0.4-0.5-0.1-0.7,0.3c0.1,0,0.4,0.1,0.6,0.1c0.2-0.1,0.3-0.2,0.5-0.3c0.1,0.1,0.2,0.2,0.2,0.2
        c0.1-0.1,0.2-0.3,0.3-0.4l0,0.3c0.1-0.1,0.2-0.1,0.4-0.2c0.2,0,0.4,0.1,0.7,0.1l-0.1-0.3c0.1,0,0.4,0.1,0.5,0.1
        c-0.1-0.4-0.6-0.5-0.8-0.2c-0.1-0.1-0.2-0.1-0.2-0.2c0.3-0.2,0.7-0.3,1-0.1v-0.2l0.2,0l-0.1,0.3c0.2-0.2,0.4-0.3,0.7-0.5
        c-0.1,0.2-0.1,0.3-0.1,0.5c-0.2,0-0.4,0-0.6,0.1c0.1,0.2,0.3,0.2,0.5,0.1c0,0,0.1,0.1,0.2,0.1c0-0.1,0-0.2,0-0.3
        c0.3,0,0.6-0.1,0.8,0c0.1-0.3,0.4,0,0.6,0c0,0.1,0.1,0.2,0.1,0.2c0.4-0.1,0.2-0.5-0.1-0.6l-0.1-0.2c0.3,0,0.6,0.1,0.8,0.1
        c0,0,0.1-0.1,0.2-0.1l0.1,0.2c-0.1,0-0.2,0-0.3,0v0.3c0.1,0,0.4,0,0.6,0c0-0.1-0.1-0.3-0.2-0.4c0.1,0.1,0.3,0.2,0.4,0.3
        c0.2-0.2,0.5-0.2,0.8-0.2c0,0.1,0,0.2,0,0.3c0.2-0.2,0.4-0.4,0.7-0.4c-0.1,0.1-0.2,0.4-0.3,0.5c0.3-0.1,0.9-0.6,1-0.1
        c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.2,0.1,0.2,0.2c0.1-0.1,0.2-0.2,0.3-0.3l0.1,0.3l0.1,0c0-0.2,0-0.5,0-0.7c0.1,0.1,0.3,0.4,0.4,0.5
        c0.2,0,0.3,0,0.5,0c0,0,0,0.1,0,0.2c0.3,0,0.4-0.3,0.6-0.5c0,0.1,0,0.2,0,0.3c0.2,0.2,0.4,0.4,0.7,0.2c-0.1-0.1-0.6-0.2-0.4-0.4
        c0.2,0.1,0.5,0.2,0.8,0.2c0,0.1-0.1,0.3-0.2,0.4c0.3-0.3,0.8-0.4,1.2-0.2c-0.2-0.2-0.3-0.4-0.5-0.6c0.4,0,0.5,0.3,0.7,0.5
        c0.2-0.5,0.6,0.4,0.6-0.2c-0.1,0-0.3-0.1-0.4-0.1c0.1-0.4,0.4,0,0.6,0c0.2,0,0.5,0,0.6,0c-0.1,0.1-0.3,0.2-0.5,0.3
        c0.4,0.2,0.7-0.7,0.9,0c0.4-0.1,0.8,0,1.1-0.3c0,0.1,0.1,0.2,0.2,0.3c-0.2,0-0.3,0.1-0.4,0.1c0.1,0.1,0.2,0.1,0.4,0.2
        c0.1-0.1,0.2-0.2,0.4-0.2c0.1,0,0.3,0,0.4,0l-0.1,0.2c0.2,0.1,0.5,0.1,0.7,0.2l0.1,0c-0.1-0.1-0.1-0.3-0.2-0.4l-0.2,0.1
        c0-0.1,0-0.3,0-0.4c0.4,0.2,0.5,0.6,1,0.7c0-0.1,0-0.3,0-0.4c0.1,0,0.2,0,0.3-0.1c0.4,0.1,0.7,0.4,1,0.7c0-0.1,0-0.3,0-0.4l0.3,0
        c0,0.2-0.1,0.5-0.1,0.7c0,0,0.1-0.1,0.1-0.2c0.5,0.2,1.1,0.3,1.4-0.3c-0.3,0-0.4-0.1-0.5-0.3c0.3,0,0.5,0.1,0.7,0.3
        c0,0.1,0,0.2,0,0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.4-0.1,1-0.1,1.1,0.5c0.1-0.1,0.2-0.2,0.2-0.3c0.1,0.3,0.3,0.3,0.5,0.1l0,0.2
        c0.1,0,0.2-0.1,0.3-0.1c0,0.2,0.1,0.3,0.2,0.5c0.1-0.2,0.2-0.3,0.3-0.4c0,0.1,0,0.4,0.1,0.6c0.1,0,0.2-0.1,0.3-0.2
        c0.2,0,0.3,0,0.5,0c0,0.1-0.1,0.3-0.1,0.4c0.1-0.1,0.2-0.2,0.4-0.2c0.3,0.1,0.6,0.3,1,0.2l0,0.2c0.2-0.1,0.3,0.1,0.2,0.2
        c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0,0.2-0.1,0.3-0.2c0.2,0,0.3,0,0.5,0c0,0.3-0.5,0.2-0.7,0.4c0.3,0,0.6,0.1,1,0.1
        c-0.1-0.2-0.2-0.3-0.3-0.5c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.3,0.1,0.5,0.2c0,0.1-0.1,0.6,0.1,0.2c0-0.1,0.1-0.2,0.1-0.2l0.1,0
        c0,0.1,0,0.3-0.1,0.4c0.3,0.1,0.5,0.3,0.8,0.1c0.3,0.1,0.6,0.1,0.4,0.5c0.1-0.1,0.2-0.2,0.3-0.2c0.4,0.3,0.8,0.6,1.3,0.8l0,0.2
        c0.1,0,0.4,0,0.5-0.1l-0.1-0.2c0.2,0.1,0.5,0.1,0.5,0.4c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0.2-0.2,0.4-0.2,0.7c0.1-0.1,0.2-0.2,0.3-0.2
        c0.2,0,0.4,0.1,0.7,0.2c0.1-0.3,0.3-0.2,0.4,0c-0.1,0-0.3,0-0.5,0c0.2,0.2,0.4,0.5,0.6,0.7c0.2,0,0.6-0.1,0.5,0.3
        c0.3-0.1,0.5,0.2,0.6,0.5c0.1,0,0.2-0.1,0.3-0.1c0,0.2,0.1,0.4,0.4,0.4c0,0.2,0,0.4,0.2,0.4c0-0.2,0-0.5,0-0.7
        c0.1,0.2,0.1,0.4,0.1,0.6l0.2,0c0,0.1-0.1,0.3-0.1,0.4c0.3-0.2,0.5,0.1,0.6,0.3c0.2,0,0.6-0.2,0.7,0.1c0.1,0,0.2,0.1,0.3,0.2
        c-0.1,0-0.3,0.1-0.4,0.1c0.2,0.1,0.3,0.2,0.5,0.3c0,0.1,0,0.2,0.1,0.3c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0.1,0.2,0.2
        c-0.6-0.2-0.2,0.2-0.1,0.3c0.3,0.2,0.5,0.5,0.6,0.8c0-0.1,0-0.2,0.1-0.3l0.1,0c-0.1,0.2-0.1,0.5-0.2,0.7c0.1-0.1,0.2-0.2,0.3-0.3
        c0.3,0.2,0.5,0.5,0.8,0.7c-0.1,0.2-0.2,0.4-0.2,0.7c0.1,0,0.3,0.1,0.4,0.1c0-0.1,0.1-0.4,0.1-0.5c0.1,0.2,0.2,0.4,0.1,0.6
        c0.2,0.2,0.1,0.5,0.1,0.7c0,0,0.1,0,0.2,0c-0.1-0.2-0.1-0.4-0.2-0.6c0.3-0.1,0.2,0.3,0.4,0.5c0.1,0.2,0.2,0.3,0.4,0.5
        c0.1,0,0.2,0,0.3-0.1c0,0.1,0,0.3,0,0.4c0.2,0.1,0.5,0.2,0.4,0.5c0.1,0.1,0.2,0.2,0.2,0.2c-0.1,0.1-0.1,0.2-0.2,0.3
        c0.5-0.2,0.2,0.6,0.7,0.5c-0.1,0.2-0.2,0.3-0.3,0.2c-0.1-0.1-0.2-0.3-0.3-0.5c0,0.1-0.1,0.3-0.1,0.3c-0.2,0-0.3-0.1-0.4-0.2
        c0.1,0.2,0.2,0.2,0.4,0.3c0,0.1,0,0.2,0,0.3c0.2-0.1,0.5-0.2,0.7-0.2c0.2,0.3,0,0.7,0.2,0.9l0,0.1l0.2-0.1l0,0.2l0.2-0.1
        c0.1,0.1,0.2,0.2,0.2,0.3c0,0.2-0.1,0.4-0.2,0.6c0.3,0,0.5,0.3,0.4,0.6c0.1,0,0.2-0.1,0.2-0.2c0,0.2,0,0.4-0.1,0.5
        c0.3,0.1,0.6,0.3,0.6,0.7c-0.2-0.1-0.4-0.3-0.6-0.5c-0.2,0.2-0.3,0.3-0.5,0.5c0.2-0.1,0.3-0.3,0.5-0.2c0.1,0.1,0.2,0.2,0.3,0.3
        l-0.1,0.1c0.2,0.4-0.2,0.6-0.4,0.8c0.4,0,0.4-0.5,0.7-0.7c0.5,0.1,0.1,0.6-0.2,0.7c0.3,0.4,0.4-0.2,0.7-0.3
        c-0.1,0.2-0.2,0.4-0.4,0.5c0.1,0.4-0.2,0.6-0.4,0.9c0,0,0.1,0,0.2,0c0-0.4,0.4-0.6,0.7-0.7c-0.1,0.2-0.5,0.6-0.1,0.7
        c0,0.1,0,0.3,0,0.4l-0.2-0.1c0,0.1,0,0.3,0,0.4c-0.2-0.2-0.4-0.3-0.6-0.5c0.1,0.2,0.3,0.4,0.5,0.5c0,0.2,0,0.4-0.1,0.6
        c0.3-0.1,0.4,0.2,0.5,0.4c-0.1,0.4,0.1,0.9,0.1,1.3c0.1,0.1,0.2,0.2,0.2,0.3l-0.3,0c0,0.2,0.1,0.5,0.3,0.6c-0.1,0-0.2,0.1-0.3,0.1
        c0.1,0.4,0,0.8,0.2,1.2c0.1,0.1-0.1,0.3-0.1,0.4l0-0.4c-0.7,0.1-0.1,0.5,0.1,0.8c-0.1,0-0.3,0-0.4-0.1c-0.1,0.3-0.1,0.6,0.2,0.8
        c-0.1-0.1-0.4-0.2-0.5-0.3c0,0.1-0.1,0.3-0.1,0.4c0.1,0,0.3-0.1,0.3-0.1c0,0.1-0.1,0.3-0.1,0.3c-0.1,0-0.2,0.1-0.3,0.1
        c0.1,0,0.2,0.1,0.3,0.1c0,0.1,0,0.2,0,0.2c-0.3,0.1-0.2,0.5-0.3,0.8c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0.3-0.2,0.5-0.3,0.8
        c0.1-0.1,0.3-0.1,0.4-0.2c0,0.1,0,0.2,0,0.2c-0.1,0-0.2,0-0.3,0c-0.1,0.2,0,0.5-0.3,0.5c0.2,0.4-0.3,0.6-0.1,1l-0.2-0.1
        c0,0.2,0,0.4,0.1,0.6l-0.2-0.1c0.2,0.7-0.6,1.2-0.5,1.8l-0.1,0c0,0.2,0,0.6-0.3,0.6c0,0,0,0.1,0,0.2c-0.4,0-0.1,0.2,0,0.4
        c-0.4,0.1-0.5,0.5-0.6,0.8c-0.2,0.4-0.5,0.7-0.7,1.1c-0.2,0.1-0.3,0.4-0.4,0.6c-0.1,0.1-0.2,0.3-0.3,0.4c-0.3,0.3-0.4,0.8-0.7,1.1
        c-0.2,0.3-0.4,0.5-0.6,0.8c-0.1,0.2-0.2,0.4-0.4,0.5c-0.1,0.2-0.3,0.3-0.5,0.5c0,0.3-0.3,0.5-0.5,0.7c-0.2,0.1-0.3,0.3-0.4,0.4
        c-0.3,0.2-0.5,0.7-0.8,0.9c-0.3,0.2-0.5,0.6-0.7,0.9c-0.6,0-0.7,0.6-1.1,0.9c-0.1,0.2-0.2,0.4-0.5,0.4c-0.3,0.3-0.5,0.7-0.9,0.8
        c0,0.1-0.1,0.3-0.1,0.3c-0.5-0.1-0.7,0.4-1,0.7c-0.3,0.4-0.8,0.4-1.1,0.7c-0.4,0.4-0.9,0.7-1.4,0.9l0.1,0.1
        c-0.4,0.1-0.7,0.4-1.1,0.6c0,0.2-0.3,0.2-0.5,0.2c-0.3,0.3-0.7,0.4-1.1,0.5l0.1,0.2c-0.1,0-0.3-0.1-0.4-0.1l0,0.2
        c-0.1,0-0.2,0-0.3-0.1l0.1,0.2c-0.1,0-0.3,0-0.4,0c-0.1,0.3-0.4,0.3-0.7,0.4l0,0.1c-0.1,0-0.2,0-0.3-0.1c-0.3,0.1-0.6,0.3-0.9,0.4
        c-0.9,0.7-1.9,1-2.9,1.5c-0.1,0-0.2,0-0.3,0c-0.1,0.3-0.5,0.3-0.7,0.4c-0.3,0.2-0.5,0.3-0.8,0.2c-0.7,0.3-1.4,0.7-2.2,0.7l0,0.1
        c0,0-0.1,0-0.2,0c-0.5,0.2-1.1,0.2-1.6,0.2c0,0.1-0.1,0.2-0.1,0.3c-0.2-0.1-0.5-0.3-0.6,0.1c-0.8-0.1-1.4,0.5-2.1,0.3l0.1,0.2
        c-0.3,0-0.7-0.1-1,0.1c-0.4,0.1-0.8,0-1.2,0.1c-0.3,0-0.7-0.1-1,0.1c-0.3,0-0.7-0.3-1,0c-0.2-0.3-0.5,0.2-0.8-0.1
        c-0.7,0-1.4,0.1-2.1-0.2c0.8,0,1.5,0,2.3,0c0.3-0.1,0.5,0,0.8-0.1c0.6,0.3,1.2-0.2,1.8,0.1c0-0.3,0.3-0.1,0.4-0.1
        c0.2,0,0.5,0,0.7-0.1c0-0.1,0-0.2,0-0.3c0.1,0.3,0.4,0.2,0.7,0.2c0.1-0.1,0.2-0.2,0.4-0.1c0.6,0,1.2-0.3,1.8-0.4
        c0.4-0.1,0.8-0.3,1.2-0.3c0.2,0.1,0.4,0,0.5-0.1c0.2,0.1,0.5,0.1,0.5-0.2c0.2,0,0.3,0,0.5,0c0.1-0.1,0.2-0.2,0.2-0.3
        c0.2,0,0.4,0,0.6,0.1c0.1-0.4,0.7-0.2,0.9-0.5l0-0.1c0.5,0.1,1-0.4,1.6-0.3c-0.1-0.4,0.2-0.3,0.5-0.2c0-0.1,0-0.2,0-0.3
        c0.1,0,0.4,0,0.5,0.1c0.1-0.2,0.3-0.3,0.5-0.4c0.1,0.1,0.2,0.2,0.2,0.2c0-0.1,0-0.3,0.1-0.3c0.3-0.1,0.7-0.1,0.8-0.4
        c0.1,0,0.3,0,0.3,0c0.1-0.1,0.3-0.3,0.4-0.4c0.3,0.1,0.5-0.1,0.7-0.3c0.1,0,0.3,0,0.4,0c0.1-0.1,0.1-0.2,0.2-0.3
        c0.2,0,0.3-0.1,0.5-0.2c0-0.1,0-0.2-0.1-0.3c0.3,0,0.5,0,0.8-0.2c0-0.1-0.1-0.2-0.1-0.3l0.1,0c0,0.1,0,0.2,0,0.3
        c0.2-0.1,0.4-0.2,0.6-0.3c0.1,0,0.2,0,0.3-0.1c0.1-0.2,0.1-0.3,0.2-0.4c0.3,0.6,0.4-0.2,0.7-0.4c0.2,0,0.4,0,0.5-0.2
        c0.4,0.2,0.4-0.2,0.6-0.4c0.3-0.2,0.6-0.4,1-0.6c0.1-0.2,0.2-0.6,0.5-0.4c0-0.1-0.1-0.3-0.1-0.4c0.1,0,0.4,0.1,0.5,0.1
        c0-0.2,0-0.3,0.2-0.4c0.2-0.1,0.3-0.2,0.4-0.4c0.1,0,0.2,0,0.2,0.1c0-0.1,0-0.3,0-0.4c0.1,0,0.3,0.1,0.4,0.2c0-0.2,0.1-0.3,0.1-0.5
        l0.2,0l0-0.1c0.3-0.1,0.6-0.4,0.8-0.7c0.2-0.3,0.2,0.1,0.3,0.2c0-0.2,0.1-0.5,0.1-0.7c0.5,0.2,0.3-0.5,0.7-0.5
        c0-0.1-0.1-0.2-0.1-0.3l0.2,0.1c0-0.1,0.1-0.2,0.1-0.2c0.1,0,0.3,0,0.3,0.1c0.2-0.4,0.4-0.9,0.8-1.1c0-0.1,0.1-0.2,0.1-0.3
        c0.2-0.1,0.4-0.3,0.3-0.5c0.1,0,0.2,0,0.3,0.1c0.1-0.3,0.3-0.5,0.4-0.7l0.1,0l0-0.1c0.4-0.1,0.3-0.9,0.8-0.7c0-0.2,0.1-0.4,0.2-0.6
        c0.3-0.1,0.4-0.4,0.5-0.7c0.3-0.5,0.5-1,0.8-1.5c0-0.2,0.1-0.4,0.3-0.4c0.3-0.7,0.8-1.2,0.9-2c0.1,0,0.3,0,0.3,0
        c-0.1-0.1-0.2-0.2-0.2-0.3c0.3-0.2,0.3-0.6,0.4-0.9c0.4-1.1,0.6-2.2,1-3.2c0.1-0.6,0.1-1.2,0.4-1.8c-0.1-0.3-0.1-0.5,0.1-0.7
        c-0.2-0.5-0.1-1,0-1.4c-0.2-0.3-0.1-0.7,0.1-1c-0.2-0.3-0.3-0.6-0.2-1c0.1-0.3-0.1-0.5-0.2-0.7c0.1-0.4-0.1-0.7-0.1-1.1
        c0.1-0.6-0.6-1-0.3-1.6c-0.2-0.3-0.4-0.6-0.3-1c-0.4-0.5-0.4-1.3-0.7-1.9c-0.3-0.4-0.3-0.9-0.5-1.2c-0.3-0.4-0.5-0.9-0.8-1.4
        c-0.4-0.6-0.9-1.1-1.2-1.7c-0.1-0.3-0.4-0.3-0.6-0.5c-0.1-0.6-0.7-0.7-0.9-1.2c-0.2-0.2-0.3-0.3-0.5-0.5c-0.3,0-0.5-0.3-0.6-0.6
        c-0.6-0.4-1.2-0.6-1.7-1.2c-0.3-0.3-0.8-0.4-0.9-0.8c-0.5,0.1-0.8-0.4-1.2-0.6c-0.4-0.2-0.9-0.4-1.3-0.7c0-0.1-0.1-0.2-0.1-0.3
        c-0.3-0.1-0.6-0.1-1-0.2c0-0.1,0-0.3,0-0.4c-0.4-0.1-0.7-0.2-1-0.4c-0.3,0-0.5,0-0.8,0.1c0-0.1,0.1-0.2,0.1-0.3
        c-0.3-0.2-0.6-0.4-0.9-0.4c-0.6-0.4-1.3-0.5-1.9-0.8c0-0.5-0.4-0.3-0.7-0.2c-0.2-0.1-0.3-0.2-0.4-0.3c-0.2,0.2-0.4,0.3-0.6,0.1
        l0.3-0.1c-0.2-0.2-0.5-0.1-0.7-0.1c-0.2-0.1-0.3-0.3-0.5-0.4c-0.1,0-0.2,0.1-0.3,0.2c-0.1-0.1-0.2-0.2-0.3-0.2
        c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1,0.1-0.4,0.2-0.5,0.2c0-0.1,0.1-0.3,0.2-0.4c-0.2,0.2-0.5,0.2-0.8,0.2
        c-0.1-0.2-0.3-0.1-0.5,0c0,0,0-0.2,0-0.2c-0.1,0-0.4-0.1-0.6-0.2c0,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.2,0-0.3
        c-0.4,0-0.8-0.2-1.2-0.3c-0.2,0.1-0.5,0.2-0.6-0.1c0,0-0.1,0.1-0.2,0.1c-0.3-0.2-0.7-0.3-1-0.1c-0.2-0.1-0.3-0.2-0.5-0.3
        c-0.2,0-0.5,0.1-0.7,0.2c0-0.1-0.1-0.2-0.2-0.3c-0.2,0-0.4-0.1-0.6-0.1c0,0.1,0,0.2,0,0.2l-0.1,0l0.1-0.2c-0.4-0.1-0.8,0.1-1.2,0.3
        c0.1-0.4-0.3-0.5-0.4-0.1c-0.3-0.1-0.6-0.1-0.8-0.2c0,0.1,0,0.2,0.1,0.3c-0.4-0.3-0.9-0.1-1.3-0.3c-0.5,0.3-1.1-0.2-1.5,0.2
        c-0.2,0-0.4-0.2-0.5-0.3c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1,0-0.3,0.1-0.4,0.1c-0.3-0.1-1-0.2-0.7,0.4c-0.2-0.2-0.4-0.3-0.6-0.5
        c-0.2,0.1-0.5,0.2-0.7,0.3c-0.2-0.1-0.4-0.1-0.6-0.2c-0.4,0.2-0.9,0.3-1.3,0.4c-0.2-0.2-1-0.4-0.7,0.1c0.2,0,0.4,0.1,0.6,0.2
        c-0.1,0-0.4,0-0.5,0c-0.1-0.2-0.3-0.3-0.4-0.4c-0.1,0.2-0.2,0.2-0.4,0c-0.4,0.1-0.7,0.4-1.1,0.3c-0.3,0.3-0.6-0.1-0.9,0
        c-0.2,0-0.3,0.2-0.4,0.3c-0.1,0.2-0.4,0-0.5,0c0.1,0.2,0.2,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0,0.3,0.1,0.6,0,0.9
        c-0.1,0.2-0.1,0.4,0,0.6c-0.3,0.1-0.6,0.4-1,0.3c-0.3,0.1-0.6,0.3-0.8,0.1c0-0.1,0-0.3,0-0.4c-0.2,0.1-0.5,0.2-0.7-0.1
        c-0.3,0.2-0.6,0.2-0.8,0.5c-0.1-0.2-0.3-0.3-0.3-0.5c-0.2,0.1-0.4,0.1-0.5-0.1c-0.4,0.2-0.8,0.1-1.1,0.3c0.1-0.1,0.3-0.3,0.4-0.4
        c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0.2-0.2,0.3-0.3,0.1c-0.3,0-0.6,0-0.9-0.1c0.1,0.2-0.2,0.6-0.2,0.2l0-0.2c-0.1,0-0.2,0-0.3,0.1
        l-0.1,0.2c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.3-0.1-0.4-0.1c0.6,0.5,0.9,1.2,1,1.9l-0.3-0.1
        c0,0.1,0,0.2,0.1,0.3c-0.3-0.1-1,0.2-1-0.4c-0.2,0.1-0.3,0.2-0.4,0.4c-0.4,0.1-0.5-0.4-0.7-0.5c-0.1-0.1-0.2-0.2-0.2-0.3
        c0.1,0.2,0.2,0.5,0.2,0.7l-0.4-0.5c-0.1,0.1-0.2,0.2-0.3,0.4c-0.1-0.1-0.2-0.1-0.3-0.2l0.3,0c0-0.1-0.1-0.2-0.2-0.3
        c-0.2,0.2-0.6,0.2-0.8,0c0,0-0.1,0.1-0.2,0.2c-0.2,0-0.5,0-0.7,0c0.2-0.1,0.3-0.3,0.4-0.4c-0.3,0.1-0.5,0.4-0.8,0.1l0.1,0.3
        c-0.2-0.1-0.5,0-0.7,0.1c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0.2-0.2,0.4-0.2,0.6c-0.1,0-0.2,0-0.2-0.1c-0.2-0.2-0.5-0.2-0.7-0.1
        c0.5,0.6,1,1.2,1.5,1.7c0.1,0.2-0.2,0.6-0.3,0.2c-0.1-0.2-0.3,0-0.4,0c-0.1-0.2-0.3-0.3-0.4-0.1c0.1,0,0.3,0.1,0.4,0.1
        c-0.4,0.4-0.8,0-1.2-0.1c0,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.2-0.1-0.3-0.2c-0.3,0-0.7-0.1-1,0c0-0.1-0.1-0.2-0.1-0.3
        c-0.1,0.1-0.3,0.2-0.4,0.4l0.1-0.4c-0.2,0.1-0.4,0.1-0.5,0.2c-0.1-0.1-0.2-0.2-0.3-0.3c0,0.1,0,0.2,0,0.3c-0.3-0.2-0.7,0.1-0.8-0.2
        c-0.1,0.3-0.3,0.4-0.6,0.2c-0.1,0.1-0.3,0.2-0.4,0.4c-0.1,0-0.2-0.1-0.3-0.1l0.2,0.3c-0.1,0-0.4-0.1-0.6-0.1
        c0.3,0.3,0.7,0.5,0.9,0.9c0.3,0.5,0.9,0.8,1.3,1.3c-0.3,0-0.5-0.1-0.8-0.1c0,0,0,0.1,0,0.2c-0.4,0-0.9-0.1-1.3-0.2
        c0,0.1,0,0.3,0,0.4l0-0.2c-0.2,0-0.6-0.2-0.6,0.1c0,0-0.1-0.1-0.2-0.2c-0.2,0.1-0.4,0.1-0.6,0.2c-0.2-0.2-0.5-0.2-0.4-0.5l-0.3,0
        c0,0.1,0,0.3,0,0.3c-0.1-0.1-0.3-0.2-0.3-0.4c-0.3-0.1-0.5,0.2-0.6,0.4c-0.1-0.1-0.2-0.2-0.2-0.4c0.1-0.1,0.3-0.1,0.4-0.2
        c-0.2,0-0.5,0-0.7,0.1l0-0.2c0,0-0.1,0.1-0.2,0.1c0.2,0.1,0.3,0.3,0.4,0.5c-0.2,0-0.5-0.1-0.7-0.2c0,0.5-0.6,0.3-0.8,0.2l-0.1,0.1
        l0.3,0.1c-0.1,0.3-0.2,0.6-0.1,0.8c-0.1,0-0.2,0-0.3-0.1l0,0.2l0.2-0.1c0,0.1,0,0.3,0,0.4c0.4,0.3,0.7,0.6,1.1,0.9
        c-0.4,0-0.8-0.3-1.2-0.2c-0.2-0.2-0.5-0.1-0.7,0.1c-0.1-0.2-0.2-0.3-0.4-0.3c0,0.1,0.1,0.3,0.2,0.4c-0.4-0.1-0.8-0.4-1.1-0.5
        l-0.2,0.1c0.1,0,0.3,0.1,0.4,0.1c0,0.1-0.1,0.2-0.1,0.3c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1,0.1-0.2,0.1-0.3,0.2c0,0-0.1,0-0.1,0
        c-0.2,0.2-0.4,0.1-0.4-0.2c-0.1,0.1-0.3,0.1-0.4,0.3c-0.4-0.2-0.6,0.3-1,0.3l0,0.1c-0.1,0-0.3,0.1-0.4,0.1l-0.2-0.2
        c-0.2,0.3,0.2,0.3,0.4,0.3c-0.2,0.1-0.3,0.1-0.5,0.2c0,0.1,0.1,0.2,0.1,0.3c-0.1,0-0.2,0.1-0.3,0.1c0.1,0.2,0.5,0.4,0.4,0.7
        c0.1,0,0.2,0,0.2,0c0,0-0.1,0.1-0.1,0.2c0.6,0.5,1.3,1.1,2.1,1.2c0.3,0,0.5,0.1,0.8,0.1l0,0.1c-0.6-0.1-1.1,0.2-1.7,0.1l0,0.2
        c-0.2,0-0.5,0-0.7-0.1c0,0,0,0.1,0,0.2c-0.2,0-0.4,0-0.6,0c-0.3-0.1-0.5,0-0.7,0.2c0-0.1,0.1-0.3,0.1-0.4l0.1-0.1
        c0-0.1,0-0.2-0.1-0.3c0,0.1-0.1,0.2-0.2,0.2c-0.2,0.1-0.3-0.1-0.4-0.3c0.1,0.3,0.3,0.6,0.4,0.9c-0.3-0.1-0.5-0.4-0.8-0.3
        c-0.3,0.1-0.4-0.3-0.6-0.3c-0.2,0.3,0.4,0.4,0.3,0.7c-0.4-0.3-0.8-0.3-1.3-0.4c-0.2,0.2-0.5,0.3-0.7,0.1c-0.1,0-0.2,0.1-0.3,0.2
        c-0.1-0.3-0.4-0.1-0.6,0c-0.1,0-0.3-0.1-0.4-0.1c0-0.1,0-0.2,0-0.2l-0.2,0c0,0.1,0,0.2,0,0.3l-0.2-0.1c-0.2,0.1-0.4,0.4-0.6,0.3
        c0.1-0.1,0.2-0.3,0.2-0.3c-0.1,0-0.3,0-0.4,0c0,0.1,0,0.2,0.1,0.3c-0.2,0-0.4,0.1-0.6,0.1c0.1,0.2-0.2,0.7,0.2,0.8l0,0.1
        c0.2,0.1,0.3,0.2,0.5,0.4c0,0.1-0.1,0.2-0.1,0.3c0.2,0.1,0.4,0.2,0.6,0.3c-0.3,0.1-0.6,0-0.9-0.1c-0.4,0-0.7-0.1-1.1,0.1
        c-0.1-0.4-0.4,0.1-0.6,0.1c-0.3,0.1-0.5-0.2-0.7-0.4c0,0-0.1,0.1-0.1,0.1c0.2,0.2,0.4,0.3,0.5,0.5c-0.2,0-0.4-0.1-0.5-0.2
        c-0.4,0.2-0.9,0.2-1.2,0.5c0-0.2,0-0.4,0.2-0.6c-0.3,0.1-0.5,0.2-0.8,0.2c-0.2,0.1-0.4,0.2-0.6,0.3c0.2,0,0.3,0,0.5,0l0,0.2
        c0-0.1,0.1-0.3,0.1-0.4c0.3-0.1,0.5,0.2,0.6,0.5c-0.3,0-0.6,0.1-0.9,0.2c-0.1,0.2,0.2,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.7
        c-0.1-0.1-0.3-0.2-0.4-0.3c0.1,0.2,0.2,0.5,0.3,0.7c-0.2,0.1-0.5,0.5-0.5,0c-0.2,0.1-0.3,0.3-0.2,0.5c-0.2,0-0.7,0-0.6,0.3l-0.1,0
        l0-0.2c-0.2,0-0.4,0-0.7,0c-0.1-0.3-0.4-0.4-0.7-0.5c-0.2-0.1-0.4-0.2-0.5-0.3c0.1-0.1,0.3-0.2,0.4-0.2c-0.1-0.1-0.2-0.4-0.4-0.3
        c-0.1,0.1-0.2,0.3-0.3,0.5c-0.2,0-0.3,0-0.5,0c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1-0.1-0.2-0.3-0.2-0.3c0,0.2,0,0.4-0.1,0.6
        c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1-0.1-0.2-0.1-0.3-0.2c0,0.2,0.1,0.3,0,0.5c-0.1-0.1-0.2-0.2-0.3-0.2c-0.4,0.3-0.8,0.6-1,1
        c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.2-0.4,0.2-0.6,0.3c0,0.1,0,0.3,0.1,0.4c-0.2-0.2-0.3-0.1-0.4,0.1
        c-0.1,0-0.2-0.1-0.3-0.2c-0.1,0.3-0.4,0.4-0.6,0.5c0,0.2-0.1,0.4-0.2,0.5c-0.1,0-0.2-0.1-0.3-0.1c0,0.5-0.7,0.5-0.9,0.8
        c-0.1,0.1-0.3,0.2-0.4,0.3c-0.2,0.1-0.3,0.1-0.5,0.2c0.1,0.1,0.2,0.2,0.2,0.3c-0.3,0-0.4-0.2-0.7-0.1c0,0.1,0,0.4,0,0.5
        c-0.2,0-0.5,0-0.7,0c0,0.2,0,0.3,0.1,0.5c-0.1,0-0.3-0.1-0.4-0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0-0.2-0.1-0.3-0.1l0.1,0.2
        c-0.1,0-0.2,0-0.3,0c0,0.1,0.1,0.3,0.1,0.4c-0.6,0-1,0.5-1.3,0.9c-0.4,0.1-0.6,0.5-1,0.7c-0.2,0.1-0.4,0.2-0.5,0.5
        c-0.1-0.1-0.2-0.2-0.2-0.2c-0.1,0.2-0.2,0.4-0.4,0.5c0,0.4-0.3,0.7-0.7,0.7c0,0.1,0,0.3,0,0.4c-0.1-0.1-0.3-0.2-0.4-0.2
        c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.2-0.4,0.3c0.1,0,0.4,0,0.6,0c-0.2,0.1-0.4,0.2-0.6,0.1c-0.1,0-0.3,0.1-0.3,0.1
        c-0.1,0.6,0.7,0.7,1,1.1c0.4,0.2,0.7,0.7,1.2,0.8c0.4,0,0.3,0.6,0.7,0.6c0.8,0.2,1.4,0.9,2,1.3c0.7,0.5,1.3,1,2,1.6
        c0.1,0.1,0.2,0.1,0.3,0.1c0,0.3,0.3,0.3,0.5,0.4c0.4,0.1,0.5-0.3,0.7-0.5c-0.1,0.2-0.2,0.5-0.2,0.7c0.1,0,0.4-0.1,0.5-0.1
        c0.2-0.3,0.4-0.6,0.7-0.8l0,0.3c0.3,0,0.5,0,0.8,0c-0.1-0.1-0.3-0.3-0.4-0.4c0.2,0,0.4,0,0.6,0c-0.1,0.1-0.1,0.2-0.2,0.4
        c0.3,0,0.9-0.1,0.5-0.6c0.2,0.2,1,0.5,1-0.1c0.1,0,0.3,0.1,0.4,0.1c0-0.1,0-0.3,0-0.4c0.1,0,0.2,0.1,0.2,0.2
        c0.1-0.3,0.4-0.1,0.7-0.1c-0.1-0.1-0.2-0.2-0.3-0.2c0-0.1,0-0.3,0-0.4l0.3,0c-0.1,0.1-0.2,0.3-0.2,0.4c0.3-0.1,0.5-0.3,0.8-0.3
        c-0.1-0.1-0.2-0.2-0.3-0.3c0.4,0,1.1,0.3,1.3-0.3c-0.3,0-0.5,0.1-0.6-0.1c0.2,0,0.5,0,0.7,0c0,0.1,0,0.4-0.1,0.6l0.2-0.2
        c0-0.1,0-0.2,0-0.3c0.2,0,0.3-0.1,0.4-0.2c0.2,0,0.4,0.1,0.5,0.2c0.1-0.2,0.3-0.3,0.4-0.5c0,0.1,0.1,0.2,0.2,0.3
        c0.1-0.1,0.2-0.2,0.4-0.3c-0.2-0.3-0.5-0.3-0.7-0.4c0.2,0,0.4,0,0.6,0l0,0.2c0.3-0.2,0.5-0.3,0.9-0.3c-0.2,0.2-0.4,0.3-0.6,0.3
        c-0.1,0.1-0.1,0.2-0.2,0.4c0.3-0.1,0.6-0.3,0.9-0.2c0-0.1-0.1-0.3-0.1-0.4c0.3-0.1,0.7-0.2,1-0.4c0,0.1,0.1,0.2,0.1,0.3l-0.3,0
        l0,0.2c0.3,0,0.4-0.2,0.5-0.5c0.1,0,0.2,0.1,0.3,0.1c0.1-0.1,0.1-0.3,0.2-0.4c0.3,0,0.6-0.1,0.9-0.1c-0.1-0.2-0.2-0.3-0.3-0.5
        c0.2,0.1,0.3,0.3,0.5,0.4c0-0.1,0.1-0.2,0.1-0.2c0.8,0.1-0.1-0.4-0.3-0.3l0-0.1c0.2,0,0.5,0,0.6,0.1c0.2,0.2,0.6,0,0.9,0.2
        c-0.1-0.1-0.2-0.3-0.3-0.4c0.1,0,0.3,0.1,0.4,0.1l0-0.2c0.2,0,0.5-0.1,0.5,0.2c0.2-0.2,0.4-0.4,0.6-0.6c0.2,0,0.7,0.2,0.6-0.3
        c-0.1,0-0.3,0.1-0.3,0.1v-0.1c0.2-0.1,0.3-0.2,0.5-0.3c0,0.1-0.1,0.4-0.2,0.5c0.1-0.1,0.3-0.2,0.4-0.2l0.1,0.2c0.2-0.1,0.5,0,0.7,0
        c0,0,0-0.1,0-0.1c-0.2-0.1-0.4-0.1-0.5-0.3c0.3-0.1,0.7,0.1,0.9-0.2c0.1-0.1,0.3-0.2,0.4-0.3c0,0.1,0,0.2,0,0.3
        c0.2-0.1,0.3-0.1,0.5-0.3c0.1,0.1,0.3,0.2,0.4,0.3c0,0,0-0.1,0-0.2c-0.1,0-0.2-0.1-0.3-0.2c0.1,0,0.3,0,0.4,0l0-0.2l0.3,0.1
        c0,0.1,0,0.3,0,0.3c0.1-0.2,0.2-0.4,0.4-0.5c0,0.1,0,0.2,0,0.3c0.1,0,0.2,0,0.3,0c-0.3-0.4,0.3-0.8,0.6-0.4c0-0.1-0.1-0.3-0.1-0.4
        c0.1,0.1,0.3,0.2,0.4,0.3c0.3-0.2,0.7-0.4,1-0.5c0,0.1,0,0.3,0,0.4c0.3,0,0.3-0.3,0.3-0.5c0.2-0.3,0.5-0.5,0.8-0.8
        c-0.1,0.2-0.3,0.5-0.5,0.7c0.1,0.2,0.5-0.2,0.7,0c0-0.1,0-0.3-0.1-0.4c0.2,0.2,0.3,0.2,0.6,0.1c0,0.1,0,0.2,0,0.3
        c0.1,0,0.4-0.1,0.5-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c0.2,0,0.5,0,0.6,0c0,0.1,0,0.3-0.1,0.4c0.1-0.1,0.3-0.1,0.4-0.2
        c0-0.1-0.1-0.2-0.2-0.3c0.1,0,0.3,0,0.5,0c0-0.2,0.1-0.3,0.3-0.4c0,0.1,0,0.3-0.1,0.4c0.1,0,0.3-0.1,0.4-0.1
        c-0.1-0.1-0.2-0.2-0.2-0.3c0.1,0.1,0.3,0.1,0.4,0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0,0.3-0.1,0.4-0.1c0.4,0.5,0.6-0.2,0.6-0.5
        c0.2-0.1,0.6-0.4,0.7,0c-0.2,0.1-0.5,0.1-0.7,0.2c0,0.1,0.1,0.3,0.2,0.4c0.1-0.3,0.4-0.2,0.6-0.1c0-0.1-0.1-0.3-0.1-0.4
        c0.3-0.2,0.5-0.5,0.9-0.4c0,0.2,0,0.4-0.1,0.5c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1,0-0.3,0.1-0.4,0.1c0.1,0.1,0.2,0.3,0.3,0.4
        c0-0.1,0-0.3,0-0.4c0.1,0,0.2,0.1,0.3,0.2c0.3-0.2,0.5-0.3,0.9-0.4c0.1-0.1,0.2-0.3,0.2-0.4c0.2,0.1,0.3,0.1,0.5,0.2
        c0-0.1-0.1-0.2-0.1-0.3c0.3,0.1,1.1,0.2,0.6-0.3c0.2-0.1,0.3-0.2,0.5-0.2c0,0.2-0.2,0.3-0.3,0.4c0.1,0,0.3-0.1,0.4-0.1L406,325
        c0.2-0.1,0.2-0.2,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2l0,0.3c0.1-0.1,0.1-0.2,0.2-0.3c0.1,0,0.3-0.1,0.3-0.2c0.1,0.1,0.2,0.2,0.4,0.3
        c0-0.1,0.1-0.3,0.1-0.4c0.1,0,0.2,0,0.3,0c0-0.1,0.1-0.3,0.1-0.3c-0.1,0-0.2,0-0.3,0c0,0,0-0.2,0-0.2c0.2,0,0.4,0.3,0.4,0
        c0,0.1,0.1,0.2,0.2,0.3c0.1-0.1,0.2-0.2,0.2-0.2c0.1,0.5,0.5-0.1,0.8-0.1l0-0.3c0.1,0.1,0.3,0.2,0.4,0.3c0-0.1,0-0.2,0-0.3l0.3,0
        l0,0.2c0.1,0,0.2,0.1,0.3,0.1l-0.2-0.4c0.2,0,1,0.4,0.5-0.2c0.3,0.1,0.6,0.1,0.9,0.2c0.1-0.3,0.1-0.5,0.2-0.8
        c0.2,0.1,0.5,0.1,0.7,0.3c0.1-0.4,0.6-0.6,1-0.5c0.2,0.1,0.5,0,0.6-0.2c0.1,0,0.2,0,0.3,0.1c0-0.3,0.3-0.4,0.5-0.6
        c0.1,0.1,0.2,0.2,0.3,0.3c0.1-0.1,0.2-0.3,0.2-0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.3-0.2,0.7-0.4,1-0.6l-0.3,0l0-0.1c0.2,0,0.4,0,0.6-0.1
        c-0.1-0.3,0.2-0.4,0.5-0.3l0,0.2c-0.1,0-0.2,0-0.3,0c0,0.1,0,0.3,0,0.4c0.2-0.1,0.4-0.2,0.4-0.4l0.3,0c-0.1-0.1-0.2-0.3-0.3-0.4
        c0.3-0.1,0.8-0.3,0.9,0.2c-0.2,0-0.5-0.1-0.6-0.2c0.1,0.6,0.9,0.4,0.7-0.1c0.1-0.1,0.2-0.2,0.3-0.2c-0.1,0.2-0.1,0.5-0.2,0.7
        c0,0,0.1,0,0.2,0c0-0.3,0.2-0.6,0.3-0.8c0.1,0.1,0.2,0.2,0.3,0.3c0-0.1,0-0.4,0-0.5c0.2,0,0.4,0,0.5,0.2l0-0.2
        c0.2,0,0.4-0.1,0.6-0.3c0,0.3-0.1,0.5-0.1,0.8c0.1-0.2,0.1-0.4,0.1-0.6c0.4,0,0.5-0.4,0.8-0.5c0,0.2,0.1,0.3,0.3,0.4
        c0-0.2-0.1-0.4-0.1-0.5c0.1,0,0.3,0.1,0.4,0.2c0.1-0.1,0.2-0.4,0.4-0.3c0.2,0,0.4,0.2,0.6,0.2c0-0.2-0.2-0.2-0.3-0.3
        c0.5,0.1,0.4-0.6,1-0.5c0,0.2-0.2,0.3-0.4,0.3c0.1,0.1,0.3,0.1,0.4-0.1c0.1,0,0.2,0,0.3,0.1c0-0.1-0.1-0.3-0.1-0.4
        c0.3-0.1,0.7-0.1,1-0.3c0.1,0,0.2,0,0.3,0c0.2-0.2,0.4-0.3,0.7-0.3c0.1-0.2,0.2-0.3,0.4-0.3c0.1,0,0.2,0.1,0.2,0.1
        c0.2-0.1,0.4-0.2,0.7-0.3c0-0.1,0-0.3,0.1-0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.3-0.3,0.9-0.3,1.1-0.8c0.1,0,0.2,0,0.3,0.1
        c0.2-0.2,0.4-0.4,0.6-0.4c0.4-0.3,0.9-0.5,1.4-0.8c0.1-0.1,0.2-0.2,0.4-0.4c0,0.1,0.1,0.2,0.1,0.4c0-0.5,0.5-0.7,0.9-0.9
        c0.3,0.1,0.5-0.3,0.7-0.4c0.9-0.5,1.8-1.1,2.6-1.7c1.1-0.7,2.2-1.6,3.2-2.6c-0.3,0.6-0.8,1-1.2,1.4c-0.5,0.4-0.8,0.9-1.4,1.2
        c-0.5,0.5-1.1,0.9-1.6,1.3c-0.5,0.4-1.2,0.7-1.6,1.3c-0.4,0.1-0.7,0.5-1.1,0.6c0,0.1,0,0.2-0.1,0.3c-0.7,0.3-1.3,0.8-1.9,1.3
        c-0.4,0.2-0.7,0.4-1,0.6c-0.8,0.5-1.7,0.8-2.5,1.4c-0.5,0.4-1.2,0.5-1.6,0.9c-0.5,0.5-1.2,0.6-1.8,0.9c-0.7,0.5-1.4,0.8-2.1,1.2
        c-0.4,0.2-0.9,0.4-1.4,0.5c-1.2,0.7-2.5,1-3.8,1.5c-0.7,0.2-1.3,0.5-2,0.7c-0.7,0.2-1.5,0.7-2.3,0.6c0,0.1-0.1,0.2-0.2,0.2
        c-0.7,0.1-1.4,0.4-2.1,0.6c-0.6,0.4-1.3,0.5-1.9,0.7c-0.2,0.1-0.5,0.2-0.7,0.1c-0.3,0.2-0.6,0.4-0.9,0.2c-0.4,0.2-0.8,0.5-1.3,0.4
        c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0-0.2-0.1-0.2-0.1c-0.2,0.2-0.4,0.4-0.6,0.5c-0.8,0.2-1.5,0.5-2.3,0.6c-0.4,0.2-0.8,0.1-1.1,0.3
        c-0.4,0.2-0.8,0.4-1.2,0.3c-0.1,0.2-0.4,0.2-0.6,0.2c-0.8,0.6-1.9,0.5-2.8,1c-0.6,0.1-1.2,0.3-1.8,0.5c-0.5,0.2-1.1,0.3-1.6,0.5
        c-0.3,0.3-0.8,0-1.1,0.4c-0.7,0.1-1.3,0.5-1.9,0.7c-1,0.4-2,0.9-3.1,1.1c-0.9,0.2-1.7,0.8-2.7,0.9c-0.7,0.4-1.5,0.5-2.1,0.9
        c-0.2,0.1-0.4,0.1-0.5,0.1c-0.2,0.2-0.5,0.3-0.7,0.3c-0.2,0.1-0.5,0.2-0.7,0.3c-0.3,0.1-0.6,0.3-0.9,0.4l0,0.1
        c-0.3-0.1-1.3,0.3-0.4,0.3c-0.2,0.2-0.5,0.3-0.8,0.3c-0.2,0.1-0.4,0.2-0.6,0.2c-0.6,0.2-1.1,0.5-1.7,0.8c-0.4,0-0.6,0.3-0.9,0.5
        c-0.1,0.1-0.3,0.1-0.5,0.1c-0.1,0.1-0.2,0.1-0.4,0.2c-0.3,0.1-0.5,0.3-0.8,0.4c-0.1,0.4-0.6,0.4-0.9,0.4l0,0.1
        c-0.4,0-0.6,0.5-0.9,0.3l0.1,0.1c-0.4,0.1-0.7,0.3-1,0.3c0,0.1,0,0.2,0,0.2c-0.2,0-0.4,0-0.7,0l0.1,0.2c-0.2,0.1-0.6,0.1-0.7,0.4
        c0-0.1,0-0.4,0-0.5c-0.2,0.2-0.2,0.4-0.4,0.6c-0.2,0-0.4,0.1-0.6,0.2c0,0.3-0.2,0.4-0.3,0.1c-0.3,0.3-0.7,0.4-1.1,0.6
        c-0.1,0.3-0.6,0.1-0.6,0.4c-0.5,0-0.9,0.5-1.4,0.7c0-0.1,0-0.2,0-0.3c-0.1,0-0.3,0.1-0.4,0.1l0.3,0.2c-0.1,0.1-0.4,0.2-0.5,0.3
        c0-0.1,0-0.3,0-0.4c-0.2,0.1-0.2,0.3-0.2,0.6c-0.1,0-0.3-0.1-0.4-0.1l0,0.2c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0-0.3,0-0.4,0.1
        c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3-0.2-0.5,0.3-0.7,0.4l-0.2-0.2c0,0,0.1-0.1,0.1-0.2c0.1,0,0.2-0.1,0.4-0.1l-0.1-0.2
        c-0.1,0.2-0.3,0.3-0.5,0c0,0.2,0,0.3,0.1,0.5c-0.2,0.1-0.3,0.2-0.5,0.3c-0.4-0.1-0.5,0.4-0.8,0.4c0,0.1,0,0.4,0,0.5
        c0.1,0,0.4,0,0.5-0.1c-0.3,0.1-0.2,0.5,0.1,0.6l0-0.3c0.1,0,0.2,0,0.3,0c0,0.1,0.1,0.2,0.1,0.3c0-0.1,0.1-0.3,0.1-0.4
        c0.1,0,0.2,0,0.3,0c-0.1,0.1-0.2,0.4-0.3,0.5c0.3-0.1,0.4-0.3,0.5-0.5l0.1,0.2c0.1,0.1,0.2,0.2,0.2,0.3c0.2-0.1,0.4-0.2,0.5-0.5
        c-0.1,0-0.3,0-0.4,0.1c-0.1-0.4-0.5-0.4-0.8-0.4c0.2-0.2,0.4-0.2,0.7-0.1c0.2,0.4,0.7,0.1,1,0c0.1,0.1,0.2,0.3,0.3,0.4
        c0-0.1,0.1-0.4,0.1-0.5c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0,0.2-0.1,0.3-0.1l0-0.2c0,0,0.1-0.1,0.2-0.1c0.2,0.2,0.3,0.5,0.5,0.7
        c0.2-0.2,0.4-0.5,0.7-0.5l0,0.1c-0.1,0-0.2,0-0.3,0c0,0.4,0.3,0.2,0.5,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.2-0.4,0.4-0.2,0.7-0.1
        c0.1-0.1,0.2-0.2,0.2-0.2c0.3,0,0.6,0,0.9,0c0,0.1-0.1,0.2-0.1,0.3c-0.1-0.1-0.3-0.2-0.3-0.2c0,0.2,0.1,0.4,0.2,0.6
        c0.1-0.2,0.2-0.3,0.3-0.5c0.1,0.1,0.2,0.2,0.3,0.3c0-0.2-0.1-0.5-0.2-0.6c0.1,0.1,0.3,0.3,0.5,0.4l0-0.3c0.2,0.1,0.3,0.2,0.5,0.3
        l-0.1,0.1c0.1,0.1,0.3,0.2,0.4,0.3c0-0.4,0.5-0.5,0.7-0.8c0,0.1,0,0.3,0,0.4c0.2,0.3,0.6,0.1,0.9,0.2c-0.1-0.2-0.3-0.5-0.4-0.7
        c0.2,0.5,0.8,0.5,1.2,0.6c-0.1-0.4-0.7-0.1-0.9-0.4c0.4-0.1,0.8,0,1.1,0.2c0,0.1,0,0.2,0,0.3c0.2,0,0.4,0,0.6,0
        c-0.1-0.1-0.1-0.3-0.2-0.4c0.1,0,0.2,0.1,0.3,0.1c0,0.2-0.1,0.4-0.1,0.5l-0.2-0.1c0,0.2,0.2,0.2,0.4,0.2c-0.1-0.3,0.1-0.3,0.3-0.3
        c0.1,0,0.2-0.1,0.3-0.1l0,0.2c0.1,0,0.2,0,0.3-0.1c0-0.1-0.1-0.2-0.2-0.2c0.1,0,0.2,0,0.3,0c0.1-0.4,0.5,0.1,0.7,0.1
        c-0.3-0.4,0.2-0.4,0.4-0.4l0,0.2c0.1,0,0.3-0.1,0.4-0.1c0,0.1,0,0.2-0.1,0.3c0.1,0,0.4,0,0.5,0c-0.1-0.1-0.3-0.3-0.3-0.4
        c0,0,0-0.1,0.1-0.2c0.2,0.2,0.3,0.4,0.6,0.3l-0.1,0.2c0.2-0.1,0.5-0.2,0.7-0.3c-0.1,0.2-0.3,0.5-0.5,0.5c0,0,0,0.1,0,0.2
        c0.2-0.2,0.4-0.3,0.6-0.5c0.1,0,0.3,0.1,0.4,0.1c-0.2-0.4,0.2-0.2,0.4-0.3c0.1,0.1,0.2,0.3,0.3,0.4c0-0.1,0-0.3,0-0.4
        c0.1,0,0.3,0.1,0.4,0.2c-0.1-0.2-0.3-0.4-0.5-0.6c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1-0.1-0.2-0.2-0.3-0.3c0.2,0,0.4-0.1,0.6-0.1
        c0.4,0.3,0.7,0.7,1.2,0.9c-0.1-0.3-0.4-0.4-0.6-0.6c0.1,0,0.3-0.1,0.4-0.1l0,0.3c0.2-0.1,0.4-0.2,0.6-0.3c0,0.2,0,0.4,0,0.6
        c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0.2,0.3,0.4,0.4,0.5c0-0.1-0.1-0.3-0.1-0.4c0.1,0,0.2,0,0.3-0.1c0-0.1-0.1-0.2-0.2-0.3
        c0.1,0,0.3,0.1,0.4,0.2c0.2-0.1,0.3-0.2,0.4-0.4c-0.1,0-0.3,0.1-0.4,0.2c-0.1-0.1-0.2-0.2-0.3-0.3c0.5,0,1,0.1,1.4-0.3
        c-0.1,0.2-0.3,0.4-0.5,0.6l0.4-0.1l-0.2,0.3c0.2-0.1,0.4-0.2,0.6-0.3c-0.1,0-0.3-0.1-0.4-0.1c0.2-0.1,0.4-0.3,0.6-0.3
        c0,0.1-0.1,0.3-0.1,0.5c0.2-0.2,0.4-0.5,0.6-0.8c0,0.2-0.1,0.5-0.1,0.6c0.2,0.2,0.3-0.1,0.5-0.2c0.2,0,0.4,0,0.6-0.1
        c-0.1,0.2-0.1,0.4-0.2,0.5c0.2-0.2,0.2-0.6,0.5-0.7l0,0.3c0.1,0,0.2,0,0.3,0c-0.1-0.3,0.2-0.6,0.5-0.5c-0.1,0.1-0.3,0.2-0.4,0.3
        l0,0.2c0.1-0.1,0.3-0.2,0.3-0.2c0.4-0.1,0.7-0.3,1-0.4c0.1,0.1,0.3,0.3,0.5,0.2c-0.1-0.1-0.2-0.2-0.2-0.3c0.1,0,0.4,0.1,0.5,0.1
        l0-0.2l0.2,0l-0.1-0.3c0.2,0.1,0.3,0.2,0.3,0.4l0.3,0l-0.1-0.3c0.1,0,0.3,0.1,0.4,0.1l0.1-0.3l-0.2,0c0.1-0.1,0.2-0.1,0.3-0.2l0,0.3
        c0.2-0.1,0.4-0.2,0.6-0.3c0,0.1-0.1,0.3-0.1,0.4c0.2-0.2,0.4-0.5,0.8-0.4l0-0.2c0.1,0.1,0.3,0.1,0.4,0.2c0-0.1,0-0.2,0-0.3
        c0.2,0,0.4,0,0.6,0l0-0.3c0,0.1,0.1,0.2,0.1,0.3c0.2-0.1,0.7-0.7,0.8-0.1c0.1-0.1,0.2-0.3,0.2-0.3c0.2-0.1,0.4-0.1,0.6-0.2
        c0.9-0.5,1.9-0.8,2.8-1.2c0.8-0.3,1.5-0.7,2.2-1.1c-0.3,0.3-0.6,0.6-1,0.8c-0.8,0.5-1.7,0.9-2.6,1.4c-0.3,0.4-0.9,0.4-1.2,0.7
        c-0.2,0.2-0.5,0.3-0.7,0.5c-0.1,0.2-0.3,0.3-0.6,0.3c-0.8,0.8-2.1,0.9-2.9,1.7c-0.7,0.2-1.3,0.6-2,0.8c-0.3,0.1-0.5,0.4-0.8,0.5
        c-0.4,0.1-0.6,0.5-1,0.5c-0.2,0-0.4,0-0.5,0c-0.3,0.2-0.7,0.4-1.1,0.4c-0.7,0.2-1.4,0.7-2.2,0.4c-0.6,0.4-1.3,0.2-2,0.4
        c-1-0.1-2,0.1-3,0c-0.3,0-0.7,0.1-1-0.1c-0.2-0.2-0.4,0-0.6,0.1c-0.5-0.4-1.3-0.2-1.9-0.1c-0.2-0.1-0.3-0.2-0.4-0.3
        c-0.4,0.4-0.9,0.1-1.4,0c-0.4,0.1-0.7,0.2-1-0.1c-0.1,0-0.2,0.1-0.3,0.1c0,0-0.1-0.1-0.2-0.1c-0.4,0.1-0.7-0.1-1-0.2
        c-0.2,0.2-0.4,0.2-0.6,0c-0.1,0-0.2,0-0.2,0.1c-0.6-0.1-1.2-0.1-1.9-0.1c-0.5,0-1,0.2-1.6,0.1c-0.6,0.1-1.1,0.1-1.7,0
        c-0.5,0.1-1.1,0.2-1.7,0c-0.4,0.3-1,0.2-1.5,0.3c-0.6-0.1-1.2,0.5-1.8,0.3c-0.4,0.4-1.1,0.4-1.6,0.6c-0.4,0-0.8,0.2-1.2,0.2
        c-0.4,0.2-0.8,0-1.1,0.2c-0.6,0.2-1.2,0.2-1.7,0.5c-0.5-0.2-0.9,0.2-1.2,0.5c-0.4-0.1-0.6,0.3-1,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
        c-0.1,0-0.2-0.1-0.2-0.1c-0.3,0.4-0.9,0.5-1,1c-0.2-0.1-0.4-0.1-0.6,0.1c0,0-0.1-0.1-0.2-0.1c-0.1,0.1-0.2,0.3-0.4,0.4
        c0,0.1,0,0.2-0.1,0.3c-0.1-0.1-0.3-0.2-0.4-0.3c0,0.1,0,0.2,0,0.3c-0.3,0.1-0.5,0.4-0.8,0.5c-0.1,0.2-0.1,0.4-0.2,0.5
        c0-0.3,0.1-0.6-0.1-0.8c-0.1,0.2-0.2,0.3-0.2,0.5c-0.3,0.1-0.5,0.4-0.8,0.2c0,0.5-0.3,1.1-0.2,1.6c0,0.3-0.1,0.6-0.3,0.9
        c0,0.3,0,0.5,0,0.8c-0.4,0.3,0.1,0.3,0.3,0.4c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0.1,0.2,0.2,0.2c0,0.2,0.1,0.4,0.2,0.7l-0.2-0.1l0,0.3
        c0.1,0,0.2-0.1,0.3-0.1c0,0.2,0.1,0.3,0.3,0.4c-0.1,0.1-0.1,0.3-0.2,0.4c0.1,0,0.3-0.1,0.4-0.1c-0.1,0.2-0.2,0.4-0.3,0.5
        c0.2,0,0.2,0.2,0.2,0.4c0.2,0.1,0.6,0.1,0.4,0.5c0.4,0,0.3,0.5,0.5,0.7l0.1-0.2l0.3,0.1c-0.1,0.2-0.3,0.3-0.4,0.5
        c0.2,0.1,0.4,0.2,0.5,0.3c0,0.2,0,0.4,0.2,0.6c0-0.1,0.1-0.3,0.1-0.4c0.2,0.1,0.2,0.4,0.1,0.6c0,0.1,0.1,0.2,0.1,0.3
        c0.2,0.1,0.3,0.3,0.5,0.4l0.1,0l0,0.1l-0.2-0.1c0,0.1-0.1,0.3-0.1,0.3c0.3-0.1,0.2,0.4,0.5,0.3c-0.3,0.4,0.2,0.7,0.5,0.9
        c-0.2,0-0.4,0.1-0.6,0.1c0.1,0.1,0.3,0.1,0.4,0.1c0,0.2-0.2,0.6,0.1,0.6c-0.1-0.3-0.1-0.5,0-0.8c0.1,0.1,0.2,0.3,0.3,0.5
        c-0.1,0.2-0.1,0.4-0.2,0.6c0,0,0.1,0,0.1,0c0-0.3,0.3-0.4,0.5-0.1c0,0.1-0.1,0.2-0.2,0.3c0.2,0,0.5,0.1,0.6,0.4
        c-0.2,0-0.3-0.1-0.5-0.1c0.2,0.2,0.4,0.5,0.6,0.2c0,0.2,0.1,0.5,0.2,0.7c0.6,0.1,0.7,1,1.3,1.1c-0.1,0-0.2,0.1-0.3,0.2l0-0.1
        c0,0,0,0.1,0,0.2l0.2-0.1c0,0.4,0.4,0,0.5,0.3c0.1,0.3,0.6,0.3,0.5,0.7c0.1,0,0.2,0,0.2-0.1c0.1,0.3,0.4,0.5,0.6,0.8
        c0,0,0.1,0,0.2-0.1c0.3,0.4,0.7,0.6,1,1c0,0,0.1,0,0.2,0l-0.1,0.3c0.1,0,0.2,0,0.3,0c0.1,0.2,0.1,0.4,0.2,0.6
        c0.1-0.1,0.2-0.1,0.3-0.2c0,0.1,0.1,0.4,0.1,0.6c0.1-0.1,0.2-0.2,0.2-0.2c0,0.1,0.1,0.3,0.1,0.3c0.4,0,0.7,0.3,0.9,0.5
        c0.2,0,0.4,0.2,0.6,0.3l-0.4,0c0.1,0.1,0.3,0.3,0.5,0.4l0.1,0c0-0.1-0.1-0.3-0.1-0.4c0.2,0,0.3,0.2,0.3,0.4l0.2-0.1
        c-0.3,0.4,0.3,0,0.4,0.4c0,0.1,0.1,0.2,0.1,0.3c0.1,0,0.2,0,0.2-0.1c0,0.1-0.1,0.2-0.1,0.2c0.2,0.2,0.5,0.3,0.5,0.6
        c0.1-0.1,0.2-0.2,0.2-0.3c0.1,0.1,0.2,0.3,0.2,0.5l0.2,0l-0.1,0.2c0.4-0.2,0.7,0.3,1.1,0.3c0,0.1-0.1,0.2-0.1,0.3
        c0.1,0,0.2-0.1,0.3-0.2c0,0.1,0,0.3,0,0.3c0.1-0.1,0.3-0.2,0.4-0.3c0,0.1,0,0.3,0,0.4c0.1,0,0.2,0,0.3,0c0.1,0.2,0.2,0.2,0.4,0.2
        c0,0.1,0,0.2,0,0.3c0.1,0,0.2,0,0.3,0c0.2,0.4,0.7,0.3,1,0.6c0,0.4,0.5,0.4,0.7,0.6c0.1,0.1,0.2,0.1,0.4,0.1c0,0.1,0,0.2-0.1,0.2
        c0.2,0,0.4,0.1,0.6,0.2c0.1,0,0.2,0,0.2,0c0,0.3,0.3,0.3,0.5,0.4c0.2,0.1,0.5,0.3,0.7,0.4l0,0.1c0.5,0,0.9,0.5,1.3,0.7
        c0.3,0.1,0.5,0.2,0.8,0.4c-0.5-0.1-1.1-0.4-1.5-0.7c-0.1-0.1-0.3-0.1-0.4-0.2c-0.2-0.1-0.3-0.1-0.5-0.2c-0.2-0.1-0.3-0.3-0.5-0.4
        c-0.2-0.1-0.5-0.1-0.6-0.3c-0.5-0.2-1-0.4-1.4-0.7c-0.4,0-0.5-0.5-0.8-0.6c-0.4-0.1-0.7-0.3-1-0.6c-0.2-0.1-0.5-0.2-0.7-0.3l0-0.1
        c-0.4,0-0.7-0.3-1.1-0.5c-0.1-0.2-0.3-0.2-0.5-0.3c-0.2-0.2-0.5-0.4-0.8-0.4c-0.1-0.3-0.4-0.2-0.6-0.4c-0.2-0.3-0.7-0.3-0.8-0.7
        c-0.2,0-0.4-0.1-0.5-0.1c-0.1-0.1-0.2-0.2-0.4-0.2l0.1-0.2c-0.2-0.1-0.5-0.1-0.7-0.3c-0.1,0-0.2,0-0.3,0c-0.3-0.2-0.5-0.5-0.8-0.7
        c-0.1,0-0.2-0.1-0.3-0.2c0-0.1,0-0.2,0-0.2c-0.1,0-0.2,0-0.3,0l0-0.2c0,0-0.1,0-0.2,0c0-0.4-0.4-0.3-0.6-0.6
        c-0.1-0.2-0.4-0.3-0.7-0.3c-0.1-0.1-0.1-0.2-0.2-0.3c-0.3-0.1-0.3-0.5-0.6-0.5c0-0.1-0.1-0.3-0.1-0.4c-0.1,0-0.3,0.1-0.3,0.2
        c0-0.1,0-0.4,0-0.5c-0.3,0.2-0.5-0.3-0.8-0.3c-0.2-0.2-0.4-0.3-0.6-0.4c0-0.1,0-0.2,0-0.2c-0.3,0-0.5,0-0.5-0.3
        c-0.2-0.2-0.5-0.4-0.8-0.5c0-0.1,0-0.3,0-0.4c-0.2-0.1-0.4-0.1-0.5-0.2c-0.1,0-0.3-0.1-0.4-0.2c0.1-0.1,0.2-0.2,0.3-0.3
        c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.2-0.3-0.3-0.4-0.5c-0.3-0.2-0.5-0.3-0.8-0.4c0-0.3,0.3-0.2,0.5-0.2c-0.1,0-0.3-0.1-0.4-0.1
        c-0.1,0-0.2,0.1-0.2,0.1c-0.1-0.1-0.2-0.3-0.3-0.4c0,0.1-0.1,0.2-0.1,0.3c-0.2-0.3-0.4-0.6-0.5-0.9c-0.1,0.1-0.3,0.1-0.4,0.2
        c-0.1-0.3-0.2-0.6-0.4-0.9c-0.1,0-0.2,0.1-0.3,0.1c-0.3-0.3-0.6-0.7-0.8-1c-0.3,0-0.6-0.1-0.6-0.4c-0.4-0.3-0.8-0.6-0.9-1.1
        c-0.2,0-0.3-0.1-0.4-0.1c0,0-0.1,0-0.2,0.1c0-0.3-0.5-0.6-0.1-0.9c-0.1,0-0.3,0.1-0.3,0.2c-0.1-0.2-0.2-0.4-0.2-0.7
        c-0.2-0.1-0.4-0.3-0.6-0.3c0.2-0.2-0.6-1-0.2-0.2c-0.1,0-0.2,0-0.3,0c0.1-0.2,0.1-0.4,0.1-0.7c-0.1,0-0.4,0-0.5,0.1
        c0-0.3-0.3-0.5-0.5-0.8c0,0.1-0.1,0.2-0.2,0.2c-0.1-0.1-0.2-0.1-0.3-0.2c0,0.2-0.1,0.3-0.3,0.4l0.1,0.2c-0.1-0.1-0.3-0.1-0.4-0.2
        c0,0.1-0.1,0.3-0.1,0.4c-0.2,0.1-0.3,0-0.5-0.1c0,0.1-0.1,0.3-0.1,0.4c-0.3-0.3-0.5,0.1-0.6,0.3c-0.3-0.3-0.8,0.1-0.3,0.3l0-0.2
        c0.1,0,0.2,0,0.3,0c-0.1,0.1-0.3,0.2-0.4,0.3c-0.4-0.3-0.6,0.2-0.8,0.5c-0.3,0-0.8,0-0.8,0.4l-0.2,0c0,0-0.1,0.1-0.2,0.1
        c-0.4,0.3-0.9,0.5-1.3,0.9c-0.3,0.2-0.9,0.1-0.9,0.6c-0.2,0.3-0.8,0.4-0.7,0.8c-0.2-0.1-0.4-0.3-0.6-0.3c0,0.2,0.4,0.3,0.3,0.5
        c-0.2,0-0.4-0.1-0.5-0.2c0.1,0.2,0.1,0.3,0.2,0.5c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0.1-0.3,0.2-0.5,0.3c0,0.2-0.1,0.4-0.2,0.6
        c-0.2,0-0.4,0-0.5,0.2c-0.5-0.1-0.9,0.3-0.9,0.8c-0.2-0.1-0.4,0.1-0.6,0.2c0.1-0.2,0.2-0.3,0.4-0.4c-0.2,0-0.3-0.1-0.5-0.1
        c-0.1,0.3-0.6,0.4-0.6,0.8c-0.1,0.5-0.8,0.4-1,0.8l0.3,0.1c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0.4-0.7,0.2-0.9,0.6
        c-0.3,0-0.6,0.1-0.8,0.3c0.1,0.1,0.3,0.1,0.5,0.2c-0.2,0.1-0.4,0.1-0.5,0.2c-0.2,0.3-0.5,0.5-0.8,0.8c-0.1-0.1-0.3-0.2-0.3-0.3
        c0,0.1,0,0.2,0,0.3l-0.2,0c0.3,0.3,0,0.5-0.3,0.5c-0.2,0.2-0.4,0.3-0.6,0.4c-0.1,0-0.2,0.1-0.3,0.1c0,0-0.1,0.1-0.1,0.2l-0.2,0
        c0,0.1,0,0.2,0.1,0.3c-0.2,0.3-0.5,0.3-0.8,0.2c0,0.2,0,0.4,0,0.6c-0.2,0-0.4,0.1-0.6-0.1c0,0.1,0,0.3,0,0.5c-0.1,0-0.2,0-0.3,0
        c-0.1,0.2-0.2,0.3-0.4,0.3c0,0.3,0.3,0.4,0.4,0.7c-0.3-0.2-0.6-0.2-0.9,0c-0.2,0.2-0.3,0.4-0.5,0.6c-0.4-0.2-0.5,0.2-0.5,0.5
        c-0.1,0-0.3,0-0.4,0c-0.2,0.5-0.7,0.7-1,1.1c-0.1-0.1-0.2-0.2-0.3-0.2c0,0.1,0.1,0.3,0.1,0.4c-0.3,0.2-0.6,0.4-0.6,0.8
        c-0.1,0-0.2-0.1-0.3-0.1c0,0.1,0,0.2,0,0.3c-0.1,0-0.3-0.1-0.4-0.1c0.1,0.2,0.2,0.4,0.3,0.5c-0.1,0-0.4-0.1-0.5-0.2
        c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0-0.2,0-0.3,0c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.2-0.1,0.4-0.3,0.5c0.1-0.3,0-0.4-0.3-0.5
        c0.1,0.1,0.1,0.3,0.2,0.4c-0.2,0.2-0.3,0.3-0.5,0.4c-0.1,0-0.3-0.1-0.4-0.1c0.3,0.5-0.2,0.8-0.6,1c0,0.1-0.1,0.2-0.1,0.3
        c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0-0.2,0.1-0.3,0.1c0,0.1,0,0.2,0,0.3c-0.2,0.2-0.4,0.3-0.5,0.5c-0.1,0-0.2-0.1-0.2-0.2
        c-0.2,0.2-0.3,0.5-0.2,0.8c-0.1,0-0.2,0-0.3,0.1c-0.2,0.1-0.3,0.2-0.5,0.3c0,0.1,0,0.2,0.1,0.3c-0.1,0.1-0.2,0.2-0.3,0.3
        c-0.1,0.1-0.3,0.1-0.4,0.3c-0.2,0.4-0.6,0.6-0.8,1c0-0.7-0.3,0.1-0.5,0.2c0.2,0.7-0.8,0.7-0.7,1.3c-0.1,0-0.2,0-0.3,0
        c0,0.2-0.2,0.4-0.4,0.4c0,0.4-0.4,0.3-0.7,0.2c0.1,0.1,0.2,0.2,0.3,0.3c-0.3,0.2-0.5,0.6-0.9,0.6c0.2,0.6-0.6,0.7-0.8,1.2
        c-0.2,0.2-0.3,0.4-0.5,0.6c-0.3,0-0.6,0.1-0.8,0.3c0,0.2,0.3,0.3,0.2,0.5c-0.4-0.3-0.6,0.1-0.8,0.4c0.1,0.4-0.3,0.5-0.5,0.8
        c0,0.1-0.1,0.3-0.1,0.4c-0.4,0-0.5,0.4-0.6,0.8c-0.2,0-0.3,0.1-0.5,0.1c0,0.1,0,0.3,0,0.4c-0.4,0.3-0.6,0.7-1,1
        c-0.2,0.2-0.6,0.3-0.5,0.7c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.1,0.3c-0.1,0-0.3,0-0.4,0.1c-0.1,0.2-0.1,0.4-0.2,0.6
        c-0.1,0.1-0.2,0.1-0.3,0.2c0,0.1,0,0.2,0.1,0.3c-0.1,0-0.2,0-0.3,0c0.2,0.4-0.2,0.6-0.4,0.8c-0.1,0.3-0.3,0.5-0.5,0.7
        c0-0.1,0-0.4,0-0.5c-0.3,0.2-0.2,0.6-0.2,0.9c-0.4,0.2-0.7,0.6-0.8,1.1c-0.1,0-0.2,0.1-0.3,0.1c0,0.1,0,0.3,0,0.4
        c-0.2,0.2-0.4,0.3-0.6,0.5c0.1,0.5-0.4,0.6-0.6,0.9c-0.2,0.1-0.2,0.2,0,0.4c-0.2,0.2-0.5,0.3-0.6,0.6c-0.1,0.3-0.2,0.6-0.6,0.6
        c0.3,0.4-0.2,0.6-0.5,0.8c0,0.3-0.2,0.5-0.3,0.8c-0.1,0-0.3,0-0.3,0c0,0,0,0.1,0,0.2c0.1,0,0.3,0,0.4,0.1c-0.1,0.3-0.6,0.3-0.8,0.6
        c0,0.1,0,0.2,0,0.3c-0.2,0.2-0.4,0.3-0.6,0.5c0.1,0.2,0.1,0.4-0.1,0.5c-0.3,0.1-0.5,0.5-0.3,0.8c-0.2,0.2-0.4,0.4-0.6,0.6
        c-0.2,0.3-0.3,0.6-0.5,0.9c-0.2,0.2-0.4,0.4-0.4,0.7c-0.5,0.5-0.5,1.2-1,1.7c-0.3,0.2-0.3,0.7-0.7,0.9c0,0.1,0,0.2,0.1,0.3
        c-0.2,0.2-0.4,0.3-0.5,0.5c0,0.4-0.3,0.7-0.6,1c0,0.1,0,0.2,0,0.3c-0.5,0.4-0.8,1-0.9,1.6c-0.1,0-0.3-0.1-0.4-0.1
        c0.4,0.2,0.1,0.5-0.1,0.8c-0.1,0-0.2,0.1-0.4,0.1c0,0.2,0.1,0.3,0.1,0.5c-0.5,0.4-0.6,1.1-0.9,1.7c-0.2,0.4-0.5,0.7-0.6,1.2
        c-0.1,0-0.2,0.1-0.3,0.1c0,0.1,0,0.2,0.1,0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.4-0.2,0.8-0.5,1.1c-0.2,0.3-0.1,1.1-0.6,0.7
        c0,0.2,0.1,0.4,0.1,0.6c-0.3,0.2-0.3,0.7-0.6,0.9c-0.2,0.1-0.1,0.3,0,0.5c-0.5,0.4-0.5,1.1-0.9,1.6c-0.3,0.4-0.3,0.9-0.7,1.3
        c-0.2,0.2-0.1,0.5-0.3,0.7c-0.5,0.4-0.4,1.1-0.8,1.7c-0.1,0.3-0.1,0.8-0.5,0.9c0,0.6-0.4,1-0.5,1.6c-0.3,0.7-0.5,1.4-0.9,2
        c-0.1,0.6-0.3,1.2-0.7,1.6c0,0.1,0,0.2,0.1,0.3c-0.2,0.2-0.3,0.5-0.5,0.7c0.1,0.7-0.3,1.3-0.5,2c-0.3,0.5-0.3,1.1-0.7,1.6
        c-0.1,0.7-0.4,1.4-0.6,2.1c-0.5,0.9-0.4,2-1,2.8c0,0.2-0.1,0.5-0.1,0.7c-0.1,0.1-0.3,0.1-0.4,0.2c0.1,0.2,0.1,0.3,0.2,0.5
        c-0.7,1.8-1.1,3.8-1.8,5.6c-0.3,0.4-0.1,0.8-0.4,1.2c-0.3,0.4-0.1,0.9-0.4,1.3c-0.2,0.2-0.3,0.5-0.2,0.7c-0.2,0.3-0.3,0.7-0.5,1
        c-0.1,0.6-0.2,1.3-0.5,1.8c-0.1,0.5-0.3,0.9-0.4,1.4c-0.1,0.3-0.5,0.5-0.2,0.9c-0.4,0.2-0.1,0.6-0.3,0.8c-0.2,0.4-0.1,0.9-0.3,1.3
        c-0.3,0.9-0.4,1.9-0.8,2.8c-0.1,0.5-0.2,1-0.4,1.4c0,0.9-0.3,1.8-0.5,2.7c-0.1,0-0.2,0.1-0.2,0.1c0.2,0.6-0.2,1.1-0.2,1.7
        c0,0.4-0.2,0.9-0.2,1.3c0,0.1-0.1,0.2-0.2,0.3c0,0.1,0,0.2,0.1,0.3c-0.1,0.1-0.2,0.2-0.2,0.3c0.2,0.4-0.1,0.7-0.1,1.1
        c0,1-0.4,1.8-0.4,2.8c-0.1,0.3,0,0.6-0.2,0.9c0.1,0.6,0,1.3-0.3,1.8c0.1,0.3,0.1,0.7-0.1,1c0,1.3-0.1,2.5-0.3,3.8
        c0.1,0.6-0.1,1.1,0,1.7c0.1,0.6-0.1,1.2-0.1,1.8c0.4-0.2,0.1,0.2,0,0.2c0.1,0.4,0.1,0.8,0,1.2c0.2,0.2,0.3,0.5,0.2,0.8l-0.2,0
        c0.1,0.3,0.2,0.7,0.1,1.1c-0.4,0.3,0.1,0.6,0.1,0.9c-0.1,0.1-0.2,0.3-0.3,0.4c0.1,0.2,0.1,0.4,0.4,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
        c-0.1-0.1-0.3-0.2-0.4-0.2c0.1,0.1,0.2,0.3,0.4,0.4c-0.1,0.3-0.1,0.7-0.1,1c0.7,0.1-0.4,0.7,0.1,0.9c0,0.3,0,0.6,0,0.9
        c0,0.2-0.1,0.3-0.2,0.4c0.1,0.3,0.3,0.7,0.1,0.9c0.1,0.3-0.1,0.7,0.2,0.9c-0.1,0-0.3,0-0.3,0c0.1,0.1,0.2,0.3,0.3,0.4
        c-0.1,0.2-0.2,0.4-0.3,0.6c0.3,0.1,0.1,0.4,0,0.6l0.3-0.1c-0.1,0.1-0.2,0.4-0.2,0.5c-0.1,0.3,0.1,0.6,0,0.9c0,0.1,0,0.2,0,0.2
        c0.1,0,0.2,0.1,0.3,0.1c-0.2,0.5-0.6,1.1-0.3,1.6l-0.2,0.1c0.1,0.1,0.2,0.3,0.3,0.4c-0.1,0.4-0.2,0.9-0.2,1.3
        c0.2,0.2,0.5,0.7-0.1,0.7c0.1,0.1,0.3,0.3,0.3,0.4c-0.3-0.2-0.4-0.2-0.5,0.1c0.1,0,0.3,0,0.4-0.1c-0.2,0.2-0.4,0.5,0,0.6
        c-0.1,0.1-0.3,0.2-0.2,0.4c0.1,0.3,0,0.6,0.1,0.9l-0.2,0c0.1,0.1,0.2,0.3,0.2,0.4c-0.1,0.1-0.3,0.3-0.4,0.4l0.2-0.1
        c-0.1,0.5,0,1-0.1,1.5l0.1,0.1c-0.1,0.3-0.2,0.6-0.1,0.9l-0.3,0c0.3,0.2,0.3,0.7,0.1,0.9c0.1,0.1,0.2,0.3,0.1,0.4
        c-0.2,0.3,0.2,0.8-0.3,0.8c0.5,0.1,0.2,0.6,0.2,0.9c0,0.2,0.2,0.5-0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.3c-0.2,0.4,0.1,1-0.2,1.4
        c0,0.8,0,1.5,0,2.3c-0.1,0.5,0.1,1,0,1.4c-0.2,0.9,0,1.8-0.2,2.7c0.1,0.3,0.1,0.5,0.1,0.8c-0.1,0.3-0.1,0.6-0.2,0.8
        c0.1-1.2-0.2-2.3,0-3.5c-0.2-0.4,0.1-0.8,0.1-1.2c-0.1-0.5-0.1-0.9-0.1-1.4c0.1-0.8,0-1.7,0.1-2.6c-0.2-0.5-0.2-1.1-0.3-1.6
        c0-0.5-0.1-1-0.1-1.4c-0.2-0.9-0.1-1.8,0-2.7c-0.2-1,0.2-2.1-0.2-3.1c-0.1-0.4,0-0.7,0.1-1.1c0-0.1-0.1-0.2-0.2-0.3
        c0-0.1,0.1-0.2,0.2-0.2c-0.3-0.4-0.1-0.8-0.1-1.2c-0.1-0.8,0-1.6,0.1-2.4c-0.3-0.5-0.1-1-0.1-1.5c0-0.3,0-0.7,0-1
        c-0.2-0.8-0.1-1.6-0.1-2.3c0.1-0.6-0.1-1.1,0-1.7c0.1-0.4-0.1-0.9,0.1-1.3c0-0.3-0.1-0.5-0.2-0.8c0.1-0.6-0.2-1.2,0-1.9
        c-0.1-0.3-0.1-0.7,0-1c-0.3-0.6-0.1-1.2-0.2-1.9c0-0.6,0.2-1.1,0-1.7c0-0.1,0.1-0.3,0.1-0.3c-0.1-0.3-0.2-0.6-0.2-1
        c0-0.1,0.1-0.3,0.2-0.4c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.5,0.2-1.1,0-1.6c-0.1-0.3-0.1-0.7-0.1-1c0-0.3-0.2-0.7-0.1-1
        c-0.1-0.6-0.2-1.2,0.1-1.7c-0.1-0.2-0.2-0.4-0.2-0.5c0.1-0.8-0.1-1.6,0-2.4c-0.2-0.7-0.2-1.5-0.3-2.3c-0.1-0.4-0.3-0.8-0.2-1.2
        c0.3,0.4,0.4,0.9,0.7,1.3c-0.3,0.1-0.1,0.3,0.1,0.3c0-0.3-0.1-0.6-0.3-0.8l0.3,0.1l0-0.1c-0.1,0-0.2-0.1-0.3-0.1
        c0-0.5-0.4-0.9-0.3-1.4c0-0.4-0.2-0.7-0.2-1l-0.2,0.1c0-0.2,0.1-0.4,0.3-0.6c-0.1,0-0.2,0-0.2-0.1c0.1-0.2,0.1-0.4,0.1-0.6
        c-0.1,0-0.2,0.1-0.2,0.1c0-0.3-0.4-0.7,0-0.8c-0.1-0.2-0.2-0.5-0.4-0.7c0.3-0.1,0.4-0.7-0.1-0.4c-0.1-0.2,0-0.4,0.1-0.5
        c-0.1,0-0.3,0-0.4,0c0.1,0,0.2-0.1,0.2-0.2c0.1-0.7-0.2-1.4-0.4-2c0.1,0,0.2,0.1,0.3,0.1c0,0.2,0.1,0.4,0.4,0.5
        c0-0.4-0.3-0.6-0.4-0.9c-0.2-0.4-0.1-0.8-0.3-1.2l-0.2,0.2l0-0.1c0-0.1,0.1-0.2,0.2-0.2c-0.1,0-0.2,0-0.3,0v-0.1l0.2,0
        c-0.1-0.3-0.1-0.7-0.4-0.8c0-0.1,0.1-0.4,0.1-0.5c0.1,0,0.3,0.1,0.4,0.1c-0.1-0.1-0.4-0.3-0.5-0.5c0.1,0,0.4-0.1,0.5-0.1
        c-0.4-0.2-0.6-0.7-0.8-1c0,0,0.1-0.1,0.1-0.1c0-0.1,0-0.4,0-0.6c-0.1-0.1-0.3-0.2-0.5-0.3l0,0c0.2,0,0.7,0.2,0.6-0.2
        c-0.1,0-0.3,0.1-0.3,0.1c0-0.1,0-0.3-0.1-0.4c0.1-0.1,0.2-0.3,0.2-0.4l-0.2,0c-0.1-0.2-0.3-0.4-0.4-0.6c0.1,0,0.3,0,0.4,0
        c-0.1-0.2-0.2-0.3-0.2-0.5c-0.3,0-0.6-0.7-0.2-0.5c0,0.1,0.1,0.3,0.2,0.4l0.1,0c0-0.2,0-0.3-0.1-0.4c0,0-0.1-0.1-0.2-0.1
        c0.1-0.3,0.2-0.6,0.1-0.9c-0.2,0.2-0.2,0.4-0.1,0.6c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1-0.4-0.1-0.7,0-1.1l-0.2,0c0.2-0.3,0.2-0.6,0-0.9
        c-0.1,0-0.4,0.1-0.5,0.2c0.1-0.1,0.3-0.3,0.4-0.4c0.1,0.1,0.3,0.2,0.4,0.2c-0.1-0.2-0.2-0.4-0.3-0.5c-0.1,0-0.3,0.1-0.4,0.2
        c0.1-0.3,0.1-0.5,0-0.7c0.1-0.1,0.2-0.2,0.3-0.3c-0.2-0.1-0.5-0.1-0.6-0.3c0.2-0.1,0.4-0.3,0.4-0.5c-0.2,0-0.4,0.2-0.4,0.4
        c-0.1-0.2-0.4-0.5,0-0.4c0-0.1-0.1-0.4-0.2-0.5c0.1,0,0.3-0.1,0.3-0.2l0-0.1c-0.3,0-0.6,0.2-0.8,0.4c0.1-0.4,0.4-0.6,0.5-1
        c-0.2,0.1-0.3,0.2-0.5,0.3c0-0.1,0.1-0.3,0.2-0.4c-0.1-0.1-0.2-0.2-0.3-0.2c0-0.1,0-0.2,0-0.2c0.2-0.4,0.6-1,1.1-0.7
        c-0.3-0.1-0.5-0.3-0.4-0.6c-0.1,0-0.3,0-0.3,0l0,0.1c0.2,0,0.3,0.3,0.2,0.4c-0.3,0.1-0.6,0.2-0.9,0.4l-0.1-0.1
        c0.3-0.3,0.5-0.5,0.7-0.9c-0.3,0-0.5-0.1-0.7-0.3l0.5,0.1c-0.2-0.2-0.4-0.4-0.7-0.5c0.1-0.1,0.3-0.2,0.4-0.3
        c-0.2-0.2-0.4-0.5-0.6-0.8c0.3,0.1,0.5,0.2,0.8,0.5c0.1-0.3-0.3-0.5-0.5-0.5c-0.3-0.3-0.5-0.6-0.7-0.9c0.5,0.1,0.1-0.4,0.2-0.7
        c-0.2-0.1-0.2-0.4-0.3-0.6c0.1-0.2,0.1-0.5,0.3-0.7c-0.4,0.1-0.4-0.4-0.4-0.6c0,0-0.1-0.1-0.1-0.2c0.1-0.2,0.2-0.3,0.3-0.5
        c-0.4,0.1-0.4,0.6-0.8,0.7c0.1-0.3,0.3-0.6,0.4-0.9c0,0,0.1-0.1,0.2-0.2c-0.1,0-0.3,0.1-0.4,0.1c0-0.1,0.1-0.3,0.1-0.4
        c-0.1-0.1-0.3-0.1-0.4-0.2c0-0.2,0.1-0.3,0.1-0.5l0.2,0c0-0.1,0-0.2-0.1-0.3l-0.2,0.1c0-0.2,0-0.4,0-0.6c0.2-0.1,0.2-0.4,0.2-0.6
        l-0.1,0l0,0.3c-0.2-0.1-0.4-0.2-0.6-0.3c0.2-0.2,0.3-0.9-0.2-0.4c0.5-0.3,0.1-0.6-0.2-0.8c0-0.1,0.1-0.2,0.1-0.3
        c-0.1-0.2-0.1-0.3-0.1-0.5c-0.1,0-0.2,0-0.2,0c0-0.2-0.2-0.3-0.3-0.5c0,0-0.1-0.1-0.1-0.1l0.2,0c0-0.2,0.1-0.4-0.1-0.5
        c0-0.1,0-0.3,0.1-0.4c0.1,0.1,0.2,0.2,0.4,0.2c-0.1-0.2-0.3-0.4-0.2-0.7c0,0-0.1-0.1-0.2-0.1c0,0.1,0,0.3,0.1,0.3
        c-0.4-0.1-0.3-0.5-0.5-0.7c0.4-0.2,0-0.3-0.1-0.4c0.1-0.2,0.1-0.6,0.3-0.6c0.1,0.2,0.2,0.5,0.3,0.7c0.4-0.2,0-0.6-0.1-0.9
        c-0.1-0.3-0.3-0.1-0.5,0c0-0.1-0.1-0.2-0.2-0.2c0.1,0,0.2,0,0.3,0c-0.1-0.1-0.2-0.3-0.3-0.4l0.3,0c-0.1-0.4-0.3-0.8-0.7-0.9
        c0.1,0,0.3-0.1,0.4-0.1c0-0.1-0.1-0.2-0.2-0.3c0,0.1-0.1,0.2-0.2,0.3c-0.2-0.1-0.3-0.2-0.4-0.3c0.1,0,0.4,0,0.5,0
        c-0.1-0.3-0.3-0.6-0.4-0.9c0.1,0,0.2-0.1,0.3-0.1c-0.1-0.1-0.3-0.2-0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.3c0-0.1,0-0.3,0-0.5l-0.1,0
        l-0.1,0.2c0,0-0.1,0.1-0.2,0.1c0-0.2,0.1-0.4,0.1-0.6c-0.1,0.1-0.3,0.2-0.3,0.3c0-0.2,0.1-0.4,0.2-0.6c-0.1-0.1-0.2-0.2-0.2-0.3
        c0.1-0.1,0.2-0.2,0.3-0.3c-0.2,0-0.4,0.1-0.6,0.2c0-0.2,0-0.4,0.1-0.6c-0.1-0.2-0.2-0.4-0.4-0.5c0.1,0,0.2-0.1,0.3-0.1
        c0-0.2,0-0.4-0.1-0.6c-0.1,0-0.3-0.1-0.3-0.1c0-0.4-0.1-0.7-0.4-0.9c0.1-0.2,0.1-0.6,0.4-0.5l0-0.1c-0.1,0-0.2,0-0.3-0.1l-0.3-0.3
        c0.1,0,0.4,0.1,0.6,0.2c0-0.1,0-0.2,0-0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2-0.1-0.2-0.2c-0.1-0.2-0.1-0.4-0.3-0.5
        c0.1,0,0.2-0.1,0.2-0.2c-0.5-0.2,0.2-0.5-0.2-0.7l0.2-0.1l0.1-0.2c-0.2,0-0.3,0-0.5,0.1c0-0.2-0.1-0.4-0.1-0.5
        c-0.1,0-0.3,0.1-0.5,0.1c0.1-0.2,0.2-0.5-0.1-0.6c0,0,0.1-0.1,0.2-0.1c0-0.3,0.1-0.7,0.5-0.5c-0.2-0.2-0.4-0.3-0.5-0.6
        c-0.1,0.2-0.3,0.4-0.4,0.5c0.1,0,0.2,0,0.3,0c0,0.1,0,0.3,0,0.4c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1,0-0.2,0.1-0.3,0.1
        c0-0.5,0.7-0.6,0.6-1.1c-0.2,0.1-0.3,0.2-0.3,0.4c-0.2-0.1-0.3-0.2-0.4-0.3c0.1,0,0.4,0,0.5-0.1c-0.1,0-0.2-0.1-0.2-0.2
        c0.1-0.2,0.1-0.4,0-0.6c-0.2,0-0.5,0-0.5-0.3c0.1,0,0.3,0.1,0.4,0.1l0.1-0.2c-0.2-0.1-0.5-0.2-0.7-0.3c0.2-0.2,0.4-0.7-0.1-0.6
        c0.3-0.5-0.4-0.5-0.6-0.8c0.1,0,0.4,0,0.5,0c-0.2-0.2-0.2-0.5,0-0.7c-0.2,0-0.3,0-0.3,0.2c-0.1,0-0.2-0.1-0.2-0.1
        c0-0.1-0.1-0.3-0.1-0.4c0.3,0,0.4-0.2,0.5-0.4c-0.1,0-0.3-0.1-0.4-0.1c0,0.3-0.1,0.4-0.4,0.4c0.1-0.4,0.4-0.9,0.4-1.3
        c-0.1,0.1-0.2,0.2-0.4,0.4c-0.2-0.2-0.4-0.3-0.6-0.4c0.3-0.2,0.7,0.2,0.9-0.2c-0.5,0.1-0.6-0.3-0.8-0.6c0,0.1,0,0.4,0,0.6
        c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0.1-0.2,0.1-0.3c-0.3,0-0.3-0.3-0.4-0.5c0.2,0,0.4,0.1,0.6,0.1c0.1,0.1,0.2,0.4,0.2,0.5
        c0-0.2,0-0.4,0.1-0.6c-0.2-0.1-0.4-0.1-0.5-0.2c0-0.1,0-0.2,0-0.3c0,0.1-0.1,0.2-0.1,0.3c-0.4-0.1-0.3-0.8,0.1-0.6
        c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2,0-0.4-0.2-0.5-0.3c-0.2-0.1-0.5-0.1-0.4-0.4c-0.1-0.1-0.2-0.2-0.2-0.4c0.4,0,0.6,0.5,1,0.6
        c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1,0-0.2,0.1-0.3,0.1c0-0.1,0-0.2,0-0.3c-0.2,0-0.6-0.1-0.5-0.4c-0.1,0-0.3,0-0.3-0.1
        c-0.2-0.1-0.1-0.4,0-0.5c-0.1,0-0.2,0.1-0.3,0.1c0-0.1,0.1-0.3,0.1-0.4c0.2,0.1,0.3,0.2,0.5,0.2c0.1,0.1,0.3,0.3,0.5,0.3
        c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.1,0.1-0.2,0.1-0.2c0.1,0.1,0.3,0.2,0.5,0.4c-0.1-0.3-0.4-0.4-0.6-0.5c0,0.1-0.1,0.2-0.1,0.2
        c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1-0.4-0.4-0.6-0.7-0.9c0-0.1,0.1-0.2,0.2-0.2c-0.3-0.1-0.2-0.3,0-0.4c-0.2-0.1-0.3-0.3-0.2-0.6
        c0.1,0.1,0.3,0.2,0.4,0.2c0.1-0.3-0.3-0.3-0.4-0.3c-0.1-0.1-0.4-0.2-0.5-0.3c0.1,0.1,0.3,0.3,0.4,0.4c0,0-0.1,0.1-0.2,0.1
        c-0.1-0.1-0.2-0.2-0.2-0.4c-0.1-0.2-0.1-0.5-0.4-0.5c0.1-0.2,0.2-0.4,0.3-0.5c-0.1-0.2-0.4-0.3-0.6-0.3c0.2-0.1,0.3-0.3,0.5-0.4
        c-0.1-0.1-0.2-0.2-0.3-0.3c0.2-0.1,0.2-0.2,0.2-0.4c-0.2,0.2-0.3,0.5-0.6,0.7c0-0.2,0-0.4,0.1-0.5c-0.3-0.1-0.4-0.3-0.5-0.5
        c0.1,0,0.3,0,0.5,0c-0.1-0.1-0.3-0.3-0.4-0.4c0.1-0.2,0.2-0.3,0.2-0.5c-0.1,0.1-0.3,0.1-0.4,0.2c0.1,0.2,0.2,0.4-0.1,0.5
        c-0.1-0.3-0.2-0.6-0.3-0.9c0.1-0.1,0.2-0.2,0.3-0.2l-0.4,0c0.1-0.1,0.2-0.2,0.2-0.3c0-0.2,0.3-0.3,0.4-0.4c-0.1-0.1-0.2-0.3-0.3-0.4
        c0.1,0.4-0.3,0.8-0.6,1c0-0.2-0.1-0.4-0.1-0.6c0.2,0.1,0.3,0.2,0.5,0.2c-0.1-0.2-0.3-0.8-0.6-0.3l-0.1,0c0.1-0.5-0.4-0.7-0.4-1.2
        c0.2,0.2,0.4,0.4,0.6,0.5c-0.2-0.2-0.4-0.5-0.5-0.9c-0.1,0.1-0.2,0.2-0.4,0.3c-0.1-0.1-0.2-0.3-0.3-0.4c0.1,0,0.2-0.1,0.2-0.2
        c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.2,0-0.3-0.1-0.4c0.2,0.1,0.5,0.3,0.7,0.4c0-0.3-0.3-0.3-0.5-0.4c-0.1-0.2-0.2-0.3-0.3-0.5
        c-0.2,0-0.6-0.1-0.5-0.4c0.1-0.5-0.2,0.3-0.4,0c0-0.2,0.1-0.4,0.1-0.5c0-0.1,0-0.3,0-0.3c-0.2,0.1-0.3,0.5-0.5,0.2
        c0.1-0.1,0.2-0.2,0.3-0.3c-0.1-0.2-0.1-0.3-0.1-0.5c0.1,0,0.3,0,0.4,0.1c0-0.4-0.7-0.5-0.5-1c-0.1,0-0.2-0.1-0.3-0.1
        c0.1,0.2,0.2,0.5,0.3,0.7c-0.2,0.2-0.1,0.7-0.4,0.6c0-0.2,0-0.4,0.2-0.6c-0.3-0.2-0.4-0.5-0.4-0.9c0.2-0.2,0.1-0.4-0.2-0.4
        c0.1,0.3-0.1,0.6-0.1,0.8c-0.3-0.2-0.1-0.6-0.1-0.9c-0.2,0-0.5-0.1-0.6-0.2c0.1-0.1,0.3-0.5,0.4,0l0.2,0.1c0-0.5-0.6-0.6-0.8-1
        c-0.1,0-0.3,0-0.4,0.1c0.2-0.1,0.4-0.2,0.6-0.3c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.3,0-0.5,0.1-0.7c-0.3-0.1-1-0.1-0.7-0.6l-0.2,0
        c0-0.1,0-0.2,0-0.3c0.1,0,0.2,0,0.3,0.1l0,0.2c0.1,0,0.2,0,0.3-0.1c-0.1,0.1-0.1,0.2-0.1,0.4l0.1,0c0.1-0.3,0.3-0.1,0.5,0
        c-0.2-0.2-0.3-0.4-0.5-0.6c-0.1,0-0.2,0-0.3,0c0-0.1-0.1-0.3-0.1-0.5c-0.5,0.2-0.7-0.4-1.1-0.5c0-0.1,0.1-0.2,0.1-0.3
        c-0.4,0.1-0.3-0.3-0.3-0.5c-0.3-0.3-0.5-0.6-0.8-0.8c0.1-0.2,0.4,0.1,0.6-0.1c0-0.2-0.1-0.3-0.2-0.4l-0.1,0.3
        c-0.1-0.1-0.2-0.2-0.3-0.2c0-0.3-0.1-0.9-0.5-0.6c-0.1-0.3,0.2-0.8-0.1-1c0.1-0.2,0.2-0.5,0.2-0.7c-0.3,0-0.4,0.2-0.2,0.5
        c-0.1,0-0.3-0.1-0.4-0.1c0.1,0.3,0.6,0.8,0.1,1c0-0.1,0-0.3,0.1-0.4c-0.4-0.1-0.4-0.5-0.2-0.8c-0.3,0-0.6,0-0.5-0.4
        c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0-0.3-0.1-0.4-0.1c0.2-0.3,0.7-0.3,0.8,0.1c0.1-0.2,0.1-0.4,0.2-0.7c-0.1,0-0.2,0.1-0.3,0.1
        c-0.2,0-0.5,0-0.7,0.1c0-0.1,0.1-0.3,0.1-0.4l-0.2,0.1c0-0.1,0-0.2,0-0.3l-0.3,0l0,0.2c-0.1,0-0.3,0-0.4-0.1c0.1-0.4,0-0.7-0.4-1
        c0.1,0,0.3,0,0.4,0c-0.2-0.2-0.5-0.3-0.7-0.3c0.1-0.2,0.2-0.3,0.3-0.5c-0.2,0-0.4,0.1-0.5,0.3c-0.1-0.2-0.2-0.4-0.3-0.5
        c0.1,0,0.2,0,0.3,0c-0.1-0.2-0.1-0.5-0.4-0.5c-0.3,0-0.4-0.2-0.6-0.3c0-0.1,0.1-0.4,0.1-0.5c-0.3,0-0.5,0.1-0.8,0.1
        c0-0.1,0-0.2,0-0.3c0.2,0,0.4-0.1,0.6-0.2c-0.1-0.1-0.2-0.2-0.3-0.2l0,0.3c-0.1,0-0.2,0-0.3,0c-0.1-0.3-0.2-0.5-0.5-0.5l0-0.2
        c0.1,0,0.4,0,0.5,0c-0.2-0.2-0.4-0.2-0.6,0.1c-0.1-0.4-0.4-0.7-0.6-1c0-0.1,0.1-0.3,0.1-0.4c-0.1-0.1-0.3-0.2-0.4-0.2
        c0.2,0.2,0.3,0.5,0.1,0.7l-0.1,0c0-0.1,0.1-0.3,0.1-0.3c-0.1,0-0.4,0-0.6,0c0.1,0,0.2-0.1,0.3-0.1c0-0.1-0.1-0.3-0.1-0.4
        c-0.1,0-0.3-0.1-0.5-0.2c0.3,0,0.5,0,0.8,0.1c-0.2-0.2-0.4-0.4-0.6-0.7c0,0.4-0.4,0.3-0.6,0.2c0.1-0.1,0.2-0.3,0.3-0.4
        c-0.1,0-0.3,0-0.4,0c0-0.2,0.1-0.3,0.1-0.5c-0.4-0.1-0.7-0.5-1-0.8c-0.3-0.1-0.6-0.3-0.6-0.6l0.3,0l0-0.2c-0.2,0.1-0.4,0.1-0.6,0
        c0.1-0.1,0.2-0.2,0.2-0.3c-0.1,0-0.3,0-0.4,0c0-0.1,0-0.3,0-0.4c-0.1,0-0.3,0-0.4-0.1l0,0c0.2-0.2,0.1-0.3,0-0.4
        c-0.1,0.1-0.2,0.2-0.3,0.2c0-0.1,0.1-0.3,0.1-0.4c-0.2,0.1-0.4,0-0.6-0.1c0.1-0.4-0.2-0.4-0.4-0.6c-0.1,0-0.2,0.1-0.2,0.1
        c0-0.2,0.1-0.3,0.1-0.5c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.3,0-0.4c-0.2,0-0.4,0-0.6,0c-0.3-0.3-0.4-0.8-0.9-0.8c0.1-0.4-0.3-0.5-0.5-0.6
        c-0.3-0.1-0.5-0.5-0.8-0.5c0-0.3-0.2-0.5-0.5-0.6c0,0,0-0.1,0.1-0.2c-0.1,0-0.3,0-0.4,0c-0.2-0.4-0.8-0.5-0.8-1
        c-0.1,0-0.2,0.1-0.3,0.1c0-0.1,0.1-0.3,0.1-0.4c-0.3,0,0,0.4-0.3,0.5c-0.1-0.1-0.1-0.3-0.2-0.4c-0.3,0-0.4-0.2-0.5-0.4
        c-0.1,0-0.4,0-0.5,0c0-0.1,0.1-0.2,0.1-0.4c-0.3,0-0.4-0.2-0.5-0.4c-0.2,0-0.3,0-0.5,0c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0-0.2,0-0.3,0
        c0-0.1,0.1-0.3,0.2-0.4c-0.4,0-0.6-0.2-0.8-0.6l0,0.3c0,0-0.1,0.1-0.2,0.1l0-0.3c-0.1,0-0.3,0.1-0.4,0.1c0-0.1,0.1-0.2,0.2-0.3
        c-0.4-0.3-0.7-0.6-1.2-0.8c-0.2,0-0.6,0.2-0.5-0.2c-0.2-0.1-0.3-0.2-0.3-0.4c-0.1,0-0.3,0-0.4,0c0-0.1,0-0.2,0-0.2l-0.1,0
        c0,0.1,0.1,0.3,0.1,0.4c-0.2-0.2-0.4-0.6-0.7-0.4c0-0.1,0.1-0.3,0.2-0.4c-0.1,0-0.2,0.1-0.3,0.3c0,0-0.1-0.1-0.2-0.1
        c0.1-0.2,0-0.4-0.2-0.5c-0.2,0-0.3,0-0.4,0.1c-0.1-0.2-0.3-0.4-0.5-0.6l0-0.1c-0.3-0.1-0.5-0.2-0.8,0c0-0.1-0.1-0.2-0.1-0.2l0.3,0
        l0-0.2c-0.1,0-0.4,0.1-0.5,0.1c0.1-0.2,0.2-0.3,0.3-0.5c-0.2,0.2-0.3,0.2-0.5,0.1c0,0.1,0,0.2,0,0.3h-0.1l0-0.2c-0.1,0-0.2,0-0.3,0
        c0.1-0.1,0.2-0.2,0.2-0.2c-0.1,0-0.3-0.1-0.4-0.2c-0.5,0.1-0.7-0.5-1-0.7c0.3,0,0.6,0,0.8,0.2c-0.1-0.1-0.1-0.3-0.3-0.4l-0.1,0
        c-0.2,0.1-0.3-0.1-0.4-0.2c-0.1,0.1-0.2,0.3-0.3,0.4c0-0.1,0-0.4,0-0.5c-0.1,0-0.3,0-0.3,0l-0.1,0l0.1-0.2c-0.3,0.1-1,0.4-0.9-0.2
        c-0.1,0.1-0.4,0.3-0.5,0c-0.1-0.2-0.5-0.8-0.5-0.2l-0.1,0c0-0.1,0.1-0.2,0.1-0.3c-0.2,0-0.3-0.1-0.5-0.2l0.1-0.2
        c-0.2,0-0.5,0.2-0.5-0.1c-0.2,0-0.3,0-0.5,0.1c0-0.4-0.4-0.3-0.6-0.4c-0.3,0.1-0.5-0.3-0.7-0.5c-0.3,0.1-0.5-0.2-0.7-0.3
        c-0.3-0.1-0.6-0.3-0.9-0.4c-0.2-0.1-0.5,0-0.5-0.3c-0.2,0-0.6,0.1-0.5-0.3c-0.3,0.1-0.4-0.2-0.7-0.2c-0.3-0.1-0.5-0.3-0.9-0.3
        c0-0.1,0-0.2,0.1-0.3c-0.2-0.1-0.4-0.2-0.7-0.1l0.1-0.2c-0.2,0-0.5,0-0.7,0c0.1-0.1,0.3-0.2,0.4-0.3c-0.4,0.1-0.7,0-1-0.2
        c0,0.1,0,0.2,0,0.3c0-0.1-0.1-0.3-0.1-0.4c-0.2,0-0.3,0-0.5-0.1c-0.3,0-0.4-0.1-0.4-0.3c-0.3-0.1-0.8-0.1-0.9-0.4
        c-0.3,0.1-0.6-0.1-0.8-0.3c-0.1,0-0.2,0-0.3,0.1c-0.2-0.4-0.7-0.3-1-0.5c-0.3-0.4-0.9-0.4-1.3-0.6c-0.3-0.2-0.6-0.5-0.8-0.7
        c0,0.1,0.1,0.3,0.1,0.4c-0.1-0.1-0.3-0.1-0.4-0.2c0-0.1,0.1-0.2,0.1-0.2c-0.2,0.1-0.6,0.1-0.6,0.4c-0.2,0-0.3,0.2-0.3,0.4
        c-0.3-0.1-0.5,0-0.5,0.3c0.1,0.1,0.2,0.2,0.2,0.2c-0.2,0-0.3-0.1-0.5-0.1c-0.2,0.1,0.1,0.3,0.1,0.5c-0.2-0.4-0.5,0.1-0.8,0.1
        c0,0.1,0,0.2,0,0.3c-0.1,0.1-0.2,0.3-0.2,0.4c-0.1,0-0.2-0.1-0.3-0.2c0.3,0.3,0,0.6-0.1,0.9c-0.2,0-0.5,0.1-0.7,0.1
        c-0.1,0.5-0.5,0.9-0.9,1.1c0,0.3-0.3,0.4-0.6,0.4c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.2-0.3,0.7-0.7,0.7c-0.4-0.1-0.5,0.4-0.6,0.6
        c-0.1,0-0.2,0.1-0.2,0.2l-0.1-0.3c-0.2,0.3-0.4,0.6-0.6,0.9c0-0.5-0.3-0.1-0.4,0.1c-0.1,0-0.3,0-0.4,0c0,0.1,0.1,0.2,0.2,0.3
        c-0.5,0.2-0.9,0.5-1.2,1c-0.2,0-0.4,0.1-0.6,0.1c0.1,0.1,0.3,0.2,0.3,0.3c-0.3,0.2-0.8,0.1-0.9,0.5c-0.1,0.5-1,0.4-0.9,0.9
        c-0.1,0-0.3,0.1-0.4,0.1c-0.3,0.6-1,0.6-1.3,1.1c-0.1,0-0.2-0.1-0.2-0.1c0,0.1,0,0.4,0,0.5c-0.1,0-0.2-0.2-0.3-0.2
        c-0.1,0.2-0.1,0.5-0.4,0.5c-0.2,0.3-0.5,0.7-0.9,0.7c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0.4-0.7,0.6-0.9,0.9l-0.2-0.1
        c-0.1,0.1-0.2,0.3-0.3,0.4c-0.4,0.2-0.7,0.7-1.2,0.8c-0.6,0.2-0.9,0.8-1.5,0.8c0,0.2-0.1,0.3-0.3,0.3c-0.2,0.2-0.5,0.3-0.6,0.6
        c-0.1,0-0.3,0-0.4,0c0,0.1,0,0.2,0,0.2c-0.8,0.1-1.3,0.8-2,1c-0.1,0.1-0.3,0.1-0.4,0.1c-0.1,0.2-0.3,0.3-0.4,0.4c-0.1,0-0.2,0-0.3,0
        c-0.3,0.4-0.8,0.4-1.2,0.7c-0.2,0.2-0.4,0.4-0.7,0.5c-0.4,0.2-0.9,0.4-1.3,0.6c-0.1,0.2-0.3,0.3-0.5,0.3c-0.2,0.1-0.3,0.2-0.4,0.3
        c-0.3,0.3-0.8,0.3-1.1,0.6c-0.6,0.4-1.2,0.5-1.8,0.9c-0.5,0.1-0.9,0.3-1.2,0.7c0.1-0.2,0.4-0.4,0.6-0.6c0.8-0.2,1.4-0.7,2.1-1
        c0.3-0.3,0.7-0.4,1-0.7c0.3-0.2,0.7-0.5,1.1-0.6c0,0,0.1-0.1,0.1-0.2c0.5-0.1,1-0.4,1.3-0.7c0.1,0,0.2,0,0.3,0
        c0.1-0.1,0.1-0.3,0.2-0.4c0.2-0.1,0.3-0.2,0.5-0.3c0.1,0,0.2,0,0.3,0c0.2-0.2,0.5-0.3,0.5-0.6c0.1,0,0.2,0,0.2,0.1
        c0-0.2,0.2-0.3,0.4-0.2c0-0.1,0-0.3,0-0.4c0.2,0.3,0.6,0.1,0.6-0.2c0.4,0,0.6-0.6,1-0.3c0-0.2,0-0.3,0-0.5c0.1,0,0.3,0,0.4,0
        c0.1-0.1,0.2-0.3,0.3-0.4c0.1,0,0.2-0.1,0.3-0.2c-0.1,0-0.2-0.1-0.3-0.2c0.1,0,0.2,0,0.3,0.1c0.1-0.3,0.4-0.7,0.8-0.6
        c0-0.1,0-0.2,0-0.3c0.1,0,0.3,0.1,0.4,0.1l-0.1-0.2c0.4-0.1,0.6-0.5,1-0.5c0-0.2,0-0.3,0.2-0.3c0,0.1,0,0.3,0,0.4
        c0.4,0,0.2-0.3,0.3-0.6c0.1,0,0.2,0,0.3,0c0.1-0.2,0.1-0.3,0.2-0.5c0.3,0.8,0.1-0.5,0.6-0.4l-0.1-0.2c0.1,0,0.2,0.1,0.3,0.1
        c0.1-0.5,0.7-0.6,0.9-1c0.1,0,0.2,0,0.3,0.1l-0.1-0.2c0.2-0.2,0.4-0.3,0.5-0.6c0.1,0,0.2,0,0.3,0c-0.1-0.3,0.3-0.6,0.5-0.3
        c0-0.1,0-0.3,0-0.4c0.3-0.3,0.6-0.5,0.9-0.7l0-0.2c0.2,0,0.4,0,0.6-0.1c-0.1,0-0.3,0-0.4,0c0.2-0.2,0.3-0.5,0.6-0.3
        c0-0.3,0.1-0.6,0.2-0.8c0.2,0,0.5,0,0.7-0.2c-0.1,0-0.4,0-0.5,0.1c0.1-0.2,0.3-0.3,0.5-0.4c0-0.2,0-0.4,0.1-0.5
        c0.1,0,0.2,0.1,0.3,0.1c0-0.1-0.1-0.2-0.1-0.4c0.2-0.2,0.3-0.3,0.4-0.6c0.3-0.2,0.6-0.5,0.3-0.9c0.1,0,0.2,0.1,0.3,0.1
        c0-0.1-0.1-0.2-0.1-0.3l0.2,0c0-0.1,0.1-0.2,0.1-0.3c-0.1,0-0.3,0.1-0.4,0.1l0-0.1c0.3,0,0.4-0.2,0.5-0.4c0.5-0.1,0.2-0.7,0.2-1
        l0.2,0.1c0,0.1,0.1,0.2,0.1,0.3c0.2-0.2,0-0.6,0.3-0.7c0.1-0.1,0.3-0.1,0.4-0.2c-0.2-0.2-0.3-0.3-0.5-0.5c0.1,0,0.3,0.1,0.4,0.1
        c0-0.2,0.1-0.4,0.1-0.6c0.1,0,0.2,0,0.3,0c0-0.1-0.1-0.2-0.2-0.3c0.2-0.1,0.6-0.1,0.5-0.4c0.4-0.1,0-0.4-0.1-0.5
        c0.1,0,0.3,0,0.4-0.1c-0.1-0.1-0.1-0.3-0.2-0.4c0.1,0,0.2-0.1,0.3-0.2l0-0.2c0.1,0,0.3,0,0.4-0.1c-0.1-0.1-0.2-0.2-0.3-0.2
        c0.1-0.3,0.1-0.6,0.3-0.8c-0.1-0.1-0.1-0.2-0.2-0.3c0.3,0,0.5-0.2,0.6-0.4c-0.1,0-0.3,0-0.3,0c0.1-0.2,0.1-0.3-0.1-0.3
        c0.1-0.1,0.2-0.2,0.3-0.3c0-0.2,0-0.3,0-0.5c0-0.2,0-0.4,0-0.6c-0.4,0.1-0.7-0.3-1.1-0.1c0-0.1-0.1-0.2-0.1-0.3
        c-0.6-0.2-1.1-0.9-1.7-0.7c0-0.1,0.1-0.2,0.1-0.3c-0.3,0-0.5,0-0.8-0.1l0-0.2l-0.1,0c-0.1-0.3-0.3-0.5-0.5-0.7l0.4-0.1
        c-0.3-0.1-0.6-0.1-0.8-0.3c0,0.3,0.5,0.3,0.3,0.7c-0.4-0.1-0.5-0.6-0.9-0.5c-0.2-0.1-0.4-0.3-0.6-0.4c-0.1,0-0.2,0.1-0.2,0.1
        c-0.2-0.2-0.5-0.4-0.7-0.5l0,0.3c0-0.1-0.1-0.2-0.2-0.2l-0.2,0.1c0.1-0.1,0.2-0.2,0.2-0.3c-0.2,0-0.4-0.1-0.5-0.1
        c-0.2-0.1-0.3-0.3-0.4-0.4c-0.1,0-0.4,0-0.5,0c0.1-0.1,0.3-0.2,0.4-0.3c-0.1,0-0.3,0.1-0.4,0.2c0-0.1,0.1-0.3,0.1-0.4
        c-0.1,0-0.3,0-0.4,0c0.1,0,0.2,0.1,0.3,0.2c0,0.1,0,0.2,0,0.3c-0.4-0.1-0.6-0.5-1-0.6c-0.1-0.1-0.2-0.4-0.4-0.3
        c-0.2,0.2-0.4,0-0.5-0.1c-0.1-0.1-0.2-0.2-0.4-0.2c-0.5-0.1-1-0.2-1.4-0.4c-0.7,0.1-1.4-0.2-2.1-0.3c-0.5-0.2-1.1-0.2-1.6-0.6
        c-0.6,0.2-1-0.3-1.6-0.3c-0.2,0-0.5-0.1-0.7-0.1c-0.3,0-0.7-0.1-1-0.2c-0.7,0.3-1.4-0.1-2.2,0c-1.1,0.1-2.3-0.2-3.4,0
        c-0.1,0-0.2-0.1-0.3-0.1c-0.5,0.2-1,0-1.5,0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.3,0.1-0.7,0.3-1.1,0.2c-0.2,0.3-0.4-0.1-0.7,0.1
        c-0.2,0.1-0.4,0.1-0.6,0.1c-0.3-0.1-0.7,0.1-1,0c-0.1,0-0.2,0.1-0.2,0.1c-0.2-0.1-0.4-0.2-0.5-0.3c-0.2,0.1-0.3,0.3-0.6,0.2
        c-0.5-0.1-1.2-0.3-1.7,0.2c-0.2-0.2-0.5-0.2-0.7,0c-1.3,0.1-2.6-0.1-3.9,0c-0.7-0.2-1.4,0-2-0.4c-0.7,0.3-1.3-0.2-1.9-0.3
        c-0.3-0.1-0.7-0.1-1-0.3c-0.4-0.3-0.9,0-1.3-0.4c-0.2-0.3-0.6-0.3-0.9-0.5c-0.4-0.3-0.9-0.4-1.3-0.6c-0.6-0.3-1.2-0.5-1.7-0.9
        c-0.8-0.5-1.7-0.7-2.4-1.3c-0.6-0.1-1.1-0.6-1.6-1c-0.8-0.2-1.3-0.8-2.1-1.1c-0.8-0.5-1.7-0.8-2.3-1.5c0.6,0.4,1.3,0.7,2,1
        c0.9,0.4,1.7,0.8,2.6,1.1c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.1,0.2,0.2,0.3c0-0.1,0.1-0.2,0.2-0.3
        c0.2,0.1,0.5,0.3,0.7,0.3l-0.1,0.2c0.2,0,0.5,0,0.7-0.1l0,0.2c0.2,0,0.4,0,0.7,0c0.2,0.1,0.3,0.3,0.5,0.4c0-0.1-0.2-0.3-0.2-0.4
        c0.2,0.1,0.4,0.3,0.7,0.4c0.1,0,0.3,0,0.4,0c-0.1,0.2-0.1,0.5,0.1,0.6c0-0.1-0.1-0.3-0.1-0.3c0.2,0,0.5,0,0.7-0.1l-0.1,0.2
        c0.1,0,0.2-0.1,0.3-0.2c-0.1,0.5,0.2,0.3,0.4,0.1c0.3,0.1,0.6,0.2,0.8,0.5c0.3-0.3-0.1-0.4-0.3-0.5c0.1,0,0.3,0,0.3,0
        c0.1,0.1,0.3,0.2,0.5,0.2c0.1,0.2,0.2,0.4,0.3,0.6c0-0.1,0.1-0.3,0.2-0.3c0.3,0,0.3,0.5,0.6,0.6c-0.1-0.2-0.2-0.4-0.2-0.5
        c0.1,0,0.3,0,0.4,0c0.2,0.1,0.4,0.3,0.6,0.4c0-0.1,0-0.4-0.1-0.5c0.2,0.2,0.3,0.4,0.5,0.5c0-0.1,0-0.3,0-0.4
        c0.2,0.1,0.4,0.2,0.5,0.4c-0.1,0-0.3,0-0.3,0l0,0.1c0.3,0.1,0.5,0.2,0.8,0.2c0.3-0.3,0.7-0.1,1.1-0.1c0-0.1-0.1-0.3-0.1-0.4
        c0.2,0.1,0.4,0.2,0.6,0.3c0-0.3,0.2-0.2,0.4-0.1c-0.2,0.2-0.4,0.3-0.5,0.6c0.4,0,0.6-0.3,0.8-0.5c0.1-0.1,0.3-0.2,0.4-0.3
        c0.2,0.1,0.4,0.2,0.5,0.2c-0.1,0.1-0.2,0.1-0.4,0.2c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0.2-0.3,0.4-0.4,0.6c0.1,0,0.3-0.1,0.4-0.1
        c0,0.1,0,0.3,0,0.4c0.1-0.1,0.2-0.3,0.3-0.4c0.3-0.1,0.4,0.1,0.5,0.4c0.2-0.5,0.5,0.2,0.8,0.2c-0.1-0.2-0.2-0.4-0.3-0.6
        c0.2,0.1,0.4,0.3,0.6,0.3c0-0.1,0-0.2,0-0.3c0,0,0.1,0.1,0.2,0.1c0.1-0.1,0.3-0.2,0.5-0.4c-0.1,0.2-0.2,0.4-0.3,0.6
        c0.1,0,0.4,0,0.5,0c0-0.1,0-0.2,0-0.3c0.3,0,0.5,0,0.8,0c0.1,0.2,0.3,0.2,0.5,0c0.1,0.1,0.3,0.1,0.5,0.1c-0.1,0-0.2,0.1-0.2,0.1
        c0,0,0.1,0.1,0.2,0.2c0.1-0.2,0.3-0.2,0.4-0.1c0.1,0,0.3,0,0.4,0c-0.2-0.3,0-0.5,0.3-0.5c-0.1,0.2-0.1,0.3-0.2,0.5
        c0.2,0,0.4-0.1,0.7-0.1c0-0.3,0.3-0.4,0.6-0.5c0.2,0,0.4,0.1,0.6,0.2c0.1-0.1,0.3-0.2,0.4-0.3c0,0.3-0.3,0.5-0.5,0.8
        c0.2,0,0.4,0.1,0.6,0.1c0.3-0.1,0.5-0.6,0.1-0.6c0,0.1,0.1,0.3,0.1,0.4c-0.1,0-0.3,0-0.4,0c0-0.3,0.2-0.5,0.4-0.7
        c0.1,0.3,0.4,0.5,0.7,0.6c0,0.1-0.1,0.3-0.1,0.4c0.2-0.2,0.4-0.4,0.5-0.6c0.1-0.1,0.3-0.2,0.4-0.3l0,0.3c0.1-0.1,0.2-0.3,0.4-0.4
        c0,0.2-0.1,0.4-0.2,0.5l0,0.1c0.2-0.1,0.3-0.2,0.5-0.3c-0.1,0.2,0,0.4,0.2,0.5c0-0.1,0-0.3,0-0.4l0.2,0c-0.1-0.1-0.2-0.3-0.3-0.4
        c0.2,0.1,0.7,0.2,0.9,0.2c-0.1,0-0.3,0.1-0.4,0.2c0.4,0.3,0.7-0.2,1-0.3c-0.3-0.1-0.7-0.1-0.6-0.5c0.2,0.1,0.5,0.3,0.8,0.2
        c0-0.1,0-0.3,0-0.4c0.1,0,0.2,0,0.3,0c-0.1,0.3-0.3,0.5-0.5,0.7c0.1,0.1,0.2,0.2,0.3,0.3c0.1-0.2,0.2-0.3,0.3-0.5c0,0.1,0,0.2,0,0.3
        c0.1,0,0.2,0,0.3,0c-0.1-0.1-0.2-0.3-0.2-0.4c0.3,0.1,0.5,0.2,0.6,0.5c0.3-0.1,0.4-0.5,0.6-0.7c0,0.1,0.1,0.4,0.1,0.5
        c0.3-0.2,0.6-0.2,0.8-0.5c0.2-0.3,0.6,0.2,0.8,0c0.2-0.1,0.6-0.5,0.8-0.2c0.1,0.3-0.4,0-0.5,0.2c-0.2,0.2-0.4,0.1-0.6,0.3
        c0.1,0.1,0.3,0.2,0.4,0.3c0-0.1,0-0.2,0.1-0.3l0.2,0.1c0-0.1,0.1-0.2,0.1-0.3c0.2,0.2,0.2,0.5,0.5,0.6c-0.1-0.2-0.3-0.5-0.2-0.7
        c0.1,0.1,0.2,0.3,0.4,0.4c0.1-0.3,0.5-0.3,0.5,0.1c0.1-0.2,0.4-0.4,0.1-0.6c0.3,0.1,0.5-0.2,0.7-0.4c0.2,0.1,0.4,0.2,0.4,0.5
        c-0.1-0.1-0.3-0.2-0.5-0.2c-0.1,0.3-0.6,0.2-0.6,0.7c0.2-0.1,0.5-0.2,0.7-0.3c0.3,0.2,0.6,0.1,0.7-0.1c0,0.1-0.1,0.3-0.2,0.4
        c0.2,0,0.5,0.1,0.7,0.1c0-0.1,0.1-0.2,0.2-0.2c-0.3,0-0.5,0.1-0.6-0.2c0.2-0.1,0.4-0.2,0.5-0.4c0,0.1,0,0.3-0.1,0.5
        c0.1,0,0.3-0.1,0.3-0.2c0,0,0.1-0.1,0.2-0.1c0-0.3-0.3-0.3-0.5-0.4c-1.3-0.7-2.5-1.4-3.8-1.9c-0.3-0.1-0.5-0.4-0.7-0.7
        c-0.8-0.1-1.5-0.4-2.1-0.9c-0.4-0.3-0.9-0.2-1.2-0.6c-0.3-0.1-0.7-0.2-0.9-0.5c-0.4,0.2-0.7-0.3-1.1-0.4c-0.7-0.3-1.4-0.6-2.1-0.9
        c-0.4-0.2-0.9-0.2-1.4-0.3c-0.9-0.3-1.8-0.6-2.6-1.1c-0.5,0-0.8-0.5-1.2-0.6c-0.5-0.1-0.9-0.4-1.4-0.6c-0.6-0.2-1.1-0.3-1.6-0.6
        c0.4,0,0.9,0.2,1.3,0.3l0-0.2c-0.8-0.3-1.7-0.5-2.4-1.1c-0.3,0.2-0.5-0.1-0.8-0.1c-0.8-0.2-1.5-0.6-2.3-0.9c-1-0.4-2.1-0.6-3.1-1.2
        c-0.1,0-0.2,0-0.3,0c-0.4-0.2-0.9-0.2-1.3-0.4c-1.1-0.4-2.2-0.8-3.2-1.3c-0.3-0.1-0.7-0.1-1-0.4c-0.5,0.1-1-0.3-1.5-0.3
        c-0.8-0.1-1.4-0.6-2.1-0.7c-0.5-0.1-1-0.3-1.6-0.5c-0.7-0.1-1.3-0.3-1.9-0.6c-0.2,0-0.5,0-0.7-0.2c-0.4,0-0.8-0.2-1.2-0.3
        c-0.9-0.3-1.8-0.4-2.7-0.7c-0.3-0.2-0.8-0.1-1-0.4c-0.2-0.1-0.3-0.4-0.5-0.1c-0.1-0.1-0.3-0.2-0.4-0.3c-0.5,0.1-0.9-0.2-1.3-0.4
        c-0.3,0.1-0.6-0.1-0.9-0.2c-0.5,0.1-1-0.2-1.5-0.3c-0.6-0.1-1-0.5-1.5-0.6c-0.6-0.2-1.1-0.4-1.7-0.5c0-0.1-0.1-0.2-0.2-0.2
        c-0.6,0.1-1.1-0.2-1.7-0.3c-0.5-0.3-1-0.3-1.5-0.5c-1.6-0.7-3.4-1.1-4.9-2c-0.7-0.1-1.4-0.5-2-0.8c-0.4-0.2-0.8-0.3-1.1-0.6
        c-0.6-0.5-1.5-0.5-2.1-1c-0.5-0.4-1.2-0.5-1.7-0.9c-0.9-0.7-2.1-1-2.9-1.7c-0.8-0.3-1.2-1-2-1.3c-0.2-0.1-0.5-0.2-0.5-0.5
        c-0.3-0.1-0.5-0.4-0.9-0.5c-0.4-0.1-0.5-0.5-0.9-0.7c-0.4-0.2-0.8-0.6-1.2-0.9c-0.6-0.6-1.3-1.1-2-1.6c-0.6-0.6-1.3-1.1-1.8-1.8
        c-0.3-0.4-0.8-0.6-1-1.1c0.4,0.4,1,0.8,1.4,1.3c0.7,0.7,1.5,1.3,2.3,1.8c0.4,0.3,0.9,0.5,1.2,0.9c0.1,0,0.2,0,0.3,0.1
        c0.2,0.3,0.6,0.4,0.8,0.6c0.5,0.2,0.9,0.7,1.4,0.7c-0.1,0-0.2,0.1-0.3,0.2c0.2,0.1,0.4,0.1,0.6,0c0.2,0.2,0.3,0.4,0.4,0.7
        c0-0.1,0-0.3,0-0.3c0.5,0.4,1.1,0.7,1.6,1c0.1,0.1,0.3,0.2,0.5,0.1c0.2,0.3,0.6,0.4,0.9,0.6c0.2,0.1,0.4,0.3,0.6,0.2
        c0.1,0.2,0.3,0.2,0.5,0c0,0.1,0.1,0.2,0.1,0.4c0.3,0.1,0.6,0.3,0.9,0.2c0.1,0.1,0.3,0.2,0.4,0.3c0.4,0.2,0.8,0.2,1.2,0.5
        c0.3,0.1,0.8,0,0.7,0.6c0.1-0.1,0.2-0.4,0.4-0.4c0.2,0.1,0.3,0.3,0.4,0.5c0.1-0.1,0.3-0.2,0.4-0.3l-0.2,0.2c0.1,0.2,0.3,0.2,0.5,0.2
        c0,0.1,0.1,0.2,0.1,0.3c0.2-0.1,0.3-0.1,0.5-0.1c0,0.1,0,0.3-0.1,0.5l0.2,0c0-0.1,0-0.2,0-0.3c0.2,0.1,0.4,0.2,0.5,0.4
        c0.1,0,0.2,0.1,0.3,0.1c0,0.2,0,0.4,0.2,0.5c0-0.3-0.1-0.5-0.1-0.8c0.1,0.1,0.3,0.2,0.4,0.3c0.3,0,0.3,0.3,0.5,0.6
        c0.3-0.2,0.1-0.4-0.1-0.4c0.1,0,0.3-0.1,0.4-0.2c0.1,0.1,0.1,0.3,0.2,0.5c0.1-0.1,0.3-0.2,0.4,0c0.1,0.2,0.1,0.5,0.3,0.7
        c0-0.3-0.1-0.6-0.1-1c0.1,0.2,0.4,0.4,0.3,0.7c0.1-0.2,0.3-0.3,0.6-0.4c0.1,0,0.3,0.1,0.4,0.2c0,0.1-0.1,0.3-0.1,0.4
        c0.2-0.2,0.4-0.3,0.7-0.3c0,0.1-0.1,0.3-0.1,0.4c0.4,0.1,0.7,0,1.1-0.2c0,0,0,0.1,0,0.2c-0.2,0-0.4,0-0.5,0.1c0,0.2,0.3,0.3,0.4,0.4
        c0.2,0.1,0.3,0.2,0.5,0.3c0.1-0.2,0.2-0.3,0.4-0.2c0,0.1,0,0.3,0,0.5c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0.1,0.2,0.3,0.3,0.4
        c0-0.1,0-0.3,0-0.5c0.2,0.1,0.4,0.3,0.5,0.6c0.1,0,0.2-0.1,0.3-0.1l0,0.2c0.5,0.1,1.4-0.2,1.5,0.5c0.2-0.1,0.5-0.3,0.7-0.3
        c0.1,0.3,0.2,0.6,0.3,0.9c0.2-0.1,0.4-0.3,0.4-0.5c-0.1,0-0.2-0.1-0.2-0.1l0-0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0,0.2,0,0.3,0
        c-0.2,0.1-0.4,0.3-0.4,0.6c0.3-0.1,0.5-0.3,0.6-0.5c0,0.1,0.1,0.2,0.1,0.3c-0.2,0-0.4,0.1-0.5,0.3c0.3,0,0.5-0.1,0.8-0.2
        c-0.1,0.1-0.2,0.3-0.2,0.4c0.2-0.1,0.3-0.2,0.4-0.3c0.1,0.1,0.3,0.1,0.5,0.1l0-0.1c-0.1,0-0.2-0.1-0.2-0.1c0,0,0-0.1,0-0.1
        c0.2,0.1,0.4,0.2,0.6,0.3c-0.1,0-0.2,0.1-0.3,0.1l0,0.1c0.1,0,0.3,0,0.4,0c0,0.1,0.1,0.2,0.1,0.3c0.2-0.1,0.4-0.2,0.7-0.3
        c0.1,0.2,0.1,0.3,0.2,0.5c-0.1,0.2,0,0.4,0,0.5l-0.1,0c0.1,0,0.2,0.1,0.2,0.1c-0.1-0.3,0-0.5,0-0.8c0.1,0.1,0.2,0.4,0.2,0.6
        c0.1-0.1,0.3-0.3,0.3-0.4c0.3,0.1,0.5,0.2,0.6,0.4c0-0.1,0-0.3,0-0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.3-0.2,0.6-0.3,0.9-0.1
        c0,0.1-0.1,0.2-0.2,0.3c0.2,0.2,0.5,0,0.8,0c0,0.1,0,0.3-0.1,0.3c0.2-0.1,0.3-0.1,0.5-0.2c0.1,0.2,0.1,0.3,0.2,0.5
        c0.2,0,0.3,0,0.5,0c0,0.1,0.1,0.2,0.2,0.3c0-0.2,0.1-0.4,0.1-0.6c0.3,0,0.6,0.1,0.7,0.4l0.2-0.1c0,0.1-0.1,0.4-0.2,0.6
        c0.2,0,0.5-0.2,0.6,0.1c0.1-0.1,0.1-0.2,0.2-0.3c0.1,0.1,0.1,0.5,0.3,0.4c0.3-0.2,0.8,0,1,0.3c0.1-0.1,0.2-0.2,0.3-0.2
        c0,0.2-0.1,0.5,0.3,0.4c0-0.1,0-0.4,0-0.5c0.1,0.1,0.2,0.4,0.3,0.5c0.2,0,0.3,0,0.5,0c-0.1,0-0.2,0.1-0.2,0.2
        c0.1,0.1,0.3,0.2,0.4,0.2c0-0.1,0-0.3,0-0.3c0.2-0.1,0.4,0,0.6,0c-0.1,0-0.2,0.1-0.3,0.2c0.3,0,0.5,0.3,0.7,0.5
        c-0.1-0.2-0.2-0.4-0.2-0.6c0.2,0.1,0.4,0,0.6-0.1c0,0.1-0.1,0.3-0.1,0.4c0.1,0,0.3,0.1,0.4,0.1l-0.1,0.2c0.2,0.1,0.3,0.4,0.6,0.3
        c-0.1-0.1-0.2-0.2-0.2-0.3c0.1-0.2,0-0.4-0.1-0.5l0.1,0c0.2,0.2,0.4,0.4,0.4,0.6l0.4,0c-0.1-0.2-0.2-0.5,0.1-0.6l0,0.2
        c0.2,0,0.3,0,0.5,0.1l-0.4,0c0.2,0.2,0.5,0.4,0.8,0.5c0.1-0.1,0.3-0.2,0.4-0.3c0,0.1-0.1,0.3-0.1,0.4c0.3-0.3,0.9,0.1,0.6,0.5
        c0.1,0,0.3-0.1,0.4-0.1l-0.1-0.3c0.2,0.1,0.4,0.2,0.3,0.5l0.1,0c0-0.2,0-0.4,0.3-0.4c0,0.1,0,0.2,0,0.3l0.3-0.1
        c-0.1,0.1-0.1,0.3-0.2,0.5c0.2-0.1,0.3-0.3,0.5-0.4c0,0.4,0.4,0.3,0.5,0c0.1,0.1,0.3,0.2,0.3,0.3c-0.1,0-0.4,0-0.5,0
        c0.1,0.1,0.2,0.2,0.3,0.3l-0.1-0.3c0.3,0.1,0.7,0.1,1,0.1c0,0.1-0.1,0.2-0.2,0.3c0.2,0,0.3,0,0.5,0.1c0-0.1-0.1-0.2-0.1-0.4
        c0,0,0.1,0,0.2,0c0,0.1,0,0.3,0,0.3c0.1,0,0.2,0,0.3-0.1c0-0.2-0.1-0.3-0.1-0.4c0.2,0.1,0.3,0.3,0.5,0.4c0,0,0.1,0.1,0.1,0.2
        c0.1,0,0.3,0,0.4,0c0,0.1,0,0.2,0,0.2c0.2,0,0.5,0.4,0.6,0.1c0.1,0,0.3,0,0.3,0l0,0.1c0.1,0,0.3,0,0.4,0.1c-0.1,0.1-0.2,0.2-0.2,0.3
        c0.4-0.3,0.8,0.1,1.2,0c0,0.1-0.1,0.2-0.1,0.4c0.2-0.1,0.3-0.3,0.5-0.5c0,0.1-0.1,0.3-0.1,0.5c0.3,0,0.5,0.1,0.8,0
        c0.1,0.2,0.3,0.5,0.6,0.3c0.1,0.1,0.1,0.3,0.2,0.4c0.3-0.1,0.5,0.2,0.6,0.4l0-0.3l0.3,0.1c-0.1-0.3-0.3-0.4-0.6-0.3
        c0.2-0.2,0.5-0.5,0.8,0c-0.1-0.1-0.2-0.4-0.3-0.5c0.3,0.3,0.8,0.4,1.2,0.7c0.1-0.3,0.2-0.1,0.4,0c-0.5,0-0.4,0.5,0,0.6l0.1-0.3
        l0.2,0.2c0-0.1,0.1-0.3,0.1-0.4c0.3,0.2,0.2,0.4,0,0.6c0.3-0.3,0.7-0.1,1,0c0.3,0,0.7-0.2,0.8,0.1c-0.1,0-0.4-0.1-0.5-0.1l0.1,0.2
        c0.1,0.1,0.3,0.2,0.4,0.2c0-0.1,0.1-0.2,0.2-0.2l0,0.3c0.2-0.2,0.5-0.2,0.8-0.2c-0.1,0.1-0.2,0.2-0.3,0.3c0.3,0.1,0.5,0.2,0.8,0.3
        c-0.1-0.1-0.1-0.3-0.1-0.4c0.2,0.2,0.1,0.5,0.1,0.7c0.2-0.1,0.5-0.2,0.5,0.1c0.1,0,0.2-0.1,0.3-0.2c0,0.1,0,0.3,0,0.4
        c0.3-0.1,0.5,0,0.7,0.2c0.2,0,0.4-0.1,0.6-0.1c0.1,0.2,0.1,0.5,0.4,0.4c0.1,0,0.3,0.1,0.3,0.2c-0.1-0.2-0.2-0.3-0.3-0.5
        c0.1,0,0.3,0,0.4,0.1c0,0.1-0.1,0.3-0.2,0.4c0.2,0,0.5,0,0.7,0l-0.1-0.2l0.1,0c0.1,0.2,0.3,0.4,0.4,0.6c0.1,0.1,0.2,0.4,0.5,0.2
        c0.2-0.3-0.1-0.5-0.2-0.7c0.3,0.1,0.4,0.5,0.7,0.7c0.1,0,0.3,0,0.4-0.1c-0.1,0.1-0.2,0.1-0.3,0.2c0.3,0.2,0.6,0.4,0.9,0.5
        c0.1-0.1,0.2-0.2,0.3-0.2c0,0.1,0.1,0.3,0.2,0.4c0.1-0.1,0.3-0.2,0.4-0.3c0,0.1-0.1,0.2-0.1,0.3c0.1,0.1,0.2,0.2,0.3,0.2l0.1-0.2
        c0.2,0.1,0.5,0.3,0.6,0.5c0.1,0.2,0.2,0.1,0.4,0.1c0,0.4,0.2,0.2,0.4,0c0,0.1,0,0.2,0,0.2c0.3-0.1,0.5,0,0.6,0.3l-0.4-0.1
        c0.1,0.2,0.3,0.2,0.5,0.2c0-0.1,0-0.3,0-0.4c0.3,0.1,0.4,0.3,0.4,0.6c0,0,0.1-0.1,0.2-0.1c-0.1-0.1-0.1-0.2-0.2-0.3
        c0.3-0.1,0.4,0.2,0.5,0.4c0-0.1,0.1-0.3,0.1-0.4c0.1,0.1,0.3,0.3,0.4,0.4c0-0.1-0.1-0.3-0.1-0.4c0.1,0,0.3,0,0.4,0l-0.2,0.2
        c0.1,0,0.3,0.1,0.4,0.1c0.1-0.1,0.2-0.2,0.2-0.3c0,0.3,0.1,0.4,0.3,0.2c0.2,0.1,0.4,0.1,0.6,0.2c0.1-0.4,0.3,0,0.5,0
        c0.2,0.1,0.3-0.1,0.5-0.2c0,0.1-0.1,0.2-0.1,0.3c0.2,0,0.6,0.3,0.6-0.1c-0.1,0-0.3,0-0.4,0c0.2-0.2,0.4-0.4,0.6-0.5
        c0,0.4,0.4,0.4,0.7,0.5c0.1-0.4-0.6-0.3-0.4-0.7c0.2,0.1,0.5,0.2,0.7,0.3c0.4,0.1,0.7,0.2,1.1,0.4c0-0.1-0.1-0.3-0.1-0.4
        c0.1,0,0.4-0.1,0.6-0.1c-0.1,0-0.3-0.1-0.4-0.2c0.2-0.1,0.3-0.3,0.5-0.4c0.2,0.3,0.4,0.4,0.7,0.4c0.1,0.1,0.3,0.2,0.4,0.3
        c0-0.1,0-0.3,0-0.4c-0.1,0-0.3,0-0.3,0c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0.2,0.2,0.4,0.4,0.6c0.1,0,0.2-0.1,0.2-0.2
        c0.2,0.1,0.4,0.1,0.7,0c0.2,0.1,0.3,0.2,0.5,0.3l0-0.1l-0.2,0l0-0.2c0.2,0,0.5,0,0.7,0c0-0.1,0-0.2,0-0.2l0.1,0
        c0.1,0.2,0.1,0.3,0.3,0.5c0.1,0,0.5-0.1,0.4-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c0.2-0.4,0.5-0.1,0.5,0.2c0.1,0,0.1-0.1,0.2-0.2
        c0.2,0.4,0.7,0.6,1.2,0.6c-0.2-0.2-0.3-0.6-0.4-0.1c-0.1-0.1-0.2-0.2-0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2c-0.2-0.2-0.4-0.7-0.8-0.5
        c-0.1-0.3-0.4-0.5-0.8-0.5c0-0.1,0-0.2-0.1-0.3c-0.6-0.1-0.8-0.7-1.2-1c0-0.1-0.1-0.2-0.1-0.3c-0.6,0.7,0.1-0.3-0.4-0.3
        c-0.1,0.1-0.3,0.1-0.4,0.2c0-0.1,0.1-0.2,0.1-0.3c-0.1-0.3-0.4-0.4-0.6-0.6c-0.3-0.1-0.5-0.4-0.9-0.3c0.1-0.1,0.3-0.2,0.4-0.3
        c-0.2-0.1-0.3-0.2-0.5-0.3l0.1,0.3c-0.2,0-0.3,0-0.5,0c0-0.2,0.1-0.3,0.1-0.5c-0.1,0-0.4,0.1-0.5,0.1c0.2-0.4-0.2-0.5-0.5-0.6
        c0-0.4-0.3-0.6-0.7-0.6c-0.3-0.1-0.3-0.5-0.6-0.6c0.1,0.2,0.2,0.3,0.2,0.5c-0.6,0.2-0.2-0.6-0.7-0.6c0-0.4-0.3-0.3-0.5-0.2
        c-0.1-0.2-0.2-0.3-0.4-0.5c0.3-0.5-0.7-0.2-0.5-0.7c-0.4,0-0.8-1-1.1-0.4c-0.2-0.1-0.1-0.3-0.1-0.5c-0.4,0-0.7-0.3-0.7-0.7
        c-0.2-0.1-0.3-0.2-0.4-0.5c0,0.1-0.1,0.2-0.2,0.3c-0.2-0.3-0.5-0.5-0.8-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.1-0.5-0.2
        c0-0.1-0.1-0.2-0.2-0.3c-0.4-0.2-0.7-0.7-1.2-0.6c0-0.1,0-0.3,0-0.4c-0.2-0.1-0.6-0.1-0.7-0.4c-0.1,0-0.2,0.1-0.3,0.1
        c0-0.2,0-0.4,0-0.6c-0.2,0-0.4,0-0.6,0.1c0-0.2,0-0.5-0.2-0.6c-0.1,0.1-0.3,0.2-0.4,0.3c0.1-0.1,0.1-0.3,0.2-0.4
        c-0.3-0.1-0.5-0.3-0.8-0.3c-0.2-0.4-0.7-0.5-1.1-0.8c0-0.1,0.1-0.2,0.1-0.2c-0.2,0.1-0.4,0.1-0.5-0.2c-0.1,0-0.2,0.1-0.3,0.1
        l0.1-0.1c0.1,0,0.3-0.1,0.3-0.1c-0.3-0.2-0.5-0.4-0.8-0.6c-0.2,0.1-0.4,0.1-0.4-0.1c-0.1,0-0.3,0.1-0.4,0.1c0.1-0.1,0.2-0.2,0.3-0.3
        c-0.2-0.1-0.4-0.2-0.7-0.3c-0.6,0.8-1.6,0.5-2.5,0.6c0.1-0.3,0.5-0.3,0.7-0.5c0.3-0.2,0.5-0.4,0.8-0.6c-0.1,0-0.2-0.1-0.3-0.1
        c0-0.1,0.1-0.2,0.2-0.3c-0.1,0-0.2,0-0.2,0c0-0.3-0.2-0.2-0.4-0.1c-0.1-0.3-0.4-0.2-0.6-0.2c-0.4,0.2-0.9,0.2-1.3,0.5
        c-0.5-0.1-1,0.2-1.6,0c-0.1,0-0.4,0.1-0.6,0.1c0.2-0.4,0.9-0.3,1.2-0.5c0.3-0.1,0.3-0.6,0.6-0.6l0-0.1c0.1,0,0.3,0,0.4,0.1
        c0-0.1-0.1-0.3-0.1-0.4c0.6-0.1,0-0.5-0.1-0.7c-0.3,0-0.6,0-0.7-0.3c-0.3,0.1-0.7,0.1-1,0.1c-0.3-0.2-0.7,0-1.1,0
        c-0.4,0.5-1,0.5-1.6,0.7c-0.9,0.2-1.7,0.2-2.6,0.2c-0.3,0.1-0.7,0.1-1,0.1c0.2-0.2,0.5-0.2,0.8-0.3c0.3-0.1,0.6-0.4,1-0.4
        c-0.1-0.3,0.1-0.5,0.2-0.1c0.4-0.2,0.7-0.8,1.2-0.6c0-0.1-0.1-0.2-0.1-0.3c0.3,0,0.6-1.1,0.4-0.1c0.3-0.1,0.3-0.4,0.3-0.7
        c0.1,0,0.2,0.1,0.3,0.1c0-0.3,0-0.6,0-1c-0.2-0.1-0.3-0.2-0.5-0.3c-0.5-0.2-1.1-0.2-1.7-0.3c-0.7-0.2-1.4,0.4-2,0.7
        c-0.5,0.2-0.8,0.7-1.3,0.9c0.2-0.2,0.4-0.5,0-0.6c0.1-0.1,0.2-0.3,0.3-0.5c-0.1,0-0.2,0.1-0.3,0.2c0-0.2-0.1-0.5-0.1-0.7
        c0.1-0.2,0.6-0.6,0.1-0.7c0,0.1,0,0.2,0,0.3l-0.2,0.1c-0.1-0.3,0.1-0.6,0.3-0.9c-0.2-0.1-0.3-0.2-0.5-0.3c0-0.1,0-0.4,0-0.5
        c0.1,0,0.2-0.1,0.3-0.1c-0.1-0.2-0.3-0.3-0.5-0.4c-0.3-0.1-0.7-0.2-1.1,0c0-0.1-0.1-0.2-0.1-0.3c-0.3,0.3-0.5,0.8-1,0.8
        c-0.8,0.2-1.6,0.1-2.3,0.3c0-0.2,0-0.3,0-0.5c0.3-0.3,0.3-0.8,0.4-1.2c0.1-0.1,0.3-0.1,0.3-0.3c0.1-0.1,0.2-0.3,0.3-0.3
        c-0.1,0-0.4,0.1-0.6,0.2c0.1-0.1,0.3-0.4,0.4-0.5c-0.1,0-0.4,0.1-0.5,0.1c0.1-0.1,0.2-0.3,0.3-0.4c-0.1,0-0.2,0.1-0.3,0.1l-0.1-0.2
        c-0.4,0-0.7-0.2-1-0.2c-0.2-0.1-0.5,0-0.6-0.3c0.2-0.1,0.3-0.2,0.4-0.3c0.2-0.1,0.5-0.1,0.4-0.4c-0.2,0-0.5,0.1-0.6,0.3
        c-0.5,0.3-1,0.1-1.5,0.4c0.3-0.2,0.4-0.5,0.5-0.9c0.1,0,0.2-0.1,0.2-0.1c-0.3-0.3-0.8-0.5-1.1-0.8c-0.3-0.1-0.7-0.1-1-0.1
        c-0.3,0.1-0.6,0.1-0.9,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.1,0.5-0.6,0.5-0.9,0.7c-0.1,0-0.2,0-0.3,0c-0.7,0.5-1.7,0.6-2.6,0.6
        c0.1-0.2,0.2-0.3,0.4-0.4c0.3-0.2,0.5-0.4,0.6-0.8c0.3-0.1,0.6-0.3,0.8-0.6c-0.1,0-0.3,0-0.3,0c0-0.1,0-0.3,0-0.4
        c0,0,0.1,0.1,0.2,0.2c0.1-0.2,0.2-0.3,0.3-0.5c0.2-0.1,0.4-0.2,0.6-0.4c-0.2,0.1-0.4,0.2-0.6,0.1c0-0.1-0.1-0.3-0.1-0.4
        c0.1,0,0.2,0,0.3,0c0-0.1,0-0.2,0-0.3c-0.2,0.4-0.5,0.3-0.5-0.1c-0.2,0-0.5,0.1-0.7-0.1c-0.2,0.2-0.5,0.2-0.8,0.2
        c-0.6,0.7-1.3,1.3-2.2,1.6c-0.1,0-0.2,0-0.2-0.1c-0.8,0.7-1.8,0.8-2.8,1.1c0.4-0.4,0.9-0.5,1.3-0.8c0.2-0.2,0.4-0.4,0.6-0.6
        c0-0.1,0.1-0.3,0.1-0.4c0.1,0,0.4,0.1,0.5,0.1c-0.1-0.1-0.2-0.3-0.2-0.4c0.2-0.1,0.3-0.3,0.6-0.2c-0.1-0.2,0-0.3,0.3-0.3
        c0.2-0.1,0.3-0.3,0.1-0.5c0,0.1-0.1,0.3-0.1,0.4c-0.3-0.2,0-0.6,0.2-0.7l0-0.1c-0.3,0.2-0.5-0.1-0.5-0.3c-0.2,0-0.3,0-0.5-0.1
        c-0.2-0.3-0.5-0.1-0.7-0.3c-0.6,0.2-1.2,0.3-1.7,0.5c-0.4,0.3-0.5,0.8-1,0.9c0,0.1-0.1,0.2-0.2,0.2c0,0-0.1-0.1-0.2-0.1
        c-0.4,0.4-0.9,0.8-1.5,0.8c-0.2,0.1-0.4,0.3-0.6,0.4c-0.4-0.3-0.7,0.7-1.1,0.3c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3,0-0.5,0
        c0.1-0.1,0.3-0.1,0.5-0.2c0.3-0.3,0.8-0.5,1.1-0.8c0.2-0.2,0.4-0.4,0.7-0.4c0.1-0.3,0.4-0.4,0.7-0.5c0-0.2,0-0.5,0.3-0.4
        c0.1-0.2,0.1-0.5,0.1-0.7l0.1,0c0,0.1,0,0.3,0,0.5c0.4-0.4,0.3-1.1,0.9-1.5c-0.1-0.2-0.1-0.4,0-0.5c-0.2-0.1-0.3-0.1-0.5,0
        c-0.3,0-0.5,0.1-0.8,0.1c-0.1-0.4-0.3,0.1-0.5,0.1c-0.4,0.2-0.8,0.8-1.3,0.6l0,0.2c-0.3-0.1-0.7,0.1-0.8-0.3l0.3,0
        c-0.2-0.3,0.7-0.9-0.1-0.6c0.1-0.3,0.5-0.3,0.6-0.7c0.1,0,0.3,0,0.4,0.1l0-0.2c-0.1,0-0.2,0-0.3,0l0-0.1c0.2,0,0.5-0.2,0.3-0.4
        c-0.3,0-0.6,0-0.8-0.3c-0.2,0-0.4,0.1-0.6,0.1c0-0.1,0-0.2,0-0.3c-0.3-0.1-0.5-0.2-0.8,0c-0.1,0-0.4,0-0.5-0.1
        c0.2-0.1,0.4-0.3,0.5-0.5c-0.2,0.1-0.5,0.2-0.7,0.3c-0.2-0.2-0.6-0.3-0.6,0c-0.2-0.2-0.5-0.4-0.7-0.6c-0.1,0-0.2,0.1-0.3,0.2
        c-0.1,0-0.2,0-0.2,0c-0.1-0.1-0.2-0.3-0.4-0.4c-0.2,0.2-0.4,0.4-0.6,0.6c0-0.1,0.1-0.2,0.1-0.2l0.2-0.1c-0.1-0.2-0.4-0.2-0.6-0.3
        c-0.1-0.1-0.3-0.2-0.4-0.1c0.1,0.1,0.2,0.3,0.2,0.4c-0.2,0-0.4,0-0.6,0c0-0.1,0.1-0.2,0.1-0.2c-0.3-0.1-0.4-0.2-0.6-0.4
        c-0.5,0-1.1-0.2-1.5,0.3c0-0.1,0.1-0.3,0.1-0.5c-0.3,0.5-0.9,0.2-1.2-0.1c-0.3,0-0.5,0.4-0.8,0.3c0-0.1,0-0.3-0.1-0.3
        c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1-0.1-0.2-0.3-0.3-0.4c-0.3-0.1-0.6,0.1-0.9,0.2c0-0.1,0-0.4,0-0.5c0,0-0.1,0-0.2,0
        c0,0.1,0.1,0.3,0.1,0.4c-0.4,0-0.8-0.3-1.2-0.3l-0.1,0.2c-0.2-0.4-0.4,0-0.6,0.2c-0.2,0-0.4,0-0.7,0c0.1,0,0.3-0.1,0.4-0.1
        c0.3,0,0.4-0.1,0.4-0.3c-0.3-0.1-0.6-0.1-0.8,0.2c-0.5-0.1-0.9-0.2-1.4-0.4c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2-0.1-0.5-0.2-0.7-0.3
        c-0.2,0.1-0.4,0.5-0.6,0.5c0-0.1,0.1-0.3,0.1-0.3c-0.3,0-0.5-0.1-0.8,0.1c-0.3-0.1-0.5-0.2-0.8-0.3c-0.1,0.1-0.2,0.2-0.2,0.3
        c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2-0.1-0.2-0.2c-0.3,0.1-0.7,0-1,0.2c-0.2-0.3-0.5-0.1-0.7-0.1c-0.3,0-0.6,0-0.9,0.2
        c0-0.1,0.1-0.2,0.1-0.3c-0.2,0.1-0.4,0.2-0.7,0.2c-0.2,0.1-0.3-0.2-0.6-0.2c0,0.1,0,0.3-0.1,0.3c-0.5-0.2-0.9-0.3-1.5-0.3
        c0,0-0.1,0.1-0.1,0.2c-0.2-0.2-0.3,0-0.4,0.2c-0.2,0-0.5-0.1-0.7-0.1c-0.3,0.2-0.6,0.4-1,0.2c-0.3,0.1-0.7,0.2-1,0.4
        c-0.2-0.1-0.5-0.1-0.7,0c-0.3,0.1-0.7,0-0.8,0.4c-0.1,0-0.3,0-0.4,0c0-0.1,0.1-0.2,0.1-0.3c-0.1,0.1-0.7,0-0.6,0.3
        c-0.3-0.1-0.5,0.1-0.8,0.1c-0.2-0.1-0.3-0.1-0.5-0.2l0,0.1l0.2,0.1l0.1,0.2c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2,0.1-0.3,0.3-0.3,0.5
        c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0.3-0.6-0.1-0.8,0.2c-0.2,0.3-0.6,0.2-0.9,0.2c0,0.1,0.1,0.3,0.1,0.4c-0.2,0-0.3-0.1-0.4-0.3
        c-0.1,0.1-0.3,0.2-0.4,0.3c-0.2-0.1-0.3-0.1-0.5-0.2c-0.2,0.2-0.3,0.5-0.6,0.5c-0.7,0.2-1.3,0.6-2,0.7c-0.2,0.1-0.5,0.3-0.4,0.6
        c-0.5-0.4-1,0-1.5,0.2c-0.3,0-0.3,0.2-0.3,0.4c-0.3,0.1-0.6,0.2-0.9,0.2c-0.3,0.5-0.9,0.6-1.4,0.9c-0.5,0.2-0.8,0.9-1.4,0.7
        c0,0.1-0.1,0.3-0.2,0.4c-0.1,0-0.2,0-0.2-0.1c-0.1,0.3-0.6,0.3-0.7,0.7c-0.2,0-0.4,0.2-0.5,0.4c-0.5,0.2-1.1,0.4-1.3,1
        c-0.7,0.2-0.9,1-1.4,1.3c-0.1,0.2-0.2,0.3-0.3,0.5c-0.2,0.1-0.4,0.2-0.6,0.4c-0.4,0.7-0.9,1.3-1.4,2c-0.3,0.4-0.4,0.9-0.7,1.3
        c-0.3,0.4-0.3,0.9-0.6,1.4c-0.3,0.5-0.3,1.2-0.7,1.8c0.1,0.4-0.1,0.7-0.3,1c0.3,0.6-0.4,1.1-0.3,1.7c0,0.3-0.2,0.7-0.1,1
        c-0.1,0.2-0.3,0.5-0.2,0.7c0.1,0.3,0,0.7-0.2,0.9c0.2,0.3,0.4,0.7,0.1,1c0.1,0.5,0.2,1,0.1,1.4c0.2,0.2,0.2,0.5,0.2,0.7
        c0.3,0.6,0.2,1.3,0.4,1.9c0.5,1.3,0.7,2.6,1.2,3.8c0.1,0.1,0.1,0.3,0,0.5c0.1,0,0.2,0,0.2-0.1c0.1,0.4,0.2,0.9,0.5,1.3
        c0.2,0.2,0.2,0.5,0.4,0.7c0.3,0,0.3,0.4,0.5,0.6c0.4,0.6,0.6,1.4,1.1,1.9c0,0.2,0.1,0.3,0.3,0.4c0,0.1,0,0.2-0.1,0.2
        c0.6-0.2,0.5,0.7,1,0.8l-0.1,0.1c0.3,0.1,0.4,0.6,0.7,0.7c0.1,0.2,0.2,0.3,0.3,0.5l0.1,0c0,0.2,0.1,0.3,0.2,0.4
        c0.3,0.4,0.6,0.8,0.7,1.3l0.2,0c0-0.1-0.1-0.3-0.1-0.4c0.2,0.1,0.3,0.3,0.3,0.5c0.1,0,0.3,0,0.3,0c0,0.1,0,0.3,0,0.4
        c0.5-0.2,0.3,0.5,0.6,0.7c0-0.1,0-0.3,0-0.4l0.2,0c0,0.1,0.1,0.2,0.1,0.3c0.3,0.5,1,0.6,0.9,1.2c0.1,0,0.4-0.3,0.5-0.1
        c0,0.1,0,0.2,0,0.3c0.1,0,0.2-0.1,0.3-0.1c0,0.1,0,0.2,0,0.3c0.2,0.1,0.6,0.3,0.5,0.6c0.1,0,0.3-0.1,0.4-0.1c0,0.1,0,0.2,0.1,0.3
        c0.1,0,0.2-0.1,0.3-0.1c0.1,0.8,1.4,0.7,1.4,1.5c0.3-0.2,0.5,0.3,0.9,0.2c0.2,0.2,0.3,0.7,0.7,0.5c0,0.3,0.3,0.3,0.5,0.4
        c0.1,0.2,0.3,0.3,0.5,0.4c0.2,0.1,0.4,0.3,0.5,0.4c0.2,0,0.4,0.1,0.6,0.2c0,0,0.1-0.1,0.2-0.1c0.1,0.1,0.1,0.2,0.2,0.4
        c0.2,0,0.4-0.1,0.5-0.1c0,0.1,0,0.2,0,0.3c0.2,0,0.4,0.1,0.6,0.1c0.2,0.2,0.4,0.5,0.8,0.4c0,0.6,1,0.2,0.9,0.8
        c0.1,0,0.2-0.1,0.3-0.2c0.1,0.1,0.2,0.2,0.2,0.3c0.2,0.1,0.4,0.1,0.6,0.1c0,0.1,0,0.3,0,0.3c0.2-0.2,0.7-0.3,0.5,0.2
        c0.1,0,0.2,0,0.3-0.1c0.2,0.1,0.4,0.2,0.6,0.1c0,0.4,0.5,0.2,0.8,0.3c0.1,0.3,0.5,0.3,0.7,0.3c-0.1,0.2,0.1,0.2,0.2,0.3
        c0.2-0.3,0.5-0.1,0.7,0.1c0.1,0,0.3,0,0.4,0c0,0,0.1,0,0.1,0c-0.1,0.4,0.3,0.3,0.5,0.2c0.2,0.5,0.7,0,1,0.3c0.1,0,0.2,0.1,0.4,0.1
        c0.2,0,0.4,0.1,0.6,0.1c0.3,0.1,0.6,0.3,0.9,0.3c0.3,0.2,0.7,0,1,0.2c0.1,0,0.3,0,0.4,0.2c0.1,0,0.2-0.1,0.3-0.2
        c0.1,0.1,0.2,0.2,0.3,0.3c0.1-0.1,0.3-0.2,0.4-0.1c0.5,0.1,1,0,1.5,0.1c0.2,0.2,0.5,0,0.8,0c0.3,0.1,0.6,0,0.9,0.1
        c0.7-0.1,1.4,0,2.2-0.1c0,0,0,0.1,0,0.1c-0.3,0-0.5,0-0.8,0c-0.2,0.2-0.5,0.1-0.7,0c-0.5,0.1-1.1,0.1-1.6,0
        c-0.3,0.1-0.7,0.3-0.8-0.1l-0.2,0.1c-0.6-0.2-1.2,0-1.8-0.2c-0.2-0.1-0.3-0.1-0.5,0c-0.1-0.1-0.3-0.1-0.4-0.1l0-0.2
        c-0.3,0-0.7,0-1,0l0-0.1c-0.4-0.1-0.7-0.2-1.1-0.2c-0.2-0.2-0.4-0.1-0.6-0.1c-0.2-0.3-0.5-0.4-0.8-0.2c-0.1,0-0.2-0.1-0.3-0.2
        c-0.3,0-0.6,0-0.9-0.2c-0.2,0.1-0.4-0.1-0.6-0.2c-0.1,0-0.2,0-0.3,0c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2,0-0.5-0.1-0.7-0.2
        c-0.2-0.2-0.5,0-0.8-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.2,0-0.3c-0.1,0-0.3,0.1-0.4,0.1
        c-0.3-0.3-0.8-0.4-1.2-0.6c-0.3-0.2-0.8-0.3-1-0.6c-0.4,0-0.6-0.3-0.9-0.4c-0.4-0.2-0.9-0.3-1.3-0.5c-0.2-0.1-0.4-0.3-0.7-0.4l0-0.2
        c-0.1,0-0.2,0-0.3,0.1l0-0.2c-0.1,0-0.3,0.1-0.3,0.1c0-0.1,0-0.2-0.1-0.3l-0.2,0.1l0-0.2c-0.1,0-0.3,0-0.3,0
        c-0.1-0.1-0.3-0.2-0.4-0.3c-0.3,0-0.5-0.1-0.5-0.3c-0.1,0-0.2,0-0.3,0l0-0.2c-0.2-0.1-0.5-0.3-0.7-0.3c0-0.1-0.1-0.2-0.1-0.2
        c-0.4-0.1-0.8-0.4-1.1-0.6c-0.2-0.3-0.4-0.5-0.8-0.6l0-0.1c-0.2,0-0.4-0.1-0.5-0.3l-0.1,0l0-0.1c-0.2,0-0.3-0.1-0.3-0.3
        c-0.2-0.1-0.2-0.2-0.3-0.4c-0.1,0-0.2,0.1-0.3,0.1c-0.2-0.4-0.7-0.7-1-1c0-0.1,0-0.2,0-0.2c-0.2,0-0.5,0-0.5-0.3c-0.1,0-0.2,0-0.2,0
        c0-0.2-0.1-0.4-0.3-0.4c0-0.3-0.4-0.4-0.6-0.5c-0.2-0.3-0.3-0.6-0.6-0.8c-0.2-0.2-0.4-0.4-0.6-0.7c-0.3-0.4-0.7-0.5-0.9-1
        c-0.1,0-0.2-0.1-0.3-0.1c0-0.1,0-0.2,0.1-0.3c-0.4-0.2-0.7-0.6-1-1c-0.1-0.2-0.3-0.4-0.4-0.6c-0.4-0.4-0.5-1-0.9-1.4
        c-0.2-0.2-0.4-0.5-0.6-0.8c-0.4-0.4-0.8-0.9-1-1.4c0.2,0.1,0.5,0.2,0.6,0.5l0.1,0c-0.1-0.2-0.3-0.4-0.4-0.6c-0.1,0-0.3,0-0.4,0
        c0-0.3-0.1-0.5-0.4-0.5c0.1-0.1,0.2-0.2,0.3-0.3c-0.1-0.2-0.3-0.4-0.5-0.4c0-0.1,0-0.3,0-0.4l-0.2,0c0.1-0.3,0.4-0.5,0.2-0.8
        c-0.1,0.1-0.2,0.2-0.4,0.3l0-0.5c-0.1,0-0.2,0-0.2,0c0.2-0.3-0.1-0.4-0.2-0.7c0-0.1,0.1-0.3,0.1-0.4c-0.1,0-0.2,0.1-0.3,0.1
        c0-0.1,0.1-0.3,0.1-0.5c-0.1-0.1-0.3-0.1-0.4-0.2c0.1,0,0.3,0,0.4,0c-0.2-0.3-0.5-0.6-0.4-1c0,0-0.1,0-0.2,0c0-0.3-0.1-0.5-0.4-0.4
        c0-0.1,0-0.2,0-0.2c0.1,0.1,0.3,0.2,0.4,0.2c-0.2-0.4-0.3-0.9-0.6-1.2c0.1-0.4-0.4-0.7-0.2-1c-0.1-0.1-0.1-0.3-0.2-0.4
        c0.1,0,0.3,0.1,0.4,0.1l-0.1-0.4c-0.1,0.1-0.4,0.2-0.5,0.3c0.3-0.2,0.3-0.5,0.2-0.9c-0.1,0-0.3,0.1-0.4,0.1c0.1-0.2,0.3-0.4,0.4-0.6
        c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.1-0.1-0.2-0.2-0.3c0.2-0.3,0-0.7,0.2-1c-0.3,0.3-0.4-0.1-0.5-0.3c0.1,0,0.2,0.1,0.3,0.2
        c0-0.1,0.1-0.4,0.1-0.6l-0.3,0c0.1-0.1,0.1-0.2,0.2-0.3c0-0.3,0-0.5,0.1-0.7c-0.3-0.4,0.1-0.7,0.3-1c-0.1,0-0.4,0-0.5,0
        c0.1-0.1,0.2-0.2,0.3-0.3c0-0.1-0.1-0.3-0.1-0.4c0.1,0,0.3,0,0.4,0l0-0.3c-0.1,0-0.3,0.1-0.4,0.1c0.1-0.2,0.2-0.4,0.3-0.7
        c-0.1-0.2-0.2-0.3-0.3-0.5c0.2,0,0.4,0.2,0.5,0.4c0.1-0.3,0.4,0,0.6,0.1c0-0.4-0.4-0.4-0.6-0.2l0-0.3L42,316
        c0.3,0.4,0.6-0.1,0.9-0.1c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2,0-0.3,0-0.5-0.1c-0.1-0.4,0.5-0.1,0.6-0.4l-0.2,0c0-0.2-0.1-0.3-0.2-0.4
        c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.1,0.3-0.2,0.5-0.4c-0.1,0-0.4,0-0.5,0c0-0.1,0.1-0.2,0.1-0.2c0.5,0.5,0.4-0.4,0.7-0.5
        c0.4,0,0-0.4,0-0.7c0.1-0.1,0.2-0.3,0.3-0.4c0.4-0.4,0.4-1.1,0.5-1.6c0,0.1,0.1,0.2,0.2,0.2c0-0.2,0.1-0.4,0.3-0.6
        c0-0.4,0.2-0.8,0.5-1c0.1,0.3-0.3,0.5-0.2,0.9c0.1-0.1,0.3-0.2,0.4-0.3c0-0.1,0-0.3,0-0.4c0.1,0,0.4-0.1,0.5-0.1
        c-0.2,0-0.4-0.3-0.4,0.1c-0.2-0.2-0.2-0.6,0.2-0.4c0.2-0.3,0.5-0.5,0.4-0.9c0.2,0,0.3,0,0.5-0.1c0-0.3-0.1-0.7,0.2-0.9
        c0,0.2-0.2,0.6,0,0.8c0.5-0.1,0.3-0.5,0.2-0.9c0.1-0.1,0.3-0.1,0.4-0.2c0.1-0.2,0.2-0.3,0.4-0.5c0.1,0.1,0.2,0.2,0.3,0.4
        c0-0.2-0.1-0.4-0.1-0.6c0.1,0,0.2,0.1,0.3,0.1c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.3-0.3,0.4-0.4c0.2,0,0.3,0.2,0.5,0.3
        c-0.1-0.3-0.3-0.5-0.6-0.6c0.1,0,0.3,0,0.4,0c0.1,0,0.3-0.1,0.4-0.1c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.4-0.1,0.4-0.3
        c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.1,0.4-0.1,0.3-0.3c0.2,0,0.4,0,0.6,0.1c0-0.1,0.1-0.3,0.2-0.3c0.1,0,0.3,0,0.4,0
        c0-0.1-0.1-0.3-0.1-0.4c0.1,0,0.2,0,0.3,0c-0.1-0.2-0.1-0.5,0.1-0.6c0.1,0.2-0.1,0.6,0.2,0.7c0-0.4,0.4-0.5,0.5-0.8
        c0.1,0,0.2,0.1,0.3,0.2c0.1-0.2,0.2-0.4,0.4-0.6c0.1,0,0.5,0.2,0.2-0.2c0.6,0.2,0.7-0.5,1.1-0.8c-0.1,0-0.4,0-0.5,0
        c0.1-0.5,0.4,0.1,0.7-0.1c0.2-0.1,0.5,0,0.7,0.1c-0.5-0.3,0-0.8,0.4-0.5c0-0.1,0-0.2,0-0.3c0.5-0.1,0.9-0.4,1.3-0.7
        c0.1,0,0.3,0.1,0.4,0.1l-0.1-0.3c0.1,0,0.3,0,0.4,0c0,0,0.1,0.1,0.1,0.2c0,0,0.1,0,0.1,0c0.1-0.3,0.4-0.4,0.6-0.5
        c0-0.2-0.1-0.4,0.1-0.5c0,0.1,0,0.3,0,0.4l0.4,0c0-0.1-0.1-0.2-0.1-0.3c0.3-0.1,0.6-0.3,0.8-0.4c-0.1,0.1-0.2,0.4-0.2,0.5
        c0.3,0,0.6-0.1,0.9-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.5,0.4-0.1,0.5,0c0.1-0.2,0.1-0.3,0.1-0.5c0.1-0.1,0.4,0.1,0.4-0.2
        c0.2,0,0.4,0,0.6-0.1c0,0.1,0,0.4,0,0.5c0.1,0,0.2,0.1,0.3,0.1c0,0,0-0.1,0-0.1c-0.7,0,0.1-1.1,0.3-0.3c0-0.5,0.6-0.5,0.8-0.2
        c0-0.2,0.1-0.4,0.1-0.6c0.1,0.1,0.3,0.6,0.4,0.3c0-0.1,0-0.3,0-0.4c0.2,0,0.4,0,0.7,0.1c0-0.1,0.1-0.2,0.1-0.3
        c0.1,0.1,0.2,0.2,0.3,0.3c0-0.3,0.3-0.4,0.5-0.5c0.1,0,0.3,0.1,0.4,0.1c0.2,0.1,0.3,0.1,0.5,0.2c0-0.1-0.1-0.2-0.1-0.3
        c0.2-0.1,0.4-0.2,0.7-0.3c0,0.1,0,0.2,0,0.3l-0.1,0c-0.5-0.1-0.1,0.3,0,0.4c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0,0.2,0
        c0.2-0.1,0.4-0.2,0.7-0.1c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1,0.1-0.3,0.1-0.4,0.3c-0.1,0-0.4,0-0.5,0c0-0.1,0-0.3,0.1-0.4
        c0.3,0,0.5,0,0.8,0c0.2-0.3,0.5-0.1,0.4,0.2c0.3-0.3,0.8-0.8,1.2-0.6c0,0.1,0,0.3,0.1,0.3c0.1-0.1,0.3-0.1,0.4-0.2
        c0.1-0.1,0.1-0.3,0.2-0.4c0.1,0,0.2,0,0.3-0.1c0,0.1,0.1,0.3,0.1,0.3l-0.3-0.1c-0.4,0.4,0.8,0.3,0.5-0.1c0.1,0,0.2,0,0.3,0
        c0.1,0.1,0.2,0.1,0.3,0.2c0.1-0.1,0.3-0.2,0.4-0.3c-0.1,0-0.3,0-0.5,0c0.1-0.1,0.2-0.3,0.2-0.4c0.2,0.5,0.7,0.1,1,0.4
        c0-0.1,0.1-0.2,0.1-0.3c0.1,0,0.3,0.1,0.5,0c0.4,0,0.7,0,1-0.3c0,0.1,0.1,0.2,0.1,0.3c-0.1,0-0.2,0-0.3,0c0,0.1,0,0.2,0,0.3
        c0.2-0.1,0.3-0.2,0.5-0.3l-0.3,0.5c0.5-0.1,0.5-0.9,1.1-0.8c-0.2,0.2-0.4,0.3-0.5,0.6c0.2,0,0.5-0.1,0.6-0.2
        c0.2,0.1,0.4,0.2,0.6,0.4c-0.1-0.2-0.2-0.4-0.3-0.6c0.1,0.1,0.3,0.2,0.4,0.3c0-0.3,0.3-0.3,0.5-0.2c-0.1,0.2-0.3,0.2-0.5,0.3
        c0.1,0.4,0.5,0.1,0.7-0.1l-0.1-0.3c0.2,0.1,0.3,0.4,0.5,0.5c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.4-0.2,0.5-0.3l-0.2,0.4
        c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.2,0.1,0.2,0.1c0-0.1,0.1-0.2,0.1-0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1-0.1,0.2-0.1,0.3-0.2
        c0.2,0.3,0.4-0.2,0.6-0.1c0.2,0.1,0.4,0.2,0.6,0.4c0-0.1,0-0.2,0-0.2c-0.1,0-0.2,0-0.3,0c0.1-0.5,0.4,0,0.7,0.1
        c0.1-0.4,0.6-0.1,0.9-0.1c0.3,0,0.6-0.5,0.8-0.2c0.1-0.1,0.3-0.1,0.4-0.2c-0.1,0.1-0.3,0.3-0.3,0.5c0.1,0,0.3-0.1,0.4-0.1
        c-0.2,0.4,0.2,0.5,0.4,0.2c0.1,0,0.2,0,0.4-0.1c0.2,0.2,0.4-0.1,0.5,0.2c0.2,0,0.5-0.1,0.5,0.2c0.2-0.1,0.3,0.1,0.5,0.1
        c0-0.1,0-0.2,0-0.2c-0.4,0-0.6-0.1-0.6-0.6c0.2,0.1,0.4,0.3,0.7,0.5c0-0.1,0-0.3,0-0.4c0.2,0.3,0.4,0.1,0.7,0.1
        c0.1,0.2,0.6,0.1,0.4,0.4c-0.1,0-0.3-0.1-0.3-0.2c-0.1,0-0.3,0-0.4,0l0,0.1l-0.2,0l0,0.2c0.1,0,0.3-0.1,0.4-0.2l0,0.2
        c0.3,0,0.6-0.2,0.9,0c0.1,0,0.2,0,0.3-0.1c0.1,0.2,0.2,0.3,0.4,0c0.3,0.3,0.7,0.3,1.1,0.3c-0.1-0.1-0.2-0.3-0.2-0.4
        c0.3,0.1,0.5,0.2,0.8,0.4c-0.4,0-0.4,0.1-0.2,0.3c0.1-0.1,0.2-0.2,0.2-0.3c0.4,0.1,0.8,0.2,1.2,0.1c-0.1,0.4,0.4,0.3,0.7,0.4
        c0.1-0.2,0.1-0.4,0-0.5c0.2,0.1,0.5,0.1,0.7,0c-0.2,0.1-0.4,0.2-0.5,0.4c0.2,0.3,0.6,0,0.8,0.2c0-0.1,0-0.2,0-0.3l0.4,0.1
        c-0.1,0.1-0.2,0.2-0.2,0.3l0.3,0.1c0.1-0.2,0.2-0.3,0.3-0.5c0.2,0,0.3,0,0.5,0.1c0.1,0.1,0.2,0.2,0.3,0.4c-0.1,0-0.3,0-0.4,0
        l0.1,0.2c0.7-0.1,1.5,0.3,2.1-0.1c0.1,0.2,0.1,0.4,0.2,0.5c0.1,0,0.3,0,0.4,0l-0.1-0.3c0.1,0.1,0.3,0.1,0.4,0.2
        c0.1-0.1,0.2-0.2,0.3-0.2l-0.1,0.3c0.2,0.2,0.3,0.5,0.7,0.5c-0.1-0.2-0.2-0.5-0.3-0.7c0.1,0.1,0.3,0.3,0.4,0.4
        c0.1-0.1,0.3-0.2,0.5-0.2c-0.2,0.2-0.5,0.3-0.4,0.6c0.2,0,0.5-0.4,0.6-0.2c0,0.1-0.1,0.2-0.2,0.3c0.2,0,0.3-0.1,0.5-0.1
        c0.1,0.1,0.1,0.3,0.2,0.4c0.1-0.1,0.2-0.2,0.3-0.3c0.3,0,0.5,0.2,0.6,0.4c0.3,0.1,0.6,0.2,0.9,0.1c0.2,0.1,0.4,0.4,0.6,0.1
        c0.2,0,0.4,0.1,0.6,0.1l-0.2,0.3c0.3-0.1,0.5-0.2,0.7-0.3c0,0.1,0,0.3,0,0.4c0.2,0.1,0.4,0.2,0.6,0.3c0.1-0.1,0.2-0.3,0.2-0.3
        c0.3,0.4,0.8,0.6,1.2,0.9c0.2-0.1,0.4-0.2,0.6-0.4c0,0.1-0.1,0.4-0.2,0.6c0.2,0,0.3-0.2,0.4-0.3c0.2,0.4,0.6,0.2,1,0.2
        c-0.1,0.1-0.2,0.3-0.4,0.4c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.3,0,0.5,0c0.1,0.1,0.2,0.1,0.4,0.2c0.2,0,0.3,0.1,0.5,0.1
        c0,0.1,0,0.3,0.1,0.4c0.3,0,0.3-0.2,0.4-0.5c0,0,0.1,0.1,0.1,0.2c0.3,0,0.6,0,0.8,0.2c-0.1,0-0.3,0-0.4,0c0,0.1,0,0.2-0.1,0.3
        c0.7-0.2,1,0.6,1.7,0.5c0.2,0.2,0.3,0.4,0.5,0.6c0.2-0.2,0.1-0.4,0.1-0.5c0,0,0.1,0,0.2,0c0,0.1,0.1,0.4,0.1,0.5
        c0-0.1,0.1-0.3,0.2-0.4c0.1,0.1,0.3,0.2,0.4,0.3c0,0.1,0,0.2,0,0.3c0.1,0,0.3,0,0.4,0.1c0,0,0.1,0.1,0.1,0.2l0-0.3
        c0.3,0.3,0.7,0.3,1.1,0.4c0.2,0.1,0.3,0.3,0.6,0.3c0,0.1,0,0.2,0,0.3c0.2,0,0.5,0.1,0.7,0c0.1,0.2,0.2,0.3,0.3,0.5
        c0.1,0,0.2-0.1,0.3-0.1c0.1,0.4,0.5,0.1,0.4-0.2h0.1c0,0.3,0.2,0.5,0.4,0.7c0-0.1,0.1-0.3,0.1-0.4c0.1,0.2,0.2,0.3,0.2,0.6
        c0.3,0,0.6-0.1,0.6,0.3c0,0,0.1,0.1,0.2,0.1c-0.1-0.2-0.1-0.3-0.2-0.5c0.4,0.2,0.7,0.5,1.1,0.6c0,0.1-0.1,0.2-0.1,0.3
        c0.2,0,0.4,0,0.6,0c0.1,0.2,0.2,0.3,0.3,0.5c0.1-0.1,0.2-0.2,0.2-0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2-0.1,0.3-0.1
        c0.1,0.3,0.3,0.4,0.5,0.7c0.2,0,0.4,0,0.6,0.1l0-0.2c0.2,0,0.4,0,0.6,0.1c-0.1,0-0.3,0.1-0.4,0.2c-0.1,0-0.2,0.1-0.3,0.1
        c-0.1,0.1-0.2,0.2-0.3,0.2c0.3,0,0.5-0.1,0.8-0.2c0,0.3,0.2,0.4,0.5,0.4c-0.1-0.1-0.2-0.2-0.2-0.3c0.5-0.3,0.6,0.3,0.7,0.6
        c0.2-0.2,0.7,0.1,0.6,0.4c0.2,0,0.5-0.4,0.6-0.1c-0.2,0.1-0.3,0.2-0.5,0.3c0.3,0.1,0.6,0.1,0.9,0.3c0.1,0,0.2-0.1,0.2-0.2
        c0.1,0.1,0.2,0.3,0.4,0.4c0,0.1,0.1,0.2,0.1,0.3c0.1-0.1,0.3-0.1,0.4-0.2c-0.1,0.2-0.1,0.3-0.2,0.5c0.1,0,0.3,0,0.4-0.1
        c0.2,0.4,0.6,0.4,0.8,0.8c0.1-0.1,0.2-0.1,0.3-0.2c0,0.1,0.1,0.3,0.1,0.4c0.2,0,0.4-0.1,0.6-0.2c0,0,0.1,0.1,0.1,0.2
        c0,0.1-0.1,0.2-0.2,0.3c0.1,0,0.3,0,0.5,0c0,0.1,0,0.3-0.1,0.5c0.1-0.1,0.2-0.2,0.3-0.2c0.3,0.2,0.6,0.5,1,0.3
        c0.2,0.2,0.8,0.3,0.4,0.6c-0.1-0.1-0.4-0.3-0.5-0.4c0.1,0.2,0.2,0.5,0.3,0.7c0.5-0.2,0.9,0.2,1.3,0.3c0,0.1,0,0.2,0,0.3
        c0.1,0,0.2-0.1,0.3-0.1c0.1,0.2,0.3,0.4,0.4,0.6c0.1-0.4,0.6-0.3,0.6,0.1c-0.1,0-0.4-0.1-0.5-0.1c0.3,0.4,0.8,0.4,1.3,0.5
        c0,0.1,0,0.3,0,0.4c0.1-0.1,0.2-0.2,0.3-0.2c0.2,0.3,0.5,0.6,0.8,0.9c0,0,0.1-0.1,0.2-0.1c0.2,0.1,0.4,0.2,0.5,0.3
        c-0.1,0-0.3,0.1-0.4,0.1c0,0.1,0.1,0.2,0.1,0.3c0.1,0,0.2-0.1,0.2-0.2c0.3,0.2,0.5,0.3,0.8,0.5c0,0.1-0.1,0.3-0.1,0.5
        c0.2,0,0.5,0,0.7,0c0,0.2,0,0.4-0.1,0.7c0.1,0,0.2,0.1,0.3,0.1c0-0.2-0.1-0.4-0.1-0.6c0.3,0,0.4,0.1,0.4,0.4c0.1,0,0.4,0,0.5-0.1
        c0,0.2-0.2,0.3-0.3,0.4c0.2,0,0.4-0.2,0.5-0.3c0,0.3-0.1,0.5-0.1,0.8c0.1-0.1,0.2-0.2,0.4-0.4c0.4,0.3,0.9,0.5,1.1,1
        c0.1,0.2,0.2,0.2,0.4,0.2c0,0.1,0.1,0.2,0.1,0.3c0.2,0,0.5,0,0.7,0.1c-0.1,0.2-0.1,0.3-0.2,0.5c0.2-0.1,0.4-0.2,0.6-0.3
        c0,0,0.1,0.1,0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0,0.4,0,0.5,0c0,0.1,0,0.3,0,0.4c0.2,0,0.3,0,0.5-0.1c0.3,0.2,0.4,0.4,0.2,0.7
        c0.1,0,0.2,0,0.3,0c0.1-0.1,0.2-0.2,0.4-0.2c0,0.2,0.1,0.5,0.2,0.7c0.1-0.1,0.2-0.2,0.2-0.2c0.1,0.1,0.2,0.2,0.3,0.4
        c0,0,0.1,0,0.2,0c0.1,0.1,0.3,0.2,0.4,0.3c0,0.1,0,0.2,0,0.2c0.1,0,0.3,0,0.4,0c-0.1,0.4,0.3,0.4,0.4,0.6c0.3,0.2,0.5,0.4,0.7,0.7
        c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0.3,0,0.7,0.3,0.8c0.1-0.3,0.5-0.4,0.4,0c0.2,0.1,0.3,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.4
        c0.5-0.1,0.6,0.4,1,0.5c0.1,0.5,0.9,0.5,0.8,1.1c0-0.1,0.1-0.2,0.2-0.3c0.1,0.3,0.3,0.6,0.7,0.6c0.5,0,0,0.5,0.3,0.7l0.1-0.2
        c0.2,0.1,0.3,0.2,0.5,0.2c0,0.1,0,0.2-0.1,0.2c0.4,0.1,0.6,0.4,1,0.4c0,0.2,0,0.3,0.1,0.5c0.2,0.2,0.7,0,0.7,0.4
        c0.3,0,0.2,0.3,0.2,0.6c0.2-0.2,0.5-0.1,0.6,0.1c0.1,0,0.2-0.1,0.2-0.1c0.2,0.4,0.9,0.4,0.9,0.9c0.3,0.2,0.7,0.2,0.9,0.6
        c-0.1,0-0.3,0.1-0.4,0.1c0.2,0.1,0.3,0.3,0.5,0.4c0-0.2,0-0.4,0.2-0.6c0.1,0.4,0.4,0.5,0.7,0.7c0.3-0.3,0.3,0.3,0.4,0.5
        c0.3-0.1,0.5,0.2,0.6,0.4c0.2,0.2,0.4,0.4,0.5,0.7c0.1,0,0.3-0.1,0.4-0.1c0,0.3,0.3,0.5,0.4,0.8c0.2-0.1,0.3-0.1,0.5-0.2
        c-0.1,0.3,0,0.5,0.2,0.7c0.2-0.4,0.4,0,0.6,0.2c0.2,0.1,0.3,0.2,0.4,0.4c0.2,0.1,0.2,0.2,0.2,0.4c0.2,0,0.4,0.1,0.4,0.4
        c0.2,0,0.5,0,0.7,0c-0.1,0.2-0.2,0.3-0.4,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0-0.1,0-0.3,0-0.4c0.2-0.1,0.4,0,0.6,0.1
        c0,0.1-0.1,0.2-0.1,0.2c0.1,0,0.3,0,0.4,0c0,0.1,0,0.3,0,0.4c0.1,0,0.3-0.1,0.4-0.2c0.3,0.3,0.6,0.5,0.8,0.9c0.1,0,0.2,0.1,0.3,0.1
        c0.1,0.2,0.2,0.3,0.3,0.5c-0.1,0.1-0.2,0.2-0.2,0.3l0.2,0c-0.1-0.3,0.3-0.3,0.3-0.1c0.1,0,0.2-0.1,0.3-0.2c0,0.1,0.1,0.2,0.1,0.3
        c-0.1,0-0.2,0-0.3,0l0,0.1c0.4,0.1,0.7,0.3,1.1,0.3c-0.1,0.1-0.1,0.3-0.2,0.5c0.1-0.1,0.3-0.2,0.4-0.3c0,0.1,0,0.3,0,0.5
        c0.1,0,0.3,0,0.4,0c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0,0.2-0.1,0.3-0.1c0.2,0.2,0.2,0.5,0.4,0.7c0.2-0.1,0.3-0.1,0.5-0.2
        c0,0.2,0,0.5,0,0.7c0.2,0.1,0.7-0.2,0.7,0.2c0,0.1-0.1,0.2-0.1,0.3c0.2,0.2,0.4,0.1,0.7,0.1c-0.1-0.2-0.2-0.4-0.3-0.5
        c0.5,0.2,0.6,0.8,1.1,0.9c0,0.1,0,0.2,0,0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.2,0.2,0.3,0.4,0.4,0.6c0.2,0.1,0.4,0.2,0.7,0.2
        c0,0.2,0,0.4,0,0.5l0.2,0l0.1-0.3c0.2,0.2,0.5,0.3,0.6,0.6c0,0.2,0.3,0.3,0.5,0.2c0.1,0.3,0.2,0.8,0.6,0.9c-0.1-0.2-0.4-0.4-0.3-0.6
        c0.2,0,0.5,0.1,0.7,0.2c-0.1,0-0.3,0-0.4,0c0.1,0.4,0.5,0.1,0.8,0.4c0.1,0.1,0.4,0.2,0.3,0.4c-0.2,0.5-0.3-0.2-0.6-0.2
        c0,0.1,0.1,0.3,0.1,0.4c0.2,0,0.3,0.1,0.5,0.1c0.1-0.1,0.3-0.3,0.4-0.4c-0.3,0.3,0,0.4,0.2,0.6c0.2,0,0.4,0,0.6,0
        c0,0.2,0.1,0.3,0.1,0.5c-0.4-0.1-0.4,0.2-0.1,0.3c0.1-0.4,0.5-0.1,0.7-0.1l0,0.2c0.1,0,0.2-0.1,0.3-0.1c-0.1,0.4,0.2,0.2,0.3,0.1
        c-0.1,0.3,0.2,0.5,0.4,0.8c0-0.3-0.1-0.6-0.3-0.7c0-0.2,0-0.4,0.1-0.6c-0.1-0.1-0.2-0.3-0.3-0.4c0.1-0.4-0.3-0.7-0.1-1.1
        c-0.1,0-0.2-0.1-0.2-0.2c0-0.6-0.6-0.9-0.9-1.4c-0.3-0.1-0.6-0.2-0.9-0.1c0,0,0-0.1,0-0.2c-0.4,0-0.5-0.3-0.8-0.5
        c-0.1-0.3-0.4-0.3-0.6-0.2c-0.2-0.2-0.4-0.3-0.7-0.4c-0.1-0.1-0.3-0.4-0.4,0c-0.2-0.1-0.4-0.2-0.7-0.2l0.1-0.2c0.1,0,0.3,0,0.4-0.1
        c-0.2-0.5-0.6,0.1-0.9,0.1c0.1-0.2,0.2-0.3,0.4-0.3c-0.1,0-0.3-0.1-0.3-0.2l0,0.3l-0.2-0.1c0.1-0.1,0.2-0.3,0.2-0.4
        c-0.3,0.1-0.6,0.3-0.9,0.1c0.3,0,0.5-0.1,0.5-0.5c-0.2,0.1-0.5,0.4-0.6,0c-0.2-0.6-0.8-0.5-1.2-0.8c0,0.1,0.1,0.2,0.1,0.3
        c-0.3,0-0.3-0.2-0.2-0.5c-0.2-0.2-0.4-0.3-0.7-0.4c-0.1-0.1-0.3-0.5-0.5-0.3c0,0.1,0.1,0.3,0.2,0.4c-0.4-0.1-0.3-0.4-0.4-0.7
        c-0.1,0.3-0.2,0.3-0.4,0.1c0-0.2,0-0.4-0.1-0.5c-0.2,0.1-0.4,0.2-0.6,0.3c0-0.1,0-0.2,0-0.3c0,0-0.1,0.1-0.2,0.1
        c0-0.1-0.1-0.3-0.2-0.4c0.1,0.1,0.4,0.2,0.5,0.2c0-0.3-0.3-0.3-0.5-0.4c0-0.1,0.1-0.3,0.1-0.4c-0.1,0-0.2,0.1-0.3,0.1
        c0,0.1,0,0.4,0,0.5c-0.3-0.1-0.1-0.6-0.1-0.9c-0.2,0-0.5,0.1-0.6-0.2c-0.4-0.2-0.7-0.2-1.1-0.2c0-0.2,0-0.3-0.1-0.4c0,0,0.1,0,0.1,0
        c0,0.1,0.1,0.2,0.1,0.3l0.3,0.1c-0.1-0.4-0.4-0.9-0.8-0.9c-0.1-0.2-0.2-0.3-0.3-0.5c-0.4,0-0.1,0.3-0.1,0.5c-0.1,0-0.2-0.1-0.2-0.2
        c0-0.1,0-0.3,0-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c0-0.3-0.1-0.5-0.4-0.5c0.1,0.2,0.2,0.4,0.3,0.6c-0.3,0-0.5-0.3-0.5-0.6
        c-0.1,0-0.2,0.1-0.3,0.1l0-0.2c-0.1,0-0.2,0-0.3,0.1c0-0.1,0.1-0.2,0.1-0.2c-0.2,0-0.4-0.1-0.4-0.3c0.2,0.1,0.4,0.2,0.6,0.3
        c-0.1-0.2-0.2-0.5-0.4-0.7c-0.1,0-0.2,0.1-0.3,0.1c-0.1-0.3-0.3-0.5-0.6-0.6c0,0.1,0.1,0.3,0.1,0.4c-0.2-0.1-0.4-0.2-0.5-0.2
        c0.2-0.1,0.5-0.1,0.6-0.4c-0.2,0-0.4-0.1-0.6-0.2c0-0.1,0-0.2-0.1-0.3c-0.1,0-0.3,0-0.4-0.1c-0.2-0.1-0.3-0.4-0.6-0.3
        c0-0.1,0.1-0.2,0.1-0.3c-0.2,0.1-0.5,0.1-0.7,0c0.1,0,0.4-0.1,0.5-0.1c-0.2-0.2-0.4-0.3-0.5-0.5c0,0.1,0,0.4,0,0.6
        c-0.2-0.2-0.2-0.4-0.2-0.6c-0.1,0-0.2,0-0.3,0c0-0.3-0.1-0.6-0.5-0.6l0-0.2c-0.1,0-0.2,0-0.2,0c-0.1-0.5-0.7-0.5-0.8-1
        c-0.1,0-0.2,0.1-0.3,0.1c0-0.1,0-0.3-0.1-0.3c-0.2-0.1-0.4-0.2-0.5-0.4c-0.3,0-0.5,0.1-0.8,0.1l0-0.1c0.1,0,0.3-0.1,0.4-0.1
        c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1,0-0.3,0.1-0.4,0.1c0-0.1,0-0.3,0-0.4c-0.1,0-0.3-0.1-0.4-0.1c0.1-0.2-0.1-0.6-0.3-0.4
        c0,0.1,0.1,0.3,0.2,0.4c-0.3,0-0.5-0.2-0.7-0.3c0-0.1,0.1-0.2,0.1-0.3c0.1,0.2,0.5,0.4,0.4,0c-0.2-0.3-0.5-0.6-0.9-0.5
        c-0.2,0.2,0.4,0.5,0,0.5c0-0.1-0.1-0.3-0.2-0.3c0.3-0.2,0-0.4-0.1-0.5c-0.4,0-0.8-0.1-0.7-0.6c-0.2,0-0.4,0-0.6,0
        c0.1-0.3,0-0.6-0.2-0.8c-0.1,0-0.3,0.1-0.4,0.1c0-0.1,0-0.3,0-0.4c-0.1,0-0.2,0.1-0.3,0.1c-0.2-0.4-0.4-0.7-0.7-1.1
        c-0.3,0.1-0.5-0.1-0.6-0.4c-0.1,0-0.3-0.1-0.4-0.1c0-0.2-0.1-0.3-0.3-0.4c0-0.1,0.1-0.2,0.2-0.3c-0.2,0-0.4-0.1-0.6-0.1
        c-0.4,0-0.1-0.3,0-0.5c-0.1,0-0.3,0-0.5,0c-0.1-0.4-0.6-0.4-0.8-0.6c0-0.1,0.1-0.2,0.2-0.3c-0.1,0-0.3,0-0.3,0
        c0,0.2,0.1,0.5,0.1,0.7c-0.3-0.3-0.3-0.9-0.8-0.9c0.1,0,0.2-0.2,0.2-0.2c-0.1-0.1-0.3-0.2-0.4-0.3c0-0.3-0.3-0.3-0.5-0.4
        c0.1,0.2,0.2,0.3,0.4,0.4l-0.1,0.1c0.4-0.3,0.3,0.4,0,0.2c-0.3-0.1-0.3-0.5-0.4-0.8c-0.3-0.2-0.4-0.9-0.9-0.6c0-0.1,0-0.3,0.1-0.4
        c-0.4,0.1-0.6-0.5-0.7-0.8c-0.1,0-0.2,0.1-0.2,0.1c-0.2-0.1-0.4-0.3-0.5-0.4c0.5-0.3-0.8-1-0.3-0.2c-0.1,0-0.2-0.1-0.2-0.2
        c0,0-0.1,0-0.1,0c-0.1-0.2-0.5-0.3-0.4-0.6c0.1,0.1,0.4,0.2,0.6,0.3c-0.1-0.6-0.7-0.3-0.8-0.7c-0.2,0.1-0.4,0.2-0.6,0.1
        c0-0.1-0.1-0.3-0.1-0.3c0.2,0.1,0.4,0.1,0.6,0.2l0-0.3c-0.4,0.1-0.6-0.2-0.9-0.3c-0.1,0-0.2-0.1-0.3-0.2c0.1-0.4-0.2-0.3-0.5-0.3
        c0-0.1,0.1-0.2,0.1-0.2c-0.1-0.1-0.2-0.1-0.3-0.2l0.1,0.3c-0.3-0.1-0.2-0.4-0.2-0.7c-0.4-0.4-1.2-0.1-1.3-0.7c0.2,0,0.7,0.4,0.7,0
        c-0.5-0.1-0.9-0.3-1.4-0.3c-0.1-0.1-0.2-0.2-0.4-0.3c0-0.1,0-0.3,0.1-0.4c-0.2,0-0.4-0.1-0.7-0.3c0-0.1,0.1-0.3,0.1-0.4
        c-0.1,0-0.2,0-0.3,0.1l0-0.2c-0.1,0-0.3-0.1-0.3-0.2c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2,0.2-0.4-0.1-0.5-0.2c-0.2-0.2-0.7-0.3-0.7-0.7
        c-0.3-0.1-0.5-0.2-0.8-0.3c0.2-0.4-0.3-0.5-0.5-0.6c-0.2,0.3-0.4,0-0.5-0.2c-0.1,0-0.2,0.1-0.2,0.1l0-0.2c-0.1,0-0.3,0-0.4,0.1
        c0.1-0.1,0.2-0.2,0.3-0.3c-0.1,0-0.2,0-0.2-0.1c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1,0.1-0.4,0-0.4,0.2c0.6,0.2,0,0.2-0.2,0.2
        c0-0.1,0-0.3-0.1-0.4c-0.1,0-0.2-0.1-0.3-0.1c0-0.1,0.1-0.3,0.1-0.4l-0.3,0c0,0.1,0.1,0.3,0.1,0.4c-0.2,0-0.3-0.2-0.2-0.4
        c-0.2-0.2-0.4-0.4-0.6-0.6c-0.1,0-0.2,0.1-0.2,0.1c-0.2-0.2-0.3-0.3-0.6-0.1c0-0.1,0-0.2,0-0.3c-0.1,0-0.3,0.1-0.4,0.1
        c0-0.1,0-0.2,0-0.2c0.1-0.1,0.3-0.1,0.5-0.1c0.1,0.2,0.3,0.3,0.5,0.3c-0.2-0.2-0.5-0.8-0.8-0.4c-0.1,0-0.3,0-0.4,0
        c0-0.1-0.1-0.2-0.1-0.3c-0.2,0-0.3,0.1-0.5,0.1c0.1-0.2,0.3-0.3,0.5-0.4c-0.3,0-0.6,0-0.8,0.1c0.1-0.5-0.5-0.3-0.8-0.3
        c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.4,0,0.5,0.1c-0.1-0.4-0.6-0.4-0.9-0.6c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1,0.1-0.2,0.1-0.3,0.2
        c0-0.1-0.1-0.3-0.1-0.4c-0.3,0-0.5-0.3-0.7-0.5c-0.2-0.1-0.3-0.1-0.5-0.2c0.1-0.1,0.2-0.2,0.3-0.3c-0.1,0-0.3-0.1-0.3-0.1
        c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.1-0.3-0.2-0.4-0.2c-0.4-0.1-0.8-0.3-1-0.6c0.1,0,0.2,0,0.3,0c0,0,0-0.1,0-0.2
        c-0.4,0.1-0.9-0.1-1.3-0.3c-0.4-0.2-0.8-0.2-1.2-0.4c-0.5,0.1-0.5-0.4-0.7-0.6c-0.1,0.1-0.3,0.2-0.4,0c-0.1,0-0.2,0-0.3,0
        c0-0.1,0.1-0.3,0.1-0.4c-0.2,0-0.3,0.1-0.5,0.1c-0.2-0.1-0.4-0.2-0.6-0.2c0.1-0.1,0.1-0.2,0.2-0.3c-0.1-0.1-0.2-0.2-0.4-0.3
        c-0.1-0.1-0.3-0.1-0.4-0.2c0,0.2,0.1,0.3,0.1,0.5c-0.3-0.1-0.3-0.4-0.3-0.6c0,0.1-0.1,0.2-0.1,0.3c-0.1-0.2-0.2-0.5-0.3-0.7
        c-0.2,0-0.5-0.1-0.5,0.2c-0.2-0.2-0.6-0.3-0.9-0.5c-0.1-0.2-0.3-0.3-0.3-0.5c-0.2,0.3-0.5,0.1-0.7,0c-0.3-0.3-0.5-0.6-0.9-0.5l0-0.2
        c-0.4,0.3-0.9,0.1-1.2-0.3c-0.3,0-0.5,0.1-0.4-0.3c-0.1,0.2-0.3,0.3-0.5,0.3c0.1-0.1,0.2-0.3,0.3-0.4c-0.1,0-0.3,0.1-0.4,0.1
        c-0.1-0.1-0.2-0.2-0.3-0.4c-0.1,0.5-0.4-0.1-0.7,0.1c-0.2-0.3-0.6-0.4-0.9-0.6c-0.1,0-0.2,0.1-0.3,0.2c0-0.1,0-0.3,0-0.3
        c-0.1,0.1-0.2,0.2-0.4,0.2c-0.4-0.3-0.9-0.6-1.4-0.5c0-0.1,0-0.2-0.1-0.3c-0.2,0-0.3,0.1-0.5,0.1c0-0.1,0.1-0.3,0.1-0.3
        c-0.2,0.1-0.3,0.1-0.5,0.2c0-0.1,0.1-0.3,0.2-0.4c-0.2,0.1-0.4,0.3-0.7,0.4c0.1-0.1,0.2-0.3,0.2-0.4c-0.3-0.1-0.7-0.1-0.6-0.5
        l-0.1,0c0,0.1,0,0.3,0,0.5c-0.3-0.2-0.6-0.4-0.9-0.5c-0.2,0-0.5,0-0.7,0c0.1-0.1,0.1-0.3,0.2-0.4c-0.2,0.1-0.3,0.1-0.5,0.2l-0.1-0.3
        c0.1,0,0.2-0.1,0.3-0.2c-0.3-0.1-0.6,0.3-0.9,0.1c0-0.1,0-0.2,0-0.3c-0.1,0.1-0.2,0.1-0.3,0.2c-0.3-0.1-0.5-0.2-0.6-0.5
        c-0.1,0.1-0.2,0.2-0.3,0.4c-0.1-0.2-0.3-0.2-0.4-0.2c-0.2-0.2-0.5-0.5-0.9-0.4c-0.3,0-0.6,0-0.8-0.2c-0.1,0-0.2,0.1-0.3,0.1
        c-0.1,0-0.2-0.1-0.2-0.2c-0.1,0-0.3,0-0.5,0c0-0.1,0.1-0.3,0.1-0.4c-0.2,0-0.3,0.2-0.4,0.4c-0.2-0.1-0.5-0.1-0.7-0.3
        c-0.3,0.2-0.6-0.2-0.9-0.1c-0.4,0-0.7-0.4-1.1-0.2c0-0.1-0.1-0.2-0.2-0.3c-0.3,0-0.5,0-0.8,0c-0.1-0.3-0.2-0.3-0.5-0.2l0-0.2
        c-0.1,0.1-0.3,0.4-0.5,0.2c-0.2-0.1-0.4-0.2-0.5-0.3c-0.2,0.2-0.5,0.2-0.8,0.2l-0.1-0.2c0.1,0,0.2,0,0.3,0l-0.1-0.3
        c-0.2,0.2-0.4,0.3-0.6,0.5c-0.2-0.3-0.4-0.4-0.7-0.5c0,0.1-0.1,0.2-0.1,0.3c-0.4,0-0.8-0.3-1.1-0.2c-0.3,0.2-0.6-0.2-1-0.2
        c-0.2,0.1-0.3,0.2-0.5,0.2c0-0.1-0.1-0.2-0.2-0.3c-0.3,0.1-0.7,0-1,0c-0.1,0.1-0.3,0.1-0.4,0c-0.2-0.1-0.5,0.1-0.7,0.1
        c-0.2-0.1-0.5-0.4-0.6,0c-0.2,0-0.3,0-0.5,0.1c-0.1-0.1-0.2-0.2-0.2-0.4c-0.1,0.1-0.2,0.2-0.2,0.3c-0.3-0.1-0.7-0.3-1-0.1
        c-0.2,0.1-0.5,0-0.7,0c-0.4,0.1-0.9,0-1.3,0.2c-0.8,0-1.6,0.3-2.4,0.2c-0.2-0.1-0.3,0.1-0.5,0.2c-0.3,0-0.5-0.1-0.8,0
        c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.2-0.1-0.2-0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.7,0.1-1.4,0.1-2,0.3c-0.2,0.1-0.5,0-0.7,0.1
        c-0.3,0.2-0.7,0.3-1.1,0.3c-0.4,0.3-0.9,0.1-1.3,0.3c-0.2,0.1-0.4,0.1-0.7,0c-0.3,0.2-0.7,0.3-1,0.2c-0.5,0.3-1.2,0.4-1.7,0.6
        c-0.5,0.2-1,0.6-1.5,0.5c-0.4,0.1-0.8,0.4-1.1,0.1c-0.1,0.2-0.3,0.2-0.5,0.3c-0.4-0.1-0.7,0.1-1,0.2c-1.1,0.4-2.3,0.7-3.4,1.2
        c-1,0.2-1.8,1-2.8,1c-0.1,0.1-0.2,0.1-0.4,0.2c-0.1,0-0.2,0-0.3-0.1c-0.8,0.6-1.9,0.8-2.7,1.4c0,0.2-0.2,0.3-0.4,0.4
        c0,0.2-0.1,0.4-0.3,0.3c-0.3-0.1-0.5,0.2-0.6,0.4l-0.1,0l0,0.2c-0.1,0-0.3-0.1-0.4-0.1c0,0.1,0,0.2,0,0.3l-0.2,0l0.1-0.3
        c-0.4,0-0.2,0.5-0.4,0.7c-0.1-0.1-0.2-0.2-0.3-0.3c0,0.2-0.1,0.3-0.1,0.5c-0.1,0-0.3,0-0.4,0c0,0,0,0.1,0,0.2
        c-0.2,0-0.3,0.1-0.5,0.1c0,0.1,0.1,0.2,0.1,0.3c-0.4-0.1-0.5,0.2-0.6,0.5c-0.1,0-0.3,0-0.4,0c-0.1,0.1-0.2,0.3-0.3,0.4
        c0.1,0,0.2,0.1,0.3,0.2c-0.3,0-0.6-0.1-0.8,0.2c0.1,0,0.4,0,0.5,0.1c-0.2,0-0.3,0-0.5,0c-0.2,0.3-0.4,0.5-0.7,0.8
        c-0.2,0.2-0.3,0.4-0.5,0.5l0,0.1c-0.1,0-0.2,0-0.3,0c0,0.1,0,0.2,0,0.2c-0.3,0.1-0.7,0.5-0.2,0.6c-0.2,0-0.4,0-0.6,0
        c0.1,0.3-0.1,0.4-0.3,0.3c0.1,0.5-0.5,0.8-0.8,1c0,0.1,0,0.3-0.1,0.4c0-0.1-0.1-0.2-0.2-0.3l0.1,0.3l-0.2,0c0.1,0.4-0.3,0.3-0.5,0.4
        c0.1,0.1,0.3,0.1,0.3,0.3c-0.3,0.1-0.5,0.4-0.8,0.6c-0.2,0.1-0.2,0.2-0.2,0.4c-0.1,0-0.3-0.1-0.5-0.1l0,0c0.1,0.1,0.3,0.2,0.4,0.3
        c-0.2,0-0.4,0-0.6,0c0.1,0.1,0.2,0.3,0.3,0.4c-0.1,0-0.3-0.1-0.4-0.1c0,0.1,0,0.2,0.1,0.3c-0.3,0-0.6,0.2-0.4,0.5l-0.1,0
        c0,0-0.1,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.5,0.6l0,0.1l-0.1-0.1c-0.1,0.2-0.2,0.3-0.3,0.5l0,0.1c-0.2,0.1-0.2,0.3-0.1,0.5l-0.2-0.1
        l0,0.3c-0.1,0-0.2-0.1-0.3-0.1c0.1,0.2,0.1,0.3-0.2,0.3c0,0.1,0.1,0.2,0.1,0.3l-0.3-0.1c0,0.1,0.1,0.2,0.1,0.3
        c-0.3,0-0.3,0.4-0.5,0.5c-0.2,0.2-0.2,0.6-0.5,0.7c0,0.1,0,0.2,0,0.3c-0.4-0.1-0.2,0.5-0.6,0.5c0,0.1,0,0.3,0,0.4
        c-0.1,0-0.2,0.1-0.3,0.1c0,0.1,0,0.3-0.1,0.3c0,0.4-0.4,0.5-0.4,0.9l-0.2-0.1c0,0.1,0,0.3,0,0.4c-0.1,0.1-0.3,0.2-0.4,0.3
        c0,0.1,0,0.3-0.1,0.3c-0.2,0.2-0.3,0.5-0.5,0.8c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.2-0.2,0.5-0.4,0.5c0,0.2-0.3,0.5,0.1,0.5
        c-0.3,0.1-0.5,0.3-0.6,0.6c-0.2,0.4-0.2,0.9-0.6,1.2c0,0.2,0,0.5-0.2,0.6c0-0.4,0.2-0.7,0.4-1c0-0.3,0.1-0.5,0.2-0.7
        c0-0.2,0.1-0.3,0.1-0.5c0.1,0,0.2-0.1,0.3-0.2c-0.2-0.5,0.4-0.8,0.4-1.2c0.3,0,0.3-0.3,0.3-0.5c0.2-0.2,0.4-0.3,0.2-0.6
        c0.1-0.1,0.2-0.2,0.3-0.3c-0.1-0.3,0.2-0.4,0.3-0.5c-0.1-0.5,0.5-0.9,0.6-1.3c0-0.1,0.1-0.2,0.2-0.3c0-0.4,0.4-0.6,0.6-0.9
        c0-0.1,0-0.2,0-0.3c0.6-0.4,0.7-1.2,1.1-1.7c0.1-0.2,0.3-0.5,0.4-0.7c0.1,0,0.2,0.1,0.2,0.1c0-0.1-0.1-0.3-0.1-0.4
        c0-0.1,0.1-0.2,0.2-0.3l0.2,0.1c0-0.1-0.1-0.2-0.1-0.3c0.2-0.1,0.4-0.2,0.4-0.4c0.1-0.3,0.3-0.4,0.5-0.6c0.4-0.6,0.8-1.1,1.2-1.7
        c0.2-0.2,0.3-0.3,0.5-0.5c0.2-0.3,0.5-0.6,0.7-1c0.3-0.4,0.6-0.8,0.9-1.3c0.2-0.3,0.4-0.5,0.7-0.7c0-0.1,0.1-0.3,0.3-0.3
        c0.5-0.5,0.9-1,1.3-1.5l0.1,0.1c0.2-0.3,0.3-0.7,0.7-0.8c0-0.1,0-0.2,0-0.2c0.9-0.4,1.4-1.2,2.2-1.8c0.4-0.4,0.7-0.9,1.3-1.1
        c0.8-0.3,1.1-1.3,1.9-1.7c0.3-0.3,0.7-0.4,0.9-0.8c0.2-0.1,0.3-0.2,0.4-0.4c0.1,0,0.3,0,0.3,0c0.5-0.6,1.2-1,1.8-1.5
        c0.3,0,0.7-0.1,0.8-0.4c0.2-0.4,0.7-0.5,1-0.9c0.1-0.2,0.3-0.2,0.5-0.2c0.2-0.2,0.5-0.3,0.6-0.6c0.1,0,0.2-0.1,0.2-0.1
        c0.1-0.4,0.5-0.3,0.8-0.3c0-0.4,0.4-0.5,0.7-0.6c0-0.1,0.1-0.2,0.2-0.3c0.4,0,0.7,0,0.8-0.4c0.4-0.1,0.8-0.2,1.1-0.5
        c0-0.1,0.1-0.2,0.2-0.3c0.2,0,0.4-0.1,0.6-0.1c0.3-0.2,0.5-0.5,0.8-0.7c0.3-0.1,0.7-0.2,1-0.5c0.5-0.5,1.3-0.6,1.9-0.7
        c-0.1-0.1-0.2-0.2-0.3-0.2c1.3-0.6,2.6-1,3.8-1.7c0.2-0.2,0.6-0.2,0.8-0.3c0.3-0.2,0.8-0.3,1.1-0.4c0.4-0.1,0.7-0.4,1.1-0.3
        c0.1-0.5,0.8-0.3,1.2-0.4c0-0.5,0.6-0.4,0.8-0.7l0,0.2l-0.2,0.1c0.2,0.5,0.7-0.1,1-0.2c0.6-0.1,1.1-0.5,1.7-0.2
        c0.3-0.1,0.5-0.3,0.6-0.6c0.2,0.3,0.6,0.2,0.9,0.1c0.5-0.1,1-0.5,1.4-0.1c0.2-0.2,0.4-0.3,0.7-0.2c0.1,0,0.2-0.1,0.3-0.2
        c0.6,0.1,1.2,0,1.6-0.4c0.2,0.1,0.5,0.1,0.8,0.1c0.1,0,0.2-0.1,0.2-0.2c0.5,0.3,1.2,0.2,1.7-0.1c0.3,0,0.7,0,1-0.1
        c0.3-0.1,0.7,0.2,1,0c0.2-0.1,0.3,0.1,0.5,0.1c0.6-0.2,1.2-0.3,1.8-0.3c0.1,0,0.2-0.1,0.2-0.2c0.4,0.2,0.9,0,1.3,0c1,0,2-0.3,3,0
        c0.2-0.1,0.3-0.1,0.5-0.2c0,0.1,0,0.3,0,0.3c0.4-0.3,0.8-0.2,1.2-0.3c0.2,0.1,0.4,0.2,0.6,0.3c0.3-0.3,0.7-0.3,1.2-0.2
        c0.5,0.2,1.1,0,1.6,0c0.2,0.1,0.4,0.2,0.6,0.3c0.2-0.1,0.4-0.1,0.6-0.2c0.2,0.2,0.3,0.3,0.5,0.5c0.2-0.1,0.3-0.3,0.6-0.3
        c0.2,0,0.3,0.1,0.5,0.2l-0.1,0.3c0.1-0.1,0.3-0.2,0.4-0.3c0.9-0.1,1.7,0.2,2.5,0.3c0.6,0.1,1.2,0.2,1.8,0.5c0.3,0,0.6,0,0.9,0
        c0.4,0.4,0.9,0,1.4,0.2c0.4,0.2,0.9,0.2,1.4,0.3c0.3,0.1,0.5,0.1,0.8,0.1c0.2,0.1,0.4,0.2,0.6,0.3c0.5-0.4,1,0.2,1.6,0.1
        c0.3-0.1,0.6,0.1,1,0.2c0.8,0.1,1.6,0.3,2.3,0.7c1-0.2,1.7,0.9,2.7,0.6c0.3,0.4,0.8,0.3,1.1,0.5c0.5,0.3,1.2,0.1,1.6,0.5
        c0.3,0.1,0.6,0.2,0.9,0.3c0.2-0.1,0.5-0.1,0.7,0.1c0.2,0.3,0.6,0.1,0.8,0.3c0.8,0.5,2.1,0.1,2.6,1.1c0.4,0,0.7,0.1,1,0.4
        c0.6,0.1,1.3,0.1,1.7,0.6c0.2,0.2,0.4,0.1,0.7,0.2c0.6,0.3,1.3,0.3,1.8,0.8c0.5,0,0.9,0.3,1.2,0.7c0.5-0.3,0.9,0.1,1.2,0.5
        c0.3,0,0.6-0.2,0.9,0c0.4,0.3,1,0.5,1.4,0.8c0.5,0.4,1.4,0.3,1.6,1.1c0.3-0.2,0.7-0.1,0.9,0.2c0.6,0.2,1.3,0.4,1.8,0.9
        c0.5,0,0.8,0.5,1.3,0.6c0.3,0.1,0.5,0.3,0.8,0.4c0.3,0.1,0.4,0.4,0.6,0.4c0.8,0.1,1.5,0.7,2.2,1c0.4,0.1,0.6,0.5,1.1,0.7
        c0.4,0.1,0.7,0.5,1.2,0.7c0.2,0.1,0.4,0.3,0.6,0.5c0.2,0.3,0.7,0.3,1,0.6c0.4,0.4,1,0.4,1.2,0.9c0.7,0.3,1.4,0.6,2.1,1.1
        c0.3,0.2,0.5,0.8,1,0.7c0.4,0,0.5,0.4,0.6,0.7c0.1,0,0.3,0,0.4,0c0.4,0.2,0.7,0.6,1.2,0.7c0.2,0.1,0.3,0.3,0.4,0.4
        c0.7,0.5,1.5,0.7,2.1,1.3c0.3,0.3,0.6,0.5,1,0.7c0.3,0.2,0.6,0.3,0.7,0.6c0.3,0.1,0.6,0.2,0.8,0.4c0.2,0.2,0.3,0.4,0.4,0.6
        c0.4,0.3,1,0.4,1.3,0.8c0.5,0.4,1,0.8,1.5,1.2c-0.1-0.7-0.7-1.1-1-1.7c-0.5-0.6-0.5-1.5-1.1-2c-0.3-0.2-0.2-0.7-0.5-1
        c-0.3-0.4-0.4-0.9-0.8-1.2c-0.4-0.2-0.1-0.8-0.5-1c-0.4-0.3-0.4-0.9-0.6-1.4c-0.1-0.1-0.3-0.3-0.4-0.4c0-0.5-0.5-0.7-0.6-1.2
        c-0.4-0.6-0.7-1.2-1.1-1.8c-0.2-0.2-0.1-0.5-0.2-0.8c-0.4-0.1-0.6-0.4-0.6-0.8c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.2-0.1-0.4-0.1-0.6
        c-0.4-0.2-0.6-0.7-0.8-1.2c-0.2-0.2-0.3-0.5-0.4-0.7c-0.2-0.4-0.5-0.7-0.6-1.1c-0.6-0.4-0.5-1.2-1.1-1.5c0-0.5-0.4-0.8-0.5-1.2
        c-0.1-0.4-0.5-0.4-0.7-0.7c-0.2-0.5-0.7-0.9-0.9-1.4c-0.4-0.5-0.7-1-1-1.6c-0.8-0.9-1.5-1.9-2.1-2.9c-0.4-0.4-0.6-0.9-1-1.3
        c-0.2-0.4-0.5-0.7-0.8-1c-0.1-0.2-0.4-0.2-0.6-0.4c-0.2-0.2-0.1-0.4-0.1-0.6c-0.3-0.1-0.6-0.3-0.9-0.5c-0.1-0.2-0.1-0.4-0.3-0.6
        c-0.2-0.1-0.5-0.1-0.5-0.4c-0.1-0.2-0.1-0.5-0.4-0.5c-0.4-0.6-1-1.3-1.7-1.6c0.3-0.5-0.2-0.6-0.6-0.7c-0.2-0.5-0.7-0.7-0.9-1.1
        c-0.2-0.5-0.9-0.5-1.1-1.1c-0.6-0.2-1.1-0.6-1.5-1c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.4-0.9-0.4-1.2-0.7c-0.2-0.2-0.4-0.5-0.7-0.5
        c-0.4,0-0.5-0.5-0.8-0.3c-0.4-0.4-0.9-0.3-1.4-0.6c-0.4-0.2-0.9-0.2-1.2-0.6c-0.3-0.1-0.7-0.2-1-0.3c-0.1,0-0.3,0-0.3,0
        c-0.2-0.3-0.5-0.2-0.8-0.3c-0.3-0.1-0.7-0.1-1-0.2c-0.2,0-0.4-0.3-0.6-0.1c-0.3-0.3-0.8-0.2-1.2-0.2c-0.2-0.1-0.4-0.2-0.5-0.3
        c-0.2,0.1-0.4,0.1-0.6,0c-0.5,0.2-1-0.1-1.5,0c-0.5,0-1,0.1-1.5,0c-0.4,0-0.7,0-1.1,0c-0.2,0.2-0.5-0.1-0.7-0.1c0,0,0,0.1,0,0.2
        c-0.8,0.3-1.8,0.1-2.6,0.4c-0.6,0.1-1.1,0.4-1.7,0.3c-0.1,0.2-0.2,0.3-0.5,0.2c0,0.2,0,0.3,0,0.5c0.2-0.1,0.4-0.1,0.3,0.3
        c0.1,0,0.2,0,0.3-0.1c0,0.1-0.1,0.2-0.1,0.3c0.1,0.1,0.2,0.3,0.2,0.4c0.1,0,0.2-0.1,0.3-0.1c0,0.1-0.1,0.2-0.1,0.4l0.2-0.1
        c0,0.1,0,0.3,0,0.4c0.4,0,0.4,0.4,0.6,0.7c0.3,0.5,0.6,1,1,1.4c-0.3-0.3-0.7-0.6-0.8-1c-0.1-0.1-0.2-0.2-0.3-0.3
        c-0.1-0.1-0.1-0.2-0.2-0.4c-0.5-0.4-0.9-0.9-1.2-1.4c-0.3-0.2-0.6-0.4-0.8-0.8c-0.2,0.1-0.4,0.2-0.6,0.2c-0.3,0.1-0.6,0.3-1,0.4
        c-0.4,0.2-0.8,0.4-1.2,0.6c-0.3,0.1-0.5,0.5-0.9,0.4l0,0.1c-0.4,0.2-0.7,0.6-1.1,0.6l0,0.1c-0.4,0.3-0.8,0.6-1.2,0.8
        c0.5-0.5,1.1-0.9,1.7-1.3c0.5-0.4,1-0.6,1.5-0.9c0-0.2,0.2-0.2,0.4-0.2c0-0.1,0-0.2,0-0.2c0.3,0,0.5-0.2,0.7-0.3
        c0.3,0,0.5-0.4,0.8-0.3l0-0.1c0.2,0,0.3,0,0.5,0c-0.1-0.2-0.2-0.4-0.3-0.6c-0.5-0.2-0.8-0.5-1.2-0.8c0-0.1-0.1-0.2-0.1-0.2
        c-0.3,0-0.4-0.2-0.5-0.5c-0.4,0-0.7-0.4-1-0.6c-0.3-0.3-0.7-0.4-1-0.6c-0.3-0.2-0.6-0.4-0.9-0.6c-0.6-0.3-1.2-0.7-1.8-0.9
        c0-0.1,0-0.2,0-0.3c-0.1,0-0.2,0.1-0.3,0.2c-0.6-0.3-1.2-0.6-1.9-0.8c0,0,0-0.1,0-0.2c-0.2,0.1-0.4-0.1-0.5-0.1
        c0,0.1-0.1,0.2-0.1,0.3c-0.2,0-0.4,0.2-0.5,0.4c-0.1,0-0.3,0-0.3,0c-0.1,0.2-0.2,0.4,0.1,0.5l0,0.1c-0.2-0.1-0.5-0.1-0.7-0.1
        c0,0.2,0.1,0.2,0.3,0.3c-0.3-0.1-0.4,0.2-0.6,0.3c-0.1,0-0.2-0.1-0.2-0.1c-0.1,0.1-0.2,0.3-0.3,0.4c-0.3-0.1-0.4,0.2-0.6,0.3
        c-0.2,0-0.3-0.1-0.5-0.1c-0.1,0.2-0.3,0.3-0.5,0.3c-0.2,0.3-0.3,0.6-0.7,0.5c-0.1,0.3-0.5,0.2-0.8,0.5c-0.3-0.2-0.4,0.1-0.7,0.2
        c0,0.1,0.1,0.2,0.2,0.3c-0.3,0.2-0.6,0.2-1,0.1c0,0.1,0,0.2,0,0.2c-0.3,0.1-0.5,0.2-0.7,0.4c0-0.1,0-0.2,0-0.3l-0.2,0
        c0,0.1,0,0.3,0,0.4c-0.2,0-0.4,0.1-0.6,0.1c-0.1,0.2-0.3,0.2-0.5,0.3c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0-0.4,0-0.5,0.1
        c-0.3,0.1-0.6,0.4-0.9,0.2c0,0.1,0,0.3,0,0.4c-0.1-0.1-0.2-0.3-0.2-0.4c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.2-0.4,0.2
        c0-0.1-0.1-0.2-0.1-0.2c-0.1,0.2-0.8,0.4-0.7,0c0,0.1-0.1,0.3-0.1,0.5c-0.1-0.1-0.2-0.2-0.3-0.2c0.1,0.2,0,0.3-0.3,0.3v0.2
        c-0.1,0-0.3,0-0.4-0.1c-0.2,0.2-0.4,0.2-0.6,0.2c0-0.3,0-0.4-0.2,0c-0.1,0-0.2-0.1-0.3-0.1l0.1,0.2c-0.4,0.1-0.9,0.1-1.2,0.4
        c-0.3-0.1-0.5,0-0.7,0.1c-0.3,0-0.7-0.1-0.9,0.1c-0.9,0.1-1.7,0.3-2.6,0.4c-0.4-0.2-0.7,0-1.1,0c-0.2,0-0.5,0-0.7,0
        c-0.3,0.5-0.8-0.2-1,0.2l0.1-0.3c-0.7,0.5-1.5,0-2.3-0.1c-0.5,0.1-0.9-0.2-1.3-0.2c-0.9-0.2-1.8-0.4-2.6-0.9
        c-0.6-0.1-1.1-0.5-1.6-0.8c-0.6-0.2-1-0.8-1.6-1c-0.1-0.1-0.1-0.2-0.2-0.3c0.6,0.4,1.2,0.7,1.7,1c0.2,0.2,0.5,0.2,0.8,0.2
        c0.2,0.4,0.6,0.3,1,0.4c0.1,0,0.3,0.1,0.4,0.1l0,0.2c0.1,0,0.3-0.1,0.4-0.1c0,0.4,0.2,0.2,0.3,0c0.3,0.1,0.6,0,0.9,0
        c0.4,0.2,1,0.1,1.5,0.1l0,0.2c0.4-0.1,0.8-0.2,1.2,0c0.1,0,0.2-0.1,0.3-0.1c0,0.1,0,0.3-0.1,0.4c0.2-0.5,0.7-0.3,1.1-0.3
        c0,0,0.1-0.1,0.2-0.2c0,0,0.1,0.1,0.2,0.2l0.3-0.2c0,0.2-0.2,0.4-0.3,0.6c0.4-0.1,0.5-0.5,0.8-0.7c0.1,0,0.3,0.1,0.4,0.1
        c0.1-0.2,0.3-0.2,0.5-0.2l0-0.1c0.2,0,0.5,0,0.7-0.1c0.3,0.3,0.6-0.5,0.8,0c0.2-0.1,0.5-0.5,0.6-0.1c0.1,0,0.2,0,0.3,0
        c0.1-0.1,0.2-0.2,0.3-0.3c0.3,0.2,0.8,0,0.8-0.4c0.2,0,0.4,0,0.6,0c0-0.1,0.1-0.2,0.2-0.3c0,0,0.1,0.1,0.2,0.1
        c0.1-0.1,0.3-0.4,0.4,0c0.3-0.2,0.5-0.5,0.8-0.7l0,0.3c0.1-0.1,0.2-0.3,0.3-0.4c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.4,0.1,0.5-0.1
        c0.2-0.3,0.6-0.3,0.8-0.5l0,0.2c0-0.2,0.1-0.4,0.4-0.4c0-0.1,0.1-0.2,0.1-0.2c0.2,0,0.4-0.2,0.6-0.3c0.2,0.2,0.5,0,0.7,0
        c0.2-0.4,0.6-0.8,1.1-0.8c0-0.1-0.1-0.2-0.1-0.3c0.1-0.1,0.3,0,0.4,0c0.1-0.2,0.1-0.5,0.3-0.7c-0.1,0-0.2,0-0.3,0
        c0.7-0.2,0.8-1,1.2-1.4l-0.1,0.3c0.2,0,0.3,0,0.5,0c0-0.3,0-0.5,0.1-0.8c0.5,0,0.7-0.4,0.9-0.8c0.1,0,0.2,0.1,0.3,0.1
        c0-0.1,0.1-0.2,0.2-0.3c0-0.3-0.2-0.7-0.6-0.4l0-0.3c-0.2,0.1-0.4,0.1-0.6,0.2c-0.2-0.2-0.5-0.2-0.7-0.4l0,0c0,0.1,0.1,0.2,0.2,0.3
        c-0.3,0.1-0.7-0.1-1-0.3c0,0.1,0,0.2,0,0.3c-0.4,0.1-0.8-0.2-1.1-0.3c-0.9,0.2-1.9,0.1-2.8,0.2c-0.6,0-1.2,0.1-1.8,0.1
        c-0.6-0.1-1.2,0.1-1.8,0.1c-0.2,0.1-0.3,0.1-0.5,0c-0.2,0.1-0.3,0.2-0.5,0.3l0-0.3c-0.1,0.1-0.2,0.1-0.3,0.2
        c-0.1-0.1-0.3-0.2-0.4-0.2c0,0.1,0,0.3,0,0.4c-0.1-0.1-0.2-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2c-0.2-0.1-0.4-0.3-0.6-0.1
        c-0.3,0.2-0.6,0.3-0.9,0.2c0,0.1-0.1,0.2-0.1,0.3c-0.1,0-0.3-0.1-0.4-0.1c-0.2,0.2-0.4,0.3-0.7,0.4c0-0.1,0-0.2,0-0.3
        c-0.4,0.1-0.8,0.4-1.3,0.4l0,0.2c-0.2-0.1-0.4-0.3-0.6-0.4c0.1,0.1,0.2,0.3,0.2,0.5c-0.2,0-0.4-0.1-0.6-0.1c-0.3,0.1-0.6,0.2-1,0.1
        c0,0.2-0.2,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1,0.2,0,0.4,0.2,0.6c-0.2-0.1-0.4,0.1-0.6,0.2c-0.1,1,0.1,2,0.1,3
        c0.1,0.2,0.1,0.4,0.1,0.6c0.1,0.2,0.2,0.4,0.1,0.6c0,0.1,0,0.2,0.1,0.3c-0.3-0.7-0.4-1.5-0.7-2.3c-0.3-0.6-0.3-1.3-0.5-1.9
        c-0.1-0.2-0.4-0.2-0.6-0.3c0.1,0.4-0.3,0.8-0.7,0.9c0.1-0.1,0.3-0.4,0.4-0.5c-0.2-0.2-0.4-0.3-0.5,0c-0.3,0.2-0.8,0-0.9,0.5
        c-0.1,0-0.3,0-0.4,0c0,0.1,0,0.3,0,0.4c-0.1-0.1-0.3-0.1-0.4-0.2c-0.2,0.1-0.3,0.3-0.5,0.3c-0.3-0.1-0.4,0.2-0.5,0.3
        c-0.1,0-0.3-0.1-0.4-0.1c0,0.1,0.1,0.3,0.1,0.4c-0.1,0-0.2,0-0.3,0c0,0.1,0,0.2,0,0.3c-0.1,0-0.4,0.1-0.5,0.1
        c0.2-0.2,0.4-0.4,0.4-0.7c-0.2,0-0.3,0.1-0.4,0.2c0,0.2,0,0.4,0,0.6c-0.1,0.2-0.5,0.6-0.5,0.1c0.3-0.1,0.3-0.4,0.4-0.6
        c-0.4-0.1-0.5,0.6-0.9,0.4c0.1,0.2,0.1,0.4,0,0.6l-0.2,0c0.1-0.2,0.2-0.5,0.2-0.7c-0.5,0.2-0.9,0.8-1.5,0.7
        c-0.1,0.1-0.3,0.3-0.3,0.4c0.1,0,0.3,0.1,0.4,0.1c-0.4,0.3-0.7-0.4-1.1-0.5c0.2,0.2,0.4,0.4,0.5,0.6c-0.2,0-0.4-0.1-0.5-0.2
        c-0.1,0-0.4,0.1-0.5,0.1c0.1,0.2,0.4,0.2,0.6,0.2c-0.2,0.2-0.5,0.2-0.7,0c-0.2,0.1-0.5,0.2-0.6,0.3c0.2,0,0.7,0.3,0.6-0.2
        c0.1,0.1,0.2,0.1,0.4,0.2c-0.4,0.1-0.7,0.4-1.1,0.3c0-0.1,0.1-0.3,0.2-0.4c-0.3,0-0.4,0.3-0.6,0.5c0-0.1,0-0.3,0-0.5
        c-0.2,0.1-0.3,0.3-0.3,0.5c-0.2-0.1-0.4-0.1-0.6-0.1c0,0.1,0.1,0.4,0.1,0.5c-0.3-0.1-0.4-0.3-0.6-0.4c0.4,0.4,0.1,1.1-0.4,1.1
        c0.2-0.2,0.3-0.3,0.5-0.5c-0.1,0-0.4-0.1-0.5-0.1c-0.2,0.2-0.8,0.4-0.3,0.6c-0.1,0.2-0.3,0.3-0.5,0.5c-0.2,0-0.1-0.1,0-0.3
        c0.1-0.3,0.4-0.5,0.1-0.8c-0.2,0.3-0.4,0.5-0.5,0.8c-0.2,0-0.3-0.1-0.4-0.1c-0.3,0-0.6,0.2-0.2,0.5c-0.3,0-0.6,0-0.9,0.1
        c0,0.5,0.6,0.2,0.9,0.1c0.1-0.2,0.3-0.4,0.5-0.4c0,0.3-0.3,0.4-0.5,0.6c-0.3,0-0.7,0-0.9-0.1c-0.1,0.1-0.2,0.2-0.3,0.3
        c-0.2,0-0.3-0.1-0.5-0.1c0.1,0.2,0.3,0.3,0.4,0.4c-0.2,0.1-0.5,0.3-0.6,0c0-0.1,0.1-0.2,0.2-0.3c-0.3-0.3-0.3,0.2-0.5,0.3
        c-0.1,0.1-0.4,0.4-0.6,0.5l0.2-0.5c-0.1,0-0.2,0-0.2,0c0-0.1,0.1-0.2,0.1-0.2c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1,0.2-0.1,0.4-0.2,0.6
        c-0.4,0.1-0.8,0-1.1,0.2c-0.2-0.2-0.4,0-0.5,0.2c0.1-0.3,0.1-0.5-0.2-0.6c0,0.3-0.2,0.5-0.4,0.7c-0.1,0.1-0.2,0.2-0.3,0.3
        c0-0.1-0.1-0.2-0.2-0.3c-0.3,0-0.5,0.1-0.7,0.3c0.1,0.1,0.1,0.3,0.2,0.5c-0.2,0.2-0.4,0.4-0.4,0.7c-0.1,0-0.2-0.1-0.2-0.2
        c-0.1,0.1-0.2,0.2-0.2,0.3c0.1,0,0.2,0.1,0.3,0.2l0.1,0.1l-0.3,0.2c-0.1-0.1-0.3-0.2-0.3-0.2c-0.2,0.5,0.4,0.4,0.7,0.2
        c0,0.1,0.1,0.3,0.1,0.3c-0.2,0-0.3,0-0.5,0c0.3,0.5-0.2-0.1-0.3,0.2c-0.1-0.1-0.2-0.1-0.4-0.2c0,0.2,0.4,0.7-0.1,0.4
        c0,0.1,0,0.2,0.1,0.3c-0.3,0.4-0.8,0.3-1.2,0.4c0,0.1,0,0.2,0,0.2c-0.2,0-0.4,0-0.6,0.1c0-0.1,0-0.2,0-0.3c-0.1,0-0.2,0.1-0.3,0.1
        c-0.1,0.2-0.2,0.4-0.4,0.5c0-0.1,0.1-0.3,0.1-0.5c-0.3-0.1-0.5,0-0.7,0.3c-0.1,0-0.2-0.1-0.3-0.2c0,0.1,0,0.3,0,0.4
        c-0.1,0-0.2-0.1-0.3-0.1c-0.3,0.3-0.5,0.6-0.9,0.8c-0.4,0-0.8-0.2-1.1-0.3c-0.1,0.1-0.2,0.1-0.3,0.2c-0.6,0.4-1.2,0.8-1.8,1.3
        c0,0.1,0.1,0.3,0.1,0.5c-0.3,0.1-0.6,0.2-0.9,0.1c-0.1,0.3,0.1,0.3,0.4,0.3c0,0.2,0.1,0.6-0.2,0.4c-0.1,0.2-0.3,0.5-0.5,0.6
        c0,0.1,0.1,0.3,0.2,0.3c-0.1,0-0.4-0.1-0.6-0.1c0,0.1,0.1,0.2,0.1,0.3c-0.1,0-0.3,0-0.3,0.1c0,0.1-0.1,0.3-0.2,0.4
        c-0.2,0.2-0.4-0.1-0.4-0.3l-0.1,0c0,0.4-0.3,0.2-0.6,0.2c0.1,0.1,0.2,0.3,0.3,0.4l-0.4-0.1c-0.1,0.2-0.2,0.2-0.3,0
        c-0.2,0.1-0.4-0.1-0.6-0.2c0,0,0,0.1,0,0.2c0.3,0,0.5,0.4,0.1,0.3l-0.1,0l0,0.1c-0.4-0.1-0.7,0.4-1,0.6c-0.1,0-0.3-0.1-0.4-0.1
        c0.1,0.1,0.3,0.3,0.3,0.5c-0.1,0.1-0.3,0.2-0.4,0.3c0-0.1,0-0.2,0-0.2c0.1,0,0.2,0,0.3,0c-0.1-0.6-0.4-0.1-0.6,0.1
        c-0.1,0-0.3,0-0.4-0.1c0,0.1,0.1,0.3,0.2,0.3c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0.2-0.4,0.3-0.6,0.3c-0.1,0.1-0.2,0.2-0.3,0.3
        c-0.2,0.4-0.6,0.3-1,0.4c0,0.2,0.1,0.3,0.2,0.4c-0.3,0-0.7-0.1-0.8,0.3c0.1,0,0.4-0.1,0.6-0.2c0,0.1,0.1,0.2,0.1,0.3
        c-0.2,0-0.4,0-0.6,0.1c0-0.2-0.1-0.6-0.3-0.4c0,0.1,0.1,0.3,0.1,0.4c-0.1,0-0.4-0.1-0.5-0.1c-0.1,0.2-0.2,0.5-0.3,0.7
        c0-0.2-0.1-0.3-0.2-0.5c0,0.1,0,0.3,0,0.4c-0.2-0.2-0.7,0-0.5,0.3c0.1,0.3-0.4,0.1-0.6,0.2c0,0.2-0.1,0.4-0.2,0.5c0-0.1,0-0.2,0-0.2
        c0.1-0.2,0.1-0.5,0.2-0.7c-0.3,0.3-0.5,0.6-0.4,1c-0.2,0-0.3,0-0.5,0c0.3,0.3-0.6,0.7-0.1,0c-0.2,0.1-0.4,0.3-0.5,0.5
        c0.1,0,0.4,0,0.5,0c0,0.3-0.3,0.2-0.5,0.2c-0.3,0.2-0.7,0.3-0.9-0.1c-0.2,0.1-0.4,0.2-0.6,0.4c-0.1,0-0.3,0-0.4,0c0,0,0,0.1,0,0.1
        l0.3-0.1c-0.1,0.1-0.2,0.3-0.3,0.5c-0.3-0.1-0.6-0.2-0.8,0c0.1,0,0.3,0.1,0.4,0.1c-0.2,0.1-0.3,0.2-0.5,0.2c0,0.1-0.1,0.3-0.1,0.5
        c0,0-0.1,0-0.2,0c0-0.1,0-0.4,0-0.5c-0.4,0.1-0.7,0.5-1.1,0.5c0,0,0,0.1,0,0.2c-0.2,0-0.5-0.1-0.7-0.1c0,0.1,0.3,0.4,0.1,0.5
        c-0.3,0-0.5-0.2-0.6-0.4c-0.1,0.2-0.1,0.7-0.4,0.4c0-0.1-0.1-0.2-0.1-0.3c-0.1,0-0.2,0.1-0.3,0.1c0,0.1,0.1,0.3,0.1,0.4
        c-0.2,0-0.3,0-0.5,0c0-0.2-0.1-0.3-0.3-0.3c0,0.1,0,0.3,0,0.4c-0.3-0.1-0.6-0.1-0.9,0.1c-0.2,0-0.3,0-0.5,0.2
        c-0.1-0.2-0.3-0.2-0.5-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.4,0.2-0.9,0.3-1.3,0.1c-0.3-0.2-0.6,0.1-0.9,0.1c-0.3-0.1-0.6-0.1-0.9-0.1
        c-0.2-0.1-0.2-0.3-0.2-0.5l-0.1,0c0,0.3-0.3,0.3-0.6,0.4c-0.2-0.1-0.4-0.2-0.6-0.4c-0.3,0-0.5-0.1-0.7-0.2c-0.2,0-0.5,0-0.7,0
        c0.1,0.2,0.4,0.3,0.6,0.4c0.8,0.3,1.5,0.9,2.4,0.9c0.3-0.1,0.6,0.1,0.9,0.2c0.4-0.1,0.9,0.1,1.2,0.3c0.8-0.1,1.5,0.3,2.3,0.1
        c0.3-0.1,0.4,0.2,0.6,0.3c0.4-0.3,0.9-0.2,1.4-0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.7-0.3,1.4,0.1,2.1-0.2c0.1,0.1,0.3,0.3,0.4,0.4
        c0.6-0.2,1.4-0.4,2.1-0.1c0.1-0.3,0.4-0.1,0.6-0.2c0.4,0,0.9,0,1.2-0.1c0.1,0.2,0.4,0.1,0.6,0c0.3-0.1,0.6,0,0.9,0
        c0.3-0.2,0.6-0.2,1-0.3c0.2,0.1,0.5,0.2,0.7,0.3c0.1-0.1,0.3-0.3,0.4-0.4c0.1,0,0.2,0.1,0.3,0.1c0.7-0.1,1.4-0.1,2.1-0.5
        c0-0.1,0-0.2,0-0.2c0,0,0.1,0,0.2,0l-0.1,0.2c0.6,0,1.1-0.2,1.6-0.2c0.1,0,0.2-0.1,0.2-0.2c0.5,0.1,0.9,0,1.4,0.1
        c0.1-0.1,0.3-0.3,0.4-0.4c0.5-0.1,1.1-0.1,1.5-0.3c0.3,0,0.5,0,0.8,0c0.6-0.4,1.3-0.3,1.9-0.6c0.3,0.4,0.8,0,1.1-0.3
        c0.4-0.1,0.7-0.2,1.1-0.3c0.3,0,0.6,0.1,0.5-0.3c0.4,0.1,0.7-0.2,1.1-0.1c0.3,0,0.3-0.3,0.2-0.5c0.1,0.2,0.4,0.2,0.6,0.1
        c0.3,0.1,0.5,0,0.7-0.2c0,0.1,0,0.2,0,0.3c0.6-0.6,1.5-0.6,2.3-1c0.4-0.2,0.8,0.1,1.1,0c0.2-0.3,0.4-0.7,0.8-0.7
        c0.1,0.1,0.3,0.2,0.5,0.2c0-0.4,0.7-0.4,0.9-0.6c0.1,0,0.3,0,0.4,0c0.1-0.1,0.2-0.2,0.2-0.2c0.1,0,0.3,0,0.4,0.1
        c0.5-0.4,1.1-0.4,1.6-0.7c0.5,0.4,0.5-0.5,0.8-0.6l-0.1,0.4c0.3,0,0.6-0.1,0.8-0.3c0.3,0.3,0.4-0.2,0.7-0.3c0.3-0.2,0.7-0.2,1-0.1
        l0-0.2l-0.2,0c0-0.4,0.3,0,0.5,0c0-0.1,0-0.3,0-0.4c0.2,0.1,0.3,0.2,0.5,0.4c0.4-0.2,0.7-0.4,1.1-0.5c0.5-0.2,1-0.5,1.6-0.6
        c0.2-0.1,0.3-0.2,0.4-0.3c0.5,0.5,0.9-0.3,1.4-0.3c0.2-0.2,0.5-0.1,0.7-0.3c0.6-0.2,1.4-0.2,2-0.6c0.3,0.1,0.5-0.1,0.7-0.3
        c0.1,0,0.2,0.1,0.3,0.1c0.1-0.3,0.3-0.3,0.6-0.3c0.4-0.2,0.8-0.4,1.2-0.3c0.2,0.1,0.4-0.1,0.6-0.3c0.4-0.1,0.8-0.3,1.2-0.3
        c0.3,0,0.4-0.3,0.6-0.4c0,0.1,0,0.3,0,0.4c0.1-0.1,0.3-0.2,0.4-0.3c0.1,0,0.2,0.1,0.3,0.1c0.3-0.2,0.7-0.3,1.1-0.4
        c0.2,0.3,0.5,0,0.7-0.1c0.2,0,0.3,0,0.5-0.1c0.2-0.3,0.6-0.4,0.9-0.5c0.2,0,0.4-0.3,0.6-0.1c0.2-0.1,0.4-0.1,0.6-0.2
        c0.1,0,0.3,0,0.4,0c0.1-0.1,0.3-0.2,0.4-0.3c0.2,0,0.3-0.1,0.4-0.1c0.2,0,0.4,0,0.6,0.1c0.2-0.1,0.5-0.2,0.7-0.3
        c0.1,0.1,0.3,0.2,0.3,0.2c0-0.1,0-0.2,0-0.3c0.3,0.1,0.5-0.1,0.7-0.3c0.2,0,0.4,0,0.6-0.1c0.1,0,0.3,0.1,0.4,0.1
        c0.2-0.2,0.4-0.3,0.5-0.5c0,0.1,0.1,0.2,0.2,0.2c0.4-0.2,0.9-0.3,1.3-0.3c0.1,0,0.2,0.1,0.2,0.2c0.1-0.1,0.3-0.3,0.5-0.3
        c0.6-0.1,1.2-0.4,1.9-0.4c0.2-0.1,0.3-0.1,0.5-0.1c0-0.1,0-0.2,0-0.3c0.2,0.2,0.5,0.2,0.8,0.3c0.2-0.1,0.3-0.2,0.5-0.3
        c0.2,0,0.3-0.1,0.4-0.2c0.2,0.1,0.3,0.2,0.5,0.4c0.2-0.1,0.5-0.3,0.7-0.3c0.1-0.1,0.3-0.2,0.4-0.3c0,0.1-0.1,0.3-0.1,0.4
        c0.3-0.1,0.7-0.1,0.8-0.5c0.2,0,0.3,0,0.5,0c0.8,0,1.5-0.3,2.3-0.3c0.4,0,0.8,0.1,1.2-0.3c0.2,0.2,0.5,0.2,0.7,0.3
        c0.5-0.5,1.3-0.3,1.9-0.4l-0.1-0.3c0.2,0.1,0.3,0.3,0.4,0.4c0.3-0.2,0.7-0.1,1,0c0.5-0.2,1.1-0.2,1.7-0.2c0-0.1,0-0.2,0-0.2
        c0.6,0.3,1.2,0.1,1.7,0.2c0.2-0.1,0.3-0.1,0.5-0.2c0.3,0,0.6,0.2,0.9,0.4c0.2,0,0.4-0.1,0.6-0.2c0.2,0.1,0.3,0.1,0.5,0.2
        c0.2,0,0.3-0.4,0.5-0.2c0.4,0.1,0.9-0.1,1.2,0.2c0.8-0.2,1.6,0.4,2.4,0.1c0.5,0.4,1.1,0,1.7,0.2c0.2,0,0.3,0,0.5,0
        c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0,0.3-0.1,0.5,0c0.3-0.2,0.6,0.1,0.9,0.3c0.2,0,0.4,0,0.6-0.1c0.5,0.1,1.1,0.3,1.5-0.1
        c0.1,0,0.1,0.1,0.2,0.2c-0.1,0-0.3,0-0.4,0.1c0,0.4,0.7,0.1,0.9,0.4c0.1,0.1,0.3,0.1,0.5,0.1c0.3,0.1,0.6,0.3,0.9,0.3
        c0.3,0,0.4,0.2,0.7,0.4c0.1,0,0.2-0.1,0.3-0.2c0.6,0.1,1.1,0.6,1.7,0.6c0.3,0.1,0.7,0.2,0.9,0.5c0.2,0,0.4,0,0.7,0
        c0,0.3,0.3,0.2,0.5,0.2c0.4,0.5,1,0.6,1.5,0.8c0-0.1,0-0.3,0-0.3l0.1,0c0,0.1-0.1,0.2-0.1,0.3c0.4-0.1,0.6,0.2,1,0.3
        c0.4,0.1,0.8,0.2,1.1,0.5c0.2,0.3,0.7,0.4,1,0.3c0.1,0.1,0.2,0.2,0.4,0.3c0.2,0.1,0.7,0,0.6,0.3c0.4-0.1,0.7,0.3,1.1,0.3
        c0.2,0,0.4,0.2,0.5,0.3c0.2,0.1,0.4,0.2,0.5,0.3c0.5-0.1,0.8,0.3,1.3,0.5c0,0.1,0,0.2,0.1,0.3c0.4,0,0.7,0.4,1,0.6
        c0.3,0.3,0.8,0,1,0.5c0.4,0.2,0.7,0.6,1.2,0.7c0.2,0.4,0.7,0.3,1,0.7c0.4,0.5,1.1,0.7,1.4,1.2c0.3,0.3,0.6,0.4,0.8,0.7
        c0.1,0.2,0.3,0.3,0.6,0.4c0,0.1-0.1,0.2-0.1,0.3c0.5-0.1,0.8,0.2,1,0.5c0.2,0.1,0.2,0.3,0.2,0.5c0.2,0.1,0.6,0.1,0.7,0.4
        c0.1,0.3,0.4,0.4,0.6,0.5c0.1,0.5,0.7,0.6,0.9,1.1c0.1,0.2,0.4,0.3,0.6,0.6c0.2,0.3,0.5,0.5,0.8,0.8c0.1,0.4,0.3,0.8,0.6,1.1
        c0.3,0.1,0.5,0.4,0.6,0.7c0.4,0.3,0.7,0.8,0.8,1.3c0.3,0.5,0.7,0.9,0.7,1.4c0.1,0.4,0.4,0.7,0.5,1.2c0.2,0.8,0.7,1.4,0.8,2.2
        c0.2,0.5,0.5,0.9,0.8,1.3c0.1,0.2,0.2,0.4,0.2,0.6c-0.6-0.3-1-0.9-1.5-1.3c0-0.3-0.7-0.3-0.4-0.7c-0.4,0-0.7-0.3-0.8-0.7l-0.1,0
        c-0.1-0.1-0.2-0.3-0.3-0.4l0,0.3c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.3-0.1-0.4c-0.1-0.1-0.3-0.1-0.4-0.1
        c-0.1-0.1-0.2-0.2-0.3-0.4c0-0.1,0-0.2-0.1-0.4c-0.1,0-0.3,0.1-0.3,0.1c0-0.1,0.1-0.3,0.1-0.4c-0.1,0-0.3,0-0.4,0.1
        c0.1-0.1,0.2-0.2,0.2-0.3c-0.1,0-0.2,0-0.3,0c0-0.3-0.2-0.5-0.3-0.8l-0.1,0.2c0,0.1,0.1,0.3,0.1,0.4c-0.3-0.2-0.4-0.4-0.4-0.7
        c-0.1,0-0.2,0.1-0.3,0.2c0-0.1,0-0.2,0-0.2l0.2,0c0.6-0.1-0.5-0.8-0.3-0.2c-0.1-0.1-0.1-0.3-0.2-0.4c-0.5,0.5-0.6-0.5-0.8-0.8
        c0.3,0,0.5,0,0.8,0c-0.2-0.1-0.5-0.1-0.7-0.1c0.1-0.2,0.1-0.4,0-0.5c-0.1,0.1-0.3,0.2-0.4,0.4l0.3-0.1l-0.2,0.2
        c-0.1,0-0.3,0.1-0.4,0.1c0-0.2,0.1-0.4,0.1-0.7l-0.3,0.1c0-0.1,0.1-0.3,0.1-0.4c-0.2,0.1-0.5,0.1-0.7,0c0.1-0.1,0.2-0.2,0.2-0.3
        c-0.3,0-0.5-0.1-0.8-0.1c0.1-0.1,0.2-0.3,0.3-0.4c-0.2,0-0.4,0-0.6,0c0-0.2-0.2-0.3-0.4-0.4l-0.2,0.2c0-0.3-0.1-0.6-0.3-0.9
        c-0.2,0.1-0.5,0.1-0.7,0V290c0.1,0,0.3-0.1,0.4-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2,0.1-0.3,0.2-0.5,0.2c0-0.2,0.3-0.6-0.1-0.6
        c0-0.3,0-0.7,0.1-1c-0.1-0.1-0.3-0.2-0.4-0.3c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.5,0,0.8
        c-0.3-0.2-0.4-0.6-0.6-0.9c0.1,0,0.3,0,0.4,0c-0.1-0.1-0.2-0.2-0.2-0.2c-0.1,0-0.3,0.1-0.4,0.2c0,0.1,0,0.2,0,0.2
        c-0.1,0-0.2,0-0.3,0c0-0.2,0-0.3,0-0.5c-0.2,0.1-0.4,0.2-0.6,0.1c0-0.1-0.1-0.4-0.1-0.5c0.1,0.1,0.2,0.2,0.3,0.3
        c0.1,0,0.3-0.1,0.4-0.2c-0.3-0.1-0.6-0.2-0.9-0.2c0-0.1,0.1-0.3,0.1-0.4c-0.2,0.1-0.3,0.1-0.4,0.2c-0.1-0.2-0.3-0.3-0.4-0.5
        c-0.2,0.1-0.5,0.2-0.4-0.2c-0.3,0-0.5-0.2-0.7-0.3c0.1-0.1,0.3-0.1,0.4-0.2l-0.1-0.2c-0.1,0.1-0.3,0.1-0.4,0.2
        c-0.1-0.2-0.1-0.4-0.1-0.6c-0.2,0-0.4,0-0.6,0c-0.1-0.1-0.1-0.5-0.4-0.3c0-0.1,0-0.2,0-0.2l-0.2,0.1c-0.1,0-0.2-0.1-0.2-0.2
        c-0.1-0.2-0.2-0.4-0.3-0.5c-0.3,0.3-0.5,0.1-0.6-0.2c0,0.1,0,0.3,0,0.4c-0.1-0.2-0.3-0.3-0.4-0.5c-0.1-0.1-0.1-0.3-0.2-0.4
        c-0.1-0.1-0.3,0-0.5,0c0.3-0.2,0.3-0.5-0.1-0.6c0,0.1,0.1,0.3,0.1,0.4c-0.2-0.1-0.4-0.1-0.6-0.1c0-0.1-0.1-0.3-0.1-0.4
        c-0.2,0.1-0.5,0.3-0.7,0c0.1,0,0.3-0.1,0.4-0.1c-0.1-0.3-0.4-0.1-0.6,0c-0.1-0.2-0.3-0.2-0.5-0.1c0-0.1,0.1-0.3,0.1-0.4
        c-0.3,0.1-0.5,0.1-0.7-0.2c0,0.1,0,0.3,0.1,0.4c-0.3,0-0.2-0.4-0.3-0.6c-0.2,0-0.4,0.1-0.5,0.2c0-0.1,0-0.2,0-0.3
        c-0.4,0-0.7-0.1-1-0.2c0-0.1,0.1-0.2,0.1-0.3c-0.1,0-0.2,0.1-0.3,0.1c0.1-0.2,0.1-0.3,0.2-0.4c-0.2,0-0.3,0.1-0.4,0.2
        c-0.2-0.2-0.5-0.3-0.7-0.5c-0.2,0-0.4,0.1-0.5,0.1c0.1-0.2,0.3-0.4,0.5-0.4c-0.3-0.2-0.5,0.2-0.8,0.2c-0.2-0.1-0.3-0.2-0.4-0.3
        c-0.1,0-0.2,0.1-0.3,0.2c0.1-0.2,0.2-0.4,0.3-0.5c-0.2-0.1-0.4-0.2-0.6-0.3c0,0.1,0,0.3,0,0.4c-0.1,0-0.2-0.1-0.2-0.1
        c-0.1,0-0.3,0.1-0.4,0.2c0.1-0.2,0.2-0.4,0.4-0.6c0,0-0.1-0.1-0.1-0.1l0,0.3c-0.2-0.1-0.4-0.2-0.6-0.3c0-0.3-0.1-0.3-0.3-0.1
        c0.2,0.2,0.4,0.4,0.6,0.6c-0.3,0-0.5,0.1-0.8,0.1c0-0.1-0.1-0.3-0.1-0.3c-0.2-0.1-0.3-0.2-0.4-0.3c0,0.1-0.1,0.2-0.1,0.3
        c0-0.1,0-0.3,0.1-0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2-0.1-0.3-0.1c0.2-0.3-0.2-0.4-0.4-0.6c0,0.1,0.1,0.2,0.1,0.3
        c-0.1,0-0.3-0.1-0.4-0.1c-0.2,0-0.4,0-0.5,0c0-0.1-0.1-0.3-0.1-0.4c0,0-0.1,0.1-0.2,0.2c-0.1,0-0.3-0.1-0.4-0.1
        c0.1-0.1,0.2-0.2,0.2-0.3c-0.2,0-0.4,0.1-0.6,0.1c-0.1-0.2-0.2-0.3-0.4-0.4c0,0.1,0.1,0.4,0.1,0.5c-0.1,0-0.2-0.1-0.3-0.1l0-0.2
        c-0.5,0.1-1-0.1-1.5-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.4c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0-0.5-0.2-0.7-0.3
        c0,0.2-0.1,0.3-0.3,0.3c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1,0-0.3,0-0.4,0l0-0.2c-0.7-0.1,0.1,0.3,0.2,0.5c-0.5,0.3-0.5-0.6-0.8-0.4
        c0,0.1-0.1,0.2-0.1,0.3c-0.2,0-0.3,0-0.4,0c0-0.1,0.1-0.3,0.1-0.4c-0.1,0-0.2,0.1-0.3,0.2c0,0.1,0.1,0.2,0.1,0.2c0,0,0,0.1,0,0.2
        c-0.1-0.1-0.3-0.2-0.4-0.4l0.1-0.1l0-0.1c-0.2,0-0.5,0.1-0.6-0.1c-0.3,0-0.6-0.1-0.9-0.2c-0.1,0.1-0.2,0.2-0.3,0.4
        c0-0.1,0.1-0.4,0.1-0.5c-0.2,0-0.5,0.2-0.6,0.2c-0.1-0.1-0.2-0.3-0.2-0.4c-0.2,0.1-0.3,0.4-0.5,0.2c-0.4-0.2-0.9-0.1-1.3-0.2
        c-0.7,0.1-1.4-0.2-2.1-0.1c-0.1,0.1-0.3,0.2-0.4,0.3c0-0.2,0.5-0.4,0.1-0.6c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2,0-0.3-0.1l0.1,0.3
        c-0.3-0.3-0.8-0.5-1.1-0.2c-0.3-0.2-0.6,0-0.9,0.1c0.1-0.1,0.2-0.4,0.3-0.5c-0.2,0.1-0.4,0.3-0.5,0.5c-0.3-0.1-0.6-0.3-0.8-0.3
        c0,0.2-0.2,0.4-0.4,0.2c-0.2-0.2-0.4,0.1-0.5,0.2c-0.2,0-0.3-0.1-0.5-0.1c-0.3,0.1-0.6,0-0.8,0.2c-0.1-0.1-0.2-0.3-0.2-0.4
        c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2-0.1-0.3-0.3-0.4-0.4c-0.1,0.1-0.3,0.3-0.4,0.4c-0.1-0.1-0.3-0.3-0.5-0.4c0.1,0.1,0.2,0.4,0.3,0.5
        c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0.1-0.6-0.1-0.9-0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.3-0.3-0.6,0.1-0.8,0.3c-0.2,0-0.4-0.1-0.5-0.1
        l0.1,0.2c-0.2-0.1-0.4,0-0.5,0.1c-0.1,0-0.3-0.1-0.4-0.1l0-0.1c0.1,0,0.3-0.1,0.4-0.1c-0.1-0.1-0.3-0.1-0.4-0.1
        c-0.1,0.4-0.6,0.3-0.7,0c0-0.1,0.1-0.2,0.2-0.3c-0.2,0.2-0.3,0.4-0.6,0.5c0,0.1,0.1,0.2,0.1,0.3c-0.2-0.1-0.4,0-0.6,0
        c-0.1-0.1-0.2-0.2-0.4-0.3c-0.1,0.4-0.5,0.4-0.8,0.4c0,0.4-0.3,0.1-0.4,0c0-0.1,0.1-0.2,0.1-0.2c-0.2,0-0.4,0-0.6,0
        c-0.2-0.1-0.3-0.1-0.5-0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.1,0.6,0,0.6,0.3c-0.1,0-0.2,0-0.4,0c-0.1,0.1-0.2,0.2-0.3,0.3
        c0-0.2,0-0.3,0.1-0.5c-0.1,0.1-0.2,0.2-0.3,0.3c0,0-0.1-0.1-0.2-0.2c-0.1,0.1-0.3,0.2-0.4,0c-0.2-0.2-0.3,0.2-0.2,0.4
        c-0.1,0-0.4-0.1-0.5-0.1c0.1-0.2,0.4-0.4,0.1-0.6c0,0.1,0,0.3,0,0.4c-0.1-0.1-0.2-0.2-0.3-0.2l0,0.2c-0.1,0-0.3-0.1-0.5-0.2
        c0,0.1,0.1,0.3,0.1,0.4c-0.4,0-0.7-0.6-1-0.2c-0.1,0-0.2,0-0.3,0c0,0.1,0.1,0.4,0.1,0.5l-0.3-0.4c0,0.1,0,0.3,0,0.4
        c-0.1,0-0.2,0-0.2,0c0-0.1,0.1-0.2,0.1-0.3c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1-0.1-0.3-0.2-0.4-0.3c0,0.1,0,0.3,0,0.4
        c-0.2,0-0.3,0.1-0.5,0.2c-0.2-0.1-0.3-0.2-0.5-0.3c0,0.1,0.1,0.4,0.2,0.5c-0.3-0.1-0.4-0.5-0.7-0.7c0,0.2,0.1,0.4,0.2,0.6
        c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1,0.2-0.2,0.4-0.5,0.4c-0.3-0.2-0.5-0.1-0.6,0.2c0.2-0.2,0.5,0,0.7,0.1
        c-0.1,0-0.4,0.1-0.6,0.1c-0.2-0.5-0.9-0.1-0.8,0.4c-0.2,0-0.9,0.1-0.7-0.4c-0.4,0-0.5,0.2-0.4,0.5c-0.1,0-0.2-0.1-0.2-0.1
        c0,0.1-0.1,0.2-0.1,0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2,0-0.4,0-0.5,0.2c-0.2,0-0.4,0-0.6,0c0,0.1,0.1,0.2,0.2,0.3
        c-0.3,0.1-1-0.1-0.4-0.3c0-0.3-0.3,0-0.5,0c0,0.2-0.1,0.4-0.2,0.5c-0.1,0-0.2-0.1-0.3-0.2c-0.1,0.1-0.2,0.3-0.2,0.4
        c-0.4-0.1,0-0.3,0.1-0.5c-0.2,0.1-0.5,0.2-0.7,0.3c-0.2,0-0.4-0.1-0.6-0.3c0,0.4-0.2,0.6-0.5,0.8c-0.2-0.1-0.3-0.1-0.5-0.1
        c0,0.1,0,0.3,0,0.4c-0.3,0.1-0.6,0.1-1,0.1c-0.2,0.1-0.4,0.1-0.6,0c0,0.1,0.1,0.4,0.2,0.5c-0.2-0.1-0.3-0.3-0.2-0.5
        c-0.1,0-0.4-0.1-0.5-0.2c0.1,0.2,0.3,0.4,0.4,0.6c-0.3,0-0.7,0.1-0.8-0.3c-0.1,0.1-0.2,0.3-0.3,0.4c0,0-0.1,0.1-0.1,0.1
        c-0.1-0.1-0.2-0.2-0.2-0.2c-0.1,0.1-0.1,0.3-0.2,0.4c-0.2,0-0.3,0-0.5,0c0.2-0.3,0.5-0.4,0.8-0.4c-0.1-0.1-0.2-0.2-0.4-0.3
        c-0.2,0.3-0.5,0.8-0.9,0.6l-0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.2c-0.2,0.1-0.3,0.1-0.5,0.1c-0.1,0.3-0.5,0.2-0.7,0.2c0-0.1,0-0.2,0-0.3
        c-0.2,0.1-0.3,0.2-0.2,0.5c-0.1,0-0.4,0-0.6,0c0.1-0.1,0.3-0.3,0.4-0.4c-0.3,0.2-0.9,0.1-0.9,0.6c-0.2,0.1-0.4,0.3-0.7,0.3
        c0.1-0.1,0.7-0.5,0.2-0.5c-0.1,0.2-0.3,0.3-0.5,0.4c0,0.1,0,0.3,0.1,0.4c-0.1,0-0.2-0.1-0.3-0.2c-0.1,0.1-0.2,0.1-0.3,0.2
        c0-0.3-0.3-0.4-0.5-0.4c0.1,0.2,0.2,0.4,0.3,0.5c-0.3-0.3-0.5,0.1-0.6,0.3c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2,0.1,0,0.3,0,0.5
        c-0.2,0-0.4-0.3-0.5,0c-0.3-0.1-0.7,0.1-0.9,0.4c0,0-0.1-0.1-0.2-0.2c-0.1,0.5-0.5,0.1-0.8,0c-0.2,0.2-0.2,0.2,0.1,0.2
        c-0.1,0.5-0.5,0.1-0.8,0c0.1,0.2,0.3,0.3,0.4,0.4c-0.4,0-0.8,0-1.2-0.1c0.1,0.1,0.4,0.2,0.5,0.2c-0.3,0.4-0.6-0.2-1-0.1
        c0.1,0.1,0.3,0.3,0.4,0.3c-0.5,0.2-0.9,0-1.3-0.2c0,0.2,0.4,0.4,0.2,0.5c-0.1,0-0.3-0.1-0.4-0.1c0,0.1,0,0.2,0.1,0.3
        c-0.2,0.1-0.3,0.1-0.5,0.2c0-0.3-0.2-0.3-0.5-0.3c0,0.1,0.1,0.3,0.2,0.4c-0.1,0-0.2-0.1-0.3-0.1l-0.1,0.2c-0.3-0.1-0.5,0.1-0.8,0.3
        c0-0.1,0.1-0.3,0.1-0.5c-0.3,0.2-0.5,0.4-0.6,0.7c-0.2-0.3-0.4-0.2-0.7,0.1c0-0.1,0.1-0.4,0.2-0.5l0.1,0.1c0.1-0.2,0.2-0.3,0.3-0.4
        c-0.4,0.1-0.4,0.5-0.6,0.7c-0.2,0.3-0.5,0.3-0.8,0.4c-0.1,0.1-0.3,0.2-0.4,0.3c0.1-0.2,0.2-0.4,0.4-0.6c0,0-0.1,0-0.2,0
        c-0.2,0.2-0.3,0.5-0.2,0.8c-0.2-0.1-0.3-0.2-0.5-0.2c-0.1,0.1-0.3,0.2-0.5,0.2c0,0.1,0.1,0.2,0.2,0.3l-0.2,0l0-0.2
        c-0.1,0-0.4,0-0.5-0.1c0.1,0.1,0.2,0.3,0.3,0.4l-0.2,0l0-0.2c-0.1,0-0.2,0-0.2,0c0-0.1-0.1-0.2-0.2-0.3c-0.1,0.2-0.1,0.4-0.2,0.6
        c-0.1,0-0.2-0.1-0.3-0.2c-0.1,0-0.2-0.1-0.3-0.1v0.1c0.4,0.3,0,0.4-0.3,0.3c0,0.5-0.8,0.5-0.8,0c-0.2,0.1-0.2,0.3,0,0.5
        c-0.5-0.5-0.7,0.5-1.1,0.3c0-0.2,0-0.3,0.1-0.5c-0.2,0.1-0.4,0.3-0.3,0.5c-0.2,0.1-0.3,0.1-0.5,0.1c-0.1,0.1-0.2,0.2-0.4,0.3
        c0-0.2-0.1-0.4-0.1-0.6c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2-0.2-0.4-0.2-0.4,0.1c0.1,0,0.3,0,0.4,0c0,0.3-0.3,0.1-0.4,0.1
        c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.3-0.1-0.4,0.1c-0.1,0-0.2-0.1-0.2-0.1c0.1-0.3,0.5-0.4,0.7-0.5c0-0.2-0.2-0.3-0.3-0.3
        c0,0.1,0.1,0.2,0.1,0.3c-0.6,0.2-1,0.7-1.5,1c0.1-0.2,0.1-0.5-0.1-0.6c-0.1,0.2-0.1,0.4-0.2,0.6c-0.2,0.1-0.5,0.3-0.7,0
        c-0.2,0.3-0.5,0.2-0.7-0.1c-0.6,0-0.4,0.8-1,0.9c0.3-0.4,0.4-0.7,0-0.9c0,0.1,0.1,0.3,0.1,0.4c-0.2,0.1-0.3,0.1-0.5,0.2
        c0,0.1,0.1,0.2,0.1,0.3c-0.2-0.2-0.3-0.2-0.5-0.1c-0.2-0.1-0.3-0.2-0.5-0.2c0,0.1,0,0.4,0,0.6c-0.2-0.2-0.4-0.3-0.5-0.5
        c-0.1,0.1-0.2,0.3-0.4,0.4c0.2,0,0.3-0.1,0.5-0.1c0,0.1,0.1,0.2,0.1,0.2c-0.2,0-0.4,0-0.6-0.1c0,0-0.1,0.1-0.1,0.2
        c-0.1-0.2-0.2-0.3-0.4-0.4l0.1,0.3c-0.1,0-0.3-0.1-0.4-0.1c-0.2,0.2-0.3,0.4-0.5,0.5c0-0.1,0-0.3,0-0.4c-0.1,0-0.3,0-0.4,0
        c0,0.1,0,0.2,0,0.2c-0.3,0.1-0.6,0.4-0.8,0.6c0-0.2,0-0.3,0-0.5c0,0.1-0.1,0.2-0.2,0.3c-0.2-0.1-0.3-0.2-0.5-0.3
        c0,0.1,0.1,0.3,0.1,0.4c-0.2,0-0.2-0.3-0.3-0.4l-0.1,0.3c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0.1-0.5,0.1-0.7,0.2c0,0.1,0.1,0.3,0.1,0.4
        c-0.2-0.1-0.3-0.2-0.2-0.4l-0.4-0.1c0,0.1,0.1,0.3,0.2,0.4c-0.1,0.1-0.3,0.1-0.4,0.2c0-0.1,0-0.2,0-0.3c-0.3,0.2-0.6,0.3-0.9,0.4
        l0-0.2c-0.1,0.1-0.2,0.2-0.3,0.3c0-0.1,0-0.3,0-0.4c-0.1,0-0.2,0.1-0.3,0.1c0.1,0.5-0.5,0.4-0.7,0.1l0.4,0c-0.1-0.1-0.2-0.2-0.2-0.3
        c0,0.2-0.3,0.2-0.4,0.3c0.2,0.2,0.3,0.4,0.5,0.6c-0.3-0.1-0.7-0.1-0.8-0.4c-0.1,0.1-0.2,0.2-0.2,0.4c-0.1,0-0.2,0-0.3,0l0.1-0.2
        c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1,0.2-0.2,0.4-0.4,0.5c-0.3,0-0.6,0-0.9,0c-0.2-0.2-0.3-0.3-0.5-0.4c0.1,0.2,0.2,0.4,0.4,0.6l-0.4-0.2
        c0,0.1,0,0.3,0,0.4c-0.1,0-0.3-0.1-0.4-0.1c0.1-0.2,0.4-0.4,0.1-0.5c-0.3,0.3-0.8,0.3-1,0.7c-0.3,0.2,0-0.3-0.1-0.5
        c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1-0.3-0.3-0.4-0.5-0.4c0,0.1,0.1,0.4,0.2,0.6c-0.1-0.1-0.3-0.3-0.4-0.4c0,0.1,0,0.3,0,0.4
        c-0.2,0-0.4,0-0.5-0.1c-0.3-0.1-0.6-0.3-0.9-0.1c0.1,0.1,0.3,0.2,0.4,0.3c-0.1,0-0.4,0-0.5,0c-0.1-0.1-0.2-0.2-0.3-0.3
        c0,0.1,0,0.3,0,0.3c-0.2,0-0.5,0-0.7,0.1c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1,0-0.2,0.1-0.3,0.2c0-0.1-0.1-0.2-0.2-0.2
        c-0.1,0-0.3,0-0.3,0c0,0.1,0.2,0.2,0.2,0.3c-0.2,0-0.3,0-0.5,0c0,0-0.1,0.1-0.2,0.2c-0.3,0.2,0.2-0.6-0.2-0.4c-0.2,0.6-0.8,0-1.2,0
        c0,0.1,0,0.3,0,0.4c-0.2-0.1-0.2-0.3-0.2-0.5c-0.1,0.1-0.2,0.2-0.2,0.2c-0.6,0-1.1,0-1.7-0.2c-0.1,0-0.2,0-0.3,0
        c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2-0.2-0.4-0.5-0.5,0c-0.2,0-0.4,0.2-0.5,0c-0.2-0.3-0.5-0.4-0.8-0.6
        c0,0.1,0,0.3,0,0.4c-0.2-0.2-0.6-0.2-0.8-0.4c-0.1-0.2-0.3-0.1-0.5-0.1c-0.3,0-0.6,0-0.9-0.2c-0.1,0-0.2,0.1-0.2,0.1
        c-0.2-0.1-0.5-0.3-0.7-0.5c0.1,0,0.4,0,0.5,0c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.3-0.2-0.4-0.2c0,0,0,0.1,0,0.2
        c0.1,0.1,0.2,0.2,0.3,0.3c-0.3,0-0.6-0.1-0.9-0.3c0.2,0,0.6,0.1,0.4-0.3c-0.2,0.1-0.4,0.1-0.6,0.2c0.1-0.1,0.2-0.3,0.2-0.4
        c-0.3,0.1-0.5,0.1-0.8,0.2c0,0.3,0.4,0.2,0.6,0.2l-0.1,0.3c-0.2-0.1-0.3-0.2-0.5-0.3c0-0.2-0.1-0.3-0.1-0.5c-0.2,0-0.4,0.1-0.5,0.2
        c-0.2-0.2-0.4-0.4-0.7-0.5l0-0.2c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0-0.3-0.1-0.3-0.1l0-0.1c-0.6,0.2-0.5-0.6-0.9-0.8
        c0.1,0.2,0.1,0.5,0.2,0.7c-0.2-0.1-0.4-0.3-0.4-0.6l-0.5,0.2c0.1-0.1,0.2-0.3,0.3-0.4c-0.1,0-0.3-0.1-0.3-0.2c0,0.1,0,0.2,0,0.3
        c-0.4-0.2-1-0.2-1.1-0.7c0,0-0.1,0.1-0.2,0.1c0-0.3-0.1-0.5-0.3-0.6c0,0.1,0,0.3,0,0.3c-0.2-0.2-0.4-0.4-0.6-0.5c0.1,0,0.3,0,0.4,0
        c-0.2-0.2-0.4-0.3-0.6-0.5c0,0.2,0,0.3,0.1,0.5c-0.3-0.2-0.4-0.5-0.6-0.8c-0.3-0.1-0.5-0.3-0.8-0.4l0.2-0.3c-0.1,0-0.4,0-0.5,0
        c0-0.1,0-0.3,0-0.5c-0.1,0-0.2,0-0.3-0.1c0.1-0.3-0.1-0.4-0.3-0.3c0,0,0.1-0.1,0.2-0.2c-0.2-0.1-0.3-0.1-0.5-0.2
        c0-0.1,0.1-0.2,0.1-0.2c-0.2-0.2-0.1-0.5-0.1-0.7c-0.2-0.2-0.3-0.4-0.5-0.6l-0.1,0c0.2,0.2,0.5,0.3,0.3,0.6l-0.1,0.1
        c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.2-0.2-0.5-0.1-0.7c-0.2,0-0.4,0.1-0.4,0.4c0-0.2-0.1-0.5,0-0.7c-0.1,0-0.2,0.1-0.3,0.1
        c0-0.1,0.1-0.2,0.1-0.3c-0.5,0-0.3-0.5-0.3-0.8l-0.3,0c0,0.1,0,0.3,0,0.3c-0.2-0.2-0.3-0.3,0-0.4c-0.3-0.3-0.5-0.6-0.4-1
        c-0.3-0.2-0.5-0.5-0.8-0.7c0-0.1,0.1-0.2,0.1-0.2c-0.3-0.2-0.1-0.6-0.2-0.9l-0.2,0.1l0-0.3c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.2
        c-0.2-0.2-0.2-0.6-0.1-0.9c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.3-0.1-0.4-0.3-0.3c0-0.2,0.1-0.4,0.2-0.6c-0.1,0-0.2-0.1-0.2-0.1
        c0,0.1,0,0.2,0,0.3H8.3c0-0.2,0-0.3,0-0.5l0.1,0c0-0.1,0-0.4,0-0.5c-0.1,0-0.3,0.1-0.4,0.2c0.2-0.3,0-0.5-0.1-0.7
        c0.1-0.1,0.3-0.3,0.4-0.4l0.1,0.2c0-0.1,0-0.2,0-0.2c-0.1,0-0.3,0-0.4,0c0.1-0.3,0-0.4-0.3-0.5c0.1-0.1,0.2-0.3,0.2-0.5
        c-0.1-0.1-0.2-0.2-0.3-0.2c0-0.1,0-0.4,0-0.6c0.1,0,0.2,0,0.3,0.1c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.7,0.1-1.3,0-2l0.3,0.1
        c-0.1,0-0.2-0.1-0.2-0.2c-0.1-0.5,0-1.1,0-1.6c0.3-0.3-0.1-0.7,0.2-0.9c0-0.3,0.1-0.7,0-1c0.1,0,0.2,0,0.2-0.1c0-0.2,0-0.5-0.1-0.7
        l0.2,0c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.3-0.1,0.3-0.1c-0.1,0-0.3,0-0.3,0c0.3-0.2,0.2-0.6,0.3-0.9c0.1-0.5,0.4-0.9,0.4-1.4
        c0.3-0.2,0.4-0.6,0.4-1c0.1,0,0.2,0.1,0.3,0.2c-0.1-0.2-0.1-0.3-0.2-0.4c0.1-0.2,0.2-0.3,0.3-0.4c-0.1-0.2-0.1-0.4,0.1-0.5
        c0-0.1,0-0.2,0-0.2c0.3-0.2,0.4-0.6,0.3-1c0.1,0,0.3,0,0.3,0c-0.1-0.2-0.2-0.3-0.3-0.4c0.1,0.1,0.3,0.2,0.4,0.3
        c-0.1-0.4,0.1-0.9,0.4-1.1c0.1-0.3,0.4-0.5,0.5-0.8c0.1,0,0.2,0,0.3,0c-0.1-0.2-0.2-0.3-0.3-0.5c0.1,0,0.3,0,0.4,0.1
        c0.1-0.2,0.1-0.4,0.2-0.6c0.3,0,0.3-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.5-0.5c0-0.1,0.1-0.3,0.1-0.4l0.2,0.1c0-0.1,0-0.2,0-0.3
        c0.1-0.1,0.3-0.1,0.4-0.1c0.1,0,0.2,0,0.2,0.1c0-0.1,0-0.2,0-0.3c0,0-0.1,0-0.2,0l0-0.1c0.2-0.3,0.6-0.4,0.7-0.8
        c0.6-0.6,1.1-1.4,1.9-1.7c0,0,0-0.1,0-0.2c0.3-0.1,0.4-0.5,0.7-0.6c0.4-0.1,0.7-0.6,1.1-0.6c0.2,0,0.1-0.3,0.3-0.4
        c0.9-0.6,1.8-1.1,2.7-1.6c1-0.6,2.1-0.8,3.2-1.2c0.1,0,0.3,0.1,0.3,0.1l-0.1-0.3c0.6,0,1.1-0.2,1.7-0.2c0.4-0.3,1-0.3,1.4-0.2
        c0,0,0.1-0.1,0.1-0.2c0.2,0,0.3,0,0.5-0.1c0.3,0.1,0.7,0.1,1-0.2c0.1,0,0.4,0.1,0.5,0.1c0-0.1,0-0.2,0-0.2c0.4,0,0.8,0,1.1,0.2
        l-0.1-0.2c0.3,0.1,0.6,0.2,0.9,0.2c0-0.2,0.1-0.3,0.3-0.2c0.3,0,0.7,0.1,1,0.2l0-0.2c0.1,0.1,0.2,0.2,0.2,0.2
        c0.4-0.1,0.9-0.1,1.2,0.2c0-0.1,0-0.2,0-0.3c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0,0.2-0.1,0.2-0.1c0.4,0.2,0.8,0.3,1.2,0.4
        c0.3,0,0.5,0,0.7,0.2c0.1,0,0.2-0.1,0.3-0.1l0,0.1c0.4,0,0.8,0.2,1.2,0.4c0.2,0.1,0.4,0.3,0.7,0.2c0.6,0.6,1.6,0.7,2.2,1.4
        c1.1,0.7,2,1.6,2.8,2.6c0.4,0.3,0.5,0.7,0.8,1c0.2,0.2,0.2,0.4,0.3,0.6c-0.4-0.4-0.6-0.9-0.9-1.3c-1-0.9-1.9-1.9-3-2.7
        c-0.6-0.5-1.3-0.9-2.1-1.2c0,0,0-0.1,0-0.2c-0.5,0.2-0.8-0.4-1.3-0.3c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0-0.3,0
        c-0.3-0.3-0.8-0.2-1.2-0.4c-0.4,0-0.8-0.1-1.2-0.3c-0.2,0.1-0.4,0-0.6-0.2c-0.5,0-1,0-1.6,0c-0.4-0.2-0.8,0-1.1-0.2l0,0.2
        c-0.1,0-0.3-0.1-0.3-0.1l0,0.2c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0.1-0.5,0.1-0.7,0c-0.1,0.1-0.4,0.3-0.4-0.1c-0.1,0.3-0.5,0.1-0.7,0.2
        c-0.5,0.2-1.1,0-1.5,0.3c-0.2-0.1-0.4-0.2-0.4,0.1c-0.2,0-0.4,0-0.7,0c-0.1,0.2-0.4,0.2-0.6,0.2c-0.4,0.1-0.8,0.2-1.2,0.2
        c0,0.1-0.1,0.2-0.1,0.2c-0.4,0-0.7,0.2-1.1,0.2l0,0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0.1-0.2,0.3-0.2,0.4c-0.1-0.4-0.4,0-0.6,0
        c0,0.1,0,0.2,0,0.3l-0.2,0c-0.1-0.2-0.3-0.2-0.4,0c-0.3,0-0.5,0.2-0.4,0.5c-0.1,0-0.3-0.1-0.4-0.1l0,0.2c-0.1,0-0.4,0-0.5,0
        c0,0.1,0.1,0.2,0.1,0.2c-0.1,0.1-0.2,0.1-0.4,0.2c0,0.1,0,0.3-0.1,0.4l0-0.3l-0.4,0c0.1,0.3,0,0.4-0.3,0.3c0,0.1,0.1,0.2,0.1,0.3
        c-0.1,0-0.3-0.1-0.4-0.1c0,0.3-0.2,0.4-0.5,0.3c0.1,0.5-0.6,0.6-0.9,0.8c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0-0.6-0.1-0.5,0.2
        c-0.2,0-0.3,0.1-0.2,0.3l-0.1,0c-0.1,0.1-0.3,0.2-0.3,0.2l0.1-0.4c-0.2,0.1-0.2,0.3,0,0.5l-0.3-0.1c0.1,0.3-0.2,0.4-0.3,0.6
        c0.1,0.1,0.2,0.2,0.2,0.2c-0.1,0-0.2-0.1-0.3-0.1l0,0.2l-0.2,0c0,0.1,0.1,0.2,0.1,0.3l-0.3-0.1c0,0.1,0,0.2,0.1,0.3
        c-0.3,0.3-0.5,0.6-0.8,0.9c-0.2,0.1-0.2,0.5-0.5,0.5c0,0.2,0,0.3-0.2,0.4c0-0.1,0-0.2,0-0.3l-0.2,0c0,0.2,0,0.4,0,0.6
        c-0.1,0.1-0.3,0.2-0.4,0.3c0.1,0,0.2,0.1,0.3,0.1c-0.1,0-0.3,0.1-0.4,0.1c0.1,0.3-0.2,0.5-0.5,0.6c0,0.1,0,0.3,0,0.4l-0.3-0.1
        c0,0.1,0.1,0.3,0.2,0.4l-0.2,0c-0.1,0.3-0.2,0.5-0.4,0.7c-0.2,0.1-0.3,0.2-0.5,0.3c0.1,0.1,0.3,0.2,0.3,0.2
        c-0.5-0.1-0.3,0.6-0.4,0.9c-0.3-0.2-0.2,0.1-0.3,0.3c-0.1,0.3-0.1,0.6-0.2,1c-0.3-0.2-0.5,0.3-0.2,0.4c-0.1,0.1-0.2,0.3-0.3,0.4
        c0,0.2,0,0.4-0.1,0.6c0,0.2,0,0.6-0.3,0.5c0,0.4-0.1,0.7-0.1,1.1c-0.1-0.1-0.2-0.3-0.3-0.4c0,0.1,0,0.3,0,0.4c0.1,0,0.2,0.1,0.3,0.2
        c0,0.1-0.1,0.3-0.1,0.5c-0.1,0-0.2-0.1-0.3-0.1c0,0.1,0.1,0.2,0.1,0.3c-0.1,0.3,0.3,0.6-0.2,0.7c0,0.3,0,0.6,0,0.9
        c-0.1,0.4-0.2,0.7,0,1.1c-0.1,0-0.3-0.1-0.4-0.1c0.1,0.2,0.2,0.3,0.3,0.5l-0.3,0c0.1,0.1,0.1,0.3,0.2,0.4c-0.1,0.1-0.2,0.2-0.2,0.3
        c0.1,0.2,0.3,0.3,0.1,0.5c0.1,2,0.3,4,0.9,5.9c0,0.6,0.4,1,0.6,1.6c0.2,0.7,0.5,1.5,1,2c0.1-0.3-0.1-0.5-0.3-0.6
        c-0.1-0.3-0.1-0.6-0.3-0.8c0.1-0.4,0.1-0.8,0.2-1.3c-0.2-0.5-0.1-1-0.3-1.4c0.1-0.4,0.3-0.7,0.2-1.1c-0.2-0.5,0.1-0.9,0-1.4
        c0.1-0.9,0.2-1.7,0.6-2.5c0.1-0.6,0.2-1.1,0.5-1.6c0.3-0.5,0.4-1,0.8-1.5c-0.2,0.8-0.5,1.5-0.6,2.3c0.1,0.4,0.2,1-0.1,1.2
        c0.1,0,0.3,0.1,0.4,0.1c-0.2,0.1-0.3,0.4-0.4,0.6l0.2,0c0,0.1,0,0.2,0,0.2c-0.1,0-0.2,0.1-0.3,0.1c0.2,0.1,0.2,0.4,0.2,0.6
        c0.2,0.3,0.1,0.6,0,1c0.1,0,0.2,0,0.3,0c-0.1,0.3,0.2,0.5,0.3,0.8c-0.1,0-0.3,0-0.4-0.1c0.2,0.3,0.4,0.7,0.5,1
        c0.3,0,0.1,0.3,0.1,0.4c-0.2,0.2,0.1,0.4,0.2,0.5c0,0.1,0.1,0.2,0.1,0.3c-0.1,0-0.2,0.1-0.3,0.1c0.2,0.1,0.4,0.3,0.6,0.5l0.1-0.2
        c0,0.3-0.6,0.2-0.4,0.7c0.1,0,0.3-0.1,0.3-0.1c-0.2,0.2,0,0.5,0.1,0.7c0.1,0,0.3,0,0.3,0c-0.1,0.1-0.2,0.2-0.3,0.3
        c0.2,0.1,0.4,0.1,0.5,0.2c-0.1,0.1-0.2,0.2-0.2,0.3c0.1,0.2,0.3,0.4,0.2,0.7l0.2,0c0-0.1-0.1-0.3-0.1-0.4c0.2,0.1,0.3,0.3,0.4,0.5
        c0,0,0.1-0.1,0.2-0.2c0.1,0.3,0.2,0.5,0.3,0.8c0.1,0.2,0.2,0.4,0.2,0.6c0.1,0.1,0.3,0.2,0.4,0.3c0.1-0.1,0.2-0.2,0.2-0.3
        c0,0.3,0.2,0.5,0.5,0.6c0,0.1-0.1,0.2-0.1,0.2c0.2,0.2,0.4,0.3,0.7,0.2c-0.1,0.2-0.4,0.3-0.6,0.4c0.3,0,0.6,0.1,1,0
        c-0.3,0.5,0.3,0.4,0.5,0.4c-0.2,0-0.5,0.2-0.2,0.3c0.5-0.1,0.6,0.4,1,0.6c0.1,0.3,0.5,0,0.7,0c0.3,0.1,0.1,0.4,0.1,0.6
        c0.4,0,1-0.2,1.3,0.1c0-0.1,0.1-0.3,0.1-0.3c0.2,0.1,0.3,0.3,0.1,0.5c0.2-0.1,0.5-0.1,0.7,0c0-0.2,0.2-0.3,0.4-0.3
        c0,0.1,0,0.2,0,0.3c0.4-0.2,0.9,0,1.3,0.1c0-0.1,0-0.2,0-0.2c0.2,0.1,0.4,0.2,0.6,0.4c0.1-0.3,0.4-0.3,0.6-0.3
        c-0.1,0.1-0.1,0.3-0.2,0.5c0.2,0.1,0.3-0.2,0.4-0.3c0.2,0,0.3,0,0.5,0c0.1,0,0.2,0.1,0.3,0.2c-0.1-0.3,0-0.4,0.3-0.4
        c0,0.2,0.1,0.3,0.2,0.5c0-0.1-0.1-0.4-0.1-0.5c0.2,0.1,0.7,0.2,0.5-0.2c0.3,0.1,0.7,0.1,0.9-0.1c0.1,0.1,0.2,0.2,0.3,0.2
        c0-0.1,0.1-0.3,0.1-0.4c0.2-0.1,0.5-0.1,0.7,0c0.2-0.4,0.6-0.4,0.9-0.4c0.1,0,0.3,0.1,0.4,0.1c-0.1-0.1-0.2-0.3-0.3-0.4
        c0.7-0.1,1.3-0.5,1.9-0.7c0.1,0,0.2,0,0.3,0c0-0.1,0.1-0.2,0.2-0.3c0.3,0,0.9,0,0.7-0.5c0.2,0,0.5-0.1,0.7-0.1
        c0.3-0.6,1-0.6,1.6-0.9c0.1-0.1,0.1-0.3,0.2-0.4c0.2,0,0.5,0,0.6-0.2c0.1-0.2,0.3-0.3,0.5-0.2c0,0,0.1-0.1,0.1-0.2
        c0.2-0.1,0.5-0.1,0.6-0.3c0.2-0.3,0.5-0.3,0.9-0.4c0.2-0.5,0.8-0.6,1.2-0.8c0-0.1,0.1-0.2,0.1-0.3c0.5-0.3,1-0.5,1.5-0.7
        c0-0.1,0.1-0.3,0.1-0.3c0.2,0,0.4-0.1,0.7-0.1c0.1-0.2,0.3-0.4,0.5-0.6c0-0.2,0-0.4,0.2-0.4c0.4,0,0.4-0.7,0.8-0.7
        c-0.1,0.1-0.2,0.3-0.3,0.5c0.6,0,0.9-0.5,1.2-0.8c0.6-0.3,1.3-0.5,1.8-1c0.3-0.3,0.7-0.4,1.1-0.6c0,0.1,0.1,0.2,0.2,0.3
        c0.1,0,0.2-0.1,0.3-0.1c0,0.1,0.1,0.2,0.1,0.2l0.2-0.1l0,0.4l-0.3-0.1c0.1,0.1,0.3,0.2,0.4,0.2c-0.1,0-0.2,0.1-0.3,0.1
        c0.1,0.3,0,0.5-0.1,0.7c0.2-0.2,0.3-0.5,0.6-0.3c0-0.1,0-0.3,0-0.3c0.1,0,0.2,0.1,0.3,0.1c-0.1-0.4,0.2-0.3,0.5-0.4
        c0.1-0.3,0.2-0.6,0.4-0.8c-0.1-0.3-0.2-0.6,0-0.9c0.3-0.4,0.3-1.1,0.9-1.2c0-0.1-0.1-0.2-0.2-0.3c0.1-0.3,0.4-0.3,0.6-0.3
        c0-0.2,0.1-0.4,0.2-0.5c0.1,0,0.4,0,0.5,0c-0.1,0.2-0.2,0.2-0.5,0.2c0.1,0.4,0.4,0.2,0.7,0.1c-0.1-0.4,0.3-0.2,0.4,0
        c0.1,0.2,0.4,0,0.6,0c0,0.1,0.1,0.3,0.2,0.5c0-0.1,0.1-0.2,0.1-0.3c0.2,0.1,0.4,0.2,0.7,0.1c0,0.2,0.1,0.3,0.2,0.4
        c0-0.1-0.1-0.3-0.1-0.4c0.2,0,0.4,0,0.5,0c0.4-0.3,0.8-0.7,1.2-1c0-0.3,0.3-0.6,0.4-0.8c0.2,0,0.5,0,0.7,0c0-0.5,0.2-0.9,0.7-1.1
        c-0.1-0.3,0-0.6,0.3-0.7c0.1,0.1,0.1,0.2,0.2,0.3c0.1-0.3,0.2-0.7,0.4-0.9c-0.2,0.6,0.3,0.3,0.5,0.2c0-0.1-0.1-0.2-0.1-0.3
        c0.3-0.1,0.7,0.1,0.9-0.2c0.1-0.1,0-0.3,0-0.5c0.1,0.1,0.2,0.2,0.2,0.4c0.2-0.1,0.4-0.2,0.5-0.4c0,0,0.1,0,0.1,0.1
        c0,0.1-0.1,0.3-0.2,0.3c0.1,0,0.3,0.1,0.4,0.1c0-0.2-0.1-0.4-0.1-0.5c0.2,0,0.4-0.1,0.6-0.2c0,0.1,0,0.2,0,0.3
        c0.1,0,0.2-0.1,0.2-0.1c0.2,0.1,0.4,0.1,0.4-0.1c0-0.4,0.4,0.1,0.6-0.2c0.1-0.1,0.2-0.3,0.3-0.4c0,0.1,0,0.3-0.1,0.4
        c0.2-0.1,0.4-0.3,0.4-0.6c0.6-0.1,1.2-0.4,1.7-0.8c0,0.1,0.1,0.2,0.1,0.3c0.2-0.1,0.3-0.3,0.5-0.3c0-0.1,0.1-0.3,0.1-0.3
        c0.5,0.3,0.9-0.6,1.5-0.4c0-0.1,0.1-0.2,0.2-0.3c0.4-0.1,0.9,0,1-0.4c0.3,0,0.7,0.1,0.9-0.2c0.1-0.3,0.4-0.1,0.6-0.1
        c0-0.1,0.1-0.3,0.1-0.4c0.4-0.1,0.7-0.2,1.1-0.1c0-0.1-0.1-0.2-0.2-0.3c0.2,0,0.4-0.1,0.6,0c0.1-0.1,0.3-0.2,0.4-0.2
        c0.2-0.3,0.6-0.3,1-0.3c0.3-0.5,1-0.6,1.5-0.3c0-0.2,0.1-0.3,0.2-0.5c0.2,0,0.5-0.1,0.7,0c0.3-0.1,0.5-0.6,0.8-0.2
        c0.2-0.2,0.4-0.2,0.6-0.1c0-0.1,0-0.2,0-0.3c0.1,0,0.3,0,0.4,0c0-0.3,0.1-0.6,0.2-0.8c-0.2-0.4,0-0.8,0.1-1.1c0-0.4,0.1-0.7,0.1-1.1
        c0.1-0.1,0.2-0.2,0.2-0.3c-0.2-0.5,0.3-0.9,0.1-1.4c0.3-0.5,0.1-1.2,0.6-1.6c0-0.1,0-0.3,0-0.4c0.3-0.5,0.3-1.1,0.6-1.5
        c0.2-0.8,0.1-1.7,0.7-2.3c0.2-0.2,0.3-0.4,0.4-0.6c-0.2-0.6,0.3-1.2,0.6-1.7c0.4-0.3,0.4-0.9,0.8-1.2c0-0.4,0.2-0.7,0.5-0.9
        c0.3-0.9,1.2-1.4,1.5-2.3c0.3-0.4,0.6-0.8,1-1.1c0.2-0.2,0.2-0.6,0.4-0.9c0.3-0.3,0.7-0.6,0.9-1.1c0.2-0.4,0.8-0.6,0.9-1
        c0.3-0.9,1.1-1.4,1.7-2c0.4-0.2,0.5-0.7,0.9-0.9c0.1-0.5,0.6-0.6,0.9-1c0.5-1,1.5-1.5,2.2-2.3c0.4-0.7,1.1-1,1.6-1.6
        c0.3-0.4,0.8-0.7,1-1.1c0.2-0.2,0.6-0.3,0.7-0.7c0.8-0.7,1.8-1.3,2.5-2.2c0.4-0.3,0.7-0.7,1.2-0.9c0.1-0.3,0.5-0.3,0.7-0.5
        c0.7-0.7,1.4-1.3,2.2-1.9c0.4-0.2,0.8-0.3,0.9-0.7c0.1,0,0.2-0.1,0.3-0.1c0.8-1,1.9-1.7,3-2.4c0.2-0.3,0.4-0.5,0.7-0.7
        c0.2-0.4,0.6-0.6,1-0.6c0-0.1,0-0.3,0-0.4c0.4-0.1,0.8-0.2,1-0.6c0.6-0.4,1.2-0.8,1.7-1.3c0.1,0,0.2-0.1,0.3-0.1
        c0.2-0.5,0.7-0.6,1.1-0.9c0.3-0.4,0.9-0.6,1.3-1c0.2-0.3,0.4-0.5,0.8-0.6c0.4,0,0.2-0.6,0.6-0.6c0.3,0,0.5-0.3,0.6-0.5
        c0.2-0.1,0.4-0.2,0.6-0.3c0.9-0.9,1.9-1.6,2.8-2.5c0.4-0.2,0.6-0.6,1-0.8c0.3-0.1,0.4-0.4,0.7-0.5c0.7-0.4,1.1-1.2,1.8-1.5
        c0.2-0.3,0.5-0.6,0.8-0.7c0.2-0.3,0.5-0.6,0.9-0.6c0-0.5,0.6-0.5,0.8-0.9c0.1-0.4,0.6-0.5,0.8-0.8c0.1-0.2,0.3-0.3,0.5-0.4
        c0.4-0.5,0.7-1.2,1.4-1.3c0-0.1,0-0.2,0-0.3c0.2-0.1,0.5-0.2,0.7-0.1c0.1-0.5,0.6-0.7,0.9-1.1c0.6-0.5,1.1-1.1,1.7-1.6
        c0.5-0.4,0.8-0.9,1.3-1.3c0.4-0.6,0.9-1.3,1.5-1.8c0.4-0.9,1.1-1.6,1.6-2.5c0,0.1-0.1,0.3-0.2,0.4c0,0.4-0.3,0.8-0.4,1.1
        c-0.4,0.8-0.2,1.6-0.1,2.4c0.1,0,0.2-0.1,0.3-0.2c0,0.2,0.1,0.3,0.1,0.5l0.2-0.1c-0.1,0.1-0.1,0.3-0.2,0.4c0.3-0.3,0.7,0.1,0.8,0.4
        c0.1,0,0.3,0,0.4,0.1c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.3,0.1,0.4,0.2c0.1-0.1,0.2-0.2,0.3-0.2c0,0.5,0.3,0,0.5-0.1
        c0.5-0.1,0.8-0.6,1.2-0.9c0.1-0.2,0.1-0.5,0.4-0.6c0.5-0.3,0.3-1,0.7-1.4c0-0.3,0.1-1-0.3-1c0.1-0.5-0.6-0.8-0.6-1.3
        c0.1-0.5-0.3-1,0-1.5c-0.1-0.2-0.1-0.4-0.2-0.5c0.1-0.1,0.2-0.2,0.3-0.3c0-0.1-0.1-0.2-0.2-0.3c0-0.1,0.1-0.2,0.1-0.3
        c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0.1-0.2,0.2-0.2c-0.2-0.5,0-1.1-0.1-1.6c0.3,0.2,0.3,0.6,0.5,0.9c0.1,0.3,0.2,0.5,0.4,0.8
        c0.1,0,0.3,0.1,0.4,0.1c-0.1,0-0.3,0.1-0.4,0.2l0.1,0.2c0.2-0.2,0.3,0.2,0.4,0.3c0.1,0.2,0.3,0.3,0.4,0.4c0.3-0.1,0.4,0.3,0.3,0.5
        c0.2,0,0.4-0.1,0.6-0.2c0.2,0.4-0.3,0.4-0.6,0.3c0.2,0.2,0.4,0.4,0.7,0.5c0-0.3,0.1-0.4,0.3-0.2c-0.1,0.2-0.4,0.6,0,0.4
        c0-0.1,0.1-0.2,0.1-0.2c0.2,0.1,0.4,0.2,0.7,0.2c0,0.1,0,0.2,0,0.3c0.1,0,0.3,0.1,0.4,0.2c-0.1,0.2-0.1,0.3-0.2,0.4
        c0.3-0.1,0.6-0.2,0.8-0.4c-0.1,0.1-0.2,0.3-0.2,0.4c0.1,0.2,0.4,0,0.6,0l-0.1,0.2c0.3,0.1,0.5,0.3,0.8,0.3c-0.1,0.3,0.1,0.3,0.4,0.3
        l-0.3-0.4c0.1,0,0.3,0.1,0.5,0.1c0,0.1,0,0.2,0,0.3c0.4-0.1,0.5,0.4,0.9,0.1c0,0.1-0.1,0.3-0.2,0.4c0.2,0.1,0.3,0.1,0.5,0.2
        c-0.1-0.2-0.1-0.3-0.2-0.5c0.2,0.1,0.5,0.3,0.7,0.5c0-0.1,0-0.2,0.1-0.3c0.2,0.2,0.4,0.4,0.6,0.6c-0.2,0-0.5-0.1-0.7-0.1
        c0.1,0.1,0.2,0.2,0.3,0.3c0.3-0.1,0.5,0.2,0.7,0.3c-0.1-0.2-0.1-0.4-0.2-0.7c0.3,0,0.4,0.1,0.4,0.4c0.4-0.3,0.5,0.2,0.8,0.4
        c0.3,0.3,0.5-0.5,0.8-0.3l-0.3,0.3c0.2,0,0.3,0.1,0.5,0.2c0.1-0.1,0.2-0.1,0.3-0.2c0,0.1,0.1,0.3,0.1,0.4c0.1,0,0.2,0,0.3,0
        c0-0.1-0.1-0.3-0.1-0.4c0.3,0.1,0.5,0.5,0.8,0.2c0,0.2,0,0.4,0.2,0.5c0.2-0.6,0.8-0.2,1.2-0.1c0,0.2-0.2,0.4,0.1,0.6
        c0.1-0.2,0.2-0.4,0.3-0.6c0.3,0,0.6,0.1,0.6,0.5c-0.3-0.1-0.8-0.2-0.5,0.2c0.2,0,0.3,0,0.5-0.1c0.2,0,0.3,0,0.5,0
        c0-0.1,0.1-0.4,0.1-0.5c-0.6-0.1-0.6-0.7-0.8-1.2c-0.4-0.5-0.6-1.1-0.8-1.7c-0.3-0.6-1.1-1.2-0.9-2c-0.4-0.3-0.4-0.7-0.5-1.1
        c-0.6-0.1-0.6-0.7-0.8-1.1c-0.1-0.3-0.4-0.6-0.4-0.9c-0.4-0.3-0.4-0.7-0.3-1.1c-0.7-0.6-1.1-1.5-1.3-2.4c-0.5-0.4-0.6-0.9-0.7-1.5
        c-0.3-0.3-0.6-0.8-0.8-1.2c0.1-0.2,0.2-0.5,0.5-0.6c-0.5-0.2-1.1-0.4-1.1-1c-0.2-0.1-0.5-0.2-0.6-0.5c0-0.4,0-0.8-0.2-1.1
        c-0.3-0.4,0.2-0.8,0.4-0.9c-0.2-0.1-0.5-0.1-0.6-0.3c0.5-0.2,0.3-0.8,0.2-1.1c0.1,0,0.2,0,0.3,0c-0.1-0.2-0.2-0.3-0.2-0.5
        c0.1,0,0.3,0.1,0.4,0.1c-0.1-0.2-0.2-0.4-0.3-0.5c-0.6,0.1-0.7-0.6-0.8-1c-0.2,0.1-0.3,0.3-0.5,0.4c-0.2-0.5-0.8-0.8-0.7-1.4
        c0.1-0.4-0.3-0.7,0-1.1c-0.1-0.2-0.3-0.3-0.2-0.5c0.1,0,0.2-0.1,0.3-0.1c-0.1-0.1-0.3-0.2-0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2
        c0-0.5-0.2-0.9-0.5-1.2c-0.2-0.1-0.3-0.1-0.5-0.2c0.1,0,0.3,0,0.4,0l0-0.3c-0.2-0.1-0.4,0-0.6,0.1c-0.2,0.1-0.7,0.2-0.7-0.2
        c0.1,0,0.3,0.1,0.4,0.1c0-0.5-0.6-0.5-0.9-0.6c-0.3,0-0.6-0.3-0.7-0.6c-0.1-0.4,0.7-0.6,0.5-1c-0.1-0.1-0.2-0.2-0.2-0.2v0.3
        c0,0.1,0.1,0.2,0.1,0.3l-0.3,0l0.1-0.2c-0.3-0.1-0.2-0.3,0-0.4c-0.1-0.1-0.4-0.2-0.4-0.3c-0.1-0.8-1-1.2-0.9-2.1
        c-0.2-0.2-0.4-0.4-0.6-0.7c0-0.3,0-0.6,0.1-0.9c-0.1-0.6-0.8-1.1-0.6-1.7c-0.3-0.5-0.7-1-0.5-1.6c-0.4-0.1-0.8-0.4-0.6-0.9
        c-0.2-0.2-0.3-0.5-0.4-0.8c-0.2-0.4-0.5-0.8-0.5-1.3c-0.4-0.3-0.3-0.7-0.3-1.1c-0.3-0.4-0.7-0.9-0.7-1.5c-0.2-0.2-0.4-0.5-0.3-0.8
        c-0.5,0.4-0.1,1.1-0.4,1.6l0.1,0c0,0.1-0.1,0.4-0.1,0.5c0,0.1-0.1,0.2-0.2,0.2c0.1,0.5,0,1,0,1.5c0,0.2,0,0.4-0.1,0.6
        c0,0.5,0.2,1,0,1.5c0.5,0,0,0.4,0.1,0.7c0.1,0.1,0.1,0.2,0.2,0.3c0,0.3,0,0.6-0.1,0.8c0.3,0.3-0.5,0.7,0.1,0.8
        c-0.2,0.6,0.3,1.1,0.2,1.6c0.1,0.4,0.5,0.7,0.2,1.1c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.5,0.1,0.9,0.1,1.4c0.1,0.2,0.2,0.4,0.2,0.6
        c0,0.2,0.2,0.2,0.3,0.4c0,0.3-0.1,0.5-0.1,0.8c0.3,0.4,0.6,1,0.3,1.5c0.1,0.2,0.2,0.5,0.4,0.6c0.1,0.1,0.2,0.2,0.2,0.3
        c-0.3,0.3,0,0.7,0.4,0.8c-0.1,0.1-0.2,0.3-0.2,0.4c0.1,0,0.3-0.1,0.4-0.2l0,0.6l-0.2-0.2c-0.2,0.3,0,0.4,0.3,0.6
        c0.2,0.3-0.2,0.6-0.3,0.9c0.1,0,0.2,0.1,0.3,0.1c0,0.2-0.1,0.5-0.1,0.7c0.1,0.2,0.1,0.5,0.1,0.7c0.4,0.4,0,1,0.2,1.4
        c-0.2,0.4,0.2,0.8-0.1,1.2c0,0.4,0.3,0.6,0.3,1c-0.1-0.2-0.2-0.3-0.3-0.5c-0.3,0.4-0.2,0.9,0,1.4c-0.3,0.3-0.3,0.7-0.3,1
        c-0.2,0.3-0.3,0.7-0.2,1.1c-0.3,0.2-0.6,0.5-0.4,0.8c0.1,0.3-0.4,0.4-0.3,0.7c0.1,0.3-0.1,0.4-0.2,0.6c0.1,0.3-0.1,0.5-0.2,0.8
        c0,0,0,0.1,0.1,0.2c-0.1,0.2-0.3,0.5-0.5,0.6c-0.1,0.2-0.2,0.3-0.3,0.5c0,0.1,0,0.2,0,0.4c-0.2,0.2-0.3,0.4-0.4,0.6
        c0.4,0.2-0.2,0.3-0.3,0.5c-0.1,0.4-0.4,0.7-0.6,1.1c-0.2,0.5-0.7,0.9-1.1,1.4c-0.4,0.4-0.5,1-0.8,1.4c-0.1,0.5-0.5,0.8-0.6,1.3
        c-0.5,0.3-0.5,1-1,1.3c-0.3,0.2-0.5,0.5-0.7,0.7c0,0.1,0,0.3,0,0.4c-0.3,0.2-0.5,0.4-0.8,0.6c-0.1,0.6-0.6,0.8-0.9,1.2
        c-0.5,0.6-0.9,1.3-1.6,1.7c-0.6,0.7-1.2,1.4-1.8,2c-0.3,0.3-0.7,0.4-0.9,0.8c-0.2,0.5-0.8,0.6-1.1,1.1c-0.7,0.3-1,1.2-1.7,1.6
        c-0.6,0.3-0.9,1-1.5,1.3c-0.1,0.4-0.5,0.5-0.8,0.8c-0.4,0.4-0.7,0.8-1.2,1.1c-0.6,0.7-1.4,1.3-2.3,1.8c-0.4,0.3-0.7,0.8-1.1,1
        c-0.1,0.1-0.2,0.3-0.4,0.4c-0.4,0-0.5,0.4-0.8,0.6c-0.2,0.1-0.6,0.2-0.6,0.6c-0.4,0-0.6,0.3-0.7,0.6c-0.4-0.1-0.6,0.3-0.8,0.6
        c-0.3,0.2-0.6,0.3-0.8,0.5c-0.2,0.4-0.8,0.3-1,0.8c-0.9,0.5-1.6,1.3-2.4,1.8c-0.2,0.2-0.3,0.4-0.5,0.6c-0.4-0.1-0.5,0.4-0.8,0.5
        c-0.4,0.2-0.7,0.5-1.1,0.8c-0.2,0-0.3-0.1-0.5-0.1c0.1,0.1,0.3,0.4,0,0.5c-0.4,0.2-0.9,0.3-1.2,0.8c-0.9,0.6-1.7,1.5-2.7,2
        c0,0.1-0.1,0.2-0.1,0.2c-0.2,0.1-0.5,0.1-0.6,0.3c-0.8,0.8-1.6,1.4-2.5,2c-0.2,0-0.4,0.2-0.5,0.4c-0.1,0.4-0.6,0.3-0.8,0.6
        c-0.1,0.2-0.4,0.4-0.6,0.6c-0.5,0.3-0.9,0.8-1.5,1.1c-0.6,0.6-1.2,1.1-1.9,1.5c0,0.5-0.4,0.7-0.9,0.7c0.2,0.7-0.8,0.6-0.9,1.2
        c-0.6,0.4-1.1,1-1.7,1.4c-0.3,0.3-0.4,0.7-0.7,1c-0.6,0.5-1.1,1.2-1.7,1.8c-0.5,0.3-0.8,0.9-1.4,1.2c-0.3,0.6-0.8,0.9-1.1,1.4
        c-0.2,0.4-0.6,0.4-0.8,0.8c-0.2,0.4-0.7,0.5-0.9,0.9c-0.7,0.6-0.9,1.6-1.5,2.2c-0.5,0.4-0.8,0.9-1.3,1.2c-0.3,0.2-0.2,0.5-0.4,0.7
        c-0.6,0.3-0.8,1-1.4,1.3c-0.4,0.2-0.4,0.7-0.8,1c-0.2,0.2-0.3,0.5-0.5,0.8c-0.2,0.2-0.5,0.3-0.6,0.5c-0.4,0.6-0.9,1.1-1.4,1.6
        c-0.2,0.5-0.6,0.9-0.9,1.4c-0.4,0.7-1,1.3-1.4,2c-0.2,0.3-0.5,0.5-0.5,0.9c-0.3,0.4-0.5,0.8-0.6,1.3c-0.2,0.3-0.4,0.6-0.5,0.9
        c0.1,0.5-0.4,0.8-0.3,1.3c-0.2,0.1-0.2,0.2-0.2,0.4c0,0-0.1,0-0.2,0c0,0.1,0,0.2,0,0.3l-0.2,0.1c0,0.2,0,0.4,0,0.6l-0.1,0
        c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1,0.7-0.2,1.5-0.5,2.2c-0.2,0.4-0.2,0.8-0.3,1.2c-0.2,0.3-0.1,0.6-0.1,1
        c-0.1-0.3-0.1-0.6-0.1-0.9c0.1-0.4,0.1-0.7,0.2-1.1c0.3-0.8,0.3-1.7,0.4-2.6c0.3-0.3,0.1-0.8,0.4-1.1c0-0.3,0-0.6,0.1-0.8
        c0.2-0.5,0.3-1,0.5-1.5c0-0.2,0-0.5,0.1-0.7l-0.2-0.2c0.1,0,0.3-0.1,0.3-0.1c0-0.1-0.1-0.2-0.2-0.3c0.5,0,0.3-0.6,0.5-0.9
        c0.2-0.4,0.2-1,0.5-1.3c0.1-0.4,0.4-0.7,0.4-1.1c0.1,0,0.3,0.1,0.4,0.1c-0.1-0.1-0.2-0.2-0.2-0.3c0.3-0.3,0.2-0.7,0.4-1.1
        c0.1-0.2,0.1-0.5,0.4-0.4c0-0.2,0-0.4,0-0.5c0.1,0.1,0.2,0.2,0.2,0.2c0-0.5,0.2-0.9,0.4-1.4c0,0.1,0.1,0.2,0.2,0.3c0,0,0.1,0,0.1,0
        c-0.3-0.2,0-0.4,0.1-0.6c0.1-0.5,0.3-1.1,0.8-1.4c0-0.1-0.1-0.4-0.1-0.5c0.1,0,0.2,0.1,0.3,0.1c0.1-0.5,0.4-0.9,0.5-1.4
        c0.1-0.3,0.3-0.5,0.6-0.5c-0.3-0.3,0.2-0.4,0.2-0.7c0.1-0.3,0.1-0.8,0.6-0.7c0-0.2-0.2-0.5,0-0.6c0.2-0.2,0.3-0.4,0.4-0.7
        c0.1-0.1,0.2-0.3,0.4-0.4c-0.1-0.3,0.2-0.5,0.5-0.6c0-0.2,0-0.5,0.2-0.6c0.3-0.1,0.2-0.4,0.3-0.6c0.2,0,0.5,0.1,0.5-0.2
        c0-0.2,0-0.3,0-0.5c0.3,0.1,0.3-0.3,0.6-0.4c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.3,0,0.4,0c0.1-0.1,0.2-0.2,0.2-0.3
        c0.1-0.3,0.3-0.6,0.6-0.7c0,0.1,0,0.3,0,0.4c0.1-0.1,0.1-0.2,0.2-0.2c-0.4-0.2,0-0.7,0.2-0.9c0.1,0.1,0.5,0.3,0.3,0
        c-0.2-0.2,0.1-0.4,0.1-0.6c-0.4,0.4-1,0.5-1.5,0.8c-0.2-0.1-0.3-0.1-0.5-0.2c0,0.1,0.1,0.3,0.1,0.4c-0.1,0-0.2,0.1-0.3,0.2
        c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2,0.2-0.1,0.7-0.4,0.5c-0.3-0.1-0.4,0.3-0.6,0.4c-0.2,0-0.6-0.1-0.6,0.2c-0.4,0.3-1,0.4-1.5,0.5
        c0,0.1,0,0.3,0,0.3c0-0.1-0.1-0.2-0.1-0.3c-0.3,0.1-0.5,0.2-0.8,0.2c0,0.1,0.1,0.2,0.2,0.3c-0.3-0.1-0.5-0.3-0.8-0.3
        c0,0.3-0.2,0.5-0.4,0.6c0.1-0.3,0.2-0.6-0.2-0.7c0,0.2,0,0.4-0.1,0.7c-0.2-0.1-0.3-0.2-0.5-0.3c0.1,0.5-0.5-0.2-0.5,0.3
        c0.3-0.1,0.4,0.2,0.2,0.3c-0.1-0.2-0.4-0.2-0.6-0.3c0,0.1,0,0.2,0,0.2c-0.3,0.1-0.7,0.1-1,0.3c-0.2,0.1-0.5,0-0.7,0.2
        c-0.3,0-0.6,0.1-0.9,0.2c-0.1-0.2-0.2-0.3-0.4-0.3c0.1,0.1,0.2,0.3,0.2,0.4c-0.4,0-0.8-0.1-1.2-0.1c0,0.2-0.1,0.4-0.3,0.6l0.1-0.3
        c-0.1,0-0.2-0.1-0.3-0.1c-0.3,0.2-0.6,0.4-0.9,0.5c0-0.1,0.1-0.4,0.2-0.5c-0.4-0.2-0.7,0.3-1.1,0.4c0-0.1-0.1-0.2-0.1-0.2
        c-0.3,0.1-0.5,0.1-0.8,0.2c-0.2-0.1-0.4-0.2-0.6-0.3c0,0.1,0.1,0.3,0.1,0.5c-0.1-0.1-0.3-0.2-0.4-0.2c-0.3,0-0.5,0-0.8,0
        c0.1,0.1,0.2,0.3,0.3,0.4c-0.3,0.1-0.5-0.3-0.8-0.2c-0.5,0.1-1-0.2-1.4,0c-0.3,0-0.5-0.1-0.8-0.1l0,0.2c-0.4-0.3-0.8,0-1.1,0.2
        c0-0.1-0.1-0.2-0.2-0.3c-0.7,0.2-1.5,0-2.3,0c-0.1,0-0.2,0.1-0.2,0.1l-0.1-0.2c-0.5-0.1-1-0.1-1.5-0.2c-0.2,0-0.5,0.1-0.7-0.2
        c-0.5,0.2-1,0-1.4-0.3c-0.3,0.2-0.6,0.2-0.8,0c-0.3-0.2-0.6-0.1-0.9-0.1c-0.2-0.1-0.6-0.6-0.5,0c-0.3-0.3-0.7-0.1-0.9-0.5
        c-0.1,0-0.2,0.1-0.2,0.2c-0.2-0.2-0.5-0.2-0.7-0.3c-0.2,0.1-0.4,0.1-0.7,0.2c-0.2-0.1-0.3-0.3-0.4-0.4c-0.2,0-0.4-0.1-0.6-0.2
        c-0.4-0.1-0.9-0.1-1.3-0.3c-0.4-0.4-1-0.3-1.4-0.7c-0.6,0.2-1.2-0.3-1.7-0.5c-0.1,0-0.2,0-0.3,0.1c0.1-0.3-0.2-0.4-0.4-0.5
        c-0.5,0-0.9-0.3-1.4-0.4c-0.2,0-0.3-0.3-0.4-0.4c-0.5,0-0.8-0.5-1.3-0.5c0,0.1,0,0.2-0.1,0.3c-0.1-0.2-0.1-0.3-0.1-0.5
        c-0.1-0.2-0.3-0.3-0.5-0.4c-0.2-0.2-0.6-0.5-0.8-0.1c-0.1-0.2-0.2-0.4-0.3-0.6c0,0,0.1-0.1,0.1-0.2c-0.2-0.2-0.4-0.4-0.7-0.5
        c-0.4,0.3-0.7-0.3-1.1-0.3c0,0,0.1-0.1,0.1-0.2c-0.2-0.2-0.5-0.3-0.7-0.5c-0.5-0.1-0.6-0.6-0.7-0.9c-0.3-0.2-0.7-0.2-0.8-0.5
        c-0.2-0.3-0.6-0.5-0.9-0.7c-0.1-0.2-0.1-0.5-0.5-0.5c0-0.1,0.1-0.2,0.2-0.3c-0.1-0.1-0.3-0.2-0.5-0.2c0,0,0.1-0.2,0.1-0.2
        c-0.1,0-0.3,0-0.3,0c-0.4-0.3-0.5-0.7-0.4-1.1c-0.6,0.2-0.7-0.5-1-0.8c-0.1,0-0.3,0-0.4,0c0-0.2,0.1-0.5-0.2-0.6
        c-0.2-0.6-0.6-1.3-1.2-1.5c0-0.1,0.1-0.4,0.1-0.5c-0.4,0.3-0.4-0.3-0.6-0.4c-0.1-0.7-0.9-1.1-0.9-1.8c-0.1,0-0.2-0.1-0.2-0.2
        c-0.1-0.2-0.1-0.5-0.2-0.7c0-0.3-0.2-0.4-0.4-0.5c-0.2-0.4-0.3-0.8-0.4-1.2c0-0.2-0.3-0.2-0.4-0.4c0-0.1,0.1-0.2,0.1-0.3
        c-0.5-0.7-0.4-1.6-0.8-2.3c-0.1-0.2,0-0.4,0-0.6c-0.3-0.5-0.3-1.1-0.4-1.7c-0.2-0.7-0.1-1.5-0.2-2.2c-0.1-0.2,0.1-0.4,0.1-0.5
        c-0.1-0.2-0.3-0.5-0.2-0.7c0.1-0.4-0.1-0.8,0-1.1c0.2-0.6-0.3-1.3,0.3-1.7c-0.3-0.7,0.1-1.4,0-2.1c0.3-0.3,0.3-0.6,0.1-1
        c0.1-0.6,0.4-1.1,0.3-1.8c0.3-0.8,0.4-1.7,0.8-2.5c0.2-1.2,0.9-2.3,1.2-3.4c0.1-0.2,0.3-0.4,0.5-0.6c0-0.1-0.1-0.2-0.1-0.3
        c0.3-0.3,0.4-0.7,0.6-1.1c0.1,0,0.2-0.1,0.3-0.1c-0.4-0.3,0.3-0.4,0.2-0.8c0.1,0,0.2-0.1,0.3-0.1c0-0.2,0.1-0.4,0.2-0.5
        c0.4-0.4,0.7-0.8,1-1.2c0-0.3,0.1-0.4,0.4-0.5c-0.1-0.3,0.1-0.3,0.3-0.2c0-0.1,0-0.3,0-0.3c0.3,0,0.4-0.3,0.6-0.4
        c0.1-0.2,0.1-0.4,0.2-0.6c0.1,0,0.3,0,0.4,0c0.1-0.2,0.2-0.5,0.3-0.7c0.1-0.1,0.2-0.2,0.2-0.4c0.4-0.1,0.5-0.5,0.6-0.9
        c0-0.4,0.1-0.9,0.1-1.3c0.1-0.4,0.2-0.8,0.4-1.1c-0.1-0.4,0.1-0.8,0.3-1.2c0.2-0.4,0.2-0.8,0.4-1.2c0.3-0.4,0.6-0.9,0.5-1.4
        c0.1-0.4,0.4-0.7,0.4-1.1c0.4-0.4,0.1-1,0.7-1.3c0-0.5,0.4-1,0.5-1.5c0.2-0.2,0.4-0.3,0.6-0.5c0.1-0.6,0.3-1.3,0.8-1.8
        c0.2-0.6,0.4-1.2,1-1.6c0.1-0.6,0.7-0.8,0.9-1.3c0.1-0.3,0.5-0.3,0.6-0.6c0.1-0.3,0.1-0.6,0.4-0.8c0.4-0.2,0.4-0.8,0.9-0.9
        c0.1-0.4,0.4-0.7,0.8-0.9c0.4-0.6,1.1-1,1.5-1.6c0.4-0.2,0.6-0.8,1.1-1c0.2-0.2,0.4-0.4,0.6-0.6c0.4,0,0.5-0.3,0.7-0.5
        c0.3-0.3,0.6-0.6,1-0.7c0.4-0.1,0.5-0.6,1-0.6c0.4-0.4,0.9-0.7,1.3-1.1c0.3-0.3,0.7-0.3,1-0.6c0.3-0.3,0.8-0.5,1.2-0.3
        c0-0.1-0.1-0.3-0.2-0.3c0.2-0.1,0.5-0.1,0.7-0.2c0,0.1-0.1,0.3-0.1,0.5c0.1-0.1,0.2-0.2,0.4-0.3c-0.2-0.2-0.2-0.3,0-0.5
        c0.3,0.3,0.7,0.1,1-0.2l-0.3-0.1l0-0.1c0,0,0.2,0,0.2,0c0.2-0.2,0.5-0.2,0.7-0.3c0.2-0.2,0.3-0.5,0.6-0.3c0.4-0.1,0.8-0.6,1.3-0.4
        c0.1-0.1,0.1-0.3,0.2-0.4c0.2,0,0.5,0.1,0.7-0.1c0.3-0.4,0.9-0.4,1.4-0.4c0-0.1-0.1-0.2-0.2-0.3c0.2,0,0.4-0.1,0.6,0
        c-0.1,0.1-0.2,0.3-0.3,0.4c0.2,0,0.6-0.2,0.5-0.5c0.2,0,0.3-0.2,0.5-0.3c0.2,0,0.3,0,0.5,0.1c0.1-0.2,0.3-0.5,0.5-0.6l-0.1,0.4
        c0.3-0.1,0.7-0.1,0.7-0.5c0.1,0.1,0.2,0.2,0.3,0.3c0.2-0.1,0.5-0.1,0.7,0c0-0.1,0-0.2,0-0.3c0.2,0,0.3-0.1,0.4-0.2
        c0.3,0.1,0.5,0.1,0.8,0.1c-0.3-0.6-0.6-1.1-1-1.6c0-0.2,0.1-0.3,0.1-0.5c-0.3-0.1-0.7-0.4-0.6-0.8c-0.1,0-0.2,0-0.2,0
        c0.1-0.4-0.3-0.6-0.3-1c-0.1-0.2,0-0.6-0.3-0.6c0-0.3-0.3-0.5-0.4-0.8c-0.1-0.4-0.5-0.8-0.3-1.3c-0.1-0.2-0.2-0.4-0.3-0.6
        c0-0.2-0.1-0.3-0.1-0.5l0.2,0.1c-0.2-0.3-0.4-0.6-0.5-0.9c-0.1-0.1-0.3-0.2-0.4-0.4c0.1,0.1,0.4,0.2,0.5,0.3
        c-0.3-0.5,0.2-1.2-0.3-1.6c0-0.3,0.4-0.6,0.2-0.9c-0.2-0.3-0.4-0.5-0.4-0.8c0.1-0.3,0.1-0.5,0-0.8c0.1-0.5,0.1-1,0.2-1.4
        c-0.1,0-0.2,0-0.3,0c0.1-0.1,0.2-0.2,0.3-0.3c-0.2-0.3-0.2-0.6-0.2-0.9c0-0.2,0-0.5,0-0.7c0.2-0.3,0.3-0.7,0.3-1.1
        c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.4,0.4-0.7,0.3-1.1c0.1-0.2,0.2-0.3,0.3-0.5c-0.2-0.4,0-0.8,0.1-1.2c0-0.1-0.1-0.2-0.1-0.2
        c0.1-0.2,0.2-0.4,0.1-0.6c-0.2-0.5,0.5-1,0.1-1.4c0,0,0.1-0.1,0.2-0.2c0.1-0.8,0.5-1.5,0.7-2.2c0-0.1-0.1-0.2-0.1-0.3
        c0.2-0.2,0.4-0.4,0.4-0.7c-0.1-0.3,0.1-0.5,0.2-0.6c0-0.4,0.3-0.8,0.2-1.2c0.1-0.2,0.2-0.4,0.4-0.6c0-0.1,0-0.3,0-0.4
        c0.3-0.6,0.5-1.2,0.8-1.8c0.1-0.3,0.1-0.5,0.3-0.7c0.3-0.3,0.2-0.7,0.4-1c0.1-0.2,0.2-0.4,0.2-0.7c0.3-0.5,0.5-1.1,1-1.4
        c-0.3-0.6,0.3-0.9,0.8-1c0-0.2,0-0.4,0.1-0.6c0.2-0.4,0.1-0.9,0.6-1c0.1-0.3,0.3-0.6,0.6-0.7c-0.1-0.8,0.6-1.3,1.1-1.9
        c0.2-0.3,0.3-0.7,0.5-0.9c0.3-0.3,0.5-0.7,0.9-1c0.1-0.7,0.7-1.2,1.1-1.7c0.1-0.1,0.3-0.2,0.5-0.3c0.4-0.7,0.7-1.5,1.5-1.8
        c0.4-0.1,0.2-0.7,0.6-0.9c0.2-0.1,0.5-0.2,0.6-0.4c0.1-0.2,0.2-0.4,0.4-0.5c0.4-0.1,0.2-0.6,0.5-0.8c0.2-0.2,0.5-0.4,0.7-0.6
        c0.1-0.2,0.4-0.2,0.6-0.3c0.1-0.2,0.1-0.4,0.1-0.6c0.6-0.5,1.1-1,1.7-1.5c0.1-0.1,0.4-0.1,0.5-0.2c0.1-0.2,0.2-0.5,0.4-0.7
        c0.4-0.3,0.7-0.8,1.1-1c0.7-0.6,1.5-1.4,2.4-1.8c0.5-0.5,1-0.9,1.6-1.3c0.6-0.5,1.2-1.1,2-1.4c0.2-0.3,0.6-0.7,1-0.6
        c0.1-0.2,0.1-0.5,0.3-0.5c0.4-0.1,0.7-0.6,1.1-0.7c0.5-0.2,0.8-0.8,1.3-1c0.4-0.1,0.5-0.6,1-0.7c0.3-0.1,0.5-0.4,0.8-0.6
        c0.5-0.3,0.9-0.7,1.4-0.8c0.1-0.4,0.6-0.6,1-0.7c0.2-0.5,1-0.3,1.2-0.9c0.1,0,0.2,0,0.3-0.1c0.1-0.2,0.3-0.4,0.5-0.5
        c0.5-0.4,0.9-0.9,1.5-1.2c0.4-0.2,0.6-0.6,1-0.9c0.1,0,0.3,0,0.4,0c0.1-0.2,0.1-0.3,0.2-0.5c0.4,0,0.9-0.1,1-0.5
        c0.3,0,0.6-0.1,0.7-0.4c0.6,0,1-0.6,1.5-0.7c0.3-0.2,0.6-0.4,1-0.6c0.1,0,0.2,0.1,0.3,0.1c0.1-0.1,0.2-0.3,0.3-0.4
        c0.3,0,0.5-0.1,0.8-0.2c0.6-0.2,1.2-0.4,1.8-0.5c0.3,0.1,0.6,0.3,0.8,0c0.5,0,1-0.1,1.4,0.2c0.1,0,0.2-0.1,0.3-0.1
        c0.5,0.2,1,0.3,1.5,0.5c0.3,0.2,0.7,0.1,1,0.1c0,0,0,0.1,0,0.2c0.4,0,0.8,0.1,1.2,0.1c0,0.1,0,0.2,0,0.3c0-0.1,0.1-0.2,0.2-0.3
        c0.2,0,0.3,0.3,0.5,0.3c0.1,0,0.3,0,0.4,0c0.2,0.1,0.4,0.4,0.7,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0,0.2-0.1,0.4-0.1l-0.1,0.2
        c0.3-0.1,0.8,0.1,0.3,0.4c0.3-0.2,0.6,0,0.7,0.3c0.1,0,0.2-0.1,0.2-0.2c0.1,0.1,0.3,0.2,0.5,0.2c-0.2,0.1-0.3,0.2-0.5,0.3
        c0.1,0.1,0.2,0.2,0.3,0.3c0.1-0.2,0.3-0.3,0.5-0.5c-0.1,0.7,0.8,0.8,1.2,1.2c0.1,0,0.2-0.1,0.3-0.2c0,0.1,0.1,0.2,0.1,0.3l-0.2-0.1
        c0,0.1-0.1,0.3-0.2,0.4c0.3,0,0.6,0,0.9,0c-0.2,0.2,0,0.5,0.1,0.7c0.1,0.1,0.3,0.1,0.4,0.2c0,0.1,0,0.2,0.1,0.3c0.1,0,0.3,0,0.4,0.1
        c-0.1,0.3,0.2,0.3,0.4,0.4c0,0.1,0.1,0.2,0.1,0.3c0.1,0,0.3,0.2,0.4,0.1c0.1-0.2,0.3,0,0.4,0.1c-0.1,0-0.3,0-0.4,0.1
        c-0.1,0.4,0.5,0.2,0.7,0.3c0.1,0.2,0.3,0.3,0.5,0.5c0,0.1,0.1,0.2,0.2,0.3c0-0.1,0-0.3,0.1-0.4c0.2,0.2,0.2,0.4,0.2,0.7
        c0.1-0.1,0.1-0.2,0.2-0.3c0.1,0.2,0.1,0.5,0.2,0.7c0.1,0,0.3-0.1,0.4-0.1c0,0-0.1,0.1-0.1,0.2c0,0.1,0.1,0.3,0.1,0.4
        c0.1-0.1,0.2-0.2,0.3-0.4c0,0.3,0.2,0.5,0.4,0.7c-0.3,0.4,0.2,0.6,0.5,0.5c-0.2,0.3,0.1,0.5,0.3,0.7c0-0.1,0-0.2,0-0.3
        c0.1,0,0.2,0,0.2,0.1c0,0.3,0.2,0.7,0.5,0.4c0.2,0.2,0.4,0.4,0.6,0.4c0.3,0,0.3,0.4,0.5,0.6c0.1,0,0.2,0,0.3,0
        c0.1,0.2,0.2,0.4,0.3,0.6c0.1-0.1,0.2-0.1,0.3-0.2c0.5,0.2,1,0.5,1.3,0.9c0.1,0,0.2,0.1,0.2,0.1c-0.1-0.2-0.2-0.4-0.3-0.5
        c0.5,0.1,0.7,0.8,1.2,0.8c0.4-0.1,0.8,0.1,1.1,0.3c0.4-0.2,0.7,0.1,1,0.4c0.3,0.1,0.5,0.3,0.8,0.1c0.2,0.1,0.3,0.1,0.5,0.1
        c0,0.1,0.1,0.2,0.2,0.3c0.1-0.1,0.2-0.2,0.3-0.2c0,0.1,0.1,0.2,0.2,0.3c0.1,0,0.2-0.1,0.3-0.1c0.1,0.3,0.4,0.3,0.6,0.4
        c0.2-0.2,0.6-0.2,0.6,0.1c0.5-0.2,1,0.1,1.6,0.1c0.4-0.1,0.8,0.4,1.2,0.2c-0.2,0.6,0.3,0.1,0.3-0.1c0.1,0.1,0.3,0.3,0.5,0.4
        c0.3-0.2,0.6-0.3,0.8-0.1c0.1,0,0.2-0.2,0.3-0.2c0.3,0.1,0.7,0,1-0.1c0.2,0.1,0.3,0,0.4-0.1c0.2,0,0.4,0,0.5,0
        c0.1,0.3,0.4,0.4,0.7,0.6c-0.1-0.2,0-0.4,0.1-0.6c0,0-0.1,0-0.2,0c0,0.3-0.1,0.3-0.3,0.3c-0.1-0.2-0.1-0.3,0.1-0.4
        c0.3-0.1,0.7-0.1,1-0.2c-0.1,0.3,0,0.4,0.2,0.5c0-0.2-0.1-0.4-0.1-0.6c0.1,0,0.3,0,0.4,0l-0.1,0.4c0.1-0.1,0.2-0.1,0.3-0.2
        c-0.1-0.2-0.2-0.3-0.3-0.5c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2-0.1,0.3-0.1c0,0.1,0,0.2,0,0.3c0.1,0,0.2,0,0.2,0c0-0.1-0.1-0.3-0.1-0.4
        c0.1,0,0.3,0.1,0.4,0.1c-0.1-0.1-0.2-0.2-0.3-0.2c0.3,0.1,0.8-0.2,0.9,0.2c0.2-0.1,0.4,0,0.5,0.1c0,0,0-0.1,0-0.1
        c-0.4-0.4-1.1-0.6-1.6-0.4c-0.5,0.3-1,0.3-1.6,0.5c-0.5,0-1,0.2-1.6,0.1c-0.6,0.1-1.1-0.1-1.7,0c0,0.3,0.1,0.4,0.4,0.4
        c-0.1,0.1-0.2,0.1-0.3,0.2c0-0.2-0.1-0.4-0.2-0.6c-0.4,0-0.8,0.2-1.1,0c0,0.1,0,0.2,0,0.3c-0.1-0.1-0.3-0.3-0.4-0.4
        c-0.3,0-0.6,0.1-0.8,0c-0.3-0.2-0.7,0-1,0c-0.6-0.3-1.3-0.2-1.9-0.5c0,0.1,0.1,0.2,0.1,0.3l-0.2,0l0.1-0.2c-0.5-0.2-1-0.3-1.4-0.6
        c-0.2,0-0.5,0-0.7,0c-0.5-0.6-1.5-0.2-1.9-0.9c-0.4-0.2-0.8-0.2-1.1-0.5c-0.4-0.3-1-0.5-1.2-1.1c-0.1,0-0.2-0.1-0.3-0.1
        c-0.3-0.3-0.5-0.6-0.6-1c-0.5-0.1-0.7-0.6-1-0.9c-0.2-0.3-0.4-0.5-0.5-0.8c-1-0.8-1.3-2.3-2.5-3.1c-0.4-0.4-0.7-0.8-1.1-1.2
        c0.5,0.2,0.9,0.6,1.4,0.9c0.1,0.1,0.3,0.2,0.4,0.3c0.2,0,0.4,0.1,0.4,0.3c0.1,0,0.3,0,0.4-0.1c0,0.1,0,0.2,0,0.3
        c0.3-0.1,0.3,0.1,0.4,0.4c0.1,0,0.2,0,0.3-0.1c0.2,0.2,0.4,0.4,0.6,0.6c0-0.1,0-0.2,0-0.3c0.1,0.1,0.3,0.3,0.4,0.5
        c0.1,0,0.2,0,0.3-0.1c0,0.1,0,0.4,0,0.6c0.1-0.1,0.2-0.3,0.3-0.4c0.2,0.1,0.3,0.2,0.2,0.4l0.3,0.1c-0.3,0.1-0.4,0.4-0.3,0.7
        c0.1-0.2,0.2-0.4,0.3-0.6c0.2,0,0.4-0.1,0.6-0.1l-0.1,0.1c0.3,0.2,0.6,0.4,1,0.3c0,0.1,0.1,0.2,0.2,0.3c0.2,0,0.4,0.1,0.6,0.1
        l-0.1,0.2c0.2-0.1,0.3-0.2,0.5-0.3c0.5,0.3,0.7,1,0.8,1.6c0.3,0,0.3,0.2,0.2,0.4c0.2-0.2,0.1-0.5-0.1-0.6c0-0.2-0.1-0.4-0.1-0.7
        c0.1,0,0.3,0,0.4,0c0.2,0.3,0.5,0.4,0.8,0.4c0,0.2,0.1,0.4,0.2,0.5c0-0.3,0.2-0.3,0.5-0.4c-0.1,0.2-0.1,0.3-0.2,0.5
        c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.2,0.1,0.2,0.2c0.1,0,0.3-0.1,0.4-0.1c-0.1,0.2-0.2,0.3-0.3,0.5l0.4,0c0-0.1,0-0.3,0-0.4
        c0.3,0.1,0.4,0.4,0.7,0.6c0-0.1,0.1-0.2,0.2-0.2l0,0.3c0.3-0.1,0.7-0.2,1.1-0.3c0,0.1,0,0.3-0.1,0.5c0.2,0,0.6-0.4,0.7,0
        c-0.2,0-0.4,0-0.6,0c0,0.1,0,0.3,0,0.3c0.3-0.1,0.6-0.2,0.8-0.4c0.2,0,0.3,0,0.5,0c-0.1,0.2-0.1,0.3-0.2,0.5
        c0.2-0.1,0.4-0.2,0.4-0.4c0.2,0.1,0.3,0.2,0.4,0.4l-0.2,0.2c0,0.1,0,0.2,0,0.2c0.2-0.1,0.3-0.3,0.5-0.4c0.3,0,0.6,0.1,0.8,0.2
        l-0.1-0.2c0.3,0,0.6,0.1,0.8-0.1c0.2,0.1,0.3,0.3,0.4,0.5c0.2,0,0.3-0.1,0.5-0.1c0.1,0.1,0.3,0.1,0.4-0.1c0.3,0.1,0.7,0,1.1-0.1
        c0.3,0,0.6,0,0.9,0c0.1,0.1,0.2,0.2,0.3,0.3c0.2-0.1,0.4-0.2,0.6-0.4c0.2,0,0.4,0.1,0.6,0c0.3-0.1,0.5-0.2,0.7-0.4
        c0.1,0,0.3,0,0.5,0.1c0.2,0.3,0.4,0,0.5-0.2c0,0.1,0,0.4,0,0.5c0.2-0.1,0.4-0.2,0.5-0.4c0.2,0.1,0.5,0.1,0.7,0.2
        c0.1-0.1,0.2-0.3,0.3-0.4c0.2,0,0.4,0,0.6,0c0-0.3,0.3-0.2,0.5-0.1c0.1,0,0.2-0.1,0.2-0.2c0.1,0.1,0.3,0.2,0.3,0.2
        c0.1-0.1,0-0.6,0.3-0.4c0.3,0.2,0.6,0,0.9,0.1c0.2,0.1,0.2-0.2,0.3-0.3c0.2,0,0.4,0.1,0.6,0.1c0.2-0.1,0.5-0.6,0.6-0.1
        c0,0,0.1-0.1,0.2-0.1c0-0.1,0-0.3,0.1-0.3c0.1,0,0.3,0,0.4,0c0.1,0.3-0.1,0.4-0.3,0.4c0.1,0.4,0.3,0,0.5-0.1c0.2,0,0.5-0.1,0.7-0.1
        c0.2-0.5,0.9-0.8,1.3-0.3c-0.3-0.3,0.1-0.8,0.2-0.3c0,0,0.1-0.1,0.2-0.1c0,0.1,0,0.3,0,0.4c0.4-0.2,0.1-0.7,0.3-1
        c0,0.1,0.1,0.2,0.1,0.2l-0.1,0c0,0.2,0.1,0.3,0.1,0.5c0.3-0.1,0.6-0.2,0.9-0.3c0-0.1,0.1-0.2,0.1-0.3c0.1,0,0.4,0.1,0.5,0.2
        c0-0.1,0-0.3-0.1-0.4c0.1,0.1,0.3,0.3,0.4,0.4c0-0.1,0-0.3,0-0.4c0.2,0,0.4,0.1,0.6,0.2c0,0,0-0.2,0-0.2c-0.2,0-0.3-0.1-0.2-0.3
        c0.3,0.1,0.7,0,1-0.1c0,0.1,0.1,0.2,0.1,0.2c0.3-0.2,0.6-0.1,0.9-0.1c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2,0-0.7,0-0.4-0.3
        c0.1,0,0.2,0.1,0.2,0.1c0.2-0.2,0.4-0.3,0.6-0.3c-0.1,0.1-0.1,0.3-0.2,0.4c0.2-0.1,0.5-0.1,0.6-0.4c0.2,0,0.6,0.3,0.7,0
        c0-0.1-0.1-0.3-0.1-0.3c0.2-0.1,0.6,0.1,0.7-0.2c0.3,0.2,0.4,0.1,0.5-0.2c0.1,0.1,0.3,0.1,0.4,0.2c0.1-0.1,0.2-0.3,0.3-0.3
        c0.1,0.1,0.2,0.2,0.3,0.3c-0.1-0.2-0.2-0.4-0.3-0.5c0.4-0.1,0.4,0.2,0.5,0.4c0.2,0,0.3,0,0.5,0.1l0-0.1c-0.2,0-0.4-0.1-0.5-0.3
        c0.4-0.2,0.8,0,1.2,0c0.1-0.1,0.2-0.2,0.2-0.3c0.2,0.1,0.3,0.2,0.5,0.4c0-0.1,0.1-0.2,0.1-0.3c-0.3,0-0.5-0.1-0.6-0.4
        c0.2,0,0.3-0.1,0.5-0.1c0.4,0.3,1.1,0.1,1.3-0.3c0.1-0.4,0.5-0.2,0.7,0c0.1-0.2,0.1-0.3,0.2-0.5c0.1,0.1,0.2,0.3,0.2,0.4l0.2,0
        c0-0.1,0-0.3,0-0.4c0-0.1-0.1-0.2-0.2-0.3c0.1,0,0.3,0,0.4,0c0,0.1-0.1,0.2-0.2,0.3c0.1,0.1,0.2,0.1,0.2,0.2c0.1-0.5,0.6-0.2,0.8,0
        c0-0.1-0.1-0.2-0.1-0.3c0.5-0.1,0.6-0.9,1.1-0.4c0.2-0.4-0.6-0.4-0.2-0.6c0.2-0.1,0.2,0.2,0.3,0.3c0.2-0.1,0.4-0.3,0.6-0.4
        c-0.1,0.2-0.2,0.4-0.2,0.5c0.1,0,0.3-0.1,0.4-0.2c0-0.1,0-0.2,0-0.3c0.2,0.1,0.5,0.2,0.7,0.2c0.1-0.1,0.3-0.2,0.4-0.2
        c-0.1-0.1-0.1-0.2-0.2-0.4c0.2-0.1,0.3-0.1,0.5-0.2c-0.1,0.2-0.1,0.3-0.2,0.5c0.3-0.1,0.3-0.4,0.5-0.5c0-0.1,0.1-0.3,0.1-0.4
        c0.1,0.2,0.1,0.4,0,0.6c0.1,0.1,0.2,0.2,0.4,0.3c-0.1-0.1-0.1-0.3-0.2-0.4c0.3-0.3,0.7-0.6,1.1-0.9c-0.1,0.3-0.3,0.5-0.5,0.8
        c0.5,0,0.8-0.4,0.8-0.9c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0.1-0.2,0.3-0.2,0.4c0.2-0.1,0.3-0.1,0.5-0.2c-0.1-0.2-0.2-0.4-0.2-0.7
        c0.4,0.2,0.7,0.1,1-0.1c0.1,0,0.3,0.1,0.4,0.1c0-0.2,0-0.5,0-0.7c0,0.1,0.1,0.2,0.1,0.3c0.1,0,0.3-0.1,0.4-0.1
        c0.1,0.1,0.2,0.2,0.3,0.2c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.2-0.1,0.3-0.3,0.3-0.6c0.3,0,0.4-0.4,0.7-0.2
        c0.3,0.2,0.5-0.1,0.7-0.2c0.2-0.2,0.4,0,0.7,0.1c0.3-0.1-0.2-0.5-0.4-0.3c0.3-0.2,0.7-0.1,0.9-0.4c0,0,0.1-0.1,0.2-0.1
        c0.2,0.3,0.4,0.2,0.7,0.2c0.2,0,0.3,0,0.5,0c-0.1-0.1-0.2-0.2-0.3-0.3c0.2,0,0.4-0.2,0.5-0.4c0.1,0,0.2,0.1,0.2,0.2
        c0.2-0.4,0.6-0.5,1-0.5c0.2-0.2,0.4-0.4,0.6-0.5c0,0.1-0.1,0.3-0.1,0.5c0.1,0,0.3,0,0.4,0c-0.1-0.3,0.1-0.4,0.3-0.6
        c0,0.1-0.1,0.4-0.1,0.5c0.2-0.1,0.3-0.2,0.5-0.4c-0.1,0.6,0.4,0.3,0.7,0.1c0,0,0.1,0.1,0.1,0.2c0.1,0,0.3,0,0.4-0.1c0,0,0-0.1,0-0.1
        l-0.3,0.1c0-0.1,0.1-0.4,0.2-0.5c-0.3-0.2-0.4,0.4-0.7,0.3c-0.2-0.1,0.1-0.3,0.2-0.3c0-0.1,0-0.2,0-0.3c0.1,0,0.2,0.1,0.2,0.2
        c0.1,0,0.4-0.1,0.6-0.1c0,0.1,0,0.2,0,0.3l-0.1,0c0,0.1,0.1,0.2,0.1,0.2c0.1-0.2,0.2-0.3,0.3-0.5c0.3,0,0.6,0,0.8,0
        c0-0.2-0.1-0.3-0.3-0.4c0-0.1,0.1-0.2,0.1-0.3c0.2,0.1,0.6,0,0.5,0.4c0.3-0.2,0.5-0.3,0.9-0.4c0,0.1-0.1,0.3-0.1,0.4
        c0.1-0.1,0.2-0.1,0.4-0.2c0-0.2-0.1-0.4,0.1-0.5c0.5,0,1,0.1,1.3-0.4c0.1,0.1,0.2,0.2,0.3,0.3c-0.3,0-0.4,0-0.5,0.3
        c0.2,0,0.5,0,0.7-0.3c-0.1-0.2-0.3-0.3-0.4-0.5c0.4,0,0.8,0.1,1.1,0.2c0-0.2,0.1-0.3,0.1-0.5c0.2-0.3,0.2-0.7,0.3-1.1
        c0.2-0.7,0.3-1.5,0.4-2.3c0.1-0.2,0.1-0.5,0-0.7c0.1-0.5,0.3-0.9,0.2-1.4c0.2-0.6,0.1-1.2,0.4-1.7c0.1,0.1,0.2,0.3,0.2,0.4
        c0,0.3,0,0.7,0,1c0.1-0.4,0-0.9,0.2-1.3c0.1-1.3,0-2.7,0.2-4c0-1.1,0-2.2,0.1-3.3c0-0.2,0-0.4,0-0.6c0.1-0.3,0.1-0.7,0.1-1
        c0.1,1,0,2.1,0,3.2c0,0.6,0.1,1.3,0,1.9c-0.1,0.2,0,0.4,0.1,0.5c0,0.1-0.1,0.2-0.1,0.3c0.2,0.7-0.1,1.4,0.1,2.1
        c-0.1,0.5,0,1,0.1,1.6c-0.1,0.2,0,0.4,0,0.6c0,0.3,0.1,0.6,0.1,0.9c-0.3,0.8,0.1,1.5,0.1,2.3c0,0.1,0.1,0.3,0.1,0.4l0.2-0.1
        c-0.1,0.2-0.1,0.3-0.2,0.5c0.1,0,0.2-0.1,0.3-0.1c0,0.1-0.1,0.3-0.1,0.4c0.1,0.1,0.2,0.2,0.3,0.2c-0.1,0-0.2,0.1-0.3,0.1
        c0.1,0,0.2,0.1,0.3,0.2l0.1,0c0.2,0,0.2,0.1,0.2,0.2l-0.1,0.1c0,0,0,0.1,0,0.1c0.3-0.1,0.5-0.3,0.8-0.3c0.3,0.1,0.5,0.3,0.7,0.4
        c0-0.1,0.1-0.3,0.1-0.3c-0.1,0-0.3-0.1-0.4-0.1c0.2-0.1,0.6-0.4,0.7-0.1l-0.3,0.1c0,0.4,0.5,0.2,0.5-0.1c0.2,0,0.5,0,0.7,0.2
        l0.1-0.3l-0.4,0.1c0.1-0.4,0.6-0.2,0.8-0.4l0.1,0.2c0.2-0.1,0.3-0.2,0.4-0.3c0.3,0.1,0,0.3-0.1,0.5c0.4-0.4,1-0.4,1.5-0.6
        c0.1,0.1,0.2,0.1,0.3,0.2c0.3-0.1,0.6-0.2,0.8-0.2c0-0.1,0.1-0.2,0.2-0.3c0.2-0.7,0-1.4,0.2-2.1c0-0.3,0-0.6,0-0.9
        c0.1,0.2,0.1,0.5,0.1,0.8c-0.2,0.7,0,1.5-0.1,2.3c0.3,0,0.5,0,0.8,0c0,0.1,0,0.2,0,0.3c0.2-0.2,0.4-0.4,0.7-0.5
        c0,0.1-0.1,0.4-0.1,0.5c0.2-0.1,0.3-0.3,0.4-0.4c0.5,0,0,0.4,0,0.5c0.2-0.1,0.3-0.3,0.5-0.4c0,0,0.1,0.1,0.2,0.1
        c0-0.1,0.1-0.3,0.1-0.4c0.1,0.4,0.4,0.2,0.7,0.1c0.3-0.2,0.6-0.1,0.8,0c0.2,0,0.5-0.2,0.8-0.1c0.4,0.2,0.8-0.2,1.2-0.1
        c-0.1,0.1-0.1,0.3-0.2,0.4c0.1,0,0.3,0.1,0.4,0.1c-0.1-0.1-0.2-0.3-0.2-0.4c0.5,0.1,1-0.1,1.6,0c0,0,0-0.1,0-0.2c0.1,0,0.3,0,0.5,0
        c0-1,0.6-1.9,0.7-2.9c0-0.1,0.1-0.2,0.2-0.2c0-0.3,0.2-0.6,0.1-0.9c0.1-0.4,0.1-0.9,0.3-1.4c0-0.1-0.1-0.3,0-0.4
        c0.3-0.4,0-0.9,0.3-1.3c0-0.5,0.2-1,0.2-1.5c0-0.7,0.2-1.3,0.2-2c-0.1-0.3,0.2-0.5,0.3-0.7l-0.4,0c0.1-0.1,0.3-0.2,0.2-0.4
        c0-0.3,0.1-0.5,0.1-0.8c0-0.8,0-1.6,0.1-2.3c0.1-0.5,0.1-1.1,0.1-1.6c0-0.9,0-1.9,0.1-2.8c0.1-0.6,0-1.2,0.1-1.9
        c0.2-0.6-0.1-1.3,0-1.9c0.1-0.2,0.1-0.4,0-0.7c-0.1-0.4,0-0.8,0.1-1.2c0.2,0.2,0.1,0.4,0,0.6c0,0.3,0.1,0.7,0.1,1.1
        c-0.1,0.6,0.1,1.3,0,1.9c-0.1,0.8,0,1.6,0,2.4c-0.2,0.4,0,0.9,0,1.3c0,0.7,0,1.3,0,2c-0.1,1.3,0.1,2.6,0,3.9c-0.1,0-0.2,0.1-0.2,0.2
        c0.1,0.1,0.2,0.2,0.2,0.2c0,0.6-0.1,1.1,0,1.7c0.1,0.3-0.1,0.7,0,1c0.1,0.6-0.1,1.3,0.2,1.9c-0.1,0.4,0.1,0.8-0.1,1.1
        c0.1,0.3,0.2,0.5,0.2,0.8c-0.2,0.3,0,0.7,0.1,1c0,0.3-0.3,0.5-0.1,0.7c0.3,0.5,0.4,1.1,0.3,1.7c0.4,0.5,0.1,1.2,0.3,1.7
        c0.1,0,0.4,0.1,0.6,0.1c-0.1,0.1-0.2,0.2-0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0-0.1,0.1-0.3,0.1-0.3l0.3,0.2l0.2,0c0,0.1,0,0.3-0.1,0.5
        c0.1-0.2,0.2-0.4,0.2-0.5c0.1,0,0.3,0,0.4,0c0,0.1-0.1,0.2-0.1,0.3c0.4,0,0.7-0.1,1.1,0c0,0.1-0.1,0.2-0.1,0.3
        c0.3,0,0.6,0.2,0.8,0.1c0.2,0.2,0.5,0.2,0.7,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0,0.3,0,0.4,0c0.2,0.2,0.4,0.4,0.7,0.3
        c0.2-0.7,0.2-1.5,0.6-2.2c0-0.7,0.1-1.3,0.4-2c0.1-0.8,0.5-1.5,0.7-2.3c0-0.2,0-0.4,0.1-0.6c0-0.2,0-0.3,0.1-0.5
        c0-0.5,0.1-1,0.2-1.5c0-1,0-2,0.1-3c0-0.9,0.2-1.7,0.1-2.6c0-0.8,0.1-1.5,0-2.3c0.2,0.1,0.2,0.3,0.2,0.4c-0.1,1.1,0.1,2.3,0,3.4
        c-0.1,0.6,0,1.3-0.2,2c0.2,0.4,0,0.8,0.1,1.2c0,0.5,0.1,0.9,0,1.4c-0.1,0.3,0,0.5,0.1,0.8c-0.1,0.2-0.1,0.4-0.1,0.5
        c0.3,0.1,0.1,0.3,0.1,0.4c0.2,0.7-0.1,1.5,0.1,2.3c0.1,0.5,0,1.1,0.1,1.6c0,0.2,0,0.4,0,0.7c0.1,0,0.2-0.1,0.3-0.1
        c0,0.2,0,0.5-0.1,0.7c0.1,0,0.4,0,0.5,0c-0.1,0-0.2,0.1-0.3,0.2l0.2,0.4c0-0.1,0.1-0.2,0.2-0.3c0,0.2-0.1,0.4,0.1,0.5
        c0,0.3,0.1,0.5,0.3,0.7c0,0.2,0,0.3,0,0.5c0.1,0,0.2,0,0.3,0c0,0.1,0,0.3-0.1,0.3c0.1,0,0.2,0,0.2,0c-0.1,0.5,0.5,0.6,0.7,1
        c-0.4,0-0.6-0.3-0.9-0.5c-0.4-0.3-0.7-0.7-1.1-1.1c0.1-0.3-0.2-0.4-0.3-0.5c0,0.3,0,0.6,0.2,0.9c0.1,0.4,0.5,0.6,0.3,1
        c0.1,0,0.2-0.1,0.3-0.1c0,0.1,0,0.4,0,0.5l0.2-0.1c0,0.4,0.4,0.4,0.5,0.6c0.4,0.5,1.1,0.8,1.3,1.4c-0.3-0.1-0.5-0.4-0.7-0.6
        c-0.1,0.4,0.4,0.6,0.5,0.9c-0.4-0.2-0.7-0.4-1.1-0.6c-0.3-0.2-0.4-0.6-0.8-0.7c0-0.1,0-0.3,0-0.4c-0.3,0-0.2,0.5,0,0.5
        c-0.1,0.5,0.2,0.9,0.1,1.3c0.1,0,0.2-0.1,0.3-0.1c0,0.1,0,0.4,0.1,0.5l-0.2-0.1c0.5,0.2,0.2,0.8,0.6,0.9c0,0.4,0.1,0.9-0.2,1.2
        c0.1-0.1,0.3-0.2,0.4-0.4c-0.1,0.2,0,0.4,0.2,0.6l0,0.1c0.2,0.1,0.2,0.3,0.3,0.5c0.2,0.2,0.4,0.4,0.6,0.6c-0.2,0-0.4-0.1-0.6-0.3
        c-0.5-0.2-0.6-0.7-1-1c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.5-0.6-0.9-0.7-1.4c0,0-0.1,0-0.1,0c0.1,0.4,0.2,0.8,0.4,1.1
        c0,0.1,0,0.2,0,0.3l0.2-0.1c0,0.1,0,0.2-0.1,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.2,0.4,0.4,0.4,0.8l0.1,0c0.1,0.2,0.1,0.5,0.3,0.6
        c-0.1,0-0.2,0-0.3,0c0,0.1,0.1,0.3,0.1,0.3c0.1,0,0.2,0,0.3-0.1c0,0.4,0.4,0.5,0.5,0.8c-0.5,0-1-0.3-1.3-0.6c-0.1-0.4-0.2-0.1-0.4,0
        c0.2,0.3,0.4,0.6,0.3,0.9l0.2-0.1c0,0.1,0,0.2-0.1,0.3l0.2,0c0,0.1,0,0.2,0,0.3c0.1,0,0.2,0,0.3,0c0,0.2,0,0.5,0.1,0.7
        c0.4,0.3,0.9,0.2,1.2-0.1c-0.1,0.1-0.3,0.4-0.3,0.6c0.2-0.1,0.5-0.3,0.6-0.5c0.4,0.1,0.7,0,1-0.2c0.4,0,0.8-0.1,1.2-0.2
        c0-0.1,0.1-0.2,0.1-0.3c0.4-0.5,0.5-1.2,0.8-1.9c0.6-1.4,1-2.9,1.7-4.3c0.2-0.4,0.3-0.8,0.5-1.2c0.1-0.8,0.5-1.4,0.7-2.2
        c-0.3,0-0.4,0.4-0.5,0.6c-0.2,0.6-0.7,1.1-0.9,1.8c-0.2,0.4-0.3,0.9-0.7,1.2c0,0.2-0.1,0.3-0.2,0.5c-0.3,0.4-0.4,0.8-0.7,1.2
        c-0.1,0.3-0.2,0.5-0.4,0.8c0-0.2,0.1-0.3,0.1-0.5c0.2-0.5,0.4-0.9,0.5-1.4c0.3-0.3,0.2-0.9,0.5-1.2c0.1-0.8,0.2-1.5,0.5-2.3
        c0.1-0.2,0.2-0.3,0.3-0.5c0-0.1,0-0.2-0.1-0.3c0.1-0.1,0.2-0.3,0.1-0.5c0.1-0.4,0.2-0.7,0.2-1.1c0.2-0.9,0.7-1.7,0.7-2.6
        c0.1,0,0.2-0.1,0.2-0.1c0-0.1-0.1-0.2-0.1-0.2c0.4-0.7,0.3-1.5,0.6-2.2c0.3-0.7,0.2-1.4,0.4-2.1c0.1-0.4,0-0.8,0-1.3
        c0.1-0.2,0.1-0.4,0-0.7l0.2-0.1c-0.3-0.7,0-1.5,0-2.2c-0.1-0.3-0.1-0.5,0-0.8c0.1-0.2-0.2-0.8,0.2-0.8c0-1-0.1-1.9,0.1-2.9
        c0-0.4,0.1-0.8-0.1-1.2c0.2-0.2,0.2-0.5,0-0.8c0,0,0.1,0,0.2,0c0-0.4,0-0.7,0-1.1c0.1-0.8-0.1-1.5,0.1-2.3c-0.1-2.1,0.1-4.2,0-6.3
        l0.1,0c-0.1,0.7,0.2,1.4,0.1,2.2c0,0.1,0.1,0.2,0.1,0.3c-0.1,2.7,0.2,5.3,0.1,8c0.2,0.8,0.1,1.7,0.1,2.5c0.1,0.5,0.1,1,0,1.4
        c-0.1,0.5,0.1,1,0.1,1.4c0,0.7,0,1.4,0.2,2.1c0,0.3,0.1,0.7,0.1,1c0.1,0.1,0.2,0.2,0.2,0.3l-0.3,0c0,0.4,0.1,0.7,0.1,1.1
        c0,0.1,0,0.3,0,0.4c0,0.1-0.1,0.2-0.1,0.2c0,0.1,0.1,0.2,0.2,0.2c-0.1,0.2-0.1,0.5-0.1,0.8c-0.1,0.4,0.2,0.7,0.3,1
        c-0.2,0.4,0.2,0.8,0,1.2c0,0.1,0.1,0.2,0.1,0.3c-0.2,0.3,0.2,0.6,0.1,1c0,0.6,0.2,1.2,0.2,1.8c0,0.5,0.2,1,0.1,1.5
        c0,0.1,0.1,0.2,0.2,0.3c-0.1,0.5-0.3,1,0,1.4c-0.1,0.5,0.1,1,0,1.5c0.1-0.1,0.3-0.1,0.4-0.2c-0.1,0.2-0.2,0.3-0.4,0.4
        c0.1,0.5,0.8,0.7,0.6,1.2c0.1,0.1,0.2,0.2,0.4,0.2c0,0.1-0.1,0.2-0.1,0.3c0.1,0.1,0.2,0.2,0.2,0.3c-0.2,0.2-0.4,0.4,0,0.3
        c0.1,0.2,0.1,0.3,0.2,0.5c0,0.1,0,0.3,0,0.4l0.2,0c0,0.1-0.1,0.3-0.1,0.4c0.1,0.4,0.3,0.7,0.4,1.1c0,0.1-0.1,0.2-0.2,0.3
        c0.3-0.1,0.6,0,0.4,0.3c0.2,0.1,0.3,0.4,0.3,0.7c0.2-0.1,0.4-0.1,0.6,0.1c0.1-0.1,0.2-0.2,0.2-0.2c0.4,0.1,0.9,0.2,1.3,0.1
        c0.2-0.1,0.5,0.2,0.6-0.2c0.4,0.4,1.1,0.1,1.6,0.1c0.1-0.5,0.4-0.9,0.5-1.4c0.2-0.7,0.5-1.3,0.4-2c-0.1-1.2,0-2.5,0-3.7
        c0-0.9-0.1-1.9,0-2.8c0-0.3,0-0.6-0.1-0.9c0-0.2,0-0.3,0.1-0.4c-0.1,0.6,0.3,1.1,0.1,1.6c0.1,0.8,0,1.5,0.2,2.3
        c0.1,0.2,0,0.5-0.1,0.7c0.2,0.5,0,1.1,0.2,1.6c0.3-0.3,0.5-0.7,0.7-1c0.2-0.1,0.3-0.2,0.3-0.4c0.1-0.1,0.2-0.1,0.4-0.2
        c0-0.1,0-0.3,0-0.4c0.1-0.1,0.3-0.3,0.4-0.3c0-0.1,0.1-0.3,0.2-0.4c0.1,0,0.2,0.1,0.3,0.1l0-0.2c0,0,0.1-0.1,0.1-0.1
        c0-0.2,0.2-0.4,0.3-0.5l0.2,0c0-0.1,0-0.2-0.1-0.3c0.1-0.1,0.3-0.3,0.4-0.5c0.1,0,0.2,0,0.2,0c0-0.3,0.1-0.6,0.4-0.8
        c0.1-0.2,0.2-0.5,0.5-0.5c0-0.2,0.1-0.4,0.1-0.6c0.1,0,0.2,0.1,0.4,0.1c0-0.1-0.1-0.2-0.1-0.4c0.2-0.1,0.1-0.5,0.4-0.6
        c0.4,0,0.3-0.4,0.3-0.6l0.1,0c0-0.1,0-0.3,0-0.4c0.1,0,0.4,0,0.5,0.1c-0.2-0.4,0.1-0.6,0.4-0.8c-0.1,0-0.2-0.1-0.3-0.2
        c0.2-0.2,0.4-0.4,0.7-0.6c0.1-0.3,0-0.6,0.4-0.8c0-0.1,0.1-0.3,0.1-0.4c0.2,0.4,0.4,0,0.6-0.2c0-0.1-0.1-0.2-0.1-0.2
        c0.2-0.5,0.8-0.8,0.7-1.4c0.2-0.2,0.4-0.5,0.7-0.7c0-0.1-0.1-0.2-0.1-0.3c0.4-0.5,0.6-1.1,0.9-1.7c0.1-0.1,0-0.3-0.1-0.4
        c0.2-0.3,0.3-0.7,0.3-1c0.1-0.1,0.3-0.2,0.2-0.3c-0.3-0.4,0.3-0.7,0.1-1.1c0.1-0.3,0.1-0.7,0.5-0.9c-0.1-0.1-0.1-0.2-0.1-0.4
        c0.4-0.8,0.6-1.7,1-2.5c0.2-0.3,0.2-0.8,0.5-1.1c0-0.2-0.1-0.5,0.1-0.7c0.3-0.5,0.4-1.1,0.6-1.7c0.1,0,0.2-0.1,0.2-0.1
        c-0.2-0.3-0.2-0.4,0.1-0.6l0,0.3l0.1,0c0-0.1,0-0.2,0-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0-0.2-0.1-0.3-0.1-0.5c0,0-0.1,0-0.1,0
        c0,0.2,0,0.5,0,0.7l-0.3,0c0-0.2,0-0.4,0-0.6c-0.1,0-0.3,0-0.5,0c0,0.3,0,0.6-0.3,0.7c0.1,0.2,0.2,0.3,0.1,0.5
        c-0.1-0.1-0.3-0.3-0.4-0.4c0,0.1,0,0.3,0,0.4l0.2,0c0,0.1,0,0.2,0,0.2c-0.2-0.1-0.4-0.1-0.6,0.1c0.1,0,0.3,0,0.4,0
        c0,0.1,0,0.3,0,0.4c-0.5,0-0.3,0.5-0.2,0.8c-0.1,0-0.3-0.1-0.3-0.1c0.2,0.4-0.2,0.8-0.3,1.2c0,0.1,0.1,0.2,0.1,0.3l-0.2,0
        c-0.1,0.2-0.3,0.3-0.4,0.5c0.1,0,0.3,0,0.3,0c-0.2,0.3-0.4,0.6-0.5,1c-0.1,0.1-0.2,0.2-0.2,0.3c0.2,0,0.3,0,0.5,0
        c-0.4,0.2-0.5,0.5-0.4,0.9c-0.1,0-0.2-0.1-0.3-0.2c0.4,0.6-0.7,1-0.3,1.6c-0.3,0.2-0.5,0.7-0.4,1l-0.2,0c0,0.1,0.1,0.3,0.1,0.5
        c-0.1,0-0.2,0-0.3,0c-0.2,0.5-0.4,1-0.7,1.5c-0.2,0.2-0.1,0.5-0.3,0.6c0,0.1,0,0.2,0,0.3c-0.4,0.5-0.6,1.1-1,1.5
        c0.1-0.6,0.4-1.1,0.5-1.7c0.2-1,0.4-2,0.8-2.9c0.1-1,0.5-2,0.7-3.1c-0.1,0-0.2,0.1-0.3,0.1c0,0.4-0.2,0.7-0.5,1
        c-0.1,0.6-0.6,1-0.8,1.6c0-0.4,0.2-0.8,0.5-1.1c0.1-0.2,0.1-0.4,0.2-0.7c0,0,0.1,0,0.1,0c0.1-0.3,0.2-0.6,0.3-0.9
        c0.4-0.3,0.2-1.1,0.8-1.1c-0.2-0.4,0-0.6,0.3-0.9c0.2-0.7,0.4-1.3,0.7-2c0-0.2,0.3-0.4,0.2-0.7c0.3-0.9,0.4-1.9,0.8-2.8
        c0.2-0.4,0.3-0.8,0.4-1.2c0.1-0.8,0.4-1.6,0.7-2.3c0-0.4,0.1-0.8,0.4-1l-0.2,0c0.1-0.4,0.2-0.8,0.4-1.2c-0.1,0-0.2-0.1-0.2-0.2
        c0.3-0.3,0.4-0.8,0.4-1.2c0-0.2,0.1-0.3,0.2-0.5c-0.5-0.3,0.5-0.8,0-1.1c0.1-0.1,0.2-0.3,0.2-0.4l-0.2,0c0.1-0.4,0.1-0.7,0.2-1.1
        l0.1,0c0.1-0.2-0.1-0.6,0.3-0.5c-0.2,0-0.4,0.1-0.6,0.2c0-0.2,0.2-0.4,0.4-0.5c0.1-0.1,0.1-0.3,0.2-0.4c-0.1-0.1-0.1-0.1-0.2-0.2
        c0,0.1,0,0.2,0,0.3c-0.1,0-0.4,0-0.5,0c0.2-0.4,0.5-0.7,0.7-1.1c-0.2,0.1-0.4,0.3-0.6,0.5c0-0.4,0.1-0.8,0.5-0.9l0-0.1
        c-0.1,0-0.3,0.1-0.4,0.1c0-0.1,0.1-0.4,0.2-0.6c-0.1,0-0.2-0.1-0.2-0.1c0.1-0.3,0.1-0.6,0-0.9c0.1,0,0.2,0.1,0.3,0.1
        c0.1-0.2,0-0.4-0.2-0.4c0.1-0.2,0.2-0.4,0.3-0.7l-0.3,0.1c0-0.1,0-0.3,0.1-0.4l-0.3,0c0,0.3,0,0.6,0,1c-0.3,0.1-0.1,0.4-0.2,0.5
        c0,0.3-0.1,0.5-0.1,0.8c0,0.2,0,0.4-0.1,0.6c0,0.3-0.1,0.6-0.1,0.9c0,0-0.1,0.1-0.2,0.2c0.1,0.4-0.1,0.7-0.2,1
        c0.2,0.2-0.2,0.4,0,0.7c-0.1,0.2-0.2,0.4-0.2,0.6c-0.1,0.2-0.2,0.5-0.1,0.7c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1,0.6-0.3,1.2-0.4,1.8
        c-0.4,1.9-1,3.7-1.9,5.4c-0.1,0.2-0.1,0.3-0.2,0.5c-0.1,0.4-0.2,0.9-0.5,1.3c0-0.2,0.1-0.3,0.2-0.5c0.1-0.4,0.2-0.7,0.3-1.1
        c0.2-0.5,0.4-0.9,0.6-1.4c0-0.3,0.1-0.5,0.2-0.8c0.4-1,0.7-2.1,0.8-3.2c0.1-0.4,0.1-0.8,0.2-1.1c0-0.6,0.3-1.2,0.4-1.8
        c0-0.4,0.1-0.8,0.4-1.1c-0.3-0.6,0.3-1.2,0.2-1.9c0.1-0.5,0.3-1,0.3-1.5c0.1-0.6,0.2-1.2,0.4-1.7c0.1-0.6,0.1-1.3,0.3-1.9
        c0-0.6,0.2-1.3,0.3-1.9c0.3-0.7,0.3-1.4,0.2-2.2c0-0.2,0.1-0.3,0.3-0.4c-0.1-0.2-0.1-0.3,0-0.4c0-0.3,0-0.5,0-0.8
        c-0.1-0.1-0.1-0.2-0.2-0.3l0.2,0c0-0.2-0.1-0.4-0.2-0.5c0.1-0.2,0.2-0.3,0.3-0.5l-0.3,0.1c0-0.1,0-0.3,0-0.4
        c0.1-0.1,0.2-0.3,0.1-0.5c0.3-0.2,0-0.4,0-0.7c0.1-0.1,0.2-0.3,0.3-0.4l-0.3,0c0-0.1,0-0.3,0-0.4l0.2,0c-0.3-0.3-0.2-0.7-0.2-1
        c0,0,0.1,0,0.2,0c0-0.3-0.1-0.7,0-1c-0.3-0.1-0.2-0.2,0-0.1l0-0.3c-0.3,0-0.3-0.1,0-0.2c0-0.1,0-0.3,0-0.4c-0.1,0-0.2-0.1-0.3-0.1
        l0.2,0c0-0.6-0.1-1.3,0.1-1.9c-0.1-0.2-0.1-0.5,0-0.7c-0.1-0.5-0.1-1-0.1-1.5c0.5-1.2-0.1-2.5,0.3-3.6c-0.2-0.4-0.2-0.9,0-1.3
        c-0.2-0.6,0-1.1,0-1.7c-0.1-0.4-0.1-0.9,0-1.3c0-1,0.1-2,0-3c0-0.2,0.1-0.5,0.1-0.7c-0.1-1.3,0.1-2.6-0.1-3.8
        C254.9,7.7,254.8,5.9,254.9,4.1 M255.7,27.9C255.8,28,255.8,28,255.7,27.9 M254.6,31.6C254.7,31.7,254.7,31.7,254.6,31.6
        M254.6,32.9C254.5,33.3,255.1,32.9,254.6,32.9 M254.7,34.8C254.8,34.9,254.8,34.9,254.7,34.8 M254.6,35.9
        C254.7,36,254.7,36,254.6,35.9 M254.5,37.2L254.5,37.2L254.5,37.2C254.5,37.2,254.5,37.2,254.5,37.2 M254.6,37.7
        C254.6,37.8,254.6,37.8,254.6,37.7 M254.6,38.2c-0.1,0.2,0,0.3,0.2,0.2C254.8,38.2,254.7,38.1,254.6,38.2 M254.2,41.4
        c0,0.2,0,0.5,0,0.7c-0.1,0.2-0.3,0.4,0,0.6c0-0.4,0.3-0.7,0.4-1l-0.3,0.1c0-0.1,0.1-0.3,0.1-0.4C254.3,41.4,254.2,41.4,254.2,41.4
        M257.5,41.7c0,0.1,0,0.2,0,0.3c0,0.1-0.2,0.3,0,0.4C257.8,42.3,257.9,41.6,257.5,41.7 M257.5,43c0.2-0.1,0.2-0.2,0-0.4
        C257.3,42.8,257.3,42.9,257.5,43 M254.4,43c0,0.1,0,0.2,0,0.2h0.1c0-0.1,0-0.2,0-0.2L254.4,43 M257.5,43.1
        C257.3,43.6,258,43.2,257.5,43.1 M254.6,43.4c-0.2,0.1-0.2,0.3-0.1,0.5C254.7,44,254.9,43.4,254.6,43.4 M257.4,44.8
        C257.2,45.2,257.9,44.9,257.4,44.8 M257.8,46c-0.1,0.2-0.1,0.3,0.1,0.3C258,46.1,258,46,257.8,46 M258.5,47.8
        C258.5,48.3,259,47.6,258.5,47.8 M258.7,49.9c-0.1,0.2,0.2,0.4,0.4,0.3C259.2,49.9,258.8,49.7,258.7,49.9 M256.7,51
        c-0.1,0.6,0,1.2,0,1.9c-0.1,0.2-0.2,0.3-0.2,0.5c0.1,0,0.3,0,0.4,0c-0.1,0.3-0.2,0.5-0.4,0.7c0.1,0.2,0.2,0.3,0.2,0.6
        c0,0.1,0.1,0.2,0.1,0.3c-0.1,0-0.2,0.1-0.3,0.1c0.1,0,0.2,0,0.2,0c0,0.3,0,0.7,0,1c0.1,0,0.3,0,0.4,0c-0.1,0.1-0.2,0.2-0.3,0.2
        c0,0.1,0.1,0.2,0.1,0.3c0.1,0.2,0.3,0.3,0.4,0.5c-0.1,0-0.3,0.1-0.4,0.1c0,0.1,0,0.2,0,0.3l0.4-0.1c-0.1,0.2-0.2,0.3-0.2,0.6
        c0,0.3-0.1,0.7,0.2,0.8c0-0.2-0.1-0.3-0.1-0.5c0.1-0.1,0.2-0.3,0.4-0.4l-0.4,0c0.2-0.2,0.4-0.3,0.4-0.6c-0.1-0.4-0.2-0.8-0.3-1.1
        c0-0.2,0-0.4-0.2-0.6c0.2-0.5,0-1-0.1-1.4C257,53.1,257.1,52,256.7,51 M259.4,52.4c0.1,0.3,0.4,0,0.3-0.2c0-0.1,0-0.2-0.1-0.3
        C259.4,51.9,259.4,52.2,259.4,52.4 M259.7,52.7C259.8,52.8,259.8,52.8,259.7,52.7 M260.2,55.4C260.3,55.5,260.3,55.5,260.2,55.4
        M260.8,57.3c0.2-0.1,0.2-0.2,0-0.3C260.5,57.1,260.5,57.2,260.8,57.3 M254.3,57.8C254,58.2,254.7,57.8,254.3,57.8 M253.8,58.5
        c0,0.3,0,0.6,0.2,0.9c-0.4,0-0.4,0.3-0.4,0.6l0.3,0c-0.1-0.2,0.1-0.7,0.4-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c0.2-0.1,0.2-0.2,0.2-0.3
        c-0.1,0-0.2,0-0.3,0C253.9,58.6,253.8,58.5,253.8,58.5 M261.5,59.1C261.6,59.1,261.6,59.1,261.5,59.1 M262.1,59.4
        C262.1,59.5,262.1,59.5,262.1,59.4 M253.3,60.3L253.3,60.3c0.1-0.1,0.1-0.2,0.1-0.2C253.2,59.9,253,60.3,253.3,60.3 M260.4,60.3
        C260.4,60.8,260.8,60.1,260.4,60.3 M262.4,60.6C262.5,60.7,262.5,60.7,262.4,60.6 M262.8,61.1C262.6,61.5,263.3,61.1,262.8,61.1
        M261.2,62c0,0.2,0.1,0.5,0.3,0.5C261.5,62.3,261.5,61.7,261.2,62 M258.8,63.6C258.6,64,259.3,63.6,258.8,63.6 M256.8,66.7
        c-0.1,0.2-0.1,0.5-0.2,0.7c0.1,0,0.2,0,0.2,0c0,0.1-0.1,0.2-0.1,0.3c0.1,0.2,0.3,0.4,0.5,0.6c0,0.1,0,0.2,0,0.4
        c-0.1-0.2-0.2-0.3-0.4-0.4c0,0.4,0.3,0.6,0.3,0.9c0.2,0,0.3,0.1,0.4,0.3c-0.2,0-0.3,0-0.5,0.1c0.3,0,0.4,0.2,0.5,0.4
        c0.2,0.1,0.4,0.3,0.6,0.5c-0.2,0-0.5-0.1-0.6,0.1c0.5,0,0.7,0.4,0.8,0.8l0.2-0.2c-0.1,0.3,0,0.5,0.1,0.8l0.2-0.1
        c-0.1,0.2,0.1,0.5-0.1,0.8l-0.1,0.1c-0.2,0.2,0.2,0.2,0.3,0.1c0.3,0,0.4,0.4,0.6,0.6c0.1,0.1,0.3,0.2,0.4,0.2
        c-0.2-0.4-0.5-0.9-0.9-1.2l0.2-0.1c-0.3-0.2-0.2-0.7-0.6-0.8c0-0.4-0.1-0.7-0.1-1.1c-0.5-0.3-0.5-0.8-0.6-1.3
        c-0.1-0.2-0.3-0.4-0.4-0.6c-0.1-0.4-0.2-0.8-0.4-1.1C256.9,67.3,257,66.9,256.8,66.7 M260.1,68.3c0,0.2,0,0.3,0.2,0.2
        C260.4,68.3,260.3,68.2,260.1,68.3 M261,70C261.1,70.1,261.1,70.1,261,70 M249.4,74.4C249.2,74.8,249.9,74.5,249.4,74.4 M263.2,74.8
        C263.3,74.9,263.3,74.9,263.2,74.8 M253.5,75.7c0.1,0,0.2,0.1,0.2,0.1l0.1-0.4c0,0.1,0.1,0.3,0.2,0.3c0.1,0.3-0.2,0.3-0.4,0.4
        c-0.1,0.1-0.3,0.3-0.3,0.5c-0.1,0-0.2-0.1-0.3-0.1c0.1,0.2,0.2,0.3,0.3,0.5c-0.1,0-0.4-0.1-0.5-0.1c0.1,0.1,0.2,0.2,0.2,0.2
        c-0.1,0.3-0.1,0.7-0.5,0.7c0,0.1,0,0.2,0,0.3c-0.1,0-0.2,0-0.3,0c0.3,0.4-0.2,0.7-0.5,0.7l0,0.1c0.1,0,0.4,0,0.6,0
        c-0.3,0.3-0.7,0.5-0.6,1c0.1-0.2,0.3-0.3,0.4-0.5c0.2-0.4,0.6-0.8,0.9-1.2c0-0.4,0.3-0.7,0.3-1c0.1,0,0.2-0.1,0.3-0.1
        c0-0.2,0.1-0.4,0.1-0.7l0.2,0.1c0-0.1,0-0.2-0.1-0.3c0.3-0.1,0.3-0.3,0.5-0.5c-0.2-0.1-0.3-0.3-0.5-0.4l0-0.2
        C253.6,75.2,253.6,75.5,253.5,75.7 M263.4,76.1c-0.1,0.2,0,0.3,0.2,0.2C263.7,76.1,263.6,76.1,263.4,76.1 M279.4,76.1
        C279.5,76.2,279.5,76.2,279.4,76.1 M219.2,76.3c0,0.2,0,0.5,0,0.7c-0.2,0.6-0.3,1.2-0.5,1.8c0,0.2-0.3,0.6,0.1,0.6l0-0.2l0.1,0
        c0-0.1-0.1-0.2-0.1-0.3l0.1,0c0,0.1,0.1,0.2,0.2,0.3c0-0.2,0-0.5,0-0.6c0.1,0.1,0.3,0.2,0.4,0.3c-0.1-0.2-0.3-0.3-0.1-0.5
        c0.2-0.3,0.2-0.6,0.2-1c0-0.3,0-0.5,0-0.8C219.4,76.4,219.3,76.3,219.2,76.3 M249.9,76.7c-0.1,0.3-0.2,0.6-0.4,0.9
        c-0.1,0.1-0.3,0.2-0.4,0.3c0.1,0.2-0.1,0.3-0.2,0.4c-0.1,0.2-0.2,0.5-0.4,0.6c0-0.1-0.1-0.3-0.2-0.4c0,0.1,0,0.3,0.1,0.5
        c-0.2,0.4-0.6,0.8-0.8,1.2l-0.2-0.2c0,0.4-0.1,0.8-0.6,0.7c0,0.1,0,0.3,0,0.4l-0.2-0.3c0,0.1-0.1,0.2-0.1,0.2l0.2,0.1
        c-0.2,0.2-0.5,0.3-0.6,0.5c-0.1,0.1-0.3,0.3-0.4,0.4c-0.3,0.1-0.4,0.4-0.7,0.5c0,0.1,0,0.2-0.1,0.2c-0.4,0-0.4,0.4-0.7,0.5
        c-0.1,0.3-0.4,0.6-0.6,0.7c0.2,0.4-0.4,0.3-0.4,0.7c-0.2,0.1-0.3,0.3-0.4,0.5c-0.2,0.2-0.4,0.5-0.6,0.7c-0.2,0.1-0.3,0.2-0.4,0.4
        c-0.1,0.1-0.3,0.3-0.4,0.4c-0.1,0.2-0.3,0.3-0.4,0.5c-0.1,0.5,0,1,0,1.5c-0.1,0.6,0.1,1.1,0.1,1.7c0,0.5,0,0.9,0.2,1.4
        c0.1,0,0.3,0,0.4,0c0.2,0,0.4,0,0.5,0c0.2-0.1,0.4-0.1,0.6,0.1c0.4-0.2,0.8,0.2,1.2,0c0.1,0.1,0.2,0.2,0.2,0.2
        c0-0.1,0.1-0.2,0.1-0.2c0.2,0,0.3,0.1,0.5,0.1c0.8-1,1.3-2.1,2.1-3c0.1-0.4,0.4-0.7,0.6-1c0-0.1,0-0.3-0.1-0.4
        c-0.1,0.1-0.4,0.3-0.5,0.4c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.5,0.4-1,0.6-1.5c0.2-0.5,0.1-1.1,0.3-1.6c0.2-0.3,0.4-0.7,0.6-1
        c0.1-0.5,0.1-1,0.4-1.4c0-0.2,0-0.4,0-0.6c0.2-0.2,0.3-0.5,0.4-0.8c-0.1,0.1-0.2,0.3-0.2,0.4c-0.2,0.2-0.3,0.4-0.3,0.6
        c-0.4,1.1-1.2,2-1.8,3c0.4-1,1-2,1.4-3c0-0.1,0-0.3,0-0.5c-0.1,0.2-0.2,0.4-0.2,0.6c-0.1,0-0.2,0.1-0.3,0.1c0,0.4-0.3,0.9-0.7,1.1
        c0,0.2-0.1,0.4-0.3,0.5c-0.1,0.2-0.2,0.3-0.3,0.5c-0.2,0-0.3,0.2-0.4,0.4c0,0.1-0.1,0.2-0.2,0.2c-0.3,0.3-0.5,0.6-0.7,0.9
        c-0.2,0.2-0.4,0.3-0.6,0.5c0.3-0.4,0.6-0.8,0.9-1.2c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.2,0.1-0.4,0.2-0.5c0.1,0,0.2,0,0.3,0
        c0-0.1,0-0.3,0-0.4l0.1,0c0-0.2,0.2-0.3,0.4-0.4c0-0.2,0-0.4,0-0.5c0.1,0,0.2,0.1,0.3,0.1c0.1-0.4,0.3-0.7,0.3-1.1
        c0.1,0,0.2,0,0.3,0c0.1-0.5,0.3-0.9,0.6-1.3c0.1-0.4,0.3-0.7,0.6-1c0.1-0.3,0.2-0.6,0.3-0.9c0.1-0.1,0.2-0.2,0.2-0.3
        c0-0.1,0-0.3,0-0.5c0.1,0.1,0.2,0.2,0.3,0.2c-0.2-0.5,0.2-0.9,0.3-1.4C250.1,76.5,250,76.6,249.9,76.7 M252.4,77.5
        c-0.1,0.2,0,0.3,0.2,0.2C252.7,77.5,252.6,77.5,252.4,77.5 M252.1,77.8C252.2,77.9,252.2,77.9,252.1,77.8 M279.3,78.1
        C279.4,78.2,279.4,78.2,279.3,78.1 M262.4,78.4c0,0.2,0,0.3,0.2,0.2C262.7,78.4,262.6,78.3,262.4,78.4 M219.2,79.4c0,0.3,0,0.7,0,1
        l-0.2,0c0.1,0.4-0.1,0.8-0.2,1.3c-0.1,0.3-0.1,0.6-0.1,0.9l-0.1,0c0,0.2,0,0.4,0,0.6c0.1,0,0.2,0.1,0.3,0.1c0-0.3,0.1-0.5,0.1-0.8
        c0.1,0,0.2,0,0.2-0.1c0,0.2,0.1,0.3,0.1,0.5c0,0,0.1,0,0.1,0l0-0.1c0-0.1,0-0.2,0.1-0.4c-0.1-0.1-0.2-0.3-0.4-0.4
        c0.1-0.2,0.2-0.5,0.2-0.6l-0.3,0.1c0.4-0.2,0.2-0.7,0.2-1c0.4,0,0.1-0.4,0-0.6c0.2-0.1,0.1-0.3,0.2-0.5
        C219.4,79.4,219.3,79.4,219.2,79.4 M300.2,79.4C300,79.8,300.7,79.4,300.2,79.4 M218.4,79.4c0,0.6-0.2,1.1-0.4,1.7
        c-0.2,0.5-0.3,1-0.5,1.5c0.2,0,0.4,0.1,0.5,0.2c0.3-0.7,0.4-1.4,0.5-2.1c0-0.4,0.3-0.8,0.2-1.3C218.7,79.4,218.5,79.4,218.4,79.4
        M299.2,79.5c0.2,0.3,0.5,0.6,0.4,1c-0.1-0.1-0.2-0.2-0.3-0.3c0,0.2,0.1,0.5,0.1,0.7c-0.1,0-0.3,0-0.4,0l0,0.2
        c0.1,0,0.3-0.1,0.4-0.1c0,0.1,0,0.3,0,0.4c-0.1,0-0.2,0-0.3,0v0.3c0.1,0,0.4,0,0.6,0c0-0.1,0-0.3,0-0.5c0-0.1,0.1-0.2,0.2-0.2
        c0-0.2,0-0.4,0.1-0.5c-0.2-0.3-0.1-0.5,0-0.8l-0.1,0c0,0.1,0,0.3,0,0.4l-0.1,0l0-0.1c-0.1-0.1-0.2-0.2-0.3-0.3L299.2,79.5 M246,79.8
        C246.1,79.9,246.1,79.9,246,79.8 M247.5,79.8C247.5,79.9,247.5,79.9,247.5,79.8 M251.4,80.3c0.2,0.1,0.4-0.2,0.2-0.3
        C251.5,79.9,251.4,80.2,251.4,80.3 M203.4,80.6C203.4,81.1,203.8,80.4,203.4,80.6 M305.1,80.6C305.2,80.7,305.2,80.7,305.1,80.6
        M311.2,80.6C311.2,81.1,311.6,80.4,311.2,80.6 M205.1,80.8C205.2,80.9,205.2,80.9,205.1,80.8 M303.6,80.8
        C303.6,81.3,304.1,80.6,303.6,80.8 M309.6,80.8C309.7,80.9,309.7,80.9,309.6,80.8 M200.1,80.9C200.1,81.4,200.5,80.7,200.1,80.9
        M319.5,81.6c0.1,0.2,0.3,0.2,0.4,0C319.7,81.5,319.5,81.5,319.5,81.6 M192.8,81.9C192.9,82,192.9,82,192.8,81.9 M259.1,81.9
        c0.2,0.2,0.4,0.5,0.7,0.6C259.7,82.2,259.4,82,259.1,81.9 M304.8,82.1c-0.8-0.2-1.5-0.1-2.3,0.1c0.2,0.1,0.3,0.2,0.4,0.4
        c0,0.2,0.1,0.4,0.2,0.6c-0.1,0-0.3,0-0.4-0.1c0.1,0.2,0.3,0.3,0.4,0.5c0,0.1,0.1,0.3,0.1,0.4c0.2,0.1,0.2,0.3,0.2,0.5l0.2,0
        c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0.1,0.2,0.2,0.2l0.1-0.1c0,0.2,0,0.5,0.3,0.5c0,0.4,0.2,1,0.6,1.1c0.2,0.3,0.6,0.6,0.9,0.8
        c0.4,0.1,0.8,0.2,1.2,0.2c-0.2,0.1-0.5-0.1-0.5,0.2c-0.3-0.2-0.7-0.3-1.1-0.5c-0.3-0.2-0.6-0.5-1-0.6c-0.1-0.4-0.6-0.6-0.7-1.1
        c-0.5-0.2-0.4-0.9-0.9-1c-0.1,0.5-0.1,0.9,0,1.4c0.1,0.2,0.2,0.4,0.2,0.6c0.1,0.1,0.2,0.2,0.3,0.3c0,0.1,0,0.3,0,0.4l0.2-0.1
        c0.1,0.2,0.2,0.3,0.3,0.4c-0.1,0-0.2,0.1-0.2,0.2l0,0.1l0.1-0.1c0.1,0.2,0.2,0.5,0.5,0.5c-0.1,0.1-0.2,0.2-0.2,0.2
        c0.2,0,0.4,0.1,0.4,0.3c-0.1,0-0.3,0-0.3,0c0.2,0.1,0.2,0.3,0.2,0.5c0.1,0,0.3,0,0.4,0c0,0.2,0,0.4,0,0.6c0.1,0.1,0.3,0.2,0.4,0.2
        c-0.1,0.1-0.3,0.2-0.3,0.2c0.1,0,0.3,0,0.3,0c0,0.2,0.2,0.4,0.3,0.5c0.1,0,0.2,0,0.2,0.1l-0.3,0c0.1,0.2,0.3,0.4,0.5,0.6
        c0.1,0.3,0.3,0.5,0.2,0.8c0.4,0,0.4,0.5,0.6,0.8l0.1,0c0.1,0.3,0.2,0.5,0.2,0.8c0.2,0.1,0.4,0.1,0.5,0.3c0.3,0.5,0.7,0.8,0.9,1.3
        c-0.4-0.4-0.8-0.7-1.1-1.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1-0.3-0.4-0.5-0.6-0.7c-0.3-0.3-0.5-0.7-0.9-0.9c0-0.1,0-0.2,0-0.3
        c-0.4-0.1-0.5-0.5-0.8-0.7c-0.1-0.2-0.2-0.4-0.5-0.4c0.2,0.4,0.5,0.8,0.6,1.3c0,0.1,0.1,0.2,0.2,0.3c-0.1,0.1-0.2,0.2-0.2,0.2
        l0.3-0.1c0.1,0.3,0.3,0.6,0.5,0.9c0,0.4,0.3,0.7,0.4,1c-0.1,0.5,0.8,0.7,0.6,1.3c0.3-0.2,0.7,0.4,0.3,0.5c0.1,0.2,0.4,0.4,0.4,0.7
        c0.1,0,0.2,0.1,0.3,0.1c0.1,0.3,0.3,0.7,0.1,1c0.1-0.1,0.2-0.2,0.2-0.2c-0.1,0.4,0.5,0.4,0.4,0.8c0.2-0.3-0.1-0.6,0-0.8
        c0.2-0.6,0.6-1.1,0.6-1.7c0.1-0.2,0-0.5,0.3-0.5c0-0.1-0.1-0.2-0.1-0.2c0.1-0.2,0.3-0.4,0.3-0.5c0.6-1.7,1.8-3.1,2.2-4.9
        c0.3-0.8,0.3-1.7,0.6-2.5c-0.1-0.2,0-0.6,0.3-0.6c-0.2-0.3,0-0.6,0.1-0.9c0.1-0.7,0.4-1.4,0.5-2.1l-0.3,0.1l0.1-0.3
        c-0.3,0.5-0.3,1.1-0.4,1.7c-0.2,0.6-0.3,1.2-0.7,1.6l0.3,0.2c-0.1,0-0.2,0-0.2,0c0,0.1-0.1,0.2-0.1,0.3c-0.1-0.5,0.4-0.8,0.4-1.3
        c0.3-1.1,0.3-2.3,0.7-3.4c-0.2-0.1-0.5-0.2-0.8-0.2c0-0.1-0.1-0.2-0.2-0.3c-0.5,0.1-1-0.2-1.5,0c-0.3,0.1-0.6-0.1-0.9-0.2
        c-0.2,0.1-0.5,0.1-0.6-0.1c-0.2,0-0.4,0.1-0.6,0.1c-0.8-0.3-1.6,0.1-2.4-0.2C305.2,81.9,305,82,304.8,82.1 M206.2,82.1
        c-0.2,0.3-0.6,0-0.9,0.3c-0.3,0.1-0.7,0.1-1-0.1c-0.3,0.2-0.7,0.1-1.1,0.1c0,0.1-0.1,0.2-0.2,0.3c-0.3,0-0.7,0.2-1,0.1
        c-0.4,0.2-0.9,0.1-1.3,0.4c-0.2,0-0.4,0-0.6,0c-0.1,0.1-0.3,0.2-0.4,0.3c0,0.3,0.4,0.7,0,0.8c0.3,0.6,0.2,1.3,0.3,1.9
        c-0.1,0.3,0.1,0.6,0,1c0,0.7,0.4,1.4,0.4,2.1l0.1,0c0-0.1,0-0.2,0-0.3c0.2,0.1,0.2,0.3,0.2,0.6l0.1,0c0,0.3,0.1,0.5,0.2,0.8
        c0.1,0.2,0.2,0.3,0.2,0.5c0.1,0,0.2,0,0.2,0c0,0.3,0,0.6,0.1,0.8c0.3-0.1,0.3,0.1,0.4,0.4c0,0.1,0.1,0.3,0.2,0.4
        c0.2,0.3,0.3,0.7,0.4,1l0.1,0c0,0.1,0,0.2-0.1,0.3l0.2,0l0,0.2l0.1-0.1c0,0.2,0,0.4,0.2,0.5c0.1,0.2,0.2,0.4,0.4,0.6
        c-0.7-0.4-1.3-0.9-1.8-1.5c-0.3,0-0.3-0.2-0.2-0.4l-0.3,0c0.1,0.4,0.3,0.7,0.6,1c0.1,0.2,0.1,0.5,0.4,0.5c0.2,0.3,0.5,0.6,0.6,1
        l0.1,0l0,0.2c0.1,0,0.2,0,0.2,0c0,0.1,0,0.3,0,0.5c0.3,0,0.3-0.3,0.4-0.5c0.1-0.4,0.4-0.6,0.6-1c0.3-0.6,0.6-1.3,1-1.9
        c0.1-0.3,0.2-0.6,0.3-0.9c0.4-0.2,0.3-0.7,0.5-0.9c0.4-1,0.9-2,1.4-3c0-0.5,0.3-0.9,0.3-1.3c0.4-0.7,0.3-1.6,0.8-2.4
        c0-0.2,0-0.4,0-0.6c0.3-0.2,0.2-0.6,0.4-0.9c0-0.2,0.3-0.5,0.1-0.6c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0.7-0.5,1.3-0.5,2
        c-0.1-0.1-0.2-0.2-0.2-0.3l-0.1,0.2c0.1,0,0.3,0.1,0.3,0.1c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.3-0.2,0.6-0.3,0.9c0,0,0.1,0.1,0.1,0.2
        c-0.2,0.2-0.4,0.4-0.5,0.6c-0.1,0.2-0.1,0.5-0.4,0.4c0,0.1,0.1,0.2,0.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0.4-0.1,0.5-0.5,0.4
        c0.1,0,0.2,0.1,0.2,0.2c-0.2,0.2-0.3,0.5-0.3,0.8c-0.3,0.5-0.4,1.2-0.8,1.7c-0.1,0.2-0.1,0.4-0.3,0.6c0,0.1,0,0.2,0,0.3
        c-0.1,0.1-0.2,0.3-0.2,0.4c-0.2,0.3-0.4,0.6-0.5,0.9c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.1,0.2-0.2,0.2-0.4c0.2-0.5,0.2-1,0.5-1.5
        c0.4-0.7,0.4-1.6,0.8-2.3c0-0.2,0.1-0.4,0.1-0.6c0.2-0.6,0.3-1.3,0.6-1.9c0.1-0.5,0.3-0.9,0.4-1.4c0-0.7,0.3-1.4,0.4-2.1
        C207.2,82.1,206.7,82.4,206.2,82.1 M282.8,82.1C282.9,82.2,282.9,82.2,282.8,82.1 M193.2,82.2C193,82.6,193.7,82.3,193.2,82.2
        M212.6,82.1c0.1,0.2,0.2,0.5,0.3,0.7c0.1,0.1,0.2,0.3,0.3,0.4c-0.1,0-0.3,0-0.4,0c0,0.1,0.1,0.2,0.2,0.3c-0.3,0.3-0.4-0.2-0.6-0.4
        c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0,0.3,0,0.4,0c0.1,0.3,0.3,0.6,0.4,0.8c0-0.5-0.5-0.7-0.5-1.2c0.1,0,0.2,0.1,0.3,0.1
        c0.1,0.4,0.5,0.7,0.4,1.1c0,0,0.1,0,0.2,0c0,0.2,0.1,0.4,0.3,0.5c0.1,0.2,0.2,0.5,0.1,0.7c0.1,0,0.2-0.1,0.3-0.2l-0.1,0.3l0.2,0
        c0,0.1,0,0.2,0,0.3l0.3,0c0,0.1-0.1,0.2-0.2,0.3c0.1,0,0.3,0,0.4,0c0,0.1,0,0.3,0,0.4c0.1,0.1,0.3,0.1,0.4,0.2c0,0.1,0,0.2,0,0.3
        c-0.2-0.1-0.4-0.2-0.6-0.3c0,0-0.1-0.1-0.1-0.2c-0.4-0.1-0.7-0.3-1-0.5c-0.2-0.2-0.5-0.2-0.7-0.1c0,0,0,0.1,0,0.2
        c0.2,0.2,0.3,0.4,0.6,0.2l0,0.2l-0.1,0c0,0.1-0.1,0.3-0.1,0.4l0.2-0.1c-0.3,0.3,0,0.7,0.2,1c0.1-0.1,0.2-0.2,0.2-0.3
        c0,0.1,0,0.3,0,0.4l0.2,0c-0.1,0-0.2,0.1-0.2,0.1c0.3,0.2,0.3,0.6,0.6,0.9c-0.1,0-0.3-0.1-0.5-0.1c0.2,0.3,0.6,0.5,0.5,0.9l0.2,0
        c0,0.1-0.1,0.3-0.2,0.4l0.3,0c0,0,0,0.1,0,0.1c-0.1,0-0.3,0-0.4,0c0.1,0.1,0.3,0.2,0.4,0.3l0,0.2l0.2,0c-0.1,0.2-0.2,0.3-0.2,0.5
        c0.1,0,0.3-0.1,0.5-0.2c0,0.1-0.1,0.3-0.2,0.4c0.1,0,0.2,0,0.3,0c-0.1,0.3,0,0.6,0.1,0.9l0.1,0c0.1,0.3,0.3,0.5,0.4,0.7
        c-0.2-0.1-0.5,0-0.4-0.4c-0.2,0.2,0.1,0.5,0.1,0.8c0.1,0,0.2,0,0.3,0c0-0.1-0.1-0.2-0.1-0.2c0.2-0.1,0.4-0.1,0.4-0.3
        c0.1-0.4,0.3-0.7,0.5-1.1c0.1-0.4,0.1-0.8,0.5-1l0,0l-0.2,0c-0.1-1,0.7-1.8,0.6-2.8c-0.3,0.4-0.5,0.8-0.7,1.2
        c0.2-0.7,0.5-1.4,0.7-2.1c0.1-0.3,0.1-0.5,0.2-0.8c-0.1,0-0.3-0.1-0.4-0.2c0.3-0.9,0.1-1.8,0.5-2.7c-0.6-0.3-1.2-0.4-1.8-0.6
        c-0.3,0-0.5-0.4-0.7-0.1c-0.6-0.3-1.3-0.1-1.8-0.5c-0.1,0-0.2,0.1-0.3,0.2C213.1,82.2,212.8,82.2,212.6,82.1 M321.4,82.4
        c0.2,0,0.3,0,0.2-0.2C321.4,82.1,321.3,82.2,321.4,82.4 M324,82.7C324.1,82.8,324.1,82.8,324,82.7 M302.6,82.8
        C302.7,82.9,302.7,82.9,302.6,82.8 M229.2,83c-0.1,0.2,0,0.3,0.2,0.2C229.5,83,229.4,82.9,229.2,83 M302.1,83c0,0.4,0.1,0.8,0,1.1
        c0.2,0.4,0,0.7,0,1.1c0.1,0,0.2,0.1,0.3,0.2c0.1-0.3,0.2-0.7,0.4-1c0-0.4-0.4-0.6-0.4-1C302.2,83.3,302.2,83.2,302.1,83 M313.4,83.1
        c0.2,0.1,0.3,0.3,0.2,0.6l0.2-0.1l-0.1,0.3l0.2-0.1c0,0.1-0.1,0.3-0.1,0.5c0.1,0,0.2,0,0.3,0c0,0.2,0,0.5,0.1,0.7c0,0.3,0,0.5,0,0.8
        l0.1,0c0,0.2,0,0.4,0,0.6c0.1,0,0.2,0,0.2,0c0,0.2,0,0.5,0.1,0.6c0.1,0.3,0.1,0.6,0.2,0.9c0.2,0.1,0.3,0.4,0.4,0.6
        c0,0.2,0.1,0.5,0.3,0.5c0,0,0,0.1,0,0.1c-0.2-0.1-0.3-0.2-0.5-0.4c-0.1-0.2-0.2-0.5-0.4-0.6c-0.1-0.2-0.2-0.3-0.3-0.4
        c-0.1-0.1-0.2-0.3-0.2-0.4c0.1,0.2,0.1,0.4,0.2,0.6c0.2,0.1,0.1,0.4,0.1,0.6c0.4,0.1,0.2,0.6,0.3,1c0.4,0.1,0.2,0.6,0.4,0.9l0.1,0
        c0,0.2,0,0.4,0.1,0.7l0.1,0c0,0.1,0.1,0.2,0.2,0.3c0.1,0.2-0.1,0.6,0.3,0.4c0.1,0.3,0.2,0.6,0.1,0.8c0.1,0,0.3,0,0.4,0
        c-0.1,0.1-0.3,0.2-0.4,0.3c0.1,0,0.3,0,0.3-0.1c0.1,0.2,0.2,0.5,0.2,0.8c-0.3-0.2-0.8-0.2-1-0.6c0,0-0.1,0-0.2,0
        c-0.2-0.3-0.3-0.7-0.6-0.9c-0.3-0.2-0.2-0.6-0.5-0.8c-0.1-0.4-0.2-0.9-0.4-1.3c-0.2-0.5-0.1-1.1-0.4-1.5c0.2,0.8,0.1,1.5,0.2,2.3
        c0.1,0.3-0.1,0.8,0.3,1c0.1,0.2,0.3,0.4,0.4,0.6c0.3,0.3,0.5,0.6,0.8,0.9c0.2,0.3,0.4,0.6,0.7,0.8c0.2,0.5,0.7,0.7,0.9,1.1
        c0.3,0.2,0.5,0.6,0.8,0.7c-0.2,0.1-0.3-0.1-0.5-0.1c-0.3-0.2-0.6-0.4-0.9-0.7c-0.1,0-0.3-0.1-0.3-0.1c-0.2-0.2-0.4-0.4-0.6-0.6
        c-0.2-0.2-0.3-0.5-0.5-0.5c0-0.1-0.1-0.3-0.1-0.4c-0.5-0.2-0.5-0.8-0.9-1.1c0,0.4,0.1,0.8,0.1,1.2c0.1,0.1,0.1,0.2,0.2,0.3
        c0.1,0,0.2-0.1,0.2-0.1c0,0.1,0,0.3,0,0.4c0.1,0,0.2-0.1,0.3-0.1l0,0.3c0.1,0,0.2-0.1,0.3-0.1c-0.1,0.4,0.4,0.6,0.5,0.9
        c0.1,0,0.4-0.1,0.5-0.1c0,0.1-0.1,0.3-0.1,0.4c0.1,0,0.2-0.1,0.2-0.1c0.2,0.3,0.5,0.5,0.7,0.8l-0.1-0.4c0,0.1,0.1,0.2,0.2,0.2
        c0,0.1,0,0.2-0.1,0.2l0.3,0c0,0.1,0,0.2,0,0.3l0.1,0c-0.1-0.1,0.2-0.5,0.2-0.2c0,0.1,0.1,0.2,0.1,0.3l0,0.1c0.1,0,0.3,0,0.4,0
        c0,0.3,0.1,0.5,0.2,0.7c0.2,0.1,0.5,0.3,0.8,0.4c0.1-0.5,0.4-0.8,0.6-1.3c0.1-0.8,0.7-1.5,0.8-2.3c0,0,0.1-0.1,0.2-0.2
        c0.1-0.4,0.3-0.8,0.5-1.1c0.3-0.5,0.3-1.1,0.6-1.5c0.1-0.9,0.8-1.5,0.9-2.4c-0.1,0-0.2-0.1-0.2-0.1c0.3-0.1,0.4-0.2,0.4-0.5
        l-0.3,0.1c0-0.2,0.1-0.4,0.3-0.4c0,0.1,0,0.3,0.1,0.4c0.4-0.1,0-0.4-0.1-0.6c-0.3-0.1-0.2-0.3-0.1-0.5l0,0c0,0.2,0.1,0.3,0.1,0.5
        c0.2,0.1,0.3,0.3,0.6,0.3c-0.4-0.4-0.6-0.9-0.8-1.4c-0.5,0,0.1,0.7-0.4,0.5l0.1,0.2l-0.3-0.1c0.1,0.1,0.3,0.4,0.3,0.5
        c-0.1,0-0.3-0.1-0.4-0.1c0,0.3,0.2,0.8-0.1,1c-0.1,0.1-0.2,0.2-0.2,0.4c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0-0.3,0-0.4,0
        c0.1,0.1,0.2,0.2,0.2,0.2c-0.3,0.4-0.4,1-0.7,1.4l0.3-0.1c-0.4,0.2-0.4,0.5-0.5,0.9c-0.2,0.1-0.3,0.2-0.3,0.5c0.1,0,0.3-0.1,0.4-0.1
        c0,0-0.1,0.1-0.1,0.2c-0.1,0-0.2,0-0.3-0.1c-0.1,0.1-0.2,0.4-0.3,0.6c0.1,0,0.3-0.1,0.4-0.1c0,0,0,0.1,0,0.1c-0.1,0-0.2,0-0.3,0
        c0.1,0.3,0,0.4-0.2,0.5l0.1,0.2c0,0-0.2,0-0.2,0c0,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0-0.2,0-0.2,0
        c-0.1,0.5-0.4,0.8-0.7,1.1c0,0.3-0.1,0.5-0.4,0.6c0-0.4,0.1-0.8,0.2-1.2c0,0,0.1-0.1,0.2-0.1c0.2-0.9,0.4-1.7,0.6-2.6
        c0.2-0.7,0.3-1.4,0.7-2.1c0-0.2,0-0.4,0-0.5c0.3,0,0.1-0.4,0.3-0.5c0.2-0.2,0.2-0.4,0.2-0.6c0.1-0.2,0.1-0.5,0.2-0.7
        c0.1-0.1,0.2-0.2,0.3-0.4c0-0.2,0-0.3,0-0.5c0,0-0.1,0-0.1,0c0.1,0.5-0.4,0.7-0.4,1.2c0,0-0.1,0.1-0.2,0.1c0.1,0.2,0.2,0.6-0.1,0.7
        c-0.1,0.2-0.2,0.4-0.2,0.6c-0.2,0.3-0.6,0.5-0.5,1c-0.2,0.2-0.3,0.4-0.4,0.6c-0.3,0.6-0.6,1.1-1,1.6c-0.1,0.1-0.2,0.3-0.3,0.4
        c0.1-0.4,0.3-0.8,0.5-1.1c0.2-0.4,0.3-0.8,0.4-1.2c0.3-0.5,0.4-1.2,0.7-1.7c0.4-0.4,0.3-1.1,0.5-1.6c0.3-0.8,0.4-1.7,0.8-2.4
        c0-0.1,0.2-0.5-0.1-0.4c-0.2-0.2-0.4-0.5-0.6-0.7l0,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c0-0.1,0-0.3,0-0.4c-0.1,0.3-0.3,0.3-0.6,0.2
        c0.1-0.1,0.3-0.2,0.4-0.2c-0.5-0.1-0.9-0.1-1.3-0.4c-0.2-0.1-0.4,0.2-0.6,0.2c0-0.2,0.1-0.3,0.2-0.5c-0.3-0.2-0.6-0.2-0.8,0
        c-0.2,0-0.3,0-0.5,0c0-0.4-0.5-0.2-0.8-0.4c-0.4,0.2-0.8-0.1-1.2-0.2C314.2,83.1,313.8,83.2,313.4,83.1 M199.2,83.6
        c-0.4-0.1-0.7,0.1-1.1,0.1c0,0.1,0,0.2,0,0.2c-0.2,0-0.3,0-0.5,0c-0.3-0.3-0.6-0.3-0.9,0c0.2,0.2,0.5,0.5,0.8,0.2
        c0.1,0.3-0.4,0.2-0.5,0.5c-0.2-0.3-0.5-0.4-0.8-0.5c0,0.1,0,0.2,0,0.4c-0.4-0.2-0.9,0-1.4,0.1c0.1,0.1,0.3,0.1,0.4,0.2
        c-0.3,0.1-0.5,0-0.6-0.2c0,0.1,0,0.3,0,0.3c-0.1-0.1-0.2-0.1-0.3-0.2l0-0.2c-0.3,0.2-0.6,0.5-0.5,0.9l-0.2,0c0,0.1,0,0.2,0,0.3
        c0.2,0,0.4,0,0.6,0c-0.1,0-0.2,0.1-0.2,0.1c0,0.1,0,0.4,0,0.5c0.1,0,0.2-0.1,0.3-0.1c0,0.4,0,0.7-0.2,1.1l0,0
        c0.1-0.1,0.2-0.2,0.2-0.2c0.1,0.2,0.2,0.5,0.2,0.8c-0.2,0-0.3,0.1-0.5,0.1c0.2,0,0.5,0.1,0.6,0.1l-0.2,0.2l0.2,0l-0.1,0.2l0.2,0
        c0,0.1,0,0.3,0,0.4l0.3,0c0,0.1-0.1,0.3-0.2,0.4c0.1,0,0.2-0.1,0.2-0.1c0,0.2,0.1,0.3,0.1,0.5c0.5,0,0.8,0.6,1.1,0.9
        c-0.4-0.1-0.7-0.5-1.1-0.6c-0.3-0.1-0.4-0.4-0.7-0.4c0-0.1,0-0.2,0.1-0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.1-0.2-0.2-0.5-0.4-0.7
        c-0.1,0-0.2,0-0.2,0c-0.3-0.4-0.6-0.7-0.6-1.2c-0.1-0.1-0.3-0.2-0.4-0.4c0.1,0.5,0,1,0.2,1.5c-0.3,0.4,0.1,0.8,0.4,1.2
        c-0.1,0-0.3-0.1-0.4-0.1c0,0.2,0.1,0.4,0.2,0.5c0-0.1,0-0.3,0-0.4l0.3,0c0,0.1,0,0.2,0,0.3c0.2,0.1,0.2,0.4,0.3,0.6
        c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0.1,0,0.3,0,0.4c0,0.2,0.2,0.4,0.4,0.5c0,0.1,0,0.2,0,0.2c0.4,0,0.5,0.3,0.6,0.6l0,0.3
        c0.1,0,0.3-0.1,0.4-0.1c-0.4,0.1-0.3,0.6,0.1,0.5c0,0.1-0.1,0.3-0.1,0.4c0.1,0,0.3-0.1,0.3-0.1c0,0.1,0,0.3-0.1,0.3
        c0.1,0,0.2,0,0.3,0c0,0.2,0,0.4,0.1,0.6c0.1,0.1,0.2,0.1,0.4,0.2c0,0.1,0,0.3,0,0.4c0.1-0.1,0.2-0.2,0.3-0.3
        c-0.1,0.3,0.1,0.6,0.4,0.8l0,0.2c0.4,0,0.6-0.3,0.7-0.7c0.1-0.2-0.3-0.5-0.3-0.7c0.1,0.1,0.4,0.2,0.5,0.2c-0.2-0.2-0.3-0.4-0.2-0.6
        c0.3,0.7,0.3-0.2,0.4-0.4c-0.2,0-0.4-0.1-0.5-0.2c0.1,0,0.3,0.1,0.4,0.1c0-0.3,0.1-0.7-0.1-1c0.1,0,0.2,0,0.2-0.1
        c0,0.2,0.2,0.3,0.3,0.4l-0.1-0.2c0.5,0,0.1-0.2-0.2-0.1c0-0.1,0.1-0.3,0.1-0.4c-0.4-0.1-0.1-0.4-0.1-0.7c0,0-0.1,0.1-0.2,0.1
        c-0.1,0.5-0.3,0.9-0.6,1.3c0.1,0.1,0.2,0.1,0.3,0.2l0-0.2c0,0,0,0.1,0,0.2c-0.1,0-0.3,0.1-0.4,0.1l0.1,0.2c-0.1-0.1-0.2-0.2-0.2-0.3
        c-0.2,0.3-0.1,0.9-0.6,0.9c0,0.3-0.1,0.5-0.3,0.8c0-0.2,0-0.3,0.1-0.5c0-0.1,0.1-0.2,0.1-0.3c0.4-0.3,0.4-0.8,0.6-1.2
        c0.1,0,0.2,0,0.2,0c0-0.1,0-0.3,0-0.4c0.4-0.4,0.5-0.9,0.7-1.4c0.3-0.6,0.4-1.3,0.7-1.9c-0.2-0.1-0.2-0.2-0.3-0.4
        c0.1,0,0.3,0.1,0.4,0.1c0,0-0.1-0.1-0.1-0.2l-0.2-0.1c0-0.2,0.7-0.6,0.1-0.3c0.3-0.4,0.2-1,0.2-1.4c0.4,0-0.1-0.3,0-0.5l0.2,0
        c0-0.2,0-0.4,0-0.6c0,0,0.1-0.1,0.1-0.1c-0.1-0.4-0.1-0.8-0.1-1.2C199.6,83.4,199.4,83.6,199.2,83.6 M218.3,83.6
        c-0.1,0.4-0.2,0.9-0.4,1.3c0,0.3-0.1,0.7-0.3,1c0.1,0.1,0.1,0.2,0.2,0.3c0-0.3,0.2-0.6,0.3-0.9c0.1-0.6,0.4-1.1,0.5-1.7
        C218.6,83.6,218.4,83.6,218.3,83.6 M326.2,83.5C326.3,83.6,326.3,83.6,326.2,83.5 M283,83.6C283.1,83.7,283.1,83.7,283,83.6
        M219.3,83.9c0,0.2-0.1,0.4-0.1,0.5c-0.1,0.1-0.1,0.3-0.1,0.5c-0.1,0.2-0.1,0.4-0.1,0.7l-0.2-0.1c0.1,0.3,0,0.7-0.2,1
        c0,0,0,0.1,0,0.2c0,0.1-0.1,0.2-0.2,0.3c0,0.3-0.1,0.5-0.2,0.7c0.1-0.3,0.2-0.5,0.4-0.8c0.1-0.1,0.1-0.3,0.1-0.5
        c0.3-0.3,0.5-0.7,0.5-1.1l0.2,0c-0.1,0-0.2-0.1-0.3-0.2l0-0.3c0.1,0,0.2,0,0.3,0c0,0,0-0.1,0-0.1c-0.3,0-0.4-0.2-0.3-0.4
        c0.1,0,0.3,0,0.4,0c0,0-0.2-0.1-0.2-0.2C219.3,84.1,219.3,84,219.3,83.9 M259.1,84.6c0.1-0.2-0.1-0.4-0.3-0.4
        c-0.1-0.1-0.2-0.2-0.3-0.3C258.6,84.2,258.8,84.5,259.1,84.6 M282,84.1c0,0.2,0.1,0.5,0.2,0.7c0,0.2,0,0.3,0,0.5l0.1,0
        c0.1-0.2,0.2-0.4,0-0.5c0-0.2,0-0.5,0-0.7C282.1,84.1,282.1,84.1,282,84.1 M221.5,85.5c0.2-0.1,0.2-0.2,0-0.3
        C221.3,85.3,221.3,85.4,221.5,85.5 M181.8,85.8c0.2,0,0.4-0.4,0.2-0.5C181.6,85.2,181.8,85.7,181.8,85.8 M257.1,85.3
        C256.9,85.8,257.6,85.3,257.1,85.3 M277.7,85.4C277.8,85.8,278.1,85.1,277.7,85.4 M181,85.6C181,86.1,181.5,85.4,181,85.6
        M182.3,85.6C182.4,85.7,182.4,85.7,182.3,85.6 M221.7,85.6c-0.1,0.2,0.2,0.3,0.4,0.3C222.1,85.7,221.9,85.5,221.7,85.6 M277.5,85.9
        c0.2-0.1,0.2-0.2,0-0.3C277.4,85.7,277.4,85.8,277.5,85.9 M313.7,86.8c0.3-0.3,0-0.8,0-1.2C313.5,85.9,313.8,86.4,313.7,86.8
        M322.9,85.6c0,0.3,0.1,0.6-0.1,0.9c-0.1,0.4,0.3,0,0.4,0c0.1,0.2,0.3,0.4,0.5,0.4c0.3-0.2,0.2-0.6,0.2-1c-0.2,0.1-0.2,0.3-0.1,0.5
        c-0.2,0.3-0.3,0.3-0.3,0c-0.1-0.1-0.3-0.1-0.4-0.2c0.1,0,0.3,0,0.4,0C323.3,86,323.1,85.8,322.9,85.6 M180.5,85.9
        c-0.2,0.1-0.2,0.2,0,0.3C180.6,86.1,180.6,86,180.5,85.9 M334.2,86c-0.1,0.2,0,0.3,0.2,0.2C334.4,86,334.4,85.9,334.2,86
        M334.8,86.2C334.8,86.3,334.8,86.3,334.8,86.2 M187.6,86.8c0.1,0.2,0.1,0.4-0.1,0.4C187.5,87.1,187.5,86.8,187.6,86.8
        c-0.1,0-0.3,0.2-0.4,0.3c-0.4-0.1-1,0.1-1,0.5c0-0.1-0.1-0.2-0.1-0.3c-0.1,0-0.4,0-0.5,0c-0.4,0.3-0.8,0.3-1.2,0.5
        c-0.2,0.1-0.5,0.3-0.5-0.1c-0.3,0.3-0.7,0.5-1,0.7c-0.1,0-0.2-0.1-0.2-0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.2-0.2,0.3
        c0-0.1-0.1-0.2-0.2-0.2c-0.1,0.1-0.2,0.3-0.2,0.4c-0.1,0-0.4,0-0.5,0c0,0.1,0.1,0.3,0.2,0.3c-0.1,0-0.2,0.1-0.2,0.1
        c0-0.1,0.1-0.3,0.1-0.4c-0.3,0.1-0.5,0.3-0.5,0.5c-0.2,0-0.3,0-0.4,0c0.1,0,0.2,0,0.2-0.1l0.2-0.2c-0.5-0.2-0.7,0.5-1.3,0.3
        c0.1,0.1,0.2,0.2,0.2,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.4,0-0.5,0.1c0.1-0.1,0.2-0.2,0.2-0.4c-0.3-0.2-0.5,0-0.7,0.2
        c0.1,0.1,0.2,0.3,0.2,0.4c-0.2-0.1-0.3-0.4-0.5-0.2c-0.3,0.3-0.8,0.1-1,0.5c0.1,0.1,0.3,0.2,0.4,0.3c-0.2,0.1-0.4,0.3-0.6,0.4
        c0.1-0.1,0.2-0.3,0.3-0.4c-0.2,0-0.4-0.2-0.5-0.1c-0.6,0.2-1.3,0.7-2,0.4c0.2,0.4-0.3,0.6-0.6,0.6c-0.3,0.1-0.7,0.2-0.9,0.5
        c0-0.1-0.1-0.2-0.1-0.3c-0.2,0.4-0.7,0.5-1.1,0.5c-0.4,0-0.7,0.6-1.1,0.4c-0.3,0.3-0.6,0.3-1,0.4c0,0.1,0.1,0.2,0.1,0.3
        c0,0-0.1-0.1-0.1-0.1c-0.6,0.1-1.1,0.4-1.7,0.3c-0.1,0.1-0.1,0.3-0.1,0.4c-0.4,0-0.8,0.2-1.2,0.3c-0.3,0-0.5,0.4-0.5,0.7
        c0-0.1-0.1-0.2-0.1-0.2c-0.2,0-0.3,0.2-0.3,0.3c-0.2,0-0.3,0.1-0.5,0.1c0.1-0.1,0.3-0.2,0.4-0.4c0.1,0,0.6-0.1,0.4-0.3
        c-0.2,0-0.5,0-0.7,0c-0.2,0.3-0.3,0.6-0.7,0.4c0,0.1,0.1,0.3,0.1,0.4l-0.1,0c0-0.1,0-0.2,0-0.3c-0.3,0.2-0.7,0.1-0.8,0.5
        c-0.2,0-0.5-0.1-0.7,0c-0.4,0.2-1,0.2-1.2,0.7c0,0-0.1-0.1-0.1-0.1c0.3-0.1,0-0.3-0.1-0.4c-0.2,0.6-1,0.5-1.5,0.7
        c-0.3,0.2-0.7,0.5-1,0.1c-0.2,0.2-0.5,0.3-0.5,0.7l-0.1,0c0,0.1,0,0.2,0,0.3l-0.1,0c0-0.1,0.1-0.4,0.1-0.5c-0.1,0.1-0.2,0.2-0.3,0.2
        c0,0-0.2-0.1-0.2-0.1c0,0.4-0.4,0.2-0.7,0.3c0,0.3-0.1,0.7-0.4,0.6c-0.1-0.3,0.5-0.1,0.3-0.4c-0.3,0-0.5-0.1-0.8-0.1
        c0,0.1-0.1,0.2-0.2,0.2c-0.3,0-0.6,0-0.8,0.2c-0.4,0.3-0.9,0.2-1.2,0.6c-0.2,0-0.5,0-0.7,0c-0.1,0.1-0.2,0.3-0.3,0.4
        c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0.1-0.3,0.2-0.4,0.3c-0.3,0-0.6,0-1,0c0,0.1-0.1,0.2-0.1,0.3c-0.1,0-0.4,0-0.5-0.1
        c0,0.1,0,0.3,0.1,0.4l-0.2,0.1c0.3,0.1,0.6,0,1,0c0-0.1-0.1-0.3-0.1-0.3c0.1,0,0.2,0,0.3,0c-0.1,0.2,0,0.5,0,0.7c0,0,0.1,0,0.2,0
        c0-0.1,0-0.4,0-0.5c0.1,0,0.4,0.1,0.5,0.1c0.2-0.1,0.4-0.3,0.6-0.4c-0.1,0-0.4,0-0.5,0c0.1-0.2,0.3-0.2,0.5-0.2c0,0.1,0,0.3,0,0.4
        l0.2,0l0-0.3c0.4-0.1,0.2,0.3,0.3,0.5c0.1-0.1,0.3-0.2,0.3-0.3c-0.2-0.1-0.3-0.2-0.5-0.2l0.1-0.1c0.1,0.1,0.4,0.2,0.5,0.3l-0.1-0.4
        c0.2,0.1,0.5,0.3,0.7,0.5c0-0.4,0.4-1,0.9-0.8c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.3-0.1,0.5-0.2,0.7c0.1,0,0.4-0.1,0.5-0.2l-0.1-0.2
        c0.2,0.1,0.5,0,0.7-0.1c-0.1,0-0.2-0.1-0.3-0.2c0.1-0.1,0.3-0.2,0.4-0.3c0.2,0,0.4,0,0.6,0.1c0.1,0.3-0.7,0.1-0.3,0.4
        c0.2-0.3,0.6-0.2,1-0.2l0-0.2c-0.1,0-0.3,0-0.4-0.1c0.1-0.2,0.4-0.2,0.6-0.3c0,0.1-0.1,0.4-0.2,0.6c0.3-0.1,0.5-0.3,0.8-0.5
        c0,0.1-0.1,0.3-0.1,0.4c0.1,0,0.2,0.1,0.3,0.1c0.1-0.3,0.5-0.2,0.7-0.2c0.1-0.1,0.2-0.3,0.3-0.3c0.1,0.1,0.3,0.3,0.4,0.4l0.1-0.3
        c0.2,0.2,0.5-0.2,0.5,0.1c-0.1,0.1-0.2,0.3-0.3,0.3c0.2,0.2,0.3,0.1,0.3-0.1c0.3-0.1,0.5,0.6,0.6,0.2c0-0.1-0.1-0.2-0.2-0.2
        c-0.2-0.3,0.4-0.3,0.5-0.3c-0.1,0.2-0.2,0.4-0.2,0.6c0.1-0.1,0.2-0.3,0.3-0.5c0.2,0.1,0.3,0.1,0.5,0c0,0.1,0.1,0.3,0.2,0.3
        c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0.1,0.3,0.2,0.4,0.3c0-0.1,0.1-0.4,0.1-0.5l-0.2,0c0.1-0.4,0.4,0.3,0.7,0.2c0.2,0.2,0.4,0.3,0.4,0.6
        c0.1-0.1,0.2-0.3,0.2-0.4c0,0.1,0.1,0.2,0.1,0.3c0.3-0.2,0.6,0,0.9,0c0,0.1,0,0.2,0,0.3c0.1,0,0.3-0.1,0.4-0.1
        c0.1,0.1,0.2,0.3,0.3,0.3c0.3-0.3,0.7,0.1,1.1-0.2c0,0.1,0,0.2,0,0.3l0.4,0c0-0.1,0-0.2,0-0.3c0,0,0.1,0,0.1,0
        c0.1,0.1,0.2,0.3,0.2,0.4c-0.1,0.1-0.2,0.2-0.2,0.3c0.1,0,0.3,0.1,0.4,0.1c0-0.2-0.1-0.4-0.1-0.7c0.1,0,0.2,0,0.2,0.1l-0.2,0
        c0.1,0.2,0.2,0.3,0.2,0.5c0.2,0,0.3,0.1,0.4,0.2l-0.3,0.1c0,0.5,0.3,0.1,0.5-0.1c0,0.2,0.1,0.3,0.1,0.5l-0.1,0
        c0.1,0.1,0.2,0.1,0.4,0.2c-0.3-0.3-0.1-0.9-0.5-1.1c0.1,0,0.3,0,0.4-0.1c0,0.1,0,0.3,0,0.4c0.2,0.1,0.4,0.1,0.6,0l0,0
        c-0.4,0-0.4,0.4-0.2,0.6c0-0.1,0-0.2,0-0.3c0.4,0,0.8,0.3,1.1,0.5c0,0.1,0,0.2,0,0.3c0.1,0,0.3,0.1,0.4,0.1c0.1,0.2-0.1,0.5,0.2,0.6
        c0.2,0.2,0.5,0.2,0.8,0.2c0,0.1,0,0.4-0.1,0.5c-0.2-0.1-0.5-0.7-0.5-0.1c-0.1,0-0.2,0-0.3-0.1c0.4,0.1,0.1,0.3,0,0.5
        c0,0.1,0.1,0.2,0.1,0.3l0.1-0.2c0.1,0.1,0.2,0.1,0.2,0.2l0-0.2c0.1,0,0.2,0,0.2,0c-0.4,0-0.4-0.3-0.3-0.6c0.2,0.2,0.4,0.4,0.7,0.2
        c0,0.1,0,0.3,0,0.4c0.3-0.4,0.8,0.1,0.8,0.5c0,0-0.1-0.1-0.2-0.1l-0.1-0.2c-0.1,0.1-0.3,0.1-0.4,0.1c0,0.2-0.1,0.4-0.1,0.6
        c0.1,0.1,0.2,0.1,0.4,0.2c0-0.1,0-0.2,0-0.2l-0.3,0.1c0.1-0.3,0.3-0.4,0.6-0.3l-0.1,0.5c0.2-0.1,0.3-0.3,0.5-0.4
        c-0.1,0.2-0.2,0.4-0.4,0.6c0,0.1,0,0.4,0,0.5c0.1,0,0.2,0,0.3-0.1c0,0.1-0.1,0.2-0.1,0.3c0.4-0.1,0.3,0.2,0.3,0.4l-0.1-0.2
        c-0.1,0-0.3,0-0.4,0c0,0.2-0.1,0.3-0.1,0.5c0.1-0.1,0.2-0.3,0.4-0.3c0.3,0.2,0.3,0.6,0.5,0.9c-0.1,0-0.2,0-0.3-0.1
        c0,0.1,0,0.3,0,0.3c-0.1-0.1-0.2-0.2-0.4-0.3c0.1,0.2,0.1,0.3,0.2,0.5c0.1-0.1,0.3-0.1,0.4-0.2c0.1,0.1,0.1,0.3,0.2,0.4
        c0.1,0,0.2,0,0.3,0c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0.2,0,0.4-0.2,0.4l0.2-0.3l-0.4,0c0.1,0.1,0.2,0.4,0.2,0.5
        c-0.2-0.1-0.4-0.1-0.6-0.2c0.1,0.1,0.2,0.2,0.3,0.4c-0.2,0.1-0.2,0.2,0,0.3c0-0.1,0-0.3,0-0.4l0.3,0.2c0.1-0.2,0.2-0.5,0.5-0.4
        c-0.3,0.3-0.6,0.6-0.9,0.9l0.3,0c-0.1,0.2-0.2,0.3-0.5,0.3c0,0.1,0,0.2-0.1,0.3c0.1-0.1,0.3-0.3,0.5-0.3c0,0.4-0.1,0.5-0.4,0.4
        c0,0.2,0.3,0.6,0,0.7c-0.1,0.2-0.2,0.3-0.2,0.5c0,0,0.1,0.1,0.2,0.1c0-0.1,0.1-0.3,0.2-0.4c0,0.3,0,0.7-0.3,0.8
        c0,0.4-0.4,0.8-0.2,1.2c0.2-0.1,0.3-0.4,0.5-0.5c0.1-0.2,0-0.6,0.3-0.6c-0.1,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.5,0.3-0.2,0.5
        c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.3-0.1-0.4-0.1c0.1,0.1,0.2,0.3,0.3,0.3c-0.4,0-0.5,0.7-0.9,0.6c0,0,0,0.1,0,0.1l0.4-0.1
        c0,0.1-0.1,0.2-0.1,0.3c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0.3-0.3,0.5-0.3,0.8c0.2-0.2,0.3-0.6,0.5-0.8c0.2,0.4,0.4,0.1,0.7,0
        c-0.3-0.3-0.2-0.6,0.2-0.7c0.1,0.1,0.3,0.1,0.5,0c0,0.2-0.2,0.2-0.4,0.3c-0.1-0.1-0.1-0.1-0.2-0.2c0,0.2,0,0.5,0,0.6
        c-0.2,0.2-0.4,0.6-0.7,0.4c0,0.1-0.1,0.3-0.2,0.4c0,0.1,0.1,0.4,0.1,0.5c-0.2-0.1-0.3-0.3-0.4-0.5c-0.2,0.2-0.4,0.3-0.4,0.5
        c0.1-0.1,0.3-0.2,0.4-0.3c0,0.1,0.1,0.3,0.2,0.5c-0.2,0-0.4,0-0.6,0c0,0.1,0,0.3,0,0.3c-0.1,0-0.4,0-0.5,0c0.1,0,0.3,0.1,0.5,0.1
        c-0.1,0.2-0.2,0.3-0.4,0.3c-0.4,0.2-0.3,0.7-0.7,1c0.1,0.2,0.3,0.3,0.3,0.5c-0.1,0-0.3,0-0.4,0l0-0.3c-0.1,0-0.2,0-0.2,0.1
        c0,0.1,0,0.2,0.1,0.4c-0.2,0.1-0.7,0.2-0.5,0.5c-0.5,0-0.5,0.6-0.7,0.9c0.1,0,0.2-0.1,0.3-0.2c0-0.2,0.1-0.3,0.2-0.4l0-0.2l0.2-0.1
        c0,0.1,0.1,0.3,0.1,0.3c0.4,0.1,0.1-0.5,0.3-0.7c0.2-0.1,0.3,0.1,0.5,0.1c0-0.1-0.1-0.3-0.1-0.4c0.3,0.1,0.5,0.1,0.6-0.2
        c-0.3-0.1-0.4-0.3-0.1-0.4c0,0.1,0.1,0.3,0.2,0.4c0.2-0.2,0.3-0.4,0.5-0.6c0.2-0.3,0.3-0.5,0.5-0.8c0-0.1,0-0.2,0-0.3
        c0.5-0.3,0.5-0.9,0.9-1.2c-0.2,0.6-0.2,1.2-0.3,1.8l-0.1,0c0.1,0.3-0.1,0.5-0.2,0.7l0.2,0l0,0.4c-0.1,0-0.3-0.1-0.4-0.1
        c0.1,0.1,0.2,0.2,0.3,0.3c-0.3,0.1-0.2,0.3,0,0.4c-0.3,0.1,0,0.5-0.4,0.5c0,0.1,0.1,0.2,0.2,0.3l0,0.2l-0.2,0.1c0-0.1,0-0.3,0-0.4
        c-0.3,0.1,0,0.5-0.1,0.7c0.1,0,0.3,0,0.4,0c-0.2,0-0.3,0.1-0.5,0.1c0.1,0.1,0.2,0.3,0.3,0.4c-0.1-0.1-0.2-0.2-0.3-0.2
        c-0.1,0.3,0,0.6,0.1,0.8c-0.2,0.2-0.5,0.6-0.1,0.8c-0.2,0-0.4-0.2-0.5,0.1l-0.1-0.2c0.3,0,0.2-0.6,0.5-0.5l-0.1-0.1
        c0-0.1,0.1-0.2,0.2-0.3c-0.1,0-0.2,0-0.3-0.1c0-0.2-0.1-0.5-0.1-0.7c0-0.1,0.1-0.2,0.2-0.3c-0.2,0.2-0.3,0.4-0.4,0.6l0.3,0
        c-0.1,0.2,0,0.4,0.1,0.5c-0.1,0.2-0.2,0.4-0.2,0.6c-0.1,0-0.2,0-0.3,0c0,0,0,0.1,0,0.1l0.3,0c0,0.1-0.1,0.2-0.2,0.3l0.2,0
        c-0.2,0.2-0.3,0.4-0.4,0.6c-0.2,0-0.5-0.1-0.5,0.1c-0.2,0.1-0.5,0.1-0.2,0.5l-0.3,0c0.3,0,0.3,0.2,0.3,0.5c-0.2,0-0.3,0.1-0.4,0.3
        c0.1,0,0.2-0.1,0.3-0.2l0,0.2c0.2-0.1,0.5-0.1,0.7-0.1c-0.1,0-0.3,0.1-0.4,0.1c0.1,0.1,0.2,0.2,0.3,0.2c-0.3,0-0.7-0.4-0.8,0
        c0-0.1-0.1-0.2-0.1-0.3c-0.2,0.2-0.4,0.4-0.7,0.5c0-0.1,0-0.3,0-0.4c-0.1,0.1-0.1,0.3-0.2,0.4c0.1,0,0.3,0.1,0.4,0.1
        c0.2-0.3,0.5-0.2,0.7-0.2c0.1,0,0.3,0,0.4,0.1c0,0,0,0.1,0,0.1c-0.2,0-0.5,0-0.7,0l-0.1,0.2c0.1,0.1,0.2,0.1,0.4,0.2
        c-0.4,0.1-0.8,0.2-1.1,0.4c0.1,0.2,0.3,0.5,0,0.7c0-0.1,0-0.4,0-0.5c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.1-0.5,0.1-0.7-0.1
        c0,0.3-0.3,0.5-0.3,0.7c0,0.4,0,0.8,0.4,1c-0.1,0.1-0.2,0.2-0.3,0.3c0-0.1-0.1-0.2-0.1-0.2c-0.2,0-0.3,0.2-0.5,0.3
        c-0.1-0.3,0.2-0.4,0.4-0.6c0-0.1-0.1-0.3-0.1-0.4c-0.2,0.3-0.4,0.7-0.6,1l0.2,0c0,0.1,0.1,0.4,0.2,0.6c0.1-0.2,0.2-0.3,0.3-0.5
        c0.1,0.4-0.2,0.5-0.5,0.5c0-0.2-0.1-0.3-0.1-0.5c-0.2-0.1-0.3-0.2-0.4-0.3c-0.2,0.2,0,0.4,0,0.6c-0.2,0.1-0.3,0.2-0.5,0.4
        c0,0.1,0.1,0.3,0.1,0.4c-0.1,0-0.2,0.1-0.2,0.1c0-0.1-0.1-0.3-0.2-0.5c0.2-0.2,0.5-0.4,0.4-0.7c-0.2,0.1-0.4,0.3-0.4,0.6
        c-0.3-0.1-0.4,0.1-0.6,0.3c0.2-0.1,0.3-0.2,0.5-0.2c0,0.2,0.1,0.4,0.1,0.6c-0.2,0-0.3-0.1-0.5-0.1c-0.1,0.1-0.3,0.2-0.4,0.3
        c0.2,0,0.6-0.2,0.5,0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1-0.1,0.2-0.1,0.3c-0.1-0.3-0.4-0.3-0.7-0.3
        c0,0.2,0,0.4-0.1,0.6c0,0-0.1-0.1-0.2-0.2c-0.2,0.5-0.8,0-1,0.5l0.3,0c0,0.1,0,0.3-0.1,0.4c0.2-0.1,0.4-0.2,0.7-0.2
        c-0.1,0.3-0.4,0.2-0.6,0.2c-0.1,0-0.2,0.1-0.2,0.2c0-0.1,0-0.3,0.1-0.4l-0.3,0.1c0,0.4,0.4,1-0.1,1.2c0-0.2,0-0.4,0-0.6l0.2,0
        c-0.1-0.5-0.5-0.3-0.8-0.3c0,0.1,0.1,0.2,0.2,0.3c-0.1,0.2-0.3,0.4-0.3,0.6c-0.1-0.1-0.2-0.2-0.4-0.4c0.1-0.1,0.2-0.3,0.4-0.4
        c-0.2,0.1-0.3,0.1-0.5,0.2c0,0.1,0.1,0.2,0.1,0.3c-0.2,0.2-0.4,0.3-0.7,0.5c-0.1-0.1-0.2-0.2-0.3-0.3c0,0.2,0,0.5,0,0.7
        c0.2-0.1,0.3-0.3,0.5-0.4l0.1,0.2c-0.3,0.1-0.4,0.4-0.8,0.4c0,0.3,0.1,0.6,0.2,0.9c0,0-0.1,0-0.2-0.1c0.1-0.3-0.2-0.5-0.4-0.8
        c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.3l0.2,0.1l0.1-0.4c0.1,0.1,0.2,0.4,0.2,0.5c-0.3,0.1-0.5,0.2-0.8,0.3
        c0-0.1,0-0.3-0.1-0.4c-0.1,0-0.2,0-0.2,0c-0.1,0.2-0.1,0.4-0.3,0.4c0.5,0.3-0.4,0.9,0.1,1.1c-0.2,0.1-0.4,0.4-0.7,0.3
        c-0.1,0.2-0.2,0.4-0.3,0.6c0-0.1-0.1-0.2-0.1-0.3c-0.2,0.1-0.2,0.5-0.5,0.4c0.2-0.3,0.3-0.5,0.5-0.8c0.1,0,0.2,0.1,0.3,0.2
        c0.1-0.2,0.3-0.4,0.5-0.5c0-0.3,0-0.5,0.1-0.8c0,0-0.1,0-0.2,0c0,0.2,0,0.4-0.1,0.6c-0.1,0-0.3,0-0.3,0.1c0,0.1,0.1,0.3,0.1,0.4
        c-0.1,0-0.4-0.1-0.5-0.1c-0.1,0.2-0.2,0.3-0.2,0.5c-0.1,0-0.2,0.1-0.3,0.2c0,0.2,0.1,0.4,0.2,0.5c-0.1,0-0.2,0.1-0.3,0.2
        c0-0.1,0.1-0.3,0.1-0.4l-0.3,0.1c0.1-0.1,0.1-0.2,0.1-0.4c-0.4,0-0.1,0.5-0.2,0.8c-0.5,0.1-0.6-0.3-0.2-0.4c-0.4,0-0.6,0.4-0.5,0.7
        c0.1,0,0.5-0.3,0.5,0c-0.2,0.1-0.6,0.2-0.5,0.5c-0.3,0.1-0.7,0-0.7,0.4c0.2,0,0.4-0.1,0.6-0.2c-0.1,0.3-0.3,0.3-0.6,0.4
        c0,0.2,0,0.5,0,0.7c0,0-0.1,0.1-0.2,0.1c0-0.1,0-0.4,0-0.5c-0.2,0.1-0.4,0.3-0.6,0.4c0.4,0.1,0.3,0.7-0.1,0.8c0-0.2,0.1-0.5,0.1-0.7
        l-0.2,0.2c0,0.1-0.1,0.3-0.1,0.4c-0.1-0.2-0.1-0.3-0.2-0.5c0,0.3-0.2,0.5-0.3,0.7c0.2-0.1,0.4-0.3,0.6-0.2c-0.1,0.3-0.1,0.7-0.4,0.8
        c0.1,0.5-0.5,1-0.2,1.5c-0.1,0-0.3,0.1-0.4,0.1c0-0.3,0.1-0.5,0.1-0.8c-0.4,0.3-0.2,0.9-0.2,1.3c0,0.3-0.2,0.7,0.1,0.9
        c0,0.2,0,0.3,0,0.5l-0.1,0c-0.1-0.1-0.3-0.1-0.4-0.2c0.2,0.2,0.4,0.4,0.5,0.7c-0.3,0.2-0.2,0.6-0.2,0.9c0-0.1,0.1-0.3,0.1-0.4
        c0.1,0.1,0.1,0.1,0.2,0.2l-0.3,0c0,0.1,0.1,0.3,0.1,0.3l0.1,0.1c0,0.2,0,0.5,0,0.7c0.3-0.1,0.4,0.1,0.4,0.5c0.1-0.1,0.2-0.2,0.3-0.3
        c0,0.2,0,0.4-0.1,0.6c0.4,0.4,0.8,0.9,1.2,1.2c-0.7-0.4-1.2-1-1.7-1.6c0-0.1,0-0.2,0-0.3c-0.3,0.1-0.4-0.2-0.5-0.4
        c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.2-0.3-0.4-0.1-0.6c-0.1,0-0.2,0-0.2,0c-0.4-0.3-0.1-0.9-0.5-1.2c0-0.2,0.3-0.4,0.1-0.6
        c-0.2-0.4-0.3-0.9-0.2-1.3c0.1-0.3,0.4-0.6,0.3-0.9c-0.2-0.5-0.1-1.3,0.4-1.5c-0.1-0.3,0-0.6,0.2-0.8c0-0.2,0-0.5,0.2-0.7
        c0.2-0.3,0.3-0.7,0.6-0.9c0-0.6,0.7-0.9,0.8-1.5c0.2-0.5,0.5-0.9,1-1.1c0.2-0.6,0.6-1.2,1.2-1.6c0.2-0.1,0.2-0.3,0.3-0.5
        c0.4-0.8,1.3-1.1,1.7-1.9c0.7-0.7,1.5-1.4,2.1-2.1c0.6-0.7,1.3-1.2,1.8-2c0.1-0.2,0.2-0.5,0.5-0.7c0.6-0.4,0.9-1.1,1.4-1.6
        c0.4-0.8,1.1-1.4,1.4-2.2c0.2,0,0.4-0.1,0.5-0.4c-0.1,0.1-0.3,0.2-0.5,0.2c0-0.1,0-0.4,0-0.5l0.2,0.1c0-0.1,0-0.2,0-0.3
        c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0.2-0.1,0.3-0.2,0.5c-0.2,0.1-0.4,0.1-0.5-0.1c-0.1,0.1-0.2,0.2-0.2,0.3c0.1,0,0.3,0.1,0.4,0.1
        c0.1-0.1,0.2-0.2,0.3-0.3c0,0.2,0,0.4,0,0.6c-0.5,0.3-1.2,0.4-1.8,0.5c-0.1,0.1-0.2,0.3-0.2,0.4c0.1,0,0.3,0.1,0.4,0.1l0-0.2
        c0.1,0,0.2,0,0.3,0c0.1-0.2,0.2-0.3,0.4-0.4c0,0.4-0.3,0.6-0.5,0.9l0-0.3c-0.2,0-0.5,0.1-0.7,0.2c0,0.1,0.1,0.2,0.2,0.3l-0.2,0
        c0-0.1,0-0.2,0-0.3c-0.3,0-0.5,0.2-0.6,0.6c0.3,0,0.7-0.1,1-0.2c-0.2,0.2-0.4,0.4-0.6,0.6c0-0.1,0-0.3,0-0.5
        c-0.2,0.1-0.4,0.2-0.6,0.2c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0-0.3,0-0.4-0.1l0,0.3c-0.1-0.1-0.2-0.3-0.1-0.4
        l0-0.1c0-0.2-0.1-0.4-0.2-0.5c0,0.3-0.1,0.7,0,1c-0.1,0-0.3-0.1-0.4-0.1c0,0.1,0,0.3,0.1,0.4c-0.1,0-0.2-0.1-0.3-0.2
        c-0.1,0.2-0.2,0.3-0.4,0.3c0,0.1,0,0.3,0,0.4c-0.5-0.2-0.6,0.6-1,0.7c0.5,0.1,0.8-0.4,1.2-0.6c-0.1,0.2-0.2,0.4-0.3,0.6
        c-0.2,0-0.3,0-0.5,0c0.1,0.2,0,0.3-0.3,0.3c0.1,0.3-0.5,0.3-0.3,0.6c-0.1,0.1-0.2,0.2-0.2,0.3c0-0.2,0.1-0.5,0.2-0.7
        c-0.2,0-0.3,0-0.4,0c0,0.2,0,0.3,0,0.5c-0.3,0-0.5,0.1-0.4,0.5l-0.2-0.1c0,0.1,0.1,0.3,0.1,0.3c-0.1,0-0.4-0.1-0.5-0.1
        c0,0.1,0.1,0.3,0.1,0.4c-0.2,0.1-0.5,0-0.7-0.1c0.4,0.3-0.2,0.8,0.2,1.1c-0.1,0-0.2,0.1-0.3,0.1c0.1-0.2,0.2-0.4,0.2-0.6
        c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0.4-0.7,0.7-0.9,1.2c-0.5,0.5-0.7,1.3-1.4,1.6c0.2,0,0.3,0,0.5,0l0-0.3c0.1,0.1,0.2,0.1,0.3,0.2
        c-0.2,0.1-0.4,0.2-0.7,0.2l0.1,0.2c-0.5,0-0.6,0.6-1,0.7l0.3,0c-0.1,0.1-0.3,0.2-0.4,0.3c0,0.1,0,0.2,0,0.2l-0.2,0
        c0,0.1-0.1,0.3-0.1,0.4c-0.6,0.8-0.8,1.8-1,2.7c0,0-0.1,0-0.1,0c0.3-0.9,0.2-2,0.5-2.9c0.1-0.2,0-0.5,0.3-0.6c0-0.1,0-0.3,0-0.3
        c0.2-0.8,0.7-1.5,0.8-2.4c0.4-0.7,0.8-1.3,1.2-2c0.2-0.5,0.5-1,0.8-1.4c0.1-0.2,0.3-0.3,0.5-0.5c0-0.4,0.4-0.4,0.6-0.7
        c0.3-0.5,0.7-0.9,1-1.4c0.5-0.2,0.5-0.7,1-0.9c0.3-0.7,0.9-1.1,1.3-1.8c0.1,0,0.2,0.1,0.3,0.1c0.1-0.4,0.4-0.7,0.8-0.8
        c0-0.1,0-0.3,0-0.4c0.4,0,0.7-0.3,0.8-0.7c0.1,0,0.2-0.1,0.3-0.1c0.2-0.2,0.3-0.5,0.5-0.7c-0.3-0.3,0-0.6,0.3-0.4
        c0.1-0.1,0.1-0.2,0.2-0.3c-0.1,0-0.2,0-0.3,0.1c-0.1-0.2,0-0.3,0.2-0.3c0.1-0.5,0.3-0.9,0.7-1.2c0-0.1,0-0.3,0-0.4
        c0.1,0,0.3-0.1,0.3-0.1c0-0.2,0-0.4,0-0.5c0.1-0.1,0.3-0.1,0.4-0.2l0-0.1c-0.1,0-0.2,0-0.3,0c0.1-0.3,0.4-0.4,0.6-0.6
        c-0.1-0.2-0.2-0.3-0.4-0.5c0.2-0.1,0.4-0.2,0.6-0.3c-0.2-0.2-0.2-0.4-0.1-0.7l-0.3,0.1c0,0,0-0.1,0-0.2c0.2,0,0.4,0,0.6,0
        c-0.1-0.1-0.2-0.3-0.2-0.5c0.2,0,0.4,0,0.5-0.2c-0.1,0-0.4,0-0.5,0c0.1-0.2,0.3-0.4,0.4-0.7c0.3-0.2,0.1-0.5-0.2-0.5c0,0,0,0,0,0
        c0.2-0.1,0.3-0.1,0.5-0.2c-0.1-0.1-0.2-0.2-0.3-0.2c0.1-0.2,0.7-0.4,0.2-0.5c0.1-0.2,0.2-0.4,0.4-0.6c-0.1,0.1-0.2,0.2-0.3,0.3
        c-0.2-0.3-0.1-0.5,0.2-0.5c-0.4-0.8-0.3-1.7-0.7-2.5c-0.2-0.4-0.2-0.9-0.5-1.3c-0.1,0-0.2,0-0.3,0c0-0.1,0.1-0.3,0.2-0.4
        c-0.1,0-0.3,0.1-0.3,0.1c0-0.1,0-0.2-0.1-0.3c-0.1,0.1-0.3,0.2-0.4,0.2c0.3-0.3-0.1-0.5-0.4-0.7c0,0.2,0.1,0.5,0.2,0.7
        c-0.1-0.1-0.2-0.2-0.3-0.4c-0.3,0.2-0.6-0.3-0.9,0c-0.4,0-0.8,0-1.2,0.1c-0.5,0-1,0-1.4,0.3c-0.1-0.1-0.3-0.1-0.4-0.2
        c-0.2,0.1-0.3,0.3-0.5,0.4c-0.2,0-0.5,0-0.7,0c-0.4,0.5-1.1,0.8-1.8,0.8c-0.2,0.4-0.7,0.4-1.1,0.4c-0.3,0.3-0.7,0.4-1.1,0.6
        c-0.6,0.3-1.2,0.6-1.8,0.8c-0.5,0.1-0.8,0.6-1.3,0.7c-0.2,0-0.4,0.2-0.6,0.3c-1.2,0.7-2.6,0.9-3.8,1.6c-0.3,0.3-0.7,0.4-1.1,0.4
        c-0.3,0.2-0.5,0.6-1,0.6c-0.3,0-0.4,0.4-0.7,0.4c-0.4,0-0.7,0.2-1.1,0.3c-0.2,0.2-0.6,0.2-0.9,0.3c-0.5,0.3-1.1,0.3-1.6,0.6
        c-0.5,0.1-0.9,0.4-1.4,0.4c-0.3,0.1-0.6,0.3-1,0.3c-0.4-0.1-0.1,0.4-0.4,0.5l0.1,0.2c0.1-0.1,0.2-0.2,0.3-0.2c0,0.1,0,0.4,0,0.5
        c-0.4,0.1-0.6-0.3-0.9-0.3c0-0.1,0-0.2,0-0.2l-0.1,0c0,0.5,0.6,0.7,1.1,0.8c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.2,0.2,0.4,0.3,0.6
        c-0.2-0.2-0.5-0.4-0.6-0.7c-0.2,0.1-0.3,0.1-0.5,0.2c-0.1-0.2-0.2-0.3-0.3-0.5c0,0.1-0.1,0.2-0.1,0.2c0.3,0.2,0.6,0.4,0.9,0.6
        c0.1,0.2,0.1,0.4,0,0.7l0.3-0.1c-0.1,0.1-0.2,0.4-0.2,0.5c0.1-0.1,0.3-0.2,0.4-0.3l0,0.3c0,0.1,0.1,0.4,0.1,0.5
        c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0.1,0.3,0.2,0.4,0.3c-0.2,0.3-0.5-0.1-0.8,0.1c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.3,0-0.4
        c-0.1,0-0.2,0.1-0.2,0.1c0,0.3,0.4,0.4,0.7,0.6c0.1,0,0.2,0,0.3,0c0,0.3,0.1,0.6,0.1,1c0.1,0.1,0.2,0.2,0.3,0.2l0.2-0.1
        c0,0.1,0,0.4,0,0.6c0.5-0.2,0.2-0.8-0.1-0.9c-0.3-0.1-0.1-0.5,0.1-0.5c0,0.1-0.1,0.3-0.1,0.4c0.1,0,0.2,0,0.2,0
        c0.2,0.4,0.3,0.8,0,1.2l0.3-0.1c0,0.2,0,0.3-0.1,0.5c-0.2-0.2-0.3-0.4-0.5-0.5c-0.1,0.3,0.2,0.5,0.5,0.5c-0.2,0.4-0.1,0.8-0.2,1.2
        c0.1,0,0.2-0.1,0.3-0.2c0.1-0.4,0.2-0.8,0.3-1.1c0.2,0.2,0.1,0.5,0.1,0.8c0.1,0.1,0.3,0.3,0.4,0.4c-0.1,0-0.3-0.1-0.3-0.1
        c-0.1,0-0.3,0.1-0.4,0.1c0.1,0.4,0.4,0.2,0.6,0.2l0,0.2c-0.1,0-0.3-0.1-0.4-0.1l0,0.2c0.1,0,0.3-0.1,0.4-0.1
        c-0.1,0.1-0.2,0.3-0.4,0.3l0-0.2c-0.1,0-0.3,0-0.4,0c0.2,0.1,0.3,0.3,0.5,0.4c0.2,0,0.3,0.1,0.5,0.2c0.1,0,0.3,0.1,0.4,0.1
        c0,0-0.1,0.1-0.1,0.2c-0.2-0.2-0.4-0.4-0.7-0.3l0.2,0.3c-0.1,0-0.2,0.1-0.2,0.1c0,0,0.1,0.1,0.1,0.2c0.2-0.2,0.4-0.2,0.6,0.1
        c-0.2,0.1-0.3,0.1-0.5,0.2l0.2,0c-0.1,0.4,0.4,0.4,0.6,0.7c-0.1,0.1-0.4,0.2-0.5,0.3c0-0.1,0-0.2,0-0.3l-0.1,0
        c0.1,0.2,0,0.8,0.5,0.4c-0.3,0.5-0.5,1.2-0.1,1.7c0,0,0.1-0.1,0.2-0.2c-0.1,0-0.2,0-0.3,0c-0.1-0.4,0.2-0.9,0.1-1.4
        c0.1,0.1,0.2,0.4,0.3,0.5c0.2,0.1,0.5-0.1,0.6,0.2c-0.2,0-0.5-0.1-0.7-0.2c0.1,0.3,0,0.8,0.5,0.8c-0.1-0.1-0.2-0.3-0.3-0.4
        c0.2,0,0.4,0.1,0.5,0.2c-0.1,0.2-0.4,0.3-0.2,0.6c0.1,0,0.3,0,0.4,0c0.1,0.3,0.2,0.5,0.4,0.7c-0.2-0.1-0.3-0.2-0.4-0.2
        c0-0.1,0.1-0.3,0.1-0.4c-0.3,0.1-0.7,0.2-1,0c0.1,0.1,0.2,0.3,0.2,0.4c0.1-0.1,0.3-0.2,0.4-0.3c-0.3,0.5,0.3,0.7,0.4,1.1
        c0,0.1-0.1,0.2-0.1,0.3c0.1,0.2,0.2,0.4,0.2,0.6c0.3-0.1,0.3-0.3,0.2-0.6c0,0.1,0.1,0.2,0.2,0.3c0,0,0.1,0,0.2,0.1
        c-0.5,0-0.3,0.9-0.8,1.1l0.4-0.1c0.1-0.3,0.3-0.5,0.6-0.7c0,0.1-0.1,0.3-0.1,0.4c0,0.1,0.1,0.2,0.1,0.2l0.2-0.1c0,0,0,0.1,0,0.1
        c-0.3,0-0.5,0-0.8,0c0.1,0.2,0.2,0.4,0.3,0.6c0.2-0.2,0.5-0.2,0.7-0.1c-0.2,0.1-0.3,0.2-0.5,0.3c0,0.1,0,0.4,0,0.5
        c0.2-0.2,0.4-0.3,0.5-0.5c0,0,0.1,0.1,0.1,0.1c-0.2,0.2-0.4,0.6-0.4,0.9c0.1,0,0.3,0,0.4,0c-0.1,0.2-0.2,0.5-0.3,0.7
        c-0.1-0.1-0.2-0.2-0.3-0.3c0.1,0.2,0.1,0.4,0.1,0.6c0.1-0.1,0.3-0.2,0.4-0.3c0.1,0.2,0.3,0.4,0.3,0.7c0.1,0,0.2-0.1,0.3-0.2
        c0,0.2,0,0.5,0,0.7c0.1,0,0.2-0.1,0.2-0.1c0.1-0.1,0.1-0.3,0.2-0.4c0.3,0.1,0.2,0.3,0,0.5l0,0.1c-0.1,0.1-0.2,0.2-0.3,0.3
        c0.1,0.1,0.4,0.3,0.2,0.4c-0.2,0.2-0.3,0.4,0,0.6c-0.1-0.3,0.1-0.4,0.4-0.6c0-0.1,0-0.3,0.1-0.5c0.1,0.1,0.2,0.2,0.3,0.2
        c-0.3,0.4,0,0.9,0.1,1.3c-0.1,0.3-0.2,0.5-0.3,0.8c0.1-0.1,0.2-0.3,0.3-0.4c0.3,0.1,0,0.4,0,0.6c0.1-0.1,0.3-0.3,0.3-0.5
        c0-0.2,0-0.3,0-0.5c0.1,0,0.2,0,0.3,0c0,0.1-0.1,0.3-0.1,0.4c0.1,0.1,0.2,0.3,0.3,0.4c-0.4,0.2-0.1,0.6-0.2,0.9c0,0,0.1,0.2,0.2,0.2
        c0,0.1,0,0.3-0.1,0.4c0.2,0.1,0.4,0.3,0.6,0.5c-0.2,0-0.3,0-0.5,0l0,0.1c0.1,0,0.3,0,0.4,0c-0.1,0.1-0.2,0.3-0.3,0.3
        c0.3,0,0.5-0.2,0.7-0.4c0,0.1,0,0.2-0.1,0.2l-0.2,0.1c0,0.1,0.1,0.3,0.1,0.4c0.2,0,0.4,0.1,0.6,0.3c-0.2,0-0.3,0-0.4-0.1
        c-0.2,0.1-0.4,0.1-0.6,0.2c0.1,0,0.3,0,0.4,0c-0.2,0.2-0.5,0.7-0.1,0.8c0-0.3,0-0.6,0.2-0.9c0.1,0.3,0.2,0.4,0.4,0.6
        c-0.1,0.2-0.2,0.5-0.5,0.6c0.2,0.2,0.2,0.6,0.2,0.8c0,0,0.1,0,0.1,0c0-0.3-0.1-0.7,0.1-1c0.2-0.1,0.3-0.3,0.3-0.5c0,0,0.1,0,0.1,0.1
        c-0.1,0.2,0,0.4,0,0.6c-0.1,0-0.3,0-0.4,0c0.1,0.4,0.1,0.7-0.1,1.1c0.4-0.1,0.4-0.5,0.5-0.8c0.2-0.1,0.3,0.1,0.4,0.2
        c-0.1,0-0.3,0.1-0.3,0.1c0.1,0.2,0,0.4-0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0,0.2-0.1,0.3-0.1c0.1,0.1,0.3,0.3,0.3,0.4
        c-0.2,0-0.6-0.2-0.5,0.2c0.1,0.1,0.3,0,0.4-0.2c0,0.1,0,0.2,0,0.2c-0.1,0-0.2,0-0.2,0c0,0.1,0,0.2,0,0.2c0.1,0,0.3,0,0.4,0
        c0,0.2-0.1,0.4-0.2,0.6c0.4,0.2,0.8,0.4,1.1,0.8c-0.1,0-0.2,0.1-0.3,0.1c0.2,0.3,0.7,0.3,0.5,0.7c-0.3-0.1-0.4-0.4-0.6-0.7
        c-0.1,0.4,0.2,0.7,0.6,0.9c-0.3,0.1-0.3,0.3-0.2,0.5c0.2-0.2,0.4-0.4,0.5-0.7c0.1,0,0.2,0.1,0.2,0.1c-0.1,0.2-0.3,0.3-0.4,0.5
        c0.1,0.1,0.2,0.2,0.3,0.3c0,0.1,0,0.3,0,0.4c0.3,0,0.7,0,0.7,0.4c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0.2-0.2,0.5-0.3,0.7
        c0.2-0.2,0.3-0.3,0.5-0.1l0.1-0.2c0.4-0.2,0.1,0.3-0.1,0.2c0,0.1,0.1,0.3,0.1,0.3c-0.1,0-0.3,0.1-0.3,0.1c0,0.1,0,0.2,0,0.3
        c0.3,0.1,0.7,0,0.6,0.4c-0.3-0.1-0.5-0.1-0.8,0.1c0.3,0,0.5,0,0.7,0.2c-0.1,0.2-0.2,0.3-0.3,0.5c0.3-0.1,0.5-0.3,0.4-0.6
        c0.4,0,0.2,0.4,0.4,0.6c-0.1,0.1-0.2,0.2-0.3,0.3c0.3-0.1,0.5,0,0.7,0.2c-0.1,0.1-0.2,0.2-0.2,0.2l0,0.2c0.1,0,0.3-0.1,0.4-0.2
        c0,0.1-0.1,0.3-0.1,0.5l-0.2-0.1c0.1,0.1,0.2,0.3,0.2,0.5l0.2-0.1c0,0.1,0,0.2-0.1,0.3c0.3,0.1,0.6,0.1,0.9,0.2c0.2,0.1,0,0.3,0,0.4
        c-0.1-0.1-0.2-0.3-0.3-0.4c-0.5,0.2,0,0.4,0.1,0.7c0.1-0.1,0.3-0.2,0.3-0.2c-0.1,0.1-0.2,0.2-0.2,0.3c0.1,0.1,0.2,0.2,0.4,0.2
        c-0.2,0.1-0.5,0.2-0.7,0.3c0.3,0.1,0.5,0.1,0.8,0c0,0,0.1,0.1,0.1,0.2c-0.2,0-0.5,0-0.6,0.2c0.1,0.1,0.2,0.3,0.3,0.4
        c0.3,0.1,0.6,0.1,0.3,0.5c-0.1-0.1-0.2-0.3-0.2-0.4l-0.1,0.1c0.2,0.2,0.4,0.5,0.4,0.8c0.1-0.2,0.4-0.7,0.4-0.1c0.1,0,0.2,0,0.3,0.1
        c-0.1,0.1-0.2,0.3-0.3,0.4c0.1,0,0.2,0,0.2,0c0.1-0.3,0.2-0.5,0.4-0.7c-0.1,0-0.2-0.1-0.2-0.1c0.2-0.3,0.5-0.5,0.7-0.8
        c-0.1,0-0.3,0-0.4,0c0.7-0.1,0.3-0.9,0.7-1.3c0.2-0.4,0.3-0.9,0.5-1.3c0.1-0.2,0.1-0.6,0-0.8c0,0,0-0.1,0-0.1
        c0.1,0.1,0.2,0.2,0.2,0.2c0.3-0.2,0.5-0.5,0.3-0.8c-0.3-0.1-0.3-0.4-0.3-0.6c0.1,0.2,0.3,0.5,0.4,0.7c0.1-0.5,0.4-0.9,0.5-1.3
        c0-0.2,0.1-0.3,0-0.5c0.1,0,0.3-0.1,0.4-0.1c-0.1-0.1-0.3-0.3-0.4-0.4c0.2-0.1,0.4,0,0.6,0c0.1-0.1,0.2-0.3,0.3-0.5
        c-0.1,0-0.3,0-0.4,0c0.1-0.1,0.2-0.2,0.4-0.2c0.2-0.3,0.3-0.6,0.4-1c0,0-0.1-0.1-0.1-0.2c0.1,0,0.2,0,0.3,0l0-0.5
        c-0.1,0.1-0.2,0.1-0.3,0.2c0,0-0.1-0.1-0.2-0.1c0.3-0.1,0.5-0.1,0.8-0.2c0.3-0.3,0.3-0.7,0.4-1.1c0.1-0.1,0.3-0.2,0.4-0.3
        c0-0.2,0.1-0.4,0.2-0.6c-0.1,0-0.4,0.1-0.5,0.1c0.3-0.3,0.7-0.2,0.9-0.6c0-0.3,0.1-0.7,0.3-0.9c0.1-0.2,0.1-0.4,0.2-0.6
        c0.5-0.4,0.9-0.9,1-1.6c0.1-0.4,0.5-0.6,0.6-0.9c0.3-0.4,0.5-0.9,0.9-1.3c0.1-0.2,0-0.3-0.2-0.3c0-0.1,0.1-0.2,0.2-0.2
        c0.3,0.1,0.6-0.3,0.6-0.6c0.3,0,0.4-0.2,0.5-0.4c-0.2-0.1-0.3-0.4-0.3-0.6c0.2,0.1,0.3,0.4,0.5,0.5c0.2-0.2,0.2-0.5,0.3-0.8
        c0.1-0.3,0.5-0.5,0.6-0.7c0.1-0.3,0.2-0.5,0.4-0.8c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1,0.1-0.2,0.2-0.3,0.3c0.1-0.1,0.1-0.2,0.2-0.4
        c0.1,0,0.2,0.1,0.4,0.1c0.2-0.2,0.4-0.5,0.6-0.7c-0.3,0-0.5,0.3-0.7,0.4c0.1-0.2,0.2-0.3,0.3-0.4c0.1,0,0.3,0,0.4,0
        c0.3-0.4,0.4-0.9,0.9-1.1c-0.1-0.1-0.3-0.2-0.5-0.2c0.5,0.1,0.8-0.4,1-0.8c0-0.1,0-0.2,0-0.3c0.2-0.1,0.4-0.2,0.5-0.4
        c0-0.1-0.1-0.2-0.1-0.3c0.4,0.2,0.5-0.3,0.7-0.5c0.2-0.2,0.6,0,0.7-0.3l-0.3-0.1c0.1-0.2,0.3,0,0.5,0c-0.1-0.2-0.1-0.3-0.2-0.4
        c0.1-0.1,0.2-0.2,0.2-0.2c0,0.1,0.1,0.2,0.1,0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.3,0.2-0.4c0-0.1-0.1-0.2-0.1-0.2
        c0.1-0.4,0.3-0.8,0.8-0.9c0.1-0.3,0.1-0.8,0.6-0.7c0.1-0.1,0.2-0.2,0.3-0.3c0-0.2,0.1-0.4,0.1-0.6c0.2-0.2,0.5-0.3,0.5-0.7l0.3-0.1
        c-0.1-0.1-0.3-0.2-0.4-0.3c0.1,0,0.2-0.1,0.3-0.1c0-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0.3-0.4,0.5-0.5c-0.2,0.3-0.4,0.5-0.6,0.7
        c0.1,0,0.2,0.1,0.3,0.1c0.2-0.4,0.4-0.7,0.7-1c0.2-0.2,0.5-0.3,0.4-0.6c0.3,0,0.7,0,0.8-0.3c-0.1,0-0.4,0-0.5,0
        c0.1,0,0.3-0.1,0.5-0.1c0.2-0.2,0.3-0.4,0.5-0.6c0.1-0.4,0.7-0.5,0.7-1c0.5-0.6,1.4-0.8,1.9-1.6c0-0.1,0-0.3,0-0.3
        c0.2-0.2,0.4-0.4,0.7-0.3c0-0.1-0.1-0.3-0.1-0.4c0.1,0.1,0.2,0.2,0.3,0.4c0.1-0.2,0.3-0.4,0.5-0.5c-0.1-0.1-0.1-0.2-0.1-0.3
        c0.2-0.2,0.7-0.3,0.4-0.7l0.1,0l0.1,0.2l0.1,0c0.1,0,0.2-0.1,0.3-0.2c-0.1,0-0.2-0.1-0.3-0.1c0.1,0,0.3,0,0.4,0.1c0-0.1,0-0.2,0-0.3
        l0.3,0c-0.1-0.1-0.1-0.3-0.2-0.4c0.1,0,0.2,0.1,0.4,0.2c0.5-0.6,1.3-1,1.8-1.6c0.6-0.4,1.1-1,1.8-1.3c0.2-0.3,0.4-0.7,0.8-0.7
        c0.4-1,1-2,1.5-3c0.6-1.3,1.4-2.5,1.9-3.9c0.3-0.7,0.7-1.3,1-2l-0.3,0.1c0,0,0-0.1,0-0.2c0.1,0,0.2,0,0.4,0.1c-0.2-0.4,0-0.7,0-1.1
        l-0.1,0c0,0.2-0.1,0.3-0.2,0.4c0-0.1,0-0.3,0-0.4c0.3-0.3,0.1-0.8,0.2-1.2c0,0.1-0.1,0.2-0.1,0.3c-0.1,0-0.2,0-0.2-0.1
        c0.1,0.1,0.2,0.2,0.3,0.3l-0.2,0c0.1,0.5-0.4,0.8-0.2,1.3c-0.1,0-0.2,0-0.2,0c0,0.2-0.1,0.5,0.1,0.6c0,0.1,0,0.3,0,0.4
        c-0.1,0-0.3,0-0.4-0.1c0,0.1-0.1,0.3-0.1,0.4c0.1,0,0.3-0.1,0.3-0.1c-0.1,0.2-0.2,0.4-0.4,0.6l0.3-0.1c0,0,0,0.1,0,0.1
        c-0.1,0-0.2,0-0.3,0c-0.2,0.4-0.2,0.9-0.4,1.3c-0.1,0-0.3,0-0.4,0c0.1,0.2,0,0.4-0.2,0.6c0,0.2-0.1,0.4-0.2,0.6
        c-0.2,0-0.4,0.1-0.6,0.1c0,0,0,0.1,0,0.1c0.1,0,0.4-0.1,0.6-0.1c-0.1,0.2-0.3,0.4-0.5,0.4c0,0.1,0,0.2,0,0.3
        c-0.1,0.1-0.3,0.3-0.4,0.4c0.2,0,0.4,0,0.6,0c-0.2,0.1-0.6,0-0.5,0.3l-0.1,0c0,0.1-0.1,0.2-0.2,0.3c0,0.1,0,0.3,0,0.4
        c-0.1,0-0.2,0-0.2,0c0,0.2,0,0.4,0,0.6c0,0-0.1,0-0.1,0c0-0.1,0-0.2,0-0.2c-0.3-0.3-0.4,0.3-0.5,0.6c-0.1,0-0.2,0.1-0.3,0.1
        c0,0.1,0,0.2,0,0.3c-0.1,0-0.2,0-0.3,0c0,0.1-0.1,0.4-0.1,0.5c-0.1-0.1-0.2-0.2-0.3-0.2c0,0.3-0.1,0.5-0.1,0.8
        c-0.1,0-0.2-0.1-0.3-0.1c0,0.1,0,0.3,0,0.4c-0.6,0.7-1.2,1.4-1.7,2.2c0,0-0.1,0-0.1,0.1c0-0.1,0.1-0.2,0.2-0.2
        c0.4-0.9,1.2-1.7,1.3-2.7c0.1-0.6,0.4-1.1,0.5-1.7c0-0.2,0.2-0.4,0.3-0.5c0-0.4,0.3-0.8,0.3-1.2c0.1-0.3,0.2-0.6,0.4-0.8
        c0.2-0.4,0.6-0.8,0.7-1.3c0-0.1-0.1-0.2-0.1-0.3c-0.1,0.3-0.3,0.5-0.5,0.8c0,0.4-0.3,0.8-0.5,1.1c0-0.2,0.1-0.5,0.1-0.6
        c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.1-0.3,0.2c0.1,0,0.2-0.1,0.3-0.2c0,0.1,0.1,0.4,0.1,0.5l-0.2,0c0,0.3-0.3,0.5,0,0.8
        c-0.1,0-0.3,0-0.4,0c0.1,0.2-0.1,0.5-0.1,0.7c0,0.1-0.1,0.2-0.2,0.3l-0.2,0c0,0.1,0,0.3,0,0.4c-0.1,0-0.2,0-0.3,0.1l-0.2,0.4l0.4,0
        c-0.2,0.1-0.6,0-0.5,0.3c-0.4,0.1-0.3,0.8-0.8,0.8c0,0.2-0.2,0.3-0.3,0.4c0,0.1,0,0.3,0,0.4c0,0-0.1,0-0.2,0
        c-0.6,0.8-1.1,1.7-1.9,2.3c0.3-0.3,0.5-0.6,0.7-1c0.2-0.5,0.5-0.9,0.7-1.3c0.2-0.6,0.5-1,0.9-1.5c0.2-1.3,1.2-2.4,1.6-3.6
        c-0.1,0-0.2,0.1-0.3,0.1c0.4-0.2,0.4-0.6,0.6-1c0.4-0.8,0.8-1.7,1.3-2.6c0.5-1.7,1.1-3.3,1.7-4.9c0.3-0.4,0.5-0.8,0.7-1.2
        c0,0,0.1-0.1,0.2-0.2c-0.1-0.3,0-0.6,0.4-0.8c-0.2-0.5,0.3-0.6,0.4-1c0.2-0.6,0.6-1,0.8-1.5c-0.1-0.3-0.1-0.6-0.1-1
        c0.1,0.1,0.2,0.3,0.2,0.4c-0.2,0.2,0.1,0.4,0.2,0.5c0-0.2,0.1-0.4,0-0.6c0-0.1-0.3-0.4,0-0.4c0-0.1,0-0.2,0-0.3
        c-0.1,0.1-0.3,0.2-0.4,0.3c0.1-0.2,0.2-0.4,0.3-0.7c-0.1-0.1-0.2-0.2-0.2-0.2l0.3,0c-0.1-0.2-0.2-0.4-0.3-0.7
        c0.1-0.1,0.3-0.2,0.2-0.4c-0.2,0.1-0.4,0.2-0.6,0.4c0.1,0.1,0.2,0.2,0.3,0.3c-0.2,0-0.4,0.2-0.5,0.4c0.1,0,0.2-0.1,0.3-0.1
        c0.2-0.1,0.5-0.6,0.4,0c-0.1,0.1-0.3,0.3-0.4,0.4l0.4-0.1c-0.1,0.2-0.2,0.3-0.2,0.5c-0.1,0-0.2,0-0.2,0c0,0.1,0.1,0.2,0.2,0.3
        c-0.1,0-0.2,0-0.3,0l-0.1,0.3c0.1,0,0.3,0,0.4-0.1c-0.1,0.3-0.8-0.1-0.3,0.4c-0.1,0-0.3,0-0.4,0c0,0.2,0,0.4,0,0.6
        c-0.3-0.2-0.3,0.1-0.3,0.3l0.2,0c-0.2,0.2-0.4,0.5-0.4,0.8c-0.1-0.1-0.2-0.2-0.3-0.2c0,0.1,0,0.2,0,0.3l0.3-0.1
        c-0.4,0.6-0.7,1.2-0.9,1.9c-0.1-0.1-0.2-0.2-0.2-0.3c0,0.2,0.1,0.4,0.1,0.6c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.2-0.1,0.4-0.2,0.6
        l-0.1,0c-0.1,0.3-0.2,0.6-0.3,0.8c0,0-0.1,0-0.2,0c-0.3,0.9-0.7,1.8-1.5,2.3c0.2-0.2,0.4-0.4,0.5-0.6c0.2-0.3,0.3-0.6,0.4-0.9
        c0.4-1.1,0.8-2.2,1-3.3c0.5-0.4,0.2-1,0.4-1.5c0.5-1.1,0.5-2.3,0.9-3.4c0.2-0.3,0.3-0.6,0.2-0.9c0.1-0.2,0.2-0.4,0.2-0.6
        c0,0,0.1-0.1,0.2-0.2c0,0-0.1,0-0.2,0c-0.4,0.7-0.3,1.5-0.7,2.2c-0.3,0.4-0.2,1-0.6,1.4c0,0.2-0.1,0.4-0.2,0.6
        c-0.2,0.3-0.2,0.8-0.6,1c0.5-0.8,0.6-1.7,0.9-2.5c0-0.1,0-0.2,0-0.3c0.5-1.3,0.5-2.9,1.2-4.1c-0.1,0-0.3,0-0.4,0
        c-0.1-0.1-0.3-0.2-0.5-0.2C188.2,86.6,188,87,187.6,86.8 M326.1,86.3c0,0.3,0,0.6,0,0.9c0.1-0.1,0.2-0.1,0.4-0.2
        c0.2,0,0.4,0.1,0.6,0.4c0,0-0.1,0-0.2,0c-0.1-0.4-0.5,0-0.7,0c0,0.2,0,0.4-0.1,0.6c0.1,0,0.2,0,0.3,0.1c0,0.3,0,0.6,0,0.9
        c0.1,0.1,0.2,0.1,0.2,0.2c0-0.1,0-0.2,0-0.3l-0.2,0c0-0.2,0.1-0.3,0.2-0.4c0,0.1,0,0.3,0,0.4c0.1,0,0.2,0.1,0.2,0.2
        c0,0,0.1,0.1,0.2,0.1c-0.1,0-0.2,0-0.3,0c-0.1,0.3,0.3,0.4,0.3,0.7c0,0.3,0.2,0.5,0.3,0.8c-0.2-0.1-0.4-0.2-0.5-0.3
        c0.1,0.2,0.3,0.4,0.4,0.6c0.1,0,0.2,0,0.2,0l0,0.4c-0.1,0-0.4-0.1-0.5-0.1c0.2,0.1,0.4,0.2,0.6,0.2c0,0.1,0,0.3,0,0.4l-0.2,0
        c0.2,0.1,0.3,0.3,0.4,0.5l0.1,0c0,0.2,0,0.5,0,0.7c0.1,0.1,0.2,0.3,0.2,0.5c0.1,0,0.2,0,0.3-0.1c0,0.1,0,0.3,0,0.4l0.1,0
        c0.2,0.5,0.5,0.9,0.7,1.4c0.2,0.6,0.7,0.8,1.1,1.3c-0.5-0.5-1.1-0.9-1.5-1.5c-0.2-0.2-0.3-0.6-0.6-0.7c0-0.4-0.3-0.6-0.6-0.8
        c-0.1-0.3-0.3-0.6-0.3-0.9c-0.5-0.5-0.5-1.2-0.9-1.7c0.1-0.2,0.2-0.5-0.2-0.6c0.1,0.9,0,1.8,0,2.7c0.1,0.2,0.1,0.4,0.2,0.6
        c-0.1,0.1-0.2,0.2-0.3,0.2c0.1,0,0.3,0,0.4,0l-0.1,0.2l0.1,0c0.1,0.2,0.2,0.4,0.2,0.5l0.1,0c0,0.2,0,0.4,0,0.7
        c0.1,0,0.3,0.1,0.4,0.1c0,0.1,0,0.2,0,0.3c0.3,0.1,0.3,0.4,0.5,0.6c0.1,0.2,0.2,0.3,0.2,0.6c0.1,0,0.2,0,0.3,0l-0.1,0.2l0.2-0.1
        c0.1,0.4,0.4,0.6,0.5,1c0.1,0,0.2,0,0.2,0c0,0.2,0,0.5,0.3,0.5c0,0.1,0,0.2,0,0.2l0.2,0c-0.1,0.3,0.3,0.5,0.3,0.8
        c0.1,0,0.2-0.1,0.3-0.1c0,0.1,0,0.3,0,0.4c0.3,0.1,0.4,0.4,0.5,0.7c0.1,0,0.3-0.1,0.3-0.1c-0.1,0.2-0.1,0.4-0.2,0.6
        c0-0.1,0-0.3,0-0.4c-0.2,0-0.4,0.4-0.5,0.1c-0.1-0.1-0.2-0.3-0.4-0.3c0,0,0.1,0.1,0.2,0.2c0,0.3,0.3,0.5,0.4,0.8
        c0.1-0.1,0.1-0.2,0.2-0.3c0.1,0.1,0.3,0.2,0.4,0.3c0,0.2,0,0.5,0,0.8c0-0.1,0.1-0.2,0.1-0.3c0.3,0.1,0.2,0.4,0.2,0.6
        c0.1-0.1,0.2-0.2,0.3-0.3c0,0.2,0,0.5,0.1,0.7c0.3,0.2,0.5,0.4,0.6,0.7c0.3,0.3,0.7,0.6,1,0.9c0.2,0,0.3-0.1,0.5-0.1
        c0-0.1-0.1-0.2-0.1-0.3l0.4,0l-0.1,0.2c0,0,0.1,0,0.2,0c0-0.1,0-0.2,0-0.2c0.2-0.1,0.4-0.3,0.7-0.3c0.1-0.3,0.1-0.5,0.1-0.8
        c0.2,0.1,0.5,0.3,0.5-0.1c0.1-0.1,0.3-0.1,0.4-0.2c-0.2-0.4-0.5,0.1-0.7,0.1c0.1-0.1,0.1-0.3,0.2-0.4c0.3,0,0.5,0,0.9,0
        c-0.1-0.1-0.1-0.3-0.2-0.4c0.1-0.1,0.2-0.1,0.4-0.2c-0.1,0.2-0.1,0.4-0.1,0.5c0.1-0.2,0.2-0.3,0.4-0.5c-0.1-0.1-0.1-0.3-0.2-0.4
        c0,0,0.1-0.1,0.2-0.2c0,0.1,0,0.2,0,0.3l0.1,0.1c0-0.1,0-0.3,0-0.4c0.5,0.4,0.4-0.6,0.9-0.4l0-0.1c0.2,0,0.4,0,0.6-0.1
        c-0.1,0-0.2-0.1-0.3-0.1c0-0.2,0-0.3,0.1-0.5c0.1,0,0.2,0,0.3,0c0,0.1,0.1,0.3,0.1,0.4c0-0.3,0.1-0.5,0.3-0.7
        c0.2,0.2,0.4,0.3,0.6,0.2c-0.2-0.1-0.3-0.2-0.5-0.4c0.4,0,0.7-0.1,1.1,0c-0.1-0.1-0.2-0.2-0.2-0.2c0.2-0.2,0.5-0.3,0.7,0
        c0-0.2,0-0.5,0-0.7c0.1,0.1,0.4,0.2,0.5,0.3c0-0.2-0.1-0.4-0.2-0.6l0.1,0l0,0.2c0.1,0,0.2-0.1,0.3-0.2c0.2,0,0.7,0.4,0.6-0.1
        c-0.1,0-0.4,0-0.5,0c0.2-0.2,0.4-0.2,0.7-0.1c0.1,0,0.2-0.1,0.2-0.2c0-0.2,0.1-0.3,0.4-0.2c0.3-0.2,0.7-0.4,0.9-0.8
        c0,0.1,0,0.2-0.1,0.2l0.3,0c0-0.2,0.3-0.3,0.5-0.4l0-0.1c0.1-0.1,0.2-0.3,0.3-0.4c-0.1,0.3-0.2,0.5-0.3,0.7
        c-0.1,0.1-0.2,0.2-0.3,0.3c0.2,0,0.3-0.2,0.4-0.3c0.2-0.1,0.3-0.1,0.5-0.2l-0.5,0.1c0.1-0.1,0.2-0.3,0.2-0.4c0.1,0,0.3,0.1,0.4,0.1
        l0-0.2l0.1,0c0-0.1,0.1-0.2,0.1-0.2c0.2-0.1,0.5,0,0.7-0.2c0.1,0,0.3,0,0.4,0l0-0.2c0.1,0,0.2,0.1,0.2,0.1c0.1-0.2,0.2-0.3,0.4-0.5
        l-0.1,0.3c0.1,0,0.2,0,0.3,0l0.1,0.1c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.2-0.1,0.3-0.1c0.1-0.2,0.3-0.3,0.4-0.5c0,0.1,0,0.4,0.1,0.5
        c0.2-0.2,0.1-0.5,0.1-0.7c0.2,0.4,0.6,0.4,1,0.3c-0.1-0.3-0.5-0.3-0.7-0.5c-0.3-0.1-0.6-0.3-0.9-0.2c0-0.1,0-0.3,0-0.4
        c-0.6,0.1-1.2-0.1-1.8-0.3c0,0-0.1,0.1-0.1,0.2c0-0.1,0-0.2,0-0.3c-0.3-0.1-0.7-0.1-0.9-0.4c-0.2,0-0.4,0.1-0.6-0.1
        c-0.5-0.5-1.3-0.2-1.7-0.8c0,0.1-0.1,0.3-0.1,0.3c0-0.1-0.1-0.2-0.1-0.3c-0.5-0.2-1.1-0.3-1.5-0.6c0-0.1,0.1-0.2,0.1-0.3
        c-0.2,0.1-0.4,0.1-0.5,0.2c0,0.1,0.1,0.2,0.1,0.2c-0.1,0-0.3,0-0.3,0c-0.2,0.1-0.4,0.1-0.5,0.3c-0.1,0-0.3-0.1-0.4,0
        c0.3-0.2,1-0.1,0.9-0.6c-0.6-0.1-1-0.4-1.6-0.5c-0.2-0.2-0.4-0.5-0.8-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1,0.1-0.3,0.2-0.4,0.3
        c0.1-0.1,0.2-0.2,0.2-0.3c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1,0-0.3,0.1-0.3,0.1c0.1,0.1,0.1,0.2,0.2,0.3c-0.2,0-0.4,0-0.6,0l0.1-0.2
        l-0.3,0.1c0.1-0.1,0.2-0.2,0.2-0.3c-0.3,0-0.6,0-0.8-0.3c-0.2,0-0.3,0-0.4,0c0,0.1,0.1,0.2,0.1,0.3c0,0-0.1,0-0.1,0
        c0-0.3-0.3-0.4-0.5-0.5c0,0.1,0.1,0.3,0.1,0.4c-0.1,0-0.2-0.1-0.2-0.1c0-0.1,0.1-0.3,0.2-0.3c-0.1,0-0.4,0-0.5,0
        c-0.1-0.1-0.2-0.3-0.2-0.4c-0.1,0.1-0.2,0.2-0.2,0.2c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.1-0.3-0.2-0.4-0.2c0,0-0.1,0.1-0.2,0.2
        c-0.3-0.2-0.7-0.4-1-0.7c0,0.1-0.1,0.2-0.2,0.2c-0.5-0.2-1.1-0.4-1.6-0.7c-0.1,0-0.4,0-0.6,0l0,0.2c0,0-0.1,0-0.1,0
        c0-0.4-0.6-0.6-1-0.5c-0.1-0.1-0.3-0.3-0.4-0.4c0,0.1,0.1,0.4,0.1,0.5c-0.2,0-0.3-0.2-0.1-0.4C326.6,87.2,326.6,86.2,326.1,86.3
        M178.8,86.5C178.8,86.6,178.8,86.6,178.8,86.5 M335.2,86.5C335.3,86.6,335.3,86.6,335.2,86.5 M211.6,87c0.2,0.7,0.2,1.4,0.2,2.1
        c0.1,0,0.2,0,0.3,0c-0.1,0.1-0.3,0.1-0.4,0.2c0.1,0.2,0.3,0.3,0.4,0.5c0.1-0.1,0.2-0.2,0.2-0.2c-0.1-0.5-0.1-1-0.3-1.4
        c-0.2-0.5-0.3-1-0.1-1.5C211.7,86.6,211.7,86.9,211.6,87 M233,86.7c0,0.2-0.1,0.4,0.1,0.5C233.4,87.2,233.3,86.6,233,86.7
        M277.3,86.6C277.3,87.1,277.7,86.4,277.3,86.6 M219.2,86.8C219.2,86.9,219.2,86.9,219.2,86.8 M335.9,86.8
        C335.7,87.2,336.4,86.8,335.9,86.8 M253.3,87.8c-0.2,0-0.3,0-0.4,0c0.1,0.1,0.2,0.2,0.3,0.3c-0.5-0.1-0.2,0.3-0.2,0.5
        c-0.1,0-0.3,0-0.4,0c0,0.2,0.1,0.5-0.1,0.6c-0.1,0-0.2,0-0.3-0.1c0,0.2-0.1,0.5-0.2,0.7c0.1-0.2,0.3-0.3,0.4-0.5
        c0,0.4-0.3,0.6-0.4,0.9c0.1,0,0.2,0.1,0.3,0.1c-0.1,0-0.3,0-0.4,0c0,0.1,0,0.3,0,0.4l-0.2-0.1l0,0.3c0,0-0.1-0.1-0.2-0.2
        c0,0.1-0.1,0.3-0.2,0.4c0.1,0,0.2,0,0.3,0c-0.5,0-0.4,0.6-0.7,0.9c-0.2,0-0.3,0-0.5,0c0.1,0.2,0,0.4-0.2,0.5c0.3,0,0.6,0.2,0.9,0.2
        c0.4-0.7,0.8-1.4,1.4-1.9c0-0.7,1.2-1,0.7-1.7c0-0.1,0.1-0.2,0.1-0.3l0.2,0c0,0.1,0,0.3,0,0.4c0.1-0.2,0.2-0.3,0.3-0.5
        c-0.1-0.3-0.5-0.6-0.1-0.9c-0.1,0-0.2,0.1-0.3,0.1c0-0.2,0-0.4,0-0.7l0-0.1l0-0.1C253.1,87,253.4,87.5,253.3,87.8 M259.5,87.1
        C259.6,87.2,259.6,87.2,259.5,87.1 M277.3,87.1C277.1,87.5,277.8,87.1,277.3,87.1 M323.7,87.1c-0.2,0-0.1,0.4,0.1,0.3
        C324,87.3,323.9,86.9,323.7,87.1 M177.7,87.2C177.5,87.6,178.2,87.2,177.7,87.2 M313.7,87.2C313.5,87.6,314.2,87.3,313.7,87.2
        M336.9,87.2C336.6,87.6,337.4,87.3,336.9,87.2 M176.4,87.5C176.5,87.6,176.5,87.6,176.4,87.5 M233.4,87.5L233.4,87.5L233.4,87.5
        L233.4,87.5L233.4,87.5 M283.4,87.7C283.2,88.1,283.9,87.7,283.4,87.7 M174.8,88c0,0.1-0.1,0.3-0.1,0.4c0.1,0,0.2,0.1,0.3,0.1
        c0-0.3,0.1-0.5,0.2-0.7C175,87.9,174.9,88,174.8,88 M250.1,87.9C249.9,88.4,250.6,87.9,250.1,87.9 M339.6,87.9
        c0.1,0.2,0.3,0.4,0.2,0.7c0.3-0.1,0.2-0.3,0.2-0.6C339.8,88,339.7,87.9,339.6,87.9 M213.9,88C214,88.1,214,88.1,213.9,88 M174,88.2
        L174,88.2L174,88.2L174,88.2L174,88.2 M303.2,88.2C303.2,88.7,303.6,87.9,303.2,88.2 M312.4,88.3c0.1,0,0.2,0.1,0.4,0.1
        c0,0.1-0.1,0.3-0.1,0.4c-0.1,0-0.3,0-0.4-0.1l0,0c0.1,0.1,0.3,0.2,0.4,0.3l-0.4,0c0,0.5-0.1,1.1,0.1,1.6c0.1-0.2,0.1-0.4,0.3-0.6
        c-0.2-0.1-0.3-0.4-0.3-0.6l0.2,0.2l0.1,0.3c0.1-0.1,0.2-0.3,0.2-0.4c0,0,0.1,0,0.2,0l-0.2,0c0-0.1,0-0.2,0-0.3l-0.2,0
        c0-0.3,0.4-0.7,0.1-1C312.7,88.3,312.5,88.3,312.4,88.3 M340.6,88.2L340.6,88.2L340.6,88.2C340.7,88.3,340.7,88.2,340.6,88.2
        M214.1,88.6l0,0.1C214.1,89,214.4,88.5,214.1,88.6 M276.6,88.5c0,0.1,0,0.2,0,0.2l0.1,0c0-0.1,0-0.2,0-0.2L276.6,88.5 M323.5,88.5
        c-0.3,0.2,0.2,0.6,0.3,0.2C323.8,88.5,323.7,88.5,323.5,88.5 M173.5,88.6C173.6,88.7,173.6,88.7,173.5,88.6 M283.3,88.6
        C283.4,89.1,283.7,88.4,283.3,88.6 M218.9,88.8c0,0.2-0.1,0.4-0.1,0.7l-0.1,0c-0.1,0.3-0.1,0.6-0.3,0.9c0,0.1,0,0.2,0,0.2
        c-0.3,0-0.2,0.4-0.2,0.6c-0.1,0-0.2,0-0.2,0c0,0.1,0,0.2,0.1,0.3l-0.2,0c0,0.2-0.1,0.4-0.1,0.6c-0.1,0.2-0.1,0.4-0.2,0.6l-0.1,0
        c0,0.2,0,0.3-0.1,0.4c-0.2,0.5-0.6,1-0.8,1.5c0.1,0.1,0.2,0.3,0.4,0.4c-0.1-0.5,0.3-0.9,0.6-1.3c0.2-1,1.1-1.6,1.5-2.6
        c0.1,0,0.2-0.1,0.3-0.2c-0.1-0.3-0.1-0.5-0.2-0.8c0.1,0.1,0.2,0.3,0.3,0.3c0.4-0.2-0.3-0.4-0.3-0.6l0.3,0.1c0-0.2,0.1-0.3,0.1-0.5
        c-0.1,0.1-0.3,0.3-0.4,0.4c0.1-0.3,0.1-0.6,0.1-0.9C219,88.8,218.9,88.8,218.9,88.8 M258.9,89C259,89,259,89,258.9,89 M323.5,89.6
        c-0.1,0-0.2,0-0.3,0c0,0.2,0.2,0.5-0.2,0.6c0.1,0.1,0.3,0.2,0.4,0.3c-0.1,0-0.3,0.1-0.4,0.1c0,0.1,0,0.3,0,0.4
        c0.3,0,0.1,0.4,0.1,0.6c-0.1,0-0.2,0.1-0.3,0.1l0.3,0c-0.2,0.3,0,0.6-0.1,1c0.1,0.2,0.2,0.3,0.3,0.5l-0.2,0c0.1,0,0.2,0.1,0.2,0.1
        c-0.2,0.1-0.3,0.3-0.1,0.5c0,0.1,0,0.3,0,0.4c-0.1-0.1-0.3-0.3-0.4-0.4c-0.5,0.2,0.3,0.4,0.3,0.6l-0.4,0c0,0,0,0.1,0,0.1
        c0.1,0,0.3,0,0.4,0c-0.1,0.1-0.2,0.3-0.3,0.4c0.1,0.3-0.2,0.5-0.2,0.8c-0.1,0-0.2,0-0.3,0c0,0,0,0.1,0,0.1c0.2,0,0.6-0.1,0.5,0.2
        c-0.1,0-0.2,0-0.2,0c-0.1,0.2-0.1,0.4-0.2,0.6c0.2,0.3-0.2,0.6,0,1c0.1-0.4,0.4-0.8,0.7-1.1c-0.2-0.1-0.2-0.3-0.1-0.4
        c0.1,0,0.2,0,0.3,0c0-0.1-0.1-0.2-0.1-0.3c-0.1,0-0.3-0.1-0.4-0.1c0.1-0.2,0.2-0.3,0.4-0.5c-0.3-0.2-0.1-0.4,0-0.6
        c-0.1-0.2-0.2-0.3,0-0.4c0.1,0,0.2,0,0.4,0.1c0,0.1,0,0.3,0,0.4c0,0-0.1,0.1-0.1,0.2c0,0,0.1-0.1,0.2-0.1c0-0.4,0-0.7,0-1.1
        c0,0.1-0.1,0.3-0.1,0.4c-0.1,0-0.3-0.1-0.4-0.2c0.1-0.1,0.2-0.2,0.2-0.3c-0.4,0-0.4-0.3-0.2-0.6c-0.2-0.3-0.2-0.6-0.2-0.9
        c0.2,0,0.3,0.4,0.4,0.6c0-0.4,0-0.8,0.1-1.2c-0.1,0.3-0.4,0.5-0.7,0.6c-0.1-0.4,0.3-0.6,0.4-0.9c-0.1-0.2-0.1-0.3-0.2-0.5
        c0.1,0.1,0.4,0.2,0.5,0.2c0.1-0.1,0.2-0.1,0.3-0.2c-0.1,0-0.3,0-0.5,0c-0.3-0.4,0.2-0.5,0.3-0.7c-0.1,0-0.2-0.1-0.3-0.2
        c0,0.1-0.1,0.2-0.2,0.3c-0.2,0-0.1-0.4-0.1-0.5c0.2-0.1,0.5-0.2,0.7,0c0,0,0-0.1,0-0.1c-0.1,0-0.2,0-0.3,0c0.1-0.2,0.2-0.3,0.3-0.5
        C323.7,89.1,323.6,89.4,323.5,89.6 M228.5,90.3c0.3,0.1-0.1,0.3-0.1,0.5c-0.2,0.3-0.2,1.1-0.7,1c0,0.1,0,0.2,0,0.3
        c0,0,0.1,0.1,0.2,0.2c-0.2,0.2-0.4,0.4-0.6,0.7c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.3-0.1,0.4-0.2c0-0.2-0.1-0.4,0.2-0.5
        c-0.2-0.2,0.1-0.3,0.2-0.5l0.1,0.2c-0.1-0.3,0.1-0.4,0.3-0.6c0-0.2-0.1-0.5-0.1-0.7c0.2-0.1,0.3,0,0.4,0.2c-0.1-0.2-0.2-0.4-0.2-0.6
        c0.1-0.1,0.2-0.2,0.3-0.3c-0.1,0-0.2-0.1-0.2-0.1c0-0.2,0.2-0.4,0.2-0.6C228.7,89.4,229,90.1,228.5,90.3 M234,89.2
        C234.1,89.3,234.1,89.3,234,89.2 M268.5,89.3C268.2,89.7,269,89.4,268.5,89.3 M113.5,89.5C113.6,89.6,113.6,89.6,113.5,89.5
        M191,89.4c0,0.2,0,0.4,0,0.6c0.1,0.1,0.2,0.1,0.3,0.2C191.3,89.9,191.2,89.6,191,89.4 M214.2,89.5
        C214.3,89.6,214.3,89.6,214.2,89.5 M171.8,89.6c-0.1,0.2,0,0.2,0.2,0.2C172.1,89.6,172,89.6,171.8,89.6 M207.2,89.6
        c0,0.1,0.1,0.2,0.1,0.3l-0.2,0c0,0.4-0.4,0.7-0.5,1.1c-0.2,0.2-0.4,0.5-0.5,0.7c0,0.2-0.1,0.3-0.3,0.5c-0.1,0.2-0.2,0.5-0.3,0.8
        c-0.3,0.1-0.3,0.5-0.5,0.6c-0.4,0.5-0.5,1.2-1.1,1.6c-0.2,0.3-0.4,0.7-0.5,1c0.3-0.1,0.4,0.2,0.4,0.5c-0.1,0-0.2-0.1-0.2-0.1
        c-0.3-0.1-0.6,0.1-0.9-0.2c-0.1,0-0.2,0-0.3,0c0.1,0.1,0.2,0.1,0.4,0.2c0,0.5,0.7,0.5,0.8,0.8c-0.6,0-1.1-0.4-1.6-0.6
        c-0.1-0.2-0.2-0.3-0.3-0.5c0,0.2,0,0.4,0,0.6c0.2,0.1,0.5,0.3,0.5,0.5c0.1,0,0.2-0.1,0.3-0.1c-0.2,0.6,0.7,0.8,0.9,1.2
        c-0.4,0-0.8-0.1-1.1-0.2l0,0.3c0,0,0.1,0.1,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0,0.4,0,0.5,0.1c-0.5,0.3-1.1,0-1.5-0.2
        c0.1,0.3,0.4,0.5,0.6,0.8c0.1,0,0.2,0.1,0.3,0.2c0,0.1,0,0.3-0.1,0.3l0.1-0.3c0.1,0,0.2,0,0.2,0l0-0.1c0.1,0,0.3,0,0.4,0
        c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.2,0-0.2c0.3-0.2,0.5-0.5,0.7-0.7c-0.2,0.1-0.3,0.1-0.5,0.2c0.1-0.2,0.3-0.4,0.4-0.5
        c0-0.2,0.2-0.4,0.3-0.5c-0.1-0.1-0.3-0.1-0.4-0.2c0.1,0,0.3,0.1,0.5,0.2c0-0.1-0.1-0.4-0.2-0.5c0.6-0.2,0.4-1,0.8-1.5
        c0.2-0.3,0.4-0.6,0.7-0.7c-0.1,0-0.4,0-0.5-0.1c0.1-0.1,0.2-0.3,0.3-0.4c0,0.1,0.1,0.2,0.2,0.3c0.1-0.3,0.2-0.5,0.1-0.8
        c0.2-0.2,0.2-0.5,0.4-0.8c0.2,0,0.4,0,0.3-0.3l0.2,0c-0.1-0.1-0.2-0.2-0.2-0.2c0.2-0.4,0.2-0.9,0.6-1.2c0.1-0.4,0.8-0.2,0.6-0.7
        c0,0.1-0.1,0.3-0.2,0.4c-0.1,0-0.2-0.1-0.3-0.1c0-0.1,0.1-0.2,0.1-0.4c0.3,0,0.4-0.2,0.6-0.4c-0.1-0.1-0.2-0.2-0.3-0.3
        c0.1,0,0.3,0,0.4,0.1c-0.1-0.1-0.2-0.2-0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2C207.6,89.8,207.4,89.8,207.2,89.6 M342.7,89.6
        c-0.1,0.2,0,0.3,0.2,0.2C343,89.6,342.9,89.6,342.7,89.6 M179.2,89.8C179.3,89.9,179.3,89.9,179.2,89.8 M198.8,90.3
        c0.3-0.1,0.5-0.3,0.2-0.6C199,89.9,198.9,90.1,198.8,90.3 M268.5,90c0.2-0.1,0.1-0.2,0-0.3C268.3,89.8,268.3,89.9,268.5,90
        M326.7,89.8C326.7,89.8,326.7,89.8,326.7,89.8 M335.4,89.8C335.5,89.8,335.5,89.8,335.4,89.8 M240.4,90.6c-0.1,0.2-0.1,0.3-0.2,0.5
        c-0.1,0.3-0.2,0.5-0.3,0.7c0.2,0,0.6,0.1,0.5-0.2c0,0,0.1,0,0.2,0c-0.2-0.2-0.2-0.3,0-0.5l-0.2,0c0-0.4,0.3-0.8,0.3-1.2
        C240.5,90.1,240.4,90.3,240.4,90.6 M201,90C201,90.1,201,90.1,201,90 M251.7,90.1C251.8,90.2,251.8,90.2,251.7,90.1 M212,90l0.1,0.3
        c0.3,0.2,0.4,0.5,0.8,0.7c0-0.2-0.1-0.5-0.2-0.7C212.5,90.2,212.3,90.1,212,90 M251.2,90.6c0,0.3,0.4,0,0.3-0.2
        C251.4,90.5,251.3,90.6,251.2,90.6 M214.3,90.6C214.4,90.7,214.4,90.7,214.3,90.6 M220.6,90.9c0,0,0.1,0.1,0.1,0.1
        c0.1,0.1,0.2,0,0.4-0.1C221.1,90.6,220.5,90.5,220.6,90.9 M299.1,90.6c0,0.2,0.2,0.4,0.4,0.4C299.8,90.9,299.3,90.4,299.1,90.6
        M168.1,91.2c0.1-0.2,0.4-0.3,0.4-0.5C168.3,90.8,167.8,90.9,168.1,91.2 M346.2,90.7c0.1,0.2,0.3,0.3,0.4,0.5
        C347,90.9,346.4,90.8,346.2,90.7 M312.3,91.1c0.1,0.6,0.1,1.1-0.2,1.6c-0.1,0-0.2-0.1-0.3-0.1c0.5,0.3,0,0.6-0.3,0.8l0.4-0.2
        c-0.1,0.4-0.3,0.8-0.4,1.3c-0.1,0-0.2,0-0.3,0c0,0.1,0,0.2,0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.2h0.3c-0.2,0.2-0.2,0.5-0.5,0.6
        c-0.1,0.6-0.5,1-0.6,1.6c-0.1,0-0.2-0.1-0.2-0.2c0,0.1,0,0.3,0.1,0.4c-0.2,0.1-0.4,0.4-0.4,0.7c-0.1,0.1-0.3,0.3-0.3,0.5
        c-0.1,0-0.2,0-0.2,0c0.1,0.3,0,0.5-0.4,0.4c0,0.1,0.1,0.3,0.1,0.4c-0.1,0-0.2,0-0.2,0.1l-0.1,0.2c0.1,0,0.2,0.1,0.3,0.1
        c-0.1,0-0.3,0-0.4-0.1c0,0.2,0.3,0.4,0.2,0.6c0.3-0.1,0.3,0.2,0.4,0.5c0.1,0.1-0.1,0.4,0.2,0.4l0,0.2c0.3-0.5,0.2-1.1,0.5-1.6
        c0.3-1.3,1.1-2.5,1.6-3.8c0.1-0.3,0.4-0.5,0.3-0.8c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.4,0.4-0.8,0.3-1.3l0.3,0c0-0.1-0.1-0.3-0.2-0.4
        l0.2,0.1c0.2-0.5,0.2-1.1,0.1-1.6c0.1,0.1,0.3,0.3,0.4,0.4l0-0.1l-0.2-0.1c0.1-0.1,0.2-0.2,0.3-0.3C312.7,90.8,312.5,91,312.3,91.1
        M191,91.2C191.1,91.3,191.1,91.3,191,91.2 M268,91.2c-0.1,0.3-0.1,0.5-0.2,0.8c0,0.1-0.1,0.2-0.2,0.2c0,0.2-0.1,0.4-0.2,0.6
        c0,0.3,0,0.6-0.3,0.9c-0.2,0.8-0.6,1.5-0.8,2.3c-0.1,0.2-0.2,0.5-0.3,0.7c0.2,0,0.3,0.2,0.3,0.4c0.1-0.2,0.2-0.4,0.4-0.6
        c0.2-0.9,0.6-1.7,1.1-2.4c0.2-0.4,0.5-0.8,0.1-1.2c0.1-0.2,0.2-0.4,0.3-0.6c0-0.1,0.1-0.2,0.2-0.3c-0.2,0.1-0.3,0.2-0.5,0.2
        c0-0.2,0.1-0.4,0.3-0.4c-0.1-0.1-0.1-0.3-0.2-0.4c0.1-0.1,0.1-0.3,0.2-0.4C268.2,91.2,268.1,91.2,268,91.2 M299.3,91.7L299.3,91.7
        c0.3,0,0.4,0.2,0.6,0.4c-0.1,0.1-0.3,0.2-0.4,0.3c0,0.2,0,0.4-0.1,0.5c0,0.1-0.1,0.2-0.2,0.2c0.1,0.3,0.1,0.6-0.1,0.9
        c-0.3,0.1-0.2,0.6-0.5,0.6c0,0.1,0.1,0.2,0.1,0.3c-0.1,0-0.2,0.2-0.2,0.2c0,0.1-0.1,0.3-0.1,0.4c-0.1-0.1-0.3-0.1-0.4-0.2
        c0.1,0.2,0.2,0.3,0.3,0.5c-0.2,0.1-0.4,0.1-0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.4l-0.2,0c0.1,0.5-0.4,0.5-0.8,0.6c-0.3,0.2,0.3,0.3,0.2,0
        c0.1,0,0.2,0,0.3,0c-0.1,0.2-0.1,0.3-0.2,0.5c0.2-0.3,0.3-0.6,0.5-1l0.1,0c0.2-0.4,0.4-0.7,0.6-1c0.3-0.3,0.3-0.8,0.8-0.9
        c0.1,0.3-0.3,1,0.2,1c0.1,0.2,0.1,0.4,0.2,0.6c-0.2,0.1-0.4-0.1-0.6-0.2c0,0,0,0.1,0,0.1c0.2,0,0.3,0.1,0.5,0.2c0,0.3,0,0.6-0.1,1
        l0.2,0.1c0-0.2,0-0.3-0.2-0.4c0.1-0.1,0.2-0.2,0.4-0.3c-0.5-0.1-0.1-0.5-0.1-0.7c0.1,0,0.2,0,0.3,0c-0.2,0-0.4-0.1-0.6-0.2
        c0.1-0.1,0.1-0.2,0.2-0.2c0-0.1,0-0.2,0-0.3c-0.2,0.1-0.3,0.1-0.5,0.1c0-0.3,0.3-0.3,0.5-0.4c0.1-0.3-0.2-0.8,0.2-0.9
        c-0.2,0-0.4,0-0.6,0c-0.1-0.3-0.3-0.7,0.1-0.9c0.1,0,0.3-0.1,0.5,0c-0.1-0.1-0.1-0.2-0.2-0.4c0,0.2-0.1,0.4-0.3,0.3
        c-0.1-0.3,0.2-0.5,0.4-0.8c-0.1-0.1-0.3-0.2-0.4-0.3c0.1-0.2,0.3-0.3,0.4-0.5C299.6,91.1,299.7,91.7,299.3,91.7 M212.9,92.1
        c-0.1,0-0.3-0.1-0.3-0.1c0,0.1,0,0.2,0,0.3c0.3-0.1,0.5-0.3,0.6-0.6c-0.2-0.1-0.3-0.3-0.3-0.4C212.8,91.5,212.7,91.8,212.9,92.1
        M305.2,91.4C305.3,91.4,305.3,91.4,305.2,91.4 M166.5,91.5C166.3,91.9,167,91.5,166.5,91.5 M217.1,92c-0.1,0.2-0.2,0.4-0.4,0.5
        c-0.2,0.2-0.3,0.4-0.5,0.7l-0.2,0c0,0.3,0.1,0.6,0.3,0.8c0.3-0.5,0.6-1,0.8-1.6c0-0.3,0.1-0.6,0.2-1C217.1,91.6,217.1,91.8,217.1,92
        M250.7,91.6c0.1,0.4,0.5-0.1,0.1-0.2C250.8,91.4,250.7,91.5,250.7,91.6 M348.1,91.5C347.9,91.9,348.6,91.5,348.1,91.5 M165.3,91.7
        c-0.2,0-0.3,0.5,0,0.3C165.5,92,165.6,91.6,165.3,91.7 M349.3,91.7c-0.1,0.2,0.2,0.4,0.4,0.3C349.7,91.9,349.5,91.6,349.3,91.7
        M213.1,92c0,0.2,0.1,0.4,0.3,0.5c0,0.1,0,0.3,0,0.4c0.1,0,0.2,0.1,0.3,0.2c-0.5,0-0.3,0.6-0.2,0.8c0-0.1,0-0.4,0-0.5l0.2,0
        c0,0.3,0.1,0.6,0.1,0.9c0.1,0,0.2-0.1,0.3-0.1c0,0.1-0.1,0.2-0.1,0.3c0.3,0.1,0.5,0.3,0.8,0.5c-0.1,0-0.4,0-0.5,0c0-0.1,0-0.2,0-0.2
        l-0.1,0c0,0.1,0,0.3,0,0.4c0.2,0,0.4,0,0.6,0.1l0.1,0.2c-0.1,0-0.4-0.1-0.5-0.2c0.1,0.2,0.3,0.3,0.5,0.3c0,0.4,0.4,0.5,0.7,0.4
        c0-0.1,0-0.3,0-0.4c-0.4-0.4-0.7-0.9-1.2-1.3c0-0.2-0.1-0.3-0.1-0.5c-0.4-0.3-0.7-0.8-0.6-1.3C213.6,92.3,213.4,92,213.1,92
        M201.1,92.5C200.8,92.9,201.5,92.5,201.1,92.5 M212.8,92.7c0.2,0,0.4,0.1,0.5,0.3c-0.1-0.2-0.2-0.5-0.3-0.7
        C212.9,92.4,212.8,92.5,212.8,92.7 M269.6,93.2c0,0.5,0.2,0.9,0.1,1.4c-0.1,0.5,0.2,1,0.2,1.5c0,0.5,0.1,1,0.2,1.5l0.2-0.1
        c0,0.1,0,0.3,0,0.4c0.3,0,0.3,0.4,0.3,0.6c0.3-0.1,0.3,0.2,0.3,0.4c0.4,0.5,1,0.7,1.4,1.1c0.3-0.1,0.5-0.5,0.6-0.7
        c-0.4-0.3-0.9-0.6-1.2-1c-0.3-0.5-0.7-0.9-0.8-1.4c-0.3-0.3-0.3-0.8-0.5-1.2c-0.2-0.6-0.4-1.1-0.5-1.7c0-0.4-0.2-0.9-0.3-1.3
        C269.4,92.7,269.6,92.9,269.6,93.2 M220.7,92.7c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0,0.2,0,0.2,0c0,0.1-0.1,0.2-0.1,0.3
        c0.2,0.1,0.4,0.4,0.6,0.4c0.5-0.4-0.4-0.5-0.3-0.9c0,0-0.1,0.1-0.2,0.1C221,93,220.9,92.9,220.7,92.7 M201.3,92.9
        C201.4,93,201.4,93,201.3,92.9 M322.2,92.8c0,0.1,0,0.3,0,0.4l-0.2,0c0,0.1,0.1,0.3,0.1,0.4c-0.1,0-0.3,0.1-0.4,0.1
        c0,0.1,0.1,0.2,0.2,0.3c-0.1,0-0.3,0.1-0.3,0.1c0,0,0,0.1,0,0.1l0.2,0c-0.1,0.2-0.3,0.3-0.3,0.5c-0.1,0.2,0,0.6-0.3,0.5
        c0.1,0.1,0.1,0.3,0.2,0.4c-0.1-0.1-0.3-0.2-0.3-0.2c0,0.1,0,0.4,0,0.5l-0.2-0.1c0,0.2-0.1,0.4-0.3,0.5c-0.1,0.3-0.3,0.6-0.5,0.9
        c-0.3,0.5-0.6,0.9-0.9,1.4c-0.1,0.3-0.2,0.6-0.5,0.7c0,0.2,0,0.3,0,0.5c0.2,0.1,0.5,0,0.6,0.3c-0.9,0.1-1.9-0.2-2.6-0.8
        c-0.3,0.1-0.5-0.2-0.5-0.4c-0.2-0.1-0.4-0.1-0.6-0.2c-0.2-0.3-0.4-0.7-0.7-0.9c0,0.3,0,0.7,0.4,0.8l0,0.1c-0.1,0-0.2,0-0.3-0.1
        c0,0.1,0,0.2,0,0.3c0.3-0.1,0.4,0.1,0.5,0.3c0.1-0.1,0.2-0.2,0.2-0.2c-0.2,0.4,0.2,0.6,0.5,0.8c-0.1,0-0.3,0.1-0.5,0.1
        c0.2,0.1,0.3,0.3,0.5,0.4l0-0.1l-0.2-0.1c0.1-0.1,0.3-0.3,0.4-0.4c0,0.2,0,0.3,0.1,0.5c0.1,0,0.3,0,0.4,0c0,0.2,0,0.3-0.1,0.5l0.2,0
        c0,0.2,0.1,0.3,0.1,0.5c0.2,0.1,0.3,0.1,0.5,0.2c0,0.1-0.1,0.2-0.1,0.3c0.3-0.1,0.4,0.1,0.4,0.4c0.3,0.1,0.5,0.2,0.8,0.3
        c-0.4,0-0.9,0-1.3-0.1c0,0.1-0.1,0.3-0.1,0.5c0.1,0,0.2-0.1,0.3-0.1c0,0.1,0,0.3,0,0.5c0.1,0,0.2-0.1,0.2-0.1c0,0.3,0.2,0.5,0.4,0.7
        c0.1,0,0.2,0.1,0.3,0.3c-0.3,0-0.7-0.1-0.9-0.4c-0.7,0.1-1.1-0.5-1.7-0.8c0.1,0.2,0.2,0.4,0.2,0.6l0.1,0c0-0.1,0-0.3,0-0.4
        c0.2,0.2,0.5,0.3,0.6,0.5c-0.1,0-0.3,0-0.5,0c0.1,0.4,0.6,0.1,0.7,0.6c-0.1,0-0.3,0-0.4,0c0.1,0.2,0.3,0.1,0.4,0
        c0,0.3,0.1,0.4,0.4,0.3c0.6-0.2,1.2-0.3,1.8-0.5c0.3-0.5,0.3-1.1,0.7-1.6c0.5-1.1,0.8-2.2,0.9-3.4c0.1-0.8,0.5-1.5,0.4-2.3
        c0.1-0.7,0.2-1.4,0.4-2.1c0.1-0.4,0-0.8,0-1.2C322.3,93.2,322.3,93,322.2,92.8 M302.2,93c0.1,0.8,0.2,1.6-0.1,2.4
        c0.3,0.4,0.1,0.9,0.2,1.4c0.2,0.2,0.3,0.4,0.5,0.6c0.1,0.1,0.2,0.3,0.2,0.4c0.3-0.1,0.1,0.3,0.1,0.4c0.1,0,0.2-0.1,0.3-0.1
        c0,0.6,0.8,0.8,0.8,1.4c0.1,0,0.3-0.1,0.3-0.1c-0.1,0.1-0.2,0.3-0.3,0.4c0.1,0,0.3,0,0.4,0c0.1,0.3,0.5,0.5,0.5,0.8l0.1,0
        c0,0.3,0.3,0.1,0.4,0.1c0,0,0,0.1,0,0.1c-0.1,0-0.2,0-0.3,0c0,0.3,0.6,0.2,0.4,0.6c0.3-0.1,0.3,0.2,0.3,0.4c0.3-0.1,0.4,0.5,0.6,0.6
        c-0.1,0-0.2,0.1-0.3,0.1c0-0.1-0.1-0.3-0.1-0.4c0,0.1,0,0.3,0,0.3c0.2,0.1,0.3,0.2,0.4,0.4c0-0.1,0-0.4,0.1-0.5
        c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0,0.2-0.1,0.3-0.1c0,0,0.1,0.1,0.1,0.2c-0.2,0-0.4,0-0.5,0.1c0.1,0,0.4,0.1,0.6,0.1
        c0,0.1-0.1,0.2-0.1,0.3c0.4,0,0.6,0.4,0.9,0.6c0,0.3,0.2,0.4,0.5,0.4c0,0.1-0.1,0.2-0.1,0.3l0.2,0c0.2,0.2,0.3,0.6,0.7,0.6
        c0.3-0.6,0.4-1.2,0.8-1.7c-0.3-0.3-0.6-0.6-1-0.6c-0.2,0.3-0.4,0.7-0.6,0.9c0.1-0.4,0.3-0.8,0.4-1.2c-0.3-0.2-0.4-0.5-0.6-0.7
        c-0.2-0.2-0.6-0.4-0.6-0.8c-0.4,0-0.5-0.5-0.9-0.3c0.1,0,0.2-0.1,0.3-0.2c-0.2-0.4-0.5-0.7-0.7-1.1c-0.3-0.2-0.5-0.4-0.7-0.8
        c-0.4-0.4-0.7-0.9-1-1.4c-0.1-0.1-0.3-0.2-0.4-0.3c0-0.2,0.2-0.4,0-0.5c-0.2-0.1-0.3-0.4-0.4-0.7c-0.1,0-0.2,0-0.2,0
        c-0.3-0.5-0.5-1.1-0.8-1.6c-0.2-0.2-0.4-0.4-0.6-0.6c0-0.2,0-0.4,0-0.6C302.5,93.2,302.3,93.1,302.2,93 M353.1,93
        C353.2,93.1,353.2,93.1,353.1,93 M224.7,93.3c-0.2,0.1-0.2,0.2,0,0.3C224.9,93.5,224.9,93.4,224.7,93.3 M285.4,93.3
        C285.5,93.4,285.5,93.4,285.4,93.3 M124.9,93.4C125,93.5,125,93.5,124.9,93.4 M218.7,93.4c0,0.1,0,0.4,0,0.5c-0.1,0-0.2,0-0.3,0
        c0,0.1,0,0.3,0,0.5c0.2,0,0.5,0,0.7,0c0,0-0.1-0.1-0.1-0.2l-0.2,0.1C218.9,94,219,93.6,218.7,93.4 M238,93.6
        c0.2,0.1,0.3,0.2,0.4,0.3c-0.1,0.1-0.3,0.2-0.4,0.3c0-0.1-0.1-0.2-0.2-0.3c-0.2,0.2-0.4,0.4-0.7,0.4c0-0.1,0.1-0.3,0.2-0.4
        c-0.1,0-0.2,0-0.3,0c-0.1,0.3-0.4,0.4-0.6,0.5c0.1-0.1,0.1-0.3,0.2-0.4c-0.3-0.1-0.4,0.2-0.4,0.4c0.2,0.3,0.4,0.6,0.6,0.9
        c0,0.1-0.1,0.2-0.1,0.3c0.1,0.1,0.2,0.3,0.4,0.4c0.2,0.1-0.1,0.5,0.2,0.5c0,0.1,0,0.2,0,0.2c0.3,0,0.3,0.2,0.3,0.5
        c0.1,0,0.4,0,0.5,0c0-0.1-0.1-0.3-0.1-0.4c0.5-1,1.1-1.9,1.6-3c-0.1-0.1-0.2-0.2-0.3-0.2c0,0.1,0,0.3,0,0.3c-0.1,0-0.2-0.1-0.3-0.1
        c-0.1,0.2-0.2,0.4-0.3,0.6c-0.4,0.5-0.5,1.2-0.9,1.7c0.3-0.9,0.3-1.9,0.9-2.6C238.6,93.7,238.3,93.6,238,93.6 M242.2,93.5
        c0.1,0.3,0.3,0.6,0.1,0.8c0-0.1,0-0.2,0-0.3c-0.1-0.1-0.3-0.2-0.3-0.3c-0.2,0.2-0.4,0.6-0.4,0c-0.1,0-0.2,0.1-0.3,0.3
        c0.1,0,0.2,0.1,0.3,0.1c-0.1,0.1-0.2,0.2-0.2,0.3c0.1,0.4,0.2,0.8,0.2,1.2l0.1,0c0,0.1,0,0.4,0,0.5l-0.2,0c0.2,0.4,0.2,0.8,0.2,1.2
        l0.2-0.1c0,0.2,0,0.4,0,0.6c0.1,0.2,0.3,0.3,0.3,0.5c-0.1,0.3-0.2,0.6-0.1,0.9c0.1-0.1,0.3-0.2,0.5-0.2c-0.1,0.1-0.2,0.2-0.3,0.3
        c0,0.1,0,0.4,0.1,0.6c-0.1-0.2-0.3-0.3-0.4-0.5c0.1,0.3,0.2,0.6,0.2,0.9c0.3-0.3,0.4-0.8,0.8-1c0.2-0.7,0.6-1.3,0.9-1.9
        c0.3-0.3,0.5-0.7,0.5-1.2c0.2-0.4,0.5-0.8,0.5-1.2c0.3-0.3,0.5-0.7,0.6-1.1c-0.2,0.2-0.3,0.4-0.4,0.7c-0.1,0.2-0.3,0.3-0.3,0.5
        l-0.1-0.1c-0.1,0.6-0.6,1.1-1,1.6c-0.3,0.5-0.7,0.9-1.1,1.3c-0.1-0.1-0.1-0.1-0.2-0.2c0.6-0.5,0.9-1.3,1.3-1.9
        c0.4-0.7,0.8-1.4,1.2-2.1c-0.1,0-0.2-0.1-0.2-0.2c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1-0.1-0.2-0.2-0.2-0.2c0,0.1,0,0.2,0.1,0.3
        c-0.7,0.1-0.8,0.9-1.4,1.1c-0.1,0.1-0.3,0.3-0.4,0.3c0.1-0.2,0.3-0.4,0.4-0.5c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.2,0.2-0.4,0.3-0.5
        l-0.2,0c0-0.1,0.1-0.3,0.2-0.4c-0.3,0.2-0.5,0.2-0.5-0.1C242.4,93.6,242.3,93.5,242.2,93.5 M389.8,93.5
        C389.9,93.6,389.9,93.6,389.8,93.5 M161,93.6C160.7,94,161.5,93.7,161,93.6 M304.9,93.6C305,93.7,305,93.7,304.9,93.6 M353.6,93.6
        C353.5,94.1,354.1,93.6,353.6,93.6 M219.2,93.9C219.2,94,219.2,94,219.2,93.9 M221.9,93.8l0,0.1C222.1,94.2,222.1,93.6,221.9,93.8
        M240.2,93.9c0,0.1,0,0.2,0,0.3c-0.4,0-0.4,0.5-0.5,0.7c-0.2,0.4-0.4,0.7-0.6,1.1l-0.1,0c-0.1,0.3-0.2,0.6-0.4,0.9
        c0,0.2-0.1,0.3-0.2,0.4c0,0.2-0.1,0.3-0.1,0.5l-0.2-0.1c0,0.1-0.1,0.2-0.1,0.2c-0.3,0.3-0.5,0.7-0.8,1c0.2-0.4,0.3-0.9,0.6-1.3
        c-0.4-0.2-0.8-0.5-1.1-0.8c-0.4-0.1-0.5-0.4-0.8-0.6c-0.2-0.2-0.5-0.3-0.7-0.5c-0.1,0-0.2-0.1-0.2-0.1c-0.1-0.2-0.2-0.4-0.3-0.6
        c-0.2-0.2-0.4-0.4-0.6-0.6c-0.1,0-0.2,0.1-0.2,0.1c-0.2-0.1-0.3-0.1-0.4-0.2l0,0.3c-0.2,0-0.4,0-0.5,0c0,0,0.1,0.1,0.2,0.2
        c-0.2,0.3,0,0.6,0.2,0.8c0-0.3-0.2-0.5,0-0.7c0,0.2,0.1,0.3,0.2,0.5c0,0.2-0.1,0.3-0.1,0.5c-0.3,0.3,0.5,0.6,0,0
        c0.2,0,0.3,0.2,0.2,0.4l0.2-0.1l-0.1,0.2c0.1,0,0.3,0.1,0.4,0.1c-0.2,0.3-0.2,0.7-0.1,1c0.1-0.1,0.2-0.3,0.4-0.4
        c-0.1,0.2-0.2,0.4-0.3,0.5l0.3-0.1c0.1,0.1,0.2,0.3,0.3,0.4c-0.2-0.1-0.3-0.1-0.5-0.2c0.4,0.5,0.8,0.9,0.7,1.6l0.2,0
        c0.1,0.2,0.2,0.4,0.3,0.6c-0.2,0-0.3,0-0.5,0.1c0.1,0,0.3,0,0.4,0c0.1,0.1,0.2,0.2,0.2,0.3c-0.1,0.1-0.3,0.1-0.4,0.2
        c0.2,0,0.3-0.1,0.5-0.1c0.1,0.1,0.1,0.3,0.2,0.4c-0.1,0-0.2-0.1-0.3-0.1c0,0,0,0.1,0,0.2c0.3,0,0.4,0.2,0.3,0.5l0.2-0.1l-0.1,0.2
        c0,0.1,0.1,0.2,0.1,0.2c0.2,0.1,0.4,0.2,0.6,0.4c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0.1,0,0.1c-0.4-0.1-0.8-0.1-1.2-0.4
        c-0.4-0.3-0.9-0.6-1-1.1c0,0-0.1,0.1-0.2,0.1c-0.7-1.1-1.5-2.2-1.9-3.4c0,0-0.1-0.1-0.1-0.1c0,0.3,0.1,0.6,0.2,0.9
        c-0.1,0.1-0.1,0.2-0.2,0.3c0.4,0,0.3,0.5,0.3,0.7c0.1-0.1,0.2-0.3,0.3-0.4c0,0.2,0,0.5,0,0.6c0.3,0.3,0.5,0.6,0.7,0.8
        c0,0.1-0.1,0.2-0.1,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1-0.1,0.1-0.3,0.2-0.4c0,0.2,0,0.3,0,0.5c0.1,0.1,0.3,0.3,0.4,0.5
        c0,0.1-0.1,0.2-0.1,0.3c0.3,0.1,0.6,0.3,0.6,0.7c0.3,0,0.1,0.2,0,0.3c0.1,0.1,0.2,0.2,0.2,0.2l0.1-0.2c0.1,0.3,0.4,0.5,0.3,0.7
        c0,0.3,0.3,0.3,0.5,0.3c0,0.2,0,0.4-0.2,0.4l0,0.1c0.1,0,0.4-0.1,0.5-0.1c0,0.1,0,0.2,0,0.3c0.2,0.1,0.4,0.3,0.5,0.5
        c-0.1,0-0.4,0-0.5,0.1c0.2,0.1,0.4,0.1,0.5,0.1c0,0.2,0.1,0.3,0.3,0.3l0,0.2c0.1,0,0.2,0,0.2,0c0.1-0.9,0.4-1.8,0.7-2.7
        c-0.1,0.1-0.3,0.2-0.4,0.3c0.1-0.2,0.2-0.4,0.3-0.6c0,0,0.2,0,0.2,0.1c0-0.4,0.2-0.8,0.3-1.2c0.3-0.6,0.3-1.2,0.6-1.8
        c0.3-0.6,0.6-1.3,0.9-1.9c0.1-0.2,0.2-0.3,0.3-0.4c-0.1-0.3-0.1-0.7-0.1-1l0.2,0c-0.1-0.2,0-0.5-0.3-0.5c0,0.6-0.6,1-0.5,1.6
        c-0.1-0.1-0.2-0.2-0.2-0.2c0.1,0.5,0,0.9-0.2,1.4c0,0-0.1,0-0.2,0c0,0.4-0.2,0.7-0.4,1.1c-0.3,0.7-0.6,1.3-1,1.9
        c0-0.3,0.2-0.6,0.3-0.9c0.2-0.6,0.3-1.3,0.6-1.9c0.4-0.7,0.5-1.5,1-2.3c0.2-0.6,0.7-1.1,0.8-1.7C240.4,94.5,240.6,93.9,240.2,93.9
        M243,93.9V94C243.3,94.2,243.3,93.6,243,93.9 M213.3,94C213.4,94.5,213.7,93.8,213.3,94 M246.5,94c0,0.5-0.6,0.6-0.7,1
        c-0.1,0-0.2,0-0.2,0c0,0.2,0,0.3,0,0.5c-0.3,0.1-0.3,0.4-0.4,0.6c-0.2,0-0.3,0.1-0.3,0.3c0,0.3-0.2,0.4-0.5,0.4
        c0.1,0,0.1,0.1,0.2,0.1c0,0.1,0.1,0.3,0.2,0.4c0-0.1,0-0.3-0.1-0.5c0.3-0.1,0.3-0.3,0.3-0.6l0.3,0.1l0-0.2l0.2,0l-0.1-0.2l0.1,0
        c0-0.1,0-0.2-0.1-0.3l0.2,0c0.2-0.2,0.3-0.6,0.6-0.8c0-0.1,0-0.2,0.1-0.3c0.1,0,0.2-0.1,0.3-0.1C246.7,94.3,246.7,94.1,246.5,94
        M249.2,94.9c-0.1,0.2-0.2,0.4-0.4,0.3l0,0.2c-0.1,0-0.2,0-0.3,0c0,0.1-0.1,0.2-0.1,0.2c0,0-0.1,0.1-0.2,0.1c0,0.1,0,0.2-0.1,0.3
        c-0.1,0-0.2,0-0.3-0.1l0.1,0.2c0.2-0.2,0.7,0,0.2,0.2c0.1,0,0.2,0,0.3,0.1c-0.1,0.1-0.3,0.2-0.4,0.3c0,0.1-0.1,0.3-0.1,0.4
        c-0.3,0.2-0.6,0.3-0.9,0.5c0,0.1,0.1,0.2,0.2,0.2c-0.1-0.3,0.4-0.6,0.5-0.2c-0.1,0-0.3,0-0.3,0.1c0,0.3,0,0.5-0.1,0.8
        c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1,0.3-0.3,0.6-0.6,0.7c0,0.1,0,0.3,0,0.4c-0.2,0.1-0.3,0.2-0.5,0.3c0,0.1-0.1,0.2-0.1,0.3l-0.2-0.1
        c0,0.1,0.1,0.2,0.1,0.3c-0.1,0-0.4,0-0.5,0c0,0,0,0.1,0,0.1l0.4,0c-0.2,0.3-0.4,0.4-0.7,0.3l0,0c0.3,0.1,0,0.4,0,0.6
        c-0.1,0-0.3,0-0.4,0c0,0.1,0,0.3,0,0.5l-0.2,0c0.1,0.3-0.2,0.4-0.4,0.5c-0.1,0.1-0.1,0.2-0.2,0.3c-0.3-0.1-0.3,0.2-0.4,0.4
        c-0.1,0.1-0.2,0.3-0.3,0.4c0,0-0.1-0.1-0.2-0.1c-0.1,0.3-0.2,0.5-0.1,0.8c-0.2,0-0.3,0-0.5,0c0.1,0.1,0.2,0.2,0.2,0.4
        c0,0.1,0,0.2,0,0.2c0-0.1,0.1-0.2,0.2-0.3c0.1,0,0.3-0.1,0.3-0.1c0,0.1-0.1,0.2-0.2,0.3c0.1,0,0.3,0,0.4,0c0,0.1,0,0.4,0,0.6l-0.2,0
        c0-0.1,0-0.2,0-0.3l-0.2,0.1c0.1,0.1,0.2,0.2,0.2,0.3l0,0.2c0.1,0,0.2-0.1,0.3-0.1c0.4-0.5,0.6-1,1.1-1.5c0.3-0.5,0.6-1.1,1-1.6
        c0.5-0.5,0.7-1.2,1.1-1.8c0.3-0.4,0.4-1,0.9-1.4c0.1-0.4,0.3-0.7,0.5-1.1c0.1-0.3,0.4-0.4,0.5-0.7c0.2-0.5,0.6-1,1-1.4
        c0.4-0.4,0.6-0.9,0.8-1.4C249.7,94.1,249.5,94.5,249.2,94.9 M247.2,94.3c-0.1,0.2-0.1,0.4-0.2,0.6c-0.2,0.1-0.3,0.3-0.5,0.4
        c0,0.3-0.2,0.4-0.4,0.7c0,0-0.1,0.1-0.2,0.2c-0.1,0.3-0.4,0.4-0.6,0.7c-0.6,1-1.3,1.9-2.1,2.8c-0.3,0.2-0.5,0.5-0.7,0.9l-0.1,0
        c0,0.1,0,0.2,0,0.3c-0.1,0-0.2-0.1-0.3-0.2c0,0.2,0,0.6,0,0.8l0.2-0.1c0,0.2,0,0.4,0,0.6l0.2,0c0,0.3,0,0.5,0,0.8
        c0.7-0.6,1.1-1.5,1.5-2.3c0.2-0.3,0.4-0.6,0.6-1c0.5-0.7,0.7-1.5,1.2-2.2c0.1-0.4,0.4-0.8,0.6-1.1C246.6,95.5,247.3,95,247.2,94.3
        M276.6,94.4C276.3,94.8,277,94.4,276.6,94.4 M190.7,94.6c0.1,0,0.2,0,0.3,0.1l0.1,0.2c-0.3,0.1-0.5,0.2-0.8,0.2
        c0,0.1,0.1,0.2,0.1,0.2c-0.2,0-0.3,0.1-0.4,0.2c0.3,0.3-0.3,0.6,0.1,0.7c-0.1,0.1-0.2,0.2-0.2,0.3c0.1,0.1,0.2,0.3,0.1,0.4
        c-0.3,0-0.3,0.3-0.2,0.5l-0.1,0c-0.1,0.3-0.2,0.7-0.1,1c-0.1,0-0.3,0-0.3-0.1c0,0.1,0,0.2,0.1,0.4c-0.1,0.2-0.2,0.4-0.3,0.7
        c0.1,0.4-0.5,0.5-0.3,1l-0.2-0.1c0.1,0.3,0.1,0.6-0.3,0.7c0-0.1,0-0.4,0-0.5c-0.4,0.2,0.1,0.9-0.4,0.9c0,0.2-0.6,0.6-0.1,0.5
        c-0.1,0.2-0.2,0.4-0.4,0.6c0.1,0,0.3,0,0.4,0c-0.2,0.3-0.3,0.6-0.6,0.8c0-0.1-0.1-0.3-0.1-0.4l-0.1,0c0,0.1,0,0.3,0,0.3l0.1,0
        c0,0.3,0,0.5,0.1,0.8c0.4-0.2,0.4-0.7,0.6-1c0.2-0.5,0.6-0.9,0.8-1.4c0.1,0,0.3,0,0.3,0c-0.2-0.2-0.2-0.4,0-0.6
        c0.7-0.7,1.1-1.7,2-2.2c0-0.1-0.1-0.2-0.1-0.3c0.1-0.2,0.2-0.4,0.2-0.6l0.2,0.1c0-0.2-0.1-0.5,0-0.7c-0.2-0.1-0.3-0.3-0.4-0.4l0.3,0
        c0-0.1-0.1-0.3-0.1-0.3c-0.1,0.1-0.2,0.3-0.2,0.4l-0.2-0.1c0.1-0.4,0.6-0.4,0.7-0.8c-0.2,0.1-0.4,0.1-0.5,0.2c0-0.3,0-0.8,0.4-0.8
        c0.1-0.1,0.2-0.2,0.3-0.3c-0.1-0.2-0.3-0.4-0.3-0.7C190.9,94.5,190.8,94.6,190.7,94.6 M195.1,94.9c0.2,0.2,0.4,0.3,0.6,0.5l0.1-0.2
        c0.2,0.1,0.3,0.5,0.4,0.7c0.4,0,0.4,0.5,0.6,0.8c0.1-0.3,0.3-0.6,0.4-0.8c-0.6-0.1-1-0.4-1.3-0.8c-0.2,0.3-0.4,0.2-0.7,0
        c0-0.1,0.1-0.2,0.1-0.3C195.3,94.7,195.2,94.8,195.1,94.9 M348.8,94.8c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0,0.2,0,0.2,0l0,0.2
        c-0.2-0.1-0.4-0.2-0.6-0.3c-0.3,0.1-0.5,0.2-0.7,0.4c-0.1,0-0.2,0-0.3-0.1c0,0.1,0.2,0.2,0.2,0.2c-0.6,0-0.9,0.6-1.3,0.8l0,0.1
        c-0.1,0.3-0.5,0.4-0.7,0.5c0.1,0.1,0.2,0.2,0.2,0.3c-0.3-0.2-0.6,0-0.7,0.3c-0.1,0-0.3-0.1-0.4-0.1c0,0.1,0,0.2,0,0.3
        c-0.3,0-0.4,0.3-0.5,0.5c-0.1-0.1-0.2-0.2-0.3-0.2c0,0.1,0,0.4,0,0.5c-0.2,0-0.3,0-0.5,0c0,0.2,0,0.4,0,0.6
        c-0.1-0.1-0.2-0.1-0.4-0.2l0,0.3c-0.1,0-0.3,0-0.4,0c0,0.1,0,0.2,0,0.3c-0.3,0.4-0.5,1-1,1.2c0-0.1,0.1-0.3,0.1-0.4
        c-0.1,0.1-0.2,0.2-0.3,0.3c0,0.1,0.1,0.3,0.1,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.1,0.2-0.2,0.3-0.2,0.5c-0.1-0.1-0.3-0.1-0.4-0.2
        c0.1,0.1,0.2,0.3,0.3,0.5c-0.1,0-0.3,0-0.4-0.1c0,0.2,0,0.3,0.1,0.5c-0.3,0-0.4,0.3-0.6,0.4c0,0.1,0.1,0.2,0.1,0.2
        c-0.3-0.1-0.5,0.2-0.3,0.5l-0.1,0l0,0.2c-0.3-0.1-0.4,0-0.2,0.2c-0.2,0.3-0.5,0.6-0.4,0.9c-0.3-0.4-0.5,0.1-0.3,0.4
        c-0.1-0.1-0.3-0.2-0.4-0.2c0.1,0.3,0,0.7,0.1,1c-0.1,0-0.4-0.1-0.5-0.1c0,0.1,0.1,0.3,0.1,0.4c-0.1,0-0.2,0-0.3,0
        c0,0.2,0.1,0.5-0.3,0.5c0,0.1,0.1,0.2,0.1,0.3c-0.3-0.2-0.7-0.1-0.7,0.3c0.1,0,0.3,0,0.4-0.1c0.2,0.3-0.1,0.2-0.3,0.1
        c-0.4,0.5-0.8,0.9-1.2,1.4c0.1,0,0.3,0,0.5-0.1c-0.1,0.1-0.3,0.5-0.5,0.3c0,0.3-0.2,0.6-0.2,0.9c-0.1,0-0.2-0.1-0.3-0.1
        c0,0.1-0.1,0.3-0.2,0.4c0.1,0,0.2,0.1,0.3,0.1c-0.1,0-0.2,0.1-0.3,0.1c0,0.2,0.2,0.5-0.1,0.3l-0.1-0.2c-0.3,0.2-0.1,0.6-0.4,0.8
        c-0.4,0.4-0.3,1-0.7,1.3c-0.1,0.3-0.1,0.8-0.6,0.9c0,0.1,0,0.3,0,0.4l-0.1,0c0,0.4-0.3,0.5-0.5,0.7c0.1,0.4,0,0.8-0.3,1.1
        c0.4,0.4-0.6,0.7-0.3,1.2c-0.1,0.2-0.4,0.4-0.4,0.6c-0.1,0.4,0.3,0.6,0.5,0.8c0.2,0.1,0.4,0.3,0.5,0.4c0.3,0.1,0.6,0.3,0.8,0.5
        c0,0,0.1-0.1,0.2-0.1l-0.1,0.3c-0.5-0.6-1.5-0.5-2-1.1c-0.3,0.2-0.2,0.7-0.4,1c0.1,0.5,0,1-0.1,1.4c0.1,0.9-0.2,1.7,0,2.6
        c-0.1,0.7,0,1.4-0.2,2.1c0,0.1,0.1,0.2,0.2,0.3c-0.2,0.7,0.4,1.4,0.2,2.1c0.1,0,0.3,0,0.3-0.1c-0.1,0.2-0.2,0.4-0.3,0.6
        c0.2,0,0.5,0.1,0.7,0.2c-0.1,0.1-0.1,0.1-0.2,0.2c0.1,0.3,0.3,0.6,0.5,0.9l-0.2,0c-0.1-0.3-0.4-0.2-0.6-0.3c0.2,0.2,0.5,0.4,0.7,0.5
        c0.2-0.1,0.3-0.3,0.2-0.5c0.1,0,0.2,0.1,0.2,0.1c-0.1,0.1,0,0.6-0.3,0.5l-0.1,0.3l0.3-0.2c-0.1,0.2,0,0.4,0.2,0.5l0,0.1
        c-0.1,0.2-0.4,0-0.6,0.2c0.1,0,0.3,0.1,0.3,0.1l0.1,0.2c-0.1,0-0.3,0-0.4,0c0,0.2,0.1,0.2,0.3,0.3c0,0.1,0.1,0.2,0.1,0.3
        c0-0.1,0-0.3,0-0.5c0.2-0.1,0.4-0.2,0.6-0.3c0,0.1-0.1,0.2-0.1,0.3l-0.2,0.1c0,0.1,0,0.3,0,0.5c0.1,0,0.3-0.1,0.4-0.2
        c-0.1,0.5,0.2,0.8,0.4,1.2c-0.4,0.2-0.5-0.3-0.8-0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0,0.2-0.1,0.3c0.2,0,0.2-0.1,0.2-0.3
        c0.2,0.1,0.3,0.2,0.3,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c0.2,0,0.4,0.1,0.6,0.1c0-0.3,0-0.6,0-0.9c0.1,0.2,0.2,0.4,0.3,0.6
        c-0.1,0.1-0.2,0.3-0.3,0.4c0.1,0,0.3,0.1,0.3,0.1c0,0.1-0.1,0.2-0.2,0.2l-0.3,0.1l0.1,0.2c0.3,0.3,0.4-0.2,0.5-0.4
        c0,0,0.1,0.1,0.2,0.1c-0.2,0.3-0.3,0.5-0.4,0.8c0.2,0.2,0.2,0.4,0.1,0.7c-0.2,0-0.5-0.1-0.6-0.2c0.1,0.1,0.1,0.2,0.2,0.3
        c0.2,0,0.4,0,0.6,0.1c-0.1-0.1-0.1-0.2-0.1-0.4l0.1,0.2c0.1-0.1,0.3-0.2,0.4-0.3c0,0.1,0,0.2,0,0.3c-0.2,0-0.3,0.2-0.3,0.4
        c0.2,0.1,0.4,0.2,0.6,0.3c-0.1-0.2-0.1-0.5,0.1-0.7c-0.1-0.1-0.1-0.3-0.2-0.4c0-0.6-0.2-1.2,0.1-1.7c-0.2-0.2-0.2-0.6-0.3-0.9
        c0.1-0.6-0.2-1.2-0.1-1.8c-0.1-0.8-0.4-1.5-0.3-2.3c-0.4-0.5-0.1-1.2-0.3-1.7c0.1,0,0.2,0,0.4-0.1c-0.1,0-0.3,0-0.4,0
        c0.1-0.6-0.3-1.3,0-1.9c-0.1-0.3-0.2-0.6-0.3-0.9c0,0,0.1,0,0.1,0.1c0.2,0.5,0.3,1,0.4,1.5c0,0.2,0.1,0.3,0.1,0.5
        c0,0,0.1,0.1,0.1,0.2l0.2-0.1c0,0.2,0,0.4,0,0.6l0.2,0c0,0.2,0,0.4,0,0.6l0.1,0c0,0.2,0,0.6,0.3,0.6c0,0.1,0,0.3,0,0.4
        c0.1,0,0.2,0.1,0.3,0.2c0,0.1,0,0.2,0,0.3l0.3-0.1c-0.3,0.1-0.3,0.6,0.1,0.5c0,0.2,0,0.5,0,0.7c0.1,0,0.2,0,0.3,0
        c-0.1,0.1-0.3,0.3-0.1,0.5c0.1,0.2,0,0.6,0.2,0.8c0-0.1,0-0.3,0.1-0.4l0.2,0.1c0,0.1-0.1,0.3-0.2,0.4c0.2,0,0.3,0.2,0.4,0.4
        c-0.1-0.1-0.3-0.3-0.4-0.3c0,0.2,0,0.5,0,0.7c-0.1-0.1-0.3-0.2-0.4-0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0,0.2,0,0.2,0
        c0.1-0.1,0.2-0.3,0.3-0.4c-0.2,0.4,0.4,0.7,0.1,1c0.2,0,0.3,0,0.5,0.2c-0.1,0-0.3-0.1-0.4-0.1l0,0.1c0.2,0.2,0.4,0.5,0.6,0.7
        c0.1,0.2,0.1,0.4,0.1,0.6c0,0.1-0.1,0.3-0.1,0.4l-0.2,0c0.3,0.3,0,0.5-0.2,0.7c0.2,0,0.3,0,0.5,0c-0.2-0.3,0-0.9,0.4-0.9
        c-0.1-0.3,0.2-0.6,0.4-0.8c0.3-0.4,0.6-0.8,1.1-1c-0.2,0.3-0.4,0.5-0.5,0.8c-0.1,0.2,0,0.6-0.3,0.8c0,0,0.1,0.1,0.2,0.2
        c0,0.5,0,0.9-0.2,1.4c0.1,0,0.3-0.1,0.4-0.1c-0.1,0.1-0.2,0.2-0.3,0.3c0.2,0.3,0.3,0.6,0.6,0.7c0-0.1,0-0.3,0-0.5
        c0,0.1,0.1,0.3,0.2,0.4c0,0,0,0.1,0,0.1c0,0,0.1,0.1,0.2,0.1c0,0.2-0.2,0.3-0.2,0.5c-0.3-0.1-0.5,0.2-0.7,0.3c0.1,0,0.3,0,0.4-0.1
        l-0.1,0.2c0,0,0.1,0,0.1,0c0-0.2,0.1-0.3,0.3-0.4c0.1-0.2,0.2-0.3,0.3-0.4c0-0.2-0.1-0.4,0.1-0.4l0,0.3c0.1,0,0.4-0.1,0.5-0.1l0,0.2
        c0.3-0.1,0.5-0.1,0.8-0.2c-0.2,0.2-0.3,0.4-0.5,0.5c0,0.1,0,0.2,0,0.2c-0.1,0-0.2,0-0.3,0c0,0.3-0.3,0.5-0.1,0.8
        c-0.2,0.2-0.4,0.4-0.6,0.6c0.2-0.1,0.4-0.2,0.6-0.3c-0.1,0.1-0.2,0.3-0.2,0.4c-0.5-0.1-0.1,0.3,0.1,0.2c0.1,0.1,0.1,0.3,0.2,0.4
        c-0.1,0-0.3,0-0.4,0c0.1,0.3,0.5,0.3,0.5,0.6c-0.1,0-0.2,0-0.2,0l-0.1-0.1c0,0,0,0.1,0,0.2c0.3,0,0.7,0.1,1,0c0,0.1-0.1,0.3-0.1,0.4
        c0.3-0.2,0.6-0.2,1-0.3c-0.1,0.2-0.2,0.5-0.2,0.8l-0.1,0c0,0.4-0.3,0.7-0.4,1.1c0.1,0.1,0.2,0.2,0.3,0.3l-0.3,0
        c0.1,0.1,0.2,0.3,0.3,0.4c0,0.1-0.1,0.2-0.1,0.3c0.2,0.1,0.3,0.2,0.4,0.3c-0.1,0-0.3-0.1-0.4-0.1c0,0.1,0,0.2,0.1,0.2
        c0.3,0.2,0.7,0.6,1.1,0.2l0,0.4c-0.3-0.1-0.1,0.1-0.1,0.2c-0.3,0.1-0.6,0.5-0.7,0.8l0.3,0c-0.1,0.2-0.3,0.3-0.4,0.5
        c0.2,0,0.3,0.1,0.5,0.1c0,0.2,0,0.3,0.1,0.5c-0.2-0.1-0.3-0.3-0.3-0.5c-0.1,0.1-0.3,0.2-0.4,0.3c0.5,0.1,0.3,0.7,0.6,1l0.1,0l0,0.1
        c0.2,0,0.4,0.1,0.5,0.2c-0.1,0-0.3,0-0.3,0c0.1,0.1,0.1,0.1,0.2,0.2l0-0.2c0.1,0,0.3,0,0.3,0c0.1,0.2,0.3,0.3,0.6,0.1
        c-0.2,0.4-0.6,0.8-0.8,1.2c0.1,0,0.3-0.1,0.4-0.1c-0.2,0.2-0.4,0.4-0.4,0.7c0.2,0.1,0.3,0.2,0.1,0.5l0.1,0c0,0.2,0.1,0.3,0.2,0.4
        c-0.1,0-0.2,0.1-0.3,0.1c0,0.4,0.4,0.3,0.6,0.3c-0.1,0-0.3,0-0.3,0c0.2,0.2,0.2,0.4,0,0.5c-0.1-0.1-0.3-0.2-0.3-0.2
        c0.1,0.2,0.4,0.2,0.6,0.3l0.1-0.3c0,0.2,0,0.3,0,0.5c0.3-0.1,0,0.3,0.2,0.3c0.3,0,0.6,0.1,0.8-0.2c0.1,0,0.2,0.1,0.3,0.2
        c-0.2,0-0.3,0-0.4,0c-0.2,0.3-0.4,0.7-0.6,1.1c0.1,0,0.2,0,0.2,0c-0.2,0.3-0.2,0.6-0.5,0.7c0.7-0.1-0.3,0.5,0.3,0.6
        c-0.1,0-0.2-0.1-0.3-0.1l0,0.3l0.1,0.1l-0.2,0c0,0.1,0.1,0.2,0.1,0.3c-0.1,0.1-0.3,0.2-0.4,0.3c0.1,0,0.3-0.1,0.4-0.2l-0.1,0.3
        c0.4,0.2,0.3,0.7,0.4,1l0.2,0l0,0.3l-0.2,0c0.1,0.2,0.1,0.3,0.2,0.5c0.1-0.1,0.2-0.2,0.2-0.3c0.1,0.1,0.2,0.2,0.3,0.3
        c0.1,0.1,0.1,0.3,0,0.4c-0.1-0.5-0.6,0-0.9-0.1c-0.2-0.2-0.4-0.5-0.6-0.7c0.1,0.2,0.2,0.4,0.3,0.6c0,0-0.1,0.1-0.1,0.2l0.2,0
        c-0.2,0.2-0.2,0.4,0,0.6c0.3-0.2,0.7-0.6,1-0.7c-0.2,0.2-0.3,0.4-0.4,0.6c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.3,0,0.5,0,0.8
        c-0.1,0.3-0.3,0.8,0.1,0.9c-0.2,0.1-0.2,0.3-0.2,0.6c-0.1,0-0.3-0.1-0.3-0.1c0.1,0.1,0.2,0.2,0.4,0.3c0,0.2,0,0.4,0,0.6
        c-0.1,0-0.2,0-0.2,0c0.1,0.3,0.1,0.7,0.2,1c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1,0.3-0.2,0.7-0.4,1c0.1,0,0.4,0,0.5,0
        c-0.1-0.3,0.4-0.2,0.3,0c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0-0.2-0.1-0.3-0.1c0,0.3,0,0.6,0.1,0.8c-0.1,0-0.3,0-0.3,0c0.1,0,0.3,0,0.3,0
        l-0.1,0.2c0,0.1,0.1,0.2,0.1,0.3c-0.3,0.1-0.5,0.2-0.2,0.5c-0.2,0.3,0.2,0.3,0.2,0.6c-0.2-0.1-0.4-0.3-0.4-0.5
        c-0.1,0.1-0.2,0.1-0.2,0.2c0.2,0.2,0.4,0.4,0.5,0.6l-0.2,0.1c0,0.1,0.1,0.2,0.2,0.2c0-0.1,0.2-0.2,0.2-0.2l0.3,0
        c-0.1-0.1-0.1-0.3-0.1-0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.2-0.2,0.5-0.3,0.8-0.4c0.6-0.4,1.2-0.9,1.9-1.2c0.2-0.4,0.7-0.5,1-0.8
        c0.4-0.4,1.1-0.6,1.5-1c0.1,0,0.2,0.1,0.2,0.1c0.1-0.1,0.2-0.3,0.3-0.4c0.7-0.1,1.1-1,1.8-0.9c0.1-0.4,0.5-0.5,0.9-0.5
        c0.3-0.4,0.6-0.7,1.1-0.9c0.3-0.5,0.7-0.8,1.2-1.1c0.5-0.3,0.8-0.7,1.3-0.9c0.1-0.2,0.2-0.4,0.3-0.6c0.3-0.3,0.5-0.7,1-0.8
        c0.2-0.3,0.7-0.5,0.8-0.9c0.4-0.5,0.7-1,1.3-1.3c0.6-0.3,0.7-1.1,1.1-1.5c0.1-0.5,0.6-0.9,0.7-1.4c0-0.2,0.2-0.3,0.3-0.5
        c0.2-0.7,0.6-1.4,1.2-2c0-0.1,0-0.3,0-0.4c0.3-0.3,0.3-0.7,0.6-1c0.4-0.8,0.9-1.5,1.2-2.3c0.2-0.2,0.4-0.5,0.4-0.8
        c0.3-0.6,0.3-1.3,0.9-1.7c-0.1,0-0.2-0.1-0.2-0.1c0.2-0.1,0.4-0.3,0.4-0.5c0.1-0.3,0.4-0.6,0.3-0.9c0.3-0.2,0.5-0.6,0.5-1
        c0.1,0,0.2,0,0.3,0l-0.1-0.2l0.2,0c0-0.2,0-0.3,0-0.5c0.3-0.3,0.3-0.7,0.5-1c-0.1-0.1-0.1-0.3,0-0.4c0.1,0,0.2,0.1,0.3,0.2
        c0-0.1,0-0.4,0-0.5c0.1,0,0.2-0.1,0.3-0.1c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.3-0.3c-0.1-0.1-0.1-0.2-0.2-0.3c0.1,0,0.3,0,0.3,0
        c0-0.3-0.1-0.5-0.1-0.8c0.1,0.1,0.2,0.2,0.3,0.2c0.1-0.2,0.1-0.3,0.2-0.5c0.1-0.2,0.3-0.5,0.3-0.8c-0.2-0.2,0.1-0.4,0.2-0.5
        c0.1-0.2,0.1-0.4,0-0.7c0.3-0.1,0.3-0.5,0.5-0.7c0.2-0.4,0.3-0.9,0.4-1.4c0.3-0.5,0.5-1.1,0.7-1.6c-0.1-0.8,0.6-1.3,0.7-2
        c0.1-0.3,0.3-0.4,0.5-0.6c-0.3-0.6,0.3-1.1,0.4-1.7c0.3-0.5,0.3-1.2,0.8-1.6c0-0.1-0.1-0.2-0.2-0.2c0.1,0,0.2,0,0.3,0l0.1-0.2
        c0-0.1-0.1-0.2-0.2-0.3c0.4,0,0.2-0.5,0.3-0.7c0.3-0.9,0.7-1.6,0.9-2.5c0.1-0.5,0.5-0.8,0.5-1.3c0.3-0.3,0.3-0.8,0.4-1.2
        c0.4-0.4,0.3-1.1,0.7-1.5c0-0.3,0.1-0.5,0.2-0.7c-0.3-0.1-0.6-0.2-0.9-0.3c-0.8-0.1-1.5-0.5-2.2-0.7c-0.5-0.1-0.9-0.4-1.4-0.4
        c-0.6-0.5-1.4-0.3-2-0.8c-0.6,0.1-0.8-0.6-1.4-0.6c-0.2-0.1-0.5,0-0.7-0.2c-0.3-0.3-0.8-0.4-1.1-0.6c-0.7-0.2-1.4-0.5-2.1-0.7
        c-0.6-0.2-1.1-0.7-1.8-0.9c-0.7-0.6-1.7-0.7-2.5-1.2c-0.4-0.3-1-0.4-1.4-0.7c-0.4-0.1-0.9-0.1-1.1-0.4c-0.7,0-1.4-0.3-1.8-0.8
        c-0.5,0.1-0.9,0-1.2-0.3c-0.1,0.1-0.3,0.1-0.4,0.2c-0.3-0.2-0.5-0.3-0.8-0.3c-0.6,0-1.2-0.2-1.8-0.1c0,0-0.1-0.1-0.2-0.2
        c-0.3,0.4-0.6,0.1-0.8-0.1c-0.3,0.2-0.5,0.5-0.8,0.5c0,0.1,0,0.2,0,0.2c-0.4-0.2-0.5,0.3-0.6,0.5c-0.2,0.3-0.1,0.7-0.3,1
        c-0.4,0.8-0.3,1.8-0.7,2.6c0.3,0,0.4,0.1,0.4,0.4c-0.2-0.1-0.3-0.1-0.5-0.2c0.2,0.2,0.3,0.3,0.4,0.6c-0.1,0-0.2,0.1-0.2,0.2
        c0.2,0.1,0.3,0.2,0.4,0.3c-0.1,0-0.3,0.1-0.3,0.2c0.2,0.1,0.3,0.1,0.5,0.2c0,0,0,0,0,0c-0.3-0.1-0.4,0.3-0.2,0.5
        c0.1,0.3,0.2,0.5,0.4,0.7c-0.1,0-0.4,0-0.5,0c0.1,0.2,0.3,0.2,0.5,0.2c-0.1,0.2-0.2,0.4-0.2,0.6c0.1-0.1,0.3-0.2,0.4-0.2
        c0,0.3,0.2,0.6-0.1,0.8c0.2,0.1,0.3,0.1,0.5,0.2c0.1,0.2-0.2,0.4-0.3,0.6c0.2,0.1,0.5,0.2,0.6,0.5c-0.1,0-0.3,0-0.4,0.1
        c0.4,0,0.5,0.4,0.5,0.7c0.1,0,0.2,0.1,0.3,0.1c0,0.1,0,0.3,0,0.4c0.4,0.3,0.5,0.8,0.7,1.2c0.3,0.1,0.3,0.2,0.2,0.5
        c-0.1,0-0.2-0.1-0.3-0.2c0.1,0.1,0.1,0.2,0.2,0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.2,0.2,0.3,0.4,0.1,0.6c0.2,0.2,0.4,0.4,0.5,0.7
        c0.1,0,0.2,0,0.3,0.1c0.1,0.4,0.4,0.7,0.8,0.7c0,0.2,0,0.5,0.2,0.5c0.4,0,0.3,0.9,0.8,0.6c0.2,0.5,0.7,0.8,0.9,1.2
        c0.2,0.2,0.3,0.5,0.5,0.7c0.4,0.2,0.3,0.7,0.8,0.8c0.1,0.3,0.4,0.5,0.6,0.8c0.3,0.4,0.5,0.9,0.9,1.1c0,0.3,0.3,0.4,0.4,0.6
        c0.4,0.5,0.7,1,1,1.6c0.4,0.7,0.8,1.3,1.2,1.9c0.1,0.9,0.6,1.6,0.8,2.4c0,0.1,0,0.3,0,0.3c0.3,0.2,0.2,0.6,0.3,0.8
        c0.3,1.1,0.3,2.3,0.5,3.5c-0.1,0-0.2-0.1-0.2-0.2c0,0,0-0.1,0.1-0.1c0-0.2,0-0.4,0-0.7c-0.2-0.3-0.1-0.6-0.2-0.9
        c-0.2-0.6-0.4-1.2-0.7-1.7c0,0-0.1,0-0.1,0c0-0.3-0.2-0.6-0.4-0.8l0.3,0c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2-0.2-0.3-0.6-0.7-0.5
        c0.2-0.5-0.4-0.6-0.5-1c-0.4-0.6-0.9-1.1-1.2-1.7c-0.4-0.2-0.3,0.6,0.1,0.5c0,0.1,0.1,0.2,0.2,0.3c-0.3-0.1-0.5-0.5-0.7-0.8
        c-0.1,0-0.3-0.1-0.4-0.2c0.2,0,0.5,0,0.7-0.1c-0.2-0.3-0.9-0.5-0.5-0.9c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2-0.1-0.3-0.1
        c0-0.1,0.1-0.3,0.1-0.3c-0.1,0-0.4,0.1-0.5,0.1c0-0.1,0.1-0.3,0.1-0.4l-0.2,0.1c0.3-0.5-0.5-0.4-0.4-0.9c-0.2,0.2-0.6,0.6-0.4,0
        c-0.2-0.1-0.3-0.2-0.3-0.4c-0.2,0-0.3-0.1-0.3-0.3c-0.2,0-0.3,0-0.5-0.1l0.1-0.2c-0.3,0.1-0.5-0.3-0.4-0.5c-0.3-0.2-0.8-0.3-1-0.6
        c0.3,0.1,0.7,0.3,1,0.4c0,0.3,0.1,0.4,0.3,0.6c0.2,0.1,0.5,0.2,0.7,0.4c0.2-0.2-0.2-0.4-0.3-0.5c-0.1-0.3-0.3-0.4-0.5-0.3
        c0-0.1,0-0.3,0-0.4c-0.2-0.1-0.4-0.5-0.7-0.2c0-0.1,0-0.2,0-0.3c-0.2,0-0.4,0.1-0.5,0c0,0-0.1,0.1-0.2,0.1l0-0.2c-0.1,0-0.3,0-0.3,0
        l0-0.1c0.1,0,0.2,0,0.2-0.1c0-0.1,0.1-0.3,0.1-0.4c-0.2,0-0.4,0-0.6-0.1c0,0.1,0,0.3,0,0.4c-0.2-0.2-0.4-0.4-0.6-0.6
        c0.1-0.1,0.2-0.2,0.3-0.2c0,0.1,0.1,0.2,0.1,0.3c0.1,0,0.4,0,0.6,0c-0.1-0.6-0.9-0.5-1.3-0.7l0,0.3c-0.2-0.3-0.5-0.6-0.5-0.9
        c0.2,0.1,0.4,0.4,0.6,0.4c0,0,0.1,0.1,0.2,0.2c0.3,0,0-0.3,0-0.5c-0.6-0.1-1.2-0.2-1.8-0.5c0-0.2,0-0.4,0-0.6
        c0.2,0.1,0.3,0.3,0.3,0.5c0.2-0.1,0.4,0.1,0.5,0.2c0.1-0.2,0-0.5-0.2-0.5c0-0.1-0.1-0.3-0.1-0.4c0,0.1-0.1,0.2-0.2,0.2
        c-0.1,0-0.3-0.1-0.4-0.1c-0.1-0.3-0.3-0.6-0.6-0.7c0,0.2,0,0.4-0.1,0.6c-0.1-0.1-0.2-0.4-0.3-0.5c0.2-0.5-0.5-0.5-0.7-0.9
        c-0.2,0-0.3,0-0.5-0.1c0.1-0.3-0.3-0.6-0.6-0.5c0-0.2,0-0.5,0-0.7c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2-0.2-0.4-0.3-0.2-0.6
        c0.1,0.2,0.2,0.3,0.4,0.4l-0.1-0.2l-0.2-0.1l0,0c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.1-0.3-0.2-0.5-0.2c0-0.1,0-0.3,0.1-0.4
        c-0.3,0-0.3,0.3-0.3,0.5c-0.1-0.3-0.2-0.6-0.6-0.7c0.1-0.1,0.1-0.2,0.2-0.2c0.1,0.1,0.3,0.2,0.4,0.3l0-0.2c-0.3-0.2-0.3-0.7-0.7-0.9
        c-0.1-0.1-0.4-0.2-0.5-0.3c0.2,0,0.4-0.1,0.6-0.1c-0.1,0-0.4,0-0.5,0c0-0.1,0-0.3,0-0.3c-0.2,0-0.4,0-0.5,0c0-0.1,0.1-0.3,0.1-0.4
        c0.1,0.1,0.3,0.2,0.4,0.3c0-0.3-0.3-0.4-0.5-0.5c0-0.6-0.5-1.1-1.1-1c0,0.2,0.2,0.3,0.4,0.4c0.4-0.6,0.5,0.4,0.6,0.6
        c-0.1-0.1-0.3-0.3-0.4-0.4c-0.1,0-0.2,0.1-0.3,0.1c-0.3-0.3-0.6-0.5-0.5-0.9c-0.1-0.1-0.1-0.1-0.2,0c-0.1-0.1-0.3-0.1-0.3-0.3
        c0.2,0.1,0.5-0.1,0.7,0.1c0.1-0.3-0.2-0.5-0.4-0.5c0.1,0,0.2-0.1,0.2-0.1l0-0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.1-0.1-0.2-0.2-0.3-0.2
        c0.3-0.2,0-0.4-0.2-0.5c-0.1-0.2-0.1-0.4-0.2-0.6c0.3,0,0.2,0.3,0.3,0.6c0.3,0.1,0.2,0.5,0.5,0.5c0.2-0.4-0.2-0.8-0.2-1.2
        c-0.3-0.1-0.4-0.6-0.2-0.9c0,0.2,0,0.4,0,0.6c0.3-0.1,0-0.4,0-0.6c-0.4-0.2,0.3-0.8-0.1-1.1c0,0.1,0.1,0.3,0.1,0.3
        c-0.4,0.4-0.4-0.4-0.4-0.7l0.2,0c-0.2-0.2-0.4-0.4-0.6-0.6c0,0-0.1-0.1-0.1-0.2l-0.1-0.1l0-0.1c0.2,0.1,0.4,0.4,0.7,0.3
        c0,0.1,0,0.2,0,0.2l0.1,0c-0.1-0.2,0-0.5,0.1-0.6c0,0,0,0,0.1-0.1c-0.2,0.1-0.4,0.1-0.6,0.2c0.1-0.2,0.1-0.4,0.1-0.7l0.2,0
        c-0.1-0.1-0.2-0.3-0.3-0.4c-0.3,0-0.3,0.2-0.2,0.5c-0.1-0.1-0.3-0.3-0.4-0.4c0.1,0,0.2,0,0.3,0c0-0.1,0.1-0.3,0.1-0.4
        c-0.2,0-0.4-0.2-0.6-0.3c0.4,0,0.6-0.1,0.8-0.5c-0.1,0-0.3-0.1-0.4-0.1c0.1,0,0.2,0,0.3,0c0.1-0.2,0.2-0.4,0.4-0.5
        c0.1,0.1,0.2,0.2,0.3,0.3c-0.1-0.2-0.1-0.3-0.2-0.5c0.2,0,0.3,0,0.3-0.2c-0.2,0.1-0.5,0.3-0.7,0.3c0,0-0.1,0.1-0.2,0.2
        c0-0.2,0-0.5,0.4-0.4c0-0.1,0-0.2-0.1-0.3c0.1,0,0.2,0.1,0.3,0.1c0-0.1,0-0.4,0-0.5c-0.1-0.2-0.2-0.4-0.4-0.6
        c0.1,0.2,0.3,0.3,0.5,0.5c0-0.1-0.1-0.4-0.1-0.5c0.1,0,0.3,0,0.4-0.1c-0.1,0.1-0.2,0.3-0.2,0.4c0.2,0.1,0.3,0,0.5,0
        c-0.1-0.2-0.1-0.5-0.2-0.7c-0.1,0-0.2,0-0.2,0.1c0.1-0.2,0-0.3,0-0.5c0.2,0.4,0.4,0.1,0.3-0.2c0.2,0.2,0.4-0.1,0.6-0.1l0.1,0.1
        c0.4,0.2,0.2-0.3,0-0.4c-0.2,0.1-0.3,0.3-0.5,0.4c0-0.1,0-0.4-0.1-0.5c0.3,0,0.5-0.1,0.8-0.1c-0.1-0.2-0.2-0.3-0.3-0.4
        c0.2,0,0.3-0.1,0.5-0.1c0-0.4,0.5-0.3,0.7-0.4c-0.1,0-0.3,0-0.4-0.1c0.4-0.3,0.7-0.7,1.3-0.6l-0.1,0.3c0.1-0.1,0.2-0.2,0.3-0.3
        l0-0.2c0.1-0.1,0.3-0.2,0.4-0.3c-0.1,0.3,0,0.6-0.3,0.8c0.1-0.1,0.2-0.1,0.3-0.1c0-0.2,0-0.4,0-0.6c0.2,0.1,0.5,0.5,0.5,0.1
        l-0.3-0.1c0.1-0.1,0.2-0.2,0.4-0.2c0-0.2,0.1-0.3,0.2-0.5l-0.2,0c0.1,0,0.2,0,0.2,0c0,0.2-0.1,0.4-0.1,0.7c0.1,0,0.3-0.1,0.4-0.1
        c-0.1-0.1-0.2-0.2-0.3-0.2c0.1-0.1,0.2-0.3,0.4-0.4c0,0.1-0.1,0.2-0.1,0.3l0.4,0c0-0.1,0-0.2,0-0.3c0.3,0.3,0.7,0,1.1,0.2
        c0.2-0.2,0.4-0.3,0.7-0.2c0-0.1,0-0.2,0-0.3c0.3,0,0.6-0.2,0.9,0c0-0.1,0.1-0.2,0.1-0.3c0.1,0.1,0.2,0.3,0.3,0.4c0-0.1,0-0.3,0-0.4
        c0.1-0.1,0.3-0.1,0.4-0.2c0.2,0,0.3-0.2,0.5-0.3c0,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1l-0.2,0c0,0.1,0.1,0.3,0.1,0.4
        c0.1-0.1,0.3-0.2,0.4-0.3c0.1,0.3,0.4,0.2,0.5,0c0.2,0,0.3,0,0.5,0c0.1,0.1,0.2,0.3,0.3,0.4c0-0.2-0.1-0.4-0.2-0.6
        c0.1,0,0.4,0,0.5,0.1c0.1,0.2-0.2,0.4-0.2,0.6c0.2-0.1,0.4-0.3,0.7-0.4c0,0.1,0,0.3,0,0.3c0.4-0.1,0.2-0.3,0-0.5c0.1,0,0.2,0,0.3,0
        c0,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.3,0c0,0.2,0.2,0.4,0.4,0.4c0-0.3,0.1-0.6-0.3-0.6c0-0.4,0.4-0.1,0.4,0.1c0.3,0,0.6,0,0.8,0.3
        c0.1-0.2,0.1-0.3,0.2-0.5c0.1,0.1,0.3,0,0.4-0.1c-0.1,0.1-0.1,0.2-0.1,0.4c0.1,0,0.4,0.1,0.5,0.1c0-0.1-0.1-0.4-0.2-0.6
        c0.2,0.1,0.5,0.2,0.7,0.2l0,0.2c-0.1,0-0.3-0.1-0.4-0.1l0,0.2c0.2,0,0.4,0,0.7-0.1c0.1,0.2,0.4,0.4,0.3,0c-0.1-0.2-0.6,0.1-0.5-0.3
        c0.2,0.1,0.5,0.1,0.7,0c0.1,0.1,0.3,0.2,0.3,0.3c-0.1,0-0.2,0.1-0.2,0.2c0.3,0.1,0.7,0.3,1,0.4c0.2-0.3,0.6-0.5,0.9-0.7
        c0,0.1-0.1,0.3-0.1,0.4c0.2-0.1,0.4-0.2,0.7-0.3c-0.1,0.3-0.4,0.4-0.6,0.6c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3,0.2,0,0.4,0.1,0.6
        c0.1-0.1,0.3-0.3,0.4-0.4c0.1,0.1,0.2,0.2,0.3,0.3c-0.1-0.3,0-0.4,0.1-0.5c0,0.1,0.1,0.2,0.1,0.3c-0.2-0.4,0.4-0.7,0.6-0.3
        c-0.1,0-0.3,0-0.5,0c0.2,0.2,0.4,0.3,0.6,0.4c0.1,0,0.3-0.1,0.4-0.1c0.4,0.3,1,0.3,1.4,0.1c0,0.1,0,0.4,0,0.6
        c0.2-0.1,0.3-0.2,0.4-0.4c0.1,0,0.4,0,0.6,0c0,0.1,0,0.3-0.1,0.4c0,0,0.1,0,0.2,0c-0.1-0.5,0.3-0.3,0.6-0.3l0,0.2
        c0.3-0.3,0.4,0.1,0.3,0.3l-0.3,0l0.1,0.1c0.3,0,0.6-0.3,0.9-0.4c-0.2-0.1-0.4-0.3-0.6,0.1c0-0.1-0.1-0.3-0.1-0.4
        c-0.2,0.1-0.3,0.3-0.5,0.1c-0.2-0.2-0.5-0.2-0.7,0c0-0.1,0-0.3,0-0.4c-0.1,0-0.2-0.1-0.3-0.1l0,0.2c-0.4,0.1-0.6-0.2-0.9-0.3
        c-0.3,0.1-0.5,0-0.5-0.3c-0.3,0-0.7-0.1-1,0c-0.1-0.1-0.2-0.3-0.4-0.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1-0.1-0.2-0.3-0.3-0.4
        c-0.2,0-0.5,0-0.7,0c-0.2-0.2-0.6-0.3-0.8-0.4c-0.4-0.2-0.7-0.4-1.2-0.4c0-0.1-0.1-0.2-0.2-0.2c-0.3,0-0.6,0-0.9,0.1
        c0.2-0.5-0.6,0-0.6-0.5c-0.2,0.1-0.3,0.1-0.4-0.1c0,0.1-0.1,0.2-0.2,0.3c0-0.3-0.3-0.5-0.5-0.7c-0.4,0.4-0.8-0.1-1.1-0.2
        c-0.5-0.1-1.2-0.1-1.4-0.7c-0.1,0.1-0.2,0.2-0.4,0.3c0.1,0,0.3,0,0.4,0c-0.1,0-0.2,0.1-0.4,0.2c-0.2-0.5-0.8-0.5-1.2-0.7
        c-0.2-0.1-0.5,0-0.7,0c0-0.4-0.5-0.4-0.8-0.5c0,0.1,0,0.2,0,0.3l-0.1,0c0-0.1,0.1-0.3,0.1-0.4c-0.1,0-0.3,0-0.4,0.1
        C349.5,94.8,349.2,94.7,348.8,94.8 M256,94.8l0,0.2c-0.6-0.2-0.1,0.3-0.1,0.6c-0.1,0.3-0.2,0.7,0,1c-0.3,0.7-0.2,1.4-0.1,2.1
        c1-0.8,0.2-2.3,1.1-3.2c0-0.1-0.1-0.3-0.1-0.4C256.6,95.1,256.3,95,256,94.8 M276.1,95.1c0,0.2-0.1,0.3-0.3,0.3
        c0,0.3-0.2,0.8-0.6,0.8c0,0.2-0.1,0.4-0.2,0.5c-0.2,0-0.3,0.1-0.3,0.3c-0.1,0-0.2,0-0.3,0c0,0.1,0,0.3,0,0.4
        c-0.4,0.3-0.7,0.8-0.9,1.2c-0.3,0.5-0.6,0.9-1,1.3c0.2,0.2,0.5,0.3,0.6,0.5c0,0,0.1-0.1,0.2-0.1c0.1,0.1,0.1,0.3,0.2,0.3
        c0.1-0.1,0.2-0.3,0.4-0.4c0-0.2,0.1-0.3,0.3-0.5c0.3-0.8,0.5-1.5,0.9-2.2c0.4-1,0.7-1.9,1.2-2.9C276.3,94.9,276.2,95,276.1,95.1
        M284.1,94.9C283.9,95.3,284.6,94.9,284.1,94.9 M347.1,95.1C347.2,95.2,347.2,95.2,347.1,95.1 M127.3,95.3
        C127.4,95.8,127.8,95.1,127.3,95.3 M128,95.3C128.1,95.8,128.4,95.1,128,95.3 M128.5,95.4c0.1,0.2,0.2,0.2,0.4,0.1
        c0-0.1-0.1-0.2-0.1-0.2C128.7,95.3,128.6,95.4,128.5,95.4 M201,96.5c0.2,0,0.4,0,0.5,0c-0.2-0.4-0.5-0.7-0.6-1.2
        C201,95.7,201,96.1,201,96.5 M251.2,95.3C251.2,95.4,251.2,95.4,251.2,95.3 M323.4,95.3c-0.1,0.2,0,0.3,0.2,0.2
        C323.6,95.4,323.6,95.3,323.4,95.3 M386,95.4C386.1,95.5,386.1,95.5,386,95.4 M226.4,95.9l0.1,0.1l-0.3,0l0.2-0.3
        c-0.1,0-0.4,0-0.6,0L226,96c-0.1,0.1-0.3,0.1-0.4,0.2c0,0.1-0.1,0.2-0.1,0.2l0-0.2l-0.5-0.1c0,0.1,0.1,0.3,0.1,0.4
        c-0.1-0.1-0.2-0.2-0.2-0.3c0,0.3-0.3,0.4-0.6,0.5c-0.3,0.5-0.6,1-1,1.5c0.2-0.8,0.7-1.4,0.8-2.2c-0.2,0.1-0.4,0.3-0.5,0.4
        c0-0.3-0.2-0.3-0.4-0.2c0.1,0,0.3,0.1,0.4,0.2c-0.1,0.4-0.3,0.1-0.5,0c-0.1,0.1-0.2,0.3-0.2,0.4c0-0.1,0-0.3,0-0.4
        c-0.1,0.1-0.2,0.2-0.3,0.3l-0.2-0.1c0.1,0.2,0.3,0.4,0.5,0.6c-0.1,0-0.3,0-0.4,0c0.4,0.3,0.7,0.7,0.9,1.2l-0.4,0
        c0.1,0,0.2,0.1,0.3,0.2l0,0.2c0.1-0.1,0.1-0.2,0.2-0.3l-0.1,0.2l0.1,0c0.2,0.3,0.3,0.5,0.5,0.7c-0.4-0.3-0.9-0.5-1.3-0.7
        c-0.5-0.3-1-0.7-1.6-0.8c-0.1-0.2-0.2-0.3-0.3-0.5c0.1,0.2,0.2,0.5,0.3,0.7c0.5-0.2,0.8,0.4,1,0.8c0.1,0.2,0.3,0.4,0.6,0.3
        c0,0.1,0,0.2,0,0.3c-0.1,0-0.2,0-0.2-0.1c0.1,0.1,0.2,0.3,0.2,0.5c0.3-0.1,0.7,0.3,0.6,0.6c0.1,0,0.2,0,0.2-0.1c0,0.1,0,0.3,0.1,0.5
        c0.4,0,0.5-0.3,0.2-0.6c0.1,0,0.2,0,0.3,0c-0.1-0.3,0.5-0.2,0.5-0.4c-0.1-0.2-0.3-0.1-0.4,0l-0.1,0c0.2-0.4,0.8-0.3,0.8-0.7
        c0.1,0,0.3,0.1,0.4,0.1c-0.2-0.4,0-0.7,0.1-1.1c0.3,0.2,0.5-0.2,0.7-0.4c-0.2,0-0.4,0-0.6,0.1c0-0.1,0-0.3,0.1-0.3l0.1,0.2
        c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.2,0.1-0.5,0.2-0.7c0,0,0.1,0,0.2,0c0-0.3,0.2-0.5,0.3-0.8c0.5,0.1,0.4-0.4,0.4-0.7
        C226.5,95.4,226.6,95.8,226.4,95.9 M191.3,95.6c-0.2,0-0.3,0.4,0,0.4C191.4,96,191.5,95.6,191.3,95.6 M214.7,95.7
        C214.8,95.8,214.8,95.8,214.7,95.7 M284.4,95.7c-0.1,0.2,0,0.3,0.2,0.2C284.7,95.7,284.6,95.6,284.4,95.7 M385.8,95.8
        c0,0.2,0.4,0.3,0.4,0.1C386.2,95.7,385.8,95.6,385.8,95.8 M386.3,95.7c-0.1,0.2,0.3,0.3,0.4,0.2l0-0.1
        C386.6,95.7,386.5,95.7,386.3,95.7 M225.4,95.9C225.4,96.3,225.8,95.6,225.4,95.9 M286.4,95.9C286.5,96,286.5,96,286.4,95.9
        M257.3,96c0,0.2,0,0.4,0,0.6c0.3,0.2-0.1,0.8,0.3,0.8c0,0.4-0.2,0.8,0.1,1.1c-0.1,0.3-0.4,0.5-0.5,0.8c0,0,0.1,0,0.2,0.1
        c0-0.3,0.2-0.4,0.5-0.5c-0.2,0.3,0.1,0.5,0.2,0.7c0,0.1-0.1,0.4-0.2,0.5l0.3-0.2c-0.1,0.3,0.1,0.4,0.2,0.6c0,0.1-0.1,0.4-0.1,0.5
        c0.2,0.1,0.3,0.2,0.5,0.3c0,0.1-0.1,0.2-0.1,0.3c0,0,0.1,0,0.2,0l0-0.3l0.2,0.1c0,0.1-0.1,0.2-0.2,0.3c0.2,0.4,0.6,0.7,0.8,1.2
        c0.1,0.2,0.1,0.4,0.3,0.4c0,0.1,0,0.3,0,0.3c0.3,0.2,0.6,0.5,0.6,0.8l0.1,0.1l0,0.1c0.1,0,0.4,0,0.5,0l-0.1-0.2
        c0.4,0,0.6-0.4,0.9-0.6c-0.1-0.3-0.2-0.7-0.1-1c0,0-0.1-0.1-0.2-0.1c0,0.1-0.1,0.2-0.1,0.3c-0.2-0.2-0.4-0.5-0.5-0.7l0.2,0l-0.2-0.3
        c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1-0.2,0-0.5,0.1-0.7c-0.1,0-0.3-0.1-0.4-0.2c0.1-0.2,0.2-0.3,0.4-0.4c0,0-0.1-0.1-0.1-0.2
        c-0.1,0-0.2,0-0.2,0c0.3,0.2,0.2,0.4-0.1,0.5c-0.1-0.2-0.2-0.5-0.1-0.7c-0.1,0-0.2,0.1-0.3,0.1c0.1-0.3,0.2-0.6-0.2-0.7
        c0.1-0.2,0.2-0.3,0.3-0.5c-0.1,0-0.3,0.1-0.4,0.1c0-0.1,0-0.4,0-0.5c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0,0-0.3,0-0.5l-0.3,0.1l0.1-0.2
        c-0.3-0.1-0.4-0.3-0.4-0.5c0,0-0.1-0.1-0.2-0.1c-0.1-0.4-0.3-0.8-0.6-1.1C257.8,96.6,257.8,96.1,257.3,96 M122.1,96.2
        c0,0.2,0,0.3,0.2,0.2C122.3,96.2,122.3,96.1,122.1,96.2 M128.9,96.3c-0.1,0.2,0.2,0.3,0.3,0.2C129.3,96.3,129,96.1,128.9,96.3
        M204.6,96.5c0.2,0,0.2-0.1,0.2-0.3C204.6,96.2,204.6,96.3,204.6,96.5 M261.9,96.3c0,0.1,0,0.2,0.1,0.3c-0.2,0-0.4-0.1-0.5,0
        c0.1,0.2,0.7,0.4,0.2,0.6c0.5,0.2,0.7,0.6,0.9,1c0,0.4,0.5,0.4,0.6,0.7c0,0.2,0,0.5,0,0.7l0.3-0.1l-0.1,0.3c0.1,0,0.3-0.1,0.4-0.1
        c0,0.1-0.1,0.3-0.1,0.4l0.2-0.1l0,0.2c0.3-0.1,0.4,0.2,0.4,0.4c0.4,0.2,0.7,0.6,0.9,1c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.2,0-0.2
        c-0.5-0.3-0.9-0.7-1.4-1c-0.3-0.1-0.5-0.3-0.6-0.6c0,0.1-0.1,0.2-0.2,0.2c0-0.1,0-0.3-0.1-0.4c-0.1,0-0.2,0.1-0.2,0.1
        c-0.1-0.2-0.2-0.4-0.4-0.6c0,0.4,0.9,0.8,0.3,1l0,0.2c0.3-0.1,0.5-0.2,0.6,0.2l-0.2,0.1c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0,0.2,0,0.2,0
        c0,0.1,0,0.3-0.1,0.3l0.2,0c0,0.1,0.1,0.3,0.2,0.3c0,0.2,0,0.3,0.2,0.4c0,0.1-0.1,0.2-0.1,0.2c0.2,0,0.4,0,0.5,0
        c0.1-0.1,0.2-0.3,0.4-0.4c0.1-0.2,0.3-0.3,0.5-0.2l0,0.2l-0.3-0.1c0,0.1,0,0.2,0,0.2c-0.3,0.2-0.8,0.4-0.6,0.9
        c-0.3,0.1-0.2,0.3,0.1,0.3c0.2,0.2,0.3,0.7,0.6,0.6c0-0.7,0.2-1.5,0.4-2.2c0.1,0,0.2,0,0.3,0c-0.1,0-0.3,0-0.4,0
        c0.1-0.3,0.3-0.6,0.4-0.9c0.1-0.3,0.1-0.8,0.4-1c-0.1-0.3,0-0.5,0.1-0.7c-0.2-0.4,0.3-0.7,0.1-1.1c-0.2-0.3-0.5,0.1-0.8,0.1
        c0.3-0.5-0.5-0.4-0.7-0.6c0,0.1,0.1,0.3,0.1,0.4c0,0-0.1,0-0.1,0c0-0.1,0-0.3,0-0.4c-0.1,0.1-0.3,0.2-0.4,0.3c0-0.1,0.1-0.3,0.1-0.3
        c-0.3,0.1-0.5-0.1-0.7-0.2c-0.3-0.1-0.6-0.2-0.9-0.3c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1,0-0.2,0-0.2,0c0.1,0.1,0.2,0.3,0.3,0.3
        C262.3,96.8,262.2,96.5,261.9,96.3L261.9,96.3 M252.8,96.4c0,0.1,0,0.3-0.1,0.4c-0.2,0.1-0.3,0.2-0.3,0.4c0.3,0.1,0.6,0.3,0.9,0.5
        c0-0.1,0-0.4,0-0.6c0.1,0,0.3,0,0.4,0.1c-0.1-0.2-0.2-0.3-0.4-0.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2-0.3,0-0.4,0.3-0.4
        C253.2,96.5,253,96.2,252.8,96.4 M282,96.4C282.1,96.5,282.1,96.5,282,96.4 M206.3,97.3c0.2-0.2,0.4-0.5,0.4-0.8
        C206.4,96.7,206.3,97,206.3,97.3 M247.5,97.1c0.2,0.1,0.3,0,0.3-0.2C247.5,96.8,247.4,96.9,247.5,97.1 M326,96.7
        c-0.2,0.5,0.2,0.9,0,1.4c0.2,0.3,0.2,0.6,0,0.9c0.2,0.3,0.2,0.7,0.2,1c0,0.4,0,0.8,0,1.2l0.1-0.1c-0.1,0.3,0,0.5,0.3,0.7
        c-0.2-0.2-0.3-0.4-0.3-0.6h0.2c0-0.4,0.1-0.8,0.2-1.2c0.3,0.4,0.1,0.9,0.4,1.3c0-0.2,0-0.4,0-0.6c-0.1-0.2-0.1-0.4,0-0.6
        c-0.1,0-0.2-0.1-0.2-0.1c-0.1-0.9-0.4-1.7-0.4-2.6l0.2,0C326.5,97.2,326.3,96.9,326,96.7 M229.8,97.1c-0.1,0.2,0.1,0.5,0.3,0.4
        C230.2,97.3,230,97,229.8,97.1 M244.4,96.9c-0.1,0.3,0,1-0.5,1l0.1,0.1c-0.3,0.2-0.4,0.5-0.6,0.8l0.1,0c0-0.3,0.3-0.3,0.5-0.6
        c0.1-0.3,0.4-0.5,0.5-0.8C244.5,97.3,244.6,97.1,244.4,96.9 M285.1,97C284.9,97.4,285.6,97,285.1,97 M222.5,97.2
        C222.6,97.3,222.6,97.3,222.5,97.2 M252.3,97c0,0.2-0.1,0.3-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.4l-0.2-0.1c0,0.2,0.1,0.6-0.3,0.7l0-0.3
        c0,0-0.1,0-0.1,0c0,0.1,0,0.3,0,0.4l-0.3,0c0.1,0,0.3,0.1,0.3,0.1c-0.3,0.2-0.4,0.5-0.6,0.8c0,0-0.1,0-0.2,0
        c-0.1,0.3-0.2,0.7-0.6,0.8l0.1,0.2l-0.2-0.1c0,0.1,0,0.3,0,0.3c-0.2,0.1-0.3,0.2-0.3,0.3c-0.1,0.3-0.5,0.3-0.3,0.6
        c-0.2,0-0.3,0.1-0.4,0.2c0.1,0,0.2,0,0.3,0l0,0c-0.3,0-0.2,0.3-0.2,0.4l-0.1-0.1l-0.1-0.1c-0.1,0.1-0.2,0.2-0.3,0.3l0.1,0.1
        c-0.1,0.1-0.5,0.1-0.5,0.3c0.1,0.3-0.1,0.4-0.3,0.5c-0.3,0.1-0.4,0.4-0.5,0.7c-0.2,0.1-0.4,0.2-0.5,0.4c-0.2,0.3-0.4,0.6-0.8,0.7
        c0,0.1,0,0.2,0,0.3c-0.1,0.1-0.3,0.1-0.4,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0,0.2l-0.3,0.1c0.1,0.1,0.1,0.2,0.2,0.3
        c-0.1,0-0.3-0.1-0.4-0.1c-0.2,0.2-0.4,0.5-0.5,0.8c0.3,0.2,0.6,0.5,0.9,0.7c-0.5-0.1-0.9-0.4-1.3-0.5c-0.3,0.4,0.2,0.7,0.2,1.1
        c0.1,0,0.2,0,0.2-0.1c0,0.2,0,0.4,0.2,0.6c0,0.1,0,0.2,0,0.3c0.4-0.1,0.4-0.6,0.7-0.8c0.4-0.3,0.6-0.8,0.9-1.2
        c0.2-0.2,0.5-0.3,0.6-0.7c0.6-0.6,0.9-1.4,1.3-2c0.5-0.6,0.9-1.1,1.3-1.7c0.2-0.4,0.6-0.6,0.8-1c0.3-0.3,0.4-0.7,0.8-0.9
        c0.3-0.4,0.4-0.8,0.7-1.1c0.5-0.5,0.9-1.1,1.2-1.8c0.2-0.2,0.9-0.6,0.4-0.9c0,0.3-0.1,0.4-0.3,0.4c0,0.1,0,0.2,0,0.3
        c-0.2-0.1-0.2-0.3-0.2-0.4l0.3,0C252.9,97.5,252.6,97.2,252.3,97 M265.2,97.3C265.3,97.4,265.3,97.4,265.2,97.3 M299.9,97.3
        C300,97.4,300,97.4,299.9,97.3 M306.8,97.3C306.8,97.4,306.8,97.4,306.8,97.3 M133.4,97.7c0.2,0,0.4,0,0.5,0l0-0.1
        C133.9,97.3,133.4,97.4,133.4,97.7 M195.5,97.4c0.1,0.2,0.3,0.5,0.4,0.7c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0.1,0,0.1,0.1
        c0-0.2,0.1-0.4,0.2-0.5C196.1,97.8,195.7,97.6,195.5,97.4 M205.9,97.9c-0.2,0.1-0.3,0.2-0.4,0.4c0.1,0,0.2,0,0.3,0l0,0.2
        c-0.1,0-0.3,0-0.4,0.1c-0.2,0.2-0.4,0.5-0.5,0.8c-0.1,0-0.2-0.1-0.3-0.2c0.1,0.2,0.2,0.5,0.1,0.7c-0.1,0-0.2,0-0.3,0
        c0,0.1,0.1,0.2,0.1,0.3c-0.2,0-0.4,0.1-0.4,0.4c0.3-0.2,1-0.3,0.8-0.8c0.4-0.1,0.5,0.2,0.4,0.5c0.1-0.2,0.1-0.4,0.2-0.5
        c-0.1,0-0.4,0-0.6,0c0.1-0.2,0.3-0.3,0.5-0.3c0-0.1-0.1-0.2-0.1-0.2c0.2-0.2,0.5-0.4,0.7-0.6c0-0.1-0.1-0.2-0.1-0.3
        c0.2-0.2,0.5-0.4,0.5-0.7C206.1,97.5,205.9,97.6,205.9,97.9 M299.2,97.5c-0.1,0.2-0.1,0.3,0.1,0.3C299.4,97.6,299.4,97.5,299.2,97.5
        M380.7,97.6c0.1,0.2,0.4,0.1,0.5,0.2C381.6,97.5,380.8,97.3,380.7,97.6 M123.6,97.6C123.7,97.7,123.7,97.7,123.6,97.6 M193.5,97.6
        c0,0.2,0,0.5,0,0.7c0.2,0.2,0.3,0.3,0.5,0.5c0,0.2-0.1,0.3-0.1,0.4l0.3-0.1l0,0.3c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.1,0.2-0.1,0.3-0.2
        c-0.1,0-0.3,0-0.4,0C194,98.3,193.8,97.9,193.5,97.6 M218.1,98.2l0.1,0.2l-0.2,0c0.1,0.3,0,0.4-0.2,0.4c-0.1,0.1-0.1,0.3-0.2,0.4
        c0,0.1,0,0.2,0,0.3c0.2,0,0.3,0.1,0.5,0.1l-0.2-0.1c0.2-0.6,0.6-0.9,0.9-1.5c0.1-0.1,0.2-0.3,0.3-0.5
        C218.8,97.7,218.6,98.2,218.1,98.2 M229.7,97.6c0,0.1,0.1,0.2,0.1,0.3c0.2,0,0.4,0.1,0.6,0.2c-0.1-0.2-0.2-0.3-0.3-0.5
        C229.9,97.6,229.8,97.6,229.7,97.6 M391.1,97.6C391.2,97.7,391.2,97.7,391.1,97.6 M143.4,97.7c0,0.2,0.4,0.3,0.5,0.1
        C143.8,97.6,143.5,97.6,143.4,97.7 M297.2,97.8C297,98.2,297.7,97.8,297.2,97.8 M312.8,97.7c-0.2,0.4-0.6,0.6-0.7,1.1
        c0,0-0.1,0-0.2,0c0,0.1,0,0.2,0,0.3c0.3,0,0.2,0.3,0.2,0.5c0.1,0,0.2,0.1,0.2,0.1l-0.2,0.1c0-0.2-0.1-0.3-0.1-0.5
        c-0.1,0-0.2,0-0.3,0c0.1,0.2,0.1,0.4-0.2,0.4c0,0.3-0.2,0.6-0.5,0.9l0.3,0c-0.2,0.2-0.6,0.4-0.7,0.7c-0.3-0.1-0.3,0.2-0.3,0.3
        c0.1,0,0.3-0.1,0.4-0.1c-0.2,0.1-0.5,0.1-0.5,0.5c-0.1,0-0.2,0-0.3,0c0,0.1,0,0.4,0.1,0.6c0.3,0.2,0.5,0.3,0.5,0.7
        c0.1-0.2,0.2-0.5,0.3-0.7c0.5-0.7,0.8-1.5,1.4-2.2c0-0.1-0.1-0.3-0.1-0.4c0.1,0,0.3,0,0.4,0.1c0-0.3,0.1-0.6,0.2-0.9
        c0.1-0.1,0.3-0.2,0.4-0.4c-0.3,0.1-0.5,0.4-0.8,0.3c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.2,0.4-0.3,0.6-0.5
        C312.9,97.9,312.9,97.8,312.8,97.7 M317.1,97.6c0.1,0.3,0.3,0.4,0.4,0.7l0.1,0c0,0.1,0,0.2,0,0.2c0.3,0.2,0.6,0.5,0.8,0.8
        c-0.3,0-0.6,0-0.7-0.3l-0.3,0.1l0.1-0.3c-0.1,0.1-0.2,0.2-0.3,0.4c0.2,0,0.4,0,0.5,0.2c0.1,0.3,0.4,0,0.6,0.1
        c-0.1,0.1-0.2,0.3-0.2,0.5c0,0,0.1,0,0.1,0c0-0.2,0.1-0.3,0.1-0.5c0.6,0.3,0.4-0.6,0.7-0.9c0.1-0.2,0.5-0.7-0.1-0.7l0.1,0.3
        C318.4,98,317.7,97.8,317.1,97.6 M370.9,97.7c-0.2,0.3,0.4,0.2,0.5,0C371.4,97.5,371,97.6,370.9,97.7 M135,97.8c0,0.2,0,0.3,0.3,0.2
        C135.3,97.9,135.2,97.8,135,97.8 M379.5,97.9c-0.1,0.2,0,0.2,0.2,0.2C379.8,97.8,379.7,97.8,379.5,97.9 M133.7,98
        C133.5,98.4,134.1,98,133.7,98 M137.8,98c-0.1,0.2,0.1,0.4,0.3,0.4C138.5,98.4,138,97.8,137.8,98 M208.5,98.4
        c0.2,0.1,0.4-0.2,0.2-0.3C208.5,97.9,208.3,98.3,208.5,98.4 M246.8,98C246.9,98.1,246.9,98.1,246.8,98 M299.1,98
        C299.1,98.1,299.1,98.1,299.1,98 M376.7,97.9c-0.1,0.1-0.4,0.3-0.3,0.4C376.7,98.6,377.2,97.8,376.7,97.9 M381,98
        C380.8,98.4,381.4,98,381,98 M196.6,98.2c-0.1,0.3-0.1,0.6-0.2,0.9c0.2-0.3,0.3-0.6,0.5-0.9C196.8,98.2,196.7,98.2,196.6,98.2
        M229.6,98.2c0,0.1-0.1,0.3-0.1,0.4c-0.1,0-0.2,0-0.3-0.1c0,0.1,0,0.2,0,0.2c0.2,0,0.5-0.3,0.6,0c-0.3,0.1-0.2,0.5-0.2,0.7
        c0,0,0.1,0,0.1,0c0-0.1,0-0.2,0-0.3c0.2-0.2,0.3,0.1,0.5,0.1c0-0.1,0-0.3-0.1-0.4l0.2,0C230.4,98.5,229.9,98.1,229.6,98.2
        M124.5,98.5c0.1,0.1,0.3,0.1,0.4,0.2c0-0.1,0-0.3,0-0.4C124.8,98.3,124.6,98.4,124.5,98.5 M133.3,98.3
        C133.3,98.8,133.7,98,133.3,98.3 M139.7,98.5c0.1,0.2,0.2,0.2,0.4,0C140,98.4,139.8,98.4,139.7,98.5 M141.2,98.4
        c-0.1,0.2,0,0.3,0.2,0.2C141.5,98.4,141.4,98.4,141.2,98.4 M234.4,98.4c-0.1,0.2,0,0.3,0.2,0.2C234.6,98.5,234.5,98.4,234.4,98.4
        M373.3,98.4c-0.1,0.2,0,0.3,0.2,0.2C373.6,98.4,373.5,98.4,373.3,98.4 M374.7,98.6c0.1,0.2,0.2,0.2,0.4,0
        C374.9,98.4,374.8,98.4,374.7,98.6 M197.1,99.7c0.4-0.1,0.4-0.5,0.5-0.8c0-0.1,0.1-0.3,0.1-0.4C197.4,98.7,197.2,99.3,197.1,99.7
        M138,98.7C137.7,99.1,138.4,98.8,138,98.7 M159.3,98.8c0,0.2,0,0.4,0,0.6c0.2-0.1,0.3-0.2,0.4-0.4
        C159.6,98.9,159.4,98.8,159.3,98.8 M262.8,98.9C262.9,98.9,262.9,98.9,262.8,98.9 M163.3,99c0,0.2,0.1,0.3,0.3,0.2
        C163.6,99,163.5,99,163.3,99 M194.7,99.6c0.4-0.1,0.8,0.3,0.9,0.7l0.1,0.2c0,0-0.1,0-0.2,0c-0.2-0.2-0.2-0.6-0.6-0.6
        c0.1,0.2,0.5,0.4,0.2,0.7l0.3,0c-0.1,0.2,0,0.4,0.3,0.5c0,0,0-0.1,0-0.1c0,0-0.1-0.1-0.2-0.1l0-0.1c0.1-0.1,0.2-0.1,0.4-0.2
        c0.1-0.1,0.2-0.2,0.2-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.5-0.1-0.8-0.5-1.1-0.8l0,0.2l0,0c0-0.1,0-0.4-0.1-0.5
        C194.4,99.1,194.3,99.5,194.7,99.6 M243.1,99.1c-0.1,0.1-0.3,0.3-0.2,0.4C243.2,99.6,243.7,98.8,243.1,99.1 M351.2,99.3
        c0.2,0.1,0.4-0.2,0.3-0.3C351.3,98.9,351,99.2,351.2,99.3 M158.7,99.3c-0.1,0.2,0.2,0.3,0.3,0.2c0-0.1,0-0.2,0-0.3
        C159,99.2,158.8,99.2,158.7,99.3 M246.1,99.2C246.2,99.2,246.2,99.2,246.1,99.2 M309.7,100.5c-0.1,0.2-0.2,0.5-0.3,0.7
        c0,0,0.1,0.1,0.2,0.1c0.3-0.4,0.5-0.9,0.7-1.4c0.2-0.3,0.4-0.5,0.6-0.7C310.3,99.6,309.9,100,309.7,100.5 M355.7,99.3
        c-0.1,0.2,0.2,0.3,0.3,0.2C356.2,99.3,355.8,99.1,355.7,99.3 M150,99.9c-0.4-0.5-0.9,0.4-1.2-0.1c0,0.1,0,0.3-0.1,0.4
        c-0.1,0-0.2-0.1-0.2-0.2c-0.4,0.2-1,0-1.3,0.3c0,0.3-0.3,0.1-0.4,0.1c-0.1,0-0.3,0-0.4,0c0.1,0.2,0.2,0.3,0.4,0.5l0-0.3
        c0.1,0,0.2,0.1,0.3,0.2c0.1-0.1,0.3-0.3,0.4-0.5l0.2,0c0,0.1,0,0.2,0,0.3l0.1,0c0-0.1,0-0.3,0-0.4c0.2,0.1,0.3,0.3,0.3,0.6l-0.2,0.1
        c0.3,0.1,0.4-0.5,0.8-0.3c-0.1,0-0.3-0.1-0.4-0.2c0,0,0.1-0.1,0.1-0.2c0.4-0.1,0.7,0.5,1.1,0.3c-0.4,0.1-0.4-0.7,0-0.4
        c0.2-0.3,0.9-0.3,0.7,0.2C150.2,100.4,150.1,100.1,150,99.9c0.2,0,0.5,0,0.6,0c0,0.2,0.2,0.3,0.4,0.4c-0.1-0.3,0-0.6,0.2-0.9
        c-0.2,0.1-0.8,0.5-0.8,0C150.1,99.6,149.9,99.6,150,99.9 M154.5,99.4L154.5,99.4L154.5,99.4C154.6,99.5,154.5,99.5,154.5,99.4
        M257.7,99.4C257.5,99.9,258.2,99.4,257.7,99.4 M154.2,99.6c0,0.2,0,0.3,0.2,0.2C154.5,99.6,154.4,99.5,154.2,99.6 M198.7,99.6
        C198.8,99.7,198.8,99.7,198.7,99.6 M293.8,99.6c-0.1,0.2,0,0.3,0.2,0.2C294.1,99.6,294,99.5,293.8,99.6 M302.3,99.4
        c0,0.4,0,0.7,0,1.1c0.3,0.4,0.1,0.9,0.1,1.3c0-0.1,0.1-0.3,0.1-0.4c0.1-0.3,0.2-0.7,0.3-1C302.7,100,302.5,99.7,302.3,99.4
        M361.2,99.7c-0.2,0.2,0.2,0.4,0.3,0.2C361.7,99.7,361.3,99.5,361.2,99.7 M286.7,100C286.8,100.1,286.8,100.1,286.7,100 M362.5,100
        C362.5,100.4,362.9,99.7,362.5,100 M366.7,100.6c-0.1,0-0.2,0-0.3,0c0.2,0.4,0.5-0.1,0.6-0.3l0.2,0c0,0.1,0,0.2-0.1,0.3l0.1,0
        c0-0.1,0-0.2,0-0.3l0.1,0c0.1,0.2,0.3,0.3,0.4,0.5c0.1-0.1,0.1-0.1,0.2-0.2l0,0.3c0.1-0.2,0.3-0.3,0.4-0.5c-0.1,0-0.4,0-0.5,0
        c-0.1,0-0.2,0.1-0.3,0.1l0-0.2C367.4,100.1,366.7,100,366.7,100.6 M200.9,100.2c0.1,0.3,0.7,0.3,0.5,0.8c0.1,0,0.2,0,0.3-0.1l0,0.2
        c0.3-0.1,0.8-0.1,0.8-0.5c0.2,0.1,0.3,0.2,0.5,0.3c-0.3-0.4-0.8-0.4-1.2-0.4l0.1-0.2C201.5,100.2,201.2,100.2,200.9,100.2
        M214.7,100.3C214.8,100.4,214.8,100.4,214.7,100.3 M275.3,100.3C275.4,100.4,275.4,100.4,275.3,100.3 M298.8,100.5
        c0.1,0,0.2,0,0.3-0.1C299.1,100.2,298.7,100.3,298.8,100.5 M253.3,100.4C253.4,100.5,253.4,100.5,253.3,100.4 M272.4,100.4
        C272.4,100.5,272.4,100.5,272.4,100.4 M145,100.9c0.3,0.2,0.5,0.4,0.8,0.5c-0.1-0.2-0.3-0.4-0.5-0.7c0.2,0.1,0.4,0.2,0.7,0.3
        c0.1-0.2,0.1-0.3,0.2-0.5C145.8,100.6,145.3,100.5,145,100.9 M368.5,100.6c0.1,0.2,0.1,0.3,0.2,0.5c0.2-0.1,0.4-0.2,0.7-0.3
        c-0.2,0.2-0.3,0.4-0.5,0.6c0.3-0.2,0.5-0.4,0.8-0.5C369.4,100.6,369,100.6,368.5,100.6 M271.1,100.7c0.2,0.3,0.2,0.7,0.6,0.7
        c0.2,0.1,0,0.3-0.1,0.3c0.3,0.1,0.5,0.2,0.7,0.3c0,0.1,0,0.3,0,0.3c0,0,0.1,0,0.2,0c0.1,0.1,0.2,0.2,0.2,0.2c0,0.1,0,0.2-0.1,0.3
        c0.1-0.1,0.3-0.3,0.4-0.4c0-0.2-0.2-0.7-0.2-0.2l-0.3,0l0-0.2c-0.2-0.2-0.3-0.4-0.6-0.3c-0.1-0.2-0.1-0.3-0.1-0.5
        C271.4,101.3,271.2,101,271.1,100.7 M272.2,100.7c0.1,0.2,0.3,0.3,0.4,0.5c0.1,0,0.2,0,0.3,0c0.1,0.2,0.2,0.4,0.3,0.6
        c0.3-0.2,0.6-1-0.1-0.8C273.1,100.3,272.5,101,272.2,100.7 M275,100.6c0,0.1,0.1,0.3,0.2,0.4c-0.1,0-0.3,0.1-0.4,0.1
        c0,0.2,0,0.4,0,0.7c-0.1,0-0.3-0.1-0.4-0.2c-0.2,0.3-0.1,0.8-0.6,0.8c0.1,0.1,0.2,0.2,0.3,0.4c-0.1,0-0.3,0-0.5,0
        c-0.2,0.2-0.4,0.5-0.5,0.8c0.3,0.1,0.4,0.4,0.5,0.6l0.1-0.1c0.1-0.6,0.5-1.2,0.8-1.8c0.1-0.3,0.3-0.5,0.2-0.9
        c0.6,0.4,0.2-0.6,0.5-0.8L275,100.6 M298.8,101.2c-0.1,0-0.3,0-0.5,0c0.1,0.5,0.9,0.3,0.6-0.2c0.1,0,0.2,0,0.3,0
        c0-0.1-0.1-0.3-0.1-0.4C298.8,100.6,298.3,101,298.8,101.2 M130.1,100.9C130.2,100.9,130.2,100.9,130.1,100.9 M302.9,100.6
        c0,0.4,0,0.8,0,1.3c0,0,0.1,0.1,0.2,0.2c0,0.3,0,0.7,0,1l0,0c0.1-0.1,0.2-0.2,0.3-0.2c0,0.3-0.3,0.9,0.3,0.8c0,0.1,0,0.2,0,0.2
        c0.1,0.1,0.2,0.2,0.3,0.2l-0.4-0.1c0.2,0.3,0.4,0.7,0.6,1c0,0.1-0.1,0.2-0.1,0.2c0.1,0,0.2,0,0.2,0.1c-0.1,0.1-0.2,0.2-0.3,0.3
        c0.2,0.1,0.4,0,0.5-0.2c0.1,0.2,0.1,0.5,0.2,0.7c0,0,0.1,0,0.2,0c0,0.1-0.1,0.2-0.1,0.3l0.2,0c0.1,0,0.3,0.1,0.3,0.2
        c0,0,0,0.1,0,0.1l-0.4,0c0.1,0.2,0.5,0.3,0.5,0.6c0.1,0,0.2,0,0.3,0c0,0.1-0.1,0.2-0.2,0.3c0.1,0,0.2,0,0.3,0.1
        c0,0.1-0.1,0.3-0.1,0.4c0.1,0,0.3-0.1,0.3-0.1c0,0.1,0,0.3,0.1,0.5c0.1-0.1,0.2-0.2,0.3-0.4c0,0.2,0,0.3,0,0.5
        c0.1,0.2,0.3,0.4,0.3,0.6l0.3,0c0,0.1-0.1,0.2-0.2,0.3l0.2,0l-0.1,0.2l0.2-0.1c0,0.1-0.1,0.3-0.1,0.4c0-0.1,0.1-0.2,0.1-0.2
        c0.1,0,0.2,0,0.3,0c0.3-0.3,0.5-0.7,0.7-1c0-0.2,0-0.3,0.1-0.5c-0.3-0.3-0.5-0.6-0.9-0.7c-0.1-0.2-0.2-0.4-0.4-0.7
        c-0.5,0.1-0.7-0.5-1.1-0.8c-0.5-0.4-0.8-1-1.1-1.5c-0.2-0.1-0.4-0.2-0.4-0.4c-0.3-0.7-0.7-1.3-0.7-2
        C303.5,101.6,303.4,100.9,302.9,100.6 M366.9,100.8C366.9,101.3,367.3,100.6,366.9,100.8 M384.5,100.9
        C384.6,100.9,384.6,100.9,384.5,100.9 M146.2,101.1c0.1,0.2,0.2,0.2,0.3,0C146.4,100.9,146.3,100.9,146.2,101.1 M240.1,101
        c0,0.1,0,0.4,0.1,0.6l-0.2-0.1c0,0.1,0.1,0.2,0.1,0.3l-0.2,0c0.2,0.4-0.2,0.5-0.5,0.6c0.1,0,0.3,0,0.4,0c-0.2,0.3-0.3,0.5-0.3,0.9
        c-0.1,0.1-0.2,0.2-0.3,0.3c0,0.2-0.1,0.5-0.1,0.6c-0.1-0.1-0.2-0.2-0.2-0.3l0.1,0.2c-0.1,0-0.3,0-0.4,0c0.3,0.2,0.2,0.6,0.4,0.9
        c0.1-0.2,0.1-0.5,0.3-0.7c0.1-0.3,0.3-0.6,0.3-0.9c0.4-0.5,0.5-1.1,0.6-1.7l0.2,0.1C240.3,101.4,240.2,101.1,240.1,101 M368.2,101.1
        c0.1,0.2,0.2,0.2,0.3,0C368.4,100.9,368.3,100.9,368.2,101.1 M197.7,101.2c-0.1,0.2-0.1,0.4-0.2,0.6l-0.1,0c-0.1,0.3,0,0.5,0.2,0.6
        c0,0,0,0.1,0,0.1l0.1,0c0.1,0.1,0.4,0.7,0.4,0.1c0.1,0.1,0.3,0.2,0.4,0.3l-0.1-0.3c0.2,0.1,0.4,0.1,0.5-0.1c-0.1,0-0.3,0-0.4,0.1
        c0.1-0.1,0.2-0.3,0.3-0.4c-0.1,0-0.3,0.1-0.4,0.1l0-0.3c-0.1,0.2-0.3,0.3-0.4,0.5c-0.1-0.3,0.2-0.5,0.3-0.6
        c-0.2-0.1-0.5-0.3-0.2-0.6c0.1,0,0.3,0.1,0.3,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0,0.3,0.1,0.4,0.2c0.1,0,0.3,0,0.4,0
        c-0.1-0.2-0.3-0.4-0.2-0.6c-0.2,0-0.4-0.1-0.5-0.2C198,101.1,197.8,101.2,197.7,101.2 M200.5,101.1c0.1,0.2,0.1,0.6,0.4,0.6
        C200.9,101.4,200.8,101.2,200.5,101.1 M216,101.6c0.3-0.3,0.5-0.1,0.6,0.2c-0.1,0-0.3,0-0.4,0c0.2,0.3,0.4,0.6,0.3,0.9
        c0,0,0.1,0,0.1,0c0.1-0.4,0.3-0.9,0.4-1.3C216.7,101,215.9,101,216,101.6 M252.8,101.2c-0.1,0.3-0.3,0.6-0.5,0.8
        c-0.1,0-0.2-0.1-0.3-0.2c0,0.1,0.1,0.3,0.1,0.4c0.1-0.1,0.2-0.1,0.3-0.2l0.2,0.1c0-0.1,0-0.2,0-0.3c0.2,0.2,0.4,0.1,0.4-0.2
        c0.2-0.3,0.3,0.3,0.2,0.5c0.1-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.2,0.1-0.3c-0.1,0-0.3,0-0.4,0c0-0.1,0.1-0.2,0.1-0.3
        C253,101.1,252.8,101.1,252.8,101.2 M283.9,101.2C284,101.3,284,101.3,283.9,101.2 M347.3,101.2c-0.2,0.1,0,0.5,0.2,0.3
        C347.6,101.4,347.4,101.1,347.3,101.2 M144.2,101.2c-0.1,0.2,0.2,0.5,0.4,0.4C144.7,101.4,144.4,101.1,144.2,101.2 M167.3,101.3
        c-0.1,0.2,0,0.3,0.2,0.2C167.6,101.3,167.5,101.2,167.3,101.3 M190.9,101.3C190.9,101.4,190.9,101.4,190.9,101.3 M211.4,101.3
        c0,0.1,0,0.3,0,0.4c0.1,0.1,0.2,0.2,0.2,0.3C211.6,101.7,211.5,101.5,211.4,101.3 M225.6,102.1c-0.3,0.1-0.3,0.6-0.6,0.4
        c-0.1,0.2,0,0.5-0.3,0.7c-0.2,0.2-0.7,0.5-0.3,0.7c0,0.1,0,0.2-0.1,0.4c0.1,0,0.2,0,0.3,0c0.4-1.1,1.1-2,1.5-3
        c-0.1,0-0.2-0.1-0.3-0.2C225.8,101.4,225.7,101.7,225.6,102.1 M252.3,101.3c-0.1,0.1-0.3,0.2-0.3,0.4
        C252.2,101.8,252.7,101.3,252.3,101.3 M261,101.3C261,101.4,261,101.4,261,101.3 M315,101.3c0.2,0.4,0.5,0.7,0.7,1.1
        c0,0,0.1,0,0.2,0C315.5,102.1,315.6,101.3,315,101.3 M284,101.4c0,0.2,0,0.3,0.2,0.2C284.3,101.4,284.2,101.3,284,101.4
        M143.9,101.5c-0.2,0-0.3,0.3-0.1,0.4C144,101.9,144.2,101.6,143.9,101.5 M194.5,101.8c0.2,0.1,0.6,0.2,0.7,0.5
        c-0.1,0.1-0.2,0.2-0.4,0.3c0.1-0.1,0.4-0.2,0.6-0.3c0,0.1-0.1,0.3-0.2,0.4c0.1,0,0.2-0.1,0.3-0.1c0-0.2,0.2-0.4,0.1-0.6
        c-0.2,0-0.3,0.1-0.4,0.3c0-0.4-0.4-0.6-0.5-0.8C194.6,101.6,194.6,101.7,194.5,101.8 M370.7,101.6c0,0.2,0,0.3,0.2,0.2
        C371,101.6,370.9,101.5,370.7,101.6 M217.6,101.7c0,0.1,0.1,0.4,0.1,0.5c-0.2-0.5-0.4,0.1-0.6,0.3c-0.1,0.3,0.1,0.6,0.2,0.9
        c-0.3,0.1-0.5-0.3-0.7-0.5c0.2,0.3,0.4,0.5,0.3,0.9c0.1,0,0.2,0.1,0.3,0.1c-0.3-0.2,0.1-0.3,0.2-0.5c0.2-0.6,0.5-1.1,0.9-1.6
        C218.1,101.9,217.8,101.8,217.6,101.7 M270.2,101.9c0.2,0.6,0,1.3,0.2,1.9l0,0c0.2,0.1,0.4,0.3,0.6,0.3c0.1,0.2,0.1,0.4,0.1,0.6
        c0.2,0,0.4,0,0.6,0c0-0.1,0.1-0.3,0.1-0.4c-0.2-0.4-0.6-0.7-0.8-1.1C270.8,102.8,270.5,102.3,270.2,101.9L270.2,101.9 M106.4,102.3
        c-0.2-0.1-0.4-0.2-0.6-0.1c-0.5,0.2-1-0.1-1.4,0.1c-0.5,0.2-1,0.3-1.4,0.4c-0.4-0.1-0.8-0.3-1.1-0.1c-0.6,0.1-0.9,0.5-1.4,0.8
        c-0.2-0.1-0.3-0.4-0.6-0.3c-0.5,0.3-1,0.8-1.6,0.4c-0.3,0.1-0.6,0.3-0.8,0.5c-0.2-0.2-0.4-0.2-0.6-0.1l0,0.2c-0.2,0-0.5,0-0.7-0.1
        c0,0,0,0.1,0,0.2c0.1,0,0.4,0,0.5,0c-0.1,0.3-0.6,0.3-0.8,0.5c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0-0.2,0-0.3,0c0.1,0.1,0.2,0.3,0.2,0.4
        c-0.2,0.1-0.3,0.1-0.5,0.2c-0.2-0.1-0.4-0.1-0.6-0.1c0,0.1,0,0.2,0,0.3c-0.3,0.2-0.7,0.3-1,0.4c-0.5,0-0.9,0.3-1.3,0.3
        c-0.2,0.3-0.6,0.3-0.9,0.5c-0.3,0.1-0.5,0.2-0.8,0.2c-0.2,0.3-0.5,0.3-0.8,0.4c-0.3,0.3-0.6,0.4-1,0.4c-0.1,0.1-0.2,0.2-0.3,0.3
        c-0.2,0.2-0.6,0.1-0.7,0.4c-0.4,0.3-0.9,0.3-1.3,0.6c-0.1,0.4-0.6,0.4-0.9,0.5c-0.2,0.2-0.3,0.6-0.7,0.5c-0.6,0.6-1.4,0.8-1.9,1.4
        c-0.4,0.4-1,0.5-1.4,0.9c-0.2,0.2-0.5,0.2-0.7,0.2c-0.1,0.2-0.1,0.4-0.3,0.5c-0.5,0.3-0.9,0.6-1.3,1c-0.1,0-0.2,0.1-0.3,0.1
        c-0.7,0.6-1.5,1.3-2.3,1.9c-0.3,0.2-0.4,0.7-0.8,0.8c-0.5,0.2-0.7,0.8-1.1,1.1c-0.4,0.6-1.1,0.8-1.4,1.5c-0.5,0.3-0.9,0.8-1.3,1.2
        c-0.2,0.3-0.4,0.6-0.6,0.8c-0.1,0.2-0.3,0.4-0.5,0.5c-0.1,0.2-0.1,0.4-0.3,0.6c-0.3,0.2-0.4,0.6-0.7,0.8c-0.2,0.2-0.5,0.5-0.6,0.8
        c-0.2,0.3-0.8,0.6-0.6,1c-0.1,0-0.3-0.1-0.4-0.1c-0.2,0.2-0.1,0.4,0.1,0.6c-0.3,0.1-0.5,0.2-0.5,0.5c-0.1,0-0.3,0-0.4,0
        c0.1,0,0.3,0,0.3,0.1c-0.1,0.3-0.2,0.5-0.2,0.8c-0.1,0-0.2,0-0.3,0c0.2,0.3,0.1,0.9-0.4,0.9c0,0.1,0.1,0.2,0.1,0.3
        c-0.1,0-0.3,0-0.4,0c0,0.2,0,0.5-0.1,0.7c-0.2,0.2-0.4,0.3-0.3,0.6c-0.3,0.1-0.3,0.3-0.3,0.6c-0.3,0.3-0.2,0.9-0.6,0.9
        c0.4,0.3,0,0.7-0.2,1c0.3,0.5-0.4,0.8-0.3,1.3c-0.1,0-0.2,0-0.2,0c0,0.4-0.1,0.7-0.1,1.1c-0.3,0.1,0,0.5-0.3,0.6
        c0.1,0.2,0,0.3-0.1,0.5c0.1,0.4-0.1,0.7-0.3,1c-0.2,0.5,0,1-0.3,1.5c-0.2,0.5-0.1,1-0.3,1.4c0.1,0.6-0.2,1.1-0.3,1.7
        c0,0.3,0.1,0.6-0.1,0.9c0.1,0.3,0.3,0.7,0.1,1.1c-0.1,0.2,0,0.4,0.1,0.6c-0.1,0.3-0.2,0.6-0.2,0.9c0.1,0.2,0.1,0.3,0,0.5
        c-0.1,0.4,0,1-0.4,1.3c0.1,0,0.3,0.1,0.3,0.2c0,0.3-0.2,0.6-0.2,0.9c0.1,0.7,0,1.4,0,2.1c0.1,0.3,0.1,0.7,0,1
        c0.1,0.1,0.2,0.2,0.2,0.3c-0.2,0.3,0,0.7,0,1c0,0.2,0.1,0.4,0.1,0.5c0.1-0.1,0.2-0.2,0.3-0.3c-0.1,0.2-0.1,0.4-0.1,0.7l-0.1,0
        c0,0.2-0.2,0.7,0.2,0.3c-0.2,0.5-0.2,1.1,0.2,1.5c0-0.1,0-0.4,0-0.5c0.1,0.1,0.2,0.3,0.4,0.4l-0.2,0c0.1,0.1,0.1,0.3,0.2,0.5
        c0,0-0.1,0.1-0.2,0.2l0.1,0.3c0.2-0.1,0.3-0.1,0.5-0.2c-0.1,0.2-0.3,0.3-0.4,0.5c0.2,0.1,0.7,0.3,0.4,0.5c-0.1-0.1-0.3-0.2-0.3-0.3
        c-0.1,0.4,0.3,0.5,0.6,0.5c0,0.1-0.1,0.3-0.2,0.4c0.3-0.5,0.7-0.1,1.1,0c0-0.1-0.1-0.4-0.2-0.5c0.1,0.1,0.1,0.3,0.2,0.4
        c0.3,0.1,0.6,0.1,0.8,0.2c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.4,0.2,0.5,0.3c0-0.1,0-0.4,0-0.6c0.1,0.1,0.2,0.2,0.3,0.3
        c0.1-0.1,0.5-0.4,0.4,0.1c0.4,0.1,0.8,0.2,1.2,0c0.1,0.1,0.2,0.2,0.2,0.2l0-0.2c0.1,0,0.3,0,0.4,0l0-0.2c0.2,0.1,0.4,0.1,0.5-0.1
        l0.2,0c-0.1,0.4,0.2,0.3,0.5,0.4c0.2,0,0.6-0.1,0.7,0.2l0.2-0.1c-0.3-0.1-0.7-0.8-0.9-0.4l0,0c0-0.1,0.1-0.2,0.1-0.3
        c0.3,0.1,0.5,0.3,0.8,0.2c0.2,0.2,0.3,0.3,0.5,0.5c0-0.1,0.1-0.2,0.1-0.3c0.1,0.4,0.7-0.1,0.5,0.4c0.1-0.1,0.2-0.2,0.4-0.2
        c0.1,0.1,0.3,0.2,0.4,0.3c0-0.1,0.1-0.2,0.1-0.3l0.2-0.1c0,0.1,0,0.3,0,0.4c0-0.1,0.1-0.2,0.2-0.3l0.2,0.2c0.1-0.1,0.2-0.2,0.3-0.4
        c0,0.1-0.1,0.3-0.2,0.4c0.4,0.1,0.7-0.2,0.9-0.5c-0.1,0.2-0.2,0.3-0.4,0.5l0.4,0.1l0-0.3c0.5,0.1,0,0.4,0,0.7
        c0.2-0.1,0.4-0.4,0.7-0.3c0.1,0,0.2-0.1,0.2-0.2c0,0.1-0.1,0.3-0.1,0.4c0.1-0.1,0.4-0.2,0.5-0.2c0.1,0.4,0.4,0.4,0.6,0.2
        c0,0.1,0,0.4,0,0.5c0.1-0.1,0.3-0.1,0.4-0.2l-0.1-0.2c0.1,0,0.3,0,0.4,0c0,0.2-0.1,0.3-0.3,0.4c0.2,0.4,0.5-0.4,0.8-0.3
        c0,0.1-0.1,0.3-0.2,0.4c0.1,0,0.3,0,0.4,0c0-0.1,0-0.3,0.1-0.4c0,0.1,0,0.4,0,0.5c0.2-0.1,0.4-0.2,0.6-0.3c0,0,0.1,0.1,0.2,0.1
        c-0.1,0-0.4,0.1-0.5,0.2c0.2,0.1,0.5,0.2,0.7,0.3c0.2-0.1,0.3-0.2,0.5-0.2c0.2,0.3-0.9,0.6,0.1,0.4c0,0.2,0.1,0.3,0.2,0.4
        c0-0.2-0.1-0.3-0.1-0.5c0.2,0,0.4,0,0.5,0.1c0,0.1-0.1,0.3-0.1,0.4c0.2-0.1,0.4-0.2,0.6-0.3c0,0.1,0,0.3,0,0.3
        c0.2,0,0.4-0.1,0.5,0.1c0.1,0,0.3,0,0.4,0c0.1,0.2,0.1,0.3,0.2,0.5c0.2,0,0.4,0,0.6,0c-0.1,0-0.3,0.2-0.3,0.2
        c0.1,0.1,0.3,0.4,0.4,0.1c0.1-0.1,0.3-0.1,0.4-0.2c0,0.1,0,0.3,0,0.4c0.1,0,0.4,0.1,0.5,0.1c-0.1,0.1-0.3,0.2-0.4,0.1
        c0,0.3,0.2,0.3,0.4,0.1c0.2,0.1,0.3,0.2,0.6,0.1c0,0-0.1,0.1-0.1,0.2c-0.1,0-0.3-0.1-0.4-0.1c0.1,0.1,0.1,0.2,0.2,0.3
        c0.2,0,0.5,0,0.7,0.1c0,0.1-0.1,0.2-0.2,0.3c0.3,0.1,0.7,0.2,1.1,0.1c-0.1,0.2-0.3,0.2-0.5,0.3c0.1,0,0.3,0.1,0.5,0.1
        c0.2,0.1,0,0.5,0.3,0.5c0-0.1,0-0.4,0-0.6c0.2,0.3,0.1,0.8,0.5,1c0-0.2,0-0.5-0.1-0.7l0,0c0.1,0.2,0.2,0.5,0.2,0.8
        c0.1,0,0.2,0,0.3,0c0,0.2-0.1,0.5,0.1,0.6c0-0.2,0-0.4,0.1-0.6l0,0.3c0.1,0,0.2,0,0.3-0.1c0,0.1-0.1,0.2-0.1,0.3l0.2,0l0,0.2
        c0.1,0,0.2,0,0.3,0c-0.1,0-0.3,0.1-0.4,0.1c0,0,0,0.1,0.1,0.2c0.2,0.1,0.2,0.2,0.2,0.4c0.2-0.2,0.3,0.1,0.4,0.2
        c0.1-0.1,0.1-0.2,0.2-0.3c0,0.2-0.1,0.5-0.1,0.7c0.1-0.1,0.3-0.2,0.4-0.2c0,0.1-0.1,0.2-0.1,0.3c0.1,0,0.3,0,0.4,0
        c-0.1,0.1-0.3,0.2-0.4,0.2c0.3,0,0.4,0.4,0.7,0.5c0,0.1-0.1,0.2-0.1,0.3c0.1,0,0.2-0.1,0.4-0.1c0,0.3,0,0.6,0,0.8
        c0.1-0.1,0.2-0.2,0.2-0.3c0.1,0.2,0.2,0.5,0.3,0.7c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0,0.2,0,0.3-0.1c-0.1,0-0.2,0.1-0.3,0.1l0,0.2
        c0,0.1-0.1,0.2-0.2,0.2c0.1,0.1,0.4,0.2,0.5,0.3c-0.1,0-0.4,0.1-0.5,0.1c0.1,0.2,0.3,0.1,0.5,0c0.1,0.1,0.3,0.3,0.4,0.4
        c-0.1,0-0.2,0-0.3,0c0.3,0.1,0.5,0.5,0.6,0.8c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1,0.1-0.1,0.3-0.2,0.4c0.1-0.4,0.4,0,0.6,0l0,0.3
        c-0.1,0.1-0.2,0.2-0.3,0.2c0.1,0,0.3,0,0.4,0l0.1,0.2c0.1,0,0.2,0,0.3,0c0,0,0,0.1,0,0.1l-0.1,0c0,0.1,0.1,0.3,0.1,0.4l-0.3-0.2
        l-0.1,0.2c0.5-0.1,0.3,0.5-0.1,0.5c0.1,0,0.3,0.1,0.4,0.1c0,0.4,0.5,0.5,0.6,0.9c-0.1,0-0.3,0-0.4,0c0.1,0,0.2,0.1,0.3,0.1
        c-0.1,0.1-0.2,0.2-0.3,0.2c0.1,0,0.3-0.1,0.4-0.1c0,0.1,0,0.2-0.1,0.3l0.2,0c-0.1,0.2-0.1,0.4-0.2,0.6c0.1,0,0.2,0,0.2,0l0,0.3
        c-0.1,0-0.2,0-0.2,0c0,0.1,0,0.2,0,0.2c0.1-0.1,0.2-0.2,0.4-0.3c-0.1,0.3-0.2,0.6,0.1,0.9c-0.1,0-0.2,0.1-0.3,0.1l0.2,0
        c0.1,0.2,0.1,0.4,0.2,0.6c-0.3,0.1-0.1,0.4,0,0.6l0.1,0c0,0.7,0,1.5,0,2.2c0,0,0.1,0.1,0.1,0.1c0,0.1,0.1,0.3,0.1,0.4l-0.2,0
        c0,0,0,0.1,0,0.1l0.2,0c0,1-0.1,2-0.2,2.9c0,0.2-0.1,0.4,0.1,0.5c0,0-0.1,0-0.2,0c0,0.6-0.1,1.3-0.2,1.9c-0.1,0.5-0.1,1-0.3,1.5
        c-0.1,0.9-0.4,1.8-0.5,2.7c-0.1,0.6-0.2,1.2-0.5,1.6c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.8,0.3-1.5,0.4-2.3c0.1-0.2,0.1-0.5,0.1-0.7
        c0.1-0.5,0.2-1.1,0.4-1.6c0-0.1-0.1-0.2-0.1-0.2c0.2-0.3,0.2-0.7,0.2-1.1c0.1-1.1,0.2-2.1,0.2-3.2c0-0.3,0-0.5-0.1-0.8
        c0.2-0.2,0.3-0.4,0.2-0.6c0,0.1,0,0.3-0.1,0.4l-0.2,0c0-0.4,0.2-0.8,0-1.2c-0.1-0.3-0.1-0.7,0-1c-0.1-0.2-0.3-0.4-0.1-0.7
        c-0.1,0-0.2,0-0.2,0.1c0-0.6-0.3-1.2-0.3-1.8c-0.2-0.3-0.3-0.7,0-1c-0.1,0-0.3,0.1-0.3,0.1c0-0.5-0.1-1-0.4-1.3
        c0.1-0.1,0.1-0.2,0.2-0.3c-0.1,0-0.2,0-0.3,0c-0.2-0.5-0.3-1.1-0.6-1.6c-0.3-0.3-0.2-0.8-0.5-1.1c-0.2-0.2-0.3-0.5-0.3-0.8
        c-0.1,0-0.2,0-0.3,0c-0.1-0.5-0.7-0.8-0.7-1.4c-0.6-0.3-0.7-0.9-1.2-1.3c-0.2-0.2-0.2-0.5-0.5-0.5c0-0.1,0-0.2,0-0.3
        c-0.5,0.1-0.6-0.5-0.8-0.8c-0.4,0.1-0.4-0.4-0.7-0.4c-0.2-0.1-0.4-0.2-0.5-0.3c-0.3-0.4-0.9-0.6-1.3-1c-0.7-0.3-1.3-0.8-2-1
        c-0.3-0.2-0.6-0.4-1-0.6c-0.2-0.3-0.5-0.2-0.8,0c0-0.1,0-0.2,0-0.2c-0.1,0-0.2,0-0.3-0.1c-0.6-0.1-1.1-0.5-1.7-0.4
        c0-0.1,0.1-0.2,0.1-0.2c-0.2,0-0.4-0.1-0.6-0.1c0-0.1-0.2-0.2-0.2-0.3c-0.2,0.1-0.3,0.2-0.5,0.2c-0.4-0.4-1-0.2-1.5-0.2
        c0-0.1,0.1-0.3,0.1-0.4c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.3,0-0.5,0c-0.2-0.1-0.4-0.3-0.7-0.3c-0.3-0.1-0.7,0.1-1-0.2
        c0,0.1-0.1,0.2-0.1,0.3c-0.2,0-0.4-0.1-0.6-0.2c-0.2,0.1-0.6,0.1-0.7,0.4c0-0.1,0-0.2,0-0.3c-0.1,0-0.4,0.1-0.5,0.1
        c0-0.1,0.1-0.3,0.1-0.3c-0.2,0.1-0.4,0.1-0.6,0.2c-0.2-0.2-0.5-0.3-0.7,0c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2,0.1-0.3,0.3-0.4,0.5
        c-0.1-0.3-0.4-0.3-0.6-0.3c0,0.1,0.1,0.2,0.1,0.3c0,0-0.1,0-0.2,0l0-0.2c-0.2,0-0.3-0.1-0.5-0.1c-0.2-0.1-0.3,0.3-0.6,0.2
        c0.1-0.1,0.2-0.3,0.3-0.4c-0.2,0-0.3,0.1-0.5,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3l0.1-0.2c-0.3,0-0.5,0-0.8,0l0.1,0.3
        c-0.1,0-0.2,0-0.2,0c0-0.1,0-0.4,0-0.5c-0.1,0.1-0.2,0.1-0.4,0.2l0.2,0c0,0.1-0.1,0.4-0.1,0.5c0.3-0.1,0.6,0,0.7,0.3
        c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0.2-0.1,0.5-0.2,0.7c0.2-0.1,0.1-0.4,0.2-0.6c0.4,0,0.1,0.5,0.4,0.6c0-0.1,0-0.3,0-0.4l0.2,0
        c-0.2,0.4,0.2,0.6,0.4,0.8l-0.3,0c0.2,0.2,0.6,0.3,0.6,0.6c0.1-0.1,0.1-0.4,0.3-0.3c-0.2,0.1-0.1,0.3-0.1,0.5c0,0.2-0.1,0.6,0.2,0.6
        c-0.2-0.2-0.1-0.6,0.2-0.3c0,0.1,0,0.3,0,0.4l0.1,0c0-0.1,0-0.2,0-0.2c0.1,0,0.4,0,0.5,0l0.1,0.2c-0.1,0-0.3-0.1-0.4-0.1l0,0.2
        c0.2,0,0.4,0.3,0.6,0c0,0.1-0.1,0.3-0.1,0.3c0.1,0,0.3,0,0.4,0c-0.1,0.2-0.1,0.4,0.1,0.4c0.1,0.2,0.4,0.2,0.6,0.3
        c0.2,0.1,0.3,0.4,0.6,0.4c-0.1,0-0.2,0.1-0.3,0.2c0.3-0.1,0.5,0.1,0.4,0.4c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0,0.2-0.1,0.3-0.1l-0.1,0.3
        c0.1,0,0.2,0,0.3,0.1c-0.1-0.1-0.5-0.5,0-0.4l0,0.2c0.1,0,0.3,0,0.4,0c0,0.1,0,0.3,0,0.4l0.3-0.1l-0.2,0.3c0.1,0,0.2,0,0.3-0.1
        c0,0,0-0.1,0-0.1c0.1,0,0.2,0,0.3,0l0,0.2l0.2,0l0,0.1c0.2,0.1,0.3,0.2,0.5,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2
        l0,0.3c0.1,0,0.2-0.1,0.3-0.2l0,0.3c0.1-0.1,0.2-0.2,0.3-0.2l0,0.2c0.1,0,0.3,0,0.5,0l0,0.1c0.1,0,0.2,0.1,0.2,0.1
        c0.1,0,0.3,0,0.4,0c0,0.1,0,0.2,0,0.4c0.1,0.1,0.3,0.1,0.4,0.2c-0.1,0.4,0.3-0.1,0.5-0.1l-0.1,0.3l0.2-0.1c0,0.3,0.1,0.6,0.2,0.1
        c0.1,0.1,0.2,0.2,0.2,0.2c0.1,0,0.2,0,0.3,0l0,0.1c0.2,0.1,0.4,0.2,0.7,0.2c0.2,0.2,0.4,0.1,0.6,0c-0.1,0.5,0.4,0.1,0.6,0.4
        c0.1,0,0.3,0,0.4,0c0,0.1,0.1,0.2,0.1,0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0.2,0.3,0.2,0.5,0.1l0,0.2c0.2,0,0.5,0.1,0.7,0
        c0,0.1,0,0.2,0,0.2c0.2-0.1,0.3-0.1,0.5-0.2c0,0.1,0,0.3,0,0.4c0.2-0.1,0.3-0.2,0.5-0.2c0.2,0.3,0.6,0.4,1,0.2
        c0.2,0.3,0.6,0.1,0.8,0.4c-0.3,0-0.6,0-0.8-0.1c-0.2,0-0.5,0-0.7,0.1c-0.2-0.1-0.5-0.3-0.7-0.1c-0.4-0.3-1.1,0-1.4-0.5
        c0,0-0.1,0.1-0.2,0.2c-0.2-0.2-0.3-0.3-0.3,0c-0.1-0.1-0.3-0.3-0.5-0.3c-0.5,0-0.8-0.3-1.3-0.3c-0.3,0-0.4-0.2-0.7-0.2
        c-0.4,0-0.7-0.2-1.1-0.4c0-0.1,0-0.2,0-0.3c-0.1,0.1-0.1,0.2-0.2,0.3c-0.4-0.2-0.8-0.3-1-0.6c-0.5-0.2-1.1-0.1-1.5-0.6
        c-0.2-0.1-0.4-0.2-0.7-0.1c-0.2-0.2-0.4-0.3-0.5-0.4c-0.1,0-0.2,0-0.3,0l0-0.1c-0.1-0.1-0.3-0.3-0.4-0.4l0,0.4
        c-0.2-0.2-0.4-0.5-0.7-0.5c0-0.1,0-0.2,0-0.3c-0.1,0-0.2,0.1-0.3,0.2c-0.3-0.3-0.7-0.5-1-0.8c-0.2-0.2-0.3-0.4-0.6-0.4l0-0.1
        c-0.5-0.2-1-0.6-1.3-1c-0.3,0-0.4-0.2-0.3-0.5c0,0.1-0.1,0.2-0.2,0.3c-0.5-0.5-1-1-1.4-1.5c-0.5-0.4-0.8-1-1.3-1.4
        c-0.2-0.4-0.6-0.6-0.9-0.9c-0.2,0.1-0.5,0.2-0.7,0.3c0-0.1,0-0.3-0.1-0.4c-0.1,0.2-0.2,0.3-0.3,0.5c-0.2-0.4-0.7,0-0.9,0.2
        c-0.2-0.1-0.7-0.2-0.8,0.1c-0.1,0-0.3,0.1-0.5,0.1c-0.1-0.1-0.2-0.2-0.2-0.2c0,0.1,0.1,0.2,0.1,0.3c-0.1,0.2-0.5,0-0.5-0.2
        c0,0.1,0,0.2,0.1,0.3c-0.3,0-0.6,0.1-0.9,0.3c-0.1,0-0.2-0.1-0.3-0.1c-0.4,0.2-0.9,0.3-1.3,0.4c0,0.1,0,0.2,0.1,0.3
        c-0.1,0-0.3-0.1-0.4-0.1c0,0.1-0.1,0.2-0.2,0.3l0.1-0.5c-0.5,0.1-0.6,0.4-0.7,0.8c0-0.1,0.1-0.3,0.1-0.5c-0.1,0-0.4-0.1-0.5-0.1
        c0,0.2,0,0.4,0,0.6c0,0-0.1,0-0.2,0c0-0.1,0-0.2,0-0.3c-0.2,0.1-0.5,0.2-0.7,0.3c-0.3,0.1-0.6,0.2-1,0.3c0,0.2,0.1,0.3,0.1,0.5
        c-0.2,0-0.2-0.2-0.2-0.4c-0.1,0-0.4,0-0.6-0.1v0.3c0.4-0.1,0.3,0.2,0.2,0.5c-0.1,0-0.3,0-0.4,0l0,0.2c0,0-0.1,0-0.2,0
        c0.2-0.3,0-0.4-0.3-0.5c0,0.1,0.1,0.3,0.1,0.4c-0.2,0-0.4,0-0.6-0.1c-0.2,0.3-0.4,0.6-0.4,0.9c-0.1,0-0.3,0-0.4,0
        c0,0.1,0.1,0.2,0.1,0.3c-0.2,0-0.5-0.1-0.6,0.1c0,0,0-0.1,0.1-0.1c0.3-0.1,0.4-0.4,0.5-0.6c-0.2,0-0.3,0-0.5,0c0,0.1,0,0.2,0,0.3
        c-0.4,0.1-0.7,0.3-0.9,0.5l0.2,0.3c-0.1,0-0.3-0.1-0.3-0.1c0,0.4-0.4,0.2-0.6,0.2c0-0.1,0-0.2,0.1-0.3c-0.2,0-0.3,0-0.5,0
        c0,0,0,0.1,0,0.2c0,0,0.1-0.1,0.1-0.1c0.3,0.1,0.3,0.5,0.7,0.4c-0.1,0.2-0.3,0.3-0.4,0.5c0-0.1,0-0.3,0-0.3
        c-0.4,0.7-1.4,0.5-1.7,1.2c0.1,0,0.2,0,0.2-0.1l0.1-0.2l0.2,0l-0.2,0.5c0.5-0.1,0.5-0.8,1-0.9c-0.1,0.2-0.6,0.3-0.3,0.6
        c-0.2,0.2-0.5,0.2-0.7,0.3l0,0.2c-0.1,0-0.2-0.1-0.3-0.1c0,0.3-0.4,0.4-0.4,0.7c-0.1-0.1-0.2-0.2-0.3-0.3l0.3,0l-0.1-0.2
        c0.3,0.1,0.6-0.2,0.8-0.4c-0.2-0.1-0.3-0.2-0.5-0.3c0-0.1,0-0.3,0.1-0.4c0,0.2-0.2,0.4-0.3,0.5c0.1,0,0.2,0,0.3,0l0,0.2
        c-0.1,0-0.3,0-0.3,0c-0.2,0.2-0.8,0.3-0.6,0.7c-0.1,0-0.2,0-0.3,0c0,0.1-0.1,0.2-0.1,0.3l-0.2,0.2c0-0.1,0.1-0.4,0.1-0.6
        c-0.3,0.2-0.2,0.7-0.5,0.9c0,0.1,0.1,0.2,0.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.3l0-0.3c-0.2,0.1-0.3,0.4-0.4,0.6
        c-0.1-0.1-0.2-0.2-0.3-0.2c0,0.1,0,0.3,0,0.4c-0.1,0-0.2,0-0.3,0l0,0.2c0.1,0,0.3,0,0.5,0c0,0-0.1,0.1-0.1,0.2
        c-0.2-0.2-0.4,0-0.6,0.1c0,0.1,0,0.3,0,0.4l-0.1,0c0.1-0.4-0.3-0.4-0.6-0.5c0.4,0.3,0.6,0.9,0.4,1.3c-0.1-0.6-0.5,0-0.7,0.1
        c0-0.2,0-0.5-0.2-0.6c0,0.1,0,0.4,0,0.5c0,0-0.1,0.1-0.1,0.2c-0.1,0.3,0.3,0.1,0.4,0c0,0.1,0,0.3,0.1,0.4c-0.1,0.1-0.2,0.3-0.3,0.4
        c0-0.1,0.1-0.3,0-0.4c-0.1,0-0.3,0-0.3,0l0,0.2c-0.2-0.1-0.4-0.1-0.5-0.1c0-0.1,0.1-0.2,0.1-0.3c0,0-0.1,0-0.1-0.1
        c-0.1,0.2-0.3,0.4-0.3,0.7c0.2,0,0.5-0.1,0.6-0.1c-0.2,0.2-0.3,0.4-0.4,0.6c0.4,0.1,0.6-0.2,0.7-0.6c0.1,0.1,0.1,0.2,0.2,0.3
        c-0.2,0-0.3,0.3-0.5,0.3c-0.3,0-0.3,0.1-0.2,0.3c0,0-0.1,0-0.2,0c0,0.1,0,0.2,0,0.3c-0.2-0.1-0.3-0.3-0.1-0.5c0-0.1-0.1-0.2-0.1-0.3
        l0.1-0.4c-0.2,0.1-0.4,0.3-0.4,0.6c-0.4-0.1-0.7,0.3-0.9,0.6c0.1,0,0.2,0,0.2-0.1l0.2-0.2c0.1,0.1,0.2,0.3,0.3,0.4
        c0.1,0,0.3-0.1,0.5-0.1c0,0.1,0,0.2,0,0.3l-0.4-0.2c0,0.2,0.1,0.4,0.1,0.6c0-0.3-0.3-0.5-0.5-0.6c0.1,0.3,0.1,0.7,0.3,1
        c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1,0.2-0.2,0.3-0.2,0.5l0.3,0c0,0.1-0.1,0.3-0.1,0.3c-0.1-0.2-0.3-0.5-0.4-0.7
        c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0,0.3,0.1,0.4,0.1c-0.2,0.1-0.4,0.3-0.5,0.5c0,0.1,0.1,0.3,0.2,0.4c0,0.1,0,0.2,0.1,0.3
        c-0.1,0-0.2,0-0.2,0l-0.2,0.2l0-0.3c0.1-0.1,0.2-0.2,0.3-0.3l-0.3,0c-0.1-0.2-0.1-0.4-0.2-0.5c-0.1,0.1-0.2,0.2-0.3,0.4
        c0.1,0,0.3,0,0.4,0c0,0.1,0,0.4,0,0.5c-0.3-0.1-0.4,0.1-0.5,0.3c0.2,0.1,0.4,0.1,0.5,0.2c-0.1,0.2-0.3,0.2-0.5,0.2
        c0,0.1,0,0.2,0,0.3l-0.2,0.2c0.1,0,0.3,0,0.4,0c0.1-0.2,0.2-0.3,0.3-0.5c0.1,0.2,0,0.4,0,0.6c-0.1,0-0.2,0-0.3,0
        c-0.1,0.2,0.1,0.6-0.3,0.6c0.1-0.1,0.2-0.3,0.2-0.4c-0.2-0.1-0.4-0.4-0.5,0c-0.3-0.3,0.3-0.4,0.3-0.7c-0.4,0.1-0.5,0.5-0.7,0.7
        c0-0.1-0.1-0.3-0.1-0.3c0,0.1,0,0.3,0,0.4c0.3,0,0.5,0,0.8,0c-0.2,0.2-0.3,0.4-0.5,0.6c-0.1-0.2-0.1-0.4-0.4-0.4
        c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.3-0.1,0.7-0.5,0.7c0.2,0.1,0.4,0.3,0.6,0.3c-0.1,0.2-0.4,0.2-0.5,0c0,0.1-0.1,0.2-0.2,0.2
        c0,0-0.1-0.1-0.2-0.1l0,0.1c-0.3,0-0.4,0.1-0.3,0.4c0.3,0,0.4-0.3,0.7-0.2c0.1,0.5-0.7,0.1-0.6,0.6l0.3,0l0,0.1l-0.2,0
        c0,0.1,0.1,0.3,0.1,0.4c0.2-0.2,0.3-0.5,0.5-0.8c0.1,0.3-0.1,0.5-0.3,0.7c0,0,0,0.1,0,0.2c-0.1,0-0.2,0-0.3-0.1l0,0.2
        c-0.1,0-0.3,0-0.4,0c0,0.1,0.1,0.3,0.1,0.4c-0.1,0-0.3,0-0.3,0c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0-0.3,0-0.4,0
        c0.1-0.2-0.1-0.4-0.1-0.5c-0.3,0.4,0.2,0.7,0.4,1.1c-0.2-0.1-0.3-0.1-0.5-0.2c0,0.1,0,0.2,0,0.3l-0.2-0.1c0,0.1,0,0.4,0,0.5
        c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1,0.1-0.4,0.2-0.4,0.5c0.2-0.1,0.3-0.2,0.5-0.3c-0.1,0.2-0.2,0.4-0.4,0.7c0.3,0,0.4-0.4,0.5-0.6
        c0.4-0.2,0.8-0.4,1.1-0.7c0.1,0,0.4,0.1,0.5,0.1c-0.1-0.2-0.2-0.3-0.5-0.3c0.2-0.2,0.5-0.1,0.8,0c-0.1-0.1-0.3-0.3-0.5-0.4
        c0.2,0.1,0.4,0.1,0.6,0.2c0-0.1,0-0.3,0-0.5c0.1,0.1,0.2,0.2,0.3,0.2c0-0.2,0.1-0.4,0.3-0.4c0.1-0.1,0.2-0.3,0.4-0.4
        c0,0.2,0,0.5,0,0.7l0.1,0c-0.2-0.3,0.3-0.6,0-0.8c0.1-0.4,0.3,0,0.5,0.1c-0.1-0.3,0.1-0.5,0.3-0.7c0.1,0.1,0.2,0.1,0.4,0.2
        c-0.1,0.1-0.2,0.3-0.2,0.3c0.3-0.3,0.5-0.7,0.9-0.9c0.4,0,0.6-0.4,0.9-0.6l0.1,0.1c0.1-0.1,0.2-0.2,0.2-0.2c0.3,0.1,0.6,0.5,0.9,0.2
        c-0.2,0-0.3,0-0.5,0l0-0.2c-0.6,0.3-0.2-0.8,0-0.2c0.2,0,0.4-0.1,0.6-0.1c0-0.3,0.5-0.1,0.5-0.4c0.1-0.1,0.3-0.1,0.4-0.2
        c0-0.2,0.1-0.3,0.2-0.5c0,0,0.1,0.1,0.1,0.1c-0.1,0.1-0.3,0.3-0.3,0.5c0.3,0.1,0.4-0.2,0.6-0.3c0-0.3,0.2-0.3,0.4-0.1
        c0.3-0.2,0.5-0.5,0.8-0.4c0-0.1,0-0.3,0-0.3c0.2-0.1,0.4-0.2,0.6-0.2c-0.1,0.2-0.3,0.2-0.4,0.3c0,0,0,0.2,0,0.2
        c0.4-0.3,1-0.3,1.5-0.6c-0.1-0.1-0.2-0.2-0.2-0.3c0.4,0.4,0.9,0.2,1.3-0.1c0,0.1,0,0.3,0,0.4c0.1-0.1,0.2-0.3,0.2-0.4
        c-0.1,0-0.2-0.1-0.2-0.1c0.1,0,0.2,0,0.3,0c0.1,0.1,0.2,0.3,0.3,0.4c-0.1-0.3,0-0.5,0.1-0.7c0,0.1,0,0.3,0,0.4
        c0.1-0.1,0.3-0.3,0.5-0.4c-0.1,0.2-0.1,0.3-0.2,0.5c0.1-0.1,0.3-0.2,0.4-0.3c0.1,0,0.4,0,0.5,0c-0.1-0.1-0.3-0.3-0.4-0.4
        c0.2,0,0.3,0.1,0.5,0.2c0.5,0,1-0.3,1.6-0.4l0,0.2c0.1-0.2,0.2-0.3,0.3-0.5c0.1,0.1,0.2,0.3,0.2,0.3c0-0.1-0.1-0.2-0.1-0.3
        c0.1,0,0.3-0.1,0.5-0.2c0,0.1,0.1,0.2,0.2,0.3l0.2-0.1l-0.2-0.1c0.2,0,0.3,0,0.5-0.1c0.2-0.2,0.4-0.1,0.7,0l-0.1-0.2
        c0.2,0.2,0.4,0.4,0.5,0.1c0.1,0,0.3,0,0.4,0c0.1,0.1,0.2,0.2,0.4,0.2c0.1-0.3,0.3-0.3,0.5-0.4c-0.1,0.2,0,0.4,0.3,0.4
        c0-0.1-0.1-0.3-0.1-0.4c0.3,0,0.6,0.1,0.9,0c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0,0.2,0,0.3,0c-0.2-0.2-0.4-0.4-0.3-0.7
        c0.1,0.1,0.3,0.2,0.4,0.4c0-0.1,0-0.3,0-0.5c0.1,0.1,0.2,0.3,0.3,0.4c0.1-0.3,0.4-0.2,0.7-0.2c0.3-0.1,0.6,0.1,0.7,0.4
        c0-0.2,0-0.4,0-0.6c0.2,0.2,0.4,0.3,0.6,0.5c0-0.1-0.1-0.4-0.2-0.5c0.2,0.1,0.3,0.2,0.4,0.3c0.4-0.2,0.8-0.1,1.2,0.1
        c0-0.1,0.1-0.3,0.1-0.4l-0.1,0.3c0.1-0.1,0.3-0.2,0.5-0.2c0,0.1,0,0.2,0,0.3c0.1,0,0.3-0.1,0.4-0.2c0,0,0.1-0.1,0.2-0.1l0.1,0.3
        c0.2-0.1,0.4-0.4,0.6-0.2c-0.1,0.1-0.2,0.2-0.3,0.3l0.2,0.1c0-0.1,0.1-0.2,0.1-0.3c0.1,0.1,0.3,0.3,0.4,0.4c0-0.1,0-0.3,0-0.4
        l0.1-0.1l0,0.3c0.1-0.1,0.3-0.2,0.3-0.3c0,0.3,0,0.5,0.1,0.8c0-0.2,0-0.4,0.1-0.6c0.6-0.3,0,0.6,0.4,0.5c0-0.1-0.1-0.3-0.1-0.4
        l0.4,0l0,0.1l0.2,0c0,0.1,0,0.3,0,0.4c0.1-0.1,0.2-0.2,0.3-0.4c0.5-0.1,0.9,0.1,1.3,0.4c-0.1-0.2-0.2-0.4-0.3-0.6
        c0.3,0,0.3,0.3,0.4,0.5l0.3-0.2c0,0.2-0.1,0.4-0.1,0.5c0.1,0,0.2,0.1,0.3,0.1c-0.2-0.1-0.1-0.4-0.2-0.6l0.4,0.2
        c0.1-0.1,0.2-0.2,0.3-0.2c0,0.1,0,0.3,0.1,0.3c0.1-0.1,0.2-0.2,0.3-0.3c0,0.1,0,0.3-0.1,0.4c0.1,0,0.3,0,0.3,0l0.1-0.2
        c0.1,0.1,0.2,0.3,0.4,0.2c0.1,0.2,0.3,0.1,0.5,0.1c0.1-0.1,0.2-0.2,0.2-0.2c0,0.2-0.1,0.4-0.2,0.6c0.2-0.1,0.4-0.2,0.7-0.2
        c0,0.2,0.1,0.3,0.1,0.5c0,0,0.1-0.1,0.2-0.1c0.2,0.1,0.5,0,0.8,0.1c0.2,0.3,0.3,0.6,0.5,0.9c0-0.1,0-0.3,0-0.5
        c0.2,0.2,0.4,0.3,0.7,0.4c0.1,0,0.3,0.1,0.4,0.1l-0.1,0.2c0.2,0,0.4,0.1,0.6,0.1v0.2c0.2,0,0.4,0,0.6,0c0,0.1-0.1,0.2-0.1,0.3
        c0.2,0,0.4,0.3,0.6,0.4c-0.1-0.2-0.1-0.3-0.1-0.5c0.3,0.2,0.1,0.8,0.6,0.8c0.3-0.1-0.3-0.6,0-0.4c0.2,0.1,0.2,0.3,0.3,0.4
        c0.1,0.1,0.3,0.2,0.4,0.3l-0.1,0.1c0.2,0,0.4,0.1,0.6,0.2c0,0.2,0,0.5-0.1,0.7c0.1,0.1,0.2,0.1,0.3,0.2c-0.2-0.1-0.5-0.4-0.1-0.6
        c0.1,0.1,0.3,0.2,0.4,0.3c0-0.1,0-0.3,0-0.4l0.1,0c0,0.1,0.1,0.3,0.1,0.5c0.2,0.1,0.6,0.1,0.6,0.4c0.1,0,0.3,0,0.4,0
        c-0.1,0.1-0.3,0.3-0.4,0.4c0.1,0,0.4-0.1,0.5-0.1c0,0.1-0.1,0.3-0.1,0.3c0.4,0,0.5,0.4,0.5,0.6c0.1,0,0.2-0.1,0.3-0.1
        c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.3,0.2,0.4,0.3c-0.1,0.1-0.2,0.2-0.3,0.3l0.2,0c0.1,0.2,0.2,0.2,0.4,0.1c0.2,0.2,0.3,0.4,0.6,0.6
        c-0.1,0.1-0.2,0.2-0.2,0.3c0.1,0,0.3,0,0.4,0c0.1,0.2,0.3,0.5,0.3,0.8c0.1-0.1,0.2-0.2,0.3-0.3c0,0.1,0,0.2,0,0.2H81l0.1,0.3
        c0.1,0,0.2,0,0.3,0c0,0.2-0.1,0.4-0.1,0.6l0.2-0.1l0,0.2l0.2,0c0,0.1,0,0.2,0,0.3c0.1,0.1,0.3,0.2,0.4,0.3c0,0.2,0,0.4,0,0.6
        c0.1,0,0.2,0,0.3,0c0,0.2,0,0.4,0,0.7l0.1-0.1c0.1,0.2,0.2,0.5,0.4,0.7c0.1,0.1,0.2,0.2,0.3,0.4c-0.1-0.1-0.4-0.2-0.5-0.3
        c0.1,0.2,0.3,0.5,0.6,0.6l-0.2,0l0,0.5c0.1,0,0.3-0.1,0.4-0.2c0,0.2,0,0.4-0.1,0.6c0-0.1-0.1-0.3-0.2-0.3c-0.2,0.1-0.1,0.3,0,0.4
        c0.1,0.1,0.2,0.3,0.2,0.3c-0.1,0-0.2,0.2-0.2,0.2l0.2,0c0.1,0.2,0.2,0.5,0.1,0.8c0.1,0,0.2-0.1,0.3-0.1c0,0.1,0,0.4,0,0.5
        c-0.1,0-0.2,0-0.3-0.1l0,0c0.1,0.2,0.1,0.3,0.2,0.5c0-0.4,0.3-0.2,0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0.2,0.1,0.5,0.1,0.7l-0.2,0
        c0.1,0.2,0.1,0.4,0.4,0.4c-0.1,0.5-0.2,1-0.2,1.6c0.1,0,0.2,0.1,0.3,0.1l-0.3-0.1c0.2,0.4-0.2,0.9,0.3,1.1c-0.1,0-0.3-0.1-0.4-0.1
        c0.1,0.2,0.3,0.6,0,0.8c0.1,0.1,0.2,0.1,0.3,0.2c-0.5,0-0.3,0.4-0.1,0.6c-0.1,0-0.3-0.1-0.4-0.1c0,0,0,0-0.1,0
        c0.1,0.1,0.3,0.3,0.1,0.4c-0.3,0.1,0.3,0.3,0.2,0.5l-0.3,0c0.4,0.3-0.1,0.7-0.3,1.1l0.2-0.1l0,0.4c-0.1,0-0.2-0.1-0.3-0.1
        c0.4,0.2,0,0.6,0,1c-0.2,0.3-0.4,0.5-0.5,0.8c0.2,0.3,0.1,0.5-0.1,0.6c0,0.1,0,0.3,0,0.4c-0.3,0.5-0.6,1-1,1.5l0,0.1
        c-0.1,0.1-0.2,0.3-0.2,0.4c-0.1,0-0.2,0-0.2,0c-0.1,0.3-0.4,0.4-0.3,0.7l-0.1,0c0,0.1,0,0.2,0,0.3l-0.1,0.1c0,0.1-0.1,0.2-0.1,0.3
        l-0.2-0.1c0,0.1,0.1,0.3,0.1,0.3l-0.2,0c0,0.1,0,0.3,0,0.4c-0.1,0-0.2,0-0.3,0c0,0.1,0,0.2,0,0.3c-0.1,0-0.3,0-0.4,0
        c0,0,0.1,0.1,0.2,0.1c-0.1,0.1-0.2,0.3-0.2,0.4l-0.1,0c0,0.1,0,0.2,0,0.2c-0.4,0.1-0.5,0.6-0.9,0.6c0,0.1,0.1,0.2,0.1,0.3
        c-0.1,0-0.3-0.1-0.3-0.1c0,0.1,0,0.2,0.1,0.3c-0.4,0-0.4,0.4-0.7,0.6l-0.2-0.1c0,0.1,0,0.3,0,0.4c-0.1,0.1-0.3,0.1-0.4,0.2
        c-0.1,0.4-0.7,0.4-0.8,0.8c-0.1,0-0.2-0.1-0.3-0.1c0,0.1,0.1,0.3,0.1,0.3c-0.2,0.1-0.4,0.2-0.6,0.2c-0.1,0.4-0.6,0.6-0.9,0.7
        c-0.3,0.2-0.6,0.5-0.9,0.7c-0.3,0.3-0.7,0.5-1.1,0.7c-0.4,0.2-0.9,0.3-1.2,0.7c-0.2,0-0.3,0.1-0.5,0.1l0.1,0.2c-0.1,0-0.3,0-0.4,0
        c0,0.1,0,0.3-0.1,0.3c-0.1,0-0.2-0.1-0.3-0.1c0,0.3-0.3,0.5-0.6,0.4c-0.1,0.4-0.6,0.1-0.7,0.6c-0.2,0-0.4,0-0.6,0
        c-0.1,0.2-0.4,0.2-0.6,0.3c-0.3,0.1-0.5,0.2-0.7,0.3c-0.3,0.1-0.6,0.2-0.8,0.3c-0.3,0.3-0.8,0.3-1.2,0.2c0,0.1-0.1,0.2-0.2,0.3
        c-0.2,0-0.4,0-0.5,0c-0.2,0.1-0.5,0.1-0.8,0.1c0,0,0-0.1,0-0.1c0.4-0.1,0.9-0.1,1.3-0.2c0-0.1,0-0.2,0.1-0.2c0.3,0,0.7-0.1,1-0.2
        c0.2-0.2,0.5-0.3,0.8-0.3c0.3-0.2,0.6-0.3,0.9-0.5c0.2-0.1,0.5-0.1,0.7-0.3c0.1,0,0.3,0,0.4,0l0-0.2l0.1,0c0.1-0.3,0.3-0.1,0.5-0.1
        c0.1-0.2,0.1-0.3,0.2-0.5c0,0.1,0,0.2,0,0.3l0.3,0c0-0.1,0-0.2,0-0.2l0.2,0.1c0-0.1,0-0.2,0-0.2c0.1,0,0.2,0.1,0.3,0.1l0-0.3
        c0.1,0,0.2,0,0.3,0c0-0.1-0.1-0.2-0.2-0.3c0.1,0.1,0.2,0.3,0.3,0.2c0.2-0.2,0.5-0.3,0.7-0.5c0.1,0,0.2,0,0.3,0l-0.1-0.2
        c0.1,0,0.3,0,0.4,0.1l0-0.2c0.2,0.1,0.4,0,0.5-0.2c0.1-0.1,0.2-0.2,0.3-0.2c0,0,0.1,0,0.2,0c0-0.3,0.3-0.2,0.4-0.4
        c0.1-0.3,0.6-0.4,0.7-0.7c0.1,0,0.3,0,0.4,0l-0.1-0.2c0.3,0.1,0.5,0,0.4-0.3l0.1,0.1c0.1-0.1,0.2-0.3,0.3-0.4
        c0.3,0.1,0.5-0.1,0.5-0.4c0.3,0,0.4-0.1,0.4-0.3c0,0,0.1,0,0.2,0c0.2-0.2,0.4-0.7,0.7-0.6c0-0.1,0-0.4,0-0.5
        c0.1,0.1,0.2,0.2,0.3,0.2c0-0.1,0-0.2,0-0.3c0.2-0.2,0.3-0.4,0.4-0.6c0.2-0.1,0.3-0.2,0.3-0.4c0.1,0,0.2,0,0.3,0c0-0.1,0-0.3,0-0.4
        c0.1,0.1,0.3,0.2,0.3,0.2c0-0.1-0.1-0.2-0.1-0.3l0.2,0l-0.1-0.2l0.1,0c0.2-0.4,0.4-0.8,0.7-1.2c0.1,0.1,0.2,0.2,0.3,0.3
        c0-0.1,0-0.4,0-0.5l0.1,0c0.1-0.3,0.1-0.8,0.5-0.9c0,0,0-0.1,0-0.2c0.3-0.1,0.2-0.4,0.3-0.6c0.1,0,0.2,0,0.2,0
        c0-0.3,0.2-0.5,0.3-0.7c0.1-0.3,0.4-0.5,0.4-0.8c0.1-0.1,0.2-0.1,0.2-0.2c0.1-1.1,0.8-2,0.9-3.1c0,0,0.1,0,0.1,0.1
        c0-0.2-0.1-0.5,0.2-0.6c-0.1-0.4,0.1-0.7,0.1-1.1c0.2-1.2,0-2.4,0.1-3.6c-0.2-0.9-0.1-1.9-0.4-2.7c-0.1-0.6-0.5-1.2-0.6-1.8
        c-0.3-0.5-0.4-1-0.7-1.5c-0.2-0.4-0.2-0.8-0.5-1.1c-0.4-0.3-0.6-0.8-0.8-1.2c-0.1-0.3-0.4-0.4-0.5-0.6c-0.2-0.3-0.3-0.8-0.8-0.8
        c-0.2-0.4-0.3-0.9-0.8-0.8c-0.1-0.2-0.2-0.5-0.3-0.7c-0.1,0-0.2,0.1-0.2,0.1c-0.4-0.3-0.6-0.8-1-1c-0.3-0.3-0.7-0.5-1-0.8
        c-0.1,0-0.2-0.1-0.3-0.1c-0.4-0.3-0.9-0.5-1.3-0.8c-0.1,0-0.3,0-0.3,0c0-0.1-0.1-0.2-0.2-0.3c-0.4-0.2-0.8-0.3-1.2-0.5
        c-0.2-0.1-0.3-0.6-0.6-0.4c0-0.3-0.2-0.2-0.4-0.1c-0.6-0.3-1.2-0.4-1.8-0.6c-0.6-0.1-1.2-0.2-1.7-0.3c-0.2,0-0.5-0.3-0.7-0.1
        c-0.6-0.3-1.4-0.1-2-0.4c0,0-0.1,0.1-0.2,0.1c-0.4,0-0.9-0.1-1.3-0.3c-0.2,0-0.4,0.2-0.6,0c-0.4,0.3-0.8-0.1-1.2,0
        c-0.2-0.1-0.5,0.3-0.6,0c-0.2,0.1-0.4,0.2-0.6,0c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0-0.2-0.1-0.2-0.1c-0.2,0.1-0.3,0.2-0.5,0.2
        c0-0.1-0.1-0.3-0.1-0.4c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1,0-0.2-0.1-0.3-0.1c-0.5,0.3-1.1,0.2-1.5,0c0,0.1,0.1,0.3,0.1,0.4
        c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2,0.2-0.4,0.2-0.6,0.2c0-0.1,0-0.2,0-0.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3-0.1-0.6,0.2-0.8,0.4
        c0-0.1,0-0.3,0-0.4c-0.3,0-0.4,0.3-0.5,0.5c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1,0.1-0.3,0.3-0.4,0.4c0-0.1-0.1-0.3-0.2-0.4
        c0,0.1-0.1,0.2-0.1,0.3c-0.1,0-0.2,0-0.3,0l0.1,0.2c-0.4-0.2-0.7,0.2-1.1,0.2c-0.3,0-0.6,0.3-0.9,0.1c0,0.1,0,0.2,0,0.2
        c-0.2,0-0.3,0-0.5,0c-0.1,0.1-0.3,0.2-0.5,0.3c-0.2-0.1-0.5-0.3-0.4,0.1c-0.3,0-0.6,0-0.8,0.3c0-0.1-0.1-0.3-0.1-0.3
        c-0.1,0.2-0.1,0.5-0.4,0.6c0-0.1-0.1-0.2-0.2-0.3c-0.2,0.2-0.3,0.5-0.7,0.4l0,0.3l0.2,0l0,0.1c-0.1,0-0.2,0-0.3,0
        c-0.1,0-0.2-0.1-0.3-0.1c0,0.1,0,0.4,0,0.5c-0.1-0.1-0.3-0.2-0.3-0.3c-0.2,0.3-0.5,0.5-0.9,0.5c-0.2,0.4-0.5,0.4-0.9,0.6
        c-0.1-0.1-0.2-0.1-0.3-0.2c0,0.1-0.1,0.2-0.2,0.3c0.1,0,0.2,0.1,0.2,0.1c-0.2,0-0.4,0.1-0.7,0.2c-0.1,0.1-0.3,0.2-0.4,0.2l0-0.3
        c0,0-0.1,0-0.1,0c0,0.2-0.1,0.4-0.2,0.6c-0.1,0-0.3,0-0.5,0c-0.3,0.4-0.8,0.7-1.3,0.9c0,0.1,0,0.2,0,0.2c-0.1,0-0.4,0-0.5,0
        c-0.1,0.3-0.4,0.4-0.6,0.7c-0.2,0.2-0.4,0.5-0.7,0.4c0,0.1,0.1,0.2,0.1,0.2c-0.1,0-0.2,0-0.2,0l-0.1,0.2c-0.1,0-0.2-0.1-0.3-0.1
        c0,0.1,0.1,0.2,0.1,0.3c-0.4-0.1-0.4,0.3-0.7,0.4c0,0.1-0.1,0.3-0.1,0.4c0-0.1-0.1-0.2-0.1-0.3c-0.1,0.2-0.3,0.4-0.5,0.4
        c0,0.1,0.1,0.2,0.1,0.3c-0.2-0.2-0.5-0.1-0.5,0.2c-0.2,0-0.4,0.1-0.5,0.2l0.3,0.1c-0.2,0.1-0.4,0.2-0.6,0.3
        c-0.3,0.2-0.7,0.3-0.7,0.7c0.2-0.1,0.4-0.3,0.7-0.4c0,0,0.1,0.1,0.1,0.1c-0.2,0.2-0.5,0.2-0.6,0.4c-0.3,0.1-0.2,0.7-0.7,0.7
        c0.1-0.3,0.3-0.4,0.4-0.7c-0.6,0.1-0.3,0.8-0.7,1.1c0,0.2,0.4,0.3,0.5,0.4c-0.2,0-0.4-0.1-0.6-0.1c0,0.5-0.2,0.9-0.2,1.4
        c-0.2-0.2-0.3-0.3-0.4-0.5c-0.1,0-0.2,0-0.2,0.1c-0.2,0,0-0.3-0.1-0.3c-0.2,0.1-0.1,0.3-0.1,0.5c0.3-0.1,0.5,0.2,0.7,0.3
        c-0.1,0.1-0.3,0.2-0.4,0.3c0.1,0,0.4-0.1,0.5-0.1c-0.1,0.1-0.3,0.3-0.4,0.5c0.1,0,0.3-0.1,0.4-0.1c-0.3,0.4,0.1,0.9-0.4,1.2
        c0.1,0,0.4-0.1,0.5-0.1c-0.1,0.1-0.2,0.2-0.4,0.2c0,0.1,0,0.3,0,0.4c0.1,0,0.3-0.1,0.3-0.2c-0.1,0.1-0.2,0.3-0.2,0.4
        c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0-0.2,0.1-0.3,0.2c0-0.3-0.2-0.5-0.4-0.7c-0.1-0.2-0.2-0.3-0.3-0.5c0.1,0.2-0.2,0.9,0.3,0.6
        c0,0.2,0.1,0.3,0.1,0.5l-0.2,0.1c0.2,0.1,0.4,0.2,0.6,0.3c-0.2,0.1-0.3,0.3-0.3,0.5c-0.1-0.1-0.3-0.2-0.4-0.3c0,0.1,0,0.3,0,0.4
        l0.3-0.1l-0.1,0.3c-0.2-0.5-0.3,0.1-0.4,0.2c-0.1,0-0.2,0-0.3-0.1c0,0.1-0.1,0.3-0.1,0.5c0.1,0,0.3,0,0.4,0
        c-0.1-0.1-0.2-0.2-0.3-0.2c0.1,0,0.2-0.1,0.3-0.1c0.4-0.2,0.8-0.3,0.9-0.7c0.3,0.5-0.2,0.6-0.5,0.9c0.1,0.2,0.2,0.4,0.1,0.6l0.3-0.1
        c-0.2,0.2-0.4,0.4-0.5,0.7c0.2-0.1,0.4-0.1,0.6-0.2c-0.2,0.1-0.4,0.2-0.5,0.5c-0.2,0-0.4,0-0.5,0c0.1,0.2,0.1,0.4,0.2,0.6
        c0,0,0.1,0,0.2,0c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0.3-0.1,0.6,0,0.9c-0.2,0.2-0.4-0.1-0.4-0.3l0,0.2c-0.1,0-0.3,0-0.4,0
        c0.4,0.1,0.6,0.3,0.8,0.6c-0.2,0.1-0.5,0.2-0.6,0.5c-0.1,0-0.2,0-0.3,0c0,0.1,0,0.2-0.1,0.2c0.3-0.2,0.7-0.4,1-0.6
        c0,0.2,0.1,0.5,0.1,0.6c-0.1,0-0.3,0-0.4,0c0.1,0.1,0.3,0.2,0.4,0.2c-0.1,0.3-0.5,0.7,0,0.9c0-0.1,0-0.3,0-0.5c0.1,0,0.2,0,0.2,0
        l-0.2,0c0,0.2,0.1,0.3,0.2,0.5c-0.1,0-0.2,0-0.3-0.1c-0.1,0.2-0.2,0.4-0.2,0.7c0.4-0.1,0.5,0.5,0.1,0.6c0.2,0.2,0.3,0.4,0.5,0.7
        c-0.1,0-0.4,0-0.5,0c-0.1-0.1-0.2-0.2-0.3-0.3c0,0.3,0.5,0.7,0.8,0.5l0,0.3c-0.1,0-0.3-0.1-0.4-0.1c0.2,0.4,0.5,0.7,0.8,1.1
        c-0.3-0.1-0.5-0.1-0.6,0.1l-0.2-0.1c0,0.1,0,0.2,0,0.2l-0.1,0.1l-0.2,0c-0.1,0.2,0.4,0.5,0.1,0.8c0.1,0,0.3-0.1,0.4-0.2l0,0.3
        c0.4,0,0.1-0.5-0.2-0.4c0-0.2-0.1-0.3-0.1-0.5c0.1,0,0.3-0.1,0.4-0.2l0,0.3l0.2-0.2c-0.1,0.3,0.1,0.5,0.2,0.7
        c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.2,0,0.3,0.1,0.5c0.1,0,0.3,0,0.4,0c-0.1,0.2-0.2,0.4-0.3,0.6c0.2,0.3,0.1,0.8,0.6,0.9
        c-0.2,0.1-0.3,0.1-0.5,0.2c0,0.1,0.1,0.4,0.1,0.5l0.1,0c0-0.1,0-0.2,0-0.2c0.1,0,0.3,0.1,0.3,0.1c-0.4,0.3,0.2,0.5,0.2,0.9
        c-0.4-0.2-0.8,0.5-0.4,0.7c0-0.1,0.1-0.4,0.1-0.6c0.4,0.1,0.3,0.4,0.2,0.7c0.1-0.1,0.3-0.2,0.4-0.2c-0.1,0.1-0.2,0.3-0.4,0.2
        c0.1,0.2,0.3,0.3,0.5,0.2c-0.1,0.1-0.2,0.2-0.2,0.3c0.1,0,0.2,0,0.3,0c0.1,0.2,0.2,0.4,0.4,0.6c-0.3-0.2-0.5-0.4-0.8-0.5
        c0-0.1,0-0.2,0-0.2l0,0c0,0.2,0,0.3,0,0.5c0.1,0,0.3-0.1,0.4-0.1c0,0.3-0.2,0.5-0.4,0.7c0.2-0.1,0.4-0.2,0.5-0.3l-0.1,0.5
        c0.1-0.1,0.2-0.2,0.3-0.3l0.1,0c0,0.1-0.1,0.2-0.1,0.3l0.2,0c0,0.3,0.1,0.6,0.3,0.9c-0.3,0.1-0.3-0.2-0.5-0.3
        c-0.1,0-0.3-0.1-0.4-0.1c0,0,0,0.1,0,0.1c0.3,0,0.5,0.2,0.7,0.5c-0.1,0-0.2,0.1-0.3,0.2c0.2,0,0.4,0,0.6,0.1c0,0.1-0.1,0.2-0.1,0.3
        c0.1,0.2,0.1,0.4,0.2,0.6c-0.2-0.1-0.3-0.2-0.5-0.3c0.1,0.2,0.1,0.4,0.4,0.4c-0.1,0.1-0.2,0.3-0.2,0.4c0.1,0,0.3-0.1,0.4-0.1
        c0-0.1-0.1-0.3-0.1-0.4c0.3,0,0.4,0.2,0.3,0.4c0.1,0,0.2,0,0.3,0c-0.1,0.1-0.2,0.2-0.2,0.2c0.1,0.1,0.7,0.4,0.3,0.6
        c-0.1,0-0.2-0.1-0.3-0.2c0,0.2,0.2,0.3,0.4,0.3c0,0.1-0.1,0.3-0.2,0.3l0.3,0c-0.2,0.3,0,0.4,0.3,0.4c-0.1,0.1-0.3,0.3-0.3,0.4
        c0.1,0,0.2,0.1,0.3,0.2c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.2,0,0.2,0l0,0.3c0.1-0.1,0.3-0.2,0.4-0.3c0.3,0,0.7,0,0.8,0.4
        c-0.1,0-0.3-0.1-0.4-0.2l0,0.2c0.2,0,0.3,0,0.5,0c0,0.1-0.1,0.2-0.2,0.3c0.2,0,0.4,0,0.5,0c0.2-0.2,0.5-0.2,0.8-0.2
        c-0.1,0.2-0.3,0.2-0.5,0.2c0.1,0.2,0.2,0.3,0.4,0.3c0.2-0.1,0.3-0.1,0.5-0.2c0,0.1,0,0.2,0,0.3c-0.1,0-0.2-0.1-0.3-0.1
        c-0.1,0.1-0.1,0.3-0.2,0.4c0.2,0,0.4,0,0.6,0c0-0.1,0-0.2,0-0.3l0.1,0c0,0.1,0,0.2,0,0.3l0.3-0.1l-0.1,0.2c-0.3,0-0.4,0.3-0.6,0.4
        c0.3-0.1,0.7-0.2,1-0.2c0.1,0.1,0.2,0.2,0.3,0.4c0,0-0.1,0-0.1,0c-0.1-0.5-0.5-0.2-0.7,0.1c0.3,0.2,0.3,0.5,0.4,0.8
        c0.1,0.2,0.2,0.3,0.3,0.5c-0.2-0.2-0.4-0.3-0.6-0.5c0.1-0.1,0.2-0.3,0.2-0.4c-0.3-0.2-0.3,0.1-0.4,0.3c0.2,0.3,0.1,0.6,0.3,0.9
        c-0.3-0.2-0.5,0-0.6,0.3c-0.1-0.1-0.2-0.1-0.3-0.2c0,0.1,0,0.3-0.1,0.4c0.1-0.1,0.3-0.1,0.4-0.2c0.2,0.2,0.2,0.4-0.1,0.5
        c0.5-0.1,0.2,0.7,0.6,0.6c0.1,0,0.2,0.1,0.3,0.1c0.1,0.5,0.4,0.8,0.6,1.3c0.1,0,0.3-0.1,0.4-0.1c0,0.1-0.1,0.3-0.1,0.4
        c0.2-0.1,0.4-0.1,0.5,0.1c0.1,0,0.2-0.1,0.2-0.1c0.1,0.2,0.1,0.4,0.1,0.6c0.2,0,0.3,0.1,0.3,0.3c0,0.1-0.2,0.3,0,0.4
        c0-0.3,0.1-0.5,0.2-0.7c0.1,0.1,0.2,0.3,0.3,0.4c0-0.1,0.1-0.3,0.2-0.4c0.2,0.3,0,0.6-0.1,0.8c0.5,0,0.9-0.1,1.3-0.4
        c-0.1,0.2-0.3,0.4-0.4,0.6c0.4,0,0.4-0.5,0.8-0.5c0.4,0,0.5-0.3,0.7-0.5c0.2,0.1,0.5,0.1,0.4,0.4c-0.1,0-0.3,0.1-0.4,0.1
        c0,0.1,0.1,0.2,0.1,0.3c0.2-0.1,0.3-0.2,0.5-0.3c0-0.2,0-0.4,0.3-0.4c0-0.1,0.1-0.2,0.1-0.3c0,0.3-0.1,0.6-0.2,0.9
        c0.3-0.1,0.6-0.1,0.9-0.2c0,0.5-0.8,0.4-0.7,0.9c0.2-0.2,0.5-0.4,0.9-0.3c0.4,0.2-0.2,0.5-0.1,0.8c0.2,0.2,0.3,0.4,0.5,0.4
        c0.2-0.4-0.3-0.4-0.5-0.6c0.2-0.2,0.4-0.2,0.6-0.2c0-0.1,0.1-0.2,0.2-0.2c0,0.1,0,0.2,0,0.3c-0.2-0.1-0.7,0.2-0.3,0.4
        c0,0,0.1,0.1,0.2,0.1c0,0.3-0.4,0.4-0.6,0.7c0.1,0,0.2,0,0.3,0c0,0.1-0.1,0.4-0.1,0.5c0.1,0,0.2,0.1,0.3,0.1c0,0.1,0,0.3,0,0.4
        c0,0.1,0.1,0.2,0.2,0.3c0.2,0,0.4,0.1,0.6,0.2c0.1,0.3,0.2,0.7,0.6,0.6c0,0.2-0.2,0.3-0.4,0.4c0.2,0,0.5,0.1,0.7,0.2l0-0.2
        c0.2,0.1,0.3,0.1,0.5,0.1c0,0.1-0.1,0.3-0.2,0.4c0.1,0,0.3-0.1,0.4-0.1c0.1,0.1,0.2,0.3,0.2,0.3c0.3-0.1,0.6-0.1,0.8-0.4
        c0.2-0.3,0.6-0.3,0.9-0.4c0-0.1-0.1-0.3-0.1-0.4c0.2,0,0.3,0.3,0.5,0.4c0.2-0.5,0.6-0.2,0.8,0.1c0,0-0.1,0.1-0.2,0.2
        c0.1,0.1,0.2,0.3,0.2,0.5c0.2,0,0.4,0,0.6-0.1c-0.1,0.2-0.1,0.3-0.1,0.5c-0.1,0-0.3-0.1-0.4-0.1l0,0.2l0.2,0
        c0.1,0.1,0.2,0.3,0.2,0.4c-0.1,0-0.3,0-0.4,0c0.3,0.4,0.8,0.6,1.1,1l-0.2,0.1l0,0.1c0.1-0.1,0.3-0.1,0.5-0.2c0-0.1,0-0.4,0-0.5
        c-0.1,0-0.3,0-0.4,0c0.1-0.1,0.2-0.2,0.3-0.2c0,0.1,0.1,0.2,0.1,0.3c0.3,0.5,0.8,0.1,1.2,0c-0.1,0.1-0.2,0.2-0.3,0.3
        c0.2,0.1,0.3,0.3,0.5,0.4c0-0.1,0-0.3,0-0.4c0,0.1,0.1,0.4,0.1,0.5c0.1-0.2,0.3-0.3,0.4-0.5c0,0,0.1,0.1,0.2,0.2
        c-0.1,0.1-0.2,0.2-0.4,0.4c0.3,0,0.7,0.1,1,0.2c-0.1-0.2-0.2-0.3-0.3-0.5c0,0,0.1-0.1,0.1-0.1c0.1,0,0.2,0,0.3,0.1
        c-0.1,0.3,0.2,0.4,0.4,0.6c0.3,0.1,0.5,0,0.7-0.2c0,0.1,0.1,0.3,0.1,0.4c0.2-0.1,0.4-0.2,0.5-0.3c0.3,0.3,0.6,0.4,0.9,0.2
        c0.3,0.2,0.6,0.4,1,0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0.1-0.4,0.5-0.3,0.1c-0.1,0-0.2-0.1-0.4-0.1c0.3,0,0.6,0.1,0.9,0
        c0,0.1,0,0.3,0,0.4c0.1,0.1,0.2,0.2,0.4,0.3l0-0.3l0.1-0.1c0.3,0,0.8,0.4,0.9-0.1l0.3,0.1c-0.1-0.1-0.2-0.2-0.3-0.3
        c0.1,0,0.2-0.1,0.3-0.1c0.1,0.2,0.1,0.4,0.1,0.6l0.3,0c0-0.2,0-0.5,0.2-0.6c0,0.1-0.1,0.3-0.1,0.4c0.2,0,0.5-0.1,0.7,0
        c0-0.2,0.1-0.3,0.3-0.4c-0.1,0.2-0.2,0.4-0.2,0.5c0.2-0.1,0.5-0.1,0.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0.1-0.2,0.3-0.3,0.4-0.5
        c0.3,0.1,0.5,0.1,0.8,0.2c-0.1,0.2-0.2,0.3-0.2,0.5c0.3-0.2,0.4-0.5,0.6-0.7c0.3,0,0.5,0,0.8,0l0,0.1c0,0,0.1,0,0.1,0
        c0.1-0.2,0.2-0.3,0.3-0.4c0.1,0.1,0.2,0.4,0.4,0.4c-0.1-0.2-0.3-0.4-0.4-0.6c0.3,0.1,0.7,0.2,1,0.2l-0.1-0.3
        c0.1,0.1,0.3,0.2,0.4,0.2c0-0.2-0.1-0.3-0.1-0.4c0.1,0,0.2,0,0.3,0l0.1,0.3c0.2-0.1,0.3-0.3,0.3-0.5c0,0.1,0,0.2,0,0.3l0.2-0.1
        c0-0.1,0.1-0.2,0.1-0.3c0.1,0,0.2,0.1,0.3,0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.5,0.3,1-0.3,1.4-0.5c0.3-0.1,0.6-0.5,1-0.3
        c0.4-0.2,0.8-0.6,1.3-0.6l0-0.1c0.4-0.2,0.7-0.4,1.2-0.4c0.1,0.3,0.4,0.1,0.4-0.1c-0.1,0-0.3,0-0.4-0.1c0.3-0.3,0.8-0.3,1.2-0.3
        c0-0.1,0-0.2,0-0.3c0.3-0.1,0.6-0.3,0.9-0.5c0.1,0,0.2,0,0.3,0c0.2-0.5,0.7-0.5,1.1-0.8c0.2-0.1,0.4-0.3,0.6-0.2
        c0.1-0.3,0.5-0.4,0.8-0.6c0-0.1,0-0.3,0-0.4c0.2,0,0.5,0.1,0.5-0.2c0.1,0,0.3-0.1,0.3-0.1c0.3-0.3,0.6-0.5,1-0.7
        c0.3-0.3,0.5-0.5,0.9-0.7c0.2-0.3,0.7-0.3,0.8-0.7c0.1,0,0.3-0.1,0.4-0.1c0.7-0.8,1.4-1.5,2.2-2.2c0.3-0.3,0.7-0.5,0.9-0.9
        c0.1-0.2,0.4-0.3,0.6-0.4c0.3-0.4,0.7-0.8,1.1-1c0-0.4,0.4-0.5,0.7-0.7c0.1-0.3,0.2-0.5,0.5-0.6c0.2-0.4,0.3-0.8,0.7-0.9
        c0.1-0.3,0.3-0.6,0.5-0.8c0.4-0.3,0.6-0.8,1-1.1c0.1-0.3,0.2-0.6,0.6-0.7c-0.1-0.5,0.4-0.7,0.7-1.1c0.4-0.6,1.1-0.9,1.4-1.5
        c0.5-0.4,0.8-1,1.2-1.5c0.3-0.3,0.3-0.8,0.7-1c0.1-0.3,0.3-0.7,0.5-1c-0.1-0.5-0.6-0.8-0.5-1.3c-0.1,0-0.2-0.1-0.3-0.1
        c0.1-0.2,0.1-0.5,0.2-0.7c-0.1,0-0.3-0.1-0.4-0.1c0.1,0,0.3-0.1,0.4-0.1c0.3,0.1,0.7,0.1,1,0.2c-0.1-0.1-0.3-0.3-0.4-0.4
        c0.2,0.1,0.4,0.1,0.6,0.2c-0.1-0.2-0.3-0.3-0.5-0.4v-0.1c0.1,0,0.2-0.1,0.3-0.1c-0.2-0.2-0.4-0.5,0.1-0.4c-0.1-0.1-0.2-0.2-0.3-0.3
        c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.3-0.1,0.4-0.1c0,0.1,0.2,0.2,0.2,0.2c0.1-0.1,0.2-0.2,0.3-0.4l-0.2-0.1v0.2l-0.2,0
        c0-0.1,0.1-0.3,0.1-0.3c0,0-0.1-0.1-0.2-0.2c0,0,0.1,0,0.2,0c0.1,0.1,0.3,0.2,0.4,0.3l0-0.2c0.1,0,0.3,0,0.3,0
        c-0.1-0.2-0.3-0.2-0.4-0.2c0.1,0,0.2-0.1,0.3-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6,0c-0.1-0.2-0.2-0.4-0.3-0.6l0.3,0
        c-0.2-0.2-0.3-0.4-0.2-0.7c0,0-0.1,0-0.2,0c0.4-0.3,0.5-1,0.8-1.4c0.1-1,1.1-1.6,1.2-2.6c0.1-0.1,0.2-0.2,0.2-0.2
        c-0.1,0-0.3,0.1-0.4,0.1c0.2-0.2,0.2-0.4-0.1-0.6c0.1-0.2,0.1-0.4-0.1-0.5c0-0.1,0-0.3,0.1-0.5c0.4-0.3,0.2,0.4,0.5,0.3
        c0.1-0.2-0.1-0.4-0.3-0.5c-0.2,0-0.4-0.1-0.6-0.2l0.5,0c-0.1-0.2-0.2-0.3-0.3-0.5c0.2,0.1,0.3,0.3,0.5,0.5c0-0.1,0.1-0.4,0.1-0.5
        c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0,0.2-0.1,0.2-0.2c-0.2-0.2-0.4-0.3-0.6-0.4c0,0,0.1-0.1,0.1-0.1c0.1,0.1,0.3,0.2,0.4,0.3
        c0-0.2-0.1-0.5,0.1-0.6c0,0.1,0,0.3,0,0.4c0.1,0,0.2,0,0.3,0.1c0-0.3,0-0.6,0-0.8c0-0.1-0.1-0.2-0.1-0.2c0.1,0,0.3-0.1,0.4-0.2
        c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.1,0.1-0.2,0.1-0.3c0.2,0.3,0.7,0.3,0.8,0c-0.1,0-0.3,0-0.5,0.1c0.1-0.2,0.4-0.4,0.3-0.6
        c0-0.4,0.3-0.7,0.3-1l-0.2,0l0-0.3c0.3-0.1,0.1-0.5,0.1-0.7l0.2,0.1c-0.1-0.3,0.1-0.4,0.3-0.6c-0.2-0.4-0.2-0.9,0.3-1
        c-0.1-0.1-0.2-0.2-0.3-0.3c0.3,0.1,0.3-0.2,0.4-0.3c-0.3-0.2-0.3-0.3,0.1-0.5c0,0.1,0,0.2,0,0.3c0.1-0.3,0-0.5-0.1-0.7
        c0.2,0.1,0.3,0,0.5-0.1c-0.1,0-0.3,0-0.4,0c0-0.2,0.1-0.4,0.3-0.6c-0.1-0.1-0.2-0.3-0.3-0.4c0.1-0.3,0.3-0.5,0.5-0.6
        c-0.1,0-0.2,0-0.2,0c-0.1-0.3-0.1-0.5-0.1-0.8c0.1,0,0.2,0,0.3,0c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.1-0.1-0.3,0.1-0.4
        c0-0.3,0-0.7,0.2-0.9c-0.1-0.1-0.2-0.2-0.3-0.2c0.1,0,0.3,0.1,0.5,0.1c0-0.2-0.1-0.5-0.2-0.7c0.1-0.2,0.3-0.1,0.5-0.1
        c0,0,0-0.1,0-0.2l-0.2,0c0-0.2,0-0.5,0-0.6c0.1-0.1,0.2-0.2,0.2-0.3c-0.5,0.1-0.1-0.7,0.2-0.4c-0.1-0.2-0.2-0.4-0.1-0.6l0.1,0.3
        c0.1-0.1,0.1-0.1,0.2-0.2c-0.1,0-0.2,0-0.3,0l0.2-0.3l-0.2,0c0,0,0-0.1,0-0.1c0.1,0,0.3,0,0.3,0c0-0.2-0.1-0.3-0.2-0.5l0.4,0.1
        c-0.1-0.1-0.2-0.4-0.3-0.5l0.3,0c-0.1-0.2-0.2-0.3-0.2-0.5l0.2,0c0-0.1,0-0.3,0-0.4c0.2,0.4,0,1,0.3,1.4c0,0-0.1,0-0.1,0.1
        c0.1,0.3,0.1,0.6,0.1,1c0.3,0.3,0,0.6-0.1,0.9l0.3-0.1c0,0.4-0.1,0.9,0.1,1.4c-0.1,0.1-0.3,0.2-0.2,0.3c0.2,0.3-0.1,0.7,0.1,1
        c-0.1,0-0.3,0-0.3,0c0.1,0,0.2,0.1,0.3,0.1c0,0.1-0.1,0.3-0.1,0.5c0,0.3,0.2,0.7,0,1c0,0.4,0.2,0.7-0.1,1c0.1,0.3,0.2,0.6,0.1,0.9
        c-0.2,0.1-0.1,0.3,0,0.4c-0.1,0.3-0.3,0.6-0.1,0.9c0,0.1-0.1,0.3-0.1,0.4c-0.1,0-0.3-0.1-0.3-0.1c0.1,0.3,0.3,0.5,0.3,0.8
        c-0.1,0-0.3,0-0.4,0c0.2,0.3,0,0.6-0.2,0.9c0.3,0.3-0.1,0.5,0,0.9c-0.1,0-0.3,0-0.4,0c0.1,0.1,0.3,0.2,0.5,0.3
        c-0.2,0.1-0.6,0.3-0.1,0.5c0,0,0,0.1,0,0.2c-0.1-0.1-0.3-0.2-0.4-0.3c0,0.2,0.2,0.4,0.3,0.5c-0.1,0.1-0.3,0.2-0.4,0.4
        c0.1,0.3,0,0.6,0.2,0.8c0.3-0.7,0,0.3-0.3,0.1c0,0.1,0.1,0.3,0.2,0.3l0,0.3c-0.1-0.1-0.2-0.3-0.2-0.3c0,0-0.1,0-0.2,0
        c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.5,0.6-0.1,0.5c0.1-0.1,0.3-0.4,0.4-0.5c0,0.2-0.1,0.5-0.1,0.7c0.1,0.1,0.3,0.3,0.1,0.4
        c-0.2,0.3-0.2,0.9-0.6,0.7c-0.1,0.2-0.2,0.5-0.3,0.7c-0.1,0-0.3,0.1-0.3,0.1c0.1,0,0.3,0,0.4,0c-0.1,0.3-0.3,0.7,0,1
        c-0.1,0-0.4-0.1-0.5-0.1c0,0.1,0,0.3,0.1,0.4l0.1,0.1c-0.1,0.1-0.4,0.1-0.3,0.3c0.2,0.5-0.3,0.2-0.2-0.1c-0.2,0.1-0.4,0.1-0.6,0.1
        c0,0.1-0.1,0.3-0.2,0.3c-0.1,0-0.2,0.1-0.2,0.1l0.3,0c-0.3,0.3-0.6,0.6-0.8,1c0,0.3,0.2,0.5,0.3,0.7c-0.1-0.1-0.3-0.3-0.4-0.4
        c0,0.1-0.1,0.2-0.1,0.2l0.3-0.1c-0.1,0.3-0.4,0.8,0.1,0.7c-0.2,0-0.3,0.1-0.4,0.2c0.4,0,0.3,0.3,0,0.2c0.1,0.2,0.3,0.4,0.3,0.6
        c-0.2,0-0.3,0.1-0.4,0.2c0.2,0,0.3-0.1,0.5-0.1c-0.1,0.1-0.3,0.2-0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3c-0.2,0-0.5-0.1-0.7-0.1
        c0,0.1,0.1,0.2,0.1,0.3c-0.1,0.4-0.4,0.7-0.7,0.9c0.1,0.1,0.1,0.2,0.2,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c0.1,0,0.3,0,0.4,0
        c-0.1,0.1-0.2,0.3-0.3,0.4c0.1,0,0.3-0.1,0.5-0.1c0,0-0.1,0.1-0.1,0.2c-0.1,0-0.3-0.1-0.5-0.1l0,0.4c0.1,0,0.4-0.1,0.5-0.1
        c-0.1,0.2-0.4,0.4-0.3,0.7c0.2,0.4-0.1,0.8-0.3,1.2c-0.5,0-0.6,0.6-1,1c-0.1,0-0.4,0-0.5,0c-0.1-0.1-0.2-0.1-0.3-0.2
        c-0.1,0.2-0.4,0.3-0.3,0.6c-0.1,0-0.2-0.1-0.3-0.1c0,0.3-0.1,0.6-0.2,0.8c-0.1,0-0.2,0-0.2,0l0.3,0l0,0.2c-0.2,0.1-0.3,0.2-0.3,0.5
        c-0.1,0.1-0.2,0.2-0.3,0.4c0,0.2,0,0.4,0,0.6l-0.1,0l0.1,0.2c-0.1,0-0.3,0-0.4-0.1c0.1,0,0.2,0.1,0.3,0.2c-0.2,0.2-0.2,0.4-0.2,0.6
        c-0.2,0-0.4,0.4,0.1,0.3c-0.1,0.4-0.5,0.3-0.7,0.3c0,0,0,0.1,0,0.1c0.1,0,0.3,0,0.4,0c0,0.3-0.3,0.4-0.5,0.5
        c-0.1-0.2-0.2-0.3-0.3-0.4c0.2,0.3,0.5,0.6,0.5,1c0.1,0,0.2-0.1,0.3-0.2c0-0.2,0-0.3-0.1-0.4c0.3,0.1,0.2,0.3,0.2,0.6
        c-0.3,0.1-0.5,0.2-0.8,0c0.1,0.3,0.1,0.5,0.2,0.8c-0.2-0.1-0.2-0.3-0.2-0.5c-0.1,0.2-0.4,0.4-0.4,0.6c-0.2-0.1-0.3-0.2-0.5-0.4
        c0.1,0.2,0.2,0.4,0.4,0.6c0,0.1-0.1,0.3-0.1,0.4c0,0.2-0.5,0.4-0.1,0.6c-0.1,0.1-0.2,0.1-0.2,0.2c0-0.1,0-0.3,0-0.4
        c-0.2,0.2-0.5,0.3-0.5,0.6c0.2-0.1,0.4-0.1,0.6,0c-0.1,0.1-0.2,0.2-0.4,0.2c0,0-0.2-0.1-0.2-0.1c-0.2,0.2-0.6,0.2-0.7,0.5
        c0.1,0,0.3,0,0.4,0c-0.1,0.1-0.2,0.3-0.3,0.4l0.2,0c-0.1,0.2-0.2,0.4-0.2,0.6c-0.3,0.1-0.7,0.4-0.6,0.7c-0.4-0.1-0.6,0.2-0.9,0.3
        c-0.1-0.2,0-0.5,0.2-0.6c0.2,0.1,0.5,0.1,0.7,0c0-0.4,0.3-0.6,0.6-0.7c0-0.1,0-0.4,0-0.5c-0.1,0.2-0.2,0.4-0.3,0.5c0-0.3,0-0.6,0-1
        c-0.2,0.4-0.1,0.8-0.4,1.1c0,0.1,0.1,0.3,0.1,0.4c-0.6,0.1-0.3-0.6-0.2-0.9c-0.2,0.1-0.3,0.3-0.4,0.5c-0.1,0.5-0.6,0.7-0.8,1.1
        l0.1,0l0.1-0.2c0.1,0,0.2,0,0.3,0c0,0.1,0,0.4,0.1,0.6c0.2-0.1,0.4-0.3,0.6-0.4c-0.1,0.1-0.1,0.3-0.2,0.4c-0.2,0-0.3,0-0.5,0
        c-0.1-0.1-0.3-0.2-0.4-0.2c0,0.1,0.1,0.3,0.1,0.4c-0.1,0-0.3,0-0.4,0c0-0.2,0-0.4,0-0.6c-0.2,0.3-0.3,0.5-0.6,0.7
        c0.3,0.3,0.1,0.8,0.5,1c-0.2,0.1-0.4,0.2-0.5,0.3c0.1-0.3,0.1-0.5,0.1-0.8c-0.3-0.1-0.5,0.1-0.8,0.2c0,0.1,0,0.2,0.1,0.3
        c0,0.3-0.3,0.1-0.4,0c0,0.1,0.1,0.2,0.2,0.3c-0.1,0.2-0.1,0.3-0.2,0.5c-0.1-0.1-0.2-0.3-0.2-0.3c-0.1,0.2-0.2,0.3-0.3,0.5
        c0.1,0,0.4-0.1,0.6-0.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0-0.4-0.5-0.5-0.1c-0.1,0.1-0.2,0.3-0.2,0.4c0.3,0,0.6,0,0.8,0
        c0,0,0,0.1,0,0.1c-0.3,0-0.5,0.1-0.7,0.2c0-0.1,0-0.2,0-0.2l-0.3,0.2c-0.1-0.1-0.2-0.2-0.2-0.2c-0.2,0.1-0.1,0.4-0.1,0.6
        c-0.1,0-0.2-0.1-0.3-0.1c0,0.1,0,0.2,0,0.2c-0.4,0-0.5,0.3-0.6,0.6c-0.2,0.1-0.4,0.2-0.6,0.3l0.1,0.2c-0.4,0-0.5,0.4-0.9,0.3
        c0,0.1,0,0.3,0,0.4c-0.1,0-0.3,0-0.4-0.1c0,0.1,0.1,0.2,0.1,0.3c-0.2,0.1-0.4,0.1-0.6,0.2c0.3,0.1,0.7,0.2,1,0.4
        c-0.1,0-0.2,0.1-0.3,0.2l0.1-0.2c-0.1,0-0.3,0-0.5,0c0,0.2-0.1,0.4-0.3,0.3c0-0.1,0.1-0.4,0.1-0.6c-0.3,0.3-0.7,0.4-0.8,0.8
        c-0.1,0-0.2,0-0.3,0c-0.1,0.2-0.3,0.2-0.5,0.1c0.1,0.1,0.2,0.2,0.2,0.3c-0.1,0-0.3,0-0.4,0c0,0.1,0.1,0.2,0.1,0.3l-0.3,0.3
        c0-0.1,0-0.4,0-0.5c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0,0.2-0.1,0.4c0.1,0,0.4,0,0.5,0c-0.1,0.5-0.5,0.1-0.8,0.1
        c0,0.5-0.5,0.4-0.9,0.4c0,0,0,0,0,0c0,0,0.1,0.1,0.2,0.1l0.1,0.2c-0.1,0-0.3,0-0.4,0c0,0.4-0.6,0.3-0.3,0.8c0.1-0.1,0.3-0.2,0.4-0.3
        c-0.1,0.1-0.1,0.2-0.2,0.4c0,0-0.1,0-0.2,0c-0.1-0.2-0.1-0.6-0.4-0.3c-0.2,0-0.4,0-0.5,0.2c0.1,0,0.4,0.1,0.5,0.1c0,0,0,0.1,0,0.2
        c-0.2-0.1-0.5-0.2-0.7-0.3c0,0.1,0,0.2,0,0.3c-0.1,0-0.3,0.1-0.4,0.1c0.1-0.1,0.2-0.3,0.2-0.4c-0.2,0.1-0.4,0.2-0.6,0.2
        c0,0.1-0.1,0.3-0.1,0.4c-0.2-0.1-0.4-0.1-0.4,0.2l-0.2,0l-0.3,0.2c0.2,0.1,0.5,0.1,0.8,0.3c-0.3,0.2-0.5,0.1-0.7-0.1
        c-0.3,0.1-0.5,0.2-0.8,0.3l0.2,0.3c0.1-0.1,0.3-0.2,0.4-0.2c0,0.7-0.7,0-1,0.2c0.1,0,0.2,0.1,0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.4
        l-0.2-0.1l0.1,0.2c-0.2,0.1-0.5,0.1-0.7,0.2c0.1-0.2,0.3-0.3,0.3-0.6c-0.4,0-0.6,0.3-0.9,0.5c0,0.1,0.1,0.2,0.1,0.2
        c-0.4-0.1-0.9,0.2-1.3,0.3c0-0.1-0.1-0.2-0.1-0.2c0,0.2,0,0.3-0.1,0.5c0,0.2,0,0.4-0.3,0.4c0,0.1-0.1,0.3-0.1,0.5
        c-0.3-0.3,0.4-0.7,0.1-1c-0.2,0.1-0.3,0.2-0.5,0.2c0,0.1,0.1,0.2,0.1,0.2c-0.2,0-0.5,0-0.6,0.1c0-0.5-0.5,0,0,0
        c0,0.1-0.1,0.3-0.1,0.4c0.1-0.1,0.3-0.2,0.5-0.3c0,0.1,0.1,0.2,0.1,0.3c-0.3,0.2-0.5,0-0.8-0.1c-0.4,0.2-0.9,0.3-1.2,0.7l0.3,0
        c0,0.1,0,0.2,0,0.2c-0.1-0.1-0.3-0.2-0.4-0.3l-0.3,0.2c0-0.1,0-0.4,0-0.5c-0.2,0.1-0.2,0.3,0,0.5c-0.4-0.1-0.7,0.2-0.9,0.5
        c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0.3-0.3,0.5-0.6,0.4c-0.1,0.1-0.3,0.1-0.5,0l0,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.3-0.2-0.6-0.2-0.9,0.1
        c-0.3,0.1-0.6,0.2-0.8,0.3c-0.1-0.2-0.4-0.2-0.7-0.2c0.1,0.1,0.1,0.3,0.2,0.5c-0.1-0.1-0.2-0.2-0.3-0.2c-0.6,0.1-1.2,0.5-1.9,0.3
        c-0.3,0-0.6,0.1-0.9,0.3c-0.3,0.1-0.7-0.1-1-0.1c0,0.1,0,0.3,0.1,0.4c-0.1-0.3-0.4-0.3-0.7-0.3c0,0.1,0.1,0.2,0.1,0.3
        c-0.1,0-0.3,0.1-0.4,0.1c0-0.2-0.1-0.4-0.1-0.6c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.4,0.1-0.5,0.3l-0.2-0.2c0,0.1-0.1,0.2-0.2,0.2
        c-0.2-0.2-0.4-0.3-0.6-0.5c0.1,0.4-0.3,0.4-0.5,0.6c-0.1,0-0.3,0-0.4-0.2c0.1,0,0.3,0,0.4,0.1l0-0.3l-0.3,0c0-0.1,0.1-0.2,0.1-0.2
        c-0.1,0.1-0.2,0.1-0.4,0.1c0,0.1,0,0.2,0.1,0.3c-0.2-0.2-0.4-0.3-0.6-0.3c0-0.4-0.4-0.1-0.5,0.1c-0.3-0.3-0.5,0-0.7,0.2
        c0,0,0-0.1,0-0.2c0.3,0.1,0.5-0.3,0.2-0.4c-0.2,0.3-0.6,0.3-0.9,0.4c0.1-0.2,0.2-0.3,0.3-0.5c-0.4-0.1-0.6,0.1-0.9,0.3
        c-0.2-0.3-0.4-0.7-0.8-0.6l0,0.2c0.1,0,0.3,0.1,0.4,0.2c-0.2,0-0.3,0-0.5,0c0-0.1-0.1-0.3-0.1-0.4c-0.2-0.1-0.5,0-0.7,0
        c0,0.1-0.1,0.2-0.2,0.3c-0.1-0.1-0.2-0.1-0.3-0.2c0.1,0,0.3-0.1,0.4-0.1c0-0.1-0.1-0.2-0.1-0.3c-0.2,0-0.3,0-0.5-0.1
        c0.2,0.5-0.6,0.1-0.4-0.3c-0.3,0-0.6,0.2-1,0c0-0.1,0-0.2,0-0.3c-0.4,0-0.7,0.1-1-0.2c-0.1,0-0.2,0.1-0.2,0.1
        c-0.1-0.2-0.3-0.3-0.5-0.4l-0.2,0.2c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0.3-0.1,0.5,0.1,0.8c-0.2-0.1-0.2-0.3-0.2-0.5
        c-0.2-0.1-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2c-0.2-0.5-0.4,0.3-0.1,0.4c-0.1,0-0.3,0-0.4,0l0.2,0c0-0.2,0-0.3,0-0.5
        c-0.2-0.1-0.3-0.3-0.4-0.5c-0.3-0.2-0.6-0.3-0.7-0.6c-0.2,0-0.5,0-0.7,0c-0.3-0.3-0.7-0.5-1.1-0.6c-0.3,0.1-0.5,0-0.8-0.1
        c-0.1,0.1-0.2,0.1-0.2-0.1c0.3,0.1,0.6,0.1,0.9-0.1c-0.3-0.5-0.8,0-1.2-0.3c0.1,0,0.3,0,0.4,0c-0.1-0.1-0.2-0.3-0.3-0.4
        c-0.4-0.1-0.7-0.3-1.1-0.5c-0.1,0.1-0.2,0.2-0.2,0.2c0.2-0.4-0.3-0.6-0.5-0.8c-0.1,0-0.2,0-0.4,0c0.1-0.1,0.2-0.2,0.2-0.3
        c-0.1,0-0.2,0-0.3,0c-0.4-0.3-0.8-1-1.4-0.7c-0.2-0.1-0.4-0.1-0.6-0.2c0.2-0.1,0.5-0.1,0.7-0.1c0-0.3-0.2-0.4-0.5-0.4
        c-0.1-0.2-0.3-0.4-0.4-0.5c-0.1,0.2-0.1,0.5,0.2,0.6c-0.3,0.2-0.4-0.2-0.5-0.4l-0.2,0.1c0.1-0.1,0.2-0.3,0.4-0.4
        c-0.1-0.1-0.3-0.2-0.4-0.3c0,0-0.1,0.1-0.2,0.1c0-0.6-0.4-1-0.8-1.4c-0.3,0-0.3,0.6,0,0.7c0.1,0.1,0.1,0.3,0.2,0.4
        c0.1,0,0.3,0.1,0.4,0.1c-0.3,0.1-0.7,0-0.6-0.4c-0.2,0.1-0.3-0.2-0.5-0.2c0.2-0.2,0.2-0.4,0.3-0.7c-0.3-0.3-0.7-0.5-0.8-0.9
        c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0.1-0.1,0.2-0.2,0.3c0-0.2,0-0.3,0-0.5c-0.3-0.2-0.6-0.4-0.9-0.6c0-0.2-0.1-0.3-0.2-0.2
        c-0.4,0.1-0.5-0.5-0.6-0.8c-0.1,0-0.2,0.1-0.3,0.2c-0.3-0.1-0.6-0.1-0.9-0.3c0-0.1,0.1-0.2,0.1-0.3c-0.2,0.1-0.5,0.6-0.4-0.1
        c-0.1,0.1-0.2,0.2-0.2,0.4c-0.1-0.2-0.1-0.6-0.4-0.6c0,0.1,0.1,0.4,0.1,0.5l-0.2,0c0-0.1,0.1-0.2,0.1-0.3l-0.3,0
        c0-0.1,0.1-0.2,0.2-0.3c-0.2-0.4,0.2-0.8-0.1-1.1c-0.3,0-0.4,0.4-0.7,0.6c0.1-0.3,0.6-0.6,0.4-0.9c-0.1,0-0.3,0-0.5,0
        c0,0.2,0,0.4-0.2,0.4l0.1-0.2c-0.1,0-0.2-0.1-0.2-0.1c0.1,0,0.2,0,0.3,0c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1,0-0.3,0-0.4,0.1
        c0.1-0.3,0.8-0.5,0.4-0.8c0-0.6,0.3-1.2,0.5-1.8c0-0.5-0.3-0.9-0.2-1.4c0-0.5-0.6-0.8-1-0.8l0,0c0.2,0,0.3,0,0.5,0
        c-0.2-0.4-0.3-0.9-0.7-1.2l0.3,0.1l-0.1-0.4c-0.1,0-0.2,0-0.3,0l0.2,0c-0.1-0.3-0.2-0.5-0.1-0.8c-0.3-0.5-0.3-1-0.6-1.5
        c-0.1,0-0.2,0.1-0.3,0.1c0.1-0.3,0.1-0.7,0.2-1l-0.2,0.1c0,0.1,0,0.3,0,0.4c-0.3-0.2,0-0.6,0.3-0.6c-0.1-0.2-0.2-0.3-0.4-0.3
        c0,0,0.1-0.1,0.1-0.1c0.2-0.6-0.2-0.1-0.4-0.1c0.1-0.1,0.2-0.3,0.3-0.5c-0.1,0-0.3,0-0.4,0c0.1,0,0.2-0.1,0.2-0.1c0-0.1,0-0.3,0-0.4
        c-0.2-0.1-0.4-0.1,0-0.2c-0.1-0.2-0.1-0.6-0.4-0.6c0,0,0-0.1,0-0.2c0.1,0,0.2,0.1,0.3,0.2c0-0.5-0.4-1-0.2-1.4
        c-0.2-0.2-0.3-0.5-0.4-0.8c0.1-0.1,0.2-0.2,0.3-0.2c-0.1-0.3-0.3-0.5-0.4-0.8c-0.1,0-0.2,0-0.2,0c0.1-0.2,0.2-0.4,0.3-0.6
        c-0.1-0.1-0.3-0.1-0.4-0.2c0.1,0,0.3,0.1,0.5,0.1c0-0.4-0.3-0.3-0.6-0.4c0.1,0,0.3,0,0.3,0c0.1-0.5-0.2-0.8-0.2-1.3
        c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0.1-0.2,0.1-0.3c-0.2-0.7,0-1.3-0.1-2c-0.1,0.1-0.2,0.2-0.3,0.3
        c0.1-0.3,0.3-0.6,0.2-0.9c-0.1-0.4-0.1-0.9,0.1-1.3c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.5,0.3-1,0-1.4c0.2-0.3,0.2-0.6,0-0.9
        c-0.1,0.1-0.3,0.1-0.3,0.2c0.1,0,0.3,0.1,0.4,0.1c-0.4,0.2-0.6,0.6-1,0.8c-0.2,0.1-0.4-0.1-0.5,0.2c0.1,0,0.3,0.1,0.5,0.2
        c0.1-0.1,0.3-0.2,0.4-0.3c0.1,0.2,0.1,0.4,0.2,0.6c-0.1-0.1-0.3-0.1-0.4-0.2c0,0.1,0,0.4-0.1,0.5l0.2-0.1c0,0.3,0,0.6-0.4,0.4
        c0.1,0.2,0.2,0.4,0.3,0.6c0,0-0.1,0-0.2,0c-0.1,0.2-0.3,0.4-0.5,0.4c0.1-0.3,0.3-0.4,0.6-0.4c-0.1-0.2-0.2-0.3-0.4-0.4
        c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.3-0.3-0.5,0c-0.2,0.1,0.1,0.3,0.2,0.3c0,0.3,0.2,0.4,0.4,0.6c-0.4-0.2-0.8,0-1,0.4
        c0.1,0,0.4,0,0.5,0c0.2-0.1,0.3-0.1,0.5-0.2c-0.1,0.1-0.1,0.2-0.2,0.4c0,0-0.1,0-0.2-0.1l0,0.4l0.2,0c0,0.1,0,0.2,0,0.3l-0.1,0v-0.2
        c-0.1,0-0.3,0-0.4,0c0.1,0.3,0,0.5-0.3,0.7c0-0.1,0.1-0.4,0.1-0.5c-0.1,0-0.2,0-0.3,0l0.1-0.2c-0.2,0.2-0.4,0.4-0.5,0.6
        c0.1-0.1,0.3-0.2,0.4-0.3c-0.1,0.3,0.3,0.6,0.1,0.8c-0.3,0.2,0,0.4-0.1,0.7c-0.3,0-0.5-0.2-0.2-0.5c0.1-0.2-0.2-0.2-0.3-0.3
        c0,0.1,0,0.3,0,0.4l-0.3,0c0.1,0.1,0.3,0.2,0.4,0.3c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.3,0,0.4
        c0,0-0.1,0.1-0.2,0.2l-0.1,0.2c-0.2-0.2-0.3-0.4,0-0.5c-0.1,0-0.3-0.1-0.4-0.1c0.1,0,0.2,0.1,0.2,0.2c-0.1,0.1-0.2,0.3-0.2,0.4
        c0.2,0,0.4,0,0.5,0.2c0-0.1,0-0.2,0-0.3c0.2-0.1,0.5-0.1,0.7-0.1c-0.2,0.2-0.3,0.6-0.7,0.6c0.2,0.3-0.4,0.4-0.2,0.7
        c0.1,0,0.2,0,0.3,0c0,0.1-0.1,0.2-0.1,0.3c0-0.1-0.1-0.2-0.1-0.2c0,0-0.1,0-0.2,0l0.2,0.3c-0.3-0.1-0.4,0.1-0.4,0.4
        c0.2,0,0.4,0.2,0.5,0.3c0,0.1-0.1,0.2-0.2,0.3c0-0.1-0.1-0.3-0.1-0.4c0,0.3-0.3,0.4-0.5,0.3c0,0-0.1,0.1-0.1,0.1
        c0.4,0.1,0.2,0.6,0,0.7c0.4,0.1,0.2,0.3-0.1,0.3l0,0.2c0.2-0.1,0.3-0.3,0.6-0.2c0-0.2-0.2-0.3-0.4-0.3c0.2-0.2,0.4-0.4,0.4-0.7
        c0.1,0.1,0.2,0.3,0.2,0.3c0.1-0.1,0.2-0.3,0.2-0.4c0.1,0.1,0.1,0.2,0.2,0.2c-0.2,0.1-0.4,0.2-0.6,0.2c0.1,0.2,0.1,0.4,0.2,0.7
        c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0-0.2-0.1-0.3-0.1c0,0.1,0,0.2,0,0.2c0.3-0.2,0.4,0.2,0.6,0.3c-0.1,0.1-0.2,0.2-0.4,0.3
        c0.1,0.2,0.3,0.3,0.5,0.4c-0.1,0.1-0.3,0.2-0.4,0.2l0.3,0.1c0,0.2,0,0.3,0,0.5c-0.2,0.1-0.3,0.2-0.5,0.3c0,0,0-0.2,0-0.2
        c0.1-0.1,0.3-0.2,0.4-0.3l-0.4-0.1c0.2-0.2,0.4-0.5,0.1-0.6c0-0.2,0-0.4,0-0.6c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.2,0.1,0.3,0.2,0.5
        c-0.1,0.2-0.3,0.4-0.4,0.7c-0.1,0-0.3,0-0.4,0c0.1,0.1,0.2,0.1,0.4,0.1c0.1,0.2,0.2,0.3,0.4,0.5c0,0,0,0.1,0,0.1
        c0.1,0.1,0.2,0.2,0.4,0.2c0,0,0,0.1,0,0.1c-0.1,0-0.3,0-0.4,0c-0.1,0.2-0.3,0.4-0.4,0.6c0.1,0,0.3,0,0.4,0c-0.1,0.1-0.2,0.3-0.3,0.4
        c0.1,0.4,0.1,0.8,0.1,1.2c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.2,0.3,0.1,0.4,0.2c0,0,0,0,0,0.1c-0.1,0-0.2,0-0.3,0
        c0.1,0.2,0.2,0.5,0.2,0.8c-0.2-0.1-0.3-0.3-0.3-0.6c-0.1,0.1-0.2,0.2-0.4,0.3c0-0.6-0.5-0.4-0.3,0l-0.2,0c0,0.1,0.1,0.3,0.1,0.3
        c0.3,0.1,0.1-0.3,0.2-0.5c0.3,0.1,0.5,0.3,0.6,0.6c-0.2,0-0.3,0-0.5,0.1c0.4,0.1,0.4,0.5,0.4,0.9c0.1,0.2,0.2,0.4,0.3,0.6
        c0,0.1-0.1,0.3-0.2,0.4l-0.2-0.1c0,0.1,0.1,0.2,0.2,0.3c-0.1,0.2-0.2,0.3-0.3,0.4c0.1,0,0.3-0.1,0.4-0.2c-0.1,0.2-0.1,0.5,0,0.7
        l-0.1,0c0.1,0.2,0.1,0.4-0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.3c0-0.1,0-0.2,0-0.3c0.3-0.2,0.1-0.7,0.2-1.1c0.2,0.2,0.4,0.3,0.6,0.5
        c-0.1,0-0.4,0-0.5,0c0.1,0.2,0.2,0.3,0.4,0.4c-0.1,0.2-0.2,0.3-0.3,0.5c0.3,0.3-0.2,0.4-0.2,0.6c0.2-0.1,0.4-0.3,0.6-0.4l-0.1,0.3
        c0,0.1,0.1,0.2,0.1,0.3c-0.1,0-0.3,0.1-0.4,0.1c0.1,0.1,0.3,0.2,0.5,0.2c-0.1-0.3,0.2-0.4,0.1-0.7c0.1-0.3-0.2-0.4-0.4-0.6
        c0.1-0.1,0.3-0.2,0.4-0.3c0,0.1-0.1,0.2-0.1,0.4c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.2,0.1,0.5-0.1,0.7c-0.1,0.2-0.1,0.7,0.3,0.6
        c0,0.1,0,0.2,0,0.3c0,0,0.2,0,0.2,0c0,0.1,0,0.2,0,0.2l0.2,0c-0.2,0.3-0.1,0.6,0.3,0.8c-0.1,0.1-0.1,0.2-0.2,0.3
        c-0.1-0.1-0.3-0.3-0.4-0.4c0,0-0.1,0.1-0.2,0.1c0.4,0.3,0.6,0.8,1.1,0.8c-0.1,0.2-0.2,0.5-0.3,0.7c0.1-0.1,0.4-0.3,0.6-0.4
        c0,0.2-0.1,0.3-0.2,0.4c0,0.2-0.1,0.4-0.2,0.5c0.3,0.2,0.6,0.4,0.9,0.5c0,0.1,0,0.3,0.1,0.4c-0.1,0-0.2,0.1-0.2,0.1l0.2,0.1l0.1,0.3
        c-0.2-0.1-0.3-0.1-0.5-0.2c0.1,0.2,0.3,0.3,0.5,0.5c0.1,0.2,0,0.6,0.3,0.6c-0.2-0.2-0.2-0.7,0.1-0.4c0.2,0.3-0.1,0.5-0.3,0.8
        c0.1-0.1,0.3-0.2,0.3-0.2c0.1,0.1,0.2,0.1,0.3,0.2c0-0.1,0.1-0.2,0.2-0.3c0.4,0.3,0.2,0.8-0.2,1c0-0.1,0-0.4,0-0.6
        c-0.1,0-0.2,0-0.2,0c0,0.2,0,0.3,0.1,0.5c0.4,0.1,0.6,0.5,0.9,0.8c0-0.2,0-0.6-0.3-0.5c0-0.2,0-0.4,0-0.6c0.1,0.1,0.2,0.2,0.3,0.3
        c0.1,0,0.2,0,0.3,0c-0.1,0.1-0.2,0.3-0.2,0.4c0.1,0,0.3,0,0.3,0c0,0.1,0,0.2-0.1,0.3c0,0.1,0.1,0.2,0.1,0.2c0.5,0,0.6,0.6,0.9,0.9
        c-0.2,0-0.4,0-0.6,0.1c0,0,0,0.1,0,0.1c0.2,0,0.3,0,0.5,0c0.1,0,0.3-0.1,0.4-0.2c0,0.2-0.1,0.3-0.1,0.5c0.3,0.1,0.5,0.2,0.7,0.4
        c0,0.1,0,0.4,0,0.5l0.2,0c0-0.1,0-0.3,0-0.4c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.2c-0.1,0-0.3-0.1-0.4-0.2
        c-0.2,0.5,0.2,0.6,0.6,0.5c0,0.3,0.3,0.5,0.5,0.8c0,0.1,0,0.3-0.1,0.4c0.1-0.1,0.2-0.3,0.3-0.4c0,0.1,0,0.3,0,0.3
        c0.4,0,0.6,0.4,0.9,0.6c0.1,0.3,0.4,0.4,0.7,0.5c0,0.1,0,0.3,0,0.4c0.1,0,0.3-0.1,0.4-0.1c0.1,0.2,0.2,0.3,0.3,0.5l-0.2,0
        c0,0,0,0.1,0,0.1c0.2,0,0.4-0.1,0.6-0.2c0.3,0.2-0.1,0.5-0.2,0.6c0,0,0.1-0.1,0.2-0.2c0.2,0.1,0.4,0.2,0.6,0.3
        c-0.1-0.2-0.2-0.4-0.4-0.5c0.2,0,0.4,0,0.5,0c-0.1,0.1-0.1,0.2-0.2,0.3c0.2,0.2,0.4,0.8,0.7,0.5c0.1,0.2,0.2,0.3,0.4,0.4
        c0.2,0,0.8,0.3,0.3,0.4l0,0.1c0.1,0,0.3,0,0.4-0.1c0.2,0.2,0.4,0.2,0.7,0.3c-0.1,0.2,0,0.4,0.2,0.4c0.1-0.1,0.2-0.3,0.3-0.4
        c0.2,0,0.3,0.2,0.4,0.3c-0.1,0-0.2,0.1-0.3,0.1c0,0.1,0,0.2,0,0.3c0.1,0,0.2,0,0.3,0l0,0.2c0-0.1,0.1-0.3,0.1-0.4
        c0.2,0.1,0.4,0.3,0.6,0.5c0,0.1-0.1,0.2-0.2,0.2c0.3,0.1,0.7,0.1,0.8,0.4c0-0.1,0.1-0.3,0.1-0.4l0.2,0.1l-0.2,0.3l0.2,0
        c0.1,0.1,0.2,0.1,0.2,0c0.3,0.1,0.6,0.5,0.8,0.1c0.2,0,0.3-0.1,0.5-0.1c-0.1,0.2-0.3,0.2-0.4,0.3c0.2,0.1,0.4,0.2,0.7,0.3
        c0-0.1-0.1-0.3-0.1-0.4c0.3,0.2,0.7,0.3,1,0.5l-0.2,0c0,0.1,0,0.2,0,0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0.6,0.8,0.4,1.1,0.2l0.1,0.1
        c-0.5,0.1-0.1,0.6,0.2,0.6c-0.1-0.1-0.2-0.3-0.3-0.4c0.1-0.1,0.3-0.2,0.4-0.2c0.1,0.1,0.2,0.4,0.2,0.5c0.1-0.1,0.2-0.2,0.2-0.3
        c0.1,0.1,0.3,0.2,0.3,0.2c0.1-0.1,0.3-0.1,0.4-0.2c0.1,0.1,0.3,0.2,0.5,0.3c-0.3,0.1-0.3,0.3,0,0.3c0-0.1,0-0.3,0-0.4
        c0.2,0.3,0.4,0.6,0.7,0.8c0-0.3,0-0.7-0.4-0.6c0.1-0.5,0.7,0.1,1,0c-0.1,0.1-0.2,0.3-0.2,0.5c0.2,0,0.2-0.2,0.3-0.4
        c0.4,0.1,0.8,0.1,1.2,0.1c-0.1,0.1-0.1,0.3-0.2,0.4c0.2-0.2,0.5-0.3,0.8-0.2c0,0.1,0,0.3,0.1,0.4c0.2-0.1,0.4-0.2,0.3-0.4
        c0.1,0.1,0.3,0.2,0.4,0.4c0.3-0.2,0.7-0.3,1-0.3l0,0.3c-0.1,0-0.2,0-0.3,0c0,0-0.1,0.1-0.1,0.2c-0.1-0.1-0.2-0.2-0.3-0.2
        c0,0.1-0.1,0.2-0.1,0.3c0.3,0.1,0.7,0.2,0.8-0.2c0.3-0.1,0.7,0,0.5,0.4c0.2,0.1,0.4,0.2,0.6,0.3l-0.1,0.2c0.1-0.1,0.2-0.2,0.3-0.4
        c0-0.1-0.1-0.2-0.2-0.2c-0.1,0.2-0.4,0.2-0.5,0c-0.2-0.4,0.5-0.1,0.5-0.4c0,0.1,0.1,0.3,0.2,0.4c0.2-0.1,0.5-0.2,0.7-0.3
        c0.3,0.1,0.6,0.4,0.8,0.7c0.1-0.2,0.2-0.3,0.3-0.5c0.2,0,0.5-0.1,0.5,0.3c0.2,0,0.4,0.5,0.6,0.2c-0.1-0.1-0.2-0.2-0.3-0.2
        c0.1-0.2,0.1-0.4,0.1-0.7c0.2,0.1,0.3,0.5,0.7,0.4c0.4-0.1,0,0.3-0.1,0.4c0.2,0,0.4,0,0.5,0c0,0,0-0.1,0-0.1
        c0.2-0.2,0.3-0.3,0.5-0.5c0,0.1,0,0.4,0,0.5c0.4,0.1,0.9,0.2,1.3-0.1c-0.2-0.2-0.3-0.3-0.4-0.5l0.2,0c0.1,0.2,0.3,0.5,0.6,0.4
        c0-0.1,0-0.3-0.1-0.4c0.4,0.3,0.9,0.1,1.4,0.3c0-0.2,0.1-0.6,0.3-0.3c-0.1,0.1-0.2,0.2-0.3,0.2c0.1,0.5,0.6,0,0.5-0.3
        c0,0.1,0.1,0.2,0.2,0.3c0.1-0.2,0.2-0.3,0.4-0.5c-0.1,0.2-0.2,0.4-0.2,0.5c0.1,0,0.3,0,0.4,0.1l0-0.3c0.1,0.1,0.3,0.1,0.4,0.2
        c0.2-0.1,0.4-0.3,0.7-0.4c0,0.1,0.1,0.2,0.1,0.3c0.3,0,0.6-0.2,0.9-0.3c0-0.1-0.1-0.2-0.2-0.3c-0.2,0.1-0.4,0.2-0.7,0.3
        c-0.1-0.1-0.2-0.2-0.2-0.3c0.1,0,0.3,0.1,0.3,0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.3,0,0.4,0c0.1,0.1,0.2,0.2,0.2,0.3
        c0.2,0,0.3,0.1,0.5,0.1c0.1-0.2,0.3-0.4,0.5-0.6c0,0.1,0,0.3,0.1,0.4c0.1,0,0.4,0,0.5,0c0-0.1,0-0.3,0-0.4c-0.1,0-0.2,0.1-0.2,0.2
        c0-0.1,0-0.3,0-0.4c0.2,0.1,0.4,0.4,0.6,0.5c0.2-0.1,0.3-0.2,0.5-0.3c-0.1-0.2,0.1-0.5,0.3-0.3c0,0.1-0.1,0.2-0.2,0.3
        c0.2,0.1,0.6-0.2,0.8,0.1c0.1-0.2,0.3-0.4,0.4-0.5c0,0.1,0,0.3,0,0.4c0-0.1,0-0.3,0-0.5c0.2,0.1,0.3,0.4,0.5,0.2
        c0.2-0.3-0.4-0.2-0.4-0.5c0.2,0,0.4,0.1,0.6,0.2c0.1,0.1,0.3,0.2,0.5,0.3c-0.2-0.2-0.4-0.3-0.4-0.6c0.2,0.1,0.3,0.2,0.6,0.2
        c-0.1,0.5,0.3,0.2,0.3-0.1c0.2,0,0.4,0.1,0.6,0.1c-0.2-0.1-0.3-0.2-0.5-0.3c0.4,0.1,0.6,0,0.5-0.4c0,0,0.1,0.1,0.1,0.2
        c0.2-0.2,0.5-0.3,0.7-0.3c0,0.1,0.1,0.2,0.1,0.2l0.1,0.1c0.3-0.2,0.7-0.2,1-0.2c0.1-0.1,0.2-0.3,0.3-0.4c-0.3,0.1-0.8,0.4-0.9,0
        c0.3,0.1,0.6,0,0.8-0.2c0.2,0.2,0.4,0.4,0.5,0c0.1,0.1,0.2,0.3,0.3,0.5c0.1-0.3,0.2-0.7,0.3-0.9c0,0.2,0,0.4,0,0.6
        c0.1-0.2,0.3-0.3,0.4-0.4c-0.1-0.4,0.3-0.3,0.6-0.4c0-0.1,0-0.2-0.1-0.3c0,0.1,0.1,0.2,0.1,0.2c0.2,0,0.4,0,0.6,0c0,0.1,0,0.3,0,0.3
        c0,0,0.1,0,0.2,0c0-0.1,0-0.2,0-0.3c-0.1-0.1-0.2-0.2-0.3-0.4c0.2-0.1,0.5-0.1,0.7,0c0.1,0,0.3-0.1,0.4-0.2c-0.1,0-0.2-0.1-0.3-0.2
        c0.4-0.4,1-0.5,1.5-0.7c0,0.1,0,0.2,0,0.3c0.1,0,0.3,0,0.4,0.1c0-0.1,0-0.4,0-0.5c0.1,0.1,0.2,0.2,0.3,0.2c0-0.3,0.3-0.5,0.6-0.5
        c0-0.1,0.1-0.2,0.1-0.3c0.2-0.1,0.5-0.2,0.7-0.3c0.1-0.1-0.1-0.2-0.2-0.3c0.5,0.1,0.7-0.3,0.9-0.6c0.1,0,0.2,0,0.3-0.1l0,0.1l-0.2,0
        c0,0.1,0,0.3,0.1,0.3c-0.2,0.1-0.4,0.2-0.4,0.4c0.2-0.1,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.2,0.2-0.2c-0.1-0.3,0.2-0.5,0.5-0.5
        c0-0.2,0.3-0.3,0.4-0.1c0.2-0.1,0.4-0.2,0.6-0.3c0.1-0.2,0.1-0.3,0.2-0.5c0.1,0,0.3,0,0.4,0c-0.1-0.4,0.3-0.7,0.6-0.7
        c0.1-0.2,0.3-0.3,0.4-0.5c0.2,0,0.3-0.2,0.5-0.4c0.1,0.1,0.2,0.2,0.3,0.3l0-0.4c-0.1,0-0.2,0.1-0.3,0.1c0.1-0.1,0.2-0.1,0.4-0.2
        c0-0.1,0.1-0.2,0.1-0.3c0.3-0.1,0.7-0.1,0.6-0.6c0.1,0,0.3,0.1,0.4,0.2c0,0.1-0.1,0.2-0.1,0.3c0.1-0.1,0.2-0.1,0.4-0.2
        c-0.1-0.2-0.3-0.3-0.4-0.5c0.2-0.1,0.3-0.2,0.5-0.2c0-0.1-0.1-0.3-0.1-0.4c0,0,0.1,0,0.2,0l0,0.3c0.3-0.1,0.5-0.4,0.5-0.7
        c0.3-0.2,0.6-0.4,1-0.4c-0.1-0.1-0.3-0.3-0.4-0.4c0.2,0,0.3,0.1,0.4,0.2c0-0.1,0.1-0.4,0.2-0.5c0.1,0,0.3,0.1,0.4,0.2
        c0.1-0.2,0.1-0.4,0.1-0.5c0.2-0.3,0.8-0.3,0.7-0.8c0.2,0.1,0.4,0.2,0.6,0.2c-0.1-0.1-0.1-0.3-0.1-0.4c0.1-0.2,0.3-0.3,0.4-0.4
        c0.2-0.3,0.5-0.5,0.8-0.6c-0.1-0.1-0.2-0.2-0.2-0.3c0.2-0.3,0.8-0.2,0.7-0.7c0-0.3,0.4-0.3,0.5-0.4c-0.2-0.5,0.5-0.7,0.6-1.1
        c0.3-0.3,0.5-0.6,0.8-1c0,0.1,0.1,0.3,0.2,0.3c-0.1-0.4,0.4-0.6,0.4-1c0.1,0,0.3-0.1,0.4-0.1c-0.1-0.3,0.1-0.7,0.4-0.7
        c0.3-0.6,0.7-1.2,1.1-1.7c0.2-0.1,0.2-0.3,0.2-0.5c0.1-0.1,0.4-0.1,0.4-0.3c-0.1-0.4,0.3-0.5,0.6-0.7c0.1-0.3,0-0.8,0.4-0.8
        c0-0.2,0.1-0.3,0.3-0.3c0-0.2,0-0.4,0.3-0.4c0.3-0.4,0.4-0.9,0.8-1.3c0-0.2,0.1-0.4,0.1-0.6c0.2-0.1,0.3-0.2,0.5-0.3
        c0-0.1-0.1-0.3-0.1-0.4c0.7-0.3,0.8-1.1,1.2-1.6c-0.1-0.3,0.3-0.5,0.4-0.7c0.2-0.4,0.1-0.9,0.5-1.2c0.2-0.2,0.1-0.5,0.2-0.7
        c0.6-0.3,0.3-1.2,0.9-1.7c0.1-0.6,0.3-1.1,0.7-1.6c0-0.1,0-0.3,0-0.4c0.1,0,0.2-0.1,0.3-0.2c0.4-1.2,0.8-2.3,1.4-3.4
        c0.3-1,0.6-2,1-2.9c0-0.4,0.2-0.7,0.3-1c0-0.3,0.2-0.5,0.3-0.7c0.1-0.3,0-0.7,0.1-1c0.2-0.3,0.4-0.7,0.3-1c-0.1-0.4,0.4-0.6,0.3-1
        c-0.2-0.7,0.5-1.4,0.3-2.1c0.2-0.5,0.4-0.9,0.3-1.4c0.1-0.4,0.1-0.8,0.1-1.3c0.1-0.5,0.6-1,0.2-1.5c0.1-0.2,0.2-0.4,0.4-0.5
        c-0.2-0.5,0-0.9,0-1.4c0.1-0.4,0-0.9,0.4-1.3c-0.1-0.2-0.2-0.6-0.4-0.2c-0.2,0-0.3-0.1-0.5-0.2c-0.1,0-0.3-0.1-0.4-0.2
        c-0.2,0.4-0.6-0.1-0.6-0.4c0.1,0.1,0.2,0.2,0.3,0.3c0-0.1,0.1-0.2,0.2-0.3c0.1,0,0.2,0.1,0.3,0.2c0-0.1,0-0.2-0.1-0.3l0.1,0
        c0.2-0.1,0.5-0.6,0.5-0.1c0,0,0.1,0.1,0.1,0.1c0-0.2-0.1-0.5-0.1-0.7l0.3,0.2c-0.1-0.2,0-0.4,0.1-0.6c-0.2-0.6-0.7-1.1-1-1.7
        c-0.1-0.3-0.6-0.4-0.9-0.6c0-0.1,0-0.2,0-0.2c-0.1,0-0.2,0-0.2,0c-0.2-0.3-0.6-0.2-0.7-0.6c0,0,0.1,0.1,0.2,0.1c0.1,0,0.4,0,0.5,0
        c0.2,0.1,0.3,0.1,0.5,0.2c0-0.1,0.1-0.2,0.1-0.2l0,0.3c0.2-0.1,0.5-0.3,0.7-0.4l0,0.2c0.1-0.2,0.3-0.3,0.4-0.4
        c-0.1-0.1-0.2-0.2-0.2-0.2l-0.1,0.4c-0.1-0.1-0.1-0.2-0.2-0.2c0.1-0.1,0.3-0.2,0.5-0.3c-0.2-0.4,0.3-0.3,0.5-0.3
        c-0.1,0-0.3-0.1-0.4-0.1l0.4-0.4l-0.3,0.1c0-0.1,0-0.3,0-0.4c-0.7,0.3-1.5,0.4-2.2,0.3c-0.7,0.2-1.4-0.2-2.1-0.1
        c-0.6-0.2-1.3-0.3-1.9-0.3c0.3-0.1,0.7-0.1,1,0l0-0.1c0.3,0,0.7,0.1,1-0.1c0.2-0.1,0.4-0.2,0.6-0.3c0,0.2-0.1,0.5-0.1,0.6
        c0.1-0.2,0.2-0.4,0.2-0.6c0.1,0.1,0.2,0.4,0.3,0.5c0-0.1,0.1-0.2,0.1-0.3c0.1,0,0.4,0,0.5,0.1c-0.2-0.1-0.5-0.2-0.7-0.4
        c0.4-0.1,0.7-0.1,1.1,0c0-0.1-0.1-0.3-0.1-0.4c0.2,0,0.4,0.1,0.6,0.2c0.1-0.1,0.2-0.2,0.4-0.2c-0.1-0.1-0.1-0.3-0.2-0.5
        c0.3,0,0.5,0,0.8,0c0,0.1,0.1,0.2,0.2,0.2c0,0,0.1-0.1,0.1-0.2c-0.1,0-0.2-0.1-0.3-0.1l-0.2-0.2c0.6,0.3,0.6-0.5,1.1-0.6
        c-0.1,0.3-0.4,0.6-0.3,0.9c0.3-0.2,0.3-0.6,0.5-0.9c0.2,0,0.3,0.1,0.5,0.1c-0.1-0.2-0.4-0.2-0.6-0.2c-0.1-0.1-0.2-0.2-0.3-0.3
        c0.3,0.1,0.6,0,0.6-0.4c0.1,0.1,0.2,0.3,0.3,0.3c0.2-0.1,0.2-0.4,0.2-0.6c0,0.1-0.1,0.2-0.1,0.3c-0.1,0-0.3-0.1-0.4-0.1
        c0.3-0.2,0.4-0.5,0.5-0.9c-0.1-0.2-0.2-0.4-0.2-0.7c-0.4,0-0.9,0-1.3,0c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.3,0.1-0.5,0.1
        c-0.1-0.3-0.6-0.2-0.8-0.5c0.2-0.1,0.4-0.1,0.7-0.1l0-0.1c0.3,0,0.5-0.1,0.8-0.2c0.1,0.4,0.5,0,0.5-0.2c0.2-0.1,0.3-0.2,0.5-0.3
        c0.2-0.3,0.4-0.5,0.4-0.8c-0.1,0-0.2-0.1-0.3-0.1c0.1,0,0.3,0,0.4,0c-0.2-0.6-0.9-0.4-1.2-0.7c-0.1-0.1-0.2-0.2-0.3-0.3
        c-0.2,0-0.5,0-0.7,0c-0.7-0.2-1.3-0.7-2-0.7c0.3-0.1,0.6-0.1,1-0.1l0-0.1c0.2,0.1,0.5,0,0.7-0.1l0-0.2c0,0.1,0,0.2,0,0.2
        c0.3-0.2,0.7-0.2,1.1-0.2c0.2-0.2,0.8,0,0.6-0.4c-0.1,0-0.2,0.1-0.3,0.1c0.1-0.1,0.2-0.1,0.3-0.2c0,0.1,0,0.3,0,0.4
        c0.2-0.1,0.4-0.2,0.4-0.5c0.1,0,0.2,0,0.2,0.1c0.1-0.3,0.1-0.5,0-0.8c0.1,0.1,0.3,0.2,0.4,0.3c-0.1-0.3-0.2-0.5-0.3-0.8
        c-0.2-0.2-0.5-0.4-0.8-0.6c-0.3,0.1-0.5,0.1-0.8,0.2c-0.6-0.2-1.2-0.4-1.7-0.6c-0.7-0.4-1.2-1-1.9-1.4c0.7,0.2,1.2,0.8,1.9,0.8
        l0,0.2c0-0.1,0.1-0.2,0.1-0.3c0.1,0,0.3,0.1,0.4,0.2c0.1,0,0.3,0,0.4,0l0-0.1c-0.1,0-0.4,0-0.5,0.1c0-0.1,0-0.2-0.1-0.2
        c0.3,0,0.6-0.1,0.8-0.3c-0.1,0.1-0.2,0.4-0.3,0.5c0.1,0,0.3-0.1,0.4-0.2l0.1-0.1c0.2,0,0.3-0.1,0.4-0.3c-0.1,0-0.3,0-0.3-0.1
        c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0.1,0.3,0.2,0.3,0.3c0-0.2-0.2-0.6,0.1-0.7c0.1-0.1,0.2-0.4,0.2-0.5c-0.1,0-0.3,0-0.4,0.1
        c0,0,0-0.1,0-0.1c0,0,0.1,0.1,0.2,0.1l-0.1-0.3c0.1,0,0.3,0.1,0.4,0.1c-0.4-0.7-1-1.1-1.6-1.6c-0.3,0-0.5-0.1-0.8-0.1
        c-0.1,0.1-0.2,0.2-0.3,0.4c-0.3-0.1-0.6-0.2-0.9-0.2c-0.4,0.2-0.8,0-1.2,0.1c-0.2,0.1-0.4-0.1-0.6-0.2c-0.1,0-0.2,0.1-0.3,0.1
        c-0.3-0.3-0.8-0.1-1.1-0.3c-0.5-0.3-1.2-0.3-1.7-0.5c-0.5-0.1-1-0.3-1.5-0.3c-0.3-0.2-0.6-0.3-1-0.2c-0.4-0.3-0.9-0.1-1.4-0.3
        c0.5,0.1,1-0.1,1.5,0.2c0.3-0.2,0.6,0,0.9,0.2l0-0.2c0.3,0,0.5,0.1,0.8,0.1c0.4-0.3,0.7,0.3,1,0.5c0,0,0-0.1,0-0.2
        c0.1,0,0.4,0.1,0.6,0.1l-0.1-0.2c0.3,0,0.5,0,0.8,0c0.2,0,0.4-0.2,0.6,0c0.2,0,0.4,0.1,0.6,0.1l0-0.1l-0.3-0.1c0.3,0,0.5,0,0.8,0
        c0-0.2,0.2-0.3,0.4-0.4c-0.1,0.2-0.2,0.3-0.3,0.5c0.2,0,0.4,0.1,0.6,0.2c0,0,0-0.1,0-0.2c-0.1,0-0.4,0-0.5,0c0-0.4,0.4-0.3,0.7-0.2
        c0.3-0.1,0.5,0,0.8,0.1c-0.3-0.3-0.7-0.2-1-0.3l0-0.1c0.3,0,0.7,0,1-0.1l-0.1,0.2c0.1,0.1,0.3,0.2,0.4,0.3c0-0.1-0.1-0.3-0.2-0.4
        l0.3,0l0-0.2c0.3-0.2,0.7-0.1,1,0.1c-0.1-0.1-0.3-0.2-0.4-0.2c0.1-0.2,0.3-0.3,0.4-0.5c0,0.2,0.1,0.3,0.3,0.3
        c-0.1-0.2-0.4-0.7,0.1-0.7c0,0.1,0,0.3,0.1,0.4c0.1-0.3,0.3-0.6,0.5-0.8c0-0.3,0.2-0.3,0.4-0.3c0-0.1,0-0.4,0-0.5
        c0.1,0,0.2,0.1,0.3,0.1c0-0.3,0-0.5-0.2-0.7c0.1,0,0.3,0,0.4,0c-0.2-0.2-0.3-0.5-0.1-0.7c-0.1,0-0.2-0.1-0.3-0.2l0.3,0
        c0-0.1-0.1-0.3-0.2-0.4c-0.5,0.1-1.1,0.3-1.6,0.4c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.4,0.1-0.6,0.1c-0.1,0-0.2-0.1-0.3-0.2
        c-0.9,0.3-1.8-0.4-2.8-0.3c-0.2-0.1-0.4-0.4-0.6-0.2c-0.1,0-0.3-0.1-0.3-0.1c-0.2,0-0.9-0.2-0.4-0.4c0.1,0.1,0.2,0.2,0.2,0.2
        c0-0.1,0.1-0.2,0.1-0.3c0.5,0,1-0.1,1.6,0c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.3,0,0.4,0c0-0.1,0-0.2,0-0.3c0.1,0,0.3,0.1,0.4,0.2l0-0.3
        c0.3,0,0.7,0,1,0c-0.1-0.2-0.2-0.3-0.3-0.5c0.1,0,0.2,0.1,0.3,0.2c0.1,0,0.3-0.1,0.4-0.1c-0.1-0.1-0.2-0.3-0.3-0.4
        c0.2,0.1,0.3,0.2,0.5,0.3c0.2-0.1,0.5-0.2,0.6-0.4c0.1,0.2,0.2,0.4,0.3,0.6c0.3-0.2,0.6-0.3,1-0.4c-0.1,0-0.3-0.1-0.4-0.2
        c-0.1,0.1-0.3,0.3-0.4,0.4c-0.4-0.2-0.1-0.5,0.1-0.8c0.5,0.3,1.1-0.1,1.4-0.5c-0.3,0.1-0.5,0.2-0.7,0.3c-0.2-0.1-0.4-0.1-0.6-0.2
        c0.1,0,0.2-0.1,0.3-0.2c0.1,0.1,0.2,0.1,0.3,0.2c0-0.1,0-0.3,0.1-0.4c0.2-0.2,0.8,0.2,0.5-0.4l0.2,0.1c0-0.1,0-0.3,0-0.4l0.2,0
        c0-0.1,0-0.2,0-0.3l-0.3,0l0.1-0.2c-0.1,0-0.3,0-0.4,0c0-0.3,0.4-0.4,0.6-0.2c0.1-0.1,0.4-0.2,0.5-0.2c-0.1,0.2-0.2,0.3-0.2,0.5
        c0.2-0.1,0.3-0.1,0.5-0.2c-0.3-0.1-0.2-0.4-0.3-0.6c-0.1,0-0.3,0-0.4,0c0.1-0.2,0.2-0.3,0.3-0.4c0.2,0.1,0.3,0.2,0.5,0.3
        c0-0.2,0-0.4,0-0.6c0,0.1-0.1,0.3-0.1,0.4c-0.1,0-0.3-0.1-0.4-0.1c0.1-0.2,0.4-0.4,0.4-0.7c-0.2,0.2-0.3,0.3-0.5,0.5
        c0.1-0.3,0.1-0.5-0.2-0.6c0-0.1,0.1-0.2,0.1-0.2c-0.2-0.1-0.3-0.3-0.3-0.5c-0.1,0.1-0.3,0.3-0.4,0.4c0-0.2,0.1-0.3,0.1-0.5
        c0-0.1,0-0.3,0-0.4c-0.2,0.1-0.4,0.2-0.6,0.2c0-0.1,0-0.3,0-0.4c-0.3,0-0.5,0.3-0.8,0.1c0.3-0.1,0.5-0.2,0.7-0.4c-0.1,0-0.4,0-0.5,0
        c0-0.1,0.1-0.2,0.1-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c-0.1,0-0.3,0-0.4,0c0-0.2,0-0.4,0-0.6c0.1,0,0.2-0.1,0.2-0.1
        c-0.2,0-0.3-0.1-0.5-0.2c0-0.1-0.1-0.3-0.1-0.4c-0.1,0-0.3,0-0.4,0.1c-0.1-0.2-0.2-0.4-0.2-0.6c-0.1,0-0.2,0-0.3,0
        c0.2,0.1,0.3,0.2,0.3,0.4c-0.6-0.4-1.1-0.8-1.9-0.8c0-0.1,0-0.2,0-0.2c-0.1,0-0.2,0-0.2,0l0-0.2l-0.1,0c0.1,0.1,0.2,0.3,0.2,0.4
        l-0.6-0.1c0.1-0.1,0.2-0.2,0.3-0.2l-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.2,0,0.3c-0.2-0.1-0.4-0.1-0.6-0.2
        c0.1,0.1,0.2,0.2,0.2,0.3c-0.1,0.1-0.3,0.1-0.4,0.2c0-0.2,0.1-0.4,0.1-0.6c-0.1,0.1-0.1,0.2-0.2,0.4c-0.2,0.2-0.5,0.5-0.8,0.3
        c0,0.1,0,0.2,0,0.2c-0.2,0-0.4-0.1-0.6-0.1c0,0.1,0,0.3,0,0.5c-0.2-0.2-0.3-0.4-0.4-0.6l0,0.2c-0.1,0-0.3,0-0.4-0.1
        c0.4-0.1,0.7,0.6,0.1,0.4c0,0.1,0.1,0.3,0.2,0.4c-0.1,0-0.3-0.1-0.5-0.1c0.1,0.3-0.2,0.7-0.5,0.8c0.1,0.1,0.2,0.2,0.3,0.3
        c-0.1,0-0.2,0.1-0.2,0.2c0.2,0.6,0.7,1.1,1.2,1.6c0.2,0.3,0.7,0.2,0.7,0.6c-0.4,0.1-0.7-0.3-1-0.1c-0.1-0.1-0.2-0.2-0.3-0.2l0.1,0.3
        c-0.2-0.5-0.4-0.2-0.6,0c0,0-0.1-0.1-0.1-0.2c0.1-0.1,0.2-0.1,0.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.3l0.1,0.3l-0.3,0
        c0.1-0.4-0.2-0.6-0.4-0.9c-0.1,0.1-0.2,0.2-0.2,0.4c-0.1-0.4-0.4-0.7-0.7-0.9c0.2,0.1,0.4,0.1,0.6,0.2c-0.1-0.1-0.3-0.2-0.4-0.3
        c-0.1-0.2-0.2-0.3-0.3-0.5c0-0.1,0-0.3,0.1-0.4c0.1,0,0.3,0,0.4,0c-0.1-0.2-0.3-0.2-0.5-0.1c0-0.3,0-0.5,0-0.8
        c0.1,0.1,0.3,0.2,0.4,0.3c-0.1-0.3-0.3-0.6,0.1-0.8l-0.2-0.1c0.7-0.6,1.4-1.3,2.3-1.6c0.1-0.1,0.2-0.2,0.3-0.3c-0.2,0-0.3,0-0.5,0
        c0,0.1,0,0.2,0,0.3c-0.2-0.1-0.5-0.1-0.7-0.2c0,0.1,0,0.3,0,0.4c-0.1-0.1-0.3-0.2-0.4-0.2c0,0.1-0.1,0.2-0.1,0.3
        c-0.1,0-0.3,0.1-0.4,0.2c0.1-0.1,0.2-0.2,0.2-0.2c-0.1-0.1-0.2-0.2-0.4-0.2c0,0.1,0,0.4-0.1,0.5c-0.1,0-0.2-0.1-0.2-0.2
        c-0.3,0.2-0.9,0.1-0.8,0.6c0.1-0.1,0.3-0.2,0.4-0.3c0,0,0.1,0.1,0.2,0.1c-0.2,0.1-0.4,0.3-0.7,0.2l0.1,0.2c-0.2,0-0.4,0-0.6,0
        l0.1,0.2c-0.1,0-0.3-0.1-0.3-0.2c0,0.1-0.1,0.3-0.1,0.3l0-0.4c-0.3,0.3-0.6,0.6-1,0.7c0.1,0.2,0.1,0.4,0.3,0.4
        c-0.1,0-0.3,0.1-0.4,0.1c0.1-0.1,0.1-0.3,0-0.4c-0.2,0.2-0.3,0.6-0.6,0.7c-0.2,0.1-0.4,0.1-0.4,0.4c0.2,0,0.5-0.1,0.7-0.2
        c-0.3,0.3-1.2,0.4-0.7,0.9c-0.1,0-0.3,0-0.4,0c0.4,0.3-0.1,0.6-0.2,0.8c0.1,0,0.2,0,0.2,0.1c0,0.6,0.3,1,0.6,1.5
        c0.5,0.3,0.8,0.9,1.3,1.1c0.4,0,0.7,0.2,1.1,0.3c0.5,0.2,1.1,0.2,1.6,0.4c0,0,0.1-0.1,0.2-0.1c0.4,0,0.9,0,1.3,0
        c-0.1,0.1-0.3,0.4-0.5,0.1c-0.1,0-0.3,0-0.4,0c-0.4,0.4-0.9,0.2-1.4,0.3c-0.1,0.1-0.3,0.2-0.4,0.3c-0.2-0.3-0.6-0.1-0.8-0.2
        c0-0.1,0-0.2,0.1-0.3c0,0-0.1,0-0.2,0c0,0.1,0,0.2,0,0.3c-0.4,0.1-0.9,0-1.2-0.3c-0.1,0-0.3,0-0.4,0c0-0.2-0.1-0.3-0.3-0.3l0.1-0.1
        c-0.4-0.1-0.7-0.3-0.9-0.6c-0.3,0-0.5-0.3-0.7-0.4c0.1,0,0.3,0,0.4,0c0,0,0-0.1,0-0.2l-0.3,0.1c0-0.1-0.1-0.3-0.1-0.4
        c0,0-0.1,0-0.2,0c0-0.2-0.1-0.3-0.3-0.3c0-0.1,0.1-0.2,0.1-0.3c-0.3-0.4-0.3-0.9-0.4-1.3c0.2,0.1,0.4,0.1,0.5,0.3
        c-0.3-0.3-0.6-0.8-0.3-1.1c-0.2-0.5,0.3-0.8,0.6-1.1c-0.3-0.3,0-0.5,0.3-0.6c0-0.1-0.1-0.3-0.1-0.3l0.3,0l0,0.2c0,0,0.1,0,0.1,0
        c-0.2-0.5,0.4-0.5,0.5-0.8c0.2-0.2,0.6-0.5,0.1-0.6c0,0.1,0,0.3,0,0.4c-0.1,0-0.2-0.1-0.3-0.1c-0.5,0.1-0.9,0.6-1.4,0.6
        c-0.2,0.1-0.3,0.3-0.4,0.5c-0.2-0.1-0.2-0.2-0.2-0.4c-0.1,0.2-0.3,0.5-0.5,0.6c-0.1,0-0.3,0-0.4-0.1c0,0.1-0.1,0.2-0.1,0.3
        c-0.1,0-0.3-0.1-0.3-0.1l0.1,0.3c-0.3-0.1-0.5,0.1-0.7,0.1c-0.3,0-0.4,0.1-0.3,0.3c0.2,0.1,0.3,0,0.5-0.1c-0.1,0.1-0.2,0.2-0.2,0.4
        c-0.1,0-0.2,0-0.2-0.1c-0.2,0-0.3,0.1-0.4,0.2l-0.2,0c0,0.2,0.1,0.3,0.1,0.5c-0.1,0-0.3-0.1-0.4-0.2c0,0.3-0.1,0.6-0.4,0.8
        c-0.1-0.1-0.3-0.2-0.4-0.2c0.1,0.2,0.2,0.3,0.2,0.5l-0.2,0.1c0,0.1,0,0.3,0,0.3c-0.2-0.1-0.3-0.3-0.4-0.5c0,0.3,0,0.7,0,1
        c-0.1-0.1-0.3-0.2-0.4-0.3c0.2,0.3,0.1,0.6-0.2,0.8c0,0.1,0,0.3-0.1,0.4c-0.1,0-0.3-0.1-0.4-0.1l0,0c0,0,0.1,0.1,0.1,0.2
        c-0.2,0.5-0.3,1.1-0.4,1.6c0,1.1,0.5,2.1,1.1,3c0.3,0.1,0.5,0.4,0.5,0.7c0.4,0,0.8,0.3,0.9,0.6c0.1,0,0.2-0.1,0.3-0.1
        c0.4,0.3,0.8,0.4,1.2,0.6c0.4,0.1,0.9,0.2,1.3,0.3l0,0.1c0.2,0,0.4,0,0.5-0.1c0.4,0.2,0.9,0,1.4,0.1c0.2-0.1,0.3,0,0.5,0.1
        c0.4-0.3,0.9-0.2,1.4-0.2c0.3-0.1,0.7,0.1,0.9-0.3c0.2,0,0.4,0,0.6-0.1c0.3-0.1,0.5-0.2,0.8-0.3c0.4-0.2,0.8-0.6,1.3-0.5
        c-0.4,0.2-0.8,0.3-1.1,0.5c-0.2,0.1-0.5,0.2-0.7,0.3c-0.2,0.1-0.5,0.2-0.7,0.2c-0.3,0.2-0.6,0.2-0.9,0.3c-0.4,0.1-0.8,0.1-1.2,0.1
        c-0.1,0.2-0.2,0.2-0.4,0.3c0-0.4-0.2,0-0.4,0c-0.2,0.1-0.4-0.4-0.5,0c-0.2-0.2-0.4-0.1-0.6,0c-0.3-0.2-0.6,0.2-1,0.1
        c-0.2-0.1-0.4-0.2-0.6-0.2l0.1,0.3c-0.2-0.2-0.4-0.3-0.6-0.4c-0.2,0.2-0.5,0.2-0.7,0.1l0,0.2l-0.2-0.1l0.2-0.2c-0.1,0-0.2,0-0.3,0
        c0,0.1,0,0.3,0,0.4c-0.1,0-0.2-0.1-0.3-0.2l0.2,0c0-0.1,0-0.2,0-0.3c-0.2,0-0.3,0.1-0.5,0.1c-0.1-0.1-0.1-0.3-0.2-0.4
        c-0.1,0.4-0.5,0-0.7,0.1c-0.3,0.1-0.2-0.5-0.6-0.3c0,0,0.1-0.1,0.1-0.1c-0.3,0.1-0.6-0.5-0.8,0c-0.2-0.2-0.3-0.4-0.2-0.6
        c-0.2-0.1-0.5-0.2-0.7-0.2h0.3c-0.2-0.2-0.3-0.4-0.3-0.6c0,0-0.1,0-0.1,0c0-0.2-0.2-0.4-0.5-0.4c0.1,0.2,0.4,0.4,0.4,0.6
        c-0.3,0-0.4-0.4-0.5-0.6c-0.1,0-0.2,0.1-0.3,0.1c0-0.1,0.1-0.2,0.1-0.3c-0.5,0-0.4-0.6-0.6-0.9c0.2,0.2,0.5,0.4,0.3,0.7
        c0.3-0.1,0.3-0.4,0.4-0.6l-0.1,0.2c-0.1,0-0.2-0.1-0.2-0.1c0-0.2-0.2-0.3-0.4-0.4c0-0.1,0.1-0.4,0.1-0.5c-0.1,0-0.3,0-0.4,0
        c0,0,0-0.1,0-0.1l0.2,0c0-0.3,0.1-0.5,0.1-0.8c-0.3,0-0.4,0.3-0.4,0.5c-0.3-0.2-0.1-0.8,0.3-0.6c-0.1-0.3-0.2-0.6,0-0.9
        c0.1,0,0.2,0,0.3,0c-0.1-0.2-0.2-0.4-0.4-0.6c0.1-0.2,0.1-0.3,0.2-0.4c0.2,0.2,0.3,0.4,0.5,0.5c0-0.1-0.1-0.2-0.2-0.3
        c-0.1-0.3-0.1-0.6-0.3-0.8l0.3,0.1l0-0.1l-0.2,0l0-0.2c0.1,0,0.3,0,0.3,0c-0.1-0.1-0.1-0.3-0.2-0.4c0.1,0,0.2-0.1,0.3-0.1
        c0-0.1,0.1-0.2,0.1-0.4c-0.2-0.3-0.3-0.6-0.2-0.9c0.1,0.2,0.1,0.5,0.2,0.7c0.2-0.2,0.5-0.4,0.3-0.7c-0.1-0.1-0.2-0.2-0.3-0.3
        c0.1,0,0.2,0,0.2,0c0.2,0.1,0.3,0.2,0.5,0.1c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.1,0.1-0.2,0.2-0.2c0-0.2,0.1-0.4,0.4-0.3
        c0-0.3,0.3-0.3,0.5-0.5c0.1-0.3,0.2-0.5,0.4-0.7c0.1,0,0.4,0.3,0.3,0.1c0-0.1,0.1-0.2,0.1-0.3c0.5,0,0.4-0.9,0.9-0.7
        c0-0.1,0-0.2-0.1-0.2c0.2-0.1,0.5-0.2,0.7-0.3c0.1-0.2,0.3-0.4,0.6-0.5c0.1,0,0.2,0,0.2,0l0.1-0.1c0.1,0,0.2,0,0.2,0
        c0.1-0.3,0.5-0.5,0.7-0.1c0,0,0.1,0,0.2,0c-0.1-0.1-0.2-0.2-0.2-0.2c0.2-0.3,0.6-0.3,0.9-0.1c0-0.1,0.1-0.3,0.2-0.4
        c0.1,0,0.2,0,0.3,0c0.1-0.1,0.3-0.2,0.4-0.4c0.1,0,0.3-0.1,0.1-0.2c-0.3-0.2-0.6,0-0.9,0c0,0.1,0,0.2,0,0.3l-0.3-0.1l-0.1-0.2
        c-0.2,0.1-0.3,0.2-0.5,0.4l0-0.3c-0.1,0-0.2,0-0.3,0c0,0.1,0,0.3-0.1,0.4c-0.1-0.1-0.2-0.3-0.3-0.3c0,0,0-0.2,0-0.2
        c-0.3-0.1-0.7,0-1,0c0,0.2,0.1,0.4,0.1,0.6c-0.3-0.1-0.6-0.4-0.9-0.4c0,0.1,0.1,0.2,0.2,0.3c-0.1,0-0.2,0-0.2,0.1l0.1-0.3
        c-0.1,0-0.4,0.1-0.5,0.2c0-0.1-0.1-0.2-0.1-0.3c-0.4-0.1-0.2,0.4-0.1,0.6c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2,0.1-0.3,0.2-0.5,0.3
        c-0.1-0.3-0.4-0.4-0.7-0.2c0.1,0.1,0.3,0.2,0.4,0.3c-0.2,0-0.4,0-0.5-0.2c-0.2,0.1-0.4,0.3-0.6,0.4c0.1-0.1,0.2-0.3,0.2-0.4
        c-0.2,0.1-0.4,0.1-0.6,0.2c0,0-0.1,0.1-0.2,0.2c-0.2-0.1-0.3-0.1-0.5,0.1c-0.8,0-1.6,0.6-2.4,0.7c-0.1,0.3-0.6-0.1-0.6,0.3
        c-0.4,0.1-0.8,0.2-1.1,0.6c-0.3,0-0.5,0-0.8,0.2c-0.1,0.2-0.3,0.3-0.4,0.5c0-0.3-0.7-0.3-0.2,0c-0.1,0.2-0.3,0.3-0.5,0.3
        c-0.2,0.1-0.3,0.3-0.3,0.5c-0.1,0-0.3-0.1-0.4-0.2c-0.1,0.3-0.2,0.6-0.3,0.9c0-0.2,0-0.3,0-0.5c-0.2,0.2-0.5,0.3-0.7,0.5
        c0.1,0,0.4-0.1,0.5-0.1c-0.1,0.1-0.2,0.3-0.2,0.5c0,0-0.1,0-0.1,0c0-0.1,0-0.3,0-0.4l-0.2,0c-0.1,0.1-0.2,0.3-0.2,0.4
        c0.1,0,0.2,0.1,0.2,0.1c0,0.1-0.1,0.3-0.2,0.4c-0.1-0.1-0.2-0.3-0.3-0.4c0,0.3-0.3,0.3-0.4,0.6c-0.1,0.2-0.2,0.5-0.5,0.5
        c0.1,0.1,0.2,0.2,0.3,0.2c-0.1,0-0.4,0-0.5,0c0.1,0.1,0.2,0.4,0.2,0.6c-0.2-0.1-0.3-0.1-0.4-0.2c0,0.2,0,0.4,0.1,0.5
        c-0.1,0.1-0.2,0.3-0.2,0.5c-0.1-0.1-0.2-0.2-0.3-0.3l-0.1,0.1c0.1,0.2,0.4,0.5,0,0.4c0.2,0.4-0.3,0.7-0.4,1.1c0.1,0,0.3-0.1,0.4-0.1
        l0,0.1c-0.1,0-0.3,0.1-0.4,0.1c0,0.3,0,0.7,0,1l-0.1,0c0,0.1-0.1,0.3-0.2,0.4c0.1,0.1,0.2,0.2,0.3,0.2c-0.1,0.1-0.3,0.3-0.4,0.4
        c0,0.2,0.1,0.4,0.2,0.6l-0.2,0c0,0.5-0.1,1,0,1.6c0.1,0,0.2,0.1,0.3,0.1l-0.3,0c0,0.1,0.1,0.4,0.1,0.5l0.2-0.1c0,0,0,0.1,0,0.1
        l-0.2,0c0.1,0.3,0.2,0.6,0.2,0.9c0.2,0.3,0.1,0.6,0.1,0.9l0.1,0c0.1,0.1,0.2,0.2,0.2,0.2c0,0.1-0.1,0.2-0.1,0.3l0.2-0.1
        c0,0.2,0,0.5-0.1,0.7c0.1-0.1,0.2-0.2,0.3-0.3c0,0.1,0,0.4,0,0.5c0.2,0.2,0.4,0.5,0.7,0.5c0,0.1,0,0.3-0.1,0.4
        c0.1,0,0.3-0.1,0.3-0.1l0,0.2c0.2,0.1,0.3,0.3,0.5,0.3c0,0.1-0.1,0.2-0.1,0.2c0.1,0,0.2,0,0.3,0l0,0.2l0.2,0c0,0.1-0.1,0.3-0.1,0.3
        c0.1,0,0.2-0.1,0.3-0.1c0,0.3,0.3,0.3,0.4,0.4c0.2,0.2,0.5,0.4,0.8,0.6c0.3,0.1,0.5,0.3,0.7,0.5c0.4,0.1,0.8,0.3,1.1,0.3
        c0.4,0.2,0.9,0.2,1.3,0.3c0,0,0.1,0.1,0.1,0.2c0.2-0.1,0.4,0,0.6,0c0.2,0,0.3-0.1,0.5-0.1c0.3,0,0.6,0,0.9,0.1
        c0.3,0.1,0.4,0,0.4-0.3c0.2,0.3,0.6,0.2,0.8,0.2c0.4,0,0.8-0.2,1.3-0.2l-0.1,0.1c-0.5,0.1-0.9,0.2-1.4,0.2c-0.5,0-1,0.2-1.4,0.1
        l0.1,0.2c-0.2-0.1-0.5-0.2-0.7-0.3c0,0.1,0,0.2,0,0.3c-0.1-0.1-0.2-0.2-0.2-0.2c0,0,0,0.1-0.1,0.2c-0.1-0.1-0.3-0.2-0.4-0.2
        c-0.2,0.2-0.3,0.1-0.3-0.1c-0.2,0.1-0.4,0-0.6-0.1c-0.1,0-0.2,0.1-0.2,0.1c-0.1-0.1-0.3-0.1-0.4-0.1c-0.2-0.1-0.5-0.1-0.8-0.2
        c-0.5-0.1-0.9-0.5-1.4-0.7c-0.2-0.2-0.4-0.3-0.6-0.6c-0.1,0-0.2,0-0.3,0.1c-0.2-0.2-0.4-0.4-0.6-0.5c-0.2-0.2-0.5-0.5-0.7-0.7
        c-0.4,0-0.5-0.6-0.8-0.8c-0.3-0.1-0.5-0.3-0.6-0.6c-0.2-0.3-0.4-0.7-0.7-1c0-0.6-0.5-1-0.7-1.5c0-0.5-0.4-1-0.4-1.5
        c-0.1-0.6,0.1-1.2-0.2-1.7c0.1-0.7,0.1-1.4,0.2-2.1c0.2-0.5,0.3-1,0.4-1.5c0.2-1,0.7-1.9,1.2-2.9c0,0,0.1-0.1,0.2-0.1
        c0-0.3,0.2-0.5,0.5-0.6c-0.1-0.3,0.2-0.4,0.4-0.6c0.1-0.3,0.2-0.5,0.4-0.7c0,0.1,0.1,0.2,0.1,0.3c0.2-0.2,0.1-0.4,0-0.5
        c0.1,0,0.4-0.1,0.5-0.1c0.1-0.1,0.1-0.3,0.2-0.4c0.1,0,0.3-0.1,0.3-0.1c0-0.4,0.4-0.6,0.7-0.7c0.1-0.4,0.4-0.4,0.8-0.5
        c0-0.1,0-0.2,0-0.3c0.4-0.2,0.6-0.5,1-0.7c0.1,0.3,0.3,0.1,0.5,0c-0.1,0-0.3-0.1-0.4-0.1l0,0c0.2-0.1,0.4-0.3,0.7-0.3
        c0.1,0,0.3,0,0.4,0c0-0.1,0-0.2-0.1-0.3c0.4-0.1,0.7-0.2,1.1-0.3c0-0.1-0.1-0.3-0.1-0.3c0.1,0.1,0.3,0.2,0.4,0.3
        c0.2,0.2,0.3-0.2,0.5-0.3c-0.1,0-0.3-0.1-0.4-0.1c0.2-0.2,0.5-0.2,0.6,0.1c0.2-0.4,0.5-0.2,0.8,0c0-0.1-0.1-0.3-0.1-0.4l0.2,0.1
        l0-0.3c0.2,0.1,0.3,0.3,0.5,0.5c0.1-0.3,0.2-0.4,0.5-0.3c0.3,0.1,0.5-0.4,0.7,0c0.2-0.1,0.3-0.2,0.4-0.3c0.2,0,0.9,0.4,0.6-0.2
        c0.3,0.2,0.7,0.3,1.1,0.3c0-0.1-0.1-0.3-0.2-0.3c0.4-0.2,0.8-0.1,1.2,0.1c0.2-0.1,0.2-0.5,0.4-0.6c0,0.3-0.3,0.5-0.2,0.8
        c0.2-0.1,0.3-0.2,0.4-0.3c0.2,0,0.3,0,0.5-0.1c0.1-0.3,0.3-0.2,0.4,0c0.1-0.1,0.2-0.1,0.3-0.2c0.2,0.1,0.4,0.3,0.6,0.5
        c-0.1-0.1-0.1-0.3-0.2-0.4c0.2,0,0.6,0.1,0.8,0.2c-0.1-0.2-0.2-0.3-0.3-0.4c0,0,0-0.1,0-0.2c0.3,0,0.3,0.4,0.6,0.5
        c0.3,0.1,0.6-0.1,0.9-0.2c-0.2,0.6,0.4,0.1,0.6-0.1c0.2,0.2,0.4,0.5,0.7,0.5c-0.1-0.1-0.2-0.3-0.3-0.4c0-0.1,0-0.3,0-0.4l0,0
        c0.2,0.1,0.4,0.4,0.6,0.3c0.1-0.1,0.3-0.2,0.4-0.3c-0.1,0.1-0.2,0.3-0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0-0.1,0-0.3,0-0.4
        c0.1,0,0.4,0,0.6,0c0,0.1-0.1,0.2-0.1,0.3c0.1,0,0.3,0,0.4,0c0.3-0.3,1-0.1,0.9-0.7c0,0,0,0,0,0c0,0.1,0.1,0.2,0.1,0.2
        c0,0,0.1-0.1,0.2-0.1l0.2-0.1c-0.1,0.1-0.2,0.3-0.3,0.4l-0.1,0c-0.1,0-0.2,0.1-0.3,0.3c0.2,0,0.4-0.1,0.6-0.2
        c0.1-0.2,0.3-0.4,0.5-0.5c0,0.1,0.1,0.2,0.2,0.2c-0.1,0-0.3,0-0.4,0.1c0.1,0.1,0.3,0.3,0.4,0.3c0.1,0,0.2,0,0.3,0.1
        c0.1-0.1,0.2-0.3,0.3-0.4l0,0.2c0.2,0,0.4,0,0.5,0.2c0.3-0.1,0.6,0,0.9-0.1c0.1,0.3,0.3,0.1,0.5,0c0.4,0.3,0.8,0,1.1-0.3
        c0.2,0.1,0.6,0.1,0.3,0.4c-0.1,0-0.2-0.1-0.3-0.2c0,0,0,0.1,0,0.2c0.1,0,0.3,0,0.3,0c0.1-0.1,0.2-0.3,0.2-0.5c0,0-0.1-0.1-0.1-0.2
        c0.2,0.1,0.4,0.2,0.6,0.2c-0.1,0.1-0.1,0.2-0.2,0.4c0.3,0,0.6-0.1,0.9-0.1l0-0.1c0.2,0.1,0.4,0.1,0.6-0.1c-0.1,0-0.3,0-0.4,0
        c0.1-0.1,0.1-0.2,0.2-0.3c0,0.2,0.1,0.4,0.3,0.4c0.1-0.2,0-0.5,0.4-0.4l-0.3,0.2c0.2,0.2,0.5,0.2,0.8,0.3c0-0.1,0.1-0.2,0.1-0.3
        c0,0.1,0,0.3,0,0.5c0.1,0,0.4-0.1,0.5-0.2c-0.1,0-0.2-0.1-0.2-0.2c0.1-0.1,0.3-0.2,0.3-0.4c0,0,0.1,0.1,0.1,0.2
        c-0.1,0.1-0.2,0.2-0.2,0.2c0.2,0.5,0.5,0.1,0.5-0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1-0.1,0.2-0.1,0.3-0.2l-0.1-0.1l0.3-0.2
        c0,0.1,0.1,0.2,0.2,0.4c0-0.2,0-0.3,0-0.5c0.1,0,0.3,0,0.4,0c0-0.2,0.1-0.3,0.1-0.5c0,0.1,0,0.4,0,0.5c0.1-0.1,0.3-0.2,0.4-0.3
        c0,0,0.1,0.1,0.2,0.2c0-0.1,0.1-0.4,0.1-0.5c0.1,0.1,0.3,0.2,0.4,0.3c0-0.1,0-0.3,0-0.4c0.2,0.1,0.3,0.2,0.5,0.2c0-0.1,0-0.3,0-0.4
        c0.3-0.3,0.5,0.1,0.3,0.4l0.1,0c0.1-0.2,0.1-0.4,0.2-0.6c0.3-0.1,0.7-0.2,0.8-0.5c0.1-0.3,0.4-0.5,0.7-0.3c-0.1-0.6,0.5-0.7,0.9-0.9
        c0.1,0.1,0.2,0.2,0.2,0.4c0.2-0.4,0.4-0.7,0.7-0.9c0.3-0.2,0.4-0.5,0.7-0.7c0.1-0.2,0.2-0.4,0.4-0.5c0.1-0.3,0.1-0.6,0.3-0.8
        c0,0-0.1-0.2-0.1-0.2c-0.1-0.3-0.4-0.3-0.7-0.3c0,0.1,0,0.3,0,0.4c-0.4,0.2-0.7,0.3-1.1,0.4c0,0,0-0.1,0-0.1
        c0.4-0.1,0.9-0.3,1.1-0.6c-0.2,0-0.7-0.2-0.7,0.2c-0.1,0-0.3,0-0.4-0.1c-0.2-0.2-0.5,0.2-0.8,0.1c0.2,0.1,0.3,0.4,0.5,0.2
        c0,0,0,0.1,0,0.1c-0.2,0.3-0.4,0.1-0.6-0.1l0.2,0c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2,0.3-0.5,0-0.7,0c0,0.1,0,0.2,0,0.3
        c-0.4-0.4-0.8,0.1-1.2,0.3c0.1-0.1,0.1-0.3,0.2-0.4c-0.4-0.1-0.4,0.3-0.6,0.5c0-0.1,0-0.3,0-0.3c-0.3,0-0.6,0-0.9,0
        c-0.5-0.5-1.3,0.2-1.9-0.3c-0.3,0-0.5,0-0.6-0.3c-0.3,0-0.7-0.2-0.9,0.1c0,0.1,0.1,0.2,0.2,0.2c-0.1,0-0.2,0-0.2,0
        c0-0.1,0-0.2-0.1-0.4c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1,0-0.4,0-0.5,0c-0.1,0.3,0,0.5,0,0.8c-0.2-0.3-0.2-0.6-0.2-0.9l-0.2,0.1
        l-0.1-0.3c-0.1,0.1-0.2,0.2-0.3,0.2c0.3-0.2,0.3-0.5,0.1-0.7c0,0.5-0.6,0.1-0.8-0.1c-0.1,0.1,0,0.4-0.2,0.3
        c-0.1-0.2,0.1-0.3,0.1-0.4c-0.5,0-0.6-0.5-1-0.8c-0.2,0-0.5,0.1-0.5-0.2c-0.1-0.3-0.5-0.4-0.7-0.6c-0.1-0.2-0.2-0.3-0.4-0.4
        c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1,0.1-0.2,0.2-0.3,0.3c0-0.2,0.1-0.3,0.3-0.3c-0.2-0.3-0.5-0.5-0.8-0.6c0.1-0.2,0.1-0.4-0.1-0.5
        c-0.4-0.5-0.6-1.2-0.4-1.8c-0.6-0.4-0.5-1.2-0.5-1.9c-0.4-0.4-0.1-0.9-0.3-1.3c0-0.3,0.1-0.6-0.1-0.8c0.1-0.2,0.2-0.4,0.3-0.5
        c-0.1-0.1-0.2-0.1-0.3-0.2c0.1-0.2,0.2-0.4,0.2-0.6c0.1,0,0.2,0,0.3,0c-0.1-0.1-0.3-0.3-0.2-0.5c0.3-0.5,0.1-1.1,0.3-1.6
        c0-0.3,0.3-0.5,0.1-0.8c0.1,0,0.2-0.1,0.2-0.1c0,0.1,0,0.2,0,0.3l0.1,0c-0.1-0.3-0.2-0.8,0.3-0.8c0-0.1-0.1-0.2-0.1-0.3l0.2,0.1
        c0-0.2,0.2-0.4,0.3-0.6c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0,0.2-0.1,0.3-0.2c-0.1,0-0.3,0-0.4,0c0.1-0.1,0.2-0.2,0.3-0.2
        c0.1-0.4,0.6-0.6,0.8-0.9c0,0.3-0.4,0.4-0.5,0.7c0.3,0.1,0.5-0.3,0.6-0.5c-0.1-0.3,0.1-0.5,0.3-0.6c-0.1,0.1-0.2,0.4-0.3,0.5
        c0.2-0.2,0.5-0.3,0.8-0.5c0-0.1,0-0.3,0-0.4c0.3,0.1,0.6,0,0.9-0.3l-0.3,0.6c-0.1,0-0.3-0.1-0.5-0.1c0.1,0.1,0.1,0.2,0.2,0.3
        c-0.1,0.2-0.3,0.3-0.4,0.5c-0.4,0.3-0.4,0.9-0.9,1.2c0.1,0.2-0.1,0.4-0.2,0.6c0,0.2-0.1,0.5-0.1,0.7c-0.1,0.1-0.2,0.2-0.2,0.3
        c0,0.2-0.1,0.3-0.1,0.5c0.1,0,0.2,0.1,0.2,0.2c-0.1,0-0.3,0-0.4-0.1c0,0.4,0,0.7,0,1.1c0.1,0,0.2,0,0.3,0c-0.1,0.1-0.3,0.2-0.4,0.3
        c0.1,0.2,0.2,0.3,0.3,0.5l-0.3-0.1c0,0.1,0,0.3,0,0.4c0.1,0.2,0,0.7,0.4,0.2c-0.3,0.3,0,0.6,0.2,0.8c-0.1,0.1-0.2,0.2-0.3,0.3
        c0.1,0.1,0.2,0.1,0.3,0.2c-0.1,0-0.3,0-0.4,0c0.1,0.1,0.2,0.2,0.3,0.2c0,0.3,0.3,0.6,0.5,0.8l0.3-0.2c0,0.2-0.1,0.5-0.1,0.8
        c0.1,0,0.3,0.1,0.4,0.2c-0.2,0.4,0.1,0.5,0.3,0.6l-0.1-0.3l0.3,0c-0.1-0.1-0.2-0.2-0.3-0.2l0-0.3c0,0.2,0.7,0.4,0.2,0.6
        c0.2,0.2,0.4,0.4,0.3,0.6c0.2,0.2,0.4,0.3,0.6,0.5c0-0.1-0.1-0.4-0.1-0.5c0.2,0.2,0.4,0.5,0.7,0.5c-0.2,0.2,0.1,0.3,0.2,0.5
        c0.1,0.3,0.4,0.4,0.7,0.3c0.2,0.5,0.7,0.8,1.2,0.8l0,0.2c0.2,0,0.5-0.2,0.7-0.1l-0.1-0.3c0,0,0.1,0,0.2,0c0,0.1,0,0.3,0,0.4
        c0.1,0,0.4-0.1,0.5-0.1c0,0.1,0.1,0.2,0.1,0.3c0.2-0.1,0.3-0.3,0.4-0.4c0.1,0.2,0.2,0.4,0.3,0.5l0.1-0.3c0.2,0.1,0.3,0.2,0.4,0.4
        c0.3-0.1,0.3-0.6,0.7-0.7c0,0.1,0,0.4,0,0.5c0.2-0.2,0.3-0.5,0.4-0.8c0.1,0,0.2,0.1,0.3,0.1c0.1-0.1,0.2-0.2,0.3-0.3
        c0,0.2-0.2,0.4-0.4,0.6c0.2,0.1,0.4,0,0.6-0.2c0.1-0.1,0.4,0,0.4-0.2c-0.1-0.1-0.1-0.2-0.2-0.3c0.3,0.1,0.5-0.1,0.7-0.3
        c0,0.1,0.1,0.2,0.1,0.2c-0.2,0.1-0.6-0.1-0.4,0.2c0.1,0.1,0.3,0.1,0.4,0.1l0.1,0.2c0-0.1,0-0.3,0-0.4c0.2,0,0.5,0.1,0.7,0
        c0.2-0.1,0.5-0.3,0.6-0.1c0-0.3,0.3-0.4,0.1-0.7c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3,0-0.7,0.5-1,0.1c0.2-0.1,0.5-0.2,0.7-0.2
        c0.1-0.1,0.2-0.3,0.3-0.5c0.2,0.1,0.4,0,0.4,0.3c0.2-0.2,0.4-0.1,0.7-0.1c-0.2-0.2-0.3-0.4-0.5-0.5c0.6-0.6,1.5-0.4,2.1-0.8
        c-0.1-0.1-0.3-0.2-0.4-0.2c0.2-0.1,0.4-0.2,0.6-0.3c0.1,0.3,0.2,0.2,0.3,0.1c-0.1-0.1-0.3-0.3-0.4-0.3c0.2-0.2,0.4-0.3,0.6,0
        c0-0.3,0.1-0.6-0.1-0.9c0.1,0,0.2,0,0.3,0.2c0.2-0.3,0.4-0.6,0.5-1c0.2-0.1,0.5,0.3,0.5-0.1c-0.1,0-0.3,0-0.4,0
        c0-0.3,0.1-0.5,0.5-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c0.1,0.1,0.3,0.2,0.4,0.3c0-0.2-0.1-0.5-0.2-0.6c0.2-0.5,0-1,0.2-1.6
        c0-0.1-0.1-0.3-0.1-0.4c0.2-0.1,0.4-0.2,0.4-0.5c-0.1-0.1-0.3-0.2-0.4-0.2c0.1,0,0.3,0,0.3-0.1c0-0.5-0.2-0.9-0.3-1.3
        c-0.1-0.2-0.3-0.4-0.4-0.6c0.1,0,0.2-0.1,0.3-0.1c0,0,0-0.1,0-0.1c-0.1,0-0.2,0.1-0.3,0.1c0-0.1-0.1-0.3-0.1-0.4
        c-0.3-0.2-0.5-0.5-0.8-0.6c-0.2,0-0.6,0-0.5-0.3c0.2,0,0.3,0.1,0.5,0.1c-0.1-0.3-0.3-0.5-0.4-0.8c-0.2,0-0.5,0.1-0.7-0.1
        c0-0.4-0.3-0.5-0.5-0.8c-0.1,0-0.3,0.1-0.4,0.2c0.1,0.2,0.2,0.3,0.3,0.5c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.5-0.5-0.9-0.8-1.3
        c-0.1,0-0.2,0-0.3,0c-0.1-0.1-0.1-0.3-0.2-0.4c-0.3,0-0.6,0-0.8,0.1c0.1-0.1,0.2-0.2,0.2-0.3c-0.3-0.1-0.5-0.3-0.8-0.1
        c0-0.1-0.1-0.2-0.1-0.2l-0.1,0.1l-0.1,0.1c0-0.1,0-0.2,0-0.3c-0.1,0-0.4,0-0.5-0.1c0.1,0,0.2,0,0.3,0c-0.2-0.3-0.6-0.2-0.9-0.4
        c-0.1-0.2-0.4-0.7-0.4-0.1c-0.2-0.2-0.3-0.3-0.5-0.4c-0.4-0.2-0.8-0.3-1.2-0.3c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2,0.1-0.3,0.2-0.4,0.3
        c-0.2-0.2-0.3-0.3-0.5-0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1-0.3-0.4-0.5-0.7-0.7C106.7,102,106.6,102.1,106.4,102.3 M373.1,102
        c0,0.2,0,0.4,0.1,0.5C373.6,102.6,373.3,101.9,373.1,102 M407.1,102.6c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2,0.1-0.3,0.3-0.5,0.5
        c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.4,0-0.9,0.2-1.3,0.3l0,0.1c-0.2,0.1-0.3,0.2-0.5,0.4l-0.1-0.3
        c-0.1,0.1-0.2,0.3-0.3,0.4c-0.3,0.1-0.5,0.2-0.8,0.2c-0.3,0.2-0.6,0.4-0.7,0.7c0,0-0.1-0.1-0.2-0.2c-0.2,0.1-0.5,0.1-0.5,0.3
        c0,0.1,0.1,0.2,0.1,0.3c0,0-0.1-0.1-0.1-0.1l-0.1-0.1c-0.1,0-0.2,0-0.2,0c-0.3-0.1-0.5,0.1-0.5,0.4c-0.4-0.2-0.5,0.2-0.6,0.4
        c-0.2,0.1-0.3,0.3-0.3,0.5c-0.2,0-0.4-0.1-0.5-0.1c-0.1,0.2-0.2,0.4-0.4,0.5c-0.1,0.4-0.3,0.8-0.5,1c-0.1-0.3,0.2-0.5,0.4-0.6
        c-0.2,0-0.5,0-0.7,0c-0.1,0.2-0.1,0.3-0.2,0.5c-0.1,0.1-0.2,0.2-0.2,0.3c0.2,0,0.3-0.1,0.5-0.1c0,0.3-0.3,0.3-0.5,0.3
        c-0.3,0.2-0.6,0.3-0.7,0.6c-0.1,0-0.2,0-0.3-0.1c0.2,0.3-0.1,0.6-0.4,0.3c0.1,0.1,0.2,0.2,0.3,0.2c-0.1,0.2-0.3,0.4-0.4,0.6
        c-0.2,0.4-0.4,0.9-0.3,1.3c0.1,0,0.2,0,0.3,0l0,0.2c-0.4-0.1-0.3,0.3-0.1,0.5c0.2,0.1,0.1,0.4,0,0.5c0.2,0.5,0,1.1,0.2,1.5
        c0,0.2-0.1,0.5-0.2,0.6c0.1-0.1,0.3-0.2,0.4-0.3c-0.1,0.1-0.2,0.3-0.3,0.4c0.2,0,0.4,0.1,0.5,0.3c-0.2,0.1-0.4,0.1-0.4,0.3
        c0.2-0.1,0.4-0.1,0.6-0.1c-0.1,0.4,0.2,0.7,0.4,1c0.1-0.1,0.2-0.2,0.2-0.3c0.1,0.3-0.1,0.7,0,1c0.2-0.2,0.4-0.2,0.6,0
        c-0.2,0.1-0.3,0.3-0.3,0.5c0.1,0,0.3-0.1,0.4-0.2c0.2,0.1,0.3,0.2,0.5,0.3c-0.1,0.1-0.3,0.2-0.4,0.2c0.3,0.1,0.5,0.3,0.8,0.2
        c0.2,0.1,0.4,0.1,0.7,0.2c0.2,0,0.4,0.2,0.4,0.4c-0.3-0.1-0.6-0.3-0.9-0.4c0,0.1-0.1,0.3-0.2,0.4c0.3,0,0.6,0,0.7,0.3
        c0.1,0,0.3-0.1,0.4-0.1c-0.1,0.1-0.2,0.3-0.3,0.4c0.1,0,0.3-0.1,0.3-0.1c0.1,0.1,0.2,0.1,0.3,0.2c0-0.3,0.3-0.2,0.5-0.3
        c0,0.2-0.3,0.3-0.5,0.3c0.1,0.2,0.2,0.3,0.3,0.5c0,0,0.1-0.1,0.2-0.1c0.3,0.2,0.7,0.3,1.1,0.2c0,0.1,0,0.3,0,0.4
        c0.2-0.2,0.5-0.2,0.7-0.4c-0.2,0-0.4-0.1-0.6-0.1l0-0.2c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0,0.3,0,0.4,0.2c-0.1,0-0.3,0.1-0.4,0.1
        c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.3,0.6,0.4,0.9,0.6c-0.1,0-0.2-0.1-0.2-0.2c0.1-0.4-0.6-0.4-0.4-0.8c0.1,0.1,0.2,0.2,0.3,0.3
        c0.1,0,0.2-0.1,0.3-0.1c0.1,0.3,0.2,0.6,0.5,0.8c0-0.1-0.1-0.3-0.1-0.4c0.2,0,0.6,0.2,0.8-0.1c0.1,0,0.2,0,0.3,0
        c0,0.2,0,0.4,0.1,0.6c0.2-0.1,0.3-0.3,0.4-0.5c0.1,0.2,0.4,0.6,0.5,0.1c0.1,0,0.4,0.1,0.5,0.1c0-0.1,0-0.3,0-0.4c0,0,0.1,0,0.2,0
        c-0.3,0.5,0.5,0.2,0.7,0.4l0-0.2c0.5,0,1-0.3,1.2-0.8c0.3,0.1,0.7,0,0.7-0.4c0.2,0,0.4,0.1,0.4,0.3c0.2-0.1,0.3-0.2,0.5-0.4
        c0.1,0,0.2,0.1,0.3,0.1c0-0.2,0-0.4,0-0.6c0,0.1-0.1,0.2-0.1,0.3c-0.1,0-0.2,0-0.2,0l0,0.2c-0.1,0-0.3-0.1-0.4-0.1
        c-0.1,0-0.2,0-0.3,0c0.1-0.3,0.4-0.6,0.7-0.8c-0.1,0.1-0.2,0.4-0.2,0.5c0.3-0.2,0.5-0.4,0.7-0.6c-0.1,0-0.3,0-0.5,0
        c0.2-0.1,0.6-0.1,0.5-0.5l0-0.1c0-0.2,0.3-0.4,0.4-0.5l0,0.3c0,0.1-0.1,0.2-0.2,0.2l0.1,0.1l0.1,0.1c0.3-0.1,0.5-0.3,0.3-0.6
        c0.1,0,0.3-0.1,0.4-0.2c0-0.3-0.1-0.5-0.1-0.8l0.2,0.2c0.2-0.3,0.4-0.5,0.5-0.8c0.1-0.1,0.2-0.2,0.3-0.2c-0.1,0-0.3,0-0.5,0
        c0.1,0,0.3-0.1,0.4-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c0.2-0.1,0.4-0.4,0.3-0.6c0-0.3,0-0.5,0.1-0.8c0.1,0,0.2,0.1,0.3,0.2
        c-0.1-0.3-0.1-0.5,0.2-0.6c0-0.1,0-0.3,0-0.4c0,0-0.1,0-0.1,0c-0.1,0.2-0.1,0.4-0.3,0.5c0-0.5,0-0.9,0-1.4c0,0-0.1,0-0.2,0
        c-0.1-0.3-0.2-0.7-0.1-1c-0.1,0-0.2,0.1-0.3,0.1c0-0.3-0.1-0.6-0.1-0.9c-0.1-0.1-0.2-0.3-0.3-0.5l0.3,0c-0.3-0.1-0.6-0.2-0.6-0.5
        c-0.2-0.2-0.3-0.6-0.6-0.7c-0.3-0.1-0.4-0.5-0.4-0.9c0.4,0.3,0.7,0.6,1.2,0.8c-0.1-0.1-0.2-0.3-0.3-0.4l0-0.1
        c0.1,0.1,0.3,0.2,0.3,0.4c-0.1,0.4,0.3,0.6,0.5,0.8c0-0.1,0-0.3,0-0.4c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.1,0.2,0.2,0.3,0.3
        c-0.1,0-0.3,0-0.4,0l0.1,0.2l0.2,0c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.2,0.5,0.5,0.1,0.7c0.1,0,0.3-0.1,0.3-0.1c0,0.2-0.2,0.5,0,0.7
        c0-0.1,0-0.4,0-0.5c0.5,0.1,0.1,0.6,0.3,0.8c0-0.1,0-0.3,0-0.3c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.1,0.3
        c0.1,0.1,0.2,0.2,0.2,0.3c0,0.4,0.1,0.8,0.1,1.2c0,0.4,0.3,0.9,0,1.3c0.2-0.1,0.2-0.4,0.2-0.6c0.4,0.1,0.2,0.4,0.1,0.6
        c0.1,0.2,0.1,0.4,0.2,0.5c-0.1,0.1-0.2,0.1-0.3,0.2c0.1,0.2,0.2,0.3,0.3,0.5c0,0.2-0.1,0.4-0.1,0.5c0.1,0.3-0.1,0.7,0,1
        c0.1,0.3-0.3,0.4-0.2,0.7c0.1,0.6,0.1,1.3-0.5,1.7c0.1,0.7-0.1,1.5-0.7,2c0.2,0.5-0.5,0.4-0.7,0.8c0.1,0.1,0.2,0.2,0.3,0.3
        c-0.2,0-0.3,0.1-0.5,0c-0.3,0.3-0.6,0.7-1,1c-0.2,0.1-0.3,0.3-0.4,0.5c-0.1,0-0.3,0-0.4,0c-0.3,0.3-0.5,0.8-1,0.8
        c0.1,0.1,0.1,0.1,0.2,0.2c0,0.1-0.1,0.2-0.1,0.3l0-0.1c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2,0.2-0.8,0.6-0.8,0c-0.1,0.2-0.2,0.5-0.3,0.7
        c-0.3,0-0.6,0-0.9,0c-0.2,0.3-0.4,0.5-0.4,0.8c0,0-0.1,0-0.2,0c0-0.1,0.1-0.3,0.1-0.4c-0.4,0-0.9-0.1-1.1,0.4
        c-0.1,0-0.2-0.1-0.2-0.1c-0.5,0.4-1.1,0.2-1.7,0.2c-0.1,0.1-0.2,0.2-0.3,0.2c-0.3,0-0.6,0-0.9,0c0,0.1,0,0.2,0,0.3l-0.1,0
        c0-0.1,0-0.3,0.1-0.4c-0.2,0-0.6,0-0.7,0c0.1,0.1,0.3,0.2,0.4,0.4c-0.3-0.2-0.6-0.4-1-0.5c0,0-0.1,0.2-0.2,0.2
        c0-0.4-0.3-0.3-0.5-0.1c-0.3-0.2-0.7-0.1-1-0.3c-0.2,0.1-0.4,0.1-0.6,0.2c0-0.4-0.5-0.2-0.7-0.2c0.3,0.4,0.8,0.5,1.2,0.6
        c-0.4,0-0.8-0.2-1.2-0.4c0-0.1,0-0.3,0-0.4c-0.1,0-0.4,0-0.5,0l-0.1,0c-0.2,0-0.4,0.1-0.6,0.2c0,0,0,0.1,0,0.1
        c0.2,0,0.3-0.1,0.5-0.1c0.3,0.1-0.1,0.2-0.2,0.3c0.1,0.2,0.3,0.3,0.3,0.6c0,0.3,0.4,0.5,0.4,0.8c0.4,0.2,0.5,0.7,0.9,0.8
        c0.1,0.2,0.1,0.4,0.4,0.4c0,0.1,0,0.2,0,0.3c0.1-0.1,0.3-0.3,0.3-0.3c0,0.1,0,0.3,0,0.5c0.1,0,0.2,0.1,0.3,0.1c0,0,0-0.1-0.1-0.2
        l-0.1,0c-0.1-0.1-0.1-0.2,0-0.3c0.3,0.2,0.7,0.4,0.7,0.8c0.1-0.1,0.2-0.1,0.3-0.2c0.2,0.2,0.4,0.5,0.5,0.7c0.3,0.2,0.9,0.4,0.8,0.9
        c0,0,0.1,0,0.1,0c-0.1-0.3,0-0.7,0.3-0.4c0,0.1,0,0.2,0,0.3c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0,0.2-0.1,0.2-0.2
        c-0.1-0.1-0.1-0.2-0.3-0.3c0.1,0,0.2-0.1,0.3-0.1c0,0.1,0,0.2,0.1,0.3l0.1,0l0.2-0.2c0,0.1,0.1,0.4,0.1,0.5c0.2-0.2,0.4,0,0.6,0.1
        c0-0.1,0-0.3,0-0.4l0.1,0c0,0.1,0,0.3,0,0.4c0.1,0,0.4,0.1,0.5,0.1c0,0.1,0,0.3,0.1,0.4c0.1-0.1,0.1-0.2,0.2-0.3
        c0,0.2,0.2,0.3,0.4,0.5c-0.1-0.2-0.2-0.3-0.2-0.5c0.2,0,0.4,0.1,0.6,0.2c-0.1,0.3,0.4,0.5,0.4,0.1c-0.1-0.1-0.2-0.2-0.2-0.2
        c0-0.1,0.1-0.1,0.1-0.1c0.1,0.1,0.2,0.3,0.3,0.3c0,0-0.1,0.1-0.2,0.2c0.1,0.1,0.3,0.2,0.5,0.2c0-0.2,0-0.3,0-0.5
        c0,0.1,0.1,0.3,0.1,0.3c0.3-0.1,0.6-0.1,0.8-0.3l-0.2-0.1c0,0,0.1-0.1,0.2-0.1c0,0.2,0.1,0.3,0.1,0.5c0.1-0.1,0.2-0.3,0.3-0.3
        c0.1,0.1,0.2,0.1,0.3,0.2c-0.1,0.1-0.3,0.1-0.4,0.2c0.2,0.1,0.3,0.1,0.6,0l0,0.2c0.2,0,0.4,0,0.7,0c0.1,0,0.2,0.1,0.2,0.2
        c0-0.2-0.1-0.3-0.1-0.5c0.2,0.1,0.4,0,0.6-0.1c-0.3,0.3,0,0.6,0.3,0.8c0.1-0.1,0.1-0.3,0.2-0.4l-0.1,0.3c-0.1-0.1-0.2-0.2-0.3-0.3
        c0.1,0,0.3-0.1,0.4-0.2c0.2,0.3,0.5,0.4,0.9,0.5c0.1-0.2,0.6-0.4,0.4-0.7c0.2,0.1,0.4,0.1,0.7,0.2c0,0.3-0.4,0-0.5,0
        c-0.1,0.1-0.3,0.3-0.4,0.3c0.2,0.2,0.4,0.1,0.5-0.1c0.2,0.3,0.6-0.1,0.9,0.2c0.2-0.1,0.4-0.4,0.6-0.2l-0.1-0.3
        c0.1,0.1,0.3,0.3,0.4,0.5c0.2-0.1,0.4-0.2,0.6-0.3l-0.1-0.2c-0.1,0-0.3,0-0.4,0c0.1-0.1,0.2-0.3,0.3-0.1c0.2,0,0.3,0.2,0.4,0.4
        c0.1,0,0.4,0.1,0.6,0.1c0-0.1-0.1-0.2-0.2-0.3c-0.3,0.2-0.5-0.2-0.4-0.4c0.1,0.1,0.3,0.3,0.4,0.4c0-0.2,0-0.3,0-0.5
        c0,0.1,0,0.3,0,0.4c0.3,0.2,0.7,0.1,0.9,0.4c0.1,0,0.3,0,0.4,0c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.5,0,0.6,0c0,0.1,0,0.3,0,0.4
        c0.1-0.1,0.2-0.2,0.3-0.2c-0.1-0.1-0.2-0.3-0.2-0.3c0.2,0.3,0.6,0.3,0.8,0.1c0-0.1,0.1-0.2,0.1-0.2c0,0.1,0,0.3,0,0.4
        c-0.1,0.1-0.2,0.2-0.3,0.4c0.3,0,0.5-0.3,0.7-0.5c0.1,0.2,0.4,0.3,0.6,0.4c0.1-0.3-0.2-0.4-0.4-0.6l0.1-0.1c0.2,0,0.3,0.1,0.5,0.1
        c-0.1,0.1-0.1,0.2-0.2,0.3c0.5,0.2,1.2,0.3,1.3-0.4c0.1,0.1,0.1,0.1,0.2,0.2c-0.2,0.1-0.3,0.2-0.4,0.4c0.2,0,0.6-0.1,0.8-0.1
        c-0.1,0.2-0.3,0.4-0.5,0.4c0.4,0,0.7-0.3,1-0.5c0.1,0.1,0.2,0.2,0.2,0.2c0.1-0.1,0.2-0.2,0.3-0.3l0,0.2c0.1,0,0.3,0.1,0.4,0.1
        c0-0.1-0.1-0.3-0.1-0.4c0.1,0,0.3,0.1,0.4,0.2c-0.1,0-0.2,0.2-0.2,0.2c0.2,0.1,0.4,0.4,0.6,0.2c-0.1-0.2-0.2-0.4-0.3-0.6
        c0.3,0.1,0.2,0.5,0.5,0.6c0.2-0.1,0.4-0.1,0.7-0.2c0.2,0.1,0.3,0.1,0.5,0.2c0,0.1-0.1,0.2-0.1,0.3c0.4,0.1,0.8-0.1,1.1-0.3
        c0.1,0,0.2,0,0.3-0.1c-0.2,0.1-0.3,0.2-0.4,0.4c0.2,0,0.5-0.1,0.6-0.1c0-0.2,0.1-0.3,0.2-0.4c0,0.1-0.1,0.3-0.1,0.5
        c0.1,0,0.3,0,0.3,0c0,0.1,0,0.2,0,0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.2-0.2,0.6-0.1,0.6,0.2
        c0.1-0.1,0.3-0.3,0.4-0.4l-0.3,0c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0.1,0.3,0.3,0.4,0.4c0,0.1-0.1,0.3-0.1,0.4c0.2-0.1,0.4-0.2,0.4-0.4
        c0.2,0.1,0.5,0.6,0.6,0.2c0.1,0,0.2,0,0.3,0c0,0,0.1,0.1,0.1,0.1c-0.1,0-0.3,0-0.5,0c0.1,0.1,0.2,0.2,0.3,0.4
        c0.2-0.1,0.4-0.2,0.6-0.4c0,0.1-0.1,0.3-0.1,0.3c0.4,0.1,0.7,0.2,1.1,0.3c0,0.1,0,0.2-0.1,0.3c0.3,0,0.7,0.1,0.9,0.3
        c-0.1,0.1-0.2,0.2-0.2,0.2c0.1,0,0.3,0.2,0.4,0.1c0.2-0.1,0.4,0.2,0.5,0.3c0.2,0.2,0.7,0.3,0.5,0.6c0.3,0.1,0.7,0.1,0.8,0.5
        c0.3,0.2,0.7,0.3,0.7,0.7c0.1,0,0.3,0.1,0.3,0.1c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0,0.4,0.1,0.5,0.1c-0.1,0.2-0.2,0.4,0,0.5
        c0-0.1,0.1-0.2,0.1-0.3c0.2,0.2,0.3,0.4,0.4,0.7c0.2,0.1,0.5,0.3,0.4,0.6c0.4,0.1,0.4,0.5,0.7,0.7c0.7,1.2,1.2,2.6,1.5,4
        c0,0.1,0.1,0.2,0.2,0.3c0.1,0.7,0.1,1.4,0.2,2.1c-0.3,0.7,0,1.5-0.3,2.2c-0.2,0.5-0.3,1-0.5,1.5c-0.3,0.3-0.5,0.6-0.5,1
        c-0.3,0.3-0.4,0.7-0.7,1c-0.1,0.3-0.3,0.5-0.6,0.7c-0.2,0.3-0.4,0.6-0.5,0.9c0-0.1-0.1-0.2-0.2-0.3c0,0.4-0.5,0.6-0.7,0.9
        c-0.2,0.1-0.4,0.3-0.6,0.5c-0.1,0-0.2,0-0.3,0c-0.1,0.2-0.3,0.4-0.5,0.5c-0.5,0.1-0.9,0.6-1.4,0.7c-0.3,0.1-0.5,0.1-0.8,0.2
        c-0.2,0-0.3,0-0.4,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.2-0.1-0.3,0-0.4,0.1c-0.3-0.1-0.6,0.1-1,0l0-0.1c-0.2,0.1-0.4,0.1-0.6,0.2
        c0,0,0.1-0.1,0.1-0.2c-0.3,0-0.5,0.1-0.7,0c-0.2,0-0.4-0.2-0.5,0.1c-0.1-0.1-0.2-0.2-0.3-0.2l0,0.3c-0.1-0.1-0.2-0.2-0.3-0.3
        c0.1,0,0.2,0,0.3,0.1c0.2,0,0.5,0,0.7-0.2c0,0.1,0,0.2,0,0.3c0.3,0,0.6-0.1,0.9-0.1c0.3,0,0.6-0.1,0.8,0.1c0.1-0.1,0.3-0.2,0.4-0.3
        c0,0.1,0.1,0.2,0.2,0.3c0-0.1,0.1-0.3,0.1-0.4c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0,0.3-0.1,0.4-0.1c0.2-0.1,0.5,0,0.7-0.2
        c0.2,0,0.4-0.1,0.6-0.1c0.2-0.1,0.5-0.2,0.7-0.3c0.3-0.2,0.7-0.5,1.1-0.6c0.2-0.2,0.4-0.4,0.8-0.4c0-0.1,0-0.2,0-0.3
        c0.1,0,0.3,0,0.4,0.1c0-0.1-0.1-0.2-0.1-0.3l0.2,0l0-0.2c0.1,0,0.2,0,0.3,0c0-0.1,0-0.2-0.1-0.2c0.3,0,0.3-0.3,0.5-0.5
        c0.3,0.1,0.3-0.2,0.2-0.4c0.1,0,0.2,0,0.3,0c0-0.1,0-0.2,0-0.3l0.2,0.1c0-0.1,0-0.4-0.1-0.5c0.1,0,0.2,0,0.3,0c0-0.1,0-0.4,0-0.5
        l0.2,0.1c-0.2-0.4,0.4-0.5,0.2-0.9h0.1c0-0.2,0.1-0.4,0.3-0.6c0-0.3,0.1-0.6,0.2-0.8c-0.1,0-0.2-0.1-0.2-0.1l0.3,0
        c0-0.3-0.1-0.5-0.2-0.7c0.1,0,0.2,0.1,0.3,0.2c-0.1-0.4,0.2-0.7,0.1-1.1c-0.3-0.5-0.1-1.2-0.1-1.8l-0.2,0c0.1-0.1,0.2-0.2,0.3-0.3
        c-0.1-0.1-0.2-0.3-0.4-0.4c0-0.3-0.1-0.8-0.5-0.8l0-0.1c0.1,0,0.3,0,0.4,0c-0.1-0.1-0.3-0.3-0.4-0.4c0-0.3,0-0.5,0-0.8l0,0
        c0,0.1-0.1,0.2-0.2,0.3c-0.1-0.2-0.1-0.4,0.1-0.6c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1-0.1-0.2-0.4-0.3-0.5c0.1,0,0.3,0,0.4-0.1
        c-0.3,0-0.4-0.1-0.3-0.4c-0.1,0.1-0.3,0.1-0.4,0.2c0-0.1,0.2-0.4,0.2-0.6c-0.1,0-0.4,0-0.5,0c0.1-0.1,0.2-0.2,0.3-0.2
        c-0.4,0-0.3-0.4-0.6-0.6c-0.2-0.1-0.3-0.3-0.4-0.5c0,0.1-0.1,0.3-0.1,0.4c-0.3-0.1-0.1-0.4-0.1-0.6c-0.2-0.4-0.5-0.6-0.9-0.8
        c0,0.1,0,0.4,0,0.5c-0.1-0.3-0.2-0.6-0.3-0.9c-0.1,0-0.3,0.1-0.4,0.2c0-0.3-0.2-0.5-0.4-0.7c0,0.2,0,0.3,0.1,0.5c0,0-0.1,0-0.1,0
        c0-0.2-0.2-0.4-0.3-0.6c0-0.1,0.1-0.2,0.2-0.3c-0.2,0-0.3,0.1-0.4,0.3c-0.2-0.2-0.5-0.3-0.3-0.6c0,0-0.1,0.1-0.2,0.2
        c-0.2-0.2-0.4-0.2-0.6-0.2c-0.3-0.3-0.7-0.5-1.1-0.6c0-0.4-0.5,0-0.6-0.3c-0.8-0.1-1.6-0.7-2.4-0.7c0,0-0.1-0.1-0.2-0.2
        c-0.1,0-0.2,0.1-0.3,0.1c0-0.1-0.2-0.2-0.2-0.2c-0.2,0-0.4-0.1-0.6-0.2c0.1,0.1,0.2,0.3,0.2,0.4c-0.5-0.1-1-0.9-1.4-0.3
        c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1,0.1-0.3,0.2-0.3,0.3c0-0.2,0.1-0.3,0.1-0.5c-0.1-0.2-0.4-0.1-0.4,0.2c-0.1,0-0.3-0.1-0.5-0.2l0,0.3
        l-0.2,0c0-0.1,0.1-0.3,0.2-0.3c-0.4,0-0.6,0.3-1,0.4c0-0.2,0.1-0.4,0.1-0.6c-0.3,0-0.6,0-1,0c0,0.2-0.2,0.4-0.4,0.6
        c0-0.1-0.1-0.3,0-0.4c-0.1,0-0.2,0-0.3,0l0.1,0.3c-0.2-0.2-0.4-0.3-0.6-0.4c0,0.2,0,0.3,0.1,0.5c0.1,0,0.2,0.1,0.3,0.2
        c0,0-0.1,0-0.2,0c-0.1,0-0.3-0.1-0.3-0.2c0-0.1,0.1-0.2,0.1-0.3l-0.3,0.1l0-0.3c-0.1,0.1-0.2,0.3-0.3,0.4l0-0.2l-0.1,0.1
        c0-0.1,0.1-0.3,0.1-0.4c-0.2,0-0.4,0.1-0.6,0.2c0.2,0.1,0.4,0.4,0.6,0.5c0.3-0.1,0.4,0.2,0.5,0.4c0.3-0.2,0.6-0.2,0.8,0.1
        c0,0.1,0,0.3,0,0.4c0.1-0.5,0.6-0.4,0.7,0c0.2,0,0.4,0.2,0.6,0.1c0.3,0.1,0.4,0.3,0.6,0.5c0.3,0.1,0.6,0.3,0.9,0.1
        c-0.1,0.1-0.2,0.2-0.3,0.3c0.3-0.1,0.6,0.2,0.6,0.5c0.1,0.1,0.3,0.2,0.4,0.2c0,0.1,0,0.2-0.1,0.3c0.1,0,0.3,0,0.5,0
        c0.2,0.2,0.3,0.4,0.4,0.7c0.2,0.1,0.5,0.2,0.5,0.5c0.1,0,0.2-0.1,0.2-0.1c0,0.2,0.2,0.4,0.3,0.6c-0.1,0.1-0.2,0.2-0.3,0.3
        c0.1,0,0.4,0,0.5,0.1l0.1-0.2c0,0,0.1,0,0.2,0c-0.1,0.1-0.3,0.2-0.3,0.4c-0.1,0.3,0.2,0.4,0.4,0.6c0.1-0.2,0-0.5,0.2-0.7
        c0,0.3,0,0.6-0.3,0.9c0,0.1,0.1,0.2,0.2,0.3c0.3,0,0.3,0.3,0.2,0.5c0.3,0,0.2,0.2,0.1,0.4l0.3-0.1c-0.2,0.4-0.3,0.8-0.4,1.1
        c0.1-0.2,0.3-0.3,0.5-0.5c0.1,0.1,0.1,0.3,0.2,0.4c-0.2,0.2-0.3,0.4-0.4,0.6c0.1,0,0.3,0,0.4,0c-0.1,0.1-0.2,0.2-0.3,0.3
        c0.1,0,0.3-0.1,0.4-0.1c-0.2,0.5-0.3,1-0.2,1.6c-0.5,0,0.1,0.6-0.4,0.7c0,0.1,0.1,0.3,0.2,0.3l-0.4-0.2c0.1,0.1,0.2,0.4,0.2,0.5
        c-0.3-0.3-0.6,0.1-0.9,0.3c0.2,0.1,0.4,0,0.6-0.3c0,0.2-0.1,0.4-0.1,0.5l-0.1-0.1c-0.1,0.3-0.6,0-0.6,0.5l-0.1,0c0,0.1,0,0.2,0,0.3
        c-0.2,0.3-0.4,0.5-0.7,0.7c-0.1,0.2-0.1,0.4-0.3,0.6c0.1-0.2,0.1-0.3,0.2-0.5c-0.3,0.2-0.6,0.4-0.9,0.6c0-0.1,0-0.4,0-0.5
        c-0.2,0.1-0.1,0.3-0.2,0.5c-0.1,0-0.2-0.1-0.3-0.1l0,0.1c0.1,0,0.2,0,0.3,0c0,0.1-0.1,0.2-0.1,0.2c-0.3-0.1-0.7,0.3-0.9,0
        c-0.1,0.2-0.1,0.3,0,0.5c-0.2-0.1-0.4-0.2-0.6-0.3c0,0.1-0.1,0.2-0.1,0.3c-0.1-0.1-0.3-0.2-0.3-0.2l-0.1,0.1l0.2,0.2l-0.2,0.1l0-0.2
        c-0.2,0.1-0.5,0.1-0.7-0.1c0,0-0.1,0-0.2,0c-0.1,0.2-0.3,0.1-0.4,0.1c-0.3,0-0.6,0.2-0.9,0.2c-0.2-0.2-0.5-0.2-0.7-0.1
        c-0.1-0.1-0.4-0.4-0.2,0c-0.1-0.1-0.3-0.2-0.5-0.3c0,0.1-0.1,0.2-0.2,0.2c-0.3-0.1-0.6-0.3-0.8,0c-0.1-0.1-0.2-0.2-0.2-0.3
        c-0.4,0-0.8,0-1.2-0.1c-0.3-0.1-0.5-0.2-0.8-0.2c-0.3-0.1-0.6-0.3-0.8-0.3c-0.3-0.1-0.5-0.2-0.8-0.4c-0.2-0.1-0.4-0.1-0.5-0.3
        c-0.2,0-0.4-0.1-0.6-0.2c0.4-0.1,0.7,0.2,1,0.4c0.6,0.3,1.3,0.4,1.9,0.7c0.3,0.1,0.6,0.1,0.9,0l0,0.2c0.1,0,0.4-0.1,0.5-0.2
        c0.6,0.3,1.2,0,1.9,0.1c0.2,0.1,0.5-0.2,0.7,0c0.1,0,0.3,0,0.3,0c0,0,0-0.1,0-0.1c0.4-0.1,0.9-0.2,1.3-0.3c0.2-0.1,0.4-0.2,0.6-0.2
        c0.2-0.1,0.4-0.3,0.6-0.4c0.1,0,0.2,0,0.3,0c0.2-0.3,0.5-0.6,0.9-0.6c0-0.2,0.1-0.3,0.2-0.5c0.5-0.3,0.8-0.9,1.1-1.5
        c0.3-0.5,0.4-1.2,0.4-1.8c-0.2-0.5-0.2-1.1-0.4-1.6c-0.2-0.1-0.3-0.3-0.3-0.5c-0.3-0.2-0.4-0.5-0.2-0.8c-0.1,0.1-0.3,0.2-0.3,0.3
        c0-0.3,0-0.7,0-1c-0.1,0.2-0.2,0.3-0.4,0.5c0-0.1,0-0.3,0-0.4l-0.2,0c0.1-0.2,0.2-0.4,0.2-0.5c-0.1,0.1-0.3,0.2-0.4,0.3
        c-0.1-0.1-0.2-0.3-0.3-0.3c-0.1,0-0.2,0-0.2,0c0-0.1,0.1-0.3,0.2-0.4c-0.2,0-0.3,0.1-0.5,0.1c0.1-0.1,0.1-0.2,0.2-0.3
        c-0.1-0.1-0.3-0.3-0.4-0.4c-0.2,0-0.3,0.1-0.5,0.1c-0.1-0.1-0.1-0.3-0.2-0.4c0.1,0.1,0.3,0.2,0.4,0.2c0.1-0.1,0.4-0.4,0-0.5
        c-0.3,0.1-0.6-0.2-0.9-0.1l0.1-0.3c-0.1,0-0.3,0.1-0.4,0.1c0-0.1-0.1-0.2-0.1-0.3c-0.1,0-0.3,0-0.4,0c-0.2-0.2-0.4-0.4-0.5-0.6
        c0,0.2,0,0.4-0.2,0.6c0.1-0.4-0.3-0.7-0.7-0.7c0,0.1,0.1,0.2,0.1,0.3c0,0.2,0.5,0.1,0.4,0.4c-0.2-0.1-0.4-0.1-0.6-0.2
        c0-0.2,0-0.3,0.1-0.5c-0.4-0.2-0.7-0.4-1.1-0.5c-0.1,0-0.2,0.1-0.3,0.1c0-0.1,0-0.3,0-0.4c-0.5,0.1,0,0.4,0.1,0.7
        c0.2,0.3,0.7,0.4,0.5,0.9c0-0.1,0.1-0.2,0.1-0.3l0.3,0.1c0,0.1-0.1,0.2-0.1,0.3c0.1,0.1,0.3,0.1,0.4,0.2c0,0.1-0.1,0.3-0.1,0.4
        c0.2,0.2,0.3,0.3,0.5,0.5c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1-0.1,0.2-0.1,0.3c0.2,0.1,0.3,0.2,0.1,0.5c0.1,0,0.2,0.1,0.2,0.2
        c-0.1,0-0.2,0-0.3,0l0,0.3c0.1,0,0.2,0,0.3,0c-0.1,0.2-0.2,0.4-0.2,0.7l0.2-0.1c0,0.1,0,0.3,0,0.4l0.2,0c0,0-0.1,0.1-0.2,0.1
        c-0.1-0.1-0.2-0.2-0.3-0.4l-0.1,0.5c-0.1-0.1-0.2-0.2-0.2-0.4c-0.3,0.2,0.2,0.5,0.2,0.7c-0.3,0.1-0.5,0.4-0.7,0.7l-0.2-0.1
        c-0.1,0.1-0.1,0.2-0.2,0.3c0.1-0.1,0.3-0.2,0.4-0.3c-0.1,0.2-0.2,0.5-0.2,0.6c-0.2,0-0.4-0.1-0.5-0.3c0.1,0.2,0.1,0.6,0.4,0.4
        c-0.2,0-0.4-0.1-0.5,0.1c-0.1,0.2-0.2,0.7-0.2,0.2l-0.2,0c-0.1,0.2-0.3,0.4-0.5,0.3l0,0.1c-0.1,0-0.3,0-0.4,0
        c-0.3,0.2-0.6,0.4-1,0.3c-0.4,0-0.9-0.2-1.1,0.1c-0.2-0.2-0.4-0.3-0.6-0.4l0,0.3c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2,0-0.4,0-0.6-0.1
        c-0.1,0-0.2-0.1-0.3-0.2c-0.1,0-0.4,0-0.5-0.1l0,0.2c0-0.1,0-0.2,0-0.2c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.1c0.4,0,0.8,0,1.3,0
        c0.3,0.3,0.6-0.1,1-0.1c0.7,0,1.2-0.5,1.9-0.5c0.5-0.3,0.8-0.9,1.3-1.1c0.2-0.5,0.6-0.9,0.6-1.5c0.4-0.2-0.1-0.5-0.2-0.7
        c0.1-0.1,0.2-0.2,0.2-0.2c-0.1,0-0.3,0-0.4,0c0.1-0.1,0.2-0.2,0.2-0.3c-0.3-0.2-0.6-0.4-1-0.5c0.3-0.1,0.6,0.3,0.8-0.1
        c-0.5-0.1-0.8-0.5-1.1-0.9c-0.3-0.2-0.6-0.3-0.8-0.6c0-0.1,0.1-0.2,0.2-0.3c-0.2,0.2-0.4,0.3-0.7,0.3c0.1-0.1,0.2-0.3,0.2-0.4
        c-0.1,0-0.3,0-0.4,0c0,0.1,0,0.2,0.1,0.3c-0.1,0-0.4-0.1-0.5-0.1c0-0.1,0.1-0.2,0.1-0.3c-0.2-0.1-0.4-0.2-0.7-0.3
        c-0.2,0-0.3-0.1-0.5-0.1c0,0.1-0.1,0.2-0.1,0.3c-0.2-0.2-0.1-0.3,0-0.5c-0.1,0-0.3,0-0.4,0.1c0-0.1,0-0.2,0-0.3
        c-0.2,0.1-0.4,0.3-0.6,0.4c0-0.1,0-0.3,0.1-0.4c-0.3,0-0.5,0.1-0.7,0.2c0-0.1,0-0.2,0-0.3c-0.2,0-0.3,0-0.5,0
        c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0,0.3,0.1,0.5,0.2c0.6,0.4,1.2,0.9,1.7,1.4c0,0,0,0.1-0.1,0.2c0.4,0.1,0.2,0.5,0.1,0.7
        c0.1-0.1,0.3-0.2,0.4-0.3c0,0.3,0,0.5,0,0.8c-0.2,0-0.3-0.1-0.4-0.1c0.2,0.2,0.3,0.3,0.5,0.5c-0.1,0.2-0.3,0.3-0.4,0.5
        c0.2,0,0.4,0.1,0.6,0.1c-0.1,0-0.3,0-0.4,0c-0.1,0.3-0.3,0.4-0.5,0.6l0.3,0c0,0,0,0.1,0,0.1c-0.1,0-0.3,0-0.4,0
        c0,0.1,0.1,0.3,0.1,0.4l-0.1,0l0-0.2c-0.2,0.2-0.4,0.4-0.6,0.3l-0.1,0.2c-0.3-0.1-0.4,0.1-0.7,0.2c0-0.1,0-0.2-0.1-0.3l0,0.3
        c-0.1,0-0.2-0.1-0.3-0.1l0,0.2c-0.4,0.1-0.9,0.2-1.3,0.2c0.1-0.4,0.4-0.3,0.7-0.5c0.5-0.5,1-1,1.3-1.7c-0.1,0-0.2-0.1-0.3-0.2
        c0.5-0.2,0.1-0.5-0.1-0.7c0-0.1,0-0.3,0-0.4c-0.1,0-0.3,0.1-0.5,0.1c0-0.1,0.1-0.3,0.1-0.4c-0.1,0-0.3,0-0.4,0
        c0.1-0.2,0.2-0.4,0.5-0.4c0,0,0-0.1,0-0.1c-0.1,0.1-0.3,0.2-0.3,0.2c0-0.1-0.1-0.2-0.1-0.2c-0.1,0.1-0.2,0.7-0.4,0.4l0-0.3
        c-0.2,0-0.4,0.1-0.5,0.1c0,0-0.1-0.1-0.1-0.2l-0.3,0c0-0.1,0.1-0.3,0.1-0.4c-0.1,0.1-0.2,0.3-0.2,0.4c-0.2-0.2-0.4-0.8-0.7-0.6
        c-0.2,0.1-0.3,0.2-0.5,0.3c0-0.2,0-0.4,0-0.5c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.4,0.1-0.5,0.1c0.1-0.1,0.2-0.3,0.2-0.4l-0.1,0l0,0.2
        c-0.1,0-0.2,0-0.2,0c0,0.1,0,0.2,0,0.2c-0.4,0-0.8,0.1-1.1,0.3c-0.2,0.1-0.4,0.3-0.5,0.4c-0.4-0.4-0.2,0.6-0.7,0.2l0,0.1l-0.3,0
        c0,0.1,0.1,0.3,0.2,0.3c-0.1,0-0.3,0-0.4,0c0,0.3,0,0.7,0,1c-0.3,0.1-0.2,0.4-0.5,0.5c0-0.4,0.8-0.8,0-0.8c0,0.1,0,0.2,0,0.3
        c0,0-0.1,0.1-0.1,0.1l0,0.2c-0.3,0-0.5,0.1-0.6,0.3c0.3-0.1,0.6,0,0.8,0.1c-0.3,0.1-1-0.3-0.8,0.3c-0.2-0.1-0.4-0.1-0.6-0.2
        c0,0.3,0.1,0.5,0.2,0.8c-0.1-0.1-0.3-0.2-0.4-0.2c0,0.3-0.1,0.5-0.3,0.6c0.2,0,0.4-0.1,0.6-0.2c-0.2,0.4-0.9,0.3-0.6,0.9
        c-0.2-0.2-0.3-0.3-0.5-0.5c0.1,0.2,0.2,0.4,0.3,0.6c0.2-0.2,0.3-0.2,0.5,0c-0.1,0-0.3,0.1-0.4,0.1c0.1,0.1,0.2,0.3,0.3,0.4
        c-0.2,0-0.3,0.1-0.5,0.1c0,0.1,0.1,0.3,0.2,0.4l-0.3,0c0,0.4,0.7,0.5,0.5,1c0.1-0.1,0.2-0.2,0.3-0.3c-0.1,0.2,0,0.5-0.4,0.5l0.1,0.2
        c-0.1,0-0.2,0.1-0.3,0.1c0.2,0.2,0.5,0.3,0.6,0.6c-0.1-0.1-0.3-0.2-0.3-0.3c-0.1,0.1-0.2,0.3-0.2,0.3c0.1,0.2,0.3,0.2,0.5,0.4
        c0,0-0.1,0-0.2,0l-0.1-0.1c-0.1,0-0.3,0.1-0.3,0.1c-0.1,0.1-0.1,0.2-0.2,0.4l0.3,0.1c-0.1,0.1-0.2,0.3-0.3,0.4
        c-0.3,0-0.3,0.2-0.1,0.4c-0.6-0.1-0.3,0.1-0.2,0.4c0.3-0.2,0.6-0.4,0.9-0.3l0-0.2c0.1,0,0.3,0,0.4,0l0,0.2c0.1,0,0.2,0,0.3,0
        c0,0,0,0.1,0,0.1c-0.1,0-0.3,0-0.4,0l0,0.2l-0.2,0l0.1,0.2c-0.1,0-0.3,0-0.3,0l0-0.3c0,0.1-0.1,0.2-0.1,0.3l-0.1-0.1
        c0,0.1,0,0.3-0.1,0.4c0.1,0,0.3-0.1,0.4-0.1c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0,0.3,0,0.4c0.1,0,0.3,0,0.5,0c-0.1,0-0.3,0-0.4,0.1
        c0.7,0.7,1.6,1.1,2.5,1.4c0.4,0,0.8,0.1,1.2-0.1c0.1-0.1,0.2-0.1,0.4-0.2c-0.2,0.3-0.5,0.4-0.8,0.4c-0.2,0.2-0.6,0.1-0.5,0.5
        c-0.1,0-0.3,0.1-0.4,0.1c0,0-0.1,0.1-0.1,0.2l-0.2,0.1l0-0.3c0,0-0.1,0-0.2,0c0,0.1,0.1,0.3,0.1,0.4c-0.2-0.1-0.4-0.2-0.6-0.2
        c-0.2,0.2,0.3,0.3,0.4,0.4c-0.2,0.1-0.4,0.1-0.5,0.2c0,0-0.1-0.1-0.2-0.1c-0.2,0-0.4-0.1-0.6-0.2c0.1,0.1,0.2,0.2,0.3,0.2
        c0,0.2,0,0.3,0,0.5c0.1,0,0.2,0,0.3,0c-0.1,0.5,0.4,0.4,0.7,0.6c0.4-0.1,0.9,0,1.3,0.1c0.4,0,0.7,0,1.1,0.2c0.5-0.1,0.8,0.3,1.3,0.2
        c0.3,0,0.6-0.1,0.8,0.2c0.6-0.1,1.3,0.1,1.9,0.1c0.9,0.1,1.9,0.2,2.8,0.1c-0.2,0-0.4,0-0.6,0.1l0,0.1c-0.2-0.1-0.5,0-0.7,0
        c-0.1,0-0.2,0-0.2,0c-0.6-0.1-1.2,0.3-1.8,0.2l0,0.1c-0.3,0.1-0.9,0-0.9,0.5c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.2,0-0.2
        c-0.1,0-0.3,0-0.4,0l-0.1,0.2l0.3,0c-0.2,0.4-0.6,0-0.9,0c-0.1-0.1-0.2-0.2-0.2-0.3c0.1,0.1,0.2,0.3,0.2,0.4c-0.1,0-0.4,0.1-0.6,0.1
        c0.4-0.3,0-0.4-0.2-0.5c-0.2-0.1-0.3,0.1-0.5,0.2c-0.1-0.1-0.2-0.2-0.3-0.2c0.1,0.2,0.2,0.4,0.3,0.5c-0.4,0.1-0.6-0.2-0.8-0.5
        c0,0.2,0,0.4,0,0.6l0.2,0l0,0.2c-0.2-0.2-0.3-0.6-0.7-0.7c0,0.2,0.1,0.3,0.2,0.5c-0.2-0.1-0.4-0.3-0.6-0.4c0.1-0.1,0.2-0.2,0.3-0.2
        l0.1,0c0-0.1,0-0.2,0-0.2c-0.2,0.2-0.4,0.5-0.6,0.6l0-0.3c-0.1,0-0.3,0.1-0.4,0.2c0-0.1,0.1-0.3,0.1-0.3c-0.1,0-0.2,0-0.3,0
        c0.1,0.2,0.1,0.3,0.2,0.5c-0.2-0.2-0.7-0.7-0.9-0.2c-0.1-0.1-0.2-0.2-0.2-0.2c0.1,0.3,0.3,0.6-0.2,0.6c0,0.1,0.1,0.2,0.1,0.3
        c-0.2,0-0.5,0-0.7,0c0.1,0.1,0.4,0.4,0.1,0.5c0,0.1,0,0.2,0,0.4c0.4,0.4,1,0.5,1.5,0.7c0.6,0.3,1.2,0.3,1.8,0.4
        c-0.3,0.1-0.6,0.2-0.9,0.2c0,0.3-0.4,0.2-0.6,0.2c-0.1,0.3-0.5,0.2-0.8,0.4c-0.2,0.2-0.4,0.2-0.6,0.2c-0.2,0-0.3-0.1-0.4-0.1
        c-0.1,0-0.3,0.1-0.4,0.1c0.2,0,0.3,0.2,0.5,0.4c-0.2,0-0.7,0.1-0.2,0.3c-0.1,0.2-0.5,0.5-0.3,0.7c0.2,0.3,0.4,0.5,0.6,0.8
        c0.2,0,0.4,0.1,0.5,0.3c0.3,0.3,0.7,0.6,1.1,0.7c-0.1,0-0.2,0.1-0.3,0.1c0,0.1-0.1,0.2-0.1,0.2l-0.3,0c0.1,0.1,0.2,0.2,0.3,0.4
        c-0.3-0.1-0.5-0.5-0.9-0.3c0.1,0.1,0.2,0.2,0.2,0.3c-0.3,0-0.5-0.1-0.8-0.1c0,0.2,0.2,0.3,0.4,0.4c-0.2,0-0.4-0.1-0.6-0.1
        c0,0.2,0,0.4,0.1,0.6l-0.2,0c0,0.1,0.1,0.2,0.1,0.3l-0.3-0.1c0.1,0.2,0.2,0.4,0.3,0.6c-0.1-0.1-0.2-0.3-0.2-0.4
        c-0.1,0.1-0.2,0.3-0.2,0.4c0.1,0,0.3,0,0.3,0c-0.1,0.2-0.2,0.4-0.2,0.5c0.2-0.1,0.5-0.2,0.5,0.2l-0.2,0.1c0.2,0.1,0.5-0.1,0.7,0
        c0,0.2,0.1,0.3,0.1,0.5c0.1-0.1,0.2-0.2,0.3-0.3c0.2,0,0.3,0.1,0.3,0.4c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0.1,0.1,0.3,0.2,0.4
        c0.2,0,0.4,0,0.6,0c-0.1,0.2-0.3,0.3-0.4,0.4c-0.2,0-0.4,0-0.5,0l0,0.3c0.1,0,0.4-0.1,0.5-0.2c-0.1,0.2-0.3,0.3-0.5,0.3
        c-0.1,0.2-0.3,0.3-0.4,0.4c0-0.1,0.1-0.3,0.1-0.4l-0.4,0l0.1,0.2l-0.2,0c0,0.2-0.1,0.4-0.2,0.6c0-0.2,0.1-0.4,0.1-0.6
        c-0.2,0.1-0.4,0.2-0.5,0.4c0,0.2,0,0.4-0.2,0.5l0.2,0.2c-0.6-0.3-0.2,0.5-0.5,0.8c0.1,0,0.2,0,0.3,0c0.1,0.1,0.2,0.2,0.3,0.2
        c0,0.1-0.1,0.2-0.1,0.3c0.1,0,0.2,0,0.3,0l0.1,0.5c-0.1-0.1-0.4-0.2-0.5-0.2c0,0.3,0.4,0.4,0.5,0.5c0.2,0.2,0.5,0.3,0.7,0.5
        c0,0,0,0.1,0.1,0.2l0.1,0l0.3-0.2c0,0.1-0.1,0.4-0.1,0.5c0-0.1,0.1-0.2,0.1-0.3c0.2,0,0.5,0,0.7,0.1c0,0.1,0,0.2,0,0.2
        c-0.4,0.1-0.6,0.7-1.1,0.6c0,0.1,0.1,0.2,0.1,0.3c-0.2,0.2-0.4,0.3-0.6,0.5c-0.1-0.1-0.1-0.2-0.1-0.3c-0.2,0.1-0.4,0.2-0.6,0.3
        c0.2,0,0.3,0.1,0.5,0.2c-0.2,0-0.4,0-0.6,0l0.1,0.2l-0.2-0.1c0,0.1,0,0.2,0,0.3l-0.1,0.1c0,0.1,0,0.3,0,0.4c0.1,0,0.2-0.1,0.3-0.1
        c0,0.3-0.3,0.7-0.2,1c0.1-0.2,0.2-0.3,0.3-0.5c0.1,0.2,0.2,0.4,0.3,0.6c0-0.2-0.6-0.3-0.4,0c0,0.3,0.3,0.2,0.5,0.2
        c0,0.2,0,0.3,0,0.5c0.3-0.2,0.5,0,0.8,0.1c0.2,0,0.4,0,0.6,0c0,0.1,0,0.2,0,0.2c-0.4,0.2-0.6,0.7-1.1,0.7c0.1-0.5-0.7,0,0,0.2
        c-0.1,0.1-0.4,0.2-0.5,0.2l0,0.3l-0.3-0.2c-0.5,0.1,0,0.8-0.2,1.1c0.3,0.8,0.3,1.6,0.5,2.4c0,0.2,0.4,0.4,0,0.5
        c0.3,0.2,0.4,0.5,0,0.6c0,0.6,0.5,1,0.4,1.6c-0.1,0.3,0.1,0.7,0.1,1c-0.1,0.6,0.4,1.1,0.2,1.7c0.2,0.6,0.6,1.2,0.4,1.9
        c0,0.2,0.2,0.4,0.3,0.6c0,0.2,0,0.5,0,0.7c0.1,0.2,0.3,0.5,0.4,0.7c0.1,0.3,0,0.8,0.2,1.1c0.3,0.3,0.2,0.7,0.4,1.1
        c0.1,0.2,0.1,0.4,0.1,0.6c0.4,0.9,0.7,1.9,1,2.9c0.6,1.1,1,2.2,1.4,3.3c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.3,0,0.4
        c0.4,0.4,0.6,1,0.7,1.6c0.5,0.5,0.2,1.3,0.8,1.7c0,0.3,0.1,0.7,0.4,0.9c0.1,0.4,0.2,1,0.5,1.3c0.2,0.1,0,0.4,0.3,0.5
        c0,0.3,0.3,0.5,0.4,0.8c0,0.3,0.4,0.5,0.7,0.6c-0.1,0.1-0.1,0.2-0.2,0.4c0.2,0.1,0.3,0.2,0.5,0.3c0,0.2,0.1,0.4,0.1,0.6
        c0.4,0.4,0.5,0.9,0.7,1.3c0.1,0,0.2,0.1,0.3,0.1c-0.1,0.3,0.4,0.4,0.3,0.7c0.1,0,0.2,0,0.3,0c0,0.2,0.1,0.5,0.1,0.7
        c0.2,0.2,0.7,0.3,0.6,0.8c0,0.2,0.3,0.2,0.4,0.3c0,0.2,0.1,0.4,0.2,0.6c0.1,0,0.2,0.1,0.2,0.1c0,0.2,0.2,0.4,0.4,0.5
        c0.2,0.3,0.4,0.7,0.6,1c0.3,0.1,0.5,0.4,0.4,0.7c0.1,0,0.3,0.1,0.4,0.1c0,0.4,0.4,0.6,0.4,1c0-0.1,0.1-0.3,0.2-0.3
        c0.5,0.6,0.9,1.2,1.5,1.8c0,0.1-0.1,0.2-0.1,0.3c0.2,0.1,0.4,0.2,0.6,0.3c0,0.2-0.1,0.5,0.2,0.5c0.3,0.1,0.6,0.3,0.3,0.6
        c0.5,0.2,0.8,0.7,1.3,1c-0.1,0.1-0.1,0.3-0.2,0.4c0.2-0.1,0.4-0.2,0.6-0.2c0,0.5,0.5,0.5,0.7,0.8c0,0.2,0,0.4,0.1,0.5
        c0.1,0,0.3-0.1,0.4-0.2c0,0.1,0.1,0.4,0.2,0.5c0.1-0.1,0.2-0.2,0.4-0.3c-0.1,0.2-0.2,0.3-0.4,0.5c0.4-0.2,0.7,0.2,1,0.3
        c0,0.3,0.2,0.5,0.5,0.7l0-0.3c0,0,0.1-0.1,0.2-0.1c0,0.1-0.1,0.3-0.1,0.4c0.2-0.1,0.4,0,0.5,0.2c-0.1,0.1-0.2,0.3-0.4,0.4
        c0.1,0.1,0.2,0.1,0.3,0.2c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.3-0.1,0.4-0.2c-0.1,0.4,0.3,0.5,0.6,0.6c0.2,0.2,0.1,0.5,0.1,0.7
        c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0.1,0.3,0.3,0.5,0.4c0.1,0.2,0.2,0.4,0.4,0.5c0.1,0,0.2,0,0.3,0c0.2,0.1,0.4,0.3,0.3,0.6
        c0.1,0,0.3,0,0.4,0c0.1,0.5,0.6,0.9,1,0.6c0,0.1,0.1,0.2,0.2,0.2c0.3-0.1,0.6,0.1,0.5,0.5c0.1,0.1,0.2,0.2,0.2,0.2
        c0.2,0.1,0.3,0.1,0.5,0.2c0-0.3-0.2-0.3-0.4-0.4c0.1-0.2,0-0.3-0.2-0.4c0.1,0,0.2,0.1,0.3,0.1c0.2,0.3,0.5,0.8,0.9,0.6
        c-0.4,0.3-0.1,0.5,0.3,0.5c0.1,0,0.2,0.1,0.3,0.1c0,0.1,0.1,0.2,0.1,0.3c0.3,0,0.5,0.1,0.6,0.4c0.1,0,0.2-0.1,0.3-0.2
        c0,0.1,0,0.4,0,0.5c0.2,0,0.3-0.1,0.5-0.1c0-0.1-0.1-0.2-0.1-0.3l0,0c0.5,0.2,1,0.4,1.5,0.7c-0.1,0.1-0.2,0.2-0.2,0.2
        c0.1,0,0.3,0.1,0.4,0.2c0.1-0.1,0.3-0.1,0.4,0c0.1,0,0.2-0.1,0.2-0.1c0.3,0.1-0.1,0.3-0.2,0.4c0,0.1,0,0.3,0.2,0.4
        c0-0.1,0-0.3,0-0.4c0.2,0,0.4,0,0.6,0c0-0.1,0.1-0.2,0.1-0.2c0,0.1,0,0.2,0,0.3c0.2,0.1,0.6,0,0.6,0.4c0.1,0.1,0.2,0.2,0.3,0.3
        c0.1,0,0.3-0.1,0.4-0.1c0,0.1,0.1,0.4,0.1,0.6c0.1-0.1,0.2-0.3,0.3-0.4c0.1,0,0.2,0,0.3,0.1l0,0.1l0.4,0l0-0.3
        c0.2,0.3,0.6,0.4,0.9,0.2c-0.1,0.3-0.7,0.1-0.5,0.5c0.1,0,0.4,0,0.5,0.1c0.1,0.1,0.3,0.1,0.5,0.2c0-0.1,0-0.3,0-0.3
        c0.2,0.2,0.5,0.2,0.8,0.3c0,0.3,0.2,0.3,0.5,0.2l0,0.3c-0.1,0-0.3-0.1-0.4-0.1c0.2,0.3,0.4,0.1,0.7,0c-0.1,0.5,0.8,0.6,1,0.1
        c0,0.2,0,0.4,0.2,0.5c0.2,0,0.3-0.2,0.4-0.3c0,0.1,0,0.3,0,0.5c0-0.1,0-0.3,0-0.4c0.2,0.3,0.4,0.5,0.8,0.5c-0.1-0.1-0.3-0.2-0.4-0.3
        c0.4-0.2,0.4,0.5,0.8,0.3c-0.1-0.2-0.1-0.3-0.1-0.5c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.1,0.3,0.2,0.5,0.3c0.2-0.1,0.4-0.3,0.6-0.5
        c0,0.1,0,0.3,0,0.4l0,0l-0.1-0.2l-0.1,0.1c-0.3,0.4,0.2,0.3,0.5,0.3c-0.1-0.1,0.2-0.7,0.2-0.2c0.2,0,0.2,0.2,0.4,0.4
        c0.3,0,0.6-0.2,0.8-0.4c0,0.1-0.1,0.3-0.1,0.4c0.3,0,0.9,0.6,0.9,0c0.2,0.1,0.5,0.3,0.7,0.4c0.1-0.1,0.1-0.2,0.1-0.3
        c-0.2-0.1-0.7,0-0.5-0.3c0.1,0,0.3-0.1,0.4-0.1l-0.1,0.2c0.2,0.1,0.4,0.2,0.4,0.4c0.1,0,0.3,0,0.4,0c-0.1-0.2-0.2-0.3-0.2-0.5
        c0.2,0.1,0.3,0.3,0.4,0.5l0-0.3c0.3,0.1,0.1,0.2,0,0.3c0.1,0,0.2,0.1,0.3,0.1c0.3,0.2,0.4-0.3,0.1-0.4c0.2-0.3,0.3,0.1,0.3,0.3
        c0.4-0.2,0.9,0,1.3-0.3c0.2,0.4-0.2,0.6-0.3,0.9c0.3-0.1,0.4-0.3,0.6-0.6c0,0.4,0.4,0.3,0.7,0.4c0.2-0.1,0.4-0.1,0.6-0.2
        c0-0.1,0-0.4,0-0.5c0.2,0.2,0.3,0.4,0.5,0.6c0.2,0,0.3,0,0.5,0.1c0-0.1-0.5-0.5-0.1-0.4c0.3,0.1,0.4-0.2,0.6-0.4l0,0.3
        c0.1,0,0.3-0.1,0.4-0.1c-0.2,0.3-0.4,0.6-0.6,0.9c0.2,0,0.4-0.2,0.6-0.3l0-0.2c0.2,0,0.4,0,0.6,0c0.1,0.2,0.2,0.3,0.3,0.5
        c0-0.5,0.6-0.3,0.9-0.4c0,0.1,0,0.3,0,0.4c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.3,0,0.4,0.1c-0.2-0.2-0.5-0.3-0.6-0.6
        c0.2,0,0.5,0.1,0.7,0.2c0.1-0.2,0.2-0.3,0.3-0.5c0,0.1,0,0.2,0.1,0.3c0.1,0,0.2-0.1,0.3-0.1c0.2,0.3-0.3,0.7-0.4,0.3
        c-0.1,0.1-0.2,0.2-0.2,0.3c0.1,0.1,0.4,0.3,0.5,0c0.1-0.1,0.2-0.1,0.3-0.2c-0.1-0.4,0.2-0.5,0.6-0.5c0,0.4,0.4,0.3,0.7,0.3
        c0.1-0.1,0.2-0.3,0.4-0.4c0.1-0.1,0.3-0.2,0.3-0.3c-0.2,0.3,0.1,0.3,0.3,0.4c0-0.1,0.1-0.4,0.2-0.5c0,0.1,0.1,0.3,0.1,0.4
        c0.1,0.1,0.2,0.2,0.3,0.3c-0.2-0.2-0.1-0.4,0.1-0.4c0-0.1-0.1-0.2-0.1-0.3c0.5,0,1.1-0.3,1.4,0.2c0.1-0.2-0.1-0.3-0.2-0.4
        c0.3,0.2,0.8-0.5,1,0c-0.4,0-0.5,0.3-0.4,0.6c0.3-0.2,0.5-0.6,0.7-0.9c-0.1,0.3,0,0.4,0.3,0.4c-0.1-0.1-0.1-0.3-0.1-0.4
        c0.3-0.1,0.5-0.3,0.8-0.2c-0.1,0.2-0.4,0.3-0.6,0.5c0.5,0.3,0.6-0.4,0.9-0.6c0.1,0.1,0.2,0.2,0.3,0.3c0.1-0.1,0.2-0.4,0.2-0.5
        c0.1,0.1,0.2,0.2,0.4,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c0.3,0.1,0.8-0.5,0.3-0.6l0-0.1c0.2,0.1,0.4,0.2,0.6,0.3
        c-0.1-0.1-0.2-0.3-0.2-0.4c0.2-0.1,0.3-0.1,0.5-0.2c-0.1,0.2-0.2,0.4-0.4,0.6l0.3-0.1l0,0.2c0.1-0.1,0.3-0.4,0.4-0.5
        c0.1,0.1,0.2,0.2,0.3,0.3c0-0.1,0-0.2,0-0.2l-0.2,0c0.3-0.2,0.6-0.4,1-0.5c0,0.1-0.1,0.3-0.2,0.4c0.2-0.1,0.4-0.2,0.6-0.2
        c-0.1-0.2-0.2-0.3-0.3-0.4c0.1,0,0.3,0.1,0.4,0.1c0.2-0.1,0.3-0.3,0.6-0.3c-0.1,0.1-0.3,0.3-0.4,0.4c0.2,0.1,0.4-0.1,0.6-0.2
        c0.2,0,0.4,0,0.6,0c0-0.1-0.1-0.3-0.2-0.4c0.2,0,0.5,0.1,0.7,0.1c-0.1-0.2-0.1-0.3-0.2-0.4c0.2,0.2,0.3,0.4,0.6,0.4
        c-0.1-0.2-0.1-0.8-0.5-0.5c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0.1,0.2,0.2,0.3,0.4c0.1-0.1,0.4-0.3,0.5-0.4c0,0.1,0,0.3-0.1,0.4
        c0.1-0.1,0.3-0.2,0.4-0.3c-0.1-0.1-0.2-0.2-0.3-0.4c0.2,0,0.3,0,0.5,0c0,0.1,0.1,0.2,0.2,0.3c0.2-0.1,0.3-0.2,0.3-0.4
        c0.3,0,0.6-0.5,0.9-0.2c0-0.1,0.1-0.1,0.1-0.2c-0.1,0-0.3,0-0.3,0c0.1-0.3,0.3-0.4,0.6-0.4c0.2-0.1,0.3-0.3,0.4-0.5
        c0.1,0,0.2,0.1,0.2,0.2c0.3-0.2,0.4-0.5,0.6-0.8c0.1,0,0.2,0,0.3-0.1c-0.1,0.2-0.3,0.3-0.4,0.5c0.3,0,0.6-0.4,0.6-0.7
        c0,0,0.2,0.1,0.2,0.1c0.1,0,0.3,0,0.4,0c-0.1,0-0.2-0.1-0.2-0.1c0.1-0.2,0.2-0.3,0.3-0.5c0.1,0,0.3,0.1,0.4,0.1c0-0.1,0-0.3,0-0.4
        c0.2-0.1,0.6-0.2,0.6-0.5c0.3-0.2,0.5-0.7,1-0.6c-0.1-0.2-0.3-0.3-0.5-0.2l0,0c0.1-0.1,0.2-0.2,0.3-0.3c-0.1,0.3,0.1,0.3,0.4,0.4
        c0.1-0.4,0.5-0.6,0.5-1c0.1,0,0.4,0,0.6,0c0-0.1,0-0.4,0-0.5c-0.1,0-0.3,0.1-0.4,0.1c0.1-0.1,0.2-0.2,0.3-0.2c0-0.1,0.1-0.2,0.2-0.3
        c0,0.1,0,0.3,0,0.4l0.2,0c0-0.1-0.1-0.4-0.1-0.5c0.1,0,0.3,0.1,0.4,0.2c-0.1-0.1-0.2-0.2-0.2-0.3c0.2-0.1,0.4-0.1,0.5-0.2
        c0-0.1,0-0.4-0.1-0.5c0.2,0.1,0.5,0.3,0.7,0c-0.1,0-0.4,0-0.5,0c0.3-0.3,0.4-0.9,0.9-1l0-0.2l0.2,0c0-0.1-0.1-0.2-0.1-0.3
        c0.1,0,0.3,0,0.4,0c-0.1-0.1-0.2-0.2-0.2-0.4c0.2-0.1,0.4-0.2,0.6-0.3c0,0.2,0,0.3,0,0.5c-0.3,0-0.4,0.3-0.3,0.6l0,0
        c0.2-0.2,0.4-0.4,0.6-0.7c0.4,0.1,0.3-0.3,0.3-0.5c-0.1,0-0.2,0-0.2,0c0,0.1,0.1,0.4,0.1,0.5c-0.1-0.1-0.3-0.1-0.4-0.2
        c-0.1-0.3-0.1-0.6,0.2-0.7c0,0.1,0.1,0.2,0.2,0.2c0.2-0.2,0.4-0.1,0.7,0c-0.1-0.1-0.2-0.2-0.4-0.3c0-0.4,0.1-0.7,0.1-1.1
        c0.1-0.2,0.1-0.4,0.3-0.4c0,0.2-0.1,0.3-0.2,0.5c0.1,0.1,0.2,0.2,0.3,0.3l-0.3,0.1l0.1,0.2c0.2-0.2,0.4-0.4,0.7-0.6
        c-0.1,0-0.3,0.1-0.4,0c-0.2-0.3,0.6-0.3,0-0.4c0-0.1,0-0.3,0-0.4c0.1-0.1,0.3-0.1,0.5-0.2c0.1-0.1,0.3-0.2,0.4-0.3
        c-0.1-0.2-0.2-0.3-0.2-0.5c-0.2-0.1-0.2-0.3-0.2-0.5c0.1,0.1,0.3,0.3,0.5,0.5l-0.1-0.2c0.2-0.2,0.4-0.2,0.7-0.1
        c-0.1-0.2-0.3-0.2-0.5-0.1l0-0.3c-0.1,0.1-0.3,0.2-0.3,0.3l0-0.2c0.3,0,0.5-0.2,0.6-0.4c-0.1,0-0.4,0.1-0.5,0.1
        c0.1-0.1,0.3-0.2,0.2-0.3l0-0.1c0.1-0.1,0.3-0.3,0.4-0.4c-0.1-0.2-0.1-0.3-0.2-0.5l0.3,0c0-0.1,0-0.2,0-0.2c0,0,0.1,0,0.2,0
        c-0.2-0.4,0.3-0.2,0.4-0.4c0.1-0.3-0.1-0.7-0.3-0.9c0,0,0.1,0,0.2-0.1c0,0,0-0.1,0-0.2c0.1-0.1,0.1-0.3,0.2-0.4
        c-0.1-0.1-0.1-0.2-0.1-0.4c0.1,0.1,0.3,0.2,0.4,0.2c-0.2,0.2-0.6,0.8-0.2,0.9c0.2-0.2,0.2-0.4,0-0.6c0.2,0.1,0.4,0.2,0.6,0.3
        c-0.3-0.3-0.2-0.7-0.5-1c0.1-0.2,0.2-0.3,0.3-0.5c-0.1,0-0.4,0-0.5,0c0.3-0.3,0.7-0.5,0.9-0.9c-0.4,0-0.6,0.3-0.8,0.5
        c0-0.1,0.1-0.3,0.1-0.3c0-0.1,0-0.4,0-0.5c0.1,0.1,0.4,0.3,0.6,0.2c-0.5-0.2,0-0.6,0.1-0.9c0-0.3,0-0.7,0.3-0.8
        c0.3-0.2-0.3-0.1-0.4-0.1c0.1-0.3,0.4-0.4,0.6-0.6c0,0.1,0,0.4,0,0.5c0.2,0.1,0.2-0.2,0.2-0.3l-0.2,0c0-0.1,0-0.2,0-0.3
        c-0.2-0.3-0.4,0-0.3,0.3c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1,0.1-0.2,0.4-0.2,0.6c-0.3-0.2,0-0.5,0.1-0.8l-0.3,0c0,0,0-0.1,0-0.1l0.2,0.1
        l0-0.2c0,0,0.1,0,0.1,0l0,0.2l0.3-0.1l-0.1,0.3c0.1,0,0.2,0,0.3,0c-0.1-0.3-0.1-0.6-0.2-0.9c-0.1,0.1-0.3,0.2-0.4,0.3
        c-0.1-0.3,0.1-0.6,0.4-0.6c0,0.1,0,0.3,0.1,0.3c0.5,0.1,0-0.5-0.1-0.6c0.1,0,0.4,0.1,0.5,0.2c-0.1-0.3-0.3-0.5-0.4-0.7
        c-0.1,0-0.3,0-0.4,0c0,0,0-0.1,0-0.1c0.3,0,0.5-0.4,0.6-0.6c0.1-0.1,0.2-0.2,0.2-0.3c0.2,0.1,0.3,0.2,0.5,0.3l0-0.1
        c0-0.1-0.1-0.2-0.2-0.3c-0.2-0.1-0.3-0.2-0.5-0.3c0.1,0,0.2-0.1,0.3-0.1c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1,0.1-0.2,0.4-0.3,0.2l0-0.2
        c0.1-0.1,0.2-0.3,0.1-0.4c-0.2-0.3-0.3-0.6-0.7-0.6c0.1,0,0.2,0,0.3,0c-0.4-0.2-0.3-0.6-0.1-0.9c-0.2-0.1-0.4-0.1-0.6-0.2
        c0-0.1,0.1-0.2,0.2-0.3c0.1,0.1,0.2,0.3,0.2,0.4c0.1-0.1,0.2-0.3,0.2-0.3c0.1,0.4,0.4,0.7,0.5,1c-0.1-0.1-0.4-0.2-0.5-0.3
        c0,0.1,0,0.2,0.1,0.3c0.2-0.1,0.4,0.1,0.5,0.2c0-0.1-0.1-0.3-0.1-0.4l0.2,0.1c-0.3-0.2-0.6-0.7-0.1-0.9c0,0-0.1-0.1-0.1-0.2
        c-0.1,0-0.2,0.1-0.3,0.1c-0.1-0.2-0.1-0.5-0.4-0.5c0.1-0.1,0.2-0.1,0.4-0.2c0-0.3-0.1-0.5-0.4-0.4l0.2-0.3c0,0-0.1,0-0.2,0
        c-0.1,0.3-0.2,0.1-0.3-0.1c0.1,0,0.2,0,0.3,0l0-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c0.2-0.3,0.4,0,0.5,0.1c0-0.2-0.1-0.3-0.1-0.5
        c-0.2-0.1-0.2-0.3-0.2-0.5c-0.1,0-0.2-0.1-0.3-0.1c0-0.1,0-0.3,0-0.4c0-0.1,0.1-0.2,0.1-0.2c-0.5-0.2,0-0.5,0.2-0.7l-0.2,0
        c0-0.1,0-0.2-0.1-0.3c-0.3,0.1-0.2,0.4,0,0.5c-0.2,0.1-0.4,0.2-0.5,0.4c0.1,0.2,0.3,0.4,0.4,0.6c-0.1,0-0.3,0.1-0.3,0.1
        c0-0.1,0.1-0.2,0.1-0.3c-0.7-0.2,0.3-0.9-0.2-1.3c-0.3-0.3,0.4-0.6,0-0.9l0.3,0.2l0.1,0.3l0.2,0c-0.1-0.1-0.2-0.3-0.3-0.4
        c-0.2-0.2-0.6-0.3-0.4-0.6c-0.1,0.1-0.2,0.3-0.3,0.4c0.1,0.2,0.3,0.3,0.5,0.4c-0.1,0-0.2,0.1-0.2,0.1c0,0.1,0.1,0.2,0.2,0.3
        c-0.2,0.1-0.3,0.2-0.4,0.3c0.1-0.3,0.1-0.5,0.1-0.8c-0.2-0.1-0.4-0.1-0.4,0.2c0-0.1,0-0.3,0-0.3l0.2,0l-0.1-0.3c-0.1,0-0.2,0-0.2,0
        c0-0.1,0-0.3-0.1-0.4c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0,0.3-0.1,0.4-0.1l0-0.1c-0.3-0.1-0.6-0.3-1-0.2c0.2-0.2,0.4-0.4,0.4-0.6
        c0.1,0,0.2-0.1,0.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1-0.1-0.2-0.3-0.2-0.4c-0.4,0.3,0.1,0.5,0.2,0.8
        c-0.3,0-0.5-0.2-0.7-0.4c0.2-0.2,0.2-0.4,0.1-0.6l-0.2,0c0-0.1,0-0.3,0-0.3l-0.2,0c0,0,0-0.1,0-0.1c0.1,0,0.3,0,0.3,0
        c0-0.1,0-0.4-0.1-0.5c-0.1,0.1-0.2,0.2-0.3,0.2c0-0.2,0-0.4-0.1-0.6l-0.1,0.3l-0.1,0c0.2-0.1,0.2-0.3,0.1-0.5
        c0.2,0.2,0.5,0.4,0.8,0.6c0.1,0,0.3-0.1,0.5-0.2c0,0-0.1-0.1-0.1-0.1c-0.6,0.1-0.9-0.6-1.4-0.9c0.4,0,0.4-0.3,0-0.3
        c-0.2,0.3-0.1,0.6,0,0.9c-0.3,0.5,0.1,1,0,1.5c0,0.1-0.1,0.3-0.1,0.4c0.2,0.4,0.3,0.9,0.1,1.4c-0.1,0.3,0.1,0.6,0.2,0.9
        c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0.5,0.1,1,0,1.5c0,0,0,0.1,0,0.2c-0.1,0.2-0.1,0.4,0,0.6c-0.1,0.3,0,0.6,0,0.9c0,0.3-0.3,0.6-0.2,1
        c0.1,0,0.3,0,0.4,0c-0.3-0.1-1,0.7-0.2,0.4c-0.1,0-0.2,0.1-0.3,0.2c0.1,0.1,0.2,0.4,0.2,0.5l-0.2,0c-0.1,0.3-0.2,0.5-0.4,0.8
        c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0.3-0.2,0.6-0.3,0.8c0.1,0.2,0,0.4-0.1,0.7c0.3,0.2-0.3,0.5-0.2,0.7c0.1,0,0.3,0,0.4,0
        c0,0,0,0.1,0,0.1c-0.1,0-0.2,0-0.2,0c0,0.4-0.4,1,0.1,1.3c-0.1,0-0.3-0.1-0.4-0.2c0,0.2,0.1,0.4,0.2,0.6c-0.1-0.1-0.2-0.2-0.4-0.3
        c-0.1,0.3-0.2,0.5-0.4,0.8l0.3,0c0,0.2,0.2,0.4,0,0.5c0-0.1,0-0.3,0-0.4l-0.2-0.1c0,0.3,0.1,0.7,0.2,1c-0.1,0-0.2-0.1-0.3-0.1
        c-0.2,0.5-0.3,1-0.5,1.4c0,0.3-0.2,0.7,0.1,0.9c-0.1,0.1-0.3,0.2-0.4,0.2c0.4,0.1,0.1,0.3,0,0.5c-0.2,0.3-0.3,0.6-0.4,0.9
        c0.1,0,0.4,0,0.5,0c-0.3,0.1-0.6,0.2-0.9,0.3c0.2,0.2,0.3,0.4,0.4,0.6c0,0.1-0.1,0.1-0.1,0.2c-0.1-0.3-0.3-0.4-0.5-0.6
        c-0.2,0.4,0,1-0.3,1.4c0,0.1,0,0.3,0,0.4c0.1,0.7,0.7,1.4,0.3,2.1c0.2,0.2,0.3,0.4,0.5,0.6c-0.1,0-0.3,0-0.4-0.1
        c-0.1,0.2-0.1,0.4-0.2,0.6l0.3-0.1c-0.1,0.1-0.2,0.3-0.2,0.4c-0.2-0.1-0.2-0.3-0.2-0.5c-0.1,0-0.3,0-0.5-0.1
        c-0.2,0.3,0.1,0.5,0.2,0.7c0.2,0.1,0,0.3-0.1,0c-0.1-0.1-0.2-0.3-0.4-0.4c-0.1,0.1-0.2,0.2-0.3,0.2c0.1,0.2,0.1,0.4,0.2,0.6
        c0,0.1-0.1,0.2-0.2,0.3c0.1,0,0.2,0.1,0.3,0.1c-0.1,0.1-0.3,0.3-0.3,0.5c0-0.1,0-0.4,0-0.5c-0.2,0.1-0.2,0.4-0.3,0.6
        c-0.1-0.1-0.2-0.3-0.2-0.4c0,0.1-0.1,0.3-0.1,0.4c-0.1-0.1-0.2-0.2-0.3-0.3c0,0.1,0.1,0.2,0.2,0.3c-0.3,0.2-0.6,0.2-0.9,0.3
        c-0.1,0-0.3-0.1-0.4-0.2c0,0.1,0.1,0.3,0.1,0.4c0,0-0.1,0-0.2,0c0,0.1-0.1,0.3-0.2,0.4c-0.2,0-0.5-0.1-0.5,0.2
        c-0.3,0.1-0.6,0.4-0.9,0.6c0,0.2,0,0.3,0,0.5c-0.1-0.1-0.2-0.2-0.2-0.3c-0.2,0.2-0.3,0.6-0.6,0.7c-0.2,0.2-0.3,0.4-0.5,0.4
        c0,0.3,0.2,0.5,0.4,0.7c-0.1,0-0.2-0.1-0.2-0.1c-0.1,0.2-0.2,0.4-0.3,0.6c-0.2,0-0.4,0.1-0.5,0.1c0.2-0.1,0.5-0.2,0.6-0.5
        c-0.1,0-0.3-0.1-0.3-0.1c0.1,0,0.3,0,0.5,0c0-0.2,0-0.5-0.1-0.7c-0.3,0.1-0.4,0.4-0.6,0.5c-0.2,0.2-0.3,0.4-0.3,0.7
        c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0,0.4,0.1,0.5,0.2c-0.1,0-0.3-0.1-0.4-0.1c0.2,0.2,0,0.7-0.3,0.5
        c0.1-0.2,0.1-0.4,0-0.6c-0.1,0.1-0.2,0.4-0.3,0.5c-0.1,0-0.3,0-0.4,0c0,0.1-0.1,0.2-0.1,0.3c0.1,0.1,0.2,0.2,0.3,0.3l0.1-0.2
        c0.1,0,0.2,0.1,0.3,0.1c-0.2,0.1-0.4,0.1-0.6,0.2c0-0.1-0.1-0.2-0.2-0.2c-0.5,0.2-0.8,0.5-1.2,0.9c-0.1,0-0.2,0-0.3,0
        c0.1,0.1,0.2,0.2,0.2,0.3c-0.1,0-0.3-0.1-0.4-0.1c-0.2,0.2-0.7,0.4-0.5,0.8c-0.1-0.1-0.2-0.2-0.2-0.2c-0.3,0.2-0.7,0.4-1.1,0.5
        c-0.1,0.1-0.2,0.3-0.3,0.4c0.1,0,0.3,0,0.4,0c-0.4,0.3-0.9-0.2-1.2,0.3c0.3,0.2,0.6,0.2,0.9,0.1c0,0,0,0.1,0,0.2
        c-0.3-0.1-0.5,0-0.8,0c-0.3,0-0.8,0.1-0.5,0.5l-0.1,0c0-0.1,0-0.3,0-0.4c-0.2,0.1-0.4,0.3-0.7,0.5c-0.3,0.1-0.7-0.1-0.8,0.2
        c-0.2,0.2-1.1,0.5-0.6,0.9c0.2-0.1,0.3-0.3,0.4-0.6l0,0.2c0.2,0,0.4,0,0.4,0.3c-0.2-0.1-0.3-0.1-0.5-0.2c-0.2,0.3-0.5,0.6-0.8,0.8
        l0.2,0.1c-0.5,0.1-0.1-0.4-0.3-0.6c-0.1,0.3-0.4,0.3-0.6,0.4c-0.2,0.2-0.3,0.4-0.4,0.5c-0.1,0-0.3-0.2-0.4,0l-0.1-0.1
        c0.1-0.1,0.3-0.1,0.4-0.1c0-0.2,0.2-0.4,0-0.5c-0.1,0.1-0.2,0.1-0.3,0.2l-0.1-0.2c-0.2,0.1-0.3,0.2-0.5,0.4
        c-0.3-0.1-0.5,0.1-0.8,0.1l0.1,0.2c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0-0.2-0.1-0.3-0.1c0.2-0.2,0.4-0.3,0.5-0.6
        c-0.2,0-0.3-0.1-0.5-0.1c0,0.1,0,0.2,0,0.3c-0.3,0.1-0.5,0.1-0.7,0c0,0.1,0,0.2,0,0.2c0,0.3-0.2-0.1-0.2-0.2l-0.1,0l0,0.3
        c-0.1,0.1-0.3,0.2-0.5,0.2l0-0.3c-0.2,0-0.3,0.1-0.5,0.1c-0.6,0.2,0.4,0.5-0.1,0.6c-0.1-0.3-0.4-0.4-0.7-0.4c-0.3,0-0.3,0.3-0.4,0.5
        c-0.2,0-0.3,0-0.5,0c0.1-0.1,0.2-0.2,0.4-0.2l0-0.2c-0.4-0.1-0.7,0.3-0.8,0.6c-0.3-0.2-0.6-0.4-0.9-0.3c0.1,0.2,0.2,0.3,0.3,0.5
        c-0.4-0.1-0.7-0.3-1-0.3c0.1,0.2,0.2,0.3,0.3,0.4c0,0,0,0.1,0,0.2c-0.2-0.1-0.5-0.5-0.7-0.2c-0.1-0.1-0.2-0.4-0.4-0.3
        c-0.2,0.3-0.5,0.3-0.8,0.5c0-0.1,0-0.3,0-0.4c-0.2,0.1-0.6,0-0.7,0.4c0.1,0,0.3,0,0.5,0c-0.1,0.2-0.3,0.2-0.4,0.1
        c-0.2-0.1-0.6-0.2-0.5-0.5c-0.2,0.1-0.4,0.3-0.6,0.5c-0.1-0.2-0.3-0.2-0.4,0c-0.1-0.1-0.2-0.2-0.3-0.3c0,0.1-0.1,0.2-0.1,0.2
        l-0.2,0.1c0.2-0.3,0.1-0.4-0.1-0.5c0,0.2-0.1,0.4-0.1,0.6c-0.2,0-0.3,0-0.4,0c0-0.1,0.1-0.2,0.1-0.3c-0.2,0.1-0.5,0.1-0.6,0.3
        c0-0.1,0-0.3,0.1-0.4c-0.4,0-0.7,0.2-1.1,0c-0.3-0.1-0.6-0.2-0.8-0.2c-0.3,0.2-0.7,0-1.1-0.1c-0.3,0-0.7-0.4-1-0.1
        c-0.1,0.1-0.3,0.2-0.3,0.2c0.1-0.2,0.4-0.3,0.4-0.5c-0.2,0-0.4,0-0.6,0c0,0-0.1,0.1-0.1,0.2c-0.6-0.2-1.1-0.8-1.7-0.4
        c-0.1,0-0.2-0.1-0.3-0.2l0-0.2c-0.1,0-0.3,0.1-0.4,0.2c0-0.1,0-0.2,0-0.3c-0.1,0-0.2,0.1-0.3,0.2c-0.1-0.1-0.3-0.3-0.4-0.4
        c-0.2,0-0.3,0-0.5,0c-0.1-0.4-0.6-0.3-0.9-0.3c0.2,0.3,0.6,0.1,0.8,0.4c-0.3,0-0.6,0-0.8-0.2c0-0.1,0-0.4,0-0.5
        c-0.2,0.1-0.4,0.2-0.6,0.4c0,0,0-0.1,0.1-0.2l0.2-0.1c-0.4-0.2-0.8-0.4-1.2-0.6c-0.2,0.2-0.5,0.3-0.7,0.1c0-0.1,0.1-0.2,0.1-0.2
        c0.1,0,0.2,0,0.3,0.1c0,0,0.1-0.1,0.2-0.1l0.1-0.1c0-0.1,0-0.2,0-0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0-0.5,0.1-0.7,0.3
        c0.1-0.2,0.2-0.3,0.3-0.4c-0.2-0.1-0.4-0.2-0.5-0.3c-0.1,0.2-0.1,0.4,0.1,0.5c0,0.2,0,0.3,0,0.5c-0.2-0.3-0.3-0.7-0.4-1.1
        c-0.4,0.1-0.9-0.2-1.3-0.3c0-0.1,0.1-0.3,0.1-0.4l0,0c0,0-0.1,0.1-0.2,0.2c-0.3-0.2-0.5-0.4-0.8-0.5c-0.1,0.3,0.2,0.4,0.4,0.5
        l-0.1,0.1l-0.1,0.1c-0.2,0-0.4-0.1-0.6-0.2l0.1-0.2l-0.3,0.1c0.1-0.4,0.6-0.2,0.7-0.5c-0.1,0-0.5,0-0.6-0.1c0,0.2,0,0.3-0.2,0.4
        c0-0.1,0.1-0.4,0.1-0.5c-0.2,0.2-0.4,0.3-0.7,0.2c-0.2-0.1-0.3-0.1-0.5-0.2c0,0.1-0.1,0.2-0.2,0.2c0-0.2,0.2-0.3,0.3-0.3
        c0.3,0.1,0.5,0.4,0.7,0c-0.3-0.1-0.6-0.2-0.8-0.4c0-0.3-0.2-0.4-0.5-0.3c0-0.3-0.2-0.3-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.4
        c-0.2,0-0.4-0.1-0.5-0.2c0,0.1,0.1,0.3,0.1,0.5l-0.3-0.2c0-0.1,0-0.2,0-0.3c-0.2,0.1-0.5,0.2-0.7,0.3c0.1-0.3,0.5-0.2,0.6-0.5
        c-0.3,0-0.5-0.1-0.7-0.2c-0.1,0.2-0.1,0.3-0.2,0.5c-0.2-0.1-0.3-0.2-0.5-0.3c0.1,0,0.3,0,0.4,0c0.4-0.2-0.4-0.4-0.4-0.7
        c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2-0.1-0.3-0.2-0.5-0.3c0.3-0.1,0.6-0.2,0.8-0.4c0,0,0,0,0,0c-0.3,0-0.8,0.1-0.9-0.4
        c-0.1,0-0.3,0.1-0.4,0.1c0,0-0.1,0.1-0.2,0.1c-0.1-0.1-0.3-0.2-0.4-0.3c0.2-0.1,0.4-0.1,0.6-0.1c-0.2-0.1-0.3-0.2-0.5-0.3
        c-0.1-0.2-0.1-0.4-0.4-0.5c0.1-0.1,0.2-0.2,0.2-0.2c-0.2,0-0.4,0-0.5-0.2c-0.1,0.2-0.2,0.4-0.3,0.5c0-0.1,0.1-0.4,0.1-0.5
        c-0.2-0.4-0.6-0.5-0.8-0.8c0,0.2,0,0.4,0.1,0.6c-0.1,0-0.2,0-0.2,0c0-0.1,0-0.2,0-0.3c-0.1,0-0.4,0-0.5,0l0.1,0.2
        c-0.1,0-0.2-0.1-0.3-0.2c0.3-0.2,0.6-0.3,0.9-0.4c-0.2-0.1-0.4-0.1-0.6-0.2c0-0.1,0.1-0.2,0.1-0.3c-0.1,0-0.3,0-0.4,0
        c0-0.5-0.6-0.6-0.9-0.7c0-0.1,0.1-0.2,0.1-0.2c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2-0.3-0.3-0.6-0.6-0.6c0-0.1,0-0.2,0-0.2
        c-0.4-0.2-0.7,0.3-1.1,0c0.3-0.1,0.5-0.2,0.8-0.2c0.2-0.5-0.3-0.3-0.5-0.3c0-0.4-0.4-0.7-0.6-1.1c-0.1,0.1-0.2,0.2-0.3,0.3
        c0-0.1-0.1-0.4-0.1-0.5c0-0.1,0.1-0.2,0.1-0.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1-0.1-0.1-0.2-0.2-0.3l0.3-0.1c-0.2-0.2-0.6-0.4-0.9-0.3
        c0,0.1,0,0.3,0,0.5c0,0.1,0.1,0.2,0.3,0.2c0-0.2-0.1-0.3-0.1-0.4c0.1,0,0.4-0.1,0.5-0.1l0,0.2l-0.3,0c0.3,0.2,0.6,0.5,0.7,0.8
        c0.2,0,0.4,0,0.6,0.1c0,0.1-0.1,0.2-0.1,0.3c-0.2-0.1-0.4-0.1-0.6,0c-0.1-0.3-0.2-0.5-0.3-0.7c-0.4,0-0.6-0.2-0.9-0.4
        c0.2-0.1,0.3-0.4,0.3-0.6c0.1-0.1,0.2-0.3,0.2-0.4c-0.3-0.2-0.5-0.4-0.6-0.7c0,0.2,0,0.4,0,0.6c-0.1,0-0.3,0-0.4,0
        c0-0.2,0.1-0.3,0.2-0.5c-0.2,0.1-0.4,0.2-0.6,0.3c0-0.2,0.1-0.5,0.1-0.7c0.1,0,0.2,0,0.2,0l0.1,0.2c0.1-0.3-0.3-0.4-0.4-0.6
        c-0.4-0.3-0.3-0.8-0.7-1c0.1,0.3,0.4,1-0.2,0.9c0-0.1,0.1-0.3,0.1-0.4c0,0-0.1-0.1-0.2-0.2c-0.1-0.3-0.1-0.6-0.2-0.9
        c-0.1,0-0.3,0-0.4,0l0,0.1c0.5-0.1,0.2,0.6,0.3,0.8c-0.1-0.2-0.2-0.4-0.3-0.6c0,0.1,0,0.4,0,0.5c0.3,0.1,0.6,0.3,0.6,0.7
        c0.2,0.1,0.5,0,0.7,0c0.2,0.1,0.3,0.4,0.2,0.6c-0.3-0.2-0.6-0.4-1-0.3c0.1-0.4-0.3-0.6-0.6-0.7c0-0.3-0.1-0.4-0.3-0.6
        c0.1,0,0.2,0,0.3-0.1c-0.1-0.1-0.2-0.3-0.2-0.4l0.3-0.1c-0.1,0-0.3-0.1-0.4-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0.2-0.7,0-0.5-0.2
        c0.1,0,0.3,0,0.4,0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.4-0.3,0.3-0.1,0.3-0.4c-0.1,0-0.4,0.1-0.5,0.1c0.1-0.2,0.2-0.4,0.3-0.6l-0.2,0
        c0-0.1,0.1-0.3,0.1-0.5c-0.4,0.2-0.3,0.6-0.1,0.9c-0.1,0-0.2-0.1-0.3-0.1c-0.3-0.4,0-0.7,0.2-1.1l-0.1,0c-0.1,0.1-0.3,0.2-0.4,0.3
        l0-0.3c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1,0.1-0.2,0.2-0.3,0.4c-0.2-0.1-0.3-0.3-0.5-0.4c0.1,0,0.3,0.1,0.4,0.1c0-0.1,0-0.3,0.1-0.4
        c-0.1,0.1-0.2,0.2-0.3,0.2l0.1-0.3c-0.1,0-0.3,0-0.5,0.1c0-0.3-0.1-0.6-0.4-0.4c0-0.1,0.1-0.2,0.1-0.4c0.3,0.2,0.5,0.4,0.3,0.8
        c0.2-0.1,0.3-0.3,0.4-0.5c0.1,0,0.2,0,0.2,0l0-0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.2-0.1-0.3-0.3-0.3-0.5L416,211
        c-0.1-0.1-0.2-0.3-0.3-0.4c0-0.1,0-0.2,0-0.3c-0.1,0-0.2-0.1-0.3-0.2c0.1,0,0.5,0.1,0.3-0.2c0-0.1-0.1-0.2-0.1-0.3
        c-0.1,0-0.2,0-0.2,0c0-0.1,0-0.2,0.1-0.3c-0.3-0.1-0.3-0.2,0-0.2c0-0.3-0.2-0.4-0.5-0.3c0,0,0-0.1,0-0.1c0.1,0,0.3,0,0.4,0
        c-0.2-0.2-0.4-0.4-0.4-0.7c-0.2-0.1-0.4-0.2-0.3-0.5c-0.1,0-0.2-0.1-0.3-0.1l0.4,0.1c0-0.3-0.2-0.5-0.5-0.5c0-0.1,0-0.2,0-0.3
        c-0.4,0.1-0.7-0.2-1.1-0.2c-0.3-0.3-0.4-0.9-1-0.9c-0.1-0.4-0.5-0.8-0.3-1.2c0.1-0.3-0.2-0.5-0.3-0.7c0.1,0,0.4,0.1,0.5,0.1l0-0.4
        c-0.1,0-0.3,0.1-0.5,0.1c0-0.1-0.1-0.2-0.1-0.2c0.1,0,0.3,0.1,0.5,0.1c-0.1-0.1-0.2-0.3-0.3-0.4c0.1,0,0.3,0,0.4,0
        c-0.1-0.1-0.3-0.3-0.3-0.3c0.1-0.1,0.1-0.2,0.2-0.3c-0.2-0.2-0.5-0.4-0.6-0.7c-0.1-0.1-0.2-0.2-0.2-0.3c0.1,0,0.3,0,0.4,0
        c-0.1-0.1-0.5-0.2-0.6,0.1c0,0-0.1-0.1-0.2-0.1c0.1-0.1,0.2-0.2,0.2-0.3c-0.2,0-0.3-0.1-0.3-0.3c0.1,0.1,0.3,0.2,0.4,0.2
        c0-0.2-0.1-0.3-0.3-0.3c0-0.2,0.1-0.4,0.3-0.5c0-0.1-0.1-0.2-0.1-0.3c0.5,0.2,0,0,0-0.2c0-0.3,0-0.8-0.4-0.7l0.1-0.2
        c0.1,0,0.4,0.1,0.5,0.1c-0.4-0.4-0.4-1-0.9-1.3c0.3-0.2-0.2-0.4-0.2-0.7c-0.2,0-0.4,0-0.6-0.1c0,0.2-0.1,0.3-0.3,0.3
        c0-0.1,0.1-0.3,0.1-0.3c-0.3-0.1-0.2-0.4-0.2-0.7c-0.1,0-0.5,0.2-0.4-0.1c0.2-0.3-0.4-0.9,0.3-0.8c-0.1,0-0.3,0-0.3,0
        c-0.1-0.3-0.2-0.5-0.3-0.8c-0.1,0-0.2,0.1-0.3,0.1c-0.2-0.3-0.3-0.7-0.5-1c0.1,0,0.2-0.1,0.2-0.2c0-0.2-0.1-0.4-0.1-0.6
        c0.1-0.2,0.2-0.4,0.3-0.6c-0.2,0-0.4-0.2-0.5-0.3c0.1,0,0.2,0.1,0.3,0.2c0-0.3,0-0.5,0.1-0.8c-0.1-0.1-0.3-0.3-0.4-0.4
        c0.2-0.1,0.4-0.3,0.3-0.5c-0.2,0.1-0.3,0.4-0.4,0.1c0.1-0.1,0.2-0.2,0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c0-0.2,0.3-0.2,0.4-0.4
        c-0.1,0-0.3,0-0.4,0c0.1-0.3-0.5-0.6,0-0.8c-0.1-0.2-0.2-0.4-0.4-0.6c0.1-0.2,0.2-0.4,0.2-0.6c-0.1,0.2-0.7,0.4-0.5,0
        c0.1-0.1,0.2-0.4,0.3-0.5c-0.3,0.1-0.4-0.1-0.5-0.3c0.2-0.3,0-0.6-0.1-0.9c0-0.1,0.1-0.2,0.2-0.3c0,0-0.1-0.1-0.2-0.1
        c0-0.3-0.1-0.5,0.1-0.8c0.2-0.2-0.1-0.3-0.2-0.4c0-0.3,0.3-0.7,0-0.9c0-0.2,0.1-0.5,0.1-0.7c0-0.1-0.1-0.4-0.1-0.5l0.3,0.1
        c0,0,0-0.1,0-0.1c-0.1,0-0.3,0-0.3,0c0.1-0.1,0.2-0.2,0.3-0.4c-0.1,0-0.2,0-0.3,0c0-0.2,0.1-0.5,0.2-0.7c-0.1,0-0.2-0.1-0.2-0.1
        c0-0.3,0.1-0.6,0-1c0.1,0,0.3,0,0.4,0c-0.1,0-0.2-0.1-0.3-0.1l-0.1-0.4c0.1,0,0.3,0,0.4,0.1c-0.1-0.1-0.2-0.2-0.2-0.2
        c0-0.1,0-0.4,0-0.6l0.2,0c0-0.2-0.3-0.5,0-0.6c0-0.2,0-0.5,0-0.7l0.4,0c0,0.1,0,0.4-0.1,0.5c0.1,0,0.2,0,0.2,0l0,0.3
        c-0.1,0-0.2,0-0.2,0c0,0.1,0.1,0.2,0.1,0.2c0.1,0,0.2-0.1,0.3-0.1c-0.1,0.2-0.2,0.3-0.2,0.5c0.3-0.1,0.4,0.2,0.5,0.4
        c-0.1,0-0.2,0-0.3,0c0.1,0.1,0.2,0.2,0.3,0.3c0,0.1,0,0.3,0,0.4l0.2,0c0,0.1,0,0.3,0,0.4l0.1,0c-0.1,0.2-0.2,0.5-0.2,0.7
        c0.1,0,0.3-0.1,0.4-0.2c-0.1,0.1-0.2,0.3-0.3,0.4c0.1,0,0.2,0.1,0.2,0.1c-0.2,0.5,0.3,0.9,0,1.4c0,0.2,0,0.3,0,0.5l0.2-0.1
        c0,0.1,0.1,0.3,0.1,0.4c0,0.1-0.1,0.3-0.1,0.4c0,0-0.1,0-0.2,0c0.2,0.2,0.4,0.4,0.5,0.6c-0.1,0.1-0.2,0.2-0.3,0.3
        c0.1,0.2,0.3,0.4,0.3,0.6c-0.1,0-0.3,0-0.4,0c0.1,0.1,0.3,0.1,0.5,0c-0.2,0.2-0.2,0.4-0.1,0.7c0-0.1,0-0.2,0-0.3
        c0.3,0.2,0.4,0.3,0.1,0.5c0.1,0.2,0.1,0.4,0.4,0.3c-0.2,0.2-0.3,0.4-0.5,0.6c0.1-0.1,0.3-0.2,0.4-0.2c0.3,0.3,0.2,0.6,0.1,1
        c0.2,0.1,0.4,0.3,0.3,0.5l0.2-0.1c-0.1,0.4,0.1,0.7,0.1,1c-0.1,0-0.2,0-0.2,0c0.1,0.2,0.1,0.4,0.3,0.6c-0.1,0.1-0.2,0.2-0.2,0.3
        c0.1,0,0.2-0.1,0.3-0.1c0,0.2,0,0.4,0,0.5c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0-0.4,0-0.5,0c0.1,0.2,0.3,0.2,0.5,0.3
        c0.2,0.2,0.3,0.5,0.6,0.5c-0.2,0.3,0,0.7-0.1,1.1c0.1,0,0.2-0.1,0.3-0.1c0-0.1,0-0.3-0.1-0.4c0.2,0.2,0.1,0.4,0.1,0.6
        c0.1-0.1,0.3-0.2,0.4-0.3c0,0,0.1,0.1,0.1,0.1c-0.2,0.2-0.7,0.3-0.4,0.6c0.1-0.1,0.2-0.3,0.3-0.4c0.1,0.1,0.2,0.3,0.2,0.4
        c0.2-0.1,0.4,0,0.7,0.1c-0.2,0-0.4,0.1-0.5,0.1c-0.1,0.1-0.5,0.5-0.1,0.6c0-0.3,0.2-0.4,0.4-0.3c0,0.3,0.1,0.6-0.2,0.7
        c0,0.1,0.1,0.2,0.1,0.3c-0.3,0.1-0.2,0.4-0.1,0.6c-0.1,0-0.3,0-0.3-0.1c0,0.1,0.1,0.2,0.2,0.2c0.1,0.8,0.8,1.3,1.1,2.1
        c0.2,0.7,0.6,1.3,0.8,2l-0.2,0c0,0.2,0,0.4,0,0.6l-0.2-0.1c0,0,0,0.1,0,0.1c0.1,0,0.3,0,0.4,0c-0.1,0.2-0.2,0.3-0.3,0.5
        c0.1,0,0.4-0.1,0.5-0.1c0.2,0.2,0.2,0.4,0,0.6c0.1,0,0.3,0,0.4,0c-0.1,0.1-0.2,0.2-0.3,0.3l0,0c0.1,0,0.3-0.1,0.4-0.2l0,0.2
        c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.3-0.1,0.4-0.2c0,0.1,0,0.4,0,0.5c0.1,0,0.2,0,0.2,0c-0.1-0.1-0.1-0.3-0.2-0.4
        c0.1,0.1,0.3,0.2,0.4,0.3c-0.1,0.1-0.2,0.3-0.3,0.3c0.1,0,0.3,0,0.4-0.1c-0.1,0.1-0.2,0.3-0.4,0.4c0.1,0.1,0.3,0.2,0.4,0.2
        c-0.3,0-0.5,0.1-0.6,0.4c0.2-0.1,0.4-0.1,0.6-0.2c-0.1,0.1-0.2,0.2-0.4,0.2l0.1,0.2c0.4,0,0.8-0.2,1.2-0.1c-0.1,0-0.3,0.1-0.3,0.2
        c0,0.2,0.1,0.5,0.2,0.7c-0.1,0-0.2,0.1-0.3,0.1c0,0.1,0.1,0.2,0.1,0.3c-0.2,0.3-0.4,0.6-0.5,1c0.3,0.3,0.4,0.6,0.5,1
        c0.2,0.1,0.3,0.3,0.4,0.6c0.5,0.7,0.9,1.4,1.5,1.9c0.1,0.2,0.1,0.3,0.3,0.4c0.6,0.5,1.1,1.1,1.6,1.7c0.2,0.1,0.1,0.3,0.2,0.5
        c0.5,0.2,0.5,0.7,0.9,1c0.4,0.6,1,1,1.2,1.7c0.4,0.1,0.6,0.5,0.7,0.9c0.3,0.1,0.4,0.3,0.5,0.6c0.3,0.2,0.7,0.3,0.7,0.7
        c0.4,0.3,0.8,0.7,1.1,1c0.6,0.1,0.8,0.7,1.2,1c0.8,0.8,1.7,1.6,2.5,2.5c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.3,0.3,0.4
        c0.2,0,0.4,0,0.5,0.3c0.1,0.1,0.3,0.2,0.4,0.3c0.4,0.3,0.7,0.6,1.2,0.8c0.1,0.3,0.3,0.4,0.6,0.4c-0.1,0.4,0.2,0.3,0.5,0.3
        c0,0.4,0.4,0.5,0.7,0.7c0,0.1,0.1,0.2,0.1,0.2c0.3-0.1,0.5,0.1,0.7,0.2c0.3,0.2,0.6,0.3,0.9,0.5c0,0.1,0.1,0.2,0.2,0.3
        c0.3-0.2,0.5,0.1,0.7,0.2c0.2,0.2,0.7,0.2,0.6,0.5c0.3,0,0.7,0.1,1,0.1c0,0.1-0.1,0.3-0.1,0.4c0.1,0.1,0.3,0.1,0.4-0.1
        c0.5,0,0.9,0.2,1.2,0.6c0.6-0.1,0.9,0.6,1.5,0.6c0.7,0.2,1.2,0.7,1.8,0.9c0.3-0.2,0.5,0,0.6,0.2c0.1,0,0.2-0.1,0.3-0.2
        c-0.1,0.4,0.4,0.4,0.6,0.6c0.1-0.2,0.2-0.3,0.4-0.3c0,0.1-0.1,0.3-0.1,0.4c0.1-0.1,0.3-0.2,0.4-0.2c0,0.1,0,0.3,0,0.3
        c0.3-0.1,0.7-0.2,1-0.2c-0.2,0.2-0.3,0.4-0.5,0.6c0.3,0,0.3-0.7,0.6-0.2c0,0.1,0.1,0.2,0.1,0.3c0,0,0.1-0.1,0.1-0.2
        c0.4,0.1,0.8,0,1.2-0.2c-0.1,0.2-0.3,0.3-0.4,0.4c0.1,0.2,0.3,0.4,0.5,0.5c-0.1-0.2-0.2-0.3-0.2-0.5c0.1,0,0.4,0,0.5,0
        c0-0.1-0.1-0.2-0.1-0.3c0,0,0.1,0.1,0.1,0.2c0.1,0,0.2,0,0.3,0c0.1,0.2,0.2,0.3,0.3,0.5c0.3-0.2,0.6,0,0.9,0
        c-0.1-0.2-0.1-0.4-0.2-0.5c0.2,0.1,0.2,0.3,0.2,0.5c0.2-0.1,0.5-0.1,0.7,0c0-0.1-0.1-0.3-0.1-0.4c0.3,0.1,0.3,0.3,0.3,0.6
        c0.2-0.1,0.4-0.2,0.7-0.3c0.1,0.5,0.8,0.3,0.6-0.2c0.1,0.2,0.2,0.5,0.5,0.3c0,0.1-0.1,0.3-0.1,0.4c0.3-0.1,0.4-0.4,0.4-0.7l0.2,0
        c-0.1,0.1-0.2,0.3-0.2,0.4c0.2,0.1,0.4,0.3,0.6,0.1c0.2-0.2,0.5-0.2,0.7,0c0.2-0.1,0.4-0.2,0.5-0.3c0.1,0.3,0.7,0.5,0.6,0
        c0.3-0.2,0.6-0.3,0.9-0.5c0,0,0,0.1,0,0.2c-0.3,0-0.5,0.2-0.7,0.4c0.2,0,0.7-0.1,0.9-0.2c0,0.1,0,0.2,0,0.2c0,0,0.1-0.1,0.1-0.1
        c0.2,0,0.5-0.1,0.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0-0.1,0-0.3,0-0.3c0.1,0,0.3,0.1,0.3,0.1c0,0,0-0.1,0.1-0.2c0.2,0,0.4-0.1,0.5-0.3
        c-0.1-0.1-0.2-0.2-0.3-0.3c0.3,0.1,0.6,0.3,0.9,0.2c0.2-0.1,0.4-0.3,0.4-0.5c0.1,0.1,0.2,0.2,0.3,0.2c-0.1,0-0.3,0-0.4,0
        c0,0.1,0,0.4,0,0.5c0.2,0.1,0.3,0.1,0.5,0.2c-0.1-0.1-0.2-0.2-0.2-0.2c0.3-0.4,0.8-0.6,1.1-1c-0.1,0-0.3,0-0.4,0
        c0.1-0.2,0.3-0.4,0.6-0.6c-0.2,0-0.3,0.1-0.5,0.2c0-0.2,0-0.3-0.1-0.5c0.2-0.1,0.4-0.1,0.5,0.1c0-0.1,0-0.3,0.1-0.4
        c0.2-0.1,0.4-0.1,0.6,0.1c-0.1-0.2-0.3-0.4-0.4-0.5c0.2-0.1,0.6-0.3,0.5,0.1c0.2-0.1,0.4-0.2,0.3-0.5c0.3,0.1,0.3,0.2,0,0.5
        c0.4-0.1,0.9,0,1.1,0.4c0.1,0.3,0.5,0.3,0.7,0.4c0.1-0.1,0.2-0.3,0.2-0.3c0.1,0,0.2,0.1,0.3,0.1c-0.1-0.1-0.1-0.3-0.2-0.4
        c0.1,0,0.3,0,0.4,0.1l0-0.2c0,0.1,0.1,0.2,0.1,0.3c0.2-0.2,0.4-0.2,0.6-0.2c-0.3,0-0.4-0.2-0.4-0.4c0.1,0,0.3,0.1,0.3,0.1
        c0.1-0.2,0.2-0.4,0.2-0.6c0.2-0.1,0.4-0.3,0.6-0.2c0-0.1,0-0.2,0-0.2c0.1,0,0.4,0.2,0.4,0c0.2-0.1,0.2-0.4,0.3-0.6
        c-0.2,0-0.4,0-0.4-0.2c-0.1,0-0.3,0-0.4,0c0-0.1,0.1-0.2,0.1-0.3c0.1,0.1,0.2,0.2,0.3,0.3l0-0.3c0.1,0,0.2,0,0.3,0
        c-0.1-0.3-0.5-0.3-0.6-0.7c0.2-0.1,0.5-0.3,0.1-0.5c0.2-0.1,0.4,0,0.5,0.1c-0.2,0.2-0.7,0.2-0.5,0.6c0.2-0.1,0.4-0.3,0.6-0.5
        c-0.1-0.3-0.2-0.5-0.3-0.8c0.4,0,0.8,0,1.1,0.3c0.1-0.5-0.7-0.4-0.7-0.9c0.3,0.1,0.6,0.1,0.9,0.2c-0.1-0.2-0.1-0.4-0.2-0.5l0.3,0.1
        c0,0.3,0.3,0.6,0.6,0.7c0-0.1,0.1-0.2,0.1-0.3c-0.2,0-0.4-0.1-0.5-0.2c0.1-0.1,0.4-0.2,0.5-0.2c0.2,0.2,0.4,0.6,0.7,0.5
        c0.4,0,0.4,0.6,0.8,0.5c-0.1-0.1-0.3-0.4-0.4-0.5c0.4,0.2,0.9,0.4,1.4,0.4c-0.1-0.3-0.3-0.6-0.1-0.8c0,0.1,0.1,0.3,0.2,0.4
        c0.1-0.1,0.2-0.3,0.3-0.4c0,0.1,0,0.3,0.1,0.4c0.1-0.1,0.2-0.2,0.3-0.3l0.1,0c0-0.2,0.1-0.5,0.2-0.7c0.1,0,0.2,0.1,0.2,0.1
        c0.1-0.2,0.2-0.3,0.4-0.1c0-0.1-0.1-0.3-0.1-0.4c0.1,0,0.3,0.1,0.4,0.1c0.2-0.4,0.5-0.8,0.6-1.3c0.1-0.1,0.3-0.2,0.5,0
        c0.1-0.3,0-0.7,0.4-0.7c-0.2-0.1-0.6-0.1-0.7-0.3c-0.1-0.4,0.4-0.7,0.2-1.1c-0.3,0-0.4,0.3-0.6,0.4c0.1-0.3,0.2-0.5,0.4-0.8
        c0.1,0.1,0.2,0.2,0.3,0.3c-0.1-0.3,0-0.5,0.1-0.8c-0.3,0-0.2-0.3-0.4-0.4c-0.2-0.1-0.3,0.1-0.5,0.2l0,0c0.1-0.1,0.1-0.3,0.2-0.4
        c0.1,0,0.3-0.1,0.5-0.1c0,0.1,0,0.2-0.1,0.4c0.1-0.1,0.4-0.2,0.5-0.3c0.1,0.1,0.2,0.2,0.3,0.2c-0.2-0.2-0.3-0.5-0.6-0.5
        c0,0,0-0.1,0-0.2c0.1,0,0.2,0.1,0.3,0.2c0-0.1,0-0.3,0-0.3c0,0.1,0,0.3,0.1,0.4c0.2,0,0.4,0,0.6,0c-0.1-0.1-0.1-0.3-0.2-0.4
        c-0.1,0-0.2,0.1-0.3,0.1c0-0.1,0-0.2,0-0.3c0.3,0.1,0.8,0.3,0.9-0.2c-0.3,0.1-0.5,0-0.6-0.3c0.2,0.2,0.6,0.1,0.8,0.3
        c0.2,0,0.3,0,0.5,0c0-0.1-0.1-0.2-0.2-0.3c0.2,0,0.3,0,0.5,0l0-0.2c-0.1,0-0.3,0.1-0.4,0.2c0-0.3,0.4-0.4,0.7-0.4
        c0.1-0.2,0.3-0.3,0.4-0.4l0-0.1c0,0,0.1,0.1,0.1,0.1c0.1,0,0.3,0.1,0.4,0.1l0.1-0.2c-0.1,0-0.2-0.2-0.3-0.2c0.2,0,0.3,0,0.5,0.1
        c0-0.1-0.1-0.3-0.2-0.4c0.2,0,0.4-0.1,0.5-0.3l-0.1,0l-0.1,0.1c-0.1,0-0.2,0-0.2,0c-0.1-0.3,0.2-0.4,0.4-0.5
        c-0.3-0.2,0.1-0.7,0.4-0.7c0,0.1-0.1,0.3-0.1,0.4c0.1,0,0.3,0.1,0.4,0.1c-0.2-0.2-0.4-0.5,0-0.5c0,0,0-0.1,0-0.2
        c-0.1,0-0.2,0.1-0.3,0.2c0-0.2,0.1-0.4,0.2-0.6c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.3,0,0.4,0c-0.2-0.5,0.4-0.6,0.7-0.8
        c-0.1,0-0.3,0-0.4,0c-0.2,0-0.2,0.4-0.5,0.3c0.1-0.3,0.5-0.7,0.1-0.9c0.1,0,0.3,0,0.4,0.1c0-0.1-0.1-0.3-0.1-0.4l0.4,0.2l-0.2-0.4
        c0.1,0,0.3,0.1,0.4,0.2c0-0.1-0.1-0.3-0.1-0.4c0.1,0,0.3-0.1,0.4-0.1l-0.1-0.2c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0-0.2-0.1-0.3-0.2
        c0,0.2-0.1,0.4-0.3,0.4c0.1-0.2,0.2-0.3,0.3-0.5c0.1,0,0.2,0,0.2,0c0-0.1-0.1-0.2-0.2-0.3c0.1,0,0.3,0,0.4,0
        c-0.1-0.1-0.2-0.3-0.3-0.4c0.1,0.1,0.3,0.2,0.4,0.2c-0.2-0.3-0.2-0.6,0.2-0.7c0,0.1,0.1,0.4,0.1,0.6c0.4-0.2,0-0.9-0.4-0.7
        c0-0.1,0.1-0.3,0.2-0.4c-0.1-0.1-0.1-0.1-0.2-0.2c0.1-0.1,0.3,0.1,0.4,0.1c-0.2-0.3-0.2-0.4,0.1-0.5c0,0.1,0.1,0.2,0.1,0.3
        c-0.1-0.4,0.1-0.7,0.5-0.7c-0.5,0.1-0.4-0.7-0.2-1c-0.1-0.2-0.2-0.4-0.3-0.6c0.1,0,0.3,0,0.4,0c0-0.2,0-0.3,0.1-0.5
        c-0.3-0.3-0.1-0.7-0.1-1.1c-0.2-0.5,0.4-0.9,0.6-1.3c-0.1,0-0.3,0-0.4,0.1l0-0.1c0.3-0.1,0.8-0.2,0.8-0.6c-0.1,0.1-0.2,0.2-0.3,0.3
        c-0.1,0-0.4,0-0.5,0.1c0.2-0.2,0.3-0.5,0.5-0.7c0.1-0.1,0.4-0.6,0-0.4c0,0.1-0.1,0.3-0.1,0.4l-0.2,0c0-0.2,0.1-0.5,0.4-0.5
        c-0.1-0.3-0.3-0.6-0.3-0.8c0.1,0.1,0.3,0.1,0.4,0.2c0.1-0.1,0.1-0.2,0.2-0.3c0-0.2,0.1-0.3,0.1-0.5c0.1,0,0.2-0.1,0.2-0.2
        c-0.3,0.1-0.4,0.5-0.6,0.7c-0.1-0.2-0.2-0.5-0.4-0.7c0.2,0,0.3-0.1,0.4-0.2c-0.1,0-0.4,0.1-0.5,0.1c0.2-0.3,0.1-0.6,0-0.8l0.1,0.2
        c0.3,0.1,0.4,0.3,0.7,0.4c-0.2-0.2-0.3-0.5-0.6-0.5c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.1,0.4,0,0.5-0.2c-0.3,0-0.5,0.1-0.7,0.2
        c0.1-0.3,0.1-0.6,0-0.9c0.1,0,0.2-0.1,0.2-0.1l0.1-0.2c0,0,0.1,0,0.2,0c0-0.2,0.1-0.4,0.2-0.6c-0.4,0.2-0.7-0.2-1-0.5
        c0.2,0.1,0.4,0.1,0.6,0.2c-0.2-0.3-0.4-0.5-0.6-0.8c0.1,0,0.3,0.1,0.4,0.1l0-0.2l0.2-0.3c-0.1,0-0.3,0-0.4,0.1
        c0.1-0.1,0.2-0.2,0.4-0.3c-0.5,0.1-0.6-0.4-0.5-0.7c0.2,0.5,0.6,0.6,1,0.8c0.1,0,0.2,0.1,0.3,0.1c-0.1,0.1-0.2,0.2-0.3,0.2
        c0.1,0,0.2,0,0.4,0c0-0.1,0-0.3,0-0.3c0,0-0.1-0.1-0.1-0.1c-0.4,0.3-0.5-0.5-0.5-0.7c-0.1,0.1-0.3,0.2-0.3,0.2
        c-0.1-0.2-0.3-0.4-0.4-0.6c0.1,0.1,0.3,0.3,0.4,0.4c0.1-0.1,0.2-0.2,0.3-0.3c-0.1,0-0.4,0.1-0.5,0.1c0.1-0.1,0.2-0.2,0.2-0.3
        c0-0.2,0-0.4,0-0.6c0.5,0.4,0.2-0.4,0.3-0.6c-0.2,0.3-0.4,0.6-0.7,0.9c-0.1-0.2-0.2-0.3-0.3-0.5c0.1,0,0.2,0,0.3,0.1
        c0-0.3,0-0.6,0.3-0.7c0-0.1-0.1-0.2-0.1-0.3c-0.1,0.1-0.2,0.3-0.3,0.4c0-0.2,0-0.5,0-0.7c-0.1,0.1-0.2,0.4-0.3,0.5
        c0,0-0.1-0.1-0.2-0.1c0.1-0.2,0.3-0.3,0.5-0.4c-0.2-0.2-0.5-0.2-0.7-0.3c0-0.1,0.1-0.3,0.2-0.4c-0.1-0.1-0.2-0.2-0.2-0.3
        c0.2,0,0.5,0.3,0.3,0.5c0.1,0.1,0.3,0.2,0.4,0.3c0-0.2,0-0.5,0-0.7c-0.4-0.2-0.1-0.7-0.2-0.9c-0.1,0-0.3,0-0.4,0c0.1,0,0.3,0,0.4,0
        c-0.1-0.4-0.1-0.9-0.4-1.2c-0.1-0.1-0.5-0.2-0.3-0.3c0.1,0,0.3,0.2,0.4,0.2c0-0.2-0.1-0.3-0.2-0.5c-0.1,0-0.2,0.1-0.3,0.1
        c-0.2-0.2,0-0.5-0.2-0.7c-0.2-0.1-0.3-0.3-0.5-0.4c-0.2-0.1-0.4-0.2-0.6-0.3l0.3-0.2c-0.2-0.1-0.3-0.1-0.5-0.2
        c0-0.4-0.2-0.4-0.4-0.2c0-0.1,0-0.2,0.1-0.3c-0.3,0-0.4-0.1-0.4-0.4c-0.1,0.1-0.2,0.2-0.2,0.2c0-0.1,0-0.3,0-0.4
        c-0.4,0-0.3-0.6-0.8-0.4c0-0.1,0.1-0.2,0.1-0.3c-0.1,0-0.3,0.1-0.3,0.1c-0.1-0.2-0.2-0.3-0.1-0.5c-0.1,0-0.2,0.1-0.3,0.1
        c-0.4-0.4-0.8-0.6-1.1-1.1c-0.1,0-0.4,0-0.5,0c0-0.1,0-0.2,0-0.2c-0.5-0.2-0.9-0.5-1.3-0.9c-0.2,0-0.3,0-0.5,0
        c-0.1-0.2-0.2-0.4-0.3-0.6l0,0.3c-0.3-0.2-0.6-0.3-0.9-0.3c0-0.2-0.1-0.3-0.1-0.5c-0.1,0.1-0.2,0.1-0.4,0.2
        c-0.2-0.2-0.6-0.1-0.7-0.3c-0.1-0.3-0.3-0.3-0.6-0.2c-0.2-0.2-0.3-0.3-0.5-0.4c-0.1,0.1-0.2,0.2-0.3,0.3c0-0.1,0-0.4,0-0.5
        c-0.2,0.2-0.4,0.2-0.6,0.1l0.2,0l0-0.3c-0.1,0-0.3,0-0.4,0.1c-0.1-0.1-0.3-0.5-0.4-0.3c-0.3,0.4-0.4-0.2-0.5-0.4
        c0,0.1-0.1,0.3-0.1,0.3c-0.2-0.3-0.5-0.4-0.8-0.3c0.1-0.4-0.2-0.2-0.4-0.1c-0.2,0-0.4-0.1-0.5-0.3c-0.2,0-0.3,0-0.5,0
        c0-0.1,0-0.2,0-0.2c-0.2,0-0.4,0.2-0.5,0c-0.4,0-0.8-0.2-1.2-0.4c-0.1,0-0.2,0.1-0.3,0.1c0.1-0.3-0.3-0.3-0.4-0.6c0,0.1,0,0.2,0,0.3
        c-0.2,0.1-0.3-0.2-0.5-0.3c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1-0.3-0.3-0.4-0.5-0.5c0,0.1,0,0.3,0,0.4c-0.3-0.1-0.5-0.4-0.9-0.3
        c-0.1-0.1-0.2-0.2-0.3-0.3c0.1,0.4-0.4,0.2-0.6,0c-0.1,0.1-0.2,0.2-0.3,0.3c0-0.1,0.1-0.3,0.1-0.4c-0.4,0.2-0.9,0.2-1.3,0.1
        c-0.2-0.1-0.3,0-0.5,0c-0.1-0.1-0.1-0.3-0.1-0.4c-0.1,0.1-0.2,0.3-0.2,0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1,0-0.2,0.1-0.3,0.1
        c-0.2-0.1-0.4-0.1-0.5-0.1c-0.3,0.1-0.6,0.1-0.9,0.1c-0.2-0.2-0.4-0.1-0.6-0.1c-0.3,0-0.7,0.3-1,0c-0.2,0.2-0.4,0.1-0.6,0
        c-0.4,0.3-0.9,0.3-1.3,0.3c-0.1,0-0.2-0.1-0.2-0.1c-0.4,0.2-0.8,0.1-1.2,0.3c-0.3,0-0.7,0.1-1,0.1c-0.7,0.3-1.5,0.4-2.2,0.5
        c-0.6,0.3-1.3,0.3-1.8,0.7c-0.2-0.1-0.4-0.2-0.4,0.1c-0.1,0-0.2-0.1-0.2-0.1c-0.2,0.7-1,0.7-1.6,1c0,0.1-0.1,0.2-0.2,0.3
        c-0.4,0.1-0.7,0.3-1.1,0.5c-0.3,0.1-0.5,0.5-0.9,0.5c-0.4,0.4-0.9,0.7-1.3,1.1c-0.2,0.3-0.4,0.5-0.7,0.8c-0.1,0-0.2-0.1-0.2-0.1
        c-0.1,0.2-0.3,0.4-0.3,0.7c-0.5,0-0.7,0.4-0.8,0.8c-0.6,0-0.6,0.7-0.9,1c-0.4,0.3-0.5,0.7-0.7,1.1c-0.2,0.5-0.8,0.7-0.8,1.3
        c-0.2,0.7-0.5,1.3-0.8,2c-0.2,0.4-0.2,0.9-0.5,1.3c-0.1,0.8-0.4,1.5-0.4,2.3c0.1,0.4-0.2,0.9-0.1,1.3c0.1,0.6-0.1,1.1,0,1.7
        c-0.2,0.3,0,0.7,0,1c-0.1,0.6,0.3,1.1,0.2,1.6c0.1,0.2,0.2,0.4,0.2,0.6l-0.3-0.1c0.4,0.2,0.4,0.7,0.6,1.1c0.1,0.3,0.2,0.6,0.3,0.8
        c0,0-0.1,0.1-0.1,0.2c0.4,0.1,0.3,0.8,0.5,1.1c0.1,0,0.2,0.1,0.3,0.1c-0.1,0-0.2,0.1-0.2,0.1c0.4,0.4,0.6,1,0.9,1.4l0.1,0
        c0.1,0.2,0.1,0.5,0.2,0.7c0.1,0,0.2,0,0.2,0c0.1,0.4,0.3,0.7,0.4,1.1l0.1,0c0,0.2,0,0.4,0,0.6c0.1-0.1,0.2-0.3,0.2-0.5
        c0.3,0.4,0.5,0.8,0.7,1.2l0.1,0l-0.1,0.2l0.2,0c0,0.1-0.1,0.3-0.1,0.3c0.1-0.1,0.2-0.2,0.3-0.3c0,0.1,0,0.3,0,0.4
        c0.1,0,0.2,0,0.3-0.1c0,0.2,0,0.4,0.3,0.4c0.2,0.3,0.5,0.5,0.4,0.9c0.1-0.1,0.2-0.2,0.3-0.2c0,0.1,0,0.4,0,0.5
        c0.1,0,0.2-0.1,0.2-0.1c0.1,0.2,0.2,0.3,0.4,0.4c0,0.1,0,0.2,0,0.2l0.2,0l0,0.1c0.1,0,0.2,0,0.2,0l0,0.2c0.1,0,0.2,0,0.3,0
        c-0.1,0.4,0.2,0.5,0.5,0.5c0.1,0.1,0.2,0.3,0.4,0.4c0,0.3,0.1,0.4,0.4,0.2l-0.1,0.2c0.1,0,0.3,0,0.4,0c0.1,0.4,0.7,0.4,0.7,0.9
        c0.2,0,0.3,0.1,0.4,0.3c0.2,0.1,0.5,0.2,0.6,0.5c0.1,0,0.3-0.1,0.3,0.2c0.1,0,0.3,0,0.4-0.1l-0.1,0.2c0.1,0,0.2,0,0.3-0.1
        c0.1,0.1,0.2,0.3,0.3,0.5c0.1-0.1,0.2-0.1,0.3-0.2c0,0.1,0,0.3,0,0.4c0.1,0,0.3,0,0.4,0.1c-0.1,0.5,0.5,0,0.4,0.4l0.2-0.1
        c0,0.1,0,0.2,0,0.3c0.3-0.1,0.5-0.2,0.5,0.2c0.4-0.2,0.7,0.3,0.9,0.6c-0.1,0-0.4,0-0.5,0c-0.1-0.3-0.4-0.4-0.6-0.3
        c0-0.1,0-0.2,0-0.3c-0.3,0-0.6,0-0.6-0.4c-0.2,0.1-0.4,0.1-0.4-0.2c-0.2,0-0.3-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.5-0.2
        c-0.4-0.3-0.8-0.4-1.2-0.6c-0.5-0.3-0.9-0.7-1.4-0.9c-0.2-0.4-0.6-0.6-0.9-0.7c-0.2-0.2-0.3-0.3-0.4-0.5c-0.3,0-0.7-0.2-0.5-0.5
        c-0.1,0-0.2,0.1-0.3,0.1c-0.1-0.2-0.2-0.3-0.4-0.4c-0.3-0.1-0.4-0.5-0.8-0.6c0-0.1,0-0.3,0-0.4c-0.1,0-0.2,0.1-0.3,0.1l0-0.2
        c-0.3,0-0.3-0.5-0.7-0.5c0-0.1,0.1-0.2,0.1-0.3c-0.1,0-0.3,0.1-0.3,0.1c0-0.1,0.1-0.3,0.1-0.4l-0.2,0l0-0.2c-0.1,0-0.3,0-0.3,0
        c-0.1-0.2-0.1-0.4-0.4-0.4c-0.2-0.3-0.2-0.8-0.6-0.8c0-0.1,0-0.2,0-0.2c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.3,0-0.3c-0.1,0-0.2,0-0.3,0
        c0-0.1,0-0.2,0.1-0.3l-0.2,0c0-0.1,0-0.2,0-0.3l-0.2,0c0-0.1,0.1-0.2,0.1-0.3l-0.2,0.1c0-0.3,0-0.5-0.3-0.7c0-0.1,0-0.2,0-0.2
        c-0.1,0-0.2,0-0.3,0c0-0.1,0.1-0.2,0.1-0.3l-0.2,0c0-0.1,0-0.2,0-0.2c-0.3-0.5-0.7-0.9-0.9-1.5l-0.1,0c0-0.1,0-0.3,0.1-0.4
        c-0.1-0.1-0.2-0.3-0.4-0.4c0-0.1,0.1-0.2,0.2-0.3c-0.4-0.4-0.6-0.9-0.4-1.5c-0.1,0-0.2,0.1-0.2,0.2c0-0.1,0-0.2,0-0.3
        c0.2-0.2,0.1-0.3-0.1-0.3c-0.3-0.2-0.3-0.7-0.5-0.9c0.1-0.1,0.2-0.2,0.2-0.2c-0.1,0-0.3,0-0.4,0c0-0.2,0.2-0.3,0.3-0.4l0,0
        c-0.1,0-0.3,0.1-0.4,0.1c0-0.3,0.2-0.5,0.2-0.7c-0.1,0.1-0.2,0.1-0.4,0.2c0.3-0.3,0.2-0.5-0.1-0.6c0.4-0.1,0-0.7,0.3-0.9
        c-0.1,0-0.3,0.1-0.4,0.1c0.1-0.1,0.2-0.2,0.3-0.3c-0.1-0.7,0-1.3-0.1-2l0.2,0.1c0-0.1,0-0.2,0-0.3c-0.1,0-0.2,0-0.3,0l0-0.3
        c0.2,0,0.3-0.3,0.4-0.5l-0.2,0c0-0.2,0-0.5,0-0.7c0.4,0.1,0.3-0.2,0.2-0.4c0-0.1,0.1-0.2,0.2-0.2c-0.1,0.1-0.3,0.1-0.4,0.2
        c-0.1-0.6,0.3-1,0.3-1.6c0,0.1,0.1,0.3,0.1,0.4c0,0,0.1,0,0.2,0c0-0.1-0.1-0.2-0.2-0.2c0.1-0.2,0.5-0.6,0.2-0.8
        c0,0.1-0.1,0.3-0.1,0.4c-0.1-0.1-0.2-0.3-0.1-0.5c0.1,0,0.3-0.1,0.4-0.1c-0.1-0.1-0.2-0.2-0.2-0.3c0.2-0.1,0.5-0.2,0.5-0.5
        c-0.1,0.1-0.3,0.2-0.4,0.3c0.2-0.2,0.3-0.4,0.5-0.6c0.1-0.1,0.1-0.5,0.3-0.4c-0.1-0.3,0-0.9,0.4-0.7l0-0.2c0.1,0,0.3-0.1,0.4-0.1
        l-0.3,0c0-0.3,0.1-0.5,0.2-0.8c0,0,0.1-0.1,0.2-0.1l0.2,0.1c0-0.2-0.1-0.4-0.1-0.6c0.1,0,0.3-0.1,0.4-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
        c0.4-0.2,0.5-0.8,1-0.6l-0.1-0.2l-0.2-0.1c0.2-0.2,0.4-0.4,0.6-0.6c0.3,0.1,0.7-0.3,0.3-0.5c0.1-0.1,0.3-0.2,0.4-0.3
        c0-0.1,0.1-0.2,0.1-0.3c0.2,0.1,0.5,0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.5-0.3c0-0.1-0.1-0.3-0.1-0.3c0.1,0,0.4,0.1,0.5,0.1
        c-0.1-0.1-0.3-0.3-0.4-0.4c0.2,0,0.6,0.1,0.5-0.2c0.2-0.1,0.4-0.2,0.6-0.2l0-0.3l-0.2,0.1c0.1-0.1,0.3-0.2,0.4-0.3
        c0,0.1,0,0.3,0,0.4c0.2-0.1,0.3-0.3,0.3-0.5l0.2,0c0,0.1,0,0.3,0,0.3l0.1,0c0-0.1-0.1-0.3-0.1-0.5c0.2-0.1,0.4-0.1,0.6-0.2
        c0-0.2,0.2-0.3,0.3-0.4c0.1-0.2,0.2-0.5,0.5-0.5c0,0.1-0.1,0.2-0.2,0.2l0,0.3c0.4-0.2,0.3-0.7,0.6-1c0,0.2-0.1,0.4-0.1,0.6
        c0.2-0.1,0.3-0.4,0.5-0.4l-0.1-0.1c0.4,0.1,0.7-0.3,1.1-0.4c0-0.1-0.1-0.2-0.1-0.3l0.2,0.1l0-0.3c0,0.1,0.1,0.2,0.1,0.3
        c0.2-0.1,0.4-0.2,0.6-0.2c0.2-0.1,0.3-0.3,0.3-0.6c0,0,0.1,0,0.1,0c0,0.1,0,0.3,0,0.4c0.3-0.1,0.3-0.4,0.5-0.6
        c0.2-0.1,0.3-0.2,0.5-0.3c-0.2,0.1-0.2,0.3-0.2,0.5c0.1-0.1,0.3-0.2,0.3-0.3c0,0,0.1,0.1,0.2,0.2c0-0.2,0.1-0.4,0.1-0.5
        c0.1,0,0.3,0.1,0.4,0.2c0-0.2,0.1-0.3,0.1-0.5c0.1,0.3,0.4,0.2,0.6,0.2c0-0.1,0-0.2,0-0.2c0.1,0,0.2,0.1,0.3,0.1
        c0.1-0.4,0.2-0.2,0.4,0c0.1-0.2,0.1-0.3,0.2-0.5c0,0.2,0.1,0.3,0.3,0.3c0-0.1,0-0.2,0-0.3c0.3,0.2,0.5,0.1,0.8,0
        c-0.1,0.1-0.2,0.4-0.3,0.5c0.2-0.1,0.4-0.2,0.3-0.5l0.4-0.1l-0.1,0.3c0.2-0.2,0.3-0.3,0.5-0.5c-0.2,0-0.6-0.3-0.5,0.2
        c-0.1-0.5,0.5-0.4,0.8-0.6c0,0.1-0.1,0.3-0.1,0.3l0.3-0.1c0-0.1,0-0.2,0.1-0.3c0,0,0.1,0,0.1,0l-0.1,0.2c0.3,0,0.7,0,1-0.1
        c0,0.1-0.1,0.3-0.1,0.4c0.3,0,0.1-0.3,0.1-0.5c0.1,0,0.3,0,0.5,0c0-0.1,0-0.3,0-0.4c0.1,0.2,0.3,0.4,0.5,0.5
        c0.1-0.1,0.2-0.3,0.3-0.4c0,0.1,0.1,0.2,0.1,0.3l0.2-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c0.2,0,0.3,0,0.5,0.1l0-0.2l0.1,0
        c0,0.3-0.1,0.6-0.2,0.8c0.1-0.1,0.2-0.1,0.3-0.2c0-0.1,0-0.4,0-0.5c0.1,0.1,0.3,0.2,0.4,0.2c0.1-0.3,0.2-0.4,0.4-0.2
        c0.3,0.1,0.4,0.1,0.4-0.2c-0.1,0-0.2,0-0.3,0l0-0.1c0.1,0,0.2,0,0.3,0.1l0,0.2c0.4-0.1,0.9,0.1,1.2-0.2c-0.2,0.7,0.4,0.2,0.5,0
        c0,0.1,0,0.3,0,0.5c0.2-0.2,0.4-0.4,0.7-0.3c0.1,0,0.4,0,0.5-0.1l-0.1,0.2c0.1,0,0.2,0,0.3,0c0.1-0.1,0.2-0.2,0.2-0.3
        c0,0.1,0,0.3,0,0.4c0.1-0.1,0.3-0.2,0.4-0.3c0.1,0.3-0.2,0.5-0.3,0.8c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.3,0.2-0.5,0.5-0.3l0.1-0.1
        c0.2,0,0.4,0,0.6-0.1c-0.1,0.1-0.1,0.3-0.2,0.4c0.2,0,0.4,0.1,0.5,0.2c-0.1-0.2-0.4-0.3-0.3-0.6c0.2,0,0.4,0.2,0.5,0.4
        c0.2-0.1,0.3-0.2,0.6-0.2c0,0.1-0.1,0.3-0.2,0.4c0.1-0.1,0.2-0.2,0.2-0.3l0.2,0c0,0.1,0,0.2,0,0.3c0.2,0,0.4,0,0.7,0
        c0,0.1,0.1,0.3,0.1,0.4c0.1-0.3,0.3-0.5,0.7-0.3c-0.1,0.1-0.2,0.2-0.2,0.3c0.2-0.1,0.5,0,0.5-0.3c0.1,0,0.3,0.1,0.4,0.2
        c0,0.1,0,0.3,0,0.4c0-0.1,0.1-0.3,0.1-0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.6,0,1.1,0.3,1.7,0.4c0.2-0.1,0.3-0.2,0.5-0.2
        c-0.1,0.1-0.3,0.2-0.4,0.4c0.1,0,0.4,0,0.5,0c0.1,0.1,0.3,0.2,0.4,0.3c-0.1-0.2-0.1-0.4-0.2-0.6c0.2,0.2,0.3,0.5,0.6,0.3
        c0,0.1-0.1,0.3-0.1,0.4c0.1-0.1,0.3-0.3,0.3-0.4c0.1,0,0.3,0,0.4-0.1c-0.1,0-0.2,0.1-0.3,0.2c0,0.2,0.1,0.3,0.4,0.1
        c0.2,0.2,0.5,0.2,0.7,0.3c0.1-0.1,0.2-0.2,0.2-0.3c-0.2,0.4,0.2,0.6,0.5,0.5c0.3,0.1,0.6,0.3,0.9,0.4c0,0,0-0.1,0-0.2
        c-0.1-0.1-0.4-0.1-0.4-0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.3,0,0.3,0c0,0.1-0.1,0.3-0.1,0.4c0.5-0.2,0.7,0.7,1.1,0.3
        c0.1,0.3,0.4,0.6,0.7,0.6c0-0.2-0.2-0.4-0.3-0.6c0,0,0.1,0,0.1,0c0.2,0.2,0.3,0.6,0.7,0.7c0,0,0,0.1,0,0.2l0.1,0.1
        c0-0.1-0.1-0.2-0.1-0.3c0.2,0,0.4,0.1,0.7,0.1c-0.2,0.1-0.4,0.1-0.5,0c0.2,0.4,0.7,0.3,1,0.4c0-0.1-0.1-0.2-0.1-0.3
        c0.1,0.1,0.3,0.2,0.4,0.3c-0.2,0-0.4,0.2-0.5,0.4c0.3,0,0.6-0.5,0.8,0l0.1-0.1c0.3,0.2,0.4,0.7,0.9,0.6c0.2,0.2,0.6,0.4,0.6,0.7
        c0,0,0.1,0,0.1,0l0-0.2c0.1,0,0.2-0.1,0.3-0.2c0.2,0.2,0.4,0.4,0.3,0.7c0.2-0.1,0.5-0.4,0.5,0l-0.2,0c0.1,0.1,0.1,0.2,0.2,0.4
        c-0.1,0.1-0.1,0.3,0.1,0.4c0-0.2,0-0.5,0-0.7c0.1,0.1,0.2,0.2,0.4,0.3c0.2,0.1,0.3,0.2,0.3,0.4c0.1-0.1,0.2-0.2,0.3-0.2
        c0,0.1,0,0.3-0.1,0.5c0.2-0.1,0.4-0.1,0.7-0.2c-0.1,0-0.2,0.1-0.3,0.1l-0.2,0.3c0.2-0.1,0.6-0.2,0.8,0c-0.3,0-0.5,0.1-0.5,0.3
        c0.1,0,0.4-0.1,0.5-0.1c0.3,0.3,0.7,0.5,1.1,0.7c0.1,0.2,0.1,0.6,0.5,0.6c-0.1-0.2-0.3-0.5-0.4-0.7c0.1,0,0.3,0,0.4,0.1l0.1,0.2
        l0.1,0c-0.2-0.2-0.4-0.5-0.7-0.4c0.2-0.3-0.2-0.3-0.3-0.5c-0.1,0-0.2,0.1-0.3,0.2c0.2-0.3,0.3-0.5,0.5-0.8c-0.2-0.2-0.2-0.5-0.5-0.5
        c0.1,0.2,0.2,0.4,0.2,0.6c-0.1,0.1-0.4,0.3-0.5,0.1c0.2-0.1,0.3-0.2,0.3-0.4c-0.1-0.3-0.3,0.1-0.3,0.2c0.1-0.1,0.3-0.4,0.3-0.5
        c-0.1,0-0.4,0.1-0.6,0.1c0,0,0.1-0.1,0.1-0.2c0.2,0.3,0.4-0.1,0.6-0.2c-0.1,0-0.3,0-0.4,0l0-0.2c-0.1,0-0.2,0-0.2,0
        c-0.2-0.2-0.5-0.5-0.4-0.8c0.2,0.2,0.2,0.6,0.5,0.7l0-0.4l0.2,0c0,0.2,0.2,0.4,0.3,0.6c0.1-0.1,0.2-0.2,0.2-0.3c-0.1,0-0.2,0-0.3,0
        c-0.1-0.4-0.5-0.8-0.9-0.8c0.1-0.3,0.5-0.3,0.6,0c0.3-0.1,0.2-0.5-0.1-0.5c-0.2,0-0.5-0.3-0.6,0.1c-0.1-0.1-0.2-0.1-0.3-0.2
        c0.2-0.1,0.5-0.1,0.6-0.3c-0.1,0-0.3-0.1-0.4-0.1c0-0.1,0-0.3,0-0.4c0-0.2,0.3-0.4,0-0.4c0,0.2-0.1,0.3-0.3,0.3
        c0-0.1-0.1-0.3-0.2-0.4c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1-0.2,0-0.6-0.3-0.6c0-0.1-0.1-0.3-0.1-0.3c0-0.1,0.1-0.1,0.1-0.1
        c0,0.3,0.5,0.5,0.3,0.9c0.1-0.1,0.2-0.1,0.4-0.2c-0.1-0.2-0.2-0.5-0.3-0.7c0.2,0.2,0.4,0.4,0.7,0.5c-0.1-0.6-0.8-0.5-1.1-0.8l0.3,0
        l0-0.4c0.1,0.1,0.2,0.2,0.3,0.3c0-0.3-0.3-0.4-0.5-0.4c0-0.1,0-0.4,0-0.5c0.1,0,0.3,0,0.4,0c-0.1,0-0.2-0.1-0.2-0.2
        c-0.3-0.1-0.3,0.4-0.6,0.4c0.1,0,0.2,0.1,0.3,0.2l0,0.3c-0.1-0.1-0.3-0.2-0.5-0.2c0-0.1,0-0.2,0.1-0.3c0.3-0.3,0-0.6-0.2-0.9
        l-0.2,0.1c0,0,0-0.1,0-0.1c0.1,0,0.3,0,0.3,0c0-0.1-0.1-0.2-0.2-0.3c-0.2,0.2-0.5,0.2-0.6-0.1c-0.1,0-0.2,0-0.3,0.1
        c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.3,0.1-0.4c-0.2,0.1-0.4,0.3-0.5,0.5c0-0.1,0.1-0.4,0.2-0.5l-0.4,0.2c0-0.1,0-0.2,0-0.3
        c0.1,0,0.3,0.1,0.4,0.1c0.2-0.2,0.4-0.6,0.6-0.1c0.3-0.2-0.1-0.4-0.3-0.4c0-0.1,0-0.3,0-0.3c0,0.2,0,0.3,0,0.5c-0.2,0-0.3,0-0.5,0
        c0-0.1,0.1-0.3,0.1-0.4c-0.1-0.2-0.2-0.4-0.4-0.6c0-0.3-0.3-0.4-0.3-0.7l-0.1,0c0,0.1,0,0.3-0.1,0.4c-0.2-0.1-0.4-0.3-0.6-0.4
        c0-0.1,0.1-0.3,0.1-0.3c0.1,0.1,0.3,0.2,0.4,0.3c-0.1-0.1-0.2-0.4-0.2-0.6c-0.1,0.1-0.3,0.2-0.4,0.3c0-0.2,0-0.5,0-0.6
        c-0.2,0.1-0.1,0.4-0.2,0.6c-0.2-0.1-0.4-0.3-0.6-0.4l0,0.3c-0.4-0.4,0-1,0.3-1.3c-0.2,0.1-0.4,0.1-0.6,0.2c0,0.1,0,0.3,0,0.4l-0.1,0
        c0-0.1,0-0.4,0.1-0.5c-0.2,0.1-0.3,0.2-0.5,0.3c0-0.1,0.1-0.3,0.1-0.4l-0.3,0.1c0.1,0,0.3-0.1,0.4-0.2l0.1-0.2c-0.1,0-0.3,0-0.4,0
        c0-0.1,0-0.2,0-0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.1-0.2-0.1-0.5-0.4-0.6l0,0.3c-0.1-0.1-0.3-0.3-0.4-0.4c0.3-0.3,0.8,0,0.8,0.4l0.1,0.1
        c-0.1-0.2,0-0.5-0.1-0.7c-0.2-0.1-0.5,0.1-0.8-0.1c-0.1-0.3-0.1-0.6-0.3-0.7c0,0.1,0.1,0.4,0.1,0.5c-0.1-0.1-0.2-0.3-0.3-0.4
        c-0.1,0-0.2,0-0.3,0c0.1-0.4-0.4-0.5-0.6-0.7c-0.1,0-0.3,0-0.4,0c0.1-0.1,0.3-0.2,0.3-0.2c-0.1-0.2-0.2-0.3-0.2-0.5
        c0,0.1,0,0.2,0,0.4c-0.2,0.1-0.4,0.1-0.5,0.3c0.3,0.1,0.5,0.4,0.8,0.4l-0.1,0.2l0.2-0.1c0.1,0.2,0.3,0.5,0,0.6
        c0-0.1-0.1-0.3-0.2-0.4l-0.2,0.1c-0.1-0.2-0.3-0.6-0.6-0.6l0-0.2c-0.3,0.1-0.5-0.2-0.7-0.3c0.2-0.3-0.1-0.3-0.3-0.5
        c0-0.1,0-0.2,0-0.2c-0.1-0.1-0.6-0.8-0.5-0.1c-0.2-0.1-0.3-0.3-0.4-0.4c0.3,0,0.4-0.5,0.8-0.4l0.1,0c-0.1-0.2-0.3-0.1-0.5-0.2
        c-0.1,0.2-0.3,0.4-0.5,0.4c0-0.3-0.2-0.3-0.4-0.2l0.2-0.2c-0.3-0.2-0.6-0.4-0.9-0.5c0-0.1,0-0.3,0-0.4c-0.1,0.1-0.3,0.2-0.4,0.3
        c0.1,0.2,0.3,0.3,0.5,0.5c-0.2-0.1-0.4-0.2-0.7-0.1c0-0.1,0-0.2,0.1-0.3c-0.1,0-0.3-0.1-0.4-0.1c0-0.1,0.1-0.2,0.1-0.2
        c0.1,0,0.2,0,0.3,0c-0.1,0-0.2-0.1-0.3-0.1l0-0.1c-0.2,0-0.4-0.1-0.5-0.3c-0.2,0.1-0.4,0.1-0.6,0.1c0-0.1,0-0.3,0-0.4
        c-0.3,0-0.3,0.3-0.2,0.5c-0.1-0.1-0.2-0.2-0.3-0.2c-0.4,0.2-0.6-0.7-0.1-0.5c0-0.1,0-0.2,0-0.3c-0.2,0-0.3,0.1-0.5,0.1
        c-0.2,0-0.2,0.4-0.4,0.2c0-0.1,0.1-0.3,0.1-0.4c-0.2-0.1-0.5-0.1-0.7-0.2l0-0.2c-0.1,0.1-0.3,0.1-0.4,0.2c0-0.1,0-0.2,0-0.3
        c-0.2,0.1-0.4,0-0.6-0.1c0,0.1-0.1,0.3-0.2,0.4c0-0.2,0-0.5,0-0.7c-0.2,0-0.3,0.1-0.5,0.2c0,0.1,0.1,0.3,0.1,0.4
        c-0.1-0.2-0.2-0.4-0.2-0.6c-0.2-0.1-0.4-0.2-0.5-0.3c0.1,0.2,0.1,0.3,0.2,0.5c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0-0.3,0-0.4,0
        c0-0.1,0.1-0.2,0.1-0.3c-0.5-0.1-1.1-0.6-1.6-0.3c0-0.1-0.1-0.2-0.2-0.2c-0.2,0-0.4-0.1-0.7-0.1c0-0.1,0.1-0.2,0.1-0.3
        c-0.1,0.2-0.3,0.3-0.6,0.3c0-0.1,0-0.2,0-0.2c-0.2,0-0.4,0-0.6-0.1c0-0.2-0.2-0.2-0.3-0.2c-0.2,0-0.3,0-0.5,0
        c-0.3-0.1-0.7-0.6-0.8-0.2c-0.1-0.2-0.2-0.3-0.3-0.5l0,0.4c-0.3-0.1-0.5-0.2-0.8-0.4c-0.4,0.2-0.7,0.5-0.9,0.9
        c-0.3,0.2-0.6,0.6-0.8,0.9c-0.7,0.6-1.2,1.4-1.9,2c0-0.1-0.1-0.2-0.2-0.3c0.1,0.3,0.1,0.5-0.3,0.5c-0.3,0.5-0.9,0.8-1.4,1.1
        c-0.6,0.3-1,0.8-1.5,1.2c-0.1,0-0.2-0.1-0.2-0.2c0,0.1,0,0.2,0,0.3c-0.3,0-0.5,0.3-0.7,0.5c-0.1,0-0.3,0-0.4,0l0,0.2
        c-0.1,0-0.2,0-0.3,0c-0.2,0.1-0.4,0.3-0.5,0.4c-0.2,0-0.5,0-0.7,0.1c0,0.1-0.1,0.2-0.2,0.2c-0.2,0.1-0.5,0.2-0.7,0.3
        c-0.1-0.1-0.1-0.2-0.1-0.2c0,0.4-0.5,0.2-0.6,0.5c-0.2,0.2-0.6,0.3-0.9,0.4c-0.1-0.1-0.1-0.2-0.2-0.3c0,0.1,0,0.2,0,0.3
        c-0.5,0.3-1.1,0.4-1.7,0.6c-0.2,0.1-0.6,0-0.7,0.2c-0.3,0-0.6,0.2-0.8,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c0-0.1,0-0.2,0-0.3
        c-0.1,0.1-0.2,0.2-0.3,0.2c0,0-0.1-0.1-0.2-0.2c-0.4,0.5-1,0.1-1.4,0.4c-0.2-0.1-0.5-0.1-0.6,0.1c-0.3,0-0.6,0-0.8,0
        c-0.1,0-0.2,0.1-0.2,0.1c-0.2,0-0.5,0-0.7,0.1c-0.3,0-0.5,0-0.7-0.1c-0.3,0.1-0.5,0.2-0.8,0.1c0.3-0.1,0.6-0.2,0.8-0.4
        c0.2,0.2,0.4,0.2,0.7,0.1c0.3-0.1,0.7-0.1,1-0.3c0.3,0.2,0.8,0.1,1-0.2c0.2,0.1,0.3,0.1,0.5,0.2c0-0.1,0-0.3,0-0.4
        c0.2,0.1,0.3,0.1,0.5,0.2c0-0.1,0-0.2,0-0.3c0.1,0,0.2,0.1,0.2,0.2c0.2-0.1,0.3-0.1,0.5-0.1l0-0.2c0.1,0,0.2,0.1,0.4,0.1l0-0.3
        c0.1,0.1,0.2,0.2,0.3,0.3c0.1-0.1,0.1-0.3-0.1-0.3c0.2,0,0.6,0.1,0.7-0.1c0.3,0,0.5,0.1,0.4-0.3c0.2,0.1,0.4,0.3,0.6,0
        c0.2,0,0.4-0.1,0.7-0.2l0-0.1c0.1,0,0.2,0,0.3,0.1l0-0.2c0.1,0.1,0.3,0.2,0.3,0.3c0-0.1,0-0.3,0.1-0.4l0.2,0.1l-0.1-0.3
        c0.1,0.1,0.3,0.2,0.4,0.2c0.1-0.2,0.4-0.5,0.5-0.1c-0.1-0.3,0-0.7,0.4-0.7c0,0.1,0,0.3,0,0.4c0-0.1,0.1-0.2,0.2-0.3c0,0,0.1,0,0.2,0
        c0.1,0,0.3-0.1,0.3-0.1l-0.1-0.2l0.3,0c0,0.1,0.1,0.2,0.2,0.3c0-0.1-0.1-0.3-0.1-0.4c0.2,0,0.5,0,0.6-0.2c-0.2,0-0.3,0.1-0.5,0.1
        c0.1-0.1,0.2-0.2,0.3-0.3c0.2,0,0.4-0.1,0.6-0.3c0,0.1,0,0.2,0,0.3l0.2-0.1c0,0.1,0.1,0.3,0.2,0.4c0-0.2,0-0.4,0-0.6
        c0.1,0,0.3,0,0.4-0.1c0.2-0.1,0.4-0.2,0.6-0.3c0-0.1,0-0.2-0.1-0.2c0.3,0,0.2-0.2,0.2-0.4c0.1,0,0.3-0.1,0.4-0.1
        c0.1-0.1,0.2-0.2,0.2-0.3c0,0.2,0.1,0.3,0.3,0.2c-0.1-0.2,0-0.4,0.2-0.5c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.3,0,0.4,0
        c-0.1-0.2,0-0.3,0.3-0.3c0.2,0,0.3-0.3,0.6-0.2c0-0.1-0.1-0.3-0.1-0.4c0.1,0,0.2,0,0.3,0c0-0.1,0-0.2,0-0.3c0.2,0.2,0.4,0,0.6-0.1
        l0-0.2c-0.2,0-0.3,0.1-0.5,0.2c0.1-0.3,0.4-0.2,0.6-0.3c0,0.2,0.1,0.5,0.2,0.1c0.1,0,0.2,0,0.3,0c0-0.4,0-0.7-0.2-1
        c0.2-0.1,0.2,0.2,0.3,0.3c0-0.3,0.4-0.4,0.6-0.6l-0.3,0c0.2-0.2,0.7-0.4,0.4-0.8l0.2,0c0,0.1,0,0.3,0,0.4c0.3-0.1,0-0.4,0.3-0.5
        c-0.2-0.1-0.3-0.2-0.2-0.4l0.1,0.3c0.2-0.2,0.3-0.5,0.6-0.2c0-0.3,0-0.5,0-0.8c-0.3,0.3-0.7,0.3-1,0.2c0,0.1,0,0.2,0,0.2
        c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.3-0.2-0.4-0.4-0.3c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0-0.3,0-0.4,0l0-0.3c-0.3,0-0.5,0.1-0.8,0.2l0,0.2
        c0,0-0.1,0-0.2,0c0-0.1,0.1-0.3,0.1-0.3c-0.2,0-0.5,0.1-0.6,0.1c-0.3-0.1-0.4-0.4-0.7-0.1c-0.1-0.1-0.3-0.3-0.5-0.2
        c-0.2,0.2-0.3,0.3-0.6,0.5c0-0.2,0.1-0.3,0.2-0.5l-0.5,0c0,0.1,0.1,0.2,0.1,0.3c-0.3-0.1-0.5,0.3-0.7,0c-0.2-0.1-0.5-0.2-0.6-0.2
        c0,0.1,0.1,0.3,0.1,0.4c0,0-0.1,0-0.1,0l-0.1-0.2l-0.1,0.1l-0.1,0.1c-0.1,0-0.3,0-0.4,0c0.1-0.1,0.2-0.2,0.3-0.2c0-0.3-0.3,0-0.4,0
        c-0.3,0-0.6,0-0.9,0c0,0.1,0.1,0.3,0.2,0.5c-0.2,0-0.2-0.2-0.2-0.4c-0.3,0.2-0.6,0.2-0.9,0.2c-0.1-0.1-0.2-0.2-0.3-0.2
        c0,0.1,0,0.3,0.1,0.3c-0.4,0-0.8,0-1.2,0c-0.2,0-0.2,0.1-0.3,0.2c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2,0.3-0.5,0.3-0.8,0.4l0.1,0.2
        c-0.6-0.1-1.2,0.4-1.8,0.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2-0.3-0.5-0.3-0.7,0c-0.4,0.2-0.7,0.4-1,0.6c-0.7,0.3-1.3,0.7-2,1
        c-0.4,0.3-0.8,0.5-1.1,0.8c-0.2,0.2-0.4,0.4-0.7,0.6c-0.3,0.1-0.4,0.6-0.7,0.5c-0.2,0.3-0.4,0.8-0.8,0.8c-0.1,0.3-0.4,0.5-0.6,0.8
        c-0.4,0.4-0.6,1.1-1.2,1.3c0.1,0.6-0.5,0.9-0.6,1.4c-0.1,0-0.2,0-0.3,0c0,0.3-0.2,0.6-0.3,0.9c-0.2,0.1-0.2,0.2-0.2,0.3
        c-0.1,0.6-0.6,1-0.7,1.6c-0.1,0.3,0,0.8-0.5,0.8c0,0.1,0.1,0.2,0.2,0.3c-0.3,0.4-0.4,0.9-0.4,1.4c-0.1,0-0.2-0.1-0.3-0.2
        c0.2,0.3,0.1,0.7,0,1.1c0,0.6-0.3,1.1-0.3,1.7c-0.1,0-0.2,0-0.3,0c0,0.1,0.1,0.2,0.2,0.3c-0.1,0.1-0.2,0.2-0.3,0.4
        c0.2,0.3,0.2,0.7,0.1,1c-0.2,0.4,0,0.9,0,1.3c-0.1-0.1-0.2-0.3-0.2-0.4h-0.1c0,0.2,0.1,0.4,0.3,0.6c-0.1,0.9,0,1.9,0.1,2.9
        c0,0.7,0,1.5,0.3,2.1c-0.1,0.6,0.2,1.1,0.2,1.6c0.2,0.6,0.2,1.3,0.3,1.9c0.1,0.4,0.1,0.9,0.3,1.3c0,0.1,0.1,0.3,0.1,0.4
        c-0.4-1.2-0.6-2.4-0.8-3.7c0-0.2-0.1-0.4-0.1-0.6c-0.2-0.6-0.2-1.2-0.4-1.8c-0.2-0.6-0.1-1.3-0.2-1.9c-0.2-1.1-0.3-2.1-0.3-3.2
        c0.1,0,0.2,0,0.2,0c-0.2-0.1-0.3-0.2-0.2-0.5l0.1,0c-0.1-0.8,0-1.5,0-2.3l0.2,0c0-0.2-0.1-0.5,0-0.7c0.2-0.5,0.1-1,0.1-1.4
        c0.1,0.1,0.2,0.2,0.4,0.2c0-0.1,0-0.2,0-0.2c-0.1,0-0.2,0-0.3,0l0-0.3c0.1,0,0.2,0,0.3,0.1c0-0.1-0.1-0.2-0.2-0.2
        c0-0.2-0.1-0.9,0.3-0.6c0-0.1-0.1-0.2-0.1-0.3l-0.2,0c0.2-0.4,0.6-0.5,0.6-0.9c0.1,0,0.3-0.1,0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2
        c-0.1-0.3,0.3-0.4,0.5-0.5l-0.4,0c0.1-0.2,0.2-0.3,0.4-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c0.1,0,0.3,0,0.4,0c0.1-0.2,0.3-0.1,0.4,0.1
        c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1,0.1-0.3,0.2-0.4,0.3c0.1-0.2,0.1-0.4,0.2-0.7c0.1,0,0.2-0.1,0.3-0.1l0-0.1l-0.3,0
        c0.1-0.2,0.5-0.3,0.4-0.6c0.2-0.1,0.5-0.2,0.7-0.2c0,0,0-0.1,0-0.1c-0.3,0.1-0.5-0.2-0.8,0.1c0.1-0.2,0.3-0.3,0.4-0.4
        c-0.1-0.2,0-0.3,0.2-0.4c0.2-0.3,0.4-0.7,0.4-1.1c0,0.1,0.1,0.3,0.1,0.5l0.1-0.1c0-0.2-0.1-0.5,0-0.7c0.1,0.1,0.2,0.2,0.3,0.3
        c0-0.1,0-0.3-0.1-0.4c0.2,0,0.3-0.1,0.3-0.3c0.1,0,0.2,0,0.2,0c-0.1,0.2-0.1,0.3,0.1,0.4c0-0.1,0-0.4,0-0.5c0.1,0,0.2-0.1,0.3-0.2
        l-0.3,0.1l0.1-0.4c0.1,0,0.2,0.1,0.3,0.1c0-0.1-0.1-0.3-0.1-0.5c0.1,0,0.2,0,0.3,0l-0.1-0.3c0.1,0.1,0.2,0.1,0.4,0.2
        c-0.1-0.3,0.3-0.5,0.5-0.7c0.1-0.2,0-0.3-0.1-0.4c0,0.3-0.1,0.7-0.4,0.4c0.1-0.1,0.3-0.3,0.4-0.5c0.2-0.1,0.3-0.2,0.3-0.4
        c0.1,0.2,0,0.5,0.1,0.7c0.2-0.2,0.2-0.4,0.2-0.6c0.1,0,0.2,0,0.3,0c-0.1-0.3,0-0.5,0.1-0.8l0,0c0,0.2,0,0.5-0.1,0.7
        c0.4-0.2,0.2-0.7,0.5-1c0,0.1,0,0.4,0,0.6c0.2-0.1,0.3-0.2,0.3-0.5c0.1,0,0.3,0,0.4,0l0-0.3c-0.1,0-0.3,0.1-0.4,0.1c0,0,0-0.1,0-0.2
        c0.3,0.2,0.6-0.1,0.5-0.4l0.1,0.1l0,0.1c0,0.2,0.3,0.1,0.4,0.2c0-0.1-0.1-0.2-0.2-0.3c0.3,0,0.7-0.1,0.9-0.3c-0.2,0-0.4,0-0.6-0.1
        c0.3,0,0.8-0.4,0.8,0.1c0,0,0.1,0,0.1,0c0-0.1-0.1-0.3-0.1-0.4c0.1,0,0.3,0,0.3,0l-0.1-0.2c0.2,0,0.4-0.1,0.3-0.3
        c0.3,0.2,0.3-0.3,0.4-0.4c0.1,0.1,0.2,0.2,0.2,0.2c0-0.2,0.1-0.3,0.1-0.5c0.1,0.2,0.2,0.3,0.5,0.3c0-0.2-0.2-0.3-0.4-0.3l0-0.2
        c0.1,0,0.3,0,0.4,0c0.1-0.3,0.3-0.1,0.5-0.1c0-0.1,0-0.3,0-0.4c0.2,0.1,0.4,0.2,0.6,0.3c0-0.2-0.1-0.3-0.1-0.4c0.2,0,0.4,0,0.6,0
        c0-0.3,0.3-0.5,0.6-0.6l0,0.1c-0.1,0.1-0.2,0.3-0.3,0.4c0.3,0,0.6-0.2,1-0.2c0-0.1-0.1-0.2-0.1-0.3c0.2,0,0.3,0.1,0.5,0.1
        c0.1,0,0.2-0.1,0.4-0.1c0-0.1-0.1-0.3-0.2-0.4c0.3,0,0.5,0.4,0.8,0.5c-0.1-0.2-0.3-0.3-0.3-0.5c0.1,0,0.3,0,0.4,0l-0.1,0.2
        c0.2,0,0.4,0,0.5-0.2c-0.1,0-0.3-0.1-0.4-0.1l0-0.2c0.4-0.2,0.6,0.5,0.9,0.2c0-0.1,0.1-0.2,0.1-0.3c0.2,0.1,0.4,0.1,0.6,0.2
        c0-0.1-0.1-0.3-0.2-0.4c0.4,0,0.7,0.2,1,0.5c-0.1-0.2-0.2-0.4-0.3-0.5c0.1,0,0.2-0.1,0.3-0.1c0,0.1,0,0.2,0,0.3
        c0.3-0.1,0.6-0.2,1-0.2c0-0.1-0.1-0.3-0.2-0.4c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0,0.3,0,0.4,0.1l-0.1-0.4c0.1,0.1,0.2,0.3,0.3,0.4
        c0.3-0.2,0.5-0.4,0.8-0.1c0-0.3,0.2-0.4,0.3-0.5c0,0.1-0.1,0.2-0.1,0.4c0.3-0.2,0.6,0.1,0.9-0.2c0,0.1,0.1,0.3,0.1,0.3
        c0.2-0.2,0.5-0.2,0.8-0.2c0.1,0,0.4-0.1,0.5-0.1c-0.1-0.1-0.2-0.2-0.4-0.3l0.4,0.1l-0.1-0.3c0.1,0.2,0.2,0.3,0.3,0.5
        c0.2-0.1,0.4-0.2,0.5-0.3v0.1c-0.1,0.1-0.2,0.2-0.2,0.3c0.1,0,0.4-0.1,0.5-0.1l0.1-0.1l0.1,0l0,0.1c0.1,0.1,0.3,0.2,0.5,0.2
        c0.2-0.1,0.4-0.1,0.6-0.1c0-0.1,0.1-0.2,0.2-0.3c0.1,0.1,0.2,0.2,0.2,0.3l0-0.2l0.2,0c0,0.1,0,0.4,0,0.5c0.1-0.1,0.2-0.2,0.3-0.3
        c0.2,0,0.4,0.2,0.6,0.3l0,0.2c0.3-0.1,0.6-0.1,0.9-0.2c0.1-0.1,0.1-0.3,0.2-0.4c0,0.1-0.1,0.4-0.2,0.5c0.4-0.1,0.9-0.5,1.2,0.1
        c-0.1-0.1-0.2-0.4-0.2-0.5c0.3,0.1,0.7,0,0.6-0.4c-0.1,0.1-0.2,0.2-0.3,0.2c0,0-0.1-0.1-0.2-0.1c0.2-0.2,0.3-0.3,0.6-0.4
        c0-0.1-0.1-0.2-0.1-0.3c0.1-0.1,0.3-0.2,0.4-0.3c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.3,0.2-0.5l-0.2,0c0.1-0.1,0.3-0.3,0.4-0.4
        c-0.1,0.2-0.1,0.3-0.2,0.5c0.5-0.2,0.4-1,0.3-1.5l0.3,0.1c0-0.1,0-0.3-0.1-0.4c-0.1,0-0.2-0.1-0.3-0.1l0.2,0c0-0.1-0.1-0.4-0.1-0.5
        c0.1,0.1,0.2,0.2,0.3,0.3c0-0.2,0-0.4,0.1-0.5c0-0.3,0.1-0.7,0-1c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1-0.1-0.3-0.1-0.4
        c0.2-0.8,0.1-1.5,0.1-2.3c0.1-0.4,0-0.8-0.1-1.2c-0.1-0.2,0.2-0.3,0.3-0.3c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.5-0.2-1-0.1-1.5
        c0-0.5-0.3-0.9,0-1.3c-0.2-0.4-0.2-0.9,0-1.3c-0.2-0.2-0.2-0.6-0.1-0.9c-0.1-0.5-0.5-1-0.3-1.6c-0.3-0.7-0.3-1.4-0.5-2.1
        c0.1-0.3,0-0.7-0.1-1c-0.1-0.2-0.3-0.5-0.3-0.7c0.1-0.2,0-0.3-0.1-0.5c0.1-0.2,0-0.4-0.2-0.5c0-0.2,0.1-0.4-0.1-0.4
        c0-0.3,0-0.5-0.1-0.8l0.1,0c0-0.1-0.1-0.2-0.1-0.3c-0.2,0-0.3-0.1-0.3-0.3c0-0.4-0.5-0.6-0.3-1c-0.2-0.2-0.3-0.4-0.4-0.7
        c0-0.1,0.4-0.4,0-0.4c-0.3-0.3-0.3-1-0.8-1.2c0.1,0,0.3,0,0.4,0c-0.1-0.1-0.3-0.2-0.4-0.3c0-0.1,0.1-0.2,0.1-0.3
        c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1-0.3,0.1-1-0.4-0.7c0-0.1,0.1-0.2,0.1-0.3c-0.2-0.1-0.5,0-0.4-0.3c-0.1-0.2,0.2-0.7-0.3-0.5
        c-0.1-0.3-0.1-0.5-0.2-0.7c0.1,0,0.2-0.1,0.3-0.1c-0.1,0-0.3,0-0.4,0c0-0.3-0.3-0.4-0.5-0.5c0.1-0.1,0.2-0.2,0.2-0.3
        c-0.1-0.3-0.3-0.2-0.6-0.1c0.2-0.3-0.2-0.6-0.3-0.8c-0.3-0.3-0.4-0.7-0.7-1c-0.3-0.2-0.4-0.6-0.7-0.9c-0.2-0.1-0.2-0.4-0.3-0.6
        c-0.2-0.1-0.4-0.3-0.5-0.5c-0.3-0.2-0.5-0.5-0.6-0.8c-0.3-0.2-0.5-0.5-0.8-0.8c-0.2-0.2-0.5-0.3-0.6-0.5c-0.1-0.3-0.4-0.6-0.7-0.8
        c-0.4-0.3-0.7-0.7-1.1-1c-0.2-0.3-0.5-0.6-0.9-0.8c-0.5-0.7-1.3-1.1-1.9-1.7c-0.3-0.3-0.7-0.5-1-0.9c-0.1,0-0.2,0-0.3,0
        c-0.2-0.2-0.4-0.4-0.7-0.5c-0.3-0.3-0.9-0.4-0.9-1c-0.2-0.1-0.5,0-0.6-0.2c-0.5-0.4-1.1-0.6-1.5-1c-0.6-0.5-1.3-0.7-1.8-1.3
        c-0.4,0.1-0.5-0.3-0.7-0.5c-0.4-0.1-0.8,0-0.9-0.5c-0.5-0.3-1.1-0.3-1.5-0.7c-0.3,0-0.6-0.2-0.8-0.5c-0.4,0-0.7-0.2-1-0.4
        c-0.3,0-0.6-0.1-0.8-0.4c-1.1-0.2-1.8-1.1-2.9-1.1c-0.3-0.2-0.6-0.3-1-0.4c0-0.1-0.1-0.3-0.1-0.4c-0.4,0-0.7,0.1-1.1,0
        c0.1-0.2,0.1-0.3,0.2-0.5c-0.3,0-0.5,0.2-0.7,0.3c-0.3-0.2-0.7-0.2-0.9-0.5c0.1,0,0.4,0.1,0.6,0.1c0,0,0-0.1,0-0.2
        c-0.2,0-0.4,0-0.6,0l0-0.2c-0.2-0.1-0.5-0.1-0.6,0.1c-0.3-0.2-0.5-0.4-0.8-0.6c-0.6,0.4-1.2-0.1-1.6-0.4c-0.2,0-0.4,0.2-0.6,0.3
        c-0.3-0.2-0.5-0.4-0.8-0.6c-0.5-0.3-1.2-0.4-1.7-0.1c-0.5-0.1-1-0.2-1.4-0.4c-0.5-0.2-0.9,0-1.4-0.1c-0.2-0.1-0.4,0-0.6,0.1
        c-0.2-0.1-0.4-0.3-0.5-0.4C407.5,102,407.3,102.3,407.1,102.6 M372.7,102.1C372.8,102.2,372.8,102.2,372.7,102.1 M208.1,102.6
        c-0.2,0.1-0.1,0.4-0.1,0.5c-0.3,0.3-0.3,0.8-0.3,1.2l0,0c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1,0.3-0.1,0.6-0.3,0.8c-0.1,0-0.3,0-0.4,0
        c0,0,0,0.1,0,0.2l0.2-0.1l0,0.4c-0.1,0-0.2,0.1-0.4,0.1l0.3,0l-0.1,0.4c-0.1,0-0.2,0-0.3,0c0,0.1,0.1,0.2,0.2,0.2
        c0,0.1-0.1,0.3-0.1,0.4c-0.2,0-0.3,0-0.4,0.1l0.3,0c0,0.1-0.1,0.3-0.1,0.4c-0.1,0-0.2-0.1-0.3-0.1c0,0.1,0.1,0.2,0.1,0.3H206
        c-0.1,0.5-0.5,0.9-0.5,1.4l0,0c-0.1-0.1-0.2-0.2-0.3-0.2c0,0.2,0,0.5,0,0.7l-0.2-0.1c0.1,0.3,0,0.4-0.3,0.4c0,0.2,0,0.4,0.1,0.6
        l-0.3-0.1c0,0.1,0.1,0.3,0.1,0.3c-0.1,0-0.2,0-0.3,0c0,0.2-0.1,0.4-0.2,0.7c0-0.1-0.1-0.2-0.2-0.2c0,0.2-0.1,0.5-0.2,0.7
        c-0.1-0.1-0.3-0.2-0.4-0.3c0,0.1,0.1,0.2,0.1,0.3c0.1,0.2,0,0.3-0.1,0.4c-0.1,0.2-0.2,0.3-0.3,0.5c0.1,0,0.2,0,0.3,0
        c-0.1,0-0.2,0.1-0.2,0.1L203,112l0.2,0.3l-0.3-0.1l0,0.2l-0.1,0c0,0.2,0,0.4-0.3,0.5c0-0.1,0-0.3,0-0.3c0,0-0.1,0.1-0.2,0.2
        c0.3,0.2,0,0.5-0.2,0.7c0.1,0,0.3,0,0.4,0c-0.2,0.4-0.5-0.1-0.8-0.1c0.1,0.1,0.2,0.2,0.2,0.3c-0.1,0-0.3,0.1-0.4,0.1l0.3,0
        c-0.1,0.1-0.3,0.3-0.1,0.4c0.1-0.3,0.5-0.5,0.8-0.3c0.1-0.1,0.3-0.2,0.3-0.4c0.2,0.2,0.5,0.2,0.6-0.1c0.3,0.1,0.6-0.2,0.9-0.4
        c0.4-0.8,0.6-1.7,1.1-2.4c0.3-0.7,0.7-1.5,1-2.2c0.1-0.2,0.2-0.3,0.4-0.3c-0.1-0.2-0.1-0.3,0.1-0.4c-0.1-0.3,0-0.5,0.1-0.7
        c0.1-0.3,0.1-0.5,0.3-0.7c0.3-0.7,0.4-1.4,0.7-2c0-0.5,0.1-0.9,0.2-1.4c0-0.2,0.2-0.3,0.2-0.5C208.2,102.3,208.1,102.4,208.1,102.6
        M221.2,102.3c-0.1,0.5-0.2,1.1,0,1.5c-0.2,0.6-0.1,1.3-0.1,2c0-0.3,0.1-0.6,0.2-0.9c0-0.2,0-0.4,0.2-0.5c0-0.7-0.3-1.3-0.2-1.9
        C221.3,102.4,221.2,102.3,221.2,102.3 M259.1,102.4C259.2,102.5,259.2,102.5,259.1,102.4 M251.5,102.9c-0.1-0.1-0.2-0.2-0.3,0
        c-0.1,0.4-0.6,0.7-0.8,1.1c0.3-0.2,0.4-0.6,0.8-0.7c0-0.1,0-0.2,0-0.3c0.1,0,0.2,0,0.3,0L251.5,102.9l0.4,0
        c-0.1,0.5-0.5,0.9-0.7,1.4c-0.2,0-0.3,0.1-0.4,0.3l0.3,0.2c0-0.5,0.8-0.3,0.8-0.8c0.2-0.2,0.4-0.3,0.4-0.6c0.4-0.1,0.5-0.6,0.6-1
        C252.3,102.5,251.9,102.6,251.5,102.9 M196.7,102.7c0,0.1,0.1,0.4,0.1,0.5c-0.1,0-0.2-0.1-0.3-0.2c0,0.1-0.1,0.4-0.1,0.5
        c-0.2,0-0.4,0-0.5-0.1c0.1,0.1,0.2,0.2,0.3,0.4c0.2-0.1,0.4-0.2,0.6-0.4c0.4,0.2,0.7-0.3,1-0.5c-0.1-0.1-0.3-0.2-0.4-0.3
        C197.1,102.7,196.9,102.7,196.7,102.7 M263.2,102.9c0.1,0.2,0.4,0.4,0.5,0.6c0.1,0.5,0.3,0.9,0.6,1.3c-0.1,0.3,0.1,0.5,0.3,0.6
        c0,0.1,0,0.3,0,0.4c0.1-0.5,0.1-1,0.3-1.4c0-0.1,0-0.2,0-0.3c-0.5-0.1-0.8-0.4-1-0.8c-0.1-0.1-0.2-0.2-0.3-0.3
        C263.4,102.9,263.3,102.9,263.2,102.9 M189,103c-0.1,0.2,0,0.3,0.2,0.2C189.3,103,189.2,103,189,103 M227.5,103.1L227.5,103.1
        c0.2,0.3-0.1,0.5-0.3,0.3c0,0.1,0.1,0.3,0.1,0.4l-0.2,0c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.1-0.2,0.2-0.3,0.2c0.1,0.1,0.1,0.2,0.2,0.4
        l-0.2,0.1c0.3,0.4-0.4,0.4-0.2,0.8c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0.2-0.1,0.5-0.1,0.7c-0.1,0-0.2,0.1-0.3,0.1l0-0.3
        c-0.1,0.1-0.2,0.2-0.3,0.3c0.2,0.4-0.3,0.7-0.2,1.1c-0.1,0-0.3,0-0.4,0c-0.1,0.3,0,0.7,0,1.1c0.2-0.1,0.5-0.1,0.4-0.4
        c0.5-0.1,0.6-0.6,0.6-1.1c0.1-0.1,0.2-0.2,0.3-0.4c-0.2,0-0.4-0.1-0.6-0.2c0.2,0,0.4,0,0.7-0.1c0.1-0.1,0.1-0.3,0.2-0.5
        c0.1-0.1,0.3-0.3,0.3-0.4c0-0.4,0.4-0.5,0.6-0.6c-0.2,0-0.5,0-0.7,0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.1,0.2-0.2,0.2-0.4
        c0,0,0.1-0.1,0.1-0.2c0-0.2,0-0.5,0.3-0.5l0.1,0c0-0.2,0-0.4,0-0.6C227.7,103,227.6,103.1,227.5,103.1 M211.5,103
        c0,0.4,0.1,0.8,0,1.2l0.2-0.1c0,0.1,0,0.4-0.1,0.5l0.2-0.1c0,0.2,0,0.4,0,0.6l0.2,0c-0.1,0.3,0.2,0.6,0.1,0.9
        c0.1-0.1,0.2-0.2,0.2-0.2l0,0l0,0.1c0.1,0.1,0.1,0.3,0.1,0.4c0.1,0.2,0.2,0.5,0.5,0.5c-0.5-1-0.7-2.1-1.1-3.1
        C211.7,103.4,211.8,103,211.5,103 M214.1,103.5c0.2-0.4,1-0.2,0.8,0.3l0.1-0.1c0,0.3,0.3,0.4,0.3,0.7c0.4,0.4,0.7,0.9,0.8,1.5l0.2,0
        c0.1-0.2,0.2-0.4,0.3-0.6c-0.1-0.2-0.3-0.5-0.4-0.7c-0.2-0.1-0.4,0-0.4-0.2c-0.1-0.1-0.2-0.5-0.5-0.3
        C215.5,103.4,214.4,102.8,214.1,103.5 M230.1,103.1c0,0.3-0.2,0.5-0.4,0.7c0,0.2,0,0.3-0.1,0.5c0.2-0.1,0.3-0.2,0.5-0.2l0,0.4
        c0.2-0.2,0.4-0.4,0.2-0.7c-0.1,0.2-0.3,0.3-0.5,0.4C229.7,103.6,230.8,103.3,230.1,103.1 M331,103.1c-0.2,0.1,0,0.4,0.1,0.3
        C331.4,103.4,331.2,103.1,331,103.1 M234.9,103.4c0.1,0.2,0.2,0.2,0.3,0C235.1,103.2,235,103.2,234.9,103.4 M312.4,103.3
        c0,0.1,0,0.3,0,0.4l-0.2-0.1c0.3,0.2,0.4,0.6,0.6,0.8c-0.1,0.1-0.2,0.2-0.3,0.4l0.3-0.1c0,0.1-0.1,0.4-0.2,0.5
        c-0.5,0.4,0-0.7-0.5-0.6c0.1-0.1,0.2-0.3,0.3-0.4c-0.2-0.1-0.4-0.5-0.5-0.3l0,0.1c0.1,0.2,0,0.4,0,0.5c0,0.1-0.1,0.2-0.2,0.3
        c-0.1,0-0.2,0-0.2,0c-0.1,0.3-0.2,0.7-0.3,1.1c-0.1-0.1-0.2-0.2-0.3-0.3c0,0.2,0,0.5,0,0.6l-0.2,0c0,0.1,0,0.2,0,0.3
        c0,0-0.1,0-0.1,0l0-0.4c-0.2,0.3-0.4,0.6-0.7,0.7c0,0.1,0,0.3,0,0.4c-0.1,0-0.3,0-0.4,0c0.1,0.3-0.1,0.5-0.3,0.6l0.2-0.1
        c0,0.1,0,0.2,0,0.3c0.2,0.1,0.3,0.2,0.3,0.3c0.1-0.1,0.3-0.1,0.4-0.2c0-0.5,0.7-0.3,0.5-0.8c0.2,0.1,0.3,0.1,0.4-0.1
        c0.4-0.5,0.6-1.1,1.1-1.5c0.1-0.2,0.5-0.4,0.2-0.7c0.3,0.1,0.6-0.2,0.5-0.5c-0.1-0.3,0.2-0.8-0.3-0.9c0.1-0.1,0.2-0.2,0.2-0.3
        c0-0.1-0.1-0.2-0.1-0.2l0,0.2C312.6,103.4,312.5,103.3,312.4,103.3 M233.2,103.5c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.2,0.1,0.4,0.3,0.6
        l0-0.3c0.3,0.1,0.5,0.1,0.7,0.3c-0.1,0-0.4,0-0.5,0c0.2,0.1,0.4,0.2,0.6,0.3c-0.2,0.1-0.4,0.2-0.3,0.4c0.3-0.1,0.5-0.4,0.7-0.6
        c-0.1,0.1-0.2,0.3-0.2,0.4c0.1,0,0.2,0.1,0.2,0.1l-0.2,0.2c0.1,0.1,0.3,0.3,0.4,0.4l-0.3,0c0.1,0.1,0.2,0.2,0.2,0.2
        c-0.2,0-0.3,0.1-0.4,0.2c0.1,0,0.3,0,0.4,0c0.1-0.1,0.2-0.3,0.3-0.4c0,0.1,0.1,0.3,0.1,0.4c0.1,0,0.3,0.1,0.4,0.2
        c-0.2,0-0.9-0.3-0.8,0.2c0.2,0,0.6-0.1,0.6,0.3c0.1,0,0.2,0.1,0.3,0.1c0,0.4-0.3,0.1-0.5,0c0.3,0.2,0.2,0.6,0.2,0.9
        c0.1-0.1,0.2-0.2,0.4-0.2c0,0.1,0.1,0.2,0.1,0.3c-0.1,0.1-0.2,0.2-0.3,0.2c0.1,0,0.3,0,0.5,0c0-0.1-0.1-0.4-0.1-0.6
        c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.2,0.2,0.4,0.4,0.6c0,0-0.1,0.1-0.1,0.1c0-0.1-0.1-0.2-0.1-0.2c-0.2,0-0.3,0.1-0.3,0.3
        c0.3,0,0.5,0.1,0.7,0.2c-0.2,0.4-0.4,0.1-0.6-0.1c0,0.2,0.1,0.4,0.2,0.5c0.1,0.2,0.2,0.3,0.3,0.4c-0.1,0.2-0.1,0.5-0.1,0.7l0,0
        c0-0.1,0.1-0.2,0.2-0.3c0-0.2,0-0.4,0.1-0.6c0.1,0.2,0.3,0.4,0.4,0.6c0,0.1-0.1,0.2-0.1,0.2c0.1,0,0.3,0,0.5,0
        c0-0.2,0.1-0.4,0.2-0.6c0.4-0.7,0.7-1.5,0.8-2.3c0.1,0,0.3,0.1,0.3,0.1c0,0-0.1-0.1-0.1-0.2l-0.1-0.1c-0.1-0.2,0.2-0.3,0.3-0.5
        c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.2-0.2,0.3l-0.1,0.1c0,0.1,0,0.2,0,0.3c-0.2,0.2-0.4,0.4-0.6,0.6c-0.2,0.3-0.4,0.7-0.7,0.8
        c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.6,0.6-1.1,0.6-1.7c0.1-0.2,0.2-0.4,0.3-0.6c-0.2-0.1-0.5-0.5-0.8-0.2c-0.2,0.2-0.3,0.4-0.5,0.6
        c0-0.3,0.2-0.6,0.2-0.9c-0.3-0.1-0.6-0.2-0.8-0.3c-0.5-0.2-0.7-0.7-1.1-0.9c0-0.1-0.1-0.2-0.1-0.4c-0.3,0-0.6-0.3-0.8-0.4
        c-0.1-0.2-0.1-0.5-0.3-0.6l0.1,0.3c-0.3-0.2-0.4,0.2-0.6,0.2c0-0.2,0-0.3-0.1-0.5C233.5,103.3,233.3,103.4,233.2,103.5 M259,103.4
        C259.1,103.5,259.1,103.5,259,103.4 M259.4,103.4C259.5,103.5,259.5,103.5,259.4,103.4 M245.1,103.7c-0.2,0.3-0.4,0.6-0.6,0.9
        c-0.2-0.1-0.3,0-0.3,0.2c-0.1,0-0.2-0.1-0.3-0.1c0,0.1,0,0.3,0,0.4c-0.1,0-0.2,0.1-0.3,0.2c0,0.2,0,0.3,0,0.5l0.2-0.1
        c0,0.1,0,0.3,0,0.4c0.2-0.6,0.8-0.9,1-1.5c0.2-0.4,0.3-0.8,0.7-1C245.4,103.6,245.2,103.6,245.1,103.7 M331.4,103.8l-0.1,0.2
        c0.1-0.1,0.3-0.2,0.4-0.2l0,0.2c0.3-0.2,0.5,0.1,0.4,0.4c0.3,0,0.2,0.5,0.4,0.7c0.2-0.1,0.4-0.1,0.6-0.1c-0.1-0.3,0.1-0.4,0.3-0.4
        l0-0.4c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1-0.2-0.3-0.5-0.5-0.4c0,0-0.2-0.1-0.2-0.2c-0.2-0.2-0.5,0-0.7-0.2c0-0.1,0-0.2,0-0.2
        c-0.1,0.3-0.5,0.3-0.7,0.1C330.9,103.9,331.2,103.9,331.4,103.8 M255.6,103.6c-0.1,0.2-0.1,0.4-0.1,0.6
        C255.8,104.2,255.9,103.8,255.6,103.6 M267.6,103.8c-0.1,0.3,0.1,0.8-0.3,0.8c-0.1,0.4-0.2,0.8-0.1,1.2c-0.1,0-0.2-0.1-0.3-0.1
        c0,0.1,0,0.3,0.1,0.5c-0.1,0-0.2,0.1-0.3,0.2c0,0.1,0.1,0.3,0.1,0.5c-0.1,0-0.2-0.1-0.3-0.1c0,0.1,0.1,0.3,0.1,0.5
        c-0.2,0-0.3,0.1-0.4,0.2c0.2,0,0.3,0,0.5-0.1c0.1-0.3,0.3-0.6,0.4-0.9c0.2-0.2,0.3-0.4,0.5-0.6c0,0.1,0.1,0.2,0.2,0.3
        c-0.2,0.2-0.3,0.4-0.4,0.6c0,0.1,0,0.2,0,0.3c0.2-0.4,0.3-0.9,0.6-1.2c-0.1,0-0.2-0.1-0.3-0.1c0.1,0,0.4-0.1,0.5-0.2
        c0,0,0-0.1,0-0.1l-0.2,0.1c0-0.1-0.1-0.2-0.1-0.2l-0.3,0.2c0.1-0.2,0-0.4-0.1-0.6c0.1-0.1,0.2-0.1,0.3-0.2c0-0.3,0.3-0.6,0.2-0.9
        C267.8,103.6,267.7,103.7,267.6,103.8 M272.7,103.8c0,0.2,0,0.4,0,0.6c0.4,0.1,0.2,0.5,0.4,0.7c0-0.1,0-0.2,0-0.3
        c0.3,0,0.3-0.4,0.5-0.5c-0.1-0.1-0.3-0.3-0.4-0.4C273,103.9,272.8,103.8,272.7,103.8 M276.1,103.9c0,0.2-0.2,0.3-0.3,0.4
        c0,0.1,0.1,0.3,0.2,0.4c-0.1,0-0.2-0.1-0.3-0.1c0,0.1,0,0.2,0.1,0.3c-0.3,0.1-0.3,0.3-0.4,0.5c-0.1,0-0.2-0.1-0.2-0.1
        c0,0.2-0.1,0.6-0.4,0.4c0,0.2,0,0.3-0.1,0.5c-0.4,0.1-0.5,0.6-0.9,0.8c0,0.1-0.1,0.4-0.2,0.5l0.3-0.1c0,0.1,0,0.2,0,0.2
        c0.1,0,0.3,0,0.4,0c-0.2,0.3-0.6,0.1-0.8,0.2c0.2,0.3,0.5,0.4,0.6,0.8c0.3-0.2,0.4-0.6,0.5-0.9c0.2-0.6,0.6-1.2,0.8-1.8
        c0.1-0.3,0.3-0.4,0.5-0.6c0.1-0.3,0.2-0.6,0.3-0.9c0-0.2,0.1-0.4,0.1-0.6C276.2,103.9,276.1,103.9,276.1,103.9 M171.3,104.5
        c0.2,0,0.2-0.3,0.2-0.5C171.3,103.8,171.2,104.4,171.3,104.5 M186.5,104C186.3,104.4,186.9,104,186.5,104 M296.5,104.3
        c0,0.1-0.1,0.2-0.1,0.3l-0.1,0c0.2,0.4-0.2,0.2-0.3,0.4c-0.1,0.2-0.2,0.4-0.4,0.4c0,0.2-0.3,0.3-0.4,0.5c0.1,0.1,0.2,0.3,0.3,0.4
        c-0.2,0.1-0.4,0-0.5-0.1c-0.1,0.3-0.2,0.5-0.2,0.8c0-0.1,0-0.4,0-0.5c-0.2,0.2-0.3,0.5-0.7,0.6c0,0.1,0.1,0.2,0.1,0.2
        c-0.3-0.1-0.3,0.2-0.4,0.4c-0.1,0.1-0.3,0.2-0.4,0.3c0-0.1,0-0.4,0-0.5c-0.1,0.2-0.2,0.5-0.2,0.7c0,0.1-0.1,0.2-0.1,0.3
        c0,0.5-0.4,0.2-0.3,0c-0.1,0-0.1,0.5-0.4,0.3c0.1,0,0.2,0.1,0.3,0.1c-0.2,0.2-0.5,0.4-0.5,0.7l-0.2,0l0-0.2l-0.1,0
        c0,0.2,0.1,0.3,0,0.5c-0.1,0-0.2,0-0.3,0c0,0.2,0,0.4,0,0.6c-0.1,0-0.2,0-0.3,0c0,0.1,0,0.2,0.1,0.3c-0.3,0-0.4,0.2-0.4,0.4
        c-0.1,0-0.2,0.1-0.2,0.2c-0.3,0.6-0.8,1.2-1.2,1.8c-0.3,0.4-0.7,0.8-0.5,1.3c-0.1,0-0.2,0-0.2,0l0,0.1c0.1,0,0.3-0.1,0.4-0.1
        c0,0.1,0,0.2-0.1,0.3c0.3,0,0.4,0.1,0.3,0.3c0,0.1,0.1,0.2,0.2,0.2l0.2,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3-0.3-0.7-0.7-1.2-0.8
        c-0.3,0.4-0.5,0.7-0.8,1.1c-0.1,0.2,0.1,0.4,0.3,0.5c0.1,0.2,0.1,0.5,0.3,0.5c0.1,0.1,0.2,0.3,0.3,0.5c-0.1,0.1-0.2,0.2-0.3,0.2
        c0,0.1,0.1,0.3,0.2,0.4c-0.1-0.3,0-0.5,0.2-0.6c0,0.1,0.1,0.4,0.1,0.5c0.1-0.3,0.5-0.5,0.5-0.8c0.1,0,0.2,0,0.2,0
        c0-0.3,0.1-0.7,0.5-0.6c0-0.3,0-0.8-0.5-0.7c0.1-0.1,0.3-0.2,0.4-0.3c0,0.2,0.1,0.4,0.3,0.5c0-0.1,0-0.3,0-0.3
        c0.1,0,0.2,0.1,0.3,0.1c0-0.1,0-0.4,0-0.5l0.2,0.1c0.1-0.2,0.1-0.3,0.2-0.4c0.2,0,0.3-0.1,0.3-0.3l0.1,0.1c-0.1-0.3,0.2-0.5,0.3-0.8
        l0.1,0c0-0.3,0.3-0.5,0.3-0.8c0,0,0.1-0.1,0.1-0.2l0.1,0c0-0.1,0-0.2,0-0.3c0.2-0.1,0.2-0.4,0.5-0.4c-0.1-0.3,0.2-0.5,0.3-0.7h0.1
        c-0.1-0.3,0.2-0.4,0.4-0.6c0.1-0.2,0-0.5,0.3-0.5c0-0.5,0.2-0.9,0.6-1.2c-0.1-0.2-0.2-0.4,0.1-0.4c0-0.1,0-0.2-0.1-0.2
        c0.2,0.1,0.4,0.1,0.3-0.2c0,0,0.1-0.1,0.2-0.1c-0.2-0.3,0-0.5,0.3-0.7c0-0.1,0-0.2,0.1-0.3c0.4-0.9,0.9-1.7,1.3-2.6l0.1,0.1l0-0.3
        l0.3-0.5C296.9,103.8,296.6,103.9,296.5,104.3 M329.1,104c0.1,0.2,0.5,0.4,0.2,0.6c0.1,0,0.3,0,0.4-0.1c-0.1,0.3,0.2,0.4,0.4,0.5
        l0,0.1c0.1,0.2,0.3,0.3,0.4,0.5l-0.1,0.2c0.2,0,0.4,0.2,0.6,0.3c-0.1,0.1-0.2,0.2-0.3,0.2c0.2,0,0.4,0,0.6,0c0,0.1,0,0.2,0,0.3
        c0.1,0,0.3,0,0.3,0l0-0.1c-0.1,0-0.2-0.1-0.2-0.1c0.1-0.1,0.2-0.3,0.2-0.5c0.1,0.2,0.2,0.3,0.2,0.6c0.1-0.1,0.2-0.2,0.3-0.3
        c-0.1-0.3,0.2-0.4,0.3-0.7c-0.2,0-0.4,0.1-0.5,0.3c0-0.1,0.1-0.2,0.1-0.4c-0.1-0.1-0.2-0.2-0.2-0.3c-0.2,0-0.5-0.1-0.7-0.2
        c-0.4-0.3-0.9-0.3-1.3-0.5C329.5,104.4,329.4,104.1,329.1,104 M306.7,104.1c0,0,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.1,0.2l0.1,0.3
        c-0.1,0-0.3-0.1-0.4-0.1c0.1,0.1,0.3,0.1,0.4,0.2c0,0.2,0,0.3-0.1,0.4c0,0,0.1,0,0.2,0c0-0.2-0.1-0.5-0.1-0.7l0.3,0
        c-0.2,0.4,0.5,0.2,0.4,0.4c-0.1,0-0.3,0-0.4,0c0.1,0.1,0.3,0.2,0.4,0.4l0.3-0.1c-0.1,0.2-0.2,0.5-0.3,0.7c0.1,0,0.2,0.1,0.2,0.1
        c0-0.1-0.1-0.2-0.1-0.3c0.1-0.2,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0c0,0.1,0,0.4,0,0.6c-0.2-0.1-0.3-0.2-0.5-0.3
        c0.3,0.3,0.4,0.6,0.7,0.9c0-0.2,0.1-0.4,0.2-0.6l0,0c-0.1-0.1-0.1-0.2-0.2-0.4c0.1,0,0.2,0.1,0.3,0.2c0.1-0.4,0.2-0.7,0.4-1.1
        c-0.9-0.1-1.5-0.9-2.4-1.1c0-0.1,0.1-0.2,0.1-0.3C306.9,104.1,306.8,104.1,306.7,104.1 M223.6,104.3c0,0.1,0.1,0.2,0.1,0.2
        c0.1,0.1,0.3,0.2,0.4,0.3c-0.2,0.5-0.5-0.1-0.8-0.1c0.2,0.3,0.5,0.5,0.7,0.8c0.2-0.3,0.4-0.6,0.5-0.9c-0.2,0-0.4-0.1-0.6-0.1
        C223.8,104.4,223.7,104.2,223.6,104.3 M262.4,104.2c0,0.2,0.1,0.5-0.1,0.7c-0.4,0.4-0.6,0.9-1,1.3c0.1,0,0.3,0,0.4,0
        c0.2,0.5,0.5,0.9,0.7,1.4c0.1,0.1,0.3,0.3,0.3,0.4c-0.1,0-0.4,0-0.6,0l0,0c0.1,0,0.3,0,0.4,0c-0.1,0.1-0.2,0.2-0.2,0.3
        c0.1-0.1,0.4-0.2,0.5-0.3c0,0.1-0.1,0.4-0.2,0.5l0.2-0.1l0,0.2l0.3-0.1c0,0.1-0.1,0.2-0.2,0.3c0.1,0,0.2,0,0.3,0c0,0.1,0,0.2,0,0.2
        c0.2-0.3,0.3-0.7,0.5-1c0.1-0.7,0.6-1.2,0.5-1.9c-0.1,0-0.4-0.1-0.5-0.1c0.1,0,0.3-0.1,0.4-0.1c-0.1,0-0.3-0.1-0.3-0.1l-0.1-0.3
        c0.1,0,0.2-0.1,0.3-0.1l-0.2,0l-0.1-0.1c-0.1,0-0.3-0.1-0.4-0.1c0.1-0.1,0.2-0.2,0.4-0.3l0-0.1c-0.1,0-0.2-0.1-0.3-0.1
        c0,0.1-0.1,0.2-0.2,0.3c-0.1-0.1-0.2-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.3-0.3C262.6,104.4,262.5,104.3,262.4,104.2 M229.8,104.5
        c-0.1,0.2-0.3,0.5-0.4,0.7c0.1,0,0.2,0,0.3,0c0,0.2,0,0.3,0,0.5c0,0-0.1,0-0.2,0c0-0.1,0.1-0.3,0.1-0.4c-0.5,0-0.6,0.6-0.5,1
        c-0.2,0.3-0.6,0.6-0.5,1c-0.1,0.1-0.2,0.1-0.4,0.2c0.3,0.1,0.1,0.5,0.2,0.7c0-0.1-0.1-0.3-0.2-0.3c-0.2,0.2-0.3,0.4-0.1,0.6
        c-0.2,0.5-0.7,0.9-0.9,1.5c-0.1,0.3-0.4,0.5-0.6,0.7c0,0.2,0,0.5,0,0.7c-0.2,0-0.4-0.1-0.5-0.1c0.1,0.1,0.3,0.2,0.4,0.3
        c-0.3,0.2-0.5,0.5-0.7,0.8c0.1,0,0.4,0,0.5,0c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c0,0.2,0,0.4,0,0.6
        c0.1-0.1,0.3-0.2,0.4-0.3c0,0.1,0.1,0.3,0.1,0.3c0-0.1,0.1-0.3,0.2-0.4c0.1,0,0.2,0,0.3,0c-0.1-0.2-0.5-0.5-0.1-0.5
        c-0.1-0.3,0-0.4,0.4-0.2c-0.4-0.3,0-0.4,0.1-0.6l-0.4,0c0.1,0,0.3-0.1,0.4-0.1c-0.1,0.4,0.2,0.5,0.5,0.5c-0.1-0.2-0.2-0.3-0.2-0.5
        l-0.2,0c0.1-0.4,0.3-0.8,0.6-1l0.1,0.2c0-0.1,0-0.3,0-0.4l0.2,0c-0.1-0.2-0.2-0.5-0.3-0.7c0.1,0.1,0.3,0.2,0.4,0.2
        c0.1-0.2,0.3-0.3,0.4-0.5c0.1-0.2-0.2-0.5,0.1-0.5c0-0.3,0.4-0.5,0.3-0.8c0-0.2,0.1-0.4,0.2-0.5c0,0.2,0,0.4,0,0.6
        c0.3-0.1,0.1-0.4,0.2-0.6c0.1-0.2,0.3-0.3,0.4-0.5l-0.2,0c0.1-0.2,0.2-0.4,0.3-0.6c0-0.1-0.1-0.2-0.2-0.3c0.1,0,0.3-0.1,0.3-0.1
        c0-0.2-0.4-0.5,0-0.5C230.1,105.3,229.7,105,229.8,104.5 M203.4,105.4c0,0.1-0.1,0.2-0.1,0.3c-0.1,0-0.2,0.1-0.2,0.1
        c-0.6,0.9-1.5,1.7-2.2,2.6c-1,0.9-1.8,2.1-3,2.8c0,0.2-0.2,0.3-0.4,0.4c-0.4,0.2-0.7,0.6-1,0.9c-0.4,0.3-0.9,0.6-1.2,1
        c0.1,0.4-0.4,0.4-0.6,0.5c0.5-0.6,1.2-1.1,1.6-1.7c0.2-0.1,0.3-0.3,0.5-0.4c0.2-0.4,0.5-0.7,0.8-1.1c0.1-0.3,0.5-0.5,0.5-0.8
        c0.1,0,0.3,0,0.5,0c-0.1,0-0.2-0.1-0.2-0.1c0-0.1-0.2-0.5,0.1-0.4c0,0,0.1-0.1,0.2-0.2c0-0.1-0.1-0.3-0.1-0.4c0.1,0,0.3,0.1,0.4,0.1
        c0.1-0.2,0.1-0.5,0.3-0.6c0-0.1-0.1-0.2-0.1-0.2c0.2-0.3,0.4-0.6,0.7-0.9c0.1,0,0.2,0.1,0.2,0.2c-0.3,0.3-0.5,0.5-0.6,0.9
        c0.3-0.5,0.8-0.9,1-1.5c0.1-0.1,0.2-0.2,0.3-0.3c-0.2,0.1-0.6,0.3-0.6-0.1c0,0.1-0.1,0.2-0.1,0.3c0.4,0.2,0,0.6-0.3,0.4
        c0-0.1,0.1-0.3,0.2-0.4c-0.2,0.1-0.5,0.2-0.7,0.3c0,0.1,0,0.3,0,0.5c-0.4-0.1-0.6,0.3-0.9,0.3c0,0.3-0.2,0.5-0.4,0.7
        c0-0.1,0.1-0.4,0.2-0.5c-0.3,0.1-0.5,0.4-0.7,0.7c0-0.1,0-0.3,0-0.4c-0.1,0.1-0.1,0.2-0.2,0.4c-0.3-0.2-0.6,0.1-0.5,0.4
        c-0.2,0.3-0.6,0.5-1,0.7c-0.3,0.1,0,0.6-0.4,0.5c-0.1,0-0.2,0-0.3,0c0-0.4-0.3-0.2-0.4,0c0.6-0.2-0.1,0.4,0.2,0.5
        c-0.1,0.1-0.2,0.1-0.2,0.2l-0.1-0.3c-0.5,0.4-0.9,1.1-1.6,1.3c0.1-0.1,0.2-0.3,0.2-0.5c-0.4,0.1-0.3,0.5-0.3,0.8
        c-0.2,0-0.5,0-0.7,0.1c0,0.1,0,0.2,0,0.3c-0.3,0.2-0.7,0.4-0.9,0.8c-0.2,0.1-0.3,0.2-0.4,0.2c-0.1,0.1-0.1,0.3-0.2,0.4
        c-0.2,0-0.4,0-0.5-0.1c0.1,0.1,0.2,0.2,0.3,0.3c-0.4,0.1-0.5,0.5-0.8,0.8c0-0.1,0-0.2,0-0.2l-0.3,0c0,0.1,0,0.2,0,0.3l-0.2-0.1
        c0,0.1,0.1,0.3,0.1,0.4c-0.5,0-0.8,0.5-1.2,0.6c0.1,0,0.2,0.1,0.3,0.1c-0.2,0.4-0.8,0.4-0.8,0.9c-0.1,0-0.3,0-0.4,0
        c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.3-0.1,0.4c-0.4,0-0.6,0.3-0.6,0.7c-0.1,0-0.2,0-0.3,0c0,0.3-0.4,0.6-0.7,0.7c0,0.1,0,0.2,0,0.3
        c-0.2,0-0.4,0-0.6,0c0,0.2-0.1,0.5-0.1,0.7c-0.1,0-0.3,0-0.4,0c0,0.1,0.2,0.3,0,0.4c-0.3,0.3-0.6,0.6-0.8,1
        c-0.3,0.1-0.5,0.4-0.6,0.7c-0.3,0.2-0.5,0.4-0.4,0.7c-0.1,0.1-0.3,0.2-0.4,0.3c0,0.4-0.3,0.8-0.7,0.7c0,0.2,0.1,0.5-0.3,0.4
        c0,0.1,0,0.2,0,0.2c-0.3,0.3-0.5,0.9-0.9,1.1c-0.2,0.3-0.4,0.6-0.5,0.9c-0.1,0-0.3,0.1-0.4,0.1c0,0.1,0,0.3,0,0.4
        c-0.2,0.2-0.4,0.4-0.5,0.7c-0.4,0.2-0.6,0.6-0.7,0.9c-0.2,0.1-0.4,0.2-0.4,0.4c-0.1,0.5-0.5,1-0.6,1.5c-0.2,0.3-0.5,0.6-0.5,1
        c-0.2,0.2-0.3,0.4-0.5,0.4c-0.1,0.2,0,0.5-0.2,0.7c-0.4,0.4-0.3,1-0.7,1.4c0,0.2-0.1,0.5-0.3,0.7c-0.5,0.8-0.7,1.7-1.3,2.4
        c-0.6,0.8-0.5,1.9-1,2.7c-0.1,0.3-0.2,0.7-0.4,0.9c0.1,0.6-0.1,1.2-0.4,1.7c-0.2,0.7-0.5,1.4-0.7,2.1c0,0.5,0.1,1-0.1,1.4
        c0.1,0.5-0.3,1-0.2,1.6c0,0.1,0,0.3-0.1,0.4c0.1,0.2,0,0.5-0.1,0.7c-0.2,0.4-0.2,0.8-0.2,1.2c-0.3,0.3-0.3,0.7-0.4,1
        c0.2,0.6-0.6,1.2-0.2,1.8c-0.1,0.2-0.2,0.3-0.3,0.5c0.1,0.1,0.2,0.2,0.4,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c0.1,0.1,0.2,0.2,0.3,0.3
        c-0.3,0.1-0.4,0.4-0.5,0.6c0.2-0.1,0.3-0.2,0.5-0.2c0.3,0.5-0.2,0.8-0.2,1.2c0.1-0.1,0.3-0.1,0.4-0.2c0,0.1,0,0.3-0.1,0.4
        c-0.1,0.4,0,0.8-0.1,1.2c0,0,0.1,0.1,0.2,0.2l-0.2,0c0.2,0.3,0.1,0.7,0,1.1c0.1,0,0.2,0.1,0.2,0.1c0,0.2-0.1,0.5,0,0.7
        c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0,0.2,0,0.3,0c0,0.5-0.1,1,0.2,1.5c0,0.4-0.4,0.6-0.4,0.9c0.1-0.1,0.3-0.2,0.4-0.4
        c0.2,0.4,0.1,0.8-0.1,1.2c0.1,0.1,0.2,0.2,0.3,0.2c0,0.3,0.1,0.5,0.1,0.8c-0.1,0-0.3,0-0.4,0c0.1,0.1,0.3,0.2,0.5,0.3
        c0.1,0.3,0.1,0.6-0.1,0.8c0.2,0.4,0.3,0.9,0.3,1.4c0.1,0.3,0.1,0.7,0.1,1.1c0,0.3,0.3,0.6,0.3,1c0,0.5,0.3,1,0.3,1.6
        c0,0.3,0.4,0.5,0.1,0.8c0.3,0.7,0.1,1.5,0.6,2.2c0.1,0.2,0.1,0.4,0.1,0.6c0.1,0.2,0.3,0.3,0.3,0.6c0.1,0.8,0.6,1.5,0.6,2.3
        c0,0,0.1,0.1,0.2,0.1c0,0.2,0.1,0.3,0.2,0.5c-0.2,0.5,0.3,0.8,0.4,1.2c-0.3,0.3,0.2,0.4,0.1,0.7c0,0.1,0,0.3,0,0.4
        c0.3,0.1,0.5,0.4,0.4,0.7c0.2,0.2,0.3,0.5,0.4,0.7c0.5,1,1,1.9,1.3,3c0-0.4,0-0.8,0.2-1.1c-0.1-0.1-0.2-0.2-0.2-0.2
        c0.3-0.4,0.4-0.9,0.3-1.5c0.2-0.3,0.3-0.8,0.5-1.1c0-0.4,0-0.7,0.4-0.9c0-0.2,0-0.4-0.1-0.6c0.2,0,0.6-0.3,0.1-0.4l0-0.2
        c0.1,0,0.2,0,0.2,0c0.1-0.6,0.2-1.2,0.3-1.7c0.1-0.3,0.5-0.7,0.1-1c0.1-0.3,0-0.7,0.2-1c0.2-0.2,0-0.5,0-0.7c0-0.3,0.2-0.7,0.2-1
        c0-0.5,0.3-0.9,0.1-1.4c0-0.7,0.2-1.3,0.2-2c0-0.2,0.1-0.5,0.2-0.7c-0.2-0.3-0.2-0.7-0.2-1c-0.1-0.8,0.5-1.5,0.1-2.3
        c0.1-0.9,0-1.8,0.1-2.7c-0.1-0.4,0-0.9,0.1-1.3c-0.1-0.2-0.2-0.3-0.2-0.5c-0.1-0.5,0.4-0.9,0-1.3c-0.3-0.4-0.1-0.8,0.1-1.2
        c-0.1-0.5-0.1-1.1-0.2-1.6c0-0.3-0.3-0.4-0.3-0.7c0.1-0.2,0.2-0.4,0.2-0.6c-0.2-0.3-0.2-0.8-0.1-1.2c-0.4-0.5-0.3-1.2-0.5-1.8
        c-0.2-0.4,0-0.9-0.2-1.4c0-0.3,0-0.6-0.1-0.8c-0.2-0.2-0.1-0.5-0.3-0.7c-0.2-0.3-0.4-0.6-0.5-0.9c-0.1-0.2-0.3-0.4-0.4-0.5
        c0-0.1,0-0.2,0-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.1-0.2,0-0.4-0.1-0.5c-0.3-0.4-0.3-0.9-0.5-1.3c0.1-0.5,0-1,0-1.4
        c0.2-0.3,0.2-0.6,0.2-1c-0.2-0.3,0-0.7,0-1.1c0-0.1,0.1-0.2,0.2-0.2c-0.3-0.4-0.1-1,0-1.5c-0.3,0.1-0.5,0.4-0.5,0.7
        c0,0-0.1-0.1-0.1-0.2c0.1-0.1,0.2-0.1,0.2-0.2c0-0.4,0.5-0.2,0.6-0.6c0-0.5,0.3-1,0.2-1.5c0.2-0.3,0.3-0.5,0.4-0.8
        c0.2-0.3,0.5-0.7,0.3-1.1c0.2-0.2,0.3-0.4,0.5-0.5c-0.1-0.1-0.2-0.2-0.2-0.3c0.1,0,0.2,0,0.3,0c0.3-0.4,0.7-0.9,0.9-1.4
        c-0.1,0-0.3-0.1-0.4-0.2c0.1,0,0.2,0,0.3,0c0.1-0.2,0.3-0.3,0.5-0.5c0-0.1-0.1-0.2-0.1-0.2c0.4-0.5,0.9-1.1,1.1-1.7
        c0.3,0,0.2-0.4,0.3-0.6c0.4,0.2,0.5-0.2,0.4-0.5c0.2-0.1,0.3-0.3,0.3-0.5c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0,0.2,0,0.2,0
        c0.2-0.6,0.5-1.2,1-1.5c0.4-0.5,0.7-1,1.1-1.5c0.3-0.3,0.7-0.6,1-1c0.3-0.3,0.8-0.5,1.1-0.9c0.4-0.4,0.8-0.9,1.4-1
        c0.1-0.2,0.2-0.5,0.4-0.7c0-0.1,0-0.2,0-0.2c0.3,0,0.6-0.1,0.7-0.3c0.2-0.3,0.6-0.5,0.8-0.8c0.1,0,0.3-0.1,0.4-0.1
        c-0.1-0.1-0.2-0.3-0.2-0.4c0.1,0.1,0.3,0.2,0.4,0.3c0.1-0.1,0.2-0.4,0.3-0.5c-0.2-0.1-0.3-0.2-0.5-0.2c0.2,0,0.3-0.1,0.5-0.1
        c0.1,0.1,0.1,0.2,0.2,0.3c0.2-0.1,0.3-0.2,0.5-0.3c0-0.1-0.1-0.2-0.1-0.3l0.2,0c0-0.1,0-0.3,0-0.5c0.2,0.2,0.5,0.2,0.7,0
        c0.2-0.4,0.5-0.6,0.7-0.9c0.2-0.3,0.5,0.2,0.7,0c0-0.2-0.1-0.4-0.1-0.6c0.2,0.1,0.3,0.2,0.5,0.3c-0.3-0.4,0.2-0.7,0.6-0.7
        c0.1-0.3,0.3-0.5,0.6-0.6c0.4,0,0.1-0.2,0-0.4c0.1,0,0.2,0.1,0.3,0.1c0.2-0.1,0.4-0.1,0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.6
        c0,0.1,0.1,0.2,0.2,0.3c0.2-0.2,0.5-0.3,0.8-0.2c0-0.1,0-0.2,0.1-0.2c0.3-0.2,0.5-0.4,0.8-0.7l0.1,0.2c0.3-0.2,0.6-0.3,0.8-0.7
        c0.1,0.1,0.2,0.2,0.3,0.3c0-0.1,0-0.3-0.1-0.3c0.3,0.1,0.4-0.3,0.7-0.3l0-0.1c0.3,0,0.5-0.1,0.8-0.2c0-0.1,0.1-0.3,0.1-0.4
        c0.5-0.6,0.9-1.3,1.3-2c0.2-0.5,0.6-0.9,0.8-1.4c0.3-0.2,0.2-0.4-0.1-0.5l0,0.2l-0.1,0c0,0.3-0.1,0.5-0.3,0.7
        c-0.3,0.3-0.9,0.6-0.9,1.1c-0.1,0-0.2,0-0.2,0c0,0.2,0,0.3-0.2,0.4c-0.4,0.4-0.8,0.7-1.2,1c0,0.1-0.1,0.2-0.1,0.3
        c-0.1,0-0.2,0.1-0.4,0.1c0,0.3-0.3,0.4-0.5,0.6c-0.2,0.4-0.6,0.5-0.9,0.8c0.4-0.5,0.9-0.8,1.3-1.4c0.1-0.1,0.2-0.2,0.3-0.3
        c0.5-0.7,0.8-1.4,1.3-2c0.1-0.2,0.2-0.5,0.4-0.6c0.2-0.3,0.3-0.6,0.4-0.9c0,0,0.1-0.1,0.2-0.1c0-0.2,0.1-0.3,0.2-0.5
        c0.4-0.5,0.7-1.1,1-1.7c0.3-0.6,0.4-1.2,0.8-1.8c0.2-0.3,0.3-0.8,0.7-0.9c-0.1-0.4,0.4-0.7,0.3-1.1
        C203.9,104.6,203.8,105.2,203.4,105.4 M267.8,104.7c-0.1,0.2,0,0.5,0.2,0.4C268.4,105,268.1,104.6,267.8,104.7 M238.6,104.7
        c0,0.4-0.4,0.7-0.6,1c0,0.1-0.1,0.3-0.1,0.4c0.2,0,0.4,0.3,0.5,0.2c0.1-0.4,0.2-0.9,0.4-1.3C238.8,104.9,238.7,104.8,238.6,104.7
        M271.9,104.8c-0.1,0.1-0.2,0.3-0.3,0.4c0.2,0.1,0.3,0.2,0.5,0.2c-0.1,0.1-0.2,0.2-0.2,0.2c0.2,0.1,0.6,0.2,0.5,0.5
        c-0.1,0-0.3,0-0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.2-0.2,0.4-0.4,0.5-0.7C272.6,105.2,272.2,105,271.9,104.8 M279.3,105
        C279,105.4,279.7,105,279.3,105 M208.4,106.2c-0.1,0-0.3-0.1-0.4-0.1C208.1,106.2,208.3,106.3,208.4,106.2c0,0.3-0.2,0.5-0.4,0.5
        l0,0.2l-0.2,0c0,0,0,0.1,0,0.1l0.3-0.1c-0.3,0.1-0.1,0.7-0.4,0.7l0.1,0.1c-0.1,0-0.3,0.1-0.4,0.1c0.1,0,0.3,0,0.4,0
        c-0.1,0.2-0.2,0.4-0.3,0.7c-0.3,0.2-0.3,0.5-0.3,0.8c-0.1,0-0.2-0.1-0.2-0.2c-0.1,0.1-0.1,0.2-0.2,0.3l0.3,0c-0.2,0.3-0.4,0.7-0.5,1
        l-0.1,0c0,0.3-0.1,0.5-0.3,0.7l0,0.1c-0.1,0-0.2,0-0.3,0c-0.1,0.5-0.3,0.9-0.7,1.2c-0.1,0.1-0.4,0.3-0.1,0.4c0.3-0.2,0.6-0.5,1-0.5
        c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.4-0.8,0.9-1.6,1.2-2.4c0.3-0.6,0.5-1.3,0.8-2c0.2-0.5,0.3-1,0.5-1.5
        c-0.1,0-0.3,0-0.3,0c0-0.1,0-0.2,0-0.2c-0.1,0-0.2,0-0.2,0c0.1-0.2,0.1-0.3,0.2-0.5c0-0.2,0-0.4,0.1-0.6
        C208.5,105.3,208.5,105.8,208.4,106.2 M234.5,105.1L234.5,105.1l0,0.2l0.1,0C234.6,105.3,234.6,105.2,234.5,105.1L234.5,105.1
        M302.2,105.1c0,0.4,0.1,0.8,0.2,1.3c0.1,0.2-0.1,0.4-0.3,0.5c0.2-0.2,0.4-0.2,0.6,0c-0.1,0.1-0.3,0.2-0.4,0.3
        c0.2,0.1,0.4,0.1,0.5,0.3c0.1,0.1,0.3,0.1,0.5,0.2c-0.1-0.1-0.2-0.3-0.4-0.3c0-0.2,0.1-0.4,0.1-0.6l-0.2,0
        C302.7,106.3,302.5,105.7,302.2,105.1C302.3,105.2,302.3,105.1,302.2,105.1 M217.1,105.4c-0.1,0.2,0,0.5-0.3,0.5
        c-0.1,0.2-0.2,0.5-0.1,0.7l-0.2-0.1c0.1,0,0.2,0.1,0.2,0.2c-0.2,0.1-0.3,0.1-0.4,0.2c0.2,0.1,0.4,0.2,0.5,0.4c0-0.2,0-0.4,0-0.5
        c0.3-0.3,0.5-0.6,0.7-0.9C217.4,105.7,217.3,105.6,217.1,105.4 M278.7,106.1c-0.3,0-0.5,0.3-0.7,0.4c0.1,0,0.3-0.1,0.4-0.2
        c0.1,0,0.4,0.1,0.5,0.1c0,0.1-0.1,0.3-0.2,0.4c0-0.1,0-0.3,0-0.3c-0.2,0.1-0.4,0.2-0.5,0.4c0,0.1,0,0.3,0,0.4
        c0.4-0.1,0.8-0.3,1.1-0.5c0-0.1,0.1-0.2,0.1-0.3c0.1,0,0.2,0,0.3,0.2c0.1,0,0.2,0,0.2,0c-0.3-0.1-0.3-0.5-0.2-0.7
        c-0.3,0.1-0.3,0.5-0.4,0.7l-0.1-0.5l-0.2,0c0.1-0.2,0.1-0.5,0.2-0.7C279,105.5,278.9,105.8,278.7,106.1 M256.5,105.5
        c0,0.1,0,0.3,0,0.3l0.2-0.1c0.1,0.2,0.1,0.3,0.2,0.5c-0.2,0-0.3,0-0.4,0c0.3,0,0.3,0.2,0.3,0.5c0.1-0.1,0.2-0.1,0.3-0.2
        c0,0.1-0.2,0.5,0.1,0.5c0,0.1,0,0.3-0.1,0.4c0.1,0,0.3,0.1,0.4,0.2c0.1-0.1,0.2-0.1,0.2-0.2c-0.2-0.5-0.8-0.8-0.7-1.4
        c-0.1-0.1-0.3-0.1-0.3-0.3l-0.1,0C256.5,105.7,256.5,105.6,256.5,105.5 M318.8,105.9c-0.4,0.5-1.1,0.7-1.2,1.4
        c0-0.1-0.1-0.3-0.1-0.4c-0.2,0.1-0.1,0.3,0,0.5l-0.3,0c0,0.1,0.1,0.2,0.1,0.3c-0.3-0.1-0.4,0.3-0.5,0.5c-0.1,0.1-0.3,0.1-0.4,0.2
        c0,0.1,0.1,0.2,0.1,0.4c-0.1,0-0.3,0-0.4,0c-0.1,0.2-0.2,0.4-0.3,0.6l-0.2,0c0,0.1,0.1,0.2,0.1,0.3c-0.3,0.2-0.8,0.3-0.6,0.7
        c-0.3,0.2-0.5,0.6-0.8,0.9c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0-0.2,0-0.2,0c0,0.1,0,0.2,0.1,0.3c-0.1,0.1-0.3,0.3-0.4,0.4
        c-0.1,0.4-0.4,0.7-0.7,1.1l0.2,0.1c-0.2,0.3-0.3,0.6-0.5,0.8c0.1,0.4-0.1,0.7-0.4,0.9c0,0.1,0.1,0.3,0.2,0.4
        c-0.2,0.4-0.6,0.7-0.5,1.1c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.6-0.1,1.2-0.4,1.8c-0.1,0.4-0.1,0.8-0.3,1.1c0,0.2,0.2,0.3,0.1,0.5
        c-0.4,0.5-0.2,1.2-0.4,1.8c-0.1,0.4-0.3,0.8-0.2,1.2c0,0.2,0,0.5,0,0.7c-0.2,0.2-0.1,0.5-0.1,0.7c-0.1,0.1-0.2,0.2-0.3,0.3
        c0.1,0.2,0.2,0.3,0.4,0.5c0-0.1,0.1-0.4,0.1-0.6c0.2,0.3-0.1,0.6,0,0.9c0.1,0.5,0.1,0.9,0.1,1.4l-0.3,0.1c0.1-0.5,0-1.1,0.1-1.6
        c-0.4,0.1-0.3,0.5-0.1,0.7c-0.3,0.4,0.1,0.8,0,1.2c0.1,0,0.2,0.1,0.3,0.1c0,0.2,0,0.4,0.1,0.6c-0.1,0.3-0.1,0.7,0.1,1l0.1,0.1
        c0,0-0.1,0.1-0.2,0.2c0,0.3,0,0.5,0,0.8c0,0,0.1,0,0.1,0.1c-0.1,0.3-0.1,0.6-0.1,0.9c0,0,0.1,0.1,0.2,0.1l-0.2,0c0,0.1,0,0.4,0,0.6
        l0.1,0c0,0.5,0,1,0,1.5c0.1,0.2,0,0.4-0.1,0.6l0.2,0c0,0.3-0.2,0.8,0.2,1l-0.2,0c0,0.1,0,0.4,0.1,0.5c0,0.1,0.1,0.4,0.1,0.5
        c0.1,0.3,0,0.6,0,1c0,0.3,0.1,0.6-0.1,0.8c0.2,0.4,0.1,0.9,0.2,1.4c0.1,0.3-0.1,0.7,0.1,1c0.1,0.5,0.1,1,0.2,1.4c0,0.2,0,0.4,0,0.6
        c0.1,0.3,0.1,0.6-0.1,0.8c0.1,0.1,0.2,0.3,0.3,0.4c-0.1,0.4-0.2,0.9-0.1,1.3c-0.1,0.6-0.1,1.3,0.2,1.9c-0.2,0.3-0.4,0.6-0.3,1
        c0.2,0.6-0.3,1.4,0.1,2c-0.1,0.2-0.3,0.3-0.1,0.6c-0.1,0.2,0,0.6-0.3,0.7c0.1,0.2,0.2,0.4,0.3,0.6c-0.1,0.1-0.2,0.3-0.3,0.4
        c0.1,0.2,0.2,0.4,0.3,0.7c-0.3,0.2-0.4,0.6-0.1,0.8c-0.3,0.8,0,1.6,0,2.4c-0.1,0.3-0.2,0.7,0,1c0,0.7-0.2,1.3-0.1,2
        c-0.1,0.3,0,0.6,0,0.9c-0.3,0.8,0,1.7-0.1,2.5c0.1,0.6-0.2,1.2,0.1,1.8c0,0.1-0.1,0.3-0.2,0.4c0.2,0.5,0,1,0.1,1.5
        c0,0.8,0.1,1.6,0.2,2.5c0,0.5,0,0.9,0.1,1.4c0.1,0.2,0,0.4-0.1,0.6c0.1,0.3,0.3,0.6,0,0.9c0.3,0.3,0.2,0.6,0,0.9
        c0.1,0.2,0.3,0.3,0.3,0.6c-0.1,0.2-0.1,0.4-0.2,0.6c0.2,0.2,0.4,0.5,0.1,0.8c0.2,0.5,0.4,1,0.3,1.5c0.2,0.4,0.4,0.9,0.4,1.4
        c0,0.4,0.4,0.6,0.4,1c0,0.3,0.2,0.5,0.4,0.7c0.1-0.1,0.2-0.2,0.2-0.2c0.1,0.1,0.2,0.3,0.3,0.4c-0.3-0.4,0-0.9,0.5-1
        c0-0.1,0.1-0.3,0.2-0.4c0,0,0.1,0,0.2,0c0-0.5,0.6-0.6,0.8-1c0.4-0.1,0.4-0.7,0.8-0.7c0.1-0.2,0.1-0.4,0.2-0.6c0.1,0,0.2,0,0.3,0.1
        c0-0.1,0-0.3,0-0.3c0.1-0.1,0.4-0.2,0.4-0.4c0.1-0.3,0.5-0.4,0.2-0.6c0.2-0.1,0.3-0.2,0.5-0.3c0-0.1,0-0.2-0.1-0.3l0.1,0
        c0-0.1,0.1-0.3,0.1-0.4l0.2,0.3c0-0.2-0.1-0.4,0.1-0.6c0.2-0.3,0.3-0.7,0.5-1c0.1-0.5,0.4-1,0.5-1.5c0.1,0,0.2,0,0.3,0
        c0-0.1-0.1-0.2-0.1-0.2c0.1-0.7,0.4-1.4,0.4-2.1l0.2,0.1c0-0.2,0-0.5-0.1-0.7c-0.1-0.1,0-0.2,0.1-0.3c-0.1-0.9,0-1.8-0.1-2.7
        c0-0.2,0-0.5,0-0.7c0,0.3,0,0.7,0.2,1c0,0.6,0.2,1.1,0.2,1.7c0,0,0.1,0.1,0.2,0.1l-0.2,0c0.2,0.4,0.1,0.9,0.2,1.4
        c0,0.4,0,0.8,0.1,1.2c0,0.4,0,0.8,0.1,1.1c0,0.1,0,0.2-0.1,0.2c0.1,0.9,0,1.7-0.1,2.6c0,0.3,0,0.7-0.2,1c0,0.8-0.1,1.5-0.3,2.3
        c-0.2,0.3-0.3,0.6-0.3,1c-0.2,0.3-0.5,0.5-0.5,0.9c-0.1,0.1-0.2,0.3-0.3,0.4c0.1,0.1,0.2,0.2,0.3,0.2c-0.2,0.1-0.3,0.2-0.4,0.2
        c0,0.1,0.1,0.2,0.1,0.3c-0.4,0.6-0.7,1.3-1.2,1.8c-0.2,0.3-0.6,0.6-0.7,1c-0.1-0.1-0.3-0.2-0.4-0.2c0,0.2,0,0.4,0,0.6l-0.2,0
        c0,0.1,0.1,0.2,0.2,0.2c-0.1,0-0.3,0-0.4,0c0,0.1,0.1,0.3,0.2,0.4c-0.6,0.1-0.6,0.9-1.1,1.2c-0.1,0.4-0.2,0.9-0.6,1.1
        c-0.1,0.2-0.1,0.4-0.2,0.6c-0.2,0-0.3,0.1-0.4,0.3c0,0-0.1-0.1-0.2-0.1c-0.2,0.3-0.4,0.6-0.6,0.9c-0.1,0.1-0.2,0.2-0.2,0.3
        c-0.1,0-0.3,0-0.4,0.1c-0.1,0.2-0.2,0.5-0.3,0.7c-0.1,0-0.3-0.1-0.4-0.1c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1-0.1,0.2-0.1,0.3
        c-0.3,0.1-0.7,0.2-0.7,0.6l0.3,0c0,0.1-0.1,0.2-0.1,0.3c-0.1,0-0.2-0.1-0.3-0.2c-0.2,0.3-0.3,0.5-0.6,0.7c0,0.2-0.1,0.7-0.4,0.4
        c0-0.1,0-0.3,0-0.4c-0.1,0-0.1,0.1-0.2,0.1c0.2,0.2,0.1,0.5,0.2,0.7c-0.4-0.3-0.5,0.2-0.5,0.5c-0.2,0.1-0.4,0.3-0.5,0.5
        c-0.1,0-0.3-0.1-0.4-0.1c0.1,0.1,0.3,0.3,0.4,0.4c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0.1-0.3,0.2-0.4,0.3c-0.2,0.1-0.3,0.2-0.3,0.4
        c0.2,0,0.4-0.2,0.6-0.4c0,0.2-0.2,0.3-0.4,0.4c-0.3,0.2-0.5,0.4-0.7,0.6c0,0.1,0.1,0.3,0.1,0.4l-0.1,0c0-0.1,0-0.2,0-0.3l-0.2,0
        c0.1,0.3-0.1,0.9-0.6,0.8c0.1,0.3,0,0.5-0.2,0.7c-0.2,0-0.3,0.3-0.4,0.4c-0.1,0-0.2-0.1-0.2-0.1c0.1,0.3-0.1,0.5-0.2,0.8
        c-0.1,0-0.3,0.1-0.4,0.1c0,0.1,0,0.2,0,0.3c-0.2,0.3-0.4,0.6-0.8,0.4c0.1,0.1,0.2,0.3,0.3,0.3c-0.2,0.2-0.4,0.2-0.6,0.1
        c0,0.2-0.1,0.3-0.2,0.4c0.1,0.1,0.2,0.2,0.2,0.3c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0.2-0.1,0.4-0.3,0.5l0,0.1c-0.3,0.1-0.3,0.4-0.4,0.6
        c-0.1,0-0.2,0-0.3,0.1c0.4,0.1,0,0.5-0.1,0.7c-0.2,0.3-0.3,0.9-0.8,0.9c0,0.1,0.1,0.2,0.2,0.3c-0.1,0-0.2,0.1-0.3,0.2
        c-0.1,0.4-0.4,0.7-0.3,1.2c-0.5,0.2-0.2,0.9-0.5,1.3c0,0.4-0.1,0.8-0.3,1.1c0,0.6-0.1,1.2-0.2,1.8c-0.1,0.4,0,0.8-0.2,1.2
        c0.1,0.4,0.1,0.8,0.1,1.3c0.2-0.1,0.4-0.3,0.6-0.4c0.3-0.3,0.5-0.6,0.7-0.9l0.1,0c0-0.4,0.5-0.4,0.6-0.7c0.3,0,0.4-0.2,0.7-0.3
        c0-0.1,0-0.3,0-0.4c0.5,0,0.8-0.5,0.7-0.9c0.1,0.1,0.2,0.1,0.3,0.2c0-0.1,0-0.3,0.1-0.4l0.2,0.1c0-0.3,0.2-0.4,0.4-0.3
        c0-0.1,0-0.2-0.1-0.3c0.1,0,0.2,0,0.3,0c0-0.1,0-0.3,0-0.4c0.3,0,0.3-0.1,0.3-0.4c0.1,0,0.2,0,0.2,0.1c0.1-0.2,0.2-0.3,0.2-0.4
        c0.1,0,0.2,0,0.2,0c0-0.2,0.1-0.4,0.1-0.6c0.1,0,0.3,0.1,0.3,0.1c0-0.1,0-0.3,0-0.4c0,0,0.1,0,0.1,0c0.1-0.2,0.2-0.3,0.3-0.5
        c0.1,0,0.2,0,0.3,0c0-0.3,0.1-0.5,0.4-0.4c0-0.1,0.1-0.2,0.1-0.3c0.1,0,0.2-0.1,0.3-0.2c0-0.3,0.1-0.5,0.2-0.7c0.1,0,0.3,0,0.3,0
        c-0.1,0-0.2,0-0.2,0l0-0.2c0.1,0,0.3,0,0.3,0l-0.1-0.2c0.3,0.1,0.5-0.1,0.7-0.2c-0.1,0-0.4,0-0.5,0l0.2-0.3c0.1,0,0.2,0,0.3,0
        c-0.2-0.3,0.1-0.9,0.5-0.8c0-0.1,0-0.3-0.1-0.4l0.3,0c0,0.1,0,0.3,0.1,0.3c0-0.2,0-0.4-0.1-0.6c0.2-0.2,0.4-0.5,0.6-0.8
        c0-0.1,0.1-0.2,0.1-0.3c0.1,0.1,0.3,0.1,0.4,0.2c-0.1-0.2-0.1-0.4-0.2-0.6c0.1,0,0.4,0.1,0.5,0.1c0-0.1,0.1-0.2,0.1-0.2
        c-0.1,0-0.2,0-0.3-0.1c0.1-0.3,0.3-0.6,0.6-0.6c0-0.1-0.1-0.2-0.1-0.3c0.3-0.4,0.5-0.7,0.7-1.1c0.1,0,0.2,0.1,0.2,0.1
        c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.3-0.1,0.4-0.1c-0.1-0.1-0.2-0.1-0.4-0.2c0.2-0.1,0.3-0.3,0.5-0.4c0.1-0.2,0.5-0.3,0.2-0.5l0.2,0.1
        c0-0.5,0.3-0.9,0.7-1.2c0.1-0.2,0.2-0.5,0.3-0.7c0.1,0,0.3,0,0.3,0.1c-0.3-0.3,0.2-0.6,0.4-0.9c0-0.1,0-0.3-0.1-0.4
        c0.1,0,0.2,0,0.3,0c-0.1-0.4,0.1-0.7,0.4-0.9c0.1-0.2,0.2-0.5,0.4-0.7c0.1,0,0.3,0.1,0.4,0.1c-0.1-0.2-0.2-0.3-0.2-0.4
        c0.1,0,0.3,0,0.4-0.1c0-0.1-0.1-0.2-0.1-0.3c0.2-0.1,0.1-0.4,0.2-0.5c0.1,0,0.3-0.1,0.4-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
        c0.1,0,0.3,0,0.4,0c0-0.1-0.1-0.3-0.1-0.4c0.2-0.1,0.4-0.1,0.5-0.2c-0.6-0.4,0.6-0.6,0.2-1.1c0.1,0.1,0.2,0.2,0.3,0.2l0.1-0.2
        c-0.1,0-0.2,0-0.3,0l0.1-0.3c0.5-0.4,0.4-1.1,1-1.5c0.1-0.4,0.2-0.7,0.6-0.8c0-0.2,0-0.3,0-0.5c0.1-0.2,0.3-0.4,0.5-0.5
        c0-0.2,0-0.3,0-0.5c0.4-0.1,0.4-0.6,0.5-0.9c0.3-0.5,0.2-1.1,0.6-1.5c0-0.2,0.1-0.3,0.2-0.5c0.1-0.1,0.2-0.2,0.3-0.3
        c0.2-0.8,0.5-1.5,0.7-2.2c0.3-0.3,0.2-0.8,0.4-1.1c0.2-1.5,0.8-2.9,1.3-4.4c0-0.4,0.5-0.8,0.2-1.3c0.1,0,0.2-0.1,0.3-0.1l0.2-0.3
        c-0.1,0-0.3,0-0.4,0c0.2-0.1,0.3-0.2,0.3-0.5c0.4-0.1,0-0.6,0.1-0.9c0.2-0.5,0.1-1.1,0.2-1.6c0.1-0.2,0.4-0.6,0-0.7
        c0.1-0.2,0.2-0.3,0.4-0.5c-0.1-0.1-0.2-0.3-0.2-0.3c0.2,0,0.3-0.1,0.4-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c0.1-0.1,0.2-0.2,0.4-0.3
        c0,0-0.1-0.1-0.1-0.2c0.3-0.2,0.3-0.6-0.2-0.5c0.1-0.1,0.2-0.3,0.3-0.4c-0.2-0.2-0.3-0.5,0.1-0.4l0-0.3c-0.1,0-0.2,0.1-0.3,0.1
        c0.3-0.1,0.3-0.5,0.1-0.7c0.1-0.1,0.2-0.1,0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1c0.1-0.2,0.2-0.4,0.2-0.5c0-0.3-0.2-0.5,0.1-0.7
        c-0.1-0.2-0.3-0.4-0.1-0.5c-0.2-0.2,0-0.4,0.1-0.5c-0.4,0-0.2-0.4-0.1-0.6c0-0.2,0-0.3,0-0.5c-0.3-0.4,0.3-0.8-0.2-1.1
        c0.2-0.2,0.2-0.5,0.2-0.8c-0.1-0.1-0.2-0.2-0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.2c-0.3-0.4-0.1-1-0.1-1.5c-0.1-0.2-0.1-0.4-0.1-0.6
        l-0.2,0.1c0.1-0.2,0.4-0.7-0.1-0.6c0.1-0.2,0.2-0.3,0.2-0.5c-0.1-0.3-0.4-0.8,0-0.9c-0.1-0.5-0.4-1.1-0.2-1.7
        c-0.1,0.1-0.3,0.2-0.5,0.2c0.1-0.1,0.2-0.2,0.3-0.3c-0.4-0.2,0.3-0.7-0.1-0.8c0-0.1,0.1-0.2,0.1-0.2c-0.1-0.3-0.1-0.6-0.4-0.7l0,0
        c0.1,0,0.3,0.1,0.4,0.1c-0.1-0.3-0.3-0.4-0.5-0.6c0.1,0,0.3,0,0.4,0.1c-0.1-0.2-0.1-0.4-0.1-0.7c0.2-0.2,0.1-0.5-0.1-0.6
        c0.2-0.5,0-0.9-0.1-1.4c-0.4-1.7-0.9-3.4-1.2-5.1c-0.2-0.3-0.4-0.7-0.3-1.1c-0.3-0.4-0.5-0.8-0.5-1.2c-0.4-0.6-0.4-1.4-0.9-2.1
        c-0.1-0.4-0.4-0.7-0.5-1.1c-0.3-0.6-0.4-1.2-0.9-1.7c-0.4-0.6-0.4-1.3-0.9-1.9c-0.2-0.4-0.5-0.8-0.4-1.3c-0.4-0.3-0.8-0.7-0.8-1.3
        c0-0.3-0.2-0.6-0.4-0.9c-0.2-0.2,0-0.6-0.2-0.8c-0.5-0.6-0.5-1.4-1.1-2c-0.2-0.2-0.2-0.6-0.5-0.8c-0.1-0.1-0.2-0.3-0.2-0.5
        c-0.2-0.1-0.3-0.3-0.3-0.5c-0.3-0.1-0.3-0.3-0.5-0.5c-0.3-0.6-0.6-1.1-1-1.7c0.4,0.3,0.8,0.7,1.1,1.1c0-0.1,0-0.4-0.1-0.6
        c0.3,0.2,0.6,0.6,1,0.5c0,0.1-0.1,0.3-0.1,0.4c0.1-0.1,0.2-0.3,0.3-0.4c0.2,0.2,0.4,0.2,0.5,0c0,0.1-0.1,0.3-0.1,0.4
        c0.2-0.2,0.5-0.2,0.8-0.2c0,0.5,0.5,0,0.4-0.3c0.1,0.1,0.2,0.2,0.3,0.3l0-0.3c0.1,0.1,0.3,0.2,0.4,0.2c0.1-0.1,0.3-0.3,0.4-0.4
        l0.1,0l-0.1-0.2c0.2,0,0.3-0.1,0.5-0.1l0,0.2c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2-0.1-0.2-0.1c0.2,0.1,0.3,0.3,0.4,0.5
        c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.1,0.6-0.1,0.5-0.4c-0.2,0.1-0.4,0.2-0.6,0c0.1,0,0.3-0.1,0.4-0.1c0-0.1,0-0.2,0-0.3
        c0,0,0.1-0.1,0.1-0.1c0.1,0.2,0.1,0.4,0.2,0.6c0.2-0.5,0.7-0.6,0.9-1c-0.3,0.1-0.6,0.2-0.9,0.4c0.3-0.2,0.5-0.5,0.9-0.4
        c0-0.1,0.1-0.2,0.1-0.4c0.2-0.1,0.2-0.3,0.1-0.5c0.1,0,0.2,0,0.3,0c0-0.1,0-0.2,0-0.2c0.3,0,0.4-0.3,0.6-0.5
        c0.4-0.8,0.7-1.6,1.2-2.2c0.4-0.6,1-1.1,1.1-1.8c0.2-0.2,0.5-0.4,0.5-0.7c-0.3,0.1-0.5,0.3-0.4,0.6c-0.4,0-0.2-0.4-0.3-0.6
        c0.1,0,0.2-0.1,0.3-0.1c0-0.1,0.1-0.2,0.1-0.3c-0.4,0.2-0.7,0.5-1,0.8c0.1-0.1,0.4-0.2,0.5-0.3c0,0.1,0,0.3,0.1,0.4
        c0,0.1,0,0.3-0.1,0.5c-0.2,0.2-0.3,0.3-0.6,0.2c0,0.2,0,0.4,0,0.6c-0.2,0.3-0.5,0.5-0.8,0.7c-0.2,0.1-0.4,0.2-0.3,0.5
        c0,0-0.1,0.1-0.2,0.2c-0.1,0-0.3,0-0.4,0c0.1,0,0.4,0.1,0.5,0.1c-0.2,0.1-0.4,0.2-0.5,0.2c0,0.1,0,0.2,0.1,0.3
        c-0.1,0.1-0.3,0.2-0.4,0.3c-0.2,0.1-0.3,0.2-0.3,0.4c-0.1,0.2-0.2,0.3-0.4,0.5l0-0.3c-0.1,0.3-0.2,0.7-0.5,0.9
        c-0.1,0.1-0.2,0.3-0.2,0.4c-0.1,0-0.2,0-0.3,0c0,0.1,0,0.2-0.1,0.2c-0.4,0.4-0.7,1-1.2,1.3c0.1-0.1,0.2-0.3,0.3-0.4
        c0.1-0.5,0.6-0.9,0.7-1.4c0.5-0.5,0.8-1.2,1-1.9c0.3-0.5,0.4-1.1,0.8-1.5c0.4-0.4,0.5-1,0.8-1.5c0.2-0.3,0.2-0.7,0.5-1
        c0.4-0.5,0.5-1.2,1.1-1.5c-0.1-0.5,0.3-0.8,0.3-1.2c0.3-0.1,0.4-0.4,0.3-0.6c0.2-0.1,0.3-0.2,0.5-0.2c0-0.2,0-0.4,0.1-0.5
        c-0.3,0.3-0.6,0.6-1,0.8c0-0.2,0.1-0.4,0.1-0.5c0.3,0.3,0.4-0.3,0.5-0.5c0.2-0.1,0.3-0.1,0.5-0.2c0-0.1,0-0.2-0.1-0.3
        c-0.1,0.3-0.5,0.3-0.7,0.1c0.1,0,0.3-0.1,0.3-0.2c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.3,0.1-0.4c-0.1,0-0.2,0-0.3,0
        c0.1,0.1,0.1,0.3,0.2,0.4c-0.1,0.1-0.2,0.2-0.3,0.4c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1-0.3-0.1-0.7,0.3-0.8c0-0.1-0.1-0.1-0.1-0.2
        c-0.2,0.1-0.3,0.2-0.4,0.3c0,0.1,0.1,0.4,0.1,0.6c-0.2,0-0.3,0-0.5,0.1c0,0.2-0.1,0.5-0.3,0.7c0,0.1,0.1,0.2,0.1,0.3l0.3,0
        c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2-0.1-0.3-0.2-0.5-0.4c-0.1,0.1-0.2,0.3-0.3,0.4c0.2-0.2,0.6-0.2,0.6,0.2c-0.1,0.1-0.3,0.2-0.4,0.3
        l0.4,0c-0.1,0-0.3,0.1-0.4,0c0,0.1,0,0.3,0.1,0.4c-0.2,0.2-0.6,0.1-0.6,0.5c0.1,0,0.2,0.1,0.3,0.1c-0.1,0.1-0.3,0.1-0.4,0.1
        c0-0.3,0-0.5,0.1-0.8c-0.2,0.1-0.2,0.3-0.2,0.5c-0.1,0-0.2,0.1-0.2,0.1l0,0.2c0.1,0,0.2,0.1,0.3,0.1c-0.3,0.3-0.4,0.8-0.8,1.1
        c0.1-0.3,0.2-0.5,0.3-0.8c-0.2,0.3-0.4,0.6-0.3,0.9c0,0-0.1,0-0.2,0l0.1,0.2l-0.2-0.1c0.1,0.2,0,0.4-0.3,0.6
        c0.1,0.1,0.2,0.1,0.3,0.2l0.1-0.2c-0.1,0.1-0.1,0.2-0.1,0.4c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0.1-0.1,0.2-0.2,0.3
        c-0.1,0-0.2,0.1-0.3,0.2c0,0.2,0,0.4,0,0.7c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0.3-0.2,0.5-0.2,0.8c0,0-0.1,0-0.1,0l0-0.2l-0.3,0.1
        c0,0.1-0.1,0.3-0.1,0.4c0.1,0,0.2,0,0.2,0c-0.1,0-0.2,0-0.2,0c-0.1,0.1-0.2,0.2-0.2,0.2c0,0,0,0.1,0,0.2c-0.1,0-0.2,0-0.3,0
        c0,0.1,0,0.3,0,0.3c-0.1,0-0.2,0-0.3,0c0,0.1,0,0.2,0,0.3c-0.4,0.2-0.7,0.6-1.1,0.9c0,0,0-0.1,0-0.1c0.7-0.8,1.1-1.8,1.5-2.9
        c0.4-0.4,0.2-1.1,0.7-1.5c0-0.1,0-0.2,0.1-0.4c0.3-0.3,0.3-0.7,0.5-1.1c0.3,0,0.3-0.3,0.3-0.5c0.3-0.2,0.5-0.5,0.6-0.8
        c-0.3,0.4-0.6,0.9-1,1.2c0,0.1,0,0.3,0,0.4c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3,0.1-0.4,0.1c0.1,0,0.3,0,0.4,0c-0.2,0.2-0.3,0.5-0.5,0.7
        c0.1,0.1,0.2,0.1,0.3,0.2l-0.5-0.2l0.2,0.4c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2,0.3-0.4,0.4-0.6,0.6c-0.1,0.2-0.2,0.3-0.4,0.5
        c-0.6,0.5-1.3,1.1-1.9,1.6c0.4-0.6,0.9-1.1,1.4-1.7c0.3-0.4,0.4-1,0.8-1.4c0-0.4,0.3-0.8,0.4-1.2c0.5-0.6,0.6-1.4,0.9-2.1
        c0.1-0.4,0.2-0.8,0.5-1.1c-0.2,0-0.3,0.1-0.5,0.1c0,0,0,0.1,0,0.1l0.3,0c-0.2,0.5-0.6,0.8-0.9,1.2c-0.1,0-0.2,0-0.3-0.1
        c-0.1,0.1,0.1,0.5-0.2,0.5c-0.3,0.3-0.7,0.6-1.1,0.8c0,0.1,0,0.2,0,0.2c0,0-0.1-0.1-0.2-0.2c-0.1,0.1-0.3,0.2-0.4,0.4
        c0.2-0.2,0.3-0.4,0.6-0.5c0.5-0.5,0.9-1.1,1.2-1.7c0.2-0.4,0.2-0.8,0.4-1.2c0.3-0.5,0.6-0.9,0.8-1.4c-0.2,0.2-0.4,0.4-0.6,0.5
        c-0.2,0.2-0.3,0.3-0.5,0.4c0-0.2,0.1-0.2,0.2-0.3c0.5-0.6,0.8-1.4,0.9-2.1C319,105.7,318.9,105.8,318.8,105.9 M172.1,105.7
        C171.9,106.1,172.6,105.7,172.1,105.7 M314.6,105.7C314.6,106.1,315,105.5,314.6,105.7 M241.5,105.9c0,0.1,0.1,0.2,0.2,0.2
        c-0.1,0.1-0.1,0.3-0.2,0.4c0.3,0.2,0.1,0.5,0,0.8c0.5-0.3,0.3-1,0.2-1.5C241.6,105.8,241.5,105.8,241.5,105.9 M187.4,106
        C187.2,106.4,187.9,105.9,187.4,106 M304.2,105.9C304.2,106.4,304.6,105.7,304.2,105.9 M218.3,106.2c0,0.3-0.2,0.4-0.5,0.4
        c0.1,0.1,0.2,0.2,0.2,0.2c-0.1,0-0.3,0.1-0.4,0.1c0,0.1,0.1,0.2,0.1,0.3l-0.2,0c0,0.1,0,0.2,0,0.3l-0.1,0c0,0.2-0.1,0.3-0.2,0.5
        c0,0.1,0.1,0.2,0.2,0.3l0.1-0.1c0-0.1,0-0.2,0.1-0.3c0.2-0.3,0.4-0.6,0.5-1C218.1,106.6,218.5,106.4,218.3,106.2 M252.3,106.6
        c0,0.2,0,0.4-0.1,0.6c0-0.5-0.6-0.1-0.2,0.1c-0.1,0.1-0.1,0.2-0.1,0.4c-0.1,0-0.2,0-0.2-0.1c0,0.3,0,0.5-0.2,0.7
        c0-0.1,0-0.4,0.1-0.5c-0.1,0.1-0.2,0.2-0.4,0.3c0.1,0,0.2,0.1,0.3,0.2c-0.3,0.1-0.5,0.2-0.8,0.3l0,0c0.1,0.2,0.3,0.6-0.1,0.7
        c0.1,0,0.4,0,0.5,0c-0.2,0.1-0.3,0.1-0.5,0.2c0,0.1-0.1,0.3-0.1,0.4c-0.1,0-0.2-0.1-0.3-0.2c0,0.2-0.2,0.4-0.3,0.5
        c0,0.2,0,0.4,0,0.6c0.1,0.1,0.3,0.4,0.4,0.1c0.1-0.4,0.3-0.9,0.7-0.8c0-0.2,0-0.3,0.1-0.4c0.4-0.6,0.8-1.2,1.3-1.6
        c0-0.3,0-0.6,0.2-0.9c0,0-0.1-0.1-0.2-0.1c0-0.2,0.1-0.3,0.3-0.3l0-0.1C252.6,106.4,252.4,106.5,252.3,106.6 M286.7,106.4
        C286.8,106.5,286.8,106.5,286.7,106.4 M172.3,106.6c-0.1,0.2,0.2,0.3,0.4,0.2C172.8,106.5,172.4,106.4,172.3,106.6 M226.4,106.5
        C226.5,106.6,226.5,106.6,226.4,106.5 M342.1,106.8c0.1,0.1,0.5,0,0.4-0.2C342.3,106.4,342,106.5,342.1,106.8 M252.7,106.7
        C252.8,107.2,253.1,106.4,252.7,106.7 M212.7,106.8c0.2,0.4,0.6,0.8,0.7,1.2c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.5,0.7,0.7,0.8,1.3
        c0.1,0.3,0.2,0.5,0.5,0.7c0.1-0.2,0.3-0.4,0.4-0.5c-0.1-0.2-0.2-0.4-0.3-0.6c-0.4-0.2-0.7-0.5-0.9-0.8c-0.1-0.2-0.3-0.3-0.4-0.4
        c-0.1-0.4-0.4-0.7-0.4-1.1c-0.1,0-0.2,0.1-0.2,0.1C212.9,106.9,212.8,106.8,212.7,106.8 M221.2,106.9c-0.2,0.4,0.1,0.8,0,1.2
        c-0.3,0.3,0.2,0.4,0.3,0.7c0-0.1,0-0.3,0-0.4c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.1,0.2,0.2,0.3,0.3c-0.1-0.3-0.1-0.7-0.5-0.5
        c0-0.1,0-0.3,0.1-0.4C221.4,107.7,221.3,107.3,221.2,106.9 M341.4,107.1c0.2,0.3,0.6,0.3,0.9,0.5c-0.1-0.2,0-0.4,0.1-0.6
        c-0.1,0.1-0.3,0.2-0.4,0.3v-0.3C341.8,107,341.6,107,341.4,107.1 M214.8,107.3c-0.1,0.2,0.2,0.3,0.3,0.5
        C215.4,107.7,215.1,107.1,214.8,107.3 M260.3,107.2c0.1,0.4,0.7,0.6,0.6,1.1c0.2-0.1,0.4,0.1,0.5,0.3c0,0.1,0.1,0.2,0.1,0.2l0,0
        c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.2,0.4,0.4,0.4,0.7c0.1,0,0.2,0,0.2,0c0,0.1,0,0.3,0,0.3c0.1,0,0.2-0.1,0.3-0.1
        c0.1-0.1,0.2-0.3,0.3-0.4c-0.3-0.4-0.8-0.6-1.2-1C261.3,108.2,261.1,107.3,260.3,107.2 M261.8,107.4
        C261.9,107.5,261.9,107.5,261.8,107.4 M277.6,107.4C277.7,107.5,277.7,107.5,277.6,107.4 M266.1,107.4c0,0.4-0.1,0.7-0.3,1l-0.1,0
        c-0.1,0.2-0.1,0.4-0.2,0.6c-0.3,0.1-0.3,0.3-0.3,0.6c-0.1,0.3-0.5,0.4-0.5,0.8l-0.1,0c0,0.1,0.1,0.2,0.1,0.3c-0.3,0-0.4,0.2-0.4,0.4
        c-0.1,0-0.2,0-0.3,0c0,0.1,0,0.3,0.1,0.4c-0.3,0-0.4,0.3-0.5,0.5c-0.1,0-0.4-0.1-0.5-0.1c0.1,0.1,0.2,0.2,0.3,0.3l-0.3,0.1
        c0,0.1,0,0.2,0,0.3l-0.1,0l0,0.3c-0.1,0-0.2-0.1-0.3-0.2c0.1,0.3-0.2,0.4-0.3,0.5c0,0.2,0,0.3,0.2,0.4c0,0.1-0.1,0.2-0.1,0.2
        c0.2,0.1,0.5,0.3,0.7,0.5c0.2-0.3,0.4-0.7,0.7-1c0-0.1,0-0.2,0-0.3l0.1,0c0-0.4,0.3-0.7,0.5-1.1c0.3-0.9,1-1.7,1.1-2.7
        c0.1-0.2,0.3-0.5,0.4-0.7c0.1,0,0.2-0.1,0.2-0.1c0-0.1-0.1-0.2-0.1-0.2c0.2-0.2,0.2-0.4,0.3-0.7L266.1,107.4 M172.9,107.7
        c-0.2,0.1-0.2,0.4,0.1,0.4C173.3,108.1,173.2,107.5,172.9,107.7 M297.6,108c0.4,0.1,0.5-0.2,0.6-0.5
        C298,107.7,297.8,107.8,297.6,108 M260.5,107.8C260.6,107.9,260.6,107.9,260.5,107.8 M272.4,108c0,0.2-0.1,0.3-0.1,0.5
        c0.2,0.3,0.4,0.5,0.8,0.4c0.1,0.1,0.2,0.2,0.2,0.4c-0.1-0.1-0.4-0.2-0.6-0.3c0,0.7,1,0.5,1.2,0.1c-0.3-0.4-0.8-0.3-1.1-0.7
        c-0.1,0.1-0.2,0.2-0.3,0.2c0-0.1,0-0.3,0.1-0.4C272.6,108.1,272.5,108,272.4,108 M287.6,108.1C287.6,108.2,287.6,108.2,287.6,108.1
        M211.4,109.2c0.2,0.3,0,0.6,0.2,1c0.1,0.1,0.3,0.3,0.2,0.5c0.2,0,0.3,0,0.5,0c0-0.3-0.1-0.5-0.2-0.7c-0.3-0.6-0.3-1.2-0.4-1.9
        C211.3,108.4,211.5,108.9,211.4,109.2 M264.1,109.1c-0.3,0.2-0.7,0.5-0.3,0.8c0.3-0.6,0.7-1.1,1-1.7
        C264.5,108.4,264.4,108.8,264.1,109.1 M297.5,108.3c-0.2,0.2-0.1,0.4,0,0.7c-0.1,0-0.3,0-0.4,0c0,0.2,0,0.4,0.1,0.6
        c-0.1,0.1-0.3,0.1-0.4,0.2c0.1,0.3-0.1,0.5-0.2,0.7c-0.1,0-0.4,0-0.5,0c0.1,0.1,0.2,0.3,0.4,0.4c-0.1,0.1-0.3,0.1-0.4,0.2
        c-0.1,0.3-0.4,0.6-0.5,1c-0.1,0-0.2,0-0.3,0c-0.1,0.3-0.2,0.9-0.6,1c0.1,0.5-0.7,0.8-0.7,1.4c-0.1,0-0.2,0.1-0.3,0.1
        c0,0.1,0,0.3,0,0.4c-0.1,0-0.3,0-0.3,0c0.1,0.3,0.1,0.6-0.3,0.4c0,0.1,0,0.3,0.1,0.4c-0.1,0-0.2-0.1-0.2-0.2c0,0.1,0,0.3,0,0.4
        c-0.2,0.2-0.4,0.5-0.7,0.7c-0.2,0.3-0.4,0.7-0.7,1c-0.2,0.2-0.2,0.6-0.5,0.5c0,0.1,0,0.2,0,0.3c-0.3,0.2-0.3,0.6-0.6,0.7
        c0-0.2,0-0.4,0.1-0.6c0.4,0,0.1-0.7,0.6-0.6c0-0.2-0.1-0.6,0.3-0.6c0-0.1,0-0.2,0-0.3c0.1-0.2,0.2-0.5,0.4-0.7c0-0.1,0-0.2,0-0.2
        c0.4,0.1,0.3-0.3,0.4-0.5c0.1-0.2,0.1-0.5,0.4-0.7c0-0.2,0.1-0.4,0.1-0.6c0.1,0,0.2-0.1,0.3-0.1c0-0.3,0-0.8,0.4-0.8
        c0.1-0.2,0.1-0.4,0.2-0.7c0.4,0,0.1-0.4,0.2-0.6c0,0-0.1,0-0.1,0c-0.1,0.2-0.1,0.4-0.2,0.7c-0.1-0.1-0.1-0.3-0.2-0.4
        c0.1,0.3,0,0.7-0.4,0.7c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.4,0.7-0.9,1.3-1.5,1.9c0,0.2-0.2,0.4-0.4,0.4
        c0.1,0.1,0.2,0.2,0.3,0.4c-0.1-0.1-0.4-0.3-0.5-0.1c-0.1,0.2-0.1,0.4-0.4,0.5c-0.2,0.2-0.2,0.5-0.5,0.5c0,0,0,0.1,0,0.2
        c-0.2,0.1-0.3,0.3-0.5,0.4c0.1,0.3,0.2,0.7,0.6,0.9c-0.1,0-0.2,0-0.3,0c0.1,0.2,0.1,0.6,0.4,0.6c-0.1-0.1-0.2-0.2-0.3-0.3
        c0.2,0,0.5-0.1,0.4,0.2c0,0.1,0.1,0.2,0.2,0.3c0.1,0,0.2,0,0.3-0.1c0,0.2-0.1,0.4-0.1,0.5l0.2-0.1c0,0.1,0,0.2-0.1,0.3
        c0.4,0.1,0.6,0.5,0.9,0.8c0.3,0.2,0.7,0.7,1.1,0.5c0-0.1-0.1-0.4-0.1-0.5c0.1,0,0.3,0,0.4,0.1c0-0.1-0.1-0.4-0.1-0.5l0.2,0
        c0.1-0.1,0.3-0.2,0.4-0.3c-0.1,0-0.4,0-0.5,0c0.2-0.2,0.4-0.5,0.6-0.2c-0.1-0.2-0.1-0.5-0.1-0.7l0.2,0c0-0.1,0-0.3,0-0.4
        c0.1,0,0.2,0,0.3,0c0.1-0.3,0.1-0.7,0-1c0.1,0,0.3,0.1,0.4,0.1c-0.1-0.2-0.2-0.3-0.3-0.4c0.4-0.1,0.3-0.4,0.3-0.7
        c0.2,0,0.4,0,0.5-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.2-0.3,0.2-0.5,0.3-0.7c0.1,0,0.3-0.1,0.4-0.1c-0.1-0.1-0.2-0.2-0.2-0.3
        c-0.1,0.1-0.2,0.2-0.2,0.3c0-0.1,0-0.3,0-0.3c0.1,0,0.3,0.1,0.3,0.1c-0.1-0.4,0.4-0.5,0.5-0.8c0-0.1-0.1-0.2-0.1-0.4
        c0.1-0.2,0.3-0.4,0.3-0.7c0.1,0,0.2-0.1,0.3-0.1l0.1-0.2l-0.3-0.1c0.1-0.1,0.2-0.2,0.4-0.4c0.1-0.2,0-0.5,0.1-0.7
        c0.1,0,0.2,0.1,0.3,0.2c-0.1-0.3,0.2-0.4,0.4-0.6c0,0.1,0,0.2,0.1,0.2c0.1-0.1,0.1-0.2,0.2-0.3c-0.1,0-0.3,0-0.4,0
        c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.1,0.2-0.2,0.4-0.3c-0.1,0-0.3-0.1-0.4-0.2c0.2-0.2,0.8-0.6,0.3-0.9c0.5,0.3,0.3-0.3,0.4-0.6
        c0.2-0.2,0.3-0.4,0.5-0.5c-0.1,0-0.3,0.1-0.4,0.1c0.1-0.1,0.2-0.3,0.2-0.4c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.2,0,0.3,0c0,0,0-0.1,0-0.2
        C297.8,108.3,297.7,108.3,297.5,108.3 M216.8,108.4c-0.1,0.1-0.2,0.2-0.3,0.2c0,0.2,0,0.3,0,0.5c0.1,0.1,0.3,0.3,0.4,0.4
        c0.1-0.1,0.2-0.2,0.3-0.3c0-0.1,0-0.2,0-0.4C217,108.7,216.9,108.5,216.8,108.4 M308.8,108.4c-0.1,0.2-0.3,0.4-0.4,0.7
        c-0.3,0.2-0.5,0.3-0.7,0.6c0.1,0,0.2,0.1,0.3,0.1c0.1-0.1,0.2-0.2,0.3-0.3c0,0.1,0,0.3,0,0.3c0.2-0.1,0.2-0.5,0.3-0.7
        c0.1,0,0.3,0,0.3,0.1c-0.1-0.1-0.2-0.2-0.3-0.3c0.1,0,0.4,0,0.5,0C309.2,108.5,309,108.5,308.8,108.4 M303.8,108.5
        c0,0.1,0,0.3,0,0.4c0.2,0.2,0.3,0.5,0.7,0.4c-0.1,0.1-0.3,0.2-0.4,0.3c0.2,0.1,0.6,0.2,0.5,0.6c0.1,0,0.3-0.1,0.4-0.1
        c-0.2,0.3-0.2,0.6,0.2,0.6c0,0.3,0.2,0.5,0.4,0.8c0.3,0.1,0.5,0.3,0.7,0.5l-0.2,0c0.1,0.1,0.2,0.2,0.3,0.4c0-0.1,0-0.4,0-0.5
        c0.1,0.2,0.3,0.3,0.5,0.3c-0.2-0.1-0.4-0.2-0.7-0.4c0.3,0.1,0.5,0,0.6-0.3c-0.1-0.2-0.5-0.8-0.4-0.2c0,0-0.1,0.1-0.2,0.1
        c0-0.1,0.1-0.3,0.2-0.4c-0.5-0.3-1.1-0.7-1.3-1.3c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.2,0-0.2C304.2,109.1,304.1,108.7,303.8,108.5
        M220.3,109.7c0.1,0,0.3-0.1,0.4-0.1c0.2,0.1,0.3,0.2,0.5,0.3c-0.1-0.4-0.4-0.9-0.1-1.3C220.6,108.9,220.5,109.4,220.3,109.7
        M239.1,108.6c0,0.4-0.1,0.7-0.2,1c0.2-0.2,0.2-0.6,0.6-0.6c-0.4,0.9-1,1.7-1.3,2.7c0.5-0.3,0.4,0.2,0.4,0.5
        c0.3-0.4,0.6-0.8,0.7-1.3c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.4,0.2-0.7,0.2-1.1c-0.1,0-0.3-0.1-0.4-0.1c0.1,0,0.3,0,0.4-0.1
        c-0.2-0.3-0.2-0.6-0.1-0.9c-0.1,0.2-0.2,0.4-0.4,0.6C239.2,109,239.2,108.8,239.1,108.6 M277.8,108.8c-0.1,0.2,0,0.3,0.2,0.2
        C278.1,108.9,278,108.8,277.8,108.8 M258.7,109.8c0.1,0,0.4-0.1,0.5-0.1c0,0.1,0,0.4-0.1,0.5c0.2,0.2,0.5,0.4,0.7,0.6
        c0.3,0.4,0.7,0.7,1,1.1c0.1,0,0.2-0.1,0.3-0.1c-0.2,0.2-0.2,0.4-0.2,0.6c0.3,0,0.8,0.2,0.8,0.6l0.2-0.1c0,0.1,0,0.3,0,0.4
        c0.4-0.2,0.5-0.7,0.7-1.1c-0.2-0.1-0.3-0.2-0.5-0.3c-0.3-0.1-0.5-0.3-0.7-0.5c-0.3-0.2-0.6-0.4-0.9-0.8c-0.1,0-0.2-0.1-0.2-0.1
        c-0.3-0.5-0.9-0.9-1.2-1.4C258.8,109.1,258.9,109.5,258.7,109.8 M309.1,108.9C309.2,109,309.2,109,309.1,108.9 M217.5,109.1
        c-0.1,0.3-0.3,0.5-0.5,0.7c0.2,0.1,0.4,0.1,0.6,0.1l-0.1,0.2c0.2-0.4,0.7-0.4,1-0.2c-0.2-0.1-0.3-0.3-0.5-0.3
        C217.9,109.5,217.7,109.3,217.5,109.1 M260.6,109.4c0.2,0.3,0.6,0.5,0.6,0.9c0.3,0.1,0.3,0.4,0.4,0.6l0.1,0c0.1,0.2,0.2,0.5,0.5,0.6
        c0.1,0.2,0.3,0.4,0.5,0.6c0.1-0.2,0.3-0.4,0.5-0.6c0.2-0.2,0.1-0.7-0.2-0.5c-0.3-0.4-0.7-0.6-1.1-0.9c-0.1-0.1-0.1-0.2-0.2-0.4
        C261.2,109.5,260.9,109.4,260.6,109.4 M277.6,110c-0.1,0-0.2,0-0.2,0c0,0.1,0,0.2,0,0.2c-0.4,0-0.5,0.3-0.8,0.6
        c-0.2,0-0.3,0.1-0.4,0.3c0.1,0,0.2,0.1,0.3,0.1l0,0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0.2-0.8,0.4-0.3,0.7c-0.5,0-0.7,0.5-1.2,0.7
        c0.1,0.3,0,0.5-0.3,0.6c0.2,0.1,0.4,0.1,0.7,0c-0.1,0.3-0.5,0.2-0.7,0.1l0.2,0.4c-0.1-0.1-0.2-0.3-0.2-0.4c-0.3,0.4-0.1,1.1,0.3,1.4
        c0,0.3,0,0.5,0.1,0.8c0.1,0.1,0.3,0.2,0.4,0.3c0.4-0.5,0.6-1.1,0.9-1.7c0-0.4,0.2-0.7,0.5-1c0.4-0.4,0.3-1.1,0.7-1.5
        c0.3-0.7,1-1.2,1.2-2C278,109.6,277.6,109.7,277.6,110 M264.8,109.8C264.8,110.3,265.2,109.6,264.8,109.8 M307.6,109.8
        c0.1,0.2,0.3,0.3,0.3,0.6c0-0.1,0-0.2,0.1-0.2c0.3,0.1,0.5,0.1,0.5-0.3C308,110.2,307.9,109.9,307.6,109.8 M215.2,110
        c0,0.1,0,0.2,0,0.2c0.2,0,0.4,0,0.6,0.1c-0.1-0.1-0.2-0.3-0.2-0.4C215.5,109.9,215.4,110,215.2,110 M263.2,109.7c0,0.1,0,0.2,0,0.2
        c-0.2,0.1-0.4,0.2-0.6,0.4c0.2,0.2,0.5,0.4,0.7,0.6c0.1-0.3,0.2-0.6,0.4-0.9C263.6,110,263.4,109.8,263.2,109.7 M220.5,110.1
        l0.1,0.3c-0.3-0.1-0.6-0.1-0.9,0.1c0.1,0,0.3,0.1,0.4,0.1l-0.1,0.2c-0.1-0.1-0.2-0.2-0.3-0.2c-0.3,0-0.5,0-0.8-0.1
        c0,0-0.1,0.2-0.1,0.2c-0.2,0-0.3,0-0.5,0c-0.2-0.2,0.2-0.2,0.3-0.4c-0.2,0.1-0.4,0.2-0.6,0.3c-0.1,0-0.2-0.1-0.2-0.1l0,0.1l-0.1,0.1
        l-0.2,0c0,0.1,0.1,0.1,0.1,0.2c0.2,0,0.3-0.1,0.5-0.1l-0.2,0.3c0.2,0.1,0.3,0.2,0.5,0.3l0,0.1l0.2,0c0,0.1,0.1,0.2,0.1,0.2
        c0.4,0.3,0.8,0.7,1.3,1c-0.5-0.2-0.9-0.4-1.3-0.7c-0.6-0.1-0.9-0.7-1.5-0.9c-0.2,0-0.3-0.1-0.5-0.2c-0.1,0.1-0.2,0.2-0.3,0.3
        c-0.2,0-0.3,0-0.5,0c-0.1,0.3,0,0.6,0.1,0.9l0.1-0.1c0.1,0.2,0.4,0.3,0.3,0.6c0.3,0,0.3,0.2,0.4,0.4c0.3,0,0.5,0.3,0.3,0.6
        c0.1,0,0.3-0.1,0.4-0.2c0,0.2,0,0.3,0,0.5c0.6,0.2,1,0.7,1.6,0.8c0.1-0.2,0.2-0.5,0.4-0.7c0.1,0,0.2-0.1,0.2-0.1
        c0.3-0.9,0.9-1.6,1.3-2.4c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.3,0.2-0.7,0-1c-0.1,0.3-0.5,0.1-0.8,0.2
        C220.6,110.3,220.5,110.1,220.5,110.1 M232.7,110.2c0,0.5,0.1,1,0.1,1.5c-0.1,0.1-0.2,0.2-0.2,0.4c0.1,0,0.3-0.1,0.3-0.1
        c0,0.1,0.1,0.2,0.1,0.3c-0.1,0.1-0.1,0.2,0,0.3c0.3,0.2,0.4,0.5,0.5,0.9c-0.1,0.2-0.3,0.4,0.1,0.4l-0.1,0.2c0.1-0.1,0.1-0.3,0.2-0.4
        c0.1,0.1,0.2,0.4,0.2,0.6c0-0.2,0-0.3,0-0.5c-0.2-0.2-0.4-0.4-0.5-0.6c0.1,0,0.2,0,0.3,0c0.3,0.3,0.5,0.6,0.6,1
        c0.2,0.2,0.4,0.3,0.4,0.6c-0.1,0.3,0.3,0.5,0.4,0.8c0.1,0.1,0.3,0.2,0.3,0.4c0.1,0.2,0.3,0.5,0.5,0.7c0-0.1-0.1-0.2-0.2-0.3
        c0-0.1,0-0.3,0-0.5l-0.2,0c-0.1-0.5-0.9-0.6-0.6-1.2c-0.3-0.4-0.5-1-0.9-1.4c0-0.5-0.5-0.8-0.5-1.3c-0.2-0.2-0.3-0.5-0.2-0.8
        c0,0-0.1,0-0.2,0c0-0.1,0-0.2,0.1-0.3c-0.4,0-0.3-0.4-0.4-0.6C232.8,110.1,232.7,110.1,232.7,110.2 M249.4,110.7l0,0.1
        C249.6,111,249.7,110.4,249.4,110.7 M234.3,110.8c0.2,0.3,0.4,0.7,0.6,1.1l0.1,0.1c0,0.4,0.3,0.6,0.4,1c0.2-0.2,0.2-0.4-0.1-0.5
        c0.1-0.1,0.4-0.3,0.4,0c0,0,0.1,0,0.2,0c-0.3,0.4,0.5,0.5,0.5,0.9c-0.1-0.1-0.3-0.2-0.4-0.2c0,0,0,0.1,0,0.1
        c0.2,0.1,0.3,0.3,0.3,0.5c0.1,0,0.2,0,0.3-0.1v0.2l0.2,0c0,0.1,0.1,0.3,0.1,0.4c0-0.1,0-0.2,0-0.3c0.1,0,0.2,0,0.3,0
        c-0.1,0.2-0.3,0.4-0.4,0.6c0.1,0,0.3,0,0.3,0c0,0.1,0,0.2,0,0.3c0.3,0.2,0.4-0.4,0.6-0.5c-0.2-0.3-0.4-0.4-0.7-0.6
        c-0.1-0.2-0.3-0.3-0.4-0.4c0.1-0.3-0.2-0.3-0.3-0.4c0-0.1-0.1-0.3-0.1-0.3c-0.3-0.1-0.5-0.3-0.6-0.6c-0.2,0-0.3-0.1-0.5-0.1
        c-0.2-0.1-0.3-0.3-0.4-0.5c0-0.1,0-0.2,0-0.3C234.5,110.9,234.4,110.8,234.3,110.8 M249.2,111c-0.1,0.2-0.1,0.5-0.4,0.7
        c-0.1,0.2-0.2,0.4-0.5,0.3l0.1,0.2c-0.4,0.5-0.9,1.1-1.4,1.5c0,0.1-0.1,0.3-0.1,0.4l-0.2-0.1c0,0.3-0.3,0.9-0.7,0.8
        c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.3-0.4,0.4c0,0.1,0,0.2,0,0.3c-0.3,0-0.6,0.1-0.6,0.4c-0.1,0-0.2-0.1-0.3-0.2
        c0,0.2,0,0.5-0.1,0.7c-0.1-0.1-0.2-0.2-0.3-0.3c0.1,0.3,0.3,0.6,0.4,0.8c0.5-0.5,0.7-1.2,1.1-1.7c0.3-0.6,1-0.9,1.2-1.5
        c0.4-0.1,0.5-0.5,0.8-0.8c0.7-0.9,1.3-1.7,2-2.6C249.6,111,249.4,111,249.2,111 M221.9,111.4c0.1,0.1,0.2,0.2,0.3,0.3l-0.3,0.1
        c0,0.1,0,0.3,0,0.3c-0.1,0-0.3,0-0.4-0.1c0.1,0,0.2,0.1,0.3,0.2c-0.2,0.2-0.5,0.3-0.6,0.7l-0.1,0c-0.1,0.2-0.2,0.3-0.3,0.3l0,0.1
        c-0.1,0.1-0.2,0.2-0.3,0.4c-0.1,0-0.2,0-0.2,0c0,0.1,0,0.3,0,0.4l-0.2,0c0,0.1,0,0.2,0.1,0.3c-0.3,0.2-0.6,0.4-0.7,0.7
        c-0.1,0-0.2,0.1-0.2,0.2c0,0.4-0.4,0.7-0.7,0.9c-0.1,0.2-0.2,0.4-0.4,0.4c-0.1,0.2-0.2,0.5-0.5,0.6c0,0,0-0.1,0-0.2
        c0.3-0.1,0.4-0.5,0.5-0.7c0,0,0.1,0,0.2,0c0.1-0.2,0.2-0.5,0.5-0.5c0-0.1,0-0.2,0-0.2l0.2,0c0-0.2,0.1-0.4,0.1-0.6
        c-0.2,0-0.3-0.1-0.5-0.1c-0.3-0.2-0.6-0.3-1-0.4c-0.2-0.2-0.4-0.4-0.7-0.3c0-0.1,0-0.2,0-0.2c-0.2,0-0.4,0.1-0.5,0
        c-0.4-0.3-1-0.6-1.3-1.1c-0.1,0-0.2,0.1-0.3,0.2c0-0.2,0-0.4-0.1-0.6c-0.2,0.1-0.4,0.2-0.5,0.4c0.1,0,0.4,0,0.5,0
        c0,0.1-0.1,0.3-0.1,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0-0.1,0-0.3,0-0.4c0.2,0.1,0.4,0.4,0.2,0.6c-0.1,0-0.3,0-0.4-0.1
        c0.2,0.1,0.4,0.3,0.6,0.6c0.1,0,0.2,0,0.2-0.1c0.1,0.1,0.2,0.4,0.2,0.5c-0.1,0-0.3,0-0.4,0c0.1,0.3,0.6,0.2,0.5,0.6l0.1,0
        c0.1,0.2,0.2,0.3,0.3,0.4c-0.1,0-0.2,0.2-0.2,0.2c0.1,0,0.2,0,0.3,0l0.1,0.2c-0.1,0-0.2,0.1-0.2,0.1c0.1,0.1,0.3,0.2,0.4,0.2
        c0,0.4,0.3,0.6,0.6,0.8c0.1,0.2,0.3,0.1,0.5,0.1c-0.4,0-0.1,0.3,0,0.4c-0.4-0.3-0.7,0.3-1,0.5c0.2-0.3,0.4-0.5,0.7-0.7
        c-0.8-0.3-1.4-1-1.9-1.6c-0.5-0.3-0.8-0.9-1.1-1.4c-0.4-0.3-0.5-0.9-0.9-1.2l0,0.2c-0.3,0.1-0.3,0.1,0,0l-0.1,0.2l0.1,0
        c0.1,0.2,0.1,0.4,0.3,0.5c0.1,0.3,0.2,0.5,0.3,0.7c-0.1,0.1-0.2,0.3-0.3,0.4c0.2-0.1,0.3-0.1,0.5-0.2c0.1,0.3,0.4,0.5,0.4,0.9
        c0.3,0.4,0.6,0.8,1,1.2c0.2,0.2,0.4,0.3,0.5,0.4c-0.3,0-0.4-0.3-0.7-0.3c-0.3-0.3-0.8-0.6-1-1c-0.7-0.5-1.3-1.1-1.5-2
        c-0.2-0.2-0.5-0.4-0.6-0.8c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0.3-0.5,0.4-0.7,0.4c0.3,0,0.3,0.3,0.2,0.5c0.2,0.1,0.4,0.3,0.6,0.5
        c0,0.1,0,0.3,0,0.3c0.4,0,0.3,0.4,0.3,0.6c0.4,0,0.3,0.4,0.4,0.6l0.1,0c0,0.1,0,0.2-0.1,0.3l0.2,0c0,0.1,0,0.2,0,0.3
        c0.1,0,0.2,0,0.2,0c0,0.1,0,0.2,0,0.2c0.3,0.3,0.6,0.5,0.7,0.9c-0.3-0.1-0.5-0.3-0.8-0.5c0.7,0.7,1.4,1.4,2.2,2
        c-1.8-1-3.2-2.5-4.5-4.1c-0.2-0.4-0.4-0.7-0.7-1c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0,0.3,0,0.5,0c-0.2,0.2-0.4,0.3-0.6,0.5
        c0,0.1,0,0.3,0,0.3c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2,0.1-0.4,0.3-0.5,0.4c-0.4,0.1-0.5,0.6-0.8,0.8c0.1,0,0.2,0,0.2,0l0.2,0.2l0,0.2
        c-0.3-0.1-0.4,0-0.6,0.2c0.1,0,0.4-0.1,0.6-0.1c-0.3,0.3-0.7,0.4-0.9,0.7c0.1,0,0.3,0,0.4,0.1c0,0-0.1,0.1-0.1,0.2
        c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0.2-0.2,0.4-0.3,0.6c0,0.1-0.1,0.2-0.1,0.3l-0.1,0c0.1,0.3-0.1,0.7-0.4,0.8c0.1-0.3,0.1-0.5,0.2-0.8
        c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0,0.3,0,0.4c-0.2,0.2-0.4,0.5-0.4,0.8l-0.1,0l0-0.2c-0.1,0-0.2,0-0.2,0c0.1,0,0.2,0.1,0.2,0.2
        c0,0.2-0.1,0.5-0.2,0.7c-0.1-0.1-0.2-0.3-0.3-0.4c0,0.3,0.1,0.5,0.1,0.8c-0.1,0-0.3,0-0.4,0c-0.1,0.3-0.1,0.6-0.2,0.9
        c-0.1,0.1-0.5,0-0.4,0.3c0.1,0.5-0.4,0.8-0.3,1.3c-0.1,0.2-0.1,0.5-0.4,0.6c0,0.1,0,0.2,0,0.3c0.2-0.1,0.3-0.2,0.5-0.3
        c0,0.1,0.1,0.3,0.1,0.4c-0.3-0.2-0.5,0-0.2,0.3c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0.2,0.1,0.4,0.2,0.5c0.1-0.1,0.2-0.1,0.4-0.2
        c-0.1,0.2-0.2,0.3-0.2,0.5c0.1,0,0.3,0.1,0.3,0.2c-0.2-0.1-0.4-0.1-0.6-0.2c0.2,0.3-0.1,0.7,0.1,1c0,0.2-0.1,0.3-0.1,0.5
        c0.1,0,0.2,0,0.3,0l-0.1-0.3c0.1,0,0.2,0.1,0.3,0.1c0,0.1,0,0.4,0,0.5c-0.1-0.1-0.3-0.2-0.4-0.3c0.2,0.2,0,0.5-0.1,0.7
        c0.2,0,0.4,0.1,0.5,0.3c-0.1,0-0.3,0-0.4,0c0.1,0.1,0.1,0.3,0.2,0.4c0.2-0.2,0.3-0.3,0.5-0.5c-0.1,0.3-0.1,0.5,0.1,0.7
        c-0.2-0.1-0.4-0.2-0.6-0.3c0.1,0.2,0.2,0.5,0.5,0.6c0,0.1-0.1,0.3-0.2,0.3c0.4-0.2,0.4,0,0.3,0.3l0.2,0.1c0,0.1,0,0.2,0,0.3l0.2,0
        c-0.1,0.1-0.3,0.1-0.4,0.2c0.1,0.2,0.3,0.4,0.2,0.6c0.5,0,0.4,0.6,0.6,0.9c-0.1,0-0.2,0-0.3,0c0.1,0.2,0.5,0.6,0.7,0.2
        c0.1,0.1,0.2,0.3,0.2,0.4c-0.1,0.1-0.2,0.1-0.3,0.2c0.5,0.1,0.8,0.6,1.2,0.9c0.1,0,0.2-0.1,0.3-0.1c0,0.1,0,0.3,0,0.3
        c0.2,0,0.4,0.2,0.5,0.3c0.1,0,0.2-0.1,0.3-0.2c0.1,0.2,0.1,0.4,0,0.6c0.2-0.2,0.6-0.3,0.5,0.2c0.2-0.3,0.5,0,0.7,0.1
        c-0.1,0.1-0.2,0.2-0.3,0.3c0.1-0.1,0.2-0.2,0.4-0.2c0.3,0.1,0.5,0.3,0.8,0.4c0,0.1,0.1,0.2,0.1,0.2c0.3,0,0.6,0.1,1,0.1
        c0,0,0.1,0.1,0.2,0.1c0.1,0,0.3-0.1,0.3-0.1l-0.1-0.2c0.3,0,0.8,0.4,0.9-0.1c0.1,0.2,0.4,0.1,0.6,0.1c0.1-0.3,0.3-0.2,0.5,0
        c0-0.1,0.1-0.3,0.2-0.3l0.2,0.2c0.2-0.3,0.5-0.3,0.8-0.2c0.1-0.1,0.2-0.3,0.3-0.4c0,0.1-0.1,0.3-0.1,0.4c0.4-0.3,0.9-0.6,1.4-0.7
        l0.1,0.2c0.2-0.3,0.5-0.5,0.8-0.5c0.3-0.4,1-0.4,1.2-1c0.1,0,0.2,0,0.3,0c0-0.1,0.1-0.3,0.1-0.3c0.2-0.1,0.3-0.1,0.5-0.2
        c0-0.1,0.1-0.2,0.1-0.3c0.3,0,0.5-0.2,0.7-0.5c0.5-0.4,1.1-0.9,1.4-1.5c0.1,0,0.3-0.1,0.4-0.2c-0.1-0.3,0.1-0.4,0.3-0.5
        c0.7-0.9,1.1-1.9,1.5-3c0.1-0.3,0.3-0.5,0.2-0.8c-0.2,0-0.4,0.2-0.6,0.3c0,0.2,0,0.4,0,0.6c-0.1,0-0.3,0-0.3,0
        c-0.1,0.3-0.3,0.5-0.5,0.7l0.1,0.1c-0.5,0.4-0.8,0.9-1.3,1.3c-0.2,0.6-0.7,1-1.1,1.4c-0.3,0.2-0.4,0.5-0.7,0.6
        c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1,0-0.3,0-0.4-0.1c0,0.1,0,0.2,0.1,0.3c-0.1,0.1-0.2,0.2-0.3,0.2c-0.4,0.2-0.6,0.6-0.9,0.9
        c-0.4,0.3-0.7,0.8-1.2,1.1c1.1-1.3,2.2-2.5,3-4c0.3-0.5,0.8-0.9,0.9-1.5l0.2,0c0,0,0-0.1,0-0.2c-0.3,0.4-0.9,0.6-0.8,1.1
        c-0.1,0-0.2-0.1-0.3-0.1c0,0.1,0,0.2,0,0.2c-0.3,0.2-0.5,0.4-0.8,0.7c-1,0.9-1.9,1.8-3,2.7c0.7-0.6,1.3-1.3,1.9-2
        c0.3-0.5,0.9-0.9,1.2-1.4c0.4-0.5,0.8-1.1,1.3-1.6c0.4-0.3,0.4-0.8,0.7-1.1c0.8-1.1,1.4-2.2,2-3.4c0.2-0.5,0.4-0.9,0.7-1.3
        c-0.1-0.1-0.2-0.2-0.3-0.2c0.1,0,0.3,0,0.4,0.1c0.6-1.2,1.2-2.5,1.9-3.6c0.3-0.5,0.5-1.1,0.9-1.5c0.1-0.2,0.2-0.4,0.2-0.7
        c-0.2,0.1-0.3,0.3-0.5,0.4c-0.1,0.3-0.2,0.6-0.5,0.6c-0.1,0.6-0.6,0.9-1,1.3c-0.1,0.2-0.2,0.5-0.3,0.7c-0.3,0.2-0.7,0.3-1,0.6
        c-0.3,0.3-0.5,0.6-0.7,1c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.3,0.4,0.5,0.5,0.8c-0.4-0.2-0.6-0.7-0.9-1.1c-0.8,0.6-1.4,1.5-2.2,2.1
        c-0.6,0.4-1.1,0.8-1.7,1.2c0-0.3,0.2-0.5,0.3-0.8l0.1,0v-0.2c0.1,0,0.2,0.1,0.3,0.1l0-0.1l-0.2,0c-0.2-0.4,0.6-0.1,0.3-0.6l0.2,0.1
        c-0.2-0.4,0.2-0.6,0.5-0.6c0-0.2-0.1-0.5,0.1-0.6c0-0.1,0-0.3,0-0.4h0.2c0-0.1,0-0.2,0-0.3c0.1,0,0.3,0.1,0.4,0.1
        c0-0.1-0.1-0.2-0.1-0.3c0.2-0.3,0.3-0.6,0.6-0.8c0-0.1,0-0.2,0-0.3c0.1-0.1,0.2-0.2,0.4-0.2l0-0.3c0.1,0,0.2,0,0.3,0
        c0-0.2-0.2-0.5,0-0.7c0,0.2,0.1,0.4,0.1,0.6l0.2-0.1c0-0.1-0.1-0.4-0.1-0.5c0.4,0.2,0.4-0.1,0.3-0.4l0.2,0l0,0.3c0.1,0,0.2,0,0.3,0
        c-0.1-0.1-0.2-0.3-0.3-0.4c0.2-0.1,0.4-0.3,0.7-0.3l0-0.2c-0.1,0-0.4,0-0.5,0c0.1-0.2,0.2-0.3,0.3-0.4c0.2,0.1,0.4,0,0.6-0.2l-0.3,0
        c0.2-0.2,0.3-0.4,0.2-0.7c0.3-0.2,0.3,0.3,0.3,0.5c0,0,0.1,0,0.2,0c0-0.1,0-0.3,0-0.4c-0.2-0.1-0.3-0.2-0.3-0.4
        c0.3-0.4,0.6-0.7,1-0.7c-0.1,0.2-0.1,0.3-0.2,0.5c0.3-0.1,0.2-0.4,0.3-0.6l0.1,0c0-0.2,0-0.4,0-0.6l0.4,0.1c0,0.1,0,0.2-0.1,0.3
        c0,0,0.1,0,0.1,0c0-0.2,0-0.4,0-0.6c0.2-0.2,0.3-0.4,0.5-0.6c-0.3,0-0.4,0.3-0.6,0.4c-0.1-0.2-0.2-0.3-0.4-0.4
        c-0.1,0.5-0.6,0.8-0.7,1.3c-0.5,0.6-0.8,1.3-1.3,1.8c-0.2,0.3-0.4,0.6-0.7,0.8c-0.6,1.2-1.9,1.8-2.6,3c-0.2,0.3-0.4,0.5-0.8,0.4
        c0.2,0.4-0.3,0.6-0.5,0.9c-0.4,0.4-0.8,0.9-1.2,1.4c-0.9,0.6-1.6,1.6-2.4,2.4c0.4-0.4,0.8-0.8,1.1-1.3c0.1-0.2,0.3-0.4,0.4-0.5
        c0.1-0.5,0.8-0.6,0.9-1.1c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.2,0.3-0.4,0.5-0.6c0-0.1,0-0.3,0-0.3c0.1,0,0.2-0.1,0.2-0.1l0.2-0.2l-0.4,0
        c0.1-0.2,0.4-0.2,0.6-0.1c0-0.1,0-0.2,0-0.2c0.1-0.1,0.2-0.2,0.3-0.2l-0.3,0c0-0.3,0.3-0.5,0.6-0.5c0,0,0-0.1,0-0.1
        c-0.1,0-0.2,0-0.3,0l0.1-0.3l0.4,0.1c-0.1-0.1-0.2-0.3-0.3-0.4c0.1,0,0.3,0,0.4,0l0-0.2c0.1,0,0.3,0,0.3,0c0-0.2,0-0.3,0-0.5
        c-0.1,0-0.3,0-0.3,0.1c0.1-0.1,0.3-0.4,0.3-0.5c0.1,0.1,0.2,0.2,0.3,0.3c0.2-0.2-0.1-0.4-0.1-0.6c0.3-0.2,0.5-0.5,0.7-0.7
        c-0.1-0.1-0.2-0.2-0.3-0.2c0.3,0.1,0.4-0.1,0.5-0.3c0-0.1-0.1-0.2-0.2-0.2c0.2,0,0.4,0,0.6,0c0.1-0.3-0.1-0.3-0.4-0.2
        c0-0.2,0.2-0.4,0.4-0.4c0,0-0.1-0.1-0.2-0.2c0.2-0.2,0.2-0.4,0-0.6c0.1-0.1,0.1-0.1,0.2-0.2c0,0.1,0,0.3,0,0.4l0.3,0
        c0-0.1-0.1-0.2-0.1-0.3c0.4,0.1,0.5-0.3,0.4-0.7c-0.1-0.1-0.2-0.2-0.2-0.2c0.2,0.1,0.4,0.1,0.6,0.2c-0.1-0.2-0.3-0.3-0.4-0.5
        c0.2,0.1,0.3,0.1,0.5,0.2c-0.1-0.4,0.3-0.4,0.4-0.7c-0.1-0.1-0.1-0.3-0.2-0.4c0,0,0-0.1,0-0.1c0.2,0.1,0.3,0,0.5,0
        c0-0.1-0.1-0.2-0.2-0.3c-0.1,0-0.2,0-0.3-0.1c0-0.2-0.1-0.3-0.1-0.4C222.5,111.6,222.2,111.2,221.9,111.4 M227.6,111.2
        c-0.1,0.1,0,0.5,0.2,0.4C228,111.4,227.8,111,227.6,111.2 M284.9,111.1c0,0.4,0.2,0.7,0,1.1c0,0,0.1,0.1,0.2,0.2
        c-0.1-0.3,0.5-0.3,0.4-0.5c-0.1,0-0.3,0-0.4,0C285.3,111.6,285.2,111.2,284.9,111.1 M287.3,111.5c-0.1,0.2,0,0.3,0.2,0.3
        C287.6,111.5,287.5,111.4,287.3,111.5 M193.6,111.7C193.7,111.8,193.7,111.8,193.6,111.7 M244.1,111.7c-0.1,0.2,0.2,0.4,0.3,0.2
        C244.5,111.7,244.2,111.5,244.1,111.7 M260.7,111.9C260.8,112,260.8,112,260.7,111.9 M285.3,112.6c0.3,0.1,0.7,0.3,0.5,0.7
        c0.1,0,0.3,0.1,0.4,0.1c-0.1,0.1-0.2,0.2-0.4,0.1c0,0.3,0.2,0.4,0.4,0.5c-0.2-0.8,0.1,0.1,0.4,0c0,0.1,0.1,0.2,0.1,0.3
        c-0.1,0-0.4-0.1-0.5-0.2l0.1,0.3c0.1,0,0.2,0,0.3,0c0,0.2-0.1,0.5,0.2,0.3l0-0.2c0,0,0.1,0,0.1,0c0,0.2,0,0.5,0.2,0.6
        c0,0.2-0.1,0.3-0.1,0.5c0.1-0.1,0.2-0.1,0.3-0.1c0,0.1,0,0.4,0,0.5c0.6-0.2,0.6-0.8,1-1.2c-0.2-0.2-0.3-0.5-0.5-0.7
        c-0.5-0.1-0.9-0.5-1-0.9c-0.3-0.2-0.7-0.3-0.8-0.7c-0.1-0.2-0.4-0.3-0.5-0.4C285.3,112.1,285.3,112.4,285.3,112.6 M270.4,112.4
        c0,0.2-0.1,0.3-0.1,0.5c0.1,0,0.3-0.1,0.4-0.1C270.6,112.7,270.6,112.4,270.4,112.4 M287.4,112.4
        C287.2,112.8,287.9,112.4,287.4,112.4 M226.7,112.8c0-0.1-0.1-0.2-0.1-0.3C226.4,112.5,226.6,112.9,226.7,112.8 M263.9,113.2
        c0.1,0,0.3,0,0.4-0.1c-0.4,0.4-0.5,1.1-1.1,1.3c0.1,0.1,0.1,0.3,0.1,0.4c0.1,0,0.2,0,0.3,0c0.3-0.4,0.3-1,0.5-1.4
        c0.1-0.3,0.2-0.6,0.2-1C264.3,112.7,264.2,113,263.9,113.2 M279.5,112.7c-0.2,0.1-0.3,0.3-0.6,0.2c0,0.1-0.1,0.2-0.1,0.3
        c0.1,0.4-0.4,0.4-0.3,0.8c-0.1,0-0.2,0-0.3,0c0.1,0.4-0.2,0.6-0.4,0.9c0-0.1-0.1-0.3-0.1-0.4c-0.1,0.2-0.1,0.4-0.1,0.6l-0.1,0
        c-0.1,0.1-0.1,0.3-0.2,0.4l-0.2,0v0.2l-0.1,0c0,0.1-0.1,0.2-0.1,0.2l-0.2,0.1c0-0.1-0.1-0.2-0.1-0.3c0,0.1,0.1,0.4,0.1,0.5l-0.2,0
        l0.1,0.3c-0.1,0-0.2-0.1-0.3-0.1c0,0.2,0,0.5-0.3,0.5l0,0.1l-0.1,0l0,0.2l-0.1-0.1c0,0.1-0.1,0.2-0.2,0.3c0,0.2-0.1,0.4-0.3,0.5
        c0,0.1,0.1,0.3,0.1,0.3c-0.1,0-0.3,0-0.3-0.1c0.1,0.4,0.2,0.8,0.6,1.2c0.2-0.4,0.4-0.7,0.8-0.9c0.1-0.3,0.2-0.6,0.3-0.9
        c0.1-0.5,0.6-0.6,0.8-1.1c0.3-0.5,0.8-0.8,1.2-1.2c0.2-0.1,0.2-0.4,0.2-0.7c0.1,0.1,0.2,0.2,0.3,0.3l0.1,0c-0.1-0.1-0.1-0.3-0.2-0.4
        l-0.2,0.1l0.1-0.3c-0.1,0-0.2,0-0.3,0c0-0.1,0.1-0.3,0.1-0.3c0.3,0.2,0.8,0.3,0.7,0.8c0.1-0.1,0.2-0.3,0.3-0.4
        c-0.1,0-0.2,0.1-0.3,0.1c0-0.3-0.2-0.4-0.4-0.6c0.1-0.1,0.2-0.3,0.3-0.4c-0.1-0.2-0.2-0.3-0.2-0.4c0.1-0.1,0.2-0.3,0.4-0.4
        C279.6,112.7,279.5,112.7,279.5,112.7 M243.2,112.9C243.3,113,243.3,113,243.2,112.9 M241.9,113.1c0.2,0.3,0.3,0.6,0.5,0.9
        C242.7,113.6,242.2,113.3,241.9,113.1 M288.3,113.1C288.4,113.2,288.4,113.2,288.3,113.1 M298.5,113.2c0,0.1,0,0.3,0,0.4
        c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1,0.1,0.3,0.1,0.5c-0.2,0.3-0.5,0.5-0.5,0.9c-0.1-0.1-0.2-0.2-0.3-0.2c0,0.2,0.1,0.3,0.1,0.5
        c-0.1,0.1-0.1,0.3-0.2,0.4c-0.2,0.1-0.3,0.3-0.3,0.5c-0.1,0-0.2,0.1-0.2,0.1c0.2,0.5-0.4,0.7-0.4,1.1c-0.1,0-0.2-0.2-0.4-0.1
        c0.1,0.2,0.1,0.3,0.2,0.5c-0.1,0.2-0.3,0.3-0.6,0.4c0,0.1,0.1,0.2,0.1,0.3c-0.3,0-0.3,0.2-0.2,0.4c-0.2,0.1-0.4,0.3-0.5,0.5
        c-0.2,0.1-0.2,0.4-0.4,0.5c0.1,0.3-0.2,0.3-0.4,0.4c-0.1,0.2-0.2,0.3-0.2,0.5c-0.1,0-0.2,0-0.3,0c0,0.1,0.1,0.2,0.1,0.3
        c-0.2,0.1-0.3,0.1-0.5,0.2c0,0.1-0.1,0.2-0.1,0.3c-0.3,0.1-0.4,0.5-0.5,0.7c-0.5,0.5-0.9,1.2-1.4,1.8c0.1,0.5,0.7,0.7,0.9,1.1
        c-0.4-0.3-0.8-0.5-1.1-0.8c-0.1,0.2-0.3,0.4-0.4,0.6c-0.2,0.2-0.4,0.4-0.6,0.6c0.1-0.4,0.5-0.6,0.5-1.1c0.1,0,0.2,0,0.3,0
        c-0.2-0.4-0.7-0.7-1.1-1c-1-0.7-1.9-1.5-2.9-2.3c-0.2-0.1-0.2-0.4-0.1-0.6c-0.7-0.2-1.4-0.6-1.7-1.3c-0.1,0-0.3-0.1-0.4-0.1
        c-0.2-0.2,0-0.5-0.2-0.7c-0.1,0-0.3-0.1-0.4-0.1c-0.5-0.6-0.9-1.3-1.4-2c-0.1,0-0.3,0-0.4,0c0.2,0.1,0.1,0.3,0,0.5
        c0,0,0.1,0.1,0.1,0.1c0-0.2,0-0.3,0.1-0.5c0,0.4,0.5,0.7,0.3,1c0,0.1,0.1,0.2,0.1,0.3c0.1,0,0.3-0.1,0.4-0.1c0,0.2,0,0.4,0,0.6
        c0.2,0.2,0.5,0.5,0.6,0.8c0,0.2,0.2,0.3,0.3,0.4c-0.1,0-0.3-0.1-0.3-0.1c0.1,0.3,0.4,0.4,0.4,0.8c0.1-0.2,0.2-0.3,0.2-0.5
        c0,0.1,0.1,0.2,0.1,0.3c-0.2,0.3-0.1,0.6,0.1,0.8c-0.1,0.2-0.4,0.5-0.1,0.7c0.1-0.2,0.1-0.6,0.4-0.7c0,0.1,0,0.4,0,0.5
        c0.3,0,0.4,0.2,0.6,0.3c0,0.1,0,0.3,0,0.4l0.2,0c0,0,0,0.1,0,0.1c-0.1,0-0.3,0.1-0.4,0.1c0.3,0.2,0.8,0.3,0.7,0.8
        c0.1,0,0.2,0.1,0.4,0.2c0.1,0.2-0.1,0.4-0.2,0.6c0.1-0.1,0.3-0.3,0.4-0.4c-0.1,0.3,0,0.5,0.3,0.6c0,0.1,0.1,0.3,0.1,0.4
        c0.1-0.1,0.2-0.2,0.4-0.4c0,0.3-0.1,0.6-0.1,0.8c0.2-0.1,0.3-0.3,0.4-0.5c0,0.2,0,0.5-0.1,0.7c0.3,0,0.5,0.1,0.5,0.4
        c0,0,0.1-0.1,0.2-0.2c0,0.1,0.1,0.3,0.2,0.4l-0.3,0c0.1,0,0.2,0,0.2,0l0.1,0.2c0.4,0,0.6,0.3,0.6,0.7c0.1,0,0.3-0.1,0.4-0.1
        c0,0.1-0.1,0.3-0.1,0.4l0.2-0.1l-0.1,0.2c0,0,0.1,0,0.2-0.1c0,0.1,0,0.2,0.1,0.3c0.2,0.2,0.5,0.5,0.7,0.7c0.1-0.1,0.2-0.2,0.2-0.3
        c-0.3,0.5,0.3,0.5,0.6,0.7c0.2,0.1,0.4,0.3,0.5-0.1c0,0.1,0,0.3,0,0.4c0.1-0.1,0.1-0.3,0.2-0.4c0-0.1-0.1-0.2-0.2-0.3l0.3,0.1
        c-0.1-0.3,0-0.5,0.3-0.5c0-0.1-0.1-0.2-0.1-0.2c0.2,0,0.2-0.3,0.2-0.5c0.1,0,0.2,0,0.2,0c0.1-0.1,0.2-0.3,0.2-0.4
        c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.2,0,0.2,0c0-0.2,0.1-0.3,0.3-0.3c0-0.1,0-0.3-0.1-0.3c0.1,0,0.3,0,0.4,0c-0.1-0.1-0.2-0.2-0.2-0.2
        c0.2-0.3,0.4-0.6,0.5-0.9c0.1,0,0.2,0,0.2,0c-0.1,0-0.2,0-0.2,0c0.2-0.4,0.4-0.7,0.7-1c0.2-0.2,0.2-0.4,0.2-0.7
        c0.2-0.2,0.2-0.4,0.1-0.6l0.1,0l0.1,0.2c0.6,0.2-0.3-0.6,0.4-0.6c0-0.1,0-0.3,0-0.3c0.2-0.1,0.3-0.3,0.2-0.5c0.1,0,0.2-0.1,0.3-0.2
        c0-0.1,0-0.4-0.1-0.5c0.1,0,0.3,0.1,0.4,0.1c-0.1-0.2-0.5-0.4-0.2-0.5c0.4-0.1-0.2-0.9,0.3-0.6l0.1,0.2c0-0.1,0.1-0.1,0.2-0.2
        l-0.2,0c0.2-0.4,0.2-0.8,0.2-1.2c0.1,0.1,0.2,0.3,0.3,0.5c0-0.1,0.1-0.2,0.2-0.2l-0.2,0c0-0.3,0-0.7,0.3-0.9c-0.1-0.2-0.2-0.4,0-0.5
        c0.3-0.2,0.1-0.5,0.1-0.8c0.2,0.1,0.3,0.1,0.5,0.2c-0.3-0.2-0.3-0.5-0.1-0.7l0.2,0.1c0-0.1-0.1-0.2-0.1-0.3c0-0.4,0.3-0.8,0.1-1.1
        c0.1,0,0.2-0.1,0.3-0.1c0-0.1-0.1-0.2-0.2-0.3c0.2-0.3,0.1-0.7,0.3-1C298.7,113.1,298.6,113.1,298.5,113.2 M222.7,113.8
        c0.1,0.1,0.4-0.1,0.4-0.2C222.9,113.5,222.6,113.6,222.7,113.8 M239.8,113.5c-0.1,0.2,0,0.4,0.2,0.4
        C240.2,113.9,240,113.4,239.8,113.5 M261.7,113.5C261.7,113.6,261.7,113.6,261.7,113.5 M267.3,113.5c-0.2,0.6-0.1,1.3-0.4,1.9
        c-0.3-0.2-0.3-0.4-0.1-0.6l0-0.1c-0.1,0.2-0.4,0.3-0.2,0.6c0.2,0.2-0.1,0.3-0.2,0.5c0.1,0,0.3,0,0.3,0c-0.1,0.1-0.3,0.2-0.4,0.2
        c0,0.1,0.1,0.4,0.1,0.5c-0.2,0.2-0.6,0.2-0.5,0.5c-0.1,0-0.3,0.1-0.4,0.1l0.3,0.1c-0.2,0.1-0.4,0.5-0.7,0.4c0,0.4-0.4,0.6-0.7,0.8
        c-0.1,0.3-0.3,0.5-0.4,0.8c0.1,0,0.3,0,0.3,0c0,0.1,0.1,0.4,0.2,0.5c-0.1,0.1-0.2,0.2-0.3,0.3c0,0.1,0.1,0.2,0.1,0.3
        c0.1-0.2,0.2-0.3,0.3-0.4c0.2-0.5,0.5-1,0.8-1.5c0.1-0.2,0.1-0.4,0.2-0.5c0.4-0.4,0.5-1,0.9-1.3c0.2-0.3,0.4-0.6,0.5-0.9
        c0.2-0.2,0.6-0.5,0.2-0.7c0.1-0.1,0.3-0.2,0.4-0.3c-0.2-0.1-0.4-0.2-0.5-0.5c0-0.1,0.1-0.2,0.1-0.3
        C267.4,113.5,267.3,113.5,267.3,113.5 M413,113.5c-0.1,0.1-0.3,0.4,0,0.5C413,113.9,413.1,113.5,413,113.5 M302.6,114.1
        c0.2,0.2,0.1,0.4,0.1,0.7c0.1,0.3,0.4,0.6,0.1,0.8c0.1,0,0.3-0.1,0.4-0.1c-0.2,0.3-0.5,0.6-0.3,1c0.2,0,0.2-0.1,0-0.2
        c0-0.1,0-0.2,0.1-0.3h0.2l0.2-0.2c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0.1-0.1,0.1-0.2c-0.2-0.1-0.4-0.4-0.6-0.1c0-0.1,0.1-0.4,0.1-0.6
        c0.1,0,0.2,0,0.3-0.1c0.2-0.4-0.4-0.1-0.5-0.1c0.1-0.1,0.2-0.2,0.3-0.3c-0.2-0.2-0.3-0.5-0.5-0.7c0-0.1,0-0.3,0-0.4
        C302.7,113.7,302.7,113.9,302.6,114.1 M256.3,114.4c0.1,0,0.3,0.1,0.4,0.1c-0.1-0.2-0.2-0.3-0.2-0.5c0.1,0.1,0.3,0.2,0.4,0.2
        c-0.1,0.4-0.2,0.9,0.1,1.3c0.2,0.2,0.4,0.4,0.6,0.6c0.5,0.1,0.6,0.8,1.1,0.9c0,0.1,0,0.2,0,0.2c0.1,0,0.2,0,0.3,0
        c-0.1,0.3,0.1,0.5,0.4,0.5c0.1,0.1,0.1,0.3,0.2,0.4l0.2,0c0,0.1,0,0.2,0,0.2c0.3,0.2,0.8,0.5,0.6,0.9c0.1-0.1,0.2-0.2,0.2-0.3
        c0.1,0.2,0.3,0.3,0.4,0.5c-0.1,0.1-0.2,0.2-0.2,0.2c0.1-0.1,0.3-0.2,0.3-0.2c-0.2,0.5,0.8,0.5,0.5,1c0.3-0.2,0.3,0.1,0.4,0.3
        c-0.1,0-0.3,0-0.4,0c0.3,0.1,0.6,0.2,0.9,0.3c-0.1,0.1-0.3,0.2-0.4,0.3c0.2-0.1,0.4,0,0.4,0.2l0,0.1c0,0,0,0.1,0,0.1
        c0.1,0,0.2,0.1,0.2,0.1c0.1,0.1,0.2,0.2,0.2,0.3c0-0.1-0.1-0.4-0.1-0.5c0.1,0.1,0.3,0.2,0.4,0.3c0,0.1-0.1,0.2-0.1,0.2
        c0.5-0.1,0.6,0.9,1.1,0.5c0,0.1-0.1,0.3-0.1,0.4c0.1,0.1,0.3,0.1,0.4,0.3c0.1,0.1,0.3,0.3,0.3,0.5l0.2,0l-0.1,0.2
        c0.3,0,0.5,0.4,0.7,0.6c0.1,0,0.2,0,0.3,0c0.1,0.1,0.2,0.2,0.3,0.3c0.4,0.2,0.7,0.6,1,1c-0.3-0.2-0.6-0.4-0.9-0.7
        c-0.2-0.1-0.3-0.2-0.5-0.3c-0.5-0.3-0.9-0.7-1.4-1c-0.3-0.3-0.8-0.6-1-1.1h0l0,0.2c-0.1,0-0.3-0.1-0.4-0.2l0-0.1l-0.1,0
        c0,0.1,0,0.4,0,0.5c0.1,0.1,0.1,0.2,0.2,0.3l-0.1-0.3c0.2,0,0.4,0,0.6,0c0,0.2,0.1,0.3,0.2,0.5c0,0.2-0.1,0.3-0.1,0.5
        c0.1,0,0.2,0.1,0.2,0.2c0-0.1-0.1-0.3-0.1-0.4c0.4,0,0.3,0.6,0.7,0.6c-0.2,0.1-0.4,0.1-0.5,0c0.1,0.1,0.2,0.1,0.4,0.2
        c0,0.2,0.1,0.4,0.1,0.6l0,0c0-0.2,0-0.4,0.2-0.6c0.1,0.3,0.3,0.5,0.6,0.7c-0.1,0-0.2,0.1-0.3,0.1c0.1,0,0.3,0,0.4-0.1
        c0,0.1,0,0.3-0.1,0.3c0.1,0,0.2-0.1,0.3-0.1c-0.1,0.3,0.2,0.4,0.4,0.5c0,0.1,0.1,0.2,0.2,0.3c0.2,0.2,0.3,0.4,0.5,0.6
        c0.3,0.1,0.4,0.3,0.6,0.5c0.5,0.3,0.9,0.6,1.3,1c0-0.3,0.3-0.7,0-1c-0.2-0.2-0.4-0.2-0.5,0.1c0-0.1,0-0.3,0-0.3c0.1,0,0.3,0,0.3,0
        c0-0.3-0.1-0.5-0.3-0.6c-0.2-0.2-0.1-0.5-0.2-0.7c-0.1,0-0.2,0-0.2,0c0.2-0.4-0.2-0.7-0.4-1c0.1-0.5,0-1,0-1.4
        c-0.1,0-0.2-0.1-0.2-0.2c0.4,0,0.1-0.4,0-0.5c-0.7-0.4-1.4-0.9-2-1.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.2-0.3,0.4-0.4,0.7
        c-0.1-0.1-0.1-0.1-0.2-0.2c0.1-0.3,0.3-0.6,0.4-0.8c0.2,0,0.4-0.4,0.1-0.4c-0.1,0.1-0.3,0.2-0.4,0.4c0.1-0.1,0.1-0.3,0.2-0.4
        c-0.2-0.3-0.5-0.5-0.9-0.6c-0.3-0.3-0.8-0.5-1.2-0.7c-0.1-0.4-0.7-0.3-0.6-0.8c-0.7-0.1-1-0.9-1.6-1c0-0.4-0.4-0.4-0.6-0.6
        c0.1-0.5-0.5-0.6-0.7-0.9c-0.5-0.7-1-1.5-1.5-2.2c-0.1-0.3-0.2-0.6-0.4-0.8C256.3,113.8,256.4,114.2,256.3,114.4 M333.2,113.8
        C333.3,113.9,333.3,113.9,333.2,113.8 M260,113.9c0.3,0,0.4,0.8,0.5,0.3c0.3,0.2,0.3,0.5,0.4,0.8c0.1,0,0.2,0.1,0.2,0.1
        c0.1,0.2,0.2,0.3,0.2,0.5c0,0,0.1,0.1,0.1,0.2c0.1-0.1,0.2-0.3,0.3-0.4c0-0.2-0.1-0.3-0.1-0.5c0.1,0,0.3,0,0.3,0.1
        c0.1,0.4,0.2,0.8,0.3,1.1l0.1-0.3l0,0c0.1,0.2,0.2,0.4,0.4,0.6c0.2-0.4,0.4-0.7,0.6-1c-0.2-0.1-0.3-0.2-0.5-0.3
        c-0.2-0.1-0.4-0.2-0.6-0.3c-0.1-0.5-0.7-0.4-1-0.5c0,0.3,0.1,0.5,0.4,0.7c0,0.1-0.1,0.5-0.3,0.3c-0.2-0.2-0.3-0.5-0.5-0.7l0.2,0
        c-0.1-0.2-0.3-0.5-0.2-0.7c0,0-0.1,0-0.2,0l0.1,0.4C260.5,114.2,260.2,114.1,260,113.9 M234.2,114.4c-0.1,0.2,0.2,0.5,0.3,0.3
        C234.5,114.4,234.4,114.4,234.2,114.4 M101.7,114.5c-0.1,0.2,0,0.3,0.2,0.2C102,114.5,101.9,114.4,101.7,114.5 M263,114.5
        C263,114.6,263,114.6,263,114.5 M238.2,114.6c0,0.1,0,0.3,0,0.4c-0.4,0.1-0.4,0.7-0.6,1c0,0,0.1,0,0.2,0c0,0.2,0.1,0.4,0.3,0.5
        c0.2-0.5,0.5-0.9,0.8-1.4C238.6,114.9,238.4,114.7,238.2,114.6 M138.2,114.9c-0.1,0.2,0,0.3,0.2,0.2
        C138.5,114.9,138.4,114.8,138.2,114.9 M221.7,115L221.7,115L221.7,115L221.7,115L221.7,115 M245.7,115.1
        C245.8,115.2,245.8,115.2,245.7,115.1 M224.4,115.8c0.2,0.1,0.4-0.2,0.4-0.4C224.5,115.3,224.2,115.6,224.4,115.8 M245.4,115.7
        c0-0.1,0.1-0.2,0.1-0.3C245.4,115.2,245.2,115.7,245.4,115.7 M255.9,115.8c0.3,0.1,0.5-0.4,0.2-0.6
        C255.9,115.3,255.8,115.7,255.9,115.8 M274,115.5c0.1,0.2,0.2,0.3,0.3,0.5c-0.1,0.2,0,0.5,0.1,0.7c0.2-0.2,0.3-0.5,0.5-0.7
        c-0.3,0-0.8,0-0.8-0.5C274.1,115.5,274.1,115.5,274,115.5 M229.4,115.7c-0.1,0.2,0,0.3,0.2,0.3c0,0.2,0.3,0.5-0.1,0.6
        c0,0.1,0,0.2,0,0.2l-0.2,0c-0.3,0.6,0.3,0,0.4,0.5c-0.1,0-0.4,0-0.6,0c0.1,0.1,0.2,0.3,0.2,0.4c-0.1,0-0.3,0-0.4,0
        c-0.1,0.1,0.2,0.3,0,0.4c-0.1,0.2-0.3,0.3-0.3,0.6c-0.3,0-0.2,0.3-0.2,0.5l-0.2-0.1l0.1,0.2l-0.2-0.1c0,0.1,0,0.3,0,0.5
        c-0.1,0-0.3,0-0.4,0c0.1,0.1,0.3,0.1,0.4,0.2c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.2-0.2,0.4-0.4,0.5c0-0.1,0.1-0.3,0.1-0.4
        c-0.3,0.2-0.1,0.5-0.1,0.7c-0.5-0.4-0.2,0.3-0.5,0.4c0,0.1,0.1,0.2,0.1,0.2c-0.2,0-0.3,0.1-0.5,0.1c0.1,0,0.3,0,0.4,0
        c-0.2,0.3-0.5,0.5-0.7,0.7c0.1,0,0.2,0.1,0.3,0.1l-0.2,0l-0.1,0.3c0-0.2,0-0.4,0-0.7c0-0.1,0.1-0.2,0.2-0.3c-0.2,0.3-0.2,0.7-0.4,1
        c-0.1,0-0.2,0.1-0.3,0.2c0.1,0.5-0.4,0.9-0.8,1c0.1,0,0.4,0,0.5,0c-0.1,0.2-0.3,0.3-0.4,0.5c0,0.3,0,0.7,0,1l0.1,0
        c0,0.3,0,0.5,0,0.8c0.3-0.4,0.6-0.8,0.9-1.2c0.1-0.5,0.5-0.9,0.6-1.4c0.1-0.3,0.3-0.7,0.6-0.9c-0.1-0.2-0.1-0.4,0-0.6
        c0.1,0,0.3,0,0.3,0c0.1-0.3,0-0.7,0.3-0.8c0.2-0.1,0.3-0.4,0.3-0.6c0.3-0.1-0.2-0.7,0.4-0.3c0-0.1-0.1-0.3-0.2-0.4
        c0,0,0.1,0.1,0.2,0.2c0.2-0.2,0.3-0.3,0.5-0.5c-0.1,0-0.3,0-0.4,0c0.1-0.2,0.4-0.2,0.5-0.5c-0.1,0-0.3-0.1-0.3-0.1h0.2
        c0-0.1-0.1-0.3-0.1-0.4c0.1,0.1,0.2,0.2,0.3,0.2c0.1-0.2,0-0.5,0.2-0.7c-0.1-0.1-0.2-0.2-0.2-0.3c0.1,0.1,0.3,0.2,0.4,0.2
        c0-0.2,0-0.5,0-0.7c-0.1,0-0.2-0.1-0.3-0.1c0.1,0,0.3,0,0.4,0c0.1-0.6,0-1.2-0.1-1.9C229.7,115.7,229.6,115.7,229.4,115.7
        M239,115.7l0.1,0.2l-0.3,0c0.1,0,0.3,0,0.3,0.1c-0.1,0.1-0.3,0.3-0.3,0.4l0.2,0c-0.2,0.3-0.2,0.6-0.3,0.9c0.1,0.2,0.2,0.3,0.3,0.5
        c-0.2,0.1-0.3,0.2-0.5,0.3c0,0,0.1,0.1,0.1,0.2l0-0.2c0.1,0,0.2,0,0.3,0c0.1-0.2,0.1-0.3,0.2-0.5c0.4-0.1,0.4-0.4,0-0.5
        c0.1-0.1,0.2-0.2,0.3-0.3c0.2,0.1,0.3,0.2,0.2,0.4c0,0,0.1,0,0.1-0.1c-0.1-0.2-0.2-0.5-0.2-0.7c-0.2,0.2-0.3,0.3-0.4,0.5
        c-0.2-0.3,0.3-0.4,0-0.7l0.1-0.2l-0.2-0.1l0-0.1C239.1,115.6,239,115.7,239,115.7 M233.1,115.8c0.1,0.1,0.2,0.2,0.3,0.3
        c0,0.1-0.2,0.3-0.2,0.5c0.2,0.1,0.3,0.4,0.5,0.5c0.1,0.1,0.3,0.4,0.4,0c0.1,0.5,0.7,0.7,0.9,1.1c0.2,0.3,0.4,0.5,0.6,0.8
        c0.2,0.3-0.2,0.4-0.3,0.5c0.1,0,0.2,0.1,0.2,0.1l-0.1-0.1c0.1,0,0.4,0,0.5,0c0,0.2,0.1,0.3,0.1,0.5c0.1,0,0.3-0.1,0.4-0.1
        c0,0.1,0.1,0.2,0.1,0.3l-0.2,0c0,0.2,0,0.4,0.1,0.6c0.2-0.1,0.1-0.3,0-0.4c0.1-0.2,0.3-0.3,0.3-0.5c-0.1-0.4-0.7-0.5-0.8-1
        c-0.6-0.4-0.9-1.1-1.5-1.5c-0.2-0.2-0.3-0.5-0.5-0.8c0,0.1,0,0.4,0,0.5c-0.2-0.1-0.2-0.3-0.3-0.5c0-0.3-0.3-0.6-0.4-0.9
        C233.2,115.8,233.1,115.8,233.1,115.8 M263.6,115.8L263.6,115.8c-0.3,0.3-0.6,0.5-0.8,0.8c-0.1,0-0.2,0-0.3,0
        c0.2,0.3,0.4,0.5,0.6,0.8c-0.1,0.2-0.2,0.4-0.3,0.6c0.1-0.2,0.2-0.3,0.3-0.5c0.3-0.6,0.7-1.1,1-1.7
        C263.9,115.8,263.7,115.8,263.6,115.8 M256,116.2c0.2,0.3,0,0.6-0.1,0.9c-0.1,0.3-0.4,0.6-0.8,0.4c0.2,0.3-0.1,0.5-0.3,0.7
        c0.2-0.1,0.7-0.2,0.4-0.6c0.2,0,0.5,0,0.6,0c-0.1,0.1-0.3,0.3-0.4,0.4c0.1,0,0.2,0,0.2,0c-0.1,0.1-0.4,0.2-0.3,0.5
        c-0.1,0-0.2,0-0.3,0c0,0.3,0.5,0.4,0.5,0.7c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0-0.2,0-0.2-0.1c0,0.1,0,0.2,0,0.3c0,0,0.1,0,0.2,0
        c0.1,0.1,0.1,0.2,0.2,0.4l-0.2,0c0,0.4-0.1,0.9,0.1,1.3c-0.1,0-0.2-0.1-0.3-0.1c0,0.3-0.5,0.5,0.1,0.5l0-0.3l0.2,0
        c-0.1,0.2-0.1,0.4-0.2,0.6c0.1,0.3,0,0.7,0.5,0.8c0,0,0,0.1-0.1,0.1c-0.1,0-0.3-0.1-0.4-0.2c0.1,0.2,0.1,0.3,0.2,0.5
        c-0.1,0.3-0.1,0.4,0.3,0.4c-0.1,0.2-0.2,0.4-0.2,0.6c0.1-0.1,0.2-0.2,0.3-0.3c0,0.2,0.2,0.4,0.4,0.5c-0.1,0.1-0.1,0.3-0.2,0.4
        c0.1,0,0.3,0,0.4,0l0,0.3c-0.1,0-0.3-0.1-0.4-0.1c0,0.1,0.1,0.3,0.2,0.4c-0.1,0-0.2,0.1-0.3,0.1c0.1,0,0.3,0,0.4,0
        c-0.1,0.1-0.2,0.3-0.2,0.3c0.1,0.1,0.2,0.2,0.2,0.2c0.1-0.1,0.3-0.2,0.3-0.2c-0.1,0.2-0.1,0.5-0.2,0.7c0.3,0.2,0.5,0.5,0.9,0.4
        c-0.1,0.2-0.2,0.4-0.3,0.7l0.3,0c-0.1,0.1-0.2,0.3-0.3,0.4c0.3,0,0.3-0.2,0.5-0.4l-0.2,0c0.1-0.6,0.7,0,0.4,0.3
        c0.4-0.2,0.5,0.5,0.1,0.4c0.1-0.4-0.4-0.3-0.4,0c0.2-0.1,0.3,0,0.4,0.1c0.1,0,0.2-0.1,0.2-0.2c0,0.1,0,0.4,0,0.5l0.2-0.2
        c0.2,0.2,0.5,0.3,0.7,0.6c-0.2,0-0.3-0.1-0.5-0.1c-0.1,0.3,0.2,0.4,0.4,0.4c0,0.2-0.1,0.5-0.2,0.7c0.3,0,0.2-0.4,0.4-0.5l0,0.4
        c0.1,0,0.2,0,0.2-0.1c0,0.2,0.1,0.3,0.2,0.5c-0.1-0.1-0.3-0.2-0.4-0.3c0,0.1,0,0.4,0.1,0.5c0.1,0,0.3,0,0.4,0l0,0.2l0.1,0
        c0.1,0.1,0.1,0.2,0.3,0.3c0,0.3,0,0.5,0.2,0.7c0.1-0.1,0.3-0.2,0.4-0.2c0,0.1,0,0.3,0,0.4c-0.1-0.1-0.3-0.1-0.4-0.2
        c0,0.1,0,0.2,0,0.3c0.1,0,0.2,0,0.4,0.1c0,0,0.1-0.1,0.2-0.1c0.5,0,0,0.3-0.1,0.3c0.2,0.1,0.5,0.3,0.3,0.5c-0.5,0.3,0.2,0.4,0.4,0.4
        l0,0.2c0.2-0.1,0.2-0.3,0.3-0.5c0.4,0.2-0.2,0.6,0,0.9c0.1,0,0.3-0.1,0.5-0.1l-0.3-0.1l-0.1-0.2c0.1,0,0.4,0.1,0.5,0.1
        c0,0.3-0.1,0.6-0.2,0.9c0.2,0,0.3,0,0.5,0c0.1,0,0.2-0.1,0.2-0.1c0,0.1,0.1,0.4,0.2,0.5c-0.2,0-0.4-0.1-0.5-0.2
        c-0.1,0.3,0.3,0.3,0.5,0.4c0,0.1,0.1,0.3,0.1,0.3c0.2,0,0.4,0,0.6,0.1c0.1,0.2-0.1,0.4-0.3,0.4c0.1,0.1,0.2,0.3,0.2,0.4
        c0.1-0.1,0.2-0.3,0.3-0.3c0.1,0.1,0.1,0.2,0.2,0.3c-0.1,0-0.2,0-0.3-0.1l0,0.2c0.3,0.1,0.6,0.2,0.7,0.5c-0.2,0-0.7-0.1-0.8,0.2
        c0.2,0,0.3,0,0.5,0c0.1,0.2,0.2,0.5,0.5,0.4c-0.2,0.2-0.2,0.5,0.2,0.4c0,0.1-0.1,0.2-0.1,0.3c0.3,0.1,0.4,0.4,0.6,0.6
        c-0.2-0.1-0.4-0.2-0.6-0.4c0.1,0.3,0.4,0.6,0.5,0.9c0.2,0.2,0.3,0.6,0.6,0.5c0.1-0.3-0.3-0.3-0.4-0.5c-0.1-0.1-0.2-0.4,0-0.5
        c0.2,0.3,0.4,0.6,0.6,0.9c-0.1,0.1-0.3,0.2-0.4,0.3c0.1,0.1,0.3,0.2,0.4,0.4c0.1-0.1,0.3-0.2,0.3-0.2c0,0.1-0.1,0.2-0.1,0.2
        l-0.2,0.1c0.1,0.2,0.3,0.4,0.2,0.6l0.1-0.1c0,0.1,0.1,0.2,0.2,0.3c-0.1,0.1-0.3,0.2-0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2
        c0.1-0.1,0.2-0.3,0.2-0.4c0.1,0.3,0.2,0.5,0.4,0.7c-0.3,0.1-0.1,0.4,0,0.6c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0.1,0.2,0.2
        c0,0.1,0,0.2,0,0.3c-0.3-0.1-0.5-0.2-0.7-0.4c0.1,0.2,0.1,0.3,0.2,0.5c0.3,0,0.7,0.1,0.8,0.5c-0.2,0-0.5-0.1-0.7-0.2l0,0.3l0.2,0
        c0,0.2,0,0.3,0.1,0.5l0.2-0.1c0,0.1,0.1,0.3,0.1,0.4c-0.1,0-0.3,0.1-0.4,0.1c0.1,0.2,0.2,0.1,0.3,0c0.3-0.2,0.3,0.2,0.4,0.4
        c0.1,0,0.2,0.1,0.3,0.1c-0.2,0.1-0.3,0.2-0.3,0.4c0.4-0.4,0.6,0.3,0.8,0.6c-0.2-0.1-0.4-0.2-0.6-0.3c0.2,0.2,0.5,0.6,0.9,0.5
        c0,0.2,0.1,0.4-0.1,0.5l0-0.4c-0.2,0.1-0.3,0.3-0.4,0.4c0.1,0,0.3,0,0.4,0c0,0.1-0.1,0.2-0.2,0.3l0.2,0c0,0.1-0.1,0.3-0.1,0.5
        c0.1,0,0.2-0.1,0.3-0.1l0,0.2c0.1,0,0.2,0,0.3,0c-0.4,0.1-0.2,0.4,0.1,0.6c0-0.1,0-0.2,0-0.3c0,0.2,0.3,0.5,0.1,0.7
        c-0.1-0.3-0.3-0.4-0.5-0.1c0.1,0,0.3,0.1,0.5,0.1l0,0.1c0.1,0.1,0.2,0.3,0.2,0.5c-0.1-0.1-0.3-0.2-0.4-0.2c0.1,0.2,0.2,0.6,0.6,0.5
        c0,0.1-0.1,0.3-0.1,0.4c0.1,0,0.3-0.1,0.4-0.1c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.3,0.4,0.1,0.5,0.4c-0.1,0-0.3,0-0.4,0.1
        c0.1,0.1,0.2,0.2,0.3,0.4c-0.4,0.2-0.3,1.1,0.3,0.9c0,0.3-0.1,0.4-0.4,0.4c0,0.1,0,0.2,0,0.3c0.1-0.1,0.2-0.2,0.4-0.4
        c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0-0.3,0-0.3,0c0.1,0.3,0.1,0.9,0.6,0.9c-0.1,0.2-0.1,0.4-0.2,0.6c-0.2,0.1-0.4-0.1-0.5-0.1
        c0,0.1,0.1,0.2,0.3,0.2c0.4-0.1,0.5,0.4,0.5,0.7c-0.1,0-0.4-0.1-0.5-0.1l0.1-0.2l-0.1,0c0,0.1-0.1,0.2-0.1,0.3c0.1,0,0.3,0,0.4,0.1
        l-0.2,0.1c0.1,0,0.4-0.1,0.5-0.1l-0.2,0.4l0.1-0.1c-0.1,0.3,0.2,0.4,0.4,0.4c0,0.1,0.1,0.4,0.1,0.6c-0.5-0.2-1-0.4-1.4-0.7
        c-0.2-0.1-0.3-0.2-0.2-0.4c-0.1,0-0.2,0.1-0.3,0.1l0-0.2c-0.2,0-0.5,0.1-0.6-0.2c-0.1,0-0.2,0-0.3,0.1l0-0.2c-0.3,0.1-0.5,0-0.8-0.1
        l0-0.1c-0.2,0-0.3-0.1-0.4-0.2c0,0.1,0,0.2,0,0.2c-0.2-0.2-0.4-0.2-0.7-0.2c0-0.1,0-0.2,0-0.3c-0.1,0-0.2,0.1-0.4,0.1
        c-0.3-0.1-0.6-0.2-0.9-0.3c-0.5,0-1-0.2-1.6-0.3c-0.1-0.1-0.1-0.2-0.2-0.3l0,0.2c-1.6-0.2-3.1-0.1-4.7,0.1c-0.3,0.2-0.7,0.2-1,0.3
        l-0.1,0.2c0-0.1-0.1-0.3-0.1-0.4c-0.2,0.1-0.7-0.1-0.7,0.2c0.1,0.1,0.3,0.1,0.5,0.3c-0.2,0-0.4,0-0.6,0c0-0.1-0.1-0.2-0.1-0.3
        l-0.1-0.1c0,0.2-0.1,0.4-0.2,0.6c0-0.3-0.1-0.4-0.4-0.2c0-0.1-0.1-0.2-0.1-0.3c-0.1,0-0.3,0.1-0.4,0.2c0-0.1,0-0.3,0-0.3
        c-0.2,0.1-0.4,0.4-0.7,0.3c0.1,0.4,0.4,0.4,0.8,0.3c0,0.1-0.1,0.2-0.2,0.2c-0.3-0.1-0.7-0.1-0.9-0.3l-0.3,0.1
        c0.1,0.1,0.2,0.3,0.2,0.5c-0.2-0.1-0.3-0.3-0.4-0.5c0,0.2,0,0.4,0,0.6c0-0.2-0.2-0.3-0.3-0.4c0.1-0.1,0.2-0.2,0.2-0.3
        c-0.2,0.2-0.8,0.2-0.8,0.6c-0.2-0.1-0.4-0.2-0.6-0.3c0.1,0.2,0.2,0.3,0.4,0.5c-0.1-0.1-0.3-0.2-0.4-0.2c-0.3,0.1-0.1,0.4-0.3,0.5
        c0-0.1,0-0.3,0-0.4l-0.2,0c0,0.2,0,0.5-0.1,0.8c0.2,0.3,0.4,0.7,0.4,1c0.1,1,0.9,1.7,0.8,2.7c0.5,0.3,0.5,0.9,0.8,1.4
        c0.3,0.2,0.1,0.7,0.5,0.9c0.2,0.2,0.3,0.5,0.3,0.7c0.2,0.3,0.6,0.5,0.5,0.9c0.2,0.1,0.3,0.2,0.5,0.3c0,0.2,0,0.4,0,0.6
        c0.2,0.2,0.5,0.2,0.7,0.3c0.3,0.4,0.6,0.8,0.8,1.3c0.1-0.1,0.2-0.2,0.3-0.2c-0.1,0.1-0.2,0.2-0.3,0.4c0.5,0.3,0.9,0.9,1.3,1.3
        c0.1-0.1,0.3-0.1,0.4-0.2c-0.1,0.1-0.2,0.3-0.3,0.4c0.7,0.3,0.8,1,1.3,1.4c0.1-0.1,0.2-0.2,0.3-0.2c0,0.1,0,0.3-0.1,0.4
        c0.3,0.4,0.5,0.8,0.9,1c0.2,0.3,0.4,0.6,0.8,0.8c0.2,0.1,0.2,0.3,0.3,0.5c0.2,0.2,0.4,0.4,0.4,0.7c0.3,0.2,0.4,0.5,0.5,0.8
        c0.3,0.3,0.5,0.7,0.7,1.1c0.2,0.1,0.4,0.1,0.5,0.2c-0.1,0-0.3,0.1-0.5,0.1l0.2,0.3c0.1,0,0.3,0,0.4,0c-0.1,0.1-0.1,0.3-0.2,0.4
        c0.1,0.2,0.1,0.5,0.3,0.6c0-0.1,0.1-0.2,0.2-0.3c0.1,0,0.3,0.1,0.4,0.2c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2-0.1-0.3-0.1
        c-0.1,0.3,0.2,0.5,0.4,0.6c-0.2,0.1-0.2,0.2-0.1,0.4c-0.1,0.3,0,0.5,0.1,0.7c0.1,0,0.2,0.1,0.4,0.1c0,0.4-0.1,0.7-0.3,1
        c0.2-0.2,0.4-0.3,0.6-0.4c0-0.2,0-0.4,0-0.6c0.1,0.1,0.1,0.3,0.2,0.5c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0.2,0.2,0.4,0.1,0.6l0.2-0.1
        c0,0.3,0,0.5,0,0.8c0.2,0.2,0.1,0.5,0.1,0.8c0.4,0.1,0.3,0.6,0.3,0.9l0.2,0c0.1,0.4,0.1,0.9,0.3,1.3c0.1,0.1,0,0.2-0.1,0.4
        c0-0.1,0-0.2,0.1-0.3c-0.4-0.4-0.5-0.9-0.9-1.4c-0.3-0.7-0.8-1.1-1.1-1.8c-0.4-0.3-0.7-0.9-1.1-1c0,0.1,0,0.2,0,0.3
        c-0.1,0-0.2,0.1-0.2,0.1c0.1-0.2,0.2-0.4,0.1-0.6c-0.1-0.1-0.2-0.2-0.3-0.2c0-0.1-0.1-0.2-0.1-0.3c-0.1,0-0.2-0.1-0.2-0.1
        c0,0.2,0,0.3,0,0.5c0.2,0.1,0.3,0.2,0.4,0.3c-0.1,0-0.3-0.1-0.4-0.1c-0.4,0,0.1,0.2,0.2,0.3c0,0.1,0,0.3,0,0.4
        c-0.4,0.2-0.3,0.7-0.5,1c0,0.1-0.1,0.2-0.1,0.4c0.3,0.2,0.1,0.4-0.1,0.5c0,0.2,0.1,0.4,0.3,0.6c0-0.2-0.2-0.6,0.1-0.8
        c0,0.1,0,0.3,0,0.4c0.1-0.1,0.2-0.3,0.2-0.4c0.1,0,0.2,0,0.2,0c-0.2,0.4-0.7,0.8-0.8,1.3l-0.2-0.1c0.1-0.1,0.2-0.2,0.2-0.2V181
        c-0.2,0.1-0.2,0.3-0.3,0.5c0.1,0.2,0.3,0.4,0.1,0.7c0.1,0,0.3-0.1,0.5-0.1c-0.1,0.2-0.3,0.3-0.4,0.4c-0.1-0.1-0.2-0.2-0.3-0.3
        c0,0.1,0.1,0.3,0.1,0.4l-0.2-0.1c0,0.1,0.1,0.2,0.1,0.3c0.2,0,0.6,0.1,0.7-0.2l0,0.2c-0.2,0-0.4,0-0.5,0c0,0.1-0.1,0.4-0.2,0.6
        c-0.1-0.1-0.2-0.3-0.3-0.4l0.1,0.3c-0.1,0-0.2-0.1-0.3-0.1l0,0c0.2,0.1,0.2,0.3,0.3,0.5c-0.3,0.2-0.4,0.6-0.6,0.8
        c0,0.1,0.3,0.1,0.3,0.3c0.1,0.3-0.4,0.2-0.6,0.3c0.1,0.4,1-0.3,0.4,0.3c-0.1,0.2-0.4,0.4-0.5,0c0,0.1-0.1,0.2-0.2,0.2l0.2,0
        c0,0.3,0,0.9,0.5,0.7l-0.1-0.3c0.1,0,0.2,0,0.3,0c0-0.2,0-0.3,0.1-0.4c0,0.2,0,0.3,0,0.5l-0.3-0.1c0.1,0.1,0.2,0.3,0.2,0.4
        c-0.3,0.1-0.4,0.3-0.3,0.6c-0.1,0-0.2,0-0.3,0c0.1,0.1,0.1,0.3,0.1,0.5c-0.2-0.1-0.3-0.3-0.4-0.5c0.3,0,0.6-0.4,0.1-0.5l-0.1,0.2
        c-0.2,0-0.4,0.1-0.5,0.3c0.3,0,0.5,0.2,0.2,0.5c0.3,0.1,0.2,0.5,0.3,0.7c-0.3-0.1-0.4,0.1-0.3,0.4c-0.1,0.1-0.2,0.2-0.3,0.3
        c0.1-0.3,0.3-0.5,0.3-0.8c0.1,0,0.2,0,0.3,0c-0.1-0.1-0.3-0.2-0.4-0.4c-0.1,0.2-0.3,0.2-0.5,0.1c0,0.1,0,0.2-0.1,0.2
        c0.1,0,0.3-0.1,0.5-0.1c0,0.3-0.2,0.5-0.5,0.4c0.1,0.3,0.3,0.2,0.5,0.1c-0.1,0.2-0.3,0.5,0.1,0.6c-0.1,0-0.2,0-0.2,0
        c0,0.2,0.1,0.4-0.1,0.6c0-0.1,0.1-0.3,0.1-0.4c-0.4-0.1-0.6,0.4-0.3,0.7c-0.1,0-0.3-0.1-0.4-0.2c0,0.1,0.1,0.3,0.1,0.4
        c0.2,0,0.4-0.1,0.5-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.2,0-0.4,0.1-0.5,0.3c0.1,0.1,0.2,0.2,0.3,0.3l0.1-0.2c0.1,0,0.2,0.1,0.3,0.1
        c-0.2,0.1-0.3,0.2-0.5,0.3l0-0.2c-0.1,0-0.2,0.1-0.3,0.1c0.1,0.3-0.1,0.4-0.4,0.5c0.1,0,0.2,0.1,0.2,0.2c0.1-0.1,0.3-0.2,0.5-0.3
        c-0.2,0.3-0.1,0.6-0.3,0.9c0-0.2-0.1-0.4-0.3-0.6c0,0.3-0.2,0.5-0.4,0.8c0.1-0.1,0.3-0.1,0.4-0.2c0,0.2,0.1,0.4,0.1,0.6
        c0,0-0.1,0-0.2,0c0-0.1,0-0.2,0-0.3l-0.2,0c0,0.3,0.1,0.5,0.1,0.8c0,0-0.1,0-0.1,0c0-0.2-0.1-0.5-0.2-0.7c-0.2,0.2-0.3,0.4-0.1,0.6
        c-0.1,0-0.2,0.1-0.3,0.1c0,0.1-0.1,0.3-0.1,0.4l-0.1,0c0,0.3-0.1,0.5-0.2,0.7c0.1,0.1,0.2,0.3,0.2,0.4c-0.2,0-0.3-0.1-0.5-0.1l0,0.2
        c-0.1,0.1-0.2,0.3-0.3,0.4c0.2-0.1,0.4-0.2,0.6-0.2c-0.1,0.4-0.7,0.3-0.8,0.8c0,0.1,0,0.2,0,0.3c-0.2,0.3-0.3,0.5-0.5,0.8
        c-0.1,0.2-0.4,0.6,0,0.7c-0.1,0-0.3,0-0.4-0.1c-0.1,0.2,0.1,0.8-0.3,0.6c0,0.1,0,0.3,0,0.4c-0.1-0.1-0.2-0.3-0.3-0.3
        c-0.2,0.1-0.1,0.3-0.2,0.5c-0.4-0.1-0.5,0.4-0.8,0.7l0.3,0c-0.4,0.3,0,0.7-0.3,1c-0.1-0.2-0.1-0.3-0.2-0.5c0-0.1,0-0.3,0-0.5
        c-0.1,0.1-0.2,0.3-0.4,0.4c0,0.1,0.1,0.2,0.2,0.3c-0.1,0.3-0.2,0.6-0.4,0.9c0.1,0,0.2,0.1,0.3,0.2c-0.1,0-0.3,0-0.4,0
        c0.1,0.1,0.2,0.2,0.2,0.2c-0.1,0-0.3,0-0.4,0c0-0.2,0.1-0.3,0.4-0.4c-0.1-0.1-0.2-0.2-0.3-0.2c0.1,0,0.3-0.1,0.3-0.2
        c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0.2-0.2,0.3-0.3,0.5l0.3,0c-0.1,0.2-0.2,0.4-0.3,0.6c-0.2,0-0.8-0.2-0.7,0.3c0.1,0,0.2-0.1,0.3-0.2
        c0.2,0.1,0.6,0,0.6,0.3c-0.2,0-0.5,0-0.7,0c-0.2,0.1,0.1,0.4,0.1,0.6c-0.3-0.1-0.5-0.1-0.7-0.2c0,0.1,0,0.3-0.1,0.3
        c0.2-0.2,0.4-0.2,0.6-0.1l0,0.2c-0.5,0.2-0.9,0.5-1.2,0.9c0.2-0.1,0.4-0.1,0.6-0.2c-0.3,0.3-0.6,0.5-1,0.6c-0.1,0.2-0.2,0.3-0.3,0.5
        c0.2-0.1,0.5-0.2,0.6-0.5c0.1,0.1,0.2,0.2,0.4,0.2c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0-0.2,0.1-0.3,0.1c0.1-0.2,0.2-0.3,0.3-0.5
        c-0.5-0.1-0.6,0.4-0.7,0.8l0.3,0c-0.2,0.2-0.4,0.3-0.5,0.6l0,0c0-0.2,0-0.4,0-0.6c0,0,0.1,0,0.2,0c0-0.1,0-0.4,0-0.6
        c-0.3,0.2-0.6,0.4-0.7,0.8c0.1,0,0.3,0,0.4-0.1c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0.1,0.1,0.2,0.2,0.2c-0.2,0.4-0.4,0.7-0.7,1
        c-0.1-0.1-0.1-0.2-0.1-0.3c0.3-0.1,0.7-0.4,0.4-0.8l-0.1,0.4c-0.3,0-0.2-0.3-0.3-0.5c0,0.2-0.1,0.4-0.1,0.7l-0.2,0
        c0.1,0.1,0.1,0.3,0.2,0.4c-0.2,0.2-0.3,0.4-0.6,0.4c0.1-0.1,0.3-0.3,0.4-0.4c-0.1-0.1-0.2-0.1-0.2-0.2c-0.2,0.4-0.3,0.9-0.7,1.2
        l-0.1-0.1l-0.1,0c0.1,0.1,0.1,0.2,0.2,0.4c-0.2,0.1-0.4,0.3-0.5,0.6c-0.1,0.1-0.3,0.3-0.4,0.5l0.5,0c-0.2,0.1-0.5,0.1-0.7-0.1
        c-0.3,0-0.5,0.2-0.5,0.5l0.2,0c0-0.1,0-0.2,0-0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0.1,0.4,0.2c-0.2,0.1-0.6,0.3-0.5-0.1l-0.1,0
        c0,0.4-0.4,0.4-0.7,0.6c-0.2,0.3-0.5,0.4-0.7,0.6c0.2,0,0.4,0,0.7-0.1l0,0.2c-0.2,0-0.4,0.1-0.5,0.1c0,0.1,0.1,0.3,0.1,0.4l-0.2,0
        c0-0.1,0-0.4,0-0.6l-0.3-0.1c0,0.2,0,0.4,0,0.7c-0.1-0.1-0.2-0.2-0.3-0.2l0.2-0.1c0-0.2,0-0.4,0-0.6c-0.2,0.4-0.4,0.8-0.3,1.2l0.2,0
        c0,0.1,0,0.2,0,0.2c-0.1-0.1-0.3-0.2-0.4-0.3c0,0.1,0,0.3-0.1,0.4c0,0.1,0.1,0.2,0.2,0.3c-0.1-0.1-0.3-0.2-0.4-0.2l0.3-0.1
        c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1,0.4-0.4,0.5-0.7,0.6c0.1,0.1,0.2,0.1,0.3,0.2c-0.2,0.1-0.4,0.2-0.6,0.3c0.1,0.1,0.2,0.1,0.3,0.1
        c0.1,0,0.2-0.1,0.2-0.1c0.1,0.5-0.5,0.2-0.8,0.3c0-0.1,0-0.2,0-0.3c-0.2,0.2-0.3,0.5-0.4,0.7c0,0-0.1,0-0.2,0
        c0.2,0.4-0.3,0.5-0.5,0.7c0.1,0.1,0.3,0.2,0.5,0.2c-0.4,0-0.6,0.4-0.9,0.6c-0.1,0-0.2,0-0.3,0.1c0-0.1,0-0.3,0-0.4
        c-0.2,0.1-0.4,0.2-0.5,0.5c0.6-0.2,0,0.7,0,0.1c-0.4,0.2-0.6,0.6-1.1,0.8c0,0.2-0.1,0.4-0.3,0.5c0.2-0.4-0.3-0.3-0.4-0.4
        c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0.1-0.3,0.3-0.4,0.4c0-0.1-0.1-0.2-0.2-0.3c-0.1,0.1-0.2,0.2-0.3,0.4c0.1,0.2,0.4,0.3,0.6,0.1
        c-0.1,0.2-0.1,0.5-0.4,0.4c0,0,0,0.1,0,0.2c-0.1-0.1-0.2-0.3-0.3-0.4c0.1,0.7-0.4,0.4-0.5,0.1c0,0.2-0.1,0.4-0.3,0.5
        c0.1,0,0.2,0.1,0.2,0.2c-0.2,0.1-0.5,0-0.7,0.2c-0.2,0.1-0.3,0.2-0.5,0.3c0,0.1,0.1,0.3,0.2,0.4c0-0.1-0.1-0.2-0.2-0.2
        c-0.1,0.2-0.2,0.4-0.4,0.5c0-0.1,0-0.3,0-0.4c-0.2,0.1-0.2,0.3-0.2,0.4c-0.2,0.1-0.3,0.2-0.5,0.2c0-0.2,0.1-0.3,0.1-0.5
        c-0.2,0.1-0.3,0.2-0.5,0.2c0.1,0.1,0.2,0.2,0.4,0.3c-0.3,0.1-0.6,0.3-0.9,0.3c-0.4,0.3-0.9,0.8-1.4,0.7l0.1,0.2
        c-0.1,0-0.4,0.1-0.5,0.2c0-0.1,0-0.2,0-0.2l-0.1,0l0,0.2c-0.3,0-0.4,0.1-0.4,0.4c-0.5-0.1-0.8,0.4-1.4,0.4c0,0.1-0.1,0.2-0.1,0.3
        c-0.3,0.1-0.5,0.1-0.8,0.2c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0-0.3,0-0.4-0.1c-0.2,0-0.5,0.1-0.7,0c0,0.1,0.1,0.3,0.1,0.3
        c-0.3,0.1-0.6-0.2-0.8-0.2c0,0.1,0.1,0.3,0.2,0.4c-0.1,0-0.4,0.1-0.5,0.2c0-0.1,0.1-0.4,0.1-0.5c-0.3,0.1-0.3,0.4-0.6,0.5
        c0-0.1-0.1-0.2-0.2-0.3c0,0.1,0.1,0.3,0.1,0.4c-0.4-0.1-0.8-0.1-1.1,0.1c0,0.1,0,0.3,0,0.5c-0.1-0.1-0.2-0.3-0.4-0.4
        c0,0.4-0.3,0.6-0.6,0.5c0.2-0.2,0.5-0.3,0.5-0.6c-0.3,0.1-0.6,0.4-1,0.3l0,0.2c0.1,0,0.2,0,0.3,0c-0.1,0.1-0.2,0.3-0.3,0.1
        c-0.2-0.2-0.3,0.1-0.5,0.2c0-0.1,0-0.3,0-0.4c-0.2,0-0.5,0.1-0.7,0.1c0,0.1,0,0.3,0,0.4c-0.1-0.1-0.1-0.3-0.2-0.4
        c-0.3,0-0.5,0.1-0.5,0.4c0.2,0,0.5-0.1,0.6,0.2c-0.2-0.1-0.4-0.1-0.7-0.2c0-0.1,0.1-0.3,0.1-0.4c-0.3,0-0.6,0.1-0.9,0.3
        c0.2,0,0.4,0.1,0.6,0.1c-0.1,0-0.3,0-0.4,0c0,0,0,0.1-0.1,0.2c-0.1-0.2-0.2-0.4-0.3-0.5c-0.2,0.2-0.4,0.3-0.7,0.5l-0.1-0.2l0.3,0
        c0-0.3-0.4-0.1-0.6-0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3-0.1-0.5-0.1-0.7,0.1l0.1-0.3c0,0-0.1,0-0.2,0l0,0.3c-0.1,0-0.4-0.1-0.5-0.1
        l0.1-0.2c-0.1,0-0.2,0-0.3,0c-0.8-0.2-1.6-0.1-2.4-0.4c-0.3-0.2-0.8-0.1-1.1-0.2c0,0.1,0.1,0.3,0.1,0.4c-0.1-0.2-0.2-0.4-0.3-0.5
        c-0.2,0.1-0.3,0.2-0.4,0.4c0-0.1,0.1-0.3,0.1-0.4c-0.4,0.2-0.8-0.2-1-0.4c-0.2,0-0.4,0-0.5,0c0.1,0.2,0.3,0.3,0.4,0.5
        c0-0.1-0.1-0.2-0.1-0.3c0.4,0.2,0.6,0.6,1,0.8c0,0.3,0.3,0.2,0.5,0.2c-0.1,0.1-0.2,0.2-0.3,0.2l0,0c0.2,0,0.5-0.1,0.7,0.1
        c-0.1,0-0.3,0.1-0.4,0.2c0.1,0,0.3-0.1,0.4-0.1c0.2,0.2,0.7,0.5,0.8,0.1c-0.2,0.3,0.2,0.4,0.4,0.6c0-0.1,0-0.3,0-0.3
        c0,0.1,0,0.3,0,0.4c0.3,0.2,0.6,0.4,1,0.5c0,0.1,0.1,0.2,0.2,0.2c0.3,0.2,0.7,0.1,0.9,0.5c0.1-0.1,0.2-0.2,0.2-0.2
        c0.2,0.1,0.3,0.3,0.6,0.3l0,0.1c0.1,0,0.2-0.1,0.3-0.1l0,0.2c0.2-0.2,0.4-0.2,0.6-0.2l0,0.4c0-0.1,0.1-0.2,0.1-0.2
        c0.1,0,0.4,0,0.5,0l-0.1,0.2c0.1-0.1,0.3-0.2,0.4-0.3l-0.1,0.3l0.1,0c0.1-0.1,0.2-0.3,0.2-0.4l0.1,0.3c0.3-0.2,0.7-0.2,1.1-0.3
        c-0.1,0.1-0.2,0.3-0.2,0.3c0.2-0.1,0.4-0.2,0.6-0.4c0.1,0.2,0.2,0.2,0.4,0c0.1-0.1,0.2-0.1,0.4-0.2c0.2,0.1,0.5,0,0.6-0.1
        c0.1,0,0.2,0.1,0.3,0.2c0-0.1,0.1-0.3,0.1-0.3c0.2,0,0.5,0,0.6-0.1c0.4-0.1,0.7-0.1,1.1,0c0.1-0.1,0.2-0.3,0.2-0.4
        c0.1,0.1,0.1,0.2,0.2,0.3c0.1-0.1,0.5-0.3,0.2-0.5c0.2-0.1,0.4,0.1,0.6,0.1c0-0.1,0-0.2,0-0.3c0.2,0,0.4,0.3,0.6,0.4
        c0-0.1,0-0.3,0-0.5l0.3,0.1c-0.1-0.1-0.2-0.2-0.2-0.2c0.2-0.1,0.3-0.1,0.5,0c-0.1-0.1-0.3-0.2-0.4-0.3c0.5,0.2,1-0.2,1.5-0.2
        c0.1-0.3,0.3-0.3,0.5,0c0.1-0.1,0.1-0.2,0.2-0.2l-0.3,0l0-0.3c0.6,0.6,0.9-0.7,1.6-0.3c-0.1-0.1-0.2-0.3-0.3-0.4
        c0.1,0,0.3,0.1,0.4,0.1c0-0.1,0-0.3-0.1-0.4c0.1,0.1,0.3,0.3,0.3,0.4c0-0.1,0-0.4-0.1-0.5c0.3-0.1,0.5,0.1,0.7,0.2
        c0-0.1-0.1-0.2-0.1-0.3c0.2,0,0.3,0,0.5,0c0-0.1-0.1-0.2-0.1-0.3l0.2,0c0.1-0.1,0.1-0.3,0.2-0.4c0.1,0,0.2,0.1,0.3,0.1
        c0.2-0.3,0.5-0.4,0.8-0.5l-0.2-0.1c0-0.1,0.1-0.3,0.1-0.4c0.1,0,0.2,0.1,0.4,0.1c0-0.1,0.1-0.2,0.1-0.3c0.2-0.1,0.3-0.1,0.5-0.2
        c0.2-0.4,0.6,0.2,0.6-0.2c0.1,0.1,0.3,0.1,0.4,0.2c-0.1-0.2-0.3-0.3-0.4-0.5l-0.2,0.1c0,0,0-0.1,0-0.2c0.1,0,0.3,0,0.4,0
        c0-0.2,0.3-0.3,0.5-0.4c0,0,0.1,0.1,0.2,0.2c0.4-0.1,0.6-0.5,1-0.6c0.1-0.1,0.1-0.2,0.2-0.4c0.3-0.1,0.8-0.4,0.6-0.8
        c-0.2,0.2-0.2,0.6-0.6,0.7c-0.1-0.1-0.1-0.1-0.2-0.2c0.1-0.2,0.3-0.3,0.4-0.5c0.1,0,0.2-0.1,0.3-0.1c0.2-0.2,0.4-0.4,0.5-0.6
        c0.1,0,0.2,0,0.3,0c0,0.1,0,0.3,0,0.4l0.2-0.1c0-0.1,0-0.3,0-0.4c0.1,0.3,0.3,0.2,0.5,0.1c0-0.1,0-0.3,0-0.4c-0.1,0-0.3,0-0.4,0
        l0,0.3c0-0.1-0.1-0.2-0.2-0.2c0.3-0.2,0.5-0.5,0.8-0.6c-0.1,0.3-0.3,0.6-0.1,0.9c0.4-0.5,0.3-1.1,0.6-1.6c0,0.1-0.1,0.3-0.1,0.4
        c0.1,0,0.2,0,0.3,0c-0.2-0.4,0.4-0.2,0.3-0.5c-0.1,0-0.3-0.1-0.3-0.1c0.3,0,0.7-0.1,0.9,0.2c0-0.2,0-0.5,0.2-0.6
        c-0.1,0-0.2-0.1-0.3-0.2c0.3-0.2,0.6-0.3,0.9-0.4c0,0.1,0.1,0.2,0.2,0.3c0-0.2,0-0.4,0.2-0.6c-0.1-0.1-0.2-0.2-0.3-0.3
        c0.2,0,0.3-0.1,0.5-0.1l0-0.2c-0.1,0-0.2,0-0.2,0c0,0,0-0.1,0-0.2c0.2,0.1,0.3,0.1,0.5,0.2c-0.1-0.2-0.1-0.4,0-0.6
        c0,0.1,0.1,0.3,0.1,0.4c0.4,0.2,0.3-0.3,0.2-0.5l0.4,0.1c-0.1-0.1-0.2-0.3-0.3-0.4l0.2,0c-0.1-0.1-0.2-0.2-0.2-0.2
        c0.3-0.1,0.5-0.2,0.8-0.2c0-0.2,0-0.4-0.1-0.6l0.1,0l0.1,0.2c0.2-0.2,0.1-0.5,0.5-0.6c0.2,0.3-0.1,0.5-0.3,0.7c0,0,0.1,0.1,0.2,0.1
        c0.1-0.2,0.3-0.4,0.5-0.5c-0.1-0.1-0.2-0.2-0.2-0.4c0.7,0.3,0.2-0.4,0.1-0.6c0.2,0.1,0.5,0.2,0.7,0.2c0-0.1,0-0.2,0-0.3
        c-0.1-0.2-0.2-0.4-0.1-0.6l0.3,0c0,0.2,0,0.5,0.1,0.7c0.1,0,0.2,0,0.2,0c0-0.2-0.1-0.5-0.1-0.7c0.5,0.3,0.5-0.5,0.9-0.6l-0.1,0.5
        c0,0,0.1,0,0.2,0c0-0.3,0-0.6,0-0.9c-0.1,0.2-0.2,0.4-0.2,0.6c-0.1,0-0.3-0.1-0.4-0.1c0.3-0.3,0.6-0.7,0.9-0.9
        c-0.1,0.2-0.1,0.3-0.2,0.5c0.1,0,0.3,0.1,0.4,0.1c0-0.3,0-0.5,0-0.8c0.2,0,0.4-0.1,0.6-0.1l-0.1-0.1l-0.1-0.1c0-0.1-0.1-0.4,0.2-0.3
        l0.1,0c0,0,0.1,0,0.2-0.1l0.1-0.2l-0.3,0c0.2-0.1,0.3-0.2,0.4-0.4c0.1,0.1,0.2,0.2,0.3,0.3c0-0.1,0-0.3,0-0.4c0.1,0,0.2,0.1,0.2,0.2
        c0.1-0.2,0.2-0.3,0.3-0.5c-0.2,0.1-0.4,0.1-0.5,0.2c0.1-0.2,0.2-0.4,0.3-0.5c0.1,0,0.2-0.1,0.3-0.1c-0.1-0.7,0.9,0.2,0.3-0.5
        c0.1,0,0.2,0,0.3-0.1c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.2,0,0.3,0.1l0-0.2c0.1,0,0.2,0.1,0.2,0.1c0-0.4,0.5-0.5,0.4-0.8
        c0.2,0,0.6,0.4,0.6,0c-0.1,0-0.3,0-0.4,0l0.1-0.3c0.1,0,0.2,0,0.3,0l0-0.1l-0.2,0l0-0.1c0.3,0.1,0.4-0.1,0.5-0.3c0.1,0,0.3,0,0.4,0
        l0.1-0.3c-0.1,0-0.4,0.1-0.6,0.1l0-0.1c0.1,0,0.2,0,0.3,0c0-0.1,0-0.2,0-0.3c0.1,0.1,0.3,0.3,0.4,0.3c0-0.1-0.1-0.4-0.1-0.5
        c0.4,0,0.7-0.4,1.2-0.2c-0.1-0.3-0.3-0.4-0.6-0.3c0.2-0.2,0.5-0.3,0.7-0.5c0,0.1-0.1,0.3-0.1,0.4c0,0,0.1,0,0.2,0
        c0,0-0.1-0.2-0.1-0.2c0.3-0.2,0.6-0.4,0.8-0.6c0,0.2-0.1,0.4-0.2,0.6c0.2-0.2,0.5-0.5,0.5-0.8c0.1,0,0.3,0.1,0.4,0.2
        c0-0.4-0.4-0.4-0.7-0.4c0.1-0.3,0.3-0.2,0.6-0.1c0-0.2,0-0.3,0-0.5c0.1,0.1,0.2,0.3,0.3,0.4c0.1-0.1,0.2-0.3,0.3-0.4
        c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0.6-0.1,1.2,0,1.7-0.3c-0.3-0.1-0.6-0.1-0.8,0l0-0.3c0.4-0.2,0.9-0.2,1.3-0.3
        c0-0.2,0-0.3,0-0.5c0.2,0.1,0.3,0.2,0.5,0.2c0-0.3-0.4-0.4-0.7-0.5c0.1,0,0.4,0,0.5,0l0.1-0.3c0,0.2,0.1,0.4,0.1,0.6
        c0.2-0.2,0.2-0.4,0.1-0.6c0.1-0.1,0.3-0.2,0.3-0.2c0,0,0,0.1,0,0.1l-0.2,0.1c0,0.1,0,0.3-0.1,0.5c0.4-0.1,0.4-0.6,0.7-0.8
        c0,0.1,0,0.4,0,0.5c0.1-0.2,0.3-0.5,0.6-0.4l0-0.1l0.1,0c0-0.1,0-0.2,0-0.2c0.1,0,0.3,0,0.4,0c0.1-0.1,0.3-0.3,0.4-0.4
        c0.3-0.1,0.4-0.5,0.7-0.6c0.2,0.3,0.6,0.1,0.7-0.2c0.1,0,0.2,0,0.2,0c0-0.1,0.1-0.3,0.1-0.4l0.1,0.2c0.1,0,0.3-0.1,0.4-0.2
        c-0.1,0.1-0.2,0.3-0.3,0.4c0.2-0.1,0.3-0.3,0.4-0.4c0.1,0,0.2,0.1,0.2,0.1c0.1-0.2,0.2-0.3,0.3-0.4c0.2,0,0.5,0.2,0.5-0.2
        c0.2,0,0.6,0.2,0.7-0.1c0.1,0,0.2,0,0.2,0.1c0-0.4,0.5-0.2,0.7-0.2c0-0.1,0-0.2,0-0.3c0.1,0,0.3,0.1,0.4,0.2c0-0.1,0.2-0.2,0.2-0.3
        c0.1,0,0.2,0,0.3,0l0-0.2c0.1,0,0.4,0.1,0.5,0.1l0-0.2c0.2,0,0.4,0,0.6,0l0-0.1c0.6,0,1.2-0.2,1.8-0.2c-0.5,0.1-1,0.1-1.4,0.3
        c-0.1,0-0.3,0.1-0.5,0.1c0.2,0.1,0.3,0.3,0.6,0.3c0.2-0.2,0.4-0.3,0.6-0.4c-0.2,0.2,0,0.3,0.1,0.4c-0.1,0.1-0.2,0.2-0.2,0.3
        c0.3,0,0.5-0.1,0.8-0.1c-0.1,0.2-0.2,0.4-0.4,0.6c0.2,0,0.3,0,0.5,0c0-0.4-0.1-0.7-0.2-1c0.3,0.1,0.3,0.4,0.2,0.7l0.4-0.2
        c-0.1,0.2-0.2,0.4-0.2,0.7c0.2-0.1,0.5-0.4,0.7,0c0.3-0.2,0-0.4,0-0.7c0-0.3,0-0.6-0.4-0.6c0-0.1,0.1-0.2,0.1-0.3
        c-0.2,0-0.6,0.1-0.6-0.3c0.2,0,0.5,0.1,0.5-0.2c-0.1,0-0.3,0-0.5,0c0-0.1,0.1-0.2,0.2-0.3c-0.1-0.2-0.2-0.4-0.3-0.6
        c-0.1-0.1-0.4-0.1-0.4-0.4c0.1,0,0.2-0.1,0.3-0.2l-0.1-0.2c0-0.1,0.1-0.2,0.1-0.2c0,0-0.1-0.1-0.2-0.2c0.3-0.4-0.2-0.3-0.3-0.6
        c0.1,0,0.3,0,0.4,0l0-0.2c-0.1,0-0.3,0-0.4,0.1c0,0,0-0.1,0-0.2l0.3,0.1c-0.1-0.3-0.3-0.6-0.2-1c0.1-0.5-0.1-1.1,0-1.6
        c0.1-0.4,0-0.9,0.2-1.3c-0.1-0.2-0.1-0.5-0.1-0.7c0.1-0.4-0.1-0.8,0.1-1.1c-0.1-0.7,0.3-1.4,0.3-2.1c0-0.4,0.1-0.7,0.1-1.1
        c0-0.2,0.1-0.3,0-0.5c0-0.3,0.2-0.7-0.1-0.9c0.1-0.4,0.1-0.8,0.1-1.2c0.1-0.1,0.2-0.2,0.3-0.3c-0.1,0-0.2-0.1-0.3-0.1
        c-0.1-0.3,0.5-0.6,0-0.9c0.3-0.4,0.1-0.9,0-1.3c0.2,0,0.2,0.2,0.3,0.4c0.2-0.2,0-0.4-0.1-0.6c0.1-0.4,0.1-0.7-0.1-1.1
        c0.1,0,0.2,0,0.2,0c-0.2-0.1-0.3-0.3-0.2-0.4c0.1-0.3,0-0.6,0-0.9c0.1,0,0.2,0,0.2,0c-0.1-0.3-0.2-0.6,0.1-0.8
        c-0.2-0.4-0.2-0.7,0.2-1c-0.4-0.4,0-0.8-0.1-1.3c-0.1-0.3,0.1-0.6,0.1-0.8c-0.2-0.3,0.1-0.5,0.1-0.8c-0.1-0.1-0.1-0.3-0.2-0.4
        c0.1,0,0.2-0.1,0.3-0.1c-0.2-0.2-0.3-0.5-0.3-0.7c0.1-0.1,0.2-0.2,0.3-0.2c-0.1-0.4-0.2-0.9-0.1-1.4c0.1,0,0.2-0.1,0.3-0.1
        c-0.1-0.1-0.2-0.2-0.3-0.3c0.1-0.2,0.1-0.3,0.1-0.5c0.1-0.1,0.1-0.2,0.2-0.3c-0.3-0.2-0.1-0.3,0.1-0.4c0-0.1-0.1-0.2-0.1-0.2
        c0.2-0.5-0.1-1,0.1-1.5c0.2-0.3-0.1-0.9,0.3-1.1c-0.1-0.3-0.1-0.6,0.1-0.9c-0.2-0.2-0.2-0.3,0-0.4c-0.1-0.4-0.3-1.2,0.3-1.4
        c-0.1-0.1-0.1-0.2-0.2-0.3c0.1-0.3,0.2-0.6,0.2-0.9c0-0.2-0.2-0.6,0.2-0.6c-0.1-0.1-0.1-0.2-0.2-0.3c0.1-0.1,0.2-0.2,0.2-0.4
        c0.2-0.2-0.2-0.4-0.2-0.6c0.2,0,0.3,0,0.5,0c-0.1-0.2-0.2-0.2-0.4-0.2c0-0.4,0.3-0.8,0.2-1.3c0-0.3-0.2-0.6,0.1-0.9
        c0-0.1-0.1-0.2-0.2-0.2c0,0,0.1-0.1,0.2-0.2c0.1-0.4-0.1-0.8,0.1-1.1c-0.2-0.2-0.2-0.4,0-0.5c0,0-0.1-0.1-0.2-0.2
        c0.1-0.4,0.2-0.7,0.3-1.1c-0.3-0.4,0.2-0.8-0.1-1.2c0.3-0.3-0.1-0.8,0.1-1.1c0.2-0.4,0-0.8-0.1-1.1c0.2,0,0.3,0.1,0.5,0.1
        c-0.1-0.2-0.3-0.3-0.4-0.4c0.1-0.3,0.2-0.6,0.2-1c0-0.5,0.1-0.9,0-1.4c0.1-0.4-0.2-0.8,0.1-1.2c-0.4-0.4-0.2-1-0.4-1.6
        c0-0.4-0.1-0.9,0-1.3c-0.2-0.5-0.1-1.1-0.3-1.6c0-0.6-0.1-1.1-0.2-1.7c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2,0-0.3-0.1-0.5-0.1
        c0,0,0-0.1,0-0.2c0.1,0.1,0.3,0.1,0.4,0.2c0-0.4-0.3-0.7-0.6-1c-0.2-0.1-0.3-0.2-0.5-0.2c0.1,0,0.4,0,0.5,0
        c-0.2-0.3-0.4-0.6-0.6-0.9c-0.1,0-0.3,0.1-0.4,0.1c-0.2-0.3-0.3-0.7-0.4-1c-0.2,0.1-0.3,0.1-0.4,0.2c0.1-0.1,0.1-0.2,0.2-0.3
        c-0.2-0.2-0.3-0.5-0.3-0.8c-0.1-0.1-0.2-0.3-0.4-0.4c0,0.2-0.1,0.5-0.1,0.7c0-0.1,0-0.4,0-0.5c-0.1,0-0.3,0-0.4,0
        c0.1-0.3,0.3-0.1,0.5-0.1l0-0.3c-0.3-0.4-0.7-0.8-0.9-1.3c-0.2-0.2-0.4-0.2-0.6-0.1c0.1-0.3,0.1-0.5,0.2-0.8
        c-0.2-0.2-0.4-0.3-0.6-0.5l0,0.3c-0.2-0.1-0.4-0.1-0.5-0.2c0.1,0,0.3,0,0.5,0c-0.1-0.4-0.3-0.7-0.5-1c-0.3-0.2-0.1-0.7-0.5-0.8
        c0,0.2-0.1,0.4,0,0.6c-0.2-0.1-0.5-0.2-0.7-0.4c-0.4-0.2-0.9-0.4-1.2-0.7c-0.4-0.1-0.6-0.4-1-0.6c-0.2-0.1-0.3-0.2-0.4-0.4
        c-0.1,0-0.2,0-0.3,0c-0.1-0.3-0.3-0.5-0.6-0.4c-0.4-0.4-0.8-0.9-1.3-1.1c-0.4-0.7-1.2-0.8-1.5-1.5c-0.5-0.2-0.7-0.7-1-1.1
        c-0.2-0.1-0.3-0.3-0.4-0.5c-0.5-0.6-0.9-1.4-1.2-2.1c-0.2-0.2-0.3-0.4-0.4-0.6c-0.3-0.5-0.6-1.1-0.8-1.7c-0.2-0.8-0.9-1.3-1.2-2
        C256.3,115.9,256.1,116,256,116.2 M261.2,116.1C261.3,116.2,261.3,116.2,261.2,116.1 M324.2,116.1c-0.2,0.1,0,0.5,0.2,0.4
        c0.1-0.1,0.2-0.2,0.1-0.4C324.5,116.1,324.3,116.1,324.2,116.1 M267.8,116.2c0,0.4-0.2,0.7-0.4,1.1c-0.1,0.4-0.2,0.8-0.6,1
        c0.3,0.1,0.3,0.5-0.1,0.3c0,0.3,0,0.5,0,0.8c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.3-0.3c0.1,0.4-0.1,0.7-0.2,1.1
        c-0.1-0.1-0.2-0.2-0.2-0.2c0,0.1,0.1,0.3,0.2,0.4l-0.3-0.1c0,0.1,0,0.3,0,0.4l-0.1,0c0,0.1-0.1,0.3-0.1,0.3c0,0.2,0,0.4,0,0.6
        l-0.3,0c0.1,0,0.2,0.1,0.4,0.1l0,0.1c0.4-0.1,0.4,0.4,0.1,0.5c0.1,0,0.2-0.1,0.3-0.1c-0.1,0.3,0.3,0.5,0.5,0.2c0,0.2,0,0.4,0.1,0.6
        c0.1,0.1,0.2,0.1,0.3,0.2c0-0.3,0.1-0.6-0.1-0.9c0-0.2,0.1-0.4,0.2-0.5c-0.1-0.1-0.2-0.2-0.2-0.3c0.4-0.8,0-1.8,0.5-2.6
        c0-0.1-0.1-0.2-0.1-0.3c0.1-0.2,0.4-0.5,0.4-0.8c-0.1-0.2,0.1-0.4,0.2-0.7c0.1-0.1,0.2-0.2,0.3-0.4l-0.2,0L267.8,116.2
        C267.8,116.2,267.8,116.2,267.8,116.2 M279,116.2C278.8,116.7,279.5,116.3,279,116.2 M322.3,116.2
        C322.4,116.3,322.4,116.3,322.3,116.2 M239.9,116.4C240,116.4,240,116.4,239.9,116.4 M266.1,116.3
        C266.2,116.4,266.2,116.4,266.1,116.3 M264.4,116.8l-0.1,0c-0.1,0.2-0.1,0.3-0.2,0.5l-0.2,0c0,0.1,0,0.3,0.1,0.4l-0.2-0.2
        c0.1,0.4,0.2,0.8,0.3,1.2c0.2-0.3,0.4-0.6,0.5-1c0.1-0.5,0.4-0.8,0.4-1.3C264.5,116.2,264.4,116.5,264.4,116.8 M279,117
        c0.2,0,0.2-0.2,0.1-0.4C278.9,116.7,278.9,116.8,279,117 M228.7,116.8C228.8,116.9,228.8,116.9,228.7,116.8 M279.3,117.1
        C279.4,117.2,279.4,117.2,279.3,117.1 M287.6,117.1C287.4,117.5,288.1,117.1,287.6,117.1 M138.4,117.4c-0.1,0.2,0.1,0.5,0.3,0.4
        C138.8,117.6,138.6,117.2,138.4,117.4 M278.3,117.8c-0.3,0-0.4,0.5-0.7,0.3c0,0.1,0,0.2,0,0.2c0.1,0,0.2-0.1,0.3-0.2l0.1,0.1
        c-0.3,0.2-0.3,0.7-0.7,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0.2-0.3,0.3-0.5,0.5
        c0.1,0.3,0.3,0.6,0.6,0.7c0.3-0.4,0.5-0.8,0.8-1.2c0.4-0.2,0.5-0.6,0.8-0.9c0.3-0.3,0.6-0.5,0.9-0.9c0.1-0.2,0-0.5-0.3-0.5
        c0.1,0.1,0.2,0.3,0.3,0.4c-0.2,0.1-0.5,0.2-0.5,0.5c-0.4-0.2-0.1-0.6-0.1-0.9C278.6,117.4,278.2,117.5,278.3,117.8 M278.9,117.5
        C278.7,117.9,279.4,117.6,278.9,117.5 M103.4,117.7c0,0.1,0,0.4,0.2,0.4C103.9,117.9,103.7,117.5,103.4,117.7 M286.5,117.6
        c0,0.1,0,0.2,0,0.3c0.1,0.1,0.3,0.2,0.4,0.3l-0.1,0.2c0.3,0.3,0.4,0.8,0.8,1c-0.1-0.2-0.2-0.4,0-0.5c0.2,0,0.3,0.2,0.5,0.3
        c0,0.1,0,0.3,0,0.4c0.2,0,0.5,0,0.7,0.1l-0.2,0.3c0.6-0.2,0.2,0.5,0.5,0.7c0.4,0.3-0.3,0.4-0.1,0.7l0.1-0.3c0.1,0.1,0.3,0.2,0.4,0.3
        c0,0.2,0,0.4,0,0.6c0.2,0,0.6-0.2,0.4,0.2l0.2,0c0,0.1-0.1,0.2-0.1,0.3l0.2,0c0,0.1-0.1,0.2-0.1,0.3c0.2,0,0.3,0.1,0.3,0.3h0.1
        c0,0.1,0,0.2-0.1,0.3l0.2,0l0,0.1c0,0.1,0.1,0.2,0.2,0.2l0.1,0l0,0.1l0.4,0c0-0.1,0-0.2,0-0.3c0.1-0.1,0.2-0.3,0.1-0.5l0.2,0.1
        c0-0.1-0.1-0.3-0.1-0.4l0.4,0c0-0.1-0.1-0.3-0.1-0.3l0.1,0c0-0.1-0.1-0.3-0.1-0.3l0.2,0c0-0.3-0.2-0.4-0.4-0.6
        c-0.3-0.1-0.8-0.2-0.9-0.6c-0.4-0.1-0.7-0.4-1-0.6c-0.2-0.2-0.3-0.4-0.6-0.4c-0.4-0.1-0.6-0.5-1.1-0.5c0-0.1-0.1-0.3-0.1-0.4
        c-0.4,0-0.5-0.4-0.6-0.7c0.1,0.1,0.2,0.4,0.3,0.5c-0.3-0.1-0.7,0.1-0.8-0.4c0-0.1-0.1-0.3,0-0.4
        C286.8,118.1,286.6,117.8,286.5,117.6 M243.6,117.9c0.3,0.3,0.3,0.7,0.6,0.8c-0.1-0.1-0.2-0.2-0.2-0.3c0.2-0.1,0.4-0.2,0.6-0.3
        C244.3,118.1,243.9,118,243.6,117.9 M245.3,118c0,0.1,0,0.3,0,0.4c-0.2,0-0.3,0-0.5,0c-0.1,0.3-0.3,0.7-0.5,1l-0.1-0.5
        c-0.1,0.1-0.1,0.3-0.1,0.5c-0.3,0-0.1,0.3,0.1,0.2c0.2-0.2,0.5-0.4,0.6-0.7c0.3-0.1,0.5-0.5,0.6-0.8l0.2,0l0-0.1
        C245.6,117.9,245.4,118,245.3,118 M283.1,118c-0.1,0.2,0.2,0.4,0.4,0.3C283.6,118.1,283.3,117.9,283.1,118 M114.7,118.3
        c0,0.4,0.4,0.4,0.5,0c0.2,0,0.4-0.1,0.5-0.1C115.5,117.9,115.1,118.1,114.7,118.3 M104.1,118.3c0,0.2,0,0.4,0,0.6
        c0.1,0,0.2-0.1,0.2-0.1c0.2,0.1,0.4,0.2,0.5,0.3c0-0.1,0.1-0.4,0.2-0.5c-0.3,0-0.5,0.1-0.7,0.2l0.2-0.3c-0.1,0-0.2,0-0.2,0
        C104.2,118.6,104.2,118.4,104.1,118.3 M289.1,118.4C289.2,118.4,289.2,118.4,289.1,118.4 M237.8,118.8c0.1,0.3,0,0.5-0.2,0.7
        c0.3-0.1,0.5-0.2,0.7-0.5C238.2,118.9,238,118.8,237.8,118.8 M242.1,119.1c-0.1,0.9-0.1,1.8-0.3,2.6c0.2-0.1,0.5-0.4,0.7-0.2
        c-0.2-0.3,0-0.4,0.2-0.6c-0.1-0.2-0.3-0.3-0.3-0.5c0-0.5-0.1-1-0.3-1.4C242.2,119.1,242.1,119.1,242.1,119.1 M255.3,119
        c-0.2,0-0.3,0.4,0,0.4C255.5,119.4,255.6,119,255.3,119 M185.6,119.2C185.7,119.3,185.7,119.3,185.6,119.2 M104.8,119.4
        c0,0.1,0.3,0.4,0.4,0.2C105.1,119.5,104.8,119.2,104.8,119.4 M409.6,119.6c0.1,0.2,0.4,0,0.3-0.2
        C409.9,119.2,409.6,119.5,409.6,119.6 M139.1,119.5c-0.1,0.2,0.2,0.5,0.4,0.4C139.5,119.7,139.2,119.5,139.1,119.5 M300.5,119.9
        C300.6,120,300.6,120,300.5,119.9 M288.2,120.1C288.3,120.2,288.3,120.2,288.2,120.1 M237.1,120.1c-0.1,0.3-0.2,0.5-0.3,0.8
        c0.3,0.1,0.5,0.3,0.5,0.6c-0.1-0.1-0.3-0.2-0.4-0.3c0.1,0.1,0.2,0.3,0.3,0.4l-0.3,0c0,0,0,0.1,0,0.1c0.1,0,0.2,0,0.3,0
        c0.4-0.3,0.3-0.7,0.5-1.2C237.5,120.4,237.3,120.3,237.1,120.1 M221.4,120.3C221.5,120.4,221.5,120.4,221.4,120.3 M404.5,120.2
        c0.1,0.2,0.3,0.4,0.5,0.5c0.1-0.1,0.2-0.2,0.3-0.3C405,120.3,404.8,120.2,404.5,120.2 M166.6,120.6c-0.2,0.4-0.7,0.4-0.9,0.8
        c0,0.1,0,0.4,0,0.6c0.3-0.1,0.4-0.4,0.2-0.7c0.4,0.1,0.7-0.3,1-0.5C166.8,120.8,166.7,120.7,166.6,120.6 M300.1,120.6
        c-0.1,0.1-0.2,0.2-0.2,0.3c0.2,0.2,0.1,0.7-0.3,0.7c0,0,0,0.1,0,0.1c0.1,0,0.3-0.1,0.3-0.1c0.1,0.4,0,0.5-0.3,0.3
        c0,0.1-0.1,0.2-0.1,0.3c0.1,0,0.3,0,0.5,0c0,0.5,0.5,0.6,0.8,0.9c-0.1-0.2-0.2-0.6-0.5-0.6c0.1-0.1,0.2-0.2,0.4-0.3
        c-0.1,0-0.4,0.1-0.5,0.1c0.1-0.1,0.3-0.4,0.4-0.5c-0.1,0-0.2,0.1-0.2,0.1c0-0.3-0.1-0.5-0.2-0.7l0.3,0l0,0.2l0.2,0
        c-0.2-0.2-0.2-0.4-0.1-0.6c-0.1,0.1-0.2,0.2-0.2,0.2C299.9,121.1,300.1,120.8,300.1,120.6 M406.8,120.6c-0.1,0.2,0,0.3,0.2,0.2
        C407,120.6,407,120.5,406.8,120.6 M279.2,120.9c0.2,0.2,0,0.6-0.3,0.5c0-0.1-0.1-0.2-0.1-0.2c-0.1,0.1-0.1,0.1-0.1,0.2l0,0.1
        c0,0,0,0.1,0,0.2l-0.2,0.1c0-0.1-0.1-0.2-0.2-0.3c0,0.3,0.2,0.6,0.5,0.7c0.1-0.2,0.1-0.4,0.2-0.6c0.1,0,0.3,0.1,0.5,0.1
        c0,0.2-0.1,0.4-0.2,0.5c0.1,0.1,0.3,0.2,0.4,0.3c-0.1-0.2-0.2-0.4-0.3-0.6c0-0.1,0.1-0.2,0.2-0.3c-0.2,0-0.3-0.1-0.3-0.3
        c0.1,0,0.2-0.1,0.3-0.1C279.4,121.2,279.3,121.1,279.2,120.9 M220.9,121.5C220.9,121.5,220.9,121.5,220.9,121.5 M285.6,121.6
        c0,0.1,0,0.3,0,0.4C285.9,122.1,285.9,121.3,285.6,121.6 M220.7,121.9C220.8,122,220.8,122,220.7,121.9 M239.3,121.9
        C239.4,122,239.4,122,239.3,121.9 M278.9,122.4c0-0.1,0-0.2,0-0.3C278.7,122,278.7,122.4,278.9,122.4 M238.9,122.4
        c-0.5-0.1-0.4,0.5-0.3,0.8c0.1,0,0.2-0.1,0.3-0.1c0-0.4,0.2-0.6,0.4-0.9C239.1,122.2,238.9,122.2,238.9,122.4 M299.3,122.2
        c0.1,0.1,0.1,0.3,0.2,0.4c-0.5,0.3-0.3,1-0.6,1.4c-0.1-0.1-0.3-0.2-0.4-0.3c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0.3-0.4,0.6-0.6,0.9
        c-0.1,0.1-0.2,0.3-0.3,0.4c0,0.5-0.5,0.8-0.7,1.3c-0.2,0.4-0.5,0.9-0.8,1.2c0,0.2,0.1,0.6-0.2,0.7c0-0.1,0.1-0.3,0.1-0.4
        c-0.3,0-0.3,0.4-0.4,0.6l-0.2-0.1c0,0.3-0.1,0.6,0,0.9c-0.2,0.1-0.3-0.1-0.4-0.1c0,0.1-0.1,0.4-0.2,0.5l-0.2-0.1
        c0.3,0.3-0.3,0.5-0.3,0.8c-0.1,0-0.2-0.1-0.2-0.1c0.1,0.1,0.2,0.3,0.2,0.4c-0.2,0.1-0.5,0.3-0.5,0.6c-0.1,0-0.2,0-0.3,0l0,0l0.2,0.1
        c-0.1,0.4-0.5,0.8-0.8,1c0,0.1,0,0.3,0,0.4c-0.2,0.1-0.3,0.2-0.5,0.3c0,0.1,0.1,0.2,0.1,0.3l-0.2,0c-0.1,0.1-0.2,0.3-0.3,0.4
        c0,0.1-0.1,0.2-0.1,0.2c-0.1,0.1-0.3,0.2-0.3,0.3c-0.3,0.6-0.8,1.1-1.2,1.6c-0.2,0.3-0.5,0.4-0.4,0.8c-0.1,0-0.3,0-0.4,0
        c0.1-0.2,0.3-0.5,0.5-0.7c0.7-1.1,1.4-2.2,2-3.4c0.3-0.5,0.5-1,0.9-1.4c0.5-1.2,1.1-2.3,1.7-3.4c0-0.2,0.1-0.5,0.3-0.6
        c0.2-0.7,0.6-1.4,0.9-2c0.1-0.5,0.5-0.9,0.6-1.4c-0.2,0.1-0.3,0.3-0.4,0.5c-0.2,0.3-0.4,0.7-0.7,1c-0.2,0.2-0.5,0.4-0.4,0.7
        c-0.1,0.3-0.3,0.5-0.5,0.7c0.1,0.3,0,0.4-0.3,0.3c0,0.1,0,0.3,0,0.4c-0.1,0-0.3-0.1-0.4-0.1c0.1,0.2,0.2,0.6-0.2,0.5
        c-0.1,0.2-0.2,0.5-0.5,0.3c0,0,0.1,0.1,0.2,0.1c0,0.1-0.1,0.3-0.1,0.4c-0.1,0-0.2,0-0.2,0c0,0,0,0.1,0,0.2c-0.1,0.1-0.2,0.2-0.2,0.3
        c-0.1,0-0.2,0-0.2,0c0.3,0.4-0.4,0.6-0.7,0.8c0.1,0.2,0,0.3-0.1,0.4c0,0.3-0.3,0.4-0.5,0.5c-0.1,0.1-0.2,0.3-0.3,0.4
        c0.1,0,0.3,0.1,0.4,0.2c-0.1,0-0.2-0.1-0.3-0.2c-0.4,0.4-0.8,0.8-1.2,1.2c-0.1,0.1-0.2,0.2-0.3,0.3c0.3,0.1,0.5,0.4,0.7,0.6
        c-0.3-0.1-0.4-0.4-0.8-0.5c0,0.1-0.1,0.2-0.1,0.2l-0.2,0c0,0.2,0,0.5-0.1,0.7c0-0.4-0.5-0.6-0.8-0.4c0.1,0,0.3,0.1,0.4,0.1
        c0.1,0.1,0.2,0.2,0.4,0.3l-0.1,0.2c0.1,0,0.4,0,0.5,0c0-0.2,0-0.4,0-0.6c0,0,0.1,0,0.1,0c0,0.2,0,0.4-0.1,0.6
        c-0.2,0-0.3,0.1-0.5,0.1c0,0.3-0.2,0.6-0.3,0.8c0,0,0.1,0.1,0.2,0.1c0.2-0.2,0.5-0.5,0.7-0.7c0,0.2,0,0.6-0.3,0.5c0,0,0,0.1,0,0.2
        c-0.5-0.1-0.2,0.3-0.1,0.5c0.2-0.2,0.4-0.3,0.6-0.5c-0.3,0.2-0.5,0.4-0.7,0.7c0.1,0,0.2,0.1,0.2,0.1l0.1,0.2c0.1,0,0.2,0,0.2-0.1
        c-0.1,0.2-0.3,0.4-0.4,0.6c-0.1,0-0.3-0.1-0.3-0.2c0.1-0.1,0.3-0.2,0.4-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0.4-0.3,0-0.3-0.2
        c-0.4,0-0.1,0.6,0.1,0.5c0,0.1-0.1,0.2-0.1,0.3c0.3,0.1,0.6,0.1,0.9,0.2c-0.1,0.1-0.2,0.3-0.3,0.4c0.2,0.1,0.3,0.2,0.5,0.3
        c-0.2,0.2-0.4,0.1-0.5-0.1c-0.2,0-0.4-0.1-0.6-0.1c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0.1,0.3,0.3,0.4,0.4c-0.1,0-0.3-0.1-0.4-0.1
        c0,0.1-0.1,0.4-0.1,0.5c0.2-0.2,0.5-0.3,0.7-0.5c-0.1,0.3-0.3,0.4-0.5,0.6c0.4,0.1,0.6-0.1,0.7-0.4c-0.1,0.2-0.2,0.3-0.2,0.5
        c-0.1,0-0.3,0-0.4,0c0,0.1,0.1,0.4,0.2,0.5c0.1-0.1,0.2-0.1,0.4-0.2c0.1,0.2,0.2,0.4,0.2,0.6c0.4,0.5,0.6,1.2,0.9,1.9
        c0,0.1,0,0.3,0,0.3l0.2,0c0,0.1,0,0.3-0.1,0.4c0.3,0.1,0.2,0.6-0.1,0.3c0,0,0,0.1,0,0.2c0.2,0,0.4-0.1,0.6-0.2
        c0,0.2-0.1,0.4-0.2,0.6c0.1,0,0.2,0.1,0.3,0.1c0,0.4,0.5,0.8,0,1.1c0-0.1,0-0.4,0-0.6l-0.3-0.1c0.1,0.2,0.1,0.4,0.1,0.6
        c0.3-0.1,0.4,0.2,0.5,0.4l0.2-0.1l-0.1,0.3l0.2-0.1c-0.1,0.1-0.1,0.3-0.2,0.4c0.4-0.1,0.4,0.4,0.6,0.6c-0.2-0.2-0.4-0.4-0.6-0.6
        c0,0.3,0,0.7-0.1,1l0.2,0.1c0-0.1-0.1-0.3-0.1-0.4c0.7-0.2-0.1,0.7,0.4,0.7c-0.1,0.2-0.2,0.3-0.4,0.5c0.1,0.1,0.3,0.2,0.4,0.3
        l0.1-0.2c0.1,0.2,0.3,0.4,0.3,0.7c-0.2-0.1-0.3-0.3-0.3-0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2-0.1-0.4-0.3-0.5-0.4
        c0,0.3,0.3,0.4,0.5,0.7c0.1,0,0.2,0,0.2,0c0,0.3,0.3,0.4,0.5,0.5c-0.1,0.3-0.5,0.7-0.4,0c-0.2,0.2-0.1,0.5,0,0.7
        c0,0-0.1,0.1-0.1,0.2c0,0.1,0.1,0.3,0.1,0.4c-0.2,0.1-0.5,0.2-0.7,0.3c0-0.2,0.1-0.4,0.2-0.5c0-0.1,0-0.3,0-0.4
        c-0.4,0.3-0.6,1.2,0.1,1.2c0,0.3,0.1,0.7-0.2,1c-0.2-0.2-0.2-0.5-0.1-0.8c0,0-0.1,0-0.2,0c-0.1,0.4,0.1,0.8,0.3,1.2
        c0.1,0,0.2-0.1,0.2-0.1c0.1-0.1,0.1-0.3,0.2-0.4c0.1,0,0.3-0.1,0.4-0.1c-0.1,0.3-0.3,0.5-0.6,0.6c0.2,0.1,0.3,0.3,0.5,0.4
        c0,0.2,0,0.3,0,0.5c-0.1-0.1-0.3-0.1-0.4,0c-0.1,0.2,0.1,0.3,0.3,0.4c0,0.2-0.1,0.4-0.1,0.6c-0.1,0-0.2-0.1-0.3-0.2
        c-0.2,0.2-0.2,0.6-0.5,0.7c0.1,0.1,0.2,0.2,0.3,0.2c-0.1,0.2-0.3,0.3-0.2,0.5c-0.1-0.1-0.3-0.2-0.4-0.3c0.1,0.1,0.2,0.2,0.3,0.3
        l-0.1,0.2c-0.1,0-0.2,0-0.2,0c0,0.1,0.1,0.2,0.1,0.2c-0.3,0-0.5,0.3-0.4,0.5c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.2,0.3-0.4,0.6-0.4
        c-0.2,0.3-0.5,0.6-0.6,1c-0.2,0-0.4,0.1-0.5,0.1l0,0.1l0.1,0c0,0.1,0.1,0.3,0.1,0.4c0.1-0.1,0.2-0.3,0.3-0.5
        c0.2-0.2,0.4,0.2,0.6-0.1c0,0.2-0.2,0.2-0.3,0.2c-0.3,0.2-0.1,0.5,0.1,0.7c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1,0.2-0.2,0.5-0.3,0.7
        c-0.1,0.1-0.3,0.2-0.4,0.3c0.2,0.1,0.3,0.2,0.3,0.5l0.1,0l0,0.1c-0.1,0-0.2,0-0.3-0.1c-0.1,0.1-0.2,0.2-0.3,0.3
        c0,0.1,0.1,0.2,0.1,0.2c-0.1,0.2-0.2,0.4-0.4,0.6c0,0.2,0,0.4,0,0.6c-0.1,0.2-0.3,0.4-0.4,0.6c0.1,0,0.2,0,0.3,0l0,0.3
        c0.3-0.1,0.4-0.4,0.4-0.6c0.3,0.3-0.3,0.6-0.4,0.9c-0.1-0.1-0.2-0.3-0.2-0.4c0,0.1-0.1,0.3-0.1,0.5c-0.1,0-0.3,0-0.4,0
        c0.1,0.2,0.3,0.1,0.5,0.1c-0.1,0.2-0.4,0.4-0.6,0.6c0,0.2,0,0.4,0,0.6c0.2,0,0.4-0.1,0.5-0.3c-0.1,0-0.3,0.1-0.4,0.1l-0.1-0.2
        c0.2-0.1,0.3-0.1,0.5-0.2c0,0.1-0.1,0.3-0.1,0.4c0.1,0,0.2,0.1,0.2,0.1c0.3,0,0.5,0.1,0.7,0.3c-0.2,0-0.4,0.1-0.5,0.1l0,0.2
        c-0.1,0-0.3,0-0.4,0c0,0.3-0.2,0.3-0.5,0.3l0,0.1c-0.1,0-0.2,0.1-0.2,0.1c0.1,0,0.4,0,0.5,0c-0.2,0.1-0.3,0.2-0.4,0.4
        c-0.1,0-0.3-0.1-0.3-0.1c0,0.2,0.2,0.4,0.4,0.4c-0.1,0.2-0.7-0.3-0.5,0.3l-0.2,0.3c0.1,0,0.3,0,0.4,0l0,0.1c-0.1,0-0.3,0-0.4,0
        l0.1,0.3c0.3,0.2-0.3,0.5-0.3,0.7l0.3-0.1c0,0.2,0,0.3,0,0.5c-0.3,0-0.4,0.2-0.3,0.5c0.1,0,0.2-0.1,0.3-0.1c0,0.2-0.1,0.6,0.2,0.6
        l0.1,0c0,0.2,0,0.4,0,0.7c0.1,0,0.2,0.1,0.3,0.2l-0.4,0c0,0.1,0.1,0.2,0.1,0.3c-0.3,0-0.5,0-0.8,0c-0.1,0.2-0.2,0.3-0.4,0.3l0,0.1
        c-0.1,0-0.2,0.1-0.3,0.2c0,0.1,0,0.3,0.1,0.4l-0.2-0.2c0,0.2,0,0.5-0.1,0.7c0,0.1,0.3,0.5-0.1,0.4c0,0.1,0,0.3,0.1,0.4
        c-0.1,0.1-0.1,0.3-0.1,0.5c0.3,0.1,0.4,0.3,0.4,0.6c0.4,0,0.4,0.5,0,0.4c0.1,0,0.3,0,0.4,0c0.1,0.4,0.5,0.5,0.8,0.5l-0.4,0l0.1,0.3
        c-0.1-0.1-0.1-0.2-0.2-0.2c-0.2,0.1-0.5,0.5-0.7,0.2c-0.4,0-0.7,0.3-1,0.4c0,0,0,0.1,0.1,0.1l-0.2-0.1c0,0.1,0.1,0.2,0.1,0.3
        c-0.1,0-0.3,0-0.4,0l0,0.2l-0.1,0c0,0-0.1,0.1-0.2,0.2c0,0-0.1,0.1-0.2,0.2c0,0.1,0,0.2,0,0.2c-0.1,0-0.2,0.1-0.3,0.1
        c0,0.1,0.1,0.2,0.2,0.2c-0.1,0.1-0.3,0.2-0.4,0.3c0.1,0,0.2,0,0.3,0c-0.2,0.1-0.3,0.3-0.2,0.5c-0.1,0-0.2-0.1-0.3-0.1
        c0,0.1,0,0.3,0.1,0.4c0.3,0.1,0,0.4,0,0.6l0.3-0.1l-0.1,0.3c0,0,0.1,0,0.2-0.1c-0.1,0.4,0.7,0.2,0.5,0.6c0.1,0,0.3,0.1,0.3,0.1
        c-0.3,0-0.6,0-0.9,0c-0.1,0.1-0.3,0.2-0.4,0.3c0-0.2,0-0.3,0-0.5l0.2,0c-0.1,0-0.2-0.1-0.4-0.1l0.1,0.3c-0.3-0.1-0.5-0.1-0.8-0.2
        c0.1-0.1,0.3-0.1,0.5-0.2c-0.2-0.1-0.6-0.3-0.5,0.2l-0.1,0c0.1,0.3-0.2,0.5-0.3,0.7c0.2-0.1,0.5-0.2,0.6-0.4c0,0,0.1,0.1,0.1,0.2
        c0.2,0,0.4,0.1,0.5,0.3c-0.3,0-0.4,0.2-0.6,0.3c0.1,0.2,0.1,0.4,0.2,0.6l0.2-0.1c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.3,0.3-0.4,0.4
        l0.4-0.1c-0.1,0-0.2,0.1-0.3,0.1c0,0.4,0,0.9,0.1,1.3c-0.1,0-0.2-0.1-0.3-0.2c0.1,0.2,0.2,0.4,0.4,0.4l0,0.2c-0.2,0-0.3,0.1-0.5,0.2
        c0.2,0,0.5,0,0.6,0c-0.1,0.1-0.3,0.2-0.3,0.3c0.2,0,0.4,0.2,0.6,0.3c-0.4,0.1-0.7-0.3-1.1-0.2c-0.1,0.1-0.2,0.2-0.3,0.3l0,0.1
        c0.1,0,0.3-0.1,0.4-0.1l-0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0,0.1-0.1,0.2-0.1,0.3l-0.1,0c0,0.1,0.1,0.3,0.2,0.5c0,0.3,0,0.5,0,0.8
        c0.2,0.1,0.3,0.3,0.4,0.5c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3-0.1-0.4-0.1c0-0.1,0-0.2,0-0.3c0,0-0.1,0-0.1,0c0.1,0.3-0.1,0.5-0.2,0.7
        c0.1,0,0.2,0.1,0.3,0.2l-0.1,0.2c0.1-0.1,0.2-0.3,0.4-0.3l-0.3,0.4c0.1,0,0.3,0,0.3,0c-0.1,0.1-0.2,0.3-0.2,0.4
        c0.1,0.4,0.4,0.6,0.6,1l-0.1,0c-0.1-0.1-0.2-0.3-0.2-0.3c0,0.1-0.1,0.3-0.1,0.4c0.1,0,0.2,0,0.3,0c0.1,0.2,0.2,0.5,0.2,0.8l0.2-0.1
        c0,0.1,0,0.3,0,0.4c0.2,0.2,0.1,0.5,0.2,0.7c-0.2,0.1-0.1,0.3,0,0.5c0-0.2,0.1-0.4,0.3-0.5c0-0.2,0-0.5,0.3-0.4
        c0.1-0.2,0.4-0.3,0.3,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.1-0.1,0.1-0.3,0.2-0.4c0,0.3,0,0.5,0,0.8c0.1-0.1,0.2-0.2,0.2-0.3
        c-0.1,0.3,0.1,0.6,0.2,0.9c0,0,0.1,0,0.2,0c0-0.2-0.2-0.5-0.1-0.7c0.1,0,0.2,0.1,0.2,0.1c-0.3,0.3,0.3,0.7,0.6,0.5
        c0,0.1,0,0.2,0,0.2c0.1,0,0.3,0,0.4,0l-0.1,0.2l0.2-0.1l0,0.2l0.2,0l0,0.1c0.1,0.1,0.1,0.3,0.1,0.5c0.1,0,0.3-0.1,0.3-0.1
        c0,0.1-0.1,0.3-0.1,0.4c0.1,0,0.3,0,0.3,0c0,0.1,0,0.2-0.1,0.2l0.2,0c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.1,0.2,0.3,0.3,0.5l0.1,0
        c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.1,0.2,0.2,0.3,0.2c0.1-0.1,0.2-0.2,0.3-0.3c-0.1,0-0.3,0-0.4,0.1l0-0.1c0.2-0.1,0.3-0.4,0.4-0.6
        l0.2,0.2c0.1-0.2,0.2-0.3,0.5-0.3c-0.2-0.3-0.5,0-0.7,0.1c0.1-0.1,0.2-0.2,0.3-0.3c0-0.1,0-0.2,0-0.3c0.2-0.1,0.3-0.1,0.5-0.3
        c-0.7,0,0.2-0.4-0.1-0.6c0.1,0.1,0.3,0.2,0.4,0.2c-0.1-0.1-0.2-0.2-0.2-0.2c0.1-0.2,0.2-0.6,0.4-0.7c-0.1-0.1-0.2-0.3-0.2-0.4
        c0-0.2,0.3-0.3,0.2-0.6c0.1-0.2-0.4-0.3-0.2-0.5c0.2,0.1,0.3,0.2,0.5,0.3l-0.2-0.3l0.3,0.1c0,0,0-0.1,0-0.2c-0.1,0-0.4,0-0.5,0.1
        c0.2-0.5,0.4-1.1,0-1.6c0.1-0.1,0.1-0.3,0.2-0.4c0,0.2,0,0.4,0,0.7c0.1-0.2,0.1-0.5,0.2-0.7c-0.1-0.1-0.2-0.3-0.3-0.4
        c0.1,0,0.3,0,0.4,0c-0.2-0.1-0.4-0.3-0.4-0.5c0.1,0,0.4,0.1,0.5,0.1c-0.1-0.1-0.2-0.1-0.3-0.2l-0.2,0.1l0-0.4c0.1,0,0.3,0,0.4,0.1
        c-0.1-0.1-0.3-0.2-0.4-0.2c0.2-0.3,0.2-0.6,0.2-0.9c0.1-0.1,0.2-0.1,0.4-0.2c-0.1,0-0.3,0-0.4,0l0-0.4l0.2,0.1
        c0-0.1-0.1-0.3-0.1-0.4l0.2,0.1c-0.1-0.2-0.1-0.4,0.1-0.6c0-0.2-0.1-0.4,0-0.5c0.3-0.8,0.7-1.5,1.1-2.3c0,0.2-0.1,0.5-0.2,0.7
        l-0.1,0c-0.1,0.4-0.3,0.8-0.4,1.2c-0.1,0.5,0,0.9-0.1,1.4c0,0.2-0.2,0.4,0,0.6c0,0.5,0,0.9,0.1,1.4c0.2-0.1,0.2-0.3,0.2-0.5
        c0.1,0.1,0.2,0.2,0.2,0.2c0.2-0.2,0.4-0.4,0.7-0.6l0,0.2l0.1,0c-0.1-0.3,0-0.4,0.3-0.4c0.2-0.2,0.6-0.2,0.6-0.6c-0.1,0-0.3,0-0.4,0
        c0.1,0,0.2-0.1,0.3-0.1c0-0.1,0.1-0.3,0.1-0.4c0.1-0.2,0.1-0.4,0.1-0.6c0.2,0,0.3,0,0.5,0c-0.1-0.1-0.2-0.1-0.3-0.2
        c-0.1,0.1-0.2,0.1-0.3,0.2c0-0.2,0-0.5,0-0.7c0.1-0.1,0.1-0.2,0.2-0.3c0-0.2-0.3-0.5,0-0.5c0.1,0.1,0.2,0.2,0.3,0.2
        c-0.3-0.3-0.3-0.7-0.2-1.1c0.1,0.1,0.3,0.2,0.4,0.2c-0.1-0.2-0.4-0.4-0.3-0.7c0-0.4,0.3-0.7,0.1-1l0.2,0.1l-0.1-0.1
        c0.1-0.1,0.5-0.3,0.2-0.4c0-0.1-0.1-0.3-0.1-0.4c0.1,0,0.3-0.1,0.3-0.1l0.1-0.1c0,0,0-0.1,0-0.1l-0.1-0.1c-0.2-0.5,0.1-1.2-0.1-1.7
        c-0.1-0.3,0-0.7,0.1-1c-0.1,0-0.3,0.1-0.4,0.1c0.1-0.1,0.2-0.2,0.2-0.2c-0.1-0.3,0.1-0.6,0.3-0.9l-0.3,0.1c0-0.4,0-0.8,0.2-1.1
        c-0.1,0-0.2,0.1-0.4,0.2l0-0.2c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0.1-0.1,0.2-0.2c0,0.1,0,0.3,0,0.4c0.5,0-0.2-0.5,0.3-0.6
        c0.2-0.1-0.3-0.1-0.4-0.1c0-0.1,0.1-0.3,0.1-0.4c-0.1,0-0.3,0.1-0.4,0.1c0.1-0.2,0.2-0.4,0.2-0.6c0.1,0.1,0.1,0.3,0.3,0.4
        c0.1,0.1,0.2,0.3,0.3,0.4c0.1-0.3-0.1-0.5-0.3-0.7c-0.1-0.1-0.2-0.5-0.4-0.2c0-0.4-0.1-0.7,0.1-1.1c0.1,0.3,0.1,0.8,0.5,0.8
        c0-0.2,0-0.3-0.3-0.3c0.2-0.3-0.1-0.4-0.2-0.6c0.2-0.6,0.4,0,0.6,0.2c0-0.2-0.1-0.3-0.3-0.4c-0.1-0.1-0.2-0.2-0.2-0.3
        c-0.1,0.2,0,0.6-0.3,0.5c-0.2-0.4,0.3-0.5,0.4-0.8c-0.1,0-0.3,0-0.4,0l-0.1,0.2c0,0-0.1,0-0.2,0c0.1-0.1,0.3-0.2,0.4-0.3
        c-0.1-0.1-0.2-0.2-0.3-0.2c0.1-0.4,0-0.8,0.3-1.1c-0.2,0.1-0.4,0.1-0.5-0.1c0.1,0,0.3,0,0.4,0.1l0-0.1c0.1-0.1,0.3-0.3,0.4-0.4
        c0,0,0,0,0,0c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0.2-0.2,0.3-0.4,0.5c0.2-0.3,0.3-0.7,0-1c0.1,0,0.2,0,0.2,0.1l0-0.3c0.1,0,0.2,0,0.3,0
        c-0.1-0.1-0.2-0.2-0.3-0.4c-0.1,0.1-0.2,0.2-0.3,0.2c0.1-0.1,0.1-0.3,0.2-0.4c0.2-0.1,0.3-0.2,0.3-0.4c-0.2,0.1-0.4,0.3-0.5,0.5
        c0-0.1,0-0.4,0-0.5c0.1,0,0.3,0,0.3,0l-0.1-0.3c0.2-0.1,0.4-0.3,0.6-0.2c0,0.2-0.2,0.3-0.2,0.4c0.1,0.2,0.3,0.7,0.5,0.3
        c-0.3-0.2-0.2-0.5-0.2-0.8c-0.2-0.1-0.4-0.1-0.5-0.2l0.1,0.3c-0.2,0-0.3,0-0.4,0.1c0.1-0.1,0.2-0.4,0.3-0.5c0.3,0,0.4-0.3,0.5-0.5
        c-0.1-0.1-0.1-0.1-0.1-0.2c-0.2,0.2-0.3,0.7-0.6,0.6c-0.1-0.4,0.4-0.7,0.5-1.1c-0.2,0.2-0.3,0.4-0.5,0.6c0-0.3,0-0.6-0.2-0.8
        c0.1-0.2,0.2-0.3,0.3-0.5c0,0.1,0,0.4,0,0.5l0.2,0.1c0-0.4,0.4-0.2,0.7-0.1c0-0.1,0-0.2,0-0.2c-0.1,0-0.3,0.1-0.4,0.1l0-0.3
        c0.1,0,0.2,0,0.3,0c-0.1-0.1-0.1-0.2-0.1-0.3c0,0.1,0,0.2,0,0.3l-0.2-0.1c0,0.1,0.1,0.3,0.1,0.4c-0.5,0-0.3-0.5-0.4-0.7
        c0.1-0.1,0.3-0.1,0.4-0.2c-0.1-0.1,0-0.4-0.2-0.4c0,0.1,0.1,0.3,0.1,0.4c-0.1,0-0.3,0-0.5,0c0-0.3,0.3-0.4,0.4-0.7
        c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2-0.3,0-0.7,0.1-1c0.2,0.1,0.5,0.2,0.7,0.3c0,0,0-0.1,0-0.2c-0.3,0-0.5-0.1-0.7-0.2
        c0.3-0.3-0.3-0.8,0.2-1c-0.1-0.3-0.2,0.3-0.4,0.2c0.1-0.2,0.1-0.5,0-0.7c0.3-0.2,0-0.6,0-0.9c0.1,0,0.2,0.1,0.3,0.2
        c0-0.1-0.1-0.3-0.2-0.4l0.3,0.1c0-0.1,0-0.2,0-0.3c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c0.2-0.3,0.3-0.7,0.4-1
        c0,0.1,0.1,0.3,0.1,0.4c0.1-0.4-0.4-0.6-0.4-0.9c0.1,0,0.3,0,0.4,0l0-0.1c-0.1,0-0.3,0-0.4,0c0-0.1-0.1-0.3-0.2-0.4
        c0.2,0,0.5,0.1,0.7,0.2c0.1,0,0.2,0.1,0.3,0.1c-0.3-0.2-0.7-0.3-0.9-0.7c0.1,0,0.2,0,0.2,0l0.2,0.1c-0.1-0.3,0-0.5,0.3-0.6l-0.2,0.1
        c0-0.2,0-0.4-0.1-0.5l-0.3,0.2l0.1-0.4l0,0c0,0.1-0.1,0.2-0.1,0.3l0-0.3c-0.1,0-0.2,0-0.3-0.1l0.4,0c-0.1-0.2-0.2-0.3-0.2-0.5
        c0.2-0.1,0.3-0.2,0.4-0.4l-0.4,0.1c0-0.1,0.1-0.3,0.2-0.4l-0.3,0c0.1-0.2,0.2-0.4,0.2-0.6c-0.1,0-0.2-0.1-0.3-0.2c0.1,0,0.3,0,0.4,0
        c0-0.1,0-0.4,0-0.5c-0.2-0.2-0.2-0.8-0.6-0.7c0,0,0-0.1,0-0.1c0.1,0,0.3,0.1,0.4,0.1l-0.1-0.3l0.1-0.3l-0.2,0.1c0-0.2,0-0.5-0.1-0.7
        c0.2,0.1,0.3,0.2,0.2,0.5l0.4,0c-0.1-0.4-0.3-0.7,0-1.1c-0.3-0.2-0.2-0.4,0.1-0.5c0-0.2-0.3-0.3-0.2-0.5c0-0.2,0-0.5,0.1-0.7
        c0,0.1,0,0.3,0.1,0.4c0.2-0.2,0.2-0.4,0.4-0.6c-0.1,0-0.4,0-0.5,0c0.1-0.1,0.2-0.3,0.2-0.4l-0.2,0c0.1-0.1,0.1-0.3,0.2-0.4
        c0.3-0.3,0-0.5-0.3-0.3c0.2-0.2,0.4-0.4,0.4-0.7c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0-0.5,0.3-0.5c-0.2,0-0.4,0.1-0.6,0.1
        c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0.3-0.4,0.2-0.6,0.2c0,0,0-0.1,0-0.1l0.2,0c-0.1-0.2-0.1-0.5,0.2-0.6c-0.1-0.2-0.1-0.3-0.2-0.5
        c0.1,0,0.3,0.1,0.4,0.1c-0.1-0.2-0.2-0.5-0.3-0.7c0.1-0.3,0.3-0.6,0.5-0.9c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.3,0-0.4
        c0.1-0.1,0.2-0.3,0.2-0.4l-0.3,0c0-0.1,0-0.2,0-0.3l0.1,0c0-0.1,0.1-0.3,0.1-0.4c-0.1-0.3-0.1-0.7,0-1c0.1-0.2-0.4-0.5,0-0.4l0-0.3
        l-0.2-0.1c0.1,0,0.2-0.1,0.2-0.1c0-0.8,0-1.7,0-2.5l0.1,0c0-0.1-0.1-0.3-0.1-0.4c0.1-0.2,0-0.5,0-0.7c0-0.1,0.1-0.2,0.1-0.3
        c-0.2-0.2-0.1-0.6-0.1-0.9c0,0,0.1-0.1,0.1-0.2c-0.1-0.4-0.1-0.8-0.1-1.2c0-0.3,0.1-0.7,0.1-1l-0.1,0c0-0.2,0-0.5-0.1-0.7
        c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.3,0,0.4l0.1,0c-0.1,0.5-0.1,1.1-0.1,1.6c0.2,0.6,0,1.2,0.2,1.8c0,0.2-0.1,0.4,0,0.6
        c0.2,0.4,0.1,0.9,0.1,1.4c0.1,0.4,0.2,0.8,0.1,1.2c0.2,0.5,0.2,1.1,0.1,1.7c0,0.5,0.4,0.8,0.2,1.3c0,0.1,0.1,0.2,0.2,0.3
        c0,0.5,0.1,1-0.1,1.4c0.5,0.3,0.2,0.9,0.2,1.4c0.2,0.4,0.4,0.8,0.4,1.2c0,0.6,0.3,1.2,0.1,1.8c0,0.4,0.1,0.8,0.3,1.2
        c-0.1,0.2-0.1,0.3-0.2,0.5c0.1,0.2,0.2,0.4,0.3,0.6c-0.1,0.1-0.2,0.2-0.2,0.3c0.1,0.4,0.3,0.8,0,1.2c0,0.3,0,0.5,0.1,0.8
        c0.1,0.2-0.2,0.4-0.1,0.6c0.3,0.9,0,1.9,0,2.8c-0.1,0.9,0.3,1.9-0.2,2.7c0.3,0.4,0.1,0.8,0.1,1.3c0.3,1.2-0.1,2.5,0.1,3.8
        c0.2,0.5-0.1,1,0.1,1.5c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0.4,0.2,0.6,0.3,0.9c0,0.5-0.2,0.9,0,1.4c-0.1,0.3,0,0.7-0.1,1.1
        c-0.4,0.3,0,0.6,0.1,0.9c0,0.2-0.1,0.5-0.2,0.7c0.1,0.4-0.1,0.9,0,1.3c-0.1,0.3-0.2,0.6-0.2,1c-0.2,0.7-0.2,1.3-0.4,2
        c-0.1,0.8-0.4,1.6-0.6,2.4c0,1.1,0.3,2.3,0.2,3.4c0,0.7,0.3,1.4,0.1,2.1c0.1,0.4,0.2,0.8,0.1,1.3c-0.1,0.5-0.4,0.9-0.6,1.3
        c-0.2,0.2-0.3,0.3-0.3,0.5c-0.2,0.2-0.7,0.8-0.7,0.3c0.2-0.3-0.2-0.1-0.2,0.1l-0.1,0.1c0-0.1-0.1-0.2-0.1-0.3
        c0.1,0.5-0.5,0.4-0.8,0.6c0.1,0.1,0.2,0.2,0.3,0.2c-0.1,0-0.3,0-0.4,0c-0.2,0.3-0.4,0.6-0.5,0.9c0.5,0,0.2,0.5,0.4,0.8
        c0.1,0.1,0.2,0.2,0.4,0.3c-0.1,0.1-0.3,0.3-0.4,0.5c0.2,0,0.5,0.1,0.4,0.4c0,0.5,0,1.1-0.2,1.6c-0.2,0.2-0.4,0.4-0.3,0.7
        c-0.1,0.5-0.5,0.8-0.9,1c-0.2,0.5-0.7,0.9-1.1,1.2l0.4,0c-0.1,0.2-0.3,0.4-0.6,0.4c0,0.1,0.1,0.2,0.2,0.3c-0.2,0-0.3,0.1-0.3,0.3
        c0,0.1-0.1,0.3-0.2,0.3c-0.3-0.3-0.5,0-0.7,0.2c-0.4,0-0.9,0.2-1-0.2c-0.4-0.5-0.5-1.2-0.6-1.8l0.2,0.1c0-0.2,0-0.4,0.1-0.5
        c-0.2-0.2-0.5-0.4-0.7-0.4c-0.2,0-0.6,0.2-0.7-0.2c-0.2,0.1-0.3,0.1-0.5,0.1c0,0.1,0,0.2,0,0.3c-0.6-0.1-1.2-0.1-1.7-0.3
        c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.4,0.1-0.5,0.1c-0.3-0.4-0.8-1.1-0.3-1.6c0.2-0.9-0.5-1.5-0.9-2.2c-0.5-0.4-0.6-1-1-1.5
        c-0.7-1.5-1.3-3.1-1.4-4.8c-0.1-0.4-0.2-0.9,0-1.3c-0.1-0.6,0-1.2,0.2-1.7c0-0.1-0.1-0.2-0.1-0.2c0.2-0.7,0.1-1.5,0.4-2.1
        c0-0.5,0.2-0.9,0.2-1.3c0.1,0,0.2-0.1,0.2-0.1c0-0.1,0-0.3,0-0.4c0.2-0.2,0.3-0.5,0.2-0.8c0.2-0.1,0.4-0.3,0.5-0.5
        c0-0.1-0.1-0.2-0.2-0.3c0-0.1,0.2-0.2,0.2-0.3c0.1-0.5,0.1-1,0.4-1.4c-0.1-0.4,0.5-0.7,0.3-1.1c0-0.2,0.1-0.3,0.1-0.5
        c0.1,0,0.2-0.1,0.2-0.2c0-0.3,0.1-0.5,0.1-0.8c0.1,0,0.2-0.1,0.3-0.1c-0.2-0.3-0.1-0.8,0.3-0.8c-0.2-0.3-0.1-0.8,0.3-0.9
        c-0.2-0.6,0.4-1,0.5-1.5c0.1-0.4,0.4-0.6,0.3-1c0-0.5,0.4-0.9,0.6-1.3c-0.1-0.2-0.2-0.5,0.1-0.6c0.1-0.2,0.3-0.3,0.4-0.4
        c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.3,0.2-0.3,0.4-0.4c0-0.5,0.1-0.9,0.3-1.3c-0.1-0.3,0.1-0.5,0.3-0.6c0.1-0.3,0-0.8,0.4-0.9
        c0-0.1-0.1-0.2-0.2-0.3c0.1-0.2,0.3-0.5,0.5-0.6c0-0.2-0.1-0.5,0.1-0.7c0.1-0.2,0.4-0.4,0-0.5c0.1,0,0.2-0.1,0.3-0.2
        c0-0.1-0.1-0.2-0.1-0.3c0.2-0.1,0.3-0.3,0.3-0.5c0-0.4,0.1-0.7,0.3-1c-0.3-0.4,0.3-0.7,0.2-1.1c-0.1-0.6,0.2-1.2,0.1-1.8
        c0.3-0.3,0.2-0.8,0.1-1.2c-0.1-0.3,0.2-0.6,0.3-0.8c-0.1-0.1-0.2-0.2-0.3-0.3c0.1-0.6,0.2-1.1,0-1.7c0.1-0.4,0.2-0.7,0.1-1.1
        c0.1,0,0.3-0.1,0.4-0.1c-0.5-0.3,0-0.8-0.1-1.3c-0.1-0.3,0-0.6,0.2-0.8c0-0.1-0.1-0.3-0.2-0.4c0-0.2,0.1-0.3,0.1-0.5
        c-0.1-0.8,0.1-1.6,0-2.4c-0.1-0.2-0.2-0.5-0.1-0.7c0-0.1,0-0.2-0.1-0.3c-0.1-0.3-0.3-0.6-0.6-0.5c0.3,0.3,0.2,0.7,0.1,1
        c-0.1-0.1-0.1-0.2-0.2-0.3c0.1-0.2,0-0.4,0-0.6c-0.2,0.1-0.4,0.3-0.6,0.5c0,0,0.1,0,0.2,0.1c0-0.1,0-0.3,0.1-0.4
        c0.3,0.2,0.3,0.8,0.1,1.1c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.2,0c0,0.1,0.1,0.2,0.2,0.2c0.1,0.3,0.2,0.5,0.1,0.8
        c-0.1,0-0.3,0-0.4,0c0,0.1,0.1,0.2,0.1,0.3c0.2-0.2,0.4-0.4,0.6-0.4c0,0.2-0.1,0.4-0.3,0.5c0.2,0.7,0.9-0.1,0.5-0.5
        c-0.2-0.1-0.4-0.4-0.4-0.6c0.2-0.2,0.5-0.4,0.7-0.6c0,0.2-0.2,0.4-0.3,0.6c0.1,0.1,0.3,0.3,0.4,0.4c-0.3,0-0.3-0.4-0.6-0.5
        c0.1,0.3,0.1,0.6,0.4,0.7c0,0.4,0.2,0.9-0.1,1.2c0-0.3,0.1-0.5,0.1-0.8c-0.1,0.1-0.3,0.3-0.4,0.5c0,0.1,0.1,0.3,0.1,0.4
        c-0.2,0.1-0.5,0.3-0.7,0.2c0,0.1,0.1,0.3,0.1,0.4c0.2-0.1,0.4-0.2,0.5-0.3c0.1,0.1,0.2,0.2,0.2,0.4c-0.1,0-0.2-0.1-0.2-0.2
        c-0.1,0.1-0.2,0.2-0.3,0.2c0.1,0,0.3,0.1,0.4,0.2c-0.1,0.5-0.4,0-0.7-0.1c-0.3,0.1-0.2,0.4-0.1,0.7c-0.1,0.1-0.2,0.2-0.2,0.3
        c0.1,0.1,0.2,0.2,0.3,0.2l-0.3,0.2c0.1,0.2,0.5,0,0.3-0.3l0.2,0c0-0.2-0.3-0.5-0.2-0.7c0.1,0.1,0.3,0.3,0.3,0.4
        c0.2,0.1,0.3-0.2,0.4-0.2c0.1,0.1,0.1,0.2,0.2,0.4c0-0.1-0.1-0.2-0.2-0.2c-0.3,0.2-0.4,0.4-0.3,0.7c-0.2,0.2-0.7,0.2-0.7,0.6
        c0.1,0.2-0.1,0.4-0.2,0.6c0.1-0.1,0.2-0.2,0.3-0.3c0-0.1,0.1-0.3,0.1-0.4c0.3-0.1,0.6-0.2,0.8-0.2c0,0.1,0,0.2,0,0.3l-0.1-0.2
        c-0.2,0.2-0.7,0.3-0.6,0.7l0.2,0.1c0,0-0.1,0.1-0.2,0.2l-0.1,0.3c-0.1-0.1-0.2-0.4-0.5-0.3c0.1,0.2,0.3,0.3,0.5,0.4
        c0,0.1,0,0.2,0.1,0.3c-0.2,0.2-0.3,0.4-0.5,0.6c0.1,0.2,0.2,0.4,0.2,0.6c0.3,0.2-0.1,0.5-0.1,0.8c-0.1,0-0.2,0-0.3,0
        c0.1-0.2,0.2-0.4,0.3-0.6c-0.2-0.1-0.4-0.2-0.6-0.1c0.1,0.1,0.3,0.2,0.4,0.3c-0.2,0.2-0.3,0.3-0.4,0.5c0.3-0.1,0.7,0,0.6,0.4l0.1,0
        c0.1,0.2,0.2,0.3,0.3,0.5c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0.2-0.3,0.3-0.5,0.2c0,0.1,0.1,0.3,0.1,0.4c0.1,0,0.2-0.1,0.3-0.2
        c0.1,0.4-0.3,0.7-0.5,1c0.1,0,0.2,0.1,0.3,0.1c0,0.1-0.1,0.3-0.1,0.4c0.2-0.1,0.3-0.2,0.5-0.3c-0.2,0.2-0.3,0.7-0.6,0.2
        c0.1,0.1,0.2,0.4,0.2,0.5c-0.1,0-0.2-0.2-0.3-0.2l-0.2,0c0,0.1,0.1,0.3,0.2,0.4c-0.1,0-0.3,0-0.4,0c0,0.3-0.2,0.5-0.4,0.7l0.3-0.1
        c-0.2,0.2-0.3,0.4-0.2,0.7c-0.1,0.3-0.4,0.8,0.1,0.9l-0.1,0.1c-0.1,0-0.3,0-0.4,0c0,0.1,0,0.3,0,0.4c0.2-0.1,0.5-0.3,0.7-0.2
        c-0.1,0.2-0.3,0.2-0.5,0.4c-0.3-0.2-0.5,0.1-0.4,0.4l-0.2,0.3c0.2,0.1,0.4,0.2,0.6,0.2c-0.1,0.1-0.3,0.2-0.4,0.3c0.1,0,0.2,0,0.3,0
        c-0.2,0.3-0.5,0.6-0.5,1c0,0.2-0.2,0.3-0.3,0.4c0.1-0.3,0.3-0.7,0.2-1c0.1,0,0.2-0.2,0.2-0.2c-0.1-0.2-0.2-0.4-0.3-0.5
        c-0.1,0.3-0.1,0.6-0.1,1c-0.1,0-0.2-0.1-0.2-0.1c0,0.2-0.2,0.4-0.2,0.7c0.1,0.1,0.2,0.2,0.3,0.4c0,0.1,0,0.2-0.1,0.4
        c-0.1-0.1-0.2-0.3-0.3-0.4c0,0.1,0.1,0.3,0.1,0.4c-0.5,0-0.5,0.6-0.8,0.9c0.1,0.1,0.3,0.2,0.5,0.1c-0.3,0.1-0.6,0.3-0.8,0.5
        c0,0.1,0.1,0.2,0.2,0.3c-0.2,0-0.3,0.2-0.3,0.4c0.2,0,0.4-0.1,0.6,0c-0.1,0-0.3,0.1-0.4,0.2l0,0.1c0-0.1,0-0.2,0-0.3
        c-0.2,0.1-0.3,0.2-0.5,0.4c0,0.1,0.1,0.2,0.1,0.3c-0.2,0.2-0.4,0.4-0.1,0.6c0.1-0.3,0.4-0.5,0.3-0.8c0.1,0,0.2,0,0.4,0
        c-0.1,0.2-0.2,0.4-0.3,0.7c-0.3,0.2-0.6,0.3-0.8,0.5c0.1,0,0.3,0,0.4,0c-0.1,0.4-0.6,0.3-0.8,0.6c0.1,0.1,0.2,0.3,0.3,0.4
        c-0.3,0-0.5,0.1-0.5,0.4c-0.1,0-0.2,0-0.3-0.1c0,0,0,0.1,0,0.1c0.1,0,0.3,0,0.3,0c-0.1,0.2-0.2,0.4-0.3,0.6c0.3,0,0.3-0.3,0.3-0.6
        c0.2,0,0.3-0.1,0.3-0.3c0.2,0,0.4-0.2,0.6-0.3c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1,0-0.3-0.1-0.4-0.1c0,0.4,0,0.7,0,1.1l-0.1,0
        c0-0.1,0-0.3,0.1-0.4c-0.2,0.2-0.7,0.2-0.7,0.6c0.1,0.1,0.2,0.2,0.3,0.3c0.1-0.1,0.2-0.3,0.3-0.4c0,0.2-0.1,0.4-0.3,0.5
        c0,0.2,0.1,0.3,0,0.5c-0.1-0.1-0.3-0.3-0.4-0.4c0.6-0.1,0.2-0.3,0-0.5c-0.3,0.2,0.1,0.9-0.4,0.9c0,0.1,0.1,0.3,0,0.4
        c-0.2,0.3-0.2,0.6-0.2,1c-0.4,0.1-0.4,0.5-0.5,0.9l0.3,0.1l-0.1-0.2c0.2,0.1,0.4-0.1,0.6-0.2c0,0.1,0,0.2,0,0.3
        c-0.2-0.1-0.3,0-0.4,0.2c-0.1,0-0.3,0.2-0.4,0.2c0-0.1-0.1-0.2-0.1-0.3c-0.3,0.1-0.2,0.5-0.3,0.7c0,0.2,0,0.5,0.2,0.7
        c0-0.1,0.1-0.3,0.1-0.3c0,0.2,0,0.5,0,0.7c-0.1,0-0.2,0-0.2,0c0.1-0.3-0.4-1-0.5-0.4l0.1,0.2c0,0.1-0.1,0.2-0.1,0.3
        c0.3,0.1,0,0.5,0.2,0.7c0.1-0.2,0.2-0.4,0.4-0.5c-0.1,0.3-0.3,0.5-0.4,0.7c-0.1-0.2-0.3-0.4-0.2-0.6c-0.1,0-0.3,0-0.3,0
        c0.1,0.2,0.2,0.4,0.2,0.7c-0.1,0-0.3,0.1-0.4,0.1c0.1,0.2,0.1,0.3,0.2,0.5c-0.2,0.2-0.2,0.4-0.1,0.6c-0.2-0.1-0.3-0.2-0.4-0.3
        c-0.1,0.1-0.1,0.2-0.2,0.3c0.1,0,0.2,0.1,0.2,0.1l-0.2,0.2c0.2,0,0.4,0.1,0.6,0.1l-0.2-0.4c0.1,0,0.3,0,0.4,0l-0.3,0.1
        c0,0.1,0.1,0.3,0.1,0.3c-0.2,0.1-0.4,0.2-0.6,0c0.1,0.3,0.1,0.5-0.2,0.5l0.1-0.4c-0.2,0.1-0.4,0.3-0.6,0.4c0.1,0,0.1,0.1,0.2,0.1
        c0.1,0,0.2-0.1,0.3-0.2c0.2,0.2-0.4,0.5,0,0.6c0.3,0,0.4-0.2,0.5-0.5c0.2,0.1,0,0.6-0.2,0.4c0,0.1,0.1,0.3,0.1,0.4
        c-0.1-0.1-0.2-0.2-0.3-0.3c0,0.2,0.1,0.4,0.1,0.6c-0.2-0.2-0.2-0.5-0.4-0.7c-0.1,0.2-0.3,0.4-0.4,0.6l0.1,0l0-0.1c0.1,0,0.2,0,0.3,0
        c-0.2,0.2-0.3,0.5-0.5,0.7c0.5,0.1,0.6-0.4,0.9-0.5c0,0.1-0.1,0.3-0.1,0.4c-0.1,0-0.2,0-0.3,0c-0.2,0.2-0.5,0.1-0.6,0.3
        c-0.4,0.2,0.2,0.4,0.2,0.7c0.1,0,0.2-0.1,0.2-0.2c0-0.2-0.1-0.3,0-0.5c0.1,0.1,0.1,0.3,0.2,0.4c-0.3,0.1-0.4,0.6-0.7,0.5
        c0,0,0,0.2,0,0.2c-0.1,0-0.2,0-0.3,0c0.2,0.2,0.3,0.4,0.5,0.7c-0.2-0.1-0.5-0.3-0.7-0.4c0.1,0.1,0.3,0.3,0.4,0.4
        c-0.1,0.1-0.4,0.3-0.1,0.5c0.1-0.1,0.2-0.3,0.3-0.4c-0.2,0.3,0,0.5,0.1,0.7c0,0.1,0,0.3,0,0.3l-0.2,0c0,0.1,0,0.4-0.1,0.6
        c0.1,0,0.3-0.1,0.4-0.2c-0.2,0.4,0,0.8,0.2,1.1c-0.3,0-0.3-0.4-0.4-0.6c-0.1,0.1-0.2,0.2-0.3,0.4l0.2,0l0,0.3
        c-0.1,0-0.3-0.1-0.4-0.1c0.2,0.1,0.3,0.4,0,0.4c0.3,0.1,0.1,0.4,0,0.5c0,0.1,0,0.4,0.2,0.4c0,0.2,0.1,0.4,0.1,0.6l0.2-0.1
        c0,0.1,0,0.4,0,0.5l-0.3-0.2c0,0.1,0,0.3,0,0.5c0.2,0,0.4,0.1,0.6,0.1l-0.1-0.3c0,0,0.1,0,0.1,0c0,0.1,0,0.3,0,0.4
        c-0.1,0-0.2,0.1-0.3,0.1c0,0.1-0.1,0.3-0.1,0.4l0.2,0l0,0.2c-0.1,0-0.2-0.1-0.3-0.1c0.1,0.2,0.1,0.4,0.1,0.6c-0.1,0-0.3,0-0.4,0
        c0.1,0.2,0.4,0.4,0.2,0.6c0.2,0.1,0.3,0.2,0.5,0.3c0,0.1-0.1,0.3-0.1,0.4c0.3,0.3,0.6,0.5,0.8,0.9c-0.4-0.2-0.7-0.3-1.1-0.4
        c0.2,0.1,0.4,0.3,0.6,0.4c-0.2,0.3-0.1,0.4,0.2,0.4c-0.1,0.1-0.2,0.2-0.2,0.3c0.4-0.1,0.6,0.3,0.6,0.6c-0.1,0.1-0.2,0.3-0.3,0.4
        c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2c0-0.1,0-0.3-0.1-0.4c0.3-0.2,0.5,0.1,0.3,0.3c0.3,0.2,0.5,0.6,0.4,1
        c0.2,0.2,0.8-0.2,0.7,0.2c-0.2,0-0.5-0.3-0.6,0.1c0.4,0,0.4,0.3,0.4,0.6c0.3-0.1,0.8,0,0.8,0.4c-0.1,0-0.2,0.1-0.3,0.1
        c0.2,0.4,0.8,0.4,0.8,1c0.2-0.3,0.6-0.2,0.4,0.2c0.1,0,0.4-0.1,0.5-0.1l0,0.2c-0.1,0-0.2,0.1-0.2,0.1c0.1,0,0.3,0.1,0.4,0.2
        c-0.1,0.1-0.3,0.1-0.4,0.2c0.1,0,0.3-0.1,0.5-0.1l-0.2,0.4c0.2-0.3,0.5-0.3,0.7-0.1c0,0.1-0.1,0.2-0.1,0.2c0.1,0.2,0.2,0.3,0.5,0.3
        c0,0,0,0.1,0,0.2c0.1,0.1,0.2,0.2,0.3,0.2l-0.3,0.1c0.4,0.1,0.8,0.3,1,0.7c0-0.2,0-0.4,0.1-0.6c0,0.2,0,0.3,0.1,0.5
        c0.1,0,0.3,0.1,0.4,0.1c-0.2,0.1-0.2,0.2-0.2,0.4c0.4,0.1,0.7,0.5,1.2,0.5c0,0.1,0.1,0.2,0.2,0.3c-0.1,0.1-0.1,0.1-0.2,0.2
        c0.4,0,0.4,0.5,0.8,0.4c-0.2-0.1-0.3-0.2-0.5-0.3c0-0.2,0.1-0.3,0.1-0.5c0.3,0.3,0.6,0.6,1,0.8c0,0.1,0,0.2,0,0.3l0.4,0.1l0-0.3
        c0,0,0.1,0,0.1,0c0,0,0,0.1,0,0.2c0.4,0.2,0.7,0.8,1.2,0.7c0,0,0,0.1,0,0.1c-0.1,0-0.3,0-0.4,0c0,0.4,0.5,0.6,0.8,0.7l0.1,0.1
        c0.6,0.1,0.7-0.6,1.2-0.8c-0.1-0.2,0-0.3,0.2-0.4c-0.1,0-0.3-0.1-0.4-0.1c0.1-0.2,0.2-0.3,0.4-0.5c0,0.1,0,0.4,0,0.5
        c0.1,0,0.2,0,0.3,0c0-0.1,0-0.2,0-0.2c0.2-0.2,0.2-0.5,0-0.7c0,0.1,0,0.4,0,0.5c0-0.1-0.1-0.2-0.2-0.3c0.3-0.5,0.9-0.8,1-1.5
        l-0.3,0.1c0-0.1,0-0.2,0.1-0.3l0.3,0.2c-0.3-0.3,0.2-0.6,0.1-1c-0.1-0.3,0.3-0.2,0.5-0.3c0-0.2,0.2-0.3,0.4-0.4
        c-0.1-0.2-0.2-0.4-0.2-0.5c0.2,0,0.3-0.2,0.4-0.3c0-0.6,0-1.1,0.1-1.7c0.1-0.3,0.4-0.2,0.7-0.2c0.1-0.4,0.2-0.8,0.2-1.1
        c0.2,0.1,0.4,0.3,0.5,0.4c0.5-0.1,1-0.7,1.4-0.2c-0.2-0.2,0-0.4,0.2-0.5c0.1-0.6,0.1-1.2,0.1-1.9c-0.1-0.4,0-0.8,0.1-1.3
        c-0.1-0.2-0.1-0.5,0-0.7c-0.1-0.3,0-0.6,0.1-0.8c-0.1-0.6-0.3-1.4-0.1-2c-0.3-0.5,0-1,0-1.4c0-0.6,0-1.2,0.4-1.7
        c-0.2-0.2-0.2-0.5,0-0.8c0.2-0.2,0-0.5,0.1-0.8c0.2-0.5-0.1-1.1,0.1-1.6c0.1-0.3,0-0.6-0.1-0.8c0.1-0.2,0.2-0.4,0.3-0.6
        c-0.3-0.6-0.3-1.3-0.1-2c0-0.3,0-0.6,0.1-0.9c-0.2-0.5,0.2-0.9,0-1.4c0.1-0.5,0.1-0.9,0-1.4c0.1-0.2,0.2-0.5,0.3-0.7
        c-0.2-0.3-0.3-0.6-0.1-1c-0.2-0.3-0.1-0.6,0-0.9c-0.2-0.2-0.1-0.5,0-0.7c-0.1-0.3,0-0.5,0.1-0.8c0-0.5,0.2-0.9,0.1-1.4
        c-0.1-0.3,0-0.5,0.1-0.7c-0.2-0.3-0.4-0.6-0.2-0.9c0.1-0.3,0-0.6-0.1-0.9c0.2-0.4,0.3-0.8,0.1-1.2c0.1-0.2,0.2-0.3,0.3-0.5
        c-0.1-0.3-0.1-0.6,0.1-0.9c-0.1-0.3-0.4-0.6-0.2-1c-0.3-0.9,0-1.8,0-2.7c-0.1-0.3,0-0.5,0.1-0.8c-0.1-0.3-0.1-0.6,0-0.9
        c-0.4-0.8,0.1-1.7-0.2-2.4c0.1-0.6-0.3-1.2,0-1.9c-0.3-0.7-0.2-1.5-0.2-2.3c-0.2-0.3-0.3-0.7,0-1c-0.4-0.6,0-1.3-0.2-1.9
        c0-0.4,0-0.9,0.2-1.3c-0.1-0.4-0.1-0.8,0.1-1.2c0-0.5-0.1-0.9-0.2-1.3c0,0-0.1-0.1-0.1-0.1c0.1-0.1,0.3-0.4,0-0.5
        c0,0.1-0.1,0.2-0.2,0.3l0,0c0-0.1,0.1-0.3,0.1-0.4c-0.1,0-0.3-0.1-0.4-0.1c0-0.1,0.1-0.2,0.2-0.3c0,0.1,0.1,0.2,0.2,0.3
        c0.1,0,0.3,0.1,0.4,0.1c-0.2-0.2-0.2-0.6-0.1-0.8c-0.6,0.1,0.4-0.6-0.2-0.4l0.1-0.2c-0.2,0-0.4,0-0.5,0c0-0.2,0-0.5,0.3-0.6
        c0,0.1-0.1,0.4-0.2,0.6c0.2-0.1,0.4-0.3,0.6-0.4c-0.5-0.2-0.2-0.8-0.1-1.1l-0.2,0c0-0.6,0.8-0.1,0.7,0.3c0,0,0.1,0,0.1,0
        c-0.1-0.2-0.2-0.5-0.3-0.7l0.3,0c0-0.1-0.2-0.2,0-0.3c-0.1,0-0.2,0-0.3,0c0,0.1-0.1,0.3-0.1,0.4c-0.2-0.1-0.3-0.3-0.5-0.4
        c0.1-0.1,0.3-0.2,0.4-0.4c-0.4-0.1-0.6,0.4-0.9,0.3c0.2-0.1,0.1-0.3,0.1-0.5c0.1,0,0.2-0.1,0.2-0.1c0,0.1-0.1,0.4-0.1,0.5
        c0.2-0.2,0.4-0.3,0.6-0.5c0-0.2-0.1-0.3-0.1-0.5l-0.2,0c0.2-0.3,0.1-0.7-0.3-0.7c-0.3,0.2,0.5,0.7,0.1,0.5c0-0.1-0.1-0.2-0.2-0.2
        c-0.1,0-0.2,0-0.2,0.1c0.3-0.2,0.4-0.6,0.3-0.9c0.1,0.1,0.2,0.2,0.2,0.2c0.1-0.2,0.1-0.4,0.2-0.6c-0.2-0.1-0.3-0.3-0.4-0.4
        c0.2,0.1,0.5,0.2,0.7,0.4c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3,0.3-0.4,0.4-0.5c-0.2-0.2-0.4-0.4-0.6-0.7
        c0.3-0.1,0.5,0,0.6,0.2c-0.1-0.3,0-0.5-0.2-0.8c-0.3,0-0.5,0.3-0.7,0.5c0-0.2,0.1-0.3,0.2-0.5c0.3,0,0.6-0.4,0.8,0
        c0.2-0.2,0.1-0.4,0.1-0.6c-0.3,0.1-0.7,0.1-0.7,0.5c-0.1-0.1-0.3-0.2-0.4-0.3c0.1,0,0.2,0,0.3,0c0.4-0.2,0.7-0.6,0.3-1.1l0.2,0
        c0-0.1,0.1-0.2,0.1-0.3c0.1,0,0.3-0.1,0.4-0.1c0-0.1,0-0.2,0-0.3c0.1-0.1,0.2-0.2,0.2-0.3c-0.1,0-0.2,0-0.3,0c0,0.3-0.1,0.7-0.5,0.6
        c-0.2-0.1-0.2-0.5-0.4-0.6c0,0.1-0.1,0.2-0.2,0.3c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.3-0.1,0.4-0.1l0,0.2c0.1,0.1,0.3,0.2,0.4,0.3
        c0.2-0.1,0-0.4,0-0.5c0.1,0,0.3,0,0.5-0.1c-0.2-0.1-0.3-0.1-0.4-0.3c-0.3,0.1-0.5,0.3-0.7,0.3c0.1-0.2,0.3-0.3,0.5-0.3
        c0.1-0.2,0.2-0.4,0.3-0.5c0-0.4-0.3-0.7-0.3-1.1c0.2,0.1,0.4,0.3,0.3,0.5c0.1,0.1,0.1,0.1,0.2,0.2c0-0.3,0.2-0.3,0.3-0.2
        c0.1,0.4-0.3,0.7-0.5,1c0.2-0.1,0.4-0.3,0.6-0.4c0.1-0.2,0.2-0.4,0.2-0.6l-0.1-0.1c-0.1-0.2-0.2-0.5-0.3-0.7c-0.1,0-0.3,0.1-0.4,0.1
        c0.3-0.2,0.3-0.6,0.4-0.9C299.7,122.5,299.5,122.3,299.3,122.2 M126.6,123c0.1,0.2,0.1,0.5,0,0.7c0,0.1,0,0.3,0,0.4l0.2,0l0,0.1
        c-0.1,0-0.2-0.1-0.3-0.1c0,0.3-0.2,0.7,0.1,0.8c0,0.2-0.1,0.5,0,0.7c0.2,0.6,0.5,1.3,1.1,1.7c0,0.1-0.1,0.2-0.1,0.2
        c0.1,0.2,0.3,0.3,0.5,0.3c-0.2,0.5-0.1,1.1-0.1,1.6c-0.1,0.3-0.5,0.4-0.6,0.6c-0.2,0.3-0.6,0.4-0.4,0.8c-0.1,0-0.2,0-0.3-0.1
        c0,0,0,0.1,0,0.1l0.1-0.1c0.1,0.2,0.2,0.4,0.4,0.6c-0.2,0-0.3-0.1-0.4-0.2c-0.1,0.2,0,0.4,0,0.6c-0.1,0.1-0.2,0.2-0.3,0.3
        c0.2,0,0.3-0.1,0.5-0.2c-0.1,0.2-0.1,0.5-0.1,0.7c-0.1,0.1-0.3,0.2-0.4,0.3c0.3,0.1,0.5,0.4,0.7,0.6c-0.2,0-0.3-0.1-0.5-0.2
        c0,0.2-0.1,0.4-0.1,0.6c0,0.2,0,0.4,0.1,0.6l-0.2,0c0,0.1,0.1,0.3,0.1,0.4l0.3-0.2c-0.1,0.4,0.3,0.5,0.3,0.9c-0.1,0-0.3-0.1-0.5-0.1
        c-0.1,0.4,0.3,0.1,0.5,0.1c0.2,0.1,0.3,0.3,0.4,0.5c-0.1,0.1-0.1,0.3-0.2,0.4c0.1,0,0.2,0,0.3,0c0,0.1-0.2,0.3-0.2,0.4l0.3-0.1
        c-0.3,0.8,0.4,0,0.3-0.3h0c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0.2-0.4,0.1-0.5,0.3c0.1,0.2,0.3,0.3,0.4,0.5c0.2,0,0.4,0.1,0.5,0.2
        c0.1,0,0.2,0,0.3,0c0,0.1-0.1,0.3-0.1,0.4c0.1,0.1,0.2,0.3,0.3,0.4c0.2,0,0.3,0.1,0.3,0.3c-0.1-0.1-0.4-0.2-0.5-0.2
        c0.1,0.3,0.3,0.4,0.6,0.4c-0.1,0.1-0.2,0.3-0.2,0.4c0.1,0.1,0.2,0.3,0.2,0.4c-0.2,0.2-0.4,0.2-0.7,0.3c0.1,0.1,0.1,0.2,0.2,0.4
        c-0.5-0.1-0.2,0.4-0.2,0.7c-0.2-0.1-0.3-0.3-0.5-0.4c-0.2,0.1-0.5,0.2-0.7,0.2c0.2,0.1,0.4,0.1,0.6,0.2c0.4-0.2,0.6,0.3,0.1,0.3
        c-0.1,0.3,0,0.7-0.3,0.9c0-0.2-0.1-0.4-0.1-0.6c0,0.3-0.2,0.4-0.4,0.6c0.1,0,0.2,0,0.3,0c0,0.1,0.1,0.3,0.2,0.4
        c0,0.1-0.1,0.3-0.1,0.4c-0.1-0.1-0.1-0.3-0.1-0.5c-0.3,0-0.3,0.5,0,0.5c-0.1,0.2-0.2,0.6-0.5,0.5c-0.2,0.2-0.4,0.3-0.6,0.3
        c0.1,0.2,0.3,0.5,0.4,0.1c0.1-0.1,0.3-0.1,0.4-0.2c0,0.3-0.3,0.6-0.1,0.8l-0.2,0c-0.2,0.3,0,0.7-0.3,0.9c0,0.1-0.1,0.2-0.1,0.3
        c0,0.1,0,0.3,0,0.4c0.3,0,0.2,0.3,0,0.4c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1,0.4,0.2,0.6,0.4,0.9c-0.2,0.1-0.4,0.3-0.4,0.5
        c0.2-0.1,0.4-0.2,0.5-0.3c0,0.1-0.1,0.2-0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c0.1-0.2,0.1-0.3,0.2-0.5
        c-0.5-0.1-0.4,0.5-0.6,0.7c0.1,0.1,0.2,0.2,0.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0,0.3,0,0.4,0.1c-0.1,0.1-0.2,0.2-0.3,0.3
        c0.1,0.1,0.3,0.2,0.4,0.2c-0.1,0.1-0.3,0.2-0.4,0.3l0.1-0.4c-0.1,0-0.3,0.1-0.4,0.2c0.1,0.2,0.3,0.4,0.4,0.7
        c-0.1-0.1-0.3-0.2-0.4-0.2c0,0.2,0.2,0.2,0.3,0.3c-0.1,0.1-0.1,0.3-0.2,0.4c0.1,0.1,0.2,0.2,0.2,0.3c-0.1,0.1-0.1,0.2-0.2,0.4
        c0.3,0.2,0.1,0.3-0.1,0.4c0.1,0.5,0,1.1,0.1,1.6c-0.1,0.2-0.1,0.4-0.1,0.6c0.1,0,0.4-0.1,0.5-0.1c-0.1,0.2-0.3,0.2-0.5,0.3
        c0,0.1-0.1,0.4-0.1,0.6c0.2-0.1,0.3-0.1,0.5-0.2l0,0.2c0.3,0,0.4,0.7,0,0.5c0-0.1,0.1-0.3,0.1-0.4c-0.1,0-0.3,0.1-0.4,0.2
        c-0.2,0.2,0.2,0.4,0.2,0.5c-0.2,0.2-0.4,0.4-0.3,0.7c0.1,0.3,0.1,0.6,0.1,0.9c0,0,0.1,0,0.2,0c0,0.2,0,0.5,0,0.6
        c-0.1-0.1-0.2-0.2-0.3-0.3c0.1,0.3,0.2,0.6,0.3,0.9c-0.1,0-0.3,0-0.4,0c0.1,0.1,0.1,0.3,0.3,0.4c-0.1,0.2,0,0.3,0.2,0.4
        c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.3,0.6,0.4,0.2,0.6c0,0.1,0,0.2,0,0.3c-0.1,0-0.3-0.1-0.4-0.2c0.1,0.2,0.2,0.3,0.4,0.4
        c0,0.1,0.1,0.3,0.1,0.4c-0.1,0.1-0.2,0.3-0.3,0.4c0.1,0,0.3,0,0.4,0c0,0.2-0.1,0.3-0.1,0.5c-0.1,0-0.2,0-0.3,0
        c0.1,0.1,0.2,0.3,0.3,0.4c-0.2,0.2-0.4,0.4-0.2,0.6c-0.2,0.2-0.2,0.3,0.1,0.5c0-0.2,0.1-0.3,0.2-0.5c0,0.1,0,0.3,0,0.4
        c0.1,0,0.2-0.1,0.3-0.1c0,0.1,0,0.2-0.1,0.2l0,0.1l0,0.1c-0.1-0.1-0.3-0.1-0.4-0.2c-0.2,0.5,0.2,1.1,0,1.6l0.2,0.1
        c-0.1,0.2-0.1,0.3-0.2,0.5c-0.2-0.1-0.4-0.2-0.6-0.3c0.1,0.4,0.4,0.5,0.8,0.5c-0.2,0.2-0.3,0.4,0,0.6c-0.1,0-0.2,0.1-0.2,0.1
        c0,0,0.1,0.1,0.2,0.2c-0.1,0.2-0.3,0.6,0.1,0.8c0-0.2,0.1-0.3,0.2-0.5c0,0.1,0,0.3,0,0.4c-0.2,0.2-0.4,0.4-0.5,0.7
        c0.2,0,0.3,0.1,0.5,0.1c-0.2,0.3-0.4,0.5-0.5,0.7c0.1,0.1,0.2,0.2,0.3,0.3c-0.3,0.4,0.2,0.8,0.1,1.2c-0.1,0-0.3-0.1-0.5-0.2
        c0.1,0.1,0.2,0.2,0.3,0.2c0,0.3,0.2,0.5,0.4,0.6c0,0.1,0,0.2,0,0.2l0.2,0l0,0.1c-0.1,0-0.3,0-0.4,0c0-0.1,0-0.2,0-0.3
        c-0.1,0-0.3,0.1-0.3,0.1c-0.3,0.3,0.2,0.4,0.4,0.5c0,0.2-0.2,0.3-0.3,0.4c0.2,0.1,0.3,0.3,0.4,0.4c-0.1,0.1-0.2,0.3-0.1,0.5
        c0,0.1,0,0.3,0,0.4c-0.1,0.1-0.2,0.2-0.2,0.3c0.1,0,0.2,0.1,0.3,0.2c0,0.4,0.3,0.3,0.6,0.3c-0.2,0.2-0.3,0.3-0.3,0.6l-0.2,0
        c0,0.1,0.1,0.2,0.1,0.3c-0.2,0.2-0.3,0.3-0.4,0.6l0.4-0.3c-0.1,0.2-0.2,0.6,0.1,0.8c-0.1,0.2-0.5,0.6-0.1,0.6c0,0.2,0.1,0.4,0.1,0.5
        c0.1-0.1,0.2-0.2,0.3-0.2c0,0.2-0.3,0.3-0.4,0.4c-0.1,0.3-0.1,0.6,0.3,0.5c-0.1,0.1-0.3,0.2-0.4,0.3c0.2,0.2,0.2,0.4,0,0.6
        c0.1-0.1,0.1-0.2,0.2-0.3c0.5,0.1,0,0.7,0.2,1c-0.1,0-0.3,0.1-0.3,0.1c0.2,0.2,0.6,0.4,0.1,0.6c0.1,0.1,0.2,0.2,0.3,0.2
        c-0.1,0.2-0.2,0.4-0.3,0.6c0.4-0.3,0.4,0.6,0.7,0.1c0,0.2-0.5,0.5-0.1,0.5c0,0,0,0.1,0,0.2c-0.1-0.1-0.3-0.2-0.4-0.3
        c0-0.1,0-0.2,0-0.3c-0.1,0.1-0.2,0.2-0.3,0.3c0.2,0.1,0.2,0.2,0.3,0.4c0.1,0,0.2,0,0.2,0l0,0.2c-0.5-0.2,0.2,0.4-0.3,0.6
        c0,0.1,0.1,0.2,0.1,0.3c0-0.3,0.2-0.4,0.4-0.5c0-0.1-0.1-0.2-0.1-0.3c0.2,0,0.3,0,0.5,0.1c0-0.4,0.2-0.4,0.5-0.3
        c0.2-0.3,0.3-0.9,0.7-0.9c0.2-0.3,0.4-0.5,0.5-0.8c0.3,0.2,0.6-0.3,0.4-0.5c0.2,0,0.3-0.1,0.4-0.3c0.5-0.5,0.7-1.2,1.1-1.8
        c-0.1-0.6,0.3-1.1,0.4-1.7c0-0.2-0.1-0.5-0.2-0.7c0.2-0.5,0.3-1.1,0.2-1.7c0.3-0.4-0.3-0.9,0-1.3c-0.1-0.4-0.2-0.7-0.2-1.1
        c0-0.1,0.1-0.2,0.2-0.2c-0.2-0.4-0.3-0.9-0.2-1.3c0.3-0.2,0.5-0.5,0.4-0.9c0.2-0.3,0.2-0.5,0.2-0.8c0.6-1.1,0.7-2.4,1.2-3.5
        c0.3-0.5,0.2-1,0.4-1.5c0.2-0.5,0-1.1,0.4-1.5c0-0.1-0.1-0.3-0.1-0.5l0.2,0c0-0.1,0-0.2,0-0.3c-0.1,0-0.2,0-0.3,0.1
        c0.1-0.2,0.2-0.3,0.3-0.5l-0.2,0c0.1-0.3,0.2-0.5,0.2-0.8c0.1-0.3,0.1-0.5,0.1-0.8l0.2,0.1c0-0.2,0-0.4,0-0.6l0.1,0
        c0-0.3-0.1-0.5,0.1-0.8c-0.1-0.2-0.1-0.4,0.1-0.5l0.1,0c-0.1-0.5,0.4-0.7,0.3-1.2c0.3-0.5,0-1.3,0.6-1.6c-0.2-0.6-0.4-1.1-0.6-1.7
        c-0.3-0.4-0.4-0.7-0.4-1.2c-0.5-0.4-0.6-1.1-0.7-1.7c0-0.5-0.5-0.9-0.6-1.5c-0.1-0.3-0.3-0.5-0.3-0.8c-0.2-0.6-0.5-1.3-0.7-1.9
        c0-0.2,0-0.3,0-0.5c-0.6-0.4-0.5-1.2-0.7-1.9c-0.1-0.2,0.1-0.5,0.2-0.7c-0.2-0.2-0.5-0.3-0.7-0.4c-0.1-0.2,0-0.5-0.1-0.7
        c-0.1-0.3-0.4-0.5-0.4-0.8c-0.1-0.6-0.4-1.1-0.2-1.7c-0.3-0.2-0.6-0.4-0.6-0.7c-0.1-0.6-0.4-1.1-0.5-1.6c-0.1-0.5-0.7-1-0.3-1.5
        c-0.2-0.4-0.5-0.8-0.2-1.3c-0.3-0.9-0.3-1.9,0-2.8l0,0l0,0.2c0.1-0.2,0.3-0.2,0.6-0.2c0,0.1-0.1,0.4-0.1,0.5l0.1,0
        c0-0.1,0.1-0.2,0.1-0.3c0.2,0.1,0.3,0.3,0.5,0.4l-0.2-0.5c0.2,0.4,0.7,0.5,0.9,0.9c-0.1,0-0.4,0-0.5,0c0.1,0.2,0.2,0.4,0.3,0.5
        c0-0.2-0.1-0.3-0.1-0.5c0.1,0.1,0.4,0.2,0.6,0.3c0,0.1,0,0.3,0,0.4l0.1,0c0-0.1,0.1-0.2,0.1-0.2c0.1,0,0.2,0,0.2-0.1
        c0,0.2,0,0.3,0,0.5c-0.1,0-0.2,0-0.2,0c0.1,0.1,0.3,0.2,0.4,0.4c0-0.1,0-0.3,0-0.4c0.1,0.1,0.2,0.2,0.3,0.3l-0.2,0
        c0.3,0.2-0.4,0.9-0.3,0.4c0.1-0.2-0.2-0.3-0.3-0.4l0.2,0.3l-0.3-0.1c0,0.3,0.3,0.5,0.4,0.8c0.1,0,0.2,0,0.3,0c0,0-0.1-0.1-0.1-0.2
        c0.1,0,0.2-0.1,0.2-0.2c0.1-0.1,0.3-0.4,0.3-0.5c0,0.1,0,0.3-0.1,0.5c0.1,0,0.2,0,0.2,0c-0.1,0.3-0.2,0.5-0.1,0.8
        c0.2-0.1,0.4-0.2,0.6-0.3c-0.1-0.1-0.2-0.2-0.3-0.3c0.4-0.2,0-0.4-0.2-0.5c0-0.3-0.1-0.7-0.2-1c-0.3-0.4-0.2-1-0.6-1.4
        c-0.2-0.2-0.1-0.6-0.4-0.8c-0.2-0.2-0.4-0.4-0.6-0.6c-0.1-0.3-0.2-0.6-0.3-0.9c-0.5-0.6-0.8-1.3-1.1-2c-0.7-0.5-0.7-1.4-1.2-2
        c0-0.6-0.5-1-0.9-1.4c0-0.2,0-0.5,0-0.7C126.7,122.5,126.7,122.8,126.6,123 M230.7,122.5C230.8,122.6,230.8,122.6,230.7,122.5
        M280.5,122.5c0,0.1,0.1,0.2,0.1,0.3c-0.2,0.1-0.3,0.1-0.5,0.2c0.2,0.3,0.6,0.5,1,0.7c-0.3-0.4-0.2-0.8-0.5-1.2
        C280.6,122.4,280.5,122.5,280.5,122.5 M387.8,122.3c-0.1,0.3,0.1,0.6-0.2,0.9c-0.3,0.3-0.6,0.6-0.7,1.1c0,0.3-0.4,0.4-0.4,0.7
        c-0.2,0.5-0.4,1-0.8,1.4c-0.2,0.4-0.4,0.8-0.6,1.2c-0.2,0.5-0.6,0.8-0.7,1.3c-0.1,0.5-0.5,0.8-0.8,1.1c-0.1,0.3-0.2,0.6-0.4,0.9
        c-0.2,0.3-0.2,0.7-0.4,1c-0.2,0.3-0.3,0.7-0.3,1.1c-0.1,0-0.2,0.1-0.3,0.2c-0.3,0.2,0.5,0.5-0.2,0.5c0.2,0.2,0.4,0.3,0.6,0.4
        c0.2-0.3-0.3-0.7,0.1-0.9c0-0.1,0-0.3-0.1-0.4c0.4,0.2,0.2,0.8,0.7,0.8c-0.1-0.3-0.3-0.6-0.5-0.8c0-0.1,0.1-0.2,0.1-0.3l-0.3,0
        c0.2-0.1,0.6-0.7,0.4,0c0.1-0.1,0.3-0.2,0.4-0.4l-0.2,0c0-0.2,0-0.3-0.1-0.5c0.1,0,0.2-0.1,0.3-0.1c0,0.1,0,0.3-0.1,0.3l0.1,0
        c-0.2-0.3,0.1-0.5,0.3-0.7c0,0.1,0.1,0.2,0,0.3l0,0.1c0.2,0,0.2-0.3,0.3-0.5c-0.1,0-0.3,0-0.4,0c0-0.3,0.3-0.3,0.5-0.5
        c0.1-0.2,0.3-0.3,0.5-0.5c0,0.1-0.1,0.4-0.2,0.5c0.2-0.1,0.3-0.2,0.5-0.3c0,0.1,0.1,0.3,0.2,0.4c0-0.2,0-0.5-0.1-0.7
        c0.1,0,0.4,0.1,0.5,0.1c0,0,0.1-0.1,0.1-0.1l0,0.2c0.3,0.8,0.3,1.8,0,2.7c0.3,0.4-0.1,0.9-0.2,1.3c0.4,0.5-0.2,1-0.3,1.5
        c-0.2,0.5-0.5,1-0.5,1.6c0,0.4-0.4,0.6-0.6,0.8c0.1,0.2,0.1,0.5,0,0.7c-0.2,0.5-0.2,1.1-0.6,1.6c-0.2,0.3-0.1,0.6-0.2,0.9
        c-0.3,0.1-0.5,0.2-0.7,0.4c0.1,0.2,0.3,0.4,0.2,0.7c-0.2,0.6-0.1,1.4-0.7,1.9c0,0.2,0,0.3,0,0.5c-0.3,0.6-0.4,1.2-0.7,1.8
        c0,0.3-0.1,0.6-0.3,0.8c0,0.6-0.5,1-0.6,1.6c-0.1,0.6-0.2,1.2-0.7,1.7c0,0.4-0.1,0.8-0.4,1.2c-0.1,0.6-0.5,1.2-0.5,1.8l-0.2-0.1
        c0,0.3,0,0.6-0.3,0.9c0,0.6-0.4,1.1-0.7,1.5c0,0.4,0,0.8-0.3,1c-0.1,0.6-0.5,1.1-0.6,1.7c-0.1,0.2-0.3,0.4-0.2,0.7
        c0,0.4-0.4,0.5-0.6,0.7c0.4,0.6-0.3,1.1-0.4,1.6c0.1,0.6-0.5,0.9-0.6,1.5c-0.1,0.3,0,0.6,0,0.8c0.1,0.4-0.3,0.7-0.6,1
        c0.1,0.9-0.8,1.3-0.9,2.2c-0.4,0.2-0.8,0.4-0.7,0.9c0.1-0.1,0.2-0.3,0.2-0.4l0-0.1c0.1,0,0.2-0.1,0.2-0.1c-0.1,0.3-0.2,0.5-0.3,0.8
        c0.5,0.2,0.2,1-0.3,1c-0.4,0.1-1,0.1-1,0.6c0.1,0,0.3-0.1,0.3-0.1c-0.1,0.7-0.8-0.2-1.2,0l0,0.3c0.6-0.2,0.1,0.1,0,0.2
        c-0.2,0.2-0.4,0.4-0.5,0.7c0,0.2,0,0.4,0,0.6c0.1,0,0.2,0.1,0.3,0.2c-0.2,0.1-0.3,0.2-0.4,0.3c0.1,0,0.3,0.1,0.3,0.1
        c0,0.2-0.3,0.4-0.1,0.7c0.1,0.3-0.1,0.5-0.1,0.7c0,0.2,0,0.4,0.1,0.6c0.2-0.1,0.5-0.1,0.7-0.2l-0.1-0.2c0.1,0,0.3,0,0.4,0
        c0.2-0.2,0.4-0.4,0.6-0.2c0-0.1-0.1-0.2-0.2-0.3l0-0.1c0.4,0.4,1,0.1,1.5-0.2c0.6-0.3,1.1-0.7,1.7-1c0.5-0.4,0.9-0.9,1.5-1.2
        c0.1-0.4,0.6-0.7,0.9-1c0.6-0.4,0.8-1.2,1.4-1.6c0-0.5,0.6-0.8,0.7-1.3c0-0.2,0.2-0.3,0.4-0.5c0-0.2,0.1-0.4,0.1-0.6
        c0.1,0,0.2,0.1,0.3,0.1c0-0.2,0.1-0.4,0.1-0.6c0.3-0.5,0.4-1,0.6-1.5c0.1-0.1,0.1-0.3,0.1-0.4c0,0,0.1,0,0.1,0
        c0-0.2,0.1-0.3,0.2-0.4c0.3-0.6,0.4-1.3,0.8-1.8c0.1-0.8,0.4-1.6,0.4-2.4c0-0.1,0.1-0.2,0.1-0.3l-0.1-0.1l-0.1-0.1
        c0,0,0.1-0.1,0.2-0.2c0-0.3,0-0.5,0.1-0.8c0.2-0.2,0-0.5,0-0.8c0,0,0,0,0.1,0c0.2,0.5,0.1,1.1,0.3,1.6c0,0.3-0.1,0.7,0.2,1
        c0,0-0.1,0-0.2,0c0,0.3,0,0.6,0,0.9c-0.1,0-0.2-0.1-0.3-0.1c0.2,0.1,0.2,0.4,0.2,0.6l0.2-0.1c-0.1,0.2-0.1,0.5,0.1,0.7
        c-0.1,0-0.2-0.1-0.3-0.1c0.2,0.1,0.3,0.4,0.2,0.6c-0.1,0-0.2,0.1-0.3,0.1l0.4-0.1c-0.3,0.3-0.1,0.7,0,1.1c-0.2,0.1-0.1,0.2,0,0.4
        c0,0.2,0,0.5,0.1,0.7c0.1,0.3,0.5,0.7,0.1,1c0.1,0,0.3,0,0.4,0c-0.1,0.1-0.2,0.2-0.2,0.2c0.2,0.5,0.3,1.1,0.5,1.6
        c-0.2,0-0.3,0.2-0.2,0.4c0-0.1,0.1-0.2,0.1-0.2c0.3,0,0.1,0.3,0.1,0.5c0.1,0.2-0.1,0.6,0.2,0.7c0,0.1-0.1,0.3-0.1,0.4
        c0.3,0.1,0.1,0.4,0.1,0.6c0.1,0,0.2,0.1,0.2,0.2c0.2,1,0.5,2,0.9,3c0.1,0.2,0.2,0.5,0.2,0.7c0.2,0,0.2,0.1,0.2,0.3
        c0,0.5,0.4,0.9,0.5,1.3c0.3,0.6,0.5,1.2,0.6,1.8c0.4,0.6,0.6,1.2,0.9,1.7c0.1-0.1,0.2-0.2,0.3-0.3c0-0.2-0.3-0.3-0.4-0.4
        c0.4,0,0.3-0.3,0.3-0.6c0.2-0.1,0.5-0.3,0.7-0.5c-0.3,0.1-0.6,0.3-0.9,0.5c0-0.3,0.2-0.5,0.2-0.8c0.1,0,0.2,0,0.2,0.1
        c0-0.1-0.1-0.4-0.2-0.5c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2-0.3,0.1-0.4,0.4-0.6c0-0.2-0.1-0.5-0.2-0.6c0.1,0.1,0.2,0.2,0.3,0.3l0.2,0
        c-0.1-0.2-0.3-0.4-0.4-0.5c0.2-0.2,0.4-0.4,0.5-0.7c-0.1-0.2-0.4-0.2-0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2c-0.1-0.2-0.5-1.2,0.1-0.7
        c0,0-0.1-0.1-0.2-0.2c-0.1-0.1-0.1-0.3-0.2-0.4c0.1-0.1,0.2-0.3,0.3-0.4c-0.1-0.2-0.2-0.3-0.2-0.4c0.1-0.1,0.3-0.2,0.5-0.3
        c0-0.2-0.3-0.3-0.4-0.3c0,0.1,0,0.2,0,0.3c-0.1,0-0.3,0-0.4,0c0.2-0.3,0.6-0.5,0.6-0.9c0.1,0,0.2,0,0.3,0l0-0.2
        c-0.1,0-0.3,0.1-0.4,0.2c-0.1-0.4,0.3-0.8,0.1-1.2c0.1,0,0.2-0.1,0.2-0.1c-0.1-0.3,0.1-0.5,0.2-0.7c-0.1,0-0.2,0-0.3,0
        c0,0.1-0.1,0.2-0.2,0.3c0-0.1-0.1-0.3-0.2-0.4c0.2,0,0.3,0,0.5-0.1c-0.1-0.3-0.2-0.6-0.4-0.7c0-0.1,0-0.3,0-0.4
        c0.1,0.1,0.2,0.3,0.2,0.5c0.4-0.2,0.1-0.6,0.1-0.9c-0.1,0-0.4,0.1-0.5,0.1c0.2-0.1,0.4-0.2,0.6-0.1c-0.2-0.2-0.2-0.6-0.6-0.5
        c0,0,0-0.1,0-0.2c0.2,0.1,0.4,0.2,0.6,0.4c-0.1-0.1-0.2-0.4-0.2-0.5c0.1,0,0.3,0,0.4,0c0.1-0.1,0.4-0.2,0.3-0.5
        c-0.2,0.1-0.4,0.2-0.6,0.3c-0.1-0.2-0.1-0.3-0.2-0.5l0.2,0c-0.3-0.4,0.2-0.9-0.1-1.3c0.2,0,0.5,0.1,0.6-0.2c-0.1,0-0.4,0.1-0.5,0.1
        c0-0.3,0.6-0.5,0.2-0.7c0-0.1,0.1-0.2,0.1-0.3c-0.1-0.1-0.2-0.2-0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0-0.1,0-0.2,0.1-0.3
        c-0.1,0.1-0.2,0.2-0.2,0.2c0-0.1-0.1-0.4-0.1-0.5c0.1,0,0.3,0,0.4,0c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.4,0.4-0.5,0.5-0.8
        c-0.1,0-0.3,0.1-0.4,0.2c0-0.1,0-0.2,0-0.3c-0.4-0.3,0.6-0.5,0-0.8c-0.4-0.1,0.2-0.3,0-0.5c0.1-0.1,0.2-0.2,0.2-0.3
        c-0.1,0-0.3,0-0.4,0c0.1-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.1-0.3,0.1-0.4c-0.1,0.1-0.2,0.2-0.3,0.3c0-0.2,0.1-0.6-0.2-0.6
        c0.1,0,0.3,0.1,0.3,0.1c0.1-0.6-0.4-0.9-0.7-1.3c0.3,0.1,0.5,0.3,0.7,0.5c0.1-0.4,0-0.7-0.3-1c0.1-0.1,0.2-0.3,0.3-0.4
        c-0.1-0.3-0.6-0.1-0.4-0.5c0.1,0,0.4,0.1,0.5,0.2c-0.1-0.2-0.2-0.4-0.2-0.6c-0.1,0-0.3,0-0.4,0c0-0.2,0-0.3,0.1-0.5
        c-0.1,0.2,0.1,0.6,0.3,0.3c0-0.1-0.1-0.4-0.1-0.5c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.2c0-0.4,0-0.7,0-1.1
        c-0.2-0.1-0.3-0.3-0.1-0.4c-0.1-0.1-0.1-0.3-0.2-0.4c0.1-0.1,0.2-0.2,0.2-0.3c-0.1-0.2-0.1-0.3-0.2-0.5c0.1,0,0.2,0,0.3,0
        c0-0.1,0-0.2,0-0.2c-0.1,0.1-0.3,0.2-0.4,0.2c0.1-0.2,0.2-0.4,0.4-0.6c-0.2-0.1-0.4-0.1-0.6-0.1c0.1-0.1,0.3-0.3,0.4-0.3
        c-0.1-0.1-0.2-0.1-0.4-0.2c0.1,0,0.4,0,0.5,0c-0.1-0.1-0.2-0.2-0.3-0.3c0.1,0,0.2-0.1,0.2-0.2c-0.2-0.2-0.3-0.4-0.3-0.6
        c-0.1,0-0.3,0-0.4,0c0.1,0.1,0.2,0.2,0.3,0.3c0,0-0.1,0-0.2,0c-0.1-0.1-0.1-0.2-0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.3
        c0-0.1-0.1-0.3-0.2-0.3c0.2,0.1,0.3,0.2,0.5,0.3c0-0.2-0.3-0.4-0.4-0.5c0.1-0.3,0.5-0.6,0.4-0.9c-0.2,0.1-0.3,0.3-0.3,0.4
        c-0.2-0.1-0.3-0.4,0-0.4c-0.1-0.2-0.1-0.4-0.1-0.6c-0.3-0.2-0.1-0.5-0.2-0.8l-0.2,0.1c0-0.1,0-0.2,0-0.3c0,0-0.1-0.1-0.2-0.2
        l0.1-0.1c0-0.1,0.1-0.4,0.1-0.6c-0.2,0.1-0.3,0.3-0.5,0.4c0.1-0.2,0.2-0.3,0.3-0.5c0-0.1-0.1-0.3-0.2-0.4c0.3-0.1,0.2-0.5-0.1-0.5
        c0,0.1,0.1,0.2,0.1,0.3L387,143c0-0.1-0.1-0.2-0.2-0.3c0.1-0.1,0.2-0.3,0.3-0.4c0.1,0,0.2,0.1,0.3,0.1c-0.2-0.2-0.5-0.3-0.5-0.6l0,0
        c0,0.2-0.1,0.4-0.1,0.5c-0.1,0-0.2-0.1-0.3-0.1c0-0.2-0.1-0.5-0.1-0.7c-0.1,0-0.3-0.1-0.4-0.2c0.1-0.2,0.3-0.2,0.5-0.1l0-0.1
        c0.2,0,0.4-0.1,0.6-0.1c-0.3,0-0.5-0.1-0.8-0.2c-0.1,0.2-0.3,0.3-0.4,0.4c0-0.3,0.2-0.8-0.2-0.7c0.1-0.1,0.1-0.3,0.2-0.4
        c-0.2-0.1-0.4-0.1-0.6-0.2c0.1-0.2,0.1-0.3,0.2-0.5l0.2,0.1c-0.1,0.2-0.1,0.4,0.2,0.4c0.4,0.1,0.3-0.5,0-0.5c0-0.1,0.1-0.3,0.1-0.4
        c-0.2,0.3-0.6,0.2-0.7-0.1c0.2,0,0.6,0,0.6-0.3c-0.2-0.2-0.6,0.4-0.4,0c0.1,0,0.2-0.1,0.2-0.1c0.1-0.2,0.2-0.4,0.3-0.6
        c-0.2,0-0.5,0.3-0.5,0c0.2,0,0.3,0,0.5-0.1c0.2-0.1,0.4-0.2,0.7-0.2c0.2-0.3,0.5-0.6,0.8-0.8c-0.1-0.1-0.2-0.2-0.2-0.2
        c0.4,0,0.2-0.3,0.2-0.5c0.1-0.1,0.2-0.1,0.4-0.1c-0.1,0.1-0.2,0.3-0.2,0.5c0.2,0,0.6-0.2,0.4-0.4c-0.4-0.1-0.1-0.4-0.1-0.6
        c0.1-0.2,0.3-0.4,0.5-0.5c0-0.1,0-0.2,0.1-0.3l-0.2,0c0-0.2,0.1-0.5,0.1-0.7c-0.2,0-0.4,0.1-0.6,0.2c0.1-0.2,0.3-0.2,0.5-0.2
        c0-0.1,0.1-0.3,0.1-0.4l0.2,0c-0.1-0.1-0.2-0.2-0.2-0.2l0,0.3l-0.4-0.1c0.2-0.2,0.4-0.4,0.6-0.5c-0.2-0.1-0.3-0.2-0.5-0.2
        c0-0.2,0-0.5,0-0.7c0.1,0.1,0.3,0.2,0.5,0.3c-0.3-0.2-0.2-0.6-0.2-0.9c-0.1,0.1-0.3,0.2-0.4,0.2c0.1-0.2,0.3-0.4,0.4-0.6l0.1,0.1
        c0,0,0-0.1,0-0.1c-0.1,0-0.2,0-0.3,0.1c0-0.1,0-0.4,0-0.5c-0.2,0.1-0.3,0.6-0.5,0.2l0.2,0c0-0.2,0-0.3-0.1-0.5
        c-0.2-0.2-0.5-0.3-0.7-0.5c-0.1-0.6,0.1-1.3-0.3-1.9c0.2,0.3,0.7,0,0.5-0.4c0.6-0.6,1.2-1.5,1-2.4c0.1,0,0.2-0.1,0.2-0.2
        c0-0.2,0-0.4,0-0.6c-0.1,0.1-0.3,0.2-0.4,0c0.1,0,0.3-0.1,0.3-0.1c-0.1-0.4-0.2-0.8-0.1-1.1C388.1,122.8,388,122.5,387.8,122.3
        M164.3,122.8c0-0.1,0-0.2-0.1-0.3C163.9,122.5,164,123,164.3,122.8 M164.4,122.6C164.5,122.7,164.5,122.7,164.4,122.6 M316.6,122.8
        C316.7,123.3,316.9,122.5,316.6,122.8 M230.8,122.9c-0.1,0.2,0,0.3,0.3,0.2C231.1,122.9,231,122.8,230.8,122.9 M300.1,122.9
        c-0.1,0.2,0,0.4,0.1,0.6C300.5,123.4,300.3,122.9,300.1,122.9 M230,123.8c-0.4,0.3-0.8,0.7-1,1.2c0.3-0.3,0.6-0.6,0.9-1
        c0.1,0,0.2-0.1,0.3-0.1c0-0.2,0-0.3,0.1-0.5l0.3,0c0,0.2,0,0.5-0.1,0.7l-0.1,0.1l-0.1,0.1c0.4-0.1,0.3-0.5,0.5-0.7
        c-0.1-0.1-0.3-0.2-0.4-0.3c0-0.1,0-0.3,0.1-0.3C230.3,123.2,230,123.4,230,123.8 M239.1,123c0,0.1,0,0.2,0,0.3
        c0.2,0.2,0.6,0.3,0.8,0.1c-0.2,0-0.4,0-0.5,0C239.4,123.2,239.3,123,239.1,123 M262.4,123C262.5,123.1,262.5,123.1,262.4,123
        M237.5,123.7c0.1,0,0.3,0,0.4,0c0,0.1-0.1,0.2-0.1,0.3c0.5,0,0.1,0.5,0.2,0.8c0.2-0.4,0.5-0.9,0.7-1.3c-0.3,0.1-0.5,0-0.6-0.3
        C237.8,123.3,237.5,123.4,237.5,123.7 M298.9,123.4C298.6,123.8,299.3,123.4,298.9,123.4 M167.6,124c0.2,0.3,0.6-0.3,0.4-0.4
        C167.8,123.7,167.6,123.8,167.6,124 M238.9,123.6c-0.2,0.4-0.4,0.7-0.6,1c0.1-0.1,0.3-0.3,0.4-0.4c0.2-0.1,0.4-0.2,0.5-0.3
        C239.2,123.8,239.1,123.7,238.9,123.6 M263.2,123.6C263.3,123.7,263.3,123.7,263.2,123.6 M282.9,123.7
        C282.9,123.8,282.9,123.8,282.9,123.7 M263.2,123.9C263,124.3,263.7,123.9,263.2,123.9 M283.1,123.9
        C283.2,124,283.2,124,283.1,123.9 M285.3,124.4c-0.1,0-0.3-0.1-0.4-0.2l0.2,0.2c0.3-0.1,0.5,0,0.6,0.3c0.2,0.5,0.8,0.7,1,1.3
        c0.1,0,0.2,0,0.3,0l-0.1,0.2c0.1,0,0.2,0,0.3,0c0.1,0.2,0.2,0.5,0.3,0.7c-0.2-0.1-0.3-0.3-0.5-0.4c-0.1,0-0.2,0-0.4,0
        c0,0.1,0.1,0.3,0.2,0.4c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0.1,0.2,0.3,0.3,0.4c0.4-0.1,0.4,0.4,0.6,0.7c0.1,0.1,0.2,0.1,0.3,0.2l0.1,0
        c0.1,0.4,0.6,0.6,0.6,1c0.2,0.1,0.4,0.3,0.6,0.5c0,0.1,0,0.2,0,0.2c0.6,0.2,0.9,0.9,1.6,0.8c0.1-0.2,0.2-0.4,0.1-0.7
        c0.1,0,0.2,0.1,0.3,0.2l0-0.3c0-0.1,0.1-0.2,0.2-0.3c-0.1,0-0.2-0.1-0.2-0.2l0.3-0.2c-0.2-0.1-0.5-0.2-0.6-0.5
        c-0.7,0-1.3-0.4-1.8-0.9c-0.2-0.1-0.5-0.3-0.7-0.4c0,0,0-0.1,0-0.1c-0.3,0.2-0.4-0.1-0.6-0.2c-0.4-0.3-0.8-0.5-1-0.9
        c-0.1,0-0.4,0.1-0.5,0.1c0-0.2,0.1-0.4,0.2-0.5l-0.1,0.2c-0.4-0.3-0.8-0.6-1-0.9c-0.1-0.2-0.2-0.3-0.3-0.5
        C285.3,124.3,285.3,124.4,285.3,124.4 M230.3,124.5c-0.2,0.1,0.1,0.4,0.3,0.3C230.7,124.6,230.5,124.3,230.3,124.5 M353,124.5
        c-0.1,0.2-0.4,0.5-0.3,0.7c0.1,0,0.3,0,0.3,0C353,125,353,124.7,353,124.5 M230.1,124.6c-0.1,0.3-0.3,0.5-0.3,0.9
        c0,0-0.1-0.2-0.2-0.2c0,0.1,0,0.3,0.1,0.4c-0.1,0-0.2,0-0.3-0.1l0.1,0.2l-0.3,0c0.1,0,0.2,0.1,0.3,0.1l0,0.3l-0.1,0
        c0,0.3-0.2,0.6-0.4,0.8c-0.1,0-0.2,0-0.3,0c0.1,0.1,0.1,0.2,0.2,0.4c-0.3,0.1-0.2,0.4-0.2,0.6c-0.1,0.1-0.2,0.3-0.4,0.4
        c-0.1,0.2-0.1,0.4,0,0.5c-0.1,0-0.2,0.1-0.2,0.2l0.3,0.1c-0.1,0-0.3-0.1-0.4-0.1c0.2,0.4-0.3,0.6-0.5,0.8c-0.1-0.1-0.2-0.2-0.3-0.2
        c0.1,0.1,0.2,0.2,0.3,0.3c-0.2,0.2-0.5,0.4-0.8,0.5c0.1,0,0.3-0.1,0.4-0.1c0,0.1-0.1,0.2-0.1,0.2c0.1-0.1,0.2-0.2,0.2-0.3
        c0.3-0.3,0.7-0.5,1.1-0.3c-0.2,0.1-0.4,0.4,0,0.4c0.2-0.2,0.4-0.4,0.6-0.5l0,0.3c0,0,0.1,0,0.1,0c0.2-0.3,0.4-0.6,0.5-1
        c0.2-0.5,0.6-0.9,0.8-1.4c-0.1-0.1-0.2-0.2-0.3-0.4c0.1,0.1,0.2,0.2,0.3,0.2c0.1-0.2,0.2-0.4,0.4-0.5c-0.1-0.2-0.4-0.4-0.2-0.6
        c0.1,0.2,0.2,0.4,0.4,0.4c-0.1-0.2-0.2-0.4-0.3-0.4c0-0.2,0-0.4-0.2-0.6c0.1,0,0.3,0,0.3,0.1c-0.2-0.2-0.3-0.5-0.4-0.7
        c0.2,0.1,0.4,0.2,0.5,0.3c-0.2-0.2-0.3-0.4-0.5-0.6c-0.1,0.2-0.2,0.5-0.4,0.2C230,125,230.3,124.8,230.1,124.6 M230.8,124.7
        c-0.2,0.1-0.2,0.2,0,0.3C231,124.9,231,124.8,230.8,124.7 M255.7,124.7C255.7,125.2,256.2,124.5,255.7,124.7 M228.7,125.2
        c-0.2,0-0.3,0.4-0.1,0.4C228.8,125.6,228.9,125.2,228.7,125.2 M230.8,126.4c0.2-0.1,0.2-0.2,0-0.3
        C230.6,126.2,230.6,126.3,230.8,126.4 M397.4,126.6L397.4,126.6l0.2,0c-0.1,0.2-0.2,0.3-0.4,0.3c-0.3,0-0.2,0.4-0.1,0.6
        c-0.1,0-0.2,0.1-0.2,0.1c0-0.1,0-0.2,0-0.3l-0.2,0c0,0.1,0.1,0.4,0.1,0.5c-0.4-0.1-0.4,0.4-0.5,0.7c0.2-0.2,0.3-0.4,0.5-0.5l0.1,0
        c-0.1,0.2-0.2,0.4-0.3,0.6c0.1,0.2,0.2,0.3,0.3,0.5c-0.1,0.1-0.2,0.2-0.3,0.4c0.1,0,0.3,0,0.4,0c-0.2,0.1-0.3,0.3-0.4,0.4
        c0.1,0,0.2,0,0.3,0c0.1,0.4,0.5,0.4,0.7,0.8c0.1,0,0.2,0,0.3,0c-0.2,0.5,0.2,0.9,0.2,1.4c0.1,0,0.3-0.1,0.4-0.2
        c-0.3-0.1-0.3-0.5-0.4-0.7c0.2,0,0.5,0.2,0.7,0c0,0.1,0,0.3,0,0.4c0.1-0.1,0.2-0.2,0.3-0.3c0,0.1,0.1,0.2,0.2,0.3
        c-0.2,0.1-0.5,0-0.6,0.2c0,0.3,0.5,0,0.6,0c-0.1,0.1-0.1,0.3-0.2,0.4c0.1,0,0.2,0.1,0.3,0.1c0-0.1-0.1-0.3-0.2-0.3
        c0.2,0,0.3,0,0.3-0.2l0.1-0.1c0.1,0.1,0.3,0.2,0.4,0.2c-0.1-0.2-0.3-0.4-0.6-0.4c-0.1-0.2-0.3-0.5-0.2-0.8c0-0.2,0-0.3-0.2-0.3
        c0.1,0.3,0.2,0.5,0.2,0.8c-0.4-0.1-0.1-0.7-0.6-0.7c0-0.2,0.1-0.4,0.2-0.6c-0.2-0.1-0.3-0.2-0.5-0.3c0-0.1-0.1-0.3-0.1-0.4
        c0,0.1-0.1,0.3-0.1,0.4l-0.1,0l0.1-0.2c-0.1,0-0.2-0.1-0.2-0.1c0.1-0.2,0.2-0.5,0.3-0.7l-0.2,0c0-0.4,0-0.9,0.2-1.3l-0.2,0.1
        c0.1-0.2,0.1-0.5,0.2-0.7C397.7,126.6,397.5,126.6,397.4,126.6 M66.7,127.1c0.2,0.2,0.5-0.1,0.4-0.3
        C66.9,126.7,66.6,126.9,66.7,127.1 M116.8,126.6c0,0.3,0.3,0.7,0,0.9c0,0.2,0.1,0.3,0.2,0.5c-0.2,0.2-0.2,0.3-0.1,0.5l-0.1,0
        c0-0.1-0.1-0.2-0.2-0.3c0,0.2-0.1,0.4-0.1,0.6c0,0,0.1-0.1,0.1-0.2c0.4,0,0.2,0.4,0,0.5c0.1,0,0.3,0,0.4,0c-0.1,0-0.2,0.1-0.2,0.2
        l0,0.2l-0.1,0.1c0-0.1,0-0.3,0-0.4l-0.1-0.1c0.1,0.4-0.1,0.6-0.4,0.7c0.1,0.2,0.1,0.4,0.2,0.6c-0.4,0-0.1,0.6-0.6,0.7
        c-0.1-0.3,0.1-0.5,0.2-0.8c-0.1,0-0.2,0-0.2,0.1c0,0.3,0,0.7-0.2,1c-0.3,0-0.4,0.1-0.6,0.3l0.2,0l0-0.1l0.2-0.1l0.1,0.1
        c0,0.2,0.1,0.3,0.3,0.3c0,0.1-0.1,0.3-0.1,0.3c0.1,0,0.2-0.1,0.3-0.1c-0.1-0.1-0.1-0.3-0.2-0.4c0.2,0,0.5,0.1,0.6,0.1
        c0-0.5-0.5-0.2-0.7-0.3c0.1-0.1,0.2-0.2,0.2-0.3c0.1,0.1,0.2,0.2,0.3,0.3c0-0.1,0-0.3,0.1-0.4c0.3,0.2,0.5-0.1,0.7,0
        c-0.2,0.2-0.1,0.7-0.4,0.7c0.1,0.1,0.3,0.2,0.4,0.2c0-0.1,0-0.3-0.1-0.4c0.1-0.1,0.2-0.3,0.2-0.3c0-0.2,0-0.5,0-0.7
        c0.1,0,0.3,0,0.4,0c-0.1-0.1-0.1-0.3-0.2-0.4c0.3,0.5,0.7,0,0.8-0.4c0.1,0,0.2,0,0.3,0c-0.1-0.2-0.3-0.3-0.4-0.4c0.1,0,0.3,0,0.4,0
        c-0.1-0.1-0.2-0.3-0.3-0.4c0.1-0.1,0.2-0.3,0.3-0.4c0-0.2-0.2-0.4-0.2-0.7c0.2,0.2,0.4,0.4,0.6,0.6c-0.1-0.3-0.2-0.7-0.5-0.7
        c0-0.1,0.1-0.4,0.1-0.6c-0.1,0.1-0.2,0.3-0.3,0.3c-0.4-0.1,0.1-0.4-0.2-0.6C117.4,127,117.2,126.6,116.8,126.6 M227.3,127.4
        c0.3-0.1,0.6-0.3,0.6-0.6C227.6,126.7,227.5,127.1,227.3,127.4 M447.9,127.2c0.2-0.1,0-0.4-0.2-0.4
        C447.5,126.9,447.7,127.2,447.9,127.2 M159.7,127c-0.1,0.1-0.2,0.3-0.3,0.4c0.1,0.1,0.2,0.1,0.3-0.1
        C159.7,127.2,159.7,127.1,159.7,127 M355.1,127.4c0.1,0,0.2,0,0.2,0c-0.1-0.1-0.2-0.3-0.3-0.4C355,127.1,355,127.3,355.1,127.4
        M165.1,127.2C164.9,127.6,165.6,127.2,165.1,127.2 M226.8,127.8c0.2,0.1,0.4-0.1,0.4-0.3C227.2,127.2,226.8,127.5,226.8,127.8
        M226.4,128.5L226.4,128.5c0.2-0.1,0.4-0.2,0.5-0.3c-0.1,0.3-0.3,0.6-0.6,0.5c0,0.1,0,0.3,0,0.4c-0.2,0-0.4,0.1-0.6,0.2
        c0.2,0,0.5-0.1,0.6-0.1c-0.1,0.1-0.2,0.4-0.3,0.5c0.1-0.1,0.3-0.2,0.4-0.4c0-0.6,0.6-1,0.8-1.6C226.9,128.1,226.6,128.2,226.4,128.5
        M193.7,128C193.8,128.1,193.8,128.1,193.7,128 M193.4,128.3c0.2,0.4-0.4,0.4-0.4,0.7c-0.1,0-0.2,0-0.3,0c0.1,0.3,0,0.6-0.3,0.6
        c-0.1,0.2-0.2,0.3-0.3,0.4c-0.2,0.4-0.4,0.8-0.8,0.9c0,0-0.1,0.1-0.1,0.2c-0.2,0.4-0.5,0.8-0.6,1.2c-0.1,0-0.2,0.1-0.3,0.2
        c0,0.1,0,0.3,0,0.4c-0.2,0.2-0.5,0.2-0.4,0.5c-0.3,0.3-0.4,0.7-0.7,1c-0.3,0.2-0.4,0.6-0.6,0.9c-0.2,0.2-0.1,0.6-0.5,0.6
        c-0.2,0.6-0.4,1.2-0.8,1.8c-0.1,0.2,0,0.4,0,0.6c-0.1,0.4,0.2,0.9,0,1.4c-0.2,0.3,0.2,0.5,0.3,0.8c-0.1,0.2-0.3,0.4-0.1,0.6
        c0.3,0.4,0.4,0.9,0.6,1.4c0.1,0,0.3-0.1,0.4-0.1c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0.1-0.1,0.2-0.1,0.3
        c0.2,0.1,0.5,0.1,0.6,0.3c-0.1,0-0.3-0.1-0.4-0.2c0.1,0.1,0.2,0.3,0.2,0.4c0.1,0.2,0.2,0.4,0.3,0.6l-0.3,0.1
        c0.1,0.1,0.3,0.1,0.4,0.1c-0.1,0.3-0.1,0.6,0.3,0.5c-0.1,0.2,0.1,0.4,0.2,0.6c0.3,0.6,0.3,1.2,0.7,1.8c0.1,0.2-0.1,0.4-0.2,0.6
        c0,0.2,0.1,0.4,0.2,0.5c-0.1,0.2-0.2,0.4-0.2,0.6c0.4,1.3-0.5,2.6-0.1,4c-0.1,0.2-0.2,0.4-0.3,0.6c0.4,0.5-0.1,1.1-0.1,1.7
        c-0.1,0.4,0.1,0.8,0,1.3c-0.1,0.4,0,0.9-0.3,1.3c0.2,0.5,0.3,1.1,0.1,1.6c-0.1,0.2-0.1,0.5,0,0.7c-0.3,0.3,0.1,0.6,0.1,1
        c-0.1,0.6,0.2,1.3-0.2,1.8c0.2-0.2,0.4-0.4,0.7-0.5c-0.2-0.8,0.4-1.6,0.1-2.5c0.1-0.8,0-1.6,0.1-2.4c0.1-0.5,0-1,0.3-1.4
        c0-0.1-0.1-0.3-0.1-0.4c0.1-0.7,0.2-1.5,0.4-2.1c-0.2-0.7,0-1.3,0.2-2c-0.1-0.5,0.1-1,0.3-1.4c-0.2-0.4,0.2-0.8,0.1-1.2
        c0.3-0.8,0.3-1.7,0.4-2.5c-0.1,0-0.2,0-0.3-0.1c0.1,0,0.3,0,0.4,0c0.1-0.5-0.5-0.6-0.8-0.8l0.1,0.3c0,0-0.1,0-0.2,0
        c0.3-0.4-0.4-0.7-0.6-1c-0.1-0.3-0.2-0.5-0.4-0.8c0,0-0.1,0.1-0.2,0.1c-0.1-0.3-0.1-0.5-0.3-0.8c-0.3-0.2-0.1-0.7-0.5-0.9
        c0,0.1,0,0.3,0,0.4c-0.1-0.4-0.1-0.9-0.4-1.1c0-0.1,0-0.3,0-0.4c-0.2,0-0.3,0.1-0.3,0.3c0,0-0.1,0-0.2,0c0.2-0.3,0.4-0.7,0.3-1.1
        c0.1-0.4,0-0.8,0.2-1.1c0.3-0.5,0.3-1.1,0.6-1.5c0.2-0.3,0.3-0.8,0.6-1c0-0.5,0.4-1,0.8-1.3c0,0.4,0,0.8,0,1.3c0,0,0.1,0.1,0.2,0.2
        c0,0.3-0.1,0.5-0.2,0.8c0.1,0,0.3-0.1,0.4-0.1l-0.2,0.5c0.1-0.1,0.3-0.2,0.5-0.3c0,0,0,0.1,0,0.2l-0.3,0c0.2,0.2,0.2,0.5-0.1,0.6
        c0.1,0,0.3,0.1,0.4,0.1c0.1-0.1,0.2-0.3,0.3-0.4c-0.1,0.3-0.3,0.5-0.4,0.8c0,0-0.1,0-0.1,0c0,0,0,0.1,0,0.1c0.5-0.1,0.6,0.6,0.5,1
        c0.4,0,0.3,0.4,0.3,0.6l0.3,0c0-0.1,0-0.3,0.1-0.4c0,0.2,0,0.4,0.1,0.7c-0.1,0-0.2,0-0.3,0c0.1,0.3,0.3,0.1,0.5,0
        c-0.3,0.5,0.5,0.8,0.4,1.3c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0,0.3,0,0.4,0c-0.2-0.2-0.4-0.5-0.5-0.7c0.4,0.2,0.4,0.8,0.9,0.9
        c-0.1,0.1-0.2,0.2-0.2,0.3c0.2,0.2,0.4,0.5,0.7,0.4l0,0.2c0.2,0,0.5,0,0.7,0c-0.2,0.1-0.4,0.2-0.6,0.3c0,0.1,0,0.2,0,0.3
        c0.1-0.1,0.2-0.2,0.2-0.3c0.1,0.1,0.3,0.3,0.4,0.4c0.1,0,0.2-0.1,0.2-0.1c0,0.1,0,0.3,0,0.4c0.1,0.1,0.2,0.2,0.4,0.3
        c-0.2-0.1-0.4-0.2-0.7-0.2c0.2,0.2,0.4,0.3,0.4,0.6c0.1,0,0.2,0,0.2,0c0.1,0.2,0.1,0.3,0.2,0.5c0.1,0,0.2,0,0.2,0
        c0,0.6,0.6,0.7,1,0.9c-0.3,0-0.5,0-0.7,0.1c-0.1-0.1-0.2-0.2-0.3-0.2c0,0-0.1,0.1-0.2,0.2c-0.2-0.1-0.8-0.6-0.7,0
        c-0.2-0.1-0.4-0.1-0.6-0.2c0.1,0.1,0.2,0.3,0.3,0.3c-0.2,0.3,0.2,0.4,0.3,0.5c0.4,0.4,1,0.8,1.4,1.2c0.1,0.2,0.4,0.2,0.5,0.4
        c-0.2,0.2-0.4,0.1-0.6,0c0,0.1,0,0.3,0,0.5c-0.3,0-0.5-0.1-0.6-0.4c0,0.1,0,0.3,0.1,0.4c-0.3,0-0.5,0.3-0.8,0.5c0,0.2,0,0.3,0,0.5
        c-0.3,0.1-0.4-0.2-0.2-0.4c0-0.2,0-0.3,0.2-0.4l0-0.1c-0.3,0.1-0.3,0.3-0.3,0.6l-0.2,0l0,0.3c-0.1-0.1-0.2-0.1-0.2-0.2l0,0.1
        l-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0.2-0.1,0.9-0.2,0.5,0.2c0.2,0.2,0.3,0.3,0.5,0.5c0.3,0,0.5,0.1,0.5,0.4c0.1,0,0.3,0,0.4,0
        c0.2,0.2,0.5,0.4,0.8,0.5c-0.1,0-0.4,0.1-0.5,0.2c0.2,0.4-0.5,0.4-0.4,0.7c-0.3,0.1-0.8,0.2-0.7,0.6l-0.2,0c0,0.1,0.1,0.3,0.1,0.4
        c-0.1,0.1-0.2,0.2-0.3,0.4c0.3,0,0.1,0.3,0.2,0.5c-0.1,0-0.2,0-0.3,0c0.1,0.3,0.3,0.4,0.5,0.3c-0.1,0.1-0.3,0.2-0.5,0.3
        c0.4-0.1,0.7,0.1,1.1,0.1c-0.1,0.3-0.8,0.4-0.5,0.8c0,0-0.1,0-0.1,0c0-0.1,0-0.3-0.1-0.4c-0.2,0.1-0.5,0.2-0.6,0.5
        c-0.4,0-0.6,0.3-0.6,0.7c0,0.1,0.1,0.3,0.2,0.4c-0.1,0-0.2,0-0.3-0.1l-0.1,0.2c0.3-0.1,0.4-0.1,0.4,0.2c0.1,0,0.2,0,0.3,0
        c-0.1,0.1-0.3,0.1-0.4,0.2l0,0.3c0.6,0,0.7-0.7,1.2-0.9c0-0.1,0-0.2,0.1-0.3c0.7-0.2,0.7-1,1.2-1.3c0-0.1,0-0.3,0-0.4
        c0.2-0.3,0.8-0.6,0.4-1c0.2,0.1,0.4,0.3,0.5,0.5c-0.1-0.2-0.2-0.4-0.3-0.6l0.4,0c-0.1-0.1-0.2-0.2-0.3-0.3c0.1,0,0.2-0.1,0.3-0.1
        c-0.1-0.1-0.2-0.2-0.3-0.3c0.1,0,0.2-0.1,0.3-0.1c0-0.1-0.1-0.4-0.1-0.5c0.1,0,0.3,0.1,0.4,0.2c-0.1-0.1-0.2-0.2-0.3-0.2
        c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.3-0.2-0.5c0.2,0.1,0.5,0.1,0.6,0.4c0.1,0.4,0.4,0.5,0.6,0.8c-0.1,0-0.3,0-0.3,0l0.2,0l0,0.2
        c0.2,0,0.3,0.1,0.3,0.4c0.1,0,0.2,0,0.3,0c-0.2,0.1-0.3,0.2-0.5,0.3c0.6-0.1,0.3,0.6,0.2,0.9c0.1,0.2,0.2,0.4,0.3,0.6
        c0,0.1,0,0.2,0,0.3l0.2,0.1c0-0.1,0-0.4,0-0.5c0.2,0.2,0.1,0.6,0.5,0.6c-0.2,0-0.3,0.2-0.3,0.3c0.1,0,0.2,0,0.3,0c0,0.1,0,0.2,0,0.3
        c0.2,0,0.4,0.2,0.6,0.3c-0.1,0-0.4-0.1-0.5-0.1c0.2,0.2,0.3,0.4,0.2,0.7l0.1,0c-0.1-0.3,0.1-0.3,0.3-0.4c-0.1,0.3,0.3,0.5,0.5,0.7
        c-0.1,0-0.4,0-0.5,0c0.2,0.3,0.6,0,0.7,0.3c-0.1,0.1-0.3,0.3-0.1,0.4c0,0.2,0,0.4,0.2,0.5c0-0.3-0.4-0.8,0.2-0.9
        c0,0.1-0.1,0.3-0.1,0.4l0.2-0.1l-0.1,0.3c0.3,0.3,0.7,0.6,1.1,0.7c0.2,0.1,0.3,0.2,0.3,0.4c0.2,0,0.4-0.1,0.5-0.1l-0.1,0.3
        c0.3,0,0.5,0,0.8-0.1c0,0.1,0,0.2,0,0.3c0.5,0,1.1-0.1,1.5,0.3c0.1-0.1,0.1-0.2,0.1-0.3c0,0.1,0.1,0.2,0.1,0.3c0.1,0,0.3,0,0.4,0
        c0.2,0,0.5-0.1,0.5,0.2l0.1,0c0-0.1-0.1-0.4-0.2-0.6c0.1,0.1,0.4,0.4,0.5,0.5c0-0.6,0.4-0.1,0.6,0c-0.1-0.2-0.1-0.3-0.2-0.5
        c0,0,0.1,0,0.2,0c0,0.2,0,0.3,0.2,0.5c0-0.1,0.1-0.3,0.1-0.4c0,0.2,0,0.3,0,0.5c0.1,0,0.2-0.1,0.2-0.3c0.2,0,0.5,0,0.6,0
        c0,0.1-0.1,0.2-0.1,0.3c0,0,0.1,0,0.2,0l0-0.2l0.2,0.1c-0.2-0.5,0.3,0,0.5,0c0.1-0.1,0.2-0.2,0.3-0.2c0.2,0,0.4,0.3,0.6,0.1
        c0-0.1,0-0.3,0-0.4l0,0.4c0.2-0.1,0.5-0.3,0.7-0.4c-0.1,0.1-0.2,0.3-0.2,0.4c0.3-0.1,0.6-0.3,0.7-0.5c0.3,0,0.5,0.1,0.7,0.1
        l-0.2-0.3c0.2,0,0.3,0.1,0.5,0.2c0.3-0.1,0.6-0.2,0.8-0.2l-0.1-0.2c0.1,0,0.4,0.1,0.6,0.1c0.2-0.2,0.5-0.4,0.7-0.5
        c0-0.1,0-0.2,0-0.2c0.2,0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.2,0.5-0.2c0.1-0.2,0.3-0.3,0.4-0.5c0.1,0,0.2,0.1,0.3,0.2
        c0-0.1,0-0.3,0-0.3c0.1,0,0.2,0,0.3,0c0-0.1,0-0.2,0-0.2c0.4-0.2,0.7-0.7,1.1-0.8c-0.2-0.6,0.5-0.1,0.5-0.6c0.1,0,0.2,0.1,0.4,0.1
        c-0.1-0.1-0.1-0.3-0.2-0.4c0.3,0,0.4-0.2,0.5-0.4c0.1,0.1,0.2,0.2,0.2,0.2c0-0.1-0.1-0.2-0.1-0.3c0.3,0.1,0.3-0.2,0.4-0.4
        c0.1-0.1,0.3-0.3,0.4-0.4c0.1,0.1,0.1,0.2,0.2,0.3l-0.2,0.1c0.1,0,0.2,0,0.2,0c0.1-0.3-0.1-0.8,0.2-1c0.1,0,0.3-0.4,0.3-0.1
        c0,0.1-0.1,0.2-0.1,0.3c0.1-0.1,0.2-0.1,0.3-0.2l0-0.1c0-0.3,0.1-0.6,0.2-0.9c0.1,0,0.2,0.1,0.2,0.2c0-0.2,0.1-0.3,0.1-0.4
        c0.4-0.2,0.2-0.9,0.6-1.1c0.2-0.5,0.2-1.1,0.4-1.6c0.2-0.8,0-1.6,0.2-2.3l-0.1-0.1c0.2-0.3-0.1-0.7,0-1c0-0.4-0.2-0.8-0.1-1.1
        c0.1,0.3,0.1,0.6,0.1,1c0,0.3,0.1,0.6,0.2,0.8c0,0.4,0.1,0.8,0,1.2c-0.1,0.7,0.2,1.4-0.1,2c0,0.5-0.1,1.1-0.2,1.6
        c-0.2,0.2-0.2,0.5-0.2,0.8c0,0.1-0.1,0.3-0.2,0.4c-0.1,0-0.2,0-0.3,0c0,0.2,0.1,0.4,0.1,0.7c0,0-0.1,0-0.2,0
        c-0.1,0.2-0.2,0.4-0.2,0.7l-0.1,0l0,0.2l-0.2-0.1c0,0.1,0,0.3,0,0.5c-0.1,0-0.3,0-0.3,0c0.2,0.3-0.2,0.5-0.2,0.8
        c-0.4,0.1-0.3,0.6-0.6,0.7c-0.1,0.3-0.2,0.5-0.5,0.4c0.1,0.3,0,0.7-0.4,0.6c0.1,0.3-0.3,0.6-0.6,0.6c-0.1,0.5-0.5,0.9-0.9,1.3
        c-0.1,0.2-0.2,0.4-0.5,0.4c0,0.1,0,0.2,0,0.2c-0.1,0-0.3,0-0.3,0c0.1,0.1,0.2,0.3,0.3,0.4c-0.1-0.1-0.2-0.2-0.3-0.3
        c-0.1,0.2-0.2,0.3-0.2,0.5c-0.2,0-0.4,0.1-0.3,0.3c-0.3-0.1-0.4,0.2-0.5,0.4c-0.2,0.4-0.7,0.6-0.9,1c-0.4,0.1-0.6,0.4-0.5,0.8
        c0-0.1,0-0.3,0-0.5c-0.2,0.1-0.5,0.3-0.4,0.6c0.1,0,0.3,0,0.4,0c0,0-0.1,0.1-0.2,0.2c-0.2,0-0.5-0.3-0.7,0c-0.2,0.4-0.9,0.5-0.7,1
        c0.2-0.1,0.4-0.3,0.5-0.5c-0.1,0.2-0.1,0.3-0.2,0.5c-0.1,0-0.2,0-0.3,0c-0.1,0.1-0.3,0.2-0.3,0.2c0.1-0.2,0.1-0.4,0.2-0.6
        c-0.3,0-0.4,0.3-0.5,0.5c-0.1-0.1-0.2-0.2-0.2-0.2c0,0.1,0.1,0.3,0.2,0.4l-0.3-0.1l0,0.2c-0.3,0.1-0.7,0.3-0.6,0.7
        c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0.2-0.3,0.4-0.2,0.6c-0.3,0-0.5,0.2-0.7,0.3c0,0.2,0,0.4-0.2,0.6c-0.1,0-0.3,0-0.4,0.1
        c0.2-0.1,0.3-0.3,0.4-0.5l-0.3,0c-0.1,0.2-0.3,0.3-0.4,0.5c-0.2,0-0.3,0-0.4,0.2l-0.2,0c0.1,0,0.2,0,0.2,0l0,0.3
        c-0.6-0.2-0.7,0.7-1.3,0.7c0.1,0.1,0.5,0.3,0.5,0c0-0.3,0.1-0.4,0.4-0.4c-0.3,0.2-0.3,0.8-0.8,0.7c-0.1,0.1-0.1,0.2-0.1,0.3
        c0-0.2,0-0.4,0-0.6c-0.3,0.3-0.7,0.6-1.1,0.8c-0.3,0.3-0.6,0.5-0.9,0.8c-0.3,0.1-0.8,0.3-0.7,0.7c0-0.1-0.1-0.2-0.2-0.3
        c-0.1,0.2-0.2,0.3-0.3,0.5c-0.2,0.1-0.5,0.1-0.7,0.2c-0.2-0.2-0.2-0.5-0.2-0.8c0,0.1-0.1,0.3-0.1,0.4c-0.1,0-0.2,0-0.3-0.1
        c0,0.2-0.1,0.4-0.1,0.7c0.2-0.1,0.1-0.3,0.2-0.5c0.2-0.2,0.3,0.2,0.2,0.3c-0.1,0.3-0.6,0.3-0.5,0.7l-0.1,0c0-0.5-0.3,0.1-0.4,0.2
        c0-0.1,0-0.3,0-0.4l-0.1,0c0,0.1,0,0.3,0,0.5c-0.1,0-0.3,0-0.4,0c-0.2,0.1-0.3,0.3-0.5,0.4c-0.1-0.2-0.3-0.4-0.4-0.5
        c0.1,0.1,0.1,0.3,0.1,0.4l0,0.1l0,0.2c-0.2,0.1-0.4,0.2-0.5,0.3c0.1,0,0.4,0,0.5,0c-0.2,0.3-0.7,0.3-0.9,0.6c0,0-0.1-0.1-0.2-0.2
        c0,0.2,0,0.4,0,0.6c-0.2-0.1-0.7-0.3-0.6,0.2c-0.1,0.1-0.2,0.2-0.2,0.3c-0.6,0.4-0.9,1.1-1.4,1.6c-0.1,0.1-0.1,0.3-0.2,0.4
        c0.1-0.3,0.2-0.6,0.4-0.9c0.4-0.8,0.7-1.8,1.4-2.4c-0.1-0.4,0.4-0.6,0.5-1c-0.1,0-0.2-0.1-0.3-0.1c0-0.1,0-0.2,0-0.3
        c0.1,0.1,0.2,0.2,0.3,0.3c0-0.1,0.1-0.3,0.2-0.4c-0.3-0.2-0.5-0.4-0.4-0.8c0.2,0,0.3,0.2,0.1,0.4c0.3,0.2,0.3-0.1,0.3-0.3
        c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.1-0.1-0.3-0.2-0.4c0.1,0,0.3,0,0.4,0c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.3-0.6-0.4-0.2-0.8l-0.2,0
        c0,0.1,0,0.3,0,0.4c-0.3-0.2-0.4-0.5-0.6-0.8c0,0,0,0.1,0,0.2c-0.5,0,0,0.5,0.3,0.2c-0.1,0.1-0.2,0.2-0.3,0.3
        c-0.2-0.2-0.5-0.3-0.4-0.6c-0.1,0-0.3,0-0.4,0.1c-0.2,0.1-0.4,0.2-0.6,0.4c0-0.1,0.1-0.4,0.1-0.5c-0.1,0-0.2,0.1-0.3,0.2l0-0.1
        c-0.3,0.1-0.5,0.3-0.7,0.5c-0.1-0.1-0.2-0.1-0.4-0.2c-0.1,0.1-0.2,0.2-0.4,0.3c0.1,0,0.3,0.1,0.4,0.1c0,0,0,0.1,0,0.1
        c-0.2,0.1-0.4,0.2-0.6,0.2l0.1-0.3c-0.3,0-0.5,0.1-0.4,0.5c0-0.1-0.1-0.2-0.2-0.2c-0.1,0.1-0.3,0.3-0.4,0.4c-0.1,0-0.2,0-0.3,0
        c-0.1,0.2-0.2,0.3-0.3,0.5c0,0,0.1,0.1,0.1,0.1c0-0.1,0.1-0.3,0.2-0.4c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0,0.2-0.1,0.2-0.2
        c0.3,0.2,0.1,0.6,0.2,0.9c-0.1,0-0.2,0-0.3-0.1c0,0.1,0.1,0.2,0.2,0.3c-0.2,0.1-0.8,0.5-0.3,0.6c0-0.1,0-0.3,0-0.3
        c0.6-0.4,1.3-0.7,2-1c-0.1,0.1-0.2,0.3-0.2,0.4l0.2,0c-0.1,0-0.2,0-0.3,0c0,0.1,0,0.2,0,0.3l-0.2,0c0,0.1,0,0.2,0.1,0.3
        c-0.1,0-0.2,0-0.3,0l0,0.3c0,0,0.1,0.1,0.2,0.2l-0.1,0.1l-0.1,0.1l-0.2,0c0,0.2,0,0.4-0.1,0.5c0,0.3,0,0.5,0,0.8l0.2,0
        c-0.1,0.1-0.5,0.1-0.4,0.4c0.3,0.2,0.4,0.4,0.6,0.7c-0.1,0-0.2,0.1-0.2,0.1l-0.1,0.3c0.1,0,0.4,0,0.5,0c0,0.4,0.1,0.7-0.1,1.1
        c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.3-0.1-0.5c-0.2,0-0.3-0.1-0.3-0.2c0,0.1,0,0.2,0.1,0.4c0.1,0.2,0.2,0.3,0.3,0.5l-0.2,0
        c0.1,0.1,0.2,0.2,0.3,0.3c-0.2,0.1-0.3,0.2-0.5,0.3c0-0.1-0.1-0.2-0.1-0.3c0,0-0.1,0.1-0.2,0.2c0.3,0.3,0.2,0.6,0.2,1
        c0.1-0.1,0.1-0.4,0.1-0.6c0.1,0,0.2,0,0.3,0l-0.3-0.2c0.2,0,0.3,0,0.5,0c0.1-0.2,0.2-0.3,0.4-0.4c0,0.2-0.1,0.5-0.1,0.7l0.2,0
        c-0.1,0.3,0,0.7,0.2,1l0,0.1l0.2-0.1c0,0.2,0,0.5,0.2,0.5c0.2,0.1,0.3,0.2,0.5,0.3l-0.3,0.1l0.2,0.1c0-0.1,0.1-0.2,0.1-0.3
        c0.3,0,0.7,0,0.7,0.3c0.2,0,0.4,0,0.7,0c-0.1,0.1-0.2,0.1-0.2,0.2c-0.1,0.2-0.2,0.4-0.4,0.4c0,0.4,0,0.8,0,1.1c0.1,0,0.2,0,0.3,0
        c-0.1,0.4,0.1,0.5,0.5,0.7c-0.1-0.2-0.1-0.4-0.3-0.6c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3,0,0.5,0.1c0,0-0.1,0.2-0.2,0.2
        c0,0-0.1-0.1-0.2-0.1c0,0.1,0,0.2,0,0.3c0.2,0,0.4,0,0.6,0c0,0,0-0.2,0-0.2c0.3-0.1,0.5-0.2,0.8,0c0.2-0.2,0.4-0.4,0.6-0.6
        c0,0.1,0,0.3,0,0.4c0.2-0.2,0.3-0.4,0.5-0.6c0,0.2,0,0.5,0,0.7c0.1-0.4,0.4-0.8,0.7-1c0-0.1,0-0.3,0.1-0.3c0,0,0.1,0,0.1,0
        c-0.1,0.5-0.3,0.9-0.5,1.4c-0.2,0.1,0,0.5-0.3,0.5c0.1,0.2-0.1,0.6,0.3,0.6c0.1-0.1,0.2-0.2,0.3-0.2c0,0.1-0.1,0.3-0.2,0.4l0.6,0
        c-0.1-0.1-0.2-0.3-0.2-0.4c0,0,0.1,0.1,0.2,0.2c0.1,0.4,0.4,0,0.6-0.1c0.3,0.2,0.5,0.2,0.8,0c0,0.2,0,0.4-0.2,0.6
        c0.1-0.1,0.2-0.1,0.3-0.2c-0.1-0.4,0.3-0.5,0.6-0.7c0,0,0.1,0,0.1,0c0.1-0.1,0.3-0.2,0.4-0.3c0-0.1,0-0.2,0-0.3
        c0.4-0.1,0.5-0.6,0.7-1c0-0.1-0.1-0.2-0.1-0.2c0,0,0.1,0,0.1,0c0.3,0.7,0.1,1.5,0.2,2.2c-0.1,0-0.2,0-0.3,0c0.3,0.1,0.6,0.1,0.9,0.3
        c-0.1-0.2-0.2-0.4-0.3-0.6c0.1,0.1,0.4,0.2,0.6,0.3c0-0.1-0.1-0.4-0.2-0.5c0.2,0.1,0.4,0.2,0.7,0.3c0-0.3,0.1-0.5,0.1-0.8
        c0.1,0,0.3,0.1,0.4,0.1c0-0.1-0.1-0.3,0-0.4c0.1,0.1,0.2,0.3,0.3,0.4c0-0.2,0.1-0.4,0.1-0.7c0.1,0,0.2,0,0.3,0.1
        c-0.1-0.4,0.2-0.5,0.2,0c0-0.1,0.1-0.3,0.1-0.4l0.2,0.1l0-0.3c0.2,0.1,0.3,0.3,0.5,0.5c-0.1-0.2-0.2-0.4-0.2-0.6c0.1,0,0.3,0,0.3,0
        c0-0.4,0-0.8,0-1.2c0,0,0.1,0,0.1,0c0,0.3,0,0.5,0.1,0.8c0.3,0,0.1,0.3,0,0.4c-0.1,0.3,0.2,0.5,0.4,0.7c0-0.1,0.1-0.3,0.1-0.4
        c0.1,0,0.3,0,0.4,0l-0.1-0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1-0.1,0.2-0.2,0.2-0.3c0.4-0.1,0.8-0.6,0.7-1c0,0,0.1,0.1,0.2,0.1
        c0,0.1-0.1,0.2-0.1,0.3c0.3,0,0.4-0.2,0.6-0.4c-0.1-0.3,0-0.5,0.3-0.3c0-0.1,0-0.3,0-0.4c0.1,0.1,0.2,0.2,0.3,0.3c0-0.1,0-0.4,0-0.5
        l0.1,0c0.1,0.1,0.2,0.2,0.2,0.4c0-0.2,0-0.5-0.2-0.6c0.1,0,0.2-0.1,0.2-0.1l0-0.1c0.2-0.1,0.4-0.2,0.4-0.5c0,0,0.1,0.1,0.1,0.1
        c0-0.1-0.1-0.3-0.1-0.3c0.2-0.1,0.3-0.2,0.3-0.4c0.1-0.2,0.1-0.3,0.1-0.5l0.1,0c0.1,0.2,0.3,0.3,0.4,0.1c-0.2,0-0.4-0.1-0.3-0.3
        c0,0-0.1-0.1-0.2-0.1c0.1,0,0.3,0.1,0.4,0.1c0-0.2-0.1-0.5-0.1-0.6l0.2,0c-0.1-0.2,0.1-0.4,0.2-0.5c0-0.1-0.1-0.3-0.2-0.4l0.3,0
        c-0.1,0-0.2-0.1-0.2-0.1c0.1-0.8,0.1-1.8-0.6-2.3c-0.4-0.2-1-0.2-1.5-0.2c-0.2,0.1-0.5,0.1-0.6,0.3c-0.5,0.1-1,0.5-1.4,0.8
        c-0.1,0.1-0.4,0.2-0.3,0.5c-0.1,0-0.2,0-0.3,0c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0-0.2,0-0.2,0.1c0.2-0.2,0.2-0.4,0.5-0.6
        c0.6-0.4,1.2-0.9,1.8-1.3c0.2-0.1,0.4-0.4,0.7-0.3l0-0.2c0.1,0,0.3,0,0.4,0l0-0.2c0.3,0,0.5,0.1,0.8,0.1c0.2-0.1,0.5-0.1,0.7,0.1
        c0.1,0,0.2,0,0.2,0l0,0.1c0,0,0.1,0.1,0.2,0.2l0.1,0c0.1,0.2,0.4,0.3,0.4,0.6c0.4,0.2,0.3,0.6,0.3,0.9l0.2,0
        c0.1,0.3,0.2,0.7,0.1,1.1c0,0,0.1,0,0.1-0.1c0,0.1,0,0.3,0,0.4c0.1,0,0.2,0,0.3,0l-0.2,0c0,0.1,0,0.3,0,0.4l-0.2-0.1
        c0.1,0.2,0,0.5,0,0.7c-0.1,0.1-0.2,0.1-0.3,0.2c0.2,0.3,0.2,0.6,0.1,0.9c-0.1-0.1-0.2-0.1-0.4-0.2c0.1,0.2,0.1,0.5,0.1,0.7
        c-0.5,0.2-0.4,0.9-0.7,1.3c-0.3,0.3-0.3,0.8-0.7,1.2c-0.2,0.5-0.8,0.7-0.8,1.3c0,0,0,0-0.1,0c0-0.1,0-0.4-0.1-0.5
        c-0.3,0.1-0.3,0.4-0.4,0.7c0,0.1-0.1,0.3-0.1,0.4c-0.2,0-0.3,0.1-0.5,0.1c0,0.1,0,0.3,0,0.4c-0.7,0-1.1,0.6-1.5,1.1
        c-0.3,0.1-0.7,0-0.7,0.4c-0.2-0.1-0.5,0-0.7,0c0,0.3,0.4,0.3,0.6,0.4c-0.4,0.3-0.7-0.5-1.1-0.2c0.1,0.1,0.2,0.2,0.3,0.3
        c-0.1,0-0.3,0-0.4-0.1l0.2,0.4c-0.1-0.1-0.2-0.3-0.3-0.4c-0.3,0.1-0.6,0.2-0.8,0.3c0.1,0.1,0.2,0.2,0.2,0.2c0,0,0.1,0,0.2,0.1
        c-0.2,0-0.5,0-0.6-0.2c-0.3,0.1-0.7,0.1-1,0.3c-0.3,0.1-0.6,0.2-0.9,0.3c-0.4,0-0.8,0-1.1,0.1c-0.1-0.1-0.3-0.2-0.4-0.3
        c-0.3,0.2-0.6,0.3-0.8,0c-0.1,0.1-0.2,0.1-0.3,0.2c-0.4-0.2-0.8-0.2-1.2-0.4c-0.4,0.1-0.8-0.1-1.2-0.3c-0.5-0.4-1.2-0.6-1.7-1
        c-0.1-0.4-0.4-0.7-0.6-0.9c0,0.1,0.1,0.3,0.1,0.4c0.2,0.1,0.3,0.3,0.4,0.4c-0.4-0.1-0.6-0.4-0.7-0.7c-0.1,0-0.2,0-0.3,0
        c-0.2-0.4-0.6-0.5-0.7-0.9c-0.5-0.2-0.6-0.9-1.1-1.1c-0.1-0.2-0.1-0.6-0.4-0.6c-0.2-0.4-0.6-0.7-0.6-1.2c-0.5,0-0.6-0.5-0.6-0.9
        c-0.1-0.1-0.3-0.1-0.4-0.2c-0.2-0.6-0.3-1.3-0.9-1.7c-0.3-0.3-0.3-0.7-0.5-1c-0.3-0.3-0.1-0.9-0.6-1c0-0.1,0-0.3,0-0.4
        c-0.2-0.2-0.3-0.5-0.4-0.7c0-0.1,0.1-0.3,0.1-0.3c-0.2-0.4-0.4-0.8-0.6-1.2c0,0.2-0.1,0.4-0.3,0.4c-0.3,0.1-0.4,0.5-0.5,0.7
        c0,1.1,0.2,2.1,0.3,3.1c0,0.3-0.2,0.5-0.2,0.8c-0.1,0.3,0.3,0.5,0.3,0.7c-0.1,0.3-0.3,0.7-0.2,1.1c0.2,0,0.4,0.1,0.5,0.3
        c-0.1,0.1-0.2,0.2-0.4,0.3c0.1,0,0.3,0,0.4,0c0,0.2,0,0.4,0,0.7c0,0,0.1,0,0.2,0.1c0-0.2-0.1-0.4,0.1-0.5c0.2,0.3,0.4,0.6,0.5,0.9
        c0.1,0,0.2-0.1,0.2-0.1c0.1,0.1,0.1,0.3,0.2,0.4c-0.2-0.1-0.4-0.1-0.6-0.2c0.1,0.3,0.5,0.3,0.7,0.3c0,0.1,0,0.3,0,0.5
        c0.2,0,0.4-0.1,0.6,0.1c0.3,0.4,0.8,0.7,1.1,1.2c0.1,0.1,0.2,0.1,0.3,0.2c0.3,0.5,0.9,0.7,1.3,1.1c0.2,0.2,0.5,0.3,0.8,0.4
        c0.1,0.1,0.2,0.2,0.3,0.3c0.6,0.1,0.8,0.8,1.4,0.8c0.4,0.1,0.6,0.8,1.1,0.6c0.5,0.5,1.3,0.5,1.7,1.1c0.2-0.1,0.4-0.1,0.6,0
        c0.6,0.3,1.3,0.5,2,0.7c0.4-0.1,0.9-0.3,1.3-0.3l0,0.3c0.2-0.1,0.3-0.3,0.5-0.4c0.3-0.1,0.7-0.1,0.8-0.5c0.3-0.1,0.6-0.3,0.9-0.4
        c0.1,0,0.2,0,0.3,0c0.2-0.3,0.6-0.5,1-0.3c0-0.1-0.1-0.3-0.1-0.4c0.2,0.5,0.4,0,0.6-0.1c0.4,0,0.5-0.4,0.8-0.6l0,0.3
        c0.2-0.2,0.5-0.3,0.6-0.6c0.3-0.2,0.8-0.2,0.9-0.6c0.1-0.1,0.3-0.1,0.4-0.2c0,0,0.1,0.1,0.2,0.2c0.1-0.1,0.2-0.3,0.2-0.4l0-0.1
        c0.1,0,0.3,0.1,0.4,0.2c-0.3-0.4,0.2-0.4,0.4-0.5c0.4,0,0.4-0.5,0.8-0.6c0.4-0.1,0.7-0.5,1-0.7c0.3,0.1,0.5,0,0.4-0.3
        c0.4-0.1,0.6-0.7,1.1-0.4c-0.1-0.2,0-0.4,0.1-0.6c0.6,0,0.8-0.9,1.4-0.9c-0.1,0.2-0.2,0.4-0.4,0.6c0.2-0.2,0.5-0.4,0.5-0.8
        c0-0.4,0.6,0,0.5-0.5c0.1-0.4,0.4-0.6,0.8-0.8c0-0.1,0-0.3,0-0.4c0.2-0.1,0.2-0.3,0.3-0.5c0.2-0.1,0.3-0.3,0.3-0.5
        c0.1,0.1,0.2,0.2,0.3,0.3c-0.1-0.2-0.2-0.4-0.3-0.5c0.2-0.1,0.4-0.1,0.6-0.2c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.4,0-0.5
        c0.1,0,0.3,0.1,0.4,0.1c-0.3-0.4,0.2-0.7,0.3-1.1c-0.1-0.1-0.2-0.3-0.3-0.4c0.2,0.1,0.3,0.1,0.5,0.2c0-0.1,0-0.3,0-0.4l0.1-0.1
        c-0.1-0.7,0.2-1.4-0.1-2.1c0-0.3,0-0.6-0.2-0.9c0-0.2,0-0.4-0.1-0.7c-0.5-0.8-1-1.6-1.7-2.3c0.6,0.4,1.1,1,1.5,1.6
        c0.6,0.5,0.7,1.3,1.1,2c0.3,0.9,0.4,1.8,0.6,2.7c0,0.3,0.1,0.7,0,1c0.1,0.3-0.1,0.6,0.1,0.9l-0.1,0.1c0,0.1,0.1,0.2,0.1,0.3
        l-0.4-0.1c0.1,0.1,0.2,0.2,0.3,0.3c-0.2,0.4-0.4,0.8-0.3,1.3c-0.1,0.1-0.2,0.2-0.3,0.3c0,0.3-0.1,0.5-0.1,0.8
        c-0.3-0.3-0.4,0.3-0.5,0.4c0.1,0,0.2,0,0.3,0c-0.3,0.3-0.5,0.8-1,1c-0.2,0.1-0.5,0.2-0.6,0.5c0.1,0.1,0.2,0.2,0.3,0.4
        c-0.3,0.1-0.4,0.3-0.5,0.5c-0.2,0.2-0.5,0.4-0.7,0.6c-0.1,0.1-0.2,0.3-0.2,0.4l0.2,0c0,0.1,0,0.2,0,0.3c-0.2,0-0.4-0.2-0.4,0.1
        c-0.1,0-0.3,0-0.4-0.1c0,0.1,0,0.3,0,0.4l0.3-0.2c0,0.1,0,0.3-0.1,0.4c-0.1,0-0.2,0.1-0.3,0.2c0-0.3,0-0.5,0-0.8
        c-0.1,0.1-0.2,0.3-0.3,0.5l0.2,0c0,0.1-0.1,0.3-0.1,0.4c-0.5-0.2-0.2,0.8-0.6,0.4c-0.1,0.1-0.3,0.2-0.4,0.3c0,0.1,0,0.3,0,0.4
        c0.1-0.1,0.3-0.2,0.4-0.3c-0.1,0.2-0.3,0.4-0.4,0.5c0-0.2-0.1-0.3-0.1-0.5c-0.1,0-0.3,0-0.4,0.1c0.1,0.1,0.1,0.2,0.2,0.3
        c0,0.1-0.1,0.2-0.2,0.2c-0.3-0.4-0.4,0.1-0.2,0.3c-0.1,0-0.2-0.1-0.3-0.1l-0.2,0.1l-0.1,0.2c0.1,0,0.3,0,0.4-0.1
        c0,0,0.1,0.1,0.1,0.2c-0.2,0.1-0.3,0.2-0.5,0.4c0-0.1,0-0.4,0-0.5c-0.2,0.1-0.3,0.3-0.5,0.4c0.1,0.1,0.2,0.2,0.4,0.3l0,0.1
        c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0.4-0.1,0.8-0.4,1.1c0-0.1,0-0.4,0-0.6c-0.1,0.2-0.3,0.3-0.4,0.5
        c-0.3,0.2-0.7,0.3-0.8,0.6c-0.2,0.4-0.8,0.3-0.7,0.8c0.1,0,0.4-0.1,0.5-0.1c-0.2,0.2-0.5,0.3-0.7,0.3c0,0.3-0.3,0.4-0.4,0.5
        c0-0.2-0.1-0.4-0.3-0.4c0.1,0.1,0.1,0.3,0.2,0.4c-0.2,0.1-0.3,0.2-0.3,0.4c-0.2,0.1-0.4,0.3-0.5,0.4c-0.2,0.1-0.4,0.2-0.5,0.2
        c-0.1,0.2,0,0.6-0.4,0.6c0.2,0.4-0.4,0.3-0.6,0.3c0.2,0.3,0,0.6,0,0.9c-0.1,0-0.4-0.1-0.5-0.1c-0.2,0.2-0.3,0.4-0.4,0.6
        c0.1,0,0.2,0,0.3,0l0-0.3c0.5,0,0.3,0.4,0,0.6c-0.3,0.2-0.4,0.6-0.8,0.7c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.3,0,0.4
        c-0.3,0.2-0.2,0.6-0.3,0.9c-0.4-0.1-0.5,0.2-0.5,0.5c0.1,0,0.2,0,0.3-0.1c0,0.1,0,0.2,0,0.3c-0.2-0.1-0.3-0.1-0.5-0.2
        c0,0.1,0.1,0.3,0.2,0.4c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0,0.2,0.1,0.2c-0.2,0.7-0.4,1.4-0.2,2.1c0,0.3-0.2,0.7,0,1
        c-0.2,0.5-0.5,1.1-0.1,1.6c-0.1,0.1-0.2,0.3-0.2,0.4c0.1,0.1,0.2,0.2,0.3,0.2c-0.1,0.4,0.1,0.8,0.1,1.2c0.2,0.2,0.4,0.4,0.6,0.4
        c0,0.1-0.1,0.2-0.2,0.3c0.1-0.1,0.2-0.1,0.3-0.1c0.2,0.2,0.5,0.4,0.7,0.7c0.2,0.2,0.2,0.4,0.4,0.5c0,0.1,0,0.2,0,0.2
        c0.1,0,0.3-0.1,0.4-0.1c0,0.2,0.1,0.3,0.3,0.5c0.4-0.6,0.6-1.4,1.1-2c0,0.1,0,0.3,0,0.4c0.2-0.2,0.1-0.5,0.2-0.7
        c0.1-0.2,0.2-0.6,0.5-0.6c0-0.1-0.1-0.2-0.1-0.2c0.1,0,0.2,0,0.2,0.1c0.1-0.2,0.3-0.4,0.3-0.6c0,0,0.1,0,0.2,0.1
        c0.1-0.4,0.4-0.6,0.6-0.9c0.3,0,0.5-0.3,0.7-0.3l0-0.1c-0.1,0-0.3,0.1-0.4,0.1c0-0.3,0.3-0.3,0.5-0.3c0.1-0.2,0.2-0.5,0.4-0.6l0,0.5
        c0.1-0.3,0.3-0.6,0.6-0.8l0.2,0.1c0-0.1,0-0.2-0.1-0.3c0.1,0,0.3,0.1,0.3,0.2l-0.1-0.4c0.1,0,0.3,0.1,0.3,0.1l0-0.2
        c0.1,0,0.2,0,0.2,0c0-0.3,0.2-0.3,0.5-0.3c0.2-0.2,0.3-0.5,0.6-0.5c0.1-0.1,0.3-0.2,0.5-0.3c0.2-0.2,0.3-0.2,0.6-0.1
        c-0.1,0.1-0.3,0.2-0.4,0.3c0.3,0.1,0.5-0.1,0.7-0.2c-0.1,0-0.3-0.1-0.4-0.1c0.2-0.1,0.4-0.2,0.6-0.3c0.1,0.1,0.2,0.2,0.2,0.3l0-0.2
        c0.1-0.1,0.3-0.2,0.5-0.2l0.1-0.2c0,0.1,0,0.3,0.1,0.4c0.2-0.1,0.3-0.3,0.3-0.5c0.2,0,0.5,0.1,0.7,0.1c0-0.1,0.1-0.3,0.1-0.4
        c0.1,0.1,0.3,0.3,0.4,0.4c0-0.1,0-0.3,0-0.4c0.2,0,0.3,0,0.5,0.1v0.3c-0.1,0-0.3-0.1-0.4-0.1c0,0,0,0.1,0,0.1c0.3,0,0.6,0.1,0.8-0.1
        c-0.1,0-0.3,0.1-0.4,0.1c0-0.1,0-0.3,0-0.4c0.1,0,0.3,0,0.4,0.1c0.2-0.1,0.5-0.3,0.5,0c-0.1,0-0.3,0.1-0.4,0.1c0,0,0,0.1,0,0.2
        c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.5,0,0.6-0.2c-0.1,0-0.2-0.1-0.3-0.1c0.3,0.1,0.5,0.2,0.8,0.3l-0.1-0.3c0.1,0.1,0.2,0.2,0.4,0.4
        c0.1-0.2,0.2-0.3,0.3-0.3c-0.1,0.5,0.5,0.2,0.7,0.3c0-0.1,0.1-0.2,0.2-0.3c0,0.2,0,0.4,0.1,0.6l-0.1-0.3c0.1,0,0.3,0.1,0.4,0.1
        c-0.1-0.1-0.2-0.2-0.3-0.3c0.2,0,0.3,0,0.5,0c0,0.1,0,0.2,0,0.2c0.6,0,1.1,0.4,1.7,0.2l-0.1-0.2c0,0,0.1,0,0.1,0c0,0.1,0,0.3,0,0.4
        c0.2,0,0.4,0,0.7,0c0.1,0,0.2,0.1,0.3,0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0,0.3,0,0.4,0.1
        c-0.3,0-0.6,0-0.8-0.1c-0.1,0-0.2,0-0.2,0l-0.1-0.1c-0.5-0.1-1-0.1-1.4-0.3c-0.1,0.1-0.3,0.1-0.4,0c-0.3-0.1-0.6,0.1-0.9,0
        c-0.6-0.2-1.2,0-1.7,0c-0.1,0.1-0.3,0.1-0.5,0.1l0,0.1c-0.5-0.3-1.1,0.1-1.7,0c-0.2,0.3-0.6,0.2-0.9,0.2c0,0.1-0.1,0.2-0.2,0.2
        c-0.2-0.1-0.3-0.1-0.5-0.2l0,0.2c-0.5-0.1-0.9,0.2-1.4,0.4c-0.5,0-0.9,0.3-1.4,0.3c-0.3,0.2-0.5,0.5-0.8,0.5
        c-0.2,0.1-0.3,0.1-0.4,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3,0-0.5,0.2-0.6,0.4c-0.6,0.3-1.2,0.8-1.6,1.3c-0.1,0-0.2-0.1-0.4-0.1
        c0,0.2,0.2,0.4,0,0.5c-0.2,0.3-0.6,0.5-0.6,0.9c-0.3,0-0.3,0.2-0.3,0.5c-0.1,0-0.3,0-0.4,0c0,0,0,0.1,0,0.1l0.3,0
        c0,0.3-0.2,0.5-0.4,0.5c0,0.1,0,0.2,0.1,0.3c-0.1,0-0.3,0-0.3,0c0.2,0.2,0,0.4,0,0.7c-0.1-0.1-0.2-0.2-0.2-0.3
        c-0.2,0.2-0.3,0.4-0.3,0.7l-0.1,0l0.1,0.1c0-0.3,0.2-0.6,0.5-0.4c-0.2,0.2-0.3,0.5-0.5,0.7c0,0,0.1,0.1,0.2,0.1
        c-0.1,0.1-0.2,0.3-0.3,0.5l-0.2-0.1c0,0,0,0.1,0,0.1l0.2,0l0,0.1c0.2,0.1,0.4,0.3,0.6,0.4c0.3,0.4,0.8,0.4,1,0.7
        c0.2,0.1,0.3,0.1,0.5,0.2c0.2,0.2,0.5,0.4,0.8,0.5c0.2,0.1,0.3,0.2,0.5,0.3c0.1,0,0.2,0,0.3,0c0,0.1,0,0.2,0,0.3c0.1,0,0.2,0,0.3,0
        l0,0.2c0.1,0,0.3-0.1,0.4-0.1l0,0.2c0.1,0,0.3,0,0.4,0c0.1,0.3,0.5,0.2,0.7,0.5c0.2,0,0.4,0.1,0.6,0.2c-0.3,0.1-0.6-0.2-0.9-0.3
        c-0.1,0-0.3,0-0.3,0c0-0.1,0-0.2,0-0.2c-0.2,0.1-0.4,0-0.5-0.2c-0.1,0-0.2,0-0.3,0.1l-0.1-0.1c-0.1,0-0.2,0-0.2,0c0,0,0-0.2,0-0.2
        c-0.5,0.1-0.9-0.3-1.3-0.4c-0.1-0.2-0.3-0.2-0.5-0.1c0,0,0-0.1,0-0.2c-0.2,0-0.4-0.1-0.6-0.2c-0.2,0-0.3-0.1-0.5-0.2
        c0,0-0.1,0.1-0.2,0.2c-0.1,0-0.2-0.1-0.3-0.2l0-0.2c-0.3,0.1-0.6,0.2-0.7,0.5c0,0.3-0.3,0.6-0.2,0.9c-0.5,0-0.3,0.5,0,0.1
        c0,0.1,0.2,0.2,0.2,0.2c-0.1,0.1-0.3,0.2-0.4,0.4c0.1,0.1,0.2,0.2,0.2,0.3c0-0.2,0-0.3,0.1-0.5c0.1,0.1,0.2,0.3,0.3,0.4
        c-0.1,0-0.2,0.1-0.3,0.1l-0.1,0.4c0.1,0,0.3-0.1,0.3-0.2c0,0.1,0.1,0.4,0.1,0.5c-0.1,0-0.3,0-0.3,0c0,0.1-0.1,0.2-0.1,0.3
        c0.1-0.2,0.3-0.2,0.5-0.2c0,0.1-0.1,0.2-0.2,0.3c0,0,0.1,0.1,0.1,0.2c-0.1,0-0.2,0.1-0.3,0.1c0.2,0.3,0.4-0.1,0.6-0.2
        c0,0.3,0,0.6,0,0.8c-0.2-0.1-0.5-0.1-0.7-0.2c0.2,0.1,0.4,0.3,0.6,0.6c0-0.1,0-0.3,0-0.4l0.4,0c-0.1,0.2-0.2,0.3-0.4,0.5
        c0.1,0,0.3,0.1,0.4,0.1c0.1,0.2,0.1,0.5,0.4,0.4c0,0.1,0,0.3-0.1,0.4l0.3-0.1c-0.1,0.2-0.2,0.4-0.2,0.6l0.1,0c0-0.1,0-0.3,0-0.3
        c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0-0.2,0-0.3,0c0.4,0.3,0.4,0.8,0.3,1.3c0.2-0.2,0.2-0.5,0.3-0.7c0.1,0.2,0.2,0.4,0.1,0.6
        c0,0,0.1,0,0.1,0c0,0.1,0,0.2,0,0.3c0.2,0,0.4,0,0.5,0c0,0.2,0.1,0.4,0.3,0.5c0-0.1,0-0.4,0-0.5c0.2,0.1,0.1,0.3,0,0.5
        c0.2,0.2,0.3,0.4,0.5,0.5c0-0.1-0.1-0.3-0.1-0.4c0.1,0,0.3-0.1,0.4-0.2c-0.1,0.1-0.3,0.3-0.1,0.5c0,0.4,0.3,0,0.5-0.1
        c0,0.3,0.1,0.5,0.4,0.4c-0.1,0.1-0.1,0.3-0.2,0.4c0,0,0.1-0.1,0.2-0.1l0.1-0.3c0.2,0.2,0.4,0.4,0.7,0.4c0.1,0,0.2-0.1,0.3-0.2
        c-0.1,0.2-0.4,0.3-0.4,0.5c0.3-0.1,0.7-0.3,0.6,0.2c0.1-0.1,0.2-0.4,0.3-0.2c0.2,0.2,0.3,0,0.4-0.1c0,0.4,0.5,0.2,0.6,0.6
        c0.3,0,0.6,0,0.8-0.2c0.1,0.1,0.2,0.2,0.4,0.4c0-0.1,0-0.2,0-0.3c0.3-0.1,0.6,0.1,0.8,0.2c0.2-0.1,0.4-0.2,0.5-0.4
        c0.1,0.1,0.2,0.3,0.3,0.4c0.2,0,0.4,0,0.6,0c-0.3,0-0.5-0.1-0.5-0.3c0.2,0,0.5-0.2,0.6,0c0.1,0.3,0.4,0.2,0.5,0c0.1,0,0.3,0,0.4-0.1
        c0.5,0.1,1-0.1,1.5,0c0,0,0.1-0.1,0.1-0.2c0.2,0.1,0.3,0,0.4-0.2c0.5,0.2,0.8-0.3,1.3-0.2c0.2-0.3,0.4-0.1,0.6,0
        c0.4-0.4,1-0.3,1.5-0.6c0.4-0.1,0.8-0.3,1.2-0.3c0.5-0.2,0.8-0.7,1.4-0.6c0.3-0.3,0.6-0.5,1-0.4c0.1-0.1,0.2-0.2,0.2-0.3
        c0.3,0,0.6-0.1,0.8-0.3c0.6-0.4,1.3-0.5,1.8-1.1c0.2-0.3,0.6-0.1,0.9-0.4c0.1-0.1,0.3-0.2,0.5-0.3c0.1-0.4,0.5-0.4,0.8-0.5
        c0.3-0.9,1.4-1,2-1.7c0.4-0.2,0.8-0.5,1.2-0.7l-0.2-0.3l0.3,0.2c0.1-0.3,0-0.6,0.1-0.9c0.1-0.1,0.3-0.2,0.5-0.2
        c0.1,0,0.3,0.1,0.4,0.2c0-0.2,0-0.5,0-0.7c-0.2,0.1-0.3,0.3-0.5,0.4c-0.2-0.4,0.3-0.5,0.6-0.5c-0.1-0.1-0.1-0.2-0.2-0.3
        c0.3-0.1,0.6-0.2,0.7-0.5c-0.2,0-0.5,0-0.6,0c0,0,0-0.1,0-0.1c0.1,0,0.4,0,0.5,0l-0.1-0.2c0.1,0,0.2,0,0.3,0c0.1-0.2,0.4-0.7,0-0.6
        c0,0.1,0,0.2,0,0.3c-0.1,0-0.3,0-0.4,0l0-0.1l0.2,0c-0.2-0.3,0.1-0.5,0.4-0.4c-0.1-0.2-0.2-0.5-0.2-0.7c0.2,0.1,0.4,0.2,0.6,0.3
        c-0.1-0.3-0.3-0.6-0.4-0.8c-0.3-0.2-0.4-0.5-0.7-0.8c-0.1-0.2-0.1-0.6-0.4-0.4c0-0.1,0-0.3,0.1-0.4l-0.2,0c-0.1-0.1-0.2-0.2-0.3-0.3
        c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1,0.1-0.2,0.3-0.2,0.4l0-0.2c-0.3,0,0-0.4-0.2-0.5c-0.3-0.2-0.2-0.5-0.3-0.8c-0.2,0.2-0.2,0.4-0.2,0.6
        c0,0-0.1,0-0.1,0c0-0.1,0.1-0.2,0.1-0.3c-0.4-0.1-0.3-0.3-0.1-0.4c-0.4,0.1-0.6-0.4-0.4-0.6c-0.1,0-0.3,0-0.4,0
        c0,0.1,0.1,0.2,0.1,0.3c-0.1-0.3-0.5-0.1-0.4-0.4c0.1,0,0.3,0.1,0.4,0.1c0-0.1-0.1-0.3-0.1-0.4c-0.1,0-0.3,0.1-0.4,0.1
        c0-0.1-0.1-0.3-0.1-0.4c-0.1,0.1-0.2,0.2-0.3,0.2c0.1-0.1,0.2-0.2,0.3-0.2c-0.2-0.2-0.4-0.3-0.5-0.6c-0.1-0.2-0.1-0.5-0.4-0.3
        c0.1,0.1,0.2,0.2,0.3,0.3c-0.8,0.3-0.5-1.1-1.4-0.8c0.1-0.1,0.2-0.3,0.2-0.4c-0.1,0-0.2,0-0.2,0c-0.1,0.1-0.1,0.3-0.2,0.4
        c-0.1-0.5-0.6-0.7-1-0.8c0,0-0.1-0.1-0.2-0.2c-0.5,0-0.6-0.5-1.1-0.6c-0.2-0.2-0.6-0.3-0.8-0.5c-0.5-0.3-1.2-0.2-1.7-0.7
        c-0.9-0.1-1.8-0.3-2.7-0.5c-0.8-0.1-1.5-0.1-2.3-0.3c-0.3,0-0.6,0-0.9-0.2c-0.2,0-0.4,0.1-0.7,0.1c-0.2,0-0.3-0.3-0.5-0.2
        c-0.3,0.2-0.6,0-0.9-0.1c-0.3,0.1-0.6,0.1-0.9,0.1c-0.6,0-1.1,0.1-1.7,0c-0.3,0.2-0.6,0.2-0.9,0.2l-0.1-0.2
        c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.2,0.2-0.2,0c-0.3,0-0.6,0-0.9,0c0.1,0.2,0.4,0.1,0.6,0c0,0.6-0.8,0.3-1.2,0.4
        c-0.1,0.1-0.2,0.3-0.3,0.4c-0.3,0-0.4,0.2-0.5,0.3c-0.1,0-0.2-0.1-0.3-0.1c0,0.1,0,0.2,0,0.2c-0.4,0.1-0.7,0.3-1,0.5
        c0-0.1-0.1-0.2-0.1-0.3c0,0.1-0.1,0.2-0.1,0.3c-0.2,0-0.3,0-0.5,0.1c-0.1,0.1-0.3,0.2-0.4,0.3c-0.3-0.3,0-0.7,0.4-0.6
        c-0.1,0-0.2,0-0.2,0c0,0,0.1-0.1,0.1-0.1c-0.2,0.1-0.3,0.2-0.5,0.1c0,0.2,0,0.4,0.1,0.6l-0.1,0l0,0.2c-0.3-0.1-0.5,0.2-0.8,0.4
        c0-0.1,0.1-0.4,0.1-0.5l-0.1,0c-0.1,0.2-0.1,0.4-0.1,0.5c-0.1-0.1-0.2-0.3-0.3-0.4c0,0.1-0.1,0.2-0.1,0.3c0.1,0,0.2,0,0.2,0l0,0.2
        c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0-0.3,0c0,0.1,0,0.3,0,0.3c-0.1,0-0.2-0.1-0.3-0.1l-0.1,0.2l0,0.1c0.1,0.1,0.1,0.1,0.2,0.1
        c0,0,0,0.1,0,0.1c-0.1,0-0.2-0.1-0.3-0.1l0,0.3c0,0-0.1,0-0.1,0c0-0.1,0-0.2,0-0.3l-0.1,0l-0.1,0c0.3-0.5,0.6-0.9,1-1.3
        c0.7-0.5,1.1-1.3,1.7-1.9c0.3-0.7,0.9-1,1.4-1.6c0.3-0.3,0.9-0.5,0.6-1c0,0.1,0.1,0.2,0.2,0.2c0.3-0.4,0.7-0.5,1-0.9
        c0.1-0.2,0.3-0.3,0.5-0.4c0.4-0.6,1.1-1,1.5-1.6c0.2-0.3,0.7-0.5,0.9-0.8c0.3-0.7,1.1-1,1.4-1.7c0.2-0.6,0.7-0.9,1-1.3
        c0.2-0.5,0.6-0.9,0.9-1.4c0-0.1-0.1-0.2-0.1-0.2c0.2-0.4,0.4-0.8,0.4-1.2c0.2-0.5,0.4-1,0.5-1.5c0.3-0.5,0.2-1.2,0.6-1.6
        c0-0.5-0.1-1,0.1-1.5c0.3-0.8,0-1.7,0.2-2.5c-0.1-0.5-0.1-1,0.2-1.4c-0.1-0.1-0.1-0.3-0.2-0.4c0-0.2,0.2-0.5,0-0.7
        c0-0.1,0.1-0.2,0.2-0.2c0-0.2,0-0.3,0-0.5c-0.3-0.3-0.1-0.7-0.1-1.1c0.1-0.3-0.1-0.6-0.1-0.9c0-0.1,0.1-0.2,0.1-0.3
        c-0.2-0.4-0.2-0.9,0.2-1.3c-0.2-0.3-0.3-0.6-0.2-1c0.2-0.4-0.2-0.9,0.2-1.3c-0.4-0.7,0.1-1.4-0.2-2.1c0.2-0.5,0-1,0.2-1.5
        c-0.1-0.2-0.2-0.4-0.1-0.6c0.1-0.8,0-1.5,0.2-2.3c-0.2-0.4-0.1-0.9,0-1.3c-0.1-0.4-0.2-0.7,0.1-1c0-0.3,0-0.5-0.1-0.8
        c0.2-0.7-0.2-1.4,0.1-2c-0.1-0.2-0.1-0.5-0.2-0.7c0.2-0.4-0.1-0.8,0.1-1.3c0.2-0.6-0.1-1.3,0.2-1.9c0-1-0.1-2.1,0.2-3.1
        c0-0.1-0.1-0.2-0.1-0.2c0-0.4,0-0.8,0-1.2c0.1-0.6,0.1-1.2,0-1.8c0.1-1,0-2,0-3c0.3-0.6-0.1-1.1,0.2-1.7c-0.1-0.5-0.2-1,0-1.5
        c-0.1-1.1,0.1-2.1-0.1-3.2c0-0.1,0.1-0.2,0.1-0.3c-0.2-0.5,0.1-1.1-0.2-1.6c-0.1,0.1-0.3,0.2-0.4,0.2c0.2-0.2,0.5-0.4,0.5-0.7
        c0-0.3,0.2-0.5,0.1-0.8c-0.2-0.4-0.1-0.8,0-1.2c-0.1-0.2-0.3-0.6-0.4-0.2l-0.1,0c0,0.3-0.3,0.5-0.5,0.8c-0.3,0.5-0.8,0.8-1.1,1.3
        c0.1-0.3,0.3-0.6,0.6-0.8c0.1-0.4,0.6-0.6,0.5-1.1c0.2-0.3,0.4-0.6,0.6-1c0.1-0.7,0.6-1.2,0.9-1.8c0.3-0.4,0.7-0.9,0.3-1.4
        c-0.2,0.3-0.3,0.7-0.4,1c-0.3,0.2-0.5,0.5-0.7,0.8c-0.3,0.3-0.5,0.6-0.7,1c0.1,0.2,0.2,0.3,0.2,0.5c-0.2-0.2-0.5,0-0.7,0
        c0,0.3-0.3,0.4-0.3,0.7c-0.4-0.1-0.4,0.4-0.7,0.6c0.2,0.1,0.3,0.1,0.5,0c0,0.2-0.1,0.5-0.3,0.4c0,0.2,0,0.5,0,0.7
        c-0.4,0,0-0.6-0.1-0.8c-0.2,0.1-0.4,0.2-0.6,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0-0.1,0.1-0.2,0.1c0.2,0.3-0.1,0.4-0.2,0.6
        c-0.1,0-0.2-0.1-0.3-0.1c0.1,0.3-0.2,0.6-0.5,0.8c0.1-0.1,0.2-0.3,0.3-0.4c-0.1,0-0.2-0.1-0.2-0.2c0.4,0.2,0.4-0.7,0.7-0.3
        c0.1-0.1,0.2-0.1,0.2-0.2c-0.1-0.3-0.1-0.6,0-0.8c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0-0.3,0.2-0.4,0.3c0.1,0.3,0,0.4-0.3,0.5
        c-0.1,0.1-0.3,0.2-0.4,0.3c0.1,0.3-0.1,0.3-0.4,0.4c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1,0.2-0.1,0.5-0.3,0.6c0-0.1,0-0.3,0-0.4
        c-0.1,0-0.2,0.1-0.3,0.1c0.1,0.3,0,0.4-0.3,0.4l0,0.1c-0.6-0.2-0.8,0.5-1.3,0.6l-0.1,0.1c0.1,0,0.4-0.1,0.6-0.1
        c0.1-0.2,0.3-0.3,0.5-0.5l0,0.3c-0.4,0.1-0.7,0.3-1,0.4c-0.1,0.3-0.3,0.1-0.5,0.1c0,0.1,0,0.2,0,0.3c-0.3,0-0.6,0.1-0.8,0
        c0.1,0.1,0.2,0.3,0.2,0.5c-0.1-0.1-0.4-0.2-0.5-0.2l0,0.3c0-0.1-0.1-0.3-0.2-0.3c-0.2,0.2-0.2,0.5-0.5,0.6c0-0.1,0-0.3,0-0.4
        c-0.2,0.1-0.4,0.3-0.2,0.5c-0.1,0-0.4,0-0.5,0l0-0.3c-0.2,0.1-0.4,0.4-0.6,0.1c0,0.1,0,0.3,0,0.3c-0.4,0.3-0.8,0.3-1.3,0.3
        c-0.4,0.3-0.9,0.4-1.4,0.4l0,0.2c-0.1,0-0.2-0.1-0.3-0.1c0,0.1-0.1,0.2-0.1,0.3c-0.2-0.1-0.4-0.2-0.5-0.3c0,0.1-0.1,0.2-0.1,0.3
        c-0.4-0.1-0.7,0.1-1.1,0.2c0-0.1,0.1-0.2,0.1-0.4c0,0-0.1-0.1-0.2-0.2c0,0.1,0,0.3,0,0.4c-0.3,0-0.4-0.3-0.6-0.4
        c0,0.1,0.1,0.3,0.1,0.3c-0.2,0.2,0.2,0.3,0,0.5c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.1-0.4-0.1-0.6-0.2c-0.2,0-0.4,0.3-0.6,0.3
        c0.1-0.1,0.2-0.2,0.2-0.3c-0.1,0-0.4,0-0.5,0l0-0.2l-0.2,0.1c0.1,0.2,0.2,0.3,0.2,0.5c-0.1-0.1-0.2-0.2-0.4-0.3
        c-0.2,0.1-0.3,0.2-0.5,0.2c0-0.3,0.4-0.3,0.5-0.5c-0.3-0.3-0.6,0.1-0.6,0.3c-0.1-0.1-0.2-0.3-0.2-0.4c-0.3,0.1-0.6,0.1-0.9,0.1
        c0-0.1,0.1-0.3,0.1-0.4c-0.3,0.1-0.4,0.3-0.4,0.6c-0.3-0.1-0.1-0.3,0-0.5c-0.2,0-0.3,0.1-0.5,0.1c-0.1-0.1-0.1-0.2-0.2-0.4
        c-0.1,0-0.3,0-0.4,0c0.1,0.3,0.2,0.5,0.3,0.8c-0.2-0.1-0.3-0.4-0.6-0.5c0.1-0.1,0.2-0.3,0.3-0.4c-0.5,0.1-1-0.1-1.4-0.4
        c-0.3,0.1-0.5,0.2-0.7,0.4c0,0.1,0.1,0.2,0.1,0.2l-0.3-0.1c0.2-0.2,0.4-0.4,0.5-0.6c-0.5-0.1-0.8-0.6-1.3-0.6
        c-0.3-0.2-0.6,0-0.9,0.1c0.2-0.1,0.4-0.2,0.5-0.4c-0.2-0.2-0.4-0.3-0.5-0.5c-0.1,0.1-0.1,0.1-0.2,0.2c0.1,0.1,0.3,0.2,0.4,0.3
        c-0.1,0-0.3,0-0.4,0c0-0.2,0-0.3-0.1-0.5c-0.3,0.1-0.4-0.2-0.5-0.4c-0.2,0-0.4-0.1-0.6-0.1l0.1,0.2c-0.1,0-0.3,0-0.4,0
        c0.1-0.1,0.3-0.2,0.4-0.3c0-0.1-0.1-0.4-0.2-0.5c-0.2,0-0.4-0.1-0.7-0.1c0.1-0.1,0.2-0.2,0.3-0.2c-0.2-0.1-0.4-0.3-0.7-0.4
        c0,0.1,0,0.3,0,0.4c-0.2-0.3,0.1-0.8-0.3-0.9c-0.2-0.2-0.5-0.3-0.5-0.7c-0.1,0.1-0.2,0.2-0.3,0.3c0-0.2-0.1-0.4-0.2-0.6
        c0,0-0.1,0-0.2,0c-0.2-0.2-0.3-0.4-0.5-0.5c0.1-0.1,0.1-0.2,0.2-0.4c0,0.1,0.1,0.4,0.1,0.5c0.1,0.1,0.2,0.3,0.5,0.3
        c0.1-0.6-0.4-1.3-1-1.3c0,0,0-0.1,0-0.1c0.1,0,0.3,0.1,0.4,0.1c-0.1-0.4-0.3-1.3-0.9-1.1c0,0,0.1-0.1,0.1-0.1l0.2,0
        c-0.1-0.1-0.2-0.3-0.3-0.4c0,0,0.1-0.1,0.1-0.2c-0.1-0.3-0.2-0.7-0.5-0.8c0.1-0.2,0.1-0.4,0.2-0.6c-0.1-0.1-0.2-0.2-0.3-0.3
        c-0.1,0-0.2,0.1-0.3,0.1c0-0.2,0-0.6,0-0.7c0.1,0,0.2,0.1,0.2,0.1c-0.2-0.2-0.2-0.5-0.2-0.7c0,0-0.1-0.1-0.2-0.2
        C193.9,128.5,193.8,128.2,193.4,128.3 M257.1,128.4c-0.1,0.2,0.2,0.3,0.3,0.2C257.6,128.5,257.3,128.3,257.1,128.4 M118.4,129.1
        c0.2,0.1,0.4-0.2,0.3-0.3C118.5,128.7,118.3,128.9,118.4,129.1 M287.8,128.8c0.1,0.2,0.6,0.4,0.2,0.6l0.1,0.1
        c0.1-0.1,0.3-0.1,0.4-0.1c-0.1,0.1-0.1,0.2-0.2,0.3l0.2,0.1c0,0.5,0.4,0.9,0.6,1.3c-0.1,0-0.3-0.1-0.5-0.1c0.2,0.1,0.5,0.2,0.4,0.5
        c0-0.1,0.1-0.2,0.1-0.3c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.2,0.2,0.4,0.3,0.6c-0.4,0.2-0.2-0.9-0.7-0.5c0.2,0.3,0.4,0.6,0.7,0.7
        c0-0.1,0.2-0.2,0.2-0.3c0.4-0.2,0.6-0.6,0.6-1l0.3,0c-0.3-0.5-0.8-0.7-1.3-1c-0.4-0.2-0.7-0.6-1.1-0.8
        C288.4,129,288.2,128.5,287.8,128.8 M396,128.7c-0.1,0.2,0.2,0.4,0.3,0.3C396.5,128.9,396.2,128.7,396,128.7 M299.7,129.3
        c-0.1,0.2,0,0.3,0.2,0.2C300,129.3,299.9,129.2,299.7,129.3 M230.7,129.6c-0.1,0.2,0.1,0.4,0.3,0.3
        C231.1,129.7,230.8,129.5,230.7,129.6 M144,129.7L144,129.7L144,129.7L144,129.7L144,129.7 M162.9,129.7c-0.2,0.3,0.1,0.4,0.3,0.3
        C163.2,129.9,163.1,129.8,162.9,129.7 M299.3,130.2c0.1,0,0.3,0,0.4,0c0.1-0.2,0.1-0.3,0.2-0.5C299.6,129.8,299.4,130,299.3,130.2
        M204.7,130.1c-0.1,0.2,0,0.5,0.3,0.4C205.1,130.3,204.9,129.9,204.7,130.1 M230.3,130c-0.2,0.2,0.2,0.4,0.3,0.2
        C230.8,130,230.4,129.8,230.3,130 M227,130.3c0,0.1,0.1,0.2,0.2,0.3c0.2-0.2,0.4-0.2,0.6-0.3c-0.2,0.2-0.4,0.4-0.6,0.6
        c0.1,0,0.2,0.1,0.3,0.2c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0.2,0.3,0.3,0.5,0.4c-0.2-0.2-0.3-0.3-0.5-0.4c0,0,0.1-0.1,0.1-0.2
        c-0.2-0.1-0.1-0.4-0.1-0.6C227.6,130.3,227.3,130.5,227,130.3 M118.5,130.4c-0.1,0.2,0,0.3,0.2,0.2
        C118.8,130.4,118.8,130.3,118.5,130.4 M205,130.6L205,130.6L205,130.6L205,130.6L205,130.6 M299.8,130.6c-0.1,0.2,0,0.4,0.1,0.5
        C300.3,131.1,300,130.5,299.8,130.6 M224.7,130.7C224.8,130.8,224.8,130.8,224.7,130.7 M161.9,130.9c-0.2,0.1-0.2,0.2,0,0.3
        C162,131.1,162,131,161.9,130.9 M130.2,131C130.3,131.1,130.3,131.1,130.2,131 M384.4,131C384.5,131.1,384.5,131.1,384.4,131
        M299,131.6c0.1,0.1,0.2,0.2,0.2,0.3c0-0.1,0-0.3,0-0.4c0.1,0,0.4,0.1,0.5,0.2c0,0.2,0,0.5-0.3,0.5c0.1,0.3,0.4-0.1,0.5-0.2
        c-0.1-0.3-0.4-0.5-0.6-0.8C299.3,131.3,299.1,131.4,299,131.6 M384.7,131.3C384.8,131.4,384.8,131.4,384.7,131.3 M110.9,131.4
        C110.7,131.8,111.4,131.4,110.9,131.4 M259.7,131.4C259.8,131.9,260.2,131.2,259.7,131.4 M242.1,133.5c-0.4,0.6-1.2,0.8-1.5,1.5
        c-0.7,0.3-1,1-1.6,1.5c-0.4,1-1.8,1.5-2,2.7c0.2,0.3,0.2,0.7,0,1c0.1,0.3,0.1,0.7,0.1,1c-0.1,0.4,0.3,0.8,0.1,1.2
        c-0.1,0.3-0.3,0.4-0.5,0.7c0.1,0.2,0.3,0.4,0.4,0.6c-0.1,0.3-0.2,0.7-0.3,1c0.2,0.3,0.2,0.7,0,1c0,0.4,0,0.7-0.1,1
        c-0.1,0.6,0.1,1.2-0.3,1.7c0.1,0.5,0.2,0.9-0.1,1.4c0.3,0.4,0.2,1-0.1,1.3c0.1,0.4,0.4,0.9,0,1.2c0.1,0.2,0.2,0.5,0.2,0.8
        c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0.1,0.2,0.1,0.3c-0.2,0.6,0.2,1.2-0.3,1.7c0.1,0.9,0.3,1.8-0.1,2.6c0.2,0.6-0.1,1.1-0.1,1.7
        c0.1-0.1,0.2-0.3,0.3-0.4c0,0,0.1-0.1,0.2-0.2c0.1-0.2,0.2-0.3,0.3-0.5c0.6-0.5,0.7-1.4,1.4-1.8c-0.2-0.8,0.1-1.6,0.1-2.4
        c0.2-1.1-0.1-2.3,0.2-3.3c0.1-0.3-0.1-0.5-0.1-0.8c0-0.5,0.2-0.9,0.1-1.4c-0.1-0.4,0-0.8,0.1-1.1c-0.1-0.5-0.1-1,0-1.6
        c0.2-0.6-0.2-1.4,0.3-1.9c-0.2-0.3-0.3-0.5-0.1-0.8c-0.2-0.4,0.1-0.8,0.2-1.2c-0.2-0.5-0.1-1-0.1-1.4c0-0.4,0.3-0.7,0.2-1.1
        l-0.1-0.1l-0.1,0c0.1,0,0.2,0,0.3-0.1c0.1-0.2,0.1-0.3,0.1-0.5c0.1-0.2,0.1-0.4,0.2-0.6l0.2,0c-0.2,0.3,0.1,0.4,0.2,0.6
        c0-0.1-0.1-0.4-0.2-0.5l0.4,0.2c-0.4-0.2-0.3-0.6-0.1-0.9c0.1,0.1,0.2,0.1,0.2,0.2c0,0,0.1-0.1,0.2-0.1c-0.1-0.1-0.3-0.2-0.5-0.3
        c0.1,0,0.3-0.1,0.4-0.1c0-0.1,0-0.2,0-0.2c-0.1,0-0.2,0-0.3,0c0.1-0.1,0.3-0.1,0.4-0.2c0-0.2-0.2-0.4,0.1-0.5
        c0.1-0.2,0.1-0.3,0.3-0.4c0-0.1,0-0.2,0-0.3c0,0.1,0.1,0.2,0.1,0.3c0.2-0.2,0.2-0.4,0.1-0.6c0.1,0.1,0.2,0.2,0.2,0.3
        c0,0,0.1,0,0.2,0c0-0.2,0-0.4,0-0.5c0.1,0,0.2,0.1,0.3,0.1c0-0.1-0.1-0.3-0.1-0.4c0.5,0,0.4-0.6,0.8-0.7c0.3-0.3,0.7-0.6,1.1-0.6
        c-0.1,0.1-0.1,0.3-0.2,0.4c0.2-0.2,0.3-0.6,0.5-0.8c0.1,0,0.2,0.1,0.2,0.1c0-0.1,0-0.2-0.1-0.2c0.3,0,0.5-0.2,0.7-0.3
        c0.4-0.2,0.9-0.3,1.4-0.4c0.3,0,0.8-0.2,1,0.1c0.3-0.1,0.6-0.2,0.8,0c-0.1-0.1-0.1-0.2-0.2-0.4c-0.2,0.1-0.3,0.2-0.6,0.2l0.1-0.2
        c-0.6-0.3-1.2-0.4-1.8-0.3c-0.4,0.1-0.6-0.4-0.8-0.6C243.8,132.2,243,132.9,242.1,133.5 M290.2,132.1c0,0.1,0,0.2,0,0.3
        C290.5,132.5,290.6,131.7,290.2,132.1 M114.8,132.1c-0.1,0.2,0,0.3,0.3,0.2C115.1,132.1,115,132.1,114.8,132.1 M399.5,132.2
        c0.1,0.4-0.4,0.2-0.5,0.5c0.2,0,0.4-0.1,0.6-0.2l0-0.2c0.2,0,0.5,0.1,0.5-0.2C399.8,132,399.6,132.5,399.5,132.2 M289,132.2
        c-0.1,0.3,0,0.7-0.3,0.9c0.2-0.2,0.4-0.3,0.6-0.5C289.2,132.5,289.1,132.4,289,132.2 M289.4,132.3
        C289.5,132.4,289.5,132.4,289.4,132.3 M223.8,132.4c-0.1,0.2,0,0.3,0.2,0.2C224,132.4,224,132.3,223.8,132.4 M289.7,132.4
        C289.7,132.5,289.7,132.5,289.7,132.4 M337,132.7c0,0.2-0.1,0.4-0.1,0.6h0.1c0-0.1,0.1-0.3,0.2-0.4c0,0.3,0.1,0.4,0.4,0.5
        C337.4,133.1,337.2,132.8,337,132.7 M159.7,133C159.8,133.1,159.8,133.1,159.7,133 M100.3,133.4c0,0.2,0.5,0.2,0.5,0
        C100.8,133.2,100.4,133.2,100.3,133.4 M413.9,133.4c0,0.2,0.5,0.3,0.5,0C414.3,133.2,414,133.2,413.9,133.4 M337.1,133.4
        c-0.1,0.2,0,0.3,0.2,0.2C337.4,133.4,337.3,133.3,337.1,133.4 M333.2,133.6c0.2,0.4,0.4,0.7,0.6,1.1c0.1,0,0.3-0.1,0.4-0.1l0-0.2
        c-0.1,0-0.2,0.1-0.3,0.2C333.6,134.2,333.5,133.8,333.2,133.6 M246.8,134c0,0.2,0.1,0.4-0.1,0.6c-0.2-0.1-0.5-0.2-0.7-0.2
        c-0.1,0.1-0.2,0.2-0.2,0.3c-0.2,0-0.4,0-0.6,0c0,0.1,0,0.3,0.1,0.3l-0.1-0.3c-0.2,0.2-0.4,0.3-0.7,0.4c0.1-0.2,0.2-0.4,0.4-0.5
        c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.2,0.4c-0.1,0-0.2,0.1-0.3,0.1c0,0.3-0.3,0.4-0.6,0.4c-0.2,0.3-0.4,0.6-0.8,0.7
        c-0.3,0.7-1,1.2-1.3,1.9c-0.6,0.9-1.4,1.8-1.5,2.9l-0.2,0c0,0.1,0,0.2,0,0.2c0.1-0.1,0.2-0.2,0.3-0.2l0,0.3c0,0,0.1,0,0.2,0
        c-0.1,0.3,0,0.6,0,0.9l-0.3,0c0.1,0,0.2,0.1,0.2,0.2c0,0.3,0,0.6,0.2,0.8c0,0.1,0,0.2-0.1,0.4c0.5-0.3,1-0.5,1.6-0.6
        c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0,0.2,0,0.3,0c-0.3,0.1-0.5,0.2-0.8,0.3c0,0.1,0,0.2,0.1,0.2c-0.1,0-0.2,0.1-0.2,0.1
        c-0.1,0.4-0.6,0.5-0.8,0.8c0.1,0,0.4,0,0.5,0c-0.2,0.2-0.5,0.1-0.5,0.4c-0.2,0-0.3,0.1-0.3,0.2c0.1,0,0.4-0.1,0.5-0.2
        c-0.3,0.3-0.1,0.7-0.1,1.1c-0.1,0-0.3,0-0.4,0c0.2,0.3,0.5,0,0.7-0.1c-0.1,0.3,0.1,0.4,0.3,0.4c0,0.1-0.1,0.2-0.1,0.3
        c0.3-0.1,0.5,0.1,0.7,0.2c-0.5,0-1,0-1.4,0.1c0,0.1,0.1,0.3,0.1,0.5c0.4-0.1,0.8-0.2,1.2-0.3c-0.2,0.2-0.5,0.3-0.8,0.4
        c0,0.1,0,0.3,0,0.3c-0.1-0.1-0.1-0.3-0.2-0.4c0,0.2,0.1,0.4,0.2,0.5l-0.3-0.1c0,0.1,0,0.2,0,0.3l-0.2,0l0.1,0.2l0.2-0.2
        c0,0.1-0.1,0.3-0.1,0.4c0.1,0,0.3-0.1,0.4-0.1c-0.2,0.2-0.4,0.5-0.5,0.7l0.2,0.1c0,0.4,0.2,0.9,0.6,1l-0.1,0.1
        c0.3,0.1,0.5,0.1,0.8,0.2c-0.5,0.2-1.1,0.5-1.7,0.4c0,0.2,0,0.5-0.3,0.5c0.1,0.1,0.1,0.2,0.2,0.3c-0.1,0.1-0.2,0.2-0.3,0.3
        c0.1-0.1,0.5-0.3,0.4,0.1c0,0.3-0.4,0.6,0,0.8c-0.3,0.1,0,0.4,0,0.6c-0.1,0-0.2,0.1-0.3,0.2c-0.1-0.1-0.2-0.2-0.2-0.3
        c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.3,0.2,0.4,0.3c0,0.1-0.1,0.3-0.2,0.4c0.2-0.3,0.4-0.6,0.7-0.8c0.3-0.3,0.9-0.3,0.9-0.9
        c0.4-0.2,0.7-0.5,1-0.8c0.3-0.3,0.8-0.4,1.1-0.7c0.3-0.3,0.7-0.6,1.1-0.7c0.2-0.2,0.3-0.3,0.5-0.5c0.2-0.3,0.6-0.3,0.9-0.6
        c0.3-0.3,0.7-0.4,1-0.6c0.6-0.5,1.2-1,2-1.1c0-0.1,0.1-0.2,0.2-0.2c0.5-0.2,0.9-0.7,1.5-0.7c0.5-0.5,1.3-0.3,1.9-0.8
        c-0.2-0.2,0.1-0.4,0.2-0.5c0,0.2-0.1,0.4-0.2,0.5c0.8-0.4,1.6-0.4,2.4-0.7c0.5-0.1,1-0.1,1.4-0.3c-0.2-0.2-0.3-0.6-0.6-0.4
        c0-0.1,0-0.2,0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.2c0-0.1,0.1-0.2,0.1-0.2c-0.2-0.2-0.1-0.5,0-0.7c-0.1,0-0.2,0-0.2,0
        c0.2-0.3-0.2-0.7-0.2-1l-0.1,0c0,0.2-0.2,0.6-0.3,0.2c0.1-0.1,0.2-0.2,0.3-0.2c-0.1-0.2-0.3-0.3-0.4-0.5c-0.2-0.2-0.2-0.6-0.6-0.5
        c0.1,0.3,0.3,0.6,0.2,1c-0.3,0.1-0.3,0.5-0.2,0.7c-0.3,0.2-0.5,0.4-0.7,0.7c-0.3,0.6-0.8,1-1.3,1.4c-1.2,0.7-2.5,1.2-3.9,1
        c0.1-0.1,0.3-0.3,0.4-0.4c-0.2,0.1-0.4,0.2-0.6,0.2c0-0.1,0.1-0.3,0.1-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c0.2,0,0.5,0,0.7,0
        c0,0.1,0.1,0.2,0.1,0.3l0-0.3c0.4,0.1,0.7,0.1,1-0.3c0,0.1-0.1,0.4-0.2,0.5c0-0.1,0.1-0.2,0.1-0.2c0,0,0.1,0,0.2,0l0-0.2l0.2,0
        c0,0-0.1-0.1-0.2-0.1c0.3-0.1,0.4-0.3,0.3-0.6c0.3,0,0.5,0.1,0.8,0c-0.1,0-0.4,0-0.6,0c0-0.3,0.2-0.2,0.4-0.1
        c-0.1-0.3,0-0.4,0.2-0.5c0-0.2,0-0.4,0-0.7c0.2,0,0.3-0.1,0.5-0.1c0.1-0.3,0.2-0.4,0.5-0.2c0-0.6-0.5,0.2-0.8-0.1
        c0.3-0.2,0.8-0.8,0.4-1.1c0.2,0,0.3,0.1,0.5,0.1c0-0.2,0-0.4,0-0.6c0.2-0.1,0.2-0.4,0-0.5c0,0.1-0.1,0.3-0.1,0.4
        c0-0.2,0.1-0.5,0.1-0.7c-0.1,0-0.2,0-0.3,0c0-0.2,0-0.3,0.1-0.5l-0.3,0c0.1,0,0.2-0.1,0.3-0.1c-0.2-0.2-0.4-0.3-0.7-0.2
        c0.1-0.1,0.1-0.3,0.2-0.4c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0.1-0.2,0.2-0.3,0.2c0-0.1,0-0.3,0-0.5
        c-0.1,0.1-0.2,0.3-0.3,0.4c0-0.1,0-0.3,0-0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.4,0.6-0.9,1.2-1.3,1.8c-0.2,0.1-0.3,0.2-0.4,0.3
        c-0.3,0.3-0.9,0.4-1.1,0.9c-0.2,0-0.4,0-0.5-0.2c0.1,0.4-0.4,0.5-0.7,0.5c0-0.1,0-0.3,0-0.4c-0.2,0.1-0.1,0.3-0.2,0.5
        c-0.3-0.2-0.7,0.4-1,0c-0.2,0-0.4,0.4-0.4,0c-0.2-0.1-0.4-0.2-0.6-0.2c-0.5-1,0.3-2.1,1.2-2.4c0.2-0.3,0.7-0.5,1-0.6
        c0.4-0.2,0.7-0.5,1.2-0.5c-0.2,0.1-0.5,0.1-0.6,0.3c-0.4,0.2-0.9,0.4-1.3,0.6c-0.1,0.2-0.2,0.4-0.5,0.4c-0.5,0.5-1.1,1.1-0.9,1.9
        c0.2,0.1,0.4,0.2,0.6,0.2c0.4,0,0.7-0.1,1.1-0.2l0-0.1c0.3,0,0.6-0.1,0.8-0.3c0.6,0,0.8-0.8,1.3-0.9c0-0.1,0-0.2,0-0.2
        c0.1,0,0.2,0,0.2,0c0-0.2,0.1-0.4,0.3-0.3c0-0.1,0-0.3,0-0.4c0.1-0.1,0.2-0.2,0.3-0.2c-0.1-0.1-0.2-0.3-0.2-0.4
        c0.1,0.1,0.3,0.2,0.3,0.2c0-0.2,0-0.5,0-0.7l0.2,0.1c0-0.3,0-0.5-0.1-0.8l0.2,0.1l0-0.4l-0.2,0c0-0.1,0-0.3,0-0.4l0.2,0l0-0.4
        l-0.2,0c0.1-0.5-0.1-0.9-0.3-1.3c-0.1,0-0.3-0.1-0.4-0.1c0.1-0.1,0.1-0.3,0.2-0.4c-0.2,0-0.6,0-0.5-0.3l-0.1,0
        c-0.2-0.3-0.5-0.2-0.8-0.2C247,134.3,246.9,134.1,246.8,134 M132.4,134.6c0.1,0,0.3-0.1,0.4-0.1c0.1,0.3,0.1,0.6,0.5,0.5
        c-0.2-0.3-0.4-0.6-0.5-1C132.6,134.2,132.3,134.3,132.4,134.6 M381.9,134.1c-0.1,0.3-0.3,0.6-0.5,0.9c0.1,0,0.3,0,0.4,0
        c0-0.2,0.1-0.4,0.1-0.5c0.1,0,0.3,0.1,0.4,0.1c0-0.2,0-0.4-0.2-0.6C382.1,134,382,134.1,381.9,134.1 M250.3,134.5
        c0.4,0.6,0,1.3,0.1,2c-0.3,0.5-0.1,1-0.4,1.5c0.1-0.2,0.3-0.2,0.5-0.1c0-0.1-0.1-0.2-0.2-0.3c0.2-0.2,0.4-0.1,0.6,0l0-0.2l0.3,0
        c0,0.1,0.1,0.2,0.1,0.3l0-0.3c0.3,0.1,0.6,0.2,1,0.3c0.2,0,0.3,0,0.5,0.1c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1,0-0.3,0-0.4,0
        c0.1-0.1,0.1-0.3,0.2-0.4c-0.1,0-0.3,0-0.4,0.1c0-0.1,0-0.3,0-0.4c-0.2-0.1-0.3-0.3-0.4-0.5c-0.4-0.2-0.6-0.5-0.8-0.8
        c-0.1-0.2,0-0.3,0.1-0.5C250.6,134.7,250.4,134.6,250.3,134.5 M108.6,135.1c-0.2,0-0.4,0.1-0.4,0.4c0.1,0,0.2,0,0.3,0
        c0,0.1-0.1,0.2-0.1,0.3c0.3,0,0.5,0,0.8,0.1c-0.1,0.2-0.3,0.2-0.5,0.3c0.1,0,0.3,0.1,0.5,0.1c0.1,0.1,0.2,0.2,0.3,0.4
        c-0.1,0.1-0.2,0.3-0.2,0.4c0.4,0.4,0.7,0.9,1.1,1.3c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0.1,0.3,0.2,0.4,0.3c0,0.2,0.1,0.4,0.2,0.6
        c0.3,0.2,0.1,0.7,0.4,0.9c0.1-0.2,0.2-0.4,0.1-0.6l0.1,0l0,0.2c0.1,0,0.2,0,0.3,0c-0.2,0.1-0.3,0.3-0.5,0.4c0.3,0.2,0.1,0.8,0.5,0.8
        c0.3,0,0.2,0.3,0.2,0.5c0.4,0.2,0.4,0.7,0.5,1c-0.4,0.4-0.2,1-0.3,1.5c0.1,0.2,0,0.5,0,0.8c0.1,0,0.4,0,0.5,0.1
        c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0.1,0.2,0.2,0.4,0.4c0.2,0,0.5,0,0.6,0.3c0.3,0.1,0.5,0.3,0.7,0.6c-0.1,0-0.4,0.1-0.5,0.1
        c0.3,0.2,0.7,0.4,0.9,0.7c0.2,0.3,0,0.5-0.1,0.8c0.2,0.1,0.3-0.2,0.4-0.3c0.3,0.3-0.2,0.5-0.3,0.8c0.2,0,0.4,0,0.7-0.1
        c-0.1,0.1-0.2,0.2-0.3,0.2c0.1,0.2,0.4,0.2,0.5,0.3c-0.1,0.3-0.2,0.6-0.3,0.9c-0.3,0.1-0.6,0.5-1,0.4c-0.3-0.2-0.6,0.2-0.6,0.6
        c0,0-0.1,0-0.2,0c0.3,0.1,0.3,0.4,0.2,0.6c0.5-0.3,0.2,0.5,0.3,0.7l0.1,0c0,0.1,0,0.3,0,0.4c0.2,0.1,0.2,0.2,0.2,0.4
        c0.2,0.1,0.2,0.2,0,0.3c0,0.3,0.1,0.5,0.1,0.8c-0.1,0.1-0.1,0.1-0.2,0.2c0.1-0.1,0.2-0.2,0.3-0.2c0,0.3,0.4,0.6,0.3,1
        c0.2,0.5,0.5,1,0.7,1.5c0.1-0.1,0.2-0.2,0.2-0.4c0.1,0.1,0.2,0.1,0.3,0.2c-0.2,0-0.4,0.1-0.5,0.3c0.3,0.1,0.1,0.4,0.1,0.6
        c0,0.3,0,0.6-0.1,0.9c0.1,0,0.3-0.1,0.4-0.1l-0.3,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0,0-0.2,0.1-0.2,0.1c-0.1-0.2-0.2-0.3-0.3-0.5
        c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.1-0.3,0.2-0.4,0.3c0.3,0.1,0.5-0.3,0.8-0.3c-0.1,0.3-0.3,0.6-0.5,0.8c-0.1-0.1-0.3-0.3-0.4-0.4
        c0.2,0.2,0.4,0.6,0.8,0.5c0.1-0.2,0.3-0.4,0.6-0.5c0,0.2-0.2,0.4-0.3,0.6c-0.1,0-0.3,0-0.4,0c0.1,0.1,0.2,0.3,0.2,0.4l-0.1,0.2
        l0.3,0.2c0-0.1,0.1-0.3,0.1-0.4c0.3,0.3,0,0.9-0.4,0.4c0.2,0.3,0,0.7-0.2,1c0,0.1,0.1,0.2,0.1,0.3c-0.1-0.1-0.3-0.2-0.5-0.2
        c0.1,0.1,0.2,0.3,0.2,0.4c-0.1,0.2-0.2,0.4-0.3,0.6c0.1,0.2,0.2,0.3,0.2,0.5c0.4-0.2-0.3-0.5-0.1-0.7c0.1,0,0.4-0.1,0.5-0.1
        c-0.2-0.2-0.3-0.4,0-0.5c0,0.1,0,0.3,0,0.4c0.1,0,0.3-0.1,0.4-0.1c-0.1,0.2-0.2,0.3-0.5,0.4c0.2,0.1,0.4,0.3,0.5,0.5
        c-0.1-0.1-0.3-0.2-0.4-0.2c0.1,0.3,0.2,0.6,0,0.9c-0.2,0-0.3-0.2-0.4-0.4c-0.1,0.1-0.1,0.2-0.2,0.3c0.3,0.1,0.5,0.4,0.6,0.7
        c-0.1,0-0.4-0.1-0.5-0.1c0.1,0.3,0.5,0.2,0.7,0.4c-0.2,0.2-0.4,0.4-0.3,0.7c0.2-0.2,0.3-0.3,0.5-0.4c-0.1,0.2-0.2,0.6-0.5,0.5
        c-0.1,0.1-0.1,0.2-0.2,0.4c-0.3,0.1-0.5,0.3-0.8,0.2c0.2,0.2,0.4,0.5,0.4,0.8c0.1,0,0.3,0,0.4,0c0,0.1,0.1,0.2,0.1,0.3
        c-0.2,0-0.3,0.1-0.5,0.1c0.1,0.2,0.4-0.1,0.6-0.1c0,0.2,0,0.5,0,0.6c-0.1,0-0.3-0.1-0.3-0.2c0.1,0.1,0.2,0.3,0.3,0.4
        c-0.3,0.1-0.5,0.4-0.9,0.2c0.1,0.1,0.2,0.2,0.4,0.2c0,0.1-0.1,0.4-0.2,0.5c0.1,0,0.3-0.1,0.3-0.1l-0.2-0.1c0.2-0.2,0.3-0.4,0.5-0.6
        c0.2,0,0.4,0,0.6,0c-0.1-0.1-0.3-0.3-0.4-0.5c0.4,0.3,0.3-0.1,0.4-0.3l-0.4,0.2c0.2-0.3,0.4-0.7,0.3-1.1c-0.1-0.6,0.1-1.2,0.2-1.7
        c-0.1-0.2-0.3-0.3-0.4-0.4c0.1,0,0.3,0,0.4,0.1c0-0.7-0.1-1.5,0-2.2c-0.1,0-0.3-0.1-0.4-0.1c0.2-0.2,0.5-0.3,0.5-0.6
        c-0.2-1.3,0-2.7-0.1-4c0-0.1-0.1-0.2-0.2-0.3c0,0,0.1-0.1,0.1-0.1c0.1-0.7,0-1.5,0-2.2c0.2-0.4,0-0.8,0-1.2c-0.3-0.5-0.3-1-0.2-1.6
        c-0.2-0.5-0.3-1,0-1.5c-0.3-0.3-0.3-0.7-0.4-1.1c0-0.2,0-0.5-0.2-0.7c0-0.5-0.1-1-0.2-1.5c-0.2-0.1-0.3-0.1-0.5-0.1
        c0-0.1,0-0.2,0.1-0.3c0.1,0.1,0.2,0.1,0.4,0.1c0-0.1,0.1-0.3,0.1-0.4c-0.3-0.2-0.6-0.7-0.4-1.1c0-0.6-0.5-1-0.6-1.5
        c-0.1-0.4-0.5-0.8-0.5-1.3c0-0.5-0.7-0.8-0.5-1.4c-0.3-0.2-0.5-0.5-0.7-0.7c0.1,0,0.2-0.1,0.2-0.1c-0.2-0.2-0.4-0.3-0.6-0.6
        c-0.3,0-0.7,0-0.8-0.4l0.1,0l0.1,0c0,0,0.1,0,0.1,0c0.2-0.2-0.2-0.2-0.3-0.3c-0.4-0.4-0.9-0.7-1.1-1.2c-0.1-0.9-0.1-1.9-0.7-2.7
        C109.5,134.9,109.1,135.1,108.6,135.1 M132.5,134.9C132.3,135.3,133,134.8,132.5,134.9 M145.9,134.8
        C145.7,135.3,146.4,134.9,145.9,134.8 M382.2,134.8c-0.1,0.1-0.3,0.3-0.5,0.4c-0.1,0-0.2-0.1-0.3-0.1c0,0.1,0.1,0.3,0.1,0.4
        c-0.1,0-0.2,0-0.3,0c0.2,0.3-0.1,0.6-0.2,0.9c-0.3,0.2-0.2,0.6-0.5,0.8c0.4,0.5-0.3,0.9-0.2,1.4c0,0.1,0.1,0.2,0.2,0.3
        c0.1,0,0.2-0.1,0.3-0.1c0,0.1-0.1,0.3-0.1,0.3c0.2,0,0.5-0.1,0.6-0.1c-0.1-0.1-0.2-0.2-0.4-0.2c0-0.3-0.1-0.5-0.1-0.8
        c0.3,0.1,0.4,0.6,0.8,0.6c-0.1-0.2-0.2-0.4-0.5-0.4c-0.1-0.2-0.2-0.7,0.2-0.7c0.1-0.1,0.3-0.2,0.3-0.2c0,0.1,0,0.3,0,0.4
        c0.1,0.2,0.2,0.4,0.1,0.6c0.2-0.1,0.1-0.3,0.1-0.5c-0.1-0.2-0.2-0.3-0.2-0.5c0.2-0.2,0.3-0.5,0.6-0.7c0-0.3,0.3-0.4,0.5-0.5
        l-0.2,0.1c0-0.1-0.1-0.3-0.1-0.4c0,0.1,0,0.2,0,0.3c-0.3,0.4-0.7,0.8-1,1.2c-0.1,0-0.5-0.4-0.3,0c-0.2,0.1-0.4,0.2-0.5,0.3
        c0.2-0.4,0.7-0.7,0.6-1.2l0.1,0c-0.1,0.2,0,0.5,0.2,0.7c0-0.2,0.1-0.4,0.1-0.7c0.1,0,0.2-0.1,0.2-0.1l0.1-0.2l-0.3,0l0-0.1l0.3,0.1
        c0-0.1-0.1-0.3-0.1-0.3C382,135.2,382.3,135.1,382.2,134.8 M404.1,137.2c0,0.4-0.3,0.6-0.6,0.8c-0.3,0.2-0.5,0.6-0.9,0.6
        c0,0.1,0,0.2,0,0.2c0.1,0,0.3,0,0.4,0c-0.2,0.2-0.4,0.4-0.7,0.3c-0.2,0.2-0.4,0.4-0.6,0.5c0,0.1,0.1,0.2,0.1,0.3
        c-0.2,0.2-0.4,0.5-0.7,0.7c0.1,0.6-0.5,0.9-0.5,1.4c0,0.4-0.3,0.7-0.5,1.1c-0.2,0.6-0.5,1-0.7,1.6c0.3,0.4,0.1,0.9-0.3,1.1
        c0,0.1,0.1,0.4,0.1,0.5c0.2,0,0.5-0.1,0.5,0.1l-0.1,0c-0.2,0-0.3,0-0.5-0.1c-0.1,0.5-0.2,1.1-0.2,1.6c-0.1,0.2-0.2,0.4-0.2,0.6
        c0.3-0.1,0.5-0.2,0.7-0.4c-0.1-0.1-0.3-0.2-0.3-0.2c0.2,0,0.5,0,0.7,0.1c-0.2-0.2-0.3-0.4-0.5-0.6c0.1-0.1,0.2-0.2,0.2-0.2
        c0,0.1,0,0.3,0,0.4c0.6-0.1,0-0.6,0.3-0.9c0.2-0.3,0.6-0.5,0.9-0.7c-0.1,0-0.4-0.1-0.5-0.1c0.1-0.3,0.4-0.4,0.7-0.6
        c0-0.1,0.1-0.2,0.2-0.3c0.4,0.2,0.6-0.3,0.9-0.5c-0.2,0-0.4,0.1-0.4-0.1c0.1,0,0.4,0,0.5,0c0-0.4,0-0.8,0.1-1.1
        c-0.2-0.3,0-0.6-0.2-0.9c-0.3-0.4-0.1-1,0.3-1.3c0-0.2,0-0.5,0.2-0.5c0.5,0,0.3-0.7,0.6-0.9c-0.1,0-0.2-0.1-0.3-0.1
        c0.1,0,0.3,0.1,0.4,0.1c0.1-0.3,0.1-0.7,0.4-0.9c0.1-0.1,0.1-0.3,0.1-0.5c0.1-0.1,0.3-0.2,0.4-0.3c0.1,0,0.2,0.2,0.2,0.2
        c0.4-0.4,0.7-0.9,1.1-1.3c-0.1-0.1-0.2-0.3-0.2-0.4c0.1-0.1,0.2-0.3,0.3-0.4c0.1,0,0.3,0,0.4-0.1c-0.2-0.1-0.4-0.2-0.5-0.4
        c0.2-0.1,0.5-0.1,0.7-0.1c0-0.1,0-0.2-0.1-0.3c0.1,0,0.2,0,0.3,0c0-0.1-0.1-0.3-0.1-0.4c-0.6,0-1.1-0.1-1.6-0.4
        C404.1,135.4,404.1,136.4,404.1,137.2 M132.7,135.2c0.3,0.1-0.1,0.7,0.3,0.5l0,0.1l-0.3-0.1c0.1,0.1,0.2,0.2,0.3,0.3
        c0,0.2,0,0.5,0.2,0.6c0.1-0.2,0.1-0.4,0-0.7l0.1,0c0,0.2,0,0.3,0,0.5c0.2,0.2,0.4,0.4,0.6,0.7c-0.2-0.1-0.4-0.2-0.6-0.3l0.1-0.2
        c-0.1,0-0.2,0.1-0.3,0.1c-0.2-0.1-0.4-0.3-0.6-0.4c0.1,0.2,0.2,0.4,0.4,0.6c0,0.2,0,0.4-0.2,0.5c0,0.2-0.1,0.4,0.1,0.5
        c-0.1-0.3,0-0.5,0.1-0.6c0-0.1,0-0.3,0-0.4c0.2,0.1,0.3,0.3,0.6,0.3c0,0.1,0.1,0.3,0.1,0.3c0,0.1-0.1,0.2-0.1,0.3
        c-0.2,0-0.3,0.2-0.4,0.4c0.4,0,0.5-0.5,0.8-0.6c0,0.3-0.1,0.5-0.1,0.8c-0.2,0-0.3,0.1-0.4,0.2c0.2,0,0.4,0,0.6,0.1
        c0-0.1,0-0.3-0.1-0.4c0.5,0.1,0.1,0.5,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.7c0.1-0.1,0.2-0.2,0.2-0.3c0,0.1,0,0.2,0.1,0.2l0.2,0.1
        l-0.1,0.2c-0.2-0.2-0.4-0.2-0.6-0.2c0,0.2-0.1,0.5-0.2,0.7c0,0.2,0.3,0.2,0.4,0.4c0.1,0.2,0.1,0.4,0.3,0.6l0-0.3
        c-0.4-0.2,0.1-0.4-0.2-0.6c0-0.2,0-0.5-0.1-0.6c0.2,0.2,0.7,0.3,0.3,0.6c0.1,0.1,0.2,0.3,0.2,0.4c0.1,0,0.2,0,0.2,0
        c-0.1,0-0.4,0-0.5,0c0.1,0.3,0.3,0.6,0.6,0.8c0.1,0.3,0.2,0.5,0.5,0.7c-0.1,0.1-0.1,0.2-0.2,0.3c0-0.1,0-0.3-0.1-0.4l-0.2,0l0,0.3
        c-0.1-0.1-0.2-0.1-0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.2c-0.1-0.4-0.4-0.7-0.7-0.9c0,0.3,0,0.5,0.3,0.7c-0.1,0-0.2,0.1-0.2,0.2
        c0.1,0.6,0.7,0.8,1,1.3c0-0.1,0.1-0.2,0.2-0.3c0,0.2,0,0.4,0,0.6c-0.2-0.2-0.4-0.3-0.6-0.5c0.1,0.2,0.2,0.5,0.1,0.7
        c0.2,0.2,0.6,0.3,0.5,0.6c-0.2-0.1-0.4-0.2-0.7-0.3c-0.1,0.2-0.2,0.4-0.3,0.6c0.1-0.1,0.2-0.5,0.5-0.4c0.3,0.1,0.5,0.4,0.5,0.7
        c-0.2-0.1-0.3-0.3-0.5-0.4c-0.3,0.2-0.1,0.5,0,0.8c0.1,0.1,0.2,0.3,0.4,0.3c-0.1,0.1-0.1,0.3-0.2,0.4c0.2-0.2,0.4-0.4,0.3-0.7
        c0.2-0.1,0.4-0.3,0.5-0.5c0-0.1-0.1-0.2-0.2-0.2c0.1,0,0.2-0.1,0.4-0.1c0,0.1-0.1,0.2-0.2,0.2c0,0.2,0,0.5,0,0.7l-0.3,0.1l0.1,0.2
        c0.1,0,0.2,0,0.3,0c-0.2,0.1-0.3,0.3-0.3,0.5c-0.1,0-0.3,0-0.4,0c0.3,0.1,0.6,0.2,0.9,0.4c-0.1,0-0.2,0.1-0.3,0.2
        c0.1,0.2,0.2,0.5,0.5,0.2c0,0.1-0.1,0.4-0.1,0.6c-0.2,0-0.4-0.1-0.6-0.1c0-0.1,0-0.2,0-0.3l-0.1-0.1c0-0.1-0.1-0.2-0.1-0.2
        c-0.2,0-0.2,0.1-0.1,0.2l0,0.1c0,0.2,0,0.4,0.2,0.6c0.1,0,0.3-0.1,0.4-0.1c0.2,0.2,0.4,0.4,0.5,0.6c-0.2,0-0.4,0-0.6,0.1
        c0.4,0,0.5,0.4,0.8,0.4c0-0.1,0.1-0.2,0.2-0.2c0.1,0.1,0.2,0.2,0.3,0.3l-0.3,0c0,0.3-0.1,0.6,0.2,0.8c-0.1,0-0.4-0.1-0.5-0.2
        c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0,0.3,0.2,0.3,0.4l0.2,0c0,0.1-0.1,0.3-0.1,0.4c-0.1-0.1-0.2-0.2-0.2-0.3c-0.5,0.3,0.2,0.3,0.4,0.4
        c0.1-0.2,0.2-0.5,0.4-0.7c-0.1,0.3-0.4,0.6-0.1,0.9l-0.3-0.1c0.1,0.4,0.5,0.6,0.9,0.6c0.1,0.2,0.3,0.4,0,0.6
        c-0.1-0.2-0.2-0.5-0.3-0.7c-0.1,0.1-0.3,0.2-0.4,0.2c0,0.1,0.1,0.3,0.2,0.4c0,0,0.1,0,0.2,0c0,0.1,0,0.4,0,0.5
        c0.2,0.1,0.4,0.1,0.6,0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0.1,0,0.3,0,0.4,0.1c0,0.2-0.1,0.3-0.2,0.5c-0.1,0-0.3-0.1-0.4-0.1
        c0.1,0.3,0.5,0.4,0.8,0.5c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0.1-0.2,0.2-0.2,0.3l0.3,0c0,0.2,0.1,0.5-0.1,0.6c0-0.2-0.1-0.4-0.3-0.6
        c0-0.4-0.4-0.7-0.8-0.8c0.2,0.2,0.4,0.4,0.6,0.6c-0.1,0.1-0.3,0.1-0.4,0.2c0.4-0.2,0.4,0.2,0.6,0.5c-0.1,0-0.2,0.1-0.3,0.1
        c0.1,0,0.3,0,0.4,0c0,0.2,0,0.3,0.1,0.4c0.1,0,0.2-0.1,0.3-0.1c0.1,0.1,0.3,0.1,0.5,0.1c-0.1,0.1-0.1,0.2-0.1,0.3
        c-0.1-0.1-0.2-0.2-0.3-0.2c-0.4-0.1-0.6,0.2-0.7,0.5c0.1,0.1,0.3,0.3,0.4,0.4c-0.1-0.2-0.3-0.4-0.1-0.7c0.1,0,0.3,0.1,0.4,0.1
        c0,0.1,0,0.3,0,0.4c0.4,0.2,0.1-0.4,0.5-0.3c-0.2,0.3-0.1,0.5,0,0.8c-0.1,0-0.3,0-0.5,0c-0.1,0.5,0.7,0.1,0.7,0.7
        c-0.2-0.1-0.3-0.1-0.5-0.2c0.1,0.1,0.2,0.3,0.2,0.4c0.1-0.1,0.3-0.2,0.4-0.3c-0.1,0.2-0.1,0.4,0.1,0.5c-0.2,0.3-0.1,0.7-0.3,1
        c0.2,0.1,0.3,0.1,0.5,0.2c0.2,0.1,0.5,0.4,0.7,0.1c0,0.1,0,0.2,0,0.3l-0.1,0c0,0.1,0,0.4,0,0.5c0.1,0.1,0.6-0.2,0.6,0.2
        c-0.2,0.3-0.6-0.3-0.6,0.1c0,0,0,0.2-0.1,0.2c0.1,0,0.3-0.1,0.4-0.2c0,0.2,0.1,0.5,0.1,0.7c-0.2-0.1-0.3-0.2-0.3-0.4
        c-0.1,0-0.2,0.1-0.2,0.1c0.2,0.2,0.4,0.4,0.6,0.4c0,0.2-0.1,0.4-0.1,0.6c0.1,0,0.3,0,0.4,0c-0.1,0.3-0.2,0.5-0.4,0.8l0.1,0
        c0.2-0.2,0.5-0.5,0.7-0.7c0,0.1,0,0.2,0,0.3c0.3,0.2,0.5,0.6,0.3,0.9c0.2,0,0.4,0,0.6,0.1c-0.3,0-0.5,0-0.8,0.1
        c0.1,0.1,0.3,0.3,0.5,0.4c0.1,0,0.3-0.1,0.4-0.1c0,0.2-0.1,0.4-0.2,0.6l0-0.1c-0.1,0.2-0.2,0.4-0.4,0.6c0.2,0.1,0.4,0.1,0.4-0.2
        c0.1-0.3,0.3,0.1,0.4,0.2l-0.2-0.1c0,0.2,0,0.5,0,0.7l0.2,0c0,0.1-0.1,0.3-0.1,0.4c0.1-0.1,0.4-0.3,0.5-0.3c0-0.1-0.1-0.2-0.2-0.3
        c0.1,0,0.3,0,0.4,0.2c-0.4,0.1,0.1,0.6-0.3,0.7c0-0.1,0-0.2,0-0.3c-0.2,0.2-0.3,0.4-0.4,0.6c0.2-0.1,0.4-0.1,0.6,0
        c0.1-0.1,0.2-0.1,0.3-0.2c0,0.1,0,0.2-0.1,0.2l-0.2,0.1c0,0,0.1,0.1,0.2,0.1c0,0-0.1,0.1-0.2,0.2c0.1,0.2,0.3,0.3,0.4,0.5
        c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0-0.2-0.1-0.3-0.2c0.2-0.1,0.4-0.3,0.3-0.6c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0-0.3,0-0.4,0.1
        c0.1,0.1,0.1,0.2,0,0.4c0.4-0.1,0.7,0.3,1.1,0.4c0-0.1,0-0.3,0.1-0.5c0.2,0.1,0.2,0.3,0.2,0.4c-0.2,0-0.3,0.1-0.5,0.1
        c-0.1,0.2,0,0.4,0.1,0.6c-0.1-0.2-0.3-0.3-0.4-0.5c-0.1,0.2,0.2,0.4,0.2,0.7c0.1,0,0.2,0.1,0.3,0.1c0,0.1-0.1,0.3-0.1,0.4
        c-0.2,0.2-0.4-0.1-0.6-0.2c0.2,0.2,0.4,0.5,0.6,0.7c-0.1,0-0.3,0.1-0.4,0.1c0,0.1,0,0.3,0,0.4l-0.2,0c0,0,0.1,0.1,0.2,0.1
        c0,0.2,0,0.3,0.1,0.5c0.2,0.2,0.4,0,0.6-0.1l0.1,0.1c0,0,0,0.1,0.1,0.1c-0.2,0-0.3,0-0.5,0c0.1,0.1,0.2,0.3,0.3,0.5
        c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0.2,0.5,0.3,0.4,0.6l-0.1-0.3c-0.1,0.1-0.2,0.2-0.3,0.3c0.2,0.1,0.5,0.2,0.8,0.3
        c0,0.1-0.1,0.3-0.1,0.4c0.2,0.1,0.3,0.2,0.5,0.3l-0.3,0c0.1,0.5,0.5,0.2,0.6-0.1c-0.2,0.2-0.1,0.4,0,0.7c0,0.2,0,0.4-0.1,0.6
        c0.2,0.2,0,0.7-0.3,0.4c0-0.1,0-0.3,0-0.4c0.1-0.1,0.3-0.3,0.4-0.5c-0.2-0.2-0.5-0.3-0.8-0.3c0.1,0.2,0.3,0.3,0.5,0.4
        c-0.1,0.2-0.2,0.4-0.2,0.7c0.2,0.2,0.3,0.5,0.7,0.2c-0.1,0.1-0.2,0.3-0.2,0.3c0.1,0.2,0.1,0.4,0.1,0.6l0.2,0.1
        c-0.1,0.1-0.2,0.2-0.4,0.3c0.2,0,0.3-0.1,0.4-0.2c0.1,0.3,0.4,0.3,0.7,0.4c-0.1,0.1-0.2,0.3-0.3,0.3c0.1,0,0.4,0,0.6,0
        c-0.2,0.1-0.3,0.2-0.2,0.4c0.3-0.2,0.5,0,0.7,0.3c-0.3,0.1-0.5,0-0.7-0.1c0.1,0.1,0.3,0.3,0.2,0.5c0.1-0.1,0.3-0.2,0.5-0.2
        c0.1,0.1,0.3,0.3,0.4,0.4c-0.3,0-0.3,0.1,0,0.3c0.1-0.2,0.2-0.3,0.1-0.5c0.2,0.1,0.4,0.3,0.6,0.4l-0.3,0c0.1,0.1,0.2,0.2,0.3,0.3
        c0,0.2-0.1,0.5,0,0.7c-0.1,0-0.2-0.1-0.2-0.1c0,0.1,0,0.3,0,0.3l0.1,0c-0.2-0.6,0.3,0,0.4,0c-0.1,0.1-0.2,0.2-0.3,0.3
        c0,0.2,0,0.3-0.1,0.5c0.3-0.1,0.3-0.7,0.7-0.5c0.2,0.1,0.4,0.3,0.6,0.4c0.1,0.2,0.1,0.5,0.2,0.7c-0.2-0.1-0.2-0.3-0.3-0.5
        c-0.1-0.4-0.6-0.4-0.9-0.5c0,0.5,1.2,0.5,0.5,1.1c0.5-0.4,0.7,0.4,0.3,0.5c0,0.1,0,0.2,0,0.3c-0.1,0-0.3,0.1-0.5,0.1
        c0,0,0,0.1,0,0.1c0.1-0.1,0.3-0.2,0.5-0.1c0.1-0.1,0.1-0.3,0.2-0.4c0,0.1,0.1,0.3,0.1,0.4l0.2,0.1c-0.1,0-0.3,0-0.4,0
        c0.1,0.3,0.4,0.4,0.7,0.3c0.1,0.3,0,0.4-0.3,0.3l-0.1,0c-0.2-0.4-0.4-0.1-0.6,0.1c0.1,0,0.2,0,0.2,0c0.1,0.2,0.3,0.5,0.3,0.7
        c0.1,0,0.2,0,0.3,0c-0.1-0.1-0.2-0.3-0.3-0.5c0.1-0.1,0.2-0.2,0.3-0.1c0.2,0.1,0.3,0.3,0.5,0.3c0,0.1-0.1,0.3-0.1,0.4l0.2-0.1
        c0,0.3-0.1,0.7-0.3,0.9c0.3,0,0.2,0.3,0.1,0.5l0.1,0l0-0.1c0.3-0.2,0-0.4,0.1-0.7c0.3,0,0.3,0.3,0.5,0.5c-0.1,0-0.2,0.1-0.2,0.1
        c0.2,0,0.4,0.1,0.6,0.2c-0.1,0.3,0.1,0.6,0,0.9l0.2,0.1c0,0.1-0.1,0.2-0.1,0.3c0.4,0,0.6,0.3,0.9,0.5c-0.3,0.2-0.4,0.5,0,0.7
        c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.3-0.1-0.4-0.2c0,0.2,0.2,0.3,0.4,0.3l0.1,0c0.1,0,0.2,0,0.2-0.1c0,0.1,0,0.2,0,0.3l0.3,0
        c-0.2,0.2-0.4,0.5-0.6,0.2c-0.1,0-0.1,0.1-0.2,0.1c0.2,0,0.3,0.1,0.5,0.2c0.3-0.4,0.6,0.2,0.4,0.5l0.2-0.1c0,0.1-0.2,0.4-0.2,0.5
        c0.2-0.1,0.4-0.1,0.6,0.1c-0.2,0.1-0.3,0.2-0.5,0.2c0.3,0,0.7,0.1,0.8-0.2c0,0.1,0,0.2,0,0.2l-0.2,0c0,0.1,0.1,0.3,0.1,0.4
        c-0.3-0.1-0.6-0.1-0.9-0.2c0.2,0.2,0.4,0.4,0.7,0.3c0,0.1,0,0.2,0,0.3l0.2,0.1c0.4-0.1-0.2-0.4,0.1-0.5c0,0.1,0.1,0.3,0.1,0.4
        c0.2,0.1,0.5,0.1,0.5,0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1,0.1-0.2,0.1-0.3,0.2c-0.3,0-0.3,0.3-0.4,0.5c0.1-0.1,0.3-0.3,0.4-0.4
        c0.2,0.1,0.3,0.2,0.5,0.3c-0.5,0-0.3,0.4-0.1,0.5c0.2,0,0.3,0.1,0.3,0.3c0.1-0.1,0.2-0.2,0.3-0.3c-0.1,0.1-0.2,0.2-0.3,0.3
        c0.2,0.3,0.4,0.9,0.9,0.6c0-0.3-0.1-0.5-0.1-0.8c-0.1,0-0.3,0.1-0.4,0.1c0.2-0.1,0.3-0.2,0.3-0.4c-0.2-0.3-0.3-0.6-0.5-0.9
        c0-0.3-0.2-0.5-0.4-0.7c-0.1,0-0.2,0.1-0.3,0.1c0-0.2,0.1-0.3,0.1-0.4c-0.1-0.3-0.3-0.6-0.2-1c-0.1,0-0.2-0.1-0.2-0.1
        c0-0.2-0.1-0.3-0.3-0.4c-0.2-0.3-0.3-0.6-0.4-1c-0.2-0.2-0.4-0.5-0.3-0.9c-0.3-0.3-0.2-0.9-0.7-1c0-0.1,0.1-0.2,0.1-0.3
        c-0.5-0.5-0.6-1.2-0.8-1.9c-0.1-0.3-0.3-0.5-0.3-0.8c0-0.5-0.6-0.8-0.5-1.3c0-0.6-0.6-0.9-0.7-1.5c0-0.6-0.6-1-0.6-1.6
        c-0.2-0.3-0.3-0.5-0.4-0.9c-0.3-0.2-0.2-0.6-0.4-0.8c-0.2-0.4-0.3-0.8-0.5-1.2c-0.1-0.6-0.5-1-0.6-1.5c-0.1-0.3-0.2-0.7-0.4-1
        c-0.2-0.2-0.1-0.6-0.1-0.9c-0.1,0-0.2-0.1-0.3-0.1c0-0.8-0.6-1.4-0.8-2.1c0-0.2-0.1-0.3-0.2-0.5c0-0.6-0.5-1.1-0.6-1.7
        c-0.3-0.5-0.5-0.9-0.8-1.4c-0.1-0.3,0-0.7-0.3-0.8c-0.3-0.9-0.7-1.7-0.9-2.7c-0.2-0.4-0.4-0.7-0.5-1.2c-0.2-0.4-0.4-0.7-0.5-1.1
        c-0.4-0.6-0.5-1.3-0.9-1.9c0-0.1,0-0.2,0-0.3c-0.1-0.2-0.3-0.3-0.3-0.5c-0.1-0.5-0.3-1-0.6-1.4c0.2-0.5-0.4-0.9-0.5-1.4
        c-0.2-0.3-0.4-0.7-0.3-1.1c-0.5-0.2-0.5-0.7-0.4-1.2c-0.5-0.2-0.6-0.8-0.5-1.2c-0.2-0.2-0.5-0.5-0.4-0.8c0.1-0.4-0.4-0.5-0.4-0.8
        c-0.1-0.5-0.5-0.8-0.5-1.3c0-0.3-0.4-0.5-0.4-0.8c0-0.6-0.6-1-0.5-1.6c-0.3-0.2-0.4-0.5-0.4-0.8c-0.4-0.2-0.4-0.7-0.4-1
        c-0.5-0.8-0.8-1.8-1.3-2.6c-0.3-0.5-0.4-1.2-0.9-1.6c0.3-0.5-0.4-0.6-0.4-1.1c0-0.4-0.6-0.8-0.2-1.1c-0.1-0.1-0.3-0.2-0.4-0.4
        c-0.1-0.4-0.6-0.8-0.4-1.3c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.2,0-0.3c-0.2,0.1-0.3,0.1-0.4-0.1C132.8,135,132.8,135.2,132.7,135.2
        M157.2,135.1c-0.1,0.2,0,0.3,0.2,0.2C157.5,135.1,157.4,135,157.2,135.1 M289.3,135.3c-0.1,0.2,0,0.3,0.2,0.2
        C289.6,135.3,289.5,135.2,289.3,135.3 M95.5,135.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.2-0.2,0.3c0.2-0.1,0.3-0.2,0.6-0.2
        c0,0,0-0.1,0-0.1C95.8,135.5,95.6,135.5,95.5,135.4 M334.2,135.7c0,0.4,0.7,0.3,0.6,0.7c-0.2,0.2-0.4-0.1-0.6-0.2
        c0,0.1,0,0.2,0.1,0.2c0.4,0.1,0.8,0.3,1,0.6c0,0.3-0.1,0.6,0.1,0.8c0.3,0.3-0.2,0.4-0.3,0.5c-0.1-0.1-0.3-0.1-0.4,0
        c0.1,0.1,0.2,0.3,0.4,0.2c0.2-0.1,0.4,0.1,0.5,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c0.2,0.2,0.4,0.3,0.6,0.5l-0.2,0.1c0,0.1,0,0.3,0.1,0.5
        c0.1-0.2,0.3-0.4,0.5-0.6c-0.1-0.4-0.4-0.8-0.6-1.2c0-0.1,0.1-0.2,0.1-0.3c-0.1-0.1-0.2-0.2-0.2-0.3c0.1-0.2,0.1-0.5,0.1-0.7
        c-0.2-0.1-0.5-0.1-0.5-0.4c0.1,0,0.2,0.1,0.3,0.1c0-0.2,0-0.4-0.1-0.6c-0.1-0.3-0.5-0.2-0.7-0.4
        C334.5,135.6,334.3,135.6,334.2,135.7 M388.1,135.5c-0.2,0.1-0.2,0.2,0,0.3C388.3,135.7,388.3,135.6,388.1,135.5 M333.9,135.7
        C333.6,136.1,334.3,135.7,333.9,135.7 M263.1,135.8c-0.2,0-0.3,0.3-0.1,0.3C263.2,136.2,263.4,135.8,263.1,135.8 M126.6,136.4
        c0.1-0.1,0.2-0.1,0.3-0.2l0,0.2l0.3-0.1c-0.1-0.2-0.3-0.3-0.4-0.4C126.7,136.1,126.6,136.2,126.6,136.4 M146.7,136.2
        c-0.1,0.1-0.4,0.6-0.1,0.5c0.1-0.1,0.2-0.2,0.3-0.3C146.9,136.3,146.9,136.2,146.7,136.2 M240.6,136.4
        C240.4,136.8,241.1,136.4,240.6,136.4 M289.8,137C289.9,137.1,289.9,137.1,289.8,137 M334.7,137.3c0.1,0,0.3,0,0.4,0
        c-0.1-0.1-0.2-0.3-0.3-0.4C334.6,137,334.5,137.2,334.7,137.3 M146.9,137.5c0,0-0.1-0.1-0.2-0.2
        C146.3,137.4,146.8,137.9,146.9,137.5 M253,137.8c-0.1,0.2,0,0.3,0.2,0.2C253.3,137.8,253.2,137.8,253,137.8 M264.4,138.2
        C264.4,138.7,264.8,138,264.4,138.2 M289.7,138.4c0.1,0.2,0,0.7,0.4,0.6C290.2,138.8,289.9,138.5,289.7,138.4 M93.5,138.7
        C93.5,138.8,93.5,138.8,93.5,138.7 M379.8,138.8c0,0.2,0.1,0.5-0.1,0.7c-0.1,0.1-0.6,0.6-0.2,0.6c0,0,0.1-0.1,0.2-0.1l0-0.2
        c0,0.1,0.1,0.2,0.2,0.3c0.1-0.2,0.5-0.4,0.4,0l-0.1,0.3c0.5-0.1,0.2-0.5,0.1-0.8c0.6-0.3,0.4,0.4,0.8,0.6c0-0.3,0-0.6-0.2-0.9
        c0,0.1-0.1,0.2-0.2,0.2l-0.2,0c-0.1-0.3,0-0.7-0.3-0.9C380.1,138.6,379.9,138.8,379.8,138.8 M133.3,139.2c-0.2,0.1-0.1,0.4,0.1,0.3
        C133.6,139.4,133.5,139,133.3,139.2 M133.8,139.3c0,0.2,0,0.3,0.2,0.2C134.1,139.3,134,139.2,133.8,139.3 M381.3,139.2
        c0,0.2,0,0.3,0.2,0.2C381.6,139.2,381.5,139.2,381.3,139.2 M128.8,139.2c0,0.1,0.1,0.3,0.1,0.4c-0.2,0.1-0.3,0.3-0.2,0.5
        C129.2,140.3,129.2,139.3,128.8,139.2 M334.9,139.4c0.1,0.1,0.2,0.2,0.3,0.3c0,0.3,0,0.8,0.5,0.7l0,0.3l-0.3-0.1
        c0.1,0.1,0.2,0.2,0.4,0.2c-0.1,0.2-0.1,0.3-0.2,0.5c0,0.1,0.1,0.3,0.2,0.4c0.3-0.4,0-0.9,0-1.2l0.4,0.1c0,0.3,0.1,0.7,0.1,1l0.1-0.4
        c0.4,0.1-0.1,0.3-0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.2c0-0.1,0-0.3,0-0.4c0.4,0,0,0.3-0.1,0.5c-0.3,0-0.5-0.4-0.7,0
        c0.3,0.1,0.5,0.2,0.7,0.4c0,0.2,0.1,0.5,0.2,0.7c0.2,0.1,0.3,0.1,0.5,0.2c-0.1,0.2-0.3,0.1-0.5,0.1c0,0.2,0.2,0.4,0.4,0.5l-0.2,0
        c0.1,0.1,0.2,0.2,0.3,0.2c0,0.1,0,0.2,0,0.3c-0.3-0.1-0.4-0.6-0.7-0.7c0.1,0.3,0.3,0.6,0.5,0.9c0.2-0.1,0.3,0,0.5,0.1
        c-0.3,0-0.6,0-0.8-0.2c0,0.1-0.1,0.3-0.1,0.4l-0.3-0.1c0,0.2,0.2,0.3,0.3,0.4c0.1-0.2,0.2-0.3,0.3-0.5c0.4,0.4-0.2,0.5-0.4,0.7
        c0.1,0,0.4,0.1,0.6,0.2l-0.1-0.4l0.3-0.1l-0.1,0.3c0.1,0,0.2,0,0.2,0c-0.1,0-0.2,0.1-0.3,0.1c0,0.1,0.1,0.4,0.2,0.6l0.2-0.1
        c0,0.1,0,0.2,0,0.2c-0.1,0.1-0.2,0.3-0.3,0.4l0.3,0.1c0,0.1-0.1,0.2-0.2,0.3c-0.2,0.3-0.1,0.6,0.1,0.9c-0.2,0.3-0.3,0.5-0.5,0.8
        c0,0,0.2,0,0.2,0c0.2,0.2,0.3,0.3,0.3,0.6c-0.1,0-0.3,0-0.4,0c0.1,0.2,0.2,0.4,0.4,0.3c-0.1,0.3-0.4,0-0.5,0c0,0.1,0.1,0.3,0.1,0.4
        c0.1,0,0.2,0,0.2,0c0.1,0.2,0.3,0.4,0.5,0.5c0,0.1,0.1,0.2,0.1,0.2c-0.2,0.1-0.6,0.1-0.7,0.3c0.1,0,0.3,0,0.4,0
        c-0.1,0.3-0.4,0.3-0.6,0.1c0,0.1,0.1,0.3,0.2,0.5c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0,0.3-0.1,0.4-0.1c0-0.3,0.2-0.5,0.3-0.6
        c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0-0.3,0-0.3,0l0,0.3c0.1,0,0.2,0,0.3,0c0.1,0.2,0.1,0.5,0.1,0.7c-0.2-0.2-0.2-0.4-0.2-0.7
        c-0.1,0-0.3,0.1-0.4,0.2c0,0.3,0,0.6,0.4,0.6c-0.1,0.2,0,0.4,0.1,0.6c0,0-0.1,0.1-0.1,0.1c-0.1-0.1-0.3-0.2-0.4-0.3c0,0-0.1,0-0.2,0
        c0-0.1,0-0.3-0.1-0.4c-0.1,0.1-0.2,0.3-0.3,0.4c0.2,0,0.4,0.1,0.5,0.2c0,0.1,0.1,0.3,0.1,0.4c-0.1,0-0.3,0-0.4,0
        c0,0.1,0.1,0.2,0.2,0.2c-0.2,0.1-0.3,0.2-0.3,0.4c0.3-0.3,0.6-0.5,0.9-0.3L338,154c-0.1,0-0.3,0-0.3,0l-0.2,0.3
        c0.2,0,0.5-0.1,0.7-0.1c0.1-0.1,0.2-0.2,0.3-0.3c-0.1,0.2-0.3,0.3-0.2,0.5c0.1,0.1,0.2,0.3,0.1,0.5c-0.3,0.1-0.2-0.3-0.3-0.4
        l-0.1-0.1c-0.1,0.1-0.3,0.3-0.4,0.4c-0.1-0.1-0.2-0.3-0.3-0.4l-0.2,0.1c0.1,0.4,0.5,0.3,0.6,0.6c0,0.2-0.2,0.3-0.2,0.4
        c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0,0.1-0.4,0.1-0.5c0.1,0,0.2,0.1,0.2,0.1c-0.2,0.3,0.1,0.5,0.2,0.7c-0.1,0.1-0.1,0.2-0.2,0.3
        c-0.1-0.3-0.4-0.5-0.7-0.4c0.1,0.1,0.2,0.2,0.2,0.2c0,0.1-0.1,0.3-0.1,0.4c0.3-0.3,0.5,0,0.7,0.3c-0.4,0-0.7-0.2-1.1-0.3
        c0,0.2,0,0.3,0.1,0.5c0.1-0.3,0.9-0.2,0.4,0.1l0.1,0.3c-0.1,0-0.2,0-0.3,0c0,0.1,0.1,0.2,0.2,0.3c-0.1,0-0.3,0.1-0.3,0.1
        c0.1,0.1,0.2,0.2,0.4,0.3c0,0.3-0.1,0.6,0,1c-0.1,0.1-0.3,0.2-0.4,0.3c0.1,0,0.2,0,0.3-0.1c0.1,0.2-0.2,0.4-0.1,0.5
        c0.1,0.2,0.1,0.3,0.2,0.5c-0.1,0.1-0.3,0.2-0.3,0.4c0,0.2,0.2,0.4,0.3,0.6l-0.3,0c0.1,0.3,0.6,0.4,0.7,0c-0.1,0-0.3-0.1-0.4-0.1
        c0.1-0.2-0.1-0.4-0.1-0.7c0.2-0.1,0.4-0.1,0.6-0.2c-0.2-0.1-0.4-0.3-0.4-0.5c0.1-0.1,0.2-0.2,0.3-0.4c-0.1-0.1-0.2-0.3-0.3-0.3
        l0-0.1l0-0.1l-0.1-0.2c0.1-0.1,0.3-0.1,0.4-0.2c-0.5-0.2,0-0.5,0-0.8l0,0c0,0.3,0,0.5,0.1,0.8c-0.2,0.1-0.3,0.3-0.5,0.4
        c0.4,0.1,0.5,0.7,0.1,0.8c0.1,0.1,0.2,0.3,0.3,0.4c-0.2,0.1-0.6,0.1-0.5,0.5l0.3,0c-0.1,0.1-0.2,0.2-0.3,0.4c0.1,0,0.4,0.1,0.5,0.1
        c-0.1,0.2-0.2,0.4-0.4,0.5c0.2,0.5-0.6,0.4-0.6,0.8c0.5-0.4,0.5,0.3,0.2,0.4c-0.1-0.5-0.3,0-0.4,0.1c0.1,0,0.3-0.1,0.4-0.1
        c-0.1,0.2-0.2,0.5-0.3,0.7c0.1,0,0.3-0.1,0.4-0.2c0.1,0.2,0.2,0.4,0.2,0.6c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1,0.3-0.2,0.6-0.3,0.9
        c0.1,0,0.2,0.1,0.3,0.1c0-0.1,0-0.4,0-0.5c0.1,0.1,0.1,0.3,0.2,0.4c-0.4,0.2-0.5,0.7-0.7,1.1c0.4-0.1,0.5-0.7,1-0.8
        c-0.2,0.3-0.8,0.5-0.4,0.9c-0.1,0-0.3-0.1-0.4-0.1c0.1,0.3,0.3,0.6,0.5,0.7c0,0.3-0.1,0.5-0.1,0.8l-0.1,0c0.1-0.2,0.1-0.5,0.2-0.7
        l-0.2,0.1c-0.1-0.2-0.1-0.3-0.3-0.4c-0.1,0.3,0,0.5,0,0.8c-0.2,0.1-0.4,0.1-0.6,0.2c0,0.1,0,0.3,0,0.4l0.2-0.3
        c0.1,0.2,0.3,0.4,0.5,0.6c-0.2,0-0.4-0.1-0.5-0.1c0.1,0,0.3,0.1,0.4,0.1c-0.2,0.2-0.3,0.5-0.4,0.8c0-0.2,0.7-0.2,0.5-0.5
        c-0.1,0-0.2-0.1-0.2-0.1c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.3-0.2,0.4-0.3c0,0.6,0.3-0.2,0.6,0c0.1-0.1,0.2-0.2,0.2-0.2l0,0.3
        c0,0,0.1,0,0.1,0c0.1-0.4,0.4-0.8,0.3-1.3c0.1-0.1,0.2-0.3,0.4-0.4c-0.3-0.5,0-1,0.2-1.4c0-0.3-0.2-0.5-0.1-0.8
        c0.3-0.6-0.1-1.3,0.3-1.8c0-0.2-0.1-0.5-0.1-0.7c0.1-0.4-0.2-0.8,0.1-1.1c0.3-0.2-0.1-0.5-0.2-0.7c0.1-0.2,0.1-0.3,0.2-0.5
        c-0.2-0.7,0-1.4-0.2-2.1c0.1-0.2,0.2-0.5,0.1-0.7c0-0.6,0-1.2-0.2-1.8c0.2-0.4,0.1-0.8,0.1-1.2c0.1-0.1,0.1-0.3-0.1-0.4
        c-0.4-0.4,0-0.9-0.2-1.3c-0.1-0.2-0.2-0.5-0.1-0.7c0.1-0.3-0.2-0.5-0.2-0.8c0-0.3,0-0.6-0.2-0.9c0.1-0.3,0-0.6-0.1-0.9
        c-0.3-0.2,0-0.5-0.1-0.7c-0.2-0.3-0.4-0.7-0.2-1.1c-0.1-0.2-0.4-0.3-0.2-0.6c0.2-0.4-0.3-0.7-0.4-1.1c0-0.1,0.1-0.3,0.1-0.4
        c-0.1,0-0.3-0.1-0.4-0.1c0.1,0,0.3,0,0.5,0c-0.5-0.4-0.6-1.1-1.1-1.5c0.2,0.1,0.4,0.2,0.6,0.2c-0.1-0.7-0.8-1.1-0.7-1.8
        c-0.2-0.2-0.4-0.4-0.6-0.6c0.1,0,0.3,0,0.5,0c-0.2-0.1-0.3-0.3-0.5-0.2c-0.2,0.1-0.3,0.3-0.5,0.4c-0.1-0.2-0.3-0.4-0.2-0.7
        C335.2,139.6,335.1,139.5,334.9,139.4 M133.8,140.1c0.2,0,0.2-0.4,0-0.5C133.6,139.6,133.5,140,133.8,140.1 M134.3,139.9
        c-0.1,0.2,0,0.3,0.2,0.3C134.5,140.1,134.4,140,134.3,139.9 M253.1,140C252.9,140.4,253.6,140,253.1,140 M379.7,140.1l0,0.2l0.1,0.2
        c0,0.1,0,0.3-0.1,0.4c-0.2,0.2-0.1,0.3,0.1,0.4c-0.1,0-0.3,0.1-0.4,0.2c0.2,0.1,0.4,0.1,0.6,0.3c0.1,0.1,0.2,0.2,0.2,0.3
        c0-0.1-0.1-0.3-0.1-0.4c0.1-0.2,0.3-0.5,0.4-0.8c-0.2,0.2-0.3,0.4-0.4,0.7c-0.1-0.1-0.5-0.2-0.3-0.4c0.3-0.2,0.5-0.5,0.5-0.8
        c-0.1,0.1-0.2,0.3-0.3,0.4c0-0.2,0-0.5,0-0.6C380,140.1,379.8,140.1,379.7,140.1 M378.7,141.5c-0.3,0.6-0.6,1.4-1,2
        c-0.1,0.4-0.1,0.8-0.4,1c0,0.3-0.1,0.6-0.4,0.8c0,0.7-0.7,1.1-0.6,1.9c-0.4,0.3-0.2,0.9-0.6,1.2c-0.2,0.4-0.3,1-0.7,1.3
        c0.2,0.4-0.1,0.8-0.4,1.1c0,0.4,0,1.1-0.5,1.2c0.1,0.5,0,1-0.4,1.2c0.1,0.6-0.4,1-0.5,1.5c-0.5,0.3-0.2,0.9-0.6,1.3
        c-0.3,0.4-0.3,1.1-0.6,1.5c0.1,0.3-0.1,0.6-0.2,0.9c-0.4,1.2-1,2.3-1.5,3.4c-0.2,0.3-0.2,0.6-0.3,0.9c-0.3,0.6-0.5,1.3-0.7,2
        c-0.4,0.3-0.2,0.8-0.4,1.1c-0.2,0.6-0.7,1-0.8,1.7c-0.4,0.5-0.4,1.1-0.6,1.6c-0.2,0.8-0.8,1.3-0.8,2.1c-0.1,0-0.2,0.1-0.3,0.1
        c0.1,0.4,0,0.8-0.3,1.1c-0.2,0.5-0.4,1.1-0.7,1.6c-0.3,0.7-0.4,1.5-0.8,2.1c0,0.2-0.1,0.5-0.3,0.6c0,0.3-0.2,0.6-0.4,0.9
        c0,0.6-0.6,1-0.6,1.6c-0.1,0.5-0.7,0.9-0.7,1.4c0,0.4-0.3,0.7-0.5,1c-0.1,0.2,0,0.5-0.2,0.7c-0.4,0.7-0.4,1.6-1,2.2
        c0,0.1,0.1,0.2,0.1,0.3c-0.5,0.1-0.3,0.7-0.7,1c0,0.2,0.1,0.4-0.1,0.6c-0.3,0.3-0.2,0.9-0.5,1.2c-0.2,0.2-0.3,0.4-0.5,0.6
        c0.1,0.3-0.1,0.6-0.2,0.9c0,0.1,0.1,0.3,0.2,0.4c-0.1,0-0.2-0.1-0.3-0.1c-0.4,0.3-0.3,0.7-0.6,1c-0.1,0.2-0.2,0.4-0.3,0.6
        c0.1,0.2,0.2,0.3,0.4,0.4c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0.3-0.1,0.5-0.1,0.8l0.2,0c0,0.3,0.2,0.5,0.4,0.7l0.1-0.4
        c0.1,0.1,0.2,0.2,0.3,0.3l-0.1-0.2h0.1l0-0.2l0.2,0.1c0-0.3,0.3-0.1,0.5-0.2c-0.1,0-0.2,0-0.3,0l0-0.1c0,0.1-0.1,0.2-0.2,0.3
        c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2,0.1-0.6,0.2-0.3-0.2c0.2-0.3,0.4-0.5,0.1-0.8l0.1,0c0,0.1,0.1,0.2,0.2,0.2c0.1-0.4,0.5-0.4,0.6-0.7
        c0-0.2-0.3-0.2-0.3-0.3c0.1-0.1,0.4-0.2,0.6-0.3c0.1,0.1,0.2,0.3,0.4,0.3c-0.1-0.2-0.1-0.5-0.4-0.5c-0.1-0.1-0.2-0.1-0.3-0.2
        c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1-0.3,0.2-0.4,0.5-0.4c0-0.1,0-0.3,0.1-0.4c0.3,0.1-0.2,0.5,0.1,0.5c0-0.1,0.1-0.3,0.2-0.4
        c0.2,0,0.6-0.1,0.7-0.3c-0.3,0-0.6,0.2-0.9,0.2c0-0.1,0.1-0.3,0.1-0.4l-0.2,0c0-0.1,0-0.2,0.1-0.3c0.1,0.1,0.2,0.2,0.3,0.3
        c0.1-0.3,0.3-0.6,0.7-0.4c-0.1-0.1-0.2-0.4-0.2-0.5l0.3,0.1c-0.2-0.3,0.1-0.9,0.4-0.5c0.1-0.1,0.3-0.2,0.5-0.1c0,0-0.2-0.1-0.2-0.1
        c-0.2,0.3-0.4,0-0.6-0.1l0.3-0.1c0-0.1,0-0.2,0.1-0.3c0.4,0.2,0.6-0.3,1-0.4l0,0c-0.2,0.1-0.6,0.1-0.7,0.4c-0.3-0.1-0.5-0.3-0.1-0.5
        c-0.1-0.2-0.2-0.4-0.3-0.6c0.3,0.2,0.5-0.1,0.7-0.3c0.1,0.1,0.2,0.1,0.3,0.2c0,0.1,0,0.3,0,0.4c-0.2-0.1-0.4-0.2-0.6-0.1
        c0.1,0.1,0.2,0.2,0.2,0.3c0.2-0.1,0.5-0.2,0.7-0.3c-0.2-0.2-0.3-0.4-0.4-0.6l0.4,0.1c-0.2-0.1-0.3-0.2-0.5-0.3
        c0.1,0,0.2-0.1,0.3-0.1c-0.1-0.2-0.1-0.4,0-0.6c-0.2-0.3,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.6,0.5-0.6c0,0.2-0.1,0.4,0,0.6
        c0.1-0.1,0.2-0.3,0.3-0.4c-0.2-0.3-0.3-0.6-0.3-0.9l0.2,0.1c0-0.1,0-0.3-0.1-0.4c0.1,0,0.2-0.1,0.2-0.1l0.1-0.2
        c0.1,0.1,0.2,0.1,0.3,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0,0.2,0,0.3,0c0.1-0.3,0-0.6-0.3-0.7c-0.1,0-0.3,0-0.4,0
        c0-0.6,0.6-0.1,0.8-0.6c-0.1,0-0.3,0-0.3,0l0-0.1l0.2,0.1c0.1-0.3,0.3-0.7,0.1-1c-0.2,0.2-0.3,0.5-0.3,0.8c-0.2,0.1-0.4,0.6-0.7,0.5
        c0.4-0.1,0.6-0.5,0.5-0.9c0.1,0,0.2-0.1,0.3-0.1l0-0.1c0.1-0.1,0.3-0.2,0.4-0.3c0.1,0.2,0.2,0.3,0.3,0.5c0.2,0.1,0.3,0.3,0.5,0.4
        c-0.1-0.2-0.2-0.4-0.2-0.6c0.2,0,0.6,0,0.4-0.4l0.2,0.1c-0.2-0.2-0.3-0.3-0.5-0.5c0.3,0.1,0.2,0.5,0.1,0.6c-0.3,0.1-0.4-0.3-0.5-0.5
        c0.2-0.3,0.5-0.3,0.7-0.6c0.2,0.1,0.5,0.2,0.7,0.4c0-0.2,0.1-0.4,0.3-0.5c-0.1,0-0.2-0.1-0.1-0.2c0-0.1,0.1-0.2,0.2-0.2
        c-0.1,0-0.2,0.1-0.3,0.2l0,0.1c-0.1,0.2-0.2,0.3-0.4,0.4c0-0.3-0.2-0.5-0.4-0.7c0.1-0.1,0.3-0.2,0.4-0.3c0,0.1,0,0.2,0.1,0.3
        c0-0.1,0-0.3,0-0.4c-0.1,0-0.2,0.1-0.3,0.1l0-0.4c0.1,0,0.2,0.1,0.3,0.1c0.1-0.3,0.4-0.3,0.7-0.4c0.1-0.3-0.4,0-0.5,0
        c-0.3-0.4,0.8-0.5,0.1-0.6c0.1-0.1,0.2-0.3,0.4-0.4c0.2,0,0.3,0,0.5,0.1c-0.1-0.2-0.3-0.2-0.5-0.2c0.1-0.2,0.3-0.3,0.6-0.3
        c0.2-0.6,0.6-0.9,1.1-1.2c-0.1-0.3,0.2-0.7-0.1-0.8c0.1-0.3,0.2-0.6,0.1-0.8c-0.1-0.4,0.2-0.7,0-1.1c0.1,0.3,0.5,0.6,0.6,0.1l-0.3,0
        c0.1-0.2,0.3-0.2,0.5-0.3c-0.1-0.1-0.1-0.3-0.2-0.4c0.4-0.1,0.7-0.3,1.1-0.3c0,0,0-0.1,0-0.2c-0.1,0.1-0.3,0.2-0.4,0.2
        c0-0.3,0.1-0.4,0.4-0.4c0.1-0.1,0.2-0.3,0.3-0.4c-0.2,0-0.3,0-0.5-0.1c0,0,0.1-0.1,0.1-0.1l0,0.1c0.2,0,0.4,0,0.6,0
        c0.1-0.3,0.1-0.7,0-1c-0.1,0-0.3-0.1-0.4-0.1c0.2-0.2,0.4-0.5,0.7-0.7c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1-0.2-0.2-0.6,0.2-0.6
        c0-0.2,0.4-0.5,0.2-0.7c-0.1,0.1-0.2,0.3-0.3,0.4c0-0.1,0-0.4-0.1-0.6c-0.2,0-0.3,0-0.5-0.1c-0.1-0.2,0-0.3,0.1-0.4
        c0,0.1,0.1,0.3,0.1,0.4c0.2-0.1,0.4-0.1,0.6-0.2c-0.2-0.1-0.4-0.2-0.5-0.3l0-0.1c-0.2-0.2-0.5-0.4-0.5-0.6c0.3,0.1,0.4,0.4,0.6,0.6
        c0-0.1,0.1-0.3,0.2-0.4c0.1-0.3-0.3-0.4,0-0.7c0,0.1,0.1,0.2,0.1,0.3c0.2-0.1,0.4-0.1,0.6,0c-0.1-0.1-0.3-0.4-0.4-0.6l0,0.3
        c-0.1-0.1-0.2-0.1-0.3-0.1c0.3-0.3-0.3-0.8,0.3-0.7c-0.1,0.1-0.2,0.2-0.2,0.3c0.2,0.1,0.7,0.7,0.4,0l0.2-0.1c0-0.1,0-0.4,0-0.6
        c-0.1,0-0.2,0-0.3,0c0.1-0.1,0.2-0.2,0.2-0.3c0.2-0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0,0.3,0c-0.2-0.2-0.3-0.4-0.4-0.6
        c0,0,0-0.1,0-0.1c0.1,0.1,0.3,0.2,0.4,0.2c0.1-0.4-0.3-0.5-0.6-0.3c0-0.3,0.3-0.2,0.5-0.2c0.1-0.1,0.3-0.2,0.4-0.2
        c-0.2-0.1-0.5-0.1-0.7-0.2c0.2,0,0.3,0,0.5-0.1c0-0.2-0.1-0.4,0-0.6c0.4,0.2,0,0.6,0.3,0.8c0.3-0.1,0-0.4,0-0.6
        c0.1-0.2,0.3-0.3,0.1-0.6c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0-0.3,0-0.3,0c0.1,0,0.2-0.1,0.2-0.1c0,0-0.1-0.1-0.2-0.2
        c0.2-0.1,0.3-0.2,0.4-0.3c0.2,0.2,0.4,0.5,0.7,0.7c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.3-0.5-0.7-0.1-0.9c0,0.2,0.1,0.4,0.3,0.5
        c0-0.1,0-0.4,0-0.6c0.3-0.1,0.4-0.3,0.6-0.4c-0.1,0-0.2-0.1-0.2-0.1c-0.1,0.1-0.1,0.3-0.3,0.4c0-0.2-0.1-0.5-0.3-0.6
        c0.1,0,0.3,0,0.3,0l0-0.2c0.1,0.1,0.4,0.2,0.5,0.2c-0.1-0.3-0.2-0.6-0.2-0.9c0.1,0.1,0.3,0.1,0.4,0.2c0.1-0.1,0.1-0.2,0.2-0.3
        c-0.1,0-0.3,0-0.4,0c0-0.1,0.1-0.2,0.1-0.3c-0.1-0.2-0.2-0.4-0.2-0.5c0.1,0,0.2,0,0.2,0c0.1-0.2,0.2-0.3,0.4-0.4c0,0.2,0,0.4,0,0.6
        c-0.1,0-0.3,0-0.4,0c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.3,0,0.5-0.1c-0.2-0.3-0.1-0.7-0.3-1c0.2-0.1,0.2-0.3,0.1-0.5
        c0.1,0.1,0.3,0.2,0.4,0.3c0.1-0.1,0.1-0.3,0.2-0.4c-0.2,0.1-0.3,0.1-0.4,0.2c0-0.5,0.7-0.2,0.7-0.6c-0.1,0-0.4,0-0.5,0
        c0-0.3,0.2-0.6,0-0.8c0.3-0.1,0.1,0.5,0.5,0.3c0-0.1,0-0.3,0-0.4c0.1-0.1,0.3-0.2,0.5-0.2c0,0.3-0.1,0.5-0.2,0.7
        c0.1-0.2,0.3-0.3,0.4-0.5c-0.2-0.2-0.4-0.5-0.7-0.4c-0.2,0.3-0.4,0.2-0.4-0.1c0.2,0,0.3-0.1,0.5-0.1c0.5,0.5,0.2-0.6,0.7-0.3
        c-0.1,0-0.2-0.1-0.3-0.1c0.2-0.2,0.2-0.6,0.6-0.5c0,0,0-0.1,0-0.1c-0.1,0-0.3,0-0.4,0c0.1-0.3,0.4-0.4,0.6-0.6c0,0-0.1,0-0.2,0
        c-0.2,0.1-0.5,0.6-0.6,0.1c0.3-0.2,0.7-0.2,0.9-0.5c-0.1,0-0.3,0.1-0.4,0.1c0-0.2-0.4-0.5-0.1-0.5c0.4-0.2,0.1,0.3,0,0.4
        c0.2,0,0.4-0.1,0.6-0.2c0-0.1,0-0.4,0-0.5c0.1,0,0.2,0,0.2,0c0-0.2,0-0.4,0-0.5c-0.1,0-0.3,0-0.4,0.1c-0.1,0.2-0.1,0.3-0.2,0.5
        c-0.2-0.2-0.1-0.4,0-0.7c0.2,0,0.3,0,0.5,0c-0.1-0.1-0.2-0.4-0.3-0.5c0.1,0.1,0.2,0.3,0.4,0.3c0.1-0.1,0.2-0.3,0.3-0.4
        c-0.1,0-0.4,0-0.5,0.1c0.1-0.1,0.2-0.1,0.4-0.2c-0.1-0.2-0.2-0.5-0.3-0.8c0.2,0.2,0.3,0.4,0.4,0.7c0.2-0.1,0.3-0.2,0.6-0.1
        c0-0.4-0.3-0.2-0.4,0c-0.1-0.3,0-0.7,0.4-0.8l0,0.2l0.1,0c0-0.1,0-0.4,0-0.5c-0.1,0-0.2,0.1-0.2,0.1c0.2-0.3,0.1-0.6-0.1-0.9
        c0.1-0.1,0.2-0.2,0.4-0.1c-0.4,0.1-0.1,0.4,0.1,0.5c0.3,0.1,0.1,0.6,0.5,0.6c0-0.1-0.1-0.3-0.2-0.4c0-0.1,0.1-0.2,0.1-0.3
        c-0.2,0-0.4-0.1-0.5-0.2c0.3-0.3,0.6-0.5,1-0.4c-0.2-0.1-0.5-0.2-0.8-0.1c0.1-0.5,0.6-0.4,1-0.6c0.2-0.1,0-0.4-0.1-0.5l0.3,0
        c-0.1-0.1-0.1-0.2-0.2-0.2c0,0.1-0.1,0.3-0.2,0.4c0,0.1,0,0.3-0.1,0.5c-0.1,0-0.3,0-0.4-0.1c-0.1,0-0.3,0.2-0.4,0.2
        c0-0.2,0-0.4,0.4-0.3c-0.2-0.3,0-0.5,0.1-0.8c-0.1,0.2-0.2,0.4,0,0.6c0.1-0.1,0.2-0.3,0.3-0.4c-0.1-0.1-0.2-0.2-0.3-0.2
        c0.3-0.1,0.6-0.2,0.9-0.4c-0.4,0.1-0.5-0.2-0.7-0.5c0.1,0,0.2,0,0.3,0l0.1-0.2l-0.3,0c0.1-0.3,0.1-0.6-0.1-0.9c0,0,0.1,0,0.2,0
        c-0.1,0.4,0.1,0.6,0.4,0.8l-0.2-0.4c0.2-0.2,0.3-0.5,0.5-0.7c-0.1,0-0.3,0-0.4-0.1c0.2-0.2,0.4-0.3,0.6-0.5c0-0.2,0-0.5,0.1-0.7
        c-0.2,0.1-0.4,0.3-0.6,0.5c0-0.2,0-0.4,0.1-0.6c0,0.1,0.1,0.2,0.2,0.3c0.2-0.5,0.8-0.7,1-1.3c-0.1,0-0.2-0.1-0.3-0.1
        c0.3-0.1,0.3-0.4,0.3-0.7c-0.2,0.2-0.3,0.4-0.5,0.5c-0.1,0.2-0.3,0.6,0.1,0.6c-0.1,0.1-0.1,0.1-0.2,0.2c0-0.1,0-0.2,0-0.3l-0.2,0
        c0,0.1,0,0.3,0,0.4c-0.1-0.1-0.1-0.2-0.2-0.3c0.3-0.3,0.4-0.9,0.8-1.1c0.1-0.2,0.1-0.3,0.2-0.5c-0.1,0-0.4,0-0.5,0
        c0.1,0,0.2,0,0.2,0c0.1-0.1,0.2-0.3,0.3-0.4c-0.1-0.1-0.2-0.2-0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.3c-0.1,0-0.2,0-0.3,0
        C379,140.6,378.9,141.1,378.7,141.5 M109.3,140.6c-0.2,0.2,0.2,0.3,0.3,0.1C109.7,140.5,109.4,140.4,109.3,140.6 M339.9,140.5
        c-0.1,0.2,0,0.3,0.2,0.2C340.2,140.5,340.1,140.4,339.9,140.5 M405.2,140.6c0.1,0,0.2,0.1,0.3,0.1
        C405.6,140.6,405.2,140.4,405.2,140.6 M252.6,140.9c-0.1,0.1-0.2,0.4,0,0.5C253,141.3,252.9,140.7,252.6,140.9 M127.2,141.9
        c0.2-0.1,0.4-0.3,0.7-0.4C127.7,141.1,127.3,141.7,127.2,141.9 M386.9,141.3c-0.1,0.4,0.4,0.5,0.6,0.6
        C387.4,141.7,387.2,141.5,386.9,141.3 M239.8,141.7C239.6,142.2,240.2,141.7,239.8,141.7 M341,141.7
        C341.1,141.8,341.1,141.8,341,141.7 M266.6,142.1c-0.2,0.2,0.2,0.4,0.3,0.1C267.1,142.1,266.7,142,266.6,142.1 M289.6,142.1
        c0,0.2,0.1,0.3,0.2,0.4C290.3,142.6,289.9,142,289.6,142.1 M296.3,142.3c-0.2,0.1,0,0.5,0.2,0.3
        C296.7,142.5,296.6,142.1,296.3,142.3 M336.1,143.1c0.1-0.1,0.3-0.2,0.4-0.3c-0.1-0.1-0.2-0.2-0.2-0.2
        C335.9,142.5,336.1,142.9,336.1,143.1 M95,142.9C94.7,143.3,95.5,143,95,142.9 M127,143c-0.1,0.2,0,0.3,0.2,0.2
        C127.3,143,127.2,142.9,127,143 M336.2,143.2c0,0.1,0,0.3,0,0.4c0.1,0,0.4,0,0.5,0c-0.2-0.2-0.3-0.4-0.2-0.6
        C336.4,143,336.3,143.1,336.2,143.2 M387.5,143c-0.1,0.2,0,0.3,0.2,0.3C387.8,143,387.8,142.9,387.5,143 M292.5,143.1
        C292.3,143.5,293,143.2,292.5,143.1 M239.8,143.2C239.8,143.7,240.2,142.9,239.8,143.2 M251.2,143.2
        C251.2,143.7,251.6,142.9,251.2,143.2 M87.3,143.4C87.1,143.8,87.8,143.5,87.3,143.4 M149.2,143.4c-0.1,0.2,0.2,0.5,0.4,0.4
        C149.6,143.6,149.4,143.3,149.2,143.4 M427.3,143.4C427.1,143.8,427.8,143.4,427.3,143.4 M135.2,143.7c0,0.2,0,0.3,0.2,0.2
        C135.5,143.7,135.4,143.6,135.2,143.7 M149.8,143.7c0,0.1-0.1,0.2-0.1,0.2C149.9,144.4,150.2,143.4,149.8,143.7 M255.9,143.5
        c0.1,0.2,0.1,0.3,0.3,0.5c0,0.2,0,0.5,0.2,0.7C256.5,144.3,256.3,143.6,255.9,143.5 M379.3,143.7c-0.1,0.2,0,0.3,0.2,0.2
        C379.6,143.6,379.5,143.6,379.3,143.7 M340.3,144C340.1,144.4,340.8,144,340.3,144 M438,144C438.1,144,438.1,144,438,144 M336,144.1
        c-0.1,0.2,0.2,0.5,0.4,0.5C336.6,144.3,336.2,144.1,336,144.1 M126.3,144.2C126.3,144.7,126.7,144,126.3,144.2 M296.2,145.2
        c0.2,0.1,0.4-0.4,0.4-0.6C296.4,144.7,296.2,145,296.2,145.2 M86.9,145.4C87,145.5,87,145.5,86.9,145.4 M427.8,145.4
        C427.8,145.4,427.8,145.4,427.8,145.4 M97.9,145.8C97.9,145.9,97.9,145.9,97.9,145.8 M150.3,145.8c0,0.1,0,0.3,0,0.4
        c-0.1,0.1-0.2,0.2-0.1,0.3c0.1-0.2,0.3-0.4,0.4-0.6C150.5,145.9,150.4,145.8,150.3,145.8 M416.8,145.8
        C416.9,145.9,416.9,145.9,416.8,145.8 M98.1,145.9C98.2,146,98.2,146,98.1,145.9 M336.9,146.4c-0.1,0-0.3,0-0.4-0.1
        c0.2,0.2,0.4,0.4,0.5,0.6c-0.1,0-0.3,0-0.3,0c-0.1,0.2,0,0.3,0.1,0.5c0,0.1-0.1,0.2-0.1,0.2c0,0,0.1,0.1,0.1,0.1
        c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.3c-0.1-0.1-0.2-0.2-0.4-0.3c0.1-0.1,0.3-0.2,0.5-0.3
        l-0.3-0.1c0-0.1,0-0.3,0-0.4C336.7,145.9,336.8,146.2,336.9,146.4 M416.5,145.9C416.6,146,416.6,146,416.5,145.9 M86.9,146.4
        c-0.1,0.2,0,0.3,0.2,0.2C87.2,146.4,87.1,146.3,86.9,146.4 M427.7,146.4c-0.2,0.1-0.2,0.2,0,0.3
        C427.9,146.5,427.9,146.4,427.7,146.4 M268.7,147.1c0.2,0,0.4-0.3,0.3-0.4C268.7,146.6,268.6,147,268.7,147.1 M289.4,146.6
        c-0.2,0-0.4,0.2-0.3,0.4C289.3,147.2,289.7,146.7,289.4,146.6 M342,146.6C342.1,146.7,342.1,146.7,342,146.6 M150.9,147
        c-0.1,0.2,0.2,0.3,0.3,0.2C151.3,147,151,146.9,150.9,147 M295.9,147.1c-0.1,0.2,0.2,0.6,0.4,0.6
        C296.3,147.5,296.1,147.2,295.9,147.1 M240,147.3l0,0.1C240.1,147.7,240.4,147.2,240,147.3 M293.4,148.3l-0.3,0
        c0.2,0.1,0.4,0.3,0.4,0.5c0.1-0.1,0.2-0.1,0.3-0.2l-0.3-0.1c0-0.3,0.1-0.6,0.3-0.9l-0.3,0.2c-0.1-0.2-0.2-0.3-0.3-0.5
        C292.8,147.7,293.3,148,293.4,148.3 M240.5,147.7C240.6,147.8,240.6,147.8,240.5,147.7 M87.5,148.2l0,0.1
        C87.7,148.6,87.7,147.9,87.5,148.2 M336.8,148.2C336.8,148.7,337.2,147.9,336.8,148.2 M111.9,148.8c0,0.1,0,0.2,0,0.3
        c-0.2,0.1-0.7,0.5-0.2,0.6c0,0-0.1,0.1-0.1,0.2c0.6-0.1,0.5,0.7,0.8,0.9c0.2-0.1,0.3-0.3,0.4-0.6c-0.1,0-0.2,0-0.3,0l0.1,0.3
        c-0.5-0.1,0-0.5,0.1-0.7c-0.2-0.1-0.4,0-0.5,0.2l0-0.2l0.2,0c0-0.2,0-0.3,0.1-0.5c-0.1-0.2-0.3-0.4-0.2-0.7
        C112.1,148.8,111.9,148.8,111.9,148.8 M240.5,148.8C240.5,148.9,240.5,148.9,240.5,148.8 M398.7,148.8c0,0.4-0.1,0.7-0.3,1
        c0.3,0.5,0.2,1,0,1.5c0.1,0.5,0.1,1.1-0.2,1.6c0,0.3,0,0.5,0,0.8c0.2,0.8-0.1,1.7,0.1,2.6c0,0,0.1,0.1,0.1,0.1
        c-0.3,0.6-0.1,1.2-0.1,1.8c0,0.8,0,1.6-0.1,2.4c0.1,0.6,0.1,1.3,0.1,1.9c0.1,0.2,0.4,0.2,0.5,0.4c-0.3,0.1-0.7,0.2-0.5,0.6
        c0.1,0,0.3-0.1,0.4-0.1c-0.1,0.1-0.3,0.2-0.4,0.4c0.1,0.5,0.2,1,0.2,1.5c-0.2,0.5,0,1,0.3,1.3c-0.6-0.7-0.4,0.7,0,0.1
        c-0.6,0.3-0.3,1-0.4,1.5l0.3,0l-0.1-0.2l0.1,0c0.1,0.2,0.1,0.4,0.2,0.6c-0.2-0.1-0.3-0.2-0.5-0.3c0.1,0.2-0.1,0.8,0.4,0.6l0,0.2
        c-0.1,0-0.3,0-0.4,0c0,0.5,0.2,0.9-0.1,1.3c0.3,0.1,0.6,0,0.6-0.3l-0.4,0c0.1-0.3,0.4-0.2,0.7-0.3c-0.1-0.1-0.3-0.2-0.4-0.3
        c0.2-0.2,0.3-0.5,0.5-0.7c-0.1,0-0.3,0-0.3,0c0.1-0.1,0.2-0.4,0.2-0.5c-0.2-0.4-0.4-0.9-0.7-1.3c0.1,0,0.4,0,0.5,0
        c0.1,0.2,0.3,0.4,0.5,0.6l-0.2,0.1c0.1,0,0.3,0,0.4,0.1c0-0.1-0.1-0.3-0.2-0.5c0.1-0.1,0.2-0.2,0.3-0.2c-0.3,0.3-0.5-0.1-0.8-0.2
        c0.1-0.1,0.2-0.3,0.3-0.4c-0.1,0-0.2,0-0.3,0.1c-0.2-0.2,0-0.5,0.3-0.4c0,0,0.1,0.1,0.2,0.2c0-0.1,0-0.2,0-0.3
        c-0.1,0-0.3,0.1-0.4,0.1c-0.1-0.3-0.3-0.6-0.4-1c0.1,0.1,0.3,0.2,0.4,0.3c0.4-0.2,0-0.5-0.1-0.7c-0.1,0.1-0.2,0.3-0.3,0.3
        c0-0.2,0-0.4,0.1-0.6c0.1,0,0.2,0,0.3,0.2c0.2,0.1,0.5,0.2,0.7,0.4c-0.1,0.3-0.3,0.5-0.5,0.7c0.2,0,0.3,0,0.5-0.1
        c0-0.2,0.1-0.4,0.1-0.6c0.1,0,0.2-0.1,0.3-0.2c0,0-0.1-0.1-0.1-0.1c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1-0.4-0.5-0.4-0.6-0.8
        c-0.3,0-0.4-0.3-0.5-0.5c0.1,0.1,0.4,0.3,0.5,0.4c0-0.3-0.2-0.5-0.4-0.7c0.2-0.1,0.4-0.1,0.7-0.1c0-0.1,0-0.2,0-0.2
        c-0.2,0.2-0.3,0.2-0.5,0.1c0.1-0.2,0.2-0.4,0.3-0.6c0.1,0,0.2,0,0.3,0c-0.1-0.1-0.2-0.2-0.2-0.2c0-0.4-0.1,0.2-0.2,0.3
        c-0.4-0.2-0.2-0.7-0.1-1c-0.1,0.1-0.3,0.2-0.4,0.2c0.2-0.2,0.4-0.3,0.6-0.5c-0.2,0-0.4-0.1-0.4-0.3c0.3,0.1,0.4-0.3,0.5-0.5
        c0-0.4-0.2-0.8-0.1-1.1c-0.4,0.5-0.7-0.1-0.4-0.4c0,0.1,0.1,0.3,0.1,0.4l0.3-0.2c-0.2-0.2-0.1-0.4,0.1-0.6c-0.4,0.2-0.6-0.2-0.7-0.5
        c0.1,0,0.2,0,0.3,0l0,0.2c0.1,0,0.3,0,0.4,0L399,159l0.4,0c-0.3-0.2-0.4-0.5-0.6-0.9c0.3,0,0.5,0.3,0.8,0.3
        c-0.2-0.3-0.6-0.5-0.7-0.8c-0.2,0.2-0.2,0.5-0.5,0.5c0.1-0.1,0.2-0.3,0.2-0.4l-0.3,0c0,0,0.1-0.1,0.2-0.1l0.3,0.2
        c-0.1-0.3-0.2-0.5-0.1-0.8c0-0.2-0.3-0.5,0.1-0.6c-0.1-0.2-0.2-0.3-0.5-0.3c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0.1,0.1,0.3,0.2,0.4
        c0.3-0.5,0.5-1,0.8-1.5c0-0.2-0.1-0.5-0.1-0.7c0.1,0,0.2,0.1,0.3,0.1c0-0.1,0-0.3,0-0.4c0.1,0,0.3,0.1,0.4,0.2
        c-0.1,0-0.2-0.1-0.2-0.2c0-0.3,0.1-0.5,0.1-0.8c-0.1-0.2-0.1-0.3-0.1-0.5c0.1,0.1,0.2,0.2,0.3,0.3c0.1-0.1,0.1-0.3,0.2-0.4l-0.3,0.1
        c0-0.1,0-0.3,0-0.5l0.1,0c-0.1-0.2,0-0.4,0.1-0.5c0-0.1-0.1-0.3-0.2-0.3c0.1,0,0.4,0.1,0.5,0.1c-0.1-0.1-0.2-0.2-0.3-0.3
        c0.1-0.2,0.2-0.3,0.3-0.4l-0.2,0c0-0.3-0.3-0.7-0.6-0.6c-0.4,0.2-0.7-0.2-1-0.3C399,149.4,399.1,148.7,398.7,148.8 M288.7,149.6
        c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0.1,0.1,0.2,0.2,0.3c0.1-0.1,0.2-0.2,0.2-0.3c-0.2-0.1-0.5-0.1-0.4-0.4
        C288.8,149.1,288.8,149.4,288.7,149.6 M137,149.2C137,149.7,137.4,149,137,149.2 M377.6,149.2C377.5,149.7,378,149,377.6,149.2
        M137.5,149.7c0,0.1-0.1,0.3-0.2,0.4c0.3-0.1,0.7-0.8,0.2-0.8C137.3,149.3,137.5,149.5,137.5,149.7 M342.7,149.4
        C342.8,149.4,342.8,149.4,342.7,149.4 M288.6,150.5c0.2,0.2,0.3-0.1,0.5-0.2c-0.2-0.1-0.4-0.2-0.6-0.3
        C288.5,150.2,288.4,150.5,288.6,150.5 M337.2,150.3c-0.1,0.2,0.2,0.5,0.4,0.3C337.6,150.5,337.4,150.2,337.2,150.3 M137.4,150.5
        c0,0.2,0,0.3,0.2,0.2C137.7,150.5,137.6,150.4,137.4,150.5 M377.1,150.5c-0.1,0.2,0,0.3,0.2,0.2
        C377.4,150.5,377.3,150.4,377.1,150.5 M337.1,150.8C336.9,151.2,337.6,150.8,337.1,150.8 M91.8,150.9C91.9,151,91.9,151,91.8,150.9
        M422.9,151C423,151,423,151,422.9,151 M288.6,151.5c0-0.1,0-0.3,0-0.3C288.4,151,288.4,151.7,288.6,151.5 M153.6,151.4
        c0,0.2,0.1,0.3,0.2,0.3C153.9,151.4,153.8,151.3,153.6,151.4 M293.1,151.3c0.1,0.1,0.3,0.4,0.4,0.5c-0.1,0-0.4,0-0.5,0
        c0.1,0.3-0.1,0.4-0.3,0.5c0.1,0.1,0.2,0.3,0.3,0.4c0-0.2,0-0.4,0.1-0.6c0.3-0.1,0.6-0.3,1-0.3C293.7,151.7,293.4,151.4,293.1,151.3
        M153.6,151.9c0.1,0,0.2-0.1,0.3-0.1C153.8,151.6,153.3,151.7,153.6,151.9 M156.6,151.8c0,0.1-0.1,0.2-0.1,0.2
        C156.6,152.3,156.8,151.8,156.6,151.8 M62.7,152.2C62.8,152.3,62.8,152.3,62.7,152.2 M337.3,152.2
        C337.4,152.3,337.4,152.3,337.3,152.2 M451.9,152.2C452,152.3,452,152.3,451.9,152.2 M114.3,152.3
        C114.1,152.8,114.8,152.4,114.3,152.3 M293.4,152.1l-0.2,0.3c0,0.1,0,0.3,0.1,0.3c-0.1,0-0.3,0-0.3,0.1c0,0.1,0,0.3-0.1,0.3
        c0.2-0.1,0.3-0.1,0.5-0.2c0.1,0,0.3,0,0.5,0l0.1-0.3c-0.1,0-0.3,0-0.5,0C293.4,152.5,293.4,152.2,293.4,152.1 M110.3,152.7
        c-0.2,0-0.3,0.4,0,0.4C110.6,153.1,110.6,152.8,110.3,152.7 M295.7,152.7C295.5,153.2,296.2,152.8,295.7,152.7 M375.9,153.6l-0.2,0
        c0,0.1,0,0.2,0,0.3c-0.4-0.1-0.2,0.2-0.1,0.3c0.1-0.4,0.5-0.7,0.5-1.1C375.8,153.1,375.9,153.4,375.9,153.6 M154,153.6
        c-0.1,0.1-0.1,0.4,0.1,0.4C154.2,154,154.3,153.6,154,153.6 M341.9,153.6C341.9,154.1,342.3,153.4,341.9,153.6 M257.6,153.9
        l-0.1,0.1C257.6,154.4,257.9,153.9,257.6,153.9 M271.4,153.8C271.4,153.9,271.4,153.9,271.4,153.8 M292.7,153.9
        C292.7,154.3,293.1,153.6,292.7,153.9 M139.1,154.3C139.2,154.4,139.2,154.4,139.1,154.3 M375.6,154.3
        C375.7,154.4,375.7,154.4,375.6,154.3 M389.2,154.5C389.3,154.5,389.3,154.5,389.2,154.5 M125.6,154.7c-0.2,0.1-0.2,0.2,0,0.3
        C125.7,154.9,125.7,154.8,125.6,154.7 M194.1,154.9c0.1,0.2,0.2,0.2,0.3,0C194.3,154.7,194.2,154.7,194.1,154.9 M389.1,154.8
        c0,0.2,0,0.3,0.2,0.2C389.3,154.8,389.3,154.7,389.1,154.8 M110.3,155.2c-0.1,0.1-0.1,0.2-0.2,0.4c0.1,0,0.3,0.1,0.4,0.2
        c0.1,0.1,0.1,0.2,0.2,0.4c0-0.1,0-0.3,0-0.4c0.1,0,0.2,0.1,0.3,0.1c0-0.1,0-0.3,0-0.4c-0.1,0.1-0.2,0.2-0.3,0.3
        C110.6,155.3,110.5,155.2,110.3,155.2 M194.2,155.9C194.1,156.3,194.7,155.9,194.2,155.9 M247.9,157.1l0,0.2c-0.2,0-0.4,0.1-0.6,0.2
        c-0.1,0.2-0.3,0.3-0.4,0.4c-0.3,0-0.6,0-0.8,0.4c0.1,0,0.2,0,0.3,0c-0.4,0.2-0.7,0.4-1.1,0.7c0,0,0.1,0.1,0.2,0.2
        c-0.2,0-0.4-0.1-0.6-0.1c0,0.1,0,0.2,0,0.3c-0.3,0.3-0.6,0.6-0.9,0.9c0.1-0.3-0.1-0.4-0.4-0.3c0.1,0.1,0.2,0.2,0.3,0.3
        c-0.1,0-0.3,0.1-0.4,0.1c0.1,0.1,0.2,0.2,0.2,0.2c-0.2,0.1-0.4,0.1-0.6,0.2c0,0.1,0,0.2,0,0.3c-0.3,0.3-0.8,0.4-1,0.8
        c0,0.1,0.1,0.2,0.2,0.3c-0.3-0.4-0.5,0.2-0.7,0.4c-0.1,0.2-0.1,0.3-0.2,0.4c-0.5,0.4-0.7,1.1-1.3,1.3c0,0.1,0,0.3,0,0.4
        c-0.1,0-0.2-0.1-0.3-0.2c-0.1,0.3-0.2,0.6-0.5,0.7c0,0.1,0.1,0.3,0.1,0.4l-0.2,0c0,0.1,0,0.2,0,0.3c0.2-0.3,0.2-0.6,0.5-0.8
        c-0.2,0.3,0,0.8-0.3,1.1c0.1,0.1,0.2,0.2,0.2,0.3l-0.2,0.2c0-0.1-0.1-0.3-0.2-0.4c-0.2,0.2,0,0.6-0.3,0.7c0.2,0.1,0.3-0.1,0.5-0.2
        c0.2,0,0.4,0,0.6,0c0.1-0.1,0.4-0.3,0.5-0.3l-0.1,0.3c0,0,0.1,0,0.1,0l0-0.1l0.6-0.1c-0.1,0.1-0.2,0.3-0.3,0.4l0,0.2
        c-0.3,0.1-0.9,0.2-0.8,0.6c-0.3,0-0.5,0.2-0.6,0.5c0.2-0.2,0.4-0.3,0.6-0.4c-0.1,0.2-0.1,0.5-0.1,0.7c-0.3-0.1-0.4,0.1-0.5,0.3
        l0.1,0c0.1-0.2,0.3-0.2,0.5-0.2c-0.2,0.3-0.4,0.6-0.5,0.9c0.1-0.1,0.3-0.2,0.4-0.3c-0.1,0.4-0.1,0.9,0,1.3c0.1,0,0.3,0,0.4,0l0,0.1
        c-0.1,0-0.2,0-0.2,0c0,0.1,0,0.2,0,0.3c0.1,0,0.2,0,0.3-0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0,0.4,0,0.6,0.1c0.1,0,0.2-0.1,0.3-0.1
        c-0.2,0.2-0.5,0.2-0.6,0.5c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2,0.1-0.4,0.3-0.3,0.5c-0.1,0-0.2,0.1-0.3,0.1c0,0.1,0.1,0.3,0.1,0.4
        c-0.1,0-0.3,0-0.3,0c0.1,0.2,0.1,0.4-0.1,0.5c0,0.3-0.1,0.5-0.3,0.7c0.3,0.1-0.1,0.3,0,0.5c0.1,0,0.2,0.1,0.2,0.2
        c0,0.4,0.7,0.1,0.5,0.6l0.2-0.1c0.1,0.1,0.3,0.2,0.4,0.4c-0.2,0-0.5,0.1-0.7,0.1c-0.2,0.2-0.4,0.1-0.6,0.1c-0.1,0-0.3,0.1-0.4,0.1
        c0,0.1,0.2,0.2,0.2,0.3c-0.2-0.1-0.4-0.3-0.4,0.1c0.1,0,0.2,0.1,0.3,0.2c0.3-0.2,0.6-0.5,1-0.5c-0.2,0.3-0.6,0.5-0.7,0.9l-0.1,0v0.1
        c-0.2,0.1-0.2,0.3-0.1,0.5c-0.1,0-0.2-0.1-0.3-0.1c0,0.1,0,0.2,0,0.3l0.2-0.1c-0.1,0.2-0.2,0.5-0.3,0.7c0.1-0.1,0.3-0.2,0.4-0.3
        c-0.1,0.2-0.2,0.5-0.4,0.6c0.2,0,0.3,0.3,0.4,0.5c-0.1-0.1-0.3-0.2-0.4-0.2c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0,0.2,0.1,0.3,0.2
        c-0.1,0-0.3,0.1-0.3,0.1c0.2,0.2,0.6,0.2,0.4,0.6c0.3-0.2,0.6-0.1,0.9-0.1c-0.2,0-0.3,0.1-0.5,0.2l0,0.1c-0.3,0.1-0.5,0.4-0.8,0.6
        c-0.2,0.3-0.3,0.7-0.2,1.1l-0.1,0c0,0.2,0,0.4,0,0.6c-0.3,0-0.1,0.4-0.1,0.5c0,0.3,0,0.6,0.1,0.8c0.3,0.2-0.5,0.6,0.2,0.3
        c-0.1,0.1-0.2,0.3-0.3,0.5l0.3-0.1c0,0.1-0.1,0.3-0.2,0.4c0.1,0,0.3-0.1,0.4-0.1c0,0.1-0.1,0.3-0.1,0.4c-0.2-0.1-0.3-0.1-0.5-0.2
        c0.1,0.2,0.3,0.3,0.4,0.4c-0.1,0.1-0.2,0.2-0.3,0.4c0.1,0,0.3,0.1,0.4,0.1c0.1,0.1,0.1,0.2,0.2,0.3c-0.1,0.4,0.3,0.6,0.5,0.8
        c0.1-0.1,0.2-0.2,0.3-0.3c-0.1,0.1-0.2,0.3-0.2,0.4c0.1,0,0.3-0.1,0.4-0.1l0,0.2l-0.2,0l0,0.1c0.3,0,0.5,0,0.8,0l0,0.3
        c-0.2-0.1-0.4-0.1-0.6-0.2c0.2,0.2,0.4,0.3,0.6,0.2c0.3-0.1,0.9,0,0.7-0.5c0.1,0,0.3,0,0.4,0l0,0.1c-0.5-0.2-0.1,0.6,0.1,0.2
        c0.2-0.2,0.5-0.1,0.7-0.1c0-0.3,0.3-0.4,0.6-0.6c0.3-0.1,0.6-0.3,0.8-0.6c0.5,0,0.5-0.6,0.9-0.7c0-0.4,0.5-0.4,0.5-0.8
        c0.3-0.4,0.1-1,0.2-1.5c-0.5-0.1-0.5-0.6-0.7-1c-0.2-0.5-0.8-0.6-0.9-1.1c-0.5-0.4-0.7-1-1.2-1.5c-0.2-0.5-0.4-0.9-0.4-1.4
        c-0.1-0.6,0.2-1.2,0.2-1.7c0.2-0.6,0.4-1.4,0.9-1.9c0.2-0.6,0.8-1.1,1.2-1.6c-0.3,0.4-0.6,0.9-1,1.3c0,0.1,0,0.2-0.1,0.3
        c-0.1,0.1-0.2,0.3-0.3,0.4c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.3-0.2,0.6-0.3,0.9c0,0.1,0,0.2,0,0.2c-0.1,0.2-0.1,0.5-0.1,0.7
        c-0.1,0.3,0.3,0.6,0,0.9c0,0.2,0,0.4,0,0.7c0,0,0.1,0.1,0.1,0.2c0.4-0.2-0.1,0.7,0.5,0.5c0,0.1-0.1,0.2-0.1,0.3l0.2-0.1l0,0.1
        l0.1,0.1c-0.1,0.4,0.2,0.3,0.4,0.3c0,0.3,0,0.6,0.3,0.7c-0.2-0.2,0.1-0.6,0.3-0.2c0,0.1,0,0.3-0.1,0.5c0.1-0.1,0.3-0.4,0.4-0.2
        c0,0.1-0.1,0.2-0.1,0.3c0.2,0,0.4-0.1,0.5,0.2c0,0,0.1,0,0.2,0c0,0.1-0.1,0.2-0.2,0.2c0.1,0,0.3,0.1,0.5,0.1
        c0.1,0.2,0.1,0.6,0.4,0.6c0-0.2,0-0.3,0-0.5c0.4,0.1,0.3,0.5,0.2,0.8c0.1,0.3,0,0.6,0.3,0.7c0.2,0.1,0,0.3-0.1,0.3
        c0,0,0.1,0.1,0.1,0.1c0.1-0.1,0.2-0.2,0.3-0.4c0,0.2,0.7,0.5,0.3,0.6c-0.1-0.1-0.2-0.2-0.3-0.3c0.1,0.4,0.4,0.4,0.7,0.6
        c-0.1,0.1-0.4,0.2-0.5,0.3c0.3,0.1,0.6,0.3,0.8,0.4c0-0.1-0.1-0.3-0.1-0.5c0.1-0.1,0.3-0.2,0.4-0.2c-0.1,0-0.2-0.1-0.3-0.1l-0.1,0.2
        c-0.1-0.2-0.1-0.3,0.1-0.4c0.4-0.1,0.9-0.2,0.9-0.7c0.2-0.1,0.5-0.1,0.4-0.4c-0.2,0-0.5,0.1-0.7,0.2c0.3-0.2,0.5-0.4,0.8-0.6
        c0-0.2,0.1-0.5,0.2-0.7c-0.1,0-0.3,0-0.4,0c0.1-0.1,0.1-0.1,0.2-0.2c0.2,0.4,0.5-0.1,0.7-0.2c-0.1-0.2-0.2-0.3-0.3-0.4
        c0.2-0.1,0.3-0.3,0.5-0.4c-0.1-0.2-0.1-0.3-0.1-0.5c0.2,0,0.2,0.2,0.4,0.3c0.1-0.2,0.3-0.4,0.4-0.5c-0.2-0.2-0.4,0-0.6,0
        c0.2-0.3,0.6-0.5,0.8-0.8c0-0.1,0-0.2,0-0.3c0.1,0,0.3-0.1,0.4-0.2c-0.1-0.1-0.2-0.2-0.3-0.2c0.2-0.2,0.3-0.3,0.3-0.6
        c0.1,0,0.2,0,0.2-0.1l-0.1-0.2c0.1,0,0.3,0,0.4-0.1c-0.1,0-0.3,0-0.4,0c0.1-0.3,0.3-0.5,0.4-0.8c0.2-0.2,0.3-0.4,0.5-0.6l-0.3,0
        c0-0.5,0.5-0.6,0.7-0.9c-0.1,0-0.4,0-0.5,0c0.1-0.1,0.1-0.3,0.2-0.4c-0.2-0.1-0.3-0.3-0.2-0.5c-0.3-0.8-1-1.3-1.1-2.1
        c-0.1,0-0.2,0.1-0.2,0.1c0.1,0.2,0.2,0.3,0.3,0.5c-0.2-0.2-0.4-0.3-0.5-0.6l-0.1-0.1c0,0,0-0.1,0-0.1c0.1,0,0.2-0.1,0.2-0.1
        c0-0.4-0.4-0.8-0.3-1.3c-0.3-0.4-0.5-0.8-0.7-1.2c-0.1-0.2,0-0.4,0.1-0.5c-0.1-0.2-0.3-0.3-0.3-0.5c-0.1-0.5-0.3-1-0.1-1.5
        c-0.2-0.1-0.3-0.4-0.3-0.6c-0.1,0.1-0.2,0.1-0.3,0.2c0.2-0.2,0.2-0.5,0.1-0.8c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.2,0-0.4,0.1-0.7
        c-0.3-0.4-0.4-0.9-0.4-1.4c-0.2-0.2-0.2-0.5-0.1-0.8c-0.1-0.1-0.2-0.3-0.3-0.4c0.1-0.2,0.1-0.3,0.2-0.5c-0.4-0.4-0.3-0.9-0.1-1.3
        c0-0.1-0.1-0.2-0.2-0.3c0.1-0.5,0.1-1,0.1-1.4c0-0.4-0.3-0.7-0.3-1c-0.1,0-0.3,0-0.3,0c0.1-0.1,0.2-0.1,0.3-0.2
        C248,157.8,248.4,157.1,247.9,157.1 M59.5,157.7c0.2,0.1,0.5-0.1,0.4-0.3C59.7,157.2,59.3,157.5,59.5,157.7 M454.9,157.3
        c-0.1,0.2,0.2,0.5,0.4,0.3C455.4,157.5,455.1,157.2,454.9,157.3 M434.7,158C434.8,158.5,435.2,157.8,434.7,158 M111,158.3
        C111.1,158.4,111.1,158.4,111,158.3 M112.2,158.8c-0.2,0.1,0,0.6,0.2,0.4C112.6,159,112.4,158.6,112.2,158.8 M308.6,158.9
        C308.7,159,308.7,159,308.6,158.9 M433.3,158.9c-0.2,0.1-0.2,0.2,0,0.3C433.5,159.1,433.5,159,433.3,158.9 M81.8,159
        c-0.1,0.2,0,0.3,0.2,0.2C82.2,159,82.1,158.9,81.8,159 M111.9,159.1c0.1,0.3,0,0.6-0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.4
        c0-0.3-0.1-0.6-0.1-1C112.1,159.3,112,159.2,111.9,159.1 M141.6,159.4c-0.2,0.3,0.5,0.3,0.4,0C141.9,159.4,141.7,159.4,141.6,159.4
        M53,159.6c-0.1,0.2-0.1,0.3,0.1,0.3C53.3,159.7,53.2,159.6,53,159.6 M461.5,159.9c0.2,0.1,0.4-0.1,0.3-0.3
        C461.6,159.4,461.3,159.7,461.5,159.9 M83.1,159.7C83.1,160.2,83.5,159.5,83.1,159.7 M134.7,159.7
        C134.8,159.8,134.8,159.8,134.7,159.7 M284.9,159.9c-0.1,0.2,0.4,0.6,0.5,0.3C285.3,160,285.1,159.8,284.9,159.9 M66.5,160.6
        c-0.1,0.2,0,0.3,0.2,0.2C66.8,160.6,66.7,160.5,66.5,160.6 M448,160.6c-0.1,0.2,0,0.3,0.2,0.2C448.3,160.6,448.3,160.5,448,160.6
        M134.7,160.9C134.8,160.9,134.8,160.9,134.7,160.9 M337.4,161.8c0.2,0,0.3-0.1,0.3-0.3C337.5,161.5,337.4,161.6,337.4,161.8
        M337,161.7C336.8,162.1,337.5,161.8,337,161.7 M342.6,162.1c0-0.1,0.1-0.3,0.1-0.4C342.4,161.7,342.3,162,342.6,162.1 M85.8,162.2
        c-0.1,0.2,0,0.3,0.2,0.2C86,162.2,86,162.1,85.8,162.2 M143.1,162.2C143.2,162.3,143.2,162.3,143.1,162.2 M143.1,162.4l0,0.1
        C143.4,162.8,143.4,162.2,143.1,162.4 M428.1,162.6C427.9,163,428.6,162.6,428.1,162.6 M342.3,162.7
        C342.4,162.8,342.4,162.8,342.3,162.7 M143.1,163.2c0.2,0.1,0.4-0.4,0.1-0.4C143,162.8,143,163,143.1,163.2 M214.8,163
        C214.8,163.1,214.8,163.1,214.8,163 M143.6,163.7l0,0.1c0.2,0.1,0.4-0.2,0.2-0.3C143.8,163.5,143.7,163.6,143.6,163.7 M115.2,164
        C115,164.5,115.7,164,115.2,164 M342.3,164.3l-0.1,0.1c0,0.5,0.6-0.6,0.1-0.5C342.1,164,342.3,164.2,342.3,164.3 M399.5,164
        c-0.2,0.1-0.2,0.2,0,0.3C399.6,164.2,399.6,164.1,399.5,164 M296.3,164.2c0,0.2,0.2,0.4,0.3,0.5c0.1,0,0.3-0.1,0.4-0.2
        C296.7,164.4,296.5,164.3,296.3,164.2 M402.7,164.3C402.8,164.4,402.8,164.4,402.7,164.3 M115.5,164.4
        C115.6,164.5,115.6,164.5,115.5,164.4 M399.2,164.4C399.3,164.5,399.3,164.5,399.2,164.4 M370.7,164.8c0.1,0.2,0.2,0.2,0.3,0
        C370.9,164.6,370.8,164.6,370.7,164.8 M443.5,164.7C443.6,164.7,443.6,164.7,443.5,164.7 M296.1,165.4c0.2,0.1,0.4-0.3,0.3-0.4
        C296.2,165,296,165.2,296.1,165.4 M371.2,165.1C371.2,165.6,371.6,164.9,371.2,165.1 M342,165.3c-0.1,0.2,0,0.3,0.2,0.2
        C342.3,165.3,342.2,165.2,342,165.3 M134.7,165.4C134.8,165.5,134.8,165.5,134.7,165.4 M72.7,165.6L72.7,165.6L72.7,165.6
        L72.7,165.6L72.7,165.6 M341.7,166c0.1,0,0.1,0.1,0.2,0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4-0.1,0.4-0.3
        C342.1,165.8,341.9,165.9,341.7,166 M466.2,165.5c0.3,0.3,0.5,0.6,0.6,1l0.4,0c-0.1-0.1-0.2-0.4-0.2-0.5l0.2,0l0,0.3
        c0,0,0.1-0.1,0.2-0.1C467.2,165.7,466.6,165.7,466.2,165.5 M336.8,165.9C336.8,166.3,337.2,165.6,336.8,165.9 M125.6,166
        C125.7,166.1,125.7,166.1,125.6,166 M389.1,166C389.1,166.1,389.1,166.1,389.1,166 M144.4,166.1c0,0.2,0,0.3,0.2,0.2
        C144.7,166.1,144.6,166,144.4,166.1 M370.1,166.1c-0.1,0.2,0,0.3,0.2,0.2C370.4,166.1,370.3,166,370.1,166.1 M369.9,166.3
        c0,0.2-0.1,0.4-0.2,0.6c0.1,0,0.3-0.1,0.5-0.2l-0.1,0.3c0.2-0.1,0.4-0.2,0.5-0.4C370.2,166.9,370,166.5,369.9,166.3 M89.3,166.7
        C89.4,167.2,89.8,166.5,89.3,166.7 M425.2,166.7C425.2,167.2,425.6,166.5,425.2,166.7 M115.6,168.5c0.1,0.2,0.1,0.3,0.2,0.5
        c-0.1,0-0.3,0-0.3,0c0.1,0.2,0.3,0.3,0.3,0.5c0.1,0,0.2,0.1,0.3,0.2c-0.2,0.1-0.5,0.2-0.4,0.6c-0.2,0-0.4-0.1-0.6-0.3
        c0,0.2,0.1,0.3,0.2,0.5c0.1-0.1,0.3-0.1,0.4-0.1c0,0.3-0.3,0.5-0.4,0.7c0.1,0.1,0.2,0.2,0.3,0.3l0-0.2l0.2,0c-0.1,0-0.2-0.1-0.3-0.1
        l0-0.1c0.4,0.2,0.7-0.4,0.4-0.6c0.1-0.2,0.2-0.4,0.4-0.4c0,0.2,0,0.3,0,0.5l-0.2-0.1c0.1,0.2,0.1,0.4,0.2,0.6c-0.1,0-0.2,0-0.3,0
        c0.1,0.1,0.2,0.3,0.3,0.4c-0.2-0.1-0.3-0.2-0.5-0.4c-0.1,0.2-0.2,0.5-0.3,0.6c0.2-0.1,0.5-0.1,0.7,0c-0.1,0-0.2,0.1-0.3,0.2
        c-0.3-0.3-0.4,0.3-0.5,0.4c0.2-0.1,0.3-0.1,0.5-0.2c-0.1,0.2,0,0.4,0.2,0.5c-0.2-0.1-0.4-0.2-0.7-0.3c0.1,0.3,0.2,0.7-0.1,1
        c0.1,0.1,0.3,0.3,0.5,0.2c0-0.3-0.1-0.5-0.1-0.8c0.3,0.2,0.1,0.6,0.2,0.9c-0.2,0-0.3,0-0.5,0c0,0.2,0,0.4,0,0.6
        c0.3-0.1,0.5-0.2,0.8-0.4c-0.2,0.2-0.7,0.4-0.5,0.8c-0.2,0.2-0.5,0.1-0.7-0.1c0.2-0.2,0.2-0.5,0.3-0.7c-0.1,0-0.3,0-0.4,0
        c0.2,0,0.3,0.2,0.4,0.4c-0.5,0.1-0.1,0.6,0,0.8c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0.2,0,0.4-0.1,0.6l0.2,0l0-0.2c0.1,0,0.2,0,0.2,0
        c-0.2,0.1-0.3,0.4-0.6,0.3c0.1,0.1,0.3,0.2,0.4,0.3c0,0.1-0.1,0.4-0.2,0.5c-0.2-0.2-0.3-0.4-0.6-0.4c0.1,0.2,0.3,0.4,0.4,0.6
        c-0.2-0.1-0.7,0.3-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5c0.2,0,0.3-0.1,0.4-0.3c-0.1-0.1-0.1-0.2-0.2-0.3c0.2-0.4,0.6-0.4,0.9-0.5
        c0,0.3-0.3,0.2-0.5,0.3c0.2,0.2-0.2,0.6,0.1,0.8c0.2,0,0.3,0.1,0.4,0.3c-0.1-0.1-0.3-0.1-0.4-0.1c0,0.1-0.1,0.3-0.1,0.4
        c-0.1,0.2-0.1,0.5-0.1,0.7c-0.2-0.1-0.3-0.2-0.4-0.4c0.1-0.1,0.2-0.2,0.3-0.4c-0.1,0-0.3,0-0.4,0c0,0.2,0,0.3-0.1,0.5
        c0.1,0.2,0.3,0.3,0.4,0.5c-0.1,0.2-0.2,0.3-0.2,0.5c-0.2,0.1-0.3,0.2-0.4,0.3c0.2,0,0.4,0,0.5-0.2c0.1,0,0.3-0.1,0.4-0.2
        c0,0.1,0,0.4,0,0.6c-0.1,0-0.2-0.1-0.3-0.1l0,0.2c-0.1,0-0.2,0-0.2,0c-0.2,0.3-0.1,0.6,0,0.9c0,0-0.1,0.1-0.1,0.2
        c0,0.1,0.1,0.3,0.2,0.4c-0.2-0.1-0.4-0.3-0.7-0.5c0,0.1,0,0.2,0,0.4c0.1,0,0.2-0.1,0.2-0.1l0.2,0.1c-0.1,0-0.2,0.1-0.3,0.2
        c0.1,0,0.3,0.1,0.5,0.2c0.2-0.2,0.5-0.5,0.6-0.8c0,0.1,0,0.3,0,0.4l-0.2,0c0.3,0.4-0.2,0.6-0.4,0.3c-0.1,0.1-0.4,0.2-0.4,0.5
        c0.2,0,0.3-0.1,0.4-0.3c0.3,0.3-0.2,0.5-0.2,0.7c0.2-0.1,0.4-0.1,0.6-0.2c-0.1,0.2-0.4,0.3-0.6,0.4c-0.2-0.2-0.3-0.4-0.1-0.6
        c-0.3,0-0.6,0.3-0.5,0.6c0,0-0.1,0.1-0.2,0.1c0.1,0,0.3,0.1,0.4,0.1c-0.1-0.1-0.1-0.3-0.2-0.4c0.2-0.2,0.4,0.3,0.5,0.4
        c-0.1,0.1-0.3,0.2-0.4,0.3c0.1,0.1,0.4,0.2,0.5,0.2c0.2-0.1,0.5-0.6,0.5-0.2c-0.2,0.1-0.3,0.1-0.5,0.2l0,0.1
        c-0.1-0.1-0.4-0.2-0.5-0.3c0,0.2,0.1,0.5,0.1,0.7l0.1,0.1c0.4,0.1-0.1,0.3-0.2,0.4c-0.1,0-0.3-0.1-0.4-0.1l0.1-0.3
        c0.1,0,0.3,0.1,0.3,0.1c0,0-0.1-0.1-0.1-0.2c-0.1,0-0.3,0-0.3,0c0,0.1,0,0.3,0,0.4c0.4,0.2,0.6,0.6,1,0.8c-0.3,0.1-0.5,0.2-0.6,0.5
        c0.1,0,0.3,0,0.4,0c-0.1,0.1-0.2,0.3-0.4,0.4l0.1-0.3c-0.2,0-0.4-0.1-0.5-0.3c0.3,0.1,0.7-0.3,0.3-0.4l0.3-0.1
        c-0.2-0.1-0.3-0.1-0.5-0.1l-0.1-0.3c-0.3,0.3,0.3,0.6-0.1,0.9c0,0-0.1,0.1-0.1,0.2c0.2,0.2,0.4,0.4,0.7,0.6c0,0.1,0.2,0.3,0.1,0.5
        c-0.3,0.3-0.1-0.3-0.1-0.4c-0.1,0-0.4,0.1-0.5,0.1c-0.2,0.4-0.3,0.8-0.4,1.2c-0.2,0-0.4,0.1-0.5,0.3c0.1,0.2,0.2,0.5,0.3,0.6
        l-0.4-0.2c0.3,0.1,0.5,0.5,0.1,0.7c-0.1,0.2-0.1,0.5,0.1,0.7c-0.4-0.2-0.4,0-0.3,0.3c0.1,0,0.2-0.1,0.4-0.1c-0.2-0.3,0.6-0.6,0-0.8
        c0.2-0.1,0.5-0.2,0.4-0.5c0.1,0.2,0.2,0.4,0,0.6c0,0,0.1,0.1,0.2,0.2l-0.3,0c0,0.1,0,0.2,0.1,0.3l-0.1,0c0,0.3-0.1,0.4-0.4,0.5
        c0,0.2-0.1,0.4-0.2,0.5c0.1,0.3,0,0.5-0.1,0.8c0.2-0.1,0.4-0.3,0.6-0.4c-0.1,0.2-0.1,0.5-0.4,0.4c0,0.1,0,0.3,0.1,0.3
        c-0.1-0.3-0.4-0.6-0.6-0.8c0,0.1-0.1,0.2-0.2,0.2c0.1,0,0.2,0,0.3,0c0,0.3,0.4,0.6,0.3,0.9c-0.2-0.3-0.4-0.5-0.7-0.6
        c0.1,0.3,0.4,0.6,0.6,0.8l-0.3,0c0,0.1,0,0.2,0,0.3c-0.2,0-0.4,0.1-0.6,0.1c-0.1,0.2-0.3,0.4-0.4,0.6l0.1,0c0.2-0.3,0.4-0.2,0.6,0.1
        c-0.1,0-0.3,0-0.5,0c0,0.1,0.1,0.2,0.2,0.2c0,0.1,0.1,0.3,0.1,0.5c-0.2,0.1-0.5,0.2-0.7,0.3c0,0.1,0.1,0.4,0.1,0.5
        c-0.1,0-0.3,0.1-0.4,0.1l0.1-0.3c-0.1,0.2-0.3,0.3-0.4,0.5l0.2,0l-0.1-0.1c0.2,0,0.4,0,0.6,0l-0.1,0.2l-0.2,0l0,0.4
        c-0.1-0.1-0.2-0.2-0.3-0.3c0.1,0.3,0,0.6,0.2,0.8c0,0.2,0.1,0.4,0.1,0.6c0,0.1-0.1,0.2-0.2,0.2c0-0.1-0.1-0.2-0.1-0.4
        c-0.1,0-0.2,0-0.2,0c0.2-0.2,0.1-0.5-0.1-0.6c0,0.2,0,0.4-0.2,0.5c0.1,0.1,0.3,0.3,0.2,0.5c-0.2,0.1-0.3,0.1-0.5,0.1
        c0,0,0,0.1-0.1,0.2c0,0-0.1,0.2-0.1,0.2c0.1,0,0.2-0.1,0.3-0.2c0.2-0.1,0.4-0.1,0.6-0.3c0,0.1,0,0.3-0.1,0.4c-0.1,0-0.2-0.1-0.3-0.1
        c0,0.3-0.3,0.3-0.5,0.4c0,0.1,0,0.4,0,0.5c0.1,0,0.2-0.1,0.3-0.2c0-0.1,0.1-0.3,0.1-0.4c0.4,0,0.4,0.8,0,0.3c0,0.3,0,0.7,0.1,1
        c-0.1-0.1-0.3-0.2-0.4-0.3c0.4-0.7-0.6,0-0.4-0.5c0,0,0.1-0.1,0.1-0.2c-0.2,0-0.3,0.2-0.4,0.4c0.2,0.1,0.4,0.1,0.6,0.2l-0.2,0.2
        c-0.1,0-0.2-0.1-0.3-0.1c0,0.2,0,0.4,0.2,0.5c0.1-0.1,0.2-0.3,0.3-0.4c0,0.1,0,0.4,0,0.6c0.1,0.2,0.2,0.6,0,0.8
        c-0.2,0.1-0.3-0.2-0.4-0.3c0.1,0,0.3,0,0.5,0.1c-0.1-0.2-0.1-0.6-0.4-0.5c0,0.1-0.1,0.2-0.1,0.3c-0.1,0-0.2,0-0.3,0
        c0.1,0.2,0.2,0.3,0.2,0.5c-0.2,0-0.5,0-0.7,0c0,0,0,0.1,0,0.1c0.1,0.1,0.3,0.2,0.4,0.2c-0.1,0-0.7,0.3-0.2,0.3c0,0.1,0,0.3,0,0.3
        c-0.2,0-0.3,0.2-0.4,0.3c0.2,0.2,0.2,0.4,0.2,0.6c0.1,0,0.3,0,0.4,0c0,0,0.1,0.1,0.1,0.2c-0.2-0.1-0.4,0-0.7,0
        c0.2-0.3,0-0.6-0.3-0.8c0,0.1,0,0.3,0,0.5c-0.1,0-0.2,0-0.3,0c0,0.1,0,0.2,0,0.3c-0.1,0.1-0.3,0.3-0.4,0.5c0.2,0,0.3-0.1,0.5-0.1
        c0,0.1,0.1,0.3,0.1,0.4c-0.4,0.1-0.4,0.1,0.1,0.1c-0.1,0.2-0.3,0.3-0.4,0.5c-0.3,0.1-0.3,0.4-0.3,0.6c0.3-0.2,0.4-0.5,0.8-0.6
        c-0.2,0.3-0.6,0.5-0.8,0.8c0,0.1,0.1,0.3,0.2,0.5l-0.2,0c0-0.1,0-0.2-0.1-0.3l-0.1-0.1c0-0.2,0.1-0.5-0.1-0.7
        c-0.2,0.4,0.1,0.9-0.2,1.2l0.4-0.3l-0.1,0.3c-0.1,0.1-0.2,0.2-0.3,0.2c0,0.1,0,0.2-0.1,0.3c-0.1-0.1-0.2-0.3-0.3-0.4
        c-0.1,0.1-0.2,0.3-0.3,0.5c0.2,0.1,0.4,0,0.6,0.1c0.1-0.4,0.4-0.1,0.6-0.1c-0.1,0.3-0.4,0.2-0.6,0.1c-0.3,0.1-0.5,0.3-0.8,0.1
        c0.2,0.3,0.3,0.7,0.2,1.1c-0.2,0-0.5-0.4-0.5,0c-0.1-0.1-0.2-0.2-0.4-0.3c0,0.1,0,0.2,0.1,0.3c0,0.1,0,0.5,0.3,0.5
        c0.1-0.2,0.5-0.4,0.6-0.1c-0.3,0.1-0.5,0.3-0.7,0.6l-0.1-0.1c-0.2-0.5-0.4,0.1-0.3,0.3c-0.1-0.1-0.2-0.2-0.3-0.3
        c0,0.2,0.1,0.3,0.1,0.5c-0.2,0.2-0.3,0.5-0.2,0.7l-0.3,0.1c0-0.1-0.1-0.2-0.2-0.3c0.2,0.5-0.3,0.9-0.5,1.3c0.4-0.2,0.8-0.4,1.1-0.7
        c0-0.1-0.1-0.2-0.1-0.4c0.1,0,0.3-0.1,0.4-0.1c0-0.1-0.1-0.3-0.2-0.4c0.3,0.3,0.5-0.1,0.7-0.2c-0.2,0-0.5,0.1-0.7,0.2
        c0.2-0.2,0.3-0.5,0.3-0.7c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0,0.3,0.1,0.4,0.1c-0.2-0.2-0.3-0.4-0.4-0.6c0.2,0.3,0.4,0.1,0.6-0.1
        c0.2-0.1,0.4-0.2,0.6-0.3l-0.1-0.2c-0.1,0-0.2-0.1-0.2-0.1c0.1,0,0.2-0.1,0.2-0.1c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.2-0.5,0.4-0.6
        c0.4-0.5,0.7-1,1.1-1.5c0.4-0.2,0.4-1.1,0.9-0.7c0-0.1-0.1-0.3-0.1-0.4c0.3-0.2,0.7-0.4,0.6-0.8c0-0.3,0.3-0.5,0.5-0.6
        c0-0.1,0.1-0.4,0.1-0.5c0.1,0.1,0.3,0.2,0.4,0.2c-0.1-0.1-0.1-0.3-0.2-0.4c0.2-0.3,0.3-0.6,0.7-0.6c-0.1-0.1-0.1-0.3-0.2-0.4
        c0.2-0.1,0.3-0.1,0.5-0.2c0-0.2,0.1-0.4,0.1-0.6c0.1-0.2,0.3-0.5,0.4-0.7c-0.1-0.6,0.5-1.1,0.7-1.6c0.2-0.2,0.5-0.5,0.3-0.9
        c0.5-0.2,0.3-0.8,0.5-1.2c0.2-0.5,0.3-1.1,0.6-1.5c0-0.2,0-0.4,0.1-0.7c0.3-0.2,0.4-0.5,0.2-0.8c0.1,0,0.2-0.1,0.3-0.1
        c0-0.2-0.1-0.5,0-0.7c0.2-0.6,0.2-1.2,0.4-1.7c0-0.3,0.1-0.6,0.3-0.8c-0.2-0.3-0.1-0.5,0.1-0.8c-0.1-0.3-0.1-0.6,0-0.9
        c0.2-0.6,0.1-1.2,0.3-1.8c-0.2-0.6-0.5-1.1-0.3-1.8c0.2-0.4,0-0.7-0.1-1c0.1-1.1,0-2.2,0.2-3.3c-0.1-0.6,0.2-1.1,0-1.7
        c-0.1-0.3,0.1-0.6,0.2-0.9c-0.2-0.3-0.2-0.6-0.1-1c-0.1-0.9,0.1-1.7,0.2-2.6c0-0.4-0.1-0.8,0-1.2c0.1-0.4-0.2-0.9,0.2-1.2
        c-0.1-0.4,0-0.9,0.1-1.3c-0.2-0.4,0-0.7-0.1-1.1c-0.1,0-0.3,0-0.4,0l0-0.2c0.1,0,0.2,0,0.3,0.1c0-0.2,0-0.4,0.1-0.6
        c-0.2,0.1-0.3,0.2-0.5,0.3c0.1-0.2,0.2-0.4,0.2-0.6l0.1,0l-0.1,0.2l0.3,0c-0.1-0.3-0.1-0.7-0.1-1C116,167.7,115.8,168.1,115.6,168.5
        M338,167.7c0.2,0.5-0.4,0.3-0.6,0.2c0,0.1-0.1,0.3-0.1,0.4c-0.2,0-0.3,0.1-0.4,0.1c0.1,0.4-0.5,0.4-0.3,0.8
        c0.2-0.1,0.4-0.2,0.6-0.3c0,0.3-0.6,0.3-0.5,0.6c0.2,0,0.4-0.1,0.6-0.1c0,0,0,0.1,0,0.1c-0.3,0.1-0.6,0-0.6,0.3l-0.1-0.1
        c0,0.1,0,0.3,0,0.4l-0.4-0.1c0.1,0.1,0.2,0.2,0.2,0.2c0,0.1,0,0.2,0,0.2c0.2,0.1,0.4,0.2,0.5,0.1c-0.7-0.2-0.1-0.5,0-0.8
        c0,0.2,0,0.5,0,0.7c-0.2,0.3-0.2,0.7-0.1,1.1c-0.4-0.2-0.4-0.5-0.2-0.8c-0.2,0-0.4,0.1-0.7,0.2c0,0.1,0.1,0.3,0.2,0.4
        c-0.1,0.2-0.1,0.5-0.1,0.7l-0.2,0c0,0,0,0.1,0,0.2c0.2-0.2,0.5-0.3,0.8,0c0,0.1-0.1,0.2-0.2,0.3c-0.1-0.2-0.2-0.3-0.3-0.4
        c-0.2,0.2-0.3,0.5-0.4,0.7c0.1,0.1,0.3,0.3,0.4,0.3c0-0.1-0.1-0.3-0.2-0.5c0.1,0,0.3,0,0.4,0c-0.1,0.1-0.1,0.3-0.2,0.4
        c0.1,0.1,0.1,0.2,0.2,0.3c-0.4-0.1-0.5-0.5-0.9-0.5c0,0.3,0.4,0.4,0.4,0.6c-0.1,0-0.2,0-0.3,0.1c-0.1,0.3,0,0.6-0.2,0.8
        c0.2,0,0.4,0.3,0.1,0.3c-0.3,0-0.3,0.5,0,0.6c-0.1-0.3,0.3-0.4,0.4-0.6c0.1,0.3-0.2,0.4-0.3,0.6l-0.1,0.3c0.1,0,0.3-0.1,0.3-0.1
        c-0.1,0-0.2,0.1-0.3,0.2c0,0.1,0,0.2,0.1,0.3c0,0.1-0.1,0.2-0.1,0.3l0,0c0-0.1,0-0.3-0.1-0.4c-0.2-0.2-0.7-0.2-0.4,0.2
        c0.1,0.1,0.2,0.2,0.2,0.3c-0.2,0.3-0.5,0.6-0.6,1c-0.2,0.1-0.3,0.4-0.3,0.6c0.1-0.1,0.3-0.2,0.4-0.3c0.1,0,0.2,0,0.3,0
        c0-0.1,0-0.4,0.1-0.5c0.2,0.1,0.2,0.4,0.2,0.6l-0.3,0c0,0.1,0.1,0.2,0.1,0.3c-0.3,0.3,0.1,0.7-0.2,1c0-0.4,0.1-0.9-0.2-1.2
        c-0.1,0.3-0.3,0.6-0.4,0.9c0.1,0.1,0.3,0.3,0.4,0.5c0.1,0,0.2,0,0.3,0c-0.2,0.1-0.4,0.2-0.6,0.2c0-0.1,0-0.4,0-0.5l-0.1,0
        c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0.3,0.4,0,0.6c0,0.4,0.3,1-0.2,1.2c0.1-0.3,0.3-0.7-0.1-0.8c0,0.3,0,0.6,0,0.8c0,0.1,0.1,0.2,0.2,0.2
        c-0.2,0-0.4-0.1-0.6-0.2c0,0.1,0.1,0.3,0.2,0.3c-0.1,0-0.2,0-0.3,0c0,0.1,0,0.2,0,0.3c0.2,0.2,0.4,0.4,0.3,0.6
        c-0.1-0.1-0.2-0.2-0.3-0.3c0,0.1,0,0.4,0,0.5c-0.1,0-0.3-0.1-0.3-0.2c-0.1,0.4,0.1,0.7,0.3,1.1c-0.2,0.1-0.3,0.3-0.5,0.4
        c0-0.3,0.5-0.7,0.1-0.9c0,0.2,0,0.4,0,0.6c-0.2,0.1-0.3,0.3-0.4,0.5c0.1,0,0.3-0.1,0.5-0.1c0,0-0.1,0.1-0.1,0.2l-0.2,0
        c0,0.2,0,0.4,0.1,0.6c-0.2,0.2-0.3,0.4-0.5,0.7c-0.1,0-0.2-0.1-0.3-0.1c0.4,0.1,0.1,0.5,0,0.8c-0.4,0.1-0.3,0.5-0.2,0.8l-0.1,0.1
        c0,0.1,0,0.2,0,0.3l-0.2-0.1c0,0.1,0.1,0.2,0.1,0.2c-0.1,0.2,0,0.4,0.1,0.6c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
        c-0.2,0.1-0.3,0.2-0.4,0.4c0.1,0,0.3,0,0.4-0.1l0.1-0.2c0,0,0.1,0,0.2,0.1c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1,0.2-0.5,0.3-0.3,0.5
        c0.1-0.2,0.3-0.3,0.5-0.3c-0.1,0.3-0.3,0.5-0.5,0.7c-0.2,0.2,0,0.3,0.2,0.3c0-0.1,0-0.3,0-0.4c0.4,0.3-0.3,0.6-0.5,0.8
        c0.3,0.3,0.1,0.6,0,0.9c0.5-0.2,0.1-0.9,0.6-1c0,0.1-0.1,0.4-0.2,0.5l-0.1,0l0,0.3l0.3,0c-0.1,0.1-0.2,0.3-0.2,0.5l-0.2,0l0,0.1
        c0.1,0,0.2,0,0.2,0c-0.1,0.2-0.1,0.8-0.5,0.6c0,0,0,0.1,0,0.1c0.2-0.1,0.3-0.1,0.5-0.2c0,0.2-0.1,0.4-0.1,0.6c0.1,0,0.2,0,0.3,0
        l-0.1,0.3c0.1,0,0.3,0,0.4,0c-0.2,0.3-0.4,0.5-0.5,0.8c0.1,0,0.2-0.1,0.3-0.2l0.1,0c0,0.1,0,0.3,0.1,0.4c0,0-0.1,0.1-0.2,0.2
        c0.2,0.3,0.3,0.7,0.8,0.7c0,0,0,0.1,0,0.1c-0.1,0-0.3-0.1-0.4-0.1c0.1,0.3,0.2,0.7,0.5,0.9c0-0.1,0-0.4,0-0.5
        c0.2,0.2,0.3,0.4,0.2,0.7c0.2,0.1,0.3,0.4,0.5,0.4c-0.1-0.2-0.2-0.3-0.3-0.5c0.3,0,0.5,0.4,0.7,0.4c0,0.1-0.1,0.2-0.2,0.3
        c0.1,0,0.2,0.1,0.2,0.1c0.1-0.1,0.2-0.2,0.2-0.3c0.1,0.2,0.2,0.7,0.5,0.4c0.1,0,0.3,0.1,0.4,0.2c0,0.1-0.1,0.2-0.1,0.2
        c0.3-0.3,0.7,0,1.1,0c1,0,2,0.2,3,0.2c0.9-0.1,1.7-0.2,2.6-0.3c0.5-0.1,1.1,0,1.6-0.4c0,0.1,0.1,0.2,0.2,0.2c0-0.3,0.3-0.6,0.2-0.9
        c0.2,0.1,0.4,0.2,0.5,0c-0.1,0-0.3-0.1-0.3-0.2c0.4-0.1,0.2-0.3,0.1-0.5c0.2-0.2,0.3-0.4,0.4-0.6c-0.3,0-0.4,0.4-0.7,0.3
        c0.3-0.3,0.7-0.5,0.9-0.9c0-0.3-0.3-0.5-0.4-0.7l0.2,0.1c-0.1-0.2-0.1-0.4-0.2-0.5c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.3-0.2-0.8,0.2-0.9
        c0,0.2,0,0.5,0.1,0.7c0.3,0,0.1-0.4,0.3-0.5c0-0.1-0.2-0.2-0.2-0.2c0.3-0.1,0.4-0.3,0.6-0.5c-0.3,0-0.6,0.2-0.9,0.2
        c0.3-0.2,0.8-0.2,0.9-0.6c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1-0.2-0.5-0.6-0.1-0.7c0,0.1,0.1,0.2,0.1,0.2c0.2,0,0.3,0,0.5,0
        c-0.1-0.2-0.3-0.4-0.3-0.6c0.2-0.2,0.4,0.1,0.6,0.2c-0.1-0.2-0.3-0.3-0.4-0.5c0.1-0.1,0.1-0.2,0.2-0.2c-0.1,0-0.2-0.1-0.2-0.1
        l-0.1-0.2c0.1,0,0.3,0.1,0.5,0.1c-0.3-0.3,0-0.7-0.1-1c0.1,0,0.2,0.1,0.3,0.1c-0.1-0.2-0.1-0.5-0.3-0.6c-0.1,0-0.2,0-0.3,0
        c0,0,0.1-0.1,0.1-0.2l0.1,0c0.2-0.1,0.1-0.3,0-0.5c0.1-0.1,0.2-0.3,0.3-0.4l0.3,0.1c0-0.1-0.1-0.2-0.1-0.3c0.2-0.1,0.2-0.4,0.2-0.5
        c0-0.2,0.2-0.4,0.2-0.6c0.3,0,0.3-0.2,0.2-0.4c-0.2,0.5-0.5,0.1-0.5-0.2l0.2,0c-0.1-0.2-0.1-0.4-0.2-0.7c0.1,0.1,0.3,0.2,0.4,0.3
        c0-0.2,0-0.3-0.2-0.3c0-0.2-0.1-0.6,0.2-0.6c0-0.3,0.1-0.6,0.2-0.9c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2-0.1-0.3-0.2-0.4-0.4
        c0.1,0,0.2,0,0.2,0l0.1,0.2c0.3-0.1,0-0.5,0.3-0.6c-0.1-0.1-0.2-0.3-0.3-0.4l0.3,0c-0.1-0.1-0.1-0.3-0.2-0.4l-0.2,0.1
        c-0.1-0.2-0.2-0.3-0.2-0.5c0.1,0.1,0.3,0.2,0.4,0.3c0.1-0.1,0.1-0.3,0.2-0.4c-0.1-0.2,0-0.4-0.3-0.4c0-0.2,0.1-0.4,0.2-0.5l-0.3,0.1
        c0.1-0.1,0.2-0.3,0.3-0.4l-0.3,0c0.1-0.3-0.1-0.7,0.1-1c-0.2,0-0.3-0.1-0.4-0.2c0.2,0,0.4,0,0.5,0.1c-0.1-0.1-0.2-0.2-0.2-0.3
        c0.1-0.4,0-0.7-0.2-1c-0.1-1-0.4-2-0.6-3.1c-0.2-0.6,0-1.4-0.6-1.8l0-0.1c0.1,0,0.2,0.1,0.3,0.1c0.2-0.4-0.2-0.3-0.5-0.4
        c0-0.1,0.1-0.4,0.1-0.5c-0.2-0.2-0.3-0.5,0-0.7c-0.1-0.2-0.2-0.3-0.3-0.5c0-0.1,0.1-0.2,0.2-0.2c-0.1,0-0.3,0-0.4,0.1
        c0-0.1,0-0.3,0-0.4c-0.2-0.1-0.3-0.2-0.4-0.4c0.6,0,0.1-0.3-0.1-0.3c-0.4-0.1-0.4-0.7-0.8-0.9c-0.2-0.1-0.5-0.2-0.7-0.3
        c0,0.1,0,0.4,0,0.5c-0.2-0.1-0.2-0.4-0.2-0.6c-0.2,0.1-0.4,0.3-0.6,0.5c0.4-0.1,0.5,0.4,0.6,0.7c0,0.1,0.1,0.2,0.2,0.3l-0.1,0
        c-0.1-0.1-0.2-0.2-0.3-0.2c0-0.1,0-0.4,0-0.5l-0.4,0.1c0.1,0.2,0.1,0.3,0.2,0.5c-0.1,0.1-0.3,0.2-0.4,0.3c0.2-0.4,0.2-0.8-0.1-1.1
        c0,0.1,0,0.2,0,0.2c0.4,0.1,0.1,0.5,0,0.6c0.1,0.2,0.2,0.4,0.4,0.6c-0.1,0.1-0.3,0.2-0.4,0.3c0.2,0.4-0.1,0.5-0.4,0.7
        c0.7,0.1,0.2,0.9-0.2,1.1c0.2-0.1,0.3-0.2,0.5-0.2c0.1,0.2,0.3,0.3,0.3,0.6c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2,0.1-0.3,0.3-0.5,0.2
        c-0.1,0.1-0.2,0.2-0.3,0.3c0,0.1,0,0.3-0.1,0.4c0.1-0.3,0.4-0.5,0.7-0.6c0,0.1,0.1,0.3,0.2,0.4c0-0.1,0-0.3,0-0.4
        c0.2,0.1,0.4,0.1,0.5,0.2l0.4-0.1c-0.1,0.3-0.4,0.5-0.7,0.6c0.1,0.1,0.2,0.2,0.2,0.2c-0.3,0-0.4,0.2-0.3,0.4
        c-0.3-0.2-0.5,0.2-0.6,0.5c0.2-0.1,0.3-0.3,0.5-0.4c0,0.3-0.1,0.5-0.1,0.8c-0.1,0-0.2,0-0.3,0c0.1,0.2,0.2,0.3,0.4,0.5
        c-0.3,0.1-0.1,0.6,0.2,0.4c-0.1,0.1-0.3,0.3-0.2,0.5c0.3-0.1,0.5-0.1,0.6,0.2c0.1,0.1,0.2,0.1,0.3,0.2c-0.1,0-0.3,0-0.4-0.1l0.1,0.2
        c-0.1,0-0.4,0-0.5,0c0.1,0.1,0.2,0.2,0.3,0.3c-0.3,0-0.5,0.1-0.8,0.2c0.1,0.1,0.1,0.2,0.2,0.2c-0.1,0-0.3,0-0.5-0.1
        c-0.1,0.2-0.3,0.5-0.2,0.7c-0.1,0-0.2,0.1-0.3,0.1l0,0.3c-0.2-0.3-0.2-0.7,0.2-0.7c0-0.1,0.1-0.3,0.1-0.4c-0.1,0.2-0.4,0.4-0.6,0.6
        c0,0.1,0.1,0.2,0.1,0.3l-0.3,0c0,0,0,0.1,0,0.1c0.1,0,0.3,0,0.3-0.1l0,0.4l-0.2-0.1c0,0.2,0,0.4,0.1,0.7c0.1,0,0.3-0.1,0.4-0.1
        c0.1,0.2,0.1,0.3,0.2,0.5c0.1,0,0.2,0,0.3,0l0,0.3c-0.4,0-0.8,0-1.1,0c-0.1,0.3-0.3,0.1-0.5,0.1l0,0.2c-0.3,0.1,0,0.4,0,0.6
        l-0.3-0.1l0.1,0.2c-0.3,0.1-0.3,0.5-0.5,0.7l0.3,0c-0.1,0.2-0.4,0.5-0.1,0.7c-0.3,0.1-0.1,0.4-0.1,0.7c-0.2-0.2-0.3-0.4-0.6-0.5
        c0.1,0.1,0.1,0.3,0.2,0.4c-0.1,0-0.3,0.1-0.4,0.1c0.2,0,0.7-0.2,0.7,0.2c-0.5-0.1-0.9,0.4-1.4,0.6c0-0.1-0.1-0.2-0.1-0.2
        c0.1,0,0.3-0.1,0.4-0.1l-0.1-0.2c0.2,0,0.3,0,0.5,0c-0.2-0.1-0.3-0.2-0.5-0.3l0.1,0.3c-0.1,0-0.3-0.1-0.4-0.1
        c-0.2,0.2-0.4,0.5-0.2,0.8l-0.2,0c0,0.1,0.1,0.3,0.1,0.4c-0.5-0.1-0.4,0.6-0.5,1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0.1-0.3,0.3-0.1,0.4
        c0.2,0.4-0.2,0.7-0.2,1.1c-0.1-0.1-0.3-0.3-0.3-0.4c0,0.3,0.1,0.5,0.4,0.5c0,0.1,0.1,0.3,0.1,0.4c-0.1,0-0.3-0.1-0.4-0.1
        c0,0.2-0.2,0.6,0.2,0.4c0,0.2,0,0.4,0,0.7c0.6,0-0.3,0.3-0.2,0.5c0.5-0.2,0.4,0.3,0,0.1c0.2,0.2,0.4,0.4,0.5,0.7
        c-0.1,0-0.3,0.1-0.4,0.1l0.1-0.2c-0.1,0-0.2,0-0.2,0c0,0.1,0,0.2,0.1,0.3c0.1,0,0.2,0.1,0.3,0.1c-0.2,0.3-0.1,0.5,0.2,0.6
        c0,0.1,0,0.3,0.1,0.4c-0.2-0.1-0.5-0.4-0.7-0.4c-0.3,0.1-0.2,0.4-0.2,0.6c0-0.2,0.1-0.4,0.2-0.5c0.1,0.1,0.4,0.2,0.5,0.3
        c0,0.2,0,0.4,0.2,0.5c0,0.1,0,0.3,0,0.4l-0.2,0c0,0.2,0,0.3,0,0.5c0.1,0,0.3,0,0.5-0.1c0.1,0.1,0.3,0.3,0.4,0.4c-0.1,0-0.3,0-0.5,0
        c0,0,0,0.1,0,0.1c0.3-0.3,0.5,0.1,0.3,0.4c0.1,0.1,0.3,0.2,0.4,0.2c0,0.2-0.1,0.4,0.2,0.5c-0.1-0.3,0-0.5,0.3-0.4
        c-0.1,0.1-0.1,0.3-0.2,0.4c0.1,0,0.4,0,0.5,0c0,0.1,0,0.2,0,0.3c-0.1,0-0.3,0-0.4,0c0.2,0.1,0.4,0.2,0.6,0.4c-0.1-0.2,0-0.4,0.1-0.6
        c0.2,0.1,0.3,0.2,0.5,0.2c-0.2,0.2-0.5,0-0.6,0.3c0.2,0,0.3,0.1,0.5,0.1c0.3-0.3,0.7-0.1,0.9,0.2c-0.1,0-0.2,0-0.3,0.1
        c0.1,0,0.2,0.1,0.3,0.1c0-0.1,0-0.3,0-0.4c0.2,0.2,0.4,0.4,0.6,0c0,0.1,0.1,0.2,0.1,0.3l0.1,0c0-0.1,0-0.2-0.1-0.2
        c0.3-0.1,0.7,0,0.9-0.3c0.1-0.2,0.3-0.3,0.4-0.5c0.1-0.3,0.1-0.6,0.2-0.9c0.3-0.5,0.2-1.1,0.5-1.6c-0.3-0.3-0.1-0.7,0-1
        c0,0-0.1-0.1-0.1-0.2c0.1-0.5-0.4-0.9-0.2-1.3c-0.1-0.1-0.3-0.2-0.4-0.3c0-0.1-0.1-0.2-0.1-0.2c-0.1-0.1-0.2-0.3-0.3-0.4
        c-0.3-0.6-0.9-0.8-1.2-1.3c-0.3,0-0.6,0-0.8-0.3c-0.3,0-0.6,0-0.9,0.1c0.1-0.1,0.2-0.2,0.3-0.3l0,0.2c0.2-0.1,0.5-0.1,0.8,0
        c0.5,0,1,0,1.4,0.4c0-0.1,0.1-0.2,0.2-0.3c0,0.1-0.1,0.3-0.1,0.4c0.1,0,0.2-0.1,0.3-0.1l0.1,0.2c0.3-0.1,0.6,0,0.6,0.4l0.3,0.1
        c-0.1,0.1-0.3,0.2-0.4,0.3c0.3,0,0.6-0.1,0.8,0.1c0.5,0.3,0.5,1,0.8,1.4c-0.1,0-0.2,0-0.3,0c0,0.1,0,0.2,0,0.3c0.1,0,0.3,0,0.4,0.1
        c0.1,0.2,0.1,0.5,0.1,0.7c0.3,0.3,0.2,0.9-0.1,1.2c0-0.6,1,0,0.4,0.1c0,0.3,0.1,0.6-0.1,0.8c-0.2,0.2,0,0.5-0.2,0.7
        c-0.1,0-0.2-0.1-0.2-0.1l0-0.2c0,0-0.1,0-0.2,0.1c0,0.2,0.1,0.3,0.2,0.5c0.1,0,0.2,0,0.2,0c0,0.1,0,0.4,0,0.5
        c-0.1,0-0.2-0.1-0.3-0.1c0,0.1,0.1,0.3,0.1,0.4c-0.2,0.2-0.4,0.3-0.4,0.6c0,0-0.1,0.1-0.2,0.1c-0.1,0.4-0.3,0.7-0.5,1
        c0,0.1,0,0.3,0,0.4c0,0-0.1,0-0.1,0c0-0.1,0.1-0.3,0.1-0.4c-0.3,0-0.3,0.6-0.7,0.3l0,0.1l0.2,0.1c-0.2,0.1-0.4,0.5-0.6,0.3
        c-0.2,0.4-0.9,0.4-1.3,0.5c-0.4,0.1-1.1,0.3-1.2-0.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1,0.1-0.3,0.2-0.4,0.3
        c0-0.1,0.1-0.4,0.1-0.5c-0.1,0.1-0.2,0.2-0.2,0.3c-0.2-0.1-0.4-0.2-0.6-0.3c-0.1,0.1-0.1,0.1-0.2,0.2c0-0.1-0.1-0.2-0.1-0.3
        c-0.4-0.1-0.7-0.5-1-0.8l0,0.2c-0.2,0-0.4-0.1-0.7,0c0,0,0-0.1,0-0.1c0.1,0,0.4,0,0.5,0c-0.1-0.5-0.8-0.7-0.8-1.3
        c-0.5-0.4-0.6-1-0.9-1.5c0.1-0.4,0-0.9-0.1-1.3c-0.2-0.6-0.1-1.3-0.2-2c0.1-0.6-0.1-1.4,0.4-1.9c0-0.5,0.1-1.1,0.3-1.6
        c0.4-0.6,0.4-1.3,0.6-1.9c0-0.4,0.5-0.6,0.3-1c0.1-0.6,0.1-1.3,0.5-1.8c0-0.1,0-0.2,0-0.3c0.1-0.3,0.2-0.7,0.2-1
        c-0.2-0.4,0-0.8,0.3-1.1c-0.1-0.7,0.3-1.3,0.5-2c0.5-1,0.6-2.2,1-3.3c0.2-0.2,0.3-0.4,0.2-0.7c0.1-0.6,0.3-1.3,0.5-1.9
        c0.2-0.6,0.1-1.3,0.5-1.9c-0.2-0.6,0.1-1,0.3-1.5c-0.2-0.1-0.3-0.2-0.4-0.4c0,0.1,0,0.3,0,0.4C338.3,168,338.1,167.8,338,167.7
        M402.3,167.8c-0.1,0.2-0.1,0.4-0.2,0.6c0.2-0.2,0.4-0.4,0.7-0.5C402.6,167.9,402.5,167.9,402.3,167.8 M44.6,168.7l0.1,0
        c-0.1-0.4,0.3-0.5,0.5-0.7c-0.1,0-0.4-0.1-0.5-0.1C44.6,168.1,44.6,168.4,44.6,168.7 M43.8,168.9l0.2,0l0.1-0.2
        c0.3,0.1,0.1-0.4,0.1-0.5C44.1,168.4,44,168.7,43.8,168.9 M126,168.3C125.8,168.7,126.5,168.3,126,168.3 M288.4,168.3
        C288.5,168.3,288.5,168.3,288.4,168.3 M470.4,168.2c0,0.1,0.1,0.3,0.1,0.3c0,0.1-0.1,0.2-0.1,0.2c0.1,0,0.2,0,0.3-0.1l0,0.2
        c0.1,0,0.2,0,0.2-0.1c0-0.1-0.1-0.2-0.1-0.2C470.7,168.5,470.5,168.4,470.4,168.2 M237.1,169.3c-0.3,0.5-0.4,1.2-0.8,1.6
        c0.1,0.3-0.1,0.5-0.4,0.5c-0.1,0.4-0.1,0.8-0.1,1.2c-0.4,0.9-0.6,1.9-0.5,2.8c-0.2,0.3,0,0.8-0.1,1.1c0.1,0.3,0.1,0.6,0,0.8
        c0,0.6,0.2,1.3,0,1.8c0.1,0.3,0.3,0.6,0,0.9c0.3,1,0.1,2,0.2,3c-0.2,0.3-0.3,0.5-0.2,0.8c-0.2,0.8,0,1.6-0.5,2.3
        c0.1,0.3,0.1,0.5,0.1,0.8c0.1,0,0.2,0.1,0.3,0.1c0,0.1,0.1,0.3,0.1,0.3c0.2,0,0.3,0.1,0.5,0.2c0.4,0.3,0.5,0.8,1,1
        c0-0.2-0.1-0.3-0.3-0.5c-0.1-0.3-0.3-0.5-0.4-0.7c0-0.1,0.1-0.2,0.1-0.2c-0.2-0.4,0-0.9-0.1-1.3c-0.1-0.3,0.1-0.5,0.2-0.7
        c0-0.3-0.1-0.7-0.1-1c0.2-0.6,0.3-1.1,0.4-1.7c0.1-0.8,0.4-1.5,0.8-2.2c0-0.6,0.4-1.1,0.5-1.7c-0.3-0.5-0.2-1-0.2-1.5
        c-0.1-0.8-0.1-1.7-0.1-2.5c-0.1-0.4-0.2-0.8,0.1-1.2c-0.1-0.3-0.2-0.7-0.2-1c0.1-0.4,0.1-0.8-0.1-1.1c0-0.1,0.1-0.2,0.1-0.3
        c-0.1-0.3-0.1-0.7,0-1c-0.1-0.3-0.2-0.5,0-0.8C237.2,169.3,237.1,169.3,237.1,169.3 M471.3,169.8c0,0.1,0,0.3,0,0.4
        c0.2,0.1,0.3,0.2,0.5,0.2c-0.1-0.2,0.1-0.4,0.2-0.6C471.7,169.9,471.5,169.8,471.3,169.8 M43,170.1c0,0.5,0.7,0,0.4-0.2
        c0,0-0.1,0-0.2,0.1C43.1,169.9,42.9,169.9,43,170.1 M398.8,170.7c0,0.1,0.2,0.3,0.2,0.4c0.1,0,0.3,0,0.4,0c-0.1,0-0.2,0.1-0.2,0.1
        c0,0.1,0.1,0.2,0.1,0.2c0.1-0.1,0.2-0.2,0.3-0.3c-0.1-0.3-0.5-0.4-0.4-0.9c0.1,0.1,0.3,0.2,0.3,0.3c0.1-0.1,0.1-0.3,0.2-0.4
        C399.4,170.1,398.9,170.2,398.8,170.7 M42.2,171c0.2,0,0.4-0.3,0.2-0.4C42.3,170.5,42.1,170.8,42.2,171 M109.9,170.5
        C110,171,110.4,170.3,109.9,170.5 M398.3,170.7c0.1,0.3,0.1,0.6,0.1,0.8c0,0.3,0.2,0.4,0.2,0.7c-0.1,0.4,0.1,0.8,0,1.3
        c0,1.1,0.3,2.1,0.2,3.1c0.1,0.3,0.1,0.7-0.1,1c0.5,0.6,0,1.3,0.2,2c0,1,0.2,2,0.1,3c0,0.4,0.1,0.8,0,1.2c-0.2,0.4,0.1,0.8,0.1,1.3
        c0,0.5-0.3,0.8-0.4,1.3c0.2,0.4,0.1,0.8,0.2,1.2c0,0.5,0.3,1,0.1,1.5c0.2,0.3,0.2,0.5,0.1,0.8c0.3,0.2,0.3,0.5,0.3,0.9
        c0.2,0.3,0.1,0.7,0.2,1c0.1,0.4,0.3,0.8,0.1,1.3c0.1,0,0.2,0.1,0.3,0.1c-0.2,0.3-0.1,0.6,0.2,0.8c0,0.7,0.4,1.3,0.6,1.9
        c0.3,0.5,0.2,1.1,0.6,1.5c0.1,0.2-0.1,0.4,0.1,0.6c0.3,0.2,0.4,0.6,0.7,1c0.1,0.3,0.4,0.5,0.3,0.9c0.1,0.2,0.3,0.5,0.4,0.7
        c0,0.2,0,0.4,0.1,0.6c0.2,0.1,0.3,0.1,0.5,0.2c-0.1,0.1-0.1,0.3-0.2,0.4c0.1,0,0.3,0.1,0.4,0.1c0.1,0.2,0.5,0.5,0.1,0.7
        c0,0,0.1,0.1,0.1,0.1c0.1-0.1,0.2-0.2,0.3-0.2c0,0.6,0.7,0.8,0.6,1.4c0.2,0.3,0.8,0.4,0.5,0.9c0.5-0.3,0.5,0.4,0.7,0.6
        c0.4,0.3,0.6,0.8,1,1.2c0.2,0.4,0.6,0.6,0.7,1c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.1,0.3,0.2c-0.1,0-0.3,0-0.4,0
        c0.2,0.4,0.7,0.5,1,0.8c0.1,0,0.2-0.1,0.3-0.2c-0.1,0.2-0.3,0.4-0.4,0.6c0.1,0,0.3-0.1,0.4-0.1c0.1-0.2,0.2-0.3,0.4-0.5
        c0,0.3,0.1,0.5,0.3,0.7c-0.2,0-0.5-0.1-0.7-0.2c0.2,0.1,0.4,0.6,0.7,0.2c0,0.1-0.1,0.3-0.2,0.4c0.1,0,0.3,0.1,0.3,0.1
        c0,0.1-0.1,0.3-0.1,0.4c0.3,0.3,0.7,0.5,1,0.7l0-0.1c-0.3-0.4-0.5-0.8-0.4-1.2c0,0.1-0.1,0.2-0.1,0.3l-0.3-0.1
        c0.1-0.3,0-0.5-0.2-0.7c0-0.2,0.1-0.4,0.1-0.5c-0.1,0.1-0.2,0.2-0.3,0.3c0-0.2,0-0.4-0.2-0.5c-0.1,0.1-0.2,0.3-0.2,0.4
        c-0.2-0.3-0.5-0.4-0.8-0.7c0.3,0.1,0.4,0,0.6-0.1c0.1,0.1,0.1,0.2,0.1,0.4l0.1,0l0-0.2c0.2-0.1,0.2-0.4,0.2-0.7
        c-0.1,0.1-0.2,0.2-0.2,0.3c-0.2-0.2-0.4-0.1-0.6,0c-0.1-0.4,0.1-0.8,0.2-1.1c-0.1,0.1-0.2,0.1-0.4,0.2c-0.1-0.1-0.3-0.2-0.3-0.3
        c0.1-0.2,0.4-0.1,0.6-0.1c-0.1-0.3-0.5-0.8-0.7-0.1c0.1-0.2,0.3-0.5,0.3-0.7c-0.2,0.1-0.5,0.2-0.7,0.3c0.1-0.1,0.3-0.2,0.3-0.3
        c-0.2-0.3,0-0.7-0.1-1.1c-0.2,0.1-0.3,0.1-0.4,0.2c-0.1-0.3,0.2-0.8-0.2-0.9c0.1,0.2,0.1,0.5-0.2,0.5c0-0.2-0.1-0.4-0.3-0.5
        c0-0.1,0-0.3,0-0.4c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2-0.2-0.3-0.4-0.4-0.5c0.3,0.2,0.7,0.1,1.1,0.1c0.1,0,0.3,0.1,0.3,0.1
        c-0.1-0.2-0.3-0.3-0.4-0.5c0-0.1,0-0.2,0-0.3c-0.1,0-0.2,0-0.3,0.1c0-0.1,0-0.4,0-0.5c-0.1,0.1-0.2,0.2-0.3,0.4
        c-0.3,0-0.4,0.1-0.4,0.4c-0.2-0.1-0.1-0.2,0.1-0.3c-0.1-0.2-0.2-0.4-0.2-0.7c0.2,0.2,0.4,0.4,0.4,0.6c0.1-0.1,0.3-0.3,0.4-0.4
        c-0.1-0.2-0.2-0.3-0.4-0.3c0-0.2,0-0.4,0.2-0.5c-0.1-0.1-0.3-0.1-0.4-0.3c0.1,0,0.3-0.1,0.4-0.1c0,0,0-0.1,0-0.1c-0.2,0-0.5,0-0.7,0
        c0-0.2,0.4-0.7-0.1-0.6c0-0.1-0.1-0.3-0.2-0.4c-0.1,0.2-0.2,0.5-0.3,0.7c0.1,0,0.3-0.1,0.5-0.1c-0.1,0.2-0.2,0.4-0.5,0.3
        c-0.2-0.5,0.2-1.1,0-1.6c0.2,0,0.3-0.1,0.5-0.2c-0.1-0.2-0.2-0.4-0.4-0.4c0.2,0.2,0.1,0.5-0.2,0.5c-0.1-0.3,0-0.5-0.1-0.8
        c-0.3-0.1-0.6-0.2-0.4-0.6c0.1,0.2,0.3,0.3,0.5,0.3c-0.1-0.1-0.1-0.3-0.2-0.4c-0.2,0-0.4,0-0.6-0.1c0.1-0.2,0.2-0.3,0.3-0.5l-0.2,0
        c0-0.2,0-0.4,0-0.6c-0.1,0.2-0.3,0.4-0.2,0.7c-0.2,0-0.3,0.6-0.5,0.3c0-0.3,0.1-0.6,0.2-0.9c-0.1-0.2-0.1-0.4-0.2-0.7
        c-0.1,0-0.2,0-0.3,0c0.1-0.3,0.4-0.9,0-1c0,0.1,0,0.4,0,0.5c-0.1-0.1-0.2-0.2-0.3-0.2c-0.3,0.2,0.2,0.5,0.3,0.7
        c-0.2-0.1-0.5-0.2-0.7-0.4c0.1-0.1,0.1-0.3,0.2-0.4c0.1,0,0.4,0,0.5,0c-0.1-0.2-0.3-0.4-0.6-0.4c0,0,0.1-0.1,0.2-0.1
        c0.1-0.2,0.2-0.5,0.2-0.6l-0.4,0c0.1-0.1,0.3-0.2,0.4-0.3c0.1,0.1,0.2,0.2,0.3,0.4c0.1-0.3-0.1-0.4-0.3-0.4c0.1-0.2,0-0.3-0.1-0.4
        c0,0.1,0.1,0.3,0.1,0.4c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0.2-0.2,0.4-0.4,0.5c0.1-0.2,0.2-0.5,0.2-0.8l0.2,0c-0.1-0.1-0.2-0.2-0.3-0.3
        c0.2-0.3,0.4-0.5,0.5-0.8c-0.3,0.2-0.7,0.5-0.8,0.8c-0.3-0.2-0.2-0.6-0.1-0.9c-0.3,0-0.3-0.3-0.4-0.5c0.2,0.1,0.3,0.3,0.5,0.4
        l0.1-0.4c0.1,0.1,0.1,0.2,0.2,0.3c-0.4,0.1-0.4,0.5-0.4,0.9c0.3-0.2,0.5-0.6,0.5-0.9c0.1,0,0.2,0,0.3,0c-0.2-0.2-0.3-0.3-0.4-0.6
        l0.2,0c-0.2-0.3-0.3-0.7-0.7-0.4l0.1-0.2c-0.2,0-0.3-0.1-0.4-0.3c0.3,0,0.6,0.2,0.7-0.2c-0.3,0-0.7,0.1-0.9-0.1
        c0.1-0.1,0.1-0.2,0.2-0.3l0.1,0.3c0.1,0,0.2,0,0.3,0c-0.1-0.2-0.2-0.4-0.1-0.6c-0.1,0-0.2,0-0.3,0c0-0.5-0.5-0.8-0.5-1.2
        c0.1,0,0.2,0,0.2-0.1c0.2,0.3,0.2,0.6,0.5,0.8l0-0.1c-0.3-0.1-0.1-0.4-0.1-0.6c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2-0.5-0.1-1.4-0.9-1.4
        c0,0.2,0.1,0.7-0.2,0.4c0.1-0.2,0.1-0.3,0.2-0.5l0.1,0c0.2-0.2,0.4-0.4,0.6-0.6c-0.1-0.1-0.2-0.3-0.3-0.4c0.1-0.1,0.2-0.3,0.3-0.4
        l-0.3,0.2l0.1-0.4c-0.2,0.1-0.3,0.2-0.5,0.3c0,0.3,0.1,0.5,0.5,0.5c-0.2,0.2-0.3,0.4-0.4,0.6c-0.1-0.1-0.3-0.3-0.4-0.4
        c0.2,0,0.3,0,0.5,0l-0.2-0.3l0-0.3c-0.1,0-0.3,0.1-0.4,0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.3-0.1,0.4-0.4,0.6-0.6c-0.3,0-0.5-0.2-0.7-0.3
        c0.1-0.1,0.2-0.2,0.4-0.3c-0.1-0.2-0.1-0.3,0-0.5l-0.1-0.2c0.1,0,0.4,0,0.5,0c0,0,0-0.1,0-0.1c-0.3,0.1-0.4-0.2-0.6-0.3
        c0.2-0.2,0.3-0.3,0.3-0.6c0.3,0.2,0.2,0.4,0.1,0.7c0.1,0,0.3,0,0.4-0.1c0,0-0.1-0.1-0.2-0.1c0.1-0.4-0.2-0.6-0.5-0.7
        c0.2,0.2,0.1,0.6-0.2,0.6c-0.3,0-0.1-0.3,0-0.4c-0.1-0.1-0.2-0.2-0.3-0.3c0.3-0.3,0.3-0.5-0.2-0.5l0.1,0.3c0,0-0.1-0.1-0.2-0.2
        c0-0.2-0.1-0.4-0.1-0.5c0.3,0.1,0.4,0.3,0.6,0.5c0.1,0,0.4-0.1,0.5-0.2c-0.1,0-0.2-0.1-0.3-0.2c0.1-0.1,0.2-0.2,0.4-0.1
        c0-0.1-0.1-0.2-0.1-0.3c-0.2,0.1-0.4,0.3-0.6,0.5c0-0.5,0.3-1,0.1-1.5c-0.1,0-0.2,0-0.2,0l0-0.2c-0.1,0-0.3,0.1-0.4,0.1
        c0.1,0.1,0.3,0.2,0.5,0.3c-0.1,0-0.3,0.1-0.4,0.1l0.3,0.2l0.1,0.3c-0.5-0.1-0.4-0.6-0.5-0.9c0.2-0.1,0.1-0.4,0.1-0.6
        c0.3,0.1,0.6,0.4,0.9,0.4c-0.3-0.2-0.7-0.4-0.7-0.8c0.1-0.2,0.3-0.3,0.4-0.5c-0.1-0.2-0.2-0.3-0.1-0.5c-0.1,0-0.3,0-0.3,0
        c0.1,0.1,0.2,0.3,0.3,0.4c-0.2,0.1-0.3,0.2-0.4,0.3c0-0.2,0-0.5-0.1-0.7c0-0.1-0.1-0.3-0.1-0.4c-0.1,0-0.2,0-0.2,0l-0.2,0.1
        c0.1-0.2,0.2-0.3,0.4-0.3c0-0.1,0.1-0.2,0.1-0.3c-0.3-0.2-0.1-0.5,0-0.7c0.1,0.1,0.3,0.3,0.3,0.5c-0.1,0.1-0.1,0.2-0.2,0.3
        c0.1,0.2,0.2,0.3,0.4,0.3c0-0.2-0.4-0.6,0-0.7c-0.1-0.1-0.3-0.3-0.5-0.3c0.1-0.2,0.3-0.3,0.3-0.5c-0.3,0-0.4,0.3-0.6,0.4
        c-0.1-0.2-0.1-0.3-0.2-0.5c0.1-0.1,0.3-0.2,0.4-0.3c-0.3,0-0.4-0.2-0.6-0.4c0.2,0.1,0.3,0.1,0.5,0.2c-0.1-0.1-0.2-0.4-0.3-0.5
        c0.2,0,0.3,0.1,0.5,0.1c0-0.1,0.1-0.2,0.1-0.2c0-0.2,0.2-0.6-0.2-0.5c0-0.2,0.2-0.4,0.4-0.5c0,0,0.1-0.1,0.1-0.1
        c-0.2-0.1-0.3-0.2-0.5-0.3l0,0.1c0.1,0,0.2,0.1,0.2,0.2c-0.3,0.2-0.3,0.7,0,0.9c-0.2,0.2-0.6,0.3-0.8,0.1c0-0.1,0.1-0.2,0.1-0.3
        c-0.2-0.2-0.4-0.3-0.6-0.5c0.3,0.1,0.5,0.3,0.8,0.4c0-0.2,0-0.4,0-0.6c-0.2,0-0.4,0-0.5,0c0-0.3,0-0.5,0-0.8c0,0,0.1,0,0.2,0
        c0,0.2-0.1,0.5-0.1,0.7c0.2,0,0.3,0,0.5-0.1c-0.2-0.3-0.3-0.7-0.1-1.1c-0.2,0.1-0.4,0.2-0.7,0.3c0.1-0.2,0.3-0.3,0.2-0.5
        c0.1,0.1,0.3,0.1,0.4,0.2c0-0.1-0.1-0.2-0.1-0.3l0-0.4c-0.1,0.1-0.3,0.2-0.4,0.3c0-0.1,0-0.2,0-0.2l-0.2,0.1l0,0
        c0.2,0,0.4-0.1,0.6-0.2c0-0.1-0.1-0.4-0.2-0.5c-0.2,0.1-0.3,0.2-0.5,0.4c0.1-0.2,0.2-0.3,0.3-0.5l-0.3,0c0-0.1,0.1-0.3,0.1-0.4
        H398.3 M286.7,171.4c-0.3,0.4-0.4,0.8-0.5,1.2c-0.3,0-0.3,0.3-0.2,0.5c0-0.1,0-0.3,0-0.4c0.1,0,0.3,0.1,0.5,0.1c0-0.1,0-0.2,0.1-0.3
        c0.4,0.2,0.6-0.2,0.9-0.4c0,0.1,0,0.3,0,0.4c0-0.1,0.1-0.2,0.2-0.3c0.1,0,0.4-0.1,0.5-0.1c-0.3-0.2-0.6-0.3-0.9-0.6
        c-0.4,0.1,0,0.7-0.4,0.9c-0.1,0-0.4,0.1-0.5,0.1c0.3-0.3,0.5-0.7,0.5-1.1c0.1,0,0.3,0.1,0.3,0.1
        C287.1,171.2,286.7,170.9,286.7,171.4 M287.5,171.4C287.6,171.5,287.6,171.5,287.5,171.4 M199.5,171.7c-0.1,0.2,0,0.3,0.2,0.2
        C199.8,171.7,199.7,171.6,199.5,171.7 M91.9,172.4C92,172.5,92,172.5,91.9,172.4 M422.8,172.4C422.9,172.5,422.9,172.5,422.8,172.4
        M367.8,173.1c0.1,0.2,0.2,0.3,0.4,0.5c0,0.1,0,0.3,0,0.3c0,0,0.1,0,0.2,0c0-0.3-0.4-0.9,0.2-0.9C368.5,172.6,368,172.9,367.8,173.1
        M399.6,172.9c-0.1,0.2,0,0.3,0.2,0.3C399.9,173,399.8,172.9,399.6,172.9 M404,173C404.1,173,404.1,173,404,173 M111.3,173.3
        l-0.1,0.1c0,0.3,0.5,0.1,0.3-0.1C111.5,173.3,111.4,173.3,111.3,173.3 M402.6,173.2c-0.1,0.2,0,0.3,0.2,0.3
        C402.9,173.2,402.8,173.2,402.6,173.2 M40.1,173.6c0.1,0.2,0.6,0.2,0.5-0.1C40.5,173.3,40.2,173.4,40.1,173.6 M474.1,173.5
        c0,0.2,0.3,0.2,0.4,0.1C474.6,173.4,474.2,173.4,474.1,173.5 M474.8,173.5C474.6,174,475.3,173.5,474.8,173.5 M126.6,174.5
        c0.1-0.2,0.2-0.5-0.1-0.5C126.2,174,126.4,174.5,126.6,174.5 M126.8,174.1C126.8,174.2,126.8,174.2,126.8,174.1 M368,174.1
        C368.1,174.2,368.1,174.2,368,174.1 M59,174.4c0.1,0.2,0.2,0.1,0.3,0C59.3,174.2,59.2,174.2,59,174.4 M110.5,174.2
        C110.6,174.3,110.6,174.3,110.5,174.2 M455.1,174.2c-0.1,0.2,0,0.3,0.2,0.2C455.4,174.2,455.3,174.1,455.1,174.2 M455.5,174.3
        c-0.1,0.2,0,0.3,0.2,0.2C455.7,174.3,455.7,174.2,455.5,174.3 M57.5,174.5C57.5,174.9,57.9,174.3,57.5,174.5 M111.3,175.2
        c0.2,0.1,0.4,0.3,0.6,0.4c0-0.2,0.1-0.4,0.1-0.6C111.8,174.8,111.5,175,111.3,175.2 M279.7,175.6c0.2,0.1,0.5-0.4,0.2-0.5
        C279.8,175.2,279.7,175.4,279.7,175.6 M340.4,175.5C340.2,175.9,340.9,175.6,340.4,175.5 M335.2,175.6c0,0.1,0,0.2,0,0.2l0.1,0
        c0-0.1,0-0.2,0-0.2L335.2,175.6 M444.7,175.6C444.8,175.7,444.8,175.7,444.7,175.6 M38.5,176.2c0.2-0.1,0.2-0.2,0-0.3
        C38.3,176,38.3,176.1,38.5,176.2 M476.2,176.2c0.2-0.1,0.2-0.2,0-0.3C476,176,476,176.1,476.2,176.2 M71.4,176.1
        C71.2,176.5,71.9,176.1,71.4,176.1 M403.3,176.1C403.4,176.5,403.8,175.9,403.3,176.1 M443.2,176.1
        C443,176.5,443.7,176.1,443.2,176.1 M48,176.4c-0.1,0.2,0,0.3,0.3,0.2C48.3,176.4,48.2,176.3,48,176.4 M194.9,176.4
        c-0.1,0.2,0.1,0.4,0.2,0.4C195.4,176.7,195.1,176.2,194.9,176.4 M466.5,176.4c-0.1,0.2,0,0.3,0.3,0.2
        C466.8,176.4,466.8,176.3,466.5,176.4 M47.4,176.6c-0.2,0.1-0.1,0.4,0.1,0.3C47.7,176.8,47.6,176.5,47.4,176.6 M467.2,176.6
        c-0.1,0.2,0.1,0.4,0.3,0.2C467.6,176.7,467.3,176.5,467.2,176.6 M476.1,177.1c0.1,0.2,0.2,0.2,0.4,0
        C476.4,176.8,476.2,176.8,476.1,177.1 M279.8,177.2c-0.2,0-0.3,0.5,0,0.3C280,177.5,280.1,177.1,279.8,177.2 M339.8,177.5
        c0-0.1-0.1-0.2-0.1-0.3C339.5,177.1,339.6,177.6,339.8,177.5 M126,177.4C125.8,177.8,126.5,177.4,126,177.4 M165,177.3
        c0.1,0.3,0,0.6-0.1,0.8c0.1,0.3,0.2,0.6-0.1,0.9c0.2,0.4,0.1,0.9,0.1,1.4c0,0.7,0.1,1.4,0,2.1c0,0.3-0.1,0.6,0,0.8
        c-0.5,0.2,0,0.7,0,1c-0.1,0.4,0,0.8,0,1.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,1.1-0.2,2.1,0,3.2c0.1,0.2,0,0.5-0.1,0.7
        c0.1,0.3,0.2,0.6,0.3,1c0,0.7-0.1,1.3,0.2,1.9c-0.3,0.4,0,0.8,0.1,1.2c-0.1,0.3-0.3,0.7-0.1,1c0.1,0.6,0.5,1.1,0.3,1.7
        c0.2,0.4,0.2,0.8,0.3,1.3c0,0.2-0.2,0.5,0,0.7c0.2,0.2,0.3,0.5,0.3,0.8c0.2,0.2,0.4,0.5,0.8,0.4c0,0.1,0,0.2,0,0.3
        c0.2-0.2,0.5-0.2,0.6-0.4c0.4,0.3,0.9,0.5,1.3,0.6c0.3,0.7,1.1-0.1,1.4,0.4c-0.3,0-0.4,0.1-0.4,0.3c0.3-0.1,0.6-0.2,0.9-0.3l0,0.2
        c0.1,0,0.4,0.1,0.5,0.1c-0.1,0.1-0.3,0.2-0.5,0.3c0,0,0.1,0.1,0.1,0.1c0.3-0.2,0.6-0.4,1-0.3l0,0.1c0.1,0,0.2,0,0.3,0
        c-0.1,0.1-0.3,0.4,0.1,0.4c0.3,0,0.3,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.6,0.2c-0.2-0.3-0.5-0.5-0.6-0.8c0.1-0.1,0.3-0.2,0.4-0.3
        c-0.1,0.1-0.2,0.2-0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0-0.1,0-0.3,0-0.4c0.2,0.2,0.4,0.4,0.6,0.6c-0.1,0-0.3,0.1-0.4,0.1
        c0.3,0,0.5,0.2,0.7,0.3c0-0.1-0.1-0.3-0.1-0.5c0.2,0.1,0.4,0.2,0.5,0.4c0.1-0.1,0.3-0.1,0.4,0l0-0.1c0.2,0.2,0.3,0.4,0.5,0.6
        c0,0,0.1,0,0.2,0l0,0.3c0-0.1,0.1-0.2,0.2-0.3c0.2,0.1,0.6,0.3,0.6-0.1c0.1,0.1,0.2,0.1,0.3,0.2c-0.2,0.1-0.3,0.3-0.5,0.4
        c0.3,0.1,0.6,0.2,0.9,0.3l-0.1,0.2c0.2,0,0.4-0.2,0.6-0.3c0,0.2,0.1,0.4,0.1,0.6c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.1-0.3
        c0.3-0.2,0.4,0.2,0.4,0.4c0.1,0,0.2-0.1,0.4-0.1c0,0.1,0,0.2,0,0.3c0.4,0.1,0.8,0.3,0.8,0.8c0.1-0.1,0.2-0.2,0.2-0.2
        c-0.6,0,0-0.6,0.1-0.1c0.1-0.1,0.3-0.1,0.4-0.1c-0.1,0.1-0.2,0.3-0.3,0.4c0.1,0,0.4,0,0.6-0.1c-0.1,0.1-0.2,0.2-0.2,0.2
        c0.2,0,0.4,0,0.6,0c0,0.1-0.1,0.3-0.1,0.3c0.2,0,0.4-0.1,0.4-0.4c0.1,0,0.2,0,0.3,0c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0-0.2,0-0.2,0
        l-0.2-0.2c-0.1,0.2-0.4,0.5,0,0.7c0-0.2,0.2-0.3,0.4-0.4c0,0.1,0,0.3,0,0.4c0.1,0,0.2-0.1,0.2-0.2c0,0,0.1,0.1,0.2,0.2
        c0.2,0.1,0.6,0.1,0.5,0.5c0.2-0.1,0.4-0.1,0.5,0c0.1-0.1,0.2-0.1,0.3-0.2c0,0.2,0,0.4,0,0.7c0.1,0,0.3-0.1,0.3-0.1
        c0,0,0.1,0.1,0.2,0.1l0.1,0c0.1-0.1,0.2,0.2,0.2,0.3c0,0-0.1,0.1-0.1,0.2c0.4,0,0.6,0.1,0.8,0.4c0.4,0.1,0.7,0.2,0.7,0.7
        c0.2-0.3,0.9-0.2,0.6,0.2c0.3-0.1,0.5,0.2,0.8,0.3c0,0.1,0,0.2,0,0.2c0.2,0,0.4,0.1,0.4,0.3c0.2-0.2,0.2-0.4,0.1-0.7
        c0.3,0.1,0.1,0.5,0.2,0.7c0.5-0.3,0.6,0.7,1.1,0.6c-0.1,0.1-0.3,0.2-0.4,0.3c0.2,0,0.4-0.1,0.5-0.1l0,0.3c0.2-0.1,0.2-0.3,0.3-0.5
        c0,0.4,0.1,0.8,0.6,0.7c-0.1,0.1-0.2,0.3-0.2,0.4c0.1-0.1,0.2-0.2,0.3-0.2c0.4,0.3,0.7,0.6,1.2,0.9c0.1,0,0.3,0,0.4,0
        c-0.1,0-0.3,0.1-0.3,0.1c0.1,0.1,0.2,0.2,0.2,0.3c0.2-0.1,0.3-0.2,0.5-0.2c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0.1,0,0.1
        c-0.1-0.1-0.3-0.1-0.4-0.2c0,0.1,0,0.4,0,0.6c-0.1-0.1-0.3-0.2-0.4-0.3c0,0.1-0.3,0.3,0,0.3c0.4,0,0.5,0.4,0.7,0.7
        c0.1-0.3-0.5-0.8,0-0.9c0,0.1,0.1,0.2,0.2,0.2l0.3-0.1c-0.1,0.2-0.3,0.4-0.3,0.7c0.2-0.1,0.2-0.3,0.2-0.5c0.2,0.1,0.3,0.3,0.4,0.4
        c0-0.1,0-0.3,0.1-0.4c0.1,0.3-0.3,0.9,0.2,0.8c0.1,0.2,0.3,0.5,0.6,0.5c0,0.2,0,0.4,0,0.6c0.1-0.1,0.2-0.3,0.2-0.5
        c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.1,0.2,0.2,0.4,0.3l0.1-0.3c0,0-0.1,0-0.2,0c0,0,0.1-0.1,0.2-0.2c0,0.2,0,0.5,0.1,0.7
        c-0.2,0.1-0.4,0-0.5-0.1c0.1,0.2,0.3,0.3,0.6,0.3c0.3,0,0.2,0.4,0.4,0.5c0-0.2-0.1-0.3-0.1-0.5c0.2,0.1,0.4,0.2,0.6,0.2l0.1,0
        c0.1,0.1,0.3,0.1,0.5,0.2c0,0,0,0.1,0,0.1l-0.3,0c0.1,0.2,0.3,0.3,0.6,0.4c0,0,0.1,0.1,0.2,0.2c-0.1,0-0.4-0.1-0.5-0.1
        c0,0.4,0.5,0.4,0.7,0.6c0-0.1,0-0.3,0-0.4c0.1,0.1,0.3,0.3,0.4,0.4c-0.1-0.1-0.3-0.2-0.3-0.2c-0.1,0.4,0.3,0.6,0.6,0.5
        c-0.5,0-0.2,0.8,0.2,0.6c0-0.1,0-0.2,0.1-0.3c0,0.1,0,0.3,0,0.4c0.2,0.1,0.3,0.2,0.4,0.4c0.2,0.1,0.5,0.3,0.4,0.6
        c-0.2,0-0.2-0.5-0.5-0.4c0.1,0.3,0.4,0.5,0.3,0.8c0.1,0.2,0.1,0.4,0.2,0.6c0-0.3,0.2-0.6-0.1-0.8c0.2-0.1,0.4-0.2,0.6-0.1
        c0,0.1-0.1,0.3-0.1,0.4c0.2,0.1,0.5,0.1,0.6,0.4c-0.3,0-0.5-0.2-0.7-0.4l0,0.3l0.2,0c0,0.1,0,0.3,0,0.4c0-0.1,0.1-0.2,0.1-0.2
        c0.2,0.1,0.4,0.2,0.6,0.3c0.3,0.1-0.2,0.2-0.3,0.2c0.3,0.3,0.9,0.3,1,0.8c0,0.2,0.3,0.2,0.4,0.4c-0.1,0.1-0.1,0.1-0.2,0.2l-0.1-0.2
        c-0.2,0.1-0.3,0.2-0.5,0.3c0.2,0.1,0.4,0.2,0.6,0.3c0-0.1,0-0.2,0-0.3c0.2,0.1,0.5,0.2,0.6,0.4c-0.1,0-0.3,0.1-0.4,0.1
        c0.1,0.1,0.3,0.2,0.3,0.2c0,0.2,0,0.4,0,0.6c0.3-0.1,0.3-0.3,0.2-0.6c0.1,0.1,0.1,0.3,0.2,0.4c0.2,0.1,0.3,0.3,0.4,0.4
        c-0.1,0-0.4-0.1-0.5-0.1c0,0.8,0.9,0.8,1.3,1.3c-0.3,0.1-0.4,0.3-0.4,0.6l-0.1,0l0,0.2l-0.2-0.1c0,0,0,0.1,0,0.1c0.1,0,0.2,0,0.3,0
        l0.1,0.1c0,0,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0,0.1,0.1,0.3,0.1,0.3l0.3,0c-0.2-0.2,0.1-0.5,0.1-0.7l0.1,0.1
        c0,0.1,0,0.2,0.1,0.2c0.3,0.2-0.6,0.3-0.2,0.6c0.2-0.1,0.4-0.2,0.6-0.2c0,0.2,0.3,0.4,0,0.5v0.1c-0.1,0-0.2,0-0.3,0
        c0,0.1,0.1,0.4,0.2,0.6c0.1-0.1,0.3-0.1,0.4-0.2c-0.1,0.5,0.2,0.9,0.5,1.3c0,0.1-0.1,0.3-0.1,0.4c0.2-0.1,0.4-0.4,0.2-0.6
        c0.2,0.1,0.4,0.3,0.3,0.6c-0.1,0.1-0.3,0.2-0.4,0.3c0.1,0,0.3,0,0.4,0c0.1-0.2,0.1-0.3,0.3-0.4c-0.1,0.3-0.4,0.7,0,0.8
        c0.1-0.1,0.2-0.2,0.2-0.2c-0.1,0.2-0.1,0.4-0.2,0.5l0.4-0.2l-0.1,0.3c0.1-0.1,0.3-0.1,0.4-0.2c-0.1,0.2-0.2,0.3-0.3,0.5
        c0.2,0,0.5,0,0.7-0.1c-0.1-0.5,0.7-0.2,0.8-0.6c-0.1,0-0.2,0-0.3,0l0-0.1c0.3,0,0.8,0.2,0.7-0.3c0.2,0,0.3,0.1,0.5,0.1
        c0,0,0-0.1,0-0.2c-0.1,0-0.3,0-0.4,0c0.1-0.1,0.4-0.3,0.5-0.4l0,0.3c0.1-0.1,0.3-0.2,0.2-0.3c0.3-0.1,0.6-0.2,0.9,0
        c0.2,0,0.3-0.1,0.5-0.1c0.1-0.1,0.1-0.2,0.2-0.3c-0.1-0.1-0.2-0.2-0.4-0.4c0.3,0.1,0.6-0.1,1,0c0.1-0.2,0.2-0.3,0.4-0.5
        c0.1,0,0.3,0,0.5,0.1l-0.1-0.2c0.3,0,0.7,0,0.3-0.3c0.1,0,0.2,0,0.2-0.1l0,0.1c0.2,0.2,0.4,0.3,0.6,0.5l0-0.3
        c0.2,0.1,0.6,0.1,0.4,0.5c0.2,0,0.4,0,0.6,0c0.1,0.2,0.2,0.4,0.4,0.6c0.2,0.3,0.8,0.2,0.7,0.7c0.2-0.1,0.3,0,0.4,0.2
        c0.2,0.1,0.6,0.5,0.7,0.2c-0.1,0.1-0.1,0.3-0.3,0.4l0,0.1c0.2,0.1,0.4,0.2,0.7,0.1l0,0.1l-0.2,0l0,0.2c0.2,0,0.4,0.1,0.6,0.3
        c0,0,0,0.1,0,0.2c0.1,0,0.2,0.1,0.3,0.1c-0.1,0-0.3,0.1-0.5,0.1c0.2,0.1,0.4,0.4,0.7,0.4c0,0.1,0,0.3,0,0.5c0.1,0,0.3,0,0.4-0.1
        c0,0.1,0.1,0.4,0.1,0.5l-0.3-0.1c0,0,0,0.1,0,0.2c0.2,0,0.4,0,0.6,0c-0.2,0.3,0.3,0.5,0.4,0.8c0,0.1,0,0.2,0.1,0.3
        c0.1,0,0.3,0,0.4-0.1c0.1,0.1,0.3,0.3,0.4,0.4c0.2,0.3,0.3,0.6,0.5,0.9c0.1,0,0.3,0,0.4,0c0.1,0.5,0.7,0.6,0.9,1.1
        c0,0.1,0,0.2,0,0.2c0.3,0,0.6,0.2,0.9,0.4c0,0.1,0.1,0.2,0.1,0.3c0.4,0.1,0.8,0.3,1,0.8c0.1,0,0.3-0.1,0.3-0.2l0,0.2
        c-0.1,0-0.2,0.1-0.3,0.1l0,0.2c0.1,0,0.3-0.1,0.4-0.2l-0.1,0.2c0.3,0,0.4,0.3,0.7,0.3c-0.2-0.2-0.4-0.4-0.7-0.5
        c0.4-0.2,0.9,0.3,0.7,0.7l0.3,0c0-0.1,0-0.2,0-0.3c0.1,0,0.2,0,0.3,0c-0.1,0.1-0.2,0.3-0.3,0.4c0.1,0,0.3-0.1,0.4-0.1
        c0.1,0.1,0.2,0.3,0.3,0.4l0-0.3c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.4,0.1,0.6,0.2c-0.5,0.1-0.1,0.3,0,0.5c0.2,0.2,0.5,0.2,0.7,0.4
        c0.3-0.2,0.7-0.2,1,0.1l0-0.3c0.1,0,0.3,0,0.4,0.1l-0.2,0l0,0.2c0.4-0.3,0.7,0.2,1,0.1l0.1-0.1c0,0,0,0.1,0,0.2
        c0.3-0.1,0.5,0.2,0.7,0.4c0.2,0,0.5-0.1,0.6,0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.2,0.7-0.7,0.4-0.9,0.2c0.1,0.1,0.2,0.3,0.3,0.4
        c0.2,0,0.3,0.1,0.4,0.1c0.2-0.2,0.5,0,0.8-0.1c0,0.1-0.1,0.2-0.2,0.3c0.1,0,0.2,0,0.3-0.1l0,0.3c0.1-0.1,0.2-0.2,0.3-0.3
        c0.2,0,0.5-0.1,0.6-0.1c-0.1,0.2-0.3,0.4-0.4,0.6c0.1-0.1,0.3-0.1,0.4-0.2c0.1,0.3,0.3,0,0.4-0.1c0.2,0.2,0.4,0.2,0.6,0.2
        c0,0.1-0.1,0.3-0.1,0.4c0.1-0.2,0.3-0.4,0.5-0.5c-0.1,0.3,0,0.5,0.3,0.6c0-0.1,0-0.3,0-0.5c0.1,0.1,0.2,0.2,0.2,0.4l0.2,0l0-0.2
        c0,0,0.1,0,0.2,0c0,0.1-0.2,0.2-0.2,0.3c0.3,0,0.6-0.1,0.8,0.2c0.2-0.1,0.3-0.2,0.5-0.3c-0.1,0.1-0.2,0.4-0.3,0.5
        c0.3-0.1,0.6-0.2,0.8-0.4c-0.1,0.1-0.2,0.3-0.3,0.4c0.2-0.1,0.4-0.2,0.7-0.2l0-0.1c0,0,0.1,0,0.1,0l0,0.2c0.6,0,1.2,0.4,1.8,0.4
        c0.2,0,0.5-0.1,0.6,0.1c0.2,0,0.4,0,0.6,0l0,0.1c0.3,0,0.7,0,1.1,0c-1,0.2-2,0-3,0.3c-0.5,0.1-1,0-1.4,0.3c-0.4,0.2-0.9,0.1-1.3,0.2
        c-0.4,0.1-0.9,0.1-1.3,0.2c-0.2,0.3-0.5,0.5-0.8,0.6c0-0.3,0.3-0.4,0.4-0.5c-0.2,0.1-0.3,0.1-0.5,0.2c0,0.1,0,0.2,0,0.2l-0.3,0
        l0,0.2c0.1,0,0.2,0,0.3,0c-0.1,0.1-0.2,0.2-0.3,0.3l-0.1-0.3c-0.2,0.1-0.4,0.3-0.2,0.5c-0.2,0.2-0.3,0.4-0.3,0.6c-0.1,0-0.2,0-0.3,0
        c0,0,0.1-0.1,0.2-0.1c0.1-0.2,0.1-0.3,0.2-0.5c-0.1,0-0.3-0.1-0.4-0.2c0,0.1,0.1,0.3,0.1,0.3c-0.2,0-0.5,0.1-0.4,0.4
        c-0.1,0-0.3,0-0.4,0c0.1,0,0.2,0.1,0.3,0.1c-0.4,0.3-0.6,0.8-1,1c0,0.1,0.1,0.2,0.2,0.2c-0.3,0.1-0.5,0.2-0.7,0.3
        c0.1,0.3,0.4,0,0.6,0.1c-0.3,0.2-0.7,0.6-0.3,0.8c-0.3,0.1-0.5,0-0.6-0.3c-0.1,0.2-0.2,0.4-0.3,0.6c0.1,0,0.3,0.1,0.4,0.1
        c0-0.1-0.1-0.3-0.1-0.4c0.3-0.1,0.3,0.1,0.3,0.4c-0.4,0.2-0.3,0.8-0.1,1.2c-0.1,0-0.3-0.1-0.4-0.1c0,0.1,0.1,0.3,0.2,0.4
        c-0.3,0.2-0.5,0.4-0.7,0.7c0.2-0.1,0.3-0.3,0.5-0.4c0.1,0.2,0.2,0.5,0.2,0.6l-0.3-0.2c0,0.1,0.1,0.3,0.1,0.3l-0.2-0.1
        c0,0.2,0,0.3-0.2,0.4c0.1,0.3,0.2,0.5,0.4,0.8c0.1,0,0.2-0.1,0.3-0.1l0,0.1c-0.1,0-0.2,0-0.2,0c-0.1,0.3-0.2,0.6-0.5,0.8
        c0.3,0,0.5-0.1,0.7-0.3c-0.1,0.3-0.1,0.6-0.2,0.8c-0.3,0-0.4-0.3,0-0.3c0-0.1,0.1-0.2,0.1-0.3c-0.1,0-0.4,0-0.5,0.1
        c0.2,0.4-0.2,0.7-0.1,1c0.2,0,0.2-0.3,0.3-0.5c0,0.2,0.4,0.6-0.1,0.6l-0.1,0.2c0.1,0,0.4-0.1,0.6-0.1c-0.2,0.1-0.4,0.3-0.6,0.4
        c0.2,0.1,0.5,0.2,0.5,0.4c-0.3-0.1-0.5-0.3-0.8-0.4c0.1,0.2,0.1,0.5,0.4,0.5c-0.1,0.3,0.2,0.4,0.3,0.5l-0.3,0l0.1,0.2
        c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.2c-0.1,0.1-0.3,0.3-0.3,0.4c0.1,0,0.3,0.1,0.4,0.1
        c-0.4,0.1-0.1,0.6-0.4,0.7l0-0.2c-0.1,0-0.2,0-0.2,0c0.2,0.2,0.3,0.5,0.6,0.3c-0.1-0.2,0-0.3,0.2-0.4c0.1,0.2,0.1,0.4,0.2,0.7
        c-0.1-0.1-0.2-0.2-0.2-0.3c-0.4,0.2-0.2,0.5-0.2,0.8c0.1,0,0.2,0,0.3,0c0.1,0.5-0.4,0-0.7-0.1c0.1,0.3,0.8,0.6,0.2,0.8l0-0.3
        c-0.2,0.1-0.2,0.2-0.1,0.3c0,0.3,0.1,0.5,0.2,0.7c-0.1,0-0.3,0.1-0.3,0.1c0.1,0.1,0.3,0.2,0.4,0.2c-0.1,0.3-0.2,0.6-0.3,0.8
        c0,0,0.1,0.1,0.1,0.1c-0.1,0.2-0.1,0.3,0,0.5c0.1-0.1,0.2-0.2,0.4-0.4l0.1,0c-0.1,0.2-0.2,0.3-0.3,0.5c0.2,0.1,0.3,0.3,0.5,0.4
        c-0.2-0.1-0.6-0.2-0.7,0.1c0.4,0,0.4,0.4,0.4,0.6c-0.1-0.1-0.3-0.2-0.3-0.2c0,0.2-0.1,0.3-0.1,0.5c0.1-0.1,0.4-0.2,0.5-0.2
        c-0.1,0.3-0.4,0.6-0.7,0.7c0.2,0.2,0.5-0.1,0.7-0.2l0,0.1c-0.1,0.1-0.2,0.3-0.3,0.4c0.1,0,0.3,0,0.4,0c-0.1,0.5-0.9-0.6-0.5,0.2
        c0.1,0.2-0.3,0.4-0.4,0.6c0.1,0,0.3,0,0.4,0l-0.1,0.3l0.1,0.3c0,0-0.1,0-0.2,0.1c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.1,0,0.2,0.1,0.3
        c0.1-0.2,0.2-0.4,0.3-0.5c0-0.1-0.1-0.2-0.1-0.2c0,0,0.1,0,0.2,0c0,0.3,0.3,0.7,0,0.9c0.1,0,0.2,0.1,0.2,0.1c0,0.1-0.1,0.4-0.1,0.5
        c-0.2-0.2-0.2-0.4-0.4-0.6c-0.3,0.5,0.3,0.9-0.2,1.3l0.3-0.2c0-0.1,0-0.2,0-0.3c0,0,0.1,0.1,0.1,0.2c-0.1,0.2-0.3,0.4-0.5,0.5
        c0.1,0.1,0.3,0.2,0.5,0.2c-0.1,0.4-0.1,0.7,0.2,1c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.3-0.1-0.6-0.4-0.8c0,0.3,0.4,0.9-0.1,1.1
        c0,0,0,0.1,0,0.1c0.3-0.1,0.7,0,0.6,0.4c-0.1-0.1-0.2-0.2-0.3-0.3l-0.2,0.1c-0.4,0,0.1,0.2,0,0.4c0,0.2,0.2,0.3,0.2,0.4
        c-0.1,0.2-0.1,0.4-0.1,0.7c-0.2-0.1-0.5-0.2-0.5,0.2c0.1,0,0.3-0.1,0.3-0.2c0,0.1,0,0.4,0,0.5c0.1,0,0.2,0.1,0.3,0.1
        c-0.2,0.1-0.3,0.3-0.5,0.4c0.1,0,0.2,0,0.3,0c0,0.1,0.1,0.4,0.1,0.5c-0.1,0.1-0.3,0.3-0.4,0.4l0.2,0c0,0.2,0.1,0.4,0.2,0.6
        c-0.1,0-0.2,0-0.3-0.1c0,0.1,0.1,0.4,0.1,0.5c0.1-0.1,0.3-0.3,0.4-0.4l0,0.2l-0.2,0c0,0.2,0.1,0.3,0.3,0.4c-0.1,0.1-0.2,0.2-0.2,0.2
        c0,0.1,0.1,0.2,0.2,0.3c-0.1,0-0.2,0-0.2-0.1c0.1-0.2-0.1-0.4-0.3-0.5l-0.1,0.2l0.2,0.1c0,0.1-0.1,0.2-0.1,0.2
        c0.1,0.1,0.6,0.2,0.2,0.4c-0.2,0.1,0.2,0.2,0.3,0.3c-0.1,0-0.3,0-0.4,0c0,0.3-0.1,0.6,0,0.9c0.1-0.1,0.2-0.2,0.3-0.3
        c-0.1-0.2-0.1-0.3-0.1-0.5c0.1,0.2,0.2,0.4,0.2,0.6c-0.3,0.1-0.5,0.3-0.6,0.5l0.3-0.1c-0.1,0.2-0.1,0.4,0,0.6c-0.1,0-0.3,0-0.3,0
        c0.1,0.3,0.3,0.6,0.1,0.9l0.1,0.2c0,0-0.1,0.1-0.2,0.2c0.2,0.3,0,0.7,0,1.1c0.2,0,0.3,0.1,0.4,0.2c-0.2-0.1-0.4-0.1-0.5-0.2
        c0,0.2,0,0.4,0,0.6c-0.2,0.1-0.4,0.2-0.5,0.4c0.3-0.1,0.5-0.2,0.8-0.3c-0.1,0.3-0.3,0.5-0.6,0.6c-0.2,0.2-0.4,0.4-0.5,0.6
        c0.3-0.2,0.6-0.5,1-0.6l-0.2,0.5c0.1,0,0.2,0,0.3,0c-0.2,0.3-0.3,0.7-0.4,1.1c0.1,0.2,0.1,0.4,0.1,0.6c0.1,0,0.2-0.1,0.2-0.1l0.1,0
        c0.1,0.2-0.2,0.3-0.1,0.5c0,0,0.1,0.1,0.2,0.2c-0.1,0-0.3-0.1-0.4-0.2c0,0.4,0.1,0.7,0.4,1c-0.1-0.1-0.2-0.2-0.3-0.2
        c-0.3,0.1-0.1,0.6,0.2,0.6c0,0.2-0.4,0.2-0.3,0.5c0.1,0,0.3,0,0.4,0c-0.1,0-0.2,0.1-0.2,0.1c0,0.2,0,0.4,0,0.5l-0.1-0.1
        c0,0.1,0,0.4,0,0.5c-0.3-0.1-0.3,0.1-0.5,0.3c0,0.2,0,0.4,0.2,0.4c0-0.1,0-0.4,0-0.6c0.1,0,0.3,0,0.4,0c-0.2,0.2-0.1,0.5-0.1,0.8
        c0.1,0,0.2-0.1,0.3-0.1c-0.2,0.1-0.3,0.3-0.2,0.5l-0.2,0c0,0.3,0.2,0.6-0.1,0.8c0.1,0.1,0.2,0.2,0.3,0.4l-0.3-0.1
        c0.1,0.2,0.2,0.4,0.2,0.7c-0.2,0.2-0.1,0.5-0.1,0.8l0.2,0c0,0.3,0,0.6,0,0.9c0-0.1-0.1-0.3-0.2-0.3l0,0c-0.1,0.2-0.1,0.4-0.2,0.6
        l0.1,0c0,0.2,0.1,0.3,0.1,0.5c-0.2,0.3-0.1,0.7,0,1c0.1,0.5,0.1,1,0.2,1.5c-0.1,0.3,0,0.6,0.1,0.9c0,0.5,0.3,0.9,0.1,1.4
        c0.2,0.2,0.2,0.5,0.2,0.7c0.2,0.3,0.2,0.7,0.2,1c0.1,0.4,0.3,0.7,0.3,1.1c0.1,0.2,0.1,0.4,0.1,0.7c0.2,0.2,0.5,0.5,0.3,0.8
        c0.5,0.6,0.5,1.3,0.9,2c0.3,0.8,0.5,1.6,0.7,2.4c0.7,1.1,1,2.4,1.8,3.5c0.6,0.4,0.6,1.2,1.1,1.6c0.3,0.3,0.7,0.6,0.8,1.1
        c0.5,0.5,0.9,1.1,1.4,1.7c0.3,0.5,0.6,1,1,1.4c0.3,0.6,0.9,0.9,1.3,1.4c0.9,1,2.1,1.7,3,2.7c0.3,0.3,0.7,0.4,0.9,0.7
        c0.2,0.2,0.4,0.4,0.7,0.4l-0.1,0.2c0.2-0.1,0.6,0,0.3,0.3c0.1,0,0.3,0,0.4,0c0,0.1,0,0.2-0.1,0.3c0.2,0,0.5-0.1,0.7,0.1
        c0.3,0.2,0.7,0.2,1,0.3c0,0.1,0.1,0.2,0.1,0.3c0.4,0,0.8,0,0.9,0.5c-0.4-0.3-1-0.1-1.4-0.5c-0.1,0-0.2,0-0.3,0
        c-0.4-0.2-1-0.1-1.3-0.4c-0.2-0.1-0.4,0-0.5-0.2c-0.1,0.3-0.6-0.1-0.9-0.1c-0.4-0.1-0.9-0.1-1.2-0.5c-0.1,0-0.2,0-0.3,0.1l0-0.2
        c-0.1,0-0.2,0.1-0.2,0.2c-0.2-0.2-0.5-0.2-0.8-0.2l0-0.2c-0.2,0.1-0.5,0.1-0.7,0c-0.6-0.2-1.2-0.5-1.8-0.7c-0.4-0.1-0.7-0.5-1.2-0.5
        c-0.3,0-0.6-0.6-0.9-0.3c-0.1,0-0.2-0.1-0.2-0.2c0,0.1-0.1,0.2-0.2,0.2c-0.1-0.3-0.2-0.6-0.5-0.7c0,0.2,0.1,0.4,0.2,0.6l0,0
        c-0.2-0.1-0.3-0.3-0.3-0.5c-0.4,0-0.6-0.3-0.7-0.6l-0.3,0.2c0-0.1,0.1-0.3,0.1-0.4c-0.4,0.1-0.7-0.1-1-0.2c0-0.6-0.7-0.8-1.2-0.6
        c0.1-0.1,0.2-0.3,0.3-0.4c-0.5,0.1-0.7-0.3-1.1-0.5c0-0.3-0.2-0.3-0.4-0.3c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.3-0.3-0.3-0.5-0.4
        c-0.1-0.2-0.2-0.4-0.5-0.4c-0.3-0.6-0.9-0.8-1.3-1.3c-0.4-0.4-1-0.6-1.3-1.1c-0.2-0.3-0.6-0.5-0.9-0.8l-0.1,0
        c-0.2,0.4,0.4,0.4,0.6,0.6c-0.4,0-0.7-0.2-0.9-0.5c0.1-0.1,0.2-0.2,0.2-0.4c-0.1-0.3-0.4-0.4-0.7-0.5c-0.2-0.5-0.5-1.1-1.1-1
        c0.1-0.1,0.2-0.3,0.3-0.3c-0.5-0.3-0.7-0.9-1.2-1.1c-0.5-0.8-1.5-1.4-1.5-2.4c-0.5-0.2-0.7,0.4-0.4,0.7c-0.3,0-0.3-0.3-0.4-0.4
        c0.1-0.2,0.2-0.4,0.3-0.5c-0.3-0.2-0.3-0.6-0.6-0.9c-0.5-0.7-0.7-1.5-1.3-2.1c-0.6-0.7-1.1-1.4-1.3-2.3c-0.2-0.2-0.5-0.1-0.7-0.2
        c0,0,0-0.1,0-0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c-0.4-0.1-0.4-0.4-0.5-0.7c-0.2-0.5-0.6-0.8-0.8-1.3
        c0.4-0.3,0-0.5-0.1-0.8c-0.1,0-0.2-0.1-0.3-0.1c0,0,0,0.1-0.1,0.2c0-0.1-0.1-0.2-0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.3
        c-0.5-0.3-1-0.6-1.6-0.7c-0.2-0.2-0.5-0.2-0.7-0.2l0-0.1c-0.3-0.1-0.6-0.3-0.8-0.5c-0.1,0.3-0.3,0.5-0.5,0.7
        c-0.2,0.1-0.3,0.3-0.5,0.4c0.1-0.2,0.2-0.4,0.3-0.5c0-0.1,0-0.3,0-0.4c0,0,0.1,0,0.2,0c0-0.3,0.3-0.5,0.4-0.7c0-0.6,0.3-1.1,0.4-1.6
        c0.1-0.3,0.1-0.7,0.3-1c-0.2-0.5,0-1.1,0.2-1.6c0-0.7,0.3-1.3,0.7-1.8c0-0.3,0.2-0.6,0.4-0.8c0.1-0.7,0.3-1.5,0.7-2
        c0-0.1-0.1-0.3-0.1-0.3c0.2-0.5,0.2-1,0.4-1.5c0.2-0.6,0.1-1.3,0.2-2c0,0,0.1-0.1,0.2-0.2c0-0.1,0-0.3,0-0.4c-0.2-0.3-0.2-0.7,0-1
        c-0.2-0.3,0.1-0.7,0-1c-0.1-0.5,0.1-1-0.1-1.4c0.1-0.4,0.4-0.8,0.2-1.2c0.2,0,0.2-0.1,0.1-0.2c0.1-0.2,0.2-0.5,0.2-0.7
        c-0.1-0.2-0.2-0.4-0.2-0.6c0.1-0.2,0.2-0.4,0.3-0.7c-0.2-0.1-0.3-0.3-0.4-0.4c0.4-0.1,0.2-0.6,0.1-0.9c0.1,0,0.3,0,0.4-0.1
        c-0.1,0-0.3-0.1-0.3-0.1c0-0.2,0.2-0.4,0.3-0.5c-0.1,0-0.4,0.1-0.5,0.1c0.2-0.3,0.8-0.4,0.7-0.9c-0.2,0.1-0.3,0.3-0.5,0.4
        c0.1-0.3-0.1-0.8,0.4-0.7c-0.1-0.3-0.1-0.6-0.3-0.8c0-0.2,0-0.4,0-0.6c0.1-0.2,0.3-0.3,0.3-0.5c-0.1,0.1-0.3,0.2-0.4,0.3
        c0-0.2,0-0.4,0.1-0.6c0.2,0,0.3,0,0.5,0c0,0,0-0.1,0-0.1c-0.1,0-0.3,0.1-0.3,0.1c0.1-0.4,0.1-0.7,0-1.1c0-0.2-0.1-0.5,0.2-0.5
        c-0.1-0.3-0.3-0.5-0.2-0.8c0.1-0.2,0-0.5-0.2-0.4c-0.3,0.1-0.5-0.2-0.6-0.4c-0.3,0-0.4-0.2-0.5-0.4c-0.4-0.1-0.8-0.2-1-0.5
        c-0.1-0.1-0.2-0.3-0.4-0.4c-0.6-0.2-1.2-0.5-1.6-1c-0.4,0-0.7-0.3-1-0.5c-0.6-0.3-0.9-0.8-1.5-1c-0.5-0.4-1.2-0.7-1.7-1.2
        c0-0.1,0-0.3-0.1-0.4c-0.1,0-0.2,0.1-0.3,0.1c-0.1-0.1-0.2-0.2-0.3-0.4c-0.5,0-0.7-0.5-1.1-0.7c-0.1,0.9-0.4,1.9-0.5,2.8
        c0,1-0.3,2-0.3,3c0.1,0.2,0.1,0.3,0,0.5c-0.1,0.4,0.1,0.8-0.1,1.1c-0.1,0.5,0.1,1,0,1.4c-0.1,0.4,0.1,1-0.1,1.4
        c0.1,0.4,0.3,0.9,0.1,1.3c0.1,0.3,0.1,0.6,0.1,0.9c0.1,0,0.2,0,0.2,0c-0.1,0-0.2,0-0.3,0c0,0.1,0,0.4,0,0.6c0.1,0,0.3,0,0.4,0
        c-0.2,0.2-0.4,0.9,0,0.9c-0.1,0-0.2,0-0.3,0l0,0.3c0.1,0,0.3-0.1,0.4-0.1c-0.1,0.1-0.2,0.2-0.3,0.3c0.2,0.1,0.3,0.1,0.5,0.2
        c-0.1,0-0.3-0.1-0.4-0.1c0,0.5,0.2,0.9,0.1,1.4c0,0.1,0.1,0.2,0.1,0.3c0,0.2-0.2,0.6,0.2,0.3c-0.1,0.4-0.1,0.9,0,1.3
        c0.2,0.3,0.2,0.7,0.3,1c0.1,0.3-0.1,0.6,0,0.9c0.1-0.1,0.2-0.1,0.3-0.2c0,0.1-0.1,0.3-0.2,0.4c0,0,0.1,0.1,0.1,0.2
        c-0.3,0.7,0.1,1.4,0.2,2.1c-0.1,0.5-0.2,1.1,0.1,1.5c0-0.1,0.1-0.3,0.2-0.4c0.1,0.5,0,0.9,0.1,1.4c0.1-0.1,0.2-0.2,0.3-0.3
        c0,0.1,0,0.3,0,0.4c-0.1,0-0.4,0-0.5,0c0,0.1,0.1,0.3,0.1,0.5c0.2,0,0.4,0,0.6,0c0,0.1-0.1,0.3-0.1,0.4c0-0.1-0.1-0.2-0.1-0.3
        c-0.1,0-0.2,0-0.3,0.1c0.1,0.2,0.2,0.5,0.4,0.7c-0.1,0.3,0,0.6,0.2,0.9c0,0.1-0.2,0.2-0.2,0.2c0.2,0.2,0.4,0.5,0.7,0.3
        c-0.2,0.2-0.5,0.4-0.5,0.7c0.1,0,0.2-0.1,0.3-0.1l-0.1,0.3c0.1,0,0.3,0,0.4-0.1c-0.3,0.1-0.3,0.5-0.3,0.8c0.1,0,0.3,0,0.4,0
        c0,0,0,0.1,0,0.1c-0.1,0-0.2,0-0.3,0c0,0.7,0.6,1.2,0.6,1.9c0,0.1,0.1,0.2,0.1,0.3l0.1,0c-0.1,0.6,0.1,1.1,0.5,1.5l0,0.1
        c0.1,0,0.2,0.1,0.3,0.1c-0.2,0.2-0.2,0.5-0.3,0.7c0.2,0.2,0.4,0.4,0.6,0.6c0.2,0.1,0.3,0.2,0.5,0.4c-0.2,0.1-0.3-0.1-0.5-0.1
        c0.1,0.2,0.2,0.7,0.5,0.6c0,0,0,0,0,0.1l-0.4-0.1c0.1,0.2,0.3,0.3,0.5,0.5c-0.1,0.1-0.2,0.1-0.3,0.2c0.1,0.1,0.2,0.3,0.3,0.4
        c0.6-0.7-0.2,0.4,0.5,0.3c0,0.1-0.1,0.2-0.1,0.2c0.1,0.2,0.2,0.4,0.3,0.6c-0.2,0.5,0.5,0.6,0.8,0.5c0,0.1,0,0.2,0,0.3l0-0.2
        c-0.2-0.1-0.4-0.1-0.6,0c0.1,0.2,0.3,0.5,0.4,0.7c0.1-0.1,0.2-0.2,0.3-0.3h0.1c-0.1,0.2-0.2,0.4-0.3,0.7c0.4,0.6,0.6,1.2,0.9,1.9
        c0.1-0.1,0.2-0.2,0.3-0.4c0,0.2-0.1,0.4-0.1,0.5c0.2,0.2,0.4,0.4,0.7,0.5c-0.1,0-0.3-0.1-0.4-0.1c0.1,0.3,0.2,0.9,0.6,0.9l0,0
        c-0.1,0-0.3,0-0.3,0c0.2,0.3,0.4,0.6,0.6,0.9c0.1-0.2,0.2-0.3,0.3-0.4c0,0.2-0.1,0.4-0.2,0.5c0,0.2,0.1,0.4,0.3,0.6
        c-0.3,0.4,0.3,0.4,0.5,0.6c0,0.2-0.1,0.4,0.1,0.5c0,0.1-0.1,0.2-0.1,0.2c0.1,0.1,0.2,0.2,0.3,0.4c0.2,0.4,0.6,0.7,0.7,1.2
        c0.3,0.2,0.4,0.6,0.6,0.8c0.1,0.2,0.4,0.3,0.3,0.7c0.1-0.1,0.2-0.1,0.3-0.2c0-0.2,0.1-0.3,0.1-0.5c0,0.1,0,0.4,0,0.6
        c0.1,0,0.3-0.1,0.4-0.1l0,0c-0.2,0-0.4,0.2-0.5,0.4c0.3,0.1,0.4,0.9,0.8,0.6c0,0.1,0,0.2,0,0.3l-0.1-0.2c-0.1,0.1-0.2,0.1-0.3,0.2
        c0.3,0.2,0.5,0.5,0.5,0.8c0.1,0.1,0.2,0.2,0.4,0.2c0,0.1,0.1,0.3,0.1,0.4c0.1-0.2,0.3-0.4,0.5-0.5c0,0.3-0.2,0.5-0.3,0.8
        c0.2,0.3,0.4,0.6,0.7,0.8c0-0.1,0.1-0.2,0.2-0.3c0.1,0.2,0.2,0.4-0.2,0.3c0.1,0.3,0.3,0.5,0.6,0.6c0,0.4,0.4,0.6,0.6,0.8
        c0.2,0,0.3-0.1,0.4,0.1c-0.1,0-0.3,0-0.4,0c0.2,0.4,0.6,0.8,0.9,1.2c0.5,0.6,1,1.3,1.5,1.9c0-0.1,0.1-0.2,0.1-0.3
        c0,0.2,0,0.4-0.1,0.6c0,0.1,0.1,0.2,0.2,0.2c0,0-0.1,0.1-0.2,0.1c0.2,0.2,0.5,0.2,0.8,0.3c0,0.2,0,0.3,0,0.5l-0.1,0.1
        c0.1,0,0.2,0,0.3,0c0.3,0.4,0.8,0.8,0.9,1.4l0.1-0.1l0.1-0.1c0.1,0,0.2,0,0.3,0c-0.1,0-0.2,0.1-0.2,0.1c0,0.1,0.1,0.2,0.2,0.3
        c0.1,0.4,0.4,0.6,0.7,0.9c0.1,0.3,0.3,0.4,0.4,0.7c0.2,0.2,0.3,0.4,0.5,0.6c0.2,0.2,0.2,0.5,0.5,0.7c0.3,0.2,0.5,0.5,0.7,0.8
        c0.2,0.2,0.4,0.4,0.6,0.6c0,0.1,0,0.2,0,0.3c0.5,0.3,0.9,0.7,0.8,1.3c0.2,0,0.3,0.1,0.5,0.1c-0.3-0.4-0.1-1-0.2-1.4l0.1,0
        c0-2.5,0.1-5-0.1-7.5c0,0-0.1-0.1-0.2-0.2c0.1,0,0.2,0,0.4,0.1c-0.1,0.5,0,0.9,0.1,1.4c0.1,0.9,0.1,1.8,0.2,2.7c0,0.3,0.2,0.6,0,0.9
        c0,0.2,0.1,0.3,0.1,0.5c-0.1,0.5,0.2,1,0,1.6c0.2,0.6-0.1,1.4,0.2,2c0.1,0.4,0,0.9,0.3,1.2c0.1-0.1,0.3-0.2,0.4-0.2l0,0.1
        c0,0.1-0.1,0.3-0.1,0.4c0.1,0.1,0.3,0.2,0.4,0.2c-0.1,0-0.3,0-0.4,0.1c0.2,0.2,0.5,0.4,0.7,0.6c0,0.1,0.1,0.3,0.1,0.4
        c0.1,0,0.2-0.1,0.2-0.2c0,0.4,0.3,0.8,0.5,1.2c0.1,0,0.2-0.1,0.2-0.1c0,0.1,0,0.2,0,0.2c0.4,0.3,0.6,0.7,1,1.1
        c0,0.1,0.1,0.2,0.1,0.3c0.3,0.3,0.5,0.7,0.7,1.1c0.6,0.2,0.8,0.9,1.3,1.2c0.2,0,0.4-0.1,0.5,0.1c-0.1,0-0.4,0-0.5,0
        c0.2,0.2,0.3,0.4,0.4,0.7c0.2,0.2,0.3,0.4,0.5,0.7c0.3,0.2,0.6,0.4,0.6,0.7c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0.7,0.8,0.9,1.1,1.4
        c0.2,0.3,0.6,0.4,0.6,0.8c0.3,0.3,0.6,0.4,0.8,0.8c0.2-0.1,0.2-0.2,0.2-0.4c0,0.3,0.2,0.3,0.4,0.3c0,0,0,0.1,0,0.2
        c-0.3,0-0.6,0-0.8-0.1c0.1,0.1,0.2,0.4,0.2,0.5c0.6,0.4,1,0.9,1.6,1.3c-0.4,0.4,0.3,0.8,0.5,0.3c0,0.1-0.1,0.2-0.1,0.3
        c0.3,0.2,0.4,0.6,0.5,1c0.2,0.1,0.6,0.4,0.8,0.1c-0.3,0.4,0.1,0.7,0.3,1c0,0.1,0.1,0.4,0.1,0.5c0.2-0.2,0.4-0.5,0.4-0.8
        c0,0.1,0.1,0.2,0.1,0.3c-0.1,0.2-0.2,0.3-0.3,0.5c0.1,0,0.3,0.1,0.4,0.1c0,0.2,0,0.5,0,0.7c0.3,0.2,0.6,0.3,1,0.3
        c-0.1,0-0.2,0.1-0.3,0.2c0.1,0.2,0.3,0.3,0.5,0.2c0,0,0,0.1,0,0.2l-0.3,0c0.1,0.1,0.3,0.3,0.4,0.4c-0.1,0.4,0.2,0.7,0.6,0.8
        c0.2,0.2,0.6,0.3,0.8,0.5c-0.1,0-0.2,0-0.3,0c-0.3-0.1-0.1,0.3,0.1,0.4c0.2,0.3,0.5,0.6,0.7,0.9c-0.1,0-0.2,0.1-0.3,0.1l0.2,0
        c0.2,0.5,0.9,0.7,0.9,1.3c0.1-0.1,0.2-0.2,0.4-0.2c-0.1,0-0.2,0.1-0.2,0.2c0.1,0.1,0.2,0.3,0.3,0.4c0.4,0.5,0.5,1.1,1,1.6
        c0.5,0.7,0.9,1.4,1.2,2.2c0.1-0.3,0.5-0.6,0.1-0.8c0.4,0.1,0.1,0.5,0,0.7c0.2,0,0.3-0.1,0.5,0c-0.2,0.1-0.3,0.2-0.5,0.3
        c0.2,0.6,1,1,0.8,1.7c0.4,0.5,0.5,1.1,0.9,1.6c0.2,0.3,0.5,0.4,0.5,0.8l0.2,0c0-0.1,0-0.3,0-0.4c0.1,0.2,0.1,0.4,0.1,0.6
        c-0.1,0-0.3-0.1-0.4-0.1c0.3,0.6,0.4,1.4,0.9,1.9c0.1-0.1,0.2-0.2,0.3-0.3c0,0.1-0.1,0.3-0.1,0.4c0.2,0.4,0.5,0.7,0.9,0.8
        c-0.3-0.1-0.5-0.2-0.7-0.2c0.1,0.3,0.2,0.5,0.5,0.7c0-0.1,0.1-0.2,0.1-0.3c0,0.4,0,0.7,0,1.1c0.3,0.4,0.8,0.8,0.8,1.3
        c-0.1,0.3-0.3,0.7,0.2,0.8c0-0.1,0-0.4,0.1-0.6c0.1,0.1,0.1,0.1,0.2,0.2c-0.5,0.4,0.1,0.7,0.3,1c-0.2,0.4,0.3,0.6,0.2,1
        c0.1,0,0.2,0.1,0.3,0.1l0.1-0.2c0,0,0.1,0,0.2,0.1c-0.2,0.1-0.5,0.3-0.4,0.6c0.4,0.5,0.2,1.2,0.7,1.6c0.1,0.4,0.1,1.2,0.6,1.3
        c0,0.1-0.1,0.3-0.2,0.4c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.1,0.2c0.1,0.5,0.3,1,0.5,1.5c0.1-0.1,0.3-0.1,0.4-0.2
        c-0.2,0.2-0.3,0.4-0.5,0.6c0.4,0.3,0.5,0.8,0.4,1.2l0.3-0.1c0,0.1-0.1,0.3-0.1,0.3c0.4-0.1,0.3,0.3,0.2,0.6c0.1,0,0.2,0,0.3,0
        c-0.1,0.2-0.2,0.4-0.3,0.6c0.2,0.4,0.1,1.2,0.7,1.2c-0.1,0.2-0.2,0.4-0.3,0.6c0.2,0.3,0.4,0.7,0.5,1.1c0.1,0,0.2,0,0.4,0.1
        c-0.1,0-0.3,0-0.4,0c0.1,0.2,0.1,0.4,0.1,0.7c0.1,0.2,0.1,0.5,0,0.7c0.2,0.2,0.3,0.5,0.3,0.8l0.3-0.1c-0.1,0.2-0.2,0.3-0.3,0.5
        c0.1,0,0.2,0.1,0.3,0.1c0.1,0.6,0,1.2,0.1,1.8c0.3,0.3,0.3,0.7,0.3,1.1c-0.2,0.3,0,0.7,0,1c-0.1,0.6,0.3,1.1,0.3,1.7
        c0,0,0.1-0.1,0.2-0.1c-0.3-0.6,0.4-1.1,0.4-1.7c-0.3-1,0.7-1.9,0.5-2.9c0.1-0.2,0.2-0.3,0.3-0.4c0-0.8,0.3-1.5,0.5-2.2
        c0.1-0.3-0.1-0.5,0.1-0.8c0.2-0.3,0.2-0.7,0.3-1.1c0-0.9-0.4-1.8-0.3-2.7c-0.2-0.4-0.3-0.7-0.2-1.2c-0.4-0.4-0.2-0.9,0-1.4
        c-0.2-0.1-0.4-0.2-0.4-0.4c-0.2-0.4-0.5-1,0-1.4c-0.2-0.3-0.4-0.4-0.5-0.7c0-0.2,0.1-0.3,0.2-0.5c-0.1-0.2-0.4-0.5-0.3-0.8
        c-0.3-0.4-0.4-0.8-0.5-1.3c0-0.4-0.4-0.7-0.3-1.1c-0.7-0.9-0.6-2.1-1.1-3c0-0.8-0.6-1.4-0.6-2.1c0,0-0.1-0.1-0.2-0.2
        c-0.1-0.3,0-0.6-0.2-0.8c-0.2-0.2-0.3-0.4-0.4-0.6c0-0.2,0.1-0.5-0.2-0.6c-0.3-0.3-0.2-0.7-0.5-1c-0.2-0.3-0.3-0.6-0.3-1
        c-0.4-0.3-0.3-0.9-0.7-1.2c-0.1-0.3-0.2-0.6-0.3-0.9c-0.2-0.3-0.1-0.6-0.3-0.8c-0.1-0.1-0.2-0.2-0.3-0.3c0.5-0.2-0.1-0.4-0.2-0.6
        c-0.2-0.5-0.5-1-0.6-1.5c-0.1,0-0.3,0-0.4,0c0-0.1,0.1-0.2,0.1-0.3c-0.3-0.3-0.5-0.7-0.5-1.1c-0.3-0.4-0.5-0.7-0.7-1.2
        c-0.3-0.5-0.3-1.1-0.8-1.5c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1,0-0.2-0.1-0.2-0.1c-0.1-0.5-0.4-0.9-0.7-1.3c0-0.1,0-0.4,0-0.5
        c-0.1,0-0.2,0-0.3,0c0.1-0.2,0.1-0.4-0.1-0.5c-0.2-0.5-0.5-1-0.7-1.6c-0.2-0.4-0.4-0.7-0.5-1.1c-0.2-0.3-0.4-0.6-0.6-0.9
        c0-0.1,0.1-0.2,0.2-0.3c-0.3-0.1-0.5-0.3-0.6-0.6c-0.3-0.7-0.4-1.4-0.7-2c0.1-0.6-0.5-0.9-0.5-1.4c0-0.5-0.6-0.9-0.5-1.4
        c-0.5-0.5-0.1-1.2-0.5-1.7c0-0.6-0.1-1.3,0.1-1.9c-0.2-1.1-0.2-2.2-0.2-3.2c-0.3-1.4-0.2-2.8-0.5-4.1c0.1,0.1,0.2,0.3,0.2,0.4
        c0.3,0.8,0.4,1.6,0.8,2.3c-0.1,0.1-0.2,0.1-0.4,0.2c0.1,0,0.3,0,0.4,0l-0.1,0.2c0.2,0.2,0.6,0.5,0.4,0.9l0.2,0c0,0.2,0,0.4,0.1,0.5
        c0-0.1,0-0.3,0-0.4c0.3,0.2,0.6,0.4,0.4,0.8l0.2-0.1c0,0.1-0.1,0.2-0.2,0.3c0.3-0.2,0.4,0.2,0.6,0.4c-0.1,0-0.2,0-0.3,0l0,0.4
        c0.4-0.3,0.3,0.4,0.6,0.4c0,0.1,0,0.2,0,0.3c0.1,0.1,0.3,0.3,0.4,0.4c-0.1,0-0.3-0.1-0.4-0.1c-0.2,0.3-0.3,0.6-0.3,1
        c0.1-0.2,0-0.4,0.2-0.4c0.2,0,0.4,0,0.5,0c0.1,0.1,0.2,0.2,0.3,0.3c-0.4,0.2-0.1,0.5,0.2,0.4c0.1,0,0.2,0.1,0.3,0.2
        c-0.1,0-0.2,0-0.3,0.1c0.1,0.2,0.2,0.4,0.4,0.5c-0.1,0-0.4,0-0.5,0c0.3,0.2,0.6,0.4,1,0.4c-0.1,0.1-0.2,0.1-0.3,0.2
        c-0.1-0.1-0.3-0.1-0.4-0.2c0,0.5,0.4,0.6,0.7,0.3c0,0.1,0,0.3,0,0.4c-0.2-0.2-0.4-0.2-0.7-0.2l-0.1,0c-0.2,0.1-0.6,0.5-0.3,0.7
        c0-0.3,0.2-0.4,0.4-0.6c0.1,0.1,0.3,0.2,0.5,0.3c0.3,0.2,0.3,0.7,0.7,0.8c-0.1,0-0.2,0-0.3,0c0.1,0.3-0.5,0.8,0,1
        c-0.1-0.4,0.2-0.3,0.5-0.3c-0.1-0.1-0.2-0.3-0.3-0.4c0.1,0.1,0.3,0.3,0.4,0.4c-0.2,0.1-0.3,0.2-0.4,0.3c0.1,0.1,0.2,0.4,0.2,0.5
        c-0.3-0.1-0.6-0.2-0.8-0.3c0,0.3-0.1,0.5-0.1,0.8c0.2-0.1,0.2-0.4,0.3-0.6c0.2,0,0.5,0.1,0.7,0.1c-0.1,0.1-0.3,0.2-0.4,0.3
        c0.1,0,0.2,0.1,0.3,0.2l0-0.3c0.1,0,0.2,0.1,0.3,0.1c-0.2,0.3-0.1,0.6-0.1,0.9l0,0c0.1-0.3,0.4-0.1,0.6,0c0.1,0.2,0.3,0.4,0.1,0.6
        l0.2,0.1c0,0.1-0.1,0.2-0.1,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0,0.2-0.1,0.3-0.1l0,0.2c0.3,0,0.2,0.3,0.3,0.5
        c-0.1,0.2-0.2,0.5-0.3,0.7c0.1,0,0.3,0,0.4,0.1l0-0.2c0.1,0,0.2,0.1,0.2,0.2c-0.1,0.1-0.2,0.2-0.4,0.3l0.3,0.1c0,0.1,0,0.3,0.1,0.4
        c-0.3,0-0.4-0.1-0.4-0.4c-0.1,0.4,0.2,0.8,0.3,1.3c-0.1,0.1-0.2,0.2-0.3,0.2l0.2-0.1c0,0.2-0.1,0.4-0.1,0.5c0.1,0,0.2,0,0.2,0
        c0,0-0.1,0-0.2-0.1c0.1-0.2,0.1-0.4,0.3-0.6c0.3,0.1,0.3,0.5,0.4,0.7c0.1,0,0.2,0.1,0.3,0.2c0.1-0.1,0.2-0.1,0.4-0.1
        c0-0.1,0.1-0.2,0.1-0.3c-0.1,0.2,0,0.4,0.1,0.6c0.2,0.1,0.2,0.5,0.3,0.7c-0.2,0-0.4-0.1-0.6-0.1c0.1-0.1,0.2-0.1,0.3-0.2
        c-0.1,0-0.3-0.1-0.4-0.1c0,0.2,0,0.6,0.4,0.6c0.1,0.1,0.2,0.3,0.3,0.4c0-0.2,0-0.3,0.1-0.5c0.1,0.1,0.2,0.2,0.3,0.2
        c-0.1,0.3-0.2,0.5-0.3,0.7c0.2,0,0.3-0.1,0.5-0.2c-0.1,0.1-0.1,0.3-0.2,0.4l0.2,0c0,0.1-0.1,0.4-0.2,0.5c0,0.2,0.1,0.5,0.2,0.7
        c0.1,0,0.2,0,0.3,0c0,0.2,0.1,0.3,0.3,0.5c0,0.1,0,0.2-0.2,0.2c-0.1-0.2-0.2-0.3-0.4-0.5c0,0.1-0.2,0.2,0,0.3
        c0.2,0.1,0.3,0.4,0.5,0.2c0,0.1,0,0.2,0.1,0.2c0-0.1,0.1-0.3,0.2-0.4c0.3,0.1,0.5,0.4,0.8,0.6c-0.1,0.1-0.2,0.2-0.3,0.4
        c0.1-0.4-0.2-0.5-0.5-0.6c0.2,0.4,0.6,0.8,0.9,1.1c-0.1,0-0.3,0-0.4-0.1c-0.1,0.4,0.3,0.2,0.5,0.3c0.1,0.1,0.2,0.2,0.2,0.3
        c-0.1,0.1-0.3,0.2-0.4,0.2c0-0.3-0.2-0.4-0.4-0.4c0,0.2,0.2,0.4,0.4,0.5c0.2,0.3,0.4,0.1,0.4-0.1c0.2,0.2,0.3,0.4,0,0.6l0.3,0l0,0.3
        c-0.3-0.2-0.6,0-0.9,0.1c0.3,0.2,0.5,0,0.7-0.1l0,0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0,0.2,0,0.3,0c0,0,0,0.1,0.1,0.1
        c-0.1,0-0.2,0-0.2,0c0,0.2,0,0.4,0.1,0.6c0.1,0.1,0.5-0.1,0.5,0.2c0,0.2-0.4,0.5,0,0.4c0.1,0,0.2-0.1,0.2-0.2c0,0.1,0,0.2,0,0.3
        l0.2,0.1c-0.1,0-0.2,0-0.3,0l-0.1,0.1c0.2,0.2,0.4,0.3,0.6,0.4c0.1,0.1,0.2,0.2,0.3,0.4c-0.1,0.2-0.2,0.3-0.2,0.5c0.1,0,0.2,0,0.3,0
        l0,0.2c-0.1,0-0.4-0.1-0.5-0.2l-0.1,0.4c0.3,0,0.5-0.1,0.8-0.1c-0.1,0.2-0.3,0.2-0.5,0.2c0.1,0.2,0.3,0.3,0.5,0.5
        c0,0.1-0.1,0.4,0.1,0.4c0,0.1,0,0.2,0,0.3c0-0.1,0.1-0.2,0.1-0.3c0.4,0,0.2,0.4,0.2,0.6c-0.1,0-0.2-0.1-0.3-0.2c0,0.2,0,0.4-0.1,0.6
        c0.2-0.2,0.4-0.4,0.6-0.5c-0.1,0.1-0.3,0.4-0.4,0.6c0.1-0.1,0.3-0.2,0.5-0.3c0,0.1-0.1,0.4-0.2,0.5l0.3-0.2
        c-0.1,0.2-0.1,0.4-0.1,0.7c0,0.2,0.3,0.2,0.4,0.4c-0.2,0-0.5,0-0.7,0c0.1,0.1,0.2,0.2,0.3,0.4c-0.1,0.1-0.3,0.2-0.5,0.3
        c0.2,0,0.3-0.1,0.5-0.1c0.1-0.2,0.2-0.5,0.4-0.4l0.1,0.1c-0.1,0.3-0.3,0.5-0.5,0.7l0,0.1c0.2-0.1,0.3-0.2,0.5-0.2
        c0.1,0.1,0.3,0.2,0.5,0.3c-0.2,0-0.4,0-0.5-0.1c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1-0.1,0.3-0.1,0.3c-0.3,0-0.5-0.2-0.7-0.3
        c-0.1,0.3,0.3,0.3,0.5,0.4c0.3,0.2,0.5-0.2,0.6-0.5l0.2,0.1c-0.3,0.4-0.5,0.8-0.9,1.2c0,0.1-0.1,0.2-0.2,0.2c0,0,0.1,0,0.2,0
        c0-0.2,0.2-0.3,0.4-0.5c0.1-0.1,0.2-0.2,0.3-0.3c0,0.2,0,0.4,0.2,0.6c0.1-0.3,0-0.5-0.2-0.6l0.2,0c0.1,0.2,0.2,0.4,0.3,0.7
        c-0.3,0-0.5,0-0.8-0.1c-0.3,0.3,0.3,0.4,0.4,0.2c-0.1,0.2,0,0.4,0.2,0.5c-0.1,0.2-0.2,0.3-0.3,0.5c-0.2,0-0.4,0-0.6,0.1
        c0,0,0,0.1,0,0.1c0.1,0,0.3,0.1,0.4,0.1c0.2-0.2,0.4-0.1,0.6,0.1c-0.1,0.1-0.2,0.3-0.2,0.4c0.3-0.2,0.6,0,0.9,0c0,0,0,0,0,0.1
        c-0.5,0.1-0.3,0.7-0.3,1l0.2,0c-0.1,0.3,0,0.9-0.4,1l0-0.3l-0.1,0c0,0.1,0,0.3,0,0.4c0.1,0,0.4,0.1,0.4-0.2c0.1-0.2,0.2-0.4,0.2-0.6
        c0.4,0.1,0.2,0.6,0.3,1c0.2,0.2,0.3,0.4,0.6,0.4c0,0.1,0.1,0.2,0.1,0.3l-0.3-0.1c0,0.2,0,0.4,0.2,0.6c0.1,0,0.2,0,0.3,0
        c0,0.1,0.1,0.2,0.1,0.3c-0.3,0.3,0.1,0.6,0.4,0.8c-0.3,0-0.6-0.2-0.9-0.4c0,0.4,0.4,0.7,0.7,0.9c-0.3,0-0.5,0-0.8,0
        c0.1,0.1,0.3,0.2,0.5,0.3c0,0.2,0.1,0.5,0.1,0.6c0.2-0.3,0.3-0.6,0.2-1c0,0,0.1,0,0.2,0c0,0.4,0.1,0.8-0.1,1.1
        c0.2,0.2-0.2,0.3-0.3,0.4c0.2,0.1,0.6,0,0.6,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0,0.1,0,0.2,0,0.3c-0.1,0.1-0.1,0.2-0.2,0.3
        c-0.1,0.1-0.3,0.2-0.4,0.2c0.2,0.2,0.5,0.4,0.7,0.6c-0.1,0-0.3,0.1-0.4,0.2c0.1,0.1,0.2,0.4,0.2,0.5c-0.1-0.1-0.4-0.2-0.5-0.2
        c0.2,0.4,0.5,0.7,0.9,0.9c0-0.1,0.1-0.2,0.1-0.3c0.3,0,0.1,0.3,0,0.5c-0.2-0.1-0.5-0.3-0.7-0.4c0,0.4,0.2,0.4,0.5,0.4
        c0.1,0.3-0.1,0.6-0.1,1c0.1,0,0.3-0.1,0.4-0.1c-0.1,0.3-0.6,0.6-0.3,0.9c-0.2,0.4,0.2,0.5,0.4,0.8c-0.1,0-0.4,0-0.6,0
        c0,0.1,0,0.3,0,0.5c0.1,0,0.2-0.1,0.2-0.1l0-0.2c0.2,0,0.3,0,0.5,0.1c-0.1,0.1-0.2,0.3-0.3,0.5c0,0.2-0.1,0.3-0.1,0.5
        c0.1,0,0.2,0.1,0.3,0.1c0-0.1-0.1-0.3-0.1-0.4l0.3,0c0,0.3,0,0.6-0.2,0.9c0.1,0,0.2,0.1,0.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.3
        c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0.4,0.4,0.3,0.6,0.4l-0.1,0.3c0.1,0,0.3,0,0.4,0c0.1,0.2,0.3,0.4,0,0.6c-0.1-0.2-0.1-0.6-0.5-0.5
        l0,0.3l-0.2,0c0.2,0.1,0.3,0.4,0.2,0.6c0.1-0.1,0.3-0.1,0.4-0.2c0.2,0.1,0.2,0.3,0.1,0.4l-0.1-0.3c-0.1,0.2-0.3,0.4-0.1,0.7
        c0.1-0.1,0.2-0.2,0.3-0.3c-0.1,0.1-0.2,0.4-0.2,0.5l0.4-0.2c-0.2,0.1-0.5,0.4-0.6,0.5h0.6l0.2-0.1c0,0.1,0.1,0.2,0.1,0.3
        c-0.1,0-0.4-0.1-0.6-0.2c0.1,0.2,0.2,0.3,0.3,0.5c-0.2,0-0.3,0-0.5-0.1c-0.2,0.6,0.3,0.3,0.6,0.3c0,0.1,0,0.2,0,0.3l-0.3,0
        c0.3,0.3,0.5,0.7,0.7,1.1c-0.2-0.1-0.3-0.2-0.4-0.3c0.1-0.3,0-0.5-0.3-0.5c0,0.1,0,0.3,0,0.4c-0.1,0-0.2,0-0.2,0
        c0.2,0.2,0.4,0.4,0.4,0.7c0.6,0,0,0.6-0.2,0.6c0.1,0.4,0.2,0.8,0.3,1.2c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0,0.3,0,0.4,0
        c0.2,0.2-0.1,0.3-0.1,0.5c0.2,0.1,0.4,0.4,0.7,0.3c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.2-0.1-0.3-0.1c0.1,0.1,0.1,0.3,0,0.4
        c-0.1-0.1-0.4-0.4-0.5-0.5l0.4,0.1c-0.1-0.2-0.2-0.4-0.4-0.4l0,0.2c-0.2,0-0.2,0.2-0.4,0.3c0.2-0.1,0.4,0,0.6,0.2
        c-0.1,0.1-0.2,0.3-0.3,0.4c0.3-0.1,0.6-0.3,0.9-0.4c0,0.2,0,0.4-0.1,0.6c0.7-0.2-0.2,0.4,0.3,0.5c-0.1,0-0.3-0.1-0.5-0.1l0,0.2
        c-0.3,0.1-0.1,0.3,0,0.4c0,0.1,0.1,0.3,0.1,0.4c-0.1-0.1-0.3-0.2-0.4-0.3c0.1,0.3,0.2,0.6,0.2,1c-0.1,0-0.2,0-0.3,0
        c0,0.1,0,0.2,0,0.3c-0.1,0-0.2,0-0.3,0c0.1,0.1,0.2,0.2,0.2,0.2c-0.2,0.3-0.3,0.5-0.4,0.8c0.4,0.1,0.4-0.4,0.4-0.6
        c0.2-0.2,0.4-0.5,0.7-0.6c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0-0.3,0-0.4-0.1c0,0.1,0.1,0.2,0.1,0.3c-0.5-0.2-0.3,0.3-0.3,0.6l0.3,0
        c-0.3,0.2-0.6,0.4-0.7,0.7c-0.2,0.2,0,0.3,0.2,0.4c0,0.2,0.1,0.3,0.3,0.3c-0.1,0.1-0.1,0.2-0.2,0.3c0.4,0.1,0.5,0.6,0.2,0.9
        c-0.2-0.1-0.3-0.2-0.5-0.2c-0.1-0.2-0.2-0.4-0.3-0.6c0,0.2,0,0.4,0,0.6c0,0.1,0.1,0.2,0.1,0.2c0.2,0,0.3,0,0.5,0l-0.2,0.4
        c0.3-0.1,0.5-0.3,0.6-0.6l0.3,0.1l-0.3,0.4c0.1,0,0.4,0,0.5,0c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2-0.1-0.4-0.1-0.6-0.1
        c0,0.3,0.2,0.6,0.4,0.9c-0.3-0.1-0.6-0.2-0.8-0.4c-0.1,0-0.2,0-0.2,0.2c0.3,0.2,0.6,0.4,0.8,0.6c0,0.2,0.2,0.6-0.3,0.4l0.2,0.3
        c0.3,0.3,0.4-0.3,0.4-0.5c0.2,0.2,0.1,0.5,0.1,0.8l-0.2-0.1c0,0.1,0.1,0.3,0.1,0.4c-0.1,0-0.2,0-0.3,0c0.1,0.2,0,0.6-0.3,0.6
        c-0.1-0.2-0.1-0.4-0.3-0.5c0,0.1,0,0.4,0.1,0.6c0,0.1,0.1,0.1,0.2,0.2c0.1-0.1,0.3-0.1,0.4-0.2l-0.1,0.3l0.2,0.1
        c-0.1,0-0.3,0-0.4-0.1c0,0.3,0.1,0.6,0.1,0.9l-0.3,0c0-0.1,0.1-0.2,0.1-0.3c-0.2,0.2-0.4,0.2-0.5,0c0,0,0,0.1,0,0.1
        c0.3,0,0.4,0.4,0.7,0.5c0.1,0.5-0.4,0.1-0.6,0.1c0,0,0,0.1,0,0.2c0.3,0,0.5,0.3,0.6,0.5c0,0.2-0.1,0.4,0,0.6c0.2,0,0.5,0,0.5,0.3
        c-0.3-0.1-0.5-0.4-0.8-0.3c0.1,0.1,0.3,0.3,0.4,0.5l-0.4,0l-0.2,0.2c0.1,0,0.4,0,0.6,0l-0.2,0.2c0.2,0.3,0.2,0.6,0.3,0.9l0.1,0
        c0.1,0.2,0,0.4,0,0.6c-0.1,0-0.2-0.1-0.2-0.1c0-0.1,0-0.2,0-0.3l-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.3c0.1,0.3-0.2,0.5-0.4,0.7l0.2,0
        c0.1-0.1,0.2-0.2,0.2-0.2c0.1,0.2,0.2,0.4,0.3,0.6c-0.2-0.1-0.3-0.2-0.3-0.4c-0.2,0.2-0.3,0.3-0.1,0.5c-0.1,0.2-0.3,0.4-0.4,0.6
        c0.1,0,0.3,0,0.5,0l0,0.4c-0.1,0-0.4-0.1-0.5-0.1c0.1,0.2,0.1,0.5,0,0.7c0.4,0,0.3,0.5,0.3,0.7c-0.2-0.1-0.2-0.4-0.4-0.6
        c-0.2-0.1-0.3-0.3-0.3-0.5c0,0-0.1,0-0.1,0c0.2,0.3,0.3,0.6,0.5,1c-0.1,0.2-0.3,0.3-0.4,0.5c0.1,0,0.3-0.1,0.3-0.1
        c0,0,0.1,0.1,0.2,0.2c0.2-0.5,0.4-1,0.4-1.5c0.4-0.2,0.2-0.7,0.3-1c0.7-1,1.4-2.1,1.9-3.2c0.4-0.8,0.6-1.8,1-2.6
        c-0.3,0.1-0.5,0.4-0.7,0.6c-0.3,0.2-0.7,0.4-1,0.3c0,0,0-0.1,0-0.1c0.5,0,0.6-0.6,0.8-1c0.2-0.5,0.4-0.9,0.4-1.4
        c0.1-0.3,0.3-0.5,0.4-0.7c0.1-0.8,0.6-1.4,0.8-2.1c0.1-0.3,0.4-0.7,0.1-1l0.2,0c0-0.2,0.1-0.4,0.3-0.4c-0.1-0.2-0.1-0.4-0.2-0.6
        l0.2,0c0-0.3,0.1-0.5,0.2-0.8c-0.1,0.1-0.3,0.2-0.4,0.3l0.1,0.1c-0.1,0.2-0.3,0.4-0.3,0.7l-0.2-0.1c0,0,0,0.1,0,0.1
        c0.1,0,0.3,0,0.4-0.1c0,0.1-0.1,0.4-0.2,0.5c-0.1,0-0.2,0-0.3,0c0,0.1,0.1,0.3,0.1,0.4l-0.2,0c0,0.1,0,0.3,0,0.4
        c-0.4,0.1-0.4,1-0.9,0.8c0,0.1,0,0.3,0,0.4c-0.1,0.4-0.3,0.1-0.4,0l0.1,0.3l-0.3-0.1c0.1,0.1,0.2,0.1,0.3,0.2
        c-0.3,0.1-0.5,0.2-0.7,0.3c-0.2,0-0.3,0-0.5,0c0.7-0.5,0.7-1.4,1-2.1c0.1-0.1,0.2-0.1,0.3-0.2c0-0.5,0.1-1,0.5-1.4
        c-0.1-0.3,0.1-0.6,0.3-0.9c-0.2,0.2-0.5,0.2-0.5,0.6c-0.1-0.2-0.1-0.4,0-0.5l-0.2,0.1l-0.1-0.2c0,0.2,0,0.5,0,0.7
        c-0.3,0.2-0.5,0.5-0.8,0.7c0.2-0.8,0.7-1.6,0.5-2.5c0.2-0.4,0.3-0.8,0.4-1.3c-0.2-0.1-0.4,0.1-0.5,0.3c0-0.1,0.1-0.3,0.1-0.4
        c-0.2,0.1-0.3,0.3-0.5,0.4l0.3,0c-0.4,0.5-1,0.8-1.5,1.2c0,0.1-0.1,0.2-0.1,0.3c-0.1-0.4,0.3-0.8,0.4-1.2c0.3-0.8,0.5-1.5,0.8-2.3
        c0.1-0.6,0.5-1.1,0.8-1.6c0-0.2,0.1-0.4,0.2-0.6c-0.2,0.1-0.3,0.2-0.5,0.4c0,0.1,0,0.3,0,0.4c-0.1,0-0.2,0-0.3,0
        c0,0.1,0.1,0.3,0.1,0.3l-0.2-0.1c0,0.2-0.1,0.3-0.3,0.3c0,0.4-0.5,0.6-0.6,0.9c-0.1-0.1-0.1-0.2-0.2-0.2c0.3-0.7,0.5-1.5,0.7-2.2
        c0.1-0.2,0.1-0.5,0.1-0.8c0.1-0.1,0.2-0.2,0.3-0.3c0-1,0.8-1.7,1.1-2.6c-0.2-0.1-0.4,0-0.6,0.1c0.1,0,0.3,0,0.4,0
        c-0.2,0.2-0.3,0.6-0.6,0.6c0,0.4-0.4,0.7-0.8,0.9c0.2-0.4,0.4-0.7,0.4-1.2c0.1-0.6-0.1-1.2,0.3-1.7c0-0.2,0-0.5,0-0.7
        c0.1-0.1,0.1-0.2,0.2-0.3c-0.1-0.4,0.2-0.8,0.2-1.2c0.2-0.4,0.6-0.9,0.5-1.4c0.3-0.5,0.2-1.1,0.6-1.6c0-0.2,0.1-0.4,0.2-0.6
        c0.2-0.8,0.5-1.5,0.9-2.2c0.3-0.5,0.3-1.1,0.7-1.5c0.1-0.5,0.1-1.1,0-1.6c-0.1-0.6,0.2-1.2,0.1-1.8c0,0-0.1,0-0.1,0
        c0,0.4-0.1,0.7-0.1,1.1l-0.1,0c0,0.2,0,0.5,0,0.7l-0.1,0c0,0.2,0,0.5,0,0.7c-0.1,0-0.2-0.1-0.3-0.2c0.1,0.2,0.1,0.3,0.2,0.5
        c-0.1,0.2-0.4,0.5-0.1,0.7c-0.4-0.1-0.2,0.4-0.3,0.6l-0.2,0c0,0.2,0,0.4,0,0.6l-0.2-0.1c0,0.4-0.1,0.8-0.2,1.2
        c-0.1,0-0.2,0.1-0.2,0.2c0,0.2,0,0.4,0,0.6c-0.1,0-0.2-0.1-0.3-0.1c0.2,0.5-0.3,1-0.3,1.5l-0.2,0c0,0.2,0,0.6-0.3,0.6
        c0,0.1,0,0.3,0,0.3c-0.5,0.7-0.8,1.5-1.3,2.2c0.3-0.8,0.5-1.7,0.9-2.5c0.2-0.8,0.5-1.6,0.7-2.5c0.4-0.8,0.3-1.6,0.5-2.5
        c0.1-0.6,0.1-1.2,0.4-1.7c0-0.7,0.3-1.3,0.4-2c0-0.5,0.5-1.1,0.1-1.6c0.2-0.1,0.2-0.2,0.2-0.4c-0.2,0.1-0.5,0.3-0.7,0
        c0.1,0.1,0.3,0.4,0.4,0.5c-0.1,0-0.2,0-0.4,0l0.2-0.3c-0.3,0-0.4-0.2-0.5-0.4c0.1,0,0.3,0,0.4,0c-0.2-0.2-0.3-0.4-0.5-0.6
        c0.1-0.1,0.2-0.3,0.3-0.4c-0.4,0-0.5,0.3-0.4,0.6l-0.2-0.1c0-0.3,0-0.6-0.3-0.9c0.3,0.1,0.3-0.1,0.2-0.3c-0.1,0.1-0.3,0.1-0.4,0.2
        c0.3,0.1,0.1,0.4,0.1,0.6c-0.3,0-0.2-0.4-0.2-0.6c-0.1,0-0.3,0-0.3,0l0.1-0.2c0.1,0,0.3,0,0.3,0c-0.3-0.1-0.4-0.3-0.6-0.5
        c0.1-0.1,0.3-0.2,0.4-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.4,0-0.3,0.6-0.3,0.8c-0.2-0.1-0.3-0.2-0.4-0.3c0.5-0.3-0.4-0.3,0-0.6
        c-0.2-0.3-0.2-0.5,0-0.8c-0.2,0.1-0.4,0.2-0.5,0.3c0.1,0.1,0.2,0.3,0.3,0.5c-0.4-0.3-0.7-0.7-1.1-1l0.4,0.4l0.1-0.5
        c-0.1,0.4,0.2,0.1,0.4,0c0,0,0-0.1,0-0.1c-0.1,0-0.4,0-0.5,0c-0.1-0.3-0.2-0.6-0.6-0.8c0.1-0.1,0.2-0.2,0.3-0.3
        c-0.3,0-0.4,0.3-0.5,0.5c0.3,0,0.2,0.2,0.1,0.5c-0.2-0.2-0.4-0.4-0.2-0.6c-0.1-0.2-0.4-0.4-0.3-0.6c0.1,0,0.3,0.1,0.4,0.2
        c-0.1-0.1-0.2-0.2-0.3-0.2c0-0.1,0.1-0.3,0.1-0.4l-0.2,0c0-0.1,0.1-0.4,0.1-0.6c-0.2,0.2-0.2,0.4-0.1,0.6c-0.1,0-0.3,0.1-0.4,0.1
        l0.3,0.1c-0.1,0.2-0.1,0.3-0.1,0.5c-0.1-0.2-0.3-0.4-0.4-0.6c0,0,0.1-0.1,0.1-0.2c-0.1-0.2-0.2-0.3-0.1-0.5c0-0.3-0.2-0.5-0.3-0.7
        c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0,0.2,0,0.3-0.1c0.1,0.1,0.2,0.4,0.3,0.5c-0.1,0-0.4-0.1-0.5-0.1c-0.1-0.3-0.2-0.6-0.3-0.9
        c0.1,0,0.3-0.1,0.4-0.1c-0.1-0.2-0.3-0.1-0.4,0c-0.2-0.1-0.4-0.3-0.6-0.5c0.2,0,0.4,0,0.7,0c0.1-0.1,0.1-0.2,0.2-0.3
        c-0.3,0-0.6,0-0.8,0.2l0.1-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c0.1,0,0.3,0,0.5-0.1c-0.2-0.2-0.9,0-0.7-0.5c0.2,0.1,0.4,0.1,0.7,0.1
        c-0.2-0.1-0.4-0.2-0.6-0.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.3-0.1-0.1-0.4-0.2-0.6c-0.1-0.1-0.2-0.2-0.3-0.3c0.1,0,0.3-0.1,0.4-0.1
        c0.1-0.2-0.3-0.3-0.3-0.5c-0.1,0-0.4,0-0.5-0.1c0,0.3,0.3,0.3,0.6,0.4c-0.2,0-0.4,0.1-0.6,0.1c0-0.1,0.1-0.2,0.1-0.3
        c-0.1-0.2-0.3-0.3-0.4-0.5c0.1,0,0.3,0,0.5,0c0.1-0.4-0.8,0.1-0.4-0.3l0.1-0.2l-0.1-0.1c-0.1,0.1-0.2,0.2-0.3,0.3
        c-0.2-0.2-0.2-0.6-0.5-0.5c0.1-0.5,0.4,0.1,0.7,0.1l-0.2-0.1c0-0.2-0.1-0.4-0.3-0.4c0-0.2,0-0.4,0-0.6c-0.3,0.1-0.6-0.3-0.9-0.1
        c0.1-0.1,0.2-0.2,0.3-0.3c0-0.1-0.1-0.2-0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2c-0.1-0.2-0.3-0.4-0.3-0.7c0,0-0.1,0-0.1,0.1
        c-0.1,0.1-0.2,0.2-0.2,0.2c0.1-0.4-0.6-0.7-0.2-1.1c-0.2,0-0.5,0-0.7,0c0.1-0.2,0.3-0.4,0.4-0.6c-0.1,0-0.3,0-0.3-0.1
        c0,0.1,0,0.3,0,0.4l-0.2-0.1c-0.2-0.5-0.6-0.8-0.8-1.3c0.4,0,0.2,0.6,0.6,0.5c0-0.1-0.1-0.3-0.1-0.4c-0.2-0.1-0.3-0.3-0.4-0.4
        c0-0.1,0.1-0.2,0.1-0.3c-0.2-0.1-0.4-0.1-0.5-0.3c0.1,0,0.3,0,0.4,0c0-0.2,0-0.3,0-0.5l0,0c-0.2,0.2-0.4,0.4-0.6,0.7
        c0-0.1-0.1-0.1-0.1-0.1c0.3-0.1,0.3-0.5,0.4-0.7c-0.4,0.2-0.5-0.3-0.7-0.5c0.2,0,0.4,0,0.5,0.1c-0.2-0.3-0.5-0.5-0.8-0.7
        c0-0.2,0.1-0.5,0.2-0.7l-0.2,0.1c-0.1-0.2-0.2-0.4-0.3-0.5c0.1,0,0.3,0,0.4,0c-0.2-0.1-0.4-0.2-0.6-0.4l0,0.2l0,0
        c0-0.2,0-0.5-0.1-0.7c0.1,0.1,0.3,0.3,0.4,0.4c0-0.1,0-0.3,0-0.5c-0.3,0-0.5-0.2-0.7-0.4c0.1,0,0.2-0.1,0.3-0.2l-0.2,0
        c-0.5,0,0.2-0.4,0-0.7c-0.2,0.2-0.3,0.5-0.7,0.6c0.1-0.1,0.2-0.3,0.3-0.4c0.3-0.3-0.2-0.6-0.5-0.5c0-0.1,0-0.2,0-0.2
        c0.1,0,0.3,0,0.5,0l0,0.2c0.3-0.1,0.2-0.4,0.1-0.5c-0.2,0.1-0.3,0.2-0.5,0.3c0.2-0.3,0.3-1-0.2-1c0,0.2,0.1,0.5,0.2,0.7
        c-0.1,0.1-0.1,0.1-0.2,0.2c0.1-0.2-0.1-0.3-0.2-0.5c0.1-0.2,0.1-0.4,0.2-0.6c-0.2,0-0.4,0-0.6,0c0.1,0,0.4,0,0.5,0
        c0-0.1,0-0.2,0-0.3c-0.3,0-0.3-0.3-0.2-0.5c-0.1,0-0.3,0.1-0.4,0.1c0-0.1,0-0.4,0-0.5c0.2,0.1,0.4,0.3,0.6,0.3
        c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1-0.2-0.3-0.3-0.5-0.5c-0.1,0.1-0.2,0.2-0.3,0.3c0.1-0.2,0.2-0.3,0.3-0.4c-0.1,0-0.2-0.1-0.3-0.2
        c0.1,0,0.3,0,0.5,0c-0.4-0.3-0.9-0.7-1.3-1c0.1-0.2,0.2-0.3,0.3-0.5c0-0.3,0-0.5,0-0.8c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.2,0-0.3
        l0.4,0.1c-0.2-0.2-0.4-0.3-0.7-0.2c0-0.1,0-0.3,0-0.3c0.2-0.2,0.2-0.5,0.2-0.8c-0.1,0.1-0.2,0.2-0.3,0.3c0,0.1,0,0.2,0,0.3
        c-0.2-0.1-0.5-0.2-0.7-0.4c0.2,0,0.4,0.2,0.6,0c-0.1-0.1-0.3-0.2-0.4-0.3c0.1,0,0.4-0.1,0.5-0.1c0-0.2-0.1-0.3-0.2-0.5
        c0,0.1,0,0.3,0,0.4c-0.2-0.2-0.3-0.3-0.4-0.5c0.1-0.1,0.1-0.3,0.2-0.4c-0.1,0-0.2,0-0.3,0.1l0,0.2c-0.1,0-0.2,0-0.3-0.1
        c0.1,0,0.2-0.1,0.2-0.2c-0.1-0.2-0.2-0.5-0.3-0.7c0.1,0,0.4,0.5,0.5,0.1c-0.2-0.1-0.4-0.4-0.7-0.3c0,0.1,0,0.3,0,0.4
        c-0.5-0.2,0-0.9,0.4-0.5c-0.1-0.3-0.3-0.4-0.6-0.3l0,0.2c0-0.1-0.1-0.2-0.2-0.3l-0.1-0.1c0.2,0,0.3,0,0.5,0
        c-0.1-0.1-0.2-0.2-0.3-0.3l0.3,0c-0.2-0.2-0.6-0.3-0.7-0.6c-0.1-0.3-0.2-0.6-0.5-0.8c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.2-0.1,0.3-0.2
        c-0.2-0.1-0.4-0.2-0.5-0.3c0-0.1-0.1-0.2-0.1-0.4l0,0c0.1,0,0.2,0.1,0.3,0.2c0-0.1-0.1-0.4-0.1-0.5c-0.3-0.1-0.5,0.1-0.7,0.2l0,0
        c0.3-0.2,0.1-0.4-0.1-0.6l0.2-0.1c0,0.1,0.1,0.3,0.1,0.3c0.1-0.1,0.3-0.2,0.4-0.2c-0.3-0.2-0.5-0.3-0.8-0.6c0.1-0.1,0.2-0.2,0.2-0.2
        c0-0.1,0-0.2,0-0.3c-0.2,0.1-0.4,0.5-0.7,0.4c0.2-0.2,0.4-0.3,0.5-0.6c-0.1,0-0.3,0-0.3,0c0,0,0-0.1,0-0.2c0.1,0,0.3,0,0.4,0.1
        c-0.1-0.3-0.5-0.1-0.6-0.4c0-0.1-0.1-0.3-0.2-0.4c0.1,0,0.2,0,0.3,0c-0.1-0.1-0.2-0.3-0.2-0.4c0,0.1,0,0.3,0,0.4l-0.3,0
        c0-0.3-0.1-0.4-0.3-0.5c0-0.1,0.1-0.2,0.2-0.3c-0.2-0.1-0.4-0.3-0.5-0.5c0.1,0,0.3,0.1,0.4,0.1c0,0-0.1-0.1-0.1-0.2
        c0-0.4-0.4-0.4-0.4-0.8c-0.1-0.1-0.3-0.2-0.4-0.2c0,0.1,0.1,0.3,0.2,0.4c-0.2-0.2-0.4-0.2-0.6-0.3c0.2,0,0.3-0.2,0.4-0.4
        c0,0.1,0,0.2,0,0.3c0.1,0,0.4,0,0.6,0c-0.2-0.1-0.3-0.3-0.2-0.5c0,0-0.1,0.1-0.1,0.2c-0.5,0.5,0.1-1-0.6-0.2l0.2-0.3l-0.2,0.1
        c0-0.1,0.1-0.3,0.1-0.4c-0.2,0-0.4-0.2-0.4-0.4l0.3,0c-0.1-0.3-0.5-0.1-0.7-0.3c0-0.1,0-0.3,0-0.3c-0.1-0.1-0.3-0.2-0.4-0.3
        c0.4-0.1,0.6,0.3,0.8,0.5c0-0.1,0.1-0.3,0.1-0.3c-0.4,0-0.6-0.5-0.9-0.6c0-0.1,0.1-0.3,0.1-0.4c0.2,0.1,0.2,0.3,0.4,0.4
        c0-0.4-0.2-0.6-0.5-0.8c0-0.2,0-0.3-0.2-0.3c0,0.1-0.1,0.3-0.1,0.4c-0.4-0.2,0-0.5,0.1-0.7c-0.3-0.1-0.5-0.1-0.8-0.1
        c0,0,0-0.1,0-0.1l0.3,0.1c-0.1-0.2-0.1-0.6-0.5-0.6c0-0.1-0.1-0.4-0.2-0.5l0.3,0c-0.1-0.1-0.2-0.2-0.3-0.2c0-0.2,0-0.3-0.1-0.5
        c-0.1,0-0.2,0-0.3,0c0-0.2,0-0.4,0-0.6c0,0,0.1-0.1,0.2-0.2c-0.2,0-0.3,0.3-0.4,0.4c0.1,0.1,0.1,0.2,0.2,0.4c-0.6,0-0.8-0.4-1.2-0.8
        c0.1-0.8,0.3-1.6,0-2.4c-0.1-0.3-0.4-0.2-0.6-0.3c-0.1-0.1-0.2-0.2-0.4-0.2c0,0.1,0.1,0.3,0.1,0.3c-0.1,0-0.3,0-0.4,0.1
        c-0.2-0.2-0.4-0.3-0.6-0.4c0.1,0,0.4,0,0.5,0.1c0-0.4-0.2-0.7-0.6-0.7c-0.2,0-0.5,0.1-0.7,0c0.1,0,0.3-0.1,0.4-0.2
        c-0.3-0.3-0.6-0.1-0.9,0c-0.1-0.1-0.2-0.3-0.2-0.4c0.2,0.1,0.4,0.1,0.7,0.2c0-0.5-0.5-0.5-0.9-0.5c0,0.1,0,0.2,0,0.3
        c-0.3-0.1-0.5-0.4-0.7-0.7c0,0.3,0,0.5-0.3,0.5c0-0.2,0-0.4-0.1-0.6c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0-0.3,0-0.4,0.1
        c0,0.1,0,0.2,0,0.3c-0.4-0.1-0.5,0.3-0.6,0.5c-0.4,0-0.4,0.5-0.8,0.7c-0.2-0.2-0.2-0.5-0.2-0.8c0.2-0.2,0.2-0.4,0.2-0.6
        c0.3,0.1,0.2,0.5,0.3,0.8c0.3-0.3-0.2-0.7-0.1-1l0.2,0c0-0.4,0.4-0.8,0-1.2l0.4,0.2c-0.1-0.3-0.1-0.5-0.1-0.8l0.2,0.1
        c0.1-0.3,0.2-0.6,0.2-0.9c0.1,0,0.2,0,0.2,0c-0.3-0.2,0-0.4,0.1-0.6c0-0.3-0.1-0.7,0.4-0.6c-0.1-0.1-0.2-0.3-0.2-0.4
        c0.2-0.2,0.3-0.4,0.5-0.6c0-0.2-0.1-0.4-0.1-0.6c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.2,0-0.4,0-0.6c0.1-0.2,0.2-0.4,0.4-0.4
        c0.1-0.1,0.2-0.2,0.2-0.3c-0.3-0.3-0.2-0.8,0.3-0.8c-0.1-0.3,0-0.5,0.1-0.8c0.1-0.1,0.2-0.2,0.3-0.2c0.3-0.7,0.2-1.5,0.6-2.2
        c0-0.1-0.1-0.2-0.1-0.3c0.2-0.5,0.5-1.1,0.5-1.6c0.1,0,0.2-0.1,0.3-0.2c0-0.1,0-0.3,0-0.3c0-0.1-0.1-0.2-0.1-0.2
        c0.4-0.8,0.3-1.6,0.6-2.4c0.2-0.3-0.1-0.8,0.3-1.1c0-0.3-0.1-0.6,0.1-0.8c0.2-0.3-0.1-0.6,0-0.9c0.1-0.7,0.2-1.5,0.2-2.2
        c0.2-0.4-0.1-0.8,0.1-1.2c0.1-0.4-0.1-0.9,0.1-1.3c0.2-0.4,0.1-0.9,0.1-1.4c0.1-0.9,0.1-1.8,0.1-2.7c0.3-0.5,0-1,0.1-1.5
        c0-0.7,0.3-1.3,0.2-2c0-0.1,0.1-0.2,0.2-0.3c-0.1-0.3-0.2-0.7-0.2-1c0.3-1.5,0-3.2,0.5-4.7c-0.3-0.3-0.2-0.6,0-0.9
        c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.9,0.4-1.8,0.2-2.7c-0.1-0.3,0.2-0.6,0-0.9c-0.1-0.3,0.1-0.6,0.3-0.8c-0.3-0.5-0.2-1.1,0-1.6
        c-0.3-0.6,0-1.3,0-1.9c0-0.9,0.2-1.8,0-2.6c0-0.1,0.1-0.3,0.1-0.4c-0.2-0.3-0.2-0.6,0-0.9c0.1-0.8-0.2-1.5,0.1-2.3
        c0.2-0.6-0.1-1.2,0.2-1.8c-0.2-0.7-0.3-1.5-0.2-2.3c-0.3-0.4-0.1-1-0.2-1.4c0.1-0.3,0.2-0.6,0.1-0.8c0-0.8-0.1-1.6,0.1-2.4
        c-0.1-0.3-0.1-0.6,0-0.9c-0.2-0.5-0.1-1-0.3-1.5c0.1-0.6-0.3-1.2,0.1-1.8c-0.2-0.4-0.3-0.8-0.3-1.2c0-0.5-0.2-1.1,0-1.6
        c-0.1-0.3-0.3-0.5-0.4-0.8c-0.1-0.6,0.1-1.2-0.2-1.7c-0.2-0.4,0-0.8-0.1-1.1c-0.2-0.4,0.3-0.8,0.1-1.2c-0.3-0.6-0.4-1.3-0.3-2
        c-0.3-0.9-0.3-1.9-0.5-2.8c-0.2,0.1-0.4,0.2-0.6,0.3c0,0.1,0,0.2,0,0.3c0,0-0.1-0.1-0.1-0.2c-0.6,0.3-1.2,0.1-1.7,0.3
        c-0.7,0.2-1.4,0-2,0.3c-0.3,0.1-0.6,0.2-0.9,0.3c-0.2,0.1-0.4,0.2-0.6,0.4c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1-0.1-0.2-0.2-0.3-0.3
        c0.3,0,0.7,0.1,0.8-0.3c-0.3,0.1-0.6,0.1-0.9,0c-0.3,0.2-0.7,0.4-1,0.2c-0.3,0.1-0.5,0.3-0.6,0.7c0-0.1,0.1-0.3,0.1-0.4
        c-0.3,0.2-0.6-0.3-0.9,0c-0.8-0.2-1.6,0.4-2.4,0.2c-0.4-0.1-0.9,0.1-1.2-0.2c-0.3,0-0.5,0-0.8,0.1c0.3,0.2,0.7,0.5,1.1,0.4l0,0.1
        c0.4,0,0.6,0.4,0.9,0.5c0.1,0,0.3,0.1,0.4,0.1c0,0.1,0.1,0.2,0.2,0.3c0.1,0,0.3,0,0.4,0c0.3,0.2,0.5,0.3,0.7,0.6
        c0,0,0.1-0.1,0.2-0.1c0.1,0.1,0.2,0.1,0.4,0.2c0.2,0.1,0.4,0.2,0.6,0.3c0.1,0,0.2,0,0.3,0c0.6,0.4,1.2,0.7,1.8,0.9
        c0.1,0,0.3,0.1,0.4,0.1c0.2,0.2,0.7,0.1,0.9,0.4c0.2,0,0.4,0.1,0.6,0.2c-0.1,0-0.3,0-0.4,0c-0.3-0.2-0.7-0.3-1-0.6
        c-0.3,0.1-0.5-0.2-0.7-0.2c-0.5-0.2-1-0.4-1.4-0.7c-0.3-0.1-0.6-0.2-0.9-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c0,0-0.1,0.1-0.2,0.2
        c-0.1-0.1-0.2-0.2-0.2-0.3c-0.3-0.1-0.6-0.5-0.9-0.3c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2,0-0.4,0-0.5-0.2c-0.4-0.3-0.8-0.5-1.3-0.6
        c-0.2-0.1-0.3-0.2-0.5-0.4c-0.4,0.1-0.7-0.3-1-0.2c0-0.1,0-0.2,0-0.2c-0.4-0.1-0.8-0.3-1.2-0.2l0.3-0.1c-0.2-0.3-0.6-0.4-1-0.3
        c-0.1-0.1-0.2-0.3-0.3-0.4c-0.7,0-1.4-0.4-1.9-0.9c-0.3-0.3-0.9-0.2-1.2-0.7c-0.6-0.2-0.9-0.7-1.5-0.8c-0.1-0.2-0.3-0.2-0.5-0.2
        c-0.1-0.2-0.4-0.3-0.6-0.4c-0.2,0-0.3-0.4-0.6-0.3c-0.2,0-0.3-0.2-0.5-0.3c-0.3-0.3-0.7-0.3-1-0.6c-0.4-0.3-0.9-0.3-1.2-0.7
        c-0.5-0.2-1-0.5-1.5-0.7c-0.3-0.3-0.8-0.3-1.1-0.6c-0.2-0.2-0.7-0.3-0.7-0.7c-0.1,0.1-0.2,0.2-0.2,0.2c-0.6-0.1-1-0.6-1.5-0.8
        c-0.2-0.4-0.7-0.3-1-0.7c-0.1-0.2-0.3-0.2-0.5-0.2c-0.1-0.1-0.3-0.2-0.4-0.4c-0.1-0.1-0.2-0.3-0.3-0.2c-0.6,0-1-0.4-1.4-0.8
        c-0.5,0.1-0.8-0.3-1-0.6c-0.3-0.1-0.5-0.1-0.7-0.2c0-0.2-0.2-0.3-0.3-0.3c-0.2,0-0.3-0.3-0.4-0.5c-0.3-0.2-0.7-0.1-1-0.2
        c-0.3-0.5-0.9-0.6-1.3-1c-0.2-0.2-0.5-0.3-0.7-0.5c-0.3-0.2-0.7-0.4-0.7-0.8c-0.1,0-0.2,0-0.3,0c-0.2,0.1-0.3,0.1-0.5,0
        c0.3,0.1,0.5,0,0.7-0.2c-0.5-0.1-1-0.4-1.5-0.4c-0.2-0.1-0.3-0.3-0.4-0.5c0.3,0,0.5,0.7,0.9,0.4c-0.5-0.4-1.1-0.7-1.5-1.2
        c-0.1,0.1-0.3,0.1-0.4,0.2c0.1-0.1,0.1-0.2,0.2-0.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0-0.3,0-0.4,0.1c0-0.1,0.1-0.3,0.2-0.4
        c-0.5-0.1-0.7-0.6-1.1-0.7c0-0.1,0-0.2-0.1-0.3c-0.2-0.1-0.3-0.2-0.5-0.3c0,0.1,0,0.2,0,0.3c0.1-0.4-0.3-0.6-0.5-0.9
        c-0.2-0.1-0.4-0.2-0.6-0.1c0.1-0.3-0.4-0.4-0.5-0.5c0,0.1,0,0.3,0,0.4c-0.1-0.2-0.4-0.5,0.1-0.5c-0.2-0.3-0.5-0.4-0.8-0.6
        c-0.3-0.2-0.5-0.5-0.8-0.6c-0.3-0.3-0.3-0.6-0.6-0.9c0,0.1-0.1,0.4-0.1,0.5c-0.1-0.2-0.1-0.4-0.1-0.6c-0.1,0-0.3,0-0.4,0
        c0.1,0.1,0.1,0.3,0.2,0.4c-0.2-0.1-0.3-0.3-0.5-0.4c0.1-0.1,0.3-0.1,0.5-0.1c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.2-0.5-0.4-0.4-0.7
        c-0.2-0.1-0.5-0.2-0.6-0.4c-0.3,0-0.4-0.2-0.6-0.4c-0.3,0.6,0.6,0.8,1,0.9c0,0.2-0.4,0.1-0.5,0.1l0-0.2c-0.5,0.1-0.5-0.4-0.6-0.7
        c0,0.1-0.1,0.2-0.2,0.3c-0.1-0.1-0.2-0.2-0.3-0.2c0.1,0,0.2,0,0.3,0l0.2-0.3c-0.2,0.1-0.4-0.1-0.5-0.2c0,0.1-0.1,0.2-0.1,0.3
        c-0.1-0.2-0.2-0.3-0.3-0.5c0.1,0,0.3-0.1,0.4-0.1c-0.2-0.2-0.3-0.4-0.6-0.6c0,0.2,0,0.5,0.1,0.6c-0.1-0.1-0.2-0.2-0.2-0.3
        c-0.2,0-0.4-0.1-0.6-0.3c0-0.1,0-0.2,0-0.3c-0.1-0.1-0.2-0.2-0.3-0.4c-0.2,0-0.4-0.2-0.6-0.3c0.1,0,0.2-0.1,0.2-0.1
        c0.1,0.2,0.2,0.3,0.3,0.3c-0.2-0.4-0.5-0.6-0.7-1c-0.1-0.3-0.5-0.3-0.8-0.4c0.4,0.3,0.8,0.6,1,1c-0.3-0.2-0.8-0.3-0.8-0.7
        c-0.2-0.2-0.4-0.5-0.5-0.7c-0.1,0-0.2,0.1-0.2,0.2c0-0.2,0.2-0.6-0.2-0.6l-0.1,0.2l-0.1,0c0-0.1,0.1-0.3,0.1-0.4l-0.4,0
        c0.1,0.4-0.2,0-0.3-0.1c0.1-0.1,0.2-0.2,0.3-0.3c-0.3-0.4-0.8-0.6-1.2-0.9c0.3-0.2,0.3-0.3-0.1-0.3l0-0.3c-0.1,0.1-0.2,0.1-0.2,0.2
        c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.1,0.2-0.2,0.3-0.2c-0.2-0.1-0.5-0.1-0.6-0.3c-0.2-0.4-0.8-0.6-0.7-1.1c-0.3-0.2-0.6-0.4-0.9-0.6
        l0,0.3c-0.2-0.1-0.2-0.3,0-0.3c-0.1-0.1-0.2-0.2-0.2-0.3c-0.2-0.2-0.4-0.3-0.6-0.4c-0.1-0.1-0.1-0.3-0.2-0.4
        c-0.6-0.3-0.6-1.2-1.3-1.4c-0.3-0.1-0.2-0.4-0.1-0.5c-0.3-0.1-0.4-0.4-0.7-0.4c-0.5-0.7-1.1-1.4-1.7-1.9c0.2-0.3-0.4-0.9-0.5-0.4
        c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0.1-0.2,0.2-0.2c-0.2-0.2-0.6-0.3-0.7-0.6c-0.2-0.2-0.1-0.6-0.5-0.6c0-0.3-0.2-0.6-0.5-0.8
        c-0.2-0.2-0.7-0.3-0.5-0.7c-0.1-0.2-0.4-0.4-0.6-0.5c-0.1-0.2-0.1-0.4-0.3-0.4c0.1-0.1,0.2-0.2,0.2-0.2c-0.6-0.2-0.7-0.8-1.2-1.1
        c0.1-0.3-0.2-0.4-0.4-0.7c-0.3-0.3-0.7-0.5-0.8-0.9c-0.3-0.1-0.4-0.4-0.5-0.7c-0.3,0-0.4-0.3-0.7-0.5c-0.1-0.5-0.5-0.8-0.9-1.1
        c0.2-0.5-0.4-0.7-0.7-0.9c-0.2-0.4-0.2-0.8-0.6-1c-0.1-0.4-0.3-0.7-0.7-0.9c0-0.2,0-0.4-0.1-0.6c-0.6-0.4-0.9-1-1.3-1.5
        c-0.1-0.5-0.6-0.8-0.9-1.2C165.1,177.7,165.4,177.3,165,177.3 M388.6,177.4C388.4,177.8,389.1,177.4,388.6,177.4 M149.2,177.3
        c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1-0.2-0.3-0.3-0.4-0.1c0.1,0,0.3,0.1,0.4,0.1c0.2,0.1,0.4,0.3,0.6,0.2
        C149.4,177.9,149.3,177.6,149.2,177.3 M403.3,177.5C403.4,177.6,403.4,177.6,403.3,177.5 M37.6,177.8
        C37.6,178.2,38,177.5,37.6,177.8 M279.2,177.8c0.1,0.2,0.2,0.4,0.2,0.7c-0.2,0.1-0.2,0.3-0.2,0.5c0.1-0.1,0.2-0.2,0.3-0.4
        c0.1-0.1,0.3-0.2,0.4-0.3c0-0.1-0.1-0.4-0.1-0.5c-0.1,0.2-0.1,0.5-0.3,0.6c-0.1-0.2-0.1-0.4-0.1-0.5
        C279.3,177.8,279.2,177.8,279.2,177.8 M440.4,177.9C440.5,178,440.5,178,440.4,177.9 M284.8,178.5c0.2,0,0.3-0.1,0.2-0.3
        C284.9,178.2,284.8,178.3,284.8,178.5 M148.8,178.8c0,0.2,0.1,0.3,0.2,0.3C149.3,179,149,178.7,148.8,178.8 M284.9,178.8l0,0.1
        C285.2,179.2,285.1,178.5,284.9,178.8 M36.5,179.5c-0.1,0.2,0,0.3,0.2,0.2C36.8,179.5,36.7,179.4,36.5,179.5 M239,179.5
        C239,180,239.4,179.3,239,179.5 M478.1,179.7c0.2-0.1,0.2-0.2,0-0.3C477.9,179.5,477.9,179.6,478.1,179.7 M36.7,180.1
        c0.1,0.4,0.7-0.2,0.4-0.4C37,179.7,36.9,179.9,36.7,180.1 M284.9,179.6C284.9,179.7,284.9,179.7,284.9,179.6 M407.3,180
        C407.3,180.5,407.7,179.8,407.3,180 M239,180.7c0,0.1-0.1,0.3-0.1,0.4c0.3-0.1,0.6-0.3,0.8-0.6C239.4,180.5,239.2,180.6,239,180.7
        l-0.1-0.3C238.8,180.5,238.7,180.7,239,180.7 M197.4,180.7c0.1,0.2,0.2,0.2,0.3,0.1C197.6,180.6,197.5,180.5,197.4,180.7
        M295.9,180.6c-0.1,0.2,0.3,0.4,0.4,0.3C296.3,180.8,296,180.6,295.9,180.6 M338.4,181C338.5,181,338.5,181,338.4,181 M35.9,181.5
        c-0.1,0.2,0,0.3,0.2,0.2C36.1,181.5,36.1,181.4,35.9,181.5 M478.6,181.5c-0.1,0.2,0,0.3,0.2,0.2C479,181.5,478.9,181.4,478.6,181.5
        M220.5,182c0.2,0,0.5,0.1,0.4-0.2C220.8,181.5,220.6,181.9,220.5,182 M250.3,181.6c-0.1,0.2,0.2,0.3,0.3,0.2
        C250.7,181.7,250.4,181.5,250.3,181.6 M126.8,181.7C126.8,182.2,127.2,181.5,126.8,181.7 M263,181.8c0,0.2,0,0.3,0.2,0.2
        C263.3,181.8,263.2,181.7,263,181.8 M197.1,181.9c-0.2,0.3,0.1,0.6,0.3,0.8c0.1-0.3,0.2-0.6,0.3-0.9c-0.1,0.1-0.3,0.2-0.4,0.2
        C197.3,182,197.2,181.9,197.1,181.9 M400.3,181.9c-0.1,0.2,0,0.3,0.2,0.2C400.6,181.9,400.5,181.8,400.3,181.9 M196.2,182.2
        c0.1,0,0.2,0,0.3,0c0,0.3,0.3,0.5,0.3,0.9l0.2,0c0,0.1,0,0.2-0.1,0.2c0.4,0,0.3,0.4,0.5,0.6c0.2-0.7-0.5-1.1-0.5-1.8
        C196.7,182.1,196.4,182,196.2,182.2 M210,182.4L210,182.4l0,0.2h0.1l0.1-0.1C210.2,182.5,210.1,182.4,210,182.4 M210.9,182.6
        C211,182.7,211,182.7,210.9,182.6 M209.4,183.1c0.3,0.1,0.4-0.2,0.4-0.4C209.5,182.7,209.4,182.9,209.4,183.1 M113.6,183.8
        c-0.1,0.2,0,0.3,0.2,0.2C113.9,183.7,113.8,183.7,113.6,183.8 M106.7,184.4C106.8,184.5,106.8,184.5,106.7,184.4 M332.5,184.7
        c0.2-0.1,0.2-0.2,0.1-0.3C332.4,184.5,332.4,184.6,332.5,184.7 M284.4,184.7C284.5,185.2,284.8,184.5,284.4,184.7 M238.1,185.3
        C237.8,185.7,238.5,185.4,238.1,185.3 M313.5,185.8c-0.1,0.2,0,0.3,0.2,0.2C313.8,185.8,313.7,185.7,313.5,185.8 M152.5,186.1
        c0.2,0.2,0.4,0.3,0.7,0.3c0-0.1,0.1-0.2,0.2-0.3c-0.2-0.1-0.5,0-0.7,0.1c0-0.1,0.1-0.3,0.1-0.4C152.7,185.9,152.5,185.9,152.5,186.1
        M261.9,186.7c0.1-0.1,0.2-0.3,0.2-0.4C262,186,261.5,186.6,261.9,186.7 M81.8,186.3C81.9,186.4,81.9,186.4,81.8,186.3 M113.2,186.3
        c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.1-0.1,0.2-0.2,0.3c-0.2,0.1-0.2,0.3,0,0.4c0-0.4,0.3-0.9,0.7-1.1
        C113.5,186.4,113.3,186.3,113.2,186.3 M401.2,186.5c0.2,0.2,0.3,0.3,0.5,0.5c0.1,0.1,0,0.6,0.3,0.4c0-0.2-0.2-0.4-0.3-0.6
        c0-0.2,0-0.4-0.1-0.6C401.5,186.4,401.1,186.3,401.2,186.5 M432.9,186.3C432.9,186.4,432.9,186.4,432.9,186.3 M262.3,186.5
        c-0.2,0.3,0.5,0.2,0.4-0.1C262.6,186.4,262.4,186.5,262.3,186.5 M239.1,186.6C238.9,187.1,239.5,186.6,239.1,186.6 M246.9,186.7
        c-0.1,0.2,0,0.3,0.2,0.2C247.2,186.7,247.1,186.6,246.9,186.7 M312.4,187.1c0.2,0,0.3-0.1,0.2-0.3
        C312.5,186.9,312.4,186.9,312.4,187.1 M285.3,187.5C285.4,187.5,285.4,187.5,285.3,187.5 M261.2,187.6
        C261.3,187.7,261.3,187.7,261.2,187.6 M345.7,187.8l0,0.1C345.9,188.1,346,187.5,345.7,187.8 M34.7,188
        C34.8,188.5,35.1,187.8,34.7,188 M479.8,188C479.8,188.5,480.2,187.8,479.8,188 M292.4,188.4L292.4,188.4L292.4,188.4L292.4,188.4
        L292.4,188.4 M153.9,188.8C153.6,189.2,154.3,188.8,153.9,188.8 M342.3,188.7C342.4,188.8,342.4,188.8,342.3,188.7 M360.8,188.8
        C360.5,189.2,361.2,188.8,360.8,188.8 M409,188.7C409.1,188.8,409.1,188.8,409,188.7 M260.8,188.8c0,0.2-0.1,0.3-0.1,0.5
        c0,0.1-0.1,0.2-0.2,0.3c0.2-0.1,0.4-0.1,0.5-0.2C261.2,189.1,261,188.9,260.8,188.8 M287.8,188.9c0,0.2,0,0.3,0.2,0.3
        C288.1,188.9,288,188.9,287.8,188.9 M360.4,188.9C360.4,189,360.4,189,360.4,188.9 M282.9,189.2
        C282.9,189.6,283.3,188.9,282.9,189.2 M431.3,190c0.1-0.2,0.1-0.4,0.1-0.6C431.2,189.4,431.1,189.9,431.3,190 M237.6,189.8
        c0,0.1,0.1,0.2,0.2,0.4c0.4-0.1,0.6,0.2,0.8,0.5l-0.2,0.1c0.2,0.4,0.6,0.2,1,0.3c0.2,0.2,0.4,0.4,0.6,0.6c0.1-0.1,0.2-0.2,0.3-0.2
        c0,0.1,0,0.3,0.1,0.3c0.3,0,0.4,0.2,0.7,0.3c0.2-0.1,0.3-0.1,0.5-0.2c-0.1,0.1-0.2,0.2-0.3,0.2c0.5,0.2,0.8,0.8,1.4,0.7
        c-0.1-0.2-0.1-0.4-0.2-0.6c0.2,0.2,0.3,0.4,0.6,0.4c0-0.1-0.1-0.3-0.1-0.3c0-0.1,0.1-0.2,0.2-0.3c0,0,0.1,0,0.2,0.1
        c0.1-0.3,0.3-0.6,0.4-0.9c0.1,0,0.2,0.1,0.2,0.2c0.2-0.3,0.4-0.5,0.6-0.8c-0.5,0-1,0-1.5,0.3c-0.3,0-0.6,0.4-0.8,0
        c-0.3,0.1-0.6,0.1-0.7,0.3c-0.1,0-0.3-0.1-0.4-0.1c0,0.3,0.2,0.5,0.3,0.8c-0.3-0.1-0.4-0.4-0.6-0.7c-0.2,0-0.5-0.1-0.7-0.2
        c0,0.1,0.1,0.3,0.1,0.4c-0.2-0.1-0.3-0.1-0.4-0.2c0.1-0.1,0.2-0.2,0.2-0.2c-0.2-0.3-0.6-0.3-0.9-0.2c-0.3-0.2-0.6-0.7-1-0.6
        C237.8,190,237.7,189.9,237.6,189.8 M255.8,189.9c0.1,0.1,0.1,0.3,0.2,0.4c-0.2,0.1-0.5,0.2-0.7,0.2c0,0.1,0.1,0.3,0.1,0.4
        c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0.2-0.2,0.3-0.3,0.3c0,0,0,0.1,0,0.2c-0.1,0-0.2,0-0.2-0.1c0,0.1,0,0.3-0.1,0.3
        c-0.4,0.3-0.8,0.7-1.2,1.1c-0.4,0.4-0.7,0.9-1.2,1.1c-0.1,0.2-0.2,0.4-0.4,0.6c-0.3,0.5-0.7,1-1.1,1.5c-0.3,0.4-0.7,0.7-0.8,1.2
        c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0.4-0.3,0.7-0.5,1c0.2,0.3,0,0.6-0.3,0.8c0.1,0.4-0.3,0.7-0.3,1.1c-0.1,0.6-0.4,1.1-0.6,1.6
        c-0.1,0.3-0.1,0.7-0.2,1c-0.2,0.4,0,0.9-0.4,1.3c0.1,0.2,0.1,0.5,0.2,0.7c0.4-0.5,0.9-0.9,1.2-1.4c0.3-0.5,0.9-0.9,1.1-1.5
        c0.1-0.2,0.4-0.2,0.6-0.3c0.1-0.3-0.1-0.6,0-0.8c0.2-0.2,0.5-0.3,0.6-0.6c0-0.5,0.6-0.5,0.8-0.9c0.2-0.4,0.7-0.7,0.7-1.1
        c0.2-0.1,0.4-0.3,0.3-0.6c0.4-0.3,0.4-0.9,0.8-1.2c0-0.4,0.3-0.6,0.4-1c0.3,0,0.3-0.3,0.3-0.6c0.1,0,0.2-0.1,0.2-0.2
        c0.3-0.8,0.7-1.4,1-2.2c0.4-0.5,0.4-1.2,0.6-1.7c0.1-0.2,0.1-0.3,0.1-0.5C256.2,189.9,256,189.9,255.8,189.9 M112.1,190.2
        C112.2,190.2,112.2,190.2,112.1,190.2 M402.6,190.2C402.7,190.2,402.7,190.2,402.6,190.2 M374.1,190.3c-0.1,0.2,0,0.3,0.2,0.2
        C374.4,190.3,374.3,190.2,374.1,190.3 M112.1,191.1c0.1,0,0.2,0.1,0.2,0.2c0-0.3,0.2-0.5,0.3-0.7
        C112.3,190.5,112.2,190.9,112.1,191.1 M291.7,190.6C291.8,190.7,291.8,190.7,291.7,190.6 M334.8,190.6c-0.1,0.2,0,0.3,0.2,0.2
        C335,190.5,334.9,190.5,334.8,190.6 M34.4,190.9C34.2,191.4,34.9,190.9,34.4,190.9 M290,191C290,191.1,290,191.1,290,191
        M140.1,191.3C139.8,191.7,140.6,191.3,140.1,191.3 M154.6,191.4c0,0.3,0.4,0.2,0.5,0C155,191.2,154.6,191.2,154.6,191.4
        M155.6,191.4C155.7,191.5,155.7,191.5,155.6,191.4 M291.2,191.5c0.1,0.2,0.2,0.2,0.3,0C291.4,191.4,291.3,191.4,291.2,191.5
        M359,191.4C359.1,191.5,359.1,191.5,359,191.4 M345.5,191.7c0,0.2,0,0.3,0.2,0.2C345.8,191.7,345.7,191.7,345.5,191.7 M155.2,191.9
        C155.2,192.3,155.6,191.6,155.2,191.9 M359.4,191.8c-0.1,0.2,0,0.3,0.2,0.2C359.6,191.8,359.6,191.8,359.4,191.8 M155.5,192.2
        c-0.1,0-0.3,0.1-0.4,0.2c0.1,0,0.3-0.1,0.4-0.1l0,0.2l0.3-0.1l-0.1,0.2l0.2-0.1l-0.1,0.3c0.1-0.1,0.2-0.2,0.3-0.4l0.1,0.3
        c0.1,0,0.2-0.1,0.3-0.1c0,0.2,0.1,0.3,0.2,0.4c0.1-0.1,0.2-0.2,0.2-0.2c0.1,0,0.2-0.1,0.3-0.1l-0.1-0.2c-0.3,0.2-0.4-0.2-0.5-0.3
        c-0.1,0-0.3,0.1-0.4,0.1c-0.2-0.2-0.4,0-0.5,0.1C155.6,192.4,155.6,192.3,155.5,192.2 M285.7,192.2
        C285.5,192.6,286.2,192.2,285.7,192.2 M480.8,192.2C480.6,192.6,481.3,192.2,480.8,192.2 M83.5,192.3
        C83.6,192.4,83.6,192.4,83.5,192.3 M156.7,192.3C156.8,192.4,156.8,192.4,156.7,192.3 M294.7,192.1c0,0.1,0.1,0.4,0.2,0.5
        c-0.1,0-0.3-0.1-0.4-0.1c0,0.1,0,0.3,0,0.5c0.2-0.3,0.5-0.6,0.9-0.4c-0.1,0-0.2-0.1-0.2-0.1C295,192.3,294.9,192.2,294.7,192.1
        M307.7,192.3c-0.2,0.1-0.2,0.2,0,0.3C307.9,192.4,307.9,192.4,307.7,192.3 M358,192.3C358,192.4,358,192.4,358,192.3 M408.1,192.3
        c-0.1,0.1-0.2,0.2-0.2,0.3c0.1,0.2,0.1,0.5,0.4,0.4c-0.1-0.2-0.2-0.3-0.3-0.5c0.1,0,0.2-0.1,0.2-0.2
        C408.2,192.3,408.1,192.3,408.1,192.3 M343.2,192.7c0.2-0.1,0.2-0.2,0-0.3C343.1,192.5,343.1,192.6,343.2,192.7 M357.5,192.9
        c0.1,0,0.2,0,0.3,0c0.2,0.3,0.4,0.4,0.7,0.5c0,0.1-0.1,0.2-0.2,0.3c0.1,0,0.2,0,0.2,0l0-0.2c0.1-0.3,0.4-0.4,0.7-0.4l0,0
        c-0.2,0-0.5,0-0.6,0.2c-0.2,0.2-0.4-0.1-0.6-0.1l0.3-0.2C358.1,192.5,357.5,192.4,357.5,192.9 M34.3,192.8l0,0.1
        C34.6,193.2,34.6,192.6,34.3,192.8 M371.6,192.9C371.7,192.9,371.7,192.9,371.6,192.9 M156.9,193c0,0.1,0,0.3,0,0.3
        c0,0-0.1,0.1-0.2,0.1c0.1,0.3,0.6,0.2,0.9,0.3c-0.1-0.3-0.3-0.5-0.3-0.8C157.2,193,157,193,156.9,193 M190.4,193
        c0.1,0.1,0.2,0.2,0.3,0.3l0,0.1l-0.2,0c0.1,0.3,0.3,0.5,0.4,0.7c0,0.1-0.1,0.2-0.2,0.2c0.4,0.1,0.4,0.6,0.4,0.9
        c0.1,0.3,0.2,0.6,0.5,0.8c0,0.5,0.4,0.8,0.5,1.2c0.2,0.4,0.4,0.9,0.7,1.3c0.3,0.3,0.5,0.6,0.5,0.9c0.2,0.2,0.4,0.3,0.4,0.5
        c0.1,0.3,0.1,0.7,0.5,0.7c0.2,0.5,0.5,1,0.9,1.4c0,0.2,0.1,0.3,0.1,0.5c0.1,0,0.3,0.1,0.4,0.1c0,0.1,0,0.3,0,0.4
        c0.3,0.4,0.8,0.7,0.9,1.2c0.5,0.4,0.6,1,1.1,1.4c0.2,0.5,0.8,0.8,0.9,1.3c0.4,0.1,0.5,0.5,0.7,0.9c0.5,0.1,0.5,0.7,0.8,1
        c0.2,0.3,0.6,0.4,0.8,0.7c0.3,0.5,0.8,0.7,1.2,1.1c0.5,0.6,1.2,1,1.6,1.7c0.2,0.2,0.5,0.3,0.7,0.5c0.3,0.2,0.5,0.7,0.8,0.9
        c0.4,0.3,0.6,0.7,1.1,0.8c0.4,0.5,1,0.8,1.3,1.3c0.2,0.2,0.3,0.5,0.6,0.5c0.2,0.1,0.3,0.4,0.5,0.5c0.2,0.2,0.5,0.4,0.6,0.7
        c0.1,0,0.2,0.1,0.2,0.1c0-0.3-0.3-0.5-0.3-0.8c0.1-0.3-0.2-0.6-0.4-0.8c0.1-0.5-0.2-1-0.1-1.4c0-0.5,0-0.9,0-1.4
        c0-0.7,0.2-1.4,0.2-2.1c0.3-0.6,0.4-1.4,0.6-2.1c-0.1,0-0.3,0.1-0.4,0.1l0-0.2c-0.1,0-0.2,0-0.2,0l0-0.2c-0.4,0.1-0.7-0.1-0.9-0.5
        c0.1,0,0.3,0,0.4,0l-0.1,0.1c0,0,0.1-0.1,0.2-0.2c-0.3,0.1-0.4-0.2-0.4-0.3c0,0-0.1,0.1-0.1,0.1c-0.2,0.1-0.4,0.1-0.7,0.1
        c0-0.1,0-0.2,0-0.3c-0.5,0.1-0.7-0.7-1.2-0.4c-0.3-0.7-1.2-0.8-1.5-1.5c-0.2,0.2-0.5,0.3-0.8,0.2c-0.2-0.3,0-0.8-0.4-0.9
        c-0.1-0.2-0.2-0.3-0.3-0.3c0.1,0.2,0.1,0.3,0.2,0.5c-0.3-0.2-0.3-0.6-0.6-0.7c0-0.1,0-0.2,0-0.3c-0.1,0-0.2,0.1-0.4,0.2
        c0-0.2-0.1-0.4-0.1-0.6c-0.4,0,0.3,0.5-0.2,0.4c-0.2-0.4-0.4-0.7-0.6-1c-0.2-0.3-0.6-0.5-0.8-0.8c0,0.1,0,0.3,0,0.3
        c-0.2,0.1-0.4-0.1-0.6-0.1c0.1,0,0.3,0,0.4,0c-0.1-0.2-0.2-0.5-0.3-0.7l-0.1,0.3c-0.2-0.2-0.4-0.3-0.6-0.2c-0.1-0.2-0.1-0.3-0.2-0.4
        c0.2,0.1,0.4,0.2,0.6,0.3c-0.1-0.1-0.1-0.3-0.2-0.4c-0.4,0-0.8,0-1.1-0.4c0.3-0.2,0.5,0.2,0.7,0.3c0.1-0.3-0.2-0.4-0.3-0.6
        c-0.2-0.1-0.5-0.2-0.7-0.2c0,0.1,0,0.2,0,0.2c-0.1-0.2-0.3-0.3-0.5-0.4c0-0.3,0.1-0.5,0.2-0.8c-0.4-0.2-0.4-0.6-0.7-0.8
        c-0.3-0.2,0.5,0.4-0.1,0.3c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.1,0.1-0.2,0.1-0.2c-0.2-0.2-0.2-0.6-0.6-0.7c0,0.2,0,0.5,0,0.7
        c-0.1-0.1-0.6-0.5-0.1-0.3c0-0.1,0-0.4-0.1-0.6c-0.3-0.2-0.6-0.5-0.8-0.8c-0.1,0-0.2,0.1-0.3,0.2c0,0.2,0.1,0.3,0.1,0.5
        c-0.3-0.1-0.1-0.4-0.1-0.5c-0.5,0-0.7-0.6-1-0.8c-0.2-0.2-0.6-0.2-0.5-0.6c0.3,0.2,0.6,0.5,1,0.6c-0.3,0.3,0.2,0.7,0.5,0.6
        c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0-0.2-0.1-0.2c-0.3-0.2-0.6-0.5-0.7-0.8c-0.3-0.3-0.7-0.5-1-0.9c-0.3,0.5-0.7-0.1-0.8-0.4
        c-0.1,0-0.2,0.1-0.3,0.2c0-0.1,0-0.3,0-0.4l-0.2,0c0-0.1,0-0.2,0-0.3c-0.2,0.1-0.4,0-0.5-0.2c0.1,0,0.4,0.1,0.5,0.1
        c-0.2-0.3-0.7-0.3-0.8-0.8C190.7,193,190.5,193,190.4,193 M357.1,193.8c0.3,0,0.7-0.1,1-0.3c-0.2-0.1-0.4-0.2-0.3-0.4
        C357.4,192.9,357.3,193.5,357.1,193.8 M141.4,193.3c-0.1,0.2,0.2,0.3,0.3,0.2C141.9,193.3,141.5,193.1,141.4,193.3 M372.5,193.2
        c-0.1,0.2,0.2,0.3,0.3,0.2C373,193.3,372.6,193.1,372.5,193.2 M106.6,193.4c-0.1,0.2,0,0.3,0.2,0.2
        C106.9,193.4,106.8,193.4,106.6,193.4 M407.9,193.4c-0.1,0.2,0,0.3,0.2,0.2C408.2,193.4,408.2,193.4,407.9,193.4 M331.3,193.6
        c-0.1,0.2,0.1,0.4,0.3,0.4C331.7,193.8,331.5,193.6,331.3,193.6 M344.4,194.1c0.1-0.2,0.2-0.5-0.1-0.6
        C344.2,193.6,344.2,194.1,344.4,194.1 M157,194l0.3,0c-0.1,0.1-0.1,0.2-0.2,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.2,0,0.5,0,0.7,0.1
        c0,0.1,0,0.3,0,0.4c0.5-0.4-0.1-0.9-0.3-1.2C157.4,193.9,156.9,193.5,157,194 M191.6,193.8c0.1,0.2,0.1,0.3,0.2,0.5
        c0.4,0.1,0.6,0.5,1,0.6c-0.1-0.2-0.1-0.4-0.3-0.5C192.2,194.1,191.9,193.9,191.6,193.8 M287.2,193.9c-0.1,0.2,0,0.3,0.2,0.2
        C287.5,193.9,287.4,193.8,287.2,193.9 M357,194c0.1,0.1,0.2,0.2,0.3,0.3c0,0-0.1,0.1-0.1,0.2c-0.1-0.1-0.3-0.1-0.4-0.2
        c-0.1,0.3-0.5,0.7,0,1c0-0.1,0-0.4,0-0.5c0.3,0,0.7,0,0.9-0.3c-0.1-0.1-0.2-0.2-0.2-0.4l0.3,0l0-0.3
        C357.5,193.8,357.2,193.9,357,194 M156.5,194c-0.1,0.2,0,0.3,0.2,0.2C156.7,194,156.7,193.9,156.5,194 M358.2,194.2
        c0.2-0.1,0.2-0.2,0-0.3C358,194,358,194.1,358.2,194.2 M156.2,194.3c-0.1,0.2,0,0.3,0.2,0.2C156.5,194.3,156.4,194.2,156.2,194.3
        M358.4,194.3c-0.1,0.2,0,0.3,0.2,0.2C358.7,194.3,358.6,194.2,358.4,194.3 M293.8,194.4c-0.1,0.2,0,0.3,0.2,0.2
        C294.1,194.4,294,194.3,293.8,194.4 M321.5,195c-0.2,0.1-0.5,0.3-0.7,0.4c-0.7,0.5-1.4,0.9-2.1,1.3c-0.5,0.1-0.8,0.4-1.2,0.6
        c-0.3,0.3-0.7,0.4-1,0.7c-0.1,0.1-0.3,0.1-0.5,0.2c-0.2,0.4-0.7,0.4-0.9,0.7c-0.1,0-0.2,0-0.3-0.1c0,0.3-0.3,0.3-0.5,0.4
        c-0.3,0.5-0.9,0.7-1.4,1.1c-0.1,0.4-0.6,0.3-0.8,0.6c-0.4,0.1-0.6,0.4-0.9,0.6c-0.6,0.4-1.1,0.9-1.8,1.3c-0.6,0.4-1.2,0.6-1.6,1.2
        c-0.6,0.4-1.1,0.8-1.7,1.2c-0.2,0.2-0.4,0.3-0.7,0.5c-0.1,0-0.2,0.1-0.2,0.1c-0.4,0.6-1.1,0.8-1.5,1.4c-0.3,0.4-0.8,0.7-1.3,1
        c0,0.1,0,0.3,0,0.4c-0.2-0.1-0.3-0.1-0.5-0.2c-0.1,0.1-0.2,0.2-0.3,0.3l0,0.1c-0.3,0.1-0.5,0.4-0.8,0.5c-0.1,0.5-1,0.6-1.1,1.2
        c-0.2,0.7-0.1,1.5-0.5,2.1l-0.1-0.2c0,0,0,0.1-0.1,0.2c0.1,0.1,0.2,0.2,0.3,0.2c-0.2,0.2-0.4,0.4-0.3,0.7c0.1,0.6-0.3,1.2,0.1,1.6
        c0,0.1,0,0.3,0,0.4l0.2,0c0,0.3,0,0.6,0,0.9c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.4,0.5-0.6,0.7-0.9c0.5-0.5,1-1,1.4-1.6
        c0.5-0.6,1.3-0.9,1.6-1.6c0.5-0.5,1.2-0.9,1.8-1.3c0.5-0.5,1.1-0.9,1.8-1.2c0.4-0.2,0.7-0.6,1.1-0.7c0.5-0.1,0.8-0.4,1.2-0.6
        c0.6-0.2,1.1-0.5,1.6-0.8c0.2-0.2,0.4-0.3,0.7-0.3c0.3-0.3,0.8-0.4,1.1-0.7c0.1,0,0.3-0.1,0.4-0.1c0.1-0.2,0.5-0.4,0.6-0.1
        c0-0.1,0-0.2,0-0.3l0.2,0c0,0.1,0.1,0.2,0.2,0.3c0-0.2-0.1-0.3-0.1-0.5c0.2-0.1,0.4-0.1,0.6,0c0-0.1,0.1-0.3,0.1-0.4
        c0,0.1,0.2,0.2,0.2,0.3c0.1-0.1,0.2-0.2,0.2-0.2c-0.1-0.1-0.2-0.2-0.3-0.2c0.3-0.1,0.5-0.2,0.8-0.3c0.1,0,0.3-0.1,0.4-0.1
        c0.2-0.1,0.4-0.1,0.6-0.2c0.2-0.2,0.4-0.2,0.6-0.4l0,0.2c0.1,0,0.3,0,0.4,0c0.1-0.5,0.7-0.4,1.1-0.4l0-0.2c0.2,0,0.4,0,0.5,0.1
        c0.3-0.2,0.6-0.6,1-0.3c0.1,0,0.2-0.1,0.3-0.2c0.2-0.3,0.7,0,0.2,0.2c0.2,0,0.3-0.1,0.5-0.1c-0.1,0-0.2-0.1-0.3-0.1l-0.1-0.2
        c0.3-0.2,0.5-0.4,0.6-0.7c0,0.1,0.1,0.2,0.2,0.3c0.1-0.1,0.2-0.3,0.3-0.4c-0.1,0-0.2,0-0.3,0c0-0.2,0.1-0.5,0.4-0.4
        c0,0.1,0,0.2,0,0.3c0,0,0.1,0,0.2,0c-0.1-0.2-0.2-0.3-0.3-0.5c0.2,0.1,0.4,0,0.6-0.2c-0.2,0-0.5,0.1-0.6,0.1
        c0.2-0.2,0.3-0.6,0.7-0.4c-0.1-0.2-0.1-0.4-0.2-0.6l0.2,0l-0.1-0.3c0.1,0,0.2,0,0.2,0l-0.1,0.4c0.1-0.1,0.3-0.2,0.4-0.3
        c-0.1-0.1-0.2-0.3-0.2-0.4c0.3,0.1,0.7-0.1,0.8-0.5c-0.3,0-0.4,0.2-0.5,0.4c0-0.1-0.1-0.2-0.2-0.3l0.2,0c0-0.2,0-0.4,0-0.7
        c0,0.1,0.1,0.3,0.1,0.4c0.4-0.1,0.4-0.5,0.2-0.7c0.1-0.1,0.3-0.3,0.4-0.4c-0.1,0-0.4,0-0.5,0c0.1-0.1,0.3-0.1,0.4-0.1
        c0-0.1-0.1-0.2-0.1-0.3c0,0,0.1,0,0.2,0l-0.1,0.4c0.5-0.2,0-0.9,0.5-1.2c-0.1,0-0.2-0.1-0.3-0.1c0,0.1-0.1,0.2-0.1,0.2
        c0-0.2-0.1-0.4-0.2-0.5c0,0,0.1,0,0.1,0.1c0,0.2,0.3,0.2,0.4,0.2c-0.1-0.3-0.2-0.5-0.3-0.8c0.1,0,0.3,0,0.4,0
        c-0.2-0.1-0.3-0.2-0.5-0.3c0.1-0.1,0.2-0.3,0.2-0.4l-0.3,0l0-0.1l0.2,0c-0.3-0.5-0.7-1.2-1.4-1.1C322.3,194.7,321.9,194.9,321.5,195
        M29.8,195.1c0.1-0.2,0-0.3-0.2-0.4C29.3,194.7,29.5,195.2,29.8,195.1 M34.2,194.7C34.3,194.8,34.3,194.8,34.2,194.7 M480.5,194.7
        C480.6,194.8,480.6,194.8,480.5,194.7 M485.1,194.7c-0.1,0.1-0.2,0.3-0.1,0.5C485.2,195.3,485.5,194.5,485.1,194.7 M29.2,195
        c-0.2,0-0.2,0.4,0.1,0.3C29.5,195.3,29.4,194.9,29.2,195 M157.5,194.8l0.1,0.4c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.2,0.1-0.3,0.1
        c0.2,0.1,0.5,0.2,0.6,0.4c0.1,0,0.3,0,0.4,0c0-0.1,0-0.3-0.1-0.4l-0.1,0c0-0.1-0.1-0.2-0.1-0.3L157.5,194.8 M357,195.2
        c-0.2,0.2-0.4,0.2-0.5-0.1c-0.1,0.1-0.2,0.3-0.3,0.4c0,0.2,0,0.5-0.2,0.6c-0.3,0.7-0.6,1.4-0.9,2.1c0.3,0,0.3-0.2,0.3-0.5
        c0.2,0,0.4-0.2,0.5,0l0,0c0-0.1-0.1-0.3-0.1-0.3c0.1,0,0.3,0,0.4,0c-0.1-0.1-0.3-0.2-0.4-0.1c0-0.1,0.1-0.3,0.1-0.4
        c0.1,0.1,0.2,0.2,0.3,0.2c0-0.1,0.1-0.3,0.1-0.4c-0.1,0-0.3,0.1-0.4,0.1c0.2-0.2,0.5-0.5,0.5-0.8c0.1,0.1,0.3,0.2,0.5,0.3
        c0-0.2,0-0.4,0.1-0.6c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1-0.1-0.2-0.2-0.3-0.3c0.2,0,0.4,0,0.6,0c-0.1-0.3,0.6-0.3,0.3-0.6
        c-0.1,0.1-0.3,0.2-0.3,0.3l0.1-0.4C357.2,194.9,357,195.1,357,195.2 M485.5,195c-0.2,0-0.2,0.3,0,0.3
        C485.7,195.3,485.7,195,485.5,195 M289.4,195.1c-0.1,0.1,0.1,0.5,0.2,0.4C289.8,195.3,289.6,194.9,289.4,195.1 M144.6,195.3
        c-0.1,0.2,0,0.3,0.2,0.2C144.9,195.3,144.8,195.2,144.6,195.3 M158.1,195.1c-0.2,0.4,0.1,0.7,0.1,1.1c0.1,0.2,0.3,0.4,0.4,0.5
        c-0.1,0-0.3-0.1-0.4-0.1c0,0.1,0.1,0.3,0.1,0.4c0.1,0,0.2-0.1,0.3-0.2c0,0.1,0.1,0.3,0.1,0.4c-0.2,0-0.4,0.2-0.6,0.3
        c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0.1-0.1,0.2-0.3,0.4-0.4c0,0.1,0,0.3,0,0.5c0.1-0.1,0.2-0.2,0.2-0.3
        c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0,0.2,0.1,0.3,0.1c-0.5-0.7-0.6-1.7-1.2-2.4c0-0.1,0-0.3,0-0.4C158.4,195.4,158.2,195.3,158.1,195.1
        M338.3,195.3C338.4,195.4,338.4,195.4,338.3,195.3 M366.4,195.5c0.1,0.1,0.4-0.1,0.4-0.2C366.7,195.2,366.4,195.3,366.4,195.5
        M369.4,195.4c0.1,0.2,0.2,0.2,0.3,0C369.6,195.2,369.5,195.2,369.4,195.4 M289.9,195.4c0,0.2,0.1,0.3,0.2,0.4
        C290.7,195.8,290.2,195.3,289.9,195.4 M269.8,195.7c0.1,0.2,0.6,0.1,0.6-0.2C270.2,195.4,269.9,195.5,269.8,195.7 M157.7,195.6
        c0,0.2,0,0.4,0.1,0.6c0.1-0.1,0.3-0.3,0.4-0.4C158,195.9,157.8,195.8,157.7,195.6 M289.5,195.7c-0.1,0.2,0,0.3,0.2,0.2
        C289.8,195.7,289.8,195.6,289.5,195.7 M290.5,195.7c-0.1,0.2,0.2,0.4,0.4,0.4C291,195.9,290.7,195.6,290.5,195.7 M28.5,196
        c-0.2,0.2,0.1,0.5,0.3,0.3C29,196.1,28.7,195.8,28.5,196 M331.9,196C332,196.1,332,196.1,331.9,196 M485.9,196.3
        c0.2,0.2,0.4-0.1,0.3-0.3C486.1,195.8,485.7,196.1,485.9,196.3 M149.6,196.1c-0.1,0.2,0,0.3,0.2,0.2
        C149.9,196.1,149.8,196,149.6,196.1 M364.5,196.1c-0.2,0.1-0.1,0.4,0.1,0.3C364.8,196.4,364.7,196,364.5,196.1 M270.8,196.3
        c-0.2,0.1-0.2,0.2,0,0.3C270.9,196.4,270.9,196.3,270.8,196.3 M151.2,196.4C151,196.8,151.6,196.4,151.2,196.4 M273.3,196.5
        c0.1,0.1,0.3,0.2,0.4,0.3C274,196.6,273.6,196.3,273.3,196.5 M340.7,196.6C340.8,196.6,340.8,196.6,340.7,196.6 M28.1,197
        c-0.1,0.2,0,0.3,0.2,0.2C28.4,197,28.3,196.9,28.1,197 M360.1,197c0,0.2,0.3,0.4,0.5,0.2C360.7,196.9,360.2,196.9,360.1,197
        M486.5,197c-0.1,0.2,0,0.3,0.2,0.2C486.7,197,486.7,196.9,486.5,197 M28.7,197.1c-0.2,0.1-0.2,0.2-0.2,0.4c0.2,0,0.3-0.1,0.3-0.3
        C28.7,197.2,28.7,197.1,28.7,197.1 M480.9,197.7c0.3,0,0.3-0.4,0.2-0.6C481,197.2,480.6,197.6,480.9,197.7 M207.5,197.4
        C207.5,197.9,207.9,197.2,207.5,197.4 M264.3,197.4C264.3,197.9,264.7,197.2,264.3,197.4 M28,198.4c0.2,0,0.1-0.4-0.1-0.3
        C27.7,198.1,27.8,198.4,28,198.4 M480.9,198.2C480.6,198.6,481.4,198.2,480.9,198.2 M486.8,198.1c-0.2,0.1-0.2,0.2,0,0.3
        C487,198.2,487,198.2,486.8,198.1 M33.4,198.4c-0.1,0-0.2,0.4,0,0.4C33.6,198.8,33.7,198.4,33.4,198.4 M481.2,198.4
        c-0.2,0.1,0,0.4,0.2,0.3C481.5,198.7,481.4,198.3,481.2,198.4 M256.7,198.6C256.8,198.7,256.8,198.7,256.7,198.6 M378.9,198.7
        C378.7,199.1,379.4,198.7,378.9,198.7 M135.5,198.9C135.3,199.3,136,198.8,135.5,198.9 M405.4,199.7c0.2-0.1,0.2-0.2,0.1-0.4
        C405.3,199.1,405.2,199.7,405.4,199.7 M342.3,199.7L342.3,199.7c0,0.2,0,0.4-0.1,0.5l-0.1-0.3c-0.3,0.2-0.6,0.4-0.9,0.6
        c-0.5,0.2-0.9,0.7-1.5,0.9c-0.6,0.4-1.2,0.4-1.7,0.9c-0.5,0.3-1,0.6-1.5,1c-0.2,0.1-0.4,0.2-0.5,0.4c0,0-0.1-0.1-0.2-0.1
        c-0.1,0.1-0.4,0.3-0.5,0.3c0.2,0.2,0.4,0.2,0.6,0c0.1,0,0.2,0.1,0.3,0.2c0-0.2,0-0.4,0.1-0.5c0.2,0.1,0.4,0.2,0.6,0.3
        c0-0.1-0.1-0.3-0.1-0.4l0.1,0l0,0.2c0.3-0.1,0.6-0.2,0.9-0.3c-0.1-0.1-0.3-0.2-0.4-0.4c0.3,0.1,0.6-0.2,0.9,0.1
        c0.1-0.1,0.3-0.2,0.5-0.1l-0.2,0c0.1-0.2,0.3-0.4,0.5-0.5c0.3,0,0.1,0.3,0.1,0.5l0,0c0.2-0.2,0.4-0.4,0.6-0.7c0-0.1-0.1-0.6,0.1-0.4
        l0,0.1c-0.1,0.2,0.2,0.2,0.4,0.3c-0.2,0-0.4,0.2-0.4,0.4c0.1-0.1,0.3-0.2,0.5-0.4c0-0.1,0-0.2,0-0.3c0.3-0.1,0.4-0.4,0.6-0.6
        c0,0.1-0.1,0.4-0.1,0.5c0.1-0.1,0.3-0.3,0.4-0.4c0,0,0.1,0.1,0.1,0.2c-0.2,0.2-0.3,0.4-0.5,0.6c0.1,0,0.3-0.1,0.4-0.1
        c0.1-0.2,0.1-0.5,0.4-0.5c0-0.4,0.4-0.5,0.6-0.8l0,0.2c0,0,0.1,0,0.2,0l-0.1-0.3c0.2,0,0.4,0,0.4-0.3c-0.3,0.1-0.7,0.2-1,0.2
        c0.1,0,0.2,0,0.2-0.1c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.2,0.1,0.3,0.1l0-0.2l0.2,0.1c0-0.1,0-0.3,0.1-0.4c0.1,0.1,0.2,0.2,0.2,0.2
        c0.3-0.2,0.1-0.8-0.1-0.3C342.6,199.8,342.5,199.7,342.3,199.7 M167.7,200.1C167.8,200.2,167.8,200.2,167.7,200.1 M197.7,200.5
        c0.2-0.2,0.5-0.1,0.8,0c-0.2-0.2-0.4-0.4-0.6-0.6C197.8,200.1,197.7,200.4,197.7,200.5 M34.3,200.4c-0.1,0.2,0,0.3,0.2,0.3
        C34.5,200.4,34.5,200.3,34.3,200.4 M27.2,200.7C27.3,200.7,27.3,200.7,27.2,200.7 M258.9,201.1c0,0.3,0.4,0.1,0.3-0.1
        C259.1,200.7,258.8,200.9,258.9,201.1 M274.6,201.3c0.3-0.1,0.8-0.2,0.9-0.5C275.2,200.9,274.7,201,274.6,201.3 M27.2,201
        c-0.1,0.2,0,0.3,0.2,0.2C27.4,200.9,27.4,200.9,27.2,201 M171,201.1C171,201.2,171,201.2,171,201.1 M274,201c0,0.1-0.1,0.3-0.1,0.4
        c-0.1-0.1-0.2-0.2-0.3-0.3c0,0.1,0,0.4,0,0.5c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2,0.1-0.4,0.3-0.4,0.6c-0.2,0-0.4-0.1-0.5,0.2
        c-0.1,0-0.2,0-0.3,0c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.4-0.7,0.3c0,0.1-0.1,0.3-0.1,0.4c-0.5,0-0.8,0.3-1.1,0.6
        c-0.9,0.4-1.5,1.2-2.4,1.6c-0.1,0.3-0.4,0.4-0.7,0.5c-0.6,0.7-1.1,1.6-1.7,2.4c-0.1,0.1-0.3,0.1-0.3,0.3c-0.4,0.8-1,1.4-1.5,2.1
        c-0.6,1.1-1.5,2-2.1,3.2c-0.4,0.5-0.4,1.3-1.1,1.5c0.2,0.7-0.4,1.1-0.6,1.7c-0.2,0.5-0.9,0.9-0.7,1.6c-0.7,0.5-0.6,1.5-1.1,2.2
        c0.1,0.4-0.3,0.6-0.3,1c-0.1,0.5-0.3,0.9-0.6,1.3c0,0.7-0.6,1.2-0.6,1.9c0.3-0.1,0.5-0.2,0.8-0.3c0,0.4,0,0.7,0,1.1
        c0.3,0,0.4-0.3,0.4-0.6c0.2,0,0.3-0.1,0.4-0.2c-0.1,0-0.4,0-0.5,0l0-0.2c0.1,0,0.3-0.1,0.4-0.1c0.1-0.2,0-0.5,0-0.7l0.4,0
        c0,0.1-0.1,0.4-0.1,0.5c0.1,0,0.2-0.1,0.3-0.1c0-0.1-0.1-0.3-0.1-0.4c0.3,0,0.3-0.4,0.4-0.6c-0.3,0.1-0.4,0.2-0.3,0.5
        c-0.2,0-0.3-0.1-0.5-0.1c0.4,0.1,0.2-0.5,0.6-0.5c0-0.3,0.2-0.5,0.4-0.7l-0.4,0.1l0.2-0.3l-0.3,0.1c0,0,0-0.1,0-0.1
        c0.4,0.1,0.2-0.9,0.5-0.3l-0.2,0.1c0,0.1-0.1,0.3-0.1,0.4c0.2-0.1,0.4-0.3,0.6-0.4c-0.1-0.3-0.1-0.7-0.1-1c0.1,0.1,0.3,0.2,0.4,0.3
        c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.2,0.3-0.6-0.1-0.6c0.3-0.3,0.1-0.9,0.7-0.9c0-0.2,0-0.4,0-0.6c0,0,0.1,0,0.1,0l0,0.3l0.2-0.1l0.1-0.3
        c0.1,0.1,0.2,0.2,0.2,0.3c-0.1-0.4-0.2-0.8,0.1-1.1c0.1,0,0.2,0.1,0.3,0.1c0.4-0.2,0.1-0.6,0.2-0.9l-0.2,0c0-0.4,0.3-0.3,0.5-0.1
        c-0.1-0.2-0.1-0.4-0.2-0.6c0.1,0,0.2,0.1,0.3,0.1c0-0.1-0.1-0.3-0.1-0.3c0.1,0,0.3,0,0.3,0c0-0.1-0.1-0.2-0.1-0.3
        c0.3,0.1,0.6,0.2,0.6-0.3c-0.1,0-0.2,0-0.3,0c0-0.1,0.1-0.2,0.1-0.3l0.2,0.1c0-0.1-0.1-0.3-0.2-0.4c0.3,0.1,0.5-0.1,0.8-0.3
        c0.2,0,0.4,0,0.5-0.2c-0.2,0-0.8,0.1-0.6-0.3l0.2-0.1l0.1,0.3c0.1,0,0.2-0.1,0.2-0.2c-0.1,0-0.2-0.1-0.3-0.2
        c-0.1-0.4,0.4-0.1,0.5-0.1c0-0.2,0.1-0.3,0.2-0.4c-0.1,0-0.3,0-0.4,0c0.1-0.2,0.3-0.3,0.5-0.5c0.1,0.1,0.2,0.2,0.2,0.2
        c0-0.1,0.1-0.2,0.1-0.3l-0.2,0l0-0.3c0.2,0,0.3,0,0.4-0.2l-0.3-0.1c0.2-0.2,0.3-0.3,0.6-0.4c-0.1-0.1-0.3-0.1-0.5-0.1l0,0
        c0.3,0,0.9-0.3,0.5-0.6c0.2-0.1,0.3-0.2,0.6-0.2c-0.1-0.1-0.1-0.3-0.2-0.4c0.2,0.3,0.4,0.1,0.6,0c0-0.1,0-0.2,0-0.3
        c0,0,0.1-0.1,0.2-0.2c-0.3-0.1-0.3,0.5-0.6,0.4c0-0.1,0.1-0.4,0.1-0.5c0.3,0.1,0.5-0.1,0.5-0.4l-0.2,0c0.1-0.2,0.3-0.3,0.5-0.2
        l-0.1-0.3l0.2,0c0,0.1,0,0.3-0.1,0.4c-0.1,0-0.2,0.1-0.3,0.1c0,0.2,0.1,0.2,0.2,0.2c0.3-0.3,0.3-0.7,0.3-1c0.1,0.1,0.2,0.2,0.3,0.3
        c0.1,0,0.3,0,0.4,0c0,0,0.1-0.1,0.2-0.1c-0.1,0-0.4,0.1-0.5,0.1c0-0.1,0.1-0.3,0.2-0.4c-0.2,0.1-0.4,0.1-0.7,0.2
        c0.2-0.4,0.7-0.4,0.7-0.9l0.2,0.1c0,0.1-0.1,0.3-0.1,0.4c0.2-0.1,0.5-0.3,0.7-0.4c0-0.4,0-0.9,0.4-1c0,0.1,0,0.3,0,0.4l0.2,0
        c0,0.1,0,0.2,0,0.2c0.3,0.2,0.2-0.4,0-0.3c0.1-0.2,0.3-0.3,0.4-0.5c0.1-0.1,0.3-0.2,0.5-0.3c0.2-0.2-0.2-0.5,0.2-0.5
        c0-0.1,0-0.2,0-0.3c0.4-0.1,0.7-0.5,1-0.8c0,0.3-0.2,0.5-0.3,0.7c0.3-0.1,0.7-0.3,0.5-0.6c0.2-0.1,0.2-0.4,0.3-0.5
        c0,0.1,0,0.3-0.1,0.4l0.3,0c-0.2-0.5,0.4-0.2,0.7-0.2c-0.1,0-0.2-0.1-0.3-0.2c0-0.3,0.5-0.2,0.7-0.3c0.1,0.1,0.2,0.2,0.2,0.2
        c0-0.1-0.1-0.3-0.1-0.4c0.7-0.1,1.4-0.3,2.1-0.1c0-0.1-0.1-0.3,0-0.4c0.1,0,0.2,0.1,0.2,0.2c0.4,0,0.7,0.2,1.1,0.2
        c0.2,0.1,0.5,0.2,0.6,0.4c0.1,0,0.2,0,0.3-0.1l0,0.2c0.1,0,0.3,0,0.4,0c0,0.1,0,0.2,0,0.2c0.5,0.3,0.9,0.8,0.9,1.4l0.2-0.1
        c0,0.1-0.1,0.3-0.1,0.4c0.4-0.1,0.1,0.4,0.2,0.7c0.1,0.2,0.2,0.4,0.2,0.6l-0.3,0c0.1,0,0.3,0.1,0.4,0.1c0,0.3,0,0.5,0,0.8l-0.2,0
        c0.1,0,0.2,0.1,0.3,0.1c-0.1,0.1-0.2,0.2-0.2,0.3l0.2,0c0,0.7-0.3,1.3-0.4,2c-0.1,0.1-0.1,0.3-0.2,0.4c-0.2,0.4-0.1,1-0.5,1.2
        c-0.1,0.2,0,0.4-0.3,0.5c-0.1,0.3-0.4,0.6-0.5,0.9c-0.3,0-0.3,0.2-0.3,0.4c-0.1,0.2-0.3,0.4-0.5,0.5c-0.1,0.3-0.2,0.6-0.4,0.8
        c-0.1,0.2-0.2,0.3-0.4,0.4c-0.1,0.1-0.2,0.3-0.3,0.4c0.3-0.4,0.4-0.8,0.8-1.1c0.2-0.1,0.2-0.3,0.2-0.5c0.1,0,0.2-0.1,0.3-0.2
        c0.1-0.7,0.7-1.1,0.9-1.7l0.1,0l0-0.3l-0.2,0c0,0,0-0.1,0-0.1l0.1,0c0.4-0.3,0.1-0.9,0.5-1.1c-0.1,0-0.2-0.1-0.2-0.2
        c0,0,0.1-0.1,0.2-0.1c0.2-0.4,0.1-0.9,0.1-1.4l0.2,0.1c0-0.1-0.1-0.3-0.2-0.4l0.3,0c-0.1-0.1-0.2-0.3-0.4-0.3c0,0,0-0.1,0-0.1
        c0.1,0,0.3,0,0.4,0c0-0.1-0.1-0.3-0.2-0.4c0.1-0.1,0.1-0.3,0.2-0.5c-0.1-0.1-0.2-0.2-0.2-0.3l0.3,0c0-0.1,0-0.2,0-0.3
        c-0.3,0-0.3-0.2-0.3-0.4c-0.2-0.2-0.2-0.3,0-0.5c-0.1-0.1-0.2-0.2-0.3-0.3l0.4,0c-0.1-0.1-0.3-0.1-0.4-0.2c0-0.1,0-0.2,0-0.2
        c-0.1,0-0.2,0-0.2,0c0-0.1,0-0.3,0-0.4c-0.3-0.3-0.5-0.7-0.9-1c-0.7-0.3-1.4-0.2-2.1-0.3c0,0.1,0.1,0.2,0.2,0.2
        c-0.6,0-1,0.6-1.6,0.6c-0.5,0.3-0.8,0.7-1.1,1.2c-0.2,0.2-0.4,0.4-0.7,0.6c-0.1,0.3-0.3,0.6-0.5,0.8c-0.3,0.3-0.4,0.7-0.7,0.9
        c-0.1,0.3-0.3,0.6-0.6,0.6c-0.1,0.2-0.1,0.5-0.3,0.5c-0.4,0.7-0.8,1.4-1.2,2.1c-0.2,0.2-0.4,0.5-0.5,0.8c0.2,0,0.4-0.1,0.5-0.1
        c-0.2,0.2-0.6,0.2-0.3,0.6c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1,0.1-0.2,0.3-0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.2c-0.1,0-0.4,0-0.5,0
        c0,0.1,0,0.3,0,0.4c-0.3,0.2-0.3,0.6-0.4,1c0,0-0.2,0.1-0.2,0.1l-0.1,0.3c0.2,0,0.5,0,0.8,0c0,0,0,0.1,0,0.1c-0.3,0-0.5,0-0.8,0
        c-0.2,0.3-0.3,0.5-0.5,0.8c0.1,0,0.3,0,0.4,0c0,0,0,0,0,0l-0.4,0c0.1,0.1,0.3,0.2,0.4,0.3c-0.2,0-0.4-0.2-0.5-0.3
        c-0.1,0.4-0.4,0.6-0.5,0.9c-0.2,0-0.3,0.1-0.2,0.3c-0.2,0.2-0.3,0.4-0.2,0.7c-0.1,0-0.2,0-0.3,0c0,0.1,0,0.2,0.1,0.3
        c-0.1,0-0.2,0-0.3,0c0.1,0.1,0.2,0.4,0.3,0.5l-0.3-0.1c-0.4,0.5-0.6,1-0.8,1.5c0.1,0.2,0.2,0.5,0.3,0.7c0,0-0.1,0.1-0.1,0.1
        c0-0.3-0.1-0.5-0.2-0.8c-0.1,0.2-0.3,0.3-0.4,0.5c0.1,0.3-0.3,0.5-0.5,0.8c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.4,0.5-0.3,0.8
        c0.1,0.3-0.4,0.5-0.4,0.8c-0.1,0.1-0.2,0.1-0.3,0.2c0.1,0,0.3-0.1,0.5-0.1c0,0.1-0.1,0.4-0.2,0.5c0,0-0.1,0-0.2,0
        c-0.1,0.1-0.3,0.2-0.4,0.3c0.2,0,0.3-0.2,0.4-0.2c0,0.1,0,0.4,0.1,0.5c-0.1,0.1-0.2,0.3-0.2,0.5l0.3-0.1c0,0,0,0.1,0,0.2
        c-0.5-0.2-0.3,0.3-0.1,0.4c-0.1,0-0.3,0-0.5,0c0.1,0.1,0.2,0.2,0.3,0.3c-0.4,0.1-0.1,0.3,0,0.5c-0.1-0.1-0.2-0.3-0.4-0.4
        c0,0.2-0.1,0.3-0.1,0.5l0.2,0c-0.1,0.2-0.3,0.3-0.4,0.4c0.1,0,0.3-0.1,0.4-0.1c0,0.2,0,0.3,0,0.5l-0.1-0.1c0,0.7,0.2,1.4-0.1,2.1
        c-0.2,0.3,0.1,0.7-0.1,1c0.2,0.4-0.1,0.8-0.3,1.2c0.3,0.5,0.1,1.1-0.1,1.6c0.1,0.1,0.2,0.2,0.3,0.3c0,0.3-0.1,0.6-0.3,0.9
        c-0.2,1,0.1,2-0.4,2.9c0.5,0.7-0.1,1.6-0.1,2.4c0.2,0.7-0.1,1.4-0.2,2.1c0.1,0.3,0.1,0.7,0,1c0.1,0.1,0.1,0.3,0.2,0.4
        c-0.1,0.5-0.1,0.9-0.2,1.4c0.1,0.3,0,0.5-0.1,0.7c0,0.2,0.1,0.5,0.2,0.7c-0.1,0.1-0.2,0.3-0.3,0.4c0.2,0.4,0.2,0.9,0,1.3
        c0.1,0.3,0.2,0.5-0.1,0.8c-0.1,0.6,0.1,1.3,0,1.9c-0.1,1-0.2,2-0.4,3c0.1,0.3,0.1,0.6,0.1,0.9c-0.1,0.6,0.3,1.3-0.1,1.9
        c0.1,0.2,0.1,0.5,0.1,0.7c-0.1,0.4,0.2,0.9-0.1,1.3c0.1,0.3,0.1,0.6,0,0.8c-0.1,0.5-0.1,1-0.3,1.4c0.2,0.6-0.1,1.2,0.1,1.9
        c0.1,0.3-0.4,0.5-0.2,0.8c0.1,0.4,0.3,0.8,0,1.2c0.1,0.3,0.2,0.6,0,1c0.3,0.7,0.1,1.4,0.1,2.2c0.1,0.5-0.3,0.9,0,1.4
        c0,0.1-0.1,0.3-0.2,0.4c0.5,0.6-0.3,1.3,0.2,1.9c-0.1,0.7,0.1,1.4-0.1,2c0.1,0.4,0.2,0.8,0.1,1.1c0.1,0.7,0,1.4-0.3,2
        c0.5,0.6,0.5,1.5,0.3,2.2c0.2,0.4,0.3,0.8,0,1.2c0,0.6,0.1,1.2,0,1.8c0.2,0.4-0.1,0.7-0.3,1c0.2,0.2,0.3,0.4,0.4,0.6
        c0,0.3-0.2,0.6-0.1,0.9c0.1,0.2,0.1,0.4,0,0.6c0,0.3,0,0.6-0.1,0.9c0.1,0.1,0.1,0.3,0.2,0.4c-0.1,0.2-0.1,0.5-0.2,0.7
        c0.4,0.4,0,1,0.1,1.5c0,0.2-0.2,0.5-0.1,0.8c0.2,0.4,0,0.9,0.1,1.4c0.3,0.1,0.7,0.1,0.8,0.4c0,0,0.1-0.1,0.2-0.1
        c0.2,0.1,0.4,0.1,0.6,0.2c0,0.1-0.1,0.2-0.1,0.3c0.4-0.3,0.8,0,1.1,0.3c0.2-0.1,0.5,0,0.7,0.1c0.3-0.1,0.5-0.2,0.7,0
        c0.1-0.1,0.2-0.4,0.4-0.3c0.2,0,0.3,0.2,0.5,0.2c0.1-0.1,0.2-0.3,0.2-0.4c0.3-0.1,0.5,0,0.8-0.2c0.3-0.2,0.6-0.2,0.9-0.4
        c0.9-0.4,1.9-0.5,2.7-1.1c0.6-0.3,1.1-0.7,1.7-0.7l0-0.2c0.1,0,0.3,0,0.3,0c0.4-0.4,1.1-0.2,1.3-0.8c-0.2-0.4-0.6-0.5-1-0.7
        c0.1-0.1,0.2-0.2,0.3-0.2c-0.3,0-0.6-0.1-0.8-0.2c-0.2-0.2-0.4-0.4-0.6-0.6c-0.3-0.1-0.6-0.3-0.8-0.4c-0.1,0.1-0.1,0.2-0.1,0.3
        c-0.1-0.3-0.5-0.1-0.7-0.3c0.2,0,0.5-0.1,0.7-0.2c-0.7-0.2-1.2-1-2-0.9c-0.1,0-0.2,0.1-0.2,0.1c0,0-0.1-0.1-0.1-0.1
        c0.1,0,0.2-0.1,0.2-0.1c-0.2-0.2-0.4-0.4-0.6-0.7c-0.1-0.1-0.2-0.1-0.3-0.2c0.5-0.2,1.2-0.3,1.5-0.8c0.7-1,1-2.2,1.2-3.4
        c0.2-0.7,0.1-1.5,0.4-2.3c-0.3-0.4-0.1-1-0.4-1.4c0.2-0.5-0.3-0.9-0.1-1.3c0.2-0.4,0-0.8-0.1-1.1c0.1-0.4,0.1-0.8,0-1.1
        c0.3-0.5,0.1-1,0.2-1.5c-0.2-0.6-0.1-1.3,0.1-1.9c-0.1-0.2-0.3-0.4-0.1-0.6c0.2-0.5,0.2-1,0.1-1.4c0.1-0.5,0.1-1.1,0.2-1.6
        c0-0.2-0.2-0.4-0.1-0.6c0.1-0.3,0.2-0.6,0.1-0.9c-0.1-0.3,0.2-0.6,0.2-1c0-0.2-0.1-0.5,0-0.7c0.2-0.3-0.1-0.5-0.1-0.7
        c0.1-0.2,0.2-0.5,0.3-0.7c-0.1-0.4-0.3-0.9,0-1.3c-0.1-0.2-0.2-0.5-0.1-0.8c0-1,0.2-1.9,0.3-2.9c-0.3-0.5-0.2-1,0.1-1.5
        c-0.1-0.3-0.1-0.7,0.2-1c-0.1-0.4-0.1-0.8-0.2-1.2c0.1-0.2,0.1-0.5,0.1-0.7c0-0.1,0.1-0.3,0.1-0.4c-0.2-0.5-0.2-1.1-0.2-1.7
        c-0.1-0.3-0.2-0.6-0.1-1c0-0.1,0.1-0.2,0.2-0.3c-0.1,0-0.3,0.1-0.4,0.2c-0.1-0.2,0-0.5-0.1-0.7c-0.2-0.2,0.1-0.5-0.1-0.6
        c-0.1-0.2-0.2-0.4-0.3-0.5c0-0.1,0.1-0.3,0.2-0.4c-0.3-0.2-0.5-0.5-0.6-0.9c0-0.6-0.4-1-0.7-1.6c-0.1,0-0.3,0.1-0.4,0.1
        c0.1-0.4-0.2-0.5-0.4-0.7c-0.3-0.4-0.8-0.2-1.1-0.4c-0.3-0.1-0.2-0.5-0.3-0.7c-0.2-0.1-0.3-0.2-0.5-0.3c0-0.1,0-0.3,0-0.3
        c-0.3-0.2-0.6-0.4-0.9-0.6c0-0.1-0.1-0.2-0.1-0.3c-0.2,0-0.3-0.1-0.5-0.1c0-0.2-0.1-0.3-0.1-0.5c0,0.3-0.1,0.4-0.3,0.4
        c0,0,0,0.1,0,0.1c0.2-0.1,0.5,0,0.7,0c-0.1,0.2-0.2,0.3-0.2,0.5l0.2,0c0-0.1,0.1-0.2,0.1-0.3l0.2,0c-0.1,0.2-0.2,0.4-0.3,0.6
        c0.1,0.2,0.2,0.4-0.1,0.6l0.2,0.2c-0.1,0.1-0.3,0.3-0.4,0.4c0.1,0,0.4,0,0.5,0c-0.1,0-0.3,0.1-0.3,0.2c0.1,0.2,0.2,0.4,0.3,0.6
        l-0.2,0c0-0.1-0.1-0.3-0.1-0.4c-0.4,0.2,0.2,0.5,0,0.7l0.2,0c-0.1,0.1-0.1,0.2-0.2,0.4c-0.1,0.2-0.7,1-0.1,0.4l0.1-0.3l0.2,0.1
        c-0.1,0.2-0.2,0.5,0,0.7c-0.1,0.2-0.2,0.3-0.3,0.4c0-0.3,0.1-0.4,0.2-0.7c0,0-0.1,0-0.1,0c-0.1,0.1-0.1,0.3-0.2,0.4
        c0,0.1,0.1,0.3,0.2,0.4c-0.1-0.1-0.3-0.1-0.4-0.2c0.1,0.1,0.1,0.2,0.2,0.4c-0.2,0.2-0.3,0.4-0.4,0.6c0.1,0.1,0.1,0.2,0.2,0.3
        c-0.1-0.3,0.2-0.4,0.2-0.7c0.1,0,0.2,0,0.2,0c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.2,0,0.3,0l0-0.3c0.3,0.3-0.1,0.7-0.3,0.9
        c0.1,0,0.2,0,0.3,0.1l0.1,0.2c-0.3,0-0.4,0.2-0.4,0.4c0.6-0.4,1.2-0.2,1.8,0c0,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.2-0.2,0.3
        c-0.1,0-0.3,0.1-0.4,0.1c0,0.1,0.1,0.2,0.1,0.3c-0.2-0.1-0.4,0-0.3,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0,0.4,0,0.5,0
        c-0.2,0.1-0.4,0.3-0.6,0.5l0.3,0c-0.1,0.2-0.2,0.5,0.1,0.6c-0.1,0.3,0,0.4,0.3,0.4c0-0.1,0-0.2,0-0.3c0.1,0.2,0.1,0.4,0.2,0.6
        c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2,0-0.3,0-0.5,0c0,0,0,0.1,0,0.1c0.3,0.1,0.7,0.3,0.7,0.6c0.3,0.1,0.7,0.2,1,0.2
        c-0.4,0.2-0.8,0.3-1.2,0.3c0,0,0,0,0,0.1c0.2,0.1,0.4,0.2,0.6,0.3c-0.1,0.2-0.3,0.4-0.6,0.3c0,0.1,0.1,0.2,0.1,0.3
        c-0.2,0-0.5,0-0.7,0.1l0,0.1c0.1,0,0.3,0,0.4-0.1l0,0.3c-0.1,0-0.2,0-0.3,0c0,0.1,0,0.3,0,0.3l0.2-0.1c0,0.3-0.1,0.7,0,1
        c0.1,0,0.2-0.2,0.2-0.2c0,0.4,0.4,0.7,0.6,1c0.1,0,0.2,0,0.3,0c-0.3,0.1-0.6,0.1-0.8,0.1c0.3,0.4-0.2,0.5-0.4,0.6
        c0,0.1,0,0.2,0.1,0.3c-0.1,0-0.2,0-0.3-0.1c0,0.2,0,0.4,0,0.6c-0.1,0.3-0.1,0.6-0.2,0.9l0.2,0l0,0.1l-0.2,0c0,0.2,0,0.5-0.2,0.7
        c0.1,0,0.3-0.1,0.3-0.1c-0.2,0.3-0.1,0.6,0.1,0.9c0.1,0,0.3,0,0.4,0l0,0.2l0.3,0c-0.1,0.2-0.3,0.3-0.5,0.4c-0.1,0.2-0.2,0.3-0.4,0.5
        c-0.1,0.3-0.1,0.6-0.3,0.8c0.1,0.1,0.2,0.2,0.2,0.3l-0.2-0.1c0,0.2,0.1,0.4,0.1,0.6c-0.3-0.1-0.3,0.2-0.4,0.3
        c0.2-0.1,0.6-0.5,0.5,0.1l-0.2,0c0,0.1,0.1,0.3,0.2,0.4l-0.3-0.1c0.1,0.2,0.2,0.4,0.4,0.5c0.2,0.1,0.4,0.3,0.6,0.5l0,0.1
        c0.2,0,0.3,0,0.5,0.1l0,0c-0.4-0.1-0.7,0.2-1,0.4c0,0.3-0.1,0.7-0.2,1l0.2,0c-0.1,0.2-0.1,0.3-0.2,0.5c0.1,0,0.2,0,0.3,0
        c-0.2,0.1-0.4,0.2-0.4,0.4c0,0-0.1,0.1-0.2,0.1l0.2,0c0,0.1,0,0.4-0.1,0.5c0.1,0,0.2-0.1,0.3-0.1l0,0.1c-0.1,0-0.2,0-0.3,0
        c0.1,0.2,0.1,0.4,0.3,0.5c-0.1,0-0.2-0.1-0.3-0.1c0.1,0.2,0.2,0.5,0.5,0.7c-0.1,0-0.4-0.1-0.5-0.1l0.2,0.3c0.1,0,0.2,0.1,0.2,0.1
        c0,0.1-0.1,0.4-0.2,0.6c0.1-0.1,0.4-0.2,0.6-0.2c0,0.1,0,0.3,0,0.5c-0.6,0.2-0.8,0.7-1.1,1.2c0.2-0.1,0.3-0.3,0.5-0.4
        c-0.1,0.3-0.3,0.6-0.4,0.8c0.2-0.1,0.4-0.2,0.6-0.3c-0.1,0.1-0.2,0.2-0.3,0.3c0,0.1,0.1,0.2,0.1,0.4c-0.1,0-0.3,0.1-0.4,0.2
        c0.1,0.1,0.2,0.2,0.3,0.3c-0.2,0-0.5,0.3-0.1,0.4c0.1,0,0.2-0.1,0.2-0.2c-0.3,0.3-0.3,0.6-0.2,0.9c0.3,0.2,0.1,0.5,0.2,0.7
        c0,0.2,0,0.4,0.1,0.7c-0.2,0-0.3,0.1-0.2,0.3c0.1,0,0.2,0,0.3-0.1c0,0.1,0,0.4,0,0.6c-0.1-0.1-0.2-0.2-0.3-0.3l0,0.2
        c-0.2-0.1-0.3-0.2-0.5-0.2c0.1,0.2,0.4,0.4,0.1,0.6c0.2,0.2,0.3,0.5,0.3,0.8l-0.3,0c0-0.1,0-0.3,0-0.4c-0.3,0.1-0.1,0.3,0,0.5
        c0,0,0.1,0.1,0.2,0.1c-0.1,0.3-0.1,0.6-0.5,0.7c0,0,0,0.1,0.1,0.2c0.1-0.1,0.3-0.2,0.4-0.3c0,0.2,0,0.4,0,0.7l-0.3-0.1
        c0.1,0.1,0.2,0.5,0.4,0.5c-0.2,0.2-0.3,0.4-0.4,0.7l0.3,0c0,0.3,0,0.5-0.2,0.7c0.1,0.1,0.2,0.2,0.2,0.3c-0.1-0.1-0.3-0.2-0.4-0.3
        c0.1,0.3,0.1,0.7,0.4,1c-0.1,0-0.3,0-0.4,0c0.1,0.2,0.2,0.3,0.2,0.6c0.1,0,0.2-0.1,0.3-0.2c-0.3,0.3-0.4,0.7-0.3,1.1
        c0,0.3-0.2,0.7-0.2,1c0.1,0,0.3-0.1,0.4-0.1c-0.1,0.1-0.2,0.2-0.3,0.2c0,0.2,0.1,0.4,0.2,0.6c-0.1-0.1-0.2-0.2-0.4-0.2
        c0,0.3,0.1,0.6,0.2,0.9c-0.2-0.1-0.3-0.2-0.5,0c0.1,0.1,0.2,0.2,0.4,0.3c0,0.3,0,0.5,0.1,0.8c0.1,0.8-0.1,1.6,0.1,2.4
        c0,0.2,0,0.5,0,0.7c-0.1,1.1,0.1,2.1,0.1,3.2c-0.2-0.3-0.1-0.7-0.1-1c-0.1-0.2-0.2-0.5-0.2-0.7c0-0.4-0.2-0.8-0.1-1.2
        c-0.2-0.6-0.1-1.3-0.3-1.9c0-0.8-0.3-1.6-0.2-2.4c-0.1-0.8-0.2-1.7-0.1-2.6c0-0.1-0.1-0.2-0.1-0.3c0-0.2,0.1-0.3,0.2-0.4
        c-0.4-0.5-0.3-1.2-0.4-1.8c-0.2-0.5-0.1-1,0-1.5c-0.4-0.5,0.1-1.2-0.3-1.8c0-0.1,0.1-0.2,0.1-0.2c-0.2-0.3-0.1-0.7-0.1-1
        c-0.1-0.4,0-0.9-0.1-1.3c0.1-0.7-0.1-1.3,0-2c-0.2-0.4,0-0.9,0-1.3c0.1-0.7,0-1.4-0.1-2.1c0.1-0.1,0.1-0.2,0.2-0.4
        c-0.3-0.5-0.2-1.2-0.2-1.8c0.1-0.4-0.2-0.8,0.1-1.2c-0.3-0.5,0-1.1-0.1-1.7c0-1.2,0-2.4,0.1-3.6c-0.3-0.4,0.2-0.8,0-1.3
        c0.1-0.3,0.1-0.6,0.2-0.9c-0.1-0.1-0.2-0.2-0.3-0.2c0-0.5,0.1-1.1,0.2-1.6c-0.1-0.6-0.3-1.2,0-1.7c0.1-0.3,0.1-0.6,0-0.8
        c0.1-0.6,0-1.1,0.1-1.7c-0.2-1.1,0.2-2.3,0-3.4c-0.1-0.5,0.1-0.9,0.3-1.3c-0.1,0-0.2-0.1-0.2-0.1c0-0.4-0.1-0.8,0.1-1.2
        c-0.1-0.4-0.1-0.9,0.1-1.3c-0.1-0.4,0-0.8,0.1-1.1c0-0.8-0.2-1.8,0.2-2.5c0.2-0.6-0.3-1.3,0.2-1.8c-0.2-0.6,0.2-1.2,0-1.8
        c0-0.4,0.2-0.8,0.3-1.1c0-0.1-0.1-0.2-0.1-0.3c0.1-0.9,0-1.8,0.4-2.6c0-1.6,0.5-3.2,0.6-4.9l0.1,0c0-0.8,0.3-1.6,0.5-2.3
        c0.2,0.5-0.1,0.9,0,1.4c0.1,0.3,0,0.7,0.1,1.1l-0.1,0c-0.1,0.3,0.1,0.5,0.3,0.6c-0.1,0-0.2-0.1-0.3-0.1l0,0.3c0,0.1,0.1,0.2,0.1,0.3
        c0.1,0,0.2,0.1,0.2,0.1l-0.4,0c0.1,0.1,0.2,0.4,0.2,0.6l0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0.2-0.2,0.7,0.2,0.8
        c-0.1,0.1-0.1,0.1-0.2,0.2c0-0.1-0.1-0.3-0.1-0.4c-0.1,0.3,0.1,0.6,0.1,0.9c-0.1,0-0.2-0.1-0.3-0.1c0,0.1,0.1,0.3,0.1,0.5
        c0.1-0.1,0.2-0.3,0.3-0.4c0.1,0,0.2,0,0.2-0.1c-0.3,0.2-0.2,0.5-0.3,0.7c0,0.1,0.1,0.2,0.2,0.3c-0.1-0.1-0.2-0.2-0.4-0.3
        c0,0.3-0.4,0.8,0,1l0,0.3l-0.3,0c0.1,0.2,0.2,0.3,0.3,0.5l0.2-0.1c-0.1,0.2-0.2,0.4-0.3,0.5c0.1,0,0.2,0,0.3,0
        c0,0.1-0.1,0.2-0.1,0.3c0.1,0,0.2,0.1,0.2,0.1c-0.1,0.1-0.2,0.3-0.2,0.5c-0.2-0.2-0.2-0.5-0.2-0.7c-0.1,0.2-0.2,0.3-0.1,0.5
        c-0.1,0-0.2,0.1-0.3,0.1c0.1,0,0.2,0.1,0.3,0.1c0,0.2-0.2,0.4,0.1,0.5c0.1-0.1,0.2-0.3,0.2-0.4c0,0.1,0.1,0.2,0.1,0.3
        c-0.1,0.1-0.2,0.3-0.3,0.4c0.2,0,0.3,0,0.5,0c-0.1,0-0.3,0.1-0.4,0.1c0.1,0.2,0.1,0.3,0.2,0.5l-0.3-0.1c0,0.1,0.1,0.3,0.2,0.4
        c-0.3,0-0.3-0.2-0.3-0.5l0.3,0c-0.1-0.1-0.2-0.3-0.3-0.4c0-0.2-0.1-0.3-0.3-0.3c0.1,0.2,0.2,0.4,0.3,0.7c-0.2,0-0.3,0.1-0.4,0.1
        l0.2,0c0.1,0.3,0.2,0.6,0.4,0.8c-0.1,0.1-0.2,0.2-0.2,0.2l0.2,0l0,0.2c0.1,0,0.2,0,0.2,0.1c-0.3-0.1-0.7,0.5-0.1,0.4
        c-0.1,0.1-0.2,0.3-0.2,0.4l0.3-0.1c0,0,0,0.1,0,0.1c-0.1,0-0.2,0-0.3,0.1c0,0.1,0.1,0.3,0.1,0.4c-0.1,0-0.3,0-0.4,0
        c0.3,0.2,0.4,0.4,0.4,0.8c0.1-0.1,0.2-0.2,0.3-0.2c-0.1,0.1-0.2,0.3-0.3,0.4c0.1,0,0.4,0,0.5,0c0-0.1,0-0.2,0-0.2
        c0.2,0,0.3-0.1,0.4-0.2c-0.1,0.2-0.3,0.3-0.4,0.5c0.1,0,0.2,0.1,0.3,0.1c0-0.4,0.4-0.4,0.5-0.1c0.1-0.3,0.4-0.4,0.7-0.4
        c0-0.1,0-0.3,0-0.4c0.2,0,0.3,0.1,0.3,0.2c0.1,0,0.3-0.1,0.4-0.1c0.2-0.2,0.4-0.4,0.6-0.5c-0.1,0.1-0.6,0.4-0.2,0.5l0.2-0.1
        c0.4,0.3,0.7-0.1,1-0.3l-0.1-0.3c0.1,0.1,0.3,0.2,0.4,0.3c0-0.1,0-0.3,0-0.4l0.2,0.1c0.1-0.3,0.5-0.3,0.7-0.3c0-0.1-0.1-0.3-0.2-0.4
        l0.3,0.1l0-0.2c0.1,0,0.2,0.1,0.3,0.1c0-0.1,0-0.3,0-0.4c0.3,0,0.6,0.1,0.9,0.1c0,0,0-0.1,0-0.1c-0.3,0.1-0.4-0.1-0.6-0.3
        c0.2-0.1,0.4-0.1,0.6,0c0.3,0-0.1-0.3-0.2-0.3c0.2-0.3,0.6-0.1,0.9-0.1c-0.1-0.1-0.2-0.3-0.3-0.4c0.3-0.2,0.6-0.3,0.8,0
        c-0.2-0.2-0.2-0.4-0.1-0.6c0.4,0,0.8-0.2,0.8-0.6c0.3-0.1,0.6-0.2,0.8-0.4c-0.1-0.2-0.2-0.3-0.3-0.5c0.2,0,0.4,0,0.6,0
        c-0.2-0.2-0.1-0.5,0.1-0.7c0.1,0,0.4-0.4,0.3,0c0.2-0.1,0.4-0.2,0.6-0.2c-0.1-0.2-0.2-0.3-0.3-0.5c0.1,0,0.2,0.1,0.3,0.1
        c0.1-0.3,0.3-0.4,0.6-0.3c-0.1-0.1-0.1-0.3-0.2-0.4l0.2,0l-0.2-0.3c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.1,0.2-0.2,0.3
        c0-0.4,0.3-0.6,0.6-0.9c0.1,0.1,0.2,0.3,0.3,0.4c0.2-0.2,0.5-0.3,0.7-0.4c-0.2,0-0.4,0.1-0.5,0.1c0.1-0.2,0.3-0.4,0.5-0.6l0.1,0.2
        c0.1-0.1,0.1-0.1,0.2-0.2c-0.1-0.1-0.3-0.2-0.3-0.2c0.3-0.1,0.6-0.1,0.9-0.2c0-0.1,0-0.2,0-0.2l0.2,0c0-0.1,0.1-0.2,0.1-0.2
        c-0.1,0-0.3,0-0.4,0c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0.1,0.2,0.2,0.3,0.3c0-0.1,0-0.3,0-0.4c0.3,0,0.5-0.3,0.9-0.5
        c1.1-0.7,2.2-1.6,3.1-2.6c0.1-0.2,0.2-0.3,0.4-0.4c0.1,0.1,0.2,0.2,0.3,0.2c0.7-0.7,1.4-1.2,2.1-1.9c0.4-0.4,0.6-1,1.2-1
        c0.1-0.1,0.2-0.2,0.3-0.3c0-0.5,0.5-0.7,0.8-1c0.5-0.4,1.2-0.7,1.7-1.2c0-0.5,0-1,0.1-1.4c0.2-0.4,0.1-0.9,0.2-1.3
        c-0.1-0.7,0.2-1.3,0.2-1.9c0,0,0.1,0,0.1,0c0,0.2,0,0.4,0,0.5c0,0.1-0.1,0.4-0.2,0.5l0.2-0.1c-0.2,0.3-0.2,0.7-0.2,1
        c-0.1,0.3-0.1,0.7-0.1,1c-0.2,0.5-0.1,1-0.1,1.5c0.4-0.3,0.8-0.5,1.1-0.8c0.4-0.5,1-0.8,1.4-1.3c0.7-0.4,1.1-1.2,1.8-1.6
        c1.2-1,2.3-2.1,3.5-3.1c0.2-0.2,0.4-0.5,0.7-0.3c-0.3-0.3-0.4-0.8-0.9-0.8c-0.1-0.2-0.3-0.3-0.4-0.5c-0.3,0-0.5-0.2-0.7-0.4
        c0,0.1,0,0.4,0,0.5c-0.1-0.5-0.4-0.9-0.9-1.2c-0.2,0-0.3,0.1-0.5,0.1c0.1-0.2,0.3-0.2,0.4-0.2c-0.1-0.1-0.3-0.4-0.4-0.6
        c0,0.1,0,0.3,0,0.4c-0.1-0.1-0.1-0.3-0.2-0.4c-0.7-0.1-0.9-0.9-1.6-1.2c-0.3,0.1,0,0.4,0.2,0.4l0.1,0c-0.3,0-0.5,0.2-0.7,0
        c0.1-0.1,0.2-0.3,0.2-0.5l-0.3,0.1c0-0.1,0-0.4,0-0.5c-0.3,0-0.5,0-0.7,0.2c0-0.1,0-0.2,0-0.2c-0.2-0.2-0.4-0.4-0.7-0.5
        c-0.2,0-0.4,0.1-0.4,0.3c0,0.2,0.2,0.1,0.4,0.1c-0.3,0.1-0.3,0.2-0.3,0.5c-0.1-0.3-0.2-0.5-0.4-0.7c0.1-0.1,0.2-0.2,0.4-0.2
        c-0.3-0.1-0.5-0.3-0.8-0.2c-0.2-0.6-0.9-0.3-1.1-0.8c0,0-0.1,0.1-0.2,0.2c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.5-0.7-0.4-1.2-0.3
        c0-0.1,0-0.4,0-0.5c-0.5-0.2-0.9-0.5-1.4-0.1l0-0.3c0.1,0,0.3-0.1,0.4-0.1c-0.2-0.2-0.4-0.7-0.6-0.2c-0.1-0.2-0.4-0.2-0.6-0.3
        c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.3,0.2,0.7,0.2,1.1c0.1,0,0.3,0,0.4,0c0,0.2,0,0.4,0.1,0.5c0.2,0,0.3,0.1,0.4,0.3
        c-0.1,0-0.4,0-0.6-0.1c0.1,0.2,0.4,0.3,0.4,0.5c0.1,0.2,0,0.7,0.4,0.7c0.1,0.3-0.1,0.5-0.2,0.8c0.1,0,0.2,0,0.3,0c0,0.3,0,0.7,0,1
        c0.2,0.1,0.3,0.3,0.5,0.4c-0.2,0.1-0.3-0.1-0.4-0.1c0.1,0.5,0.6,0.6,0.9,1l-0.2-0.1c0.2,0.3-0.2,0.4-0.3,0.6
        c0.1-0.2,0.2-0.3,0.3-0.4c-0.1-0.2-0.4-0.7-0.7-0.4c0.1,0.2,0.3,0.4,0.1,0.6c0.1,0.5-0.1,1,0.3,1.4c-0.1-0.1-0.3-0.2-0.3-0.3
        c0,0.3,0.1,0.5-0.1,0.8c-0.2,0.2-0.2,0.7,0.2,0.7c0,0.1,0,0.2,0,0.3c0-0.1,0-0.2-0.1-0.3c-0.2,0.2-0.3,0.3-0.4,0.5
        c0,0,0.1,0.1,0.2,0.2c-0.1,0.2-0.2,0.4-0.2,0.6c0,0.4-0.2,0.7-0.3,1.1l0.2,0c0,0,0,0.1,0,0.1c-0.1,0-0.3,0-0.3,0
        c-0.1,0.2-0.3,0.4-0.2,0.6c0,0.3-0.3,0.6-0.5,0.8c-0.3-0.7-0.8,0.7-0.2,0.4c0,0,0,0.1,0.1,0.1c-0.3-0.1-0.4,0.1-0.5,0.2
        c-0.5,0-0.7,0.5-1,0.7c-0.1,0-0.3,0-0.3,0c-0.2,0.6-0.7,0.9-1.3,1c-0.2,0.4-0.7,0.4-1,0.7c-0.5,0.5-1.2,0.4-1.7,0.7
        c-0.1,0-0.2-0.1-0.2-0.2c0,0.1-0.1,0.2-0.1,0.3c-0.4,0.1-1,0.4-1.4,0.1c-0.3,0.2-0.6,0.1-0.9,0.2c-0.4,0.1-0.9,0.1-1.3,0.1
        c-0.7,0.1-1.3-0.3-2-0.2l0.1-0.2l-0.3,0.1l-0.2-0.2c0.3-0.2,0.5-0.1,0.7,0.2c0.6-0.4,1.6,0.1,2.2-0.3c-0.3,0-0.5,0-0.8,0
        c0.5-0.2,1-0.1,1.5-0.2c0.3-0.2,0.6-0.3,0.9-0.4c0,0.5,0.4-0.1,0.4-0.2c0.3,0,0.8,0,0.6-0.5c0.1,0,0.3,0.1,0.4,0.1l0-0.2l-0.3-0.1
        c0.1-0.3,0.3,0,0.5,0c0.2-0.1,0.6-0.3,0.4-0.6c0.1,0,0.2,0.1,0.3,0.2c0-0.2,0.1-0.4,0.2-0.6c0,0.2,0,0.4,0,0.6
        c0.1-0.2,0.3-0.3,0.4-0.5c0.1,0,0.2,0,0.3-0.1l-0.4-0.1c0.3-0.3,0.6-0.6,1-0.8c0.1-0.1,0.2-0.3,0.2-0.4c-0.1-0.1-0.2-0.3-0.1-0.4
        c0.2,0.1,0.4,0.3,0.7,0.2c-0.1-0.1-0.1-0.3-0.2-0.4c0.1,0,0.2,0,0.2,0l0.1-0.3c-0.1,0-0.3,0-0.4,0.1c0.2-0.1,0.4-0.1,0.6-0.2
        c0-0.1,0.1-0.3,0.2-0.4c-0.1,0-0.3,0-0.4,0l0,0c0.2,0,0.4-0.2,0.4-0.4c0.2-0.1,0.5-0.1,0.5-0.3c0.3-0.2,0.2-0.5,0.2-0.7
        c0.2-0.1,0.2-0.2,0.1-0.3c-0.1,0-0.2,0-0.4,0c0.1-0.1,0.3-0.3,0.5-0.3c0-0.2,0.2-0.3,0.3-0.5c0.1,0,0.2,0.1,0.3,0.1
        c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1,0.3-0.2,0.5-0.5,0.5c0-0.1,0.1-0.4,0.1-0.5c-0.1,0-0.2-0.1-0.2-0.2c0.1,0,0.2,0,0.2,0l0,0.2
        c0.1,0,0.3,0,0.4,0c-0.2-0.6,0.5-0.7,0.6-1.2c-0.3,0.1-0.5,0.4-0.8,0.6c0.1-0.1,0.2-0.4,0.2-0.6c-0.1,0.1-0.2,0.2-0.3,0.3
        c0,0-0.1,0-0.2,0c0.4-0.3,0.4-0.9,0.8-1.2c-0.1,0-0.3,0-0.4-0.1c0.2-0.2,0.4-0.3,0.6-0.4c-0.1-0.2-0.1-0.4-0.2-0.5
        c-0.1,0-0.3,0.1-0.4,0.2c-0.1-0.4,0.4-0.3,0.6-0.5c-0.2,0-0.5,0-0.6,0.1c0.1-0.2,0.3-0.3,0.4-0.5c-0.1,0-0.3,0-0.4,0
        c0.2-0.4,0-0.8-0.1-1.1c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0.1,0.2,0.3,0.1,0.5c-0.3-0.1-0.2-0.4-0.3-0.7c0.2-0.2,0.6-0.5,0.2-0.8
        c-0.1,0.1-0.1,0.2-0.2,0.4l0.1-0.3l-0.2,0.1c0.2-0.2,0.3-0.5,0.2-0.7c0.1,0.1,0.2,0.2,0.3,0.3c-0.1-0.2-0.1-0.4,0-0.5
        c-0.2-0.2-0.4-0.4-0.4-0.6c-0.3,0.1-0.1,0.5-0.4,0.6c0.1-0.2,0.2-0.3,0.1-0.5c-0.1,0-0.4-0.4-0.1-0.3l0.1,0.1
        c0.2,0.2,0.2-0.2,0.3-0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.1-0.3,0-0.7-0.4-0.8c0,0.1,0,0.3,0,0.4c-0.5-0.3,0.2-1.3-0.6-1
        c0.1,0.1,0.1,0.2,0.2,0.3l-0.2,0.1c0-0.2-0.1-0.5-0.3-0.4c0,0,0-0.1,0-0.2c0.1,0,0.2,0.1,0.3,0.2c0-0.1,0-0.3,0-0.3
        c-0.4,0.2-0.4-0.4-0.1-0.4c-0.1,0-0.3,0-0.4,0c0-0.1,0.1-0.2,0.1-0.4c-0.3-0.1-0.5-0.2-0.7-0.4c0-0.4-0.3-0.5-0.6-0.7l0-0.2l0.2,0
        c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1,0.4-0.6,0.2-0.4-0.1c-0.1,0-0.2,0-0.3,0.1c-0.2-0.2-0.4-0.6-0.7-0.5c-0.1-0.2-0.4-0.2-0.6,0
        c-0.2,0.2-0.4-0.3-0.4,0.1c-0.2-0.1-0.3-0.2-0.5-0.2c0,0,0-0.1,0-0.2C274.4,201.1,274.1,201,274,201 M171.8,201.4
        c-0.1,0.2,0.2,0.5,0.4,0.4C172.4,201.5,172,201.3,171.8,201.4 M322.5,201.4C322.6,201.5,322.6,201.5,322.5,201.4 M322.7,201.7
        C322.8,201.7,322.8,201.7,322.7,201.7 M199.8,202C199.9,202.1,199.9,202.1,199.8,202 M99.7,202.5c0.2,0,0.3,0,0.2-0.2
        C99.7,202.2,99.7,202.3,99.7,202.5 M26.6,202.8c0.1,0.1,0.5,0,0.4-0.2C26.8,202.4,26.4,202.5,26.6,202.8 M487.9,202.5
        c-0.1,0.2,0,0.3,0.2,0.3C488.2,202.6,488.1,202.5,487.9,202.5 M257.4,202.6c0,0.2,0,0.3,0.2,0.2
        C257.7,202.7,257.6,202.6,257.4,202.6 M479.7,202.8c-0.1,0.3-0.1,0.5,0,0.8c0.1-0.1,0.2-0.4,0.3-0.5c0.1,0,0.3-0.1,0.4-0.1
        C480.1,202.9,479.9,202.8,479.7,202.8 M186.1,202.9L186.1,202.9L186.1,202.9L186.1,202.9L186.1,202.9 M321.4,202.9
        c-0.1,0.2,0,0.3,0.2,0.3C321.7,202.9,321.6,202.8,321.4,202.9 M256.2,203.7C256.3,203.7,256.3,203.7,256.2,203.7 M321,203.9
        c0,0.1-0.1,0.2-0.2,0.2c-0.1-0.2-0.2-0.3-0.4-0.3l0.1,0.3l-0.4,0c0,0,0-0.1,0-0.2c-0.3,0-0.3,0.6-0.7,0.5l0,0.2
        c-0.1,0-0.2,0-0.3-0.1c-0.1,0.1-0.2,0.2-0.2,0.3c0-0.1,0.1-0.3,0.1-0.3c-0.3,0-0.4,0.4-0.6,0.5c0,0.1-0.1,0.2-0.2,0.3
        c0-0.1,0-0.4,0-0.5c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0-0.2,0-0.2-0.1c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0-0.3-0.1-0.4-0.1
        c0,0.1,0.1,0.2,0.1,0.3c-0.2-0.1-0.3,0-0.4,0.2c0,0-0.1-0.1-0.2-0.1c-0.1,0.2-0.2,0.4-0.3,0.5c-0.2-0.3-0.5,0-0.7-0.1l0.1,0.1
        c-0.3,0-0.3,0.5-0.6,0.6c-0.2,0.2-0.5,0.2-0.7,0.2c0,0.1,0.1,0.2,0.1,0.3c-0.3,0-0.5,0-0.5,0.3c0,0-0.1-0.1-0.2-0.1l-0.1,0.2
        c-0.2,0-0.4,0.1-0.5,0.1c0.1,0,0.2,0,0.3,0c-0.2,0.2-0.4,0.3-0.6,0.3c-0.3,0.3-0.6,0.6-1,0.7c-0.3,0.2-0.5,0.5-0.8,0.7
        c-0.4,0.2-0.6,0.6-1,0.8c-0.3,0.5-0.7,1-1.1,1.4c-0.2,0-0.3,0-0.4,0.2l0.3,0c-0.3,0.3-0.4,0.7-0.7,1c-0.1,0.3-0.3,0.6-0.4,0.8
        c0-0.3,0-0.5,0.1-0.7c-0.4,0.2-0.4,0.6-0.3,1c-0.3,0-0.7,0.1-0.7,0.4c0.3-0.1,0.5-0.2,0.8-0.3c-0.1,0.2-0.3,0.4-0.6,0.5
        c-0.1,0.2-0.2,0.3-0.2,0.5c0,0.2,0,0.4,0,0.7l-0.3-0.1c0.5,0.5-0.4,0.6-0.3,1.1l-0.1,0c-0.1,0.2-0.1,0.6-0.3,0.7
        c-0.1-0.1-0.2-0.2-0.3-0.4c0.2,0,0.4-0.1,0.4-0.3c-0.2,0.1-0.5,0.2-0.7,0.1c0.1,0.3,0.3,0.6,0.4,0.8c-0.2,0-1,0.3-0.5,0.4
        c0.1-0.1,0.2-0.2,0.2-0.3l0.1,0.1c-0.3,0.4-0.2,0.9-0.3,1.3l0,0c0-0.1,0-0.3-0.1-0.4c0-0.1,0.1-0.3,0.1-0.4
        c-0.2-0.3-0.2,0.2-0.3,0.2c-0.1,0.3-0.5,0.3-0.7,0.5c0.3,0,0.8-0.2,0.9,0.3c-0.2,0-0.5,0.1-0.7,0c0,0.1-0.1,0.2-0.1,0.2
        c0.1,0,0.3-0.1,0.4-0.1c0,0.3,0,0.5,0,0.8l-0.1-0.2c-0.1,0.1-0.2,0.3-0.3,0.4c0-0.3,0.1-0.5,0-0.8c-0.1,0.2-0.1,0.4-0.2,0.5
        c0.1,0.1,0.2,0.3,0.3,0.4c-0.2,0.1-0.3,0.2-0.5,0.3c-0.3-0.1-0.6-0.3-0.8,0.1c0.3-0.1,0.5,0.1,0.6,0.3c0.2,0,0.3-0.2,0.4-0.3
        c0.5,0.2-0.2,0.5-0.5,0.5c0,0.1,0.1,0.2,0.1,0.3c-0.1,0.2-0.1,0.5-0.1,0.8c-0.2,0-0.4-0.1-0.5-0.3c-0.1,0.1-0.2,0.3-0.3,0.4
        c0.1,0.1,0.1,0.3,0.2,0.4c0,0.2-0.4,0.6,0.1,0.6c0-0.2-0.1-0.4,0.1-0.6c-0.1-0.3,0.2-0.3,0.4-0.5c0,0.2,0,0.4-0.1,0.5
        c0.1-0.2,0.3-0.3,0.3-0.6c0.1,0,0.2-0.1,0.3-0.1c-0.1-0.1-0.3-0.2-0.4-0.3c0.1,0,0.4-0.1,0.5-0.1c-0.2-0.4,0.3-0.4,0.4-0.7
        c-0.1,0-0.3,0-0.4,0c0.3,0,0.6-0.1,0.9-0.2c-0.3-0.3,0.2-0.5,0.2-0.8c0.4-0.1,1.1-0.1,1-0.7c0.2-0.1,0.3-0.1,0.5-0.2
        c0-0.2,0.1-0.4,0.1-0.6c0.3,0,0.5-0.1,0.8-0.2c-0.1-0.3-0.4-0.1-0.5-0.1c0,0,0-0.1,0-0.1c0.3,0,0.6-0.1,0.9-0.2
        c-0.1-0.1-0.2-0.2-0.3-0.3l-0.1-0.1c0.1,0,0.4,0,0.5,0.1c0.2-0.1,0.4-0.2,0.6-0.3l-0.1-0.2l-0.4,0.3l0-0.5c0.1,0.1,0.3,0.2,0.4,0.3
        c0.2-0.4,0.2-1.1,0.8-1c-0.2,0.1-0.4,0.2-0.4,0.5c0.6,0,0.2-1,0.8-1.1c-0.1,0.1-0.2,0.4-0.2,0.5c0.2,0,0.4,0,0.6-0.1
        c-0.1-0.2-0.3-0.6,0.1-0.6c0.2,0.2,0.2,0.4,0.1,0.6l0.1,0c0-0.2,0-0.5,0.2-0.6c0-0.1,0-0.2-0.1-0.3c0.1-0.3,0.4-0.5,0.6-0.6l0-0.1
        c0.1,0,0.3-0.1,0.4-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c0.3-0.1,0.9,0,0.8-0.6c-0.2,0.1-0.3,0.2-0.5,0.3c0,0,0-0.1-0.1-0.1
        c0.5-0.1,0.8-0.6,1.4-0.8c0.2,0,0.4-0.2,0.2-0.3c-0.1,0-0.3,0.1-0.4,0.1c0.1-0.1,0.2-0.2,0.3-0.2c-0.1-0.4,0.6-0.3,0.5,0l-0.1,0.1
        c0,0,0.1,0.1,0.2,0.1c0,0.1,0.1,0.2,0.2,0.2c-0.1-0.2-0.1-0.5-0.2-0.7c0.1,0,0.3-0.1,0.4-0.1l-0.1-0.2l-0.2-0.1l0,0.3
        c-0.1-0.1-0.2-0.2-0.1-0.3c0.2-0.2,0.4-0.5,0.7-0.3c0-0.1,0-0.3,0-0.4c0.2,0.1,0.6,0.3,0.3,0.6c0.1,0,0.2,0,0.3,0
        c0-0.2-0.1-0.4-0.3-0.5l0.3,0.1c-0.1-0.1-0.2-0.2-0.3-0.2c0.1-0.1,0.3-0.2,0.4-0.3c0-0.1-0.1-0.3-0.1-0.4c0.1,0,0.4,0.1,0.5,0.1
        c0-0.1-0.1-0.2-0.2-0.3c0.1,0,0.2-0.1,0.3-0.1c0-0.3,0.1-0.6,0.3-0.8c0.2-0.3,0.5,0.3,0.1,0.2c0,0.2,0,0.4,0,0.6
        c0.2-0.1,0.2-0.2,0.1-0.4c0.1-0.1,0.2-0.3,0.3-0.4c-0.1-0.1-0.2-0.2-0.3-0.3c0.1,0,0.3,0,0.4,0c0.2-0.2,0.4-0.3,0.6-0.1
        c-0.1-0.2-0.2-0.5-0.2-0.7c0.1,0,0.3-0.1,0.4-0.1c0.1-0.1,0.3-0.1,0.4-0.2l0,0.1c0.1-0.1,0.2-0.1,0.2-0.2c-0.1,0-0.3,0-0.4,0.1
        c0.1-0.3,0.3-0.7,0.1-1c0.5,0.3,0.6-0.5,1-0.7c-0.4-0.3,0.2-0.5,0.4-0.6c0-0.1-0.1-0.2-0.2-0.3c0.3,0.2,0.5,0.1,0.6-0.2
        c0.1,0,0.3,0.1,0.4,0.2c0-0.1,0-0.2,0-0.3c-0.3,0-0.6,0.1-0.9,0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.5,0.1,0.4-0.2
        c-0.1-0.1-0.2-0.2-0.3-0.2c0.1,0,0.2,0,0.3-0.1c-0.1-0.3,0.2-0.5,0.4-0.6c0,0.2-0.2,0.4-0.2,0.7c0,0,0.1-0.1,0.2-0.1
        c0,0,0.1-0.1,0.2-0.1c0-0.1,0-0.3-0.1-0.4l0.2,0.1c0-0.1-0.1-0.3-0.2-0.4c0.4,0,0.8-0.1,0.9-0.4C321.4,203.7,321.2,203.8,321,203.9
        M334,204.5c0,0,0,0.1-0.1,0.1l0,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.5,0.1-1,0.4-1.3,0.8c-0.2,0-0.3,0.1-0.4,0.2
        c0.4,0,0.7-0.1,0.8-0.5c0,0.1,0.1,0.3,0.2,0.4c0.3-0.1,0.2-0.3,0-0.5c0.1,0,0.3,0,0.4,0c0-0.1-0.1-0.2-0.1-0.2
        c0.3,0,0.6-0.1,0.8-0.3c0,0,0.1-0.1,0.1-0.2c0.1-0.1,0.3-0.2,0.3-0.2c-0.2,0.3,0,0.5,0.2,0.4c-0.3-0.5,0.5-0.7,0.8-0.8
        c0-0.1,0-0.2-0.1-0.3C334.9,204,334.5,204.4,334,204.5 M99.1,204.1C99.2,204.2,99.2,204.2,99.1,204.1 M415.5,204.1
        C415.6,204.2,415.6,204.2,415.5,204.1 M279.1,204.3c0.1,0,0.2,0.1,0.2,0.2l0.1,0C279.6,204.3,279.1,204.1,279.1,204.3 M487.9,204.5
        C488,204.6,488,204.6,487.9,204.5 M34.6,204.8c0,0.1-0.1,0.2-0.1,0.2C34.7,205.5,35.1,204.6,34.6,204.8 M480,204.8
        c-0.2,0.1,0,0.4,0.2,0.3C480.3,205,480.1,204.7,480,204.8 M317.4,205C317.2,205.4,317.9,205,317.4,205 M254.4,205.2
        c-0.2,0.1-0.1,0.5,0.1,0.3C254.8,205.4,254.6,205.1,254.4,205.2 M334.1,205.4C334.1,205.4,334.1,205.4,334.1,205.4 M26.3,205.9
        c0.2,0.1,0.4-0.2,0.2-0.3C26.4,205.5,26.2,205.7,26.3,205.9 M188.7,205.7c0.2,0.2,0.3,0.5,0.7,0.5c-0.1-0.1-0.2-0.3-0.3-0.4
        C189,205.6,188.9,205.5,188.7,205.7 M106.6,205.7C106.7,205.8,106.7,205.8,106.6,205.7 M269.3,206.1c0.2-0.1,0.2-0.2,0-0.3
        C269.1,205.9,269.1,206,269.3,206.1 M253.6,206.6c0.1-0.3,0.2-0.5,0.3-0.7l-0.2-0.1C253.6,206.1,253.1,206.4,253.6,206.6
        M480.2,205.9C480.3,206,480.3,206,480.2,205.9 M331.4,206.2c-0.2,0.2,0.2,0.5,0.3,0.2C331.9,206.2,331.5,206,331.4,206.2
        M205.4,206.3c-0.1,0.2,0,0.3,0.2,0.2C205.6,206.4,205.6,206.3,205.4,206.3 M253,206.6c0,0.1,0,0.2,0,0.3c0.2,0.2,0.4-0.3,0.1-0.3
        L253,206.6 M329.6,207.2c0.1,0,0.3,0.1,0.4,0.1c0.1-0.1,0.2-0.3,0.2-0.4c0.2-0.1,0.5-0.1,0.6-0.3
        C330.4,206.5,330.1,207.2,329.6,207.2 M107.5,207.2c-0.1,0.2,0,0.3,0.2,0.2C107.8,207.2,107.7,207.1,107.5,207.2 M407,207.2
        c-0.1,0.2,0,0.3,0.2,0.2C407.3,207.2,407.2,207.1,407,207.2 M479,207.2c-0.2,0.2,0.1,0.5,0.3,0.4C479.3,207.4,479.2,207.1,479,207.2
        M35.5,207.6c0.2,0,0.3-0.1,0.2-0.3C35.5,207.3,35.5,207.4,35.5,207.6 M328.7,207.9c0.3-0.1,0.6-0.1,0.8-0.4
        C329.2,207.5,328.8,207.5,328.7,207.9 M318.2,207.7c-0.1,0.2,0.2,0.4,0.4,0.3C318.6,207.8,318.4,207.6,318.2,207.7 M328.4,208.3
        c0.2,0.2,0.4-0.1,0.3-0.3C328.5,207.8,328.2,208.1,328.4,208.3 M26.2,208.4c-0.2,0.1-0.2,0.5,0.1,0.4
        C26.6,208.7,26.5,208.3,26.2,208.4 M327.7,208.5c0.1,0.2,0.3,0.2,0.4,0C328,208.3,327.8,208.3,327.7,208.5 M488.3,208.4
        c-0.2,0.2,0.1,0.6,0.3,0.4C488.7,208.6,488.5,208.2,488.3,208.4 M281.3,208.8C281.4,208.9,281.4,208.9,281.3,208.8 M325,210.3
        c-0.2,0-0.3,0.1-0.4,0.1c-0.2,0.3-0.5,0.4-0.8,0.5c-0.3,0.2-0.5,0.4-0.8,0.6c-0.1,0-0.3,0-0.4,0c-0.1,0.1-0.2,0.2-0.3,0.3
        c0,0.1,0.1,0.2,0.2,0.2c-0.5,0.1-1.1,0.2-1.3,0.6c-0.1,0.2-0.4,0.2-0.6,0.2c-0.2,0.3-0.4,0.6-0.8,0.8c-0.5,0.2-0.8,0.7-1.3,0.7
        c-0.3,0.3-0.7,0.6-1.1,0.9c-0.4,0.2-0.7,0.4-1,0.7c-0.2,0-0.5,0-0.6,0.3c-0.2,0.4-0.8,0.5-1.1,0.7c-0.1,0.3-0.5,0.5-0.7,0.7
        c-0.2,0.1-0.3,0.1-0.5,0.2c-0.4,0.5-0.9,0.8-1.5,1c-0.2,0.2-0.3,0.4-0.6,0.6c-1.1,0.8-2.2,1.7-3.3,2.4c-0.1,0-0.2,0.1-0.3,0.1
        c-0.1,0.5-0.7,0.6-1,0.9c-0.5,0.4-1.1,0.5-1.5,1c-0.5,0.5-1.1,0.8-1.6,1.1c-0.2,0.1-0.4,0.2-0.5,0.3c0.1,0,0.2,0.1,0.2,0.2
        c-0.1,0.2-0.2,0.3-0.2,0.5c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.3-0.1,0.7-0.4,0.9c0.1,0,0.3-0.1,0.4-0.2
        l-0.1,0.5c0.1-0.1,0.3-0.1,0.4-0.2c0,0.1-0.1,0.3-0.2,0.4c0,0.1,0,0.3,0,0.5c0-0.2,0-0.3,0-0.5c-0.4-0.1-0.3,0.3-0.2,0.5
        c-0.3,0.2,0,0.3,0.1,0.5c0.2,0.2,0.2,0.5,0.2,0.8c-0.2-0.2-0.3-0.5-0.2-0.8c-0.1,0-0.2,0.1-0.3,0.2c0.1,0.3,0.4,0.7,0.1,1
        c0,0.3-0.1,0.7-0.2,1c0.3-0.1,0.4,0,0.4,0.3c0.1,0,0.2,0,0.2,0c0,0,0,0.1,0,0.1c-0.1,0-0.2,0-0.2,0c0.1,0.3-0.2,0.4-0.3,0.6
        c0.1,0,0.3-0.1,0.5-0.1c-0.2,0.4,0.1,0.6,0.3,0.8c-0.2,0-0.3,0-0.5,0c0-0.1-0.1-0.4-0.1-0.5c-0.2,0.2-0.3,0.5-0.4,0.8
        c0.2,0,0.3-0.2,0.4-0.3c0.1,0.3,0.3,0.6,0.5,0.8c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1-0.1-0.2-0.3-0.3-0.4c0,0.1,0,0.2,0,0.3
        c0.3,0.3,0.6,0.1,0.8-0.1c-0.1,0.2-0.3,0.4-0.4,0.7c0.3,0.1,0.4,0.3,0.6,0.4c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1-0.2-0.3-0.4-0.4-0.7
        c-0.2,0.2-0.1,0.4,0,0.5l-0.2,0.1c0.1,0,0.3,0.1,0.3,0.1c0,0.2,0,0.3,0,0.5c0-0.1,0.1-0.4,0.1-0.5c0.4,0.1,0,0.5,0.1,0.8
        c-0.1,0.6,0.7,1,0.5,1.5c0.1,0.2,0.3,0.4,0.4,0.6c0,0.2-0.1,0.4-0.2,0.6c0.3,0.2,0.5,0.4,0.7,0.7c-0.2,0-0.3,0.1-0.5,0.2
        c0-0.1,0-0.4,0-0.5c-0.2,0.1-0.3,0.2-0.4,0.4c0.2,0.1,0.4,0.2,0.6,0.3c0,0.1,0,0.2,0,0.2c-0.1,0.1-0.3,0.1-0.4,0.2
        c0.1,0,0.3,0,0.4-0.1c0.2,0.4-0.2,0.6-0.3,1c0.2-0.3,0.5-0.5,0.8-0.7c0.1,0.2-0.3,0.5,0,0.7c0.1-0.1,0.3-0.2,0.4-0.3l-0.4,0
        c0.1-0.1,0.3-0.2,0.4-0.3c-0.1,0-0.3-0.1-0.3-0.1l0.2,0c0.1-0.1,0.2-0.2,0.2-0.2c0-0.1,0-0.2,0-0.2c-0.1,0.1-0.3,0.3-0.4,0.1
        c0.1-0.5,0.8-0.5,1-1c-0.2,0.1-0.4,0.2-0.6,0.3c0-0.1,0-0.3,0-0.4c0.3-0.2,0.3-0.6,0.3-0.8c-0.1,0-0.3-0.1-0.3-0.1
        c0.1-0.1,0.2-0.1,0.3-0.2l-0.1-0.2c0.1,0,0.3-0.1,0.4-0.1c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0,0.3,0.1,0.4,0.1c0.1-0.3,0.1-0.6,0.2-0.9
        c0-0.1-0.1-0.2-0.1-0.3c-0.1,0.2-0.1,0.4-0.1,0.7c-0.1,0-0.2-0.1-0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c-0.1,0-0.2-0.1-0.3-0.1
        c0.3,0,0.4-0.5,0-0.4c0.1,0,0.2,0,0.2,0c0.2-0.3,0.2-0.7,0.6-0.8c0,0.1,0,0.4,0,0.6c0.1-0.1,0.2-0.1,0.2-0.2
        c-0.5-0.1,0.1-0.4,0.1-0.6l-0.2,0.1l-0.1,0.1c-0.1,0-0.4,0-0.3-0.1l0.1-0.1c0-0.1-0.1-0.2-0.1-0.3c0.1,0.1,0.3,0.2,0.4,0.3
        c-0.4-0.6,0.5-0.6,0.2-1.1c0.1,0.1,0.2,0.2,0.3,0.2c0-0.3-0.4-0.7,0.1-0.6c0.2-0.2,0.3-0.4,0.1-0.6c0.2,0,0.4,0,0.6-0.1
        c-0.1-0.2-0.1-0.4-0.2-0.6c0.1,0,0.3,0,0.4,0c-0.1-0.2-0.3-0.3-0.4-0.4c0.3,0,0.5-0.1,0.6-0.3c0.1,0.2,0.3,0.4,0.1,0.7l-0.1-0.3
        l-0.1,0.2c0.1,0.1,0.2,0.2,0.3,0.3l0-0.1c0.1,0,0.3,0,0.3,0l0.1-0.1c0.1,0,0.2,0,0.2,0l0-0.1l-0.2,0c0-0.2,0-0.4,0-0.6
        c-0.2,0.1-0.5,0.1-0.8,0.1l0.1-0.3l0.2,0c0.1-0.2,0-0.6,0.4-0.5c0.1,0,0.2-0.1,0.3-0.2c0,0,0-0.1,0-0.1c-0.2,0.1-0.3,0.1-0.5,0.2
        c0-0.2,0-0.4,0-0.5c0.1,0.1,0.2,0.2,0.2,0.2c0.2-0.1,0.3-0.3,0.3-0.5c-0.2,0-0.3,0-0.5,0c0.2-0.1,0.3-0.4,0.6-0.3
        c-0.2-0.4,0.2-0.5,0.5-0.5c0-0.1,0.1-0.3,0.1-0.4c0.3-0.2,0.2-0.5,0.3-0.8c0.1,0,0.3,0.1,0.3,0.2c0.3-0.3,0.1-0.7,0.2-1
        c0.1,0.1,0.2,0.2,0.3,0.3c-0.1-0.2-0.1-0.4-0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2c-0.5,0.4,0.1,0.4,0.3,0.4c0,0-0.1-0.1-0.2-0.2
        c0.3-0.2,0.5-0.7,1-0.7c-0.1-0.2-0.3-0.3-0.3-0.5c0.3-0.1,0.6-0.3,0.8-0.6l-0.2,0l0,0.2c-0.2,0-0.4,0-0.5,0.1
        c-0.1-0.3,0.2-0.4,0.4-0.6c-0.1-0.1-0.1-0.3-0.1-0.4c0.3,0.1,0.3,0.2,0.2,0.5c0.2-0.2,0.2-0.5,0.4-0.8c-0.1,0-0.3,0.1-0.4,0.1
        c0.1-0.5,0.6-0.4,0.9-0.2c0,0-0.1-0.1-0.2-0.2c0-0.2,0.1-0.4,0.1-0.5c0.1-0.1,0.2-0.2,0.3-0.3c-0.1,0-0.3,0-0.4,0
        c0.1-0.2,0.4-0.3,0.6-0.4c0,0.1,0,0.3,0,0.3c0.2-0.1,0.5-0.2,0.7-0.3c-0.2-0.1-0.3-0.2-0.5-0.2c-0.1,0-0.2,0.1-0.2,0.1
        c0-0.3,0.5-0.3,0.4-0.7c0.2-0.2,0.4-0.4,0.7-0.4c0.1,0,0.3-0.1,0.4-0.1l0.1,0.1c0,0,0.1,0.1,0.2,0.1c0-0.2,0-0.4,0-0.7
        c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.1-0.4,0.1-0.6,0l0-0.2c0.3,0,0.5-0.1,0.6-0.3c-0.7,0-0.1-0.4,0.2-0.4c-0.3-0.4,0.4-0.8,0.5-0.2
        l-0.3,0c0,0,0.1,0,0.2,0.1c0,0.4-0.2,0.8-0.3,1.2c0.1,0,0.3,0,0.3,0c-0.2-0.5,0.7-1.3-0.1-1.6c0.1-0.2,0.2-0.5,0.5-0.6
        c0-0.2,0.1-0.3,0.3-0.3c0-0.2,0-0.4,0.1-0.6c0,0.2,0,0.4,0.1,0.5c0.1-0.2,0.3-0.4,0.4-0.6l-0.2,0l0-0.1c0.1,0,0.2,0,0.2,0l0.3-0.3
        c-0.1,0-0.3-0.1-0.4-0.1c0.1-0.1,0.3-0.3,0.4-0.4c0,0.1,0,0.3,0.1,0.4c0.3-0.2,0.6-0.3,0.8-0.6c-0.2,0-0.4,0.1-0.6,0.1
        c0.1-0.2,0.4-0.2,0.6-0.3c-0.1-0.1-0.3-0.2-0.3-0.2c0.2-0.1,0.4,0,0.6,0.1c0-0.1,0-0.2,0-0.3c0.1-0.1,0.4-0.2,0.6-0.2
        c0,0.1-0.1,0.4-0.2,0.5c0.3-0.1,0.3-0.4,0.4-0.6c-0.3,0-0.4-0.3-0.3-0.5l0.1,0.2c0.2,0,0.3-0.1,0.4-0.2c0,0,0.1,0.1,0.1,0.2
        c0.3-0.3,0.6-0.6,0.5-1.1c0.1-0.1,0.3-0.2,0.4-0.2c0,0.1,0,0.3,0,0.4c0.2-0.2,0.4-0.3,0.5-0.5c0.1,0,0.3-0.1,0.4-0.2
        c-0.1,0-0.2-0.1-0.3-0.1c0.2-0.2,0.4-0.4,0.6-0.6c0,0.2,0.1,0.3,0.2,0.5c0.1-0.3-0.1-0.5-0.2-0.7c0.1,0,0.3,0,0.3,0.1l0.1-0.2
        c0.1,0,0.3,0,0.4,0c-0.1,0.2-0.2,0.5-0.3,0.7c0,0,0.1,0,0.2,0c0-0.1,0.1-0.3,0.1-0.4c0.2-0.1,0.4-0.2,0.6-0.2c0-0.1-0.1-0.2-0.2-0.3
        c0,0.1-0.1,0.3-0.1,0.3c-0.1-0.2-0.2-0.5-0.2-0.7c0.2,0.2,0.6,0.2,0.6-0.2c0.2-0.1,0.5-0.2,0.6-0.5c0.3-0.1,0.6-0.6,0.8-0.2
        c0.1-0.2-0.1-0.3-0.2-0.4c0.4,0.1,0.5-0.4,0.8-0.3c0.2-0.3,0.4-0.6,0.8-0.6c0,0,0.1,0.1,0.1,0.2c0.3-0.2,0.1-0.2-0.1-0.2
        c0-0.1,0.1-0.2,0.1-0.3C325.7,209.6,325.3,209.9,325,210.3 M210.9,210C211,210.1,211,210.1,210.9,210 M240.6,210
        c-0.1,0.2,0,0.3,0.2,0.2C240.9,210,240.9,209.9,240.6,210 M210.5,210.2C210.6,210.7,211,209.9,210.5,210.2 M478.4,210.2
        C478.5,210.3,478.5,210.3,478.4,210.2 M26,210.5c0.2,0.2,0.3,0.5,0.6,0.6C26.6,210.7,26.3,210.6,26,210.5 M488,211
        c0.4,0.2,0.5-0.3,0.7-0.5C488.4,210.6,488.2,210.7,488,211 M104,211.2C104,211.7,104.4,210.9,104,211.2 M239.7,211.5
        c0.1,0.3,0.6-0.1,0.5-0.4C239.9,211.1,239.8,211.4,239.7,211.5 M26.8,211.5C26.8,211.6,26.8,211.6,26.8,211.5 M487.9,211.5
        C488,211.6,488,211.6,487.9,211.5 M161.8,212.5c0.2,0.2,0,0.8-0.1,0.2c-0.1,0.1-0.2,0.3-0.3,0.4c0-0.1,0-0.3,0-0.4
        c-0.2,0.3-0.9-0.1-0.7,0.4c-0.1-0.1-0.2-0.2-0.3-0.2l-0.2,0.1c-0.1-0.1-0.2-0.2-0.3-0.3c0,0.1-0.1,0.4-0.1,0.5
        c0.6,0.3,1.3,0.2,2,0.3c0.1-0.1,0.2-0.2,0.2-0.3c0,0.1-0.1,0.3-0.2,0.3c0.4,0.3,0.4-0.2,0.5-0.4c-0.1,0.4,0.2,0.6,0.5,0.8
        c0.1-0.2,0-0.4-0.1-0.5c0.1,0,0.2,0,0.2,0c0,0.2,0,0.5-0.1,0.7c0.5,0,0.9,0.1,1.3,0.3c0-0.2-0.1-0.5-0.1-0.6c-0.1,0-0.2-0.1-0.3-0.1
        l0.4,0c-0.2-0.5-0.6-0.8-1-1.2l0.1,0.3c-0.1,0-0.3,0.1-0.4,0.1l0-0.1c0.4-0.1,0.1-0.3-0.1-0.5c0,0.1,0,0.2,0.1,0.3
        c-0.2,0.3-0.6,0.1-0.9,0c0.1-0.1,0.1-0.2,0.3-0.2l0-0.2c0.1,0,0.2,0,0.3,0C162.3,211.9,162,212.4,161.8,212.5 M190.6,212.2
        c0.1,0.1,0.5,0,0.4-0.2C190.8,211.8,190.5,212,190.6,212.2 M323.2,212c-0.2,0.2,0.2,0.4,0.4,0.2C323.8,212,323.4,211.8,323.2,212
        M161.3,212.3c0.1,0.2,0.2,0.2,0.3,0C161.5,212.2,161.4,212.2,161.3,212.3 M314.1,212.2C314.2,212.3,314.2,212.3,314.1,212.2
        M351.8,212.2L351.8,212.2c0.2,0.2,0.3,0.2,0.5,0.3c0,0.1-0.1,0.2-0.1,0.3c-0.2-0.1-0.3-0.2-0.5-0.3c0,0.1,0,0.2,0,0.2
        c-0.2-0.2-0.5-0.4-0.4,0.1c-0.4,0-0.6,0.6-0.7,1c-0.4,0.6-0.9,1.1-1.3,1.8c-0.3,0.3-0.2,0.7-0.5,1c-0.5,0.6-0.8,1.3-1.2,1.9
        c-0.3,0.2-0.6,0.5-0.6,0.8c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.4-0.2,0.7-0.5,1c-0.2,0.6-0.7,0.9-1,1.5c-0.5,0.5-0.8,1.2-1.2,1.8
        c-0.4,0.3-0.6,0.7-0.9,1.1c-0.2,0.2-0.2,0.6-0.5,0.8c-0.4,0.3-0.5,1-1,1.2c-0.1,0.2-0.2,0.4-0.3,0.6c-0.2,0.3-0.6,0.3-0.8,0.5
        c0.1,0,0.3,0.1,0.3,0.1c-0.1,0-0.3,0-0.4,0c0.2,0.7-0.7,1-0.9,1.6c-0.5,0.6-0.8,1.2-1.4,1.7c0,0.1,0,0.2,0,0.3
        c-0.2,0.3-0.5,0.5-0.8,0.7c-0.2,0.6-0.7,1-1.2,1.4c0,0.4-0.4,0.6-0.7,0.8c-0.1,0.4-0.4,0.6-0.7,0.8c0,0.6-0.7,0.9-1.1,1.3l0.2,0
        c0,0,0,0.1,0,0.2c-0.1-0.1-0.2-0.2-0.3-0.3c0.1,0.7-0.6,0.8-0.8,1.4c-0.2,0.3-0.9,0.5-0.6,1c-0.1,0-0.2-0.1-0.3-0.2l-0.2,0.1
        c0,0.1,0,0.3,0,0.4c-0.1,0.1-0.2,0.1-0.3,0.2c-0.3,0.1-0.6,0.3-0.5,0.7c-0.4,0.1-0.4,0.5-0.7,0.7c-0.6,0.3-0.7,0.9-1.3,1.3
        c-0.2,0.1-0.3,0.2-0.4,0.5c-0.2,0-0.3,0-0.5,0.1c-0.4,0.4-0.5,1.1-1.1,1.2c-0.4,0.6-0.9,1.1-1.4,1.5c-0.1,0.3-0.3,0.4-0.6,0.4
        c-0.3,0.5-0.6,1-1.2,1.1c-0.1,0.2-0.2,0.3-0.3,0.5c-0.5,0.2-0.7,0.8-1.3,0.9c-0.3,0.4-0.6,0.9-1.1,1c-0.1,0.4-0.5,0.6-0.7,0.9
        c-0.5,0.2-0.7,0.6-1.1,0.8c-0.2,0.1-0.4,0.3-0.5,0.5c-0.3,0.1-0.5,0.3-0.6,0.6c-0.7,0.5-1.3,1.2-2,1.7c-0.3,0.3-0.6,0.6-1.1,0.8
        c-0.2,0.3-0.5,0.8-1,0.7c-0.3,0.3-0.4,0.8-0.9,0.8c-0.3,0.1-0.1,0.6-0.5,0.6c0.1,0.1,0.2,0.2,0.4,0.1c0.1,0.2,0.2,0.4,0.4,0.6
        c0,0.3-0.2,0.7,0.2,0.7c0.1,0.2,0,0.4,0.3,0.5c-0.1,0.2-0.2,0.4-0.2,0.7c0.1,0,0.2-0.1,0.3-0.1c0,0.3,0.4,0.7-0.1,0.7
        c0.1,0.2,0.2,0.4,0.4,0.6l-0.2,0c0,0.2,0,0.3,0,0.5c0.3,0.2,0.8-0.2,0.1-0.2c0-0.1,0.1-0.3,0.2-0.4c0.1,0.2,0.2,0.3,0.3,0.5
        c-0.1,0.1-0.2,0.2-0.3,0.2c0.1,0.2,0.3,0.3,0.4,0.5c-0.1,0-0.3,0-0.4-0.1c0,0.2,0.1,0.3,0.2,0.4c0,0.1,0,0.3,0,0.4
        c-0.1,0-0.2,0-0.3,0c0.1,0.2,0.2,0.3,0.3,0.3c0-0.1,0-0.3,0-0.5c0.2,0.2,0.1,0.5-0.1,0.6c0,0.1,0,0.3,0,0.4c0.1,0.1,0.1,0.2,0.2,0.2
        l0.1-0.1c0,0.1,0,0.4,0,0.6l-0.1,0.1c-0.1-0.2-0.2-0.4-0.3-0.5c0,0.1,0.1,0.3,0.2,0.4c0.2,0.1,0.4,0.3,0.5,0.5
        c-0.1,0-0.4-0.1-0.5-0.2c0.1,0.2,0.3,0.4,0.4,0.5c-0.4,0.3-0.4,0.9-0.2,1.3c-0.2,0.1-0.3,0.4-0.3,0.6c-0.2-0.1-0.2-0.3-0.3-0.4
        c0,0-0.1,0.1-0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.1-0.2,0.3-0.3,0.4-0.5c0.1,0,0.2,0,0.3,0c0-0.3,0.2-0.4,0.5-0.4
        c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.3-0.1,0.4-0.1c0-0.1,0-0.2,0-0.3c0.1,0,0.2,0,0.3,0l-0.1-0.2l0.2,0c0-0.1,0-0.2,0-0.2
        c0.1,0,0.2-0.1,0.2-0.2c0.1-0.1,0.3-0.1,0.4-0.2c0.1-0.5,0.5-0.6,0.8-0.9c0.1-0.1,0.2-0.3,0.4-0.3c0.2-0.3,0.4-0.7,0.8-0.6
        c0.2-0.2,0.3-0.4,0.4-0.6c0,0,0.1,0.1,0.1,0.1c-0.2,0.3-0.5,0.5-0.8,0.6c0,0.1,0,0.2,0,0.2c-0.1,0-0.2,0-0.2,0
        c-0.1,0.2-0.2,0.3-0.2,0.5c-0.3,0-0.4,0.3-0.4,0.5c-0.1,0-0.2,0-0.3-0.1c0,0.1,0,0.3,0.1,0.4c-0.1-0.1-0.2-0.1-0.4-0.2
        c0,0.2,0,0.4,0,0.7c-0.2,0-0.4,0-0.6,0c0,0.3,0,0.6-0.1,0.9l-0.2-0.1l0,0.2c-0.2,0.1-0.2,0.2-0.1,0.4c-0.1,0-0.2,0.1-0.3,0.1
        c0,0.1,0,0.3,0,0.4c-0.1,0-0.3,0.1-0.3,0.1c0,0.3-0.1,0.5-0.4,0.6c0,0.1,0.1,0.2,0.2,0.3l-0.2-0.1c0.1,0.3-0.1,0.6-0.3,0.9
        c-0.3,0-0.3,0.4-0.6,0.5c0,0.3-0.2,0.5-0.5,0.4c0,0.1,0.1,0.2,0.1,0.2l-0.2,0c-0.3,0.4-0.3,1-0.7,1.4c-0.1,0.2-0.2,0.4-0.3,0.6
        c-0.1,0-0.2-0.1-0.3-0.1c0,0.2,0,0.5,0,0.7c-0.1,0-0.3-0.1-0.4-0.1c0,0.1,0.1,0.4,0.1,0.5c-0.2,0.1-0.3,0.3-0.4,0.4
        c0,0.3-0.3,0.6-0.3,0.9c-0.1,0-0.3-0.1-0.3-0.1c0.3,0.3-0.1,0.6-0.3,0.9c-0.3,0.3-0.4,0.8-0.5,1.2c-0.1,0-0.3,0-0.4,0
        c0.1,0.6-0.4,0.9-0.6,1.4c-0.6,0.2-0.5,1-1.1,1.2c-0.2,0.3-0.1,0.8-0.6,0.8c-0.1,0.3-0.3,0.5-0.4,0.8c0,0.3,0,0.5-0.3,0.6
        c0,0.3-0.3,0.6-0.5,0.8c0,0.1,0,0.2,0,0.3c-0.5,0.6-0.9,1.3-1.3,2c0.1,0.3-0.2,0.4-0.4,0.5c-0.1,0.5-0.4,1-0.7,1.4
        c0.3,0.4-0.3,0.7-0.5,1.1c0.2,0.3,0,0.7-0.4,0.7c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.3-0.3,0.6-0.5,0.9c0,0.3,0,0.6-0.3,0.8
        c-0.5,0.4-0.3,1.2-0.8,1.6c0.1,0.4-0.2,0.8-0.3,1.1c-0.3,0.3,0,0.8-0.4,1c-0.1,0.4-0.2,0.9-0.6,1.2c0,0.1,0,0.3,0,0.3
        c-0.3,0.4-0.3,0.9-0.5,1.4c-0.3,0.9-0.7,1.8-1,2.8c0.1,0.4-0.2,0.7-0.4,1c0,0.1,0.1,0.3,0.1,0.4c-0.1,0.1-0.4,0.2-0.3,0.5
        c0,0.4-0.1,0.7-0.2,1c-0.2,0.5,0,1.2-0.5,1.6c0.2,0.3,0,0.6-0.1,0.9c-0.2,0.8-0.1,1.6-0.5,2.3c0.1,0.4-0.2,0.7-0.1,1.1
        c0.1,0.4-0.3,0.7-0.2,1.1c0.1,0.7-0.1,1.3-0.3,2c0.1,0.2,0.2,0.3,0.1,0.6c-0.1,0.4-0.1,0.8-0.1,1.2c0,0.3-0.1,0.6-0.1,1
        c0.1,0.5-0.2,0.9-0.3,1.3c0.4,1.2,0,2.4,0.1,3.7c-0.1,0.4,0.2,0.9-0.1,1.3c0.1,0.3,0.2,0.5,0.3,0.8c0,0.2-0.2,0.4-0.1,0.6
        c0.2,0.7-0.1,1.4,0,2.1c0.1,0.9-0.3,1.8-0.2,2.7c0.1,0.5-0.2,1.1,0,1.6c-0.1,0.2-0.2,0.3-0.2,0.5c0.1,0.2,0.2,0.5,0.3,0.7
        c0,0,0.1,0.1,0.2,0.1l-0.3,0c0,0.2,0,0.5,0.1,0.6c0.1,0,0.3,0,0.4,0c-0.1,0-0.2,0.1-0.3,0.1c0.1,0.4,0.1,0.8,0.1,1.2
        c0.1,0,0.2,0,0.2,0l0,0c-0.3,0.1-0.3,0.2-0.2,0.5c0.1,0,0.3-0.1,0.4-0.2c-0.1,0.2-0.2,0.4-0.3,0.5c0.6,0,0,0.7,0.3,1
        c0,0.1,0.1,0.4,0.1,0.6l-0.2,0c0,0,0,0.1,0,0.1l0.2,0c0,0.1,0,0.2,0,0.3c0.3,0.1,0.2,0.5,0.2,0.8l0.2-0.1c-0.1,0.2-0.2,0.3-0.2,0.5
        c0.1,0.2,0.2,0.3,0.3,0.4c-0.3,0.1-0.1,0.5-0.1,0.7c0.1,0,0.2,0,0.2,0c0,0.1,0.1,0.4,0.1,0.5c-0.1,0-0.3,0-0.4-0.1
        c0.1,0,0.3,0.1,0.4,0.1c-0.2,0.2-0.1,0.3,0.1,0.5c0,0.2,0,0.5,0,0.7c0.2,0.1,0.2,0.2,0.2,0.4l0.1,0c0.1,0.3-0.2,0.7,0.1,0.9
        c0,0.3,0.1,0.7,0.3,1c0.1,0.4,0,0.8,0.2,1.1c0.3,1,0.4,2.1,0.7,3.2c0,0.4,0.2,0.8,0.3,1.1c0,0.4,0.1,0.9,0.2,1.3
        c0.1,0.3,0,0.6,0.2,0.9c0.2,0.6,0.1,1.2,0.4,1.8c0,0.1,0.1,0.2,0.1,0.2c-0.1-0.2-0.2-0.4-0.3-0.6c-0.2-0.5-0.1-1.1-0.4-1.6
        c-0.1-0.4-0.2-0.8-0.3-1.2c-0.4-1.5-0.9-3.1-1.3-4.6c-0.5-1.5-0.9-3-1.4-4.5c-0.1-0.9-0.6-1.6-0.7-2.4c0-0.6-0.5-1.1-0.6-1.7
        c-0.1-0.9-0.7-1.7-0.8-2.6c-0.4-0.9-1-1.7-1.1-2.6c-0.4-0.3-0.5-0.8-0.8-1.2c-0.2-0.5-0.5-0.9-0.6-1.4c-0.2-0.7-0.6-1.4-0.5-2.2
        c-0.2-0.3-0.4-0.5-0.5-0.7c0-0.4,0.1-0.9-0.3-1.2c-0.3-0.3-0.5-0.7-0.4-1.1c0.1-0.5-0.6-0.8-0.5-1.2c0-0.4-0.3-0.6-0.4-0.9
        c-0.2-0.6-0.3-1.2-0.7-1.7c-0.2-0.7-0.7-1.3-0.7-2c-0.3-0.3-0.5-0.6-0.5-1c-0.3-0.4-0.6-0.9-0.6-1.4c-0.1-0.1-0.2-0.2-0.3-0.3
        c0-0.3,0-0.5-0.2-0.7c-0.4-0.8-1-1.5-1.2-2.3c-0.2-0.3-0.5-0.7-0.7-1c-0.3-0.4-0.3-0.9-0.6-1.3c-0.4-0.5-0.6-1.1-0.9-1.6
        c-0.1-1-1.1-1.6-1.1-2.6c-0.6-0.2-0.8-0.9-1.1-1.5c-0.5-0.4-0.4-1.2-1.1-1.4c-0.1-0.2-0.2-0.4-0.3-0.6c-0.2-0.2-0.4-0.4-0.6-0.7
        c-0.2-0.6-0.7-0.9-0.9-1.5c-0.4-0.3-0.3-1-0.8-1.1c0-0.1-0.1-0.2-0.2-0.3c0-0.1,0-0.3,0-0.3c-0.1,0-0.2-0.1-0.3-0.1
        c-0.3-0.8-1-1.3-1.2-2.2c-0.1,0-0.2-0.1-0.3-0.2c0-0.3-0.1-0.6-0.3-0.8c-0.2,0-0.3,0.1-0.4,0.1c0-0.1,0.1-0.3,0.2-0.3
        c-0.1-0.3-0.3-0.4-0.6-0.3c0.2-0.2,0.3-0.5-0.1-0.6c0.1-0.3-0.2-0.3-0.4-0.4c-0.1-0.2-0.3-0.3-0.5-0.3c0,0,0-0.1,0-0.2l0.3,0
        c-0.2-0.5-1-0.6-1-1.2c-0.3-0.2-0.6-0.3-0.8-0.6c-0.1,0.1-0.2,0.2-0.4,0.1c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0-0.3,0-0.4,0
        c0,0.1,0,0.2,0,0.3c-0.2,0-0.4,0-0.5,0.2c-0.1,0.1-0.3,0.2-0.3,0.3c-0.1-0.1-0.2-0.2-0.2-0.3c0,0.1,0.1,0.3,0.2,0.4
        c-0.1,0-0.3,0.1-0.4,0.2c0,0.1,0.1,0.3,0.1,0.3c-0.1,0-0.2-0.1-0.3-0.1c0,0.1-0.1,0.3-0.1,0.5c-0.3,0-0.4,0.3-0.6,0.5
        c-0.2,0.2-0.5,0.3-0.4,0.6c-0.1,0-0.2-0.1-0.3-0.1c0,0.1,0,0.2,0.1,0.2c-0.1,0.1-0.3,0.3-0.4,0.4c0,0.1,0.2,0.3,0.1,0.3
        c-0.1,0.1-0.2,0-0.3-0.1c-0.2,0.2-0.3,0.3-0.5,0.5c0.1,0.1,0.1,0.3,0.2,0.4c-0.2-0.4-0.7-0.2-0.4,0.2c-0.1,0.1-0.2,0.2-0.3,0.3
        c0.2,0.3-0.1,0.7-0.4,0.4c0.2,0.3,0.3,0.6,0.2,0.9c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1,0.4-0.8,0.5-0.2,0.9c-0.4-0.5-0.5,0-0.7,0.1
        c0.2,0.4-0.3,0.8-0.7,0.8c0,0.1,0,0.3,0,0.4c0.4,0,0.1,0.3-0.1,0.4c0-0.1,0-0.3-0.1-0.4c-0.1,0.1-0.2,0.2-0.3,0.2
        c0.1,0.2,0.2,0.4,0.3,0.6c-0.2,0-0.3-0.1-0.5-0.1l0.1,0.4c-0.1,0-0.3,0-0.4-0.1c0.1,0.2-0.1,0.8-0.4,0.6c0-0.1,0-0.2,0.1-0.3
        c0,0-0.1,0-0.1,0c0,0.2-0.2,0.5,0.2,0.4l0,0.3c-0.3-0.2-0.4,0.1-0.5,0.3c0.1,0.2,0.2,0.4,0.3,0.5c-0.2-0.3-0.5-0.1-0.7,0
        c0.1,0.1,0.2,0.3,0.3,0.4c-0.1,0-0.4,0-0.6,0c0.1,0.1,0.2,0.2,0.4,0.3c-0.3,0.1-0.3,0.3-0.2,0.6c-0.1,0-0.2,0-0.3,0
        c0.1,0.2-0.1,0.5,0.1,0.7l0,0c-0.1,0-0.3,0-0.4,0c0,0.3-0.1,0.6-0.2,0.9c-0.2,0.1-0.5,0.2-0.6,0.5c0.1,0,0.2,0.1,0.3,0.2
        c0.1,0.3-0.4,0.2-0.4,0.4c0,0.1-0.1,0.4-0.2,0.6c0.1,0,0.2,0,0.2,0c-0.3,0.3-0.8,0.7-0.6,1.2c-0.3,0.2,0.2,0.7-0.4,0.7
        c0,0.2-0.1,0.3-0.1,0.5c0.1,0.5-0.1,1-0.2,1.5c0,0.2,0.1,0.5,0.1,0.7c-0.2,0.2,0,0.5-0.1,0.8c-0.2,0.2,0.1,0.5,0,0.6
        c-0.1,0.1-0.2,0.3-0.1,0.5c0,0.6,0,1.3,0,2c0,0.4,0.1,0.9,0,1.3c0.2,0.3,0.4,0.6,0.2,1c0.1,0.6,0.2,1.2,0.3,1.7
        c0.1,0.5,0.3,1,0.3,1.5c0.1,0.3,0.1,0.6,0,1c0.1,0.3,0.1,0.6,0.2,0.9c-0.1,0.3-0.1,0.6,0.1,0.8c0,0-0.1,0.1-0.2,0.2
        c0.1,0.5,0.1,1.1,0.5,1.6c0.3,0.5,0.1,1.2,0.4,1.8c0.1,0.4,0.2,0.7,0.3,1.1c0.1,0.4,0,0.9,0.3,1.2c0.2,0.5,0.2,1.1,0.4,1.6
        c0.3,0.6,0.4,1.2,0.6,1.9c0.3,0.4,0.5,0.9,0.7,1.4c0.1-0.1,0.2-0.1,0.3-0.2c0,0,0,0.1,0,0.1c-0.1,0-0.2,0-0.3,0
        c0.1,0.1,0.3,0.2,0.4,0.3c0,0-0.1,0.1-0.2,0.2c0.2,0.6,0.3,1.2,0.4,1.8c0.5,0.5,0.5,1.2,0.7,1.9c0.1-0.1,0.2-0.3,0.2-0.4
        c-0.1,0.4-0.2,0.8,0.1,1.2c0.1,0.6,0.4,1,0.5,1.6c0.1,0.5,0.5,1,0.2,1.5c0.1,0.3,0.4,0.6,0.6,0.8c0.3,0.5,0.3,1.1,0.6,1.6
        c-0.2,0.6,0.1,1.3,0.5,1.7c0.2,0.3,0.1,0.7,0.3,0.9c0.1,0.1,0.3,0.3,0.3,0.5c0.1,0.6,0.6,1.2,0.6,1.8c0.3,0.2,0.5,0.5,0.5,0.9
        c0.3,0.4,0.4,1,0.8,1.3c0.3,0.7,0.7,1.4,1,2.1c0.4,0.6,0.5,1.3,0.9,1.9c0.4,0.4,0.4,1,0.8,1.5c0.2,0.6,0.4,1.3,1,1.7
        c0-0.2,0.1-0.4,0.1-0.6c0.2-0.8,0.1-1.6,0.2-2.4c0.2-0.3,0.3-0.7,0.3-1.1c0-0.4,0-0.9-0.1-1.3c-0.2,0-0.3,0.2-0.2,0.4
        c0,0-0.1,0-0.1,0c0.1-0.2,0.1-0.4,0.2-0.6l0.1-0.1l0-0.1c-0.1,0-0.2-0.1-0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0,0.3,0.1,0.6,0.2,0.9
        c0.3,0.9,0.2,1.9,0.2,2.9c0,0.5-0.2,0.9-0.1,1.4c-0.1,0.6-0.1,1.3,0,2l-0.2,0c0,0,0.1,0.1,0.1,0.2c0,0.1,0.1,0.4,0.1,0.5
        c0.1-0.1,0.2-0.2,0.3-0.3l0,0c0,0.1-0.1,0.3-0.2,0.4l0.4-0.1c-0.1,0.1-0.2,0.2-0.4,0.3c0.1,0.1,0.4,0.2,0.5,0.2
        c-0.1,0-0.2,0.1-0.2,0.1c0,0.3,0.2,0.5,0.5,0.5c0-0.2,0-0.4,0-0.5c0.1,0.2,0.1,0.4,0.2,0.6c-0.1,0-0.2,0-0.3-0.1c0,0,0,0.1,0,0.2
        c0.4,0.4,0.7,0.9,1,1.3c0.1-0.1,0.3-0.2,0.4-0.3c0-0.1,0.1-0.2,0.1-0.3c0.4-0.5,0.8-1,1.2-1.6c0.3-0.6,0.9-1,1.1-1.7
        c0.4-0.3,0.5-0.8,0.9-1.1c0.2-0.4,0.4-0.9,0.6-1.3c0.4-0.5,0.6-1.2,1-1.7c0.2-0.2,0.3-0.5,0.4-0.7c0.2-0.4,0.4-0.8,0.6-1.2
        c0.1,0,0.2,0,0.3,0c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.2,0.3-0.2,0.5-0.2c-0.1-0.2-0.2-0.4,0-0.5c-0.4,0.3-0.7,0.7-0.9,1.1
        c-0.1,0.1-0.2,0.3-0.4,0.4c-0.1,0.4-0.3,0.8-0.5,1.2c-0.1,0.1-0.2,0.4-0.5,0.4c0,0,0.1,0.1,0.2,0.2c-0.2,0.2-0.4,0.5-0.5,0.7
        c0,0.1,0,0.3,0,0.4c-0.5,0.2-0.6,0.9-0.9,1.3c-0.2,0.3-0.4,0.7-0.7,0.9c0.1-0.2,0.2-0.4,0.2-0.7c0,0,0.1,0,0.1,0
        c0-0.2,0-0.4,0.2-0.5c0.1-0.4,0.2-0.8,0.4-1.1c0-0.1,0-0.2,0-0.3c0,0-0.1,0.1-0.1,0.2c-0.7,0.3-1.1,1-1.8,1.4
        c-0.4,0.2-0.6,0.7-1.1,0.8c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.5,0.6-0.9,0.8-1.3c0.3-0.5,0.7-0.9,1-1.3c-0.3-0.2-0.7-0.5-1-0.8
        c-0.6-0.2-0.8-0.9-1.3-1.1c-0.3-0.2-0.5-0.4-0.7-0.6c-0.4-0.5-0.9-0.8-1.2-1.3c-0.4-0.2-0.7-0.7-0.9-1.1c-0.4,0.1-0.4-0.4-0.6-0.5
        c-0.4-0.2-0.3-0.7-0.7-0.8c-0.3-0.3-0.8-0.6-0.7-1.1c-0.6,0-0.7-0.8-1.1-1.1c-0.4-0.3-0.3-1-0.8-1c-0.1-0.2-0.2-0.5-0.5-0.5
        c-0.1-0.4-0.2-0.7-0.4-1.1c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1-0.1-0.1-0.2-0.2-0.3c0.1,0,0.3,0,0.4,0c-0.2-0.2-0.2-0.4-0.2-0.6
        c-0.2,0-0.4,0-0.6,0c0-0.1,0.1-0.3,0.2-0.4c-0.4-0.1-0.4-0.8-0.9-0.8c0,0.1-0.1,0.2-0.2,0.3c0-0.2,0-0.3,0-0.5
        c-0.1-0.1-0.3-0.1-0.4-0.2c0.3,0.1,0.6,0.3,1,0.4c-0.1-0.5-0.7-0.7-0.8-1.2c-0.4-0.1-0.2-0.7-0.6-0.7c0,0,0-0.1,0-0.1l0.3,0
        c-0.2-0.3-0.4-0.6-0.7-0.8c0.1-0.2,0.1-0.4,0.1-0.6c-0.3-0.2-0.4-0.5-0.5-0.9c-0.1,0-0.2,0-0.3-0.1c0.3-0.2,0.1-0.6,0-0.9
        c-0.3-0.3-0.3-0.8-0.6-1.1c-0.1,0.2-0.1,0.4-0.2,0.6c0-0.3,0.1-0.6,0.2-0.9c0-0.2-0.1-0.5-0.1-0.7c-0.2-0.2-0.4-0.5-0.5-0.7
        c0.1-0.1,0.1-0.3,0.2-0.4c-0.4-0.2-0.6-0.7-0.8-1.1c0.3-0.4-0.1-0.7-0.3-1.1c0-0.2,0.1-0.4-0.1-0.6c-0.1,0.1-0.2,0.3-0.4,0.5
        c-0.1-0.4,0.2-0.7,0.3-1.1c-0.1-0.3-0.5-0.4-0.4-0.7c-0.3-0.1-0.4-0.4-0.5-0.6c0,0,0.1,0,0.2,0c0,0.1,0,0.2,0.1,0.2
        c0.4,0.4,0.4-0.3,0.4-0.6c-0.2,0-0.3,0.1-0.5,0.2c0.1-0.4,0-0.8-0.1-1.3c-0.1,0-0.3,0.1-0.4,0.1c0.2-0.1,0.3-0.2,0.3-0.5
        c-0.1,0-0.2-0.1-0.3-0.1c0.1,0,0.2,0,0.3,0c0-0.1,0.1-0.3,0.1-0.4c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.3,0-0.4
        l0.2,0.1c-0.1-0.3-0.1-0.5,0-0.8c-0.1,0-0.3-0.1-0.4-0.1c0.1,0,0.3,0.1,0.4,0.1c-0.1-0.3,0.1-0.7-0.2-0.9c0.2-0.3-0.2-0.7,0.1-1
        c0.1-0.1,0.2-0.3,0.1-0.4c-0.3-0.4-0.2-0.9-0.1-1.3c-0.1,0-0.3,0.1-0.4,0.1c0.1-0.1,0.2-0.1,0.3-0.2c0-0.4,0-0.8,0-1.2
        c-0.1-0.3-0.1-0.7,0.2-0.9c-0.1-0.2-0.2-0.3-0.3-0.4c0.1-0.2,0.2-0.3,0.3-0.5c-0.2-0.2-0.3-0.5-0.2-0.8c0-0.1,0-0.3,0-0.4
        c0.2-0.7,0-1.3,0.2-2c-0.1-0.5-0.1-1.2,0.4-1.6c-0.2-0.5-0.1-0.9-0.1-1.4c0.1-0.1,0.2-0.2,0.2-0.3c-0.1-0.4,0-0.8,0.1-1.1
        c0.1-1.2,0.3-2.4,0.9-3.6c0-0.3,0-0.5,0.1-0.7c-0.1-0.5,0.3-0.9,0.4-1.4c0.2-0.7,0.5-1.4,1.1-1.9c0.1-0.3,0.2-0.5,0.4-0.7
        c-0.1,0.2-0.2,0.4-0.2,0.6c-0.6,0.7-0.8,1.6-0.7,2.5l-0.2,0c0,0.1,0.1,0.4,0.1,0.5l-0.2,0c0.1,0.2,0.2,0.5,0.2,0.7l-0.2,0
        c0,0.1,0,0.3,0,0.5l0.1,0c0,0.1,0,0.2,0,0.3l-0.2,0c0,0.1,0,0.4,0,0.5l-0.2-0.1c0,0.2,0,0.5,0,0.7c0.1,0,0.2,0,0.3,0
        c0,0,0,0.1,0,0.1c-0.1,0-0.2,0-0.3,0c0,0.3,0.3,0.6,0.1,0.9c-0.2-0.2,0-0.4,0-0.5c-0.1,0-0.2,0-0.2,0c-0.1,0.2-0.1,0.3-0.2,0.5
        c0.1,0.1,0.2,0.1,0.3,0.2c0,0.2,0,0.5-0.2,0.7c0.2,0.1,0.3,0.2,0.5,0.3c-0.1,0-0.4,0-0.5-0.1c0,0.3,0.3,0.4,0.5,0.6
        c-0.2-0.1-0.3-0.1-0.5-0.2c0.1,0.1,0.2,0.3,0.3,0.4c-0.3,0.2-0.1,0.7-0.1,1c-0.1,0.1-0.2,0.2-0.3,0.3l0.3,0
        c-0.1,0.2-0.3,0.5-0.5,0.3c-0.1-0.2-0.1-0.3-0.1-0.5c0,0-0.1,0-0.1,0l0,0.1c0,0.1,0,0.2,0,0.3c0.3,0.3,0,0.7,0.1,1.1
        c0.1-0.1,0.1-0.2,0.2-0.3l-0.2-0.1c0.1-0.2,0.3-0.4,0.6-0.5c0,0.1,0,0.3,0,0.4c0.1,0,0.2,0,0.3,0c-0.1,0.1-0.2,0.2-0.3,0.3
        c0.1,0.1,0.2,0.3,0.3,0.3c-0.3,0-0.3,0.2-0.5,0.3l0.1,0c0.1-0.4,0.4,0,0.6,0.1c-0.1,0.2-0.3,0.3-0.2,0.5c-0.1,0-0.3-0.1-0.4-0.1
        c0.1,0.2,0.3,0.4,0.4,0.6c0,0-0.1,0.1-0.2,0.1c0,0.1,0,0.4,0,0.5c0.3,0.1,0.2,0.4,0.2,0.6c0.1,0,0.2,0,0.2,0
        c-0.1,0.2-0.2,0.5-0.4,0.7l0.3,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0,0.3-0.1,0.5-0.1c-0.1,0.2-0.5,0.9,0,0.9
        c-0.2,0.3-0.2,0.6-0.2,0.9c0,0-0.1,0.1-0.2,0.1c0.1,0,0.2,0.1,0.3,0.1c0.3,0.3-0.2,0.3-0.3,0.4c0.1,0,0.3,0,0.4-0.1l0,0.4
        c-0.1,0-0.3-0.1-0.3-0.1c0,0.2,0.1,0.4,0,0.6c0.1,0.1,0.2,0.3,0.3,0.4c0-0.1,0.1-0.2,0.2-0.2c-0.1,0-0.3-0.1-0.4-0.2
        c0.2-0.2,0.4-0.4,0.5-0.1c-0.2,0.2-0.2,0.5-0.1,0.8c0.1,0,0.2,0,0.2,0c-0.1,0-0.2,0-0.3,0c-0.1,0.4,0.4,0.5,0.3,0.8
        c-0.1,0.1-0.3,0.2-0.4,0.3c0.1,0,0.3-0.1,0.4-0.1c0.1,0.2,0.2,0.5-0.1,0.6c0-0.1,0-0.3,0-0.4c-0.4,0.1,0,0.4,0.1,0.5
        c0,0.3,0,0.6,0,0.9l0.2,0c0-0.2,0.1-0.5,0.2-0.6c0,0.1,0,0.3,0,0.5c-0.1,0.2-0.4,0.4-0.2,0.6c0.1,0.3,0.5,0.1,0.7,0.3
        c-0.1,0-0.3,0-0.3-0.1c-0.3,0.3,0.3,0.6,0.5,0.8c-0.2-0.1-0.5-0.2-0.7-0.2c0.1,0.3,0.4,0.6,0.7,0.6c0,0,0,0.1,0,0.2
        c-0.2-0.1-0.4-0.2-0.6-0.3c0.1,0.1,0.2,0.4,0.3,0.5l0,0.3c-0.2-0.2-0.3-0.4-0.6-0.5l0.2,0.2l-0.5,0c0.3,0,0.6-0.1,0.6,0.3
        c0.2,0,0.3,0,0.5,0c0,0.2-0.3,0.5-0.1,0.7c0.1,0,0.2-0.1,0.2-0.2c-0.1,0.2-0.2,0.4-0.2,0.7c0.1-0.1,0.2-0.2,0.3-0.3l0.1,0
        c-0.1,0.2-0.2,0.4-0.4,0.6c0.2,0.2,0.2,0.5,0.2,0.7c0.2-0.1,0.4-0.3,0.6-0.3c-0.1,0.1-0.2,0.2-0.3,0.3c0,0.1,0,0.3-0.1,0.4
        c0.1,0.1,0.3,0.3,0.4,0.4l-0.4-0.1l-0.1,0.1c0.2,0.3,0.5,0.3,0.7,0c-0.1,0.2-0.3,0.4-0.4,0.6c0.2,0.2,0.4,0.4,0.5,0.7
        c-0.1,0-0.3,0-0.5-0.1c0.1,0.1,0.2,0.2,0.4,0.2c-0.1,0.7,0.4,1.2,0.5,1.8c0.1,0.3,0.2,0.6,0.3,0.9l0.3-0.2c-0.3,0.4,0,0.9,0.2,1.3
        c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0.7,0.3,0.2,0.4c0.1,0.2,0.1,0.4,0,0.6c0.1,0,0.2,0,0.3,0c-0.1,0.3,0.1,0.5,0.2,0.8
        c0.1,0,0.3,0,0.3-0.1c0,0.1,0,0.3,0,0.4c-0.2-0.1-0.3-0.1-0.5-0.2c0.2,0.2,0.5,0.3,0.4,0.6c-0.1,0.3,0.2,0.4,0.4,0.3
        c0,0.5,0.1,1,0.4,1.4c-0.1,0.1-0.3,0.2-0.5,0.1c0,0,0,0.1,0,0.1c0.2,0,0.4,0,0.7,0c0,0.1,0,0.3,0.1,0.4c0.2,0,0.4,0.1,0.6,0.1
        c0,0.1-0.1,0.2-0.1,0.4c0.1,0,0.3,0,0.4,0c-0.2,0.1-0.5,0-0.5,0.3c0.1,0.2,0.3,0.3,0.5,0.4c0,0.1-0.1,0.2-0.2,0.2
        c0.2,0.2,0.5,0.3,0.3,0.6l0.3-0.1c-0.1,0.2-0.1,0.3-0.3,0.4c0.1,0.3,0.5,0.4,0.5,0l0,0c0,0.1,0,0.4,0,0.5c0.1,0,0.2-0.1,0.3-0.2
        c-0.1,0.2,0.1,0.3,0.3,0.3c-0.2,0-0.3,0.1-0.5,0.2c0.1,0.2,0.2,0.4,0.1,0.6c0.1,0.2,0.2,0.3,0.3,0.4c0,0.1,0,0.3,0,0.4l0.4-0.1
        l-0.2,0.3c0.1-0.1,0.1-0.2,0.2-0.2c0.3,0.3,0.2,0.6,0.3,1l0-0.4c0.1,0.1,0.3,0.3,0.4,0.4c-0.3,0.2-0.2,0.7,0,1
        c0.2-0.2,0.1-0.5,0-0.7c0.6,0,0.2,0.7,0.5,1c0.1-0.1,0.2-0.2,0.3-0.3c-0.1,0.1-0.3,0.3-0.4,0.5c0.1,0,0.3-0.1,0.4-0.1
        c0,0.3,0.1,0.7,0.4,0.8c0,0.1,0.4,0.5,0.1,0.3c-0.1,0-0.2-0.1-0.2-0.1l0-0.2c-0.2,0.1-0.1,0.3,0.1,0.4c-0.1,0.1-0.2,0.3-0.2,0.4
        c0.2-0.1,0.2-0.3,0.4-0.4c0.1,0.2,0,0.6,0.4,0.5c0,0.1,0,0.2,0,0.3c0.2-0.1,0.3-0.3,0.5-0.4c-0.1,0.2-0.2,0.3-0.2,0.5l0.3,0
        c0,0.1-0.1,0.2-0.1,0.3l0.2,0c0,0.3-0.2,0.7,0.3,0.6l0,0.2c0.1-0.1,0.2-0.2,0.2-0.3c0,0.1,0.1,0.2,0.1,0.3c0.5,0,0.2,0.6,0.1,0.9
        c0,0,0.1,0,0.1,0c0-0.1,0.1-0.3,0.2-0.3c0.1,0.2,0.3,0.3,0.5,0.4l0-0.4c0.1,0.2,0.1,0.3,0,0.5c0.1,0,0.2,0.1,0.3,0.1
        c-0.3,0.1-0.5,0-0.6-0.3c0,0.1,0,0.4,0.1,0.5c0.1,0,0.2-0.1,0.2-0.1c0.2,0.2,0.3,0.3,0.6,0.3c-0.1,0.1-0.2,0.2-0.3,0.3
        c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0,0.2-0.1,0.2-0.1c0.1,0.2,0.2,0.4,0.4,0.3l0,0.1c-0.1,0-0.2,0-0.3,0c0.2,0.2,0.3,0.5,0.6,0.7
        c0.1,0.1,0.1,0.3,0.3,0.3c0.1-0.3,0.3-0.6,0.6-0.8c0.1-0.3,0.3-0.5,0.6-0.7c0.3-0.3,0.5-0.7,0.7-1c0-0.2,0-0.4,0.1-0.6
        c0.3-0.3-0.1-0.6-0.3-0.7c0,0.3-0.1,0.5-0.1,0.8c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0.5-0.7,0.7-1.1,1c-0.1,0-0.3,0-0.3,0.1
        c0.2-0.3,0.7-0.5,0.6-0.9c-0.1-0.8,0.4-1.5,0.6-2.3c0.3-0.3,0.3-0.8,0.5-1.1c0.2-0.5,0.1-1.1,0.6-1.4c-0.1-0.3-0.1-0.6,0.2-0.7
        c0-0.3-0.1-0.6,0.3-0.5c0-0.1-0.1-0.3-0.1-0.4c0.1,0,0.2,0,0.3,0c0.1-0.4,0.1-0.8,0.5-1l0-0.1l-0.5,0.1c0.2-0.3,0.6-0.3,0.6-0.7
        l0.3,0.2c-0.2,0.2-0.3,0.4-0.2,0.6c0.1-0.1,0.3-0.3,0.4-0.4c-0.2-0.2-0.5-0.5-0.3-0.8l-0.3,0.1c0.1-0.1,0.2-0.3,0.3-0.4
        c0.1-0.2,0.2-0.4,0.4-0.6c0-0.2,0-0.4,0-0.5c-0.1,0.3-0.1,0.6-0.3,0.9c-0.1,0.2-0.3,0.3-0.5,0.1c-0.2,0-0.3,0-0.4,0.1
        c0.1,0,0.4,0,0.5,0c-0.1,0.1-0.2,0.3-0.3,0.5c-0.3,0-0.1,0.3,0,0.4c0.2,0.2-0.1,0.4-0.3,0.5c0,0.2,0,0.4-0.1,0.7
        c-0.2,0.1-0.4,0.3-0.4,0.5c0,0.4-0.4,0.6-0.5,1c0,0.2-0.2,0.3-0.3,0.4c0.1,0.4-0.3,0.6-0.3,0.9c-0.1-0.1-0.2-0.2-0.2-0.2
        c0,0.1,0.1,0.2,0.1,0.3l-0.3,0c0.4,0.1-0.1,0.4-0.1,0.7c-0.1,0.3-0.4,0.5-0.6,0.7c-0.1,0.2-0.3,0.3-0.4,0.5c-0.1,0-0.2,0-0.2,0
        c0.5-0.8,0.7-1.7,0.9-2.6c0.1-0.3,0.3-0.5,0.3-0.8c0.3-0.3,0.3-0.8,0.6-1.2c0.2-0.3,0.2-0.9,0.7-1c-0.4-0.3,0.1-0.6,0.2-0.9
        c0.2-0.5,0.8-0.9,0.7-1.4c0.2-0.1,0.4-0.4,0.1-0.6c0.1,0,0.4,0.1,0.5,0.1c-0.1-0.2-0.2-0.3-0.3-0.5l0.2,0.1c0-0.1,0-0.3,0-0.4
        c-0.1-0.2-0.2-0.4-0.2-0.6c0.2,0.1,0.3,0.2,0.5,0.3c0,0.2,0,0.3,0.1,0.5c0,0,0.1-0.1,0.1-0.1c-0.1-0.2-0.2-0.4-0.2-0.6
        c-0.1,0-0.2,0-0.3,0c-0.1-0.2-0.2-0.3-0.3-0.5c0.3-0.1,0.1-0.4,0-0.6c0,0.1,0.1,0.3,0.1,0.4c-0.2,0.1-0.2,0.2-0.2,0.4
        c0,0-0.1,0.1-0.2,0.2l0.4,0c-0.1,0.2-0.7,0.4-0.4,0.7c0.1,0,0.5-0.2,0.3,0.1c-0.1,0-0.2,0-0.2,0.1c0,0-0.1,0.1-0.1,0.2l-0.1,0
        c0,0.4-0.2,0.7-0.3,1l-0.1,0c0,0.1,0,0.3,0,0.4c-0.1,0-0.2,0.1-0.3,0.1c0.2,0.6-0.6,0.8-0.6,1.3c-0.4,0.4-0.8,0.9-1.2,1.3
        c0.8-1.8,1.6-3.5,2.3-5.3c0.3-1,0.7-2,0.9-3.1c0,0,0.1-0.1,0.2-0.2c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.1,0.3-0.3,0.4-0.4
        c-0.1-0.1-0.1-0.2-0.2-0.4c0,0.2-0.1,0.4-0.2,0.6c-0.3,0.3-0.3,0.6-0.4,1c-0.3,0.6-0.6,1.3-1.1,1.8c0,0,0-0.1,0-0.1
        c0.2-0.5,0.4-1.1,0.6-1.6c0.3-0.5,0.2-1.1,0.5-1.6c0.1-0.8,0.4-1.6,0.4-2.4l0.2,0.1c0-0.1,0-0.3,0-0.5c0-0.1,0.1-0.3,0.1-0.4
        c-0.1,0-0.2,0-0.3,0c0.1-0.1,0.1-0.3,0.2-0.4c0.1,0.1,0.2,0.2,0.2,0.2c0-0.1-0.1-0.4-0.1-0.5l0.2,0c0-0.2,0-0.4-0.1-0.6
        c0,0,0.1-0.1,0.1-0.2c0-0.8,0-1.7,0-2.5c0-0.2-0.1-0.3-0.1-0.5c0.1,0,0.2,0,0.3,0c0.1,0.2,0.1,0.5,0.1,0.7c0.1,0,0.2-0.1,0.3-0.1
        l-0.2,0c-0.1-0.4-0.2-0.7-0.4-1c0.1,0,0.3,0,0.3,0c0,0-0.1-0.1-0.2-0.1c0-0.4,0-0.7,0-1.1c0.3-0.1-0.1-0.5-0.1-0.7l0.2,0
        c-0.1-0.4-0.1-0.8-0.1-1.2c0.1-2,0-4,0-6.1l-0.1,0c0-0.2,0-0.5,0-0.7l0.2,0c-0.1-0.4-0.2-0.7-0.1-1.1c-0.1-0.3-0.3-0.6-0.3-0.9
        c-0.4-0.2-0.6-0.6-0.4-1c-0.6-0.4-0.8-1.2-0.7-1.8c-0.2-0.1-0.5-0.1-0.6-0.4c-0.3-0.5-0.5-1.1-0.8-1.6c-0.2-0.5-0.4-1.1-0.8-1.5
        c-0.3-0.7-0.8-1.3-0.9-2.1c-0.3-0.2-0.4-0.4-0.4-0.7c-0.1-0.1-0.4-0.2-0.4-0.4c0-0.6-0.7-0.9-0.7-1.4c0-0.6-0.7-0.9-0.8-1.5
        c-0.1-0.6-0.6-0.9-0.7-1.5c-0.4-0.5-0.7-1.1-0.9-1.7c-0.2-0.2-0.5-0.4-0.3-0.8c-0.4,0-0.1-0.5-0.5-0.6c-0.4-0.4-0.6-1-0.9-1.5
        c-0.5-0.6-0.9-1.2-1.3-1.8c-0.2-0.2-0.4-0.5-0.6-0.8c0.6,0.4,1,1,1.6,1.5c0.3,0.2,0.6,0.3,0.5,0.7c0.1,0,0.4,0,0.5,0
        c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0,0.3,0,0.4-0.1c0,0.1,0,0.3,0,0.3c0.1,0,0.2,0,0.3-0.1l0,0.2c0.1,0,0.3-0.1,0.4-0.1
        c0,0.1,0,0.3,0.1,0.4c0.1,0,0.2-0.1,0.2-0.1c0,0.4,0.3,0.6,0.6,0.7l0-0.2c0,0,0.1,0,0.1,0l-0.2,0.1c0,0.1,0.1,0.3,0.1,0.4
        c-0.2,0-0.4-0.1-0.5-0.2c0.1,0.2,0.4,0.3,0.6,0.3c0.1,0.3,0.5,0,0.6,0.3c-1.2-0.4-0.2,0.1,0,0.4c0.2,0.2,0.4,0.8,0.6,0.3
        c0,0,0.1,0,0.1,0c0,0.1-0.1,0.2-0.2,0.2c0.3,0.2,0.3,0.5,0.4,0.8l0.2,0c0-0.1,0-0.2,0-0.2c0.1,0.1,0.3,0.4,0.3,0.5l-0.4-0.1
        c0.1,0.2,0.3,0.1,0.4,0.1c0.2,0.4,0.6,0.6,0.8,1.1c0,0.2,0.3,0.3,0.2,0.4c-0.3,0-0.5-0.3-0.7-0.6c0,0.1,0,0.3,0,0.4
        c0.3,0.2,0.5,0.5,0.9,0.8l0,0c0-0.1,0-0.2,0.1-0.2c0.5,0.3,0.7,0.9,0.9,1.4c0.1,0,0.2,0,0.2-0.1c0.1,0.2,0.2,0.3,0.4,0.5
        c0,0.3,0,0.5-0.1,0.8c0,0,0.1-0.1,0.2-0.2c0.1,0.2,0.3,0.3,0.6,0.3c0.3,0.3,0,0.6-0.1,0.9c0.5-0.2,0.3-0.8,0.3-1.2
        c0.1,0.3,0.2,0.5,0.4,0.8c-0.1,0-0.2,0.1-0.2,0.1c0,0.3,0.5,0.5,0.3,0.8c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0.1,0.2,0.3,0.3,0.5
        c-0.2-0.1-0.4-0.3-0.7-0.2c0.2,0.1,0.3,0.3,0.5,0.4c-0.4-0.1-0.4,0.2-0.5,0.5c-0.1-0.1-0.3-0.2-0.3-0.2c0.1,0.1,0.2,0.2,0.3,0.3
        l0.1-0.2c0.1,0.1,0.2,0.2,0.3,0.3c-0.1-0.2-0.1-0.3-0.2-0.5c0.3,0.1,0.5,0.1,0.5-0.2l0,0c0,0.4,0,0.7-0.2,1c0.1,0,0.3,0.1,0.3,0.1
        c0.2,0.1,0.5,0.2,0.8,0.1c0,0.1,0,0.3,0,0.4c0.3,0,0.5,0.2,0.4,0.5c-0.2-0.2-0.5-0.4-0.5-0.8c-0.4-0.1-0.9,0.3-1.2-0.1
        c0,0-0.1,0.1-0.2,0.2c0.2,0.2,0.7,0.3,0.7,0.7l-0.2,0.1c0.1,0,0.2-0.1,0.3-0.1c-0.1-0.2-0.2-0.4,0-0.5c0.1,0.1,0.3,0.2,0.4,0.3
        c-0.1,0.1-0.2,0.2-0.3,0.3c0.3,0.1,0.6,0,0.8,0.4c-0.1,0-0.4,0-0.5,0l-0.1,0.2c-0.2-0.1-0.3-0.3-0.4-0.4c0.1,0.2,0.2,0.3,0.3,0.5
        c0.3,0,0.6,0,0.9-0.1c0,0.1,0.1,0.3,0.1,0.4c0.1-0.1,0.2-0.3,0.3-0.4c0,0.1,0,0.3-0.1,0.5l-0.2,0c0.1,0.3,0.3,0.4,0.7,0.3
        c-0.1,0.2-0.1,0.3-0.2,0.5c0.2,0.1,0.3,0.1,0.5,0.2c-0.1,0-0.3,0-0.4,0c-0.1-0.1-0.3-0.2-0.4-0.2c0,0.2-0.1,0.4-0.2,0.6
        c0,0.3,0,0.5,0.1,0.8c0-0.1,0.1-0.2,0.1-0.2l0.2-0.1c0-0.1,0-0.2-0.1-0.2l-0.2,0c0-0.1,0.1-0.3,0.1-0.4l0.2-0.1
        c0,0.1,0.1,0.4,0.1,0.5c0-0.1,0.1-0.2,0.2-0.3c0.4,0.1,0.1,0.5-0.1,0.6c0,0.1,0,0.3,0,0.5c-0.3,0.4,0.6,0.6,0.2,0.1
        c0.2-0.3,0.4-0.6,0.2-0.9c0,0,0.1-0.1,0.2-0.1c0,0.3-0.1,0.6-0.1,0.9c0.1,0,0.2,0.1,0.3,0.1c-0.1,0.1-0.2,0.2-0.3,0.3
        c0.1,0,0.3,0.1,0.3,0.1l0,0.2c-0.2-0.1-0.4-0.1-0.7,0c0,0.1,0,0.2,0,0.3c0.1,0.1,0.2,0.3,0.3,0.4c0.4-0.1,0.5,0.2,0.6,0.5
        c-0.1,0.2-0.3,0.3-0.3,0.6c0.2-0.1,0.5-0.3,0.6-0.5c-0.1-0.1-0.2-0.2-0.3-0.3c0.1,0.1,0.5,0.1,0.6,0.3c-0.3,0.1-0.3,0.5,0.1,0.4
        c-0.2,0.3-0.4,0.5-0.6,0.8c0.1,0,0.3,0,0.5,0.1c0.1,0.2,0.1,0.5-0.1,0.7c-0.1-0.1-0.2-0.2-0.2-0.4c-0.1,0.2-0.1,0.4-0.3,0.5
        c0.2,0.1,0.4,0.1,0.7,0.2c0.1-0.2,0.1-0.5,0.3-0.6c0-0.1-0.1-0.3-0.2-0.4c0.3,0.1,0.6,0.6,0.2,0.8c0,0.2-0.1,0.4-0.2,0.6
        c0.2,0.1,0.5,0.1,0.6,0.4c-0.1,0-0.4,0.1-0.5,0.1l0-0.3c-0.3,0-0.2,0.3-0.2,0.5c0.2-0.4,0.4,0.1,0.6,0.3c-0.1,0-0.3,0-0.4,0
        c0.1,0.2,0.3,0.2,0.5,0.2c0,0.1,0,0.3,0,0.4c0.3,0.2,0.4,0.4,0.3,0.8c-0.1-0.1-0.3-0.2-0.4-0.3c0.1,0.2,0.3,0.3,0.4,0.5
        c0-0.4,0.3-0.8-0.1-1.1c0.2-0.3,0.1-0.6,0.2-1c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.4-0.2-0.5-0.4-0.7l0.3,0c0-0.1,0-0.3,0-0.5
        c0.1,0.1,0.1,0.2,0.1,0.4l-0.1,0c0.1,0.2,0.1,0.3,0.2,0.5c0.1,0.1,0.3,0.2,0.4,0.3c0,0.3-0.2,0.7,0.2,0.9c-0.2,0.1-0.3,0.2-0.3,0.4
        c0.1,0,0.3,0,0.4,0c0.2,0.2,0.4,0.4,0.4,0.7c-0.2-0.2-0.2-0.6-0.5-0.6c-0.1,0.2-0.1,0.3-0.1,0.5c0.2,0.1,0.4,0.2,0.6,0.4
        c0,0.1,0,0.3,0,0.4l0.3-0.1c0,0,0,0.1,0,0.1l-0.2,0l0.1,0.3c-0.1,0-0.2,0-0.3,0c0-0.3-0.1-0.5-0.4-0.6c0,0.1,0,0.3,0,0.4
        c-0.3,0.1-0.3,0.3,0,0.4c-0.1,0-0.2,0.1-0.2,0.2c-0.2,0.5,0.5,0.4,0.8,0.5l0,0.2c-0.1,0-0.2-0.1-0.3-0.1c0,0.2-0.1,0.4-0.1,0.6
        c0.1-0.1,0.2-0.2,0.3-0.3c0,0.2-0.3,0.6,0,0.8c0-0.1,0-0.3,0.1-0.5l0.2,0.3c0.1-0.2,0.2-0.4,0.3-0.5c0.1,0,0.2,0,0.2,0.1
        c-0.1,0.1-0.2,0.3-0.3,0.3c0.1,0,0.3,0,0.4,0c-0.1,0.4,0,1.3-0.6,1.3c0.1,0.1,0.3,0.2,0.4,0.3c-0.2,0.2-0.2,0.3,0.1,0.4
        c0,0.1-0.1,0.3-0.1,0.3c-0.2,0-0.3-0.1-0.4-0.3c-0.1,0.1-0.1,0.2-0.2,0.3l0.3,0c0,0.2-0.1,0.4-0.1,0.5c0.1-0.1,0.3-0.4,0.4-0.6
        c0,0.4,0.1,0.8,0.2,1.3c0,0,0.1,0.1,0.2,0.2l0.2,0.1c0,0.1,0,0.2,0,0.3l-0.2,0l-0.1-0.2l-0.2,0c0.2,0.2,0.2,0.4,0.1,0.7
        c0.1-0.1,0.2-0.1,0.4-0.1c0.1-0.1,0.2-0.3,0.2-0.3c0,0,0-0.1,0-0.2l0.1,0c0,0.2-0.1,0.3-0.1,0.5c-0.2,0.1-0.3,0.3-0.4,0.5
        c0.3-0.3,0.6,0.1,0.7,0.3c-0.1,0-0.2-0.2-0.3-0.2c-0.1,0-0.3,0.1-0.4,0.1c0.1,0.1,0.3,0.2,0.5,0.2c-0.1,0.2-0.2,0.3-0.5,0.2
        c0.2,0.3,0.4,0.7,0.6,1c-0.1,0-0.3-0.1-0.4-0.2c0.2,0.2,0.3,0.4,0.5,0.6c-0.1,0.2-0.2,0.5,0.1,0.4c0,0.1,0,0.3,0,0.4
        c0.3-0.1,0.4,0.1,0.4,0.4c-0.2-0.1-0.3-0.4-0.5-0.4c0.1,0.4,0.3,0.7,0.6,0.8c0,0.2,0.1,0.3,0.1,0.5c-0.1,0-0.3-0.1-0.4-0.1
        c0,0.4,0.1,0.8,0.3,1.1c0.1,0.1,0.3,0.2,0.4,0.3c-0.1,0-0.3,0.1-0.4,0.2c0.3,0.1,0.2,0.4,0.2,0.6l-0.2,0c0,0.4,0.1,0.9,0.3,1.3
        l-0.3,0c0,0,0,0.1,0,0.1c0.3,0.1,0.4,0.4,0.2,0.7c0.1,0,0.2,0.1,0.3,0.2c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1-0.1-0.2-0.2-0.3-0.3
        c0.1,0.3,0.4,0.4,0.6,0.7c-0.1,0-0.3,0-0.4-0.1c0.1,0,0.3,0.1,0.4,0.2c-0.1,0.6,0,1.3,0,1.9c0.2,0.3,0.1,0.7,0.1,1.1l0.2,0
        c0,0,0,0.1,0,0.1l-0.3,0c0.1,0.1,0.2,0.2,0.2,0.3c0,0.3,0,0.6,0,0.9l0.1,0c0,0.2,0,0.5-0.1,0.7c0.2,0.6,0,1.2,0.2,1.7l-0.2,0
        c-0.1,0.4,0.2,0.8,0.1,1.1c0,0.1,0,0.4,0,0.5c0.1,0.3,0.1,0.6,0.1,0.9c0,0.4,0,0.9,0,1.4c0,0,0.1,0.1,0.2,0.1l-0.2,0
        c0,0.3,0.2,0.6,0.1,0.9c0.1,0.3,0.1,0.7,0.1,1c0,0-0.1,0-0.1,0c-0.1-0.5,0-1.1-0.2-1.6c0-0.4-0.1-0.8,0-1.3l-0.1,0
        c0-0.2,0-0.4,0-0.6l0.2,0.1c-0.2-0.4-0.3-0.9-0.4-1.4c0,0,0.1-0.1,0.2-0.2c-0.2-0.2-0.2-0.5-0.2-0.8c0-0.1,0-0.2,0.1-0.2
        c-0.2-0.3-0.2-0.7-0.2-1.1c-0.1-0.5-0.2-0.9-0.2-1.4c0,0,0.1,0,0.2,0c-0.1-0.2-0.4-0.4-0.3-0.7l-0.2,0c0-0.1,0.1-0.3,0.2-0.4
        c-0.1,0-0.2,0-0.3,0c0-0.1,0.1-0.2,0.2-0.2c-0.1-0.4-0.2-0.8-0.3-1.2c-0.1-0.4-0.1-0.8-0.2-1.1c0.1,0,0.2,0,0.2,0
        c-0.3-0.7-0.5-1.4-0.7-2.1l0.3,0.1c-0.4-0.4-0.4-0.9-0.6-1.3c-0.2-0.6-0.3-1.3-0.6-1.9c-0.2-0.5-0.5-1.1-0.6-1.6
        c-0.1-0.4-0.5-0.7-0.3-1c-0.4-0.4-0.4-1.1-0.7-1.5c-0.1-0.4-0.5-0.8-0.4-1.3c-0.2-0.4-0.5-0.8-0.6-1.3c-0.1-0.6-0.6-1-0.7-1.6
        c-0.2-0.7-0.3-1.5-0.7-2.1c-0.5-1.1-0.7-2.3-1.2-3.4c0-0.2-0.1-0.4-0.2-0.6c-0.2-0.2-0.2-0.6-0.5-0.8c0,0.6,0.2,1.3,0.2,1.9
        c0,0.9,0.1,1.7,0.2,2.6c-0.1,0.2-0.1,0.5,0,0.7c-0.2,0.4,0.3,0.9-0.1,1.3c0.2,0.5,0.2,1.2,0.2,1.8c0,0.5,0.1,1.1,0,1.6
        c0.2,0.7,0.2,1.5,0.2,2.3c0,0.3,0.3,0.5,0.1,0.8c0.1,0.2,0,0.5,0.1,0.8c0.1,0.2,0.1,0.4,0,0.6c0.1,0.1,0.2,0.2,0.2,0.3
        c0.1,0.6,0,1.3,0.2,1.9c0.2,1.1,0.2,2.1,0.5,3.2c0,0.8,0.3,1.6,0.3,2.3c0.1,0.2,0.1,0.3,0.2,0.5c0,0.1,0.1,0.2,0.1,0.3
        c-0.1-0.1-0.3-0.2-0.4-0.3c0.1,0.3,0.1,0.6,0.2,0.9c0.5,0.5,0.2,1.4,0.8,1.8c0.3,0.2-0.4,0.5-0.3,0.1c0,0-0.1,0-0.1,0
        c0.1,0.2,0.1,0.3,0.2,0.5c0.1,0,0.2-0.1,0.2-0.1c0.1,0.3,0.1,0.7,0.2,1.1c-0.1,0-0.2,0-0.3,0c0.1,0.1,0.2,0.1,0.4,0.1
        c0,0.1,0,0.2-0.1,0.3c0.3,0.2,0.5,0.5,0.6,0.9c-0.3-0.2-0.6-0.5-0.9-0.6c-0.3-0.3-0.5-0.6-0.8-0.8c0,0.2,0,0.4-0.1,0.5l0.2-0.2
        c0,0.2-0.1,0.5-0.1,0.6c0.4,0,0.3,0.6,0.3,0.9c0.2,0.2,0.3,0.4,0.4,0.6c0-0.1,0.1-0.2,0.2-0.3c0.2,0.3,0,0.5-0.1,0.8
        c0.3,0,0.4,0.2,0.3,0.4c0.5-0.1,0.2,0.4,0.5,0.5c0.1,0.2,0.2,0.4,0.3,0.6c-0.1,0-0.3,0-0.5,0c0.2,0,0.3,0.1,0.5,0.1l0,0.1l0.2,0.1
        l0.3,0.3c-0.3,0-0.6,0.1-0.9-0.2l-0.1,0.1c0.1,0.2,0.2,0.3,0.3,0.5c0.2,0.2,0.4,0.4,0.3,0.7c0.4,0,0.5,0.5,0.4,0.8l-0.2-0.2
        c0,0.1,0,0.3,0.1,0.4c0.1-0.1,0.2-0.2,0.3-0.3c0,0.2,0.1,0.5,0.1,0.7l0.1-0.1c0,0.4,0.2,0.6,0.4,0.9c-0.6-0.2-1-0.8-1.7-0.9
        c-0.2-0.1-0.3-0.3-0.4-0.5c-0.4,0.1,0.1,0.4-0.1,0.7c0.2,0,0.4,0.2,0.5,0.4c-0.2-0.1-0.4-0.2-0.5-0.2c0,0.2,0.1,0.3,0.2,0.4
        c0.2,0.1,0.4,0.2,0.5,0.3c-0.2-0.1-0.4-0.1-0.5-0.2c0.1,0.2,0.4,0.5,0.4,0.7l0.2,0l0,0.3c0.1-0.1,0.3-0.2,0.5-0.2
        c-0.2,0.2-0.5,0.3-0.4,0.7c0.1,0,0.3-0.1,0.3-0.1c-0.3,0.2,0.3,1.4,0.1,0.4c0.2,0.2,0.6,0.4,0.4,0.8c-0.1-0.1-0.2-0.2-0.3-0.3
        c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0,0.3,0,0.3,0c0,0.1,0,0.2,0,0.3l0.1,0.1l0.1,0.1c0-0.1,0.1-0.2,0.1-0.2c0.1,0,0.3,0.1,0.4,0.1
        c-0.1,0.2-0.3,0.3-0.4,0.5c-0.2,0-0.3-0.1-0.5-0.1c0,0.1,0,0.3,0,0.4c0.1,0,0.3,0,0.4,0.1c-0.1,0.2-0.1,0.4-0.1,0.7l0.3,0l0-0.2
        l0.2,0c-0.2,0.2-0.2,0.4,0,0.5c0,0.1,0,0.4-0.1,0.5l0.2-0.1c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1-0.1,0.2-0.1,0.3l0.3-0.1
        c0,0.3,0,0.5,0,0.8c0.1,0,0.2-0.1,0.3-0.1c0.1,0.2,0.1,0.5,0,0.8c-0.3-0.1-0.6-0.1-0.8-0.4c-0.4,0.1-0.5-0.4-0.8-0.6
        c0.4,0.4,0.4,1,0.7,1.4c0-0.2,0-0.3-0.1-0.4c0-0.1,0-0.4-0.1-0.5c0.5,0.2,0.4,0.8,0.7,1.2c0.2,0.3-0.3,0.1-0.4,0.3
        c0.2,0,0.3,0,0.5,0c0.1,0.3,0.2,0.6,0.5,0.8c0,0.1,0,0.3,0,0.4l0.1,0c0.1,0.1,0.2,0.2,0.2,0.3c0,0-0.1,0.1-0.2,0.2
        c-0.5-0.1-0.9-0.4-1.4-0.7c-0.5-0.7-1.2-1.2-1.6-2c0.1-0.4-0.4-0.7-0.4-1.1c-0.6-1.2-1.1-2.5-1.1-3.9c0,0-0.2,0-0.2-0.1
        c0,0.1,0,0.3,0,0.4l0.2,0c0,0.2-0.1,0.5-0.2,0.7c0.2,0.3,0.3,0.6,0.4,0.9c-0.1,0.1-0.2,0.3-0.3,0.4c0.1,0.2,0.2,0.4,0.3,0.7
        c-0.2,0.2-0.2,0.4,0,0.5l0.2,0c0,0.1-0.1,0.3-0.1,0.4l0.2-0.2c0.2,0.3,0.1,0.6,0,0.9c0.1,0.2,0.1,0.4,0,0.6l0.1,0
        c0.4-0.2,0.4,0.4,0.6,0.6c0.2,0,0.3,0,0.5,0.1c0.1,0.3,0.4,0.6,0.7,0.8c-0.1,0-0.2,0.1-0.3,0.1c0.1,0.3,0.4,0,0.6,0
        c-0.2,0.4,0.3,0.7,0.5,1c-0.1,0-0.4,0-0.5,0c0.1,0.1,0.3,0.1,0.5,0.2c0,0.3,0.3,0.4,0.4,0.6c0.2,0.3,0.4,0.5,0.5,0.8
        c0.1,0,0.2-0.1,0.3-0.1c0,0.1-0.5,0.4-0.1,0.3c0,0.1,0.1,0.2,0.2,0.3l0.1,0c0.1,0.3,0.2,0.5,0.4,0.8c-0.9-0.3-1.6-1-2.5-1.4
        c-0.1,0.2-0.1,0.3-0.2,0.5c0.1-0.1,0.3-0.1,0.4-0.2c0.1,0.4,0.4,0.8,0.7,1.1c0.2,0.5,0.5,0.9,0.8,1.4c0.1,0.2,0.2,0.3,0.3,0.5l0.1,0
        c0.1,0.7,0.7,1.1,1.2,1.4c-0.8-0.2-1.4-0.8-2-1.3c-0.3-0.3-0.7-0.4-0.9-0.7c-0.3-0.2-0.5-0.4-0.7-0.7c-0.3-0.2-0.6-0.5-0.8-0.8
        c-0.1-0.3-0.3-0.6-0.4-0.8l0,0.2c0.1,0.1,0.2,0.3,0.3,0.4l-0.3-0.1c0.1,0.2,0.1,0.4,0.4,0.3c0.1,0.2,0.1,0.4,0.2,0.6
        c0.2,0.3,0.4,0.7,0.6,1c-0.2-0.1-0.3-0.1-0.5-0.2c0.1,0.2,0.2,0.4,0.3,0.6l0-0.3c0.1,0,0.2,0.1,0.3,0.1c0,0.1,0,0.4,0,0.6
        c-0.1,0-0.2,0.1-0.2,0.1c0.1,0,0.4,0,0.5,0c-0.1,0.1-0.2,0.3-0.3,0.3c0.2,0,0.4-0.1,0.5-0.1c0,0.4,0.4,0.6,0.5,0.9
        c0.1,0.1,0.2,0.3,0.2,0.5l0.1-0.1c0,0.1,0.1,0.2,0.1,0.2c0.5,0.3,0.9,0.7,1.5,0.8c0,0,0,0.1,0,0.1c0,0-0.1-0.1-0.1-0.1
        c-0.7-0.1-1.3-0.5-2-0.8c0,0.2,0,0.3-0.1,0.4c0.2,0.1,0.4,0.2,0.5,0.4c0.2,0.1,0.2,0.3,0.3,0.5c0.2,0.4,0.6,0.6,0.6,1
        c0.4-0.1,0.5-0.6,0.9-0.8c0.1-0.1,0.5,0,0.4-0.3c-0.2-0.3-0.4,0.2-0.5,0c0.2-0.1,0.4-0.2,0.4-0.5c0.1,0.1,0.1,0.1,0.2,0.2
        c0-0.3,0.3-0.5,0.5-0.6c0-0.2-0.1-0.4-0.1-0.6l0.2,0.1c0-0.1,0-0.3-0.1-0.4l0.2,0c0-0.5,0.1-1-0.2-1.4c0.2-0.1,0.3-0.1,0.5-0.2
        l0-0.1c0.2,0,0.5-0.1,0.7-0.1c0.1-0.2,0.1-0.4,0.2-0.5c-0.2-0.2-0.2-0.7,0.2-0.4c-0.1-0.5,0-0.9,0.3-1.3c-0.1-0.5,0.4-0.8,0.3-1.2
        c0.3-0.5,0.3-1.1,0.6-1.6c-0.2-0.5,0.2-0.9,0.1-1.4c0.3-0.3,0.3-0.7,0.2-1c0.1-0.1,0.2-0.3,0.3-0.4c-0.1-0.2-0.2-0.3-0.1-0.5
        c0.3-0.7,0.2-1.6,0.2-2.3c0.1-0.7-0.1-1.4,0.1-2c-0.1-0.2-0.1-0.4,0.1-0.6c-0.2-0.5,0-1.1-0.1-1.6c0.1-0.8,0.1-1.5,0.2-2.3
        c-0.1-0.4-0.2-1,0.1-1.4c-0.1-0.7-0.3-1.4-0.6-2.1c-0.3-0.5-0.3-1.1-0.6-1.5c0-0.1,0-0.2-0.1-0.3c0.3,0.3,0.6,0.7,0.8,1.1
        c0.1,0.2,0.3,0.4,0.5,0.6c0.1,0.2,0.2,0.3,0.2,0.5c0,0,0.1,0,0.2,0c0.1,0.5,0.4,0.8,0.7,1.2c0.3,0.1,0.2,0.4,0.3,0.6
        c0.2,0.3,0.2,0.7,0.6,0.8c0.3,0.1,0.4,0.4,0.5,0.7c-0.1,0.1-0.2,0.3-0.2,0.4c0.3,0.1,0.4-0.2,0.5-0.4c0,0.1,0.1,0.2,0.1,0.3
        c-0.4,0-0.2,0.4-0.3,0.7c-0.2,0.1-0.3,0.2-0.4,0.4c0.1,0,0.2,0,0.2,0c0-0.1,0.1-0.2,0.1-0.3l0.2-0.1c0,0.2,0.1,0.5,0,0.7l0.3,0.3
        c-0.2-0.1-0.5-0.2-0.7-0.2c0,0.1-0.1,0.1-0.1,0.2c0.1,0,0.3,0,0.3,0.1c-0.2,0.5,0.5,0.6,0.5,1.1c-0.2-0.1-0.4-0.2-0.6-0.4
        c0,0.2,0.1,0.3,0.3,0.4c-0.1,0.2-0.1,0.5,0,0.7c0.3,0.1,0.6,0.2,0.7,0.5c-0.2-0.1-0.4-0.2-0.5-0.3c-0.3,0.1-0.3,0.3-0.1,0.6
        c-0.1,0-0.3-0.1-0.5-0.2c0.2,0.3,0.6,0.5,0.8,0.9c-0.1,0.3-0.4-0.4-0.7-0.1l0.1-0.2c0,0-0.1,0-0.1,0c0,0.1,0,0.3,0,0.4
        c0.3-0.2,0.4,0,0.5,0.3c-0.2,0.1-0.2,0.4-0.5,0.5c0,0.1,0.1,0.2,0.1,0.3l-0.3,0c0,0,0,0.1,0,0.1c0.1,0,0.3,0,0.3,0
        c0,0.3,0.3,0.4,0.5,0.6l-0.1,0.3c0.1,0,0.3,0,0.4,0c-0.1,0.1-0.3,0.1-0.2,0.3c0.1,0.2-0.1,0.3-0.2,0.4c-0.1,0.2-0.1,0.3-0.2,0.5
        c-0.1-0.1-0.2-0.1-0.4-0.2c0.3-0.1,0.7-0.3,0.5-0.7c-0.1-0.2-0.4,0.1-0.5,0.2c0.1,0.1,0.2,0.2,0.2,0.2c-0.1,0.1-0.3,0.2-0.4,0.3
        c0.1,0.1,0.2,0.3,0.2,0.4l-0.2,0c0,0.1,0.1,0.2,0.1,0.3c0.2-0.1,0.3-0.2,0.5-0.3c0,0.1,0,0.2,0,0.3c-0.1,0-0.3,0-0.4,0
        c-0.1,0-0.3,0.1-0.3,0.1c0,0.3,0.4,0.8-0.3,0.7c0-0.1,0.1-0.3,0.1-0.4c0,0-0.1,0-0.1,0c0,0.2-0.1,0.3-0.1,0.5c0.1,0,0.2,0,0.3,0
        c-0.2,0.3-0.1,0.7-0.1,1c0,0-0.1,0.1-0.2,0.1l0.4,0c-0.4,0.2-0.4,0.6-0.3,0.9c-0.2,0.3-0.2,0.7-0.6,0.8c0.1,0,0.3,0.1,0.3,0.2
        c-0.1,0.2-0.2,0.5-0.4,0.6c0.4-0.3,0.4,0.2,0.5,0.5c-0.1-0.1-0.3-0.2-0.4-0.3c0,0.1,0,0.3,0,0.4l-0.1-0.1c-0.2,0.3-0.3,0.7-0.1,1.1
        l-0.2-0.1c-0.1,0.2-0.2,0.5,0,0.7l-0.3,0.1c0.1,0.1,0.3,0.1,0.5,0.2c-0.1,0.2-0.2,0.3-0.2,0.5c-0.1-0.2-0.1-0.4-0.3-0.5
        c0,0.1-0.1,0.3-0.1,0.4c0.1,0,0.3,0.1,0.4,0.1c-0.1,0.2-0.2,0.5-0.2,0.7c-0.1,0-0.3-0.1-0.4-0.1c0.1-0.2,0.2-0.4,0.2-0.6
        c-0.2,0.2-0.3,0.4-0.4,0.7c0.1,0.1,0.3,0.3,0.4,0.4c-0.2-0.1-0.4,0-0.5,0.2c-0.2,0.1-0.3,0.3-0.3,0.6c0.2-0.2,0.4-0.3,0.6-0.5
        c-0.1,0.3-0.4,0.5-0.6,0.7c0.2,0.2,0.1,0.4-0.1,0.5c0-0.1,0-0.4,0-0.5c-0.3,0.2-0.3,0.5-0.2,0.7c-0.1,0.2-0.2,0.3-0.3,0.5
        c-0.1,0-0.3,0.1-0.4,0.2c0.1,0,0.3,0.1,0.4,0.2c-0.4,0.2-0.5,0.5-0.5,0.9c-0.2,0.1-0.3,0.2-0.5,0.3c0,0.1,0,0.4,0.1,0.6
        c-0.1,0-0.2,0.1-0.3,0.1l-0.1,0c0,0.2,0,0.3,0.1,0.5c-0.2,0-0.5,0.1-0.5,0.4c0.1,0,0.3-0.1,0.4-0.1c-0.1,0.1-0.3,0.3-0.4,0.3
        c0,0.1,0.1,0.3,0.1,0.3c0-0.1-0.1-0.2-0.2-0.3c-0.1,0.4-0.6,0.8-0.4,1.2c-0.1,0-0.2,0-0.2,0c-0.2,0.3-0.4,0.6-0.7,0.8
        c-0.3,0.7-0.9,1.2-1.2,1.9l0.2,0c0,0-0.1,0.1-0.1,0.1c-0.1-0.1-0.2-0.2-0.2-0.3c0,0.2-0.2,0.4-0.4,0.5c0,0.1,0,0.3,0,0.4
        c-0.2,0.1-0.3,0.2-0.5,0.4c0.1,0,0.3-0.1,0.4-0.1l-0.1,0.3c0.1,0,0.2-0.1,0.3-0.2c0,0.2-0.1,0.3-0.3,0.4c0,0,0.1,0.1,0.2,0.2
        c0-0.1,0.1-0.2,0.2-0.3c0.3,0.3,0.4,0.6,0.5,1l0.1,0c0.1,0.3,0.3,0.4,0.5,0.6c-0.9-0.1-1.6-0.7-2.4-0.9c-0.2,0.2-0.4,0.4-0.6,0.7
        c0.2,0,0.4,0.1,0.6,0.1c-0.2,0-0.4,0-0.6,0c-0.1,0.2-0.3,0.4-0.4,0.6c0.2,0.1,0.4,0.1,0.5,0.2c-0.1,0.1-0.2,0.3-0.3,0.4
        c0.1,0,0.3-0.1,0.4-0.2c0,0.2-0.1,0.5-0.1,0.6c0.1,0,0.2-0.1,0.3-0.1l-0.1,0.2l0.2,0l0.1,0.4c-0.4-0.3-1-0.1-1.3-0.6
        c0,0.1-0.1,0.3-0.1,0.5c-0.1-0.1-0.2-0.3-0.2-0.4c-0.1,0-0.2,0-0.2,0c0.1,0.1,0.2,0.3,0.3,0.4c0.2-0.1,0.4,0,0.6,0.1
        c0.2,0.2,0.2,0.4,0.1,0.6c0.1,0,0.2-0.1,0.3-0.2c0,0.2,0.1,0.3,0.2,0.5c-0.1,0-0.3,0-0.3,0c0.1,0.3,0.3,0.2,0.5,0
        c0,0.1,0,0.3,0.1,0.4l0.1,0c0,0.1,0,0.4,0.1,0.5c0.6-0.7,1.4-1.3,1.8-2.1c0.1-0.4,0.6-0.5,0.8-0.9c0.2-0.4,0.7-0.5,0.8-0.9
        c0.5-0.4,0.8-1,1.4-1.3c0.3-0.6,0.7-1.1,1.2-1.5c0.4-0.7,0.7-1.5,1.2-2.3c0.4-0.6,1-1.1,1.2-1.8c0.1-0.5,0.7-0.8,0.6-1.3
        c0.3-0.4,0.4-0.8,0.8-1.1c0.1-0.3,0.2-0.6,0.3-0.9c0.2-0.3,0.4-0.5,0.4-0.8c0.4-0.5,0.5-1.2,0.7-1.7c0.4-0.3,0.5-0.7,0.4-1.2
        c0.1-0.1,0.3-0.3,0.4-0.4c0-0.3-0.2-0.6,0.1-0.8c0.4-0.3,0.2-0.7,0.2-1.1c0.1,0,0.2-0.1,0.3-0.2c-0.2-0.3,0.1-0.5,0.1-0.8
        c0-0.3,0.2-0.6,0.3-0.9c0-0.3,0-0.6,0.1-0.8c0.1-0.4,0.6-0.6,0.3-1c0.1,0,0.2,0,0.2,0c-0.4-0.4,0.1-0.9-0.1-1.4
        c0.2-0.1,0.3-0.3,0.4-0.5l-0.4,0.1c0.1-0.1,0.2-0.2,0.3-0.2c-0.1-0.3,0-0.7,0.1-1c0.2-0.5,0.2-1.1,0.4-1.7c-0.1-0.2-0.2-0.5-0.3-0.7
        l0.3,0c0-0.1,0-0.4-0.1-0.6c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.1c0.1,0,0.2,0,0.3,0c0.2-0.5-0.2-1,0.1-1.4c0.3-0.5,0-1-0.2-1.5
        c0.1,0.1,0.2,0.2,0.3,0.3c-0.1-0.3-0.1-0.5-0.2-0.8c0,0-0.1-0.1-0.2-0.1c0.1,0,0.2,0,0.3,0c0-0.4,0.1-0.9,0.1-1.3
        c0.3,0,0.4-0.3,0.2-0.4c0-0.3,0-0.7-0.4-0.4l0-0.1c0.1,0,0.2,0,0.3,0c0-0.1-0.1-0.4-0.1-0.6c-0.1-0.1-0.2-0.2-0.3-0.2
        c0.1,0,0.3,0.1,0.3,0.1c0-0.4,0.1-0.7,0-1.1c0.1-0.2,0.2-0.4,0.2-0.5c0-0.1-0.1-0.2-0.2-0.2c0.1-0.2,0.1-0.3,0.2-0.5l-0.2,0
        c0-0.1,0-0.2,0-0.3c-0.1,0-0.2-0.1-0.3-0.1l0.2-0.1c0-0.3,0-0.7,0.1-1c-0.1-0.3-0.3-0.6-0.1-0.9c-0.1-0.1-0.2-0.1-0.3-0.2
        c0.2,0,0.3,0,0.5-0.1c-0.1-0.1-0.3-0.2-0.4-0.2c0.1,0,0.3,0.1,0.4,0.1c-0.1-0.3-0.2-0.6-0.3-0.8c0.1-0.2,0-0.4-0.2-0.6
        c0.1,0,0.3,0.1,0.4,0.1c-0.1-0.3,0-0.6,0-0.9c-0.1,0-0.2-0.1-0.3-0.1c0-0.3,0.1-0.7-0.1-0.9c0.3-0.2,0.1-0.5,0.1-0.8
        c-0.2-0.5,0-1-0.2-1.4c0.1-0.4,0.3-0.8,0.2-1.2c-0.2-0.4-0.4-0.8-0.6-1.2c0.2-0.3-0.1-0.7,0-1c-0.1,0-0.3,0.1-0.4,0.1
        c0.2-0.2,0.6-0.1,0.6-0.5c-0.1-0.2-0.2-0.3-0.3-0.5c0.1-0.3,0-0.6-0.1-0.9c0.1,0,0.2-0.1,0.3-0.2c-0.1-0.1-0.2-0.3-0.3-0.4
        c0-0.6-0.3-1.2-0.2-1.8c0.1-0.2,0.2-0.6-0.1-0.7c0,0.3-0.2,0.5-0.4,0.6c0.3-0.2,0.2-0.5,0.2-0.8l0.1-0.1c0.1-0.2-0.2-0.4-0.3-0.6
        c0.1,0.1,0.2,0.2,0.3,0.3c0.2-0.4,0.1-0.9-0.1-1.2c0.1-0.2,0.2-0.4,0.2-0.7c0.1-0.4-0.5-0.7-0.3-1.1c-0.3-0.3,0-0.8-0.2-1.2
        c-0.1,0-0.2,0-0.2,0c0,0,0-0.1,0-0.1c0.1,0,0.2,0,0.3,0c0.3-0.4-0.1-0.8,0-1.3c0-0.3,0.1-0.6-0.2-0.7c0-0.4,0.1-0.8,0.2-1.2
        c-0.3-0.1-0.4,0-0.4,0.3l-0.1,0c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.2-0.2,0.3-0.2c-0.1-0.3-0.1-0.6-0.4-0.6c0.1-0.2,0.3-0.4,0.5-0.5
        c-0.1-0.1-0.2-0.4-0.2-0.5c-0.2,0.1-0.3,0.1-0.5,0.2c0-0.1,0-0.2,0-0.2c0.1,0,0.3,0,0.4,0c-0.3-0.2-0.3-0.4-0.1-0.6
        c-0.1-0.2-0.3-0.2-0.4-0.3c0.3,0.1,0.4,0,0.5-0.3c-0.1,0-0.3,0-0.4,0c0.1-0.1,0.2-0.1,0.3-0.2c-0.1-0.1-0.1-0.3-0.2-0.4
        c0.1-0.2,0.1-0.4,0.2-0.7l-0.2,0.1c0.1-0.4-0.1-0.8,0.1-1.1c0.1-0.1,0.3-0.5-0.1-0.4c0-0.4-0.1-0.9,0.1-1.3c0.1-0.4,0.4-1,0-1.3
        c0-0.5,0.6-1.1,0.1-1.6c0-0.3,0-0.6,0-0.9c0.1,0,0.2-0.1,0.2-0.1l-0.2,0c0.1-0.4,0.3-0.8,0.1-1.1c0.1-0.4,0.4-0.8,0-1.2
        c0.1,0,0.2,0,0.3,0.1c0-0.5,0.2-1.1-0.1-1.5c0.1,0,0.3,0.1,0.4,0.1c-0.3-0.6,0.2-1.2,0.1-1.8c0.2-0.1,0.2-0.3,0.1-0.5
        c-0.1-0.4,0.1-0.8,0.2-1.2c0.1-0.3,0.1-0.6,0.2-0.8c0-0.4,0.4-0.8,0.1-1.2c0.2-0.2,0.3-0.4,0.5-0.6l-0.2,0c0-0.2,0-0.3,0.1-0.5
        c0.3-0.3,0.1-0.8,0.3-1.1c0.2-0.3,0.2-0.7,0.4-1c0.1-0.3,0.1-0.6,0-0.8c0.4-0.4,0.2-1,0.5-1.4c0.3-0.5,0.3-1,0.6-1.5
        c0-0.8,0.4-1.5,0.7-2.1c0-0.5,0.3-0.8,0.5-1.2c0.1-0.3,0.1-0.6,0.3-0.8c-0.1-0.4,0-0.7,0.3-0.9c0.3-0.6,0.6-1.2,1-1.7
        c0.1-0.4,0.4-0.6,0.7-0.7c0.1-0.3,0.1-0.5,0.2-0.8c0.3-0.4,0.5-0.9,0.9-1.2c0.4-0.3,0.3-0.9,0.8-1.2c0.2-0.7,0.8-1.2,1-1.9
        c0.1-0.4,0.5-0.8,0.7-1.1c0-0.2,0.1-0.4,0.2-0.5c0.3-0.5,0.5-1,0.8-1.4c0.2-0.2,0.1-0.6,0.3-0.9c0.5-0.6,0.7-1.4,1.2-2.1
        c0.3-0.4,0.3-0.9,0.6-1.2c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.3c0,0.2,0,0.3-0.1,0.5c-0.5,0.9-0.7,1.8-1.1,2.8l-0.1-0.1
        c0,0.2,0,0.4,0,0.7c-0.3,0.3-0.2,0.8-0.5,1.2c0.1,0,0.2,0.1,0.2,0.2c-0.1,0-0.3,0-0.4,0c0,0.2,0,0.4,0,0.6c-0.4,0-0.1,0.5-0.1,0.7
        l-0.2,0.1l0-0.3c-0.3-0.2-0.2,0.3-0.2,0.5c-0.1,0-0.3,0.1-0.4,0.1c0.2,0.3,0.4,0,0.5-0.2c0,0.2,0,0.3,0,0.5
        c-0.4-0.1-0.6,0.3-0.8,0.5c0.2-0.1,0.4-0.2,0.7-0.2c-0.2,0.3-0.6,0.5-0.8,0.8c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0.1,0.2,0.2,0.3,0.3
        c-0.1,0-0.3,0-0.4,0.1l0,0.2l-0.2,0c0.1,0.2,0.2,0.5-0.1,0.7c0.2,0,0.4,0,0.6-0.1c-0.2,0.1-0.3,0.2-0.4,0.4
        c-0.1,0.1-0.3,0.3-0.5,0.3c-0.1,0-0.3-0.1-0.3-0.2c0.1,0.1,0.1,0.3,0.2,0.4c-0.2,0.1-0.3,0.2-0.3,0.4c0.1-0.1,0.3-0.3,0.4-0.4
        c0.1-0.1,0.2-0.1,0.4-0.1c0,0.4-0.7,0.4-0.4,0.9c-0.2-0.5-0.5-0.2-0.7-0.1c0.1,0,0.4-0.1,0.6-0.1l-0.1,0.4c-0.1,0-0.3-0.1-0.4-0.1
        c0.1,0.1,0.3,0.1,0.4,0.2c-0.1,0.2-0.1,0.4-0.1,0.6l-0.2,0c0,0.2-0.3,0.4-0.1,0.6c0.1,0,0.2-0.1,0.3-0.2c0,0.4-0.2,0.8-0.6,0.9
        c-0.1,0.2-0.2,0.4-0.3,0.6c0.2,0,0.4-0.2,0.6,0c-0.1,0-0.3,0-0.4,0c-0.1,0.2-0.2,0.3-0.3,0.5c0.2,0,0.3,0,0.5,0.1
        c-0.3,0-0.6,0.1-0.8,0.2c-0.2,0.1,0,0.3,0,0.5c0.2-0.2,0.3-0.4,0.5-0.5c0,0.2,0,0.5,0,0.6c-0.3-0.1-0.4,0.3-0.6,0.5
        c0.2,0,0.3-0.1,0.5-0.2c0,0.1,0,0.2-0.1,0.3c-0.2,0-0.4,0.1-0.6,0.1c-0.1,0.2-0.3,0.4-0.6,0.5c-0.1,0.2-0.3,0.3-0.5,0.3
        c-0.1,0.1-0.1,0.2-0.2,0.3c0.2-0.2,0.5-0.3,0.8-0.4c0.3-0.1,0.5-0.3,0.8-0.4l0,0.1l-0.1,0c0.1,0.4-0.5,0.5-0.7,0.8l0-0.2
        c-0.1,0.3-0.3,0.4-0.5,0.6c0.1-0.1,0.3-0.1,0.4-0.2c0-0.1,0.1-0.2,0.2-0.3c0.1,0,0.3,0,0.4,0.1c0.1-0.1,0.2-0.2,0.4-0.3
        c0,0.1,0,0.3,0,0.4c-0.3,0-0.5,0-0.5,0.4c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0.2-0.4,0.5,0,0.6c-0.1,0.2-0.8,0.6-0.3,0.8
        c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1-0.3-0.1-0.6-0.3-0.8c-0.1,0.1-0.1,0.3-0.2,0.4c0,0,0.1-0.1,0.2-0.1c0.3,0.2-0.2,0.3-0.2,0.5
        c0.1-0.1,0.3-0.2,0.4-0.2c-0.1,0.1-0.2,0.4-0.2,0.5c0.1,0.1,0.2,0.2,0.3,0.3c-0.3-0.1-0.4,0.2-0.6,0.3c0,0.1,0.1,0.2,0.1,0.3
        c-0.2,0.4,0.1,0.9-0.3,1.2c0.2,0,0.4,0.1,0.6-0.1c-0.2,0.2-0.4,0.3-0.6,0.5c0,0,0.1,0,0.2,0.1c-0.1,0.1-0.2,0.2-0.3,0.3
        c-0.1-0.1-0.1-0.2-0.2-0.2c0.1-0.1,0.2-0.3,0.3-0.4c-0.1,0.1-0.4,0.2-0.5,0.2c-0.1,0.3,0,0.5,0.2,0.7l-0.3,0c0,0.2,0,0.3-0.1,0.5
        c0,0,0.1,0.1,0.2,0.2c-0.1,0.1-0.2,0.3-0.3,0.4c0.1,0,0.4-0.1,0.6-0.1c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1-0.1-0.3-0.2-0.4-0.3l0,0.2
        c0.1,0,0.2,0,0.3,0c0,0.2,0,0.5,0,0.7c-0.1,0-0.3-0.1-0.3-0.1c-0.1,0.1-0.2,0.1-0.3,0.2c0,0.1,0,0.2,0,0.2c-0.3,0-0.5,0.1-0.8,0.2
        c0,0,0,0.1,0,0.1c0.1,0,0.2,0,0.3,0c0,0.1,0,0.3,0,0.4c0-0.1,0-0.3,0-0.5c0.2,0,0.4,0,0.6,0c0-0.1,0-0.3-0.1-0.5l0.3,0
        c0,0.1-0.1,0.2-0.1,0.3c0.1,0,0.3,0.1,0.3,0.2c-0.1,0.1-0.3,0.2-0.4,0.2c0,0.1,0,0.3,0,0.5c0.1,0,0.2,0.1,0.2,0.1l0,0.1l-0.1,0
        c-0.2-0.3-0.7,0.1-0.4,0.3c0.1,0,0.2-0.1,0.3-0.2c-0.2,0.2-0.3,0.3,0,0.4c-0.2,0.1-0.5,0.3-0.7,0.3c0-0.1,0-0.3-0.1-0.3
        c-0.1-0.2-0.2-0.4-0.2-0.6c0,0,0,0-0.1,0c0,0.1,0,0.3,0,0.4c0.2,0.2,0.2,0.4,0.1,0.7c0.1,0,0.2,0.1,0.4,0.1c0.1-0.2,0.4-0.6,0.5-0.1
        c-0.4,0.2-0.4,0.6-0.1,0.9c-0.1,0-0.3,0-0.4,0c0,0.1,0.1,0.3,0.1,0.3c-0.1,0-0.3-0.1-0.4-0.1c0.1,0.1,0.2,0.3,0.3,0.4
        c0.1-0.1,0.3-0.3,0.4-0.4c0,0.3-0.4,0.4-0.5,0.7c-0.1,0-0.2-0.1-0.3-0.2c0,0.1,0,0.3,0,0.4c0.1,0,0.3,0,0.4,0
        c-0.1,0.2-0.2,0.3-0.2,0.6c-0.2-0.2-0.3-0.3-0.5-0.5c-0.1,0.2-0.1,0.3-0.1,0.5c0.1,0,0.2,0.1,0.2,0.2c0-0.1-0.1-0.3-0.1-0.4
        c0.4,0,0.4,0.4,0.4,0.7c-0.1-0.1-0.2-0.2-0.2-0.2c0,0.2,0,0.5,0,0.6c0.1,0,0.2,0,0.3-0.1c-0.1,0-0.2,0.1-0.2,0.1
        c0,0.1,0.1,0.2,0.1,0.4c-0.1,0.1-0.2,0.1-0.3,0.2c0-0.1,0.1-0.2,0.2-0.2c-0.1-0.4-0.4,0-0.5,0c0.1,0.3,0.2,0.5,0.3,0.8
        c-0.1,0.1-0.3,0.1-0.4,0.2c0.1,0,0.5,0.2,0.2,0.4c-0.3,0.2-0.3,0.5-0.4,0.8c0.1,0.2,0.2,0.3,0.3,0.5c-0.1,0-0.2,0-0.3,0
        c0,0.1,0.1,0.3,0.2,0.3c-0.1,0-0.3,0-0.4,0l0.3,0l0,0.4c-0.2-0.1-0.3-0.2-0.5-0.2c0,0.1,0,0.3,0.1,0.4l0.2-0.2
        c0.2,0.2,0.3,0.4,0.5,0.7c-0.1,0-0.3-0.1-0.4-0.1l0.1,0.2c0.1,0.1,0.2,0.3,0.3,0.3l-0.2,0.1l-0.2-0.4l-0.2,0.2l0.4,0.2
        c-0.1,0-0.3,0.1-0.4,0.1c0,0.1,0.1,0.2,0.1,0.3c-0.1,0-0.2,0.1-0.2,0.1c0.1,0.4-0.1,0.6-0.4,0.9l0.3,0c0,0.1-0.1,0.3-0.1,0.5
        c0.2-0.3,0.1-0.9,0.6-0.6c-0.1-0.1-0.2-0.3-0.3-0.4c0-0.1,0.1-0.3,0.2-0.4c0.4,0,0.2,0.2,0.1,0.5c0.1,0.1,0.2,0.2,0.3,0.4
        c-0.1,0.1-0.2,0.3-0.2,0.3l0,0.1c0,0.1,0.1,0.2,0.1,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c0-0.1,0-0.3,0-0.4c-0.2-0.1-0.2,0.2-0.2,0.3
        c0.4,0.2,0.3,0.8,0.3,1.2c-0.1,0-0.3-0.1-0.4-0.1c0.1,0.2,0.1,0.4,0.1,0.5c0.1,0,0.2,0,0.3,0c-0.1,0.2-0.2,0.3-0.3,0.5
        c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0-0.3,0-0.4,0.1c-0.1,0.2,0.3,0.4,0.3,0.1c0.1,0.1,0.3,0.3,0.4,0.4c-0.1,0-0.2,0.1-0.3,0.1
        c-0.1,0.1-0.1,0.3-0.2,0.4l0.2,0.1c-0.1-0.3,0.1-0.5,0.3-0.6c0,0.2-0.1,0.5-0.1,0.7l0.2-0.1c0.1,0.1,0.1,0.3,0.2,0.4
        c-0.2-0.1-0.4-0.2-0.5-0.3c0.1,0.1,0.2,0.3,0.3,0.4c-0.1,0.1-0.3,0.2-0.3,0.3c0,0.2,0.1,0.7,0.4,0.4c0-0.1,0.1-0.3,0.1-0.4l0,0
        c0,0.3-0.1,0.6-0.4,0.8c0.1,0.1,0.2,0.2,0.2,0.3c-0.1,0-0.4,0-0.5,0c0,0.2,0.1,0.4,0.2,0.5c-0.1,0-0.2,0.1-0.3,0.1
        c0,0,0.1,0.1,0.1,0.2c0.2-0.1,0.3-0.2,0.5-0.2l0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.2c0.1,0.1,0.3,0.2,0.4,0.2c-0.1,0-0.2,0.1-0.3,0.2
        l0,0.1c0.1,0,0.3-0.1,0.4-0.1c0,0.1,0,0.3,0,0.4c-0.1,0-0.4-0.1-0.5-0.1c0,0.1,0,0.4,0,0.5c0.1-0.1,0.2-0.2,0.3-0.3
        c-0.1,0.2-0.1,0.3-0.2,0.5c0.1,0.1,0.2,0.3,0.3,0.3l0.1,0c0,0.1,0.1,0.2,0.1,0.3c-0.1,0-0.2,0-0.3,0l0.1,0.2
        c-0.4-0.2-0.5,0.1-0.5,0.5c0.1-0.1,0.3-0.3,0.4-0.4c-0.1,0.3,0,0.5,0.2,0.6c-0.1,0-0.3,0-0.4,0c0.2,0.2,0.6,0.3,0.6,0.6
        c-0.2-0.1-0.3-0.3-0.4-0.4c-0.2,0.2-0.4,0.5-0.5,0.9c0.4,0.1,0.3-0.3,0.3-0.6c0.1,0.1,0.3,0.2,0.4,0.3c0,0.2-0.2,0.6,0.1,0.7
        c0,0.1,0.1,0.3,0.1,0.3c-0.1,0.1-0.3,0.2-0.4,0.3c0.2,0,0.4,0.1,0.6,0.1c-0.1,0-0.2,0-0.3,0.1l0,0.2c-0.2-0.1-0.4-0.2-0.6-0.3
        c0.1,0.3,0.4,0.4,0.6,0.5c0-0.2,0.1-0.3,0.3-0.3c-0.1,0.2-0.1,0.3-0.1,0.5c-0.1,0-0.2,0-0.2,0c-0.1,0-0.3,0-0.4,0.1
        c0.1,0,0.3,0,0.4,0c-0.1,0.2-0.3,0.5-0.1,0.7c-0.3,0.1-0.2,0.4-0.3,0.6c0.2,0.2,0.4,0.4,0.7,0.5c0-0.4-0.4-0.5-0.6-0.8
        c0.2-0.2,0.4-0.4,0.7-0.5c0,0.3-0.4,0.5-0.2,0.9c0.1-0.1,0.2-0.2,0.3-0.3c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.3,0.2,0.9-0.3,1
        c0.1,0.1,0.2,0.2,0.2,0.3c0-0.1,0-0.3,0-0.4c0.5,0.2-0.1,1.3-0.5,0.8c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0.3,0.3,0.7,0.3,1.1l-0.3-0.2
        c0.1-0.3-0.1-0.5-0.3-0.7c0.1,0.4,0.2,0.7,0.3,1.1c0.3,0.1,0.4-0.2,0.7-0.3c-0.1,0.1-0.2,0.3-0.2,0.4c0.1,0.1,0.1,0.3,0.2,0.4
        c0.1,0,0.2-0.1,0.3-0.1l-0.1,0.2c0,0-0.1,0-0.2,0c0,0.1-0.1,0.3-0.1,0.4c-0.2-0.3,0-0.9-0.5-0.8c0.1,0.1,0.2,0.2,0.3,0.2
        c0,0.2-0.1,0.3-0.2,0.4c0.2,0.1,0.3,0.2,0.5,0.4c0,0.1-0.1,0.3-0.2,0.4c0.2,0.3,0.3,0.3,0.5,0c-0.1,0.2-0.2,0.3-0.4,0.5
        c0.1,0,0.2,0.1,0.3,0.2c0,0.1,0,0.2,0,0.2c0.5,0-0.1,0.4-0.1,0.6c0-0.2,0-0.4,0.1-0.5c-0.1-0.1-0.3-0.1-0.4-0.2c0-0.1,0-0.4,0-0.5
        c-0.1-0.1-0.2-0.2-0.3-0.3c0.1,0.4,0.1,0.7,0.3,1c0,0.3,0.1,0.7,0.5,0.6c-0.1,0-0.2,0.1-0.2,0.1c0.1,0.4,0.3,0.8,0.1,1.1
        c0.1,0,0.2,0,0.3-0.1c0,0.1,0,0.3,0,0.5c-0.2-0.1-0.3-0.3-0.3-0.5c-0.3-0.1-0.3-0.3-0.2-0.6c-0.1,0-0.3-0.1-0.4-0.2
        c0.2,0.3,0.3,0.6,0.3,1c0.2,0.1,0.3,0.3,0.3,0.5c-0.1,0.1-0.3,0.1-0.4,0.2c0.1,0,0.4-0.1,0.6-0.1c-0.2,0.1-0.4,0.3-0.6,0.4
        c0.1,0.3,0.2,0.6,0.4,0.8c-0.1,0-0.2,0-0.3,0.1c0.2,0.1,0.5,0.2,0.7,0.3c-0.1-0.2-0.3-0.3-0.4-0.4l0.2-0.1c-0.2-0.1-0.3-0.2-0.5-0.3
        c0.1-0.3,0.5-0.4,0.8-0.4c-0.2,0.3-0.3,0.7,0,1c0,0.1-0.1,0.2-0.1,0.3l0.3,0c0,0.1,0,0.2-0.1,0.3l0.2,0l0,0.1l-0.2,0
        c0,0.3,0.1,0.6-0.1,0.8c0.1,0.3,0.3,0.6,0.5,0.9c-0.1,0-0.2,0-0.3,0l0,0.3c-0.1-0.1-0.1-0.3-0.2-0.4c-0.2,0-0.5,0.1-0.5,0.4
        c0.2-0.1,0.3-0.2,0.5-0.2c0,0.1,0,0.4-0.1,0.6c0.1,0,0.2,0.1,0.3,0.1l-0.2,0.4c0.1,0,0.3-0.2,0.4-0.2c-0.1,0.2,0,0.6-0.2,0.7
        c-0.1-0.1-0.3-0.2-0.4-0.2c0,0.1,0.1,0.3,0.1,0.4c0.2-0.1,0.4-0.1,0.4,0.2c0,0.1-0.1,0.3-0.2,0.3l0.3-0.1c0,0.3,0.1,0.7,0.1,1
        c-0.3-0.2-0.3-0.8-0.4-1.2c-0.1-0.2-0.3-0.2-0.4,0c0.1,0,0.2,0.1,0.3,0.2c0,0.2-0.2,0.2-0.4,0.1c0,0.1,0,0.2,0,0.3
        c0.1,0,0.3-0.1,0.5-0.1c-0.2,0.3,0.1,0.6,0.2,0.9c0,0.2,0.1,0.4,0.3,0.6c0,0.2-0.1,0.5-0.1,0.7c-0.2-0.3-0.5-0.5-0.3-0.8
        c0,0-0.1,0-0.2,0c-0.2,0.2-0.4,0.4-0.5,0.6c0.2-0.1,0.3-0.1,0.5-0.3c0.1,0.4,0.5,0.6,0.8,0.8c-0.1,0.1-0.3,0.1-0.4,0.2l0.2,0.2
        l-0.3,0.1c0.1,0.2,0.3,0.4,0.4,0.6c-0.1-0.1-0.4-0.5-0.5-0.1c-0.2,0.3,0.3,0.2,0.4,0.3c0.1,0.2,0.3,0.7-0.1,0.8
        c-0.2-0.1,0.1-0.4,0.1-0.6c-0.1,0-0.3,0-0.4,0l0.2,0.3c-0.1,0-0.2,0.1-0.2,0.1c0.4,0.1,0,0.4,0,0.6c0.1-0.1,0.3-0.2,0.4-0.3
        c0,0.1,0.1,0.3,0.2,0.4c-0.1,0.3,0,0.6,0,0.9c0,0,0.1,0,0.1,0.1c-0.3,0.2-0.5,0.8-0.9,0.5l-0.1,0.2c0.1,0,0.3,0.1,0.3,0.1
        c0,0.2-0.3,0.4,0,0.3l-0.1,0.3l0.2,0l0-0.3c0.2,0.1,0.3,0.2,0.5,0.3c-0.2,0.2-0.3,0.4-0.5,0.6c0.1,0,0.3,0,0.3,0
        c0.2,0.1-0.2,0.4-0.2,0.6c-0.2,0-0.5,0-0.7-0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0,0.2-0.1,0.3-0.2c-0.1,0.4,0.2,0.8,0.2,1.2
        c0.3-0.1,0.2-0.4,0.3-0.6c0.4-1.7,0.7-3.4,1.3-5.1c0.1-0.5,0.2-1,0.4-1.4c0-0.1-0.1-0.2-0.1-0.2c0.3-0.3,0-0.9,0.3-1.2
        c0.1-0.4-0.1-0.9,0.2-1.2l-0.4,0.2c0.1-0.3,0.3-0.5,0.1-0.8c0-0.1,0-0.3-0.1-0.4l0.2,0.1c-0.1-0.3,0-0.6,0-1l0.2,0.1
        c-0.1-0.2-0.2-0.4-0.3-0.5c0.1-0.1,0.3-0.2,0.4-0.2l-0.4,0l0-0.3l0.2,0c-0.1-0.2-0.2-0.5,0.1-0.6l0-0.1l-0.2,0.1c0-0.4,0.1-0.7,0-1
        l0.2,0c-0.2-0.2-0.2-0.4-0.2-0.7c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.2,0,0.3,0c-0.1-0.1-0.2-0.3-0.3-0.4c0.1,0,0.3,0,0.4-0.1
        c-0.5-0.3,0-0.9-0.2-1.4l0.2,0c0-0.2-0.4-0.6,0-0.6c0-0.3-0.1-0.5-0.3-0.7l0.4,0.1l-0.3-0.3c-0.1,0.1-0.2,0.1-0.3,0.2
        c0-0.2,0.1-0.3,0.2-0.5c-0.2-0.4-0.5-0.7-0.9-1c0.1-0.4-0.3-0.8-0.5-1.1c-0.1-0.1,0-0.3-0.1-0.4c-0.5-0.4-0.6-1.1-0.8-1.7
        c-0.3,0-0.4-0.3,0-0.3c-0.1,0-0.3-0.2-0.4-0.2c0.2-0.3-0.2-0.5-0.2-0.8c-0.1-0.5-0.4-0.9-0.6-1.4c-0.3-0.5-0.2-1.1-0.4-1.6
        c-0.2-0.4,0.1-0.9-0.1-1.3c-0.3-0.3-0.4-0.9-0.2-1.3c0-0.5-0.1-1-0.3-1.4c0.1-0.2,0.1-0.3,0.2-0.4c-0.3-0.5,0-1.2-0.2-1.7
        c0.3-0.7,0.1-1.5,0.4-2.2c0.1-0.5-0.1-1,0.2-1.4c0.2-0.2,0-0.5,0-0.7c0-0.4,0.3-0.7,0.2-1.1c-0.1-0.4,0.2-0.7,0.3-1.1
        c0.1-0.6,0.2-1.3,0.5-1.9c-0.2-0.3-0.1-0.6,0.2-0.9c-0.2-0.6,0.2-1.3,0.4-1.8c0.3-1.1,0.5-2.2,1-3.2c0.1-0.4,0.3-0.8,0.4-1.1
        c0.1-0.4,0.1-0.7,0.2-1c0.1-0.1,0.1-0.2,0.1-0.4c0.3-0.6,0.3-1.2,0.7-1.8c0.2-0.9,0.8-1.7,0.9-2.6l0.1,0c0.1-0.2,0.1-0.5,0.2-0.7
        c0.1-0.3,0.3-0.5,0.3-0.8c0.3-0.2,0.2-0.6,0.3-1c0,0.1,0,0.3,0,0.5c-0.1,0.3-0.2,0.6-0.3,0.8c-0.1,0.1-0.1,0.3-0.2,0.4
        c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.1,0.5-0.2,0.7c-0.3,0.3-0.2,0.8-0.5,1.1c-0.1,0.3-0.2,0.6-0.2,1c-0.1,0.2-0.1,0.4-0.2,0.6
        c-0.3,0.3-0.1,0.7-0.3,1c0,0.3,0,0.6,0,1l-0.2,0c0.1,0.1,0.1,0.3,0.2,0.4c-0.1,0-0.2,0-0.3,0c0.1,0.1,0.2,0.2,0.2,0.3l-0.2,0
        c0.2,0.8-0.3,1.6-0.4,2.4c-0.2,0.4,0.1,0.9-0.3,1.2c0.1,0.2,0.3,0.3,0.4,0.5c-0.1,0-0.3,0-0.4,0l0.1,0.3c0,0-0.1,0.2-0.1,0.2
        c0,0.4-0.1,0.9,0,1.3c-0.2,0.1-0.4,0.2-0.5,0.3c0.1,0.3,0.3,0.1,0.5,0l-0.1,0.3l-0.3,0.1c0.1,0.1,0.3,0.2,0.4,0.3
        c-0.2,0.1-0.5,0.3-0.6,0.6c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.2,0,0.3,0c0,0.2,0.1,0.4,0.2,0.6l-0.1-0.1c-0.1,0.1-0.2,0.3-0.2,0.4
        c-0.3-0.3,0.2-0.4,0.2-0.7c-0.2,0-0.4,0.3-0.6,0.4c0.2,0,0.3,0.1,0.3,0.4c0.2,0.1,0.4,0,0.5,0.2l-0.3-0.1c-0.1,0.2-0.1,0.4-0.2,0.6
        l0.2,0c0,0.1-0.1,0.2-0.1,0.3c-0.1,0-0.3,0-0.4,0c0.1,0.1,0.2,0.2,0.2,0.2c0,0.2,0,0.5-0.1,0.7c0.2,0,0.3-0.1,0.4-0.2
        c-0.1,0.2-0.2,0.4-0.3,0.5h0.3c-0.1,0-0.3,0-0.3,0c0,0.1,0.1,0.3,0.2,0.4c-0.2-0.1-0.4-0.2-0.6-0.3c0.2,0.2,0.3,0.4,0.4,0.7
        l-0.3-0.1c0,0,0,0.1,0,0.2c0.1,0,0.2,0,0.3,0c0.1,0.3-0.1,0.4-0.3,0.6c0.2-0.3-0.2-0.4-0.3-0.6c0.1,0.2,0.2,0.4,0.2,0.6
        c-0.2,0.1-0.3,0.1-0.4,0.2c0.2,0,0.3,0,0.5-0.2c0.1,0,0.2,0.1,0.2,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0.1,0.3,0.2,0.4,0.3
        c-0.1,0.1,0,0.4-0.3,0.4c0,0.1,0.1,0.4,0.2,0.5c-0.1,0-0.3,0-0.4-0.1c0,0.1,0,0.2,0,0.3c0.1,0,0.2,0,0.3,0c-0.1,0.3-0.2,0.6-0.3,0.9
        c0.2,0,0.4-0.1,0.6-0.3c-0.1,0.1-0.2,0.3-0.3,0.4c0,0.1,0.1,0.2,0.2,0.2c-0.2,0-0.4-0.1-0.5-0.3c-0.3,0-0.3,0.4-0.3,0.6
        c0.1-0.1,0.2-0.4,0.4-0.2c0.1,0.1,0.1,0.3,0,0.4c-0.1,0-0.3,0.1-0.4,0.2c0.1,0,0.2,0,0.3,0.1c0.1-0.2,0.3-0.3,0.4-0.5
        c0,0.2,0.1,0.3,0.2,0.5c-0.1,0-0.2,0-0.4,0l0.2,0.2c-0.3,0.2-0.1,0.5,0.1,0.7c-0.1,0.2-0.2,0.4-0.2,0.7c0.2-0.1,0.4-0.3,0.6-0.4
        c0,0.1-0.1,0.3-0.1,0.4c-0.1,0-0.3,0.1-0.4,0.1c0.1,0.3,0.6,0,0.6,0.4c-0.1,0.3-0.3-0.5-0.5-0.1c0.1,0.2,0.2,0.4,0.1,0.7
        c-0.2-0.1-0.5-0.2-0.7-0.3c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0,0.3,0,0.3,0.1c0-0.2,0.1-0.3,0.3-0.4l-0.1,0.5c0.1-0.1,0.3-0.2,0.4-0.3
        c0.1,0.2-0.2,0.3-0.2,0.4c-0.1,0.3-0.1,0.5-0.4,0.6c0.3,0,0.3,0.3,0.3,0.5c-0.2-0.1-0.4,0-0.6,0.1c0,0.1,0,0.2,0,0.3l0.3,0
        c0,0.1-0.1,0.3-0.1,0.4c0.1-0.1,0.2-0.2,0.2-0.2c0.2,0.2,0.4,0.4,0.4,0.7c0.1,0,0.3,0.1,0.4,0.1l-0.1,0.2c-0.1-0.1-0.3-0.2-0.4-0.3
        c0,0.2,0.1,0.5,0.1,0.6c0.1-0.1,0.3-0.1,0.5-0.2c-0.1,0.1-0.2,0.3-0.3,0.4c0,0.2,0,0.3-0.1,0.5c0.2-0.1,0.3-0.4,0.6-0.3
        c-0.1,0.1-0.2,0.3-0.3,0.4c0,0.3,0.1,0.6,0.1,0.9c0.2-0.1,0.3-0.1,0.4,0.1c0.3-0.1,0.1-0.4,0.1-0.7c0-1,0.1-1.9,0.1-2.9
        c0-0.4,0-0.8,0.1-1.2c0.2,0.4-0.1,0.7-0.1,1.1c0.1,0.8,0,1.6,0,2.5c0,0.4,0,0.8,0,1.2c0,0-0.1,0.1-0.1,0.2c0.2,0.3,0,0.6-0.3,0.7
        c0.1,0,0.3-0.1,0.4-0.1c-0.1,0.2-0.1,0.4-0.2,0.6c0,0,0.1,0,0.1,0c0-0.2,0.1-0.4,0.3-0.4c-0.1,0.5,0.5,0.5,0.5,0.9
        c-0.2-0.2-0.4-0.5-0.7-0.3l0,0.2c0.1,0,0.2,0,0.3,0c0,0.1,0,0.2-0.1,0.3c0.1,0,0.3,0,0.4,0c-0.1,0.2-0.2,0.5-0.4,0.7
        c0.1,0,0.2,0.1,0.3,0.2c0.1,0.2,0.2,0.3,0.3,0.4c0.2,0,0.5,0,0.6,0.2c-0.1,0-0.3,0-0.4,0c0.1,0.1,0.2,0.4,0.3,0.5
        c-0.1,0-0.3-0.1-0.4-0.1c0.3,0.1,0.3,0.4,0.3,0.6c0,0,0.1,0,0.2,0c0.1,0.3,0.2,0.6,0,0.9c0,0,0.1,0,0.2,0c0-0.1,0-0.4,0-0.6
        c0.1,0.1,0.2,0.3,0.3,0.3c0.3,0,0.4,0.3,0.6,0.6c0.1,0,0.2,0,0.3,0c0.1,0.2-0.2,0.2-0.3,0.3c0,0.1,0.1,0.4,0.1,0.5
        c0.2,0.1,0.4,0.1,0.6,0.1c-0.1,0.1-0.2,0.3-0.3,0.4c0,0.1,0.1,0.3,0.1,0.5c0.1,0,0.2-0.1,0.3-0.1c-0.1-0.2-0.1-0.3,0-0.4
        c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0.1-0.3,0.2-0.3,0.2c0.3,0.1,0.7,0.1,0.8,0.5c-0.1,0-0.2,0.1-0.3,0.1c0-0.1,0-0.3,0-0.4
        c-0.1,0-0.2,0.1-0.3,0.1c0,0.3,0.1,0.7,0.3,0.9c0-0.1,0-0.3,0-0.4c0.2,0.1,0.4,0.2,0.6,0.2c0,0.1,0,0.2,0,0.3c-0.1,0-0.2,0-0.3-0.1
        c0,0.1,0,0.3,0,0.5c0.1-0.2,0.3-0.3,0.4-0.4c0.1,0.1,0.2,0.3,0.3,0.3c0,0.2,0,0.3,0,0.5c0.3-0.1,0.3,0.2,0.5,0.4
        c0,0.1,0,0.2-0.1,0.3l0.2-0.1c0,0.3,0,0.6,0.3,0.6c0,0-0.1,0.1-0.2,0.2c0.1,0,0.2,0,0.3,0c0.2,0.4,0.5,0.5,0.9,0.7
        c-0.1,0.2-0.2,0.4-0.3,0.6c0.1,0,0.4-0.1,0.5-0.1c-0.1,0.2-0.2,0.4-0.3,0.6c0.2,0.2,0.5,0.3,0.7,0.5l-0.2,0.1c0,0.1-0.1,0.2-0.1,0.3
        c0.3-0.1,0.3-0.4,0.5-0.6c-0.2-0.1-0.6-0.1-0.6-0.4c0.1-0.1,0.1-0.2,0.2-0.2c0.1,0.4,0.5,0.6,0.8,0.9c-0.1,0-0.2,0-0.3,0l-0.2,0.2
        c0.1,0,0.2,0.1,0.3,0.1l0,0.1c-0.1,0-0.3,0-0.4,0c0.1,0.2,0.4,0.3,0.3,0.6c0,0.1,0.2,0.2,0.2,0.3c0-0.1,0-0.3,0-0.4
        c-0.1-0.1-0.2-0.2-0.2-0.3l0.3,0c0-0.1,0-0.2,0-0.3c0.2,0.4,0.3,1-0.1,1.3c0.2,0.1,0.3-0.1,0.4-0.2c0,0.1,0.1,0.2,0.2,0.2l0.3,0
        c-0.1,0.1-0.2,0.3-0.2,0.4c-0.2,0-0.4-0.1-0.7-0.1c0.2,0.1,0.4,0.1,0.6,0.2c0.1-0.1,0.3-0.2,0.4-0.2c-0.1,0.3-0.1,0.6-0.1,0.9
        c0.2-0.2,0.2-0.4,0.3-0.6c0,0.3,0.1,0.5,0.1,0.8c0.2-0.1,0.3,0.1,0.4,0.2l-0.3,0c0,0.1,0.1,0.4,0.2,0.5c-0.2,0-0.6-0.4-0.7,0
        c0.1,0.1,0.2,0.2,0.4,0.3c0.1-0.1,0.2-0.2,0.3-0.3l0.1,0.5c0.2-0.3,0.6-0.5,0.6-0.9l-0.2,0c0-0.1,0-0.2,0-0.2
        c-0.3,0-0.3-0.3-0.3-0.4c0.2,0.1,0.3,0.2,0.3,0.4c0.1-0.1,0.2-0.2,0.4-0.3l-0.1,0.2l0.1,0.1c0.1,0,0.2,0,0.3,0
        c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.2,0-0.4-0.3-0.5c0,0.1,0.1,0.3,0.2,0.4c-0.1,0.1-0.2,0.1-0.2,0.2c0-0.1-0.1-0.2-0.2-0.3
        c-0.1,0-0.2,0-0.3,0c0.3,0,0.5-0.3,0.6-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c0-0.1-0.1-0.2-0.1-0.3c0.2,0.4-0.2,0.5-0.4,0.7
        c0-0.1,0-0.3,0.1-0.4l-0.3,0.1c0-0.3,0.2-0.2,0.4-0.2c0.1-0.4-0.2-0.5-0.6-0.4c0.2-0.1,0.4-0.2,0.6-0.2c-0.1-0.1-0.3-0.3-0.4-0.4
        c0.1,0,0.2,0,0.3,0c-0.4-0.4-0.7-1-0.8-1.5c-0.2-0.2-0.3-0.3-0.5-0.5c0-0.2,0-0.4-0.1-0.6c-0.7-1-0.7-2.2-1.4-3.1
        c-0.3-0.7-0.3-1.5-0.5-2.3c-0.1-0.5-0.6-1-0.5-1.5c0.1-0.3-0.2-0.6-0.3-0.9c-0.1-0.5-0.5-1.1-0.2-1.6c-0.1-0.1-0.2-0.3-0.3-0.4
        c-0.2-0.3,0.2-0.7-0.2-0.9c0.1-0.6-0.2-1.2-0.2-1.8c-0.2-0.8-0.2-1.6-0.5-2.4c0-0.1,0.1-0.2,0.2-0.3c-0.1-0.2-0.2-0.3-0.3-0.5
        c-0.1-0.8-0.1-1.5,0-2.3c-0.1-0.3-0.2-0.7-0.3-1c-0.1-0.6,0.1-1.1,0.2-1.7c-0.7-1.2-0.3-2.6-0.3-3.9c-0.2-0.3-0.1-0.6-0.1-0.9
        c0-0.3-0.1-0.6,0-0.9c-0.1-0.6-0.2-1.2,0-1.7c-0.2-0.2,0.1-0.5,0-0.7c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0.1-0.3,0.1-0.4
        c-0.1,0-0.2-0.1-0.3-0.2c0.1-0.2,0.2-0.4,0.3-0.7c-0.4-0.2-0.1-0.7,0-1c-0.2-0.1-0.2-0.1-0.1-0.3c-0.1-0.7,0.2-1.4,0.1-2.1
        c0-0.3,0.1-0.7,0.2-1c-0.3-0.2-0.1-0.5-0.1-0.7c0-0.4,0.3-0.7,0.2-1.1c0-0.7-0.1-1.4,0.2-2.1c-0.3-0.2-0.2-0.5-0.1-0.8
        c0-0.2-0.1-0.5-0.1-0.7c0.2-0.2,0.3-0.4,0.1-0.6c0.2-0.5,0.2-1,0.3-1.5c0.2-0.6-0.1-1.2,0.3-1.7c-0.1-0.2,0-0.5,0.1-0.7
        c0-0.2-0.1-0.5,0-0.7c0.1-0.3,0.1-0.5,0.3-0.8c0.1-0.4,0.4-0.8,0-1.2c0.1,0.1,0.3,0.2,0.4,0.3c0-0.2,0-0.4,0-0.6
        c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.3-0.2c-0.1-0.2-0.5-0.3-0.3-0.6c0-0.3,0.7,0.4,0.5-0.4c-0.1,0-0.3,0-0.4,0
        c0.1-0.1,0.3-0.2,0.5-0.2c-0.1-0.1-0.3-0.2-0.5-0.2c0,0,0.1-0.1,0.1-0.2c0.1,0,0.2,0.1,0.3,0.2c0-0.4,0.3-0.8,0-1.1
        c0.3,0.1,0.4-0.2,0.4-0.5c-0.1,0-0.3,0-0.4,0c0.1-0.3,0.3-0.3,0.6-0.3c0-0.4-0.1-0.9,0.1-1.3c0-0.1-0.1-0.2-0.2-0.3
        c0.1,0,0.2,0,0.3,0c0.1-0.2,0.1-0.5,0.1-0.7c0.1,0,0.2,0,0.3,0c0-0.3-0.1-0.6-0.3-0.8c0.1-0.1,0.1-0.1,0.2-0.2
        c-0.1-0.4,0.4-0.4,0.5-0.7c-0.1,0-0.3,0-0.4,0c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.6,0.4-1.2,0.5-1.8c0.7-1.1,0.6-2.5,1.3-3.6
        c0-0.6,0.1-1.4,0.6-1.8c0.1-0.4,0.1-0.7,0.4-1c0-0.4,0.2-0.9,0.3-1.2c0.3-0.6,0.4-1.3,0.9-1.9c0-0.3,0-0.5,0.2-0.7
        c0.1-0.4,0.1-0.9,0.6-1c-0.2-0.6,0.2-1.2,0.5-1.8c0.1-0.2,0.2-0.5,0.4-0.6c0.3-0.1,0-0.5,0.2-0.6c0.2-0.3,0.3-0.8,0.7-0.9
        c0-0.3,0.2-0.6,0.4-0.9c0.2-0.7,0.6-1.2,1-1.8c0.2-0.8,0.6-1.4,0.9-2.2c0-0.2,0.2-0.3,0.4-0.4c0.2-0.7,0.4-1.3,0.7-1.9
        c0.2-0.4,0.3-0.9,0.7-1.2c-0.1-0.5,0.4-0.8,0.4-1.3c0.2-0.3,0.4-0.6,0.6-0.9c0-0.5,0.4-0.8,0.6-1.2c0.2-0.4,0.3-0.8,0.6-1.1
        c0.2-0.1,0.1-0.4,0.2-0.6c0.1-0.1,0.3-0.1,0.4-0.2c0-0.2,0-0.5,0-0.7c0.1-0.3,0.4-0.4,0.5-0.7c0.1-0.5,0.4-1,0.8-1.3
        c0.1-0.5,0.4-0.9,0.9-1.1c0-0.3,0-0.6,0.2-0.8c0.5-0.1,0.3-0.8,0.8-0.9c0.1-0.9,0.9-1.5,1.2-2.4c0.8-0.7,0.8-1.9,1.7-2.5
        c0.2-0.4,0.5-0.8,0.9-1.1c0-0.5,0.5-0.7,0.7-1c0-0.5,0.5-0.8,0.6-1.3c0.5-0.3,0.6-1,1-1.4c0.8-0.5,0.9-1.6,1.8-2.1
        c0.3-0.6,0.9-1.1,1.2-1.6c0.4-0.4,0.5-1,1.1-1.2c0-0.7,0.8-0.7,1-1.4c0.3-0.3,0.7-0.6,1-1c0.4-0.7,1-1.2,1.5-1.9
        c0.3-0.2,0.3-0.6,0.7-0.8c0.3-0.2,0.2-0.6,0.6-0.8c0.5-0.2,0.5-0.8,0.9-1.1c0.4-0.3,0.6-0.9,1.1-0.9c0.1-1,1.1-1.4,1.5-2.3
        c0.4-0.2,0.6-0.4,0.7-0.8c0.1,0,0.2-0.1,0.3-0.1c0.1-0.3,0.3-0.5,0.6-0.7c0.1-0.3,0.3-0.5,0.6-0.7c0.1-0.3,0.3-0.4,0.6-0.5
        c0.3-0.3,0.5-0.6,0.7-1c0.2-0.3,0.5-0.5,0.7-0.8c0,0-0.1-0.1-0.2-0.2v-0.2c-0.1,0-0.3,0-0.3,0l0,0.3c-0.1,0-0.2-0.1-0.2-0.2
        c-0.2,0-0.4,0-0.6,0c-0.1-0.1-0.1-0.3-0.2-0.4c-0.3,0.1-0.4-0.2-0.2-0.4c-0.2,0.2-0.4,0.4-0.3,0.7l-0.2-0.1l0,0.2
        c-0.2,0-0.4,0-0.6-0.1c0.1-0.1,0.2-0.3,0.2-0.3c-0.2-0.1-0.5-0.1-0.7-0.1l0,0l0.1,0c0.1,0.1,0.3,0.1,0.4,0.2c-0.2,0.2-0.4,0.1-0.6,0
        c-0.2,0.1-0.4,0.1-0.6,0.2c0.2-0.3,0-0.4-0.2-0.6C352.1,212.3,351.9,212.2,351.8,212.2 M355.1,212.2c0.1,0.2,0.1,0.7,0.4,0.7
        C355.6,212.6,355.3,212.4,355.1,212.2 M357.4,212.2C357.4,212.3,357.4,212.3,357.4,212.2 M156.7,212.8c0.3,0,0.5-0.2,0.7-0.3
        C157.1,212.5,156.8,212.5,156.7,212.8 M157.7,212.5C157.8,212.6,157.8,212.6,157.7,212.5 M158,212.6l0.1,0.3c-0.2,0-0.5,0-0.6-0.2
        c-0.2,0.3,0.2,0.3,0.4,0.3c0.2,0.4,0.4-0.1,0.2-0.3c0.3-0.2,0.4,0.3,0.6,0.4c-0.1-0.2-0.2-0.4-0.3-0.6
        C158.3,212.5,158.2,212.6,158,212.6 M158.7,212.6c0,0.1,0.1,0.2,0.2,0.3C159.3,212.8,158.9,212.3,158.7,212.6 M160.1,212.5
        C160.2,212.6,160.2,212.6,160.1,212.5 M354,212.5C354,213,354.4,212.3,354,212.5 M355.8,212.5c-0.3,0.3,0.3,0.3,0.1,0L355.8,212.5
        M155.9,212.6c-0.1,0.2,0,0.3,0.2,0.2C156.2,212.6,156.1,212.5,155.9,212.6 M158.9,213.1c0.2,0.1,0.4,0.1,0.6,0.2c0-0.3,0-0.5,0-0.8
        C159.3,212.7,159.2,213,158.9,213.1 M167.9,212.6c-0.2,0.2,0.2,0.5,0.4,0.4C168.4,212.8,168.2,212.4,167.9,212.6 M171.6,212.7
        c0,0.2,0.1,0.3,0.3,0.2C172,212.8,171.9,212.7,171.6,212.7 M356.6,212.7c-0.2,0.1-0.2,0.2,0,0.3
        C356.8,212.9,356.8,212.8,356.6,212.7 M37.3,213.2c0.2,0,0.3,0,0.2-0.2C37.4,212.9,37.3,213,37.3,213.2 M170.4,213
        c0.1,0,0.3,0,0.4,0c-0.1,0.1-0.1,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2l-0.3-0.1l0.1-0.2c-0.1,0-0.3,0.1-0.5,0.2
        c0.2,0.5,0.2,1.1,0.5,1.5c0.1,0.3,0.2,0.7,0.4,1c0.3,0.4,0.3,0.9,0.7,1.3c0.2,0.3,0.3,0.6,0.3,1c0.1,0.1,0.1,0.2,0.2,0.2
        c0,0.1-0.1,0.2-0.1,0.3c0.3-0.1,0.4,0.3,0.2,0.5c0.3-0.2,0.7,0.3,0.3,0.5c0.1,0,0.2,0,0.4,0c0,0.1,0,0.3-0.1,0.3l0.2,0.3
        c-0.3,0.2-0.5-0.1-0.6-0.3c-0.2-0.1-0.3-0.3-0.3-0.5c-0.2-0.1-0.2-0.2-0.2-0.4c-0.2-0.3-0.5-0.6-0.6-1c-0.1,0-0.3,0.1-0.4,0.1l0-0.2
        c0,0-0.2,0.1-0.2,0.1c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2,0.4,0.6,0.4,0.3,0.8c0.1,0,0.3,0,0.4,0l-0.1,0.2c0.1-0.2,0.2-0.4,0.3-0.6
        c0.1,0.1,0.2,0.4,0.3,0.6c-0.1,0-0.3,0-0.4-0.1c0.1,0.2,0.3,0.3,0.5,0.3c0,0.1-0.1,0.2-0.1,0.2c0.1,0,0.2,0,0.2,0
        c0.2,0.3,0.4,0.6,0.7,0.8c-0.1,0.2,0.1,0.3,0.2,0.4c0.1-0.1,0.2-0.2,0.3-0.3c0.2,0.4,0.7,0.7,0.6,1.2c0.1,0,0.4-0.3,0.4,0
        c-0.3,0.2,0,0.5,0.1,0.8c0.1-0.1,0.2-0.3,0.2-0.4c0.1,0.1,0.2,0.2,0.3,0.4c-0.2,0-0.3,0-0.5,0c0.3,0.1,0.4,0.5,0.5,0.8
        c0.4,0,0.4,0.3,0.3,0.6c0.3-0.4,0.5,0.2,0.7,0.4c0.1,0,0.3,0,0.4,0c-0.4,0.3-0.1,0.8,0.2,0.9l0,0.1c-0.1,0.1-0.2,0.2-0.2,0.3
        c0.2-0.2,0.4-0.1,0.6,0c-0.2,0.1-0.5,0.4-0.1,0.4c0,0.1,0,0.2,0,0.3l0.1,0l0-0.1c0.1,0,0.3-0.1,0.4-0.1l0,0.2l0.2,0.2
        c0-0.1,0-0.4,0-0.5c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.3,0.4c-0.3-0.1-0.5-0.2-0.8-0.2c0.1,0.3,0.2,0.7,0.6,0.5
        c0,0.1,0,0.2,0,0.3c0.1,0,0.3,0,0.4,0c-0.1,0.1-0.1,0.2-0.2,0.4c-0.1-0.1-0.2-0.2-0.3-0.2c0.1,0.2,0.2,0.3,0.3,0.4
        c0.1-0.1,0.2-0.1,0.2-0.2c0.2,0.3,0.4,0.7,0.7,1c0.1,0,0.2-0.1,0.3-0.1c0.1,0.3,0.3,0.5,0.4,0.8c0.4,0.1,0.8,0.2,0.9,0.6
        c-0.1-0.1-0.3-0.2-0.4-0.2c0.1,0.1,0.1,0.3,0.2,0.4c0.3,0.1,0.9,0.2,0.7,0.7c0.3,0.2,0.5,0.6,0.8,0.9c0.1-0.2,0.2-0.3,0.2-0.5
        c0.1,0.1,0.4,0.2,0.5,0.3c0,0.2,0,0.4,0,0.5c0.1-0.1,0.2-0.2,0.2-0.3l0.1,0.4c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0.2,0.1,0.3,0,0.5
        c0.2,0,0.4,0,0.5,0.2l-0.1,0.2c0,0,0.1,0.1,0.2,0.1c-0.1,0.3,0.3,0.4,0.5,0.5c-0.1-0.2-0.1-0.3,0.1-0.4c0,0.2,0,0.3,0.1,0.5
        c0.3,0,0.3,0.4,0.6,0.4c0,0.1-0.1,0.2-0.1,0.3c0.2,0,0.3,0,0.5,0.1c-0.2-0.1-0.3-0.3-0.4-0.5c0.1,0.1,0.3,0.2,0.4,0.3
        c0.2,0.1,0.2,0.3,0.3,0.4c0.2,0.1,0.4-0.2,0.5,0.1c-0.2,0.1-0.3,0.2-0.2,0.3c0,0.1,0.1,0.2,0.1,0.3c0-0.1,0-0.4,0-0.5
        c0.6-0.3,0.1,0.5,0.5,0.8c0.4-0.2-0.1-0.5,0.2-0.7c0,0.1,0.1,0.4,0.1,0.5l0.3,0.1c0-0.1-0.1-0.4-0.1-0.5c0.4,0,0.1,0.4,0.1,0.6
        c0.1-0.1,0.3-0.2,0.4-0.3c0,0.1,0,0.2,0,0.3c0.1,0,0.3,0.1,0.4,0.1c0,0.1,0,0.3,0,0.4c0,0,0.1,0,0.1,0c-0.1-0.4,0.4-0.3,0.5-0.1
        c0.2,0.1,0.1,0.6,0.5,0.5c-0.1-0.2-0.1-0.4-0.2-0.6c0,0,0.1,0,0.1,0c0,0.3,0.3,0.3,0.5,0.4c-0.1-0.2-0.1-0.3-0.2-0.5
        c0.2,0.1,0.4,0.1,0.6,0.2c0.1,0.2,0.2,0.4,0.3,0.6c0-0.1,0-0.3-0.1-0.4c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0.3,0.3-0.1,0.6,0
        c0.2-0.1,0.4,0,0.5,0.2c0-0.1,0.1-0.2,0.1-0.2c0.2,0.2,0.4,0.6,0.6,0.2c0.2,0,0.3,0.1,0.5,0.1c0-0.2,0.1-0.4,0.2-0.6l0.2,0
        c0,0.1,0,0.3,0,0.4c0.1-0.1,0.2-0.2,0.3-0.4c0,0.1,0.1,0.2,0.1,0.3c0.2-0.1,0.3-0.1,0.5-0.1l0-0.2c-0.1,0-0.3,0.1-0.4,0.1
        c0-0.2,0.1-0.3,0.3-0.5c0.1,0.1,0.2,0.2,0.3,0.4c0-0.1,0-0.3,0-0.4c0.1,0.1,0.3,0.2,0.3,0.3c0-0.1,0-0.2,0-0.3c0.1,0,0.2,0,0.3,0.1
        c0,0.2-0.1,0.4,0.1,0.4c0.1-0.3,0-0.5-0.3-0.5c0.1-0.2,0.3-0.2,0.5-0.2l0-0.2l0.1,0c0,0.1-0.1,0.2-0.1,0.3l0.2,0
        c-0.1,0.2-0.2,0.3-0.2,0.5c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.4-0.5,0.8-0.2c0-0.1-0.1-0.3-0.1-0.4c0.1,0.1,0.3,0.2,0.4,0.3
        c0.3,0.3-0.2,0.3-0.4,0.4c0,0,0,0.1,0,0.1c0.1-0.1,0.3-0.1,0.4-0.1c0.3-0.2,0.5-0.8,0.9-0.4c0.1-0.2,0.2-0.3,0.2-0.5
        c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1-0.2-0.2-0.4-0.2-0.5c0.3,0.2,0.5-0.1,0.7-0.2c0,0.1-0.1,0.2,0,0.3c0.4,0.1,0.4-0.2,0.5-0.4
        c0.1,0,0.2,0.1,0.3,0.1c0.2-0.2,0.4-0.6,0.8-0.5c-0.2,0.3-0.5,0.5-0.7,0.8c0.2-0.1,0.4-0.2,0.6-0.3c0.1-0.2,0.2-0.3,0.3-0.5
        c0.3,0,0.6,0.1,1,0.2c-0.1-0.1-0.2-0.3-0.3-0.4l-0.2-0.1c0.1,0,0.3,0,0.3,0l0-0.1l0.2,0c0-0.1,0-0.2,0-0.3l0.2,0
        c-0.1,0.2,0,0.5,0.1,0.7l0,0c0-0.2,0-0.5,0.3-0.5c0.4-0.2,0.1-0.7,0-1c0.3,0.2,0.4,0.6,0.7,0.8c0.1-0.3-0.3-0.5-0.3-0.8
        c-0.1,0-0.3-0.1-0.4-0.1l0.1-0.2l-0.2,0c0-0.1,0-0.2-0.1-0.2c-0.1,0.1-0.1,0.2-0.2,0.4c0.1,0.1,0.2,0.3,0.3,0.5
        c-0.2-0.2-0.3-0.4-0.5-0.6c-0.1-0.1-0.2-0.2-0.3-0.3l0.4,0c-0.2-0.4-0.8-0.2-0.9-0.7c-0.1,0-0.2-0.1-0.3-0.2c-0.1,0-0.3,0.1-0.4,0.1
        c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.5,0.2-0.3c0.3,0.1,0.2,0.4,0.1,0.7c0-0.1,0.1-0.2,0.1-0.2c0.2,0.2,0.4,0.4,0.6,0.5
        c0-0.3-0.4-0.4-0.5-0.6c0.1-0.3,0.2-0.6,0.2-0.9c0,0-0.1,0-0.2,0c0,0.2,0.2,0.4,0,0.5c-0.3-0.1-0.5-0.6-0.9-0.5
        c-0.3,0-0.6,0.1-0.6-0.3c0.1,0,0.3,0,0.4,0c-0.2-0.2-0.4-0.2-0.6-0.2c0,0.2,0.1,0.4,0.1,0.5c-0.2-0.1-0.3-0.3-0.3-0.5l-0.3-0.1
        c0.2,0,0.3-0.1,0.5-0.1c-0.1-0.1-0.2-0.3-0.2-0.4c-0.1,0.2-0.2,0.3-0.2,0.5c-0.1-0.2-0.1-0.5-0.2-0.7c0.3,0,0.2-0.2,0.1-0.4
        l-0.1,0.2c-0.1,0.1-0.5,0.3-0.1,0.4l0,0.1c-0.1,0-0.2-0.1-0.3-0.1c0-0.1,0.1-0.3,0.1-0.4c-0.4,0.2-0.7-0.4-0.5-0.7
        c0.1,0.2,0.3,0.4,0.4,0.5c0-0.2,0.1-0.4,0.1-0.6c-0.1,0-0.2,0.1-0.2,0.1c-0.3-0.2-0.6-0.4-0.9-0.4c-0.1-0.3-0.5-0.4-0.8-0.6
        c0.2-0.1,0.4-0.1,0.5-0.2c0.1,0.3,0.6,0.7,0.9,0.3c-0.1,0-0.2,0-0.3,0c-0.1-0.1-0.3-0.2-0.4-0.2c0-0.1-0.1-0.2-0.1-0.3
        c-0.2,0.7-0.6-0.4-0.8,0.2c0.1-0.2,0.1-0.3,0-0.5c-0.1,0.1-0.2,0.2-0.3,0.3c0-0.1,0.1-0.3,0.1-0.5l-0.4,0.2
        c-0.1-0.3,0.4-0.3,0.6-0.4c-0.2-0.2-0.3-0.2-0.4,0.1c-0.2-0.3-0.3-0.3-0.3,0c-0.1-0.2-0.2-0.4-0.1-0.7l-0.2,0.1l0-0.2l-0.2-0.2
        c0.1-0.1,0.2-0.1,0.3-0.2c-0.3-0.1-0.5,0.2-0.8,0.3c0-0.1-0.1-0.2-0.1-0.3c-0.3,0-0.5-0.2-0.4-0.5c0.3,0.2,0.6,0.3,0.8,0.5
        c0-0.1,0.1-0.2,0.1-0.2c-0.4-0.1-0.9-0.3-1.1-0.8c0,0.1-0.1,0.3-0.2,0.4c0.1,0,0.3,0,0.4,0.1c-0.2,0.4-0.8,0.1-0.6-0.3
        c-0.1,0-0.3,0-0.3,0c0.1-0.2,0.1-0.5,0.4-0.6c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.2,0.1-0.2,0.2l0.3,0.1c-0.1,0.1-0.2,0.3-0.3,0.4
        c-0.1-0.2-0.2-0.4-0.4-0.6c0,0.1,0,0.3,0,0.4c-0.2,0-0.1-0.3-0.2-0.4c-0.2-0.1-0.5,0-0.5-0.3c0.3-0.2,0.4,0.4,0.7,0.2
        c0.1-0.3-0.3-0.2-0.5-0.3c-0.1-0.2-0.3-0.5-0.6-0.3c0-0.5-0.8-0.3-0.4-0.8c-0.2,0.1-0.4,0.1-0.6,0.1c-0.2-0.2-0.3-0.3-0.5-0.4
        c0.2-0.1,0.5-0.1,0.7,0c-0.1,0.2,0.1,0.4,0.3,0.4c-0.2-0.2-0.5-1.1-0.9-0.5c-0.1-0.1-0.2-0.3-0.2-0.4c-0.1,0-0.4,0.1-0.5,0.1
        c0-0.5-0.6-0.3-0.8-0.7c0.2,0,0.4,0,0.5-0.1c-0.3-0.1-0.7,0.3-1-0.1c0,0.1,0.1,0.3,0.1,0.4l-0.1,0c-0.1-0.1-0.2-0.2-0.3-0.4l0.3,0.1
        c0-0.5-0.5-0.2-0.7-0.5c-0.1,0.1-0.2,0.2-0.3,0.2c0,0-0.1-0.1-0.1-0.2c0.2,0,0.4-0.1,0.6-0.1l-0.1-0.3c-0.2,0.1-0.5,0.1-0.8,0.1
        l0-0.1l0.3,0c-0.3-0.2-0.2-0.6-0.3-0.8c-0.1,0.1-0.2,0.4-0.3,0.5c0.1,0.1,0.1,0.2,0.2,0.3c-0.1,0-0.2,0.1-0.3,0.1
        c-0.1-0.2,0-0.4-0.1-0.6c-0.2,0.2-0.4,0.2-0.6,0c0.1,0,0.3,0,0.4,0c0.1-0.2,0.1-0.3,0.2-0.4c-0.1,0-0.2-0.1-0.2-0.1
        c0,0.1,0,0.3-0.1,0.4c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0.1-0.3,0.1-0.4,0.2c0-0.1-0.1-0.3-0.1-0.4c-0.2-0.1-0.5-0.2-0.7-0.3
        c0.1-0.1,0.2-0.2,0.3-0.3c-0.2,0-0.4,0-0.7,0c0.1,0.2,0.3,0.3,0.4,0.5c-0.3,0.4-0.3-0.4-0.7-0.4c0.2-0.2,0.4-0.3,0.5-0.5
        c-0.3,0.1-0.7,0.5-0.8,0.1l0.3-0.1c-0.1-0.1-0.1-0.1-0.2-0.2l0,0.2c-0.2,0-0.6,0-0.7,0c0.1-0.1,0.2-0.3,0.2-0.3
        c-0.1,0-0.4,0-0.5,0.1c0,0,0-0.1,0-0.1c-0.5-0.1-0.8-0.6-1.3-0.8c-0.3,0-0.8,0.2-0.6-0.4c-0.1,0.1-0.1,0.2-0.2,0.4
        c-0.2-0.1-0.4-0.2-0.5-0.3l0.1-0.2c-0.1,0.1-0.3,0.4-0.4,0c-0.3,0.1-0.5,0-0.8,0c0-0.3-0.1-0.5-0.1-0.8c-0.2,0.1-0.1,0.4-0.1,0.6
        c-0.1,0-0.2,0-0.2,0c-0.1-0.3-0.3-0.2-0.5-0.1c0-0.1,0-0.2,0-0.3c-0.3,0-0.5,0-0.8-0.2c0-0.1-0.1-0.2-0.2-0.2
        c-0.2,0.3,0,0.7,0.3,0.7c0,0,0,0.1,0,0.1c-0.2-0.3-0.6-0.1-0.8-0.3c0.1,0,0.3-0.1,0.4-0.1c-0.2-0.2-0.4-0.3-0.6-0.5
        c-0.5-0.2-0.9-0.3-1.4-0.5c0,0.1,0,0.3,0,0.4c-0.3-0.1-0.5-0.5-0.9-0.5c0.1,0.1,0.3,0.3,0.4,0.3c-0.2,0-0.3,0-0.4-0.1
        c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0.1-0.2,0.2-0.2,0.3c0-0.1-0.1-0.3-0.1-0.4c-0.1,0-0.2,0.1-0.3,0.1l-0.1-0.2c-0.3,0.1-0.4,0.2-0.5,0.5
        c0.1-0.3,0.1-0.5,0.2-0.8C170.7,213,170.5,213,170.4,213 M247.4,212.9L247.4,212.9L247.4,212.9L247.4,212.9L247.4,212.9
        M477.2,212.9C477,213.3,477.7,212.9,477.2,212.9 M97,213c0,0.1,0,0.3,0.1,0.4c-0.2,0.1-0.2,0.3,0,0.4C97.3,213.5,97.3,213.2,97,213
        M167.8,213.1c0,0.1-0.1,0.2-0.2,0.2c0.2,0.1,0.3,0.2,0.5,0.3c-0.1,0.2-0.3,0.2-0.5,0.1c0.2,0.1,0.2,0.3,0,0.5c0,0.1,0,0.2-0.1,0.2
        c0.3,0,0.4-0.4,0.3-0.6c0.2,0,0.3-0.2,0.4-0.4c0.1,0.2,0.2,0.3,0.2,0.5c0.1,0,0.2,0,0.2,0c0,0.1-0.1,0.2-0.1,0.3
        c0.2,0.3,0.2,0.7,0.2,1c0,0,0.1-0.1,0.2-0.1c0-0.1,0.1-0.3,0.1-0.4c0.1,0.1,0.2,0.2,0.3,0.3l-0.3,0c0,0.1,0.1,0.4,0.1,0.5
        c-0.1,0-0.3,0-0.3,0l-0.1-0.2c0,0-0.1,0.1-0.2,0.1c0,0-0.1,0.1-0.1,0.2c0,0,0.1,0,0.2,0l0-0.2c0.2,0.1,0.4,0.2,0.6,0.2
        c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.4,0.2-0.3c0.1,0.3,0.2,0.6,0.1,0.9c0.2,0,0.4,0,0.6,0c-0.1-0.1-0.2-0.3-0.3-0.4
        c-0.1-0.2-0.3-0.4-0.5-0.6c0-0.1,0-0.2,0-0.4c-0.2-0.2-0.5-0.4-0.5-0.7c0,0-0.1-0.1-0.2-0.1c-0.1-0.2-0.2-0.5-0.4-0.6
        C168.2,213.2,168,213.2,167.8,213.1 M26.6,213.4c0.2-0.1,0.2-0.2,0-0.4C26.4,213.2,26.4,213.3,26.6,213.4 M172.3,213.2
        C172.3,213.3,172.3,213.3,172.3,213.2 M247,213.7C247.1,213.8,247.1,213.8,247,213.7 M96.6,213.9C96.6,214.3,97.1,213.7,96.6,213.9
        M296.4,214.1c-0.1,0-0.2,0-0.2,0c-0.2,0.4-0.5,0.7-0.8,1c-0.2,0.2-0.5,0.3-0.7,0.5c0-0.1-0.1-0.2-0.1-0.2c0.2,0.4-0.2,0.7-0.4,0.9
        c0,0,0,0.1,0,0.2c-0.2,0-0.5,0-0.4,0.4l-0.1-0.1c-0.1,0.2-0.3,0.5-0.5,0.5c0,0.3-0.1,0.4-0.3,0.2c0,0.1,0,0.3,0,0.4
        c-0.1-0.1-0.2-0.2-0.3-0.2l0.1,0.2c-0.2,0-0.5,0-0.5,0.3c-0.2,0-0.3,0.1-0.2,0.3c-0.3,0.1-0.5,0.4-0.8,0.7l0.3,0
        c-0.1,0.1-0.3,0.2-0.4,0.3c0,0.1,0.1,0.2,0.1,0.2c-0.1,0.1-0.3,0.2-0.4,0.2c0.1,0.1,0.2,0.2,0.4,0.3c-0.2,0.1-0.3,0.2-0.5,0
        c-0.1,0.2-0.3,0.3-0.4,0.4c0.1,0.4-0.4,0.6-0.7,0.9c-0.3,0.1-0.1,0.6-0.4,0.6c0,0.1,0.1,0.2,0.1,0.3c-0.1,0-0.3,0-0.4,0
        c0.1,0.2,0,0.4-0.1,0.6c-0.3,0.1-0.6,0.3-0.5,0.7l-0.2,0c0,0.1,0,0.3,0.1,0.4c-0.2,0.2-0.5,0.3-0.6,0.5c0.1,0,0.3,0,0.4,0
        c-0.1,0.1-0.2,0.3-0.3,0.4c-0.4-0.2-0.5,0.2-0.6,0.5l0.3-0.1c0,0,0,0.1,0,0.2c-0.1,0-0.3,0-0.4,0c0.1,0.7-0.2,1.4-0.1,2.1
        c0,0-0.1,0-0.2,0l0.2,0c-0.1,0.4-0.2,0.8-0.2,1.2c0.1,0.1,0.2,0.4,0.2,0.5l-0.3,0c0.3,0.5,0.1,1,0.1,1.5c0.1,0.7,0,1.4,0,2.1
        c0.1,0.7-0.1,1.4,0,2.1c-0.2,0.4-0.1,0.9,0,1.3c0,0.4,0,0.7,0,1.1c0.6-0.3,1-0.9,1.7-1.2c0.1-0.1,0.1-0.3,0.2-0.4l0.1,0l0-0.2
        c0.1,0,0.3,0,0.5-0.1c0-0.1,0-0.2,0-0.3c0.1,0,0.2,0.1,0.3,0.1l0-0.2c0.2,0,0.4-0.2,0.6-0.3l0-0.1c0.4,0.1,0.6-0.3,0.9-0.5
        c0-0.3,0.2-0.3,0.4-0.3c0.1-0.2,0.3-0.4,0.6-0.4c0,0-0.1-0.1-0.1-0.2c0.3,0,0.4-0.1,0.4-0.4c0.1,0.1,0.2,0.3,0.2,0.4
        c-0.1,0.1-0.3,0.3-0.4,0.4c-0.8,0.6-1.6,1.1-2.3,1.7c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.3,0.2-0.4,0.3c-0.3,0.4-0.9,0.6-1.2,0.9
        c-0.3,0.3-0.8,0.3-1,0.7c0.4,0.3,0,0.8,0.2,1.2c0.1,1.3,0.1,2.6,0.1,3.8c0.1,0.7-0.1,1.4,0.2,2.1c0.1,0.2-0.1,0.4-0.2,0.6
        c0.2,0.4,0.2,0.8,0,1.2c0.3,0.3,0.1,0.8,0.2,1.2c0.6-0.4,1.3-0.8,2-0.7c-0.1,0.4-0.7,0.6-0.8,1c-0.1,0-0.2,0-0.2-0.1l0.1,0.2
        c-0.1,0-0.3,0.1-0.4,0.1l0.3,0c-0.1,0.2-0.3,0.3-0.5,0.5c0.2-0.1,0.5-0.2,0.6-0.3c-0.2,0.2-0.5,0.5-0.7,0.8c0,0.1,0,0.2,0,0.3
        c0.1,0.2,0.1,0.4,0,0.6c0.1-0.1,0.3-0.2,0.4-0.3c-0.1,0.1-0.2,0.3-0.2,0.3c0.2,0,0.4,0,0.5,0.2c-0.1,0-0.4,0-0.6,0
        c0.2,0.3,0.4,0.4,0.7,0.3c-0.1,0.1-0.3,0.2-0.4,0.3c0.3-0.2,0.5,0.1,0.7,0.3c0.1,0.1,0.3,0.1,0.4,0.2c0,0.1-0.1,0.3-0.1,0.5
        c-0.3,0.2-0.6,0-0.9-0.1c0.1,0.1,0.2,0.2,0.3,0.3c-0.2,0-0.5,0-0.7,0l0-0.3l0.2,0c-0.1-0.1-0.3-0.1-0.4-0.2c0,0.1,0.1,0.3,0.1,0.4
        c-0.1,0-0.3,0-0.4,0c-0.1,0.2,0.1,0.6,0.3,0.5c0.3-0.2,0.7-0.1,1-0.1c0,0,0,0,0,0.1c-0.3,0.1-0.6,0.3-0.9,0.5c0,0.1,0,0.3,0,0.4
        l-0.1,0c0,0.3,0,0.6,0,0.9c0,0-0.1,0-0.2,0c0,0.3,0.1,0.5,0,0.8c0.1,0.2,0.1,0.5,0.4,0.5c0,0,0,0.1,0,0.2l0.3,0
        c-0.1,0.1-0.2,0.3-0.3,0.4c0.1,0,0.3-0.1,0.4-0.2l-0.1,0.3c0.1,0,0.2,0,0.3,0.1l0.3,0.2c-0.3,0-0.6,0.1-0.9,0.2
        c0-0.1-0.1-0.2-0.1-0.3c0,0.1,0,0.2,0,0.3c-0.4,0-0.4,0.4-0.2,0.7l-0.2,0c0.1,0.2,0.3,0.2,0.5,0c-0.1,0.3-0.4,0.4-0.5,0.7
        c0.1,0,0.3-0.1,0.4-0.1c-0.4,0.6,0,1.5,0.7,1.7c-0.3,0-0.5,0.2-0.8,0.3c0.6-0.2,1,0.4,1.4,0.8c-0.1,0-0.2,0-0.3,0c0,0,0,0.1,0,0.2
        c-0.3-0.3-0.6,0-0.9,0.1c0,0.1,0,0.2,0,0.2c-0.1,0-0.2,0-0.3,0c0,0.3-0.1,0.7,0.1,0.9c-0.1,0.4,0,1,0.4,1.2c0.1,0.2,0.2,0.5,0.3,0.7
        c0.2,0.2,0.4,0.3,0.4,0.6L288,265c0.2,0.4-0.3,0.5-0.4,0.1c0,0.1,0,0.3,0,0.3c-0.2-0.1-0.3-0.2-0.5-0.2c0,0.1,0,0.3,0,0.4
        c-0.1,0.1-0.2,0.3-0.2,0.4c0.1,0.2,0.2,0.4,0.2,0.6c-0.1,0.9,0.1,1.7,0,2.6c0.3-0.3,0.7-0.5,1-0.8c0.8-0.3,1.2-1.2,1.9-1.6
        c0,0.1,0.1,0.2,0.1,0.3c-0.1-0.3,0-0.5,0.3-0.4c0.4-0.3,0.8-0.8,1.3-1.1c0-0.1,0.2-0.2,0.2-0.3c0.2-0.1,0.5-0.1,0.5-0.4
        c0.7-0.3,1.3-0.9,1.9-1.5c0.1-0.2,0.3-0.2,0.6-0.3c0-0.1,0.1-0.2,0.1-0.3c0.1,0,0.3,0,0.5-0.1c0-0.3,0.3-0.4,0.6-0.5
        c0.2-0.1,0.3-0.3,0.5-0.4c0.7-0.4,1.2-1,1.8-1.5c-0.2-0.3-0.1-0.6-0.3-0.9c-0.1-0.2-0.1-0.6-0.4-0.7c0.1-0.3,0-0.6-0.3-0.8
        c0-0.2,0-0.4,0.1-0.5c-0.5-0.3-0.3-0.9-0.7-1.3c0.2-0.5-0.7-0.8-0.2-1.3c-0.1-0.1-0.4-0.2-0.4-0.4c-0.1-0.8-0.5-1.6-0.6-2.4
        c-0.1-0.6-0.2-1.2-0.5-1.8c0-0.1,0.1-0.2,0.1-0.2c-0.4-0.9-0.8-1.8-0.9-2.7c-0.3-0.7-0.3-1.5-0.5-2.2c0-0.1,0.1-0.2,0.1-0.4
        c-0.1,0-0.2,0-0.3,0c0-0.4,0-0.7-0.1-1.1l0.2,0c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0.1-0.2c-0.3-0.3-0.6-0.8-0.3-1.1
        c-0.2-0.4-0.2-0.8-0.2-1.2c-0.1,0-0.2,0.1-0.3,0.1c0.5-0.1-0.1-0.7,0.2-1.1c-0.4-0.1-0.2-0.4-0.1-0.6c-0.1,0-0.2-0.1-0.2-0.1
        c0-0.4,0.1-0.8,0-1.2c-0.1-0.2-0.3-0.4-0.1-0.6c-0.3-0.3,0.3-0.6,0-0.9c-0.3-0.2,0.2-0.5-0.1-0.6c0-0.2,0.1-0.4,0.1-0.6
        c-0.1,0-0.2,0.1-0.3,0.2c0.1-0.1,0.2-0.1,0.3-0.2c-0.2-0.5-0.3-1,0.1-1.4c0-0.1-0.1-0.2-0.2-0.2c0.1-0.2,0.1-0.4,0.1-0.6
        c-0.2,0.2-0.4,0.5-0.7,0.7c-0.1-0.4,0.3-0.8,0.7-1.1c0.1-0.4,0-0.8,0.3-1c-0.1-0.4-0.2-1,0.3-1.3c-0.1-0.2-0.2-0.3-0.2-0.5
        c0.1-0.2,0.3-0.3,0.4-0.5c-0.1-0.1-0.2-0.2-0.3-0.3c0.2-0.2,0.3-0.5,0.1-0.8c0.1-0.6,0.8-1.2,0.4-1.8c0.1-0.2,0.3-0.3,0.3-0.6
        c0-0.5,0.3-0.9,0.5-1.3c0.1-0.6,0.3-1.2,0.5-1.8c0.3-0.6,0.8-1.1,1-1.8c0.3-0.3,0.4-0.7,0.6-1c0.3-0.6,0.7-1.2,1-1.9
        c0.2-0.6,0.9-0.9,0.8-1.6c-0.8-0.9-1.3-2-2.3-2.8C296.4,214,296.4,214.1,296.4,214.1 M96.3,214.2c-0.1,0.2,0,0.3,0.2,0.2
        C96.6,214.2,96.6,214.1,96.3,214.2 M164.3,214.1c0,0.2,0.3,0.5,0.5,0.6C164.8,214.4,164.5,214.1,164.3,214.1 M168.1,214.4
        c0.1,0.2,0.2,0.4,0.3,0.6c0-0.3-0.1-0.5-0.2-0.7l0.2,0v-0.2C168.4,214.2,168.2,214.3,168.1,214.4 M320.2,214.2
        c-0.1,0.3,0.2,0.5,0.3,0.7C320.5,214.6,320.7,213.9,320.2,214.2 M418.2,214.2c-0.2,0.2,0.2,0.4,0.4,0.3
        C418.6,214.3,418.3,214.1,418.2,214.2 M281.2,214.5c0,0.1-0.1,0.2-0.1,0.2C281.3,215.2,281.6,214.3,281.2,214.5 M242.9,215.2
        c0.2,0,0.6-0.3,0.4-0.5C243,214.6,242.8,215,242.9,215.2 M476.2,214.6c-0.1,0.1-0.3,0.2-0.4,0.4l0.4,0.2l-0.1-0.3l0.2,0
        c0,0.1,0,0.2,0,0.3c0.1,0,0.2-0.1,0.3-0.1C476.4,214.9,476.3,214.7,476.2,214.6 M333.3,215c-0.1,0.2,0,0.3,0.2,0.2
        C333.6,215,333.5,214.9,333.3,215 M335.1,215L335.1,215c0.3,0.5-0.4,0.4-0.6,0.3c-0.2,0.1-0.4,0.2-0.5,0.4c-0.3,0.2-0.5,0.4-0.8,0.5
        c0.1,0.1,0.2,0.2,0.2,0.3c-0.2-0.1-0.4-0.2-0.6-0.3c0.1,0.3-0.2,0.5-0.2,0.8c0-0.1-0.1-0.4-0.1-0.5c-0.2,0.1-0.3,0.1-0.4-0.1
        c0,0.1,0.1,0.3,0.1,0.4c-0.1,0-0.3,0-0.4,0c0.2,0.2,0.2,0.4-0.1,0.5c-0.1,0.1-0.2,0.3-0.4,0.4c0-0.3,0.2-0.5,0.4-0.7
        c-0.2,0-0.4,0-0.6,0c0.1,0.1,0.2,0.2,0.2,0.3c-0.2,0.1-0.5,0.2-0.7,0.3c0,0.1-0.1,0.3-0.1,0.4c-0.3-0.1-0.6-0.3-0.7,0.1
        c-0.1-0.1-0.2-0.3-0.3-0.4c0,0.2,0.1,0.5,0.1,0.6c-0.1,0-0.4,0-0.5,0c0-0.1,0-0.2,0-0.3l-0.2,0c0.2,0.5-0.4,0.7-0.6,1.1
        c0-0.1,0-0.2-0.1-0.3l-0.1-0.1c-0.1,0.3-0.1,0.8-0.6,0.6c0.2,0.1,0.5,0.3,0.1,0.4c0,0-0.1,0.1-0.1,0.2l-0.2,0l0.3-0.1
        c-0.1-0.1-0.1-0.2-0.2-0.2c-0.2,0.4-0.6-0.2-0.9,0.2c0.1,0,0.3,0,0.4,0c0,0.3-0.5,0.3-0.7,0.4c-0.2-0.1-0.3-0.2-0.5-0.3
        c0,0.3,0.4,0.4,0.6,0.6c-0.2,0-0.5,0-0.7-0.1c0.2,0.4-0.3,0.3-0.4,0.1c-0.2,0.3-0.7,0.6-0.5,1.1c-0.2,0.1-0.5,0.2-0.4,0.5
        c-0.1,0-0.3-0.1-0.4-0.2c-0.2,0.2-0.2,0.4-0.2,0.6l-0.3,0.1l0.1-0.3c-0.1,0-0.2,0-0.3,0c0,0.4-0.5,0.5-0.6,0.8
        c-0.1-0.1-0.2-0.2-0.2-0.3c0,0.1,0,0.3,0,0.5c-0.1,0-0.3,0-0.4,0c0.1,0,0.2,0.1,0.2,0.2c-0.1,0.1-0.2,0.2-0.4,0.3
        c-0.1-0.1-0.2-0.2-0.3-0.3l0.4,0.1c-0.1-0.2-0.1-0.3-0.2-0.5c-0.2,0.2-0.4,0.5-0.6,0.6c-0.2,0.1-0.5,0.1-0.4,0.4
        c0.3-0.1,0.7-0.2,0.8-0.5c0.1,0.1,0.2,0.3,0.3,0.4c-0.2,0-0.4,0-0.6,0c0,0.1-0.1,0.3-0.2,0.4c-0.2-0.5-0.7,0-0.5,0.3l-0.2-0.1
        c0,0.2-0.1,0.4-0.2,0.6c-0.1-0.2-0.2-0.3-0.4-0.1c-0.2-0.3-0.4-0.1-0.4,0.1c0.2-0.1,0.4,0,0.7,0c0,0-0.1,0.1-0.1,0.2
        c-0.1,0-0.3-0.1-0.4-0.1c0.1,0.1,0.2,0.3,0.2,0.3c0,0-0.1,0-0.1,0l0-0.2c-0.2,0.1-0.3,0.3-0.2,0.6c0-0.1-0.1-0.2-0.1-0.2
        c-0.2,0.2-0.2,0.6-0.5,0.8c-0.1-0.1-0.4-0.4,0-0.5l0.2-0.1c-0.1,0-0.3-0.3-0.3-0.1c-0.1,0.4-0.7,0.3-0.7,0.7
        c0.2-0.1,0.5-0.2,0.7-0.3c0,0.2-0.1,0.3-0.1,0.5c-0.2-0.1-0.4,0-0.5,0.2l-0.2-0.1c0,0.1,0,0.4,0.1,0.5c-0.3,0.3-0.4,0.7-0.8,0.9
        c0.1-0.2,0.2-0.4,0.3-0.5l-0.2-0.1c0-0.1-0.1-0.3-0.1-0.4l-0.1,0c-0.1,0.1-0.2,0.3-0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.3
        c-0.1,0.2-0.2,1-0.5,0.6c0.1-0.1,0.3-0.2,0.4-0.4c-0.3-0.1-0.4,0.2-0.4,0.4c-0.2,0-0.4,0.1-0.6,0.2c0.1,0,0.3,0,0.4,0
        c0,0.2-0.3,0.3-0.4,0.4l0.4,0.1c-0.3,0.4-1.1,0.1-1,0.7c-0.2,0.2-0.3,0.3-0.5,0.5c-0.1,0.3-0.4,0.5-0.7,0.5c0.2,0.4-0.3,0.7-0.5,0.9
        c0.1-0.2,0.2-0.4,0.3-0.6c-0.1-0.2-0.3-0.1-0.4,0c0,0.3-0.1,0.5-0.2,0.7l0.3-0.1c0,0,0,0.1,0,0.1c-0.3-0.1-0.4,0.4-0.7,0.4
        c0.1-0.1,0.1-0.3,0.2-0.4c-0.1-0.3-0.4-0.1-0.5-0.1l0-0.3c-0.2,0.2-0.3,0.4-0.5,0.6c0.2,0,0.3-0.4,0.5-0.3c0.1,0.2-0.2,0.4-0.3,0.6
        c-0.2,0-0.3,0.2-0.4,0.4c0.3,0,0.5-0.2,0.6-0.4c0.1,0,0.2,0,0.3,0.1c-0.3,0.3-0.6,0.6-1.1,0.5c0,0,0,0.1,0,0.2
        c-0.3,0-0.4,0.1-0.5,0.4c0.2-0.1,0.4-0.2,0.7-0.3c-0.2,0.2-0.3,0.4-0.6,0.4c0,0.1,0.1,0.2,0.1,0.2l-0.3-0.1c0.2,0.3,0.2,0.5-0.2,0.3
        c0,0.1,0,0.2,0,0.3c-0.1,0-0.3,0-0.4,0c0.1,0.2,0.2,0.3,0.3,0.5c-0.1-0.1-0.3-0.2-0.4-0.3c0,0.1,0,0.4,0,0.5c-0.1,0-0.3-0.1-0.4-0.1
        c0.1,0.1,0.2,0.3,0.3,0.4l-0.3,0c0.1,0.1,0.2,0.3,0.1,0.5c-0.1-0.1-0.2-0.2-0.3-0.3c0.1,0.4-0.2,0.5-0.4,0.7c0.1,0,0.2,0.1,0.3,0.1
        c-0.3,0-0.5,0.3-0.7,0.5c0.1,0,0.3,0,0.4,0c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0-0.2,0-0.2-0.1c-0.1,0.2-0.1,0.5-0.3,0.5
        c-0.1,0.4-0.5,0.8-0.6,1.2c-0.3,0.7-0.7,1.3-1,2l-0.1-0.3l-0.1,0c0,0.1,0.1,0.3,0.1,0.3l-0.2,0c0,0.3-0.1,0.7-0.1,1
        c-0.1,0-0.2,0.1-0.2,0.1c0.2,0,0.4,0.1,0.6,0.3c-0.2,0-0.5-0.1-0.7-0.1c0.1,0.2,0.3,0.4,0.4,0.5c0,0.1-0.1,0.3-0.2,0.3
        c0.1,0,0.4-0.1,0.5-0.1l0,0.3c0.4,0.3,0.6,0.8,1.1,1c0,0,0,0.1,0,0.2c-0.1,0-0.3,0-0.3,0c-0.1,0.5,0.6,0.5,0.9,0.8c0,0,0,0,0,0
        c-0.1,0-0.2,0.1-0.3,0.2l0.3,0.1c0,0.2-0.1,0.3-0.1,0.5l-0.2,0c0.2,0.1,0.4,0.1,0.6,0.2c0,0.2,0,0.4,0,0.6l0.2,0
        c0,0.3,0.3,0.6,0.3,0.9c0.1-0.3,0-0.6-0.1-0.9c0.2,0.1,0.3,0.3,0.4,0.5c0,0,0.1,0.1,0.1,0.2l-0.3,0c0.4,0.2,0.3,0.9,0.7,0.9
        c-0.1,0.1-0.2,0.2-0.3,0.3c0.2,0.3,0.5,0.6,0.5,1c-0.1,0-0.2,0-0.3,0c0,0.1,0,0.3,0.1,0.3c0-0.1,0.1-0.2,0.2-0.2
        c0.1,0.1,0.2,0.2,0.3,0.2c0.2-0.1,0.4-0.3,0.7-0.4c-0.3-0.2-0.5,0.1-0.7,0.1c0-0.1,0-0.3,0-0.4c0.1,0,0.3,0.1,0.4,0.1
        c0-0.1,0-0.3-0.1-0.4c0.1-0.1,0.3-0.1,0.4-0.2c0.2,0.4,0.5-0.1,0.4-0.4l0.2,0c0-0.2,0-0.5,0-0.7c0.1,0.1,0.1,0.3,0.2,0.4l0.3,0
        c0-0.3-0.1-0.5-0.1-0.8c0.1,0.1,0.2,0.2,0.3,0.3c0.1-0.3,0.5-0.6,0.8-0.4c0-0.2-0.1-0.4-0.1-0.6l0.3,0c-0.1-0.1-0.2-0.2-0.3-0.3
        c0.2,0,0.3-0.1,0.4-0.3c0.1,0,0.2,0.1,0.2,0.1c0.1-0.1,0.2-0.2,0.3-0.3c-0.1-0.1-0.2-0.2-0.2-0.3c0.4-0.1,0.2-0.4,0.1-0.6
        c0.2,0,0.3-0.1,0.5-0.2c0.1,0,0.2,0.1,0.3,0.1l0-0.4l-0.3,0c0.1,0,0.2,0,0.4-0.1l0-0.1l0.3,0.1c0-0.1-0.1-0.4-0.1-0.5
        c0.2-0.2,0.5-0.3,0.7-0.5c0.1,0,0.2,0,0.3,0.1c0.1-0.2,0.2-0.3,0.3-0.5c-0.2,0-0.3,0-0.4,0.2c-0.1,0-0.2,0-0.3,0
        c0.1-0.3,0.3-0.4,0.6-0.4c0-0.2,0.1-0.5,0.3-0.5c0.1,0.2,0,0.5,0.2,0.6c-0.1-0.3,0-0.6,0-0.9c0.1-0.1,0.2-0.2,0.4-0.3
        c0.1,0,0.2,0,0.3,0.1c0-0.2-0.1-0.5-0.1-0.7l0.1,0c0,0.1,0.1,0.2,0.1,0.2c0-0.3,0.1-0.6,0.4-0.7c0,0.2-0.1,0.4-0.2,0.6
        c0,0,0.1,0.1,0.1,0.1c0.2-0.4,0.1-1,0.4-1.4c0,0.1-0.1,0.4-0.1,0.6c0.3-0.1,0.4-0.4,0.5-0.6c0-0.1-0.1-0.2-0.1-0.3
        c0.2-0.1,0.5-0.2,0.7-0.4c-0.1-0.2,0-0.3,0.2-0.3l-0.1-0.1l0.1,0l-0.1-0.2l0.4,0.1c-0.1-0.1-0.2-0.4-0.3-0.5
        c0.1,0.1,0.4,0.2,0.5,0.3c-0.3-0.3-0.1-1,0.3-1c-0.1,0.2,0,0.6-0.3,0.5c0,0,0,0.1,0,0.2c0.1,0,0.3,0,0.4,0l0-0.2c0.1,0,0.4,0,0.5,0
        c-0.3-0.1-0.5-0.3-0.4-0.6c0.1,0,0.2,0,0.2,0c0-0.2,0-0.5,0-0.6l-0.3,0.1c0.1-0.1,0.2-0.2,0.3-0.3l-0.1,0.1c0.2,0,0.4,0,0.6,0
        c0-0.1-0.1-0.2-0.1-0.3c0.2,0,0.5,0.2,0.7-0.1c-0.1,0-0.4-0.1-0.5-0.2c0.1-0.1,0.3-0.2,0.4-0.3c0.1,0.2,0.3,0.3,0.5,0.3
        c-0.1-0.2-0.3-0.3-0.4-0.4c0.1-0.3,0.3-0.1,0.5-0.1c0-0.1-0.1-0.3-0.2-0.4l0.4,0.2c0-0.1-0.1-0.2-0.1-0.3c0.3-0.2,0-0.6,0.4-0.6
        c0,0.1,0,0.4,0,0.5c0,0,0.1,0,0.2,0c0-0.1,0-0.3,0-0.4c0.4,0,0.1-0.4,0.1-0.6c0.2,0,0.5,0,0.7,0c0-0.1-0.1-0.4-0.1-0.6l0.2,0
        c0,0.1,0.1,0.4,0.1,0.5c0.4-0.2-0.2-0.7,0-1c0.1-0.1,0.5-0.3,0.5,0c0.2,0.2-0.2,0.3-0.3,0.5c0.1,0,0.2,0,0.3,0
        c0.3-0.2,0.1-0.5,0.1-0.8c0.1-0.2,0.3-0.3,0.1-0.5c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.2,0.1-0.3c0,0,0.1,0,0.1,0
        c-0.1,0.2-0.2,0.4,0,0.6c0.1,0,0.3,0,0.4-0.1c0,0,0.1,0.1,0.1,0.1c-0.1-0.1-0.2-0.3-0.3-0.4c0.4,0,0.7-0.1,0.9-0.4
        c-0.1,0-0.3,0-0.4-0.1c0,0.1-0.1,0.2-0.2,0.3c-0.4,0-0.2-0.4,0.1-0.4l0.1-0.1c0.2-0.2,0.4-0.1,0.7-0.1c-0.1-0.1-0.1-0.2-0.2-0.2
        c-0.1,0-0.2,0.1-0.3,0.2c-0.1-0.2,0.2-0.3,0.3-0.5c0-0.1-0.1-0.3-0.2-0.4c0.1,0,0.3,0.1,0.4,0.1c0,0.1-0.1,0.3-0.2,0.3
        c0.1,0,0.2-0.1,0.3-0.1c0.3-0.1,0-0.3-0.1-0.5c0.1,0,0.2,0,0.2,0l0-0.3c0.2,0.2,0.3,0.4,0.5,0.5c0-0.4-0.3-0.9-0.8-0.6
        c0.1-0.2,0.2-0.3,0.5-0.2c-0.1-0.2-0.1-0.3-0.2-0.5c0.1,0.1,0.2,0.3,0.3,0.4c0,0.1,0.1,0.2,0.1,0.3c0.2-0.1,0.2-0.3,0.1-0.5
        c0.1,0,0.2,0,0.3,0c0-0.1,0-0.2,0-0.2c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1-0.4,0.4-0.3,0.6-0.4c-0.1-0.4,0.2-0.8,0.6-0.6
        c-0.1-0.1-0.2-0.4-0.3-0.5l0.4,0.2c0-0.3,0-0.6,0.1-0.9c0.2,0,0.3,0,0.5,0c0.1-0.1,0.2-0.2,0.3-0.3c-0.1-0.1-0.3-0.3-0.3-0.4
        c0.2,0,0.5,0,0.7,0.1c0,0.1,0,0.4,0,0.5c0.2-0.2,0.1-0.5,0.1-0.8c-0.1,0-0.2,0.1-0.3,0.2c-0.1-0.1-0.2-0.2-0.2-0.3
        c0.1,0,0.3,0,0.4,0c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0.1-0.2,0.2-0.3c0,0.3,0.2,0.5,0.3,0.7c0-0.2-0.1-0.4-0.3-0.6
        c0.1,0,0.3-0.1,0.4-0.2c0-0.1,0-0.2,0-0.3l0.2,0c0.2-0.3,0.5-0.3,0.7-0.5c-0.1,0-0.3,0-0.5,0.1c0.1-0.2,0.2-0.4,0.3-0.6
        c0.1,0,0.2,0,0.3,0c-0.1-0.2-0.3-0.4-0.4-0.7c0.2,0,0.4,0.1,0.5,0.2c0-0.1,0-0.4,0-0.5c0,0,0.1,0,0.1,0c0-0.3,0.5-0.2,0.3-0.6
        c0.1-0.2,0.2-0.4,0.4-0.5c0,0.2,0,0.5-0.2,0.7c0.2-0.1,0.3-0.2,0.4-0.4c-0.1-0.2-0.2-0.4-0.3-0.5c0.1,0,0.3-0.1,0.4-0.1
        c0,0.1,0.1,0.2,0.1,0.3c0-0.1,0-0.3-0.1-0.4c0.2,0,0.4-0.1,0.6-0.1c0-0.2,0.1-0.3,0.1-0.5l0.2,0.1c0,0,0-0.1,0-0.1
        c-0.1,0-0.3-0.1-0.3-0.1c0,0.2,0,0.3,0,0.5c-0.3-0.2-0.5-0.9,0.1-0.9c0-0.1-0.1-0.2-0.1-0.3c0.2,0.1,0.4,0.1,0.6,0.2
        c-0.1-0.3-0.2-0.6-0.2-0.9c0.1,0,0.2-0.1,0.3-0.2c0.1-0.3,0.2-0.6,0.5-0.7c0,0.2-0.2,0.4-0.3,0.6c0.2,0,0.4-0.1,0.6-0.2
        c-0.2-0.2-0.3-0.5-0.5-0.7c0.1-0.1,0.2-0.2,0.3-0.3c0.2,0,0.4,0,0.6,0.1l0-0.2c0.3-0.1,0.5-0.3,0.8-0.4c-0.1,0-0.4,0.1-0.5,0.1
        c-0.2-0.4,0.2-0.6,0.5-0.8c0.2-0.4,0.1-1,0.6-1.1v0.3c0.1-0.4,0.2-0.7,0.3-1.1c-0.2,0.1-0.3,0.3-0.4,0.4c0,0.3-0.1,0.4-0.4,0.4
        C335.3,215.6,335.4,215.2,335.1,215 M486.9,215L486.9,215l0.1,0.2l0.1-0.1l0-0.1C487.1,215,487,215,486.9,215 M38.8,215.3
        c0.1,0.2,0.2,0.2,0.3,0C39,215.1,38.9,215.1,38.8,215.3 M475.6,215.1c-0.1,0.2,0,0.3,0.3,0.3C475.9,215.2,475.9,215.1,475.6,215.1
        M155.1,215.8l0.3,0.2c-0.2,0.1-0.4,0.1-0.3,0.4c-0.2,0-0.3,0.1-0.5,0.1c0-0.1,0.1-0.3,0.1-0.4c-0.3,0.1-0.4,0.2-0.3,0.5
        c-0.1,0-0.4-0.1-0.6-0.1c0.2-0.2,0.6-0.2,0.5-0.5c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2-0.1-0.3-0.1-0.5,0c-0.3,0.1,0.2,0.3,0,0.4
        c-0.4,0.2-0.8,0.4-1.2,0.4c-0.1,0.3-0.4,0-0.6,0l0,0.2c0-0.3-0.6-0.6-0.5-0.2c0.1,0,0.2,0,0.3,0c-0.1,0.1-0.1,0.3-0.2,0.4
        c-0.1-0.2-0.3-0.3-0.3-0.6c-0.2,0.1-0.4,0.1-0.5,0.2c-0.1,0.5,0.3-0.2,0.5,0.1c-0.2,0.4-0.8,0.3-1.1,0.4c0-0.1,0.1-0.3,0.1-0.4
        c-0.1,0.3-0.5,0.3-0.5,0.6c0.2,0,0.5,0,0.6-0.1c-0.1,0.1-0.3,0.2-0.5,0.3c-0.1-0.3-0.3-0.3-0.5,0c0-0.1,0-0.3-0.1-0.3
        c-0.2,0.3-0.9,0.4-0.6,0.9c-0.1-0.1-0.2-0.2-0.3-0.3c0,0.4-0.2,0.5-0.5,0.5l0,0.1c-0.2,0-0.7,0.1-0.5,0.4c0-0.1,0-0.3,0-0.4
        c-0.4,0.1-0.9,0.5-0.8,1c-0.1,0-0.3-0.1-0.4-0.1c0.1,0.2,0.1,0.3,0.2,0.5c-0.1-0.1-0.2-0.2-0.3-0.3c0,0.1-0.1,0.3-0.2,0.4
        c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0-0.4,0-0.6-0.1c0,0.1,0.1,0.3,0.1,0.3l-0.2,0c0,0.1,0.1,0.2,0.1,0.3c-0.3-0.1-0.8-0.2-0.9,0.2
        c0.1,0,0.4,0,0.5,0c-0.5,0.3-0.9,0.7-1.1,1.3c-0.1-0.1-0.2-0.2-0.3-0.2c0,0.1-0.1,0.3-0.2,0.4c0.5,0,0.1,0.5-0.2,0.6
        c0.1-0.2,0.1-0.4,0.2-0.6c-0.5,0.2-0.4,0.7-0.5,1.1c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0.1-0.2,0.3-0.2,0.3c0.1,0.1,0.1,0.3,0.2,0.4
        c-0.1,0-0.3,0-0.5,0.1c0,0.2,0.1,0.4,0,0.6c-0.1,0-0.3,0-0.4,0c0.1,0.3,0,0.6,0,0.9c-0.3,0.2-0.1,0.7-0.3,1c0.1,0,0.2,0,0.3,0
        c0,0.1,0,0.2,0,0.3l0.2,0c0,0.2,0,0.3,0,0.5c0.4,0.2,0.3,0.6,0.5,0.9c-0.2-0.1-0.3-0.1-0.4-0.2c0,0.1,0,0.2,0,0.2c0.2,0,0.3,0,0.5,0
        c0-0.1,0-0.3-0.1-0.3c0.1,0.1,0.2,0.2,0.4,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c0.2,0,0.4,0.2,0.5,0.3c0,0.1-0.1,0.2-0.1,0.3
        c0.3,0.1,0.6,0.3,0.8,0.5l0.2-0.1c-0.3,0.4,0.2,0.5,0.4,0.8c0,0-0.1,0.1-0.2,0.1c0.1,0,0.3,0,0.4,0c0,0.1,0,0.3,0.1,0.4
        c0.2,0,0.4-0.2,0.5-0.3c-0.1,0-0.2-0.1-0.3-0.1c0-0.1-0.1-0.3-0.1-0.3l0.1,0c0.2,0.2,0.4,0.4,0.7,0.6c-0.1,0-0.2,0.1-0.3,0.2
        c0.1,0.4,0.4,0.6,0.7,0.9c0,0.1,0.1,0.2,0.1,0.3c0-0.1,0-0.3,0-0.4c0.1,0,0.2,0,0.3,0c-0.2,0.4,0.4,0.5,0.7,0.6l0,0.2
        c0.2-0.1,0.5,0,0.6,0.2c0.1,0,0.2,0,0.3,0c0,0.1-0.1,0.4-0.1,0.6c0.1-0.1,0.3-0.2,0.4-0.2c0,0.2-0.1,0.4-0.1,0.5
        c0.2-0.2,0.4-0.3,0.6-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0,0.1,0,0.2,0,0.3c-0.2,0-0.4,0-0.6,0l0,0.2c0.4,0.1,0.8-0.5,1-0.1
        c-0.1,0-0.3,0.1-0.4,0.1c0.1,0.1,0.3,0.3,0.4,0.5c0-0.1,0-0.2,0-0.3c0.2,0.1,0.6,0.7,0.8,0.3c0.1,0.1,0.2,0.2,0.4,0.4
        c-0.1,0-0.4,0-0.5,0c0.2,0.5,0.9,0.1,1.2,0.5c0.3,0.1-0.1,0.3-0.1,0.5c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.3-0.2,0.4-0.2
        c-0.2,0.3-0.3,0.6-0.2,0.9c0.2,0,0.1-0.2,0.1-0.4c0.1-0.2,0.3-0.3,0.4-0.5c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.3,0.2,0.4,0.3
        c0.1-0.1,0.3-0.2,0.4-0.3c0,0.2-0.1,0.3-0.3,0.3l0,0.2c0.3,0,0.7,0,1,0.1c0.2,0.3,0.6,0.2,0.7,0.5l0,0.1c-0.1,0-0.4,0-0.5,0
        c0,0,0,0.1,0,0.1c0.3,0.1,0.5,0.2,0.8,0.1c0.1,0.3,0.5,0.3,0.7,0.4c0.1-0.1,0.1-0.1,0.2-0.2c-0.2,0-0.3-0.1-0.3-0.3
        c0.2,0,0.4,0.1,0.6,0.2c0,0.1-0.1,0.3-0.1,0.4c0.3,0,0.4-0.1,0.6-0.3c0.1,0,0.2,0.1,0.3,0.2c0,0,0.2-0.1,0.2-0.1
        c0.1,0.2,0,0.6,0.4,0.4l-0.1,0.2c0.1,0,0.2-0.1,0.3-0.1c0.1,0.1,0.2,0.3,0.2,0.4c-0.1,0-0.2,0.1-0.3,0.1c0.3,0,1,0,0.9-0.4l0.2,0
        c0,0.1-0.1,0.3-0.1,0.5c0.1-0.1,0.3-0.2,0.4-0.2c0,0.1,0,0.3,0,0.4c0.2,0,0.4-0.1,0.5-0.1c0.1,0.2,0.1,0.5,0.3,0.7l0-0.2
        c0.1-0.1,0.3-0.2,0.4-0.3c0.2,0.1,0.3,0.3,0.6,0.3c0.2,0,0.5-0.1,0.5,0.2c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.1,0.1-0.2,0.1
        c0.3,0.2,0.7,0.1,1,0.3c-0.1-0.1-0.2-0.3-0.2-0.3l0-0.2c0.3,0.3,0.7,0.3,1,0.4c-0.1,0-0.4,0-0.5,0c0,0.3,0.4,0.4,0.6,0.3
        c0.1,0.2,0.3,0.3,0.5,0.4c0-0.3-0.7-0.5-0.3-0.7c0.2,0.3,0.6,0.3,0.9,0.5c-0.1,0-0.4,0.1-0.5,0.1c0.2,0.1,0.4,0.3,0.6,0.1
        c0.1-0.1,0.3-0.5,0.4-0.1c0.4,0.1,0.9,0.2,1.2,0.6c-0.1,0-0.3,0-0.4,0c0,0.1,0,0.2,0,0.2c0.1,0,0.3-0.1,0.4-0.1
        c0.1,0.2,0.2,0.3,0.4,0.4c0.2-0.2,0.4-0.2,0.7,0.1l0.1-0.2l-0.3,0c0-0.1,0.1-0.2,0.1-0.2c0.2,0.1,0.3,0.2,0.5,0.4
        c-0.1,0.1-0.3,0.1-0.4,0.2c0.3,0.2,0.4,0.4,0.5,0.7c0-0.3-0.1-0.5-0.1-0.7c0-0.3,0.4-0.2,0.6-0.3c-0.1,0.2-0.3,0.3-0.5,0.3
        c0.2,0.1,0.3,0.2,0.5,0.3c0-0.1,0.1-0.3,0.1-0.4l0.2,0c0,0.1-0.1,0.2-0.1,0.2c0.1,0,0.4,0.1,0.5,0.2c0.1-0.2,0.3-0.3,0.5,0
        c-0.1,0-0.3,0-0.3,0.1l0,0.1l-0.2,0.1c-0.4,0.6,0.3-0.2,0.5,0c0.1-0.1,0.2-0.2,0.3-0.3c0,0.2,0.1,0.5,0.1,0.7c0.1,0,0.2-0.1,0.3-0.2
        c0.1,0.4,0.3,0.2,0.5,0c0.1,0.1,0.2,0.2,0.2,0.3c0,0.2,0.1,0.4,0.3,0.5l0-0.2c0-0.6,0.4,0,0.5,0.1c0,0,0.1-0.1,0.1-0.2
        c0.4,0,0.2,0.9,0.7,0.7c-0.1-0.2-0.2-0.3-0.2-0.5c0.4,0.1,0.7,0.4,1.1,0.5c-0.1,0.2-0.4,0.1-0.6,0.2c0-0.1-0.1-0.3-0.1-0.4
        c0,0.1,0,0.2,0,0.4c0.1,0.4,0.7,0.2,0.9,0c0,0.1-0.1,0.3-0.2,0.4c0.3-0.1,0.6-0.1,0.9,0l0.1,0c0.1,0,0.2,0.1,0.3,0.1
        c-0.2,0-0.4,0-0.6,0c-0.3,0.4,0.4,0.1,0.6,0c0,0.1,0,0.3,0,0.4c0.2-0.1,0.4-0.2,0.7-0.3c-0.1,0.2-0.3,0.3-0.4,0.5
        c0.2,0,0.4-0.1,0.6-0.3c0.1,0,0.3,0.1,0.4,0.1c0,0.1,0.1,0.3,0.1,0.4c-0.1-0.1-0.4-0.2-0.6-0.3c0,0.2,0,0.4,0,0.5
        c0.2-0.1,0.5-0.2,0.7-0.3l0,0.3c0.2-0.1,0.4-0.2,0.7-0.3c0,0.2-0.1,0.5-0.1,0.6l-0.1,0l0-0.3c-0.4,0-0.2,0.4,0,0.5
        c0.1-0.2,0.7,0.2,0.4-0.3c0.3,0.2,0.6,0.3,1,0.4c0-0.1,0-0.2-0.1-0.3c0.1,0,0.2,0,0.2,0c-0.1,0.2-0.2,0.4-0.4,0.6
        c0.2,0,0.5,0.2,0.7,0.3c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0-0.2-0.1-0.3c0.2,0.3,0.9,0.1,0.7,0.6l0.3,0l-0.1-0.2
        c0.2,0,0.3,0.1,0.5,0.1c0,0,0,0.1,0,0.1c-0.1,0-0.2-0.1-0.3-0.1c0,0.2-0.1,0.4-0.2,0.6c0.3,0,0.5,0.1,0.8,0.2
        c0.2-0.1,0.4-0.2,0.6-0.2c-0.1,0.1-0.3,0.2-0.5,0.3c0.3,0.3,1,0.1,1.2,0.6c-0.2,0-0.5,0-0.7,0c0.2,0.2,0.6,0.1,0.8,0.4
        c0.1,0.1,0.2,0.1,0.3-0.1c-0.1-0.1-0.2-0.2-0.2-0.2l0-0.2c0.2,0.1,0.4,0.2,0.6,0.3c-0.1,0.1-0.2,0.3-0.2,0.4c0.2,0,0.3-0.1,0.5-0.1
        c-0.1-0.1-0.2-0.2-0.2-0.3c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0.1-0.5,0.2-0.4,0.4c0.2,0,0.4-0.1,0.6-0.2c-0.1,0.1-0.2,0.3-0.2,0.4
        c0.3-0.1,0.7,0.1,0.7,0.4c0.1-0.4,0.6,0.1,0.7,0.3c0.4-0.3,0.4,0.3,0.4,0.6c0.3-0.1,0.2-0.3,0.1-0.4c0.1,0,0.4,0.1,0.5,0.1
        c0,0,0,0.1,0,0.1c-0.1,0-0.2,0-0.3,0c-0.2,0.3,0.3,0.4,0.5,0.6c0-0.2-0.1-0.6,0.2-0.6c-0.3,0.3,0,0.5,0.3,0.7c0.2,0,0.3,0.2,0.4,0.3
        c0.2,0,0.5,0,0.7-0.1c0,0.1,0,0.2,0,0.3l-0.1-0.2c-0.2,0.2-0.6,0-0.7,0.3c0.3,0,0.5,0.1,0.8,0.1c0,0.1-0.1,0.2-0.1,0.3l0.4,0
        c0-0.1-0.1-0.3-0.1-0.4c0.1,0,0.2,0,0.3,0.1c-0.1,0.2-0.1,0.4-0.2,0.6c0.4-0.1,0.5,0.7,0.9,0.2c0.2,0.1,0.5,0.2,0.7,0.3
        c-0.1,0.2-0.2,0.4,0,0.6c-0.1,0-0.3,0-0.4,0c0.3,0.2,0.8,0.5,1.2,0.3c-0.1,0.1-0.3,0.2-0.4,0.3c0.2,0.1,0.4,0.3,0.6,0.4
        c0-0.1,0-0.3,0-0.4c0.2,0.1,0.2,0.3,0.2,0.5l0.2,0c0,0.1,0,0.3,0,0.4c0.1,0,0.3-0.1,0.4-0.1c0.3,0.3,0.5,0.7,0.7,1.1
        c0-0.1,0.1-0.2,0.2-0.3c0.1,0.2,0.6,0.7,0.1,0.8c0,0,0,0.1,0,0.2c0.2-0.1,0.3-0.2,0.5-0.3c0,0.1-0.1,0.3-0.1,0.5
        c0.1-0.1,0.2-0.2,0.3-0.3l0,0.2c0.3,0,0.3,0.2,0.2,0.5l-0.3-0.1c0,0.1,0.1,0.2,0.2,0.3c0.1-0.1,0.2-0.3,0.3-0.4
        c0.1,0.2,0.2,0.4,0.3,0.7c0.1,0,0.2,0.1,0.3,0.1c-0.1,0.3,0.3,0.3,0.5,0.4c-0.1,0.1-0.2,0.2-0.3,0.3c0.3-0.1,0.6,0,0.9,0.2
        c-0.1,0-0.3,0.1-0.4,0.1v0.2c0.1,0,0.3,0,0.4,0c0.2,0.3,0.3,0.9,0.7,0.8c0,0.1-0.1,0.3-0.1,0.4l0.1-0.3c0.2,0.2,0.3,0.6,0.6,0.6
        c-0.1,0.1-0.3,0.3-0.1,0.5c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0.2,0.1,0.4,0.2,0.6c0.2,0.2,0.5,0.3,0.8,0.3c-0.1,0.2-0.1,0.4,0.1,0.5
        c-0.1,0-0.3,0-0.4,0c0.2,0.2,0.5,0.4,0.8,0.6c0.1,0.2,0.1,0.4,0.4,0.4c0,0.2,0.2,0.3,0.4,0.5c0.2,0.1,0.1,0.4,0.2,0.6
        c-0.1-0.1-0.2-0.3-0.3-0.4c0,0-0.1,0-0.1,0c0.1,0.2,0.1,0.6,0.5,0.6c0,0.2,0,0.4,0,0.6c0,0,0.1,0.1,0.1,0.1c-0.1-0.2,0-0.4,0.2-0.5
        c0,0.4,0.2,0.5,0.5,0.6c0,0.2-0.2,0.3-0.3,0.4c-0.1-0.1-0.2-0.2-0.2-0.3c0.1,0.2,0.2,0.4,0.3,0.5c0.1-0.1,0.2-0.2,0.2-0.2
        c0.1,0.2,0.1,0.4,0.1,0.6c0.1,0.1,0.7,0.1,0.4,0.4c-0.2,0.2,0,0.3,0.1,0.5c0,0.2,0,0.3,0.2,0.4c-0.1,0.2,0,0.4,0.1,0.5
        c0,0,0.1-0.1,0.2-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0,0.4,0.2,0.6,0.4,0.9c-0.1,0.1-0.3,0.3-0.4,0.4c0.1,0,0.2,0.1,0.3,0.2l0-0.3
        c0.1,0,0.2,0,0.3,0c0.1,0.4-0.3,0.4-0.3,0.7c0.2-0.2,0.4-0.3,0.6-0.5c-0.1,0.3-0.2,0.6-0.2,0.9c0.1-0.1,0.3-0.2,0.4-0.2
        c0.1,0.2,0.2,0.5,0.2,0.7c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.2,0.2,0.4,0.3,0.6c-0.2,0-0.3-0.1-0.5-0.1c0-0.1,0-0.4,0.1-0.5
        c-0.5,0-0.1,0.4,0,0.7l-0.3,0c0.2,0.1,0.3,0.1,0.5,0.2c0,0.2,0,0.4,0,0.6c0.1,0,0.2,0.1,0.3,0.2c0,0.1-0.1,0.2-0.2,0.3
        c0.4,0.3,0.1-0.4,0.4-0.4c0,0.1,0,0.4,0,0.5c0.1,0.1,0.3,0.2,0.4,0.4c-0.1,0.3-0.2,0.3-0.5,0.2c0,0.1,0.1,0.4,0.1,0.5
        c0.1-0.1,0.3-0.2,0.4-0.2c0,0.3-0.1,0.5-0.4,0.5c0.1,0,0.2,0.1,0.3,0.1c0,0.1-0.1,0.2-0.1,0.3c0.1,0.2,0.4,0.1,0.6,0.1
        c-0.2-0.1-0.3-0.1-0.4-0.2c0.1-0.1,0.2-0.4,0.3-0.6c0,0.1,0.1,0.2,0.1,0.3c0,0.2,0.1,0.3,0.1,0.5c0.1,0,0.2,0,0.2-0.1
        c-0.1-0.1-0.2-0.3-0.3-0.4c0.1,0,0.3,0,0.4,0.1l0.1,0.2c0,0,0.1,0,0.2,0c-0.3-0.2-0.6-0.3-0.8-0.5c0-0.2-0.1-0.4-0.3-0.5
        c0.1-0.1,0.1-0.3,0.3-0.4c0.1,0.1,0.2,0.2,0.3,0.2c0.1-0.2,0-0.4,0-0.5c-0.1,0-0.3,0-0.4,0.1l-0.1,0.3c-0.3-0.3,0.2-0.4,0.4-0.6
        c-0.1-0.1-0.2-0.3-0.3-0.3c-0.1,0-0.2,0-0.3,0c0.2-0.3,0.4,0.1,0.6,0.1c0-0.3-0.2-0.5-0.3-0.7l0.2,0c0-0.1,0-0.3,0-0.4l-0.3,0.1
        c0-0.2,0.2-0.4,0.4-0.5l-0.2-0.1l0.5-0.2l-0.3-0.1l-0.3,0.1c0.1-0.1,0.2-0.3,0.3-0.4c-0.1-0.1-0.3-0.3-0.5-0.1
        c0-0.1,0.1-0.2,0.2-0.2c0-0.2,0-0.4,0-0.6c0.1,0.3,0.3,0.4,0.5,0.6l0.2-0.2c-0.4-0.2-0.1-0.5,0-0.7c-0.1-0.3-0.2,0-0.4,0.1
        c-0.2,0.2-0.3-0.4,0-0.3c0,0-0.1-0.1-0.2-0.2c0.1-0.2,0.2-0.3,0.4-0.5c0-0.1,0-0.3,0-0.4c-0.2-0.1-0.1-0.3,0-0.4
        c-0.1-0.2-0.1-0.3,0.2-0.4l0-0.2c-0.1,0-0.3,0-0.4,0c0.2-0.2,0.5-0.2,0.5-0.5c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.2,0.1-0.3,0.2-0.5
        c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1-0.1-0.2-0.2-0.3-0.4c0-0.2,0.3-0.4,0.4-0.6c0,0,0.1-0.1,0.1-0.1c-0.2-0.1-0.2-0.3-0.2-0.5
        c0.3-0.2,0.1-0.6,0.1-0.9c0.2-0.2,0.4-0.4,0.6-0.5c-0.2,0.1-0.4,0.1-0.6,0.2c0.1-0.1,0.3-0.3,0.4-0.4c-0.1,0-0.3-0.1-0.4-0.1
        c0.1,0,0.2,0,0.3,0c0-0.2,0-0.3,0.2-0.4c0-0.2,0-0.5,0-0.7c0.1,0.3,0.3,0.3,0.5,0.2c-0.1,0-0.3-0.1-0.4-0.1c0-0.1,0.1-0.2,0.2-0.3
        l-0.2-0.2c0.1,0,0.2-0.1,0.3-0.1c0.2-0.4-0.3-0.6,0-1c0.1,0,0.2,0.1,0.3,0.1l0.1-0.4l-0.1,0.1c-0.1-0.4-0.5-0.7-0.9-0.9
        c-0.6-0.5-1.1-1.2-1.9-1.4c-0.2-0.2-0.3-0.5-0.5-0.7c-0.5,0.1-0.8-0.4-1-0.7c-0.6-0.2-0.9-0.8-1.5-1.1c-0.5-0.5-1-1-1.6-1.4
        c-0.1-0.3-0.4-0.5-0.6-0.6c-0.3-0.5-0.8-0.7-1.1-1c-0.4-0.4-1.1-0.6-1.2-1.2c-0.5-0.1-0.8-0.6-1.1-1c-0.6-0.1-0.8-0.7-1.3-0.9
        c-0.1-0.2-0.2-0.3-0.2-0.5c-0.6-0.2-0.9-0.6-1.2-1.2c-0.3-0.1-0.5-0.2-0.6-0.4c-0.6-0.4-1-0.9-1.4-1.5c-0.6-0.1-0.8-0.8-1.1-1.2
        c-0.2,0-0.3,0-0.5-0.1c0-0.3-0.2-0.4-0.4-0.5c-0.4-0.4-0.6-1-1.2-1.2c-0.3-0.2-0.4-0.6-0.8-0.7c0.1-0.5-0.5-0.7-0.8-0.9
        c0-0.1,0-0.3,0-0.4l-0.2-0.1c-0.1,0.1-0.2,0.2-0.3,0.2c0-0.1,0.1-0.2,0.1-0.3c-0.3-0.2-0.6-0.4-0.8-0.7c-0.1-0.5-0.9-0.7-0.7-1.3
        c-0.1,0.1-0.3,0.2-0.4,0.2c0.1-0.1,0.2-0.2,0.3-0.3c-0.4-0.4-1-0.7-1-1.3c-0.2-0.2-0.6-0.2-0.6-0.6c-0.1-0.4-0.7-0.4-0.7-0.8
        c0-0.3-0.3-0.4-0.5-0.6c-0.3-0.2-0.4-0.6-0.7-0.7c0-0.1,0-0.2,0-0.2c-0.3-0.2-0.6-0.5-0.8-0.7c0-0.1,0-0.2,0-0.3
        c-0.7-0.6-1.2-1.4-1.7-2.2c-0.3-0.3-0.8-0.6-0.6-1.1c-0.1,0-0.3,0-0.4,0c0.1,0,0.3,0,0.4,0c-0.1-0.3-0.5-0.2-0.7-0.5
        c-0.2-0.2-0.2-0.5-0.4-0.7c-0.2-0.1-0.5-0.3-0.5-0.5c-0.1-0.3-0.4-0.6-0.7-0.8c-0.1-0.6-0.6-0.9-0.9-1.4c-0.4-0.3-0.5-0.9-0.9-1.2
        c0,0.2,0.1,0.4,0.2,0.6c-0.1,0.1-0.2,0.2-0.2,0.4c0.1,0.1,0.2,0.1,0.3,0.2c-0.1,0-0.2,0.1-0.3,0.1c0.2,0.3,0.1,0.6-0.1,0.9
        c0.1,0,0.3,0,0.4,0c-0.1,0-0.2,0-0.3,0.1c-0.2,0.2,0.1,0.3,0.1,0.5c-0.1,0-0.2,0-0.3,0c0,0.2,0.1,0.4,0,0.5c0,0.2-0.1,0.4-0.2,0.6
        c-0.2,0.2,0,0.6-0.3,0.8c0,0.1,0,0.3,0,0.3c-0.3,0-0.3,0.3-0.3,0.5c-0.1-0.1-0.2-0.2-0.3-0.3c0,0.1,0,0.3-0.1,0.5
        c-0.2,0.2-0.4,0.4-0.7,0.4c0,0.2,0.1,0.6-0.3,0.6l0,0.1l-0.2-0.1c0,0.2,0,0.4,0,0.6c-0.1-0.1-0.3-0.3-0.4-0.3c0,0.1,0,0.4-0.1,0.5
        c-0.1,0-0.3,0-0.3,0c-0.1,0.3-0.2,0.7-0.7,0.6c-0.1,0.1-0.3,0.3-0.3,0.5c-0.2,0.1-0.4,0.2-0.6,0.3c-0.3,0.2-0.6,0.4-0.8,0.5
        c-0.1-0.1-0.2-0.2-0.2-0.3c0.6,0,0.8-0.6,1.4-0.7c0.1-0.3,0.4-0.6,0.7-0.7c0.2-0.3,0.4-0.6,0.7-0.9c0,0,0-0.1,0-0.2
        c0.7,0,0.5-1.1,1.1-1.1c0-0.3-0.1-0.8,0.3-0.7c-0.3-0.2-0.2-0.3,0.1-0.3c0-0.1,0-0.2-0.1-0.2c0.2-0.1,0.3-0.2,0.2-0.4l0.2,0
        c0-0.1-0.1-0.2-0.1-0.3c0.3,0,0.3-0.1,0-0.1c0.1-0.4,0-0.8,0.1-1.2c0-0.1,0.3-0.4,0-0.4c0-0.1,0-0.3,0-0.4c-0.1-0.1-0.2-0.2-0.3-0.2
        c0.6-0.1-0.1-0.6-0.3-0.9c0.1,0,0.3,0,0.4,0c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.2-0.1-0.4-0.3-0.5c-0.1-0.3-0.3-0.5-0.6-0.8
        c0.2,0.1,0.8-0.2,0.8,0.2c0,0.4,0.2,0.7,0.4,1l0.1,0c-0.1-0.1-0.2-0.3-0.4-0.4c0-0.2,0-0.5-0.1-0.7c-0.2-0.2-0.5-0.4-0.8-0.2
        l0.1-0.2c0,0-0.1,0-0.2,0c0-0.1-0.1-0.2-0.1-0.3c0-0.3-0.1-0.6-0.2-0.8c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2-0.2,0-0.5-0.2-0.7
        c0,0.1,0,0.4,0,0.5c-0.2-0.3-0.4-0.4-0.6-0.6l0.2,0.2c0.1,0,0.4,0.1,0.5,0.1c0-0.5-1-0.2-0.6-0.8c0.1,0.1,0.2,0.2,0.4,0.3
        c0-0.1-0.1-0.2-0.1-0.3l-0.2,0c0,0-0.1-0.1-0.1-0.2c-0.1,0-0.2,0-0.2,0c0-0.1-0.1-0.2-0.2-0.2c0,0.2,0.1,0.3,0.2,0.5
        c0,0.1,0.1,0.4,0.1,0.5c-0.4-0.3-0.5-0.9-0.8-1.3c0,0-0.1,0-0.1,0c0,0.2,0.3,0.5-0.1,0.4c-0.1-0.2-0.3-0.4-0.4-0.6
        c-0.2,0-0.3,0-0.5,0c0.1-0.1,0.1-0.2,0.2-0.4c-0.2-0.1-0.4-0.2-0.5-0.3c0,0.1-0.1,0.3-0.1,0.3c-0.1-0.1-0.3-0.2-0.3-0.3
        c-0.2,0-0.5,0-0.7-0.1l0.1-0.3c0.1,0,0.2,0,0.3,0c0-0.1,0-0.3,0-0.4c-0.1,0.1-0.2,0.2-0.3,0.3c0-0.1-0.1-0.2-0.2-0.2l0,0.3
        c-0.3-0.1-0.5-0.3-0.8-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c0,0.1-0.1,0.2-0.2,0.3c-0.1-0.2-0.3-0.3-0.4-0.5l-0.2,0.1
        c0.1,0.1,0.2,0.2,0.2,0.3c-0.1,0.1-0.2,0.2-0.4,0.2c0.1-0.2,0.1-0.4,0-0.6c-0.3-0.1-0.7-0.2-1-0.3c-0.1,0-0.2,0-0.3,0
        c-0.2-0.2-0.4,0.1-0.5,0.2c-0.1,0-0.2-0.1-0.3-0.1c0.2-0.1,0.3-0.2,0.4-0.4c-0.2,0-0.5-0.1-0.6-0.1c0,0.1,0,0.3,0,0.3
        c-0.4,0.2-0.8-0.1-1.1-0.2c0,0.1,0,0.2,0,0.3c0-0.3-0.5-0.6-0.5-0.1c-0.1-0.1-0.3-0.2-0.4-0.3c0.1,0.1,0.2,0.2,0.3,0.3l-0.1,0.2
        c-0.1,0-0.4-0.1-0.6-0.1c0.2-0.1,0.4-0.3,0.2-0.5c-0.2,0.2-0.6,0.4-0.4,0.7c-0.1,0-0.4-0.1-0.6-0.1c0.1-0.2,0.3-0.2,0.5-0.3
        c-0.2-0.1-0.3-0.2-0.4-0.4C155.2,215.7,155.1,215.7,155.1,215.8 M311.4,215.6C311.5,215.7,311.5,215.7,311.4,215.6 M344.8,215.6
        c-0.2,0.1-0.1,0.6,0.2,0.5C345.1,215.9,345.1,215.6,344.8,215.6 M27.9,216.2c0.3,0.1,0.4,0.5,0.6,0.6c0.2-0.3,0-0.5-0.1-0.7l-0.2,0
        c0-0.1,0-0.2,0-0.3C28.1,216,28,216.1,27.9,216.2 M486.5,215.9c0,0.1,0,0.2,0,0.2c-0.1,0.1-0.2,0.2-0.3,0.2
        c-0.4,0.2,0.3,0.9-0.4,0.9c0,0.1,0,0.2,0.1,0.3c0.1-0.1,0.2-0.3,0.3-0.4C486.1,216.6,487,216.2,486.5,215.9 M154.8,216
        C154.7,216.5,155.2,215.8,154.8,216 M195.2,216.2c0.2,0.1,0.3,0.1,0.5,0.2c0-0.1,0-0.3,0-0.4C195.6,216.1,195.4,216.2,195.2,216.2
        M261.7,216.1c-0.1,0-0.3,0.1-0.5,0.1c0,0.2,0,0.4,0.2,0.6c0.4,0,0.1-0.6,0.4-0.7C261.8,216.1,261.8,216.1,261.7,216.1 M152.6,216.2
        c0,0,0,0.1,0,0.1c0.1,0,0.2,0.1,0.3,0.2c0.2,0,0.3,0,0.4,0C153.3,216.3,152.9,216.3,152.6,216.2 M279.7,216.2
        C279.8,216.3,279.8,216.3,279.7,216.2 M318.5,216.2L318.5,216.2L318.5,216.2L318.5,216.2L318.5,216.2 M152.2,216.3
        c0,0.3-0.3,0.4-0.5,0.6c0.2,0,0.4,0.1,0.6-0.1c0.2,0,0.3-0.1,0.4-0.2C152.6,216.5,152.4,216.4,152.2,216.3 M169.9,216.6
        c-0.1,0-0.3-0.1-0.4-0.1c0,0,0,0.1,0.1,0.1c0.3,0,0.6,0.1,0.8,0.3l-0.1-0.4c0.1,0.1,0.5,0.1,0.3,0.4c0.2,0.3,0.4,0.7,0.8,0.9
        c-0.4-0.5-0.5-1.1-0.9-1.6C170.3,216.5,169.8,216.1,169.9,216.6 M344.7,216.3c-0.1,0.2-0.2,0.4-0.3,0.6c0.2-0.1,0.4-0.3,0.6-0.4
        l0.1,0.3C345.4,216.4,344.9,216.4,344.7,216.3 M331.5,216.5C331.3,216.9,332,216.5,331.5,216.5 M244,216.6
        C244.1,216.7,244.1,216.7,244,216.6 M305.5,216.6c-0.2,0.2,0.2,0.4,0.3,0.2C306,216.6,305.6,216.4,305.5,216.6 M144.5,216.8
        c0,0.1,0,0.3,0,0.3c-0.2,0.1-0.6,0.1-0.6,0.5c-0.3,0.1-0.6-0.1-0.9-0.3c0.1,0.2,0.3,0.4,0.2,0.6c-0.1,0-0.3,0-0.4,0
        c0,0.1,0,0.2,0,0.3c-0.1,0-0.2-0.1-0.3-0.2c0,0.1-0.1,0.3-0.2,0.3c-0.1,0.1-0.1,0.2-0.2,0.3c0-0.1,0.1-0.3,0.1-0.4
        c-0.3,0.2-0.6,0.4-0.8,0.6c-0.1-0.2,0-0.3,0.1-0.5c-0.1,0.1-0.3,0.1-0.4,0.2c0,0.1,0.1,0.2,0.1,0.3c-0.2,0.2-0.4,0.2-0.6,0.2
        l0.1,0.1c-0.2,0-0.4,0.1-0.6,0.3c-0.4,0.2-0.8,0.4-1.1,0.8c0.2,0,0.5,0,0.7-0.2c0,0.2-0.2,0.3-0.3,0.4c0,0.1,0,0.3,0,0.3
        c-0.1,0.2-0.5,0-0.4,0.3c0,0.1,0,0.2,0,0.2c-0.2,0.1-0.4,0.2-0.4,0.5c0,0.2-0.2,0.4-0.4,0.4c0-0.3,0.2-0.5,0.3-0.7
        c-0.2,0.1-0.3,0.3-0.5,0.5c0,0.1,0.1,0.3,0,0.4c0.1,0,0.2,0,0.3,0l0-0.2c0.1,0,0.2,0.1,0.2,0.2c-0.1,0.1-0.2,0.3-0.2,0.4
        c0,0,0,0,0,0c-0.1-0.2-0.2-0.3-0.4-0.4c0.2,0.4,0.1,0.8,0.1,1.2l-0.1,0c-0.1,0.2-0.2,0.5,0,0.7l-0.2,0c0,0.2,0,0.4,0,0.5
        c0.1,0,0.3-0.1,0.4-0.1l0,0.1c-0.1,0-0.3,0.1-0.4,0.1c0.1,0.1,0.2,0.2,0.3,0.3c-0.2,0.1-0.3-0.1-0.4-0.1v0.4c0.1,0,0.2,0.1,0.3,0.1
        c-0.4,0.2-0.4,0.7-0.2,1.1c-0.2-0.1-0.5-0.1-0.7-0.1c0.1,0.3,0.5,0.2,0.7,0.2c-0.1,0.1-0.2,0.3-0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.4
        c-0.1,0-0.3-0.1-0.3-0.2c-0.3,0.2,0,0.6,0.3,0.6c0,0.3,0,0.5-0.2,0.7c0.3,0.2,0.3,0.6,0.3,0.9l0.2,0c0,0.1,0,0.4-0.1,0.5
        c0.2,0.1,0.4,0.2,0.5,0.5c-0.1-0.1-0.3-0.2-0.3-0.3c-0.1,0.3,0.2,0.6,0.2,0.9c0,0,0.1,0.1,0.2,0.1c0.1,0.4,0.2,0.8,0.6,1
        c0,0,0,0.1,0,0.1l-0.3,0c0.1,0.3,0.9,0.5,0.3,0.7c0.2,0.1,0.3,0.1,0.5,0.2c-0.1,0.2,0,0.5,0.3,0.6c-0.1,0.3,0.2,0.4,0.3,0.6
        c0.1-0.1,0.2-0.2,0.3-0.3l-0.1,0.3c0.1,0,0.3,0,0.4,0c0.2,0.3,0.3,0.7,0.7,0.8c-0.4,0-0.6-0.4-0.7-0.7c-0.2,0-0.3-0.1-0.5-0.1
        c0.1,0.4,0.3,0.7,0.7,0.9c0,0.3,0.1,0.7,0.5,0.5c0,0.1-0.1,0.3-0.2,0.4c0.1-0.1,0.2-0.2,0.3-0.2c0,0.6,0.8,0.8,1.3,0.8
        c-0.1,0-0.2,0.1-0.3,0.2c0.3,0.2,0.7,0.3,0.6-0.1c0,0.1,0,0.4,0,0.5c0.2,0,0.4,0,0.5,0.2c-0.2,0-0.4-0.1-0.6-0.1c0,0,0,0.1,0,0.2
        c0.2,0,0.5,0.3,0.7,0c0.2,0.1,0.3,0.2,0.5,0.3c-0.3,0.1-0.5,0.2-0.7,0.3c0.1,0.1,0.1,0.1,0.2,0.2c0.3-0.1,0.5-0.2,0.8-0.3
        c-0.2,0.4,0.3,0.5,0.5,0.3c0.2,0.1,0.4,0.1,0.4,0.4c-0.1-0.1-0.4-0.2-0.5-0.2c-0.2,0.2-0.2,0.4,0,0.6c0.2-0.3,0.5-0.2,0.7-0.3
        c0,0.1,0.1,0.3,0.1,0.4l-0.1-0.1c0,0,0.1,0.1,0.2,0.2c0.1-0.1,0.1-0.2,0.2-0.2c-0.1,0.3,0,0.5,0.3,0.6c0-0.3-0.1-0.5-0.2-0.7
        c0.1,0,0.3,0.1,0.4,0.2c-0.1,0.1-0.1,0.3-0.2,0.4c0.2,0.2,0.2,0.5,0.5,0.4c-0.1-0.2-0.4-0.5-0.2-0.7c0.2,0.3,0.3,0.5,0.5,0.8
        c0,0.1,0,0.2,0,0.3c0.1,0,0.2-0.1,0.2-0.1c0.1,0.1,0.3,0.2,0.4,0.2c0-0.1,0-0.3,0-0.4c-0.2-0.1-0.4-0.1-0.6-0.1c0-0.1,0-0.2,0-0.3
        c0.2,0.1,0.5,0.1,0.7,0.2c0.1,0.2,0.2,0.4,0.2,0.5c0.2-0.1,0.4-0.2,0.6-0.2c0,0.1-0.1,0.3-0.2,0.3c0.2,0,0.4,0,0.6,0
        c0,0.1-0.1,0.3-0.1,0.4l0.2,0c0,0.1,0,0.2,0,0.3c0.2-0.1,0.3-0.2,0.5-0.1c0,0.1,0,0.2,0,0.2c0.2,0,0.4,0,0.6,0
        c-0.3-0.3-0.6-0.4-1-0.3c-0.1-0.1-0.1-0.3-0.2-0.4c0.1,0,0.2-0.1,0.3-0.1c0,0.4,0.6,0.4,1,0.4c-0.1,0.1-0.1,0.2-0.2,0.3
        c0.3,0,0.6,0.1,0.8-0.1c-0.1,0.1-0.2,0.2-0.3,0.4c0.2,0.2,0.4,0.2,0.6,0.1c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0,0.3-0.1,0.4-0.1
        c0.2,0.2,0.3,0.3,0.5,0.5c-0.1,0-0.3,0-0.4,0c0.1,0.1,0.3,0.2,0.4,0.3c0.3,0,0.6,0,0.9-0.2c0.3,0,0.8,0.1,0.8,0.5
        c0.3,0,0.5-0.1,0.8-0.1c0,0.1-0.1,0.3-0.1,0.4c0.1-0.1,0.3-0.2,0.4-0.2c-0.4,0.6,0.4,0.6,0.7,0.6c0.1-0.1,0.1-0.2,0.2-0.3
        c0,0.1,0.1,0.3,0.2,0.4c0-0.1,0.1-0.2,0.1-0.3l0.1,0c0,0.1-0.1,0.4-0.1,0.5c0.2,0,0.5,0,0.6,0.1l0-0.2c0,0,0.1,0.1,0.2,0.2
        c0.1-0.1,0.3-0.2,0.3-0.2c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0-0.3,0-0.3,0c0.1,0.1,0.2,0.1,0.3,0.2c0.3-0.1,0.5-0.4,0.8-0.6
        c0.1,0.2,0.2,0.3,0.2,0.5c-0.1-0.1-0.2-0.2-0.2-0.2l-0.2,0.1c-0.2,0.6,0.4,0.3,0.7,0.2c0,0.1,0,0.3,0,0.4c0.4,0.2,0.7,0.4,1.1,0.6
        c-0.2-0.4-0.6-0.7-1-0.8c0.1-0.1,0.1-0.1,0.2-0.2c0.4,0.3,0.8,0.7,1.3,0.5l-0.1,0.3c0.2-0.1,0.4-0.2,0.6-0.1
        c-0.2,0.1-0.4,0.1-0.4,0.3c0.1,0,0.3,0,0.4,0l0,0.2c0.2-0.1,0.4-0.2,0.7,0c-0.1,0.3-0.5,0.2-0.7,0.4c0.2,0,0.4,0,0.6,0
        c0.2-0.2,0.4-0.3,0.5-0.5c0.2,0.2,0.6,0.1,0.9,0.2c-0.1,0.2-0.2,0.5-0.5,0.4c0.1-0.1,0.2-0.3,0.2-0.4c-0.1,0-0.2,0.1-0.3,0.2
        c-0.2,0-0.3,0-0.5,0c0.1,0.1,0.4,0.2,0.5,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0,0.2,0,0.3,0l0-0.2c0.2,0,0.4,0,0.5,0
        c0,0.1,0.1,0.3,0.2,0.3c0.2-0.1,0.2-0.2,0.3-0.4c0-0.1-0.1-0.2-0.2-0.2c0.3,0.2,0.5,0.3,0.8,0.5c0.1,0,0.2-0.1,0.3-0.1
        c0,0.1,0,0.2,0,0.3c-0.2,0-0.4-0.1-0.5,0.1c0.3-0.1,0.6,0.1,0.8,0.3c0.1,0,0.2,0,0.2,0c-0.1,0.4,0.3,0.3,0.4,0
        c-0.1-0.2-0.2-0.4-0.3-0.6c0.2,0.1,0.4,0.4,0.6,0.4c0,0.1,0,0.2,0,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1-0.1,0.2-0.1,0.3-0.2
        c-0.1-0.1-0.2-0.2-0.3-0.3c0.2,0.1,0.3,0.2,0.5,0.2c0,0.3,0.3,0.1,0.5,0.2c0,0.1,0,0.2-0.1,0.2l-0.1,0.1c-0.2,0-0.4-0.1-0.6-0.2
        c0.1,0.2,0.4,0.4,0.6,0.5c0-0.1,0-0.2,0-0.3c0.2,0,0.3,0.3,0.5,0.4c0.3,0.2,0.5,0.1,0.7-0.2c0,0.1,0.1,0.3,0.1,0.4l0.2,0.2l0-0.4
        l0.1,0l0,0.3c0.2-0.1,0.4-0.2,0.6-0.4c0,0.2,0,0.4,0.1,0.5l-0.3-0.1c-0.3-0.2-0.2,0.2-0.3,0.4c0.3-0.2,0.6-0.3,0.9-0.1
        c-0.1-0.2-0.1-0.3-0.2-0.5c0.2,0.1,0.4,0.3,0.6,0.4c-0.2,0.1-0.3,0.2-0.3,0.3c0.3-0.1,0.5-0.2,0.8-0.2c0,0.1,0.1,0.2,0.1,0.2l0.3,0
        c-0.1,0.5-0.5,0.3-0.8,0.1c0.1,0.1,0.2,0.3,0.3,0.4c0.3-0.1,0.7-0.6,0.9-0.2c-0.2,0.1-0.4,0.2-0.6,0.4c0.1,0,0.2-0.1,0.3-0.1
        c0.1,0,0.3-0.1,0.5-0.1c0.1,0,0.2-0.1,0.2-0.2c-0.1,0.4,0.3,0.6,0.4,1c0,0,0.1-0.1,0.2-0.1c-0.1-0.3-0.4-0.4-0.5-0.7l0.3,0.1
        l0.1,0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0.3,0.6,0.2,0.6,0.6c0.2,0.1,0.4,0.2,0.6,0.4l0-0.2c0.1,0.1,0.1,0.1,0.2,0.2
        c0.2-0.1,0.5,0,0.6,0.3l-0.2,0c0-0.5-0.3,0-0.5,0c-0.2,0-0.4-0.1-0.5-0.1c0.2,0.1,0.3,0.4,0.5,0.4c0.1,0,0.2-0.1,0.3-0.2
        c0.2,0.1,0.4,0.1,0.6,0.2c0-0.1,0-0.2,0-0.3c0.4,0.2,0.7,0.5,1.1,0.6c-0.1,0-0.3,0.1-0.4,0.1c0.1,0.1,0.2,0.2,0.3,0.3
        c0-0.1,0.1-0.2,0.2-0.3c0.1,0.1,0.3,0.2,0.5,0.4c-0.3,0.1-0.5,0.1-0.8,0.2c0.3,0.3,0.8,0,1,0.5c0,0,0.1-0.1,0.1-0.2
        c-0.3-0.1-0.5-0.4-0.1-0.5l0.1,0.3c0.1,0,0.2-0.1,0.3-0.1c-0.2,0.2,0,0.5,0,0.7l-0.2,0c0.1,0.1,0.2,0.2,0.3,0.3
        c0.3-0.1,0.5,0.2,0.8,0.3c0,0,0.1-0.1,0.1-0.1c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1,0-0.3,0-0.4,0c0-0.1-0.1-0.3-0.1-0.4
        c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0.2,0.5,0.4,0.5,0.7c0.1-0.1,0.2-0.2,0.3-0.3c0,0.2,0,0.4,0.1,0.5c0.1-0.3,0.3-0.3,0.4-0.1
        c0,0,0.1,0,0.2,0c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.3,0.3,0.5,0.4c0-0.1,0.1-0.2,0.2-0.2c0.1,0.5,0.9,0.4,0.7,1c0.1,0,0.2-0.1,0.3-0.1
        c-0.1,0.3,0.2,0.5,0.2,0.8c0.1,0,0.2,0,0.2,0c0.1,0.2,0.2,0.3,0.3,0.5c0-0.1,0-0.3,0-0.5c0.2,0.2,0.6,0.3,0.7,0.6
        c-0.2,0-0.5,0-0.7,0.1c0,0,0.1,0.1,0.2,0.1c0.2-0.1,0.4,0.1,0.5,0.3c-0.1-0.3,0-0.5,0.3-0.6c0.1,0.2,0.1,0.4,0.1,0.5
        c0.3,0.1,0.8-0.1,0.9,0.3c-0.2,0-0.5,0-0.7,0c0.1,0.2,0.4,0.2,0.6,0.3c0-0.1,0.1-0.3,0.2-0.4l0.2,0.2c0.2,0.2-0.3,0.2-0.1,0.5
        c0.2-0.2,0.4-0.2,0.6,0c0.1-0.1,0.2-0.1,0.3,0c-0.2,0.1-0.4,0.1-0.6,0.2c0.2,0.2,0.6,0.3,0.5,0.7c-0.1,0-0.3-0.1-0.4-0.1
        c0.1,0.1,0.2,0.3,0.2,0.4c0.2,0,0.3-0.1,0.5-0.1c-0.3-0.1-0.3-0.8,0.2-0.4c-0.1-0.1-0.2-0.4-0.2-0.5c0.2,0.2,0.4,0.4,0.5,0.6
        c-0.1,0-0.4-0.1-0.5-0.2c0.2,0.2,0.2,1,0.7,0.6c-0.1,0.1-0.1,0.2-0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0.3-0.1,0.4,0.3,0.6,0.5
        c0.1,0,0.2,0,0.3,0c0.1,0.2,0.2,0.4,0.3,0.5c-0.2,0-0.2,0.2,0,0.3c0.3,0.1,0.5,0.2,0.8,0.2c-0.2,0.1-0.4,0.3-0.6,0.5
        c0.2,0,0.4,0,0.6-0.1c0.2,0.1,0.3,0.3,0.5,0.4c0.1-0.1,0.2-0.1,0.4,0c-0.4-0.2-0.7,0.7-0.1,0.4c0,0,0,0.1,0,0.1l0,0.1l0,0.1
        c0.1,0,0.2,0,0.2,0c0,0.1,0.1,0.2,0.1,0.3c0.1,0,0.3-0.1,0.4-0.1c-0.1,0.1-0.2,0.3-0.3,0.3c0.2,0.2,0.5,0.2,0.7,0.2
        c-0.1,0.1-0.2,0.3-0.2,0.3c0.3-0.1,0.8,0.3,0.4,0.5c0.2,0.2,0.5,0.5,0.7,0.7c0.1,0.1,0.2,0.3,0.3,0.4c0-0.1,0-0.4-0.1-0.5
        c0.3,0.1,0.2,0.3,0.1,0.5c0,0.1,0.1,0.3,0.1,0.4c0.2,0.2,0.4,0.5,0.6,0.2l0-0.2c0,0,0,0,0.1,0c0,0.2,0.1,0.3,0.1,0.5
        c-0.1,0-0.3-0.1-0.4-0.1c0.1,0.2,0.2,0.5,0.4,0.5c0,0.1-0.1,0.3-0.1,0.5c0.1-0.1,0.2-0.2,0.3-0.3c0.3,0.1-0.2,0.8,0.3,0.5l0.2,0
        c-0.1,0.4-0.3,0.7,0,1.1c0-0.4,0-0.8,0.1-1.1c0,0.2,0.1,0.3,0.2,0.4c0,0.3,0.1,0.9,0.5,0.7c0,0.1-0.1,0.2-0.1,0.2
        c0.4,0.1,0.3,0.6,0.4,0.9c0.2-0.2,0.2-0.5,0.3-0.7c0,0,0.1,0,0.1,0c0,0.2-0.1,0.5-0.2,0.7l0.5-0.1c-0.1,0.1-0.3,0.3-0.4,0.4
        c0.1,0,0.4,0.1,0.5,0.2c0,0.1-0.1,0.4-0.1,0.5c0.1,0,0.2,0,0.3,0c0-0.1,0.1-0.2,0.1-0.2c0,0.2,0.2,0.4,0.3,0.5l-0.1,0.2
        c0.4,0.2-0.2,1.4,0.4,0.3c0.1,0.2-0.2,0.5,0,0.7c0.1,0.1,0.1,0.3,0.1,0.5c0.2,0,0.4,0,0.4,0.3c0.2,0,0.5,0,0.7-0.1
        c0,0.1,0,0.2,0,0.2c-0.2-0.1-0.4-0.1-0.6-0.2l0,0.2c0.3,0,0.5,0.2,0.5,0.5c0.1,0,0.3,0,0.4,0c0,0.1,0,0.3,0,0.3l-0.1,0
        c0-0.1-0.1-0.2-0.1-0.2c-0.3-0.1-0.2,0.2-0.3,0.4c0.2,0,0.5-0.1,0.5,0.2c-0.1,0-0.2,0.1-0.2,0.2c0,0.5,0.4,0.4,0.7,0.4l0,0.1
        c-0.1,0-0.2,0-0.3,0c0,0.2,0.2,0.3,0.3,0.4l-0.2,0c0,0.2-0.1,0.4-0.1,0.6c0.1,0.4,0.6-0.1,0.6,0.3c-0.1,0.1-0.2,0.2-0.3,0.2l0.2,0
        c-0.1,0.4-0.4,0.9-0.1,1.2c-0.2-0.3,0-0.5,0.3-0.3c0-0.1-0.1-0.4-0.1-0.5l0.3-0.2c-0.1,0.3,0.1,0.4,0.4,0.3c-0.1,0-0.2,0.1-0.3,0.1
        c0,0.1,0.1,0.2,0.1,0.2c-0.1,0-0.2,0-0.2,0c0.1,0.3-0.2,0.6-0.2,0.8c0.1,0.2,0,0.6,0.4,0.4c0,0.1-0.1,0.3-0.1,0.4
        c0.2,0.2,0.3,0.5,0.6,0.5c0,0.2,0.1,0.3,0.2,0.5c-0.2,0.3-0.2,0.6-0.2,0.9c0.4-0.2,0-0.8,0.6-0.8c-0.1,0.4,0,0.8-0.2,1.1
        c0,0.1,0,0.3,0.1,0.4l0.1-0.2c0.4-0.1,0.4,0.4,0.7,0.6c-0.2,0-0.4,0-0.5,0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0.1,0.3,0.1
        c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0,0.4,0.1,0.5,0.2c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0.2,0.5,0.3,0.1,0.5c-0.1,0-0.2-0.1-0.3-0.2
        c0.1,0.1,0.2,0.2,0.2,0.2c0,0.3-0.1,0.6,0.1,0.9c0-0.1,0-0.4,0-0.5c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.1,0.2,0.1,0.3,0.2
        c-0.1,0-0.3-0.1-0.4-0.1c0,0.2,0,0.5-0.3,0.6c0,0.1,0.1,0.2,0.2,0.3c0.2-0.1,0.3-0.2,0.3-0.4l0.1,0c-0.1,0.2-0.2,0.5,0.1,0.6
        c0,0.1-0.1,0.2-0.1,0.4c0.1,0,0.2-0.1,0.3-0.1c0,0.2,0,0.5,0,0.7c0.1,0.2,0.1,0.4,0.2,0.5c-0.2-0.1-0.4-0.1-0.6-0.2
        c0.2-0.2,0.3-0.5,0.4-0.8c-0.2,0-0.3,0.3-0.5,0.2c0-0.1,0.1-0.2,0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.2c0,0.2,0,0.4-0.1,0.6
        c0.1,0,0.2,0.1,0.3,0.1c0,0.1,0,0.2,0,0.4c0.2,0.1,0.4,0.2,0.6,0.4c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0.3,0,0.8,0.3,1.1
        c0,0-0.1,0.1-0.1,0.1c0.1,0.5-0.4,0.8-0.5,1.3c-0.1-0.1-0.2-0.1-0.2-0.2l-0.1,0.2c0.1,0,0.2,0,0.3,0c0,0.2,0,0.5-0.1,0.7
        c0-0.1,0.1-0.2,0.1-0.2c0.1-0.1,0.3-0.2,0.2-0.4c-0.1-0.3,0.3-0.6,0.4-0.8c0.2,0.3-0.2,0.6,0.1,0.9c-0.3,0.4-0.3,1,0.3,1.1
        c-0.1,0.2-0.3,0.3-0.5,0.1c0.1,0.1,0.2,0.3,0.3,0.4c0,0.1-0.1,0.4-0.1,0.5l0.3-0.2c0,0,0,0.1,0,0.1l0.1,0.1c-0.2,0.5-0.2,1-0.5,1.5
        c0.2-0.2,0.4-0.4,0.6-0.5c-0.1,0.3-0.2,0.6-0.3,0.9c0.1,0,0.3-0.1,0.4-0.2c-0.2,0.1-0.3,0.3-0.3,0.5c0.6-0.3,0.1,0.2,0.1,0.4
        c0.1,0.1,0.2,0.2,0.3,0.3l-0.3,0l0,0.1c-0.1,0.1-0.2,0.3-0.2,0.4c0.1,0,0.4,0,0.5,0c-0.1,0.1-0.2,0.3-0.3,0.4
        c0.3,0.4-0.2,0.7-0.5,0.9c0,0.2,0,0.4,0.2,0.5c-0.4-0.5,0.2-0.7,0.6-0.8c-0.2,0.3,0,0.7-0.3,1h0.3c-0.2,0.2-0.1,0.5-0.4,0.6
        c0.1,0,0.2,0,0.3,0l0.3,0.3c-0.3-0.2-0.6,0-0.8,0.1c0.3-0.1,0.5,0,0.7,0.3l-0.5,0c0.2,0.1,0.3,0.1,0.5,0.2c0,0.3,0,0.6-0.1,0.9
        c0.1,0,0.2,0.1,0.4,0.1l-0.2,0.1c0,0.2,0.1,0.4,0.1,0.6c-0.3-0.3-0.2,0.2-0.4,0.3l0.3-0.1c0,0.1-0.1,0.4-0.1,0.5l0.1,0
        c0-0.1,0-0.2,0-0.3c0.1,0.1,0.2,0.1,0.3,0.2c-0.1,0.2-0.2,0.4-0.3,0.5c-0.2-0.2-0.3-0.4-0.5-0.5c0.1,0.3,0.3,0.4,0.5,0.5
        c-0.1,0.1-0.2,0.2-0.2,0.2c0,0,0.1,0,0.1,0l0.1-0.2c0.5-0.3,0.1,0.3,0.1,0.4c0.1,0.1,0,0.5,0.3,0.4c0.2,0.3,0.4,0.7,0.3,1.1
        c0.1,0.3-0.2,0.4-0.3,0.6l0.4,0c0,0.1,0,0.2-0.1,0.3c0.1,0.1,0.1,0.3,0.2,0.4c-0.1-0.1-0.3-0.2-0.3-0.3c-0.1,0.1-0.2,0.3-0.2,0.5
        c-0.1,0-0.2,0.1-0.2,0.1c0.1,0,0.4-0.1,0.5-0.1c0,0.5-0.6,0.4-0.8,0.1c0,0-0.1,0.1-0.1,0.2c0.1,0.1,0.2,0.2,0.3,0.3l-0.2,0.1
        c0.1,0,0.3-0.1,0.3-0.1c0,0.2,0.1,0.4,0.1,0.6c0,0.1-0.1,0.2-0.2,0.3c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.2,0.3
        c-0.2,0-0.3-0.1-0.4-0.1c0,0.1,0,0.3,0,0.4c-0.1-0.2-0.1-0.4-0.1-0.5c-0.1,0-0.2,0.1-0.3,0.2c0.5,0.3-0.1,0.8-0.3,1.1
        c0.2-0.1,0.5-0.2,0.4-0.5c0.2,0.1,0.4,0.3,0.5,0.5c-0.2,0-0.4,0.2-0.6,0.3l0.3,0c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.1-0.1,0.3-0.2,0.4
        c0.2-0.2,0.3-0.5,0.4-0.7c0,0.1,0.1,0.3,0.1,0.4c-0.1,0.2-0.4,0.7,0.1,0.7c0-0.1,0.1-0.2,0.1-0.3c0,0.1,0,0.3,0.1,0.3
        c-0.2,0.1-0.4,0.2-0.6,0.3c0.2,0,0.3,0,0.5,0c-0.1,0.3-0.5,0.1-0.7,0c0.3,0.2,0.1,0.5-0.1,0.8c0.1-0.1,0.3-0.1,0.4-0.2
        c0.1,0.3,0.3,0.1,0.4,0.1c0,0,0,0.1,0,0.1c-0.3,0-0.4,0.1-0.2,0.4c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0.3,0.2,0.5,0.4,0.7
        c0,0.3,0,0.6,0,0.9c-0.1-0.1-0.2-0.1-0.3-0.2c0-0.1,0.1-0.4,0.2-0.6c-0.1,0-0.2-0.1-0.2-0.1l-0.1-0.1c-0.1-0.2-0.3-0.2-0.4-0.3
        c0.1,0.2,0.1,0.4,0.3,0.6c0.2,0.1-0.1,0.3-0.2,0.5c0.1,0,0.3,0,0.4,0c-0.1,0.3-0.2,0.4-0.5,0.2c0,0.1,0.1,0.3,0.2,0.4l-0.3-0.1
        c0.1,0.1,0.3,0.2,0.4,0.3c0-0.3,0.2-0.5,0.5-0.4c0,0.2,0,0.7-0.3,0.3c-0.1,0.2-0.3,0.4-0.4,0.6c0.3-0.1,0.5-0.3,0.8-0.4
        c-0.1,0.2-0.3,0.3-0.5,0.5c0.1,0.1,0.2,0.2,0.2,0.2c-0.1,0.4-0.7,0.5-0.8,0.9c0.1-0.2,0.7-0.4,0.5,0.1l-0.2,0c0,0.1,0.1,0.3,0.2,0.4
        c0.1-0.1,0.3-0.1,0.4-0.2c0-0.2,0-0.7-0.3-0.7c0.1-0.1,0.3-0.2,0.5-0.3c0,0.2-0.1,0.4-0.1,0.6c0.2,0.1,0.1,0.3,0,0.5
        c0,0,0.1,0.1,0.1,0.2c-0.2,0.1-0.3,0.2-0.5,0.3c0-0.1,0-0.3,0-0.4c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.2
        c0,0.1,0,0.3,0.1,0.4l-0.2,0c0.1,0.1,0.3,0.3,0.5,0.2c-0.3-0.2-0.2-0.7,0.1-0.8c0.2,0.4-0.1,0.9,0.1,1.3c0.1-0.1,0.2-0.2,0.3-0.3
        l-0.2-0.1c0-0.1,0.2-0.2,0.2-0.2c0,0.2,0.1,0.3,0.3,0.4c-0.1,0-0.2,0-0.2,0.1c0,0.2,0,0.3,0.1,0.5c-0.1-0.1-0.2-0.3-0.2-0.4
        c0,0.2,0,0.5,0,0.6l-0.1-0.2c-0.1,0.1-0.3,0.2-0.4,0.3c0.1,0.1,0.1,0.2,0.2,0.3c-0.1,0-0.2,0.1-0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.3
        c-0.2,0-0.3,0.1-0.3,0.3l-0.2,0.1c-0.2,0.5,0.4,0.8,0.6,1.2c-0.2,0.1-0.4,0.2-0.6,0.2c0,0.1-0.1,0.3-0.1,0.4
        c-0.1-0.1-0.2-0.3-0.3-0.4c0,0.2-0.1,0.3,0,0.5c-0.1,0.2-0.2,0.3-0.2,0.5c0-0.1-0.1-0.3-0.1-0.4l-0.1,0c0.1,0.2,0.1,0.6,0.5,0.5
        c-0.1,0.1-0.3,0.3-0.4,0.3c0.1,0.1,0.3,0.4,0,0.5c-0.2,0.1-0.3,0.1-0.5,0.1l0,0.1l0.2,0c0,0.1-0.1,0.3-0.1,0.4l-0.1,0
        c0.1,0.1,0.1,0.3,0.2,0.5c-0.2,0-0.3,0.2-0.4,0.3c0.2-0.1,0.4-0.3,0.5-0.4c-0.1-0.1-0.2-0.2-0.2-0.2c0.1-0.1,0.1-0.5,0.3-0.3
        l0.1,0.1c0.2,0.1,0.1-0.4,0.2-0.5c0.1,0.1,0.2,0.2,0.2,0.3l-0.2,0c0,0.1,0,0.2,0.1,0.3c-0.1,0-0.2,0.1-0.3,0.2
        c0.3,0.2-0.1,0.6-0.1,0.8c0,0.2,0.4,0.7-0.1,0.7c0-0.1,0-0.3,0-0.4c-0.5,0.2,0,0.6,0.2,0.8c0,0.1-0.1,0.2-0.2,0.3
        c-0.2,0-0.3,0.1-0.5,0.1c0,0.3,0.1,0.6,0.3,0.8c0.1-0.2,0.2-0.4,0.3-0.6l-0.3,0l0-0.1c0.1,0,0.3,0,0.4,0.1c0,0.4-0.3,0.8-0.3,1.2
        c-0.1,0-0.3,0.1-0.3,0.1c0.1,0.2,0.2,0.4,0.4,0.4c-0.1,0.1-0.3,0.2-0.4,0.3c0,0.2,0.3,0.4,0.2,0.6c-0.1,0.2-0.3,0.3-0.4,0.5
        c-0.1-0.2-0.4-0.4-0.6-0.5c0.1,0.3,0.5,0.4,0.7,0.7c-0.1,0-0.3-0.1-0.4-0.1c0.1,0.2-0.1,0.4-0.2,0.6c0.3-0.1,0.5-0.2,0.8-0.2
        c-0.2,0.2-0.4,0.3-0.5,0.5c-0.3,0.3-0.2,0.7-0.2,1.2c-0.1,0.3-0.3,0.6-0.5,0.8c0.6,0,0.6,0.7,0.4,1.1c-0.3-0.2,0-0.5,0-0.8
        c-0.1-0.1-0.2-0.2-0.3-0.3c0,0.3,0.2,0.7-0.1,1c0,0.2,0,0.5,0,0.7c0.2-0.2,0.3-0.5,0.6-0.7c-0.1,0.2-0.1,0.4-0.2,0.6
        c-0.2,0-0.3,0.2-0.4,0.4c0-0.2-0.1-0.5-0.4-0.3c0.1,0.1,0.1,0.3,0.2,0.4c-0.2,0.2-0.2,0.5-0.3,0.8c-0.1,0-0.3,0-0.4,0
        c0.1,0.2,0.2,0.2,0.4,0.2c-0.2,0.2-0.3,0.5-0.5,0.7c-0.1,0.1-0.3,0.2-0.1,0.4c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0-0.2,0.1-0.3,0.2
        c0.1,0.2,0.2,0.3,0.3,0.5c0,0.1,0,0.2,0.1,0.3l-0.1-0.2c-0.3,0.3-0.6,0.5-0.6,0.9c-0.1,0-0.3,0-0.4-0.1c0.1,0.1,0.2,0.3,0.3,0.3
        c0,0.1,0,0.2,0,0.3c-0.1,0-0.2-0.1-0.3-0.1l-0.1,0.4c0.2-0.1,0.4,0,0.6,0.1c-0.1,0-0.4,0-0.5,0c0,0.3,0,0.5-0.2,0.7
        c-0.1-0.2-0.2-0.5,0.1-0.6c-0.1-0.1-0.3-0.1-0.4-0.2c0.1,0.2,0.2,0.5,0,0.7c-0.3,0.4-0.1,1-0.6,1.4c0.1,0.1,0.3,0.3,0.4,0.4
        c0-0.2,0-0.5,0-0.6c0,0.1,0.1,0.2,0.1,0.3c0.3-0.2,0-0.5-0.1-0.8c0-0.1,0.1-0.3,0.2-0.4c0.2,0.2,0.3,0.4,0.6,0.3
        c-0.2,0-0.3,0.1-0.5,0.2c0.1,0.2,0.2,0.3,0.3,0.5c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.2-0.1,0.4-0.1,0.6c-0.2,0.2-0.3,0.1-0.5-0.1
        c0.1-0.1,0.1-0.3,0-0.3c-0.5,0.3-0.4,1,0,1.3c0,0.1-0.1,0.2-0.1,0.3c-0.1-0.1-0.2-0.3-0.3-0.4l0.2,0c-0.1-0.1-0.2-0.2-0.4-0.3
        c-0.3,0.3,0.4,0.7-0.1,0.9c0,0.1,0.1,0.2,0.2,0.3c-0.1,0.1-0.1,0.1-0.2,0.2c-0.2-0.3-0.3-0.5-0.2-0.8c-0.1,0.1-0.2,0.2-0.4,0.3
        c0.1,0.1,0.2,0.2,0.3,0.4c-0.1,0.2-0.3,0.3-0.4,0.5c0,0.1,0,0.3,0,0.4c0.2-0.2,0.3-0.3,0.3-0.5c0.2,0.3,0,0.6-0.3,0.5
        c0,0.1,0.1,0.2,0.1,0.2c-0.1,0.2-0.1,0.3-0.1,0.5c-0.2,0-0.5-0.5-0.1-0.5c-0.1-0.2-0.1-0.3-0.2-0.5c0,0-0.1,0-0.1,0
        c0,0.3-0.1,0.6-0.1,0.9c-0.1,0-0.2,0-0.2,0c0,0.1,0,0.3,0,0.4c-0.2-0.1-0.4,0-0.4,0.2l0.4-0.1c-0.2,0.2-0.2,0.4-0.5,0.5
        c0,0.3-0.2,0.6-0.5,0.7c0.1,0.1,0.3,0.1,0.4,0.2c-0.1,0.1-0.3,0.2-0.4,0.2c0,0.1,0,0.3,0,0.4c-0.1-0.1-0.2-0.2-0.3-0.2
        c-0.2,0-0.3,0-0.5,0c0,0.3,0,0.5,0,0.7c0.3-0.1,0.3-0.4,0.3-0.6c0.2,0.2,0.5,0.5,0,0.6l-0.1,0.1c0.1,0.5-0.3,0.8-0.6,1.1
        c-0.1,0.1-0.5,0.4-0.4,0.1c0.3-0.3,0.6-0.5,0.8-0.8c0.1-0.3-0.3-0.3-0.4-0.3c0,0.2,0,0.3,0,0.5l-0.1,0c-0.1,0.2-0.3,0.3-0.4,0.4
        c0.1,0.1,0.2,0.3,0.2,0.4c-0.1,0-0.2,0-0.3,0c-0.1,0.1-0.2,0.2-0.3,0.3c0,0.1,0,0.3,0,0.4c-0.3,0-0.8,0.7-0.2,0.5
        c0-0.4,0.4-0.4,0.7-0.6c-0.2,0.1-0.7,0.5-0.3,0.6c-0.5,0-1,0.3-1.4,0.6c0.3,0.1,0.9-0.1,0.9,0.4c0,0-0.1-0.1-0.1-0.2
        c-0.1,0-0.4,0.1-0.5,0.1c0,0.2,0,0.4,0,0.6c0-0.1-0.1-0.2-0.1-0.3l-0.2-0.2l0,0.2l-0.2,0c0.1,0.2,0.3,0.5,0.3,0.8
        c-0.1-0.1-0.2-0.2-0.2-0.3l-0.1,0.2c-0.1,0-0.3,0-0.4,0c0,0.2,0,0.4-0.1,0.6c-0.1,0-0.3,0-0.4,0.1c0,0.4-0.1,0.7,0,1.1
        c-0.4-0.1-0.2-0.5-0.3-0.7c-0.3,0.1-0.1,0.6-0.2,0.8c0.1,0.1,0.3,0.2,0.4,0.4c0.3-0.1,0.5,0,0.7,0.1c0,0,0.1-0.1,0.1-0.2
        c0.1,0.1,0.2,0.3,0.3,0.4c0.3,0.1,0.5,0.2,0.8,0.2c-0.1,0.1-0.1,0.1-0.2,0.2l0.3-0.1c0,0.1,0,0.2-0.1,0.3c0-0.1,0.1-0.3,0.1-0.4
        c0,0.1,0.1,0.4,0.1,0.6c0,0,0.1,0,0.2,0c0-0.1-0.1-0.3-0.2-0.3c0.5,0.1,0.8,0.4,1.3,0.5l0-0.3c0.1,0.1,0.1,0.3,0.2,0.4
        c0.3,0,0.5,0.3,0.7,0.4c0.3,0,0.6,0.1,0.8,0.2c0.2,0.2,0.5,0.3,0.7,0.6c0,0,0.1-0.1,0.2-0.2c0.3,0.1,0.6,0.3,0.9,0.3
        c-0.2-0.2-0.4-0.4-0.6-0.5c0.1,0,0.3,0,0.4,0c0,0,0,0.1-0.1,0.2c0.2,0.2,0.5,0.1,0.6,0.2c-0.1,0-0.2,0-0.3,0l0,0.3
        c0.2,0,0.4,0.1,0.6,0.2c0.2,0,0.4-0.1,0.5-0.2c0,0.1,0,0.2,0,0.3c0.2-0.1,0.5,0,0.7,0.1c0.1,0.3,0.2,0.4,0.5,0.2
        c0,0.1,0,0.3,0.1,0.4c0.4-0.3,0.7,0.1,1,0.3l0-0.3c0.3,0.1,0.3,0.5,0.7,0.5c0,0.2-0.1,0.4,0,0.6c0-0.2,0.1-0.4,0.1-0.6
        c-0.2-0.1-0.4-0.4-0.2-0.7c0,0.3,0.1,0.5,0.4,0.5c0.1,0.2,0.4,0.4,0.2,0.6c0.1-0.1,0.3-0.2,0.4-0.3c0,0.2,0.1,0.3,0.2,0.5l-0.1-0.4
        c0.3,0.2,0.6,0.3,0.9,0.1c-0.1,0.1-0.1,0.2-0.2,0.4c-0.1,0-0.3-0.1-0.4-0.1c0,0.3,0.3,0.2,0.5,0.2c0,0.1,0,0.2,0,0.3
        c0.2-0.1,0.3-0.2,0.5-0.2c0.2-0.5,0.7-0.9,0.8-1.4c0.4-0.4,0.9-0.8,1.2-1.4c0.5-0.7,1-1.3,1.5-2c-0.1,0-0.3-0.1-0.4-0.1
        c0.1-0.1,0.2-0.2,0.2-0.3c-0.5,0-0.2-0.2-0.1-0.4c-0.3,0.2-0.3,0.5-0.3,0.8c0.4,0.1,0.1,0.3-0.2,0.2l0.1,0.2
        c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1-0.1-0.2-0.2-0.2-0.2c0,0.1,0.1,0.2,0.2,0.3c0.1,0.3-0.4,0.2-0.3,0.5l-0.2,0l0.1,0.2l-0.2-0.1
        c0,0,0,0.1,0,0.1l0.3,0c-0.1,0.2-0.3,0.4-0.5,0.5c0,0.4-0.2,0.5-0.6,0.4l0.2,0c-0.1,0.2-0.3,0.4-0.4,0.7c-0.1-0.1-0.2-0.2-0.3-0.3
        l0.1,0.2l-0.2-0.1c0,0.2,0,0.4,0,0.6l-0.2,0c0,0.1,0,0.2,0,0.2c-0.2,0-0.5,0-0.7,0.1c0.4-0.4,0.3-1.1,0.8-1.5c0.3-0.3,0.5-0.7,0.9-1
        c0.2-0.2,0-0.7,0.4-0.7c0,0,0-0.1,0-0.2c0.2-0.2,0.5-0.4,0.5-0.7c0.3-0.6,0.8-1.1,1.2-1.7c0-0.1,0.1-0.2,0.1-0.3
        c-0.1,0.1-0.2,0.3-0.2,0.4c-0.2,0.3-0.5,0.6-0.7,1c-0.3,0.4-0.7,0.7-0.8,1.2l-0.1,0l0,0.1c-0.2,0.1-0.2,0.3-0.3,0.4
        c-0.2,0.2-0.4,0.4-0.6,0.6c-0.4,0.1-0.7,0.4-1.1,0.3c0.3-0.1,0.3-0.2,0.2-0.4c0.3-0.6,0.5-1.3,0.8-1.9c0.1-0.4,0.5-0.7,0.4-1.1
        c-0.2,0.1-0.5,0.3-0.8,0.3c0,0,0-0.1,0-0.1c0.1,0,0.2-0.1,0.3-0.1c0.2-0.3,0.3-0.7,0.6-1c0.1-0.2,0.2-0.4,0.2-0.6
        c0.1-0.1,0.2-0.2,0.2-0.2c0.2-0.3,0.2-0.8,0.5-1c0-0.2,0.1-0.4,0.1-0.5c0.1-0.1,0.3-0.2,0.4-0.2c0-0.1,0-0.2-0.1-0.3
        c0.2-0.4,0.4-0.8,0.4-1.3c0.1-0.1,0.2-0.3,0.2-0.4c0.2-0.1,0.2-0.3,0.1-0.5c0.1-1,0.4-1.9,0.5-2.9c-0.1-0.9,0.1-1.8,0-2.7
        c0.2,0.2,0.3,0.5,0.4,0.8c0.1,0.4,0,0.9,0.2,1.3c-0.1-1.2,0.4-2.3,0-3.5c0-0.1,0-0.3,0-0.4c-0.6-0.3-0.4-1-0.6-1.4
        c0.1-0.5-0.3-0.9-0.2-1.3c-0.3-0.6-0.3-1.2-0.4-1.8c-0.1-0.1-0.3-0.3-0.3-0.5c0.2,0,0.3-0.1,0.4-0.3c-0.4,0.1-0.5,0.6-0.3,0.9
        l-0.3-0.2c0,0.1,0,0.3,0,0.4c0.2,0,0.2,0.1,0.3,0.3c-0.2-0.1-0.3-0.1-0.5-0.2c0.1,0.1,0.2,0.2,0.2,0.3c-0.3,0.3-0.4,0.7-0.2,1.1
        c-0.1,0-0.4-0.1-0.6-0.1c0.1,0,0.2,0.1,0.3,0.2c-0.1,0.2-0.3,0.4-0.4,0.6c0.2,0.1,0.4,0.2,0.3,0.4c-0.1-0.1-0.2-0.2-0.3-0.3
        c0,0.2-0.1,0.4-0.3,0.6c0.1,0.1,0.2,0.2,0.2,0.3l-0.2,0c0,0.3-0.1,0.5-0.3,0.8c-0.1,0.2-0.3,0.3-0.1,0.6l-0.3-0.1
        c0,0.1,0.1,0.3,0.1,0.4c-0.3,0.3-0.4,0.6-0.3,1c-0.3,0.2-0.5,0.5-0.4,0.8l0.2,0c-0.1,0-0.2,0-0.3,0c-0.1,0.2-0.2,0.5-0.5,0.5
        c0,0,0,0.1,0,0.1c0.1,0,0.3,0,0.3,0c-0.1,0.2-0.3,0.3-0.6,0.3c0.1,0,0.2,0.1,0.3,0.1c0,0.1-0.1,0.3-0.2,0.4
        c-0.5,0.1-0.3,0.9-0.9,1.1c0,0.2-0.1,0.5-0.3,0.6c-0.2,0.5-0.7,1-0.9,1.5c-0.1,0-0.3,0-0.4,0c0.1,0,0.2,0.1,0.2,0.2
        c-0.2,0.3-0.4,0.6-0.6,1l-0.1,0c0,0.2-0.1,0.4-0.3,0.5c0,0.1,0,0.3,0,0.4c-0.1,0-0.3,0-0.4,0c0,0.1,0.1,0.2,0.1,0.3l-0.3-0.1
        c0,0.1,0,0.3,0.1,0.4l-0.1,0c-0.1,0-0.2,0.1-0.2,0.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2-0.2-0.3-0.4-0.3-0.6c0.1,0,0.2,0,0.3,0
        c0-0.2,0-0.3,0.1-0.5c0-0.1,0-0.3,0-0.3c0.2-0.1,0.2-0.3,0.2-0.5l0.1,0c0-0.1-0.1-0.2-0.2-0.3c0.1,0,0.2,0,0.3,0
        c0-0.3,0-0.6,0.1-0.8c0.1,0.1,0.2,0.3,0.2,0.4c0-0.3-0.1-0.6,0.1-0.8c0-0.4,0.3-0.6,0.4-0.9c0.1,0,0.2,0,0.3,0.1
        c-0.1-0.2-0.2-0.4,0.1-0.5c0.1-0.2,0.1-0.4,0.2-0.5c0.3-0.3,0.4-0.6,0.6-1c0.3-0.7,0.5-1.3,0.8-2c0.1,0,0.2,0.1,0.4,0.1
        c-0.1-0.1-0.2-0.2-0.3-0.2c0.2-0.4,0.4-0.8,0.5-1.2c0.2-0.2,0.1-0.4,0.1-0.5c0.2-0.2,0.4-0.4,0.7-0.5l0,0c-0.1,0-0.4,0-0.5,0
        c0.2-0.3,0.5-0.5,0.5-0.9c-0.1-0.3,0.1-0.5,0.3-0.6c0-0.1-0.1-0.4-0.1-0.5l0.2,0.1c-0.1-0.3,0.3-0.6,0.2-0.9
        c0.1-0.2,0.2-0.3,0.3-0.5c0-0.4,0.2-0.7,0.3-1c0.3-0.7,0.3-1.4,0.5-2.1c0.1-0.8,0.3-1.6,0.4-2.4c0.1-0.8,0.2-1.5,0.4-2.3
        c-0.1-0.3,0-0.6,0.2-0.9c-0.2-0.5,0.1-1,0.2-1.5c0.1-0.7,0.2-1.4,0.4-2.1c-0.2-0.6,0.2-1.1,0.1-1.7c-0.1-0.2,0-0.5,0.1-0.7
        c-0.3-0.7,0.3-1.3,0.1-2c0.2-0.4-0.1-0.9,0.2-1.2c0.2-0.3-0.2-0.5-0.1-0.7c0.1-0.7,0.1-1.5,0.4-2.1c0-0.4-0.1-0.9,0.2-1.3
        c-0.1-0.3-0.3-0.6-0.3-0.9c0-0.3,0.2-0.6,0.1-0.9c-0.1-0.3,0.1-0.5,0.2-0.7c0-0.3-0.2-0.5-0.3-0.8c0-0.2,0.1-0.4,0.1-0.5
        c-0.1-0.4,0.1-0.8-0.2-1.2c0-0.2,0.1-0.5,0.1-0.7c-0.1-0.9,0.1-1.8-0.1-2.7c0.1-0.7,0-1.3,0-2c-0.1-2.2-0.5-4.4-0.8-6.7
        c0.1,0.3,0.1,0.5,0.2,0.8c0.2,0.7,0.4,1.3,0.5,2c0.1,0.4,0.4,0.8,0.4,1.2c0,0.1,0,0.1,0,0.2c0.2,0,0.2,0.2,0.1,0.4
        c0.1,0.2,0.4,0.3,0.3,0.6l0.1,0c0,0.1,0,0.3,0,0.4l0.2,0c0,0.2,0,0.3,0.1,0.5c-0.1,0.2-0.2,0.3-0.3,0.6c0.1-0.1,0.3-0.2,0.4-0.2
        c0.1,0.3-0.1,0.8,0.3,1c0.1,0.1,0.2,0.1,0.3,0.2c-0.1,0-0.4-0.1-0.5-0.2c0.1,0.1,0.3,0.4,0.5,0.6c0,0-0.1,0.1-0.1,0.2l0,0.3
        c0.1-0.1,0.3-0.2,0.3-0.2c-0.1,0.1-0.2,0.1-0.3,0.2c0.2,0.5,0.1,1.1,0.5,1.4c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.3,0,0.4,0.3,0.3
        l-0.2-0.1c0.1-0.1,0.2-0.2,0.2-0.3c0.1,0.1,0.3,0.3,0.4,0.4c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0.1-0.2,0.3-0.2,0.5
        c0.1-0.1,0.2-0.4,0.5-0.3c-0.2,0.3-0.1,0.5,0.1,0.6c-0.1,0-0.2,0.1-0.3,0.1c0,0.2-0.1,0.4-0.1,0.6c0.2-0.2,0.3-0.4,0.5-0.5
        c0.1,0.3,0,0.7-0.3,0.9c-0.1-0.1-0.2-0.2-0.3-0.3c0.3,0.2,0.1,0.4-0.1,0.6c0.5,0,0.8-0.6,1.3-0.6c-0.2,0.1-0.4,0.3-0.4,0.5l-0.2,0
        c0,0.1-0.1,0.2-0.1,0.2l0.4-0.1c0,0.3-0.2,0.5-0.6,0.3l0,0c0.3,0.1,0.6,0.1,0.7,0.4c-0.2,0-0.4,0-0.6,0.1c0,0.2,0,0.4,0,0.5l0.1,0
        l0-0.2c0.1,0,0.4,0.1,0.5,0.1l-0.2,0.3c0.2-0.1,0.4-0.1,0.6,0.1c-0.2,0.5-0.5,0.9-0.4,1.4c-0.2,0-0.4-0.2-0.5,0.1
        c0.1,0.2,0.3,0.2,0.5,0.2c0,0.1,0,0.3-0.1,0.4l0.2,0c-0.1,0.3-0.4,0.4-0.7,0.6c0.3-0.1,0.5-0.2,0.8-0.3c0.1-0.2,0-0.4,0-0.6
        c0.1-0.1,0.2-0.2,0.3-0.3c0,0.1,0,0.3,0,0.4c0.1,0,0.2,0.1,0.3,0.1c-0.1,0-0.4-0.1-0.5-0.1c0.1,0.1,0.2,0.3,0.2,0.4
        c-0.1,0-0.2,0.1-0.3,0.1c0.1,0.2,0.2,0.3,0.3,0.3c-0.2,0.3,0.2,0.4,0.2,0.7c-0.3,0-0.4-0.2-0.3-0.5c-0.1,0-0.3,0.1-0.5,0.2
        c0-0.2,0-0.4-0.2-0.4c0,0.1,0,0.4,0,0.5c-0.4,0.1-0.2,0.4,0,0.5c-0.1-0.2-0.1-0.5,0.2-0.5l0,0.4c0.1-0.1,0.3-0.3,0.5-0.2
        c-0.1,0.1-0.2,0.2-0.3,0.3c0,0.2,0,0.4,0.3,0.4c0.1,0.1,0.2,0.3,0.3,0.4c-0.3-0.1-0.6-0.6-0.8,0.1l0.4-0.1c0.1,0.2,0.3,0.3,0.5,0.5
        c-0.2-0.1-0.3-0.2-0.5-0.2c-0.2,0.2-0.3,0.7-0.6,0.4c0.1,0.3-0.2,0.7,0.2,0.8c0-0.1-0.1-0.3-0.1-0.4c0-0.2,0.3-0.1,0.4-0.2
        c0.1-0.1,0.2-0.2,0.2-0.3c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0-0.3-0.1-0.4-0.1c0,0.1,0,0.2,0,0.3c-0.1,0-0.3,0-0.4,0
        c0.1,0.2,0.2,0.3,0.4,0.4c0.1-0.2,0.2-0.3,0.4-0.4c0,0.4-0.3,0.5-0.6,0.5l0-0.2l-0.3,0c0.1,0.2,0.2,0.3,0.3,0.5
        c0.1,0,0.2-0.1,0.3-0.1c-0.1,0.2-0.1,0.5-0.2,0.7c0,0.1-0.1,0.3-0.1,0.4c-0.1-0.2-0.3-0.4-0.4-0.7c0.1-0.1,0.3-0.3,0-0.4
        c0,0.1-0.1,0.3-0.2,0.4c0,0.1,0,0.3,0.1,0.4l0-0.3c0.4,0.1,0.1,0.5,0,0.7c0.1-0.1,0.3-0.2,0.4-0.2c0,0.1-0.1,0.3-0.1,0.4
        c0.2,0.1,0.4,0.2,0.6,0.5c-0.3,0-0.6-0.2-0.8-0.4c-0.2,0-0.4-0.2-0.6-0.4c0,0.2,0.1,0.4,0.2,0.5l0-0.3c0.2,0.1,0.3,0.2,0.5,0.3
        c0,0.1-0.1,0.2-0.1,0.3l-0.2-0.1l0,0.1c0.3,0.1,0.5,0.1,0.7-0.1c0,0.1,0,0.2,0,0.2c-0.1,0-0.3,0-0.4,0c0,0.1,0,0.3,0,0.4
        c0.2,0,0.4-0.2,0.6-0.3c-0.1,0.1-0.2,0.3-0.4,0.4c-0.2,0-0.4,0-0.6,0c0.1,0.3,0.4,0.3,0.4,0.6c-0.1,0-0.3,0-0.4,0.1
        c-0.1,0.3-0.2,0.6-0.1,1c0.1,0.1,0.2,0.2,0.2,0.2c-0.4,0-0.2,0.5-0.3,0.7c0.1-0.1,0.2-0.3,0.2-0.4c0.2,0,0.3-0.1,0.3-0.2l-0.1-0.1
        c-0.1-0.2,0.1-0.4,0.3-0.4c0,0.1,0,0.3-0.1,0.4c0.1,0,0.2,0.1,0.3,0.2c-0.1,0-0.2,0-0.3,0c-0.1,0.2-0.3,0.4-0.1,0.6
        c-0.1,0.2-0.1,0.7,0.3,0.6c-0.1,0.1-0.2,0.2-0.4,0.4c0.3,0,0.4,0.2,0.2,0.4c0-0.1,0-0.2,0-0.3c-0.1,0-0.3,0.1-0.4,0.2
        c0.1-0.2,0.1-0.3,0.3-0.5c0-0.1-0.1-0.2-0.1-0.2c-0.1,0-0.3,0-0.4,0c0-0.1,0-0.4,0-0.6c-0.1,0.2,0,0.4-0.1,0.6
        c0.3-0.1,0.3,0.2,0.2,0.4c-0.1,0-0.3,0-0.4,0c0.1,0,0.3,0.1,0.4,0.1c-0.1,0.2,0,0.4,0.1,0.6c-0.2,0-0.4-0.1-0.6-0.3
        c0.2,0.4,0.4,0.8,0.5,1.3c0.1,0,0.3,0,0.4,0.1c0,0-0.1,0.1-0.1,0.2c-0.1-0.1-0.2-0.1-0.2-0.2c0,0.2-0.1,0.4-0.3,0.6
        c-0.1,0-0.3,0-0.4,0c0,0,0.1,0.1,0.1,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.1,0.2,0.2,0.4c-0.3-0.1-0.6-0.2-0.7-0.4l-0.1,0
        c0.2,0.1,0.3,0.3,0.4,0.5c-0.1,0.4-0.7,0.2-0.6,0.7c0.3-0.2,0.5-0.2,0.6,0c0,0.3-0.3,0.1-0.5,0.1c0,0-0.1,0.1-0.1,0.1
        c0,0,0.1,0.1,0.2,0.1c-0.1,0.2,0.1,0.4,0.2,0.5c0-0.2,0.1-0.5,0.1-0.6c0.1,0.2,0.2,0.4,0.3,0.7c-0.2-0.1-0.4-0.1-0.5,0.1
        c-0.1,0-0.2-0.1-0.3-0.1c0.1,0.2,0.2,0.5,0.3,0.7c-0.1,0.3,0.1,0.5,0,0.8l0.3-0.1c-0.2,0.2-0.3,0.4-0.5,0.6c0.1,0.1,0.2,0.2,0.3,0.2
        c-0.3,0.4,0.5,0.1,0.4,0.5c-0.1,0-0.2,0.1-0.3,0.1l0,0.3c0.1-0.1,0.2-0.2,0.3-0.3l0.1,0c-0.1,0.2-0.2,0.4-0.2,0.6
        c0,0.2-0.1,0.4-0.2,0.6c-0.1-0.2-0.3-0.3-0.4-0.5c0.3,0.4,0.5,0.9,1,1.1l0.1-0.3c0,0.1,0,0.3,0,0.4c0.1,0,0.2,0.1,0.3,0.2l-0.3-0.1
        c0.1,0.2,0.2,0.4,0.4,0.5c-0.3,0.1-0.4,0.4-0.4,0.7c0.1-0.1,0.2-0.3,0.3-0.4c0.1,0.5-0.2,1.1,0.3,1.5c0,0.1,0,0.2-0.1,0.3l0.2,0
        c0,0.2,0,0.3,0,0.5l0.2,0c0.3,0.4-0.4,0.2-0.2,0.6c0.1,0,0.4,0,0.5,0c0,0.2,0,0.4,0,0.6c0.2,0.1,0.6,0.2,0.4,0.5
        c-0.3-0.2-0.6-0.2-0.9-0.3c0.3,0.2,0.4,0.6,0.7,0.8c0-0.1-0.1-0.4-0.2-0.5c0.5,0,0.4,0.6,0.5,0.9l0.2-0.2c0.2,0.3,0.1,0.5-0.2,0.6
        c0.2,0.2,0.4,0.3,0.6,0.5c-0.1,0-0.3,0-0.3,0c0.1,0.2,0.3,0.3,0.5,0.3c-0.1,0.1-0.5,0.2-0.6,0c0-0.1-0.1-0.2-0.1-0.3
        c-0.1,0-0.2,0-0.2,0c0,0,0.1,0.1,0.1,0.2c0.1,0.2-0.1,0.3-0.2,0.3c0.3,0,0.5-0.1,0.7,0.1c-0.2,0.1-0.4,0.4-0.5,0.6
        c0.4-0.1,0.3-0.6,0.8-0.6c-0.1,0.3-0.1,0.6-0.2,0.9c0,0,0.1-0.1,0.1-0.1l0.1-0.3c0.2,0.2,0.1,0.6,0.1,0.9c-0.1,0-0.3,0-0.4,0
        c0.1,0.1,0.2,0.2,0.2,0.3c0.1-0.3,0.3-0.4,0.5-0.5l0,0.2l-0.2,0c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.3,0.1,0.6,0.2,0.9
        c-0.4-0.2-0.7-0.7-1.1-0.8c0.3,0.3,0.5,0.9,1,0.7l-0.1,0.1l0.2,0l-0.1,0.2c0.1,0.1,0.3,0.2,0.4,0.4c0.5-0.5,0.4-1.3,0.9-1.9
        c0.2-0.2,0.1-0.6,0.4-0.6c-0.2-0.5,0.4-0.8,0.3-1.2c0.1-0.1,0.3-0.2,0.4-0.4c-0.5,0.1-0.5,0.6-0.7,0.9c-0.4,0.6-0.8,1.1-1.4,1.4
        c0.3-0.4,0.5-0.8,0.7-1.2c0.3-0.6,0.4-1.2,0.9-1.7c0-0.1-0.1-0.2-0.1-0.3c0.2-0.4,0.5-0.7,0.6-1.1c0.2-0.9,0.7-1.7,0.7-2.6
        c0-0.5,0.2-1.1,0.4-1.5c0.1-0.2,0.1-0.4,0.2-0.6c0-0.4,0.3-0.8,0.3-1.3c0-0.5,0.3-1,0.3-1.5c0.2-0.2,0.3-0.6,0.3-0.9
        c0.2-0.6,0.5-1.2,0.5-1.9c0.2-0.2,0.2-0.5,0.1-0.7c0.1-0.1,0.2-0.2,0.3-0.3c0-0.1,0-0.4,0-0.6l-0.2,0c0.1-0.2,0.2-0.6,0.3-0.7
        c-0.9,0-0.3,0.9-0.8,1.3c0.1,0,0.2,0,0.3,0c-0.1,0-0.2,0.1-0.3,0.2c0,0.2,0,0.5,0,0.7l-0.1,0c0.1,0.6-0.1,1.1-0.4,1.6l0.1,0.1
        c0,0.4-0.2,0.7-0.3,1.1c-0.2,0.4-0.1,0.9-0.4,1.3c-0.1,0.4-0.3,0.8-0.4,1.1c-0.1,0.6-0.5,1.1-0.7,1.6c-0.1,0.3-0.2,0.6-0.4,0.8
        c-0.2,0.3-0.3,0.7-0.6,0.9c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.7,0.4-1.4,0.7-2c0.1-0.2,0-0.7,0.4-0.7c0-0.1-0.1-0.2-0.1-0.3
        c0.1-0.6,0.3-1.2,0.5-1.8c0.1-0.7,0.5-1.4,0.5-2.1c0.2-0.7,0.4-1.4,0.3-2c0.1,0.1,0.2,0.2,0.2,0.3c0-0.3-0.1-0.7,0.2-1
        c0-0.3,0-0.5,0-0.8c0.5-0.7,0.2-1.7,0.6-2.4c-0.1-0.8,0.3-1.5,0.3-2.3c0.2-0.8,0.2-1.6,0.2-2.4c0-0.4-0.1-0.7-0.3-1c0,0,0.1,0,0.1,0
        c0.2,0.3,0.4,0.6,0.4,0.9c0.2,1.1,0.1,2.2,0.1,3.4c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.3-0.1,0.4c0,0,0.1,0,0.1,0c0-0.1,0.1-0.3,0.1-0.4
        c0.1,0,0.2,0.1,0.3,0.1c-0.4-0.5-0.1-1.1-0.1-1.7c0-0.3-0.1-0.7,0.1-1l0.1-0.1c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.4,0-0.5
        c0,0,0.1,0,0.2,0c0-0.5-0.2-1-0.1-1.5c0.1-1.1,0.1-2.2,0.1-3.3c0.1-0.5,0.1-1,0-1.5c-0.1,0.1-0.2,0.3-0.3,0.5
        c-0.3-0.3,0.5-0.9-0.2-0.9c0.1-0.1,0.3-0.2,0.5-0.4c-0.2,0.1-0.5,0.1-0.7,0.2c0-0.3-0.2-0.5-0.1-0.8c-0.1,0-0.2,0-0.3,0
        c-0.1-0.2-0.4-0.4-0.3-0.7c-0.1,0-0.3,0-0.3,0c0-0.3-0.1-0.6-0.3-0.9c-0.3-0.1-0.2,0.2-0.3,0.4l0.3,0c-0.1,0-0.3,0-0.4,0
        c0.1-0.1,0.2-0.2,0.2-0.2c0-0.2,0-0.5,0-0.7c-0.1,0-0.3,0.1-0.4,0.1c0.1-0.7-0.6-1-0.7-1.6c-0.1-0.1-0.2-0.2-0.3-0.3
        c0,0.1-0.1,0.2-0.1,0.3c-0.1-0.2-0.2-0.3-0.3-0.5c0,0,0.1-0.1,0.2-0.1l0-0.1l-0.3-0.3l0.4,0.1c-0.1-0.2-0.2-0.2-0.4-0.1
        c0-0.2,0-0.3-0.1-0.5c-0.3,0-0.5-0.3-0.8-0.3c0,0,0,0,0-0.1c0.1,0,0.2,0,0.3,0c0-0.1,0-0.4-0.1-0.5c-0.1,0-0.3,0.1-0.3,0.1
        c0-0.3,0-0.6,0.2-0.8c-0.1,0.1-0.2,0.1-0.4,0.2c0-0.2-0.4-0.6,0-0.7c-0.1-0.2-0.3-0.1-0.4-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
        c0.1,0,0.2-0.1,0.3-0.2c-0.2-0.2-0.4-0.1-0.6-0.1c0.1-0.1,0.1-0.2,0.2-0.3l-0.3-0.1c0.1-0.1,0.2-0.2,0.3-0.3
        c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0.2-0.2,0.4-0.3,0.5c0-0.2,0-0.4,0.1-0.6c-0.1-0.1-0.2-0.1-0.3-0.2c0.1,0,0.2,0,0.3,0
        c0-0.2-0.1-0.4-0.1-0.6c-0.1-0.1-0.3-0.2-0.4-0.2c0,0.2,0.1,0.3,0.2,0.5c0,0-0.1,0.1-0.2,0.1c0.1-0.3-0.1-0.5-0.3-0.6
        c0.3,0,0.4-0.2,0.4-0.5l-0.2,0.1c0.2-0.4-0.2-0.5-0.5-0.4c0.1-0.3,0.2-0.7,0-1c-0.1,0.1-0.2,0.2-0.3,0.4c0.1,0.1,0.2,0.2,0.2,0.3
        c-0.1-0.1-0.2-0.2-0.3-0.3c0.1-0.1,0.2-0.3,0.1-0.5c-0.1,0-0.2,0-0.3,0l-0.1-0.1c-0.1,0-0.2,0-0.2,0l0-0.1c0.2,0.1,0.4,0.2,0.6,0.3
        c0.1-0.2,0.2-0.4,0.2-0.6c-0.2,0.1-0.5,0.2-0.7,0.2c0.3-0.1,0.4-0.4,0-0.5c0.1-0.1,0.3-0.1,0.4-0.2c-0.1-0.2-0.3-0.2-0.4-0.1
        c-0.1-0.2-0.2-0.3-0.4-0.4c0.1-0.2,0.2-0.4,0.3-0.6c-0.1,0-0.2-0.1-0.4-0.1l0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.2
        c-0.1-0.2-0.3-0.4-0.4-0.5c0.3,0,0.6-0.1,0.8,0.1c0-0.1,0.1-0.2,0.1-0.3c-0.3,0-0.6,0-0.8-0.1c-0.2,0.1-0.3-0.2-0.5-0.3
        c0.1,0,0.3,0.1,0.4,0.1c0-0.1,0.1-0.4,0.1-0.5c-0.1,0-0.2-0.1-0.2-0.1c-0.1-0.4-0.4-0.7-0.8-0.9c0.3-0.1,0.5,0,0.8,0
        c0-0.2,0-0.4,0-0.6c-0.1,0-0.2,0-0.2,0c0,0.1,0.1,0.3,0.2,0.5c-0.3-0.1-0.7-0.2-0.9-0.5l0.3,0c0-0.1-0.1-0.3-0.1-0.4
        c0.1,0.1,0.2,0.2,0.3,0.2c0,0.1-0.1,0.2-0.1,0.2c0.1,0.3,0.5-0.5,0.2-0.5c-0.2-0.2-0.6,0-0.5-0.3c0.2,0,0.4-0.1,0.6-0.1
        c-0.2-0.4-0.7-0.1-0.9,0.2c0-0.2,0.1-0.5,0.3-0.7c0,0-0.1-0.1-0.2-0.1c0-0.1,0.1-0.2,0.2-0.3c0.1,0,0.3,0,0.4,0.1
        c-0.1-0.1-0.2-0.3-0.3-0.4l0.1,0.3c-0.1,0-0.4-0.1-0.5-0.1c0.1-0.1,0.2-0.3,0.2-0.4l0-0.1c-0.1,0.2-0.3,0.4-0.5,0.5
        c0-0.1,0.1-0.3,0.2-0.4l0-0.2c-0.1,0-0.2,0-0.3,0l0-0.2c0.1,0,0.3,0,0.4,0c-0.2-0.1-0.4-0.3-0.6-0.4c0.1,0,0.4,0,0.5,0
        c-0.2-0.3-0.6-0.2-0.6-0.6c0.3,0.1,0.5-0.2,0.5-0.5c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.3,0.1-0.4,0.2c0-0.1,0.1-0.2,0.1-0.2
        l0.2-0.1c-0.2-0.3-0.4-0.5-0.6-0.7c0.2-0.2,0.4,0,0.5,0.1c0.1-0.3,0-0.5-0.4-0.5c0-0.3-0.1-0.6-0.1-0.8c0.3,0.2,0.5-0.5,0.2-0.6
        c-0.1-0.1-0.5-0.2-0.2-0.4c0.1,0,0.2,0,0.3-0.1c0,0,0,0,0-0.1c-0.3-0.1-0.4-0.4-0.6-0.5c0,0.3,0.3,0.7,0.1,1c0,0.5,0.8-0.4,0.5,0.6
        c-0.1,0-0.4-0.1-0.5-0.1c0.1,1,0,2.1,0,3.1c0,0.7-0.2,1.3-0.1,2c-0.1-0.4-0.1-0.9,0-1.3c0.1-0.5-0.2-1,0-1.5
        c-0.2-0.5-0.1-1.2-0.2-1.7c0-0.7-0.4-1.5-0.2-2.2c-0.2-0.4,0-0.9-0.3-1.3c0-0.9-0.5-1.7-0.5-2.6c-0.1-0.4-0.2-0.7-0.3-1.1
        c0.1-0.4-0.2-0.8-0.4-1.2c-0.1-0.4-0.1-0.8-0.2-1.2c-0.6-0.7-0.6-1.7-1-2.5c-0.3-0.4-0.4-0.9-0.3-1.5c-0.5-0.1-0.5-0.7-0.8-1.1
        c-0.2-0.4-0.6-0.7-0.4-1.2c-0.1-0.1-0.2-0.1-0.4-0.2c-0.2-0.4-0.3-0.8-0.6-1.2c0.2-0.4-0.3-0.5-0.4-0.8c0,0-0.1-0.1-0.2-0.1
        c0.1,0,0.2,0,0.2,0l0.1-0.3c-0.2,0-0.4-0.1-0.5-0.3c0,0.1,0,0.3,0,0.4c-0.1-0.1-0.2-0.3-0.3-0.4c0,0,0-0.1,0-0.2c0.1,0,0.2,0,0.2,0
        c0.1-0.4-0.3-0.6-0.4-1c-0.2-0.3-0.2-0.7-0.6-0.9c-0.2,0.2-0.3,0.3,0,0.5c-0.1-0.1-0.2-0.1-0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3
        c-0.1-0.2-0.3-0.4-0.5-0.5c0-0.1-0.1-0.3-0.1-0.4c-0.3,0-0.5-0.1-0.8-0.2c0-0.1,0-0.3,0-0.4l0,0c-0.1,0.4,0.4,0.6,0.7,0.6
        c-0.2-0.4-0.5-0.7-0.8-1c-0.2,0.3-0.5,0-0.6-0.2c0.1,0.1,0.4,0.2,0.5,0c-0.1-0.2-0.3-0.4-0.4-0.6c0,0-0.1,0.1-0.2,0.1
        c0-0.4-0.2-0.7-0.3-1.1c-0.2-0.2-0.5-0.4-0.7-0.6c-0.1,0.1-0.2,0.3-0.2,0.5c0-0.3-0.1-0.6-0.5-0.6c0.2,0,0.5,0.1,0.7,0
        c-0.2-0.1-0.3-0.2-0.5-0.3c-0.5-0.3-0.6-0.9-1-1.3c-0.3-0.1-0.5-0.2-0.7-0.4c-0.1-0.2-0.2-0.5-0.3-0.7c-0.2-0.1-0.3-0.1-0.5-0.1
        c-0.1,0-0.2-0.1-0.3-0.1c0.2,0,0.4,0,0.5,0c-0.2-0.2-0.4-0.4-0.6-0.6c0,0.2-0.1,0.4-0.2,0.6c-0.4,0,0-0.5,0.1-0.7
        c-0.3-0.2-0.5-0.5-0.7-0.7c0,0.1,0,0.3,0,0.5c-0.2-0.1-0.2-0.2-0.1-0.4c-0.2-0.2-0.4-0.4-0.6-0.2c0-0.3,0.3-0.3,0.5-0.3
        c-0.4-0.5-0.9-0.8-1.4-1.2c-0.3,0.5-0.1-0.6-0.6-0.1c0-0.1,0.1-0.2,0.1-0.3c-0.1-0.4-0.6-0.4-0.9-0.2c-0.1-0.1-0.2-0.1-0.4-0.2
        c-0.1-0.1-0.2-0.2-0.4-0.3c0.1,0,0.2-0.1,0.3-0.1c0.1,0.3,0.4,0.4,0.7,0.5c0-0.2-0.1-0.5-0.1-0.7c-0.4,0-0.7-0.3-1-0.4
        c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1,0.1-0.2,0.2-0.3,0.2c0.1-0.1,0.2-0.2,0.2-0.3c-0.3-0.1-0.5-0.6-0.8-0.6c-0.1,0-0.2,0.1-0.3,0.2
        c0,0,0,0-0.1,0l0.1-0.2l-0.3,0c0,0,0,0,0-0.1c0,0,0.1-0.1,0.2-0.1c0-0.3-0.4-0.6-0.8-0.5c0-0.5-0.6-0.6-0.9-0.9
        c-0.6-0.1-0.7-0.7-1.2-1c-0.1,0.1-0.1,0.3,0,0.4c-0.2,0.1-0.3-0.2-0.5-0.3l0.2,0c0-0.1,0-0.2,0.1-0.4c-0.3-0.1-0.5-0.3-0.8-0.4
        c0,0.1,0,0.4-0.1,0.5c0-0.2,0-0.3,0.1-0.5c-0.5-0.4-1.2-0.6-1.7-1.1c-0.1,0.1-0.2,0.2-0.2,0.3c0-0.1,0-0.3,0-0.4
        c-0.2-0.2-0.5-0.3-0.8-0.4c-0.2-0.2-0.3-0.4-0.4-0.6c-0.3,0-0.6-0.2-0.9-0.3c-0.1,0-0.2,0.1-0.2,0.2c-0.4-0.3,0.3,0,0.3-0.4
        c-0.4,0-0.8-0.1-1-0.4c-0.1,0.1-0.2,0.2-0.3,0.3c0.1-0.1,0.2-0.3,0.3-0.3c-0.2,0-0.4,0-0.6-0.3c-0.3-0.1-0.5-0.2-0.7-0.4
        c-0.3,0.1-0.1,0.6-0.2,0.8l-0.1,0c0-0.3,0-0.6,0-0.8c-0.4,0-0.7-0.5-1.1-0.4c0.1-0.3-0.3-0.6-0.6-0.5c-0.1,0-0.2,0-0.3,0
        c-0.3-0.3-0.7-0.3-1-0.5c-0.1,0-0.3,0-0.4,0c-0.1-0.4-0.4-0.6-0.8-0.6c0,0.2,0.1,0.4,0.2,0.6c-0.2-0.1-0.2-0.3-0.2-0.5
        c-0.3-0.3-0.8-0.5-1.2-0.6l0.1,0.2c-0.2,0-0.5,0-0.7,0c0.2-0.2,0.4-0.2,0.6-0.1c-0.1-0.5-0.6-0.4-0.9-0.4c-0.3-0.2-0.6-0.4-1-0.4
        l0.1-0.3c-0.3,0-0.7,0.1-1-0.2c-0.3-0.4-0.8-0.1-1.2-0.4c-0.4-0.1-0.7-0.3-0.9-0.5c-0.6,0.3-1.2-0.3-1.8-0.4c0,0.3,0.4,0.5,0.3,0.9
        c0,0-0.1,0-0.2,0c0.1-0.3-0.2-0.5-0.3-0.8c0.3-0.2,0-0.5-0.2-0.6c0,0.1,0,0.2,0,0.3c-0.1-0.3-0.5-0.2-0.7-0.3
        c-0.2,0.1-0.5,0.1-0.6-0.1c0.1,0,0.3-0.1,0.4-0.1c-0.1-0.2-0.3-0.2-0.5-0.2c-0.3-0.1-0.7-0.2-1-0.4c-0.2-0.5-0.7-0.1-1.1-0.1
        c0.1-0.1,0.3-0.2,0.4-0.3c-0.2,0-0.5,0-0.6,0c-0.2-0.2-0.3-0.3-0.5-0.5c-0.4-0.1-0.9-0.2-1.3-0.3c0,0.1,0,0.3,0,0.4
        c-0.1-0.1-0.1-0.3-0.1-0.4c-0.4,0-0.6-0.2-1-0.4c-0.3-0.1-0.6,0-0.9,0c0,0-0.1-0.1-0.2-0.2c0.2,0,0.4,0,0.7,0.1
        c-0.1-0.4-0.6-0.5-1-0.5c0,0-0.2,0.1-0.2,0.2c0-0.1,0-0.3,0-0.3c-0.3,0.1-0.6,0.1-0.9,0.2c0.1-0.1,0.5-0.1,0.4-0.3
        c-0.2-0.3-0.6-0.3-0.9-0.4c-0.3-0.1-0.5-0.3-0.8-0.4c-0.3-0.1-0.7-0.1-0.8-0.4c-0.1-0.2-0.3-0.6-0.6-0.3c-0.3,0.1-0.6,0.1-0.9,0.2
        c0-0.1-0.1-0.2-0.1-0.2c0.1,0,0.2,0.1,0.4,0.1c0.2-0.2-0.1-0.3-0.3-0.4c-0.3-0.1-0.6-0.3-1-0.2c0-0.2-0.1-0.4-0.1-0.6
        c-0.5,0.4-0.7-0.5-1.2-0.3c0-0.1,0-0.2,0-0.3l-0.3,0c0,0.2,0,0.3-0.1,0.4c0-0.1,0-0.3,0-0.4c-0.3-0.2-0.6-0.4-0.8-0.6
        c-0.1,0.1-0.2,0.2-0.2,0.3c0-0.1,0.1-0.2,0.1-0.3c-0.3-0.2-0.6-0.5-0.9-0.7c-0.1,0.1-0.2,0.1-0.4,0.2c0.1-0.2,0.2-0.3,0.3-0.5
        c-0.3-0.1-0.5-0.3-0.7-0.5c-0.2,0-0.4,0-0.6-0.1c0.1-0.1,0.3,0,0.4-0.1c-0.4-0.4-0.6-0.9-0.8-1.4c-0.6-0.5-0.6-1.4-0.9-2.1
        c-0.2-0.4-0.1-0.9-0.3-1.3c-0.2-0.6-0.1-1.3-0.1-1.9c0-0.3,0-0.5,0.2-0.7c-0.1-0.4-0.2-0.8,0-1.2c-0.1,0-0.3,0.1-0.4,0.1
        c0.2-0.2,0.4-0.5,0.5-0.7c-0.2,0-0.4,0-0.5,0c0.1-0.2,0.3-0.2,0.5-0.2l-0.3-0.3c0.1-0.1,0.3-0.2,0.3-0.3l0-0.2l-0.2,0
        c0,0,0-0.1,0-0.2c0.3,0.4,0.6,0,0.9-0.1c-0.3-0.3,0-0.8,0.2-1c-0.1-0.1-0.2-0.1-0.3-0.2l0.3,0.1c0-0.1,0-0.2,0-0.3
        c0,0.1,0.1,0.3,0.1,0.4c0.1-0.3,0.1-0.8,0.6-0.6c0-0.1,0-0.3,0-0.4c0.1,0,0.3,0,0.4,0c-0.1,0-0.3-0.1-0.4-0.1
        c0.1-0.2,0.3-0.3,0.5-0.4c-0.3-0.1-0.2-0.5-0.1-0.7c0,0.1,0.1,0.3,0.1,0.4c0.1,0.3,0.3,0.1,0.5,0c0-0.1-0.1-0.2-0.2-0.2
        c0.4-0.2,0.6-0.8,1.1-1c0-0.1-0.1-0.2-0.1-0.3c0.2,0,0.4,0,0.6,0.1c-0.2-0.4,0.2-0.8,0.7-0.8C144.7,217.1,144.7,216.9,144.5,216.8
        M197.1,217.9c0-0.1,0-0.3,0-0.4C196.7,217.3,196.8,218.1,197.1,217.9 M28.3,217.9c0.2-0.1,0.2-0.2,0-0.4
        C28.1,217.6,28.1,217.8,28.3,217.9 M142.7,217.7C142.8,217.8,142.8,217.8,142.7,217.7 M305,217.6c-0.1,0.2,0,0.3,0.2,0.2
        C305.3,217.6,305.2,217.5,305,217.6 M309.4,217.6c-0.1,0.2,0,0.3,0.2,0.2C309.7,217.6,309.6,217.5,309.4,217.6 M317.2,218
        c0.2-0.1,0.3-0.4,0.1-0.5C317.1,217.5,317,217.9,317.2,218 M486.3,217.6c-0.2,0.1,0,0.4,0.2,0.3
        C486.6,217.8,486.5,217.4,486.3,217.6 M343.7,218.1c0.2,0.1,0.3,0.1,0.5,0.2c0.1-0.2,0.2-0.3,0.2-0.5
        C344.2,217.9,343.9,218,343.7,218.1 M197.8,219.4l0.2,0c-0.1-0.3-0.1-0.6-0.3-0.9c0.1,0,0.3-0.1,0.4-0.1c-0.1-0.1-0.3-0.2-0.3-0.3
        C197.4,218.5,197.8,219,197.8,219.4 M316.3,218.3c0,0.3,0.6,0.1,0.4-0.1C316.5,218.1,316.4,218.2,316.3,218.3 M28.2,218.3
        c0,0.2,0,0.3,0.2,0.2C28.5,218.3,28.4,218.3,28.2,218.3 M486.4,218.3c-0.2,0.1-0.2,0.2,0,0.3C486.5,218.5,486.5,218.4,486.4,218.3
        M241.5,218.6c-0.1,0.3,0.3,0.2,0.4,0.2c0-0.1,0-0.3,0-0.3C241.8,218.5,241.6,218.5,241.5,218.6 M333.6,218.6L333.6,218.6l0.1,0.2
        l0.1-0.1v-0.1C333.8,218.5,333.7,218.6,333.6,218.6 M278,218.7c-0.1,0.1-0.3,0.3-0.3,0.4C278,219.2,278.5,218.6,278,218.7
        M299.4,218.7C299.4,219.2,299.8,218.5,299.4,218.7 M316,218.8L316,218.8L316,218.8L316,218.8L316,218.8 M316.5,218.6
        c-0.1,0.3-0.2,0.5-0.3,0.8c0.1,0,0.2,0,0.2,0c-0.1-0.3,0.1-0.5,0.2-0.7L316.5,218.6 M187.9,219c-0.1,0.2,0,0.3,0.2,0.2
        C188.2,219,188.1,219,187.9,219 M214.6,219L214.6,219L214.6,219L214.6,219L214.6,219 M326.1,219c-0.1,0.2,0,0.3,0.3,0.2
        C326.4,219,326.3,219,326.1,219 M327.4,219C327.4,219.1,327.4,219.1,327.4,219 M472.9,219.5c0.2,0,0.6-0.3,0.7,0
        c0-0.2-0.1-0.3-0.1-0.5c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1-0.1-0.2-0.3-0.3-0.4C472.7,218.9,472.6,219.5,472.9,219.5 M260,219.1
        C260,219.6,260.4,218.9,260,219.1 M260.2,219.5c0.1,0.2,0.5,0,0.3-0.2C260.4,219.2,260.1,219.4,260.2,219.5 M259.4,219.5
        c0,0.2-0.1,0.5-0.2,0.7c0.1,0,0.2,0.1,0.2,0.2c-0.1,0-0.2,0.1-0.2,0.2c0,0.4,0.2-0.1,0.3-0.2c-0.1-0.4,0.2-0.8,0.4-1.1
        C259.8,219.4,259.6,219.4,259.4,219.5 M307.5,219.4C307.5,219.9,307.9,219.2,307.5,219.4 M166.8,219.6c0,0.2,0,0.3,0.2,0.3
        C167.1,219.7,167,219.6,166.8,219.6 M167.3,219.6c-0.1,0.2,0,0.3,0.3,0.2C167.6,219.6,167.5,219.5,167.3,219.6 M315.4,219.6
        c-0.2,0.1-0.2,0.2,0,0.3C315.5,219.8,315.5,219.7,315.4,219.6 M333.2,219.6c-0.1,0.2-0.2,0.4-0.1,0.6c0.1,0,0.2,0.1,0.3,0.1
        C333.3,220,333.4,219.7,333.2,219.6 M374.1,219.7c0.2,0.5-0.6,0.6-0.9,0.9c-0.1,0-0.3,0-0.3,0c-0.2,0.5-0.6,0.8-1.1,0.9
        c-0.1,0.1-0.1,0.3-0.2,0.5c-0.5,0-0.7,0.5-1,0.8c-0.3-0.3-0.4,0.2-0.7,0.3c0,0.1-0.1,0.2-0.1,0.2c-0.2,0.1-0.4,0.3-0.7,0.3
        c-0.1,0.3-0.4,0.4-0.6,0.7c-0.1,0-0.2,0-0.3,0c0,0.5-0.5,0.6-0.8,0.8c-0.5,0.6-1.2,1-1.7,1.6c-0.2,0.1-0.4,0.2-0.4,0.4
        c-0.1,0-0.2,0-0.3,0c0,0.5-0.5,0.5-0.9,0.7c0,0.1,0,0.2,0,0.3c-0.4,0.1-0.5,0.5-0.9,0.6c0,0.6-1,0.4-0.8,1.1c-0.2,0-0.3,0-0.5,0
        c-0.1,0.3-0.2,0.8-0.6,0.5c0,0.4-0.3,0.7-0.7,0.6c0,0.3-0.3,0.6-0.6,0.6c-0.2,0.2-0.2,0.7-0.6,0.6c-0.1,0.2-0.2,0.3-0.3,0.5
        c-0.2,0.1-0.4,0.2-0.6,0.4c0,0.3-0.2,0.4-0.4,0.5c-0.3,0.5-0.8,0.8-1.1,1.3c-0.1,0-0.2-0.1-0.3-0.1c0,0.1,0,0.3,0,0.4
        c-0.3,0.2-0.8,0.3-0.8,0.7c-0.6,0.4-1,1-1.5,1.5c-0.4,0.3-0.5,0.7-0.9,1c-0.4,0.6-1,1.1-1.3,1.7c-0.5,0.3-0.8,0.9-1.1,1.4
        c-0.4,0.3-0.6,0.7-0.9,1.1c-0.4,0.5-0.6,1.2-1.2,1.5c-0.1,0.2-0.1,0.4-0.3,0.6c-1.1,0.8-1.5,2.2-2.5,3.1c-0.3,0.3-0.3,0.7-0.6,1
        c-0.3,0.2-0.4,0.6-0.7,0.8c-0.1,0.3-0.3,0.6-0.6,0.8c-0.5,0.7-0.7,1.5-1.3,2.1c-0.4,0.7-1,1.2-1.4,1.9c-0.2,0.4-0.7,0.7-0.8,1.2
        c-0.3,0.4-0.2,0.9-0.7,1c0,0.4-0.3,0.7-0.6,0.9c-0.1,0.3-0.1,0.5-0.4,0.7c-0.1,0.3-0.2,0.6-0.4,0.9c-0.1,0.2-0.4,0.4-0.5,0.6
        c0,0.4-0.5,0.6-0.7,0.8c0.1,0,0.3,0,0.4,0l0,0.2c-0.4-0.1-0.6,0.4-0.4,0.7c-0.1,0-0.2,0-0.2,0c-0.1,0.2-0.2,0.5-0.4,0.7
        c-0.2,0.2-0.5,0.5-0.3,0.8c-0.3,0.2-0.6,0.5-0.6,0.9c0,0-0.1,0-0.2,0c0.3,0.3-0.1,0.6-0.4,0.6c0,0.2,0,0.4,0.1,0.6
        c-0.1-0.1-0.2-0.2-0.3-0.3c0,0.1-0.1,0.3-0.1,0.4l-0.2,0l0,0.2c0.1,0,0.4-0.1,0.5-0.2c-0.3,0.3-0.6,0.5-0.6,0.9
        c-0.1,0-0.3-0.1-0.3-0.1c0,0.1,0.1,0.3,0.1,0.4c-0.1,0-0.3,0.2-0.3,0.2c0.1,0,0.4,0,0.5,0c-0.2,0.1-0.5,0.3-0.7,0.3l0,0.1
        c0.1,0,0.2,0,0.3,0c0,0.1,0,0.2,0,0.3c-0.1,0-0.3,0-0.4,0c0.1,0.1,0.2,0.3,0.2,0.3c-0.2,0.2-0.3,0.4-0.5,0.6c0,0.3-0.2,0.5-0.1,0.7
        c-0.1,0-0.2,0-0.2,0c0,0.1,0,0.3,0.1,0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.3,0.2,0.2,0.4,0.2,0.6c-0.1,0-0.2,0-0.3,0l-0.1,0.3
        c0.1,0,0.3-0.1,0.4-0.1c-0.2,0.2-0.4,0.4-0.5,0.7c0,0.2-0.1,0.3-0.3,0.3c0,0.1,0,0.3,0,0.5l-0.2,0c0,0.1,0.1,0.3,0.1,0.4
        c-0.1,0-0.2,0-0.3,0c0-0.1,0.1-0.3,0.1-0.4l-0.3,0c0.3,0.6-0.3,1-0.3,1.6c-0.1,0-0.2,0.1-0.3,0.2c0.1,0.3-0.2,0.5-0.5,0.7
        c0,0,0.1,0.1,0.2,0.2c-0.5,0.9-0.9,1.9-1.2,2.8c-0.2,0.4-0.2,1-0.5,1.4c0.1,0.5-0.5,0.8-0.2,1.3c-0.1,0-0.2,0.1-0.3,0.2
        c0,0.2-0.1,0.3-0.1,0.5c0,0,0.1,0.1,0.1,0.2l-0.2,0c0.1,0.2,0.1,0.4-0.1,0.5c0,0.3-0.2,0.7-0.5,0.8c0.2,0.3,0.3,0.9-0.2,0.9
        c0.1,0.5,0,1.1-0.1,1.5c-0.1,0.1-0.4,0.3-0.2,0.5c0,0.2,0,0.4-0.1,0.6c-0.1,0-0.3,0.1-0.4,0.1c0.1,0.1,0.2,0.3,0.2,0.4
        c0,0.3-0.2,0.6-0.4,0.8c0.4,0.2,0.1,0.6,0,0.9c0,0.2,0,0.5,0,0.7c0.1-0.4,0.5-0.7,0.7-1.1c0-0.7,0.6-1.1,0.9-1.7
        c-0.3-0.4,0.1-1,0.5-1.1c0-0.2,0.2-0.3,0.3-0.4c0-0.4,0.4-0.4,0.5-0.7c-0.1,0-0.3,0-0.4,0c0-0.1,0.1-0.2,0.2-0.3
        c0,0.1,0.1,0.2,0.2,0.3c0.2-0.4,0.6-0.7,0.8-1.1c-0.1-0.4,0.6-0.6,0.5-1c0.3-0.2,0.3-0.6,0.5-0.9c0.4-0.5,0.6-1.1,1-1.5
        c0.5-0.5,0.8-1.3,1.2-1.9c0.2-0.3,0.2-0.6,0.4-0.8c0.5-0.6,1-1.3,1.2-2.1c0.1-0.1,0.4-0.2,0.4-0.4c0.1-0.7,0.6-1.3,1-1.8
        c0.4-0.3,0.5-0.9,1-1c0.2-0.7,0.9-1.1,1.2-1.8c0.8-0.9,1.5-1.9,2.3-2.8c0.4-0.4,0.7-1,1.2-1.3c0.2-0.4,0.6-0.6,0.8-1
        c0.8-1,1.6-2,2.3-3c0.4,0.3,0.8,0.4,1.3,0.3c0.5-0.1,1,0.2,1.5,0.3c0.2,0.3,0.5,0.4,0.9,0.3c0.6,0.3,1.3-0.1,2,0.1
        c0.4-0.3,0.9,0,1.3-0.1c0.3-0.1,0.6,0.2,0.9,0.1c0.4,0.2,0.9-0.1,1.3,0.2c0,0,0.1-0.1,0.2-0.1c0.4,0.1,0.8-0.1,1.2,0.2
        c0.2-0.1,0.3-0.2,0.5-0.1c0.4,0.1,0.8-0.2,1.2,0.1c0.1-0.4,0.4-0.2,0.7-0.1c0.3,0,0.6-0.1,1,0c0.2-0.3,0.7,0.1,0.8-0.3
        c0,0,0.1,0.1,0.2,0.2c0.4-0.3,0.9-0.1,1.2-0.4l0,0.3c0-0.1,0.1-0.2,0.2-0.3c0.9-0.2,1.9-0.5,2.7-0.9c0.5,0,0.8-0.4,1.3-0.5
        c0.4-0.1,0.7-0.5,1.1-0.5c0.1-0.1,0.1-0.3,0.3-0.4c0.5-0.1,0.9-0.4,1.3-0.6c0.7-0.5,1.5-0.8,2.3-1.3c0.8-0.5,1.7-1,2.5-1.5
        c0.3-0.1,0.4-0.3,0.7-0.4c0.2-0.2,0.3-0.6,0.6-0.6c0.3-0.1,0.6-0.3,0.9-0.4c-0.1-0.3,0.1-0.7,0-1c-0.2-0.5,0.1-1,0-1.5
        c-0.1-0.3,0.1-0.7,0.1-1c0-0.6-0.2-1.2-0.2-1.8c0.1-0.7,0.1-1.4-0.1-2c0.1-0.8-0.2-1.5,0-2.3c-0.1-0.3-0.1-0.6,0-0.9
        c-0.2-0.6-0.2-1.1-0.1-1.7c-0.1-0.3-0.2-0.6-0.2-1c-0.3,0.1-0.6,0.3-0.8,0c-0.2-0.3-0.5,0.1-0.3,0.3c0,0-0.1,0-0.2,0l-0.2,0.2
        c-0.1-0.1-0.3-0.2-0.4-0.3c0,0.1-0.1,0.3-0.1,0.3c-0.6,0.1-1.2,0.4-1.8,0.5c0,0-0.2-0.1-0.2-0.1c0,0.5-0.5,0.3-0.8,0.5
        c-0.2,0.2-0.4,0.5-0.7,0.5c-0.3,0-0.5,0.1-0.8,0.3c0,0.1-0.1,0.2-0.1,0.2c-0.5,0.1-1,0.4-1.4,0.7c-0.3,0.3-0.6,0.6-1,0.7
        c-0.3,0.5-0.8,0.6-1.2,0.9c-0.4,0.2-0.8,0.6-1.1,1l0.1-0.4c0,0-0.1,0.1-0.2,0.1l-0.1,0.2c-0.1,0-0.2,0-0.3,0
        c0.1,0.1,0.1,0.3,0.2,0.4c-0.1,0-0.2,0-0.3,0.1c0,0.1,0,0.2,0,0.2c-0.3,0-0.6,0.3-0.3,0.5l-0.1-0.1c-0.2,0.3-0.3,0.2-0.3-0.1
        c-0.5,0,0.3,0.6-0.1,0.6l0,0.1c-0.2-0.1-0.5,0.1-0.7,0.2c0,0.1,0.1,0.3,0.1,0.5c0-0.1-0.1-0.2-0.2-0.2c-0.1,0.2-0.1,0.4-0.2,0.6
        c-0.3-0.2-0.5-0.1-0.6,0.2l0.3-0.1c0.3,0.2-0.1,0.5-0.3,0.1c0.3,0.5-0.7,0.5-0.3,1c-0.1,0-0.2-0.1-0.3-0.1c0.2,0.3-0.1,0.4-0.3,0.5
        c-0.1,0.2-0.1,0.4-0.3,0.6c-0.2,0.2-0.2,0.6-0.5,0.8c-0.1,0.2-0.3,0.3-0.2,0.5l-0.2-0.1c0,0.1,0.1,0.2,0.1,0.3l-0.1,0
        c0,0.2-0.1,0.5-0.1,0.7c-0.1,0-0.2,0-0.2,0c0,0.2,0,0.4,0,0.6c0,0.1-0.1,0.3-0.1,0.4c0,0.1,0.1,0.2,0.1,0.3
        c-0.3,0.4,0.1,0.8-0.1,1.2c0.2,0.1,0.1,0.4,0.1,0.6l0.1,0c-0.1,0.8,0.4,1.4,0.6,2.1c0,0,0,0,0,0c-0.1-0.2-0.3-0.4-0.2-0.6
        c-0.1-0.1-0.2-0.3-0.3-0.4c0-0.1,0-0.2,0-0.3c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.2-0.1-0.3-0.1-0.5l-0.1,0c-0.1-0.3-0.2-0.5-0.3-0.7
        c0,0,0.1-0.1,0.1-0.1c-0.1-0.3-0.3-0.7-0.2-1c0-0.2,0-0.5-0.2-0.6c0.1,0,0.2,0,0.3,0c-0.1-0.3-0.2-0.5-0.3-0.8
        c0.1,0.1,0.3,0.2,0.4,0.3c-0.1-0.3-0.3-0.5-0.3-0.8c0.1-0.3-0.1-0.6-0.2-0.9c0-0.2,0-0.5,0-0.7c0.1,0,0.2,0,0.3,0
        c-0.1-0.2-0.2-0.3-0.3-0.5c0.1-0.1,0.1-0.2,0.2-0.3c-0.2-0.2-0.1-0.5-0.1-0.7c0.4-0.3,0.3-0.9,0.2-1.3c0-0.2,0.1-0.3,0.1-0.4
        c0.2,0,0.3,0,0.5,0c-0.1,0-0.3,0-0.4,0c0-0.2,0-0.5,0-0.6c0.2,0.1,0.3,0.3,0.5,0.4c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.2-0.4-0.5-0.1-0.6
        c0-0.2-0.1-0.5-0.1-0.7c0.1,0,0.2,0,0.3,0c-0.1,0-0.2,0-0.3,0c0.2-0.7,0.5-1.4,0.8-2.1c0.1-0.4,0.2-0.8,0.5-1.1
        c0.2-0.5,0.3-1.1,0.6-1.5c0.3-0.4,0.6-0.8,0.9-1.2c0.1-0.5,0.6-0.7,0.8-1.1c0.4-0.8,1.1-1.4,1.7-2.2c0.3-0.3,0.5-0.7,1-0.9
        c0.1-0.1,0.1-0.2,0.2-0.3c0.6-0.3,0.9-1,1.5-1.3c0.2-0.1,0.2-0.3,0.4-0.4c0.4-0.2,0.5-0.7,0.9-0.9c0.9-0.9,1.9-1.7,2.9-2.6
        c0.3-0.2,0.5-0.4,0.8-0.4c0-0.1,0.1-0.2,0.2-0.2c0.6-0.1,0.8-1.1,1.5-1c-0.1,0-0.2-0.1-0.3-0.1c0.8-0.5,1.5-1.2,2.2-1.8
        c0.4-0.3,0.7-0.7,1.1-1c-0.1-0.2-0.2-0.3-0.3-0.4c0,0.1,0,0.3-0.1,0.4c0-0.2,0-0.3,0-0.5c0.2-0.1,0.3-0.2,0.4-0.4
        c-0.1,0-0.3,0-0.4,0c0.1-0.2,0.3-0.4,0.4-0.5c0.1-0.2,0.3-0.4-0.1-0.5c0,0.1,0,0.3,0,0.4c-0.1-0.1-0.2-0.2-0.3-0.4
        c0.2-0.1,0.4-0.3,0.5-0.5c-0.2,0-0.3,0-0.5,0c0.1,0,0.2-0.1,0.2-0.1c-0.3-0.3,0-0.5,0.2-0.7c-0.2-0.1-0.3-0.1-0.4-0.2
        c0-0.1-0.1-0.2-0.1-0.4c0.1-0.1,0.2-0.3,0.2-0.4c-0.5-0.1-0.3-0.7-0.7-0.9c-0.1-0.2-0.2-0.5-0.3-0.7c0.1-0.1,0.1-0.3,0.2-0.4
        C374.5,219.8,374.3,219.7,374.1,219.7 M29.8,219.9c-0.1,0.2,0,0.3,0.3,0.2C30.1,219.9,30,219.8,29.8,219.9 M290.3,219.9
        C290.3,220,290.3,220,290.3,219.9 M484.7,219.9c-0.1,0.2,0,0.3,0.2,0.2C485,219.9,484.9,219.8,484.7,219.9 M28.9,220
        C29,220.1,29,220.1,28.9,220 M485.8,220C485.8,220.1,485.8,220.1,485.8,220 M171.8,220.4c-0.1,0.2,0,0.3,0.2,0.2
        C172,220.5,172,220.4,171.8,220.4 M342.9,220.4c-0.2,0.1-0.2,0.2,0,0.3C343.1,220.6,343.1,220.5,342.9,220.4 M138.5,221.4
        c0.1,0,0.2-0.1,0.3-0.1c0-0.1,0.1-0.3,0.1-0.4c0.1,0,0.2,0,0.3,0c0-0.1,0-0.4,0.1-0.5C138.8,220.4,138.7,221,138.5,221.4
        M145.1,220.8c0,0.1,0,0.2,0,0.3c0.2-0.1,0.3-0.2,0.3-0.4C145.3,220.7,145.1,220.7,145.1,220.8 M239.1,220.5
        c-0.1,0.2,0.2,0.4,0.4,0.3C239.5,220.6,239.2,220.3,239.1,220.5 M340.4,220.8c0.6,0,0.2,0.7,0.6,0.8c0-0.2,0-0.4,0.1-0.6l-0.3,0.1
        c0-0.2,0-0.3-0.1-0.5C340.6,220.6,340.5,220.7,340.4,220.8 M375,220.6C374.7,221,375.4,220.6,375,220.6 M275.7,220.7
        C275.8,220.8,275.8,220.8,275.7,220.7 M303.8,220.8c-0.2,0.2,0.2,0.3,0.3,0.2C304.3,220.7,303.9,220.6,303.8,220.8 M305.8,221
        c0.2,0,0.4-0.2,0.6-0.2c-0.1-0.1-0.4-0.2-0.5-0.1C305.7,220.8,305.7,220.9,305.8,221 M199.6,220.9c0,0.1,0,0.3,0.1,0.4
        c0.1,0,0.2,0,0.3-0.1C200.1,221.1,199.8,220.8,199.6,220.9 M484.1,221.2C484,221.7,484.5,221,484.1,221.2 M237.7,221.9
        c0.2,0.1,0.5-0.2,0.6-0.4C238.1,221.4,237.7,221.6,237.7,221.9 M340.3,221.8c-0.1,0-0.2-0.1-0.3-0.2c0,0.1,0.1,0.2,0.1,0.4
        c0.1-0.1,0.3-0.1,0.5-0.1c0-0.2,0-0.3,0-0.5C340.5,221.5,340.4,221.7,340.3,221.8 M173.3,221.5c0,0.2,0,0.4,0.1,0.6
        c0.1-0.1,0.3-0.2,0.4-0.3c-0.1,0-0.2,0.1-0.3,0.1c0-0.1,0-0.3,0-0.4L173.3,221.5 M341.3,221.6c-0.1,0.1-0.5,0.5-0.1,0.5
        C341.5,222.1,341.6,221.6,341.3,221.6 M190.6,221.7c0,0.1,0.1,0.2,0.1,0.3c0.3,0,0.5,0.2,0.6,0.4c0.1-0.1,0.2-0.2,0.3-0.2
        C191.2,222.2,191,221.8,190.6,221.7 M323.4,221.7c-0.1,0.2,0,0.3,0.2,0.2C323.7,221.7,323.6,221.7,323.4,221.7 M303.2,222
        c0.1,0.3,0.6,0,0.5-0.2C303.5,221.7,303.3,221.9,303.2,222 M237.3,222c-0.1,0.2,0,0.3,0.2,0.3C237.6,222,237.5,221.9,237.3,222
        M341.4,222.6c0.2-0.1,0.3-0.3,0.4-0.4c0-0.1,0.1-0.2,0.2-0.2C341.7,222.1,341.4,222.3,341.4,222.6 M322.7,222.2
        c-0.1,0.2,0.2,0.3,0.4,0.2C323.2,222.1,322.8,222,322.7,222.2 M339.9,222.2c0,0.1,0,0.3,0.1,0.3c-0.2,0.1-0.5,0.1-0.6,0.3
        c0.2,0,0.5-0.1,0.7-0.1c-0.1,0.3-0.3,0.4-0.6,0.2c-0.2,0.2-0.3,0.4,0,0.5c0.1-0.1,0.3-0.3,0.5-0.4c0.2-0.2,0.3-0.4,0.1-0.6
        c0.3,0,0.5-0.2,0.6-0.5c-0.2,0.1-0.3,0.1-0.5,0.2C340.1,222.3,340,222.2,339.9,222.2 M331.9,222.6c0.2-0.1,0.2-0.2,0-0.3
        C331.7,222.4,331.7,222.5,331.9,222.6 M174.1,222.5C173.8,222.9,174.5,222.5,174.1,222.5 M322.4,222.5c-0.1,0-0.4,0.2-0.2,0.3
        C322.5,223,322.9,222.3,322.4,222.5 M140.3,223.3c0.1,0,0.2,0.1,0.3,0.1c0-0.2,0-0.4-0.1-0.6C140.4,222.9,140.3,223.1,140.3,223.3
        M173.8,222.9C173.9,222.9,173.9,222.9,173.8,222.9 M320.6,223c-0.2,0-0.1,0.4,0.1,0.3C320.9,223.3,320.8,222.9,320.6,223
        M44.6,223.3c-0.1,0-0.3,0.3-0.1,0.3C44.7,223.6,44.9,223.3,44.6,223.3 M470.1,223.3c-0.1,0.2,0.1,0.4,0.3,0.3
        C470.4,223.5,470.2,223.2,470.1,223.3 M174.2,223.4c0.1,0.2,0.3,0.3,0.4,0.5c0.1-0.2,0.2-0.3,0.3-0.5c-0.1,0-0.2,0.1-0.2,0.2
        C174.5,223.5,174.3,223.4,174.2,223.4 M338.4,224.3c0.2-0.1,0.4-0.1,0.6-0.2c-0.1,0.3-0.4,0.5-0.5,0.7c0-0.1,0-0.3-0.1-0.4
        c-0.2,0.2-0.5,0.5-0.7,0.6c0.1,0,0.3,0.1,0.5,0.1c-0.3,0.3-0.7,0.3-1,0.6c0,0.2,0,0.3-0.1,0.5c-0.4,0.1-0.6,0.4-0.7,0.7
        c0.1,0.2,0.2,0.3,0.2,0.5c0.1,0,0.3,0,0.3,0c0.1-0.3-0.1-0.7,0.2-0.9c0.3-0.4,0.5-0.8,0.9-1.1c-0.1,0.3,0.1,0.5,0.3,0.6
        c0-0.1,0-0.2,0-0.3c0.4-0.1,0.1-0.3,0-0.4c0.1-0.1,0.2-0.2,0.3-0.3c0,0.1,0.1,0.2,0.2,0.3l0.1-0.1l-0.2-0.1c0.2-0.2,0.3-0.5,0.5-0.7
        c0-0.3,0.2-0.6,0.4-0.8l-0.2,0c0-0.1,0.1-0.2,0.1-0.3C338.7,223.4,338.7,224.1,338.4,224.3 M482.1,223.6
        C481.8,224,482.5,223.6,482.1,223.6 M89.9,223.7C89.7,224.1,90.3,223.7,89.9,223.7 M424.8,223.7
        C424.5,224.1,425.2,223.8,424.8,223.7 M320.5,224C320.6,224.1,320.6,224.1,320.5,224 M201.7,224.3c0,0.1,0,0.2,0,0.2
        c0.2,0.1,0.4,0.2,0.6,0.4c0.1-0.1,0.2-0.2,0.3-0.4c-0.2,0-0.4,0.1-0.5,0.1C201.9,224.6,201.8,224.5,201.7,224.3 M175.4,224.6
        c0,0.2,0.1,0.3,0.3,0.3C175.7,224.6,175.6,224.5,175.4,224.6 M194.1,224.7c0.1,0.2,0.2,0.2,0.4,0
        C194.3,224.5,194.2,224.5,194.1,224.7 M320,224.6c-0.2,0.1-0.2,0.2,0,0.3C320.2,224.7,320.2,224.6,320,224.6 M337.2,225.1
        c-0.2,0.1-0.2,0.2,0,0.3C337.4,225.3,337.4,225.2,337.2,225.1 M49,225.8c0,0.1,0.2,0.4,0.4,0.4C49.5,226,49.2,225.7,49,225.8
        M336.2,226.1c-0.1,0.2,0,0.3,0.2,0.2C336.5,226.1,336.4,226.1,336.2,226.1 M83.5,226.4C83.6,226.5,83.6,226.5,83.5,226.4
        M337.1,226.6c0.1,0.1,0.2,0.2,0.2,0.3c0.3,0,0.3-0.4,0.4-0.5C337.6,226.5,337.4,226.5,337.1,226.6 M338.6,226.4
        c-0.1,0.2,0,0.2,0.2,0.2C338.9,226.4,338.8,226.3,338.6,226.4 M431.2,226.4C431.2,226.5,431.2,226.5,431.2,226.4 M285.8,226.6
        c0,0.2,0.1,0.4,0.1,0.7c-0.2,0.1-0.5,0.3-0.4,0.6c0,0.1,0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.2c0-0.1-0.1-0.3-0.1-0.5
        c-0.1,0.1-0.2,0.3-0.3,0.4c0,0-0.1,0-0.1,0l0-0.3c-0.2,0.2-0.3,0.4-0.5,0.5c0.1,0,0.3,0.1,0.4,0.2c0.1-0.1,0.3-0.2,0.4-0.2
        c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0-0.3,0-0.4,0c0,0.3-0.2,0.6-0.4,0.8c0.1,0,0.3,0,0.4,0c-0.4,0.5-0.8,1.2-1.4,1.5
        c0.2,0,0.3,0,0.5,0.1c-0.2,0.1-0.4,0.3-0.5,0.5c-0.2,0.2-0.6,0.6-0.2,0.8c-0.2,0.2-0.4,0.4-0.4,0.7c-0.1-0.1-0.3-0.2-0.3-0.3l0,0
        c0,0.1,0,0.2,0,0.3c0.4,0.1,0.1,0.4,0,0.7c-0.1,0-0.2-0.1-0.3-0.1c0,0.4,0.2,0.9-0.2,1.1c-0.2,0.1,0.1,0.4,0,0.6
        c-0.1,0.2-0.3,0.3-0.3,0.6c0,0.5-0.2,1-0.3,1.5c0,0.2,0,0.5,0,0.7c-0.1,0.1-0.2,0.3-0.2,0.3c0.1,0.5,0.2,1.1,0,1.6
        c0.2,0.3,0.2,0.6-0.1,0.8c0.2,0.4,0.2,0.8,0.1,1.3c0.3,0.4,0.2,0.9,0.2,1.3c0.1,0.2,0.2,0.3,0.3,0.5c0,1,0.2,2,0.2,3
        c0.1,0.5,0,1,0.3,1.4c0.2,0.3,0,0.7,0.1,1c0.2,0.3,0.6,0.5,0.8,0.7c-0.1,0.5-0.2,1.1-0.4,1.6c-0.3,0.9,0.3,1.7,0.1,2.6
        c-0.3,1.9,0.1,3.8-0.4,5.6c-0.1,0.6-0.1,1.2-0.1,1.8c0,1.3,0,2.7,0,4c0.1,0.4-0.1,0.8,0.2,1.1c-0.1,0.5-0.3,0.9-0.5,1.4
        c-0.1,0.4,0,1,0.4,1.2c-0.2,0.3-0.4,0.7-0.2,1.1c0.1,0.3,0.4,0.5,0.6,0.8c-0.2,0.3-0.5,0.6-0.6,1c0,0.3,0.1,0.7,0.2,1
        c0,0.6,0.2,1.1,0.1,1.7c0.3-0.2,0.5-0.4,0.8-0.5l0-0.1c0.3,0.1,0.4,0,0.4-0.4c0.1,0.2,0.2,0.3,0.3,0.5c-0.3,0.1-0.6,0.2-0.9,0.3
        c-0.2,0.2-0.4,0.3-0.6,0.3c0.1,1,0.2,2.1,0.2,3.1c0.1,0.5-0.2,1.1,0.1,1.6c0.2,0.5,0.1,1,0.3,1.5c0.2,0.4-0.2,0.8,0,1.1
        c0.3,0.4,0.2,1,0.1,1.5c0.1,0.2,0,0.5,0,0.8c0.5,0.5,1.1,0.8,1.6,1.2c0.1-0.1,0.2-0.3,0.3-0.4l0.1,0.1c-0.1,0.2-0.1,0.5,0.1,0.7
        c0.1-0.2,0.3-0.4,0.1-0.6c-0.1-0.4,0.4-0.1,0.5,0.1c0-0.2-0.1-0.4-0.2-0.5c-0.3,0.1-0.6,0.2-0.8,0.3c0-0.2,0-0.6,0-0.8
        c0.2,0.1,0.4,0.2,0.6,0.4c-0.1-0.2-0.2-0.4-0.2-0.6l-0.1,0.3c-0.1-0.1-0.2-0.2-0.2-0.4c0.1-0.1,0.4-0.2,0.6-0.3
        c-0.4-0.1-1,0.1-0.9,0.6c0,0-0.1-0.1-0.2-0.2c0.1-0.1,0.1-0.2,0.1-0.4c0,0,0.1,0,0.2,0c0.2-0.2,0.4-0.5,0.4-0.8
        c-0.3,0.1-0.4,0.6-0.8,0.6c0.2-0.3,0.4-0.5,0.7-0.8c-0.2,0-0.4,0.1-0.5,0.1c0-0.2-0.1-0.4-0.1-0.7c0.2,0.1,0.4,0.4,0.5,0.1
        c-0.2-0.2-0.4-0.3-0.6-0.5c0.2,0.1,0.4,0,0.5-0.2c-0.1,0-0.2,0-0.3,0l0-0.1c0.3-0.1,0.6-0.1,1-0.1c-0.1,0-0.2,0-0.3,0l0.1-0.2
        c-0.1,0.1-0.2,0.1-0.2,0.2c-0.3-0.1-0.3-0.4-0.4-0.6c0.1-0.1,0.1-0.2,0.2-0.3c0.1,0.1,0.2,0.2,0.2,0.2c-0.1-0.3-0.3-0.5-0.5-0.8
        c-0.2,0-0.3,0.1-0.4,0.1c0.2-0.2,0.5-0.4,0.7-0.6c-0.2-0.3-0.6-0.5-0.7-0.9c0.1,0.1,0.3,0.2,0.3,0.2c0.1,0,0.2-0.1,0.3-0.1
        c0,0.1,0,0.3,0.1,0.4c0-0.1,0-0.2,0-0.3c-0.1-0.1-0.2-0.2-0.3-0.3c0.4-0.2,0-0.7-0.3-0.6c0.1-0.2,0.3-0.3,0.5-0.3l0,0.3l0.2,0
        c-0.1-0.2-0.2-0.4-0.1-0.5c-0.1,0.2-0.2,0.2-0.4,0.1c0-0.1,0.1-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.3-0.2c-0.1,0-0.4,0-0.5,0
        c0.1-0.2,0.2-0.4,0.4-0.5c0,0.1,0.1,0.2,0.1,0.3c0-0.2-0.1-0.4-0.1-0.6c0.1-0.1,0.2-0.2,0.4-0.3c-0.3,0.1-0.6,0.2-0.6-0.2l0.3,0
        c-0.1-0.3,0.1-0.5,0.3-0.6l0,0.3l0.1,0c-0.1-0.3-0.1-0.7,0.3-0.9c-0.2,0-0.3,0-0.5-0.1c0.1,0.1,0.2,0.2,0.3,0.2
        c-0.2,0.1-0.4,0.5-0.7,0.3l0.1,0.2c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1-0.1-0.2-0.2-0.2-0.4c0.1,0,0.3,0,0.4,0c0-0.1,0-0.3,0-0.4
        c0.1,0.1,0.3,0.2,0.4,0.3c0-0.2,0-0.5,0-0.7c-0.2,0.1-0.3,0.2-0.4,0.3c0,0-0.1-0.1-0.1-0.2l0.3-0.1c0-0.1-0.1-0.3-0.2-0.4
        c0.3-0.1,0.5,0.3,0.8,0.2c-0.2-0.1-0.4-0.3-0.5-0.5c-0.2,0.1-0.3-0.2-0.4-0.3c0.3-0.5,0.6,0.2,0.9,0c-0.6,0-0.4-0.8-0.8-1
        c0,0.2,0.1,0.5,0.1,0.7l-0.2,0.1c0-0.1,0-0.3,0-0.4c-0.1,0.4-0.3,0.3-0.3,0c0.1,0,0.2,0,0.3,0.1c0.1-0.3,0.1-0.5,0-0.8
        c0.3,0,0.5,0.3,0.7,0.4c0-0.1-0.1-0.2-0.2-0.2c-0.1-0.3-0.6-0.5-0.3-0.9c-0.2,0.2-0.4,0.3-0.6,0.4c0.2-0.3,0.6-0.5,0.7-0.8
        c-0.2-0.1-0.4-0.3-0.6-0.4c0-0.2,0.3-0.5,0.5-0.2c0,0.1-0.1,0.3-0.1,0.3c0.2-0.1,0.2-0.3,0.2-0.4c-0.2-0.2-0.3-0.3-0.4-0.5
        c0.1,0,0.4,0.1,0.6,0.1c-0.1-0.2-0.2-0.2-0.4-0.2l0.1-0.2c-0.2,0.1-0.3,0-0.5-0.1c0.1,0,0.3,0,0.4,0l-0.1-0.3c0.2,0,0.3,0.1,0.5,0.2
        c-0.1-0.2-0.1-0.3-0.2-0.4c-0.1,0-0.3,0-0.3,0c0.1-0.1,0.2-0.2,0.3-0.3c-0.1,0-0.2-0.1-0.2-0.2c0.1,0,0.3,0,0.4,0
        c-0.2-0.6-0.5,0.1-0.8,0.1l0,0.1l-0.1,0c0.4-0.4,0.6-1,1-1.3c0,0.1,0.1,0.3,0.1,0.4c-0.1,0.1-0.1,0.3-0.2,0.4
        c0.6,0.1,0.3-0.6,0.1-0.8c-0.2,0-0.4,0.2-0.6,0.4c-0.1-0.1-0.1-0.3-0.1-0.4l0.2,0c0-0.1-0.1-0.4-0.1-0.5c-0.1,0.1-0.2,0.2-0.4,0.3
        c0-0.1-0.1-0.3-0.1-0.4c0.1,0,0.4,0,0.5,0.1c0-0.1,0-0.3,0-0.4c0.1,0,0.3,0.1,0.3,0.1c-0.1-0.2-0.2-0.4-0.4-0.5
        c0.1-0.1,0.2-0.2,0.3-0.3c-0.1-0.2-0.3-0.3-0.4-0.5c0.1,0,0.2,0,0.3-0.1c-0.1-0.3-0.1-0.7,0-1c-0.1,0-0.4,0-0.5,0.1
        c0.1-0.1,0.3-0.2,0.4-0.3c-0.2-0.2-0.4-0.3-0.3-0.6c0.2,0.3,0.6,0.4,0.7,0c-0.1,0-0.4,0.1-0.5,0.1c-0.3-0.3,0.3-1,0.4-0.3l0.2,0
        c-0.1-0.2-0.3-0.3-0.5-0.4c-0.1-0.2-0.1-0.5-0.1-0.7c0.1,0.1,0.2,0.3,0.3,0.4c0.1-0.2-0.1-0.5,0.2-0.6c0.1-0.1,0.2-0.1,0.3-0.2
        c-0.3,0-0.5,0.2-0.7,0.3c0-0.1,0.1-0.3,0.1-0.4c-0.3-0.1-0.5-0.4-0.2-0.6c0,0.3,0.3,0.4,0.5,0.5c-0.1-0.2-0.3-0.4-0.4-0.5
        c-0.2-0.3,0.2-0.6,0.3-0.8c-0.1,0-0.4,0-0.5,0c0,0.2,0,0.4-0.2,0.5c0.1-0.3,0-0.6-0.2-0.8c0.2,0.1,0.5,0.2,0.6,0.3
        c-0.1-0.1-0.3-0.4-0.3-0.5c0.2,0,0.3,0.1,0.4,0.3c0-0.1,0-0.4,0.1-0.5c-0.1,0-0.2,0-0.3,0c0.1-0.3-0.2-0.5-0.3-0.8
        c0.1,0,0.3,0,0.4,0c0.2,0.2,0.3,0.4,0.5,0.6c0.1-0.5-0.5-0.6-0.6-1c0.4-0.1,0.3-0.2,0-0.3c0.1-0.1,0.2-0.2,0.4-0.2
        c0.2-0.3,0.3-0.5,0.4-0.8l0.2,0c0,0,0-0.1,0-0.2c-0.1,0-0.2,0-0.3,0c-0.1,0.2-0.3,0.5-0.4,0.7c-0.2,0.2-0.7,0.3-0.6,0.7
        c0-0.1,0-0.2,0-0.3c-0.1-0.1-0.1-0.2-0.2-0.2c0.1,0,0.2,0.1,0.2,0.2c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.3-0.2-0.9,0.3-0.9
        c-0.1-0.1-0.3-0.2-0.4-0.3c0.3-0.1,0.5,0.1,0.7,0.3c-0.1-0.2-0.2-0.4,0-0.6c0.2-0.2-0.1-0.4-0.1-0.6c-0.1,0-0.3,0.1-0.3,0.2
        c0.1,0.1,0.3,0.2,0.4,0.4c-0.5,0.2-0.5-0.4-0.6-0.7c0.2-0.3,0.2-0.6,0.4-0.9c-0.1,0.1-0.3,0.2-0.4,0.3c0-0.1,0.1-0.4,0.2-0.5l-0.3,0
        c-0.1,0.2,0,0.5,0,0.7c-0.1-0.2-0.2-0.4-0.2-0.6c-0.1-0.1-0.2-0.2-0.2-0.3c0.1,0,0.3-0.1,0.3-0.1c0.1-0.1,0.2-0.3,0.2-0.4
        c-0.6,0-0.1-0.1,0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.3c-0.2-0.2-0.4-0.3-0.6-0.4c0,0.3,0.1,0.7-0.3,0.7c0.1-0.2,0.3-0.5,0.1-0.8
        c0.2,0.1,0.3-0.1,0.3-0.3l-0.3,0c0-0.1,0.1-0.3,0.1-0.4c0.2,0.2,0.3,0.5,0.4,0.8c0-0.2,0-0.6-0.1-0.7c0.2,0.1,0.3,0.2,0.6,0.2
        c-0.1,0.2-0.3,0.4-0.4,0.5c0.1,0.1,0.3,0.2,0.4,0.3c-0.1-0.1-0.1-0.3-0.2-0.4c0.1-0.2,0.3-0.3,0.5-0.1c0,0,0-0.1,0-0.1
        c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.2,0-0.3l-0.3,0.1c-0.2-0.4-0.2-0.8-0.5-1.2c0.2,0,0.2,0.5,0.5,0.3c0.1-0.1,0.2-0.2,0.2-0.3
        c-0.2-0.1-0.4-0.2-0.6-0.3c0.4-0.3,0-0.7,0.3-1.1c-0.1,0-0.3,0-0.4,0c0-0.1-0.1-0.3-0.1-0.4l0.1,0l0,0.2c0.3,0,0.5,0.3,0.8,0.3
        c-0.1-0.2-0.2-0.3-0.3-0.5c0.1-0.2,0.3-0.3,0.4-0.5c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1-0.1-0.3-0.3-0.4-0.1c-0.2-0.3,0.4-0.4,0.4-0.8
        c0.1,0.2,0.3,0.5,0.5,0.6c0-0.1,0-0.2,0-0.3c-0.1-0.1-0.2-0.3-0.4-0.3c-0.1-0.2-0.1-0.4-0.2-0.6c-0.5,0-0.1,0.3,0,0.5
        c-0.1,0.1-0.3,0.2-0.4,0.1l0,0.2l-0.1,0c0-0.1,0-0.2,0-0.3c0.1,0,0.3,0,0.4,0c-0.2-0.2-0.2-0.3-0.1-0.5c0-0.1-0.1-0.3,0-0.4
        c0.1,0.1,0.2,0.3,0.3,0.5c0-0.4,0.1-0.7,0.4-0.9c-0.4,0-0.3-0.3-0.4-0.5l0.2-0.1l0.1-0.2c-0.2,0-0.4,0-0.6,0.2c0-0.1,0-0.2,0-0.3
        c0.1,0,0.3,0.1,0.5,0.1c-0.1-0.3-0.3-0.5-0.4-0.8c0.1,0,0.4,0.1,0.5,0.1c0-0.4-0.3-0.4-0.5-0.5c0.1,0,0.3,0,0.5,0c0,0.1,0,0.2,0,0.3
        c0.1-0.1,0.2-0.3,0.2-0.5c-0.1,0-0.3,0-0.4,0c0.1-0.1,0.3-0.3,0.4-0.4c0.1,0.1,0.2,0.2,0.4,0.3c-0.1-0.2-0.3-0.3-0.4-0.4
        c-0.2,0.1-0.3,0.2-0.5,0.3c0.2-0.2,0.5-0.4,0.3-0.8c0.1,0,0.3,0,0.4,0c-0.1-0.1-0.2-0.2-0.2-0.3c0.1-0.2,0.3-0.4,0.4-0.6
        c-0.1,0.1-0.4,0.2-0.5,0.2c0.1-0.1,0.2-0.4,0.3-0.5c-0.4-0.2,0.1-0.4,0.2-0.6c0,0-0.1-0.1-0.2-0.2c-0.1-0.3,0.4-0.1,0.5-0.3
        c-0.3,0.2-0.5,0-0.6-0.3l0.3,0c-0.1-0.2-0.1-0.3-0.2-0.5c0.2,0,0.4,0.1,0.5,0.4c0,0,0-0.1,0-0.2c-0.2-0.2-0.4-0.4-0.5-0.7
        c0.4,0.1,0.6-0.6,0-0.2c0.1-0.3,0.1-0.5,0.3-0.7c-0.1-0.2-0.2-0.3-0.2-0.4c0.2-0.4,0.1-0.9,0.2-1.4l0.2,0c0-0.1,0-0.3-0.1-0.4
        c0,0,0.1,0,0.1,0c0-0.1,0.1-0.3,0.1-0.3c-0.1,0-0.2,0.1-0.3,0.2c0.1-0.1,0.2-0.4,0.2-0.5l-0.2,0l0-0.1l0.2,0l0-0.2l-0.2,0
        c0.2,0,0.4,0,0.5-0.2l-0.4,0c0.1-0.2,0.3-0.3,0.5-0.4l-0.5,0.1c0.1-0.1,0.3-0.4,0.4-0.5c-0.1,0-0.3,0.1-0.4,0.1l0-0.2
        c0.1,0,0.3,0,0.4,0c0-0.1,0-0.3,0.1-0.4c-0.1,0-0.3,0.1-0.4,0.1c0,0,0.1-0.1,0.1-0.2l0.2,0c0-0.1,0-0.3,0-0.5c0.1,0,0.3,0,0.4,0.1
        c-0.1-0.1-0.2-0.1-0.4-0.2c0.1-0.3,0-0.7,0.2-1c0-0.3,0-0.6,0-0.9c0.4-0.1,0-0.4,0-0.6c0.1-0.1,0.5-0.4,0.1-0.3c0-0.4,0.1-0.8,0-1.2
        l0.2,0c0-0.4-0.1-0.7-0.1-1.1c0.4,0.2,0-0.3,0-0.5c0.2-0.2,0.2-0.5,0.2-0.7c0-0.3,0.1-0.6-0.2-0.8c0.1,0,0.2-0.1,0.3-0.1
        C286.1,226.8,285.9,226.7,285.8,226.6 M176.2,226.7c-0.1,0.1,0.1,0.4,0.2,0.3C176.6,226.9,176.4,226.6,176.2,226.7 M203.8,226.8
        C203.9,226.9,203.9,226.9,203.8,226.8 M203.1,227c-0.1,0.2,0.2,0.5,0.3,0.3C203.6,227,203.2,226.8,203.1,227 M228.7,227.1
        c0.1,0.2,0.2,0.2,0.3,0C228.9,226.9,228.8,226.9,228.7,227.1 M310.9,226.9c-0.2,0.1-0.1,0.4,0.1,0.3
        C311.3,227.2,311.1,226.8,310.9,226.9 M337.1,227C337.2,227.1,337.2,227.1,337.1,227 M317.2,227.2c-0.1,0.1,0,0.4,0.2,0.4
        c0.1-0.1,0.2-0.3,0.2-0.3C317.5,227.1,317.3,227.1,317.2,227.2 M335.9,227.1c0,0.1,0,0.4,0,0.5l-0.2-0.1c-0.2,0.3-0.1,0.8-0.5,0.9
        c-0.2,0.3-1,0.3-0.8,0.9c-0.1,0-0.3,0-0.3,0l0,0.1c0.2,0,0.4,0.1,0.6,0.1c0-0.1,0-0.4,0-0.5c0.1,0,0.3,0,0.4,0
        c0.3-0.2,0.5-0.5,0.8-0.7c0,0.1-0.1,0.4-0.1,0.5c-0.1,0-0.3,0-0.4,0c-0.1,0.2-0.3,0.3-0.5,0.4c0.1,0.1,0.1,0.4,0.3,0.4
        c0.1-0.2,0.2-0.3,0.3-0.5c0.3-0.1,0.6-0.3,0.5-0.6c0.4,0,0.3-0.4,0.3-0.6c-0.1,0-0.3,0-0.3,0c0.1-0.1,0.1-0.2,0.2-0.4
        C336.1,227.3,336.1,227.2,335.9,227.1 M479.1,227.1C479.2,227.2,479.2,227.2,479.1,227.1 M197.3,227.4
        C197.4,227.5,197.4,227.5,197.3,227.4 M35.9,227.6c0.1,0.2,0.3,0.2,0.4,0C36.2,227.5,36.1,227.5,35.9,227.6 M478.4,227.7
        c0.1,0.2,0.2,0.2,0.3,0C478.7,227.4,478.5,227.5,478.4,227.7 M121.2,227.7c0,0.1,0.1,0.2,0.1,0.3c-0.5,0.2-1.1-0.1-1.6,0.1
        c-0.2-0.1-0.5-0.1-0.7-0.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.5-0.1-1.1,0.1-1.6,0c-0.4,0.1-0.7,0.2-1.1,0.1c-0.2-0.1-0.3,0.2-0.4,0.3
        c-0.4-0.4-1,0.2-1.5,0c0,0.1-0.1,0.2-0.2,0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.4,0.2-0.8,0.2-1.1,0.4c0,0-0.1-0.1-0.2-0.1
        c-0.3,0.3-0.6,0.2-1,0.1c-0.1,0.2-0.3,0.4-0.5,0.3c-0.5-0.2-0.9,0.4-1.4,0.3c-0.3,0-0.6,0.2-0.8,0.3c-0.1,0-0.2-0.1-0.3-0.2
        c0,0.1,0.1,0.3,0.1,0.4c-0.1,0-0.4,0-0.5,0c-0.1,0.3-0.3,0.1-0.4-0.1c0,0.6-1-0.2-0.3,0.5l-0.3,0l-0.1-0.3c-0.1,0-0.3,0.1-0.4,0.1
        c0.3,0.5-0.4,0.5-0.7,0.6c0,0.1-0.1,0.3-0.2,0.3c-0.1-0.1-0.3-0.2-0.4-0.2c0,0.1,0,0.3,0,0.4c0-0.1-0.1-0.2-0.1-0.2
        c-0.1,0.1-0.2,0.2-0.3,0.3c-0.5,0.1-0.8,0.4-1.3,0.6c-0.1-0.2-0.3-0.4-0.5-0.2c0.1,0.1,0.2,0.2,0.3,0.2c-0.5,0.2-0.9,0.6-1.5,0.8
        c-0.2,0.2-0.4,0.4-0.6,0.2c0.2,0.5-0.4,0.5-0.7,0.6c-0.4,0.4-0.9,0.7-1.4,0.8c-0.5,0.6-1.4,0.7-1.8,1.4c-0.1,0-0.3-0.1-0.4-0.1
        c0,0.1-0.1,0.3-0.1,0.4c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.1,0.3-0.2,0.4c-0.2,0.1-0.3,0.1-0.5,0.2c-0.3,0.4-0.7,0.7-1.1,0.9
        c-0.1,0.3-0.5,0.3-0.7,0.6c-0.4,0.5-1.1,0.8-1.4,1.4c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.3-0.4,0.6-0.7,0.7c-0.3,0.1-0.3,0.4-0.5,0.6
        c-0.3,0-0.6,0.2-0.5,0.5c-0.2,0.1-0.5,0.1-0.6,0.4c-0.4,0.3-0.7,0.7-1,1.1c-0.3,0.2-0.6,0.3-0.5,0.7c-0.1,0-0.3,0-0.3,0
        c-0.1,0.5-0.6,0.9-0.9,1.3c-0.5,0.5-0.9,1.1-1.4,1.5c-0.3,0.2-0.4,0.6-0.7,0.8c-0.2,0.1-0.2,0.4-0.4,0.5c0-0.1-0.1-0.2-0.1-0.2
        c-0.1,0.3-0.3,0.7-0.5,1c0,0.3-0.2,0.4-0.3,0.7c-0.1,0-0.2,0.1-0.2,0.1c-0.4,0.6-0.8,1.3-1.1,1.9c-0.2,0.5-0.6,0.8-0.8,1.3
        c-0.1,0.1-0.3,0.2-0.4,0.3c0.1,0.1,0.1,0.2,0.2,0.4c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2,0.2-0.3,0.4-0.4,0.6c0.1,0,0.3,0,0.4,0
        c-0.1,0.2-0.3,0-0.4,0c-0.1,0.3-0.2,0.6-0.3,0.9c-0.2,0.1-0.3,0.2-0.4,0.4c0.2-0.1,0.4-0.1,0.5-0.2c-0.3,0.4-0.6,0.7-0.9,1.1
        c-0.2,0.1-0.3,0.3-0.4,0.4c0,0.1,0.1,0.3,0,0.4c0.1,0,0.3-0.1,0.4-0.1c-0.1,0.1-0.2,0.3-0.3,0.5c-0.1-0.1-0.2-0.2-0.3-0.2
        c0,0.1,0,0.3,0.1,0.4c0.2,0,0.4-0.1,0.5-0.1c-0.1,0.2-0.3,0.2-0.5,0.2l0,0.4c0.1,0,0.2-0.1,0.3-0.1c0,0,0,0.1,0,0.1
        c-0.5-0.2-0.6,0.7-0.2,0.9c-0.1,0-0.3,0-0.5-0.1l0.2,0.3c-0.1,0-0.2,0-0.3,0.1l-0.1,0.3c0.1,0,0.3,0,0.3,0c0.1,0.4,0.6,0.1,0.8,0.3
        c0-0.1-0.1-0.3-0.1-0.4c0.2,0.1,0.3,0.3,0.5,0.4c0-0.1,0-0.2,0-0.2c-0.1,0-0.2-0.1-0.2-0.2l0.1-0.1c0.2,0.2,0.5,0.2,0.8,0.1
        c0.1,0.1,0.3,0.2,0.4,0.3c0-0.1,0.1-0.3,0.1-0.4l0.2,0c0,0.1,0,0.3,0,0.4c0.1-0.2,0.3-0.3,0.4-0.5c0.1,0.1,0.3,0.2,0.4,0.2
        c0.1-0.2,0.2-0.4,0.3-0.6c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c0.4-0.2,0.5-0.6,0.8-0.8c0,0,0,0.1,0,0.2
        c-0.2,0.1-0.3,0.3-0.1,0.5c0.1-0.1,0.3-0.2,0.4-0.3c0,0.1,0,0.2,0,0.3c0,0,0.1,0,0.2,0c0-0.1,0.1-0.3,0.1-0.4c-0.1,0-0.2,0-0.3,0
        c0,0,0.1-0.2,0.1-0.2c0.1,0.2,0.3,0.3,0.5,0.3c0-0.1,0-0.3,0-0.3c0,0.2,0,0.3,0.1,0.5c0.4-0.1,0.7-0.3,1.1-0.4
        c0.1-0.1,0.1-0.2,0.2-0.3c0,0.1,0,0.3,0,0.4c0.2-0.2,0.4-0.2,0.7-0.1l0-0.3c0.2,0,0.3,0.3,0.6,0.4c0.1-0.2,0.2-0.6,0.4-0.5l-0.2,0.1
        c0,0.1,0.1,0.3,0.1,0.4c0.2-0.2,0.4-0.2,0.6-0.1c-0.1-0.1-0.2-0.3-0.3-0.4c0.2,0,0.3,0.1,0.5,0.2c0-0.1,0.1-0.2,0.1-0.2
        c0,0.1,0,0.2,0,0.3c0.6-0.1,1.2,0.1,1.8-0.1c0,0.1,0,0.2,0,0.3c0.1-0.1,0.3-0.2,0.4-0.3l0,0.2c0.2,0,0.4-0.1,0.6-0.1
        c0.1,0,0.3,0,0.4-0.1c0,0,0,0.2,0,0.2c0.1,0,0.2-0.1,0.2-0.1c0.2,0.1,0.3,0.4,0.5,0.4c0-0.2-0.1-0.3-0.1-0.5
        c0.6,0.3,1.2,0.2,1.8,0.2c0,0,0.1-0.1,0.1-0.2l0.1,0.1c0.1,0.1,0.4,0.1,0.4,0.3c-0.1,0.1-0.2,0.1-0.2,0.2c0.2,0,0.5,0,0.6,0.1
        c-0.1-0.1-0.3-0.3-0.3-0.4c0.4-0.2,0.6,0.4,0.9,0.1c0.2,0.1,0.7-0.1,0.6,0.3l0.2-0.1c0.1,0.2,0.1,0.4,0.3,0.5c0-0.1-0.1-0.2-0.2-0.3
        c0.1-0.1,0.1-0.2,0.2-0.2c0.4,0.1,0.8,0.2,1.2,0.2c0.2,0.1,0.3,0.1,0.5,0.2c0.2,0,0.4,0.4,0.5,0.1c0.2-0.1,0.4-0.1,0.6-0.2
        c0-0.1-0.1-0.2-0.1-0.4l0.1,0.1c0.1-0.1,0.2-0.3,0.3-0.4c0,0.1,0,0.4,0,0.5c0.1-0.1,0.1-0.3,0.2-0.4c-0.5-0.1,0-0.5,0.2-0.6
        c0.3-0.4,0.4-1.2,1-1.1l0-0.2c0.3-0.6,0.9-1,1.1-1.6c0,0.1,0.1,0.2,0.2,0.3c0,0,0.1,0,0.1,0c0-0.3,0-0.5,0.4-0.4
        c-0.5-0.2,0.1-0.5-0.1-0.8l0.1,0l0,0.3c0-0.1,0.1-0.3,0.2-0.3c0.1,0,0.3,0,0.4,0l-0.1-0.2c-0.1,0-0.2-0.1-0.3-0.1c0.1,0,0.2,0,0.3,0
        c0.1-0.4,0.4-0.6,0.7-0.7c0-0.2-0.1-0.4-0.2-0.6c0.1-0.2,0.5-0.4,0.1-0.6c0.1,0,0.3-0.1,0.4-0.1c0-0.1,0.1-0.2,0.1-0.2
        c0,0.1,0,0.3,0,0.3c-0.2,0.2-0.3,0.4-0.4,0.6c0.4,0,0.6-0.4,0.7-0.8c0.1-0.4,0.6-0.6,1-0.6c0-0.2,0.1-0.4,0.1-0.5
        c-0.1-0.1-0.2-0.2-0.2-0.2c0.2,0,0.3,0.1,0.5,0.1c0-0.1,0-0.3,0.1-0.4c0.2-0.1,0.4-0.2,0.5-0.3c0.1-0.2,0.1-0.4,0.2-0.6
        c-0.1-0.2-0.2-0.2-0.4-0.3c0.2,0.1,0.3,0.2,0.5,0.2c0.2-0.2,0.4-0.5,0.6-0.6c0.5-0.2,0.7-0.9,1.2-1.2c-0.1,0-0.4,0.1-0.6,0.1
        c0.1-0.1,0.3-0.2,0.5-0.3c0,0.1,0,0.2,0,0.3c0.5-0.4,0.9-0.9,1.5-1.2c0.5-0.1,0.6-0.7,1-0.9l0,0c0,0.1,0,0.3-0.1,0.4
        c0.2-0.2,0.5-0.4,0.4-0.7c0.1,0,0.3,0.1,0.4,0.1c0.2-0.1,0.4-0.3,0.6-0.5c0,0.1,0.1,0.3,0.2,0.4l0.2-0.1c0-0.1,0-0.3-0.1-0.4
        c0.5,0.2,0.9-0.3,1.3-0.4c0-0.2,0-0.3,0.1-0.5l0.2,0l0-0.3c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.1l0.3,0c-0.1-0.1-0.2-0.2-0.3-0.3
        c0.2-0.2,0.3-0.3,0.4-0.5c-0.2,0-0.4,0.3-0.6,0.1c0.2-0.2,0.4-0.4,0-0.6c0.2,0.8-0.3,0.2-0.3,0c0.1,0,0.3,0,0.4,0
        c-0.1-0.2-0.6-0.3-0.4-0.6l-0.3,0.1l0.2-0.3c-0.1,0-0.2,0-0.2,0c0-0.1,0-0.2,0-0.2c-0.5,0-0.8-0.5-1.2-0.6c0.1,0,0.3,0.1,0.5,0.1
        c0.1-0.4-0.3-0.5-0.5-0.7c0,0-0.1,0-0.2,0c-0.2-0.1-0.3-0.2-0.5-0.3c0-0.1-0.4-0.7-0.2,0c-0.3-0.1-0.4-0.4-0.4-0.6
        c-0.2,0.1-0.4,0.3-0.6,0c0.1-0.1,0.2-0.2,0.3-0.2c-0.1,0-0.2,0-0.2,0l0,0.2c-0.2-0.1-0.5-0.1-0.7-0.3c-0.2,0-0.4,0-0.5,0l0-0.2
        c-0.3,0-0.5-0.1-0.7-0.1c-0.2,0-0.4-0.1-0.6-0.1c-0.1,0-0.2,0.1-0.2,0.2l0-0.2c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0-0.4-0.2-0.5,0.1
        c-0.3,0-0.6,0-0.9,0c-0.2,0.2-0.5,0.1-0.7,0.1c-0.1,0-0.2,0.1-0.3,0.1c0,0.1,0,0.2,0,0.2c-0.1,0-0.2-0.1-0.3-0.1l-0.1,0.2
        c-0.3,0.7-0.1-0.6-0.3,0c-0.2,0.1-0.3,0.4-0.5,0.4c0.1-0.2,0.1-0.3,0.2-0.5c-0.4,0-0.4,0.3-0.6,0.6c-0.1,0-0.2-0.1-0.3-0.1
        c0.1-0.1,0.3-0.3,0.4-0.4c-0.2-0.2-0.3,0.1-0.4,0.3c-0.3,0-0.5,0-0.7,0.1c0.3,0,0.5,0,0.8,0c-0.3,0.3-0.8,0.3-1.1,0.6
        c0-0.1,0-0.4,0-0.5c-0.1,0.1-0.2,0.2-0.2,0.3c0,0-0.1,0.1-0.1,0.1c0.1,0.1,0.3,0.2,0.4,0.3c-0.2,0-0.3-0.1-0.4-0.1
        c-0.4,0.2-0.7,0.5-1.1,0.7c0.1-0.2,0.2-0.4,0.2-0.5c-0.2,0.2-0.3,0.5-0.5,0.7c-0.1,0-0.3,0-0.3,0c-0.2,0.1-0.3,0.2-0.5,0.2
        c0,0.1,0.1,0.2,0.1,0.3c-0.1,0-0.2,0-0.3-0.1c-0.1,0.1-0.3,0.2-0.4,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.1,0.6-0.9,0.7-1.2,1.2l-0.2-0.1
        c0,0.1,0.1,0.2,0.1,0.3c-0.1,0-0.3,0-0.5,0c0,0.1,0,0.3,0,0.4c-0.2,0-0.4,0.1-0.5,0.3c-0.2,0.2-0.5,0.3-0.5,0.7
        c-0.2,0.1-0.4,0.2-0.5,0.4c-0.6,0.7-1.2,1.4-1.8,2c0.4-0.5,0.8-0.9,1.1-1.4c0.2-0.2,0.6-0.4,0.5-0.7c0.7-0.1,0.8-1.3,1.5-1.3
        c-0.3-0.4,0.4-0.3,0.3-0.7c0,0,0.1,0,0.2,0c0.2-0.4,0.7-0.4,0.8-0.8l0.1,0c0-0.4,0.4-0.6,0.7-0.6c0.1-0.2,0.3-0.4,0.4-0.6
        c0.1,0.1,0.2,0.2,0.2,0.2c0-0.1,0-0.3-0.1-0.4c0.4-0.2,0.8-0.4,1.1-0.7c0.1-0.5,0.6-0.7,1-0.9c0.6-0.7,1.5-1,2.2-1.6
        c0.1,0,0.2,0,0.3,0c0-0.1-0.1-0.2-0.1-0.2c0.1,0,0.3,0,0.4,0c0.1-0.1,0.3-0.2,0.4-0.2c0-0.1,0.1-0.2,0.2-0.3
        c0.4,0.1,0.6-0.3,0.6-0.6c0.1,0,0.2,0.1,0.3,0.1c0.1-0.3,0.4-0.4,0.8-0.4c0-0.2,0.1-0.3,0.2-0.4c0.2,0,0.3,0,0.5,0.1
        c0.2-0.2,0.3-0.4,0.6-0.4c0-0.1,0-0.2,0-0.3c0,0.1,0.1,0.2,0.1,0.2l0.2,0c0-0.1,0-0.2,0-0.3c0.2-0.1,0.3-0.2,0.5-0.3
        c0,0,0.1-0.1,0.1-0.2l0,0.2c0.1,0,0.4,0,0.5,0.1c0.3-0.2,0.5-0.3,0.8-0.5c0,0.1,0,0.2,0,0.3c0.2-0.1,0.5-0.2,0.7-0.2
        c-0.1-0.1-0.1-0.3-0.2-0.4c0.2,0,0.4,0.2,0.5,0.4c0.3,0,0.9,0.1,0.8-0.3c0,0,0.1,0,0.2,0c0,0-0.1,0.1-0.1,0.2
        c0.4,0.2,0.9,0.2,1.3,0.3c0-0.2,0-0.3,0-0.5c0,0.1,0,0.3,0,0.3c0.5,0,1.1,0.2,1.6,0.1c-0.1,0.1-0.2,0.2-0.2,0.4c0.5,0.1,1,0,1.5,0.3
        c1.1,0.5,2,1.4,3.1,2.1c0.5,0.5,1,1,1.5,1.5c0.3,0.8,1.4,1,1.4,1.9c0.3,0.1,0.5,0.3,0.6,0.5c0.5,0.1,0.7,0.5,0.5,0.9
        c0.5-0.2,0.8,0.4,1,0.8c0.1,0.1,0.3,0.2,0.4,0.4c0.2,0.7,0.9,1,1.3,1.5c0,0.1,0,0.3,0,0.5c0.6,0.3,1.2,0.7,1.5,1.3
        c0.2,0.4,0.7,0.6,1,1c0.2,0.3,0.5,0.6,0.5,1c0,0.2,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.7,0.4,1c0,0.6,0.5,1,0.5,1.6c-0.1,0-0.2,0-0.2-0.1
        c-0.3-0.2-0.7-0.1-1-0.2c0-0.1,0-0.2,0-0.2l-0.2,0.1c0-0.1-0.1-0.2-0.1-0.3c0,0.1,0,0.4,0,0.5c-0.1-0.2-0.3-0.4-0.5-0.6
        c0,0.1,0,0.4,0,0.5c-0.1-0.1-0.2-0.2-0.2-0.2c-0.3,0.2-0.7,0.2-0.8-0.2c0.1,0,0.3,0.1,0.4,0.1c-0.1-0.1-0.2-0.3-0.2-0.4
        c-0.3,0.1-0.1,0.6-0.5,0.7c0,0.1,0,0.3,0.1,0.4h-0.1c0-0.1,0-0.3,0-0.3c-0.3,0.4-0.5-0.1-0.7-0.2c-0.2,0-0.4,0.1-0.6,0.1
        c-0.3,0.1,0.2,0.4,0.3,0.2c0,0,0,0.1,0,0.1c-0.4,0.1-0.7,0-0.9-0.4l0.2,0.1l0.1-0.1c0-0.1,0.1-0.2,0.1-0.3c-0.4,0.1-0.7,0.2-1.1,0.2
        l0.1,0.2c-0.4,0.1-0.7,0-1.1,0c0,0,0-0.1,0-0.2l-0.3,0c0-0.1,0.2-0.3,0.1-0.4c-0.1-0.1-0.2,0.2-0.3,0.1c0,0-0.1-0.1-0.2-0.1
        c-0.2,0.4-0.6,0.1-0.8-0.1c0.1,0.2,0.3,0.4,0.5,0.5c-0.3,0.3-0.4,0.9-0.9,0.7c0,0.1,0,0.3,0.1,0.4l-0.1-0.2
        c-0.1,0.1-0.3,0.2-0.4,0.2c0-0.3,0.2-0.4,0.4-0.6c-0.3-0.1-0.3,0.2-0.6,0.3c0,0,0.1,0.1,0.1,0.2c-0.2,0.1-0.3,0.4-0.6,0.2
        c0.1-0.1,0.2-0.2,0.2-0.2c-0.1-0.1-0.2-0.2-0.2-0.2c0.3,0.1,0.3-0.2,0.4-0.4c-0.2,0.1-0.4,0.2-0.5,0.4c0.5,0.2,0,0.5-0.2,0.7
        c-0.1-0.1-0.3-0.1-0.4-0.2c0,0.4-0.5,0.3-0.7,0.5l0.1-0.4c-0.2,0-0.3,0-0.4,0c-0.1,0-0.3,0.1-0.4,0.2c0.3,0.1,0.5-0.1,0.7-0.2
        c0.1,0.4-0.4,0.5-0.7,0.6c0-0.1,0-0.2,0-0.3c-0.2,0.2-0.3,0.3-0.5,0.5c-0.2-0.1-0.3-0.2-0.5-0.3c0,0-0.1,0.1-0.1,0.2
        c0.2,0.1,0.4,0.3,0.6,0.4c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1-0.1-0.2-0.2-0.2-0.2c-0.2,0-0.3-0.1-0.5-0.1c-0.1,0.1-0.2,0.2-0.3,0.3
        c0.2,0,0.4,0,0.6,0c0,0,0.1,0.1,0.1,0.2c-0.2,0.1-0.4,0.6-0.6,0.1c-0.3-0.1-0.1,0.4-0.1,0.5c-0.1,0-0.2-0.1-0.3-0.2l-0.1,0.2
        c-0.1,0-0.2,0-0.3,0c-0.2,0.3-0.4,0.7-0.8,0.9l0.1-0.3c-0.1,0.1-0.3,0.1-0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.2c-0.2,0.1-0.4,0.1-0.6,0.1
        c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0-0.6-0.1-0.6,0.2c0,0.2-0.1,0.4-0.4,0.4l0.1-0.3c-0.1,0.1-0.2,0.1-0.3,0.2c0,0.2-0.1,0.3-0.3,0.3
        c0.1,0.3-0.1,0.4-0.3,0.5c0-0.1,0.1-0.3,0.1-0.5c-0.2,0.1-0.4,0.3-0.3,0.6c-0.3-0.1-0.5,0-0.7,0.3c0.2,0,0.5-0.2,0.6,0.1
        c-0.3-0.1-0.5,0.1-0.8,0.2c0,0.2-0.1,0.2-0.3,0.2c-0.1-0.1-0.2,0-0.3,0.2c0.3,0,0.6,0,0.9,0c-0.1,0.1-0.2,0.2-0.3,0.3l0-0.3
        c-0.2,0.2-0.5,0.3-0.7,0.5c0.1,0.1,0.1,0.2,0.2,0.3c-0.1,0.1-0.2,0.1-0.4,0.2l0.2-0.2c-0.1-0.1-0.2-0.2-0.3-0.2l-0.1,0.2
        c-0.1,0-0.2-0.1-0.2-0.2c-0.1,0.1-0.2,0.4-0.2,0.5c0.1,0.1,0.2,0.2,0.3,0.3c-0.4,0.1-0.3-0.3-0.3-0.5c-0.3,0.1-0.3,0.3-0.1,0.5
        c-0.2,0-0.3,0.1-0.5,0.1c-0.7,0.1-0.9,1.2-1.7,1.2c0.3,0.3-0.3,0.7,0,0.9c-0.2,0-0.3,0.1-0.4,0.3c-0.1-0.1-0.3-0.2-0.4-0.3
        c0,0.2,0.1,0.4,0.1,0.6c0.3-0.1,0.4,0,0.4,0.3l0.1-0.1c-0.2-0.5,0.3-0.1,0.4,0c-0.1,0.1-0.2,0.2-0.3,0.2c0.3,0,0.6,0.2,0.8,0.4
        c0.3,0.1,0.6,0.1,0.9,0c0,0-0.1,0.1-0.1,0.2c0.2,0,0.4,0,0.6,0.1c-0.1,0-0.3,0.1-0.4,0.1c0.1,0.3,0.4,0.2,0.7,0.3
        c0.1,0.2,0.3,0.1,0.4,0.1c0,0.1,0,0.3,0,0.3c0.2,0,0.4,0.1,0.6,0.1c0,0.2,0,0.4,0,0.6c0,0,0.1,0,0.1,0c0-0.1,0-0.2,0-0.3
        c0.1,0,0.3,0.1,0.4,0.2c0.2-0.2,0.4,0.2,0.6,0.1c-0.1,0.1-0.2,0.1-0.3,0.2c0.1,0.2,0.3,0.4,0.5,0.3c0.3,0.2,0.5,0.5,0.6,0.8
        c0.2,0.1,0.4,0.3,0.7,0.4l0,0.1c0.1,0,0.2-0.1,0.3-0.1c0,0.1,0,0.3,0,0.5c0.1,0,0.4,0,0.5,0c0,0.1-0.1,0.2-0.1,0.3l0.2-0.1l-0.1,0.2
        c0.1,0,0.2-0.1,0.3-0.1c-0.1,0.3,0.1,0.4,0.4,0.5c0,0.1,0.1,0.2,0.2,0.3c0,0.1,0,0.2-0.1,0.3c0.1,0,0.3,0,0.4,0
        c-0.1,0.1-0.2,0.3-0.2,0.3c0.2-0.1,0.3-0.3,0.5-0.4c0.1,0,0.2,0,0.2,0.1c0.1-0.3,0.4-0.3,0.7-0.3c0,0,0.1,0.1,0.1,0.2
        c0-0.1,0-0.3,0-0.3c0.3,0,0.5,0,0.8,0l-0.2-0.2c0.1,0,0.3,0,0.4,0.1l0.1-0.1c0.2,0,0.7,0,0.5-0.3c0.3,0.1,0.6,0.1,0.7-0.2l0,0.3
        c0.2-0.2,0.4-0.2,0.7-0.1l0.1-0.2c-0.1,0-0.3,0.1-0.4,0.1c0.1-0.1,0.4-0.6,0.4-0.2c0.3,0,0.6,0,0.9,0c0,0.5,0.4-0.2,0.6-0.1
        l-0.1,0.3l0.1,0l0-0.1l0.1,0l-0.1-0.3l0.4,0.1c0,0.1,0,0.2,0,0.3c0.2,0,0.3-0.1,0.2-0.3c0.2,0,0.4,0.3,0.6,0.1
        c0.1,0,0.3-0.1,0.3-0.2c0.3,0.1,0.7,0,1.1,0.1l-0.2-0.3l0.3,0.1c0.1,0,0.2-0.1,0.2-0.1c0.2,0.2,0.3,0.3,0.5,0.5
        c-0.1-0.1-0.2-0.3-0.2-0.4c0.3,0.2,0.5-0.1,0.7-0.1c-0.1,0.1-0.2,0.2-0.2,0.3c0.2,0.3,0.4-0.2,0.5-0.3c0.1,0.1,0.3,0.3,0.4,0.4
        c0,0.1-0.1,0.3-0.2,0.4c0.2-0.1,0.4-0.2,0.6-0.3c-0.2-0.1-0.4-0.3-0.5-0.5c0.2,0.1,0.5,0.3,0.7,0.5l-0.1-0.4c0,0.1,0,0.2,0.1,0.3
        c0.1,0,0.4,0,0.5-0.1c0,0.1,0,0.2,0,0.3c0.3,0.3,0.5-0.1,0.8-0.1l-0.1,0.3c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0.1,0.4,0.3,0.5,0.4
        c0.1-0.1,0.1-0.2,0.2-0.3c0.1,0.1,0.2,0.2,0.3,0.2c0-0.1-0.1-0.3-0.1-0.4c0.2,0.1,0.3,0.4,0.6,0.4c0,0.2,0,0.3,0.1,0.4
        c0-0.1-0.1-0.4-0.1-0.6c0.1,0.1,0.3,0.3,0.4,0.4l-0.1-0.3c0.3,0,0.4,0.5,0.8,0.4c-0.1-0.1-0.2-0.2-0.3-0.3l0,0
        c0.1,0.1,0.2,0.2,0.3,0.3c0.3,0,0.5,0.2,0.8,0.3c0-0.1-0.1-0.3-0.1-0.5c0,0.1,0.1,0.2,0.1,0.3c0.7,0.1,1.4,0.5,2,0.8l0-0.3
        c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0,0.2-0.1,0.2-0.1c0.1,0.1,0.2,0.2,0.2,0.2c0.1,0,0.2-0.1,0.3-0.1c0,0.1,0,0.3,0,0.4
        c0.1,0,0.2-0.1,0.3-0.1c0,0.1,0.1,0.3,0.1,0.4l-0.1-0.4c0.6,0.3,1.1,0.8,1.8,0.8c-0.1,0.1-0.1,0.3-0.2,0.5c0.2-0.1,0.4-0.2,0.5-0.3
        c0,0.1,0,0.3-0.1,0.4c0.1-0.1,0.2-0.2,0.2-0.3c0.1,0.1,0.2,0.4,0.3,0.5c0.1,0,0.2,0,0.3-0.1c0.2,0.1,0.4,0.6,0.6,0.2
        c0.2,0.1,0.4,0.2,0.6,0.3c0,0.1-0.1,0.3-0.2,0.3l0.3-0.1l-0.1,0.3l0.2-0.1c0.1,0,0.2,0.1,0.3,0.2c0,0,0.1,0.1,0.1,0.2V271
        c0.3,0.1,0.4,0.4,0.6,0.7c0.2,0,0.4,0.1,0.6,0.2c0-0.1,0-0.3,0.1-0.4c0.1,0.1,0.2,0.2,0.2,0.3c0.3,0.1,0.6,0.4,1,0.3
        c0.4-0.2,0.6,0.4,1,0.3c0.5,0,1.1,0,1.5,0.4c0.2,0,0.3,0,0.5-0.1c0.1,0.1,0.2,0.1,0.3,0.2c0.5-0.1,0.9,0.2,1.4,0.3
        c0.4,0.1,0.9,0,1.3,0.1c1.1-0.1,2.3,0.1,3.4-0.1c1,0.1,2.1-0.1,3.1-0.2c0.3,0,0.6,0,0.9-0.1c0.4,0,0.8-0.1,1.1-0.2
        c0.4,0,0.8,0.3,1.1-0.1c0.6-0.1,1.1-0.1,1.7-0.2c0.3-0.1,0.5-0.4,0.8-0.3c0,0,0-0.1,0.1-0.1c-0.2,0-0.5,0-0.7-0.1
        c0,0.1-0.1,0.2-0.1,0.3c-0.4-0.2-0.8-0.1-1.3-0.1c0.2-0.2,0.4-0.2,0.6-0.1c-0.1-0.1-0.2-0.2-0.2-0.3c-0.3,0.1-0.6,0-1,0
        c0-0.1,0-0.3-0.1-0.4c-0.1,0.4-0.8,0.3-0.7-0.1c-0.1,0-0.3,0-0.3,0l0,0.2l-0.1,0c0-0.1,0-0.3,0-0.4c-0.2,0.1-0.3,0.2-0.5,0.2
        l0.1-0.3c-0.1,0-0.3,0.1-0.4,0.2c0-0.1-0.1-0.3-0.2-0.4c-0.1,0-0.2,0.1-0.2,0.2l0-0.2c-0.1,0-0.2,0-0.3,0.1l0-0.1
        c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.2c-0.2,0-0.3,0-0.5,0c0-0.1-0.1-0.2-0.1-0.3c-0.3,0.2-0.7,0.1-1,0.1
        c0.1-0.1,0.2-0.2,0.3-0.3c-0.2,0-0.4,0-0.6,0.1c-0.3-0.2-0.5-0.4-0.8-0.3c-0.3-0.1-0.5-0.5-0.8-0.4l0-0.2c-0.3,0-0.6,0-0.8-0.2
        c-0.2-0.1-0.4-0.5-0.5-0.1c-0.6-0.4-1.4-0.5-2-0.9c-0.1,0-0.2,0-0.3,0c-0.3-0.2-0.7-0.4-0.9-0.7c0.3,0.2,0.6,0.6,1.1,0.5l0,0.1
        c0.4,0,0.7,0.3,1.1,0.3c0.3,0,0.5,0.1,0.7,0.2c0.2,0.1,0.5-0.1,0.7,0.1c0.2,0,0.6,0.4,0.7,0.1c0.4,0.3,1,0.2,1.3,0.7l-0.1-0.3
        c0.7,0.1,1.2,0.6,2,0.5c0.4,0.1,0.9,0.2,1.3,0.3c0.5,0.1,0.9,0.4,1.4,0.3c0.2,0,0.5,0,0.7,0.1c0.9,0.3,1.8,0.5,2.7,0.7
        c0.1,0.1,0.2,0.2,0.2,0.2c0.6,0,1,0.4,1.6,0.4c0.1,0.1,0.2,0.2,0.4,0.2c0.7,0.1,1.3,0.6,2,0.7c0.4,0,0.7,0.5,1.1,0.5
        c0.2,0,0.4,0.2,0.6,0.3c0.4,0.1,0.8,0.4,1.1,0.6c0.4,0.2,0.9,0.3,1.3,0.5c0.5,0.2,1,0.7,1.5,0.9c0.6,0.2,1.1,0.8,1.6,1.2
        c0.5,0.5,1.2,0.2,1.7,0.7c0,0.1,0.1,0.3,0.1,0.4c0.2,0,0.4,0.1,0.6,0.2c0.1,0.2,0.2,0.3,0.3,0.5c0.4-0.3,0.9,0.3,1.2-0.1
        c0.3,0.1,0.6,0.4,1,0.4c0.3,0,0.4,0.2,0.5,0.4c0.7,0.2,0.7,0.9,1,1.4c0.5-0.4,1,0,1.4,0.2c0.3,0.2,0.3,0.7,0.3,1.1
        c0.2,0.2,0.4,0.4,0.7,0.5c0.3,0.5,0.9,0.4,1.3,0.7c0.4-0.2,0.9,0.1,1.3,0c0.5-0.1,0.9,0.3,0.8,0.8c0.2,0,0.4,0,0.6,0
        c-0.1,0.3-0.2,1.1,0.4,0.8c0,0.1,0,0.4,0,0.5c-0.1,0-0.3-0.1-0.4-0.1c0.1,0.1,0.6,0.3,0.3,0.5c-0.1,0-0.2-0.1-0.3-0.1
        c-0.1,0.3-0.2,0.7-0.5,0.9c0.1,0,0.2,0.1,0.2,0.1c0.2-0.1,0.3-0.3,0.5-0.4c0.5,0,1.1,0.3,1.5-0.1c0.3,0.1,0.5,0.3,0.6,0.6
        c-0.4,0.4,0.5,0.4,0.4,0.8c0,0.2-0.1,0.4-0.1,0.6c0.2,0.1,0,0.3,0,0.5c0.3,0,0.6,0.1,0.9-0.1c0.1-0.2-0.2-0.3-0.4-0.3l0,0.2
        c0-0.1-0.1-0.2-0.2-0.2c0.1,0,0.2-0.1,0.2-0.1c0.3,0.2,0.7,0,0.8-0.3c-0.1,0.2-0.2,0.5-0.3,0.6c0.2-0.1,0.5-0.2,0.7-0.3
        c-0.2,0.3-0.7,0.2-0.9,0.6c0.1,0,0.4,0,0.6,0c0.2,0.3,0.5,0.4,0.9,0.4c0.4,0.3,0.6,0.8,0.8,1.2c0.2,0.4,0,0.8,0.2,1.2
        c0.3,0.5-0.6,0.8-0.4,1.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1-0.1-0.2-0.2-0.3-0.2c0.3,0.4-0.2,0.7-0.3,1c0.2,0.2,0.4,0.4,0.5,0.7
        c0.6,0.1,0.6,0.9,1.2,0.9c0-0.3,0-0.5,0-0.8c0.1,0.2,0.1,0.4,0.1,0.6l0.2-0.1c-0.1,0.2-0.2,0.3-0.4,0.3c0.3,0.4,0.8,0.6,1.3,0.6
        c0.2,0.2,0.3,0.5,0.1,0.8c0.1,0,0.2,0,0.3,0c0,0.2,0,0.4,0.1,0.7c0.1-0.1,0.3-0.2,0.4-0.2c-0.4,0.4,0.1,0.4,0.3,0.5l-0.2,0
        c0,0.3,0,0.7,0,1c-0.1,0.5-0.4,1.2-1,1.2c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0-0.2-0.1-0.3-0.2c0.1,0.5-0.4,0.5-0.8,0.6
        c0.1,0.2,0.2,0.4,0.4,0.6c-0.2-0.1-0.4-0.2-0.6-0.3c0-0.2-0.2-0.4-0.4-0.6c0.1,0.2,0.1,0.5,0,0.7c0.1-0.1,0.2-0.2,0.3-0.3
        c0,0.1,0,0.3,0,0.4c0.3,0.1,0.6,0.3,0.9,0.1c0,0.1-0.1,0.4-0.1,0.5c0.2,0.2,0.5,0.3,0.6,0.5c0.1,0.3,0.4,0.6,0.4,1
        c0.1,0.3,0.2,0.3,0.4,0c-0.2,0.3-0.1,0.8,0.2,1c0,0.1-0.1,0.2-0.2,0.2c0.1,0.1,0.2,0.3,0.3,0.4c0,0.2,0,0.4,0,0.6l0.2-0.1
        c0,0.1,0.1,0.3,0.1,0.4c-0.2,0.2-0.4,0.5-0.1,0.7c0.1-0.2,0.1-0.3,0.2-0.4c-0.1,0.3,0,0.7,0.2,1l0.1,0c0.1,0.1,0.2,0.2,0.3,0.3
        c-0.1,0.1-0.3,0.3-0.4,0.4c0,0.1,0.1,0.3,0.1,0.4l0.1,0c0,0.2,0,0.4,0,0.6c0,0.1-0.1,0.2-0.1,0.3c0.2,0.2,0.5,0.3,0.6,0.6
        c-0.3-0.1-0.5-0.1-0.8,0c0.1,0.2,0.3,0.2,0.4,0.2c0,0.1,0,0.2,0,0.3c0.1,0,0.2,0,0.2,0c0,0.3,0.1,0.5,0.3,0.7
        c-0.2-0.1-0.4-0.2-0.5-0.3c0,0.3,0.2,0.5,0.1,0.8c0.1,0,0.3,0.1,0.4,0.1c0-0.1,0.1-0.2,0.1-0.2c0,0.2,0,0.4,0.1,0.6l-0.1,0l0-0.3
        c-0.2,0.2-0.4,0.4-0.5,0.6l0.3,0c0,0.1-0.1,0.2-0.2,0.3c0.1,0.2,0.1,0.4-0.1,0.6c-0.2,0.5-0.5,1.2,0.1,1.5l-0.2,0.1
        c0,0.1-0.1,0.2-0.1,0.2l0.2,0.1c0.1-0.1,0.2-0.2,0.2-0.2c0.1,0.1,0.1,0.2,0.2,0.3c-0.1,0-0.2-0.1-0.3-0.1c-0.3,0.6-0.5,1.4-0.4,2.1
        c-0.1,0-0.3-0.1-0.4-0.1c0.1,0.1,0.1,0.3,0.2,0.4c-0.3,0-0.3,0.4-0.4,0.6c-0.1,0.4-0.2,0.8-0.6,1c0.1,0.2,0.1,0.3,0.2,0.5
        c0,0-0.1,0-0.1,0c0-0.1,0-0.3,0-0.4c-0.5,0.2-0.3,0.9-0.9,1c0.1,0.1,0.1,0.3,0.2,0.4c-0.3,0-0.5-0.1-0.8,0c0,0.2,0,0.3,0.1,0.4
        c-0.1-0.1-0.2-0.1-0.4-0.2c-0.2,0.3-0.4,0.5-0.7,0.6c-0.5,0.2-0.9,0.5-1.4,0.7c-0.2,0.1-0.4,0.3-0.7,0.4c-0.5,0-1,0.2-1.5,0
        c-0.3,0.1-0.7,0.3-0.9-0.1c0,0.1,0,0.2-0.1,0.3c-0.4-0.1-0.8-0.1-1.2-0.1c-0.4,0-0.7-0.2-1.1-0.1c-0.2-0.1-0.2-0.3-0.4-0.3
        c-0.2,0-0.4,0.1-0.6,0.1c-0.3-0.3-0.8-0.1-1.1-0.4c-0.1,0-0.2,0.1-0.2,0.1c-0.2-0.2-0.4-0.4-0.7-0.3c-0.2-0.3-0.5-0.5-0.9-0.3
        c0-0.1,0-0.3,0-0.4c-0.5,0.2-0.8-0.3-1.2-0.5c-0.1-0.1-0.2-0.3-0.3-0.4l-0.1,0.3c-0.3-0.3-0.7-0.5-1-0.8c-1-0.7-1.8-1.6-2.8-2.4
        c0-0.1-0.1-0.2-0.1-0.3c-0.9-0.8-1.3-2-2-2.9c-0.4-0.5-0.5-1.2-0.8-1.8c-0.1-0.5-0.4-1-0.5-1.5c-0.1-0.7-0.6-1.3-0.4-2l-0.2,0
        c0-0.6-0.1-1.2-0.3-1.8c0-0.4,0-0.9-0.1-1.3c-0.1-0.8,0-1.6,0-2.4c0,0,0,0,0.1,0c0,0.3,0,0.5,0,0.8c0.1,0.4,0,0.9,0,1.4l0.1,0
        c0.1,0.4-0.1,0.8,0.2,1.1c0,0.3,0,0.6,0,0.9c0.5,0,0.2,0.7,0.2,1c0.5-0.1,0.3,0.5,0.3,0.8l0.2-0.1c0,0.2-0.1,0.5,0.1,0.6
        c-0.1,0.3-0.1,0.4,0.2,0.4c0,0.1-0.1,0.2-0.1,0.3l0.2,0c0,0.1,0.1,0.3,0.1,0.4c0,0-0.1,0.1-0.2,0.2c0.1,0.1,0.2,0.2,0.4,0.3
        c0.1,0.1,0.3,0.2,0.4,0.4c0,0.1-0.1,0.3-0.1,0.5c0.3-0.3,0.6,0.2,0.5,0.5c0.2,0.1,0.4,0.3,0.6,0.4l-0.3,0c0.2,0.1,0.3,0.3,0.4,0.5
        l0.2,0c0,0.1-0.1,0.2-0.1,0.2c0.3,0.1,0.2,0.3,0.1,0.6c0.1,0,0.2-0.1,0.3-0.1c0.1,0.1,0.2,0.2,0.3,0.3c0,0.1,0,0.3,0,0.4
        c0.1-0.1,0.3-0.2,0.3-0.2c0,0.1-0.1,0.3-0.1,0.4c0.1,0,0.3,0,0.3-0.1c0,0.2,0,0.5,0,0.8l0.1,0c0-0.1,0-0.3,0-0.4
        c0.5,0,0.2,0.6,0.4,0.9c0-0.2,0-0.5,0.2-0.6c0.1,0.3,0.1,0.5,0.2,0.8v-0.4c0.4,0.1,0.3,0.4,0.1,0.6c0.2-0.1,0.3-0.2,0.5-0.3
        c0,0.1,0,0.3-0.1,0.4c0.2,0,0.4,0.2,0.5,0.4c0.1-0.1,0.2-0.2,0.2-0.3c-0.2,0.5,0.4,0.6,0.7,0.7c0,0.1,0,0.3,0,0.4
        c0.1-0.1,0.3-0.2,0.4-0.2c0,0.3,0.1,0.5,0.5,0.4c0.1,0.3,0.4,0.2,0.6,0.3c0,0.1-0.1,0.3-0.1,0.4l0.1,0c0-0.2,0.3-0.2,0.4-0.3
        c0.2,0.2,0.3,0.3,0.6,0.4c0.1,0.1,0.2,0.2,0.2,0.3c0,0.2,0,0.4,0.3,0.4c-0.3-0.1-0.1-0.4-0.2-0.5c0.1,0.1,0.3,0.2,0.4,0.3
        c0.2,0.1,0.6,0.1,0.3,0.4c0.1,0,0.2-0.1,0.3-0.2c0.1,0.1,0.2,0.2,0.2,0.3c0-0.1,0.1-0.2,0.1-0.3c0,0.1,0,0.2,0,0.3
        c0.1,0,0.2-0.1,0.3-0.1l0.1,0.1c0.3,0,0.5,0.1,0.7,0.3c0.1,0.1,0.3,0.3,0.3-0.1c0.3,0.4,0.8,0.1,1.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.2
        c0.2,0.1,0.3-0.1,0.5-0.2c0.2,0.1,0.4,0.1,0.6,0.1c0.2,0.1,0.3,0.2,0.5,0.3V320l0.3,0c0,0.1,0,0.3,0,0.4c0.2-0.1,0.3-0.3,0.5-0.4
        c0,0.1,0,0.2,0,0.3c0.1,0,0.3,0,0.4,0c0-0.1,0-0.2,0-0.3c0.1,0.3,0.3,0.3,0.5,0.2c0.3,0.2,0.6-0.1,0.9-0.2c-0.5-0.1-1-0.3-1.2-0.8
        c-0.6-0.6-0.8-1.5-1-2.3c0.1,0.2,0.3,0.3,0.4,0.5c0,0.2,0.2,0.3,0.4,0.3l0,0.1c0.2,0.1,0.4,0.3,0.5,0.4c0-0.1,0-0.3,0-0.4
        c0,0.1,0.1,0.2,0.1,0.3c0.6,0.4,1.3,0.1,1.9,0.3c0-0.1,0-0.2-0.1-0.3c0.2-0.1,0.3-0.2,0.5-0.3c0-0.1-0.1-0.2-0.1-0.3
        c0.1,0,0.3,0,0.4,0c-0.1-0.1-0.2-0.2-0.2-0.3c0.1-0.2,0.2-0.4,0.2-0.6c0.2-0.1,0.1-0.3,0-0.4c-1-0.4-1.9-0.9-2.5-1.8
        c-0.7-0.5-0.7-1.5-1.3-2.1c0.4,0.4,0.7,0.8,1.1,1.3c0.1,0,0.2,0.1,0.3,0.2c0.3,0,0.5,0.1,0.7,0.2c0.2,0.1,0.3,0.2,0.5,0.3
        c0-0.4,0.2-0.3,0.4-0.1c0.3,0,0.6-0.1,0.9-0.3c0,0.1-0.1,0.3-0.1,0.4c0.4-0.3,1-0.8,0.6-1.3c0.2-0.2,0-0.4,0-0.6
        c-0.7-0.2-1.3-0.5-1.9-0.7c0-0.1-0.1-0.2-0.1-0.2c-0.2,0-0.4,0-0.5-0.2c-0.5-0.2-0.9-0.7-1.2-1.1c-0.3-0.4-0.7-0.7-0.9-1.2
        c-0.2-0.4-0.8-0.7-0.7-1.2c0.3,0.8,1.1,1.2,1.7,1.8c0.3,0.2,0.7,0.5,1.1,0.5l0,0.2c0.1,0,0.4-0.1,0.5-0.1c0,0.1-0.1,0.2-0.1,0.4
        c0.1,0,0.3,0,0.4,0c0-0.1-0.1-0.2-0.1-0.3c0.2,0.1,0.4,0.1,0.5,0.2c-0.1,0-0.2,0.1-0.2,0.1c0.1,0.3,0.6,0.1,0.8,0
        c0.1,0.1,0.2,0.1,0.3,0.2c0-0.2,0.1-0.3,0.3-0.3c0.1-0.1,0.2-0.3,0.3-0.4c0,0.7,0.5-0.1,0-0.2c0.5-0.1,0.5-0.5,0.5-0.9l0.2,0.1
        c-0.1-0.3-0.1-0.6-0.1-0.9c-0.3-0.5-0.4-1.1-0.9-1.5c-0.3-0.5-0.9-0.6-1-1.2c0.1,0,0.2,0.1,0.4,0.1c0.3,0,0.6,0,0.8,0.1
        c-0.1-0.2-0.3-0.4-0.4-0.5c0.3,0.1,0.6,0.4,0.5,0.7c0.4-0.2,0-0.6-0.1-0.8c0.1-0.2,0.2-0.3,0.3-0.4c-0.2,0.1-0.4,0.3-0.6,0.5
        c0-0.2,0.1-0.4,0.2-0.5c-0.2-0.1-0.2-0.3-0.2-0.5c0-0.1-0.1-0.4-0.2-0.5c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1-0.3,0-0.5,0-0.8
        c0.1-0.1,0.2-0.3,0.2-0.3c-0.2,0-0.3,0.1-0.4,0.2c-0.2-0.3-0.1-0.9-0.5-0.8c0-0.1-0.2-0.2-0.1-0.4c0,0.2,0.2,0.3,0.3,0.4
        c0-0.2-0.1-0.4-0.2-0.6c-0.1,0-0.2,0.1-0.2,0.1c-0.1-0.1-0.2-0.2-0.3-0.4c0.2,0.1,0.3,0.2,0.5,0.2c-0.1-0.2-0.2-0.4-0.3-0.6l0.4,0.1
        c-0.2-0.2-0.3-0.3-0.4-0.5c0.3-0.1,0.3-0.6,0.6-0.7c-0.4,0.1-0.2-0.2-0.2-0.4c0-0.3-0.3-0.6-0.1-0.8c0.2-0.3,0-0.6-0.1-0.9
        c-0.2-0.2-0.1-0.7-0.5-0.7c0-0.1,0.1-0.2,0.1-0.3l-0.2,0c0,0,0-0.1,0-0.2c-0.1,0-0.2,0-0.3,0.1c0-0.2,0.3-0.6,0-0.7
        c0-0.1,0-0.2,0-0.3c-0.4-0.5-0.8-1.1-1.1-1.7c-0.3-0.4-0.6-0.7-0.8-1.1c-0.2-0.4-0.6-0.5-0.8-0.9c-0.3-0.4-0.7-0.7-1.2-1
        c-0.1-0.2-0.2-0.4-0.4-0.5c0,0.1,0,0.3,0.1,0.4l-0.1,0c0-0.2-0.2-0.4-0.4-0.5c0,0.1-0.1,0.2-0.1,0.3c0-0.1,0-0.2,0-0.3
        c-0.4,0-0.7-0.2-0.9-0.5c-0.4-0.1-0.7-0.4-1.1-0.6c0,0.1-0.1,0.3-0.1,0.4l-0.1-0.3c-0.1,0-0.3-0.1-0.4-0.1c0,0.1,0,0.3,0,0.4
        c0,0-0.1,0-0.1,0c0-0.1,0-0.3,0-0.4c-0.4,0.1-0.8-0.1-1.1-0.4c-0.2,0-0.5,0-0.5,0.3c-0.1,0-0.2,0-0.2,0c0-0.1,0-0.3,0-0.4
        c-0.2,0.1-0.4,0.2-0.6,0.4c0.1,0.1,0.3,0.3,0.4,0.4c-0.4,0-0.5-0.4-0.6-0.7c-0.3,0-0.6,0.1-0.8,0.2c0-0.1,0-0.3,0-0.4
        c-0.2-0.1-0.3,0-0.3,0.3c-0.1-0.1-0.3-0.2-0.4-0.2v0.2c-0.2,0-0.5-0.1-0.6,0.1c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1-0.1-0.1-0.2-0.1-0.4
        c-0.2-0.1-0.5-0.1-0.7-0.2c-0.1,0.1-0.3,0.3-0.4,0.4c-0.2-0.2-0.4-0.4-0.7-0.3c-0.3-0.1-0.2,0.4-0.4,0.4l0-0.3l-0.2-0.1
        c-0.1,0.2-0.3,0.5-0.1,0.7c-0.1,0-0.2,0.1-0.3,0.2c0-0.1,0.1-0.4,0.1-0.5c-0.4,0-0.4,0.6-0.8,0.6c0.2-0.3,0.3-0.8,0.8-0.8
        c0-0.3-0.5-0.1-0.7-0.3c0,0.1,0.1,0.2,0.2,0.3c-0.2,0.1-0.3,0.1-0.5,0.2c0-0.1,0-0.3,0-0.3c-0.2,0-0.4,0-0.5,0
        c0.1,0.1,0.1,0.2,0.2,0.3c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1-0.3-0.4-0.5-0.7-0.4c0,0.2,0.2,0.4,0.4,0.5c-0.1,0.1-0.2,0.1-0.4,0.1
        c0-0.1,0-0.3,0-0.4c-0.2-0.1-0.4-0.5-0.5-0.1c-0.4-0.4-1.1,0.1-1.4-0.4c-0.2,0.1-0.3,0.3-0.6,0.2c0,0.2,0,0.4-0.1,0.6
        c0.2,0.1,0.3,0.2,0.3,0.4c-0.2-0.2-0.4-0.4-0.5-0.6c-0.2,0.2-0.3,0.5-0.5,0.7c0-0.2-0.1-0.4-0.1-0.6c0.3,0,0.6-0.3,0.9-0.3
        c-0.3-0.2-0.7-0.2-1,0c-0.2,0-0.4-0.1-0.6-0.1c-0.1,0.2-0.3,0.4-0.1,0.6c-0.1,0-0.2,0-0.2,0c-0.1,0.2-0.6,0.6-0.8,0.2
        c0.1,0,0.3-0.1,0.4-0.1c0-0.4-0.3-0.1-0.5,0c0-0.3,0.3-0.3,0.5-0.3c0.3,0,0.4-0.3,0.6-0.4c-0.3,0-0.7,0.2-1-0.1
        c-0.1,0.1-0.1,0.2-0.2,0.3c-0.3-0.1-0.5,0-0.6,0.2c-0.1-0.1-0.2-0.2-0.3-0.3c0,0.1-0.1,0.2-0.1,0.3c-0.3-0.1-0.6-0.3-0.9,0
        c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0.1-0.4,0.2-0.5,0.2l0-0.3c0,0-0.1,0.1-0.2,0.2c-0.3-0.1-0.4,0.1-0.4,0.4c0-0.1-0.1-0.2-0.1-0.3
        c-0.3-0.3-0.5,0.1-0.6,0.3c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2-0.1,0.3-0.2c0.1,0.3-0.3,0.4-0.5,0.4c0-0.1,0-0.3,0-0.4l-0.2,0
        c-0.1-0.3,0.4-0.2,0.3-0.4c-0.3-0.2-0.5,0.1-0.7,0.3c0.1,0.1,0.2,0.3,0.2,0.4c-0.2-0.1-0.5-0.2-0.7-0.5c0.1,0,0.3-0.1,0.4-0.1
        c0.1-0.4-0.4,0.1-0.5-0.3c-0.1-0.2-0.4,0-0.5,0c-0.2-0.4-0.6-0.1-0.9,0c0.1,0.2,0.3,0.3,0.4,0.5c-0.3-0.2-0.7-0.4-1.1-0.5
        c0.1-0.2,0.3-0.3,0.6-0.2c-0.1-0.1-0.3-0.1-0.4-0.1c-0.5,0-1-0.4-1.5-0.2c0.1,0.1,0.1,0.3,0.2,0.4c0.1-0.1,0.3-0.3,0.4-0.4
        c-0.1,0.2-0.2,0.4-0.3,0.6c0.1,0.2,0.3,0.3,0.3,0.6c-0.2-0.2-0.7-0.4-0.5-0.8c-0.2,0-0.3,0.1-0.4,0.3c0.2,0.2,0.5,0.4,0.7,0.7
        c-0.1,0.2-0.5,0.4-0.3,0.6c0.2-0.2,0.4-0.4,0.4-0.7c0.1,0.1,0.2,0.3,0.3,0.5c0,0,0.1,0,0.1,0c0.5,0.3,0.4,1,0.1,1.3
        c0.1,0.1,0.2,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2c-0.1-0.2-0.3-0.3-0.5-0.5c0-0.3,0-0.7,0.4-0.7c0,0.1-0.1,0.2-0.1,0.4
        c0,0.1,0.1,0.2,0.2,0.3c0.1,0,0.2-0.1,0.4-0.1c0,0.2-0.1,0.3-0.1,0.5c0.1,0.2,0.2,0.5,0.5,0.4c-0.1-0.2-0.2-0.4-0.3-0.7
        c0,0.1,0.1,0.2,0.2,0.2c0,0,0.1,0,0.2,0c0,0.2,0,0.3,0,0.5c-0.5,0.3,0.2,0.5,0.4,0.6c-0.2,0.1-0.4,0.2-0.6,0.1
        c0.3,0.3-0.2,0.6,0.1,0.9c0-0.1,0-0.3,0-0.4c0.2-0.1,0.3-0.2,0.5-0.4c-0.2,0.5,0.3,0.7,0.5,1c-0.1,0.2-0.1,0.4-0.2,0.5
        c0.2-0.2,0.4-0.1,0.7,0.1c-0.1-0.2-0.4-0.5-0.1-0.7c0,0.4,0.4,0.7,0.5,1.1c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1,0-0.3,0-0.5,0
        c0,0.1,0,0.2-0.1,0.3c0.2,0.1,0.5,0.3,0.8,0.3c0,0.1-0.1,0.4-0.1,0.5c0.1,0,0.2,0,0.3,0c0-0.1-0.1-0.4-0.1-0.6
        c0.1,0.1,0.2,0.2,0.3,0.3c0,0.1-0.1,0.3-0.1,0.4c0.1,0,0.3,0,0.4,0c-0.2,0.3-0.6,0-0.8-0.1c0,0.5,0.4,0.7,0.7,0.9
        c-0.1,0-0.2,0-0.3,0l0,0.1c0.2-0.1,0.4,0,0.6,0.1c0.2-0.3,0-0.6-0.3-0.5c0-0.1,0-0.2,0-0.2c0.5,0.2,0.6,0.8,0.3,1.2
        c-0.1-0.6-0.4-0.2-0.5,0l0.3,0c0,0,0.1,0.1,0.1,0.2c0.1-0.1,0.3-0.1,0.4-0.2c0,0.2,0,0.4-0.1,0.6c0.3,0,0.4,0.3,0.4,0.6
        c0,0.1,0.1,0.3,0.1,0.3c0.1,0.1,0.1,0.2,0.1,0.4c-0.1-0.1-0.3-0.2-0.4-0.2c0.3,0.3,0,0.6-0.1,0.9c0.2-0.2,0.3-0.5,0.5-0.7
        c0.4,0.1,0.4,0.4,0.3,0.7c0.2-0.1,0.3-0.2,0.5-0.4c0.1-0.2-0.4-0.2-0.2-0.5c-0.1-0.1-0.2-0.2-0.3-0.4c0.1,0,0.3,0.1,0.4,0.1
        c-0.2,0.3,0.3,0.6,0.2,1c-0.1,0-0.3,0.1-0.4,0.2l0.3,0.1c-0.1,0.2-0.2,0.4-0.3,0.5c0,0.2-0.1,0.3-0.2,0.4c0.1,0,0.2,0,0.4,0l-0.2,0
        c0.1-0.2,0.2-0.4,0.2-0.5c0.4,0,0.5,0.5,0.2,0.8l0.3-0.1c0-0.2,0.1-0.5,0.1-0.6c0.1,0.1,0.3,0.3,0.4,0.4c0,0.1,0,0.2,0,0.3
        c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0.1-0.3,0.4-0.4,0.5l0.5-0.2c0,0.2-0.1,0.4-0.1,0.6l0.2-0.1c-0.1,0.4,0.3,0.3,0.5,0.2
        c-0.1,0.4-0.2,0.9,0.2,1.2c-0.1-0.2,0.5-0.7,0-0.6c0-0.1,0-0.2,0-0.3c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.2,0,0.4-0.1,0.6
        c0,0.1,0.1,0.3,0.1,0.4c0-0.2,0.1-0.3,0.3-0.4c0.1,0.2,0,0.3-0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c-0.1,0-0.3,0-0.4,0
        c0.1,0.2,0.3,0.4,0.4,0.5c0.1-0.1,0.2-0.2,0.3-0.2c0,0.1,0,0.3,0.1,0.4c-0.1,0-0.3-0.1-0.4-0.1l0,0.2c0.1,0,0.3,0,0.4,0
        c0,0.1,0,0.2,0,0.3c0.1,0,0.3,0,0.4-0.1c0.1,0.1,0.2,0.3,0.3,0.4c-0.1,0-0.2,0.1-0.3,0.1c0.1,0.2,0.2,0.3,0.4,0.4l0.2-0.1l0,0.4
        c-0.1,0-0.3,0-0.4,0c0-0.1-0.1-0.2-0.2-0.2c-0.2,0.6,0.6,0.2,0.8,0.6c-0.1,0-0.3,0.1-0.4,0.1c0.3,0.1,0.4,0.3,0.3,0.7
        c-0.1,0-0.2,0-0.3,0l-0.1,0.1c0.1,0,0.4-0.1,0.5-0.1c0.6,0.5-0.3,0.4-0.6,0.3c0,0,0,0.1,0,0.1c0.3,0,0.5,0.1,0.7,0.2
        c0.1-0.2,0.2-0.4,0.2-0.6c-0.2,0-0.3-0.1-0.4-0.1c0-0.2,0.1-0.4,0.1-0.6c0,0.3,0.2,0.5,0.5,0.6c0,0.3,0,0.6,0,0.8
        c0.1,0.1,0.3,0.2,0.4,0.3c-0.1,0.2-0.2,0.3-0.3,0.5c0.2-0.1,0.3-0.3,0.5-0.4c0-0.2,0-0.4-0.1-0.5c0.1,0,0.2-0.1,0.2-0.1
        c0,0.3,0,0.6-0.1,0.9c0.4,0.2-0.1,0.5-0.1,0.8c0.4,0.1,0.4-0.8,0.9-0.4c-0.1,0.1-0.2,0.2-0.4,0.3c0.2,0.3,0.4,0.5,0.7,0.5
        c-0.1,0.1-0.2,0.2-0.3,0.2c0.1,0.1,0.2,0.3,0.3,0.4c0.1-0.1,0.2-0.2,0.2-0.3l0,0.3c0.1,0,0.2-0.1,0.3-0.1c0.1,0.2,0.2,0.3,0.3,0.5
        l-0.2,0.1c0.2,0.1,0.4,0.2,0.3,0.5c0.1,0,0.2,0.1,0.4,0.1c-0.2,0-0.4,0.1-0.6,0.2c0-0.1,0.1-0.3,0.1-0.4c-0.2-0.2-0.4-0.5-0.7-0.2
        c0,0.2,0.2,0.2,0.3,0.4c0,0.1-0.1,0.3-0.1,0.4c0.1,0,0.3-0.1,0.4-0.1c0,0.2,0,0.4,0,0.6c0.2,0.3,0.5,0.5,0.8,0.5
        c0.3-0.1,0-0.3-0.1-0.5c0.1,0,0.6,0.2,0.2,0.2c0,0.1,0.1,0.3,0.2,0.4l-0.2,0c0.2,0.3-0.1,0.6-0.2,0.9c-0.1-0.1-0.2-0.2-0.3-0.3
        c0.1,0.1,0.2,0.3,0.3,0.4c0.1-0.2,0.3-0.3,0.5-0.4c0,0.3,0.3,0.4,0.6,0.4c0.1,0.1,0.2,0.2,0.2,0.3c-0.1,0.2-0.1,0.4-0.3,0.5
        c0.2,0.2,0.4,0.4,0.6,0.6c0.1,0.1,0.2,0.3,0.3,0.4l0.2-0.4c0,0.3,0.1,0.4,0.4,0.3c0.1,0.3,0.2,0.6,0.5,0.8c0,0.1-0.1,0.2-0.1,0.3
        c0.3,0,0.6-0.1,0.7,0.2c0-0.1,0.1-0.2,0.1-0.3c0,0.1,0,0.3,0,0.4c0.2,0.1,0.4,0.2,0.5,0.3c-0.1,0-0.4,0-0.6,0
        c0.1,0.1,0.2,0.2,0.4,0.2c0,0.2,0.2,0.3,0.3,0.5c0,0,0.1-0.1,0.1-0.1c0.1,0.1,0.3,0.1,0.4,0.2c0,0.1,0,0.2-0.1,0.3
        c0.3,0,0.4,0.3,0.5,0.5c0.1-0.1,0.2-0.1,0.3-0.2c0,0.1,0.1,0.2,0.2,0.3c0,0,0.1,0.1,0.1,0.2l0.2,0.1c0-0.3,0.2-0.1,0.3,0l-0.3,0.1
        c0.1,0.1,0.3,0.3,0.4,0.5c0-0.1,0.1-0.2,0.2-0.3c0,0.1,0,0.2,0,0.3c0.2,0.2,0.5,0.2,0.8,0.4c-0.2,0-0.3,0.1-0.5,0.1
        c0.1,0.1,0.2,0.2,0.3,0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0-0.2,0-0.3,0c0.2,0.2,0.3,0.4,0.5,0.4
        c0-0.1-0.1-0.3-0.1-0.4c0.3,0,0.5,0.1,0.4,0.4c0.1,0,0.2,0.1,0.3,0.1c0.5,0.1,0.6,0.8,1.2,0.8c0.2,0.1,0.4,0.2,0.6,0
        c0.1,0.1,0.3,0.2,0.4,0.3c0-0.1,0.1-0.2,0.2-0.2c0,0.1,0,0.3,0,0.4c0.3,0.1,0.4-0.2,0.5-0.4c-0.1,0-0.2-0.1-0.2-0.2
        c0.3-0.1,0.4,0.4,0.3,0.6c0.2,0.1,0.4,0.1,0.6,0.2l0,0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.2,0.1,0.5,0.2,0.7,0.2c0.3,0.2,0.6,0.4,0.9,0.5
        c0-0.1,0-0.3,0.1-0.4l0.1,0l0.1,0.3c0.2-0.1,0.2-0.3,0.3-0.5c0,0.2,0,0.4,0.1,0.6c0.1-0.1,0.3-0.1,0.5-0.2c0,0.1,0.1,0.2,0.2,0.3
        c0.5,0.2,0.7-0.3,1-0.5c0,0.2-0.1,0.4-0.2,0.5c0.2,0,0.4-0.1,0.6-0.3c0.1,0.1,0.4,0.2,0.5,0.3l-0.1-0.2c0.4-0.1,0.7-0.2,1.1-0.2
        c-0.1,0.3-0.5,0.3-0.8,0.5c0.2,0.1,0.4-0.2,0.7-0.1c0.2-0.4,0.6-0.7,1.1-0.9c0.2,0.1,0.3,0.2,0.6,0.2c0.1-0.2,0.1-0.3,0.2-0.4
        c0.3,0,0.7,0.1,0.9-0.2l-0.1,0.4c0.3,0,0.3-0.3,0.4-0.4c0.2,0,0.6,0.3,0.6-0.2c-0.1,0-0.3,0.1-0.4,0.1c-0.1-0.1-0.2-0.2-0.3-0.3
        c0.2,0.1,0.5,0.1,0.8,0.1c0.1-0.3,0.1-0.5,0.2-0.7l0.1,0c-0.1,0.2,0,0.5,0.1,0.7c0.1-0.3,0.3-0.6,0.6-0.7c0.3,0,0.2-0.6,0.5-0.6
        l0.1,0.1c0.1,0,0.2,0.1,0.2,0.1l-0.2-0.1c0.1-0.1,0.1-0.2,0.2-0.3c-0.1,0-0.3,0-0.4,0c0.1-0.3,0.6-0.6,0.3-0.9
        c0.1-0.1,0.2-0.2,0.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.2c0.1,0,0.2,0,0.3,0c0-0.1,0.1-0.3,0.1-0.4l-0.2,0c0.1-0.3,0.5-0.5,0.4-0.9
        c0.1-0.2,0.2-0.5,0.2-0.7c0,0,0.1,0,0.2,0c0-0.1,0-0.3,0-0.4c0-0.1-0.1-0.2-0.1-0.3c0.2-0.7,0.2-1.5,0.4-2.2c0.2-0.1,0.1-0.2,0-0.4
        c0.1-0.3,0.6-0.8,0.1-1c0.1,0,0.2-0.1,0.3-0.1c0.1-0.5-0.2-0.9,0.1-1.3c-0.2-0.5-0.1-1,0-1.5c-0.6-0.3,0.2-1-0.3-1.3
        c0-0.2,0-0.3,0-0.5c0.1,0,0.2-0.1,0.2-0.1c0.1-0.2-0.3-0.2-0.2-0.4c0-0.2,0.1-0.4,0.2-0.6c-0.3-0.4,0.1-1.1-0.3-1.5
        c0.1-0.2,0.1-0.3,0.2-0.5c-0.1-0.2-0.2-0.3-0.3-0.5c0.2-0.5,0-1.2-0.1-1.7c0.1-0.4-0.2-0.8,0.1-1.1c-0.3-0.4-0.3-1-0.4-1.4
        c0-0.2,0.1-0.4-0.1-0.6c-0.2-0.4-0.1-0.8-0.1-1.2c-0.2-0.2-0.3-0.3-0.1-0.5c-0.2-0.4-0.4-0.8-0.4-1.2c-0.3-0.3-0.3-0.8-0.3-1.1
        c-0.4-0.3-0.3-0.8-0.5-1.2c-0.2-0.4-0.2-0.9-0.3-1.4c-0.5-0.5-0.5-1.3-0.8-1.9c0-0.5-0.4-0.9-0.5-1.4c0-0.4-0.3-0.6-0.3-1
        c-0.4-0.5-0.3-1.2-0.9-1.6c0.1-0.4-0.4-0.5-0.6-0.7c-0.3-0.6-0.9-0.9-1.3-1.4c-0.3-0.1-0.5-0.6-0.8-0.4c0.2-0.3-0.1-0.6-0.4-0.8
        c0,0.1-0.1,0.2-0.1,0.3c-0.5-0.6-1-1.3-1.7-1.7c0-0.3-0.4-0.2-0.6-0.4c-0.2-0.2-0.4-0.4-0.7-0.5c0-0.1,0.1-0.2,0.1-0.3
        c-0.2,0-0.5-0.1-0.7-0.1c0-0.1,0-0.2-0.1-0.3c-0.2-0.1-0.3-0.2-0.5-0.2c-0.1-0.1-0.2-0.3-0.3-0.4c-0.4,0.1-0.6-0.4-1-0.3
        c0-0.3-0.2-0.5-0.4-0.5c-0.5-0.2-0.9-0.6-1.3-1c-0.1,0-0.3,0-0.4,0c0-0.1,0-0.3,0-0.4c-0.1,0-0.4-0.1-0.6-0.1
        c-0.1,0.2,0,0.4,0.2,0.6c-0.2-0.1-0.4-0.3-0.6-0.4c0.1-0.1,0.2-0.3,0.4-0.4c-0.1,0-0.4-0.1-0.6-0.1c-0.1-0.2-0.4-0.3-0.6-0.5
        c-0.2,0.1-0.4,0.1-0.6,0.1c0.1-0.1,0.2-0.2,0.3-0.4l-0.2-0.1l-0.1-0.2c-0.5,0.1-0.8-0.3-0.9-0.7c0,0.1-0.1,0.2-0.1,0.3
        c0-0.1,0-0.3,0.1-0.3c-0.2,0-0.5-0.1-0.7-0.1c-0.4-0.1-0.6-0.5-1-0.5c0-0.5-0.9-0.8-1.2-0.4c0-0.2,0-0.5-0.2-0.6
        c-0.2,0.1-0.3,0.3-0.5,0.1c0.1,0,0.3-0.1,0.4-0.1c-0.5-0.2-1-0.6-1.6-0.8c0,0.1,0.1,0.3,0.1,0.4c0,0-0.1,0-0.2,0c0-0.1,0-0.2,0-0.3
        l-0.3,0c0.1-0.2,0.3-0.2,0.5-0.1c-0.2-0.3-0.5-0.3-0.8-0.3c-0.2-0.2-0.3-0.5-0.5-0.7c-0.2,0.2-0.5,0.3-0.8,0.5
        c-0.1-0.2-0.4,0-0.5-0.2c0.3,0,0.7,0.1,1-0.2c-0.4,0-0.7-0.1-1-0.3l0.1,0.4c-0.1-0.1-0.2-0.2-0.2-0.4c-0.1,0-0.3-0.1-0.4-0.2
        c0,0.1-0.1,0.2-0.1,0.2c-0.1,0-0.3,0-0.4,0.1c0.1,0,0.2-0.1,0.3-0.1c-0.1-0.2-0.1-0.6-0.4-0.5l0,0.3l-0.1,0c0-0.1,0.1-0.2,0.1-0.3
        c-0.8-0.2-1.3-0.9-2.2-0.8c-0.3-0.2-0.5-0.5-0.8-0.7c0,0.1,0,0.2-0.1,0.3c-0.3-0.2-0.6-0.4-0.9-0.3c-0.1-0.1-0.2-0.2-0.3-0.3
        c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1-0.1-0.3-0.1-0.4,0c0.1-0.1,0.1-0.2,0.2-0.3c-0.1-0.1-0.2-0.2-0.2-0.2c-0.5-0.2-1-0.3-1.5-0.3
        c0-0.1,0-0.2,0-0.2c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2,0.2-0.6,0.2-0.8,0c-0.3-0.2-0.6-0.2-0.9-0.4c-0.2-0.1-0.5,0.1-0.5-0.2
        c-0.1,0-0.3,0-0.4,0.1c-0.1-0.2-0.2-0.3-0.2-0.5c-0.3,0-0.5,0.1-0.8,0c-0.3,0-0.5-0.3-0.9-0.3c-0.3,0.1-0.4-0.2-0.6-0.3
        c-0.1,0-0.2,0.1-0.2,0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.3,0-0.5-0.1c-0.3,0.1-0.5,0.2-0.7,0.4c0-0.1,0-0.2,0-0.2
        c0.3,0.1,0.3-0.2,0.5-0.3c-0.5-0.3-1.1-0.3-1.7-0.4c-0.3-0.3-0.7-0.4-1.1-0.3c-0.1-0.2-0.1-0.4-0.3-0.5c-0.1,0.1-0.3,0.2-0.4,0.2
        c-0.2-0.1-0.6-0.6-0.6-0.1c0.1-0.4-0.4-0.3-0.6-0.5c-0.7-0.4-1.7-0.1-2.3-0.7c0,0.1,0.1,0.4,0.2,0.5c-0.2-0.1-0.2-0.3-0.3-0.5
        c-0.1-0.1-0.3-0.2-0.4-0.2c0,0.1,0,0.2,0,0.3c0,0-0.1,0-0.1,0c0-0.1,0-0.2,0-0.3c-0.2-0.1-0.5-0.1-0.8-0.1c-0.3-0.2-0.5-0.4-0.8-0.6
        c-0.2,0-0.3,0.1-0.5,0.2c-0.6-0.2-1.1-0.7-1.7-0.5c-0.2-0.2-0.4-0.4-0.7-0.5v0.3c0,0,0-0.1,0-0.2c-0.4,0-0.7-0.3-1.1-0.4
        c-0.1,0-0.4,0-0.5,0l0.1,0.3c0,0-0.1-0.1-0.1-0.1l0-0.3c-0.2,0.1-0.4,0.2-0.6,0.1c0.1,0,0.3-0.1,0.4-0.2c-0.4-0.2-0.7-0.5-1.1-0.7
        c0.1-0.5-0.4-0.2-0.7-0.2l0,0.2c-0.3-0.3-0.6-0.7-0.9-0.9c-0.3-0.1-0.2-0.5-0.2-0.7c-0.4,0.2-0.9,0-1-0.4c0-0.4-0.5-0.3-0.6-0.5
        c-0.3-0.4-0.6-0.7-0.8-1.1c-0.2-0.1-0.3-0.3-0.4-0.5c-0.2-0.5-0.6-0.9-0.8-1.3c-0.2-0.2-0.4-0.4-0.3-0.7c-0.6-0.3-0.6-1-1-1.4
        c-0.1-0.2-0.1-0.4-0.2-0.5c-0.4-0.4-0.6-0.9-0.7-1.3c-0.1-0.2-0.2-0.4-0.4-0.4c0-0.1-0.1-0.2-0.1-0.3c0.2,0.2,0.4,0.4,0.5,0.7
        c0.3,0.4,0.5,1,1.1,1.1c0,0.1,0,0.2,0,0.3c0.1,0,0.2,0,0.3,0c0,0.1,0,0.2,0,0.3c0.1,0,0.2-0.1,0.2-0.1c0.3,0.4,0.6,0.7,1,1
        c-0.1-0.2-0.2-0.4-0.3-0.6c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.1,0.3,0.3,0.4,0.5c-0.2-0.1-0.4-0.1-0.6-0.2c0.1,0.2,0.2,0.3,0.4,0.2
        c0,0.1-0.1,0.4-0.1,0.5l0,0c0.1-0.2,0.1-0.3,0.2-0.5c0.2,0.5,0.8,0.8,1.1,1.3c0-0.2,0-0.3,0.2-0.4c0,0.2,0,0.5,0,0.7l0.1,0
        c0-0.2,0.1-0.3,0.2-0.4c0,0.1,0,0.3,0,0.4c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.3,0.1,0.4,0.1c-0.1,0.1-0.4,0.1-0.4,0.4
        c0.3,0,0.5-0.2,0.7-0.4c0,0.3,0.3,0.1,0.4,0.4l-0.2,0l-0.1-0.1c-0.2,0-0.4,0.2-0.5,0.4c0.2-0.3,0.5-0.1,0.8-0.1
        c-0.2,0.3,0.2,0.5,0.5,0.7c0.3-0.3-0.4-0.2-0.3-0.6c0.2,0.1,0.4,0.1,0.6,0c-0.1,0.2,0,0.5,0,0.7c0.2-0.1,0.4-0.1,0.6-0.2
        c0.3,0.2,0.6,0.4,1,0.6c0,0.1-0.2,0.2-0.2,0.2c0.3,0,0.5,0.1,0.6,0.3c0-0.2-0.2-0.5,0.1-0.4c0-0.1-0.1-0.3-0.2-0.4
        c0.2,0.1,0.4,0.3,0.6,0.6c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0.2-0.1,0.4-0.2,0.6c0.2-0.1,0.3-0.2,0.5-0.3c0.1,0.1,0.2,0.2,0.3,0.2
        c0,0.3,0.2,0.5,0.4,0.4c0-0.1,0-0.4,0-0.5c0.2,0.1,0.1,0.4,0.4,0.4c0.1,0,0.3,0.1,0.4,0.1c-0.1,0.1-0.1,0.2-0.2,0.3
        c0.2,0.1,0.5,0.1,0.7,0.2c0-0.1,0.1-0.3,0.1-0.4c-0.1,0-0.2-0.1-0.3-0.1c0,0,0.1-0.1,0.2-0.1c0.2,0.1,0.5,0.2,0.7,0.4
        c-0.2-0.1-0.3-0.2-0.5-0.1c0,0.1,0.1,0.3,0.1,0.4c0.4-0.1,0.8,0,1.1,0.2c0.2-0.1,0.4-0.1,0.5-0.2c0,0.2-0.1,0.3-0.2,0.5
        c0.2,0,0.3-0.1,0.5-0.1c0.1,0.2,0.3,0.2,0.5,0.1c0,0.1-0.1,0.3-0.1,0.4c0-0.1,0.1-0.2,0.1-0.3c0.1,0,0.2,0,0.3,0
        c-0.1,0.4,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0c0.1,0.5,0.7,0.2,1,0.2c-0.1,0.1-0.2,0.3-0.4,0.5c0.2-0.2,0.5-0.2,0.8-0.3
        c0,0.2,0,0.3,0,0.5c0.4-0.1,0.8-0.1,1.1,0.1c0.1-0.1,0.2-0.2,0.3-0.2c0,0.1-0.1,0.3-0.2,0.4c0.1,0,0.3-0.1,0.3-0.1
        c0.1,0.1,0.2,0.2,0.3,0.3c0.1-0.2,0.3-0.4,0.4-0.5c-0.1,0.2-0.3,0.4-0.4,0.6c0.2,0,0.4,0,0.6,0.2c0.3,0.1,0.5-0.1,0.8,0
        c0,0,0.1-0.1,0.1-0.2c0.2,0.3,0.5,0.5,0.9,0.4c0-0.1,0-0.2,0-0.3l0.2,0.1c0,0,0,0.1,0,0.2c0.1,0,0.3,0,0.4-0.1c0,0,0.1,0.2,0.1,0.2
        c-0.2,0-0.4,0-0.6,0c0.1,0.1,0.3,0.3,0.4,0.4c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0.3,0.4,0.3,0.6,0.2c0.1,0.4,0.6-0.1,0.8,0.3
        c0.3,0,0.5,0.1,0.7,0.2l0-0.3c0,0.1,0,0.3,0.1,0.3c0.2-0.1,0.3-0.2,0.5-0.3c-0.1,0.1-0.2,0.3-0.3,0.4c0.2,0,0.4,0.1,0.6,0.1
        c0.2,0.3,0.5,0.3,0.8,0.4c0.1-0.2,0.2-0.3,0.3-0.4l0,0.1c-0.1,0.1-0.2,0.2-0.3,0.2c0.3,0.1,0.6,0.2,0.9,0.4c0.2-0.1,0.4-0.1,0.5,0.1
        c0.1,0,0.4,0,0.6,0c-0.1-0.1-0.1-0.2-0.2-0.3c0.1,0,0.2-0.1,0.2-0.1c0,0.2,0,0.3,0,0.5l-0.2,0c0,0.3,0.3,0.3,0.5,0.3
        c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.2-0.1,0.3-0.1c0.2,0.3,0.5,0,0.8,0c-0.1,0.1-0.2,0.2-0.2,0.3c0.2,0,0.4,0,0.6,0
        c-0.1,0.1-0.4,0.2-0.5,0c-0.1,0.1-0.1,0.1-0.2,0.2c0.2,0.1,0.3,0.2,0.5,0.4l0-0.3c0.2,0.3,0.5,0.4,0.9,0.4c-0.1-0.2-0.1-0.3-0.2-0.4
        c0.1-0.1,0.2-0.1,0.3-0.2c-0.1,0.3,0.1,0.6-0.2,0.9c0.2-0.1,0.4-0.2,0.5-0.3l0.1,0.2l-0.3,0c0.1,0,0.3,0,0.4,0
        c-0.1,0.6,0.5-0.1,0.7,0c-0.1,0.1-0.2,0.3-0.3,0.4l0,0.1c0.2-0.1,0.4-0.3,0.5-0.4c0,0.2-0.1,0.3-0.1,0.5c0.2-0.1,0.4-0.1,0.5-0.3
        l0,0c0,0.1,0,0.2-0.1,0.3c0.2,0,0.4-0.1,0.5-0.3c0.1,0.1,0.1,0.2,0.2,0.2c-0.3,0-0.4,0.2-0.5,0.4c0.2,0,0.5,0,0.6-0.2
        c0.2,0.2,0.4,0.4,0.6,0.6c0.1-0.1,0.2-0.2,0.3-0.3c0,0.1,0.1,0.3,0.2,0.4c0.2-0.1,0.3-0.2,0.5-0.3c0,0.1-0.1,0.3-0.2,0.3
        c0.3,0,0.7,0,1,0c-0.1,0.1-0.2,0.1-0.2,0.2c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0,0.4,0,0.6-0.1c0,0.1-0.1,0.3-0.2,0.3c0.2,0,0.3,0,0.5,0
        c-0.1-0.1-0.2-0.2-0.3-0.3c0.3,0,0.4,0.4,0.7,0.5c0.2,0,0.4,0,0.6,0c0,0.1,0.1,0.3,0.2,0.5c0.1-0.3,0.3-0.2,0.5-0.3
        c0,0.3,0.1,0.4,0.4,0.3c0.1,0.3,0.2,0.6,0.5,0.4c0.1,0.3,0.5,0.3,0.8,0.4c0,0.1-0.1,0.3-0.1,0.4c0.2,0,0.4,0,0.5,0
        c-0.1-0.2-0.5-0.2-0.4-0.5c0.1,0,0.4,0,0.5,0c0,0.1,0.1,0.3,0.1,0.4c0.1-0.1,0.5-0.4,0.4,0.1c-0.1,0-0.2,0-0.3,0c0,0,0,0.1,0,0.2
        c0.3-0.2,0.6-0.1,0.8,0.2c0.2-0.1,0.5-0.2,0.7-0.3c-0.1,0.2-0.2,0.4-0.4,0.5c-0.2,0.1-0.3,0.4-0.5,0.5c0.3-0.1,0.5-0.5,0.8-0.4
        c0-0.1,0.1-0.2,0.1-0.2c0.2,0.1,0.3,0.2,0.5,0.4c0.3-0.1,0.5,0,0.8,0.2c0,0,0,0,0,0c-0.1,0-0.3,0.1-0.4,0.1c0-0.1-0.1-0.2-0.1-0.3
        c-0.2,0.2-0.3,0.4-0.5,0.6c0.1,0,0.3-0.1,0.4-0.2c0.2,0.2,0.5,0.3,0.8,0.1c0,0.1,0,0.3,0,0.4c0.1,0,0.3-0.1,0.4-0.2
        c0.2,0.2,0.6,0.1,0.6,0.5c-0.1,0-0.3,0.1-0.4,0.1l0-0.2c-0.1,0-0.3,0.1-0.4,0.1c0,0.1,0,0.3,0,0.3c0-0.1,0.1-0.2,0.2-0.2
        c0.2,0.4,0.6,0.1,0.9,0c0,0.2,0,0.3-0.1,0.5c0.3-0.1,0.5-0.2,0.8-0.3c0,0.1,0,0.2,0,0.3c0.3-0.1,0.5,0.2,0.7,0.3
        c0-0.1,0.1-0.3,0.1-0.4c0.2,0.3,0.3,0.8,0.7,0.7c0,0.1,0,0.3,0,0.4c0.1-0.1,0.2-0.2,0.2-0.3c0.3,0.3,0.3,0.7,0.7,1
        c0-0.1,0.1-0.2,0.1-0.3c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.5-0.1,0.5,0.2c0.3-0.1,0.5,0.1,0.5,0.4c-0.3-0.2-0.9-0.7-1-0.1
        c-0.1,0-0.2,0-0.2,0c0.2,0.1,0.3,0.2,0.5,0.3l-0.1-0.4c0.2,0.1,0.3,0.3,0.5,0.2c0.3,0.1,0.5,0.1,0.8,0.3c0.1-0.1,0.3-0.2,0.3-0.4
        c-0.1-0.2-0.3-0.2-0.4-0.3c0.2,0,0.4,0,0.6,0.1c-0.3-0.2-0.5-0.4-0.6-0.6c-0.1,0.1-0.1,0.3-0.2,0.4c0-0.2,0-0.3,0-0.5
        c-0.2-0.3-0.5-0.5-0.8-0.7c-0.1,0-0.2,0-0.3,0c-0.1-0.3-0.4-0.5-0.6-0.7c-0.1,0.1-0.2,0.2-0.2,0.3l0-0.3c-0.1,0-0.2,0.1-0.3,0.1
        c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.2-0.2-0.3c-0.1,0-0.2,0.1-0.2,0.1c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1,0.1-0.2,0.3-0.3,0.4l0.1-0.2
        c-0.1,0-0.3,0-0.4,0.1c0,0,0-0.2,0.1-0.2c0.2,0,0.3,0,0.5-0.1c-0.3-0.3-0.5-0.6-0.9-0.7c0,0.1,0,0.3,0.1,0.4
        c-0.2-0.1-0.3-0.2-0.1-0.4c-0.4,0.1-0.5-0.4-0.8-0.6c0.2-0.3-0.2-0.5-0.3-0.2c-0.5-0.1-0.6-0.8-1.2-0.5l0,0c0.1,0,0.2,0,0.2,0
        c-0.2-0.7-1.1-0.8-1.6-1.1c-0.1,0.2,0,0.3,0.2,0.5c-0.1,0-0.2,0-0.2,0c0,0.1,0.1,0.3,0.1,0.3c-0.1-0.2-0.2-0.3-0.3-0.4
        c0-0.2,0-0.4,0.1-0.5c-0.3-0.2-0.5-0.7-0.9-0.5c-0.1-0.2-0.3-0.6-0.6-0.5c-0.3,0.2,0.2,0.4,0.4,0.6c-0.2-0.1-0.4-0.2-0.6-0.4
        c0-0.1-0.1-0.2-0.1-0.2c-0.1,0-0.3,0-0.4,0.1c0.2-0.1,0.3-0.3,0.5-0.4c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0-0.2,0.1-0.3,0.1
        c-0.1-0.5-0.7-0.5-1-0.8c-0.6-0.3-1.3-0.7-2-0.7c0.1-0.1,0.2-0.2,0.3-0.3c-0.2,0-0.5-0.1-0.6-0.1c0,0,0,0.1,0,0.2
        c-0.1-0.2-0.4-0.2-0.6-0.2c0.1-0.1,0.2-0.2,0.3-0.3c-0.3-0.1-0.5-0.2-0.8-0.2c-0.1-0.2-0.4-0.4-0.6-0.4c0.1,0.2,0.1,0.3,0.2,0.5
        c0,0-0.2-0.1-0.2-0.2c0.2-0.3-0.4-0.8-0.3-0.2c-0.3-0.7-1.1-1.2-1.8-0.8c0-0.6-0.6-0.4-0.8-0.7c-0.4-0.1-0.7-0.1-1.1-0.1
        c-0.1-0.2-0.2-0.4-0.4-0.5c0,0.1,0.1,0.3,0.1,0.4c-0.1,0-0.2-0.1-0.3-0.1c0,0.1-0.1,0.2-0.1,0.2c-0.1-0.1-0.2-0.2-0.3-0.3
        c0.2,0,0.4-0.1,0.5-0.2c-0.3-0.1-0.7-0.2-1.1-0.1c0-0.2-0.1-0.4-0.2-0.5c-0.3,0.1-0.7,0.2-1,0.2c0.2-0.1,0.4-0.2,0.6-0.2
        c-0.2-0.3-0.5-0.3-0.7-0.3l0.1,0.3l-0.1,0c0-0.5-0.6-0.3-0.9-0.5c0.2,0.3,0.4,0.5,0.7,0.6c-0.2,0-0.3,0-0.5-0.1
        c-0.2-0.2-0.4-0.4-0.6-0.5l0.1-0.2c-0.3-0.1-0.6,0.1-0.8,0.3c0.1-0.1,0.3-0.3,0.3-0.4c-0.1,0-0.3,0-0.4,0l-0.1-0.1
        c-0.3-0.1-0.4,0.1-0.5,0.3c-0.1,0-0.1-0.1-0.2-0.1l-0.1,0.2c0-0.1-0.1-0.2-0.2-0.3c0.1,0,0.3,0.1,0.4,0.1c0-0.1,0.1-0.3,0.1-0.4
        c-0.3,0-0.7-0.1-1-0.2c-0.1,0.1-0.2,0.3-0.2,0.5c-0.1,0-0.2-0.1-0.3-0.2c0.2-0.1,0.5-0.1,0.5-0.4c-0.3,0-0.5,0.1-0.7,0.2
        c0-0.1-0.1-0.3-0.1-0.4c-0.2,0-0.3,0.1-0.4,0.3c0.2-0.5-0.5-0.5-0.9-0.5l0-0.2c0,0.1-0.1,0.2-0.1,0.2c-0.1,0-0.2-0.1-0.3-0.1
        c-0.5-0.5,0.2,0.4-0.3,0.3c0-0.1,0-0.3,0.1-0.4l-0.3,0.1c0-0.1,0.1-0.2,0.1-0.3c-0.1,0-0.2,0-0.3-0.1c0,0.2,0.1,0.4,0.1,0.6
        c-0.2-0.1-0.4-0.1-0.5-0.2c0.1,0,0.4,0.1,0.5,0.1c0-0.1-0.1-0.4-0.1-0.6c-0.3-0.1-0.6,0.1-0.8,0.4l0.1-0.2l-0.2,0.1
        c0-0.1,0-0.3,0-0.4c-0.2,0-0.4,0.1-0.6,0.2c0-0.4-0.3-0.3-0.5-0.2c0-0.1-0.1-0.3-0.1-0.4c-0.2,0.1-0.4,0.1-0.7,0.2l0.1,0.2
        c-0.1-0.1-0.3-0.2-0.4-0.3c-0.3-0.3-0.7-0.7-1.2-0.4l-0.2-0.2c0,0.1,0,0.3-0.1,0.3c-0.1,0-0.3,0-0.4,0c0.1-0.1,0.2-0.2,0.3-0.4
        c-0.3,0-0.6-0.1-1-0.1c-0.2-0.1-0.4-0.3-0.7-0.4c-0.3,0-0.6-0.2-0.9-0.2c0.1,0.2,0.1,0.4,0.4,0.4l0,0c-0.1,0-0.3,0-0.3,0
        c-0.1-0.2-0.3-0.4-0.4-0.6c-0.1,0.1-0.2,0.1-0.3,0.2c0-0.2-0.1-0.3-0.1-0.5c0,0.1-0.1,0.2-0.2,0.3c-0.3-0.4-0.6-0.2-0.9-0.1
        c0-0.1,0.1-0.2,0.1-0.3c-0.5-0.2-1-0.3-1.3-0.7c0,0.1-0.1,0.3-0.1,0.3c0-0.1,0-0.2,0-0.3c-0.5,0.1-1.1-0.2-1.4-0.6
        c-0.4,0-0.8-0.2-1.1-0.5c-0.3-0.3-0.7-0.4-0.9-0.7c-0.1-0.2-0.2-0.4-0.5-0.3c-0.2-0.2-0.5-0.3-0.8-0.3c0.1,0,0.4,0,0.5,0.1
        c-0.2-0.3-0.5-0.5-0.8-0.7c-0.1,0.1-0.2,0.2-0.2,0.3c0-0.1,0.1-0.3,0.1-0.4c-0.1,0-0.4,0-0.5,0l0.3,0c-0.2-0.2-0.3-0.4-0.5-0.5
        c-1-0.3-1.6-1.3-2.4-1.8c-0.2-0.1-0.4-0.4-0.6-0.6c-0.2-0.1-0.6-0.2-0.7-0.4c-0.3-0.5-0.7-0.8-1.1-1c-0.4-0.3-0.5-0.9-1-1.1
        c-0.3-0.1-0.5-0.3-0.6-0.7c-0.4-0.3-0.7-0.7-1.2-0.9c-0.5-0.4-1.1-0.8-1.1-1.6c-0.1,0-0.4,0-0.5,0c-0.2-0.2-0.4-0.4-0.6-0.5
        c0-0.1,0-0.2,0-0.3c-0.7-0.1-0.8-0.9-1.3-1.2c0-0.4-0.1-0.8-0.5-1c0,0.1,0,0.3,0,0.4c0-0.2-0.1-0.4-0.1-0.6
        c-0.1,0.1-0.2,0.2-0.3,0.3c0-0.2,0.1-0.9-0.3-0.7l-0.1,0.1c-0.1,0-0.3,0-0.4,0c0.1-0.1,0.2-0.2,0.3-0.2c0,0,0.1-0.1,0.1-0.1
        c-0.2-0.3-0.4-0.5-0.6-0.7c-0.2-0.3-0.3-0.6-0.5-0.8c-0.5-0.4-1-1-1.5-1.5c-0.5-0.2-0.8-0.6-1.2-1c-0.4-0.5-1-0.8-1.4-1.3
        c0.2-0.4,0.9,0.7,0.6,0c0.2,0.1,0.4,0.2,0.6,0.4c0.3,0,0.6,0,0.9,0l-0.1,0.2c0.1-0.1,0.3-0.2,0.4-0.2c0.1,0,0.2,0.1,0.3,0.1
        c0-0.1,0.1-0.2,0.1-0.2c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.2,0.2,0.3c0-0.1,0-0.3,0-0.4c-0.1,0-0.2,0-0.3,0
        c0.1-0.1,0.1-0.2,0.2-0.3c0,0.1,0.1,0.2,0.2,0.3c0.1-0.1,0.3-0.2,0.4-0.3c-0.1,0.2-0.1,0.3-0.2,0.5c0.3,0.1,0.4,0,0.5-0.3
        c0.1,0.1,0.2,0.3,0.4,0.3c0-0.1,0-0.3,0-0.4l0.3,0c0-0.1,0-0.2,0-0.2c0.2-0.1,0.5-0.3,0.5,0l-0.2,0c-0.1,0.2-0.3,0.4,0,0.6
        c0-0.1,0-0.2,0-0.3c0.2-0.1,0.3-0.3,0.5-0.5c0.2-0.1,0.4,0.2,0.6,0.3c0-0.2-0.1-0.3-0.1-0.5c0.1,0.1,0.2,0.2,0.3,0.2
        c-0.2-0.3,0.6-0.9,0.2-0.2c0.3-0.1,0.5-0.1,0.8-0.1c0-0.1-0.1-0.2-0.1-0.3c0.2-0.3,0.2,0,0.3,0.2c0.2,0.1,0.4-0.2,0.5-0.3
        c-0.1,0-0.2,0.1-0.2,0.2l-0.3-0.1c0.2-0.2,0.7-0.5,0.6,0c0.1-0.1,0.3-0.3,0.5-0.4c0.1,0.1,0.2,0.3,0.3,0.4c-0.1-0.3,0-0.6,0.3-0.8
        c0-0.1,0-0.3,0-0.4c0.2,0,0.4,0,0.6,0c-0.1-0.3-0.1-0.5,0.2-0.6c0,0.1,0,0.3,0,0.4c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.2,0.4-0.2,0.4-0.5
        c-0.1,0-0.2-0.1-0.3-0.1l0-0.1c0.3-0.1,0.4-0.4,0.6-0.6c0.1,0,0.2,0.1,0.2,0.1c0.1-0.2,0.1-0.4,0.2-0.6c-0.1,0-0.3,0.1-0.4,0.1
        c0,0,0-0.1,0-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0,0.3,0.2,0.4,0.4,0.3c-0.1-0.1-0.2-0.3-0.2-0.4c0.1,0,0.3,0.1,0.5,0.1
        c-0.1-0.1-0.2-0.3-0.2-0.4c-0.1,0.1-0.2,0.2-0.2,0.2c0.1-0.2,0.3-0.3,0.4-0.5c0,0.1,0.1,0.3,0.2,0.4c0-0.2,0-0.5,0-0.7
        c-0.1,0-0.3,0-0.4,0c0.2-0.2,0.4-0.4,0.6-0.5c-0.1,0-0.2-0.1-0.3-0.1c0,0.1-0.1,0.3-0.1,0.3c-0.1,0-0.3-0.1-0.4-0.2
        c0,0.1,0,0.3,0,0.3l-0.1,0c0.1-0.2,0.1-0.4-0.1-0.6c-0.2,0.4-0.6,0.3-0.9,0c-0.3,0.4-0.7-0.1-1.1,0c0,0.1-0.1,0.2-0.2,0.2
        c0-0.1-0.1-0.2-0.1-0.2l0.2,0l0-0.3c-0.2,0-0.3,0-0.5,0c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1-0.2-0.2-0.3-0.3-0.3
        c-0.1-0.2-0.3-0.3-0.6-0.3c0,0.1,0.1,0.3,0.1,0.4c-0.3-0.1-0.8,0-0.9-0.5c-0.2,0.1-0.3,0.3-0.4,0.4c-0.1,0-0.2,0-0.3,0
        c-0.1-0.1-0.2-0.3-0.2-0.4c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1-0.1-0.2-0.2-0.3-0.3c0,0.1,0.1,0.3,0.1,0.4c-0.4-0.3-0.9-0.1-1.3,0
        C121.5,227.9,121.3,227.8,121.2,227.7 M388.3,228c0,0.1,0,0.2,0,0.2c-0.2-0.3-0.5-0.3-0.8-0.3c0.1,0.1,0.2,0.3,0.4,0.4
        c-0.2-0.1-0.5-0.1-0.7-0.1c-0.1,0.2-0.3,0.2-0.5,0.1l0,0.2h-0.1c0-0.1,0.1-0.3,0.1-0.4c-0.2,0.1-0.4,0.4-0.6,0.3c0,0.1,0,0.2,0,0.3
        c-0.2,0-0.4,0.1-0.5,0.3c0.1,0,0.4,0,0.5,0c-0.2,0.2-0.5,0.4-0.8,0.6c0.1,0,0.3,0,0.4,0c0,0.1-0.1,0.3-0.2,0.3
        c0,0.1-0.1,0.3-0.1,0.3c0.1,0,0.2,0.1,0.2,0.1c-0.2,0.2-0.2,0.4-0.1,0.6c0.3,0,0.4,0.3,0.5,0.5c0.2,0,0.4,0.1,0.6,0.1
        c0.4,0.4,0.9-0.1,1.3-0.1c0.4,0.1,0.6-0.2,0.9-0.5c0,0,0.1,0.1,0.1,0.1c0.8-0.2,1.3-0.9,2.1-1c0-0.2,0.2-0.5,0.5-0.4
        c0.3,0.1,0.5-0.3,0.7-0.4c0.1,0.1,0.2,0.2,0.4,0.2c0.2-0.1,0.3-0.4,0.4-0.6c0,0.1,0,0.3,0.1,0.4c0.2-0.2,0.4-0.3,0.6-0.5
        c0,0,0,0.1,0,0.2c0.3,0,0.7,0.1,0.9-0.1c0.5,0.2,1.1-0.1,1.6,0.2c0.8,0.3,1.9,0.1,2.5,0.8c-0.3,0-0.7,0-1,0l0-0.2
        c-0.1,0-0.3,0-0.3,0.1c0,0.1,0,0.2,0.1,0.3c-0.2,0-0.4,0-0.6,0c0,0.1,0.1,0.2,0.1,0.3c-0.3-0.1-0.6-0.2-0.9-0.1
        c0.1-0.1,0.2-0.3,0.3-0.4c-0.2,0.1-0.4,0.2-0.6,0.4l0.4-0.1c-0.1,0.4-0.6,0.3-0.8,0.5c0,0.1,0.1,0.2,0.1,0.3
        c-0.2-0.1-0.3-0.2-0.4-0.3c0,0.1-0.1,0.3-0.1,0.4c-0.2-0.2-0.4-0.3-0.7-0.4c0.2,0.1,0.4,0.2,0.6,0.4c-0.1,0-0.3,0.1-0.3,0.2
        c-0.3,0-1,0.1-0.7,0.6c-0.2,0-0.4,0-0.6,0c0,0.1-0.1,0.2-0.1,0.3c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0.2-0.2,0.4-0.3,0.7
        c-0.1-0.1-0.3-0.1-0.4-0.2c0,0.1-0.1,0.3-0.1,0.4c-0.4-0.2-0.5,0.3-0.7,0.6c-0.2,0-0.4,0.1-0.6,0.1c-0.1,0.1-0.2,0.3-0.2,0.4
        c0,0.1,0.1,0.2,0.1,0.3c-0.1,0-0.3,0-0.4,0.1c-0.1,0.2-0.3,0.4-0.2,0.7c-0.1,0.2-0.3,0.3-0.4,0.5c-0.3,0.3-0.6,0.7-0.6,1.1
        c-0.1,0-0.2,0-0.3-0.1c0,0.5-0.4,1-0.5,1.5c-0.1,0-0.2,0.1-0.2,0.2c0.1,0.2,0.2,0.4-0.1,0.5c-0.3,0.6-0.4,1.3-0.5,2
        c0,0.1-0.1,0.2-0.1,0.3c0.1,0.1,0.2,0.3,0.2,0.4c0,0.1-0.1,0.3-0.2,0.4c0.2,0.1,0.3,0.3,0.2,0.5c0,0.2,0.3,0.2,0.3,0.4
        c-0.1,0-0.3,0-0.4,0c0.1,0.3,0.2,0.7,0.3,1c-0.1,0.2-0.1,0.4,0.1,0.6c0.2,0.4-0.1,0.8-0.4,1.1c0.1-0.1,0.3-0.2,0.4-0.3
        c0.2,0.2,0.1,0.4,0,0.7c0,0.3-0.2,0.7,0.2,0.8c-0.1,0-0.2,0.1-0.2,0.2c0.1,0.2,0.2,0.5,0.2,0.7c0.1-0.2,0.2-0.4,0.3-0.6l-0.2,0.1
        c0-0.1,0.1-0.2,0.2-0.4c0.2,0,0.6,0.2,0.5-0.2c0.3,0.1,0.9-0.5,1,0.1c0.3-0.3,0.4-0.6,0.7-0.8l0,0.3c0.2,0,0.3,0,0.5,0l-0.2-0.1
        c0.2-0.2,0.4-0.4,0.4-0.6c0.1,0.1,0.2,0.2,0.3,0.3c0,0.1-0.1,0.2-0.2,0.3c0.2-0.1,0.3-0.2,0.5-0.3c-0.1-0.1-0.3-0.2-0.4-0.3
        c0.1,0,0.4-0.1,0.5-0.1c0,0.1,0.1,0.2,0.2,0.3c-0.1-0.3,0-0.5,0.4-0.6c0,0.1-0.1,0.4-0.1,0.5c0.2-0.1,0.4-0.3,0.5-0.4
        c0.2,0,0.5,0.2,0.6-0.1c-0.2,0-0.4-0.1-0.5-0.3c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2-0.1-0.4-0.1-0.6-0.2c0.2-0.3,0.7-0.1,1-0.2
        c0,0.1,0,0.2,0,0.2c0.3-0.1,0.6,0,0.9,0c0.1-0.2,0.2-0.4,0.5-0.4c0.2-0.1,0.4-0.1,0.6-0.1c-0.1-0.3-0.6-0.3-0.8-0.1
        c-0.1-0.1-0.2-0.2-0.1-0.3c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0.2,0.4,0.3,0.6,0.4c0.1-0.1,0.2-0.2,0.3-0.3c0-0.1,0-0.3,0-0.4
        c0.2,0,0.4,0.1,0.5,0.1c-0.2,0.1-0.4,0.1-0.6,0.3c0.2,0,0.4,0,0.6,0c0-0.1,0-0.3,0.1-0.4c0.1,0,0.2,0.1,0.3,0.1
        c0.2-0.1,0.5-0.2,0.4-0.5c0.2,0.1,0.3,0.3,0.5,0.4c0.1-0.2,0.2-0.5,0.3-0.7c0.1,0.1,0.2,0.1,0.4,0.2l-0.1-0.1
        c0.1,0.1,0.2,0.2,0.3,0.3c0-0.2,0-0.4,0.2-0.6c0,0.1-0.1,0.4-0.1,0.6c0.2-0.2,0.6-0.3,0.7-0.6h-0.4c0.2-0.1,0.4-0.1,0.6-0.1
        c-0.1,0.1-0.1,0.3-0.2,0.4c0.9-0.3,1.9-0.4,2.9-0.7l0,0.1c0.1-0.1,0.3-0.1,0.5-0.1c0.1-0.1,0.3-0.1,0.4-0.1c0.4,0.2,0.9-0.3,1.2,0
        c0.2-0.1,0.5-0.2,0.7-0.3c0.2,0.3,0.5,0,0.7-0.1l0,0.3c0.1-0.1,0.4-0.2,0.5-0.2c0.2,0,0.3,0.1,0.5,0.1c0.3,0,0.5-0.2,0.8,0
        c0.2-0.3,0.6,0.2,0.7-0.2l-0.1,0.2c0.8,0,1.7-0.1,2.5,0.2c0.3-0.1,0.6-0.1,0.9-0.1c0,0.1,0,0.2,0,0.3c0.2-0.2,0.6-0.3,0.8,0
        c0.3,0,0.6,0,0.9,0l-0.1,0.2c0.2,0,0.4,0,0.6,0l-0.1,0.2c0.3-0.1,0.7,0,1,0c0.5,0.2,1,0.2,1.5,0.3c0.1,0.1,0.2,0.1,0.4,0.2
        c0.2,0,0.4,0,0.5,0.1c-0.8,0-1.5-0.4-2.3-0.3l0-0.1c-0.5,0-1-0.1-1.5,0l0-0.2c-0.3,0-0.7,0-1,0.1c-0.2-0.4-0.7,0-1,0l0,0.2
        c0,0-0.1,0-0.1,0c0-0.1,0-0.3,0-0.3c-0.2,0.1-0.5,0.2-0.6,0.2c0-0.1,0-0.2,0-0.3c-0.1,0.1-0.2,0.2-0.3,0.3c0-0.1,0-0.3,0-0.4
        c-0.1,0.1-0.3,0.3-0.4,0.4c0-0.1,0-0.3,0-0.4c-0.5,0.3-1.1,0.2-1.6,0.2l0,0.2c-0.2-0.1-0.3-0.1-0.5-0.2c-0.3,0.2-0.6,0.5-1.1,0.4
        c-0.2,0-0.4,0-0.5,0.2c-0.1-0.3-0.4,0.1-0.7,0l0.1,0.2c-0.1,0-0.4-0.1-0.6-0.1l0,0.2c-0.2,0-0.3,0-0.5,0c-0.1,0-0.4,0-0.5,0l0,0.2
        c-0.7-0.1-1.3,0.3-2,0.3c-0.5,0.2-1.1,0.3-1.6,0.6c-0.3,0.2-0.8,0.2-1,0.5c-0.1,0-0.2,0-0.3,0c-0.7,0.5-1.4,1-2.1,1.3
        c-0.1,0.2-0.3,0.3-0.5,0.3c0,0.1-0.1,0.2-0.2,0.2c-0.3,0.1-0.5,0.4-0.9,0.4c-0.1,0.2-0.2,0.3-0.4,0.4c-0.5,0.1-0.8,0.6-1.2,0.9
        c-0.3,0.1-0.5,0.4-0.7,0.5c-0.6,0.3-1.1,0.8-1.7,1.2c-0.1,0-0.2-0.1-0.3-0.1c0,0.1,0,0.3,0,0.4c-0.2-0.1-0.3-0.2-0.4,0
        c-0.2,0.5-0.7,0.8-1.1,1.1c-0.5,0.2-0.8,0.8-1.3,0.9c0,0.1-0.1,0.3-0.2,0.4c-0.1,0-0.2,0-0.3,0c-0.3,0.2-0.1,0.6-0.4,0.9
        c0.2,0.2,0.2,0.6,0.3,0.9c0.1,0.3,0,0.6,0.2,0.9c0,0.7,0.1,1.4,0.6,1.9c0.1,0.4,0.2,0.7,0.3,1.1c0.3,0.6,0.8,1,1.1,1.6
        c0.3,0.3,0.5,0.8,0.9,1c0.6,0.3,1.2,0.9,1.3,1.7c0.1,0.1,0.2,0.2,0.3,0.2c0,0.4,0.4,0.6,0.6,0.8c0.5,0.5,0.9,1.1,1.3,1.7
        c0.2,0.3,0.5,0.5,0.8,0.8c0.3,0.7,1,1,1.3,1.7c-0.2-0.1-0.4-0.1-0.4-0.4c-0.4-0.1-0.6-0.4-1-0.6l0-0.1c-0.1,0-0.2,0-0.3,0l0-0.2
        c-0.1,0-0.3,0.1-0.4,0.1l0.1-0.3l-0.4,0.1c0-0.1,0.1-0.4,0.1-0.5c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2-0.4-0.5,0.1-0.7-0.1
        c-0.2-0.2-0.4,0.1-0.6,0.1c0,0-0.1-0.1-0.2-0.2c-0.1,0-0.4,0-0.5,0c0.1-0.2,0.1-0.4-0.1-0.5c-0.1,0.2-0.2,0.3-0.2,0.5
        c0.1,0.1,0.2,0.2,0.3,0.3c-0.3,0-0.4-0.2-0.4-0.4c-0.1,0-0.3,0.1-0.5,0.1c0,0.1,0.1,0.2,0.1,0.3c-0.1,0-0.2-0.1-0.4-0.1
        c-0.1,0.1-0.3,0.3-0.4,0.4c0-0.2,0.1-0.5,0.2-0.7c-0.3,0.1-0.3,0.3-0.4,0.6c-0.2,0-0.2,0.2-0.2,0.3c-0.2,0-0.3,0.2-0.4,0.4l0.5-0.2
        c-0.2,0.2-0.3,0.4-0.5,0.5c0.2,0.2,0.2,0.4-0.2,0.4c0.1,0.3,0.1,0.7-0.1,1c0,0.2,0.1,0.3,0.1,0.5c0.2,0.1,0.3,0.2,0.5,0.3
        c-0.1,0.1-0.2,0.3-0.2,0.4c0.1,0,0.3,0,0.4,0c0,0.2,0.2,0.2,0.4,0.2l0-0.4c0.2,0.3,0.5,0.6,0.8,0.8c0,0.1,0.1,0.2,0.1,0.4
        c0.1-0.1,0.2-0.3,0.2-0.3c0.2,0.2,0.4,0.4,0.6,0.6c0.2-0.1,0.6-0.2,0.5,0.3c0.5,0.1,1,0,1.4,0.3c0.8-0.1,1.5,0.5,2.3,0.3
        c0.5-0.1,1-0.1,1.6,0.1c0.2-0.2,0.2-0.5,0.4-0.8c0.2-0.3,0.5-0.5,0.6-0.8c0.2-0.3,0.4-0.6,0.6-0.9c0.2-0.1,0.3-0.3,0.3-0.5
        c0,0,0.1,0,0.1,0c0.1-0.3,0.4-0.6,0.5-0.9c0.1,0.1,0.2,0.2,0.2,0.2c-0.2,0.7-1,1.1-1.3,1.8l-0.1,0c0,0.2-0.1,0.3-0.3,0.4
        c-0.1,0.4-0.5,0.7-0.8,0.9c0,0.2-0.1,0.4-0.1,0.6c0.3-0.1,0.6,0.1,0.9,0.1c0.1-0.2,0.3-0.3,0.4-0.5c0.1,0,0.2,0.1,0.2,0.1
        c-0.2,0.1-0.4,0.2-0.6,0.4c0.8,0.1,1.6-0.3,2.4-0.2c0.4-0.2,0.8,0,1.2-0.2c0.3-0.1,0.4,0.3,0.7,0.2c-0.2-0.3,0.1-0.5,0.2-0.7
        c-0.1,0.2-0.1,0.4-0.2,0.6c0.3-0.2,0.6,0,0.9,0c0.2-0.2,0.7-0.2,0.6-0.6c0.2,0.1,0.3,0.2,0.5,0.4c0.1-0.2,0.5-0.3,0.4-0.6l0.2,0.1
        c0-0.1,0-0.2,0-0.3c0,0.2,0.1,0.3,0.2,0.5l-0.2-0.2c-0.1,0.2-0.2,0.3-0.3,0.5c0.6-0.2,1.4,0.1,1.9-0.4c0,0.1-0.1,0.3-0.1,0.3
        c0.4-0.1,0.9,0.1,1.3-0.2c0,0.1,0,0.2,0,0.2c0.3,0,0.5,0.2,0.8,0c0.3-0.2,0.5,0,0.8,0.1c0.1-0.1,0.2-0.3,0.3-0.4
        c0,0.1,0.1,0.2,0.2,0.3c0.1-0.3,0.3-0.5,0.4-0.7c0,0.2,0.1,0.3,0.3,0.3c-0.1,0-0.4,0.3-0.2,0.4c0.4,0.1,1-0.1,1.2,0.3
        c0.3-0.1,0.5-0.1,0.8-0.2c0.4,0.3,1,0.2,1.4,0.2c0.6,0,1.3,0.3,1.9,0.2c0-0.1-0.1-0.2-0.1-0.2c0.1,0,0.2,0,0.3,0
        c0,0,0.1,0.1,0.1,0.2c0.1,0,0.2,0,0.4,0c0.1,0.4,0.5,0.4,0.8,0.5l0-0.4c-0.1,0-0.3,0.1-0.4,0.1c0-0.1,0.1-0.2,0.2-0.3
        c0.2,0.2,0.5,0.3,0.7,0.5c0-0.1-0.1-0.3-0.1-0.4c0.1,0,0.2-0.1,0.3-0.1c0.2,0.1,0.3,0.1,0.5,0c0.1,0,0.2,0.1,0.3,0.1
        c-0.1,0-0.3,0.1-0.5,0.1c-0.2,0-0.7-0.3-0.6,0.2c0.2,0.2,0.4,0.3,0.6,0.4c0.2-0.1,0.3-0.3,0.5-0.4c0.3,0.2,0.6,0.4,0.9,0.5
        c0,0,0.1-0.1,0.2-0.2c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0,0.1,0,0.3,0,0.4c0.1,0,0.4-0.1,0.5-0.1l-0.1,0.2
        c0.7,0,1.5,0.2,2.2,0.5c0-0.1-0.1-0.3-0.1-0.4c0,0,0.1,0,0.1,0c0,0.1,0,0.2,0,0.3c0.3,0,0.7,0.5,1,0.2c0-0.1,0-0.3-0.1-0.4l-0.1,0.2
        l0-0.1c0.1-0.2,0.2-0.3,0.2-0.5c0,0.1,0.1,0.3,0.1,0.4c0.1-0.1,0.3-0.2,0.4-0.3c-0.1,0.1-0.2,0.3-0.3,0.4c0.2,0.2,0.7,0.2,1,0.3
        c0.3-0.3,0.5,0.2,0.7,0.3c0.3-0.2,0.8,0.2,1-0.2c0,0.2,0.1,0.5,0.4,0.4c0-0.1,0.1-0.3,0.1-0.4c0,0.1,0.1,0.2,0.2,0.3
        c0.1,0,0.2,0,0.2,0c0.1-0.1,0.1-0.1,0.2,0c-0.2,0.1-0.3,0.2-0.3,0.4c0.4-0.1,0.7,0,1,0.2c0.3,0,0.6-0.1,0.8,0
        c0.4,0.1,0.7,0.5,1.1,0.3c0.2,0.1,0.4,0.1,0.6,0.2c0,0,0.1-0.1,0.2-0.1c0.4,0.3,1,0.2,1.4,0.7c0.1,0,0.4,0.1,0.5,0.1
        c-0.1-0.1-0.2-0.3-0.3-0.3c0.2,0,0.4-0.4,0.6-0.2c0.1,0,0.2-0.1,0.3-0.1c-0.2,0.3-0.5,0.3-0.8,0.2c-0.1,0.3,0.5,0.9,0.8,0.5
        c0.1,0,0.3,0.1,0.4,0.1c0.1,0.1,0.3,0.2,0.4,0.3c0-0.2-0.1-0.3,0-0.5c0,0.3,0.2,0.7,0.5,0.4c0.1,0.1,0.3,0.2,0.4,0.3
        c0-0.1,0-0.3,0-0.3c0.2,0.1,0.3,0.4,0.6,0.4c0.3,0,0.6,0.1,0.9,0.2c0.2,0.1,0.5-0.1,0.7-0.1c0.1-0.1,0.1-0.2,0.2-0.2
        c0.1,0.1,0.1,0.3,0.2,0.4c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0.1-0.3,0.2-0.4,0.4c0.6,0,1.1,0.1,1.6,0.4c0.2,0,0.5,0,0.6,0.3
        c0.1,0,0.2-0.1,0.3-0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.6,0,1.1,0.5,1.7,0.5c0.1-0.1,0.1-0.3,0.3-0.4c0.1,0.2-0.1,0.3-0.2,0.5
        c0.5,0,1,0.3,1.4,0.4c-0.1-0.3,0.2-0.4,0.4-0.2c-0.1,0.1-0.2,0.1-0.3,0.2c0.2,0,0.5,0.4,0.7,0.3c0.1-0.1,0.2-0.2,0.2-0.3
        c0,0.1-0.1,0.3-0.1,0.3c0,0,0,0.1,0.1,0.2c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0.1,0.2,0.2,0.4,0.4c0.4-0.1,0.8,0.2,1.2,0.4l0-0.2
        c0,0.2,0.2,0.3,0.4,0.4c0.1-0.2,0.4-0.3,0.6-0.3c-0.1,0.2-0.4,0.2-0.5,0.4c0.5,0,0.8,0.4,1.2,0.5c0.1-0.1,0.2-0.2,0.2-0.3l0.2,0
        c0,0.1,0,0.3,0,0.4c0.1-0.1,0.3-0.2,0.5-0.3c-0.1,0.1-0.2,0.3-0.3,0.4c0.5-0.2,1.1,0,1.5,0.4c0.4-0.1,0.8,0.3,1.2,0.2
        c-0.2-0.2-0.4-0.2-0.6-0.3c0.3,0,0.6-0.1,0.7,0.3c0.1,0,0.2-0.1,0.4-0.1c0.1,0.1,0.2,0.3,0.2,0.3c0.1-0.1,0.3-0.2,0.5-0.3
        c-0.2,0.2-0.3,0.3-0.5,0.4c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1,0.1-0.1,0.2-0.2,0.4c0.6,0.1,1.1,0.2,1.7,0.4c0-0.1,0-0.3,0-0.3
        c0.1,0,0.2,0.1,0.2,0.2c-0.1,0.1-0.2,0.2-0.2,0.2c0.4,0.2,0.9,0.2,1.3,0.4l-0.1-0.3c0,0,0.1,0,0.1,0c0,0.1,0.1,0.3,0.1,0.4l0.2-0.1
        c-0.2-0.3,0.2-0.1,0.4-0.3c0.1,0,0.2,0,0.2,0c-0.2,0.2-0.4,0.3-0.6,0.5c0.1,0,0.3,0.1,0.4,0.2c0,0,0.1-0.1,0.2-0.2l0-0.2
        c0.1,0.1,0.2,0.1,0.2,0.2c-0.1,0-0.2,0.1-0.3,0.1l-0.1,0.3l0.3,0c0.1,0,0.2-0.1,0.3-0.2c0.2,0.1,0.4,0.1,0.5,0.3
        c0.2,0.2,0.3,0.6,0.6,0.5c0.7-0.2,1.3,0.2,2,0.4c0.3-0.1,0.2-0.4,0-0.4c0.1-0.1,0.2-0.2,0.3-0.3c-0.4,0.3,0.2,0.4,0.4,0.5
        c0,0.1,0,0.3,0,0.4c0.2,0.1,0.3,0.3,0.5,0.4c0-0.1-0.1-0.3-0.1-0.4c0.1,0.1,0.3,0.2,0.4,0.3l0.4,0c-0.1-0.2-0.2-0.4-0.3-0.6
        c0.1,0,0.2,0.1,0.3,0.1c0,0.1,0,0.3,0.1,0.4c0.1,0,0.4,0,0.5,0.1l0-0.2c0.2,0.1,0.3,0.1,0.5,0.2c-0.2,0-0.4,0-0.6,0.1
        c0.2,0.2,0.4,0.3,0.7,0.2c0.3,0.1,0.5,0.2,0.8,0.2c-0.1,0.4,0.3,0.2,0.5,0.2c0.2,0.1,0.3,0.1,0.5,0.1c0.2,0.1,0.4,0.2,0.6,0.2
        c0.3,0.3,0.8,0.7,1.1,0.3l0.3,0.1c0-0.1,0-0.3-0.1-0.5c0.2,0.1,0.1,0.3,0.1,0.5c0.5,0.1,1.1,0.1,1.6,0.5c0.8-0.1,1.5,0.3,2.3,0.3
        c0.3,0.7,1.1,0.3,1.7,0.4c0.2,0,0.5,0.4,0.6,0.1c0-0.3,0.3-0.4,0.5-0.4c0.1,0,0.2-0.1,0.3-0.1l0,0c-0.2,0.1-0.6,0.1-0.6,0.5
        c0.2,0.3,0.7,0.2,1.1,0.3c0-0.1,0.1-0.2,0.1-0.3l0.1-0.1c0,0.1,0,0.3,0,0.3c0.3,0,0.5,0.1,0.8,0.2c0-0.1,0-0.2,0-0.3
        c0,0,0.1,0,0.1,0c0,0.1-0.1,0.3-0.1,0.4c0.2,0,0.5,0,0.7,0c0.2,0,0.5-0.1,0.5,0.2c0.3-0.3,0.5,0,0.8,0.3c0.4,0,0.1-0.3,0-0.4
        c0.1,0.1,0.3,0.2,0.3,0.3c0-0.1,0-0.4,0-0.5c0.1,0,0.3-0.1,0.4-0.1c-0.1,0.1-0.2,0.2-0.2,0.2l-0.1,0.1c0.3,0.2,0.7,0.2,1,0.2
        c0.1,0,0.2,0.1,0.2,0.2c0.5-0.2,0.9,0,1.4,0c0-0.1,0-0.3,0-0.4c0.1,0.1,0.1,0.2,0.1,0.4c0.3,0,0.7,0.1,0.9-0.2l-0.3-0.1
        c0.3-0.1,0.3,0.1,0.3,0.4c0.2,0,0.4,0,0.7,0c0,0,0.1,0.1,0.2,0.2c0.5-0.3,1.1-0.2,1.5,0c-0.1-0.2-0.2-0.4,0-0.6
        c0.1,0.3,0.1,0.6,0.5,0.8c0.2-0.1,0.3-0.3,0.4-0.4c0.7,0.3,1.4-0.1,2.1,0.2c0.1-0.1,0.2-0.2,0.3-0.2c0.3,0,0.7,0,1,0
        c0.2,0,0.4,0.3,0.6,0c-0.1-0.1-0.2-0.2-0.3-0.2c0.6,0.1,1.3,0.1,1.9,0l0.1-0.3c-0.1,0-0.3,0-0.4,0c0,0,0.1-0.1,0.2-0.2
        c0.2,0.1,0.4,0.2,0.2,0.5c0.3-0.1,0.7-0.2,1-0.1c0.3-0.2,0.7-0.3,1-0.4c0-0.1,0-0.2,0-0.3c0.2,0,0.3-0.1,0.3-0.4
        c-0.3-0.1-0.6-0.3-0.9-0.2c0,0.1,0,0.3,0,0.3l-0.1,0l0-0.2c-0.8,0.3-1.5-0.4-2.3,0c-0.4-0.1-0.9-0.4-1.3-0.2c0-0.1-0.1-0.3-0.1-0.4
        c-0.1,0.1-0.2,0.3-0.2,0.3c-0.2,0-0.5,0-0.7-0.1c0.5-0.2-0.2-0.5-0.4-0.7c0.1,0.2,0.2,0.3,0.3,0.5c-0.3,0.4-0.4-0.2-0.6-0.4
        c0,0.5-0.5,0.3-0.8,0.5c0.1-0.2,0.2-0.3,0.3-0.5l0.1,0l-0.1,0.3c0.1,0,0.3-0.1,0.4-0.2c-0.1-0.1-0.2-0.2-0.3-0.3
        c-0.2,0.1-0.5,0.2-0.7,0.2l0.1-0.2c-0.1,0-0.3,0.1-0.4,0.1c-0.2-0.3-0.6-0.4-0.9-0.5c0,0.1,0,0.4,0,0.5c0,0-0.1,0-0.2,0
        c0-0.2,0-0.3-0.1-0.5c-0.2,0-0.4-0.1-0.5-0.2c0.1,0,0.3-0.1,0.4-0.1c-0.5-0.3-0.9,0.1-1.3,0.2c0-0.3,0.3-0.2,0.5-0.3
        c-0.2-0.3-0.5-0.6-0.9-0.6c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2,0-0.5-0.1-0.7-0.1c-0.1-0.1-0.1-0.2-0.2-0.3c0.1,0.3,0.2,0.5,0.4,0.7
        c0.2,0,0.3,0,0.5,0c-0.1,0.1-0.2,0.3-0.3,0.4c0,0,0-0.1-0.1-0.2c-0.2-0.1-0.4-0.1-0.5-0.2c-0.2,0-0.7,0.1-0.4-0.2c0.1,0,0.3,0,0.5,0
        c-0.1-0.2-0.3-0.4-0.5-0.5l0.1,0.4c-0.2-0.1-0.3-0.2-0.2-0.4c-0.1,0-0.4,0-0.5,0c0-0.2,0-0.3,0-0.5c-0.2-0.1-0.2-0.4-0.4-0.4
        c0,0.2,0.1,0.4,0.2,0.6c0,0.1-0.1,0.2-0.1,0.4c-0.1-0.3-0.1-0.7-0.5-0.7c0,0.2,0.2,0.5,0,0.6c0-0.3-0.1-0.5-0.3-0.6
        c0.1-0.1,0.2-0.2,0.2-0.2c-0.2-0.1-0.4-0.2-0.6-0.1c0-0.1,0-0.2-0.1-0.3c0,0.1-0.1,0.3-0.2,0.4c-0.1-0.2-0.2-0.4-0.3-0.7
        c-0.1,0-0.4,0.1-0.5,0.1c0-0.1,0.1-0.3,0.1-0.4c-0.3-0.2-0.3,0.2-0.3,0.4c-0.2,0-0.4-0.1-0.6-0.1c0-0.1,0.1-0.2,0.1-0.3
        c0.1,0,0.4,0.1,0.6,0.2c-0.1-0.4-0.5-0.3-0.8-0.3c0.1-0.1,0.2-0.3,0.3-0.4c-0.3-0.1-0.7,0-1-0.3c0-0.1,0-0.3,0.1-0.4l-0.1,0l0,0.3
        c-0.3-0.3-0.7-0.3-1-0.6c-0.1,0-0.3-0.1-0.4-0.1c0-0.1,0.1-0.3,0.1-0.3c-0.4,0.2-0.8-0.2-0.8-0.6c-0.2,0-0.3,0-0.5,0.1
        c-0.2-0.3-0.5-0.7-0.9-0.7c0,0,0-0.1-0.1-0.2c-0.1,0-0.2,0-0.3,0c-0.1,0.1-0.2,0.2-0.3,0.3c0-0.3,0-0.5,0.4-0.4l0-0.3
        c-0.3,0.1-0.5,0.1-0.7-0.1c0,0.1,0,0.4,0,0.6h-0.2l0-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c0.2-0.1,0.3-0.2,0.5-0.3c0,0,0.1-0.1,0.1-0.2
        c-0.2,0-0.3,0.1-0.5,0.1c-0.1-0.1-0.1-0.3-0.2-0.4c0,0.1-0.1,0.3-0.1,0.4c-0.3,0.2-0.5-0.2-0.5-0.4c-0.1,0-0.2,0-0.3,0
        c0-0.1,0-0.3,0-0.4c-0.2,0.1-0.3,0.1-0.5,0.2c0.1-0.1,0.1-0.3,0.2-0.4c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.2-0.2-0.3-0.3-0.4
        c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.3,0.1-0.4c0.3,0.1,0.4,0,0.4-0.3c-0.1,0-0.3,0.1-0.4,0.1c-0.2-0.2-0.6-0.2-0.8,0
        c0.1-0.1,0.2-0.2,0.3-0.2c0-0.1,0.1-0.3,0.1-0.4c-0.6-0.5-1.1-1-1.8-1.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2,0.1-0.3,0.2-0.5,0.2
        c0,0.1,0.1,0.2,0.1,0.3c0,0-0.1,0-0.1,0l0.1-0.2c-0.6,0.3-1.1-0.3-1.4-0.7c-0.1,0-0.3,0.1-0.4,0.1c0.1-0.1,0.2-0.3,0.2-0.4
        c-0.1,0-0.3,0.1-0.4,0.2c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1,0-0.3,0-0.4,0c-0.1,0.1,0.1,0.6-0.1,0.4c-0.1-0.2-0.5-0.7-0.6-0.2
        c-0.1,0-0.4-0.1-0.6-0.1c0-0.1,0-0.2,0-0.2c-0.3-0.1-0.6-0.1-0.8-0.1c-0.2-0.1-0.3-0.4-0.6-0.2c-0.2,0.1-0.3,0-0.4,0
        c0.2-0.1,0.7,0.1,0.7-0.3c-0.3-0.1-0.5,0-0.8,0.1c0-0.1,0.1-0.3,0.2-0.4c-0.2-0.1-0.4-0.1-0.6-0.1c0.1,0.2,0.1,0.3,0.2,0.5
        c-0.3-0.2-0.3-0.7-0.1-1c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.3-0.3-0.7-0.7-0.7c0.2-0.2,0.4-0.5,0.5-0.7c-0.1,0-0.2,0.2-0.2,0.2
        c-0.1-0.3-0.4-0.4-0.6-0.4c0,0.4-0.2,0.2-0.4,0c-0.2-0.2-0.5-0.4-0.4-0.7c-0.1,0.1-0.2,0.2-0.3,0.3c-0.4,0-0.9-0.2-1.3-0.2
        c-0.4,0-0.3-0.4-0.4-0.6c-0.1,0.1-0.5,0.2-0.3,0.4c-0.1,0-0.2,0.1-0.2,0.1c0.2,0.2,0.4,0.3,0.5,0.5c-0.1,0-0.2,0.1-0.3,0.2
        c0-0.1,0-0.3,0-0.3c-0.1,0-0.2,0-0.3,0.1l0.2-0.1c-0.2-0.3-0.5-0.4-0.6-0.8c0,0-0.1,0.1-0.2,0.1c-0.2-0.1-0.5-0.1-0.7-0.1
        c0.1-0.1,0.2-0.2,0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0-0.5,0-0.7,0c-0.2-0.2-0.5-0.3-0.5-0.6
        c0.2,0,0.4,0.2,0.5,0.4c0.3,0.1,1,0.4,0.9-0.2c-0.3,0-0.6-0.1-0.9-0.1c0.1-0.1,0.2-0.2,0.2-0.2c-0.1-0.2-0.3-0.2-0.5-0.3
        c0,0-0.1,0.1-0.1,0.2c-0.2,0.1-0.5,0.2-0.7,0.2c-0.3-0.2-0.6-0.5-0.2-0.7c0.3,0.1,0.1,0.7,0.5,0.6c0-0.1,0-0.3,0-0.4
        c-0.2-0.1-0.3-0.3-0.3-0.5c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2-0.1-0.4-0.2-0.5-0.4c-0.2,0.1-0.5,0.2-0.6-0.1c0.1-0.1,0.2-0.3,0.2-0.4
        c-0.2,0.2-0.3,0.4-0.6,0.4c0-0.1,0.1-0.4,0.1-0.5c-0.2,0-0.4,0-0.6,0.1c0-0.2-0.1-0.3-0.2-0.4l-0.1,0c-0.1,0-0.3-0.1-0.4-0.1
        c0,0.1,0,0.3,0,0.4c-0.3-0.1-0.7-0.2-1.1-0.4c0.1-0.1,0.3-0.2,0.4-0.2l0,0.3c0.2-0.1,0.5-0.1,0.7-0.2c0-0.1,0-0.2,0-0.3
        c-0.1,0.1-0.2,0.2-0.4,0.3c-0.3-0.4-0.7,0-1-0.1l0.1,0.3l-0.1,0c0-0.1,0.1-0.3,0.1-0.3c0.2,0,0.3-0.1,0.5-0.1
        c-0.1-0.2-0.4-0.2-0.6-0.3c-0.1,0.1-0.4,0.2-0.5,0.3c0.1-0.3,0.4-0.4,0.5-0.7c-0.3,0.2-0.6,0.4-0.8,0.7c-0.1-0.1-0.2-0.2-0.2-0.3
        c0.2,0,0.3,0,0.5,0c-0.3-0.4-0.7-0.4-1.1-0.5c-0.2-0.3-0.5-0.4-0.7-0.5c0,0.3,0.1,0.5,0.2,0.7l-0.2,0l0.1-0.2c0,0-0.1-0.1-0.2-0.1
        c0-0.1,0.1-0.2,0.1-0.3c-0.4,0.1-0.4-0.5-0.9-0.4c0.1,0.2,0.1,0.5,0.4,0.6l0,0.2c-0.2-0.1-0.4-0.3-0.6-0.4c-0.1,0-0.3,0-0.4,0
        c0-0.1,0-0.2,0-0.3c-0.1,0.1-0.2,0.2-0.3,0.3c0-0.1,0.1-0.4,0.1-0.5c-0.1,0-0.3,0.1-0.4,0.1c-0.1-0.1-0.3-0.4-0.3-0.5
        c0,0.2,0,0.4,0.1,0.5c-0.1,0-0.2,0-0.3,0l0.1,0c0-0.1,0-0.2,0-0.3c-0.3,0.2-0.4-0.1-0.6-0.2c-0.3,0.2-0.5,0.2-0.5-0.2l-0.2,0
        c0,0.1,0.1,0.3,0.1,0.4c-0.1,0.1-0.3,0.1-0.4,0.2c0.1-0.2,0.2-0.3,0.3-0.5c0-0.1-0.1-0.3-0.2-0.4c-0.3,0-0.5,0-0.7-0.2
        c-0.1-0.2-0.4-0.1-0.4,0.1c0,0.1,0.2,0.2,0.2,0.3c0.2,0,0.5-0.3,0.4,0.1c0.2-0.1,0.5-0.1,0.6,0.1c-0.1,0-0.2,0.1-0.3,0.1
        c-0.1-0.1-0.3-0.2-0.4-0.4c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1-0.2-0.3-0.4-0.4-0.6c0-0.1,0-0.2,0.1-0.3c-0.3,0.1-0.5,0.2-0.7,0.4
        c0-0.3,0-0.7-0.4-0.7c0.1,0.1,0.2,0.2,0.3,0.3c-0.2,0.1-0.3,0.1-0.5,0.1c0-0.1,0-0.3,0-0.4c0,0.1-0.1,0.2-0.2,0.2
        c-0.1,0-0.2,0-0.3,0c0,0.3,0.1,0.6,0,0.9c-0.2,0.7-0.3,1.5-0.7,2.2c-0.1-0.9,0.4-1.7,0.1-2.5c0.1,0,0.3-0.1,0.4-0.1l-0.5,0
        c0-0.1,0.1-0.2,0.2-0.2c-0.3,0-0.2-0.3-0.2-0.5c-0.1,0-0.3-0.1-0.4-0.1l0,0.2c-0.1-0.1-0.2-0.1-0.3-0.1c0.2-0.1,0.4-0.1,0.5-0.2
        c-0.3-0.1-0.5-0.1-0.8-0.2c-0.2,0-0.6,0.3-0.6-0.1c0.1-0.1,0.2-0.2,0.3-0.2c-0.3,0.1-0.5,0.3-0.7,0.4l0-0.2
        c-0.5,0.1-0.9-0.3-1.4-0.4c0,0.1,0.1,0.3,0.1,0.4c-0.2-0.2-0.5-0.3-0.7-0.5c-0.1,0-0.3,0.1-0.4,0.1c-0.1-0.2-0.1-0.3-0.2-0.5
        c0,0.1,0,0.3,0,0.4c-0.2-0.1-0.4-0.1-0.6-0.2c-0.2-0.3-0.6-0.3-0.8-0.1c-0.1,0-0.3-0.1-0.4-0.2l0-0.2c-0.2,0-0.3,0.1-0.4,0.2
        c-0.1-0.1-0.2-0.2-0.2-0.2c0,0.1-0.1,0.2-0.2,0.2c-0.2-0.1-0.4-0.5-0.7-0.2c-0.4-0.2-0.8-0.1-1.2-0.2c-0.2-0.1-0.6,0.1-0.6-0.3
        c0,0.1-0.1,0.2-0.1,0.3c-1.4-0.2-2.9-0.2-4.3-0.3c-0.3,0-0.6-0.2-0.9,0c-0.2,0.2-0.5,0.2-0.8,0.3c0-0.1,0-0.3,0-0.5
        c-0.1,0.1-0.2,0.2-0.3,0.4c-0.3,0-0.5,0.2-0.8,0.1c0.1-0.1,0.2-0.2,0.2-0.3c-0.3,0.2-0.6,0.2-0.8,0.4c0-0.1-0.1-0.2-0.1-0.2
        c-0.2,0.2-0.3,0.1-0.4,0c0,0.1,0,0.2,0,0.3c-0.3-0.3-0.6,0.1-0.9,0.2c0-0.1,0-0.3,0-0.4c-0.1,0.1-0.2,0.2-0.1,0.4
        c-0.3,0-0.7-0.1-1,0.1c-0.2,0.1-0.5,0-0.7,0.3c-0.2-0.1-0.4-0.2-0.6,0.1c-0.1,0-0.3-0.2-0.3,0.1c-0.3-0.1-0.4,0-0.5,0.2
        c-0.2-0.1-0.3,0-0.4,0.1c-0.2,0-0.4-0.1-0.6-0.1l0,0.2c-0.4-0.1-0.7,0.2-1,0.3c-0.4,0.1-0.7,0.2-1,0.4c-0.1,0-0.2-0.1-0.3-0.1l0,0.2
        c-0.5,0.2-0.9,0.4-1.4,0.6c-0.2,0.3-0.4-0.1-0.5-0.2c0,0.2,0,0.4,0,0.6c-0.1-0.1-0.3-0.2-0.4-0.3c0,0.1,0,0.3,0,0.4
        c-0.2,0-0.4,0-0.5,0c-0.2,0.3-0.7,0.8-1.1,0.5c0,0.1,0.1,0.2,0.1,0.2c-0.5,0.2-0.9,0.6-1.4,0.9c-0.1,0-0.3,0.4-0.3,0.1
        c-0.5,0.3-1,0.8-1.6,1c-0.1,0.3-0.5,0.4-0.7,0.7c-0.4,0.3-0.7,0.6-1.2,0.8c-0.1,0.3-0.4,0.7-0.7,0.8c-0.2,0.3-0.5,0.5-0.7,0.7
        c-0.1-0.2-0.1-0.4-0.2-0.6c0.1,0,0.2,0,0.3,0.1c-0.1-0.4,0.6-0.2,0.3-0.6c0.1,0,0.2,0,0.2,0c0-0.1,0-0.2,0-0.3c0.1,0,0.2,0,0.3,0
        c0-0.1,0-0.3,0-0.5l0.2,0c0-0.2,0-0.3,0-0.5l0.1,0c0,0.1-0.1,0.3-0.1,0.4c0.2-0.2,0.5-0.2,0.5-0.5c0.1,0,0.2,0,0.3,0.1l0-0.1
        c0.2-0.1,0.2-0.2,0.1-0.4c0.4,0.1,0.3-0.2,0.3-0.5c0.1,0.1,0.2,0.2,0.3,0.4l0.1,0c0-0.1-0.1-0.3-0.1-0.4c0.3-0.1,0.9-0.2,0.8-0.6
        c0.1,0,0.2-0.1,0.3-0.1c0,0.1,0,0.2,0,0.3c0.1-0.1,0.1-0.3,0.1-0.4l0.1,0.1c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.2,0,0.3,0
        c0.1-0.2,0.2-0.4,0.2-0.5c0.2-0.1,0.5-0.2,0.7-0.3c0.1,0,0.3,0,0.3,0c-0.1-0.1-0.3-0.2-0.3-0.2c0.2-0.1,0.4-0.1,0.7-0.1
        c0-0.1,0-0.2,0.1-0.3c0.2-0.1,0.4,0,0.6-0.2c0.2,0,0.5,0.1,0.6-0.2c-0.1,0-0.3-0.1-0.4-0.1c0.1-0.2,0.4-0.1,0.6-0.1
        c0,0-0.1-0.1-0.1-0.2c0.3,0,0.6,0,0.9,0c0.3-0.2,0.5-0.5,0.9-0.4c-0.1-0.1-0.2-0.2-0.3-0.2c0.2-0.2,0.5-0.3,0.7-0.5
        c-0.1,0.2,0,0.4,0.1,0.7l0,0c-0.1-0.4,0.2-0.7,0.5-0.9c-0.1,0.3-0.2,0.5-0.3,0.8c0.1-0.1,0.2-0.3,0.3-0.5c0.1,0,0.2,0,0.2,0l0-0.1
        l-0.1-0.1l0-0.1c0.2-0.2,0.5-0.3,0.7-0.4c-0.1,0.2-0.2,0.4-0.3,0.6c0.2-0.2,0.4-0.5,0.5-0.7c0,0,0.1,0.1,0.1,0.2
        c0.2,0,0.4,0,0.6-0.2l-0.3,0l0.1-0.2c0.1,0,0.3,0.1,0.3,0.2c0-0.1,0.1-0.3,0.1-0.3c0.1,0.1,0.2,0.2,0.3,0.4c0-0.1-0.1-0.4-0.1-0.5
        c0.1,0.1,0.3,0.3,0.4,0.4c-0.2-0.4,0.1-0.4,0.4-0.4v0c0.2,0,0.3-0.1,0.5-0.1c0,0.1,0.1,0.2,0.1,0.3c0-0.1,0-0.3,0-0.4
        c0.1,0,0.2,0.1,0.3,0.1l0-0.3c0.1,0.1,0.2,0.2,0.2,0.3c0-0.1,0-0.3,0-0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0,0.4,0,0.6-0.2
        c-0.1,0-0.3,0.1-0.4,0.1c-0.1-0.6,0.8-0.2,1.1-0.5c-0.1,0.1-0.3,0.4-0.5,0.3c0,0,0,0.1,0,0.1c0.4,0,0.7-0.1,1.1,0l-0.1-0.2
        c-0.2,0.2-0.8-0.1-0.8,0.1c0.2-0.2,0.5-0.4,0.8-0.5c0.2,0.4,0.5,0,0.7-0.1c0-0.1-0.1-0.2-0.2-0.3c0.1,0.1,0.2,0.2,0.4,0.2l0-0.2
        c0.4,0.1,0.8-0.1,1.2-0.2c0.1,0.1,0.1,0.2,0.2,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c0.3,0,0.4-0.3,0.5-0.5l0.2,0.1c0-0.3,0.3-0.3,0.5-0.2
        c0.1,0,0.2-0.1,0.2-0.2c0.1,0.1,0.2,0.2,0.2,0.3c0.1-0.2,0.4-0.3,0.7-0.3c-0.1,0.1-0.2,0.3-0.3,0.4c0.2-0.1,0.4-0.1,0.7-0.1
        c-0.1-0.1-0.7-0.1-0.3-0.3c0.1,0,0.2-0.1,0.3-0.1c0.6,0.1,1.1-0.1,1.7,0c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0.1,0.2,0.2,0.3,0.3
        c0.1,0,0.2-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.1l0-0.2c0.1,0,0.3,0,0.5,0c0.2,0.1,0.6,0.2,0.6-0.1c0.2,0.2,0.5,0.5,0.4,0
        c0.6,0.1,1.2,0,1.8,0.1l-0.1-0.2c0.1,0,0.3,0.1,0.4,0.1l-0.1,0.2c0.2-0.1,0.4-0.1,0.6,0.1c0-0.2,0-0.4-0.1-0.5
        c0.2,0.2,0.3,0.4,0.5,0.6c0.1-0.2,0.2-0.4,0.4-0.4c0,0.1,0.1,0.2,0.1,0.3c0.1-0.4,0.4,0,0.6,0c0.2-0.1,0.4,0,0.7,0.1
        c0.2,0.1,0.3,0.1,0.5,0.2c0-0.1,0-0.4,0-0.5c0,0.1,0,0.2,0.1,0.3c0.2,0,0.4-0.2,0.5-0.3c0,0.1,0.1,0.2,0.1,0.3l-0.3,0
        c0,0.1,0.1,0.4,0.2,0.5c0-0.5,0.3-0.3,0.5-0.1c0-0.1,0.1-0.3,0.1-0.3l-0.2-0.1c0,0,0-0.1,0-0.2c0.3,0.3,0.5,0.6,0.8,0.8
        c-0.1-0.1-0.2-0.3-0.2-0.4c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0.2,0.2,0.4,0.3,0.6c0.1-0.1,0.3-0.2,0.4-0.2c0.1,0.2,0.3,0.3,0.4,0.5
        c0-0.1,0-0.3,0-0.4l0.2,0c-0.1,0.6,0.4,0.1,0.7,0.2c0.3,0.2,0.5,0,0.6-0.2c0,0.1,0,0.3,0.1,0.4l-0.3,0c0.1,0.4,0.3-0.1,0.5-0.2
        c0.1,0.1,0.1,0.4,0.3,0.4c-0.1-0.1-0.1-0.3-0.1-0.4l-0.2,0.1l0-0.1c-0.1-0.1-0.2-0.2-0.3-0.2c0.1-0.1,0.3-0.2,0.5-0.3l-0.3,0.1
        c0-0.3,0.2-0.6-0.1-0.8c0.1,0.3,0,0.6-0.2,0.8c0-0.3-0.1-0.6-0.1-0.8c-0.1,0-0.2-0.1-0.3-0.1l0,0.2c0,0-0.1-0.1-0.1-0.1
        c0.1-0.1,0.1-0.2,0.1-0.2c-0.2-0.1-0.3-0.2-0.4-0.3c0.2,0.1,0.4,0.1,0.5,0.2l0.1-0.3c-0.3,0-0.5-0.2-0.7-0.4c0.1,0,0.3,0,0.5,0
        c-0.2-0.2-0.5-0.3-0.6-0.5c0-0.1,0.1-0.4,0.1-0.5c-0.1,0.1-0.3,0.2-0.5,0.2c0,0,0-0.1,0-0.2l0.2,0c-0.1-0.2-0.1-0.5,0-0.7l-0.2,0
        c0,0.1,0,0.3,0,0.4c-0.2-0.2-0.3-0.4-0.5-0.6c0.1,0.1,0.3,0.1,0.5,0.2c0.1-0.3-0.2-0.3-0.3-0.5c0-0.2-0.1-0.3-0.1-0.4
        c0-0.1-0.1-0.2-0.2-0.3c-0.1,0-0.2,0.1-0.3,0.1c0,0,0.1-0.1,0.1-0.2l0.1-0.1c0.1-0.3-0.1-0.5-0.4-0.5c0-0.1,0.1-0.2,0.1-0.2
        c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1-0.5-0.5-0.9-0.8-1.3c-0.3-0.5-0.6-1-0.9-1.5c-0.1-0.2-0.2-0.5-0.5-0.5c0-0.2-0.1-0.3-0.3-0.4
        c-0.1-0.4-0.4-0.8-0.5-1.2c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1-0.2-0.2-0.5-0.4-0.6c-0.3-0.2-0.4-0.6-0.7-0.8c-0.2-0.2-0.5-0.4-0.6-0.7
        c-0.1,0-0.2-0.1-0.3-0.1c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.1-0.3-0.2-0.4-0.4c-0.3-0.5-0.7-0.8-0.9-1.4c-0.1,0-0.2,0.1-0.3,0.1
        c-0.1-0.2-0.1-0.5-0.3-0.6c-0.2-0.1-0.4-0.3-0.5-0.5c-0.3-0.3-0.7-0.7-1-1c-0.2-0.1-0.3-0.1-0.5-0.2c0-0.3,0-0.5-0.3-0.5
        c-0.3,0-0.3-0.4-0.4-0.6c-0.4-0.1-0.9-0.3-0.9-0.8c-0.1,0-0.2-0.1-0.3-0.1c-0.4-0.6-1.1-0.9-1.5-1.5c-0.1-0.1-0.3-0.2-0.4-0.2
        c-0.1-0.3-0.5-0.4-0.7-0.6c-0.3-0.2-0.5-0.6-0.8-0.7c-0.3-0.1-0.3-0.5-0.7-0.6c0-0.1-0.1-0.3-0.2-0.4c-0.1,0.1-0.2,0.2-0.3,0.2
        c-0.4-0.6-1.1-0.8-1.6-1.2c-0.3-0.3-0.9-0.3-1.2-0.7c-0.3-0.3-0.8-0.4-1.2-0.7c0-0.1,0-0.2,0-0.3c-0.3,0.3-0.4,0-0.6-0.1
        c-0.5-0.2-1-0.5-1.5-0.8c0.1-0.1,0.2-0.2,0.3-0.2c-0.2-0.2-0.4,0.1-0.5,0.2c-0.3-0.3-1.2-0.3-0.9-0.9c-0.1-0.1-0.2-0.1-0.3-0.2
        c0.1,0.2,0.1,0.4,0.2,0.6c-0.2-0.2-0.4-0.3-0.6-0.5c-0.1,0-0.2,0.1-0.3,0.2c-0.1-0.1-0.2-0.2-0.3-0.4c-0.3-0.1-0.6-0.2-0.8-0.3
        c0-0.1,0.1-0.2,0.1-0.4c-0.1,0-0.4-0.1-0.5-0.1c0,0.1,0.1,0.2,0.1,0.3c-0.2,0-0.3,0-0.5,0c0.1-0.1,0.2-0.2,0.3-0.3
        c-0.1,0-0.3,0.1-0.4,0.1c0-0.1-0.1-0.2-0.1-0.3c-0.1,0.2-0.2,0.4-0.4,0.1c-0.2,0-0.3,0-0.5,0l0-0.1c-0.3-0.1-0.6-0.3-0.9-0.4
        c-0.6,0.3-1.1-0.6-1.7-0.2c-0.2-0.1-0.4-0.2-0.4-0.4c-0.2,0-0.4,0.1-0.5,0.1c-0.6-0.2-1.2-0.3-1.7-0.5c-0.2,0.2-0.4,0.4-0.5,0
        c-0.3,0-0.8,0.1-1-0.2c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2,0.1-0.4,0.1-0.6,0.1c-0.6-0.2-1.3-0.1-2-0.1
        c-0.2,0.1-0.3-0.1-0.5-0.2c-0.2,0-0.3,0-0.5,0c-0.1,0-0.2,0.1-0.3,0.1c-0.2-0.1-0.5-0.1-0.7-0.1c-0.3,0.2-0.6,0.1-0.9,0
        c0.1-0.1,0.2-0.2,0.2-0.3c-0.2,0.1-0.4,0.2-0.5,0.4c-0.3,0-0.6-0.1-0.9-0.2c-0.2,0.1-0.3,0.1-0.4,0.2c0-0.1,0-0.2,0-0.2
        c-0.1,0-0.2,0-0.2,0c-0.3-0.4-0.4,0.3-0.8,0.2c-0.1-0.2-0.2-0.5-0.4-0.4c-0.1,0.4-0.6,0.3-0.9,0.4c0.1-0.1,0.2-0.3,0.2-0.4
        C388.8,227.6,388.5,227.9,388.3,228 M203.5,227.9c-0.1,0.2,0,0.3,0.2,0.2C203.8,227.9,203.8,227.8,203.5,227.9 M335.2,227.9
        c0,0.1,0,0.3,0,0.4C335.5,228.5,335.5,227.7,335.2,227.9 M336.5,227.9c0.1,0.2,0.5,0.2,0.6,0C337,227.7,336.6,227.7,336.5,227.9
        M168.8,228C168.9,228.1,168.9,228.1,168.8,228 M198.1,228.2c-0.1,0.2,0,0.3,0.3,0.3C198.4,228.3,198.3,228.2,198.1,228.2
        M315.9,228.5c0.2,0,0.3,0,0.2-0.2C315.9,228.2,315.8,228.3,315.9,228.5 M79.6,228.8c0,0.2,0.4,0.2,0.4,0
        C79.9,228.9,79.7,228.8,79.6,228.8 M327.5,228.8C327.5,229.3,327.9,228.6,327.5,228.8 M309.1,229.3c0.1,0,0.3,0.1,0.4,0.1
        C309.6,229.1,308.9,228.9,309.1,229.3 M72.3,229.5c-0.1,0.2,0,0.3,0.2,0.2C72.5,229.5,72.4,229.4,72.3,229.5 M442.4,229.4
        c-0.2,0.1-0.2,0.2,0,0.3C442.6,229.6,442.6,229.5,442.4,229.4 M334.1,229.6c0,0.1,0,0.2,0.1,0.3c-0.1-0.1-0.3-0.2-0.3-0.3
        c0,0.1,0,0.3,0,0.4c-0.6-0.1-0.1,0.9-0.7,0.7c-0.1,0.1-0.2,0.2-0.3,0.4c0,0.1,0,0.2,0.1,0.3l-0.4,0c0.2,0.3,0.8,0.3,0.7-0.2l0.1,0
        l0-0.2l0.2,0.1l-0.1-0.2c0.1,0,0.2,0,0.3,0c0-0.1,0-0.2,0-0.3c0.2,0.1,0.4,0.2,0.6-0.1c-0.1,0-0.3,0-0.4-0.1
        c0.2-0.2,0.3-0.4,0.5-0.5c-0.1,0.1-0.2,0.4-0.2,0.5c0.2-0.2,0.4-0.3,0.5-0.5c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.2,0.2-0.3,0.3-0.4
        C334.4,229.5,334.3,229.6,334.1,229.6 M334.6,229.6c0.2,0.3,0.7,0.2,1,0.4c0-0.1,0-0.3,0-0.4c-0.2,0.1-0.3,0.2-0.5,0.1
        C335,229.7,334.8,229.4,334.6,229.6 M284,229.9C283.8,230.3,284.5,229.9,284,229.9 M57,230.2c0.1,0.2,0.2,0.3,0.4,0.4
        C57.5,230.4,57.3,230,57,230.2 M204.9,230.7c0.1,0,0.2,0,0.2,0.1c0-0.1,0.1-0.3,0-0.5C204.9,230.2,204.7,230.5,204.9,230.7
        M309,230.3c0,0.2,0,0.5,0.2,0.5C309.6,230.7,309.3,230.2,309,230.3 M457.3,230.7c0.2-0.1,0.3-0.3,0.4-0.4
        C457.4,230.1,457.3,230.4,457.3,230.7 M79.2,230.9c0.2,0,0.4-0.1,0.6-0.2c0.1,0,0.2,0.1,0.4,0.1c0.1-0.1,0.1-0.3,0.1-0.4
        C79.8,230.3,79.3,230.5,79.2,230.9 M434.5,230.4c0,0.1,0,0.3,0,0.4c0.1,0,0.3-0.1,0.4-0.1c0.2,0.2,0.4,0.2,0.6,0.2
        C435.4,230.4,434.9,230.4,434.5,230.4 M334.3,230.7c-0.1,0.2-0.3,0.4-0.3,0.7l-0.1,0c0-0.1-0.1-0.4-0.1-0.5
        c-0.1,0.1-0.3,0.3-0.4,0.5c0.1,0,0.3,0,0.4,0c0.2,0.5-0.4,0.2-0.6,0.3c-0.1,0.1-0.2,0.2-0.2,0.3c-0.2,0-0.3-0.1-0.5-0.1
        c0,0.2,0,0.6,0,0.7c0.1,0,0.2,0,0.3,0l0,0.2h0.1c0-0.1-0.1-0.2-0.1-0.3c0.3-0.1,0.6-0.2,0.6-0.5c0,0.1,0.1,0.2,0.2,0.2
        c0.2-0.4,0.7-0.6,0.7-1.1c0.1,0,0.3,0,0.4-0.1c-0.1,0.3,0.1,0.3,0.3,0.3c0-0.1,0.1-0.3,0.1-0.4c-0.1,0.1-0.2,0.2-0.2,0.3
        c-0.2-0.1-0.3-0.2-0.2-0.4C334.5,230.7,334.4,230.7,334.3,230.7 M205.4,230.8C205.4,230.9,205.4,230.9,205.4,230.8 M216,230.8l0,0.1
        c0.1,0,0.2,0.1,0.2,0.2C216.4,231,216.2,230.7,216,230.8 M104.5,231.3c0,0,0.1,0.1,0.2,0.1c0-0.1,0.1-0.2,0.1-0.3
        C104.6,231.1,104.5,231.1,104.5,231.3 M182.1,231.4c-0.1,0.1-0.2,0.4,0,0.5C182.4,231.9,182.3,231.3,182.1,231.4 M332.3,231.5
        c-0.3,0.2-0.5,0.7-0.8,0.6c0.3,0.1,0.3,0.4,0.3,0.6c0-0.1-0.1-0.2-0.1-0.2c-0.2,0.2-0.4,0.2-0.7,0.2c-0.1,0.2-0.1,0.4-0.2,0.6
        c0.2,0.1,0.5,0.1,0.8,0.1c0.2-0.2,0.4-0.2,0.6-0.1c0-0.1-0.1-0.3-0.1-0.4c0.1,0,0.4,0.1,0.6,0.1c0-0.1-0.1-0.2-0.1-0.3
        c-0.1,0-0.3,0.1-0.4,0.1c0.1-0.2,0.2-0.3,0.4-0.5c-0.2,0-0.4,0.2-0.6,0.3c0.1-0.2,0.3-0.4,0.5-0.5c0,0-0.1-0.1-0.1-0.2
        C332.5,231.9,332.4,231.7,332.3,231.5 M77.2,231.8c-0.1,0.2,0,0.3,0.2,0.2C77.5,231.8,77.5,231.7,77.2,231.8 M312.7,231.9
        C312.8,232,312.8,232,312.7,231.9 M437.3,231.8c-0.1,0.2,0,0.3,0.2,0.3C437.6,231.8,437.5,231.7,437.3,231.8 M202,232.1
        c0,0.1,0.3,0.4,0.3,0.2C202.4,232.1,202.1,231.8,202,232.1 M262.3,232.4c-0.2,0.1-0.2,0.2,0,0.3
        C262.4,232.5,262.4,232.4,262.3,232.4 M272.9,232.4C273,232.5,273,232.5,272.9,232.4 M201.3,233c0.1,0,0.3,0,0.3,0
        c0-0.2,0-0.3,0.1-0.5C201.6,232.6,201.4,232.8,201.3,233 M439.4,232.5c0,0.2,0,0.3,0.2,0.3C439.7,232.6,439.6,232.5,439.4,232.5
        M138.8,232.7C138.9,232.8,138.9,232.8,138.8,232.7 M183.6,232.7C183.7,232.8,183.7,232.8,183.6,232.7 M333,233.1l0.1-0.3
        c-0.1,0.1-0.2,0.2-0.3,0.3c0,0.1,0,0.2,0,0.2C332.7,233.3,332.8,233.2,333,233.1c0.2,0.1,0.6-0.1,0.6-0.4
        C333.4,232.8,333.2,233,333,233.1 M45.1,233.1C44.8,233.5,45.6,233.1,45.1,233.1 M73.2,233.4c0.1,0.1,0.1,0.2,0.2,0.3
        c0.1-0.2,0.3-0.4,0.5-0.2c0-0.1,0.1-0.3,0.1-0.3C73.7,232.9,73.4,233.1,73.2,233.4 M440.8,233.4c0.3-0.2,0.4,0,0.5,0.2
        c0.2-0.1,0.1-0.3,0.1-0.5C441.2,233.1,440.5,232.8,440.8,233.4 M469.6,233.1C469.3,233.5,470,233.1,469.6,233.1 M330.4,233.5
        c-0.1,0.2-0.4,0.7,0,0.7c-0.1,0.2-0.1,0.3-0.3,0.3c0.1-0.3,0-0.5-0.2-0.6c0,0.2-0.1,0.5-0.2,0.7c-0.1-0.1-0.2-0.3-0.3-0.4
        c0,0.1,0.1,0.4,0.1,0.5l-0.3-0.1c-0.1,0.2-0.2,0.4,0,0.6c-0.2,0-0.4,0-0.5,0c0,0,0,0.1,0,0.2c-0.3,0.2-0.3,0.5-0.6,0.7
        c0,0,0,0.1,0,0.1c0.1,0,0.3,0,0.3,0c-0.2,0.1-0.5,0.2-0.6,0.5c0-0.1-0.1-0.3-0.1-0.4c-0.1,0.2-0.2,0.4-0.3,0.7c-0.3,0-0.6,0-0.7,0.3
        c0.1,0,0.4-0.1,0.5-0.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1-0.1-0.3-0.2-0.4-0.3c0,0.2,0.1,0.4,0.1,0.6l-0.2,0c-0.1,0.1-0.2,0.2-0.2,0.3
        c0-0.1,0-0.3,0-0.4c-0.4,0.6-1.2,1-1.5,1.7l0,0c0-0.1,0-0.2,0-0.3l-0.1,0c0,0.4-0.7,0.3-0.6,0.8c-0.2,0.1-0.3,0.2-0.5,0.3
        c-0.1-0.1-0.2-0.2-0.2-0.2c0.1,0.1,0.3,0.5,0,0.5c-0.2,0-0.3,0.1-0.5,0.2c0,0.1,0,0.3,0,0.4c-0.1-0.1-0.2-0.1-0.3-0.2
        c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0-0.2,0-0.3,0c0,0.3-0.2,0.5-0.5,0.6c-0.1,0.1-0.2,0.2-0.2,0.3c-0.2-0.1-0.3-0.2-0.5,0
        c0.1,0.1,0.2,0.2,0.3,0.3c-0.6,0.2-1,0.8-1.5,1.1c-0.1,0.2-0.4,0.4-0.6,0.6c-0.2,0.2-0.4,0.5-0.7,0.4c0,0,0,0.1,0,0.2
        c-0.3,0.3-0.6,0.7-1,0.9c-0.4,0.4-0.8,0.7-1.1,1.1c-0.3,0.4-0.7,0.6-1,0.9c-0.9,1.1-1.9,1.9-3,2.8l0-0.3c-0.1,0-0.2,0.1-0.3,0.1
        c0.1,0.1,0.2,0.2,0.3,0.3c-0.2,0.1-0.4,0.2-0.6,0.2c0,0.1,0,0.2,0.1,0.2c-0.2,0.2-0.5,0.3-0.8,0.5c0,0.2,0,0.3,0.1,0.5
        c0.2,0.1,0.4-0.1,0.6-0.1c-0.1,0.1-0.2,0.3-0.3,0.4c0.3,0.2,0.1,0.6-0.2,0.5c0,0,0,0.1,0,0.1c0.2,0,0.4,0,0.6,0
        c0,0.2-0.2,0.5,0.1,0.5c0,0.3,0.2,0.5,0,0.8c0.1,0,0.3-0.1,0.4-0.1c-0.1,0.2-0.2,0.5-0.3,0.7c0.2,0,0.4,0.1,0.5,0.2
        c-0.1-0.1-0.3-0.2-0.3-0.3c0.1,0,0.2,0,0.2-0.1c0-0.1,0-0.3,0-0.4c0.3,0.1,0.3,0.4,0.3,0.7c0.1-0.1,0.2-0.2,0.3-0.3
        c0.1-0.1,0.2-0.3,0.1-0.5c0.3-0.1,0.7-0.3,0.6-0.7c0.2,0,0.3,0.3,0.5,0.2c-0.2-0.3-0.1-0.6,0.2-0.6c0-0.1,0-0.2,0-0.3
        c0.1,0,0.2,0.1,0.3,0.1c0-0.2,0-0.3,0.1-0.5c0.1-0.1,0.3-0.2,0.4-0.2c0.1,0,0.3,0,0.4,0.1c0.2-0.3,0.5-0.5,0.8-0.6
        c-0.1,0-0.2,0-0.3,0c0-0.2,0-0.5,0.2-0.7c0,0,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.1,0.2c0.1,0,0.3,0,0.3,0c0-0.1-0.1-0.2-0.1-0.2
        c0.1,0,0.2,0,0.3-0.1c-0.2-0.2,0-0.5,0.3-0.5c0-0.3,0.3-0.4,0.5-0.3c0,0-0.1-0.1-0.2-0.1c0.1-0.2,0.2-0.4,0.2-0.7c0.1,0,0.2,0,0.3,0
        c0.3-0.1,0.1-0.6,0.5-0.5c0-0.1-0.1-0.2-0.1-0.2c0.4,0.1,0.4-0.4,0.6-0.6c0.1,0.3,0.4,0.2,0.6,0.1l-0.3-0.1l0-0.1l0.2,0.1l-0.1-0.2
        c0.3-0.1,0.5-0.3,0.6-0.5c0.2-0.2,0.3-0.7,0.7-0.4c0-0.1-0.1-0.2-0.2-0.3c0.1-0.1,0.3-0.1,0.4-0.2c0-0.3,0.1-0.5,0.4-0.5
        c-0.1,0.2-0.1,0.4-0.2,0.7c0.1,0,0.2-0.1,0.3-0.1c0-0.2,0.2-0.7-0.2-0.6c0.1-0.2,0.3-0.3,0.6-0.2c0.1-0.2,0-0.4-0.1-0.5
        c0.2,0,0.4-0.1,0.6-0.2c0-0.1-0.1-0.2-0.1-0.2c0.3-0.2,0.5-0.5,0.8-0.5c0.2-0.1,0.7-0.2,0.7-0.5c-0.2,0-0.6,0.4-0.7,0.1
        c0.1,0,0.3,0,0.4,0c-0.1-0.3,0.2-0.7,0.5-0.5c-0.2-0.4,0.2-0.3,0.5-0.3c0-0.1,0-0.3-0.1-0.4l0.1,0c0-0.2,0-0.3,0.1-0.5
        c0,0.1,0,0.3,0.1,0.3l0.2,0c0-0.3,0.1-0.5,0.4-0.6c0.1-0.2,0.1-0.4,0.2-0.6c0.1,0.1,0.2,0.2,0.2,0.3l-0.2,0.1l0,0.2
        c0.1,0,0.2-0.1,0.3-0.1c0.1-0.2,0-0.5-0.1-0.6c0.1,0,0.4,0.1,0.5,0.1c0.1,0.2,0.2,0.2,0.3,0c-0.2-0.1-0.5-0.1-0.7-0.1
        c0.4-0.5,1.1-0.8,1.2-1.4c0.3,0,0.5-0.1,0.7-0.2c-0.1-0.1-0.3-0.2-0.4-0.3c0.1,0,0.3,0,0.4-0.1c0.4-0.2,0-0.6,0.4-0.9
        c0,0.1,0.1,0.3,0.1,0.4c0.1,0,0.3,0,0.4,0c-0.1,0.2-0.2,0.3-0.3,0.5c0.2-0.1,0.4,0.1,0.6,0.1c-0.1-0.1-0.2-0.2-0.4-0.2
        c0.1-0.2,0.2-0.4,0.3-0.6c-0.1,0-0.3,0.1-0.4,0.1l0-0.4c0.1,0,0.3,0,0.4,0c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.2,0,0.3-0.1
        c0-0.5,0.4-0.4,0.3,0c0,0,0.1,0,0.2,0c0,0-0.1-0.1-0.2-0.1c0-0.1,0.1-0.3,0.2-0.4c0.1,0.2,0.3,0.5,0.6,0.5c0-0.1,0-0.2,0-0.3
        c-0.2,0-0.5-0.2-0.2-0.4c-0.1-0.1-0.1-0.2-0.1-0.3c0.1-0.1,0.3-0.2,0.4-0.4c0,0.2,0.1,0.3,0.2,0.4c0-0.3,0.2-0.4,0.4-0.5l-0.2,0.1
        c-0.1-0.2-0.2-0.3-0.3-0.4l0.2,0c0-0.1,0-0.3,0-0.3c0.2,0.1,0.4,0.2,0.5-0.1c-0.1,0-0.3-0.1-0.4-0.2c0.1,0,0.3-0.1,0.4-0.1
        c0-0.1-0.1-0.2-0.2-0.2c0.2-0.1,0.4-0.2,0.6-0.3c0,0.2-0.6,0.4-0.1,0.6c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0-0.3-0.1-0.4-0.1
        c0,0.4,0.3,0.2,0.5,0.1c0-0.2,0.1-0.3,0.1-0.5c0.2,0,0.3,0.2,0.2,0.5c0.3-0.4,0.1-0.8-0.1-1.2c0.3-0.1,0.5-0.1,0.8-0.3
        c0,0.1,0,0.3,0,0.4c0-0.1,0-0.3,0-0.4c0.3-0.1,0.4-0.3,0.3-0.6c0.1,0,0.3,0.1,0.4,0.1c0-0.1-0.1-0.2-0.1-0.2c-0.2,0-0.3,0-0.5,0
        c0,0.1,0,0.2,0,0.3c-0.3,0.2-0.7,0.4-1.1,0.6c0.1-0.2,0.3-0.4,0.5-0.5c0.1,0,0.2-0.1,0.3-0.2l0-0.2l-0.2,0.3l0-0.1l-0.1-0.1
        C331,233.4,330.7,233.4,330.4,233.5 M198.5,233.9c-0.1,0.2-0.1,0.3,0.1,0.3C198.7,234,198.7,233.9,198.5,233.9 M47.1,234.1
        C46.9,234.5,47.6,234.1,47.1,234.1 M184.7,234.1C184.8,234.2,184.8,234.2,184.7,234.1 M185.1,234.1c0.2,0.3,0.3,0.6,0.7,0.6
        C185.6,234.4,185.5,234.1,185.1,234.1 M49.1,234.2c-0.2,0.2,0.4,0.5,0.5,0.3C49.4,234.4,49.3,234.2,49.1,234.2 M51.7,234.8
        c-0.1,0.2,0.2,0.4,0.3,0.2C52.2,234.8,51.8,234.6,51.7,234.8 M195.1,234.8C195.2,234.9,195.2,234.9,195.1,234.8 M462.7,234.8
        c-0.2,0.2,0.2,0.4,0.3,0.2C463.2,234.8,462.9,234.6,462.7,234.8 M123.3,234.9l0,0.1C123.6,235.3,123.6,234.6,123.3,234.9
        M50.9,235.1C51,235.2,51,235.2,50.9,235.1 M52.5,235.4c0.2,0,0.4-0.2,0.4-0.4C52.7,234.9,52.4,235.2,52.5,235.4 M463.7,235.1
        C463.8,235.2,463.8,235.2,463.7,235.1 M302.9,235.2c-0.1,0.2,0,0.3,0.3,0.2C303.2,235.2,303.1,235.1,302.9,235.2 M64,235.3
        C64.1,235.4,64.1,235.4,64,235.3 M56.3,235.9c0.1,0,0.3,0,0.4-0.1c0,0.1,0.1,0.2,0.2,0.2c0-0.1,0-0.3,0-0.4c0.1,0,0.3,0.1,0.4,0.1
        l0.1-0.1C57.1,235.1,56.6,235.7,56.3,235.9 M62.6,235.5c-0.2,0.2,0.2,0.3,0.3,0.2C63.1,235.5,62.7,235.3,62.6,235.5 M306.3,235.5
        c0,0.1,0,0.3,0,0.4C306.5,235.9,306.6,235.3,306.3,235.5 M306.9,235.5C307,235.6,307,235.6,306.9,235.5 M322.6,235.5
        C322.4,236,323.1,235.5,322.6,235.5 M451.8,235.5c-0.1,0.2,0.2,0.3,0.4,0.2C452.3,235.5,451.9,235.3,451.8,235.5 M59.1,235.7
        C59.2,235.8,59.2,235.8,59.1,235.7 M62,235.6c-0.1,0.2,0.2,0.4,0.4,0.3C62.4,235.7,62.1,235.5,62,235.6 M189.4,235.6
        c-0.1,0.2,0,0.3,0.2,0.2C189.8,235.6,189.7,235.5,189.4,235.6 M455.5,235.7C455.6,235.8,455.6,235.8,455.5,235.7 M58.3,235.8
        C58.4,235.9,58.4,235.9,58.3,235.8 M191,236c0.1,0.1,0.4-0.1,0.3-0.3C191.2,235.6,191,235.8,191,236 M456.4,235.8
        C456.4,235.9,456.4,235.9,456.4,235.8 M59.4,236.1c-0.1,0.2,0,0.3,0.3,0.2C59.7,236.1,59.7,236,59.4,236.1 M455.1,236.1
        c-0.1,0.2,0,0.3,0.2,0.2C455.4,236.1,455.3,236,455.1,236.1 M330.4,236.7c-0.2,0.1-0.2,0.2,0,0.3
        C330.5,236.9,330.5,236.8,330.4,236.7 M153.6,236.7c-0.1,0.2,0,0.3,0.2,0.3C153.9,236.8,153.8,236.7,153.6,236.7 M303.6,237.5
        c0.1,0.1,0.3,0.2,0.4,0.3c-0.1-0.3-0.2-0.6-0.3-0.9C303.7,237.1,303.6,237.3,303.6,237.5 M329.8,237.3c0.2,0.1,0.4-0.4,0.1-0.4
        C329.7,236.9,329.7,237.1,329.8,237.3 M262.1,237.2C262.2,237.3,262.2,237.3,262.1,237.2 M320.5,237.2c-0.1,0.2,0.1,0.5,0.3,0.3
        C321,237.4,320.7,237.2,320.5,237.2 M156.4,237.5c0,0.1,0.1,0.4,0.1,0.5c0.2-0.1,0.3-0.2,0.5-0.3
        C156.8,237.6,156.6,237.5,156.4,237.5 M265.3,238.2c0.2,0.1,0.4-0.2,0.3-0.3C265.4,237.8,265.2,238.1,265.3,238.2 M265.8,237.9
        c-0.1,0.2,0,0.3,0.2,0.2C266.1,237.9,266,237.8,265.8,237.9 M264.8,238.1c-0.2,0.1-0.2,0.5,0.1,0.4C265.1,238.4,265,238,264.8,238.1
        M328.3,238.7c0.2-0.2,0.3-0.4,0.4-0.7C328.5,238.2,328.2,238.4,328.3,238.7 M225.2,239c0,0.2,0.1,0.3,0.3,0.3
        C225.5,239,225.4,238.9,225.2,239 M327.3,238.9c0.2,0.2,0,0.4,0,0.7c0.1-0.2,0.2-0.3,0.3-0.5c-0.1-0.1-0.2-0.2-0.2-0.3L327.3,238.9
        M210.9,239.6c-0.1,0.2,0,0.3,0.2,0.2C211.1,239.6,211.1,239.6,210.9,239.6 M326.7,239.7l0.1,0.2l-0.2-0.1c-0.2,0.2-0.3,0.5-0.6,0.6
        c-0.1,0.2-0.1,0.3,0,0.5c0.1-0.1,0.3-0.1,0.4-0.2c-0.1,0-0.2-0.1-0.3-0.1l0-0.1c0.2-0.1,0.4-0.1,0.6-0.1c0-0.1,0-0.3-0.1-0.5
        c0.2,0,0.5,0.1,0.7-0.1C327,239.8,326.9,239.7,326.7,239.7 M161.4,239.9c-0.1,0.2,0,0.3,0.2,0.2
        C161.8,239.9,161.7,239.8,161.4,239.9 M210.2,239.9c0.1,0.2,0.2,0.3,0.3,0.5c-0.2-0.1-0.4-0.1-0.5-0.2c0,0.1,0.1,0.3,0.1,0.3
        c-0.2,0-0.3-0.1-0.4-0.2c-0.2,0-0.4,0-0.6,0c0.1,0.1,0.2,0.2,0.4,0.3c0,0.2,0,0.5,0,0.7c-0.3-0.1-0.2-0.5-0.4-0.6
        c0.1,0.3,0.2,0.6,0.2,0.9c0.1,0.1,0.2,0.2,0.3,0.3c0.3,0,0.6-0.1,0.8,0.2c0-0.1,0-0.3,0-0.4c0.1,0,0.2,0.1,0.3,0.1
        c0-0.5,0.6,0.1,0.7-0.2c0,0.1,0,0.3,0,0.4c0.1,0,0.3-0.1,0.4-0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.1,0.3-0.2c-0.1,0.2-0.2,0.3-0.2,0.5
        c0.1,0,0.3,0.1,0.4,0.1c0.1-0.1,0.3-0.2,0.4-0.3c0.1,0.2,0.3,0.4,0.6,0.4l0,0.2c0.1,0,0.2-0.1,0.3-0.2c-0.1,0.3,0.1,0.5,0.4,0.6
        c0-0.1-0.1-0.4-0.2-0.5l0.4,0.2c0-0.1-0.1-0.3-0.2-0.5c0.1,0.1,0.4,0.3,0.5,0.4c-0.1,0-0.4,0-0.5,0.1l0.1,0.3
        c0.2,0.4,0.3-0.1,0.4-0.2c0.2,0.2,0.3,0.3,0.5,0.5l-0.3-0.1l0,0.3c0.3-0.2,0.3,0.2,0.5,0.3c0.1-0.4-0.1-0.7-0.2-1.1
        c-0.1-0.2-0.1-0.4-0.1-0.6c0,0.1-0.1,0.4-0.1,0.5c-0.3-0.2-0.4-0.4-0.2-0.7c-0.1-0.1-0.2-0.2-0.2-0.3c0,0.1-0.1,0.2-0.1,0.2
        c-0.2-0.1-0.3-0.3-0.3-0.5c-0.2-0.1-0.2-0.2-0.1-0.5c-0.3,0.3-0.6,0-0.8-0.2c-0.1,0-0.2,0-0.3-0.1c0-0.1-0.1-0.3-0.2-0.4
        c-0.2,0.2-0.4,0.5-0.7,0.4c-0.1-0.4-0.6-0.4-1-0.4c0.1,0.2,0.5,0.5,0.1,0.5c0-0.1-0.1-0.4-0.1-0.6c-0.1,0.1-0.2,0.3-0.3,0.4
        C210.6,240.2,210.6,239.9,210.2,239.9 M227.3,240.2c0-0.1,0.1-0.2,0.2-0.3C227.2,239.7,226.9,240.2,227.3,240.2 M100.7,240.4
        c0.2,0,0.3-0.1,0.2-0.3C100.7,240.1,100.7,240.2,100.7,240.4 M212,240.1C212.1,240.2,212.1,240.2,212,240.1 M227.4,240.3
        c0.1,0,0.3,0.1,0.3,0.2c0.2-0.4,0.5,0.1,0.7,0.2c0.3-0.1,0.6,0.1,0.9,0.4c0-0.2-0.1-0.3-0.1-0.4c-0.1-0.1-0.3-0.2-0.3-0.2
        c-0.2,0.1-0.3,0.2-0.4,0.3c0-0.1,0.1-0.4,0.2-0.5c-0.1,0-0.3,0.2-0.4,0.2c0-0.1-0.1-0.2-0.1-0.3C227.9,240,227.7,240.2,227.4,240.3
        M162.3,240.2C162.4,240.3,162.4,240.3,162.3,240.2 M163.7,240.2c0,0.4,0.6,0.7,0.9,0.4c-0.3-0.1-0.6-0.2-0.8-0.5
        C163.8,240.1,163.7,240.2,163.7,240.2 M318.6,240.5c0.3,0.2,0.5-0.5,0.1-0.4C318.5,240.2,318.5,240.4,318.6,240.5 M112.7,240.4
        c-0.1,0.2,0.3,0.4,0.3,0.2C113.1,240.4,112.8,240.2,112.7,240.4 M148,240.8C147.9,241.2,148.4,240.6,148,240.8 M206.1,241
        c0.2,0.1,0.3,0.1,0.5,0l0-0.1C206.5,240.7,206,240.7,206.1,241 M206.9,240.7c0,0.2,0.1,0.3,0.3,0.4c-0.3-0.1-0.4,0.3-0.7,0.2
        c-0.3-0.2-0.4,0.2-0.5,0.4l-0.2,0c0.1-0.2,0.2-0.3,0.3-0.5c-0.3,0.2-0.7,0.4-1.1,0.2c0,0.1-0.1,0.2-0.1,0.3c-0.1,0-0.3,0-0.4,0
        c0.1,0.1,0.2,0.3,0.3,0.4c-0.1,0-0.2-0.1-0.2-0.1l-0.2-0.2c-0.1,0.2-0.2,0.4-0.2,0.6c-0.1-0.1-0.3-0.2-0.4-0.3
        c-0.1-0.1-0.2-0.2-0.2-0.3c0,0.1,0,0.4,0,0.5c-0.2-0.1-0.4-0.2-0.6-0.3c-0.3,0.1-0.6,0.3-0.5,0.7c-0.1,0-0.2-0.1-0.3-0.1
        c-0.3,0.1-0.6,0.3-0.9,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0-0.3,0-0.5,0c0.2,0,0.5,0,0.7,0.1c-0.1,0.3-0.4,0.3-0.7,0.2
        c-0.2,0.2-0.3,0.6-0.6,0.3c0.4,0,0.3-0.4,0.3-0.6c-0.3,0.1-0.6,0.2-0.9,0.4c0.1,0,0.3,0.1,0.5,0.2c-0.2,0-0.5,0-0.6,0
        c0,0.1,0.1,0.2,0.1,0.3c-0.3-0.3-0.8-0.7-1.1-0.1c0.2,0,0.5,0,0.7,0c0,0,0,0.1-0.1,0.2c-0.2,0-0.5,0.1-0.7,0
        c-0.1,0.1-0.2,0.2-0.3,0.3c0-0.1-0.1-0.2-0.1-0.3c-0.2,0.1-0.3,0.2-0.2,0.5c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0.2-0.2,0.3-0.4,0.4
        c0-0.2,0.1-0.4,0.2-0.5c0.1,0,0.3,0.1,0.3,0.2c0-0.2,0-0.4,0-0.5c-0.2,0.1-0.5,0.3-0.7,0.4c-0.2,0-0.4,0.1-0.5,0.2
        c-0.2-0.2-0.5-0.1-0.5,0.2c0,0-0.1-0.1-0.1-0.2c-0.2,0-0.4-0.2-0.4-0.4c-0.4,0.1-0.7,0.3-1.1,0.2c-0.1,0.2-0.3,0.3-0.5,0.2
        c-0.1-0.3-0.3-0.5-0.5-0.6c0.2,0.2,0.3,0.4,0.5,0.6c-0.2,0.2-0.6,0.1-0.6-0.3c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1-0.1-0.3-0.3-0.5-0.2
        c0-0.1,0.1-0.2,0.1-0.3c-0.1,0-0.3-0.1-0.3-0.2c-0.1,0-0.1,0.1-0.2,0.2c0.1,0,0.2,0,0.3,0c-0.1,0.1-0.2,0.3-0.2,0.3
        c0.2,0.3,0.8,0.2,0.9,0.6c0.1,0.1,0.2,0.1,0.3,0.2c0,0.1-0.1,0.2-0.1,0.2c0.2,0.1,0.4,0.2,0.6,0.2c-0.1,0.1-0.2,0.3-0.1,0.5
        c0.1,0,0.4,0,0.5,0c-0.2,0.3-0.2,0.5-0.2,0.8c0.1,0,0.2,0.1,0.3,0.1l0-0.2c0.2,0,0.4,0.1,0.7,0.1c0,0.1-0.1,0.3-0.1,0.4
        c0.1,0,0.2-0.1,0.3-0.1c0.3,0.5,0.6,0.9,1,1.2l0.1,0l0,0.1l-0.3,0c0.1,0.3,0.5,0.4,0.6,0.1c0.1,0.3,0.2,0.6,0.5,0.8
        c0.3,0,0.4,0.2,0.4,0.5c0.1,0,0.3,0,0.4,0c0,0.1,0,0.3,0,0.4c0.1,0,0.3,0,0.4-0.1l0.1,0c-0.1,0.2-0.3,0.3-0.4,0.4
        c0.3-0.1,0.5,0.1,0.5,0.3l0.1-0.1c0.1,0.2,0.2,0.3,0.1,0.5c0.1,0,0.2,0,0.2,0.1l0,0.1c0,0-0.1,0.1-0.1,0.1c0.1,0,0.3,0,0.4,0
        c0-0.1-0.1-0.2-0.1-0.3c0.2-0.4,0.6,0.8,0,0.5c0.3,0.1,0.6,0.4,0.8,0.6c0.1,0,0.3-0.1,0.4-0.1c0.2,0.1,0.5,0.3,0.6,0.5
        c-0.3,0.1-0.5,0-0.6-0.3c-0.1,0-0.3,0-0.4,0.1c0.3,0.2,0.6,0.3,0.8,0.5c0-0.1,0.1-0.2,0.1-0.3l0.1,0l-0.1,0.1
        c0.3,0.1,0.7,0.4,0.4,0.7c0.1,0,0.2,0,0.3,0c0.2,0.2,0.5,0.4,0.7,0.5c0,0.1,0,0.3,0.1,0.4c0.2,0.1,0.4,0.3,0.6,0.3
        c0,0.1,0,0.2,0,0.2c0.1,0,0.2,0,0.3,0c0,0.2-0.2,0.5,0.2,0.5c0.1,0.2,0.2,0.3,0.4,0.3c0,0.1,0,0.2,0,0.3c0,0,0.1,0,0.2,0
        c0-0.1-0.1-0.2-0.1-0.3c0.4,0.1,0.6,0.7,1.1,0.8l0-0.2c0.2-0.1,0.2-0.4,0.2-0.6c0.1,0,0.3,0.1,0.4,0.2c-0.1-0.2-0.2-0.5-0.2-0.8
        c0.1,0,0.4,0,0.5,0.1c-0.3-0.1-0.2-0.5-0.1-0.6c0.1,0.1,0.2,0.2,0.2,0.3c0.1-0.3-0.3-1,0.3-1c0-0.2,0-0.4,0-0.5l0.2,0
        c0-0.3,0.1-0.5,0.2-0.7c0.3,0.1,0.3,0,0.2-0.3c0.1,0,0.2,0,0.3,0c0-0.1-0.1-0.3-0.1-0.3l0.3,0.1c0-0.1-0.1-0.2-0.1-0.3
        c0.1,0,0.2-0.1,0.2-0.1c0-0.3,0.2-0.7,0.5-0.8c0.2-0.5,0.5-0.9,0.8-1.4c0,0,0.1,0,0.2,0c0.2-0.5,0.4-0.9,0.8-1.3
        c0.2-0.2,0.3-0.7,0.7-0.7c-0.4-0.9-0.6-1.9-1-2.8c-0.2,0.1-0.4,0.1-0.7,0.2c-0.2-0.1-0.4-0.1-0.6-0.2c-0.2,0.1-0.3,0.3-0.5,0.4
        c-0.5,0.1-1.1,0.3-1.6,0.3c-0.5,0.3-1.3,0.1-1.7,0.6c-0.3,0.3-1,0.4-1.2,0.8c-0.1,0-0.3-0.2-0.4-0.1c-0.4,0.3-0.9,0.2-1.3,0.5
        c-0.5,0.3-1.1,0-1.5,0.3c-0.6-0.1-1.2,0-1.8-0.4c-0.2,0-0.4,0-0.6-0.2c-0.3-0.1-0.5-0.2-0.8-0.4c-0.3-0.3-0.8-0.3-1-0.5
        c-0.3-0.2-0.5-0.5-0.7-0.8c0.3,0.2,0.7,0.3,1.1,0.4l-0.1-0.3c0.5,0.5,1.2-0.2,1.6,0.3l0-0.2l0.2,0.1l0-0.2c0.5,0,1,0,1.5-0.1
        c0.2-0.1,0.4,0,0.7,0c-0.1-0.2-0.1-0.3,0-0.5c0.1,0.1,0.2,0.2,0.3,0.3c0,0,0.1-0.1,0.1-0.2c0.2,0.1,0.4,0,0.7-0.1
        c0.3-0.1,0.5-0.3,0.8-0.2c0-0.1-0.1-0.3-0.1-0.4l0.1,0.2c0.1,0,0.2,0.1,0.3,0.1c0-0.1-0.1-0.3-0.1-0.4c0.1,0,0.3,0.1,0.4,0.2
        c0-0.1,0-0.2,0-0.3c0.2,0.1,0.3,0.1,0.5,0.2c0.1-0.2,0.2-0.3,0.3-0.5c0.1,0.1,0.3,0.2,0.4,0.3c0-0.1,0-0.3,0-0.4
        c0.3,0,0.6-0.2,0.9,0c-0.5-0.6,0.8-0.2,0.6-0.8c0.1,0.3,0.6,0.1,0.9,0.2c0-0.1-0.1-0.3-0.1-0.4l-0.1-0.1c0.2,0,0.3,0.2,0.5,0.3
        c0.3-0.4,0.9-0.4,1.4-0.4c0-0.2,0-0.4,0-0.6c0.2,0.1,0.3,0.2,0.5,0.4c-0.1,0-0.2,0-0.2,0l-0.1,0l-0.1,0c-0.2,0.2,0.2,0.3,0.3,0.5
        c0.1-0.2,0.2-0.3,0.3-0.5c0-0.1-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.3-0.2-0.4-0.3c0-0.1,0.1-0.2,0.2-0.3
        C207.4,240.7,207.1,240.7,206.9,240.7 M167.4,241.7c-0.1,0.2,0.2,0.4,0.3,0.1C167.7,241.7,167.4,241.5,167.4,241.7 M204.1,241.7
        c-0.1,0.1-0.3,0.4-0.1,0.4C204.3,242.1,204.4,241.6,204.1,241.7 M150.2,241.8c-0.1,0.2,0,0.3,0.2,0.2
        C150.4,241.8,150.4,241.7,150.2,241.8 M210.7,241.6l-0.2,0.2c0.1,0.4,0.6,0.5,0.8,0.8l0-0.1l0-0.1c-0.1-0.1-0.1-0.2,0.1-0.3v-0.1
        c-0.1,0-0.4,0.1-0.5,0.1C210.8,241.9,210.7,241.7,210.7,241.6 M317.3,241.9C317,242.3,317.7,241.9,317.3,241.9 M324.8,241.9
        C324.9,242,324.9,242,324.8,241.9 M211.6,242.1C211.4,242.5,212.1,242.1,211.6,242.1 M212.7,242.1c-0.3,0.1,0,0.5,0.3,0.3
        C213,242.3,212.8,242.1,212.7,242.1 M153.1,242.4c0.1,0.1,0.2,0.2,0.4,0.2C153.6,242.2,153,242,153.1,242.4 M211.9,242.3
        c0,0.2,0.2,0.3,0.4,0.3C212.5,242.3,212.1,242,211.9,242.3 M213.7,242.9c0,0.1,0,0.2,0,0.2c0,0,0.1,0,0.1,0L213.7,242.9
        c0.3,0,0.5,0.1,0.6,0.3l0.1-0.1c-0.4-0.2-0.8-0.2-1.1-0.6C212.9,242.5,213.5,242.8,213.7,242.9 M324.1,243.1c0.1,0,0.3,0,0.3,0
        c-0.1-0.3,0.3-0.4,0.2-0.7C324.3,242.5,324.2,242.8,324.1,243.1 M213.1,242.7c0,0-0.1-0.1-0.2-0.2
        C212.5,242.6,213,243.1,213.1,242.7 M214.7,242.8c0,0.1,0,0.2-0.1,0.2l0.1,0C214.8,243,214.8,242.9,214.7,242.8L214.7,242.8
        M316.8,242.8C316.9,242.8,316.9,242.8,316.8,242.8 M154.2,243.1c0.2,0,0.5,0.2,0.7,0c-0.1,0-0.2,0-0.3,0l0-0.3
        C154.4,242.9,154.3,243,154.2,243.1 M323.6,243.2C323.7,243.2,323.7,243.2,323.6,243.2 M397.5,243.2l0.1,0.1c0.1,0,0.2,0,0.3,0
        C398,243.1,397.6,243,397.5,243.2 M190.8,243.9c0.2-0.1,0.2-0.2,0-0.3C190.6,243.7,190.6,243.8,190.8,243.9 M191.3,243.6
        c-0.1,0.2,0,0.3,0.3,0.2C191.6,243.7,191.5,243.6,191.3,243.6 M323.8,243.6c-0.1,0.2,0,0.3,0.2,0.2
        C324.1,243.6,324,243.5,323.8,243.6 M192.1,244.5c0.2,0.3,0.5,0.4,0.8,0.6C193,244.6,192.4,244.6,192.1,244.5 M210,244.5
        c0,0.2,0,0.4,0,0.6c0.1,0,0.2,0,0.3,0c0,0.1-0.1,0.2-0.2,0.3c0.1,0.3,0.2,0.6,0.2,0.9c0.2,0.2,0.1,0.1,0.2,0.4
        c0.4-0.4,0.7-0.9,1.2-1.4c0.2-0.2,0.3-0.4,0.5-0.6c-0.3-0.2-0.7-0.1-1.1,0c-0.1-0.1-0.2-0.2-0.3-0.4
        C210.5,244.5,210.2,244.5,210,244.5 M321.9,245c0.3-0.1,0.6-0.3,0.7-0.6C322.4,244.6,321.7,244.6,321.9,245 M159.6,244.7
        c0.1,0.2,0.2,0.2,0.3,0.1C159.8,244.5,159.7,244.5,159.6,244.7 M306.8,244.6c0,0.2,0.1,0.4,0.2,0.5C307.2,245,307,244.5,306.8,244.6
        M210.6,246.9c-0.1-0.1-0.2-0.2-0.4-0.3c0.1,0.2,0.3,0.4,0.3,0.7c0,0.6,0.1,1.1,0.2,1.7c0,0.8,0.3,1.5,0.2,2.2l0.1,0
        c-0.1,0.1-0.1,0.2-0.1,0.3c0.2,0.3,0.1,0.7,0.1,1.1c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.3,0-0.4c-0.3-0.5,0-1.2-0.3-1.7
        c-0.3-0.6-0.1-1.4-0.4-2c0-0.1,0-0.2,0.1-0.3c0-0.1-0.1-0.3-0.2-0.4c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.3-0.2,0.7-0.6,0.9
        c-0.2,0.6-0.6,1.1-0.9,1.6c-0.3,0.4-0.4,1-0.9,1.3c0,0.5-0.2,0.8-0.5,1.2c-0.3,0.4-0.4,0.9-0.5,1.4c0,0-0.1,0.1-0.1,0.1
        c-0.1,0.5-0.4,0.9-0.4,1.5c0,0.6-0.4,1-0.4,1.6c0.1-0.1,0.3-0.2,0.4-0.3c-0.1,0.1-0.2,0.2-0.3,0.4c0.2,0.2,0.3,0.3,0.5,0.5l0-0.3
        c0,0.1,0.1,0.2,0.1,0.2l0.2,0l0,0.1l-0.3,0c0.2,0.2,0.3,0.5,0.6,0.5c0.1,0.3,0.4,0.2,0.6,0.3c0,0.2,0.1,0.3,0.1,0.5
        c0.1,0,0.2-0.1,0.3-0.2c0,0.1,0.1,0.3,0.1,0.4c-0.1,0-0.3-0.1-0.4-0.1c0,0,0,0.1,0,0.1c0.1,0,0.3,0.1,0.4,0.1c0.3,0,0.6,0.2,0.5,0.5
        c0.2,0.2,0.4,0.1,0.6,0.1c0.3,0.2,0.3,0.7,0.7,0.6c0,0.2,0,0.3,0.1,0.4l-0.1-0.3c0.1,0,0.2,0,0.3,0c-0.1,0.2,0,0.4,0.3,0.3
        c0,0.1,0.1,0.2,0.2,0.3l-0.1,0.1c0.1,0,0.3-0.1,0.3-0.1l0,0.2c0.5,0.2,1,0.4,1.2,1c0.2,0,0.3,0,0.5,0c0.2,0.2,0.1,0.3-0.2,0.3
        c0.1,0.3,0.4-0.1,0.6-0.1c0,0.1-0.1,0.4-0.1,0.5c0-0.1,0.1-0.2,0.2-0.3c0.1,0.1,0.3,0.2,0.5,0.2l0,0.1l0.2,0l0,0.2
        c0.1-0.1,0.3-0.2,0.4-0.3c-0.1,0.1-0.2,0.2-0.3,0.3c0.3,0,0.4,0.1,0.4,0.4c0.1,0,0.2,0,0.3-0.1c0,0.1,0,0.2-0.1,0.3
        c0.2,0,0.3,0,0.5,0.1c0,0.1-0.1,0.2-0.1,0.3c0.1,0,0.3-0.1,0.3-0.2c0,0.1,0,0.2,0,0.3c0.3,0,0.5,0,0.7,0.2c-0.4-0.7-0.2-1.5-0.4-2.2
        c-0.1-0.4,0.3-0.9,0-1.2l0.2-0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1-0.3-0.2-0.8-0.5-0.4c0.1-0.2,0.2-0.4,0.3-0.6l-0.2,0c0,0,0,0,0-0.1
        c0.2-0.1,0.4-0.3,0.6-0.5c-0.4-0.2,0-0.8-0.5-1c0.2-0.3,0.1-0.6,0.1-0.9c0.1-0.2,0.1-0.4,0.2-0.6c-0.4-0.2,0.1-0.7,0.4-0.7
        c-0.1-0.4-0.4-0.1-0.5,0.1c0-0.2-0.1-0.4-0.1-0.6c0.1,0,0.3-0.1,0.4-0.1c0-0.3-0.2-0.5-0.4-0.7c0.1-0.3,0.1-0.7,0.1-1
        c-0.5-0.3,0.4-0.3,0.2-0.6c-0.1,0-0.2-0.1-0.3-0.1c0-0.2,0.1-0.5,0.4-0.5c-0.1-0.1-0.2-0.2-0.2-0.3l0.1-0.2c-0.4,0-0.2-0.4-0.2-0.6
        c0.1-0.4-0.2-0.7-0.1-1c0.3-0.2,0-0.5-0.1-0.7c-0.1,0.1-0.2,0.2-0.3,0.2c0-0.2,0.1-0.4,0.1-0.6c0.2-0.3,0-0.6,0.1-0.8l0,0
        c-0.1,0.1-0.2,0.2-0.2,0.2c-0.3-0.3-0.3-0.7-0.1-1.1c-0.3,0-0.4,0.1-0.4,0.3c0-0.1-0.1-0.1-0.1-0.2c0.1-0.3-0.2-0.6-0.3-0.9
        c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.1-0.4c0,0.1-0.1,0.3-0.1,0.4c-0.2,0-0.8-0.4-0.3-0.4c0-0.4-0.5-0.6-0.6-1
        c-0.3-0.1-0.5-0.2-0.6-0.5c0.1,0,0.3,0,0.3,0c-0.2,0-0.6,0-0.5-0.4c-0.1,0-0.2-0.1-0.3-0.1c0.1,0,0.3,0,0.4,0
        c-0.1-0.3-0.3-0.4-0.5-0.5C211.7,245.6,211,246.1,210.6,246.9 M192.9,245.8c0.3,0,0.2-0.7-0.1-0.7
        C192.5,245.3,193,245.5,192.9,245.8 M284.8,245.2c-0.1,0.2-0.5,0.3-0.4,0.6C284.6,245.9,285,245.3,284.8,245.2 M321.9,245.1
        c-0.1,0.2-0.3,0.5-0.1,0.7c0.2,0,0-0.3,0.1-0.4C322.1,245.3,322.1,245,321.9,245.1 M307.4,246c0.2,0,0.4-0.4,0.2-0.4
        C307.4,245.5,307.2,245.8,307.4,246 M314,245.7c-0.1,0.2,0,0.3,0.2,0.2C314.3,245.7,314.2,245.6,314,245.7 M163,245.9L163,245.9
        l0.1,0.2l0.1-0.1l0-0.1C163.1,245.8,163,245.9,163,245.9 M193.4,246C193.5,246.1,193.5,246.1,193.4,246 M321.3,246
        C321.3,246.1,321.3,246.1,321.3,246 M315.7,246.6C315.8,246.7,315.8,246.7,315.7,246.6 M194.3,246.8
        C194.4,246.8,194.4,246.8,194.3,246.8 M307.9,246.8c-0.2,0.2,0.1,0.4,0.3,0.3C308.3,246.9,308,246.6,307.9,246.8 M320.4,246.8
        C320.5,246.8,320.5,246.8,320.4,246.8 M388.9,246.8C389,246.8,389,246.8,388.9,246.8 M388.2,247.1c0.1,0,0.5,0,0.5-0.2
        C388.5,246.7,388.1,246.8,388.2,247.1 M194.3,247.4c0.2-0.1,0.2-0.2,0-0.3C194.1,247.2,194.1,247.3,194.3,247.4 M320.5,247.4
        c0.2-0.1,0.2-0.2,0-0.3C320.3,247.2,320.3,247.3,320.5,247.4 M229.3,247.8c-0.1,0.1,0.1,0.5,0.3,0.3
        C229.7,248,229.4,247.7,229.3,247.8 M308.3,247.9c0,0.1,0.1,0.2,0.1,0.3c0.2,0,0.5,0.2,0.7,0.3c0-0.1-0.1-0.2-0.1-0.3
        C308.7,248.2,308.5,248.1,308.3,247.9 M361.4,248.3C361.4,248.4,361.4,248.4,361.4,248.3 M196,248.7c-0.1,0.2,0,0.3,0.2,0.3
        C196.3,248.7,196.2,248.6,196,248.7 M309.1,248.7c0,0.1,0.1,0.3,0.1,0.5l0.2,0c0,0.1,0,0.3,0,0.4c0.1,0,0.2,0.1,0.2,0.2
        c-0.1-0.3-0.2-0.6-0.3-1C309.2,248.7,309.1,248.7,309.1,248.7 M124.1,249.7c0.1,0.2,0.4,0.1,0.4-0.1
        C124.4,249.3,124,249.5,124.1,249.7 M170.4,249.4C170.1,249.8,170.9,249.5,170.4,249.4 M310.6,249.5c0,0.2,0.4,0,0.3-0.2
        C310.8,249.4,310.7,249.5,310.6,249.5 M317.9,249.4C317.9,249.5,317.9,249.5,317.9,249.4 M312.6,249.6
        C312.6,250.1,313,249.4,312.6,249.6 M171.5,249.8c0,0.2,0,0.3,0.1,0.4C172,250.2,171.7,249.7,171.5,249.8 M197.2,249.8
        C197.3,249.8,197.3,249.8,197.2,249.8 M228.8,249.8c-0.2,0.1,0,0.5,0.2,0.4C229.1,250,229,249.7,228.8,249.8 M127.5,249.9
        c-0.1,0.2,0,0.3,0.2,0.3C127.8,249.9,127.7,249.8,127.5,249.9 M122.1,250.2c0.1,0.3,0.5,0.4,0.7,0.6c-0.1-0.1-0.1-0.3-0.2-0.4
        C122.5,250.3,122.3,250.2,122.1,250.2 M124.2,250.5c0.1,0.4,0.6,0.2,0.6-0.1C124.6,250.4,124.4,250.5,124.2,250.5 M125.3,251
        c0.1-0.1,0.3-0.2,0.4-0.4c-0.2-0.1-0.4-0.1-0.6-0.2C124.9,250.7,125.3,250.9,125.3,251 M119.5,250.7
        C119.3,251.2,119.9,250.7,119.5,250.7 M119.9,250.9c-0.1,0.2,0.3,0.4,0.5,0.3C120.4,250.9,120.1,250.8,119.9,250.9 M173.7,250.9
        c0,0.1-0.1,0.3-0.2,0.3c0.1,0.1,0.3,0.2,0.4,0.3l-0.1-0.3c0.2,0,0.4,0.1,0.6,0.2c-0.1-0.1-0.2-0.3-0.2-0.4
        C174.1,251.2,173.5,251.2,173.7,250.9 M228.1,251.3C228.2,251.4,228.2,251.4,228.1,251.3 M186.1,252.4c0.4,0.2,0.1,0.5,0,0.7
        c0.2-0.2,0.4-0.3,0.6-0.1c0.2-0.1,0.3-0.3,0.2-0.6l-0.3,0l0,0.3c0-0.1-0.1-0.3-0.1-0.4C186.5,252.3,186.2,252.4,186.1,252.4
        M18,252.8c0.1,0,0.2-0.1,0.3-0.2C18.3,252.4,17.9,252.7,18,252.8 M228.5,252.9c-0.1,0.1,0,0.4,0.2,0.3
        C228.8,253.1,228.7,252.8,228.5,252.9 M200.6,253.1C200.7,253.2,200.7,253.2,200.6,253.1 M177.6,253.6c0.2,0.1,0.3,0,0.3-0.2
        C177.6,253.3,177.5,253.4,177.6,253.6 M284.6,253.5c-0.1,0.1-0.3,0.3-0.2,0.5C284.6,254,284.9,253.5,284.6,253.5 M499,254
        C499.1,254.1,499.1,254.1,499,254 M262.1,254.1C261.8,254.6,262.6,254.1,262.1,254.1 M283.7,254c0,0.3,0,0.5-0.3,0.7l0.1,0
        c0.1,0,0.2,0.1,0.3,0.1l0.1-0.2c0.1,0.1,0.4,0.3,0.1,0.5c0.2-0.1,0.4,0,0.5,0.2c-0.2-0.2-0.2-0.9-0.7-0.7
        C283.9,254.3,283.8,254.2,283.7,254 M178.5,254.3c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0,0.4,0,0.6,0c-0.2-0.1-0.4-0.2-0.6-0.4
        C178.7,254.3,178.5,254.3,178.5,254.3 M15.7,254.5C15.7,254.6,15.7,254.6,15.7,254.5 M499.6,254.5
        C499.6,254.5,499.6,254.5,499.6,254.5 M283.3,254.5C283.4,254.6,283.4,254.6,283.3,254.5 M215.9,255c-0.1,0.1-0.1,0.4,0.1,0.5
        C216.2,255.4,216.1,255,215.9,255 M14.8,255.3C14.6,255.7,15.3,255.3,14.8,255.3 M80,255.3C80,255.8,80.4,255.1,80,255.3
        M284.2,255.6c-0.2,0.1-0.1,0.5,0.1,0.5C284.5,256,284.4,255.4,284.2,255.6 M15,255.7L15,255.7C15,255.8,15,255.7,15,255.7L15,255.7
        M14.7,256C14.8,256.1,14.8,256.1,14.7,256 M14.3,256.4C14.4,256.5,14.4,256.5,14.3,256.4 M500.9,256.4
        C501,256.5,501,256.5,500.9,256.4 M13.9,256.9C14,257,14,257,13.9,256.9 M135.9,257.3c-0.1,0.2,0,0.3,0.2,0.3
        C136.2,257.3,136.2,257.2,135.9,257.3 M182.3,257.4C182.1,257.9,182.8,257.4,182.3,257.4 M100.6,257.7c-0.2,0.1-0.2,0.2-0.1,0.3
        C100.7,257.9,100.8,257.8,100.6,257.7 M148.2,257.9c0,0.3,0.5,0,0.1-0.1L148.2,257.9 M137.7,258.1c-0.1,0.2,0,0.3,0.2,0.3
        C138,258.1,137.9,258.1,137.7,258.1 M139.5,258.5c-0.1,0.2,0,0.3,0.2,0.2C139.9,258.5,139.8,258.5,139.5,258.5 M203.9,258.5
        c-0.1,0.2-0.1,0.3,0.1,0.3C204.1,258.7,204.1,258.6,203.9,258.5 M313.6,258.7C313.6,259.2,314,258.5,313.6,258.7 M153.9,259
        c0,0.1,0.1,0.2,0.1,0.3C154.2,259.3,154.1,258.9,153.9,259 M313.6,259.6l0.1,0c0,0.3,0.2,0.1,0.3,0.1c-0.1-0.1-0.2-0.3-0.3-0.4
        C313.7,259.4,313.6,259.5,313.6,259.6 M203.6,259.7C203.7,259.8,203.7,259.8,203.6,259.7 M194,260.4c0,0.2,0,0.3,0.2,0.2
        C194.2,260.4,194.2,260.3,194,260.4 M86.2,260.7C86.3,260.8,86.3,260.8,86.2,260.7 M429,260.7C429.1,260.8,429.1,260.8,429,260.7
        M75,260.9C74.7,261.3,75.4,260.9,75,260.9 M194.6,261c-0.1,0.1,0.1,0.4,0.2,0.3C194.9,261.2,194.7,260.9,194.6,261 M105.1,261.5
        c0.1,0.1,0.3,0.2,0.4,0.2c0.2-0.2,0.4-0.5,0.4-0.8C105.7,261.2,105.3,261.2,105.1,261.5 M73.3,261.6c0.2-0.1,0.2-0.2,0-0.3
        C73.1,261.4,73.1,261.5,73.3,261.6 M442,261.4C441.8,261.8,442.4,261.4,442,261.4 M314.3,262.1c0.2,0,0.2-0.4,0-0.4
        C314,261.7,314.1,262,314.3,262.1 M203,262c0,0,0.1,0.1,0.2,0.1C203.6,262,203.1,261.6,203,262 M284.3,262c-0.2,0.1,0.1,0.4,0.2,0.3
        C284.7,262.1,284.5,261.8,284.3,262 M211.2,262.1C211.2,262.6,211.6,261.8,211.2,262.1 M298.8,262.5c0.1,0.1,0.2,0.2,0.3,0.2
        c-0.2,0-0.4,0-0.5,0c0.3,0.5-0.2,0.5-0.5,0.7c-0.1,0-0.2-0.1-0.2-0.1c0.1,0.4-0.4,0.4-0.6,0.6c-0.2,0.2-0.4,0.3-0.5,0.5
        c0,0.1,0.1,0.2,0.2,0.1l0.1,0c0.1,0.3-0.4,0.1-0.5,0.3l0,0c0-0.1,0-0.3,0-0.4c-0.3,0.1-0.4,0.4-0.2,0.6c-0.1,0-0.2-0.1-0.2-0.2
        c-0.2,0.3-0.7,0.3-0.7,0.7c-0.2,0-0.3,0.1-0.5,0.1c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.1-0.5,0.1-0.6,0.3c-0.5,0.5-1.2,0.9-1.8,1.4
        c-0.3,0.2-0.8,0.3-0.9,0.7c-0.5,0.1-0.8,0.6-1.3,0.8c-0.6,0.3-1.2,0.6-1.8,1c-0.3,0.2-0.5,0.5-0.6,0.8c-0.1-0.1-0.2-0.2-0.2-0.3
        c-0.1,0.2-0.1,0.4,0,0.5l-0.3,0c0,0.4,0,0.8,0,1.2c0.2-0.1,0.3-0.2,0.5-0.3c0.3-0.4,0.8-0.5,1.2-0.8c0.6-0.2,1-0.9,1.7-0.9
        c0.2-0.4,0.5-0.7,0.9-0.9c0.6-0.3,1-0.7,1.5-1c0.6-0.2,0.9-0.8,1.5-1c0-0.7,1-0.8,1.2-1.4c0.1-0.2,0.4-0.1,0.6-0.1
        c0-0.2,0-0.4,0.2-0.5c0.5-0.4,1.1-0.8,1.7-1c0.2-0.4,0.7-0.6,1.1-0.8c0.5-0.3-0.1-0.8,0-1.2C299.2,262.5,298.9,262.1,298.8,262.5
        M211.5,262.4C211.6,262.5,211.6,262.5,211.5,262.4 M374.4,262.7c-0.2,0.2-0.3,0.6-0.7,0.6c0.2,0.4-0.4,0.4-0.5,0.7
        c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0.3-0.2,0.6-0.5,0.8c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0.3-0.4,0.6-0.7,0.7c0,0.5-0.8,0.4-0.7,0.9
        c0,0.2-0.3,0.3-0.4,0.4c-0.5,0.2-0.6,0.9-1.2,1.1c-0.1,0.2-0.1,0.4-0.2,0.6c-0.2,0-0.3,0-0.5,0c-0.1,0.2-0.2,0.4-0.3,0.6
        c-0.8,0.3-0.7,1.4-1.5,1.8c0,0.6-0.6,0.7-0.8,1.1c-0.2,0.4-0.5,0.7-0.8,1.1c-0.4,0.6-0.7,1.5-1.3,2c-0.1,0.4-0.1,0.9-0.4,1.1
        c-0.5,0.4-0.3,1.2-0.9,1.5c0.1,0.5-0.3,0.8-0.4,1.3c-0.1,0.6-0.6,1-0.6,1.7c0,0.5-0.5,0.7-0.5,1.1c0,0.5-0.3,0.9-0.5,1.3
        c-0.3,0.7-0.7,1.3-1,1.9c-0.1,0.4-0.1,0.8-0.1,1.2c-0.3,0.2-0.1,0.6-0.1,0.9c-0.3,0.3-0.2,0.8-0.4,1.1c-0.1,0.3,0,0.6-0.2,0.8
        c0,0.2,0,0.4,0,0.5c-0.2,0.4-0.3,0.7-0.4,1.1l0.2,0c-0.2,0.5-0.1,1.1-0.4,1.5c0,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.3,0.3,0.4
        c-0.5,0.1-0.5-0.3-0.5-0.7l0,0.1l-0.2-0.1c0,0.1,0.1,0.3,0.1,0.5l-0.2,0c0-0.1,0-0.3,0-0.4l-0.2,0c0,0,0-0.1,0-0.2
        c-0.2-0.1-0.3-0.3-0.4-0.6c-0.1-0.3-0.4-0.5-0.7-0.7l0-0.1c-0.7-0.3-1.2-1.1-1.2-1.9c-0.1,0.1-0.2,0.2-0.3,0.3l-0.1,0
        c0.1-0.5,0.4-1,0.1-1.4c0,0.1,0,0.2,0,0.3c0,0-0.1,0.1-0.1,0.1c0.3,0.2-0.1,0.6-0.2,0.8c0-0.2-0.2-0.3-0.4-0.4
        c0.1,0,0.3,0.1,0.4,0.2l0-0.4c-0.1,0.1-0.9-0.2-0.3-0.1l-0.1-0.5c-0.1,0.1-0.2,0.2-0.3,0.3c0-0.1-0.1-0.3-0.1-0.4l0-0.1l0-0.1l0.1,0
        c0.1-0.1,0-0.4,0.2-0.5c0-0.2,0-0.3-0.1-0.4c0,0.1,0,0.4,0,0.5c-0.1,0-0.3,0-0.4-0.1c0-0.5,0.5-1,0.1-1.5c-0.1,0.3-0.1,0.6-0.1,0.9
        c-0.1,0-0.2,0-0.2,0c0-0.3,0.1-0.6,0.1-0.9c0.3-0.3-0.1-0.5-0.4-0.6c0.1-0.1,0.2-0.2,0.2-0.2c-0.3-0.2-0.2-0.5,0-0.7l-0.2,0
        c0-0.1,0.1-0.4,0.1-0.5c-0.1,0-0.2-0.1-0.2-0.2c0.2-0.2,0.2-0.5,0-0.7l0.4-0.1c0,0.1,0.1,0.4,0.1,0.6c0.1-0.1,0.2-0.3,0.2-0.3
        c-0.1,0-0.2-0.1-0.3-0.1c0-0.2,0.2-0.3,0.3-0.4c-0.3-0.1-0.5,0.2-0.8,0.3c0-0.2,0-0.4,0.1-0.6c0-0.1,0-0.3,0-0.4
        c-0.1-0.2,0.2-0.6-0.2-0.4c0.1-0.1,0.2-0.3,0.2-0.4c0-0.2-0.1-0.3-0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.5c-0.1,0.1-0.3,0.2-0.4,0.3
        c0.1-0.2,0.2-0.5,0.3-0.7l-0.3,0.1c0.2-0.2,0.3-0.4,0.4-0.6l-0.3,0c-0.1-0.2-0.2-0.3-0.2-0.5c0.1-0.3,0.2-0.6,0.1-0.9
        c0.1-0.1,0.2-0.3,0.2-0.5c0.1,0,0.2,0,0.2-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0.1-0.2,0.4-0.3,0.5c-0.1-0.3-0.2-0.5-0.3-0.8
        c0.1,0,0.2,0,0.3-0.1c-0.1-0.1-0.1-0.2-0.2-0.3c0,0.1,0,0.2,0,0.3c-0.2-0.1-0.4-0.2-0.7-0.3c-0.2-0.1-0.3-0.3-0.5-0.3
        c0.1,0.2,0.1,0.3,0.2,0.5c-0.2-0.1-0.3-0.4-0.5-0.3c-0.3,0.2-0.6,0.3-1,0.4c-0.3,0.2-0.6,0.3-0.9,0.5c-0.2,0.1-0.4,0.3-0.7,0.2
        c0,0,0,0.1,0,0.2c-0.2-0.2-0.8,0.4-0.5-0.1c-0.1,0-0.4,0.1-0.6,0.1c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.2,0,0.3,0
        c0-0.2,0-0.3-0.1-0.5l0.1,0c0.1-0.1,0.2-0.3,0.2-0.4c-0.2,0.1-0.3,0.2-0.5,0.2c0.2-0.2,0.1-0.4,0.2-0.7c0.1,0.1,0.2,0.3,0.3,0.4
        c0-0.1,0.1-0.4,0.1-0.5c0.2-0.1,0.3,0.1,0.5,0.2c0.1-0.1,0.2-0.3,0.2-0.4c-0.1,0-0.3-0.1-0.4-0.1c0.3-0.2,0.6-0.3,0.9-0.4
        c0.4-0.1,0.7-0.4,1.1-0.3c0-0.2-0.1-0.3-0.1-0.4c-0.1,0-0.4,0-0.5,0c0.1-0.1,0.2-0.2,0.3-0.1c0.3,0.2,0.6,0.1,0.9,0.1
        c0-0.1,0.1-0.3,0.2-0.4c0,0.1,0,0.3,0,0.5c0.2-0.2,0.4-0.3,0.6-0.3c0,0.1,0,0.3,0,0.5c0.2-0.2,0.3-0.3,0.5-0.4c0,0.1,0,0.3,0,0.3
        c0.2-0.1,0.5-0.2,0.7-0.1c-0.1,0.1-0.2,0.2-0.3,0.3c0.2,0.1,0.5,0,0.6,0.2c0.4,0.5,0.8,0.9,1.3,1.4c0.1,0.3,0.1,0.5,0.1,0.8
        c0.1,0,0.2,0.1,0.3,0.2c0.1,0.4,0,0.9,0.2,1.3c0,0.1-0.1,0.3-0.2,0.4c0.1,0.2,0.1,0.4,0.2,0.6c0,0.2-0.2,0.5,0,0.7
        c-0.1,0.4-0.1,0.7-0.2,1.1c0.3,0.7,0.1,1.4,0.2,2.1c0.1,0.3-0.2,0.8,0.1,1c0.1,0.5,0.3,1,0,1.5c0.4,0.1,0.2,0.5,0.3,0.8
        c0.2,0.4,0.5,0.9,0.2,1.3c0.1,0,0.2,0,0.2,0.1c0,0.5,0.4,1,0.3,1.6c0.1,0,0.2,0,0.2,0c0,0.2,0.1,0.3,0.2,0.5
        c0.1,0.4,0.3,0.8,0.5,1.2c0,0.3,0.1,0.4,0.4,0.4c0-0.4,0-0.8-0.2-1.2l-0.1,0c0-0.2-0.1-0.5-0.2-0.7c0.1,0,0.3,0.1,0.4,0.2
        c-0.2-0.3-0.4-0.5-0.2-0.9l-0.2,0.1c0-0.1,0.1-0.3,0.1-0.5c-0.1,0-0.2-0.1-0.3-0.2l0.3,0l0-0.3l-0.2,0c0-0.3,0-0.5,0-0.8
        c-0.1,0-0.3,0-0.4,0c0.1,0,0.3-0.1,0.4-0.1c-0.1-0.1-0.2-0.3-0.2-0.4c0.1-0.2,0.3-0.3,0.4-0.5c-0.1,0-0.3,0-0.4,0
        c0-0.1,0.1-0.4,0.1-0.5c-0.3,0.5-0.7-0.2-0.1-0.1c-0.1-0.1-0.2-0.1-0.3-0.2c0-0.1,0-0.3,0-0.4l0.1,0l-0.1-0.2c0.1,0,0.3,0,0.4,0.1
        c0,0,0-0.1,0-0.2c-0.1,0-0.2,0.1-0.3,0.1c0.2-0.3,0.1-0.6-0.1-0.9c0.2-0.3,0.4-0.6,0-0.8c0,0.1,0,0.4,0,0.5c-0.2-0.1-0.1-0.3,0-0.5
        c-0.1-0.1-0.6-0.5-0.2-0.4c0,0,0.1,0.2,0.2,0.2c0.2,0.1,0.5,0.3,0.5-0.1c-0.1,0-0.3,0-0.4,0c0-0.1,0-0.2,0-0.2
        c-0.1-0.1-0.2-0.2-0.3-0.3c0.1-0.1,0.5,0.1,0.5-0.1c-0.2-0.1-0.5-0.2-0.3-0.4c0.2-0.2,0.2-0.5,0-0.8c-0.1,0-0.2,0.1-0.3,0.1
        c0-0.1,0.1-0.3,0.1-0.4l0.2,0.1c0.1-0.2,0.2-0.4,0.2-0.6c-0.1-0.2-0.1-0.4-0.2-0.5c-0.1,0-0.1,0.1-0.1,0.2c0.2,0.2,0.1,0.4-0.1,0.6
        c-0.1-0.1-0.2-0.2-0.3-0.3c0.2-0.3,0.1-0.5-0.1-0.7c0-0.2-0.1-0.5-0.1-0.7c0.1,0.1,0.2,0.2,0.4,0.3c0,0.2,0.1,0.3,0.1,0.5
        c0-0.3,0.4-0.3,0.6-0.5c-0.1,0-0.4,0-0.5,0.1c-0.1-0.2-0.2-0.4-0.3-0.6c0.3-0.1,0.3-0.4,0.3-0.7c-0.1,0.2-0.2,0.3-0.3,0.5
        c-0.1-0.1-0.2-0.2-0.2-0.2c0.3-0.3-0.3-0.3-0.4-0.5c0.2-0.1,0.5-0.2,0.6-0.4c-0.4-0.1-0.6-0.4-0.7-0.7c0.3,0,0.4,0.3,0.5,0.5
        c0.2-0.2,0.1-0.3-0.1-0.4c-0.2-0.1,0-0.4,0-0.6c-0.1-0.1-0.3-0.3-0.4-0.4c-0.1,0-0.2,0-0.2,0c0-0.1-0.1-0.3-0.2-0.3
        c-0.1-0.3,0.2-0.3,0.5-0.3c-0.1-0.1-0.2-0.2-0.3-0.2c0.1-0.1,0.1-0.2,0.2-0.3c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1,0-0.2,0.1-0.3,0.1
        c0.1-0.1,0.1-0.3,0.2-0.4c-0.2-0.2-0.5,0-0.5-0.3c0.1,0,0.3,0.1,0.5,0.1c-0.1-0.4-0.6-0.5-0.8-0.8c0.1,0,0.3,0,0.4,0
        c-0.1-0.1-0.2-0.4-0.4-0.2c-0.1,0-0.2,0.1-0.3,0.1c0-0.1,0.1-0.3,0.1-0.4c-0.5-0.2-1-0.3-1.5-0.3c-0.6,0.1-1.1-0.5-1.7-0.4
        c-0.1-0.2-0.2-0.3-0.4-0.3c0,0.1,0.1,0.4,0.1,0.6c-0.2-0.1-0.4-0.3-0.5,0c-0.2,0.1-0.4,0.3-0.4,0.6c-0.1,0-0.2-0.1-0.3-0.2
        c-0.1,0.1-0.1,0.2-0.1,0.4c-0.3-0.1-0.5,0-0.6,0.2l0.4,0c-0.1,0-0.3,0-0.4,0.1c0,0.1,0,0.4,0,0.5c-0.4-0.1-0.6,0.2-0.8,0.5l0-0.3
        c-0.2,0.2-0.5,0.3-0.7,0.6c0.1,0,0.4,0,0.5-0.1c-0.2,0.3-0.5,0.2-0.8,0.2c-0.1,0.1-0.2,0.3-0.2,0.5c-0.1,0-0.2,0.1-0.2,0.2
        c-0.2,0.2-0.4,0.4-0.6,0.5c0.1-0.3,0.3-0.5,0.6-0.7c0-0.1-0.1-0.3-0.1-0.4c-0.2,0.1-0.3,0.3-0.4,0.5l-0.2,0c-0.2,0.5-0.6,0.8-1,1.2
        c-0.2,0-0.4,0-0.5,0l0,0.3c0.1,0,0.3,0,0.5,0c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2-0.2-0.6-0.3-0.7,0.1c0.1,0,0.3,0,0.4,0
        c-0.1,0.2-0.4,0.1-0.6,0.1c-0.2,0.4-0.7,0.6-1,1c-0.4,0.3-0.7,0.7-1.2,0.7c0,0.2-0.1,0.3-0.3,0.5c-0.1,0.2-0.3,0.3-0.5,0.2
        c-0.1,0.2-0.2,0.4-0.3,0.6c-0.3,0.2-0.5,0.3-0.8,0.5c0.1,0.4-0.4,0.5-0.7,0.6c0,0.2-0.1,0.3-0.3,0.3c0,0,0.1,0.1,0.1,0.2
        c-0.1,0-0.2,0.1-0.3,0.2c-0.2,0.3-0.5,0.6-0.7,0.9c-0.2,0.2-0.4,0.5-0.7,0.3c0.1,0.1,0.1,0.3,0.2,0.4c-0.5-0.2-0.4,0.4-0.8,0.5
        c-0.4,0.1-0.3,0.7-0.7,0.9c0,0.1-0.1,0.2-0.1,0.3c-0.4,0-0.3,0.4-0.5,0.6c-0.4,0.2-0.5,0.8-1,0.8c0,0.1,0,0.3,0,0.4
        c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.3-0.2,0.7-0.6,0.9c0.1,0.5-0.4,0.5-0.7,0.8c0,0.1,0,0.3-0.1,0.4c-0.1,0-0.3,0-0.3,0.1
        c0,0.1,0.1,0.3,0.2,0.4c-0.1,0-0.3-0.1-0.5-0.1c0,0.1,0.1,0.2,0.2,0.3c-0.1,0-0.3,0.1-0.4,0.1c0,0.1-0.1,0.2-0.1,0.3
        c-0.2,0.2-0.7,0.5-0.4,0.8c-0.4,0.2-0.5,0.7-0.9,1c0,0.1,0,0.3,0,0.4c-0.3,0.2-0.5,0.5-0.7,0.9c0,0.1,0,0.2,0,0.3
        c-0.4,0.3-0.7,0.7-0.8,1.2c-0.2,0.2-0.5,0.5-0.5,0.8c-0.4,0-0.4,0.3-0.4,0.6c-0.1,0-0.3,0-0.4,0c0,0.2,0,0.3,0,0.5
        c-0.4,0.2-0.4,0.7-0.7,1.1c-0.1,0.4-0.3,0.7-0.6,1c-0.3,0.6-0.6,1.2-0.9,1.8c-0.1,0.2-0.2,0.6-0.4,0.7c0.1,0.5,0.1,1,0,1.5
        c0,0.1,0.1,0.3,0.2,0.4c0,0.1-0.1,0.2-0.1,0.3c0.1,0.6,0,1.1,0.1,1.7c0.1,0.6,0,1.2,0.1,1.8c-0.1,0.6,0.2,1.2-0.1,1.8
        c0.3,0.4,0.2,0.9,0.3,1.3c0.2,0.7,0,1.4,0.2,2.1c-0.1,0-0.2,0.1-0.3,0.2c0.1,0.2,0.4,0.4,0.3,0.7c-0.1,0.4,0,0.7,0,1.1
        c0.2,0.6,0.4,1.1,0.5,1.7c0.1,0.6,0.4,1.2,0.2,1.9c0.4,0.5,0.5,1.1,0.5,1.7c0,0.5,0.2,0.9,0.1,1.4c0.4,0.3,0.3,0.7,0.4,1.1
        c0.1,0,0.2,0.1,0.3,0.1c0.2,0.3,0.5,0.5,0.8,0.7c0.5,0.4,1.2,0.4,1.7,0.8c0.2,0.2,0.4,0.1,0.6,0.1c0.6,0,1.2,0.4,1.8,0.4
        c0.5,0.3,1.1,0.2,1.5,0.5c0.8-0.2,1.6,0.4,2.4,0.3c0.3,0.2,0.7,0.2,1,0c0.2,0,0.4,0.1,0.5,0.1c0.5-0.1,1.1,0.1,1.6,0
        c0.3,0.1,0.5,0.3,0.9,0.1c0.7,0.1,1.5,0.1,2.2,0c0.6,0,1.1,0,1.7,0c0.1,0,0.3,0,0.5,0c0.3,0.1,0.7,0.3,1,0c-0.1-0.1-0.3-0.1-0.5-0.1
        c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0.1,0.3,0.2,0.4,0.2c0.5,0,0.9,0,1.4,0c0.2-0.1,0.2-0.3,0.3-0.5c0,0.1,0.1,0.2,0.1,0.3
        c0.4-0.1,0.7-0.1,1.1-0.1c0,0,0.1-0.1,0.1-0.2c0.3,0,0.6,0.1,0.9-0.1c0,0.4,0.3,0,0.4-0.1c0.2-0.4,0.4,0.3,0.4-0.1
        c0.3,0,0.7,0,0.8-0.4c0,0.1-0.1,0.4-0.2,0.5c0.2-0.2,0.7-0.2,0.7-0.6c0,0,0.1,0.1,0.1,0.2c0.2,0.1,0.5,0.2,0.7,0.2
        c-0.1-0.1-0.2-0.3-0.3-0.4c0,0,0.1,0,0.2,0c0.1,0.4,0.6,0.1,0.9,0.1c0.4,0.2,0.9,0,1.3,0c0.5,0.3,1,0,1.5,0.1
        c0.1,0.1,0.2,0.1,0.3,0.2c0.3-0.1,0.5-0.3,0.8-0.1c0.7,0.3,1.5,0,2.1,0.5c0.1,0,0.3,0,0.3,0c0.9,0.5,1.9,0.9,2.6,1.7
        c0.4,0.1,0.8,0.2,1.1,0.6c0.3,0,0.5,0.3,0.7,0.5c0.1-0.5,0.3,0,0.5-0.1c0.1,0,0.2-0.1,0.2-0.2c-0.2,0-0.5,0-0.7,0
        c0-0.3,0.4-0.3,0.6-0.3c0.1-0.2,0.2-0.3,0.5-0.3l0-0.2c0,0-0.1,0-0.2-0.1c0.2,0,0.4,0,0.6-0.1c0-0.1,0-0.2,0-0.3l0.1,0
        c0,0.1,0,0.3,0,0.3c0.4-0.1,0.2-0.4,0.2-0.7c0.2,0,0.3,0,0.5,0c0.1-0.1,0.2-0.2,0.3-0.2c0-0.2,0-0.3,0.1-0.4c0,0.1,0,0.3,0,0.4h0.2
        c-0.1-0.6,0.8-0.5,0.8-1.1c0.1,0,0.3,0.1,0.4,0.1l0.2,0c-0.1-0.2-0.2-0.3-0.2-0.5c0.2-0.1,0.4-0.2,0.7-0.2c0-0.1,0.1-0.2,0.1-0.3
        c0.2-0.1,0.4-0.3,0.6-0.3c0.1-0.2,0.2-0.4,0.3-0.6c0,0.2,0,0.4,0.1,0.5c0.2-0.2,0.4-0.3,0.7-0.4c-0.2-0.1-0.3-0.1-0.5,0
        c0-0.4,0.2-0.5,0.6-0.4c0.1-0.2,0.2-0.3,0.4-0.4c-0.1-0.1-0.1-0.3,0-0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.1-0.3,0.2-0.4,0.4-0.4
        c-0.1-0.4,0.2-0.3,0.5-0.3c-0.1-0.3,0.3-0.5,0.5-0.5c0-0.3,0.3-0.3,0.5-0.1c0-0.2,0.1-0.4,0.1-0.6c0.2,0.1,0.5,0.1,0.7,0.2
        c0-0.1-0.1-0.3-0.2-0.3c0.1-0.1,0.2-0.3,0.4-0.4c0.1,0,0.4,0,0.5,0c0,0.1,0,0.2,0,0.3c0.2,0,0.2-0.2,0.4-0.3
        c-0.2-0.1-0.4-0.3-0.5-0.5c0.2,0.1,0.4,0.1,0.7,0.1c-0.1-0.1-0.2-0.2-0.3-0.2c0,0,0-0.1,0-0.2c0.2,0.1,0.6,0.2,0.4,0.6
        c0,0,0.1,0,0.1,0c0-0.2,0-0.5,0-0.7c0.3,0,0.5-0.1,0.8-0.1c-0.1-0.3-0.1-0.6-0.3-0.8c0.1,0.1,0.3,0.3,0.5,0.2c0,0.1,0,0.3,0,0.4
        l0.1-0.1c0.2-0.5-0.3-0.9-0.5-1.3c0.2,0,0.4,0,0.7,0c0,0-0.1-0.1-0.1-0.1c-0.2,0-0.3,0-0.5,0c0,0.1-0.1,0.3-0.1,0.3
        c-0.2-0.4-0.5-0.4-0.9-0.5c0.2,0,0.4,0,0.6,0c0-0.4-0.3-0.3-0.6-0.2c0,0,0-0.1,0-0.2c-0.1-0.1-0.3-0.2-0.5-0.2c0,0,0-0.1,0-0.1
        c0.4,0.2,0-0.3,0.1-0.4l-0.2,0.1c0-0.1,0-0.3,0.1-0.5c-0.3,0.1-0.1,0.4-0.1,0.6c-0.3,0-0.4-0.6-0.7-0.1c0-0.1,0-0.3,0-0.3
        c-0.2,0.1-0.4,0.2-0.5-0.1c0.1,0,0.2,0,0.3,0c0,0,0-0.1,0-0.2c-0.2,0.1-0.4,0.1-0.5,0.2c0-0.1,0-0.3,0-0.4c0,0-0.1,0-0.1,0.1
        c-0.3,0.4-0.6,0-0.9-0.2l0,0.3l-0.3-0.2c0.1-0.2,0.2-0.3,0.4-0.5c-0.2,0.1-0.5,0.3-0.7,0.4l0-0.2c-0.4,0-0.7-0.1-1.1,0
        c0-0.1,0.1-0.2,0.1-0.2c-0.3-0.1-0.5,0-0.7,0.1c0.1-0.5-0.3-0.1-0.5,0c0-0.4-0.3-0.4-0.4-0.1l0.3,0.1l0,0.1
        c-0.2-0.1-0.5-0.2-0.7-0.2c-0.1,0-0.2,0-0.2,0.1c-0.2,0-0.4,0-0.5,0c0-0.1,0-0.3,0-0.5c0,0.2-0.1,0.4-0.3,0.4l0.1,0.2
        c-0.1-0.1-0.3-0.2-0.4-0.2l0-0.1c-0.2,0-0.3-0.1-0.5-0.1c0.2,0.1,0.4,0.2,0.4,0.5c-0.2-0.1-0.4-0.3-0.6-0.4
        c-0.1,0.1-0.2,0.3-0.3,0.4c0-0.2,0.1-0.5,0.1-0.6c0,0-0.1,0.1-0.2,0.2c-0.2,0.3-0.7-0.1-0.8,0.3c-0.2-0.3-0.6-0.2-0.9-0.3
        c-0.2,0.1-0.4,0.1-0.5,0.2l0-0.2c-0.2,0-0.6-0.2-0.6,0.2c-0.2,0-0.4,0.1-0.6,0.1c0-0.1,0.1-0.3,0.1-0.4c-0.4,0-0.4,0.4-0.7,0.4
        l0-0.2c-0.2,0-0.3,0-0.5,0l0.3,0.1l0,0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0.1-0.3,0.2-0.4,0.3c0-0.1,0-0.3,0-0.4
        c-0.1,0.1-0.3,0.2-0.4,0.3l0.1,0.2c0,0-0.1,0-0.2,0l0-0.2c-0.3,0-0.6,0.1-0.9,0.1c0-0.1,0.1-0.2,0.1-0.3c-0.4,0.1-0.7,0.4-1.1,0.4
        c0-0.1,0-0.2,0.1-0.3l-0.2-0.1c-0.1,0.1-0.3,0.2-0.4,0.4c-0.2,0-0.3,0-0.4,0.1c0.1-0.1,0.1-0.3,0.2-0.4c-0.1,0-0.4-0.1-0.5-0.2
        c0.2,0.2,0.1,0.4-0.1,0.5c0-0.1,0-0.3,0-0.4c-0.1,0-0.3,0-0.4,0c0,0.1,0,0.2,0,0.3c0-0.1,0-0.2,0-0.3c-0.1,0-0.2,0-0.3,0.1
        c0-0.1,0-0.4,0-0.5c-0.1,0.2-0.3,0.3-0.4,0.5c-0.3-0.2-0.6-0.5-1-0.7c0.1,0.2,0.3,0.4,0.4,0.6c-0.3-0.1-0.5-0.2-0.8-0.3
        c0,0.1-0.1,0.2-0.1,0.3c0-0.2,0-0.4-0.2-0.5c0,0.1,0,0.3,0,0.5c-0.2-0.1-0.3-0.2-0.4-0.4l0,0.4c-0.1-0.1-0.3-0.4-0.3-0.5
        c-0.1,0.2-0.5,0.1-0.7,0.1c-0.1,0.1-0.2,0.2-0.2,0.2c0-0.1,0.1-0.3,0.2-0.4c-0.2,0-0.5,0.1-0.6,0.2c0-0.2-0.1-0.3-0.2-0.4
        c-0.5,0.3-1-0.3-1.5-0.3l0-0.2c-0.4,0.2-0.7,0-1-0.1l0.2-0.2c-0.4-0.1-0.8-0.2-1.2-0.4c0-0.1,0-0.2,0.1-0.3c-0.2,0-0.4,0-0.6,0
        c0-0.1-0.1-0.2-0.1-0.3c-0.5-0.2-0.9-0.7-1.3-0.9c-0.3-0.1-0.5-0.5-0.7-0.8c-0.8-0.6-1.1-1.6-1.6-2.5l0.2,0
        c-0.5-0.6-0.5-1.4-0.7-2.1c-0.3-0.4-0.1-1-0.3-1.4c-0.1-0.6-0.1-1.3,0-2l-0.1,0c0.1-0.3,0.1-0.7,0.2-1.1c-0.1,0.3,0.1,0.7-0.2,1
        l0.2,0c-0.1,1,0.3,1.9,0.4,2.9l0.1,0c0,0.2,0.1,0.4,0.1,0.6l0.2,0c0,0.1,0,0.2,0,0.3l0.2,0c0,0.1,0,0.2,0,0.3l0.2,0
        c0,0.1,0,0.4,0,0.5c0.1,0,0.2,0,0.3-0.1c0,0.1,0.1,0.3,0.2,0.3l0.2-0.1c0,0.1-0.1,0.2-0.1,0.4l0.3,0c-0.1,0.1-0.2,0.2-0.2,0.3
        c0.1,0,0.3-0.1,0.4-0.2l-0.1,0.3c0.2-0.1,0.4,0,0.4,0.2c-0.1,0-0.2,0-0.3,0l0,0.1c0.1,0,0.4-0.1,0.5-0.1c0.1,0.2,0.2,0.5,0.4,0.6
        c0-0.1,0-0.3,0-0.5c0,0.2,0.1,0.3,0.1,0.5c0.4-0.1,0.6,0.4,0.8,0.6c0.1,0,0.2-0.1,0.4-0.1c0,0.1-0.1,0.3-0.1,0.4
        c0.1,0,0.4-0.1,0.5-0.2c0,0.1,0,0.3,0,0.4c0.2-0.1,0.4-0.1,0.7-0.2c-0.1,0.2-0.3,0.3-0.3,0.5c0.1-0.1,0.3-0.2,0.4-0.3
        c0,0.1,0,0.4,0,0.6c0.2-0.2,0.2-0.4,0.1-0.6c0.1,0.1,0.2,0.3,0.4,0.5c0.1,0,0.4-0.1,0.5-0.1c0,0.1,0,0.3,0,0.4c0.5-0.2,1,0,1.2,0.4
        c0-0.1,0-0.3,0-0.4c0.4-0.1,0.5,0.3,0.9,0.4c-0.1-0.1-0.2-0.3-0.3-0.4c0.5,0.2,1,0.4,1.5,0.4c0.4-0.1,0.8,0.1,1.2-0.2
        c0,0.1,0,0.3-0.1,0.4c0.1,0,0.3,0,0.4,0c-0.1-0.1-0.2-0.3-0.3-0.4c0.2,0,0.4,0,0.6,0.1c0.2-0.3,0.7-0.1,1-0.1l-0.1-0.2
        c0.1,0,0.4,0.1,0.5,0.1c0-0.1-0.1-0.3-0.1-0.4c0,0.1,0.1,0.3,0.1,0.4c0.3-0.4,0.7,0,0.9-0.3c0.3-0.3,0.7-0.1,0.9-0.4
        c0.1,0,0.2,0,0.3,0.1c0.3-0.2,0.6-0.3,0.8-0.6c0.3,0.1,0.1,0.4-0.1,0.5c0.3-0.2,0.5-0.4,0.7-0.7c-0.1-0.1-0.2-0.2-0.2-0.3
        c0.1,0.1,0.2,0.2,0.4,0.4c0.1-0.4,0.6-0.7,1-0.4c-0.1-0.3,0.1-0.5,0.3-0.6c0.3,0.1,0.7-0.1,0.8-0.5c0.3-0.1,0.6-0.2,0.5-0.5
        c0.1,0,0.3,0.1,0.4,0.2c-0.1,0.1-0.2,0.3-0.3,0.4c0.2-0.1,0.4-0.3,0.5-0.6c0.1,0,0.2,0,0.3,0c0-0.5,0.5-0.4,0.6-0.8
        c0.2-0.1,0.3-0.1,0.5-0.2c0.1-0.2,0.3-0.3,0.4-0.5c0.3,0,0.4-0.6,0.8-0.4c-0.2-0.5,0.6-0.1,0.5-0.5c-0.2-0.3,0.2-0.3,0.4-0.5
        c0.7-0.8,1.3-1.6,1.9-2.4c1.1-1.6,1.9-3.3,2.9-4.9c0.2-0.4,0.3-0.9,0.8-1c0.4-0.4,1-0.5,1.4-0.8c0.2-0.4-0.1-0.8,0.1-1.1
        c0.2-0.5,0.2-1.1,0.3-1.6c0-0.4-0.2-0.7-0.2-1.1c0-0.8-0.1-1.6-0.1-2.4c-0.2-0.7-0.1-1.3-0.2-2c0.2-0.6-0.2-1.1,0-1.7
        c-0.2-0.3-0.1-0.7,0-1c-0.1-0.2-0.2-0.5-0.1-0.7c0.1-0.4,0-0.8,0.1-1.3c0.1-0.9,0.1-1.7,0.3-2.6c0-0.1-0.1-0.2-0.2-0.3
        c0.1-0.7,0-1.3,0.2-2c-0.1-0.5,0.4-0.9,0.1-1.4c-0.2-0.4,0-0.9,0-1.3c0.3-0.5,0-1.1,0.2-1.5c0.2-0.3-0.3-0.6-0.1-0.9
        c0.2-0.4,0.4-0.9,0-1.3c-0.2-0.3,0-0.5,0.2-0.8c0-0.1-0.1-0.2-0.2-0.3c0.1-0.3,0.2-0.6,0.3-0.9c-0.2-0.4-0.3-0.8,0.1-1.1
        c-0.4-0.5-0.4-1.3-0.2-1.9c-0.2-0.3-0.1-0.7,0-1c0.1-0.4-0.2-0.8,0.1-1.1c0-0.1-0.1-0.2-0.2-0.3c0-0.4,0-0.9,0.1-1.3
        C374.9,262.4,374.6,262.6,374.4,262.7 M215.6,262.7c-0.1,0.2,0,0.2,0.2,0.2C215.9,262.7,215.8,262.6,215.6,262.7 M298.4,262.8
        c-0.2,0.1-0.2,0.2,0,0.3C298.5,263,298.5,262.9,298.4,262.8 M228.1,263C228.2,263.1,228.2,263.1,228.1,263 M106.1,263.4
        C106.2,263.5,106.2,263.5,106.1,263.4 M94.9,264.2c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.2-0.3,0.3-0.4
        C95.5,263.7,95.1,263.9,94.9,264.2 M10.3,264C10.4,264,10.4,264,10.3,264 M108.5,263.9C108.6,264,108.6,264,108.5,263.9 M335.6,264
        C335.7,264,335.7,264,335.6,264 M406.7,263.9C406.8,264,406.8,264,406.7,263.9 M505,263.9C505,264,505,264,505,263.9 M442.9,264.3
        c0,0-0.1,0.1-0.1,0.2c0.2,0.2,0.3,0.4,0.5,0.6c0-0.1-0.1-0.4-0.1-0.6C443.1,264.4,443,264.3,442.9,264.3 M158,264.4
        C157.8,264.8,158.5,264.4,158,264.4 M94.9,264.5C94.9,264.6,94.9,264.6,94.9,264.5 M159,264.6c0.1,0.2,0.2,0.2,0.3,0
        C159.2,264.4,159.1,264.4,159,264.6 M100.7,264.8C100.8,264.9,100.8,264.9,100.7,264.8 M109.8,264.9c0.1,0.2,0.2,0.2,0.3,0
        C110,264.7,109.9,264.7,109.8,264.9 M405.4,265.1c0.2-0.1,0.2-0.2,0-0.3C405.2,264.9,405.2,265,405.4,265.1 M284,266.2
        C284.1,266.3,284.1,266.3,284,266.2 M97.2,266.7c0.1,0,0.3,0,0.3,0.1c0-0.1,0.1-0.2,0.1-0.2C97.7,266.2,97.2,266.6,97.2,266.7
        M197.1,266.4c-0.1,0.2,0.3,0.5,0.4,0.3C197.5,266.5,197.3,266.3,197.1,266.4 M392.5,266.4C392.5,266.5,392.5,266.5,392.5,266.4
        M122.6,266.9c0.2,0,0.4-0.1,0.4-0.3C123,266.3,122.6,266.7,122.6,266.9 M284.3,266.5c-0.2,0.1-0.2,0.3-0.1,0.5
        C284.4,267.1,284.6,266.5,284.3,266.5 M112.5,266.9C112.6,267,112.6,267,112.5,266.9 M165,267.3c0.1,0.3,0.5,0,0.3-0.2
        C165.2,266.8,164.9,267.1,165,267.3 M165.5,267C165.3,267.5,166,267.1,165.5,267 M202.1,267.1C201.9,267.5,202.6,267.1,202.1,267.1
        M283.9,268l0-0.4c0.1,0.1,0.2,0.3,0.4,0.4c0-0.1,0-0.2,0-0.3c-0.2-0.1-0.3-0.2-0.5-0.3C283.5,267.6,283.7,267.9,283.9,268
        M299.9,267.5c-0.3,0-0.6,0.1-0.8,0.3c-0.1,0-0.2,0.1-0.3,0.1c0.1,0.1,0.2,0.3,0.2,0.4c-0.1-0.1-0.1-0.2-0.2-0.3l-0.2,0.1
        c-0.3,0-0.3,0.4-0.4,0.6c-0.3,0-0.1-0.3,0.1-0.4c-0.1,0-0.2,0-0.3-0.1c0,0.3-0.4,0.3-0.6,0.3c0.2,0.2,0.3,0.5-0.1,0.4
        c0-0.1,0.1-0.3,0.1-0.4c-0.4,0.1-0.5,0.5-0.7,0.8c0-0.1-0.1-0.2-0.2-0.2c-0.2,0.3-0.4,0.5-0.7,0.6c-0.5,0.3-0.8,0.7-1.2,1.1
        c-0.2,0.2-0.5,0.5-0.7,0.2c0.1,0.1,0.2,0.1,0.3,0.2c-0.2,0.3-0.5,0.4-0.9,0.4c0.3,0.2,0.2,0.4,0,0.6c-0.1,0-0.2-0.1-0.3-0.1
        c0,0.5-0.5,0.8-0.7,1.2c0,0.1-0.1,0.3-0.2,0.4c-0.2,0.1-0.3,0.5-0.6,0.6c-0.4,0.7-0.9,1.3-1.2,2.1c-0.7,1.2-1,2.6-1.7,3.8
        c0,0.9-0.6,1.8-0.6,2.7c0,0.2,0,0.5,0,0.7c0.2,0.3,0.1,0.7,0,1.1c0.3,0.4,0.1,0.9,0.2,1.4c0.1,0.3-0.1,0.7,0,1
        c0.1,0.4,0,0.8-0.1,1.2c0.2,0.5,0.1,1,0.1,1.5c0.3,0.5,0.1,1.1,0,1.6c0.1,0,0.2-0.1,0.4-0.1c0.2,0.2,0.3,0.4,0.6,0.4
        c-0.2,0.2-0.4,0.2-0.6,0.2c0.2,0.1,0.5,0.2,0.6,0.5c0.1-0.1,0.1-0.2,0.2-0.2l-0.3-0.1c0.1-0.1,0.2-0.2,0.2-0.3
        c0.1,0.1,0.2,0.3,0.2,0.5c0.3,0.1,0.5,0.3,0.8,0.4c0,0.1-0.1,0.2-0.1,0.3c-0.2,0-0.4,0-0.5,0c-0.1,0.1-0.3,0.3-0.2,0.4
        c0.2-0.2,0.5-0.4,0.9-0.4l0,0.2l0.3,0c-0.1,0.2-0.2,0.3-0.1,0.5c0.1-0.1,0.2-0.2,0.2-0.2l0,0.3c0.1,0,0.3,0,0.4,0
        c0.1,0.3-0.4,0.2-0.6,0.3c0.1,0,0.3,0,0.4,0c0,0.2,0.1,0.3,0.3,0.2l0,0.2l0.3-0.1c0,0.1,0,0.4,0,0.5c0.2-0.2,0.3-0.4,0.5-0.4l0,0.2
        c0.2,0,0.3,0,0.5,0c0-0.1,0-0.2,0-0.3c-0.1,0-0.3,0-0.4,0.1c0.1-0.6,0.6,0.3,0.8-0.2l-0.2-0.2c0.1,0,0.2,0.1,0.3,0.2
        c0.1,0,0.4,0,0.5,0c0-0.1,0-0.3,0-0.3c0.2,0.1,0.3,0.2,0.5,0.3c-0.1-0.2-0.1-0.4-0.2-0.6c0.1,0,0.3,0,0.5,0.1
        c-0.1-0.2-0.2-0.4-0.4-0.6c0.1,0,0.3,0,0.4,0l0-0.2l0,0c0,0.2,0,0.5,0.1,0.7c0.3,0,0.3-0.3,0.4-0.5c-0.1-0.3,0.2-0.6,0.3-0.8
        c0,0.1,0.1,0.3,0.1,0.4c-0.1,0-0.2,0.1-0.3,0.1l0,0.2c0.1-0.1,0.7,0,0.6-0.2c-0.3-0.3,0.2-0.7,0.5-0.5c-0.1-0.2-0.1-0.4,0.1-0.5
        c0.1,0,0.3,0,0.3-0.1c0-0.1-0.1-0.3-0.1-0.4c0.1,0,0.3,0,0.4-0.1c0-0.1-0.1-0.3-0.2-0.5c0.1,0,0.3,0,0.4,0.1c0-0.1,0-0.3,0-0.4
        c-0.1,0-0.3,0-0.3,0c0.2-0.2,0.1-0.5,0.3-0.6c0.2-0.1,0.2-0.3,0.3-0.5c0.1,0.1,0.2,0.3,0.2,0.5c0.5,0,0.2-0.4,0-0.6
        c0.2-0.5,0.5-0.8,0.7-1.2c0-0.1-0.1-0.2-0.1-0.2l0.1,0l0,0.2c0.2-0.2,0.4-0.4,0.5-0.6c0-0.4,0-0.7,0.2-1c0.2-0.3,0.2-0.6,0.3-0.9
        c-0.2-0.2-0.2-0.5-0.2-0.8c0.3,0.1,0.3,0.4,0.3,0.6c0.2-0.3,0.3-0.6,0.4-1c0.1-0.2,0.2-0.4,0.1-0.6c-0.2-0.1-0.4,0-0.5,0.2
        c0-0.2,0.1-0.4,0.2-0.6c0.1,0.1,0.3,0.3,0.5,0.3c0-0.2,0-0.4,0-0.7c0.1-0.1,0.2-0.2,0.3-0.3c-0.2,0-0.4,0-0.6,0
        c0.2-0.1,0.4-0.3,0.6-0.4c0-0.3,0.1-0.5,0.1-0.8c-0.2,0.1-0.3,0.3-0.3,0.5c-0.1,0.1-0.2,0.2-0.2,0.4c-0.1-0.1-0.2-0.2-0.2-0.3
        c0.2-0.2,0.5-0.5,0.3-0.8c0,0,0.1,0,0.2,0c0,0.1-0.1,0.3-0.1,0.4c0.1,0,0.2-0.1,0.3-0.2c0,0,0.1,0.1,0.2,0.2c0-0.3,0-0.5-0.3-0.5
        c0.3-0.4,0.4-0.8,0.4-1.3c-0.2-0.2,0.1-0.5,0.1-0.7c-0.2,0-0.3,0-0.5,0c0.1-0.2,0.3-0.3,0.4-0.5c0,0.1,0,0.3,0,0.4l0.1,0
        c0-0.2,0-0.5,0-0.7c0.1,0,0.2-0.1,0.3-0.1c0-0.2,0-0.3,0-0.5c-0.3,0-0.2-0.5-0.3-0.7c0.1,0,0.3,0.1,0.4,0.1
        c-0.2-0.2-0.5-0.4-0.7-0.6c0.1,0,0.2,0,0.2-0.1c0-0.1,0-0.2,0-0.3l0.2,0c0.1,0.1,0.2,0.2,0.2,0.2c-0.1-0.3-0.2-0.5-0.3-0.7
        c-0.1,0.1-0.2,0.2-0.2,0.3c0,0-0.1-0.1-0.2-0.2c0.2-0.2,0.8-0.2,0.6-0.5c0-0.1,0-0.2,0.1-0.3c-0.2-0.1-0.4-0.3-0.4-0.6
        c0.1,0.1,0.2,0.2,0.3,0.2c0.3-0.2-0.2-0.3-0.3-0.4c0.1,0,0.3,0,0.4,0c-0.1-0.2-0.3-0.3-0.5-0.5c0.3,0.1,0.5,0.2,0.8,0.2
        c-0.3-0.3-0.7-0.4-0.8-0.8c0.2,0,0.4-0.1,0.4-0.3c-0.2-0.1-0.4-0.2-0.5-0.4c0-0.1,0-0.2,0-0.3l0.2,0l0-0.3c0.1,0.1,0.1,0.2,0.2,0.4
        l-0.2,0c0.1,0.1,0.4,0.3,0.5,0.4c0-0.2,0-0.4,0-0.6c-0.2-0.1-0.3-0.4-0.4-0.7l0.3,0.2c-0.1-0.3-0.1-0.6,0-0.9c0,0,0.1-0.1,0.1-0.2
        c0-0.1-0.1-0.3-0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.3c-0.3,0.1-0.4,0-0.7-0.1c0.1-0.2,0.2-0.3,0.3-0.4c-0.1,0.2,0,0.3,0.1,0.5
        c0.5-0.3-0.3-0.7,0.1-1c-0.4-0.1-0.2-0.5-0.2-0.8c0-0.3-0.1-0.5-0.1-0.8C300.2,267.4,300.1,267.4,299.9,267.5 M333.5,267.7
        c-0.2,0.1-0.2,0.2,0,0.3C333.7,267.9,333.7,267.8,333.5,267.7 M94.6,268C94.6,268.5,95,267.8,94.6,268 M314.9,268.2L314.9,268.2
        L314.9,268.2L314.9,268.2L314.9,268.2 M333.3,268.2C333.4,268.3,333.4,268.3,333.3,268.2 M168.3,268.6c0,0.1,0.4,0.3,0.4,0.1
        C168.7,268.6,168.4,268.4,168.3,268.6 M289.3,268.7C289.4,268.7,289.4,268.7,289.3,268.7 M61.8,269.1
        C61.8,269.5,62.2,268.9,61.8,269.1 M199.4,270c0.1-0.2,0.1-0.4-0.1-0.5C199.1,269.5,199.3,270,199.4,270 M261.5,269.5
        C261.3,269.9,262,269.6,261.5,269.5 M84.8,269.6C84.9,269.7,84.9,269.7,84.8,269.6 M79.5,270.1c-0.1,0.2,0.2,0.3,0.4,0.2
        C80,270.1,79.6,270,79.5,270.1 M193.2,270.1c-0.2,0.1,0.2,0.6,0.2,0.3C193.3,270.3,193.2,270.2,193.2,270.1 M155.9,271.2
        C155.6,271.6,156.4,271.3,155.9,271.2 M199.5,271.2c-0.1,0.2,0,0.3,0.2,0.2C199.8,271.2,199.7,271.1,199.5,271.2 M459.3,271.9
        C459.1,272.3,459.7,271.9,459.3,271.9 M175.6,272.3C175.6,272.8,176.1,272.1,175.6,272.3 M176,272.6c0.1,0.1,0.3,0.2,0.4,0.3
        c-0.1,0.1-0.2,0.2-0.2,0.2c0.1,0,0.3,0.1,0.4,0.1c0.1-0.2,0.1-0.3,0.2-0.5c-0.3,0.1-0.5-0.1-0.7-0.4
        C176.2,272.4,176.1,272.5,176,272.6 M289.4,272.7c0,0.1,0.1,0.3,0.1,0.4c-0.2,0-0.4-0.1-0.5-0.1c0,0.3-0.1,0.6-0.4,0.7
        c0-0.2,0-0.5,0-0.6c-0.2,0.2-0.2,0.4-0.2,0.7c-0.1,0-0.2,0-0.2-0.1c-0.2,0.2-0.5,0.3-0.4,0.6c0.3-0.1,0.3-0.6,0.7-0.6
        c-0.1,0.3-0.2,0.7-0.6,0.7c0,0.2,0,0.5-0.2,0.6c-0.3,0.3-0.2,0.8,0,1.1c-0.1,0.3-0.1,0.7-0.2,1.1c0.1-0.1,0.3-0.1,0.4-0.2
        c-0.1,0-0.3,0-0.4,0c0-0.2,0.2-0.3,0.4-0.4c-0.3-0.3,0.2-0.7,0.2-1c-0.2,0-0.3,0-0.5,0c0.3-0.2,0.5-0.6,0.8-0.7l0.1,0.2
        c0.1-0.1,0.2-0.1,0.3-0.2c-0.1,0-0.2,0-0.3,0l-0.2-0.3l0.2,0c-0.2-0.6,0.3,0.1,0.6,0c0,0,0-0.1,0-0.1c-0.1,0-0.3,0-0.4,0
        c0.1-0.2,0.2-0.5,0.4-0.7l0-0.2c0,0,0.1,0.1,0.2,0.1c-0.2-0.2,0.1-0.4,0.3-0.3c0,0,0-0.1,0-0.2c0.2-0.1,0.4-0.2,0.5-0.4
        c0-0.1,0-0.3,0.1-0.4C290,272.5,289.7,272.6,289.4,272.7 M459.3,272.6c0,0.3,0.8,0,0.5-0.2C459.6,272.4,459.4,272.5,459.3,272.6
        M194.1,272.6c-0.1,0.2,0,0.3,0.2,0.2C194.4,272.6,194.3,272.6,194.1,272.6 M201.9,272.6c-0.2,0.1-0.2,0.2,0,0.3
        C202.1,272.8,202.1,272.7,201.9,272.6 M460,273.1L460,273.1L460,273.1L460,273.1L460,273.1 M347.9,273.8
        C347.8,274.2,348.3,273.6,347.9,273.8 M180.9,274.8l-0.2-0.4c-0.1,0.1-0.2,0.4-0.3,0.5c0.3,0,0.5,0,0.8-0.2c0,0,0,0.1,0,0.1
        c-0.1,0-0.2,0.1-0.2,0.1c-0.3,0.4,0.2,0.2,0.4,0.2c-0.2,0.4,0.7,1.1,0.6,0.3c0,0.1,0.1,0.3,0.2,0.4c0.1,0,0.2,0,0.2-0.1
        c0,0.1,0,0.3-0.1,0.3c0.2-0.3,0.5,0,0.7-0.2c-0.1-0.3-0.3-0.4-0.6-0.5c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1,0-0.3,0-0.4,0
        c-0.2-0.4-0.6-0.8-1-1.1C180.8,274.3,180.8,274.6,180.9,274.8 M355.1,274.6c0.2,0.1,0.3,0,0.2-0.2
        C355.1,274.3,355,274.4,355.1,274.6 M195.1,274.5C194.9,275,195.6,274.5,195.1,274.5 M287.6,274.6c-0.1,0.1-0.3,0.4-0.1,0.5
        C287.7,275,288,274.5,287.6,274.6 M180.6,275.2C180.7,275.3,180.7,275.3,180.6,275.2 M201.5,275.4c0.2,0.1,0.3,0.1,0.4-0.1
        C201.9,275.1,201.4,275.2,201.5,275.4 M288.2,275.6c0.2,0,0.3-0.1,0.2-0.3C288.2,275.2,288.1,275.3,288.2,275.6 M355.4,275.8
        c0,0.2,0,0.3,0.3,0.3C355.7,275.8,355.6,275.7,355.4,275.8 M407.3,275.8C407.4,275.8,407.4,275.8,407.3,275.8 M110.4,275.9l0,0.1
        C110.7,276.2,110.6,275.7,110.4,275.9 M107.5,276C107.6,276.1,107.6,276.1,107.5,276 M502.1,276C502.2,276.1,502.2,276.1,502.1,276
        M181.9,276.3C181.6,276.7,182.3,276.4,181.9,276.3 M182.3,276.6c0.1,0,0.3,0,0.4,0c0,0.3-0.1,0.6,0.2,0.7c0-0.2,0-0.4,0-0.5
        c0-0.2-0.1-0.3-0.1-0.4C182.5,276.4,182.4,276.5,182.3,276.6 M183.2,276.6c0,0.2,0,0.5,0.2,0.7c0-0.2-0.1-0.4-0.1-0.6
        c-0.1-0.2-0.3-0.3-0.4-0.5C182.8,276.4,183,276.5,183.2,276.6 M202.5,276.6c-0.1,0.2,0,0.3,0.2,0.2
        C202.8,276.6,202.8,276.6,202.5,276.6 M119.5,276.8C119.6,276.8,119.6,276.8,119.5,276.8 M227.9,277c-0.1,0.1,0,0.5,0.2,0.4
        C228.2,277.3,228.1,276.9,227.9,277 M260.9,277C260.7,277.5,261.4,277,260.9,277 M420,277.2c0-0.1-0.1-0.2-0.1-0.3
        C419.6,276.9,419.8,277.4,420,277.2 M183.6,277.1c-0.1,0.2-0.3,0.3-0.5,0.4c0.2-0.1,0.5-0.2,0.7-0.2
        C183.8,277.3,183.7,277.2,183.6,277.1 M202.6,277.4c0,0.2,0.1,0.3,0.3,0.3C203.1,277.5,202.7,277.1,202.6,277.4 M261.4,277.3
        C261.4,277.4,261.4,277.4,261.4,277.3 M46.5,277.6C46.3,278,47,277.6,46.5,277.6 M468.7,277.6C468.4,278,469.1,277.6,468.7,277.6
        M88.2,278.1c0,0.1-0.1,0.2-0.1,0.3C88.4,278.9,88.7,277.8,88.2,278.1 M356.2,278.2c0.1,0.1,0.2,0.2,0.4,0.1
        C356.8,278,356.2,277.9,356.2,278.2 M427,278.5c0.2,0,0.1-0.4,0-0.4C426.7,278,426.8,278.4,427,278.5 M184.9,278.2
        C184.7,278.6,185.4,278.2,184.9,278.2 M341.7,278.2c0,0.1,0,0.2,0,0.2C342.1,278.7,342.1,277.8,341.7,278.2 M227.6,278.8
        c0.2,0.1,0.5-0.1,0.4-0.3C227.7,278.4,227.5,278.6,227.6,278.8 M87.9,278.6c0.1,0.2,0.2,0.2,0.4,0.1
        C88.2,278.5,88.1,278.5,87.9,278.6 M126.4,278.8c-0.2,0.2,0.2,0.4,0.3,0.2C126.9,278.8,126.5,278.6,126.4,278.8 M431.1,279
        c-0.1,0.2,0,0.2,0.2,0.2C431.4,279,431.3,279,431.1,279 M84.4,279.3c0.1,0.2,0.3,0.2,0.4,0C84.7,279.1,84.5,279.1,84.4,279.3
        M430.6,279.2c0,0-0.1,0.1-0.2,0.2C430.6,279.8,431.2,279,430.6,279.2 M127.5,279.4C127.6,279.5,127.6,279.5,127.5,279.4
        M471.7,279.5c-0.2,0.1-0.2,0.2,0,0.3C471.8,279.6,471.8,279.5,471.7,279.5 M129.6,280.1c-0.1,0.3,0.7,0.4,0.6,0
        C130,280.1,129.8,280.1,129.6,280.1 M81.7,280.2c0,0.1,0,0.2,0.1,0.3C82.2,280.6,82,279.9,81.7,280.2 M196.4,280.4
        C196.5,280.5,196.5,280.5,196.4,280.4 M15,280.8c0.2,0,0.3,0,0.2-0.2C15,280.5,14.9,280.6,15,280.8 M500.1,280.5
        c0,0.2,0,0.3,0.2,0.2C500.4,280.6,500.3,280.5,500.1,280.5 M9.8,280.7C9.9,280.8,9.9,280.8,9.8,280.7 M284.3,280.8
        c0,0,0.1,0.1,0.2,0.2C284.9,281,284.4,280.4,284.3,280.8 M505.4,280.7C505.5,280.8,505.5,280.8,505.4,280.7 M15.3,280.8
        C15,281.3,15.7,280.9,15.3,280.8 M132.3,280.9c-0.1,0.3,0.1,0.6,0.3,0.7c0-0.1,0-0.3,0-0.4C132.5,281.1,132.5,280.9,132.3,280.9
        M500,280.8C499.7,281.3,500.4,280.9,500,280.8 M79.2,281C79.3,281.1,79.3,281.1,79.2,281 M353.2,281C353,281.5,353.7,281,353.2,281
        M133,281.2C133.1,281.2,133.1,281.2,133,281.2 M77.4,281.3C77.5,281.4,77.5,281.4,77.4,281.3 M437.8,281.3l0,0.1
        C438.1,281.7,438,281.1,437.8,281.3 M76.8,281.4c-0.1,0.2,0,0.3,0.2,0.2C77.1,281.5,77,281.4,76.8,281.4 M133.4,281.5
        C133.4,281.9,133.9,281.2,133.4,281.5 M133.1,281.7C133.2,282.2,133.5,281.4,133.1,281.7 M227.6,281.8c-0.2,0.2,0.1,0.4,0.3,0.2
        C228.1,281.8,227.7,281.6,227.6,281.8 M437.8,281.7C437.9,281.8,437.9,281.8,437.8,281.7 M439,281.7c-0.1,0.2,0,0.3,0.3,0.3
        C439.3,281.8,439.2,281.7,439,281.7 M90.5,281.9C90.6,282,90.6,282,90.5,281.9 M134.6,282c-0.1,0.2,0,0.3,0.2,0.2
        C134.8,282.1,134.8,282,134.6,282 M310.7,282C310.8,282.1,310.8,282.1,310.7,282 M439.6,282L439.6,282c0,0.1,0.1,0.2,0.1,0.2
        l0.1-0.1C439.8,282.1,439.7,282,439.6,282 M16.4,282.2c-0.2,0.1,0.1,0.4,0.2,0.3C16.8,282.4,16.6,282,16.4,282.2 M498.7,282.2
        c0,0.1-0.1,0.2-0.1,0.2C498.8,282.9,499.2,281.9,498.7,282.2 M197.8,282.4c-0.2,0.1-0.3,0.4-0.1,0.4
        C198.1,282.9,198.2,282.3,197.8,282.4 M310.6,282.4c-0.2,0.2,0.3,0.3,0.3,0.1C310.8,282.5,310.7,282.5,310.6,282.4 M136.6,282.8
        c0.1,0.2,0.5,0,0.4-0.2C136.8,282.4,136.4,282.6,136.6,282.8 M284.4,283c0.3,0.2,0.3,0.5,0.3,0.8c0.1-0.1,0.2-0.3,0.3-0.4
        c-0.1-0.1-0.2-0.3-0.3-0.4c0.1-0.2,0.2-0.3,0.3-0.5C284.7,282.6,284.6,282.8,284.4,283 M442.3,282.6
        C442,283,442.7,282.6,442.3,282.6 M72.1,282.9c-0.1,0.2,0,0.3,0.2,0.2C72.4,282.9,72.3,282.8,72.1,282.9 M443,282.9
        c-0.1,0.2,0,0.3,0.2,0.2C443.3,282.9,443.2,282.8,443,282.9 M71.7,283.1C71.5,283.5,72.2,283.1,71.7,283.1 M136.8,283.1
        c-0.2,0.2,0.3,0.3,0.5,0.3C137.3,283.1,137,282.9,136.8,283.1 M443.5,283.1C443.3,283.5,444,283.1,443.5,283.1 M11.6,283.2
        C11.3,283.5,12,283.2,11.6,283.2 M32.2,283.8c0.2,0.1,0.4-0.2,0.3-0.4C32.3,283.2,32.1,283.6,32.2,283.8 M70.8,283.4
        C70.9,283.9,71.3,283.2,70.8,283.4 M444.3,283.5C444.3,283.9,444.7,283.2,444.3,283.5 M483,283.7c0-0.1-0.1-0.2-0.1-0.3
        C482.6,283.4,482.9,283.8,483,283.7 M17.3,283.6c-0.1,0.2,0,0.3,0.2,0.2C17.6,283.6,17.6,283.5,17.3,283.6 M497.7,283.6
        c-0.1,0.2,0,0.3,0.2,0.2C498,283.6,498,283.5,497.7,283.6 M503.4,283.6C503.5,283.7,503.5,283.7,503.4,283.6 M18,283.8
        C18.1,283.9,18.1,283.9,18,283.8 M137.3,283.7c-0.1,0.3,0.4,0.4,0.6,0.4C137.8,283.9,137.5,283.6,137.3,283.7 M497.3,283.8
        C497.3,283.9,497.3,283.9,497.3,283.8 M69.5,284C69.6,284.1,69.6,284.1,69.5,284 M198.4,284C198.5,284.1,198.5,284.1,198.4,284
        M445.4,284C445.5,284.1,445.5,284.1,445.4,284 M445.8,284C445.9,284.1,445.9,284.1,445.8,284 M68.9,284.1
        C69,284.2,69,284.2,68.9,284.1 M496.6,284.3c-0.1,0.2,0,0.3,0.2,0.2C496.9,284.3,496.8,284.2,496.6,284.3 M68.2,284.4
        c0,0.2,0,0.3,0.2,0.2C68.4,284.4,68.4,284.3,68.2,284.4 M139.1,284.8c0.1,0.2,0.4,0.6,0.7,0.4c-0.2-0.1-0.4-0.3-0.5-0.5
        c0.1-0.1,0.2-0.2,0.3-0.2C139.4,284.6,139.1,284.6,139.1,284.8 M446.9,284.4c-0.1,0.2,0,0.3,0.2,0.2
        C447.2,284.4,447.1,284.3,446.9,284.4 M309.7,284.8c0.2,0,0.3,0,0.2-0.2C309.7,284.5,309.6,284.6,309.7,284.8 M502.7,284.5
        C502.8,284.6,502.8,284.6,502.7,284.5 M181.9,285.2c0.2,0,0.4,0,0.6,0c0.2-0.1,0.3-0.2,0.4-0.3C182.6,284.8,182.2,284.9,181.9,285.2
        M198.7,284.8c0,0.2,0,0.3,0.2,0.2C198.9,284.9,198.9,284.8,198.7,284.8 M33.9,285.4c0.2,0.4,0.8,0.1,0.9-0.2l0-0.1l0-0.1
        C34.5,285.2,34.3,285.4,33.9,285.4 M181.5,285.6c0.1,0.1,0.4-0.2,0.3-0.3C181.6,285.2,181.4,285.5,181.5,285.6 M15.6,285.4
        c0.3,0.3,0.6,0.8,1.1,0.8C16.4,285.9,16.1,285.5,15.6,285.4 M65,285.4C65.1,285.5,65.1,285.5,65,285.4 M450.2,285.4
        C450.3,285.5,450.3,285.5,450.2,285.4 M459.7,285.4c-0.1,0.1,0,0.4,0.2,0.3C460.1,285.6,459.9,285.4,459.7,285.4 M498.5,286.2
        c0.5,0,0.9-0.5,1.1-0.8C499.2,285.6,498.9,285.9,498.5,286.2 M180.9,285.8c-0.1,0.2-0.1,0.3-0.2,0.4c0.3,0.1,0.9,0,1,0.4
        c-0.1,0-0.3,0-0.5,0c-0.1,0.2-0.2,0.4,0.1,0.4c-0.1-0.3,0-0.4,0.3-0.3c0.2,0.1,0.3,0.2,0.5,0.3c0.1,0.1,0.2,0.2,0.3,0.3
        c-0.2-0.4-0.7-0.8-0.3-1.2c0.1,0.2,0.3,0.3,0.5,0.5c0.1,0.1,0.3,0.2,0.4,0c-0.1,0-0.3-0.1-0.4-0.2c0.2-0.2,0.5-0.3,0.1-0.5
        c0-0.1,0.1-0.2,0.1-0.3c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.4,0-0.5,0l0,0.2c0.1,0,0.3,0.1,0.4,0.1c-0.1,0-0.4-0.1-0.5-0.1
        c0,0.1-0.1,0.2-0.1,0.2c-0.2-0.2-0.4-0.1-0.6,0c0.1-0.2,0.2-0.3,0.2-0.5C181.1,285.6,181,285.7,180.9,285.8 M203.4,285.8
        c-0.2,0.1,0,0.5,0.2,0.3C203.8,285.9,203.6,285.6,203.4,285.8 M309.4,286.1c0.2,0,0.3,0,0.5,0c0-0.1,0-0.3,0-0.4
        C309.7,285.7,309.6,285.9,309.4,286.1 M356.9,285.7c-0.2,0.1-0.2,0.2,0,0.3C357.1,285.9,357.1,285.8,356.9,285.7 M63.8,285.8
        c-0.1,0.2,0,0.3,0.2,0.2C64,285.8,63.9,285.8,63.8,285.8 M451.4,285.9c-0.1,0.2,0,0.3,0.2,0.2C451.6,285.8,451.5,285.8,451.4,285.9
        M141.1,286.1c0.1,0.2,0.2,0.4,0.3,0.7c0.1,0,0.3,0,0.4,0.1c0.1-0.2,0.2-0.3,0.4-0.5c-0.2,0.1-0.4,0.2-0.6,0.2
        C141.5,286.5,141.4,286.2,141.1,286.1 M309.1,286.3c-0.2,0-0.3,0.1-0.5,0.3C308.7,286.9,309.5,286.5,309.1,286.3 M453.5,286.6
        C453.6,287.1,453.9,286.4,453.5,286.6 M309.1,287C309.2,287.1,309.2,287.1,309.1,287 M496.1,287.3l0-0.2c-0.1,0-0.3,0.1-0.4,0.1
        c0.2,0.1,0.4,0.2,0.6,0.2c0.3-0.1,0.7-0.1,1-0.4C496.9,287,496.5,287.4,496.1,287.3 M59.6,287.2c-0.3,0.1,0,0.5,0.2,0.3
        C60.2,287.4,59.8,287.1,59.6,287.2 M29.7,287.6c0-0.1,0-0.2,0-0.3C29.4,287.2,29.4,287.7,29.7,287.6 M455.4,287.3
        c-0.1,0.2,0.2,0.3,0.4,0.2C455.9,287.2,455.5,287.1,455.4,287.3 M153.7,287.6c-0.2,0.2,0.2,0.2,0.3,0.2c0,0,0.1,0,0.2,0
        c0,0,0.1-0.1,0.1-0.2C154.1,287.5,153.9,287.5,153.7,287.6 M495.2,288c0.2,0,0.5-0.2,0.7-0.3C495.7,287.4,495.2,287.7,495.2,288
        M495.1,287.5c-0.2,0.3-0.6,0.2-0.9,0.2c0,0.1,0.1,0.2,0.1,0.2c-0.3,0-0.5,0.1-0.6,0.4c0-0.1,0-0.2,0.1-0.3c-0.1,0-0.4-0.1-0.6-0.1
        c-0.1,0.1-0.2,0.2-0.2,0.3c-0.3,0-0.8-0.1-0.9,0.3c0.2,0,0.3,0,0.5,0c-0.4-0.1-0.6,0.2-0.8,0.6c0.3-0.1,0.5-0.2,0.8-0.3
        c0.2,0,0.5,0,0.7-0.1c0.3-0.1,0.8-0.1,1-0.4c0.3-0.1,0.6-0.2,0.9-0.4C495.2,287.8,495.1,287.6,495.1,287.5 M56.9,287.8
        c-0.1,0.2,0,0.3,0.2,0.3C57.2,287.8,57.1,287.7,56.9,287.8 M143.1,288c0.1,0.1,0.4,0,0.3-0.2C143.3,287.7,142.9,287.8,143.1,288
        M227.5,287.9C227.3,288.3,228,287.8,227.5,287.9 M458.2,287.8c-0.1,0.2,0,0.3,0.2,0.2C458.5,287.8,458.4,287.7,458.2,287.8
        M493.2,287.8L493.2,287.8L493.2,287.8L493.2,287.8L493.2,287.8 M499.1,288C498.8,288.4,499.6,288.1,499.1,288 M203.9,288.1
        c-0.1,0.1,0.1,0.4,0.2,0.3C204.3,288.3,204.1,288,203.9,288.1 M326.1,288.1c-0.1,0.2,0,0.3,0.2,0.2
        C326.4,288.1,326.3,288,326.1,288.1 M16.5,288.4C16.6,288.5,16.6,288.5,16.5,288.4 M227.6,288.5
        C227.5,288.6,227.5,288.6,227.6,288.5C227.7,288.4,227.7,288.4,227.6,288.5 M308.5,288.4c-0.1,0.1,0,0.5,0.2,0.4
        C308.8,288.6,308.7,288.2,308.5,288.4 M17,288.5l0.1,0.2c-0.1,0-0.2,0.1-0.3,0.1c0.1,0.1,0.2,0.3,0.3,0.3c0.2,0,0.3,0,0.5,0.1
        C16.9,289.2,17.2,288.7,17,288.5 M297.9,288.8c0.2-0.1,0.2-0.2,0-0.3C297.7,288.6,297.7,288.7,297.9,288.8 M497.9,289.2
        c0,0,0.1,0.1,0.2,0.2c0.1-0.2,0.7-0.6,0.2-0.6l0.1-0.2C498.1,288.7,498.4,289.2,497.9,289.2 M204,289.3c0.1,0.2,0.4,0.1,0.4-0.1
        C204.5,288.8,203.9,289,204,289.3 M53.5,289.1c-0.3,0.2,0.1,0.5,0.3,0.3C54,289.2,53.7,288.9,53.5,289.1 M461.5,289.1
        c-0.1,0.2,0.1,0.5,0.3,0.4C462,289.3,461.7,288.9,461.5,289.1 M227.5,289.4c-0.1,0.2-0.1,0.3,0.1,0.3
        C227.8,289.6,227.7,289.5,227.5,289.4 M145.5,289.7c0.1,0.2,0.4,0.4,0.2,0.7c-0.2,0.1,0,0.3,0.2,0.3
        C145.8,290.3,146,289.8,145.5,289.7 M296.8,289.9c0,0.2,0,0.4-0.1,0.6c0.1,0,0.2,0,0.3,0.1c0-0.1-0.1-0.3-0.1-0.4
        c0.3-0.1,0-0.3-0.1-0.4C296.9,289.8,296.9,289.9,296.8,289.9 M496.2,289.9C496.2,290.3,496.6,289.6,496.2,289.9 M19.5,290.1
        C19.6,290.2,19.6,290.2,19.5,290.1 M50.8,290.1C50.9,290.2,50.9,290.2,50.8,290.1 M464.4,290.1C464.5,290.2,464.5,290.2,464.4,290.1
        M495.7,290.1C495.8,290.2,495.8,290.2,495.7,290.1 M227.4,290.3C227.5,290.3,227.5,290.3,227.4,290.3 M205.2,290.4
        C205.3,290.9,205.6,290.1,205.2,290.4 M46.6,291c0.3,0.2,0.5,0.1,0.8-0.1l0-0.2c-0.2,0.1-0.4,0.2-0.5,0.3c0-0.1,0-0.3,0-0.4
        C46.8,290.8,46.7,290.9,46.6,291 M45.1,290.8c-0.1,0.2,0,0.3,0.2,0.2C45.4,290.8,45.3,290.7,45.1,290.8 M469.9,290.9
        c0.1,0.2,0.2,0.2,0.4,0C470.1,290.7,470,290.7,469.9,290.9 M307.4,291.3c0.1,0.1,0.5,0,0.3-0.2C307.6,290.9,307.2,291.1,307.4,291.3
        M471.2,291.4C471.1,291.9,471.6,291.2,471.2,291.4 M43.3,291.6c-0.1,0.2,0,0.3,0.2,0.2C43.6,291.6,43.5,291.5,43.3,291.6
        M471.8,291.6c-0.1,0.2,0,0.3,0.2,0.2C472.1,291.6,472,291.5,471.8,291.6 M148.5,292.1c-0.2,0.2,0.2,0.4,0.3,0.2
        C148.9,292.1,148.6,291.9,148.5,292.1 M289.6,292.4c-0.1,0.1,0.1,0.4,0.2,0.3C289.9,292.6,289.7,292.3,289.6,292.4 M149.1,292.8
        c-0.1,0.2,0,0.3,0.2,0.2C149.4,292.8,149.3,292.8,149.1,292.8 M156.2,292.9c0.1,0.2,0.2,0.2,0.4,0
        C156.5,292.7,156.3,292.7,156.2,292.9 M148.6,293.6c0.2-0.1,0.4-0.6,0-0.5C148.3,293.1,148.7,293.5,148.6,293.6 M206.5,293.3
        C206.6,293.4,206.6,293.4,206.5,293.3 M289,294c-0.1,0.2,0,0.3,0.3,0.2C289.3,294,289.2,293.9,289,294 M157.1,294.5
        c0.1,0.2,0.2,0.2,0.3,0C157.3,294.3,157.2,294.3,157.1,294.5 M157.7,295.4c0.2,0.1,0.3,0.1,0.5,0c0-0.1-0.3-0.2-0.3-0.3
        C157.7,295,157.5,295.3,157.7,295.4 M430.7,295.5c0,0.2,0.2,0.3,0.4,0.3C431.1,295.6,430.9,295.4,430.7,295.5 M206.3,295.9
        c0.1,0,0.2,0,0.2,0C206.8,295.6,206.1,295.6,206.3,295.9 M435.4,295.7C435.5,295.7,435.5,295.7,435.4,295.7 M151.6,295.8
        C151.6,295.9,151.6,295.9,151.6,295.8 M158.3,295.8C158.4,295.9,158.4,295.9,158.3,295.8 M439.2,295.8
        C439.3,295.9,439.3,295.9,439.2,295.8 M72.4,296C72.4,296,72.4,296,72.4,296 M81,295.9c-0.2,0.2,0.3,0.3,0.4,0.2
        C81.6,295.9,81.2,295.8,81,295.9 M444.4,296.3c-0.4,0.2,0.1,0.3,0.3,0.1C444.9,296.1,444.5,296.1,444.4,296.3 M68,296.5L68,296.5
        L68,296.5L68,296.5L68,296.5 M68.3,296.7C68.4,296.7,68.4,296.7,68.3,296.7 M424.9,296.6L424.9,296.6L424.9,296.6L424.9,296.6
        L424.9,296.6 M447.8,296.8c0,0.1-0.1,0.2-0.1,0.4c0.2-0.1,0.5-0.3,0.5-0.5C448.1,296.7,447.9,296.8,447.8,296.8 M424.3,296.8
        c-0.1,0.2,0,0.3,0.2,0.3C424.6,296.8,424.6,296.7,424.3,296.8 M445.4,296.9C445.5,297,445.5,297,445.4,296.9 M88.2,297.1
        c-0.1,0.2,0,0.3,0.2,0.2C88.4,297.1,88.4,297.1,88.2,297.1 M158.7,297.4c0,0,0.1-0.1,0.2-0.2C158.8,296.8,158.3,297.3,158.7,297.4
        M420.6,297.1c0.2,0,0.5,0.3,0.6,0.1C421.1,297,420.7,296.9,420.6,297.1 M449,297.1C449,297.5,449.4,296.8,449,297.1 M449.5,297.1
        C449.2,297.5,450,297.2,449.5,297.1 M63.5,297.3c-0.1,0.2,0,0.3,0.3,0.3C63.8,297.3,63.7,297.3,63.5,297.3 M91.6,297.4
        c-0.4,0.3,0.3,0.2,0.5,0C92.1,297.2,91.7,297.3,91.6,297.4 M152.7,297.4C152.5,297.8,153.2,297.4,152.7,297.4 M63.1,297.5
        C63,298,63.5,297.3,63.1,297.5 M418.5,297.9c0.2,0.1,0.3,0,0.4-0.1C418.7,297.7,418.6,297.7,418.5,297.9 M159.6,298
        c-0.1,0.1,0.1,0.4,0.2,0.3C160,298.1,159.8,297.8,159.6,298 M93.8,298.1c0.1,0.2,0.2,0.2,0.4,0.1C94.1,298,94,298,93.8,298.1
        M200.2,298.2C200,298.6,200.7,298.3,200.2,298.2 M159.7,298.5C159.4,298.9,160.1,298.6,159.7,298.5 M188.1,298.8
        c0,0,0.1,0.1,0.2,0.2c0.2-0.1,0.2-0.3,0-0.4C188.2,298.6,188.1,298.7,188.1,298.8 M275.4,298.8C275.5,298.9,275.5,298.9,275.4,298.8
        M427.4,298.8C427.5,298.9,427.5,298.9,427.4,298.8 M111.3,299.1C111.3,299.5,111.7,298.8,111.3,299.1 M402.8,299.1
        C402.9,299.2,402.9,299.2,402.8,299.1 M49.1,299.2C49.1,299.7,49.5,299,49.1,299.2 M200.3,299.2
        C200.4,299.3,200.4,299.3,200.3,299.2 M275.5,299.2C275.6,299.3,275.6,299.3,275.5,299.2 M455.5,299.2
        C455.5,299.3,455.5,299.3,455.5,299.2 M465,299.2C465.1,299.3,465.1,299.3,465,299.2 M188.6,299.6c0.2-0.1,0.2-0.2,0-0.3
        C188.4,299.4,188.4,299.5,188.6,299.6 M48.4,299.5C48.5,299.6,48.5,299.6,48.4,299.5 M48.8,299.6C48.9,299.6,48.9,299.6,48.8,299.6
        M455.8,299.5C455.8,299.6,455.8,299.6,455.8,299.5 M465.3,299.5C465.4,299.6,465.4,299.6,465.3,299.5 M160.2,300.3
        c0.1,0,0.2,0,0.3,0c0-0.2,0.1-0.4,0.1-0.6C160.4,299.9,160.3,300.1,160.2,300.3 M456.4,300c0.1,0.1,0.4-0.1,0.3-0.3
        C456.5,299.6,456.3,299.8,456.4,300 M56.3,300.3c0-0.1,0-0.2,0-0.2l-0.1-0.1C55.9,300,56.1,300.5,56.3,300.3 M55.7,300.6
        C55.7,300.7,55.7,300.7,55.7,300.6 M419.7,300.7C419.7,300.7,419.7,300.7,419.7,300.7 M94.1,300.9C94.2,301,94.2,301,94.1,300.9
        M200.3,301.3C200.3,301.4,200.3,301.4,200.3,301.3 M115.5,301.5C115.6,301.6,115.6,301.6,115.5,301.5 M424.6,301.5
        C424.7,302,425,301.3,424.6,301.5 M200.5,301.7c0,0.4,0.1,0.8,0.4,1.1c-0.1,0-0.2,0-0.3,0l0-0.2c0,0-0.1,0-0.2,0
        c0.2,0.5-0.1,1,0.1,1.5c0.1-0.1,0.3-0.3,0.4-0.4c-0.1,0-0.3-0.1-0.4-0.1l0-0.2c0.1,0,0.4,0.1,0.5,0.1l-0.4-0.4
        c0.2,0,0.6-0.1,0.5-0.4c-0.1-0.4-0.2-0.8-0.5-1.1L200.5,301.7 M417.9,302.1C418,302.1,418,302.1,417.9,302.1 M469.4,302.2
        C469.5,302.3,469.5,302.3,469.4,302.2 M412.5,302.4C412.6,302.5,412.6,302.5,412.5,302.4 M209.9,302.5c-0.1,0.2,0.3,0.3,0.3,0.1
        C210.1,302.6,210,302.5,209.9,302.5 M277.8,302.5c0.2,0.2,0.3,0.4,0.6,0.6C278.5,302.7,278.1,302.5,277.8,302.5 M417.3,302.5
        C417.3,302.6,417.3,302.6,417.3,302.5 M303.8,302.6C303.8,302.7,303.8,302.7,303.8,302.6 M304.3,302.9
        C304.1,303.3,304.8,302.9,304.3,302.9 M52.9,303.3c0.2,0,0.3,0,0.2-0.3C53,302.9,52.9,303,52.9,303.3 M162.4,303.1
        c-0.2,0.3,0,0.9,0.4,0.7c-0.1-0.2-0.2-0.3-0.3-0.4C162.5,303.4,162.4,303.2,162.4,303.1 M278.6,303.4c-0.3,0.2,0,0.5,0.3,0.5
        C278.9,303.7,278.9,303.2,278.6,303.4 M102,304.1c0.2-0.1,0.3-0.2,0.5-0.3l0,0.2c0.1-0.1,0.4-0.1,0.5-0.2c-0.2-0.2-0.5-0.2-0.7-0.3
        C102.2,303.6,102.1,303.8,102,304.1 M402.3,303.8C402.4,303.8,402.4,303.8,402.3,303.8 M410.7,303.8
        C410.7,304.2,411.1,303.5,410.7,303.8 M100.1,304.4c0.2-0.1,0.4-0.2,0.4-0.4C100.4,303.7,99.8,304.2,100.1,304.4 M393.3,304.1
        C393.4,304.1,393.4,304.1,393.3,304.1 M409.6,304.1C409.6,304.1,409.6,304.1,409.6,304.1 M410.4,304c-0.1,0.2,0,0.3,0.2,0.2
        C410.7,304,410.6,304,410.4,304 M258.9,304.2c-0.1,0.2,0,0.3,0.2,0.2C259.2,304.2,259.1,304.1,258.9,304.2 M304.3,304.3
        c0.2,0.3-0.2,0.5-0.3,0.7c0,0.1,0.1,0.3,0.1,0.5c0-0.2,0.1-0.3,0.1-0.5c0,0,0.1-0.1,0.2-0.2c0-0.2,0-0.4,0.1-0.5L304.3,304.3
        M407.5,304.3C407.5,304.4,407.5,304.4,407.5,304.3 M279.3,304.5c0.1,0.2,0.2,0.4,0.3,0.7C280,305,279.5,304.6,279.3,304.5
        M106.1,305C106.1,305.5,106.6,304.8,106.1,305 M463.9,305.2C463.9,305.2,463.9,305.2,463.9,305.2 M200.4,305.3
        C200.5,305.4,200.5,305.4,200.4,305.3 M397.9,305.3C397.7,305.8,398.4,305.4,397.9,305.3 M105.2,305.6
        C105.3,305.7,105.3,305.7,105.2,305.6 M163.7,305.9c0.2-0.1,0.2-0.3,0-0.4C163.4,305.7,163.4,305.8,163.7,305.9 M464.5,305.6
        C464.6,305.7,464.6,305.7,464.5,305.6 M41.4,306.1C41.5,306.2,41.5,306.2,41.4,306.1 M103.9,306.4C104,306.5,104,306.5,103.9,306.4
        M403.8,306.6c0.1,0.2,0.3,0.2,0.4,0C404,306.4,403.9,306.4,403.8,306.6 M389.4,306.7C389.4,307.2,389.8,306.5,389.4,306.7
        M405.9,306.7c0,0.2,0,0.3,0.2,0.2C406.2,306.8,406.1,306.7,405.9,306.7 M465.7,306.8C465.8,306.8,465.8,306.8,465.7,306.8
        M262.7,306.9C262.7,307,262.7,307,262.7,306.9 M401.9,307C402,307.1,402,307.1,401.9,307 M404.6,307c0.1,0,0.2,0.1,0.2,0.2
        C405.1,307.1,404.7,306.8,404.6,307 M125.6,307.2C125.4,307.7,126.1,307.2,125.6,307.2 M388.5,307.2
        C388.3,307.7,389,307.2,388.5,307.2 M399.7,307.6c0.1,0.3,0.4,0.4,0.6,0.6c0-0.2-0.1-0.4-0.2-0.6C400,307.5,399.9,307.5,399.7,307.6
        M303.6,307.7c-0.1,0.2,0,0.3,0.3,0.3C303.9,307.8,303.8,307.7,303.6,307.7 M126.4,307.9l0,0.1l0.1,0.1l0.1,0c0-0.1,0-0.2,0-0.2
        L126.4,307.9 M240.1,307.9L240.1,307.9L240.1,307.9L240.1,307.9L240.1,307.9 M39.4,308C39.5,308.1,39.5,308.1,39.4,308 M46,308.3
        C45.7,308.8,46.4,308.3,46,308.3 M113.5,308.6C113.6,308.6,113.6,308.6,113.5,308.6 M303.2,308.6C303.2,309,303.6,308.3,303.2,308.6
        M200.4,308.8C200.2,309.2,200.9,308.8,200.4,308.8 M127.8,308.9c-0.1,0.2,0.2,0.4,0.3,0.3C128.2,309,128,308.8,127.8,308.9
        M165.5,309c0.1,0.2,0.2,0.2,0.3,0C165.7,308.8,165.6,308.8,165.5,309 M360.9,308.9C361,309,361,309,360.9,308.9 M386.1,309.1
        c0.2,0.1,0.4-0.1,0.3-0.3C386.3,308.7,386,309,386.1,309.1 M242.6,309.3C242.4,309.7,243.1,309.3,242.6,309.3 M467.6,309.3
        c-0.1,0.2,0.1,0.4,0.2,0.5C468.1,309.7,467.9,309.1,467.6,309.3 M282.3,309.5c0,0.1,0,0.2,0,0.2c0.2,0.1,0.4,0.1,0.7,0.2
        C282.9,309.7,282.7,309.3,282.3,309.5 M165.8,309.6c-0.1,0.2,0.4,0.6,0.5,0.3C166.3,309.7,166,309.5,165.8,309.6 M307.2,309.9
        C307.2,310.4,307.6,309.7,307.2,309.9 M394.6,309.8c0.1,0.1,0.2,0.2,0.2,0.3c-0.4,0.3-0.9,0.4-1.3,0.6c0,0,0,0,0,0
        c0.4,0.1,0.8,0.1,1.2,0.2c0.3,0.3,0.7,0.4,1.1,0.5c-0.2-0.1-0.4-0.3-0.6-0.4c-0.1-0.3-0.4-0.5-0.3-0.9c0.1-0.1,0.2-0.2,0.3-0.3
        c-0.1,0-0.3,0.1-0.4,0.1C394.8,310,394.7,309.9,394.6,309.8 M395.9,310C396,310.1,396,310.1,395.9,310 M282.9,310.3
        c0.2,0.1,0.3,0.1,0.4-0.1C283.1,310.1,283,310.1,282.9,310.3 M123.3,310.6C123.4,310.7,123.4,310.7,123.3,310.6 M166.9,310.7
        c0,0.2,0,0.5,0,0.8c0.1-0.1,0.3-0.2,0.2-0.4L167,311l-0.1-0.2c0.2,0.2,0.7,0.2,0.6-0.2C167.3,310.6,167.1,310.6,166.9,310.7
        M303.2,310.8c0,0.2-0.1,0.5,0.2,0.6c0-0.3,0-0.6,0-0.9C303.2,310.6,303.2,310.7,303.2,310.8 M44.3,311c-0.3,0.3,0.4,0.2,0.1,0
        L44.3,311 M120.5,313.2c0.1,0,0.3-0.1,0.4-0.1c0-0.1,0-0.2-0.1-0.2C120.8,312.9,120.6,313.1,120.5,313.2c0-0.3,0-0.5,0-0.7
        C120.2,312.7,120.3,313,120.5,313.2 M132.8,312.9c-0.1,0.2,0.3,0.4,0.5,0.4C133.3,313,133,312.8,132.8,312.9 M380.9,313.3
        c0.3-0.1,0.5-0.3,0.6-0.5C381.2,312.8,380.9,313,380.9,313.3 M391.9,312.8c-0.1,0.2,0,0.3,0.2,0.2
        C392.2,312.8,392.1,312.8,391.9,312.8 M216,312.8c0,0.1,0,0.4,0.1,0.5c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0-0.3-0.1-0.4-0.1
        c0.2,0.8,0,1.7,0.1,2.5c0.1,0.5-0.2,0.9-0.1,1.4c0.2,0.7-0.1,1.3,0,2c-0.1,0.9,0,1.7,0,2.6c0,0.3-0.1,0.6,0.1,0.9
        c-0.1,0.4,0.1,0.8,0.1,1.3c-0.1,0.8,0.3,1.6,0.1,2.4c0.1,0.5,0.4,1,0.6,1.5c0,0-0.1,0-0.2,0c0.1,0.2,0.1,0.3,0.2,0.5l0.1,0
        c0.5,1.2,1.1,2.4,2,3.4c-0.8-0.4-1.3-1.3-2-1.8c0-0.2-0.1-0.4-0.4-0.4c0-0.1,0.1-0.3,0.1-0.4c-0.2,0.2-0.3,0.4-0.5,0.5
        c0.3,0.2,0.5,0.6,0.3,1c0.2,0.3,0.2,0.7,0.3,1.1l0.1,0c0,0.1,0,0.2-0.1,0.3l0.2,0c0,0.1,0,0.3,0,0.4c0.2,0.2,0.3,0.5,0.2,0.8
        c0.1,0,0.2,0,0.3,0c0,0.3,0,0.5,0.2,0.7l0.1,0c0,0.2,0,0.4,0.1,0.6c0.1,0.3,0.3,0.5,0.3,0.9c0.4-0.2,0.3,0.2,0.3,0.4
        c0,0,0.1,0.1,0.2,0.1c0.1,0.4,0.7,0.8,0.5,1.3c0.2-0.1,0.3,0,0.3,0.3l0.1,0l0,0.2l0.1,0c0.1,0.3,0.4,0.6,0.7,0.7
        c-0.5-0.1-0.9-0.5-1.4-0.7c-0.3-0.4-0.7-0.7-1-1c-0.2-0.3-0.6-0.5-0.5-0.9c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.5-0.4-0.9-0.5-1.3
        c0,0-0.1,0-0.1,0c0,0.4-0.1,0.7,0,1.1c0.1,0,0.2,0.1,0.2,0.2c0,0.5,0.5,0.8,0.7,1.2c0,0.2,0,0.4,0.2,0.5c0.3,0.1,0.2,0.5,0.5,0.6
        c-0.1,0-0.2,0-0.3,0c0.2,0.3,0.6,0.5,0.7,0.9c0,0.2,0.2,0.3,0.4,0.5c0.2,0.5,0.4,1,0.7,1.5c0.1,0.1,0.2,0.3,0.2,0.5
        c0.3,0,0.3,0.3,0.3,0.6c0.3,0.2,0.4,0.6,0.7,0.8c-0.2-0.1-0.3-0.2-0.5-0.2c-0.4-0.3-0.9-0.6-1.2-1c-0.3-0.1-0.5-0.4-0.7-0.6
        c-0.1-0.3-0.4-0.6-0.6-0.8c-0.2-0.3-0.3-0.7-0.6-0.8c-0.2-0.2-0.3-0.4-0.3-0.7l-0.2,0.1c0-0.1,0.1-0.4,0.1-0.5c-0.1,0-0.2,0-0.3,0
        c0,0.1,0,0.3,0,0.4l0.1-0.1c0.1,0.4,0.2,0.8,0.5,1.1c0.1,0.1,0.1,0.3,0.2,0.5c0.4,0.2,0.4,0.6,0.6,1c0.1,0,0.2,0.1,0.3,0.1l-0.4,0
        c0.4,0.2,0.4,0.7,0.5,1.1c0.3,0.4,0.7,0.8,0.5,1.3c0.1-0.1,0.2-0.2,0.2-0.3c0.1,0.2,0.2,0.4,0.3,0.7c0.1,0,0.2,0,0.3,0
        c0,0.1,0,0.3,0,0.5l0.2-0.1c0,0.1-0.1,0.2-0.1,0.3l0.2,0c0,0.1-0.1,0.2-0.1,0.3l0.2,0l-0.1,0.2c0.2-0.1,0.2,0.2,0.4,0.3
        c0.2,0.3,0.5,0.5,0.8,0.7c0,0,0,0.1,0,0.1c-0.3-0.1-0.7-0.2-0.9-0.5c-0.5-0.4-1.1-0.7-1.5-1.2c-0.1,0.1-0.2,0.2-0.3,0.3
        c0,0.1,0.1,0.2,0.2,0.2c0-0.1,0-0.2,0-0.3l0.3,0c0,0.1,0,0.3,0,0.4c0.3,0.1,0.4,0.5,0.3,0.8c0.1-0.1,0.2-0.2,0.3-0.3
        c0,0.2,0,0.4,0,0.6c0.1,0,0.2,0.1,0.3,0.1c0,0.1,0,0.3,0,0.4c0.2,0.1,0.3,0.2,0.3,0.5c0.3,0,0.3,0.3,0.3,0.5c0.1,0,0.2,0,0.3-0.1
        c-0.1,0.3,0.2,0.5,0.3,0.7c0.2,0,0.4,0,0.5,0c0,0-0.1,0.1-0.1,0.1c-0.1-0.2-0.4-0.1-0.6-0.1c-0.4-0.2-0.7-0.6-1.1-0.6
        c0.1-0.3-0.2-0.3-0.4-0.4c0,0.1,0.1,0.2,0.2,0.3c0.1,0.2,0,0.5,0.3,0.6c0.1-0.1,0.2-0.2,0.3-0.3c-0.1,0.2-0.2,0.3-0.3,0.5
        c0.1,0,0.3-0.1,0.4-0.1c0,0.1-0.1,0.3-0.1,0.3c0.2,0,0.4,0,0.5,0.1c0,0-0.1,0.1-0.2,0.2c0.2,0.2,0.3,0.5,0.1,0.7
        c-0.1-0.1-0.2-0.2-0.3-0.3c0,0.3,0.3,0.3,0.5,0.4c0,0.2,0,0.3-0.1,0.5l0.2-0.1l0,0.2l0.2,0c0,0.1-0.1,0.2-0.1,0.3l0.1,0
        c0,0.2,0,0.3,0.1,0.5c0.2,0.2,0.4,0.4,0.6,0.7c-0.7-0.4-1.3-1.1-1.9-1.6c-0.2-0.1-0.5-0.3-0.6-0.5c0,0.2,0.1,0.4,0,0.7l-0.3,0
        c0.1,0.2,0.2,0.3,0.4,0.3c0,0.1,0,0.2,0,0.3c0.2,0,0.5,0.1,0.5,0.4c0.1,0.1,0.3,0.2,0.4,0.3c-0.1,0.1-0.1,0.2-0.2,0.3l0.3,0.2
        c-0.2,0-0.3-0.1-0.5-0.1c0.1,0.2,0.3,0.3,0.4,0.5l0.1,0c0.1,0.1,0.2,0.3,0.3,0.4c-0.1,0-0.3,0-0.3,0c0.2,0.2,0.3,0.4,0.5,0.5
        c-0.1,0-0.2,0-0.3,0c0.1,0.1,0.2,0.3,0.2,0.5l0.1-0.1l0,0.2l0.1,0c0.1,0.2,0.2,0.4,0.4,0.5c0,0,0,0.1,0,0.1c0.2,0.2,0.4,0.4,0.8,0.5
        c0,0,0,0,0,0.1c-0.3-0.1-0.6-0.1-0.8-0.3c-0.5-0.4-1.1-0.8-1.4-1.3c-0.3-0.1-0.6-0.3-0.7-0.6c-0.2-0.4-0.8-0.7-0.8-1.2
        c-0.1-0.2-0.3-0.5-0.4-0.7c0.1-0.2,0-0.4-0.2-0.6c-0.2-0.4-0.3-0.8-0.3-1.3c-0.3-0.3-0.3-0.8-0.5-1.1c0-0.8-0.2-1.5-0.1-2.3
        c-0.1-0.5,0.3-1-0.1-1.4c0-0.6-0.3-1.2-0.1-1.8c-0.1,0-0.2-0.1-0.3-0.1c0.1,0.6,0,1.1,0.1,1.7c0,0.3,0.2,0.8-0.2,1
        c0.1,0.2,0.4,0.5,0.3,0.8c-0.1,0.1-0.1,0.3-0.2,0.4c0.2,0.4,0.4,0.8,0.2,1.2c-0.1,0.4,0.1,0.7-0.1,1.1c0.1,0.2,0.2,0.4,0.3,0.6
        c-0.1,0-0.2,0.1-0.2,0.1l0.2,0c-0.1,0.3,0,0.5,0.3,0.6c0,0.2,0,0.5,0.1,0.7l0.3-0.1c0,0.1-0.1,0.3-0.1,0.4c0.1,0,0.3-0.1,0.4-0.1
        l0,0.3l-0.2,0c0,0,0,0.1,0,0.1c0.1,0.1,0.3,0.3,0.4,0.4l-0.3-0.1c0,0.1,0,0.3,0,0.4c0.2,0.1,0.3,0.1,0.5,0.2c0,0.1-0.1,0.3-0.1,0.4
        c0.1-0.1,0.2-0.3,0.3-0.4c0,0.1,0,0.3,0,0.4l-0.3,0c0.5,0.2,0.6,0.7,0.9,1.2c0.1,0.1,0.4,0.2,0.4,0.5c0.1,0.2,0.1,0.6,0.4,0.5
        c0,0.1-0.1,0.3-0.1,0.4c0.2,0,0.3,0.2,0.2,0.4c0.2,0.2,0.4,0.5,0.6,0.7c-0.1-0.1-0.2-0.1-0.3-0.2c0,0.2,0.2,0.3,0.3,0.4
        c0,0.2,0.1,0.3,0.3,0.3c0.1,0.3,0.3,0.6,0.6,0.7c0.2,0.2,0.4,0.4,0.6,0.6c0,0,0,0.1,0,0.1c-0.4-0.3-1-0.5-1.3-0.9
        c-0.2-0.1-0.4-0.1-0.7-0.2c0.3-0.4-0.3-0.5-0.5-0.7c0.2,0.2,0.4,0.5,0.5,0.8c0.2,0.2,0.3,0.4,0.6,0.3c0.1,0.2,0.1,0.3,0.2,0.5
        c0.2,0.1,0.3,0.3,0.3,0.4c0.3,0.1,0.2,0.3,0.3,0.5l0.2-0.1c0,0.5,0.6,0.7,0.8,1c-0.5-0.3-1.1-0.7-1.5-1.1c-0.3-0.3-0.8-0.5-1-0.9
        c-0.1,0-0.3,0-0.4,0c0-0.1,0-0.2,0-0.2c-0.2-0.1-0.3-0.3-0.5-0.4c0.1,0.2,0.3,0.3,0.4,0.5c-0.1,0-0.2,0.1-0.2,0.2
        c0.1,0.1,0.3,0.1,0.4,0c0,0.4,0.5,0.3,0.6,0.6c-0.1,0.1-0.2,0.2-0.3,0.2c0,0.3,0,0.5,0.1,0.8c0.1-0.1,0.2-0.2,0.3-0.3
        c0.1,0.2,0.2,0.5,0.5,0.5c0,0,0,0.1,0,0.2l0.1,0c0-0.2,0-0.3,0.1-0.5c0,0.1,0,0.4,0,0.5c0.3,0,0.5,0.3,0.5,0.5
        c-0.3,0-0.4-0.2-0.6-0.3c0.1,0.2,0.3,0.3,0.4,0.5c0.2,0.1,0.8-0.1,0.5,0.4h0.1l0,0.1l0.2-0.1c0,0.1,0,0.2,0,0.3h0.1
        c-0.1,0.4,0.3,0.5,0.5,0.7c-0.2-0.4,0-0.7,0-1.1c0.2-0.4,0.5-0.7,0.7-1.1c0.1-0.4,0.2-0.8,0.3-1.2c0,0,0.1-0.1,0.2-0.2
        c0-0.3,0.1-0.5,0.4-0.6c-0.1-0.3,0-0.5,0.2-0.7c0-0.2,0-0.3,0-0.5c0.5-0.4,0.6-1.1,0.7-1.6c0.1-0.1,0.2-0.2,0.3-0.2
        c-0.3,0-0.3,0.2-0.5,0.4c0,0.1,0,0.3,0,0.4c-0.1,0-0.3,0.1-0.3,0.1c0,0.1,0.1,0.2,0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4
        c0,0.1,0,0.2,0,0.3c-0.3,0-0.4,0.2-0.4,0.4c-0.2,0.1-0.4,0.2-0.5,0.3c0-1-0.2-2.2,0.6-2.9c0-0.9,0.5-1.6,0.9-2.3c0-0.1,0-0.3,0-0.4
        c0.1-0.2,0.1-0.3,0.1-0.5l0.1,0c0.1-0.2,0.2-0.4,0.4-0.6c-0.1,0-0.2,0-0.3,0.1c-0.1,0.2-0.2,0.4-0.3,0.6c0,0.7-0.5,1.3-0.9,1.8
        c-0.1,0-0.2-0.1-0.2-0.1l0,0.1l0.2,0l0,0.2c-0.1,0-0.2,0-0.3,0l0,0.1c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.3-0.6,0.4
        c0.1-0.4,0.3-0.8,0.4-1.2c0-0.3,0-0.6,0.2-0.9c-0.1-0.4,0-0.9,0.3-1.1c0.1-0.3,0.2-0.5,0.2-0.8c0.2-0.4,0.5-0.7,0.6-1.1
        c0-0.3,0.1-0.6,0.2-0.9c-0.1,0.1-0.2,0.2-0.3,0.3c0,0.1,0,0.3,0,0.4l-0.1,0c-0.1,0.4-0.4,0.9-0.9,1c0.6-0.6,0.4-1.4,0.7-2.1
        c0.2-0.2,0.2-0.4,0-0.7c0.5-0.7,0.5-1.6,1.1-2.2c0-0.2,0-0.3,0-0.5c0.3-0.4,0.5-0.9,0.7-1.4c0.2-0.2,0.4-0.4,0.3-0.7
        c0.2-0.5,0.5-1,0.5-1.5c0.5-0.1,0.3-0.7,0.4-1c0.2-0.2,0.3-0.4,0.4-0.6c-0.3,0-0.3,0.2-0.4,0.4c-0.1,0.2-0.2,0.4-0.2,0.7
        c-0.3,0-0.2,0.2-0.1,0.3c-0.1,0-0.2,0-0.2,0l0,0.1c-0.3,0.1-0.5,0.3-0.5,0.6c0.1-0.2,0.2-0.5,0.5-0.6c-0.2,0.4-0.3,0.8-0.6,1.1
        c0,0.2,0,0.3,0,0.5c-0.1,0.2-0.3,0.4-0.4,0.6c-0.1,0.2-0.1,0.4-0.2,0.6c0,0-0.1,0-0.2,0c0,0.1-0.1,0.3-0.1,0.3
        c-0.4,0.6-0.9,1.2-1.4,1.8c0.6-1.2,1-2.4,1.4-3.6c0.1-0.2,0.1-0.5,0.3-0.7c0.1-0.7,0.4-1.4,0.8-2.1c0.1-0.4,0.1-0.8,0.3-1.1
        c0.5-1,0.6-2.1,1-3.2c-0.1,0-0.2-0.1-0.3-0.2c0.3-0.1,0.5-0.4,0.1-0.7c0.1,0,0.3,0.1,0.4,0.1c-0.1-0.6,0-1.2-0.3-1.8
        c0.2,0.1,0.3,0.3,0.5,0.5c-0.2-0.4-0.3-0.9,0-1.3l-0.2,0c0-0.1,0-0.2,0-0.3l0.2,0.1c0-0.1,0-0.4-0.1-0.5c0,0-0.1-0.1-0.1-0.1
        c0.1,0,0.2,0,0.2,0c0,0,0,0.1,0,0.2l0.4-0.1l-0.3,0.3c0.1-0.1,0.3-0.2,0.4-0.4c-0.2-0.1-0.3,0-0.4,0.1c0-0.1,0-0.3,0-0.4l-0.2,0
        c0.1-0.1,0.1-0.2,0.2-0.3c-0.1-0.1-0.2-0.3-0.2-0.5l-0.1,0c0.1-0.1,0.1-0.2,0.1-0.4c-0.1,0-0.2-0.1-0.2-0.2c0.3,0,0.4,0.3,0.5,0.5
        c0.2-0.2,0-0.6-0.3-0.5c0-0.3,0.1-0.7-0.2-0.8c0,0.2-0.1,0.3-0.2,0.5c0-0.2,0-0.4-0.1-0.6c0.1-0.2,0-0.3-0.2-0.3
        c-0.1-0.4-0.5-0.8-0.6-1.2c-0.6,1.1-0.1-0.2-0.2-0.5c-0.2,0.2-0.2,0.5-0.1,0.8c-0.2-0.4-0.7-0.4-0.8-0.9c0.1,0.1,0.3,0.2,0.5,0.2
        c0-0.3-0.3-0.4-0.4-0.6c0,0.1-0.1,0.2-0.1,0.3c0-0.7-0.7-0.8-1.2-1.1c0,0,0-0.1,0-0.2c0,0.1,0.1,0.2,0.2,0.3l0-0.3l0.3,0.1
        c-0.1-0.3-0.3-0.5-0.6-0.4c0-0.1,0.1-0.2,0.2-0.3c-0.1,0-0.2,0.1-0.3,0.2c0-0.4-0.4-0.3-0.6-0.5c0.1,0,0.4,0,0.5,0
        c-0.2-0.2-0.2-0.5-0.5-0.4l0-0.2c-0.4-0.4-0.6-1-1.1-1.2c0-0.2,0-0.4-0.1-0.6c-0.1,0.1-0.3,0.2-0.3,0.2c-0.2-0.2-0.2-0.3,0.1-0.3
        c-0.1-0.2-0.3-0.3-0.4-0.4c-0.1,0.1,0,0.7-0.3,0.6c0-0.3,0-0.5,0.3-0.7c-0.2,0.1-0.3,0-0.3-0.2c0.1-0.4-0.3-0.4-0.5-0.3
        c0.2-0.2,0.3-0.6,0-0.7c0,0.2-0.1,0.3-0.3,0.3l0-0.2l-0.1-0.1c-0.2-0.1-0.5-0.1-0.7,0c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0,0.2,0,0.3,0
        c0,0,0.1-0.1,0.1-0.2c-0.2-0.2-0.3-0.6-0.6-0.7l0-0.2c-0.1,0-0.3-0.1-0.4-0.1c0,0.2,0.1,0.4,0.1,0.6c-0.1-0.1-0.3-0.3-0.4-0.4l0.3,0
        c-0.1-0.1-0.2-0.3-0.3-0.4c0.1,0,0.3,0,0.4,0.1c-0.2-0.2-0.5-0.3-0.5-0.6l-0.2,0l0-0.2l0.3,0c-0.1-0.1-0.2-0.2-0.4-0.1
        c0-0.2,0-0.3-0.2-0.4c0,0.1,0.1,0.4,0.1,0.5l-0.2-0.1c0-0.1,0-0.3,0-0.4c-0.1,0-0.3,0.1-0.3,0.1c0.1-0.1,0.1-0.3,0.2-0.4
        c-0.3-0.1-0.6-0.3-0.3-0.6c-0.1,0-0.2,0-0.2,0c-0.1-0.2-0.2-0.5-0.4-0.7c-0.1,0.1-0.2,0.1-0.4,0.2l0-0.1c0.2-0.1,0.2-0.3,0.2-0.5
        c-0.1,0-0.3,0-0.4,0c-0.1-0.4-0.3-0.7-0.7-0.9l-0.1-0.1l-0.1-0.1c-0.1,0.1-0.2,0.2-0.2,0.4c-0.2-0.3-0.1-0.5,0.1-0.7
        c0-0.2-0.2-0.5-0.3-0.7c-0.1,0.1-0.2,0.2-0.2,0.3c0-0.1,0-0.2,0-0.3L216,312.8 M127.4,313.1C127.5,313.6,127.9,312.9,127.4,313.1
        M245.7,313.2C245.5,313.6,246.2,313.1,245.7,313.2 M396.5,313.1C396.6,313.2,396.6,313.2,396.5,313.1 M263,313.4l0,0.1
        C263.2,313.7,263.3,313.2,263,313.4 M168.9,313.9c0,0,0,0.1,0,0.2c0.1,0,0.2,0,0.3,0c0,0.1-0.1,0.3-0.1,0.4c0.1,0,0.3,0,0.4,0
        c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0.1-0.2,0.1-0.3C169.2,313.9,169.1,313.9,168.9,313.9 M358.5,314.2c0-0.1,0.1-0.2,0.1-0.3
        C358.4,313.7,358.2,314.2,358.5,314.2 M359.3,313.9c-0.1,0.2,0,0.3,0.2,0.2C359.6,313.9,359.5,313.8,359.3,313.9 M172,314
        C172,314.4,172.4,313.7,172,314 M245.7,314C245.5,314.4,246.2,314,245.7,314 M187.3,314.3C187.4,314.4,187.4,314.4,187.3,314.3
        M263,314.5C263,314.6,263,314.6,263,314.5 M284.7,314.4c-0.1,0.2,0,0.3,0.2,0.2C285,314.4,284.9,314.3,284.7,314.4 M120.6,314.5
        C120.6,315,121.1,314.3,120.6,314.5 M42.6,314.7C42.7,314.8,42.7,314.8,42.6,314.7 M130,314.9c0.1,0.1,0.5,0,0.4-0.2
        C130.3,314.6,129.9,314.7,130,314.9 M373,314.7C373.1,314.8,373.1,314.8,373,314.7 M262.9,315c0,0.3,0,0.5,0.1,0.8
        c0,0.3-0.4,0.6,0,0.8c-0.2-0.4,0.1-0.5,0.3-0.8c-0.2-0.3-0.2-0.6-0.2-0.9L262.9,315 M390,315.1C389.9,315.6,390.4,314.9,390,315.1
        M206,315.3c-0.1,0.2,0,0.3,0.2,0.2C206.2,315.3,206.2,315.2,206,315.3 M126.1,315.4C126.2,315.5,126.2,315.5,126.1,315.4
        M303.4,315.5c-0.1,0.2,0,0.3,0.2,0.2C303.6,315.5,303.6,315.4,303.4,315.5 M375.3,315.4c-0.1,0.2,0,0.3,0.2,0.2
        C375.5,315.4,375.5,315.4,375.3,315.4 M188.9,315.8c0,0.3-0.3,0.3-0.5,0.2c0.1,0,0.2,0.1,0.3,0.1c0.3-0.2,0.8-0.2,0.9-0.6
        C189.4,315.7,189.2,315.8,188.9,315.8 M199.6,316c0.1,0.2,0,0.4-0.1,0.5c0.2-0.1,0.4-0.4,0.4,0.1c0.1,0,0.3,0,0.4,0
        C200,316.4,199.8,316.2,199.6,316 M206.4,316.3C206.4,316.4,206.4,316.4,206.4,316.3 M127.8,316.4c-0.1,0.2-0.1,0.4-0.1,0.7l-0.2,0
        c0.2,0.2,0.3-0.1,0.4-0.2c0.2,0,0.5,0,0.7,0l0-0.1c-0.2-0.1-0.4-0.1-0.5-0.2C128,316.6,127.9,316.5,127.8,316.4 M307.2,316.6
        C307.3,316.6,307.3,316.6,307.2,316.6 M42.4,316.9C42.2,317.3,42.9,316.9,42.4,316.9 M385.3,317.1
        C385.3,317.6,385.7,316.9,385.3,317.1 M199.3,317.3C199.3,317.4,199.3,317.4,199.3,317.3 M193.1,317.4
        C193.2,317.5,193.2,317.5,193.1,317.4 M303.5,317.5c-0.1,0.2-0.1,0.3-0.1,0.5C303.7,318,303.8,317.4,303.5,317.5 M199.4,317.6
        c0,0.1-0.1,0.2-0.1,0.2C199.5,318.2,199.9,317.3,199.4,317.6 M263.1,317.7C262.9,318.2,263.6,317.7,263.1,317.7 M382.1,317.7
        C382.2,317.8,382.2,317.8,382.1,317.7 M385.4,317.8C385.4,317.9,385.4,317.9,385.4,317.8 M42.1,318.5c0.2-0.1,0.2-0.2,0-0.3
        C41.9,318.3,41.9,318.4,42.1,318.5 M262.7,318.7c0.2,0,0.3-0.1,0.2-0.3C262.7,318.4,262.6,318.5,262.7,318.7 M307.2,318.3
        c0.1,0.2,0.1,0.3,0.2,0.5c0.1-0.1,0.2-0.2,0.4-0.3C307.6,318.5,307.4,318.4,307.2,318.3 M384.4,318.4c0,0.2,0.1,0.3,0.3,0.2
        C384.7,318.4,384.6,318.3,384.4,318.4 M177.6,318.6C177.7,318.6,177.7,318.6,177.6,318.6 M129.8,319
        C129.7,319.1,129.7,319.1,129.8,319C129.9,318.9,129.9,318.9,129.8,319 M199.4,318.8c0,0.3,0,0.5,0,0.8c0.1,0,0.3-0.1,0.5-0.1
        c0-0.1,0-0.2,0-0.3l-0.2-0.1C199.6,318.9,199.5,318.8,199.4,318.8 M89.1,319.1c0.1,0.2,0.3,0.2,0.4,0
        C89.3,318.8,89.2,318.8,89.1,319.1 M199.1,319C199.2,319.1,199.2,319.1,199.1,319 M140.6,319.1C140.4,319.6,141.1,319.1,140.6,319.1
        M286.3,319.2c-0.2,0.2,0.1,0.4,0.3,0.3C286.7,319.3,286.4,319.1,286.3,319.2 M89.8,319.4C89.9,319.5,89.9,319.5,89.8,319.4
        M221.4,319.4C221.4,319.9,221.8,319.2,221.4,319.4 M419.7,320.1c-0.1,0.2,0,0.3,0.2,0.2C420,320.1,419.9,320,419.7,320.1
        M262.6,320.5c0.2-0.1,0.2-0.3,0-0.4C262.4,320.3,262.4,320.4,262.6,320.5 M263.3,320.6c0.1,0.2,0.2,0.2,0.3,0
        C263.5,320.3,263.4,320.3,263.3,320.6 M307.7,320.7C307.8,320.8,307.8,320.8,307.7,320.7 M41.7,321c0.1,0,0.2,0.1,0.3,0.1
        C42.2,320.8,41.7,320.7,41.7,321 M94.3,321c0,0.1,0.1,0.2,0.1,0.2c0.2,0,0.4-0.1,0.4-0.3C94.6,320.9,94.5,320.9,94.3,321
        M416.6,321.2c-0.1,0.2,0,0.3,0.2,0.2C416.9,321.2,416.8,321.1,416.6,321.2 M95.3,321.2c0,0.2,0,0.3,0.2,0.2
        C95.6,321.3,95.5,321.2,95.3,321.2 M95.9,321.5L95.9,321.5L95.9,321.5L95.9,321.5L95.9,321.5 M287.5,322c0.1,0,0.2,0,0.3,0
        c0.1,0.1,0.2,0.1,0.3,0c0-0.1-0.1-0.2-0.1-0.3C287.8,321.7,287.4,321.8,287.5,322 M304.1,321.7c-0.1,0.2-0.1,0.3,0.1,0.3
        C304.3,321.8,304.2,321.7,304.1,321.7 M223.9,321.8C223.9,321.9,223.9,321.9,223.9,321.8 M414.8,321.8c-0.2,0.1-0.2,0.2,0,0.3
        C415,322,415,321.9,414.8,321.8 M249.6,321.9C249.6,322,249.6,322,249.6,321.9 M307.7,322c-0.4,0.2,0.3,0.6,0.4,0.3
        C308,322.1,307.9,322,307.7,322 M471,322C471.1,322.1,471.1,322.1,471,322 M287.1,322.1c0.1,0.3,0.3,0.5,0.6,0.5
        c0.2-0.2,0.2-0.3,0-0.5l-0.1,0.3C287.5,322.3,287.3,322.2,287.1,322.1 M136.4,322.3C136.2,322.7,136.9,322.3,136.4,322.3
        M178.9,322.3C179,322.3,179,322.3,178.9,322.3 M188.6,322.7c0.1,0,0.3,0.1,0.5,0.2c-0.2-0.2-0.1-0.5,0.1-0.7
        C188.9,322.3,188.7,322.5,188.6,322.7 M412.6,322.7c-0.1,0.2,0,0.3,0.2,0.2C412.9,322.7,412.9,322.6,412.6,322.7 M411.8,322.8
        c-0.2,0.3,0.6,0.3,0.4,0C412.1,322.8,411.9,322.8,411.8,322.8 M98.7,322.9c-0.2,0.2,0.2,0.4,0.4,0.2
        C99.3,322.9,98.8,322.7,98.7,322.9 M99.8,323c-0.2,0.1,0.1,0.5,0.2,0.3C100.3,323.2,100,322.8,99.8,323 M100.5,323.3
        c0.1,0.2,0.6,0.1,0.4-0.2C100.8,323,100.3,323.1,100.5,323.3 M180.1,323.1c-0.2,0.1-0.2,0.2,0,0.3
        C180.2,323.3,180.2,323.2,180.1,323.1 M185.1,323.3c0.2,0.1,0.3,0,0.3-0.2C185.3,323.1,185.2,323.1,185.1,323.3 M182.9,323.4
        C183,323.5,183,323.5,182.9,323.4 M184.5,323.4c-0.1,0.2,0,0.3,0.2,0.2C184.7,323.4,184.7,323.3,184.5,323.4 M198.4,323.7
        c-0.1,0.2,0,0.5,0.2,0.5C198.9,324.1,198.6,323.7,198.4,323.7 M102.9,324c0.1,0.1,0.4,0.3,0.4,0.1
        C103.2,323.9,102.9,323.8,102.9,324 M104.5,324.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0-0.3-0.1-0.4-0.1c0.2,0.1,0.4,0.2,0.6,0.3
        C104.4,324.5,104.6,324.4,104.5,324.2 M260.8,324.5C260.9,324.6,260.9,324.6,260.8,324.5 M264.2,324.6
        C264,325,264.7,324.6,264.2,324.6 M406.9,324.7c0,0-0.1,0.1-0.2,0.2C406.8,325.3,407.4,324.6,406.9,324.7 M407.3,324.7
        C407.4,324.7,407.4,324.7,407.3,324.7 M407.7,324.6C407.7,325.1,408.2,324.4,407.7,324.6 M105.4,325C105.5,325,105.5,325,105.4,325
        M106.8,324.9C106.9,325,106.9,325,106.8,324.9 M197.7,325C197.8,325.1,197.8,325.1,197.7,325 M470.2,324.9
        C470.3,325,470.3,325,470.2,324.9 M106.6,325.1C106.7,325.2,106.7,325.2,106.6,325.1 M147.2,325.1
        C147.3,325.2,147.3,325.2,147.2,325.1 M303.5,325.1C303.5,325.6,303.9,324.9,303.5,325.1 M198.1,325.4l0.1,0.2c-0.2,0-0.3,0-0.5,0.1
        c0.1,0,0.3-0.1,0.4-0.1l-0.1,0.2c0.1,0,0.3-0.1,0.4-0.1l0-0.1c0.1-0.1,0.1-0.2,0.2-0.3C198.5,325.3,198.3,325.3,198.1,325.4
        M251.4,325.3c-0.2,0.1,0.1,0.4,0.2,0.3C251.8,325.4,251.6,325.1,251.4,325.3 M405,325.2C405,325.7,405.4,325,405,325.2
        M205.3,325.4C205.1,325.9,205.8,325.4,205.3,325.4 M107.5,325.5c-0.1,0.2,0,0.3,0.2,0.2C107.8,325.5,107.7,325.4,107.5,325.5
        M147.8,325.5C147.6,325.9,148.3,325.6,147.8,325.5 M226,325.5c-0.1,0.2,0.2,0.4,0.3,0.3C226.4,325.6,226.1,325.4,226,325.5
        M309.9,325.5c-0.2,0-0.2,0.3,0,0.4C310,325.9,310.1,325.5,309.9,325.5 M109.4,325.8c0,0.1,0,0.3,0,0.4c0.1,0,0.2-0.1,0.2-0.2
        c0,0.1,0.1,0.2,0.1,0.3c0.1-0.1,0.1-0.2,0.2-0.4C109.8,325.9,109.6,325.8,109.4,325.8 M205.2,325.8c-0.2,0-0.3,0.4,0,0.4
        C205.4,326.2,205.5,325.8,205.2,325.8 M264.4,325.9c-0.1,0.2,0,0.3,0.2,0.2C264.7,326,264.6,325.9,264.4,325.9 M251.5,326.1
        c-0.1,0.2,0,0.4,0.1,0.5C251.9,326.7,251.7,326,251.5,326.1 M197.8,326.1c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0.1-0.3,0.2-0.4,0.3
        c0.1,0,0.3,0,0.4,0.1C198.3,326.4,198.1,326.2,197.8,326.1 M400.8,326.5c-0.1,0.2,0,0.3,0.2,0.2
        C401.2,326.5,401.1,326.4,400.8,326.5 M111.6,326.8c-0.1,0.2,0,0.3,0.2,0.2C111.9,326.8,111.9,326.7,111.6,326.8 M310.4,326.8
        C310.2,327.2,310.9,326.8,310.4,326.8 M198.2,327.5c0.3-0.1,0.3-0.9-0.1-0.6C197.8,327,198.2,327.3,198.2,327.5 M397.1,327.5
        c-0.1,0-0.3,0.3-0.1,0.4C397.2,327.9,397.6,327.2,397.1,327.5 M116.5,327.8C116.6,327.9,116.6,327.9,116.5,327.8 M147.7,327.9
        c-0.1,0.3,0.4,0.3,0.5,0.1C148.1,327.8,147.8,327.8,147.7,327.9 M396.1,328.1c0-0.1,0-0.2,0-0.2l-0.1-0.1
        C395.7,327.8,395.9,328.3,396.1,328.1 M116.2,328.1C116.3,328.2,116.3,328.2,116.2,328.1 M116.6,328.2c-0.1,0.2,0,0.3,0.2,0.2
        C116.9,328.2,116.8,328.1,116.6,328.2 M394.5,328.3c0.1,0.2,0.2,0.2,0.3,0C394.8,328.1,394.7,328.1,394.5,328.3 M395.2,328.5
        c0.2-0.1,0.2-0.2,0-0.4C395,328.3,395,328.4,395.2,328.5 M468.9,328.4C468.6,328.8,469.3,328.4,468.9,328.4 M117.5,328.5
        C117.3,329,117.9,328.5,117.5,328.5 M117.9,328.6C117.9,329.1,118.3,328.4,117.9,328.6 M311.3,329.3c0.2,0.1,0.3-0.3,0.2-0.4
        C311.3,328.8,311.2,329.1,311.3,329.3 M311.7,328.9c0.1,0.2,0.2,0.4,0.2,0.7c0.2-0.1,0.2-0.4,0.3-0.5
        C312.1,329.1,311.9,329,311.7,328.9 M304.4,329.1c-0.1,0.2,0.1,0.5,0.3,0.4C304.9,329.3,304.5,328.9,304.4,329.1 M468.5,329.4
        C468.3,329.8,469,329.5,468.5,329.4 M150.1,329.8c0.1,0,0.2-0.1,0.3-0.1c-0.1-0.1-0.2-0.2-0.2-0.3C150,329.6,150,329.7,150.1,329.8
        M197,329.6c-0.1,0.2,0,0.3,0.2,0.2C197.3,329.6,197.2,329.6,197,329.6 M389.9,329.8C390,329.9,390,329.9,389.9,329.8 M304.1,330.1
        C304.1,330.2,304.1,330.2,304.1,330.1 M289.3,330.2c0,0.2,0.2,0.5,0.4,0.4C289.9,330.3,289.5,330.1,289.3,330.2 M249.2,330.5
        C249.3,330.6,249.3,330.6,249.2,330.5 M230.1,331.1c0,0.3,0.1,0.6,0,0.9c-0.1,0.3,0.3,0.6,0.2,0.9c0.2,0.3,0.4,0.7,0.2,1.1
        c0.3,0.3,0.2,0.8,0.2,1.2c0.1,0.1,0.1,0.3,0.2,0.4c-0.1,0.2-0.2,0.4-0.1,0.6c0.2,0.6,0.1,1.2,0.1,1.8c0.1,0.4,0.2,0.9,0.1,1.3
        c0.1,0,0.2,0.1,0.3,0.2l-0.3,0c0.2,0.6,0.9,0.8,1.1,1.4l0.1,0l0,0.1c0.3,0.2,0.5,0.4,0.7,0.6c0.1,0.2,0.3,0.3,0.6,0.3l0,0.1l0.2,0
        c0.1,0.5,0.5,0.6,0.8,1c0.2,0,0.5,0,0.6,0.2c-0.1,0-0.4,0-0.6,0c0.3,0.2,0.6,0.4,0.9,0.4l0,0.1c-0.1,0-0.2,0-0.3,0
        c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0,0.2-0.1,0.3-0.1c0,0.2,0.1,0.4,0.1,0.6c0.1,0,0.2-0.1,0.3-0.2c0,0.3,0.2,0.4,0.3,0.6
        c0.2,0.2,0.5,0.4,0.8,0.7c0.1,0.1,0.3,0.3,0.5,0.4c0-0.4,0-0.9-0.1-1.3c-0.1-0.7,0.1-1.4,0-2.2c0,0-0.1-0.1-0.1-0.2
        c0.2-0.5,0.1-1.1,0-1.6c-0.1,0-0.2-0.1-0.3-0.1c0-0.1,0-0.2,0-0.3c0,0.3,0.2,0.4,0.5,0.3c-0.1-0.3-0.3-0.5-0.4-0.7
        c-0.4,0.1-0.4-0.3-0.5-0.6c-0.1,0.1-0.1,0.2-0.2,0.4c-0.1,0-0.2-0.1-0.2-0.1c0.1-0.1,0.2-0.2,0.3-0.3l-0.4,0c0-0.1,0.1-0.3,0.2-0.4
        l-0.2,0.1c0.1-0.4-0.4-0.6-0.7-0.8c0-0.2,0-0.4-0.1-0.5c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0.1,0.2,0.3,0.3,0.4l-0.1,0
        c-0.3-0.2-0.3-0.5,0-0.8c-0.3-0.3-0.6-0.5-0.9-0.6c-0.2-0.3-0.5-0.4-0.8-0.6c0.1,0,0.3,0,0.4,0l0-0.2c-0.3-0.1-0.5-0.2-0.8-0.3
        c-0.2-0.1-0.4,0-0.5-0.3c0.3,0.1,0.5,0.2,0.7,0.3c0-0.1-0.1-0.4-0.1-0.5c-0.2,0-0.3-0.1-0.5-0.1c0.1-0.1,0.1-0.3,0.2-0.4
        c-0.2,0-0.5-0.1-0.6-0.3c0-0.1,0.1-0.2,0.1-0.3c-0.2-0.1-0.4-0.1-0.6-0.1c0-0.1,0-0.3,0.1-0.4l0.2,0c-0.2-0.4-0.8-0.1-0.8-0.7l0.3,0
        l0-0.2c-0.1,0-0.2,0.1-0.3,0.2c-0.2-0.3-0.4-0.6-0.7-0.8c0-0.2-0.1-0.3-0.1-0.4c-0.1,0-0.3-0.1-0.4-0.1c0-0.1,0-0.3,0.1-0.5
        C230.3,331.1,230.2,331.1,230.1,331.1 M196.8,331.3c0.2,0.3-0.1,0.5-0.4,0.6l0,0.1c0.1,0,0.2,0,0.3,0l-0.1,0.3l0.2-0.1
        c-0.1-0.3,0-0.7,0.2-1C197,331.2,196.9,331.3,196.8,331.3 M357.6,331.2c0.2,0.1,0.3,0.2,0.1,0.4l0.3,0.1c0-0.1-0.1-0.3-0.1-0.5
        c0.4,0,0.4,0.7,0,0.7c-0.2-0.2-0.4-0.4-0.6-0.5c-0.2,0.2-0.5,0-0.7,0c0.3,0.1,0,0.3-0.1,0.4l-0.2-0.2c-0.3,0.1-0.7,0.2-1,0.3
        c-0.2,0.2-0.5,0.1-0.7,0.1c0,0.3,0.4,0.3,0.4,0.6c-0.3-0.2-0.6-0.2-0.7-0.5c-0.3,0.2-0.5,0.4-0.6,0.7c-0.1,0-0.2-0.1-0.2-0.1
        c-0.3,0.1-0.6,0.3-0.8,0.1l0.1,0.3c0.2-0.1,0.4-0.1,0.6-0.1L353,333c-0.1,0.1-0.2,0.2-0.3,0.2l0-0.3c-0.3,0.1-0.5,0.3-0.5,0.6
        c-0.1,0-0.3,0-0.4,0c0.1,0.1,0.2,0.2,0.2,0.3c-0.5-0.1-1.1,0-1.6,0.2c0.2,0,0.3,0,0.5,0c-0.1,0.2-0.4,0.4-0.6,0.4
        c-0.4,0.1-0.5,0.4-0.5,0.8c0-0.1,0-0.3,0-0.4l-0.2,0c0,0.1,0,0.2,0,0.3c-0.1,0-0.3,0-0.4,0.1c0.1,0.1,0.2,0.2,0.3,0.3
        c-0.2,0-0.3,0-0.5,0c0,0.1,0,0.2,0,0.3c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0.3-0.3,0.5-0.7,0.4l0,0.1c0.2,0,0.3,0.1,0.5,0.1
        c-0.1,0-0.3,0.1-0.4,0.1l-0.1,0c-0.1,0.1-0.2,0.3-0.1,0.5c-0.1-0.1-0.3-0.1-0.4-0.2c0,0.1,0,0.3-0.1,0.4c-0.1,0-0.3-0.1-0.4-0.2
        c0.1,0.2,0.2,0.3,0.3,0.5c-0.3,0-0.5,0-0.7-0.1c-0.1,0.1-0.1,0.2-0.2,0.2l0.2,0c0,0.5-0.4,0.6-0.8,0.8c0,0.1,0.1,0.2,0.2,0.3
        c-0.4-0.1-0.4,0.4-0.6,0.5c-0.2,0.1-0.6,0.1-0.3,0.4c-0.2,0.2-0.4,0.4-0.6,0.6c0,0.4-0.3,0.7-0.6,0.8c0.1,0.3-0.2,0.5-0.3,0.8
        c-0.3,0.5-0.4,1.2-0.8,1.6c0,0.1,0.1,0.2,0.2,0.3c-0.1,0-0.3,0-0.4,0c0,0.1,0.1,0.2,0.2,0.2c-0.2,0.4-0.6,0.8-0.8,1.2
        c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0,0.2,0.2,0.3,0.2c0.2-0.1,0.7-0.3,0.3-0.5c0,0.1-0.1,0.3-0.1,0.4c-0.1-0.1-0.3-0.2-0.3-0.2
        c0-0.2,0.3-0.3,0.4-0.5c0.2-0.1,0.6,0,0.6-0.3l-0.4,0c0.1-0.3,0.3-0.1,0.5-0.1c0-0.1,0-0.3,0-0.4c0,0.2,0,0.3,0,0.5
        c0.3-0.1,0.4-0.2,0.2-0.5c0.3,0,0.7,0.1,1-0.1c-0.1,0-0.4,0-0.5,0c0.3-0.4,0.6-1,1.2-1.1c0.2-0.1,0.5-0.1,0.5-0.4
        c0.1-0.1,0.2-0.3,0.3-0.4c0.1,0.1,0.2,0.2,0.3,0.3c0-0.1,0-0.2,0-0.3c0.1-0.1,0.3-0.1,0.4-0.2c0.2-0.4,0.6-0.7,1-1
        c0.2,0,0.4,0.2,0.7,0.2c0.1-0.2,0-0.5,0.1-0.7c0.1,0.1,0.3,0.1,0.4,0.2c0.1-0.1,0.2-0.2,0.2-0.2c0-0.2,0.1-0.3,0.1-0.4
        c0.1,0,0.3,0,0.4,0c0.1-0.2,0.2-0.4,0.3-0.6c0.4,0.1,0.5-0.1,0.5-0.5c0.1,0.1,0.2,0.2,0.3,0.3c0-0.1-0.1-0.3-0.1-0.5
        c0.4,0,0.8-0.2,1.1-0.4c-0.3,0-0.3-0.2-0.1-0.4c0.1,0.1,0.2,0.2,0.3,0.2c0-0.1,0-0.3,0-0.4c0.1,0.1,0.2,0.2,0.2,0.3
        c0.1-0.3,0.3-0.6,0.6-0.8c0.2-0.2,0.5-0.6,0.9-0.4c-0.3-0.1-0.2-0.3,0-0.5c0.1,0.1,0.3,0.2,0.5,0.2c0-0.1-0.1-0.2-0.2-0.3
        c0.1-0.1,0.3-0.1,0.4-0.1c-0.2-0.4,0.3-0.3,0.6-0.4c0,0.1,0,0.4,0,0.5c0.1-0.1,0.2-0.2,0.2-0.3c-0.1-0.2-0.3-0.3-0.4-0.4
        c0.2,0,0.5,0,0.7,0c0.1-0.2,0.2-0.4,0.4-0.4l0-0.2c-0.1,0-0.2,0-0.3,0c0.1,0,0.3-0.1,0.4-0.1c0.1-0.2,0.2-0.4,0.5-0.5
        c0.1-0.1,0.2-0.3,0.3-0.4c0.3,0.4,0.4,0,0.5-0.2c0-0.1-0.1-0.2-0.1-0.3c0.2,0.1,0.4,0.2,0.6,0.3c-0.2-0.4,0.2-0.6,0.3-0.9
        c0.1,0,0.3,0.1,0.4,0.1c0.1-0.4,0.5-0.7,0.8-1c-0.3,0-0.5,0-0.8,0c0-0.5,0.7,0,0.5-0.5c-0.1,0.1-0.2,0.2-0.3,0.2
        c-0.1-0.1-0.3-0.1-0.4-0.2C358,331.2,357.8,331.2,357.6,331.2 M205.2,331.4L205.2,331.4L205.2,331.4L205.2,331.4L205.2,331.4
        M364.7,332.1c0,0,0.1-0.1,0.2-0.1c0-0.1,0-0.2,0-0.3c-0.3,0-0.3-0.2-0.5-0.4C364.3,331.6,364.5,331.9,364.7,332.1 M309.3,331.6
        c0.2,0.7-0.1,1.4,0.1,2.1c0.2,0.8,0,1.5,0.1,2.3c0,0.3,0,0.6,0.1,0.9c-0.2,0.3-0.1,0.5,0.1,0.7c0.1-0.2,0.1-0.3,0.2-0.5
        c0.1,0.5-0.4,0.8-0.2,1.3c0,0.8,0.3,1.6,0,2.3c0.2,0.5,0.1,1,0.2,1.5c-0.1,1,0,1.9,0.2,2.8c0,0.8,0.2,1.6,0.3,2.4
        c0,0.2,0.2,0.5,0,0.6c0,0.4-0.1,0.9,0.3,1.1c0,0.1,0,0.2,0,0.2l0.2-0.1c-0.1,0.2,0,0.6-0.3,0.7c0.1,0,0.3-0.1,0.4-0.1
        c0,0.2,0,0.5,0,0.7c0.3,0.2,0.1,0.6,0.2,0.9c0.1,0,0.2,0,0.2-0.1c0,0.3,0.1,0.6,0.2,0.9c0,0.2,0.6,0.8,0.1,0.7
        c-0.5-0.4-0.8-1.1-1-1.7c0,0-0.1,0-0.1,0c0.1,0.3,0.1,0.5,0.1,0.8c0.1,0.2,0.1,0.5,0.1,0.7c0.1,0.1,0.2,0.2,0.3,0.4
        c0,0.1-0.1,0.2-0.1,0.2l0.2,0c0,0.1,0.1,0.2,0.1,0.2c-0.1,0-0.2,0-0.3,0c0.1,0,0.2,0.1,0.2,0.1c0,0.2,0.1,0.4,0.3,0.6
        c0.1,0,0.2-0.1,0.2-0.2c-0.1,0.4,0.4,0.7,0.5,1c0,0.1,0,0.3,0,0.3c0.4,0.2,0.3,0.7,0.5,1.1l0,0.1c0.3,0,0.3,0.3,0.4,0.5
        c0.1,0.2,0.1,0.5,0.4,0.6c0.2,0.3,0.4,0.5,0.7,0.7c-0.5-0.3-1.3-0.6-1.3-1.2l-0.1,0.2c-0.4-0.3-0.6-1-1.2-1c0-0.5-0.5-0.7-0.8-1
        c0,0.4,0.1,0.7,0.2,1.1c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0,0.2,0.1,0.4,0.1c-0.1,0-0.4,0-0.5-0.1c0.3,0.5,0.8,0.8,0.9,1.4
        c-0.1,0-0.3-0.1-0.4-0.1c0.2,0.2,0.5,0.1,0.7,0.1c0,0.1,0.1,0.3,0.1,0.4c0.2,0,0.4,0.3,0.4,0.5c0.1,0,0.2,0,0.3-0.1
        c0,0.1,0,0.3,0,0.4c0.3,0.3,0.5,0.7,0.8,1c-0.1,0-0.3-0.1-0.4-0.1c-0.3-0.3-0.6-0.6-0.7-1c0,0-0.1,0.1-0.1,0.2
        c0.2,0.3,0.5,0.5,0.7,0.8c-0.1,0-0.2,0.1-0.3,0.1c0.4-0.1,0.7,0.4,1.2,0.3c-0.1,0.1-0.3,0.2-0.4,0.3c0.1,0,0.3,0,0.3,0
        c0.2,0.4,0.7,0.4,0.8,0.9c-0.5-0.4-1.4-0.2-1.7-0.8c-0.1,0-0.2,0.1-0.2,0.1c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1,0-0.3,0.1-0.5,0.1
        c-0.3-0.2-0.4-0.5-0.6-0.7c-0.1,0-0.2,0-0.3,0c0.1,0,0.2,0,0.3,0c0.1,0.1,0.2,0.2,0.3,0.4c0,0.1,0,0.2,0,0.3
        c0.2,0.2,0.4,0.3,0.7,0.2c0.1,0.3,0.3,0.6,0.5,0.8c-0.1,0-0.2,0-0.3,0c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1,0.1-0.1,0.3-0.2,0.4
        c0.1-0.1,0.2-0.2,0.2-0.2c0.1,0.3,0.2,0.5,0.3,0.8c0.1-0.1,0.2-0.2,0.3-0.3c0,0.5,0.4,0.8,0.7,1.1c-0.1,0-0.3,0.1-0.3,0.1
        c0.1,0,0.2,0,0.3,0c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0,0.2,0,0.3,0c0,0.1,0,0.2,0,0.3l0.1,0c0.2,0.5,0.7,0.9,1.1,1.2
        c-0.6-0.2-1-0.5-1.4-0.9c-0.3-0.1-0.4-0.4-0.5-0.7c-0.1,0-0.2,0.1-0.2,0.1c-0.1-0.3-0.4-0.4-0.7-0.7c-0.2-0.3-0.5-0.5-0.7-0.8
        c-0.1,0-0.2-0.1-0.2-0.1c-0.3-0.4-0.6-0.8-0.8-1.3c-0.3,0.5-0.1,1.1,0,1.6l0.2,0c0,0.2,0,0.4,0,0.6c0.1,0,0.2,0,0.2,0
        c0.2,0.4,0.3,0.9,0.4,1.3c0.1,0,0.3-0.1,0.4-0.2c0,0.2-0.1,0.4-0.2,0.6c0.1-0.1,0.2-0.2,0.2-0.2c0.2,0.3,0.5,0.5,0.4,0.8
        c-0.1-0.1-0.3-0.2-0.4-0.3c0.2,0.3,0.4,0.5,0.6,0.7c0-0.1-0.1-0.4-0.1-0.5c0.2,0.2,0.3,0.3,0.5,0.5c-0.4,0.1-0.1,0.6,0.3,0.4
        c0.1,0.1,0.1,0.3,0.2,0.4c0.4,0.1,0.7,0.6,1.2,0.7c-0.1,0-0.2,0-0.2,0c-0.3-0.2-0.7-0.3-1.1-0.5c-0.2-0.2-0.4-0.1-0.7-0.1l0.1-0.2
        c-0.1,0-0.2,0.1-0.3,0.1c0-0.2,0-0.4-0.1-0.6c0,0.1,0.1,0.3,0.1,0.4c-0.3,0-0.3,0.1,0,0.1l0,0.2c0.1,0,0.2,0,0.2-0.1
        c0,0.3,0.3,0.4,0.4,0.6c0.1,0.1,0.3,0.2,0.4,0.2c-0.1,0.1-0.2,0.2-0.3,0.2c0.1,0.1,0.2,0.3,0.2,0.4l0.2-0.1l0,0.2l0.2,0
        c0,0.1,0,0.2-0.1,0.3l0.2,0l0,0.2c0,0,0.1,0,0.2,0c0.1,0.3,0.3,0.7,0.6,0.9c0.2,0.1,0,0.3,0,0.5c0.1,0,0.3-0.1,0.3-0.2
        c0.1,0,0.2,0.1,0.3,0.2c-0.1-0.1-0.2-0.2-0.2-0.3c0.1-0.2,0.1-0.4,0.2-0.6c0.1,0.1,0.2,0.2,0.3,0.3c0.1-0.3,0.2-0.5,0.3-0.7l-0.3,0
        c0,0,0-0.1,0-0.1c0.3,0,0.5,0.2,0.7,0.2c0-0.1,0-0.2,0-0.2l-0.3,0.1c0.1-0.1,0.2-0.3,0.3-0.4l0.2,0c0,0.1,0,0.2,0,0.3l0.1,0
        c0-0.1,0-0.3,0-0.4c0.2-0.1,0.5-0.2,0.8-0.2c0.3-0.3,0.5-0.6,0.8-1c0.4,0.5,0.5-0.5,1-0.5c0.1-0.2,0.2-0.4,0.2-0.7c0,0,0.1,0,0.1,0
        c0,0.1,0,0.3,0.1,0.4c0.2-0.1,0.4-0.1,0.6-0.2c-0.1,0-0.4-0.1-0.5-0.2c0.3-0.2,1-0.1,0.8-0.6c0.4-0.1,0.7-0.4,1-0.7l0,0.2
        c0.4,0.1,0.4-0.2,0.4-0.5c0.1,0,0.3,0,0.4,0c0.2-0.3,0.6-0.5,0.8-0.9c0.5,0.1,0.8-0.3,1.2-0.5c0.2-0.3,0.6-0.3,0.9-0.5l-0.2-0.1
        c0-0.1,0.1-0.2,0.1-0.3c0.4,0.3,0.7,0,1-0.3l0.2,0c0-0.1,0-0.2-0.1-0.3l0.2,0c0-0.1-0.1-0.2-0.1-0.3c0.3,0,0.5-0.1,0.6-0.3
        c0,0,0.1,0.1,0.1,0.1c-0.1,0.1-0.1,0.2-0.2,0.3c0.2,0,0.4-0.1,0.5-0.1c0-0.1-0.1-0.3-0.1-0.4c0.3,0.1,0.4-0.3,0.6-0.4
        c0.3-0.2,0.5-0.4,0.8-0.5c0.6,0,0.9-0.7,1.4-1c0.1,0.1,0.2,0.2,0.3,0.3c0-0.1,0-0.3,0-0.4c0.1,0,0.2,0.1,0.3,0.2
        c0.1-0.1,0.2-0.3,0.3-0.4c-0.1-0.2,0-0.4,0-0.5c0,0.1,0.1,0.3,0.1,0.4c0.1-0.1,0.2-0.4,0.3-0.5c0.1,0.1,0.3,0.2,0.4,0
        c0.1,0,0.2,0,0.4,0.1c-0.2-0.3-0.4-0.6-0.8-0.7c0.1-0.2,0.1-0.4,0.1-0.7c-0.3,0-0.4-0.1-0.4-0.4c-0.1,0.1-0.2,0.2-0.3,0.3
        c-0.2-0.2-0.4-0.4-0.6-0.7c0.1,0,0.2,0,0.3,0c-0.1,0.3,0.3,0.3,0.5,0.4c-0.2-0.3-0.4-0.5-0.7-0.7c0-0.1,0.1-0.2,0.1-0.3
        c-0.3-0.1-0.3,0.3-0.5,0.4c0-0.5-0.4-0.3-0.5,0c-0.2-0.1-0.5-0.5-0.8-0.2c0.1,0.1,0.2,0.2,0.3,0.2c-0.1,0.1-0.2,0.3-0.2,0.4l-0.2,0
        c0.1-0.1,0.2-0.3,0.3-0.4c-0.3,0-0.4,0.1-0.6,0.3c-0.2,0-0.7-0.3-0.5,0.1c-0.4-0.2-0.8,0-1.1,0.2c0.1,0.1,0.2,0.2,0.3,0.2l0,0
        c-0.1,0-0.3,0-0.4,0c0-0.1,0.1-0.3,0.1-0.4c-0.4,0.1-0.5,0.3-0.3,0.5l-0.4-0.1c0-0.1,0-0.2,0-0.3c-0.1,0.1-0.3,0.2-0.4,0.3
        c0,0.2-0.2,0.3-0.3,0.4c0-0.2,0-0.4-0.1-0.5c-0.3,0.2-0.4,0.6-0.8,0.6c0-0.3,0.5-0.4,0.5-0.7c-0.2,0.1-0.5,0.2-0.7,0.3
        c-0.3,0-0.5-0.2-0.8-0.1c0,0.1,0.1,0.2,0.2,0.3c0.2,0,0.3,0,0.5,0c-0.2,0-0.3,0.2-0.4,0.4c-0.3-0.2-0.4-0.4-0.6-0.6
        c-0.3,0.1-0.6,0.1-0.7,0.4c0-0.1,0-0.3,0.1-0.3c-0.3,0-0.6-0.1-0.9-0.1c-0.6,0.1-1.2-0.1-1.7-0.3c0,0.1,0.1,0.3,0.1,0.4l-0.1,0
        c0-0.2-0.1-0.3-0.1-0.5c-0.2,0-0.3-0.1-0.5-0.1c-0.3-0.3-0.7-0.4-1.1-0.5c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1,0-0.3,0-0.5,0
        c-0.1-0.1-0.2-0.3-0.3-0.5c-0.5,0.2-0.6-0.5-1-0.6c-0.4-0.4-0.7-0.7-0.9-1.2c-0.1-0.3-0.3-0.5-0.6-0.7c-0.3-0.7-0.8-1.2-1-1.9
        c-0.4-0.7-0.9-1.5-1.1-2.4c-0.3-0.8-0.5-1.7-0.4-2.6c0.2,0.1,0.1,0.4,0.1,0.6c0.1,0.7,0.6,1.3,0.6,2.1c0.1,0,0.2,0,0.2,0
        c0,0.2,0,0.4,0.1,0.6l0.1-0.1c0,0.1,0.1,0.2,0.2,0.2c0,0.1,0,0.2-0.1,0.3l0.3-0.1l-0.1,0.2c0.1,0,0.3,0,0.4,0c0,0.1,0,0.3,0,0.5
        l0.2-0.1c0,0.2,0,0.3,0.1,0.5c0.1-0.1,0.2-0.2,0.2-0.3c0.1,0.1,0.2,0.3,0.2,0.4c0,0-0.1,0.1-0.1,0.2c0.2,0,0.4,0.1,0.6,0.2
        c0-0.1,0-0.2,0.1-0.3c0,0.1,0,0.2,0,0.3c0.1,0.1,0.2,0.2,0.3,0.4c0-0.1,0.1-0.2,0.1-0.3c0.3,0.6,0.9,1.2,1.5,1.6c0.1,0,0.4,0,0.5,0
        c0,0.1,0,0.3-0.1,0.4c0.1,0,0.2,0.1,0.3,0.2c0-0.2,0-0.5-0.1-0.7c0.3,0.1,0.4,0.3,0.6,0.5c0.1,0,0.3,0,0.4,0l-0.1,0.3
        c0.1-0.1,0.2-0.2,0.3-0.3c0.2,0.1,0,0.3,0,0.5c0.2,0,0.4,0,0.6,0c0,0.1,0,0.3,0,0.4c0.4,0,0.2-0.6-0.1-0.6c0.2-0.1,0.3,0,0.4,0.2
        c0,0,0.1-0.1,0.2-0.1c-0.1,0.3,0.2,0.4,0.4,0.6c0.3,0,0.6,0.1,0.9,0c-0.1-0.1-0.3-0.3-0.4-0.4c-0.1-0.2,0.2-0.1,0.3-0.2l-0.1,0.2
        c0.3-0.2,0.4-0.2,0.5,0.2c-0.1,0-0.3,0-0.4,0c0,0,0.1,0.1,0.1,0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.2,0.2,0.4,0.3,0.7,0.2
        c0.2,0,0.3,0.2,0.6,0.2c-0.2-0.2-0.4-0.2-0.6-0.3c0.1-0.3,0.5-0.3,0.6,0c0.1,0,0.2-0.1,0.3-0.2c-0.1-0.1-0.1-0.3-0.1-0.4
        c0.1,0.1,0.3,0.2,0.4,0.4c0.3-0.1,0.7-0.2,1-0.2l0-0.2c0,0,0.1,0,0.2,0c-0.1,0.1-0.2,0.2-0.2,0.3c0.2,0.4,0.8,0.1,1.1,0
        c0.3-0.2,0.6-0.1,1,0l0-0.1c-0.1-0.1-0.2-0.3-0.3-0.4c0.1,0.1,0.4,0.2,0.5,0.2c0.2-0.1,0.4-0.2,0.3-0.5c0.1,0,0.2,0,0.2,0
        c0,0.1-0.1,0.3-0.1,0.4c0.3,0,0.6-0.1,0.9-0.2c-0.1-0.1-0.2-0.3-0.3-0.4c0.3,0.3,0.7,0,1,0.2c0,0,0.1-0.1,0.1-0.1
        c-0.2-0.1-0.3-0.1-0.5-0.2c0-0.2,0.1-0.5,0.1-0.6l-0.2,0c0,0.3,0,0.5,0,0.8c-0.3-0.3-0.1-0.8-0.1-1.2c-0.3-0.1-0.4,0.2-0.4,0.4
        c0,0-0.1-0.1-0.1-0.1c0.2-0.3,0.1-0.6,0.1-0.9c-0.1,0-0.3,0-0.4,0.1l0-0.2c-0.1,0-0.3,0.1-0.4,0.1c0-0.2,0.1-0.3,0.1-0.4
        c0.1,0,0.2,0,0.3,0c-0.1-0.3-0.3-0.5-0.6-0.4c-0.1-0.1-0.3-0.3-0.4-0.4c0.1,0,0.4,0,0.6,0c-0.4-0.4-0.9-0.6-1.4-0.7
        c0.2,0,0.3-0.2,0.2-0.5l-0.2,0.1l0-0.1c0.6-0.2,0.3,0.7,0.9,0.5c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.3-0.5-0.4-0.3-0.7
        c-0.2-0.1-0.5-0.5-0.4-0.1l0.3,0.1c0,0.1-0.1,0.2-0.1,0.2c-0.2-0.1-0.3-0.1-0.5-0.2c0.1-0.1,0.2-0.2,0.3-0.2c0,0-0.1-0.1-0.2-0.1
        l-0.2,0c-0.1-0.1-0.3-0.3-0.4-0.4c0-0.1,0-0.3,0-0.4c0.2,0.3,0.4,0.5,0.7,0.7c0.1-0.1,0.2-0.1,0.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.3
        c-0.3-0.1-0.3-0.4-0.3-0.6l-0.3,0c0-0.1,0.1-0.2,0.2-0.3c-0.1,0-0.2,0-0.2,0c0-0.2,0.1-0.4,0.1-0.7c-0.3,0-0.6,0-0.8,0.2
        c0.2-0.4-0.3-0.6-0.4-0.9c0.2,0,0.4,0.1,0.5,0.2c-0.1-0.2-0.4-0.2-0.4-0.5c-0.1-0.2-0.1-0.5-0.4-0.4c0-0.1,0-0.2,0-0.3
        c0.1-0.1,0.1-0.2,0.2-0.3c-0.1,0-0.2,0-0.3,0c-0.2-0.2-0.7,0.2-0.8-0.1c0.2,0,0.3,0,0.5-0.1c0.1,0.1,0.2,0.2,0.3,0.2
        c0-0.2-0.1-0.4-0.3-0.4c0.1-0.3,0-0.6-0.1-0.9l-0.1,0c-0.1,0.2-0.2,0.3-0.2,0.5c0.1,0,0.2,0.1,0.3,0.1c-0.1,0.1-0.2,0.4-0.4,0.4
        c0-0.3,0-0.5,0-0.8c-0.2,0.1-0.5-0.1-0.3-0.4c0.1-0.2,0.1-0.4,0-0.6c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2-0.1-0.1-0.3,0.1-0.5
        c-0.3-0.2-0.1-0.4,0-0.7c-0.2-0.2-0.5-0.2-0.3-0.5c-0.1-0.3-0.1-0.9-0.5-0.6l0,0.1l0.1,0.1c0,0.1-0.1,0.2-0.1,0.3
        c-0.3-0.1-0.7,0-0.5,0.4c0.1,0,0.3,0,0.4,0c-0.2,0.1-0.3,0.2-0.5,0.4c0-0.1-0.1-0.3-0.1-0.4c-0.1,0.3-0.3,0.3-0.6,0.4l0.1-0.3
        c-0.2-0.1-0.3-0.1-0.5-0.2c0.4,0.3,0.4,0.8,0.3,1.3c0.2-0.1,0.3-0.5,0.1-0.7c0.2-0.1,0.4-0.1,0.7,0c0,0.2,0,0.4,0.1,0.6
        c0.1-0.1,0.3-0.1,0.4-0.2c-0.1,0.4-0.1,0.8,0.2,1.1l-0.2-0.1c-0.1,0.1-0.2,0.3-0.3,0.4c0,0.1,0.1,0.2,0.1,0.3l0.1,0
        c0-0.2,0.1-0.4,0.3-0.6c0,0.2,0,0.6,0,0.8c0,0.1,0.1,0.3,0.1,0.4c0.2-0.1,0.2-0.2,0.3-0.4c0,0,0.1,0,0.1,0c-0.2,0.2-0.1,0.5-0.2,0.8
        c0.1,0,0.2,0,0.3,0c0,0,0,0.1,0,0.2c-0.2-0.1-0.5-0.2-0.6-0.5c-0.2-0.1-0.5-0.3-0.7-0.3c0,0,0,0.1,0,0.2c0.2,0,0.5,0.2,0.6,0.4
        c-0.2,0.1-0.3,0.3-0.5,0.4c0.1,0.1,0.2,0.2,0.2,0.3c0-0.1,0.1-0.2,0.1-0.3c0.3,0,0.3,0.4,0.3,0.6c-0.1,0-0.3,0-0.5,0l0.1-0.2
        c-0.1,0-0.3-0.1-0.4-0.1c0.2,0.3,0.4,0.5,0.7,0.7c-0.1,0.3,0.3,0.4,0.3,0.6l-0.5-0.2c0,0.3,0.3,0.6,0.6,0.3c0,0,0,0.1,0,0.2
        c-0.2,0-0.3,0.1-0.5,0.1c0,0.2,0.2,0.5,0.1,0.7c-0.1,0.2-0.3,0.3-0.4,0.5c0-0.4,0-0.8-0.3-1c0.3,0,0.4,0.4,0.5,0.6l0.2-0.3
        c-0.1-0.1-0.2-0.2-0.2-0.3c0.3-0.2,0.1-0.5-0.1-0.7c-0.2,0-0.4,0.1-0.5,0.2c0.2,0,0.6-0.2,0.5,0.2c-0.3,0-0.5,0.2-0.8,0.3
        c0.1,0,0.4,0,0.5,0c0,0.2-0.1,0.4-0.1,0.6c0.1,0.2,0.3,0.3,0.4,0.5c-0.2-0.1-0.4-0.3-0.6-0.5l0.1,0.3c-0.1,0-0.3,0-0.4,0
        c0,0.1,0.1,0.3,0.1,0.4c-0.1,0-0.2,0-0.2,0c-0.1,0.4-0.6,0.8-0.9,0.4c-0.3,0.2-0.5,0.6-0.9,0.8c-0.2-0.1-0.4-0.2-0.6-0.3
        c-0.2,0.1-0.3,0.4-0.5,0.2c-0.4-0.2-0.9-0.2-1.3-0.4c0,0-0.1,0.1-0.2,0.2c0,0,0-0.1,0-0.2l0.2,0c-0.3-0.4-0.8-0.7-1.1-1.2
        c-0.2-0.5-0.5-0.9-0.8-1.3c-0.2-0.7-0.5-1.4-0.5-2.1c-0.2-0.4-0.3-0.8-0.4-1.2c0.1,0,0.2-0.1,0.3-0.1c-0.4,0.1-0.5-0.2-0.5-0.5
        c0.1-0.4-0.1-0.7-0.1-1.1c-0.3-0.6,0.3-1.2,0-1.8c0.1,0.1,0.2,0.2,0.3,0.4c0,0.3,0,0.6,0,0.9l0.1,0c0,0.2,0,0.4,0,0.6
        c0.1,0,0.2,0,0.2,0c0,0.3,0.2,0.6,0.2,0.9l0.2-0.1c-0.1,0.2,0.1,0.3,0.3,0.4c0,0.3,0,0.6,0.1,0.8c0.1-0.1,0.2-0.2,0.3-0.3
        c0.1,0.3,0.2,0.8,0.5,0.9c0,0.2,0,0.5,0.4,0.4c0,0.1-0.1,0.2-0.1,0.3c0.1,0,0.2,0,0.3,0c0.2,0.3,0.6,0.6,0.9,0.6l-0.1,0.2
        c0.1,0,0.2,0,0.3,0c0,0-0.1,0.1-0.2,0.1c0.1,0.1,0.3,0.2,0.5,0.3l0,0.1c0.1,0.1,0.2,0.2,0.4,0.3c-0.1-0.3-0.2-0.5-0.3-0.8
        c0.2,0.1,0.3,0.3,0.5,0.4c0,0.1,0.1,0.3,0.1,0.4c0.1-0.2,0.3-0.3,0.4-0.5c-0.1-0.3,0-0.5,0.1-0.7c-0.2-0.7-0.2-1.5-0.1-2.2
        c-0.1,0-0.2-0.1-0.3-0.2c0-0.1,0.1-0.3,0.1-0.4c-0.4-0.2-0.6-0.6-0.3-1c-0.3-0.4-0.6-0.9-0.9-1.4l0.2,0c0,0.2,0.2,0.3,0.3,0.4
        c0.1-0.4-0.3-0.8-0.7-0.6c0,0-0.1-0.1-0.1-0.2c0.1,0,0.3,0.1,0.4,0.1c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1,0-0.2,0-0.3,0
        c-0.1-0.6-0.3-1.2-0.8-1.5c0-0.2,0-0.3,0-0.5c-0.2-0.1-0.5-0.2-0.7-0.2c0,0,0.1-0.1,0.2-0.1c-0.2-0.5-0.6-1-1-1.4
        c-0.1,0-0.2,0.1-0.2,0.1c0,0-0.1-0.1-0.1-0.1c0.1,0,0.2,0,0.3-0.1l0-0.2c-0.3-0.3-0.6-0.6-0.8-0.9c-0.2-0.2-0.3-0.7-0.6-0.6l0.1-0.1
        c-0.1-0.2-0.1-0.4-0.1-0.5c-0.3-0.3-0.5-0.7-0.9-0.7c0-0.1,0-0.2,0-0.2c-0.1-0.2-0.3-0.4-0.4-0.5c0-0.1,0-0.3,0-0.4
        C309.4,331.5,309.4,331.6,309.3,331.6 M384.5,331.8c0.1,0.1,0.2,0.1,0.3,0.1C385.1,331.7,384.4,331.5,384.5,331.8 M152.7,331.8
        c-0.1,0.2,0,0.3,0.2,0.2C153,331.8,152.9,331.7,152.7,331.8 M231.2,331.8c-0.1,0.2,0,0.3,0.2,0.2
        C231.6,331.8,231.5,331.7,231.2,331.8 M365.3,331.8C365.1,332.2,365.8,331.9,365.3,331.8 M128.4,331.9c-0.1,0.4,0.5,0.4,0.7,0.4
        C128.9,332.1,128.6,332,128.4,331.9 M253.9,332L253.9,332c0.3,0.1,0.3,0.3,0.3,0.5c0.1,0,0.3,0,0.4,0
        C254.4,332.3,254.1,332.1,253.9,332 M363.3,332.2c0.2,0.3,0.1,0.4-0.2,0.5c0,0,0,0.1,0,0.1c-0.2,0-0.4,0.1-0.6,0.1
        c-0.1,0.2-0.3,0.3-0.5,0.3c0,0.1,0,0.3,0,0.4c0,0-0.1,0-0.2,0l0-0.4c-0.2,0.2-0.3,0.5-0.4,0.7c0.2-0.1,0.3-0.2,0.5-0.3
        c-0.2,0.2-0.4,0.3-0.6,0.5c-0.1,0.1,0,0.2,0,0.3c0,0-0.1,0-0.1,0c0-0.1,0-0.3,0-0.4c-0.1,0.1-0.3,0.2-0.3,0.2l-0.1-0.3
        c-0.2,0.2-0.4,0.5-0.6,0.6c0.1,0.1,0.3,0.1,0.5,0.1l-0.1-0.3c0.1,0,0.2,0,0.3,0c-0.1,0.2-0.2,0.3-0.4,0.4c0,0.1,0.1,0.2,0.2,0.3
        c0,0-0.2,0-0.2,0c-0.1-0.1-0.1-0.4-0.4-0.3c-0.1,0.3-0.4,0.4-0.7,0.6c0,0.4-0.8,0.2-0.8,0.7c0.1-0.1,0.3-0.2,0.4-0.2
        c0.1,0.1,0.2,0.2,0.3,0.2c-0.1,0-0.3-0.1-0.4-0.1l0,0.3c-0.2-0.2-0.4-0.1-0.6-0.1c-0.1,0.1-0.2,0.3-0.3,0.4
        c-0.2,0.2,0.2,0.3,0.2,0.5c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0.2-0.3,0.2-0.5,0.3c0.2,0,0.5,0.1,0.7-0.1c0.2-0.1,0.4-0.2,0.6-0.3
        c-0.1-0.1-0.3-0.1-0.4-0.1c0-0.1,0.1-0.2,0.1-0.2c0.1-0.1,0.3-0.2,0.4-0.2c0.1,0.2,0.3,0.4,0.2,0.6c0.1,0.1,0.2,0.2,0.2,0.2l0.2-0.2
        c0.1,0.1,0.2,0.2,0.2,0.3c0.1-0.2,0.3-0.4,0.3-0.6c0.2-0.1,0.4-0.2,0.6-0.3c0,0,0,0.1,0,0.1c-0.2,0.1-0.5,0.2-0.5,0.5
        c0.2,0,0.5,0,0.7,0c-0.1,0.1-0.2,0.3-0.3,0.4c0.3-0.1,0.6-0.6,1-0.3c0.1-0.1,0.2-0.1,0.4-0.1c0,0.1,0.1,0.3,0.1,0.3
        c0.3,0,0.6-0.2,0.8-0.3c0.3-0.1,0.6-0.1,0.7-0.4c0.2,0,0.4,0.2,0.5,0.4c-0.1,0-0.2,0-0.2,0c-0.2-0.6-0.4-0.1-0.6,0.2
        c-0.1,0-0.2-0.1-0.2-0.2c0,0-0.1,0.1-0.1,0.1c0.1,0.1,0.1,0.3,0.1,0.5c0.1-0.1,0.3-0.2,0.4-0.3c-0.1-0.3,0.2-0.5,0.3-0.1
        c0.1,0,0.3,0,0.4,0c0-0.1-0.1-0.2-0.1-0.3c0.2-0.1,0.4,0,0.5-0.3c0.1,0.1,0.3,0.2,0.4,0.4c-0.2-0.1-0.3-0.1-0.5-0.2
        c-0.1,0.2-0.2,0.4-0.4,0.5c0,0.1-0.1,0.2-0.1,0.3c0.4-0.2,0.8-0.2,1.2-0.5c0,0,0.1,0,0.2,0c0.2-0.2,0.4-0.3,0.5,0
        c-0.2,0.1-0.5,0.1-0.5,0.5c0.3-0.1,0.7-0.2,0.8-0.6c0.1,0.3,0.1,0.6,0.2,0.8c0.2,0,0.3-0.1,0.5-0.1l-0.1-0.4
        c0.1,0.1,0.3,0.2,0.4,0.3c0.4-0.1,0.6-0.5,0.6-0.9c0.2,0.4,0.7,0,0.9,0.4c-0.3-0.1-0.5,0-0.8,0c-0.1,0.1-0.2,0.3-0.3,0.3
        c0.2,0.1,0.4,0.1,0.6,0c0.2-0.3,0.5,0.5,0.5-0.2c0,0.1,0.1,0.3,0.2,0.4c0.3-0.2,0.5-0.3,0.7-0.5c0,0.1,0,0.3,0,0.4
        c0.1-0.1,0.2-0.3,0.3-0.4c0,0.5,0.3,0.3,0.5,0c0.1,0,0.2,0.1,0.3,0.1c0-0.1,0.1-0.2,0.2-0.2c0,0.1,0.1,0.3,0.2,0.4
        c0.2-0.1,0.4-0.1,0.5-0.2c-0.1,0-0.4,0-0.5,0.1c0.1-0.6,0.8-0.1,1.1-0.5c0.1,0,0.2,0,0.2,0c0.3-0.5,0.9-0.6,1.4-0.5l-0.1-0.2
        c0.1,0,0.3,0.1,0.4,0.1c-0.1-0.1-0.2-0.4-0.3-0.5c0.3,0.2,0.6,0.1,0.9-0.1c0.1-0.1,0.1-0.2,0.2-0.3c-0.1,0-0.3,0-0.4,0
        c-0.1,0-0.2,0.1-0.2,0.2c0-0.2,0.2-0.7-0.2-0.4c0,0.1,0,0.2,0,0.3l-0.1,0c0.1-0.3,0-0.6-0.2-0.7c-0.2-0.1-0.4,0-0.5-0.2
        c-0.1-0.1-0.2-0.2-0.3-0.3l0-0.1c-0.1,0-0.2,0-0.3,0.1l0-0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.1-0.3-0.5-0.2-0.7-0.2
        c0.2-0.3-0.3-0.6-0.1,0c-0.1-0.2-0.3-0.3-0.6-0.3c0-0.3-0.1-0.5-0.1-0.7c-0.2,0.2-0.4,0.1-0.6,0.1c0,0.1,0.1,0.2,0.1,0.3
        c0.1-0.2,0.5-0.4,0.6,0l-0.3,0c0,0.1,0.1,0.2,0.3,0.3c-0.1,0-0.2,0-0.3,0l0.1-0.1c-0.2-0.1-0.4-0.2-0.6-0.3c0-0.1-0.1-0.3-0.1-0.4
        c0,0.1-0.1,0.2-0.1,0.3c-0.4,0-0.7-0.3-1-0.4c0,0.1,0,0.2-0.1,0.4c0,0-0.1-0.1-0.2-0.1c0.1-0.1,0.1-0.2,0.2-0.3
        c-0.3,0-0.6,0.4-0.9,0.2c0.3,0,0.5,0,0.5-0.3c-0.4,0-0.7,0.1-1,0.2c0-0.3,0.4-0.3,0.5-0.6c-0.3,0.1-0.6,0.3-1,0.1
        c0.1,0.2,0.3,0.3,0.4,0.5c-0.1,0-0.2-0.2-0.3-0.2c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.2,0-0.3c-0.3,0-0.6,0-0.8,0.1
        c-0.2-0.1-0.4-0.1-0.5,0c-0.1,0-0.2,0.1-0.2,0.2c0-0.1-0.1-0.2-0.1-0.3C364.1,332.1,363.7,332.1,363.3,332.2 M362.9,332.6
        c0-0.1,0.1-0.2,0.1-0.3C362.6,332.3,362.4,332.9,362.9,332.6 M313.7,332.8c0.2,0.1,0.4-0.1,0.3-0.3
        C313.9,332.4,313.7,332.6,313.7,332.8 M233.1,332.7C232.8,333.1,233.5,332.7,233.1,332.7 M382,332.6c-0.1,0.2,0,0.3,0.2,0.2
        C382.3,332.6,382.2,332.5,382,332.6 M254,332.5l0.1,0.3l-0.4-0.1c0.3,0.3,0.6,1,1,0.7c-0.1,0-0.3-0.1-0.4-0.2
        C254.3,333,254.3,332.6,254,332.5 M254.4,332.8c-0.1,0.2,0,0.3,0.2,0.2C254.7,332.8,254.6,332.7,254.4,332.8 M130.5,332.9
        c-0.1,0.2,0.2,0.3,0.3,0.2C130.9,332.9,130.6,332.7,130.5,332.9 M131.1,333.1C131.2,333.1,131.2,333.1,131.1,333.1 M351.9,333
        C352,333.1,352,333.1,351.9,333 M380.5,333.2L380.5,333.2l0.1,0.1L380.5,333.2L380.5,333.2 M132.1,333.5L132.1,333.5L132.1,333.5
        L132.1,333.5L132.1,333.5 M314.8,334.1c0.2-0.1,0.1-0.2,0-0.3C314.6,333.9,314.6,334,314.8,334.1 M361.1,333.8
        c-0.3,0-0.2,0.4,0.1,0.3C361.5,334.2,361.4,333.7,361.1,333.8 M254.9,334.1c-0.1,0.2,0,0.3,0.2,0.2
        C255.2,334.1,255.1,334,254.9,334.1 M218,334.4c0-0.1-0.1-0.2-0.1-0.3C217.6,334.1,217.8,334.5,218,334.4 M314.8,334.4
        C314.6,334.8,315.3,334.3,314.8,334.4 M281.7,334.7C281.5,335.1,282.2,334.7,281.7,334.7 M304.9,335.1c0.1,0,0.2,0,0.3,0
        C305.5,334.7,304.7,334.7,304.9,335.1 M315.6,334.8c0.1,0.1,0.2,0.3,0.2,0.5c-0.1,0-0.2,0-0.3,0l0.1,0c0.1,0.4,0.4,0,0.4-0.2
        C315.9,335,315.8,334.9,315.6,334.8 M376,335C376.1,335.1,376.1,335.1,376,335 M136.5,335.3c0,0.2,0.3,0.4,0.3,0.2
        C136.9,335.3,136.6,335,136.5,335.3 M305.6,335.4c0.2-0.1,0.2-0.2,0-0.3C305.4,335.2,305.4,335.3,305.6,335.4 M374.8,335.3
        C374.6,335.8,375.3,335.3,374.8,335.3 M137.6,335.6c0,0.2,0.1,0.3,0.3,0.3C138,335.6,137.9,335.5,137.6,335.6 M195.6,335.5
        c0,0.1,0,0.3,0,0.4c-0.1-0.1-0.3-0.2-0.4-0.2c0.1,0.1,0.2,0.3,0.3,0.4c0.2-0.2,0.5-0.3,0.6-0.6L195.6,335.5 M305.2,336.2
        c-0.1,0.2,0,0.3,0.2,0.2C305.5,336.1,305.4,336.1,305.2,336.2 M139.1,336.3C139.2,336.4,139.2,336.4,139.1,336.3 M160.8,336.3
        C160.9,336.4,160.9,336.4,160.8,336.3 M251.7,336.7c0.4,0,0.1,0.4,0,0.5c0.2,0,0.4,0.1,0.5,0.1c0-0.1,0-0.2,0-0.2l-0.2,0.1
        C252,337,252.1,336.4,251.7,336.7 M213.4,336.7c0,0.2-0.1,0.4-0.3,0.4c0,0.1,0,0.2,0.1,0.3c0.1-0.2,0.2-0.5,0.4-0.7
        C213.5,336.8,213.4,336.7,213.4,336.7 M149.3,337.3c0.2,0.1,0.4,0.2,0.4,0.5c0.1,0.1,0.3,0.1,0.4,0.2c0-0.2,0-0.4,0.1-0.6
        c-0.1,0-0.2,0.1-0.3,0.1C149.7,337.4,149.4,336.8,149.3,337.3 M150.8,337.5c0.1,0.2,0.2,0.2,0.3,0
        C151,337.3,150.9,337.3,150.8,337.5 M305.3,337.5c-0.1,0.1,0,0.4,0.2,0.3C305.6,337.7,305.5,337.4,305.3,337.5 M154,337.6
        C154.1,337.7,154.1,337.7,154,337.6 M356.7,337.6C356.7,337.7,356.7,337.7,356.7,337.6 M251.6,337.8
        C251.7,337.8,251.7,337.8,251.6,337.8 M214.3,338c-0.2,0.3,0.6,0.6,0,0.7c0-0.1-0.1-0.2-0.2-0.2l0,0c0,0.2,0,0.4,0,0.5
        c0,0.1,0.1,0.2,0.1,0.4l-0.3-0.2c0,0.2-0.1,0.4-0.1,0.6c0,0-0.1,0.1-0.2,0.1l-0.1,0c0,0.2,0,0.4,0.2,0.5c-0.1,0.1-0.3,0.2-0.4,0.2
        c0,0.1,0,0.2,0,0.2c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.2-0.2,0.5-0.4,0.7c0,0.1,0,0.2,0.1,0.3c-0.1,0.1-0.2,0.1-0.4,0.2
        c0,0.2,0,0.3-0.1,0.5c-0.2,0.3-0.4,0.6-0.7,0.9c-0.2,0.4-0.6,0.8-1.1,1.1c0.3-0.5,0.5-1,0.7-1.5c0.2-0.6,0.6-1.1,0.6-1.7
        c0,0-0.1,0-0.2,0c0.1,0.6-0.4,1-0.7,1.4c-0.1,0.2-0.3,0.4-0.5,0.6c-0.3,0.1-0.5,0.4-0.9,0.4c0,0.2,0.5,0.8-0.1,0.5
        c0.2,0.2,0.5,0.4,0.5,0.7c-0.2-0.1-0.4-0.3-0.6-0.4c-0.1,0.3,0.2,0.4,0.5,0.5c0,0.1-0.1,0.3-0.1,0.4c0.3,0,0.3-0.2,0.3-0.4
        c0.1,0.1,0.1,0.3,0,0.4c0.1,0.2,0,0.6,0.3,0.6c-0.1,0.1-0.2,0.1-0.3,0.2c0.2,0.2,0.4,0.5,0.6,0.8l-0.3,0c0,0.1,0.1,0.2,0.1,0.3
        l0.1,0c0.1,0.2-0.1,0.6,0.3,0.4c0,0.1-0.1,0.3-0.1,0.4c0.6-0.5,1.1-1.2,1.5-1.9c0-0.1-0.1-0.2-0.1-0.2c0.1-0.3,0.3-0.7,0.4-1
        c-0.3,0.2-0.5,0.5-0.9,0.6c-0.1,0.1-0.3,0.2-0.5,0.4c-0.2,0-0.4,0-0.6,0.1c0.6-0.7,1.2-1.5,1.5-2.4c0.5-0.6,0.6-1.3,1.1-1.9
        c0.2-0.3,0.4-0.6,0.3-0.9c0.1,0,0.2,0,0.2,0c0-0.2,0.1-0.4,0.2-0.6c-0.1-0.3-0.3-0.5-0.3-0.8c0.4-0.1,0.4,0.2,0.4,0.6
        c0.1,0,0.2,0.1,0.2,0.1c-0.1-0.1-0.2-0.3-0.2-0.5c0.1,0,0.3,0.1,0.5,0.1c0.1-0.5-0.6-0.5-0.7-1c0.3,0.1,0.6,0.2,0.9,0.3l0-0.1
        c-0.1,0-0.3,0-0.4,0c-0.2-0.1-0.3-0.3-0.4-0.4l0.2-0.1c0.1,0.1,0.3,0.2,0.3,0.3c-0.1-0.3-0.2-0.5-0.3-0.8c0.1,0,0.3-0.1,0.4-0.1
        c-0.1,0-0.3,0-0.4,0l0-0.3C214.3,337.9,214.3,338,214.3,338 M236.1,337.9C236.2,338,236.2,338,236.1,337.9 M228.2,339.3
        c0.2-0.1,0.2-0.3,0-0.4C228,339.1,228,339.2,228.2,339.3 M207.4,339.2c-0.1,0.1,0,0.5,0.2,0.4C207.9,339.5,207.6,339,207.4,339.2
        M358.8,339.5c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2-0.6-0.8,0.2-1-0.3c-0.2,0.2-0.3,0.3-0.6,0.3c-0.3,0-0.6,0.2-0.9,0.1
        c-0.9,0.5-1.9,0.5-2.8,1c-0.2,0.5-0.8,0.2-1.1,0.6c-0.1,0-0.2,0-0.3,0.1c0,0.1,0.1,0.2,0.1,0.4c-0.2,0.1-0.4,0.2-0.4,0.5
        c0-0.1-0.1-0.2-0.2-0.2c-0.5,0.4-0.8,0.8-1.2,1.2c0-0.1,0-0.3,0.1-0.4c-0.1,0-0.3,0.1-0.4,0.2c-0.1,0.3-0.3,0.5-0.6,0.5
        c0.1,0.1,0.1,0.2,0.2,0.3c-0.3,0-0.6,0.1-0.6,0.4c0,0-0.1-0.1-0.2-0.2l-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.3,0.1
        c0,0.2,0,0.4-0.1,0.6c-0.4-0.1-0.7,0.2-0.7,0.6c-0.2,0.2-0.4-0.1-0.5-0.2c-0.2,0.2,0,0.4,0.1,0.6c-0.6-0.3-0.5,0.7-1.1,0.5
        c0,0.1,0.2,0.3,0.2,0.4l-0.3,0c-0.1-0.1-0.2-0.2-0.3-0.3c0,0.1,0.1,0.3,0.2,0.4l-0.4,0c0-0.1-0.1-0.2-0.2-0.3c0,0.1,0,0.3,0.1,0.4
        c-0.1,0.1-0.3,0.1-0.4,0.2c-0.2,0.3-0.5,0.6-0.9,0.6c0,0.1,0,0.2,0,0.2l-0.2,0l0,0.2l-0.2,0c0.1,0.2,0.1,0.3,0.2,0.5
        c-0.3,0-0.3-0.4-0.5-0.5l0.1,0.3c-0.4-0.1-0.5,0.3-0.7,0.5c-0.2,0.3-0.5,0.4-0.8,0.5c-0.1-0.3-0.4-0.2-0.6,0.1
        c0.1,0,0.3,0.1,0.4,0.1c-0.1,0.2-0.3,0-0.5,0c0,0.3,0,0.7,0,1c-0.1,0.2-0.3,0.4-0.2,0.7c0.2,0.6-0.3,1.2,0,1.8
        c0.2-0.1,0.4-0.2,0.6-0.3c0,0.1,0,0.3,0,0.4c0.2-0.1,0.6-0.2,0.5-0.5c-0.2,0.1-0.4,0.2-0.5,0.2c0.1-0.1,0.3-0.2,0.4-0.3
        c0.3,0.1,0.7,0.1,1-0.1c0.2-0.2,0.5-0.5,0.8-0.4c0.2-0.1,0.3-0.4,0.6-0.3c0.2-0.2,0.4-0.3,0.6-0.5c0.1,0,0.2,0,0.3,0.1
        c0-0.3,0.4-0.6,0.6-0.2c0.1,0,0.3-0.1,0.4-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c0.4,0,0.7-0.2,1.1-0.3c0-0.1-0.1-0.2-0.1-0.4
        c0.2,0,0.6,0.2,0.6-0.2c0.3-0.1,0.6-0.4,0.9-0.4l-0.1-0.2c0.1,0,0.3,0.1,0.3,0.1c0-0.1,0-0.2-0.1-0.3c0.2,0,0.4-0.2,0.6-0.4
        c0,0.1,0,0.3,0,0.5c0.1-0.1,0.2-0.2,0.4-0.3c-0.1-0.1-0.3-0.2-0.3-0.2c0-0.1,0-0.2,0-0.3c0,0.1,0.1,0.2,0.2,0.3
        c0-0.3,0.2-0.4,0.4-0.5c0,0.3-0.4,0.5-0.2,0.8c0.3-0.2,0.9-0.2,0.6-0.7c0.2,0.1,0.3,0.2,0.5,0.3c-0.1-0.1-0.3-0.4-0.3-0.6l0.5,0.3
        c0-0.1,0-0.2,0.1-0.3c0.1,0.1,0.3,0.2,0.4,0.3c0.1-0.1,0.3-0.2,0.4-0.4c0.3-0.1,0.6-0.2,0.8-0.4c0-0.1-0.1-0.2-0.2-0.3
        c0.1,0,0.4,0.1,0.6,0.1l-0.1-0.2c0.1,0.1,0.3,0.2,0.4,0.2l-0.2-0.4c0.2,0,0.4,0,0.5,0.1c0.1-0.2,0.1-0.5,0.2-0.7c0,0.1,0,0.3,0,0.4
        l0.2,0c0.1-0.2,0.4-0.3,0.4-0.6c0.3,0,0.6-0.1,0.9-0.2c0-0.1,0.1-0.3,0.2-0.4l0.1,0.1c0,0.1,0.1,0.2,0.1,0.3
        c0.1-0.1,0.2-0.2,0.1-0.3l-0.1-0.1c-0.1-0.2,0.1-0.3,0.2-0.4c0,0.1,0,0.4,0,0.5c0.2-0.2,0.3-0.4,0.5-0.6c0.1,0,0.2,0.1,0.3,0.1
        c0-0.1-0.1-0.2-0.2-0.3c0.3,0,0.5,0,0.8,0c-0.2-0.2-0.4-0.2-0.6-0.2c0.1-0.1,0.3-0.1,0.4-0.2l0-0.2c0,0.1,0.1,0.3,0.1,0.4
        c0.1-0.2,0.3-0.4,0.5-0.5c0.1-0.1,0.3-0.2,0.4-0.4l0.1,0.3c0.2-0.2,0.4-0.4,0.7-0.5l0,0.2c0,0,0.1,0,0.1,0l-0.1-0.2l0.2,0.1l0-0.2
        l0.2,0.1c0.1-0.1,0.3-0.2,0.5-0.2c-0.1-0.2-0.3-0.2-0.5-0.2c0.3-0.3,0.8-0.2,1.1-0.5c0.1,0,0.3,0.1,0.4,0.1
        c-0.1-0.1-0.6-0.5-0.1-0.5c0.1,0.1,0.2,0.2,0.2,0.2c0.2,0,0.2-0.3,0.3-0.4l0.4,0l-0.1,0.2c0,0,0.1,0,0.1,0l0-0.2l0.2,0
        c0.1-0.3,0.4-0.4,0.5-0.7c0.1,0,0.3,0,0.5,0c0.1-0.2,0.1-0.3,0.2-0.4c0.2,0,0.4,0.1,0.6,0.1c-0.1-0.1-0.2-0.3-0.3-0.4
        c0.1,0.1,0.2,0.1,0.3,0.2c0.1-0.1,0.4-0.3,0.5-0.4c0,0.1,0,0.3,0,0.4c0.1,0,0.2-0.1,0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
        c0.2,0,0.4,0.1,0.6,0.2l0-0.3c0.1,0,0.3,0.1,0.4,0.1c-0.1-0.1-0.2-0.1-0.4-0.2l0-0.2c0,0-0.1,0.1-0.2,0.2c-0.1-0.1-0.2-0.3-0.2-0.3
        c-0.1,0.1-0.2,0.2-0.4,0.3c0-0.1,0-0.3-0.1-0.4c-0.3,0-0.6,0.1-0.9,0.2c0-0.1-0.1-0.3-0.2-0.3c-0.1,0.1-0.2,0.2-0.3,0.3
        c-0.4-0.1-0.7-0.2-0.9-0.5c0,0.1,0,0.4,0,0.5c-0.2-0.1-0.3-0.2-0.3-0.3c-0.2,0-0.4,0-0.5,0c-0.2,0-0.3,0.1-0.5,0.1
        c-0.2-0.2-0.4-0.3-0.5,0C359.2,339.6,359,339.5,358.8,339.5 M269.7,339.6c0,0.2,0,0.3,0,0.5C270,340.1,270.1,339.4,269.7,339.6
        M151.1,340.1c-0.1,0-0.2,0-0.3,0c-0.2,0.2-0.5,0.1-0.5-0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3-0.1-0.6-0.1-0.9-0.1c0,0.1,0,0.3,0,0.3
        c-0.1-0.1-0.3-0.2-0.3-0.3c-0.2,0.2-0.5,0.3-0.7,0.4c0.1,0,0.3,0,0.4,0l0,0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.3,0.2,0.5,0.3,0.8,0.4
        c0,0.5,0.3,0.2,0.6,0.1c0.1,0.1,0.2,0.3,0.2,0.4c0.1,0,0.9,0.2,0.4,0.3c-0.1,0-0.2,0-0.3,0c0.1,0.2,0.4,0.4,0.5,0.1
        c0.1,0.1,0.3,0.2,0.3,0.4c0-0.2-0.1-0.4-0.1-0.6c0.2,0.1,0.3,0.2,0.4,0.3c0.2,0,0.4,0,0.6,0c0,0,0,0.1,0,0.1c-0.2,0-0.4,0-0.6,0
        c0.1,0.2,0.2,0.3,0.3,0.5c0.1-0.1,0.3-0.3,0.4-0.4c0.1,0.3-0.1,0.4-0.2,0.6c0.3-0.3,0.6-0.2,0.8,0.2c0-0.1,0-0.3,0-0.5
        c0.1,0.1,0.2,0.2,0.2,0.3c0.2,0,0.3,0.1,0.5,0.2c-0.1,0-0.4,0-0.5,0l0,0.1c0.3-0.1,0.5,0.3,0.8,0.4c0.3,0,0.6-0.2,0.9-0.3
        c-0.1,0.2-0.3,0.3-0.4,0.4c0.2,0.1,0.3,0.1,0.5,0c0.3,0.2,0.5,0.4,0.8,0.6c0,0.1,0,0.3,0,0.4c0.1-0.1,0.4-0.1,0.4-0.3
        c-0.1,0-0.3,0.1-0.4,0.1c0-0.2,0.2-0.3,0.4-0.3c0,0.1,0,0.3,0,0.4c0.1,0,0.3,0,0.4,0c0,0.1-0.1,0.2-0.2,0.3c0.1,0,0.3-0.1,0.3-0.1
        c0.2,0.3,0.4,0.7,0.8,0.8c-0.2-0.2-0.4-0.5-0.5-0.7c0.5,0.2,0.7,0.9,1.3,0.9c0,0.2,0,0.4,0,0.6c0,0,0.1,0,0.1,0c0-0.1,0-0.3,0-0.4
        c0.1,0,0.4,0.1,0.5,0.1c0,0.1-0.1,0.2-0.1,0.3c0.2,0,0.4,0,0.6-0.1c0,0.2-0.1,0.5,0.2,0.6c0-0.1,0-0.3,0-0.4l0.3,0
        c0,0.1-0.1,0.2-0.1,0.3c0.1,0,0.2,0,0.3,0c0,0.2,0.1,0.4,0.2,0.5c0.2,0,0.3,0,0.5,0c0,0,0-0.1,0-0.2c0.1,0,0.2,0,0.3,0
        c0,0.1-0.1,0.3-0.1,0.4c0.1-0.1,0.2-0.1,0.3-0.2c0.2,0.1,0.4,0.2,0.5,0.5c0.1-0.2,0.4-0.3,0.6-0.2c0.2-0.1,0.3-0.2,0.5-0.3
        c-0.2,0.2-0.4,0.5-0.6,0.7c-0.1,0-0.2-0.1-0.2-0.1l0,0.2c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0.1c0.2,0.1,0.4,0.1,0.6,0.2l-0.2-0.1
        c0.1-0.2,0.4-0.3,0.7-0.4l-0.1,0.3c0.3,0.1,0.6,0.3,0.9,0.3c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2,0-0.4,0-0.5,0.1c0-0.1,0.1-0.2,0.1-0.2
        c0.2,0,0.4,0,0.6,0.1c0.1,0.2,0.2,0.4,0.4,0.6c0-0.1,0-0.4-0.1-0.5c0.1,0.2,0.2,0.5,0.2,0.7c0.2,0.1,0.7,0.1,0.7,0.5
        c0.3,0.1,0.5,0.3,0.8,0.4c0,0.1,0,0.3,0,0.4c0.2-0.1,0.5-0.3,0.7,0c-0.1,0-0.3,0-0.4,0c-0.2,0.3,0.3,0.3,0.5,0.3
        c0.3,0,0.4,0.2,0.6,0.3c0,0.1,0,0.3,0.1,0.4c0.1,0,0.2-0.1,0.3-0.1l0,0.2c0.3-0.1,0.3,0.1,0.3,0.3c0.3,0,0.5,0.2,0.7,0.3
        c0.4,0.4,1,0.3,1.3,0.8c0.6,0.1,1.1,0.4,1.6,0.7c0.3,0.4,0.7,0.4,1.2,0.6c0.4,0.1,0.6,0.5,1,0.6c0.5,0.1,0.7,0.6,1.3,0.7
        c0-0.1,0.1-0.3,0.1-0.3c0-0.4-0.1-0.7,0.1-1c-0.2-0.4-0.2-0.8,0-1.1c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.1,0.1-0.3,0.2-0.4
        c0-0.1-0.1-0.2-0.2-0.2c0.3-0.2,0-0.5-0.2-0.7c-0.2,0.1-0.3,0.3-0.5,0.4c0.1-0.2,0.2-0.3,0.3-0.4c-0.2-0.1-0.3-0.3-0.4-0.5
        c-0.5,0.2-0.8-0.2-1.1-0.5c-0.3-0.1-0.6-0.1-0.8-0.4c-0.1,0-0.3,0-0.4,0c0.1-0.1,0.3-0.3,0.4-0.4c-0.2-0.1-0.5,0-0.7,0.1
        c-0.7-0.2-1-1-1.6-1.3c0.1,0.1,0.2,0.2,0.2,0.3l-0.1,0.1l-0.1-0.3c-0.1,0.1-0.2,0.2-0.2,0.3c0-0.1,0-0.2,0-0.3l-0.3-0.1
        c0.1,0,0.3,0,0.4,0.1c-0.1-0.1-0.2-0.4-0.2-0.6c-0.2,0-0.3,0-0.5,0c-0.2-0.2-0.4-0.5-0.7-0.6c-0.1-0.1-0.4-0.1-0.4-0.3
        c-0.3,0.2-0.4-0.2-0.8-0.1c0.1-0.1,0.1-0.3,0.2-0.4c-0.2,0-0.3-0.1-0.5-0.1c0-0.1-0.1-0.3-0.1-0.4c-0.5,0.1-0.7-0.5-1.1-0.3
        c0.1-0.3,0-0.5-0.2-0.7l0,0.3c0-0.1,0-0.2,0-0.3c-0.1,0-0.3,0-0.4,0c0-0.2,0.1-0.4,0-0.6c-0.2,0-0.3,0-0.5,0
        c-0.1,0.1-0.2,0.2-0.2,0.2c0.1-0.4-0.3-0.5-0.6-0.4c0.1-0.1,0.1-0.2,0.2-0.3c-0.3,0-0.5-0.3-0.7-0.5c-0.1,0-0.2-0.1-0.3-0.2l0.1,0.4
        c-0.5-0.3-0.8-0.9-1.3-1.2c0,0-0.1,0.1-0.2,0.1c-0.1-0.3-0.6-0.4-0.3-0.8c-0.2,0-0.3-0.1-0.5-0.2c-0.3-0.2-0.8-0.1-1-0.5
        c-0.9-0.3-1.9-0.5-2.7-0.9c-0.3,0.1-0.6-0.1-0.9-0.1c-0.3,0-0.5-0.1-0.6-0.3c-0.2,0.6-0.8-0.3-1,0.3c-0.2-0.1-0.3-0.2-0.5-0.3
        c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2-0.2-0.4-0.3-0.6,0c-0.5,0-1-0.2-1.4,0.2c0-0.1,0-0.3,0-0.4C151.3,339.8,151.2,340,151.1,340.1
        M371.2,339.9C371.3,340,371.3,340,371.2,339.9 M281.2,340.8l-0.2,0c0.1,0.1,0.2,0.1,0.4,0.2l0-0.2c0.3-0.1,0.3-0.4-0.1-0.4
        c0.1-0.2,0.2-0.3,0.3-0.5C281.1,340,281.2,340.5,281.2,340.8 M460.3,340C460.4,340.1,460.4,340.1,460.3,340 M218.1,340.7
        c0.3-0.1,0-0.5-0.2-0.6C217.8,340.2,218,340.6,218.1,340.7 M52.3,340.3C52.4,340.4,52.4,340.4,52.3,340.3 M231.4,340.4
        c0,0.2,0,0.3,0,0.5l0.2,0c0,0.1,0,0.2,0,0.3l0.1,0c0,0.1,0,0.3,0,0.4c0,0,0.1,0,0.2,0c0,0.2,0.1,0.4,0.1,0.6l0.2,0
        c0,0.1-0.1,0.4-0.1,0.5c0.1,0,0.3-0.1,0.4-0.2c-0.3,0.2,0,0.5,0,0.8l0.2-0.1c0,0.7,0.4,1.2,0.9,1.6c-0.6-0.2-1-0.7-1.5-1
        c-0.3-0.4-0.8-0.7-1-1.2c0,0.3-0.1,0.6-0.2,0.9l-0.2,0c0,0,0,0.1,0,0.2c0.1,0,0.3,0,0.4,0c0,0.1,0,0.4,0,0.5
        c0.2,0.2,0.3,0.4,0.3,0.6c0.2,0.4,0.8,1,0.2,1.3c0.1,0,0.2,0,0.3,0c0-0.1,0-0.3,0-0.3c0.4,0,0.2,0.6,0.5,0.7c0,0.1,0,0.3,0,0.4
        c-0.1-0.1-0.2-0.2-0.1-0.4c-0.2-0.1-0.3-0.1-0.5-0.2c0.1,0,0.2,0.1,0.2,0.2c0.1,0.2,0.1,0.4,0.2,0.7c0.1-0.1,0.2-0.3,0.3-0.4
        c0.2,0.3,0.4,1-0.2,1c0.1,0,0.3,0.1,0.4,0.1l-0.1,0.2c0.1-0.2,0.2-0.3,0.3-0.5c0.1,0.1,0.2,0.4,0.2,0.5c0.2,0.3,0.7,0.6,0.5,1
        c0.4,0.1,0.7,0.4,1.1,0.6c-0.4,0-0.8-0.1-1.2-0.2c-0.5-0.4-1-0.8-1.5-1.2c-0.1-0.2-0.2-0.4-0.3-0.5c-0.4-0.3-0.4-0.8-0.5-1.2
        c0,0.1-0.1,0.3-0.2,0.4c0.1,0.3,0.2,0.5,0.1,0.8c0,0.5,0.1,1,0.2,1.5c0,0.2,0.6,0.5,0,0.4c0.1,0.1,0.2,0.2,0.3,0.2
        c0.2,0.4,0.3,0.7,0.5,1c-0.1,0-0.3,0.2-0.4,0c-0.1-0.3-0.3-0.6-0.5-0.8l0.2-0.3c-0.1,0-0.2,0.1-0.3,0.2c-0.1-0.2-0.2-0.4-0.1-0.7
        c-0.4,0.7,0.6,1.1,0.5,1.7c0.1,0,0.3,0.1,0.3,0.1c0,0.1-0.2,0.4,0,0.4c0.1-0.3,0.1-0.5,0.3-0.7c0.1,0.3,0.3,0.6,0.4,1l0.1,0
        c0,0-0.1,0.2-0.1,0.2c0.2,0.1,0.3,0.3,0.3,0.5l0.1,0c0,0.2,0.1,0.5,0.2,0.7c-0.1,0.2,0,0.4,0.1,0.6c0,0-0.1,0.1-0.1,0.2
        c0.5-0.2,0.3,0.5,0.3,0.8l0.2-0.1c0,0.1-0.1,0.3-0.1,0.3c0.1,0,0.2,0,0.3,0c-0.1,0.6,0.7,0.5,0.7,1.1c-0.5-0.3-1-0.6-1.5-1
        c-0.3-0.4-0.7-0.7-1-1.1c-0.4-0.2-0.4-0.8-0.8-1c0-0.3-0.1-0.5-0.4-0.7c0-0.1,0.1-0.2,0.1-0.3c-0.4-0.1-0.2,0.3-0.2,0.5
        c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0-0.3,0-0.4,0c0.1,0,0.3,0,0.4,0c-0.1,0.2-0.2,0.3-0.3,0.5c0.2-0.3,0.7-0.3,0.5,0.1
        c-0.1,0-0.2,0.1-0.2,0.1c0.1,0,0.3,0.2,0.4,0.1l0.1-0.1c0,0.2,0.1,0.5,0.3,0.7c0.3,0.3,0.5,0.7,0.4,1.2c0.1,0,0.3,0,0.4,0
        c-0.1,0.1-0.2,0.2-0.3,0.2l0,0.1c0.1-0.1,0.2-0.1,0.3-0.2c0,0.1,0.1,0.3,0.1,0.5c0.2,0.3,0.5,0.6,0.5,0.9l-0.1,0.1
        c-0.1,0.2,0.1,0.3,0.2,0.1c0.2,0,0.1,0.4,0.2,0.6c-0.1,0-0.3-0.1-0.3-0.1c0,0,0,0.1,0,0.2c0.1,0,0.3,0,0.4,0
        c0.2,0.3,0.5,0.5,0.5,0.9l0.1,0c0,0.1,0,0.2-0.1,0.2c0.3-0.1,0.3,0.2,0.4,0.4c0.2,0.2,0.3,0.4,0.5,0.7c-0.3-0.1-0.7-0.2-1-0.4
        c-0.3-0.1-0.5-0.4-0.8-0.6c-0.4-0.5-1.1-0.7-1.1-1.4c-0.1,0-0.3,0-0.5,0c0-0.1,0-0.2,0-0.3c-0.1,0-0.3,0-0.4,0
        c0.1-0.3-0.1-0.6-0.1-0.9c-0.2,0.3-0.1,0.8,0.1,1.1c0.1,0.2,0.2,0.6,0.5,0.5c-0.1,0.1-0.2,0.3-0.3,0.4l0.4-0.2
        c-0.2,0.2-0.2,0.4,0,0.6c0,0.1,0.1,0.3,0.2,0.3c0,0.2-0.2,0.3-0.1,0.5c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0.1,0.2,0.3,0.3,0.4l-0.2,0
        c0.1,0.2,0.1,0.4,0.1,0.6c0,0.1-0.1,0.2-0.2,0.3c0.2,0.1,0.3,0.1,0.5,0.1c-0.1,0.1-0.2,0.3-0.3,0.4c0.1,0.1,0.2,0.3,0.3,0.4
        c-0.1,0.3,0.2,0.5,0.5,0.4c0.1,0.1,0.2,0.1,0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0.1,0.3,0.1,0.4c0.2,0.2,0.3,0.3,0.2,0.6
        c-0.1-0.1-0.3-0.2-0.4-0.3c0.1,0.3,0.4,0.4,0.7,0.5c-0.1,0.3,0,0.6,0.1,0.8c0-0.1,0-0.3,0-0.4c0.1-0.2,0.2-0.4,0.3-0.6
        c-0.1-0.1-0.3-0.3-0.1-0.4c0.7-1.2,0.6-2.7,1.3-3.9c0-0.2,0-0.3,0-0.5c0.1-0.2,0.3-0.5,0.4-0.7c-0.1-0.4,0.1-0.7,0.2-1
        c0.1-0.4,0.1-0.9,0.4-1.3c0-0.2,0.1-0.5,0.1-0.7c0-0.5,0.5-0.9,0.4-1.4c0-0.3,0.2-0.7,0-1c0.2-0.3,0.1-0.7,0.1-1.1
        c0.4,0,0-0.4,0.2-0.6c-0.1-0.3-0.2-0.6,0-0.9c0-0.1,0-0.3,0.1-0.4l-0.1,0c-0.2-0.6-0.6-1.2-0.9-1.7c-0.3-0.4-0.3-1-0.7-1.4
        c-0.5-0.5-0.6-1.3-1.1-1.7c-0.3-0.4-0.7-0.7-0.8-1.2c-0.6-0.3-0.8-0.9-1.2-1.3c-0.5-0.4-0.7-1.1-1.3-1.5l0.1-0.1
        C231.6,340.8,231.5,340.6,231.4,340.4 M369.3,340.7C369.4,340.7,369.4,340.7,369.3,340.7 M148.7,340.9
        C148.5,341.3,149.2,340.9,148.7,340.9 M238.1,340.9C238.1,341,238.1,341,238.1,340.9 M205.2,341.2c0,0.1,0,0.3,0.1,0.4
        c0.1,0.3,0,0.6,0.1,0.9c-0.3,0.4,0.1,0.8,0,1.3c-0.1,0.7,0.3,1.3,0.1,2c0.2,0.2,0.4,0.5,0.3,0.8c0.1,0.8,0.1,1.6,0.2,2.4
        c0.1,0.2,0.2,0.5,0.1,0.7l0.2,0c0,0.2,0.1,0.4,0.2,0.6l-0.3,0c0.3,0.2,0.5,0.5,0.7,0.7c0,0.2-0.1,0.3-0.1,0.5l-0.2,0
        c0.1,0,0.3,0.1,0.4,0.2c0.1,0.2,0.3,0.4,0.4,0.6c-0.1,0-0.3,0-0.4,0c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.2,0.5,0.4,0.3,0.7
        c-0.4,0-0.6-0.4-1-0.5c0.1,0.2,0.3,0.4,0.4,0.6c-0.1,0-0.3,0-0.4,0c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.2-0.1,0.6,0.3,0.7
        c-0.1-0.3-0.2-0.5-0.3-0.8c0.3,0,0.4,0.3,0.5,0.6c0.2,0.1,0.2,0.3,0.3,0.5c0.1,0,0.2,0,0.3-0.1c0,0.3,0.3,0.5,0.3,0.8
        c0,0.1,0.1,0.3,0.1,0.3l0.1,0c0-0.1,0-0.2,0-0.3c0.3,0.4,0.6,0.8,0.9,1.2c-0.3,0-0.6,0-0.8,0c0.3,0.3,0.1,0.7,0.4,1l0.1,0
        c0.3,0.3,0.6,0.6,0.7,1c0.1,0.1,0.4,0.3,0.5,0.4c-0.6,0.1-0.9-0.4-1.4-0.6c-0.3-0.2-0.7-0.7-1.1-0.6c0-0.3-0.1-0.5-0.4-0.6
        c-0.1-0.1-0.2-0.3-0.2-0.5c-0.1,0-0.2,0-0.3,0c0.4,0.2,0.6,0.7,0.8,1c-0.1,0-0.2,0.1-0.3,0.1c0.4-0.1,0.4,0.4,0.7,0.6
        c-0.2-0.2-0.2-0.4-0.2-0.6c0.5,0.2,0.5,0.9,1,1.2c-0.1,0.1-0.2,0.2-0.3,0.2c0.2,0,0.3,0,0.5,0c0.3,0.2,0.3,0.8,0.7,0.8
        c0,0.1,0,0.2,0,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0,0.5,0,0.7,0c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0-0.4-0.2-0.6-0.3
        c-0.2-0.1-0.3-0.1-0.5-0.1c-0.2-0.1-0.5-0.2-0.7-0.3c0-0.1-0.1-0.3-0.1-0.3c-0.2-0.3-0.5-0.6-0.8-0.8c0.3,0.3,0.6,0.6,0.8,1
        c0.1,0.2,0.2,0.3,0.3,0.5c0.2-0.1,0.3,0,0.5,0.1c0.1,0.1,0.3,0.2,0.3,0.5c0.1,0,0.2,0,0.2-0.1c0.1,0.3,0.3,0.7,0.7,0.8
        c-0.1,0.2-0.3,0.3-0.5,0.2c-0.1-0.1-0.2-0.2-0.3-0.2c0.1,0.3,0.4,0.4,0.7,0.5l-0.1,0.2c0.1,0,0.2,0,0.2,0c0.1,0.2,0.3,0.3,0.5,0.4
        c-0.1,0-0.3,0.1-0.4,0.1c-0.7-0.3-1.4-0.3-2-0.8c-0.1,0-0.3,0.1-0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1-0.1,0.2-0.2,0.2-0.3
        c0,0.1,0,0.3-0.1,0.4c0.1,0,0.2-0.1,0.3-0.1c0,0.1,0,0.2-0.1,0.2c0.2,0.1,0.6,0.2,0.6,0.5c0.2,0,0.4,0,0.6,0
        c-0.1,0.1-0.1,0.3-0.2,0.4c0.2,0,0.3,0,0.5,0l0.1-0.3c0.1,0.2,0.1,0.5,0.1,0.8c0-0.1,0.1-0.3,0.1-0.4c0.2,0.1,0.3,0.3,0.5,0.4
        c-0.1,0.1-0.2,0.2-0.2,0.3c0,0,0.1,0,0.2,0.1c0-0.2,0.1-0.3,0.3-0.4c0.1,0.1,0.2,0.3,0.3,0.5c0.5-0.2,0.8,0.5,0.8,0.9
        c0,0,0.1-0.1,0.1-0.1c0.1,0,0.3,0.1,0.4,0.1c-0.1-0.3-0.3-0.3-0.5-0.4l0.1-0.2c0.1,0,0.3-0.1,0.4-0.2c-0.2,0.4,0.4,0.4,0.5,0.7
        c-0.1,0-0.3,0-0.4,0c0.2,0.1,0.4,0.2,0.6,0.3c-0.1-0.1-0.2-0.3-0.2-0.4c0.5,0.3,1,0.5,1.3,1c-0.1,0-0.4,0.1-0.5,0.1
        c0,0.1,0,0.2,0,0.2c0.2-0.1,0.3-0.1,0.5,0c0-0.1,0-0.2,0-0.3l0.2,0c-0.1,0.1-0.1,0.3-0.2,0.5c0.2,0,0.4-0.3,0.6-0.2
        c0.1,0.1,0.3,0.3,0.3,0.5c-0.2,0-0.3-0.2-0.4-0.2c-0.2,0-0.4,0.2-0.6,0.3c0.1-0.1,0.2-0.1,0.3-0.2c0.2,0.1,0.5,0.3,0.7,0.2
        c0.2,0.2,0.4,0.3,0.6,0.3c-0.2-0.3-0.5-0.7-0.7-1c0.6-0.1,0.4,1,1,0.6c0,0.1-0.2,0.3-0.2,0.4c0.2-0.1,0.4-0.2,0.6-0.3
        c0,0.1,0.1,0.2,0.1,0.3c-0.3,0.1-0.6,0.2-0.7,0.5c0.2-0.1,0.4-0.1,0.6-0.2c0,0.1-0.1,0.3-0.1,0.4c0.2-0.1,0.5,0.1,0.5-0.2
        c0.1-0.3,0.4-0.1,0.4,0.1l0.3-0.1c-0.1,0.1-0.3,0.3-0.5,0.2c0.5,0.1,0.4,0.6,0.5,1l0.1,0c-0.1-0.4,0-0.8,0.1-1.1
        c0,0.3,0.5,0.6,0.1,0.9c0.1,0,0.3-0.1,0.4-0.2l0,0.1c0,0.1-0.1,0.2-0.1,0.3c0.2,0,0.3-0.2,0.4-0.3c0.2,0,0.4,0.1,0.4,0.4
        c0.3-0.4,0.4,0.1,0.7,0.2c-0.4,0.2-0.7-0.2-1.1-0.3l0,0.1c0.2,0.1,0.4,0.3,0.5,0.5c0.3,0,0.6,0.1,0.9,0.2c0.1-0.4,0.3,0,0.4,0.1
        c0.3,0.2,0.6,0.4,0.8,0.6c0.2-0.2,0.2-0.5,0.2-0.8c-0.2-0.1-0.4-0.2-0.5-0.3c-0.1-0.2-0.1-0.4-0.2-0.6c0.2,0,0.3,0,0.4-0.1
        c-0.2,0-0.4-0.1-0.6-0.1c0-0.2,0-0.4,0-0.6c0,0-0.1,0-0.2,0l-0.1-0.2l-0.3-0.1c0.1-0.1,0.2-0.2,0.3-0.3c-0.1-0.2-0.2-0.3-0.3-0.4
        c0,0.2,0,0.4-0.1,0.6c-0.2-0.5-0.4-0.9-0.7-1.4c0,0-0.1,0.1-0.2,0.1c-0.1-0.2-0.1-0.4-0.3-0.5c-0.1-0.2-0.2-0.4-0.3-0.6
        c0.1-0.1,0.1-0.3,0.2-0.4c-0.3,0.1-0.4,0-0.5-0.3c0.1,0,0.2,0,0.3,0c-0.1-0.1-0.3-0.3-0.4-0.4c0,0.1,0.1,0.3,0.1,0.4l-0.1,0
        c0.1-0.3-0.3-0.4-0.4-0.6c0.1,0,0.3,0.1,0.5,0.1c-0.1-0.4-0.3-0.3-0.5-0.2c0-0.3-0.1-0.6-0.2-0.9c-0.1,0-0.2,0-0.3,0.1
        c0-0.4-0.1-0.7,0-1.1c-0.1,0-0.2-0.1-0.3-0.1c0,0.2,0.1,0.5,0.1,0.6c-0.4-0.1-0.3-0.5-0.3-0.7c0,0,0.1-0.1,0.2-0.2
        c-0.3-0.3-0.9-0.4-0.8-0.9c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.2-0.1,0.3-0.2c0,0.1,0.1,0.2,0.2,0.3
        c0-0.1-0.1-0.2-0.1-0.4c-0.2,0.1-0.3,0.1-0.5,0.2c0-0.2,0-0.5,0-0.6l-0.2,0c0-0.1,0-0.2,0.1-0.3c0,0-0.1,0-0.2,0
        c0,0.3,0.1,0.5,0.1,0.8c0,0-0.1,0-0.2,0c-0.1-0.3,0-0.8-0.3-1c-0.1-0.2,0-1-0.4-0.6l0.1-0.3c-0.1,0-0.2,0-0.3,0.1
        c0.1-0.4-0.1-0.7-0.3-1c-0.1,0-0.2,0-0.3,0c0.1-0.2,0.2-0.4,0.3-0.6l-0.2,0.1c-0.2-0.2-0.2-0.5-0.1-0.8l-0.3,0.1
        c0-0.1,0.1-0.2,0.1-0.3l-0.3,0c0-0.1,0.1-0.3,0.2-0.4c-0.1,0-0.3,0.1-0.4,0.1c0-0.1,0.1-0.3,0.2-0.4c-0.1-0.1-0.3-0.3-0.4-0.4
        c-0.2,0.2-0.3,0.4-0.3,0.6c-0.2,0-0.3-0.1-0.5-0.2c0.4,0.3-0.1,0.7-0.4,0.8c-0.3,0.5-0.8,0.8-1.3,1c0.3-0.3,0.6-0.6,0.8-1
        c0.4-0.8,0.7-1.6,1-2.4c-0.3-0.6-0.7-1-1-1.6c-0.2,0-0.5-0.1-0.7-0.1c0,0,0,0,0-0.1c0.1,0,0.2-0.1,0.3-0.1c0-0.3-0.2-0.5-0.3-0.9
        c-0.4-0.1-0.5-0.4-0.5-0.7c-0.2-0.2-0.5-0.4-0.4-0.7c-0.3-0.2-0.4-0.6-0.6-0.9c-0.3-0.5-0.7-1-0.8-1.6c-0.2-0.4-0.7-0.7-0.7-1.2
        c0-0.4-0.4-0.6-0.5-1c-0.1,0-0.3,0-0.4,0c0.1-0.1,0.2-0.2,0.3-0.2c-0.2-0.1-0.3-0.3-0.4-0.4c0-0.3,0.1-0.6-0.2-0.7
        c-0.4-0.3-0.6-0.9-0.5-1.4c-0.2,0-0.5-0.1-0.4-0.3c0-0.4-0.1-0.8-0.3-1.2c-0.1,0-0.2,0-0.3,0L205.2,341.2L205.2,341.2 M238,341
        c0,0.6,0.1,1.2,0,1.7c0.2,0.7,0.1,1.5,0.2,2.3c-0.1,0.2,0,0.5,0.1,0.7c0,0.3-0.1,0.7,0,1c0.2,0,0.3,0,0.5,0c0,0,0,0.1,0,0.1
        c-0.2,0-0.3,0-0.5,0.1c0.2,0.1,0.4,0.3,0.5,0.5c-0.1,0-0.4,0-0.5,0c0.1,0.3,0.4,0.2,0.6,0.1l0,0.3c0.1,0,0.4-0.1,0.5-0.1
        c-0.1,0.1-0.2,0.3-0.3,0.4c0,0.1,0,0.2,0,0.3c0.1-0.1,0.2-0.3,0.2-0.4c0.1,0.1,0.2,0.2,0.4,0.3c0,0.2,0,0.4-0.1,0.6
        c0.1,0.1,0.1,0.3,0.2,0.4c0-0.3,0-0.5,0.2-0.7c0.1,0.3,0.1,0.8,0.5,0.8c0,0,0,0.1,0,0.2c0.1,0.2,0.3,0.3,0.4,0.5
        c-0.3,0.1-0.2,0.4-0.2,0.6l-0.2-0.1l0,0.2c0.1,0,0.4,0,0.5,0c0-0.3-0.3-0.5-0.1-0.7c0.1,0.1,0.3,0.3,0.4,0.4
        c-0.1,0.3-0.3,0.7,0.1,0.8c0-0.1,0-0.3,0-0.5l0.2,0.1c0,0.1,0,0.3,0,0.4c0,0,0.1,0,0.2,0l0,0.3c0.1-0.1,0.2-0.3,0.3-0.3
        c0,0.2,0,0.3,0.1,0.5c0,0.3,0.2,0.7,0.5,0.8c0-0.2,0.1-0.3,0.1-0.4c0,0.2,0,0.4,0.1,0.6c-0.2,0-0.4,0-0.5-0.2c0,0-0.1,0.1-0.2,0.1
        c0,0.1,0.1,0.2,0.2,0.3c0.3,0.1,0.5,0.3,0.8,0.5c-0.1,0-0.2,0-0.3,0c0.1,0.2,0.2,0.5,0.2,0.7l0.1,0c0,0.2,0.2,0.4,0.3,0.7
        c0-0.3,0.4-0.2,0.6-0.2c0.1,0.1,0.1,0.3,0.2,0.4c-0.2-0.1-0.4-0.3-0.6-0.3c0,0.1,0,0.2,0,0.3c0.3-0.1,0.5,0.2,0.5,0.4
        c-0.3-0.2-0.6-0.4-0.9-0.1c0.1,0.2,0.4,0.4,0.3,0.6c0.5,0,0.1-0.4-0.1-0.5c0.2,0,0.3-0.2,0.4,0.1c0.1,0.1,0.5,0.4,0.1,0.5
        c0,0,0.1,0.1,0.1,0.2c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0.1,0.2,0.3,0.3,0.4c0.1-0.1,0.3-0.2,0.4-0.2c-0.1,0.1-0.2,0.1-0.3,0.2
        c0,0.2-0.1,0.5-0.1,0.6c0.2-0.1,0.3-0.3,0.5-0.4c-0.1,0.3-0.2,0.9-0.5,0.5c-0.2,0-0.3,0-0.5,0c0,0.1,0,0.2,0,0.2
        c0.1-0.1,0.2-0.1,0.3-0.2c0.2,0.2,0.3,0.4,0.4,0.7c0.3,0.1,0.5,0.4,0.8,0.3l-0.1-0.2c0.1,0,0.2,0.1,0.2,0.1
        c-0.1,0.1-0.3,0.2-0.4,0.4l0-0.2c-0.1,0-0.4,0.1-0.5,0.1c0.1,0.2,0.4,0.3,0.6,0.3c-0.1,0.1-0.2,0.3-0.2,0.4c0.2,0.2,0.4,0.4,0.6,0.6
        c0.1,0,0.2,0.1,0.3,0.2c0,0.1-0.1,0.3-0.2,0.4c0.1-0.1,0.3-0.2,0.4-0.3c-0.1,0.3-0.2,0.5-0.4,0.7c0-0.1-0.1-0.4-0.1-0.5
        c-0.1,0.1-0.2,0.3-0.2,0.5c0.3,0.1,0.5,0,0.6-0.4c0,0.1,0.1,0.3,0.2,0.5l-0.3,0l0.1,0.2c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2-0.1-0.3-0.1
        c0,0.1,0.1,0.2,0.1,0.3c0.1,0,0.4-0.1,0.5-0.1l-0.1,0.2c0.1,0.1,0.2,0.3,0.3,0.4c0.1-0.2,0.1-0.4,0.2-0.5c0.1,0.2,0.3,0.3,0.4,0.3
        c-0.1,0.1-0.6,0.2,0,0.3c-0.2,0.1-0.2,0.2-0.2,0.4c-0.1,0.2-0.2,0.4-0.3,0.6c0.3-0.1,0.5-0.1,0.5,0.3c-0.1,0-0.3,0-0.4,0
        c0.1,0.1,0.3,0.1,0.4,0.2c0.1-0.2,0.2-0.3,0.3-0.5c-0.1,0.3-0.3,0.5-0.2,0.8l-0.3,0c0.2,0.2,0.4,0.4,0.6,0.6c-0.1,0-0.4,0-0.5-0.1
        c0.2,0.3,0.5,0.6,0.8,0.3l-0.1,0.2c0.1,0,0.3,0,0.4,0c0,0.1,0,0.3,0,0.4c-0.2-0.2-0.3-0.3-0.5-0.5c-0.2,0.2-0.3,0.3-0.2,0.5
        c0.1,0,0.3-0.1,0.4-0.2c0,0.1,0,0.3,0,0.5c-0.1,0-0.3,0.1-0.4,0.1c0.1,0.3,0.5,0.5,0.3,0.8c0,0,0.1,0,0.1,0l-0.1,0.2
        c0,0,0.1,0,0.2,0c0.1-0.3,0.3-0.5,0.5-0.7c0,0.3-0.2,0.6-0.3,0.8c0.1,0.3,0.2,0.5,0.4,0.7l-0.3,0c0,0.1,0,0.4,0,0.5
        c0.1-0.1,0.3-0.2,0.4-0.2c0,0.1,0.1,0.2,0.1,0.2c-0.4-0.1-0.4,0.4-0.1,0.5c-0.1,0.2-0.2,0.5-0.5,0.6c0.2,0.2,0.5,0.5,0.8,0.2
        c0,0.1-0.1,0.4-0.1,0.6l-0.3-0.2c-0.1,0.5,0.6,0.2,0.7,0.7c-0.1,0-0.3-0.1-0.5-0.1c0.2,0.1,0.3,0.3,0.3,0.5c0.2,0,0.4,0.2,0.3,0.4
        l0.2,0l0,0.1c0.2,0,0.4,0,0.4,0.2c-0.3-0.1-0.6-0.2-0.9,0c0.2,0.2,0.4,0.3,0.6,0.2l0,0.2c-0.1,0-0.3,0-0.4,0.1
        c-0.1-0.1-0.2-0.2-0.3-0.3c0,0.3,0.4,0.4,0.5,0.6c0-0.3,0.4-0.4,0.6-0.5c-0.2,0.2-0.4,0.4-0.6,0.6c0.1,0,0.2,0.1,0.2,0.1l0.1-0.3
        l0.2,0c0,0.1-0.1,0.4-0.1,0.5c0-0.1-0.1-0.2-0.2-0.3c-0.1,0-0.3,0.1-0.4,0.1c0.2,0.1,0.4,0.2,0.5,0.3c-0.1,0.1-0.2,0.2-0.3,0.2
        c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.4,0.2,0.5,0.3c-0.6-0.2-0.1,0.6-0.6,0.4c0.1,0.1,0.2,0.3,0.3,0.5c0.1-0.2,0.1-0.4,0.2-0.5
        c0.1,0.1,0.2,0.4,0.3,0.5l-0.3-0.1c0,0.2-0.1,0.4-0.3,0.6l0-0.5c-0.3,0.1,0.1,0.5-0.3,0.5c0.2,0,0.3,0,0.5,0
        c0.1-0.1,0.2-0.2,0.2-0.2c0.4-0.2-0.1,0.4-0.1,0.6c0.1-0.1,0.3-0.2,0.5-0.3c-0.1,0.2-0.2,0.4-0.2,0.7c0.1,0,0.2,0.1,0.3,0.1
        c0.1,0,0.2,0,0.3,0c0,0,0,0.1,0,0.1c0,0-0.1,0-0.2,0c-0.1,0.2-0.2,0.5-0.2,0.7c-0.1-0.1,0.1-0.7-0.3-0.5c0,0.1-0.1,0.3-0.2,0.4
        c0.1,0,0.2,0,0.3,0c0.2,0.3,0,0.7-0.2,1c0.1,0,0.2,0.1,0.2,0.1c0.1-0.2,0.1-0.4,0.2-0.6c0.1,0,0.2,0,0.3,0.1c0-0.1,0-0.3-0.1-0.4
        c0.1,0.2,0.2,0.4,0.3,0.7c-0.1,0-0.3-0.1-0.3-0.2c-0.1,0.4-0.4,0.8-0.3,1.2c0,0.1-0.1,0.2-0.1,0.3c0.2,0.2,0.4,0.5,0,0.6l0-0.3
        l-0.2,0c0.2,0.2,0.3,0.4,0.5,0.6c0,0.2,0,0.5,0.2,0.5c-0.1-0.4,0.2-0.3,0.4-0.4c-0.1,0.1-0.2,0.3-0.3,0.4c0.1,0,0.3,0,0.3,0
        c0,0.2,0,0.3,0,0.5c0,0-0.1,0-0.2,0c0-0.1,0-0.4,0-0.5c-0.2,0-0.4,0-0.6,0.1c0,0.1-0.1,0.3-0.1,0.4c0.2,0.1,0.5,0.1,0.6,0.4
        c-0.3-0.1-0.4,0.2-0.5,0.4c0.3-0.1,0.4-0.4,0.8-0.4c-0.2,0.2-0.4,0.4-0.6,0.6c0.2,0.1,0.3,0.2,0.3,0.4l-0.3,0
        c0.1,0.1,0.2,0.3,0.3,0.4c0.2-0.2,0.3-0.5,0.6-0.6c-0.1,0.1-0.1,0.3-0.2,0.4c-0.2,0.1-0.5,0.2-0.6,0.5c0.1,0.1,0.3,0.2,0.3,0.4
        c-0.1,0.3-0.2,0.6-0.1,0.9l0.2,0c0-0.2,0.1-0.3,0.1-0.4c0.4,0,0-0.3,0-0.4l0.1,0c0.1,0.1,0.2,0.2,0.3,0.2c-0.1,0.2-0.3,0.3-0.2,0.5
        c-0.2,0.2-0.3,0.8,0.1,0.6l0.1-0.1l0.1,0c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1-0.2-0.2-0.4-0.5-0.4c0.1,0.4,0.5,0.7,0.4,1.1
        c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2,0.3,0,0.5,0.2,0.6c0-0.3,0.2-0.5,0.5-0.5c-0.1,0.2-0.2,0.4-0.3,0.6c0.1,0.1,0.2,0.1,0.2,0.2l-0.4,0
        c0.2,0.1,0.3,0.5,0.6,0.2l0,0.1c-0.2,0.1-0.3,0.3-0.5,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1-0.1,0.3-0.2,0.5-0.3c0,0.2-0.1,0.3-0.3,0.3
        l0,0.3c-0.3-0.1-0.5-0.2-0.6-0.5c-0.1,0.1-0.2,0.1-0.2,0.2c0,0.1-0.2,0.4,0.1,0.2c0.3-0.5,0.4,0.5,0.7,0.6c-0.1,0-0.3-0.1-0.4-0.2
        c0.1,0.2,0.1,0.3,0.2,0.5c-0.2-0.1-0.3-0.1-0.5-0.2c0.2,0.2,0.5,0.3,0.6,0.6c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.3-0.3
        c0.2,0.1,0.2,0.2,0,0.3c0,0,0,0.1-0.1,0.2l0.2,0l0,0.1l-0.5,0c0.2,0.1,0.4,0.3,0.6,0.4c-0.3,0.1-0.4-0.3-0.7-0.1
        c0,0.2-0.1,0.4-0.2,0.6l0.2,0c0,0.3,0,0.6,0,0.9c-0.2,0.1-0.2,0.2,0,0.3c0,0.2,0,0.4,0.1,0.6c0,0.1-0.1,0.2-0.2,0.3
        c0.3,0,0.3,0.2,0,0.4c0,0.3,0,0.7,0.2,0.9c0,0.1-0.1,0.3-0.2,0.4c0.5,0.1-0.2,0.6,0.2,0.7c0.2-0.2,0.3-0.4,0.6-0.5
        c-0.2,0.2-0.4,0.5-0.7,0.7c0.1,0.3,0.1,0.5-0.1,0.8c0.1,0.2,0.4,0.5,0.1,0.7c0.1,0,0.3,0,0.4,0c-0.1,0-0.2,0-0.3,0
        c0.1,0.3,0.1,0.7,0,1c0,0.2,0.1,0.3,0.2,0.4c-0.1,0.1-0.2,0.2-0.3,0.4c0.2,0.3,0.2-0.3,0.5-0.3c0.2,0.2,0.5,0.2,0.4,0.5
        c-0.1-0.1-0.4-0.2-0.5-0.2c-0.2,0.1-0.2,0.4-0.3,0.6c0.2-0.1,0.4-0.2,0.6-0.3c0,0.4-0.3,0.7-0.5,1c0.2,0,0.4,0,0.6,0.1
        c-0.2,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.1,0.2,0.2,0.4c0,0-0.1,0.1-0.2,0.1c-0.1-0.3-0.4-0.5-0.7-0.5c0-0.1,0.1-0.3,0.1-0.4
        c0,0-0.1,0-0.1,0c0,0.2-0.1,0.3-0.2,0.5c0.3-0.1,0.6,0.3,0.6,0.6c0.1,0.1,0.2,0.2,0.3,0.2c-0.4,0-0.4,0.5-0.5,0.7
        c0.2-0.1,0.4-0.5,0.7-0.4c-0.2-0.2-0.2-0.3,0.1-0.4c0,0.3,0.1,0.7-0.4,0.5c0.1,0.4-0.3,0.5-0.5,0.8c0.1,0,0.4,0,0.5,0l-0.1-0.2
        c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0.2,0.1,0.4,0.1,0.6l0.2,0c-0.1,0.2-0.3,0.4-0.4,0.6c0.1,0,0.3,0,0.4-0.1c-0.1,0.1-0.3,0.2-0.4,0.4
        c0.1,0,0.3,0,0.4,0.1c0,0.2-0.1,0.3-0.2,0.5l0.3-0.1c0,0.1-0.1,0.2-0.1,0.3c0.2,0.2,0.1,0.3-0.1,0.4c0.1,0.1,0.2,0.2,0.4,0.3
        c-0.1,0-0.3,0-0.4,0c0-0.1-0.1-0.2-0.1-0.4l0.2,0c0-0.3-0.1-0.6-0.3-0.9l-0.3,0l0,0.2c0,0.1-0.1,0.2-0.1,0.2c-0.1,0-0.2,0-0.2,0
        c0.2,0.3,0.4-0.2,0.6-0.2c0.1,0.2,0.1,0.4-0.2,0.4c0.1,0.3,0,0.6-0.1,0.9c0.2-0.1,0.3-0.1,0.5-0.2c-0.1,0.3-0.4,0.4-0.6,0.6
        c0.1,0,0.3-0.1,0.4-0.1c-0.1,0.2-0.1,0.5,0,0.7l-0.2,0.1c0.2,0.2,0.8,0.3,0.2,0.6l0.1,0.1c0.3,0.2,0.3-0.4,0.4-0.5
        c0.2,0.1,0.2,0.3,0.3,0.6c0.1,0,0.2,0,0.3,0.1c-0.1,0-0.2,0.1-0.3,0.1c0,0.1,0,0.2,0.1,0.2l0.2,0c0,0.2-0.4,0.3-0.3,0
        c0,0-0.1,0-0.2,0l0-0.1c0,0-0.1-0.1-0.1-0.1l-0.1,0.1c0.1,0.3,0,0.6-0.1,0.8c0.3,0.3,0,0.7,0.4,0.9c-0.1,0.2-0.3,0.4-0.4,0.6
        c0.2-0.2,0.5-0.3,0.8-0.5c-0.2-0.2-0.2-0.4-0.2-0.7c0.1,0,0.3,0.1,0.4,0.1c-0.1,0.1-0.1,0.3-0.2,0.4l0.2,0.1
        c-0.1,0.1-0.1,0.2-0.2,0.4c-0.4,0-0.7,0.4-0.4,0.7c-0.1,0-0.2,0-0.2,0l0.2,0c0,0.1,0,0.2,0,0.3c0.1,0,0.2,0,0.3,0
        c0-0.1-0.1-0.4-0.1-0.5l0.3,0l-0.1,0.2c0.1,0,0.2,0,0.3,0l0.2,0.1l-0.4,0c0.1,0.2,0.2,0.3,0.3,0.5l0.2,0c0,0.1,0,0.2-0.1,0.3
        c-0.4,0.1-0.4,0.5-0.4,0.8c0.1,0,0.3-0.1,0.4-0.1c-0.1,0.1-0.1,0.2-0.2,0.4c-0.2-0.1-0.4-0.2-0.6-0.2c0.1,0.2,0.3,0.2,0.4,0.2
        c0.1,0.3,0,0.6,0,0.9c0.1,0.1,0.3,0.2,0.4,0.2c-0.2,0.2-0.5,0.3-0.5,0.6c0.3,0,0.4-0.4,0.6-0.2c0.1,0.2,0.1,0.3,0.1,0.5
        c-0.1,0.2-0.3,0.3-0.5,0.5c-0.1-0.1-0.3-0.2-0.3-0.3c0,0.4,0.5,0.4,0.8,0.4c-0.1-0.3,0.2-0.4,0.3-0.6c0,0.1-0.1,0.3-0.2,0.4
        c0.1,0.1,0.1,0.2,0.2,0.3l-0.3,0c0.1,0.2,0.2,0.4,0.3,0.6c-0.1,0-0.3,0.1-0.4,0.1c0,0.3,0.2,0.4,0.5,0.3c0,0,0,0.1,0,0.1l-0.2-0.1
        l0,0.4c0.1,0,0.2,0.1,0.2,0.2c-0.2,0-0.3,0-0.5,0c0-0.2,0-0.5,0-0.7c-0.3,0.3-0.1,0.7,0,1c0,0.2-0.1,0.5,0.1,0.7
        c-0.1,0.2,0.1,0.4,0.2,0.5c0,0.1-0.1,0.2-0.2,0.2c0.2,0.2,0.3,0.5,0.4,0.7c0.1,0.1,0.1,0.3,0,0.4c0.3,0.6,0.4,1.3,0.4,1.9
        c0.2,0.3,0,0.7-0.1,1c0.7,0.4,0.4,1.2,0.6,1.8c0,0.2,0,0.4,0.1,0.7c-0.1,1.2,0.2,2.5,0.3,3.7c-0.2,0.5,0.4,1.2-0.2,1.6
        c0.2,0.3,0.3,0.6,0.2,0.9c0.1,0.4,0.1,0.9,0,1.3c0.2,0.3,0.1,0.6,0.1,0.9c0.1,1.1-0.1,2.2-0.1,3.3c0,0.7,0,1.4-0.2,2.1
        c0,0.8,0,1.7-0.2,2.5c0,0-0.1,0-0.1,0c0-0.1,0-0.2,0-0.2l0.1-0.1c-0.1-0.7-0.3-1.4-0.2-2.1l-0.1,0c0-0.3,0-0.7,0-1
        c-0.3-0.2,0.1-0.7-0.4-0.8l0.2-0.2l0.1-0.3c-0.1,0.1-0.3,0.2-0.4,0.2c0.1-0.2,0.2-0.4,0.3-0.7c-0.1,0-0.3,0.1-0.4,0.1
        c0.2-0.1,0.4-0.2,0.4-0.5c-0.1,0-0.3,0.1-0.3,0.1c-0.2-0.3-0.2-0.7,0.2-0.6c-0.1-0.2-0.3-0.3-0.5-0.3c0-0.1-0.1-0.2-0.1-0.2
        c0.7,0.3,0.2-0.2,0-0.4l0.2,0.1c0-0.3,0.1-0.7-0.3-0.7c0,0.1,0,0.3,0,0.5c-0.1-0.1-0.2-0.2-0.1-0.4c0.2-0.2,0.2-0.5,0.2-0.8
        c-0.1,0-0.2,0-0.2,0c-0.1-0.1-0.2-0.3-0.2-0.5c0.3-0.1,0.5,0.5,0.7,0.3c-0.2-0.2-0.4-0.3-0.6-0.4c0.1-0.3,0.2-0.5,0.4-0.7
        c0-0.3-0.1-0.6-0.2-0.9c0,0.3,0,0.6,0,0.9c0,0-0.1,0.1-0.2,0.1l0,0.3l-0.2,0l-0.1-0.3l0.3,0c-0.1-0.4-0.4-0.7-0.6-1l0.3,0
        c0.1,0.1,0.2,0.2,0.3,0.3c0-0.1,0-0.3,0-0.4c0.3,0,0.1-0.3,0-0.4c0,0.1-0.1,0.3-0.1,0.5c-0.4,0-0.3-0.5-0.4-0.8
        c0.1,0.1,0.4,0.2,0.5,0.3l-0.1-0.3c-0.3-0.1-0.4-0.3-0.4-0.6c-0.3,0-0.3-0.3-0.3-0.5c0.1,0.1,0.2,0.3,0.3,0.4
        c0.2-0.2,0.4-0.3,0.6-0.5c0,0-0.1-0.1-0.2-0.1c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1-0.2-0.2-0.4-0.4-0.4c0-0.3,0.1-0.5,0.3-0.6
        c0.2-0.1,0.4,0.2,0.6,0.3c0-0.3-0.2-0.5-0.4-0.6c0.1-0.2,0.2-0.5,0.2-0.7c-0.2,0.2-0.3,0.5-0.4,0.7c-0.1,0.1-0.3,0.2-0.4,0.3
        c0-0.1,0.1-0.4,0.1-0.6c0.2-0.1,0.2-0.2,0.1-0.4c-0.2,0-0.3,0.3-0.5,0.4c0-0.3,0.1-0.5,0.2-0.7c-0.1,0-0.2-0.1-0.3-0.1
        c0-0.1,0-0.4-0.1-0.6c0.1,0,0.3,0,0.4,0c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.4,0.5,0,0.7,0c-0.4-0.2-0.7-0.5-0.8-1
        c0.1-0.1,0.4-0.2,0.6-0.3c0,0.2,0,0.5,0,0.7c0.1-0.2,0.2-0.4,0.1-0.6c-0.1-0.2-0.3-0.4-0.4-0.7c-0.1-0.1-0.2-0.2-0.3-0.3
        c0,0.1,0,0.2,0,0.2l0.2,0.1l0.1,0.2l-0.2-0.1c0.1,0.1,0.2,0.3,0.4,0.4c-0.3,0.1-0.6,0.4-0.9,0.4c-0.1-0.3,0.2-0.5,0.5-0.4
        c-0.1-0.1-0.2-0.5-0.5-0.2c0-0.1,0.1-0.2,0.1-0.3c-0.1-0.1-0.3-0.2-0.5-0.2c0,0,0-0.1,0-0.2c0.1-0.1,0.2-0.2,0.3-0.3
        c-0.1,0-0.3-0.1-0.4-0.1l0.4,0.1c-0.1-0.1-0.4-0.3-0.5-0.4c0-0.1,0.1-0.2,0.1-0.3c-0.3,0.1-0.5-0.3-0.2-0.4c0.1,0.1,0.3,0.4,0.3,0.5
        l0-0.5c0.1,0.1,0.3,0.2,0.4,0.3c0-0.1,0.1-0.2,0.1-0.2c-0.3,0-0.5-0.2-0.8-0.1l0.1-0.2c0.2,0,0.3-0.1,0.4-0.2
        c-0.1,0-0.3,0.1-0.4,0.1c-0.1-0.3,0.2-0.6,0-0.9c-0.1,0.1-0.2,0.4-0.4,0.3c0.1-0.3,0.5-0.6,0.3-0.8c-0.2,0.1-0.3,0.3-0.5,0.5
        c-0.3-0.3-0.1-0.4,0.2-0.3c-0.1-0.3-0.1-0.5-0.2-0.8c-0.1,0.1-0.2,0.1-0.3,0.2c0.1-0.2,0.2-0.4,0.2-0.7c-0.2,0.1-0.4,0.2-0.6,0.3
        c0.1-0.3,0.3-0.4,0.6-0.5c0-0.2,0-0.3,0-0.5c0,0-0.1,0-0.2,0c0.1,0.3-0.2,0.4-0.5,0.5c0-0.2,0.1-0.4,0.2-0.6c0-0.3-0.3-0.4-0.6-0.4
        c0-0.1,0.1-0.3,0.2-0.4c-0.1,0-0.2,0-0.2,0c0.1-0.1,0.2-0.3,0.2-0.5l-0.2,0c0.1-0.3,0.1-0.6-0.2-0.8l-0.1,0.3c0,0-0.1,0-0.2,0
        c0.2-0.3,0.3-0.7,0.3-1c-0.2,0-0.3,0.2-0.3,0.4c0,0-0.1-0.1-0.2-0.1c0-0.1,0.1-0.2,0.1-0.3c-0.1-0.2-0.3-0.5-0.4-0.7
        c0.1,0.1,0.2,0.1,0.3,0.2c0.2-0.2,0.4,0,0.4,0.3c0.2-0.1,0.3-0.1,0.5-0.2c0,0,0-0.1,0-0.1c-0.3,0.2-0.5-0.1-0.6-0.3
        c-0.1,0.1-0.2,0.1-0.3,0.2c0-0.3-0.2-0.7-0.5-0.7c0.2-0.3,0.1-0.6,0-0.8l0.3,0.2c0-0.1-0.1-0.3-0.2-0.4c-0.2,0-0.3,0.1-0.4,0.4
        c-0.1-0.2-0.2-0.3-0.3-0.5c0.1,0,0.2-0.1,0.3-0.1l-0.1-0.3c-0.1,0-0.4,0.1-0.5,0.1c0.2-0.1,0.3-0.3,0.5-0.3c0,0-0.1-0.1-0.1-0.1
        c0-0.1,0.1-0.3,0.2-0.5c-0.3,0.1-0.5,0.2-0.6,0.5c-0.1-0.3-0.1-0.7,0-1c-0.1,0-0.3,0.1-0.4,0.1c0-0.1-0.1-0.2-0.1-0.3
        c0-0.1-0.1-0.2-0.2-0.2c0.3,0.1,0.6,0.5,1,0.2c-0.3,0-0.4-0.2-0.3-0.4c-0.2,0-0.4,0-0.6,0c-0.1-0.2-0.2-0.4-0.3-0.5
        c-0.1-0.1-0.1-0.3-0.2-0.4c0-0.1-0.1-0.3-0.1-0.4c0.2,0.1,0.2,0.4,0.4,0.5c0,0.1,0,0.3-0.1,0.4c0.2,0,0.3-0.2,0.4-0.4
        c-0.4,0.2-0.7-0.5-0.3-0.6c0,0.1,0,0.3,0.1,0.4c0-0.2,0-0.4,0-0.6c-0.1,0.1-0.3,0.2-0.3,0.3c0-0.1,0-0.3,0-0.5
        c-0.2,0.1-0.3,0.4-0.5,0.5c0.1-0.3,0.2-0.6,0.4-0.8c-0.1,0.1-0.4,0.2-0.5,0.2c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.3-0.3-0.5-0.3-0.8
        c0.3,0.2,0.4,0.5,0.5,0.9l0.1,0c0-0.3,0-0.6-0.2-0.9c0.1,0.1,0.4,0.2,0.5,0.3c-0.1-0.2-0.2-0.3-0.3-0.4c-0.2,0.2-0.3-0.2-0.4-0.3
        c0,0.1-0.1,0.4-0.1,0.5c-0.1-0.3-0.2-0.5-0.4-0.7c0.2,0,0.3,0,0.4-0.2l-0.3,0c0.1-0.1,0.2-0.4,0.2-0.6c-0.1-0.1-0.3-0.3-0.4-0.4
        c0,0.3,0.1,0.7,0.2,1c-0.6-0.2-0.1-0.9-0.6-1.1c0-0.1,0-0.2,0-0.2c0.2-0.2,0.2-0.7-0.1-0.8c0,0.3,0,0.5,0,0.8
        c-0.3-0.2-0.2-0.7-0.1-1c-0.1,0.1-0.3,0.2-0.4,0.3c0.1-0.2,0.1-0.5-0.1-0.6c0.1-0.2,0.6-0.1,0.6,0.2c0,0.7,0.6-0.5,0.1-0.3
        c-0.3,0.2-0.4-0.2-0.6-0.4l0,0.2c-0.1,0-0.3-0.1-0.3-0.1c-0.2-0.3,0-0.5,0.2-0.6c-0.3,0-0.7-0.1-0.5-0.4c0.1,0,0.2,0,0.3,0
        c0,0-0.2-0.1-0.2-0.2c0-0.2,0-0.4,0-0.6c-0.2,0.2-0.2,0.4-0.2,0.7c-0.5,0-0.2-0.5-0.1-0.7c0-0.2-0.1-0.4-0.1-0.5
        c-0.2,0-0.4-0.1-0.6-0.1c0.1-0.2,0.2-0.5,0.5-0.3c-0.2-0.1-0.3-0.4-0.4-0.5c0.1-0.1,0.2-0.2,0.3-0.3c-0.3,0-0.3,0.3-0.4,0.5
        c-0.3,0-0.8-0.1-0.5-0.4c0-0.1,0-0.2,0-0.3c-0.2,0.2-0.4,0.3-0.4-0.1c-0.4,0-0.1-0.4-0.1-0.6c-0.1,0-0.4,0.1-0.5,0.1l0-0.1
        c0.2,0,0.4,0,0.6,0c-0.1-0.3-0.3-0.4-0.6-0.4c0,0.1-0.1,0.2-0.1,0.2c-0.1-0.5-0.7-0.8-0.5-1.4l-0.2-0.1l0.1,0.3c0,0-0.1,0.1-0.1,0.2
        c0-0.2,0-0.4,0-0.5l0.2,0c-0.1-0.3-0.2-0.4-0.5-0.3c0.1-0.1,0.4-0.4-0.1-0.4c-0.3-0.1-0.3-0.5-0.5-0.8c0.2,0,0.5,0,0.6,0
        c0,0.2,0,0.4,0,0.6c0.1-0.1,0.2-0.3,0.2-0.5c0.1,0.1,0.2,0.2,0.3,0.3c-0.2-0.3-0.3-0.5-0.5-0.8c-0.2,0.5-0.7,0-1,0
        c-0.1-0.3-0.2-0.6-0.2-0.9c-0.1,0-0.3,0-0.4,0c0.2,0,0.3-0.1,0.4-0.3c-0.2,0-0.4-0.1-0.4-0.3c0,0-0.1,0-0.2,0l0.1-0.3
        c-0.1,0-0.3,0-0.4,0l0-0.1c-0.1,0-0.2-0.1-0.2-0.1c0.2,0.1,0.4-0.1,0.5-0.2c-0.3-0.1-0.6-0.2-0.9-0.3c0.2-0.4,0.6,0.3,0.9,0.2
        c0-0.3-0.3-0.4-0.5-0.5c0-0.2,0-0.5-0.3-0.6c-0.1,0.2-0.1,0.4,0,0.7c-0.2-0.1-0.3-0.2-0.5-0.3l0.3,0c0-0.2,0-0.4,0-0.6
        c-0.1,0.1-0.3,0.3-0.4,0.4c0-0.2,0.2-0.4,0.3-0.6c-0.1,0-0.3-0.1-0.4-0.1c0-0.4-0.5-0.6-0.6-1c-0.2-0.1-0.3-0.1-0.5,0
        c0.3-0.1,0.5-0.4,0.6-0.8c-0.1,0-0.4,0-0.5,0c0.1,0.1,0.3,0.3,0.3,0.4l-0.4,0c0-0.1,0.1-0.3,0.1-0.5c-0.1,0.1-0.3,0.2-0.4,0.2
        c0.4-0.3-0.1-0.6-0.3-0.8c0.1-0.2,0.2-0.4,0.3-0.6c-0.2,0.2-0.5,0.4-0.6,0.7c0.1,0.3,0.2,0.5,0.3,0.8l0.1,0c0,0.1,0,0.2,0,0.3l0.2,0
        c0,0,0,0.1-0.1,0.2c0.1,0.1,0.3,0.3,0.4,0.5c-0.7-0.2-1.1-0.7-1.7-1.1c-0.3-0.4-0.9-0.6-0.9-1.1c-0.2-0.1-0.4-0.3-0.6-0.5
        c0-0.1,0-0.2,0-0.3c-0.4-0.3-0.6-0.8-0.8-1.3c-0.1,0-0.2-0.1-0.2-0.2c0-0.3-0.1-0.5-0.3-0.7c0.2-0.4-0.2-0.8-0.6-0.6
        c-0.3,0.1,0.1-0.5,0.2-0.2c0-0.2,0-0.3-0.1-0.4c-0.2-0.2-0.3-0.5-0.4-0.7c0,0.1,0,0.3,0,0.4c0.1,0.1,0.2,0.3,0.2,0.5
        c-0.1,0.1-0.3,0.3-0.4,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.1-0.1,0.2-0.1,0.3-0.2c0,0.1-0.1,0.3-0.2,0.4c0.4-0.1,0.3,0.4,0.5,0.6
        c-0.2,0-0.4,0-0.5-0.1c0,0.1,0.1,0.2,0.1,0.3c0.1-0.1,0.2-0.1,0.4-0.2c0.1,0.3,0.4,0.6,0.3,1c0.2,0.1,0.4,0.2,0.3,0.4
        c0.1,0,0.3,0,0.4,0.1h-0.3c0.2,0.3,0.4,0.6,0.7,0.9c-0.1,0-0.2,0.1-0.2,0.1c0.4-0.1,0.6,0.4,0.6,0.8c0.1,0.1,0.2,0.2,0.3,0.2
        c0,0.1-0.1,0.3-0.1,0.4l0.3-0.1l-0.1,0.3c0.1-0.1,0.2-0.3,0.2-0.4c0.1,0.3,0.2,0.5,0.4,0.7c0.1,0.1,0.2,0.2,0.3,0.3
        c-0.1,0.4,0.5,0.5,0.3,1c0.1,0.2,0.3,0.3,0.4,0.5l0.1,0c0,0.3,0.3,0.5,0.5,0.7c-0.1,0-0.2,0-0.3,0c-0.7-0.4-1.3-1-2-1.4
        c-0.1,0-0.2,0.1-0.2,0.1c-0.4-0.2-0.7-0.5-1-0.8c-0.1,0.2,0.1,0.3,0.2,0.4c0.3,0,0.4,0.4,0.6,0.6c0.1,0,0.3,0,0.4,0
        c0,0.1,0,0.2,0,0.3c0.5-0.1,0.4,0.7,0.6,0.9c-0.2-0.1-0.4-0.2-0.6-0.4c0.1,0.2,0.2,0.4,0.2,0.7c0-0.1,0-0.3,0-0.4
        c0.1,0.1,0.2,0.1,0.4,0.2c0-0.1,0.1-0.2,0.1-0.3c0,0.1,0.1,0.3,0.1,0.4c0.1,0.4,0.4,0.5,0.7,0.8l-0.2,0l0,0.3c0.4,0,0.4,0.6,0.6,0.9
        l0.2,0c0,0.1,0,0.2,0,0.3c0.1,0,0.2,0,0.3,0c0,0.1-0.1,0.3-0.1,0.3c0.3,0,0.6-0.1,0.8-0.2c-0.1,0.2-0.3,0.3-0.6,0.3
        c0.1,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.6,0.1,0.5,0.5c-0.3-0.2-0.7-0.5-1-0.7c-0.4-0.1-0.6,0.2-0.8,0.5c0,0.3,0.2,0.5,0.2,0.8l0.2-0.1
        l-0.1,0.2l0.2,0c0,0.1-0.1,0.2-0.1,0.3c0.5,0.2,0.7,0.8,1.2,1c-0.5-0.1-0.9-0.5-1.4-0.7c-0.2-0.1-0.4-0.3-0.5-0.4
        c-0.2-0.1-0.4-0.1-0.6,0c0,0.1,0,0.3,0,0.4c0-0.1,0.1-0.3,0.2-0.4c0.1,0.1,0.1,0.2,0.2,0.3c-0.3,0.2-0.4,0.6-0.6,0.9
        c0.1-0.2,0.2-0.4,0.5-0.6c-0.1,0.4,0.1,0.7,0.3,0.9c0.2,0.1,0.3,0.3,0.4,0.5l-0.3,0c0,0,0,0.1,0,0.1c0.1,0,0.3,0,0.4,0
        c0.1,0.3,0.4,0.1,0.7,0.1l0,0.3H236c0,0.1,0,0.3,0,0.4c0.1,0,0.2,0,0.3,0c0.1,0.2,0.3,0.4,0.3,0.6c-0.2-0.2-0.5-0.3-0.7-0.4
        c-0.1-0.1-0.3-0.2-0.4-0.3c0,0.2,0.2,0.4,0.3,0.6c-0.2-0.1-0.4-0.2-0.6-0.3c0.1,0.2,0.1,0.4,0.3,0.4c0.2,0.1,0,0.5,0.3,0.6
        c0,0.1,0,0.2,0,0.3c0.3-0.3,0.5-0.8,1-1c0,0.1,0,0.2,0,0.3c0.1-0.2,0.2-0.4,0.5-0.4c-0.1,0.3-0.2,0.6-0.2,1c0.1,0.2,0.4,0.5,0,0.5
        c0,0.2,0.1,0.3,0.2,0.5c-0.1,0.3-0.4,0.6-0.2,1c0.1-0.1,0.2-0.3,0.2-0.4c0.2,0.4,0,0.8,0.1,1.2c0,0-0.1,0.1-0.2,0.1
        c0.1,0,0.3,0,0.3,0c0,0.1,0,0.3,0,0.4c0.1,0,0.3-0.1,0.4-0.1l-0.1,0.3l0.2-0.1l0.1,0.2c0.1,0,0.2,0,0.2,0l0,0.2
        c0.1,0,0.3-0.1,0.3-0.2c0,0.1,0,0.3-0.1,0.4c0.5-0.3,0.9-0.5,1.4-0.8c-0.1,0.2-0.2,0.5-0.3,0.7c-0.1,0.4-0.2,0.8-0.6,1
        c0.1,0,0.3,0,0.4-0.1c0,0.1,0,0.4,0,0.5c0.1,0.3,0.2,0.6,0,0.8l0.2,0c0,0.3,0,0.6,0,0.9c0,0.1,0.1,0.2,0.1,0.2c0-0.2,0-0.4,0.2-0.6
        c0,0.1,0,0.4,0,0.6c0.1,0,0.2,0,0.3,0c0,0.1,0,0.3,0,0.4c0.4-0.1,0.1,0.2,0.1,0.4c0.1,0,0.2,0,0.3,0l0.1,0.2c0-0.1,0.2-0.3,0.2-0.3
        c0,0.1,0,0.3-0.1,0.4c0.1,0,0.4,0,0.5,0l0.1,0c0.4,0,0.6-0.4,0.9-0.6c0.1,0,0.2,0,0.3-0.1c-0.4,0.3-0.9,0.8-0.8,1.4l-0.1,0
        c0,0.2-0.1,0.4-0.2,0.6c0.1,0,0.2,0.1,0.3,0.1l-0.2,0c0,0.2-0.1,0.5-0.1,0.6c0.1,0,0.3,0,0.4,0c-0.2,0.1-0.6,0-0.5,0.4l0.2,0
        c0,0.3-0.2,0.9,0.3,0.9c-0.1,0.1-0.1,0.2-0.2,0.3c0.2,0.3,0.4,0.7,0.5,1c0.1,0,0.3-0.1,0.4-0.1c-0.1,0.1-0.2,0.2-0.3,0.3
        c0.2,0,0.4-0.1,0.5-0.2c0,0.1,0,0.2,0,0.2c0.1,0,0.3,0,0.4,0.1c0.3-0.3,0.7-0.6,1-0.8c0,0.1,0,0.4,0,0.5l-0.2,0
        c0,0.1,0.1,0.3,0.1,0.5l-0.2,0c0,0.1,0.1,0.2,0.1,0.3c-0.1,0-0.3,0-0.4,0.1l0.3,0.1c-0.2,0.1-0.3,0.2-0.5,0.4c0.1,0,0.3,0.1,0.5,0.1
        c0,0.3-0.2,0.5-0.4,0.8c0.2,0.2,0.3-0.1,0.4-0.2c0.1,0.1,0.3,0.2,0.4,0.3c-0.3,0-0.3,0.3,0,0.4c-0.1,0-0.3-0.1-0.4-0.2l0,0.4
        c0.1,0,0.2,0,0.3,0c0,0.3,0,0.5,0,0.8c-0.1-0.1-0.2-0.1-0.3-0.2l0.3-0.5c-0.3,0-0.2,0.3-0.3,0.5c0.1,0.2,0.3,0.2,0.6,0.2
        c0.1,0,0.3,0,0.4,0c0,0,0,0.1,0.1,0.2c-0.1,0-0.3-0.1-0.4-0.1l0,0.2l0.3,0c0,0.1-0.1,0.2-0.1,0.3c0.2-0.1,0.4,0,0.4,0.2
        c-0.2,0-0.3,0-0.5,0c0.1,0,0.3,0.1,0.4,0.1c0,0.1,0,0.2,0,0.2c0.2,0,0.3,0,0.5,0l0,0.1c0.3,0,0.5,0.1,0.7,0.3
        c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.1,0.4-0.2,0.6-0.4c-0.4,0.4-0.6,0.9-0.6,1.5c-0.1,0-0.2,0-0.2,0c0,0,0,0.1,0,0.1
        c0.4,0,0.5,0.3,0,0.2c0,0.2,0,0.4,0,0.6c-0.1,0-0.3,0.1-0.4,0.1c0.2,0,0.3,0,0.5,0l-0.3,0.3c0.1,0.1,0.3,0.2,0.4,0.3
        c-0.3,0-0.6-0.1-0.6-0.4c0,0.1-0.1,0.2-0.1,0.3c0.3,0,0.4,0.1,0.3,0.4c0.1,0,0.2,0,0.2-0.1c-0.1,0.4,0.3,1,0.8,0.9
        c0,0.2-0.1,0.3-0.3,0.4c0.3,0,0.5,0,0.8,0c-0.1,0.1-0.1,0.3-0.2,0.5c0.2-0.2,0.5-0.6,0.7-0.1c0.3,0,0.6-0.1,0.9-0.3
        c-0.2,0.3-0.5,0.5-0.7,0.8c-0.2,0.3-0.3,0.5-0.4,0.8c0.1,0,0.2,0.1,0.3,0.1c0,0-0.1,0.1-0.2,0.1c0,0.2-0.4,0.5,0,0.4
        c0,0.2,0.1,0.4,0.2,0.6c-0.1,0-0.3,0-0.4-0.1c0,0,0,0.1,0,0.2c0.1,0,0.2,0,0.3,0c0,0.3,0,0.5,0,0.8c-0.1,0-0.3-0.1-0.4-0.1
        c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0.2,0,0.6,0.3,0.7c0.3,0.4,0.3,0.8,0.3,1.3l0.1,0l0-0.1h0.1l0-0.4c0.1,0.1,0.4,0.3,0.5,0.4
        c-0.1,0.1-0.2,0.3-0.3,0.4c0.1-0.1,0.3-0.2,0.4-0.2c0.1,0.1,0.1,0.2,0.2,0.3c0-0.1,0-0.3,0-0.4c0.2,0,0.6,0.1,0.8,0.1
        c-0.1,0.1-0.2,0.2-0.3,0.3c0.2-0.1,0.5-0.2,0.5-0.5c0,0.1,0.1,0.2,0.1,0.3c0.1-0.1,0.3-0.2,0.4-0.2c-0.1,0.1-0.2,0.2-0.2,0.3
        c0.3,0,0.6,0,0.8-0.1c0,0-0.1,0.1-0.1,0.2c-0.2,0.5-0.7,0.6-1,1.1c-0.5,0.5-0.8,1.1-0.8,1.8c-0.2,0.3,0,0.7-0.2,1.1l0.2,0
        c-0.1,0.1-0.1,0.3-0.2,0.5c0.2-0.2,0.3-0.4,0.5-0.6c-0.1,0.3-0.2,0.5-0.3,0.8c0.5,0.1,0.1,0.7,0.1,1c0.2,0.1,0.4,0.4,0.5,0.1
        c-0.1,0-0.3-0.1-0.4-0.1c-0.1-0.3,0.3-0.4,0.3-0.7c0,0.2-0.1,0.4-0.1,0.6c0.2,0.1,0.3,0.2,0.5,0.3l0,0c-0.1,0-0.2,0-0.3,0.1
        l-0.1,0.2c0.2,0,0.4,0.2,0.6,0.3c0-0.1,0.1-0.2,0.1-0.3c0,0.1,0,0.4,0,0.5c0.3-0.1,0.5,0.2,0.8,0.4c0.1,0,0.3-0.1,0.4-0.1
        c0,0.1-0.1,0.2-0.1,0.3c0.1,0,0.2,0,0.2,0l-0.1-0.3c0.2,0.1,0.4,0.1,0.5,0.2c0,0.2-0.1,0.3-0.3,0.3c-0.2,0.2-0.4,0.5-0.7,0.7
        c0-0.1,0-0.3,0.1-0.5c-0.2,0.1-0.4,0.3-0.5,0.4c-0.1-0.3-0.3-0.3-0.5-0.1c-0.4-0.1-0.8-0.4-1.1-0.6c0,0,0.1-0.1,0.1-0.2
        c-0.1,0-0.3,0-0.4,0c0.3,0.1,0.5,0.5,0.4,0.8c0,0,0.1,0,0.2,0c-0.1,0.3,0.2,0.6,0.3,0.9c0.2,0.3,0,0.6,0,0.9
        c0.3-0.1,0.3-0.4,0.5-0.6c0.4-0.4,0.6-0.8,1-1.1c-0.1,0.2-0.3,0.4-0.4,0.6c-0.1,0.3-0.2,0.7-0.4,1c-0.1,0.1-0.2,0.2-0.3,0.3
        c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0.2-0.3,0.3-0.1,0.5c0.1,0.1,0.1,0.3,0.1,0.4c-0.1,0-0.2,0.2-0.3,0.2l0.4,0c-0.1,0.1-0.3,0.3-0.4,0.4
        c0,0.1,0.1,0.2,0.2,0.3c0,0,0.1,0,0.1,0c-0.2-0.2-0.3-0.5,0.1-0.6c0.1,0.2,0.2,0.7,0.5,0.7c0,0.1,0.1,0.4,0.1,0.5l-0.4-0.1
        c0.2,0.2,0.6,0.3,0.6,0.6c0.1,0,0.3-0.1,0.4-0.1c-0.2,0.2,0.1,0.3,0.2,0.4c0.2-0.1,0.4-0.1,0.6-0.2l0,0.2c0-0.1,0-0.2,0-0.2
        c0.6-0.1,0.2,0.5,0.1,0.7c-0.1,0-0.3,0-0.3,0l0,0.2c-0.1,0-0.2,0-0.3-0.1c-0.1,0.1-0.2,0.3-0.2,0.5c-0.1,0-0.2,0-0.2,0
        c0.1,0.2,0,0.4,0,0.6l0.2-0.1c0,0.2,0,0.4,0,0.6c-0.4,0.3,0.4,0.4,0.5,0.1c0,0.1,0,0.3-0.1,0.4c0.2-0.2,0.4-0.1,0.5,0.2
        c0.2,0,0.4,0,0.6,0.1c-0.3,0-0.7,0.1-1,0c-0.3,0.1-0.5-0.1-0.8-0.2c0.2,0.5,0.4,1,0.3,1.6c0.2,0.4,0.5,0.8,0.5,1.3
        c0,0.5,0.4,1,0.4,1.5c0.2,0.5,0.1,1,0.3,1.5c0.1,0.2-0.1,0.5,0,0.7c0.2,0.7,0.2,1.4,0.4,2.1c0,0.4,0.3,0.9,0,1.3
        c0.2,0.2,0.3,0.5,0.4,0.8c-0.2,0.4,0.1,0.8,0.2,1.2c-0.3,0.3,0,0.6,0.1,0.8c0,0.6-0.1,1.2,0.2,1.8c0.1,0.6,0.1,1.2,0,1.8
        c0.2,0.4,0.1,0.9,0.3,1.3c-0.1,0.6,0.2,1.1,0.1,1.7c0.2,0.7,0.3,1.5,0.3,2.2c0.2,0.5,0.1,1.1,0.1,1.6c0,0.5,0.1,1,0.2,1.4
        c0.1,0.5,0.1,1,0.2,1.6c0.2,0.8-0.1,1.7,0.2,2.4c0,0.6-0.1,1.2-0.3,1.7c0.2,0,0.3,0,0.4,0.2c0,0.3-0.1,0.7-0.1,1
        c0.2,0.3,0,0.6,0.1,0.9c0.1,0.3,0.1,0.6,0,0.9c0,0.6,0.2,1.2,0,1.8c0,0.1,0.1,0.2,0.2,0.3c0,0.2,0,0.5,0.1,0.7l-0.2-0.1
        c0.1,0.1,0.2,0.3,0.3,0.4c0-0.2-0.1-0.5-0.1-0.7c0.1,0,0.3,0.1,0.4,0.1c-0.2-0.1-0.5-0.2-0.4-0.4c0.1,0,0.2,0,0.3,0
        c-0.1,0-0.2-0.1-0.2-0.1c0-0.1,0-0.4,0-0.6c0.1,0.1,0.3,0.2,0.4,0.2c-0.2-0.2-0.3-0.4-0.4-0.6l-0.1,0c0.1-0.1,0.3-0.2,0.4-0.3
        c-0.1-0.1-0.2-0.2-0.3-0.2c0.2-0.4,0.1-0.9,0.1-1.3c0.1,0,0.3,0,0.4,0c0-0.2,0-0.3,0-0.5c0,0.1,0,0.3,0,0.5l-0.3,0
        c0-0.2,0-0.5,0.1-0.7c-0.1,0-0.2,0-0.3,0c0.1-0.2,0.3-0.4,0.2-0.6h0.2c0-0.1-0.1-0.3-0.2-0.4c0.2-0.2,0.2-0.6,0.2-0.9
        c0,0.1-0.1,0.3-0.2,0.4l-0.1,0c0.1-0.2,0.1-0.4,0.2-0.5c-0.1-0.1-0.2-0.3-0.2-0.4c0.1,0.1,0.3,0.2,0.4,0.3c0-0.4-0.2-0.8-0.3-1.2
        c0.1,0.1,0.2,0.1,0.4,0.3c0-0.1,0-0.4,0.1-0.6c-0.2,0.1-0.4,0.1-0.6,0c0.1,0,0.3,0,0.4-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
        c0.2,0.1,0.4,0.2,0.6,0.3c-0.1-0.2-0.1-0.4-0.1-0.6l-0.1,0l-0.1,0.3c-0.2-0.2-0.3-0.4,0.1-0.4c0-0.1-0.1-0.2-0.1-0.3l0.3,0.1
        c-0.1-0.2-0.2-0.4-0.3-0.5c0.1,0.1,0.3,0.1,0.4,0c-0.1-0.2-0.5-0.4,0-0.4c0-0.1-0.1-0.3-0.1-0.4c0.1-0.2,0.1-0.4,0.2-0.6
        c0-0.1-0.1-0.3-0.2-0.4l-0.1,0c0,0,0-0.1,0-0.2c0.1,0.1,0.4,0.2,0.5,0.3c0-0.3-0.3-0.7,0.1-0.9c-0.2,0-0.4,0.1-0.5,0
        c0.2-0.2,0.4-0.4,0.3-0.7c-0.1,0-0.3,0.1-0.4,0.2c0.1-0.1,0.2-0.3,0.4-0.3c0-0.1-0.1-0.2-0.1-0.3c0.3-0.1,0.3-0.5,0.4-0.7
        c-0.3,0.1-0.4,0.3-0.5,0.5c0-0.1,0-0.3,0-0.4c0.1,0,0.2,0,0.3,0c0-0.3,0-0.7-0.4-0.7l-0.1,0c0.1,0,0.2-0.1,0.2-0.1l0.1-0.1
        c0.1,0.1,0.2,0.1,0.3,0.2c0-0.2,0-0.4-0.2-0.5c0-0.1,0-0.2,0-0.3l0.2,0.1l-0.1-0.3c0.1,0,0.2,0,0.2,0c-0.1-0.3,0-0.5,0.2-0.7
        c-0.5-0.1-0.5,0.4-0.8,0.6c0.2-0.3,0.4-0.5,0.6-0.7c0.2,0.2,0.4,0.1,0.3-0.2l0.2,0c-0.1,0-0.2-0.1-0.2-0.1c0-0.1,0-0.3,0-0.4
        l0.2,0.1c0,0,0-0.1,0-0.2c-0.1,0-0.3,0.1-0.4,0.1c0-0.4,0.4-0.4,0.6-0.6c-0.1-0.1-0.2-0.1-0.2-0.2c-0.2,0.1-0.4,0.1-0.6,0.2
        c0,0,0-0.1,0-0.2c0.1,0,0.3,0,0.4,0c-0.1-0.2-0.2-0.4-0.3-0.6l0.2,0.1c0-0.2,0.2-0.3,0.4-0.2c0-0.2-0.1-0.5-0.2-0.6
        c0.3,0.2,0.5,0.1,0.7-0.2c-0.3,0-0.5,0.1-0.8,0.2c0.2-0.2,0.4-0.4,0.6-0.6c0-0.1,0-0.3-0.1-0.4c0.2-0.1,0.4-0.4,0-0.3
        c-0.2-0.2-0.3-0.4-0.4-0.7c0.1-0.1,0.2-0.3,0.3-0.4c-0.1,0.3-0.1,0.9,0.3,0.4c0,0.1,0,0.3,0.1,0.4l0.2,0c-0.1-0.3,0.3-0.3,0.2-0.6
        c-0.1,0.5-0.4,0.1-0.5-0.1c0.2,0,0.4-0.2,0.2-0.4c0.1-0.3,0.5-0.4,0.5-0.8c-0.3,0.1-0.4,0.6-0.8,0.5c0-0.2,0.2-0.3,0.4-0.4
        c0.1-0.3,0.2-0.6,0.3-0.9c0.2-0.3,0.5-0.6,0.8-0.9c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0-0.2,0-0.3,0c-0.2,0.3-0.5,0.6-0.4,1.1
        c-0.2,0.1-0.4,0.2-0.5,0.3c0.1-0.3,0.6-0.3,0.4-0.7c0-0.1,0.1-0.3,0.1-0.4c-0.1,0-0.2-0.1-0.2-0.1c0.1,0,0.3,0.1,0.4,0.2
        c-0.1-0.3,0.3-0.5,0.4-0.8c-0.2-0.1-0.5,0-0.7,0c0.1-0.1,0.3-0.2,0.4-0.3l-0.3-0.1c0.2-0.2,0.3,0.1,0.5,0.2c0.2-0.2,0.5-0.4,0.7-0.7
        c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2,0-0.3,0.1-0.5,0.2c0.2-0.2,0.3-0.3,0.4-0.5c0-0.2-0.2-0.3-0.3-0.5c0.2,0.1,0.3,0.2,0.5,0.3
        c-0.1-0.2-0.1-0.5,0-0.7c0.1,0.1,0.3,0.2,0.4,0.2l-0.3,0.2l0.1,0.2c0.4-0.1,0.4-0.3,0.4-0.7c-0.1,0-0.3,0-0.4,0c0,0,0-0.1,0-0.1
        c0.1,0,0.2,0,0.2,0l0.2-0.2l-0.3,0c0-0.3-0.2-0.7-0.3-1c0.1,0.1,0.3,0.3,0.5,0.3c0-0.2-0.2-0.4-0.4-0.5c0.1,0,0.2,0,0.3,0
        c0,0.2,0.2,0.3,0.4,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c0.2-0.2,0.6-0.1,0.6-0.5c-0.2-0.1-0.3-0.2-0.3-0.5c0.1,0,0.3,0,0.4,0
        c0.1,0.1,0.2,0.2,0.2,0.3c0.2-0.3-0.4-0.4-0.5-0.5c0-0.1,0.1-0.2,0.2-0.3c0.1,0,0.2,0.1,0.3,0.1c-0.2-0.3-0.5-0.6,0-0.8l0,0.2
        c0,0,0.1,0,0.2,0c-0.1-0.1-0.2-0.3-0.2-0.3c0.1,0,0.3,0,0.3,0c-0.1-0.2,0-0.4,0.1-0.6c-0.1,0.1-0.2,0.2-0.2,0.2
        c-0.1-0.1-0.2-0.3-0.2-0.4c0.2,0,0.3,0,0.5,0c-0.1-0.3-0.1-0.5-0.1-0.8c-0.2,0.2-0.4,0.6-0.6,0.8l-0.1-0.2c-0.1,0-0.2-0.1-0.2-0.1
        c0,0,0.1-0.1,0.2-0.1l0-0.2c0,0.1,0.1,0.2,0.1,0.2c0.3-0.1,0.3-0.4,0.5-0.5c0.2,0,0.5,0.2,0.6-0.1c-0.4,0-0.6-0.4-0.9-0.6
        c0.2-0.1,0.3,0.2,0.5,0.3c0-0.1,0.1-0.4,0.1-0.5c-0.1,0-0.2,0-0.2,0c0.1-0.1,0.2-0.3,0.3-0.4c0,0.1,0.1,0.2,0.1,0.2l0.1,0.1
        c0-0.2,0-0.5,0.1-0.6c0.1,0.1,0.2,0.2,0.3,0.2c-0.1-0.2-0.2-0.4-0.3-0.5c-0.2,0.1-0.3,0.4-0.5,0.4c-0.1-0.1-0.2-0.2-0.2-0.3l0.2,0
        c0-0.1,0-0.3,0-0.4l0.1,0l-0.1,0.5c0.2-0.2,0.4-0.4,0.6-0.6c0.1,0.1,0.3,0.2,0.4,0.2c0-0.2-0.2-0.4-0.4-0.5c0.1-0.1,0.2-0.4,0.3-0.6
        c-0.4,0.1-0.6,0.4-0.6,0.8c-0.4,0-0.3-0.4,0-0.4c0-0.1,0-0.2,0.1-0.2l0.2,0l-0.1-0.2c0.2,0,0.3-0.1,0.5-0.2l0.1,0.2
        c0.3-0.1,0.3-0.3,0.1-0.5c0.5-0.1,0.5-0.7,0.5-1.1c-0.2-0.1-0.4-0.2-0.5-0.4c0.1,0,0.4,0,0.5,0c0-0.1-0.1-0.3-0.1-0.4l0.3,0
        c-0.1-0.2-0.4-0.3-0.4-0.5c0.1,0,0.2,0.1,0.3,0.2l0,0.2l0.3,0c-0.2-0.4-1-0.6-0.8-1.2c0.2,0.4,0.6,0.6,0.9,0.9c0,0,0.1,0.1,0.2,0.1
        c-0.2-0.4-0.1-0.8,0.1-1.2c-0.2,0.1-0.3,0.2-0.3,0.5c-0.1,0-0.3,0.1-0.4,0.1c0.1-0.1,0.2-0.4,0.2-0.5c-0.2,0-0.4-0.1-0.6-0.2
        c0.1,0,0.3,0.1,0.5,0.1c0-0.1-0.1-0.3-0.1-0.4l0.2,0.1c0.1-0.1,0.2-0.3,0.3-0.4c0.2,0.2,0.3,0.4,0.3,0.7c0.3-0.3-0.1-0.7-0.1-1.1
        c0.1,0,0.3,0,0.4,0c0-0.1-0.1-0.3-0.1-0.4c0.2,0,0.4-0.1,0.4-0.3c0-0.3,0.6-0.6,0.2-0.9c0,0.4-0.3,0.6-0.6,0.4
        c0.1,0.2,0.2,0.4,0.3,0.6c-0.4,0.1-0.4-0.6-0.8-0.6c0.2-0.1,0.5-0.2,0.8-0.1c0-0.1-0.1-0.3-0.1-0.5c0.1,0.1,0.2,0.2,0.3,0.2
        c0.2-0.2,0.3-0.4,0.5-0.6c-0.1,0-0.3,0-0.4,0c0,0.1-0.1,0.3-0.1,0.4c-0.3-0.3-0.1-0.5,0.1-0.8c0.1,0.1,0.2,0.2,0.3,0.2l0.1-0.3
        c-0.1,0-0.4-0.1-0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c-0.1-0.1-0.2-0.3-0.4-0.4c-0.1-0.2-0.2-0.3-0.3-0.4c0.2,0.1,0.4,0,0.6-0.2
        c0-0.4,0.4-0.7,0.7-1c0.1,0.2,0.1,0.4-0.2,0.6c0.1-0.1,0.5,0,0.4-0.3c-0.2-0.2,0.2-0.5-0.1-0.6c-0.1-0.1-0.2-0.2-0.2-0.2
        c0-0.1,0-0.2,0-0.2c0,0.1,0.1,0.2,0.1,0.2c0.1,0.1,0.2,0.1,0.4,0.2c0-0.1,0-0.3,0-0.4c0.1,0,0.3,0,0.4,0c-0.1-0.3,0-0.5,0.2-0.6
        c0-0.1,0-0.2,0-0.2c0.1,0,0.2,0,0.3,0.1c-0.1-0.1-0.2-0.2-0.3-0.3c0.1-0.1,0.3-0.3,0.5-0.3c0-0.2,0-0.3,0.1-0.5
        c-0.1,0.2-0.2,0.3-0.3,0.6c-0.1-0.1-0.2-0.2-0.2-0.4c0,0-0.1-0.1-0.1-0.2c0.1,0,0.3,0.1,0.4,0.1c-0.1-0.2-0.2-0.4-0.4-0.5
        c0.2,0.1,0.4,0.2,0.6,0.2c0.1-0.3,0.2-0.7-0.1-1c0.2,0,0.3,0.1,0.5,0.2c0-0.3-0.1-0.5-0.1-0.7c0.1-0.2,0.3-0.4,0.4-0.6
        c-0.2,0-0.3,0.1-0.5,0.1c0.2-0.2,0.5-0.6,0.1-0.7c0.4-0.2-0.2-0.6,0.1-0.8c0.1,0.3,0.2,0.4,0.5,0.3c-0.1,0.1-0.2,0.3-0.2,0.4
        c0.2,0.3,0.5,0.5,0.7,0.8c-0.1-0.3,0.2-0.5,0.1-0.8c-0.1,0-0.2,0.5-0.3,0.2c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.3-0.1,0.4-0.2
        c-0.1-0.1-0.2-0.2-0.2-0.2l-0.1,0.2c-0.5-0.2-1-0.4-1.3-0.9c0.4,0.1,0.8,0.2,1.1,0.2c-0.1-0.1-0.2-0.1-0.3-0.2
        c0.1,0,0.3,0.1,0.4,0.2c-0.2-0.3-0.1-0.5,0.3-0.5c0-0.1-0.1-0.3-0.2-0.4c0.2,0.1,0.4,0.2,0.5,0.4c0.1-0.2,0.3-0.3,0.2-0.6
        c-0.1,0.1-0.3,0.2-0.3,0.3c-0.1-0.2-0.2-0.3-0.3-0.5c0.1,0.1,0.2,0.2,0.4,0.3l-0.1-0.5c0.2,0.1,0.3,0.2,0.4,0.4
        c0.2-0.3,0.1-0.6,0.1-0.9c0.5,0.1,0.5-0.5,0.7-0.8c-0.1-0.1-0.2-0.3-0.2-0.4c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.2
        c-0.3,0-0.4,0.2-0.4,0.4c0,0-0.1,0-0.2,0c-0.1,0.1-0.2,0.3-0.2,0.5l0,0c0-0.1,0-0.2,0.1-0.3c0.2-0.3,0.4-0.7,0.5-1
        c0.1,0,0.2,0,0.2,0c0-0.3-0.1-0.6-0.2-0.8c0.5,0.1,1-0.1,1.6-0.2c-0.1,0-0.4,0-0.5,0c0.1-0.2,0.3-0.2,0.5-0.4
        c-0.1,0-0.4,0.1-0.6,0.1c0.2-0.2,0.4-0.4,0.6-0.6c-0.1-0.1-0.2-0.1-0.3-0.2c0-0.3,0.2-0.6,0-0.9c-0.1-0.2,0.2-0.2,0.3-0.3
        c0,0.2-0.1,0.3-0.1,0.5c0.1,0,0.2,0,0.3-0.1c-0.1,0.1-0.1,0.3-0.2,0.4l-0.2,0c0.1,0.2,0.2,0.3,0.4,0.4c0-0.1,0.1-0.4,0.1-0.5
        c0.1-0.3,0.3,0,0.5,0.1c-0.1-0.1-0.1-0.2-0.2-0.3l-0.2,0l0.1-0.3c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.1,0.3-0.2,0.2-0.4
        c0.3-0.3-0.3-0.4-0.1-0.7c0.2-0.1,0.3,0,0.2,0.3c0.1,0.1,0.3,0.2,0.4,0.4c-0.2,0.1-0.3,0.2-0.5,0.3c0.2,0,0.4,0,0.6,0.1
        c0-0.1,0.1-0.3,0.1-0.4c0-0.2-0.1-0.4-0.4-0.4c0.1-0.2,0.3-0.3,0.6-0.2c0,0,0-0.1,0-0.2c-0.1,0-0.3,0.1-0.4,0.1
        c0.1-0.2,0.4-0.5,0-0.6c-0.1,0.2-0.2,0.4-0.1,0.6c-0.5-0.1,0-0.5,0.1-0.8c-0.2-0.1-0.4-0.4-0.5-0.6c0-0.1,0-0.2,0-0.4
        c0.1,0,0.2,0,0.2,0c0.1-0.4,0.7-0.1,1,0c-0.3-0.5,0.3-0.3,0.4-0.1c0-0.1-0.1-0.2-0.1-0.3c0.2-0.1,0.4-0.2,0.6-0.3l0.1,0.2
        c0-0.1,0-0.2,0-0.2l-0.4,0.1c0.1-0.2,0.3-0.4,0.5-0.5c0,0.1,0.1,0.2,0.2,0.2c-0.1-0.3-0.3-0.5-0.6-0.5c0.1,0.3,0.1,0.6-0.2,0.8
        c0-0.1,0-0.3,0-0.4c0-0.1-0.1-0.3-0.1-0.4c-0.1,0.1-0.3,0.2-0.3,0.3c0.1-0.1,0.2-0.3,0.4-0.4l0,0.3c0.4-0.1,0.3-0.4,0-0.6
        c0.1,0,0.3,0,0.4,0c0,0.1-0.1,0.4-0.1,0.5c0.4,0,0.3-0.3,0.2-0.6c0.1,0,0.3,0,0.4-0.1c0-0.1,0-0.2-0.1-0.3c0.3,0,0.5-0.1,0.3-0.4
        c-0.2-0.1-0.4-0.3-0.6-0.3c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0.1,0.3,0.2,0.4,0.4c0.1,0,0.2,0,0.2,0c-0.1-0.2-0.1-0.3-0.2-0.5
        c0.1,0,0.4,0.1,0.5,0.1c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2,0.1-0.4,0.2-0.5,0.4c0-0.1-0.3-0.3,0-0.3c0.1,0,0.3,0,0.4,0
        c-0.2-0.3-0.5-0.4-0.7-0.7c-0.3-0.2,0-0.6-0.4-0.6c0.1,0,0.3,0,0.4,0c0-0.1,0-0.3,0-0.3c0,0,0.1,0,0.1,0l-0.1,0.2
        c0.4,0,0.7-0.2,1-0.2c0-0.1-0.1-0.2-0.1-0.3c0.5,0.2,0.5-0.4,0.7-0.7c0,0.2,0,0.7,0.4,0.6c-0.1,0-0.2-0.1-0.2-0.2
        c0.1-0.1,0.3-0.3,0-0.4c0-0.2-0.1-0.5,0.2-0.5c0,0.1-0.1,0.4-0.2,0.6c0.1-0.2,0.3-0.3,0.4-0.5c0-0.1,0-0.2,0-0.3c0.1,0,0.3,0,0.4,0
        c-0.1-0.2-0.3-0.3-0.6-0.3c0.1-0.1,0.1-0.2,0.2-0.3l0.1,0.2c0.1,0,0.3,0.1,0.3,0.1c-0.1-0.3,0-0.5,0-0.7c0.4-0.1,0.8-0.4,0.8-0.8
        c-0.4-0.2-0.8-0.4-1.1-0.7c-0.3-0.2-0.3-0.6-0.6-0.8c0.2-0.1,0.4-0.2,0.7-0.2c0,0.1,0,0.3,0,0.4c0.4-0.3,0.7-1,1.3-0.9
        c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.3-0.1,0.5-0.1c0-0.4,0.3-0.3,0.5-0.1l-0.2,0.3l0.1,0c0-0.3,0.2-0.4,0.4-0.6c0-0.2-0.3-0.3-0.2-0.5
        c0.4-0.2,0.3-0.7,0.2-1c0.1,0,0.2,0,0.3,0l0-0.2c-0.2,0-0.4,0-0.6,0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.2,0.1,0.3,0,0.3-0.2
        c0.2-0.1,0.1-0.4,0.1-0.6c-0.4,0-0.8-0.1-1.1-0.4c0.2,0.2,0.5,0.1,0.7,0l0-0.1c0.4,0,0.8,0,1.1,0l-0.1-0.2c0.3,0.2,0.5-0.2,0.7-0.3
        c0-0.1,0-0.2,0-0.3l0.2,0.1c0-0.1,0-0.2,0-0.3c0.2,0,0.5-0.1,0.7-0.1c0.2-0.2,0.3-0.5,0.5-0.7c-0.1-0.1-0.2-0.3-0.2-0.5
        c0.3-0.1,0.3-0.2,0-0.2c0-0.6,0.5-0.2,0.7,0.1c0.2-0.1,0.3-0.2,0.4-0.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.4-0.3-0.8-0.7-1.2-1.1
        c0-0.1,0-0.2,0-0.3c0.3,0.2,0.6,0.4,0.9,0.5l0-0.2c0.1,0,0.2,0.1,0.3,0.2c0-0.3,0.1-0.4,0.3-0.4l0-0.1l0.1,0c0-0.1-0.1-0.2-0.1-0.3
        c0.1-0.1,0.3-0.3,0.4-0.4c-0.2-0.3,0.1-0.5,0.3-0.7c0,0.2-0.1,0.4-0.2,0.6c0,0.2-0.1,0.4-0.3,0.5c0.1,0,0.3,0,0.4,0
        c0-0.1-0.1-0.3-0.1-0.4c0.1,0,0.3,0,0.4,0c-0.1-0.2-0.1-0.4-0.2-0.6c0.1,0,0.2,0.1,0.3,0.1c0-0.1,0-0.3,0-0.3c0.1,0,0.2,0,0.3,0
        l0-0.2c0.5,0.2,0.3-0.3,0.2-0.6c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0-0.3,0.1-0.4,0.1c0.1-0.4,0.5-0.5,0.7-0.7c-0.3-0.2,0-0.3,0.1-0.3
        c0,0.1,0,0.3,0,0.4c0-0.2,0.1-0.5,0.1-0.6c0.6,0.2,0.9-0.3,1-0.8c0.1,0,0.3,0.1,0.4,0.1c-0.1-0.2-0.3-0.4-0.6-0.4
        c0,0.3-0.2,0.7-0.5,0.5c0.1-0.1,0.3-0.2,0.4-0.3c-0.1-0.1-0.2-0.2-0.2-0.3c0.2,0,0.3,0.3,0.5,0.1c0-0.2-0.1-0.3-0.1-0.5
        c0.3,0.1,0.2,0.5,0.4,0.6c0-0.1,0-0.4,0.1-0.5l0.2,0c0-0.1,0-0.4,0-0.5c-0.1,0.1-0.2,0.2-0.3,0.3l-0.2,0c0.1-0.1,0.2-0.2,0.3-0.3
        c0.3-0.1,0.6-0.4,0.4-0.7c0.2,0,0.3,0,0.5,0c0,0,0,0.1,0,0.1c-0.1,0.1-0.3,0.2-0.4,0.3c0.2,0,0.3,0,0.5,0c0-0.1,0-0.3,0-0.4
        c0.6,0,0.9-0.6,0.8-1.1c0.2,0.1,0.3,0.1,0.5,0.3c0-0.1-0.1-0.3-0.1-0.4l0.1,0c0-0.4,0.4-0.5,0.7-0.7l-0.3,0c0,0,0-0.1,0-0.1
        c0.1,0,0.2,0,0.3-0.1l0.1-0.2l-0.3,0l0-0.2c-0.1,0-0.3-0.1-0.4-0.1c0.1,0,0.2,0,0.3,0c0.1-0.1,0.3-0.3,0.4-0.4
        c-0.1,0.1-0.2,0.4-0.2,0.5c0.1,0,0.3,0,0.4,0.1c0-0.2,0-0.4,0-0.7c0-0.2,0.1-0.7,0.4-0.4l0.1-0.2l0.2,0c-0.1,0.1-0.2,0.2-0.3,0.3
        c-0.1,0-0.3,0-0.4,0.1c0,0.2,0,0.5,0,0.6c0.3-0.1,0-0.8,0.4-0.5c0.2-0.6,1.1-0.9,0.7-1.5c0.1,0.1,0.2,0.2,0.3,0.3
        c0-0.1,0.1-0.2,0.1-0.3c-0.3,0.1-0.4-0.2-0.4-0.4c0.2,0.1,0.4,0.2,0.6,0.3c0.1-0.1,0.2-0.3,0.2-0.4c-0.4-0.1-0.2-0.4-0.1-0.6
        c0.1,0,0.2,0,0.2,0c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0.2,0.4,0.2,0.6,0.1c0-0.3,0.3-0.5,0.5-0.6c-0.2-0.2-0.4-0.3-0.7-0.3
        c0.2-0.2,0.5-0.1,0.8,0l0-0.3c0.1,0,0.2,0.1,0.3,0.1c-0.1-0.3-0.2-0.6-0.1-0.8c0.3-0.1,0.6-0.3,0.8,0c-0.3,0-0.6,0.2-0.8,0.4
        c0.3,0.3,0.4-0.1,0.6-0.2c0.1-0.1,0.4-0.2,0.3-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c0.2-0.2,0.6-0.1,0.9-0.2c-0.1-0.2-0.1-0.3-0.2-0.4
        c0.2-0.2,0.3-0.7,0.6-0.5c-0.1,0.2-0.3,0.3-0.4,0.5c0.1,0,0.3,0,0.4-0.1c0-0.1,0-0.3,0.1-0.4c0.2,0,0.4,0,0.7,0
        c-0.1-0.1-0.2-0.2-0.3-0.2c0.2-0.1,0.3-0.2,0.5-0.4l-0.2-0.1c0.2-0.1,0.4-0.2,0.6-0.4c0.1-0.2,0.2-0.4,0.4-0.5
        c-0.2,0-0.4,0.1-0.6,0.3c-0.1,0.4-0.6,0.5-0.6,0.9c-0.2,0.1-0.3,0.2-0.5,0.3c0-0.1,0-0.2,0-0.3c0.1,0,0.4-0.1,0.5-0.2
        c-0.1-0.3-0.4-0.2-0.5-0.3c0.2,0,0.3,0,0.5,0c0.3-0.1,0.4-0.4,0.5-0.7c0.1,0,0.4,0,0.5,0c0-0.1,0-0.4-0.1-0.5
        c0.2-0.1,0.4-0.6,0.5-0.2c-0.2,0.1-0.3,0.3-0.4,0.5l0.2,0c-0.1,0.2,0,0.4,0.1,0.5c0-0.2,0-0.5,0-0.7c0.1,0,0.3,0.1,0.4,0.1
        c-0.2-0.2-0.3-0.4,0-0.5c-0.1,0-0.3-0.1-0.3-0.1c0.2,0,0.4,0,0.6,0.1c0.2-0.2,0.3-0.4,0.4-0.6c-0.2,0.1-0.6-0.1-0.3-0.2
        c0.3,0,0.5,0,0.7-0.2c0.2,0.2,0.3,0.2,0.3-0.1l-0.3,0c0.3-0.3,0.6-0.9,1.1-0.6c0-0.2,0-0.4,0-0.6c-0.2,0.1-0.4,0.2-0.5,0.5
        c-0.1,0-0.3,0.1-0.4,0.1c0.3-0.3,0.6-1,1.2-0.7c0.3,0,0.2-0.3,0.3-0.5c0.3,0.1,0.5-0.2,0.6-0.4c-0.2,0-0.5,0.1-0.7,0.2
        c0.1-0.1,0.1-0.2,0.2-0.3c0.2,0,0.5,0,0.8,0c0.1-0.2,0.1-0.4,0.4-0.4c0-0.3,0.4-0.7,0.1-1c-0.3-0.1,0.1-0.3,0.2-0.4
        c0,0.1,0,0.3-0.1,0.4c0,0.1,0.1,0.3,0.1,0.5l0.1,0c0.1-0.3,0.3-0.5,0.2-0.8c0.1-0.3,0.4-0.5,0.7-0.6c0.1,0.3-0.2,0.3-0.4,0.4
        c0,0.2-0.1,0.4-0.1,0.6c0.1-0.1,0.2-0.3,0.3-0.4c0.1,0,0.3,0,0.3,0c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.2-0.3,0.3-0.4
        c-0.1,0-0.3,0.1-0.3,0.1l-0.1,0.1l-0.1,0.1c0-0.1,0.1-0.3,0.1-0.4l-0.1-0.2c0.1,0,0.2-0.1,0.3-0.1l0-0.2c0.1,0,0.3,0.1,0.3,0.1
        c0.1-0.2,0.2-0.7,0.6-0.6c-0.1-0.4,0.1-0.8,0.2-1.2c0.3-0.5,0.5-1,1-1.3c0.5-1.5,1.6-2.7,2.5-3.8c0.2-0.2,0.5-0.3,0.7-0.5l-0.2-0.2
        c0.2,0,0.4,0,0.5,0c-0.1,0-0.2-0.1-0.3-0.1c0-0.1-0.1-0.2-0.1-0.3c-0.1,0.2,0,0.5-0.2,0.6c-0.2,0-0.4,0.2-0.6,0.3
        c0.1-0.3,0.3-0.5,0.5-0.6c0-0.2,0-0.5,0-0.7l-0.2,0.1c0,0.6-0.6,1-0.8,1.5c-0.1,0.2-0.2,0.3-0.3,0.5c-0.3,0.3-0.2,0.7-0.5,1
        c-0.4,0.5-0.7,1-1,1.4c-0.4,0.4-0.6,1-1,1.4c-0.5,0.7-1,1.5-1.5,2.2c-0.2,0.3-0.3,0.6-0.6,0.9c0,0.1,0,0.2-0.1,0.3
        c-0.1,0.1-0.2,0.2-0.3,0.3c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.1,0.2-0.2,0.3-0.3c0-0.1,0-0.2,0-0.3c0.2-0.3,0.4-0.6,0.5-1
        c-0.6,0.5-1.1,1.1-1.8,1.4c0.6-0.6,1-1.4,1.2-2.3c0.5-1,0.8-2,1.3-2.9c0.2-0.2,0.4-0.5,0.4-0.7c0,0-0.1,0.1-0.1,0.2
        c0,0.3-0.2,0.5-0.4,0.7c0,0-0.1,0-0.2-0.1c0,0.2-0.1,0.5-0.3,0.6c-0.2,0.2-0.2,0.6-0.6,0.5c0,0.1,0.1,0.2,0.1,0.3
        c-0.1,0-0.2,0-0.3,0c0,0.1,0,0.2,0,0.3c-0.4,0-0.4,0.6-0.7,0.5c0,0.1,0,0.2,0.1,0.3c-0.2,0.1-0.2,0.2-0.3,0.4
        c-0.3,0-0.5,0.1-0.4,0.4c-0.3,0.1-0.8,0.2-0.7,0.7c-0.1,0-0.3,0.1-0.5,0.2c0.1,0,0.2,0.1,0.3,0.2c-0.2,0.1-0.4,0.3-0.5,0.5l-0.1-0.3
        c-0.3,0-0.1,0.5-0.3,0.5c0,0.1-0.1,0.2-0.1,0.3c-0.6,0.2-0.7,1-1.2,1.3c-0.1,0-0.3,0.1-0.4,0.1c0,0.1,0.1,0.2,0.1,0.3
        c-0.1,0-0.2,0-0.3-0.1c0,0.1-0.1,0.3-0.1,0.4c-0.2,0.1-0.3,0.2-0.3,0.4c-0.1-0.1-0.2-0.2-0.3-0.2c0,0.2,0,0.5-0.1,0.7
        c-0.1,0-0.2-0.1-0.3-0.2c0.2,0.3,0,0.6-0.2,0.7c-0.2,0.2-0.4,0.2-0.7,0.2c-0.3,0.1,0,0.3,0.2,0.3c-0.2,0.2-0.7,0.3-0.6,0.6
        c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.1-0.3,0.2l0-0.2c-0.3,0.1-0.3,0.3-0.1,0.5c0.1,0.2-0.3,0.1-0.4,0.2c0,0.3-0.3,0.2-0.5,0.3
        c0.2,0.2,0.5-0.3,0.5,0.2c-0.3,0.2-0.6,0.4-1,0.6c0.1,0,0.4,0.1,0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0.2-0.3,0.4-0.4,0.6
        c-0.1,0-0.2,0.1-0.3,0.2c0,0.2-0.1,0.6-0.4,0.5c-0.1,0.2-0.2,0.5-0.4,0.7c0-0.1,0-0.3,0-0.5c-0.2,0.3-0.5,0.6-0.5,1
        c-0.7-0.2-0.3,0.9-0.9,0.8c-0.1,0.3-0.2,0.9-0.7,0.8c0.1,0.2-0.1,0.4-0.3,0.6c-0.4,0.4-0.7,1-1.2,1.3c0-0.4-0.3-0.2-0.3,0l0.3,0
        c-0.1,0.3-0.3,0.4-0.5,0.5c-0.4-0.2-0.3,0.3-0.3,0.5c-0.2,0.1-0.4,0.1-0.6,0.2c0,0.1,0.1,0.2,0.1,0.3c-0.2,0.3-0.8,0.3-0.7,0.7
        c-0.2,0.3-0.4,0.4-0.7,0.2c0.1,0.1,0.2,0.1,0.3,0.2c-0.2,0.3-0.5,0.4-0.7,0.7c0.1,0.4-0.3,0.3-0.5,0.3c0.2,0.2-0.1,0.4-0.2,0.6
        c-0.1,0.3-0.7,0.2-0.4,0.6c-0.1,0-0.4-0.2-0.5,0c0,0.1,0.1,0.2,0.2,0.2c-0.1,0.2-0.3,0.2-0.5,0.2c-0.1,0.5-0.5,0.9-0.9,1.2
        c-0.1,0.3-0.1,0.7-0.5,0.5c0,0.1,0,0.3,0.1,0.4c-0.1,0-0.3,0-0.4,0.1c-0.1,0.3-0.2,0.5-0.2,0.8c-0.5,0.1-0.5,0.8-1,0.8
        c-0.1,0.2-0.2,0.4-0.1,0.6c-0.2,0.1-0.4,0.2-0.6,0.3c0,0.2,0,0.3,0,0.5c-0.2,0.1-0.4,0.2-0.6,0.3c-0.1,0.6-0.8,0.9-1.1,1.5
        c0,0.1,0,0.3-0.1,0.4c-0.2,0-0.4,0.1-0.6,0.3c0,0.1,0.1,0.4,0.1,0.5c-0.1,0-0.3,0-0.4,0c0,0.3-0.3,0.5-0.4,0.7
        c0.1,0.4-0.3,0.5-0.5,0.7c0,0.2,0,0.5-0.3,0.5c-0.2,0.4-0.4,0.7-0.7,1.1c0,0.1-0.1,0.3-0.1,0.3c-0.3,0-0.6,0.3-0.5,0.6
        c-0.4,0.4-0.9,0.9-0.9,1.5c-0.1,0.2-0.3,0.3-0.5,0.5c0,0.4-0.3,0.7-0.5,1c-0.3,0.4-0.3,0.8-0.6,1.2c-0.2,0.3-0.3,0.6-0.4,0.9
        c-0.3,0.1-0.3,0.4-0.4,0.6c-0.1,0.3-0.3,0.5-0.3,0.8c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0.2-0.1,0.4-0.2,0.6c-0.5,0.8-0.7,1.7-1.2,2.5
        c-0.1,0.4-0.4,0.8-0.5,1.2c-0.2,0.2-0.2,0.4-0.3,0.6c-0.1,0.4-0.6,0.7-0.5,1.2c-0.4,0.9-0.7,1.9-1.1,2.8c-0.2,0.5-0.3,1.1-0.7,1.5
        c0,0.3-0.1,0.6-0.3,0.8c-0.1,0.6-0.3,1.1-0.6,1.6c-0.1,1.2-0.8,2.3-1,3.6c-0.2,1.2-1.1,2.2-0.9,3.4c-0.1,0.7-0.6,1.3-0.6,2
        c-0.5,1.1-0.6,2.3-1,3.5c-0.2,0.5-0.2,1.1-0.4,1.5c-0.1,0.8-0.5,1.6-0.6,2.4c-0.1,0.3,0,0.7-0.2,1c-0.1,0.3,0,0.6-0.2,0.8
        c0.1,0.2,0,0.5-0.1,0.7c0,1.1-0.3,2.2-0.2,3.3c-0.1,0-0.2-0.1-0.3-0.1c0.4,0.3,0.1,0.7,0.1,1.1c0.1,0.2,0.1,0.4,0.1,0.7
        c-0.5-0.5-0.3-1.2-0.3-1.8c0-0.4-0.2-0.8-0.1-1.2c0-0.4-0.2-0.8,0.2-1.1c-0.2,0-0.4,0-0.6-0.1c0.1,0,0.3,0.1,0.4,0.1
        c0-0.2-0.1-0.5-0.2-0.7c0.1,0.1,0.3,0.1,0.4,0.3c0,0,0-0.1,0-0.2c-0.1,0-0.3,0-0.3,0c0.3-0.3-0.1-0.6-0.1-0.8c0.1,0,0.3,0,0.3,0
        c0,0,0-0.1,0-0.2l-0.2,0c0.1-0.6,0-1.1,0-1.7c0.1-0.1,0.2-0.2,0.3-0.3c-0.1-0.1-0.2-0.2-0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3
        c-0.1,0-0.2,0-0.3,0c0-0.1,0.1-0.3,0.1-0.4l-0.2,0c0,0,0.1-0.1,0.1-0.2c0.2-0.3,0.3-0.7,0.2-1.1l0.2,0c-0.1,0-0.2-0.1-0.2-0.1
        c0.1-0.3,0.1-0.5,0.1-0.8c-0.1,0.1-0.2,0.2-0.3,0.3c0.1-0.2,0.3-0.5,0.6-0.6c0,0.1,0,0.4,0,0.5c0.1-0.1,0.1-0.3,0.1-0.5
        c-0.1,0-0.3,0-0.4-0.1c-0.1-0.3,0-0.4,0.3-0.4c0-0.1-0.1-0.2-0.2-0.3c0-0.2,0.5-0.5,0.1-0.6c0-0.1,0-0.3,0-0.3
        c0.3,0.1,0.4-0.1,0.5-0.3c-0.1,0-0.2,0.1-0.3,0.2c-0.3,0.1-0.2-0.3-0.3-0.5c0.1,0.1,0.3,0.2,0.4,0.2c-0.1-0.2-0.3-0.6-0.3-0.7
        c0.1,0,0.3,0.1,0.4,0.1c0-0.1-0.1-0.2-0.1-0.2c0-0.4-0.4-0.1-0.5,0.1c0.1-0.2,0.1-0.4,0.2-0.5c0.2,0,0.3,0,0.5,0
        c0-0.3-0.1-0.5-0.2-0.8c0.2,0,0.3-0.1,0.5-0.1c-0.1-0.4,0-0.8-0.2-1.2l0.2,0c0,0.5,0.1-0.2,0.2-0.3l0.2,0c0-0.2-0.1-0.5-0.3-0.6
        c0.1,0,0.3,0,0.5,0c-0.1-0.2-0.2-0.4-0.2-0.6c0.1-0.1,0.2-0.2,0.3-0.3l-0.2,0c-0.1,0.2-1,0.7-0.1,0.6c-0.1,0-0.4,0.1-0.6,0.2
        c0,0.1,0,0.3,0,0.4c0,0-0.1,0-0.1,0c0-0.2,0-0.3,0-0.4c0.5,0,0.2-0.5,0.1-0.7c0.2,0,0.3,0,0.5,0c-0.1-0.2-0.2-0.4-0.3-0.6
        c0-0.1,0.1-0.2,0.1-0.2c-0.2,0.3,0.2,0.3,0.4,0.4c0.1,0.1,0.2,0.3,0.3,0.4c0.1-0.2,0.1-0.3,0.1-0.5c-0.1,0-0.3,0-0.4,0
        c-0.1-0.2-0.1-0.3-0.2-0.5c0.1,0.1,0.3,0.2,0.5,0.2c0-0.2-0.2-0.3-0.4-0.4c-0.1-0.3,0.1-0.4,0.3-0.6c0-0.2,0-0.4-0.1-0.6
        c0.1,0.1,0.3,0.1,0.5,0.2c0.1-0.2,0.3-0.3,0.5-0.3c0,0,0-0.1,0-0.2c-0.1,0-0.4,0.1-0.5,0.2c-0.4-0.1-0.1-0.4,0.2-0.4
        c-0.1-0.3-0.2-0.4-0.4-0.4c0.1-0.1,0.1-0.2,0.2-0.3c0.2,0,0.3-0.1,0.3-0.3c0.1,0.1,0.3,0.1,0.4,0.2c-0.1-0.1-0.1-0.3-0.2-0.4
        c-0.2-0.3-0.3,0.3-0.5,0.1c0.2-0.2,0.5-0.5,0.6-0.8c-0.2-0.2,0.1-0.8,0.4-0.4c-0.1,0.1-0.2,0.2-0.2,0.2c0.1,0.1,0.3,0.2,0.4,0.3
        c-0.1,0.1-0.2,0.2-0.3,0.3c0.1-0.1,0.6-0.2,0.3-0.3c0.1-0.1,0.1-0.2,0.2-0.3c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1,0-0.3,0-0.4,0
        c0-0.1,0.1-0.4,0.1-0.5c0.1,0.1,0.2,0.2,0.3,0.3l0,0c-0.1-0.3,0-0.6,0-0.9l0.2,0c0-0.2-0.1-0.3-0.3-0.4c-0.1-0.2-0.3-0.5,0.1-0.6
        c0.1,0.2,0.2,0.5,0.3,0.7c0.3-0.2,0.2-0.6-0.2-0.6c0-0.3,0.2-0.5,0.5-0.6c0.1,0.1,0.3,0.2,0.4,0.3c0-0.1,0-0.3,0-0.4
        c-0.2,0-0.4,0-0.6,0c0-0.2-0.1-0.4-0.2-0.6c0.1,0.1,0.3,0.3,0.4,0.4c0.1-0.1,0.2-0.4,0.3-0.6c-0.1,0-0.3,0.1-0.4,0.1
        c0-0.3,0-0.6-0.3-0.7c0,0,0.1-0.1,0.1-0.1c0.2,0.2,0.3,0.7,0.6,0.4c-0.1-0.3-0.5-0.5-0.4-0.9c0.3,0,0.2,0.3,0.3,0.5
        c0.2-0.1,0.4,0,0.5,0.1c-0.2-0.2-0.4-0.4-0.4-0.7c0.1,0,0.4,0,0.5,0c-0.2-0.2-0.4-0.4-0.5-0.7c0.1-0.1,0.2-0.2,0.3-0.3
        c0,0.2-0.1,0.6,0.2,0.7c0.2-0.2,0.4-0.4,0.4-0.7c-0.2,0.1-0.3,0.2-0.4,0.4c-0.4-0.4,0.4-0.6,0.4-1l-0.3,0l0.1-0.3
        c0.4,0.1,0.6-0.2,0.8-0.5c-0.3,0-0.5,0.2-0.7,0.1c0.1-0.1,0.1-0.2,0.2-0.4c0.2,0,0.4-0.1,0.6-0.1c0,0,0.1,0.1,0.2,0.2
        c0-0.2-0.2-0.4-0.3-0.6c-0.2,0.1-0.4,0.1-0.5,0.3c-0.2-0.1-0.3-0.1-0.5-0.2c0.1,0,0.3,0,0.4,0.1l0-0.2c0.3-0.1,0.5-0.4,0.8-0.6
        c-0.1,0-0.4,0.1-0.5,0.1c0.1-0.2,0.2-0.2,0.4-0.2c0.1-0.3,0.1-0.7-0.1-1c0.1-0.1,0.3-0.2,0.4-0.3c-0.1,0.1-0.2,0.2-0.3,0.3
        c0.2,0.1,0.3,0.4,0.4,0.6c0.1,0,0.2,0.1,0.3,0.2c0,0,0.1-0.1,0.1-0.2c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.4,0-0.5c0.1-0.1,0.2-0.2,0.2-0.2
        c0.1-0.1,0.3-0.1,0.4-0.2c-0.1,0-0.2-0.1-0.3-0.2c0,0.1-0.1,0.3-0.2,0.3c-0.4-0.3,0-0.7,0.4-0.7c0-0.1,0.1-0.3,0.1-0.4
        c0.1,0,0.3,0,0.4,0c-0.1-0.3-0.3-0.2-0.5-0.2c0-0.5,0.6-0.2,0.5-0.7c0.5-0.2,0.1-0.8,0.2-1.1l-0.2,0l0,0.2c0,0-0.2,0-0.2,0
        c0.1-0.1,0.2-0.2,0.2-0.3c0.2,0,0.4-0.1,0.4-0.4c-0.1,0-0.3,0-0.4,0c0,0,0-0.1,0-0.1l0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
        c0.1,0,0.3,0,0.4,0c0.1,0.3,0.3,0.7-0.1,0.9c0,0.1,0.1,0.2,0.1,0.3l-0.2,0.1c0.1,0.1,0.3,0.2,0.5,0.1c-0.2-0.3-0.1-0.6,0.1-0.8
        c0.1-0.1,0.1-0.3,0.2-0.4c-0.1,0-0.2,0-0.3,0c-0.1-0.4-0.1-0.8-0.1-1.2c-0.1,0.1-0.3,0.2-0.4,0.2c0.1-0.1,0.3-0.2,0.4-0.3
        c-0.1,0-0.2-0.1-0.3-0.2c0.1,0,0.2-0.1,0.2-0.2c0.1,0.1,0.2,0.3,0.2,0.4c0.2,0,0.5,0.2,0.7,0c-0.1,0-0.3-0.1-0.4-0.1
        c0-0.3-0.2-0.9,0.2-1c-0.1,0.1-0.2,0.4-0.2,0.5c0.2,0,0.5-0.2,0.5,0.2c-0.1,0-0.3,0-0.4,0l0.1,0.4c0.1-0.1,0.3-0.3,0.4-0.3
        c0,0.2,0,0.4-0.1,0.6c0.2-0.2,0.2-0.4,0.1-0.6l0.2,0c-0.1-0.4-0.1-0.9,0.3-1c0.2-0.3,0.2-0.6,0.3-0.9c-0.3,0-0.3-0.3-0.3-0.5
        c-0.5,0,0,0.7-0.5,0.9c0.1,0.1,0.2,0.2,0.3,0.2c-0.2,0.1-0.8,0.2-0.5,0.5c-0.1,0-0.3,0.1-0.4,0.2c0-0.1,0.1-0.2,0.2-0.3
        c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.3-0.2,0.4-0.3c-0.1-0.1-0.1-0.2-0.2-0.3c0.2-0.2,0.3-0.4,0.4-0.7c0.2,0,0.4-0.1,0.4-0.4
        c0-0.2,0.3,0,0.3,0.2c-0.2,0.2-0.4,0.3-0.4,0.5l0.2,0c0.1-0.1,0.2-0.2,0.2-0.3c0,0.1,0,0.3,0,0.4c0.1-0.1,0.1-0.1,0.2-0.2l-0.2-0.1
        c0.1-0.4,0.3-0.7,0.5-1.1c0.1,0,0.3,0,0.4,0c0,0,0-0.1,0-0.1l-0.3,0c0.1-0.3,0.3-0.4,0.5-0.5c-0.1-0.2-0.1-0.4-0.2-0.6
        c0-0.2,0-0.5,0-0.7c0.1-0.1,0.2-0.2,0.2-0.3c0,0.1-0.1,0.4-0.1,0.6c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.2,0,0.4,0.1
        c0-0.1-0.2-0.3-0.2-0.4c0.1-0.2,0.1-0.4,0-0.5c0.4-0.1,0.5-0.5,0.4-0.8c0,0.1,0.1,0.2,0.2,0.3c-0.1,0.2-0.1,0.5-0.3,0.6
        c0,0.2-0.2,0.5,0.1,0.6c0-0.3,0.2-0.4,0.2-0.7c0-0.2,0.1-0.3,0.3-0.4c-0.1-0.3-0.2-0.5-0.4-0.7c0.3,0,0.5-0.2,0.8-0.1
        c0-0.3,0.1-0.4,0.4-0.3c0-0.3,0.1-0.5,0.4-0.6c-0.1-0.1-0.1-0.2-0.2-0.4c0.3-0.2,0.4,0.2,0.4,0.4c0.1,0,0.2,0,0.3,0
        c-0.3-0.2-0.4-0.5-0.3-0.9c-0.2,0.1-0.3,0.2-0.4,0.3c0-0.1,0-0.2,0-0.3l0.2-0.1c0-0.3,0.2-0.6,0.6-0.7c0,0.1,0,0.2,0.1,0.3
        c-0.3,0-0.5,0.1-0.5,0.5c0.3-0.1,0.7-0.1,0.7-0.5c0.2-0.1,0.2-0.2,0.2-0.4l0.2,0.1c0-0.1,0-0.4,0-0.5c0.1,0,0.3,0,0.4,0
        c-0.1-0.2-0.2-0.3-0.4-0.4c0.1-0.1,0.2-0.2,0.4-0.1c0-0.1,0.1-0.3,0.2-0.4c-0.2,0-0.3,0-0.5,0c0.1-0.1,0.2-0.3,0.1-0.5
        c0.1,0.2,0.2,0.3,0.4,0.5c0-0.2,0-0.5,0-0.7c0.1,0,0.2,0.1,0.3,0.1c0-0.1-0.1-0.4-0.1-0.6c0.1,0,0.2,0.1,0.2,0.2
        c0.1-0.2,0.4-0.5,0.1-0.7c0.1,0,0.2,0,0.2,0c0.3-0.6,0.5-1.2,0.9-1.8c0.2-0.6,0.7-1.1,0.8-1.7c0.3-0.5,0.5-1,0.8-1.5
        c0.2-0.4,0.5-0.7,0.6-1.1c0.2-0.3,0.4-0.6,0.7-0.8c0.2-1,1.1-1.6,1.5-2.5c0.2-0.5,0.7-0.6,0.7-1.1c0-0.1-0.1-0.2-0.2-0.2
        c0.2,0,0.4,0,0.6,0c-0.1-0.2-0.1-0.4-0.2-0.5c-0.1,0-0.4,0.1-0.5,0.1c0.2-0.1,0.3-0.5,0.6-0.4c0,0.1,0.1,0.2,0.1,0.3
        c0.2-0.3,0.2-0.7-0.2-0.6c0-0.2-0.1-0.3-0.3-0.4c0.1,0.2,0.1,0.3,0.2,0.5c-0.1,0.1-0.3,0.1-0.5,0c0.2,0.3-0.2,0.4-0.4,0.6l0,0.1
        l-0.2,0c0,0.1,0,0.2,0,0.3c-0.1,0-0.2,0-0.3,0c0,0.1-0.1,0.3-0.1,0.4c-0.1,0-0.2,0-0.2,0c-0.3,0.5-0.5,1.1-1,1.5
        c0.2-0.4,0.3-0.9,0.4-1.3c0.4-0.9,0.2-2,0.8-2.8c-0.1-0.3-0.1-0.5,0.2-0.7c0.2-0.6,0.6-1,0.8-1.6c0.2,0,0.3-0.2,0.4-0.4
        c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0-0.3,0-0.4c-0.1,0.1-0.2,0.3-0.3,0.4c0-0.2-0.1-0.6-0.1-0.8l0.5,0l-0.2,0.2c0.3,0,0.4,0.2,0.3,0.5
        c0.2-0.2,0.3-0.4,0.5-0.6c-0.3,0-0.6,0.1-0.8-0.2c0.1,0,0.3-0.1,0.4-0.1c0,0,0-0.1,0-0.1l-0.3,0.1c0-0.3,0.3-0.7-0.1-0.8l0.1,0.3
        l-0.3,0c0.1,0.1,0.2,0.3,0.3,0.4l-0.2,0c0,0.1,0,0.2,0,0.3l-0.3,0c0-0.1,0-0.3,0-0.4c-0.1,0.1-0.2,0.2-0.2,0.3l-0.1,0
        c0,0.2,0,0.4,0,0.6l-0.2-0.1c0.3,0.5-0.6,0.5-0.4,1.1c-0.1-0.1-0.3-0.2-0.4-0.3c0,0.2,0,0.4-0.3,0.4c0.1,0,0.4,0,0.5,0
        c-0.1,0.1-0.2,0.3-0.3,0.4l-0.1,0l0,0.2l-0.2-0.1c0,0.3,0.1,0.6-0.3,0.6c0,0.1,0.1,0.2,0.1,0.3l-0.2,0c0,0.1,0,0.2,0,0.3
        c-0.1,0-0.2-0.1-0.3-0.1c0,0.7-0.7,1.1-1.2,1.6c-0.3,0.3-0.8,0.4-0.9,0.8c-0.1,0-0.2,0-0.3,0c0,0.1,0.1,0.3,0.1,0.4
        c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1,0.2-0.1,0.4-0.2,0.6c-0.2-0.3-0.3,0-0.5,0.1c0.1,0.1,0.2,0.2,0.2,0.3c-0.1,0.1-0.3,0.2-0.4,0.2
        c0,0,0.1,0.1,0.2,0.1c-0.3,0.3-0.5,0.5-0.7,0.8c0.1,0,0.4-0.1,0.6-0.2c-0.1,0.4-0.5,0.1-0.7,0.2c0.1,0.2,0.2,0.3,0.3,0.5
        c-0.2,0.1-0.3,0.2-0.4,0.4c0-0.3,0.1-0.6,0-0.8c-0.1,0.1-0.2,0.2-0.2,0.3l0.3,0c-0.1,0.3-0.3,0.5-0.6,0.8c0.1,0,0.4,0,0.5,0
        c0,0.1,0,0.3,0,0.4c-0.1-0.1-0.3-0.2-0.4-0.3c0,0.1,0.1,0.4,0.1,0.6c-0.2,0.2-0.5,0.4-0.5,0.7l-0.1,0c0-0.2-0.1-0.3-0.1-0.5
        c0.4,0,0.6-0.5,0.5-0.8c-0.1,0.1-0.3,0.2-0.4,0.4c0.1,0.2-0.1,0.4-0.3,0.5c-0.1,0.2,0.2,0.4,0.2,0.6c-0.1,0-0.3,0-0.4,0
        c0,0.1,0,0.2,0,0.3c0.2-0.1,0.4-0.1,0.5-0.2c0,0,0,0.1,0,0.2c-0.4,0-0.8,0.3-1.2,0.4c0.2,0,0.4,0.1,0.6,0.2c0.1-0.1,0.3-0.3,0.5-0.4
        c0,0.4-0.4,0.4-0.5,0.6c-0.2,0.2,0,0.7-0.4,0.5c0.1-0.1,0.2-0.3,0.3-0.4c0-0.1-0.1-0.2-0.2-0.3c-0.1,0.1-0.3,0.1-0.4,0.2
        c0.1,0.1,0.2,0.3,0.2,0.4c-0.2-0.1-0.4-0.1-0.6-0.2c-0.1,0-0.1,0.1-0.2,0.2c0.1,0,0.3,0,0.5,0c0,0.2-0.1,0.4-0.1,0.7
        c-0.3,0.1-0.3,0.3-0.2,0.6c-0.1,0-0.2,0-0.3,0l0.1-0.3l-0.2,0l0,0.3c-0.4,0-0.7,0.5-1,0.7c0.1-0.1,0.3-0.1,0.4-0.2
        c0.2,0.4-0.3,0.6-0.6,0.6c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.4,0.3c0.1,0.1,0.2,0.1,0.3,0.2c0-0.3,0.1-0.5,0.3-0.7
        c0.1,0.1,0.1,0.2,0.2,0.3l-0.1,0.1l0.1,0.1c0,0.2-0.6,0.3-0.2,0.5c0.1,0,0.3,0,0.4,0c-0.3,0.3-0.9-0.1-0.8,0.4
        c-0.2-0.1-0.3-0.2-0.4-0.3c0,0.1,0,0.4,0,0.5c-0.1,0-0.3-0.1-0.5-0.1c0,0.1,0.1,0.2,0.1,0.2c0.3-0.1,0.7-0.1,1-0.3
        c-0.2,0.2-0.4,0.4-0.6,0.7c0.2,0,0.4-0.1,0.6-0.1c-0.2,0.1-0.4,0.2-0.5,0.5c-0.2-0.2-0.2-0.4-0.2-0.7l-0.3,0c0,0.1,0.1,0.3,0.1,0.4
        L270,403l-0.2,0c0-0.1,0-0.3,0-0.4c-0.1,0.2-0.3,0.3,0,0.5c0,0.3,0.3,0.8-0.2,0.7c0-0.1,0-0.2,0-0.3c-0.3,0,0-0.5-0.4-0.3
        c0.3,0.3,0.4,0.7,0.4,1.1c0.1,0,0.2,0.1,0.3,0.1c-0.2,0.3-0.5,0.5-0.8,0.8c0-0.3,0.2-0.5,0.5-0.6c-0.1-0.1-0.3-0.1-0.4-0.2
        c0.1-0.1,0.2-0.2,0.2-0.2c-0.2,0-0.4,0.1-0.6,0.1l0.3,0c0,0.3-0.1,0.5-0.5,0.4c0,0,0,0.1,0,0.1c0.1,0,0.2,0,0.3,0l0.1,0.4
        c-0.3-0.1-0.6,0-0.9,0l0.1,0.3c0.2-0.1,0.4-0.2,0.6-0.3c-0.1,0.2-0.3,0.5-0.4,0.7l0.4-0.1c0,0,0,0.1-0.1,0.2c-0.3,0-0.5,0-0.8-0.1
        c0.1,0.1,0.3,0.4,0.4,0.5l-0.3,0c0-0.1,0-0.2-0.1-0.2l0,0.2c-0.1,0-0.3,0-0.4-0.1c0,0.1,0,0.2,0,0.2c0.1,0,0.3-0.1,0.4-0.1
        c-0.2,0.3,0.2,0.6-0.1,0.9c0.1,0.1,0.2,0.2,0.3,0.4c-0.1-0.1-0.2-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.5,0.3c0,0.3,0.1,0.5,0.1,0.8
        c-0.2-0.3-0.3-0.7-0.6-0.8c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0.1-0.2,0.2-0.3,0.2c0.1,0,0.2,0,0.3,0c-0.1,0.3,0.1,0.6-0.2,0.8
        c-0.2,0-0.4,0-0.6-0.1c0,0,0-0.1,0-0.1c-0.1,0.2-0.3,0.4-0.4,0.6c0.1,0,0.3-0.1,0.4-0.1l-0.1-0.3c0.4-0.1,0.3,0.3,0.4,0.5
        c-0.4,0.2-0.8,0.6-0.6,1.1c-0.1,0-0.4,0-0.5,0c0.1,0,0.2,0.1,0.3,0.1l0,0.2c-0.7,0,0,1-0.7,0.9c0-0.1,0.1-0.3,0.1-0.5l-0.2,0
        c0.1-0.1,0.2-0.2,0.3-0.3c-0.2,0.1-0.3,0.2-0.4,0.4c0.1,0,0.3,0,0.4,0l0,0.2c-0.2,0-0.4,0.1-0.5,0.2c0.1,0.1,0.2,0.2,0.3,0.3l0.3,0
        c-0.1,0.1-0.2,0.2-0.2,0.2l-0.2,0c0,0.1,0.1,0.3,0.1,0.4c-0.1,0.1-0.4,0.1-0.4,0.3c0.1,0.3-0.4,0.7-0.6,0.3c0,0.1-0.1,0.3-0.1,0.4
        c0.1,0.1,0.1,0.2,0.2,0.4c0-0.3,0.2-0.6,0.6-0.5c-0.1,0.2-0.2,0.4-0.5,0.4c-0.1,0.2-0.2,0.4-0.2,0.7c-0.1-0.1-0.2-0.2-0.2-0.2
        c0,0.2,0.1,0.3,0.2,0.5c-0.1,0-0.3-0.1-0.4-0.1c0.3,0.3,0,0.6-0.2,0.8c0.1,0,0.4,0,0.5,0c-0.1,0.1-0.3,0.3-0.4,0.3
        c0.1,0.1,0.2,0.2,0.3,0.4c-0.4,0.2-0.4-0.7-0.8-0.3c0.2,0.1,0.3,0.3,0.4,0.6c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1-0.2-0.1-0.4,0.1-0.6
        c-0.2,0.1-0.3,0.3-0.4,0.4c0.3,0.1,0.2,0.4,0.2,0.6c-0.2,0.1-0.3,0.4-0.3,0.6l0.3,0c-0.1,0-0.2,0.1-0.3,0.1
        c-0.1,0.2-0.1,0.4-0.3,0.5c0,0.1,0,0.2,0,0.3c-0.1,0-0.3,0-0.4,0c0.1,0.2,0.2,0.4,0.2,0.6l-0.2-0.1c0,0.1,0,0.3,0,0.4
        c-0.3,0.2-0.1,0.6-0.4,0.9c-0.2,0.2-0.1,0.4-0.1,0.7c-0.1-0.1-0.2-0.2-0.3-0.3c0.1,0.1,0.1,0.2,0.2,0.4c-0.2,0.9-0.4,1.8-0.6,2.7
        c-0.2,0.7-0.3,1.4-0.5,2c-0.1,0.3,0,0.7-0.1,1c0-0.4,0-0.7,0-1.1c0.2-0.6,0.3-1.3,0.3-2c0.3-1.3,0.4-2.7,0.6-4.1
        c-0.1-0.3,0.2-0.2,0.4-0.2c-0.1,0-0.2-0.1-0.3-0.2c0.1-0.3,0.1-0.5,0.1-0.8c-0.2,0-0.3,0.2-0.3,0.4c0-0.3,0-0.6,0.1-0.9
        c0.1-0.2,0.1-0.4,0.2-0.5c0.1-1.8,0.4-3.6,0.5-5.4c-0.2-0.7,0.3-1.4,0.2-2.1c-0.1-0.7,0.2-1.3,0.2-2c0.2-0.8,0.3-1.6,0.4-2.4
        c0.1-0.6,0.3-1.3,0.4-1.9c0.1-0.4-0.1-0.9,0.1-1.3c-0.2-0.6-0.2-1.3,0.1-1.8c-0.1-0.4,0.1-0.7-0.1-1.1c-0.1-0.2,0.1-0.5,0.2-0.7
        c-0.1-0.7,0.2-1.3,0-1.9c0.1-0.5,0-1,0.4-1.3c-0.3-0.6,0-1.2,0.2-1.8c0-0.4-0.1-0.8,0.1-1.1c-0.1-0.3-0.1-0.6,0.1-0.8
        c-0.3-0.9-0.1-1.9-0.1-2.9c0.1-0.3,0.2-0.5,0.3-0.8c0-0.4-0.1-0.8-0.2-1.2c0-0.5,0.2-1,0-1.4c0-0.6,0.2-1.3-0.3-1.9
        c0.1-0.2,0.1-0.4,0.2-0.6c-0.1,0.1-0.3,0.2-0.4,0.2c0-0.1,0-0.4,0-0.5c0.3,0.1,0.4-0.1,0.6-0.3c-0.1-0.1-0.2-0.2-0.3-0.4l0,0.2
        c-0.1-0.1-0.2-0.2-0.2-0.3l-0.1,0.1c-0.1,0.1-0.2,0.3-0.3,0.4c0.1,0,0.2-0.1,0.2-0.2c0.2-0.1,0.3,0.1,0.5,0.2
        c-0.1,0-0.3,0.1-0.4,0.1c0,0.2-0.2,0.4,0,0.6c0,0.2-0.1,0.4-0.1,0.6l-0.3,0c0.1,0.3,0.4,0.1,0.6,0.1c-0.2,0.1-0.3,0.3-0.3,0.6
        c-0.1-0.2-0.2-0.4-0.4-0.5c-0.1,0.3,0.1,0.6,0.3,0.9l-0.3-0.2c0,0.1,0,0.3,0,0.4c-0.1-0.1-0.3-0.2-0.3-0.4l-0.1,0
        c0.1,0.2,0.2,0.4,0.3,0.6l-0.2,0c0.1,0.1,0.2,0.3,0.3,0.4l-0.2,0c0,0.1,0.1,0.3,0.1,0.4l-0.1,0c0-0.1,0-0.2,0-0.3l-0.1,0
        c0-0.2,0-0.4,0-0.5c-0.1,0.2-0.2,0.5-0.1,0.8c-0.1,0-0.2,0-0.2,0c0,0.3-0.1,0.5,0,0.8c-0.8-1-0.1,0.1-0.4,0.4
        c-0.2,0.1-0.3,0.3-0.2,0.5c-0.2,0.1-0.4,0.2-0.3,0.5l-0.1,0c0,0.1,0,0.3,0,0.4c-0.2,0.2-0.3,0.3-0.4,0.5c0,0.2,0.2,0.4,0.1,0.6
        c-0.5,0.7-0.6,1.5-1,2.2c0.2,0.7-0.4,1.3-0.5,2c-0.1,0.6-0.7,1.1-0.8,1.7c-0.1,0.4-0.2,0.9-0.7,1c0,0.1,0,0.2,0,0.3
        c-0.3-0.2-0.1-0.6-0.1-0.9c-0.1-0.1-0.2-0.3-0.2-0.4c0.1,0.1,0.2,0.2,0.3,0.3c0-0.2-0.1-0.4-0.1-0.6c0,0-0.1,0-0.1,0
        c0-0.1,0-0.3,0-0.4c0.1,0.1,0.2,0.2,0.3,0.3l0.1-0.4c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0-0.2,0-0.3c0.1,0.2,0.2,0.4,0.4,0.4
        c0-0.2-0.1-0.5-0.1-0.6c-0.1,0-0.2,0-0.3,0c0.1-0.1,0.3-0.2,0.4-0.3c-0.1-0.2-0.1-0.5-0.3-0.6c0,0.2,0,0.4,0,0.7
        c-0.1-0.2-0.2-0.8,0.2-0.7c0-0.3-0.1-0.6-0.3-0.9c0.2,0,0.4,0.1,0.6,0.2l0,0.2c0.1-0.1,0.1-0.1,0.2-0.2l-0.4-0.1
        c0.1-0.2,0.5-0.5,0.2-0.6c0.1-0.1,0.2-0.2,0.2-0.3c-0.1,0.1-0.2,0.2-0.3,0.3c0-0.2-0.1-0.5-0.1-0.6c0.1,0,0.2-0.1,0.2-0.2
        c-0.1,0-0.3,0-0.3,0l0.1-0.3c-0.4,0.1-0.1,0.5,0,0.8c0.1,0.2,0.1,0.4-0.1,0.6l-0.1-0.3c0,0.1-0.1,0.2-0.2,0.2c0.1-0.3,0.1-0.6,0-0.9
        c0.1-0.2,0.2-0.4,0.2-0.6l0.2,0.1c-0.1-0.2-0.1-0.5,0-0.7c0.5,0.2,0.8-0.6,0.5-1c0,0.3,0.1,0.6-0.1,0.8l-0.1-0.1
        c-0.2,0.1-0.4,0.2-0.6,0.3c0.2,0.2,0.1,0.4,0,0.6c-0.1-0.2-0.2-0.5-0.3-0.7c0.1-0.1,0.3-0.2,0.3-0.3c0-0.2,0-0.3,0-0.5
        c-0.3,0-0.4-0.1-0.5-0.3c0.2-0.2,0.3-0.4,0.5-0.5c-0.1-0.3-0.2-0.5-0.4-0.6c0.1,0,0.3-0.1,0.4-0.2l-0.2-0.1l-0.3,0.1
        c-0.1-0.4,0.2-0.5,0.6-0.5c0,0-0.1-0.1-0.2-0.1c0.1-0.1,0.2-0.4,0.2-0.5c-0.1,0.1-0.2,0.2-0.3,0.4c-0.1-0.1-0.2-0.2-0.4-0.2
        c0,0,0-0.1,0-0.1c0.1,0,0.3,0,0.4,0.1c-0.1-0.2-0.2-0.3-0.3-0.5c0.3-0.2,0.7-0.4,0.6-0.8c0.2,0,0.4,0,0.6,0c0,0-0.1-0.1-0.1-0.2
        c-0.4,0-0.6,0.4-1,0.3c0-0.1,0.1-0.2,0.1-0.3c0.4,0,0.4-0.4,0.4-0.6c-0.2,0.1-0.4,0.3-0.4,0.5c-0.1,0-0.2,0-0.3,0
        c0.1-0.3,0.3-0.6,0.4-0.9c0.1,0,0.3,0,0.3,0c0.1-0.2,0.6-0.5,0.3-0.6c-0.1,0.5-0.7,0.5-1.1,0.7c0.1-0.2,0.3-0.4,0.4-0.5l-0.4,0
        c0-0.2,0-0.3,0-0.4c0.1-0.1,0.2-0.2,0.3-0.3c-0.1,0-0.3,0-0.4,0c0-0.2,0-0.5,0.2-0.5c0,0.1,0,0.3-0.1,0.4c0.1,0,0.2,0.1,0.3,0.1
        c0.2-0.4-0.1-0.7-0.5-0.8c0-0.1-0.1-0.3-0.1-0.4c0.2,0.2,0.5,0.3,0.7,0.5c-0.1-0.2-0.2-0.4-0.2-0.6c-0.2-0.1-0.3-0.1-0.5-0.2
        c0.3-0.2,0.6-0.3,0.8-0.6c-0.1,0-0.3,0.1-0.4,0.2c-0.2-0.2-0.4-0.3-0.5-0.6l0.1,0c0.1,0.1,0.4,0.2,0.5,0.3c-0.2-0.2-0.3-0.5-0.5-0.7
        c0-0.2,0-0.4-0.1-0.5c0.3,0.2,0.5,0.6,0.9,0.7c-0.2-0.2-0.4-0.4-0.6-0.7c-0.3-0.2-0.6-0.3-0.7-0.6c0.3,0.1,0.6,0.2,0.9,0.3
        c0-0.2,0-0.5,0.2-0.6c-0.3-0.2-0.5-0.4-0.8-0.6c0.1-0.1,0.3-0.2,0.3-0.3c-0.1-0.1-0.3-0.2-0.3-0.4l-0.2,0c0-0.1,0.1-0.2,0.2-0.3
        c-0.1,0-0.2,0.1-0.3,0.1c-0.3-0.2,0-0.7-0.5-0.8c0.2-0.1,0.4-0.3,0.6-0.2c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.2-0.1,0.3-0.1,0.5
        c0.3-0.2,0.4-0.5,0.5-0.8c-0.1-0.2-0.3-0.4-0.4-0.6c-0.1,0.2-0.4,0.3-0.6,0.4c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0-0.4,0-0.5
        c-0.1,0-0.3,0.1-0.4,0.2c0-0.2,0.4-0.8-0.1-0.7c0.1-0.1,0.2-0.2,0.3-0.3c0-0.2,0.1-0.3,0.3-0.4c0-0.3-0.3-0.5-0.3-0.8
        c0,0.1-0.2,0.2-0.2,0.3c0.3,0.1,0.5,0.2,0.4,0.6c-0.2,0-0.4-0.1-0.6-0.1c0-0.2,0.1-0.3,0.2-0.4c-0.1,0-0.2-0.1-0.2-0.1l0.1-0.3
        c-0.2,0-0.3,0-0.5,0c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0.1,0.3,0.2,0.5,0.2c-0.3-0.3-0.2-0.7-0.2-1.1c-0.1,0-0.2,0.1-0.3,0.1
        c0.1,0.1,0.1,0.3,0.2,0.4c-0.2,0.1-0.3,0.1-0.5,0.2c0-0.1,0.1-0.4,0.1-0.5c-0.1,0-0.3,0.1-0.4,0.1l0.1-0.1c0-0.1,0-0.3,0-0.3
        c-0.1,0-0.2,0.1-0.2,0.2c0-0.2,0-0.4,0.1-0.6l-0.2,0l0-0.1c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.3-0.1,0.4c0.1,0,0.2,0,0.2,0
        c0-0.1,0-0.2-0.1-0.3l0.2,0l0-0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1-0.3-0.4-0.4-0.4-0.7c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0,0-0.2,0.1-0.2
        c0-0.2,0.1-0.4,0.1-0.5c-0.1,0-0.2,0.1-0.3,0.1l0-0.3c0.1,0,0.2,0,0.2,0l0.1-0.3c0.2,0.1,0.3,0.2,0.5,0.3c-0.1-0.3-0.4-0.6-0.6-0.8
        c-0.2,0.2-0.2,0.5-0.5,0.5c0.1-0.3,0.2-0.6,0-0.8c0.1,0,0.2-0.1,0.3-0.2c-0.4-0.2-0.1-0.5,0-0.8c-0.2,0.1-0.3,0.2-0.5,0.4
        c0-0.1,0.1-0.3,0.1-0.4c-0.2-0.1-0.3-0.2-0.5-0.2c0.2,0,0.3,0,0.5,0c0.1,0,0.2,0,0.3,0.1c0,0-0.1-0.1-0.2-0.2l0-0.3
        c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1-0.1-0.8-0.4-0.1-0.4c-0.1-0.1-0.2-0.3-0.3-0.4c0.2-0.1,0.4,0.1,0.6,0.2c0-0.4-0.4-0.4-0.6-0.6
        c-0.1,0.1-0.2,0.2-0.3,0.3c0.1-0.3,0-0.7,0.2-1c-0.1-0.1-0.2-0.2-0.2-0.2c0.1,0,0.2-0.1,0.3-0.2c0-0.5-0.3-0.1-0.4,0.1
        c-0.2-0.2-0.2-0.5-0.2-0.7c0-0.1-0.1-0.3-0.1-0.4l0.2,0.1l0.1,0.2c0.1,0,0.2,0,0.2-0.1c-0.2-0.2-0.4-0.3-0.5-0.5
        c0.1-0.1,0.1-0.3,0.2-0.4c-0.1,0-0.2,0-0.2,0c0,0.2-0.1,0.3-0.1,0.5c-0.1,0.2-0.2,0.4-0.4,0.5l0.2-0.3c-0.1-0.1-0.3-0.3-0.4-0.4
        c0.2,0,0.3,0.1,0.4,0.3l0.2-0.2c-0.1-0.1-0.2-0.3-0.2-0.3c-0.1,0-0.2-0.1-0.3-0.1c1,0.2,0-0.3-0.2-0.5c0-0.2,0-0.4,0-0.6
        c-0.1-0.1-0.3-0.3-0.4-0.3l0.3,0c0,0,0-0.1,0-0.1l-0.2,0.1c0-0.2-0.1-0.4-0.1-0.5c-0.3-0.2-0.3-0.5-0.3-0.9
        c-0.2-0.1-0.7-0.4-0.1-0.6c-0.3-0.4-0.5,0.4-0.7,0.1l0.2,0c0-0.1,0-0.3,0-0.4l-0.1,0.1c0.2-0.3-0.3-0.6-0.3-0.9
        c0.3,0.1,0.5,0.6,0.8,0.4c-0.4-0.3-0.8-0.5-1.1-0.8c0-0.1,0.1-0.2,0.1-0.3c-0.1,0-0.3-0.1-0.5-0.1c0.1-0.1,0.2-0.3,0.3-0.4
        c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.3-0.1-0.4l-0.2-0.1c0,0.1,0,0.2,0,0.3c0.1-0.4-0.2-0.8-0.5-1c0.1-0.1,0.2-0.2,0.2-0.2
        c-0.1-0.2-0.4-0.3-0.6-0.4c0.1-0.1,0.2-0.1,0.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c0.1-0.3,0-0.4-0.3-0.3c0-0.3,0.2-0.6-0.2-0.5
        c-0.2,0.4,0.1,0.6,0.2,1l0.2-0.1l0.1-0.1c0,0.2-0.1,0.4-0.2,0.6c-0.2-0.3-0.2-0.6-0.4-0.8c0.1-0.3-0.2-0.5-0.3-0.8l0.1,0.2
        c0.1,0,0.2,0,0.3-0.1c-0.1-0.3-0.3-0.5-0.4-0.7c0.1-0.2,0.1-0.4-0.3-0.4c0-0.1,0-0.2,0-0.3c-0.4,0-0.3-0.4-0.2-0.7
        c-0.2-0.1-0.4-0.1-0.6-0.2c0.1-0.1,0.2-0.3,0.3-0.4c-0.2,0-0.4,0.1-0.5,0.2c0.1-0.1,0.2-0.3,0.3-0.4c-0.3,0.1-0.4-0.2-0.5-0.3
        c0-0.1,0-0.2,0.1-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.1,0-0.3,0.1-0.4c-0.2-0.2-0.6-0.3-0.5-0.6l-0.2,0.1c0-0.2,0-0.5-0.2-0.7
        c-0.3,0.3-0.1,0.7-0.1,1l-0.2-0.1c0.2-0.4-0.2-0.8,0-1.2c-0.3,0-0.2-0.5-0.5-0.6c-0.1,0-0.2,0.1-0.3,0.2c-0.1-0.3-0.2-0.6,0.1-0.8
        c-0.1,0-0.3,0-0.4,0c0-0.1,0.1-0.2,0.1-0.3c-0.3-0.1-0.6-0.2-0.5,0.2l0.2-0.1l0,0.3c-0.2-0.1-0.3-0.2-0.4-0.3
        c-0.2-0.1-0.3-0.2-0.4-0.4c0.1,0.2,0.6,0.3,0.5-0.1c0.2,0.1,0.3,0.1,0.5,0.2c-0.1-0.3-0.4-0.3-0.7-0.3c0-0.1,0.1-0.3,0.1-0.4
        c-0.4,0-0.6-0.2-0.9-0.4c0.1,0,0.3,0,0.4,0c-0.2-0.2-0.5-0.3-0.7-0.4c0-0.1,0.1-0.2,0.1-0.3c-0.2-0.1-0.4-0.2-0.6-0.3l0.3,0
        c0-0.1,0-0.4,0-0.6c-0.3,0-0.6,0-1,0.1l0-0.2l0.3,0c-0.1-0.2-0.2-0.4-0.4-0.4c0.1,0,0.3,0,0.3,0c0.1,0.2,0.2,0.5,0.5,0.4
        c-0.2-0.2-0.3-0.4-0.3-0.7c-0.2,0-0.3,0-0.5,0c0,0,0-0.1,0-0.1l0.3,0c-0.1-0.1-0.3-0.3-0.4-0.4c0,0,0-0.1,0-0.2
        c-0.2-0.1-0.3-0.2-0.5-0.3c-0.3-0.1-0.1-0.5-0.4-0.6c-0.2-0.3-0.4-0.5-0.5-0.8c0-0.3-0.3-0.3-0.5-0.2c0-0.1,0.1-0.3,0.2-0.4
        C238.3,341.3,238.1,341.2,238,341 M347.2,341.5c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2-0.1,0.3-0.2c-0.1,0-0.2-0.1-0.3-0.1
        c0,0,0.1-0.1,0.2-0.2l-0.2-0.2C347.4,341.1,347.3,341.3,347.2,341.5 M362.8,341.2C362.6,341.6,363.3,341.2,362.8,341.2 M320.8,341.6
        c-0.1,0.2,0.1,0.6,0.4,0.4C321.3,341.8,321,341.6,320.8,341.6 M362.1,341.6l0,0.1C362.3,342,362.3,341.3,362.1,341.6 M216.9,342.3
        c0.3,0,0.2-0.5,0-0.5C216.6,341.8,216.7,342.2,216.9,342.3 M239.2,341.9C238.9,342.3,239.6,341.9,239.2,341.9 M254.4,341.8l0,0.1
        c0.1,0,0.2,0,0.2,0l0-0.1C254.5,341.8,254.4,341.8,254.4,341.8 M254,342.1c-0.1,0.1-0.3,0.4-0.1,0.4
        C254.2,342.6,254.4,342,254,342.1 M319.2,342c0.1,0.3,0.2,0.6,0.1,1c0.2-0.1,0.2-0.3,0.2-0.5C319.4,342.4,319.5,341.9,319.2,342
        M270.9,342.3C270.7,342.7,271.4,342.3,270.9,342.3 M365.9,342.4C366,342.4,366,342.4,365.9,342.4 M208.9,342.5
        C208.7,342.9,209.4,342.5,208.9,342.5 M214.6,342.5c-0.2,0.1-0.2,0.2,0,0.3C214.8,342.6,214.8,342.5,214.6,342.5 M240.8,342.5
        c-0.1,0.2,0,0.3,0.2,0.2C241,342.5,240.9,342.4,240.8,342.5 M308.4,342.4c0,0.2,0,0.4,0.1,0.7c-0.1,0-0.2,0-0.3,0
        c0.1,0.1,0.2,0.4,0.3,0.6l-0.4-0.2c0,0.1,0.1,0.2,0.1,0.3l-0.2,0c0,0.1,0,0.2,0,0.3l0,0.1c0,0.1-0.1,0.2-0.2,0.2
        c-0.2,0.8-0.4,1.6-0.6,2.3c-0.4,0.7-0.3,1.6-0.8,2.3c-0.1,0.3,0.1,0.8-0.4,0.8c0.1,0.2,0.3,0.4,0.2,0.7c-0.3-0.1-0.3-0.4-0.2-0.6
        c-0.4,0.1-0.2,0.5-0.2,0.8l-0.2,0c0,0,0,0.1,0,0.1c0.1,0,0.3-0.1,0.4-0.2c0,0.1-0.1,0.4-0.1,0.6c0.3-0.1,0.2-0.4,0.2-0.7
        c0.2,0.2,0.1,0.4,0.2,0.6c-0.1,0.1-0.2,0.2-0.3,0.3c0,0-0.1-0.1-0.2-0.1c0,0.1,0,0.3,0,0.4c-0.2,0-0.6,0-0.6,0.3
        c0.4,0.1,0.7-0.3,1.1-0.4c0,0.1,0.1,0.2,0.1,0.3c-0.1,0-0.2,0-0.2,0c0,0.3-0.3,0.3-0.5,0.5c0.2,0.3,0,0.7,0.1,1c0,0,0.1,0,0.1,0
        c0.1-0.3,0.2-0.7-0.1-0.9c0.2-0.1,0.4-0.4,0.6-0.1c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0,0.3-0.1,0.4l0.2,0l0,0.2l-0.2,0
        c0,0.3,0,0.6-0.1,0.9c0,0.2,0.1,0.5-0.1,0.7c0-0.1-0.1-0.3-0.2-0.4c-0.1,0.1-0.4,0.4,0,0.4c-0.1,0.1-0.2,0.3-0.2,0.3
        c-0.2,0-0.5,0.1-0.6,0.2c0.6,0,0.2,0.7,0.5,1c-0.1,0.3-0.4,0.3-0.5,0.5l0.3,0c-0.1,0.2-0.1,0.5,0,0.7c-0.1,0-0.2,0.1-0.3,0.1
        c0.1,0,0.3,0,0.4,0c0,0.2,0.2,0.4,0.3,0.6c-0.2,0.1-0.4,0.2-0.5,0.3c0,0.1-0.1,0.3-0.1,0.4c0.1,0,0.2,0.1,0.2,0.2
        c-0.2,0.2-0.4,0.6,0,0.6c0,0.1,0.1,0.3,0.1,0.4H305c-0.1-0.3-0.3-0.4-0.5-0.6c0,0.1,0,0.2,0,0.3c0.1,0.2,0.2,0.4,0.3,0.6l-0.3,0
        c0.1,0.2,0.4,0.5,0,0.6c0-0.2-0.1-0.3-0.2-0.4c-0.1,0-0.3,0.1-0.4,0.1c0.1,0.1,0.3,0.1,0.4,0.2c0,0.4-0.3,0.8,0.1,1.1
        c-0.2,0.2-0.3,0-0.5,0c0-0.3,0.1-0.5,0.1-0.8c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0-0.2,0-0.3,0c-0.1,0.2-0.1,0.3-0.2,0.5
        c0.1-0.1,0.2-0.3,0.4-0.3c0.4,0.1,0.3,0.5,0,0.5c0.1,0.1,0.2,0.2,0.2,0.3c0.4,0,0.2-0.4,0.4-0.5c0,0.1,0,0.4,0,0.5c0,0,0.1,0,0.2,0
        c-0.1,0-0.2,0-0.3,0c0,0.2-0.1,0.2-0.2,0c-0.1,0.2-0.4,0.4-0.4,0.7c0.2-0.1,0.4-0.2,0.6-0.3c0,0.1,0,0.2,0,0.3
        c-0.3,0.1-0.7,0.1-0.8,0.4c0.2,0,0.5,0,0.6,0.3l-0.3,0c0,0.1,0,0.3-0.1,0.3c-0.1-0.1,0-0.3-0.2-0.3c-0.1,0.1-0.1,0.3-0.1,0.5
        c0,0-0.1,0.1-0.2,0.1c0,0.1,0,0.4,0,0.6l0.2,0c0,0.2,0,0.4,0,0.6c-0.2,0.1-0.5,0.1-0.7,0.2c0.1,0.1,0.3,0.2,0.4,0.4
        c0,0-0.1,0.1-0.1,0.2c-0.1-0.2-0.3-0.4-0.5-0.6c0,0.1,0,0.3,0.1,0.4c0,0.2,0.1,0.3,0.2,0.5c0.1,0.1,0.2,0.2,0.3,0.3
        c-0.1-0.1-0.3-0.2-0.4-0.3l0.1,0.5c-0.3-0.1-0.2-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1,0-0.3,0.1-0.5,0.2c0,0,0.1,0.1,0.1,0.2
        c0.2-0.1,0.4-0.1,0.6,0.1c-0.3,0.1-0.5,0.2-0.6,0.5c0.1,0,0.3-0.1,0.4-0.1l0,0.2c-0.1,0-0.2-0.1-0.3-0.1c0.4,0.5-0.6,0.2-0.6,0.7
        c0.3,0.2,0.6,0,0.9,0c0-0.1,0-0.2,0-0.3l0.1,0c0,0.1,0,0.3,0,0.4c-0.3,0-0.6,0.1-0.9,0.1c-0.1-0.2-0.2-0.3-0.3-0.3
        c0.1,0.1,0.2,0.3,0.2,0.5c-0.2,0-0.5,0-0.6,0.3c0,0.1,0.1,0.2,0.2,0.3c0.1-0.2,0.1-0.5,0.4-0.4c0,0.1,0,0.4,0.1,0.5
        c0.1,0,0.2,0,0.3,0c0-0.1,0-0.4-0.1-0.5c0.1,0.1,0.2,0.3,0.4,0.4c-0.3,0.1-0.5,0.1-0.7,0.4c-0.1,0-0.3,0-0.4,0
        c0.1,0.2,0.2,0.3,0.2,0.5c-0.3,0.2-0.7,0.3-1,0.2c-0.1-0.4,0.3-0.4,0.6-0.4c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2,0.2-0.4,0.3-0.5,0.6
        c-0.1,0.3,0.2,0.4,0.4,0.5c0.1-0.2,0.3-0.1,0.4,0c0.1-0.1,0.2-0.4,0.4-0.3c0,0.1-0.1,0.3-0.1,0.4c-0.2,0.1-0.5,0-0.6,0.3l-0.2,0
        c-0.1,0.1-0.3,0.3-0.3,0.5c0.1,0,0.3,0,0.4,0l0,0.3c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0.2-0.3,0.4-0.2,0.6c0.1,0,0.2-0.1,0.3-0.1
        c0.2,0,0.4,0,0.7,0c-0.2,0.1-0.4,0.1-0.5,0.1l0,0.2c-0.2-0.1-0.4,0.1-0.5,0.3c-0.1-0.2-0.1-0.3-0.3-0.5c0.1,0.2,0.1,0.3,0.2,0.5
        c-0.3,0.3-0.5,0.6-0.7,1c0.2,0,0.6-0.6,0.7-0.1c-0.2,0.1-0.3,0.1-0.5,0.2c0,0.1-0.1,0.2-0.1,0.3c0.1,0.1,0.1,0.3,0.2,0.4
        c-0.1,0.1-0.3,0.1-0.4,0.2c0.1-0.3,0.2-0.6,0.3-0.8c-0.2,0.1-0.4,0.2-0.5,0.3c0,0.3,0.1,0.5,0.1,0.8c0.1,0,0.3-0.1,0.4-0.1
        c-0.1,0.1-0.2,0.1-0.3,0.2c0,0.1,0,0.2,0,0.3c-0.1-0.3-0.2-0.7-0.5-0.8c0,0.4-0.3,0.7-0.5,1c0.2,0.2,0.3,0.5,0,0.6
        c0-0.1,0-0.3,0-0.4c-0.1,0-0.3,0.1-0.4,0.2c0-0.1,0-0.2,0-0.2c0.2-0.1,0.3-0.3,0.2-0.5c-0.2,0.2-0.4,0.5-0.4,0.9c0.1,0,0.3,0,0.5,0
        c-0.1,0.2-0.2,0.4-0.3,0.5c0,0.2-0.2,0.3-0.3,0.3c-0.1,0.2-0.2,0.5-0.3,0.7l-0.1,0l-0.1-0.3c-0.1,0.2-0.2,0.4-0.2,0.6l-0.1-0.1
        c0,0-0.1-0.1-0.2-0.1c0,0.1,0,0.2,0,0.2l0,0.1l0,0.2c-0.1,0.2-0.1,0.4-0.3,0.5c-0.1,0.2-0.5,0.3-0.4,0.6c0.1,0,0.2-0.1,0.4-0.1
        c0,0,0,0.1,0,0.1c-0.1,0-0.3,0-0.4,0c0,0.1,0,0.3-0.1,0.4c-0.2,0.1-0.3,0.2-0.4,0.3c0,0-0.1-0.1-0.2-0.2c-0.2,0.3-0.4,0.5-0.4,0.8
        c-0.1,0.2-0.2-0.1-0.2-0.1c-0.3,0.2-0.6,0.4-0.7,0.7c-0.1,0-0.4,0.1-0.5,0.1c0.1,0.1,0.4,0.2,0.5,0.3c-0.5-0.2-0.9,0.2-1.1,0.5
        c0,0.2,0.1,0.4,0.1,0.6c-0.1-0.1-0.3-0.2-0.4-0.3c0,0.1,0.1,0.3,0.1,0.4c-0.3,0.1-0.7,0.3-0.9,0.5c0.1,0,0.3,0.1,0.5,0.1
        c-0.2,0.1-0.3,0.2-0.4,0.4c-0.2,0.5-0.9,0.7-0.8,1.3c-0.2,0.1-0.2,0.3-0.3,0.5l-0.2,0l0-0.3c-0.5,0.1,0.2,0.4-0.1,0.6
        c-0.3,0.3-0.4,0.7-0.8,1l0.1,0.2c-0.1,0-0.2,0-0.3,0l0.1,0.2c-0.5-0.3-0.7,0.4-0.4,0.7c-0.1,0.2-0.2,0.6,0.2,0.7
        c-0.2-0.3,0-0.6,0.2-0.8c-0.1-0.1-0.2-0.2-0.3-0.2l0.3,0l0-0.3c0.1,0.1,0.2,0.3,0.2,0.3c0.2-0.2,0.3-0.4,0.4-0.6
        c0.1-0.1,0.3-0.2,0.4-0.3c-0.4-0.2,0-0.5,0.2-0.8c0,0.2-0.1,0.4-0.2,0.6c0.1,0,0.2,0,0.3,0l-0.1,0.3c0.1-0.1,0.2-0.2,0.3-0.3
        c0-0.4,0.6-0.7,0.5-1.2c0,0,0.1-0.1,0.1-0.1l0,0.2c0.2-0.1,0.4-0.3,0.7-0.4c0-0.1,0-0.3,0.1-0.3c0.1-0.1,0.2-0.2,0.2-0.2
        c0.3,0.1,0.8,0,0.7-0.4c-0.2,0-0.6,0.5-0.6,0.1c0.3,0,0.7-0.1,0.9-0.4c0.1-0.3,0-0.6,0.4-0.7l-0.1,0.5c0.2-0.2,0.3-0.6,0.7-0.4
        c-0.1-0.1-0.2-0.3,0-0.4c0.3-0.2,0.7-0.4,0.9-0.7c0.3-0.1,0.1-0.5,0.5-0.5c0-0.3,0-0.4,0.2-0.1c0.2-0.3,0.4-0.5,0.6-0.8
        c0.1,0.1,0.2,0.2,0.3,0.2c0-0.1-0.1-0.3-0.1-0.4c0.1,0,0.3,0.1,0.3,0.1c0-0.1,0-0.2,0-0.3c0.4,0,0.4-0.6,0.8-0.6
        c0.2-0.2,0.3-0.4,0.4-0.7c0.1,0.1,0.2,0.2,0.2,0.2c0.2-0.3,0.3-0.8,0.7-1c0.2-0.3,0.4-0.5,0.6-0.7c0,0.1,0.1,0.3,0.1,0.4
        c0.2-0.2,0.1-0.4,0.1-0.6c0.1-0.1,0.3-0.2,0.4-0.2c0.1-0.3,0.4-0.3,0.6-0.3c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.2,0.1,0.3,0.1l0-0.2
        l0.1,0c-0.1-0.3,0.2-0.6,0.5-0.4c-0.2-0.4,0.2-0.5,0.5-0.4c0-0.1,0-0.2,0-0.3c0.1,0,0.3,0,0.4,0c0-0.1,0-0.2-0.1-0.3
        c0.6-0.3,0.5-1.1,0.8-1.6c0.2-0.9,0.8-1.6,0.8-2.5c0-0.4,0.2-0.8,0.4-1.2c0.3-0.4,0-1,0.5-1.3c0-0.1,0-0.3,0-0.4
        c-0.1,0.2-0.3,0.4-0.4,0.6c0-0.6,0.5-1,0.3-1.6c-0.1,0.2-0.2,0.4-0.4,0.7c-0.3,0.7-0.6,1.3-1,2c-0.2,0.5-0.4,1-0.8,1.5
        c0,0.1,0,0.2,0,0.2l-0.2-0.1c0.2-0.3,0.4-0.5,0.5-0.8c0.3-0.9,0.8-1.8,1.2-2.7c0.5-1.3,1.2-2.6,1.2-4c-0.3,0.3-0.4,0.7-0.7,1
        c0-0.1,0.1-0.2,0.1-0.2c0-0.4,0.2-0.7,0.3-1c0-0.5,0.1-0.9,0.2-1.4c0.3-0.5,0.1-1.1,0.3-1.6c0.1-0.6,0.1-1.3,0.5-1.8
        c-0.1-0.2,0-0.4,0.1-0.5c-0.1-0.3,0.1-0.5,0.1-0.8c0-0.4,0.1-0.8,0.2-1.1c0.1-0.4,0.2-0.8,0.3-1.1c0.3,0,0.2-0.3,0-0.3
        c-0.2,0.4-0.5,0.8-0.6,1.3c-0.1,0.2-0.1,0.5-0.3,0.7c0,0.1,0,0.3,0,0.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1-0.1-0.2-0.1-0.3-0.2
        c0-0.1,0.1-0.2,0.1-0.4c0,0.1,0,0.3,0,0.4c0.1,0,0.3-0.1,0.4-0.1c0-0.1,0-0.3,0-0.5c0.3-0.7,0.1-1.5,0.4-2.1c0.1-0.9,0-1.9,0.4-2.8
        c-0.1-0.5,0.1-0.9,0.3-1.4c0.2-0.8,0.5-1.5,0.6-2.3c-0.1,0.1-0.3,0.2-0.4,0.2c0-0.3,0.2-0.3,0.5-0.3c0-0.3-0.2-0.5-0.3-0.7
        c0.1,0,0.3,0.1,0.4,0.1c0.1-0.3,0.2-0.6,0.3-0.9c-0.1,0.1-0.7,0.2-0.4,0.5l0,0c0,0-0.1,0-0.2,0c0-0.2,0-0.3,0-0.4
        c0.1-0.1,0.2-0.2,0.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.2c0-0.1-0.1-0.2-0.1-0.3c0.1,0.2,0.3,0.3,0.5,0.4c-0.1-0.2-0.2-0.5-0.3-0.7
        c0.2,0.1,0.3,0.1,0.5,0.2c0-0.2,0.1-0.3,0.2-0.4c-0.1-0.1-0.2-0.1-0.4-0.1c0.1,0,0.2,0.1,0.3,0.2c-0.2,0.1-0.3,0.1-0.5,0.1
        c0.1-0.3,0-0.7,0-1c0.1,0.1,0.3,0.3,0.4,0.4c-0.1-0.1-0.2-0.3-0.3-0.4l0.4,0c-0.2-0.2-0.3-0.3-0.5-0.5c0.1,0,0.2,0,0.3,0
        c0-0.1,0-0.3-0.1-0.4c0.1-0.1,0.2-0.2,0.3-0.3c0-0.2-0.4-0.4-0.1-0.5l0.1-0.1C308.5,342.8,308.4,342.6,308.4,342.4 M346,342.7
        c0.2-0.1,0.2-0.2,0-0.4C345.8,342.5,345.8,342.6,346,342.7 M321.2,342.8c0.1,0.3,0.4-0.1,0.1-0.2
        C321.3,342.6,321.2,342.7,321.2,342.8 M166.7,343c0.2-0.1,0.2-0.2,0-0.4C166.5,342.7,166.5,342.9,166.7,343 M360.9,342.7
        C361,342.8,361,342.8,360.9,342.7 M281.9,342.9C281.9,343,281.9,343,281.9,342.9 M305.6,343C305.4,343.4,306.1,343,305.6,343
        M254.7,343.1c0,0.2,0,0.4,0,0.6c-0.1,0-0.3-0.1-0.4-0.1c0.1,0.3,0.3,0.2,0.5,0C255.1,343.5,254.8,343.3,254.7,343.1 M344.8,343.1
        c0,0.1,0,0.2,0,0.2l0.1,0C344.9,343.3,344.9,343.1,344.8,343.1L344.8,343.1 M167,343.3C166.7,343.7,167.4,343.3,167,343.3
        M302.6,343.6L302.6,343.6L302.6,343.6L302.6,343.6L302.6,343.6 M209.2,344.1c0,0.2,0.3,0.3,0.4,0.3
        C209.8,344.2,209.3,343.8,209.2,344.1 M168.3,344.1c0,0.2,0,0.3,0.2,0.2C168.5,344.1,168.4,344.1,168.3,344.1 M198.8,344.3
        C198.9,344.4,198.9,344.4,198.8,344.3 M209.1,344.4C209.2,344.5,209.2,344.5,209.1,344.4 M228.9,344.5c-0.1,0.4-0.5,0.7-0.9,0.8
        c0,0.4-0.1,0.8-0.2,1.2c0,0,0.1,0.1,0.2,0.2c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.3,0,0.4c0-0.1,0-0.4,0-0.5c-0.5,0.2,0.1,0.8-0.2,1.2
        c-0.1-0.2-0.2-0.3-0.4-0.4c-0.1,0.4,0.2,1.2-0.5,1.2c0.1,0,0.2,0,0.3,0c-0.1,0.1-0.3,0.2-0.4,0.3c0,0.1,0.1,0.2,0.1,0.3
        c-0.1,0-0.2,0-0.3,0l-0.1,0.3l0.4-0.2c-0.1,0.2-0.2,0.4-0.3,0.6c0.2-0.2,0.3-0.4,0.4-0.6c0.1-0.1,0.4-0.2,0.3-0.5
        c0.3,0,0.5-0.4,0.6-0.6c0.1-0.3,0.5-0.5,0.3-0.9c0-0.1,0-0.3,0-0.3l0.2,0c0-0.3-0.1-0.6-0.2-0.9c0.2,0,0.5,0.1,0.6-0.2
        c-0.1-0.1-0.1-0.3-0.2-0.4l0.4,0.1c-0.2-0.1-0.3-0.3-0.4-0.4C228.7,344.9,228.9,344.7,228.9,344.5L228.9,344.5 M312.7,344.5
        C312.5,344.9,313.2,344.5,312.7,344.5 M305.8,344.8c-0.1,0.1,0.2,0.3,0.1,0.5l-0.1,0.1c0,0.1,0,0.2,0,0.2c0.3-0.3,0.3-0.7,0.4-1.1
        C306,344.6,305.9,344.6,305.8,344.8 M361.2,344.6C361.3,344.7,361.3,344.7,361.2,344.6 M166,345.5c-0.2,0.1,0,0.4,0.2,0.3
        C166.3,345.6,166.1,345.3,166,345.5 M237.2,345.9C237.3,346,237.3,346,237.2,345.9 M319.3,345.9c-0.2,0.2,0.1,0.5,0.3,0.4
        C319.6,346.1,319.5,345.8,319.3,345.9 M213.9,346.2l-0.2-0.1c0.1,0.4-0.1,0.8-0.4,1.1c0,0,0,0.1,0,0.2c-0.1,0.1-0.3,0.3-0.4,0.4
        c0,0.1,0,0.2,0,0.3c-0.2,0.1-0.5,0.3-0.7,0.4c0-0.1,0.1-0.3,0.1-0.4c0-0.1-0.1-0.2-0.1-0.4c-0.1,0.4-0.5,0.7-0.8,0.9
        c0.1,0.5,0.4,0.9,0.5,1.4c0,0.1,0.2,0.2,0.3,0.3c0.3-0.4,0.4-0.8,0.5-1.2c0.3-0.5,0.5-1.1,0.7-1.6c0.1,0,0.2-0.1,0.2-0.1
        C213.7,347,213.8,346.6,213.9,346.2l0.1,0.1c0-0.1,0-0.3,0-0.4C214,346,214,346.1,213.9,346.2 M255.6,346
        C255.6,346.1,255.6,346.1,255.6,346 M358.6,346.5c-0.3-0.1-0.6,0.3-0.8-0.1c0.1,0.4-0.2,0.7-0.6,0.5c0.3,0.5,0.9,0.1,1.2-0.1
        c0,0.1,0.1,0.3,0.1,0.4c0,0,0.1-0.1,0.1-0.1c-0.2,0-0.3-0.4,0-0.3c0.2,0.1,0.4,0.2,0.7,0.2c0.1-0.3-0.2-0.3-0.4-0.4
        c0-0.1,0.1-0.3,0.1-0.4c-0.1,0-0.2,0.1-0.3,0.1c0-0.1,0-0.3,0-0.4C358.4,346.1,358.9,346.3,358.6,346.5 M452,346
        C452.1,346.1,452.1,346.1,452,346 M237.4,346.1C237.4,346.2,237.4,346.2,237.4,346.1 M314.1,346.1c0,0.3,0.3,0.4,0.6,0.6
        C314.6,346.4,314.4,346.2,314.1,346.1 M237.6,346.3c-0.1,0.2,0,0.3,0.2,0.2C237.9,346.3,237.8,346.2,237.6,346.3 M242.3,346.3
        c-0.1,0.2,0,0.3,0.2,0.2C242.5,346.3,242.5,346.2,242.3,346.3 M383.2,346.4c0.1,0.3,0.5,0.2,0.6-0.1
        C383.7,346.1,383.3,346.2,383.2,346.4 M361.6,346.7c0.2,0,0.3,0,0.2-0.2C361.7,346.4,361.6,346.5,361.6,346.7 M128.9,346.7
        c0.1,0.1,0.3,0.2,0.5,0.1C129.4,346.6,129,346.5,128.9,346.7 M305.3,346.5c0.1,0.3,0.4,0.3,0.6,0.4
        C305.7,346.7,305.6,346.5,305.3,346.5 M130.1,346.7L130.1,346.7L130.1,346.7L130.1,346.7L130.1,346.7 M156,346.7
        c-0.1,0.3-0.2,0.6-0.3,0.9c0.2-0.1,0.4-0.3,0.6-0.4c0,0.1-0.1,0.3-0.2,0.5c0.1,0,0.3,0.1,0.4,0.1c0-0.2-0.1-0.4-0.1-0.6l-0.2,0
        C156.3,346.9,156.2,346.7,156,346.7 M295.7,346.7c0,0.1,0.1,0.3,0.1,0.4C296,347,295.8,346.5,295.7,346.7 M150.9,346.8
        c0,0.2,0.1,0.3,0.3,0.2C151.1,346.8,151,346.7,150.9,346.8 M227.2,346.9C227,347.3,227.7,346.9,227.2,346.9 M361.7,347L361.7,347
        c0.2,0.1,0.3,0,0.4-0.2C362.1,346.6,361.7,346.9,361.7,347 M362.3,346.7c0,0.1,0,0.4,0.1,0.5c0.3,0,0.5,0,0.8,0.1
        c0-0.1-0.1-0.3-0.1-0.4c-0.1,0.1-0.2,0.3-0.3,0.4C362.6,347,362.4,346.9,362.3,346.7 M150.5,347c0,0.4,0.3,0.4,0.6,0.5
        c0,0,0-0.1,0-0.2c-0.3,0-0.4-0.1-0.4-0.3C150.7,347,150.6,347,150.5,347 M379.2,347C379.2,347,379.2,347,379.2,347 M261.4,347.1
        c-0.1,0.2,0,0.3,0.3,0.2C261.7,347.1,261.6,347.1,261.4,347.1 M315.3,347.2c0.1,0.2,0.2,0.2,0.3,0C315.6,347,315.5,347,315.3,347.2
        M149.5,347.5c0.3-0.1,1,0.1,0.9-0.4c-0.1,0.1-0.3,0.3-0.4,0.3C149.8,347.3,149.6,347.2,149.5,347.5 M169,347.3
        C169,347.4,169,347.4,169,347.3 M364.2,347.3C364.3,347.7,364.6,347,364.2,347.3 M364.9,347.2C364.9,347.7,365.3,347,364.9,347.2
        M367.1,347.3c0.1,0.2,0.2,0.2,0.3,0C367.3,347.1,367.2,347.2,367.1,347.3 M373.5,347.3L373.5,347.3L373.5,347.3L373.5,347.3
        L373.5,347.3 M134.5,347.9c0.2,0,0.4-0.2,0.4-0.4C134.8,347.3,134.5,347.7,134.5,347.9 M142.5,347.4c-0.2,0-0.1,0.3,0.1,0.2
        c0.2-0.1,0.5,0,0.6-0.2C142.9,347.3,142.7,347.4,142.5,347.4 M280.4,347.7c0.3,0.1,0.3,0.2,0,0.5c0.1,0,0.2,0,0.3,0
        c0.1-0.2,0.1-0.3,0.2-0.5l-0.2,0l0-0.3C280.6,347.5,280.5,347.6,280.4,347.7 M368.3,347.4C368.1,347.8,368.8,347.4,368.3,347.4
        M369.8,347.5c-0.1,0.1-0.2,0.2-0.3,0.3c0.3-0.1,0.7,0,1-0.1c0.1,0.1,0.3,0.2,0.4,0.2c0-0.2-0.2-0.3-0.3-0.4
        C370.3,347.5,370,347.5,369.8,347.5 M139.1,347.6C138.9,348,139.6,347.6,139.1,347.6 M145.3,347.5c-0.1,0.2,0,0.3,0.3,0.2
        C145.7,347.5,145.6,347.5,145.3,347.5 M147.6,347.6C147.7,347.7,147.7,347.7,147.6,347.6 M148.3,347.6
        C148.4,347.7,148.4,347.7,148.3,347.6 M373.7,347.6C373.8,348,374.2,347.3,373.7,347.6 M374.5,347.5L374.5,347.5
        C374.5,347.6,374.5,347.5,374.5,347.5L374.5,347.5 M376.1,347.5l0,0.1C376.1,348,376.4,347.4,376.1,347.5 M135.7,347.7
        c-0.1,0.2,0,0.3,0.2,0.2C135.9,347.8,135.9,347.7,135.7,347.7 M139.5,347.7C139.6,347.8,139.6,347.8,139.5,347.7 M139.9,347.7
        C139.7,348.2,140.4,347.8,139.9,347.7 M144.3,347.7C144.1,348.1,144.8,347.8,144.3,347.7 M136.5,347.8
        C136.3,348.3,137,347.9,136.5,347.8 M138.1,347.8c0,0,0.1,0.1,0.1,0.2l0.1,0l0-0.1C138.2,347.8,138.1,347.8,138.1,347.8
        M141.8,348.2c0.2-0.1,0.3-0.2,0.5-0.2c0.3,0.1,0.6,0.1,0.9,0.1c-0.2-0.4-0.7-0.2-1-0.3C142,347.8,141.9,348,141.8,348.2
        M141.2,347.9c0,0.2,0.1,0.2,0.3,0.1C141.5,347.9,141.4,347.8,141.2,347.9 M305.3,348c-0.1,0.2,0,0.3,0.2,0.2
        C305.6,348,305.5,347.9,305.3,348 M218.4,348.3C218.5,348.4,218.5,348.4,218.4,348.3 M280.4,348.2c0,0.4-0.2,0.6-0.4,0.9
        c0.1,0.1,0.3,0.3,0.5,0.3c0-0.1,0.1-0.3,0.1-0.4c0-0.2,0.1-0.4,0.1-0.5C280.6,348.4,280.5,348.3,280.4,348.2 M296.7,348.2
        c0,0.2,0.1,0.3,0.2,0.4c0,0,0.1,0,0.2,0C297,348.4,296.9,348.1,296.7,348.2 M162.5,349.2c0.2,0.1,0.5,0,0.5-0.3
        C162.8,348.8,162.5,349,162.5,349.2 M163.7,348.9c-0.2,0.1-0.3,0.4-0.3,0.6c0.2-0.1,0.3-0.2,0.5-0.2
        C163.9,349.1,163.8,349,163.7,348.9 M204,349.5c0,0.1,0,0.3,0,0.4c-0.1,0-0.2,0.1-0.3,0.2c0,0.3-0.1,0.5-0.3,0.6l-0.1,0
        c-0.1,0.3-0.3,0.7-0.4,1c-0.1,0-0.2,0-0.2,0c0,0.1,0,0.3,0,0.4c-0.2,0.2-0.4,0.4-0.5,0.7c0,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.2,0-0.2,0
        c0,0.1,0,0.3,0,0.4c-0.2,0.4-0.6,0.7-0.7,1.2c-0.2,0.3-0.5,0.6-0.8,0.8c0.1,0.1,0.1,0.1,0.2,0.2c0.8-0.8,1.5-1.8,2.2-2.7
        c0.2-0.4,0.7-0.7,0.8-1.2c0.1-0.3,0.4-0.5,0.2-0.8l0,0c0,0.1,0.1,0.2,0.2,0.2c0.2-0.5,0-1,0.1-1.4c-0.1-0.2,0-0.5,0-0.7
        C204.1,349.1,204,349.3,204,349.5 M257.1,349C256.9,349.4,257.6,349,257.1,349 M286.5,349.3C286.7,348.9,286.1,349.3,286.5,349.3
        M163.6,349.7c0.1,0.2,0.2,0.2,0.3,0C163.8,349.4,163.7,349.4,163.6,349.7 M275.5,349.6C275.6,349.7,275.6,349.7,275.5,349.6
        M285.2,350C285.3,350.1,285.3,350.1,285.2,350 M228,350c-0.2,0.4,0.3,0.4,0.5,0.5c-0.1,0.1-0.2,0.3-0.2,0.4
        c0.1,0.1,0.3,0.2,0.3,0.3c0.1-0.2,0-0.3-0.2-0.3c0.1-0.1,0.2-0.3,0.2-0.4C228.4,350.4,228.3,350.1,228,350 M240.3,350.1
        C240.4,350.2,240.4,350.2,240.3,350.1 M304.9,350.6C304.7,351,305.4,350.5,304.9,350.6 M227.7,350.7c0.1,0.2,0.1,0.4-0.1,0.6
        c0.3,0.2,0.1,0.4,0,0.5c0,0,0,0.1,0.1,0.1c-0.4-0.2-0.3,0.3-0.3,0.6c0,0-0.1,0-0.2,0l0,0.2l-0.2,0c-0.1,0.4-0.3,0.8-0.5,1.2
        c-0.1,0-0.2,0-0.2-0.1l0.1,0.3l-0.3-0.1l0,0.1l0.3,0c-0.1,0.1-0.2,0.3-0.2,0.4c-0.1,0.2-0.1,0.4-0.2,0.5c0.2,0,0.3-0.2,0.3-0.4
        c0.1-0.2,0.2-0.4,0.4-0.6c0,0,0-0.1-0.1-0.2c0.4-0.5,0.8-1,1.3-1.4c0-0.1-0.1-0.2-0.1-0.2l0,0c0.1,0,0.2,0.1,0.3,0.1
        c0.1-0.3,0.2-0.5,0.3-0.7l-0.4,0.2c0.1-0.4,0.1-0.7,0.3-1l-0.3,0.1l0.1-0.3L227.7,350.7 M187.5,351c-0.1,0.4,0.5,0.3,0.4,0
        C187.8,351,187.7,351,187.5,351 M259.3,350.9c0,0.1,0,0.3,0,0.4C259.7,351.5,259.6,350.7,259.3,350.9 M326.3,351.3
        c0.2,0.1,0.5-0.1,0.4-0.3C326.6,350.9,326.3,351.1,326.3,351.3 M228.6,351.6c0.1-0.2-0.2-0.3-0.4-0.4
        C228,351.3,228.4,351.7,228.6,351.6 M258.5,352.1c0.1-0.1,0.2-0.2,0.3-0.2c0.2-0.2,0.4-0.3,0.3-0.6
        C258.9,351.5,258.7,351.8,258.5,352.1 M220.4,351.7l-0.1,0.1C220.4,352.2,220.7,351.6,220.4,351.7 M241.8,351.8c0,0.2,0,0.4,0,0.7
        c0.1,0,0.2,0,0.3,0c0-0.2-0.2-0.4-0.1-0.7C241.9,351.8,241.8,351.8,241.8,351.8 M213.5,352C213.6,352.1,213.6,352.1,213.5,352
        M281.6,352c0.1,0.4-0.2,0.7-0.3,1l-0.1,0c0,0.2-0.1,0.4-0.1,0.6c0.1,0,0.2,0.1,0.3,0.1c-0.1,0-0.4,0-0.6,0c0,0.1,0,0.3,0,0.4
        l-0.2,0c0,0.4-0.4,0.9,0.1,1.1c0.2,0.2,0.3,0.4,0.6,0.6c0.1-0.3,0.1-0.7,0.3-0.9c0-0.1,0-0.2,0-0.3c-0.1-0.1-0.3-0.2-0.4-0.3
        c0.1,0,0.3,0,0.4,0.1c-0.1-0.2-0.2-0.4-0.2-0.6c0.2-0.1,0.4,0,0.5,0.2c-0.1-0.2-0.2-0.5-0.4-0.5c0-0.1-0.1-0.3-0.1-0.4
        c-0.5-0.1,0.1-0.8,0.3-0.5l0,0.1c0,0,0.1,0,0.2,0.1c-0.1-0.2-0.1-0.3-0.2-0.4C281.9,352.1,281.7,352,281.6,352 M202.2,352.1
        C202.3,352.2,202.3,352.2,202.2,352.1 M258.8,352.1C258.6,352.6,259.3,352.1,258.8,352.1 M265.7,353
        C265.7,353.1,265.7,353.1,265.7,353 M282,353.1c-0.1,0.2,0,0.3,0.2,0.2C282.3,353.1,282.2,353,282,353.1 M259.7,353.3
        c-0.1,0.2-0.1,0.4,0,0.6C259.8,353.7,260,353.2,259.7,353.3 M239.5,353.4c0.1,0.3-0.1,0.7,0.2,1c0,0.2,0,0.3,0,0.5
        c0.2,0.1,0.2,0.3,0.2,0.5l0.1,0c0,0.3-0.1,0.8,0.3,0.8c0,0-0.1,0.1-0.2,0.1c0,0.2,0,0.5,0,0.7c0.4,0-0.1,0.7,0.5,0.6
        c0,0.2,0,0.4-0.1,0.5l0.2,0c0,0.1,0,0.3,0,0.5l0.2,0c0,0.1,0,0.3-0.1,0.5l0.2,0c0,0.1,0,0.2,0,0.3c0.2,0.2,0.1,0.6,0.3,0.8
        c0,0.1,0,0.3,0,0.4l0.2,0c0,0.1-0.1,0.3-0.1,0.4c0.3,0.1,0.3,0.4,0.3,0.7l0.1,0c0,0.3,0.2,0.6,0.3,0.9c0.3,1,0.9,1.8,1.3,2.8
        c-0.6-0.8-1-1.7-1.5-2.5c-0.5-0.7-0.9-1.5-1.3-2.3c-0.2-0.6-0.6-1.1-0.6-1.7c-0.1-0.5-0.4-0.8-0.4-1.3c0,0-0.1,0-0.2,0
        c0,0,0.1,0.1,0.1,0.2c0.1,0.3,0.2,0.7,0.1,1c0.1,0.1,0.2,0.3,0.2,0.4c-0.2,0.3,0.1,0.7,0.2,1c-0.1,0.2-0.1,0.3-0.1,0.5
        c0.3,0.3,0.2,0.6,0,0.9c0.3,0.5,0.3,1,0.4,1.5l0.2,0c-0.1,0.3,0.1,0.6,0.3,0.9c0,0.2,0,0.4,0,0.6c0.1,0,0.2,0,0.3,0l0,0.2
        c0.3,0,0.2,0.4,0.3,0.6c0.1,0,0.2,0,0.3-0.1c0,0.1,0,0.2,0,0.3l0.1,0c0.1,0.4,0.5,0.6,0.8,0.9c-0.2-0.1-0.5-0.3-0.7-0.4
        c-0.3-0.1-0.4-0.5-0.8-0.6c-0.2-0.2-0.4-0.4-0.6-0.6c0.1,0.1,0.2,0.3,0.2,0.4c0.2,0.2,0.4,0.3,0.4,0.6c0.2,0.2,0.2,0.7-0.1,0.8
        c0.1,0,0.2,0.1,0.2,0.1l-0.1,0.1c0.2,0,0.3,0.1,0.5,0.2c0,0.1,0,0.3,0,0.5c0.1-0.1,0.2-0.2,0.3-0.3c0,0.1-0.1,0.3-0.2,0.4
        c0.1,0.2,0.2,0.4,0.3,0.6c-0.1,0-0.3-0.1-0.4-0.1c0.1,0.1,0.2,0.2,0.4,0.2c0.2,0.2,0.2,0.4,0.1,0.6c0,0,0.1,0,0.2,0
        c0.1,0.2,0.1,0.3,0.2,0.5c-0.2,0.4,0.4,0.5,0.3,0.9l0.1,0c0.1,0.2,0.5,0.4,0.2,0.6c0.1,0.4,0.4,0.7,0.6,1c-0.7-0.3-0.8-1.2-1.5-1.4
        c0.1,0.4,0.4,0.7,0.3,1.1l-0.1,0c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.2-0.1-0.5-0.1-0.7c0,0.1-0.1,0.2-0.1,0.4c0.2,0.2,0.2,0.5,0.3,0.7
        c0.2,0.1,0.4,0.2,0.6,0.3c-0.1,0.2,0,0.4,0.1,0.5c0.1,0.2,0.1,0.4,0.2,0.6c0.2,0.2,0.1,0.4,0,0.7c0.1-0.1,0.2-0.2,0.3-0.3
        c0,0.4,0.1,0.8,0.3,1.2c0,0,0.1,0,0.2,0c0,0.3,0.1,0.7,0.3,0.9c0,0.1,0,0.3,0,0.4c0.3,0.3,0.2,0.9,0.5,1.3l0,0.1l0.1,0
        c0,0.4,0.3,0.6,0.5,0.9c-0.3-0.3-0.6-0.6-1-0.8c-0.5-0.4-0.7-1-1.1-1.4c-0.2-0.2-0.4-0.3-0.4-0.5c0-0.1-0.1-0.2-0.1-0.3
        c-0.2,0-0.4,0-0.6-0.1c0.2-0.1,0.3-0.4,0-0.5l0-0.1c-0.2-0.1-0.4-0.1-0.5-0.2c0-0.1,0.1-0.3,0.1-0.4h0.2c-0.2-0.1-0.5-0.4-0.3-0.6
        c-0.1,0-0.2,0-0.3,0c0-0.2-0.1-0.3-0.2-0.4c0,0.2,0,0.4,0.1,0.5l0.1,0c0.1,0.3,0.2,0.7,0.3,1c0.1,0,0.3,0.1,0.4,0.2
        c-0.1,0.2-0.5,0.6,0.2,0.3c-0.1,0.1-0.2,0.3-0.3,0.5c0.1,0,0.2-0.1,0.3-0.1l0,0.2c0.1,0,0.3-0.1,0.4-0.1l-0.2,0.3c0.1,0,0.2,0,0.3,0
        c-0.3,0.4,0.4,0.6,0.5,0.9c0.1,0.5,0.4,0.9,0.6,1.3c0,0.1-0.1,0.2-0.1,0.4c0.1,0,0.2-0.1,0.3-0.1c0,0.2,0,0.4,0,0.5
        c0.1,0,0.2-0.1,0.2-0.1c0,0.1,0,0.3,0,0.4l0.2,0c0,0.1,0,0.2,0,0.3l0.1,0c-0.1,0.4,0.3,0.6,0.6,0.7l0,0.1c0.6-0.1,1.1,0.6,1.6,0.3
        c0.1,0,0.2,0.1,0.3,0.1c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.2,0.1-0.3,0.1-0.5c0.1,0,0.2,0,0.2,0l-0.2-0.1c-0.1-0.1-0.2-0.3-0.3-0.4
        c0.1,0,0.3-0.1,0.4-0.1c-0.1-0.2-0.1-0.4-0.3-0.4c-0.1-0.1-0.1-0.2-0.2-0.4c0.2,0.1,0.3,0.3,0.5,0.4c0.3-0.3-0.3-0.5-0.3-0.8
        c0.6,0.3,0.4-0.1,0.1-0.2c-0.1-0.2-0.1-0.4-0.1-0.7c-0.3,0-0.3-0.2-0.4-0.4c0.2,0,0.3,0.1,0.5,0.2c0-0.1,0.1-0.3,0.2-0.4
        c0,0-0.1,0-0.2,0l-0.1,0.2c-0.4-0.4-0.7-1-1.3-1.1c0.1-0.1,0.2-0.2,0.3-0.3c-0.1,0-0.2,0.1-0.3,0.1c0-0.4-0.2-0.6-0.4-0.8
        c0.2,0,0.4,0,0.6,0c0,0,0,0.1,0.1,0.2l0-0.3c0.1,0,0.4,0.1,0.5,0.1c-0.1-0.3-0.4-0.4-0.7-0.3c0.1-0.1,0.2-0.2,0.4-0.2
        c0.2,0,0.4,0,0.6,0c-0.1-0.2,0.1-0.5-0.2-0.6c0.1-0.2,0.2-0.3,0.3-0.5l-0.2,0c0-0.2,0.1-0.4,0.2-0.5c0.4-0.1,0.1-0.6-0.2-0.3
        c-0.1-0.3,0.3-0.8-0.1-1c0-0.2,0-0.3-0.1-0.5c0-0.1-0.1-0.2-0.1-0.3c-0.4-0.1-0.3-0.6-0.6-0.8c0.1,0,0.3,0,0.5,0
        c-0.2-0.1-0.4-0.2-0.6-0.1c0-0.1,0.1-0.2,0.1-0.3c-0.3-0.1-0.6-0.1-0.9-0.3c0-0.2-0.1-0.4-0.2-0.5c0.1,0,0.3,0.1,0.4,0.2l0-0.2
        c0.1-0.3,0.2-0.6,0.4-0.8c-0.1,0.3-0.2,0.5-0.3,0.8c0.2-0.1,0.4-0.3,0.5-0.4c0-0.1,0-0.3,0-0.4c-0.2-0.3-0.4-0.7-0.4-1
        c-0.3-0.5-0.5-1-0.7-1.6c-0.3-0.5-0.2-1.2-0.7-1.5c-0.1,0-0.2,0.1-0.3,0.2c-0.2,0-0.5,0-0.7-0.1c0.1-0.2,0.2-0.5,0.3-0.7
        c-0.1,0.1-0.4,0.2-0.6,0.2c0.2-0.2,0.4-0.4,0.6-0.6c0.1,0.1,0.2,0.2,0.2,0.3c0-0.1-0.1-0.3-0.1-0.4c0.1-0.1,0.2-0.2,0.3-0.3
        c-0.3,0.1-0.5,0.3-0.8,0.5l0,0c0.1-0.2,0.1-0.4,0-0.7l0.1,0c0-0.1,0-0.2,0-0.2l0.2-0.2c0,0.3-0.2,0.4-0.3,0.7c0.4,0,0.4-0.4,0.4-0.7
        c-0.7-0.7-0.6-1.7-1.2-2.4c-0.2-0.7-0.4-1.4-0.9-1.9c-0.5-0.6-0.6-1.3-0.9-1.9C240.1,354.4,240,353.7,239.5,353.4 M305.5,353.6
        c0.1,0.2,0.2,0.2,0.3,0C305.8,353.5,305.6,353.5,305.5,353.6 M228.1,354.4l0.1-0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.5-0.5,0-0.4,0.4
        c0,0.1-0.1,0.2-0.1,0.4c-0.2,0-0.3,0.2-0.5,0.3c0.1,0,0.2,0.1,0.2,0.1l0.1-0.2c0.1,0.2,0,0.3-0.3,0.3c0.1,0.2,0.3,0.5,0,0.6
        c0-0.2,0-0.4-0.1-0.6c0,0.7-0.7,1.3-0.7,2l-0.1,0c-0.1,0.3-0.3,0.8-0.7,0.7l0,0.2c0.1,0,0.4-0.1,0.5-0.1c-0.1,0.2-0.2,0.4-0.2,0.6
        l-0.1,0c0-0.1,0-0.3,0-0.4c-0.3,0.1-0.2,0.5-0.3,0.7c-0.2,0-0.3,0.1-0.4,0.3l-0.2,0c0,0.1,0.1,0.3,0.1,0.5l-0.2-0.1
        c0.1,0.2,0,0.5,0,0.7c-0.3,0.1-0.5,0.3-0.4,0.7c-0.1,0-0.3,0-0.4,0c0.1,0.1,0.2,0.2,0.2,0.4c-0.2,0.1-0.3,0.3-0.5,0.4
        c0,0,0.1,0.1,0.1,0.2c-0.3,0.2-0.5,0.4-0.8,0.5c-0.1,0.1-0.3,0.2-0.4,0.3c0-0.3,0-0.5,0-0.8c-0.5-0.2-0.9-0.6-1.4-0.8
        c0,0.2,0.2,0.3,0.3,0.5c-0.1,0-0.4,0-0.5,0.1c-0.1-0.5-0.6-0.9-1-1.2c0.3,0.5,0.9,0.7,0.9,1.3c0.1,0,0.2-0.1,0.3-0.1
        c0,0.1,0,0.3,0,0.4c0,0,0.1,0,0.1,0c0-0.2,0.1-0.4,0.3-0.5c0,0.1,0,0.2,0,0.2c0.1,0.2,0.2,0.4,0.4,0.6c0.1,0.2,0.2,0.4,0.2,0.6
        c0.3-0.1,0.3,0.2,0.3,0.4c0.2,0.3,0.6,0.6,1,0.8c0,0,0,0.1,0,0.1c0,0-0.1-0.1-0.1-0.1c-0.6-0.2-1.1-0.6-1.7-0.9
        c-0.5-0.3-1-0.6-1.5-1c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1,0-0.2,0-0.3,0c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.3-0.3-0.4
        c-0.1-0.2-0.2-0.3-0.4-0.5c0,0-0.1,0.1-0.1,0.1c0.4,0.1,0.4,0.5,0.6,0.8c0.1,0.1,0.1,0.2,0.2,0.3l-0.2,0.1l0.2,0.1l0.1-0.3
        c0.1,0.1,0.2,0.2,0.3,0.4c-0.1,0.1-0.2,0.3-0.3,0.3l0.2-0.2c0.1,0.1,0.1,0.2,0.2,0.3c0.2-0.1,0.4,0,0.4,0.2
        c-0.1,0.2-0.1,0.4-0.2,0.5c0.1-0.1,0.2-0.2,0.2-0.2c0.1,0.1,0.3,0.3,0.4,0.4c-0.7-0.3-0.2,0.3-0.1,0.5c0-0.1,0-0.4-0.1-0.5
        c0.3,0.1,0.3,0.3,0.4,0.6c0.1,0,0.2,0.1,0.3,0.1c0,0.3,0.2,0.5,0.2,0.8c0.1,0,0.2-0.1,0.3-0.1c0,0.1,0,0.2,0,0.3c0.1,0,0.2,0,0.3,0
        c-0.2,0.5,0.5,0.3,0.5,0.7c-0.2,0.1-0.5-0.1-0.7-0.1c0.1,0.2,0.2,0.2,0.4,0.3c0.1,0.2,0.2,0.5,0.4,0.6c0.1-0.2,0.1-0.4,0.2-0.6
        c0.2-0.3,0.5-0.6,0.6-0.9c0.2-0.4,0.3-0.9,0.6-1.2c0.3-0.2,0.3-0.6,0.5-0.9c0.3-0.5,0.6-0.9,0.8-1.5c0.2-0.9,0.6-1.7,1-2.4
        c0-0.5,0.5-0.7,0.4-1.2c0.1-0.2,0.3-0.3,0.3-0.6c0.1-0.5,0.4-1,0.7-1.4c-0.1,0-0.4,0.1-0.5,0.1c0,0,0.1-0.1,0.1-0.1
        c0.7,0.1-0.2-0.4,0.3-0.6c-0.1,0.2,0.1,0.4,0.2,0.6c0.1-0.2,0.1-0.3,0.2-0.5c0.1-0.2,0.2-0.5,0.2-0.7c-0.4,0.1-0.1,0.9-0.6,0.7
        c0-0.1,0-0.4,0-0.5c-0.1-0.1-0.1-0.3-0.1-0.4c0.2-0.1,0.3-0.2,0.5-0.3c-0.1,0.1-0.4,0.5-0.1,0.6c0.1-0.2,0.1-0.5,0.3-0.6
        c-0.2-0.4-0.8-0.6-0.6-1.1C227.6,353.7,227.9,354.1,228.1,354.4 M228.5,354c0,0.1,0.3,0.3,0.4,0.1c-0.1-0.1-0.1-0.3-0.1-0.4
        C228.6,353.6,228.3,353.8,228.5,354 M188,354.2c0-0.2-0.1-0.3-0.3-0.3C187.4,354,187.8,354.3,188,354.2 M297.1,354.1
        C297.2,354.2,297.2,354.2,297.1,354.1 M322.8,354.5c0.2,0.1,0.3,0,0.4-0.1C323.2,354.1,322.7,354.2,322.8,354.5 M227.5,354.4
        c-0.1,0.2,0,0.3,0.2,0.2C227.8,354.4,227.7,354.3,227.5,354.4 M243,354.6c-0.1,0.2,0.2,0.4,0.4,0.3
        C243.6,354.7,243.2,354.4,243,354.6 M184.8,355.3c0.1,0,0.3,0,0.3,0c-0.1,0.3-0.3,0.7-0.4,0.2c-0.1,0.1-0.2,0.2-0.3,0.3
        c0,0.1,0.1,0.2,0.1,0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0.3-0.5,0.5-0.6,0.9c0.5,0,0.1,0.4-0.1,0.6c0-0.1,0-0.2,0-0.3l0.3,0l0-0.2
        c-0.1,0-0.3,0-0.4,0c0,0.2,0,0.5,0,0.7c-0.1,0-0.3,0.1-0.3,0.1c-0.2,0.3-0.6,0.5-0.4,0.8c-0.3,0-0.6,0.2-0.7,0.5c0.1,0,0.3,0,0.4,0
        c-0.1,0.1-0.2,0.2-0.2,0.2c0.3,0.2,0.6,0.4,0.9,0.5c0.3,0.1,0.6,0.1,0.7,0.4c0.7,0.3,1.5,0.3,2.1,0.7c0.2,0.1,0.5,0.1,0.7,0.3
        c0.1,0,0.3,0,0.3,0.1c0.4,0.4,1.3,0.2,1.3,1c0.1-0.1,0.2-0.4,0.3-0.2c0.4,0.4,1.1,0.3,1.4,0.9c0.5,0.1,1.1,0.2,1.5,0.5
        c0.5,0.4,1.2,0.4,1.6,0.9c0.4,0.1,0.9,0.2,1,0.6c0.2-0.1,0.4,0,0.6,0.2c0.6,0.2,1.2,0.4,1.7,0.7c0.1,0.3,0.4,0.4,0.7,0.3
        c0,0.1,0.1,0.2,0.2,0.2c0.3,0,0.5,0.2,0.7,0.4c0.2,0,0.4,0,0.6,0.2c0.3,0.3,0.7,0.4,1.1,0.6c0.1,0,0.2,0.1,0.3,0.1
        c0.1,0.1,0.2,0.2,0.3,0.3c0.4-0.1,0.7,0.4,1.1,0.5c0.4,0.4,1,0.4,1.4,0.7c0.2,0.2,0.4,0.4,0.5,0.6c0.1,0,0.3,0,0.3,0
        c0,0.1,0.1,0.3,0.2,0.4c0.1-0.1,0.2-0.2,0.3-0.3c0.2,0.7,1.1,0.4,1.5,0.9c0.6,0.1,1,0.6,1.5,0.8c0.3,0.1,0.5,0.4,0.6,0.6
        c0.5,0,0.8,0.3,1.1,0.6c0.3,0.3,0.8,0.4,1,0.7c0.1,0,0.2-0.1,0.3-0.1c0.3,0.2,0.6,0.5,0.9,0.8c0.3,0.3,1,0.2,1.1,0.8
        c0.2,0.1,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.6,0.5c0.5,0,0.3,0.8,0.8,0.6c0.2,0.2,0.3,0.5,0.6,0.6c0.4,0.2,0.8,0.5,1.2,0.7
        c0,0.1,0,0.2,0,0.3c0.3,0,0.5,0.1,0.7,0.4c0.4,0.5,0.9,0.8,1.2,1.3c0.3,0,0.7,0.6,0.9,0.1c-0.1,0.3,0.1,0.5,0.2,0.8
        c0.4,0.1,0.6,0.5,0.9,0.8c0.2-0.1,0.3-0.1,0.5-0.1c-0.1,0.2-0.1,0.3-0.2,0.5c0.2,0.3,0.4,0.5,0.7,0.7c0-0.1,0.1-0.3,0.1-0.4
        c0,0.1,0,0.4,0,0.5c0.5-0.2,0.6-0.7,0.8-1.2c0.4-0.6,0.7-1.3,1.1-1.8c-0.1-0.3,0-0.5,0.2-0.6c-0.2,0.2-0.6,0.4-0.4,0.7
        c-0.1,0-0.3,0-0.4,0c0,0.1-0.1,0.3-0.1,0.4c-0.1,0-0.3,0-0.4,0l0,0.1c-0.2,0-0.5,0.1-0.7,0.1c0.1-0.3,0.2-0.4,0.4-0.5
        c0.4-1.3,1.6-2.2,2.2-3.3c-0.2,0-0.4,0.1-0.6,0.2c-0.3,0.4-0.9,0.5-1.5,0.5c-0.2,0.5-0.6,0.9-1,1.2c-0.1,0.1-0.2,0.2-0.2,0.3
        c-0.3,0.3-0.5,0.7-1,0.8c0.7-0.7,0.8-1.7,1.4-2.4c0.4-0.6,0.9-1.1,1.1-1.7c-0.1,0.1-0.4,0.2-0.5,0.3c0.1-0.1,0.2-0.4,0.2-0.5
        c-0.2-0.2-0.4-0.3-0.6-0.5c-0.3-0.2-0.4-0.6-0.8-0.6c-0.4-0.3-0.7-0.5-0.8-1c-0.2,0.1-0.4,0.2-0.5,0.3c0.1,0.2,0.4,0.3,0.4,0.6
        c-0.4,0-0.4-0.4-0.5-0.6c-0.3-0.2-0.5-0.7-0.9-0.5c-0.1-0.7-1.1-0.5-1.1-1.2c-0.5-0.2-0.8-0.7-1.4-0.8l0,0.3c0-0.1-0.1-0.2-0.1-0.3
        c-0.3-0.1-0.5-0.4-0.7-0.6c-0.1,0-0.2,0-0.3,0.1c-0.1-0.2-0.3-0.3-0.5-0.4c0.1,0,0.3,0,0.4,0c0.1,0.1,0.2,0.2,0.2,0.2
        c0.4,0-0.1-0.4-0.2-0.5c-0.2,0-0.5-0.1-0.7-0.1c0.1,0.2,0.2,0.4-0.1,0.4c0-0.1,0-0.3,0.1-0.4c-0.5-0.3-1.1-0.7-1.6-0.9
        c-0.4-0.3-0.7-1.1-1.3-1c-0.1-0.1-0.2-0.1-0.3-0.2c-0.9-0.3-1.6-0.9-2.5-1.3c-0.3-0.1-0.4-0.4-0.7-0.5c-0.4-0.1-0.7-0.5-1.1-0.6
        c-0.1,0.1-0.3,0.3-0.1,0.4c-0.1-0.1-0.3-0.2-0.4-0.2c0.2-0.1,0.3-0.2,0.5-0.4c-0.2,0-0.5,0-0.7,0.1c0-0.1-0.1-0.2-0.1-0.3
        c-0.1-0.1-0.2-0.2-0.3-0.3c-0.4-0.2-0.7-0.5-1.1-0.5c-0.4,0-0.8-0.3-1.1-0.5l0,0.3c-0.1-0.5-0.5-0.8-1-0.8c-0.3-0.2-0.7-0.4-1-0.6
        c0,0.1,0.1,0.3,0.1,0.4c-0.2-0.3-0.5-0.5-0.8-0.7c-0.5-0.1-0.8-0.5-1.2-0.7c-0.1,0.1-0.1,0.2-0.2,0.3l0.3,0.1
        c-0.4,0.1-0.3-0.2-0.3-0.5c-0.5-0.1-0.9-0.5-1.3-0.9c-0.4,0.1-0.7-0.1-1-0.2c-0.4-0.3-0.8-0.4-1.2-0.7c-0.4-0.1-0.7-0.3-0.9-0.5
        c-0.2-0.1-0.4-0.1-0.5-0.2c-0.4-0.3-0.8-0.5-1.3-0.7c-0.1,0-0.2,0-0.2,0.1c-0.5-0.2-1-0.4-1.4-0.8c-0.2,0-0.4-0.1-0.5-0.1
        c-0.2-0.6-1-0.7-1.5-0.9c-0.6-0.1-1-0.8-1.6-0.8c-0.5-0.2-1-0.5-1.3-0.9C185.3,354.9,184.7,355,184.8,355.3 M219.6,355
        c0.2-0.1,0.2-0.3,0-0.4C219.4,354.7,219.4,354.8,219.6,355 M260.5,354.6c-0.2,0-0.2,0.4,0,0.4C260.6,355,260.7,354.6,260.5,354.6
        M204.3,355c-0.1,0.2,0,0.3,0.2,0.2C204.6,355,204.5,354.9,204.3,355 M170.7,355.1c0,0.2,0,0.3,0.1,0.4
        C171.1,355.6,170.9,355.1,170.7,355.1 M171.2,355.4C171.3,355.9,171.6,355.1,171.2,355.4 M280.5,355.3c0,0.3,0.1,0.6,0,0.8l0.2,0
        c-0.1,0-0.3,0-0.4,0c0.1,0.1,0.2,0.2,0.3,0.3c0,0.1-0.1,0.2-0.1,0.3c-0.1,0-0.2-0.1-0.2-0.1c-0.2,0.3-0.5,0.5-0.6,0.8
        c0.2-0.2,0.4-0.4,0.6-0.6c0.2,0.4-0.2,0.6-0.5,0.8c0.1,0,0.2,0.1,0.2,0.1c-0.2,0.2-0.2,0.4-0.2,0.6c-0.2,0-0.3,0.1-0.4,0.3h0.3
        c-0.2,0.2-0.4,0.5-0.5,0.8c-0.2,0.4-0.6,0.8-0.8,1.3c-0.4,0.5-1,0.9-1.1,1.5c-0.1,0-0.2-0.1-0.3-0.1c0.5,0.1-0.1,0.4-0.2,0.5
        c-0.1,0.3,0.4,0.3,0.5,0.6c0,0.2,0.2,0.4,0.4,0.3c0.3-0.5,0.5-1.1,0.9-1.6c0.2-0.8,0.6-1.6,1.1-2.2c0-0.4,0.1-0.8,0.5-1
        c0-0.1,0-0.2,0-0.3c0.5-0.8,0.6-1.9,1.1-2.7C281.2,355.7,280.9,355.4,280.5,355.3 M296.9,355.7l0.2,0.1c-0.1,0.2-0.3,0.4-0.4,0.6
        c0.3-0.1,0.5-0.4,0.8-0.5c-0.1,0-0.2-0.1-0.2-0.1c0-0.1,0-0.2,0-0.3C297.2,355.5,297.1,355.6,296.9,355.7 M304.4,355.9
        c0.2,0.1,0.3-0.2,0.3-0.3C304.5,355.4,304.3,355.7,304.4,355.9 M172.7,356.1C172.7,356.2,172.7,356.2,172.7,356.1 M304.4,356.1
        c-0.1,0.2,0,0.3,0.2,0.3C304.7,356.1,304.6,356,304.4,356.1 M206.2,356.3c0,0.1,0.1,0.3,0.1,0.4c-0.2,0.6,0.4,1.1,0.8,1.4
        c-0.1-0.3-0.2-0.6-0.4-0.9c0-0.2,0-0.4-0.2-0.6C206.5,356.5,206.4,356.3,206.2,356.3 M244.2,356.8c0.1-0.1,0.2-0.2,0.3-0.2
        c0.1,0.1,0.2,0.2,0.3,0.2C244.7,356.3,244,356.3,244.2,356.8 M174.1,356.7c-0.1,0.2,0,0.3,0.3,0.2
        C174.4,356.7,174.3,356.6,174.1,356.7 M278.6,357.1l0.3,0l0,0.3l-0.1,0c-0.1,0.2-0.3,0.4-0.3,0.7c-0.1,0.1-0.3,0.2-0.4,0.3
        c-0.1,0-0.2,0.1-0.3,0.2l0.4,0c-0.1,0.3-0.5,0.5-0.6,0.1c0.1,0.2,0.1,0.3,0.1,0.5c-0.3,0.3-0.6,0.6-0.9,0.9
        c-0.2,0.1-0.3,0.5-0.7,0.5c0,0.1,0,0.3,0,0.4c-0.2,0-0.4,0.1-0.5,0.3c0.3,0,0.5,0.3,0.8,0.3l0,0.1l-0.2,0c0.1,0.2,0.1,0.6,0.4,0.6
        c0.1-0.3,0.3-0.5,0.4-0.8c0.2-0.5,0.4-1,0.8-1.3c-0.2-0.3,0.1-0.7,0.3-0.9c0.1-0.3,0.3-0.6,0.3-0.9c0.3-0.1,0.3-0.5,0.5-0.7
        c0-0.2,0-0.4-0.1-0.6C278.7,357,278.6,357.1,278.6,357.1 M281.6,357.7c0.1,0.2,0.2,0.2,0.4,0.2c0,0.1-0.1,0.2-0.1,0.3l0.1,0
        c0.1-0.2,0.1-0.3,0.2-0.5C282,357.7,281.8,357.7,281.6,357.7 M304.7,358.2c0,0.2,0.5,0.3,0.5,0C305.1,358,304.7,358,304.7,358.2
        M216.9,358.6c0.1,0.1,0.2,0.2,0.3,0.3c0-0.2,0-0.5,0-0.7C217.1,358.3,216.8,358.4,216.9,358.6 M281.7,358.2
        c-0.1,0.1-0.1,0.3-0.2,0.4c0.1,0.1,0.3,0.3,0.4,0.4c0-0.1,0.1-0.3,0.1-0.4c-0.1,0-0.3,0-0.4,0l0-0.2c0.1,0,0.3,0,0.4-0.1
        C281.9,358.3,281.8,358.3,281.7,358.2 M304.5,358.2c-0.2,0.1-0.2,0.2,0,0.3C304.7,358.4,304.7,358.3,304.5,358.2 M203.1,358.3l0,0.1
        c-0.3,0-0.3,0.2-0.3,0.4c-0.1,0-0.2,0-0.3,0l0.1,0.2c-0.3-0.1-0.3,0.2-0.4,0.4c-0.2,0.1-0.3,0.3-0.4,0.4c0.8-0.1,1.2-0.8,1.8-1.3
        l0-0.1c-0.1,0.1-0.3,0.1-0.4,0.2L203.1,358.3L203.1,358.3 M203.7,358.4C203.7,358.4,203.7,358.4,203.7,358.4 M237.1,359.1l-0.1,0
        c0,0.2,0,0.5-0.1,0.7c0,0.1-0.2,0.2,0,0.3c-0.1,0.2-0.3,0.5-0.4,0.7c0,0.2,0,0.3,0,0.5c-0.1,0.1-0.1,0.3-0.2,0.4
        c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.2,0.4-0.4,0.7c0.1,0,0.3-0.1,0.4-0.1c-0.1,0.2-0.2,0.4-0.4,0.5c0.2,0.3-0.3,0.5-0.3,0.8
        c0,0.3,0,0.6-0.2,0.7l-0.1-0.3c-0.1,0-0.1,0.1-0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c-0.3,0.1-0.3,0.4-0.3,0.7c-0.1,0-0.2,0-0.3,0
        c-0.1,0.2,0,0.5-0.2,0.6c-0.1,0.5-0.4,0.8-0.6,1.2c-0.1,0.2-0.2,0.5-0.5,0.5c0,0.1,0,0.4,0,0.6l0.2,0c0,0.1,0,0.3,0,0.3
        c-0.1,0-0.3-0.1-0.4-0.1c0,0,0,0.1,0,0.2c0.1,0,0.2-0.1,0.3-0.1c-0.1,0.2,0,0.3,0.1,0.4c0.2-0.7,0.5-1.3,0.7-1.9
        c0.2-0.5,0.1-1,0.5-1.4c0.3-0.2,0.1-0.7,0.4-0.9c0.4-0.4,0.2-1.1,0.7-1.5c0.2-0.2,0.1-0.5,0.1-0.7c0.1,0,0.2,0,0.3,0
        c0.1-0.7,0.4-1.4,0.9-2c-0.1-0.1-0.2-0.2-0.3-0.3c0,0,0-0.1,0.1-0.1c0.1,0,0.3,0.1,0.4,0.2c-0.2-0.3-0.2-0.6-0.1-0.9
        c0-0.1-0.1-0.3-0.1-0.4c0.2,0.1,0.5,0.4,0.8,0.2c-0.4,0-0.4-0.3-0.6-0.6c0-0.1,0-0.3,0-0.4C237.3,358.7,237.2,358.9,237.1,359.1
        M200.6,359.1c0.4,0.1,0.5-0.2,0.6-0.5C201,358.8,200.8,359,200.6,359.1 M304,358.7c0,0.4,0,0.7-0.2,1.1c0.2-0.2,0.4-0.5,0.7-0.6
        c-0.1-0.1-0.3-0.1-0.4-0.2c0.1-0.1,0.2-0.2,0.3-0.2C304.2,358.7,304.1,358.7,304,358.7 M281.5,359.1c0,0.1,0,0.3,0,0.4
        c-0.1,0.2-0.3,0.3-0.3,0.6c-0.1,0.6-0.8,1-0.7,1.7c-0.1-0.1-0.2-0.2-0.3-0.3c0,0.3,0,0.5,0.1,0.8c-0.1,0-0.3,0-0.4,0
        c0,0.1,0.1,0.3,0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.3c0,0.2,0.1,0.3,0.1,0.5c-0.1-0.1-0.2-0.1-0.4-0.2c-0.2,0.2-0.3,0.5-0.3,0.8
        c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.2-0.2,0.3-0.3,0.5c0.2,0.2,0.4,0.4,0.5,0.7c0.2,0.1,0.3,0.2,0.5,0.3c-0.1-0.4,0.1-0.7,0.3-1
        c0.2-0.5,0.4-1,0.6-1.4c0.2-0.3,0.2-0.8,0.5-1.1c0-0.3,0.1-0.7,0.2-1c-0.1,0-0.2,0-0.3,0c0.4,0,0.3-0.6,0.7-0.7c0-0.2,0-0.4,0-0.7
        c0.1-0.2,0.1-0.5,0-0.7C281.6,359.1,281.5,359.1,281.5,359.1 M209.1,359.2C209.2,359.3,209.2,359.3,209.1,359.2 M230.8,359.6
        c-0.1,0.6,0.1,1.3,0,1.9c0,0.2,0,0.4,0.2,0.5c-0.1,0-0.2,0.1-0.2,0.1c0.1,0,0.2,0,0.3,0l-0.2,0.3c0.1,0,0.3,0,0.3,0
        c-0.4,0.3-0.1,0.7-0.1,1l0.1,0c0,0.1,0,0.3,0.1,0.4c0-0.1,0-0.3,0-0.4l0.3,0c0,0.5,0.3,1,0.4,1.5l0.1,0c0,0.4,0.1,0.7,0.2,1
        c0.1-0.1,0.2-0.2,0.3-0.3c0,0.1-0.1,0.3-0.1,0.4c0,0.1,0.1,0.2,0.2,0.3l-0.3,0l0.1,0.1c0.1,0,0.2,0,0.3,0c0,0.1-0.1,0.3-0.1,0.4
        c0.4,0.1,0.6,0.5,0.5,0.9c0.3,0.1,0.4-0.2,0.5-0.4c0.3-0.7,0.5-1.4,0.7-2.1c-0.3-0.4-0.7-0.7-1-1.1c-0.2-0.3-0.5-0.5-0.6-0.9
        c-0.4-0.4-0.6-0.8-0.9-1.3c-0.1-0.3-0.3-0.6-0.1-0.8C231,360.9,231.3,360,230.8,359.6 M209.5,359.8
        C209.6,359.9,209.6,359.9,209.5,359.8 M245.9,359.8c-0.1,0.2,0.2,0.3,0.3,0.2C246.3,359.8,246,359.6,245.9,359.8 M262.4,359.9
        C262.4,360.4,262.9,359.7,262.4,359.9 M286.6,360c0,0.2,0.2,0.4,0.4,0.4C287,360.3,286.8,359.9,286.6,360 M227.9,360.5
        c0.1,0.1,0.3,0.3,0.4,0.4c0.1-0.2,0.1-0.3,0.2-0.5c-0.1,0.1-0.3,0.2-0.3,0.2l0-0.2C228.1,360.5,228,360.5,227.9,360.5 M227.6,360.8
        c-0.1,0.5-0.6,0.8-0.8,1.2c0.1-0.2,0.3-0.3,0.4-0.5c0.1,0,0.2,0,0.3,0c-0.2,0.3-0.3,0.5-0.4,0.8c-0.1,0-0.2-0.1-0.3-0.1
        c0,0.1,0.1,0.2,0.1,0.3l-0.2,0c0,0.2,0,0.4,0,0.7c-0.1,0.1-0.2,0.2-0.3,0.3c0.4,0.1,0.5-0.4,0.5-0.7c0.5-0.1,0.6-0.6,0.7-1
        C227.6,361.4,227.7,361.1,227.6,360.8 M262.4,361.1c-0.1,0.2,0,0.3,0.2,0.2C262.7,361.1,262.6,361,262.4,361.1 M179.9,361.2
        C180,361.3,180,361.3,179.9,361.2 M251.1,361.4C251.1,361.8,251.5,361.1,251.1,361.4 M246.9,361.7l0,0.3l-0.3,0
        c0.1,0.1,0.2,0.2,0.3,0.3C247.2,362.1,247.1,361.8,246.9,361.7 M287.7,361.8C287.8,361.9,287.8,361.9,287.7,361.8 M246.6,362.4
        c-0.1,0.2,0,0.3,0.2,0.2C246.9,362.4,246.8,362.3,246.6,362.4 M263.3,363.1c-0.1,0.2-0.2,0.5,0.2,0.3
        C263.5,363.3,263.4,363.1,263.3,363.1c0.5,0.3,0.6-0.5,0.3-0.6C263.5,362.6,263.4,362.8,263.3,363.1 M273.7,362.8
        c0,0.2,0.1,0.4,0.1,0.6l-0.1,0.1c0,0.9,0.6,1.7,0.3,2.6c0.3,0.9,0.5,1.9,0.6,2.9c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0.1,0.3,0.1,0.5
        c0,0.1,0.1,0.2,0.1,0.3l0.1,0c0,0.2,0,0.4,0,0.6l0.2,0c0,0.1,0,0.2,0,0.3c0.1,0,0.2,0.1,0.3,0.2c-0.1,0-0.3,0-0.4,0
        c0.1,0.1,0.2,0.3,0.3,0.3c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.2,0.2,0.3,0.4c0,0.1,0,0.4-0.1,0.5l0.2-0.1c0,0.1,0,0.4,0,0.5
        c0.1,0,0.2,0,0.2,0c0,0.4,0.2,0.7,0.4,1.1c-0.4-0.4-0.7-0.9-1.1-1.2c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.1-0.3-0.3-0.3-0.5
        c0,0-0.1,0-0.1,0c0.1,0.3,0,0.6-0.1,0.9c0.1,0.2,0.1,0.5,0.2,0.8c0,0.1,0.4,0.2,0,0.3c0.4,0.3,0.8,0.8,0.8,1.4
        c0.1,0,0.3-0.1,0.3-0.2c0,0.2,0,0.3,0,0.5c0.1-0.1,0.2-0.2,0.3-0.3c-0.1-0.1-0.2-0.2-0.3-0.3c0.5-0.5,0.4-1.3,0.8-1.8
        c0.1-0.6,0.6-0.9,0.7-1.5c0.1-0.2,0.2-0.3,0.4-0.5c0-0.3,0-0.6,0-0.8c0.2-0.6,0.2-1.2,0.7-1.6c0-0.1,0-0.3,0-0.4
        c0.1-0.1,0.2-0.2,0.3-0.3c0-0.5,0.6-1.2,0.1-1.6c-0.2,0.4-0.6,0.7-0.7,1.2c-0.4,0.5-0.7,1.1-1.1,1.5c0.3-0.3,0.4-0.7,0.6-1
        c0.1-0.2,0.2-0.4,0.3-0.5c0-0.5,0.5-0.8,0.5-1.3c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.5-0.2c0-0.1,0-0.2,0.1-0.2
        c-0.3,0-0.3-0.3-0.4-0.5c0,0-0.1,0-0.1,0c0,0.1-0.1,0.2-0.1,0.2c-0.4,0.2-0.8,0.4-1.2,0.6c0,0,0-0.1,0-0.1c0.4-0.2,0.6-0.5,0.9-0.8
        c-0.2-0.3-0.5-0.8-0.9-0.6l0.1-0.2c-0.1,0-0.2,0.1-0.2,0.1c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1,0-0.3,0-0.5,0.1c0-0.3,0.1-0.7-0.1-1
        c0,0.3-0.1,0.6-0.2,0.9c-0.1-0.1-0.2-0.4-0.3-0.6l0.2,0c0-0.1-0.1-0.2-0.1-0.2c0-0.3-0.1-0.5-0.4-0.6c0.1,0.2,0.3,0.5,0.4,0.7
        c-0.2,0-0.5-0.1-0.6-0.2C273.8,363.3,273.9,363,273.7,362.8 M288.7,362.9C288.8,363,288.8,363,288.7,362.9 M227,363.8l-0.1,0
        c-0.1,0.3-0.3,0.6-0.5,0.8c-0.3,0.3-0.4,0.7-0.7,1c0,0.1-0.1,0.3-0.2,0.4c-0.2,0.3-0.4,0.5-0.5,0.8l-0.2-0.1l0,0.2
        c-0.1,0-0.2,0-0.2,0c0,0.5-0.5,0.6-0.8,1c0.1,0.3,0.2,0.6,0.4,0.7c0.1,0.2,0.2,0.3,0.3,0.5c0.3-0.5,0.9-0.8,0.9-1.4
        c0.6-0.8,1-1.7,1.3-2.6c0.1-0.6,0.4-1.1,0.4-1.7c0-0.1,0.1-0.3,0.1-0.4C227.2,363.3,227,363.5,227,363.8 M251.7,363.1
        c-0.1,0.2,0.2,0.4,0.4,0.3C252.2,363.2,251.9,363,251.7,363.1 M328.7,363.2c0.2,0.1,0.3,0,0.2-0.2C328.7,363,328.6,363,328.7,363.2
        M263.6,363.4c0,0.2,0.4,0.3,0.6,0.3C264.3,363.5,263.8,363.4,263.6,363.4 M247.2,363.5C247,363.9,247.7,363.6,247.2,363.5
        M308.3,363.9c0.1,0.1,0.1,0.2,0.2,0.2c0-0.2,0.4-0.3,0.3-0.6C308.6,363.7,308.4,363.8,308.3,363.9 M282,364.5l0.2,0
        c0,0.3-0.1,0.7-0.1,1c0-0.1-0.1-0.2-0.2-0.2c0,0,0-0.1,0-0.2c-0.1,0.2-0.1,0.3-0.2,0.5l0.1,0c0.3,0,0,0.4,0.1,0.6
        c-0.1,0-0.2-0.1-0.3-0.1c0,0.1,0,0.2,0,0.3l0.2,0c0,0.1-0.1,0.4-0.1,0.5l-0.1,0l0,0.2c-0.1,0-0.3-0.1-0.4-0.1c0,0,0,0.1,0,0.2
        c0.9-0.3-0.5,0.7,0.4,0.5c-0.1,0.1-0.1,0.2-0.2,0.3c0.2,0,0.4,0,0.6,0.1c0-0.1,0-0.3,0-0.5c0.3,0.2,0.7,0.4,1,0.6c0,0,0-0.1-0.1-0.1
        c-0.2-0.2-0.7-0.1-0.5-0.5c0-0.2-0.1-0.3-0.3-0.3c0,0.1,0.1,0.3,0.1,0.5c-0.1,0-0.3-0.1-0.4-0.1c0.1-0.2,0.2-0.4,0.3-0.6L282,367
        c0.1-0.3,0.2-0.6,0-0.8l0.4,0.2l0-0.1l-0.2,0c0-0.2,0-0.5,0-0.6c0.1,0.1,0.2,0.2,0.3,0.3c0-0.1,0-0.2,0-0.3c-0.1,0-0.2,0-0.2,0
        c0-0.2,0-0.5,0-0.6c0.2,0.1,0.3,0.2,0.5,0.3c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2,0-0.4-0.1-0.3-0.3c-0.2-0.1-0.3-0.3-0.2-0.6
        C282.1,364.2,282.1,364.3,282,364.5 M189.6,364.3C189.7,364.4,189.7,364.4,189.6,364.3 M211.4,364.8
        C211.5,364.9,211.5,364.9,211.4,364.8 M228.1,365L228.1,365c0,0.4-0.5,0.4-0.6,0.7c0.3,0.2-0.2,0.6,0,0.9c-0.1-0.1-0.2-0.2-0.3-0.2
        c0,0.3-0.2,0.5-0.3,0.8c-0.3,0.8-0.6,1.6-0.9,2.3c-0.1,0.3-0.1,0.7-0.5,0.7c0.1,0.3,0.2,0.6,0.3,0.9c0.1-0.1,0.2-0.3,0.2-0.4
        c0.2-0.1,0.3-0.3,0.3-0.5c0.2-0.6,0.6-1,0.9-1.5c-0.1-0.5,0.5-0.9,0.6-1.4c-0.2,0.1-0.6,0.2-0.5,0.5c0-0.1-0.1-0.1-0.1-0.2
        c0.2-0.1,0.3-0.3,0.4-0.5c0.1,0,0.3,0.1,0.4,0.1c-0.1-0.4-0.3-0.5-0.6-0.3c0.1-0.1,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.3-0.2
        c-0.1-0.3,0.1-0.8,0.4-0.8c0-0.1,0-0.2,0-0.3c-0.3,0.3-0.6,0.6-0.8,1c0-0.2,0-0.5,0-0.7c0.1-0.1,0.3-0.2,0.4-0.4
        c0-0.1,0.1-0.2,0.2-0.3c0,0-0.1-0.1-0.1-0.1C228.3,364.8,228.2,364.9,228.1,365 M211.8,365C211.8,365.5,212.2,364.8,211.8,365
        M252.5,365.1c-0.2,0.1-0.2,0.2,0,0.3C252.7,365.2,252.7,365.2,252.5,365.1 M302.8,365.2c0,0.1-0.1,0.3-0.1,0.3
        c-0.1,0-0.2,0.1-0.2,0.1c0.1,0,0.2,0.1,0.3,0.1l0-0.3c0.2,0.1,0.3,0.2,0.5,0.3c0-0.2-0.1-0.4-0.1-0.5
        C303,365.3,302.9,365.2,302.8,365.2 M232.1,365.8c-0.1,0.2,0.1,0.5,0.3,0.4C232.5,366.1,232.3,365.7,232.1,365.8 M303.3,365.8
        c0,0-0.1,0.1-0.2,0.2C303.3,366.2,303.7,365.8,303.3,365.8 M278,366.2C278,366.7,278.5,366,278,366.2 M301.9,366.5
        C301.9,366.9,302.4,366.3,301.9,366.5 M248.1,366.5c0.2,0.3,0.3,0.8,0.7,0.9c0.1-0.3-0.2-0.4-0.4-0.5
        C248.4,366.7,248.3,366.6,248.1,366.5 M323.7,366.6C323.7,367.1,324.1,366.4,323.7,366.6 M228.2,366.8
        C228.3,366.9,228.3,366.9,228.2,366.8 M230.9,366.8l-0.1,0.2l0.2,0c0,0.2,0,0.3,0,0.5c0.1-0.1,0.2-0.1,0.3-0.2c0,0.1,0,0.4-0.1,0.5
        c0.1-0.1,0.3-0.1,0.4-0.2c0,0.1-0.2,0.3-0.2,0.4c0.1,0,0.2,0,0.3,0c0,0.2,0,0.5,0,0.8l0,0c0.1-0.5,0.2,0,0.4,0.2c-0.1,0-0.3,0-0.3,0
        c0.1,0.1,0.3,0.3,0.4,0.4c0,0.1-0.1,0.4-0.1,0.5l0.3,0l0-0.2l0.1,0c-0.1,0.2-0.2,0.4-0.3,0.6c0.1,0.1,0.2,0.2,0.3,0.2l-0.2,0.1
        c0.1,0,0.3,0,0.4,0c-0.1,0.1-0.2,0.3-0.2,0.4c0.1-0.1,0.2-0.2,0.3-0.2c0,0.1,0,0.3,0.1,0.4c0,0,0.2-0.1,0.2-0.1
        c0.1,0.4,0.3,0.8,0.2,1.2c0.2-0.1,0.3-0.4,0.4-0.6c0.2-0.5,0.6-1,0.8-1.5c0.2-0.5,0.6-1,0.7-1.6c0.3-0.2,0-0.3-0.1-0.5
        c-0.1,0.2-0.3,0.4-0.5,0.5c0.2-0.1,0.4-0.2,0.5-0.1c-0.1,0.1-0.2,0.3-0.3,0.4c0,0-0.1,0-0.1,0c0,0.2-0.1,0.6-0.3,0.3
        c0.1,0.3-0.1,0.5-0.2,0.7c-0.4,0-0.6,0.5-1.1,0.5c0.1-0.3,0.3-0.6,0.6-0.8c-0.3-0.3-0.5-0.6-0.8-1c-0.3-0.1-0.3-0.4-0.6-0.4
        c-0.1-0.5-0.6-1-1.1-1C231,367,231,366.9,230.9,366.8 M264.6,367c0.1,0.2,0.1,0.4,0.1,0.6c0.1,0,0.2,0.1,0.3,0.1l0.1-0.2l0.2-0.1
        c0,0-0.1-0.1-0.2-0.2C265.2,366.8,264.8,367,264.6,367 M308.3,367c0,0.3,0.4,0.3,0.6,0.6c-0.2,0.2-0.4,0.3-0.5,0.5
        c0.1-0.3,0-0.6-0.1-1c-0.2,0.1-0.3,0.3-0.4,0.5c0.1,0.1,0.2,0.2,0.4,0.3c-0.1,0-0.3,0-0.4,0l0.1,0.3c-0.1,0-0.3,0-0.3,0
        c0,0.1,0.1,0.3,0.1,0.4c-0.3-0.2-0.6,0.3-0.2,0.4c-0.3,0.3-0.4,0.7-0.5,1.1c-0.1,0-0.2,0-0.2,0c0.2,0.4-0.3,0.7-0.5,1
        c0.2-0.1,0.3-0.2,0.5-0.3c0,0.2,0,0.4,0,0.7c0.1-0.2,0.1-0.3,0.1-0.5c0.4,0.1,0.6-0.5,1-0.6c0.1-0.3,0.2-0.6-0.1-0.8
        c0.1,0.1,0.3,0.2,0.5,0.3l0-0.5c-0.1,0-0.2,0-0.2,0.1c0.2-0.1,0.4-0.4,0.1-0.5c-0.2-0.7,0.9,0.5,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.3
        c-0.2,0-0.4,0-0.6,0c0.2-0.1,0.4-0.4,0.5-0.6C308.8,367.4,308.6,367.1,308.3,367 M236.5,368.1c-0.3,0.4-0.4,0.9-0.6,1.3
        c-0.1,0.1-0.2,0.3-0.3,0.4c0.4-0.2,0.4-0.7,0.7-0.9c0-0.1,0-0.2,0-0.3c0.3-0.1,0.4-0.5,0.5-0.7c0.1-0.2,0.4-0.4,0.4-0.7
        C236.8,367.4,236.8,367.8,236.5,368.1 M286.4,367.2c0.1,0.3,0.3,0.5,0.6,0.8C286.9,367.7,286.6,367.4,286.4,367.2 M248.5,367.5
        C248.6,367.6,248.6,367.6,248.5,367.5 M253.3,367.4l0,0.1C253.6,367.8,253.6,367.2,253.3,367.4 M311.3,367.4L311.3,367.4
        c0.2,0.4,0.4,0.7,0.7,0.9c0,0.1,0,0.3,0,0.4c0.1,0,0.2,0,0.2,0l-0.1,0.3c0.1,0,0.2,0,0.3,0.1c0,0.2,0.1,0.4,0.3,0.5
        c0,0.1,0.1,0.2,0.2,0.3l0,0.1c0.2,0.1,0.3,0.2,0.5,0.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1-0.1-0.2-0.3-0.3-0.4c0.1,0.2,0.1,0.4,0.2,0.6
        c0.1-0.1,0.2-0.1,0.4-0.2c0,0.1,0.1,0.3,0.2,0.4l0.3,0c0.1,0.6-0.5,0.3-0.7,0.2c-0.7-0.3-1.4-0.8-2.1-1.1l0,0.4l0.2,0l0,0.2
        c0.1,0,0.4,0,0.5,0c-0.1,0.1-0.2,0.2-0.2,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0,0,0.1-0.1,0.2-0.1c0.1,0.1,0.2,0.2,0.4,0.3c0,0,0,0.1,0,0.1
        c-0.1-0.1-0.3-0.2-0.3-0.2l0,0.3c0.1,0,0.3,0.1,0.4,0.1c0,0.1,0,0.4,0,0.5c-0.1-0.1-0.4-0.2-0.5-0.3c0.2,0.2,0.4,0.3,0.6,0.6
        c0.1,0,0.2,0.1,0.3,0.1c-0.2-0.3-0.4-0.6,0-0.9l0.1,0.4c0.1-0.1,0.2-0.2,0.3-0.3c-0.1,0-0.2-0.1-0.2-0.2c0.1,0,0.3,0.1,0.4,0.2
        c0-0.1,0-0.2,0.1-0.3c0.1,0,0.2,0.1,0.2,0.1c0.1-0.3,0.3-0.5,0.6-0.7c-0.1-0.3,0.1-0.4,0.3-0.6c-0.2-0.1-0.3-0.2-0.5-0.3
        c-0.3-0.5-0.9-0.5-1.1-1c-0.1,0-0.2-0.1-0.2-0.1c-0.3-0.2-0.5-0.5-0.7-0.8c-0.1-0.1-0.2-0.2-0.2-0.3
        C311.6,367.6,311.4,367.5,311.3,367.4 M322.4,367.5C322.4,367.9,322.8,367.2,322.4,367.5 M215,367.6c-0.2,0.1-0.2,0.2,0,0.3
        C215.2,367.8,215.2,367.7,215,367.6 M196.8,368.1C196.9,368.5,197.3,367.8,196.8,368.1 M231.4,368.2
        C231.1,368.6,231.8,368.3,231.4,368.2 M287.3,368.3C287.4,368.4,287.4,368.4,287.3,368.3 M217.6,368.7c0,0-0.1-0.1-0.2-0.2
        C217,368.6,217.5,369.1,217.6,368.7 M281.8,369c0,0.3,0.1,0.6-0.3,0.5c0,0.1,0,0.3,0,0.4c0-0.1,0.1-0.2,0.1-0.3
        c0.3,0.2,0.1,0.4-0.2,0.5l0,0.1c0.1,0,0.2,0,0.3-0.1c-0.1,0.2-0.2,0.4-0.2,0.7c-0.1-0.2-0.1-0.3-0.2-0.4c-0.3,0-0.1,0.4-0.2,0.5
        c-0.2,0.1-0.4,0.3-0.5,0.5c-0.2,0-0.4,0.1-0.5,0.3c0.1-0.1,0.3-0.2,0.5-0.2l0,0.4c0.1-0.1,0.1-0.3,0.1-0.5c0.2-0.2,0.4-0.4,0.7-0.4
        c0,0.1,0,0.4,0,0.5l0.2,0c0,0,0,0.1,0,0.1l-0.2,0c0.1,0.3,0.2,0.7,0.2,1c-0.1,0-0.3-0.1-0.4-0.1c0,0.3,0,0.6,0.1,0.9l-0.2,0
        c0-0.1,0.1-0.3,0.1-0.3c-0.1,0-0.2,0.1-0.3,0.1c0.1,0.3,0.2,0.7-0.1,1c0.3,0.2,0.1,0.6-0.3,0.5c0,0.1,0,0.2-0.1,0.2l0.2-0.1l0,0.4
        l-0.2-0.1c0,0,0,0.1,0,0.2l0.3,0c-0.2,0.2-0.5,0.5-0.3,0.8l-0.2-0.1c0,0,0.1,0.1,0.2,0.1c-0.1,0.1-0.2,0.3-0.2,0.3
        c-0.1,0-0.2,0-0.3-0.1c0,0,0,0.1,0,0.1l0.2,0l0,0.4l-0.2,0c0.1,0.3,0,0.5-0.3,0.7c0,0,0.1,0.1,0.2,0.2c-0.1,0.1-0.2,0.3-0.2,0.3
        c-0.1,0.3-0.3,0.5-0.2,0.8c0-0.1-0.1-0.3-0.2-0.3c-0.1,0.2,0,0.4,0,0.6c-0.1,0-0.3,0-0.3,0c0,0.2,0,0.6-0.3,0.7c0,0.2,0,0.4,0,0.6
        c-0.1-0.1-0.2-0.1-0.3-0.2c0,0.4-0.3,0.6-0.2,1c0,0.1-0.1,0.2-0.2,0.2c-0.1,0.2-0.2,0.3-0.4,0.3c0.1,0.3-0.1,0.5-0.2,0.7l0.2,0
        c0.1,0.3,0.2,0.6,0.2,0.9l0.2,0c0.1-0.3,0.4-0.5,0.5-0.9c0.4-0.7,0.6-1.6,1.1-2.2c-0.1-0.2-0.1-0.5-0.1-0.8l0.2,0
        c0,0-0.1-0.1-0.1-0.2l0.2,0c0-0.1,0-0.3,0-0.4c0.1,0.1,0.1,0.3,0.2,0.4c0.2-0.2,0.4-0.3,0.6-0.4l-0.2,0c0.5-0.5,0.1-1.2,0.3-1.7
        c0.2-0.6,0.2-1.2,0.3-1.8c0-0.4,0.4-0.7,0.4-1.1c0-0.3,0.3-0.6,0.2-0.9c0.1-0.3,0.2-0.5,0.2-0.8c0.1,0,0.2-0.1,0.3-0.2
        c0-0.1-0.1-0.3-0.1-0.4c0.2-0.3,0.1-0.7,0.3-0.9c-0.1-0.2-0.2-0.4-0.3-0.6c0,0,0.1,0,0.2,0l0,0.2l0.3,0c-0.1-0.1-0.2-0.4-0.2-0.6
        l0.2,0c0-0.2-0.1-0.3-0.1-0.5c-0.1-0.1-0.3-0.2-0.4-0.2c0-0.1,0.1-0.3,0.2-0.4C282,369.1,281.8,369.1,281.8,369 M286,369l0,0.4
        l0.2,0c0,0.4,0.3,0.6,0.6,0.9c-0.2,0-0.4,0-0.6-0.1c0.1,0.2,0.2,0.3,0.4,0.3l-0.1,0.2c0.1-0.1,0.2-0.3,0.3-0.4
        c0.3,0.2,0.6,0.9,1,0.5c0.1,0,0.2,0,0.2,0l0,0.2c0.2-0.1,0.4-0.2,0.6-0.2c-0.6-0.6-1.5-0.7-2-1.4C286.5,369.2,286.2,369.1,286,369
        M254,369.3c-0.1,0.2,0,0.3,0.2,0.2C254.3,369.3,254.2,369.2,254,369.3 M230.8,370c0,0.3,0,0.7-0.1,1c0.1,0.3,0.3,0.6,0.3,1
        c0.1,0,0.2,0.1,0.2,0.1l0.1,0.1c-0.1,0-0.3,0-0.4,0c0.2,0.2,0.3,0.3,0.5,0.4c-0.1,0-0.2,0.1-0.2,0.1c0,0.1,0,0.2,0,0.3
        c0.1,0,0.3,0.1,0.4,0.1c-0.1,0.2-0.2,0.3-0.3,0.5l0.3,0.1c-0.1,0.1-0.2,0.1-0.3,0.2c0.2,0.1,0.4,0.2,0.5,0.4
        c0.2,0.2,0.4,0.3,0.6,0.5c-0.1,0-0.3,0-0.4,0c0.2,0.2,0.3,0.4,0.5,0.6c0-0.5,0.3-0.9,0.6-1.3c-0.2-0.1-0.3-0.1-0.5-0.1
        c0-0.1-0.1-0.2-0.2-0.2c-0.1,0.2-0.2,0.4-0.3,0.6c-0.2-0.1-0.3-0.2-0.5-0.3c0.1,0,0.3-0.1,0.4-0.1c0.4-0.3-0.2-0.6-0.3-0.9
        c-0.4-0.7-0.5-1.5-1-2.2C230.9,370.4,231,370.2,230.8,370 M235.1,370.2c0,0.2,0,0.4,0.1,0.6l-0.2,0.1c-0.1,0.6-0.6,0.9-0.9,1.4
        c0,0.3-0.2,0.1-0.4,0.1c-0.1,0.1-0.4,0.4-0.1,0.5l-0.2,0.2l-0.1-0.1c-0.4,0.1,0.1,0.3,0.1,0.5c0-0.1-0.1-0.3-0.1-0.4
        c0.4,0.2,0.4-0.1,0.4-0.3l0.2,0c0.4-0.5,0.9-1,1.3-1.5c0.1-0.2,0.2-0.4,0.1-0.6c-0.1-0.2,0-0.4,0.2-0.4
        C235.5,370.1,235.2,370,235.1,370.2 M264.9,370.1c0.2,0.1,0.3,0.3,0.4,0.4c0-0.1,0.1-0.2,0.1-0.3C265.3,370.2,265,370,264.9,370.1
        M242.1,370.2C242.1,370.3,242.1,370.3,242.1,370.2 M220.3,370.9c0.1,0,0.2-0.1,0.3-0.1c0.3,0.4,0.9,0.2,1.2,0.5
        c-0.1-0.1-0.3-0.4-0.4-0.5c-0.3,0.1-0.5,0-0.7-0.2C220.4,370.6,220.2,370.6,220.3,370.9 M222.1,370.9c0,0.1-0.1,0.2-0.2,0.3
        c-0.1,0.4,0.7,0.5,0.1,0.7c0,0,0,0.1,0,0.1c0.3-0.5,0.6,0.4,0.9,0.1c-0.1-0.2-0.2-0.3-0.2-0.5c-0.5,0-0.5-0.6-0.3-0.9
        C222.3,370.7,222.2,370.8,222.1,370.9 M237.7,371c-0.1-0.1-0.3-0.2-0.4-0.3c0.2,0.2-0.2,0.3-0.3,0.4c0.4,0.2,0.2,0.6,0,0.9
        c0.1,0.1,0.2,0.2,0.3,0.3c-0.2,0-0.4-0.1-0.5-0.3c0,0.1,0,0.3,0,0.4c-0.1,0.2-0.3,0.4-0.5,0.5c0,0.1,0.1,0.2,0.1,0.3l-0.1,0
        c0-0.1,0-0.2-0.1-0.3c-0.3,0.1-0.2,0.4-0.2,0.6c-0.2,0.1-0.1,0.6-0.4,0.5c0.1,0.2,0,0.5-0.3,0.4c0,0.1,0.1,0.2,0.1,0.3l-0.2,0
        c0.1,0.6-0.9,0.6-0.8,1.2c-0.1-0.1-0.2-0.2-0.2-0.3c0,0.2,0,0.3,0,0.5c-0.2,0.1-0.3,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.4,0.2
        c-0.4,0.4,0,0.9,0.1,1.3c0.1-0.2,0.3-0.3,0.4-0.5c0.2-0.5,0.7-1,1-1.5c0.1-0.4,0.5-0.7,0.8-1.1c0.1-0.4,0.6-0.6,0.6-1
        c0.2-0.6,0.9-0.8,1-1.4c0.1-0.2,0.4-0.3,0.4-0.6c-0.1,0-0.2,0-0.3,0c-0.1-0.2-0.1-0.4-0.2-0.6c0.2,0.1,0.4,0.2,0.6,0.3
        c0-0.3,0.1-0.5,0-0.8c-0.1-0.1-0.3-0.3-0.4-0.4C237.7,370.7,237.7,370.9,237.7,371 M254.6,370.6c0,0.2,0,0.5,0.2,0.6
        C255.1,371.1,254.8,370.6,254.6,370.6 M240.6,370.8c-0.1,0.2-0.1,0.4,0.1,0.5C241,371.3,240.9,370.7,240.6,370.8 M287.8,371
        C287.9,371.1,287.9,371.1,287.8,371 M222.7,371.2C222.8,371.3,222.8,371.3,222.7,371.2 M221,371.3c0,0.2,0.1,0.5,0.4,0.3
        c0.1,0.2,0.2,0.4,0.3,0.6c0.2-0.2,0-0.5,0-0.7C221.4,371.6,221.2,371.4,221,371.3 M308.8,371.9C308.6,372.3,309.3,372,308.8,371.9
        M227.8,372.1c0.1,0.3,0,0.7-0.1,1c0,0.4,0.5,0.4,0.6,0.8c0,0,0.1,0,0.1,0c0-0.2,0-0.4-0.2-0.4c-0.2-0.2-0.3-0.5-0.2-0.8
        c0.1,0.1,0.2,0.3,0.3,0.4c0,0,0.1,0,0.2,0c-0.2-0.3-0.3-0.7-0.4-1C228,372.1,227.9,372.1,227.8,372.1 M228.5,372.5
        c-0.1,0.2,0,0.3,0.2,0.2C228.8,372.4,228.7,372.4,228.5,372.5 M204.7,372.7c0.1,0.2,0.3,0.2,0.4,0
        C205,372.5,204.8,372.5,204.7,372.7 M279.8,372.6c-0.2,0.1-0.2,0.2,0,0.3C279.9,372.8,279.9,372.7,279.8,372.6 M242.6,373.3
        C242.7,373.8,243,373,242.6,373.3 M272.2,373.5C272.3,373.6,272.3,373.6,272.2,373.5 M283,373.5c-0.2,0.1,0,0.5,0.2,0.3
        C283.3,373.7,283.2,373.4,283,373.5 M272.2,374c0,0.3-0.1,0.6-0.5,0.6l0,0.1c-0.1,0-0.2,0-0.3,0c0.1,0.2,0.3,0.4,0.3,0.7
        c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2,0.2,0,0.3,0.2,0.3c0,0.1-0.1,0.3-0.2,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c0.1,0,0.3,0.1,0.4,0.2
        c-0.1,0-0.3,0.1-0.4,0.2c0,0.1,0.3,0.2,0.1,0.3c-0.3,0.1-0.3,0.4-0.5,0.6c-0.2,0-0.3,0.1-0.5,0.1c0.3,0,0.5,0.2,0.3,0.5
        c-0.3,0.1-0.6,0.3-0.8,0.6c0.2-0.1,0.4-0.1,0.6-0.2c0,0.2-0.1,0.4-0.3,0.6c0.2-0.2,0.3-0.3,0.5-0.5l-0.3,0c0-0.3,0.1-0.5,0.4-0.5
        c0-0.1,0-0.2,0-0.3c0.3-0.1,0.4-0.4,0.5-0.7c0.6-0.4,0.8-1.1,0.9-1.7c0.2,0,0.3-0.1,0.4-0.2c-0.2,0-0.5-0.1-0.6-0.4
        c0.3,0,0.4-0.3,0.5-0.5c0.2-0.2,0.3-0.5,0.4-0.7C272.4,373.7,272.3,373.8,272.2,374 M237.9,374.4c-0.1-0.1-0.2-0.2-0.3-0.3
        c0.1,0.3,0.4,0.5,0.2,0.8c0-0.1-0.1-0.2-0.1-0.3c-0.1,0-0.3-0.1-0.3-0.2c0,0.1,0,0.3,0,0.4c-0.2,0.1,0,0.5-0.3,0.6
        c0-0.1,0-0.4,0-0.5c-0.2,0.4-0.5,0.8-0.7,1.2l-0.2-0.1c0,0.1,0.1,0.2,0.1,0.3c-0.3,0-0.2,0.3-0.5,0.4l0,0.1c-0.1,0-0.2-0.1-0.3-0.1
        c0,0.1,0,0.3,0.1,0.5c-0.3,0-0.2,0.3-0.3,0.5c-0.2,0-0.5-0.1-0.6,0.1c0.1,0,0.3,0,0.4,0c-0.3,0.3-0.6,0.5-0.8,0.7
        c0.1,0.1,0.3,0.3,0.4,0.4c-0.2-0.2-0.4-0.3-0.7-0.3c0,0,0,0.1,0,0.2c-0.3-0.1-0.3,0.2-0.4,0.3c-0.2,0.1-0.4,0.2-0.5,0.4
        c0.1,0,0.3,0,0.4,0c0,0.4,0,0.9,0.5,1l-0.1,0.1c0.2,0.2,0.5,0.4,0.3,0.7c0,0,0.1,0,0.1,0c0.2-0.8,0.7-1.5,1-2.3
        c0.4-0.2,0.4-0.7,0.6-1.1c0.3-0.2,0.5-0.6,0.6-1c0.5-0.5,0.7-1.2,1.3-1.5c0-0.2,0.1-0.4,0.2-0.5c0-0.1-0.1-0.4-0.1-0.5
        c0.1,0,0.3,0.1,0.4,0.1C238.2,374.6,238.1,374.5,237.9,374.4c0-0.2,0.2-0.4,0.3-0.6C237.9,373.8,238,374.2,237.9,374.4 M207.1,374
        C207.2,374.1,207.2,374.1,207.1,374 M265.7,374c-0.2,0-0.3,0.3-0.1,0.4C265.8,374.4,265.9,374,265.7,374 M278.8,374.2l0.2,0.3
        c-0.2-0.2-0.5-0.1-0.7,0c0.1,0.1,0.2,0.2,0.2,0.3c-0.2,0.3-0.5,0.6-0.7,0.9c0,0.1,0,0.3,0,0.3c-0.1,0-0.2,0-0.3,0
        c0,0.3-0.3,0.3-0.5,0.4c0,0.1,0,0.3,0,0.4c0.3,0.2,0.2,0.6,0.3,0.9c0.2-0.4,0.3-0.8,0.6-1.2c0.3-0.2,0.3-0.6,0.5-0.8
        c0.1-0.3,0.2-0.6,0.4-0.9l-0.2,0c0.2,0,0.4-0.2,0.6-0.3C279.2,374.4,279.2,373.9,278.8,374.2 M285.2,374.3c0,0.2,0,0.5,0.2,0.7
        c0.1,0.1,0.3,0.3,0.4,0.4c0-0.1,0-0.3,0-0.4l0-0.1c0,0,0-0.1,0-0.2C285.5,374.6,285.4,374.3,285.2,374.3 M297.9,374.6
        c-0.2,0-0.4,0.4-0.1,0.4C298.1,375,298.3,374.5,297.9,374.6 M351,374.8C351,374.9,351,374.9,351,374.8 M283,375.4
        C283,375.9,283.4,375.2,283,375.4 M351.7,375.5C351.8,375.6,351.8,375.6,351.7,375.5 M162,375.7C162.1,375.8,162.1,375.8,162,375.7
        M161.7,375.9C161.8,375.9,161.8,375.9,161.7,375.9 M222.2,375.9c0,0.1,0,0.2,0,0.3c-0.1,0-0.2,0-0.2,0l0.3,0
        c-0.1,0.3-0.3,0.5-0.5,0.7c0.4-0.2,0.8-0.5,0.6-0.9l0.3,0C222.5,375.9,222.3,375.9,222.2,375.9 M241,375.9c0.2,0.3,0.4,0.7,0.6,1
        c-0.1,0-0.2,0-0.2,0c0.2,0.2,0.4,0.4,0.6,0.7l-0.4,0.1c0-0.1,0-0.2-0.1-0.3c0,0.1,0,0.3,0,0.4l0.2-0.1c0,0.1,0.1,0.3,0.1,0.4
        c0.2,0.1,0.3,0.2,0.2,0.5c0.1,0,0.3,0,0.4,0c0.1,0.2,0.2,0.3,0.4,0.5c0.2,0,0.1,0.2,0,0.3c0.2,0.2,0.2,0.5,0.2,0.8
        c0.2,0,0.3,0.1,0.5,0.2c-0.1,0-0.2,0-0.3,0c0.3,0.2,0.2,0.4,0,0.6c0.1,0,0.3-0.1,0.3-0.1c0.1,0.3,0.1,0.7,0.3,1c0-0.1,0-0.4-0.1-0.5
        c0.3,0.2,0.4,0.5,0.6,0.8c0.2,0.2,0.3,0.5,0.4,0.7c0.1,0,0.2,0,0.2-0.1c0,0.2,0,0.4,0.1,0.6c0.1,0,0.2,0,0.3,0c0,0.2,0,0.4,0.2,0.6
        c0.2,0.2,0.4,0.4,0.6,0.6c-0.4-0.2-0.9-0.4-1.2-0.7c-0.4-0.3-0.6-0.8-1.1-0.9c-0.2-0.2-0.4-0.4-0.6-0.6c-0.1,0.2,0,0.4,0.2,0.5
        c0,0.3,0.1,0.5,0.3,0.7c0,0.1,0,0.2,0,0.2c0.1,0,0.2,0,0.3,0c0,0.1-0.1,0.2-0.2,0.3c0.1,0,0.3,0,0.5-0.1c-0.1,0.2-0.2,0.3-0.3,0.5
        c0.1-0.1,0.3-0.2,0.3-0.3c-0.1,0.3,0,0.5,0.2,0.5c0,0.1,0,0.3,0,0.4c-0.1,0-0.2,0.1-0.3,0.2c0.1,0,0.3,0,0.4,0c0,0.1,0,0.3,0,0.3
        c0.1,0,0.2,0,0.3,0c0,0.1,0,0.2,0,0.3l0.1,0.1c0,0,0,0.1,0,0.2c0.5,0.5,0.8,1.2,1.3,1.7c-0.3-0.2-0.6-0.4-0.8-0.7
        c-0.4-0.1-0.6-0.5-0.8-0.8c-0.4-0.1-0.6-0.6-1.1-0.7c-0.2-0.3-0.4-0.6-0.7-0.8c0.1,0.1,0.2,0.3,0.2,0.4c0,0.1,0,0.2,0.1,0.3
        c0.2,0.3,0.6,0.5,0.5,0.9c0,0,0.1-0.1,0.2-0.1c0,0.1,0.1,0.2,0.1,0.4c-0.2,0.5,0.4,0.5,0.5,0.9c-0.1,0-0.2-0.1-0.3-0.1
        c0,0,0,0.1,0,0.1c0.3,0,0.8,0.3,0.5,0.7l0.2,0c0.2,0.4,0.4,0.8,0.7,1.2c0.1,0.1,0.2,0.3,0.3,0.4c0,0,0,0.1,0,0.2
        c0.1,0.1,0.2,0.2,0.3,0.4l0.1,0c0,0.2,0.1,0.4,0.2,0.5c0.3-0.1,0.4,0.1,0.5,0.4c-0.2-0.1-0.3-0.2-0.5-0.2c-0.6-0.4-1.1-0.8-1.6-1.2
        c-0.5-0.3-0.8-0.8-1.2-1.1c-0.2,0-0.4,0-0.6,0c0.1,0,0.3,0.1,0.3,0.1c0,0.1-0.1,0.3-0.1,0.4c0.1,0,0.2,0.1,0.2,0.2
        c0-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.3,0-0.4c0.2,0.1,0.3,0.3,0.3,0.5c0.1,0.2,0.3,0.3,0.3,0.6c0.1,0.2,0.3,0.4,0.4,0.6l0.1,0
        c0,0.3,0.3,0.5,0.2,0.8c0.5,0.1,0.1,0.8,0.6,0.8c0,0.1,0.1,0.2,0.1,0.4c0.2,0.3,0.4,0.6,0.7,0.8c0.5,0.4,0.7,0.9,1.2,1.2
        c-0.4-0.3-0.8-0.5-1-0.9c-0.7-0.4-1.2-1.1-1.9-1.5c-0.3-0.3-0.6-0.6-0.9-1c-0.5-0.3-0.8-0.7-1.1-1.1c-0.1-0.2-0.4-0.2-0.4-0.5
        l-0.1,0c0.1-0.5-0.4-0.8-0.9-0.8c0.2,0.2,0.4,0.6,0.7,0.4c0,0.1,0,0.3,0,0.5l0.2,0c0,0.1-0.1,0.2-0.1,0.3c0.2,0,0.3,0,0.5,0.1
        c0.1,0.3,0.2,0.5,0.3,0.7c0,0,0.1,0,0.2,0c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0,0.2,0,0.3,0c0,0.2,0,0.3,0,0.5c0.2,0.1,0.3,0.3,0.4,0.5
        c0.1,0.1,0.2,0.2,0.4,0.3c0,0.2,0.1,0.3,0.2,0.4c0,0.1-0.1,0.2-0.1,0.3c0.1,0,0.2,0,0.2,0c0.3,0.8,1,1.3,1.4,2.1
        c0,0.1,0.1,0.2,0.1,0.2l0.1,0l0,0.2l0.1,0c0.1,0.4,0.6,0.6,0.9,0.9c0.1,0,0.2,0.1,0.2,0.1c-0.4-0.1-0.6-0.4-1-0.4
        c0.3,0.4,0.6,0.7,0.8,1.1c-1.3-1-2.4-2.1-3.6-3.1c0,0.4,0.4,0.7,0.6,0.9c0.6,0.5,0.8,1.2,1.3,1.8c0.2,0,0.4,0.1,0.6,0.2
        c-0.1,0-0.3,0-0.4,0c0.2,0.1,0.3,0.4,0.2,0.7c0.2,0.4,0.6,0.7,1,1c-0.7-0.1-0.1,0.5,0.1,0.6c0.1-0.1,0.2-0.1,0.4-0.1
        c-0.1,0.1-0.2,0.3-0.3,0.4c0.1,0.3,0.4,0.5,0.6,0.8c0.1,0,0.2-0.1,0.3-0.1c0,0-0.1,0.1-0.2,0.2c0,0.2,0.1,0.4,0.2,0.5
        c0.2,0.3,0.2,0.7,0.1,1c0.1,0,0.3,0,0.5,0c0,0,0,0.1,0,0.2c-0.1,0-0.2-0.1-0.3-0.1c0,0.1,0.1,0.2,0.1,0.3c0.4-0.1,0.6,0.3,0.6,0.6
        c0.2,0.2,0.4,0.4,0.6,0.6c-0.1,0.2-0.1,0.3-0.2,0.5c0.2,0.1,0.3,0.3,0.5,0.4c0,0.4,0.2,0.9,0.5,1.2c0-0.3,0.2-1-0.4-1
        c0.4-0.1,0.2-0.5,0.3-0.8c-0.3-0.4,0-1,0-1.5c-0.1,0-0.2-0.1-0.3-0.2c0.2-0.2,0.3-0.4,0.2-0.6c-0.1-0.6-0.2-1.2-0.2-1.8
        c-0.1-0.3-0.1-0.6,0.1-0.9c-0.1-0.2-0.3-0.3-0.2-0.5c0.1-0.6,0.1-1.2-0.2-1.7c0.1-0.1,0.2-0.2,0.3-0.4c-0.1-0.2-0.2-0.3-0.3-0.4
        c0.2-0.7-0.2-1.4,0.1-2.2c0-0.1-0.1-0.2-0.1-0.3c0.1-0.3,0.1-0.7-0.1-1c0.1-0.5,0-1.1-0.1-1.6c-0.1-0.5,0.2-1.1-0.1-1.6
        c-0.3,0-0.5,0-0.8,0c0-0.2,0-0.3-0.1-0.5c-0.1,0-0.2,0.1-0.3,0.1c0-0.3-0.3-0.3-0.5-0.4c0-0.2,0-0.4,0-0.5c0.3,0.1,1.2,0.3,0.6-0.4
        c0.1,0,0.4,0.1,0.5,0.1c-0.1-0.1-0.2-0.3-0.3-0.4c0.1,0,0.3-0.1,0.4-0.1c0-0.1,0.1-0.3,0.1-0.4c-0.1,0-0.2,0.1-0.3,0.1
        c0-0.1,0-0.3,0-0.4l0.2,0c0-0.1-0.1-0.3-0.2-0.3c0.2-0.6,0-1.1-0.1-1.7c-0.1,0-0.2,0.1-0.3,0.1c0.1-0.3,0.2-0.5,0.5-0.7
        c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.2,0.1-0.3,0.2c0.2-0.2,0.6-0.8,0-0.8c-0.2-0.2-0.3-0.5,0.1-0.4c-0.5-0.5-1.1-0.7-1.6-1
        c0.1,0,0.2,0,0.3,0c-0.1-0.2-0.2-0.3-0.4-0.5c0.1-0.1,0.1-0.2,0.2-0.2c-0.1-0.1-0.2-0.2-0.2-0.3l0.2,0c-0.4-0.1-0.7-0.4-1-0.6
        l-0.1-0.1c-0.1,0-0.3,0-0.4,0c0.3,0.2,0.6,0.5,0.8,0.8c-0.3-0.1-0.6-0.3-0.9-0.4c-0.5-0.3-0.9-0.7-1.3-1c-0.2-0.2-0.5-0.3-0.4-0.6
        c-0.6,0.1-0.5-0.7-1-0.8c-0.2-0.4-0.5-0.8-0.7-1.2C241.3,376.3,241.2,376.1,241,375.9 M296.9,376.3c0,0.3,0,0.7-0.4,0.8
        c0.1,0.2,0.2,0.2,0.4,0C296.8,376.8,297,376.5,296.9,376.3 M243.2,376.5C243.2,376.6,243.2,376.6,243.2,376.5 M211.3,377
        C211.1,377.4,211.8,377,211.3,377 M232,377.1c0,0.2-0.3,0.6,0.1,0.6c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.2,0.1,0.3,0.2,0.5
        c0.1,0.1,0.3,0.2,0.5,0.2c-0.1,0.1-0.3,0.2-0.4,0.3c0.1,0,0.3,0,0.4,0c0.2-0.3,0.4-0.6,0.6-0.9c-0.3,0-0.3-0.3-0.5-0.4
        C232.7,377.7,232.4,377.4,232,377.1 M237.7,377.1c0,0.1,0.1,0.3,0.1,0.4c-0.1,0-0.2,0-0.3,0c0,0,0,0.1,0,0.1c0.4,0,0.2,0.3,0,0.5
        c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1,0.2,0,0.4,0.2,0.6c-0.1,0-0.2-0.1-0.3-0.1c0,0.2,0,0.5-0.2,0.7c-0.1,0-0.3,0.1-0.4,0.1
        c0.1,0.2,0,0.4-0.2,0.5c0,0.1,0.1,0.2,0.1,0.2l-0.2,0.1l-0.1,0.3c0.3-0.1,0.4-0.3,0.6-0.6c0.3-0.5,0.8-0.9,1-1.5
        c0.1-0.2,0.3-0.3,0.4-0.5c-0.5,0.1-0.3-0.5-0.3-0.7L237.7,377.1 M238.2,377.6c0.2-0.1,0.2-0.3,0-0.4
        C238,377.4,238,377.5,238.2,377.6 M276,377.5c0,0.1-0.1,0.4-0.1,0.5c0.4-0.6,0.3,0.2,0.7,0.2c-0.2,0-0.4-0.1-0.6-0.1
        c0.1,0.2,0.1,0.6,0.5,0.5c-0.1,0.2-0.1,0.4-0.2,0.6c0,0,0.1,0,0.1,0c0.2-0.2,0.3-0.5,0.1-0.7c0.4,0.2,0.5-0.2,0.7-0.5
        C276.7,377.8,276.4,377.4,276,377.5 M295.7,378c-0.1,0.2,0.2,0.4,0.3,0.3C296.2,378.2,295.9,377.9,295.7,378 M250.7,378.1
        c-0.2,0.1-0.2,0.2,0,0.3C250.9,378.3,250.9,378.2,250.7,378.1 M266.2,378.7c0.1,0,0.2-0.1,0.3-0.1c0-0.1-0.1-0.4-0.1-0.5
        C266.2,378.2,266.2,378.4,266.2,378.7 M271.5,378.4c0,0.2,0.1,0.3,0.2,0.1C271.8,378.3,271.7,378.3,271.5,378.4 M225.8,378.4
        c0,0.1,0,0.2,0,0.2l-0.1,0.1c0,0.1,0,0.2,0,0.3c-0.1,0-0.2,0-0.2,0l0.1,0.3c-0.1,0-0.2,0-0.2,0c0,0.2-0.1,0.4-0.3,0.5
        c-0.1,0.2-0.2,0.5-0.5,0.5c-0.1,0.4-0.3,0.9-0.8,1l0,0.1l-0.2-0.1c0,0.1,0.1,0.2,0.1,0.3c-0.4,0.2-0.7,0.4-1.1,0.6
        c0-0.1,0-0.3-0.1-0.4c0.1,0,0.2,0,0.2,0c0.1-0.4,0.2-0.7,0.4-1c-0.2,0.3-0.3,0.6-0.5,0.9c-0.1,0-0.2,0-0.2,0c0,0.1,0,0.3,0,0.4
        l-0.1,0c-0.1,0.4-0.4,0.6-0.8,0.8c0.2,0.2,0.4,0.4,0.6,0.7c0-0.1,0.1-0.2,0.1-0.2c0,0.3,0.2,0.6,0.3,0.9c0.3,0.1,0.6,0.3,0.9,0.5
        c0.3-0.2,0.6-0.5,0.7-0.8c0.1-0.3,0.4-0.6,0.6-0.9c0.3-0.7,0.8-1.2,1.1-1.8c0.1-0.4,0.4-0.7,0.6-1c0.1-0.4,0.3-0.8,0.5-1.1
        C226.3,378.7,226.1,378.5,225.8,378.4 M294.9,378.9c0.1,0.1,0.3,0.1,0.4,0.1c0.1-0.2,0.2-0.4,0.4-0.5
        C295.4,378.5,295.3,378.8,294.9,378.9 M271.2,379.1c0.2,0.1,0.3-0.2,0.2-0.3C271.2,378.6,271.1,378.9,271.2,379.1 M236.7,378.8
        C236.7,379.3,237.1,378.6,236.7,378.8 M270.9,379.6c0.1,0,0.2,0.1,0.2,0.1c0,0.1,0,0.2,0,0.4c-0.1,0.1-0.2,0.2-0.2,0.3
        c-0.1,0-0.2,0-0.2,0c0,0.1,0,0.2,0,0.3c0.3,0.2-0.2,0.5-0.2,0.7l-0.1,0c0.1,0.3-0.2,0.6-0.2,0.9c-0.1,0-0.2,0-0.2,0l0,0.1
        c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0,0.2,0,0.3,0c0,0.1-0.1,0.3-0.1,0.4c-0.1,0-0.2-0.1-0.3-0.1c0.1,0.4,0,0.7-0.4,0.9
        c0.1,0.1,0.2,0.1,0.3,0.2c0.1-0.1,0.2-0.2,0.3-0.3c-0.1-0.4,0.2-0.6,0.3-1c-0.1-0.1-0.2-0.2-0.3-0.3c0.4,0,0.6-0.5,0.5-0.9l0.2,0.1
        c-0.1-0.3,0.2-0.5,0.4-0.7c-0.2-0.1-0.2-0.3-0.3-0.4c0.1,0,0.2,0.1,0.3,0.1c0-0.1,0.1-0.2,0.2-0.3c0-0.3-0.2-0.7,0.2-0.9
        c0.2-0.1,0.3-0.2,0.3-0.4C271.5,379.2,271.2,379.4,270.9,379.6 M295.1,379c0.2,0.4-0.1,0.4-0.4,0.4c-0.2,0.3-0.4,0.6-0.7,0.8l0-0.3
        c-0.2,0.2-0.4,0.4-0.4,0.7c0.1-0.1,0.2-0.2,0.3-0.3c0.5,0.1,0.6-0.5,1-0.7l0.1,0.3c0.1-0.1,0.3-0.2,0.4-0.2c0-0.2-0.1-0.4-0.1-0.6
        L295.1,379 M303.6,380.5l0.1,0c0-0.1,0.1-0.2,0.1-0.3C303.6,380,303.4,380.4,303.6,380.5 M228.1,380.2c0,0.1,0.1,0.3,0.1,0.4
        c0,0.3-0.2,0.7,0.3,0.7c-0.1-0.1-0.2-0.3-0.3-0.4c0.1,0,0.3-0.1,0.4-0.1l-0.2,0C228.5,380.6,228.3,380.5,228.1,380.2 M250.9,380.3
        C250.7,380.7,251.4,380.3,250.9,380.3 M274.5,380.3c0,0.4,0.2,0.7,0.3,1c-0.1,0.9,0.9,1.4,1,2.3c0.4-0.1,0.2-0.7,0.4-1
        c-0.1,0-0.2,0-0.3-0.1c0-0.1,0.1-0.3,0.2-0.4c-0.1,0-0.3,0.1-0.4,0.2c0.1-0.4-0.3-0.7-0.6-0.9c0.1-0.1,0.3-0.4,0-0.3l0-0.2
        c-0.1,0-0.3,0-0.4,0c0-0.1,0.1-0.3,0.1-0.4C274.7,380.3,274.6,380.3,274.5,380.3 M235.8,380.7l-0.3,0c0,0.1,0.1,0.2,0.1,0.3
        c-0.1,0-0.2,0-0.2,0c0,0.3-0.2,0.4-0.4,0.4c0,0.2-0.2,0.4-0.4,0.4c-0.1,0.3,0.3,0.5,0.5,0.6c0.1-0.3,0.3-0.5,0.4-0.7
        c0.2-0.5,0.7-0.8,0.9-1.3C236,380.3,235.8,380.4,235.8,380.7 M266.7,381.5c-0.1-0.2-0.2-0.5-0.3-0.7
        C266.3,381,266.3,381.5,266.7,381.5 M227.7,380.9c0,0.1,0.1,0.3,0.1,0.3c-0.1,0.1-0.2,0.2-0.4,0.3c0.1,0.4-0.3,0.3-0.5,0.3
        c0.1,0,0.3,0,0.5,0c-0.1,0.3-0.4,0.4-0.6,0.6c0.1,0.2,0,0.5,0,0.7c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1,0.1-0.2,0.2-0.3,0.3
        c0.1,0,0.3,0,0.4-0.1c-0.4,0.4-0.6,1.2-1.2,1.3c0,0.2-0.1,0.3-0.2,0.4c-0.2,0.1-0.4,0.1-0.6,0.3c-0.2,0-0.2,0.1-0.1,0.3
        c0.2-0.2,0.3-0.4,0.6-0.4c0.1,0.3-0.5,0.4-0.4,0.8c-0.3-0.2-0.4,0.1-0.5,0.3c0.7,0,0.9,0.8,1.4,1.2c0.4-0.8,0.6-1.8,1.3-2.5
        c0-0.5,0.4-0.8,0.5-1.2c0.1-0.4,0.6-0.7,0.2-1.2c0.1,0,0.3,0.1,0.5,0.2c-0.1-0.1-0.2-0.2-0.4-0.2c0-0.4,0.1-0.7,0.2-1.1
        c0.1,0,0.2,0,0.2,0C227.9,381.3,227.9,381.1,227.7,380.9 M257.8,382C257.6,382.4,258.3,382,257.8,382 M242.9,382.1
        c-0.2,0-0.3,0.3-0.1,0.4C243,382.5,243.2,382.1,242.9,382.1 M237.6,382.3c-0.1,0.4-0.4,1-0.9,1c0,0.2,0,0.3,0,0.5
        c-0.1,0-0.3,0.1-0.4,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0.2,0.2,0.5,0.4,0.4c0.5-0.3,0.7-0.9,1-1.3c0.5,0.2,0.5-0.5,0.4-0.8
        C238,382.3,237.8,382.3,237.6,382.3 M251,382.4c-0.1,0.1,0.1,0.4,0.3,0.3C251.3,382.6,251.1,382.3,251,382.4 M285,382.4
        c-0.1,0.2,0,0.5,0.2,0.6C285.5,382.9,285.2,382.4,285,382.4 M242.9,382.8c-0.1,0.2,0,0.3,0.2,0.3
        C243.2,382.8,243.1,382.7,242.9,382.8 M285.4,382.9c0.2,0.3,0.2,0.6,0.2,1c-0.1,0-0.2,0.1-0.3,0.1c0.1,0,0.4-0.1,0.5-0.1l-0.1,0.3
        c0.1-0.1,0.2-0.1,0.3-0.2c0,0.1,0.1,0.3,0.2,0.3c0,0.2,0,0.4,0.1,0.5c0.1-0.2,0.2-0.4,0.3-0.7c-0.3-0.2-0.6-0.6-1-0.3l0.1-0.2
        l-0.3,0.1c0.1-0.1,0.2-0.3,0.3-0.5C285.8,383.1,285.6,383,285.4,382.9 M217.9,383.3c-0.1,0.2,0,0.3,0.3,0.2
        C218.2,383.3,218.1,383.2,217.9,383.3 M269.3,383.3C269.4,383.4,269.4,383.4,269.3,383.3 M276.4,383.6c-0.4,0.4,0.1,0.9,0.2,1.3
        c0.2,0.1,0.3,0.2,0.6,0.3c0.1-0.5,0.3-0.9,0.5-1.3c-0.2-0.2-0.4-0.4-0.6-0.6C276.8,383.2,276.6,383.5,276.4,383.6 M243.3,383.5
        C243.4,383.6,243.4,383.6,243.3,383.5 M267.1,383.6c-0.1,0.1-0.1,0.3-0.1,0.5C267.3,384.3,267.4,383.5,267.1,383.6 M266.2,384.3
        c0.2,0.1,0.3,0,0.5,0c0-0.2,0-0.3-0.2-0.3C266.3,384.1,266.2,384.2,266.2,384.3 M290.3,384.3c-0.1,0.3-0.5,0.3-0.5,0.7
        c0.2-0.1,0.4-0.3,0.6-0.4c0-0.1,0.1-0.2,0.1-0.2C290.5,384.3,290.4,384.3,290.3,384.3 M282.6,384.3c-0.1,0.1-0.2,0.4,0,0.4
        C282.8,384.8,282.9,384.2,282.6,384.3 M266.7,384.6c-0.1,0.2,0,0.3,0.3,0.2C267,384.5,266.9,384.5,266.7,384.6 M251.2,384.7
        c-0.2,0.2,0.2,0.3,0.3,0.2C251.7,384.7,251.3,384.5,251.2,384.7 M284.9,384.7c0,0.3,0,0.8-0.4,0.8c0.1,0.1,0.2,0.2,0.3,0.3
        c-0.1,0.6-0.2,1.2-0.1,1.7c0.6-0.6,0.8-1.5,1.3-2.1C285.8,385,285.3,384.8,284.9,384.7 M227.7,384.8
        C227.4,385.2,228.2,384.9,227.7,384.8 M266.6,385.3c0.2-0.1,0.2-0.2,0-0.3C266.4,385,266.4,385.1,266.6,385.3 M282.7,384.9
        C282.7,385.4,283.1,384.7,282.7,384.9 M302.8,385.4C302.8,385.5,302.8,385.5,302.8,385.4 M251.3,385.6
        C251.1,386,251.7,385.6,251.3,385.6 M257.6,386.3c0,0.1-0.1,0.3-0.2,0.4c0.2,0,0.3,0,0.5,0.1c-0.1-0.1-0.2-0.3-0.3-0.4
        c0.1,0,0.4,0,0.5,0.1C258,386.2,257.8,386.3,257.6,386.3 M227.6,386.8c0,0.1,0,0.3,0,0.4l-0.2-0.1c0,0,0.1,0,0.2,0.1
        c-0.1,0.2-0.2,0.5-0.3,0.7c0,0.1,0.1,0.2,0.1,0.3c-0.1,0-0.2-0.1-0.3-0.1c0,0.1,0.1,0.2,0.1,0.3c-0.2,0.1-0.2,0.3-0.3,0.5
        c0.2,0.3,0.7,0.3,0.6,0.8c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0,0.3,0,0.4,0c0,0,0-0.1,0-0.1c-0.1,0-0.3,0-0.4,0c0-0.3,0-0.6-0.3-0.7
        c0.1-0.2,0.1-0.4,0.2-0.6c0.1,0,0.3,0,0.4,0c0-0.1,0-0.3,0-0.4c-0.1,0-0.2-0.1-0.3-0.2c0,0.1,0,0.4,0,0.5c-0.1,0-0.3,0-0.4,0
        c0.1-0.2,0.2-0.4,0.3-0.6c0-0.2-0.1-0.5-0.1-0.7c0.1-0.1,0.2-0.2,0.3-0.3c0,0-0.1,0-0.2,0C227.7,387,227.7,386.9,227.6,386.8
        M234.6,387.2c0.2,0.1,0.2,0.2,0.2,0.4l-0.1,0.1c-0.1,0.4,0.6,0.7,0.4,1.2c0.2,0.2,0.3,0.4,0.2,0.7c0.1-0.2,0.2-0.5,0.4-0.7l-0.2,0
        c0.1-0.1,0.1-0.3,0.2-0.4c-0.2-0.1-0.4-0.2-0.6-0.3c0.1-0.1,0.2-0.2,0.3-0.3c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1-0.3-0.1-0.6-0.1-0.9
        C234.8,387.2,234.7,387.2,234.6,387.2 M251.8,387.1L251.8,387.1L251.8,387.1L251.8,387.1L251.8,387.1 M227.8,387.5
        c0,0.1,0,0.3,0,0.4c0.1,0,0.2,0,0.2,0.1c-0.1-0.3,0-0.4,0.2-0.5C228.1,387.5,227.9,387.5,227.8,387.5 M287.5,388.1
        c-0.1,0.2,0.2,0.4,0.3,0.3C288,388.1,287.7,387.9,287.5,388.1 M288.4,388.1c0,0.2-0.1,0.4-0.3,0.4l0,0.2c-0.3-0.1-0.6-0.3-0.8-0.1
        c0.3-0.1,0.5,0.2,0.6,0.5c0.1-0.2,0.1-0.3,0.2-0.4l0.1,0.2c0-0.1,0-0.2,0-0.3c0.3,0,0.3-0.3,0.4-0.5L288.4,388.1 M257.6,388.3
        C257.7,388.4,257.7,388.4,257.6,388.3 M232.3,388.5C232.3,389,232.6,388.2,232.3,388.5 M257.7,389c-0.6,0.3,0.1,0.9,0.4,1l-0.2-0.1
        c0-0.3-0.1-0.6-0.1-0.9c0.3-0.1,0.3,0.2,0.4,0.4c0.1,0,0.2,0.1,0.3,0.1c-0.2-0.1-0.3-0.4-0.5-0.5C257.8,388.7,257.7,388.8,257.7,389
        M236.2,389.1C236,389.5,236.7,389.1,236.2,389.1 M240.3,389.1c0.1,0.2,0.3,0.7,0.5,0.3C240.7,389.3,240.5,389.2,240.3,389.1
        M224,389.2C224.1,389.3,224.1,389.3,224,389.2 M223.6,389.6c-0.1,0.2,0.2,0.4,0.3,0.2C224,389.7,223.7,389.5,223.6,389.6
        M236.5,389.6C236.5,390.1,236.9,389.4,236.5,389.6 M282,389.9c-0.1,0.2,0,0.4,0.2,0.4C282.5,390.2,282.2,389.7,282,389.9
        M266.4,390.1c-0.1,0.2,0,0.3,0.2,0.2C266.7,390.1,266.6,390,266.4,390.1 M278.1,390.1C277.9,390.5,278.6,390.1,278.1,390.1
        M286.6,390.2c0,0.1,0.1,0.3,0.1,0.4c0.2-0.1,0.4-0.1,0.5-0.2C287,390.4,286.8,390.3,286.6,390.2 M237.1,390.7l0.2,0
        c0,0.3,0.3,0.4,0.6,0.3c-0.2-0.2-0.6-0.2-0.6-0.6C237.3,390.5,237.2,390.6,237.1,390.7 M277.3,390.8
        C277.4,391.2,277.8,390.6,277.3,390.8 M266.4,391.2c0,0.3,0.1,0.6,0.4,0.4c0-0.1,0-0.3,0-0.4c-0.1,0-0.2,0.1-0.3,0.1
        C266.5,391.3,266.4,391.2,266.4,391.2 M224.6,391.5C224.6,391.6,224.6,391.6,224.6,391.5 M237.9,391.5
        C238,391.6,238,391.6,237.9,391.5 M266.5,392.1c0.2,0.1,0.5,0.2,0.5,0.5c0.1-0.1,0.2-0.2,0.3-0.3c-0.2-0.2-0.3-0.3-0.5-0.5
        C266.7,391.9,266.6,392,266.5,392.1 M258.1,392.1C258.2,392.2,258.2,392.2,258.1,392.1 M272.1,393.6c0.1-0.1,0.4-0.1,0.4-0.3
        c0.1,0,0.3,0.1,0.3,0.1c0-0.1-0.1-0.4-0.2-0.5c0.3,0,0.4-0.1,0.5-0.4c-0.1,0.1-0.3,0.2-0.5,0.3c0-0.1,0-0.3,0-0.4
        C272.1,392.5,272.1,393.2,272.1,393.6 M232,392.7c0,0.2,0.1,0.5,0.3,0.6c0.2,0,0-0.3,0-0.4C232.2,392.8,232.1,392.7,232,392.7
        M225.8,393.1C225.6,393.5,226.3,393.1,225.8,393.1 M232,393.3c0,0.1,0.1,0.2,0.1,0.3c0,0.5,0.4,0.7,0.7,1c0.4-0.3,0.7,0.2,0.8,0.5
        c-0.2,0-0.3-0.1-0.5-0.1l0-0.1c-0.1,0-0.3,0-0.3,0c0.2,0.1,0.4,0.1,0.5,0.3c0.1,0,0.3,0,0.4,0c0.2,0.3,0.4,0.5,0.5,0.8
        c0.2,0,0.3,0.1,0.5,0.2c0.2,0,0.5,0,0.7-0.2c-0.5-0.5-1.1-0.8-1.4-1.3c-0.4-0.1-0.7-0.3-0.9-0.5c-0.1-0.3-0.3-0.5-0.6-0.7
        c0.1,0.2,0.2,0.4,0.4,0.6c0.1,0.1,0.1,0.3,0.1,0.4c-0.3,0.1-0.4-0.2-0.6-0.3C232.1,394,232.2,393.6,232,393.3 M227,394.4
        c-0.2,0.2,0.3,0.4,0.4,0.2C227.3,394.4,227.1,394.3,227,394.4 M227.4,394.9C227.5,395,227.5,395,227.4,394.9 M228.2,395.9
        c-0.1,0.2,0.1,0.6,0.4,0.4C228.6,396.1,228.3,395.9,228.2,395.9 M228.4,396.9L228.4,396.9L228.4,396.9L228.4,396.9L228.4,396.9
        M228.7,397.1c0,0.4,0.6,0.5,0.9,0.8c0-0.1-0.1-0.3-0.1-0.4C229.2,397.4,228.9,397.2,228.7,397.1 M242,397.3
        c-0.2,0.1-0.1,0.4,0.1,0.3C242.4,397.5,242.3,397.1,242,397.3 M266.3,397.3c-0.1,0.2,0,0.3,0.3,0.2
        C266.7,397.3,266.6,397.2,266.3,397.3 M242.3,397.8c-0.2,0.1,0,0.5,0.2,0.3C242.8,398,242.5,397.6,242.3,397.8 M272.1,398.7
        c-0.1,0.3,0.5,0.3,0.4,0C272.6,398.4,272.2,398.5,272.1,398.7 M288,398.8l-0.1,0.1c-0.2,0.1-0.1,0.3,0,0.5c0.1-0.2,0.3-0.4,0.5-0.5
        C288.2,398.8,288.1,398.6,288,398.8 M243.1,399c-0.2,0-0.2,0.4,0.1,0.3C243.4,399.2,243.3,398.9,243.1,399 M287.7,399.6
        C287.5,400,288.1,399.6,287.7,399.6 M230.1,399.8c0.1,0.1,0.3,0.3,0.5,0.3C230.7,399.9,230.3,399.5,230.1,399.8 M267.7,400.6
        c0,0.2-0.2,0.4-0.4,0.5c-0.5,0.5-0.9,0.9-1.4,1.4c0.1-0.7-0.2-0.2-0.3-0.1c0.3,0.1,0.2,0.5,0.3,0.8c-0.2,0-0.3-0.2-0.4,0
        c-0.1,0.2,0,0.3,0.2,0.4l-0.1,0.2l0.1,0c0.1-0.2,0.2-0.3,0.4-0.4c0,0.2-0.2,0.5-0.5,0.5c0.1,0.2,0,0.5,0,0.7
        c0.2-0.4,0.4-0.7,0.6-1.1c-0.4-0.2,0.3-0.6,0.1,0c0.3-0.2,0-0.5,0.3-0.7c0.1-0.1,0.3-0.2,0.4-0.3c0-0.4,0.1-0.6,0.4-0.8
        c0-0.4,0.2-0.7,0.4-1C267.8,400.6,267.7,400.6,267.7,400.6 M252.2,401.1c0.1,0,0.2,0,0.3,0.1c0-0.1,0-0.3,0-0.4
        C252.3,400.8,252.1,400.9,252.2,401.1 M270.1,401.3C270,401.8,270.5,401.1,270.1,401.3 M265.2,401.9c-0.1,0.2,0,0.3,0.2,0.2
        C265.5,401.9,265.5,401.9,265.2,401.9 M253.1,402.5c0.2-0.1,0.2-0.3,0-0.4C252.9,402.2,252.9,402.4,253.1,402.5 M252.6,402.4
        c0.1,0.2,0.3,0.4,0.4,0.6l-0.2,0c0,0.1,0,0.4,0,0.5C253.4,403.4,253,402.5,252.6,402.4 M245.5,402.8c0,0.1,0,0.3,0.1,0.4
        C246,403.3,245.8,402.6,245.5,402.8 M232.8,403.3C232.9,403.4,232.9,403.4,232.8,403.3 M233.2,404c-0.1-0.1-0.3-0.2-0.4-0.3
        c0.1,0.3,0.4,0.5,0.7,0.7c-0.2-0.3,0-0.6,0.2-0.9C233.5,403.7,233.3,403.8,233.2,404 M246.3,405.4c0.2-0.1,0.4-0.1,0.6,0
        c-0.1-0.1-0.3-0.3-0.4-0.4C246.3,405,246.2,405.2,246.3,405.4 M234.2,406l0,0.1c0.2,0.2,0.4-0.3,0.2-0.3
        C234.3,405.8,234.2,405.9,234.2,406 M273.8,406.4C273.8,406.9,274.2,406.2,273.8,406.4 M267.4,406.6
        C267.2,407,267.8,406.6,267.4,406.6 M234.9,407.1c-0.2,0.1-0.2,0.2,0,0.3C235.1,407.3,235.1,407.2,234.9,407.1 M267.1,407.2
        c-0.1,0.1-0.2,0.3-0.2,0.4c0.2-0.1,0.4-0.3,0.6-0.5c0.2,0.3,0.4,0.2,0.4-0.2C267.6,407.1,267.4,407.2,267.1,407.2 M247.6,407.7
        c-0.1,0.2,0,0.3,0.2,0.2C247.9,407.7,247.8,407.6,247.6,407.7 M248,408C248.1,408.1,248.1,408.1,248,408 M266.4,408.1
        c0.1,0.3,0.2,0.6,0.4,0.9l0.2-0.1C266.8,408.7,266.6,408.4,266.4,408.1 M272,408.3c-0.3,0.2,0.2,0.5,0.4,0.2
        C272.4,408.4,272.2,408.2,272,408.3 M280.5,408.5c0.1,0,0.3,0.1,0.4,0.1c0,0.1,0,0.2,0,0.3c0.1-0.1,0.2-0.2,0.3-0.3
        c0,0.1,0.1,0.2,0.1,0.3c0.1-0.1,0.1-0.2,0.2-0.3C281.4,408.3,280.7,408.2,280.5,408.5 M239.5,408.9
        C239.6,408.9,239.6,408.9,239.5,408.9 M240.2,409.7C240.3,409.8,240.3,409.8,240.2,409.7 M248.3,409.8c-0.1,0.2,0,0.3,0.3,0.3
        C248.6,409.9,248.5,409.8,248.3,409.8 M236.2,410C236.3,410.1,236.3,410.1,236.2,410 M254,410.2l-0.2,0.3c-0.1,0-0.3,0-0.4,0
        c0.2,0.2,0.1,0.5,0.1,0.7c0.1,0,0.2,0.1,0.2,0.1c0-0.1,0-0.4,0-0.5C254.2,410.9,254,410.4,254,410.2 M270.8,410.8
        c0.1-0.3,0.4-0.3,0.6-0.4C271.1,410.1,270.3,410.3,270.8,410.8 M237.1,410.5c-0.1,0.3-0.2,0.6-0.3,0.8c0.2-0.1,0.3-0.2,0.5-0.3
        C237.3,410.9,237.3,410.6,237.1,410.5 M265.5,410.6C265.3,411,265.9,410.5,265.5,410.6 M249.6,410.7
        C249.6,411.2,250,410.5,249.6,410.7 M240.4,411.3c0.2,0.2,0.4,0.5,0.6,0.8c0.2-0.4,0.4-0.8,0.7-1.2l-0.1,0
        C241,410.9,240.6,411,240.4,411.3 M249,411.1c-0.1,0.2,0.5,0.3,0.6,0.1C249.4,411.1,249.1,411,249,411.1 M278.9,411.6
        c-0.1,0.2,0,0.3,0.2,0.2C279.1,411.6,279,411.5,278.9,411.6 M236.9,412c-0.2,0.2,0.3,0.7,0.4,0.4C237.2,412.2,237.1,412,236.9,412
        M250,412.1C249.7,412.5,250.5,412.2,250,412.1 M249.4,412.3c-0.1,0.2,0.3,0.4,0.5,0.3C250,412.4,249.6,412.2,249.4,412.3
        M264.4,412.4C264.5,412.9,264.8,412.1,264.4,412.4 M238.1,413.3c-0.1,0.2,0.2,0.4,0.4,0.3C238.5,413.4,238.3,413.1,238.1,413.3
        M237.8,413.5c0,0.1,0,0.3,0,0.5c0.2,0.1,0.4,0.2,0.5,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0-0.1,0.1-0.2,0.1-0.3
        C238.3,414.1,238,413.8,237.8,413.5 M253.7,413.5c0,0.2,0,0.3,0.2,0.2C254,413.5,253.9,413.4,253.7,413.5 M254.3,413.5
        c-0.2,0-0.2,0.5,0,0.3C254.5,413.8,254.6,413.4,254.3,413.5 M254.2,414.9c0.1,0,0.2-0.1,0.3-0.2c0-0.1-0.1-0.2-0.1-0.2
        C254.2,414.6,254.2,414.7,254.2,414.9 M277.7,415C277.8,415.1,277.8,415.1,277.7,415 M254.4,415.3c0.2,0,0.3-0.1,0.3-0.3
        C254.5,415,254.4,415.1,254.4,415.3 M238.6,415.7C238.7,415.8,238.7,415.8,238.6,415.7 M250.5,415.9c0.1,0.2,0.4,0.2,0.6,0.1
        C251.1,415.8,250.6,415.6,250.5,415.9 M254.7,415.8C254.8,415.9,254.8,415.9,254.7,415.8 M267.7,416l0.1,0.2c-0.1,0-0.3,0.1-0.3,0.2
        c0,0.1,0,0.4,0,0.5l0.3,0c0-0.1-0.1-0.3-0.2-0.4c0.1,0,0.3,0,0.4,0.1c0-0.2,0.1-0.4,0.1-0.6C268,416,267.8,416,267.7,416 M251,416.8
        c0.2,0,0.3-0.3,0.2-0.4C251,416.3,250.8,416.6,251,416.8 M250.8,417c0.1,0.6,0.6,0.1,0.8,0.5C251.7,417.1,251.1,416.9,250.8,417
        M252,417.5C252.1,417.6,252.1,417.6,252,417.5 M262.3,417.7C262.3,417.8,262.3,417.8,262.3,417.7 M251.7,418.7
        C251.8,418.8,251.8,418.8,251.7,418.7 M260.8,419.3c0.3,0.1,0.2-0.6,0.2-0.7C260.7,418.7,260.7,419,260.8,419.3 M262,418.7
        C262,418.7,262,418.7,262,418.7 M241.1,418.8C241.1,418.9,241.1,418.9,241.1,418.8 M241.3,420.7
        C241.4,420.7,241.4,420.7,241.3,420.7 M244.6,420.7c0.2,0.4,0.4,0.9,0.5,1.3c0.3-0.1,0.4-0.4,0.5-0.7c0.2-0.2,0.4-0.3,0.6-0.5
        c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3,0.2-0.5,0.3-0.8,0.4C245,420.7,244.8,420.7,244.6,420.7 M274.9,420.7
        C274.7,421.1,275.4,420.7,274.9,420.7 M265.7,421.3c0.1,0,0.3-0.1,0.4-0.1c0-0.1,0-0.2,0-0.3l0.3,0
        C266.2,420.6,265.8,421.1,265.7,421.3 M241.8,421.1c-0.2,0.1-0.2,0.4,0.1,0.3C242.1,421.3,242,420.9,241.8,421.1 M265.2,422.1
        c0,0.1-0.1,0.3-0.1,0.4C265.4,422.8,265.6,421.6,265.2,422.1 M241.8,422.2c-0.1,0.2,0,0.3,0.2,0.2
        C242.1,422.2,242,422.2,241.8,422.2 M253.3,422.7C253,423.1,253.7,422.7,253.3,422.7 M252.7,423.1
        C252.8,423.2,252.8,423.2,252.7,423.1 M247.7,425.6c-0.1,0-0.3,0-0.4-0.1c-0.1,0.1-0.3,0.3-0.4,0.4c0-0.1,0-0.2,0-0.3
        c-0.1,0-0.2,0-0.3,0l0-0.2l-0.1,0c0,0.3,0.1,0.6,0.2,0.9c0.2,0.2,0.1,0.4,0.2,0.6c0.3-0.1,0.3-0.4,0.5-0.6c0.1-0.6,0.8-0.7,0.9-1.2
        C248.1,425.3,247.9,425.4,247.7,425.6 M253.7,426c0.3,0,0.2-0.4,0.1-0.4C253.5,425.4,253.4,425.9,253.7,426 M243.4,426.9
        C243.4,427.3,243.9,426.7,243.4,426.9 M243.8,427.3c0.1,0.2,0.2,0.2,0.4,0C244,427.1,243.9,427.1,243.8,427.3 M270.8,428.5
        c-0.1,0.2,0.2,0.4,0.3,0.2C271.2,428.6,270.9,428.4,270.8,428.5 M248,428.6C248.1,428.7,248.1,428.7,248,428.6 M244.2,428.9
        c-0.2,0.1-0.2,0.2,0,0.3C244.3,429.1,244.4,429,244.2,428.9 M244.7,429.9c-0.2,0-0.3,0.2-0.3,0.4c0.1,0,0.3-0.1,0.3-0.1
        c0.1,0.3,0.3,0.6,0.2,0.9c-0.1,0-0.2-0.1-0.3-0.1c0,0.3,0.3,0.3,0.5,0.4c0-0.3,0-0.5,0-0.7c-0.3-0.2-0.2-0.5-0.3-0.8l0.3,0.1
        c-0.1-0.1-0.3-0.2-0.4-0.3C244.6,429.6,244.7,429.8,244.7,429.9 M245.2,430C245.3,430.1,245.3,430.1,245.2,430 M250.4,431.5
        C250.5,431.5,250.5,431.5,250.4,431.5 M269.4,431.4C269.4,431.5,269.4,431.5,269.4,431.4 M250,432c-0.1,0-0.2,0.1-0.3,0.1
        c0-0.4-0.3,0-0.4,0.1c0-0.1,0-0.3,0.1-0.4c-0.1,0.1-0.3,0.2-0.3,0.2c0-0.1-0.1-0.2-0.2-0.3c-0.2,0-0.5,0-0.7,0
        c0.2,0.2,0.4,0.5,0.5,0.8c0.2,0.5,0.2,1,0.5,1.4c0.2-0.4,0.3-0.8,0.7-1.1c0.3-0.3,0.5-0.6,0.7-0.9c0.1-0.1,0.3-0.2,0.4-0.4
        C250.5,431.8,250.3,431.9,250,432c0.1-0.1,0.1-0.3,0-0.5C249.9,431.7,249.9,431.9,250,432 M262.2,431.6
        C262.3,431.7,262.3,431.7,262.2,431.6 M245.1,431.8c-0.2,0.1-0.2,0.2-0.1,0.3C245.2,432,245.2,431.9,245.1,431.8 M245.2,432.2
        c-0.1,0.2,0.2,0.4,0.4,0.2C245.7,432.2,245.3,432,245.2,432.2 M261.6,432.3c0.1,0.2,0.2,0.3,0.3,0.5c0-0.2,0.1-0.5,0.3-0.7
        C262,432.2,261.8,432.3,261.6,432.3 M245,432.5c-0.1,0.2-0.1,0.3,0.1,0.3C245.3,432.6,245.2,432.5,245,432.5 M268.6,433.3
        C268.7,433.4,268.7,433.4,268.6,433.3 M261.7,433.4c-0.1,0.2,0,0.3,0.3,0.2C262,433.4,261.9,433.4,261.7,433.4 M261.3,433.8
        c0.1,0.3-0.3,0.3-0.4,0.4l0.4,0c0,0.1,0,0.2,0,0.3c0.1,0,0.2,0,0.3,0c-0.1-0.1-0.2-0.3-0.3-0.4c0.3,0.1,0.6-0.1,0.2-0.3l0.1-0.3
        C261.4,433.5,261.2,433.6,261.3,433.8 M268.5,434.3C268.6,434.4,268.6,434.4,268.5,434.3 M246,434.6c-0.1,0.2,0,0.3,0.2,0.2
        C246.4,434.6,246.3,434.5,246,434.6 M255.3,434.7C255.4,434.8,255.4,434.8,255.3,434.7 M246,435.2c0,0.2,0,0.4,0,0.7
        c0.1-0.1,0.1-0.3,0.2-0.4l-0.2-0.1c0.1-0.2,0.2-0.3,0.3-0.4C246.3,435,246.1,435.1,246,435.2 M268.4,435.3
        C268.1,435.7,268.8,435.4,268.4,435.3 M246.6,436c0.1,0.1,0.3,0.2,0.4,0.2c0,0,0.1,0,0.2,0c0-0.1-0.1-0.2-0.2-0.3
        C246.8,435.9,246.7,436,246.6,436 M255.8,436.3C255.9,436.4,255.9,436.4,255.8,436.3 M246.7,436.7c0.1,0.2,0.5,0,0.5-0.1
        C247.1,436.3,246.7,436.5,246.7,436.7 M247.2,437C246.9,437.4,247.7,437,247.2,437 M247.5,439.8c0-0.1,0-0.2,0-0.2
        c-0.2-0.1-0.2-0.3-0.2-0.5C247,439.3,247.4,439.6,247.5,439.8 M259.9,439.8c0.1,0.1,0.2,0.2,0.3,0.3
        C260.5,439.9,260.1,439.5,259.9,439.8 M247.6,440.5c0,0.2,0.1,0.5,0.2,0.7c-0.2,0-0.4-0.1-0.6-0.2c0,0.1,0.1,0.4,0.1,0.5
        c0.3-0.2,0.5,0.1,0.8,0c0.2-0.1,0.5,0.4,0.5-0.1l-0.3,0c0-0.1,0-0.2,0-0.4l-0.2,0.1c0-0.1,0-0.2,0-0.2c0,0.2-0.1,0.5-0.3,0.6
        C247.6,441.2,248,440.7,247.6,440.5 M266.5,440.5l0,0.3c-0.1-0.1-0.3-0.2-0.3-0.2c0,0.1,0,0.3,0.1,0.4
        C266.5,440.9,266.9,440.7,266.5,440.5 M265.9,441C266,441.1,266,441.1,265.9,441 M251.2,441.3C250.9,441.6,251.6,441.4,251.2,441.3
        M252.4,443.9C252.2,444.3,252.9,443.8,252.4,443.9 M264.7,444.6c-0.2,0-0.3,0.3-0.1,0.3C264.7,444.9,264.9,444.6,264.7,444.6
        M252,444.7c0,0.3,0.1,0.6,0.2,0.9c0.3-0.1,0.2-0.5,0.2-0.7c-0.1,0-0.2,0-0.3,0L252,444.7L252,444.7 M248.6,445.2
        c0,0.1,0.1,0.3,0.1,0.4c0.2-0.1,0.4-0.2,0.5-0.3C249.1,445.3,248.8,445.3,248.6,445.2 M249.4,447.6
        C249.5,447.6,249.5,447.6,249.4,447.6 M249.4,448.7c0.1,0,0.2,0.1,0.2,0.1c0.1-0.1,0.2-0.3,0.3-0.3c0-0.2,0-0.3,0-0.4
        c-0.1,0.2-0.1,0.4-0.3,0.6c-0.1-0.2-0.1-0.4-0.2-0.6C249.5,448.3,249.4,448.5,249.4,448.7 M249.4,450c0.2,0.1,0.4-0.2,0.4-0.4
        C249.6,449.5,249.3,449.8,249.4,450 M249.8,450.1c-0.2,0.1,0,0.5,0.2,0.3C250.2,450.3,250,450,249.8,450.1 M250.6,450.6
        c0.2-0.1,0.2-0.2,0-0.3C250.4,450.4,250.4,450.5,250.6,450.6 M250.5,451.2c0,0.2,0.4,0.3,0.5,0C250.9,451,250.6,451,250.5,451.2
        M262.6,451.4C262.4,451.9,263.1,451.4,262.6,451.4 M250.3,451.8C250.4,451.8,250.4,451.8,250.3,451.8 M251.1,453.5
        C251.2,453.6,251.2,453.6,251.1,453.5 M252.4,457.3c-0.2,0.1-0.2,0.2,0,0.3C252.6,457.5,252.6,457.4,252.4,457.3 M260.3,458.8l0.3,0
        c0,0.1,0,0.2-0.1,0.3l0.1,0.1c0.1-0.2,0.3-0.4,0.4-0.7C260.7,458.5,260.4,458.5,260.3,458.8 M260.4,460.1
        C260.2,460.5,260.9,460.1,260.4,460.1 M260.2,460.6C260.3,460.7,260.3,460.7,260.2,460.6 M253.1,463.6
        C253.2,463.6,253.2,463.6,253.1,463.6 M253.4,464C253.5,464.1,253.5,464.1,253.4,464 M253.8,464.8c-0.1,0.2,0,0.3,0.3,0.2
        C254.1,464.9,254,464.8,253.8,464.8 M259,464.8l-0.1,0.1C259,465.3,259.3,464.8,259,464.8 M253.5,465.6
        C253.6,465.7,253.6,465.7,253.5,465.6 M254,466.6c-0.1,0.2,0.1,0.4,0.3,0.4C254.5,467,254.2,466.5,254,466.6 M254.3,470.2
        C254.3,470.7,254.7,470,254.3,470.2 M255.1,473.7C255.2,473.8,255.2,473.8,255.1,473.7 M255.3,474.2
        C255.4,474.2,255.4,474.2,255.3,474.2 M257.7,504.4C257.8,504.5,257.8,504.5,257.7,504.4 M257.6,504.7c-0.1,0.2,0,0.3,0.2,0.2
        C257.8,504.6,257.8,504.6,257.6,504.7 M257.6,506.6c-0.1,0.2-0.1,0.3,0.1,0.3C257.8,506.7,257.8,506.6,257.6,506.6 M257.6,508.1
        C257.3,508.6,258,508.2,257.6,508.1 M253.9,58.8L253.9,58.8L253.9,58.8L253.9,58.8L253.9,58.8 M260.4,75.1
        C260.8,75.1,260.1,75.5,260.4,75.1 M248.3,75.9C248.7,75.9,248,76.3,248.3,75.9 M105,81.1c0.2,0.2,0.4,0.3,0.7,0.4
        c0.2-0.1,0.3-0.2,0.5-0.4c0.5-0.1,0.9,0.3,1.4,0.2c0.3-0.1,0.6-0.2,0.9-0.2c0.2,0.1,0.3,0.2,0.5,0.2c0.5,0,0.9,0.1,1.4,0.1
        c0.4,0.5,1,0.2,1.5,0.3c0.7,0.1,1.2,0.6,1.8,0.7c0.1,0.6,1.1,0.4,1.3,0.9c0.6,0.3,1.1,0.8,1.6,1.2c0.1,0.1,0.1,0.3,0.2,0.4
        c0.3,0.1,0.5,0.3,0.8,0.5c0.2,0.5,0.4,0.9,0.5,1.5c0.1,0,0.2,0.1,0.3,0.1c0,0.1-0.1,0.2-0.1,0.2c0.2,0.6,0.4,1.3,0.7,1.9
        c-0.1,0-0.3-0.1-0.4-0.2l0.1,0.3c-0.3-0.1-0.5-0.2-0.8-0.3c0,0.1,0,0.3,0,0.4c-0.3-0.1,0-0.5-0.4-0.5c0,0.1,0,0.3-0.1,0.4
        c-0.2-0.1-0.5-0.2-0.6-0.4c-0.2-0.1-0.4-0.1-0.6-0.2c-0.1-0.2-0.3-0.4-0.4-0.6c0,0.1-0.1,0.4-0.1,0.5c-0.1-0.1-0.2-0.2-0.3-0.3
        c0,0-0.1,0.1-0.1,0.1c-0.1-0.1-0.2-0.2-0.4-0.3l0.1-0.2c-0.1,0-0.3,0-0.3,0.1c0-0.3-0.3-0.3-0.5-0.5c0.2-0.1,0.4,0,0.6,0
        c0-0.1,0-0.3,0-0.4c-0.3,0.3-0.5,0-0.8-0.1c-0.2,0.1-0.4,0.1-0.5,0c0-0.1,0.1-0.2,0.2-0.3c-0.2-0.1-0.3,0-0.5,0.1
        c0-0.2,0.1-0.5-0.2-0.6c0,0.2,0,0.4,0,0.6c-0.4-0.4-0.9-0.6-1.4-0.6c-0.1-0.1-0.1-0.2-0.2-0.3l0.1-0.1c0-0.1,0-0.2,0-0.2
        c-0.1,0.1-0.3,0.1-0.4,0.2c0-0.1,0-0.4,0-0.5c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1-0.1-0.3-0.2-0.4-0.2c0-0.1,0-0.2,0-0.3
        c-0.1,0-0.3,0.1-0.4,0.2l0.1,0.2l0.3,0c-0.1,0.3-0.3,0.3-0.5,0.2c-0.1-0.2-0.3-0.4-0.5-0.5c0.1,0.1,0.2,0.3,0.2,0.4
        c-0.1,0-0.3,0-0.4,0c0.2-0.5-0.3-0.1-0.5-0.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0.1-0.9,0.4-0.6-0.1c-0.4-0.1-0.3,0.2-0.3,0.4
        c-0.1-0.1-0.2-0.2-0.2-0.3c-0.3-0.1-0.5,0.1-0.8-0.1l0,0.2c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3,0-0.4,0c-0.2,0.2-0.4,0.3-0.6,0.5
        c0-0.1,0-0.3,0-0.4c-0.3,0.2-1.1-0.1-0.7,0.5l-0.2-0.2c0-0.1,0-0.2,0-0.3c-0.2,0.2-0.5,0.3-0.7,0.4c-0.1,0-0.2-0.1-0.3-0.1
        c0,0.1,0,0.3,0,0.5c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1,0.4-0.6,0.2-0.9,0.3c0.1,0.1,0.3,0.2,0.4,0.3c0,0-0.1,0.1-0.1,0.1
        c-0.1-0.1-0.3-0.2-0.3-0.2c0,0.1,0,0.2,0,0.3c-0.3-0.2-0.6,0.3-1,0.3c-0.2-0.1-0.3-0.1-0.5-0.2c-0.3,0.2-0.8,0.3-1,0.7
        c-0.1,0-0.3,0-0.4,0c0,0.1,0.2,0.4,0,0.4c-0.5,0.1-0.9,0.3-1.2,0.6c-0.5,0-0.9,0.5-1.3,0.7c-0.1-0.1-0.2-0.2-0.2-0.2
        c0.5,0,0.5-0.6,0.7-1c-0.1,0-0.2,0.1-0.3,0.1c0,0.1,0,0.3,0,0.4c-0.2,0.1-0.3,0.2-0.5,0.3c0,0.2,0,0.3,0.1,0.5
        c-0.2,0.2-0.5,0.3-0.8,0.1c0,0.1,0.1,0.3,0.1,0.5c-0.3-0.1-0.6,0.1-0.8,0.3c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0.2-0.1,0.4-0.2,0.6
        C94,89.8,93.8,90,93.5,90c0-0.1,0-0.3,0-0.4c-0.3,0.3-0.3,1-0.9,1c0.1,0,0.3,0.1,0.4,0.2c-0.2,0.2-0.5,0.2-0.5-0.2
        c-0.1,0-0.2,0.1-0.3,0.1c-0.4,0-0.6,0.2-0.6,0.6c0.3-0.2,0.5-0.6,0.9-0.4c-0.2,0.2-0.5,0.3-0.6,0.6c-0.1,0-0.2,0-0.4,0
        c0-0.1,0-0.3,0-0.4c-0.2,0.2-0.5,0.5-0.8,0.3c0.1,0.2,0.1,0.5,0.1,0.8c-0.1,0-0.3,0-0.4,0c0.1-0.1,0.2-0.2,0.2-0.3
        c-0.1,0-0.3,0-0.4,0c0-0.1,0-0.2,0-0.3c-0.1,0.3-0.3,0.6,0,0.8c-0.2,0.1-0.4,0.2-0.6,0.3c0.1-0.2,0.1-0.4,0.2-0.5
        c-0.4,0.1-0.2,0.7-0.5,0.7c-0.1-0.1-0.2-0.2-0.2-0.2c-0.2,0-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3-0.5-0.4,0.2-0.6,0.4
        c0.2,0.2,0.3,0.3-0.1,0.4c0-0.1,0.1-0.4,0.1-0.5c-0.2,0.2-0.4,0.4-0.6,0.6c0.1,0,0.3,0,0.4,0c-0.1,0.1-0.3,0.2-0.4,0.2
        c0,0.1,0.1,0.2,0.1,0.3c-0.2,0-0.4,0-0.6,0c0,0.1,0,0.3,0,0.4c0,0-0.2,0-0.2,0.1c0-0.2,0-0.8-0.2-0.4l-0.1,0.1
        c0.1,0.1,0.2,0.2,0.3,0.2c-0.1,0-0.3,0-0.4,0c0,0.1,0,0.2,0,0.3c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0.1-0.3,0.2-0.4,0.4
        c0.2,0,0.3-0.1,0.5-0.1c-0.1,0.4-0.6,0.4-0.9,0.6c-0.2,0.1-0.4,0.4-0.7,0.4c0,0.1,0,0.2,0,0.2c-0.1,0-0.2,0-0.3,0l0,0.3
        c-0.1-0.1-0.2-0.2-0.4-0.4c-0.1,0.1-0.2,0.2-0.3,0.2c0.1,0,0.3,0.2,0.5,0.2c-0.2,0.1-0.5,0.2-0.7,0.3c0,0.1,0,0.2,0,0.3
        c-0.1,0-0.3,0-0.4,0c0.1,0.4-0.5,0.5-0.8,0.7c-0.2,0-0.4,0-0.6,0l0.1,0.2c-0.2,0-0.3,0.1-0.5,0.1c0,0.1,0.1,0.2,0.1,0.3
        c-0.1,0.1-0.2,0.2-0.4,0.3c0.1-0.3,0.2-0.5,0.3-0.8c-0.4,0.2-0.5,0.7-0.8,1c-0.2-0.1-0.4,0-0.5,0.1c-0.1-0.1-0.2-0.2-0.2-0.3
        c-0.1,0.1-0.2,0.2-0.2,0.3c0.5-0.2,0.4,0.3,0.1,0.2c-0.2,0.1-0.4,0.2-0.6,0.2c0,0.1-0.1,0.2-0.1,0.3c-0.1,0-0.2,0.1-0.3,0.2
        c-0.2-0.1-0.3-0.2-0.5-0.3l0,0.1c0.2,0.1,0.3,0.2,0.4,0.3c-0.2,0-0.5-0.1-0.6-0.2c0.1,0.1,0.2,0.3,0.3,0.5c-0.1,0-0.2-0.1-0.3-0.1
        c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0.2-0.6,0.4-0.9,0.6c0,0.1,0,0.2,0,0.3c0-0.1-0.1-0.2-0.1-0.3c-0.1,0-0.3,0-0.4,0.1
        c-0.2,0.3-0.4,0.4-0.7,0.4c-0.1,0.1-0.2,0.2-0.2,0.3c0-0.1,0-0.4,0.1-0.6c-0.2,0.2-0.4,0.5-0.5,0.7c-0.4,0-0.7,0.1-0.9,0.4
        c0-0.1,0-0.3,0.1-0.4c-0.1,0.1-0.3,0.2-0.3,0.2l-0.1-0.2l-0.1,0c0,0.1,0.1,0.3,0.2,0.4l-0.3-0.1l0,0.2c-0.2,0-0.4-0.1-0.5-0.2
        c-0.2-0.1-0.2,0.4-0.2,0.5c-0.1-0.1-0.3-0.2-0.4-0.3c0,0.1,0,0.3,0,0.4c-0.1,0-0.3-0.1-0.4-0.2c-0.3,0.1-0.5,0.2-0.8,0.4
        c-0.2,0-0.5,0-0.7,0.2c-0.1,0-0.3,0-0.4-0.2c-0.1,0.2-0.4,0.1-0.6,0.2c-0.8,0-1.5,0-2.3-0.1c-0.2-0.1-0.4-0.2-0.6-0.3
        c-0.7,0-1.2-0.5-1.7-0.9c0.2,0,0.4,0.1,0.5,0.3c0.3,0.2,0.7,0.4,1.1,0.6c0.2,0,0.3,0,0.5,0.1c0.1,0,0.2,0.1,0.2,0.2
        c0.6,0,1.2-0.1,1.7,0c0.5-0.1,1-0.2,1.6-0.2c0,0,0.1-0.1,0.1-0.2c0.6,0,1.1-0.3,1.7-0.5c0.5-0.3,1.1-0.5,1.6-0.8
        c0.1-0.1,0.3-0.1,0.4-0.2c0.4-0.3,0.7-0.7,1.2-0.9c0.5-0.2,0.8-0.6,1.2-0.8c1.2-0.8,2.4-1.7,3.4-2.6l0.1-0.2c0.1,0,0.2,0,0.3,0
        c0-0.4,0.5-0.4,0.6-0.6c0.3-0.3,0.8-0.5,0.9-0.9c0.4,0.1,0.6-0.4,0.7-0.7c0.4-0.2,0.7-0.5,1-0.8c0.2-0.3,0.5-0.3,0.8-0.4
        c-0.4-0.3,0-0.5,0.2-0.7c0,0.1,0,0.3-0.1,0.4l0.2,0c-0.1-0.5,0.5-0.6,0.7-0.9c0.7-0.7,1.4-1.3,2.3-1.8c0.3-0.4,0.7-0.6,1.1-0.7
        c0-0.3,0-0.8,0.3-0.2c0.4-0.6,1.1-0.9,1.7-1.4c0.2,0,0.3,0.1,0.5,0.1c0,0-0.1-0.1-0.1-0.2c0.2-0.2,0.4-0.5,0.8-0.6
        c0.3-0.2,0.5-0.4,0.7-0.7c0,0,0.1,0.1,0.2,0.1c-0.1-0.4,0.1-0.2,0.3-0.1c0.2-0.2,0.4-0.4,0.4-0.7c0.6,0.2,1-0.3,1.4-0.6
        c0.2,0,0.4-0.1,0.6-0.2c0-0.2,0.2-0.4,0.4-0.6c0,0.2,0,0.4-0.1,0.6c0.3-0.1,0.3-0.2,0.2-0.5c0.2-0.2,0.5-0.3,0.8-0.4
        c0.1,0,0.2,0.1,0.3,0.1c0.2-0.1,0.4-0.2,0.5-0.3c0-0.1,0-0.3,0-0.4c0.1,0,0.2,0.1,0.3,0.1c0-0.1,0-0.4,0-0.5c0.4,0.5,0.8,0,1.1-0.2
        c0.4-0.3,0.9-0.4,1.2-0.7l-0.1,0.3c0.3-0.3,0.6-0.4,1-0.2c0.1-0.4,0.4-0.5,0.7-0.7c0.1,0,0.2,0.1,0.3,0.2c0.3-0.5,0.9-0.3,1.3-0.6
        c0.4-0.2,0.9-0.2,1.3-0.3C104.9,81.2,104.9,81.1,105,81.1 M105.4,83.6C105.4,84.1,105.8,83.4,105.4,83.6 M109.8,84.5
        C109.9,84.6,109.9,84.6,109.8,84.5 M111.6,85.5c0.3,0,0.6,0.1,0.9,0.1l0,0.2l0.3-0.1c-0.2-0.3-0.6-0.4-0.8-0.6
        C111.8,85.3,111.7,85.4,111.6,85.5 M101,85.4c-0.1,0-0.4,0.3-0.1,0.4C101.1,85.7,101.4,85.2,101,85.4 M113.6,85.8
        c0.1-0.1,0.3-0.1,0.4-0.2c0.1,0.1,0.2,0.3,0.4,0.3c-0.1-0.1-0.2-0.2-0.3-0.4C113.9,85.5,113.6,85.4,113.6,85.8 M99.9,85.6
        C99.9,86.1,100.3,85.4,99.9,85.6 M97.7,87.1c-0.2,0.2-0.4,0.3-0.5,0.6c0.3-0.2,0.7-0.2,0.9-0.6C97.9,87.1,97.8,87.1,97.7,87.1
        M115,87.3C115.1,87.4,115.1,87.4,115,87.3 M115.3,87.4c0,0.2,0.1,0.3,0.3,0.2C115.6,87.5,115.5,87.4,115.3,87.4 M117.8,88.4
        C117.9,88.8,118.2,88.1,117.8,88.4 M94.5,88.7c-0.2,0.3,0.4,0.2,0.5,0.1C94.9,88.5,94.6,88.6,94.5,88.7 M95.1,88.9L95.1,88.9
        L95.1,88.9L95.1,88.9L95.1,88.9 M87.1,94.4c0.1,0.2,0.2,0.2,0.4,0C87.4,94.2,87.3,94.2,87.1,94.4 M85.5,95.2c-0.1,0.2,0,0.3,0.2,0.2
        C85.8,95.2,85.7,95.1,85.5,95.2 M84.6,96.4c0.3,0,0.7-0.3,0.8-0.6C85.2,95.9,84.9,96.1,84.6,96.4 M84.2,96.5
        c0.2,0.1,0.4-0.2,0.2-0.3C84.3,96.1,84.1,96.4,84.2,96.5 M82.8,97.3c0.2,0.1,0.3,0.2,0.5,0.2C83.5,97.3,82.9,97.1,82.8,97.3
        M82.4,98c0.1,0,0.2-0.1,0.2-0.2C82.5,97.6,82.1,97.9,82.4,98 M81.2,98c-0.2,0.1,0,0.5,0.2,0.3C81.7,98.2,81.4,97.9,81.2,98
        M80.4,98.9C80.2,99.3,80.9,98.9,80.4,98.9 M79.2,99.3C79,99.7,79.7,99.3,79.2,99.3 M80.2,99.3C80.3,99.4,80.3,99.4,80.2,99.3
        M78.5,100.1c0,0,0.1-0.1,0.2-0.2C78.6,99.6,78.1,100.1,78.5,100.1 M75.5,101.8c0.2,0,0.3,0,0.2-0.2
        C75.6,101.5,75.5,101.6,75.5,101.8 M405.7,81.2c0.2,0,0.4-0.1,0.5-0.2c0.5,0,1,0.4,1.5,0.2c0.3-0.1,0.6-0.2,0.9-0.1
        c0.2,0.1,0.3,0.3,0.5,0.4c0.3-0.1,0.5-0.2,0.7-0.4c0,0.1,0,0.2,0,0.3c0.6,0.1,1.1,0.2,1.7,0.4c0.4,0,0.8,0.2,1,0.4
        c0.4-0.3,0.9,0.2,1,0.6c0.4-0.2,0.7-0.1,1,0.2c0-0.1,0-0.2,0-0.3c0.3,0.3,0.8,0.4,1.2,0.7c0.4,0.2,0.8,0.8,1.2,0.3
        c-0.4,0.3,0.1,0.6,0.3,0.4c0,0.1,0,0.3,0,0.3c0.2,0.1,0.4,0.3,0.6,0.3c0.4-0.1,0.7,0.2,1,0.4c-0.1,0.2,0,0.4,0.2,0.5
        c0-0.2,0-0.4-0.1-0.6c0.1,0.2,0.3,0.4,0.4,0.6c0.2,0.1,0.4,0.3,0.6,0.2c0.4,0.4,0.8,0.8,1.4,0.7c0,0.3,0.2,0.5,0.4,0.7
        c0.1,0,0.2-0.1,0.3-0.1c0,0.1,0,0.2,0,0.3c0,0,0.1-0.1,0.2-0.1c0.1,0.3,0.4,0.5,0.7,0.8c0.3,0.1,0.5,0.3,0.8,0.6
        c0,0.1-0.1,0.2-0.1,0.2c0.2,0,0.3,0,0.5-0.1c0.6,0.5,1.2,0.8,1.7,1.4l0.1-0.3c0.1,0.2,0.1,0.3,0.1,0.5c0.3,0.1,0.7,0.2,0.9,0.5
        c0.3,0.4,0.8,0.5,1.1,0.9c0.6,0.4,1.1,1,1.7,1.4c0.2,0.2,0.5,0.3,0.4,0.6l0.2,0c0-0.1,0-0.3-0.1-0.4c0.2,0.2,0.6,0.5,0.2,0.7
        c0.3,0.1,0.6,0.1,0.8,0.4c0.3,0.3,0.7,0.6,1,0.8c0,0.3,0.2,0.5,0.4,0.7c0.3,0,0.4,0.2,0.5,0.4c0.3,0.2,0.6,0.4,0.8,0.7
        c0.2,0.1,0.4,0.2,0.4,0.5c0.1,0,0.2,0,0.3,0c0,0.2,0.2,0.3,0.3,0.4c1,0.9,2.1,1.8,3.3,2.5c0.7,0.6,1.6,0.9,2.2,1.6
        c1.2,0.5,2.4,1.3,3.7,1.5c0,0,0.1,0.1,0.1,0.1c0.5,0.1,1,0.1,1.5,0.3c0.1,0,0.2-0.1,0.3-0.1c0.3,0.1,0.7,0,1,0c0.1,0,0.2,0,0.3,0.1
        c0.1,0,0.2-0.1,0.2-0.2c0.6,0,1.1-0.3,1.6-0.6c0.1-0.1,0.3-0.2,0.5-0.3c-0.4,0.5-1,0.9-1.7,0.9c-0.2,0.1-0.4,0.2-0.6,0.3
        c-0.8,0.1-1.5,0.1-2.3,0.1c-0.2,0-0.4,0-0.6-0.2c-0.3,0.3-0.7-0.1-1.1,0c-0.4-0.2-0.8-0.3-1.2-0.3c0-0.1,0-0.2,0-0.3
        c-0.3,0-0.7,0.1-0.6-0.4c0,0.1-0.1,0.2-0.1,0.2c-0.1,0-0.3,0-0.4,0l0-0.2l-0.3,0.1c0-0.1,0.1-0.3,0.2-0.4c-0.2,0.3-0.3,0.3-0.5,0
        c0,0.1,0.1,0.3,0.1,0.4c-0.1,0-0.3,0-0.3-0.1c0-0.1,0-0.2,0-0.3c-0.2,0.2-0.5,0.1-0.7,0c-0.1-0.2-0.2-0.5-0.5-0.7c0,0.1,0,0.4,0,0.5
        c-0.1-0.1-0.2-0.2-0.2-0.3c-0.3-0.1-0.6-0.2-0.7-0.4c-0.2,0-0.3,0-0.4-0.1c0,0.1-0.1,0.2-0.1,0.2c0-0.1,0-0.2,0-0.3
        c-0.2-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.4-0.2-0.6-0.3c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0.1-0.3,0.1c0.1-0.2,0.2-0.3,0.3-0.5
        c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1-0.4-0.7-0.2-0.7-0.7c-0.3-0.1-0.5-0.3-0.8-0.2c0,0,0-0.1,0-0.2c-0.2-0.1-0.4-0.2-0.6-0.1
        c-0.2-0.3-0.4-0.6-0.6-0.9c-0.3,0.1-0.5,0-0.8-0.1c0-0.1,0-0.2,0-0.2c-0.2,0-0.3,0-0.5,0.1c-0.2-0.1-0.3-0.3-0.5-0.4
        c0.1,0,0.4,0,0.5,0c-0.1-0.2-0.2-0.2-0.3-0.2c-0.3,0.1-0.6-0.1-0.8-0.2c0.2-0.1,0.5-0.2,0.7-0.3c-0.3-0.1-0.6,0.1-0.8,0.1
        c-0.2-0.1-0.4-0.2-0.7-0.3c0.1,0,0.3-0.1,0.5-0.2c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0.1-0.2,0.3-0.4,0.4l0-0.3c-0.1,0-0.2,0-0.3,0
        c0-0.1,0-0.2,0-0.2c-0.4,0.1-0.6-0.2-0.8-0.4c-0.3-0.1-0.7-0.2-0.8-0.6c0.1,0,0.3,0.1,0.4,0.1c0.2-0.3-0.4-0.4-0.5-0.3
        c0-0.2-0.1-0.3-0.3-0.3c0.3-0.2,0.2-0.5-0.1-0.5c0,0.2,0.1,0.6,0.1,0.8c-0.2-0.2-0.3-0.4-0.3-0.7c-0.2,0-0.4,0-0.6,0
        c0-0.1,0.1-0.2,0.1-0.3c-0.1-0.1-0.3-0.2-0.4-0.2c0.1,0,0.3,0,0.5-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.2-0.3-0.3-0.4
        c0,0.1,0.1,0.4,0.1,0.5c-0.1,0-0.3-0.1-0.4-0.1c0.1-0.1,0.2-0.2,0.3-0.3c-0.1-0.2-0.2-0.4-0.4-0.6c-0.1,0.1-0.2,0.2-0.3,0.2
        c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0-0.3-0.1-0.4c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1-0.3-0.1-0.6-0.4-0.7c0.1,0.2,0.1,0.4,0.2,0.5
        c-0.2-0.1-0.4-0.2-0.6-0.3c0.1-0.1,0.2-0.2,0.2-0.2c-0.1-0.2-0.1-0.4-0.2-0.6c0,0.1,0,0.2,0,0.3c-0.1,0-0.3,0-0.3,0l0,0.1
        c0.4,0.2,0,0.4-0.2,0.3c0-0.3,0-0.5,0.1-0.8c-0.4,0.2-0.6-0.1-0.8-0.3c0,0.1,0,0.3,0.1,0.4c-0.5,0.1-0.6-0.4-1-0.6
        c0.1,0,0.3-0.1,0.4-0.1c0.1,0.2,0.3,0.3,0.5,0.5c-0.1-0.2-0.1-0.3-0.1-0.5c-0.2,0-0.5-0.1-0.7-0.2c0,0.3-0.2,0.3-0.4,0.2
        c0.1-0.1,0.3-0.2,0.3-0.2c-0.6,0-0.6-0.7-0.9-1c0,0.1-0.1,0.3-0.1,0.4c-0.3-0.1-0.5-0.2-0.7-0.2c0-0.2-0.1-0.4-0.2-0.6
        c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2-0.2-0.5-0.3-0.8-0.3c0-0.1,0.1-0.3,0.1-0.5c-0.3,0.2-0.6,0.1-0.8-0.1c0-0.1,0-0.3,0-0.5
        c-0.2-0.1-0.4-0.2-0.5-0.3c0-0.1,0-0.3,0-0.4c-0.1,0-0.2-0.1-0.3-0.2c0.1,0.4,0.1,1,0.7,1c-0.2,0.4-0.5,0-0.8-0.1
        c-0.2-0.2-0.5-0.3-0.8-0.4c-0.2-0.1-0.3-0.2-0.5-0.4c-0.3-0.1-0.6-0.2-0.9-0.3c0-0.1,0-0.3,0.1-0.4c-0.1,0-0.3,0-0.4,0
        c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2,0-0.4-0.1-0.6-0.3c-0.4,0.2-0.9,0.1-1.2-0.2c-0.1,0-0.2,0.1-0.3,0.1l0-0.3c-0.1,0.1-0.3,0.2-0.4,0.2
        c0,0-0.1-0.1-0.1-0.1c0.1-0.1,0.2-0.2,0.3-0.3c-0.2-0.1-0.7,0.1-0.8-0.3l-0.2,0c0,0.1,0,0.3,0,0.4c-0.2-0.1-0.2-0.2-0.1-0.4
        c-0.4,0.1-0.7-0.2-1-0.4c-0.1,0.1-0.2,0.4-0.2,0.5c0.4-0.6-0.4-0.3-0.7-0.5c0,0.1,0,0.3,0,0.4c-0.3-0.2-0.6-0.7-1-0.4
        c-0.2,0-0.4,0-0.5-0.2c-0.3,0.2-0.7-0.1-1,0.2c0-0.1,0-0.2,0-0.3c-0.1,0-0.2,0-0.3,0.1l0.1,0.2c-0.3,0-0.5-0.1-0.7-0.2
        c-0.2,0.2-0.5,0.5-0.8,0.3l0.1,0.3c-0.1,0-0.3,0-0.4,0c0.1-0.2,0.2-0.4,0.2-0.6c-0.3,0.2-0.4,0.8-0.9,0.7l0-0.2
        c0.1,0,0.3-0.1,0.4-0.2c-0.1,0-0.4-0.1-0.5-0.2c0,0.1,0,0.2,0,0.2c-0.2,0-0.3,0-0.4,0.2c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.2-0.1-0.3-0.1
        c0.1,0.2,0.1,0.3,0.1,0.5c-0.1-0.1-0.3-0.1-0.4-0.2c0,0.1,0.1,0.3,0.2,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.3,0.1-0.6,0.1-0.9,0.2
        c-0.2,0.1-0.4,0.3-0.6,0.4c-0.1-0.2,0-0.4,0-0.6c-0.3,0.1-0.2,0.4-0.2,0.6c-0.2-0.1-0.3-0.1-0.5-0.1c0,0.1,0.1,0.3,0.2,0.3
        c-0.2,0-0.4,0-0.6-0.1c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0-0.2-0.1-0.3-0.2c0,0.1,0,0.3,0,0.4c0.2-0.1,0.4-0.1,0.6,0
        c-0.1,0.1-0.3,0.1-0.4,0.2c0,0.2-0.1,0.3-0.3,0.2c-0.1,0.2-0.2,0.3-0.4,0.4c-0.3-0.2-0.6,0.6-0.5-0.1c-0.2,0.1-0.3,0.3-0.4,0.5
        c-0.2,0.1-0.4,0.1-0.6,0.2c-0.1,0-0.2,0.1-0.3,0.2l0.1,0.1c-0.2,0-0.3,0.1-0.5,0.2c0-0.1,0-0.3,0-0.4c-0.3,0.1-0.4,0.2-0.3,0.4
        c0,0-0.1,0-0.2,0c0-0.1,0-0.3,0-0.3c-0.2,0.1-0.5,0.4-0.7,0.1c-0.1,0-0.2,0.1-0.4,0.1c0.3-0.6,0.6-1.2,0.7-1.9
        c0-0.1-0.1-0.2-0.1-0.2c0.5-0.1,0.2-0.7,0.5-1.1c0.1-0.5,0.5-0.8,1-1c0.1-0.1,0.1-0.3,0.2-0.4c0.5-0.4,1-0.8,1.6-1.2
        c0.3-0.4,0.8-0.5,1.2-0.7c0-0.1,0.1-0.2,0.1-0.3c0.7-0.1,1.2-0.6,1.8-0.7c0.5-0.1,1.1,0.2,1.5-0.3C404.8,81.3,405.2,81.1,405.7,81.2
        M409.1,83.6C409.2,84.1,409.6,83.4,409.1,83.6 M402.8,85.2c-0.3,0.2-0.6,0.3-0.8,0.6l0.3,0.1l0-0.2c0.3,0,0.5-0.1,0.8-0.1
        C403,85.4,402.9,85.3,402.8,85.2 M413.5,85.4c-0.1,0.2,0.2,0.4,0.4,0.3C414,85.5,413.7,85.3,413.5,85.4 M400.6,85.5
        c-0.1,0.1-0.2,0.2-0.3,0.4C400.5,85.9,400.6,85.7,400.6,85.5c0.2,0.1,0.4,0.2,0.5,0.2C401.2,85.4,400.9,85.5,400.6,85.5 M414.7,85.6
        C414.7,86.1,415.1,85.4,414.7,85.6 M416.7,87.1c0.2,0.3,0.6,0.4,0.9,0.6c0-0.3-0.3-0.4-0.5-0.6C416.9,87.1,416.8,87.1,416.7,87.1
        M396.8,88.3C396.7,88.8,397.2,88.1,396.8,88.3 M419.8,88.6c0,0.1,0,0.2,0,0.2c0.2,0,0.4,0,0.6,0C420.3,88.6,420,88.7,419.8,88.6
        M419.5,88.9C419.6,89,419.6,89,419.5,88.9 M427.2,94.4c0.1,0.2,0.2,0.2,0.4,0C427.5,94.2,427.4,94.2,427.2,94.4 M429,95.2
        c-0.1,0.2,0,0.3,0.2,0.2C429.3,95.2,429.2,95.1,429,95.2 M429.3,95.7c0.1,0.3,0.5,0.4,0.7,0.7C430,96,429.5,95.9,429.3,95.7
        M430.3,96.2c-0.1,0.1,0.1,0.4,0.3,0.3C430.6,96.4,430.4,96.1,430.3,96.2 M432.6,98C432.7,98.1,432.7,98.1,432.6,98 M433.3,98
        c-0.2,0.1,0,0.5,0.2,0.3C433.7,98.2,433.5,97.9,433.3,98 M434.5,99.3C434.6,99.4,434.6,99.4,434.5,99.3 M435,99.4
        C434.7,99.8,435.5,99.4,435,99.4 M435.4,99.3C435.2,99.7,435.9,99.3,435.4,99.3 M436,100c0.1,0.2,0.2,0.2,0.3,0
        C436.3,99.8,436.2,99.8,436,100 M436.5,100.3C436.6,100.4,436.6,100.4,436.5,100.3 M106.8,84.8C107.3,84.6,106.9,85.2,106.8,84.8
        M99.6,86.6c0.3-0.2,0.7-0.4,1-0.6c-0.1,0.2-0.2,0.3-0.3,0.5C100,86.5,99.8,86.6,99.6,86.6 M414.2,86c0.3,0.2,0.7,0.3,1,0.5
        c-0.2,0-0.4,0-0.6,0C414.4,86.3,414.3,86.2,414.2,86 M421.9,86.6c-0.2-0.1-0.3-0.5-0.1-0.6C422,86.2,422.1,86.6,421.9,86.6
        M194.7,86.8C194.8,86.9,194.8,86.9,194.7,86.8 M186.4,87.6c0-0.2,0.1-0.3,0.3-0.2C186.8,87.5,186.5,87.9,186.4,87.6 M185.5,87.9
        c0.2,0.1,0.3,0.2,0.4,0.4c-0.2-0.3-0.4-0.1-0.6,0C185.3,88.2,185.4,88,185.5,87.9 M328.9,88.3c0.1-0.1,0.2-0.4,0.4-0.4
        c0.1,0.1,0.1,0.3,0.2,0.4C329.3,88.2,329.1,88,328.9,88.3 M253.5,88.2l0.1-0.1c0.2,0,0.1,0.4-0.1,0.3
        C253.5,88.4,253.5,88.3,253.5,88.2 M183.3,88.6c0.2-0.1,0.3,0,0.2,0.2C183.3,88.9,183.2,88.8,183.3,88.6 M331.2,88.6
        c0.2-0.1,0.3,0,0.2,0.2C331.2,88.9,331.1,88.8,331.2,88.6 M312.7,89.4L312.7,89.4L312.7,89.4L312.7,89.4L312.7,89.4 M180.9,89.6
        c0.2,0,0.3,0,0.2,0.2C180.9,89.8,180.9,89.8,180.9,89.6 M421.1,90.2c0.2-0.1,0.6,0.1,0.5,0.3C421.4,90.6,421,90.4,421.1,90.2
        M178.8,90.3C178.9,90.4,178.9,90.4,178.8,90.3 M167.1,90.6c0.2,0.1,0.4,0,0.6-0.1c0,0.4-0.5,0.4-0.7,0.5
        C167,90.8,167,90.7,167.1,90.6 M347,90.6c0.2-0.3,0.4,0.2,0.7,0c0,0.1,0.1,0.3,0.1,0.3C347.5,90.9,347.2,90.7,347,90.6 M91.2,91.6
        c0.4-0.1,0.3,0.3,0,0.3C90.9,92.1,91,91.6,91.2,91.6 M423.3,91.6c0.2-0.1,0.5,0.1,0.4,0.3C423.6,92,423.2,91.8,423.3,91.6 M173.9,92
        c0-0.2,0.3-0.4,0.3-0.2C174.2,92,174,92.2,173.9,92 M340.6,91.8c0.2,0,0.3,0.1,0.3,0.2C340.8,92.3,340.4,91.9,340.6,91.8
        M250.7,92.1L250.7,92.1L250.7,92.1L250.7,92.1L250.7,92.1 M299.3,94c-0.1-0.6,0.7-0.2,0.6,0.2c0,0.3-0.1,0.6-0.4,0.7
        c0-0.3,0.1-0.5,0.1-0.8C299.5,94.1,299.3,94,299.3,94 M167.3,94.5c0.2,0,0.1,0.4-0.1,0.4C167,94.8,167.1,94.5,167.3,94.5
        M234.2,94.6C234.3,94.7,234.3,94.7,234.2,94.6 M346.7,94.6C346.8,94.7,346.8,94.7,346.7,94.6 M190.6,95.3c0-0.2,0.3-0.4,0.4-0.3
        C191.2,95.3,190.8,95.6,190.6,95.3 M190.3,95.7c-0.1-0.2-0.1-0.3,0.1-0.4C190.6,95.5,190.5,95.7,190.3,95.7 M248.4,96
        c0-0.2,0.1-0.5,0.3-0.6C248.8,95.6,248.6,96.1,248.4,96 M130.2,95.7C130.6,95.5,130.2,96.2,130.2,95.7 M151.1,95.7
        c0.1,0,0.3,0,0.4,0C151.7,96,150.9,96.1,151.1,95.7 M384.4,95.7C384.8,95.5,384.4,96.2,384.4,95.7 M163.6,96
        c0.4-0.1,0.2,0.4-0.1,0.4C163.2,96.4,163.4,96,163.6,96 M190.1,96c0.2-0.1,0.3,0.2,0.2,0.4C190.2,96.4,190,96.2,190.1,96
        M162.4,96.2C162.8,95.9,162.4,96.6,162.4,96.2 M352.1,96.2C352.5,95.9,352.2,96.6,352.1,96.2 M322.8,96.5c-0.1-0.2,0-0.3,0.2-0.3
        C323,96.5,323,96.5,322.8,96.5 M160.3,97.4c0.3-0.2,0.5-0.7,0.9-0.5c-0.1,0.2-0.3,0.2-0.5,0.2C160.6,97.2,160.5,97.4,160.3,97.4
        M353.7,97c0.1-0.6,0.6,0.2,0.8,0.4c-0.2,0-0.3-0.2-0.4-0.3C354,97.1,353.8,97.1,353.7,97 M160,97C160.4,96.8,160,97.5,160,97
        M354.5,97C354.9,96.8,354.6,97.5,354.5,97 M190.5,97.2c0.2-0.1,0.4,0.2,0.3,0.4C190.6,97.7,190.4,97.3,190.5,97.2 M356.1,97.6
        C356.2,97.7,356.2,97.7,356.1,97.6 M190.8,97.9L190.8,97.9L190.8,97.9L190.8,97.9L190.8,97.9 M357.5,97.9c0.2,0.1,0.2,0.2,0,0.3
        C357.3,98.1,357.3,98,357.5,97.9 M190.5,98.1c0.2-0.1,0.4,0.2,0.3,0.3C190.5,98.5,190.3,98.2,190.5,98.1 M156.2,98.1
        c0.2-0.1,0.3,0,0.2,0.2C156.2,98.4,156.1,98.4,156.2,98.1 M193.5,98.2C193.6,98.2,193.6,98.2,193.5,98.2 M340,98.2
        C340.1,98.2,340.1,98.2,340,98.2 M358.3,98.2c0.1-0.2,0.2-0.2,0.3,0C358.5,98.4,358.4,98.4,358.3,98.2 M162.4,98.4
        c0.6-0.1-0.1,0.6-0.2,0.2C162.3,98.6,162.4,98.5,162.4,98.4 M352.1,98.5c0.1-0.1,0.5,0,0.4,0.2C352.3,98.9,352,98.7,352.1,98.5
        M359.1,98.6C359.2,98.7,359.2,98.7,359.1,98.6 M230.1,98.7L230.1,98.7L230.1,98.7C230.1,98.8,230.1,98.7,230.1,98.7 M350.1,98.9
        c-0.1-0.2,0.2-0.4,0.3-0.2C350.6,98.9,350.3,99,350.1,98.9 M359.3,98.6c0.3,0.1,0.5,0.4,0.6,0.6c-0.4-0.1-0.8,0-1.2-0.1
        c0.1-0.1,0.2-0.3,0.3-0.4c0.2,0.1,0.3,0.2,0.6,0.2C359.5,98.9,359.4,98.7,359.3,98.6 M155.6,98.9c0.2-0.1,0.3,0.3,0.1,0.3
        C155.3,99.3,155.3,98.9,155.6,98.9 M152.4,99.3c0.2-0.1,0.3,0,0.2,0.2C152.4,99.6,152.3,99.5,152.4,99.3 M362.1,99.3
        c0.2-0.1,0.3,0,0.3,0.2C362.1,99.6,362,99.5,362.1,99.3 M362.6,99.4C363,99.2,362.7,99.9,362.6,99.4 M166.3,99.6
        C166.3,99.7,166.3,99.7,166.3,99.6 M259.5,99.6C260,99.6,259.3,100,259.5,99.6 M348.4,99.6C348.5,99.7,348.5,99.7,348.4,99.6
        M222.7,99.7C222.8,99.8,222.8,99.8,222.7,99.7 M346.2,100.3c0.2-0.1,0.3,0,0.2,0.2C346.2,100.6,346.1,100.5,346.2,100.3
        M258.6,100.9C258.7,100.9,258.7,100.9,258.6,100.9 M317.5,100.9C318,100.9,317.3,101.3,317.5,100.9 M190.1,101.4l0.1,0
        c0,0.1,0,0.2,0,0.2l-0.1,0C190.1,101.6,190.1,101.5,190.1,101.4 M187.8,102c-0.3-0.3,0.6-0.8,0.3-0.4C188.1,101.8,188,102,187.8,102
        M170.7,102.4c0.1-0.2,0.2-0.2,0.3,0C171,102.5,170.9,102.5,170.7,102.4 M334,103.4c0.2-0.3,0.2-0.7,0.5-0.8
        c-0.1,0.3-0.4,0.5-0.1,0.8C334.3,103.4,334.1,103.4,334,103.4 M342.5,103.7c0.2,0,0,0.6-0.2,0.5C342.2,104,342.4,103.8,342.5,103.7
        M96.7,104.1L96.7,104.1L96.7,104.1C96.8,104.2,96.7,104.1,96.7,104.1 M418,104.1C418,104.2,418,104.2,418,104.1 M332.6,105
        c-0.2-0.1-0.2-0.3,0-0.4C332.7,104.8,332.7,104.9,332.6,105 M307.9,105.7C308,105.8,308,105.8,307.9,105.7 M350.3,105.8
        c0.2-0.2,0.3,0.2,0.1,0.3C350.2,106.3,350.1,105.9,350.3,105.8 M208.5,106.3c0.1,0,0.3,0.1,0.3,0.2c0.2,0.2-0.1,0.3-0.2,0.5
        c0,0.1,0.1,0.2,0.2,0.3l-0.2,0c0,0.2,0,0.4-0.2,0.5c0-0.2,0.1-0.5,0.2-0.6c-0.4-0.1-0.1-0.2,0.1-0.3
        C208.6,106.6,208.5,106.4,208.5,106.3 M308.4,106.8C308.9,106.9,308.2,107.3,308.4,106.8 M225.7,107.1
        C225.8,107.2,225.8,107.2,225.7,107.1 M235.6,107.2c0-0.2,0.4-0.2,0.5,0c0.1,0.1,0,0.3-0.2,0.3c0,0-0.1-0.1-0.1-0.1
        C235.8,107.3,235.7,107.2,235.6,107.2 M116.4,107.2c0.1,0.1,0.2,0.2,0.2,0.3C116.5,107.8,116.2,107.3,116.4,107.2 M309.5,108
        c0.1-0.1,0.2-0.1,0.3,0.1C309.8,108.3,309.6,108.2,309.5,108 M341.5,108.4c-0.2-0.1-0.2-0.2,0-0.3
        C341.7,108.2,341.7,108.3,341.5,108.4 M412.1,108.1c0.2-0.1,0.4,0.3,0.2,0.3C412.2,108.3,412.1,108.2,412.1,108.1 M117.5,108.4
        c0.2-0.1,0.3,0,0.2,0.2C117.5,108.7,117.4,108.6,117.5,108.4 M397.2,108.4c0.2,0.1,0.2,0.2,0,0.3C397,108.6,397,108.5,397.2,108.4
        M250.9,108.8c-0.2-0.1,0-0.5,0.2-0.3C251,108.5,250.9,108.7,250.9,108.8 M198.9,108.8C199,108.9,199,108.9,198.9,108.8
        M236.7,109.1c0.2-0.1,0.3,0,0.2,0.2C236.7,109.4,236.6,109.3,236.7,109.1 M323.3,109.4c0.1,0,0.4,0,0.6-0.1
        c0.1,0.1,0.2,0.2,0.3,0.3l-0.3,0.1c-0.1-0.1-0.2-0.3-0.2-0.3c0,0.1-0.1,0.3-0.1,0.4l-0.3,0C323.3,109.8,323.3,109.5,323.3,109.4
        M249.9,110.2c-0.1-0.2,0-0.3,0.2-0.2C250.2,110.1,250.1,110.2,249.9,110.2 M323.2,110C323.7,110,323,110.4,323.2,110 M276.7,111
        c-0.2-0.2,0.1-0.4,0.3-0.3C277,110.9,276.8,111.1,276.7,111 M340.2,111.4c0.5,0.3,0.2,1,0.5,1.5c0,0.1,0,0.2,0,0.3
        c0.2,0.3,0.4,0.7,0.5,1.1c0.1-0.1,0.3,0,0.4,0c0,0-0.1,0.1-0.2,0.2c0.1,0.1,0.2,0.3,0.3,0.5l0.1,0l0,0.2l0.2,0l0,0.2l0.2-0.1
        c0,0.2,0,0.3,0,0.5l0.2-0.1c-0.1,0.2-0.2,0.7,0.2,0.7c0,0.1-0.1,0.2-0.1,0.3c0.1,0,0.2-0.1,0.3-0.1c0,0.1,0,0.3,0.1,0.4
        c0.2,0.1,0.3,0.3,0.4,0.5c-0.1,0.2-0.2,0.3-0.4,0.2c0,0,0,0.1,0.1,0.1c0.3,0,0.5,0,0.8,0c0,0.1-0.1,0.3-0.1,0.3c0.1,0,0.2,0,0.3,0
        c-0.1,0.4,0.3,0.5,0.4,0.8c0.1-0.1,0.2-0.2,0.2-0.3c0,0.1,0,0.3,0.1,0.4l0.2,0c-0.1,0.1-0.3,0.3-0.1,0.4c0.2,0.1,0.3,0.3,0.3,0.6
        c0.1,0,0.2-0.1,0.3-0.1c-0.1,0.3-0.2,0.5,0.2,0.6c0-0.1,0-0.3,0.1-0.4c0.1,0.2,0.1,0.4,0.1,0.7c0.1,0,0.3,0,0.3-0.1
        c0,0.1,0,0.2,0,0.2l0.2-0.1c0,0.2-0.3,0.5,0,0.6c0.1,0,0.3,0.1,0.4,0.1c0.2,0.2,0.3,0.6,0.5,0.8c-0.1-0.2-0.1-0.4,0-0.7
        c0.3,0.4,0.1,1,0.5,1.3l0.1-0.3l-0.2,0c0-0.1,0-0.2,0-0.3c0.2,0.1,0.4,0.1,0.6,0.2c-0.1,0-0.2,0.1-0.2,0.2c0.1,0.1,0.3,0.3,0.4,0.5
        c-0.1,0-0.4-0.1-0.5-0.1c0,0.1,0,0.2,0,0.2c0.1,0,0.2-0.1,0.2-0.1c0.2,0.2,0.3,0.1,0.4-0.1c0.2,0.3,0.6,0.4,0.7,0.8
        c0.1,0,0.2,0.1,0.3,0.1c-0.1,0.1-0.2,0.2-0.3,0.2c0.1,0.2,0.3,0.1,0.3-0.1c0.1,0,0.3,0,0.4,0c-0.1-0.2-0.3-0.4-0.4-0.6
        c0.5,0.2,0.6,0.8,0.3,1.2c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0.1,0.2,0.2,0.3,0.3c0-0.1,0-0.4,0-0.5c0,0.4,0.1,0.8,0.3,1.1
        c-0.1,0.3,0,0.4,0.3,0.5c-0.1-0.1-0.2-0.3-0.2-0.3c0.1,0,0.3-0.1,0.4-0.1c0,0.1,0.1,0.4,0.1,0.5c0.1-0.1,0.2-0.3,0.2-0.4
        c-0.1-0.2-0.4-0.2-0.6-0.3c0.1-0.1,0.3-0.1,0.4,0c0.1,0,0.2,0,0.3,0c0,0.3,0.2,0.4,0.4,0.5c-0.2,0-0.4,0.1-0.5,0.1
        c0.2,0.3,0.4,0.7,0.8,0.6c0,0.2-0.2,0.3-0.4,0.4c0.2,0,0.5-0.1,0.7-0.1c-0.1,0.1-0.1,0.3-0.2,0.4c0.1,0.2,0.3,0.4,0.5,0.5l0-0.4
        c0.5,0.1,0.1,0.4,0,0.6c0.1,0,0.3,0,0.4-0.1c-0.1,0.3,0.2,0.6-0.1,0.8c0,0,0,0.1,0.1,0.1c0.1-0.2,0.2-0.3,0.3-0.4
        c-0.1-0.1-0.2-0.2-0.2-0.3c0.2-0.3,0.4,0.1,0.5,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c0.4-0.1,0.7,0.1,0.9,0.4c0.1,0.3,0.4,0.2,0.6,0.2
        l0,0.1c-0.1,0-0.3,0-0.4,0c0,0.2,0.2,0.3,0.4,0.3c0,0.1,0,0.3,0,0.4c0.1-0.1,0.2-0.7,0.4-0.3c-0.1,0.2-0.2,0.4-0.4,0.6
        c0.3,0,0.6-0.1,0.9-0.2c0,0.1-0.2,0.3,0,0.5c0.1,0,0.3,0.1,0.3,0.2c-0.1,0.1-0.2,0.3-0.2,0.4c0.1,0,0.3,0,0.4-0.1
        c-0.1,0.1-0.4,0.4,0,0.3c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0.1,0,0.1,0c0,0.2,0.1,0.5,0.1,0.7c-0.1-0.1-0.3-0.2-0.4-0.3
        c-0.1,0.2-0.1,0.5-0.1,0.7c0.1-0.1,0.3-0.6,0.5-0.2c0.1,0.1,0,0.5,0.2,0.5c0.1,0,0.2-0.1,0.2-0.1c-0.1-0.1-0.3-0.2-0.4-0.2
        c0.1-0.2,0.4-0.1,0.5,0c0,0.1-0.1,0.4-0.1,0.5c0.1,0,0.3-0.1,0.3-0.1c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1-0.2-0.3-0.4-0.6-0.4
        c0.2,0.3,0.4,0.5,0.7,0.7c0.2-0.1,0.3-0.1,0.5-0.2c-0.1,0-0.4,0-0.5,0c-0.1-0.3,0.3-0.3,0.5-0.2c0,0.2,0,0.4,0.2,0.6
        c-0.1,0-0.3,0-0.3,0.1l0-0.2c-0.1,0.1-0.3,0.2-0.4,0.2c0.1,0.1,0.2,0.1,0.3,0c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.2-0.1,0.3-0.1
        c0,0.1,0,0.2,0,0.3c-0.1,0-0.4,0-0.5,0c0,0.3,0.4,0.3,0.5,0.1c0.2-0.1,0.2,0.3,0.4,0.4c-0.2-0.1-0.3-0.2-0.5-0.3
        c0,0.3,0.4,0.4,0.3,0.7c0.2,0.1,0.1,0.3,0.1,0.4c0.1,0,0.3,0.1,0.3,0.2c0.1,0,0.2,0,0.3,0c0,0.5,0.2,1,0.3,1.5
        c-0.1,0-0.2-0.1-0.3-0.2c0,0.1,0,0.2,0,0.2c0.1,0,0.2,0,0.3-0.1c0,0.1,0,0.3,0.1,0.4c-0.1,0-0.6-0.3-0.4,0l0.2,0.1l-0.1,0.2
        c0.1,0,0.3,0,0.4-0.1c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.1,0.3,0.1,0.4c0-0.1,0-0.3,0-0.4c0.2,0.1,0.2,0.2,0.2,0.5
        c0.1,0.2,0.2,0.5,0.1,0.7c-0.1,0-0.3,0-0.3,0c0.2,0.1,0.3,0.1,0.5,0.1l0,0.3c-0.1,0-0.4-0.1-0.5-0.1c0,0.1,0,0.2,0,0.3
        c0.2-0.1,0.3-0.1,0.5-0.2c-0.1,0.3-0.2,0.6-0.3,0.9c0.2-0.2,0.4-0.4,0.6-0.6c0,0.1,0,0.2,0.1,0.2c-0.5,0-0.1,0.3,0,0.5
        c-0.2-0.1-0.3-0.2-0.5-0.3c0,0.2,0,0.3,0,0.5l0.2,0c-0.1,0.3-0.3,0.6-0.2,1c-0.1,0-0.2-0.1-0.3-0.1c0,0.1,0,0.2,0.1,0.3l-0.3,0
        c0.2,0,0.4-0.1,0.6-0.2c0,0.2-0.1,0.4-0.3,0.4c0.1,0.2,0.3,0.4,0.2,0.7c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2,0-0.4,0-0.5,0
        c0.2,0,0.3,0,0.5,0c-0.1,0.2-0.2,0.5-0.2,0.6c0.1,0,0.2,0,0.3,0c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.2-0.4,0.3-0.4,0.6
        c0.3,0,0.4-0.2,0.6-0.4c-0.1,0.2,0,0.4-0.2,0.5c0,0.3-0.8,0.2-0.7,0.7l0.1,0c0.1-0.1,0.3-0.2,0.5-0.3c0,0.4-0.4,0.7-0.5,1.1
        c-0.1,0.3-0.2,0.6-0.5,0.7c0.1,0.1,0.1,0.2,0.2,0.3c-0.3,0.2-0.3,0.5-0.5,0.8c0.1,0,0.2,0,0.2,0c-0.1,0-0.3,0-0.3,0l-0.1,0.3
        c0.1,0,0.2,0.1,0.2,0.2l-0.1,0l-0.1,0c-0.1,0-0.2,0-0.2,0c0,0.1-0.1,0.3-0.2,0.4c-0.2-0.1-0.3-0.2-0.5,0c0.1,0.1,0.2,0.2,0.2,0.3
        c-0.2,0.2-0.5,0.1-0.8,0.3l0.5,0c-0.2,0.2-0.4,0.3-0.6,0.4c0,0.1,0.1,0.2,0.1,0.3c-0.2,0.1-0.4,0.3-0.5,0.5c0-0.1-0.1-0.3-0.2-0.3
        c-0.2,0.2-0.1,0.4,0,0.6c-0.1,0-0.3,0-0.5,0c-0.1,0-0.2-0.2-0.2-0.2c0.1,0.1,0.2,0.3,0.2,0.4c-0.2,0.1-0.4,0.1-0.6,0
        c0.1,0.1,0.2,0.2,0.3,0.3c-0.3,0.1-0.6,0.1-0.8,0c0.5,0.4-0.4,0.6-0.6,0.7c-0.1,0.1-0.2,0.2-0.2,0.2c-0.3,0-0.6,0.1-0.7,0.3
        c-0.1-0.1-0.3-0.1-0.4-0.1c0,0.1,0,0.2,0,0.3c-0.3,0-0.7,0-1,0l0,0.1c-0.3,0-0.7,0.1-1,0c-0.1,0-0.2,0.1-0.2,0.2
        c-0.2-0.1-0.4-0.5-0.5-0.1c-0.3-0.1-0.6-0.3-0.8-0.5c0,0-0.1,0.1-0.2,0.1c-0.2-0.2-0.4-0.3-0.6-0.4c-0.3-0.5-0.8-0.8-1.1-1.4
        c-0.5-0.9-0.8-1.9-0.8-2.9c-0.1-0.3,0.1-0.6,0-1c-0.1-0.3,0.1-0.6,0.1-0.9c0-0.5,0.2-1,0.3-1.5c0,0.1,0,0.2,0.1,0.3
        c-0.3,0.5-0.3,1-0.3,1.6c-0.2,0.2-0.2,0.5,0,0.8c0.2,0.4-0.1,0.9,0.2,1.3c0,0-0.1,0-0.1,0c0.1,0.2,0.1,0.5,0.2,0.7
        c0.2,0.4,0.3,0.8,0.4,1.1c0.1,0.2,0.1,0.4,0.3,0.4c0.1,0.2,0.2,0.5,0.5,0.6c0.2,0.2,0.4,0.4,0.5,0.7c0.2-0.1,0.3,0.1,0.4,0.3
        c0.4-0.1,0.7,0.3,1.1,0.3c0.4,0,0.9,0,1.3,0l0-0.1c0.2,0,0.5,0,0.7,0c-0.1-0.4,0.3,0,0.4,0c0-0.1,0-0.3,0-0.4
        c0.1,0.1,0.2,0.2,0.4,0.2c0-0.1,0-0.2-0.1-0.3c0.2-0.1,0.3-0.1,0.5-0.2c0-0.1,0-0.3,0-0.3c0,0.1,0.1,0.2,0.2,0.3
        c0.3,0,0.2-0.4,0.3-0.6c0.1,0.1,0.2,0.2,0.2,0.3c0.2-0.2,0.3-0.6,0.6-0.5c0-0.1,0-0.2,0-0.3c0.3,0.1,0.6-0.2,0.5-0.4
        c0.1,0,0.2,0.1,0.2,0.1c0.1-0.3,0.3-0.2,0.5,0c0-0.1,0-0.3-0.1-0.4c-0.1,0-0.3,0-0.3,0.1c0.2-0.1,0.5-0.2,0.5-0.5
        c0.1,0,0.2-0.1,0.3-0.1c0-0.1-0.1-0.2-0.1-0.3l0.2,0.1c0-0.2,0.1-0.3,0.1-0.5c0.2-0.1,0.2-0.3,0.2-0.5l0.3,0c0-0.1-0.1-0.2-0.1-0.3
        c0.1,0,0.2,0,0.2,0c0.3-0.9,0.6-1.9,0.8-2.9c0-0.3-0.1-0.6,0.1-0.9c-0.1-0.2-0.2-0.5-0.1-0.7c0.1-0.7-0.3-1.3-0.2-2
        c-0.2-0.4-0.6-0.7-0.3-1.2c-0.3-0.4-0.4-0.9-0.7-1.2c0.1-0.1,0.2-0.3,0.3-0.4c-0.5-0.3-0.6-0.8-0.6-1.3c-0.2-0.1-0.4-0.3-0.5-0.5
        c0.2-0.4-0.2-0.6-0.4-0.9c-0.1,0.1-0.2,0.2-0.3,0.3c0.1-0.1,0.1-0.3,0.2-0.4c-0.1-0.3-0.3-0.5-0.6-0.7c-0.1-0.3-0.1-0.6-0.2-0.9
        c-0.1,0.1-0.2,0.2-0.2,0.3l0.1-0.2c-0.2,0-0.3-0.1-0.4-0.2c0.1,0,0.3,0.1,0.5,0.1l-0.1-0.5c-0.1,0-0.2,0-0.3,0
        c-0.1-0.3-0.4-0.5-0.5-0.8c-0.1,0.2-0.1,0.4-0.2,0.7c0-0.3,0-0.6,0-0.9c-0.1,0-0.3,0-0.4,0c-0.1-0.1,0.1-0.3,0.1-0.4
        c-0.2-0.1-0.4-0.1-0.6-0.2c-0.1,0-0.2-0.1-0.3-0.2c0.1,0,0.2,0,0.4,0l0.1,0.2l0.2,0c0.2-0.6-0.7-0.4-1.1-0.4c0-0.1,0-0.2,0-0.3
        l0.1,0.2c0.1-0.1,0.2-0.1,0.3-0.2c-0.2-0.2-0.3-0.5-0.6-0.7c-0.1,0.1-0.2,0.2-0.3,0.2c0.1-0.1,0.2-0.2,0.2-0.3
        c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.4-0.5-0.5-0.7-0.8c-0.2-0.3-0.5-0.1-0.8-0.1l0-0.1c0.1,0,0.2,0,0.3,0l0.1-0.4
        c-0.2,0.2-0.4,0.2-0.6,0c0.1,0,0.3,0,0.4-0.1c-0.2-0.2-0.5-0.4-0.7-0.6c-0.1-0.2-0.3-0.5-0.3-0.7c-0.1,0-0.3-0.1-0.4-0.1
        c-0.2-0.1-0.4-0.1-0.5-0.2l0,0.2c-0.1-0.1-0.2-0.1-0.2-0.2c0.1-0.1,0.3-0.1,0.5-0.2c-0.3-0.2-0.4-0.7-0.8-0.9c-0.2,0-0.5,0-0.7,0.1
        c0-0.3,0.3-0.1,0.5-0.1c-0.1-0.1-0.2-0.4-0.2-0.5c-0.1,0.1-0.2,0.1-0.3,0.2c0-0.2,0.2-0.3,0.1-0.5c-0.1,0-0.3,0-0.4,0.1
        c0,0,0-0.1,0-0.2l0.3,0c-0.2-0.1-0.3-0.3-0.5-0.4c0-0.4-0.4-0.6-0.6-0.9c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.1,0.2-0.1,0.3-0.2
        c-0.5-0.1-0.5-0.7-0.8-1c0,0.1-0.1,0.2-0.2,0.3c0-0.1,0.1-0.2,0.1-0.2c-0.1-0.4-0.3-0.7-0.3-1.1c-0.4-0.4-0.4-0.9-0.5-1.4
        c-0.5-1.2-1.2-2.4-1.4-3.7c-0.4-0.8-0.8-1.6-0.9-2.5c0,0-0.1,0-0.1,0c0-0.6-0.3-1.2-0.3-1.9C340.4,111.9,340.1,111.7,340.2,111.4
        M344.4,119C344.2,119.4,344.9,119,344.4,119 M351,126.2c-0.2,0.1,0,0.5,0.2,0.3C351.4,126.4,351.2,126,351,126.2 M353.8,128.9
        C353.9,129,353.9,129,353.8,128.9 M355.5,132c0,0.2,0,0.3,0.2,0.2C355.8,132,355.7,131.9,355.5,132 M232.9,111.8
        C233,111.9,233,111.9,232.9,111.8 M235.1,112.1C235.2,112.2,235.2,112.2,235.1,112.1 M275.3,112.2
        C275.7,112,275.3,112.7,275.3,112.2 M275,112.4C275.1,112.5,275.1,112.5,275,112.4 M214.7,113L214.7,113l0.1,0L214.7,113L214.7,113
        M278.9,113.4c0-0.2-0.1-0.6,0.3-0.5c-0.1,0.2,0.1,0.4,0.1,0.6c-0.2,0.1-0.4,0.2-0.5,0.3C278.5,113.7,278.8,113.6,278.9,113.4
        M172.2,113.4C172.7,113.2,172.2,113.8,172.2,113.4 M236.2,113.4L236.2,113.4L236.2,113.4L236.2,113.4L236.2,113.4 M342.3,113.4
        C342.7,113.1,342.4,113.8,342.3,113.4 M99.8,113.5c0.3-0.1,0.1,0.5,0,0.1L99.8,113.5 M225.3,113.9C225.3,114,225.3,114,225.3,113.9
        M225.6,114C225.7,114,225.7,114,225.6,114 M267.2,114.2c0.2-0.1,0.3,0,0.2,0.2C267.2,114.5,267.2,114.4,267.2,114.2 M224.9,114.8
        c0-0.2,0.1-0.5,0.3-0.5C225.3,114.5,225.1,114.8,224.9,114.8 M274.7,115.1C275.1,115.1,274.4,115.5,274.7,115.1 M343.7,115.4
        c0.1-0.1,0.3-0.1,0.4,0C344.2,115.8,343.6,115.8,343.7,115.4 M208.7,116.3c0.2-0.1,0.4-0.3,0.7-0.4c0,0.5-0.6,0.6-0.7,1.1
        c-0.1,0-0.3-0.1-0.4-0.2C208.5,116.7,208.6,116.5,208.7,116.3 M343.7,115.9C344.2,115.9,343.5,116.4,343.7,115.9 M103.2,116.5
        c0.2-0.1,0.3,0,0.2,0.2C103.2,116.8,103.1,116.7,103.2,116.5 M411.3,116.5c0.2-0.1,0.3,0,0.2,0.2
        C411.3,116.8,411.3,116.7,411.3,116.5 M169.9,117.4c-0.3-0.1,0.1-0.5,0.3-0.4C170.3,117.1,170,117.3,169.9,117.4 M344.5,116.9
        c0.2,0.1,0.4,0.2,0.5,0.5c0.1,0.1,0.1,0.1,0.2,0.2c-0.1,0-0.2,0-0.3,0.1l0-0.3c-0.1,0.1-0.2,0.2-0.2,0.3
        C344.8,117.3,344.6,117.1,344.5,116.9 M169.5,117.4C170,117.2,169.6,117.9,169.5,117.4 M219.6,118.5
        C220,118.3,219.7,118.9,219.6,118.5 M345.8,118.6c0.3,0,0.5,0.3,0.4,0.5C345.9,119.2,345.9,118.8,345.8,118.6 M400.8,119.2
        c0.2-0.5,0.5,0.2,0.8-0.1l0,0.1c0.2,0,0.3,0.1,0.4,0.3c-0.2,0-0.5,0-0.7-0.1C401.1,119.3,401,119.2,400.8,119.2 M235.7,119.2
        C235.8,119.3,235.8,119.3,235.7,119.2 M107,119.6C107.1,119.7,107.1,119.7,107,119.6 M107.3,119.7c0.1-0.2,0.5,0,0.3,0.2
        C107.5,120.1,107.1,119.9,107.3,119.7 M407.2,119.6c0.1-0.2,0.4,0.1,0.3,0.3C407.3,120,407,119.8,407.2,119.6 M407.7,119.6
        C407.8,119.7,407.8,119.7,407.7,119.6 M346.7,119.7C347.2,119.5,346.8,120.2,346.7,119.7 M109.6,120c0.2,0,0.4,0.3,0.1,0.4
        C109.6,120.4,109.4,120,109.6,120 M346.6,120.6C347,120.4,346.6,121.1,346.6,120.6 M289.7,121.6
        C289.8,121.7,289.8,121.7,289.7,121.6 M255.5,122.2c0.1,0,0.2-0.1,0.3-0.1C255.9,122.2,255.5,122.4,255.5,122.2 M220.2,122.1
        L220.2,122.1C220.3,122.1,220.2,122.2,220.2,122.1L220.2,122.1 M169.8,122.3c0.2,0.1,0.2,0.2,0,0.3
        C169.6,122.5,169.6,122.4,169.8,122.3 M348.3,122.6C348.4,122.7,348.4,122.7,348.3,122.6 M169.3,122.7l0.2-0.1c0,0.1,0,0.4,0,0.5
        c-0.4,0-0.5,0.4-0.7,0.6c0-0.2-0.1-0.5-0.1-0.7c0.2,0.1,0.4,0,0.6-0.1C169.3,122.9,169.3,122.8,169.3,122.7 M299.5,122.9
        C299.6,123,299.6,123,299.5,122.9 M102.3,123.3c0.1-0.2,0.3,0,0.4,0.1C102.9,123.7,102.3,123.5,102.3,123.3 M299.4,123.2
        C299.7,122.9,299.4,123.7,299.4,123.2 M299.8,123.8c0.2-0.1,0.3,0,0.2,0.2C299.8,124.1,299.7,124,299.8,123.8 M411.7,123.7
        C412.1,123.5,411.7,124.2,411.7,123.7 M266.8,123.9C267.2,123.7,266.8,124.4,266.8,123.9 M350.4,124.6c0.1-0.1,0.4,0,0.3,0.2
        C350.6,125,350.3,124.8,350.4,124.6 M160.8,126.2c0.2-0.3,0.7-0.7,1.1-0.6C161.6,126,161.2,126.1,160.8,126.2 M171,125.6
        C171.4,125.3,171,126,171,125.6 M256.5,125.7c0.1-0.2,0.6,0,0.4,0.2C256.8,126,256.6,125.8,256.5,125.7 M166.4,126.2
        c0.2,0,0.3,0.1,0.5,0.2l0-0.2c0,0.4,0,0.8-0.4,1.1c0-0.1,0-0.3-0.1-0.4l0.2,0.1c0-0.1,0-0.4,0-0.6c-0.1,0-0.2,0-0.2,0
        c-0.1,0.2-0.1,0.6-0.5,0.6C166.2,126.7,166.3,126.5,166.4,126.2 M256.7,126.4c0.1-0.1,0.2-0.2,0.3-0.2c0,0.3,0.1,0.5,0.3,0.7
        C257,127,256.9,126.6,256.7,126.4 M108.4,126.4C108.5,126.5,108.5,126.5,108.4,126.4 M406.3,126.5
        C406.4,126.5,406.4,126.5,406.3,126.5 M204.4,126.9c-0.2-0.2,0.2-0.4,0.3-0.2C204.8,127,204.5,127.1,204.4,126.9 M223.9,126.7
        C224,126.8,224,126.8,223.9,126.7 M352.7,126.9c0.2-0.1,0.3,0,0.2,0.2C352.7,127.2,352.6,127.1,352.7,126.9 M111.5,127
        C112,126.8,111.6,127.5,111.5,127 M228.7,127.1c0.1,0,0.2-0.1,0.3-0.2C229.1,127.1,228.7,127.4,228.7,127.1 M299.1,127.6
        c-0.1-0.2,0-0.3,0.2-0.4C299.3,127.4,299.2,127.6,299.1,127.6 M228.7,127.6c0.2,0.1,0.2,0.2,0,0.3
        C228.5,127.8,228.5,127.7,228.7,127.6 M331.9,127.6c0.2-0.1,0.3,0,0.2,0.2C331.9,127.8,331.8,127.8,331.9,127.6 M332.2,127.9
        c0.2,0,0.2,0.4,0,0.4C332,128.2,332,127.9,332.2,127.9 M310.2,128c0.3-0.1,0.1,0.5,0,0.1L310.2,128 M204.7,128.1
        C205.1,127.9,204.6,128.6,204.7,128.1 M348,128.1C348.4,127.9,348,128.6,348,128.1 M164.9,128.7c-0.1-0.1,0-0.4,0.2-0.4
        C165.2,128.4,165.1,128.8,164.9,128.7 M299.2,128.8C299.3,128.9,299.3,128.9,299.2,128.8 M298.5,129.2c0.3,0.1,0.2,0.3,0.2,0.5
        c0.1-0.1,0.3-0.2,0.4-0.3c0,0.3-0.3,0.5-0.5,0.6c0-0.1,0-0.3,0-0.4l0.2,0C298.6,129.4,298.6,129.3,298.5,129.2 M116.3,129.8
        c0.2-0.2,0.3,0.2,0.2,0.4C116.3,130.4,116.1,130,116.3,129.8 M333,129.9c0.2-0.1,0.3,0,0.2,0.2C333,130.2,332.9,130.1,333,129.9
        M398.2,129.8c0.2-0.1,0.4,0.2,0.2,0.4C398.2,130.3,398.1,130,398.2,129.8 M116.2,130.9c-0.1-0.3,0.3-0.5,0.5-0.6
        C116.6,130.5,116.5,130.9,116.2,130.9 M398,130.4c0.2,0.1,0.6,0.3,0.6,0.6C398.3,130.9,398.2,130.5,398,130.4 M298.6,130.9
        c-0.1-0.1-0.2-0.5,0.1-0.4C298.9,130.6,298.9,131,298.6,130.9 M333,130.7c0.1-0.1,0.2-0.1,0.4-0.1c0,0.3,0.5,0.7,0.2,0.9
        c-0.1-0.2-0.4-0.4-0.1-0.5C333.3,130.9,333.1,130.8,333,130.7 M127,130.7C127.4,130.5,127,131.1,127,130.7 M156.3,131.1
        C156.8,131.2,156.1,131.5,156.3,131.1 M180.9,131.1C181,131.2,181,131.2,180.9,131.1 M358.3,131.1
        C358.8,131.2,358.1,131.6,358.3,131.1 M145,131.6c0.2-0.1,0.3,0,0.2,0.2C145.1,131.9,145,131.8,145,131.6 M127,131.9
        C127.5,131.9,126.7,132.3,127,131.9 M358.9,131.9C359,131.9,359,131.9,358.9,131.9 M387.6,131.9
        C388.1,131.9,387.4,132.3,387.6,131.9 M144.8,132.3c-0.1-0.2,0.2-0.3,0.3-0.5c0.2,0.1,0.1,0.3,0,0.5c0.2,0.2,0.5,0.3,0.3,0.6
        C145.1,132.8,145,132.6,144.8,132.3 M95.3,132.5c-0.1-0.1,0.1-0.4,0.3-0.3C95.7,132.3,95.5,132.6,95.3,132.5 M108.5,132.2
        L108.5,132.2L108.5,132.2L108.5,132.2L108.5,132.2 M414.4,132.2c0.2,0.1,0.2,0.2,0,0.3C414.2,132.4,414.2,132.3,414.4,132.2
        M98.1,132.7c-0.5,0.1-0.1-0.4,0.1-0.5l0.1,0.2c0.1,0,0.3-0.1,0.4-0.1l0,0.2c-0.1,0-0.4-0.1-0.5-0.1L98.1,132.7 M100.3,132.3
        L100.3,132.3L100.3,132.3L100.3,132.3L100.3,132.3 M101.8,132.4c0.3,0,0.5-0.2,0.8-0.1C102.3,132.4,101.9,132.8,101.8,132.4
        M416.4,132.4c0.2-0.6,0.7,0.5,0.2,0.3l0-0.3c-0.2,0-0.4,0.1-0.5,0.1l0-0.1C416.2,132.4,416.4,132.4,416.4,132.4 M91.7,132.4
        c0.2-0.1,0.3,0,0.2,0.2C91.7,132.8,91.6,132.7,91.7,132.4 M101.1,132.4C101.2,132.5,101.2,132.5,101.1,132.4 M160.6,132.4
        c0.3,0,0.6,0,0.9,0c0,0.1,0,0.3,0,0.4c-0.1,0-0.3,0-0.4,0c0,0.3,0,0.7-0.4,0.7c-0.1,0.1-0.2,0.2-0.3,0.2c0-0.2,0-0.5,0-0.6
        c0,0.1,0.1,0.3,0.2,0.4c0.2-0.2,0.3-0.5,0.4-0.7C160.9,132.6,160.7,132.5,160.6,132.4 M413.6,132.4
        C413.6,132.5,413.6,132.5,413.6,132.4 M103.1,132.6L103.1,132.6C103.1,132.6,103.1,132.7,103.1,132.6L103.1,132.6 M411.6,132.6
        C411.7,132.7,411.7,132.7,411.6,132.6 M145.6,132.7C146.1,132.8,145.3,133.1,145.6,132.7 M261,133.3c0.1-0.2,0.2-0.5,0.4-0.5
        C261.6,133.1,261.2,133.3,261,133.3 M87.7,133.1c-0.2-0.1-0.2-0.2,0-0.3C87.9,132.9,87.9,133,87.7,133.1 M88.2,132.9
        C88.3,133,88.3,133,88.2,132.9 M427,133.1c-0.2-0.1-0.2-0.2,0-0.3C427.2,132.9,427.2,133,427,133.1 M87,133c0.2,0,0.3,0.1,0.3,0.3
        C87.1,133.3,87,133.2,87,133 M223.8,133c0.2-0.1,0.3,0,0.2,0.2C223.8,133.3,223.7,133.2,223.8,133 M427.4,133.3
        c0-0.2,0.1-0.3,0.3-0.3C427.7,133.2,427.6,133.3,427.4,133.3 M160.1,133.3C160.2,133.4,160.2,133.4,160.1,133.3 M165.6,133.4
        c0,0.2,0,0.4,0,0.6c-0.1,0-0.2,0.1-0.3,0.1c0-0.2,0.1-0.3,0.2-0.4C165.5,133.6,165.6,133.5,165.6,133.4 M223.2,133.9
        c0-0.2,0-0.3,0.2-0.2C223.4,133.9,223.4,134,223.2,133.9 M299,134c0.1,0,0.3,0.1,0.3,0.1c-0.1,0.2-0.5,0.4-0.3,0.7
        c-0.1,0-0.2,0-0.3,0C298.8,134.5,298.9,134.3,299,134 M334.6,134.2c0.2-0.1,0.4,0.2,0.2,0.3C334.6,134.7,334.4,134.3,334.6,134.2
        M262.4,134.5c-0.2-0.1-0.2-0.2,0-0.3C262.6,134.3,262.6,134.4,262.4,134.5 M158.6,134.7c-0.2-0.2,0.2-0.5,0.3-0.2
        C158.8,134.7,158.7,134.8,158.6,134.7 M159,134.4c0.1-0.2,0.5,0,0.3,0.2C159.2,134.9,158.9,134.6,159,134.4 M221.5,135.2
        C222,135,221.6,135.7,221.5,135.2 M290.4,135.6c0.2,0,0.2,0.4,0,0.4C290.1,135.9,290.2,135.6,290.4,135.6 M298.8,136.3
        c0.2-0.1,0.3,0,0.3,0.2C298.8,136.5,298.8,136.5,298.8,136.3 M334.9,136.4C335,136.5,335,136.5,334.9,136.4 M299.2,136.7
        C299.3,136.8,299.3,136.8,299.2,136.7 M147.2,137.4c0-0.2,0-0.4,0.1-0.6c0,0.1,0.1,0.3,0.1,0.4c0.1,0.1,0.3,0.2,0.4,0.3
        c-0.1,0-0.2,0-0.2,0l-0.1,0C147.4,137.4,147.2,137.4,147.2,137.4 M128.2,137.2c0.2-0.1,0.3,0,0.2,0.2
        C128.2,137.5,128.2,137.4,128.2,137.2 M264.5,137.3c0.2-0.1,0.3,0,0.2,0.2C264.5,137.6,264.4,137.5,264.5,137.3 M386.1,137.7
        c-0.2-0.2,0.2-0.3,0.3-0.5c0,0,0.1,0.1,0.2,0.1C386.4,137.4,386.2,137.6,386.1,137.7 M163,137.4c0.3-0.1,0.2,0.5-0.1,0.4
        C162.7,137.8,162.9,137.5,163,137.4 M176.9,137.5C177.4,137.6,176.7,138,176.9,137.5 M359.7,137.5c0.3,0.1,0.4,0.4,0.4,0.6
        C359.7,138.1,359.7,137.7,359.7,137.5 M414.6,138.5c0-0.3,0.4,0,0.3,0.1C414.8,138.6,414.7,138.6,414.6,138.5 M95.3,138.9
        c0.2-0.2,0.6-0.3,0.9-0.4C95.9,138.7,95.6,138.9,95.3,138.9 M418.7,138.5c0.2,0,0.4,0.1,0.6,0.2C419.2,139,418.7,138.7,418.7,138.5
        M176.2,138.9C176.7,138.8,176,139.3,176.2,138.9 M409.9,139c0-0.3,0.5-0.1,0.4,0.1C410.1,139.2,410,139.1,409.9,139 M94.6,139.1
        c0.2-0.1,0.3-0.1,0.3,0.1C94.7,139.3,94.6,139.3,94.6,139.1 M162.3,139.1C162.4,139.2,162.4,139.2,162.3,139.1 M359.9,139.2
        C360,139.3,360,139.3,359.9,139.2 M360.1,139.6C360.1,139.7,360.1,139.7,360.1,139.6 M92.7,140.2C93.2,140,92.7,140.7,92.7,140.2
        M161,140.5C161.5,140.6,160.8,140.9,161,140.5 M191.8,140.5C192.3,140.6,191.6,141,191.8,140.5 M427.5,142.1c0.2,0,0.3,0,0.3,0.3
        C427.5,142.4,427.4,142.4,427.5,142.1 M135.4,142.5C135.5,142.6,135.5,142.6,135.4,142.5 M149.9,142.5c0.1,0,0.2,0.1,0.3,0.1
        c-0.1,0.2-0.1,0.3-0.2,0.5c-0.2,0-0.4-0.1-0.5-0.2c0.1,0,0.3,0,0.4,0C149.9,142.9,149.9,142.6,149.9,142.5 M153.1,142.5L153.1,142.5
        c0.1,0.2,0.1,0.5-0.1,0.6C152.9,142.9,152.9,142.7,153.1,142.5 M379.3,142.5C379.4,142.6,379.4,142.6,379.3,142.5 M100.9,142.8
        C101.3,142.6,100.9,143.3,100.9,142.8 M110.8,143C111.3,143,110.6,143.4,110.8,143 M359.5,143.1
        C359.6,143.1,359.6,143.1,359.5,143.1 M152.9,143.2C153.4,143.3,152.7,143.7,152.9,143.2 M86.5,143.4c0.3-0.1,0.2,0.4,0.1,0.5
        C86.4,143.9,86.2,143.4,86.5,143.4 M437.6,143.6C437.7,143.6,437.7,143.6,437.6,143.6 M289.7,143.6L289.7,143.6l0.2,0l0,0.1
        l-0.1,0.1C289.7,143.8,289.7,143.7,289.7,143.6 M293.2,143.8C293.7,143.8,293,144.2,293.2,143.8 M267.9,144c0.1,0,0.4-0.2,0.5,0
        c-0.1,0.1-0.3,0.2-0.5,0.2C267.9,144.2,267.9,144.1,267.9,144 M268.1,144.4c0.2,0,0.3,0.1,0.3,0.2
        C268.2,144.7,268.1,144.6,268.1,144.4 M136.7,144.8c0.2-0.1,0.3,0,0.3,0.2C136.7,145.1,136.6,145,136.7,144.8 M189.6,144.8
        c0.2-0.1,0.3,0,0.2,0.2C189.6,145,189.5,145,189.6,144.8 M377.8,144.8c0.2-0.1,0.3,0,0.2,0.2C377.8,145.1,377.7,145.1,377.8,144.8
        M403.5,144.9L403.5,144.9L403.5,144.9L403.5,144.9L403.5,144.9 M290.1,145.4c0.5-0.1-0.1,0.7-0.1,0.2
        C290,145.5,290.1,145.4,290.1,145.4 M136,146c-0.2-0.3,0.4-0.4,0.5-0.2C136.4,145.9,136.2,146.1,136,146 M293.8,145.8
        C294.3,145.8,293.6,146.2,293.8,145.8 M99.9,146.2C99.9,146.3,99.9,146.3,99.9,146.2 M414.8,146.2
        C414.9,146.3,414.9,146.3,414.8,146.2 M126.8,146.7c0.1-0.2,0.2-0.2,0.3,0C127,146.8,126.9,146.8,126.8,146.7 M137.3,146.5
        c0.1,0,0.2,0.1,0.4,0.1c-0.1,0.1-0.3,0.1-0.4,0.2C137.2,146.8,137.1,146.6,137.3,146.5 M293.8,146.5
        C293.9,146.6,293.9,146.6,293.8,146.5 M377.1,146.7c0.1-0.1,0.5-0.3,0.5,0C377.5,147,377.3,146.7,377.1,146.7 M387.6,146.6
        c0.1-0.2,0.2-0.2,0.3,0C387.8,146.8,387.7,146.8,387.6,146.6 M294,146.7C294.5,146.7,293.8,147.1,294,146.7 M289.8,146.9
        c0.2-0.1,0.6,0.4,0.3,0.5C289.9,147.4,289.7,147.1,289.8,146.9 M152,147.1c0.2-0.1,0.4,0.2,0.2,0.3C152,147.6,151.9,147.2,152,147.1
        M289.5,147.3c0.2-0.1,0.3,0,0.2,0.2C289.5,147.7,289.5,147.6,289.5,147.3 M137.7,147.5C137.8,147.6,137.8,147.6,137.7,147.5
        M157.5,147.5C157.9,147.3,157.5,148,157.5,147.5 M269.6,147.5c0.2-0.1,0.3,0,0.3,0.2C269.6,147.7,269.5,147.7,269.6,147.5
        M377,147.5C377.1,147.6,377.1,147.6,377,147.5 M295.6,147.7C295.7,147.8,295.7,147.8,295.6,147.7 M152.2,147.8
        c0.2-0.2,0.4,0.2,0.3,0.3C152.3,148.3,152,148,152.2,147.8 M137.9,148.5c0.2-0.2,0.4,0.1,0.3,0.3C138,148.9,137.7,148.6,137.9,148.5
        M376.6,148.7c-0.1-0.2,0-0.3,0.2-0.3C376.9,148.7,376.8,148.8,376.6,148.7 M402.9,148.5L402.9,148.5l0.1,0.1L402.9,148.5
        L402.9,148.5 M337.3,148.5c0.2,0.1,0.4,0.2,0.6,0.3c0,0,0.1-0.1,0.1-0.1c-0.1,0.3-0.1,0.6,0,0.9c0,0.1-0.1,0.2-0.1,0.3
        c0.3,0.1,0.1,0.5,0.2,0.7c-0.5-0.3-0.4-0.8-0.5-1.3l0.3,0.1c0-0.2,0-0.3,0-0.5C337.6,148.8,337.3,148.8,337.3,148.5 M426.8,148.9
        L426.8,148.9L426.8,148.9L426.8,148.9L426.8,148.9 M112,149.2C112.1,149.3,112.1,149.3,112,149.2 M388.3,149.6
        C388.4,149.7,388.4,149.7,388.3,149.6 M126.7,149.7c0.2,0.1,0.2,0.2,0,0.3C126.5,149.9,126.5,149.8,126.7,149.7 M387.9,149.8l0.1,0
        c0,0,0.1,0.1,0.2,0.2C388.2,150.2,387.7,150,387.9,149.8 M138.5,150c0.2-0.1,0.3,0,0.2,0.2C138.5,150.2,138.4,150.2,138.5,150
        M289.4,150.7c0.1-0.3-0.1-0.7,0.3-0.9C289.5,150.1,289.6,150.5,289.4,150.7 M376.1,149.9c0.2,0.1,0.2,0.2,0,0.3
        C376,150.1,376,150,376.1,149.9 M58.7,150.8c-0.2,0-0.6-0.5-0.3-0.6C58.5,150.4,58.7,150.6,58.7,150.8 M126.4,150.4
        c0.2-0.1,0.3,0,0.2,0.2C126.5,150.7,126.4,150.7,126.4,150.4 M388.1,150.4c0.2-0.1,0.3,0,0.2,0.2C388.1,150.8,388,150.7,388.1,150.4
        M289.2,151.2c-0.2-0.1-0.2-0.2,0-0.3C289.4,151,289.4,151.1,289.2,151.2 M423.2,151.3C423.3,151.4,423.3,151.4,423.2,151.3
        M92.8,151.4C92.9,151.5,92.9,151.5,92.8,151.4 M94.6,151.5C94.7,151.6,94.7,151.6,94.6,151.5 M138.9,151.5
        C139.4,151.5,138.7,151.9,138.9,151.5 M240.5,151.8c-0.1-0.2-0.1-0.3,0.1-0.3C240.7,151.7,240.7,151.8,240.5,151.8 M420.1,151.5
        C420.2,151.6,420.2,151.6,420.1,151.5 M421.9,151.4C422,151.5,422,151.5,421.9,151.4 M270.9,152c-0.1-0.1-0.1-0.4,0.1-0.4
        C271.2,151.6,271.1,152,270.9,152 M288.4,152.3c0.2,0,0.3,0,0.5,0c0,0,0,0.1,0,0.1l-0.2,0c0,0.1,0.1,0.4,0.1,0.5
        C288.6,152.7,288.4,152.5,288.4,152.3 M288.2,153.1c-0.2-0.1,0-0.6,0.2-0.4C288.6,152.9,288.4,153.3,288.2,153.1 M155,153.3
        c0.2,0,0.3,0,0.4,0.1C155.4,153.7,154.8,153.5,155,153.3 M271.4,153.2C271.8,153,271.4,153.7,271.4,153.2 M288,153.4
        c0.2,0.2,0.4,0,0.6-0.1c-0.3,0.3-0.3,0.8-0.6,1.1C287.8,154.1,288.2,153.7,288,153.4 M338.2,153.3c0.2,0,0.2,0.4,0,0.4
        C338,153.7,338,153.4,338.2,153.3 M108.3,154c0-0.2,0.3-0.4,0.5-0.3C108.9,153.9,108.5,154,108.3,154 M295.4,153.6
        c0.2,0.1,0.3,0.2,0.5,0.3c-0.2,0.1-0.4,0.4-0.7,0.4c0.1-0.1,0.3-0.4,0.4-0.5c-0.1,0-0.2,0-0.3-0.1L295.4,153.6 M324.5,153.8
        C324.6,153.8,324.6,153.8,324.5,153.8 M288.4,153.9C288.8,154,288.1,154.3,288.4,153.9 M374.4,153.9c0.1,0,0.2,0.1,0.3,0.1
        c0,0.1,0.1,0.2,0.1,0.3c-0.2,0.1-0.3,0.3-0.3,0.6c-0.2-0.1-0.2-0.7,0.2-0.7C374.6,154.1,374.5,154,374.4,153.9 M343.5,154.1
        c0.2,0.1,0.2,0.2,0,0.3C343.4,154.3,343.5,154.1,343.5,154.1 M254.7,154.8c0.1,0.1,0.1,0.2-0.1,0.3
        C254.5,154.9,254.5,154.8,254.7,154.8 M140.5,154.9c0.2-0.1,0.3,0,0.2,0.2C140.5,155.1,140.5,155.1,140.5,154.9 M388.2,154.8
        c0.2-0.1,0.3,0.3,0.2,0.4C388.2,155.3,388.1,155,388.2,154.8 M67.9,155.6C68.3,155.4,67.9,156.1,67.9,155.6 M68.6,155.6
        c0.2,0.1,0.2,0.2,0,0.3C68.4,155.8,68.4,155.7,68.6,155.6 M295.4,155.6C295.5,155.7,295.5,155.7,295.4,155.6 M444,155.6
        c0.1,0.1,0.1,0.2,0.2,0.3c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0.1-0.3,0.3-0.4,0.4c-0.1-0.1-0.2-0.2-0.4-0.3
        C443.5,155.9,443.8,155.8,444,155.6 M446.7,155.6c0.2-0.2,0.3,0.2,0.1,0.3C446.6,156.1,446.5,155.7,446.7,155.6 M66.2,155.8
        c0.2,0.1-0.2,0.6-0.4,0.4C65.7,156,66,155.8,66.2,155.8 M448.5,155.8c0.2,0,0.5,0.2,0.4,0.4C448.7,156.3,448.5,156,448.5,155.8
        M72.7,155.9C72.8,156,72.8,156,72.7,155.9 M126.5,155.9c0.4,0-0.1,0.4-0.3,0.4C126,156.2,126.3,155.9,126.5,155.9 M441.9,155.8
        C442.3,155.6,441.9,156.3,441.9,155.8 M73.1,156C73.6,155.8,73.1,156.5,73.1,156 M441.4,156C441.8,155.8,441.4,156.5,441.4,156
        M407.4,156.4c0.1-0.2,0.3-0.4,0.6-0.2l0,0.1l0,0.1l0,0.2C407.7,156.5,407.5,156.4,407.4,156.4 M426.2,156.4c0.3-0.2,0.7,0,1.1,0.1
        c0,0.1,0.1,0.2,0.1,0.2C427,156.5,426.6,156.5,426.2,156.4 M126.4,156.6c0.2-0.1,0.3,0,0.2,0.2C126.4,156.8,126.4,156.8,126.4,156.6
        M337.9,157.3c0.1-0.3,0.2-0.7,0.5-0.8c-0.1,0.3-0.3,0.5-0.4,0.8c-0.1,0.2-0.1,0.3-0.2,0.5C337.5,157.8,337.9,157.5,337.9,157.3
        M77.1,156.8c0.2-0.1,0.3,0,0.3,0.2C77.1,157.1,77,157,77.1,156.8 M292.4,156.8c0.1,0,0.2,0.1,0.2,0.1c0,0.1,0,0.2,0,0.3
        c-0.1,0-0.3,0-0.3,0c0,0.1,0,0.4,0.1,0.5c-0.2-0.1-0.3-0.3-0.4-0.4C292.2,157.1,292.4,157,292.4,156.8 M247.8,157.6
        C248.2,157.6,247.5,158,247.8,157.6 M126.3,157.9c0.2-0.1,0.3,0,0.3,0.2C126.3,158.2,126.2,158.1,126.3,157.9 M388.2,157.9
        c0.2-0.1,0.3,0,0.2,0.2C388.2,158.2,388.1,158.1,388.2,157.9 M291.8,158.2c0.2,0,0.3-0.1,0.5-0.1c0,0,0,0.1,0,0.2l-0.3-0.1
        c0,0.1,0.1,0.3,0.1,0.4c0,0.1-0.1,0.3-0.2,0.4c-0.1,0-0.2,0-0.2,0c0,0.1,0.1,0.2,0.1,0.3c-0.1,0-0.3,0-0.4,0
        c0.1-0.2,0.2-0.4,0.4-0.4C291.9,158.7,291.8,158.4,291.8,158.2 M141.4,158.1c0.3,0.1,0.4,0.2,0.4,0.5c0.1,0,0.2-0.1,0.2-0.2
        c-0.1,0.2-0.6,1.1-0.3,0.2c-0.1,0-0.4,0.1-0.5,0.1c0-0.1,0.1-0.2,0.1-0.3C141.4,158.4,141.4,158.2,141.4,158.1 M295.5,158.8
        c-0.1-0.1,0-0.5,0.2-0.3C295.9,158.6,295.7,158.9,295.5,158.8 M448.7,159C449.1,158.7,448.7,159.5,448.7,159 M286,159.9
        c-0.2,0-0.1-0.4,0.1-0.3C286,159.7,286,159.8,286,159.9 M201.1,160.1c0.1-0.1,0.4,0.1,0.4,0.3C201.3,160.5,201.1,160.3,201.1,160.1
        M142.8,160.5c0.2-0.1,0.4,0.2,0.3,0.4C142.9,161,142.6,160.7,142.8,160.5 M371.6,160.9c-0.1-0.2,0.1-0.5,0.3-0.4
        C372.1,160.7,371.8,161,371.6,160.9 M115.7,160.7c0.1,0,0.3,0,0.4,0c0,0.2,0,0.3-0.2,0.3C115.8,161,115.7,160.9,115.7,160.7
        L115.7,160.7 M291.1,160.7c0.2,0,0.3,0,0.3,0.2C291.3,161.2,291,160.9,291.1,160.7 M398.5,161c0.2-0.1,0.3,0.2,0.2,0.3
        C398.5,161.5,398.3,161.1,398.5,161 M142.8,161.2c0.2-0.1,0.4,0.2,0.2,0.3C142.8,161.6,142.6,161.3,142.8,161.2 M290.8,161.1
        C291.3,160.9,290.8,161.6,290.8,161.1 M116.2,161.6C116.6,161.5,116,162,116.2,161.6 M398.5,161.6
        C398.9,161.6,398.2,162,398.5,161.6 M446.7,161.9c0.1,0,0.2-0.1,0.3-0.2C447.2,161.9,446.8,162.1,446.7,161.9 M285.3,162
        c0.2,0.4-0.4,0.6-0.7,0.7C284.6,162.4,285.1,162.3,285.3,162 M337.8,162c0.2-0.2,0.5,0.1,0.3,0.3C337.9,162.5,337.6,162.2,337.8,162
        M54.2,162.3c0.1-0.2,0.5-0.2,0.5,0C54.7,162.6,54.1,162.6,54.2,162.3 M460.1,162.2c0.1-0.2,0.5-0.1,0.5,0.1
        C460.5,162.7,459.9,162.5,460.1,162.2 M388.4,162.4c0.2-0.1,0.3,0,0.2,0.2C388.4,162.7,388.3,162.6,388.4,162.4 M337.7,162.8
        c0.1,0,0.2-0.1,0.3-0.1c0.1,0.3,0,0.6,0,0.9c-0.1-0.2-0.1-0.5-0.1-0.7L337.7,162.8 M116.2,163.2c-0.2-0.1-0.2-0.2,0-0.4
        C116.4,162.9,116.4,163,116.2,163.2 M69.6,163C69.7,163.1,69.7,163.1,69.6,163 M445.1,163C445.2,163.1,445.2,163.1,445.1,163
        M87.5,163.5c0.1,0,0.3,0,0.4,0l0,0.1C87.8,163.8,87.4,163.7,87.5,163.5 M426.7,163.6c0,0,0.1-0.1,0.2-0.2l0.1,0.1l0,0.1
        C427,163.8,426.8,163.8,426.7,163.6 M52,163.5c0.2-0.1,0.3,0,0.3,0.2C52.1,163.8,52,163.8,52,163.5 M70.8,164.3
        C71.3,164.3,70.6,164.7,70.8,164.3 M50.4,164.5c0.5-0.2,0.2,0.6-0.1,0.2C50.3,164.6,50.4,164.5,50.4,164.5 M343,164.4
        c0.1-0.2,0.5,0,0.3,0.2C343.2,164.9,342.8,164.6,343,164.4 M464.2,164.4c0.2-0.1,0.4,0.2,0.2,0.3C464.2,164.9,464,164.6,464.2,164.4
        M116,164.8c0.1,0.2,0.1,0.4,0.2,0.6c-0.1-0.1-0.2-0.3-0.3-0.3c-0.1,0.2-0.2,0.4-0.4,0.5c0.1,0.1,0.2,0.1,0.3,0.2
        c0.1-0.1,0.3-0.2,0.4-0.3c-0.1,0.2-0.2,0.5-0.2,0.7c-0.3-0.2-0.6-0.3-0.6-0.7C115.3,165.1,115.9,165.1,116,164.8 M442.7,165
        c0.1,0,0.2,0,0.3,0c0,0.2-0.4,0.3-0.6,0.4C442.3,165.2,442.6,165,442.7,165 M126.6,165.1c0.2,0.2,0,0.5-0.2,0.5
        C126.4,165.5,126.5,165.3,126.6,165.1 M388.1,165.2c0.2-0.1,0.3,0.3,0.3,0.4C388.1,165.7,387.9,165.3,388.1,165.2 M283.7,165.5
        c0.1,0,0.3,0,0.4,0c-0.1,0.2-0.2,0.3-0.3,0.4C283.7,165.8,283.7,165.6,283.7,165.5 M145.5,166.1c0.3-0.1,0.2,0.3,0,0.4
        C145.2,166.7,145.3,166.2,145.5,166.1 M283.3,166.9c0-0.4,0.2-0.7,0.5-0.9C283.8,166.4,283.5,166.7,283.3,166.9 M337.2,167.3
        L337.2,167.3L337.2,167.3L337.2,167.3L337.2,167.3 M46.5,167.4c0.2-0.1,0.3,0,0.2,0.2C46.5,167.6,46.4,167.6,46.5,167.4 M46.1,167.5
        c0.2,0,0.1,0.4-0.1,0.4C45.8,167.9,46,167.5,46.1,167.5 M468.5,167.6c0.2-0.1,0.3,0.2,0.2,0.3C468.6,168,468.4,167.7,468.5,167.6
        M282.8,168.6c-0.6-0.1,0-0.5,0.1-0.7c0,0.1,0,0.2,0,0.3c0.1,0,0.2,0.1,0.2,0.1l-0.3-0.1C282.8,168.3,282.8,168.5,282.8,168.6
        M145.2,168.1C145.7,168.2,144.9,168.5,145.2,168.1 M369.5,168.1c0.2,0.1,0.2,0.2,0,0.3C369.3,168.3,369.3,168.2,369.5,168.1
        M208.6,168.8C209.1,168.6,208.7,169.3,208.6,168.8 M44.7,169.1C44.7,169.2,44.7,169.2,44.7,169.1 M470,169.1
        C470.1,169.2,470.1,169.2,470,169.1 M402.7,169.7C403.2,169.4,402.7,170.1,402.7,169.7 M423.4,169.8
        C423.4,169.9,423.4,169.9,423.4,169.8 M91.5,170.6C91.8,170.3,91.9,170.9,91.5,170.6L91.5,170.6 M281.9,170.5c0.1,0,0.3,0,0.4,0
        c-0.1,0.2-0.2,0.3-0.3,0.5c0,0-0.1,0-0.1,0C281.9,170.9,281.9,170.7,281.9,170.5 M423,170.6l0.1-0.1l0.1,0.1c0,0-0.1,0.1-0.1,0.2
        l-0.1-0.1L423,170.6 M109,170.9c0.1-0.1,0.8-0.3,0.6,0.1C109.4,171,109.2,171,109,170.9 M341.6,170.9L341.6,170.9L341.6,170.9
        L341.6,170.9L341.6,170.9 M198.8,171.1C199.2,170.9,198.8,171.6,198.8,171.1 M344.2,171c0.1,0,0.3,0.1,0.3,0.2c0,0.2,0,0.4,0,0.7
        c0,0-0.1-0.1-0.1-0.1C344.4,171.5,344.3,171.2,344.2,171 M368.9,171.1c0.2-0.1,0.3,0,0.2,0.2C368.8,171.4,368.8,171.3,368.9,171.1
        M341.4,171.2c0.2-0.1,0.2,0,0.2,0.2C341.4,171.5,341.3,171.4,341.4,171.2 M194.4,171.9c0-0.2,0-0.3,0.2-0.2c0,0.1,0,0.3,0.1,0.4
        C194.6,172,194.4,171.9,194.4,171.9 M336.5,172.1c-0.2,0.1-0.4-0.3-0.2-0.4C336.5,171.7,336.6,171.9,336.5,172.1 M472.6,171.6
        c0.2,0,0.3,0.1,0.5,0.2c0.1,0.2,0.1,0.5,0.1,0.7c0.1,0.2,0,0.3-0.2,0.3c-0.1-0.2-0.1-0.5-0.1-0.7
        C472.8,171.9,472.6,171.7,472.6,171.6 M146.5,171.8c0.2-0.1,0.3,0,0.2,0.2C146.5,172.1,146.4,172.1,146.5,171.8 M194.5,172.5
        c-0.3-0.2-0.9-0.4-0.6-0.8C193.9,172.1,194.6,172.1,194.5,172.5 M368,171.8c0.2-0.1,0.3,0,0.2,0.2C368,172.1,368,172.1,368,171.8
        M387.8,172c-0.1-0.2,0.2-0.4,0.4-0.3C388.2,171.9,388,172.1,387.8,172 M41,172.7c-0.1-0.1,0-0.4,0.2-0.3c0.1,0.1,0.1,0.2,0.1,0.3
        C41.2,172.7,41.1,172.7,41,172.7 M335.9,174.6c-0.1-0.4,0.2-0.7,0.5-0.8c0,0.2-0.3,0.3-0.4,0.4c0.1,0.1,0.2,0.2,0.3,0.3
        C336.1,174.6,336,174.6,335.9,174.6 M92.4,174.1C92.5,174.2,92.5,174.2,92.4,174.1 M422.2,174.1
        C422.3,174.2,422.3,174.2,422.2,174.1 M387.9,174.3c0.2-0.1,0.3,0,0.2,0.2C387.9,174.6,387.8,174.5,387.9,174.3 M403,174.6
        c-0.1-0.2-0.1-0.3,0.1-0.4C403.2,174.4,403.1,174.5,403,174.6 M450.5,174.2C450.6,174.3,450.6,174.3,450.5,174.2 M474.1,174.3
        C474.6,174.3,473.9,174.7,474.1,174.3 M460.1,174.4c0.1,0,0.2,0,0.2,0C460.6,174.7,459.9,174.7,460.1,174.4 M65.7,174.7
        C65.8,174.7,65.8,174.7,65.7,174.7 M115.6,174.8c0.2-0.1,0.3,0,0.2,0.2C115.6,175.1,115.5,175,115.6,174.8 M398.9,174.8
        c0.2-0.1,0.3,0,0.2,0.2C398.9,175.1,398.8,175,398.9,174.8 M403.3,174.9C403.4,175,403.4,175,403.3,174.9 M115.6,175.8
        c-0.1-0.1,0.1-0.4,0.2-0.3C115.9,175.6,115.8,175.9,115.6,175.8 M398.9,175.5c0.2-0.1,0.4,0.2,0.2,0.3
        C399,175.9,398.8,175.6,398.9,175.5 M71.1,175.7C71.2,175.7,71.2,175.7,71.1,175.7 M39.7,175.8C39.8,175.9,39.8,175.9,39.7,175.8
        M475,175.8C475.1,175.9,475.1,175.9,475,175.8 M266.3,177.2c-0.1-0.3-0.2-0.6,0-0.9c0,0.1,0.1,0.3,0.1,0.4
        C266.6,176.8,266.6,177.1,266.3,177.2 M399.4,176.6c-0.2,0.2-0.4-0.3-0.1-0.3c0,0.1,0.1,0.2,0.2,0.2L399.4,176.6 M115.5,177.1
        c-0.1-0.2-0.1-0.3,0.1-0.3C115.7,177,115.6,177.1,115.5,177.1 M399.1,176.8c0.2-0.1,0.3,0.3,0.2,0.4
        C399.1,177.2,399,176.9,399.1,176.8 M365.5,177.1c0.3,0,0.1,0.4-0.1,0.4c-0.1,0-0.2,0-0.3-0.1C365.2,177.3,365.3,177.1,365.5,177.1
        M111.1,177.7C111.2,177.8,111.2,177.8,111.1,177.7 M365.1,177.7c0.1-0.1,0.4,0,0.3,0.2C365.2,178.1,364.9,177.9,365.1,177.7
        M127.1,178.2c-0.2-0.1-0.2-0.2,0-0.3C127.4,178,127.4,178.1,127.1,178.2 M115.5,178.1C115.9,177.8,115.5,178.5,115.5,178.1
        M215.7,178.1C215.8,178.2,215.8,178.2,215.7,178.1 M115.4,178.6c0.1,0,0.2,0.1,0.3,0.1c-0.1,0.2-0.1,0.4-0.3,0.6l0.1-0.4l-0.2,0.1
        C115.3,178.8,115.3,178.7,115.4,178.6 M399.1,178.7c0.1,0,0.2-0.1,0.3-0.1c0,0.1,0.1,0.2,0.1,0.3l-0.2-0.1l0.1,0.4
        C399.2,179.1,399.2,178.9,399.1,178.7 M473.1,178.8C473.2,178.9,473.2,178.9,473.1,178.8 M38,178.9c0.2-0.1,0.3,0,0.3,0.2
        C38,179.2,37.9,179.1,38,178.9 M364.6,179c0.2,0,0.4,0,0.4,0.2c-0.4,0.1-0.8,0.1-0.9,0.5c-0.1,0-0.2,0.1-0.2,0.1
        C363.9,179.4,364.3,179.2,364.6,179 M476.5,178.9c0.2-0.1,0.3,0,0.2,0.2C476.5,179.2,476.4,179.2,476.5,178.9 M40.6,179.3
        c-0.1-0.2,0.2-0.3,0.3-0.2C41,179.2,40.7,179.4,40.6,179.3 M263.9,180.3c-0.2-0.4,0.1-0.9,0.2-1.3c0.1,0,0.4,0,0.5,0l0,0.2
        c-0.1,0-0.2,0-0.3,0l-0.1,0.3c0.1,0,0.2,0,0.3,0c0,0,0,0.1,0,0.1c-0.1,0-0.3,0-0.4,0c0,0.1,0.1,0.4,0.2,0.6
        C264.2,180.1,264.1,180.2,263.9,180.3 M473.8,179.1c0.2-0.1,0.5,0.2,0.3,0.3C473.9,179.3,473.8,179.2,473.8,179.1 M76.5,179.2
        C76.6,179.3,76.6,179.3,76.5,179.2 M280.2,179.2C280.3,179.3,280.3,179.3,280.2,179.2 M339.6,179.3c0.2-0.2,0.4,0.3,0.1,0.3
        C339.7,179.5,339.6,179.3,339.6,179.3 M280.3,179.5L280.3,179.5L280.3,179.5L280.3,179.5L280.3,179.5 M477.3,179.5
        C477.4,179.6,477.4,179.6,477.3,179.5 M127.4,179.6C127.9,179.7,127.2,180,127.4,179.6 M280,179.8c0.1,0,0.3,0,0.4,0
        c-0.2,0.2,0,0.5,0.1,0.7c0,0.1,0,0.3,0,0.3l0,0c-0.1-0.2-0.3-0.4-0.5-0.6C279.8,180.1,279.9,180,280,179.8 M114.5,180.2
        c0.2-0.1,0.4-0.2,0.5-0.3c0,0.2,0,0.4,0,0.6c-0.1,0.1-0.3,0.2-0.4,0.3c0.1-0.2,0.2-0.4,0.3-0.6C114.9,180.2,114.7,180.2,114.5,180.2
        M333.8,181.6L333.8,181.6L333.8,181.6L333.8,181.6L333.8,181.6 M151.7,181.8c0.3,0,0.2,0.2,0.3,0.4c-0.1,0.1-0.2,0.1-0.4,0.2
        C151.8,182.3,151.8,182.1,151.7,181.8 M249.8,181.9c0.2-0.1,0.3,0,0.2,0.2C249.8,182.1,249.7,182.1,249.8,181.9 M334.2,181.9
        c0.2,0.1,0.2,0.2,0,0.3C334,182.1,334,182,334.2,181.9 M362.8,182.3c0-0.2,0-0.4,0.3-0.4c-0.1,0.2-0.1,0.4,0.1,0.6
        C363,182.4,362.9,182.3,362.8,182.3 M399.9,182.5C400,182.6,400,182.6,399.9,182.5 M280.5,182.6l0.2,0c-0.2,0.3,0,0.6,0.2,0.9
        c-0.2-0.1-0.4-0.2-0.5-0.4C280.4,183,280.5,182.8,280.5,182.6 M114.4,183.1C114.5,183.1,114.5,183.1,114.4,183.1 M220.3,183.4
        c-0.2-0.2,0.2-0.5,0.4-0.4C220.7,183.2,220.5,183.5,220.3,183.4 M362.2,183.5c0.1-0.3,0.4-0.6,0.7-0.3c-0.1,0-0.2,0-0.2,0
        C362.6,183.5,362.4,183.6,362.2,183.5 M152,183c0.2,0.1,0.4,0.3,0.5,0.5c-0.1,0-0.3,0-0.4,0.1C152.2,183.4,152.1,183.2,152,183
        M246.9,183.2c0.1,0.1,0.4,0.5,0.2,0.6C246.8,183.7,246.7,183.4,246.9,183.2 M263.6,183.2c0.1,0.2,0.1,0.4,0.2,0.6
        c-0.2,0.1-0.5,0.2-0.8,0.3c0.2,0.1,0.4,0.2,0.6,0.2c-0.2,0.1-0.7,0.3-0.2,0.5c-0.1,0.1-0.2,0.2-0.3,0.3c0-0.1,0-0.3,0-0.4
        c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.4,0.2-0.6,0.5-0.8c0.2-0.1,0.3-0.3,0.1-0.4C263.5,183.3,263.5,183.2,263.6,183.2 M169.2,183.5
        C169.3,183.6,169.3,183.6,169.2,183.5 M206.8,183.5c0.2-0.1,0.3,0,0.3,0.3C206.8,183.8,206.7,183.7,206.8,183.5 M280.3,183.8
        c-0.1-0.2,0-0.3,0.1-0.5c0,0.4,0.5,0.5,0.6,0.8c-0.1,0.1-0.3,0.2-0.3,0.2c0-0.1,0.1-0.3,0.1-0.4C280.5,184,280.4,183.9,280.3,183.8
        M152.4,183.8C152.9,183.8,152.2,184.2,152.4,183.8 M346.1,184.3c-0.3-0.1-0.2-0.5,0-0.6C346.3,183.8,346.3,184.2,346.1,184.3
        M362.2,183.8C362.7,183.8,362,184.2,362.2,183.8 M248.5,184c0.3-0.2,0.3,0.4,0,0.3C248.2,184.5,248.3,184.1,248.5,184 M81.4,184.5
        C81.5,184.6,81.5,184.6,81.4,184.5 M280.7,184.5C281.2,184.3,280.7,185,280.7,184.5 M433.3,184.5
        C433.4,184.6,433.4,184.6,433.3,184.5 M219.2,184.6C219.7,184.6,219.1,185.1,219.2,184.6 M281,184.8
        C281.1,184.9,281.1,184.9,281,184.8 M153.3,185.3c0.2-0.1,0.5,0.1,0.3,0.3C153.4,185.7,152.8,185.4,153.3,185.3 M361.2,185.3
        c0.2,0,0.5,0,0.6,0.2c-0.3-0.1-0.5-0.1-0.6,0.2C361.2,185.6,361.2,185.4,361.2,185.3 M432.8,185.4L432.8,185.4L432.8,185.4
        L432.8,185.4L432.8,185.4 M281,185.5c0.3-0.1,0.6,0.3,0.4,0.6C281.1,186.1,281,185.7,281,185.5 M262.4,185.7l0.1-0.1l0.1,0l0,0.1
        C262.5,185.8,262.4,185.8,262.4,185.7L262.4,185.7 M345.7,185.8c0.1,0,0.2-0.1,0.2-0.2C346.2,185.8,345.7,186.3,345.7,185.8
        M345.6,186.2c0.2-0.1,0.3,0,0.2,0.2C345.6,186.5,345.5,186.4,345.6,186.2 M346,186.2C346.1,186.3,346.1,186.3,346,186.2
        M281.7,186.3C281.8,186.4,281.8,186.4,281.7,186.3 M153.9,186.6c0.2-0.1,0.5,0.2,0.4,0.4C154.1,187.2,153.7,186.8,153.9,186.6
        M360.7,186.6c0.4-0.1,0.2,0.5-0.1,0.4C360.4,187,360.5,186.6,360.7,186.6 M345.6,186.7c0.2-0.2,0.3,0.2,0.2,0.3
        C345.6,187.2,345.4,186.9,345.6,186.7 M113.5,188.1c0-0.4,0.2-0.9,0.6-0.9c-0.1,0.2-0.2,0.3-0.4,0.4
        C113.7,187.8,113.7,188,113.5,188.1 M400.7,187.2c0.2,0.1,0.3,0.1,0.5,0.2c0,0.2,0.2,0.4,0.1,0.6c-0.3,0.1-0.1-0.2-0.2-0.4
        C400.9,187.5,400.8,187.4,400.7,187.2 M335.4,187.7C335.8,187.7,335.5,188.2,335.4,187.7L335.4,187.7 M360.1,188
        C360.2,188.1,360.2,188.1,360.1,188 M113.2,188.8c-0.3-0.2-0.1-0.9,0.3-0.7C113.4,188.3,113.3,188.6,113.2,188.8 M331.7,188.4
        c0.2-0.1,0.3,0,0.2,0.2C331.7,188.7,331.6,188.6,331.7,188.4 M345.1,188.8c0.2-0.1,0.3,0,0.2,0.2C345.1,189.1,345,189,345.1,188.8
        M215,189c0.2,0,0.1,0.4-0.1,0.4C214.7,189.4,214.9,189,215,189 M401.4,189.6c0-0.2,0-0.5,0.1-0.7c-0.1,0.3,0.2,0.4,0.5,0.4
        C401.8,189.6,401.6,189.6,401.4,189.6 M283.6,189.2C283.7,189.3,283.7,189.3,283.6,189.2 M35.2,189.5c0.3-0.2,0.2,0.4,0,0.5
        C35,190,35,189.6,35.2,189.5 M283.4,189.5C283.8,189.5,283.2,189.9,283.4,189.5 M283.6,189.7c0-0.2,0.5-0.2,0.5,0
        C284.1,189.9,283.7,189.9,283.6,189.7 M342.9,189.8C342.9,189.8,342.9,189.8,342.9,189.8 M214.1,190.1c0,0.1,0.1,0.2,0.1,0.3l-0.2,0
        c0,0,0,0.1,0,0.2c-0.1,0-0.2,0-0.2,0c0,0.2-0.2,0.4-0.4,0.5c0,0,0,0.1,0,0.2c0-0.1-0.1-0.2-0.2-0.2l0.1,0c0.1-0.2,0.1-0.4,0.2-0.6
        C213.8,190.6,214,190.3,214.1,190.1 M345.1,190.5C345.2,190.6,345.2,190.6,345.1,190.5 M155.5,191c0.1-0.2,0.2-0.3,0.2-0.5
        c0,0.2,0.3,0.5,0,0.7l0-0.3L155.5,191 M35,190.7C35.5,190.8,34.8,191.1,35,190.7 M113.3,191.1c-0.4,0.2-0.3-0.6,0-0.4
        C113.3,190.8,113.3,191,113.3,191.1 M401.4,190.7c0.2-0.2,0.4,0.2,0.2,0.3C401.5,191.2,401.2,190.9,401.4,190.7 M402.1,190.7
        c0.2,0,0.3,0.1,0.3,0.3c0,0.1-0.1,0.2-0.1,0.3C402.2,191.2,402.1,190.9,402.1,190.7 M112.8,191C112.9,191.1,112.9,191.1,112.8,191
        M401.9,191C402,191.1,402,191.1,401.9,191 M294.7,191.3c0.1-0.2,0.2-0.2,0.4,0C295,191.5,294.8,191.5,294.7,191.3 M410.1,191.2
        c0.2-0.1,0.4,0.2,0.2,0.3C410.2,191.6,410,191.3,410.1,191.2 M285.7,191.5C285.8,191.6,285.8,191.6,285.7,191.5 M286.1,191.9
        c0.1,0,0.3-0.1,0.4-0.1c0,0,0.1,0.1,0.2,0.2c-0.2,0.1-0.3,0.2-0.5,0.4c0-0.1,0.1-0.3,0.2-0.4L286.1,191.9 M335.9,192.2
        c0.3,0,0.1,0.5-0.1,0.4C335.7,192.4,335.8,192.3,335.9,192.2 M479.8,192.3c0.2,0,0.3,0,0.2,0.2C479.8,192.6,479.7,192.5,479.8,192.3
        M331.6,192.6C332.1,192.6,331.4,193,331.6,192.6 M112,193.5c-0.2-0.1-0.5-0.5,0.2-0.4c0.1,0.3,0.2,0.5,0.3,0.8
        C112.2,193.8,112.1,193.6,112,193.5 M287.4,193.3c0.2-0.2,0.6,0.1,0.4,0.3C287.6,193.7,287.3,193.5,287.4,193.3 M336.6,193.4
        c0.1-0.2,0.2-0.2,0.3,0C336.8,193.5,336.7,193.5,336.6,193.4 M190.9,193.6C190.9,193.7,190.9,193.7,190.9,193.6 M210.8,193.6
        c0.2,0.1,0.2,0.2,0,0.3C210.6,193.8,210.6,193.7,210.8,193.6 M272,193.8C272.5,193.6,272.1,194.3,272,193.8 M112.2,194
        C112.7,194.1,112,194.4,112.2,194 M259.2,194c0.3-0.3,0.3,0.3,0,0.1L259.2,194 M402.4,194C402.9,194,402.2,194.5,402.4,194
        M157.4,194.2c0.2-0.1,0.4,0.1,0.3,0.3C157.5,194.6,157.3,194.3,157.4,194.2 M209.9,194.3c0.2-0.1,0.3,0,0.2,0.2
        C209.9,194.6,209.9,194.5,209.9,194.3 M192.7,195.3c0.2-0.1,0.3,0,0.3,0.2C192.8,195.5,192.7,195.5,192.7,195.3 M271.8,195.3
        c0.2-0.1,0.3,0,0.2,0.2C271.8,195.5,271.7,195.5,271.8,195.3 M34.2,195.9c-0.5-0.1,0-0.5,0.2-0.4c0.1,0.2,0.2,0.3,0.3,0.5
        c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1-0.2,0-0.5-0.2-0.7L34.2,195.9 M193.1,195.5c0.2-0.1,0.3,0,0.2,0.2
        C193.1,195.8,193.1,195.8,193.1,195.5 M272.9,195.6L272.9,195.6l0.2,0l0,0.1C273,195.7,272.9,195.7,272.9,195.6L272.9,195.6
        M292.8,195.6C292.9,195.7,292.9,195.7,292.8,195.6 M480.2,195.6c0-0.3,0.5,0,0.4,0.2C480.4,195.8,480.3,195.7,480.2,195.6 M292,196
        c0.2,0,0.4,0,0.6-0.2c0.1,0.3-0.2,0.5-0.3,0.7c0,0-0.1-0.1-0.2-0.1c0-0.1,0-0.2,0-0.2C292,196.2,292,196.1,292,196 M480.1,195.8
        c0.2-0.1,0.3,0,0.2,0.3C480,196.2,480,196.1,480.1,195.8 M403,196c0.2,0.1,0.6-0.1,0.7,0.3c0,0.1,0,0.3-0.1,0.4
        c-0.2-0.1-0.4-0.2-0.3-0.5C403.2,196.1,403.1,196,403,196 M343.6,196.3l0.1,0C344,196.5,343.3,196.5,343.6,196.3 M208.5,196.4
        C208.6,196.6,208.6,196.6,208.5,196.4 M266.1,196.6C266.2,196.7,266.2,196.7,266.1,196.6 M264.6,197.1c0-0.2,0-0.3,0.2-0.2
        C264.9,197,264.8,197.1,264.6,197.1 M404.1,197.6l0.1,0c0,0,0.1,0.1,0.2,0.2C404.3,198.1,403.9,197.8,404.1,197.6 M216.6,197.9
        c0.1-0.2,0.2-0.2,0.4-0.2C216.9,197.9,216.8,198,216.6,197.9 M262.5,198.2c0.2-0.2,0.3,0.3,0,0.3
        C262.5,198.4,262.5,198.2,262.5,198.2 M404.2,198.6c0-0.2,0.1-0.4,0.3-0.4c0.1,0.2,0.2,0.4,0.3,0.5c-0.1,0.3,0,0.6,0.2,0.8
        c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1-0.2-0.2-0.4-0.2-0.5c0-0.3,0.1-0.6,0.1-0.8C404.5,198.5,404.3,198.6,404.2,198.6 M479.9,199.1
        C480,199.2,480,199.2,479.9,199.1 M222.2,199.3C222.3,199.3,222.3,199.3,222.2,199.3 M28.1,200.1c-0.4-0.2-0.1-0.6,0.2-0.7
        C28.4,199.7,27.9,199.8,28.1,200.1 M109.8,199.4c0.2-0.1,0.5,0.4,0.3,0.5C109.9,199.9,109.7,199.6,109.8,199.4 M260.3,199.4
        c0.1,0,0.3,0,0.4,0.1c0,0.3-0.3,0.2-0.5,0.3C260.1,199.6,260.2,199.5,260.3,199.4 M196.9,199.4c0.2,0.1,0.4,0.3,0.5,0.4
        c-0.1,0.1-0.2,0.1-0.3,0.2C197.1,199.8,197,199.6,196.9,199.4 M256,199.9c-0.2-0.2,0.1-0.5,0.3-0.3
        C256.6,199.8,256.2,200.1,256,199.9 M486.5,200.3c0.3,0.1,0.6,0,0.8,0.3c-0.1,0.1-0.3,0.2-0.3,0.4c-0.2-0.2-0.1-0.4-0.1-0.6
        l-0.3,0.1c0,0-0.1,0.1-0.1,0.2C486.5,200.5,486.5,200.4,486.5,200.3 M171.7,200.8C171.7,200.9,171.7,200.9,171.7,200.8 M109.3,201.2
        c0.1-0.2,0.4-0.3,0.4,0c-0.2,0.1-0.7,0-0.7,0.3c0.1,0,0.3,0,0.4,0c-0.2,0-0.4,0-0.5,0.2C108.6,201.5,108.9,200.9,109.3,201.2
        M198.2,201C198.7,201,198,201.4,198.2,201 M405.1,201.1c0-0.3,0.3,0,0.4,0.1c0.4-0.3,0.6,0.3,0.5,0.6c-0.2-0.1-0.4-0.2-0.6-0.2
        c0.1,0,0.3-0.1,0.4-0.1C405.7,201.1,405.1,201.4,405.1,201.1 M272.9,201.7c-0.1-0.1,0.1-0.4,0.3-0.3
        C273.3,201.5,273.1,201.8,272.9,201.7 M257.3,202c0-0.2,0.2-0.4,0.4-0.3C257.8,201.8,257.5,202.1,257.3,202 M108.7,202
        c0.3-0.2,0.2,0.4,0,0.5C108.4,202.5,108.6,202.1,108.7,202 M174.1,202C174.1,202.1,174.1,202.1,174.1,202 M174.9,201.9
        C175.4,201.7,174.9,202.4,174.9,201.9 M339.2,202C339.3,202,339.3,202,339.2,202 M414.4,201.9L414.4,201.9l0.1,0.1L414.4,201.9
        L414.4,201.9 M486.9,202c0.2-0.1,0.4,0.3,0.2,0.5C486.9,202.6,486.9,202.2,486.9,202 M257,202.1C257.1,202.2,257.1,202.2,257,202.1
        M338.5,202.3C338.5,202.3,338.5,202.3,338.5,202.3 M338.3,202.6C338.8,202.6,338.1,203,338.3,202.6 M108,202.7
        c0.2,0,0.2,0.1,0.1,0.3C107.9,203,107.8,202.9,108,202.7 M175.8,202.7C175.9,202.8,175.9,202.8,175.8,202.7 M277.4,202.7
        C277.4,202.8,277.4,202.8,277.4,202.7 M406.6,202.7c0.2-0.1,0.4,0.2,0.2,0.3C406.6,203.2,406.3,202.8,406.6,202.7 M487.5,203
        c-0.2-0.1-0.2-0.2,0-0.3C487.6,202.8,487.6,202.9,487.5,203 M200.7,203.1C200.7,203.2,200.7,203.2,200.7,203.1 M337.1,203.1
        C337.6,203.1,336.9,203.6,337.1,203.1 M253.8,203.7c-0.2-0.1-0.2-0.2,0-0.4C254,203.4,254,203.5,253.8,203.7 M253.4,203.6
        c0.3-0.1,0,0.5,0,0.1L253.4,203.6 M273.6,203.5C273.6,203.6,273.6,203.6,273.6,203.5 M253.3,203.9c0.3,0,0,0.7-0.2,0.6
        C253,204.3,253.2,204.1,253.3,203.9 M320.5,204.1L320.5,204.1L320.5,204.1L320.5,204.1L320.5,204.1 M406.7,204.2
        c0.2,0.1,0.4,0.3,0.6,0.5c0.1,0,0.3,0,0.4,0c0,0.1,0,0.3,0,0.4c-0.1,0.2-0.3,0.3-0.4,0.4c0.1-0.3,0.2-0.6,0-0.8
        C407,204.7,406.8,204.5,406.7,204.2 M487.8,205c-0.1,0.2-0.5-0.1-0.3-0.2C487.6,204.9,487.8,205,487.8,205 M487.4,205.5
        c0.2-0.1,0.4-0.2,0.6-0.4c0,0.2-0.2,0.3-0.2,0.5c0.1,0.2,0.3,0.4,0.4,0.6l-0.3,0l0.1,0.3l-0.1,0l-0.1-0.2c-0.1,0-0.2,0-0.2,0
        c0-0.2,0-0.3,0-0.4l0.3-0.1C487.6,205.7,487.5,205.6,487.4,205.5 M487.7,206.1l-0.1,0.1C487.8,206.6,488.1,206,487.7,206.1
        M279.4,205.7c0.1-0.1,0.5,0,0.4,0.2C279.7,206.1,279.3,205.9,279.4,205.7 M107,206c0,0,0.1-0.1,0.2-0.2
        C107.6,205.9,107.1,206.4,107,206 M407.7,206c-0.1,0.4-0.6-0.1-0.2-0.2C407.6,205.9,407.7,206,407.7,206 M268.5,206.3
        c0.3,0.1-0.1,0.3-0.2,0.3C268.1,206.5,268.4,206.2,268.5,206.3 M407.9,206.2c0.5,0-0.1,0.6-0.3,0.3
        C407.4,206.4,407.7,206.2,407.9,206.2 M184.4,206.7c0.2,0,0.5,0.3,0.3,0.5C184.5,207.2,184.3,206.9,184.4,206.7 M267.7,206.7
        c0.1,0,0.3,0,0.4,0C268.1,206.9,268,206.9,267.7,206.7L267.7,206.7 M106.3,207.1c0-0.2,0.4-0.3,0.5,0
        C106.8,207.3,106.3,207.3,106.3,207.1 M408,207c0-0.3,0.4-0.2,0.5,0C408.4,207.3,408,207.3,408,207 M280.2,207.3
        c-0.3,0,0-0.5,0.2-0.4C280.3,207.1,280.2,207.2,280.2,207.3 M184.9,207.3C185.3,207.3,184.6,207.7,184.9,207.3 M266.9,207.7
        c0.1-0.2,0.3-0.3,0.4-0.4c0,0.2,0,0.5-0.1,0.7c-0.1,0.3-0.4,0-0.6,0C266.7,207.8,266.8,207.7,266.9,207.7 M27,207.7
        C27.5,207.7,26.8,208.1,27,207.7 M249.8,208.2c0.3-0.2,0.6-0.4,0.9-0.6c0,0,0,0.1,0,0.2c-0.1,0-0.2,0-0.2,0
        C250.4,208.1,250,208.4,249.8,208.2 M487.6,207.7C488.1,207.7,487.3,208.1,487.6,207.7 M317.4,208.3c0-0.1,0.1-0.3,0.2-0.4
        c0.1,0.3,0.1,0.7-0.3,0.6C317.3,208.4,317.3,208.3,317.4,208.3 M26.8,208.4c0.2-0.6,0.6,0,0.5,0.4C27.1,208.6,26.9,208.5,26.8,208.4
        M186.7,208.5c0-0.2,0.3-0.1,0.4,0C187.1,208.8,186.7,208.7,186.7,208.5 M191.8,208.6c0.2-0.1,0.5,0.2,0.4,0.4
        C192,209,191.7,208.8,191.8,208.6 M187.1,208.9C187.5,208.7,187,209.4,187.1,208.9 M316.6,209.3c-0.1-0.1,0.1-0.4,0.2-0.4
        C317,209.1,316.8,209.4,316.6,209.3 M280.8,209.6c0.2-0.1,0.3,0,0.2,0.2C280.8,209.9,280.7,209.8,280.8,209.6 M104.4,210.3
        c-0.1-0.2,0-0.3,0.2-0.3C104.7,210.2,104.6,210.3,104.4,210.3 M189.6,210.1C190,210.1,189.4,210.5,189.6,210.1 M410.2,210
        c0.2,0,0.2,0.1,0.2,0.3C410.1,210.3,410.1,210.2,410.2,210 M284.7,210.4C284.8,210.4,284.8,210.4,284.7,210.4 M189.8,210.6
        c0-0.2,0.4,0,0.3,0.1C190,210.7,189.9,210.6,189.8,210.6 M280.8,210.5c0.2-0.1,0.5,0.2,0.4,0.4C281,211,280.7,210.7,280.8,210.5
        M487.3,210.9c-0.3-0.1,0-0.5,0.2-0.3C487.5,210.8,487.5,210.9,487.3,210.9 M210.8,210.8C210.9,210.9,210.9,210.9,210.8,210.8
        M190.7,211.1C190.8,211.1,190.8,211.1,190.7,211.1 M210.5,211.3L210.5,211.3L210.5,211.3L210.5,211.3L210.5,211.3 M210.8,211.6
        C211.2,211.7,210.5,212,210.8,211.6 M27.7,212.7c-0.3-0.2-0.5-0.7,0-0.9c0,0.2,0,0.4-0.1,0.6l0.2,0
        C27.7,212.5,27.7,212.7,27.7,212.7 M37.4,211.9c0.2-0.2,0.5,0.1,0.4,0.4C37.6,212.3,37.3,212.1,37.4,211.9 M476.9,212.2
        c-0.2-0.2,0.2-0.6,0.4-0.4C477.4,212.1,477.1,212.3,476.9,212.2 M98.3,212.3C98.7,212.1,98.3,212.8,98.3,212.3 M294.4,212.3
        C294.5,212.4,294.5,212.4,294.4,212.3 M354.5,212.7C354.6,212.8,354.6,212.8,354.5,212.7 M284.1,213.2c0.2-0.1,0.3,0,0.2,0.2
        C284.1,213.5,284,213.4,284.1,213.2 M284.4,213.5C284.5,213.6,284.5,213.6,284.4,213.5 M97.5,214.4c-0.1-0.4,0.1-0.6,0.4-0.8
        C98,213.9,97.7,214.1,97.5,214.4 M173.3,213.5c0.1,0,0.4,0.1,0.5,0.1c0,0.2,0.3,0.5,0.1,0.7C174,213.8,173.4,213.9,173.3,213.5
        M244.4,213.7c0.1-0.1,0.6-0.1,0.4,0.2C244.7,214,244.2,213.9,244.4,213.7 M416.8,213.6c0.2,0.1,0.5,0.3,0.4,0.6
        c0.1,0,0.2,0.1,0.2,0.1c0-0.1,0.1-0.3,0.2-0.4c0,0.1,0,0.3,0,0.4c-0.2,0.1-0.3,0.2-0.4,0.3l0.2-0.2
        C417,214.3,416.8,213.9,416.8,213.6 M266.6,213.8c0.2-0.1,0.3,0,0.2,0.2C266.6,214.1,266.6,214,266.6,213.8 M193.7,213.9
        c0.2-0.1,0.3,0,0.2,0.2C193.7,214.2,193.6,214.1,193.7,213.9 M266.4,214C266.4,214.1,266.4,214.1,266.4,214 M446.7,213.8
        c0.3,0.1,0.6,0.3,0.9,0.3c0.4,0.2,0.7,0.5,1.2,0.5c0.2,0,0.3,0.1,0.5,0.2c0.3,0.1,0.6,0.1,0.9,0.2c0.3,0.4,0.9,0.2,1.3,0.4
        c0-0.1,0-0.2,0.1-0.2c0,0.1,0.1,0.3,0.1,0.4c0.1-0.1,0.2-0.2,0.4-0.3c0,0.1,0,0.2,0.1,0.3c0.8,0,1.6,0.2,2.4,0.2c0,0,0,0.1,0,0.1
        c-0.7,0-1.4,0.2-2-0.1c-0.3,0.3-0.5-0.2-0.7-0.2c0,0.1-0.1,0.2-0.1,0.3c0-0.5-0.5-0.2-0.8-0.4c-0.3,0-0.6-0.1-0.9-0.3
        c-0.3-0.1-0.7-0.1-1-0.3c-0.7-0.1-1.3-0.6-2.1-0.8C446.9,214.1,446.8,213.9,446.7,213.8 M284.4,214.5
        C284.9,214.5,284.2,214.9,284.4,214.5 M38.7,214.6C38.8,214.7,38.8,214.7,38.7,214.6 M176.1,214.9c-0.2-0.1-0.2-0.2,0-0.3
        C176.3,214.6,176.3,214.7,176.1,214.9 M245.4,214.8c0.1,0,0.2,0,0.2,0v0.1c-0.1,0-0.2,0-0.2,0L245.4,214.8 M96.4,215.2
        c0.2-0.1,0.3,0,0.2,0.2C96.4,215.4,96.3,215.4,96.4,215.2 M418.1,215.2c0.2-0.1,0.3,0,0.3,0.2C418.1,215.5,418,215.4,418.1,215.2
        M279.8,215.5C279.9,215.6,279.9,215.6,279.8,215.5 M62.6,215.6C63,215.4,62.6,216.1,62.6,215.6 M244.4,215.7c0.1,0,0.2,0,0.2,0
        C244.7,215.9,244.2,216,244.4,215.7 M334.9,215.6C334.9,215.7,334.9,215.7,334.9,215.6 M60.2,216c0.7,0,1.3-0.1,2-0.1
        c-0.6,0.4-1.3,0.1-2,0.2C60.2,216,60.2,216,60.2,216 M62.3,215.8c0.1,0,0.2,0,0.3,0C62.8,216.2,62,216.2,62.3,215.8 M283.9,215.8
        c0.2-0.1,0.3,0,0.2,0.2C283.8,216.1,283.8,216,283.9,215.8 M334.3,216.1c0.2,0,0.5,0,0.7,0.1c0.1,0.4-0.1,0.6-0.4,0.9
        c-0.3-0.3,0.1-0.4,0.3-0.6c0-0.1-0.1-0.2-0.2-0.3C334.6,216.1,334.4,216.1,334.3,216.1 M156.7,216.1
        C157.2,216.2,156.5,216.6,156.7,216.1 M159.1,216.6C159.2,216.7,159.2,216.7,159.1,216.6 M419.7,217.3c0.2-0.1,0.5,0.2,0.2,0.3
        C419.7,217.6,419.7,217.5,419.7,217.3 M472.7,217.5c0.2-0.2,0.3,0.3,0.1,0.3l-0.1-0.1C472.6,217.6,472.6,217.5,472.7,217.5
        M334.2,217.6c0.2-0.2,0.3,0.2,0.2,0.4C334.3,218.1,334.1,217.7,334.2,217.6 M420.1,218c0.1-0.2,0.3-0.1,0.5,0
        c0.3,0,1.1-0.2,0.7,0.4c-0.3-0.1-0.5-0.2-0.8-0.3c0,0-0.1,0.1-0.1,0.2C420.3,218.2,420.1,218.1,420.1,218 M93.5,218.5
        c-0.3-0.6,0.4-0.5,0.7-0.5C94.1,218.3,93.7,218.3,93.5,218.5 M292.2,218C292.7,218,292,218.5,292.2,218 M148.8,218.3
        c0.2-0.1,0.4-0.2,0.6-0.3c-0.1,0.2-0.4,0.4-0.5,0.5C148.7,218.6,148.5,218.4,148.8,218.3 M170.8,218.4c-0.2-0.2,0.3-0.4,0.4-0.2
        C171.1,218.4,171,218.4,170.8,218.4 M202.8,218.2c0.3-0.1,0.5,0.1,0.6,0.4C203.2,218.4,202.9,218.4,202.8,218.2 M291.9,218.4
        C292,218.4,292,218.4,291.9,218.4 M484.9,218.9c0-0.1,0.1-0.3,0.1-0.3C485,218.6,485,218.8,484.9,218.9l0.4,0.1
        c-0.1,0.1-0.2,0.2-0.3,0.3C484.8,219.1,484.8,219,484.9,218.9 M29.7,218.8c0,0,0.1,0.1,0.1,0.1c0.2,0.2-0.2,0.5-0.3,0.3
        C29.6,219,29.7,218.9,29.7,218.8 M92.6,218.8c0.1,0,0.4,0,0.6,0c-0.1,0.1-0.1,0.3-0.2,0.5c-0.4,0.2-0.5,0.6-0.6,0.9
        c-0.3-0.2-0.1-0.4,0-0.6c0.1-0.3,0.4-0.4,0.6-0.5C92.8,219,92.7,218.8,92.6,218.8 M291.3,218.8c0.3-0.3,0.3,0.3,0,0.1L291.3,218.8
        M147.3,219.2c0.2,0,0.3,0,0.2,0.2C147.3,219.5,147.2,219.4,147.3,219.2 M41.9,219.5c-0.1-0.2,0-0.3,0.2-0.3
        C42.2,219.5,42.1,219.5,41.9,219.5 M146.6,219.8c0.1-0.3,0.3-0.4,0.6-0.4C147,219.6,146.9,219.9,146.6,219.8 M306.8,219.6
        c0-0.2,0.1-0.3,0.3-0.3C307,219.6,306.9,219.7,306.8,219.6 M239.8,219.8c0.2-0.2,0.5-0.3,0.8-0.4c-0.1,0.2-0.2,0.4-0.4,0.5
        c-0.1,0.3-0.4,0.2-0.6,0.3c0-0.1,0-0.2,0.1-0.3l0.2,0L239.8,219.8 M276.1,219.9C276.6,219.9,275.9,220.3,276.1,219.9 M30.4,220
        c0.3,0.1,0.4,0.6,0.3,0.9c-0.3,0.1-0.6-0.3-0.1-0.3C30.6,220.4,30.5,220.2,30.4,220 M187.2,220C187.2,220.1,187.2,220.1,187.2,220
        M306.3,220C306.8,219.8,306.3,220.5,306.3,220 M187.6,220.1c0.2-0.1,0.3,0.2,0.2,0.4C187.6,220.6,187.5,220.3,187.6,220.1
        M326.5,220.2C327,220.2,326.2,220.6,326.5,220.2 M141.2,220.6C141.3,220.7,141.3,220.7,141.2,220.6 M325,220.9
        c0.1-0.3,0.4-0.4,0.6-0.2c0,0,0.1,0.1,0.1,0.2c-0.3,0.1-0.7,0.6-0.9,0.2C324.8,221.1,325,221,325,220.9 M31,220.8
        c0.2,0.2,0.2,0.5,0.5,0.6c-0.1,0.1-0.2,0.2-0.4,0.3C31.1,221.3,31,221.1,31,220.8 M483.3,221.3c0.3,0,0.3-0.3,0.5-0.5
        c0,0.3-0.1,0.6-0.3,0.8C483.4,221.6,483.3,221.4,483.3,221.3 M90.8,221.1c0,0,0.1,0,0.1,0c-0.1,0.5-0.5,0.8-0.9,0.9
        c0,0.1,0,0.3,0,0.4c-0.1,0-0.3,0-0.4-0.1c0.1-0.1,0.2-0.3,0.3-0.4c0-0.1,0-0.2,0-0.2c0.1,0,0.2,0.1,0.3,0.1
        C90.1,221.6,90.8,221.5,90.8,221.1 M423.8,221.2c0,0,0.1,0,0.1,0c0,0.4,0.6,0.4,0.6,0.9c0.1,0,0.2-0.1,0.2-0.1
        c0.1,0.2,0.2,0.4,0.3,0.6c-0.1,0-0.3,0-0.3,0c0-0.1,0-0.3,0-0.4c-0.2,0-0.3-0.2-0.5-0.3C424,221.7,423.9,221.4,423.8,221.2
        M258.6,221.3C258.6,221.4,258.6,221.4,258.6,221.3 M373.6,221.5c0,0,0.1,0,0.1,0c-0.2,0.4-0.4,0.9-0.8,1.2c0,0.3-0.1,0.8-0.4,0.3
        c0,0.1,0.1,0.2,0.1,0.3c-0.1,0.2-0.7,0.2-0.5,0.5l-0.3-0.1c0,0.1,0,0.3,0.1,0.3c-0.1,0-0.3,0-0.5,0c0,0.1,0.1,0.3,0.1,0.4
        c-0.1,0-0.3,0-0.4,0.1c0,0.1,0,0.3-0.1,0.4c-0.1,0-0.2-0.1-0.3-0.1c0.2,0.4-0.3,0.6-0.6,0.8c0.1,0.1,0.3,0.2,0.4,0.2
        c-0.2,0.1-0.4,0.1-0.6,0c0,0.1,0,0.3,0,0.3c-0.1,0.1-0.3,0.2-0.4,0.3h0.4c-0.2,0.1-0.3,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.3,0.5
        c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0.2-0.2,0.4-0.2,0.6l0.3-0.1c0-0.1,0-0.2,0-0.3l0.2,0c-0.1,0.4-0.5,0.5-0.8,0.7c0-0.1,0.1-0.3,0.2-0.4
        c-0.1,0.1-0.2,0.2-0.2,0.3l-0.2,0c0.1,0.1,0.2,0.2,0.2,0.3c-0.3,0-0.6,0.1-0.7,0.5c0.1,0,0.3,0,0.3,0c-0.3,0.1-0.5,0.2-0.8,0.4
        l0.4,0c-0.3,0.2-0.4,0.4-0.5,0.7l0-0.5c-0.4,0.4-0.7,0.8-1,1.3c-0.1,0-0.3,0-0.4,0c0,0-0.1-0.1-0.2-0.2c-0.1,0.1-0.1,0.2-0.1,0.3
        c0.1,0,0.3,0,0.4,0c-0.2,0.3-0.6,0.2-0.7,0.5c0.1,0,0.3-0.1,0.4-0.1c0,0,0.1,0.2,0.1,0.2c-0.2,0.1-0.8,0.2-0.5,0.5
        c-0.1-0.1-0.3-0.1-0.4-0.2c0,0.1,0.1,0.3,0.1,0.4c-0.2,0-0.5,0.1-0.6,0.4c0.1,0,0.3,0,0.5,0c-0.1,0.2-0.4,0.1-0.6,0.1l-0.1,0
        c-0.1,0-0.2,0.1-0.2,0.2l0,0.1c0,0.1,0,0.3,0,0.4c-0.3-0.2-0.3,0.2-0.4,0.3c-0.1,0-0.3,0-0.3,0c-0.1,0.2-0.1,0.4-0.1,0.6
        c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0-0.2,0-0.3,0c-0.1,0.2-0.2,0.4-0.4,0.5l0,0.2c-0.3-0.2-0.5,0.1-0.7,0c-0.1,0.3-0.1,0.7-0.5,0.8
        c-0.2,0,0,0.3,0,0.4c-0.1,0-0.3-0.1-0.4-0.1c0,0.1,0.1,0.2,0.1,0.3l-0.2,0.1l-0.1,0.3c0.1,0,0.3,0,0.4,0c-0.2,0-0.4,0.4-0.5,0
        c-0.1,0-0.3,0-0.4,0c0,0.1,0,0.3,0,0.4c-0.2,0-0.3,0.2-0.3,0.4c-0.2,0.2-0.6,0.3-0.7,0.6c-0.3,0.6-0.7,1.1-1.2,1.6
        c-0.3,0.3-0.4,0.8-0.8,1c-0.1,0.2-0.2,0.4-0.4,0.6c-0.5,0.7-1.1,1.4-1.7,1.9c-0.1,0.7-0.7,1.1-1.1,1.7c-0.1,0.2-0.3,0.2-0.4,0.3
        c-0.2,0.3-0.3,0.7-0.5,1c-0.4,0.3-0.5,0.9-0.9,1.2c-0.3,0.4-0.7,0.7-0.9,1.2c-0.4,0.6-1,1.1-1.3,1.7c-0.3,0.6-0.8,1-1.1,1.6
        c-0.5,0.3-0.7,0.9-1,1.3c-0.2,0.5-0.6,0.9-0.9,1.4l-0.1-0.3c0,0.1,0,0.2,0,0.3c0,0-0.1,0-0.2,0.1c0.1,0.1,0.4,0.3,0.1,0.5
        c0,0,0.1,0.1,0.1,0.1c0-0.2,0-0.5,0-0.6c0.1,0.1,0.3,0.3,0.4,0.3c-0.1,0.1-0.2,0.4-0.2,0.5c0.1,0,0.3,0,0.3,0.1
        c-0.4,0.3,0,0.7,0.2,1c-0.2-0.1-0.3-0.1-0.5-0.2c0.1,0.3,0.2,0.7,0.1,1c0.2-0.1,0.4-0.3,0.6-0.1c-0.2,0-0.3,0.2-0.4,0.4l-0.2,0
        c0.1,0.1,0.1,0.1,0.2,0.2c0-0.1,0-0.3,0-0.4c0.3,0.1,0.4,0.3,0.4,0.6c0.1-0.1,0.3-0.3,0.5-0.3c-0.1,0.1-0.2,0.3-0.3,0.4
        c0.2-0.1,0.4,0,0.5,0.1l-0.3,0.1c0.2,0.2,0.4,0.3,0.6,0.1c0,0.1,0.1,0.2,0.2,0.3c0.2-0.2,0.3-0.4,0.5-0.5c0,0.1-0.1,0.2-0.2,0.3
        c0,0.1,0.1,0.3,0.2,0.3c0.2-0.1,0.5-0.2,0.7-0.2l0,0.2c0.3,0,0.6,0,0.9-0.1l-0.1,0.3c0.3-0.4,0.8-0.1,1.2-0.4l-0.1,0.3
        c0.2-0.2,0.5-0.3,0.7-0.4l0,0.2c0.4-0.2,0.8-0.5,1.2-0.7c-0.4,0.5-1,0.6-1.5,1c-0.2,0.2-0.5,0.3-0.8,0.4l0,0.2
        c-0.1,0-0.3-0.1-0.4-0.1l0.2,0.2l-0.3-0.1c0,0.1,0,0.2,0,0.2l-0.3,0l-0.1-0.2c0,0-0.1-0.1-0.2-0.1c0.1,0.1,0.2,0.3,0.3,0.4
        c-0.1,0-0.2,0-0.3-0.1c-0.1,0.1-0.4,0.2-0.6,0.2c0-0.1,0-0.2,0-0.2c-0.4,0.1,0.1,0.6-0.3,0.7c0-0.1,0-0.4,0-0.5
        c-0.1,0.1-0.2,0.3-0.3,0.4c-0.3-0.1-0.5-0.1-0.8,0c0-0.3,0.3-0.5,0.6-0.5c-0.2,0-0.5,0-0.7,0c0,0.1,0,0.2,0.1,0.3
        c-0.3,0-0.8,0.2-1-0.3l0,0.4c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.3,0-0.4,0l0.1-0.2c-0.4-0.1-0.9-0.6-1.3-0.3c0.1-0.1,0.1-0.3,0.2-0.4
        c-0.2-0.2-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.5-0.5-0.7c-0.2,0-0.4,0-0.6-0.1c0.1-0.2,0.2-0.4,0.4-0.5c-0.3-0.2-0.6-0.3-0.5-0.7
        c-0.2,0.7-0.8,1.2-1,1.9c-0.2,0.2-0.5,0.3-0.5,0.6c-0.1,0.5-0.6,0.8-0.8,1.3c-0.2,0.6-0.8,0.9-1,1.5c-0.1,0.5-0.6,0.7-0.8,1.2
        c-0.3,0.6-0.7,1.2-1,1.8c-0.1,0.2-0.3,0.3-0.5,0.4c0,0.1,0,0.3,0,0.4c-0.2,0.4-0.7,0.6-0.8,1.1c-0.1,0.3-0.4,0.5-0.6,0.8
        c-0.3,0.5-0.6,1-1,1.4c0,0.4-0.4,0.6-0.5,0.9c-0.3,0.6-1.1,0.9-1.2,1.6c-0.1,0.4-0.4,0.7-0.7,1c-0.5,0.8-1.1,1.5-1.6,2.2
        c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3,0.4-0.7,0.9-0.9,1.4c0.1-0.5,0.3-1,0.5-1.4c0.1-0.2,0.2-0.4,0.4-0.5l-0.2,0c0-0.3,0.4-0.4,0.3-0.7
        l0.1,0c0-0.2,0-0.3,0.1-0.5c0-0.1-0.1-0.2-0.1-0.2c0.1,0,0.2,0.1,0.3,0.2c0-0.1,0-0.4,0-0.5c0.4,0,0.1-0.6,0.5-0.6
        c0,0.1,0.1,0.2,0.1,0.3c0-0.3-0.2-0.5-0.3-0.7c0.1,0,0.4,0,0.5,0c0,0,0-0.1,0-0.1c-0.1,0-0.3,0-0.4,0.1c0.1-0.2,0.5-0.5,0.1-0.6
        c0.1,0,0.2,0,0.3,0c0-0.1,0-0.4,0-0.5c0.2-0.1,0.4-0.3,0.5-0.4c0.2-0.1,0.4-0.3,0.6-0.5c-0.3,0.1-0.5,0.3-0.7,0.5
        c0.1-0.3,0.3-0.5,0.4-0.8l0.3,0.1c-0.1-0.1-0.2-0.4-0.3-0.6c0.1,0.1,0.3,0.2,0.4,0.3l0-0.3c-0.3-0.1-0.1-0.3,0-0.4
        c0.1-0.2,0.3-0.4,0.3-0.6c0.1,0.3,0.1,0.5,0,0.8c0.1,0,0.2-0.1,0.3-0.1c-0.1-0.3-0.2-0.6-0.2-0.9c0.1,0,0.3,0.1,0.4,0.1
        c0-0.4-0.1-0.7-0.2-1c0.2,0.2,0.5,0.3,0.7,0.5c0-0.2,0-0.3,0.1-0.5c-0.1,0.1-0.3,0.1-0.4,0.2c0.1-0.3,0.3-0.5,0.6-0.4
        c0-0.1,0-0.3,0-0.4c-0.2,0.1-0.3,0.1-0.5,0.2v-0.2c0.2,0,0.2-0.2,0.2-0.4c0.3,0,0.5-0.1,0.6-0.3c-0.1,0-0.2-0.1-0.3-0.2l0.3,0
        c0-0.1-0.1-0.3-0.1-0.4c0.1,0.1,0.2,0.3,0.4,0.4c0.2-0.3-0.1-0.7,0.1-0.9c0,0.1,0.1,0.2,0.1,0.3c0.1-0.1,0.2-0.2,0.3-0.1
        c0.1,0,0.2,0,0.3,0c-0.1,0-0.2-0.1-0.2-0.1c0-0.1,0.1-0.2,0.1-0.3c-0.2,0.1-0.3,0.1-0.5,0.2c0.1-0.3,0.2-0.5,0.3-0.8
        c0.2-0.1,0.3-0.1,0.5-0.2l0,0.2l0.1,0c0.1-0.3,0.3-0.5,0.4-0.8c-0.2,0.2-0.6,0.3-0.4,0.6c-0.2,0-0.3,0-0.5,0
        c0.2-0.4,0.6-0.7,0.7-1.1c0.1,0.1,0.3,0.1,0.5,0.1c0.1-0.1,0.1-0.3,0.2-0.4c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1-0.1-0.1-0.2-0.1-0.2
        c0.3-0.1,0.3-0.3,0.2-0.6c0.1-0.1,0.4-0.3,0.4,0c0,0.1-0.1,0.2-0.1,0.4c0.1,0,0.2,0,0.3,0.1c0-0.1-0.1-0.3-0.1-0.5l0.3,0
        c-0.1-0.1-0.2-0.2-0.3-0.3c0.1-0.1,0.2-0.3,0.3-0.4c0.1,0,0.2,0.1,0.2,0.1c0.2-0.3,0.5-0.7,0.9-0.6c-0.1-0.1-0.3-0.1-0.4-0.2
        c-0.2,0.2-0.4,0.5-0.7,0.7c-0.1-0.3,0-0.5,0.2-0.6c0.1-0.2,0.2-0.4,0.2-0.6c0.1,0,0.2,0.2,0.4,0.1c0.1-0.2-0.1-0.3-0.2-0.4
        c0.1-0.1,0.2-0.2,0.3-0.2c0,0.1,0,0.3,0,0.3c0.1-0.2,0.2-0.3,0.2-0.5c0.1,0,0.3-0.1,0.4-0.2c-0.2,0-0.5,0.1-0.6,0.1
        c0.2-0.3,0.4-0.5,0.6-0.8c0.3,0,0.5,0,0.6-0.3c-0.1,0-0.3,0-0.4,0l0-0.3c0.1,0,0.3,0,0.4,0c0-0.1-0.1-0.3-0.2-0.4
        c0.3-0.1,0.5-0.1,0.8-0.1c-0.1,0-0.3-0.1-0.4-0.1c0.2-0.2,0.5-0.3,0.7-0.4c-0.1,0-0.3,0-0.4,0c0-0.1-0.1-0.3-0.1-0.4
        c0.1,0,0.3,0,0.4,0l0-0.2l-0.4,0.1c0.2-0.2,0.4-0.4,0.7-0.5c0.1-0.1,0.2-0.2,0.3-0.3c0.2,0.4-0.2,0.6-0.3,1c0.2-0.2,0.3-0.4,0.5-0.5
        c-0.1-0.2-0.2-0.5-0.2-0.7c0.2-0.1,0.5-0.2,0.7-0.2c-0.1,0.2-0.2,0.3-0.3,0.5c0.1-0.1,0.3-0.2,0.4-0.2c0-0.2,0-0.3,0-0.5
        c0.1-0.1,0.2-0.2,0.3-0.3c-0.1-0.1-0.2-0.4-0.2-0.5c0.2-0.1,0.9,0.3,0.7-0.3h-0.2l0.1,0.2c-0.1,0-0.4,0-0.5,0
        c0.1-0.1,0.3-0.4,0.3-0.5c0.3,0.1,0.4-0.2,0.6-0.3c-0.3-0.4,0.3-0.5,0.3-0.8c0.1,0.1,0.2,0.3,0.3,0.4c0.2-0.3-0.4-0.3-0.2-0.6
        c0.3-0.1,0.5-0.4,0.6-0.7c-0.1,0-0.2-0.1-0.2-0.2c0.1,0,0.3,0.1,0.4,0.1l0-0.3c-0.1,0-0.2,0-0.3,0l0,0c0.3,0.1,1-0.3,0.4-0.4
        c0.1,0,0.3,0,0.4,0c0.2-0.4,0.4-0.8,0.8-1.1c0.2-0.1,0.2-0.3,0.2-0.5c0.2-0.1,0.3-0.2,0.5-0.3c0.1,0.1,0.2,0.2,0.3,0.3
        c0-0.2-0.3-0.3-0.5-0.4c0.1-0.1,0.3-0.3,0.4-0.4c0,0.2,0.1,0.5,0.3,0.3c0-0.2-0.1-0.3-0.1-0.5c0.2-0.1,0.4-0.3,0.6-0.4
        c0-0.1,0-0.3,0-0.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0-0.2,0.1-0.3,0.1c0.1-0.2,0.2-0.3,0.4-0.5c0.1,0,0.2,0,0.3,0.1
        c0.2-0.2,0.2-0.6,0.5-0.5c0.1-0.1,0.1-0.1,0.2-0.2c-0.1,0-0.3,0-0.4,0.1c0-0.4,0.3-0.4,0.5-0.3c0-0.1,0-0.2,0-0.3l0.2,0
        c-0.1,0-0.2-0.1-0.2-0.1c0-0.2,0.1-0.4,0-0.6c0.3,0,0.4-0.2,0.6-0.4c0,0.1,0.1,0.3,0.2,0.3c0-0.3,0-0.5,0.3-0.5c0-0.1,0-0.3,0-0.4
        c0.1,0,0.2,0,0.3,0.1c0-0.2,0.1-0.4,0.1-0.6l-0.2,0c0-0.1,0.1-0.1,0.2-0.2c0,0.2,0.1,0.4,0.2,0.5c0-0.4,0.6-0.6,0.3-1l0.3,0.1
        c0.2-0.2,0.1-0.4,0.1-0.6c0.1,0,0.3,0.1,0.4,0.2c0.1-0.2,0.3-0.5,0.3-0.7l0.1,0c0.1-0.3,0.4-0.4,0.5-0.6c0.4-0.7,0.9-1.2,1.3-1.9
        c0.5-0.1,0.5-0.7,0.9-0.9c0.3-0.5,0.8-0.9,1.1-1.3c0.4-0.3,0.6-0.8,1-1c0.2-0.4,0.5-0.6,0.8-0.9c0.3-0.1,0.4-0.4,0.6-0.7
        c0.3-0.3,0.7-0.5,0.9-0.8c0.1-0.2,0.3-0.3,0.5-0.4c0.1-0.3,0.3-0.5,0.6-0.6c0.4-0.4,0.8-0.8,1.2-1.3c0.6-0.4,1-1.1,1.6-1.6
        c0.8-0.5,1.2-1.4,2-1.7c0-0.6,0.5-0.9,0.9-1.2c0.3-0.3,0.7-0.5,1-0.7c0.4-0.5,0.9-0.7,1.3-1.1c0.3-0.5,0.9-0.7,1.2-1.1
        c0.5-0.4,1.1-0.8,1.6-1.2c0.6-0.3,0.8-1.1,1.5-1.2L373.6,221.5 M362.6,233.8c0.2,0,0.3-0.1,0.3-0.3
        C362.7,233.4,362.3,233.7,362.6,233.8 M360.1,236.1C359.8,236.5,360.5,236.1,360.1,236.1 M350.2,245.3c-0.1,0.2,0,0.3,0.2,0.2
        C350.5,245.3,350.4,245.2,350.2,245.3 M347.7,249C347.8,249.5,348.2,248.8,347.7,249 M346.1,251.4c0.2,0,0.3-0.3,0.3-0.4
        C346.2,251,346,251.3,346.1,251.4 M345.8,251.7C345.6,252.1,346.3,251.7,345.8,251.7 M345.5,252.2c0,0.2,0.5,0.1,0.3-0.1
        C345.7,252.1,345.5,252.1,345.5,252.2 M346.7,255.3c0,0.2-0.1,0.7,0.2,0.7C347,255.7,346.9,255.5,346.7,255.3L346.7,255.3
        M344.7,258.1C344.7,258.2,344.7,258.2,344.7,258.1 M477.1,221.6c0.2-0.1,0.3,0,0.2,0.2C477.1,221.9,477,221.8,477.1,221.6
        M304.2,221.9C304.7,221.9,304,222.3,304.2,221.9 M140.1,222.2c0.2-0.2,0.4,0.2,0.2,0.3C140.2,222.6,139.9,222.3,140.1,222.2
        M88.6,222.9c0-0.2,0.1-0.5,0.3-0.3c0.1-0.1,0.3-0.2,0.4-0.3c0,0.2,0,0.3,0,0.5c-0.3,0.2-0.5,0.5-0.8,0.7c0.1-0.3,0.3-0.5,0.4-0.7
        C88.9,222.9,88.7,222.9,88.6,222.9 M425.4,222.4c0.2,0.1,0.4,0.5,0.7,0.3c0,0.1,0.1,0.3,0.1,0.4c-0.1-0.1-0.3-0.2-0.4-0.3
        c-0.2,0.2,0.1,0.5,0.2,0.7c0.1-0.1,0.2-0.3,0.3-0.4c0.1,0.1,0.2,0.2,0.2,0.2c-0.2,0.1-0.3,0.2-0.4,0.3c-0.2-0.3-0.4-0.5-0.7-0.7
        C425.4,222.8,425.4,222.6,425.4,222.4 M235.3,222.8c0.2-0.1,0.3,0,0.2,0.2C235.3,223.1,235.3,223,235.3,222.8 M261.5,222.7
        C261.6,222.8,261.6,222.8,261.5,222.7 M323.3,222.7C323.3,222.8,323.3,222.8,323.3,222.7 M39.1,223.2c0.1,0,0.2,0,0.3,0
        c0.1,0.1,0.3,0.3,0.4,0.4C39.6,223.5,39.4,223.3,39.1,223.2 M88.3,223.4c0-0.2,0.3-0.3,0.4-0.2C88.7,223.4,88.3,223.6,88.3,223.4
        M474.9,223.6c0.2-0.2,0.4-0.5,0.7-0.4C475.4,223.3,475.2,223.5,474.9,223.6 M201.9,223.7c0.3-0.1,0,0.5-0.1,0.1L201.9,223.7
        M168.5,224C168.6,224.1,168.6,224.1,168.5,224 M87.2,224.1c0.2,0,0.4,0.1,0.5,0.3c-0.1,0-0.3-0.1-0.4-0.1c0,0.1,0,0.3-0.1,0.4
        c-0.1,0-0.2,0-0.2,0C87,224.4,87.1,224.2,87.2,224.1 M225.8,224.1C225.9,224.2,225.9,224.2,225.8,224.1 M232.9,224.1
        C233,224.2,233,224.2,232.9,224.1 M427.1,224.3c0.1-0.1,0.2-0.2,0.3-0.3c0.2,0.1,0.5,0.5,0.2,0.7
        C427.6,224.4,427.5,224.2,427.1,224.3 M223.5,224.3c0.2-0.1,0.3,0,0.2,0.2C223.6,224.5,223.5,224.5,223.5,224.3 M86.6,224.4
        c0.1,0,0.2,0,0.3,0c-0.1,0.2-0.1,0.4-0.3,0.6c-0.2,0-0.4,0-0.5-0.2c0.2,0,0.5-0.1,0.6-0.1L86.6,224.4 M311.3,225
        c0.1-0.2,0.2-0.3,0.3-0.5l0.1,0.2c-0.1,0-0.2,0.1-0.3,0.2c0.4,0.3,0.1,1-0.4,1.1c0-0.1,0.1-0.3,0.2-0.4l-0.1,0
        c0,0.1-0.1,0.2-0.2,0.3C310.5,225.4,311.8,225.5,311.3,225 M428,224.8c0.1-0.2,0.6,0,0.4,0.2C428.3,225.1,427.8,225.1,428,224.8
        M329.5,225L329.5,225l0.1,0.1L329.5,225L329.5,225 M338.2,225C338.3,225.1,338.3,225.1,338.2,225 M203.2,226c-0.6,0-1-0.9-0.3-1.1
        C202.6,225.3,203.2,225.5,203.2,226 M202.4,225.4C202.5,225.5,202.5,225.5,202.4,225.4 M203.2,225.6
        C203.3,225.7,203.3,225.7,203.2,225.6 M177.2,225.9c-0.1,0.2-0.5-0.3-0.2-0.2C177.1,225.7,177.2,225.8,177.2,225.9 M85.2,225.9
        c0.2-0.1,0.3,0.2,0.2,0.3C85.2,226.2,85,226,85.2,225.9 M429.3,226.2c-0.1-0.1,0.1-0.4,0.2-0.3C429.7,226,429.5,226.2,429.3,226.2
        M465.2,226C465.6,226,464.9,226.4,465.2,226 M49.9,226.3C50,226.4,50,226.4,49.9,226.3 M464.7,226.3
        C464.8,226.4,464.8,226.4,464.7,226.3 M310.3,226.5c0.3,0,0,0.5-0.2,0.4C310.1,226.7,310.2,226.5,310.3,226.5 M328.1,227.1
        C328.5,226.9,328.1,227.6,328.1,227.1 M196.1,227.2C196.2,227.3,196.2,227.3,196.1,227.2 M318,227.2L318,227.2L318,227.2L318,227.2
        L318,227.2 M432,227.7c0.1-0.1,0.2-0.1,0.4-0.2l0,0.1l-0.3,0.1c0.3,0.2,0.6,0.2,0.9,0.5c-0.1,0-0.3,0.1-0.4,0.1
        C432.4,228.1,432.1,228,432,227.7 M82.2,227.9c0.1,0,0.4-0.1,0.5-0.2c-0.2,0.4-0.6,0.6-1,0.5C81.9,228,82.1,227.9,82.2,227.9
        M204.4,227.8C204.8,227.6,204.4,228.3,204.4,227.8 M309.6,227.8C310,227.6,309.6,228.3,309.6,227.8 M335.9,228
        C336.3,227.8,336,228.5,335.9,228 M433,228.2c0.1,0,0.2,0,0.3,0l0.1,0.1C433.3,228.6,432.8,228.4,433,228.2 M209.3,228.8
        c0.1-0.2,0.4-0.2,0.6-0.1c0,0.1-0.1,0.2-0.1,0.2C209.6,228.9,209.4,228.9,209.3,228.8 M46,228.7C46.4,228.4,46,229.1,46,228.7
        M468.6,228.6C469,228.5,468.6,229.1,468.6,228.6 M55.9,228.8C56.3,228.9,55.6,229.2,55.9,228.8 M316.1,229.2c0-0.2,0.1-0.5,0.3-0.5
        c0,0.1,0.1,0.2,0.1,0.3C316.4,229.1,316.3,229.2,316.1,229.2 M458.8,228.8C459.3,228.9,458.6,229.3,458.8,228.8 M81.5,229.3
        C81.6,229.3,81.6,229.3,81.5,229.3 M198.6,229.2c0.3,0,0.6,0.2,0.8,0.4c-0.2,0-0.5,0-0.7,0l0.1,0.2c0.1,0,0.2,0.1,0.3,0.1
        c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2-0.1-0.2-0.1C198.6,229.7,198.5,229.4,198.6,229.2 M433.2,229.3
        C433.2,229.3,433.2,229.3,433.2,229.3 M55.9,229.4C56,229.5,56,229.5,55.9,229.4 M198.4,229.4C198.5,229.5,198.5,229.5,198.4,229.4
        M458.8,229.4C458.9,229.5,458.9,229.5,458.8,229.4 M39.4,229.5c0.2-0.1,0.3,0,0.3,0.2C39.4,229.8,39.3,229.7,39.4,229.5
        M143.4,229.6C143.5,229.7,143.5,229.7,143.4,229.6 M275.6,229.6C275.7,229.7,275.7,229.7,275.6,229.6 M475.1,229.5
        c0.2-0.1,0.3,0,0.2,0.2C475.1,229.8,475,229.7,475.1,229.5 M366.8,229.7C367.2,229.5,366.7,230.1,366.8,229.7 M48.2,230
        C48.3,230.1,48.3,230.1,48.2,230 M77.7,230.3c-0.1-0.3,0.6-0.3,0.4,0C78,230.3,77.8,230.3,77.7,230.3 M59.6,230.5
        c-0.2-0.2,0.2-0.2,0.3-0.1c0.1,0,0.5,0.3,0.2,0.3C59.9,230.6,59.8,230.6,59.6,230.5 M60.7,230.4c0.2-0.1,0.3,0,0.2,0.2
        C60.7,230.6,60.6,230.6,60.7,230.4 M66.5,230.4c0.1-0.1,0.4,0.1,0.3,0.3C66.7,230.7,66.4,230.6,66.5,230.4 M181.1,230.5
        c0-0.3,0.5-0.1,0.3,0.1l-0.1,0C181.3,230.7,181.2,230.6,181.1,230.5 M448,230.7c-0.1-0.2,0.2-0.4,0.3-0.3
        C448.4,230.6,448.1,230.7,448,230.7 M453.8,230.4c0.2-0.1,0.3,0,0.2,0.2C453.8,230.6,453.8,230.6,453.8,230.4 M452.5,230.5
        C452.6,230.6,452.6,230.6,452.5,230.5 M452.2,230.7C452.7,230.7,452,231.1,452.2,230.7 M181.4,230.9c0.2,0,0.5,0,0.4,0.2
        C181.9,231.5,181.3,231.1,181.4,230.9 M307.9,230.8C308.3,230.8,307.6,231.3,307.9,230.8 M49.6,231c0.2-0.1,0.3,0,0.2,0.2
        C49.6,231.3,49.6,231.2,49.6,231 M308.7,231.1C309.1,231.1,308.5,231.5,308.7,231.1 M262.5,231.2L262.5,231.2c0.1,0,0.1,0.1,0.1,0.2
        l-0.1,0C262.5,231.4,262.5,231.3,262.5,231.2 M238.2,231.6c0.2-0.1,0.3,0,0.3,0.2C238.3,231.9,238.2,231.8,238.2,231.6 M43.1,231.7
        c0.1-0.1,0.4,0.1,0.3,0.2C43.3,232,43,231.8,43.1,231.7 M76,232.1c-0.1-0.2,0.1-0.4,0.3-0.3C76.4,231.9,76.1,232.2,76,232.1
        M145.6,231.7c0.2-0.1,0.3,0.2,0.3,0.4C145.7,232.1,145.5,231.8,145.6,231.7 M307.3,232c-0.1-0.2-0.1-0.3,0.1-0.4
        C307.8,231.6,307.6,232.2,307.3,232 M73.6,232c-0.1-0.3,0.6-0.3,0.5,0C73.9,232.1,73.7,232.1,73.6,232 M438.1,231.8
        C438.2,231.9,438.2,231.9,438.1,231.8 M438.5,231.8c0.2-0.1,0.3,0,0.3,0.3C438.6,232.1,438.5,232,438.5,231.8 M478.8,231.8
        C478.9,231.9,478.9,231.9,478.8,231.8 M43.4,232.1C43.5,232.2,43.5,232.2,43.4,232.1 M52.4,232.1C52.8,232.2,52.1,232.5,52.4,232.1
        M236.2,232.1c0.2-0.1,0.3,0,0.2,0.2C236.3,232.3,236.2,232.3,236.2,232.1 M44.2,232.3c0.2,0,0.2,0.2,0.1,0.3
        C44.1,232.5,44.1,232.4,44.2,232.3 M53.2,232.6c-0.4,0,0-0.3,0.2-0.4c0,0.2,0,0.4,0,0.5l0,0C53.3,232.7,53.3,232.6,53.2,232.6
        M53.8,232.2C53.9,232.3,53.9,232.3,53.8,232.2 M164,232.4c0.1-0.2,0.3-0.2,0.4-0.1c-0.2,0.1-0.4,0.3-0.6,0.3c0,0.2-0.2,0.6-0.2,0.1
        c-0.2,0.1-0.4,0.2-0.6,0.4c-0.1,0.2-0.2,0-0.3,0c-0.2,0.1-0.5,0.2-0.7,0.4c-0.3,0-0.5,0.1-0.7,0.2l0,0.1c-0.2,0-0.5,0-0.7,0l0,0.2
        c-0.6,0-1.2,0.2-1.8,0.2c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.6,0.1-1.3,0-1.9,0c-0.2-0.3-0.7-0.1-1-0.2
        c-0.3-0.1-0.6-0.2-1-0.3c-0.4-0.1-0.7-0.4-1.1-0.7l0-0.2c0.2,0.2,0.5,0.3,0.7,0.6c0.3,0.2,0.6,0.4,1,0.5c0.4,0.2,0.8,0,1.2,0.2
        c0.4,0.1,0.7,0.1,1.1,0.1c0.9-0.1,1.8,0,2.6-0.3c0.1,0,0.3,0,0.4,0l0-0.1c0.2,0,0.4,0,0.5,0l0-0.2c0.2,0,0.4,0,0.6,0l0-0.1
        c0.2,0,0.4-0.1,0.6-0.1c0.1-0.2,0.3-0.3,0.6-0.2l-0.1-0.2c0.2,0,0.4-0.1,0.7-0.1c0.1-0.2,0.4-0.3,0.6-0.3l0-0.2
        C163.8,232.4,163.9,232.4,164,232.4 M232.9,232.3c0.1-0.2,0.3-0.2,0.4,0C233.1,232.5,233,232.5,232.9,232.3 M302.9,232.2
        C303,232.3,303,232.3,302.9,232.2 M470.3,232.4c0.1-0.3,0.5,0,0.2,0.2C470.4,232.6,470.3,232.6,470.3,232.4 M139.2,232.4
        C139.6,232.1,139.2,232.9,139.2,232.4 M44.7,232.7c0.1-0.2,0.2-0.2,0.4,0C45,232.9,44.8,232.9,44.7,232.7 M54,232.6
        c0.2-0.1,0.5,0.1,0.3,0.3C54.2,233,53.9,232.8,54,232.6 M74.3,232.7C74.3,232.8,74.3,232.8,74.3,232.7 M440.4,232.7
        C440.5,232.8,440.5,232.8,440.4,232.7 M139.5,232.8c0.2-0.1,0.3,0,0.2,0.3C139.5,233.1,139.4,233,139.5,232.8 M271.4,233.6
        c0.1-0.3,0.2-0.6,0.4-0.9c0,0.2,0,0.5,0,0.7C271.7,233.5,271.6,233.6,271.4,233.6 M126.2,233.1c0,0,0.1-0.1,0.1-0.1l-0.1,0.3
        l0.4-0.2c-0.2,0.5-0.7,0.3-1.1,0.4C125.7,233.3,126,233.2,126.2,233.1 M331.2,233.2c0-0.3,0.4-0.3,0.7-0.2
        C331.6,233.1,331.4,233.3,331.2,233.2 M47.1,233.3c0.2,0,0.4,0.2,0.4,0.4C47.3,233.8,47.1,233.5,47.1,233.3 M148.1,233.2
        C148.1,233.3,148.1,233.3,148.1,233.2 M56.6,233.4c0.1-0.1,0.5,0,0.4,0.2C56.9,233.8,56.5,233.6,56.6,233.4 M148.3,233.4
        c0.2-0.1,0.3,0,0.2,0.2C148.3,233.7,148.3,233.6,148.3,233.4 M442.5,233.8c0-0.2,0.4-0.3,0.5-0.4C443.1,233.7,442.7,234,442.5,233.8
        M457.7,233.6c-0.1-0.2,0.2-0.4,0.4-0.2C458.2,233.6,457.9,233.8,457.7,233.6 M443.5,233.5c0,0,0.1,0.1,0.1,0.1
        C443.6,234,443.1,233.6,443.5,233.5 M70.3,233.7c0.2-0.1,0.3,0,0.3,0.2C70.3,234,70.2,233.9,70.3,233.7 M109.4,233.7
        C109.5,233.7,109.5,233.7,109.4,233.7 M444.2,233.7c0.2-0.1,0.3,0,0.2,0.2C444.2,234,444.2,234,444.2,233.7 M64,233.8L64,233.8
        L64,233.8L64,233.8L64,233.8 M271,233.8c0.2-0.1,0.3,0,0.2,0.2C271,234.1,270.9,234,271,233.8 M322.8,233.8c0.2,0.1,0.2,0.2,0,0.3
        C322.7,234,322.7,233.9,322.8,233.8 M455.9,233.8c0.2-0.1,0.3,0,0.2,0.2C455.9,234,455.8,234,455.9,233.8 M60.9,234
        C61,234,61,234,60.9,234 M124.6,234C124.7,234,124.7,234,124.6,234 M448.4,234C448.5,234,448.5,234,448.4,234 M196.4,234.5
        c-0.1-0.4,0.6,0,0.7-0.3l0.1,0.1C197,234.4,196.7,234.7,196.4,234.5 M67.3,234.4c0.4-0.1,0.1,0.6-0.2,0.4
        C67.1,234.6,67.2,234.5,67.3,234.4 M447.2,234.4c0.2-0.1,0.4,0.3,0.4,0.4C447.4,234.9,447.1,234.6,447.2,234.4 M65,234.8
        c0.2,0.1,0.6,0,0.5,0.4c-0.2,0-0.5,0-0.7-0.1l0.3-0.1L65,234.8 M361.5,234.8L361.5,234.8L361.5,234.8L361.5,234.8L361.5,234.8
        M193.5,235C194,235,193.3,235.4,193.5,235 M151.3,235.1c0.2-0.1,0.3,0,0.2,0.2C151.3,235.3,151.3,235.3,151.3,235.1 M303.6,235.1
        C304,235.1,303.4,235.5,303.6,235.1 M453.2,235.1C453.3,235.2,453.3,235.2,453.2,235.1 M459.9,235.1c0.2,0,0.4,0,0.6,0
        c0,0,0,0.1,0,0.1C460.3,235.2,460,235.3,459.9,235.1 M57.6,235.3c0.2-0.1,0.3,0,0.2,0.2C57.6,235.6,57.5,235.5,57.6,235.3
        M60.8,235.3c0.1-0.2,0.6-0.1,0.4,0.2C61.1,235.6,60.6,235.5,60.8,235.3 M192.3,235.3c0.2-0.2,0.6,0.1,0.3,0.3
        C192.4,235.7,192.1,235.5,192.3,235.3 M453.6,235.5c-0.2-0.3,0.3-0.4,0.4-0.2C454.1,235.5,453.7,235.6,453.6,235.5 M456.9,235.3
        c0.2-0.1,0.3,0,0.2,0.2C456.9,235.5,456.8,235.5,456.9,235.3 M58.7,235.4C58.8,235.5,58.8,235.5,58.7,235.4 M141.2,235.4
        c0.2-0.1,0.3,0,0.2,0.2C141.2,235.7,141.1,235.6,141.2,235.4 M191.9,235.4c0.2-0.1,0.3,0,0.2,0.2
        C191.9,235.6,191.8,235.6,191.9,235.4 M328.9,235.4C329,235.5,329,235.5,328.9,235.4 M460.3,235.4L460.3,235.4L460.3,235.4
        L460.3,235.4L460.3,235.4 M141.5,235.8C141.6,235.9,141.6,235.9,141.5,235.8 M141.9,236c0.3-0.2,0.3,0.2,0.2,0.4
        C141.9,236.4,141.7,236.1,141.9,236 M329.3,235.9c0.1-0.2,0.4,0,0.4,0.2C329.5,236.3,329.1,236.2,329.3,235.9 M43.8,236.2
        c0.1-0.1,0.4,0.1,0.4,0.3C44.1,236.6,43.8,236.4,43.8,236.2 M268.3,236.2L268.3,236.2L268.3,236.2L268.3,236.2L268.3,236.2
        M471.1,236.4c0.2-0.1,0.3,0,0.2,0.2C471.1,236.6,471,236.6,471.1,236.4 M154.9,236.5c0,0.1,0,0.2,0,0.2c0.4,0,0.4,0.3,0.4,0.6
        c-0.2-0.2-0.3-0.3-0.5-0.5c0,0-0.1-0.1-0.1-0.1C154.8,236.7,154.9,236.6,154.9,236.5 M305.6,236.5
        C305.7,236.6,305.7,236.6,305.6,236.5 M328.5,236.8c0.2-0.1,0.4,0.1,0.5,0.2C328.9,237.2,328.5,237,328.5,236.8 M360.1,236.8
        C360.1,236.9,360.1,236.9,360.1,236.8 M266.2,237.6c-0.3-0.1,0-0.5,0.2-0.5C266.6,237.2,266.4,237.6,266.2,237.6 M158,238.1
        c-0.3,0.4-0.7-0.4-0.2-0.3C157.8,237.9,158,238.1,158,238.1 M158.9,238.3C159,238.4,159,238.4,158.9,238.3 M159.5,238.4
        c0.2-0.1,0.3,0,0.2,0.2C159.5,238.7,159.5,238.6,159.5,238.4 M149.8,238.5C150.2,238.3,149.8,239,149.8,238.5 M226.6,239.6
        c-0.1-0.2,0.2-0.3,0.3-0.3C227,239.5,226.7,239.7,226.6,239.6 M211.4,240.4c0.2,0,0.3,0,0.2,0.2
        C211.5,240.6,211.4,240.6,211.4,240.4 M165.3,240.5c0.2-0.1,0.4,0.1,0.4,0.2C165.6,241.1,165.1,240.8,165.3,240.5 M230.7,240.8
        C231.1,240.5,230.7,241.3,230.7,240.8 M231,241.1c0-0.2,0.2-0.4,0.4-0.3C231.5,240.9,231.1,241.3,231,241.1 M123.6,241.2
        C123.7,241.3,123.7,241.3,123.6,241.2 M152.3,241.6c0.3,0.1,0.6,0.1,0.7,0.3c-0.2,0-0.5,0.1-0.6,0.1
        C152.4,241.9,152.3,241.8,152.3,241.6 M205.1,241.6c0.2,0.1,0.2,0.2,0,0.3C204.8,241.8,204.9,241.7,205.1,241.6 M316.7,241.9
        C316.8,242,316.8,242,316.7,241.9 M202.8,242.3C203.2,242.1,202.8,242.8,202.8,242.3 M201.2,243.2c-0.1-0.2,0.3-0.5,0.4-0.2
        C201.6,243.2,201.4,243.2,201.2,243.2 M306.2,243.2c0,0,0.1-0.1,0.1-0.1c0.2,0.2,0.6,0.2,0.6,0.6
        C306.7,243.5,306.3,243.5,306.2,243.2 M293.4,243.2C293.5,243.3,293.5,243.3,293.4,243.2 M387.4,243.2c0.2,0.1,0.2,0.2,0,0.3
        C387.2,243.4,387.2,243.3,387.4,243.2 M392.7,244.1c0.3-0.1,0.6-0.1,0.9-0.2c0,0.1-0.1,0.2-0.1,0.2c0,0.1,0.1,0.2,0.1,0.4
        C393.4,244.2,392.9,244.5,392.7,244.1 M164.4,244.2c0.1-0.2,0.5-0.1,0.3,0.2C164.6,244.5,164.2,244.4,164.4,244.2 M194.5,244.1
        c0.1,0,0.2,0.1,0.3,0.2c0.2,0,0.4,0,0.6,0c-0.1,0.5-0.6,0-0.9,0.2C194.5,244.4,194.5,244.2,194.5,244.1 M194.2,244.3
        c0.2,0,0.3,0,0.2,0.2C194.2,244.5,194.1,244.5,194.2,244.3 M390.9,244.8c0.3,0.1-0.1,0.5-0.2,0.3
        C390.6,244.9,390.8,244.8,390.9,244.8 M165.6,244.9c0.2-0.1,0.3,0,0.2,0.2C165.6,245.2,165.5,245.1,165.6,244.9 M391.3,244.9
        c0.2-0.1,0.3-0.1,0.3,0.1C391.4,245.1,391.3,245.1,391.3,244.9 M390.1,245.2c0.1-0.1,0.5-0.1,0.5,0.1
        C390.6,245.5,390.1,245.4,390.1,245.2 M389.8,245.4c0.1,0,0.3,0.1,0.4,0.2c-0.1,0-0.4,0.1-0.5,0.1c-0.1,0.2,0,0.8-0.4,0.7
        c-0.3,0.1-0.4-0.2-0.2-0.4c0.2,0,0.4,0,0.6,0c-0.1-0.1-0.3-0.2-0.4-0.3c0.1,0,0.4,0,0.5,0.1L389.8,245.4 M390.2,245.6
        c0.3-0.1,0,0.5-0.1,0.1L390.2,245.6 M193.9,246.4c-0.1-0.2-0.1-0.3,0-0.4c0.2,0.1,0.3,0.2,0.3,0.4
        C194.1,246.5,194,246.5,193.9,246.4 M164,246.2C164.4,246,164,246.7,164,246.2 M313.5,246.3L313.5,246.3
        C313.5,246.3,313.5,246.3,313.5,246.3L313.5,246.3 M178.2,246.8c0.2,0,0.6-0.3,0.6,0C178.7,247,178.2,247.1,178.2,246.8
        M283.7,246.6c0.2,0.1,0.3,0.4,0.3,0.6C283.7,247.3,283.6,246.8,283.7,246.6 M308.5,247c-0.1-0.1,0-0.5,0.2-0.4
        C308.9,246.8,308.7,247.2,308.5,247 M108.8,246.9c0.2-0.1,0.3,0,0.2,0.2C108.8,247.2,108.7,247.2,108.8,246.9 M166.4,247.1
        c0.2,0.1,0.4,0.2,0.6,0.3c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1-0.2-0.3-0.3-0.4-0.4C166.3,247.2,166.4,247.1,166.4,247.1 M312.5,247.6
        c0-0.2,0-0.3,0.3-0.2C312.8,247.5,312.7,247.6,312.5,247.6 M482.2,248.1c0.1,0,0.4-0.4,0.2,0c0.3-0.1,0.7-0.2,1-0.2
        c0.2-0.1,0.3-0.1,0.3,0.2c0.3,0,0.6-0.1,0.9-0.2l-0.1,0.2c0.3-0.2,0.7-0.2,1.1-0.2c0,0.1,0,0.2,0,0.2c0.1,0,0.4-0.1,0.5-0.2
        c0.1,0.1,0.2,0.2,0.2,0.2c0.2,0,0.5,0,0.8-0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2-0.1,0.2-0.1c0,0.1,0,0.2,0,0.2
        c0.5-0.1,1.1,0,1.6,0.2c0.4,0,0.8,0.1,1.2,0.2c0.2,0,0.6-0.1,0.3,0.3c0.3-0.2,0.6,0,1,0.1c0.5,0.2,1,0.3,1.5,0.5
        c-0.2,0.1-0.3,0.5-0.6,0.3c-0.2-0.1-0.6,0-0.7-0.3c-0.1,0-0.2,0.1-0.3,0.1l0-0.2c-0.4,0-0.7-0.2-1-0.2c-0.1-0.3-0.4-0.2-0.6-0.3
        c-0.2-0.1-0.5-0.1-0.7-0.2c-0.2,0-0.5,0-0.6-0.2c-0.2,0-0.5,0-0.7,0c0-0.1,0-0.2,0-0.2c-0.1,0-0.2,0.1-0.3,0.2
        c-0.4-0.3-0.9-0.2-1.3-0.2c-0.3-0.2-0.7-0.1-1-0.3c-0.1,0.3-0.3,0-0.4,0c-0.1,0-0.3,0.1-0.4,0.1c-0.1-0.2-0.3-0.1-0.4,0
        c-0.2,0-0.6,0.1-0.6-0.3c-0.3,0.3-0.8,0-1.1,0.3c-0.4,0.1-0.9-0.1-1.3,0.1c-0.2-0.2-0.4,0-0.6,0.1c-0.5-0.1-0.9,0.4-1.4,0.3
        c-0.2,0.1-0.4,0.1-0.5,0.2c-0.2,0.1-0.3,0.1-0.5,0.1c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2,0-0.3-0.1c0,0.1-0.1,0.2-0.2,0.2
        c-0.5-0.1-0.8,0.6-1.3,0.3c0,0,0,0.1,0,0.2c-1.3,0.4-2.3,1.3-3.3,2.2c-0.4,0.3-0.8,0.8-1.2,1.1c0.3-0.7,1-1.1,1.6-1.6
        c0.3-0.3,0.8-0.5,1.1-0.9c0.6-0.5,1.4-0.6,2-1.2c0.1,0,0.2,0.1,0.2,0.1c0.5-0.3,1-0.6,1.6-0.7l0-0.1c0.1,0,0.2,0.1,0.3,0.1
        c0,0,0.1-0.1,0.2-0.2c0.2,0,0.3,0,0.5,0c0.4-0.2,0.8-0.2,1.2-0.4c0.1,0,0.2,0.1,0.2,0.1c0.1-0.1,0.3-0.2,0.4-0.3l0,0.3
        C481.3,248,481.7,248.1,482.2,248.1 M107.4,248.3c0.2-0.2,0.3,0.2,0.1,0.3C107.2,248.7,107.2,248.3,107.4,248.3 M181.3,248.3
        C181.8,248.3,181.1,248.7,181.3,248.3 M232.1,248.6c-0.2,1-0.1,2-0.1,3l-0.2-0.1c0,0.5,0,0.9,0,1.4l-0.2-0.1c0,0,0,0.1,0,0.2
        l0.2-0.1l0,0.4l-0.3-0.1c0.1,0.1,0.2,0.1,0.4,0.1c0.4-0.2,0.9-0.3,1.3-0.4l0,0.2c0.1,0,0.3-0.1,0.3-0.1c0,0.1-0.1,0.3-0.1,0.4
        c-0.1,0-0.2,0-0.3-0.1c0,0.1,0.1,0.2,0.1,0.3c-0.1,0-0.2,0-0.3,0c0.1,0.1,0.2,0.3,0.2,0.5c-0.1,0-0.3-0.1-0.3-0.1l0,0.3
        c-0.1-0.1-0.3-0.2-0.4-0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.2,0.4-0.1,0.8-0.4,1.1l0.2,0c0-0.1,0.1-0.2,0.2-0.3c0.2,0.2-0.1,0.5-0.1,0.7
        c-0.2,0-0.4,0-0.5,0c0.2,0.1,0.4,0.2,0.5,0c0.3,0,0.4,0.4,0.7,0.6c0-0.1,0-0.4,0-0.5c0.1,0.1,0.1,0.3,0.1,0.5c0.2,0,0.5,0,0.5,0.4
        l0.1,0c0.1,0.1,0.1,0.2,0.2,0.3c-0.2,0.1-0.5,0.1-0.7,0.2c-0.1-0.1-0.3-0.2-0.4-0.3c0.4,0.5-0.3,0.6-0.6,0.9c0,0.1,0.3,0.3,0.1,0.5
        c-0.1,0-0.3,0-0.3,0c0.1,0.1,0.3,0.1,0.4,0.2c0-0.1,0.1-0.3,0.1-0.3l0.2,0.1l-0.1,0.3c0.1,0,0.2,0,0.3-0.1c0,0,0,0.1,0,0.1
        c-0.1,0-0.4,0-0.5-0.1c0.1,0.3,0.4,0.5,0.6,0.7c-0.2,0-0.3-0.1-0.5-0.1c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0.1-0.2,0.3-0.2,0.4
        c0.1,0,0.2-0.1,0.3-0.1c0,0.2,0.1,0.4,0.1,0.6c0.1,0,0.2,0,0.3-0.1c0,0.1-0.1,0.2-0.1,0.3l0.4-0.1c-0.1,0.3-0.4,0.5-0.7,0.6
        c0-0.2-0.1-0.3-0.2-0.5c0,0.1,0.1,0.3,0.1,0.4c-0.3-0.2-0.3,0.3-0.6,0.3c0,0.3,0.1,0.6,0,0.9c0.1,0,0.2,0,0.3,0c0,0-0.1,0.1-0.2,0.1
        c0,0.1,0,0.4,0.1,0.5c0.1,0,0.2,0,0.3,0c0,0,0,0.1,0,0.1l-0.2-0.1c0,0.1,0,0.4,0,0.5l0.2,0c0,0.2-0.2,0.3-0.4,0.4
        c0.1,0.1,0.2,0.2,0.3,0.4c0-0.1,0.1-0.2,0.2-0.3l0,0c-0.1,0.3,0,0.6,0,0.9c0.1,0,0.2,0.1,0.2,0.1c-0.1,0-0.3,0-0.4,0
        c0-0.2,0-0.3-0.1-0.5c0,0-0.1,0.1-0.2,0.1c0.1,0.2,0.2,0.3,0.3,0.5c-0.2,0.1-0.3,0.3-0.5,0.4c0.1,0,0.2,0,0.3,0
        c0.1-0.1,0.3-0.2,0.4-0.3c0.3,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.2,0.4-0.2,0.6c0.1,0.1,0.2,0.1,0.3,0.2c-0.1-0.1-0.1-0.2-0.2-0.4
        c0.3-0.1,0.5-0.2,0.6-0.5c0.3,0.2,0.1,0.4-0.1,0.6c0.4,0.3-0.2,0.4-0.4,0.6c0.3,0,0.5-0.1,0.8-0.1c0.2,0.3,0.1,0.6-0.2,0.7
        c0-0.1,0-0.3,0-0.4c-0.3,0-0.3,0.2,0,0.4c-0.1,0.1-0.2,0.2-0.2,0.3c-0.3-0.1-0.2-0.4-0.4-0.6c0,0.1,0,0.3,0,0.4
        c0.1,0.1,0.2,0.2,0.3,0.3c0.2-0.1,0.4-0.2,0.6-0.3c0,0.1,0.1,0.2,0.2,0.3c0-0.3,0-0.6,0.1-0.8c0.3,0.2,0.2,0.6,0.2,1
        c0.2,0.3,0.4,0.6,0.3,0.9c0.1,0,0.3,0,0.4,0l0,0.1c-0.1,0-0.3,0-0.4,0c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0.1,0,0.2-0.1
        c0,0.2,0.1,0.4,0.1,0.6c0.1,0,0.3,0,0.4,0c0.1,0.4-0.4,0.3-0.6,0.3c0.1,0.1,0.3,0.2,0.5,0.2c0,0.1-0.1,0.3-0.1,0.4
        c0.1-0.1,0.2-0.2,0.3-0.3c0,0.1,0.1,0.4,0.2,0.5c0.1,0,0.3,0.1,0.4,0.2c-0.1,0-0.3,0-0.4,0c0,0.3,0.2,0.3,0.5,0.3c0,0.2,0,0.3,0,0.5
        c0.2,0.1,0.3,0.2,0.5,0.4c0-0.1,0.1-0.2,0.1-0.3c0,0.1,0,0.3,0,0.3c0.2,0.1,0.2,0.2,0,0.4c0.1,0.2,0.3,0.4,0.4,0.6
        c0,0.2,0,0.3,0,0.5c0.1,0,0.3,0,0.3,0c0.2,0.2,0.5,0.4,0.3,0.7c0.1,0,0.2,0,0.2,0c0,0.3,0.2,0.4,0.4,0.6c0.1,0.2,0.1,0.4,0.2,0.6
        c0.1,0.2,0.3,0.3,0.5,0.3l-0.1,0.2l0.2-0.1c0,0,0,0.1,0,0.1l-0.4,0c0.2,0.2,0.7,0.6,0.1,0.7c0.1,0.1,0.2,0.2,0.3,0.3
        c0,0.1-0.1,0.3,0.1,0.4c0,0.3,0,0.6,0,0.9c0-0.1,0.1-0.3,0.1-0.4c0.3,0.2,0.1,0.7,0.4,1c0.4,0.7,0.3,1.6,0.7,2.4
        c-0.4-0.4-0.4-1.2-0.7-1.7c-0.3-0.4-0.3-1-0.8-1.3c-0.1-0.2-0.1-0.6-0.4-0.4l0-0.1c-0.1,0-0.3,0-0.4,0c-0.1-0.1-0.2-0.2-0.3-0.3
        c-0.2-0.1-0.5-0.1-0.7-0.2c-0.2-0.1-0.4-0.4-0.6-0.2c-0.5-0.4-1.2-0.5-1.7-1c-0.2,0-0.4,0-0.5-0.2c-0.3-0.3-0.8-0.3-1.1-0.6
        l-0.1,0.1c0,0.1,0.1,0.3,0.2,0.4c-0.3-0.1-0.5-0.3-0.5-0.6c-0.4,0.2,0.1,0.5,0.2,0.7c0.2,0,0.5,0.1,0.7,0.1c-0.1,0-0.3,0.1-0.4,0.2
        c0,0,0.1,0.1,0.2,0.2l0.2-0.1c0.1,0.1,0.1,0.2,0,0.3c0.1,0.3,0.1,0.6,0,0.8c0.1-0.1,0.2-0.2,0.3-0.2c0.2,0,0.4,0.1,0.6,0.1
        c0,0,0,0.1,0,0.1c-0.1,0-0.3,0-0.4,0c0.2,0.3,0.5,0.5,0.7,0.7c-0.2,0-0.5-0.1-0.7-0.1c0.2,0.3,0.3,0.6,0.3,0.9
        c0.1-0.3,0.1-0.6,0.1-0.8c0.1,0,0.3,0.1,0.3,0.2c-0.1,0.5,0.3,0.9,0.5,1.3l-0.2,0c0.2,0.1,0.3,0.2,0.5,0.3c0,0.1-0.1,0.2-0.1,0.3
        c0.3,0.1,0.5,0.3,0.6,0.6c-0.3-0.1-0.3,0.3-0.5,0.4c0.1-0.1,0.3-0.1,0.4-0.2c0.1,0.1,0.1,0.6,0.4,0.5c0,0.1,0.1,0.2,0.1,0.3
        c-0.1,0-0.2,0-0.3,0c0,0.2,0.1,0.3,0.1,0.5l0.3-0.1c0,0.1-0.1,0.3-0.1,0.4l0.2-0.1c0.1,0.2,0.1,0.4,0.2,0.6l0.1,0
        c0,0.3,0.2,0.6,0.3,0.9c-0.1-0.1-0.3-0.3-0.4-0.4c0,0.1,0.2,0.2,0.2,0.3c0.1,0.2,0.3,0.4,0.4,0.6c-0.1,0-0.2,0.1-0.3,0.1
        c0.1,0,0.3,0,0.4,0c0,0.2,0,0.4,0,0.6c0.1,0,0.2,0,0.2,0c0,0.1,0,0.3,0,0.5l0.1,0l0,0.3c-0.1,0-0.2,0-0.2,0.1l0.2,0
        c0,0.4,0.1,0.7,0.2,1.1c0.1,0.7,0.2,1.3,0.3,2c0.1,0.5,0,1.1,0,1.6l-0.1,0c0,0.4-0.1,0.8,0,1.2c0,0-0.1,0.1-0.2,0.1
        c0.1-0.4,0.1-0.9,0-1.3c0.3-0.3,0-0.7,0-1c0.2-0.2,0-0.4,0-0.6c-0.1-0.4-0.1-0.8-0.3-1.1c0-0.7-0.6-1.2-0.5-1.9h-0.1
        c0-0.5-0.5-0.8-0.6-1.3c-0.1-0.5-0.7-0.7-0.6-1.3c-0.6-0.4-1.2-0.9-1.3-1.6c-0.1,0-0.2-0.1-0.3-0.1c-0.2-0.3-0.6-0.5-0.7-0.9
        c-0.1,0-0.3-0.1-0.4-0.1c-0.2-0.3-0.1-0.8-0.5-0.7c-0.2-0.4-0.7-0.7-0.7-1.3c-0.1,0-0.3,0-0.4-0.1c-0.1-0.2-0.2-0.3-0.3-0.5
        l-0.1,0.2c-0.2,0.1-0.6,0.2-0.4,0.5c0.1-0.1,0.3-0.1,0.4-0.1c0.1,0.1,0.1,0.3,0.2,0.4c-0.1,0-0.2,0.1-0.2,0.2c0-0.1-0.1-0.4-0.1-0.5
        c-0.1,0.1-0.3,0.3-0.4,0.4c0.1,0,0.3,0,0.4,0c-0.1,0.2-0.2,0.3-0.3,0.5c0.2-0.1,0.4-0.2,0.6-0.3c-0.2,0.3-0.5,0.6-0.9,0.8
        c0.2,0,0.4,0,0.5-0.3c0.1,0,0.3,0.1,0.4,0.2c-0.5,0-0.5,0.6-0.2,0.9c0,0.1,0,0.3,0,0.4c0.1,0.1,0.2,0.2,0.2,0.3
        c-0.2,0-0.3-0.1-0.4-0.1c0.1,0.2,0.3,0.3,0.4,0.5c-0.1,0-0.3,0-0.4,0l0,0.1c0,0,0.1,0,0.2,0c0,0.1,0.1,0.3,0.2,0.4
        c-0.3-0.3-0.5,0.2-0.7,0.4c0.2-0.1,0.4-0.2,0.7-0.3c-0.1,0.2-0.2,0.4-0.3,0.7c0,0.2-0.1,0.4-0.3,0.6c0.1,0.1,0.2,0.2,0.3,0.3
        c0,0,0.1,0,0.2,0c0,0.1,0,0.2,0,0.3c0.1,0,0.3,0,0.4,0l-0.2,0.1c0,0.1,0.1,0.4,0.2,0.5c-0.1,0-0.3,0-0.4,0c0-0.1,0.1-0.3,0.1-0.3
        l-0.1,0c0,0.2,0,0.3,0,0.5c0.1,0,0.2-0.1,0.3-0.1c-0.1,0.1-0.2,0.3-0.2,0.4c0.1,0,0.3-0.1,0.4-0.1c-0.1,0.1-0.2,0.4-0.2,0.6
        c-0.2-0.1-0.4-0.1-0.6-0.1c0,0,0,0.1,0,0.1c0.2,0,0.3,0,0.5,0c0,0.2-0.1,0.5-0.1,0.6c0.2-0.2,0.4-0.4,0.3-0.7c0.3,0.2,0,0.5,0,0.8
        c0,0,0.1,0.1,0.2,0.1c-0.1,0-0.2,0-0.3,0l0,0.4c0.1,0,0.3-0.1,0.4-0.2c0,0.2-0.1,0.4-0.3,0.5c0.1,0.1,0.2,0.2,0.3,0.2
        c-0.1,0-0.4,0-0.6,0c0.1,0.1,0.3,0.3,0.4,0.3c-0.1,0.2-0.2,0.4-0.3,0.6c0.1,0,0.3,0,0.4,0c0,0.2-0.1,0.4-0.1,0.6l0.3-0.1
        c-0.1,0.1-0.2,0.2-0.3,0.4c0.1,0,0.2,0.1,0.2,0.2c-0.1,0.1-0.3,0.3-0.4,0.4c0.6-0.2,0.5,0,0.2,0.2c0,0.2,0,0.5-0.1,0.7
        c0.1,0,0.2,0,0.3,0c-0.2,0.3-0.1,0.6-0.1,0.9l0.2-0.2c0,0.1-0.1,0.3-0.1,0.3l0.2,0c-0.2,0.2-0.4,0.3-0.4,0.6c0.1,0,0.3-0.1,0.4-0.1
        c-0.1,0.2-0.2,0.2-0.4,0.1l0.1,0.4c0.1-0.1,0.2-0.2,0.3-0.3l0,0.3l-0.3,0c0,0.2,0.1,0.5,0.2,0.6c-0.2-0.1-0.3-0.3-0.5-0.4
        c0.1,0.2,0.2,0.3,0.4,0.4c0,0.2,0,0.4,0,0.6c0.1,0,0.2,0,0.3,0l-0.1,0.3c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0.2-0.2,0.3-0.4,0.4
        c0.1,0.1,0.2,0.1,0.4,0.2c0-0.1-0.1-0.3-0.1-0.4l0.3,0c0,0.3,0,0.6,0.1,0.9l0.2,0l0-0.3c0,0.1,0,0.2,0,0.3l-0.3,0
        c0.1,0.1,0.2,0.3,0.2,0.5c-0.1,0.2,0,0.4,0.2,0.6c-0.1,0.1-0.2,0.3-0.3,0.4l0.3-0.1c0,0.5-0.1,1,0.1,1.4c-0.1,0.1-0.2,0.3-0.3,0.4
        c0.1,0,0.2-0.1,0.3-0.1c0.1,0.2,0.1,0.4,0.2,0.6l-0.3,0c0.1,0.1,0.2,0.2,0.3,0.3c0,0.1-0.1,0.2-0.1,0.2c0.1,0.2,0.1,0.3,0.2,0.5
        c-0.2,0-0.3,0-0.4,0.1c0.1,0,0.3,0,0.4,0c0,0.2-0.2,0.6,0.1,0.6c-0.1,0.6,0.1,1.2,0.2,1.9c0.1,0.1,0.2,0.2,0.2,0.3
        c0.1,0.2,0.3,0.4,0,0.5c0,0.2,0,0.4,0,0.7c0.1,0.1,0.2,0.3,0.1,0.5l0.1,0c0,0.2,0,0.4,0.1,0.7c0.1,0.2,0.1,0.3,0.2,0.5
        c0,0.6,0.4,1.2,0.4,1.8c0.3,0.6,0.4,1.3,0.6,2c-0.2-0.4-0.3-0.8-0.4-1.2c-0.1-0.4-0.3-0.7-0.3-1c0-0.2-0.2-0.4-0.3-0.6
        c-0.1-0.8-0.5-1.6-0.7-2.4c-0.2-0.5,0-1.1-0.4-1.6c-0.2-0.6-0.5-1.3-0.4-2c-0.1,0-0.2-0.1-0.2-0.1c-0.1-0.5-0.3-1-0.3-1.6
        c0-0.5-0.5-0.9-0.4-1.4c0.1-0.8-0.5-1.4-0.4-2.1c0,0-0.1-0.1-0.2-0.1c0-0.4,0-0.7,0-1c-0.1,0-0.2-0.1-0.2-0.2
        c0.1-0.5-0.3-1.1-0.1-1.6c-0.1-0.2-0.2-0.5-0.4-0.7c0.1-0.2,0.1-0.3,0.2-0.4c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.4-0.1-0.8-0.1-1.2
        c-0.1-0.3,0.1-0.7-0.2-1c-0.2-0.5-0.1-1-0.2-1.4c0-0.4-0.2-0.8,0.1-1.1c-0.1-0.3-0.1-0.6-0.1-0.8c-0.2-0.3-0.3-0.6-0.1-0.9
        c-0.2-0.2-0.3-0.6-0.2-0.9c-0.3-0.5,0-1.1-0.2-1.6c0.1-0.2,0.1-0.4,0.2-0.5c-0.3-0.4-0.1-1,0.1-1.5c-0.1-0.1-0.2-0.2-0.3-0.2
        c0.1-0.2,0-0.4-0.1-0.6c0.1-0.8-0.1-1.6,0.1-2.4c0-0.3,0-0.7-0.1-1c0.1-0.6-0.1-1.2,0.1-1.7c-0.2-0.6,0.1-1.3-0.2-1.9
        c-0.2-0.1-0.4-0.3-0.7-0.4c0.2-0.3,0.5-0.3,0.6,0c0.4-0.3,0.2-0.8,0.2-1.2c0.2-1.5,0-3,0.2-4.5c0-0.6,0-1.3,0.2-1.9
        c-0.2-0.4,0.1-0.8-0.1-1.3c0.2-0.6,0.2-1.2,0.1-1.8c0.2-0.9,0.2-1.8,0.4-2.7c-0.1-0.6,0.2-1.1,0.1-1.7c-0.1-0.6,0.4-1.1,0.2-1.7
        c0.2-0.5,0.2-1,0.2-1.6c0.1-0.2,0.2-0.4,0.3-0.6c0-0.4-0.2-0.9,0.2-1.1c0-0.1-0.1-0.2-0.2-0.3c0.3-0.7,0.3-1.5,0.5-2.3
        C231.7,250.1,231.8,249.3,232.1,248.6 M232.4,255.1c0.2-0.2,0-0.5-0.1-0.7C232,254.5,232.5,254.9,232.4,255.1 M233.4,268.5
        c0.2,0.1,0.4,0.7,0.7,0.4C233.9,268.6,233.6,268.5,233.4,268.5 M233.8,269.4c-0.2,0.2,0.2,0.7,0.4,0.4
        C234,269.7,234,269.4,233.8,269.4 M231.5,271.8c0.2,0.2,0.4,0.3,0.7,0.3c0.2-0.1-0.1-0.2-0.1-0.3
        C231.9,271.8,231.7,271.8,231.5,271.8 M231.6,272.1c0.1,0.2,0.2,0.4,0.3,0.7c0-0.6,0.3-0.1,0.5,0c-0.1,0-0.2,0-0.2,0l0,0.1
        c-0.2,0.2-0.3,0.4,0,0.5c-0.1,0.1-0.2,0.1-0.3,0.2c0.2,0,0.5,0.1,0.4,0.4c0.3,0.1,0.5,0.6,0.1,0.7c0.1,0.1,0.2,0.3,0.4,0.1
        c0.1,0,0.2,0.1,0.3,0.1c-0.1,0-0.2,0-0.3,0l-0.1,0.2c0.5,0.4,1,0.8,1.6,0.9c0.1,0.1,0.3,0.2,0.4,0.3c0.4,0.2,0.8,0.3,1.2,0.6
        c0.1,0,0.2,0.1,0.3,0.2c0.2,0,0.4,0.1,0.5,0.3c0.1-0.1,0.1-0.2,0.2-0.3l-0.1,0.3c0.4-0.2,0.5,0.4,0.9,0.3c0.2,0.1,0.5,0.2,0.6,0.4
        l0.3,0c-0.2-0.3-0.4-0.6-0.5-0.9c-0.4,0-0.7-0.5-0.8-0.8c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.2-0.1-0.4-0.1-0.6c-0.6-0.4-1.3-0.8-1.5-1.5
        c-0.5-0.3-1-0.6-1.5-1c-0.1-0.2-0.2-0.4-0.4-0.6c-0.2-0.2-0.5-0.2-0.7-0.2c0-0.1-0.1-0.4-0.1-0.5c-0.1,0-0.2,0.1-0.3,0.1l-0.1,0.1
        c-0.2,0-0.4-0.1-0.5-0.2l0.1,0.3C231.8,272.2,231.7,272.2,231.6,272.1 M232,272.8C232,272.8,232,272.8,232,272.8 M232.1,274.2
        C231.8,274.6,232.5,274.2,232.1,274.2 M233.8,278c-0.1,0.2,0.2,0.3,0.3,0.2C234.2,278.1,234,277.9,233.8,278 M231.1,278.7
        c-0.1,0.2,0,0.3,0.2,0.2C231.4,278.7,231.3,278.7,231.1,278.7 M230.7,279.6c-0.2,0.1-0.2,0.2,0,0.3
        C230.9,279.8,230.9,279.7,230.7,279.6 M231.1,281.7C231.2,281.8,231.2,281.8,231.1,281.7 M231.6,289.4l-0.1,0.1
        C231.6,289.9,232,289.3,231.6,289.4 M287.6,249.3C288.1,249.4,287.4,249.7,287.6,249.3 M287.6,250.1c-0.3,0.1-0.5-0.7-0.1-0.4
        C287.6,249.8,287.6,249.9,287.6,250.1 M215,250.2c-0.2-0.1-0.2-0.3,0-0.4C215.2,249.9,215.2,250,215,250.2 M198.5,250.6
        C198.6,250.6,198.6,250.6,198.5,250.6 M316.2,250.6C316.2,250.6,316.2,250.6,316.2,250.6 M121.1,250.9c-0.2-0.4,0.5-0.3,0.4,0
        c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0-0.3,0-0.3,0C120.8,251.3,121,251.1,121.1,250.9 M121.7,251L121.7,251l0.2,0l0,0.1l-0.1,0
        C121.7,251.1,121.7,251,121.7,251 M125.9,251c0.2-0.1,0.4,0.1,0.4,0.3C126.1,251.6,125.8,251.1,125.9,251 M199,251.2
        c0.3-0.1,0.3,0,0.3,0.3C199.1,251.5,199,251.4,199,251.2 M315.6,251.5c-0.3,0-0.1-0.5,0.1-0.3C315.7,251.3,315.6,251.4,315.6,251.5
        M121.3,251.2c0.2,0,0.3,0,0.2,0.3C121.4,251.5,121.3,251.4,121.3,251.2 M283.3,251.2C283.4,251.4,283.4,251.4,283.3,251.2
        M199.3,251.6c0.1-0.2,0.3,0,0.3,0.1c0,0,0.1,0.1,0.1,0.1C199.6,252.2,199.1,251.8,199.3,251.6 M315.1,251.7c0-0.1,0.1-0.2,0.1-0.2
        c0.5,0.1-0.1,0.8-0.2,0.4C315.1,251.8,315.1,251.7,315.1,251.7 M345.1,251.7C345.2,251.8,345.2,251.8,345.1,251.7 M186,251.8
        C186.4,251.6,186.1,252.3,186,251.8 M283.1,251.8C283.6,251.9,282.9,252.3,283.1,251.8 M228.7,252.3
        C229.1,252,228.7,252.7,228.7,252.3 M283.5,252.3L283.5,252.3L283.5,252.3L283.5,252.3L283.5,252.3 M311.3,252.5
        c0.2-0.1,0.4,0.2,0.2,0.3C311.4,252.8,311.2,252.6,311.3,252.5 M311.6,252.9c0.2-0.1,0.4,0.5,0.1,0.5
        C311.5,253.3,311.6,253.1,311.6,252.9 M344.5,253c0.2,0.1,0.2,0.2,0,0.3C344.3,253.2,344.3,253.1,344.5,253 M201.2,253.3
        C201.3,253.4,201.3,253.4,201.2,253.3 M311.8,253.9c-0.2-0.4,0.4-0.3,0.4,0C312.1,253.9,311.9,253.9,311.8,253.9 M346.7,254.3
        c0-0.3,0.3-0.5,0.4-0.8c0,0.1,0.1,0.3,0.1,0.4c-0.1,0-0.2,0-0.3,0c0,0.1,0.1,0.2,0.1,0.3C347,254.3,346.7,254.5,346.7,254.3
        M343.5,254c0.2,0,0.3,0.3,0.4,0.5c-0.2,0.2-0.4,0.5-0.7,0.4c0-0.4,0.3-0.3,0.5-0.5C343.7,254.3,343.6,254.1,343.5,254 M183.3,255
        c0.2-0.1,0.3,0,0.2,0.2C183.4,255.2,183.3,255.2,183.3,255 M203.1,255c0.2,0,0.3,0.1,0.2,0.3C203.1,255.3,203,255.2,203.1,255
        M203.5,255.4C203.6,255.5,203.6,255.5,203.5,255.4 M283.1,256.3c-0.2-0.1-0.2-0.2,0-0.3C283.3,256.1,283.3,256.2,283.1,256.3
        M79.7,256.6c-0.3,0.2-0.2-0.4,0-0.3C79.7,256.4,79.7,256.6,79.7,256.6 M137.5,257c0.2-0.1,0.3,0,0.2,0.2
        C137.5,257.3,137.4,257.2,137.5,257 M283.1,257.4c-0.2-0.1-0.2-0.2,0-0.4C283.3,257.2,283.3,257.3,283.1,257.4 M138.2,257.3
        C138.3,257.3,138.3,257.3,138.2,257.3 M110.6,257.4C111.1,257.5,110.4,257.8,110.6,257.4 M138.7,257.6
        C138.8,257.6,138.8,257.6,138.7,257.6 M110.3,257.7C110.3,257.8,110.3,257.8,110.3,257.7 M233,258.1c-0.5,0.2-0.2-0.8,0.1-0.4
        C233,257.8,233,258,233,258.1 M186.7,257.8C186.8,257.9,186.8,257.9,186.7,257.8 M348.7,257.9C348.8,258,348.8,258,348.7,257.9
        M148.9,258.2C149.4,258,148.9,258.7,148.9,258.2 M84.7,258.6C84.8,258.7,84.8,258.7,84.7,258.6 M430.5,258.6
        C430.6,258.7,430.6,258.7,430.5,258.6 M185,259.1c-0.2,0-0.3-0.4-0.1-0.4C185.1,258.7,185.2,259.1,185,259.1 M207.9,259.3
        C208,259.4,208,259.4,207.9,259.3 M340.2,259.3C340.3,259.3,340.3,259.3,340.2,259.3 M143.6,259.4c0.2,0,0.3,0,0.2,0.2
        C143.7,259.6,143.6,259.6,143.6,259.4 M228.7,259.4c0.2,0,0.2,0.5,0,0.6C228.4,260,228.5,259.6,228.7,259.4 M215.5,259.9
        c-0.1-0.1,0-0.5,0.2-0.4C215.7,259.7,215.6,260,215.5,259.9 M194.3,259.7C194.4,259.8,194.4,259.8,194.3,259.7 M106.7,260.5
        c0.1-0.1,0.2-0.5,0.4-0.2c0,0.1,0,0.2,0,0.3C107.1,260.7,106.5,260.7,106.7,260.5 M215.6,260.1C215.7,260.2,215.7,260.2,215.6,260.1
        M155.9,260.4c0.2-0.1,0.3,0,0.2,0.2C155.9,260.7,155.8,260.6,155.9,260.4 M228.5,261.4c-0.1-0.2,0.1-0.5,0.3-0.5
        C228.8,261,228.7,261.5,228.5,261.4 M411.8,261.1L411.8,261.1L411.8,261.1L411.8,261.1L411.8,261.1 M187.8,262
        c0.2-0.1,0.3,0,0.2,0.2C187.9,262.3,187.8,262.2,187.8,262 M152.8,262.1c0.2-0.1,0.6,0.1,0.4,0.3C153,262.5,152.7,262.3,152.8,262.1
        M106.7,262.2c0.1,0.2,0.1,0.3,0.1,0.5c0.1,0,0.3,0.1,0.3,0.1l-0.1,0.2c-0.4-0.1-0.8-0.2-1-0.6c0.3,0,0.5,0,0.6,0.3
        C106.7,262.6,106.7,262.3,106.7,262.2 M408.1,262.8c0.3-0.1,0.3-0.4,0.4-0.6c0,0.1,0,0.4,0,0.5c0.2-0.2,0.4-0.3,0.6-0.3
        c-0.2,0.3-0.6,0.5-1,0.6L408.1,262.8 M283.1,262.5c0.2-0.1,0.3,0,0.2,0.3C283.1,262.8,283,262.7,283.1,262.5 M140.8,262.7
        c0.1-0.2,0.5,0,0.4,0.2C141,263.1,140.7,262.9,140.8,262.7 M163.8,262.8C164.3,262.9,163.5,263.3,163.8,262.8 M283.1,263.1
        C283.6,263.1,282.9,263.5,283.1,263.1 M202.5,263.2L202.5,263.2L202.5,263.2L202.5,263.2L202.5,263.2 M144.1,263.5
        C144.2,263.6,144.2,263.6,144.1,263.5 M283,263.6c0.1-0.2,0.2-0.2,0.3,0C283.2,263.8,283.1,263.8,283,263.6 M75.4,263.7
        c0.1,0,0.3,0,0.4,0.1c-0.1,0.2-0.5,0.3-0.5,0C75.3,263.8,75.3,263.7,75.4,263.7 M164.6,263.8C165,263.6,164.6,264.3,164.6,263.8
        M166,264.2c0,0.3-0.5-0.1-0.2-0.2C165.8,264.1,165.9,264.1,166,264.2 M202.1,264.3C202.2,264.4,202.2,264.4,202.1,264.3
        M440.8,264.4C440.9,264.5,440.9,264.5,440.8,264.4 M146.2,264.5C146.3,264.6,146.3,264.6,146.2,264.5 M202.3,264.5
        c0.3-0.3,0.2,0.4-0.1,0.2C202.2,264.7,202.3,264.6,202.3,264.5 M161.7,265c0.1-0.2,0.5,0.1,0.4,0.2
        C161.9,265.4,161.6,265.2,161.7,265 M228.6,265.2c0.2,0,0.3,0,0.2,0.2C228.7,265.5,228.6,265.4,228.6,265.2 M283.3,265.2
        C283.4,265.3,283.4,265.3,283.3,265.2 M111.3,265.8c0-0.3,0.4,0,0.3,0.2C111.5,265.9,111.4,265.8,111.3,265.8 M403.8,266
        c-0.3-0.1,0.2-0.5,0.2-0.2C403.9,265.8,403.9,265.9,403.8,266 M197.8,265.9c0.1,0,0.2-0.1,0.3-0.1c0.1,0.3,0.4,0.5,0.4,0.9
        c-0.1,0.1-0.3,0.3-0.4,0.4c-0.1-0.3-0.1-0.5,0.1-0.7C198,266.3,197.9,266.1,197.8,265.9 M445.2,265.8
        C445.3,265.9,445.3,265.9,445.2,265.8 M151.6,265.9c0.2,0.1,0.2,0.2,0,0.3C151.4,266.2,151.4,266,151.6,265.9 M190.7,266.2
        C190.8,266.3,190.8,266.3,190.7,266.2 M283,266.2c0.2-0.1,0.3,0,0.2,0.2C283,266.5,282.9,266.4,283,266.2 M121,266.5
        C121.1,266.6,121.1,266.6,121,266.5 M125.7,266.5C126.2,266.3,125.8,267,125.7,266.5 M119.5,266.7
        C119.6,266.8,119.6,266.8,119.5,266.7 M154.1,267.1c0-0.2,0.4-0.2,0.5-0.1C154.7,267.1,154.2,267.3,154.1,267.1 M448.4,266.9
        c0.1,0,0.2,0,0.2,0.1l-0.1,0.1c0.1,0.2,0.4,0.3,0.7,0.3c0,0.1,0.1,0.3,0.1,0.4c-0.1-0.1-0.4-0.1-0.4-0.3c-0.1,0-0.3,0.1-0.4,0.1
        C448.4,267.3,448.4,267.1,448.4,266.9 M166.1,267.1C166.5,266.9,166.2,267.6,166.1,267.1 M66.4,267.3c0.2-0.1,0.4-0.2,0.6-0.3
        C66.9,267.4,66.7,267.6,66.4,267.3c0,0.3-0.2,0.3-0.4,0.3C66.1,267.5,66.2,267.3,66.4,267.3 M191.9,267.6
        C191.9,267.7,191.9,267.7,191.9,267.6 M449.5,268c0.1-0.1,0.3-0.2,0.4-0.3c0.1,0.1,0.3,0.3,0.4,0.4C450,268.2,449.8,267.7,449.5,268
        M157.4,268.1C157.9,267.9,157.4,268.5,157.4,268.1 M159.1,268.5C159.2,268.6,159.2,268.6,159.1,268.5 M171.1,269.1
        C171.2,269.2,171.2,269.2,171.1,269.1 M453.7,269.6c0.1-0.1,0.4,0,0.4,0.2C454.1,270,453.6,269.8,453.7,269.6 M175.2,269.8
        L175.2,269.8c0.2,0.1,0.3,0.2,0.2,0.4C175.2,270.3,175.1,269.9,175.2,269.8 M162.9,269.9c0.2-0.1,0.3,0,0.2,0.3
        C162.9,270.3,162.8,270.2,162.9,269.9 M300.2,270.1c0.2-0.1,0.3,0,0.2,0.2C300.2,270.3,300.1,270.3,300.2,270.1 M152.3,270.2
        c0.1-0.1,0.4,0,0.3,0.2C152.5,270.5,152.3,270.3,152.3,270.2 M456.9,270.3c0.1-0.2,0.5,0.1,0.5,0.2
        C457.2,270.6,456.8,270.6,456.9,270.3 M153.2,270.5C153.2,270.6,153.2,270.6,153.2,270.5 M300.1,270.4c0.2-0.1,0.3,0,0.2,0.2
        C300.1,270.7,300,270.6,300.1,270.4 M200.2,270.8c0.2,0,0.4,0.2,0.3,0.4C200.4,271.3,200.1,271,200.2,270.8 M138.9,271.2
        c0.2,0,0.3,0.2,0.2,0.4C138.9,271.7,138.8,271.4,138.9,271.2 M201.2,271.2C201.7,271.2,201.1,271.7,201.2,271.2 M228.5,271.3
        L228.5,271.3L228.5,271.3L228.5,271.3L228.5,271.3 M228.6,271.8c0-0.1,0.1-0.2,0.1-0.3c0.2,0,0.3,0.1,0.2,0.3
        C228.8,271.8,228.6,271.8,228.6,271.8 M283.4,271.8c0.2-0.1,0.3,0,0.2,0.2C283.4,272.1,283.3,272,283.4,271.8 M177.5,272.1
        C177.6,272.1,177.6,272.1,177.5,272.1 M403.8,272.5c0.1-0.1,0.5,0,0.4,0.2C404.1,272.9,403.6,272.7,403.8,272.5 M401.7,272.6
        c0.2-0.1,0.3,0,0.2,0.2C401.7,272.9,401.6,272.8,401.7,272.6 M350.1,272.8C350.2,272.9,350.2,272.9,350.1,272.8 M409.1,272.8
        C409.2,272.9,409.2,272.9,409.1,272.8 M179.6,273.3C179.7,273.4,179.7,273.4,179.6,273.3 M196,274.8c0.2-0.1,0.4,0.4,0.2,0.5
        C196,275.2,195.9,275,196,274.8 M204.9,274.8C205,274.8,205,274.8,204.9,274.8 M346.6,275.1C347,274.9,346.6,275.6,346.6,275.1
        M181.5,275.4c0.2-0.1,0.3,0,0.3,0.2C181.5,275.7,181.4,275.6,181.5,275.4 M432,276.2c0.2,0.1,0.2,0.2,0,0.3
        C431.8,276.4,431.8,276.3,432,276.2 M433.2,276.5c0.2-0.1,0.3,0,0.2,0.2C433.1,276.8,433.1,276.7,433.2,276.5 M355.4,276.6
        C355.8,276.3,355.4,277.1,355.4,276.6 M283.8,277.6c-0.3-0.2-0.2-0.7-0.1-0.9c-0.1,0.4,0.2,0.4,0.4,0.7c-0.1,0-0.3-0.1-0.4-0.2
        C283.8,277.3,283.8,277.5,283.8,277.6 M346.9,277.1c0.2-0.1,0.5-0.1,0.7-0.2c0,0.1-0.1,0.2-0.1,0.3c-0.1,0-0.2,0-0.3,0.1
        c-0.3,0.4-0.8,0.7-1.3,0.8l0.3,0c-0.4,0.2-0.5,0.8-1,0.8c0,0.4-0.3,0.5-0.4,0.9c-0.2,0.3-0.2,0.8-0.5,1.1c0,0.1,0.1,0.2,0.2,0.3
        c0,0-0.2,0.1-0.2,0.2c0.1,0.1,0.3,0.3,0.4,0.4c-0.1,0-0.4,0-0.5,0c0.2,0.3,0.2,0.7,0.2,1c-0.1,0-0.2,0-0.3,0c0.1,0.4-0.1,0.7,0,1.1
        c0.1,0.2-0.1,0.3-0.1,0.4c0-0.1-0.1-0.2-0.1-0.3c-0.2,0-0.2,0.4-0.3,0.6c0.3,0.3-0.1,0.5-0.3,0.7c-0.4,0.1-0.6,0.5-1,0.5
        c-0.6,0.1-0.6-0.9-1.1-0.6c-0.2-0.4-0.5-0.6-0.7-1c0.1,0,0.3,0,0.4,0c-0.1-0.1-0.2-0.2-0.3-0.3l0.1,0.2c-0.6-0.1-0.9,0.6-1.2,1
        c-0.4,0.6-1,1-1.2,1.6l0.1,0c0-0.5,0.6-0.1,0.5,0.2l0.1-0.1c-0.1,0.3,0.2,0.4,0.4,0.6c-0.2,0.2-0.4,0.3-0.6,0.5
        c0.1,0.1,0.3,0.2,0.4,0.3c-0.1,0.2-0.1,0.3-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.3c0,0.1,0,0.4,0,0.6c0.1,0,0.3,0,0.4,0.1
        c-0.5,0.3-0.8,0.9-1.4,0.7c-0.2,0-0.4,0.2-0.6,0c-0.2-0.2-0.2-0.4-0.3-0.5c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1-0.1-0.2-0.3-0.2-0.5l0.2,0
        c0,0,0-0.1,0-0.1c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0.3-0.4,0.2-0.4-0.1c0.2-0.1,0.6-0.1,0.6-0.4c-0.2,0.1-0.4,0.2-0.6,0.3
        c0-0.1,0-0.4,0-0.5c0.1-0.1,0.2-0.2,0.3-0.3c-0.1-0.2-0.1-0.4-0.1-0.5l0.2,0l0-0.3c-0.3,0.1-0.3-0.2-0.4-0.4
        c0.1,0.1,0.3,0.2,0.3,0.2c0.3-0.5,0.2-1.1-0.3-1.3c0.1,0,0.3,0,0.4,0c0-0.3,0-0.5,0-0.8c0.2-0.1,0.4-0.2,0.7-0.3
        c0.2-0.4,0.7-0.5,0.7-1c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0,0.3-0.1,0.3-0.2c-0.4-0.2-0.1-0.5-0.2-0.8c0.1,0,0.2,0,0.3,0
        c0,0.1,0,0.3,0.1,0.4c0.1-0.2,0.3-0.4,0.5-0.6c-0.1,0.1-0.3,0.3-0.4,0.3c-0.1-0.5,0.3-0.7,0.6-1c0,0.2-0.1,0.5,0.2,0.5l0-0.2
        c0.3-0.1,0.2-0.6,0.6-0.7c0-0.1-0.1-0.2-0.1-0.3c0.6,0,0.8-0.7,1.4-0.7c0.1-0.1,0.2-0.3,0.3-0.4c0.2,0,0.5,0,0.4-0.4
        c0,0.1,0.2,0.2,0.2,0.2c0.1-0.3,0.5-0.5,0.8-0.6c0,0.1,0,0.2,0,0.3l0.1,0c-0.1-0.4,0.1-0.6,0.5-0.7c-0.1,0.2-0.2,0.4-0.2,0.6
        c0.3-0.2,0.4-0.6,0.6-0.9c0-0.2,0.1-0.3,0.3-0.4c0,0.1,0,0.3,0,0.4c0.1-0.2,0.1-0.4,0.1-0.6c0.1-0.2,0.3-0.1,0.5-0.2
        c0.2-0.1,0.3-0.4,0.6-0.4c0.2-0.1,0.6-0.2,0.5-0.5c0.1,0,0.3,0,0.3,0c0.2-0.2,0.5-0.4,0.9-0.4C346.9,277.3,346.9,277.2,346.9,277.1
        M341.9,281.2c0,0.1,0.1,0.3,0.1,0.4c0.1-0.1,0.2-0.3,0.3-0.4c-0.1-0.2,0-0.3,0-0.5C342.2,280.9,342.1,281.1,341.9,281.2
        M352.1,276.9C352.2,277,352.2,277,352.1,276.9 M116.5,277C117,276.8,116.5,277.5,116.5,277 M95,277.3
        C95.4,277.1,95.1,277.8,95,277.3 M196.6,277.2l0.3,0c-0.1,0.4,0.2,0.8,0.1,1.3c-0.2-0.1-0.4-0.4-0.2-0.6c-0.3,0-0.6-0.1-0.4-0.4
        c0.1,0,0.2,0,0.2,0L196.6,277.2 M47.2,277.7c-0.1-0.2,0.4-0.5,0.4-0.2C47.5,277.6,47.4,277.7,47.2,277.7 M467.6,277.6
        c0-0.4,0.6-0.2,0.5,0.1C467.9,277.8,467.8,277.6,467.6,277.6 M228.3,277.8C228.3,277.8,228.3,277.8,228.3,277.8 M300.1,278.1
        c-0.2-0.1,0-0.5,0.2-0.3C300.5,277.9,300.3,278.3,300.1,278.1 M197.2,278.2c0.2,0,0.2,0.1,0.1,0.3
        C197.1,278.5,197.1,278.4,197.2,278.2 M197,278.6C197.1,278.7,197.1,278.7,197,278.6 M284,279C284.5,279,283.8,279.5,284,279
        M440.7,279c0.2,0,0.3,0.1,0.2,0.3C440.8,279.3,440.7,279.2,440.7,279 M442.1,279.2c0.2,0,0.3,0,0.5,0c0,0,0,0.1,0,0.1
        c-0.1,0.1-0.2,0.1-0.4,0.2C442.2,279.4,442.1,279.3,442.1,279.2 M443.3,279.9C443.3,280,443.3,280,443.3,279.9 M43.1,281
        c-0.3-0.4,0.2-0.6,0.4-0.8c0,0.1,0,0.3,0.1,0.4c0-0.4,0.4-0.3,0.5,0c-0.3-0.2-0.5,0.3-0.7,0C43.2,280.6,43.1,280.8,43.1,281
        M471.4,280.3c0.2,0,0.5,0.4,0.4-0.1c0.2,0.2,0.4,0.3,0.5,0.5c-0.1,0.1-0.1,0.2-0.2,0.3c0-0.3-0.3-0.4-0.5-0.6l0,0.3l-0.1,0
        c0-0.1,0-0.2,0-0.2c-0.1,0-0.3,0.1-0.4,0.1C471.2,280.5,471.3,280.3,471.4,280.3 M197.7,280.4C197.8,280.5,197.8,280.5,197.7,280.4
        M299.6,280.9c-0.1-0.2,0.2-0.5,0.4-0.4C300.1,280.8,299.8,280.9,299.6,280.9 M178.6,280.7c0.3,0,0.5,0.3,0.6,0.6
        C178.9,281.1,178.4,281.1,178.6,280.7 M316.1,280.9C316.2,281,316.2,281,316.1,280.9 M472.4,280.9
        C472.9,280.8,472.2,281.3,472.4,280.9 M231.5,281.2C232,281.2,231.2,281.6,231.5,281.2 M339.2,281.7
        C339.6,281.5,339.2,282.2,339.2,281.7 M449.6,281.9c0.2-0.1,0.3,0,0.2,0.2C449.6,282.1,449.5,282.1,449.6,281.9 M284,282.4
        c-0.1-0.2-0.1-0.3,0.1-0.4C284.2,282.2,284.2,282.3,284,282.4 M75.7,282.1c0.2-0.1,0.3,0,0.2,0.2C75.7,282.4,75.6,282.4,75.7,282.1
        M475.7,283c0.1-0.1,0.2-0.2,0.2-0.3c0,0.2,0.1,0.5,0.1,0.7c-0.1-0.1-0.2-0.2-0.2-0.4c-0.1,0-0.2,0-0.3,0
        C475.5,283,475.6,283,475.7,283 M38,283.8c0.2-0.1,0.5,0.2,0.2,0.3C37.9,284.3,37.7,283.9,38,283.8 M477.2,283.8
        c0.2,0,0.3,0.5,0,0.4C476.9,284.1,476.9,283.7,477.2,283.8 M299,284.3c-0.3-0.1,0-0.6,0.2-0.4C299.3,284,299.2,284.3,299,284.3
        M344.6,283.9C344.7,283.9,344.7,283.9,344.6,283.9 M231.4,283.8c0.1,0.2,0.2,0.4,0.2,0.5c-0.2,0.2-0.4,0.4-0.6,0.1
        C231.3,284.3,231.4,284.1,231.4,283.8 M20.3,284.4c0.2,0.1,0.5,0.1,0.7,0.1C21.1,284.9,20.3,284.8,20.3,284.4 M298.8,284.4
        c0.2,0,0.3,0.1,0.2,0.3C298.8,284.7,298.8,284.6,298.8,284.4 M327.4,284.7C327.8,284.5,327.3,285.2,327.4,284.7 M353.3,285.3
        c0.2,0.1,0.2,0.2,0,0.3C353.1,285.5,353.1,285.4,353.3,285.3 M356.5,285.4C356.6,285.5,356.6,285.5,356.5,285.4 M462,285.6
        c0.2-0.1,0.3,0,0.2,0.2C462,285.9,461.9,285.8,462,285.6 M33.6,286c0.3,0,0,0.5-0.2,0.5C33.4,286.3,33.5,286.1,33.6,286 M182.3,286
        c0.3-0.1,0.1,0.5,0,0.1L182.3,286 M481.5,286c0.2-0.2,0.4,0.3,0.3,0.5C481.6,286.5,481.4,286.2,481.5,286 M336,287
        c-0.2-0.1-0.2-0.2,0-0.4C336.2,286.8,336.2,286.9,336,287 M466.6,287.3c0.1-0.1,0.4,0,0.3,0.2C466.8,287.6,466.5,287.4,466.6,287.3
        M468.8,287.4c0.2-0.1,0.3,0,0.2,0.2C468.8,287.7,468.7,287.6,468.8,287.4 M493.3,288.3c0.2,0.1,0.2,0.2,0,0.3
        C493.1,288.5,493.1,288.4,493.3,288.3 M166.1,288.7c0-0.2,0.2-0.3,0.4-0.2c0,0.1,0,0.2,0,0.2C166.5,288.8,166.2,288.9,166.1,288.7
        M156.8,288.6c0.2,0,0.5,0.1,0.7,0.1c-0.1,0.2-0.1,0.4,0.1,0.5c-0.2,0.2-0.4,0-0.3-0.2C157,289.2,156.9,288.8,156.8,288.6
        M166.8,288.5c0.2,0.1,0.5,0.4,0.6,0.1c0.1,0.1,0.2,0.1,0.4,0.2c-0.2,0.2-0.5,0.1-0.7,0.2C167,288.8,166.9,288.7,166.8,288.5
        M171.4,288.9c0.2-0.1,0.4-0.3,0.6-0.4c0.1,0.2,0.1,0.5,0.4,0.5l0-0.3l0.3,0.2c0-0.1,0-0.3,0-0.4c0.2,0.3,0.5,0.4,0.8,0.5
        c-0.1,0.1-0.2,0.1-0.2,0.2c-0.3-0.1-0.5-0.4-0.8-0.2c-0.2,0.2-0.5,0-0.6-0.1C171.7,289,171.5,289,171.4,288.9 M41.8,288.7
        L41.8,288.7L41.8,288.7L41.8,288.7L41.8,288.7 M164.3,288.7C164.4,288.8,164.4,288.8,164.3,288.7 M169.3,288.7l0.1,0l0,0.2
        c0.2-0.1,0.3-0.1,0.5-0.2c-0.1,0.1-0.1,0.5-0.4,0.4C169.5,289,169.4,288.8,169.3,288.7 M174.1,288.7
        C174.5,288.5,174.1,289.2,174.1,288.7 M174.6,288.7c0.2-0.1,0.3,0,0.2,0.2C174.6,289,174.6,288.9,174.6,288.7 M177.3,288.6
        c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0-0.2,0-0.3,0c0,0.3-0.2,0.3-0.4,0.3c0.1-0.2,0.2-0.3,0.4-0.3L177.3,288.6 M160.1,289
        c0.1,0.1,0.2,0.1,0.3,0.2c-0.2,0.1-0.3,0.3-0.6,0.3c-0.1-0.1-0.2-0.2-0.3-0.3C159.8,289.2,159.9,289.1,160.1,289 M161.8,289.3
        c-0.1-0.2,0.1-0.4,0.3-0.4C162.3,289,162,289.4,161.8,289.3 M162.4,288.9c0.2,0.1,0.3,0.3,0.5,0.5c-0.2,0-0.5,0-0.7,0.1
        C162.3,289.3,162.4,289.1,162.4,288.9 M164.4,288.9c0.3-0.1,0.4,0.4,0.1,0.4C164.3,289.4,164.2,289,164.4,288.9 M168,289
        c0.2-0.1,0.3,0,0.2,0.2C168,289.3,167.9,289.2,168,289 M168.8,289c0.1-0.2,0.5,0,0.3,0.2C169,289.4,168.7,289.2,168.8,289
        M174.2,289c0.2-0.1,0.3,0,0.2,0.2C174.2,289.3,174.1,289.2,174.2,289 M339,289.3c-0.2-0.2,0.2-0.5,0.4-0.4
        C339.5,289.1,339.2,289.4,339,289.3 M165.4,289.1c0.2-0.1,0.3,0,0.2,0.2C165.4,289.4,165.3,289.3,165.4,289.1 M334,290.1
        c0.5-0.2,0.8-0.6,1.3-0.9c-0.2,0.4-0.5,0.8-0.6,1.3c-0.1,0.4-0.3,0.8-0.4,1.1c0,0.1-0.1,0.3,0,0.5c-0.3,0.4,0.4,0.6,0,1
        c0.2,0.1,0.3,0.1,0.5,0.2c0,0,0.1-0.1,0.2-0.2c0,0.2,0.2,0.3,0.3,0.5c0,0.1,0.1,0.2,0.1,0.4c-0.2,0.2-0.2,0.5-0.3,0.8
        c0.1,0.1,0.1,0.3,0.2,0.4c-0.4,0.5-0.4,1.1-0.7,1.6c-0.3,0.2-0.5,0-0.8-0.1c-0.1,0-0.2,0.1-0.3,0.1c0,0.1,0.1,0.4,0.1,0.5
        c-0.4-0.2-0.6-0.6-1.1-0.5c-0.1-0.4,0.6-0.3,0.5-0.8c-0.3,0.2-0.6,0.5-0.8,0.8c0.3,0,0.3,0.3,0.3,0.5c-0.2-0.2-0.4-0.5-0.6-0.7
        c0.1-0.1,0.2-0.2,0.3-0.3c-0.2-0.1-0.4-0.2-0.6-0.2c0,0.3,0,0.6-0.2,0.9c-0.2,0.2,0,0.3,0.1,0.5c-0.1,0-0.2,0-0.3,0
        c0,0.1-0.2,0.3-0.2,0.5c0.2,0.2,0.4-0.1,0.6-0.1c-0.1,0.5-0.3,1.1,0.2,1.5c0.1-0.1,0.2-0.3,0.3-0.4c0.1,0.1,0.2,0.2,0.4,0.3
        c-0.1,0.1-0.1,0.2-0.2,0.3l0.2,0.1c-0.1,0-0.3,0-0.4,0c0.1-0.1,0.2-0.2,0.3-0.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.5,0.2-0.3,0.9-0.1,1.2
        c0-0.2,0-0.4,0.1-0.5c0,0,0.1,0,0.2,0c-0.2,0.2-0.2,0.3,0,0.3c-0.1,0.1-0.2,0.1-0.2,0.2l0.3,0.2c0,0,0.1-0.1,0.2-0.1
        c0,0,0,0.1,0,0.2c-0.1,0.1-0.3,0.3-0.4,0.4c0.2,0.2,0.1,0.5,0.1,0.8c0.1-0.1,0.2-0.2,0.3-0.3c0,0.1-0.1,0.2-0.2,0.3
        c-0.1,0.2-0.2,0.4-0.3,0.7c0-0.1-0.1-0.2-0.2-0.3c0,0.3,0,0.5-0.1,0.8c-0.2,0.2-0.4,0.4-0.7,0.6c-0.3,0-0.6,0-0.8,0.1
        c-0.4,0.1-0.8,0-1,0.4c0-0.1-0.1-0.3-0.1-0.4c0,0-0.1,0.1-0.2,0.1c0.1,0.2,0.1,0.4,0.2,0.6c0.1-0.1,0.3-0.2,0.4-0.2
        c-0.2,0.2-0.3,0.3-0.4,0.5l-0.2-0.1c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.3,0.2,0.3l-0.2,0.1c0,0.1,0,0.2,0,0.3c0,0-0.1,0-0.2,0l0,0
        c0.5,0,0,0.4-0.1,0.5l0.2-0.1c-0.1,0.4-0.4,0.9-0.1,1.2l-0.3-0.1c0,0,0,0.1,0,0.1c0.1,0,0.3,0.1,0.4,0.1l0-0.2l0.1,0
        c0,0.1,0,0.2,0,0.2c-0.2,0.1-0.4,0.3-0.7,0.3c0,0,0.1,0,0.2,0.1c0,0.1,0,0.2,0,0.3c0.2-0.1,0.3-0.2,0.3-0.4c0.3,0.2,0,0.6-0.3,0.6
        c-0.1,0.1-0.1,0.2-0.1,0.4c0.1,0,0.3,0,0.4,0.1c0-0.1,0.1-0.3,0.1-0.3l0,0.4l0.2-0.1c-0.1,0.2-0.2,0.3-0.3,0.5
        c0-0.1-0.1-0.3-0.1-0.4c-0.1,0.3-0.5,0.9,0,1c0-0.1-0.1-0.3-0.1-0.4c0.2,0.1,0.4,0,0.5-0.1c-0.1,0.3-0.4,0.5-0.5,0.8
        c0.1,0,0.2,0,0.3,0.1c0,0.3-0.1,0.5-0.2,0.8c-0.1-0.1-0.3-0.2-0.4-0.2c0.1,0.2,0.1,0.4,0.2,0.6c0.3,0.2,0.2,0.6,0.1,0.9
        c0.4,0.2,0.2,0.5-0.1,0.7c0.1,0.1,0.2,0.2,0.3,0.3c0-0.1-0.1-0.2-0.1-0.3c0.1-0.2,0.3-0.3,0.5-0.3c-0.1,0.2-0.3,0.3-0.4,0.4
        c0.1,0.1,0.2,0.3,0.3,0.4c-0.1,0.2-0.1,0.4,0,0.6c-0.1,0-0.2,0.1-0.2,0.1c0.1,0.1,0.2,0.3,0.2,0.4l-0.4-0.2c0.3,0.1,0.1,1,0.6,0.7
        c0,0.1,0.1,0.2,0.1,0.3c-0.1,0-0.4,0-0.5-0.1c0.1,0.1,0.2,0.2,0.3,0.3c-0.3,0.2-0.2,0.5-0.1,0.8c0.1-0.3,0.3-0.5,0.5-0.7
        c0.2,0-0.1,0.3,0,0.4c0.1,0,0.2,0.1,0.3,0.2c-0.2,0.1-0.4,0.2-0.4,0.4c-0.1,0-0.2-0.1-0.3-0.2c0.2,0.3,0.4,0.6,0.4,0.9
        c0.2,0.1,0.4,0.2,0.6,0.1c-0.1,0.4,0.3,0.8,0.3,1.2c0.1,0,0.2,0.1,0.2,0.1c0,0.1,0,0.3,0,0.4c0.1,0.1,0.3,0.3,0.5,0.4l-0.1,0.1
        c0.3,0.1,0.5,0.5,0.8,0.5c0.3,0.5,1.1,0.4,1.3,1c0.1,0,0.3-0.1,0.4-0.1c-0.1,0.4,0.4,0.4,0.6,0.5c0.3,0.1,0.6,0.2,0.9,0.3
        c0.1,0.1,0.2,0.2,0.4,0.3c-0.1-0.1-0.2-0.4-0.2-0.6c0.4,0.2,0.8,0.3,1.2,0.5c0.4-0.3,1-0.1,1.4,0.1c-0.1-0.1-0.3-0.3-0.4-0.4
        c0.3,0.1,0.6,0.1,1,0.1l-0.1-0.2c0.2,0,0.5,0,0.7,0c0.1,0.4-0.4,0.3-0.7,0.3c0.3,0.3,0.7-0.1,1-0.2c0.4,0.3,0.8-0.1,1.1-0.3
        c0,0.1,0,0.2,0,0.3c0.2-0.1,0.5-0.2,0.7-0.2c0,0.1-0.1,0.3-0.1,0.4c0.2,0,0.3,0,0.5-0.1c0,0,0-0.1,0-0.1c-0.1,0-0.3,0-0.4,0.1
        c0-0.1,0.1-0.3,0.1-0.4l0.3-0.2l-0.1,0.3c0.3,0,0.7,0,0.7-0.4c0.2-0.1,0.5-0.2,0.7,0c0.2,0,0.4,0.1,0.6,0.1c0-0.1,0-0.3,0-0.4
        c0.4,0.1,0.7,0,0.9-0.3c0.1,0.1,0,0.3-0.1,0.5c0.2-0.1,0.4-0.3,0.6-0.4c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.3-0.2,0.5-0.3
        c-0.1,0.2-0.2,0.4-0.3,0.6c0.4-0.3,0.9-0.3,1.3-0.6c-0.1,0.2-0.2,0.4-0.3,0.6c0.2-0.2,0.3-0.4,0.5-0.6c0,0.1,0,0.4,0,0.5
        c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.1,0.3-0.1,0.5-0.2c0.1,0,0.3,0,0.3,0c-0.1-0.2,0-0.4,0.3-0.4c0.2-0.2,0.3-0.5,0.6-0.4
        c-0.1,0.2-0.3,0.4-0.5,0.5c0.4,0.2,0.5-0.2,0.6-0.4c0.2,0.1,0.4,0.2,0.6,0.3c-0.1-0.2,0-0.4,0.1-0.6c0,0.1,0,0.3,0,0.4l0.2,0
        c0-0.1,0-0.2-0.1-0.3c0.1,0.1,0.3,0.3,0.3,0.3c0-0.1,0-0.3,0.1-0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.1-0.1,0.4-0.3,0.5-0.4
        c0,0.2,0,0.4,0,0.6c0.1-0.2,0.1-0.4,0.2-0.5c0.1,0,0.3,0.1,0.4,0.1c0.2-0.3,0.5-0.3,0.8-0.4c0,0.1,0.1,0.2,0.2,0.3
        c0-0.1,0-0.3,0-0.4c0.2,0,0.5-0.1,0.7-0.1c0,0.1,0,0.3,0,0.4c0.1,0,0.2-0.1,0.2-0.1c-0.1-0.3-0.3-0.5-0.4-0.7c0.4,0,0.5,0.4,0.9,0.4
        c0.1-0.2,0.2-0.4,0.3-0.6l0.2,0.1c-0.1,0.1-0.1,0.2-0.2,0.3c0.4,0.1,0.4-0.2,0.6-0.4l-0.2,0c0.1,0,0.2,0,0.2,0c0,0.1,0,0.3,0,0.5
        c0.3-0.3,0.4-0.3,0.6,0.1c0.1,0,0.2,0,0.3,0c0.1-0.2,0.3-0.3,0.4-0.5l0.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0,0.2,0.1,0.2,0.1
        c0.2-0.3,0.7-0.1,0.7-0.6c0.2,0,0.4,0.1,0.5,0.2c0.1,0,0.2,0,0.3,0c0-0.1,0-0.2,0-0.3l0.3-0.2c0,0.1,0,0.3,0,0.4l0.2,0l-0.1-0.4
        c0.1,0.1,0.2,0.3,0.2,0.4c0.2-0.2,0.3-0.3,0.4-0.5c0.2,0.2,0.4,0.4,0.7,0.3c0.1-0.4,0.4,0,0.5,0.1c0.1,0,0.3,0.1,0.4,0.1
        c0.2-0.1,0.4-0.2,0.6-0.3l0,0.3c-0.1,0-0.2,0.1-0.2,0.1c0.3-0.1,0.5-0.2,0.8-0.3c-0.3,0.6,0.3,0,0.5,0.1c0.1,0.1,0.2,0.2,0.3,0.2
        c0.1-0.1,0.2-0.2,0.4-0.3c-0.1,0.2-0.1,0.3-0.2,0.5c0.2-0.2,0.5-0.2,0.7-0.1c0.1,0,0.3,0,0.4,0l0,0.1c0.3,0,0.7,0.1,1,0.3
        c0.1,0,0.2-0.1,0.3-0.1l-0.1,0.2c0.2,0,0.4,0.1,0.7,0.1c0,0.1,0,0.2,0,0.3l0.2,0c0,0,0.1,0.1,0.1,0.2c0.1,0,0.2,0,0.2-0.1l-0.1,0.1
        c0.4,0,0.3,0.4,0.4,0.6c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.2,0.4,0.3,0.5c0,0-0.1,0.1-0.2,0.1c0.3,0.8-0.1,1.8-0.7,2.4
        c-0.2,0.2-0.5,0.5-0.7,0.7c-0.6,0.3-1.1,0.8-1.7,1.2c-0.1,0.1-0.3,0.1-0.5,0.1c0.5-0.2,1-0.6,1.5-1c0.3-0.3,0.8-0.4,0.8-0.9
        c0.3,0,0.2-0.3,0.3-0.5l0.1,0l0-0.2l0.2,0c0-0.1-0.1-0.3-0.1-0.4l0.2,0c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.2,0,0.2,0
        c-0.1-0.5,0.1-1.1-0.2-1.5c-0.2-0.3-0.4-0.5-0.6-0.7c-0.5-0.2-1-0.3-1.4-0.6c-1-0.1-2-0.2-3-0.2c-0.6,0-1.1,0.3-1.7,0.2
        c-0.1,0-0.2,0.1-0.3,0.2c-0.6-0.1-1.2,0.2-1.9,0.2c-0.2,0-0.4,0.1-0.6,0.2c-0.6-0.1-1.2,0.2-1.8,0.1c-0.3,0.1-0.6,0.1-0.9,0.2
        c-0.3,0.1-0.5,0-0.8,0c-0.2,0.2-0.5,0.4-0.8,0.2c-1,0.4-2,0.6-3,0.8c-0.3,0.1-0.6,0-0.8,0.2c-1.2,0.5-2.5,0.7-3.8,0.9
        c-0.4,0.3-0.9,0.3-1.4,0.4c-0.4,0.1-0.9,0.1-1.3,0.3c-0.4,0-0.7,0.1-1.1,0c-0.3,0.3-0.8,0.2-1.2,0.4c-0.4,0-0.7,0-1.1,0.1
        c0,0.1,0,0.2,0.1,0.3c-0.1-0.1-0.1-0.2-0.2-0.4c-0.2,0.2-0.5,0.2-0.8,0.2l-0.1-0.3c-0.1,0.1-0.1,0.2-0.1,0.4c-0.5,0-1,0.1-1.4,0
        c-0.2-0.1-0.5,0.1-0.7-0.1c-0.2,0.2-0.5,0.1-0.7,0.2l0-0.2c-0.1,0-0.3-0.1-0.4-0.1c-0.3,0.1-0.5,0.1-0.8,0.3c0.3-0.5-0.4-0.4-0.2,0
        c-0.2-0.1-0.4-0.2-0.6-0.1l0.1-0.1c-0.3-0.3-0.9-0.1-0.9-0.6c-0.1,0-0.2,0-0.3,0l0-0.2c-0.2,0.1-0.5,0.2-0.6,0
        c0.1,0.2,0.2,0.3,0.3,0.5c-0.3-0.2-0.7-0.1-1,0.1c-0.2-0.1-0.5-0.4-0.1-0.5c0,0.5,0.4,0.3,0.6,0c-0.4,0-0.5-0.3-0.7-0.5
        c0,0.1-0.1,0.3-0.1,0.3c-0.1,0-0.3,0.1-0.5,0.1v-0.2c0.1,0,0.2,0,0.3,0c-0.1-0.2-0.2-0.4-0.2-0.7l0,0c0,0.1,0,0.2,0,0.3
        c-0.1,0-0.3-0.1-0.4-0.1c0-0.2,0-0.4-0.2-0.5c0,0.1,0,0.4-0.1,0.5c-0.1-0.2-0.3-0.4-0.6-0.5c0.1-0.1,0.1-0.3,0.2-0.4l-0.3,0.1
        c0-0.2,0.1-0.4,0.2-0.6c0,0-0.1,0-0.1,0c-0.1,0.1-0.2,0.2-0.2,0.3c0-0.3,0-0.5-0.2-0.7c0,0.1,0,0.4,0.1,0.5c-0.5-0.3-0.4-1-0.8-1.4
        c-0.5-0.4-0.5-1.1-0.7-1.7c0,0.1,0,0.2,0,0.3l-0.1,0l0-0.2c-0.2,0-0.3,0-0.5-0.1l0.3,0c0-0.2-0.1-0.5-0.1-0.6
        c-0.1-0.1-0.5-0.3-0.1-0.4c0-0.1-0.1-0.3-0.1-0.4c-0.2-0.1-0.4-0.2-0.5-0.4c0.1,0,0.3-0.1,0.4-0.1c-0.2-0.1-0.4-0.2-0.4-0.4
        c0.2,0,0.3,0.1,0.5,0.2c-0.1-0.1-0.1-0.3-0.2-0.4c0.1-0.5-0.2-0.9-0.4-1.3c0-0.1,0.1-0.2,0.1-0.2c0.1,0.1,0.2,0.2,0.3,0.2
        c0.1-0.3-0.4-0.4-0.5-0.6c0-0.3,0-0.5,0.2-0.7c-0.5-0.3-0.1-1-0.2-1.4c0.2-0.1,0.2-0.1,0-0.2c-0.1-0.4,0-0.8,0.2-1.2
        c-0.3,0.1-0.4-0.3-0.5-0.5c0.1,0.1,0.3,0.2,0.4,0.3c0-0.2,0.1-0.5,0.1-0.6c-0.2,0.1-0.3,0.2-0.5,0.2c0.2-0.2,0.5-0.4,0.5-0.7
        c-0.1-0.7,0.4-1.2,0.5-1.8c0.3-0.5,0.5-1.1,0.6-1.6c0.2-0.4,0.5-0.9,0.7-1.3c0,0-0.1-0.1-0.1-0.2c0.1-0.2,0.2-0.4,0.3-0.6
        c0-0.1,0-0.3,0-0.4l0.3,0c-0.1-0.1-0.3-0.2-0.5-0.3c0-0.1,0.1-0.3,0.1-0.4c-0.1-0.1-0.2-0.1-0.3-0.2c0.2,0.1,0.4,0.2,0.6,0.2
        c0.3-0.3,0.3-0.7,0.5-1c-0.1-0.1-0.2-0.3-0.4-0.4c0.1,0,0.3,0,0.4,0c-0.1-0.2-0.3-0.5-0.1-0.7c0.1-0.2,0.4,0,0.6,0.1l-0.2-0.1
        c0-0.1,0.1-0.3,0.2-0.4c0,0-0.1-0.1-0.2-0.1l0,0.2c0,0-0.2,0.1-0.2,0.1c-0.1,0-0.4,0-0.5-0.1c0-0.1,0-0.3,0-0.4c0,0,0.1,0,0.2,0
        c0-0.2,0.1-0.4,0.1-0.6c0.1-0.1,0.3-0.2,0.4-0.3c-0.1-0.3-0.4-0.6-0.8-0.4c0.1-0.3,0.3-0.4,0.6-0.5c-0.1-0.3-0.1-0.7-0.4-0.8
        c0.1-0.2,0.1-0.4,0.3-0.4c0,0.1,0,0.4,0,0.6l0.3,0.1c-0.2-0.4,0.2-0.4,0.4-0.5c0.2,0,0.3-0.1,0.3-0.3c0.2-0.1,0.5-0.2,0.5-0.5
        c-0.1,0-0.3,0-0.3,0l0-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.2-0.2,0.2-0.2c0-0.2,0-0.3,0.1-0.5c0.4,0,0.4-0.2,0.3-0.5
        c0.2-0.2,0.4-0.4,0.6-0.6l-0.2-0.1c0.6-0.5,0.2-1.3,0.4-2c0,0,0.1,0.1,0.1,0.1c-0.1,0.2-0.1,0.3,0.2,0.3c0.1-0.2,0.1-0.5,0.3-0.6
        c0.1,0.3,0.2,0.7-0.2,0.8c-0.1,0.2-0.2,0.4-0.2,0.5c0.1-0.1,0.2-0.1,0.4-0.2c0,0-0.1-0.1-0.1-0.1c0.2-0.2,0.4-0.5,0.6-0.8l-0.2,0
        c0.1-0.4,0.5-0.5,0.7-0.8c0.2,0,0.3,0.1,0.5,0.2c0,0,0-0.1,0-0.1c-0.1,0-0.2,0-0.2,0c0-0.1,0.1-0.3,0.1-0.5c0.1,0,0.2,0,0.3,0l0-0.3
        c0.1,0.1,0.2,0.2,0.3,0.3L334,290.1l0.4,0.1C334.3,290.1,334.1,290.1,334,290.1 M328.2,302.1C328.3,302.2,328.3,302.2,328.2,302.1
        M328.5,310c-0.1,0.2,0,0.3,0.2,0.2C328.8,310,328.7,309.9,328.5,310 M328.8,313.2c-0.1,0.1,0.2,0.4,0.3,0.3
        C329.2,313.3,329,313.1,328.8,313.2 M330.1,317.2c0,0.1,0,0.3,0,0.4c0.3,0,0.3,0.3,0.3,0.5c0.3-0.1,0.3-0.3,0.1-0.5
        C330.3,317.5,330.2,317.3,330.1,317.2 M357.5,318C357.6,318.1,357.6,318.1,357.5,318 M364.5,318.7
        C364.6,318.8,364.6,318.8,364.5,318.7 M342.7,321.1c0,0.1-0.1,0.2-0.2,0.3C342.7,321.5,343,321.1,342.7,321.1 M329.6,321.5
        c0,0,0,0.1,0,0.2l0.1,0l0-0.1C329.8,321.5,329.7,321.5,329.6,321.5 M338.8,321.6C338.8,321.6,338.8,321.6,338.8,321.6 M38.1,289.2
        c0.1,0,0.2,0,0.2,0l0,0.1c-0.1,0-0.2,0-0.2,0L38.1,289.2 M308.9,289.6c0-0.2,0-0.3,0.2-0.3C309.2,289.6,309.1,289.7,308.9,289.6
        M478.7,289.4c0.1,0,0.3,0,0.3,0C479.1,289.7,478.6,289.7,478.7,289.4 M462.6,289.8c0.2-0.1,0.4,0.1,0.4,0.3
        C462.8,290.2,462.5,290,462.6,289.8 M155.9,290.1c0.2,0,0.3,0.1,0.5,0.3c0.1,0.1,0.2,0.2,0.1,0.4
        C156.2,290.6,156.1,290.3,155.9,290.1 M308.3,290.8c0.2-0.1,0.3,0.2,0.1,0.3C308.2,291.3,308,290.9,308.3,290.8 M295.5,291.6
        c0.3-0.3,0.4-0.7,0.9-0.7c-0.1,0.1-0.2,0.2-0.3,0.3c0,0.2,0,0.3-0.1,0.4c0,0-0.1-0.1-0.2-0.2l-0.1,0.2
        C295.7,291.6,295.6,291.6,295.5,291.6 M308,291.6c0-0.2,0-0.3,0.2-0.3C308.3,291.6,308.2,291.7,308,291.6 M295.3,292.1
        C295.7,291.9,295.3,292.6,295.3,292.1 M157.7,293C157.8,293.1,157.8,293.1,157.7,293 M157.9,293.4c0.2-0.1,0.3,0,0.3,0.3
        C157.9,293.7,157.8,293.6,157.9,293.4 M293.4,293.4c0.2-0.1,0.3,0,0.2,0.2C293.3,293.7,293.3,293.6,293.4,293.4 M32.8,293.5
        c0.3-0.3,0.3,0.3,0,0.1L32.8,293.5 M482.3,293.5C482.8,293.3,482.4,294,482.3,293.5 M434.1,295.1
        C434.6,295.1,433.9,295.5,434.1,295.1 M206.1,295.2C206.1,295.3,206.1,295.3,206.1,295.2 M431.9,295.2
        C432.3,295,431.9,295.7,431.9,295.2 M78,295.7c0.3-0.3,0.3,0.3,0,0.1L78,295.7 M82,295.8c0.1-0.2,0.3-0.2,0.4,0
        C82.2,296,82.1,296,82,295.8 M213.3,295.8C213.4,295.9,213.4,295.9,213.3,295.8 M445.8,296.2c0.3,0.1,0.6,0,0.9,0.1
        c0.2,0.3-0.3,0.4-0.4,0.3C446.1,296.5,445.9,296.3,445.8,296.2 M185.9,296.4c0.3-0.2,0.3,0.4,0,0.1L185.9,296.4 M89.3,296.7
        c0.1-0.2,0.5,0,0.3,0.2C89.5,297,89.1,296.9,89.3,296.7 M206.9,298C207.4,298,206.7,298.4,206.9,298 M328.3,298.1
        c0.2,0,0.4-0.1,0.6-0.2c0.3,0.3-0.2,0.6-0.4,0.8c0,0.1,0.1,0.3,0.1,0.4c-0.4,0-0.5-0.5-0.2-0.6C328.2,298.5,327.9,298.2,328.3,298.1
        M331.6,298.5c0.2-0.1,0.3,0,0.2,0.2C331.6,298.9,331.6,298.8,331.6,298.5 M97.2,298.6C97.6,298.4,97.2,299.1,97.2,298.6 M431.1,299
        c0.3,0.1-0.3,0.4-0.4,0.2C430.6,298.9,430.9,299,431.1,299 M57.1,299.1C57.5,298.8,57.1,299.6,57.1,299.1 M81.2,299.1
        C81.7,298.9,81.2,299.6,81.2,299.1 M70.7,299.4c0-0.3,0.6-0.2,0.5,0.1C71.1,299.5,70.7,299.6,70.7,299.4 M89.7,300
        c0.1,0.1,0.1,0.1,0.2,0.2c-0.2,0.1-0.4,0.2-0.6,0.2C89.3,300.3,89.5,300.1,89.7,300 M161.3,300.1c0.2-0.1,0.3,0,0.3,0.2
        C161.4,300.3,161.3,300.3,161.3,300.1 M102.6,300.3C103,300.1,102.6,300.8,102.6,300.3 M332.3,300.9
        C332.4,301,332.4,301,332.3,300.9 M200.8,301.1c0.2-0.1,0.4,0.2,0.4,0.4C201,301.5,200.6,301.3,200.8,301.1 M459.8,301.2
        c0.2-0.1,0.6,0,0.6,0.3C460.2,301.5,459.9,301.3,459.8,301.2 M213.3,301.4c0.2-0.1,0.3,0,0.2,0.3
        C213.3,301.7,213.3,301.6,213.3,301.4 M216.8,301.4c0.2-0.1,0.3,0,0.2,0.2C216.8,301.7,216.7,301.6,216.8,301.4 M423.7,301.4
        c0.1,0.1,0.2,0.2,0.2,0.3c0.2,0,0.4,0.1,0.5,0.1c0,0.3-0.4,0.2-0.5,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.3,0.1
        C423.4,301.7,423.5,301.6,423.7,301.4 M217.2,301.8C217.2,301.9,217.2,301.9,217.2,301.8 M209.3,302.9c0.2,0,0.3,0,0.2,0.2
        C209.3,303.2,209.2,303.1,209.3,302.9 M163.6,303.5c0,0.1,0.1,0.3,0.1,0.4c0.3,0.2,0.5,0.4,0.5,0.7
        C163.9,304.3,163.2,304,163.6,303.5 M209.9,304c0.2-0.1,0.3,0,0.2,0.2C209.9,304.2,209.8,304.2,209.9,304 M200.9,304.8
        c0.2-0.1,0.3-0.2,0.5-0.3c-0.1,0.3-0.3,0.5-0.5,0.7C201,305.1,200.9,304.9,200.9,304.8 M164.4,305.1c0.2-0.1,0.4,0.3,0.3,0.5
        C164.4,305.6,164.2,305.2,164.4,305.1 M280.9,305.1C281.3,305,280.8,305.6,280.9,305.1 M201,305.5c0.1-0.2,0.2-0.2,0.3,0
        C201.2,305.7,201.1,305.7,201,305.5 M211.3,305.9C211.4,306,211.4,306,211.3,305.9 M304.5,305.9c0.2-0.1,0.3,0,0.2,0.2
        C304.4,306.2,304.4,306.1,304.5,305.9 M200.8,306.7c0-0.1,0.1-0.2,0.1-0.3C201.2,306.4,201,306.9,200.8,306.7 M201.2,306.7
        C201.3,306.8,201.3,306.8,201.2,306.7 M281.9,306.9c0.4-0.1,0.2,0.6-0.1,0.5C281.7,307.2,281.8,307,281.9,306.9 M200.9,307.6
        L200.9,307.6c0.1,0.1,0.1,0.3,0.1,0.4C200.8,307.9,200.8,307.8,200.9,307.6 M192.3,307.7C192.3,307.8,192.3,307.8,192.3,307.7
        M388.3,307.9C388.8,307.9,388.1,308.3,388.3,307.9 M39.6,308.4C39.7,308.5,39.7,308.5,39.6,308.4 M283,308.6c0.2,0,0.4,0.3,0.1,0.4
        C282.9,309.1,282.7,308.7,283,308.6 M236.7,309c0.1,0.2,0.3,0.3,0.4,0.5c-0.3,0.3-0.6,0.7-1.1,1c-0.1-0.4,0.3-0.5,0.5-0.7
        C236.5,309.5,236.6,309.3,236.7,309 M128.4,309.7c0.6-0.1-0.1,0.6-0.2,0.1C128.3,309.9,128.4,309.8,128.4,309.7 M325.7,309.7
        c0.2-0.1,0.3,0,0.2,0.2C325.7,310,325.6,309.9,325.7,309.7 M206.5,310.1c0.2-0.1,0.3,0,0.2,0.2C206.4,310.4,206.4,310.3,206.5,310.1
        M218.4,310.3C218.5,310.4,218.5,310.4,218.4,310.3 M353,310.3C353,310.4,353,310.4,353,310.3 M200.8,310.6c0.2-0.1,0.3,0,0.2,0.2
        C200.9,310.8,200.8,310.8,200.8,310.6 M325.3,310.6c0.2-0.1,0.3,0,0.2,0.2C325.3,310.9,325.3,310.8,325.3,310.6 M206.1,310.8
        C206.5,310.5,206.1,311.2,206.1,310.8 M206.5,310.8c0.2-0.1,0.4,0.2,0.3,0.4C206.7,311.2,206.5,311,206.5,310.8 M214.7,311.2
        C214.7,311.2,214.7,311.2,214.7,311.2 M125.6,311.3C126,311.4,125.3,311.7,125.6,311.3 M383.4,311.4c0.4,0-0.1,0.5-0.3,0.4
        C383.1,311.6,383.3,311.5,383.4,311.4 M328.9,311.9c0.2,0,0.3,0.1,0.2,0.3C329,312.2,328.9,312.1,328.9,311.9 M168,312.1
        c0.1,0,0.4,0.1,0.5,0.1c0,0.2-0.1,0.3-0.1,0.5C168.1,312.6,168,312.4,168,312.1 M206.8,312.1c0.2,0,0.1,0.3-0.1,0.4
        C206.5,312.5,206.6,312.1,206.8,312.1 M284.5,312.4c0.2,0,0.3,0.2,0.4,0.4c0,0.1-0.1,0.2-0.1,0.3c0.1,0,0.2,0.1,0.3,0.1
        c-0.2,0.2-0.4-0.2-0.6,0c-0.4,0.2-0.7-0.2-0.6-0.6c0.3,0,0.4,0.3,0.6,0.6c0-0.1,0.1-0.3,0.1-0.4l0.2,0
        C284.7,312.7,284.6,312.6,284.5,312.4 M206.5,312.9c-0.1-0.2,0-0.3,0.2-0.2C206.7,312.9,206.7,313,206.5,312.9 M43.1,313
        c0.1,0.6-0.7,0-0.2-0.1C43,312.9,43.1,312.9,43.1,313 M169.2,313c0.2,0,0.2,0.1,0.1,0.3C169.1,313.3,169.1,313.2,169.2,313
        M303.6,313.2c0.2-0.1,0.4-0.3,0.5,0c0,0.1,0,0.3-0.1,0.4C303.9,313.4,303.7,313.3,303.6,313.2 M192.4,313.6
        c-0.1-0.1-0.1-0.5,0.1-0.4C192.5,313.3,192.4,313.5,192.4,313.6 M192.6,313.7C192.6,313.8,192.6,313.8,192.6,313.7 M227,313.7
        C227.1,313.8,227.1,313.8,227,313.7 M363.9,314L363.9,314L363.9,314L363.9,314L363.9,314 M356.6,314.1c0.2,0,0.3,0.1,0.2,0.2
        C356.6,314.4,356.5,314.3,356.6,314.1 M170.4,314.4c0.2,0.1,0.2,0.2,0,0.3C170.2,314.6,170.2,314.5,170.4,314.4 M134.5,314.7
        C135,314.5,134.6,315.2,134.5,314.7 M192.4,315.1c-0.1-0.1,0-0.5,0.2-0.4C192.8,314.8,192.6,315.2,192.4,315.1 M285.2,314.8
        c0.4-0.2,0.2,0.6-0.1,0.2C285.2,314.9,285.2,314.8,285.2,314.8 M326,314.7C326.5,314.7,325.8,315.1,326,314.7 M170.2,314.9
        C170.3,315,170.3,315,170.2,314.9 M228.1,315.2c0.2-0.1,0.3,0,0.2,0.2C228.1,315.5,228,315.4,228.1,315.2 M42.3,315.7
        c0.3-0.2,0.3,0.3,0,0.1L42.3,315.7 M303.7,316.4c0.2-0.1,0.3,0,0.2,0.2C303.7,316.7,303.6,316.6,303.7,316.4 M230.3,316.6
        c0.1-0.1,0.4-0.2,0.4,0.1C230.6,316.8,230.3,316.8,230.3,316.6 M330.1,316.8C330.1,316.9,330.1,316.9,330.1,316.8 M230.5,317
        C230.6,317.1,230.6,317.1,230.5,317 M286,317.2c0.2-0.1,0.3,0,0.2,0.2C285.9,317.5,285.9,317.4,286,317.2 M376.1,317.3
        c0.2-0.1,0.3,0,0.3,0.2C376.2,317.6,376.1,317.5,376.1,317.3 M172.6,317.4c0.2-0.1,0.3,0,0.3,0.3
        C172.6,317.7,172.6,317.7,172.6,317.4 M200.1,317.4C200.2,317.5,200.2,317.5,200.1,317.4 M219.4,317.4
        C219.5,317.5,219.5,317.5,219.4,317.4 M470.8,317.7c0-0.2,0-0.3,0.2-0.2C471.1,317.7,471,317.7,470.8,317.7 M219.2,317.6
        C219.3,317.7,219.3,317.7,219.2,317.6 M357.8,317.6C357.9,317.7,357.9,317.7,357.8,317.6 M200.3,317.6c0,0.3,0,0.6,0.1,0.9
        c-0.1,0.1-0.3,0.2-0.4,0.3c0.1-0.2,0.3-0.4,0.2-0.6C200.2,318,200.3,317.8,200.3,317.6 M355.8,317.7c0.2-0.1,0.3-0.1,0.3,0.1
        C356,317.9,355.9,317.9,355.8,317.7 M470.8,317.9c0.2,0,0.3,0,0.3,0.2C470.9,318.2,470.8,318.1,470.8,317.9 M138.7,318.3
        c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0.4,0.5,0.4,0.8,0.6c0,0.1-0.1,0.2-0.1,0.3C139.3,318.7,139.1,318.5,138.7,318.3 M352.8,318.3
        C352.9,318.4,352.9,318.4,352.8,318.3 M308.3,318.4C308.8,318.4,308.1,318.8,308.3,318.4 M331.2,318.8
        C331.7,318.8,331,319.2,331.2,318.8 M225,318.8c0.2-0.1,0.3,0,0.2,0.2C225,319.1,224.9,319.1,225,318.8 M308.2,318.8
        c0.2-0.1,0.3,0,0.2,0.2C308.2,319.1,308.1,319,308.2,318.8 M308.3,319.2c0.3,0.2,0.5,0.8,0.1,1C308.5,319.9,308.3,319.6,308.3,319.2
        M307.7,319.8c0.1-0.1,0.2-0.1,0.3,0.1C307.8,320.1,307.7,320,307.7,319.8 M347.1,319.8C347.6,319.6,347.2,320.3,347.1,319.8
        M191.9,320c0.2,0.1,0.2,0.2,0,0.3C191.7,320.2,191.7,320.1,191.9,320 M286.9,320.4c-0.2-0.1-0.2-0.5,0-0.5
        C287.3,319.9,287.2,320.4,286.9,320.4 M287.4,320C287.5,320.1,287.5,320.1,287.4,320 M199.4,320.1
        C199.5,320.2,199.5,320.2,199.4,320.1 M287.7,320.1c0.3,0.1-0.1,0.5-0.3,0.3C287.4,320.3,287.5,320.1,287.7,320.1 M308.1,320.1
        c0.2,0,0.4,0.2,0.3,0.4C308.2,320.6,308,320.3,308.1,320.1 M199.3,320.4C199.3,320.5,199.3,320.5,199.3,320.4 M206.5,320.8
        c0.2-0.1,0.3-0.3,0.5-0.4c-0.1,0.2-0.2,0.4-0.2,0.6C206.7,320.9,206.6,320.8,206.5,320.8 M308.6,320.6
        C308.7,320.7,308.7,320.7,308.6,320.6 M199.7,321c-0.1-0.2-0.2-0.3-0.3-0.5l0.3,0c0.1,0.1,0.1,0.3,0.2,0.4c-0.2,0-0.4,0.1-0.6,0.2
        c0-0.1,0-0.2,0-0.3C199.3,321,199.5,321,199.7,321 M221.6,321C221.7,321.1,221.7,321.1,221.6,321 M206.5,321.3
        c0.1-0.1,0.3-0.1,0.4-0.2c0,0.2-0.1,0.3-0.1,0.5C206.7,321.4,206.6,321.4,206.5,321.3 M178.8,321.3c0.2-0.1,0.3,0,0.2,0.2
        C178.8,321.6,178.7,321.5,178.8,321.3 M178.1,321.4c0.1,0,0.2,0,0.3,0C178.7,321.8,177.8,321.7,178.1,321.4 M206,321.4
        c0.5-0.3,0.7,0.8,0.1,0.6C205.9,321.8,205.9,321.6,206,321.4 M199.4,322.4C199.8,322.2,199.4,322.9,199.4,322.4 M330,322.6
        C330.4,322.3,330.1,323,330,322.6 M309.1,322.7c0.2-0.1,0.3,0,0.2,0.2C309.1,322.9,309,322.8,309.1,322.7 M332.3,323.4
        c-0.1-0.2,0.2-0.3,0.3-0.1C332.9,323.7,332.3,323.7,332.3,323.4 M144.9,323.6c0.2,0,0.6,0.2,0.6,0.4
        C145.2,324,144.9,323.8,144.9,323.6 M304.3,323.7C304.4,323.7,304.4,323.7,304.3,323.7 M332.9,323.7c0.1-0.1,0.5,0,0.4,0.2
        C333.1,324.1,332.8,323.9,332.9,323.7 M407.3,323.9C407.7,323.7,407.3,324.4,407.3,323.9 M404.5,324.5c0.1,0,0.2,0.1,0.3,0.1
        c0.1,0,0.3-0.1,0.3-0.1c-0.1,0.3-0.4,0.2-0.7,0.2C404.5,324.7,404.5,324.6,404.5,324.5 M206.1,325.5c-0.4-0.2-0.1-0.8,0.2-0.9
        c0,0.2,0.1,0.6-0.2,0.6C206.1,325.3,206.1,325.4,206.1,325.5 M288.4,324.6c0.2-0.1,0.7,0.3,0.4,0.4
        C288.6,325,288.4,324.8,288.4,324.6 M108.6,325.6c-0.3-0.1,0-0.4,0.2-0.3C109,325.4,108.8,325.7,108.6,325.6 M310.2,326.1
        c0.1-0.2,0.1-0.4,0.2-0.6c0.1,0.2,0.1,0.4,0.2,0.6C310.6,326,310.3,326,310.2,326.1 M401.3,325.7c0.1-0.2,0.5,0,0.3,0.2
        C401.5,326,401.2,325.8,401.3,325.7 M42.1,325.8C42.6,325.8,41.9,326.2,42.1,325.8 M205.8,326.5c0.2,0,0.3,0.1,0.3,0.3
        C205.8,326.8,205.8,326.7,205.8,326.5 M247.6,326.6c0.3-0.1,0.1,0.5,0,0.1L247.6,326.6 M113.6,326.6c0.2-0.1,0.3,0.2,0.1,0.3
        C113.5,327,113.4,326.7,113.6,326.6 M289.1,327.1C289.5,326.8,289.2,327.6,289.1,327.1 M311,327.6c0-0.2,0.1-0.4,0.1-0.5
        c0.2,0.3,0.4,0.5,0.4,0.8l0.2,0c-0.1,0.4-0.6,0.1-0.4-0.2C311.3,327.7,311.1,327.6,311,327.6 M205.6,327.3c0.1,0.1,0.2,0.2,0.2,0.3
        l0.1,0c-0.2,0.2-0.6,0.5-0.6,0c0.1,0,0.3,0,0.4,0C205.7,327.5,205.7,327.4,205.6,327.3 M232,327.5c0.1-0.1,0.4,0.1,0.3,0.2
        C232.1,327.8,231.9,327.7,232,327.5 M232.4,328.1C232.8,328.1,232.1,328.5,232.4,328.1 M311.8,328.4
        C311.9,328.5,311.9,328.5,311.8,328.4 M197.9,328.8c0.1,0,0.2,0,0.3,0c-0.1,0.1-0.2,0.3-0.3,0.4l0.4-0.1c0,0.1,0,0.2,0,0.2
        c-0.2,0-0.4,0-0.5,0.2C197.8,329.3,197.8,329,197.9,328.8 M232.8,328.8c0.2-0.1,0.4,0.1,0.4,0.3C233.1,329.3,232.6,329,232.8,328.8
        M145.5,329.7C145.9,329.7,145.3,330.1,145.5,329.7 M308,329.7C308.1,329.7,308.1,329.7,308,329.7 M312.6,329.6L312.6,329.6
        L312.6,329.6L312.6,329.6L312.6,329.6 M249.6,330.3c-0.2,0-0.7-0.2-0.7-0.4C249.2,329.8,249.5,330.1,249.6,330.3 M281.3,330
        C281.8,330,281.1,330.5,281.3,330 M301.2,330.1c0.2,0,0.3,0,0.2,0.2C301.2,330.3,301.1,330.2,301.2,330.1 M308.3,330
        C308.4,330.1,308.4,330.1,308.3,330 M197.8,330.4c0.2,0,0.2,0.3,0,0.3C197.6,330.7,197.7,330.4,197.8,330.4 M153.2,330.6
        C153.7,330.4,153.2,331.1,153.2,330.6 M266.6,331.6c-0.2-0.2,0-0.5,0.2-0.4C267,331.4,266.8,331.8,266.6,331.6 M127.4,331.6
        C127.5,331.6,127.5,331.7,127.4,331.6L127.4,331.6L127.4,331.6 M356.9,331.8C357,331.8,357,331.8,356.9,331.8 M356.2,331.9
        C356.6,331.7,356.2,332.4,356.2,331.9 M355.4,332.2c-0.1-0.2,0.5-0.1,0.6-0.1C356.1,332.6,355.6,332.3,355.4,332.2 M205.7,332.1
        c0.3,0.2,0.3,0.5,0,0.7c0.3,0,0.4,0.1,0.2,0.4c-0.1-0.1-0.3-0.2-0.4-0.3c0-0.1,0-0.2-0.1-0.3l0.3,0
        C205.7,332.5,205.7,332.3,205.7,332.1 M230.5,332.4C230.6,332.4,230.6,332.4,230.5,332.4 M365.8,332.3
        C366.2,332.1,365.8,332.8,365.8,332.3 M155.6,332.5c0.2-0.1,0.3,0,0.2,0.2C155.6,332.7,155.6,332.7,155.6,332.5 M354,332.7
        c-0.1-0.3,0.4-0.3,0.6-0.1C354.5,332.8,354.1,332.7,354,332.7 M197,333.5c-0.3,0.1-0.3-0.6,0.1-0.5
        C197.2,333.1,197.2,333.3,197,333.5 M231.1,332.9c0.2-0.1,0.4,0.2,0.3,0.3C231.2,333.3,230.9,333.1,231.1,332.9 M305.4,332.9
        c0.2-0.1,0.3,0,0.2,0.2C305.4,333.2,305.3,333.1,305.4,332.9 M310.3,333.2c0.3-0.1,0,0.5,0,0.1L310.3,333.2 M352.5,333.3
        c0.1-0.1,0.5,0,0.4,0.2C352.7,333.6,352.4,333.5,352.5,333.3 M315.2,333.6c0.1-0.2,0.3,0,0.4,0.1c0,0.2,0,0.2-0.2,0.2
        C315.4,333.8,315.2,333.6,315.2,333.6 M196.6,334.2c0.1-0.2,0.2-0.3,0.1-0.5l0.2,0.1c0,0.1-0.1,0.2-0.2,0.3
        c-0.1,0.2-0.1,0.3-0.1,0.5c-0.1,0-0.2,0-0.3,0C196.2,334.3,196.7,334.4,196.6,334.2 M351.1,334c0.2-0.1,0.4,0.2,0.2,0.3
        C351.1,334.4,350.9,334.1,351.1,334 M196.1,334.7c0,0,0.1-0.1,0.2-0.1c0.2,0.1,0.3,0.4,0.2,0.6C196.3,335.1,196.2,334.8,196.1,334.7
        M237.3,334.7c0.1-0.2,0.2-0.2,0.3,0C237.5,335,237.4,335,237.3,334.7 M251.6,334.7c0.2-0.1,0.4,0.2,0.2,0.4
        C251.6,335.2,251.5,334.9,251.6,334.7 M228,334.9C228.1,335,228.1,335,228,334.9 M251.7,335.4C251.8,335.5,251.8,335.5,251.7,335.4
        M373.1,335.9C373.1,336,373.1,336,373.1,335.9 M358.9,337c0.1-0.3,0.2-0.5,0.4-0.6c0.3,0,0,0.3,0,0.5
        C359.2,336.8,359,336.9,358.9,337 M153.8,336.5C154.2,336.3,153.8,336.9,153.8,336.5 M195.8,336.5L195.8,336.5c0.1,0.1,0,0.3,0,0.4
        l0.2,0.1c-0.1,0.2-0.2,0.4-0.4,0.5c0-0.3,0.1-0.5,0-0.8l0.1,0C195.8,336.7,195.8,336.5,195.8,336.5 M360.3,336.8
        c0.2-0.3,0.6-0.3,0.9-0.3c-0.1,0.1-0.2,0.2-0.2,0.3C360.7,336.7,360.5,336.7,360.3,336.8 M361.6,337c0.1-0.2,0.2-0.3,0.3-0.5
        c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0-0.2,0-0.3,0l0,0.2C361.8,337,361.7,337,361.6,337 M151.6,336.8c0.2,0,0.4,0,0.6,0
        c0.1,0.1,0.2,0.2,0.3,0.4c-0.1,0-0.3-0.1-0.3-0.2C151.9,337,151.7,337,151.6,336.8 M318.4,336.7L318.4,336.7
        c0.1,0.2,0.1,0.4,0.1,0.6C318.2,337.4,318.2,336.9,318.4,336.7 M365.9,336.8C366.3,336.5,365.9,337.2,365.9,336.8 M366.4,337.3
        c-0.3,0-0.1-0.7,0.1-0.4c0.2,0,0.4,0,0.6,0c-0.1,0.2-0.1,0.4-0.1,0.6c-0.1-0.2-0.3-0.4-0.5-0.5L366.4,337.3 M202.5,336.9
        C202.6,337,202.6,337,202.5,336.9 M144.7,337.4c0.2-0.1,0.4-0.2,0.5-0.3c0,0.2,0,0.3,0,0.5c-0.1,0-0.3-0.1-0.4-0.1
        C144.9,337.5,144.8,337.4,144.7,337.4 M161.4,337.2C161.8,337.2,161.2,337.6,161.4,337.2 M305.9,337.2
        C305.9,337.2,305.9,337.2,305.9,337.2 M366.1,337.6c-0.2-0.1-0.2-0.2-0.1-0.3C366.3,337.4,366.3,337.5,366.1,337.6 M318,337.8
        c0.2-0.1,0.3,0,0.2,0.2C318,338.1,317.9,338,318,337.8 M269.1,337.9C269.1,338,269.1,338,269.1,337.9 M269.4,338.3
        c0.1-0.1,0.5,0.1,0.4,0.3C269.6,338.7,269.3,338.5,269.4,338.3 M194.8,338.5c0.2-0.1,0.6,0.3,0.3,0.4
        C195,338.8,194.8,338.7,194.8,338.5 M319.1,338.9c-0.3-0.1,0.1-0.5,0.2-0.4C319.4,338.6,319.3,338.9,319.1,338.9 M241.8,339.9
        c0,0,0.1,0,0.2,0c0,0.1,0,0.2,0,0.2c0.3,0,0.3,0.3,0.4,0.5c0,0-0.1,0-0.2,0C242.1,340.4,241.9,340.2,241.8,339.9 M253.8,340.6
        c-0.4,0-0.2-0.3-0.1-0.6c0.2,0.1,0.3,0.7,0.6,0.3c0,0,0,0.1,0,0.1c0,0.1-0.1,0.2-0.2,0.3c0.1,0,0.3,0,0.3,0.1
        c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0,0.3,0.1,0.4l-0.2,0.1C254,341.2,253.9,340.9,253.8,340.6 M194.1,340.9c0.2,0,0.3,0,0.2,0.2
        C194.1,341.1,194,341.1,194.1,340.9 M320,340.9c0.2-0.1,0.3,0,0.2,0.2C320,341.1,319.9,341.1,320,340.9 M319.5,341.6
        C319.6,341.7,319.6,341.7,319.5,341.6 M352,341.6C352,341.7,352,341.7,352,341.6 M361.1,341.9c0-0.2,0.2-0.4,0.4-0.3
        C361.6,341.8,361.2,342.1,361.1,341.9 M281.2,342.1c-0.3,0.1-0.3-0.5,0-0.3C281.2,341.9,281.2,342,281.2,342.1 M170.2,342.9
        c-0.2,0.2-0.6-0.3-0.3-0.4C170.1,342.6,170.2,342.7,170.2,342.9 M271.4,343.3c-0.1-0.2-0.1-0.3,0.1-0.4
        C271.7,343,271.8,343.7,271.4,343.3 M153.7,343.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0-0.1,0.1-0.1,0.2c-0.1,0-0.2-0.1-0.3-0.1
        C153.8,343.5,153.8,343.3,153.7,343.1 M171,343.4c0.1-0.2,0.6-0.1,0.6,0.1C171.5,343.7,171.1,343.6,171,343.4 M258.8,343.3
        C258.9,343.4,258.9,343.4,258.8,343.3 M244.6,343.4C245,343.2,244.6,343.9,244.6,343.4 M358,343.4C358.4,343.2,358,343.9,358,343.4
        M271.6,343.8C271.7,343.8,271.7,343.8,271.6,343.8 M192.3,344c0.1-0.1,0.6,0,0.5,0.2C192.6,344.3,192.2,344.2,192.3,344 M255.8,344
        c0.2-0.1,0.3,0,0.2,0.3C255.8,344.3,255.8,344.2,255.8,344 M192.3,344.3c0.1,0.1,0.2,0.3,0.2,0.5c-0.1,0.1-0.3,0.2-0.5,0.3
        C192.1,344.9,192.4,344.6,192.3,344.3 M244.9,344.5c0.1-0.3,0.4,0.1,0.3,0.2C245.1,344.8,245,344.7,244.9,344.5 M255.5,344.3
        c0.4-0.2,0.4,0.5,0.8,0.5c0,0.1,0,0.2,0,0.2l0.2,0c0,0.1-0.1,0.3-0.1,0.4c-0.1,0-0.2-0.1-0.3-0.2c0-0.1,0.1-0.2,0.1-0.2
        C255.8,345.1,255.7,344.6,255.5,344.3 M321.7,344.7c0.1-0.1,0.1-0.6,0.2-0.3c0,0.3,0.2,0.4,0.3,0.7
        C322.1,345,321.9,344.8,321.7,344.7 M314.1,345.2c0.2,0,0.3,0,0.2,0.2C314.1,345.4,314.1,345.3,314.1,345.2 M228.6,345.8
        c-0.4,0.6-0.8-0.7-0.2-0.7c0.2,0.2,0,0.4,0,0.6C228.4,345.7,228.6,345.8,228.6,345.8 M319.8,345.4c0.2-0.2,0.5,0.1,0.6,0.3
        c-0.2,0-0.3,0.1-0.4,0.2C320.1,345.7,320.1,345.5,319.8,345.4 M260.1,345.5c0.2-0.1,0.3,0,0.3,0.2
        C260.1,345.8,260,345.8,260.1,345.5 M305.9,346.2c0-0.2,0.2-0.4,0.4-0.4C306.3,346,306.1,346.3,305.9,346.2 M363.3,346.2
        c0.2,0.1,0.4,0.2,0.5,0.3c-0.1,0.1-0.2,0.2-0.3,0.3C363.4,346.7,363.4,346.4,363.3,346.2 M154.6,346.6c0-0.3,0.4-0.3,0.3,0
        C154.9,347,154.1,346.9,154.6,346.6 M323.3,346.8c-0.4,0.2-0.4-0.4-0.1-0.5C323.4,346.4,323.4,346.6,323.3,346.8 M364.7,346.5
        c0.2,0.3,0.6,0,0.8-0.1c-0.1,0.4-0.5,0.4-0.8,0.5C364.8,346.8,364.7,346.6,364.7,346.5 M148.9,346.9c0.2-0.1,0.3-0.3,0.5-0.3
        c-0.1,0.2-0.2,0.4-0.3,0.7C149.1,347.1,149,347,148.9,346.9 M260.8,346.5c0.4-0.2,0.4,0.5,0,0.2
        C260.8,346.7,260.8,346.6,260.8,346.5 M133.1,346.6c0.3,0.1,0.5,0.4,0.8,0.3c-0.1,0.3-0.4,0.2-0.7,0.3c0-0.1,0.1-0.2,0.1-0.2
        C133.3,346.9,133.1,346.7,133.1,346.6 M358.1,346.7C358.2,346.8,358.2,346.8,358.1,346.7 M260.7,347
        C261.1,346.8,260.7,347.5,260.7,347 M374.9,347L374.9,347L374.9,347L374.9,347L374.9,347 M144.7,347.5c0-0.2,0-0.3,0.2-0.3
        c0,0.1,0.1,0.2,0.1,0.3C144.9,347.5,144.7,347.5,144.7,347.5 M160.2,347.7c-0.2-0.3,0.1-0.5,0.4-0.4
        C160.5,347.4,160.5,348,160.2,347.7 M274.3,347.5c0.2-0.1,0.2,0.3,0.2,0.4C274.3,348.1,274.2,347.6,274.3,347.5 M306.2,348.1
        c-0.1-0.1,0-0.5,0.2-0.4C306.5,347.8,306.4,348.2,306.2,348.1 M261.5,347.9c0.2-0.2,0.3,0.2,0.1,0.3
        C261.5,348.3,261.4,348,261.5,347.9 M189.5,348.5c0.2,0,0.5-0.1,0.6,0.1C189.9,348.6,189.6,348.9,189.5,348.5 M275,348.8
        c0.2,0,0.3,0.1,0.2,0.3C275,349.1,275,349,275,348.8 M310.8,349.1c-0.2,0.1-0.3-0.3-0.2-0.3C310.7,348.8,310.8,349,310.8,349.1
        M194.5,349.9c0.1-0.1,0.3-0.2,0.3-0.4c0.5,0.3,0,0.7-0.2,1c-0.2,0.2-0.1,0.6-0.4,0.5c0,0.1,0,0.3,0,0.4l-0.2,0
        c-0.1,0.3-0.3,0.5-0.4,0.7c-0.2,0.1-0.5,0.2-0.4,0.5c0,0.2-0.2,0-0.3,0c0.1-0.1,0.3-0.3,0.4-0.4c0.3-0.2,0.4-0.5,0.5-0.7
        c0-0.1,0-0.3-0.1-0.4c0.1,0.1,0.2,0.2,0.3,0.2c0-0.2,0-0.4,0-0.6l0.2,0.2c0-0.1,0.1-0.4,0.1-0.6l-0.2,0c0.1,0,0.3,0,0.3,0
        C194.5,350.2,194.5,350,194.5,349.9 M240.9,349.9c0.3-0.2,0.3,0.4,0,0.1L240.9,349.9 M217.6,350.4
        C217.7,350.5,217.7,350.5,217.6,350.4 M219.8,350.4c0.2,0,0.3,0,0.3,0.2C219.9,350.7,219.8,350.6,219.8,350.4 M263.1,350.5
        c0.2,0.1,0.2,0.2,0,0.3C262.9,350.7,262.9,350.6,263.1,350.5 M298,350.5c0,0,0.1,0,0.2,0c-0.1,0.1-0.2,0.2-0.3,0.3
        c0.1,0.1,0.2,0.2,0.3,0.2c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.2,0-0.3C297.8,350.7,297.9,350.6,298,350.5 M241.6,350.6
        C242,350.7,241.3,351.1,241.6,350.6 M245.3,351.4c0.2,0.1,0.2,0.2,0,0.3C245.1,351.6,245.1,351.5,245.3,351.4 M297.4,351.9
        c0.2-0.2,0.4-0.4,0.7-0.4c-0.1,0.1-0.3,0.2-0.3,0.3c0.1,0.2,0.2,0.3,0.3,0.5C297.8,352.2,297.6,352.1,297.4,351.9 M326.3,351.7
        c0.2-0.1,0.5,0.2,0.3,0.3C326.4,352.2,326.1,351.8,326.3,351.7 M218.2,352.2c0-0.3,0.4-0.2,0.2,0.1
        C218.4,352.2,218.3,352.2,218.2,352.2 M187.3,352.2c0.2-0.1,0.3,0,0.2,0.2C187.3,352.5,187.2,352.4,187.3,352.2 M207.8,352.8
        c0.2-0.1,0.3-0.1,0.3,0.1C207.9,353,207.8,353,207.8,352.8 M246.3,353C246.8,353,246.1,353.4,246.3,353 M297.7,353
        c0.1-0.2,0.4,0.1,0.3,0.3C297.8,353.4,297.5,353.2,297.7,353 M326.5,353.2c0.2-0.1,0.3,0,0.2,0.2
        C326.5,353.4,326.4,353.4,326.5,353.2 M281.3,353.2C281.7,353,281.3,353.7,281.3,353.2 M324.9,353.6c0.2-0.1,0.3,0,0.2,0.2
        C324.9,353.9,324.8,353.8,324.9,353.6 M321.8,353.8C321.9,353.9,321.9,353.9,321.8,353.8 M319.5,354.2c-0.1-0.4,0.6-0.2,0.4,0.1
        C319.8,354.4,319.7,354.3,319.5,354.2 M321,354c0.1-0.1,0.4,0,0.5,0.1C321.5,354.5,321.1,354.1,321,354 M281.3,354.6
        c0.3-0.2,0.4,0.3,0.1,0.4C281.2,355.1,281.3,354.7,281.3,354.6 M297.7,355.1c-0.4,0-0.4-0.7,0.1-0.5
        C297.8,354.7,297.8,354.9,297.7,355.1 M185.3,354.9c0.2-0.2,0.4,0.2,0.2,0.4C185.3,355.4,185.1,355,185.3,354.9 M251.7,355
        C251.8,355.1,251.8,355.1,251.7,355 M244.5,355c0.2,0,0.3,0,0.2,0.2C244.5,355.3,244.4,355.2,244.5,355 M191.8,355.3
        C191.9,355.3,191.9,355.3,191.8,355.3 M305.3,355.5c0.1-0.2,0.3-0.3,0.6-0.3c-0.1,0.2-0.1,0.3-0.2,0.5c0.2,0.4-0.1,0.9-0.2,1.3
        c-0.1,0-0.4,0.1-0.5,0.1c0.1-0.3,0.4-0.4,0.6-0.5c0-0.3-0.1-0.5,0-0.8C305.5,355.7,305.4,355.5,305.3,355.5 M244.3,355.4
        c0.1,0,0.2,0.1,0.2,0.1C244.4,355.7,244.3,355.6,244.3,355.4 M244.7,355.5c0.3-0.1,0.2,0.5,0,0.4
        C244.4,355.9,244.5,355.5,244.7,355.5 M213.3,356.1C213.4,356.2,213.4,356.2,213.3,356.1 M267.2,356.2c0.3-0.2,0.2,0.3,0,0.1
        L267.2,356.2 M184.5,356.3c0.5,0.2-0.6,0.4-0.3,0.8c-0.1-0.1-0.2-0.2-0.2-0.4C184.2,356.7,184.4,356.6,184.5,356.3 M245.3,356.9
        C245.4,357,245.4,357,245.3,356.9 M245.1,357.2C245.5,357,245.1,357.7,245.1,357.2 M285,357.4c0.1-0.1,0.2-0.2,0.3-0.3
        c0.1,0.2,0.2,0.4,0.2,0.6c0.3,0.3,0.5,0.7,0.7,1.1l-0.4,0c0.2-0.3-0.1-0.5-0.3-0.7C285.2,357.8,285.1,357.6,285,357.4 M245.5,357.4
        c0.2,0.1,0.2,0.2,0,0.3C245.3,357.6,245.3,357.5,245.5,357.4 M330.4,357.3c0.2-0.1,0.3,0,0.3,0.3
        C330.5,357.6,330.4,357.6,330.4,357.3 M252.9,357.5C253.4,357.5,252.7,357.9,252.9,357.5 M297.4,358c-0.2-0.2,0.1-0.5,0.3-0.3
        C297.7,357.8,297.6,357.9,297.4,358 M232,358.1C232,358.1,232,358.1,232,358.1 M262.5,358.2c0.3,0.1,0.1,0.3,0.1,0.5
        c0.1,0.1,0.1,0.3,0.2,0.4c-0.2,0-0.3,0.1-0.5,0.2c-0.1-0.3,0.3-0.3,0.3-0.6C262.6,358.5,262.5,358.3,262.5,358.2 M297.5,358.2
        c0,0.1,0,0.3,0.1,0.5c-0.1,0.1-0.2,0.3-0.2,0.5c-0.1-0.2-0.2-0.3-0.4-0.4c0.1-0.2,0.2-0.4,0.5-0.3L297.5,358.2 M297.3,358.7
        C297.3,358.7,297.3,358.7,297.3,358.7 M199.9,358.9c-0.1-0.2,0-0.3,0.3-0.3C200.2,358.9,200.1,359,199.9,358.9 M246.3,358.6
        L246.3,358.6c0.1,0.2,0,0.3,0,0.5c-0.3,0-0.5-0.1-0.6-0.3c0.1,0,0.4,0.1,0.6,0.1C246.3,358.9,246.3,358.7,246.3,358.6 M221,359.9
        c-0.2-0.3-0.6-0.6-0.3-1c0.1,0.3,0.3,0.5,0.5,0.7C221.1,359.7,221,359.8,221,359.9 M246.4,359.4c0.1,0,0.2-0.1,0.2-0.1
        c0.1,0.2,0.1,0.3-0.1,0.4C246.5,359.6,246.5,359.5,246.4,359.4 M262.8,359.2c0.2,0.1,0.2,0.2,0,0.3
        C262.6,359.4,262.6,359.3,262.8,359.2 M225.6,359.5C225.7,359.6,225.7,359.6,225.6,359.5 M297,359.6c0.2,0,0.2,0.4,0,0.4
        C296.8,360,296.8,359.6,297,359.6 M263.1,360.1c0.2-0.1,0.3,0,0.2,0.2C263.1,360.3,263.1,360.2,263.1,360.1 M232.9,360.8
        c-0.2-0.1-0.3-0.4-0.1-0.5C233,360.4,233.2,360.8,232.9,360.8 M246.8,360.8c0.1-0.4,0.7,0.2,0.3,0.3
        C247,361,246.9,360.9,246.8,360.8 M296.8,361.1c-0.1-0.2,0.2-0.4,0.4-0.2C297.3,361,296.9,361.2,296.8,361.1 M232.9,361.3
        c0.3-0.3,0.5,0,0.3,0.3c0.4-0.1,0.2,0.5,0.3,0.7C233.2,362,233.2,361.5,232.9,361.3 M250.5,361C250.9,360.8,250.5,361.5,250.5,361
        M296.4,361.2c0.1-0.2,0.4-0.1,0.4,0.1C296.8,361.6,296.4,361.4,296.4,361.2 M341.4,362.1C341.9,362.1,341.2,362.5,341.4,362.1
        M231.2,363.2c-0.1-0.1,0-0.5,0.2-0.4C231.5,362.9,231.4,363.3,231.2,363.2 M296.1,363.3c-0.1-0.2-0.2-0.6,0-0.6
        C296.3,362.8,296.4,363.4,296.1,363.3 M248,363.2c0.5,0.2,0.1,0.7,0.3,1c-0.1,0-0.2,0-0.3-0.1C248.2,363.9,248,363.5,248,363.2
        M256.1,364.1C256.6,364.1,255.8,364.5,256.1,364.1 M211.6,364.2c0.2-0.1,0.3,0.2,0.2,0.3C211.7,364.7,211.5,364.3,211.6,364.2
        M248,364.3C248.1,364.4,248.1,364.4,248,364.3 M204.4,364.6C204.4,364.7,204.4,364.7,204.4,364.6 M248.6,365.3c0,0,0.1,0,0.1,0
        c0,0.2,0,0.5,0,0.7c-0.1-0.1-0.3-0.2-0.4-0.3C248.4,365.6,248.5,365.5,248.6,365.3 M264.9,366.1c-0.1-0.2,0-0.3,0.2-0.2
        C265.2,366.1,265.1,366.2,264.9,366.1 M286.8,366C286.9,366.1,286.9,366.1,286.8,366 M287.1,366.3L287.1,366.3L287.1,366.3
        L287.1,366.3L287.1,366.3 M241.3,366.5C241.4,366.6,241.4,366.6,241.3,366.5 M256.9,366.6c0.1-0.2,0.4,0.1,0.3,0.2
        C257,367,256.8,366.8,256.9,366.6 M249.1,366.7c0.2-0.1,0.3,0,0.2,0.2C249.1,367,249.1,366.9,249.1,366.7 M217.2,368.2
        c-0.1-0.1,0-0.5,0.2-0.4C217.5,367.9,217.4,368.4,217.2,368.2 M265.2,367.9C265.6,367.7,265.3,368.4,265.2,367.9 M294.1,368.2
        C294.2,368.3,294.2,368.3,294.1,368.2 M249.6,368.3c0.2,0,0.3,0.3,0.3,0.5C249.6,368.9,249.3,368.5,249.6,368.3 M308.3,368.3
        C308.4,368.4,308.4,368.4,308.3,368.3 M218.7,368.9C218.8,369,218.8,369,218.7,368.9 M249.1,368.9c0.2-0.1,0.3,0.2,0.1,0.3
        C249,369.3,248.9,368.9,249.1,368.9 M249.8,369.2c-0.2-0.1-0.2-0.2,0.1-0.3C250,369,250,369.1,249.8,369.2 M249.5,369.6
        c0.4,0.1,0.7,0.4,0.7,0.8C250,370.2,249.7,369.9,249.5,369.6 M254.2,370c0.4,0-0.2,0.6-0.2,0.2C254,370.1,254.1,370,254.2,370
        M237.7,371c0.1-0.1,0.2-0.1,0.3,0c0,0.1,0,0.3,0,0.4C237.8,371.6,237.6,371.2,237.7,371 M266,371.2c0,0,0.1,0,0.2,0
        c-0.1,0.2-0.2,0.4-0.2,0.6c0.1,0.1,0.2,0.2,0.2,0.2c0,0-0.1,0-0.2,0C265.8,371.7,265.8,371.4,266,371.2 M300.9,371.3
        c0.1,0.2,0.1,0.3,0.2,0.5c-0.1,0-0.2,0-0.3,0C300.8,371.6,300.8,371.4,300.9,371.3 M216.1,371.4
        C216.1,371.5,216.1,371.5,216.1,371.4 M299.9,372.9C300,373,300,373,299.9,372.9 M250.6,372.9c0.3,0.2,0,0.5,0,0.7
        c-0.1,0-0.2-0.1-0.3-0.1C250.5,373.4,250.6,373.2,250.6,372.9 M300.1,373.2C300.1,373.2,300.1,373.2,300.1,373.2 M246,373.4
        c0.1-0.2,0.2-0.2,0.4,0C246.2,373.6,246.1,373.6,246,373.4 M258.8,373.6c0.2-0.1,0.3,0,0.2,0.3C258.8,373.9,258.8,373.8,258.8,373.6
        M259.1,374C259.2,374.1,259.2,374.1,259.1,374 M250.7,374.2c0.2-0.1,0.4,0.2,0.3,0.3C250.7,374.7,250.5,374.3,250.7,374.2
        M242.2,374.7C242.2,374.8,242.2,374.8,242.2,374.7 M250.9,375C251.3,374.8,250.9,375.5,250.9,375 M298.1,376.2
        C298.2,376.2,298.2,376.2,298.1,376.2 M297.8,376.6C297.9,376.7,297.9,376.7,297.8,376.6 M280,377.6
        C280.1,377.7,280.1,377.7,280,377.6 M256,378.1c-0.2-0.1-0.1-0.4,0.2-0.4c0.1,0.1,0.2,0.5,0,0.6C256.1,378.2,256,378.2,256,378.1
        M297.1,377.7c0.2,0,0.1,0.3-0.1,0.3C296.8,378,296.9,377.7,297.1,377.7 M242.2,378.3c-0.1-0.1-0.3-0.4-0.1-0.5
        C242.2,377.9,242.5,378.2,242.2,378.3 M251.3,377.9c0.1,0.1,0.1,0.2,0.2,0.4c-0.1,0.1-0.3,0.1-0.4,0.2c0-0.1,0-0.2,0-0.2l0.2,0
        C251.4,378.2,251.3,378,251.3,377.9 M296.5,378.1C297,377.9,296.5,378.6,296.5,378.1 M251.4,378.6c0.2-0.1,0.3,0,0.2,0.2
        C251.4,378.9,251.3,378.8,251.4,378.6 M256.2,378.3c0.2,0.3,0.8,0.5,0.6,1c-0.3,0.2-0.4-0.2-0.6-0.3
        C256.2,378.8,256.2,378.5,256.2,378.3 M251.1,378.9L251.1,378.9L251.1,378.9L251.1,378.9L251.1,378.9 M251.4,379.9
        c0.3-0.3-0.4-0.7,0-0.9C251.7,379.2,251.9,379.7,251.4,379.9 M246.6,379.7c0.3,0-0.2,0.6-0.1,0.2
        C246.5,379.9,246.6,379.8,246.6,379.7 M267.2,380.3c0.2-0.1,0.3,0,0.2,0.2C267.2,380.6,267.1,380.5,267.2,380.3 M251.3,380.7
        C251.7,380.5,251.4,381.2,251.3,380.7 M292.8,381.9C292.9,382,292.9,382,292.8,381.9 M237.7,382.4
        C238.2,382.4,237.5,382.8,237.7,382.4 M301.2,382.7c0.2,0.1,0.2,0.2,0,0.3C301,382.9,301,382.8,301.2,382.7 M291.9,383.5
        c-0.1-0.1-0.1-0.5,0.1-0.5C292.2,383.1,292.2,383.6,291.9,383.5 M290.7,384.9c-0.1-0.1,0.1-0.4,0.2-0.4
        C291,384.7,290.9,385,290.7,384.9 M291.2,384.7C291.7,384.7,291,385.1,291.2,384.7 M251.9,385.4c0.2,0,0.3,0,0.3,0.2
        C251.9,385.7,251.8,385.6,251.9,385.4 M290.4,385.6C290.9,385.6,290.2,386,290.4,385.6 M297.9,386.3L297.9,386.3L297.9,386.3
        L297.9,386.3L297.9,386.3 M289.5,386.7c0-0.2,0-0.3,0.3-0.2C289.8,386.7,289.7,386.7,289.5,386.7 M281.6,386.7
        C281.7,386.7,281.7,386.7,281.6,386.7 M267.2,387.2c0.1,0.1,0.2,0.2,0.3,0.4c-0.3,0.2-0.5,0.4-0.8,0.6
        C266.6,387.8,267.1,387.6,267.2,387.2 M247.7,387.5c0.3-0.1,0.1,0.5,0,0.1L247.7,387.5 M267.3,388c0.5-0.1,0.1,0.5,0.1,0.8
        c0,0-0.1,0-0.2,0C267.3,388.5,267.3,388.2,267.3,388 M235.4,388.4C235.8,388.3,235.3,389,235.4,388.4 M261.7,388.5
        C261.8,388.6,261.8,388.6,261.7,388.5 M227.1,388.8C227.2,388.9,227.2,388.9,227.1,388.8 M252.1,390.8c0.1-0.2,0.1-0.4,0.2-0.5
        c0,0.1,0,0.3,0,0.4c0.1,0.1,0.3,0.1,0.4,0.2c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.4,0.4,0.7,0.1,1.1c-0.1-0.2-0.2-0.3-0.2-0.5
        c0-0.2-0.2-0.3-0.3-0.5C252.6,391.5,252.7,390.8,252.1,390.8 M293.4,391.2C293.8,391,293.4,391.7,293.4,391.2 M252.3,392.4
        c0.4,0.1,0.4,0.6,0.5,0.9c-0.1,0.1-0.2,0.1-0.2,0.2l0.3,0.1c-0.1,0-0.2,0.1-0.3,0.2c0-0.1,0-0.2,0-0.2c-0.1,0-0.3,0.1-0.3,0.1
        C252.7,393.3,252.3,392.8,252.3,392.4 M257.6,393C257.6,393,257.6,393,257.6,393 M257.9,392.9l0.1,0c0,0.1,0,0.2,0,0.2l-0.1,0
        C257.8,393.1,257.9,392.9,257.9,392.9 M272.3,393.1C272.4,393.2,272.4,393.2,272.3,393.1 M257.4,393.7
        C257.9,393.7,257.1,394,257.4,393.7 M252.4,394.5c0.5-0.1,0.7,0.5,0.4,0.9c0,0.1,0.1,0.2,0.1,0.3c-0.2-0.1-0.3-0.1-0.5-0.2
        c-0.1-0.3,0.5-0.3,0.5-0.6C252.7,394.7,252.4,394.7,252.4,394.5 M267,395c0.2-0.1,0.5,0.2,0.4,0.4C267.2,395.5,266.9,395.2,267,395
        M252.7,395.7c0.2,0,0.2,0.2,0.3,0.3c-0.2,0.1-0.1,0.4-0.2,0.6c-0.3-0.1-0.3-0.5,0.1-0.5C252.8,396,252.8,395.9,252.7,395.7
        M289.6,396c0.1-0.2,0.2-0.2,0.4,0C289.8,396.2,289.7,396.2,289.6,396 M289.7,396.3C290.1,396.1,289.7,396.8,289.7,396.3
        M252.9,396.8c0.3,0,0.1,0.5,0.2,0.7c0-0.1-0.1-0.2-0.1-0.3l-0.2,0c0,0.3-0.1,0.5-0.3,0.6c0-0.1,0.1-0.4,0.1-0.5
        C252.8,397.1,252.8,396.9,252.9,396.8 M267,397.3c0.2-0.1,0.3,0,0.2,0.2C267,397.6,267,397.6,267,397.3 M288.4,397.3
        c0,0,0.1,0,0.2,0c0,0.1,0,0.3,0,0.4c0.1,0,0.2,0,0.2,0c0.1-0.2,0.1-0.4,0.3-0.5c-0.1,0.2-0.1,0.4-0.2,0.6c-0.2,0.1-0.4,0.1-0.5,0.2
        C288.4,397.8,288.5,397.6,288.4,397.3 M273.4,398.9c-0.2,0.1-0.4-0.4-0.2-0.4C273.4,398.6,273.5,398.8,273.4,398.9 M252.9,399.1
        c0-0.2,0-0.2,0.2-0.2c0.1,0.1,0.2,0.2,0.3,0.2c0,0.1-0.1,0.2-0.1,0.2C253.1,399.3,253,399.2,252.9,399.1 M247.3,399.6
        C247.4,399.7,247.4,399.7,247.3,399.6 M272.7,399.6c0.2-0.1,0.3,0,0.2,0.2C272.7,399.9,272.7,399.8,272.7,399.6 M271.7,400.1
        c0.1-0.2,0.2-0.3,0.4-0.5c0.1,0,0.4,0,0.6,0c-0.1,0.2-0.3,0.3-0.5,0.4c0.1,0.2,0.2,0.4,0.3,0.6c-0.2,0-0.3,0.1-0.5,0.2
        c-0.1,0.1-0.3,0.2-0.4,0.3c0-0.1-0.1-0.3-0.1-0.4c0.3,0,0.7,0,0.9-0.4c-0.1,0-0.3,0-0.4,0c0-0.1,0.1-0.3,0.1-0.3L271.7,400.1
        M243.3,400.5c0.1-0.1,0.2-0.1,0.4,0C243.9,400.9,243.2,401,243.3,400.5 M286.5,400.9c-0.1-0.2,0-0.3,0.2-0.3
        C286.8,400.8,286.7,400.9,286.5,400.9 M243.8,401.4c0.1-0.1,0.3-0.2,0.4-0.2c0.1,0.2,0.1,0.5-0.1,0.6
        C243.9,401.7,243.8,401.5,243.8,401.4 M285.6,402.2C285.6,402.2,285.6,402.2,285.6,402.2 M248.9,402.3c0.2-0.1,0.3,0,0.2,0.2
        C248.9,402.5,248.8,402.4,248.9,402.3 M231.5,402.5c0.2,0,0.3,0,0.2,0.2C231.6,402.7,231.5,402.7,231.5,402.5 M266.4,402.5
        c0.2,0,0.1,0.6-0.1,0.5C266.1,402.8,266.2,402.6,266.4,402.5 M270.1,403c0.3-0.1,0.4,0.6,0.1,0.4C270.1,403.3,270.1,403.2,270.1,403
        M233,404.9c0.2,0,0.8,0.2,0.4,0.4C233.2,405.3,232.9,405.1,233,404.9 M253.4,404.9c0.1,0.1,0.2,0.1,0.4,0.2
        c-0.1,0.2-0.2,0.5-0.4,0.7C253.3,405.5,253.4,405.2,253.4,404.9 M274,405c0.1,0.1,0.2,0.2,0.2,0.3c-0.2,0-0.4,0.1-0.7,0.1
        C273.7,405.2,273.8,405.1,274,405 M237.8,405.1C237.9,405.2,237.9,405.2,237.8,405.1 M269,405.3c0.2-0.1,0.3,0,0.2,0.2
        C269,405.6,268.9,405.5,269,405.3 M253.6,406.2c-0.2-0.1,0-0.5,0.2-0.4c0,0.1,0,0.2,0,0.3L253.6,406.2 M282.8,406.3
        c-0.2-0.3,0.3-0.9,0.4-0.3c-0.2,0.1-0.2,0.4-0.4,0.5l0,0.1c-0.2,0-0.6,0-0.6-0.3C282.4,406.3,282.6,406.3,282.8,406.3 M268.6,406.1
        c0.2-0.1,0.3,0,0.2,0.2C268.6,406.4,268.5,406.4,268.6,406.1 M268.5,406.6c0.4,0,0,0.3-0.1,0.5C268.1,407,268.3,406.6,268.5,406.6
        M272.2,407.3c0.2,0,0.2,0.1,0.1,0.3C272.1,407.6,272,407.5,272.2,407.3 M253.5,407.4c0.1,0.1,0.3,0.1,0.4,0.2c0,0.2,0,0.3,0,0.5
        c-0.1,0-0.2,0.1-0.3,0.2c0-0.1,0-0.3,0-0.4c0.1,0,0.2-0.1,0.3-0.2C253.7,407.6,253.6,407.5,253.5,407.4 M267.6,407.8
        c0.2-0.1,0.3,0,0.2,0.2C267.6,408.1,267.5,408.1,267.6,407.8 M254.1,409.5c-0.2-0.3,0.4-0.3,0.2,0
        C254.2,409.5,254.1,409.5,254.1,409.5 M266.1,410.6c-0.3-0.3,0.4-0.3,0.3,0C266.3,410.6,266.1,410.6,266.1,410.6 M279.5,411
        c-0.2-0.2,0.2-0.4,0.3-0.1c0,0-0.1,0.1-0.2,0.2L279.5,411 M236.4,410.9L236.4,410.9l0.1,0.1L236.4,410.9L236.4,410.9 M254.3,411
        c0.2-0.1,0.3,0,0.2,0.2C254.3,411.2,254.2,411.2,254.3,411 M270.3,411C270.4,411.1,270.4,411.1,270.3,411 M270,411.6
        c-0.2,0,0-0.4,0.2-0.3C270.2,411.3,270.1,411.5,270,411.6 M240.7,411.4c0.2-0.1,0.3,0,0.2,0.2C240.7,411.7,240.7,411.6,240.7,411.4
        M254.1,412.2c0.2-0.2,0.4,0.2,0.3,0.4C254.3,412.7,253.9,412.4,254.1,412.2 M254.6,413c0.2-0.1,0.3,0,0.2,0.2
        C254.6,413.2,254.5,413.2,254.6,413 M249.3,413.3C249.8,413.3,249.1,413.7,249.3,413.3 M267.8,413.4
        C268.3,413.4,267.6,413.9,267.8,413.4 M277.7,414.5c-0.1-0.2,0.2-0.3,0.3-0.4C278.3,414.1,277.9,414.5,277.7,414.5 M267.7,415.3
        c-0.6,0-0.4-0.7-0.1-1C267.8,414.7,267.4,415,267.7,415.3 M268.2,414.8C268.3,414.9,268.3,414.9,268.2,414.8 M263.6,415.4
        C264.1,415.4,263.4,415.8,263.6,415.4 M239.2,417.5c0.2-0.1,0.4,0.4,0.2,0.5C239.2,418.1,239.1,417.7,239.2,417.5 M265.7,419.2
        c0.2-0.2,0.3,0.3,0.1,0.3C265.5,419.6,265.4,419.3,265.7,419.2 M265.5,420c-0.1-0.2,0.2-0.5,0.3-0.3
        C266,419.9,265.7,420.2,265.5,420 M251.7,420.9c0.2-0.2,0.5,0.1,0.4,0.3C252,421.3,251.6,421.1,251.7,420.9 M264.8,422.8
        C264.9,422.9,264.9,422.9,264.8,422.8 M263.9,423.4c0.2-0.1,0.3,0,0.2,0.3C263.9,423.7,263.9,423.6,263.9,423.4 M252.9,424.6
        c0.2-0.1,0.4,0.3,0.3,0.5C253,425.1,252.8,424.8,252.9,424.6 M263.4,425.2c0.2,0,0.3,0.4,0.1,0.4
        C263.2,425.7,263.1,425.3,263.4,425.2 M271,427c0.3,0.2,0.2,0.5,0.2,0.8c-0.2-0.1-0.3-0.3-0.5-0.4c0.1,0,0.3,0.1,0.4,0.1
        C271.1,427.4,271.1,427.1,271,427 M262.7,427.5c0.2,0.1,0.2,0.2,0,0.3C262.5,427.7,262.5,427.6,262.7,427.5 M243.1,427.8
        c0.2-0.1,0.4,0.2,0.3,0.4C243.2,428.2,243,427.9,243.1,427.8 M262.3,429.8c-0.2-0.2-0.1-0.6,0.1-0.7
        C262.5,429.3,262.4,429.5,262.3,429.8 M250.1,434.3c0.2,0,0.1,0.5-0.1,0.4C250,434.6,250,434.4,250.1,434.3 M267.6,435
        c0.2-0.1,0.3,0.2,0.2,0.3C267.7,435.5,267.5,435.1,267.6,435 M267.3,436.3C267.3,436.4,267.3,436.4,267.3,436.3 M267,436.5l0.2-0.1
        c0,0.2,0.3,0.4,0.2,0.7c-0.1,0-0.2,0.1-0.3,0.1c-0.1-0.2-0.1-0.3-0.3-0.3l0-0.1c0.1,0,0.3,0.1,0.4,0.1
        C267.1,436.9,267.1,436.7,267,436.5 M260.6,436.9C261,436.9,260.3,437.4,260.6,436.9 M246,436.9c0.1,0.1,0.2,0.3,0.2,0.4
        c-0.1,0.1-0.1,0.3-0.2,0.4c0-0.1-0.1-0.1-0.1-0.2C246.1,437.4,246,437.2,246,436.9 M266.8,437.3c0.2,0,0.3,0,0.3,0.2
        C267,437.8,266.6,437.5,266.8,437.3 M246.1,438.1c0.2-0.2,0.5,0.4,0.2,0.5C246.1,438.5,246.1,438.3,246.1,438.1 M264.6,442.9
        c0.3,0.1,0.3,0.3,0.3,0.6c-0.3,0.1-0.6,0.4-0.5,0.7c-0.1,0-0.2,0-0.3,0c0.1-0.2,0.1-0.4,0.1-0.5l0.1,0c0.1-0.2,0.2-0.4,0.4-0.5
        C264.7,443.2,264.7,443,264.6,442.9 M247.5,444.1c0.2-0.3,0.5,0,0.7,0.1c-0.2,0.1-0.3,0.3-0.5,0.4c0-0.1,0.1-0.4,0.1-0.5
        C247.8,444.1,247.6,444.1,247.5,444.1 M263.5,446.1c0.2,0,0.5-0.1,0.5,0.2c-0.3,0-0.4,0.2-0.3,0.5c-0.2-0.2-0.1-0.5,0-0.7
        L263.5,446.1L263.5,446.1 M263.2,447c0.1,0,0.3,0,0.4,0c0,0.2,0.1,0.4,0.3,0.4c0.2,0.2,0.1,0.4,0,0.7c-0.1,0-0.3-0.1-0.4-0.2
        c0-0.2,0.4-0.5,0-0.5c-0.5,0.1-0.4,0.6-0.6,1c0.3-0.1,0.4-0.3,0.3-0.5c0.1,0.1,0.3,0.2,0.4,0.3c-0.2,0.2-0.5,0.4-0.8,0.4
        c0.1-0.3,0.2-0.5,0.3-0.7c0-0.1,0-0.3,0-0.4C263.4,447.3,263.5,447.1,263.2,447 M261,453.9c0.2-0.3,0.4-0.6,0.2-1
        c0.1,0.2,0.3,0.2,0.5,0.1c0,0.1-0.1,0.2-0.1,0.2C261.3,453.4,261.4,453.9,261,453.9 M261.8,453.4c0.2-0.1,0.4,0.1,0.3,0.3
        C261.9,453.9,261.6,453.6,261.8,453.4 M261.4,453.9C261.5,454,261.5,454,261.4,453.9 M261,454.1c0.2,0.1,0.4,0.3,0.6,0.4
        c-0.2,0.2-0.5,0.3-0.6,0.5c-0.1-0.1-0.1-0.1-0.2-0.2c0.1,0,0.3-0.1,0.4-0.1C261,454.5,260.9,454.3,261,454.1 M259.5,461.3
        c0.2,0.1,0.2,0.2,0,0.3C259.4,461.5,259.4,461.4,259.5,461.3 M258.7,466.3c0.3-0.1,0.3,0.6,0,0.4
        C258.7,466.6,258.7,466.4,258.7,466.3 M257.7,472.1c0.2-0.1,0.3,0,0.2,0.2C257.7,472.4,257.7,472.3,257.7,472.1 M257.6,473
        c0.2-0.1,0.3,0,0.2,0.2C257.6,473.2,257.5,473.2,257.6,473L257.6,473z"/>
        </svg>

    </div>
  </section>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
section {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 100%;
    height: 100%;
  }
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
